import { Badge } from "antd";
import * as d3 from "d3";
import moment from "moment";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants";
import { toFixed2Offset } from "utils/helper";

class DetectorResultChartDetail extends Component {
  constructor(props) {
    super(props);
    this.margin = { top: 20, right: 30, bottom: 30, left: 30 };
    this.width = 900;
    this.height = 500;
    this.xAxis = {};
    this.yAxis = {};
    this.xScaleObject = {};
    this.yScaleObject = {};
    this.xSubgroup = null;
    this.svg = null;
    this.groups = null;
    this.elementRef = createRef();
    this.axisSvgRef = createRef();
    this.subgroups = [];
    this.TooltipStyle = {
      width: 196,
      height: 48,
    };
  }

  renderXAxis = (data) => {
    this.xScaleObject = d3
      .scaleTime()
      .domain([data[0].from_time, data[data.length - 1].to_time])
      .range([0, this.width]);

    this.svg
      .append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(
        d3
          .axisBottom(this.xScaleObject)
          .tickSize(10)
          .tickValues([
            ...data.map((e) => e.from_time),
            ...data.map((e) => e.to_time),
          ])
          .tickFormat((d) => {
            return moment(d).format(DATE_FORMAT.time_minute);
          })
      );

    this.xSubgroup = d3
      .scaleBand()
      .domain(this.subgroups.map((e) => e.key))
      .range([0, 64])
      .padding([0.05]);
  };

  renderYAxis = (data) => {
    this.yScaleObject = d3
      .scaleLinear()
      .domain([
        0,
        data.reduce((maxV, item) => {
          return this.subgroups?.reduce((maxI, { key }) => {
            return Math.max(maxI, item[key]);
          }, maxV);
        }, 0) * 1.05,
      ])
      .range([this.height, 0]);

    this.svgAxis = d3
      .select(this.axisSvgRef.current)
      .attr("height", this.height + 20)
      .attr("width", this.margin.left + 4)
      .style("position", "absolute")
      .style("background", "var(--background)")
      .style("width", "auto")
      .style("height", "auto")
      .style("left", -3 + "px")
      .style("top", this.margin.top + "px")
      .style("index", "-40")

      .style("pointer-events", "none")
      .style("z-index", 1);
    this.svg
      .append("g")
      .attr("transform", `translate(${0},0)`)
      .style("stroke-dasharray", "3, 3")
      .call(d3.axisLeft(this.yScaleObject).tickSize(-this.width));
    this.svgAxis
      .append("g")
      .attr("transform", `translate(${this.margin.left - 1},0)`)
      .call(d3.axisLeft(this.yScaleObject).ticks());
  };

  renderHightLinePoint = (target, xMouse, yMouse) => {
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }
    this.hightLineObject = this.svg
      .append("g")
      .attr("class", "hightline-group");

    const xTooltipPosition =
      xMouse - this.elementRef.current.scrollLeft <
      this.elementRef.current.offsetWidth / 2
        ? xMouse + this.margin.left
        : xMouse - this.TooltipStyle.width - this.margin.left;
    const yTooltipPosition =
      yMouse < this.height / 2
        ? yMouse + this.TooltipStyle.height / 2
        : yMouse - this.TooltipStyle.height;

    this.hightLineObject
      .append("rect")
      .attr("width", this.TooltipStyle.width) //hard code the width of box
      .attr("height", this.TooltipStyle.height)

      .attr("class", "tooltip-box")
      .attr("stroke", "var(--text)")
      .attr("fill", "var(--background)")
      .attr(
        "transform",
        "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
      );

    let currentTextHeight = 20;

    this.hightLineObject
      .append("text")
      .text(
        "Time: " +
          moment(target.from_time).format(DATE_FORMAT.time_minute) +
          "-" +
          moment(target.to_time).format(DATE_FORMAT.time_minute)
      )
      .attr("text-anchor", "start")
      .attr("class", "tooltip-info-text")
      .attr(
        "transform",
        "translate(" +
          (xTooltipPosition + this.margin.left) +
          "," +
          (yTooltipPosition + currentTextHeight) +
          ")"
      )
      .attr("fill", "var(--text)");

    this.hightLineObject
      .append("text")
      .text(target.label + ": " + toFixed2Offset(target.value))
      .attr("text-anchor", "start")
      .attr("class", "tooltip-info-text")
      .attr(
        "transform",
        "translate(" +
          (xTooltipPosition + this.margin.left) +
          "," +
          (yTooltipPosition + currentTextHeight + 16) +
          ")"
      )
      .attr("fill", this.color(target.key));
  };
  renderChartDetail = () => {
    const { data } = this.props;
    const self = this;
    this.color = d3
      .scaleOrdinal()
      .domain(this.subgroups.map((e) => e.key))
      .range(this.subgroups.map((e) => e.color));

    // Show the bars
    this.svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => {
        return "translate(" + (this.xScaleObject(d.time) - 32) + ",0)";
      })
      // .on("mousemove", function (data) {
      //   let mouse = d3.mouse(self.svg.node());
      //   self.renderHightLinePoint(data, mouse[0], mouse[1]);
      // })

      .selectAll("rect")
      .data((d) => {
        return this.subgroups.map(function ({ key, label }) {
          return {
            key: key,
            value: d[key],
            label: label,
            from_time: d.from_time,
            to_time: d.to_time,
          };
        });
      })
      .enter()
      .append("rect")
      .attr("x", (d) => {
        return this.xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return this.yScaleObject(d.value);
      })
      .attr("width", this.xSubgroup.bandwidth())
      .attr("height", (d) => {
        return this.height - this.yScaleObject(d.value);
      })
      .attr("fill", (d) => {
        return this.color(d.key);
      })
      .on("mouseout", () => {
        if (this.hightLineObject) this.hightLineObject?.remove();
      })
      .on("mousemove", function (data) {
        let mouse = d3.mouse(self.svg.node());
        self.renderHightLinePoint(data, mouse[0], mouse[1]);
      });
  };
  drawChart = () => {
    // set the dimensions and margins of the graph
    const { data, all_key_phase } = this.props;

    this.subgroups = all_key_phase;

    const minTimeRange = data.reduce(
      (maxValue, item) => {
        return Math.min(
          maxValue,
          item.to_time.getTime() - item.from_time.getTime()
        );
      },
      [Number.MAX_SAFE_INTEGER]
    );
    const tempWidth =
      (120 *
        (data[data.length - 1].to_time.getTime() -
          data[0].from_time.getTime())) /
      minTimeRange;
    this.height =
      this.elementRef.current.offsetHeight -
      this.margin.top -
      this.margin.bottom;
    this.width = Math.max(
      tempWidth,
      this.elementRef.current.offsetWidth - this.margin.left - this.margin.right
    );
    // append the svg object to the body of the page
    this.svg = d3
      .select(this.elementRef.current)
      .append("svg")
      .style("display", "block")
      .style("width", "auto")
      .style("height", "100%")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );

    this.renderXAxis(data);
    this.renderYAxis(data);
    // this.renderChartCrossHair();
    this.renderChartDetail();
  };
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.elementRef.current.innerHTML = "";
    this.axisSvgRef.current.innerHTML = "";
    this.drawChart();
  }
  render() {
    const { all_key_phase } = this.props;
    const legends = all_key_phase;

    return (
      <div className="flex flex-1-1-auto  gap-2">
        <div className="relative overflow-x-auto flex-1-1-auto">
          <svg ref={this.axisSvgRef}></svg>
          <div
            className="overflow-x-auto"
            style={{ height: "100%", width: "100%" }}
            ref={this.elementRef}></div>
        </div>
        <div className="flex-0-0-auto">
          {legends?.map((item) => (
            <div key={item.key}>
              <Badge
                className="border-square mx-8"
                color={item.color}
                text={item.label}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(DetectorResultChartDetail);
