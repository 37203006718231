import * as d3 from "d3";
import moment from "moment";
import React, { Component, createRef } from "react";
import { ANALYSIS_LEGEND, GRAPHIC_CHART_TYPE } from "utils/constants";
import "./style.scss";

const LABEL_TEXT = 21;
class ChartDetail extends Component {
  ChartStyle = {
    marginLeftRight: 0,
    marginTopBottom: 0,
    extraFocusPosition: 0,
    extraFocusHeight: 0,
    extraFocusWidth: 2,
  };

  TooltipStyle = {
    defaultChartHeight: 18,
    width: 170,
    marginLeft: 20,
    marginTop: 20,
  };
  HIGHT_LINE_POINT = 5;
  YAXIS_TICK_SPACE = 0.1;
  LINE_POINT = 3;

  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.renderRefChart = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.planData = {};
    this.chartLegend = [];
    this.chartObject = null;
    this.fullWidth = 0;
    this.fullHeight = 0;
    this.xAxis = {};
    this.yAxis = {};
    this.xScaleObject = {};
    this.yScaleObject = {};
    this.newX = {};
    this.newYObject = {};
    this.availableLineData = [];
    this.crossHairObject = {};
    this.hightLineObject = null;
    this.bisectDate = d3.bisector(function (d) {
      return new Date(d.time).getTime();
    }).left; //select nearby data point for hightline map
    this.bisectNerbine = d3.bisector(function (d) {
      return new Date(d.time).getTime();
    }).left;
  }
  state = {
    isClick: false,
  };
  /**
   * renderChartData
   * render data inside chart area
   * base on config line type we will mapped render type
   * right now we support line and point
   * NOTE: on point type cuz many point render in the same place(value is the sametime to closed)
   * we use shouldShow check to reduce the point in the chart(improve performance)
   * if 2 point is too closed( by previouslocation) we will not render that
   */

  renderChartData = () => {
    const { isPrintMode } = this.props;
    let lineCharts = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    if (!isPrintMode)
      lineCharts
        .append("defs")
        .append("SVG:clipPath")
        .attr("id", "clip")
        .append("SVG:rect")
        .attr("width", this.fullWidth)
        .attr("height", this.fullHeight)
        .attr("x", 0)
        .attr("y", 0);
    this.lineChart = lineCharts;
    // this area to override the lineChart overflow x;yAxis
    if (Array.isArray(this.availableLineData)) {
      this.availableLineData.map((line) => {
        let minX = line.x(new Date(line.x.domain()[0]));
        let maxX = line.x(new Date(line.x.domain()[1]));
        let minY = line.y(line.y.domain()[0]);
        let maxY = line.y(line.y.domain()[1]);

        // let renderData = line.data;
        switch (line.type) {
          case GRAPHIC_CHART_TYPE.ziczac: {
            const zizacData = [];
            line.data?.forEach((item, index) => {
              zizacData.push(item);
              if (index < line.data.length - 1)
                zizacData.push({
                  time: line.data[index + 1].time,
                  value: item.value,
                });
            });
            const lineChartObj = d3
              .line()
              .x(function (d) {
                return line.x(new Date(d?.time));
              })
              .y(function (d) {
                return line.y(d?.value);
              })
              .curve(d3.curveMonotoneX);
            lineCharts
              .append("path")
              .attr("clip-path", "url(#clip)")
              .attr(
                "transform",
                "translate(" +
                  this.ChartStyle.marginLeftRight +
                  ", " +
                  this.ChartStyle.marginTopBottom +
                  ")"
              )
              .attr("class", "lines-chart")
              .attr("stroke", line.color)
              .style("fill", "none")
              .style("stroke-width", "2")
              .datum(zizacData)
              .attr("d", lineChartObj);
            let renderData = line.data.filter((item) => {
              let PointXlocation = line.x(new Date(item?.time));
              let PointYlocation = line.y(item?.value);
              if (
                PointXlocation >= minX &&
                PointXlocation <= maxX &&
                PointYlocation >= minY &&
                PointYlocation <= maxY
              ) {
                return true;
              }
              return false;
            });
            renderData?.forEach((item) => {
              if (item) {
                let pointx =
                  line.x(new Date(item?.time)) +
                  this.ChartStyle.marginLeftRight;
                let pointy =
                  line.y(item?.value) + this.ChartStyle.marginTopBottom;
                lineCharts
                  .append("circle")
                  .attr("r", this.LINE_POINT)
                  .attr("fill", line.color)
                  .style("opacity", "0")
                  .attr("class", "hight-line-point")
                  .attr("transform", "translate(" + pointx + "," + pointy + ")")
                  .on("mousemove", () => {
                    this.handleMouseMove([pointx, pointy], {
                      data: item,
                      key: line.key,
                    });
                  });
              }
            });
            break;
          }
          case GRAPHIC_CHART_TYPE.line: {
            const lineChartObj = d3
              .line()
              .x(function (d) {
                return line.x(new Date(d?.time));
              })
              .y(function (d) {
                return line.y(d?.value);
              })
              .curve(d3.curveMonotoneX);
            lineCharts
              .append("path")
              .attr("clip-path", "url(#clip)")
              .attr(
                "transform",
                "translate(" +
                  this.ChartStyle.marginLeftRight +
                  ", " +
                  this.ChartStyle.marginTopBottom +
                  ")"
              )
              .attr("class", "lines-chart")
              .attr("stroke", line.color)
              .style("fill", "none")
              .style("stroke-width", "2")
              .datum(line.data)
              .attr("d", lineChartObj);
            let renderData = line.data.filter((item) => {
              let PointXlocation = line.x(new Date(item?.time));
              let PointYlocation = line.y(item?.value);
              if (
                PointXlocation >= minX &&
                PointXlocation <= maxX &&
                PointYlocation >= minY &&
                PointYlocation <= maxY
              )
                return true;
              return false;
            });

            const renderDataLength = renderData.length;

            renderData.forEach((item) => {
              if (item) {
                let pointx =
                  line.x(new Date(item?.time)) +
                  this.ChartStyle.marginLeftRight;
                let pointy =
                  line.y(item?.value) + this.ChartStyle.marginTopBottom;
                lineCharts
                  .append("circle")
                  .attr("r", this.LINE_POINT)
                  .attr("fill", line.color)
                  .style("opacity", renderDataLength === 1 ? "1" : "0")
                  .attr("class", "hight-line-point")
                  .attr("transform", "translate(" + pointx + "," + pointy + ")")
                  .on("mousemove", () => {
                    this.handleMouseMove([pointx, pointy], {
                      data: item,
                      key: line.key,
                    });
                  });
              }
            });
            break;
          }
          case GRAPHIC_CHART_TYPE.point: {
            let previousPointXlocation = null;
            let previousPointYlocation = null;
            let renderData = line.data.filter((item) => {
              let PointXlocation = line.x(new Date(item?.time));
              let PointYlocation = line.y(item?.value);
              if (
                PointXlocation >= minX &&
                PointXlocation <= maxX &&
                PointYlocation >= minY &&
                PointYlocation <= maxY
              )
                return true;
              return false;
            });
            //if element overflow xyAxis we will not render
            renderData.forEach((item) => {
              if (item) {
                let currentPointXlocation = line.x(new Date(item?.time));
                let currentPointYlocation = line.y(item?.value);
                let shouldShow = true;
                if (previousPointXlocation && previousPointYlocation) {
                  shouldShow =
                    Math.abs(currentPointXlocation - previousPointXlocation) >
                      this.LINE_POINT * 2 ||
                    Math.abs(currentPointYlocation - previousPointYlocation) >
                      this.LINE_POINT * 2;
                }
                if (shouldShow) {
                  let pointx =
                    line.x(new Date(item?.time)) +
                    this.ChartStyle.marginLeftRight;
                  let pointy =
                    line.y(item?.value) + this.ChartStyle.marginTopBottom;

                  //To use the old method of highlighting, declare variable dot (let dot = lineCharts.append...)
                  //Uncomment lines 277-280, comment out lines 283-292
                  lineCharts
                    .append("circle")
                    .attr("r", this.LINE_POINT)
                    .attr("fill", line.color)
                    .attr("class", "hight-line-point")
                    .attr("class", "foo")
                    .attr(
                      "transform",
                      "translate(" + pointx + "," + pointy + ")"
                    )
                    .on("mousemove", () => {
                      this.handleMouseMove([pointx, pointy], {
                        data: item,
                        key: line.key,
                      });
                    });
                  if (item?.isHighLight) {
                    // Old method to draw the yellow highlight on top of the points:
                    // dot
                    //   .attr("stroke-opacity", 0.3)
                    //   .attr("stroke-width", 5)
                    //   .attr("stroke", "yellow");

                    // New method to draw orange circles around highlighted points instead:
                    lineCharts
                      .append("circle")
                      .attr("r", this.LINE_POINT * 1.5)
                      .attr(
                        "transform",
                        "translate(" + pointx + "," + pointy + ")"
                      )
                      .attr("fill", "none")
                      .attr("stroke-width", 1.5)
                      .attr("stroke", "#f5ac05");
                  }
                  previousPointXlocation = currentPointXlocation;
                  previousPointYlocation = currentPointYlocation;
                }
              }
            });
            break;
          }
          case GRAPHIC_CHART_TYPE.bar: {
            let defaultBarWidth =
              line.x(new Date(1610438540000)) - line.x(new Date(1610438400000));
            if (defaultBarWidth > 16) defaultBarWidth = 16;
            if (defaultBarWidth < 8) defaultBarWidth = 8;
            line.data.forEach((item) => {
              let pointX =
                line.x(new Date(item.time)) +
                this.ChartStyle.marginLeftRight -
                defaultBarWidth / 2;
              let miniumYValueBar = line.y.domain()[0] / 50;
              let pointY = line.y(
                item.value > 0 ? item.value : miniumYValueBar
              );
              let bar = lineCharts
                .append("rect")
                .style("fill", line.color)
                .attr("clip-path", "url(#clip)")
                .attr(
                  "transform",
                  "translate(" +
                    this.ChartStyle.marginLeftRight +
                    ", " +
                    this.ChartStyle.marginTopBottom +
                    ")"
                )
                .attr("x", pointX - this.ChartStyle.marginLeftRight)
                .attr("y", pointY)
                .attr("width", defaultBarWidth)
                .attr("class", "bar")
                .attr(
                  "height",
                  this.fullHeight - pointY + this.ChartStyle.marginTopBottom
                );
              if (
                pointX >= minX &&
                pointX <= maxX &&
                pointY >= minY &&
                pointY <= maxY
              )
                bar.on("mousemove", () => {
                  this.handleMouseMove([pointX, pointY], {
                    data: item,
                    key: line.key,
                  });
                });
            });
            break;
          }
        }
      });
    }
  };
  /**
   * findMaxValueInObjectArray
   *
   * @param {Array} array  data to find the max value
   * @param {String} key key of compare field
   * @param {Object} defaultValue defaultValue
   */
  findMaxValueInObjectArray = (array, key, defaultValue = 0) => {
    if (Array.isArray(array)) {
      return Math.max.apply(
        Math,
        array.map(function (data) {
          return data[key];
        })
      );
    }
    return defaultValue;
  };
  /**
   * renderGapDataForChart
   * find the gapdata setting in chartdata
   * then render it as background
   * @param {Array} chartData
   */
  renderGapDataForChart = (chartData) => {
    let gapDataGroup = this.chartObject
      .append("g")
      .attr("class", "gapdata-group");
    gapDataGroup
      .append("defs")
      .append("pattern")
      .attr("id", "diagonalHatch")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 4)
      .attr("height", 4)
      .append("path")
      .attr("d", "M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2")
      .attr("stroke", "#666666")
      .attr("stroke-width", 1);
    chartData?.forEach((gap) => {
      let xStart = this.newX(new Date(gap?.from_time)); //get position of from time plan
      let xEnd = this.newX(new Date(gap?.to_time)); //get position of to time plan
      const widthPlan = xEnd - xStart; //calculate the plan width
      gapDataGroup
        .append("rect")
        .attr("x", xStart)
        .attr("y", 0)
        .attr("width", widthPlan)
        .attr("height", this.fullHeight)
        .attr("class", "plan-background")
        .attr("clip-path", "url(#clip)")
        .attr("fill", "#fff")
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            "," +
            this.ChartStyle.marginTopBottom +
            ")"
        );
      gapDataGroup
        .append("rect")
        .attr("x", xStart)
        .attr("y", 0)
        .attr("width", widthPlan)
        .attr("height", this.fullHeight)
        .attr("class", "plan-background")
        .attr("clip-path", "url(#clip)")
        .attr("fill", "url(#diagonalHatch)")
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            "," +
            this.ChartStyle.marginTopBottom +
            ")"
        );
    });
  };
  /**
   * renderChartBackgroundColor
   * render chart background color by the plan data
   * @param {Array} planData
   */
  renderChartBackgroundColor = (planData) => {
    let backgroundGroup = this.chartObject
      .append("g")
      .attr("class", "backgournd-group")
      .style("pointer-events", "none");
    const minX = moment(this.newX.domain()[0]);
    const maxX = moment(this.newX.domain()[1]);
    planData?.forEach((plan, index) => {
      let fromTime = moment(plan?.from_time);
      let toTime = moment(plan?.to_time);
      if (fromTime.isBetween(minX, maxX) || toTime.isBetween(minX, maxX)) {
        if (fromTime < minX) {
          fromTime = this.newX.domain()[0];
        } else {
          fromTime = plan?.from_time;
        }
        if (toTime > maxX) {
          toTime = this.newX.domain()[1];
        } else {
          toTime = plan?.to_time;
        }
        let xStart = this.newX(new Date(fromTime)); //get position of from time plan
        let xEnd = this.newX(new Date(toTime)); //get position of to time plan
        const widthPlan = xEnd - xStart; //calculate the plan width

        backgroundGroup
          .append("rect")
          .attr("x", xStart)
          .attr("y", 0)
          .attr("width", widthPlan)
          .attr("height", this.fullHeight)
          .attr("class", "plan-background")
          .style("pointer-events", "none")
          .attr(
            "transform",
            "translate(" +
              this.ChartStyle.marginLeftRight +
              "," +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("clip-path", "url(#clip)")
          .attr("fill", index % 2 ? "#e1f0fa" : "#fff");
      }
    });

    this.chartObject.selectAll(".backgournd-group").lower();
  };
  /**
   * getDataObject
   * convert the chart data to format of D3 Chart
   * this format is inside of LineData
   * @param {Array} chartData
   * @param {Object} item item key wanna convert
   * @param {Object} planData
   *
   * @returns {Array} data with 3D format
   */
  getDataObject = (chartData, item) => {
    const { from_time, to_time } = this.props;
    let result = [];
    chartData.forEach((e) => {
      if (
        from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
        new Date(e[this.value_field]).getTime() <= to_time.getTime()
      ) {
        result.push({
          time: e[this.value_field],
          value: e[item.key],
        });
      }
    });
    return result;
  };
  /**
   * tranformChartDataToFronEndDataFormat
   * convert data return from backend to front-end format to handle logic
   * @param {Array} chartData
   * @param {*} planData just passing by for later used
   *
   * @returns {Array} chart data with front-end format
   */
  tranformChartDataToFronEndDataFormat = (chartData, planData) => {
    const { hiddenLegend } = this.props;
    this.availableLineData = [];
    if (Array.isArray(hiddenLegend) && Array.isArray(this.chartLegend)) {
      this.chartLegend.forEach((item) => {
        if (!hiddenLegend.includes(item.key)) {
          let dataObject = {
            ...item,
            data: this.getDataObject(chartData, item, planData),
            x: this.xScaleObject,
            y: this.yScaleObject[item.yAxis],
          };
          this.availableLineData.push(dataObject);
        }
      });
    }
    return this.availableLineData;
  };

  renderXaxisLineForChart = (fromTime, toTime) => {
    //create the scaleObject - this one is important cuz we use this to calculate position base on time(draw plan info)
    this.xScaleObject = d3
      .scaleTime()
      .domain([fromTime, toTime])
      .range([0, this.fullWidth]);
    this.newX = this.xScaleObject;
    //create axisObject
    let axisObject = d3
      .axisBottom(this.xScaleObject)
      .tickSize(-this.fullHeight);

    //draw x axis line
    this.xAxis = this.chartObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr(
        "transform",
        "translate(" +
          this.ChartStyle.marginLeftRight +
          "," +
          (this.fullHeight + this.ChartStyle.marginTopBottom) +
          ")"
      )
      .call(axisObject);
  };
  renderYaxisLineForChart = () => {
    let yAxisObject = this.CHART_AXIS_SETTING;
    if (Array.isArray(yAxisObject)) {
      yAxisObject.forEach((item) => {
        //find the max value of target
        const maxValue = this.findMaxValueInObjectArray(item.data, item.key);
        const tickSize = maxValue * this.YAXIS_TICK_SPACE;
        let yScaleObject = d3
          .scaleLinear()
          .domain([maxValue + tickSize, 0]) //add extra space on top to make sure out data not reach the top
          .range([0, this.fullHeight]);
        let isLeftMode = item.position === "left";
        let yaxisObject = null;
        if (isLeftMode) {
          yaxisObject = d3.axisLeft(yScaleObject);
        } else {
          yaxisObject = d3.axisRight(yScaleObject);
        }
        if (item.tick) {
          yaxisObject = yaxisObject
            .tickSize(-this.fullWidth)
            .tickFormat(item.tickFormat);
        }
        // yaxisObject;
        this.yScaleObject[item.position] = yScaleObject;
        this.yAxis[item.position] = this.chartObject
          .append("g")
          .attr("clip-path", "url(#clip-plan)")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight +
                (isLeftMode ? 0 : this.fullWidth)) +
              "," +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("class", "y-axis")
          .call(yaxisObject);
        //NOTE: rule of x and y change when rotate what why we have complicated x, y calculation
        this.chartObject
          .append("text")
          .attr("transform", isLeftMode ? "rotate(-90)" : "rotate(90)")
          .attr("x", isLeftMode ? -(this.fullHeight / 2) : this.fullHeight / 2)
          .attr(
            "y",
            (isLeftMode
              ? 0
              : -(this.fullWidth + this.ChartStyle.marginLeftRight * 2)) +
              LABEL_TEXT
          )
          .attr("class", "y-axis-label")
          .style("fill", "var(--text)")
          .style("text-anchor", "middle")
          .style("font-size", "14px")
          .text(item.label);
      });
    }
    this.newYObject = this.yScaleObject;
  };

  renderChartCrossHair = () => {
    let currentContext = this;
    //render overlay rect cover all chart + add crosshair on that
    this.overLayGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    let crossHairGroup = this.overLayGroup
      .append("g")
      .attr("class", "cross-hair-group");
    //add crosshair line
    this.crossHairObject.crosshair_x = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", this.ChartStyle.marginTopBottom)
      .attr("x2", 0)
      .attr("y2", this.fullHeight + this.ChartStyle.marginTopBottom);
    this.crossHairObject.crosshair_y = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", this.ChartStyle.marginLeftRight)
      .attr("y1", 0)
      .attr("x2", this.fullWidth + this.ChartStyle.marginLeftRight)
      .attr("y2", 0);
    //add overlay rect
    this.overLayGroup
      .append("rect")
      .attr("class", "overlay-area")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("fill-opacity", "0")
      .attr(
        "transform",
        "translate(" +
          this.ChartStyle.marginLeftRight +
          "," +
          this.ChartStyle.marginTopBottom +
          ")"
      )
      .on("mousemove", function () {
        let mouse = d3.mouse(this);
        currentContext.handleMouseMove(mouse);
      })
      .on("mouseout", this.handleMouseOut)
      .call(this.handleZoom());
    //handler mouse hover /movein/ moveout of overlay to draw cross hair and detail box
  };
  renderTooltipText = (label, unit, value) => label + ": " + value + " " + unit;

  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      this.hightLineObject
        .append("text")
        .text(
          "Time: " +
            new Date(targetObject[0]?.data?.time)?.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
            })
        ) //@TODO:hard code here
        .attr("text-anchor", "middle")
        .attr("class", "tooltip-info-text")
        .attr(
          "transform",
          "translate(" +
            (xTooltipPosition + this.TooltipStyle.width / 2) +
            "," +
            (yTooltipPosition +
              this.TooltipStyle.defaultChartHeight * labelIndex) +
            ")"
        )
        .attr("fill", "var(--text)");
      labelIndex += 1;
      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find((e) => e.key === item.key);
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        //get point position
        const yPosistion = this.newYObject[setting.yAxis](pointValue);
        this.hightLineObject
          .append("circle")
          .attr("r", this.HIGHT_LINE_POINT)
          .attr("fill", setting.color)
          .attr("class", "hight-line-point")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight + x) +
              "," +
              (yPosistion + this.ChartStyle.marginTopBottom) +
              ")"
          )
          .on("mouseout", () => {
            this.handleMouseOut();
          });

        this.hightLineObject
          .append("text")
          .text(
            this.renderTooltipText(
              setting.label,
              setting.unit,
              setting.toolTip(pointValue)
            )
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.width / 2) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", setting.color);
        labelIndex += 1;
      });

      //render hight-line infor box
    }
  };
  /**
   * handleMouseMove
   * handle mouse move action
   * if targetItem is not defined => find the suitable item in the chart data
   * if targetItem is vaild => render hight line that target item
   * @param {Object} mousePosition
   * @param {Object} targetItem the target item// format :{ data, key: line.key }
   */
  handleMouseMove = (mousePosition, targetItem = null) => {
    if (
      Array.isArray(mousePosition) &&
      mousePosition.length === 2 &&
      this.bisectDate
    ) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      //find the closest data object on chart

      //find target object by target date
      let targetObject = [];
      if (!targetItem) {
        let currentTime = null;

        //convert current mouse postion to date
        const indexDate = new Date(this.newX?.invert(xPosition));
        const indexValue = indexDate?.getTime();
        this.availableLineData.forEach((line) => {
          // if (line.type === GRAPHIC_CHART_TYPE.line) {
          let minX = line.x(new Date(line.x.domain()[0]));
          let maxX = line.x(new Date(line.x.domain()[1]));
          let minY = line.y(line.y.domain()[0]);
          let maxY = line.y(line.y.domain()[1]);

          const index = this.bisectDate(line.data, indexValue);
          const data = line.data[index];
          if (data) {
            let dataPositonX = line.x(new Date(data.time));
            let dataPositonY = line.y(data.value);
            //checkdata  in current zoom data or not
            if (
              dataPositonY > minY &&
              dataPositonY < maxY &&
              dataPositonX > minX &&
              dataPositonX < maxX
            ) {
              if (data.time)
                if (currentTime === null) {
                  targetObject.push({ data, key: line.key });
                  currentTime = data.time;
                } else {
                  if (data.time < currentTime) {
                    targetObject = [];
                    targetObject.push({ data, key: line.key });
                  } else if (data.time === currentTime) {
                    targetObject.push({ data, key: line.key });
                  }
                }
            }
          }
          // }
        });
      } else {
        targetObject.push(targetItem);
        yPosition -= this.ChartStyle.marginTopBottom;
      }

      let newXPosition = xPosition;
      if (Array.isArray(targetObject) && targetObject.length > 0) {
        //get all info of target datapoint to draw the hightline and box detail
        //draw box detail
        //update position of crosshair to data point
        newXPosition = this.newX(new Date(targetObject[0].data.time));
        this.renderHightLinePoint(targetObject, newXPosition, yPosition);
        //update the crosshair x to exact position of data point
      }

      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", newXPosition + this.ChartStyle.marginLeftRight)
        .attr("x2", newXPosition + this.ChartStyle.marginLeftRight);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.ChartStyle.marginTopBottom)
        .attr("y2", yPosition + this.ChartStyle.marginTopBottom);
    }
  };
  handleMouseOut = () => {
    //hide crossHair
    this.crossHairObject?.crosshair_x?.attr("display", "none");
    this.crossHairObject?.crosshair_y?.attr("display", "none");
    //hide hightline data point
    this.hightLineObject?.remove();
  };
  updateChart = (xObj, yObj) => {
    const { onChartZoom } = this.props;
    this.newX = xObj;
    this.newYObject = yObj;
    for (var id in yObj) {
      if (id == "left") {
        this.yAxis[id].call(d3.axisLeft(yObj[id]).tickSize(-this.fullWidth));
      } else {
        this.yAxis[id].call(d3.axisRight(yObj[id]));
      }
    }
    for (var id in this.availableLineData) {
      this.availableLineData[id].x = this.newX;
      this.availableLineData[id].y =
        this.newYObject[this.availableLineData[id].yAxis];
    }
    this.xAxis.call(d3.axisBottom(this.newX).tickSize(-this.fullHeight));
    onChartZoom && onChartZoom(this.newX);
    this.chartObject.selectAll(".backgournd-group").remove();
    this.chartObject.selectAll(".lines-chart-group").remove();
    this.chartObject.selectAll(".gapdata-group").remove();
    this.renderChartBackgroundColor(this.planData);
    this.renderGapDataForChart(this.props.gapdata);
    this.handleMouseOut();
    this.renderChartData();
  };
  resetZoom = () => {
    //let intXScale = d3.axisBottom(this.xScaleObject).tickSize(-this.fullHeight);
    this.updateChart(this.xScaleObject, this.yScaleObject);
  };
  handleZoom = () => {
    let minX = this.xScaleObject(new Date(this.xScaleObject.domain()[0]));
    let maxX = this.xScaleObject(new Date(this.xScaleObject.domain()[1]));
    let minY;
    let maxY;
    if (this.yScaleObject.left) {
      minY = this.yScaleObject.left(this.yScaleObject.left.domain()[0]);
      maxY = this.yScaleObject.left(this.yScaleObject.left.domain()[1]);
    } else {
      minY = this.yScaleObject.right(this.yScaleObject.right.domain()[0]);
      maxY = this.yScaleObject.right(this.yScaleObject.right.domain()[1]);
    }

    let zoom = d3
      .zoom()
      .scaleExtent([1, 20]) // This control how much you can unzoom (x0.5) and zoom (x20)
      .extent([
        [0, 0],
        [this.fullWidth, this.fullHeight],
      ])
      .translateExtent([
        [minX, minY],
        [maxX, maxY],
      ])
      .on("zoom", () => {
        if (this.props.isChartPerformanceMetric) {
          if (this.state.isClick) {
            let newX = d3.event.transform.rescaleX(this.xScaleObject);
            let newYObject = {};
            for (let id in this.yScaleObject) {
              let newY = d3.event.transform.rescaleY(this.yScaleObject[id]);
              newYObject[id] = newY; //update newYScale
            }
            this.updateChart(newX, newYObject);
          }
        } else {
          let newX = d3.event.transform.rescaleX(this.xScaleObject);
          let newYObject = {};
          for (let id in this.yScaleObject) {
            let newY = d3.event.transform.rescaleY(this.yScaleObject[id]);
            newYObject[id] = newY; //update newYScale
          }
          this.updateChart(newX, newYObject);
        }
      });
    return zoom;
  };

  componentDidMount() {
    const { from_time, to_time, data, isPrintMode, chartType, gapdata } =
      this.props;
    if (!this.data_field && !this.value_field) {
      return;
    }
    this.chartLegend = ANALYSIS_LEGEND[chartType];
    //general Info
    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    const chartData = data[this.data_field];
    this.planData = data?.plan_statistics;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight * 0.86 -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      //draw background color base on plan data

      this.renderXaxisLineForChart(fromTime, toTime, this.planData);
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair();
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.chartRef && !this.chartRef.current.contains(event.target)) {
      this.setState({
        isClick: false,
      });
      this.renderRefChart.current = null;
    }
  }
  handleClick = () => {
    if (this.props.isChartPerformanceMetric) {
      this.setState({
        isClick: true,
      });
    }
  };

  render() {
    return (
      <svg
        className="chart-detail"
        style={{
          boxShadow: this.state.isClick
            ? "rgba(0,153, 153, 0.7) 0px 1px 15px 0px"
            : "",
        }}
        ref={this.chartRef}
        onClick={this.handleClick}
      />
    );
  }
}

export default ChartDetail;
