import { configure } from "mobx";
import CommonStore from "./CommonStore";
import InitStore from "./InitStore";
import UserStore from "./UserStore";
import AgencyStore from "./AgencyStore";
import IntersectionStore from "./IntersectionStore";
import AuthStore from "./AuthStore";
import ReportStore from "./ReportStore";
import MyStore from "./MyStore";
import AlarmRuleStore from "./AlarmRuleStore";
import NotificationStore from "./NotificationStore";
import UnreadNotificationStore from "./UnreadNotificationStore";
import AlarmRecordStore from "./AlarmRecordStore";
import SocketStore from "./SocketStore";
import AnalysisStore from "./AnalysisStore";
import DashboardStore from "./DashboardStore";
import ChartStore from "./ChartStore";

import ReportTemplateStore from "./TemplateGroup/SummaryReportTemplateStore";
import ReportResultStore from "./TemplateGroup/SummaryReportResultStore";
import AnalysisScheduleTemplate from "./TemplateGroup/AnalysisScheduleTemplate";

import DownloadStore from "./DownloadStore";
import JobProgressStore from "./JobProgressStore";
import IntersectionConfigStore from "./IntersectionConfig/IntersectionConfigStore";
import TopologyConfigStore from "./IntersectionConfig/TopologyConfigStore";

import EventHistoryStore from "./TSPStore/EventHistoryStore";
import IntersectionEventHistoryStore from "./TSPStore/IntersectionEventHistoryStore";
import SummaryTableStore from "./TSPStore/SummaryTableStore";
import TSPStore from "./TSPStore";
import OptimizerStore from "./OptimizerGroup";
import AnalysisScheduleResult from "./TemplateGroup/AnalysisScheduleResult";
import CorridorStore from "./CorridorStore";
import CustomAnalysisStore from "./CustomAnalysisStore";
import DetectorDataStore from "./TemplateGroup/DetectorDataStoreTemplate";
import DetectorResultStore from "./TemplateGroup/DetectorResultStore";

configure({ enforceActions: "observed" });

class RootStore {
  constructor(history) {
    this.myStore = new MyStore(this);
    this.commonStore = new CommonStore(this);
    this.initStore = new InitStore(this);
    this.userStore = new UserStore(this);
    this.agencyStore = new AgencyStore(this);
    this.authStore = new AuthStore(this);
    this.reportStore = new ReportStore(this);
    this.alarmRuleStore = new AlarmRuleStore(this);
    this.intersectionStore = new IntersectionStore(this);
    this.notificationStore = new NotificationStore(this);
    this.unreadNotificationStore = new UnreadNotificationStore(this);
    this.alarmRecordStore = new AlarmRecordStore(this);
    this.socketStore = new SocketStore(this);

    this.dashboardStore = new DashboardStore(this);
    this.chartStore = new ChartStore(this);
    this.downloadStore = new DownloadStore(this);

    //corridor store
    this.corridorStore = new CorridorStore(this);
    //report group
    this.reportTemplateStore = new ReportTemplateStore(this);
    this.reportResultStore = new ReportResultStore(this);
    this.analysisScheduleStore = new AnalysisScheduleTemplate(this);
    this.analysisScheduleResultStore = new AnalysisScheduleResult(this);
    this.detectorDataStore = new DetectorDataStore(this);
    this.detectorResultStore = new DetectorResultStore(this);
    this.intersectionConfigStore = new IntersectionConfigStore(this);
    this.topologyConfigStore = new TopologyConfigStore(this);
    this.jobProgressStore = new JobProgressStore(this);

    //Analysis group
    this.analysisStore = new AnalysisStore(this);
    this.optimizerStore = new OptimizerStore(this);

    //Custom Analysis
    this.customAnalysisStore = new CustomAnalysisStore(this);

    //TSP group
    this.TSPStore = new TSPStore(this);
    this.TSPEventHistoryStore = new EventHistoryStore(this);
    this.TSPSummaryTableStore = new SummaryTableStore(this);
    this.intersectionEventHistoryStore = new IntersectionEventHistoryStore(
      this
    );

    this.initStore.initData();
    this.history = history;
  }
}

export default RootStore;
