import React, { Component } from "react";
import { Timeline, Button, Empty, Spin, Modal, Tooltip } from "antd";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import AnalysisLegend from "containers/Analysis/AnalysisLegend";
import AutoSizer from "react-virtualized-auto-sizer";
import { ANALYSIS_LEGEND } from "utils/constants";
import PrintSplitMonitor from "../PrintPreview/PrintSplitMonitor";
import { VariableSizeList } from "react-window";
import ChartItemSplitMonitor from "components/ChartItem/ChartItemSplitMonitor";

@inject("rootStore")
@observer
class AnalysisSplitMonitor extends Component {
  constructor(props) {
    super(props);
  }

  renderSplitMonitorChart = ({ height, width }) => {
    const {
      rootStore: {
        chartStore: { chartData },
      },
    } = this.props;

    return (
      <VariableSizeList
        className="list"
        height={height}
        itemCount={chartData?.length}
        itemSize={(index) => [130, height - 155][index]}
        width={width}
      >
        {ChartItemSplitMonitor.bind(this)}
      </VariableSizeList>
    );
  };

  render() {
    const {
      t,
      rootStore: {
        myStore: { currentProfile },
        chartStore: {
          chartInfo,
          allChartData,
          filterData,
          updateFilterData,
          getChartByIndex,
          currentChartTypeFrontEnd,
        },
        analysisStore: {
          gettingChart,
          printPReview,
          togglePrintPReviewVisibility,
        },
      },
      loading,
      resizing,
      convertTimeFormat,
      print,
      goToScheduleLink,
    } = this.props;
    const totalPhase = allChartData?.map((e) => e.phase);

    return gettingChart ? (
      <Spin size="large" className={"centralize"} />
    ) : chartInfo && allChartData.length && !resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header">
            <div className="left-content">
              <div className="analysis-title">
                <h1>
                  {t("analysis.perfomance-metric")}
                  {": "}
                  {t("analysis." + chartInfo?.analysis_type)}
                </h1>
                <div className="analysis-sub-title">
                  <span>{chartInfo?.title}</span>
                </div>
              </div>
              <div className="time-line">
                <div className="time-range">
                  <Timeline>
                    <Timeline.Item label={t("analysis.from_time")}>
                      {convertTimeFormat(chartInfo?.from_time)}
                    </Timeline.Item>
                    <Timeline.Item label={t("analysis.to_time")}>
                      {convertTimeFormat(chartInfo?.to_time)}
                    </Timeline.Item>
                  </Timeline>
                  {chartInfo?.isCompareMode && (
                    <Timeline>
                      <Timeline.Item label={t("analysis.from_time")}>
                        {convertTimeFormat(chartInfo?.comapre_from_time)}
                      </Timeline.Item>
                      <Timeline.Item label={t("analysis.to_time")}>
                        {convertTimeFormat(chartInfo?.comapre_to_time)}
                      </Timeline.Item>
                    </Timeline>
                  )}
                </div>
              </div>
            </div>
            <div className="right-content">
              <div className="print-function">
                <div className="flex">
                  <div className="mr-8">
                    <div className="mr-8"></div>
                    <Button size="large" icon="printer" onClick={print}>
                      {t("analysis.print-title")}
                    </Button>
                    <Tooltip title={t("analysis.create_schedule_tooltip")}>
                      <Button
                        size="large"
                        icon="snippets"
                        className="mr-8 "
                        onClick={goToScheduleLink}
                      ></Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full warning-mess"></div>
          <div></div>
          <div></div>
          <div className="filter-function">
            <div></div>
            <AnalysisLegend
              totalLegend={ANALYSIS_LEGEND[currentChartTypeFrontEnd]}
              hiddenLegend={filterData?.hiddenLegend}
              updateHiddenLegend={(hiddenLegend) => {
                updateFilterData("hiddenLegend", hiddenLegend);
              }}
            />
          </div>
        </div>
        <div className="analysis-body body-normal styled-scroll">
          {loading ? (
            <Spin size="large" className={"centralize"} />
          ) : (
            <AutoSizer>{this.renderSplitMonitorChart.bind(this)}</AutoSizer>
          )}
        </div>
        <Modal
          className="alarm-records-summary-modal"
          centered
          width={"80%"}
          title={t("print.modal-title")}
          visible={printPReview}
          onCancel={togglePrintPReviewVisibility}
          footer={null}
          destroyOnClose={true}
        >
          <PrintSplitMonitor
            totalPage={totalPhase}
            getChartByIndex={getChartByIndex}
            printInfo={{
              ...chartInfo,
              chartType: currentChartTypeFrontEnd,
              totalLegend: ANALYSIS_LEGEND[currentChartTypeFrontEnd],
            }}
            printFilter={filterData}
            reporter={currentProfile?.name}
          />
        </Modal>
      </div>
    ) : (
      <div className="wrap-analysis-result-empty">
        <Empty></Empty>
      </div>
    );
  }
}

export default withTranslation()(AnalysisSplitMonitor);
