import React, { Component } from "react";

import { Select } from "antd";
import { inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { REPORT_TEMPLATE_TYPE } from "utils/constants";

@inject("rootStore")
class SwitchTypeReport extends Component {
  reportType = [
    {
      value: REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE,
      label: "report_result.print_title",
    },
    {
      value: REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE,
      label: "analysis.perfomance-metric",
    },
    {
      value: REPORT_TEMPLATE_TYPE.DETECTOR_DATA,
      label: "detector-report",
    },
  ];

  render() {
    const { t, value, onChange } = this.props;

    return (
      <Select value={value} onChange={(value) => onChange(value)}>
        {this.reportType.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {t(item.label)}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default withTranslation()(SwitchTypeReport);
