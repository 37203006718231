import { Empty, Modal, Radio, Spin } from "antd";

import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CHART_COLOR_SCHEMA, TIMEOUT_RESIZE } from "utils/constants";
import DetectorResultChartDetail from "./DetectorResultChartDetail";

@inject("rootStore")
@observer
class DetectorResultChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "total_activation",
      loading: true,
      data: null,
    };
  }

  getCurrentChartData = () => {
    const {
      selectedInterection,
      rootStore: {
        detectorResultStore: { reportDetail, moduleService },
      },
    } = this.props;
    this.setState({ loading: true });
    moduleService.getDetailIntersectionChartDetail(
      {
        report_id: reportDetail.id,
        int_id: selectedInterection?.int_id,
      },
      (data) => {
        const chartDetailDataRes = JSON.parse(data.chart_detail);
        const dataAllKey = [];
        // getkey
        chartDetailDataRes?.data?.forEach((time, indexD) => {
          Object.keys(time.phase_data || {}).forEach((key, indexPhase) => {
            time.phase_data[key].forEach((det, indexDet) => {
              const dataKey = `phase_${key}-det_${det.detector}`;
              const label = `Phase ${key}-Detetor ${det.detector}`;
              const colorLength = Object.keys(CHART_COLOR_SCHEMA).length;

              const colorKey =
                Object.keys(CHART_COLOR_SCHEMA)[
                  (indexD * chartDetailDataRes.data.length +
                    indexPhase * Object.keys(time.phase_data).length +
                    indexDet) %
                    colorLength
                ];

              if (!dataAllKey.find((d) => d.key === dataKey))
                dataAllKey.push({
                  key: dataKey,
                  label,
                  color: CHART_COLOR_SCHEMA[colorKey],
                });
            });
          });
        });

        this.setState({
          data: {
            all_key_phase: dataAllKey,
            total_activation: chartDetailDataRes?.data.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: [],
              };

              Object.keys(time.phase_data || {}).forEach((key) => {
                time.phase_data[key].forEach((det) => {
                  objectReturn[`phase_${key}-det_${det.detector}`] =
                    det.total_activation;
                });
              });
              return objectReturn;
            }),
            occupancy: chartDetailDataRes?.data.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: [],
              };

              Object.keys(time.phase_data || {}).forEach((key) => {
                time.phase_data[key].forEach((det) => {
                  objectReturn[`phase_${key}-det_${det.detector}`] =
                    det.occupancy;
                });
              });
              return objectReturn;
            }),
            avg_volume: chartDetailDataRes?.data.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: [],
              };

              Object.keys(time.phase_data || {}).forEach((key) => {
                time.phase_data[key].forEach((det) => {
                  objectReturn[`phase_${key}-det_${det.detector}`] =
                    det.avg_volume;
                });
              });
              return objectReturn;
            }),
          },
          loading: false,
        });
      }
    );
    // const intData = resultData[0];
    // return intData?.detail_chart[this.state.selectedType];
  };
  handleWindowResize = () => {
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME

    this.setState({ resizing: true });
    setTimeout(() => {
      this.setState({ resizing: false });
    }, TIMEOUT_RESIZE);
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedInterection?.int_id !==
        this.props.selectedInterection?.int_id &&
      this.props.selectedInterection?.int_id
    )
      this.getCurrentChartData();
  }

  render() {
    const { t, selectedInterection } = this.props;

    return (
      <Modal
        visible={!!selectedInterection}
        title={"Intersection"}
        footer={<></>}
        destroyOnClose
        onCancel={() => {
          this.setState({ data: null });
          this.props.onClose();
        }}
        width={"70vw"}>
        <div
          className="flex flex-col w-full flex flex-col"
          style={{ height: "70vh" }}>
          <Radio.Group
            defaultValue="a"
            buttonStyle="solid"
            value={this.state.selectedType}
            onChange={(e) => {
              this.setState({
                selectedType: e.target.value,
              });
            }}>
            <Radio.Button value="total_activation">
              {t("Activation")}
            </Radio.Button>
            <Radio.Button value="occupancy">{t("Occupacy (%)")}</Radio.Button>
            <Radio.Button value="avg_volume">{t("Volume (VPH)")}</Radio.Button>
          </Radio.Group>

          {this.state.loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          ) : this.state.data?.[this.state.selectedType]?.length ? (
            <DetectorResultChartDetail
              all_key_phase={this.state.data.all_key_phase}
              data={this.state.data?.[this.state.selectedType]}
              // groups={}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <Empty />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(DetectorResultChart);
