import React, { Component } from "react";
import QueryBuilder from "react-querybuilder";
import { Button, InputNumber, Select } from "antd";
import { withTranslation } from "react-i18next";
import { RULE_BUILDER_COMBINATOR } from "utils/constants";

import "react-querybuilder/dist/query-builder.css";

const { Option } = Select;
const FIELD_SEPRATOR = ":";
const MAX_SUBFIELD_SUPPORTED = 4;
const ANY_IDENTIFIERS = "null";

const DF_OPERATOR = [
  { name: "==", label: "==" },
  { name: ">=", label: ">=" },
  { name: ">", label: ">" },
  { name: "<=", label: "<=" },
  { name: "<", label: "<" },
  { name: "!=", label: "!=" },
];

class AlarmRuleBuider extends Component {
  // currentFocusValue = null;
  // queryBuilderWraper = null;

  /**
   * componentDidMount
   * assign child Ref if have
   * @return  {null}
   */
  componentDidMount() {
    const { childRef } = this.props;
    childRef && childRef(this);
  }

  // componentDidUpdate() {
  //   if (this.currentFocusValue &&  this.queryBuilderWraper) {
  //     this.queryBuilderWraper.querySelector(`[name=${this.currentFocusValue}]`)?.focus();
  //   }
  // }
  /**
   * componentWillUnmount
   * clean child Ref if have
   * @return  {null}
   */
  componentWillUnmount() {
    const { childRef } = this.props;
    childRef && childRef(undefined);
  }

  /**
   * transformBackendStringToQueryBuilder
   * @params {String} queryString
   * @return  {Object}
   */
  transformBackendStringToQueryBuilder = (queryString) => {
    if (!queryString) return null;
    try {
      let queryBuilder = {};
      queryBuilder.combinator = queryString.conjunction;
      queryBuilder.rules = [];
      if (Array.isArray(queryString.conditions)) {
        queryString.conditions.map((item) => {
          if (Array.isArray(item.conditions)) {
            let recuseItem = this.transformBackendStringToQueryBuilder(item);
            queryBuilder.rules.push(recuseItem);
          } else {
            let newQuery = {};
            newQuery.field =
              item.category_id +
              FIELD_SEPRATOR +
              item.metric_id +
              (item.target
                ? FIELD_SEPRATOR +
                  item.target.type +
                  FIELD_SEPRATOR +
                  item.target.identifier
                : "");
            newQuery.value = parseFloat(item.value);
            newQuery.operator = item.operator;

            queryBuilder.rules.push(newQuery);
          }
        });
      }
      return queryBuilder;
    } catch (e) {
      //WARNING: defaultMetrix not exist
      //if this happend some thing worng with data format
      return null;
    }
  };
  /**
   * transformQueryBuilderToBackendString
   * @params {String} queryBuilder
   * @return  {Object}
   */
  transformQueryBuilderToBackendString = (queryBuilder) => {
    if (!queryBuilder) return null;
    try {
      let backendQueryFormat = {};
      backendQueryFormat.conjunction = queryBuilder.combinator;
      backendQueryFormat.conditions = [];
      if (Array.isArray(queryBuilder.rules)) {
        queryBuilder.rules.map((item) => {
          if (item.field) {
            let fieldData = item.field.split(FIELD_SEPRATOR);
            let field = {};
            field.category_id = fieldData[0];
            field.metric_id = fieldData[1];
            if (fieldData[3] !== ANY_IDENTIFIERS)
              field.target = {
                type: fieldData[2],
                identifier: fieldData[3],
              };
            field.value = item.value.toString();
            field.operator = item.operator;
            backendQueryFormat.conditions.push(field);
          } else {
            let recuseField = this.transformQueryBuilderToBackendString(item);
            backendQueryFormat.conditions.push(recuseField);
          }
        });
      }
      return backendQueryFormat;
    } catch (e) {
      //WARNING: defaultMetrix not exist
      //if this happend some thing worng with data format
      return null;
    }
  };

  /**
   * findFieldDataOnTheList
   * @params {String} categoryId
   * @params {String} metrixId
   * @params {String} identiferId
   * @return  {Object}
   */
  findFieldDataOnTheList = (
    categoryId,
    metrixId = null,
    identiferId = null
  ) => {
    const { fields } = this.props;
    let result = null;
    //find item in fields list
    let targetItem = fields.find((fieldItem) => fieldItem.id === categoryId);
    if (targetItem && Array.isArray(targetItem.metrics)) {
      result = {
        categoryId: categoryId,
        metrixList: targetItem.metrics,
      };
      let defaultMetrix = targetItem.metrics[0];
      if (metrixId) {
        defaultMetrix = targetItem.metrics.find(
          (metrix) => metrix.id === metrixId
        );
      } else {
        metrixId = defaultMetrix.id;
      }
      result.metrixId = metrixId;
      if (
        defaultMetrix &&
        defaultMetrix.target &&
        Array.isArray(defaultMetrix.target.identifiers)
      ) {
        result.operatorList = defaultMetrix.operators;
        result.identiferList = defaultMetrix.target.identifiers;
        result.type = defaultMetrix.target.type;
        result.max_value = defaultMetrix.max_value;
        result.min_value = defaultMetrix.min_value;
        let defaultIdentifier = ANY_IDENTIFIERS;
        if (identiferId) {
          defaultIdentifier = defaultMetrix.target.identifiers.find(
            (identifier) => identifier === identiferId
          );
        } else {
          identiferId = defaultIdentifier;
        }
        result.identiferId = identiferId;
      } else {
        //WARNING: defaultMetrix not exist
        //if this happend some thing worng with data format
      }
    } else {
      //WARNING: item not belong to list
      //if this happend some thing worng with data format
    }

    return result;
  };
  /**
   * updateCombinatorLogic
   * recursion update field logic
   * if field have 1 value( default format of list) => we find the sub field and add to field value
   * EX: convert  field: "cat_gap_out" =>  field: "cat_gap_out:gap_out:pharse:1"  =>category:metric:type:identifer
   * so we transform format in this function
   * @return  {null}
   */
  updateMultiFieldsLogic = (query) => {
    if (Array.isArray(query.rules)) {
      query.rules.forEach((item) => {
        if (item.field) {
          //only update field not corrected format
          let fieldValue = item.field.split(FIELD_SEPRATOR);
          let defaultFieldData = this.findFieldDataOnTheList(
            fieldValue[0],
            fieldValue[1]
          );
          if (fieldValue?.length !== MAX_SUBFIELD_SUPPORTED) {
            item.field =
              defaultFieldData?.categoryId +
              FIELD_SEPRATOR +
              defaultFieldData?.metrixId +
              FIELD_SEPRATOR +
              defaultFieldData?.type +
              FIELD_SEPRATOR +
              defaultFieldData?.identiferId;
          }
          if (item.operator === "null") {
            item.operator = defaultFieldData.operatorList[0];
          }
          if (!item.value) {
            item.value = 0;
          }
        } else if (Array.isArray(item.rules)) {
          //recursion update field logic
          this.updateMultiFieldsLogic(item);
        }
      });
    }
  };
  /**
   * updateCombinatorLogic
   * recursion update combinator logic
   * if we have 1 rule: the combinator have to be None
   * default of combinotor of 2 rule is AND
   * if we have >= 2 rules we can select combinator
   * so we transform format in this function
   * @return  {null}
   */
  updateCombinatorLogic = (rule) => {
    if (rule && Array.isArray(rule.rules)) {
      if (rule.rules.length <= 1) {
        rule.combinator = "NONE";
      } else {
        if (rule.combinator === "NONE") {
          rule.combinator = "AND";
        }
        rule.rules.forEach((item) => {
          this.updateCombinatorLogic(item);
        });
      }
    }
  };

  /**
   * transformFieldsToQueryBuilderFields
   * cuz format from backend and format of QueryBuilder is diffrence
   * so we transform format in this function
   * @return  {Array}
   */
  transformFieldsToQueryBuilderFields = (fields) => {
    if (Array.isArray(fields))
      return fields?.map((e) => {
        return { name: e.id, label: e.label };
      });
    return [];
  };
  /**
   * handleChangeQuery
   * this function will trigger when query change
   * cuz Origin libs( React-QueryBuilder) do not support multiple field/ sub fields
   * so we change format of query for our customize field
   *
   * 1. we change file to subformat support by: fieldname:subFieldName:sub-subFieldName
   * 2. we change value of Combinator base on our backend required.
   * 3. Transform our lib format to backend format for submission
   * @return  {null}
   */
  handleChangeQuery = (query) => {
    const { handleChangeCondition } = this.props;
    this.updateMultiFieldsLogic(query);
    this.updateCombinatorLogic(query);
    let queryString = this.transformQueryBuilderToBackendString(query);
    let newValue = JSON.stringify(queryString);
    if (handleChangeCondition) {
      if (queryString.conditions.length === 0) {
        newValue = undefined;
      }
      handleChangeCondition(newValue);
    }
  };

  /**  Add Phase/Detector before the Number. And change All to All Phases/Detectors
   * @return labelName
   */
  handleDisplayTargetNameIdentifier = (result, e, isAll) => {
    const targetName = result?.type === "none" ? "" : result?.type;
    return isAll
      ? result?.type === "none"
        ? `${targetName?.charAt(0).toUpperCase()}${targetName?.slice(1)} ${e}`
        : ` ${e} ${targetName?.charAt(0).toUpperCase()}${targetName?.slice(1)}s`
      : `${targetName?.charAt(0).toUpperCase()}${targetName?.slice(1)} ${e}`;
  };

  render() {
    const { t, fields, setVisible } = this.props;

    let query = undefined;

    try {
      query = this.transformBackendStringToQueryBuilder(
        JSON.parse(this.props["data-__meta"]["initialValue"])
      );
    } catch (e) {}

    return (
      <div
        className="wrap-my-query-builder"
        // ref={(x) => (this.queryBuilderWraper = x)}
      >
        <div className="abcxyz">
          <Button type="link" onClick={() => setVisible(true)}>
            {t("alarm_rules.show_condition_info")}
          </Button>
        </div>
        <QueryBuilder
          query={query}
          operators={DF_OPERATOR}
          combinators={RULE_BUILDER_COMBINATOR}
          controlClassnames={{
            queryBuilder: "queryBuilder-branches", // Root <div> element
            // ruleGroup: string, // <div> containing the RuleGroup
            // header: string, // <div> containing the RuleGroup header controls
            // combinators: string, // <select> control for combinators
            // addRule: string, // <button> to add a Rule
            // addGroup: string, // <button> to add a RuleGroup
            // removeGroup: string, // <button> to remove a RuleGroup
            // notToggle: string, // <label> on the "not" toggle
            // rule: string, // <div> containing the Rule
            // fields: string, // <select> control for fields
            // operators: string, // <select> control for operators
            // value: string, // <input> for the field value
            // removeRule: string // <button> to remove a Rule
          }}
          controlElements={{
            addGroupAction: ({ handleOnClick }) => {
              return (
                <Button type="link" onClick={handleOnClick}>
                  + Group
                </Button>
              );
            },
            addRuleAction: ({ handleOnClick }) => {
              return (
                <Button type="link" onClick={handleOnClick}>
                  + Rule
                </Button>
              );
            },
            removeGroupAction: ({ handleOnClick }) => {
              return (
                <Button
                  type="link"
                  onClick={handleOnClick}
                  icon="delete"
                  className="delete-query-btn"
                ></Button>
              );
            },
            removeRuleAction: ({ handleOnClick }) => {
              return (
                <Button
                  type="link"
                  onClick={handleOnClick}
                  icon="delete"
                  className="delete-query-btn"
                ></Button>
              );
            },
            combinatorSelector: ({ options, value, handleOnChange }) => {
              return (
                <Select
                  onChange={handleOnChange}
                  value={value}
                  disabled={value === "NONE"}
                >
                  {options.map((e) => {
                    return (
                      <Option
                        key={e.name}
                        value={e.name}
                        disabled={e.name === "NONE" && query?.rules?.length > 1}
                      >
                        {e.label}
                      </Option>
                    );
                  })}
                </Select>
              );
            },
            fieldSelector: (queryData) => {
              let realValue = queryData.value.split(FIELD_SEPRATOR);
              let result = this.findFieldDataOnTheList(
                realValue[0],
                realValue[1],
                realValue[3]
              );
              return (
                <div className="field-group">
                  <Select
                    className="field-selector"
                    onChange={queryData.handleOnChange}
                    value={realValue[0]}
                  >
                    {queryData.options.map((e) => {
                      return (
                        <Option key={e.name} value={e.name}>
                          {e.label}
                        </Option>
                      );
                    })}
                  </Select>
                  <Select
                    className="field-selector"
                    onChange={(value) =>
                      queryData.handleOnChange(
                        realValue[0] + FIELD_SEPRATOR + value
                      )
                    }
                    value={result?.metrixId}
                  >
                    {result?.metrixList.map((e) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.label}
                        </Option>
                      );
                    })}
                  </Select>
                  <Select
                    className="field-selector"
                    onChange={(value) =>
                      queryData.handleOnChange(
                        realValue[0] +
                          FIELD_SEPRATOR +
                          result?.metrixId +
                          FIELD_SEPRATOR +
                          result?.type +
                          FIELD_SEPRATOR +
                          value
                      )
                    }
                    value={result?.identiferId}
                  >
                    <Option key={ANY_IDENTIFIERS} value={ANY_IDENTIFIERS}>
                      {this.handleDisplayTargetNameIdentifier(
                        result,
                        t("All"),
                        true
                      )}
                    </Option>

                    {result?.identiferList?.map((e) => {
                      return (
                        <Option key={e} value={e}>
                          {this.handleDisplayTargetNameIdentifier(
                            result,
                            e,
                            false
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              );
            },
            operatorSelector: (queryData) => {
              let realValue = queryData.field.split(FIELD_SEPRATOR);
              let result = this.findFieldDataOnTheList(
                realValue[0],
                realValue[1],
                realValue[3]
              );
              return (
                <Select
                  className="operator-selector"
                  onChange={queryData.handleOnChange}
                  value={
                    queryData.value !== "null"
                      ? queryData.value
                      : result?.operatorList[0]
                  }
                >
                  {result?.operatorList?.map((e) => {
                    return (
                      <Option key={e} value={e}>
                        {e}
                      </Option>
                    );
                  })}
                </Select>
              );
            },
            valueEditor: (queryData) => {
              let realValue = queryData.field.split(FIELD_SEPRATOR);
              let result = this.findFieldDataOnTheList(
                realValue[0],
                realValue[1],
                realValue[3]
              );
              return (
                // <Input className="value-input" onChange={e => {
                //   const { value } = e.target;
                //   const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                //   if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                //     queryDatahandleOnChange(e.target.value);
                //   }
                // }} value={queryData.value} ></Input>
                <InputNumber
                  className="value-input"
                  name={queryData.field}
                  min={result?.min_value ? result?.max_value : 0}
                  max={result?.max_value ? result?.max_value : Number.MAX_VALUE}
                  value={queryData.value !== "null" ? queryData.value : 0}
                  onBlur={(item) => {
                    let value = item?.target?.value;
                    if (value) queryData.handleOnChange(value);
                  }}
                  // onChange={(value) => {
                  //   if (typeof value === "number") {
                  //     queryData.handleOnChange(value);
                  //   }
                  // }}
                />
              );
            },
          }}
          fields={this.transformFieldsToQueryBuilderFields(fields)}
          onQueryChange={this.handleChangeQuery}
        />
      </div>
    );
  }
}

export default withTranslation()(AlarmRuleBuider);
