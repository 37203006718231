import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DATE_FORMAT } from "utils/constants";
import ActualChart from "./ActualChart";

class TimeSpaceChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartHeight: 500,
      showUpstreamBand: true,
      showDownstreamBand: true,
      showGreenwave: true,

      showActivation: true,
      showTimePhase: true,
      showAogPhase: false,
    };
  }

  render() {
    const {
      data: { actual, general_data },
    } = this.props;

    return (
      <ActualChart
        start_time={moment(
          general_data.start_time,
          DATE_FORMAT.time_only_full
        ).toDate()}
        end_time={moment(
          general_data.end_time,
          DATE_FORMAT.time_only_full
        ).toDate()}
        from_time={moment(
          actual.from_time,
          DATE_FORMAT.time_only_full
        ).toDate()}
        to_time={moment(actual.to_time, DATE_FORMAT.time_only).toDate()}
        data={actual}
        getDataActual={null}
        {...this.state}
        hiddenContent={this.props.hiddenContent}
      />
    );
  }
}

export default withTranslation()(TimeSpaceChart);
