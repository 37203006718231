import * as d3 from "d3";
import { throttle } from "lodash";
import moment from "moment";
import React, { Component, createRef } from "react";
import {
  DATE_FORMAT,
  FONT_STRING_CAVAS,
  HISTORIC_SEEK_TYPE,
} from "utils/constants/index";
import BaseLeftContent from "./BaseLeftContent";
import BaseRightContent from "./BaseRightContent";

class BaseHistoricTimeSpaceChart extends Component {
  constructor(props) {
    super(props);
    this.parentRef = createRef();
    this.areaRef = createRef();
    this.leftRef = createRef();
    this.rightRef = createRef();
    this.ctx = null;
    this.xScale = null;
    this.yScale = null;
    this.rangeX = [0, 0];
    this.rangeY = [0, 0];
    this.leftContentWidth = 300;
    this.rightPanelWidth = 0;

    const { data } = props;
    this.dataSource = data;

    this.rangeX = [data.from_time, data.to_time];
    this.rangeY = [data.from_distance, data.to_distance];

    this.listHeight = (data.to_distance / data.min_distance) * 120;
  }

  getInitialAxis = () => {
    const width = this.areaRef.current.offsetWidth;
    this.xScale = d3
      .scaleTime()
      .range([0, width - 20])
      .domain(d3.extent([this.dataSource.from_time, this.dataSource.to_time]));
    this.rootXScale = { ...this.xScale };
    this.yScale = d3
      .scaleLinear()
      .range([0, this.listHeight])
      .domain(d3.extent(this.rangeY));
    this.rootYScale = { ...this.yScale };
  };

  handleMouseMove = () => {
    const width = this.areaRef.current.offsetWidth;

    var mouseX = d3.event.layerX || d3.event.offsetX;
    var mouseY = d3.event.layerY || d3.event.offsety;

    const yOffset = mouseY > this.areaRef.current.offsetHeight / 2 ? 0 : 120;
    d3.event.preventDefault();
    this.hoverCtx.clearRect(0, 0, width, this.listHeight);
    //draw x y axis of mouse
    this.hoverCtx.beginPath();
    this.hoverCtx.fillStyle = "white";
    this.hoverCtx.fillRect(
      mouseX > window.innerWidth / 2 ? mouseX - 170 : mouseX + 10,
      mouseY - 100 + yOffset,
      128,
      56
    );
    this.hoverCtx.closePath();
    const realXCoord = mouseX - this.leftContentWidth;
    this.hoverCtx.beginPath();
    this.hoverCtx.font = `16px ${FONT_STRING_CAVAS}`;
    this.hoverCtx.fillStyle = "black";
    const time = this.xScale.invert(realXCoord);
    const startFillText =
      mouseX > window.innerWidth / 2 ? mouseX - 165 : mouseX + 15;
    this.hoverCtx.fillText(
      `Time: ${moment(time).format(DATE_FORMAT.time_only_full)}`,
      startFillText,
      mouseY - 80 + +yOffset
    );
    this.hoverCtx.fillText(
      `CoordY: ${this.yScale.invert(mouseY).toFixed(2)}`,
      startFillText,
      mouseY - 56 + yOffset
    );
    this.hoverCtx.closePath();

    const lineCtx = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .context(this.hoverCtx);
    this.hoverCtx.beginPath();
    lineCtx([
      {
        x: mouseX,
        y: 0,
      },
      {
        x: mouseX,
        y: this.listHeight,
      },
    ]);
    lineCtx([
      {
        x: 0,
        y: mouseY,
      },
      {
        x: width,
        y: mouseY,
      },
    ]);
    this.hoverCtx.lineWidth = 0.5;
    this.hoverCtx.strokeStyle = "black";
    this.hoverCtx.stroke();
    this.hoverCtx.closePath();
  };
  handleMouseOut = () => {
    const width = this.areaRef.current.offsetWidth;
    this.hoverCtx.clearRect(0, 0, width, this.listHeight);
  };

  drawHiddenCanvas = () => {
    const { start_time, end_time } = this.props;

    const width = this.areaRef.current.offsetWidth;
    this.hoverCtx?.clearRect(0, 0, width, this.listHeight);
    const base = d3.select(this.areaRef.current);
    base.selectAll(".hiddenCanvas").remove();
    this.hoverCtx?.clearRect(0, 0, width, this.listHeight);

    this.hiddenCanvas = base
      .append("canvas")
      .classed("hiddenCanvas", true)
      .attr("width", width)
      .attr("height", this.listHeight);
    this.hoverCtx = this.hiddenCanvas.node().getContext("2d");
    d3.select(this.hiddenCanvas.node()).on("mousemove", this.handleMouseMove);
    d3.select(this.hiddenCanvas.node()).on("mouseleave", this.handleMouseOut);

    d3.select(this.hoverCtx.canvas).call(
      d3
        .zoom()
        .scaleExtent([0.5, 2])
        .extent([
          [0, 0],
          [this.areaRef.current.offsetWidth, this.areaRef.current.offsetHeight],
        ])
        .on(
          "zoom",
          throttle(() => {
            const tranformXScale = d3.event.transform.rescaleX(this.rootXScale);
            const startTime =
              start_time.getTime() > tranformXScale.domain()[0].getTime()
                ? start_time
                : tranformXScale.domain()[0];
            const endTime =
              end_time.getTime() < tranformXScale.domain()[1].getTime()
                ? end_time
                : tranformXScale.domain()[1];
            this.xScale = d3
              .scaleTime()
              .range([0, this.areaRef.current.offsetWidth - 20])
              .domain(d3.extent([startTime, endTime]));
            this.rightRef.current.handleBrushZoom(startTime, endTime);
          }),
          500
        )
    );
  };

  reDrawContent() {
    this.leftRef.current?.drawContent(this.yScale);
    this.rightRef.current?.reDrawContent({
      xScale: this.xScale,
      yScale: this.yScale,
    });
  }

  handleBrushZoom = (xStart, xEnd) => {
    this.xScale = d3
      .scaleTime()
      .range([0, this.areaRef.current.offsetWidth - 20])
      .domain(d3.extent([xStart, xEnd]));
    this.reDrawContent();
  };

  componentDidMount() {
    this.getInitialAxis();
    this.drawHiddenCanvas();
    this.reDrawContent();
    this.rightRef.current.handleBrushZoom(
      this.xScale.domain()[0],
      this.xScale.domain()[1]
    );
  }

  updateXAxis = () => {
    const width = this.areaRef.current.offsetWidth;
    this.xScale = d3
      .scaleTime()
      .range([0, width - 20])
      .domain(d3.extent([...this.xScale.domain()]));
    this.rootXScale = { ...this.xScale };

    this;
    this.yScale = d3
      .scaleLinear()
      .range([0, this.listHeight])
      .domain(d3.extent(this.rangeY));
    this.rootYScale = { ...this.yScale };
  };
  componentDidUpdate() {
    const { data } = this.props;
    this.dataSource = data;

    this.rangeX = [data.from_time, data.to_time];
    this.rangeY = [data.from_distance, data.to_distance];
    // this.getInitialAxis();
    this.updateXAxis();

    this.drawHiddenCanvas();
    this.reDrawContent();
  }
  seekTo = (value) => {
    const { start_time, end_time } = this.props;
    let startX;
    let endX;

    switch (value) {
      case HISTORIC_SEEK_TYPE.LAST_PREV:
        startX = start_time;
        endX = new Date(
          this.xScale.domain()[1].getTime() -
            this.xScale.domain()[0].getTime() +
            start_time.getTime()
        );
        break;

      case HISTORIC_SEEK_TYPE.PREV_1_MIN:
        startX =
          this.xScale.domain()[0].getTime() - 60000 > start_time.getTime()
            ? new Date(this.xScale.domain()[0].getTime() - 60000)
            : start_time;
        endX =
          this.xScale.domain()[1].getTime() - 60000 < end_time.getTime()
            ? new Date(this.xScale.domain()[1].getTime() - 60000)
            : end_time;
        break;
      case HISTORIC_SEEK_TYPE.PREV_2_MIN:
        startX =
          this.xScale.domain()[0].getTime() - 120000 > start_time.getTime()
            ? new Date(this.xScale.domain()[0].getTime() - 120000)
            : start_time;
        endX =
          this.xScale.domain()[1].getTime() - 120000 < end_time.getTime()
            ? new Date(this.xScale.domain()[1].getTime() - 120000)
            : end_time;
        break;
      case HISTORIC_SEEK_TYPE.NEXT_1_MIN:
        startX =
          this.xScale.domain()[0].getTime() + 60000 > start_time.getTime()
            ? new Date(this.xScale.domain()[0].getTime() + 60000)
            : start_time;
        endX =
          this.xScale.domain()[1].getTime() + 60000 < end_time.getTime()
            ? new Date(this.xScale.domain()[1].getTime() + 60000)
            : end_time;
        break;
      case HISTORIC_SEEK_TYPE.NEXT_2_MIN:
        startX =
          this.xScale.domain()[0].getTime() + 120000 > start_time.getTime()
            ? new Date(this.xScale.domain()[0].getTime() + 120000)
            : start_time;
        endX =
          this.xScale.domain()[1].getTime() + 120000 < end_time.getTime()
            ? new Date(this.xScale.domain()[1].getTime() + 120000)
            : end_time;
        break;

      case HISTORIC_SEEK_TYPE.LAST_NEXT:
        endX = end_time;
        startX = new Date(
          end_time.getTime() -
            this.xScale.domain()[1].getTime() +
            this.xScale.domain()[0].getTime()
        );
        break;

      default:
        break;
    }

    // console.log(startX, endX);
    this.rightRef.current.handleBrushZoom(startX, endX);
  };
  renderRightContent = () => {
    return (
      <BaseRightContent
        height={this.listHeight}
        ref={this.rightRef}
        yScale={this.yScale}
        xScale={this.xScale}
        {...this.props}
      />
    );
  };
  renderLeftContent = () => {
    const { data } = this.props;
    return (
      <BaseLeftContent
        dataSourceIntersection={data?.intersections}
        ref={this.leftRef}
        height={this.listHeight}
        width={this.leftContentWidth}
        xScale={this.xScale}
        yScale={this.yScale}
        rangeX={this.rangeX}
        seekTo={this.seekTo}
      />
    );
  };
  render() {
    return (
      <div
        ref={this.parentRef}
        className="chart-containter"
        style={{ height: this.props.height }}
      >
        <div
          ref={this.areaRef}
          className="hiddenContent"
          style={{
            width: `calc(100% - ${
              this.rightPanelWidth + this.leftContentWidth
            }px)`,
          }}
        ></div>
        {this.renderLeftContent()}
        {this.renderRightContent()}
      </div>
    );
  }
}

export default BaseHistoricTimeSpaceChart;
