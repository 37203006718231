import L from "leaflet";

export * from "./reportConstant";
export * from "./notificationConstant";
export * from "./analysisConstant";
export * from "./colorSchema";
export * from "./optimizerConstant";
export * from "./permissionConstant";

export const STATUS_CODE = [
  "ERROR",
  "SUCCESS",
  "NO_DATA",
  "UNKNOWN_EXCEPTION",
  "BAD_CREDENTIALS_EXCEPTION",
  "ACCOUNT_LOCKED_EXCEPTION",
  "ACCOUNT_DISABLED_EXCEPTION",
  "NOT_AUTHORIZED_EXCEPTION",
  "INVALID_INPUT_EXCEPTION",
];
export const EXPORT_FORMAT = {
  PDF: "PDF",
  CSV: "CSV",
};
export const PROGRESS_STATUS_TYPE = {
  CANCELLED: "CANCELLED",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
};
export const TIMEOUT_SHOW_NOTI = 3000;
export const TIMEOUT_RESIZE = 500;
export const TIMEOUT_LOAD_MORE = 1000;
export const PHASE_ALL_KEY = "all";
export const SYSTEM_ROLE = {
  OPERATOR: "OPERATOR",
};
export const TSP_KEY_TAB = {
  MAP_VIEW: "map_view",
  SUMMARY_TABLE: "summary_table",
  TSP_EVENT_HISTORY: "tsp_event_history",
  INT_EVENT_HISTORY: "int_event_history",
};
export const ARRAY_JOIN_CHARACTOR = {
  CHARACTOR: ",",
};
export const MANUAL_SCOPE = "MANUAL";

export const DATE_RAGNGE_SELECT = {
  DATE_RANGE: {
    value: "SPECIFIC",
    label: "Start/End date",
  },
  RELATIVE: {
    value: "RELATIVE",
    label: "Relative",
  },
  FIXED: {
    value: "FIXED",
    label: "Fixed date",
  },
};
export const SUCCESS_STATUS_CODE = ["SUCCESS", "NO_DATA"];
export const WEEK_DAY_OPTION = [
  { value: "SUNDAY", label: "SU" },
  { value: "MONDAY", label: "MO" },
  { value: "TUESDAY", label: "TU" },
  { value: "WEDNESDAY", label: "WE" },
  { value: "THURSDAY", label: "TH" },
  { value: "FRIDAY", label: "FR" },
  { value: "SATURDAY", label: "SA" },
];
export const UNAUTHORIZED_CODE = "UNAUTHORIZED";
export const INVALID_TOKEN_CODE = "INVALID_TOKEN";
export const ACCESS_DENIED_CODE = "ACCESS_DENIED";
export const SUPPER_ADMIN_GROUP = ["YUNEX_ADMIN", "DISTRIBUTOR"];
export const SUPPER_ADMIN_GROUP_AGENCY_ID =
  "00000000-0000-0000-0000-000000000000";
export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";
export const RULE_BUILDER_OPERATOR = [
  { name: "EQUALS", label: "=" },
  { name: "GREATER_OR_EQUAL", label: ">=" },
  { name: "GREATER_THAN", label: ">" },
  { name: "LESS_THAN", label: "<" },
  { name: "LESS_OR_EQUAL", label: "<=" },
  { name: "NOT_EQUAL", label: "!==" },
];
export const RULE_BUILDER_COMBINATOR = [
  { name: "NONE", label: "NONE" },
  { name: "AND", label: "AND" },
  { name: "OR", label: "OR" },
];
export const RULE_BUILDER_COMBINATOR_WITHOUT_NONE = [
  { name: "AND", label: "AND" },
  { name: "OR", label: "OR" },
];
export const DEFAULT_THEME = THEME_LIGHT;
export const LANGUAGE_SETTING = [
  { value: "en", label: "English" },
  // { value: "vi", label: "Tiếng Việt" },
  // { value: "de", label: "Deutsch" },
];

export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const UNACTIVATED = "UNACTIVATED";
export const ACTIVATE = "ACTIVATE";
export const DEACTIVATE = "DEACTIVATE";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const AVAILABLE = "AVAILABLE";
export const UNAVAILABLE = "UNAVAILABLE";
export const TIME_REFRESH_TOKEN = 120000; //2 min
export const CHECK_TOKEN_INTERVAL = 60000; //1 min
export const RELOAD_TSP_INTESECTION = 3600000; //1 hours
export const DATE_FORMAT = {
  full_with_tz: "HH:mm MMM DD, YYYY Z",
  short_with_tz: "hh:mm A MMM DD, YYYY Z",
  full: "HH:mm MMM DD, YYYY",
  backend: "YYYY-MM-DDTHH:mm:ss",
  day_only_backend: "YYYY-MM-DD",
  day_only: "MMM DD, YYYY",
  backend_with_tz: "YYYY-MM-DDTHH:mm:ss Z",
  analysis_range: "dddd, MMMM D, YYYY / hh:mm A",
  hour: "HH:mm",
  withTimeZone: "MM/DD/YYYY HH:mm [UTC]",
  withoutTimeZone: "MM/DD/YYYY HH:mm",
  time_only: "hh:mm:ss A",
  time_only_full: "HH:mm:ss",
  short_hour: "HH:mm A",
  tsp_mapview_zone: "MM/DD/YYYY HH:mm:ss [UTC]",
  tsp_full_zone: "HH:mm:ss MMM DD, YYYY (UTC Z)",
  tsp_full: "HH:mm:ss MMM DD, YYYY",
  zone_only: "(UTC Z)",
  dashboard_filter: "YYYY-MM-DD HH:mm:ss",
  date_and_hour: "YYYY-MM-DD HH:00",
  date_and_hour_second: "YYYY-MM-DDTHH:00:00",
  hour_only: "HH",
  time_minute: "HH:mm",
};

export const TRAFFIC_PROFILE_CHART_MODE = {
  DETAIL: "DETAIL",
  DATA_TABLE: "DATA_TABLE",
  MAX_MIN: "MAX_MIN",
};
export const SELECT_TREND = {
  decrease: "DECREASE",
  increase: "INCREASE",
  none: "NONE",
};

export const DEFAULT_STATUS_LIST = [
  { key: "ACTIVE", value: "active" },
  { key: "INACTIVE", value: "inactive" },
];

export const USER_STATUS_LIST = [
  { key: "ACTIVE", value: "active" },
  { key: "INACTIVE", value: "inactive" },
  { key: "UNACTIVATED", value: "unactivated" },
];

export const ACTION_STATUS_VISIBILITY_MAP = {
  ACTIVATE: "INACTIVE",
  DEACTIVATE: "ACTIVE",
  RESEND_EMAIL: "UNACTIVATED",
};

export const AVAILABLE_STATUS_LIST = [
  { key: "AVAILABLE", value: "Available" },
  { key: "UNAVAILABLE", value: "Unavailable" },
];
export const HORIZONTAL_LAYOUT = "horizontal";
export const FORM_ITEM_LAYOUT = null;
export const HTTP_REQUEST_MENTHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};
export const HTTP_CODE_RESPONSE = {
  GET_SUCCESS: 200,
  CREATE_SUCCESS: 201,
  UPDATE_SUCCESS: 204,
  DELETE_SUCCESS: 204,
  UNAUTHORIZED: 403,
  UNAUTHENTICATE: 401,
  BADREQUEST: 400,
  NOTFOUND: 404,
  CONFLICT: 409,
  ERROR: 500,
};
export const DAY_OF_WEEK = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const TIME_PICKER_FORMAT = "HH:mm";

export const INTERSECTION_SCOPE = {
  ALL: "ALL_INTERSECTIONS",
  SPECIFIC: "SPECIFIC_INTERSECTIONS",
};
export const DETAIL_POPUP_WIDTH = "30vw";

export const SOCKET_KEEP_MESSAGE_INTERVAL = 300000;
export const SOCKET_DELAY_RECONNECT = 1000;
export const AVATAR_FILE_TYPE = ["image/png", "image/jpg", "image/jpeg"];
export const AVATAR_FILE_SIZE = 4096000;
export const SEARCH_TEXT_FIELD = "text";
export const MAX_NUMBER_START_SEARCH = 2;
export const USER_KEY_ACTION = { USER_KEY_VIEW: 1, USER_KEY_RENERW: 2 };

export const INTERSECTION_TAB_INDEX = {
  INTERSECTION_LIST_TAB: "1",
  INTERSECTION_MAP_TAB: "2",
};
export const MAX_ZOOM_LEVEL = 10;
export const DEFAULT_ZOOM_LEVEL = 12;
export const ZOOM_LEVEL_WHOLE_MAP = 1;
export const DEFAULT_LOADING_RADIUS = 10;
export const DEFAULT_LOAD_INTERSECTION_ON_MAP = 1000;
export const INTERVAL_DELAY_TIME_LOAD_INTERSECTION = 300;

export const SCOPE_SELF = "OWNER";
export const AGENCY_FILTER_FIELD = "agency_id";

export const MARKER_SETTINGS = {
  shadowUrl: process.env.PUBLIC_URL + "/img/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
};

export const SAMPLING_PERIOD = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "8", value: 8 },
  { label: "12", value: 12 },
  { label: "24", value: 24 },
];

export const DYNAMIC_FIELD_TYPE = {
  LIST: "list",
};

export const SELECTED_PIN = new L.Icon({
  ...MARKER_SETTINGS,
  iconUrl: process.env.PUBLIC_URL + "/img/marker-icon-blue.png",
});
export const DEFAULT_PIN = new L.Icon({
  ...MARKER_SETTINGS,
  iconUrl: process.env.PUBLIC_URL + "/img/marker-icon-grey.png",
});
export const EMPTY_PIN = new L.Icon({
  ...MARKER_SETTINGS,
  iconUrl: process.env.PUBLIC_URL + "/img/marker-icon.png",
});

export const DEFAULT_MAP_CENTER = [34, -118];
export const DEFAULT_CENTER_POINT_DASHBOARD = {
  lat: 34,
  lon: -118,
};
export const MAP_SETTING = {
  RADIUS: "radius",
  BLUR: "blur",
  HEATMAP: "layer_hidden",
  ZOOM_LEVEL: "zoom_level",
  CENTER_POINT: "center_pointer",
};

export const WHOLE_MAP = {
  TOP_LEFT: "85,-180",
  BOTTOM_RIGHT: "-85,180",
};

export const DEFAULT_COLOR_MATRIX = {
  color: "#00BD18",
  from_value: 1,
};
export const DELAY_PULSING_ICON = 5000;
export const SOCKET_TOPIC = {
  NOTI_TOPIC: {
    key: "notification",
    path: "/",
  },
  TSP_EVENT_TOPIC: {
    key: "tsp_event",
    path: "/home/tsp-dashboard",
  },
};
export const MAP_DELAY_LOAD_DATA = 1500;
export const DELAY_SEARCH_STRING = 1000;
export const QUERY_DELAY_LOAD_DATA = 1000;
export const DASHBOARD_AUTO_LOAD_DATA_TIME = 3600000;

export const USER_SIDE_PANE_TABS = {
  UserDataTab: "UserDataTab",
  AgencyTab: "AgencyTab",
  PasswordTab: "PasswordTab",
  UserKeyTab: "UserKeyTab",
  AlarmFrequencyTab: "AlarmFrequencyTab",
};

export const VALUE_RESULT_TABLE = ["max", "min", "avg"];
export const UNKOWN_DATA_TEXT = "N/A";

export const COLOR_MAXTRIX_UNITS = [{ value: "%" }, { value: "VPH" }];

export const TIMESTAMP = {
  ONE_MIN: 60 * 1000,
  ONE_HOUR: 60 * 60 * 1000,
  ONE_DAY: 24 * 60 * 60 * 1000,
};

export const SUMMARY_REPORT_TAB_INDEX = {
  AVERAGE: "avg",
  MIN: "min",
  MAX: "max",
};
export const DEFAULT_CONDITION_SUMMARY_REPORT = "None";
