import BaseApi from "services/BaseApi";
import Api from "services/Api";
import helper from "utils/helper";
import { NOTIFICATION_TYPE } from "utils/constants";
import i18n from "i18n";

class DetectorDataService extends BaseApi {
  constructor(parentStore) {
    super("detector-report/templates", parentStore);
    this.dataKey = "detector_templates";
    this.keyField = "id";
  }
  /**
   * create alarm rule
   * @param   {function} payload  payload data
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  create = (payload, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.postRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };

  /**
   * update alarm rule
   * @param   {function} payload  payload data
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  update = (payload, cb, fb) => {
    const api = new Api(this.moduleName + "/" + payload.id, this.parentStore);
    api.putRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };
  /**
   * update alarm rule
   * @param   {function} payload  payload data
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  updateSchedule = (payload, cb, fb) => {
    const api_trend = new Api(
      this.moduleName + "/" + payload.id + "/schedule",
      this.parentStore
    );
    api_trend.putRequest(
      payload.data,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };
  /**
   * updateStatus
   * this function update status
   * @param   {String} subpathAction  sub url path
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateStatus = (subpathAction, data, cb, fb) => {
    const api = new Api(this.moduleName + subpathAction, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * delete
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  delete = (data, cb, fb) => {
    const api = new Api(
      this.moduleName + "?template_ids=" + data.join(","),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getDetail
   * this function return detail of rule
   * @param   {int} id  rule id
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getDetail = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  getTemplateMetrics = (cb, fb) => {
    const api = new Api(this.moduleName + "/metrics", this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * run template
   * this function return detail of rule
   * @param   {int} id  rule id
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  runTemplate = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id + "/run", this.parentStore);
    api.postRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      (response) => {
        response?.errors?.map((e) => {
          helper.showNotification(
            NOTIFICATION_TYPE.ERROR,
            i18n.t("socket.error"),
            e.message
          );
        });

        fb && fb(response);
      }
    );
  };

  getScheduleDetail = (id, cb, fb) => {
    const api = new Api(
      this.moduleName + "/" + id + "/schedule",
      this.parentStore
    );
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * getRuleCategories
   * this function return all categories type
   */
}

export default DetectorDataService;
