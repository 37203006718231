import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Charts from "components/ChartItem";
import { Spin } from "antd";
import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
// import RingChart from "components/Charts/SplitMonitor/RingChart/index";
import SplitMonitorEvents from "components/Charts/SplitMonitor/SplitMonitorEvents";
import moment from "moment";
import RingChartActual from "components/Charts/SplitMonitor/RingChart/RingChartActual";
import SplitMonitorRuler from "components/Charts/SplitMonitor/SplitMonitorRuler";
import { withTranslation } from "react-i18next";

@inject("rootStore")
@observer
class PrintSplitMonitorCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      numberCycles: null,
      to_time: null,
      actualCycle: {},
    };
  }

  processPrintDataSplitMonitor = (data) => {
    const maxDuration = 225;
    const cycleData = data[1].cycles;

    let dataToPrint = cycleData;

    const transformCycleData = (cD) => {
      let rs = [];

      cD.forEach((cycle) => {
        let ring1 = cycle?.ring1;
        let ring2 = cycle?.ring2;
        let events = cycle?.events;

        let start_time = moment(cycle?.start_time).unix() * 1000;
        let end_time = start_time + maxDuration * 1000;

        while (end_time < moment(cycle?.end_time).unix() * 1000) {
          const lightR1 = ring1?.light?.filter(
            (l) =>
              moment(l.end_time).unix() * 1000 < end_time &&
              moment(l.start_time).unix() * 1000 > start_time
          );

          const lightR2 = ring2?.light?.filter(
            (l) =>
              moment(l.end_time).unix() * 1000 < end_time &&
              moment(l.start_time).unix() * 1000 > start_time
          );

          const evt = events?.filter(
            (e) =>
              moment(e.time).unix() * 1000 < end_time &&
              moment(e.time).unix() * 1000 > start_time
          );

          let newCycle = {
            ...cycle,
            ring1: {
              ...cycle?.ring1,
              light: lightR1,
              start_time: start_time,
              end_time: end_time,
            },
            ring2: {
              ...cycle?.ring2,
              light: lightR2,
              start_time: start_time,
              end_time: end_time,
            },
            events: evt,
            start_time: start_time,
            end_time: end_time,
            duration: maxDuration,
          };
          rs.push(newCycle);

          start_time = end_time;
          end_time += maxDuration * 1000;
        }
      });

      return rs;
    };
    if (cycleData?.some((c) => c?.duration > maxDuration)) {
      dataToPrint = transformCycleData(cycleData);
    }

    const numberPages = Math.ceil(dataToPrint.length / 5);
    let printData = [];
    for (let i = 0; i < numberPages; i++) {
      let pageData = [];
      for (let j = i * 5 + 0; j < (i + 1) * 5; j++) {
        if (j > dataToPrint.length - 1) break;
        pageData.push({ cycleData: dataToPrint[j], cycleIndex: j });
      }
      printData.push(pageData);
    }

    this.setState({
      data: printData,
      numberCycles: dataToPrint.length,
      to_time: data[1].to_time,
    });
  };

  calculateChartWidth = (cycle) => {
    const chartScale = 445 / 120000;
    const cycleDuration = moment(cycle.end_time) - moment(cycle.start_time);
    const width = 180 + 300 + cycleDuration * chartScale;

    return width;
  };

  componentDidMount() {
    const {
      rootStore: {
        chartStore: { chartData },
      },
    } = this.props;

    this.setState({ actualCycle: chartData[1] });
    this.processPrintDataSplitMonitor(chartData);
  }

  render() {
    const {
      t,
      rootStore: {
        chartStore: { chartData },
      },
    } = this.props;
    return (
      <div className={"loading-screen"}>
        <Spin
          style={{ fontFamily: "siemenfont" }}
          size="large"
          className={"centralize"}
        >
          <div className="chart-data">
            <div
              key={"chart_" + 0}
              className="chart-item chart-item-print"
              style={{ height: "720px", width: "1280px" }}
            >
              <Charts
                style={{ fontFamily: SiemenFont }}
                index={0}
                isPrintMode={true}
              />
            </div>

            {this.state.data.map((page, index) => {
              return (
                <div
                  key={"split_monitor_actual_" + index}
                  className="chart-item chart-item-print"
                  style={{
                    height: "720px",
                    width: "1280px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: "25px",
                        padding: "20px 0px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="font-bold">
                        {t("analysis.actual_cycle")}
                      </div>
                      <div>
                        {t("analysis.number_of_cycles")}
                        <span className="font-bold">
                          {this.state.actualCycle.no_cycle}
                        </span>
                      </div>
                      <div>
                        {t("analysis.min_cycle_length")}
                        <span className="font-bold">
                          {this.state.actualCycle.min_cycle_length}s
                        </span>
                      </div>
                      <div>
                        {t("analysis.max_cycle_length")}
                        <span className="font-bold">
                          {this.state.actualCycle.max_cycle_length}s
                        </span>
                      </div>
                      <div>
                        {t("analysis.number_skipped")}
                        <span className="font-bold">
                          {this.state.actualCycle.no_skipped_phase}
                        </span>
                      </div>
                      <div>
                        {t("analysis.total_time_skipped")}
                        <span className="font-bold">
                          {this.state.actualCycle.skipped_phase_duration}s
                        </span>
                      </div>
                    </div>
                    <SplitMonitorEvents />
                  </div>
                  <div
                    style={{
                      width: "1280px",
                    }}
                  >
                    <div
                      style={{
                        height: "35px",
                        paddingBottom: "5px",
                        position: "relative",
                        display: "flex",
                        overflowX: "hidden",
                        flexShrink: 0,
                        fontWeight: 700,
                        width: "1180px",
                      }}
                    >
                      {t("analysis.by_cycle")}
                      <SplitMonitorRuler />
                    </div>
                    {page.map((data) => {
                      if (data.cycleIndex !== this.state.numberCycles - 1) {
                        return (
                          <RingChartActual
                            width={this.calculateChartWidth(data.cycleData)}
                            isLastCycle={false}
                            lastCycleEnds=""
                            key={data.cycleIndex}
                            type="actual"
                            cycleNumber={data.cycleIndex + 1}
                            data={data.cycleData}
                            isLongData={chartData[1].cycles.some(
                              (c) => c.duration > 225
                            )}
                          />
                        );
                      } else {
                        return (
                          <RingChartActual
                            width={this.calculateChartWidth(data.cycleData)}
                            isLastCycle={true}
                            lastCycleEnds={this.state.to_time}
                            key={data.cycleIndex}
                            type="actual"
                            cycleNumber={data.cycleIndex + 1}
                            data={data.cycleData}
                            isLongData={chartData[1].cycles.some(
                              (c) => c.duration > 225
                            )}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withTranslation()(PrintSplitMonitorCharts);
