export const mockShapes = [
  {
    shape_id: 38158,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2681,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3762,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4351,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4613,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4948,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.554,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6159,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6726,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14271,
    shape_pt_lon: -90.051876,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7175,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14269,
    shape_pt_lon: -90.051788,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7258,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7894,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14241,
    shape_pt_lon: -90.050624,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8362,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14202,
    shape_pt_lon: -90.049167,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9744,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141983,
    shape_pt_lon: -90.049049,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9861,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141741,
    shape_pt_lon: -90.048144,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0724,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141302,
    shape_pt_lon: -90.046499,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.228,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14108,
    shape_pt_lon: -90.045622,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.3118,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141046,
    shape_pt_lon: -90.045497,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.3235,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141029,
    shape_pt_lon: -90.045438,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3289,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141014,
    shape_pt_lon: -90.045388,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.3343,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140985,
    shape_pt_lon: -90.045278,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3447,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14032,
    shape_pt_lon: -90.042811,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5797,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139942,
    shape_pt_lon: -90.041308,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.7211,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139781,
    shape_pt_lon: -90.040688,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7799,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139747,
    shape_pt_lon: -90.040566,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7916,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139137,
    shape_pt_lon: -90.03831,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.0066,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139011,
    shape_pt_lon: -90.03779,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.0557,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13896,
    shape_pt_lon: -90.037588,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0756,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13886,
    shape_pt_lon: -90.036898,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1386,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138777,
    shape_pt_lon: -90.036188,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2033,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13875,
    shape_pt_lon: -90.035894,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2305,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13866,
    shape_pt_lon: -90.034828,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.327,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138586,
    shape_pt_lon: -90.033953,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.4064,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138567,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.4255,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138516,
    shape_pt_lon: -90.033198,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.4749,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138263,
    shape_pt_lon: -90.030893,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.6848,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138151,
    shape_pt_lon: -90.029838,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.7815,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13812,
    shape_pt_lon: -90.029527,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.8098,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.028318,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.9196,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.9306,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137919,
    shape_pt_lon: -90.027484,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.9961,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137894,
    shape_pt_lon: -90.027241,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.0173,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137856,
    shape_pt_lon: -90.026855,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.0526,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137837,
    shape_pt_lon: -90.026667,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.0697,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137829,
    shape_pt_lon: -90.026603,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.0758,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137817,
    shape_pt_lon: -90.026479,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.0869,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137805,
    shape_pt_lon: -90.026397,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.095,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137621,
    shape_pt_lon: -90.024575,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.2603,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137617,
    shape_pt_lon: -90.024441,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.2723,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137604,
    shape_pt_lon: -90.024308,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.2844,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137589,
    shape_pt_lon: -90.024182,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.2966,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13758,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3036,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137535,
    shape_pt_lon: -90.023612,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3479,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137523,
    shape_pt_lon: -90.023502,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.358,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13749,
    shape_pt_lon: -90.023171,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.3882,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13743,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.4427,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13737,
    shape_pt_lon: -90.021956,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.499,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137361,
    shape_pt_lon: -90.021871,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.5071,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13728,
    shape_pt_lon: -90.021084,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.5788,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137251,
    shape_pt_lon: -90.020768,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.6069,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137245,
    shape_pt_lon: -90.020698,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.6139,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137238,
    shape_pt_lon: -90.020639,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.619,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137212,
    shape_pt_lon: -90.020402,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.6402,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137028,
    shape_pt_lon: -90.018533,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.8105,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136963,
    shape_pt_lon: -90.017895,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.869,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136839,
    shape_pt_lon: -90.016669,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.9808,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136722,
    shape_pt_lon: -90.015441,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.0926,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136702,
    shape_pt_lon: -90.015297,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.1057,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13663,
    shape_pt_lon: -90.014579,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.1712,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136632,
    shape_pt_lon: -90.014577,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.1712,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136581,
    shape_pt_lon: -90.014086,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.2155,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136584,
    shape_pt_lon: -90.01409,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.2155,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136519,
    shape_pt_lon: -90.013438,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.2749,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136407,
    shape_pt_lon: -90.012303,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.3778,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136169,
    shape_pt_lon: -90.009926,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.5943,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136089,
    shape_pt_lon: -90.009072,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.6718,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136007,
    shape_pt_lon: -90.0082,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.7514,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135832,
    shape_pt_lon: -90.006239,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.9295,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135741,
    shape_pt_lon: -90.00529,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.0161,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135634,
    shape_pt_lon: -90.00428,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.1079,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135607,
    shape_pt_lon: -90.004072,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.1271,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135582,
    shape_pt_lon: -90.003809,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.1513,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135501,
    shape_pt_lon: -90.003142,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.2119,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135457,
    shape_pt_lon: -90.002707,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.2512,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135351,
    shape_pt_lon: -90.001717,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.3419,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135328,
    shape_pt_lon: -90.001497,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.3622,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135172,
    shape_pt_lon: -90.000081,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.4913,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135033,
    shape_pt_lon: -89.998738,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.6133,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134885,
    shape_pt_lon: -89.997303,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.7443,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134734,
    shape_pt_lon: -89.995849,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.8764,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134686,
    shape_pt_lon: -89.995377,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.9197,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134589,
    shape_pt_lon: -89.99447,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.0025,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134462,
    shape_pt_lon: -89.993242,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.1143,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134437,
    shape_pt_lon: -89.993002,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.1365,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134366,
    shape_pt_lon: -89.99229,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.201,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.3754,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134033,
    shape_pt_lon: -89.98908,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.4934,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133912,
    shape_pt_lon: -89.987859,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.6041,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133769,
    shape_pt_lon: -89.986417,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.7361,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133716,
    shape_pt_lon: -89.98588,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.7845,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13365,
    shape_pt_lon: -89.98522,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.8449,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133629,
    shape_pt_lon: -89.984992,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.8651,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133622,
    shape_pt_lon: -89.984829,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.8802,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133622,
    shape_pt_lon: -89.984697,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.8922,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133581,
    shape_pt_lon: -89.984472,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.9126,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133516,
    shape_pt_lon: -89.984369,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.9248,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133488,
    shape_pt_lon: -89.983961,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.9619,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133475,
    shape_pt_lon: -89.983768,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.979,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133472,
    shape_pt_lon: -89.983723,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.983,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133471,
    shape_pt_lon: -89.983681,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.987,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133444,
    shape_pt_lon: -89.983478,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.0052,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133429,
    shape_pt_lon: -89.983009,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.0483,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133409,
    shape_pt_lon: -89.982457,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.0973,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133472,
    shape_pt_lon: -89.982326,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.1112,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133401,
    shape_pt_lon: -89.980566,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.2704,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133277,
    shape_pt_lon: -89.977455,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.5518,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.5558,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133236,
    shape_pt_lon: -89.9765,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.6389,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133212,
    shape_pt_lon: -89.975791,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.7029,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133206,
    shape_pt_lon: -89.975421,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.7359,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133161,
    shape_pt_lon: -89.975336,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.7454,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133151,
    shape_pt_lon: -89.974818,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.7924,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133131,
    shape_pt_lon: -89.974621,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.8106,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133123,
    shape_pt_lon: -89.974542,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.8176,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133093,
    shape_pt_lon: -89.974328,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.8371,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133063,
    shape_pt_lon: -89.974156,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.8533,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133001,
    shape_pt_lon: -89.973647,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.8999,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133019,
    shape_pt_lon: -89.973222,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.9379,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133068,
    shape_pt_lon: -89.972947,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.9636,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133167,
    shape_pt_lon: -89.972624,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.9946,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133635,
    shape_pt_lon: -89.971585,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.1021,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133778,
    shape_pt_lon: -89.971258,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.1352,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133854,
    shape_pt_lon: -89.97109,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.1535,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133992,
    shape_pt_lon: -89.970787,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.1844,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134189,
    shape_pt_lon: -89.970347,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.2301,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134303,
    shape_pt_lon: -89.970092,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.2565,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134367,
    shape_pt_lon: -89.96995,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.2713,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134439,
    shape_pt_lon: -89.969776,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.2883,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134498,
    shape_pt_lon: -89.9696,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.3057,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13456,
    shape_pt_lon: -89.969281,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.3356,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134576,
    shape_pt_lon: -89.969088,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.3527,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134588,
    shape_pt_lon: -89.96855,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.4017,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134516,
    shape_pt_lon: -89.966987,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.5429,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134492,
    shape_pt_lon: -89.966773,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.5631,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134392,
    shape_pt_lon: -89.966216,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.6143,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134311,
    shape_pt_lon: -89.965966,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.639,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134331,
    shape_pt_lon: -89.96587,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.6476,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133896,
    shape_pt_lon: -89.964732,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.7616,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133737,
    shape_pt_lon: -89.964318,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.8037,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133373,
    shape_pt_lon: -89.963363,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.8985,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13293,
    shape_pt_lon: -89.962168,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.0175,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132832,
    shape_pt_lon: -89.961906,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.0439,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132124,
    shape_pt_lon: -89.960021,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.2314,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131872,
    shape_pt_lon: -89.959297,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.3022,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13179,
    shape_pt_lon: -89.959038,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.3278,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131681,
    shape_pt_lon: -89.958693,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.3611,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131607,
    shape_pt_lon: -89.958475,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.383,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13133,
    shape_pt_lon: -89.957583,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.4684,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.955797,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.6426,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130669,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.6705,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130242,
    shape_pt_lon: -89.954173,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.8006,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130206,
    shape_pt_lon: -89.954057,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.8114,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129878,
    shape_pt_lon: -89.953019,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.9121,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129507,
    shape_pt_lon: -89.951758,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.0336,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129234,
    shape_pt_lon: -89.950736,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.1303,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.950421,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.161,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129134,
    shape_pt_lon: -89.950363,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.1661,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129118,
    shape_pt_lon: -89.950301,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.1724,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128926,
    shape_pt_lon: -89.949601,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.2392,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128712,
    shape_pt_lon: -89.948817,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.3138,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128229,
    shape_pt_lon: -89.947026,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.4846,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12798,
    shape_pt_lon: -89.946059,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.5759,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.127812,
    shape_pt_lon: -89.945321,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.6456,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.6548,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.7108,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.7679,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.8209,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.9519,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.078,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.119,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131938,
    shape_pt_lon: -89.945145,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.126,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131939,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.141,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131953,
    shape_pt_lon: -89.945644,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.1711,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132097,
    shape_pt_lon: -89.946353,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.237,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132465,
    shape_pt_lon: -89.948147,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.4041,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132551,
    shape_pt_lon: -89.948528,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.4403,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132802,
    shape_pt_lon: -89.949357,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.5194,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132889,
    shape_pt_lon: -89.949717,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.5539,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132982,
    shape_pt_lon: -89.950182,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.5971,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133252,
    shape_pt_lon: -89.951485,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.7188,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.951566,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.7261,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133286,
    shape_pt_lon: -89.95164,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.7334,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133468,
    shape_pt_lon: -89.952527,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.8158,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133499,
    shape_pt_lon: -89.952883,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.8481,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13356,
    shape_pt_lon: -89.954335,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.9792,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133587,
    shape_pt_lon: -89.955071,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.0463,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133669,
    shape_pt_lon: -89.957203,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.2395,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133684,
    shape_pt_lon: -89.95763,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.2776,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133712,
    shape_pt_lon: -89.957945,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.3067,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133745,
    shape_pt_lon: -89.958157,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.3261,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133831,
    shape_pt_lon: -89.958508,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.3594,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133941,
    shape_pt_lon: -89.958826,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.3902,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134112,
    shape_pt_lon: -89.959176,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.4275,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134219,
    shape_pt_lon: -89.959346,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.4467,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134368,
    shape_pt_lon: -89.959552,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.4715,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134697,
    shape_pt_lon: -89.959931,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.5217,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13474,
    shape_pt_lon: -89.95998,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.5288,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13491,
    shape_pt_lon: -89.960192,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.5557,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135036,
    shape_pt_lon: -89.960397,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.5785,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135132,
    shape_pt_lon: -89.960599,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.6004,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135227,
    shape_pt_lon: -89.960881,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.6274,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135377,
    shape_pt_lon: -89.96137,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.6745,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135606,
    shape_pt_lon: -89.962164,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.7511,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135659,
    shape_pt_lon: -89.962368,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.7707,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136213,
    shape_pt_lon: -89.962123,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.8369,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137167,
    shape_pt_lon: -89.961716,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.9498,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137336,
    shape_pt_lon: -89.961686,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.969,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137672,
    shape_pt_lon: -89.961677,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.007,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138819,
    shape_pt_lon: -89.961671,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.135,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139345,
    shape_pt_lon: -89.961668,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.194,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140108,
    shape_pt_lon: -89.961663,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.279,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140613,
    shape_pt_lon: -89.961657,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.336,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140897,
    shape_pt_lon: -89.961636,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.3681,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140922,
    shape_pt_lon: -89.961629,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.3703,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141577,
    shape_pt_lon: -89.961446,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.446,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -89.961386,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.4715,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14235,
    shape_pt_lon: -89.961358,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.5316,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14287,
    shape_pt_lon: -89.961338,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.5896,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.143815,
    shape_pt_lon: -89.961313,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.6957,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.145206,
    shape_pt_lon: -89.961278,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.8517,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14554,
    shape_pt_lon: -89.961272,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.8887,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146113,
    shape_pt_lon: -89.961301,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.9527,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146398,
    shape_pt_lon: -89.961318,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.9848,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146541,
    shape_pt_lon: -89.961323,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.0008,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146809,
    shape_pt_lon: -89.96132,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.0308,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147312,
    shape_pt_lon: -89.961306,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.0878,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147397,
    shape_pt_lon: -89.961303,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.0968,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14776,
    shape_pt_lon: -89.961299,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.1378,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14794,
    shape_pt_lon: -89.961298,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.1578,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.148359,
    shape_pt_lon: -89.961308,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.2048,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.1484,
    shape_pt_lon: -89.961308,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.2088,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.148839,
    shape_pt_lon: -89.961308,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.2578,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149506,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.3328,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149493,
    shape_pt_lon: -89.96059,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.3948,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149478,
    shape_pt_lon: -89.959947,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.4529,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149472,
    shape_pt_lon: -89.95966,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.4789,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149468,
    shape_pt_lon: -89.959344,
    shape_pt_sequence: 274,
    shape_dist_traveled: 15.5079,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149463,
    shape_pt_lon: -89.95915,
    shape_pt_sequence: 275,
    shape_dist_traveled: 15.5249,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149433,
    shape_pt_lon: -89.957881,
    shape_pt_sequence: 276,
    shape_dist_traveled: 15.64,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.956682,
    shape_pt_sequence: 277,
    shape_dist_traveled: 15.748,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147906,
    shape_pt_lon: -89.956712,
    shape_pt_sequence: 278,
    shape_dist_traveled: 15.917,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147546,
    shape_pt_lon: -89.956701,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.957,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147412,
    shape_pt_lon: -89.956717,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.972,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147294,
    shape_pt_lon: -89.957174,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.016,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147269,
    shape_pt_lon: -89.957381,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.0343,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147242,
    shape_pt_lon: -89.957718,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.0654,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147271,
    shape_pt_lon: -89.959212,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.2004,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147305,
    shape_pt_lon: -89.960613,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.3265,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.147312,
    shape_pt_lon: -89.961306,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.3895,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146809,
    shape_pt_lon: -89.96132,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.4465,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146541,
    shape_pt_lon: -89.961323,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.4765,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146398,
    shape_pt_lon: -89.961318,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.4925,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.146113,
    shape_pt_lon: -89.961301,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.5246,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14554,
    shape_pt_lon: -89.961272,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.5886,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.145206,
    shape_pt_lon: -89.961278,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.6256,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.143815,
    shape_pt_lon: -89.961313,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.7816,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14287,
    shape_pt_lon: -89.961338,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.8877,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.14235,
    shape_pt_lon: -89.961358,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.9457,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -89.961386,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.0058,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.141577,
    shape_pt_lon: -89.961446,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.0313,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140922,
    shape_pt_lon: -89.961629,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.107,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140897,
    shape_pt_lon: -89.961636,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.1092,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140613,
    shape_pt_lon: -89.961657,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.1413,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.140108,
    shape_pt_lon: -89.961663,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.1983,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.139345,
    shape_pt_lon: -89.961668,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.2833,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.138819,
    shape_pt_lon: -89.961671,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.3423,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137672,
    shape_pt_lon: -89.961677,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.4703,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137336,
    shape_pt_lon: -89.961686,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.5083,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.137167,
    shape_pt_lon: -89.961716,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.5275,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.136213,
    shape_pt_lon: -89.962123,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.6404,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135659,
    shape_pt_lon: -89.962368,
    shape_pt_sequence: 308,
    shape_dist_traveled: 17.7065,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135606,
    shape_pt_lon: -89.962164,
    shape_pt_sequence: 309,
    shape_dist_traveled: 17.7262,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135377,
    shape_pt_lon: -89.96137,
    shape_pt_sequence: 310,
    shape_dist_traveled: 17.8027,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135227,
    shape_pt_lon: -89.960881,
    shape_pt_sequence: 311,
    shape_dist_traveled: 17.8499,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135132,
    shape_pt_lon: -89.960599,
    shape_pt_sequence: 312,
    shape_dist_traveled: 17.8768,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135036,
    shape_pt_lon: -89.960397,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.8988,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13491,
    shape_pt_lon: -89.960192,
    shape_pt_sequence: 314,
    shape_dist_traveled: 17.9216,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13474,
    shape_pt_lon: -89.95998,
    shape_pt_sequence: 315,
    shape_dist_traveled: 17.9485,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134697,
    shape_pt_lon: -89.959931,
    shape_pt_sequence: 316,
    shape_dist_traveled: 17.9555,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134368,
    shape_pt_lon: -89.959552,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.0058,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134219,
    shape_pt_lon: -89.959346,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.0306,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.134112,
    shape_pt_lon: -89.959176,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.0498,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133941,
    shape_pt_lon: -89.958826,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.087,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133831,
    shape_pt_lon: -89.958508,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.1179,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133745,
    shape_pt_lon: -89.958157,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.1512,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133712,
    shape_pt_lon: -89.957945,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.1706,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133684,
    shape_pt_lon: -89.95763,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.1997,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133669,
    shape_pt_lon: -89.957203,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.2378,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133587,
    shape_pt_lon: -89.955071,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.431,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13356,
    shape_pt_lon: -89.954335,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.4981,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133499,
    shape_pt_lon: -89.952883,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.6292,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133468,
    shape_pt_lon: -89.952527,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.6614,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133286,
    shape_pt_lon: -89.95164,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.7439,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.951566,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.7512,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133252,
    shape_pt_lon: -89.951485,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.7585,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132982,
    shape_pt_lon: -89.950182,
    shape_pt_sequence: 333,
    shape_dist_traveled: 18.8802,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132889,
    shape_pt_lon: -89.949717,
    shape_pt_sequence: 334,
    shape_dist_traveled: 18.9234,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132802,
    shape_pt_lon: -89.949357,
    shape_pt_sequence: 335,
    shape_dist_traveled: 18.9579,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132551,
    shape_pt_lon: -89.948528,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.037,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132465,
    shape_pt_lon: -89.948147,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.0731,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132097,
    shape_pt_lon: -89.946353,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.2402,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131953,
    shape_pt_lon: -89.945644,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.3062,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131939,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.3363,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131938,
    shape_pt_lon: -89.945145,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.3513,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.3583,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.3993,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.5254,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.6564,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.7094,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.7664,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.8225,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.127812,
    shape_pt_lon: -89.945321,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.8317,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12798,
    shape_pt_lon: -89.946059,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.9013,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128229,
    shape_pt_lon: -89.947026,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.9927,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128712,
    shape_pt_lon: -89.948817,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.1635,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.128926,
    shape_pt_lon: -89.949601,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.2381,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129118,
    shape_pt_lon: -89.950301,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.3049,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129134,
    shape_pt_lon: -89.950363,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.3112,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.950421,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.3163,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129234,
    shape_pt_lon: -89.950736,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.347,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129507,
    shape_pt_lon: -89.951758,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.4437,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.129878,
    shape_pt_lon: -89.953019,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.5652,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130206,
    shape_pt_lon: -89.954057,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.6659,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130242,
    shape_pt_lon: -89.954173,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.6766,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.130669,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.8068,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.955797,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.8347,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13133,
    shape_pt_lon: -89.957583,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.0089,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131607,
    shape_pt_lon: -89.958475,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.0943,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131681,
    shape_pt_lon: -89.958693,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.1162,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13179,
    shape_pt_lon: -89.959038,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.1495,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.131872,
    shape_pt_lon: -89.959297,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.1751,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132124,
    shape_pt_lon: -89.960021,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.2459,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.132832,
    shape_pt_lon: -89.961906,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.4333,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.13293,
    shape_pt_lon: -89.962168,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.4597,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.133373,
    shape_pt_lon: -89.963363,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.5787,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135659,
    shape_pt_lon: -89.962368,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.8488,
  },
  {
    shape_id: 38158,
    shape_pt_lat: 35.135855,
    shape_pt_lon: -89.963131,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.9203,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135855,
    shape_pt_lon: -89.963131,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135919,
    shape_pt_lon: -89.963385,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.024,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136108,
    shape_pt_lon: -89.964043,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0879,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136169,
    shape_pt_lon: -89.964351,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1166,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136202,
    shape_pt_lon: -89.964602,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1389,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136209,
    shape_pt_lon: -89.964891,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.166,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136196,
    shape_pt_lon: -89.965392,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.211,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136121,
    shape_pt_lon: -89.965825,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2508,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136031,
    shape_pt_lon: -89.966177,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2843,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135928,
    shape_pt_lon: -89.966469,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.313,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135936,
    shape_pt_lon: -89.966621,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.327,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135817,
    shape_pt_lon: -89.966947,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3588,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135613,
    shape_pt_lon: -89.967416,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4076,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135289,
    shape_pt_lon: -89.968137,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4824,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135331,
    shape_pt_lon: -89.96802,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.4944,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135331,
    shape_pt_lon: -89.96802,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5065,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.1352,
    shape_pt_lon: -89.968338,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5266,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134703,
    shape_pt_lon: -89.969424,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6395,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134511,
    shape_pt_lon: -89.969845,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.6834,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134402,
    shape_pt_lon: -89.97008,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7076,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134252,
    shape_pt_lon: -89.97041,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.7421,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134057,
    shape_pt_lon: -89.970838,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.7869,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133854,
    shape_pt_lon: -89.971275,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8316,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133724,
    shape_pt_lon: -89.971582,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.8634,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133249,
    shape_pt_lon: -89.972632,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9722,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13316,
    shape_pt_lon: -89.972885,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.9973,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.1331,
    shape_pt_lon: -89.973179,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0242,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133079,
    shape_pt_lon: -89.973439,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.0483,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133086,
    shape_pt_lon: -89.973696,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.0713,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133105,
    shape_pt_lon: -89.973885,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.0885,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133148,
    shape_pt_lon: -89.974193,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.1168,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13319,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.1423,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133212,
    shape_pt_lon: -89.974646,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.1585,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133238,
    shape_pt_lon: -89.975026,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.1926,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133245,
    shape_pt_lon: -89.975341,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.2206,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133206,
    shape_pt_lon: -89.975421,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.2295,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133212,
    shape_pt_lon: -89.975791,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.2625,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133236,
    shape_pt_lon: -89.9765,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.3266,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.4097,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133277,
    shape_pt_lon: -89.977455,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.4137,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133401,
    shape_pt_lon: -89.980566,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.6951,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133472,
    shape_pt_lon: -89.982326,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.8543,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133544,
    shape_pt_lon: -89.982461,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.8687,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133546,
    shape_pt_lon: -89.982668,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8877,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133577,
    shape_pt_lon: -89.983461,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.9588,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133589,
    shape_pt_lon: -89.983673,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.9778,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133591,
    shape_pt_lon: -89.983713,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.9818,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133593,
    shape_pt_lon: -89.98376,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.9858,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133599,
    shape_pt_lon: -89.983962,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.0049,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133623,
    shape_pt_lon: -89.984368,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.041,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133581,
    shape_pt_lon: -89.984472,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.0522,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133622,
    shape_pt_lon: -89.984697,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.0726,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133622,
    shape_pt_lon: -89.984829,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.0846,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133629,
    shape_pt_lon: -89.984992,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.0996,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13365,
    shape_pt_lon: -89.98522,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.1198,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133716,
    shape_pt_lon: -89.98588,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.1802,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133769,
    shape_pt_lon: -89.986417,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2286,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.133912,
    shape_pt_lon: -89.987859,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3606,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134033,
    shape_pt_lon: -89.98908,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.4713,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.5893,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134366,
    shape_pt_lon: -89.99229,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.7637,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134437,
    shape_pt_lon: -89.993002,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.8282,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134462,
    shape_pt_lon: -89.993242,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.8504,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134589,
    shape_pt_lon: -89.99447,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.9623,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134686,
    shape_pt_lon: -89.995377,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.045,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134734,
    shape_pt_lon: -89.995849,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.0883,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.134885,
    shape_pt_lon: -89.997303,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.2204,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135033,
    shape_pt_lon: -89.998738,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.3514,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135172,
    shape_pt_lon: -90.000081,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.4734,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135328,
    shape_pt_lon: -90.001497,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.6025,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135351,
    shape_pt_lon: -90.001717,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.6228,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135457,
    shape_pt_lon: -90.002707,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.7136,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135501,
    shape_pt_lon: -90.003142,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.7528,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135582,
    shape_pt_lon: -90.003809,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.8134,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135607,
    shape_pt_lon: -90.004072,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.8376,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135634,
    shape_pt_lon: -90.00428,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.8569,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135741,
    shape_pt_lon: -90.00529,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9486,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.135832,
    shape_pt_lon: -90.006239,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0352,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136007,
    shape_pt_lon: -90.0082,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.2134,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136089,
    shape_pt_lon: -90.009072,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.2929,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136169,
    shape_pt_lon: -90.009926,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.3704,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136407,
    shape_pt_lon: -90.012303,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.587,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136519,
    shape_pt_lon: -90.013438,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.6898,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136584,
    shape_pt_lon: -90.01409,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.7492,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136581,
    shape_pt_lon: -90.014086,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.7492,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136632,
    shape_pt_lon: -90.014577,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.7935,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13663,
    shape_pt_lon: -90.014579,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.7935,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136702,
    shape_pt_lon: -90.015297,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.859,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136722,
    shape_pt_lon: -90.015441,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.8721,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136839,
    shape_pt_lon: -90.016669,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.9839,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.136963,
    shape_pt_lon: -90.017895,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.0958,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137028,
    shape_pt_lon: -90.018533,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.1542,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137212,
    shape_pt_lon: -90.020402,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.3245,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137238,
    shape_pt_lon: -90.020639,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.3457,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137245,
    shape_pt_lon: -90.020698,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.3508,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137251,
    shape_pt_lon: -90.020768,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.3578,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13728,
    shape_pt_lon: -90.021084,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.386,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137361,
    shape_pt_lon: -90.021871,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.4577,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13737,
    shape_pt_lon: -90.021956,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.4657,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13743,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.522,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13749,
    shape_pt_lon: -90.023171,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.5765,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137523,
    shape_pt_lon: -90.023502,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.6068,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137535,
    shape_pt_lon: -90.023612,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.6168,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13758,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.6611,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137589,
    shape_pt_lon: -90.024182,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.6682,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137604,
    shape_pt_lon: -90.024308,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.6803,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137617,
    shape_pt_lon: -90.024441,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.6924,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137621,
    shape_pt_lon: -90.024575,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.7044,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137805,
    shape_pt_lon: -90.026397,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.8697,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137817,
    shape_pt_lon: -90.026479,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.8778,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137829,
    shape_pt_lon: -90.026603,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.8889,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137837,
    shape_pt_lon: -90.026667,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.895,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137856,
    shape_pt_lon: -90.026855,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.9121,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137894,
    shape_pt_lon: -90.027241,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.9474,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137919,
    shape_pt_lon: -90.027484,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.9686,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.0341,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.028318,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.0451,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13812,
    shape_pt_lon: -90.029527,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.1549,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138151,
    shape_pt_lon: -90.029838,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.1832,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138263,
    shape_pt_lon: -90.030893,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.2799,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138516,
    shape_pt_lon: -90.033198,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.4898,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138567,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.5392,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138586,
    shape_pt_lon: -90.033953,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5583,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13866,
    shape_pt_lon: -90.034828,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.6377,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13875,
    shape_pt_lon: -90.035894,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.7342,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.138777,
    shape_pt_lon: -90.036188,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.7614,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13886,
    shape_pt_lon: -90.036898,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.8261,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.13896,
    shape_pt_lon: -90.037588,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.8891,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.139011,
    shape_pt_lon: -90.03779,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.909,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.139137,
    shape_pt_lon: -90.03831,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.9581,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.139747,
    shape_pt_lon: -90.040566,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.1731,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.139781,
    shape_pt_lon: -90.040688,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.1848,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.139942,
    shape_pt_lon: -90.041308,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.2436,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14032,
    shape_pt_lon: -90.042811,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.385,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.140985,
    shape_pt_lon: -90.045278,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.62,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141014,
    shape_pt_lon: -90.045388,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.6304,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141029,
    shape_pt_lon: -90.045438,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.6358,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141046,
    shape_pt_lon: -90.045497,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.6412,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14108,
    shape_pt_lon: -90.045622,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.6529,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141302,
    shape_pt_lon: -90.046499,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.7367,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141741,
    shape_pt_lon: -90.048144,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.8923,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.141983,
    shape_pt_lon: -90.049049,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.9786,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14202,
    shape_pt_lon: -90.049167,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.9903,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14241,
    shape_pt_lon: -90.050624,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.1285,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.1754,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.2279,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.2902,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.3408,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.41,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.4698,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.5277,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.5843,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.6444,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.761,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.8787,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.9344,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.9944,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.1121,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2285,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.3417,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.3515,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.3741,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.3858,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.4329,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.4598,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.4754,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.5797,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.6914,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.6986,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.704,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.704,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.7606,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.7606,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.8131,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.8726,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.9314,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.9485,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.0111,
  },
  {
    shape_id: 38159,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.0621,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1863,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.247,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2556,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3647,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4687,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4818,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4872,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5422,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5636,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5699,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.578,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5862,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5988,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7146,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7377,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.769,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8278,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8408,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9499,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0682,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.149142,
    shape_pt_lon: -90.047058,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.1887,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.148739,
    shape_pt_lon: -90.045543,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3319,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.148462,
    shape_pt_lon: -90.044421,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4385,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.148367,
    shape_pt_lon: -90.044008,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4771,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.148265,
    shape_pt_lon: -90.043631,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5129,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.148239,
    shape_pt_lon: -90.043539,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.5224,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.147235,
    shape_pt_lon: -90.04394,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6403,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.146579,
    shape_pt_lon: -90.044184,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.7175,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14622,
    shape_pt_lon: -90.044269,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.7581,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.146177,
    shape_pt_lon: -90.044111,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.773,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.145587,
    shape_pt_lon: -90.041916,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9817,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.145086,
    shape_pt_lon: -90.040042,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.1607,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.144348,
    shape_pt_lon: -90.037283,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.4228,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143821,
    shape_pt_lon: -90.035301,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.6113,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14379,
    shape_pt_lon: -90.035141,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.6259,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143574,
    shape_pt_lon: -90.033031,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.8184,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143506,
    shape_pt_lon: -90.032323,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.8828,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.1434,
    shape_pt_lon: -90.031389,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.9676,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143369,
    shape_pt_lon: -90.03119,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.9861,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143319,
    shape_pt_lon: -90.031011,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.0038,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143097,
    shape_pt_lon: -90.030386,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.0651,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.143057,
    shape_pt_lon: -90.030248,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.0787,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.029973,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.1047,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.1782,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.2235,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.321,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.337,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.4708,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141346,
    shape_pt_lon: -90.027503,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.4949,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141296,
    shape_pt_lon: -90.027016,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.5391,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14123,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.5654,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141198,
    shape_pt_lon: -90.026502,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.5866,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141093,
    shape_pt_lon: -90.025398,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.6873,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142455,
    shape_pt_lon: -90.025199,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.8394,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142346,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.9391,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14227,
    shape_pt_lon: -90.023289,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.0126,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142262,
    shape_pt_lon: -90.023205,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.0206,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.14225,
    shape_pt_lon: -90.023073,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.0327,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.0478,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142226,
    shape_pt_lon: -90.02278,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.0588,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.0669,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142201,
    shape_pt_lon: -90.022537,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.081,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142182,
    shape_pt_lon: -90.022328,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.1001,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.142065,
    shape_pt_lon: -90.021216,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.202,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019836,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.3271,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019838,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.3271,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141846,
    shape_pt_lon: -90.019141,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.3905,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141849,
    shape_pt_lon: -90.019147,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.3905,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -90.018502,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.4487,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141751,
    shape_pt_lon: -90.017871,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.5061,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.141078,
    shape_pt_lon: -90.017969,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.5806,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.140445,
    shape_pt_lon: -90.018055,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.652,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.8039,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138999,
    shape_pt_lon: -90.01727,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.8916,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.9732,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138714,
    shape_pt_lon: -90.0143,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.1625,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138605,
    shape_pt_lon: -90.013143,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.2672,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13849,
    shape_pt_lon: -90.01199,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.372,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13818,
    shape_pt_lon: -90.008773,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.665,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138094,
    shape_pt_lon: -90.007878,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.7466,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138089,
    shape_pt_lon: -90.007822,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.7516,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137942,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.8857,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137906,
    shape_pt_lon: -90.005915,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.9249,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137901,
    shape_pt_lon: -90.005569,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.9569,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137948,
    shape_pt_lon: -90.00516,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.9942,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137978,
    shape_pt_lon: -90.005018,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.0066,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138101,
    shape_pt_lon: -90.004508,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.0556,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138141,
    shape_pt_lon: -90.004185,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.085,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13815,
    shape_pt_lon: -90.004017,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.1001,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13813,
    shape_pt_lon: -90.00361,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.1371,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138118,
    shape_pt_lon: -90.003489,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.1483,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.002431,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.2442,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137993,
    shape_pt_lon: -90.00236,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.2513,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.001117,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.3643,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13769,
    shape_pt_lon: -89.999664,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.4965,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137543,
    shape_pt_lon: -89.998341,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.6177,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137384,
    shape_pt_lon: -89.996914,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.7478,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.137218,
    shape_pt_lon: -89.995446,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.8812,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13706,
    shape_pt_lon: -89.994021,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.0114,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136928,
    shape_pt_lon: -89.992841,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.1193,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136877,
    shape_pt_lon: -89.99232,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.1667,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136836,
    shape_pt_lon: -89.991927,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.2019,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136659,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.3256,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13652,
    shape_pt_lon: -89.990356,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.3512,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136461,
    shape_pt_lon: -89.990297,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.3597,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136131,
    shape_pt_lon: -89.990162,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.3986,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.136076,
    shape_pt_lon: -89.990171,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.4046,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.135951,
    shape_pt_lon: -89.990193,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.4188,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.135477,
    shape_pt_lon: -89.990267,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.4721,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.135363,
    shape_pt_lon: -89.990272,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.4852,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.135136,
    shape_pt_lon: -89.9903,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.5112,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13476,
    shape_pt_lon: -89.990339,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.5534,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13444,
    shape_pt_lon: -89.990366,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.5885,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.6195,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.133149,
    shape_pt_lon: -89.990418,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.7336,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.132197,
    shape_pt_lon: -89.990458,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.8396,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.13213,
    shape_pt_lon: -89.990461,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.8477,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.990477,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.8927,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.130995,
    shape_pt_lon: -89.99051,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.9747,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.130267,
    shape_pt_lon: -89.990533,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.0558,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.129292,
    shape_pt_lon: -89.99058,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.1648,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.129205,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.1749,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.128405,
    shape_pt_lon: -89.990627,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.2649,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.128177,
    shape_pt_lon: -89.990638,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.29,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.127158,
    shape_pt_lon: -89.990685,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.404,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.12672,
    shape_pt_lon: -89.990698,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.4531,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5311,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.125157,
    shape_pt_lon: -89.990778,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6282,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -89.990783,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6382,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.124602,
    shape_pt_lon: -89.990808,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6903,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.12448,
    shape_pt_lon: -89.990813,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.7043,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.123689,
    shape_pt_lon: -89.99085,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.7924,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.123599,
    shape_pt_lon: -89.990855,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8034,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122771,
    shape_pt_lon: -89.990897,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.8954,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.121898,
    shape_pt_lon: -89.990937,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.9935,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.121819,
    shape_pt_lon: -89.99094,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.0025,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.99098,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.0996,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120771,
    shape_pt_lon: -89.990997,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.1196,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11978,
    shape_pt_lon: -89.991073,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.2309,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119642,
    shape_pt_lon: -89.991079,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.2459,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119613,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.3439,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119612,
    shape_pt_lon: -89.989921,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.35,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11959,
    shape_pt_lon: -89.988987,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.435,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119568,
    shape_pt_lon: -89.988087,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.516,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119548,
    shape_pt_lon: -89.987176,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.5981,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119525,
    shape_pt_lon: -89.986309,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.6771,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119507,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.7501,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.7621,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11949,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.8422,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119018,
    shape_pt_lon: -89.984495,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.8952,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.118696,
    shape_pt_lon: -89.984492,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.9312,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.118194,
    shape_pt_lon: -89.984556,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.9875,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.117735,
    shape_pt_lon: -89.984584,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.0385,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115975,
    shape_pt_lon: -89.984651,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.2356,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115972,
    shape_pt_lon: -89.984588,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.2416,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115969,
    shape_pt_lon: -89.984473,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.2517,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115968,
    shape_pt_lon: -89.984398,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.2587,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115964,
    shape_pt_lon: -89.984279,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.2697,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11589,
    shape_pt_lon: -89.98084,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.5798,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115884,
    shape_pt_lon: -89.980687,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.5938,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115875,
    shape_pt_lon: -89.980394,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.6208,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115863,
    shape_pt_lon: -89.980078,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.6488,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11581,
    shape_pt_lon: -89.977914,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.8449,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115771,
    shape_pt_lon: -89.976156,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.004,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115728,
    shape_pt_lon: -89.974297,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.1721,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115714,
    shape_pt_lon: -89.973502,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.2441,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115689,
    shape_pt_lon: -89.972611,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.3241,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115671,
    shape_pt_lon: -89.971763,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.4012,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115649,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.4972,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115622,
    shape_pt_lon: -89.969623,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.5942,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.1156,
    shape_pt_lon: -89.969098,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.6423,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115579,
    shape_pt_lon: -89.968183,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.7243,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115543,
    shape_pt_lon: -89.966582,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.8694,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115532,
    shape_pt_lon: -89.966054,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.9174,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11546,
    shape_pt_lon: -89.962713,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.2195,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115461,
    shape_pt_lon: -89.962538,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.2355,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115466,
    shape_pt_lon: -89.961886,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.2945,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115449,
    shape_pt_lon: -89.961122,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.3635,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -89.960662,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.4046,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -89.960326,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.4357,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115368,
    shape_pt_lon: -89.958488,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.6017,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115349,
    shape_pt_lon: -89.957581,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.6838,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -89.956647,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.7678,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115282,
    shape_pt_lon: -89.954678,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.9459,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115277,
    shape_pt_lon: -89.954368,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.9739,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115275,
    shape_pt_lon: -89.954263,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.9839,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115249,
    shape_pt_lon: -89.953173,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.0819,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115217,
    shape_pt_lon: -89.951711,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.214,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115183,
    shape_pt_lon: -89.95022,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.349,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115152,
    shape_pt_lon: -89.948766,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.4801,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115133,
    shape_pt_lon: -89.947737,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.5731,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115153,
    shape_pt_lon: -89.947244,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.6182,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115181,
    shape_pt_lon: -89.946951,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.6454,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115233,
    shape_pt_lon: -89.946653,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.672,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115284,
    shape_pt_lon: -89.946429,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.6936,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -89.94583,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.7509,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.758,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115511,
    shape_pt_lon: -89.945551,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.7769,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115899,
    shape_pt_lon: -89.944468,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.8839,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116092,
    shape_pt_lon: -89.943931,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.9367,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116219,
    shape_pt_lon: -89.943486,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.9801,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116289,
    shape_pt_lon: -89.943202,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.0063,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116397,
    shape_pt_lon: -89.942782,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.0462,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116443,
    shape_pt_lon: -89.942421,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.0795,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116479,
    shape_pt_lon: -89.942043,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.1139,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116489,
    shape_pt_lon: -89.941728,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.1419,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116482,
    shape_pt_lon: -89.941191,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.1909,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.940768,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.229,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116391,
    shape_pt_lon: -89.936804,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.5871,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116337,
    shape_pt_lon: -89.934203,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.8231,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116361,
    shape_pt_lon: -89.93408,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.8343,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116411,
    shape_pt_lon: -89.933976,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.8451,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.8537,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116576,
    shape_pt_lon: -89.93388,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.8654,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11965,
    shape_pt_lon: -89.93377,
    shape_pt_sequence: 220,
    shape_dist_traveled: 15.2095,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120237,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 221,
    shape_dist_traveled: 15.2756,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.121394,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 222,
    shape_dist_traveled: 15.4056,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122851,
    shape_pt_lon: -89.933669,
    shape_pt_sequence: 223,
    shape_dist_traveled: 15.5687,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.12285,
    shape_pt_lon: -89.933601,
    shape_pt_sequence: 224,
    shape_dist_traveled: 15.5747,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -89.931146,
    shape_pt_sequence: 225,
    shape_dist_traveled: 15.7968,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122751,
    shape_pt_lon: -89.930122,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.8888,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122745,
    shape_pt_lon: -89.929746,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.9229,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122721,
    shape_pt_lon: -89.928587,
    shape_pt_sequence: 228,
    shape_dist_traveled: 16.0279,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122662,
    shape_pt_lon: -89.926531,
    shape_pt_sequence: 229,
    shape_dist_traveled: 16.214,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.12266,
    shape_pt_lon: -89.926458,
    shape_pt_sequence: 230,
    shape_dist_traveled: 16.22,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122643,
    shape_pt_lon: -89.925813,
    shape_pt_sequence: 231,
    shape_dist_traveled: 16.279,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.122118,
    shape_pt_lon: -89.925828,
    shape_pt_sequence: 232,
    shape_dist_traveled: 16.3371,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.121753,
    shape_pt_lon: -89.925841,
    shape_pt_sequence: 233,
    shape_dist_traveled: 16.3781,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120811,
    shape_pt_lon: -89.92587,
    shape_pt_sequence: 234,
    shape_dist_traveled: 16.4841,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120549,
    shape_pt_lon: -89.925879,
    shape_pt_sequence: 235,
    shape_dist_traveled: 16.5131,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.120112,
    shape_pt_lon: -89.925895,
    shape_pt_sequence: 236,
    shape_dist_traveled: 16.5621,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119906,
    shape_pt_lon: -89.9259,
    shape_pt_sequence: 237,
    shape_dist_traveled: 16.5852,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.119714,
    shape_pt_lon: -89.925907,
    shape_pt_sequence: 238,
    shape_dist_traveled: 16.6062,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11895,
    shape_pt_lon: -89.925926,
    shape_pt_sequence: 239,
    shape_dist_traveled: 16.6922,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.117359,
    shape_pt_lon: -89.925969,
    shape_pt_sequence: 240,
    shape_dist_traveled: 16.8702,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116529,
    shape_pt_lon: -89.925992,
    shape_pt_sequence: 241,
    shape_dist_traveled: 16.9632,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.116109,
    shape_pt_lon: -89.925999,
    shape_pt_sequence: 242,
    shape_dist_traveled: 17.0103,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115937,
    shape_pt_lon: -89.926003,
    shape_pt_sequence: 243,
    shape_dist_traveled: 17.0293,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11573,
    shape_pt_lon: -89.926011,
    shape_pt_sequence: 244,
    shape_dist_traveled: 17.0523,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.115304,
    shape_pt_lon: -89.925976,
    shape_pt_sequence: 245,
    shape_dist_traveled: 17.1004,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.11492,
    shape_pt_lon: -89.925974,
    shape_pt_sequence: 246,
    shape_dist_traveled: 17.1434,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.112969,
    shape_pt_lon: -89.926057,
    shape_pt_sequence: 247,
    shape_dist_traveled: 17.3615,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.109713,
    shape_pt_lon: -89.926199,
    shape_pt_sequence: 248,
    shape_dist_traveled: 17.7257,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.10746,
    shape_pt_lon: -89.926228,
    shape_pt_sequence: 249,
    shape_dist_traveled: 17.9787,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107392,
    shape_pt_lon: -89.926229,
    shape_pt_sequence: 250,
    shape_dist_traveled: 17.9857,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107352,
    shape_pt_lon: -89.924405,
    shape_pt_sequence: 251,
    shape_dist_traveled: 18.1508,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.10735,
    shape_pt_lon: -89.924306,
    shape_pt_sequence: 252,
    shape_dist_traveled: 18.1598,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107343,
    shape_pt_lon: -89.924121,
    shape_pt_sequence: 253,
    shape_dist_traveled: 18.1758,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107328,
    shape_pt_lon: -89.923551,
    shape_pt_sequence: 254,
    shape_dist_traveled: 18.2279,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.1073,
    shape_pt_lon: -89.922517,
    shape_pt_sequence: 255,
    shape_dist_traveled: 18.3209,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107271,
    shape_pt_lon: -89.921471,
    shape_pt_sequence: 256,
    shape_dist_traveled: 18.416,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107256,
    shape_pt_lon: -89.92088,
    shape_pt_sequence: 257,
    shape_dist_traveled: 18.469,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107235,
    shape_pt_lon: -89.920151,
    shape_pt_sequence: 258,
    shape_dist_traveled: 18.535,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107229,
    shape_pt_lon: -89.91993,
    shape_pt_sequence: 259,
    shape_dist_traveled: 18.5551,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107212,
    shape_pt_lon: -89.919373,
    shape_pt_sequence: 260,
    shape_dist_traveled: 18.6061,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107184,
    shape_pt_lon: -89.918583,
    shape_pt_sequence: 261,
    shape_dist_traveled: 18.6771,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107113,
    shape_pt_lon: -89.915669,
    shape_pt_sequence: 262,
    shape_dist_traveled: 18.9403,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.107071,
    shape_pt_lon: -89.915671,
    shape_pt_sequence: 263,
    shape_dist_traveled: 18.9453,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.106386,
    shape_pt_lon: -89.915701,
    shape_pt_sequence: 264,
    shape_dist_traveled: 19.0213,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.106099,
    shape_pt_lon: -89.915717,
    shape_pt_sequence: 265,
    shape_dist_traveled: 19.0533,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.104407,
    shape_pt_lon: -89.915758,
    shape_pt_sequence: 266,
    shape_dist_traveled: 19.2434,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.102593,
    shape_pt_lon: -89.915814,
    shape_pt_sequence: 267,
    shape_dist_traveled: 19.4464,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.102476,
    shape_pt_lon: -89.91582,
    shape_pt_sequence: 268,
    shape_dist_traveled: 19.4594,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.102176,
    shape_pt_lon: -89.915834,
    shape_pt_sequence: 269,
    shape_dist_traveled: 19.4925,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.102061,
    shape_pt_lon: -89.915838,
    shape_pt_sequence: 270,
    shape_dist_traveled: 19.5055,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.101145,
    shape_pt_lon: -89.915863,
    shape_pt_sequence: 271,
    shape_dist_traveled: 19.6085,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.100215,
    shape_pt_lon: -89.915889,
    shape_pt_sequence: 272,
    shape_dist_traveled: 19.7125,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.099906,
    shape_pt_lon: -89.915898,
    shape_pt_sequence: 273,
    shape_dist_traveled: 19.7465,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.099221,
    shape_pt_lon: -89.915917,
    shape_pt_sequence: 274,
    shape_dist_traveled: 19.8236,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.098823,
    shape_pt_lon: -89.915929,
    shape_pt_sequence: 275,
    shape_dist_traveled: 19.8686,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.097948,
    shape_pt_lon: -89.915951,
    shape_pt_sequence: 276,
    shape_dist_traveled: 19.9656,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.096789,
    shape_pt_lon: -89.915986,
    shape_pt_sequence: 277,
    shape_dist_traveled: 20.0956,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.095979,
    shape_pt_lon: -89.916013,
    shape_pt_sequence: 278,
    shape_dist_traveled: 20.1867,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.095209,
    shape_pt_lon: -89.916047,
    shape_pt_sequence: 279,
    shape_dist_traveled: 20.2727,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.094805,
    shape_pt_lon: -89.915137,
    shape_pt_sequence: 280,
    shape_dist_traveled: 20.3671,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.0945,
    shape_pt_lon: -89.914452,
    shape_pt_sequence: 281,
    shape_dist_traveled: 20.4378,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.093898,
    shape_pt_lon: -89.913118,
    shape_pt_sequence: 282,
    shape_dist_traveled: 20.5753,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.092461,
    shape_pt_lon: -89.909958,
    shape_pt_sequence: 283,
    shape_dist_traveled: 20.9035,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.092377,
    shape_pt_lon: -89.909769,
    shape_pt_sequence: 284,
    shape_dist_traveled: 20.9227,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091511,
    shape_pt_lon: -89.907848,
    shape_pt_sequence: 285,
    shape_dist_traveled: 21.1214,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091459,
    shape_pt_lon: -89.907657,
    shape_pt_sequence: 286,
    shape_dist_traveled: 21.1404,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.09143,
    shape_pt_lon: -89.907433,
    shape_pt_sequence: 287,
    shape_dist_traveled: 21.1606,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091417,
    shape_pt_lon: -89.906945,
    shape_pt_sequence: 288,
    shape_dist_traveled: 21.2047,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091391,
    shape_pt_lon: -89.905881,
    shape_pt_sequence: 289,
    shape_dist_traveled: 21.3007,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091369,
    shape_pt_lon: -89.904788,
    shape_pt_sequence: 290,
    shape_dist_traveled: 21.3998,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091338,
    shape_pt_lon: -89.903489,
    shape_pt_sequence: 291,
    shape_dist_traveled: 21.5168,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091315,
    shape_pt_lon: -89.90238,
    shape_pt_sequence: 292,
    shape_dist_traveled: 21.6178,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091292,
    shape_pt_lon: -89.901328,
    shape_pt_sequence: 293,
    shape_dist_traveled: 21.7129,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091269,
    shape_pt_lon: -89.900266,
    shape_pt_sequence: 294,
    shape_dist_traveled: 21.8089,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091244,
    shape_pt_lon: -89.899214,
    shape_pt_sequence: 295,
    shape_dist_traveled: 21.9039,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091223,
    shape_pt_lon: -89.898159,
    shape_pt_sequence: 296,
    shape_dist_traveled: 21.999,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091199,
    shape_pt_lon: -89.8971,
    shape_pt_sequence: 297,
    shape_dist_traveled: 22.095,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.091179,
    shape_pt_lon: -89.895993,
    shape_pt_sequence: 298,
    shape_dist_traveled: 22.195,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.088038,
    shape_pt_lon: -89.896119,
    shape_pt_sequence: 299,
    shape_dist_traveled: 22.5462,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.086422,
    shape_pt_lon: -89.896177,
    shape_pt_sequence: 300,
    shape_dist_traveled: 22.7272,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.08619,
    shape_pt_lon: -89.896159,
    shape_pt_sequence: 301,
    shape_dist_traveled: 22.7533,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.08591,
    shape_pt_lon: -89.896087,
    shape_pt_sequence: 302,
    shape_dist_traveled: 22.785,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.085769,
    shape_pt_lon: -89.896039,
    shape_pt_sequence: 303,
    shape_dist_traveled: 22.8015,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.085635,
    shape_pt_lon: -89.89598,
    shape_pt_sequence: 304,
    shape_dist_traveled: 22.8173,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.085487,
    shape_pt_lon: -89.895917,
    shape_pt_sequence: 305,
    shape_dist_traveled: 22.8354,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.085353,
    shape_pt_lon: -89.89583,
    shape_pt_sequence: 306,
    shape_dist_traveled: 22.8524,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.085115,
    shape_pt_lon: -89.895628,
    shape_pt_sequence: 307,
    shape_dist_traveled: 22.884,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.084946,
    shape_pt_lon: -89.895439,
    shape_pt_sequence: 308,
    shape_dist_traveled: 22.9102,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.084899,
    shape_pt_lon: -89.895377,
    shape_pt_sequence: 309,
    shape_dist_traveled: 22.9172,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.084737,
    shape_pt_lon: -89.895162,
    shape_pt_sequence: 310,
    shape_dist_traveled: 22.9441,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.084237,
    shape_pt_lon: -89.894383,
    shape_pt_sequence: 311,
    shape_dist_traveled: 23.0338,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083719,
    shape_pt_lon: -89.893563,
    shape_pt_sequence: 312,
    shape_dist_traveled: 23.1278,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083596,
    shape_pt_lon: -89.893374,
    shape_pt_sequence: 313,
    shape_dist_traveled: 23.1506,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083502,
    shape_pt_lon: -89.893227,
    shape_pt_sequence: 314,
    shape_dist_traveled: 23.167,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083479,
    shape_pt_lon: -89.893188,
    shape_pt_sequence: 315,
    shape_dist_traveled: 23.1713,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083409,
    shape_pt_lon: -89.893072,
    shape_pt_sequence: 316,
    shape_dist_traveled: 23.1849,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.083347,
    shape_pt_lon: -89.892973,
    shape_pt_sequence: 317,
    shape_dist_traveled: 23.1963,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.082819,
    shape_pt_lon: -89.892146,
    shape_pt_sequence: 318,
    shape_dist_traveled: 23.2917,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.082239,
    shape_pt_lon: -89.891227,
    shape_pt_sequence: 319,
    shape_dist_traveled: 23.3965,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.082123,
    shape_pt_lon: -89.891045,
    shape_pt_sequence: 320,
    shape_dist_traveled: 23.4171,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.081437,
    shape_pt_lon: -89.889928,
    shape_pt_sequence: 321,
    shape_dist_traveled: 23.5449,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.080882,
    shape_pt_lon: -89.888971,
    shape_pt_sequence: 322,
    shape_dist_traveled: 23.6509,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.080614,
    shape_pt_lon: -89.888537,
    shape_pt_sequence: 323,
    shape_dist_traveled: 23.7009,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.080579,
    shape_pt_lon: -89.88848,
    shape_pt_sequence: 324,
    shape_dist_traveled: 23.7073,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.080566,
    shape_pt_lon: -89.888458,
    shape_pt_sequence: 325,
    shape_dist_traveled: 23.7096,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.080244,
    shape_pt_lon: -89.88793,
    shape_pt_sequence: 326,
    shape_dist_traveled: 23.7696,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.079952,
    shape_pt_lon: -89.887479,
    shape_pt_sequence: 327,
    shape_dist_traveled: 23.8214,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.079792,
    shape_pt_lon: -89.887213,
    shape_pt_sequence: 328,
    shape_dist_traveled: 23.8508,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.07902,
    shape_pt_lon: -89.885949,
    shape_pt_sequence: 329,
    shape_dist_traveled: 23.995,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078889,
    shape_pt_lon: -89.885738,
    shape_pt_sequence: 330,
    shape_dist_traveled: 24.0186,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078693,
    shape_pt_lon: -89.885572,
    shape_pt_sequence: 331,
    shape_dist_traveled: 24.0453,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078607,
    shape_pt_lon: -89.885487,
    shape_pt_sequence: 332,
    shape_dist_traveled: 24.0581,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078457,
    shape_pt_lon: -89.885328,
    shape_pt_sequence: 333,
    shape_dist_traveled: 24.0793,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078222,
    shape_pt_lon: -89.885059,
    shape_pt_sequence: 334,
    shape_dist_traveled: 24.1161,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.078045,
    shape_pt_lon: -89.884917,
    shape_pt_sequence: 335,
    shape_dist_traveled: 24.1386,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.077946,
    shape_pt_lon: -89.884851,
    shape_pt_sequence: 336,
    shape_dist_traveled: 24.152,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.077773,
    shape_pt_lon: -89.884772,
    shape_pt_sequence: 337,
    shape_dist_traveled: 24.1726,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.077646,
    shape_pt_lon: -89.884729,
    shape_pt_sequence: 338,
    shape_dist_traveled: 24.1872,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.077437,
    shape_pt_lon: -89.884692,
    shape_pt_sequence: 339,
    shape_dist_traveled: 24.2104,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.07697,
    shape_pt_lon: -89.884637,
    shape_pt_sequence: 340,
    shape_dist_traveled: 24.2636,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.074988,
    shape_pt_lon: -89.884758,
    shape_pt_sequence: 341,
    shape_dist_traveled: 24.4849,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.07453,
    shape_pt_lon: -89.884783,
    shape_pt_sequence: 342,
    shape_dist_traveled: 24.5369,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.074253,
    shape_pt_lon: -89.884808,
    shape_pt_sequence: 343,
    shape_dist_traveled: 24.568,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.074063,
    shape_pt_lon: -89.88486,
    shape_pt_sequence: 344,
    shape_dist_traveled: 24.5896,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.073839,
    shape_pt_lon: -89.88495,
    shape_pt_sequence: 345,
    shape_dist_traveled: 24.6158,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.073703,
    shape_pt_lon: -89.885023,
    shape_pt_sequence: 346,
    shape_dist_traveled: 24.632,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.073517,
    shape_pt_lon: -89.88514,
    shape_pt_sequence: 347,
    shape_dist_traveled: 24.6557,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.073279,
    shape_pt_lon: -89.885252,
    shape_pt_sequence: 348,
    shape_dist_traveled: 24.6845,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.073106,
    shape_pt_lon: -89.885304,
    shape_pt_sequence: 349,
    shape_dist_traveled: 24.7039,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.072828,
    shape_pt_lon: -89.885341,
    shape_pt_sequence: 350,
    shape_dist_traveled: 24.7352,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.072152,
    shape_pt_lon: -89.885371,
    shape_pt_sequence: 351,
    shape_dist_traveled: 24.8112,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.071548,
    shape_pt_lon: -89.885399,
    shape_pt_sequence: 352,
    shape_dist_traveled: 24.8783,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.071018,
    shape_pt_lon: -89.885423,
    shape_pt_sequence: 353,
    shape_dist_traveled: 24.9383,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.070531,
    shape_pt_lon: -89.885443,
    shape_pt_sequence: 354,
    shape_dist_traveled: 24.9923,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.070451,
    shape_pt_lon: -89.885447,
    shape_pt_sequence: 355,
    shape_dist_traveled: 25.0014,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.07001,
    shape_pt_lon: -89.885469,
    shape_pt_sequence: 356,
    shape_dist_traveled: 25.0514,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.068881,
    shape_pt_lon: -89.885518,
    shape_pt_sequence: 357,
    shape_dist_traveled: 25.1775,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.067433,
    shape_pt_lon: -89.885572,
    shape_pt_sequence: 358,
    shape_dist_traveled: 25.3395,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.066949,
    shape_pt_lon: -89.885591,
    shape_pt_sequence: 359,
    shape_dist_traveled: 25.3935,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.065815,
    shape_pt_lon: -89.885651,
    shape_pt_sequence: 360,
    shape_dist_traveled: 25.5206,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.064422,
    shape_pt_lon: -89.88571,
    shape_pt_sequence: 361,
    shape_dist_traveled: 25.6767,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.063942,
    shape_pt_lon: -89.885729,
    shape_pt_sequence: 362,
    shape_dist_traveled: 25.7308,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.063397,
    shape_pt_lon: -89.88575,
    shape_pt_sequence: 363,
    shape_dist_traveled: 25.7918,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.062806,
    shape_pt_lon: -89.885779,
    shape_pt_sequence: 364,
    shape_dist_traveled: 25.8578,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061909,
    shape_pt_lon: -89.885821,
    shape_pt_sequence: 365,
    shape_dist_traveled: 25.9579,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061872,
    shape_pt_lon: -89.884116,
    shape_pt_sequence: 366,
    shape_dist_traveled: 26.112,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061841,
    shape_pt_lon: -89.883019,
    shape_pt_sequence: 367,
    shape_dist_traveled: 26.212,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061819,
    shape_pt_lon: -89.882783,
    shape_pt_sequence: 368,
    shape_dist_traveled: 26.2332,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061749,
    shape_pt_lon: -89.882429,
    shape_pt_sequence: 369,
    shape_dist_traveled: 26.266,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061642,
    shape_pt_lon: -89.882081,
    shape_pt_sequence: 370,
    shape_dist_traveled: 26.3001,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061326,
    shape_pt_lon: -89.881052,
    shape_pt_sequence: 371,
    shape_dist_traveled: 26.3995,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061197,
    shape_pt_lon: -89.880627,
    shape_pt_sequence: 372,
    shape_dist_traveled: 26.4404,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061149,
    shape_pt_lon: -89.880395,
    shape_pt_sequence: 373,
    shape_dist_traveled: 26.462,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061119,
    shape_pt_lon: -89.880168,
    shape_pt_sequence: 374,
    shape_dist_traveled: 26.4832,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061099,
    shape_pt_lon: -89.879839,
    shape_pt_sequence: 375,
    shape_dist_traveled: 26.5123,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.061024,
    shape_pt_lon: -89.877191,
    shape_pt_sequence: 376,
    shape_dist_traveled: 26.7525,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060997,
    shape_pt_lon: -89.876077,
    shape_pt_sequence: 377,
    shape_dist_traveled: 26.8535,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060938,
    shape_pt_lon: -89.873923,
    shape_pt_sequence: 378,
    shape_dist_traveled: 27.0486,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060905,
    shape_pt_lon: -89.872867,
    shape_pt_sequence: 379,
    shape_dist_traveled: 27.1436,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060861,
    shape_pt_lon: -89.871248,
    shape_pt_sequence: 380,
    shape_dist_traveled: 27.2907,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.06083,
    shape_pt_lon: -89.869978,
    shape_pt_sequence: 381,
    shape_dist_traveled: 27.4048,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060813,
    shape_pt_lon: -89.869286,
    shape_pt_sequence: 382,
    shape_dist_traveled: 27.4678,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.06079,
    shape_pt_lon: -89.8683,
    shape_pt_sequence: 383,
    shape_dist_traveled: 27.5568,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060783,
    shape_pt_lon: -89.868012,
    shape_pt_sequence: 384,
    shape_dist_traveled: 27.5828,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.867051,
    shape_pt_sequence: 385,
    shape_dist_traveled: 27.6698,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060761,
    shape_pt_lon: -89.866779,
    shape_pt_sequence: 386,
    shape_dist_traveled: 27.6949,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060753,
    shape_pt_lon: -89.866473,
    shape_pt_sequence: 387,
    shape_dist_traveled: 27.7219,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060743,
    shape_pt_lon: -89.866096,
    shape_pt_sequence: 388,
    shape_dist_traveled: 27.7569,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060738,
    shape_pt_lon: -89.865916,
    shape_pt_sequence: 389,
    shape_dist_traveled: 27.7729,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060631,
    shape_pt_lon: -89.862548,
    shape_pt_sequence: 390,
    shape_dist_traveled: 28.0771,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060605,
    shape_pt_lon: -89.861638,
    shape_pt_sequence: 391,
    shape_dist_traveled: 28.1602,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060578,
    shape_pt_lon: -89.861196,
    shape_pt_sequence: 392,
    shape_dist_traveled: 28.2003,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060491,
    shape_pt_lon: -89.86034,
    shape_pt_sequence: 393,
    shape_dist_traveled: 28.2779,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060482,
    shape_pt_lon: -89.860254,
    shape_pt_sequence: 394,
    shape_dist_traveled: 28.286,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060346,
    shape_pt_lon: -89.858937,
    shape_pt_sequence: 395,
    shape_dist_traveled: 28.4059,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060298,
    shape_pt_lon: -89.858491,
    shape_pt_sequence: 396,
    shape_dist_traveled: 28.4462,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060169,
    shape_pt_lon: -89.857284,
    shape_pt_sequence: 397,
    shape_dist_traveled: 28.5571,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060155,
    shape_pt_lon: -89.857071,
    shape_pt_sequence: 398,
    shape_dist_traveled: 28.5762,
  },
  {
    shape_id: 38161,
    shape_pt_lat: 35.060093,
    shape_pt_lon: -89.856125,
    shape_pt_sequence: 399,
    shape_dist_traveled: 28.6625,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1863,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.247,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2556,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3647,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4687,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4818,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4872,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5422,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5636,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5699,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.578,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5862,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5988,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7146,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7377,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.769,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8278,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8408,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9499,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0682,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.149142,
    shape_pt_lon: -90.047058,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.1887,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.148739,
    shape_pt_lon: -90.045543,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3319,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.148462,
    shape_pt_lon: -90.044421,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4385,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.148367,
    shape_pt_lon: -90.044008,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4771,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.148265,
    shape_pt_lon: -90.043631,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5129,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.148239,
    shape_pt_lon: -90.043539,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.5224,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.147235,
    shape_pt_lon: -90.04394,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6403,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.146579,
    shape_pt_lon: -90.044184,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.7175,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14622,
    shape_pt_lon: -90.044269,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.7581,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.146177,
    shape_pt_lon: -90.044111,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.773,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.145587,
    shape_pt_lon: -90.041916,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9817,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.145086,
    shape_pt_lon: -90.040042,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.1607,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.144348,
    shape_pt_lon: -90.037283,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.4228,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143821,
    shape_pt_lon: -90.035301,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.6113,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14379,
    shape_pt_lon: -90.035141,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.6259,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143574,
    shape_pt_lon: -90.033031,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.8184,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143506,
    shape_pt_lon: -90.032323,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.8828,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.1434,
    shape_pt_lon: -90.031389,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.9676,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143369,
    shape_pt_lon: -90.03119,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.9861,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143319,
    shape_pt_lon: -90.031011,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.0038,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143097,
    shape_pt_lon: -90.030386,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.0651,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.143057,
    shape_pt_lon: -90.030248,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.0787,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.029973,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.1047,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.1782,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.2235,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.321,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.337,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.4708,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141346,
    shape_pt_lon: -90.027503,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.4949,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141296,
    shape_pt_lon: -90.027016,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.5391,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14123,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.5654,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141198,
    shape_pt_lon: -90.026502,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.5866,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141093,
    shape_pt_lon: -90.025398,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.6873,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142455,
    shape_pt_lon: -90.025199,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.8394,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142346,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.9391,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14227,
    shape_pt_lon: -90.023289,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.0126,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142262,
    shape_pt_lon: -90.023205,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.0206,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.14225,
    shape_pt_lon: -90.023073,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.0327,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.0478,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142226,
    shape_pt_lon: -90.02278,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.0588,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.0669,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142201,
    shape_pt_lon: -90.022537,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.081,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142182,
    shape_pt_lon: -90.022328,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.1001,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.142065,
    shape_pt_lon: -90.021216,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.202,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019836,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.3271,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019838,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.3271,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141846,
    shape_pt_lon: -90.019141,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.3905,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141849,
    shape_pt_lon: -90.019147,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.3905,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -90.018502,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.4487,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141751,
    shape_pt_lon: -90.017871,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.5061,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.141078,
    shape_pt_lon: -90.017969,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.5806,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.140445,
    shape_pt_lon: -90.018055,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.652,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.8039,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138999,
    shape_pt_lon: -90.01727,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.8916,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.9732,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138714,
    shape_pt_lon: -90.0143,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.1625,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138605,
    shape_pt_lon: -90.013143,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.2672,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13849,
    shape_pt_lon: -90.01199,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.372,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13818,
    shape_pt_lon: -90.008773,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.665,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138094,
    shape_pt_lon: -90.007878,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.7466,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138089,
    shape_pt_lon: -90.007822,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.7516,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137942,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.8857,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137906,
    shape_pt_lon: -90.005915,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.9249,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137901,
    shape_pt_lon: -90.005569,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.9569,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137948,
    shape_pt_lon: -90.00516,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.9942,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137978,
    shape_pt_lon: -90.005018,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.0066,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138101,
    shape_pt_lon: -90.004508,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.0556,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138141,
    shape_pt_lon: -90.004185,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.085,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13815,
    shape_pt_lon: -90.004017,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.1001,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13813,
    shape_pt_lon: -90.00361,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.1371,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138118,
    shape_pt_lon: -90.003489,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.1483,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.002431,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.2442,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137993,
    shape_pt_lon: -90.00236,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.2513,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.001117,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.3643,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13769,
    shape_pt_lon: -89.999664,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.4965,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137543,
    shape_pt_lon: -89.998341,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.6177,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137384,
    shape_pt_lon: -89.996914,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.7478,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.137218,
    shape_pt_lon: -89.995446,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.8812,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13706,
    shape_pt_lon: -89.994021,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.0114,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136928,
    shape_pt_lon: -89.992841,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.1193,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136877,
    shape_pt_lon: -89.99232,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.1667,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136836,
    shape_pt_lon: -89.991927,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.2019,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136659,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.3256,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13652,
    shape_pt_lon: -89.990356,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.3512,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136461,
    shape_pt_lon: -89.990297,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.3597,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136131,
    shape_pt_lon: -89.990162,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.3986,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.136076,
    shape_pt_lon: -89.990171,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.4046,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.135951,
    shape_pt_lon: -89.990193,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.4188,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.135477,
    shape_pt_lon: -89.990267,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.4721,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.135363,
    shape_pt_lon: -89.990272,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.4852,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.135136,
    shape_pt_lon: -89.9903,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.5112,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13476,
    shape_pt_lon: -89.990339,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.5534,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13444,
    shape_pt_lon: -89.990366,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.5885,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.6195,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.133149,
    shape_pt_lon: -89.990418,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.7336,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.132197,
    shape_pt_lon: -89.990458,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.8396,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.13213,
    shape_pt_lon: -89.990461,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.8477,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.990477,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.8927,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.130995,
    shape_pt_lon: -89.99051,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.9747,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.130267,
    shape_pt_lon: -89.990533,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.0558,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.129292,
    shape_pt_lon: -89.99058,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.1648,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.129205,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.1749,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.128405,
    shape_pt_lon: -89.990627,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.2649,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.128177,
    shape_pt_lon: -89.990638,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.29,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.127158,
    shape_pt_lon: -89.990685,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.404,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.12672,
    shape_pt_lon: -89.990698,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.4531,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5311,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.125157,
    shape_pt_lon: -89.990778,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6282,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -89.990783,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6382,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.124602,
    shape_pt_lon: -89.990808,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6903,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.12448,
    shape_pt_lon: -89.990813,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.7043,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123689,
    shape_pt_lon: -89.99085,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.7924,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123599,
    shape_pt_lon: -89.990855,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8034,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.122771,
    shape_pt_lon: -89.990897,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.8954,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.121898,
    shape_pt_lon: -89.990937,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.9935,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.121819,
    shape_pt_lon: -89.99094,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.0025,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.99098,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.0996,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.120771,
    shape_pt_lon: -89.990997,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.1196,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11978,
    shape_pt_lon: -89.991073,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.2309,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119642,
    shape_pt_lon: -89.991079,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.2459,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119613,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.3439,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119612,
    shape_pt_lon: -89.989921,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.35,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11959,
    shape_pt_lon: -89.988987,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.435,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119568,
    shape_pt_lon: -89.988087,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.516,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119548,
    shape_pt_lon: -89.987176,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.5981,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119525,
    shape_pt_lon: -89.986309,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.6771,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119507,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.7501,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.7621,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11949,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.8422,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.119018,
    shape_pt_lon: -89.984495,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.8952,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.118696,
    shape_pt_lon: -89.984492,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.9312,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.118194,
    shape_pt_lon: -89.984556,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.9875,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.117735,
    shape_pt_lon: -89.984584,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.0385,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115975,
    shape_pt_lon: -89.984651,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.2356,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115972,
    shape_pt_lon: -89.984588,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.2416,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115969,
    shape_pt_lon: -89.984473,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.2517,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115968,
    shape_pt_lon: -89.984398,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.2587,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115964,
    shape_pt_lon: -89.984279,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.2697,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11589,
    shape_pt_lon: -89.98084,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.5798,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115884,
    shape_pt_lon: -89.980687,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.5938,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115875,
    shape_pt_lon: -89.980394,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.6208,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115863,
    shape_pt_lon: -89.980078,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.6488,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11581,
    shape_pt_lon: -89.977914,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.8449,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115771,
    shape_pt_lon: -89.976156,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.004,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115728,
    shape_pt_lon: -89.974297,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.1721,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115714,
    shape_pt_lon: -89.973502,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.2441,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115689,
    shape_pt_lon: -89.972611,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.3241,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115671,
    shape_pt_lon: -89.971763,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.4012,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115649,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.4972,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115622,
    shape_pt_lon: -89.969623,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.5942,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.1156,
    shape_pt_lon: -89.969098,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.6423,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115579,
    shape_pt_lon: -89.968183,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.7243,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115543,
    shape_pt_lon: -89.966582,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.8694,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115532,
    shape_pt_lon: -89.966054,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.9174,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11546,
    shape_pt_lon: -89.962713,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.2195,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115461,
    shape_pt_lon: -89.962538,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.2355,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115466,
    shape_pt_lon: -89.961886,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.2945,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115449,
    shape_pt_lon: -89.961122,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.3635,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -89.960662,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.4046,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -89.960326,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.4357,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115368,
    shape_pt_lon: -89.958488,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.6017,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115349,
    shape_pt_lon: -89.957581,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.6838,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -89.956647,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.7678,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115282,
    shape_pt_lon: -89.954678,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.9459,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115277,
    shape_pt_lon: -89.954368,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.9739,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115275,
    shape_pt_lon: -89.954263,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.9839,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115249,
    shape_pt_lon: -89.953173,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.0819,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115217,
    shape_pt_lon: -89.951711,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.214,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115183,
    shape_pt_lon: -89.95022,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.349,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115152,
    shape_pt_lon: -89.948766,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.4801,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115133,
    shape_pt_lon: -89.947737,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.5731,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115153,
    shape_pt_lon: -89.947244,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.6182,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115181,
    shape_pt_lon: -89.946951,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.6454,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115233,
    shape_pt_lon: -89.946653,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.672,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115284,
    shape_pt_lon: -89.946429,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.6936,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -89.94583,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.7509,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.758,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.116434,
    shape_pt_lon: -89.9457,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.867,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.117803,
    shape_pt_lon: -89.945644,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.0201,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.11917,
    shape_pt_lon: -89.945591,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.1732,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.120245,
    shape_pt_lon: -89.945548,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.2943,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.120913,
    shape_pt_lon: -89.945522,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.3683,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.121294,
    shape_pt_lon: -89.945507,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.4113,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.122409,
    shape_pt_lon: -89.945453,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.5364,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.6245,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123129,
    shape_pt_lon: -89.943432,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.8027,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123109,
    shape_pt_lon: -89.942734,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.8657,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123098,
    shape_pt_lon: -89.942328,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.9027,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123046,
    shape_pt_lon: -89.940492,
    shape_pt_sequence: 214,
    shape_dist_traveled: 15.0688,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.123011,
    shape_pt_lon: -89.939286,
    shape_pt_sequence: 215,
    shape_dist_traveled: 15.1779,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.122951,
    shape_pt_lon: -89.937246,
    shape_pt_sequence: 216,
    shape_dist_traveled: 15.362,
  },
  {
    shape_id: 38162,
    shape_pt_lat: 35.122925,
    shape_pt_lon: -89.936112,
    shape_pt_sequence: 217,
    shape_dist_traveled: 15.465,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060093,
    shape_pt_lon: -89.856125,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060108,
    shape_pt_lon: -89.855535,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.053,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060122,
    shape_pt_lon: -89.855322,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0721,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060204,
    shape_pt_lon: -89.855028,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1006,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060262,
    shape_pt_lon: -89.854727,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1285,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060369,
    shape_pt_lon: -89.854261,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1722,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060475,
    shape_pt_lon: -89.853719,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2226,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060509,
    shape_pt_lon: -89.85353,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2399,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060512,
    shape_pt_lon: -89.853264,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2639,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060499,
    shape_pt_lon: -89.853047,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.284,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060497,
    shape_pt_lon: -89.852931,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.294,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060469,
    shape_pt_lon: -89.852519,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3321,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060403,
    shape_pt_lon: -89.852116,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.3688,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06018,
    shape_pt_lon: -89.850983,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4748,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060148,
    shape_pt_lon: -89.850614,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5079,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060134,
    shape_pt_lon: -89.85022,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.544,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060133,
    shape_pt_lon: -89.848822,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.67,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060132,
    shape_pt_lon: -89.848761,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.676,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060132,
    shape_pt_lon: -89.848707,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.68,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060133,
    shape_pt_lon: -89.84675,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.857,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060133,
    shape_pt_lon: -89.846538,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.877,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060149,
    shape_pt_lon: -89.846139,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.913,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060179,
    shape_pt_lon: -89.845105,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0061,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060162,
    shape_pt_lon: -89.843898,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1151,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060161,
    shape_pt_lon: -89.843858,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1191,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060161,
    shape_pt_lon: -89.843779,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.1261,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.060162,
    shape_pt_lon: -89.8437,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.1331,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.061321,
    shape_pt_lon: -89.843676,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2621,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.061501,
    shape_pt_lon: -89.843672,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2831,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.062079,
    shape_pt_lon: -89.843667,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3471,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.062667,
    shape_pt_lon: -89.843663,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4131,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06299,
    shape_pt_lon: -89.843659,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4491,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06426,
    shape_pt_lon: -89.843632,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5911,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064258,
    shape_pt_lon: -89.84372,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5991,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064258,
    shape_pt_lon: -89.843801,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6061,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064257,
    shape_pt_lon: -89.843843,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.6101,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06426,
    shape_pt_lon: -89.844532,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6721,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064292,
    shape_pt_lon: -89.845039,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7183,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064303,
    shape_pt_lon: -89.845149,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7284,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06437,
    shape_pt_lon: -89.845551,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.765,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064412,
    shape_pt_lon: -89.845747,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.7837,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064497,
    shape_pt_lon: -89.846061,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.8131,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064607,
    shape_pt_lon: -89.846398,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.8464,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064758,
    shape_pt_lon: -89.846759,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8835,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.064882,
    shape_pt_lon: -89.84701,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.9096,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.065029,
    shape_pt_lon: -89.847266,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.9376,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06521,
    shape_pt_lon: -89.847541,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.9702,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.065379,
    shape_pt_lon: -89.84777,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.9986,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.065601,
    shape_pt_lon: -89.848028,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.0325,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.065815,
    shape_pt_lon: -89.848233,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.0625,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066163,
    shape_pt_lon: -89.848569,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.1123,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066365,
    shape_pt_lon: -89.848791,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.1428,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066411,
    shape_pt_lon: -89.848848,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.1499,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066458,
    shape_pt_lon: -89.84891,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.1577,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066492,
    shape_pt_lon: -89.848954,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.1633,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.066741,
    shape_pt_lon: -89.849318,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.2066,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06691,
    shape_pt_lon: -89.849617,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2391,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.067053,
    shape_pt_lon: -89.849919,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.2705,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.067091,
    shape_pt_lon: -89.850001,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.2791,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.067322,
    shape_pt_lon: -89.850571,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.3368,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.06777,
    shape_pt_lon: -89.851728,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.4531,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.068329,
    shape_pt_lon: -89.853143,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.5953,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.068623,
    shape_pt_lon: -89.853896,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.6709,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.068645,
    shape_pt_lon: -89.853949,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.6767,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.068663,
    shape_pt_lon: -89.853996,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.6812,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.069553,
    shape_pt_lon: -89.856252,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.9079,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.070886,
    shape_pt_lon: -89.85965,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.2496,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.071411,
    shape_pt_lon: -89.860998,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.3851,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.071857,
    shape_pt_lon: -89.86213,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.4983,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.072157,
    shape_pt_lon: -89.862856,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.5726,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.072349,
    shape_pt_lon: -89.863341,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.6213,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.072409,
    shape_pt_lon: -89.863497,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.637,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.072616,
    shape_pt_lon: -89.864017,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.6893,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.072904,
    shape_pt_lon: -89.86476,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.7644,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.073162,
    shape_pt_lon: -89.865407,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.8293,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.073416,
    shape_pt_lon: -89.865919,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.884,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.073642,
    shape_pt_lon: -89.866358,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9303,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.073855,
    shape_pt_lon: -89.866797,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.977,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.073989,
    shape_pt_lon: -89.867148,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.0123,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074114,
    shape_pt_lon: -89.867583,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.0537,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074173,
    shape_pt_lon: -89.86784,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.0775,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07423,
    shape_pt_lon: -89.868177,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.1093,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07424,
    shape_pt_lon: -89.868256,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.1164,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07428,
    shape_pt_lon: -89.868707,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.1576,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074289,
    shape_pt_lon: -89.869051,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.1886,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.0743,
    shape_pt_lon: -89.869761,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.2526,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074317,
    shape_pt_lon: -89.870931,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.3586,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074318,
    shape_pt_lon: -89.870981,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.3626,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074319,
    shape_pt_lon: -89.871024,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.3666,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074327,
    shape_pt_lon: -89.871663,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.4246,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074339,
    shape_pt_lon: -89.872548,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.5046,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074348,
    shape_pt_lon: -89.873198,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.5636,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07436,
    shape_pt_lon: -89.873887,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.6256,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074382,
    shape_pt_lon: -89.875438,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.7666,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.0744,
    shape_pt_lon: -89.876353,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.8487,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074419,
    shape_pt_lon: -89.876742,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.8837,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074432,
    shape_pt_lon: -89.877015,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.9088,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074439,
    shape_pt_lon: -89.877133,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.9198,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07449,
    shape_pt_lon: -89.877634,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.9652,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074546,
    shape_pt_lon: -89.877886,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.989,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074638,
    shape_pt_lon: -89.878287,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.0266,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074746,
    shape_pt_lon: -89.878662,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.0627,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074842,
    shape_pt_lon: -89.878961,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.0915,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.074945,
    shape_pt_lon: -89.879228,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.1183,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.075179,
    shape_pt_lon: -89.879758,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.1729,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07534,
    shape_pt_lon: -89.880076,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.207,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.075559,
    shape_pt_lon: -89.880429,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.247,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.075721,
    shape_pt_lon: -89.88069,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.277,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.076228,
    shape_pt_lon: -89.881492,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.3689,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.076497,
    shape_pt_lon: -89.881917,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.4181,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.076813,
    shape_pt_lon: -89.882416,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.4751,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.883392,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.5877,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.077648,
    shape_pt_lon: -89.883728,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.6261,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.078056,
    shape_pt_lon: -89.884395,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.7017,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.078889,
    shape_pt_lon: -89.885738,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.8551,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.07902,
    shape_pt_lon: -89.885949,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.8787,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.079792,
    shape_pt_lon: -89.887213,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.0229,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.079952,
    shape_pt_lon: -89.887479,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.0523,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.080244,
    shape_pt_lon: -89.88793,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.1042,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.080566,
    shape_pt_lon: -89.888458,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.1642,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.080579,
    shape_pt_lon: -89.88848,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.1664,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.080614,
    shape_pt_lon: -89.888537,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.1728,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.080882,
    shape_pt_lon: -89.888971,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.2228,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.081437,
    shape_pt_lon: -89.889928,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.3288,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.082123,
    shape_pt_lon: -89.891045,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.4566,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.082239,
    shape_pt_lon: -89.891227,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.4772,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.082819,
    shape_pt_lon: -89.892146,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.5821,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083347,
    shape_pt_lon: -89.892973,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.6775,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083409,
    shape_pt_lon: -89.893072,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.6889,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083479,
    shape_pt_lon: -89.893188,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.7025,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083502,
    shape_pt_lon: -89.893227,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.7067,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083596,
    shape_pt_lon: -89.893374,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.7231,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.083719,
    shape_pt_lon: -89.893563,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.7459,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.084237,
    shape_pt_lon: -89.894383,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.84,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.084737,
    shape_pt_lon: -89.895162,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.9296,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.084899,
    shape_pt_lon: -89.895377,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.9565,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.084946,
    shape_pt_lon: -89.895439,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.9636,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.085115,
    shape_pt_lon: -89.895628,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.9898,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.085353,
    shape_pt_lon: -89.89583,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.0214,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.085487,
    shape_pt_lon: -89.895917,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.0384,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.085635,
    shape_pt_lon: -89.89598,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.0564,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.085769,
    shape_pt_lon: -89.896039,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.0722,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.08591,
    shape_pt_lon: -89.896087,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.0887,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.08619,
    shape_pt_lon: -89.896159,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.1205,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.086422,
    shape_pt_lon: -89.896177,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.1465,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.088038,
    shape_pt_lon: -89.896119,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.3275,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091179,
    shape_pt_lon: -89.895993,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.6787,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091199,
    shape_pt_lon: -89.8971,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.7787,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091223,
    shape_pt_lon: -89.898159,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.8748,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091244,
    shape_pt_lon: -89.899214,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.9698,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091269,
    shape_pt_lon: -89.900266,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.0649,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091292,
    shape_pt_lon: -89.901328,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.1609,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091315,
    shape_pt_lon: -89.90238,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.2559,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091338,
    shape_pt_lon: -89.903489,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.3569,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091369,
    shape_pt_lon: -89.904788,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.474,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091391,
    shape_pt_lon: -89.905881,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.573,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091417,
    shape_pt_lon: -89.906945,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.669,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.09143,
    shape_pt_lon: -89.907433,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.7131,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091459,
    shape_pt_lon: -89.907657,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.7333,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.091511,
    shape_pt_lon: -89.907848,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.7523,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.092377,
    shape_pt_lon: -89.909769,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.951,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.092461,
    shape_pt_lon: -89.909958,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.9703,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.093898,
    shape_pt_lon: -89.913118,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.2985,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.0945,
    shape_pt_lon: -89.914452,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.4359,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.094805,
    shape_pt_lon: -89.915137,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.5066,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.095209,
    shape_pt_lon: -89.916047,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.601,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.095979,
    shape_pt_lon: -89.916013,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.6871,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.096789,
    shape_pt_lon: -89.915986,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.7781,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.097948,
    shape_pt_lon: -89.915951,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.9082,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.098823,
    shape_pt_lon: -89.915929,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.0052,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.099221,
    shape_pt_lon: -89.915917,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.0502,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.099906,
    shape_pt_lon: -89.915898,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.1272,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.100215,
    shape_pt_lon: -89.915889,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.1612,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.101145,
    shape_pt_lon: -89.915863,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.2652,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.102061,
    shape_pt_lon: -89.915838,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.3682,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.102176,
    shape_pt_lon: -89.915834,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.3812,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.102476,
    shape_pt_lon: -89.91582,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.4143,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.102593,
    shape_pt_lon: -89.915814,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.4273,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.104407,
    shape_pt_lon: -89.915758,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.6304,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.106099,
    shape_pt_lon: -89.915717,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.8204,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.106386,
    shape_pt_lon: -89.915701,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.8525,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107071,
    shape_pt_lon: -89.915671,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.9285,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107113,
    shape_pt_lon: -89.915669,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.9335,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107184,
    shape_pt_lon: -89.918583,
    shape_pt_sequence: 184,
    shape_dist_traveled: 11.1966,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107212,
    shape_pt_lon: -89.919373,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.2677,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107229,
    shape_pt_lon: -89.91993,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.3187,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107235,
    shape_pt_lon: -89.920151,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.3387,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107256,
    shape_pt_lon: -89.92088,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.4047,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107271,
    shape_pt_lon: -89.921471,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.4578,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.1073,
    shape_pt_lon: -89.922517,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.5528,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107328,
    shape_pt_lon: -89.923551,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.6459,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107343,
    shape_pt_lon: -89.924121,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.6979,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.10735,
    shape_pt_lon: -89.924306,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.7139,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107352,
    shape_pt_lon: -89.924405,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.723,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.107392,
    shape_pt_lon: -89.926229,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.888,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.10746,
    shape_pt_lon: -89.926228,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.895,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.109713,
    shape_pt_lon: -89.926199,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.148,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.112969,
    shape_pt_lon: -89.926057,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.5122,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11492,
    shape_pt_lon: -89.925974,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.7304,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115304,
    shape_pt_lon: -89.925976,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.7734,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11573,
    shape_pt_lon: -89.926011,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.8215,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115937,
    shape_pt_lon: -89.926003,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.8445,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116109,
    shape_pt_lon: -89.925999,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.8635,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116529,
    shape_pt_lon: -89.925992,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.9105,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.117359,
    shape_pt_lon: -89.925969,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.0035,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11895,
    shape_pt_lon: -89.925926,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.1815,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119714,
    shape_pt_lon: -89.925907,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.2676,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119906,
    shape_pt_lon: -89.9259,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.2886,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120112,
    shape_pt_lon: -89.925895,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.3116,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120549,
    shape_pt_lon: -89.925879,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.3606,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120811,
    shape_pt_lon: -89.92587,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.3896,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.121753,
    shape_pt_lon: -89.925841,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.4956,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122118,
    shape_pt_lon: -89.925828,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.5367,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122643,
    shape_pt_lon: -89.925813,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.5947,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.12266,
    shape_pt_lon: -89.926458,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.6537,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122662,
    shape_pt_lon: -89.926531,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.6597,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122721,
    shape_pt_lon: -89.928587,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.8459,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122745,
    shape_pt_lon: -89.929746,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.9509,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122751,
    shape_pt_lon: -89.930122,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.9849,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -89.931146,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.0769,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.12285,
    shape_pt_lon: -89.933601,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.2991,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122851,
    shape_pt_lon: -89.933669,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.3051,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.121394,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.4681,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120237,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.5982,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11965,
    shape_pt_lon: -89.93377,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.6642,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116576,
    shape_pt_lon: -89.93388,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.0083,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.02,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116411,
    shape_pt_lon: -89.933976,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.0286,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116361,
    shape_pt_lon: -89.93408,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.0394,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116337,
    shape_pt_lon: -89.934203,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.0506,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116391,
    shape_pt_lon: -89.936804,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.2867,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.940768,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.6448,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116482,
    shape_pt_lon: -89.941191,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.6828,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116489,
    shape_pt_lon: -89.941728,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.7318,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116479,
    shape_pt_lon: -89.942043,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.7598,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116443,
    shape_pt_lon: -89.942421,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.7942,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116397,
    shape_pt_lon: -89.942782,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.8276,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116289,
    shape_pt_lon: -89.943202,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.8674,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116219,
    shape_pt_lon: -89.943486,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.8937,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116092,
    shape_pt_lon: -89.943931,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.937,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115899,
    shape_pt_lon: -89.944468,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.9898,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115511,
    shape_pt_lon: -89.945551,
    shape_pt_sequence: 242,
    shape_dist_traveled: 16.0968,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 243,
    shape_dist_traveled: 16.1158,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -89.94583,
    shape_pt_sequence: 244,
    shape_dist_traveled: 16.1229,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115284,
    shape_pt_lon: -89.946429,
    shape_pt_sequence: 245,
    shape_dist_traveled: 16.1801,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115233,
    shape_pt_lon: -89.946653,
    shape_pt_sequence: 246,
    shape_dist_traveled: 16.2017,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115181,
    shape_pt_lon: -89.946951,
    shape_pt_sequence: 247,
    shape_dist_traveled: 16.2284,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115153,
    shape_pt_lon: -89.947244,
    shape_pt_sequence: 248,
    shape_dist_traveled: 16.2555,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115133,
    shape_pt_lon: -89.947737,
    shape_pt_sequence: 249,
    shape_dist_traveled: 16.3006,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115152,
    shape_pt_lon: -89.948766,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.3937,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115183,
    shape_pt_lon: -89.95022,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.5247,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115217,
    shape_pt_lon: -89.951711,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.6598,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115249,
    shape_pt_lon: -89.953173,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.7918,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115275,
    shape_pt_lon: -89.954263,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.8898,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115277,
    shape_pt_lon: -89.954368,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.8999,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115282,
    shape_pt_lon: -89.954678,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.9279,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -89.956647,
    shape_pt_sequence: 257,
    shape_dist_traveled: 17.106,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115349,
    shape_pt_lon: -89.957581,
    shape_pt_sequence: 258,
    shape_dist_traveled: 17.19,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115368,
    shape_pt_lon: -89.958488,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.272,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -89.960326,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.4381,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -89.960662,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.4691,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115449,
    shape_pt_lon: -89.961122,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.5102,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115466,
    shape_pt_lon: -89.961886,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.5792,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115461,
    shape_pt_lon: -89.962538,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.6382,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11546,
    shape_pt_lon: -89.962713,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.6542,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115532,
    shape_pt_lon: -89.966054,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.9563,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115543,
    shape_pt_lon: -89.966582,
    shape_pt_sequence: 267,
    shape_dist_traveled: 18.0043,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115579,
    shape_pt_lon: -89.968183,
    shape_pt_sequence: 268,
    shape_dist_traveled: 18.1494,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.1156,
    shape_pt_lon: -89.969098,
    shape_pt_sequence: 269,
    shape_dist_traveled: 18.2314,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115622,
    shape_pt_lon: -89.969623,
    shape_pt_sequence: 270,
    shape_dist_traveled: 18.2795,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115649,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 271,
    shape_dist_traveled: 18.3766,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115671,
    shape_pt_lon: -89.971763,
    shape_pt_sequence: 272,
    shape_dist_traveled: 18.4726,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115689,
    shape_pt_lon: -89.972611,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.5496,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115714,
    shape_pt_lon: -89.973502,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.6297,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115728,
    shape_pt_lon: -89.974297,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.7017,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115771,
    shape_pt_lon: -89.976156,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.8698,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11581,
    shape_pt_lon: -89.977914,
    shape_pt_sequence: 277,
    shape_dist_traveled: 19.0288,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115863,
    shape_pt_lon: -89.980078,
    shape_pt_sequence: 278,
    shape_dist_traveled: 19.2249,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115875,
    shape_pt_lon: -89.980394,
    shape_pt_sequence: 279,
    shape_dist_traveled: 19.2529,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115884,
    shape_pt_lon: -89.980687,
    shape_pt_sequence: 280,
    shape_dist_traveled: 19.2799,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11589,
    shape_pt_lon: -89.98084,
    shape_pt_sequence: 281,
    shape_dist_traveled: 19.294,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.115964,
    shape_pt_lon: -89.984279,
    shape_pt_sequence: 282,
    shape_dist_traveled: 19.6041,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.116155,
    shape_pt_lon: -89.984277,
    shape_pt_sequence: 283,
    shape_dist_traveled: 19.6251,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.117731,
    shape_pt_lon: -89.984247,
    shape_pt_sequence: 284,
    shape_dist_traveled: 19.8011,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11869,
    shape_pt_lon: -89.984292,
    shape_pt_sequence: 285,
    shape_dist_traveled: 19.9092,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.118836,
    shape_pt_lon: -89.984293,
    shape_pt_sequence: 286,
    shape_dist_traveled: 19.9252,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119485,
    shape_pt_lon: -89.98426,
    shape_pt_sequence: 287,
    shape_dist_traveled: 19.9982,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119489,
    shape_pt_lon: -89.984402,
    shape_pt_sequence: 288,
    shape_dist_traveled: 20.0112,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11949,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 289,
    shape_dist_traveled: 20.0182,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 290,
    shape_dist_traveled: 20.0982,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119507,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 291,
    shape_dist_traveled: 20.1102,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119525,
    shape_pt_lon: -89.986309,
    shape_pt_sequence: 292,
    shape_dist_traveled: 20.1833,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119548,
    shape_pt_lon: -89.987176,
    shape_pt_sequence: 293,
    shape_dist_traveled: 20.2623,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119568,
    shape_pt_lon: -89.988087,
    shape_pt_sequence: 294,
    shape_dist_traveled: 20.3444,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11959,
    shape_pt_lon: -89.988987,
    shape_pt_sequence: 295,
    shape_dist_traveled: 20.4254,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119612,
    shape_pt_lon: -89.989921,
    shape_pt_sequence: 296,
    shape_dist_traveled: 20.5104,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119613,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 297,
    shape_dist_traveled: 20.5165,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.119642,
    shape_pt_lon: -89.991079,
    shape_pt_sequence: 298,
    shape_dist_traveled: 20.6145,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.11978,
    shape_pt_lon: -89.991073,
    shape_pt_sequence: 299,
    shape_dist_traveled: 20.6295,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120771,
    shape_pt_lon: -89.990997,
    shape_pt_sequence: 300,
    shape_dist_traveled: 20.7407,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.99098,
    shape_pt_sequence: 301,
    shape_dist_traveled: 20.7608,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.121819,
    shape_pt_lon: -89.99094,
    shape_pt_sequence: 302,
    shape_dist_traveled: 20.8579,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.121898,
    shape_pt_lon: -89.990937,
    shape_pt_sequence: 303,
    shape_dist_traveled: 20.8669,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.122771,
    shape_pt_lon: -89.990897,
    shape_pt_sequence: 304,
    shape_dist_traveled: 20.9649,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.123599,
    shape_pt_lon: -89.990855,
    shape_pt_sequence: 305,
    shape_dist_traveled: 21.057,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.123689,
    shape_pt_lon: -89.99085,
    shape_pt_sequence: 306,
    shape_dist_traveled: 21.068,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.12448,
    shape_pt_lon: -89.990813,
    shape_pt_sequence: 307,
    shape_dist_traveled: 21.1561,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.124602,
    shape_pt_lon: -89.990808,
    shape_pt_sequence: 308,
    shape_dist_traveled: 21.1701,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -89.990783,
    shape_pt_sequence: 309,
    shape_dist_traveled: 21.2221,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.125157,
    shape_pt_lon: -89.990778,
    shape_pt_sequence: 310,
    shape_dist_traveled: 21.2321,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 311,
    shape_dist_traveled: 21.3293,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.12672,
    shape_pt_lon: -89.990698,
    shape_pt_sequence: 312,
    shape_dist_traveled: 21.4073,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.127158,
    shape_pt_lon: -89.990685,
    shape_pt_sequence: 313,
    shape_dist_traveled: 21.4563,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.128177,
    shape_pt_lon: -89.990638,
    shape_pt_sequence: 314,
    shape_dist_traveled: 21.5704,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.128405,
    shape_pt_lon: -89.990627,
    shape_pt_sequence: 315,
    shape_dist_traveled: 21.5954,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.129205,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 316,
    shape_dist_traveled: 21.6855,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.129292,
    shape_pt_lon: -89.99058,
    shape_pt_sequence: 317,
    shape_dist_traveled: 21.6955,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.130267,
    shape_pt_lon: -89.990533,
    shape_pt_sequence: 318,
    shape_dist_traveled: 21.8046,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.130995,
    shape_pt_lon: -89.99051,
    shape_pt_sequence: 319,
    shape_dist_traveled: 21.8856,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.990477,
    shape_pt_sequence: 320,
    shape_dist_traveled: 21.9677,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13213,
    shape_pt_lon: -89.990461,
    shape_pt_sequence: 321,
    shape_dist_traveled: 22.0127,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.132197,
    shape_pt_lon: -89.990458,
    shape_pt_sequence: 322,
    shape_dist_traveled: 22.0208,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.133149,
    shape_pt_lon: -89.990418,
    shape_pt_sequence: 323,
    shape_dist_traveled: 22.1268,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 324,
    shape_dist_traveled: 22.2409,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13444,
    shape_pt_lon: -89.990366,
    shape_pt_sequence: 325,
    shape_dist_traveled: 22.2719,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13476,
    shape_pt_lon: -89.990339,
    shape_pt_sequence: 326,
    shape_dist_traveled: 22.3069,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.135136,
    shape_pt_lon: -89.9903,
    shape_pt_sequence: 327,
    shape_dist_traveled: 22.3491,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.135363,
    shape_pt_lon: -89.990272,
    shape_pt_sequence: 328,
    shape_dist_traveled: 22.3752,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.135477,
    shape_pt_lon: -89.990267,
    shape_pt_sequence: 329,
    shape_dist_traveled: 22.3883,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.135951,
    shape_pt_lon: -89.990193,
    shape_pt_sequence: 330,
    shape_dist_traveled: 22.4416,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136076,
    shape_pt_lon: -89.990171,
    shape_pt_sequence: 331,
    shape_dist_traveled: 22.4557,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136131,
    shape_pt_lon: -89.990162,
    shape_pt_sequence: 332,
    shape_dist_traveled: 22.4618,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136598,
    shape_pt_lon: -89.990099,
    shape_pt_sequence: 333,
    shape_dist_traveled: 22.5142,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136659,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 334,
    shape_dist_traveled: 22.5587,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136836,
    shape_pt_lon: -89.991927,
    shape_pt_sequence: 335,
    shape_dist_traveled: 22.6823,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136877,
    shape_pt_lon: -89.99232,
    shape_pt_sequence: 336,
    shape_dist_traveled: 22.7176,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.136928,
    shape_pt_lon: -89.992841,
    shape_pt_sequence: 337,
    shape_dist_traveled: 22.765,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13706,
    shape_pt_lon: -89.994021,
    shape_pt_sequence: 338,
    shape_dist_traveled: 22.8729,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137218,
    shape_pt_lon: -89.995446,
    shape_pt_sequence: 339,
    shape_dist_traveled: 23.0031,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137384,
    shape_pt_lon: -89.996914,
    shape_pt_sequence: 340,
    shape_dist_traveled: 23.1365,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137543,
    shape_pt_lon: -89.998341,
    shape_pt_sequence: 341,
    shape_dist_traveled: 23.2666,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13769,
    shape_pt_lon: -89.999664,
    shape_pt_sequence: 342,
    shape_dist_traveled: 23.3878,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.001117,
    shape_pt_sequence: 343,
    shape_dist_traveled: 23.52,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137993,
    shape_pt_lon: -90.00236,
    shape_pt_sequence: 344,
    shape_dist_traveled: 23.633,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.002431,
    shape_pt_sequence: 345,
    shape_dist_traveled: 23.6401,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138118,
    shape_pt_lon: -90.003489,
    shape_pt_sequence: 346,
    shape_dist_traveled: 23.736,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13813,
    shape_pt_lon: -90.00361,
    shape_pt_sequence: 347,
    shape_dist_traveled: 23.7472,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13815,
    shape_pt_lon: -90.004017,
    shape_pt_sequence: 348,
    shape_dist_traveled: 23.7842,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138141,
    shape_pt_lon: -90.004185,
    shape_pt_sequence: 349,
    shape_dist_traveled: 23.7992,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138101,
    shape_pt_lon: -90.004508,
    shape_pt_sequence: 350,
    shape_dist_traveled: 23.8287,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137978,
    shape_pt_lon: -90.005018,
    shape_pt_sequence: 351,
    shape_dist_traveled: 23.8777,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137948,
    shape_pt_lon: -90.00516,
    shape_pt_sequence: 352,
    shape_dist_traveled: 23.8901,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137901,
    shape_pt_lon: -90.005569,
    shape_pt_sequence: 353,
    shape_dist_traveled: 23.9274,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137906,
    shape_pt_lon: -90.005915,
    shape_pt_sequence: 354,
    shape_dist_traveled: 23.9594,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.137942,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 355,
    shape_dist_traveled: 23.9986,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138089,
    shape_pt_lon: -90.007822,
    shape_pt_sequence: 356,
    shape_dist_traveled: 24.1327,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138094,
    shape_pt_lon: -90.007878,
    shape_pt_sequence: 357,
    shape_dist_traveled: 24.1377,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13818,
    shape_pt_lon: -90.008773,
    shape_pt_sequence: 358,
    shape_dist_traveled: 24.2193,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.13849,
    shape_pt_lon: -90.01199,
    shape_pt_sequence: 359,
    shape_dist_traveled: 24.5123,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138605,
    shape_pt_lon: -90.013143,
    shape_pt_sequence: 360,
    shape_dist_traveled: 24.6171,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138714,
    shape_pt_lon: -90.0143,
    shape_pt_sequence: 361,
    shape_dist_traveled: 24.7218,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 362,
    shape_dist_traveled: 24.9111,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.138999,
    shape_pt_lon: -90.01727,
    shape_pt_sequence: 363,
    shape_dist_traveled: 24.9927,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 364,
    shape_dist_traveled: 25.0804,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.140445,
    shape_pt_lon: -90.018055,
    shape_pt_sequence: 365,
    shape_dist_traveled: 25.2322,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141078,
    shape_pt_lon: -90.017969,
    shape_pt_sequence: 366,
    shape_dist_traveled: 25.3037,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141751,
    shape_pt_lon: -90.017871,
    shape_pt_sequence: 367,
    shape_dist_traveled: 25.3782,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -90.018502,
    shape_pt_sequence: 368,
    shape_dist_traveled: 25.4355,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141849,
    shape_pt_lon: -90.019147,
    shape_pt_sequence: 369,
    shape_dist_traveled: 25.4938,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141846,
    shape_pt_lon: -90.019141,
    shape_pt_sequence: 370,
    shape_dist_traveled: 25.4938,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019838,
    shape_pt_sequence: 371,
    shape_dist_traveled: 25.5571,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019836,
    shape_pt_sequence: 372,
    shape_dist_traveled: 25.5571,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142065,
    shape_pt_lon: -90.021216,
    shape_pt_sequence: 373,
    shape_dist_traveled: 25.6823,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142182,
    shape_pt_lon: -90.022328,
    shape_pt_sequence: 374,
    shape_dist_traveled: 25.7841,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142201,
    shape_pt_lon: -90.022537,
    shape_pt_sequence: 375,
    shape_dist_traveled: 25.8032,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 376,
    shape_dist_traveled: 25.8174,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142226,
    shape_pt_lon: -90.02278,
    shape_pt_sequence: 377,
    shape_dist_traveled: 25.8254,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 378,
    shape_dist_traveled: 25.8365,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14225,
    shape_pt_lon: -90.023073,
    shape_pt_sequence: 379,
    shape_dist_traveled: 25.8516,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142262,
    shape_pt_lon: -90.023205,
    shape_pt_sequence: 380,
    shape_dist_traveled: 25.8637,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14227,
    shape_pt_lon: -90.023289,
    shape_pt_sequence: 381,
    shape_dist_traveled: 25.8717,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142346,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 382,
    shape_dist_traveled: 25.9452,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142455,
    shape_pt_lon: -90.025199,
    shape_pt_sequence: 383,
    shape_dist_traveled: 26.0449,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141093,
    shape_pt_lon: -90.025398,
    shape_pt_sequence: 384,
    shape_dist_traveled: 26.197,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141198,
    shape_pt_lon: -90.026502,
    shape_pt_sequence: 385,
    shape_dist_traveled: 26.2977,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14123,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 386,
    shape_dist_traveled: 26.3189,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141296,
    shape_pt_lon: -90.027016,
    shape_pt_sequence: 387,
    shape_dist_traveled: 26.3451,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.141346,
    shape_pt_lon: -90.027503,
    shape_pt_sequence: 388,
    shape_dist_traveled: 26.3894,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 389,
    shape_dist_traveled: 26.4135,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 390,
    shape_dist_traveled: 26.5472,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 391,
    shape_dist_traveled: 26.5632,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 392,
    shape_dist_traveled: 26.6608,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 393,
    shape_dist_traveled: 26.706,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.029973,
    shape_pt_sequence: 394,
    shape_dist_traveled: 26.7796,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143057,
    shape_pt_lon: -90.030248,
    shape_pt_sequence: 395,
    shape_dist_traveled: 26.8056,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143097,
    shape_pt_lon: -90.030386,
    shape_pt_sequence: 396,
    shape_dist_traveled: 26.8192,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143319,
    shape_pt_lon: -90.031011,
    shape_pt_sequence: 397,
    shape_dist_traveled: 26.8805,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143369,
    shape_pt_lon: -90.03119,
    shape_pt_sequence: 398,
    shape_dist_traveled: 26.8982,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.1434,
    shape_pt_lon: -90.031389,
    shape_pt_sequence: 399,
    shape_dist_traveled: 26.9167,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143506,
    shape_pt_lon: -90.032323,
    shape_pt_sequence: 400,
    shape_dist_traveled: 27.0015,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143574,
    shape_pt_lon: -90.033031,
    shape_pt_sequence: 401,
    shape_dist_traveled: 27.0659,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14379,
    shape_pt_lon: -90.035141,
    shape_pt_sequence: 402,
    shape_dist_traveled: 27.2584,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.143821,
    shape_pt_lon: -90.035301,
    shape_pt_sequence: 403,
    shape_dist_traveled: 27.273,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.144348,
    shape_pt_lon: -90.037283,
    shape_pt_sequence: 404,
    shape_dist_traveled: 27.4614,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.145086,
    shape_pt_lon: -90.040042,
    shape_pt_sequence: 405,
    shape_dist_traveled: 27.7236,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.145587,
    shape_pt_lon: -90.041916,
    shape_pt_sequence: 406,
    shape_dist_traveled: 27.9026,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.146177,
    shape_pt_lon: -90.044111,
    shape_pt_sequence: 407,
    shape_dist_traveled: 28.1113,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14622,
    shape_pt_lon: -90.044269,
    shape_pt_sequence: 408,
    shape_dist_traveled: 28.1262,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.146235,
    shape_pt_lon: -90.044336,
    shape_pt_sequence: 409,
    shape_dist_traveled: 28.1325,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.146284,
    shape_pt_lon: -90.044558,
    shape_pt_sequence: 410,
    shape_dist_traveled: 28.1531,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.146758,
    shape_pt_lon: -90.046337,
    shape_pt_sequence: 411,
    shape_dist_traveled: 28.3226,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 412,
    shape_dist_traveled: 28.5873,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 413,
    shape_dist_traveled: 28.7049,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 414,
    shape_dist_traveled: 28.7606,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 415,
    shape_dist_traveled: 28.8207,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 416,
    shape_dist_traveled: 28.9383,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 417,
    shape_dist_traveled: 29.0547,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 418,
    shape_dist_traveled: 29.1679,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 419,
    shape_dist_traveled: 29.1778,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 420,
    shape_dist_traveled: 29.2003,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 421,
    shape_dist_traveled: 29.212,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 422,
    shape_dist_traveled: 29.2591,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 423,
    shape_dist_traveled: 29.2861,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 424,
    shape_dist_traveled: 29.3016,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 425,
    shape_dist_traveled: 29.406,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 426,
    shape_dist_traveled: 29.5176,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 427,
    shape_dist_traveled: 29.5249,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 428,
    shape_dist_traveled: 29.5954,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 429,
    shape_dist_traveled: 29.6425,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 430,
    shape_dist_traveled: 29.7023,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 431,
    shape_dist_traveled: 29.7251,
  },
  {
    shape_id: 38163,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 432,
    shape_dist_traveled: 29.7915,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.122925,
    shape_pt_lon: -89.936112,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.122886,
    shape_pt_lon: -89.934812,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1171,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.122851,
    shape_pt_lon: -89.933669,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2212,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.121394,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.3842,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.120237,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.5142,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11965,
    shape_pt_lon: -89.93377,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.5803,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116576,
    shape_pt_lon: -89.93388,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.9244,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.9361,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116411,
    shape_pt_lon: -89.933976,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.9447,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116361,
    shape_pt_lon: -89.93408,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.9555,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116337,
    shape_pt_lon: -89.934203,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.9667,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116391,
    shape_pt_lon: -89.936804,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.2027,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116476,
    shape_pt_lon: -89.940768,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.5609,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116482,
    shape_pt_lon: -89.941191,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.5989,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116489,
    shape_pt_lon: -89.941728,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.6479,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116479,
    shape_pt_lon: -89.942043,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.6759,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116443,
    shape_pt_lon: -89.942421,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.7103,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116397,
    shape_pt_lon: -89.942782,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.7436,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116289,
    shape_pt_lon: -89.943202,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.7835,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116219,
    shape_pt_lon: -89.943486,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.8097,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116092,
    shape_pt_lon: -89.943931,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.8531,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115899,
    shape_pt_lon: -89.944468,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.9059,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115511,
    shape_pt_lon: -89.945551,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.0129,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.0319,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -89.94583,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.0389,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115284,
    shape_pt_lon: -89.946429,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.0962,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115233,
    shape_pt_lon: -89.946653,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.1178,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115181,
    shape_pt_lon: -89.946951,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.1444,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115153,
    shape_pt_lon: -89.947244,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.1716,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115133,
    shape_pt_lon: -89.947737,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.2167,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115152,
    shape_pt_lon: -89.948766,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.3097,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115183,
    shape_pt_lon: -89.95022,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.4408,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115217,
    shape_pt_lon: -89.951711,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.5758,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115249,
    shape_pt_lon: -89.953173,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.7079,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115275,
    shape_pt_lon: -89.954263,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.8059,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115277,
    shape_pt_lon: -89.954368,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.816,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115282,
    shape_pt_lon: -89.954678,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.844,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -89.956647,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.022,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115349,
    shape_pt_lon: -89.957581,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.106,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115368,
    shape_pt_lon: -89.958488,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.1881,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -89.960326,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.3541,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -89.960662,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.3852,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115449,
    shape_pt_lon: -89.961122,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.4263,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115466,
    shape_pt_lon: -89.961886,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.4953,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115461,
    shape_pt_lon: -89.962538,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.5543,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11546,
    shape_pt_lon: -89.962713,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.5703,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115532,
    shape_pt_lon: -89.966054,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.8724,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115543,
    shape_pt_lon: -89.966582,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.9204,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115579,
    shape_pt_lon: -89.968183,
    shape_pt_sequence: 49,
    shape_dist_traveled: 4.0655,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.1156,
    shape_pt_lon: -89.969098,
    shape_pt_sequence: 50,
    shape_dist_traveled: 4.1475,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115622,
    shape_pt_lon: -89.969623,
    shape_pt_sequence: 51,
    shape_dist_traveled: 4.1956,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115649,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 52,
    shape_dist_traveled: 4.2926,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115671,
    shape_pt_lon: -89.971763,
    shape_pt_sequence: 53,
    shape_dist_traveled: 4.3887,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115689,
    shape_pt_lon: -89.972611,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.4657,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115714,
    shape_pt_lon: -89.973502,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.5457,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115728,
    shape_pt_lon: -89.974297,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.6177,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115771,
    shape_pt_lon: -89.976156,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.7858,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11581,
    shape_pt_lon: -89.977914,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.9449,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115863,
    shape_pt_lon: -89.980078,
    shape_pt_sequence: 59,
    shape_dist_traveled: 5.141,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115875,
    shape_pt_lon: -89.980394,
    shape_pt_sequence: 60,
    shape_dist_traveled: 5.169,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115884,
    shape_pt_lon: -89.980687,
    shape_pt_sequence: 61,
    shape_dist_traveled: 5.196,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11589,
    shape_pt_lon: -89.98084,
    shape_pt_sequence: 62,
    shape_dist_traveled: 5.21,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.115964,
    shape_pt_lon: -89.984279,
    shape_pt_sequence: 63,
    shape_dist_traveled: 5.5201,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.116155,
    shape_pt_lon: -89.984277,
    shape_pt_sequence: 64,
    shape_dist_traveled: 5.5411,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.117731,
    shape_pt_lon: -89.984247,
    shape_pt_sequence: 65,
    shape_dist_traveled: 5.7171,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11869,
    shape_pt_lon: -89.984292,
    shape_pt_sequence: 66,
    shape_dist_traveled: 5.8252,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.118836,
    shape_pt_lon: -89.984293,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.8412,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119485,
    shape_pt_lon: -89.98426,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.9143,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119489,
    shape_pt_lon: -89.984402,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.9273,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11949,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.9343,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 71,
    shape_dist_traveled: 6.0143,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119507,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 72,
    shape_dist_traveled: 6.0263,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119525,
    shape_pt_lon: -89.986309,
    shape_pt_sequence: 73,
    shape_dist_traveled: 6.0993,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119548,
    shape_pt_lon: -89.987176,
    shape_pt_sequence: 74,
    shape_dist_traveled: 6.1784,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119568,
    shape_pt_lon: -89.988087,
    shape_pt_sequence: 75,
    shape_dist_traveled: 6.2604,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11959,
    shape_pt_lon: -89.988987,
    shape_pt_sequence: 76,
    shape_dist_traveled: 6.3414,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119612,
    shape_pt_lon: -89.989921,
    shape_pt_sequence: 77,
    shape_dist_traveled: 6.4265,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119613,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 78,
    shape_dist_traveled: 6.4325,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.119642,
    shape_pt_lon: -89.991079,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.5306,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.11978,
    shape_pt_lon: -89.991073,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.5456,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.120771,
    shape_pt_lon: -89.990997,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.6568,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.99098,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.6768,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.121819,
    shape_pt_lon: -89.99094,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.7739,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.121898,
    shape_pt_lon: -89.990937,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.7829,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.122771,
    shape_pt_lon: -89.990897,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.881,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.123599,
    shape_pt_lon: -89.990855,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.9731,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.123689,
    shape_pt_lon: -89.99085,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.9841,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.12448,
    shape_pt_lon: -89.990813,
    shape_pt_sequence: 88,
    shape_dist_traveled: 7.0721,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.124602,
    shape_pt_lon: -89.990808,
    shape_pt_sequence: 89,
    shape_dist_traveled: 7.0862,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -89.990783,
    shape_pt_sequence: 90,
    shape_dist_traveled: 7.1382,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.125157,
    shape_pt_lon: -89.990778,
    shape_pt_sequence: 91,
    shape_dist_traveled: 7.1482,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 92,
    shape_dist_traveled: 7.2453,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.12672,
    shape_pt_lon: -89.990698,
    shape_pt_sequence: 93,
    shape_dist_traveled: 7.3234,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.127158,
    shape_pt_lon: -89.990685,
    shape_pt_sequence: 94,
    shape_dist_traveled: 7.3724,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.128177,
    shape_pt_lon: -89.990638,
    shape_pt_sequence: 95,
    shape_dist_traveled: 7.4865,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.128405,
    shape_pt_lon: -89.990627,
    shape_pt_sequence: 96,
    shape_dist_traveled: 7.5115,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.129205,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.6015,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.129292,
    shape_pt_lon: -89.99058,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.6116,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.130267,
    shape_pt_lon: -89.990533,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.7207,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.130995,
    shape_pt_lon: -89.99051,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.8017,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.990477,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.8837,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13213,
    shape_pt_lon: -89.990461,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.9288,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.132197,
    shape_pt_lon: -89.990458,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.9368,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.133149,
    shape_pt_lon: -89.990418,
    shape_pt_sequence: 104,
    shape_dist_traveled: 8.0429,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.134164,
    shape_pt_lon: -89.990378,
    shape_pt_sequence: 105,
    shape_dist_traveled: 8.1569,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13444,
    shape_pt_lon: -89.990366,
    shape_pt_sequence: 106,
    shape_dist_traveled: 8.188,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13476,
    shape_pt_lon: -89.990339,
    shape_pt_sequence: 107,
    shape_dist_traveled: 8.223,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.135136,
    shape_pt_lon: -89.9903,
    shape_pt_sequence: 108,
    shape_dist_traveled: 8.2652,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.135363,
    shape_pt_lon: -89.990272,
    shape_pt_sequence: 109,
    shape_dist_traveled: 8.2913,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.135477,
    shape_pt_lon: -89.990267,
    shape_pt_sequence: 110,
    shape_dist_traveled: 8.3043,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.135951,
    shape_pt_lon: -89.990193,
    shape_pt_sequence: 111,
    shape_dist_traveled: 8.3577,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136076,
    shape_pt_lon: -89.990171,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.3718,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136131,
    shape_pt_lon: -89.990162,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.3779,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136598,
    shape_pt_lon: -89.990099,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.4302,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136659,
    shape_pt_lon: -89.990586,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.4748,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136836,
    shape_pt_lon: -89.991927,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.5984,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136877,
    shape_pt_lon: -89.99232,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.6336,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.136928,
    shape_pt_lon: -89.992841,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.681,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13706,
    shape_pt_lon: -89.994021,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.7889,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137218,
    shape_pt_lon: -89.995446,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.9192,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137384,
    shape_pt_lon: -89.996914,
    shape_pt_sequence: 121,
    shape_dist_traveled: 9.0525,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137543,
    shape_pt_lon: -89.998341,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.1827,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13769,
    shape_pt_lon: -89.999664,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.3038,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.001117,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.4361,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137993,
    shape_pt_lon: -90.00236,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.5491,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138001,
    shape_pt_lon: -90.002431,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.5562,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138118,
    shape_pt_lon: -90.003489,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.652,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13813,
    shape_pt_lon: -90.00361,
    shape_pt_sequence: 128,
    shape_dist_traveled: 9.6632,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13815,
    shape_pt_lon: -90.004017,
    shape_pt_sequence: 129,
    shape_dist_traveled: 9.7003,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138141,
    shape_pt_lon: -90.004185,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.7153,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138101,
    shape_pt_lon: -90.004508,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.7447,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137978,
    shape_pt_lon: -90.005018,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.7938,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137948,
    shape_pt_lon: -90.00516,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.8061,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137901,
    shape_pt_lon: -90.005569,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.8435,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137906,
    shape_pt_lon: -90.005915,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.8755,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.137942,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.9147,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138089,
    shape_pt_lon: -90.007822,
    shape_pt_sequence: 137,
    shape_dist_traveled: 10.0488,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138094,
    shape_pt_lon: -90.007878,
    shape_pt_sequence: 138,
    shape_dist_traveled: 10.0538,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13818,
    shape_pt_lon: -90.008773,
    shape_pt_sequence: 139,
    shape_dist_traveled: 10.1354,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.13849,
    shape_pt_lon: -90.01199,
    shape_pt_sequence: 140,
    shape_dist_traveled: 10.4284,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138605,
    shape_pt_lon: -90.013143,
    shape_pt_sequence: 141,
    shape_dist_traveled: 10.5332,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138714,
    shape_pt_lon: -90.0143,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.6379,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.8271,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.138999,
    shape_pt_lon: -90.01727,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.9088,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.9964,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139171,
    shape_pt_lon: -90.019128,
    shape_pt_sequence: 146,
    shape_dist_traveled: 11.0778,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139207,
    shape_pt_lon: -90.019516,
    shape_pt_sequence: 147,
    shape_dist_traveled: 11.1131,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139247,
    shape_pt_lon: -90.019948,
    shape_pt_sequence: 148,
    shape_dist_traveled: 11.1524,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139332,
    shape_pt_lon: -90.020851,
    shape_pt_sequence: 149,
    shape_dist_traveled: 11.2339,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139402,
    shape_pt_lon: -90.02159,
    shape_pt_sequence: 150,
    shape_dist_traveled: 11.3014,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.139921,
    shape_pt_lon: -90.021523,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.3597,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.140433,
    shape_pt_lon: -90.02145,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.4171,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142065,
    shape_pt_lon: -90.021216,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.6003,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142182,
    shape_pt_lon: -90.022328,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.7021,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142201,
    shape_pt_lon: -90.022537,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.7212,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.7354,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142226,
    shape_pt_lon: -90.02278,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.7435,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.7545,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14225,
    shape_pt_lon: -90.023073,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.7696,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142262,
    shape_pt_lon: -90.023205,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.7817,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14227,
    shape_pt_lon: -90.023289,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.7897,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142346,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.8632,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142455,
    shape_pt_lon: -90.025199,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.9629,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.141093,
    shape_pt_lon: -90.025398,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.115,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.141198,
    shape_pt_lon: -90.026502,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.2157,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14123,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 166,
    shape_dist_traveled: 12.2369,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.141296,
    shape_pt_lon: -90.027016,
    shape_pt_sequence: 167,
    shape_dist_traveled: 12.2631,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.141346,
    shape_pt_lon: -90.027503,
    shape_pt_sequence: 168,
    shape_dist_traveled: 12.3074,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 169,
    shape_dist_traveled: 12.3315,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 170,
    shape_dist_traveled: 12.4652,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.4812,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.5788,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.624,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.029973,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.6976,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143057,
    shape_pt_lon: -90.030248,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.7236,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143097,
    shape_pt_lon: -90.030386,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.7372,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143319,
    shape_pt_lon: -90.031011,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.7985,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143369,
    shape_pt_lon: -90.03119,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.8162,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.1434,
    shape_pt_lon: -90.031389,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.8347,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143506,
    shape_pt_lon: -90.032323,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.9195,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143574,
    shape_pt_lon: -90.033031,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.9839,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14379,
    shape_pt_lon: -90.035141,
    shape_pt_sequence: 182,
    shape_dist_traveled: 13.1764,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.143821,
    shape_pt_lon: -90.035301,
    shape_pt_sequence: 183,
    shape_dist_traveled: 13.191,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.144348,
    shape_pt_lon: -90.037283,
    shape_pt_sequence: 184,
    shape_dist_traveled: 13.3794,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.145086,
    shape_pt_lon: -90.040042,
    shape_pt_sequence: 185,
    shape_dist_traveled: 13.6416,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.145587,
    shape_pt_lon: -90.041916,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.8206,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.146177,
    shape_pt_lon: -90.044111,
    shape_pt_sequence: 187,
    shape_dist_traveled: 14.0293,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14622,
    shape_pt_lon: -90.044269,
    shape_pt_sequence: 188,
    shape_dist_traveled: 14.0442,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.146235,
    shape_pt_lon: -90.044336,
    shape_pt_sequence: 189,
    shape_dist_traveled: 14.0505,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.146284,
    shape_pt_lon: -90.044558,
    shape_pt_sequence: 190,
    shape_dist_traveled: 14.0711,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.146758,
    shape_pt_lon: -90.046337,
    shape_pt_sequence: 191,
    shape_dist_traveled: 14.2406,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 192,
    shape_dist_traveled: 14.5053,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 193,
    shape_dist_traveled: 14.6229,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 194,
    shape_dist_traveled: 14.6786,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 195,
    shape_dist_traveled: 14.7387,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 196,
    shape_dist_traveled: 14.8563,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 197,
    shape_dist_traveled: 14.9727,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 198,
    shape_dist_traveled: 15.0859,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 199,
    shape_dist_traveled: 15.0958,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 200,
    shape_dist_traveled: 15.1183,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 201,
    shape_dist_traveled: 15.13,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 202,
    shape_dist_traveled: 15.1772,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 203,
    shape_dist_traveled: 15.2041,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 204,
    shape_dist_traveled: 15.2196,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 205,
    shape_dist_traveled: 15.324,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 206,
    shape_dist_traveled: 15.4356,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 207,
    shape_dist_traveled: 15.4429,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 208,
    shape_dist_traveled: 15.5134,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 209,
    shape_dist_traveled: 15.5605,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 210,
    shape_dist_traveled: 15.6203,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 211,
    shape_dist_traveled: 15.6431,
  },
  {
    shape_id: 38164,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 212,
    shape_dist_traveled: 15.7095,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1863,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.247,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2556,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3647,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4687,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4818,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4872,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5422,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5636,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5699,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.578,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5862,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5988,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7146,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7377,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.769,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8278,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8408,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9499,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0091,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0676,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.183,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2377,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3007,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3661,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4177,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4743,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5331,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5594,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5929,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.652,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.714,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7706,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7981,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8446,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9066,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9449,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0145,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1084,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1587,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.2144,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2395,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3246,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4304,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5424,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.6809,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.135667,
    shape_pt_lon: -90.0539,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7281,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.135632,
    shape_pt_lon: -90.053788,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.7389,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.135536,
    shape_pt_lon: -90.052817,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.8276,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.135413,
    shape_pt_lon: -90.051589,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9393,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.135266,
    shape_pt_lon: -90.050031,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0804,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134923,
    shape_pt_lon: -90.046476,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.4046,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134916,
    shape_pt_lon: -90.046389,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.4117,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134911,
    shape_pt_lon: -90.046351,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.4157,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134779,
    shape_pt_lon: -90.044939,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.5435,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.13473,
    shape_pt_lon: -90.044399,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.5929,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134648,
    shape_pt_lon: -90.043655,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.6605,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.134538,
    shape_pt_lon: -90.043692,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.6729,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.133914,
    shape_pt_lon: -90.043803,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.7436,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.133392,
    shape_pt_lon: -90.04388,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.802,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.132758,
    shape_pt_lon: -90.043972,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.8735,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.132159,
    shape_pt_lon: -90.044057,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.9409,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.131079,
    shape_pt_lon: -90.044215,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.0627,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.13058,
    shape_pt_lon: -90.044288,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.1192,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.129562,
    shape_pt_lon: -90.044417,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.2337,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.129313,
    shape_pt_lon: -90.044443,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.2619,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.129261,
    shape_pt_lon: -90.044437,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.268,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.129194,
    shape_pt_lon: -90.044526,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.2786,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.129052,
    shape_pt_lon: -90.044782,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.3066,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.128916,
    shape_pt_lon: -90.044693,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.3236,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.128586,
    shape_pt_lon: -90.044532,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.3632,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -90.044428,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.3976,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.128068,
    shape_pt_lon: -90.044344,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.4236,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.127891,
    shape_pt_lon: -90.044259,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.4451,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.127796,
    shape_pt_lon: -90.044197,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.4577,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.127315,
    shape_pt_lon: -90.04384,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.5196,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.126561,
    shape_pt_lon: -90.043276,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.6187,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.12635,
    shape_pt_lon: -90.043114,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.6462,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.125777,
    shape_pt_lon: -90.042661,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.7222,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.124633,
    shape_pt_lon: -90.041739,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.8747,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.124272,
    shape_pt_lon: -90.041443,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.9238,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.124216,
    shape_pt_lon: -90.04141,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.9305,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.12386,
    shape_pt_lon: -90.041209,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.9744,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.12316,
    shape_pt_lon: -90.040822,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.0599,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.122267,
    shape_pt_lon: -90.040321,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.1695,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.122174,
    shape_pt_lon: -90.040269,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.1816,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.121288,
    shape_pt_lon: -90.039769,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.2904,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.120863,
    shape_pt_lon: -90.039532,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.3419,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.120394,
    shape_pt_lon: -90.039271,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.4,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119478,
    shape_pt_lon: -90.038756,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.5123,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119458,
    shape_pt_lon: -90.037887,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.5904,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119432,
    shape_pt_lon: -90.036723,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.6954,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119412,
    shape_pt_lon: -90.036018,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.7594,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11941,
    shape_pt_lon: -90.03593,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.7675,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119379,
    shape_pt_lon: -90.034777,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.8716,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119355,
    shape_pt_lon: -90.03386,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.9546,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119341,
    shape_pt_lon: -90.03338,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.9986,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119328,
    shape_pt_lon: -90.032858,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.0457,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11932,
    shape_pt_lon: -90.032571,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.0717,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119299,
    shape_pt_lon: -90.03179,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.1417,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.119271,
    shape_pt_lon: -90.030686,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.2418,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.118512,
    shape_pt_lon: -90.030712,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.3268,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.118335,
    shape_pt_lon: -90.030719,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.3468,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.117717,
    shape_pt_lon: -90.03074,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.4158,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.117313,
    shape_pt_lon: -90.030755,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.4608,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.116929,
    shape_pt_lon: -90.030767,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.5039,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.1155,
    shape_pt_lon: -90.030813,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.6639,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115489,
    shape_pt_lon: -90.030319,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.7079,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11547,
    shape_pt_lon: -90.029561,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.7769,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115463,
    shape_pt_lon: -90.029251,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.805,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115454,
    shape_pt_lon: -90.028823,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.844,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -90.028441,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.878,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115439,
    shape_pt_lon: -90.02805,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.913,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.921,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115433,
    shape_pt_lon: -90.027829,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.933,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.9431,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115429,
    shape_pt_lon: -90.02769,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.9461,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.115426,
    shape_pt_lon: -90.027519,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.9611,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.114439,
    shape_pt_lon: -90.027541,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.0721,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.114165,
    shape_pt_lon: -90.027552,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.1021,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.113293,
    shape_pt_lon: -90.027587,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.2002,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111705,
    shape_pt_lon: -90.027643,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.3782,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111733,
    shape_pt_lon: -90.028645,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.4683,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111739,
    shape_pt_lon: -90.028817,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.4843,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111741,
    shape_pt_lon: -90.028887,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.4903,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111746,
    shape_pt_lon: -90.028983,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.4994,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111749,
    shape_pt_lon: -90.029106,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.5104,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111752,
    shape_pt_lon: -90.029293,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.5274,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111756,
    shape_pt_lon: -90.029408,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.5374,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11176,
    shape_pt_lon: -90.029574,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.5524,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111767,
    shape_pt_lon: -90.03011,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.6014,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11178,
    shape_pt_lon: -90.030368,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.6245,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.111075,
    shape_pt_lon: -90.030402,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.7036,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.110747,
    shape_pt_lon: -90.03039,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.7406,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.11031,
    shape_pt_lon: -90.030399,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.7896,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109897,
    shape_pt_lon: -90.030419,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.8356,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109809,
    shape_pt_lon: -90.030426,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.8457,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109809,
    shape_pt_lon: -90.030427,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.8457,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109716,
    shape_pt_lon: -90.030431,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.8557,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10972,
    shape_pt_lon: -90.03043,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.8557,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109692,
    shape_pt_lon: -90.029454,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.9437,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10969,
    shape_pt_lon: -90.029351,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.9527,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109684,
    shape_pt_lon: -90.029215,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.9648,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10968,
    shape_pt_lon: -90.029072,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.9778,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109679,
    shape_pt_lon: -90.029,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.9848,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109675,
    shape_pt_lon: -90.028837,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.9998,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109671,
    shape_pt_lon: -90.028711,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.0108,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109666,
    shape_pt_lon: -90.028363,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0418,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109646,
    shape_pt_lon: -90.027739,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.0988,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109623,
    shape_pt_lon: -90.026888,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.1759,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109602,
    shape_pt_lon: -90.026037,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.2529,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109591,
    shape_pt_lon: -90.025679,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.2849,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109577,
    shape_pt_lon: -90.025199,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.328,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109557,
    shape_pt_lon: -90.024341,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.406,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10953,
    shape_pt_lon: -90.023504,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.4821,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109509,
    shape_pt_lon: -90.022687,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.5561,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109487,
    shape_pt_lon: -90.021947,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.6221,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10948,
    shape_pt_lon: -90.021763,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.6392,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109479,
    shape_pt_lon: -90.02172,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.6432,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109376,
    shape_pt_lon: -90.018399,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.9434,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.109272,
    shape_pt_lon: -90.015197,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.2336,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10925,
    shape_pt_lon: -90.014871,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.2628,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.108316,
    shape_pt_lon: -90.0149,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.3668,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10756,
    shape_pt_lon: -90.014929,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.4518,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.107485,
    shape_pt_lon: -90.014931,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.4598,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.106732,
    shape_pt_lon: -90.014957,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.5439,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10554,
    shape_pt_lon: -90.015006,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.6779,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.10467,
    shape_pt_lon: -90.015033,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.775,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.103777,
    shape_pt_lon: -90.015071,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.875,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.103042,
    shape_pt_lon: -90.015099,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.9571,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.1023,
    shape_pt_lon: -90.015118,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.0401,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.101558,
    shape_pt_lon: -90.015148,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.1231,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100789,
    shape_pt_lon: -90.015177,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.2092,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100752,
    shape_pt_lon: -90.014291,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.2893,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100726,
    shape_pt_lon: -90.01317,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.3903,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100708,
    shape_pt_lon: -90.012887,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.4164,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100676,
    shape_pt_lon: -90.012689,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.4349,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100506,
    shape_pt_lon: -90.012868,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.4597,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.100201,
    shape_pt_lon: -90.013155,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.5025,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.1001,
    shape_pt_lon: -90.013226,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.5155,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099951,
    shape_pt_lon: -90.013308,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.5339,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099785,
    shape_pt_lon: -90.01337,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.5538,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099567,
    shape_pt_lon: -90.013403,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.578,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099139,
    shape_pt_lon: -90.013432,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.6261,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098842,
    shape_pt_lon: -90.013466,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.6592,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098679,
    shape_pt_lon: -90.013506,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.6776,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098589,
    shape_pt_lon: -90.013542,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.6881,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098057,
    shape_pt_lon: -90.01382,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.7531,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.097884,
    shape_pt_lon: -90.013878,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.7727,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.097746,
    shape_pt_lon: -90.013895,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.7889,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.097398,
    shape_pt_lon: -90.01392,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.8279,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096731,
    shape_pt_lon: -90.013948,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.902,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096068,
    shape_pt_lon: -90.01398,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.977,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.095406,
    shape_pt_lon: -90.014004,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.0511,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.094746,
    shape_pt_lon: -90.014029,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.1251,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09409,
    shape_pt_lon: -90.014058,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.1981,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09382,
    shape_pt_lon: -90.014067,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2281,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.093659,
    shape_pt_lon: -90.01407,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.2462,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.093469,
    shape_pt_lon: -90.014075,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.2672,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09339,
    shape_pt_lon: -90.014076,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.2762,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092929,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.3282,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092708,
    shape_pt_lon: -90.014093,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.3532,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092703,
    shape_pt_lon: -90.014608,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.3992,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092149,
    shape_pt_lon: -90.014609,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.4612,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.090834,
    shape_pt_lon: -90.014647,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.6083,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.0907,
    shape_pt_lon: -90.014651,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.6233,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.090521,
    shape_pt_lon: -90.014649,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.6433,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.089586,
    shape_pt_lon: -90.014613,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.7483,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.089167,
    shape_pt_lon: -90.014592,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.7954,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088955,
    shape_pt_lon: -90.01454,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.8197,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.08884,
    shape_pt_lon: -90.014491,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.8327,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088619,
    shape_pt_lon: -90.014358,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.8604,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088539,
    shape_pt_lon: -90.014354,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.8694,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088463,
    shape_pt_lon: -90.014389,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.8789,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088409,
    shape_pt_lon: -90.014448,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.8867,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088398,
    shape_pt_lon: -90.01447,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.889,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088386,
    shape_pt_lon: -90.014508,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.8931,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088372,
    shape_pt_lon: -90.014649,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.9062,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088393,
    shape_pt_lon: -90.015923,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.0213,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088413,
    shape_pt_lon: -90.016049,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.0325,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088481,
    shape_pt_lon: -90.01625,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.0518,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088275,
    shape_pt_lon: -90.016613,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.092,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088208,
    shape_pt_lon: -90.016708,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.1034,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088138,
    shape_pt_lon: -90.016778,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.1134,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088052,
    shape_pt_lon: -90.016829,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.1246,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087918,
    shape_pt_lon: -90.016871,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.1399,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087555,
    shape_pt_lon: -90.016937,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.1803,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087548,
    shape_pt_lon: -90.016567,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.2133,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087512,
    shape_pt_lon: -90.012902,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.5444,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087515,
    shape_pt_lon: -90.011768,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.6474,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087496,
    shape_pt_lon: -90.011388,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.6815,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087495,
    shape_pt_lon: -90.010591,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.7535,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.08748,
    shape_pt_lon: -90.010408,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.7705,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087732,
    shape_pt_lon: -90.010391,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.7985,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.089096,
    shape_pt_lon: -90.010339,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.9506,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.089712,
    shape_pt_lon: -90.010313,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.0197,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.090547,
    shape_pt_lon: -90.010281,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.1137,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092156,
    shape_pt_lon: -90.010248,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.2937,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092146,
    shape_pt_lon: -90.00959,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.3537,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092097,
    shape_pt_lon: -90.008222,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.4769,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092031,
    shape_pt_lon: -90.005852,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.692,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091993,
    shape_pt_lon: -90.00447,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.8171,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091993,
    shape_pt_lon: -90.00444,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.8191,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091991,
    shape_pt_lon: -90.004377,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.8251,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09199,
    shape_pt_lon: -90.004341,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.8281,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091929,
    shape_pt_lon: -90.002117,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.0292,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091926,
    shape_pt_lon: -90.002009,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.0392,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091898,
    shape_pt_lon: -90.001038,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.1272,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091872,
    shape_pt_lon: -90.000102,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.2113,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091832,
    shape_pt_lon: -89.998657,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.3424,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09213,
    shape_pt_lon: -89.998654,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.3764,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.092763,
    shape_pt_lon: -89.998629,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.4475,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.0933,
    shape_pt_lon: -89.998598,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.5075,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.094671,
    shape_pt_lon: -89.998526,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.6606,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096012,
    shape_pt_lon: -89.998478,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.8107,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096113,
    shape_pt_lon: -89.998483,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.8217,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096193,
    shape_pt_lon: -89.998516,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.8312,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09623,
    shape_pt_lon: -89.998558,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.8362,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096272,
    shape_pt_lon: -89.998645,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.8457,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09628,
    shape_pt_lon: -89.998719,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.8527,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.096281,
    shape_pt_lon: -89.998919,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.8707,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.097157,
    shape_pt_lon: -89.998884,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.9688,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098127,
    shape_pt_lon: -89.99885,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.0778,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098402,
    shape_pt_lon: -89.998835,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.1078,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098564,
    shape_pt_lon: -89.99879,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.1273,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099119,
    shape_pt_lon: -89.998539,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.1934,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099193,
    shape_pt_lon: -89.998523,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.2016,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099559,
    shape_pt_lon: -89.998501,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.2427,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099953,
    shape_pt_lon: -89.998471,
    shape_pt_sequence: 274,
    shape_dist_traveled: 15.2867,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099898,
    shape_pt_lon: -89.996967,
    shape_pt_sequence: 275,
    shape_dist_traveled: 15.4229,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09983,
    shape_pt_lon: -89.995168,
    shape_pt_sequence: 276,
    shape_dist_traveled: 15.586,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099847,
    shape_pt_lon: -89.995097,
    shape_pt_sequence: 277,
    shape_dist_traveled: 15.5921,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099732,
    shape_pt_lon: -89.995041,
    shape_pt_sequence: 278,
    shape_dist_traveled: 15.6051,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099389,
    shape_pt_lon: -89.994838,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.6485,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099284,
    shape_pt_lon: -89.994798,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.6608,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.099148,
    shape_pt_lon: -89.994781,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.676,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09892,
    shape_pt_lon: -89.994788,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.701,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098569,
    shape_pt_lon: -89.9948,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.741,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.098479,
    shape_pt_lon: -89.994801,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.751,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.097619,
    shape_pt_lon: -89.994833,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.8471,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.094613,
    shape_pt_lon: -89.994949,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.1832,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.093223,
    shape_pt_lon: -89.994997,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.3393,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091733,
    shape_pt_lon: -89.99506,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.5054,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091651,
    shape_pt_lon: -89.992156,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.7685,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091627,
    shape_pt_lon: -89.991015,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.8716,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091571,
    shape_pt_lon: -89.989298,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.0267,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091548,
    shape_pt_lon: -89.988159,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.1297,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091537,
    shape_pt_lon: -89.987678,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.1737,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091535,
    shape_pt_lon: -89.987591,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.1817,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091532,
    shape_pt_lon: -89.987538,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.1857,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09152,
    shape_pt_lon: -89.987055,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.2298,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09148,
    shape_pt_lon: -89.985312,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.3878,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091509,
    shape_pt_lon: -89.985187,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.3992,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.091526,
    shape_pt_lon: -89.985151,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.4028,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.09087,
    shape_pt_lon: -89.983931,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.5357,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.090458,
    shape_pt_lon: -89.983167,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.6186,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088548,
    shape_pt_lon: -89.979618,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.0044,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088461,
    shape_pt_lon: -89.979411,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.0245,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.088283,
    shape_pt_lon: -89.978799,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.084,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.087071,
    shape_pt_lon: -89.974642,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.4835,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.08701,
    shape_pt_lon: -89.974429,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.5037,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086988,
    shape_pt_lon: -89.97437,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.5096,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086877,
    shape_pt_lon: -89.974144,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.5338,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086747,
    shape_pt_lon: -89.973968,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.5557,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086632,
    shape_pt_lon: -89.973823,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.5741,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086527,
    shape_pt_lon: -89.973619,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.5952,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086419,
    shape_pt_lon: -89.973323,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.6247,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.086249,
    shape_pt_lon: -89.972802,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.6754,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.085956,
    shape_pt_lon: -89.971896,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.7638,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.08586,
    shape_pt_lon: -89.971676,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.7866,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.085782,
    shape_pt_lon: -89.971567,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.7994,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.085433,
    shape_pt_lon: -89.971162,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.8532,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.085251,
    shape_pt_lon: -89.970977,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.8796,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.084886,
    shape_pt_lon: -89.971471,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.9397,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.084767,
    shape_pt_lon: -89.971596,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.9568,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.084593,
    shape_pt_lon: -89.971712,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.9787,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.084477,
    shape_pt_lon: -89.97177,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.9927,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.084341,
    shape_pt_lon: -89.971807,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.0091,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.083918,
    shape_pt_lon: -89.971831,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.0562,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.083172,
    shape_pt_lon: -89.971864,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.1392,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.082271,
    shape_pt_lon: -89.971908,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.2403,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080502,
    shape_pt_lon: -89.971989,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.4384,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080518,
    shape_pt_lon: -89.972527,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.4865,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080548,
    shape_pt_lon: -89.973703,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.5935,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080561,
    shape_pt_lon: -89.974332,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.6495,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.08055,
    shape_pt_lon: -89.974656,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.6796,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080494,
    shape_pt_lon: -89.975032,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.7141,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080399,
    shape_pt_lon: -89.975414,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.7498,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.080077,
    shape_pt_lon: -89.976641,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.8665,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079539,
    shape_pt_lon: -89.978693,
    shape_pt_sequence: 335,
    shape_dist_traveled: 20.0623,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079515,
    shape_pt_lon: -89.978864,
    shape_pt_sequence: 336,
    shape_dist_traveled: 20.0774,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079503,
    shape_pt_lon: -89.978975,
    shape_pt_sequence: 337,
    shape_dist_traveled: 20.0876,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079479,
    shape_pt_lon: -89.97986,
    shape_pt_sequence: 338,
    shape_dist_traveled: 20.1676,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079472,
    shape_pt_lon: -89.980067,
    shape_pt_sequence: 339,
    shape_dist_traveled: 20.1866,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079447,
    shape_pt_lon: -89.980882,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.2607,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079442,
    shape_pt_lon: -89.981017,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.2727,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079391,
    shape_pt_lon: -89.982602,
    shape_pt_sequence: 342,
    shape_dist_traveled: 20.4158,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079386,
    shape_pt_lon: -89.983209,
    shape_pt_sequence: 343,
    shape_dist_traveled: 20.4708,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079356,
    shape_pt_lon: -89.983978,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.541,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.07934,
    shape_pt_lon: -89.984578,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.595,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.07934,
    shape_pt_lon: -89.98463,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.599,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079339,
    shape_pt_lon: -89.984666,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.603,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079338,
    shape_pt_lon: -89.984819,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.617,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079339,
    shape_pt_lon: -89.984863,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.6211,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079322,
    shape_pt_lon: -89.984919,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.6265,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.079134,
    shape_pt_lon: -89.98494,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.6475,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.078938,
    shape_pt_lon: -89.984974,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.6699,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.078643,
    shape_pt_lon: -89.985034,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.7033,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.078213,
    shape_pt_lon: -89.985126,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.7519,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.07723,
    shape_pt_lon: -89.985337,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.8645,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.077081,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.8808,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.077027,
    shape_pt_lon: -89.985382,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.8869,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.076889,
    shape_pt_lon: -89.985413,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.9032,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.076452,
    shape_pt_lon: -89.985517,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.953,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.076308,
    shape_pt_lon: -89.985551,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.9693,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.985582,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.9836,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.075837,
    shape_pt_lon: -89.985667,
    shape_pt_sequence: 362,
    shape_dist_traveled: 21.0224,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.075751,
    shape_pt_lon: -89.985687,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.0326,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.075283,
    shape_pt_lon: -89.985796,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.0856,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.985825,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.1007,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.075,
    shape_pt_lon: -89.985859,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.118,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.074102,
    shape_pt_lon: -89.986058,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.2206,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.073676,
    shape_pt_lon: -89.986155,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.2684,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.072943,
    shape_pt_lon: -89.986292,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.3513,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.072719,
    shape_pt_lon: -89.986367,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.3773,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.072344,
    shape_pt_lon: -89.986465,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.4202,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.070852,
    shape_pt_lon: -89.986799,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.5899,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.070628,
    shape_pt_lon: -89.986851,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.6152,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.070134,
    shape_pt_lon: -89.986979,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.6725,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.069826,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.7076,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.069379,
    shape_pt_lon: -89.987227,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.7593,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.987366,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.7957,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.987491,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.8239,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.8334,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.068713,
    shape_pt_lon: -89.987548,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.8398,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.068657,
    shape_pt_lon: -89.987588,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.847,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987664,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.8609,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.8864,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.9857,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.3097,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.3943,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.4462,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.4959,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.4979,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.5261,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.5735,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.6269,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.6606,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.7041,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.7261,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.7772,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.7988,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.8172,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.8308,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.8492,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.8689,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.8854,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.9084,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 404,
    shape_dist_traveled: 22.9324,
  },
  {
    shape_id: 38165,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 405,
    shape_dist_traveled: 23.067,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1171,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1391,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1902,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2119,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2303,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2623,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2819,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2984,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3214,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3454,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4215,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4785,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5166,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5226,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6746,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8437,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9537,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9739,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9942,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0094,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0258,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0465,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0679,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0869,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.105,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.12,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1606,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1878,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2564,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.2847,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3142,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4271,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.4347,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.4441,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.5274,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8523,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9127,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9605,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.979,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.997,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0277,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0797,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.0857,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1001,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1111,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.1172,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.1312,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.1752,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06865,
    shape_pt_lon: -89.985071,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.2044,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068625,
    shape_pt_lon: -89.984978,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.2126,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068562,
    shape_pt_lon: -89.984818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.2292,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068495,
    shape_pt_lon: -89.984687,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.2428,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068416,
    shape_pt_lon: -89.984587,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.2562,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068328,
    shape_pt_lon: -89.984503,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.2676,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068199,
    shape_pt_lon: -89.984432,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2842,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068038,
    shape_pt_lon: -89.984391,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3025,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067921,
    shape_pt_lon: -89.984398,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.3155,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067789,
    shape_pt_lon: -89.984438,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.331,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067707,
    shape_pt_lon: -89.984488,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.3408,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067603,
    shape_pt_lon: -89.984597,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.3564,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06755,
    shape_pt_lon: -89.984689,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.3673,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067501,
    shape_pt_lon: -89.984828,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.3803,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06749,
    shape_pt_lon: -89.98499,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.3953,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067507,
    shape_pt_lon: -89.985162,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.4104,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067575,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.4307,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067659,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.4463,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067739,
    shape_pt_lon: -89.985591,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.4583,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.067882,
    shape_pt_lon: -89.985692,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.4767,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06811,
    shape_pt_lon: -89.985803,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.5036,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06868,
    shape_pt_lon: -89.985849,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.5678,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.5738,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.06903,
    shape_pt_lon: -89.985883,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.607,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.070991,
    shape_pt_lon: -89.986079,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.8267,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.072129,
    shape_pt_lon: -89.986183,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.955,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.072364,
    shape_pt_lon: -89.986204,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.9811,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.072728,
    shape_pt_lon: -89.986197,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.0221,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.073,
    shape_pt_lon: -89.986175,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.0522,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.073206,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.0753,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.073437,
    shape_pt_lon: -89.986107,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1017,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.074099,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.1768,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.074912,
    shape_pt_lon: -89.985771,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.2694,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.075888,
    shape_pt_lon: -89.985547,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.3802,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.07617,
    shape_pt_lon: -89.985479,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.4127,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.076291,
    shape_pt_lon: -89.985453,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.4259,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.076441,
    shape_pt_lon: -89.985422,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.4432,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.076887,
    shape_pt_lon: -89.985323,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.494,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.077022,
    shape_pt_lon: -89.985293,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.5093,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.077076,
    shape_pt_lon: -89.985282,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.5153,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.077372,
    shape_pt_lon: -89.985227,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.5487,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.078192,
    shape_pt_lon: -89.985049,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.6421,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.078692,
    shape_pt_lon: -89.984928,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.6992,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.07896,
    shape_pt_lon: -89.984866,
    shape_pt_sequence: 94,
    shape_dist_traveled: 3.7296,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079172,
    shape_pt_lon: -89.984841,
    shape_pt_sequence: 95,
    shape_dist_traveled: 3.7537,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079338,
    shape_pt_lon: -89.984819,
    shape_pt_sequence: 96,
    shape_dist_traveled: 3.7718,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079339,
    shape_pt_lon: -89.984666,
    shape_pt_sequence: 97,
    shape_dist_traveled: 3.7858,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.07934,
    shape_pt_lon: -89.98463,
    shape_pt_sequence: 98,
    shape_dist_traveled: 3.7898,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.07934,
    shape_pt_lon: -89.984578,
    shape_pt_sequence: 99,
    shape_dist_traveled: 3.7938,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079356,
    shape_pt_lon: -89.983978,
    shape_pt_sequence: 100,
    shape_dist_traveled: 3.8478,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079386,
    shape_pt_lon: -89.983209,
    shape_pt_sequence: 101,
    shape_dist_traveled: 3.9179,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079391,
    shape_pt_lon: -89.982602,
    shape_pt_sequence: 102,
    shape_dist_traveled: 3.9729,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079442,
    shape_pt_lon: -89.981017,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.116,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079447,
    shape_pt_lon: -89.980882,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.1281,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079472,
    shape_pt_lon: -89.980067,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.2021,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079479,
    shape_pt_lon: -89.97986,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.2212,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079503,
    shape_pt_lon: -89.978975,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.3012,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079515,
    shape_pt_lon: -89.978864,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.3114,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.079539,
    shape_pt_lon: -89.978693,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.3265,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080077,
    shape_pt_lon: -89.976641,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.5223,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080399,
    shape_pt_lon: -89.975414,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.6389,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080494,
    shape_pt_lon: -89.975032,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.6747,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.08055,
    shape_pt_lon: -89.974656,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.7092,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080561,
    shape_pt_lon: -89.974332,
    shape_pt_sequence: 114,
    shape_dist_traveled: 4.7392,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080548,
    shape_pt_lon: -89.973703,
    shape_pt_sequence: 115,
    shape_dist_traveled: 4.7952,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080518,
    shape_pt_lon: -89.972527,
    shape_pt_sequence: 116,
    shape_dist_traveled: 4.9023,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.080502,
    shape_pt_lon: -89.971989,
    shape_pt_sequence: 117,
    shape_dist_traveled: 4.9503,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.082271,
    shape_pt_lon: -89.971908,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.1485,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.083172,
    shape_pt_lon: -89.971864,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.2495,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.083918,
    shape_pt_lon: -89.971831,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.3326,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.084341,
    shape_pt_lon: -89.971807,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.3796,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.084477,
    shape_pt_lon: -89.97177,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.3961,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.084593,
    shape_pt_lon: -89.971712,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.41,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.084767,
    shape_pt_lon: -89.971596,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.432,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.084886,
    shape_pt_lon: -89.971471,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.449,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.085251,
    shape_pt_lon: -89.970977,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.5092,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.085433,
    shape_pt_lon: -89.971162,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.5356,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.085782,
    shape_pt_lon: -89.971567,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.5893,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.08586,
    shape_pt_lon: -89.971676,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.6021,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.085956,
    shape_pt_lon: -89.971896,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.625,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086249,
    shape_pt_lon: -89.972802,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.7134,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086419,
    shape_pt_lon: -89.973323,
    shape_pt_sequence: 132,
    shape_dist_traveled: 5.764,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086527,
    shape_pt_lon: -89.973619,
    shape_pt_sequence: 133,
    shape_dist_traveled: 5.7936,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086632,
    shape_pt_lon: -89.973823,
    shape_pt_sequence: 134,
    shape_dist_traveled: 5.8147,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086747,
    shape_pt_lon: -89.973968,
    shape_pt_sequence: 135,
    shape_dist_traveled: 5.8331,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086877,
    shape_pt_lon: -89.974144,
    shape_pt_sequence: 136,
    shape_dist_traveled: 5.855,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.086988,
    shape_pt_lon: -89.97437,
    shape_pt_sequence: 137,
    shape_dist_traveled: 5.8792,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.08701,
    shape_pt_lon: -89.974429,
    shape_pt_sequence: 138,
    shape_dist_traveled: 5.885,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087071,
    shape_pt_lon: -89.974642,
    shape_pt_sequence: 139,
    shape_dist_traveled: 5.9053,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088283,
    shape_pt_lon: -89.978799,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.3048,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088461,
    shape_pt_lon: -89.979411,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.3642,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088548,
    shape_pt_lon: -89.979618,
    shape_pt_sequence: 142,
    shape_dist_traveled: 6.3844,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.090458,
    shape_pt_lon: -89.983167,
    shape_pt_sequence: 143,
    shape_dist_traveled: 6.7702,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09087,
    shape_pt_lon: -89.983931,
    shape_pt_sequence: 144,
    shape_dist_traveled: 6.8531,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091526,
    shape_pt_lon: -89.985151,
    shape_pt_sequence: 145,
    shape_dist_traveled: 6.9859,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091509,
    shape_pt_lon: -89.985187,
    shape_pt_sequence: 146,
    shape_dist_traveled: 6.9895,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09148,
    shape_pt_lon: -89.985312,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.0009,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09152,
    shape_pt_lon: -89.987055,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.159,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091532,
    shape_pt_lon: -89.987538,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.203,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091535,
    shape_pt_lon: -89.987591,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.207,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091537,
    shape_pt_lon: -89.987678,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.215,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091548,
    shape_pt_lon: -89.988159,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.259,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091571,
    shape_pt_lon: -89.989298,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.3621,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091627,
    shape_pt_lon: -89.991015,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.5172,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091651,
    shape_pt_lon: -89.992156,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.6203,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091733,
    shape_pt_lon: -89.99506,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.8834,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.093223,
    shape_pt_lon: -89.994997,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.0495,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.094613,
    shape_pt_lon: -89.994949,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.2056,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.097619,
    shape_pt_lon: -89.994833,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.5417,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098479,
    shape_pt_lon: -89.994801,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.6378,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098569,
    shape_pt_lon: -89.9948,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.6478,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09892,
    shape_pt_lon: -89.994788,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.6878,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099148,
    shape_pt_lon: -89.994781,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.7128,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099284,
    shape_pt_lon: -89.994798,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.7279,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099389,
    shape_pt_lon: -89.994838,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.7403,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099732,
    shape_pt_lon: -89.995041,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.7837,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099847,
    shape_pt_lon: -89.995097,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.7967,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09983,
    shape_pt_lon: -89.995168,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8028,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099898,
    shape_pt_lon: -89.996967,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.9659,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099953,
    shape_pt_lon: -89.998471,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.102,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099559,
    shape_pt_lon: -89.998501,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.1461,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099193,
    shape_pt_lon: -89.998523,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.1871,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099119,
    shape_pt_lon: -89.998539,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.1954,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098564,
    shape_pt_lon: -89.99879,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.2615,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098402,
    shape_pt_lon: -89.998835,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.2809,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098127,
    shape_pt_lon: -89.99885,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.3109,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.097157,
    shape_pt_lon: -89.998884,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.42,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096281,
    shape_pt_lon: -89.998919,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.518,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09628,
    shape_pt_lon: -89.998719,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.536,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096272,
    shape_pt_lon: -89.998645,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.5431,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09623,
    shape_pt_lon: -89.998558,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.5525,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096193,
    shape_pt_lon: -89.998516,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.5575,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096113,
    shape_pt_lon: -89.998483,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.567,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096012,
    shape_pt_lon: -89.998478,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.5781,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.094671,
    shape_pt_lon: -89.998526,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.7281,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.0933,
    shape_pt_lon: -89.998598,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.8813,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092763,
    shape_pt_lon: -89.998629,
    shape_pt_sequence: 187,
    shape_dist_traveled: 9.9413,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09213,
    shape_pt_lon: -89.998654,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.0124,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091832,
    shape_pt_lon: -89.998657,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.0464,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091872,
    shape_pt_lon: -90.000102,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.1775,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091898,
    shape_pt_lon: -90.001038,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.2615,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091926,
    shape_pt_lon: -90.002009,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.3496,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091929,
    shape_pt_lon: -90.002117,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.3596,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09199,
    shape_pt_lon: -90.004341,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.5607,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091991,
    shape_pt_lon: -90.004377,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.5637,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091993,
    shape_pt_lon: -90.00444,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.5697,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.091993,
    shape_pt_lon: -90.00447,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.5717,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092031,
    shape_pt_lon: -90.005852,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.6968,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092097,
    shape_pt_lon: -90.008222,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.9119,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092146,
    shape_pt_lon: -90.00959,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.035,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092156,
    shape_pt_lon: -90.010248,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.095,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.090547,
    shape_pt_lon: -90.010281,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2751,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.089712,
    shape_pt_lon: -90.010313,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.3691,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.089096,
    shape_pt_lon: -90.010339,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.4381,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087732,
    shape_pt_lon: -90.010391,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.5902,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.08748,
    shape_pt_lon: -90.010408,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.6182,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087495,
    shape_pt_lon: -90.010591,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.6353,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087496,
    shape_pt_lon: -90.011388,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.7073,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087515,
    shape_pt_lon: -90.011768,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.7414,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087512,
    shape_pt_lon: -90.012902,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.8444,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087548,
    shape_pt_lon: -90.016567,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.1754,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087555,
    shape_pt_lon: -90.016937,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.2084,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.087918,
    shape_pt_lon: -90.016871,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.2489,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088052,
    shape_pt_lon: -90.016829,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.2642,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088138,
    shape_pt_lon: -90.016778,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.2754,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088208,
    shape_pt_lon: -90.016708,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.2854,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088275,
    shape_pt_lon: -90.016613,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.2968,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088481,
    shape_pt_lon: -90.01625,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.337,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088413,
    shape_pt_lon: -90.016049,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.3563,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088393,
    shape_pt_lon: -90.015923,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.3675,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088372,
    shape_pt_lon: -90.014649,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.4825,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088386,
    shape_pt_lon: -90.014508,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.4957,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088398,
    shape_pt_lon: -90.01447,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.4998,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088409,
    shape_pt_lon: -90.014448,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.502,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088463,
    shape_pt_lon: -90.014389,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.5098,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088539,
    shape_pt_lon: -90.014354,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.5193,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088619,
    shape_pt_lon: -90.014358,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.5283,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.08884,
    shape_pt_lon: -90.014491,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.5561,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.088955,
    shape_pt_lon: -90.01454,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.5691,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.089167,
    shape_pt_lon: -90.014592,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.5934,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.089586,
    shape_pt_lon: -90.014613,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.6404,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.090521,
    shape_pt_lon: -90.014649,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.7455,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.0907,
    shape_pt_lon: -90.014651,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.7655,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.090834,
    shape_pt_lon: -90.014647,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.7805,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092149,
    shape_pt_lon: -90.014609,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.9276,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092703,
    shape_pt_lon: -90.014608,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.9896,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092708,
    shape_pt_lon: -90.014093,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.0356,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.092929,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.0606,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09339,
    shape_pt_lon: -90.014076,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.1126,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.093469,
    shape_pt_lon: -90.014075,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.1216,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.093659,
    shape_pt_lon: -90.01407,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.1426,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09382,
    shape_pt_lon: -90.014067,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.1606,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.09409,
    shape_pt_lon: -90.014058,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.1907,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.094746,
    shape_pt_lon: -90.014029,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.2637,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.095406,
    shape_pt_lon: -90.014004,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.3377,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096068,
    shape_pt_lon: -90.01398,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.4117,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.096731,
    shape_pt_lon: -90.013948,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.4868,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.097398,
    shape_pt_lon: -90.01392,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.5609,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.097746,
    shape_pt_lon: -90.013895,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.5999,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.097884,
    shape_pt_lon: -90.013878,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.616,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098057,
    shape_pt_lon: -90.01382,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.6357,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098589,
    shape_pt_lon: -90.013542,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.7007,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098679,
    shape_pt_lon: -90.013506,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.7111,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.098842,
    shape_pt_lon: -90.013466,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.7296,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099139,
    shape_pt_lon: -90.013432,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.7627,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099567,
    shape_pt_lon: -90.013403,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.8107,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099785,
    shape_pt_lon: -90.01337,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.8349,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.099951,
    shape_pt_lon: -90.013308,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.8548,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.1001,
    shape_pt_lon: -90.013226,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.8732,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100201,
    shape_pt_lon: -90.013155,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.8863,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100506,
    shape_pt_lon: -90.012868,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.9291,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100676,
    shape_pt_lon: -90.012689,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.9539,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100708,
    shape_pt_lon: -90.012887,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.9724,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100726,
    shape_pt_lon: -90.01317,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.9984,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100752,
    shape_pt_lon: -90.014291,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.0994,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.100789,
    shape_pt_lon: -90.015177,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.1796,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.101558,
    shape_pt_lon: -90.015148,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.2656,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.1023,
    shape_pt_lon: -90.015118,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.3487,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.103042,
    shape_pt_lon: -90.015099,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.4317,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.103777,
    shape_pt_lon: -90.015071,
    shape_pt_sequence: 270,
    shape_dist_traveled: 14.5137,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10467,
    shape_pt_lon: -90.015033,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.6138,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10554,
    shape_pt_lon: -90.015006,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.7108,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.106732,
    shape_pt_lon: -90.014957,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.8449,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.107485,
    shape_pt_lon: -90.014931,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.9289,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10756,
    shape_pt_lon: -90.014929,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.9369,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.108316,
    shape_pt_lon: -90.0149,
    shape_pt_sequence: 276,
    shape_dist_traveled: 15.022,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10925,
    shape_pt_lon: -90.014871,
    shape_pt_sequence: 277,
    shape_dist_traveled: 15.126,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109291,
    shape_pt_lon: -90.01487,
    shape_pt_sequence: 278,
    shape_dist_traveled: 15.131,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109352,
    shape_pt_lon: -90.014869,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.138,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109376,
    shape_pt_lon: -90.014957,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.1463,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109451,
    shape_pt_lon: -90.015345,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.1824,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109472,
    shape_pt_lon: -90.015758,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.2194,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109518,
    shape_pt_lon: -90.017288,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.3575,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109533,
    shape_pt_lon: -90.017901,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.4136,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109557,
    shape_pt_lon: -90.018411,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.4597,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109579,
    shape_pt_lon: -90.019054,
    shape_pt_sequence: 286,
    shape_dist_traveled: 15.5177,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109643,
    shape_pt_lon: -90.020999,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.6938,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109652,
    shape_pt_lon: -90.021647,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.7519,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109655,
    shape_pt_lon: -90.021754,
    shape_pt_sequence: 289,
    shape_dist_traveled: 15.7619,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109656,
    shape_pt_lon: -90.021794,
    shape_pt_sequence: 290,
    shape_dist_traveled: 15.7659,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109659,
    shape_pt_lon: -90.021939,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.7789,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10968,
    shape_pt_lon: -90.022682,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.8459,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109703,
    shape_pt_lon: -90.023498,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.92,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109729,
    shape_pt_lon: -90.024332,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.995,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.10975,
    shape_pt_lon: -90.025198,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.0731,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109759,
    shape_pt_lon: -90.025483,
    shape_pt_sequence: 296,
    shape_dist_traveled: 16.0991,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109776,
    shape_pt_lon: -90.02603,
    shape_pt_sequence: 297,
    shape_dist_traveled: 16.1491,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.1098,
    shape_pt_lon: -90.026878,
    shape_pt_sequence: 298,
    shape_dist_traveled: 16.2252,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.109821,
    shape_pt_lon: -90.027736,
    shape_pt_sequence: 299,
    shape_dist_traveled: 16.3032,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.110262,
    shape_pt_lon: -90.027721,
    shape_pt_sequence: 300,
    shape_dist_traveled: 16.3522,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -90.027704,
    shape_pt_sequence: 301,
    shape_dist_traveled: 16.4043,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.111146,
    shape_pt_lon: -90.027671,
    shape_pt_sequence: 302,
    shape_dist_traveled: 16.4514,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.111705,
    shape_pt_lon: -90.027643,
    shape_pt_sequence: 303,
    shape_dist_traveled: 16.5134,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.113293,
    shape_pt_lon: -90.027587,
    shape_pt_sequence: 304,
    shape_dist_traveled: 16.6915,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.114165,
    shape_pt_lon: -90.027552,
    shape_pt_sequence: 305,
    shape_dist_traveled: 16.7895,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.114439,
    shape_pt_lon: -90.027541,
    shape_pt_sequence: 306,
    shape_dist_traveled: 16.8196,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115426,
    shape_pt_lon: -90.027519,
    shape_pt_sequence: 307,
    shape_dist_traveled: 16.9306,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115429,
    shape_pt_lon: -90.02769,
    shape_pt_sequence: 308,
    shape_dist_traveled: 16.9456,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 309,
    shape_dist_traveled: 16.9486,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115433,
    shape_pt_lon: -90.027829,
    shape_pt_sequence: 310,
    shape_dist_traveled: 16.9586,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 311,
    shape_dist_traveled: 16.9706,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115439,
    shape_pt_lon: -90.02805,
    shape_pt_sequence: 312,
    shape_dist_traveled: 16.9786,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -90.028441,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.0136,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115454,
    shape_pt_lon: -90.028823,
    shape_pt_sequence: 314,
    shape_dist_traveled: 17.0476,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115463,
    shape_pt_lon: -90.029251,
    shape_pt_sequence: 315,
    shape_dist_traveled: 17.0867,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.11547,
    shape_pt_lon: -90.029561,
    shape_pt_sequence: 316,
    shape_dist_traveled: 17.1147,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.115489,
    shape_pt_lon: -90.030319,
    shape_pt_sequence: 317,
    shape_dist_traveled: 17.1837,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.1155,
    shape_pt_lon: -90.030813,
    shape_pt_sequence: 318,
    shape_dist_traveled: 17.2277,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.116929,
    shape_pt_lon: -90.030767,
    shape_pt_sequence: 319,
    shape_dist_traveled: 17.3878,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.117313,
    shape_pt_lon: -90.030755,
    shape_pt_sequence: 320,
    shape_dist_traveled: 17.4308,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.117717,
    shape_pt_lon: -90.03074,
    shape_pt_sequence: 321,
    shape_dist_traveled: 17.4758,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.118335,
    shape_pt_lon: -90.030719,
    shape_pt_sequence: 322,
    shape_dist_traveled: 17.5448,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.118512,
    shape_pt_lon: -90.030712,
    shape_pt_sequence: 323,
    shape_dist_traveled: 17.5648,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119271,
    shape_pt_lon: -90.030686,
    shape_pt_sequence: 324,
    shape_dist_traveled: 17.6499,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119299,
    shape_pt_lon: -90.03179,
    shape_pt_sequence: 325,
    shape_dist_traveled: 17.7499,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.11932,
    shape_pt_lon: -90.032571,
    shape_pt_sequence: 326,
    shape_dist_traveled: 17.8199,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119328,
    shape_pt_lon: -90.032858,
    shape_pt_sequence: 327,
    shape_dist_traveled: 17.846,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119341,
    shape_pt_lon: -90.03338,
    shape_pt_sequence: 328,
    shape_dist_traveled: 17.893,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119355,
    shape_pt_lon: -90.03386,
    shape_pt_sequence: 329,
    shape_dist_traveled: 17.937,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119379,
    shape_pt_lon: -90.034777,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.0201,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.11941,
    shape_pt_lon: -90.03593,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.1241,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119412,
    shape_pt_lon: -90.036018,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.1322,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119432,
    shape_pt_lon: -90.036723,
    shape_pt_sequence: 333,
    shape_dist_traveled: 18.1962,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119458,
    shape_pt_lon: -90.037887,
    shape_pt_sequence: 334,
    shape_dist_traveled: 18.3012,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.119478,
    shape_pt_lon: -90.038756,
    shape_pt_sequence: 335,
    shape_dist_traveled: 18.3793,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.120394,
    shape_pt_lon: -90.039271,
    shape_pt_sequence: 336,
    shape_dist_traveled: 18.4916,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.120863,
    shape_pt_lon: -90.039532,
    shape_pt_sequence: 337,
    shape_dist_traveled: 18.5498,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.121288,
    shape_pt_lon: -90.039769,
    shape_pt_sequence: 338,
    shape_dist_traveled: 18.6012,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.122174,
    shape_pt_lon: -90.040269,
    shape_pt_sequence: 339,
    shape_dist_traveled: 18.71,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.122267,
    shape_pt_lon: -90.040321,
    shape_pt_sequence: 340,
    shape_dist_traveled: 18.7221,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.12316,
    shape_pt_lon: -90.040822,
    shape_pt_sequence: 341,
    shape_dist_traveled: 18.8317,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.12386,
    shape_pt_lon: -90.041209,
    shape_pt_sequence: 342,
    shape_dist_traveled: 18.9172,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.124216,
    shape_pt_lon: -90.04141,
    shape_pt_sequence: 343,
    shape_dist_traveled: 18.9611,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.124272,
    shape_pt_lon: -90.041443,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.9678,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.124633,
    shape_pt_lon: -90.041739,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.0169,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.125777,
    shape_pt_lon: -90.042661,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.1694,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.12635,
    shape_pt_lon: -90.043114,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.2454,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.126561,
    shape_pt_lon: -90.043276,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.2729,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.127315,
    shape_pt_lon: -90.04384,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.372,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.127796,
    shape_pt_lon: -90.044197,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.4339,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.127891,
    shape_pt_lon: -90.044259,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.4465,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.128068,
    shape_pt_lon: -90.044344,
    shape_pt_sequence: 352,
    shape_dist_traveled: 19.468,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -90.044428,
    shape_pt_sequence: 353,
    shape_dist_traveled: 19.494,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.128586,
    shape_pt_lon: -90.044532,
    shape_pt_sequence: 354,
    shape_dist_traveled: 19.5285,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.128648,
    shape_pt_lon: -90.044496,
    shape_pt_sequence: 355,
    shape_dist_traveled: 19.5365,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.128982,
    shape_pt_lon: -90.04443,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.574,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.12912,
    shape_pt_lon: -90.044413,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.59,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.129261,
    shape_pt_lon: -90.044437,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.6052,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.129313,
    shape_pt_lon: -90.044443,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.6112,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.129562,
    shape_pt_lon: -90.044417,
    shape_pt_sequence: 360,
    shape_dist_traveled: 19.6394,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.13058,
    shape_pt_lon: -90.044288,
    shape_pt_sequence: 361,
    shape_dist_traveled: 19.7539,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.131079,
    shape_pt_lon: -90.044215,
    shape_pt_sequence: 362,
    shape_dist_traveled: 19.8104,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.132159,
    shape_pt_lon: -90.044057,
    shape_pt_sequence: 363,
    shape_dist_traveled: 19.9322,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.132758,
    shape_pt_lon: -90.043972,
    shape_pt_sequence: 364,
    shape_dist_traveled: 19.9997,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.133392,
    shape_pt_lon: -90.04388,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.0711,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.133914,
    shape_pt_lon: -90.043803,
    shape_pt_sequence: 366,
    shape_dist_traveled: 20.1295,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134538,
    shape_pt_lon: -90.043692,
    shape_pt_sequence: 367,
    shape_dist_traveled: 20.2002,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134648,
    shape_pt_lon: -90.043655,
    shape_pt_sequence: 368,
    shape_dist_traveled: 20.2126,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.13473,
    shape_pt_lon: -90.044399,
    shape_pt_sequence: 369,
    shape_dist_traveled: 20.2802,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134779,
    shape_pt_lon: -90.044939,
    shape_pt_sequence: 370,
    shape_dist_traveled: 20.3296,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134911,
    shape_pt_lon: -90.046351,
    shape_pt_sequence: 371,
    shape_dist_traveled: 20.4575,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134916,
    shape_pt_lon: -90.046389,
    shape_pt_sequence: 372,
    shape_dist_traveled: 20.4615,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.134923,
    shape_pt_lon: -90.046476,
    shape_pt_sequence: 373,
    shape_dist_traveled: 20.4685,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.135266,
    shape_pt_lon: -90.050031,
    shape_pt_sequence: 374,
    shape_dist_traveled: 20.7928,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.135413,
    shape_pt_lon: -90.051589,
    shape_pt_sequence: 375,
    shape_dist_traveled: 20.9338,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.135536,
    shape_pt_lon: -90.052817,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.0455,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.135632,
    shape_pt_lon: -90.053788,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.1342,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.135667,
    shape_pt_lon: -90.0539,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.145,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.1922,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.3026,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.3611,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.4083,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.5248,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.5566,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 385,
    shape_dist_traveled: 21.6086,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 386,
    shape_dist_traveled: 21.6188,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 387,
    shape_dist_traveled: 21.6388,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 388,
    shape_dist_traveled: 21.6594,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 389,
    shape_dist_traveled: 21.6747,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 390,
    shape_dist_traveled: 21.7513,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 391,
    shape_dist_traveled: 21.8117,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 392,
    shape_dist_traveled: 21.8593,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 393,
    shape_dist_traveled: 21.8818,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 394,
    shape_dist_traveled: 21.9245,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.0011,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.0536,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.1159,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.1665,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.2357,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.2955,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.3534,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.41,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.4701,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 404,
    shape_dist_traveled: 22.5868,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 405,
    shape_dist_traveled: 22.7044,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 406,
    shape_dist_traveled: 22.7601,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 407,
    shape_dist_traveled: 22.8202,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 408,
    shape_dist_traveled: 22.9378,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 409,
    shape_dist_traveled: 23.0542,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 410,
    shape_dist_traveled: 23.1674,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 411,
    shape_dist_traveled: 23.1773,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 412,
    shape_dist_traveled: 23.1998,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 413,
    shape_dist_traveled: 23.2115,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 414,
    shape_dist_traveled: 23.2586,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 415,
    shape_dist_traveled: 23.2856,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 416,
    shape_dist_traveled: 23.3011,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 417,
    shape_dist_traveled: 23.4054,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 418,
    shape_dist_traveled: 23.5171,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 419,
    shape_dist_traveled: 23.5244,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 420,
    shape_dist_traveled: 23.5949,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 421,
    shape_dist_traveled: 23.642,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 422,
    shape_dist_traveled: 23.7018,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 423,
    shape_dist_traveled: 23.7246,
  },
  {
    shape_id: 38166,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 424,
    shape_dist_traveled: 23.791,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0058,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0188,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0329,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0399,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0494,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1005,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1036,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.11,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.116,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.144,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.157,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.1655,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.1713,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.1803,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.2554,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3135,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.3287,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.3357,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.5404,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.6807,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.751,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.751,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.8592,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9374,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0265,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0515,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1496,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2427,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.2858,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2978,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.3138,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3189,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067558,
    shape_pt_lon: -89.932289,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.3297,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067459,
    shape_pt_lon: -89.932293,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.3407,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.067092,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.3818,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.066671,
    shape_pt_lon: -89.932328,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.4288,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.065107,
    shape_pt_lon: -89.932411,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6039,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.064879,
    shape_pt_lon: -89.932423,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.6299,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.064291,
    shape_pt_lon: -89.932453,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.696,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.064029,
    shape_pt_lon: -89.932448,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.725,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.063716,
    shape_pt_lon: -89.932403,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.7602,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.063507,
    shape_pt_lon: -89.932347,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.7838,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.063191,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8214,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.062717,
    shape_pt_lon: -89.931944,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.88,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.062459,
    shape_pt_lon: -89.931786,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.9127,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.061961,
    shape_pt_lon: -89.931489,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.9739,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.061652,
    shape_pt_lon: -89.931341,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.0113,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.06123,
    shape_pt_lon: -89.9312,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.06,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.060961,
    shape_pt_lon: -89.931143,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.0905,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.060683,
    shape_pt_lon: -89.931106,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.1217,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.060302,
    shape_pt_lon: -89.931094,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.1647,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.059947,
    shape_pt_lon: -89.931126,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.2048,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.059509,
    shape_pt_lon: -89.931222,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.2547,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.059194,
    shape_pt_lon: -89.931322,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.2908,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.059069,
    shape_pt_lon: -89.931372,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.3054,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.05867,
    shape_pt_lon: -89.931552,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.3522,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057947,
    shape_pt_lon: -89.931951,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.4408,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057775,
    shape_pt_lon: -89.932061,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.4632,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057193,
    shape_pt_lon: -89.9324,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.5352,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057159,
    shape_pt_lon: -89.932421,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.5397,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057099,
    shape_pt_lon: -89.932456,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.5464,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.057,
    shape_pt_lon: -89.932508,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.5584,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.056851,
    shape_pt_lon: -89.932588,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.5768,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.056418,
    shape_pt_lon: -89.932841,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.631,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.055928,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.6905,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.055669,
    shape_pt_lon: -89.933286,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.7231,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.055378,
    shape_pt_lon: -89.933448,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.759,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.055259,
    shape_pt_lon: -89.933511,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.7733,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.055036,
    shape_pt_lon: -89.933617,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.8002,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.054887,
    shape_pt_lon: -89.933682,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.8182,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.054358,
    shape_pt_lon: -89.933866,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.8794,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.054075,
    shape_pt_lon: -89.933937,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.9119,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.05366,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.9585,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.053064,
    shape_pt_lon: -89.934154,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.0267,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.05298,
    shape_pt_lon: -89.934179,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.0359,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.052679,
    shape_pt_lon: -89.934248,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.0704,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.052349,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.1076,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.934276,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.1606,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051764,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.1736,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.05176,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1966,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051759,
    shape_pt_lon: -89.933918,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.2056,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051755,
    shape_pt_lon: -89.933766,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.2186,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051748,
    shape_pt_lon: -89.933588,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.2346,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051739,
    shape_pt_lon: -89.933392,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.2527,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051731,
    shape_pt_lon: -89.93318,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.2717,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051719,
    shape_pt_lon: -89.932847,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.3027,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.051673,
    shape_pt_lon: -89.931306,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.4418,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.050532,
    shape_pt_lon: -89.931286,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.5698,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.044065,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.2938,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.040507,
    shape_pt_lon: -89.931229,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.6918,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.038179,
    shape_pt_lon: -89.93122,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.9518,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036949,
    shape_pt_lon: -89.931208,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.0898,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036925,
    shape_pt_lon: -89.932389,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.1969,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036881,
    shape_pt_lon: -89.934089,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.35,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.93571,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.497,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936152,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.537,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936543,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.572,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036834,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.629,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.0368,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.633,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036767,
    shape_pt_lon: -89.93717,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.637,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.036123,
    shape_pt_lon: -89.937161,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.709,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.035849,
    shape_pt_lon: -89.937137,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.7401,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.035163,
    shape_pt_lon: -89.937052,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.8165,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.0348,
    shape_pt_lon: -89.937047,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.8575,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.034462,
    shape_pt_lon: -89.937079,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.8956,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.034377,
    shape_pt_lon: -89.937092,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.9047,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.034298,
    shape_pt_lon: -89.937106,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.9138,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.033956,
    shape_pt_lon: -89.937151,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.952,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.033633,
    shape_pt_lon: -89.937168,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.989,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.032969,
    shape_pt_lon: -89.937172,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.063,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.032404,
    shape_pt_lon: -89.937165,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.126,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.030784,
    shape_pt_lon: -89.93712,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.3071,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.030663,
    shape_pt_lon: -89.937044,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.3227,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.030277,
    shape_pt_lon: -89.937031,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.3657,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.029582,
    shape_pt_lon: -89.937016,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.4438,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.027972,
    shape_pt_lon: -89.936979,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.6238,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.026124,
    shape_pt_lon: -89.936958,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.8308,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.024229,
    shape_pt_lon: -89.936937,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.0428,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.02071,
    shape_pt_lon: -89.936884,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.4358,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.020649,
    shape_pt_lon: -89.936882,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.4428,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.020576,
    shape_pt_lon: -89.93688,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.4509,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.020052,
    shape_pt_lon: -89.936836,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.51,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.019792,
    shape_pt_lon: -89.936804,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.5392,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.0184,
    shape_pt_lon: -89.936799,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.6952,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.017036,
    shape_pt_lon: -89.936783,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.8472,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.016237,
    shape_pt_lon: -89.936777,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.9372,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.015769,
    shape_pt_lon: -89.936781,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.9892,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.015624,
    shape_pt_lon: -89.936788,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.0052,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.014839,
    shape_pt_lon: -89.936817,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.0933,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.014344,
    shape_pt_lon: -89.936848,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.1494,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.01387,
    shape_pt_lon: -89.93689,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.2024,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.013641,
    shape_pt_lon: -89.936889,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.2274,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.01323,
    shape_pt_lon: -89.936889,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.2734,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.011171,
    shape_pt_lon: -89.93687,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.5045,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.009559,
    shape_pt_lon: -89.936856,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.6845,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.008132,
    shape_pt_lon: -89.93684,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.8445,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.008012,
    shape_pt_lon: -89.93684,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8575,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.0775,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.3005,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.3425,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.3555,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.4345,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.5355,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.5705,
  },
  {
    shape_id: 38169,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.6305,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.175712,
    shape_pt_lon: -90.008378,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.174337,
    shape_pt_lon: -90.008404,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.154,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.173082,
    shape_pt_lon: -90.008452,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2951,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.2982,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.172973,
    shape_pt_lon: -90.008458,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.3072,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.172662,
    shape_pt_lon: -90.008473,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3422,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.172051,
    shape_pt_lon: -90.008501,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.4103,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.171766,
    shape_pt_lon: -90.008558,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4427,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.171383,
    shape_pt_lon: -90.008676,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4868,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.171111,
    shape_pt_lon: -90.00879,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5188,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.170649,
    shape_pt_lon: -90.009023,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5749,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.17018,
    shape_pt_lon: -90.009259,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.631,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.170029,
    shape_pt_lon: -90.009337,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.6493,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.168936,
    shape_pt_lon: -90.009896,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.7821,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.168629,
    shape_pt_lon: -90.010038,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.8185,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.168373,
    shape_pt_lon: -90.010133,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.8489,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.168159,
    shape_pt_lon: -90.010195,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.8734,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.168034,
    shape_pt_lon: -90.010221,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8877,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16773,
    shape_pt_lon: -90.010272,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.922,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.167341,
    shape_pt_lon: -90.010296,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.965,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165539,
    shape_pt_lon: -90.010343,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.1671,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165162,
    shape_pt_lon: -90.010337,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.2091,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.2591,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164727,
    shape_pt_lon: -90.010507,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.2722,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164765,
    shape_pt_lon: -90.010895,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.3075,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164836,
    shape_pt_lon: -90.011643,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.3759,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16484,
    shape_pt_lon: -90.011693,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.381,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16491,
    shape_pt_lon: -90.012433,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4474,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164918,
    shape_pt_lon: -90.012502,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4545,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164993,
    shape_pt_lon: -90.013267,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.524,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.164997,
    shape_pt_lon: -90.013295,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.526,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165072,
    shape_pt_lon: -90.014077,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.5976,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165151,
    shape_pt_lon: -90.014877,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6701,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16523,
    shape_pt_lon: -90.015676,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.7426,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165308,
    shape_pt_lon: -90.016482,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.8161,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165359,
    shape_pt_lon: -90.017013,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8645,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165438,
    shape_pt_lon: -90.017736,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9301,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165469,
    shape_pt_lon: -90.01796,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9513,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165503,
    shape_pt_lon: -90.018218,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9747,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165565,
    shape_pt_lon: -90.018664,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0153,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165574,
    shape_pt_lon: -90.018738,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0223,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16559,
    shape_pt_lon: -90.01886,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0334,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.16566,
    shape_pt_lon: -90.019526,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0939,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165779,
    shape_pt_lon: -90.020646,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1959,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165911,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.3068,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.021859,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3158,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.163811,
    shape_pt_lon: -90.022188,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.5436,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.163354,
    shape_pt_lon: -90.022263,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5951,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.162448,
    shape_pt_lon: -90.022414,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.6971,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.162102,
    shape_pt_lon: -90.022471,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.7364,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.161669,
    shape_pt_lon: -90.022542,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7848,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.161642,
    shape_pt_lon: -90.022548,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7879,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.161289,
    shape_pt_lon: -90.022607,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.8282,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.161008,
    shape_pt_lon: -90.022653,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.8595,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.160939,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.8675,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15925,
    shape_pt_lon: -90.022942,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0582,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.158187,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1779,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157517,
    shape_pt_lon: -90.023161,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2532,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.2572,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.3434,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3793,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.4053,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4693,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.5224,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5945,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6145,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7216,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.7346,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8367,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.8657,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.9448,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9648,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.0269,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0429,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.0579,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.0611,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.1101,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.1171,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.1741,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.1791,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.2182,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.305,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.3734,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4174,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.4636,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.5097,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.535,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.5734,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.5778,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.5895,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.6316,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.6663,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.6954,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.7164,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.8494,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.9464,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.0035,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.0685,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.1135,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.1329,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.1927,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.3106,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3701,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.4289,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.446,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.5087,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.5596,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.626,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.6488,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.7086,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.7171,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.8262,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.9303,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.9433,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.9487,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.0037,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.0251,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.0314,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.0395,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.0477,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.0604,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.1761,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.1992,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.2305,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.2893,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.3023,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.4115,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.4706,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.5291,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.6445,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.6993,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.7622,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.8277,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.8792,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.9358,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.9946,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.0209,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.0544,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.1135,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.1755,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.2321,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.2597,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.3061,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.3681,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.4064,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.476,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.57,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.6203,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.6759,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.701,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.7861,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.8919,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0039,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.1425,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.135667,
    shape_pt_lon: -90.0539,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.1896,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.135632,
    shape_pt_lon: -90.053788,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.2004,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.135536,
    shape_pt_lon: -90.052817,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.2891,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.135413,
    shape_pt_lon: -90.051589,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.4009,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.135266,
    shape_pt_lon: -90.050031,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.5419,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134923,
    shape_pt_lon: -90.046476,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.8661,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134916,
    shape_pt_lon: -90.046389,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.8732,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134911,
    shape_pt_lon: -90.046351,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.8772,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134779,
    shape_pt_lon: -90.044939,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.0051,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13473,
    shape_pt_lon: -90.044399,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.0544,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134648,
    shape_pt_lon: -90.043655,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.122,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134541,
    shape_pt_lon: -90.042447,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.2327,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13445,
    shape_pt_lon: -90.041519,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.3163,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134419,
    shape_pt_lon: -90.041217,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.3445,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134308,
    shape_pt_lon: -90.040018,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.4532,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.134108,
    shape_pt_lon: -90.03791,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.6455,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133991,
    shape_pt_lon: -90.03661,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.7632,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133941,
    shape_pt_lon: -90.036041,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.8146,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133879,
    shape_pt_lon: -90.03529,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.8829,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133854,
    shape_pt_lon: -90.035001,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.909,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133701,
    shape_pt_lon: -90.033345,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.06,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133647,
    shape_pt_lon: -90.032713,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.1174,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133597,
    shape_pt_lon: -90.032144,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.1696,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133591,
    shape_pt_lon: -90.032078,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.1757,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133515,
    shape_pt_lon: -90.031269,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.2492,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.133481,
    shape_pt_lon: -90.031028,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.2705,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.132985,
    shape_pt_lon: -90.031042,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.3265,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.132953,
    shape_pt_lon: -90.031045,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.3295,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.132401,
    shape_pt_lon: -90.031066,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.3916,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131833,
    shape_pt_lon: -90.03107,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.4546,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131798,
    shape_pt_lon: -90.029742,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.5746,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131782,
    shape_pt_lon: -90.029179,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.6257,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131762,
    shape_pt_lon: -90.028411,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.6947,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131761,
    shape_pt_lon: -90.028378,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.6977,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.027252,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.7997,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131741,
    shape_pt_lon: -90.02703,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.8198,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13174,
    shape_pt_lon: -90.026917,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.8298,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131729,
    shape_pt_lon: -90.026568,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.8618,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131693,
    shape_pt_lon: -90.026349,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.8812,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.909,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.9451,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.9651,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.0565,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.0771,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.1301,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.1346,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.1552,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.1619,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.1647,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.1825,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.2098,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.2434,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.32,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.384,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.4359,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.4517,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.4653,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.5005,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.6237,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.7535,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.8517,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.8989,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.9169,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.016751,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.9405,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.125917,
    shape_pt_lon: -90.016143,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.0091,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.124924,
    shape_pt_lon: -90.014434,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.1998,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.124501,
    shape_pt_lon: -90.013705,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.28,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.124171,
    shape_pt_lon: -90.013136,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.3438,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12348,
    shape_pt_lon: -90.011949,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.4757,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -90.011677,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.5065,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.123301,
    shape_pt_lon: -90.011644,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.5101,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.12279,
    shape_pt_lon: -90.01076,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.6083,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.122543,
    shape_pt_lon: -90.010346,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.6547,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.122363,
    shape_pt_lon: -90.010048,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.6883,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.121429,
    shape_pt_lon: -90.008595,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.8562,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.121217,
    shape_pt_lon: -90.008259,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.8948,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.120687,
    shape_pt_lon: -90.007442,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.9894,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.120106,
    shape_pt_lon: -90.006533,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.0941,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.1147,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.119937,
    shape_pt_lon: -90.006268,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.1247,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.119197,
    shape_pt_lon: -90.005107,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.2585,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.119091,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.277,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.118393,
    shape_pt_lon: -90.003858,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.403,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.118248,
    shape_pt_lon: -90.003629,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.4294,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11782,
    shape_pt_lon: -90.00296,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.5062,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.117619,
    shape_pt_lon: -90.002647,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.5418,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11727,
    shape_pt_lon: -90.002104,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.6051,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.117101,
    shape_pt_lon: -90.001834,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.6359,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11686,
    shape_pt_lon: -90.001403,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.6833,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.116827,
    shape_pt_lon: -90.001336,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.6905,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.116452,
    shape_pt_lon: -90.000693,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.7622,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.116358,
    shape_pt_lon: -90.000539,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.7794,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.116176,
    shape_pt_lon: -90.00028,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.8105,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.115773,
    shape_pt_lon: -89.999719,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.8785,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.115591,
    shape_pt_lon: -89.999479,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.9083,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11536,
    shape_pt_lon: -89.999139,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.9487,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.113746,
    shape_pt_lon: -89.996673,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.2353,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.113717,
    shape_pt_lon: -89.996628,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.2409,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.113499,
    shape_pt_lon: -89.996299,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.2786,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.113236,
    shape_pt_lon: -89.995889,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.3256,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.113011,
    shape_pt_lon: -89.995553,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.3661,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.112618,
    shape_pt_lon: -89.994952,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.4357,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.112561,
    shape_pt_lon: -89.994868,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.4457,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.112193,
    shape_pt_lon: -89.994306,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.5112,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.111537,
    shape_pt_lon: -89.993302,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.627,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11106,
    shape_pt_lon: -89.992569,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.7131,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.7737,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.7879,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.8043,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.8463,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.9963,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.0287,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.0965,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.11,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.1406,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.1597,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108396,
    shape_pt_lon: -89.988522,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.1861,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.108348,
    shape_pt_lon: -89.988462,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.1932,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.107991,
    shape_pt_lon: -89.988071,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.247,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.107749,
    shape_pt_lon: -89.987853,
    shape_pt_sequence: 274,
    shape_dist_traveled: 15.28,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.107475,
    shape_pt_lon: -89.987644,
    shape_pt_sequence: 275,
    shape_dist_traveled: 15.3155,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -89.987492,
    shape_pt_sequence: 276,
    shape_dist_traveled: 15.345,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.106839,
    shape_pt_lon: -89.987218,
    shape_pt_sequence: 277,
    shape_dist_traveled: 15.3974,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 278,
    shape_dist_traveled: 15.4396,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.10627,
    shape_pt_lon: -89.986761,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.4726,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105949,
    shape_pt_lon: -89.986453,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.5182,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105683,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.5585,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105579,
    shape_pt_lon: -89.986008,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.5762,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -89.985738,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.6089,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.6181,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105203,
    shape_pt_lon: -89.985466,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.6408,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.105062,
    shape_pt_lon: -89.98525,
    shape_pt_sequence: 286,
    shape_dist_traveled: 15.6658,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.104927,
    shape_pt_lon: -89.985038,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.69,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.10462,
    shape_pt_lon: -89.984552,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.7462,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.104387,
    shape_pt_lon: -89.984177,
    shape_pt_sequence: 289,
    shape_dist_traveled: 15.789,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.104063,
    shape_pt_lon: -89.983699,
    shape_pt_sequence: 290,
    shape_dist_traveled: 15.8451,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.1036,
    shape_pt_lon: -89.982976,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.9291,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.102607,
    shape_pt_lon: -89.981424,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.1078,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.102272,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.1684,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.102028,
    shape_pt_lon: -89.980468,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.2158,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.101844,
    shape_pt_lon: -89.980151,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.2508,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.100876,
    shape_pt_lon: -89.978307,
    shape_pt_sequence: 296,
    shape_dist_traveled: 16.4497,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -89.978038,
    shape_pt_sequence: 297,
    shape_dist_traveled: 16.4785,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.977596,
    shape_pt_sequence: 298,
    shape_dist_traveled: 16.5263,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.099702,
    shape_pt_lon: -89.976056,
    shape_pt_sequence: 299,
    shape_dist_traveled: 16.6921,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.099554,
    shape_pt_lon: -89.975775,
    shape_pt_sequence: 300,
    shape_dist_traveled: 16.7224,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.099477,
    shape_pt_lon: -89.975625,
    shape_pt_sequence: 301,
    shape_dist_traveled: 16.7385,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.099079,
    shape_pt_lon: -89.974863,
    shape_pt_sequence: 302,
    shape_dist_traveled: 16.8209,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.098876,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 303,
    shape_dist_traveled: 16.8628,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.098556,
    shape_pt_lon: -89.973858,
    shape_pt_sequence: 304,
    shape_dist_traveled: 16.928,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.098287,
    shape_pt_lon: -89.973401,
    shape_pt_sequence: 305,
    shape_dist_traveled: 16.9796,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.098014,
    shape_pt_lon: -89.973008,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.0263,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.09781,
    shape_pt_lon: -89.972738,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.0603,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.972644,
    shape_pt_sequence: 308,
    shape_dist_traveled: 17.0723,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.097492,
    shape_pt_lon: -89.972371,
    shape_pt_sequence: 309,
    shape_dist_traveled: 17.1084,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.097178,
    shape_pt_lon: -89.972056,
    shape_pt_sequence: 310,
    shape_dist_traveled: 17.1532,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.096506,
    shape_pt_lon: -89.971448,
    shape_pt_sequence: 311,
    shape_dist_traveled: 17.2468,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.096076,
    shape_pt_lon: -89.971066,
    shape_pt_sequence: 312,
    shape_dist_traveled: 17.3065,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.095461,
    shape_pt_lon: -89.970517,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.3909,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.095147,
    shape_pt_lon: -89.97023,
    shape_pt_sequence: 314,
    shape_dist_traveled: 17.4345,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.095028,
    shape_pt_lon: -89.970124,
    shape_pt_sequence: 315,
    shape_dist_traveled: 17.4511,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.094655,
    shape_pt_lon: -89.969791,
    shape_pt_sequence: 316,
    shape_dist_traveled: 17.5025,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.092773,
    shape_pt_lon: -89.968117,
    shape_pt_sequence: 317,
    shape_dist_traveled: 17.762,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.09271,
    shape_pt_lon: -89.968051,
    shape_pt_sequence: 318,
    shape_dist_traveled: 17.7712,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.092173,
    shape_pt_lon: -89.967574,
    shape_pt_sequence: 319,
    shape_dist_traveled: 17.845,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.09159,
    shape_pt_lon: -89.967051,
    shape_pt_sequence: 320,
    shape_dist_traveled: 17.9258,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.091458,
    shape_pt_lon: -89.96694,
    shape_pt_sequence: 321,
    shape_dist_traveled: 17.9438,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.090861,
    shape_pt_lon: -89.966416,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.0257,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.090059,
    shape_pt_lon: -89.965768,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.1325,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08799,
    shape_pt_lon: -89.964163,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.4061,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08761,
    shape_pt_lon: -89.96387,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.456,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.087159,
    shape_pt_lon: -89.96349,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.5173,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.086457,
    shape_pt_lon: -89.962842,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.6151,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08599,
    shape_pt_lon: -89.962362,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.6826,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.085793,
    shape_pt_lon: -89.962153,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.7116,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.085059,
    shape_pt_lon: -89.961303,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.8241,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.084872,
    shape_pt_lon: -89.961078,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.8538,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.084278,
    shape_pt_lon: -89.960361,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.9465,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.083742,
    shape_pt_lon: -89.959723,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.0292,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.083205,
    shape_pt_lon: -89.959084,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.1134,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.083178,
    shape_pt_lon: -89.959052,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.1176,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08305,
    shape_pt_lon: -89.958897,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.1374,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.082897,
    shape_pt_lon: -89.958712,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.1615,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08266,
    shape_pt_lon: -89.95843,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.1975,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.082189,
    shape_pt_lon: -89.957871,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.2711,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.082102,
    shape_pt_lon: -89.957772,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.2845,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.081846,
    shape_pt_lon: -89.957458,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.3241,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.081418,
    shape_pt_lon: -89.956946,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.3913,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08121,
    shape_pt_lon: -89.956703,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.4231,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.081141,
    shape_pt_lon: -89.956621,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.4338,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.081081,
    shape_pt_lon: -89.956547,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.4437,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.081057,
    shape_pt_lon: -89.956517,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.4473,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.080586,
    shape_pt_lon: -89.955955,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.5201,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.080212,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.5788,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.08011,
    shape_pt_lon: -89.955388,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.5944,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.080076,
    shape_pt_lon: -89.955347,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.5994,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.079874,
    shape_pt_lon: -89.955103,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.6312,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.079393,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 352,
    shape_dist_traveled: 19.7061,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.079346,
    shape_pt_lon: -89.954475,
    shape_pt_sequence: 353,
    shape_dist_traveled: 19.7132,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.078746,
    shape_pt_lon: -89.953756,
    shape_pt_sequence: 354,
    shape_dist_traveled: 19.8066,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.078247,
    shape_pt_lon: -89.953157,
    shape_pt_sequence: 355,
    shape_dist_traveled: 19.8851,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.078091,
    shape_pt_lon: -89.952973,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.9084,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.077269,
    shape_pt_lon: -89.951971,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.0378,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07722,
    shape_pt_lon: -89.951911,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.0449,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07679,
    shape_pt_lon: -89.95139,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.1128,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.1447,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.1692,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.1945,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.2132,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.2424,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.3034,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 366,
    shape_dist_traveled: 20.3215,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 367,
    shape_dist_traveled: 20.3625,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 368,
    shape_dist_traveled: 20.8676,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 369,
    shape_dist_traveled: 20.9807,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.0278,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.7227,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.7728,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.7818,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.7876,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.7961,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.8091,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.8702,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.876,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.889,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.9031,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.9101,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.9196,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.9707,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.9738,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 385,
    shape_dist_traveled: 21.9802,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 386,
    shape_dist_traveled: 21.9862,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.0142,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.0272,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.0357,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.0415,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.0505,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.1257,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.1837,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.1989,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.2059,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.4106,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.5509,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.6212,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.6212,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.7294,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.8076,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.8967,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.9217,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 404,
    shape_dist_traveled: 23.0198,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 405,
    shape_dist_traveled: 23.1129,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 406,
    shape_dist_traveled: 23.156,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 407,
    shape_dist_traveled: 23.168,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 408,
    shape_dist_traveled: 23.184,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 409,
    shape_dist_traveled: 23.1891,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067558,
    shape_pt_lon: -89.932289,
    shape_pt_sequence: 410,
    shape_dist_traveled: 23.1999,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067459,
    shape_pt_lon: -89.932293,
    shape_pt_sequence: 411,
    shape_dist_traveled: 23.2109,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.067092,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 412,
    shape_dist_traveled: 23.252,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.066671,
    shape_pt_lon: -89.932328,
    shape_pt_sequence: 413,
    shape_dist_traveled: 23.299,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.065107,
    shape_pt_lon: -89.932411,
    shape_pt_sequence: 414,
    shape_dist_traveled: 23.4741,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.064879,
    shape_pt_lon: -89.932423,
    shape_pt_sequence: 415,
    shape_dist_traveled: 23.5001,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.064291,
    shape_pt_lon: -89.932453,
    shape_pt_sequence: 416,
    shape_dist_traveled: 23.5662,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.064029,
    shape_pt_lon: -89.932448,
    shape_pt_sequence: 417,
    shape_dist_traveled: 23.5952,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.063716,
    shape_pt_lon: -89.932403,
    shape_pt_sequence: 418,
    shape_dist_traveled: 23.6304,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.063507,
    shape_pt_lon: -89.932347,
    shape_pt_sequence: 419,
    shape_dist_traveled: 23.654,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.063191,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 420,
    shape_dist_traveled: 23.6916,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.062717,
    shape_pt_lon: -89.931944,
    shape_pt_sequence: 421,
    shape_dist_traveled: 23.7502,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.062459,
    shape_pt_lon: -89.931786,
    shape_pt_sequence: 422,
    shape_dist_traveled: 23.7829,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.061961,
    shape_pt_lon: -89.931489,
    shape_pt_sequence: 423,
    shape_dist_traveled: 23.8441,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.061652,
    shape_pt_lon: -89.931341,
    shape_pt_sequence: 424,
    shape_dist_traveled: 23.8815,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.06123,
    shape_pt_lon: -89.9312,
    shape_pt_sequence: 425,
    shape_dist_traveled: 23.9302,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.060961,
    shape_pt_lon: -89.931143,
    shape_pt_sequence: 426,
    shape_dist_traveled: 23.9607,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.060683,
    shape_pt_lon: -89.931106,
    shape_pt_sequence: 427,
    shape_dist_traveled: 23.9919,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.060302,
    shape_pt_lon: -89.931094,
    shape_pt_sequence: 428,
    shape_dist_traveled: 24.0349,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.059947,
    shape_pt_lon: -89.931126,
    shape_pt_sequence: 429,
    shape_dist_traveled: 24.075,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.059509,
    shape_pt_lon: -89.931222,
    shape_pt_sequence: 430,
    shape_dist_traveled: 24.1249,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.059194,
    shape_pt_lon: -89.931322,
    shape_pt_sequence: 431,
    shape_dist_traveled: 24.161,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.059069,
    shape_pt_lon: -89.931372,
    shape_pt_sequence: 432,
    shape_dist_traveled: 24.1756,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.05867,
    shape_pt_lon: -89.931552,
    shape_pt_sequence: 433,
    shape_dist_traveled: 24.2224,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057947,
    shape_pt_lon: -89.931951,
    shape_pt_sequence: 434,
    shape_dist_traveled: 24.311,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057775,
    shape_pt_lon: -89.932061,
    shape_pt_sequence: 435,
    shape_dist_traveled: 24.3334,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057193,
    shape_pt_lon: -89.9324,
    shape_pt_sequence: 436,
    shape_dist_traveled: 24.4054,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057159,
    shape_pt_lon: -89.932421,
    shape_pt_sequence: 437,
    shape_dist_traveled: 24.4099,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057099,
    shape_pt_lon: -89.932456,
    shape_pt_sequence: 438,
    shape_dist_traveled: 24.4166,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.057,
    shape_pt_lon: -89.932508,
    shape_pt_sequence: 439,
    shape_dist_traveled: 24.4286,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.056851,
    shape_pt_lon: -89.932588,
    shape_pt_sequence: 440,
    shape_dist_traveled: 24.447,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.056418,
    shape_pt_lon: -89.932841,
    shape_pt_sequence: 441,
    shape_dist_traveled: 24.5012,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.055928,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 442,
    shape_dist_traveled: 24.5607,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.055669,
    shape_pt_lon: -89.933286,
    shape_pt_sequence: 443,
    shape_dist_traveled: 24.5933,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.055378,
    shape_pt_lon: -89.933448,
    shape_pt_sequence: 444,
    shape_dist_traveled: 24.6292,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.055259,
    shape_pt_lon: -89.933511,
    shape_pt_sequence: 445,
    shape_dist_traveled: 24.6435,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.055036,
    shape_pt_lon: -89.933617,
    shape_pt_sequence: 446,
    shape_dist_traveled: 24.6704,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.054887,
    shape_pt_lon: -89.933682,
    shape_pt_sequence: 447,
    shape_dist_traveled: 24.6884,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.054358,
    shape_pt_lon: -89.933866,
    shape_pt_sequence: 448,
    shape_dist_traveled: 24.7496,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.054075,
    shape_pt_lon: -89.933937,
    shape_pt_sequence: 449,
    shape_dist_traveled: 24.7821,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.05366,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 450,
    shape_dist_traveled: 24.8287,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.053064,
    shape_pt_lon: -89.934154,
    shape_pt_sequence: 451,
    shape_dist_traveled: 24.8969,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.05298,
    shape_pt_lon: -89.934179,
    shape_pt_sequence: 452,
    shape_dist_traveled: 24.9061,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.052679,
    shape_pt_lon: -89.934248,
    shape_pt_sequence: 453,
    shape_dist_traveled: 24.9407,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.052349,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 454,
    shape_dist_traveled: 24.9778,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.934276,
    shape_pt_sequence: 455,
    shape_dist_traveled: 25.0308,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051764,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 456,
    shape_dist_traveled: 25.0438,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.05176,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 457,
    shape_dist_traveled: 25.0668,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051759,
    shape_pt_lon: -89.933918,
    shape_pt_sequence: 458,
    shape_dist_traveled: 25.0758,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051755,
    shape_pt_lon: -89.933766,
    shape_pt_sequence: 459,
    shape_dist_traveled: 25.0888,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051748,
    shape_pt_lon: -89.933588,
    shape_pt_sequence: 460,
    shape_dist_traveled: 25.1048,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051739,
    shape_pt_lon: -89.933392,
    shape_pt_sequence: 461,
    shape_dist_traveled: 25.1229,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051731,
    shape_pt_lon: -89.93318,
    shape_pt_sequence: 462,
    shape_dist_traveled: 25.1419,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051719,
    shape_pt_lon: -89.932847,
    shape_pt_sequence: 463,
    shape_dist_traveled: 25.1729,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.051673,
    shape_pt_lon: -89.931306,
    shape_pt_sequence: 464,
    shape_dist_traveled: 25.312,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.050532,
    shape_pt_lon: -89.931286,
    shape_pt_sequence: 465,
    shape_dist_traveled: 25.44,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.044065,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 466,
    shape_dist_traveled: 26.164,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.040507,
    shape_pt_lon: -89.931229,
    shape_pt_sequence: 467,
    shape_dist_traveled: 26.562,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.038179,
    shape_pt_lon: -89.93122,
    shape_pt_sequence: 468,
    shape_dist_traveled: 26.822,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036949,
    shape_pt_lon: -89.931208,
    shape_pt_sequence: 469,
    shape_dist_traveled: 26.96,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036925,
    shape_pt_lon: -89.932389,
    shape_pt_sequence: 470,
    shape_dist_traveled: 27.0671,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036881,
    shape_pt_lon: -89.934089,
    shape_pt_sequence: 471,
    shape_dist_traveled: 27.2202,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.93571,
    shape_pt_sequence: 472,
    shape_dist_traveled: 27.3672,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936152,
    shape_pt_sequence: 473,
    shape_dist_traveled: 27.4072,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936543,
    shape_pt_sequence: 474,
    shape_dist_traveled: 27.4422,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036834,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 475,
    shape_dist_traveled: 27.4992,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.0368,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 476,
    shape_dist_traveled: 27.5032,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036767,
    shape_pt_lon: -89.93717,
    shape_pt_sequence: 477,
    shape_dist_traveled: 27.5072,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.036123,
    shape_pt_lon: -89.937161,
    shape_pt_sequence: 478,
    shape_dist_traveled: 27.5792,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.035849,
    shape_pt_lon: -89.937137,
    shape_pt_sequence: 479,
    shape_dist_traveled: 27.6103,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.035163,
    shape_pt_lon: -89.937052,
    shape_pt_sequence: 480,
    shape_dist_traveled: 27.6867,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.0348,
    shape_pt_lon: -89.937047,
    shape_pt_sequence: 481,
    shape_dist_traveled: 27.7277,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.034462,
    shape_pt_lon: -89.937079,
    shape_pt_sequence: 482,
    shape_dist_traveled: 27.7658,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.034377,
    shape_pt_lon: -89.937092,
    shape_pt_sequence: 483,
    shape_dist_traveled: 27.7749,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.034298,
    shape_pt_lon: -89.937106,
    shape_pt_sequence: 484,
    shape_dist_traveled: 27.784,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.033956,
    shape_pt_lon: -89.937151,
    shape_pt_sequence: 485,
    shape_dist_traveled: 27.8222,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.033633,
    shape_pt_lon: -89.937168,
    shape_pt_sequence: 486,
    shape_dist_traveled: 27.8592,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.032969,
    shape_pt_lon: -89.937172,
    shape_pt_sequence: 487,
    shape_dist_traveled: 27.9332,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.032404,
    shape_pt_lon: -89.937165,
    shape_pt_sequence: 488,
    shape_dist_traveled: 27.9962,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.030784,
    shape_pt_lon: -89.93712,
    shape_pt_sequence: 489,
    shape_dist_traveled: 28.1773,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.030663,
    shape_pt_lon: -89.937044,
    shape_pt_sequence: 490,
    shape_dist_traveled: 28.1929,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.030277,
    shape_pt_lon: -89.937031,
    shape_pt_sequence: 491,
    shape_dist_traveled: 28.2359,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.029582,
    shape_pt_lon: -89.937016,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.314,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.027972,
    shape_pt_lon: -89.936979,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.494,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.026124,
    shape_pt_lon: -89.936958,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.701,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.024229,
    shape_pt_lon: -89.936937,
    shape_pt_sequence: 495,
    shape_dist_traveled: 28.913,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.02071,
    shape_pt_lon: -89.936884,
    shape_pt_sequence: 496,
    shape_dist_traveled: 29.306,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.020649,
    shape_pt_lon: -89.936882,
    shape_pt_sequence: 497,
    shape_dist_traveled: 29.313,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.020576,
    shape_pt_lon: -89.93688,
    shape_pt_sequence: 498,
    shape_dist_traveled: 29.3211,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.020052,
    shape_pt_lon: -89.936836,
    shape_pt_sequence: 499,
    shape_dist_traveled: 29.3802,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.019792,
    shape_pt_lon: -89.936804,
    shape_pt_sequence: 500,
    shape_dist_traveled: 29.4094,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.0184,
    shape_pt_lon: -89.936799,
    shape_pt_sequence: 501,
    shape_dist_traveled: 29.5654,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.017036,
    shape_pt_lon: -89.936783,
    shape_pt_sequence: 502,
    shape_dist_traveled: 29.7174,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.016237,
    shape_pt_lon: -89.936777,
    shape_pt_sequence: 503,
    shape_dist_traveled: 29.8074,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.015769,
    shape_pt_lon: -89.936781,
    shape_pt_sequence: 504,
    shape_dist_traveled: 29.8594,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.015624,
    shape_pt_lon: -89.936788,
    shape_pt_sequence: 505,
    shape_dist_traveled: 29.8754,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.014839,
    shape_pt_lon: -89.936817,
    shape_pt_sequence: 506,
    shape_dist_traveled: 29.9635,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.014344,
    shape_pt_lon: -89.936848,
    shape_pt_sequence: 507,
    shape_dist_traveled: 30.0196,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.01387,
    shape_pt_lon: -89.93689,
    shape_pt_sequence: 508,
    shape_dist_traveled: 30.0726,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.013641,
    shape_pt_lon: -89.936889,
    shape_pt_sequence: 509,
    shape_dist_traveled: 30.0976,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.01323,
    shape_pt_lon: -89.936889,
    shape_pt_sequence: 510,
    shape_dist_traveled: 30.1436,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.011171,
    shape_pt_lon: -89.93687,
    shape_pt_sequence: 511,
    shape_dist_traveled: 30.3747,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.009559,
    shape_pt_lon: -89.936856,
    shape_pt_sequence: 512,
    shape_dist_traveled: 30.5547,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.008132,
    shape_pt_lon: -89.93684,
    shape_pt_sequence: 513,
    shape_dist_traveled: 30.7147,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.008012,
    shape_pt_lon: -89.93684,
    shape_pt_sequence: 514,
    shape_dist_traveled: 30.7277,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 515,
    shape_dist_traveled: 30.9477,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 516,
    shape_dist_traveled: 31.1707,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 517,
    shape_dist_traveled: 31.2127,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 518,
    shape_dist_traveled: 31.2257,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 519,
    shape_dist_traveled: 31.3047,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 520,
    shape_dist_traveled: 31.4057,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 521,
    shape_dist_traveled: 31.4407,
  },
  {
    shape_id: 38170,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 522,
    shape_dist_traveled: 31.5007,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.06,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.095,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.196,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.275,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.288,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3301,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.5531,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006049,
    shape_pt_lon: -89.936093,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.6181,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.93365,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.8391,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.931137,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.0671,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.930648,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.1111,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006033,
    shape_pt_lon: -89.929494,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.2151,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.927998,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.3511,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.924019,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.7111,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00606,
    shape_pt_lon: -89.923647,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.7451,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00605,
    shape_pt_lon: -89.919066,
    shape_pt_sequence: 17,
    shape_dist_traveled: 2.1591,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006038,
    shape_pt_lon: -89.916056,
    shape_pt_sequence: 18,
    shape_dist_traveled: 2.4321,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.915058,
    shape_pt_sequence: 19,
    shape_dist_traveled: 2.5221,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006021,
    shape_pt_lon: -89.913257,
    shape_pt_sequence: 20,
    shape_dist_traveled: 2.6851,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006019,
    shape_pt_lon: -89.91084,
    shape_pt_sequence: 21,
    shape_dist_traveled: 2.9041,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.910567,
    shape_pt_sequence: 22,
    shape_dist_traveled: 2.9282,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00603,
    shape_pt_lon: -89.910074,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.9732,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006026,
    shape_pt_lon: -89.909113,
    shape_pt_sequence: 24,
    shape_dist_traveled: 3.0602,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00602,
    shape_pt_lon: -89.907796,
    shape_pt_sequence: 25,
    shape_dist_traveled: 3.1792,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.006017,
    shape_pt_lon: -89.907199,
    shape_pt_sequence: 26,
    shape_dist_traveled: 3.2332,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.906361,
    shape_pt_sequence: 27,
    shape_dist_traveled: 3.3092,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005979,
    shape_pt_lon: -89.904696,
    shape_pt_sequence: 28,
    shape_dist_traveled: 3.4602,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005971,
    shape_pt_lon: -89.904277,
    shape_pt_sequence: 29,
    shape_dist_traveled: 3.4982,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005968,
    shape_pt_lon: -89.903436,
    shape_pt_sequence: 30,
    shape_dist_traveled: 3.5742,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.902176,
    shape_pt_sequence: 31,
    shape_dist_traveled: 3.6882,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.901498,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.7492,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005959,
    shape_pt_lon: -89.900487,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.8412,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005977,
    shape_pt_lon: -89.899086,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.9683,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.895486,
    shape_pt_sequence: 35,
    shape_dist_traveled: 4.2943,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005967,
    shape_pt_lon: -89.894096,
    shape_pt_sequence: 36,
    shape_dist_traveled: 4.4193,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005961,
    shape_pt_lon: -89.893764,
    shape_pt_sequence: 37,
    shape_dist_traveled: 4.4493,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.893538,
    shape_pt_sequence: 38,
    shape_dist_traveled: 4.4703,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005969,
    shape_pt_lon: -89.892566,
    shape_pt_sequence: 39,
    shape_dist_traveled: 4.5583,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005981,
    shape_pt_lon: -89.892098,
    shape_pt_sequence: 40,
    shape_dist_traveled: 4.6003,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00599,
    shape_pt_lon: -89.891662,
    shape_pt_sequence: 41,
    shape_dist_traveled: 4.6403,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005993,
    shape_pt_lon: -89.889476,
    shape_pt_sequence: 42,
    shape_dist_traveled: 4.8373,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005978,
    shape_pt_lon: -89.887981,
    shape_pt_sequence: 43,
    shape_dist_traveled: 4.9734,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.887272,
    shape_pt_sequence: 44,
    shape_dist_traveled: 5.0374,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005956,
    shape_pt_lon: -89.88715,
    shape_pt_sequence: 45,
    shape_dist_traveled: 5.0484,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005949,
    shape_pt_lon: -89.887027,
    shape_pt_sequence: 46,
    shape_dist_traveled: 5.0594,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005937,
    shape_pt_lon: -89.886656,
    shape_pt_sequence: 47,
    shape_dist_traveled: 5.0935,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00594,
    shape_pt_lon: -89.886328,
    shape_pt_sequence: 48,
    shape_dist_traveled: 5.1225,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.884633,
    shape_pt_sequence: 49,
    shape_dist_traveled: 5.2765,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.88395,
    shape_pt_sequence: 50,
    shape_dist_traveled: 5.3385,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005929,
    shape_pt_lon: -89.883789,
    shape_pt_sequence: 51,
    shape_dist_traveled: 5.3525,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005927,
    shape_pt_lon: -89.882696,
    shape_pt_sequence: 52,
    shape_dist_traveled: 5.4515,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005916,
    shape_pt_lon: -89.88176,
    shape_pt_sequence: 53,
    shape_dist_traveled: 5.5365,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005906,
    shape_pt_lon: -89.880732,
    shape_pt_sequence: 54,
    shape_dist_traveled: 5.6295,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005905,
    shape_pt_lon: -89.880578,
    shape_pt_sequence: 55,
    shape_dist_traveled: 5.6435,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005902,
    shape_pt_lon: -89.880352,
    shape_pt_sequence: 56,
    shape_dist_traveled: 5.6635,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005899,
    shape_pt_lon: -89.879792,
    shape_pt_sequence: 57,
    shape_dist_traveled: 5.7145,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005891,
    shape_pt_lon: -89.878439,
    shape_pt_sequence: 58,
    shape_dist_traveled: 5.8365,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005886,
    shape_pt_lon: -89.8772,
    shape_pt_sequence: 59,
    shape_dist_traveled: 5.9495,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005879,
    shape_pt_lon: -89.876634,
    shape_pt_sequence: 60,
    shape_dist_traveled: 6.0005,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005878,
    shape_pt_lon: -89.876388,
    shape_pt_sequence: 61,
    shape_dist_traveled: 6.0225,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005877,
    shape_pt_lon: -89.875961,
    shape_pt_sequence: 62,
    shape_dist_traveled: 6.0615,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005874,
    shape_pt_lon: -89.875289,
    shape_pt_sequence: 63,
    shape_dist_traveled: 6.1225,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005854,
    shape_pt_lon: -89.871795,
    shape_pt_sequence: 64,
    shape_dist_traveled: 6.4385,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005849,
    shape_pt_lon: -89.870967,
    shape_pt_sequence: 65,
    shape_dist_traveled: 6.5135,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005837,
    shape_pt_lon: -89.869432,
    shape_pt_sequence: 66,
    shape_dist_traveled: 6.6526,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005831,
    shape_pt_lon: -89.86839,
    shape_pt_sequence: 67,
    shape_dist_traveled: 6.7466,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005811,
    shape_pt_lon: -89.866213,
    shape_pt_sequence: 68,
    shape_dist_traveled: 6.9436,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005817,
    shape_pt_lon: -89.864401,
    shape_pt_sequence: 69,
    shape_dist_traveled: 7.1076,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005817,
    shape_pt_lon: -89.863258,
    shape_pt_sequence: 70,
    shape_dist_traveled: 7.2116,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005811,
    shape_pt_lon: -89.859679,
    shape_pt_sequence: 71,
    shape_dist_traveled: 7.5356,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005809,
    shape_pt_lon: -89.85884,
    shape_pt_sequence: 72,
    shape_dist_traveled: 7.6106,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005799,
    shape_pt_lon: -89.857674,
    shape_pt_sequence: 73,
    shape_dist_traveled: 7.7166,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00581,
    shape_pt_lon: -89.857058,
    shape_pt_sequence: 74,
    shape_dist_traveled: 7.7726,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005803,
    shape_pt_lon: -89.853289,
    shape_pt_sequence: 75,
    shape_dist_traveled: 8.1136,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005808,
    shape_pt_lon: -89.852559,
    shape_pt_sequence: 76,
    shape_dist_traveled: 8.1796,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005802,
    shape_pt_lon: -89.849723,
    shape_pt_sequence: 77,
    shape_dist_traveled: 8.4366,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.005803,
    shape_pt_lon: -89.848896,
    shape_pt_sequence: 78,
    shape_dist_traveled: 8.5116,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00582,
    shape_pt_lon: -89.848578,
    shape_pt_sequence: 79,
    shape_dist_traveled: 8.5397,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.00842,
    shape_pt_lon: -89.848638,
    shape_pt_sequence: 80,
    shape_dist_traveled: 8.8297,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.008655,
    shape_pt_lon: -89.848639,
    shape_pt_sequence: 81,
    shape_dist_traveled: 8.8567,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.009116,
    shape_pt_lon: -89.848637,
    shape_pt_sequence: 82,
    shape_dist_traveled: 8.9077,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.011341,
    shape_pt_lon: -89.848643,
    shape_pt_sequence: 83,
    shape_dist_traveled: 9.1567,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.012657,
    shape_pt_lon: -89.848643,
    shape_pt_sequence: 84,
    shape_dist_traveled: 9.3037,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.013063,
    shape_pt_lon: -89.848649,
    shape_pt_sequence: 85,
    shape_dist_traveled: 9.3498,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.013506,
    shape_pt_lon: -89.848655,
    shape_pt_sequence: 86,
    shape_dist_traveled: 9.3988,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.014204,
    shape_pt_lon: -89.848664,
    shape_pt_sequence: 87,
    shape_dist_traveled: 9.4778,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.014489,
    shape_pt_lon: -89.84867,
    shape_pt_sequence: 88,
    shape_dist_traveled: 9.5088,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.015029,
    shape_pt_lon: -89.848699,
    shape_pt_sequence: 89,
    shape_dist_traveled: 9.5698,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.015703,
    shape_pt_lon: -89.848722,
    shape_pt_sequence: 90,
    shape_dist_traveled: 9.6449,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.016649,
    shape_pt_lon: -89.848728,
    shape_pt_sequence: 91,
    shape_dist_traveled: 9.7509,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.018068,
    shape_pt_lon: -89.848733,
    shape_pt_sequence: 92,
    shape_dist_traveled: 9.9099,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.019018,
    shape_pt_lon: -89.848709,
    shape_pt_sequence: 93,
    shape_dist_traveled: 10.0159,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020332,
    shape_pt_lon: -89.848681,
    shape_pt_sequence: 94,
    shape_dist_traveled: 10.1629,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020399,
    shape_pt_lon: -89.84868,
    shape_pt_sequence: 95,
    shape_dist_traveled: 10.1709,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020383,
    shape_pt_lon: -89.852447,
    shape_pt_sequence: 96,
    shape_dist_traveled: 10.5119,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.852845,
    shape_pt_sequence: 97,
    shape_dist_traveled: 10.5479,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020379,
    shape_pt_lon: -89.853532,
    shape_pt_sequence: 98,
    shape_dist_traveled: 10.6099,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020369,
    shape_pt_lon: -89.855121,
    shape_pt_sequence: 99,
    shape_dist_traveled: 10.7539,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020358,
    shape_pt_lon: -89.857329,
    shape_pt_sequence: 100,
    shape_dist_traveled: 10.953,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859229,
    shape_pt_sequence: 101,
    shape_dist_traveled: 11.125,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859638,
    shape_pt_sequence: 102,
    shape_dist_traveled: 11.162,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.863546,
    shape_pt_sequence: 103,
    shape_dist_traveled: 11.516,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020348,
    shape_pt_lon: -89.865158,
    shape_pt_sequence: 104,
    shape_dist_traveled: 11.662,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020357,
    shape_pt_lon: -89.866029,
    shape_pt_sequence: 105,
    shape_dist_traveled: 11.741,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020413,
    shape_pt_lon: -89.866158,
    shape_pt_sequence: 106,
    shape_dist_traveled: 11.754,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866238,
    shape_pt_sequence: 107,
    shape_dist_traveled: 11.762,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866357,
    shape_pt_sequence: 108,
    shape_dist_traveled: 11.772,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020433,
    shape_pt_lon: -89.867187,
    shape_pt_sequence: 109,
    shape_dist_traveled: 11.848,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020448,
    shape_pt_lon: -89.868539,
    shape_pt_sequence: 110,
    shape_dist_traveled: 11.97,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020453,
    shape_pt_lon: -89.869939,
    shape_pt_sequence: 111,
    shape_dist_traveled: 12.097,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020468,
    shape_pt_lon: -89.871278,
    shape_pt_sequence: 112,
    shape_dist_traveled: 12.218,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020479,
    shape_pt_lon: -89.872996,
    shape_pt_sequence: 113,
    shape_dist_traveled: 12.373,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020489,
    shape_pt_lon: -89.874195,
    shape_pt_sequence: 114,
    shape_dist_traveled: 12.4821,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020501,
    shape_pt_lon: -89.875019,
    shape_pt_sequence: 115,
    shape_dist_traveled: 12.5561,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020502,
    shape_pt_lon: -89.876066,
    shape_pt_sequence: 116,
    shape_dist_traveled: 12.6511,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020492,
    shape_pt_lon: -89.8769,
    shape_pt_sequence: 117,
    shape_dist_traveled: 12.7271,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020498,
    shape_pt_lon: -89.877438,
    shape_pt_sequence: 118,
    shape_dist_traveled: 12.7751,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.878476,
    shape_pt_sequence: 119,
    shape_dist_traveled: 12.8691,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.879969,
    shape_pt_sequence: 120,
    shape_dist_traveled: 13.0041,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.879998,
    shape_pt_sequence: 121,
    shape_dist_traveled: 13.0071,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880026,
    shape_pt_sequence: 122,
    shape_dist_traveled: 13.0091,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880047,
    shape_pt_sequence: 123,
    shape_dist_traveled: 13.0111,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880071,
    shape_pt_sequence: 124,
    shape_dist_traveled: 13.0131,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880099,
    shape_pt_sequence: 125,
    shape_dist_traveled: 13.0161,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880174,
    shape_pt_sequence: 126,
    shape_dist_traveled: 13.0231,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020493,
    shape_pt_lon: -89.881984,
    shape_pt_sequence: 127,
    shape_dist_traveled: 13.1871,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020492,
    shape_pt_lon: -89.882181,
    shape_pt_sequence: 128,
    shape_dist_traveled: 13.2041,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020491,
    shape_pt_lon: -89.882972,
    shape_pt_sequence: 129,
    shape_dist_traveled: 13.2761,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.884308,
    shape_pt_sequence: 130,
    shape_dist_traveled: 13.3971,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.88445,
    shape_pt_sequence: 131,
    shape_dist_traveled: 13.4101,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020526,
    shape_pt_lon: -89.886751,
    shape_pt_sequence: 132,
    shape_dist_traveled: 13.6181,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020535,
    shape_pt_lon: -89.888839,
    shape_pt_sequence: 133,
    shape_dist_traveled: 13.8071,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020546,
    shape_pt_lon: -89.889701,
    shape_pt_sequence: 134,
    shape_dist_traveled: 13.8851,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020542,
    shape_pt_lon: -89.89057,
    shape_pt_sequence: 135,
    shape_dist_traveled: 13.9641,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020543,
    shape_pt_lon: -89.890641,
    shape_pt_sequence: 136,
    shape_dist_traveled: 13.9701,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020549,
    shape_pt_lon: -89.891089,
    shape_pt_sequence: 137,
    shape_dist_traveled: 14.0111,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020549,
    shape_pt_lon: -89.891118,
    shape_pt_sequence: 138,
    shape_dist_traveled: 14.0131,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020559,
    shape_pt_lon: -89.891753,
    shape_pt_sequence: 139,
    shape_dist_traveled: 14.0711,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020569,
    shape_pt_lon: -89.893117,
    shape_pt_sequence: 140,
    shape_dist_traveled: 14.1941,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020575,
    shape_pt_lon: -89.895639,
    shape_pt_sequence: 141,
    shape_dist_traveled: 14.4221,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020575,
    shape_pt_lon: -89.896052,
    shape_pt_sequence: 142,
    shape_dist_traveled: 14.4601,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020593,
    shape_pt_lon: -89.897279,
    shape_pt_sequence: 143,
    shape_dist_traveled: 14.5712,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.898114,
    shape_pt_sequence: 144,
    shape_dist_traveled: 14.6462,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020611,
    shape_pt_lon: -89.898226,
    shape_pt_sequence: 145,
    shape_dist_traveled: 14.6562,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02061,
    shape_pt_lon: -89.898429,
    shape_pt_sequence: 146,
    shape_dist_traveled: 14.6752,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020606,
    shape_pt_lon: -89.898952,
    shape_pt_sequence: 147,
    shape_dist_traveled: 14.7222,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020609,
    shape_pt_lon: -89.900039,
    shape_pt_sequence: 148,
    shape_dist_traveled: 14.8212,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020602,
    shape_pt_lon: -89.900498,
    shape_pt_sequence: 149,
    shape_dist_traveled: 14.8622,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020607,
    shape_pt_lon: -89.900768,
    shape_pt_sequence: 150,
    shape_dist_traveled: 14.8862,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020611,
    shape_pt_lon: -89.901336,
    shape_pt_sequence: 151,
    shape_dist_traveled: 14.9382,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.90159,
    shape_pt_sequence: 152,
    shape_dist_traveled: 14.9612,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.901661,
    shape_pt_sequence: 153,
    shape_dist_traveled: 14.9672,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020619,
    shape_pt_lon: -89.903125,
    shape_pt_sequence: 154,
    shape_dist_traveled: 15.1002,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020613,
    shape_pt_lon: -89.904618,
    shape_pt_sequence: 155,
    shape_dist_traveled: 15.2352,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020629,
    shape_pt_lon: -89.906868,
    shape_pt_sequence: 156,
    shape_dist_traveled: 15.4392,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020639,
    shape_pt_lon: -89.909091,
    shape_pt_sequence: 157,
    shape_dist_traveled: 15.6402,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020647,
    shape_pt_lon: -89.911331,
    shape_pt_sequence: 158,
    shape_dist_traveled: 15.8422,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020648,
    shape_pt_lon: -89.911413,
    shape_pt_sequence: 159,
    shape_dist_traveled: 15.8502,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020649,
    shape_pt_lon: -89.91149,
    shape_pt_sequence: 160,
    shape_dist_traveled: 15.8572,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020652,
    shape_pt_lon: -89.913666,
    shape_pt_sequence: 161,
    shape_dist_traveled: 16.0542,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020661,
    shape_pt_lon: -89.914472,
    shape_pt_sequence: 162,
    shape_dist_traveled: 16.1272,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02067,
    shape_pt_lon: -89.916207,
    shape_pt_sequence: 163,
    shape_dist_traveled: 16.2843,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.916393,
    shape_pt_sequence: 164,
    shape_dist_traveled: 16.3003,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020675,
    shape_pt_lon: -89.916827,
    shape_pt_sequence: 165,
    shape_dist_traveled: 16.3403,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020686,
    shape_pt_lon: -89.919056,
    shape_pt_sequence: 166,
    shape_dist_traveled: 16.5413,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.920732,
    shape_pt_sequence: 167,
    shape_dist_traveled: 16.6933,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.923524,
    shape_pt_sequence: 168,
    shape_dist_traveled: 16.9463,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020687,
    shape_pt_lon: -89.924789,
    shape_pt_sequence: 169,
    shape_dist_traveled: 17.0603,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020684,
    shape_pt_lon: -89.926384,
    shape_pt_sequence: 170,
    shape_dist_traveled: 17.2053,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020688,
    shape_pt_lon: -89.927984,
    shape_pt_sequence: 171,
    shape_dist_traveled: 17.3493,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020688,
    shape_pt_lon: -89.92991,
    shape_pt_sequence: 172,
    shape_dist_traveled: 17.5243,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.930379,
    shape_pt_sequence: 173,
    shape_dist_traveled: 17.5663,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.930714,
    shape_pt_sequence: 174,
    shape_dist_traveled: 17.5963,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020692,
    shape_pt_lon: -89.93125,
    shape_pt_sequence: 175,
    shape_dist_traveled: 17.6453,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020707,
    shape_pt_lon: -89.935942,
    shape_pt_sequence: 176,
    shape_dist_traveled: 18.0703,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0207,
    shape_pt_lon: -89.93661,
    shape_pt_sequence: 177,
    shape_dist_traveled: 18.1303,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02071,
    shape_pt_lon: -89.936884,
    shape_pt_sequence: 178,
    shape_dist_traveled: 18.1553,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020649,
    shape_pt_lon: -89.936882,
    shape_pt_sequence: 179,
    shape_dist_traveled: 18.1623,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020576,
    shape_pt_lon: -89.93688,
    shape_pt_sequence: 180,
    shape_dist_traveled: 18.1703,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.020576,
    shape_pt_lon: -89.93688,
    shape_pt_sequence: 181,
    shape_dist_traveled: 18.1784,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.02071,
    shape_pt_lon: -89.936884,
    shape_pt_sequence: 182,
    shape_dist_traveled: 18.1854,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.024229,
    shape_pt_lon: -89.936937,
    shape_pt_sequence: 183,
    shape_dist_traveled: 18.5784,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.026124,
    shape_pt_lon: -89.936958,
    shape_pt_sequence: 184,
    shape_dist_traveled: 18.7905,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.027972,
    shape_pt_lon: -89.936979,
    shape_pt_sequence: 185,
    shape_dist_traveled: 18.9975,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.029582,
    shape_pt_lon: -89.937016,
    shape_pt_sequence: 186,
    shape_dist_traveled: 19.1775,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.030277,
    shape_pt_lon: -89.937031,
    shape_pt_sequence: 187,
    shape_dist_traveled: 19.2555,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.030663,
    shape_pt_lon: -89.937044,
    shape_pt_sequence: 188,
    shape_dist_traveled: 19.2985,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.030782,
    shape_pt_lon: -89.93696,
    shape_pt_sequence: 189,
    shape_dist_traveled: 19.3147,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.031272,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 190,
    shape_dist_traveled: 19.3687,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.032675,
    shape_pt_lon: -89.936986,
    shape_pt_sequence: 191,
    shape_dist_traveled: 19.5257,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.032752,
    shape_pt_lon: -89.936987,
    shape_pt_sequence: 192,
    shape_dist_traveled: 19.5347,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.033071,
    shape_pt_lon: -89.936995,
    shape_pt_sequence: 193,
    shape_dist_traveled: 19.5707,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.034049,
    shape_pt_lon: -89.936998,
    shape_pt_sequence: 194,
    shape_dist_traveled: 19.6797,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.034286,
    shape_pt_lon: -89.936977,
    shape_pt_sequence: 195,
    shape_dist_traveled: 19.7068,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.034377,
    shape_pt_lon: -89.936964,
    shape_pt_sequence: 196,
    shape_dist_traveled: 19.7168,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.034619,
    shape_pt_lon: -89.936922,
    shape_pt_sequence: 197,
    shape_dist_traveled: 19.7441,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.03582,
    shape_pt_lon: -89.936605,
    shape_pt_sequence: 198,
    shape_dist_traveled: 19.881,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036047,
    shape_pt_lon: -89.936561,
    shape_pt_sequence: 199,
    shape_dist_traveled: 19.9073,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036453,
    shape_pt_lon: -89.936534,
    shape_pt_sequence: 200,
    shape_dist_traveled: 19.9524,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036539,
    shape_pt_lon: -89.936511,
    shape_pt_sequence: 201,
    shape_dist_traveled: 19.9626,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036602,
    shape_pt_lon: -89.936477,
    shape_pt_sequence: 202,
    shape_dist_traveled: 19.9706,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036636,
    shape_pt_lon: -89.936432,
    shape_pt_sequence: 203,
    shape_dist_traveled: 19.9763,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036659,
    shape_pt_lon: -89.936347,
    shape_pt_sequence: 204,
    shape_dist_traveled: 19.9836,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036673,
    shape_pt_lon: -89.936031,
    shape_pt_sequence: 205,
    shape_dist_traveled: 20.0126,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036693,
    shape_pt_lon: -89.93576,
    shape_pt_sequence: 206,
    shape_dist_traveled: 20.0367,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.03672,
    shape_pt_lon: -89.935567,
    shape_pt_sequence: 207,
    shape_dist_traveled: 20.055,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036753,
    shape_pt_lon: -89.934087,
    shape_pt_sequence: 208,
    shape_dist_traveled: 20.189,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036793,
    shape_pt_lon: -89.93238,
    shape_pt_sequence: 209,
    shape_dist_traveled: 20.3431,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036818,
    shape_pt_lon: -89.931206,
    shape_pt_sequence: 210,
    shape_dist_traveled: 20.4491,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.03689,
    shape_pt_lon: -89.931207,
    shape_pt_sequence: 211,
    shape_dist_traveled: 20.4571,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.036949,
    shape_pt_lon: -89.931208,
    shape_pt_sequence: 212,
    shape_dist_traveled: 20.4631,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.038179,
    shape_pt_lon: -89.93122,
    shape_pt_sequence: 213,
    shape_dist_traveled: 20.6011,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.040507,
    shape_pt_lon: -89.931229,
    shape_pt_sequence: 214,
    shape_dist_traveled: 20.8612,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.044065,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 215,
    shape_dist_traveled: 21.2592,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.050532,
    shape_pt_lon: -89.931286,
    shape_pt_sequence: 216,
    shape_dist_traveled: 21.9832,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051673,
    shape_pt_lon: -89.931306,
    shape_pt_sequence: 217,
    shape_dist_traveled: 22.1112,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051818,
    shape_pt_lon: -89.931308,
    shape_pt_sequence: 218,
    shape_dist_traveled: 22.1272,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051859,
    shape_pt_lon: -89.933182,
    shape_pt_sequence: 219,
    shape_dist_traveled: 22.2972,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051859,
    shape_pt_lon: -89.933387,
    shape_pt_sequence: 220,
    shape_dist_traveled: 22.3152,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051862,
    shape_pt_lon: -89.933493,
    shape_pt_sequence: 221,
    shape_dist_traveled: 22.3253,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051863,
    shape_pt_lon: -89.933593,
    shape_pt_sequence: 222,
    shape_dist_traveled: 22.3343,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 223,
    shape_dist_traveled: 22.3633,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.052017,
    shape_pt_lon: -89.933922,
    shape_pt_sequence: 224,
    shape_dist_traveled: 22.3803,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.053188,
    shape_pt_lon: -89.933938,
    shape_pt_sequence: 225,
    shape_dist_traveled: 22.5113,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05363,
    shape_pt_lon: -89.933901,
    shape_pt_sequence: 226,
    shape_dist_traveled: 22.5604,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.054053,
    shape_pt_lon: -89.933828,
    shape_pt_sequence: 227,
    shape_dist_traveled: 22.6088,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.054357,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 228,
    shape_dist_traveled: 22.6435,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.054481,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 229,
    shape_dist_traveled: 22.6581,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05497,
    shape_pt_lon: -89.933527,
    shape_pt_sequence: 230,
    shape_dist_traveled: 22.7144,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.055188,
    shape_pt_lon: -89.933423,
    shape_pt_sequence: 231,
    shape_dist_traveled: 22.7413,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.055313,
    shape_pt_lon: -89.933358,
    shape_pt_sequence: 232,
    shape_dist_traveled: 22.7565,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.0555,
    shape_pt_lon: -89.933261,
    shape_pt_sequence: 233,
    shape_dist_traveled: 22.779,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.056072,
    shape_pt_lon: -89.932929,
    shape_pt_sequence: 234,
    shape_dist_traveled: 22.8497,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05679,
    shape_pt_lon: -89.932518,
    shape_pt_sequence: 235,
    shape_dist_traveled: 22.9378,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.056932,
    shape_pt_lon: -89.932433,
    shape_pt_sequence: 236,
    shape_dist_traveled: 22.9557,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.057032,
    shape_pt_lon: -89.932373,
    shape_pt_sequence: 237,
    shape_dist_traveled: 22.9683,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.057089,
    shape_pt_lon: -89.93234,
    shape_pt_sequence: 238,
    shape_dist_traveled: 22.9759,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.057138,
    shape_pt_lon: -89.932313,
    shape_pt_sequence: 239,
    shape_dist_traveled: 22.9813,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05789,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 240,
    shape_dist_traveled: 23.0734,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.058198,
    shape_pt_lon: -89.931708,
    shape_pt_sequence: 241,
    shape_dist_traveled: 23.1119,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.058595,
    shape_pt_lon: -89.93147,
    shape_pt_sequence: 242,
    shape_dist_traveled: 23.1611,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05897,
    shape_pt_lon: -89.931251,
    shape_pt_sequence: 243,
    shape_dist_traveled: 23.2076,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.059103,
    shape_pt_lon: -89.931184,
    shape_pt_sequence: 244,
    shape_dist_traveled: 23.2238,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.059289,
    shape_pt_lon: -89.931099,
    shape_pt_sequence: 245,
    shape_dist_traveled: 23.2459,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.05965,
    shape_pt_lon: -89.930978,
    shape_pt_sequence: 246,
    shape_dist_traveled: 23.2874,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06002,
    shape_pt_lon: -89.930914,
    shape_pt_sequence: 247,
    shape_dist_traveled: 23.3298,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.060297,
    shape_pt_lon: -89.930899,
    shape_pt_sequence: 248,
    shape_dist_traveled: 23.3609,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.060377,
    shape_pt_lon: -89.930898,
    shape_pt_sequence: 249,
    shape_dist_traveled: 23.3699,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.060845,
    shape_pt_lon: -89.930927,
    shape_pt_sequence: 250,
    shape_dist_traveled: 23.4219,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.061082,
    shape_pt_lon: -89.930969,
    shape_pt_sequence: 251,
    shape_dist_traveled: 23.4482,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.061463,
    shape_pt_lon: -89.93107,
    shape_pt_sequence: 252,
    shape_dist_traveled: 23.4921,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.061775,
    shape_pt_lon: -89.931189,
    shape_pt_sequence: 253,
    shape_dist_traveled: 23.5288,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06208,
    shape_pt_lon: -89.931346,
    shape_pt_sequence: 254,
    shape_dist_traveled: 23.5656,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.063008,
    shape_pt_lon: -89.931907,
    shape_pt_sequence: 255,
    shape_dist_traveled: 23.6814,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.063223,
    shape_pt_lon: -89.931931,
    shape_pt_sequence: 256,
    shape_dist_traveled: 23.7055,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.063444,
    shape_pt_lon: -89.931978,
    shape_pt_sequence: 257,
    shape_dist_traveled: 23.731,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.063617,
    shape_pt_lon: -89.932011,
    shape_pt_sequence: 258,
    shape_dist_traveled: 23.7502,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.064022,
    shape_pt_lon: -89.932069,
    shape_pt_sequence: 259,
    shape_dist_traveled: 23.7955,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.064298,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 260,
    shape_dist_traveled: 23.8265,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.064566,
    shape_pt_lon: -89.932056,
    shape_pt_sequence: 261,
    shape_dist_traveled: 23.8565,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06487,
    shape_pt_lon: -89.932046,
    shape_pt_sequence: 262,
    shape_dist_traveled: 23.8905,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06632,
    shape_pt_lon: -89.93212,
    shape_pt_sequence: 263,
    shape_dist_traveled: 24.0527,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.066881,
    shape_pt_lon: -89.932117,
    shape_pt_sequence: 264,
    shape_dist_traveled: 24.1157,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067045,
    shape_pt_lon: -89.932129,
    shape_pt_sequence: 265,
    shape_dist_traveled: 24.1347,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067282,
    shape_pt_lon: -89.932176,
    shape_pt_sequence: 266,
    shape_dist_traveled: 24.161,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067553,
    shape_pt_lon: -89.932161,
    shape_pt_sequence: 267,
    shape_dist_traveled: 24.191,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06759,
    shape_pt_lon: -89.932188,
    shape_pt_sequence: 268,
    shape_dist_traveled: 24.1955,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 269,
    shape_dist_traveled: 24.2027,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 270,
    shape_dist_traveled: 24.2078,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 271,
    shape_dist_traveled: 24.2239,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 272,
    shape_dist_traveled: 24.2359,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 273,
    shape_dist_traveled: 24.2789,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 274,
    shape_dist_traveled: 24.372,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 275,
    shape_dist_traveled: 24.4701,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 276,
    shape_dist_traveled: 24.4951,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 277,
    shape_dist_traveled: 24.5842,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 278,
    shape_dist_traveled: 24.6624,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 279,
    shape_dist_traveled: 24.7706,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 280,
    shape_dist_traveled: 24.7706,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 281,
    shape_dist_traveled: 24.8409,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 282,
    shape_dist_traveled: 24.9813,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 283,
    shape_dist_traveled: 25.1859,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 284,
    shape_dist_traveled: 25.193,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 285,
    shape_dist_traveled: 25.2081,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 286,
    shape_dist_traveled: 25.2662,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 287,
    shape_dist_traveled: 25.3413,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 288,
    shape_dist_traveled: 25.3503,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 289,
    shape_dist_traveled: 25.3561,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 290,
    shape_dist_traveled: 25.3647,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 291,
    shape_dist_traveled: 25.3777,
  },
  {
    shape_id: 38171,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 292,
    shape_dist_traveled: 25.4387,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3625,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4213,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4833,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5453,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6009,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7176,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8362,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9529,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0756,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163474,
    shape_pt_lon: -90.042521,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0946,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.194,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163401,
    shape_pt_lon: -90.041361,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163368,
    shape_pt_lon: -90.040839,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.2471,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163359,
    shape_pt_lon: -90.040687,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.2612,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163337,
    shape_pt_lon: -90.040338,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.2922,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -90.040033,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.3204,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163314,
    shape_pt_lon: -90.039989,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3244,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163276,
    shape_pt_lon: -90.039376,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.3795,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16323,
    shape_pt_lon: -90.038681,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4427,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163225,
    shape_pt_lon: -90.038434,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4648,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038233,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4828,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5012,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6041,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6225,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6292,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6324,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163756,
    shape_pt_lon: -90.036625,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6409,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.163866,
    shape_pt_lon: -90.036306,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6714,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16431,
    shape_pt_lon: -90.035105,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7913,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.164882,
    shape_pt_lon: -90.033551,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9453,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.165073,
    shape_pt_lon: -90.033033,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9967,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.165231,
    shape_pt_lon: -90.032603,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.0397,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.165449,
    shape_pt_lon: -90.03207,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0934,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.165487,
    shape_pt_lon: -90.031962,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.1041,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.030981,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.2,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.166278,
    shape_pt_lon: -90.02976,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.3221,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.166773,
    shape_pt_lon: -90.02843,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4541,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.166926,
    shape_pt_lon: -90.028021,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4953,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.167048,
    shape_pt_lon: -90.027692,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.5271,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16736,
    shape_pt_lon: -90.026846,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.6117,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.167378,
    shape_pt_lon: -90.0268,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.6161,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.167737,
    shape_pt_lon: -90.025821,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.7128,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.167786,
    shape_pt_lon: -90.02566,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.7289,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.167978,
    shape_pt_lon: -90.025146,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.7795,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.16809,
    shape_pt_lon: -90.024868,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.8077,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.168399,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8886,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.168436,
    shape_pt_lon: -90.023966,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.8985,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.168833,
    shape_pt_lon: -90.022901,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.0041,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.169014,
    shape_pt_lon: -90.022431,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0506,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.169297,
    shape_pt_lon: -90.02168,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1258,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.169665,
    shape_pt_lon: -90.02072,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2219,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.169845,
    shape_pt_lon: -90.020188,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2743,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.169892,
    shape_pt_lon: -90.020041,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.2883,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170013,
    shape_pt_lon: -90.019726,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.3201,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17008,
    shape_pt_lon: -90.01954,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3379,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170248,
    shape_pt_lon: -90.019085,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3831,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170448,
    shape_pt_lon: -90.018531,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4387,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170477,
    shape_pt_lon: -90.018446,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4463,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17051,
    shape_pt_lon: -90.018356,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.4552,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170724,
    shape_pt_lon: -90.017781,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5125,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17074,
    shape_pt_lon: -90.01774,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.517,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.170949,
    shape_pt_lon: -90.017179,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.5729,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.171239,
    shape_pt_lon: -90.016407,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.6494,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.171718,
    shape_pt_lon: -90.015128,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.7769,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172194,
    shape_pt_lon: -90.013851,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.904,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172305,
    shape_pt_lon: -90.013547,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9345,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172458,
    shape_pt_lon: -90.013073,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.9798,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172591,
    shape_pt_lon: -90.012606,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0253,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172712,
    shape_pt_lon: -90.012141,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.0686,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172779,
    shape_pt_lon: -90.011822,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.0985,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.011314,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.1455,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17293,
    shape_pt_lon: -90.0109,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.1842,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172994,
    shape_pt_lon: -90.01023,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.2446,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173045,
    shape_pt_lon: -90.00964,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.2979,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173062,
    shape_pt_lon: -90.009155,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.342,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173057,
    shape_pt_lon: -90.008639,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.389,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.405,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173005,
    shape_pt_lon: -90.007536,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4881,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172963,
    shape_pt_lon: -90.00671,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.5633,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17292,
    shape_pt_lon: -90.005872,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.6395,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172902,
    shape_pt_lon: -90.005476,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.6745,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172782,
    shape_pt_lon: -90.00257,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.9379,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17277,
    shape_pt_lon: -90.002149,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.9759,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.1727,
    shape_pt_lon: -90.000165,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.155,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172696,
    shape_pt_lon: -89.999673,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.2,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172649,
    shape_pt_lon: -89.99864,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.2932,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.99686,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.4543,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172613,
    shape_pt_lon: -89.996368,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.4983,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172655,
    shape_pt_lon: -89.995908,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.5405,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.172709,
    shape_pt_lon: -89.995526,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.5751,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173101,
    shape_pt_lon: -89.99287,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.8192,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173299,
    shape_pt_lon: -89.991569,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.9383,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173424,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.0215,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173507,
    shape_pt_lon: -89.990126,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.0713,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173649,
    shape_pt_lon: -89.989208,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.1558,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173687,
    shape_pt_lon: -89.988951,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.1792,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173867,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.2862,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174036,
    shape_pt_lon: -89.986666,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.389,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.985734,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.4743,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174188,
    shape_pt_lon: -89.985652,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.4826,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174208,
    shape_pt_lon: -89.985526,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.4938,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.984611,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.5783,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174389,
    shape_pt_lon: -89.984348,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.6016,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.6687,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.6913,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.7637,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.7809,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.8478,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.864,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.918,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.018,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.0931,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.2002,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.3053,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.4024,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.4124,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174607,
    shape_pt_lon: -89.975379,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.4165,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174567,
    shape_pt_lon: -89.974229,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.5206,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174529,
    shape_pt_lon: -89.973062,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.6267,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174489,
    shape_pt_lon: -89.971887,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.7327,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174451,
    shape_pt_lon: -89.970819,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.8289,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174352,
    shape_pt_lon: -89.96775,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.107,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174281,
    shape_pt_lon: -89.965581,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.3022,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174272,
    shape_pt_lon: -89.965344,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.3242,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174204,
    shape_pt_lon: -89.963397,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5004,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174171,
    shape_pt_lon: -89.96235,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5944,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174168,
    shape_pt_lon: -89.962277,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6014,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174163,
    shape_pt_lon: -89.96222,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6065,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174113,
    shape_pt_lon: -89.961488,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6727,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174093,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.692,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17398,
    shape_pt_lon: -89.960329,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.7788,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173869,
    shape_pt_lon: -89.959373,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8658,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173763,
    shape_pt_lon: -89.95845,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.9505,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173758,
    shape_pt_lon: -89.958402,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.9546,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17366,
    shape_pt_lon: -89.957531,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.0344,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173558,
    shape_pt_lon: -89.956603,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.1181,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173451,
    shape_pt_lon: -89.955671,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.204,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173418,
    shape_pt_lon: -89.95529,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.2382,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.1734,
    shape_pt_lon: -89.954734,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.2882,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173375,
    shape_pt_lon: -89.953796,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.3733,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173347,
    shape_pt_lon: -89.952841,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.4593,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17332,
    shape_pt_lon: -89.951868,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.5474,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173298,
    shape_pt_lon: -89.950901,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.6344,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173289,
    shape_pt_lon: -89.94993,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.7224,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173244,
    shape_pt_lon: -89.94812,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.8865,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173237,
    shape_pt_lon: -89.947831,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.9125,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173251,
    shape_pt_lon: -89.94759,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.9336,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173283,
    shape_pt_lon: -89.947367,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.9548,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.173329,
    shape_pt_lon: -89.947171,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.9725,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17351,
    shape_pt_lon: -89.946572,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.0305,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174266,
    shape_pt_lon: -89.9441,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.2691,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.174379,
    shape_pt_lon: -89.943741,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.3042,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17475,
    shape_pt_lon: -89.94254,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.4201,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.175133,
    shape_pt_lon: -89.941291,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.541,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.175376,
    shape_pt_lon: -89.940499,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.617,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.175561,
    shape_pt_lon: -89.940451,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.6386,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.17565,
    shape_pt_lon: -89.940421,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.649,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.175801,
    shape_pt_lon: -89.940348,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.667,
  },
  {
    shape_id: 38172,
    shape_pt_lat: 35.176237,
    shape_pt_lon: -89.940148,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.7192,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3625,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4213,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4833,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5453,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6009,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7176,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8362,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9529,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0756,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163474,
    shape_pt_lon: -90.042521,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0946,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.194,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163401,
    shape_pt_lon: -90.041361,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163368,
    shape_pt_lon: -90.040839,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.2471,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163359,
    shape_pt_lon: -90.040687,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.2612,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163337,
    shape_pt_lon: -90.040338,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.2922,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -90.040033,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.3204,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163314,
    shape_pt_lon: -90.039989,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3244,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163276,
    shape_pt_lon: -90.039376,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.3795,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16323,
    shape_pt_lon: -90.038681,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4427,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163225,
    shape_pt_lon: -90.038434,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4648,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038233,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4828,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5012,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6041,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6225,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6292,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6324,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163756,
    shape_pt_lon: -90.036625,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6409,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.163866,
    shape_pt_lon: -90.036306,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6714,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16431,
    shape_pt_lon: -90.035105,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7913,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.164882,
    shape_pt_lon: -90.033551,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9453,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165073,
    shape_pt_lon: -90.033033,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9967,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165231,
    shape_pt_lon: -90.032603,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.0397,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165449,
    shape_pt_lon: -90.03207,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0934,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165487,
    shape_pt_lon: -90.031962,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.1041,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.030981,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.2,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.166278,
    shape_pt_lon: -90.02976,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.3221,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.166773,
    shape_pt_lon: -90.02843,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4541,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.166926,
    shape_pt_lon: -90.028021,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4953,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167048,
    shape_pt_lon: -90.027692,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.5271,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16736,
    shape_pt_lon: -90.026846,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.6117,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167378,
    shape_pt_lon: -90.0268,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.6161,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167737,
    shape_pt_lon: -90.025821,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.7128,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167786,
    shape_pt_lon: -90.02566,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.7289,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167978,
    shape_pt_lon: -90.025146,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.7795,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16809,
    shape_pt_lon: -90.024868,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.8077,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.168399,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8886,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.168436,
    shape_pt_lon: -90.023966,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.8985,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.168833,
    shape_pt_lon: -90.022901,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.0041,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169014,
    shape_pt_lon: -90.022431,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0506,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169297,
    shape_pt_lon: -90.02168,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1258,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169665,
    shape_pt_lon: -90.02072,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2219,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169845,
    shape_pt_lon: -90.020188,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2743,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169892,
    shape_pt_lon: -90.020041,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.2883,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170013,
    shape_pt_lon: -90.019726,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.3201,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17008,
    shape_pt_lon: -90.01954,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3379,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170248,
    shape_pt_lon: -90.019085,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3831,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170448,
    shape_pt_lon: -90.018531,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4387,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170477,
    shape_pt_lon: -90.018446,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4463,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17051,
    shape_pt_lon: -90.018356,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.4552,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170724,
    shape_pt_lon: -90.017781,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5125,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17074,
    shape_pt_lon: -90.01774,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.517,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170949,
    shape_pt_lon: -90.017179,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.5729,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.171239,
    shape_pt_lon: -90.016407,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.6494,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.171718,
    shape_pt_lon: -90.015128,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.7769,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172194,
    shape_pt_lon: -90.013851,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.904,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172305,
    shape_pt_lon: -90.013547,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9345,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172458,
    shape_pt_lon: -90.013073,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.9798,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172591,
    shape_pt_lon: -90.012606,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0253,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172712,
    shape_pt_lon: -90.012141,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.0686,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172779,
    shape_pt_lon: -90.011822,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.0985,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.011314,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.1455,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17293,
    shape_pt_lon: -90.0109,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.1842,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172994,
    shape_pt_lon: -90.01023,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.2446,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173045,
    shape_pt_lon: -90.00964,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.2979,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173062,
    shape_pt_lon: -90.009155,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.342,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173057,
    shape_pt_lon: -90.008639,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.389,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.405,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173005,
    shape_pt_lon: -90.007536,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4881,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172963,
    shape_pt_lon: -90.00671,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.5633,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17292,
    shape_pt_lon: -90.005872,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.6395,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172902,
    shape_pt_lon: -90.005476,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.6745,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172782,
    shape_pt_lon: -90.00257,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.9379,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17277,
    shape_pt_lon: -90.002149,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.9759,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.1727,
    shape_pt_lon: -90.000165,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.155,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172696,
    shape_pt_lon: -89.999673,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.2,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172649,
    shape_pt_lon: -89.99864,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.2932,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.99686,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.4543,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172613,
    shape_pt_lon: -89.996368,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.4983,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172655,
    shape_pt_lon: -89.995908,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.5405,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172709,
    shape_pt_lon: -89.995526,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.5751,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173101,
    shape_pt_lon: -89.99287,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.8192,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173299,
    shape_pt_lon: -89.991569,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.9383,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173424,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.0215,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173507,
    shape_pt_lon: -89.990126,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.0713,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173649,
    shape_pt_lon: -89.989208,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.1558,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173687,
    shape_pt_lon: -89.988951,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.1792,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173867,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.2862,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174036,
    shape_pt_lon: -89.986666,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.389,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.985734,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.4743,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174188,
    shape_pt_lon: -89.985652,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.4826,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174208,
    shape_pt_lon: -89.985526,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.4938,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.984611,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.5783,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174389,
    shape_pt_lon: -89.984348,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.6016,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.6687,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.6913,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.7637,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.7809,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.8478,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.864,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.918,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.018,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.0931,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.2002,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.3053,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.4024,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.4124,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174607,
    shape_pt_lon: -89.975379,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.4165,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174567,
    shape_pt_lon: -89.974229,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.5206,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174529,
    shape_pt_lon: -89.973062,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.6267,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174489,
    shape_pt_lon: -89.971887,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.7327,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174451,
    shape_pt_lon: -89.970819,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.8289,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174352,
    shape_pt_lon: -89.96775,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.107,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174281,
    shape_pt_lon: -89.965581,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.3022,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174272,
    shape_pt_lon: -89.965344,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.3242,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174204,
    shape_pt_lon: -89.963397,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5004,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174171,
    shape_pt_lon: -89.96235,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5944,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174168,
    shape_pt_lon: -89.962277,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6014,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174163,
    shape_pt_lon: -89.96222,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6065,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174113,
    shape_pt_lon: -89.961488,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6727,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174093,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.692,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17398,
    shape_pt_lon: -89.960329,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.7788,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173869,
    shape_pt_lon: -89.959373,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8658,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173763,
    shape_pt_lon: -89.95845,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.9505,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173758,
    shape_pt_lon: -89.958402,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.9546,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17366,
    shape_pt_lon: -89.957531,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.0344,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173558,
    shape_pt_lon: -89.956603,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.1181,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173451,
    shape_pt_lon: -89.955671,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.204,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173418,
    shape_pt_lon: -89.95529,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.2382,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.1734,
    shape_pt_lon: -89.954734,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.2882,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173375,
    shape_pt_lon: -89.953796,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.3733,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173347,
    shape_pt_lon: -89.952841,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.4593,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17332,
    shape_pt_lon: -89.951868,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.5474,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173298,
    shape_pt_lon: -89.950901,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.6344,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173289,
    shape_pt_lon: -89.94993,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.7224,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173244,
    shape_pt_lon: -89.94812,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.8865,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173237,
    shape_pt_lon: -89.947831,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.9125,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173251,
    shape_pt_lon: -89.94759,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.9336,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173283,
    shape_pt_lon: -89.947367,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.9548,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173329,
    shape_pt_lon: -89.947171,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.9725,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17351,
    shape_pt_lon: -89.946572,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.0305,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174266,
    shape_pt_lon: -89.9441,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.2691,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.174379,
    shape_pt_lon: -89.943741,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.3042,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17475,
    shape_pt_lon: -89.94254,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.4201,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175133,
    shape_pt_lon: -89.941291,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.541,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175376,
    shape_pt_lon: -89.940499,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.617,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175561,
    shape_pt_lon: -89.940451,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.6386,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17565,
    shape_pt_lon: -89.940421,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.649,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175801,
    shape_pt_lon: -89.940348,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.667,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.176237,
    shape_pt_lon: -89.940148,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.7192,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.176742,
    shape_pt_lon: -89.939902,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.7794,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177021,
    shape_pt_lon: -89.93976,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.813,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17718,
    shape_pt_lon: -89.939653,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.8336,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177335,
    shape_pt_lon: -89.939511,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.8552,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177566,
    shape_pt_lon: -89.939269,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.8885,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.178218,
    shape_pt_lon: -89.938618,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.9824,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.178303,
    shape_pt_lon: -89.938517,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.9959,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177786,
    shape_pt_lon: -89.937771,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.0845,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177689,
    shape_pt_lon: -89.937635,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.1015,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177319,
    shape_pt_lon: -89.938022,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.1554,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.177009,
    shape_pt_lon: -89.938348,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.2009,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17622,
    shape_pt_lon: -89.939111,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.3127,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.939717,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.4025,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.175354,
    shape_pt_lon: -89.93994,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.4353,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173966,
    shape_pt_lon: -89.941262,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.6315,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173883,
    shape_pt_lon: -89.94134,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.6429,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173512,
    shape_pt_lon: -89.941679,
    shape_pt_sequence: 182,
    shape_dist_traveled: 11.6943,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173462,
    shape_pt_lon: -89.941724,
    shape_pt_sequence: 183,
    shape_dist_traveled: 11.7015,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173341,
    shape_pt_lon: -89.941742,
    shape_pt_sequence: 184,
    shape_dist_traveled: 11.7156,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.17327,
    shape_pt_lon: -89.941688,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.7245,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173216,
    shape_pt_lon: -89.941671,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.7308,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173096,
    shape_pt_lon: -89.941643,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.7442,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.173019,
    shape_pt_lon: -89.941631,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.7532,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172883,
    shape_pt_lon: -89.941672,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.7688,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.172046,
    shape_pt_lon: -89.942077,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.8698,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.171176,
    shape_pt_lon: -89.942491,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.9736,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170597,
    shape_pt_lon: -89.942783,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.0436,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170331,
    shape_pt_lon: -89.942938,
    shape_pt_sequence: 193,
    shape_dist_traveled: 12.0767,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.170218,
    shape_pt_lon: -89.943012,
    shape_pt_sequence: 194,
    shape_dist_traveled: 12.0906,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.169557,
    shape_pt_lon: -89.943509,
    shape_pt_sequence: 195,
    shape_dist_traveled: 12.1767,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16874,
    shape_pt_lon: -89.944142,
    shape_pt_sequence: 196,
    shape_dist_traveled: 12.2854,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167936,
    shape_pt_lon: -89.944764,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.3914,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.167118,
    shape_pt_lon: -89.945396,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.4988,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.166301,
    shape_pt_lon: -89.946026,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.6065,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165963,
    shape_pt_lon: -89.946284,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.6515,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165786,
    shape_pt_lon: -89.946404,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.6738,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165573,
    shape_pt_lon: -89.946521,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.6993,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165371,
    shape_pt_lon: -89.946603,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.7234,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.165153,
    shape_pt_lon: -89.946667,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.7481,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16481,
    shape_pt_lon: -89.946718,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.7874,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16344,
    shape_pt_lon: -89.946883,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.9411,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.162496,
    shape_pt_lon: -89.946978,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.0474,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.0626,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.162006,
    shape_pt_lon: -89.947106,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.1036,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.161968,
    shape_pt_lon: -89.947143,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.1092,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16185,
    shape_pt_lon: -89.947211,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.1236,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.161457,
    shape_pt_lon: -89.947332,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.1689,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16071,
    shape_pt_lon: -89.947538,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.2548,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.160567,
    shape_pt_lon: -89.947563,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.2709,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.160468,
    shape_pt_lon: -89.947574,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.282,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.16029,
    shape_pt_lon: -89.947589,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.3021,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.160131,
    shape_pt_lon: -89.947593,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.3201,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.159407,
    shape_pt_lon: -89.947618,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.4011,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.159343,
    shape_pt_lon: -89.947621,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.4081,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.158556,
    shape_pt_lon: -89.947649,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.4962,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.158149,
    shape_pt_lon: -89.947661,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.5412,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156899,
    shape_pt_lon: -89.947703,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.6812,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.156744,
    shape_pt_lon: -89.947708,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.6992,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.155807,
    shape_pt_lon: -89.947739,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.8033,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.154563,
    shape_pt_lon: -89.94778,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.9433,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.153319,
    shape_pt_lon: -89.947822,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.0824,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.152091,
    shape_pt_lon: -89.947864,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.2194,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.150858,
    shape_pt_lon: -89.947903,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.3575,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.15032,
    shape_pt_lon: -89.947923,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.4175,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.149693,
    shape_pt_lon: -89.947947,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.4885,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947991,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.5197,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947853,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.5317,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.149432,
    shape_pt_lon: -89.946088,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.6918,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.149467,
    shape_pt_lon: -89.944512,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.8338,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.148914,
    shape_pt_lon: -89.94453,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.8959,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.14871,
    shape_pt_lon: -89.944537,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.9189,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.148531,
    shape_pt_lon: -89.944542,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.9389,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.148431,
    shape_pt_lon: -89.944547,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.9499,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.148202,
    shape_pt_lon: -89.944549,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.9759,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.148119,
    shape_pt_lon: -89.944551,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.9849,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.147993,
    shape_pt_lon: -89.94455,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.9989,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.147771,
    shape_pt_lon: -89.944554,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.0239,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.14769,
    shape_pt_lon: -89.944559,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.0329,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.147289,
    shape_pt_lon: -89.944577,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.078,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.147109,
    shape_pt_lon: -89.944583,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.098,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.146829,
    shape_pt_lon: -89.944591,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.13,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.146511,
    shape_pt_lon: -89.944599,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.165,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.146338,
    shape_pt_lon: -89.944606,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.185,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.146242,
    shape_pt_lon: -89.94461,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.195,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.145299,
    shape_pt_lon: -89.944643,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.301,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.144469,
    shape_pt_lon: -89.944668,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.3941,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.14408,
    shape_pt_lon: -89.944679,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.4371,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.143629,
    shape_pt_lon: -89.944695,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.4881,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.1432,
    shape_pt_lon: -89.94471,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.5361,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.142756,
    shape_pt_lon: -89.944723,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.5851,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.142028,
    shape_pt_lon: -89.944748,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.6672,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.141925,
    shape_pt_lon: -89.944751,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.6782,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.141051,
    shape_pt_lon: -89.944781,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.7752,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.140274,
    shape_pt_lon: -89.94481,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.8623,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.139031,
    shape_pt_lon: -89.944857,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.0013,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.137752,
    shape_pt_lon: -89.944904,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.1444,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.13742,
    shape_pt_lon: -89.944917,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.1824,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.136482,
    shape_pt_lon: -89.944949,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.2874,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.135316,
    shape_pt_lon: -89.944981,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.4175,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.134263,
    shape_pt_lon: -89.945007,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.5355,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.134152,
    shape_pt_lon: -89.945011,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.5475,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.133437,
    shape_pt_lon: -89.94503,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.6275,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.7956,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.8366,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.9626,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 271,
    shape_dist_traveled: 17.0937,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 272,
    shape_dist_traveled: 17.1467,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 273,
    shape_dist_traveled: 17.2037,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 274,
    shape_dist_traveled: 17.2597,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 275,
    shape_dist_traveled: 17.4158,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 276,
    shape_dist_traveled: 17.4739,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.5179,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.5569,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.5969,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.6359,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.703,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.774,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.122409,
    shape_pt_lon: -89.945453,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.8621,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.121294,
    shape_pt_lon: -89.945507,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.9872,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.120913,
    shape_pt_lon: -89.945522,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.0302,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.120245,
    shape_pt_lon: -89.945548,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.1042,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.11917,
    shape_pt_lon: -89.945591,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.2253,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.117803,
    shape_pt_lon: -89.945644,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.3784,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.116434,
    shape_pt_lon: -89.9457,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.5315,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.6405,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.115289,
    shape_pt_lon: -89.945757,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.6596,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.115097,
    shape_pt_lon: -89.945769,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.6816,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.11387,
    shape_pt_lon: -89.94581,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.8186,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.113643,
    shape_pt_lon: -89.945818,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.8436,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.113488,
    shape_pt_lon: -89.945823,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.8616,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.113079,
    shape_pt_lon: -89.945839,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.9067,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.112505,
    shape_pt_lon: -89.945862,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.9717,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.111507,
    shape_pt_lon: -89.945901,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.0828,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.111392,
    shape_pt_lon: -89.945905,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.0958,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.110998,
    shape_pt_lon: -89.94592,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.1398,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.11002,
    shape_pt_lon: -89.945956,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.2498,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.109252,
    shape_pt_lon: -89.945985,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.3359,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.10897,
    shape_pt_lon: -89.945997,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.3669,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107958,
    shape_pt_lon: -89.946041,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.4799,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.946043,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.4889,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107882,
    shape_pt_lon: -89.945972,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.4949,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107883,
    shape_pt_lon: -89.945535,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.5349,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107794,
    shape_pt_lon: -89.942447,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.8141,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107769,
    shape_pt_lon: -89.941557,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.8942,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.10774,
    shape_pt_lon: -89.940522,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.9882,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.10769,
    shape_pt_lon: -89.938887,
    shape_pt_sequence: 311,
    shape_dist_traveled: 20.1363,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107685,
    shape_pt_lon: -89.938542,
    shape_pt_sequence: 312,
    shape_dist_traveled: 20.1673,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107617,
    shape_pt_lon: -89.935723,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.4224,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107608,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.4614,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.1076,
    shape_pt_lon: -89.934959,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.4914,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107585,
    shape_pt_lon: -89.934331,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.5475,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107522,
    shape_pt_lon: -89.931714,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.7845,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107499,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.8786,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.107436,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.8856,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.106923,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.9426,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.106716,
    shape_pt_lon: -89.930678,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.9656,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.105232,
    shape_pt_lon: -89.930719,
    shape_pt_sequence: 322,
    shape_dist_traveled: 21.1317,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.105009,
    shape_pt_lon: -89.930725,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.1567,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.104382,
    shape_pt_lon: -89.930741,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.2277,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.101239,
    shape_pt_lon: -89.930821,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.5787,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.10054,
    shape_pt_lon: -89.93084,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.6578,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.099419,
    shape_pt_lon: -89.93087,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.7828,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.098169,
    shape_pt_lon: -89.930902,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.9228,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.097031,
    shape_pt_lon: -89.930932,
    shape_pt_sequence: 329,
    shape_dist_traveled: 22.0499,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.096636,
    shape_pt_lon: -89.93094,
    shape_pt_sequence: 330,
    shape_dist_traveled: 22.0939,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.095702,
    shape_pt_lon: -89.930962,
    shape_pt_sequence: 331,
    shape_dist_traveled: 22.1989,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.095111,
    shape_pt_lon: -89.930978,
    shape_pt_sequence: 332,
    shape_dist_traveled: 22.2649,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.093881,
    shape_pt_lon: -89.931008,
    shape_pt_sequence: 333,
    shape_dist_traveled: 22.4029,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.092984,
    shape_pt_lon: -89.931031,
    shape_pt_sequence: 334,
    shape_dist_traveled: 22.5029,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.092716,
    shape_pt_lon: -89.931039,
    shape_pt_sequence: 335,
    shape_dist_traveled: 22.533,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.092611,
    shape_pt_lon: -89.931039,
    shape_pt_sequence: 336,
    shape_dist_traveled: 22.545,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.091391,
    shape_pt_lon: -89.931067,
    shape_pt_sequence: 337,
    shape_dist_traveled: 22.681,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.090181,
    shape_pt_lon: -89.931093,
    shape_pt_sequence: 338,
    shape_dist_traveled: 22.817,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.088946,
    shape_pt_lon: -89.93112,
    shape_pt_sequence: 339,
    shape_dist_traveled: 22.955,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.08813,
    shape_pt_lon: -89.931142,
    shape_pt_sequence: 340,
    shape_dist_traveled: 23.046,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 341,
    shape_dist_traveled: 23.202,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 342,
    shape_dist_traveled: 23.216,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 343,
    shape_dist_traveled: 23.228,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 344,
    shape_dist_traveled: 23.243,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 345,
    shape_dist_traveled: 23.3811,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 346,
    shape_dist_traveled: 23.4771,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 347,
    shape_dist_traveled: 23.5521,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 348,
    shape_dist_traveled: 23.6001,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 349,
    shape_dist_traveled: 23.6671,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 350,
    shape_dist_traveled: 23.6891,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 351,
    shape_dist_traveled: 23.7201,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 352,
    shape_dist_traveled: 23.7311,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 353,
    shape_dist_traveled: 23.7931,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 354,
    shape_dist_traveled: 23.9072,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 355,
    shape_dist_traveled: 23.9292,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 356,
    shape_dist_traveled: 23.9462,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 357,
    shape_dist_traveled: 23.9562,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 358,
    shape_dist_traveled: 23.9692,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.9742,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.9862,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 361,
    shape_dist_traveled: 23.9952,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 362,
    shape_dist_traveled: 24.1312,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 363,
    shape_dist_traveled: 24.1983,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 364,
    shape_dist_traveled: 24.2054,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 365,
    shape_dist_traveled: 24.2435,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 366,
    shape_dist_traveled: 24.2796,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076752,
    shape_pt_lon: -89.931603,
    shape_pt_sequence: 367,
    shape_dist_traveled: 24.3214,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076651,
    shape_pt_lon: -89.93165,
    shape_pt_sequence: 368,
    shape_dist_traveled: 24.334,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076568,
    shape_pt_lon: -89.931743,
    shape_pt_sequence: 369,
    shape_dist_traveled: 24.3467,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076521,
    shape_pt_lon: -89.931842,
    shape_pt_sequence: 370,
    shape_dist_traveled: 24.357,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.07651,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 371,
    shape_dist_traveled: 24.3612,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076491,
    shape_pt_lon: -89.931999,
    shape_pt_sequence: 372,
    shape_dist_traveled: 24.3716,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 373,
    shape_dist_traveled: 24.4087,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 374,
    shape_dist_traveled: 24.4838,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 375,
    shape_dist_traveled: 24.4928,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 376,
    shape_dist_traveled: 24.4987,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 377,
    shape_dist_traveled: 24.5072,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 378,
    shape_dist_traveled: 24.5202,
  },
  {
    shape_id: 38173,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 379,
    shape_dist_traveled: 24.5813,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.176237,
    shape_pt_lon: -89.940148,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.176742,
    shape_pt_lon: -89.939902,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0602,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177021,
    shape_pt_lon: -89.93976,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0938,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17718,
    shape_pt_lon: -89.939653,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1144,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177335,
    shape_pt_lon: -89.939511,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.136,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177566,
    shape_pt_lon: -89.939269,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1693,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.178218,
    shape_pt_lon: -89.938618,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2632,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.178303,
    shape_pt_lon: -89.938517,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2766,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177786,
    shape_pt_lon: -89.937771,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3652,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177689,
    shape_pt_lon: -89.937635,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3823,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177586,
    shape_pt_lon: -89.937905,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4096,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177471,
    shape_pt_lon: -89.938066,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4287,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177359,
    shape_pt_lon: -89.938209,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4471,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.177062,
    shape_pt_lon: -89.938556,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4923,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.176316,
    shape_pt_lon: -89.939267,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5972,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.175568,
    shape_pt_lon: -89.939978,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.7028,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.175511,
    shape_pt_lon: -89.94005,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.7127,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.175376,
    shape_pt_lon: -89.940499,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7563,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.175133,
    shape_pt_lon: -89.941291,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8323,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17475,
    shape_pt_lon: -89.94254,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9532,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174379,
    shape_pt_lon: -89.943741,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0691,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174266,
    shape_pt_lon: -89.9441,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.1042,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17351,
    shape_pt_lon: -89.946572,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3428,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173329,
    shape_pt_lon: -89.947171,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.4008,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173283,
    shape_pt_lon: -89.947367,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4185,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173251,
    shape_pt_lon: -89.94759,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4397,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173237,
    shape_pt_lon: -89.947831,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4608,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173244,
    shape_pt_lon: -89.94812,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4868,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173289,
    shape_pt_lon: -89.94993,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6509,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173298,
    shape_pt_lon: -89.950901,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.7389,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17332,
    shape_pt_lon: -89.951868,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.8259,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173347,
    shape_pt_lon: -89.952841,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.914,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173375,
    shape_pt_lon: -89.953796,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.1734,
    shape_pt_lon: -89.954734,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.0851,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173418,
    shape_pt_lon: -89.95529,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.1351,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173451,
    shape_pt_lon: -89.955671,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.1693,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173558,
    shape_pt_lon: -89.956603,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.2552,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17366,
    shape_pt_lon: -89.957531,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.3389,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173758,
    shape_pt_lon: -89.958402,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.4187,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173763,
    shape_pt_lon: -89.95845,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.4228,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173869,
    shape_pt_lon: -89.959373,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.5075,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17398,
    shape_pt_lon: -89.960329,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.5945,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174093,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.6813,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174113,
    shape_pt_lon: -89.961488,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.7006,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174163,
    shape_pt_lon: -89.96222,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.7668,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174168,
    shape_pt_lon: -89.962277,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.7718,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174171,
    shape_pt_lon: -89.96235,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.7788,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174204,
    shape_pt_lon: -89.963397,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.8729,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174272,
    shape_pt_lon: -89.965344,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.0491,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174281,
    shape_pt_lon: -89.965581,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.0711,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174352,
    shape_pt_lon: -89.96775,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.2663,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174451,
    shape_pt_lon: -89.970819,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.5444,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174489,
    shape_pt_lon: -89.971887,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.6406,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174529,
    shape_pt_lon: -89.973062,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.7466,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174567,
    shape_pt_lon: -89.974229,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.8527,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174607,
    shape_pt_lon: -89.975379,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.9568,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.9609,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.9709,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.068,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.1731,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.2802,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.3553,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.4553,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.5093,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.5255,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.5924,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.6096,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.682,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.7046,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174389,
    shape_pt_lon: -89.984348,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.7717,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.984611,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.795,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174208,
    shape_pt_lon: -89.985526,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.8795,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174188,
    shape_pt_lon: -89.985652,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.8907,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.985734,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.899,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.174036,
    shape_pt_lon: -89.986666,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.9843,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173867,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.0871,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173687,
    shape_pt_lon: -89.988951,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.1941,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173649,
    shape_pt_lon: -89.989208,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.2175,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173507,
    shape_pt_lon: -89.990126,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.302,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173424,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.3518,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173299,
    shape_pt_lon: -89.991569,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.435,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173101,
    shape_pt_lon: -89.99287,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.5541,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172709,
    shape_pt_lon: -89.995526,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.7982,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172655,
    shape_pt_lon: -89.995908,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.8328,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172613,
    shape_pt_lon: -89.996368,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.875,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.99686,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.919,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172649,
    shape_pt_lon: -89.99864,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.0801,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172696,
    shape_pt_lon: -89.999673,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.1733,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.1727,
    shape_pt_lon: -90.000165,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.2183,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17277,
    shape_pt_lon: -90.002149,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.3974,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172782,
    shape_pt_lon: -90.00257,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.4354,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172902,
    shape_pt_lon: -90.005476,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.6988,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17292,
    shape_pt_lon: -90.005872,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.7338,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172963,
    shape_pt_lon: -90.00671,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.81,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173005,
    shape_pt_lon: -90.007536,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.8852,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.9683,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173057,
    shape_pt_lon: -90.008639,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.9843,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173062,
    shape_pt_lon: -90.009155,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.0313,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.173045,
    shape_pt_lon: -90.00964,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.0754,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172994,
    shape_pt_lon: -90.01023,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.1287,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17293,
    shape_pt_lon: -90.0109,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.1891,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.011314,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.2278,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172779,
    shape_pt_lon: -90.011822,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.2748,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172712,
    shape_pt_lon: -90.012141,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.3047,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172591,
    shape_pt_lon: -90.012606,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.348,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172458,
    shape_pt_lon: -90.013073,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.3935,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172305,
    shape_pt_lon: -90.013547,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.4388,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.172194,
    shape_pt_lon: -90.013851,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.4693,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.171718,
    shape_pt_lon: -90.015128,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.5964,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.171239,
    shape_pt_lon: -90.016407,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.7239,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170949,
    shape_pt_lon: -90.017179,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.8004,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17074,
    shape_pt_lon: -90.01774,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.8563,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170724,
    shape_pt_lon: -90.017781,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.8608,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17051,
    shape_pt_lon: -90.018356,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.9181,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170477,
    shape_pt_lon: -90.018446,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.927,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170448,
    shape_pt_lon: -90.018531,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.9346,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170248,
    shape_pt_lon: -90.019085,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.9902,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.17008,
    shape_pt_lon: -90.01954,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.0354,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.170013,
    shape_pt_lon: -90.019726,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.0532,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.169892,
    shape_pt_lon: -90.020041,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.085,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.169845,
    shape_pt_lon: -90.020188,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.099,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.169665,
    shape_pt_lon: -90.02072,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.1513,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.169297,
    shape_pt_lon: -90.02168,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.2475,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.169014,
    shape_pt_lon: -90.022431,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3227,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.168833,
    shape_pt_lon: -90.022901,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.3692,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.168436,
    shape_pt_lon: -90.023966,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4748,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.168399,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4847,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16809,
    shape_pt_lon: -90.024868,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.5656,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.167978,
    shape_pt_lon: -90.025146,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.5938,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.167786,
    shape_pt_lon: -90.02566,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.6444,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.167737,
    shape_pt_lon: -90.025821,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.6605,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.167378,
    shape_pt_lon: -90.0268,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.7572,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16736,
    shape_pt_lon: -90.026846,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.7616,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.167048,
    shape_pt_lon: -90.027692,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.8462,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.166926,
    shape_pt_lon: -90.028021,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.878,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.166773,
    shape_pt_lon: -90.02843,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.9192,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.166278,
    shape_pt_lon: -90.02976,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.0512,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.030981,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.1733,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.165487,
    shape_pt_lon: -90.031962,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.2692,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.165449,
    shape_pt_lon: -90.03207,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.2799,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.165231,
    shape_pt_lon: -90.032603,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.3336,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.165073,
    shape_pt_lon: -90.033033,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.3766,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.164882,
    shape_pt_lon: -90.033551,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.428,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16431,
    shape_pt_lon: -90.035105,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.582,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163866,
    shape_pt_lon: -90.036306,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.7019,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163756,
    shape_pt_lon: -90.036625,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.7324,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.7409,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.7441,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.7508,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.7692,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.8721,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038233,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.8905,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163225,
    shape_pt_lon: -90.038434,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.9085,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16323,
    shape_pt_lon: -90.038681,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.9306,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163276,
    shape_pt_lon: -90.039376,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.9938,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163314,
    shape_pt_lon: -90.039989,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.0489,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -90.040033,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.0529,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163337,
    shape_pt_lon: -90.040338,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.0811,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163359,
    shape_pt_lon: -90.040687,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.1121,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163368,
    shape_pt_lon: -90.040839,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.1262,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163401,
    shape_pt_lon: -90.041361,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.1733,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.1793,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163474,
    shape_pt_lon: -90.042521,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.2787,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.2977,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.4204,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.5371,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.6557,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.7724,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.828,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.89,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.952,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.0108,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.1244,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.1769,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.1769,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.2335,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.2335,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.2389,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.3094,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.3565,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.4163,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 182,
    shape_dist_traveled: 11.4391,
  },
  {
    shape_id: 38175,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 183,
    shape_dist_traveled: 11.5055,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.076455,
    shape_pt_lon: -89.932305,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.02,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0681,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0903,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.076489,
    shape_pt_lon: -89.931516,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0993,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1694,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2056,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2437,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2508,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3178,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4539,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4629,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4749,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.4929,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5029,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5199,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5419,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.6559,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7179,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.7289,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.7599,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.7819,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.8489,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.8969,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.9719,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.068,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.206,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.221,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.233,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.247,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.08813,
    shape_pt_lon: -89.931142,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.403,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.088946,
    shape_pt_lon: -89.93112,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.494,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.090181,
    shape_pt_lon: -89.931093,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6321,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.091391,
    shape_pt_lon: -89.931067,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7681,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.092611,
    shape_pt_lon: -89.931039,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9041,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.092716,
    shape_pt_lon: -89.931039,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9161,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.092984,
    shape_pt_lon: -89.931031,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9461,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.093881,
    shape_pt_lon: -89.931008,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0461,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.095111,
    shape_pt_lon: -89.930978,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.1841,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.095702,
    shape_pt_lon: -89.930962,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.2502,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.096636,
    shape_pt_lon: -89.93094,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.3552,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.097031,
    shape_pt_lon: -89.930932,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.3992,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.098169,
    shape_pt_lon: -89.930902,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.5262,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.099419,
    shape_pt_lon: -89.93087,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.6663,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.10054,
    shape_pt_lon: -89.93084,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.7913,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.101239,
    shape_pt_lon: -89.930821,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.8703,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.104382,
    shape_pt_lon: -89.930741,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.2213,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.105009,
    shape_pt_lon: -89.930725,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.2924,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.105232,
    shape_pt_lon: -89.930719,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.3174,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.106716,
    shape_pt_lon: -89.930678,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.4834,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.106923,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.5064,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107436,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.5635,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107499,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.5705,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107522,
    shape_pt_lon: -89.931714,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.6645,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107585,
    shape_pt_lon: -89.934331,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.9016,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.1076,
    shape_pt_lon: -89.934959,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.9576,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107608,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.9876,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107617,
    shape_pt_lon: -89.935723,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.0266,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107685,
    shape_pt_lon: -89.938542,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.2817,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.10769,
    shape_pt_lon: -89.938887,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.3128,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.10774,
    shape_pt_lon: -89.940522,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.4608,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107769,
    shape_pt_lon: -89.941557,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.5549,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107794,
    shape_pt_lon: -89.942447,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.6349,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107883,
    shape_pt_lon: -89.945535,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.9141,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107882,
    shape_pt_lon: -89.945972,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.9541,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.946043,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.9601,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.107958,
    shape_pt_lon: -89.946041,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.9691,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.10897,
    shape_pt_lon: -89.945997,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.0822,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.109252,
    shape_pt_lon: -89.945985,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.1132,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.11002,
    shape_pt_lon: -89.945956,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.1992,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.110998,
    shape_pt_lon: -89.94592,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.3092,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.111392,
    shape_pt_lon: -89.945905,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.3533,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.111507,
    shape_pt_lon: -89.945901,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.3663,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.112505,
    shape_pt_lon: -89.945862,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.4773,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.113079,
    shape_pt_lon: -89.945839,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.5424,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.113488,
    shape_pt_lon: -89.945823,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.5874,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.113643,
    shape_pt_lon: -89.945818,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.6054,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.11387,
    shape_pt_lon: -89.94581,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.6304,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.115097,
    shape_pt_lon: -89.945769,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.7675,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.115289,
    shape_pt_lon: -89.945757,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.7895,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.115462,
    shape_pt_lon: -89.945746,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.8085,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.116434,
    shape_pt_lon: -89.9457,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.9176,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.117803,
    shape_pt_lon: -89.945644,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.0707,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.11917,
    shape_pt_lon: -89.945591,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.2237,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.120245,
    shape_pt_lon: -89.945548,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.3448,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.120913,
    shape_pt_lon: -89.945522,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.4188,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.121294,
    shape_pt_lon: -89.945507,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.4618,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.122409,
    shape_pt_lon: -89.945453,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.5869,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.675,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.7461,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.8131,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.8521,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.8921,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.9312,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.9752,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.0332,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1893,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.2453,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.3023,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.3553,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.4864,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.6125,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.6535,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.133437,
    shape_pt_lon: -89.94503,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.8215,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.134152,
    shape_pt_lon: -89.945011,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.9015,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.134263,
    shape_pt_lon: -89.945007,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.9135,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.135316,
    shape_pt_lon: -89.944981,
    shape_pt_sequence: 108,
    shape_dist_traveled: 8.0316,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.136482,
    shape_pt_lon: -89.944949,
    shape_pt_sequence: 109,
    shape_dist_traveled: 8.1616,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.13742,
    shape_pt_lon: -89.944917,
    shape_pt_sequence: 110,
    shape_dist_traveled: 8.2666,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.137752,
    shape_pt_lon: -89.944904,
    shape_pt_sequence: 111,
    shape_dist_traveled: 8.3046,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.139031,
    shape_pt_lon: -89.944857,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.4477,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.140274,
    shape_pt_lon: -89.94481,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.5868,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.141051,
    shape_pt_lon: -89.944781,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.6738,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.141925,
    shape_pt_lon: -89.944751,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.7708,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.142028,
    shape_pt_lon: -89.944748,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.7819,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.142756,
    shape_pt_lon: -89.944723,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.8639,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.1432,
    shape_pt_lon: -89.94471,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.9129,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.143629,
    shape_pt_lon: -89.944695,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.9609,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.14408,
    shape_pt_lon: -89.944679,
    shape_pt_sequence: 120,
    shape_dist_traveled: 9.012,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.144469,
    shape_pt_lon: -89.944668,
    shape_pt_sequence: 121,
    shape_dist_traveled: 9.055,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.145299,
    shape_pt_lon: -89.944643,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.148,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.146242,
    shape_pt_lon: -89.94461,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.254,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.146338,
    shape_pt_lon: -89.944606,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.264,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.146511,
    shape_pt_lon: -89.944599,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.284,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.146829,
    shape_pt_lon: -89.944591,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.3191,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.147109,
    shape_pt_lon: -89.944583,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.3511,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.147289,
    shape_pt_lon: -89.944577,
    shape_pt_sequence: 128,
    shape_dist_traveled: 9.3711,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.14769,
    shape_pt_lon: -89.944559,
    shape_pt_sequence: 129,
    shape_dist_traveled: 9.4161,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.147771,
    shape_pt_lon: -89.944554,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.4251,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.147993,
    shape_pt_lon: -89.94455,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.4501,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.148119,
    shape_pt_lon: -89.944551,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.4641,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.148202,
    shape_pt_lon: -89.944549,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.4731,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.148431,
    shape_pt_lon: -89.944547,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.4991,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.148531,
    shape_pt_lon: -89.944542,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.5101,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.14871,
    shape_pt_lon: -89.944537,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.5302,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.148914,
    shape_pt_lon: -89.94453,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.5532,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.149467,
    shape_pt_lon: -89.944512,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.6152,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.149432,
    shape_pt_lon: -89.946088,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.7573,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947853,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.9173,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.149705,
    shape_pt_lon: -89.94782,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.9504,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.15031,
    shape_pt_lon: -89.947798,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.0175,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.150856,
    shape_pt_lon: -89.947779,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.0795,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.152089,
    shape_pt_lon: -89.947739,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.2175,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.153317,
    shape_pt_lon: -89.947699,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.3546,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.15456,
    shape_pt_lon: -89.947658,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.4936,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.155804,
    shape_pt_lon: -89.947613,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.6327,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156739,
    shape_pt_lon: -89.947585,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.7377,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156896,
    shape_pt_lon: -89.94758,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.7547,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.158147,
    shape_pt_lon: -89.947538,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.8947,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.158552,
    shape_pt_lon: -89.947526,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.9408,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.159349,
    shape_pt_lon: -89.947498,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.0298,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.159422,
    shape_pt_lon: -89.947494,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.0378,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.160127,
    shape_pt_lon: -89.947471,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.1168,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.160289,
    shape_pt_lon: -89.947467,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.1348,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.160449,
    shape_pt_lon: -89.947453,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.1529,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.160547,
    shape_pt_lon: -89.947442,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.164,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.160687,
    shape_pt_lon: -89.947417,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.1801,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.161422,
    shape_pt_lon: -89.947213,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.2641,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.161838,
    shape_pt_lon: -89.947097,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.3123,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.161868,
    shape_pt_lon: -89.947088,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.3153,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.161962,
    shape_pt_lon: -89.94709,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.3253,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.162006,
    shape_pt_lon: -89.947106,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.3304,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.3714,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.162496,
    shape_pt_lon: -89.946978,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.3866,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16344,
    shape_pt_lon: -89.946883,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.493,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16481,
    shape_pt_lon: -89.946718,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.6466,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165153,
    shape_pt_lon: -89.946667,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.6859,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165371,
    shape_pt_lon: -89.946603,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.7106,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165573,
    shape_pt_lon: -89.946521,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.7347,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165786,
    shape_pt_lon: -89.946404,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.7602,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165963,
    shape_pt_lon: -89.946284,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.7825,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.166301,
    shape_pt_lon: -89.946026,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.8275,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167118,
    shape_pt_lon: -89.945396,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.9352,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167936,
    shape_pt_lon: -89.944764,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.0426,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16874,
    shape_pt_lon: -89.944142,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.1486,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169557,
    shape_pt_lon: -89.943509,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.2573,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170218,
    shape_pt_lon: -89.943012,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.3434,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170331,
    shape_pt_lon: -89.942938,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.3573,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170597,
    shape_pt_lon: -89.942783,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.3904,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.171176,
    shape_pt_lon: -89.942491,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.4604,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172046,
    shape_pt_lon: -89.942077,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.5642,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172883,
    shape_pt_lon: -89.941672,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.6653,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173019,
    shape_pt_lon: -89.941631,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.6808,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173096,
    shape_pt_lon: -89.941643,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.6898,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173216,
    shape_pt_lon: -89.941671,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.7032,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17327,
    shape_pt_lon: -89.941688,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.7095,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173341,
    shape_pt_lon: -89.941742,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.7185,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173462,
    shape_pt_lon: -89.941724,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.7325,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173512,
    shape_pt_lon: -89.941679,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.7397,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173883,
    shape_pt_lon: -89.94134,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.7911,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173966,
    shape_pt_lon: -89.941262,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.8025,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175354,
    shape_pt_lon: -89.93994,
    shape_pt_sequence: 193,
    shape_dist_traveled: 12.9987,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.939717,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.0315,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17622,
    shape_pt_lon: -89.939111,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.1213,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177009,
    shape_pt_lon: -89.938348,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.2331,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177319,
    shape_pt_lon: -89.938022,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.2786,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177689,
    shape_pt_lon: -89.937635,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.3325,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177786,
    shape_pt_lon: -89.937771,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.3495,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.178303,
    shape_pt_lon: -89.938517,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.4382,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.178218,
    shape_pt_lon: -89.938618,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.4516,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177566,
    shape_pt_lon: -89.939269,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.5455,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177335,
    shape_pt_lon: -89.939511,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.5788,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17718,
    shape_pt_lon: -89.939653,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.6004,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.177021,
    shape_pt_lon: -89.93976,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.621,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.176742,
    shape_pt_lon: -89.939902,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.6546,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.176237,
    shape_pt_lon: -89.940148,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.7148,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175801,
    shape_pt_lon: -89.940348,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.767,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17565,
    shape_pt_lon: -89.940421,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.785,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175561,
    shape_pt_lon: -89.940451,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.7954,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175376,
    shape_pt_lon: -89.940499,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.817,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.175133,
    shape_pt_lon: -89.941291,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.893,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17475,
    shape_pt_lon: -89.94254,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.0139,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174379,
    shape_pt_lon: -89.943741,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.1298,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174266,
    shape_pt_lon: -89.9441,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.1649,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17351,
    shape_pt_lon: -89.946572,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.4035,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173329,
    shape_pt_lon: -89.947171,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.4615,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173283,
    shape_pt_lon: -89.947367,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.4792,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173251,
    shape_pt_lon: -89.94759,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.5004,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173237,
    shape_pt_lon: -89.947831,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.5215,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173244,
    shape_pt_lon: -89.94812,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.5475,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173289,
    shape_pt_lon: -89.94993,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.7116,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173298,
    shape_pt_lon: -89.950901,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.7996,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17332,
    shape_pt_lon: -89.951868,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.8867,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173347,
    shape_pt_lon: -89.952841,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.9747,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173375,
    shape_pt_lon: -89.953796,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.0608,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.1734,
    shape_pt_lon: -89.954734,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.1458,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173418,
    shape_pt_lon: -89.95529,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.1958,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173451,
    shape_pt_lon: -89.955671,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.2301,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173558,
    shape_pt_lon: -89.956603,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.3159,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17366,
    shape_pt_lon: -89.957531,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.3996,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173758,
    shape_pt_lon: -89.958402,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.4794,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173763,
    shape_pt_lon: -89.95845,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.4835,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173869,
    shape_pt_lon: -89.959373,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.5682,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17398,
    shape_pt_lon: -89.960329,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.6552,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174093,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.742,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174113,
    shape_pt_lon: -89.961488,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.7613,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174163,
    shape_pt_lon: -89.96222,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.8275,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174168,
    shape_pt_lon: -89.962277,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.8326,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174171,
    shape_pt_lon: -89.96235,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.8396,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174204,
    shape_pt_lon: -89.963397,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.9337,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174272,
    shape_pt_lon: -89.965344,
    shape_pt_sequence: 242,
    shape_dist_traveled: 16.1098,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174281,
    shape_pt_lon: -89.965581,
    shape_pt_sequence: 243,
    shape_dist_traveled: 16.1318,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174352,
    shape_pt_lon: -89.96775,
    shape_pt_sequence: 244,
    shape_dist_traveled: 16.327,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174451,
    shape_pt_lon: -89.970819,
    shape_pt_sequence: 245,
    shape_dist_traveled: 16.6052,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174489,
    shape_pt_lon: -89.971887,
    shape_pt_sequence: 246,
    shape_dist_traveled: 16.7013,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174529,
    shape_pt_lon: -89.973062,
    shape_pt_sequence: 247,
    shape_dist_traveled: 16.8074,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174567,
    shape_pt_lon: -89.974229,
    shape_pt_sequence: 248,
    shape_dist_traveled: 16.9134,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174607,
    shape_pt_lon: -89.975379,
    shape_pt_sequence: 249,
    shape_dist_traveled: 17.0175,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 250,
    shape_dist_traveled: 17.0216,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 251,
    shape_dist_traveled: 17.0316,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 252,
    shape_dist_traveled: 17.1287,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 253,
    shape_dist_traveled: 17.2338,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 254,
    shape_dist_traveled: 17.3409,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 255,
    shape_dist_traveled: 17.416,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 256,
    shape_dist_traveled: 17.5161,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 257,
    shape_dist_traveled: 17.5701,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 258,
    shape_dist_traveled: 17.5862,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.6531,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.6704,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.7427,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.7653,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174389,
    shape_pt_lon: -89.984348,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.8324,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.984611,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.8557,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174208,
    shape_pt_lon: -89.985526,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.9402,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174188,
    shape_pt_lon: -89.985652,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.9514,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.985734,
    shape_pt_sequence: 267,
    shape_dist_traveled: 17.9597,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.174036,
    shape_pt_lon: -89.986666,
    shape_pt_sequence: 268,
    shape_dist_traveled: 18.045,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173867,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 269,
    shape_dist_traveled: 18.1478,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173687,
    shape_pt_lon: -89.988951,
    shape_pt_sequence: 270,
    shape_dist_traveled: 18.2548,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173649,
    shape_pt_lon: -89.989208,
    shape_pt_sequence: 271,
    shape_dist_traveled: 18.2782,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173507,
    shape_pt_lon: -89.990126,
    shape_pt_sequence: 272,
    shape_dist_traveled: 18.3627,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173424,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.4125,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173299,
    shape_pt_lon: -89.991569,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.4957,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173101,
    shape_pt_lon: -89.99287,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.6148,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172709,
    shape_pt_lon: -89.995526,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.859,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172655,
    shape_pt_lon: -89.995908,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.8935,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172613,
    shape_pt_lon: -89.996368,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.9357,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.99686,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.9797,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172649,
    shape_pt_lon: -89.99864,
    shape_pt_sequence: 280,
    shape_dist_traveled: 19.1408,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172696,
    shape_pt_lon: -89.999673,
    shape_pt_sequence: 281,
    shape_dist_traveled: 19.234,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.1727,
    shape_pt_lon: -90.000165,
    shape_pt_sequence: 282,
    shape_dist_traveled: 19.279,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17277,
    shape_pt_lon: -90.002149,
    shape_pt_sequence: 283,
    shape_dist_traveled: 19.4581,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172782,
    shape_pt_lon: -90.00257,
    shape_pt_sequence: 284,
    shape_dist_traveled: 19.4962,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172902,
    shape_pt_lon: -90.005476,
    shape_pt_sequence: 285,
    shape_dist_traveled: 19.7595,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17292,
    shape_pt_lon: -90.005872,
    shape_pt_sequence: 286,
    shape_dist_traveled: 19.7945,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172963,
    shape_pt_lon: -90.00671,
    shape_pt_sequence: 287,
    shape_dist_traveled: 19.8707,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173005,
    shape_pt_lon: -90.007536,
    shape_pt_sequence: 288,
    shape_dist_traveled: 19.9459,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 289,
    shape_dist_traveled: 20.029,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173057,
    shape_pt_lon: -90.008639,
    shape_pt_sequence: 290,
    shape_dist_traveled: 20.0451,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173062,
    shape_pt_lon: -90.009155,
    shape_pt_sequence: 291,
    shape_dist_traveled: 20.0921,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.173045,
    shape_pt_lon: -90.00964,
    shape_pt_sequence: 292,
    shape_dist_traveled: 20.1361,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172994,
    shape_pt_lon: -90.01023,
    shape_pt_sequence: 293,
    shape_dist_traveled: 20.1894,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17293,
    shape_pt_lon: -90.0109,
    shape_pt_sequence: 294,
    shape_dist_traveled: 20.2498,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.011314,
    shape_pt_sequence: 295,
    shape_dist_traveled: 20.2885,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172779,
    shape_pt_lon: -90.011822,
    shape_pt_sequence: 296,
    shape_dist_traveled: 20.3356,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172712,
    shape_pt_lon: -90.012141,
    shape_pt_sequence: 297,
    shape_dist_traveled: 20.3654,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172591,
    shape_pt_lon: -90.012606,
    shape_pt_sequence: 298,
    shape_dist_traveled: 20.4087,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172458,
    shape_pt_lon: -90.013073,
    shape_pt_sequence: 299,
    shape_dist_traveled: 20.4543,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172305,
    shape_pt_lon: -90.013547,
    shape_pt_sequence: 300,
    shape_dist_traveled: 20.4996,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.172194,
    shape_pt_lon: -90.013851,
    shape_pt_sequence: 301,
    shape_dist_traveled: 20.53,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.171718,
    shape_pt_lon: -90.015128,
    shape_pt_sequence: 302,
    shape_dist_traveled: 20.6571,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.171239,
    shape_pt_lon: -90.016407,
    shape_pt_sequence: 303,
    shape_dist_traveled: 20.7846,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170949,
    shape_pt_lon: -90.017179,
    shape_pt_sequence: 304,
    shape_dist_traveled: 20.8611,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17074,
    shape_pt_lon: -90.01774,
    shape_pt_sequence: 305,
    shape_dist_traveled: 20.917,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170724,
    shape_pt_lon: -90.017781,
    shape_pt_sequence: 306,
    shape_dist_traveled: 20.9215,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17051,
    shape_pt_lon: -90.018356,
    shape_pt_sequence: 307,
    shape_dist_traveled: 20.9788,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170477,
    shape_pt_lon: -90.018446,
    shape_pt_sequence: 308,
    shape_dist_traveled: 20.9877,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170448,
    shape_pt_lon: -90.018531,
    shape_pt_sequence: 309,
    shape_dist_traveled: 20.9953,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170248,
    shape_pt_lon: -90.019085,
    shape_pt_sequence: 310,
    shape_dist_traveled: 21.0509,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.17008,
    shape_pt_lon: -90.01954,
    shape_pt_sequence: 311,
    shape_dist_traveled: 21.0961,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.170013,
    shape_pt_lon: -90.019726,
    shape_pt_sequence: 312,
    shape_dist_traveled: 21.114,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169892,
    shape_pt_lon: -90.020041,
    shape_pt_sequence: 313,
    shape_dist_traveled: 21.1457,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169845,
    shape_pt_lon: -90.020188,
    shape_pt_sequence: 314,
    shape_dist_traveled: 21.1597,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169665,
    shape_pt_lon: -90.02072,
    shape_pt_sequence: 315,
    shape_dist_traveled: 21.2121,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169297,
    shape_pt_lon: -90.02168,
    shape_pt_sequence: 316,
    shape_dist_traveled: 21.3082,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.169014,
    shape_pt_lon: -90.022431,
    shape_pt_sequence: 317,
    shape_dist_traveled: 21.3834,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.168833,
    shape_pt_lon: -90.022901,
    shape_pt_sequence: 318,
    shape_dist_traveled: 21.4299,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.168436,
    shape_pt_lon: -90.023966,
    shape_pt_sequence: 319,
    shape_dist_traveled: 21.5355,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.168399,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 320,
    shape_dist_traveled: 21.5454,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16809,
    shape_pt_lon: -90.024868,
    shape_pt_sequence: 321,
    shape_dist_traveled: 21.6263,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167978,
    shape_pt_lon: -90.025146,
    shape_pt_sequence: 322,
    shape_dist_traveled: 21.6545,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167786,
    shape_pt_lon: -90.02566,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.7051,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167737,
    shape_pt_lon: -90.025821,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.7212,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167378,
    shape_pt_lon: -90.0268,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.8179,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16736,
    shape_pt_lon: -90.026846,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.8224,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.167048,
    shape_pt_lon: -90.027692,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.9069,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.166926,
    shape_pt_lon: -90.028021,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.9387,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.166773,
    shape_pt_lon: -90.02843,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.9799,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.166278,
    shape_pt_lon: -90.02976,
    shape_pt_sequence: 330,
    shape_dist_traveled: 22.1119,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.030981,
    shape_pt_sequence: 331,
    shape_dist_traveled: 22.234,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165487,
    shape_pt_lon: -90.031962,
    shape_pt_sequence: 332,
    shape_dist_traveled: 22.3299,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165449,
    shape_pt_lon: -90.03207,
    shape_pt_sequence: 333,
    shape_dist_traveled: 22.3407,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165231,
    shape_pt_lon: -90.032603,
    shape_pt_sequence: 334,
    shape_dist_traveled: 22.3943,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.165073,
    shape_pt_lon: -90.033033,
    shape_pt_sequence: 335,
    shape_dist_traveled: 22.4373,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.164882,
    shape_pt_lon: -90.033551,
    shape_pt_sequence: 336,
    shape_dist_traveled: 22.4887,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16431,
    shape_pt_lon: -90.035105,
    shape_pt_sequence: 337,
    shape_dist_traveled: 22.6427,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163866,
    shape_pt_lon: -90.036306,
    shape_pt_sequence: 338,
    shape_dist_traveled: 22.7626,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163756,
    shape_pt_lon: -90.036625,
    shape_pt_sequence: 339,
    shape_dist_traveled: 22.7931,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 340,
    shape_dist_traveled: 22.8016,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 341,
    shape_dist_traveled: 22.8048,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 342,
    shape_dist_traveled: 22.8115,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.8299,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.9328,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038233,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.9513,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163225,
    shape_pt_lon: -90.038434,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.9693,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16323,
    shape_pt_lon: -90.038681,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.9913,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163276,
    shape_pt_lon: -90.039376,
    shape_pt_sequence: 348,
    shape_dist_traveled: 23.0545,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163314,
    shape_pt_lon: -90.039989,
    shape_pt_sequence: 349,
    shape_dist_traveled: 23.1096,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -90.040033,
    shape_pt_sequence: 350,
    shape_dist_traveled: 23.1136,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163337,
    shape_pt_lon: -90.040338,
    shape_pt_sequence: 351,
    shape_dist_traveled: 23.1418,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163359,
    shape_pt_lon: -90.040687,
    shape_pt_sequence: 352,
    shape_dist_traveled: 23.1729,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163368,
    shape_pt_lon: -90.040839,
    shape_pt_sequence: 353,
    shape_dist_traveled: 23.1869,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163401,
    shape_pt_lon: -90.041361,
    shape_pt_sequence: 354,
    shape_dist_traveled: 23.2341,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 355,
    shape_dist_traveled: 23.2401,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163474,
    shape_pt_lon: -90.042521,
    shape_pt_sequence: 356,
    shape_dist_traveled: 23.3394,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 357,
    shape_dist_traveled: 23.3584,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 358,
    shape_dist_traveled: 23.4811,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.5978,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.7164,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 361,
    shape_dist_traveled: 23.8331,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 362,
    shape_dist_traveled: 23.8888,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.9507,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 364,
    shape_dist_traveled: 24.0127,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 365,
    shape_dist_traveled: 24.0715,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 366,
    shape_dist_traveled: 24.1851,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 367,
    shape_dist_traveled: 24.2376,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 368,
    shape_dist_traveled: 24.2376,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 369,
    shape_dist_traveled: 24.2942,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 370,
    shape_dist_traveled: 24.2942,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 371,
    shape_dist_traveled: 24.2996,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 372,
    shape_dist_traveled: 24.3701,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 373,
    shape_dist_traveled: 24.4172,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 374,
    shape_dist_traveled: 24.477,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 375,
    shape_dist_traveled: 24.4998,
  },
  {
    shape_id: 38176,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 376,
    shape_dist_traveled: 24.5662,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157122,
    shape_pt_lon: -90.048039,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157293,
    shape_pt_lon: -90.048685,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.062,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157347,
    shape_pt_lon: -90.048908,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0829,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157448,
    shape_pt_lon: -90.049328,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1224,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.049456,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1591,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157054,
    shape_pt_lon: -90.049486,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1686,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156497,
    shape_pt_lon: -90.049711,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2337,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156597,
    shape_pt_lon: -90.050088,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2695,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156615,
    shape_pt_lon: -90.050156,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2758,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156644,
    shape_pt_lon: -90.050261,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2862,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156695,
    shape_pt_lon: -90.050447,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3043,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156708,
    shape_pt_lon: -90.050491,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3087,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156695,
    shape_pt_lon: -90.050447,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.3132,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156644,
    shape_pt_lon: -90.050261,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.3312,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156615,
    shape_pt_lon: -90.050156,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.3417,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156597,
    shape_pt_lon: -90.050088,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.348,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156497,
    shape_pt_lon: -90.049711,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.155498,
    shape_pt_lon: -90.050118,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5017,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.154505,
    shape_pt_lon: -90.050515,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.6184,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153956,
    shape_pt_lon: -90.050732,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.6823,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153442,
    shape_pt_lon: -90.050942,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.7433,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153129,
    shape_pt_lon: -90.051063,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.78,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153042,
    shape_pt_lon: -90.051094,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.7904,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152872,
    shape_pt_lon: -90.051162,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.8104,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152775,
    shape_pt_lon: -90.0512,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.8221,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.15155,
    shape_pt_lon: -90.051689,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.966,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150529,
    shape_pt_lon: -90.05213,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0868,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150037,
    shape_pt_lon: -90.052346,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.145,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.149593,
    shape_pt_lon: -90.052526,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1975,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148702,
    shape_pt_lon: -90.052867,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3012,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148539,
    shape_pt_lon: -90.05292,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.3209,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148707,
    shape_pt_lon: -90.053538,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.38,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148746,
    shape_pt_lon: -90.05367,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.393,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14877,
    shape_pt_lon: -90.053727,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.3984,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.1488,
    shape_pt_lon: -90.053795,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.4056,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148826,
    shape_pt_lon: -90.053844,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.411,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148682,
    shape_pt_lon: -90.053896,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.4275,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148453,
    shape_pt_lon: -90.053996,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.454,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.147823,
    shape_pt_lon: -90.054322,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.5311,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.147682,
    shape_pt_lon: -90.054399,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.5486,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.147434,
    shape_pt_lon: -90.054578,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.58,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146911,
    shape_pt_lon: -90.054928,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.6466,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146536,
    shape_pt_lon: -90.05514,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.6931,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146432,
    shape_pt_lon: -90.055189,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.7048,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.145947,
    shape_pt_lon: -90.055391,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.7627,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.145899,
    shape_pt_lon: -90.055411,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.7681,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14488,
    shape_pt_lon: -90.05578,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.8868,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.144403,
    shape_pt_lon: -90.055978,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.9437,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14394,
    shape_pt_lon: -90.056189,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.9981,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.143776,
    shape_pt_lon: -90.056279,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.0187,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.1428,
    shape_pt_lon: -90.056893,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.1413,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.142229,
    shape_pt_lon: -90.057241,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.2124,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.141828,
    shape_pt_lon: -90.057465,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.262,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.141473,
    shape_pt_lon: -90.057759,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.3081,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.141205,
    shape_pt_lon: -90.058002,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.3453,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14091,
    shape_pt_lon: -90.058317,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.3892,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14013,
    shape_pt_lon: -90.059065,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.4998,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139882,
    shape_pt_lon: -90.05929,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.5334,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139622,
    shape_pt_lon: -90.059577,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.5731,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139231,
    shape_pt_lon: -90.060104,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.6376,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139057,
    shape_pt_lon: -90.060315,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.6652,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.138819,
    shape_pt_lon: -90.060551,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.6986,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.138574,
    shape_pt_lon: -90.06076,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.7324,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.137187,
    shape_pt_lon: -90.061748,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.9111,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.136888,
    shape_pt_lon: -90.061977,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.9503,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.136425,
    shape_pt_lon: -90.062376,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.0135,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.133985,
    shape_pt_lon: -90.064528,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.349,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.13356,
    shape_pt_lon: -90.064927,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.409,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132303,
    shape_pt_lon: -90.066198,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.5909,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132145,
    shape_pt_lon: -90.066346,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.6123,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131959,
    shape_pt_lon: -90.066483,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.6365,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131682,
    shape_pt_lon: -90.066638,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.6706,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131417,
    shape_pt_lon: -90.066737,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.7019,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131257,
    shape_pt_lon: -90.066779,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.7203,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.130914,
    shape_pt_lon: -90.066819,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.7585,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.130381,
    shape_pt_lon: -90.066837,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.8185,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129473,
    shape_pt_lon: -90.066871,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9196,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129438,
    shape_pt_lon: -90.065325,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0586,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129428,
    shape_pt_lon: -90.064858,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.1016,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129422,
    shape_pt_lon: -90.064661,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.1187,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129411,
    shape_pt_lon: -90.064282,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.1537,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129389,
    shape_pt_lon: -90.063727,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.2038,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129384,
    shape_pt_lon: -90.063637,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.2118,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129383,
    shape_pt_lon: -90.063606,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.2148,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.12937,
    shape_pt_lon: -90.063379,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.2349,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129341,
    shape_pt_lon: -90.063091,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.261,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129367,
    shape_pt_lon: -90.062994,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.2705,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.1294,
    shape_pt_lon: -90.062934,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.2769,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129463,
    shape_pt_lon: -90.06288,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.2855,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.1295,
    shape_pt_lon: -90.062858,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.29,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129821,
    shape_pt_lon: -90.062751,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.3274,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.129863,
    shape_pt_lon: -90.062747,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.3314,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.12991,
    shape_pt_lon: -90.06274,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.3375,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.130666,
    shape_pt_lon: -90.062722,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.4215,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131138,
    shape_pt_lon: -90.062701,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.4745,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.131583,
    shape_pt_lon: -90.062682,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.5245,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132368,
    shape_pt_lon: -90.062671,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.6125,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.13285,
    shape_pt_lon: -90.062664,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.6665,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132849,
    shape_pt_lon: -90.06257,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.6745,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132849,
    shape_pt_lon: -90.062518,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.6795,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132889,
    shape_pt_lon: -90.062446,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.6868,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132844,
    shape_pt_lon: -90.061356,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.7859,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132833,
    shape_pt_lon: -90.060802,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.8359,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132831,
    shape_pt_lon: -90.059749,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.9309,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.132823,
    shape_pt_lon: -90.059,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.9989,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.134092,
    shape_pt_lon: -90.058958,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.141,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.134343,
    shape_pt_lon: -90.058941,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.169,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.134476,
    shape_pt_lon: -90.058908,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.1843,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.134614,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.2001,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.134772,
    shape_pt_lon: -90.058771,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.2198,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.135482,
    shape_pt_lon: -90.058266,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.3107,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.1356,
    shape_pt_lon: -90.058168,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.3265,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.136122,
    shape_pt_lon: -90.0578,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.3941,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.136349,
    shape_pt_lon: -90.057626,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.4238,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.137194,
    shape_pt_lon: -90.057018,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.5336,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.138059,
    shape_pt_lon: -90.056393,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.6456,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.138956,
    shape_pt_lon: -90.055742,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.7617,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139102,
    shape_pt_lon: -90.05563,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.7805,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.139789,
    shape_pt_lon: -90.055148,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.8692,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.140023,
    shape_pt_lon: -90.054969,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.8998,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.140313,
    shape_pt_lon: -90.054799,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.936,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.140671,
    shape_pt_lon: -90.054651,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.9784,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.140932,
    shape_pt_lon: -90.054531,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.0094,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.141103,
    shape_pt_lon: -90.055168,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.0704,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.141274,
    shape_pt_lon: -90.055798,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.1305,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14172,
    shape_pt_lon: -90.055612,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.1824,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14236,
    shape_pt_lon: -90.055356,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.258,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14288,
    shape_pt_lon: -90.055146,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.319,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.143131,
    shape_pt_lon: -90.055043,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.3484,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.14357,
    shape_pt_lon: -90.054874,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.4,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.144007,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.4507,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.144546,
    shape_pt_lon: -90.054519,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.5145,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.145048,
    shape_pt_lon: -90.05432,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.5734,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.145561,
    shape_pt_lon: -90.05411,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.6335,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146002,
    shape_pt_lon: -90.053939,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.6847,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146157,
    shape_pt_lon: -90.05388,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.7034,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.146559,
    shape_pt_lon: -90.053716,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.7508,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.147334,
    shape_pt_lon: -90.053392,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.8425,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.147559,
    shape_pt_lon: -90.053302,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.8688,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148539,
    shape_pt_lon: -90.05292,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.9832,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.148702,
    shape_pt_lon: -90.052867,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.0029,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.149593,
    shape_pt_lon: -90.052526,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.1066,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150037,
    shape_pt_lon: -90.052346,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.1591,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150529,
    shape_pt_lon: -90.05213,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.2173,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150448,
    shape_pt_lon: -90.051883,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.242,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150295,
    shape_pt_lon: -90.051323,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.2948,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150172,
    shape_pt_lon: -90.050838,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.341,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150087,
    shape_pt_lon: -90.050487,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.3742,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.4631,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.5814,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.699,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150561,
    shape_pt_lon: -90.048171,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.7234,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150743,
    shape_pt_lon: -90.048698,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.7754,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.8168,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.151209,
    shape_pt_lon: -90.050325,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.9313,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.151237,
    shape_pt_lon: -90.050352,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.9349,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.151289,
    shape_pt_lon: -90.050351,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.9409,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.151751,
    shape_pt_lon: -90.05019,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.995,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152043,
    shape_pt_lon: -90.050073,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.0295,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152218,
    shape_pt_lon: -90.050002,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.0498,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152823,
    shape_pt_lon: -90.049739,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.1215,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.152941,
    shape_pt_lon: -90.04969,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.1355,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153049,
    shape_pt_lon: -90.049651,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.1478,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153133,
    shape_pt_lon: -90.04962,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.1573,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153281,
    shape_pt_lon: -90.049567,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.175,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153653,
    shape_pt_lon: -90.049428,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.2187,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.153966,
    shape_pt_lon: -90.04931,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.2554,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.15415,
    shape_pt_lon: -90.04924,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.2763,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.15515,
    shape_pt_lon: -90.048837,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.3943,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.15613,
    shape_pt_lon: -90.048439,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.51,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.156166,
    shape_pt_lon: -90.048424,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.5141,
  },
  {
    shape_id: 38182,
    shape_pt_lat: 35.157122,
    shape_pt_lon: -90.048039,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.6267,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139171,
    shape_pt_lon: -90.019128,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0814,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139207,
    shape_pt_lon: -90.019516,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1166,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139247,
    shape_pt_lon: -90.019948,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1559,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139332,
    shape_pt_lon: -90.020851,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2374,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139402,
    shape_pt_lon: -90.02159,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3049,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139412,
    shape_pt_lon: -90.021707,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.316,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139506,
    shape_pt_lon: -90.022709,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4065,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139537,
    shape_pt_lon: -90.023,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4328,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139585,
    shape_pt_lon: -90.023492,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4781,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139591,
    shape_pt_lon: -90.023569,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4852,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139605,
    shape_pt_lon: -90.023703,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4973,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139628,
    shape_pt_lon: -90.023941,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5184,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139632,
    shape_pt_lon: -90.023997,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5245,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139748,
    shape_pt_lon: -90.025362,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.6481,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139768,
    shape_pt_lon: -90.025563,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6663,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139877,
    shape_pt_lon: -90.026695,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.769,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.139951,
    shape_pt_lon: -90.027221,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8177,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140011,
    shape_pt_lon: -90.027755,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8662,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9034,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140192,
    shape_pt_lon: -90.029567,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0314,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140357,
    shape_pt_lon: -90.031225,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.1825,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140393,
    shape_pt_lon: -90.03161,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.2167,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.14049,
    shape_pt_lon: -90.032643,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.3114,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140519,
    shape_pt_lon: -90.033486,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.3874,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140526,
    shape_pt_lon: -90.03367,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4045,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140635,
    shape_pt_lon: -90.035034,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.5281,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140655,
    shape_pt_lon: -90.035373,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5582,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140696,
    shape_pt_lon: -90.035608,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.5806,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140695,
    shape_pt_lon: -90.035715,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5896,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140719,
    shape_pt_lon: -90.035931,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6098,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140742,
    shape_pt_lon: -90.036056,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.621,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140775,
    shape_pt_lon: -90.036085,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.626,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.140856,
    shape_pt_lon: -90.036602,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6728,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.141114,
    shape_pt_lon: -90.037494,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7589,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.14121,
    shape_pt_lon: -90.037799,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.789,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.14122,
    shape_pt_lon: -90.037829,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7912,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.141232,
    shape_pt_lon: -90.037876,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7963,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.141219,
    shape_pt_lon: -90.037955,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8034,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.141241,
    shape_pt_lon: -90.038043,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8116,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.141348,
    shape_pt_lon: -90.03846,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.8505,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142028,
    shape_pt_lon: -90.040989,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0915,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142079,
    shape_pt_lon: -90.041137,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1058,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142155,
    shape_pt_lon: -90.041358,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1273,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142216,
    shape_pt_lon: -90.041538,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1457,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142263,
    shape_pt_lon: -90.041653,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1569,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142457,
    shape_pt_lon: -90.042118,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2043,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142636,
    shape_pt_lon: -90.042468,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2421,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142817,
    shape_pt_lon: -90.042897,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.285,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.142906,
    shape_pt_lon: -90.043146,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3101,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143038,
    shape_pt_lon: -90.04363,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.3566,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143096,
    shape_pt_lon: -90.043906,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.3813,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143116,
    shape_pt_lon: -90.044016,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.3917,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143121,
    shape_pt_lon: -90.044052,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.3957,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143139,
    shape_pt_lon: -90.044169,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.4059,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143176,
    shape_pt_lon: -90.04447,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.4332,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143326,
    shape_pt_lon: -90.045585,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.5357,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143407,
    shape_pt_lon: -90.046079,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.5815,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143476,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.6068,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143501,
    shape_pt_lon: -90.04647,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.6182,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143743,
    shape_pt_lon: -90.047373,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.7046,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.143831,
    shape_pt_lon: -90.047702,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.7353,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.144011,
    shape_pt_lon: -90.048376,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.7995,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.9835,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0433,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.1011,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.1578,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.2178,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.3348,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.147019,
    shape_pt_lon: -90.051379,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.3959,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.147209,
    shape_pt_lon: -90.052009,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.457,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.147389,
    shape_pt_lon: -90.052648,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.5174,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.147559,
    shape_pt_lon: -90.053302,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.5803,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.147334,
    shape_pt_lon: -90.053392,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.6066,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146559,
    shape_pt_lon: -90.053716,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.6983,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146157,
    shape_pt_lon: -90.05388,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.7457,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.146002,
    shape_pt_lon: -90.053939,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.7644,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.145561,
    shape_pt_lon: -90.05411,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.8156,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.145407,
    shape_pt_lon: -90.053486,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.8742,
  },
  {
    shape_id: 38183,
    shape_pt_lat: 35.14523,
    shape_pt_lon: -90.052809,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.9384,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.14523,
    shape_pt_lon: -90.052809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.145058,
    shape_pt_lon: -90.052164,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.061,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1218,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.144761,
    shape_pt_lon: -90.051206,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1541,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.144557,
    shape_pt_lon: -90.050466,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2249,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2395,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.144011,
    shape_pt_lon: -90.048376,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.4235,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143831,
    shape_pt_lon: -90.047702,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4877,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143743,
    shape_pt_lon: -90.047373,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.5184,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143501,
    shape_pt_lon: -90.04647,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6047,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143476,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6161,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143407,
    shape_pt_lon: -90.046079,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6414,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143326,
    shape_pt_lon: -90.045585,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.6873,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143176,
    shape_pt_lon: -90.04447,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.7897,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143139,
    shape_pt_lon: -90.044169,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.817,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143121,
    shape_pt_lon: -90.044052,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.8272,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143116,
    shape_pt_lon: -90.044016,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.8312,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143096,
    shape_pt_lon: -90.043906,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8417,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.143038,
    shape_pt_lon: -90.04363,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8664,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142906,
    shape_pt_lon: -90.043146,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9129,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142817,
    shape_pt_lon: -90.042897,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.938,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142636,
    shape_pt_lon: -90.042468,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9809,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142457,
    shape_pt_lon: -90.042118,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0187,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142263,
    shape_pt_lon: -90.041653,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0661,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142216,
    shape_pt_lon: -90.041538,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0773,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142155,
    shape_pt_lon: -90.041358,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0956,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142079,
    shape_pt_lon: -90.041137,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.1172,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142028,
    shape_pt_lon: -90.040989,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1315,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141348,
    shape_pt_lon: -90.03846,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3725,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141241,
    shape_pt_lon: -90.038043,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4114,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141219,
    shape_pt_lon: -90.037955,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4196,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.14117,
    shape_pt_lon: -90.037903,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4274,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141156,
    shape_pt_lon: -90.03785,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.4325,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141018,
    shape_pt_lon: -90.037348,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4803,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140828,
    shape_pt_lon: -90.03666,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5457,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140736,
    shape_pt_lon: -90.036098,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.5977,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140742,
    shape_pt_lon: -90.036056,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6017,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140719,
    shape_pt_lon: -90.035931,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6129,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140695,
    shape_pt_lon: -90.035715,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.6331,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140696,
    shape_pt_lon: -90.035608,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.6421,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140655,
    shape_pt_lon: -90.035373,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.6645,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140635,
    shape_pt_lon: -90.035034,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.6946,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140526,
    shape_pt_lon: -90.03367,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.8182,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140519,
    shape_pt_lon: -90.033486,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8352,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.14049,
    shape_pt_lon: -90.032643,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.9113,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140393,
    shape_pt_lon: -90.03161,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.0059,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140357,
    shape_pt_lon: -90.031225,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.0402,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140192,
    shape_pt_lon: -90.029567,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.1913,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3193,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140011,
    shape_pt_lon: -90.027755,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3565,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139951,
    shape_pt_lon: -90.027221,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.405,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139877,
    shape_pt_lon: -90.026695,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4536,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139768,
    shape_pt_lon: -90.025563,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.5563,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139748,
    shape_pt_lon: -90.025362,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.5746,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139632,
    shape_pt_lon: -90.023997,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.6982,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139628,
    shape_pt_lon: -90.023941,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7043,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139605,
    shape_pt_lon: -90.023703,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7254,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139591,
    shape_pt_lon: -90.023569,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.7375,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139585,
    shape_pt_lon: -90.023492,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.7446,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139537,
    shape_pt_lon: -90.023,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.7899,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139506,
    shape_pt_lon: -90.022709,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.8162,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139412,
    shape_pt_lon: -90.021707,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.9067,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139402,
    shape_pt_lon: -90.02159,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.9178,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139921,
    shape_pt_lon: -90.021523,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.9761,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140433,
    shape_pt_lon: -90.02145,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0335,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.142065,
    shape_pt_lon: -90.021216,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.2167,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019836,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.3419,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141913,
    shape_pt_lon: -90.019838,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.3419,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141846,
    shape_pt_lon: -90.019141,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.4053,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141849,
    shape_pt_lon: -90.019147,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.4053,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141808,
    shape_pt_lon: -90.018502,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.4635,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141751,
    shape_pt_lon: -90.017871,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.5208,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.141078,
    shape_pt_lon: -90.017969,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.5953,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.140445,
    shape_pt_lon: -90.018055,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.6668,
  },
  {
    shape_id: 38184,
    shape_pt_lat: 35.139095,
    shape_pt_lon: -90.018239,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.8186,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1521,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1553,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1639,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.273,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.377,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.39,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3954,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4505,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4782,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.4862,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.4945,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5071,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6229,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.6459,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.6773,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.7361,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.7491,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8582,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9173,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9759,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0913,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.146,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2089,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2744,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3259,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.3826,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4414,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.4676,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5012,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5603,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.6223,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6789,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7064,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7529,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8149,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.8531,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9228,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0167,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.067,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1227,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1477,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2328,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3386,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4506,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.135843,
    shape_pt_lon: -90.056147,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5385,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.135726,
    shape_pt_lon: -90.056195,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5524,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.134526,
    shape_pt_lon: -90.05639,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.6885,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.134034,
    shape_pt_lon: -90.056461,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.7439,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.885,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132789,
    shape_pt_lon: -90.05745,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.97,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132803,
    shape_pt_lon: -90.058196,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.037,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132806,
    shape_pt_lon: -90.058301,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.047,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132823,
    shape_pt_lon: -90.059,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.1101,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132831,
    shape_pt_lon: -90.059749,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.1781,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.132833,
    shape_pt_lon: -90.060802,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.2731,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129763,
    shape_pt_lon: -90.06089,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.6172,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129591,
    shape_pt_lon: -90.060899,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.6362,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129515,
    shape_pt_lon: -90.060923,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.6444,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129456,
    shape_pt_lon: -90.060971,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.6525,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129435,
    shape_pt_lon: -90.060999,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.6561,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129371,
    shape_pt_lon: -90.061113,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6689,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129353,
    shape_pt_lon: -90.061208,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.6781,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.129358,
    shape_pt_lon: -90.061781,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.7291,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.128252,
    shape_pt_lon: -90.061818,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8532,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.126989,
    shape_pt_lon: -90.06186,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.9943,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.126912,
    shape_pt_lon: -90.061862,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.0033,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.125788,
    shape_pt_lon: -90.061906,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.1293,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.124706,
    shape_pt_lon: -90.061952,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.2504,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.124642,
    shape_pt_lon: -90.061952,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.2574,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.124583,
    shape_pt_lon: -90.061953,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.2634,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.12435,
    shape_pt_lon: -90.061959,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.2894,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.124066,
    shape_pt_lon: -90.061968,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.3214,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.12346,
    shape_pt_lon: -90.061986,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.3894,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.122827,
    shape_pt_lon: -90.062006,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.4605,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.122201,
    shape_pt_lon: -90.062028,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.5305,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.120753,
    shape_pt_lon: -90.062083,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.6926,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.119407,
    shape_pt_lon: -90.062132,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.8436,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.118634,
    shape_pt_lon: -90.062166,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.9297,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.116738,
    shape_pt_lon: -90.062237,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.1418,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.116207,
    shape_pt_lon: -90.062252,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.2018,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.113949,
    shape_pt_lon: -90.062279,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.4538,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.112548,
    shape_pt_lon: -90.062294,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.6108,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.11243,
    shape_pt_lon: -90.062296,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.6238,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.111308,
    shape_pt_lon: -90.062318,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.7498,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.110168,
    shape_pt_lon: -90.06234,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.8769,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.108695,
    shape_pt_lon: -90.062367,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.0419,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107739,
    shape_pt_lon: -90.06239,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.1489,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107761,
    shape_pt_lon: -90.063447,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.2449,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107777,
    shape_pt_lon: -90.064192,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.3119,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107788,
    shape_pt_lon: -90.064761,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.3639,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.1078,
    shape_pt_lon: -90.065367,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.418,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107806,
    shape_pt_lon: -90.065658,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.445,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107817,
    shape_pt_lon: -90.066073,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.482,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107848,
    shape_pt_lon: -90.067416,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.6041,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107849,
    shape_pt_lon: -90.067507,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.6121,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.10788,
    shape_pt_lon: -90.069145,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.7601,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.10789,
    shape_pt_lon: -90.06962,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.8031,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.107907,
    shape_pt_lon: -90.070391,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.8712,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.10796,
    shape_pt_lon: -90.07298,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.1052,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.106929,
    shape_pt_lon: -90.073029,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.2213,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.106065,
    shape_pt_lon: -90.073057,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.3174,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.10548,
    shape_pt_lon: -90.073077,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.3834,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.105216,
    shape_pt_lon: -90.07309,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.4124,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.104427,
    shape_pt_lon: -90.073127,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.5015,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.103642,
    shape_pt_lon: -90.073156,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.5895,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.102862,
    shape_pt_lon: -90.073189,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.6766,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.102279,
    shape_pt_lon: -90.073213,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.7417,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.102052,
    shape_pt_lon: -90.073213,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.7667,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.102047,
    shape_pt_lon: -90.073009,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.7857,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.102013,
    shape_pt_lon: -90.071889,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.8868,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101973,
    shape_pt_lon: -90.070869,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.9788,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101929,
    shape_pt_lon: -90.07007,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.052,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101909,
    shape_pt_lon: -90.069597,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.0931,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101873,
    shape_pt_lon: -90.068773,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.1682,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101821,
    shape_pt_lon: -90.067621,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.2724,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101808,
    shape_pt_lon: -90.067228,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.3074,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101799,
    shape_pt_lon: -90.067003,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.3284,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101769,
    shape_pt_lon: -90.066294,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.3925,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101743,
    shape_pt_lon: -90.065651,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.4506,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101737,
    shape_pt_lon: -90.065406,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.4726,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101709,
    shape_pt_lon: -90.064982,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.5117,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.101008,
    shape_pt_lon: -90.065061,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.5901,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.100304,
    shape_pt_lon: -90.065139,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.6694,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09961,
    shape_pt_lon: -90.065218,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.7477,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.098901,
    shape_pt_lon: -90.065296,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.8271,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.098245,
    shape_pt_lon: -90.06537,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.9004,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.098001,
    shape_pt_lon: -90.065394,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.9285,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.097799,
    shape_pt_lon: -90.065438,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.9508,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.097659,
    shape_pt_lon: -90.06551,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.9679,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09758,
    shape_pt_lon: -90.065568,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.9782,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.096924,
    shape_pt_lon: -90.066043,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.0629,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.096231,
    shape_pt_lon: -90.066542,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.153,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.096178,
    shape_pt_lon: -90.066586,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.1602,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095679,
    shape_pt_lon: -90.066982,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.2268,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095626,
    shape_pt_lon: -90.067026,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.234,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095489,
    shape_pt_lon: -90.067117,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.251,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095221,
    shape_pt_lon: -90.067248,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.2833,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095037,
    shape_pt_lon: -90.06731,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.3042,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094933,
    shape_pt_lon: -90.067346,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.3165,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094693,
    shape_pt_lon: -90.06739,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.3438,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094261,
    shape_pt_lon: -90.067431,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.392,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094046,
    shape_pt_lon: -90.067452,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.4161,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093778,
    shape_pt_lon: -90.067477,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.4461,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093334,
    shape_pt_lon: -90.06751,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.4962,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09327,
    shape_pt_lon: -90.067506,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.5032,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093239,
    shape_pt_lon: -90.067504,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.5074,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093133,
    shape_pt_lon: -90.067498,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.5184,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092899,
    shape_pt_lon: -90.06743,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.546,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092734,
    shape_pt_lon: -90.067361,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.565,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09255,
    shape_pt_lon: -90.06725,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.5887,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092434,
    shape_pt_lon: -90.067171,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.6035,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092977,
    shape_pt_lon: -90.065445,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.771,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09304,
    shape_pt_lon: -90.0653,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.7857,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093149,
    shape_pt_lon: -90.065117,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.8057,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.093271,
    shape_pt_lon: -90.064963,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.8255,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094558,
    shape_pt_lon: -90.063778,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.0049,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094818,
    shape_pt_lon: -90.063469,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.0452,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095017,
    shape_pt_lon: -90.063148,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.0816,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.095195,
    shape_pt_lon: -90.062786,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.1202,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094636,
    shape_pt_lon: -90.062798,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.1822,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.09456,
    shape_pt_lon: -90.062799,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.1912,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.094093,
    shape_pt_lon: -90.062809,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.2433,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092614,
    shape_pt_lon: -90.06285,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.4093,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.092147,
    shape_pt_lon: -90.062863,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.4613,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.091647,
    shape_pt_lon: -90.062876,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.5173,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.090647,
    shape_pt_lon: -90.062901,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.6293,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.089643,
    shape_pt_lon: -90.06293,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.7414,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.088639,
    shape_pt_lon: -90.062956,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.8534,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08764,
    shape_pt_lon: -90.062985,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.9654,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086639,
    shape_pt_lon: -90.063008,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.0775,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086632,
    shape_pt_lon: -90.062688,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.1065,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08662,
    shape_pt_lon: -90.062498,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.1235,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.0866,
    shape_pt_lon: -90.060637,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.2915,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08656,
    shape_pt_lon: -90.058299,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.5036,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086531,
    shape_pt_lon: -90.056843,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.6356,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056747,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.6436,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056711,
    shape_pt_sequence: 182,
    shape_dist_traveled: 11.6466,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086519,
    shape_pt_lon: -90.056078,
    shape_pt_sequence: 183,
    shape_dist_traveled: 11.7046,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086517,
    shape_pt_lon: -90.055959,
    shape_pt_sequence: 184,
    shape_dist_traveled: 11.7147,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086476,
    shape_pt_lon: -90.053961,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.8957,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086452,
    shape_pt_lon: -90.052219,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.0537,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086449,
    shape_pt_lon: -90.051985,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.0747,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08644,
    shape_pt_lon: -90.050879,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.1747,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086429,
    shape_pt_lon: -90.050416,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.2168,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086358,
    shape_pt_lon: -90.047251,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.5029,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08631,
    shape_pt_lon: -90.04451,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.7509,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086261,
    shape_pt_lon: -90.042308,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.95,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08622,
    shape_pt_lon: -90.039966,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.1611,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086169,
    shape_pt_lon: -90.036919,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.4371,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08612,
    shape_pt_lon: -90.034544,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.6522,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086114,
    shape_pt_lon: -90.034143,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.6882,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086103,
    shape_pt_lon: -90.033542,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.7422,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086073,
    shape_pt_lon: -90.031991,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.8833,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086065,
    shape_pt_lon: -90.03158,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.9203,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086016,
    shape_pt_lon: -90.031498,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.9289,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.086014,
    shape_pt_lon: -90.031467,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.9319,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08601,
    shape_pt_lon: -90.031307,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.9459,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085989,
    shape_pt_lon: -90.030772,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.9949,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085973,
    shape_pt_lon: -90.030615,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.0091,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085963,
    shape_pt_lon: -90.030543,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.0162,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085935,
    shape_pt_lon: -90.03045,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.0247,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085898,
    shape_pt_lon: -90.030351,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.0346,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085835,
    shape_pt_lon: -90.030232,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.0476,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085736,
    shape_pt_lon: -90.030093,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.0639,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.085619,
    shape_pt_lon: -90.029967,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.0816,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.084546,
    shape_pt_lon: -90.029027,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.2294,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083936,
    shape_pt_lon: -90.028481,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.3132,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08372,
    shape_pt_lon: -90.028255,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.3445,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083627,
    shape_pt_lon: -90.028121,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.3609,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083569,
    shape_pt_lon: -90.028001,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.3731,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08351,
    shape_pt_lon: -90.027851,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.3883,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083467,
    shape_pt_lon: -90.027686,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.4041,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08344,
    shape_pt_lon: -90.027526,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.4185,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083433,
    shape_pt_lon: -90.027343,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.4355,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083424,
    shape_pt_lon: -90.026598,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.5025,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083412,
    shape_pt_lon: -90.026097,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.5485,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083411,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.5845,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083411,
    shape_pt_lon: -90.025585,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.5945,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083409,
    shape_pt_lon: -90.025486,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.6036,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08341,
    shape_pt_lon: -90.025332,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.6176,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083452,
    shape_pt_lon: -90.025256,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.6257,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083447,
    shape_pt_lon: -90.025149,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.6347,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083431,
    shape_pt_lon: -90.02449,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.6947,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083401,
    shape_pt_lon: -90.023202,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.8107,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083375,
    shape_pt_lon: -90.021881,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.9308,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083363,
    shape_pt_lon: -90.02147,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.9678,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083347,
    shape_pt_lon: -90.020723,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.0358,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08334,
    shape_pt_lon: -90.020337,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.0698,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083337,
    shape_pt_lon: -90.020193,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.0828,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083332,
    shape_pt_lon: -90.020013,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.0999,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083322,
    shape_pt_lon: -90.019021,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.1889,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083307,
    shape_pt_lon: -90.018605,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.2269,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083304,
    shape_pt_lon: -90.018566,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.2309,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.0833,
    shape_pt_lon: -90.018473,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.2389,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083298,
    shape_pt_lon: -90.017517,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.3249,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083301,
    shape_pt_lon: -90.016707,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.3989,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083295,
    shape_pt_lon: -90.016395,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.427,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083281,
    shape_pt_lon: -90.015807,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.48,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08324,
    shape_pt_lon: -90.01572,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.4894,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08323,
    shape_pt_lon: -90.015211,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.5354,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08322,
    shape_pt_lon: -90.015109,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.5445,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083186,
    shape_pt_lon: -90.014962,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.5581,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083134,
    shape_pt_lon: -90.014844,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.5706,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083092,
    shape_pt_lon: -90.014778,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.5784,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.083065,
    shape_pt_lon: -90.014735,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.5834,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082972,
    shape_pt_lon: -90.014641,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.5969,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.08278,
    shape_pt_lon: -90.014485,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.6221,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082747,
    shape_pt_lon: -90.014403,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.6302,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082621,
    shape_pt_lon: -90.014308,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.6468,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082488,
    shape_pt_lon: -90.014181,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.6654,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082362,
    shape_pt_lon: -90.014039,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.6845,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082246,
    shape_pt_lon: -90.013855,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.7059,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082154,
    shape_pt_lon: -90.013643,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.7274,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082086,
    shape_pt_lon: -90.013443,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.7471,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082051,
    shape_pt_lon: -90.013296,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.7607,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082048,
    shape_pt_lon: -90.013233,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.7667,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082026,
    shape_pt_lon: -90.012959,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.7919,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.082008,
    shape_pt_lon: -90.012363,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.8459,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.081176,
    shape_pt_lon: -90.012406,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.939,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.080378,
    shape_pt_lon: -90.012435,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.028,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079583,
    shape_pt_lon: -90.01247,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.1171,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078789,
    shape_pt_lon: -90.012508,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.2061,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077952,
    shape_pt_lon: -90.012543,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.3002,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077938,
    shape_pt_lon: -90.012089,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.3412,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077896,
    shape_pt_lon: -90.010887,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.4493,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077866,
    shape_pt_lon: -90.009887,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.5404,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077821,
    shape_pt_lon: -90.008539,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.6625,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077813,
    shape_pt_lon: -90.008318,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.6825,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077806,
    shape_pt_lon: -90.008117,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.7005,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077781,
    shape_pt_lon: -90.007434,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.7626,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077735,
    shape_pt_lon: -90.005931,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.8976,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077704,
    shape_pt_lon: -90.00498,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.9837,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077686,
    shape_pt_lon: -90.004379,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.0388,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077657,
    shape_pt_lon: -90.003526,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.1158,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077616,
    shape_pt_lon: -90.0023,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.2269,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077611,
    shape_pt_lon: -90.002128,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.2419,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077617,
    shape_pt_lon: -90.00173,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.278,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077666,
    shape_pt_lon: -90.001187,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.3272,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077711,
    shape_pt_lon: -90.000889,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.3547,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077843,
    shape_pt_lon: -90.000359,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.405,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077879,
    shape_pt_lon: -90.000249,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.4157,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077922,
    shape_pt_lon: -90.000125,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.4287,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078239,
    shape_pt_lon: -89.999271,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.5133,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078356,
    shape_pt_lon: -89.998871,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.5516,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078383,
    shape_pt_lon: -89.998729,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.5652,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078423,
    shape_pt_lon: -89.998528,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.5836,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078467,
    shape_pt_lon: -89.998192,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.614,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078486,
    shape_pt_lon: -89.997828,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.6471,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078483,
    shape_pt_lon: -89.99754,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.6731,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078453,
    shape_pt_lon: -89.996609,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.7572,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078441,
    shape_pt_lon: -89.996268,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.7882,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078436,
    shape_pt_lon: -89.996,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.8122,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078409,
    shape_pt_lon: -89.995334,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.8733,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078293,
    shape_pt_lon: -89.992688,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.1126,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078237,
    shape_pt_lon: -89.991497,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.2208,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078218,
    shape_pt_lon: -89.991318,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.2369,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078139,
    shape_pt_lon: -89.990991,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.2673,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.07801,
    shape_pt_lon: -89.990648,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.3017,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077762,
    shape_pt_lon: -89.990031,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.3639,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.07763,
    shape_pt_lon: -89.989671,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.4002,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077576,
    shape_pt_lon: -89.989489,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.4172,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077525,
    shape_pt_lon: -89.989282,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.4372,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077422,
    shape_pt_lon: -89.988653,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.4952,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077341,
    shape_pt_lon: -89.988067,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.549,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077342,
    shape_pt_lon: -89.987877,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.566,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077368,
    shape_pt_lon: -89.987743,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.5783,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077411,
    shape_pt_lon: -89.987622,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.5904,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077522,
    shape_pt_lon: -89.987434,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.6112,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078399,
    shape_pt_lon: -89.986129,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.7646,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.07851,
    shape_pt_lon: -89.986007,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.7817,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078642,
    shape_pt_lon: -89.985923,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.7978,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079033,
    shape_pt_lon: -89.985849,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.8422,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079042,
    shape_pt_lon: -89.98558,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.8672,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079081,
    shape_pt_lon: -89.985406,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.883,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079137,
    shape_pt_lon: -89.985295,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.8947,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079239,
    shape_pt_lon: -89.985149,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.9125,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.07929,
    shape_pt_lon: -89.985039,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.9241,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079322,
    shape_pt_lon: -89.984919,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.9349,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.079134,
    shape_pt_lon: -89.98494,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.9559,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078938,
    shape_pt_lon: -89.984974,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.9783,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078643,
    shape_pt_lon: -89.985034,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.0117,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.078213,
    shape_pt_lon: -89.985126,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.0603,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.07723,
    shape_pt_lon: -89.985337,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.173,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077081,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.1892,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.077027,
    shape_pt_lon: -89.985382,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.1953,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.076889,
    shape_pt_lon: -89.985413,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.2116,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.076452,
    shape_pt_lon: -89.985517,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.2614,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.076308,
    shape_pt_lon: -89.985551,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.2777,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.985582,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.292,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.075837,
    shape_pt_lon: -89.985667,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.3308,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.075751,
    shape_pt_lon: -89.985687,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.341,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.075283,
    shape_pt_lon: -89.985796,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.394,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.985825,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.4091,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.075,
    shape_pt_lon: -89.985859,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.4264,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.074102,
    shape_pt_lon: -89.986058,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.529,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.073676,
    shape_pt_lon: -89.986155,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.5768,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.072943,
    shape_pt_lon: -89.986292,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.6597,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.072719,
    shape_pt_lon: -89.986367,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.6857,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.072344,
    shape_pt_lon: -89.986465,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.7286,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.070852,
    shape_pt_lon: -89.986799,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.8983,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.070628,
    shape_pt_lon: -89.986851,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.9236,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.070134,
    shape_pt_lon: -89.986979,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.9809,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.069826,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.0161,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.069379,
    shape_pt_lon: -89.987227,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.0677,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.987366,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.1041,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.987491,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.1324,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.1418,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.068713,
    shape_pt_lon: -89.987548,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.1482,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.068657,
    shape_pt_lon: -89.987588,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.1554,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987664,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.1693,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.1948,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.2941,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.6182,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.7028,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.7546,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.8043,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.8063,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.8345,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.882,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.9353,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 366,
    shape_dist_traveled: 20.9691,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.0125,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.0345,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.0856,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.1072,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.1257,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.1393,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.1576,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.1773,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.1938,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.2168,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.2408,
  },
  {
    shape_id: 38191,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.3755,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1171,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1391,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1902,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2119,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2303,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2623,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2819,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2984,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3214,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3454,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4215,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4785,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5166,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5226,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6746,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8437,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9537,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9739,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9942,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0094,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0258,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0465,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0679,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0869,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.105,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.12,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1606,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1878,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2564,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.2847,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3142,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4271,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.4347,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.4441,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.5274,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8523,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9127,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9605,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.979,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.997,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0277,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0797,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.0857,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1001,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1111,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.1172,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.1312,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.1752,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06865,
    shape_pt_lon: -89.985071,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.2044,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068625,
    shape_pt_lon: -89.984978,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.2126,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068562,
    shape_pt_lon: -89.984818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.2292,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068495,
    shape_pt_lon: -89.984687,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.2428,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068416,
    shape_pt_lon: -89.984587,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.2562,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068328,
    shape_pt_lon: -89.984503,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.2676,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068199,
    shape_pt_lon: -89.984432,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2842,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068038,
    shape_pt_lon: -89.984391,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3025,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067921,
    shape_pt_lon: -89.984398,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.3155,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067789,
    shape_pt_lon: -89.984438,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.331,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067707,
    shape_pt_lon: -89.984488,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.3408,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067603,
    shape_pt_lon: -89.984597,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.3564,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06755,
    shape_pt_lon: -89.984689,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.3673,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067501,
    shape_pt_lon: -89.984828,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.3803,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06749,
    shape_pt_lon: -89.98499,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.3953,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067507,
    shape_pt_lon: -89.985162,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.4104,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067575,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.4307,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067659,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.4463,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067739,
    shape_pt_lon: -89.985591,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.4583,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.067882,
    shape_pt_lon: -89.985692,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.4767,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06811,
    shape_pt_lon: -89.985803,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.5036,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06868,
    shape_pt_lon: -89.985849,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.5678,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.5738,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.06903,
    shape_pt_lon: -89.985883,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.607,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.070991,
    shape_pt_lon: -89.986079,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.8267,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.072129,
    shape_pt_lon: -89.986183,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.955,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.072364,
    shape_pt_lon: -89.986204,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.9811,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.072728,
    shape_pt_lon: -89.986197,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.0221,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.073,
    shape_pt_lon: -89.986175,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.0522,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.073206,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.0753,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.073437,
    shape_pt_lon: -89.986107,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1017,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.074099,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.1768,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.074912,
    shape_pt_lon: -89.985771,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.2694,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.075888,
    shape_pt_lon: -89.985547,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.3802,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07617,
    shape_pt_lon: -89.985479,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.4127,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.076291,
    shape_pt_lon: -89.985453,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.4259,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.076441,
    shape_pt_lon: -89.985422,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.4432,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.076887,
    shape_pt_lon: -89.985323,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.494,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077022,
    shape_pt_lon: -89.985293,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.5093,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077076,
    shape_pt_lon: -89.985282,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.5153,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077372,
    shape_pt_lon: -89.985227,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.5487,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078192,
    shape_pt_lon: -89.985049,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.6421,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078692,
    shape_pt_lon: -89.984928,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.6992,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07896,
    shape_pt_lon: -89.984866,
    shape_pt_sequence: 94,
    shape_dist_traveled: 3.7296,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079172,
    shape_pt_lon: -89.984841,
    shape_pt_sequence: 95,
    shape_dist_traveled: 3.7537,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079338,
    shape_pt_lon: -89.984819,
    shape_pt_sequence: 96,
    shape_dist_traveled: 3.7718,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079339,
    shape_pt_lon: -89.984863,
    shape_pt_sequence: 97,
    shape_dist_traveled: 3.7759,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079322,
    shape_pt_lon: -89.984919,
    shape_pt_sequence: 98,
    shape_dist_traveled: 3.7813,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07929,
    shape_pt_lon: -89.985039,
    shape_pt_sequence: 99,
    shape_dist_traveled: 3.792,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079239,
    shape_pt_lon: -89.985149,
    shape_pt_sequence: 100,
    shape_dist_traveled: 3.8037,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079137,
    shape_pt_lon: -89.985295,
    shape_pt_sequence: 101,
    shape_dist_traveled: 3.8215,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079081,
    shape_pt_lon: -89.985406,
    shape_pt_sequence: 102,
    shape_dist_traveled: 3.8332,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079042,
    shape_pt_lon: -89.98558,
    shape_pt_sequence: 103,
    shape_dist_traveled: 3.849,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079033,
    shape_pt_lon: -89.985849,
    shape_pt_sequence: 104,
    shape_dist_traveled: 3.874,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078642,
    shape_pt_lon: -89.985923,
    shape_pt_sequence: 105,
    shape_dist_traveled: 3.9184,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07851,
    shape_pt_lon: -89.986007,
    shape_pt_sequence: 106,
    shape_dist_traveled: 3.9345,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078399,
    shape_pt_lon: -89.986129,
    shape_pt_sequence: 107,
    shape_dist_traveled: 3.9516,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077522,
    shape_pt_lon: -89.987434,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.105,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077411,
    shape_pt_lon: -89.987622,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.1258,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077368,
    shape_pt_lon: -89.987743,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.1378,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077342,
    shape_pt_lon: -89.987877,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.1502,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077341,
    shape_pt_lon: -89.988067,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.1672,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077422,
    shape_pt_lon: -89.988653,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.221,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077525,
    shape_pt_lon: -89.989282,
    shape_pt_sequence: 114,
    shape_dist_traveled: 4.279,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077576,
    shape_pt_lon: -89.989489,
    shape_pt_sequence: 115,
    shape_dist_traveled: 4.299,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07763,
    shape_pt_lon: -89.989671,
    shape_pt_sequence: 116,
    shape_dist_traveled: 4.316,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077762,
    shape_pt_lon: -89.990031,
    shape_pt_sequence: 117,
    shape_dist_traveled: 4.3523,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.07801,
    shape_pt_lon: -89.990648,
    shape_pt_sequence: 118,
    shape_dist_traveled: 4.4145,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078139,
    shape_pt_lon: -89.990991,
    shape_pt_sequence: 119,
    shape_dist_traveled: 4.4489,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078218,
    shape_pt_lon: -89.991318,
    shape_pt_sequence: 120,
    shape_dist_traveled: 4.4793,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078237,
    shape_pt_lon: -89.991497,
    shape_pt_sequence: 121,
    shape_dist_traveled: 4.4954,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078293,
    shape_pt_lon: -89.992688,
    shape_pt_sequence: 122,
    shape_dist_traveled: 4.6036,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078409,
    shape_pt_lon: -89.995334,
    shape_pt_sequence: 123,
    shape_dist_traveled: 4.8429,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078436,
    shape_pt_lon: -89.996,
    shape_pt_sequence: 124,
    shape_dist_traveled: 4.904,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078441,
    shape_pt_lon: -89.996268,
    shape_pt_sequence: 125,
    shape_dist_traveled: 4.928,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078453,
    shape_pt_lon: -89.996609,
    shape_pt_sequence: 126,
    shape_dist_traveled: 4.959,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078483,
    shape_pt_lon: -89.99754,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.0431,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078486,
    shape_pt_lon: -89.997828,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.0691,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078467,
    shape_pt_lon: -89.998192,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.1022,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078423,
    shape_pt_lon: -89.998528,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.1326,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078383,
    shape_pt_lon: -89.998729,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.151,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078356,
    shape_pt_lon: -89.998871,
    shape_pt_sequence: 132,
    shape_dist_traveled: 5.1646,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078239,
    shape_pt_lon: -89.999271,
    shape_pt_sequence: 133,
    shape_dist_traveled: 5.2029,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077922,
    shape_pt_lon: -90.000125,
    shape_pt_sequence: 134,
    shape_dist_traveled: 5.2875,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077879,
    shape_pt_lon: -90.000249,
    shape_pt_sequence: 135,
    shape_dist_traveled: 5.3005,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077843,
    shape_pt_lon: -90.000359,
    shape_pt_sequence: 136,
    shape_dist_traveled: 5.3112,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077711,
    shape_pt_lon: -90.000889,
    shape_pt_sequence: 137,
    shape_dist_traveled: 5.3615,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077666,
    shape_pt_lon: -90.001187,
    shape_pt_sequence: 138,
    shape_dist_traveled: 5.389,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077617,
    shape_pt_lon: -90.00173,
    shape_pt_sequence: 139,
    shape_dist_traveled: 5.4382,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077611,
    shape_pt_lon: -90.002128,
    shape_pt_sequence: 140,
    shape_dist_traveled: 5.4743,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077616,
    shape_pt_lon: -90.0023,
    shape_pt_sequence: 141,
    shape_dist_traveled: 5.4893,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077657,
    shape_pt_lon: -90.003526,
    shape_pt_sequence: 142,
    shape_dist_traveled: 5.6004,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077686,
    shape_pt_lon: -90.004379,
    shape_pt_sequence: 143,
    shape_dist_traveled: 5.6774,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077704,
    shape_pt_lon: -90.00498,
    shape_pt_sequence: 144,
    shape_dist_traveled: 5.7325,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077735,
    shape_pt_lon: -90.005931,
    shape_pt_sequence: 145,
    shape_dist_traveled: 5.8186,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077781,
    shape_pt_lon: -90.007434,
    shape_pt_sequence: 146,
    shape_dist_traveled: 5.9536,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077806,
    shape_pt_lon: -90.008117,
    shape_pt_sequence: 147,
    shape_dist_traveled: 6.0157,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077813,
    shape_pt_lon: -90.008318,
    shape_pt_sequence: 148,
    shape_dist_traveled: 6.0337,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077821,
    shape_pt_lon: -90.008539,
    shape_pt_sequence: 149,
    shape_dist_traveled: 6.0537,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077866,
    shape_pt_lon: -90.009887,
    shape_pt_sequence: 150,
    shape_dist_traveled: 6.1758,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077896,
    shape_pt_lon: -90.010887,
    shape_pt_sequence: 151,
    shape_dist_traveled: 6.2669,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077938,
    shape_pt_lon: -90.012089,
    shape_pt_sequence: 152,
    shape_dist_traveled: 6.375,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.077952,
    shape_pt_lon: -90.012543,
    shape_pt_sequence: 153,
    shape_dist_traveled: 6.416,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.078789,
    shape_pt_lon: -90.012508,
    shape_pt_sequence: 154,
    shape_dist_traveled: 6.5101,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.079583,
    shape_pt_lon: -90.01247,
    shape_pt_sequence: 155,
    shape_dist_traveled: 6.5991,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.080378,
    shape_pt_lon: -90.012435,
    shape_pt_sequence: 156,
    shape_dist_traveled: 6.6882,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.081176,
    shape_pt_lon: -90.012406,
    shape_pt_sequence: 157,
    shape_dist_traveled: 6.7772,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082008,
    shape_pt_lon: -90.012363,
    shape_pt_sequence: 158,
    shape_dist_traveled: 6.8703,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082026,
    shape_pt_lon: -90.012959,
    shape_pt_sequence: 159,
    shape_dist_traveled: 6.9243,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082048,
    shape_pt_lon: -90.013233,
    shape_pt_sequence: 160,
    shape_dist_traveled: 6.9495,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082051,
    shape_pt_lon: -90.013296,
    shape_pt_sequence: 161,
    shape_dist_traveled: 6.9555,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082086,
    shape_pt_lon: -90.013443,
    shape_pt_sequence: 162,
    shape_dist_traveled: 6.9691,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082154,
    shape_pt_lon: -90.013643,
    shape_pt_sequence: 163,
    shape_dist_traveled: 6.9888,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082246,
    shape_pt_lon: -90.013855,
    shape_pt_sequence: 164,
    shape_dist_traveled: 7.0103,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082362,
    shape_pt_lon: -90.014039,
    shape_pt_sequence: 165,
    shape_dist_traveled: 7.0317,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082488,
    shape_pt_lon: -90.014181,
    shape_pt_sequence: 166,
    shape_dist_traveled: 7.0508,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082621,
    shape_pt_lon: -90.014308,
    shape_pt_sequence: 167,
    shape_dist_traveled: 7.0694,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082747,
    shape_pt_lon: -90.014403,
    shape_pt_sequence: 168,
    shape_dist_traveled: 7.086,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.082809,
    shape_pt_lon: -90.014403,
    shape_pt_sequence: 169,
    shape_dist_traveled: 7.093,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083005,
    shape_pt_lon: -90.014531,
    shape_pt_sequence: 170,
    shape_dist_traveled: 7.1181,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083119,
    shape_pt_lon: -90.014639,
    shape_pt_sequence: 171,
    shape_dist_traveled: 7.1331,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08319,
    shape_pt_lon: -90.014729,
    shape_pt_sequence: 172,
    shape_dist_traveled: 7.1444,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083248,
    shape_pt_lon: -90.01482,
    shape_pt_sequence: 173,
    shape_dist_traveled: 7.1558,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08328,
    shape_pt_lon: -90.014919,
    shape_pt_sequence: 174,
    shape_dist_traveled: 7.1653,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083303,
    shape_pt_lon: -90.015051,
    shape_pt_sequence: 175,
    shape_dist_traveled: 7.1777,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083316,
    shape_pt_lon: -90.015721,
    shape_pt_sequence: 176,
    shape_dist_traveled: 7.2377,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083281,
    shape_pt_lon: -90.015807,
    shape_pt_sequence: 177,
    shape_dist_traveled: 7.2462,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083295,
    shape_pt_lon: -90.016395,
    shape_pt_sequence: 178,
    shape_dist_traveled: 7.2992,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083301,
    shape_pt_lon: -90.016707,
    shape_pt_sequence: 179,
    shape_dist_traveled: 7.3272,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083298,
    shape_pt_lon: -90.017517,
    shape_pt_sequence: 180,
    shape_dist_traveled: 7.4012,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.0833,
    shape_pt_lon: -90.018473,
    shape_pt_sequence: 181,
    shape_dist_traveled: 7.4873,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083304,
    shape_pt_lon: -90.018566,
    shape_pt_sequence: 182,
    shape_dist_traveled: 7.4953,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083307,
    shape_pt_lon: -90.018605,
    shape_pt_sequence: 183,
    shape_dist_traveled: 7.4993,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083322,
    shape_pt_lon: -90.019021,
    shape_pt_sequence: 184,
    shape_dist_traveled: 7.5373,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083332,
    shape_pt_lon: -90.020013,
    shape_pt_sequence: 185,
    shape_dist_traveled: 7.6263,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083337,
    shape_pt_lon: -90.020193,
    shape_pt_sequence: 186,
    shape_dist_traveled: 7.6433,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08334,
    shape_pt_lon: -90.020337,
    shape_pt_sequence: 187,
    shape_dist_traveled: 7.6563,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083347,
    shape_pt_lon: -90.020723,
    shape_pt_sequence: 188,
    shape_dist_traveled: 7.6904,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083363,
    shape_pt_lon: -90.02147,
    shape_pt_sequence: 189,
    shape_dist_traveled: 7.7584,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083375,
    shape_pt_lon: -90.021881,
    shape_pt_sequence: 190,
    shape_dist_traveled: 7.7954,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083401,
    shape_pt_lon: -90.023202,
    shape_pt_sequence: 191,
    shape_dist_traveled: 7.9155,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083431,
    shape_pt_lon: -90.02449,
    shape_pt_sequence: 192,
    shape_dist_traveled: 8.0315,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083447,
    shape_pt_lon: -90.025149,
    shape_pt_sequence: 193,
    shape_dist_traveled: 8.0915,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083452,
    shape_pt_lon: -90.025256,
    shape_pt_sequence: 194,
    shape_dist_traveled: 8.1005,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083488,
    shape_pt_lon: -90.025341,
    shape_pt_sequence: 195,
    shape_dist_traveled: 8.1095,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025481,
    shape_pt_sequence: 196,
    shape_dist_traveled: 8.1225,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025582,
    shape_pt_sequence: 197,
    shape_dist_traveled: 8.1315,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 198,
    shape_dist_traveled: 8.1415,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083491,
    shape_pt_lon: -90.025836,
    shape_pt_sequence: 199,
    shape_dist_traveled: 8.1545,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083499,
    shape_pt_lon: -90.026217,
    shape_pt_sequence: 200,
    shape_dist_traveled: 8.1885,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083499,
    shape_pt_lon: -90.026449,
    shape_pt_sequence: 201,
    shape_dist_traveled: 8.2095,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083501,
    shape_pt_lon: -90.026613,
    shape_pt_sequence: 202,
    shape_dist_traveled: 8.2245,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083516,
    shape_pt_lon: -90.027348,
    shape_pt_sequence: 203,
    shape_dist_traveled: 8.2906,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08353,
    shape_pt_lon: -90.027558,
    shape_pt_sequence: 204,
    shape_dist_traveled: 8.3097,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083559,
    shape_pt_lon: -90.027701,
    shape_pt_sequence: 205,
    shape_dist_traveled: 8.323,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083617,
    shape_pt_lon: -90.027871,
    shape_pt_sequence: 206,
    shape_dist_traveled: 8.3405,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083694,
    shape_pt_lon: -90.028028,
    shape_pt_sequence: 207,
    shape_dist_traveled: 8.3566,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083769,
    shape_pt_lon: -90.02813,
    shape_pt_sequence: 208,
    shape_dist_traveled: 8.3693,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.083876,
    shape_pt_lon: -90.028251,
    shape_pt_sequence: 209,
    shape_dist_traveled: 8.3856,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.084255,
    shape_pt_lon: -90.028594,
    shape_pt_sequence: 210,
    shape_dist_traveled: 8.4378,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.084614,
    shape_pt_lon: -90.02891,
    shape_pt_sequence: 211,
    shape_dist_traveled: 8.4872,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.084996,
    shape_pt_lon: -90.029233,
    shape_pt_sequence: 212,
    shape_dist_traveled: 8.5391,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.085709,
    shape_pt_lon: -90.029888,
    shape_pt_sequence: 213,
    shape_dist_traveled: 8.6385,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.085836,
    shape_pt_lon: -90.030038,
    shape_pt_sequence: 214,
    shape_dist_traveled: 8.6583,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.085888,
    shape_pt_lon: -90.030112,
    shape_pt_sequence: 215,
    shape_dist_traveled: 8.6675,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.085947,
    shape_pt_lon: -90.03022,
    shape_pt_sequence: 216,
    shape_dist_traveled: 8.6783,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.085999,
    shape_pt_lon: -90.030356,
    shape_pt_sequence: 217,
    shape_dist_traveled: 8.6926,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086032,
    shape_pt_lon: -90.030458,
    shape_pt_sequence: 218,
    shape_dist_traveled: 8.7025,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086059,
    shape_pt_lon: -90.030596,
    shape_pt_sequence: 219,
    shape_dist_traveled: 8.7148,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086077,
    shape_pt_lon: -90.030727,
    shape_pt_sequence: 220,
    shape_dist_traveled: 8.727,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086099,
    shape_pt_lon: -90.031297,
    shape_pt_sequence: 221,
    shape_dist_traveled: 8.779,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086101,
    shape_pt_lon: -90.031456,
    shape_pt_sequence: 222,
    shape_dist_traveled: 8.793,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086101,
    shape_pt_lon: -90.03148,
    shape_pt_sequence: 223,
    shape_dist_traveled: 8.795,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086098,
    shape_pt_lon: -90.03149,
    shape_pt_sequence: 224,
    shape_dist_traveled: 8.796,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086065,
    shape_pt_lon: -90.03158,
    shape_pt_sequence: 225,
    shape_dist_traveled: 8.805,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086073,
    shape_pt_lon: -90.031991,
    shape_pt_sequence: 226,
    shape_dist_traveled: 8.842,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086103,
    shape_pt_lon: -90.033542,
    shape_pt_sequence: 227,
    shape_dist_traveled: 8.9831,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086114,
    shape_pt_lon: -90.034143,
    shape_pt_sequence: 228,
    shape_dist_traveled: 9.0371,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08612,
    shape_pt_lon: -90.034544,
    shape_pt_sequence: 229,
    shape_dist_traveled: 9.0731,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086169,
    shape_pt_lon: -90.036919,
    shape_pt_sequence: 230,
    shape_dist_traveled: 9.2882,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08622,
    shape_pt_lon: -90.039966,
    shape_pt_sequence: 231,
    shape_dist_traveled: 9.5642,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086261,
    shape_pt_lon: -90.042308,
    shape_pt_sequence: 232,
    shape_dist_traveled: 9.7753,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08631,
    shape_pt_lon: -90.04451,
    shape_pt_sequence: 233,
    shape_dist_traveled: 9.9743,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086358,
    shape_pt_lon: -90.047251,
    shape_pt_sequence: 234,
    shape_dist_traveled: 10.2224,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086429,
    shape_pt_lon: -90.050416,
    shape_pt_sequence: 235,
    shape_dist_traveled: 10.5085,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08644,
    shape_pt_lon: -90.050879,
    shape_pt_sequence: 236,
    shape_dist_traveled: 10.5505,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086449,
    shape_pt_lon: -90.051985,
    shape_pt_sequence: 237,
    shape_dist_traveled: 10.6505,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086452,
    shape_pt_lon: -90.052219,
    shape_pt_sequence: 238,
    shape_dist_traveled: 10.6715,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086476,
    shape_pt_lon: -90.053961,
    shape_pt_sequence: 239,
    shape_dist_traveled: 10.8295,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086517,
    shape_pt_lon: -90.055959,
    shape_pt_sequence: 240,
    shape_dist_traveled: 11.0106,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086519,
    shape_pt_lon: -90.056078,
    shape_pt_sequence: 241,
    shape_dist_traveled: 11.0206,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056711,
    shape_pt_sequence: 242,
    shape_dist_traveled: 11.0787,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056747,
    shape_pt_sequence: 243,
    shape_dist_traveled: 11.0817,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086531,
    shape_pt_lon: -90.056843,
    shape_pt_sequence: 244,
    shape_dist_traveled: 11.0897,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08656,
    shape_pt_lon: -90.058299,
    shape_pt_sequence: 245,
    shape_dist_traveled: 11.2217,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.0866,
    shape_pt_lon: -90.060637,
    shape_pt_sequence: 246,
    shape_dist_traveled: 11.4337,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08662,
    shape_pt_lon: -90.062498,
    shape_pt_sequence: 247,
    shape_dist_traveled: 11.6018,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086632,
    shape_pt_lon: -90.062688,
    shape_pt_sequence: 248,
    shape_dist_traveled: 11.6188,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.086639,
    shape_pt_lon: -90.063008,
    shape_pt_sequence: 249,
    shape_dist_traveled: 11.6478,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.08764,
    shape_pt_lon: -90.062985,
    shape_pt_sequence: 250,
    shape_dist_traveled: 11.7598,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.088639,
    shape_pt_lon: -90.062956,
    shape_pt_sequence: 251,
    shape_dist_traveled: 11.8719,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.089643,
    shape_pt_lon: -90.06293,
    shape_pt_sequence: 252,
    shape_dist_traveled: 11.9839,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.090647,
    shape_pt_lon: -90.062901,
    shape_pt_sequence: 253,
    shape_dist_traveled: 12.0959,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.091647,
    shape_pt_lon: -90.062876,
    shape_pt_sequence: 254,
    shape_dist_traveled: 12.2079,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092147,
    shape_pt_lon: -90.062863,
    shape_pt_sequence: 255,
    shape_dist_traveled: 12.2639,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092614,
    shape_pt_lon: -90.06285,
    shape_pt_sequence: 256,
    shape_dist_traveled: 12.316,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094093,
    shape_pt_lon: -90.062809,
    shape_pt_sequence: 257,
    shape_dist_traveled: 12.482,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09456,
    shape_pt_lon: -90.062799,
    shape_pt_sequence: 258,
    shape_dist_traveled: 12.534,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094636,
    shape_pt_lon: -90.062798,
    shape_pt_sequence: 259,
    shape_dist_traveled: 12.543,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095195,
    shape_pt_lon: -90.062786,
    shape_pt_sequence: 260,
    shape_dist_traveled: 12.605,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095017,
    shape_pt_lon: -90.063148,
    shape_pt_sequence: 261,
    shape_dist_traveled: 12.6436,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094818,
    shape_pt_lon: -90.063469,
    shape_pt_sequence: 262,
    shape_dist_traveled: 12.68,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094558,
    shape_pt_lon: -90.063778,
    shape_pt_sequence: 263,
    shape_dist_traveled: 12.7203,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093271,
    shape_pt_lon: -90.064963,
    shape_pt_sequence: 264,
    shape_dist_traveled: 12.8997,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093149,
    shape_pt_lon: -90.065117,
    shape_pt_sequence: 265,
    shape_dist_traveled: 12.9195,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09304,
    shape_pt_lon: -90.0653,
    shape_pt_sequence: 266,
    shape_dist_traveled: 12.9395,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092977,
    shape_pt_lon: -90.065445,
    shape_pt_sequence: 267,
    shape_dist_traveled: 12.9543,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092434,
    shape_pt_lon: -90.067171,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.1218,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09255,
    shape_pt_lon: -90.06725,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.1366,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092734,
    shape_pt_lon: -90.067361,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.1603,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.092899,
    shape_pt_lon: -90.06743,
    shape_pt_sequence: 271,
    shape_dist_traveled: 13.1792,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093133,
    shape_pt_lon: -90.067498,
    shape_pt_sequence: 272,
    shape_dist_traveled: 13.2069,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093239,
    shape_pt_lon: -90.067504,
    shape_pt_sequence: 273,
    shape_dist_traveled: 13.2179,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09327,
    shape_pt_lon: -90.067506,
    shape_pt_sequence: 274,
    shape_dist_traveled: 13.222,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093334,
    shape_pt_lon: -90.06751,
    shape_pt_sequence: 275,
    shape_dist_traveled: 13.229,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.093778,
    shape_pt_lon: -90.067477,
    shape_pt_sequence: 276,
    shape_dist_traveled: 13.2791,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094046,
    shape_pt_lon: -90.067452,
    shape_pt_sequence: 277,
    shape_dist_traveled: 13.3092,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094261,
    shape_pt_lon: -90.067431,
    shape_pt_sequence: 278,
    shape_dist_traveled: 13.3333,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094693,
    shape_pt_lon: -90.06739,
    shape_pt_sequence: 279,
    shape_dist_traveled: 13.3814,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.094933,
    shape_pt_lon: -90.067346,
    shape_pt_sequence: 280,
    shape_dist_traveled: 13.4087,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095037,
    shape_pt_lon: -90.06731,
    shape_pt_sequence: 281,
    shape_dist_traveled: 13.4211,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095221,
    shape_pt_lon: -90.067248,
    shape_pt_sequence: 282,
    shape_dist_traveled: 13.442,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095489,
    shape_pt_lon: -90.067117,
    shape_pt_sequence: 283,
    shape_dist_traveled: 13.4743,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095626,
    shape_pt_lon: -90.067026,
    shape_pt_sequence: 284,
    shape_dist_traveled: 13.4913,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.095679,
    shape_pt_lon: -90.066982,
    shape_pt_sequence: 285,
    shape_dist_traveled: 13.4985,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.096178,
    shape_pt_lon: -90.066586,
    shape_pt_sequence: 286,
    shape_dist_traveled: 13.5651,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.096231,
    shape_pt_lon: -90.066542,
    shape_pt_sequence: 287,
    shape_dist_traveled: 13.5723,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.096924,
    shape_pt_lon: -90.066043,
    shape_pt_sequence: 288,
    shape_dist_traveled: 13.6623,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09758,
    shape_pt_lon: -90.065568,
    shape_pt_sequence: 289,
    shape_dist_traveled: 13.747,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.097659,
    shape_pt_lon: -90.06551,
    shape_pt_sequence: 290,
    shape_dist_traveled: 13.7573,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.097799,
    shape_pt_lon: -90.065438,
    shape_pt_sequence: 291,
    shape_dist_traveled: 13.7744,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.098001,
    shape_pt_lon: -90.065394,
    shape_pt_sequence: 292,
    shape_dist_traveled: 13.7968,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.098245,
    shape_pt_lon: -90.06537,
    shape_pt_sequence: 293,
    shape_dist_traveled: 13.8249,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.098901,
    shape_pt_lon: -90.065296,
    shape_pt_sequence: 294,
    shape_dist_traveled: 13.8982,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.09961,
    shape_pt_lon: -90.065218,
    shape_pt_sequence: 295,
    shape_dist_traveled: 13.9775,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.100304,
    shape_pt_lon: -90.065139,
    shape_pt_sequence: 296,
    shape_dist_traveled: 14.0558,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101008,
    shape_pt_lon: -90.065061,
    shape_pt_sequence: 297,
    shape_dist_traveled: 14.1351,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101709,
    shape_pt_lon: -90.064982,
    shape_pt_sequence: 298,
    shape_dist_traveled: 14.2135,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101737,
    shape_pt_lon: -90.065406,
    shape_pt_sequence: 299,
    shape_dist_traveled: 14.2527,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101743,
    shape_pt_lon: -90.065651,
    shape_pt_sequence: 300,
    shape_dist_traveled: 14.2747,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101769,
    shape_pt_lon: -90.066294,
    shape_pt_sequence: 301,
    shape_dist_traveled: 14.3328,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101799,
    shape_pt_lon: -90.067003,
    shape_pt_sequence: 302,
    shape_dist_traveled: 14.3968,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101808,
    shape_pt_lon: -90.067228,
    shape_pt_sequence: 303,
    shape_dist_traveled: 14.4178,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101821,
    shape_pt_lon: -90.067621,
    shape_pt_sequence: 304,
    shape_dist_traveled: 14.4529,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101873,
    shape_pt_lon: -90.068773,
    shape_pt_sequence: 305,
    shape_dist_traveled: 14.557,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101909,
    shape_pt_lon: -90.069597,
    shape_pt_sequence: 306,
    shape_dist_traveled: 14.6321,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101929,
    shape_pt_lon: -90.07007,
    shape_pt_sequence: 307,
    shape_dist_traveled: 14.6732,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.101973,
    shape_pt_lon: -90.070869,
    shape_pt_sequence: 308,
    shape_dist_traveled: 14.7464,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.102013,
    shape_pt_lon: -90.071889,
    shape_pt_sequence: 309,
    shape_dist_traveled: 14.8385,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.102047,
    shape_pt_lon: -90.073009,
    shape_pt_sequence: 310,
    shape_dist_traveled: 14.9396,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.102052,
    shape_pt_lon: -90.073213,
    shape_pt_sequence: 311,
    shape_dist_traveled: 14.9586,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.102279,
    shape_pt_lon: -90.073213,
    shape_pt_sequence: 312,
    shape_dist_traveled: 14.9836,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.102862,
    shape_pt_lon: -90.073189,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.0487,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.103642,
    shape_pt_lon: -90.073156,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.1357,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.104427,
    shape_pt_lon: -90.073127,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.2237,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.105216,
    shape_pt_lon: -90.07309,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.3128,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.10548,
    shape_pt_lon: -90.073077,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.3419,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.106065,
    shape_pt_lon: -90.073057,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.4079,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.106929,
    shape_pt_lon: -90.073029,
    shape_pt_sequence: 319,
    shape_dist_traveled: 15.5039,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.10796,
    shape_pt_lon: -90.07298,
    shape_pt_sequence: 320,
    shape_dist_traveled: 15.62,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107907,
    shape_pt_lon: -90.070391,
    shape_pt_sequence: 321,
    shape_dist_traveled: 15.8541,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.10789,
    shape_pt_lon: -90.06962,
    shape_pt_sequence: 322,
    shape_dist_traveled: 15.9221,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.10788,
    shape_pt_lon: -90.069145,
    shape_pt_sequence: 323,
    shape_dist_traveled: 15.9651,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107849,
    shape_pt_lon: -90.067507,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.1132,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107848,
    shape_pt_lon: -90.067416,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.1212,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107817,
    shape_pt_lon: -90.066073,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.2432,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107806,
    shape_pt_lon: -90.065658,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.2803,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.1078,
    shape_pt_lon: -90.065367,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.3073,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107788,
    shape_pt_lon: -90.064761,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.3613,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107777,
    shape_pt_lon: -90.064192,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.4133,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107761,
    shape_pt_lon: -90.063447,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.4804,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.107739,
    shape_pt_lon: -90.06239,
    shape_pt_sequence: 332,
    shape_dist_traveled: 16.5764,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.108695,
    shape_pt_lon: -90.062367,
    shape_pt_sequence: 333,
    shape_dist_traveled: 16.6834,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.110168,
    shape_pt_lon: -90.06234,
    shape_pt_sequence: 334,
    shape_dist_traveled: 16.8484,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.111308,
    shape_pt_lon: -90.062318,
    shape_pt_sequence: 335,
    shape_dist_traveled: 16.9754,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.11243,
    shape_pt_lon: -90.062296,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.1014,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.112548,
    shape_pt_lon: -90.062294,
    shape_pt_sequence: 337,
    shape_dist_traveled: 17.1144,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.113949,
    shape_pt_lon: -90.062279,
    shape_pt_sequence: 338,
    shape_dist_traveled: 17.2715,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.116207,
    shape_pt_lon: -90.062252,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.5235,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.116738,
    shape_pt_lon: -90.062237,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.5835,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.118634,
    shape_pt_lon: -90.062166,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.7956,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.119407,
    shape_pt_lon: -90.062132,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.8816,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.120753,
    shape_pt_lon: -90.062083,
    shape_pt_sequence: 343,
    shape_dist_traveled: 18.0327,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.122201,
    shape_pt_lon: -90.062028,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.1948,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.122827,
    shape_pt_lon: -90.062006,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.2648,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.12346,
    shape_pt_lon: -90.061986,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.3358,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.124066,
    shape_pt_lon: -90.061968,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.4038,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.12435,
    shape_pt_lon: -90.061959,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.4359,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.124583,
    shape_pt_lon: -90.061953,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.4619,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.124642,
    shape_pt_lon: -90.061952,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.4679,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.124706,
    shape_pt_lon: -90.061952,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.4749,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.125788,
    shape_pt_lon: -90.061906,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.5959,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.126912,
    shape_pt_lon: -90.061862,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.722,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.126989,
    shape_pt_lon: -90.06186,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.731,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.128252,
    shape_pt_lon: -90.061818,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.872,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129358,
    shape_pt_lon: -90.061781,
    shape_pt_sequence: 356,
    shape_dist_traveled: 18.9961,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129353,
    shape_pt_lon: -90.061208,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.0471,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129371,
    shape_pt_lon: -90.061113,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.0563,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129435,
    shape_pt_lon: -90.060999,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.0691,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129456,
    shape_pt_lon: -90.060971,
    shape_pt_sequence: 360,
    shape_dist_traveled: 19.0728,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129515,
    shape_pt_lon: -90.060923,
    shape_pt_sequence: 361,
    shape_dist_traveled: 19.0808,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129591,
    shape_pt_lon: -90.060899,
    shape_pt_sequence: 362,
    shape_dist_traveled: 19.0891,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.129763,
    shape_pt_lon: -90.06089,
    shape_pt_sequence: 363,
    shape_dist_traveled: 19.1081,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132833,
    shape_pt_lon: -90.060802,
    shape_pt_sequence: 364,
    shape_dist_traveled: 19.4522,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132831,
    shape_pt_lon: -90.059749,
    shape_pt_sequence: 365,
    shape_dist_traveled: 19.5472,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132823,
    shape_pt_lon: -90.059,
    shape_pt_sequence: 366,
    shape_dist_traveled: 19.6152,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132806,
    shape_pt_lon: -90.058301,
    shape_pt_sequence: 367,
    shape_dist_traveled: 19.6782,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132803,
    shape_pt_lon: -90.058196,
    shape_pt_sequence: 368,
    shape_dist_traveled: 19.6883,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132789,
    shape_pt_lon: -90.05745,
    shape_pt_sequence: 369,
    shape_dist_traveled: 19.7553,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 370,
    shape_dist_traveled: 19.8403,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13275,
    shape_pt_lon: -90.055818,
    shape_pt_sequence: 371,
    shape_dist_traveled: 19.9023,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132744,
    shape_pt_lon: -90.055183,
    shape_pt_sequence: 372,
    shape_dist_traveled: 19.9603,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 373,
    shape_dist_traveled: 20.0114,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.132872,
    shape_pt_lon: -90.054626,
    shape_pt_sequence: 374,
    shape_dist_traveled: 20.0264,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.133043,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 375,
    shape_dist_traveled: 20.0456,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.133207,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 376,
    shape_dist_traveled: 20.0655,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13334,
    shape_pt_lon: -90.054802,
    shape_pt_sequence: 377,
    shape_dist_traveled: 20.0821,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.133608,
    shape_pt_lon: -90.05498,
    shape_pt_sequence: 378,
    shape_dist_traveled: 20.1161,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.133698,
    shape_pt_lon: -90.055027,
    shape_pt_sequence: 379,
    shape_dist_traveled: 20.1269,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13387,
    shape_pt_lon: -90.055074,
    shape_pt_sequence: 380,
    shape_dist_traveled: 20.1463,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.134011,
    shape_pt_lon: -90.055085,
    shape_pt_sequence: 381,
    shape_dist_traveled: 20.1623,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.134228,
    shape_pt_lon: -90.055078,
    shape_pt_sequence: 382,
    shape_dist_traveled: 20.1863,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.134433,
    shape_pt_lon: -90.05506,
    shape_pt_sequence: 383,
    shape_dist_traveled: 20.2094,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.134673,
    shape_pt_lon: -90.054994,
    shape_pt_sequence: 384,
    shape_dist_traveled: 20.2371,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.134826,
    shape_pt_lon: -90.054925,
    shape_pt_sequence: 385,
    shape_dist_traveled: 20.2551,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.135772,
    shape_pt_lon: -90.054418,
    shape_pt_sequence: 386,
    shape_dist_traveled: 20.3706,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 387,
    shape_dist_traveled: 20.3854,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 388,
    shape_dist_traveled: 20.4959,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 389,
    shape_dist_traveled: 20.5544,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 390,
    shape_dist_traveled: 20.6016,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 391,
    shape_dist_traveled: 20.718,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 392,
    shape_dist_traveled: 20.7498,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 393,
    shape_dist_traveled: 20.8018,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 394,
    shape_dist_traveled: 20.8121,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 395,
    shape_dist_traveled: 20.832,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 396,
    shape_dist_traveled: 20.8526,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 397,
    shape_dist_traveled: 20.8679,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 398,
    shape_dist_traveled: 20.9446,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 399,
    shape_dist_traveled: 21.005,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 400,
    shape_dist_traveled: 21.0525,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 401,
    shape_dist_traveled: 21.0751,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 402,
    shape_dist_traveled: 21.1178,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 403,
    shape_dist_traveled: 21.1943,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 404,
    shape_dist_traveled: 21.2468,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 405,
    shape_dist_traveled: 21.3091,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 406,
    shape_dist_traveled: 21.3597,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 407,
    shape_dist_traveled: 21.429,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 408,
    shape_dist_traveled: 21.4887,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 409,
    shape_dist_traveled: 21.5466,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 410,
    shape_dist_traveled: 21.6032,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 411,
    shape_dist_traveled: 21.6633,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 412,
    shape_dist_traveled: 21.78,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 413,
    shape_dist_traveled: 21.8976,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 414,
    shape_dist_traveled: 21.9533,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 415,
    shape_dist_traveled: 22.0134,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 416,
    shape_dist_traveled: 22.131,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 417,
    shape_dist_traveled: 22.2474,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 418,
    shape_dist_traveled: 22.3606,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 419,
    shape_dist_traveled: 22.3705,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 420,
    shape_dist_traveled: 22.393,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 421,
    shape_dist_traveled: 22.4048,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 422,
    shape_dist_traveled: 22.4519,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 423,
    shape_dist_traveled: 22.4788,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 424,
    shape_dist_traveled: 22.4943,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 425,
    shape_dist_traveled: 22.5987,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 426,
    shape_dist_traveled: 22.7103,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 427,
    shape_dist_traveled: 22.7176,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 428,
    shape_dist_traveled: 22.723,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 429,
    shape_dist_traveled: 22.723,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 430,
    shape_dist_traveled: 22.7796,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 431,
    shape_dist_traveled: 22.7796,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 432,
    shape_dist_traveled: 22.8321,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 433,
    shape_dist_traveled: 22.8916,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 434,
    shape_dist_traveled: 22.9504,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 435,
    shape_dist_traveled: 22.9675,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 436,
    shape_dist_traveled: 23.0301,
  },
  {
    shape_id: 38192,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 437,
    shape_dist_traveled: 23.081,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2681,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3762,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4351,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4613,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4948,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.554,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6159,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6726,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7001,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7466,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8085,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8468,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9165,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0104,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1163,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1414,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2265,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3323,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.4443,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.135843,
    shape_pt_lon: -90.056147,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5321,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.135726,
    shape_pt_lon: -90.056195,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5461,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.134526,
    shape_pt_lon: -90.05639,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6821,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.134034,
    shape_pt_lon: -90.056461,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7376,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8786,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.13275,
    shape_pt_lon: -90.055818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9407,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.132744,
    shape_pt_lon: -90.055183,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9987,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0497,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.132721,
    shape_pt_lon: -90.054193,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0877,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.132699,
    shape_pt_lon: -90.053449,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1548,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.132667,
    shape_pt_lon: -90.051972,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2888,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.131493,
    shape_pt_lon: -90.052008,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.4199,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.131449,
    shape_pt_lon: -90.050414,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.5639,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.130255,
    shape_pt_lon: -90.050447,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.698,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12966,
    shape_pt_lon: -90.050462,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.764,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.129078,
    shape_pt_lon: -90.050489,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.829,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.129079,
    shape_pt_lon: -90.050519,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.832,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.128536,
    shape_pt_lon: -90.050533,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.893,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.128306,
    shape_pt_lon: -90.050581,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.9194,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12805,
    shape_pt_lon: -90.050671,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.9485,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.127848,
    shape_pt_lon: -90.050764,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.9732,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.127722,
    shape_pt_lon: -90.05081,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.9877,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.127473,
    shape_pt_lon: -90.050881,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.0164,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.127104,
    shape_pt_lon: -90.050963,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.0581,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.126842,
    shape_pt_lon: -90.051005,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.0874,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.126773,
    shape_pt_lon: -90.051011,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.0954,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.126598,
    shape_pt_lon: -90.051028,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.1155,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.125519,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.2355,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.124622,
    shape_pt_lon: -90.05108,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.3366,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12453,
    shape_pt_lon: -90.051057,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.3468,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.124477,
    shape_pt_lon: -90.051031,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.3531,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12435,
    shape_pt_lon: -90.05097,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3683,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.124269,
    shape_pt_lon: -90.050956,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3774,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12379,
    shape_pt_lon: -90.050969,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.4314,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.122689,
    shape_pt_lon: -90.051006,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.5544,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.122207,
    shape_pt_lon: -90.051027,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.6084,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.12146,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.6925,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.120936,
    shape_pt_lon: -90.051072,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.7505,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.119739,
    shape_pt_lon: -90.051109,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.8846,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.118797,
    shape_pt_lon: -90.051138,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.9906,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.117856,
    shape_pt_lon: -90.05117,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.0956,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.116898,
    shape_pt_lon: -90.051198,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.2027,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.116463,
    shape_pt_lon: -90.051214,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.2517,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115959,
    shape_pt_lon: -90.051233,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.3077,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115961,
    shape_pt_lon: -90.051235,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.3077,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115985,
    shape_pt_lon: -90.052406,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.4138,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115988,
    shape_pt_lon: -90.052402,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.4138,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115991,
    shape_pt_lon: -90.05258,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.4298,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115832,
    shape_pt_lon: -90.05258,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.4468,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115101,
    shape_pt_lon: -90.052539,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.5289,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.115039,
    shape_pt_lon: -90.052534,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.5359,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.114267,
    shape_pt_lon: -90.052488,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.623,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.114171,
    shape_pt_lon: -90.052481,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.633,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.114049,
    shape_pt_lon: -90.052474,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.6471,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.113788,
    shape_pt_lon: -90.05246,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.6761,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.113369,
    shape_pt_lon: -90.052434,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.7232,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.113306,
    shape_pt_lon: -90.052431,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.7302,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.112438,
    shape_pt_lon: -90.052377,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.8273,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.112336,
    shape_pt_lon: -90.052362,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.8393,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.111686,
    shape_pt_lon: -90.052284,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.9117,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.111399,
    shape_pt_lon: -90.052248,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.9438,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.111082,
    shape_pt_lon: -90.05221,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.98,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.110375,
    shape_pt_lon: -90.052127,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.0593,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.110267,
    shape_pt_lon: -90.052112,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.0715,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.1095,
    shape_pt_lon: -90.052027,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.1578,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.109399,
    shape_pt_lon: -90.052014,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.169,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.108559,
    shape_pt_lon: -90.051913,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.2634,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.108131,
    shape_pt_lon: -90.05186,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.3117,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.108082,
    shape_pt_lon: -90.051853,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.3177,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107739,
    shape_pt_lon: -90.051811,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.3559,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107626,
    shape_pt_lon: -90.051792,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.369,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107517,
    shape_pt_lon: -90.05177,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.3812,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107513,
    shape_pt_lon: -90.051598,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.3962,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107415,
    shape_pt_lon: -90.0516,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.4072,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10695,
    shape_pt_lon: -90.051618,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.4592,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10548,
    shape_pt_lon: -90.051672,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.6243,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10546,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.6803,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105447,
    shape_pt_lon: -90.050457,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.7333,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10543,
    shape_pt_lon: -90.049871,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.7864,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10542,
    shape_pt_lon: -90.049278,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.8404,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105406,
    shape_pt_lon: -90.048692,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.8934,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105396,
    shape_pt_lon: -90.048058,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.9504,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -90.047442,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.0064,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105365,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.0625,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105342,
    shape_pt_lon: -90.045987,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.1385,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105328,
    shape_pt_lon: -90.045317,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.1986,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105317,
    shape_pt_lon: -90.044665,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.2576,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105292,
    shape_pt_lon: -90.044,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.3177,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.106827,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.4898,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107212,
    shape_pt_lon: -90.04388,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.5331,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10727,
    shape_pt_lon: -90.043857,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.5403,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107348,
    shape_pt_lon: -90.0438,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.5498,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107339,
    shape_pt_lon: -90.043698,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.5588,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107282,
    shape_pt_lon: -90.043596,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.5705,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10728,
    shape_pt_lon: -90.04325,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.6015,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107267,
    shape_pt_lon: -90.042591,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.6605,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10726,
    shape_pt_lon: -90.042346,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.6835,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -90.041941,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.7195,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107239,
    shape_pt_lon: -90.041281,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.7796,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10723,
    shape_pt_lon: -90.040902,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.8136,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107224,
    shape_pt_lon: -90.040632,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.8386,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107209,
    shape_pt_lon: -90.039961,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.8986,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.1072,
    shape_pt_lon: -90.039568,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.9346,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107193,
    shape_pt_lon: -90.039241,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.9636,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107187,
    shape_pt_lon: -90.03889,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.9956,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10718,
    shape_pt_lon: -90.038542,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0267,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10717,
    shape_pt_lon: -90.038233,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.0547,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107138,
    shape_pt_lon: -90.0379,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.0859,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107126,
    shape_pt_lon: -90.037676,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.106,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107126,
    shape_pt_lon: -90.037432,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.128,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107182,
    shape_pt_lon: -90.037027,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.1645,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107193,
    shape_pt_lon: -90.036962,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.1705,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.107195,
    shape_pt_lon: -90.036773,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.1875,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.1069,
    shape_pt_lon: -90.036732,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.2208,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.106808,
    shape_pt_lon: -90.036741,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.2308,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.106664,
    shape_pt_lon: -90.036814,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.2479,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.106268,
    shape_pt_lon: -90.037167,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.3023,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.106108,
    shape_pt_lon: -90.037263,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.3224,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.1059,
    shape_pt_lon: -90.037341,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.3465,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105707,
    shape_pt_lon: -90.037369,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.3686,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105437,
    shape_pt_lon: -90.037362,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.3986,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105353,
    shape_pt_lon: -90.037349,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.4086,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10523,
    shape_pt_lon: -90.037319,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.422,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.105015,
    shape_pt_lon: -90.037209,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.448,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.104821,
    shape_pt_lon: -90.037069,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.4735,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.104577,
    shape_pt_lon: -90.036797,
    shape_pt_sequence: 173,
    shape_dist_traveled: 8.5096,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.103339,
    shape_pt_lon: -90.035293,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.7041,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.103297,
    shape_pt_lon: -90.035256,
    shape_pt_sequence: 175,
    shape_dist_traveled: 8.7105,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.103206,
    shape_pt_lon: -90.03519,
    shape_pt_sequence: 176,
    shape_dist_traveled: 8.7222,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.103092,
    shape_pt_lon: -90.035158,
    shape_pt_sequence: 177,
    shape_dist_traveled: 8.7355,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.102925,
    shape_pt_lon: -90.035166,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.7535,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.102709,
    shape_pt_lon: -90.035216,
    shape_pt_sequence: 179,
    shape_dist_traveled: 8.7781,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.101749,
    shape_pt_lon: -90.035439,
    shape_pt_sequence: 180,
    shape_dist_traveled: 8.8879,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100962,
    shape_pt_lon: -90.035629,
    shape_pt_sequence: 181,
    shape_dist_traveled: 8.9775,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.10085,
    shape_pt_lon: -90.035656,
    shape_pt_sequence: 182,
    shape_dist_traveled: 8.9897,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100775,
    shape_pt_lon: -90.035675,
    shape_pt_sequence: 183,
    shape_dist_traveled: 8.9989,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100773,
    shape_pt_lon: -90.035677,
    shape_pt_sequence: 184,
    shape_dist_traveled: 8.9989,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100643,
    shape_pt_lon: -90.035711,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.0142,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100642,
    shape_pt_lon: -90.035709,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.0142,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100546,
    shape_pt_lon: -90.035734,
    shape_pt_sequence: 187,
    shape_dist_traveled: 9.0244,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100303,
    shape_pt_lon: -90.03578,
    shape_pt_sequence: 188,
    shape_dist_traveled: 9.0529,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100197,
    shape_pt_lon: -90.03579,
    shape_pt_sequence: 189,
    shape_dist_traveled: 9.0649,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.100042,
    shape_pt_lon: -90.035787,
    shape_pt_sequence: 190,
    shape_dist_traveled: 9.0819,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.099903,
    shape_pt_lon: -90.035769,
    shape_pt_sequence: 191,
    shape_dist_traveled: 9.0969,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.09979,
    shape_pt_lon: -90.035743,
    shape_pt_sequence: 192,
    shape_dist_traveled: 9.1102,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.09965,
    shape_pt_lon: -90.03569,
    shape_pt_sequence: 193,
    shape_dist_traveled: 9.127,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.099357,
    shape_pt_lon: -90.035521,
    shape_pt_sequence: 194,
    shape_dist_traveled: 9.1623,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.099315,
    shape_pt_lon: -90.035489,
    shape_pt_sequence: 195,
    shape_dist_traveled: 9.1682,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.099127,
    shape_pt_lon: -90.035321,
    shape_pt_sequence: 196,
    shape_dist_traveled: 9.194,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.099016,
    shape_pt_lon: -90.035197,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.211,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.098569,
    shape_pt_lon: -90.034552,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.2876,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.098036,
    shape_pt_lon: -90.033782,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.3791,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.097677,
    shape_pt_lon: -90.033258,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.4416,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.097294,
    shape_pt_lon: -90.032694,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.5083,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.096787,
    shape_pt_lon: -90.031952,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.5963,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.096599,
    shape_pt_lon: -90.031683,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.6282,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.09597,
    shape_pt_lon: -90.030767,
    shape_pt_sequence: 204,
    shape_dist_traveled: 9.7367,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.09577,
    shape_pt_lon: -90.030493,
    shape_pt_sequence: 205,
    shape_dist_traveled: 9.7707,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.095765,
    shape_pt_lon: -90.030488,
    shape_pt_sequence: 206,
    shape_dist_traveled: 9.7707,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.095681,
    shape_pt_lon: -90.030409,
    shape_pt_sequence: 207,
    shape_dist_traveled: 9.7829,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.095179,
    shape_pt_lon: -90.030056,
    shape_pt_sequence: 208,
    shape_dist_traveled: 9.8474,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094943,
    shape_pt_lon: -90.029893,
    shape_pt_sequence: 209,
    shape_dist_traveled: 9.8774,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094205,
    shape_pt_lon: -90.029403,
    shape_pt_sequence: 210,
    shape_dist_traveled: 9.9714,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094096,
    shape_pt_lon: -90.029341,
    shape_pt_sequence: 211,
    shape_dist_traveled: 9.9848,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094078,
    shape_pt_lon: -90.029761,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.0228,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094085,
    shape_pt_lon: -90.0304,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.0809,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094091,
    shape_pt_lon: -90.030822,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.1189,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094094,
    shape_pt_lon: -90.031048,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.1399,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094099,
    shape_pt_lon: -90.031281,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.1609,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.09413,
    shape_pt_lon: -90.033264,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.3399,
  },
  {
    shape_id: 38193,
    shape_pt_lat: 35.094167,
    shape_pt_lon: -90.036079,
    shape_pt_sequence: 218,
    shape_dist_traveled: 10.594,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.094167,
    shape_pt_lon: -90.036079,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.094173,
    shape_pt_lon: -90.037011,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.085,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.094194,
    shape_pt_lon: -90.038107,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.184,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.094112,
    shape_pt_lon: -90.038076,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1935,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.09375,
    shape_pt_lon: -90.037972,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2355,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.092999,
    shape_pt_lon: -90.03772,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3226,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.091221,
    shape_pt_lon: -90.037091,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.5296,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.090638,
    shape_pt_lon: -90.03689,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.597,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.090409,
    shape_pt_lon: -90.036843,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.6235,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.090127,
    shape_pt_lon: -90.03682,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6546,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.0899,
    shape_pt_lon: -90.036823,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6806,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.089168,
    shape_pt_lon: -90.036834,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7626,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.08818,
    shape_pt_lon: -90.036865,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8726,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.087173,
    shape_pt_lon: -90.036889,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9857,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.086169,
    shape_pt_lon: -90.036919,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0977,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.08622,
    shape_pt_lon: -90.039966,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.3737,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.086261,
    shape_pt_lon: -90.042308,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.5848,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.08631,
    shape_pt_lon: -90.04451,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.7839,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.087321,
    shape_pt_lon: -90.044494,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.8979,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.088309,
    shape_pt_lon: -90.044478,
    shape_pt_sequence: 20,
    shape_dist_traveled: 2.0079,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.089311,
    shape_pt_lon: -90.044453,
    shape_pt_sequence: 21,
    shape_dist_traveled: 2.1199,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.090333,
    shape_pt_lon: -90.044433,
    shape_pt_sequence: 22,
    shape_dist_traveled: 2.2349,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.091242,
    shape_pt_lon: -90.044409,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.336,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.0926,
    shape_pt_lon: -90.044368,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.488,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.09299,
    shape_pt_lon: -90.044363,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.532,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.09312,
    shape_pt_lon: -90.04436,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.546,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.093228,
    shape_pt_lon: -90.044358,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.558,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.094188,
    shape_pt_lon: -90.044333,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.666,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.095103,
    shape_pt_lon: -90.044312,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.768,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.097928,
    shape_pt_lon: -90.04424,
    shape_pt_sequence: 30,
    shape_dist_traveled: 3.0841,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.100647,
    shape_pt_lon: -90.044175,
    shape_pt_sequence: 31,
    shape_dist_traveled: 3.3892,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.101279,
    shape_pt_lon: -90.044158,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.4592,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.103284,
    shape_pt_lon: -90.044078,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.6843,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105292,
    shape_pt_lon: -90.044,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.9084,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105317,
    shape_pt_lon: -90.044665,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.9685,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105328,
    shape_pt_lon: -90.045317,
    shape_pt_sequence: 36,
    shape_dist_traveled: 4.0275,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105342,
    shape_pt_lon: -90.045987,
    shape_pt_sequence: 37,
    shape_dist_traveled: 4.0875,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105365,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 38,
    shape_dist_traveled: 4.1635,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -90.047442,
    shape_pt_sequence: 39,
    shape_dist_traveled: 4.2196,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105396,
    shape_pt_lon: -90.048058,
    shape_pt_sequence: 40,
    shape_dist_traveled: 4.2756,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105406,
    shape_pt_lon: -90.048692,
    shape_pt_sequence: 41,
    shape_dist_traveled: 4.3326,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.10542,
    shape_pt_lon: -90.049278,
    shape_pt_sequence: 42,
    shape_dist_traveled: 4.3857,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.10543,
    shape_pt_lon: -90.049871,
    shape_pt_sequence: 43,
    shape_dist_traveled: 4.4397,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.105447,
    shape_pt_lon: -90.050457,
    shape_pt_sequence: 44,
    shape_dist_traveled: 4.4927,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.10546,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 45,
    shape_dist_traveled: 4.5457,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.10548,
    shape_pt_lon: -90.051672,
    shape_pt_sequence: 46,
    shape_dist_traveled: 4.6018,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.10695,
    shape_pt_lon: -90.051618,
    shape_pt_sequence: 47,
    shape_dist_traveled: 4.7668,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.107415,
    shape_pt_lon: -90.0516,
    shape_pt_sequence: 48,
    shape_dist_traveled: 4.8189,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.107513,
    shape_pt_lon: -90.051598,
    shape_pt_sequence: 49,
    shape_dist_traveled: 4.8299,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.107517,
    shape_pt_lon: -90.05177,
    shape_pt_sequence: 50,
    shape_dist_traveled: 4.8449,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.107626,
    shape_pt_lon: -90.051792,
    shape_pt_sequence: 51,
    shape_dist_traveled: 4.857,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.107739,
    shape_pt_lon: -90.051811,
    shape_pt_sequence: 52,
    shape_dist_traveled: 4.8702,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.108082,
    shape_pt_lon: -90.051853,
    shape_pt_sequence: 53,
    shape_dist_traveled: 4.9084,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.108131,
    shape_pt_lon: -90.05186,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.9144,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.108559,
    shape_pt_lon: -90.051913,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.9627,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.109399,
    shape_pt_lon: -90.052014,
    shape_pt_sequence: 56,
    shape_dist_traveled: 5.0571,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.1095,
    shape_pt_lon: -90.052027,
    shape_pt_sequence: 57,
    shape_dist_traveled: 5.0683,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.110267,
    shape_pt_lon: -90.052112,
    shape_pt_sequence: 58,
    shape_dist_traveled: 5.1545,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.110375,
    shape_pt_lon: -90.052127,
    shape_pt_sequence: 59,
    shape_dist_traveled: 5.1667,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.111082,
    shape_pt_lon: -90.05221,
    shape_pt_sequence: 60,
    shape_dist_traveled: 5.246,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.111399,
    shape_pt_lon: -90.052248,
    shape_pt_sequence: 61,
    shape_dist_traveled: 5.2822,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.111686,
    shape_pt_lon: -90.052284,
    shape_pt_sequence: 62,
    shape_dist_traveled: 5.3144,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.112336,
    shape_pt_lon: -90.052362,
    shape_pt_sequence: 63,
    shape_dist_traveled: 5.3867,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.112438,
    shape_pt_lon: -90.052377,
    shape_pt_sequence: 64,
    shape_dist_traveled: 5.3988,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.113306,
    shape_pt_lon: -90.052431,
    shape_pt_sequence: 65,
    shape_dist_traveled: 5.4959,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.113369,
    shape_pt_lon: -90.052434,
    shape_pt_sequence: 66,
    shape_dist_traveled: 5.5029,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.113788,
    shape_pt_lon: -90.05246,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.55,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.114049,
    shape_pt_lon: -90.052474,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.579,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.114171,
    shape_pt_lon: -90.052481,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.593,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.114267,
    shape_pt_lon: -90.052488,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.603,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115039,
    shape_pt_lon: -90.052534,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.6901,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115101,
    shape_pt_lon: -90.052539,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.6972,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115832,
    shape_pt_lon: -90.05258,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.7793,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115991,
    shape_pt_lon: -90.05258,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.7963,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115988,
    shape_pt_lon: -90.052402,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.8123,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115985,
    shape_pt_lon: -90.052406,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.8123,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115961,
    shape_pt_lon: -90.051235,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.9183,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.115959,
    shape_pt_lon: -90.051233,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.9183,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.116463,
    shape_pt_lon: -90.051214,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.9744,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.116898,
    shape_pt_lon: -90.051198,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.0234,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.117856,
    shape_pt_lon: -90.05117,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.1304,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.118797,
    shape_pt_lon: -90.051138,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.2355,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.119739,
    shape_pt_lon: -90.051109,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.3415,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.120936,
    shape_pt_lon: -90.051072,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.4756,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12146,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.5336,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.122207,
    shape_pt_lon: -90.051027,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.6176,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.122689,
    shape_pt_lon: -90.051006,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.6716,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12379,
    shape_pt_lon: -90.050969,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.7947,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.124269,
    shape_pt_lon: -90.050956,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.8487,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12435,
    shape_pt_lon: -90.05097,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.8578,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.124477,
    shape_pt_lon: -90.051031,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.873,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12453,
    shape_pt_lon: -90.051057,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.8793,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.124622,
    shape_pt_lon: -90.05108,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.8895,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.125519,
    shape_pt_lon: -90.051053,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.9905,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.126598,
    shape_pt_lon: -90.051028,
    shape_pt_sequence: 95,
    shape_dist_traveled: 7.1105,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.126773,
    shape_pt_lon: -90.051011,
    shape_pt_sequence: 96,
    shape_dist_traveled: 7.1306,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.126842,
    shape_pt_lon: -90.051005,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.1386,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.127104,
    shape_pt_lon: -90.050963,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1679,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.127473,
    shape_pt_lon: -90.050881,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.2097,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.127722,
    shape_pt_lon: -90.05081,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.2383,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.127848,
    shape_pt_lon: -90.050764,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.2529,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12805,
    shape_pt_lon: -90.050671,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.2776,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.128306,
    shape_pt_lon: -90.050581,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.3067,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.128536,
    shape_pt_lon: -90.050533,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.333,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.129079,
    shape_pt_lon: -90.050519,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.394,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.129078,
    shape_pt_lon: -90.050489,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.397,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.12966,
    shape_pt_lon: -90.050462,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.4621,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.130255,
    shape_pt_lon: -90.050447,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.5281,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.131449,
    shape_pt_lon: -90.050414,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.6621,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.131493,
    shape_pt_lon: -90.052008,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.8062,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.132667,
    shape_pt_lon: -90.051972,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.9372,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.132699,
    shape_pt_lon: -90.053449,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.0713,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.132721,
    shape_pt_lon: -90.054193,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.1383,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.1764,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.132872,
    shape_pt_lon: -90.054626,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.1914,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.133043,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.2106,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.133207,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.2306,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.13334,
    shape_pt_lon: -90.054802,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.2471,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.133608,
    shape_pt_lon: -90.05498,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.2811,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.133698,
    shape_pt_lon: -90.055027,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.2919,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.13387,
    shape_pt_lon: -90.055074,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.3113,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.134011,
    shape_pt_lon: -90.055085,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.3273,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.134228,
    shape_pt_lon: -90.055078,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.3513,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.134433,
    shape_pt_lon: -90.05506,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3744,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.134673,
    shape_pt_lon: -90.054994,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.4021,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.134826,
    shape_pt_lon: -90.054925,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4201,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.135772,
    shape_pt_lon: -90.054418,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.5357,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.5504,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.6609,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.7194,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.7666,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.883,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.9148,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.9668,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.9771,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.997,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.0176,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.0329,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.1096,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.17,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.2175,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.2401,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.2828,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.3593,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.4118,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.4741,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.5247,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.594,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.6538,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.7116,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.7683,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.8283,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.945,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.0627,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.1183,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.1784,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.2961,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.4124,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.5257,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.5355,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.5581,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.5698,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.6169,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.6438,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.6594,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.7637,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.8753,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.8826,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.888,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.888,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.9446,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.9446,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.9971,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.0566,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.1154,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.1325,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.1951,
  },
  {
    shape_id: 38194,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.2461,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0058,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0188,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0329,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0399,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0494,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1005,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1036,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.11,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.116,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.144,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.157,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.1655,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.1713,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.1803,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.2554,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3135,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.3287,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.3357,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.5404,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.6807,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.751,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.751,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.8592,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9374,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0265,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0515,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1496,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069147,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1556,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069096,
    shape_pt_lon: -89.930447,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3027,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069059,
    shape_pt_lon: -89.929456,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.3918,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069058,
    shape_pt_lon: -89.929252,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4108,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069026,
    shape_pt_lon: -89.928346,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.4929,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069024,
    shape_pt_lon: -89.92823,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5029,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068983,
    shape_pt_lon: -89.926756,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.637,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069,
    shape_pt_lon: -89.925336,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.765,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069023,
    shape_pt_lon: -89.923439,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.937,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069049,
    shape_pt_lon: -89.92316,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9622,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069084,
    shape_pt_lon: -89.922981,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9787,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069169,
    shape_pt_lon: -89.922689,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0062,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069241,
    shape_pt_lon: -89.922499,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0264,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069383,
    shape_pt_lon: -89.922194,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0572,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069462,
    shape_pt_lon: -89.921974,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0792,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069525,
    shape_pt_lon: -89.921729,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1023,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069561,
    shape_pt_lon: -89.921469,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1266,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069574,
    shape_pt_lon: -89.921222,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1487,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068709,
    shape_pt_lon: -89.921261,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2457,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.067742,
    shape_pt_lon: -89.921305,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3538,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.067493,
    shape_pt_lon: -89.921316,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3818,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.066656,
    shape_pt_lon: -89.921353,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4759,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.066089,
    shape_pt_lon: -89.921381,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5389,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.065611,
    shape_pt_lon: -89.921399,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.5919,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064689,
    shape_pt_lon: -89.921436,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.696,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064661,
    shape_pt_lon: -89.920426,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7881,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064628,
    shape_pt_lon: -89.920144,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.8133,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064563,
    shape_pt_lon: -89.919886,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.8386,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064479,
    shape_pt_lon: -89.919656,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.8605,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064372,
    shape_pt_lon: -89.919439,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8838,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064219,
    shape_pt_lon: -89.919212,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.9108,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064067,
    shape_pt_lon: -89.919034,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9342,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063871,
    shape_pt_lon: -89.918867,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.9608,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063734,
    shape_pt_lon: -89.918773,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.9778,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063482,
    shape_pt_lon: -89.918656,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.0079,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063305,
    shape_pt_lon: -89.918569,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.0294,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063141,
    shape_pt_lon: -89.918448,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0505,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063053,
    shape_pt_lon: -89.918363,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.0633,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06292,
    shape_pt_lon: -89.918191,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.0845,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -89.918039,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.1017,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062739,
    shape_pt_lon: -89.917806,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.125,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06271,
    shape_pt_lon: -89.917709,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.1348,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062673,
    shape_pt_lon: -89.9175,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.1543,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062656,
    shape_pt_lon: -89.917072,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.1933,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062651,
    shape_pt_lon: -89.916926,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.2063,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062642,
    shape_pt_lon: -89.916443,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.2493,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062631,
    shape_pt_lon: -89.916061,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.2843,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062626,
    shape_pt_lon: -89.915836,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.3044,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062596,
    shape_pt_lon: -89.914653,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.4114,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062568,
    shape_pt_lon: -89.913262,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.5374,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062516,
    shape_pt_lon: -89.911147,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.7285,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0625,
    shape_pt_lon: -89.910537,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.7845,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062469,
    shape_pt_lon: -89.909368,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.8896,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062406,
    shape_pt_lon: -89.906701,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.1317,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062402,
    shape_pt_lon: -89.906538,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.1457,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062388,
    shape_pt_lon: -89.90583,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.2097,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062382,
    shape_pt_lon: -89.905761,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.2167,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062379,
    shape_pt_lon: -89.905684,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.2238,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.905688,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.2378,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062848,
    shape_pt_lon: -89.905666,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.2769,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063029,
    shape_pt_lon: -89.905661,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.2969,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905592,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.531,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06567,
    shape_pt_lon: -89.905574,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.592,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.065953,
    shape_pt_lon: -89.905576,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.624,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069051,
    shape_pt_lon: -89.905485,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.9711,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069318,
    shape_pt_lon: -89.905471,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.0011,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069309,
    shape_pt_lon: -89.905121,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.0331,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06929,
    shape_pt_lon: -89.904304,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.1071,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069242,
    shape_pt_lon: -89.902121,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.3042,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069198,
    shape_pt_lon: -89.899982,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.4982,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069195,
    shape_pt_lon: -89.899885,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.5072,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06918,
    shape_pt_lon: -89.899347,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.5553,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069153,
    shape_pt_lon: -89.89783,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.6933,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069142,
    shape_pt_lon: -89.897361,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.7353,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069138,
    shape_pt_lon: -89.897066,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.7623,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06913,
    shape_pt_lon: -89.896686,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.7964,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069123,
    shape_pt_lon: -89.896298,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.8314,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069109,
    shape_pt_lon: -89.895639,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.8914,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069081,
    shape_pt_lon: -89.894621,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.9834,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069063,
    shape_pt_lon: -89.893571,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.0784,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069042,
    shape_pt_lon: -89.892539,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.1715,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069024,
    shape_pt_lon: -89.89163,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.2535,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.890636,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.3435,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068957,
    shape_pt_lon: -89.888711,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.5176,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068953,
    shape_pt_lon: -89.888524,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.5346,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068939,
    shape_pt_lon: -89.887771,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.6026,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068922,
    shape_pt_lon: -89.887112,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.6626,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06891,
    shape_pt_lon: -89.886557,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.7127,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.068881,
    shape_pt_lon: -89.885518,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.8067,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.067433,
    shape_pt_lon: -89.885572,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.9688,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.066949,
    shape_pt_lon: -89.885591,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.0228,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.065815,
    shape_pt_lon: -89.885651,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.1499,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.064422,
    shape_pt_lon: -89.88571,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.306,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063942,
    shape_pt_lon: -89.885729,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.36,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.063397,
    shape_pt_lon: -89.88575,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.421,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.062806,
    shape_pt_lon: -89.885779,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.4871,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.061909,
    shape_pt_lon: -89.885821,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.5871,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.061248,
    shape_pt_lon: -89.885849,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.6612,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.060751,
    shape_pt_lon: -89.885869,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.7172,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.059637,
    shape_pt_lon: -89.885917,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.8413,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.059542,
    shape_pt_lon: -89.885921,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.8523,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.059089,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.9033,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.057688,
    shape_pt_lon: -89.885989,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.0594,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.057352,
    shape_pt_lon: -89.885996,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.0974,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.055717,
    shape_pt_lon: -89.886049,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.2804,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.054227,
    shape_pt_lon: -89.886095,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.4475,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0539,
    shape_pt_lon: -89.886106,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.4835,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05375,
    shape_pt_lon: -89.88611,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.5005,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053399,
    shape_pt_lon: -89.886123,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.5395,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052811,
    shape_pt_lon: -89.886142,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.6055,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052281,
    shape_pt_lon: -89.886158,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.6645,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050999,
    shape_pt_lon: -89.886198,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.8086,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050642,
    shape_pt_lon: -89.886208,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.8486,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0506,
    shape_pt_lon: -89.886209,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.8526,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.8597,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05047,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.8677,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050386,
    shape_pt_lon: -89.886211,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.8767,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050124,
    shape_pt_lon: -89.886216,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.9067,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.049287,
    shape_pt_lon: -89.886227,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.9997,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048967,
    shape_pt_lon: -89.886225,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.0357,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048495,
    shape_pt_lon: -89.886168,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.089,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.047598,
    shape_pt_lon: -89.886023,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.1898,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04708,
    shape_pt_lon: -89.885972,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.2481,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0466,
    shape_pt_lon: -89.885953,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.3021,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.046041,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.3651,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.045863,
    shape_pt_lon: -89.885947,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.3841,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.045395,
    shape_pt_lon: -89.885937,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.4371,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.045289,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.4491,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.044486,
    shape_pt_lon: -89.885937,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.5391,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.044367,
    shape_pt_lon: -89.885944,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.5521,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04392,
    shape_pt_lon: -89.885932,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.6022,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043854,
    shape_pt_lon: -89.885936,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.6092,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043726,
    shape_pt_lon: -89.885932,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.6242,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043628,
    shape_pt_lon: -89.885933,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.6352,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042354,
    shape_pt_lon: -89.885913,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.7772,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042308,
    shape_pt_lon: -89.885915,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.7822,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041921,
    shape_pt_lon: -89.885875,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.8254,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04146,
    shape_pt_lon: -89.885804,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.8777,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041372,
    shape_pt_lon: -89.885779,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.8879,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041149,
    shape_pt_lon: -89.885726,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.9134,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041034,
    shape_pt_lon: -89.885689,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.927,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.040687,
    shape_pt_lon: -89.885577,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.9673,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039938,
    shape_pt_lon: -89.885279,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.0545,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039906,
    shape_pt_lon: -89.885263,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.0587,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039652,
    shape_pt_lon: -89.885178,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.0878,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03958,
    shape_pt_lon: -89.885149,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.0963,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039069,
    shape_pt_lon: -89.884961,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.1558,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038533,
    shape_pt_lon: -89.884808,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.2174,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038383,
    shape_pt_lon: -89.884787,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.2345,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038357,
    shape_pt_lon: -89.88478,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.2375,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038155,
    shape_pt_lon: -89.884753,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.2607,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03808,
    shape_pt_lon: -89.884739,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.2688,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038056,
    shape_pt_lon: -89.884734,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.272,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03755,
    shape_pt_lon: -89.884663,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.3283,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035167,
    shape_pt_lon: -89.884659,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.5953,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035097,
    shape_pt_lon: -89.884653,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.6033,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035096,
    shape_pt_lon: -89.884739,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.6113,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035103,
    shape_pt_lon: -89.886102,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.7343,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035125,
    shape_pt_lon: -89.88676,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.7944,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035171,
    shape_pt_lon: -89.888382,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.9415,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035207,
    shape_pt_lon: -89.889135,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.0096,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035208,
    shape_pt_lon: -89.889193,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.0146,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035209,
    shape_pt_lon: -89.889266,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.0216,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035238,
    shape_pt_lon: -89.892331,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.2986,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035249,
    shape_pt_lon: -89.893883,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.4386,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035277,
    shape_pt_lon: -89.897262,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.7446,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035291,
    shape_pt_lon: -89.899415,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.9396,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035306,
    shape_pt_lon: -89.901039,
    shape_pt_sequence: 196,
    shape_dist_traveled: 12.0866,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035358,
    shape_pt_lon: -89.901251,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.1065,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03543,
    shape_pt_lon: -89.901373,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.1201,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03549,
    shape_pt_lon: -89.901438,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.1286,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035599,
    shape_pt_lon: -89.901498,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.1429,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.035753,
    shape_pt_lon: -89.901547,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.1604,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03928,
    shape_pt_lon: -89.901586,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.5544,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.040194,
    shape_pt_lon: -89.901591,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.6574,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04147,
    shape_pt_lon: -89.901603,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.7994,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041628,
    shape_pt_lon: -89.901607,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.8175,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041709,
    shape_pt_lon: -89.901663,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.8278,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042655,
    shape_pt_lon: -89.901678,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.9338,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.045798,
    shape_pt_lon: -89.901704,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.2858,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.046246,
    shape_pt_lon: -89.901708,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.3358,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.047989,
    shape_pt_lon: -89.901722,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.5308,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048229,
    shape_pt_lon: -89.901726,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.5578,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048697,
    shape_pt_lon: -89.90173,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.6098,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.049284,
    shape_pt_lon: -89.901733,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.6758,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.049745,
    shape_pt_lon: -89.901735,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.7268,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050132,
    shape_pt_lon: -89.90174,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.7708,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.8658,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.9619,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.9759,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.018,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.093,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.156,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.386,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051122,
    shape_pt_lon: -89.907742,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.408,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051123,
    shape_pt_lon: -89.907829,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.416,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05054,
    shape_pt_lon: -89.90785,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.4811,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.907897,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.6571,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048596,
    shape_pt_lon: -89.907925,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.6982,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048203,
    shape_pt_lon: -89.907951,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.7423,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.046372,
    shape_pt_lon: -89.907934,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.9473,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.044654,
    shape_pt_lon: -89.907916,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.1393,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.044341,
    shape_pt_lon: -89.907926,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.1743,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04404,
    shape_pt_lon: -89.907969,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.2085,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043756,
    shape_pt_lon: -89.908049,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.2413,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043572,
    shape_pt_lon: -89.908131,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.2625,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043338,
    shape_pt_lon: -89.908263,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.292,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043116,
    shape_pt_lon: -89.908419,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.3198,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043009,
    shape_pt_lon: -89.908507,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.3342,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042789,
    shape_pt_lon: -89.908721,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.3656,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0426,
    shape_pt_lon: -89.908952,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.3953,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04247,
    shape_pt_lon: -89.909138,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.418,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0423,
    shape_pt_lon: -89.909443,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.4519,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04217,
    shape_pt_lon: -89.909753,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.4836,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042097,
    shape_pt_lon: -89.90999,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.5061,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042026,
    shape_pt_lon: -89.910274,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.5333,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041913,
    shape_pt_lon: -89.910816,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.5837,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041899,
    shape_pt_lon: -89.910887,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.5901,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041888,
    shape_pt_lon: -89.910948,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.5961,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041788,
    shape_pt_lon: -89.911409,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.6389,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04169,
    shape_pt_lon: -89.91176,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.6727,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04158,
    shape_pt_lon: -89.912093,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.705,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04138,
    shape_pt_lon: -89.912598,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.756,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041147,
    shape_pt_lon: -89.913066,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.8059,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.040997,
    shape_pt_lon: -89.913328,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.8348,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04049,
    shape_pt_lon: -89.914121,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.9266,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.040066,
    shape_pt_lon: -89.914768,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.0019,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039739,
    shape_pt_lon: -89.915267,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.0595,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03965,
    shape_pt_lon: -89.915401,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.0751,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039429,
    shape_pt_lon: -89.915739,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.115,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.039296,
    shape_pt_lon: -89.915945,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.1384,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038533,
    shape_pt_lon: -89.917108,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.2741,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.038411,
    shape_pt_lon: -89.917293,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.2955,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037997,
    shape_pt_lon: -89.917928,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.3694,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0378,
    shape_pt_lon: -89.91825,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.4066,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037638,
    shape_pt_lon: -89.918561,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.4399,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03748,
    shape_pt_lon: -89.918913,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.4766,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037401,
    shape_pt_lon: -89.919145,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.4985,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03734,
    shape_pt_lon: -89.919397,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.5223,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037277,
    shape_pt_lon: -89.919728,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.5531,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037255,
    shape_pt_lon: -89.919861,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.5655,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037222,
    shape_pt_lon: -89.920173,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.5938,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037202,
    shape_pt_lon: -89.920637,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.6358,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0372,
    shape_pt_lon: -89.920721,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.6438,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037195,
    shape_pt_lon: -89.920936,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.6628,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037193,
    shape_pt_lon: -89.920981,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.6678,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037161,
    shape_pt_lon: -89.922172,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.7749,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037144,
    shape_pt_lon: -89.923028,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.8529,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037115,
    shape_pt_lon: -89.92432,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.97,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03708,
    shape_pt_lon: -89.92581,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.104,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037078,
    shape_pt_lon: -89.925959,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.1181,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03704,
    shape_pt_lon: -89.927629,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.2691,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037025,
    shape_pt_lon: -89.928156,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.3172,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036949,
    shape_pt_lon: -89.931208,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.5933,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036925,
    shape_pt_lon: -89.932389,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.7004,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036881,
    shape_pt_lon: -89.934089,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.8534,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.93571,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.0005,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936152,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.0405,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936543,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.0755,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036834,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.1325,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036837,
    shape_pt_lon: -89.937492,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.1615,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036851,
    shape_pt_lon: -89.938659,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.2675,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036871,
    shape_pt_lon: -89.940493,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.4335,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036903,
    shape_pt_lon: -89.941311,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.5076,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036907,
    shape_pt_lon: -89.941728,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.5456,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.036928,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.7116,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.037132,
    shape_pt_lon: -89.943576,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.7347,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.03833,
    shape_pt_lon: -89.943557,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.8687,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.040042,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 297,
    shape_dist_traveled: 19.0607,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04074,
    shape_pt_lon: -89.943536,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.1387,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041359,
    shape_pt_lon: -89.943552,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.2077,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.041907,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.2687,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.042767,
    shape_pt_lon: -89.943528,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.3657,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043047,
    shape_pt_lon: -89.943522,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.3967,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043193,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.4127,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043243,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.4187,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043258,
    shape_pt_lon: -89.945294,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.5787,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043271,
    shape_pt_lon: -89.946473,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.6857,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043267,
    shape_pt_lon: -89.946868,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.7217,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043254,
    shape_pt_lon: -89.947092,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.7418,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043209,
    shape_pt_lon: -89.947391,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.7692,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04311,
    shape_pt_lon: -89.947878,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.8146,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043101,
    shape_pt_lon: -89.947932,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.8197,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043071,
    shape_pt_lon: -89.948242,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.848,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04309,
    shape_pt_lon: -89.951115,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.108,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043097,
    shape_pt_lon: -89.952144,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.201,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043107,
    shape_pt_lon: -89.953143,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.291,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.043122,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.44,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04409,
    shape_pt_lon: -89.954799,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.548,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.045832,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.743,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.0468,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.852,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.046935,
    shape_pt_lon: -89.954781,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.867,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.047214,
    shape_pt_lon: -89.95474,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.8983,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.047463,
    shape_pt_lon: -89.954679,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.9267,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.047992,
    shape_pt_lon: -89.954482,
    shape_pt_sequence: 323,
    shape_dist_traveled: 20.9884,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048362,
    shape_pt_lon: -89.954293,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.0328,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048717,
    shape_pt_lon: -89.954047,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.0784,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.048848,
    shape_pt_lon: -89.953951,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.0959,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.049027,
    shape_pt_lon: -89.953803,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.1198,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.04983,
    shape_pt_lon: -89.953094,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.2302,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050488,
    shape_pt_lon: -89.9525,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.321,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.050726,
    shape_pt_lon: -89.952308,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.3529,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05076,
    shape_pt_lon: -89.952282,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.3579,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051054,
    shape_pt_lon: -89.952096,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.3946,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051436,
    shape_pt_lon: -89.9519,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.4412,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051544,
    shape_pt_lon: -89.951852,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.4539,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.051896,
    shape_pt_lon: -89.951734,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.4944,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.95168,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.5278,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.5339,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.7319,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 339,
    shape_dist_traveled: 21.818,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 340,
    shape_dist_traveled: 21.875,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 341,
    shape_dist_traveled: 22.0861,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 342,
    shape_dist_traveled: 22.2432,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.2512,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052518,
    shape_pt_lon: -89.959711,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.2643,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052621,
    shape_pt_lon: -89.962222,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.4915,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05264,
    shape_pt_lon: -89.963049,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.5666,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052662,
    shape_pt_lon: -89.963789,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.6336,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052668,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.6506,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05268,
    shape_pt_lon: -89.964479,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.6957,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052708,
    shape_pt_lon: -89.965441,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.7827,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052739,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.8928,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052794,
    shape_pt_lon: -89.968479,
    shape_pt_sequence: 352,
    shape_dist_traveled: 23.0579,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052857,
    shape_pt_lon: -89.970677,
    shape_pt_sequence: 353,
    shape_dist_traveled: 23.257,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052892,
    shape_pt_lon: -89.972186,
    shape_pt_sequence: 354,
    shape_dist_traveled: 23.393,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052877,
    shape_pt_lon: -89.972562,
    shape_pt_sequence: 355,
    shape_dist_traveled: 23.4271,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052859,
    shape_pt_lon: -89.9729,
    shape_pt_sequence: 356,
    shape_dist_traveled: 23.4581,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052723,
    shape_pt_lon: -89.974436,
    shape_pt_sequence: 357,
    shape_dist_traveled: 23.598,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052707,
    shape_pt_lon: -89.97468,
    shape_pt_sequence: 358,
    shape_dist_traveled: 23.6201,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052683,
    shape_pt_lon: -89.975008,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.6492,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052665,
    shape_pt_lon: -89.975273,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.6733,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052606,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 361,
    shape_dist_traveled: 23.7287,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052601,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 362,
    shape_dist_traveled: 23.7287,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052553,
    shape_pt_lon: -89.976424,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.7781,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 364,
    shape_dist_traveled: 23.7781,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052521,
    shape_pt_lon: -89.976757,
    shape_pt_sequence: 365,
    shape_dist_traveled: 23.8093,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052478,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.8675,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052439,
    shape_pt_lon: -89.977803,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.9038,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05242,
    shape_pt_lon: -89.978039,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.9259,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052352,
    shape_pt_lon: -89.978785,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.9934,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052346,
    shape_pt_lon: -89.978868,
    shape_pt_sequence: 370,
    shape_dist_traveled: 24.0014,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052296,
    shape_pt_lon: -89.979454,
    shape_pt_sequence: 371,
    shape_dist_traveled: 24.0547,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052188,
    shape_pt_lon: -89.980672,
    shape_pt_sequence: 372,
    shape_dist_traveled: 24.1654,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05218,
    shape_pt_lon: -89.98076,
    shape_pt_sequence: 373,
    shape_dist_traveled: 24.1734,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052121,
    shape_pt_lon: -89.981575,
    shape_pt_sequence: 374,
    shape_dist_traveled: 24.2468,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 375,
    shape_dist_traveled: 24.2798,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052093,
    shape_pt_lon: -89.981988,
    shape_pt_sequence: 376,
    shape_dist_traveled: 24.2849,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 377,
    shape_dist_traveled: 24.2929,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.982481,
    shape_pt_sequence: 378,
    shape_dist_traveled: 24.3289,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052098,
    shape_pt_lon: -89.982754,
    shape_pt_sequence: 379,
    shape_dist_traveled: 24.354,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052151,
    shape_pt_lon: -89.98318,
    shape_pt_sequence: 380,
    shape_dist_traveled: 24.3935,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052229,
    shape_pt_lon: -89.983566,
    shape_pt_sequence: 381,
    shape_dist_traveled: 24.4294,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052309,
    shape_pt_lon: -89.983848,
    shape_pt_sequence: 382,
    shape_dist_traveled: 24.456,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05263,
    shape_pt_lon: -89.9848,
    shape_pt_sequence: 383,
    shape_dist_traveled: 24.5492,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052666,
    shape_pt_lon: -89.984901,
    shape_pt_sequence: 384,
    shape_dist_traveled: 24.559,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.052852,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 385,
    shape_dist_traveled: 24.6087,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053109,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 386,
    shape_dist_traveled: 24.6674,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053158,
    shape_pt_lon: -89.986057,
    shape_pt_sequence: 387,
    shape_dist_traveled: 24.6777,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053313,
    shape_pt_lon: -89.98643,
    shape_pt_sequence: 388,
    shape_dist_traveled: 24.7157,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053406,
    shape_pt_lon: -89.986702,
    shape_pt_sequence: 389,
    shape_dist_traveled: 24.7421,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053468,
    shape_pt_lon: -89.986996,
    shape_pt_sequence: 390,
    shape_dist_traveled: 24.77,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05349,
    shape_pt_lon: -89.987205,
    shape_pt_sequence: 391,
    shape_dist_traveled: 24.7891,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053503,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 392,
    shape_dist_traveled: 24.8092,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053497,
    shape_pt_lon: -89.98765,
    shape_pt_sequence: 393,
    shape_dist_traveled: 24.8292,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05345,
    shape_pt_lon: -89.987999,
    shape_pt_sequence: 394,
    shape_dist_traveled: 24.8616,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053319,
    shape_pt_lon: -89.988731,
    shape_pt_sequence: 395,
    shape_dist_traveled: 24.9293,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.989051,
    shape_pt_sequence: 396,
    shape_dist_traveled: 24.9584,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053299,
    shape_pt_lon: -89.989227,
    shape_pt_sequence: 397,
    shape_dist_traveled: 24.9745,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053303,
    shape_pt_lon: -89.989473,
    shape_pt_sequence: 398,
    shape_dist_traveled: 24.9965,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053284,
    shape_pt_lon: -89.989631,
    shape_pt_sequence: 399,
    shape_dist_traveled: 25.0106,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 400,
    shape_dist_traveled: 25.0182,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 401,
    shape_dist_traveled: 25.0223,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 402,
    shape_dist_traveled: 25.0323,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 403,
    shape_dist_traveled: 25.0363,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 404,
    shape_dist_traveled: 25.0433,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 405,
    shape_dist_traveled: 25.1054,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 406,
    shape_dist_traveled: 25.1454,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 407,
    shape_dist_traveled: 25.1624,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 408,
    shape_dist_traveled: 25.1774,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05355,
    shape_pt_lon: -89.99123,
    shape_pt_sequence: 409,
    shape_dist_traveled: 25.1913,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.054161,
    shape_pt_lon: -89.991249,
    shape_pt_sequence: 410,
    shape_dist_traveled: 25.2604,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.054831,
    shape_pt_lon: -89.991301,
    shape_pt_sequence: 411,
    shape_dist_traveled: 25.3355,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.055837,
    shape_pt_lon: -89.991361,
    shape_pt_sequence: 412,
    shape_dist_traveled: 25.4476,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.05677,
    shape_pt_lon: -89.991416,
    shape_pt_sequence: 413,
    shape_dist_traveled: 25.5528,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.057634,
    shape_pt_lon: -89.991457,
    shape_pt_sequence: 414,
    shape_dist_traveled: 25.6488,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.058032,
    shape_pt_lon: -89.991479,
    shape_pt_sequence: 415,
    shape_dist_traveled: 25.6939,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.058719,
    shape_pt_lon: -89.991487,
    shape_pt_sequence: 416,
    shape_dist_traveled: 25.7709,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.059566,
    shape_pt_lon: -89.991467,
    shape_pt_sequence: 417,
    shape_dist_traveled: 25.8659,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.060574,
    shape_pt_lon: -89.99144,
    shape_pt_sequence: 418,
    shape_dist_traveled: 25.9779,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.060577,
    shape_pt_lon: -89.991531,
    shape_pt_sequence: 419,
    shape_dist_traveled: 25.986,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 420,
    shape_dist_traveled: 25.994,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 421,
    shape_dist_traveled: 26.08,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 422,
    shape_dist_traveled: 26.103,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 423,
    shape_dist_traveled: 26.1271,
  },
  {
    shape_id: 38195,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 424,
    shape_dist_traveled: 26.2617,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1171,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1391,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1902,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.060602,
    shape_pt_lon: -89.991619,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2462,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2492,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.060524,
    shape_pt_lon: -89.991622,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2552,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.059692,
    shape_pt_lon: -89.991643,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3482,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.058269,
    shape_pt_lon: -89.991672,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.5083,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.057629,
    shape_pt_lon: -89.99164,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5793,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.056766,
    shape_pt_lon: -89.991596,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6764,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.055766,
    shape_pt_lon: -89.991538,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7885,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.054833,
    shape_pt_lon: -89.991486,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8926,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053527,
    shape_pt_lon: -89.991368,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.0391,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0502,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0653,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.0823,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.1223,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.1843,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.1913,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.1953,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.2053,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.2095,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053219,
    shape_pt_lon: -89.989648,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.2159,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053181,
    shape_pt_lon: -89.989544,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.227,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053183,
    shape_pt_lon: -89.989342,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2451,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05318,
    shape_pt_lon: -89.989049,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2711,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053186,
    shape_pt_lon: -89.988826,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2921,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053228,
    shape_pt_lon: -89.988528,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053316,
    shape_pt_lon: -89.988099,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3589,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05335,
    shape_pt_lon: -89.987783,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.388,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053383,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4203,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053356,
    shape_pt_lon: -89.987097,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.4504,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05334,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4616,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.986777,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.4803,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053216,
    shape_pt_lon: -89.986536,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.5041,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053179,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.5139,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052983,
    shape_pt_lon: -89.986032,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.5561,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052821,
    shape_pt_lon: -89.985703,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.5911,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052692,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.6224,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.984897,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.6716,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052141,
    shape_pt_lon: -89.983801,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.7792,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052073,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.8051,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051989,
    shape_pt_lon: -89.9831,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8454,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051956,
    shape_pt_lon: -89.982767,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.8755,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051951,
    shape_pt_lon: -89.982618,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.8886,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051948,
    shape_pt_lon: -89.982474,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.9016,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05194,
    shape_pt_lon: -89.982211,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.9256,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051956,
    shape_pt_lon: -89.982043,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.9407,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051963,
    shape_pt_lon: -89.981939,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.9508,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051966,
    shape_pt_lon: -89.981917,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.9528,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052007,
    shape_pt_lon: -89.981483,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.9921,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052061,
    shape_pt_lon: -89.980869,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.0474,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.980628,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.0695,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052143,
    shape_pt_lon: -89.979973,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.1299,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.979097,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.2093,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052244,
    shape_pt_lon: -89.978727,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2425,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0523,
    shape_pt_lon: -89.978025,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3068,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052319,
    shape_pt_lon: -89.977789,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.3279,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052351,
    shape_pt_lon: -89.977427,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.3611,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052415,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.4524,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.975876,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.5017,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052529,
    shape_pt_lon: -89.974982,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.5831,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.97467,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.6112,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052572,
    shape_pt_lon: -89.974416,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.6342,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052701,
    shape_pt_lon: -89.972892,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.7731,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052722,
    shape_pt_lon: -89.972547,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.8041,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052734,
    shape_pt_lon: -89.971971,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.8562,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05266,
    shape_pt_lon: -89.969172,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.1093,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052654,
    shape_pt_lon: -89.968527,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.1683,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052637,
    shape_pt_lon: -89.967793,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.2343,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052608,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.3374,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052573,
    shape_pt_lon: -89.965423,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.4494,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052555,
    shape_pt_lon: -89.964531,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.5295,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052536,
    shape_pt_lon: -89.963986,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.5795,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05253,
    shape_pt_lon: -89.963778,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.5975,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.963048,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.6635,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05245,
    shape_pt_lon: -89.961156,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.8357,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052413,
    shape_pt_lon: -89.960378,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.9058,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052393,
    shape_pt_lon: -89.959713,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.9658,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052401,
    shape_pt_lon: -89.959699,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.9672,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.9789,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.9869,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.144,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.3551,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.4122,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.4982,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.6963,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.95168,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.7024,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051896,
    shape_pt_lon: -89.951734,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.7357,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051544,
    shape_pt_lon: -89.951852,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.7763,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051436,
    shape_pt_lon: -89.9519,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.7889,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051054,
    shape_pt_lon: -89.952096,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.8355,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05076,
    shape_pt_lon: -89.952282,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.8722,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050726,
    shape_pt_lon: -89.952308,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.8772,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050488,
    shape_pt_lon: -89.9525,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.9091,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04983,
    shape_pt_lon: -89.953094,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.9999,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.049027,
    shape_pt_lon: -89.953803,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.1103,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048848,
    shape_pt_lon: -89.953951,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.1342,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048717,
    shape_pt_lon: -89.954047,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.1517,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048362,
    shape_pt_lon: -89.954293,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.1973,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047992,
    shape_pt_lon: -89.954482,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.2417,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047463,
    shape_pt_lon: -89.954679,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3034,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047214,
    shape_pt_lon: -89.95474,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.3318,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.046935,
    shape_pt_lon: -89.954781,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.3631,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0468,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.3781,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.045832,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.4871,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04409,
    shape_pt_lon: -89.954799,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.6821,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043122,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.7902,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043107,
    shape_pt_lon: -89.953143,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.9392,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043097,
    shape_pt_lon: -89.952144,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.0292,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04309,
    shape_pt_lon: -89.951115,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.1222,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043071,
    shape_pt_lon: -89.948242,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.3822,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043101,
    shape_pt_lon: -89.947932,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.4105,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04311,
    shape_pt_lon: -89.947878,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.4156,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043209,
    shape_pt_lon: -89.947391,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.4609,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043254,
    shape_pt_lon: -89.947092,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.4884,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043267,
    shape_pt_lon: -89.946868,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.5084,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043271,
    shape_pt_lon: -89.946473,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.5444,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043258,
    shape_pt_lon: -89.945294,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.6514,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043243,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.8114,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043193,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.8174,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043047,
    shape_pt_lon: -89.943522,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.8334,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042767,
    shape_pt_lon: -89.943528,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.8644,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041907,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.9614,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041359,
    shape_pt_lon: -89.943552,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.0224,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04074,
    shape_pt_lon: -89.943536,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.0915,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040042,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.1695,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03833,
    shape_pt_lon: -89.943557,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.3615,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037132,
    shape_pt_lon: -89.943576,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.4955,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036928,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.5185,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03688,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.5235,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036793,
    shape_pt_lon: -89.943572,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.5335,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036776,
    shape_pt_lon: -89.941736,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.6995,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036791,
    shape_pt_lon: -89.940391,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.8205,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036779,
    shape_pt_lon: -89.938659,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.9775,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036766,
    shape_pt_lon: -89.937478,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.0845,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036767,
    shape_pt_lon: -89.93717,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.1125,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036769,
    shape_pt_lon: -89.936578,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.1655,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036762,
    shape_pt_lon: -89.93654,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.1695,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036734,
    shape_pt_lon: -89.936312,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.1897,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036725,
    shape_pt_lon: -89.936144,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.2058,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036716,
    shape_pt_lon: -89.936033,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.2158,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03672,
    shape_pt_lon: -89.935567,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.2578,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036753,
    shape_pt_lon: -89.934087,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.3919,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036793,
    shape_pt_lon: -89.93238,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.546,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036818,
    shape_pt_lon: -89.931206,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.652,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036874,
    shape_pt_lon: -89.928657,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.8831,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036891,
    shape_pt_lon: -89.927626,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.9761,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036929,
    shape_pt_lon: -89.925947,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.1282,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036931,
    shape_pt_lon: -89.92581,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.1412,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036948,
    shape_pt_lon: -89.925109,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.2042,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036965,
    shape_pt_lon: -89.924332,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.2742,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.036996,
    shape_pt_lon: -89.923029,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.3923,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037013,
    shape_pt_lon: -89.92217,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.4703,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037032,
    shape_pt_lon: -89.921057,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.5713,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037034,
    shape_pt_lon: -89.920931,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.5823,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037037,
    shape_pt_lon: -89.920756,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.5984,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037051,
    shape_pt_lon: -89.920211,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.6474,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037093,
    shape_pt_lon: -89.919791,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.6857,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037208,
    shape_pt_lon: -89.919257,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.7354,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03734,
    shape_pt_lon: -89.918809,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.7791,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037413,
    shape_pt_lon: -89.918617,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.7979,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037525,
    shape_pt_lon: -89.918359,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.8247,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037677,
    shape_pt_lon: -89.918079,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.8555,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037814,
    shape_pt_lon: -89.917859,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.8805,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.037948,
    shape_pt_lon: -89.917652,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.904,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038274,
    shape_pt_lon: -89.917149,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.9624,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038391,
    shape_pt_lon: -89.916969,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.9836,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038766,
    shape_pt_lon: -89.916398,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.0505,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038947,
    shape_pt_lon: -89.916121,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.0825,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039094,
    shape_pt_lon: -89.915898,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.1081,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039388,
    shape_pt_lon: -89.915451,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.16,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039637,
    shape_pt_lon: -89.915072,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.2048,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03994,
    shape_pt_lon: -89.91461,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.258,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040123,
    shape_pt_lon: -89.91433,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.2915,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040478,
    shape_pt_lon: -89.913777,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.3555,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04071,
    shape_pt_lon: -89.913366,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.4007,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040802,
    shape_pt_lon: -89.913169,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.4213,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040874,
    shape_pt_lon: -89.913022,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.4366,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040932,
    shape_pt_lon: -89.912878,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.4509,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041113,
    shape_pt_lon: -89.912404,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.4987,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041183,
    shape_pt_lon: -89.91218,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.5203,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04154,
    shape_pt_lon: -89.910941,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.6392,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041565,
    shape_pt_lon: -89.910858,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.6468,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041585,
    shape_pt_lon: -89.910798,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.6532,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04161,
    shape_pt_lon: -89.910723,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.6608,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041639,
    shape_pt_lon: -89.91064,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.6684,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041819,
    shape_pt_lon: -89.910171,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.7158,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042111,
    shape_pt_lon: -89.909468,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.7869,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0423,
    shape_pt_lon: -89.909098,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.8269,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042463,
    shape_pt_lon: -89.908848,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.8553,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042583,
    shape_pt_lon: -89.908694,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.8751,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042777,
    shape_pt_lon: -89.90848,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.9042,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042902,
    shape_pt_lon: -89.908361,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.922,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043017,
    shape_pt_lon: -89.908275,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.9364,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043108,
    shape_pt_lon: -89.908202,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.9489,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043313,
    shape_pt_lon: -89.908068,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.9754,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043429,
    shape_pt_lon: -89.908006,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.9893,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04364,
    shape_pt_lon: -89.90791,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.014,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04392,
    shape_pt_lon: -89.907812,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.0472,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.044006,
    shape_pt_lon: -89.907791,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.0565,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.044246,
    shape_pt_lon: -89.907754,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.0836,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.044469,
    shape_pt_lon: -89.907738,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.1086,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.046366,
    shape_pt_lon: -89.907754,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.3206,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047046,
    shape_pt_lon: -89.907762,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.3966,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048206,
    shape_pt_lon: -89.90778,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.5267,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048555,
    shape_pt_lon: -89.907797,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.5657,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.049268,
    shape_pt_lon: -89.907711,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.6461,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.854,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.084,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.147,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.2221,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.2641,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.2781,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.3742,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050132,
    shape_pt_lon: -89.90174,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.4692,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.049745,
    shape_pt_lon: -89.901735,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.5132,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.049284,
    shape_pt_lon: -89.901733,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.5642,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048697,
    shape_pt_lon: -89.90173,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.6302,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048229,
    shape_pt_lon: -89.901726,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.6822,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047989,
    shape_pt_lon: -89.901722,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.7092,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.046246,
    shape_pt_lon: -89.901708,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.9042,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.045798,
    shape_pt_lon: -89.901704,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.9543,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042655,
    shape_pt_lon: -89.901678,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.3063,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041709,
    shape_pt_lon: -89.901663,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.4123,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041622,
    shape_pt_lon: -89.901726,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.4234,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041472,
    shape_pt_lon: -89.901724,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.4394,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041313,
    shape_pt_lon: -89.901724,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.4574,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040194,
    shape_pt_lon: -89.901721,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.5824,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039278,
    shape_pt_lon: -89.901712,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.6855,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038791,
    shape_pt_lon: -89.901707,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.7395,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035866,
    shape_pt_lon: -89.901678,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.0675,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035402,
    shape_pt_lon: -89.901654,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.1195,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035334,
    shape_pt_lon: -89.9016,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.1281,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03526,
    shape_pt_lon: -89.901601,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.1361,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03525,
    shape_pt_lon: -89.901339,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.1601,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035198,
    shape_pt_lon: -89.901263,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.1694,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035168,
    shape_pt_lon: -89.901212,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.175,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035165,
    shape_pt_lon: -89.900936,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.2,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035154,
    shape_pt_lon: -89.899416,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.338,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035138,
    shape_pt_lon: -89.89727,
    shape_pt_sequence: 242,
    shape_dist_traveled: 14.532,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035112,
    shape_pt_lon: -89.893883,
    shape_pt_sequence: 243,
    shape_dist_traveled: 14.839,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035105,
    shape_pt_lon: -89.892579,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.957,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035102,
    shape_pt_lon: -89.892333,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.9791,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035092,
    shape_pt_lon: -89.891111,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.0891,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035071,
    shape_pt_lon: -89.889192,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.2631,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035049,
    shape_pt_lon: -89.888383,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.3361,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035003,
    shape_pt_lon: -89.887382,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.4273,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.034988,
    shape_pt_lon: -89.886756,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.4833,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.034995,
    shape_pt_lon: -89.886101,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.5433,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03498,
    shape_pt_lon: -89.884739,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.6663,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.034976,
    shape_pt_lon: -89.884651,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.6743,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035027,
    shape_pt_lon: -89.884652,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.6793,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035067,
    shape_pt_lon: -89.884652,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.6843,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035097,
    shape_pt_lon: -89.884653,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.6873,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.035167,
    shape_pt_lon: -89.884659,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.6954,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03755,
    shape_pt_lon: -89.884663,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.9624,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038056,
    shape_pt_lon: -89.884734,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.0187,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03808,
    shape_pt_lon: -89.884739,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.0218,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038155,
    shape_pt_lon: -89.884753,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.0299,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038357,
    shape_pt_lon: -89.88478,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.0531,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038383,
    shape_pt_lon: -89.884787,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.0561,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.038533,
    shape_pt_lon: -89.884808,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.0732,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039069,
    shape_pt_lon: -89.884961,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.1348,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.03958,
    shape_pt_lon: -89.885149,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.1943,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039652,
    shape_pt_lon: -89.885178,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.2029,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039906,
    shape_pt_lon: -89.885263,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.232,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.039938,
    shape_pt_lon: -89.885279,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.2361,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.040687,
    shape_pt_lon: -89.885577,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.3234,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041034,
    shape_pt_lon: -89.885689,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.3636,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041149,
    shape_pt_lon: -89.885726,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.3772,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041372,
    shape_pt_lon: -89.885779,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.4027,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04146,
    shape_pt_lon: -89.885804,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.4129,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.041921,
    shape_pt_lon: -89.885875,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.4653,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042308,
    shape_pt_lon: -89.885915,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.5085,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.042354,
    shape_pt_lon: -89.885913,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.5135,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043628,
    shape_pt_lon: -89.885933,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.6555,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043726,
    shape_pt_lon: -89.885932,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.6665,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.043854,
    shape_pt_lon: -89.885936,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.6815,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04392,
    shape_pt_lon: -89.885932,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.6885,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.044367,
    shape_pt_lon: -89.885944,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.7385,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.044486,
    shape_pt_lon: -89.885937,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.7515,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.045289,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.8415,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.045395,
    shape_pt_lon: -89.885937,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.8535,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.045863,
    shape_pt_lon: -89.885947,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.9065,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.046041,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.9255,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0466,
    shape_pt_lon: -89.885953,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.9885,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.04708,
    shape_pt_lon: -89.885972,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.0426,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.047598,
    shape_pt_lon: -89.886023,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.1008,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048495,
    shape_pt_lon: -89.886168,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.2016,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.048967,
    shape_pt_lon: -89.886225,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.255,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.049287,
    shape_pt_lon: -89.886227,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.291,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050124,
    shape_pt_lon: -89.886216,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.384,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050386,
    shape_pt_lon: -89.886211,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.414,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05047,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.423,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.431,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0506,
    shape_pt_lon: -89.886209,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.438,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050642,
    shape_pt_lon: -89.886208,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.442,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.050999,
    shape_pt_lon: -89.886198,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.482,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052281,
    shape_pt_lon: -89.886158,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.6261,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.052811,
    shape_pt_lon: -89.886142,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.6851,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.053399,
    shape_pt_lon: -89.886123,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.7511,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.05375,
    shape_pt_lon: -89.88611,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.7902,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0539,
    shape_pt_lon: -89.886106,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.8072,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.054227,
    shape_pt_lon: -89.886095,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.8432,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.055717,
    shape_pt_lon: -89.886049,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.0102,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.057352,
    shape_pt_lon: -89.885996,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.1933,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.057688,
    shape_pt_lon: -89.885989,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.2313,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.059089,
    shape_pt_lon: -89.88594,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.3873,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.059542,
    shape_pt_lon: -89.885921,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.4384,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.059637,
    shape_pt_lon: -89.885917,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.4494,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.060751,
    shape_pt_lon: -89.885869,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.5734,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061248,
    shape_pt_lon: -89.885849,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.6295,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.061909,
    shape_pt_lon: -89.885821,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.7035,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062806,
    shape_pt_lon: -89.885779,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.8036,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063397,
    shape_pt_lon: -89.88575,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.8696,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063942,
    shape_pt_lon: -89.885729,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.9306,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064422,
    shape_pt_lon: -89.88571,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.9847,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.065815,
    shape_pt_lon: -89.885651,
    shape_pt_sequence: 320,
    shape_dist_traveled: 19.1408,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.066949,
    shape_pt_lon: -89.885591,
    shape_pt_sequence: 321,
    shape_dist_traveled: 19.2678,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.067433,
    shape_pt_lon: -89.885572,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.3219,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068881,
    shape_pt_lon: -89.885518,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.4839,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06891,
    shape_pt_lon: -89.886557,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.578,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068922,
    shape_pt_lon: -89.887112,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.628,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068939,
    shape_pt_lon: -89.887771,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.688,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068953,
    shape_pt_lon: -89.888524,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.7561,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068957,
    shape_pt_lon: -89.888711,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.7731,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.890636,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.9471,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069024,
    shape_pt_lon: -89.89163,
    shape_pt_sequence: 330,
    shape_dist_traveled: 20.0372,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069042,
    shape_pt_lon: -89.892539,
    shape_pt_sequence: 331,
    shape_dist_traveled: 20.1192,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069063,
    shape_pt_lon: -89.893571,
    shape_pt_sequence: 332,
    shape_dist_traveled: 20.2122,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069081,
    shape_pt_lon: -89.894621,
    shape_pt_sequence: 333,
    shape_dist_traveled: 20.3072,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069109,
    shape_pt_lon: -89.895639,
    shape_pt_sequence: 334,
    shape_dist_traveled: 20.3993,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069123,
    shape_pt_lon: -89.896298,
    shape_pt_sequence: 335,
    shape_dist_traveled: 20.4593,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06913,
    shape_pt_lon: -89.896686,
    shape_pt_sequence: 336,
    shape_dist_traveled: 20.4943,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069138,
    shape_pt_lon: -89.897066,
    shape_pt_sequence: 337,
    shape_dist_traveled: 20.5283,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069142,
    shape_pt_lon: -89.897361,
    shape_pt_sequence: 338,
    shape_dist_traveled: 20.5553,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069153,
    shape_pt_lon: -89.89783,
    shape_pt_sequence: 339,
    shape_dist_traveled: 20.5973,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06918,
    shape_pt_lon: -89.899347,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.7353,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069195,
    shape_pt_lon: -89.899885,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.7834,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069198,
    shape_pt_lon: -89.899982,
    shape_pt_sequence: 342,
    shape_dist_traveled: 20.7924,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069242,
    shape_pt_lon: -89.902121,
    shape_pt_sequence: 343,
    shape_dist_traveled: 20.9865,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06929,
    shape_pt_lon: -89.904304,
    shape_pt_sequence: 344,
    shape_dist_traveled: 21.1835,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069309,
    shape_pt_lon: -89.905121,
    shape_pt_sequence: 345,
    shape_dist_traveled: 21.2575,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069318,
    shape_pt_lon: -89.905471,
    shape_pt_sequence: 346,
    shape_dist_traveled: 21.2896,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06932,
    shape_pt_lon: -89.905638,
    shape_pt_sequence: 347,
    shape_dist_traveled: 21.3046,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.066051,
    shape_pt_lon: -89.905741,
    shape_pt_sequence: 348,
    shape_dist_traveled: 21.6706,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.065674,
    shape_pt_lon: -89.905739,
    shape_pt_sequence: 349,
    shape_dist_traveled: 21.7126,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905759,
    shape_pt_sequence: 350,
    shape_dist_traveled: 21.7747,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063261,
    shape_pt_lon: -89.905815,
    shape_pt_sequence: 351,
    shape_dist_traveled: 21.9827,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063039,
    shape_pt_lon: -89.905821,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.0077,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062819,
    shape_pt_lon: -89.905827,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.0327,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062599,
    shape_pt_lon: -89.90596,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.0596,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062539,
    shape_pt_lon: -89.906029,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.0695,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062518,
    shape_pt_lon: -89.90607,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.0731,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.906142,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.0804,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062406,
    shape_pt_lon: -89.906701,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.1316,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062469,
    shape_pt_lon: -89.909368,
    shape_pt_sequence: 359,
    shape_dist_traveled: 22.3737,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.0625,
    shape_pt_lon: -89.910537,
    shape_pt_sequence: 360,
    shape_dist_traveled: 22.4787,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062516,
    shape_pt_lon: -89.911147,
    shape_pt_sequence: 361,
    shape_dist_traveled: 22.5347,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062568,
    shape_pt_lon: -89.913262,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.7258,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062596,
    shape_pt_lon: -89.914653,
    shape_pt_sequence: 363,
    shape_dist_traveled: 22.8518,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062626,
    shape_pt_lon: -89.915836,
    shape_pt_sequence: 364,
    shape_dist_traveled: 22.9589,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062631,
    shape_pt_lon: -89.916061,
    shape_pt_sequence: 365,
    shape_dist_traveled: 22.9789,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062642,
    shape_pt_lon: -89.916443,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.0139,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062651,
    shape_pt_lon: -89.916926,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.0569,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062656,
    shape_pt_lon: -89.917072,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.07,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062673,
    shape_pt_lon: -89.9175,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.109,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06271,
    shape_pt_lon: -89.917709,
    shape_pt_sequence: 370,
    shape_dist_traveled: 23.1284,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062739,
    shape_pt_lon: -89.917806,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.1382,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -89.918039,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.1615,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.06292,
    shape_pt_lon: -89.918191,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.1787,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063053,
    shape_pt_lon: -89.918363,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.1999,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063141,
    shape_pt_lon: -89.918448,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.2127,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063305,
    shape_pt_lon: -89.918569,
    shape_pt_sequence: 376,
    shape_dist_traveled: 23.2338,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063482,
    shape_pt_lon: -89.918656,
    shape_pt_sequence: 377,
    shape_dist_traveled: 23.2554,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063734,
    shape_pt_lon: -89.918773,
    shape_pt_sequence: 378,
    shape_dist_traveled: 23.2854,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.063871,
    shape_pt_lon: -89.918867,
    shape_pt_sequence: 379,
    shape_dist_traveled: 23.3024,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064067,
    shape_pt_lon: -89.919034,
    shape_pt_sequence: 380,
    shape_dist_traveled: 23.3291,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064219,
    shape_pt_lon: -89.919212,
    shape_pt_sequence: 381,
    shape_dist_traveled: 23.3524,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064372,
    shape_pt_lon: -89.919439,
    shape_pt_sequence: 382,
    shape_dist_traveled: 23.3794,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064479,
    shape_pt_lon: -89.919656,
    shape_pt_sequence: 383,
    shape_dist_traveled: 23.4028,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064563,
    shape_pt_lon: -89.919886,
    shape_pt_sequence: 384,
    shape_dist_traveled: 23.4247,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064628,
    shape_pt_lon: -89.920144,
    shape_pt_sequence: 385,
    shape_dist_traveled: 23.45,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064661,
    shape_pt_lon: -89.920426,
    shape_pt_sequence: 386,
    shape_dist_traveled: 23.4752,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.064689,
    shape_pt_lon: -89.921436,
    shape_pt_sequence: 387,
    shape_dist_traveled: 23.5672,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.065611,
    shape_pt_lon: -89.921399,
    shape_pt_sequence: 388,
    shape_dist_traveled: 23.6713,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.066089,
    shape_pt_lon: -89.921381,
    shape_pt_sequence: 389,
    shape_dist_traveled: 23.7243,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.066656,
    shape_pt_lon: -89.921353,
    shape_pt_sequence: 390,
    shape_dist_traveled: 23.7874,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.067493,
    shape_pt_lon: -89.921316,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.8814,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.067742,
    shape_pt_lon: -89.921305,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.9094,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068709,
    shape_pt_lon: -89.921261,
    shape_pt_sequence: 393,
    shape_dist_traveled: 24.0175,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069574,
    shape_pt_lon: -89.921222,
    shape_pt_sequence: 394,
    shape_dist_traveled: 24.1146,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069561,
    shape_pt_lon: -89.921469,
    shape_pt_sequence: 395,
    shape_dist_traveled: 24.1367,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069525,
    shape_pt_lon: -89.921729,
    shape_pt_sequence: 396,
    shape_dist_traveled: 24.161,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069462,
    shape_pt_lon: -89.921974,
    shape_pt_sequence: 397,
    shape_dist_traveled: 24.1841,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069383,
    shape_pt_lon: -89.922194,
    shape_pt_sequence: 398,
    shape_dist_traveled: 24.206,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069241,
    shape_pt_lon: -89.922499,
    shape_pt_sequence: 399,
    shape_dist_traveled: 24.2369,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069169,
    shape_pt_lon: -89.922689,
    shape_pt_sequence: 400,
    shape_dist_traveled: 24.257,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069084,
    shape_pt_lon: -89.922981,
    shape_pt_sequence: 401,
    shape_dist_traveled: 24.2845,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069049,
    shape_pt_lon: -89.92316,
    shape_pt_sequence: 402,
    shape_dist_traveled: 24.301,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069023,
    shape_pt_lon: -89.923439,
    shape_pt_sequence: 403,
    shape_dist_traveled: 24.3262,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069,
    shape_pt_lon: -89.925336,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.4982,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.068983,
    shape_pt_lon: -89.926756,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.6262,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069024,
    shape_pt_lon: -89.92823,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.7603,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069026,
    shape_pt_lon: -89.928346,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.7704,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069058,
    shape_pt_lon: -89.929252,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.8524,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069059,
    shape_pt_lon: -89.929456,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.8714,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069096,
    shape_pt_lon: -89.930447,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.9605,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069147,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 411,
    shape_dist_traveled: 25.1076,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 412,
    shape_dist_traveled: 25.1136,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 413,
    shape_dist_traveled: 25.2117,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 414,
    shape_dist_traveled: 25.2368,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 415,
    shape_dist_traveled: 25.3258,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 416,
    shape_dist_traveled: 25.404,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 417,
    shape_dist_traveled: 25.5123,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 418,
    shape_dist_traveled: 25.5123,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 419,
    shape_dist_traveled: 25.5825,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 420,
    shape_dist_traveled: 25.7229,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 421,
    shape_dist_traveled: 25.9275,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 422,
    shape_dist_traveled: 25.9346,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 423,
    shape_dist_traveled: 25.9497,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 424,
    shape_dist_traveled: 26.0078,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 425,
    shape_dist_traveled: 26.0829,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 426,
    shape_dist_traveled: 26.0919,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 427,
    shape_dist_traveled: 26.0977,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 428,
    shape_dist_traveled: 26.1063,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 429,
    shape_dist_traveled: 26.1193,
  },
  {
    shape_id: 38196,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 430,
    shape_dist_traveled: 26.1803,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3669,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4267,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4461,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4911,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5561,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6132,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.7102,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8432,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.8642,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.8933,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.928,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.9701,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9818,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9862,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0246,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0499,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.096,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1422,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1862,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2546,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3414,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3805,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3855,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4425,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4495,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4985,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5017,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5167,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5327,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.5948,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6148,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6939,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7229,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.825,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.838,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9451,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.9651,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0372,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.0902,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1542,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1803,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2162,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3024,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157517,
    shape_pt_lon: -90.023161,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3064,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158187,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.3817,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15925,
    shape_pt_lon: -90.022942,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.5014,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160939,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.692,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161008,
    shape_pt_lon: -90.022653,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7001,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161289,
    shape_pt_lon: -90.022607,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.7314,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161642,
    shape_pt_lon: -90.022548,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7717,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161669,
    shape_pt_lon: -90.022542,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7748,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162102,
    shape_pt_lon: -90.022471,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8232,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162448,
    shape_pt_lon: -90.022414,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.8625,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163354,
    shape_pt_lon: -90.022263,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9645,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163811,
    shape_pt_lon: -90.022188,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.016,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.021859,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.2438,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165911,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.2528,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165779,
    shape_pt_lon: -90.020646,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.3637,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16566,
    shape_pt_lon: -90.019526,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.4657,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16559,
    shape_pt_lon: -90.01886,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5262,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165574,
    shape_pt_lon: -90.018738,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.5372,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165565,
    shape_pt_lon: -90.018664,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.5443,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165503,
    shape_pt_lon: -90.018218,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.5849,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165469,
    shape_pt_lon: -90.01796,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.6083,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165438,
    shape_pt_lon: -90.017736,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.6295,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165359,
    shape_pt_lon: -90.017013,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.6951,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165308,
    shape_pt_lon: -90.016482,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.7435,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16523,
    shape_pt_lon: -90.015676,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.817,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165151,
    shape_pt_lon: -90.014877,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.8895,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165072,
    shape_pt_lon: -90.014077,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.962,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164997,
    shape_pt_lon: -90.013295,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.0336,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164993,
    shape_pt_lon: -90.013267,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0356,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164918,
    shape_pt_lon: -90.012502,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.1051,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16491,
    shape_pt_lon: -90.012433,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.1121,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16484,
    shape_pt_lon: -90.011693,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.1786,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164836,
    shape_pt_lon: -90.011643,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.1837,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164765,
    shape_pt_lon: -90.010895,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.2521,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164727,
    shape_pt_lon: -90.010507,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.2874,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.3005,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164698,
    shape_pt_lon: -90.010199,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.3146,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164601,
    shape_pt_lon: -90.009349,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.3924,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164557,
    shape_pt_lon: -90.008928,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.4307,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164491,
    shape_pt_lon: -90.008313,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.4872,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164389,
    shape_pt_lon: -90.00731,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.5778,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164383,
    shape_pt_lon: -90.007242,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.5849,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164282,
    shape_pt_lon: -90.006258,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.6736,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164265,
    shape_pt_lon: -90.00608,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.6907,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164233,
    shape_pt_lon: -90.005785,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.717,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164173,
    shape_pt_lon: -90.005196,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.7703,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16408,
    shape_pt_lon: -90.004269,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.8551,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164057,
    shape_pt_lon: -90.004031,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.8772,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163936,
    shape_pt_lon: -90.002817,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.987,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16382,
    shape_pt_lon: -90.001687,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.0899,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163731,
    shape_pt_lon: -90.000659,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.1834,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163656,
    shape_pt_lon: -89.999998,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.2439,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1636,
    shape_pt_lon: -89.999521,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.2874,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163479,
    shape_pt_lon: -89.998337,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3953,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163361,
    shape_pt_lon: -89.997173,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.5011,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -89.996731,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.5413,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163296,
    shape_pt_lon: -89.996369,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.5744,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163252,
    shape_pt_lon: -89.995958,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.6117,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163189,
    shape_pt_lon: -89.99534,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.6682,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163111,
    shape_pt_lon: -89.994709,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.7257,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16298,
    shape_pt_lon: -89.993437,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.8417,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162957,
    shape_pt_lon: -89.993208,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.8619,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162851,
    shape_pt_lon: -89.992185,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.9556,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162842,
    shape_pt_lon: -89.992093,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.9636,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162723,
    shape_pt_lon: -89.990932,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.0696,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162598,
    shape_pt_lon: -89.989674,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.1844,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162589,
    shape_pt_lon: -89.989588,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.1915,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16235,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.4091,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162339,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.4201,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162269,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.4787,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162208,
    shape_pt_lon: -89.986163,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.5046,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162146,
    shape_pt_lon: -89.985911,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.5287,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162112,
    shape_pt_lon: -89.985674,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.55,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162113,
    shape_pt_lon: -89.985513,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5641,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162144,
    shape_pt_lon: -89.985288,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.5853,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162298,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.6593,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162321,
    shape_pt_lon: -89.984197,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.6854,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162322,
    shape_pt_lon: -89.984057,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.6984,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163152,
    shape_pt_lon: -89.984023,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.7915,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163662,
    shape_pt_lon: -89.984,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.8485,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163981,
    shape_pt_lon: -89.983984,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.8845,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164515,
    shape_pt_lon: -89.983969,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.9436,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164834,
    shape_pt_lon: -89.983948,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.9796,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165227,
    shape_pt_lon: -89.983921,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.0237,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165718,
    shape_pt_lon: -89.983893,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.0787,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166502,
    shape_pt_lon: -89.98386,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.1668,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167168,
    shape_pt_lon: -89.983832,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.2408,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167267,
    shape_pt_lon: -89.983828,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.2518,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167912,
    shape_pt_lon: -89.98381,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.3248,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167993,
    shape_pt_lon: -89.983813,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.3339,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168679,
    shape_pt_lon: -89.983788,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.41,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169197,
    shape_pt_lon: -89.983766,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.468,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16977,
    shape_pt_lon: -89.983742,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.532,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170075,
    shape_pt_lon: -89.98373,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.567,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170526,
    shape_pt_lon: -89.983711,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.617,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170873,
    shape_pt_lon: -89.983695,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.6561,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171316,
    shape_pt_lon: -89.983677,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.7051,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171679,
    shape_pt_lon: -89.983661,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.7462,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171889,
    shape_pt_lon: -89.983628,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.7703,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172029,
    shape_pt_lon: -89.983608,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.7855,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174346,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.0455,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174402,
    shape_pt_lon: -89.983542,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.0516,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174456,
    shape_pt_lon: -89.983571,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.058,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0644,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.0869,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.1593,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.1765,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.2435,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.2596,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.3136,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.4137,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.4887,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.5958,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.7009,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.798,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.808,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.8141,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.8231,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8651,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.9131,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.9861,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.0021,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.0911,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.1482,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.2272,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.2912,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.3782,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.4332,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.4592,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.5242,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.5392,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.6183,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.7073,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.8023,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.8143,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.8944,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.9544,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.0424,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.0764,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.1334,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.2434,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.2944,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.3885,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.5525,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.5766,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.6693,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157942,
    shape_pt_lon: -89.975988,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.6744,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.975997,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.6794,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157906,
    shape_pt_lon: -89.975006,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.7685,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157909,
    shape_pt_lon: -89.973988,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.8605,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15791,
    shape_pt_lon: -89.973728,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.8835,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157919,
    shape_pt_lon: -89.972663,
    shape_pt_sequence: 201,
    shape_dist_traveled: 10.9805,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157922,
    shape_pt_lon: -89.972376,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.0065,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157932,
    shape_pt_lon: -89.971913,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.0475,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157961,
    shape_pt_lon: -89.971649,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.0717,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.157979,
    shape_pt_lon: -89.971554,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.0809,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158026,
    shape_pt_lon: -89.971354,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.0996,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158163,
    shape_pt_lon: -89.970781,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.154,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158486,
    shape_pt_lon: -89.969401,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.2831,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158709,
    shape_pt_lon: -89.968425,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.3746,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158767,
    shape_pt_lon: -89.968172,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.3986,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159033,
    shape_pt_lon: -89.967029,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.5059,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159128,
    shape_pt_lon: -89.966613,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.5452,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159305,
    shape_pt_lon: -89.965851,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.617,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15972,
    shape_pt_lon: -89.964035,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.7876,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159738,
    shape_pt_lon: -89.963937,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.7969,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159766,
    shape_pt_lon: -89.96378,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.8112,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159962,
    shape_pt_lon: -89.962932,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.8903,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16002,
    shape_pt_lon: -89.962741,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.9093,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160031,
    shape_pt_lon: -89.962697,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.9137,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160131,
    shape_pt_lon: -89.962361,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.9457,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160482,
    shape_pt_lon: -89.961376,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.0429,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161037,
    shape_pt_lon: -89.959828,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.196,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161351,
    shape_pt_lon: -89.958953,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.2828,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161696,
    shape_pt_lon: -89.957985,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.3777,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161883,
    shape_pt_lon: -89.957507,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.4256,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162038,
    shape_pt_lon: -89.957158,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.4623,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.956806,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.4995,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162461,
    shape_pt_lon: -89.956345,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.5492,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162681,
    shape_pt_lon: -89.955979,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.5906,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162713,
    shape_pt_lon: -89.955929,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.5964,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163019,
    shape_pt_lon: -89.955451,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.6518,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164066,
    shape_pt_lon: -89.953818,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.8397,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164105,
    shape_pt_lon: -89.953755,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.8469,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164459,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.911,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163179,
    shape_pt_lon: -89.950962,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.1585,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.950821,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.1732,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162861,
    shape_pt_lon: -89.949555,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.2906,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162576,
    shape_pt_lon: -89.948104,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.4264,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.5283,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162017,
    shape_pt_lon: -89.945251,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.6908,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161756,
    shape_pt_lon: -89.944046,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.8036,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161538,
    shape_pt_lon: -89.942891,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.9115,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161448,
    shape_pt_lon: -89.942402,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.9567,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16134,
    shape_pt_lon: -89.941818,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.01,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16125,
    shape_pt_lon: -89.941332,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.0551,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16113,
    shape_pt_lon: -89.94066,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.1175,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161072,
    shape_pt_lon: -89.940385,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.1432,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160938,
    shape_pt_lon: -89.939612,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.2148,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161489,
    shape_pt_lon: -89.939568,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.2759,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.939483,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.3892,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162505,
    shape_pt_lon: -89.939352,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.4012,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.938798,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.4512,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162465,
    shape_pt_lon: -89.937429,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.5752,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.934857,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.8073,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162389,
    shape_pt_lon: -89.933641,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.9173,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16237,
    shape_pt_lon: -89.932579,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.0134,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162348,
    shape_pt_lon: -89.93129,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.1294,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162329,
    shape_pt_lon: -89.930287,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.2204,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162293,
    shape_pt_lon: -89.928736,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.3605,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162259,
    shape_pt_lon: -89.927259,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.4935,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162248,
    shape_pt_lon: -89.926816,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.5335,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162238,
    shape_pt_lon: -89.926421,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.5695,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162229,
    shape_pt_lon: -89.926081,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.6006,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.92518,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.6816,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162196,
    shape_pt_lon: -89.924549,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.7386,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162179,
    shape_pt_lon: -89.923849,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.8016,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162167,
    shape_pt_lon: -89.923343,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.8477,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162147,
    shape_pt_lon: -89.922561,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.9187,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162115,
    shape_pt_lon: -89.921213,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.0407,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1621,
    shape_pt_lon: -89.920656,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.0908,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162066,
    shape_pt_lon: -89.919163,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.2258,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162057,
    shape_pt_lon: -89.918828,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.2558,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.3899,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.5509,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.6409,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.6549,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.762,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.938,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.071,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.0901,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.109,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.1198,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.1355,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.1391,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.1535,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.1904,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.1996,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.2145,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.2284,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.2451,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.2613,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.3263,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.4654,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.4714,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161043,
    shape_pt_lon: -89.905835,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.4844,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161872,
    shape_pt_lon: -89.905812,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.5774,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161979,
    shape_pt_lon: -89.905817,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.5895,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16207,
    shape_pt_lon: -89.905749,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.6011,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162286,
    shape_pt_lon: -89.905737,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.6252,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163323,
    shape_pt_lon: -89.90571,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.7412,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163543,
    shape_pt_lon: -89.905704,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.7662,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16471,
    shape_pt_lon: -89.905676,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.8972,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165041,
    shape_pt_lon: -89.905646,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.9343,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165369,
    shape_pt_lon: -89.905579,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.9708,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165661,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.0047,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165889,
    shape_pt_lon: -89.905392,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.0323,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166188,
    shape_pt_lon: -89.905237,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.0681,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166247,
    shape_pt_lon: -89.905198,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.0757,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166458,
    shape_pt_lon: -89.905056,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.1021,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166692,
    shape_pt_lon: -89.904871,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.1332,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166797,
    shape_pt_lon: -89.904774,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.1482,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166989,
    shape_pt_lon: -89.904592,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.1754,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16711,
    shape_pt_lon: -89.904462,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.1931,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167453,
    shape_pt_lon: -89.904043,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.2469,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16758,
    shape_pt_lon: -89.903906,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.266,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16775,
    shape_pt_lon: -89.903729,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.2908,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167869,
    shape_pt_lon: -89.903612,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.3072,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168423,
    shape_pt_lon: -89.902983,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.3914,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168601,
    shape_pt_lon: -89.902798,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.4177,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168899,
    shape_pt_lon: -89.902516,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.4599,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168957,
    shape_pt_lon: -89.902448,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.4684,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169028,
    shape_pt_lon: -89.902366,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.4797,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16911,
    shape_pt_lon: -89.902271,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.4917,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169452,
    shape_pt_lon: -89.901851,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.5462,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16962,
    shape_pt_lon: -89.901672,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.571,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170064,
    shape_pt_lon: -89.901178,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.6375,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170192,
    shape_pt_lon: -89.901011,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.6587,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170347,
    shape_pt_lon: -89.900851,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.6808,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171088,
    shape_pt_lon: -89.900083,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.7887,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171719,
    shape_pt_lon: -89.899418,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.8817,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172161,
    shape_pt_lon: -89.899001,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.9437,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172343,
    shape_pt_lon: -89.898844,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.9689,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172845,
    shape_pt_lon: -89.898445,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.0355,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173303,
    shape_pt_lon: -89.898114,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.0946,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173754,
    shape_pt_lon: -89.89782,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.1519,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173998,
    shape_pt_lon: -89.897676,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.1819,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174127,
    shape_pt_lon: -89.897689,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.1969,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174453,
    shape_pt_lon: -89.8975,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.2367,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175056,
    shape_pt_lon: -89.897218,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.3092,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17527,
    shape_pt_lon: -89.897142,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.3342,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.897023,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.3708,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175667,
    shape_pt_lon: -89.896996,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.3801,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175687,
    shape_pt_lon: -89.896989,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.3823,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175739,
    shape_pt_lon: -89.89697,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.3886,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1758,
    shape_pt_lon: -89.896949,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.3959,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176067,
    shape_pt_lon: -89.896865,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.4267,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176549,
    shape_pt_lon: -89.89673,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.482,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177197,
    shape_pt_lon: -89.896594,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.555,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177261,
    shape_pt_lon: -89.896582,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.5621,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177907,
    shape_pt_lon: -89.896501,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.6355,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17853,
    shape_pt_lon: -89.89646,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.7056,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179154,
    shape_pt_lon: -89.896444,
    shape_pt_sequence: 352,
    shape_dist_traveled: 19.7746,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179936,
    shape_pt_lon: -89.89643,
    shape_pt_sequence: 353,
    shape_dist_traveled: 19.8626,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180463,
    shape_pt_lon: -89.896418,
    shape_pt_sequence: 354,
    shape_dist_traveled: 19.9216,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.182612,
    shape_pt_lon: -89.896381,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.1616,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183397,
    shape_pt_lon: -89.896371,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.2496,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183762,
    shape_pt_lon: -89.896358,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.2907,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184549,
    shape_pt_lon: -89.896342,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.3787,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185733,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.5117,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185727,
    shape_pt_lon: -89.896209,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.5198,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185712,
    shape_pt_lon: -89.896038,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.5359,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185496,
    shape_pt_lon: -89.894252,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.6987,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185382,
    shape_pt_lon: -89.893203,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.7944,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185226,
    shape_pt_lon: -89.891793,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.9226,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185138,
    shape_pt_lon: -89.89098,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.9972,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18511,
    shape_pt_lon: -89.890624,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.0294,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18511,
    shape_pt_lon: -89.890383,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.0514,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185112,
    shape_pt_lon: -89.890218,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.0654,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185155,
    shape_pt_lon: -89.889691,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.1136,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185227,
    shape_pt_lon: -89.889275,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.1525,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185383,
    shape_pt_lon: -89.888688,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.2084,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185529,
    shape_pt_lon: -89.8883,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.2469,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185657,
    shape_pt_lon: -89.88802,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.2756,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185689,
    shape_pt_lon: -89.887961,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.282,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185915,
    shape_pt_lon: -89.887558,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.3258,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.186144,
    shape_pt_lon: -89.887171,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.3694,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.186588,
    shape_pt_lon: -89.886474,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.4498,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.186371,
    shape_pt_lon: -89.886189,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.4852,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.186184,
    shape_pt_lon: -89.885921,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.5171,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.186019,
    shape_pt_lon: -89.885669,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.547,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185736,
    shape_pt_lon: -89.885167,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.6016,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185511,
    shape_pt_lon: -89.884699,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.6513,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185292,
    shape_pt_lon: -89.884163,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.7055,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185073,
    shape_pt_lon: -89.883456,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.7738,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184962,
    shape_pt_lon: -89.882961,
    shape_pt_sequence: 385,
    shape_dist_traveled: 21.8204,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184888,
    shape_pt_lon: -89.882541,
    shape_pt_sequence: 386,
    shape_dist_traveled: 21.8594,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18481,
    shape_pt_lon: -89.881869,
    shape_pt_sequence: 387,
    shape_dist_traveled: 21.92,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184747,
    shape_pt_lon: -89.88089,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.0092,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184732,
    shape_pt_lon: -89.880691,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.0273,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184706,
    shape_pt_lon: -89.880392,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.0545,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184678,
    shape_pt_lon: -89.880186,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.0728,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184609,
    shape_pt_lon: -89.879848,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.1045,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184489,
    shape_pt_lon: -89.879334,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.1526,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184339,
    shape_pt_lon: -89.878889,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.1966,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.184027,
    shape_pt_lon: -89.878028,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.2812,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183696,
    shape_pt_lon: -89.87711,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.3721,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183529,
    shape_pt_lon: -89.876618,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.4209,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183456,
    shape_pt_lon: -89.876344,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.4472,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183368,
    shape_pt_lon: -89.875972,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.4817,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183267,
    shape_pt_lon: -89.875397,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.5348,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183208,
    shape_pt_lon: -89.875027,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.5693,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183127,
    shape_pt_lon: -89.874548,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.6133,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183085,
    shape_pt_lon: -89.874223,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.6427,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183068,
    shape_pt_lon: -89.874022,
    shape_pt_sequence: 404,
    shape_dist_traveled: 22.6608,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183015,
    shape_pt_lon: -89.873409,
    shape_pt_sequence: 405,
    shape_dist_traveled: 22.7171,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.182996,
    shape_pt_lon: -89.873143,
    shape_pt_sequence: 406,
    shape_dist_traveled: 22.7412,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.182831,
    shape_pt_lon: -89.871422,
    shape_pt_sequence: 407,
    shape_dist_traveled: 22.8973,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18255,
    shape_pt_lon: -89.87145,
    shape_pt_sequence: 408,
    shape_dist_traveled: 22.9283,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.182019,
    shape_pt_lon: -89.871456,
    shape_pt_sequence: 409,
    shape_dist_traveled: 22.9883,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180422,
    shape_pt_lon: -89.871515,
    shape_pt_sequence: 410,
    shape_dist_traveled: 23.1664,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179051,
    shape_pt_lon: -89.871566,
    shape_pt_sequence: 411,
    shape_dist_traveled: 23.3205,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177658,
    shape_pt_lon: -89.871618,
    shape_pt_sequence: 412,
    shape_dist_traveled: 23.4765,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177219,
    shape_pt_lon: -89.871621,
    shape_pt_sequence: 413,
    shape_dist_traveled: 23.5255,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177107,
    shape_pt_lon: -89.871612,
    shape_pt_sequence: 414,
    shape_dist_traveled: 23.5375,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176671,
    shape_pt_lon: -89.871542,
    shape_pt_sequence: 415,
    shape_dist_traveled: 23.587,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176499,
    shape_pt_lon: -89.8715,
    shape_pt_sequence: 416,
    shape_dist_traveled: 23.6064,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176256,
    shape_pt_lon: -89.871421,
    shape_pt_sequence: 417,
    shape_dist_traveled: 23.6353,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176023,
    shape_pt_lon: -89.871327,
    shape_pt_sequence: 418,
    shape_dist_traveled: 23.6625,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175786,
    shape_pt_lon: -89.871208,
    shape_pt_sequence: 419,
    shape_dist_traveled: 23.6907,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175738,
    shape_pt_lon: -89.87118,
    shape_pt_sequence: 420,
    shape_dist_traveled: 23.6965,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175542,
    shape_pt_lon: -89.871065,
    shape_pt_sequence: 421,
    shape_dist_traveled: 23.7207,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17506,
    shape_pt_lon: -89.870743,
    shape_pt_sequence: 422,
    shape_dist_traveled: 23.782,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174284,
    shape_pt_lon: -89.870226,
    shape_pt_sequence: 423,
    shape_dist_traveled: 23.8809,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173749,
    shape_pt_lon: -89.869881,
    shape_pt_sequence: 424,
    shape_dist_traveled: 23.9489,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173389,
    shape_pt_lon: -89.869623,
    shape_pt_sequence: 425,
    shape_dist_traveled: 23.995,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173344,
    shape_pt_lon: -89.869588,
    shape_pt_sequence: 426,
    shape_dist_traveled: 24.0009,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17329,
    shape_pt_lon: -89.869547,
    shape_pt_sequence: 427,
    shape_dist_traveled: 24.0081,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173226,
    shape_pt_lon: -89.86942,
    shape_pt_sequence: 428,
    shape_dist_traveled: 24.0211,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173097,
    shape_pt_lon: -89.869326,
    shape_pt_sequence: 429,
    shape_dist_traveled: 24.0386,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17275,
    shape_pt_lon: -89.869064,
    shape_pt_sequence: 430,
    shape_dist_traveled: 24.0836,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.868948,
    shape_pt_sequence: 431,
    shape_dist_traveled: 24.1033,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172218,
    shape_pt_lon: -89.868588,
    shape_pt_sequence: 432,
    shape_dist_traveled: 24.1575,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172045,
    shape_pt_lon: -89.868408,
    shape_pt_sequence: 433,
    shape_dist_traveled: 24.1823,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171398,
    shape_pt_lon: -89.867747,
    shape_pt_sequence: 434,
    shape_dist_traveled: 24.276,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171307,
    shape_pt_lon: -89.867653,
    shape_pt_sequence: 435,
    shape_dist_traveled: 24.2896,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171021,
    shape_pt_lon: -89.867358,
    shape_pt_sequence: 436,
    shape_dist_traveled: 24.3315,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170017,
    shape_pt_lon: -89.866321,
    shape_pt_sequence: 437,
    shape_dist_traveled: 24.4777,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169776,
    shape_pt_lon: -89.866075,
    shape_pt_sequence: 438,
    shape_dist_traveled: 24.5126,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169582,
    shape_pt_lon: -89.86587,
    shape_pt_sequence: 439,
    shape_dist_traveled: 24.5409,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169427,
    shape_pt_lon: -89.865717,
    shape_pt_sequence: 440,
    shape_dist_traveled: 24.5637,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169202,
    shape_pt_lon: -89.865487,
    shape_pt_sequence: 441,
    shape_dist_traveled: 24.5963,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169003,
    shape_pt_lon: -89.865278,
    shape_pt_sequence: 442,
    shape_dist_traveled: 24.6248,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168888,
    shape_pt_lon: -89.865159,
    shape_pt_sequence: 443,
    shape_dist_traveled: 24.6418,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168745,
    shape_pt_lon: -89.865017,
    shape_pt_sequence: 444,
    shape_dist_traveled: 24.6624,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168301,
    shape_pt_lon: -89.864563,
    shape_pt_sequence: 445,
    shape_dist_traveled: 24.7263,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168259,
    shape_pt_lon: -89.864519,
    shape_pt_sequence: 446,
    shape_dist_traveled: 24.7327,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 447,
    shape_dist_traveled: 24.7391,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 448,
    shape_dist_traveled: 24.7575,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 449,
    shape_dist_traveled: 24.7888,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 450,
    shape_dist_traveled: 24.8264,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 451,
    shape_dist_traveled: 24.9009,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 452,
    shape_dist_traveled: 24.9436,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 453,
    shape_dist_traveled: 24.9904,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 454,
    shape_dist_traveled: 25.0643,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 455,
    shape_dist_traveled: 25.1345,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 456,
    shape_dist_traveled: 25.1445,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 457,
    shape_dist_traveled: 25.1523,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 458,
    shape_dist_traveled: 25.1623,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 459,
    shape_dist_traveled: 25.1758,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 460,
    shape_dist_traveled: 25.19,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 461,
    shape_dist_traveled: 25.244,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 462,
    shape_dist_traveled: 25.3001,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 463,
    shape_dist_traveled: 25.3059,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 464,
    shape_dist_traveled: 25.3434,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 465,
    shape_dist_traveled: 25.3595,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 466,
    shape_dist_traveled: 25.3756,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 467,
    shape_dist_traveled: 25.4101,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 468,
    shape_dist_traveled: 25.4357,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 469,
    shape_dist_traveled: 25.4774,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162915,
    shape_pt_lon: -89.860091,
    shape_pt_sequence: 470,
    shape_dist_traveled: 25.5155,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162871,
    shape_pt_lon: -89.861203,
    shape_pt_sequence: 471,
    shape_dist_traveled: 25.6156,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162873,
    shape_pt_lon: -89.861851,
    shape_pt_sequence: 472,
    shape_dist_traveled: 25.6746,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162883,
    shape_pt_lon: -89.862371,
    shape_pt_sequence: 473,
    shape_dist_traveled: 25.7216,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162891,
    shape_pt_lon: -89.862829,
    shape_pt_sequence: 474,
    shape_dist_traveled: 25.7626,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162898,
    shape_pt_lon: -89.863111,
    shape_pt_sequence: 475,
    shape_dist_traveled: 25.7876,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162901,
    shape_pt_lon: -89.863341,
    shape_pt_sequence: 476,
    shape_dist_traveled: 25.8087,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162909,
    shape_pt_lon: -89.86375,
    shape_pt_sequence: 477,
    shape_dist_traveled: 25.8457,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162623,
    shape_pt_lon: -89.86376,
    shape_pt_sequence: 478,
    shape_dist_traveled: 25.8777,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161748,
    shape_pt_lon: -89.863795,
    shape_pt_sequence: 479,
    shape_dist_traveled: 25.9757,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160743,
    shape_pt_lon: -89.863833,
    shape_pt_sequence: 480,
    shape_dist_traveled: 26.0878,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160279,
    shape_pt_lon: -89.86385,
    shape_pt_sequence: 481,
    shape_dist_traveled: 26.1398,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.160127,
    shape_pt_lon: -89.863857,
    shape_pt_sequence: 482,
    shape_dist_traveled: 26.1568,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15918,
    shape_pt_lon: -89.863897,
    shape_pt_sequence: 483,
    shape_dist_traveled: 26.2629,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.863902,
    shape_pt_sequence: 484,
    shape_dist_traveled: 26.2799,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158893,
    shape_pt_lon: -89.863853,
    shape_pt_sequence: 485,
    shape_dist_traveled: 26.2957,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158766,
    shape_pt_lon: -89.863767,
    shape_pt_sequence: 486,
    shape_dist_traveled: 26.3113,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158669,
    shape_pt_lon: -89.863657,
    shape_pt_sequence: 487,
    shape_dist_traveled: 26.3262,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158593,
    shape_pt_lon: -89.863506,
    shape_pt_sequence: 488,
    shape_dist_traveled: 26.3429,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158561,
    shape_pt_lon: -89.863408,
    shape_pt_sequence: 489,
    shape_dist_traveled: 26.3523,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158542,
    shape_pt_lon: -89.86328,
    shape_pt_sequence: 490,
    shape_dist_traveled: 26.3635,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158533,
    shape_pt_lon: -89.863165,
    shape_pt_sequence: 491,
    shape_dist_traveled: 26.3746,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.861991,
    shape_pt_sequence: 492,
    shape_dist_traveled: 26.4806,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.860467,
    shape_pt_sequence: 493,
    shape_dist_traveled: 26.6186,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859968,
    shape_pt_sequence: 494,
    shape_dist_traveled: 26.6641,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158597,
    shape_pt_lon: -89.859639,
    shape_pt_sequence: 495,
    shape_dist_traveled: 26.6931,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859551,
    shape_pt_sequence: 496,
    shape_dist_traveled: 26.7011,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859462,
    shape_pt_sequence: 497,
    shape_dist_traveled: 26.7091,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161506,
    shape_pt_lon: -89.859413,
    shape_pt_sequence: 498,
    shape_dist_traveled: 27.0341,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.161819,
    shape_pt_lon: -89.859401,
    shape_pt_sequence: 499,
    shape_dist_traveled: 27.0692,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162047,
    shape_pt_lon: -89.859408,
    shape_pt_sequence: 500,
    shape_dist_traveled: 27.0952,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162247,
    shape_pt_lon: -89.859422,
    shape_pt_sequence: 501,
    shape_dist_traveled: 27.1172,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162433,
    shape_pt_lon: -89.859447,
    shape_pt_sequence: 502,
    shape_dist_traveled: 27.1383,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162574,
    shape_pt_lon: -89.859473,
    shape_pt_sequence: 503,
    shape_dist_traveled: 27.1546,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162696,
    shape_pt_lon: -89.859506,
    shape_pt_sequence: 504,
    shape_dist_traveled: 27.1679,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162815,
    shape_pt_lon: -89.859615,
    shape_pt_sequence: 505,
    shape_dist_traveled: 27.1851,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.162857,
    shape_pt_lon: -89.859629,
    shape_pt_sequence: 506,
    shape_dist_traveled: 27.1892,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 507,
    shape_dist_traveled: 27.1991,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 508,
    shape_dist_traveled: 27.2408,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 509,
    shape_dist_traveled: 27.2665,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 510,
    shape_dist_traveled: 27.3009,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 511,
    shape_dist_traveled: 27.317,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 512,
    shape_dist_traveled: 27.3332,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 513,
    shape_dist_traveled: 27.3706,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 514,
    shape_dist_traveled: 27.3764,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 515,
    shape_dist_traveled: 27.4325,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 516,
    shape_dist_traveled: 27.4866,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 517,
    shape_dist_traveled: 27.5008,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 518,
    shape_dist_traveled: 27.5142,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 519,
    shape_dist_traveled: 27.5242,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 520,
    shape_dist_traveled: 27.532,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 521,
    shape_dist_traveled: 27.542,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 522,
    shape_dist_traveled: 27.6122,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 523,
    shape_dist_traveled: 27.6861,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 524,
    shape_dist_traveled: 27.733,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 525,
    shape_dist_traveled: 27.7756,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 526,
    shape_dist_traveled: 27.8501,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 527,
    shape_dist_traveled: 27.8878,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 528,
    shape_dist_traveled: 27.919,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 529,
    shape_dist_traveled: 27.9374,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168259,
    shape_pt_lon: -89.864418,
    shape_pt_sequence: 530,
    shape_dist_traveled: 27.9445,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168468,
    shape_pt_lon: -89.864121,
    shape_pt_sequence: 531,
    shape_dist_traveled: 27.98,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168529,
    shape_pt_lon: -89.863999,
    shape_pt_sequence: 532,
    shape_dist_traveled: 27.993,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168568,
    shape_pt_lon: -89.863871,
    shape_pt_sequence: 533,
    shape_dist_traveled: 28.006,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168583,
    shape_pt_lon: -89.863715,
    shape_pt_sequence: 534,
    shape_dist_traveled: 28.02,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16857,
    shape_pt_lon: -89.863538,
    shape_pt_sequence: 535,
    shape_dist_traveled: 28.0361,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168557,
    shape_pt_lon: -89.863471,
    shape_pt_sequence: 536,
    shape_dist_traveled: 28.0424,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168535,
    shape_pt_lon: -89.863413,
    shape_pt_sequence: 537,
    shape_dist_traveled: 28.0478,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168537,
    shape_pt_lon: -89.863317,
    shape_pt_sequence: 538,
    shape_dist_traveled: 28.0568,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168472,
    shape_pt_lon: -89.863198,
    shape_pt_sequence: 539,
    shape_dist_traveled: 28.069,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168356,
    shape_pt_lon: -89.86307,
    shape_pt_sequence: 540,
    shape_dist_traveled: 28.0867,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168078,
    shape_pt_lon: -89.862806,
    shape_pt_sequence: 541,
    shape_dist_traveled: 28.1259,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168007,
    shape_pt_lon: -89.862708,
    shape_pt_sequence: 542,
    shape_dist_traveled: 28.1379,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167939,
    shape_pt_lon: -89.862581,
    shape_pt_sequence: 543,
    shape_dist_traveled: 28.1515,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167888,
    shape_pt_lon: -89.862448,
    shape_pt_sequence: 544,
    shape_dist_traveled: 28.1645,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167852,
    shape_pt_lon: -89.862297,
    shape_pt_sequence: 545,
    shape_dist_traveled: 28.1791,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167766,
    shape_pt_lon: -89.861317,
    shape_pt_sequence: 546,
    shape_dist_traveled: 28.2687,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167711,
    shape_pt_lon: -89.860727,
    shape_pt_sequence: 547,
    shape_dist_traveled: 28.322,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167672,
    shape_pt_lon: -89.86026,
    shape_pt_sequence: 548,
    shape_dist_traveled: 28.3642,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167676,
    shape_pt_lon: -89.86001,
    shape_pt_sequence: 549,
    shape_dist_traveled: 28.3872,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167724,
    shape_pt_lon: -89.85975,
    shape_pt_sequence: 550,
    shape_dist_traveled: 28.4107,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16779,
    shape_pt_lon: -89.859535,
    shape_pt_sequence: 551,
    shape_dist_traveled: 28.431,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167828,
    shape_pt_lon: -89.859446,
    shape_pt_sequence: 552,
    shape_dist_traveled: 28.4404,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167822,
    shape_pt_lon: -89.859371,
    shape_pt_sequence: 553,
    shape_dist_traveled: 28.4475,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167855,
    shape_pt_lon: -89.859309,
    shape_pt_sequence: 554,
    shape_dist_traveled: 28.4547,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.167938,
    shape_pt_lon: -89.859118,
    shape_pt_sequence: 555,
    shape_dist_traveled: 28.4739,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.16812,
    shape_pt_lon: -89.858645,
    shape_pt_sequence: 556,
    shape_dist_traveled: 28.5218,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.168513,
    shape_pt_lon: -89.857623,
    shape_pt_sequence: 557,
    shape_dist_traveled: 28.6238,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169069,
    shape_pt_lon: -89.856162,
    shape_pt_sequence: 558,
    shape_dist_traveled: 28.7696,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169184,
    shape_pt_lon: -89.855889,
    shape_pt_sequence: 559,
    shape_dist_traveled: 28.7969,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169347,
    shape_pt_lon: -89.855616,
    shape_pt_sequence: 560,
    shape_dist_traveled: 28.8277,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169473,
    shape_pt_lon: -89.855466,
    shape_pt_sequence: 561,
    shape_dist_traveled: 28.8482,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169643,
    shape_pt_lon: -89.855311,
    shape_pt_sequence: 562,
    shape_dist_traveled: 28.8718,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169858,
    shape_pt_lon: -89.855179,
    shape_pt_sequence: 563,
    shape_dist_traveled: 28.8982,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.169937,
    shape_pt_lon: -89.855146,
    shape_pt_sequence: 564,
    shape_dist_traveled: 28.9077,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170147,
    shape_pt_lon: -89.855083,
    shape_pt_sequence: 565,
    shape_dist_traveled: 28.9315,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170433,
    shape_pt_lon: -89.855049,
    shape_pt_sequence: 566,
    shape_dist_traveled: 28.9636,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171102,
    shape_pt_lon: -89.85502,
    shape_pt_sequence: 567,
    shape_dist_traveled: 29.0387,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172228,
    shape_pt_lon: -89.854982,
    shape_pt_sequence: 568,
    shape_dist_traveled: 29.1647,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173239,
    shape_pt_lon: -89.854973,
    shape_pt_sequence: 569,
    shape_dist_traveled: 29.2777,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173492,
    shape_pt_lon: -89.854993,
    shape_pt_sequence: 570,
    shape_dist_traveled: 29.3058,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173734,
    shape_pt_lon: -89.855037,
    shape_pt_sequence: 571,
    shape_dist_traveled: 29.3331,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174029,
    shape_pt_lon: -89.855095,
    shape_pt_sequence: 572,
    shape_dist_traveled: 29.3665,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174297,
    shape_pt_lon: -89.855116,
    shape_pt_sequence: 573,
    shape_dist_traveled: 29.3965,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17468,
    shape_pt_lon: -89.855076,
    shape_pt_sequence: 574,
    shape_dist_traveled: 29.4396,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174861,
    shape_pt_lon: -89.855024,
    shape_pt_sequence: 575,
    shape_dist_traveled: 29.4612,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175099,
    shape_pt_lon: -89.85494,
    shape_pt_sequence: 576,
    shape_dist_traveled: 29.4884,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175252,
    shape_pt_lon: -89.85488,
    shape_pt_sequence: 577,
    shape_dist_traveled: 29.5061,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175588,
    shape_pt_lon: -89.854788,
    shape_pt_sequence: 578,
    shape_dist_traveled: 29.545,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175782,
    shape_pt_lon: -89.854762,
    shape_pt_sequence: 579,
    shape_dist_traveled: 29.5672,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175989,
    shape_pt_lon: -89.854756,
    shape_pt_sequence: 580,
    shape_dist_traveled: 29.5902,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176045,
    shape_pt_lon: -89.854757,
    shape_pt_sequence: 581,
    shape_dist_traveled: 29.5962,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176108,
    shape_pt_lon: -89.854761,
    shape_pt_sequence: 582,
    shape_dist_traveled: 29.6032,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176415,
    shape_pt_lon: -89.854821,
    shape_pt_sequence: 583,
    shape_dist_traveled: 29.6387,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176555,
    shape_pt_lon: -89.854862,
    shape_pt_sequence: 584,
    shape_dist_traveled: 29.6542,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176819,
    shape_pt_lon: -89.854972,
    shape_pt_sequence: 585,
    shape_dist_traveled: 29.6858,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176865,
    shape_pt_lon: -89.854999,
    shape_pt_sequence: 586,
    shape_dist_traveled: 29.6912,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177106,
    shape_pt_lon: -89.85515,
    shape_pt_sequence: 587,
    shape_dist_traveled: 29.7216,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177737,
    shape_pt_lon: -89.855682,
    shape_pt_sequence: 588,
    shape_dist_traveled: 29.8065,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177809,
    shape_pt_lon: -89.855738,
    shape_pt_sequence: 589,
    shape_dist_traveled: 29.8159,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177959,
    shape_pt_lon: -89.855846,
    shape_pt_sequence: 590,
    shape_dist_traveled: 29.8357,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1782,
    shape_pt_lon: -89.855972,
    shape_pt_sequence: 591,
    shape_dist_traveled: 29.8648,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17853,
    shape_pt_lon: -89.8561,
    shape_pt_sequence: 592,
    shape_dist_traveled: 29.9037,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178873,
    shape_pt_lon: -89.856181,
    shape_pt_sequence: 593,
    shape_dist_traveled: 29.9424,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178969,
    shape_pt_lon: -89.856192,
    shape_pt_sequence: 594,
    shape_dist_traveled: 29.9534,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179163,
    shape_pt_lon: -89.856205,
    shape_pt_sequence: 595,
    shape_dist_traveled: 29.9755,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179279,
    shape_pt_lon: -89.8562,
    shape_pt_sequence: 596,
    shape_dist_traveled: 29.9885,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179994,
    shape_pt_lon: -89.856178,
    shape_pt_sequence: 597,
    shape_dist_traveled: 30.0686,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179977,
    shape_pt_lon: -89.855598,
    shape_pt_sequence: 598,
    shape_dist_traveled: 30.1206,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179979,
    shape_pt_lon: -89.855108,
    shape_pt_sequence: 599,
    shape_dist_traveled: 30.1646,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180001,
    shape_pt_lon: -89.854646,
    shape_pt_sequence: 600,
    shape_dist_traveled: 30.2067,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180003,
    shape_pt_lon: -89.854593,
    shape_pt_sequence: 601,
    shape_dist_traveled: 30.2117,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179996,
    shape_pt_lon: -89.854181,
    shape_pt_sequence: 602,
    shape_dist_traveled: 30.2487,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179959,
    shape_pt_lon: -89.853203,
    shape_pt_sequence: 603,
    shape_dist_traveled: 30.3368,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179928,
    shape_pt_lon: -89.852353,
    shape_pt_sequence: 604,
    shape_dist_traveled: 30.4139,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179898,
    shape_pt_lon: -89.8517,
    shape_pt_sequence: 605,
    shape_dist_traveled: 30.4729,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179893,
    shape_pt_lon: -89.851647,
    shape_pt_sequence: 606,
    shape_dist_traveled: 30.478,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179853,
    shape_pt_lon: -89.851339,
    shape_pt_sequence: 607,
    shape_dist_traveled: 30.5063,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179791,
    shape_pt_lon: -89.85104,
    shape_pt_sequence: 608,
    shape_dist_traveled: 30.5342,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179684,
    shape_pt_lon: -89.85062,
    shape_pt_sequence: 609,
    shape_dist_traveled: 30.5741,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179497,
    shape_pt_lon: -89.849978,
    shape_pt_sequence: 610,
    shape_dist_traveled: 30.6358,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179372,
    shape_pt_lon: -89.84967,
    shape_pt_sequence: 611,
    shape_dist_traveled: 30.6662,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179047,
    shape_pt_lon: -89.849065,
    shape_pt_sequence: 612,
    shape_dist_traveled: 30.7319,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178995,
    shape_pt_lon: -89.848967,
    shape_pt_sequence: 613,
    shape_dist_traveled: 30.7427,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178795,
    shape_pt_lon: -89.848637,
    shape_pt_sequence: 614,
    shape_dist_traveled: 30.7799,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177828,
    shape_pt_lon: -89.847053,
    shape_pt_sequence: 615,
    shape_dist_traveled: 30.9591,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17757,
    shape_pt_lon: -89.846546,
    shape_pt_sequence: 616,
    shape_dist_traveled: 31.0135,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177432,
    shape_pt_lon: -89.846073,
    shape_pt_sequence: 617,
    shape_dist_traveled: 31.059,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177313,
    shape_pt_lon: -89.845238,
    shape_pt_sequence: 618,
    shape_dist_traveled: 31.1352,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177303,
    shape_pt_lon: -89.844136,
    shape_pt_sequence: 619,
    shape_dist_traveled: 31.2352,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177412,
    shape_pt_lon: -89.842512,
    shape_pt_sequence: 620,
    shape_dist_traveled: 31.3817,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17756,
    shape_pt_lon: -89.841036,
    shape_pt_sequence: 621,
    shape_dist_traveled: 31.5168,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17766,
    shape_pt_lon: -89.839958,
    shape_pt_sequence: 622,
    shape_dist_traveled: 31.6144,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177848,
    shape_pt_lon: -89.839243,
    shape_pt_sequence: 623,
    shape_dist_traveled: 31.6827,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178195,
    shape_pt_lon: -89.838202,
    shape_pt_sequence: 624,
    shape_dist_traveled: 31.7836,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178372,
    shape_pt_lon: -89.837729,
    shape_pt_sequence: 625,
    shape_dist_traveled: 31.831,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17852,
    shape_pt_lon: -89.837101,
    shape_pt_sequence: 626,
    shape_dist_traveled: 31.8905,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178579,
    shape_pt_lon: -89.836273,
    shape_pt_sequence: 627,
    shape_dist_traveled: 31.9658,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177791,
    shape_pt_lon: -89.836303,
    shape_pt_sequence: 628,
    shape_dist_traveled: 32.0549,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177151,
    shape_pt_lon: -89.836327,
    shape_pt_sequence: 629,
    shape_dist_traveled: 32.1259,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176493,
    shape_pt_lon: -89.836361,
    shape_pt_sequence: 630,
    shape_dist_traveled: 32.2,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176186,
    shape_pt_lon: -89.836378,
    shape_pt_sequence: 631,
    shape_dist_traveled: 32.234,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175339,
    shape_pt_lon: -89.836423,
    shape_pt_sequence: 632,
    shape_dist_traveled: 32.3291,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175252,
    shape_pt_lon: -89.836428,
    shape_pt_sequence: 633,
    shape_dist_traveled: 32.3391,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175116,
    shape_pt_lon: -89.836436,
    shape_pt_sequence: 634,
    shape_dist_traveled: 32.3541,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.836482,
    shape_pt_sequence: 635,
    shape_dist_traveled: 32.4402,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.836493,
    shape_pt_sequence: 636,
    shape_dist_traveled: 32.4592,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1735,
    shape_pt_lon: -89.836533,
    shape_pt_sequence: 637,
    shape_dist_traveled: 32.5353,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17153,
    shape_pt_lon: -89.836639,
    shape_pt_sequence: 638,
    shape_dist_traveled: 32.7555,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171418,
    shape_pt_lon: -89.836646,
    shape_pt_sequence: 639,
    shape_dist_traveled: 32.7685,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171452,
    shape_pt_lon: -89.835715,
    shape_pt_sequence: 640,
    shape_dist_traveled: 32.8526,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171469,
    shape_pt_lon: -89.835521,
    shape_pt_sequence: 641,
    shape_dist_traveled: 32.8697,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1715,
    shape_pt_lon: -89.835306,
    shape_pt_sequence: 642,
    shape_dist_traveled: 32.8901,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171602,
    shape_pt_lon: -89.834913,
    shape_pt_sequence: 643,
    shape_dist_traveled: 32.9268,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171702,
    shape_pt_lon: -89.834646,
    shape_pt_sequence: 644,
    shape_dist_traveled: 32.9532,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171808,
    shape_pt_lon: -89.834421,
    shape_pt_sequence: 645,
    shape_dist_traveled: 32.9774,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171999,
    shape_pt_lon: -89.834099,
    shape_pt_sequence: 646,
    shape_dist_traveled: 33.0138,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172182,
    shape_pt_lon: -89.833798,
    shape_pt_sequence: 647,
    shape_dist_traveled: 33.0474,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172308,
    shape_pt_lon: -89.833529,
    shape_pt_sequence: 648,
    shape_dist_traveled: 33.0752,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172389,
    shape_pt_lon: -89.83331,
    shape_pt_sequence: 649,
    shape_dist_traveled: 33.0975,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172432,
    shape_pt_lon: -89.833177,
    shape_pt_sequence: 650,
    shape_dist_traveled: 33.1102,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172489,
    shape_pt_lon: -89.832913,
    shape_pt_sequence: 651,
    shape_dist_traveled: 33.1352,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172532,
    shape_pt_lon: -89.832638,
    shape_pt_sequence: 652,
    shape_dist_traveled: 33.1607,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172578,
    shape_pt_lon: -89.832567,
    shape_pt_sequence: 653,
    shape_dist_traveled: 33.1685,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172616,
    shape_pt_lon: -89.832274,
    shape_pt_sequence: 654,
    shape_dist_traveled: 33.1948,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172677,
    shape_pt_lon: -89.831918,
    shape_pt_sequence: 655,
    shape_dist_traveled: 33.2276,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172741,
    shape_pt_lon: -89.831618,
    shape_pt_sequence: 656,
    shape_dist_traveled: 33.2564,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172854,
    shape_pt_lon: -89.831108,
    shape_pt_sequence: 657,
    shape_dist_traveled: 33.3042,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172951,
    shape_pt_lon: -89.830817,
    shape_pt_sequence: 658,
    shape_dist_traveled: 33.3325,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173061,
    shape_pt_lon: -89.830556,
    shape_pt_sequence: 659,
    shape_dist_traveled: 33.3584,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17315,
    shape_pt_lon: -89.83038,
    shape_pt_sequence: 660,
    shape_dist_traveled: 33.3773,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173351,
    shape_pt_lon: -89.830088,
    shape_pt_sequence: 661,
    shape_dist_traveled: 33.4127,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173779,
    shape_pt_lon: -89.829569,
    shape_pt_sequence: 662,
    shape_dist_traveled: 33.4792,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173963,
    shape_pt_lon: -89.82928,
    shape_pt_sequence: 663,
    shape_dist_traveled: 33.5126,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.828998,
    shape_pt_sequence: 664,
    shape_dist_traveled: 33.5427,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174191,
    shape_pt_lon: -89.828746,
    shape_pt_sequence: 665,
    shape_dist_traveled: 33.5677,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17432,
    shape_pt_lon: -89.828263,
    shape_pt_sequence: 666,
    shape_dist_traveled: 33.6133,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174347,
    shape_pt_lon: -89.828156,
    shape_pt_sequence: 667,
    shape_dist_traveled: 33.6237,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174372,
    shape_pt_lon: -89.828018,
    shape_pt_sequence: 668,
    shape_dist_traveled: 33.6361,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174352,
    shape_pt_lon: -89.827942,
    shape_pt_sequence: 669,
    shape_dist_traveled: 33.6437,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174359,
    shape_pt_lon: -89.827747,
    shape_pt_sequence: 670,
    shape_dist_traveled: 33.6617,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174366,
    shape_pt_lon: -89.82752,
    shape_pt_sequence: 671,
    shape_dist_traveled: 33.6818,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174332,
    shape_pt_lon: -89.826929,
    shape_pt_sequence: 672,
    shape_dist_traveled: 33.7359,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174313,
    shape_pt_lon: -89.826763,
    shape_pt_sequence: 673,
    shape_dist_traveled: 33.751,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174291,
    shape_pt_lon: -89.826478,
    shape_pt_sequence: 674,
    shape_dist_traveled: 33.7761,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174296,
    shape_pt_lon: -89.826189,
    shape_pt_sequence: 675,
    shape_dist_traveled: 33.8021,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174337,
    shape_pt_lon: -89.82582,
    shape_pt_sequence: 676,
    shape_dist_traveled: 33.8364,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174398,
    shape_pt_lon: -89.82554,
    shape_pt_sequence: 677,
    shape_dist_traveled: 33.8623,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174456,
    shape_pt_lon: -89.825348,
    shape_pt_sequence: 678,
    shape_dist_traveled: 33.8807,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17454,
    shape_pt_lon: -89.825108,
    shape_pt_sequence: 679,
    shape_dist_traveled: 33.9045,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174651,
    shape_pt_lon: -89.824871,
    shape_pt_sequence: 680,
    shape_dist_traveled: 33.9292,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174727,
    shape_pt_lon: -89.824742,
    shape_pt_sequence: 681,
    shape_dist_traveled: 33.9436,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174895,
    shape_pt_lon: -89.824489,
    shape_pt_sequence: 682,
    shape_dist_traveled: 33.9734,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175052,
    shape_pt_lon: -89.824299,
    shape_pt_sequence: 683,
    shape_dist_traveled: 33.9982,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175129,
    shape_pt_lon: -89.824228,
    shape_pt_sequence: 684,
    shape_dist_traveled: 34.0082,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175292,
    shape_pt_lon: -89.824072,
    shape_pt_sequence: 685,
    shape_dist_traveled: 34.0318,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175538,
    shape_pt_lon: -89.823892,
    shape_pt_sequence: 686,
    shape_dist_traveled: 34.0637,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175736,
    shape_pt_lon: -89.823786,
    shape_pt_sequence: 687,
    shape_dist_traveled: 34.0875,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176033,
    shape_pt_lon: -89.82367,
    shape_pt_sequence: 688,
    shape_dist_traveled: 34.1229,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176269,
    shape_pt_lon: -89.823614,
    shape_pt_sequence: 689,
    shape_dist_traveled: 34.1494,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176567,
    shape_pt_lon: -89.823574,
    shape_pt_sequence: 690,
    shape_dist_traveled: 34.1826,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176633,
    shape_pt_lon: -89.82357,
    shape_pt_sequence: 691,
    shape_dist_traveled: 34.1906,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176682,
    shape_pt_lon: -89.823563,
    shape_pt_sequence: 692,
    shape_dist_traveled: 34.1957,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.176741,
    shape_pt_lon: -89.823608,
    shape_pt_sequence: 693,
    shape_dist_traveled: 34.2038,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177163,
    shape_pt_lon: -89.823577,
    shape_pt_sequence: 694,
    shape_dist_traveled: 34.2509,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178038,
    shape_pt_lon: -89.823514,
    shape_pt_sequence: 695,
    shape_dist_traveled: 34.349,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178218,
    shape_pt_lon: -89.823483,
    shape_pt_sequence: 696,
    shape_dist_traveled: 34.3692,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178536,
    shape_pt_lon: -89.82338,
    shape_pt_sequence: 697,
    shape_dist_traveled: 34.4064,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178786,
    shape_pt_lon: -89.823253,
    shape_pt_sequence: 698,
    shape_dist_traveled: 34.4364,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178812,
    shape_pt_lon: -89.823237,
    shape_pt_sequence: 699,
    shape_dist_traveled: 34.44,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179015,
    shape_pt_lon: -89.823095,
    shape_pt_sequence: 700,
    shape_dist_traveled: 34.4656,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179234,
    shape_pt_lon: -89.8229,
    shape_pt_sequence: 701,
    shape_dist_traveled: 34.4958,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179442,
    shape_pt_lon: -89.822661,
    shape_pt_sequence: 702,
    shape_dist_traveled: 34.5277,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17964,
    shape_pt_lon: -89.82237,
    shape_pt_sequence: 703,
    shape_dist_traveled: 34.5624,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179716,
    shape_pt_lon: -89.822226,
    shape_pt_sequence: 704,
    shape_dist_traveled: 34.5776,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179757,
    shape_pt_lon: -89.822143,
    shape_pt_sequence: 705,
    shape_dist_traveled: 34.5862,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179879,
    shape_pt_lon: -89.821845,
    shape_pt_sequence: 706,
    shape_dist_traveled: 34.6162,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180347,
    shape_pt_lon: -89.82014,
    shape_pt_sequence: 707,
    shape_dist_traveled: 34.7791,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180404,
    shape_pt_lon: -89.81984,
    shape_pt_sequence: 708,
    shape_dist_traveled: 34.807,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180433,
    shape_pt_lon: -89.819551,
    shape_pt_sequence: 709,
    shape_dist_traveled: 34.8331,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180433,
    shape_pt_lon: -89.819178,
    shape_pt_sequence: 710,
    shape_dist_traveled: 34.8671,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180388,
    shape_pt_lon: -89.8188,
    shape_pt_sequence: 711,
    shape_dist_traveled: 34.9015,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18036,
    shape_pt_lon: -89.818662,
    shape_pt_sequence: 712,
    shape_dist_traveled: 34.9139,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.180287,
    shape_pt_lon: -89.818387,
    shape_pt_sequence: 713,
    shape_dist_traveled: 34.9401,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179977,
    shape_pt_lon: -89.81732,
    shape_pt_sequence: 714,
    shape_dist_traveled: 35.0432,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17975,
    shape_pt_lon: -89.816529,
    shape_pt_sequence: 715,
    shape_dist_traveled: 35.1185,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179699,
    shape_pt_lon: -89.816261,
    shape_pt_sequence: 716,
    shape_dist_traveled: 35.143,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179673,
    shape_pt_lon: -89.816046,
    shape_pt_sequence: 717,
    shape_dist_traveled: 35.1632,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17965,
    shape_pt_lon: -89.815749,
    shape_pt_sequence: 718,
    shape_dist_traveled: 35.1904,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179417,
    shape_pt_lon: -89.813051,
    shape_pt_sequence: 719,
    shape_dist_traveled: 35.4347,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177375,
    shape_pt_lon: -89.813547,
    shape_pt_sequence: 720,
    shape_dist_traveled: 35.6679,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.1761,
    shape_pt_lon: -89.813858,
    shape_pt_sequence: 721,
    shape_dist_traveled: 35.8134,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.174801,
    shape_pt_lon: -89.814173,
    shape_pt_sequence: 722,
    shape_dist_traveled: 35.9611,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173917,
    shape_pt_lon: -89.814389,
    shape_pt_sequence: 723,
    shape_dist_traveled: 36.0621,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173586,
    shape_pt_lon: -89.814468,
    shape_pt_sequence: 724,
    shape_dist_traveled: 36.0997,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173259,
    shape_pt_lon: -89.814548,
    shape_pt_sequence: 725,
    shape_dist_traveled: 36.1374,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17293,
    shape_pt_lon: -89.814618,
    shape_pt_sequence: 726,
    shape_dist_traveled: 36.1739,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172683,
    shape_pt_lon: -89.814662,
    shape_pt_sequence: 727,
    shape_dist_traveled: 36.2022,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17224,
    shape_pt_lon: -89.81477,
    shape_pt_sequence: 728,
    shape_dist_traveled: 36.2532,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172093,
    shape_pt_lon: -89.814817,
    shape_pt_sequence: 729,
    shape_dist_traveled: 36.2697,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172068,
    shape_pt_lon: -89.814714,
    shape_pt_sequence: 730,
    shape_dist_traveled: 36.2792,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17205,
    shape_pt_lon: -89.814639,
    shape_pt_sequence: 731,
    shape_dist_traveled: 36.2864,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171968,
    shape_pt_lon: -89.814203,
    shape_pt_sequence: 732,
    shape_dist_traveled: 36.3274,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171909,
    shape_pt_lon: -89.813909,
    shape_pt_sequence: 733,
    shape_dist_traveled: 36.3544,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17189,
    shape_pt_lon: -89.813819,
    shape_pt_sequence: 734,
    shape_dist_traveled: 36.3626,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171797,
    shape_pt_lon: -89.813415,
    shape_pt_sequence: 735,
    shape_dist_traveled: 36.4009,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171762,
    shape_pt_lon: -89.813289,
    shape_pt_sequence: 736,
    shape_dist_traveled: 36.4126,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171675,
    shape_pt_lon: -89.813,
    shape_pt_sequence: 737,
    shape_dist_traveled: 36.4405,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171501,
    shape_pt_lon: -89.812488,
    shape_pt_sequence: 738,
    shape_dist_traveled: 36.4912,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171383,
    shape_pt_lon: -89.812116,
    shape_pt_sequence: 739,
    shape_dist_traveled: 36.5267,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171323,
    shape_pt_lon: -89.811912,
    shape_pt_sequence: 740,
    shape_dist_traveled: 36.5469,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171211,
    shape_pt_lon: -89.81153,
    shape_pt_sequence: 741,
    shape_dist_traveled: 36.583,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17116,
    shape_pt_lon: -89.811361,
    shape_pt_sequence: 742,
    shape_dist_traveled: 36.6001,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171056,
    shape_pt_lon: -89.811046,
    shape_pt_sequence: 743,
    shape_dist_traveled: 36.6301,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171032,
    shape_pt_lon: -89.810974,
    shape_pt_sequence: 744,
    shape_dist_traveled: 36.6378,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17089,
    shape_pt_lon: -89.810506,
    shape_pt_sequence: 745,
    shape_dist_traveled: 36.6827,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170839,
    shape_pt_lon: -89.810258,
    shape_pt_sequence: 746,
    shape_dist_traveled: 36.7053,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170828,
    shape_pt_lon: -89.810129,
    shape_pt_sequence: 747,
    shape_dist_traveled: 36.7174,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170829,
    shape_pt_lon: -89.809887,
    shape_pt_sequence: 748,
    shape_dist_traveled: 36.7394,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170855,
    shape_pt_lon: -89.809644,
    shape_pt_sequence: 749,
    shape_dist_traveled: 36.7616,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.170939,
    shape_pt_lon: -89.8093,
    shape_pt_sequence: 750,
    shape_dist_traveled: 36.7939,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17112,
    shape_pt_lon: -89.808769,
    shape_pt_sequence: 751,
    shape_dist_traveled: 36.8459,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171589,
    shape_pt_lon: -89.807425,
    shape_pt_sequence: 752,
    shape_dist_traveled: 36.978,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171712,
    shape_pt_lon: -89.807144,
    shape_pt_sequence: 753,
    shape_dist_traveled: 37.0067,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.171866,
    shape_pt_lon: -89.806868,
    shape_pt_sequence: 754,
    shape_dist_traveled: 37.0369,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172063,
    shape_pt_lon: -89.806533,
    shape_pt_sequence: 755,
    shape_dist_traveled: 37.0741,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172189,
    shape_pt_lon: -89.80628,
    shape_pt_sequence: 756,
    shape_dist_traveled: 37.1016,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172279,
    shape_pt_lon: -89.806021,
    shape_pt_sequence: 757,
    shape_dist_traveled: 37.1276,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172329,
    shape_pt_lon: -89.805793,
    shape_pt_sequence: 758,
    shape_dist_traveled: 37.1482,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172346,
    shape_pt_lon: -89.805537,
    shape_pt_sequence: 759,
    shape_dist_traveled: 37.1713,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172313,
    shape_pt_lon: -89.804819,
    shape_pt_sequence: 760,
    shape_dist_traveled: 37.2363,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172307,
    shape_pt_lon: -89.804249,
    shape_pt_sequence: 761,
    shape_dist_traveled: 37.2884,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172297,
    shape_pt_lon: -89.803933,
    shape_pt_sequence: 762,
    shape_dist_traveled: 37.3164,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172285,
    shape_pt_lon: -89.803412,
    shape_pt_sequence: 763,
    shape_dist_traveled: 37.3634,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172277,
    shape_pt_lon: -89.80237,
    shape_pt_sequence: 764,
    shape_dist_traveled: 37.4574,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17227,
    shape_pt_lon: -89.802261,
    shape_pt_sequence: 765,
    shape_dist_traveled: 37.4674,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172252,
    shape_pt_lon: -89.801929,
    shape_pt_sequence: 766,
    shape_dist_traveled: 37.4975,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172239,
    shape_pt_lon: -89.801152,
    shape_pt_sequence: 767,
    shape_dist_traveled: 37.5675,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172231,
    shape_pt_lon: -89.800692,
    shape_pt_sequence: 768,
    shape_dist_traveled: 37.6095,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172236,
    shape_pt_lon: -89.800425,
    shape_pt_sequence: 769,
    shape_dist_traveled: 37.6335,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172273,
    shape_pt_lon: -89.799911,
    shape_pt_sequence: 770,
    shape_dist_traveled: 37.6797,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17229,
    shape_pt_lon: -89.799712,
    shape_pt_sequence: 771,
    shape_dist_traveled: 37.6979,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172341,
    shape_pt_lon: -89.799168,
    shape_pt_sequence: 772,
    shape_dist_traveled: 37.7471,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172375,
    shape_pt_lon: -89.798892,
    shape_pt_sequence: 773,
    shape_dist_traveled: 37.7724,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172598,
    shape_pt_lon: -89.797548,
    shape_pt_sequence: 774,
    shape_dist_traveled: 37.8972,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172621,
    shape_pt_lon: -89.797409,
    shape_pt_sequence: 775,
    shape_dist_traveled: 37.9093,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172664,
    shape_pt_lon: -89.797087,
    shape_pt_sequence: 776,
    shape_dist_traveled: 37.9388,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172716,
    shape_pt_lon: -89.796561,
    shape_pt_sequence: 777,
    shape_dist_traveled: 37.9871,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17275,
    shape_pt_lon: -89.796309,
    shape_pt_sequence: 778,
    shape_dist_traveled: 38.0105,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.172833,
    shape_pt_lon: -89.795862,
    shape_pt_sequence: 779,
    shape_dist_traveled: 38.0515,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173054,
    shape_pt_lon: -89.79499,
    shape_pt_sequence: 780,
    shape_dist_traveled: 38.1343,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173108,
    shape_pt_lon: -89.794789,
    shape_pt_sequence: 781,
    shape_dist_traveled: 38.1533,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173242,
    shape_pt_lon: -89.794119,
    shape_pt_sequence: 782,
    shape_dist_traveled: 38.2154,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173293,
    shape_pt_lon: -89.793889,
    shape_pt_sequence: 783,
    shape_dist_traveled: 38.237,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173318,
    shape_pt_lon: -89.793083,
    shape_pt_sequence: 784,
    shape_dist_traveled: 38.3101,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.17332,
    shape_pt_lon: -89.792998,
    shape_pt_sequence: 785,
    shape_dist_traveled: 38.3171,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.173322,
    shape_pt_lon: -89.792903,
    shape_pt_sequence: 786,
    shape_dist_traveled: 38.3261,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.175673,
    shape_pt_lon: -89.79288,
    shape_pt_sequence: 787,
    shape_dist_traveled: 38.5891,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177572,
    shape_pt_lon: -89.792862,
    shape_pt_sequence: 788,
    shape_dist_traveled: 38.8011,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.177951,
    shape_pt_lon: -89.792856,
    shape_pt_sequence: 789,
    shape_dist_traveled: 38.8441,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.178851,
    shape_pt_lon: -89.792848,
    shape_pt_sequence: 790,
    shape_dist_traveled: 38.9451,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.179797,
    shape_pt_lon: -89.792818,
    shape_pt_sequence: 791,
    shape_dist_traveled: 39.0502,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.181001,
    shape_pt_lon: -89.792762,
    shape_pt_sequence: 792,
    shape_dist_traveled: 39.1852,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.181834,
    shape_pt_lon: -89.792727,
    shape_pt_sequence: 793,
    shape_dist_traveled: 39.2783,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18192,
    shape_pt_lon: -89.792723,
    shape_pt_sequence: 794,
    shape_dist_traveled: 39.2883,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.18257,
    shape_pt_lon: -89.79269,
    shape_pt_sequence: 795,
    shape_dist_traveled: 39.3614,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.183178,
    shape_pt_lon: -89.792667,
    shape_pt_sequence: 796,
    shape_dist_traveled: 39.4294,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185157,
    shape_pt_lon: -89.79261,
    shape_pt_sequence: 797,
    shape_dist_traveled: 39.6504,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185155,
    shape_pt_lon: -89.792701,
    shape_pt_sequence: 798,
    shape_dist_traveled: 39.6594,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185151,
    shape_pt_lon: -89.792791,
    shape_pt_sequence: 799,
    shape_dist_traveled: 39.6674,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185195,
    shape_pt_lon: -89.795283,
    shape_pt_sequence: 800,
    shape_dist_traveled: 39.8925,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185216,
    shape_pt_lon: -89.795625,
    shape_pt_sequence: 801,
    shape_dist_traveled: 39.9235,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.185246,
    shape_pt_lon: -89.79704,
    shape_pt_sequence: 802,
    shape_dist_traveled: 40.0516,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.188448,
    shape_pt_lon: -89.79715,
    shape_pt_sequence: 803,
    shape_dist_traveled: 40.4097,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.188428,
    shape_pt_lon: -89.792521,
    shape_pt_sequence: 804,
    shape_dist_traveled: 40.8277,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.188423,
    shape_pt_lon: -89.792516,
    shape_pt_sequence: 805,
    shape_dist_traveled: 40.8277,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.189147,
    shape_pt_lon: -89.792497,
    shape_pt_sequence: 806,
    shape_dist_traveled: 40.9087,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.191332,
    shape_pt_lon: -89.79243,
    shape_pt_sequence: 807,
    shape_dist_traveled: 41.1528,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.191631,
    shape_pt_lon: -89.79242,
    shape_pt_sequence: 808,
    shape_dist_traveled: 41.1868,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.191801,
    shape_pt_lon: -89.792413,
    shape_pt_sequence: 809,
    shape_dist_traveled: 41.2058,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.191901,
    shape_pt_lon: -89.792409,
    shape_pt_sequence: 810,
    shape_dist_traveled: 41.2168,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.193095,
    shape_pt_lon: -89.792366,
    shape_pt_sequence: 811,
    shape_dist_traveled: 41.3509,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.193239,
    shape_pt_lon: -89.79236,
    shape_pt_sequence: 812,
    shape_dist_traveled: 41.3669,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.193459,
    shape_pt_lon: -89.792351,
    shape_pt_sequence: 813,
    shape_dist_traveled: 41.3909,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.194573,
    shape_pt_lon: -89.792312,
    shape_pt_sequence: 814,
    shape_dist_traveled: 41.5159,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.194793,
    shape_pt_lon: -89.792305,
    shape_pt_sequence: 815,
    shape_dist_traveled: 41.541,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.194959,
    shape_pt_lon: -89.792298,
    shape_pt_sequence: 816,
    shape_dist_traveled: 41.559,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.19506,
    shape_pt_lon: -89.792293,
    shape_pt_sequence: 817,
    shape_dist_traveled: 41.571,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.195179,
    shape_pt_lon: -89.79229,
    shape_pt_sequence: 818,
    shape_dist_traveled: 41.584,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.195269,
    shape_pt_lon: -89.792287,
    shape_pt_sequence: 819,
    shape_dist_traveled: 41.594,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.19549,
    shape_pt_lon: -89.792279,
    shape_pt_sequence: 820,
    shape_dist_traveled: 41.619,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.195981,
    shape_pt_lon: -89.792264,
    shape_pt_sequence: 821,
    shape_dist_traveled: 41.674,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.196499,
    shape_pt_lon: -89.792246,
    shape_pt_sequence: 822,
    shape_dist_traveled: 41.732,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.196977,
    shape_pt_lon: -89.792224,
    shape_pt_sequence: 823,
    shape_dist_traveled: 41.7851,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.19806,
    shape_pt_lon: -89.792187,
    shape_pt_sequence: 824,
    shape_dist_traveled: 41.9061,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.198909,
    shape_pt_lon: -89.792139,
    shape_pt_sequence: 825,
    shape_dist_traveled: 42.0012,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.198998,
    shape_pt_lon: -89.792132,
    shape_pt_sequence: 826,
    shape_dist_traveled: 42.0112,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.199156,
    shape_pt_lon: -89.792115,
    shape_pt_sequence: 827,
    shape_dist_traveled: 42.0293,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201038,
    shape_pt_lon: -89.792058,
    shape_pt_sequence: 828,
    shape_dist_traveled: 42.2404,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201681,
    shape_pt_lon: -89.79203,
    shape_pt_sequence: 829,
    shape_dist_traveled: 42.3124,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201746,
    shape_pt_lon: -89.792026,
    shape_pt_sequence: 830,
    shape_dist_traveled: 42.3194,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201802,
    shape_pt_lon: -89.792026,
    shape_pt_sequence: 831,
    shape_dist_traveled: 42.3254,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.20393,
    shape_pt_lon: -89.791956,
    shape_pt_sequence: 832,
    shape_dist_traveled: 42.5635,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204209,
    shape_pt_lon: -89.791826,
    shape_pt_sequence: 833,
    shape_dist_traveled: 42.5967,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204269,
    shape_pt_lon: -89.791757,
    shape_pt_sequence: 834,
    shape_dist_traveled: 42.6059,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204312,
    shape_pt_lon: -89.791637,
    shape_pt_sequence: 835,
    shape_dist_traveled: 42.618,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204371,
    shape_pt_lon: -89.791276,
    shape_pt_sequence: 836,
    shape_dist_traveled: 42.6517,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204344,
    shape_pt_lon: -89.790127,
    shape_pt_sequence: 837,
    shape_dist_traveled: 42.7548,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204324,
    shape_pt_lon: -89.78874,
    shape_pt_sequence: 838,
    shape_dist_traveled: 42.8808,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204323,
    shape_pt_lon: -89.788632,
    shape_pt_sequence: 839,
    shape_dist_traveled: 42.8898,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204323,
    shape_pt_lon: -89.788465,
    shape_pt_sequence: 840,
    shape_dist_traveled: 42.9048,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204288,
    shape_pt_lon: -89.785391,
    shape_pt_sequence: 841,
    shape_dist_traveled: 43.1828,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204288,
    shape_pt_lon: -89.784891,
    shape_pt_sequence: 842,
    shape_dist_traveled: 43.2278,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204287,
    shape_pt_lon: -89.784822,
    shape_pt_sequence: 843,
    shape_dist_traveled: 43.2338,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204286,
    shape_pt_lon: -89.784744,
    shape_pt_sequence: 844,
    shape_dist_traveled: 43.2408,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.782178,
    shape_pt_sequence: 845,
    shape_dist_traveled: 43.4728,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204247,
    shape_pt_lon: -89.78141,
    shape_pt_sequence: 846,
    shape_dist_traveled: 43.5418,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.20425,
    shape_pt_lon: -89.779726,
    shape_pt_sequence: 847,
    shape_dist_traveled: 43.6938,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204252,
    shape_pt_lon: -89.778769,
    shape_pt_sequence: 848,
    shape_dist_traveled: 43.7808,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204264,
    shape_pt_lon: -89.777927,
    shape_pt_sequence: 849,
    shape_dist_traveled: 43.8568,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.77771,
    shape_pt_sequence: 850,
    shape_dist_traveled: 43.8758,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.203717,
    shape_pt_lon: -89.777721,
    shape_pt_sequence: 851,
    shape_dist_traveled: 43.9368,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.203496,
    shape_pt_lon: -89.777759,
    shape_pt_sequence: 852,
    shape_dist_traveled: 43.9612,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.203337,
    shape_pt_lon: -89.777806,
    shape_pt_sequence: 853,
    shape_dist_traveled: 43.9796,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.203174,
    shape_pt_lon: -89.777883,
    shape_pt_sequence: 854,
    shape_dist_traveled: 43.9999,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.202979,
    shape_pt_lon: -89.778008,
    shape_pt_sequence: 855,
    shape_dist_traveled: 44.0236,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.202782,
    shape_pt_lon: -89.778181,
    shape_pt_sequence: 856,
    shape_dist_traveled: 44.0516,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.202592,
    shape_pt_lon: -89.778407,
    shape_pt_sequence: 857,
    shape_dist_traveled: 44.0806,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.202431,
    shape_pt_lon: -89.778698,
    shape_pt_sequence: 858,
    shape_dist_traveled: 44.1122,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201668,
    shape_pt_lon: -89.780311,
    shape_pt_sequence: 859,
    shape_dist_traveled: 44.2808,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201631,
    shape_pt_lon: -89.780423,
    shape_pt_sequence: 860,
    shape_dist_traveled: 44.2925,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.20151,
    shape_pt_lon: -89.78068,
    shape_pt_sequence: 861,
    shape_dist_traveled: 44.3194,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.201297,
    shape_pt_lon: -89.781008,
    shape_pt_sequence: 862,
    shape_dist_traveled: 44.3564,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.2011,
    shape_pt_lon: -89.781218,
    shape_pt_sequence: 863,
    shape_dist_traveled: 44.3863,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200787,
    shape_pt_lon: -89.781513,
    shape_pt_sequence: 864,
    shape_dist_traveled: 44.4305,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200686,
    shape_pt_lon: -89.781637,
    shape_pt_sequence: 865,
    shape_dist_traveled: 44.446,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.20055,
    shape_pt_lon: -89.781857,
    shape_pt_sequence: 866,
    shape_dist_traveled: 44.471,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.20041,
    shape_pt_lon: -89.782162,
    shape_pt_sequence: 867,
    shape_dist_traveled: 44.5024,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200337,
    shape_pt_lon: -89.782396,
    shape_pt_sequence: 868,
    shape_dist_traveled: 44.5249,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200293,
    shape_pt_lon: -89.782613,
    shape_pt_sequence: 869,
    shape_dist_traveled: 44.5455,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200255,
    shape_pt_lon: -89.783005,
    shape_pt_sequence: 870,
    shape_dist_traveled: 44.5809,
  },
  {
    shape_id: 38197,
    shape_pt_lat: 35.200264,
    shape_pt_lon: -89.78321,
    shape_pt_sequence: 871,
    shape_dist_traveled: 44.5999,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3669,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4267,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4461,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4911,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5561,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6132,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.7102,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8432,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.8642,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.8933,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.928,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.9701,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9818,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9862,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0246,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0499,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.096,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1422,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1862,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2546,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3414,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3805,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3855,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4425,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4495,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4985,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5017,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5167,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5327,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.5948,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6148,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6939,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7229,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.825,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.838,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9451,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.9651,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0372,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.0902,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1542,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1803,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2162,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3024,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157517,
    shape_pt_lon: -90.023161,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3064,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158187,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.3817,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15925,
    shape_pt_lon: -90.022942,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.5014,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160939,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.692,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161008,
    shape_pt_lon: -90.022653,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7001,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161289,
    shape_pt_lon: -90.022607,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.7314,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161642,
    shape_pt_lon: -90.022548,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7717,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161669,
    shape_pt_lon: -90.022542,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7748,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162102,
    shape_pt_lon: -90.022471,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8232,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162448,
    shape_pt_lon: -90.022414,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.8625,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163354,
    shape_pt_lon: -90.022263,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9645,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163811,
    shape_pt_lon: -90.022188,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.016,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.021859,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.2438,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165911,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.2528,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165779,
    shape_pt_lon: -90.020646,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.3637,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16566,
    shape_pt_lon: -90.019526,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.4657,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16559,
    shape_pt_lon: -90.01886,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5262,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165574,
    shape_pt_lon: -90.018738,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.5372,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165565,
    shape_pt_lon: -90.018664,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.5443,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165503,
    shape_pt_lon: -90.018218,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.5849,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165469,
    shape_pt_lon: -90.01796,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.6083,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165438,
    shape_pt_lon: -90.017736,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.6295,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165359,
    shape_pt_lon: -90.017013,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.6951,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165308,
    shape_pt_lon: -90.016482,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.7435,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16523,
    shape_pt_lon: -90.015676,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.817,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165151,
    shape_pt_lon: -90.014877,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.8895,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165072,
    shape_pt_lon: -90.014077,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.962,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164997,
    shape_pt_lon: -90.013295,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.0336,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164993,
    shape_pt_lon: -90.013267,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0356,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164918,
    shape_pt_lon: -90.012502,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.1051,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16491,
    shape_pt_lon: -90.012433,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.1121,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16484,
    shape_pt_lon: -90.011693,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.1786,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164836,
    shape_pt_lon: -90.011643,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.1837,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164765,
    shape_pt_lon: -90.010895,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.2521,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164727,
    shape_pt_lon: -90.010507,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.2874,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.3005,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164698,
    shape_pt_lon: -90.010199,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.3146,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164601,
    shape_pt_lon: -90.009349,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.3924,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164557,
    shape_pt_lon: -90.008928,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.4307,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164491,
    shape_pt_lon: -90.008313,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.4872,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164389,
    shape_pt_lon: -90.00731,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.5778,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164383,
    shape_pt_lon: -90.007242,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.5849,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164282,
    shape_pt_lon: -90.006258,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.6736,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164265,
    shape_pt_lon: -90.00608,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.6907,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164233,
    shape_pt_lon: -90.005785,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.717,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164173,
    shape_pt_lon: -90.005196,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.7703,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16408,
    shape_pt_lon: -90.004269,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.8551,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164057,
    shape_pt_lon: -90.004031,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.8772,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163936,
    shape_pt_lon: -90.002817,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.987,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16382,
    shape_pt_lon: -90.001687,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.0899,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163731,
    shape_pt_lon: -90.000659,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.1834,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163656,
    shape_pt_lon: -89.999998,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.2439,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.1636,
    shape_pt_lon: -89.999521,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.2874,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163479,
    shape_pt_lon: -89.998337,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3953,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163361,
    shape_pt_lon: -89.997173,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.5011,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -89.996731,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.5413,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163296,
    shape_pt_lon: -89.996369,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.5744,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163252,
    shape_pt_lon: -89.995958,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.6117,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163189,
    shape_pt_lon: -89.99534,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.6682,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163111,
    shape_pt_lon: -89.994709,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.7257,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16298,
    shape_pt_lon: -89.993437,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.8417,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162957,
    shape_pt_lon: -89.993208,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.8619,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162851,
    shape_pt_lon: -89.992185,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.9556,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162842,
    shape_pt_lon: -89.992093,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.9636,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162723,
    shape_pt_lon: -89.990932,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.0696,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162598,
    shape_pt_lon: -89.989674,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.1844,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162589,
    shape_pt_lon: -89.989588,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.1915,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16235,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.4091,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162339,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.4201,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162269,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.4787,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162208,
    shape_pt_lon: -89.986163,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.5046,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162146,
    shape_pt_lon: -89.985911,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.5287,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162112,
    shape_pt_lon: -89.985674,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.55,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162113,
    shape_pt_lon: -89.985513,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5641,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162144,
    shape_pt_lon: -89.985288,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.5853,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162298,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.6593,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162321,
    shape_pt_lon: -89.984197,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.6854,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162322,
    shape_pt_lon: -89.984057,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.6984,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163152,
    shape_pt_lon: -89.984023,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.7915,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163662,
    shape_pt_lon: -89.984,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.8485,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163981,
    shape_pt_lon: -89.983984,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.8845,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164515,
    shape_pt_lon: -89.983969,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.9436,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164834,
    shape_pt_lon: -89.983948,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.9796,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165227,
    shape_pt_lon: -89.983921,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.0237,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165718,
    shape_pt_lon: -89.983893,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.0787,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.166502,
    shape_pt_lon: -89.98386,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.1668,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.167168,
    shape_pt_lon: -89.983832,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.2408,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.167267,
    shape_pt_lon: -89.983828,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.2518,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.167912,
    shape_pt_lon: -89.98381,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.3248,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.167993,
    shape_pt_lon: -89.983813,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.3339,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.168679,
    shape_pt_lon: -89.983788,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.41,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.169197,
    shape_pt_lon: -89.983766,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.468,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16977,
    shape_pt_lon: -89.983742,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.532,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.170075,
    shape_pt_lon: -89.98373,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.567,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.170526,
    shape_pt_lon: -89.983711,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.617,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.170873,
    shape_pt_lon: -89.983695,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.6561,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.171316,
    shape_pt_lon: -89.983677,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.7051,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.171679,
    shape_pt_lon: -89.983661,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.7462,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.171889,
    shape_pt_lon: -89.983628,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.7703,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.172029,
    shape_pt_lon: -89.983608,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.7855,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174346,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.0455,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174402,
    shape_pt_lon: -89.983542,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.0516,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174456,
    shape_pt_lon: -89.983571,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.058,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0644,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.0869,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.1593,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.1765,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.2435,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.2596,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.3136,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.4137,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.4887,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.5958,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.7009,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.798,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.808,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.8141,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.8231,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8651,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.9131,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.9861,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.0021,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.0911,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.1482,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.2272,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.2912,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.3782,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.4332,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.4592,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.5242,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.5392,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.6183,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.7073,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.8023,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.8143,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.8944,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.9544,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.0424,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.0764,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.1334,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.2434,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.2944,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.3885,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.5525,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.5766,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.6693,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157942,
    shape_pt_lon: -89.975988,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.6744,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.975997,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.6794,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157906,
    shape_pt_lon: -89.975006,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.7685,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157909,
    shape_pt_lon: -89.973988,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.8605,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15791,
    shape_pt_lon: -89.973728,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.8835,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157919,
    shape_pt_lon: -89.972663,
    shape_pt_sequence: 201,
    shape_dist_traveled: 10.9805,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157922,
    shape_pt_lon: -89.972376,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.0065,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157932,
    shape_pt_lon: -89.971913,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.0475,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157961,
    shape_pt_lon: -89.971649,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.0717,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157979,
    shape_pt_lon: -89.971554,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.0809,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158026,
    shape_pt_lon: -89.971354,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.0996,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158163,
    shape_pt_lon: -89.970781,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.154,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158486,
    shape_pt_lon: -89.969401,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.2831,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158709,
    shape_pt_lon: -89.968425,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.3746,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158767,
    shape_pt_lon: -89.968172,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.3986,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159033,
    shape_pt_lon: -89.967029,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.5059,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159128,
    shape_pt_lon: -89.966613,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.5452,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159305,
    shape_pt_lon: -89.965851,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.617,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15972,
    shape_pt_lon: -89.964035,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.7876,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159738,
    shape_pt_lon: -89.963937,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.7969,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159766,
    shape_pt_lon: -89.96378,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.8112,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159962,
    shape_pt_lon: -89.962932,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.8903,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16002,
    shape_pt_lon: -89.962741,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.9093,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160031,
    shape_pt_lon: -89.962697,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.9137,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160131,
    shape_pt_lon: -89.962361,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.9457,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160482,
    shape_pt_lon: -89.961376,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.0429,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161037,
    shape_pt_lon: -89.959828,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.196,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161351,
    shape_pt_lon: -89.958953,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.2828,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161696,
    shape_pt_lon: -89.957985,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.3777,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161883,
    shape_pt_lon: -89.957507,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.4256,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162038,
    shape_pt_lon: -89.957158,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.4623,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.956806,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.4995,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162461,
    shape_pt_lon: -89.956345,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.5492,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162681,
    shape_pt_lon: -89.955979,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.5906,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162713,
    shape_pt_lon: -89.955929,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.5964,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163019,
    shape_pt_lon: -89.955451,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.6518,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164066,
    shape_pt_lon: -89.953818,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.8397,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164105,
    shape_pt_lon: -89.953755,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.8469,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.164459,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.911,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163179,
    shape_pt_lon: -89.950962,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.1585,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.950821,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.1732,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162861,
    shape_pt_lon: -89.949555,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.2906,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162576,
    shape_pt_lon: -89.948104,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.4264,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.5283,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162017,
    shape_pt_lon: -89.945251,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.6908,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161756,
    shape_pt_lon: -89.944046,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.8036,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161538,
    shape_pt_lon: -89.942891,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.9115,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161448,
    shape_pt_lon: -89.942402,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.9567,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16134,
    shape_pt_lon: -89.941818,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.01,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16125,
    shape_pt_lon: -89.941332,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.0551,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16113,
    shape_pt_lon: -89.94066,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.1175,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161072,
    shape_pt_lon: -89.940385,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.1432,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160938,
    shape_pt_lon: -89.939612,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.2148,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161489,
    shape_pt_lon: -89.939568,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.2759,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.939483,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.3892,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162505,
    shape_pt_lon: -89.939352,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.4012,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.938798,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.4512,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162465,
    shape_pt_lon: -89.937429,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.5752,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.934857,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.8073,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162389,
    shape_pt_lon: -89.933641,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.9173,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16237,
    shape_pt_lon: -89.932579,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.0134,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162348,
    shape_pt_lon: -89.93129,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.1294,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162329,
    shape_pt_lon: -89.930287,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.2204,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162293,
    shape_pt_lon: -89.928736,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.3605,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162259,
    shape_pt_lon: -89.927259,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.4935,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162248,
    shape_pt_lon: -89.926816,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.5335,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162238,
    shape_pt_lon: -89.926421,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.5695,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162229,
    shape_pt_lon: -89.926081,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.6006,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.92518,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.6816,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162196,
    shape_pt_lon: -89.924549,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.7386,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162179,
    shape_pt_lon: -89.923849,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.8016,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162167,
    shape_pt_lon: -89.923343,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.8477,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162147,
    shape_pt_lon: -89.922561,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.9187,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162115,
    shape_pt_lon: -89.921213,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.0407,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.1621,
    shape_pt_lon: -89.920656,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.0908,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162066,
    shape_pt_lon: -89.919163,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.2258,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.162057,
    shape_pt_lon: -89.918828,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.2558,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.3899,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.5509,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.6409,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.6549,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.762,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.938,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.071,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.0901,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.109,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.1198,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.1355,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.1391,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.1535,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.1904,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.1996,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.2145,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.2284,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.2451,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.2613,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.3263,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.4654,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.4714,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160909,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.4985,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160945,
    shape_pt_lon: -89.904863,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.5596,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161016,
    shape_pt_lon: -89.904213,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.6182,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16103,
    shape_pt_lon: -89.903879,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.6492,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.161018,
    shape_pt_lon: -89.903547,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.6792,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160971,
    shape_pt_lon: -89.903229,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.7077,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.16074,
    shape_pt_lon: -89.902387,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.788,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160479,
    shape_pt_lon: -89.901456,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.8778,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.160157,
    shape_pt_lon: -89.900316,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.9869,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159889,
    shape_pt_lon: -89.89937,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.0771,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159573,
    shape_pt_lon: -89.898267,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.183,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159433,
    shape_pt_lon: -89.897812,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.227,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159391,
    shape_pt_lon: -89.897726,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.236,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159316,
    shape_pt_lon: -89.897637,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.248,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159288,
    shape_pt_lon: -89.897616,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.2516,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.159172,
    shape_pt_lon: -89.897533,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.2664,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158971,
    shape_pt_lon: -89.897386,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.2919,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158896,
    shape_pt_lon: -89.897306,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.304,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158851,
    shape_pt_lon: -89.897256,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.3104,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15874,
    shape_pt_lon: -89.89705,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.3329,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15867,
    shape_pt_lon: -89.896857,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.3517,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158663,
    shape_pt_lon: -89.896825,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.3548,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158647,
    shape_pt_lon: -89.89674,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.3629,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158636,
    shape_pt_lon: -89.896631,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.3731,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158619,
    shape_pt_lon: -89.89638,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.3952,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.896166,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.4153,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158552,
    shape_pt_lon: -89.895961,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.4339,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158498,
    shape_pt_lon: -89.895781,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.452,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158423,
    shape_pt_lon: -89.895591,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.4708,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158379,
    shape_pt_lon: -89.895504,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.4802,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.158133,
    shape_pt_lon: -89.895108,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.525,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157605,
    shape_pt_lon: -89.89439,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.6128,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156941,
    shape_pt_lon: -89.893488,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.7233,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157605,
    shape_pt_lon: -89.89439,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.8337,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.157605,
    shape_pt_lon: -89.89439,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.9442,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156706,
    shape_pt_lon: -89.893168,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.9831,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156538,
    shape_pt_lon: -89.89296,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.01,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156462,
    shape_pt_lon: -89.892877,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.0206,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.156236,
    shape_pt_lon: -89.892689,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.0517,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15616,
    shape_pt_lon: -89.892633,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.0611,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155933,
    shape_pt_lon: -89.892444,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.0919,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155878,
    shape_pt_lon: -89.892387,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.1005,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.155738,
    shape_pt_lon: -89.892221,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.1217,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.154627,
    shape_pt_lon: -89.89072,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.3058,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15438,
    shape_pt_lon: -89.890388,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.3468,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.15414,
    shape_pt_lon: -89.890062,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.3864,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.153068,
    shape_pt_lon: -89.888605,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.5642,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.151715,
    shape_pt_lon: -89.890148,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.7701,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.151321,
    shape_pt_lon: -89.890635,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.8324,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.151129,
    shape_pt_lon: -89.890898,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.8649,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.150734,
    shape_pt_lon: -89.891479,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.933,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.150329,
    shape_pt_lon: -89.892155,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.0088,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.150129,
    shape_pt_lon: -89.892542,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.0507,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.150031,
    shape_pt_lon: -89.892731,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.071,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.14967,
    shape_pt_lon: -89.893519,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.1525,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.1494,
    shape_pt_lon: -89.894225,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.2236,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.149275,
    shape_pt_lon: -89.894597,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.2603,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.149065,
    shape_pt_lon: -89.8953,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.3274,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.14891,
    shape_pt_lon: -89.895916,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.3862,
  },
  {
    shape_id: 38198,
    shape_pt_lat: 35.148779,
    shape_pt_lon: -89.89656,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.4461,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200264,
    shape_pt_lon: -89.78321,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200255,
    shape_pt_lon: -89.783005,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.019,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200293,
    shape_pt_lon: -89.782613,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0544,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200337,
    shape_pt_lon: -89.782396,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.075,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20041,
    shape_pt_lon: -89.782162,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0975,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20055,
    shape_pt_lon: -89.781857,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1289,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200686,
    shape_pt_lon: -89.781637,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1539,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200787,
    shape_pt_lon: -89.781513,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1694,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.2011,
    shape_pt_lon: -89.781218,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2136,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.201297,
    shape_pt_lon: -89.781008,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2434,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20151,
    shape_pt_lon: -89.78068,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.2805,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.201631,
    shape_pt_lon: -89.780423,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3074,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.201668,
    shape_pt_lon: -89.780311,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.3191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.202431,
    shape_pt_lon: -89.778698,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4877,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.202592,
    shape_pt_lon: -89.778407,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5193,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.202782,
    shape_pt_lon: -89.778181,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5483,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.202979,
    shape_pt_lon: -89.778008,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5763,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203174,
    shape_pt_lon: -89.777883,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203337,
    shape_pt_lon: -89.777806,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.6203,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203496,
    shape_pt_lon: -89.777759,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.6387,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203717,
    shape_pt_lon: -89.777721,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.663,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.77771,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.724,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204359,
    shape_pt_lon: -89.77771,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.735,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204415,
    shape_pt_lon: -89.777708,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.7411,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20442,
    shape_pt_lon: -89.77793,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.7612,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204419,
    shape_pt_lon: -89.778731,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.8342,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204422,
    shape_pt_lon: -89.779721,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.9232,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204419,
    shape_pt_lon: -89.781027,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.0412,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20443,
    shape_pt_lon: -89.782169,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1442,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204441,
    shape_pt_lon: -89.78327,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.2442,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204459,
    shape_pt_lon: -89.784748,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.3772,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20446,
    shape_pt_lon: -89.78482,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.3832,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.20446,
    shape_pt_lon: -89.784889,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3902,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204461,
    shape_pt_lon: -89.785303,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4272,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204497,
    shape_pt_lon: -89.788467,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7132,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204497,
    shape_pt_lon: -89.78861,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7262,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204498,
    shape_pt_lon: -89.788736,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7372,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204523,
    shape_pt_lon: -89.790641,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9092,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204597,
    shape_pt_lon: -89.791948,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0275,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.2046,
    shape_pt_lon: -89.792011,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0335,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204527,
    shape_pt_lon: -89.792021,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0415,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204416,
    shape_pt_lon: -89.792032,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0536,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204333,
    shape_pt_lon: -89.792047,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0636,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.204262,
    shape_pt_lon: -89.792108,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0731,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203968,
    shape_pt_lon: -89.792167,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1066,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.203138,
    shape_pt_lon: -89.792198,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1986,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.202791,
    shape_pt_lon: -89.79221,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2376,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.201764,
    shape_pt_lon: -89.792246,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3527,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200347,
    shape_pt_lon: -89.7923,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5117,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.200005,
    shape_pt_lon: -89.792322,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5498,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.199812,
    shape_pt_lon: -89.792321,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5718,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.199484,
    shape_pt_lon: -89.792343,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.6078,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.198989,
    shape_pt_lon: -89.792423,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.6643,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.198707,
    shape_pt_lon: -89.792427,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.6953,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.198314,
    shape_pt_lon: -89.792423,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.7393,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.198064,
    shape_pt_lon: -89.792408,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7673,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.197868,
    shape_pt_lon: -89.792406,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7893,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.197687,
    shape_pt_lon: -89.792405,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8093,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.197298,
    shape_pt_lon: -89.792406,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.8533,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.196521,
    shape_pt_lon: -89.792426,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9404,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.19614,
    shape_pt_lon: -89.792439,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.9834,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.19587,
    shape_pt_lon: -89.792451,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.0134,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.195449,
    shape_pt_lon: -89.79247,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.0604,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.195185,
    shape_pt_lon: -89.792474,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.0894,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.195096,
    shape_pt_lon: -89.792479,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0995,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.194983,
    shape_pt_lon: -89.792483,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.1125,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.194932,
    shape_pt_lon: -89.792485,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.1185,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.194872,
    shape_pt_lon: -89.792486,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.1255,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.194709,
    shape_pt_lon: -89.792491,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.1435,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.194137,
    shape_pt_lon: -89.792511,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.2075,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.193472,
    shape_pt_lon: -89.792537,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.2816,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.192954,
    shape_pt_lon: -89.792548,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.3396,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.191891,
    shape_pt_lon: -89.792585,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.4586,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.191643,
    shape_pt_lon: -89.792598,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.4866,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.191369,
    shape_pt_lon: -89.792609,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.5176,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.188422,
    shape_pt_lon: -89.792699,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.8467,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.188431,
    shape_pt_lon: -89.793839,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9487,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.188435,
    shape_pt_lon: -89.795588,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.1067,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.188439,
    shape_pt_lon: -89.79715,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.2477,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.187259,
    shape_pt_lon: -89.79711,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3798,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185246,
    shape_pt_lon: -89.79704,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.6049,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185216,
    shape_pt_lon: -89.795625,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.7329,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185195,
    shape_pt_lon: -89.795283,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.764,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185151,
    shape_pt_lon: -89.792791,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.989,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183353,
    shape_pt_lon: -89.79284,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18234,
    shape_pt_lon: -89.792886,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.3041,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.181899,
    shape_pt_lon: -89.792904,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.3531,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18186,
    shape_pt_lon: -89.792907,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.3581,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.181838,
    shape_pt_lon: -89.792908,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.3601,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.181462,
    shape_pt_lon: -89.792926,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.4022,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.181058,
    shape_pt_lon: -89.792943,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.4472,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179893,
    shape_pt_lon: -89.792992,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.5782,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178869,
    shape_pt_lon: -89.793029,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.6923,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177959,
    shape_pt_lon: -89.793031,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.7943,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17746,
    shape_pt_lon: -89.793039,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.8503,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175678,
    shape_pt_lon: -89.79306,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.0503,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175478,
    shape_pt_lon: -89.79306,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.0723,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173318,
    shape_pt_lon: -89.793083,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.3143,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173293,
    shape_pt_lon: -89.793889,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.3874,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173242,
    shape_pt_lon: -89.794119,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.4089,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173108,
    shape_pt_lon: -89.794789,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.471,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173054,
    shape_pt_lon: -89.79499,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.49,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172833,
    shape_pt_lon: -89.795862,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.5729,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17275,
    shape_pt_lon: -89.796309,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.6139,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172716,
    shape_pt_lon: -89.796561,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.6372,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172664,
    shape_pt_lon: -89.797087,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.6856,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172621,
    shape_pt_lon: -89.797409,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.715,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172598,
    shape_pt_lon: -89.797548,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.7272,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172375,
    shape_pt_lon: -89.798892,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.8519,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172341,
    shape_pt_lon: -89.799168,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.8772,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17229,
    shape_pt_lon: -89.799712,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.9265,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172273,
    shape_pt_lon: -89.799911,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.9446,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172236,
    shape_pt_lon: -89.800425,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.9909,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172231,
    shape_pt_lon: -89.800692,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.0149,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172239,
    shape_pt_lon: -89.801152,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.0569,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172252,
    shape_pt_lon: -89.801929,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.1269,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17227,
    shape_pt_lon: -89.802261,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.157,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172277,
    shape_pt_lon: -89.80237,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.167,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172285,
    shape_pt_lon: -89.803412,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.261,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172297,
    shape_pt_lon: -89.803933,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.308,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172307,
    shape_pt_lon: -89.804249,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.336,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172313,
    shape_pt_lon: -89.804819,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.388,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172346,
    shape_pt_lon: -89.805537,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.4531,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172329,
    shape_pt_lon: -89.805793,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.4762,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172279,
    shape_pt_lon: -89.806021,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.4968,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172189,
    shape_pt_lon: -89.80628,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.5228,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172063,
    shape_pt_lon: -89.806533,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.5502,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171866,
    shape_pt_lon: -89.806868,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.5874,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171712,
    shape_pt_lon: -89.807144,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.6177,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171589,
    shape_pt_lon: -89.807425,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.6463,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17112,
    shape_pt_lon: -89.808769,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.7784,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170939,
    shape_pt_lon: -89.8093,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.8304,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170855,
    shape_pt_lon: -89.809644,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.8627,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170829,
    shape_pt_lon: -89.809887,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.8849,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170828,
    shape_pt_lon: -89.810129,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.9069,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170839,
    shape_pt_lon: -89.810258,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.9191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17089,
    shape_pt_lon: -89.810506,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.9417,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171032,
    shape_pt_lon: -89.810974,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.9866,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171056,
    shape_pt_lon: -89.811046,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.9942,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17116,
    shape_pt_lon: -89.811361,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.0243,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171211,
    shape_pt_lon: -89.81153,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.0414,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171323,
    shape_pt_lon: -89.811912,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.0774,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171383,
    shape_pt_lon: -89.812116,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.0977,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171501,
    shape_pt_lon: -89.812488,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.1332,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171675,
    shape_pt_lon: -89.813,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.1839,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171762,
    shape_pt_lon: -89.813289,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.2117,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171797,
    shape_pt_lon: -89.813415,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.2234,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17189,
    shape_pt_lon: -89.813819,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.2617,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171909,
    shape_pt_lon: -89.813909,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.27,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171968,
    shape_pt_lon: -89.814203,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.2969,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17205,
    shape_pt_lon: -89.814639,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.3379,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173975,
    shape_pt_lon: -89.814157,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.5582,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.813968,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.6468,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176058,
    shape_pt_lon: -89.813651,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.7955,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177333,
    shape_pt_lon: -89.813338,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.9412,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179349,
    shape_pt_lon: -89.81285,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.1704,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17945,
    shape_pt_lon: -89.812824,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.1826,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179431,
    shape_pt_lon: -89.81295,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.194,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179417,
    shape_pt_lon: -89.813051,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2041,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17965,
    shape_pt_lon: -89.815749,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.4483,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179673,
    shape_pt_lon: -89.816046,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.4755,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179699,
    shape_pt_lon: -89.816261,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.4957,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17975,
    shape_pt_lon: -89.816529,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.5202,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179977,
    shape_pt_lon: -89.81732,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.5955,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180287,
    shape_pt_lon: -89.818387,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.6986,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18036,
    shape_pt_lon: -89.818662,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.7249,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180388,
    shape_pt_lon: -89.8188,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.7373,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180433,
    shape_pt_lon: -89.819178,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.7716,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180433,
    shape_pt_lon: -89.819551,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.8056,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180404,
    shape_pt_lon: -89.81984,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.8318,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180347,
    shape_pt_lon: -89.82014,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.8597,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179879,
    shape_pt_lon: -89.821845,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.0226,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179757,
    shape_pt_lon: -89.822143,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.0525,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179716,
    shape_pt_lon: -89.822226,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.0611,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17964,
    shape_pt_lon: -89.82237,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.0764,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179442,
    shape_pt_lon: -89.822661,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.1111,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179234,
    shape_pt_lon: -89.8229,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.1429,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179015,
    shape_pt_lon: -89.823095,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.1732,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178812,
    shape_pt_lon: -89.823237,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.1987,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178786,
    shape_pt_lon: -89.823253,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.2023,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178536,
    shape_pt_lon: -89.82338,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.2324,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178218,
    shape_pt_lon: -89.823483,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.2695,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178038,
    shape_pt_lon: -89.823514,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.2897,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177163,
    shape_pt_lon: -89.823577,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.3879,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176741,
    shape_pt_lon: -89.823608,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.435,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176698,
    shape_pt_lon: -89.823641,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.4408,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17664,
    shape_pt_lon: -89.823654,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.4469,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176589,
    shape_pt_lon: -89.82367,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.4532,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17648,
    shape_pt_lon: -89.8237,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.4654,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176256,
    shape_pt_lon: -89.823778,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.4913,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176103,
    shape_pt_lon: -89.82385,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.5106,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175871,
    shape_pt_lon: -89.823989,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.5393,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175703,
    shape_pt_lon: -89.824117,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.5609,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175476,
    shape_pt_lon: -89.82433,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.5931,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175336,
    shape_pt_lon: -89.824492,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.615,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175313,
    shape_pt_lon: -89.824518,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.6179,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175114,
    shape_pt_lon: -89.824806,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.6526,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174992,
    shape_pt_lon: -89.825033,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.6764,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174889,
    shape_pt_lon: -89.825272,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.7015,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174803,
    shape_pt_lon: -89.82551,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.7243,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17475,
    shape_pt_lon: -89.825713,
    shape_pt_sequence: 201,
    shape_dist_traveled: 10.7443,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.82605,
    shape_pt_sequence: 202,
    shape_dist_traveled: 10.7753,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174556,
    shape_pt_lon: -89.827307,
    shape_pt_sequence: 203,
    shape_dist_traveled: 10.8903,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174517,
    shape_pt_lon: -89.827561,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.9136,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174421,
    shape_pt_lon: -89.827961,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.9513,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174372,
    shape_pt_lon: -89.828018,
    shape_pt_sequence: 206,
    shape_dist_traveled: 10.9584,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174347,
    shape_pt_lon: -89.828156,
    shape_pt_sequence: 207,
    shape_dist_traveled: 10.9707,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17432,
    shape_pt_lon: -89.828263,
    shape_pt_sequence: 208,
    shape_dist_traveled: 10.9812,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174191,
    shape_pt_lon: -89.828746,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.0267,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.828998,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.0518,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173963,
    shape_pt_lon: -89.82928,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.0818,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173779,
    shape_pt_lon: -89.829569,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.1152,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173351,
    shape_pt_lon: -89.830088,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.1817,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17315,
    shape_pt_lon: -89.83038,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.2172,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173061,
    shape_pt_lon: -89.830556,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.236,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172951,
    shape_pt_lon: -89.830817,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.262,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172854,
    shape_pt_lon: -89.831108,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.2902,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172741,
    shape_pt_lon: -89.831618,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.338,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172677,
    shape_pt_lon: -89.831918,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.3669,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172616,
    shape_pt_lon: -89.832274,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.3996,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172578,
    shape_pt_lon: -89.832567,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.4259,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172603,
    shape_pt_lon: -89.832637,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.4327,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172539,
    shape_pt_lon: -89.833181,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.4833,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172482,
    shape_pt_lon: -89.833458,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.509,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17235,
    shape_pt_lon: -89.833859,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.548,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172242,
    shape_pt_lon: -89.8341,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.5722,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17217,
    shape_pt_lon: -89.834228,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.5866,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171853,
    shape_pt_lon: -89.834763,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.6466,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171746,
    shape_pt_lon: -89.835,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.6717,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171629,
    shape_pt_lon: -89.83536,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.7062,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17159,
    shape_pt_lon: -89.8355,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.7198,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171538,
    shape_pt_lon: -89.83591,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.7573,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171523,
    shape_pt_lon: -89.83608,
    shape_pt_sequence: 233,
    shape_dist_traveled: 11.7724,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171519,
    shape_pt_lon: -89.836291,
    shape_pt_sequence: 234,
    shape_dist_traveled: 11.7914,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17153,
    shape_pt_lon: -89.836639,
    shape_pt_sequence: 235,
    shape_dist_traveled: 11.8234,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1735,
    shape_pt_lon: -89.836533,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.0436,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174175,
    shape_pt_lon: -89.836493,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.1197,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17435,
    shape_pt_lon: -89.836482,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.1388,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175116,
    shape_pt_lon: -89.836436,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.2248,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175252,
    shape_pt_lon: -89.836428,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.2398,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175339,
    shape_pt_lon: -89.836423,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.2499,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176186,
    shape_pt_lon: -89.836378,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.345,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176493,
    shape_pt_lon: -89.836361,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.379,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177151,
    shape_pt_lon: -89.836327,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.4531,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177791,
    shape_pt_lon: -89.836303,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.5241,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178579,
    shape_pt_lon: -89.836273,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.6131,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17852,
    shape_pt_lon: -89.837101,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.6885,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178372,
    shape_pt_lon: -89.837729,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.7479,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178195,
    shape_pt_lon: -89.838202,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.7954,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177848,
    shape_pt_lon: -89.839243,
    shape_pt_sequence: 250,
    shape_dist_traveled: 12.8962,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17766,
    shape_pt_lon: -89.839958,
    shape_pt_sequence: 251,
    shape_dist_traveled: 12.9645,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17756,
    shape_pt_lon: -89.841036,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.0621,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177412,
    shape_pt_lon: -89.842512,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.1972,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177303,
    shape_pt_lon: -89.844136,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.3438,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177313,
    shape_pt_lon: -89.845238,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.4438,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177432,
    shape_pt_lon: -89.846073,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.5199,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17757,
    shape_pt_lon: -89.846546,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.5655,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177828,
    shape_pt_lon: -89.847053,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.6198,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178795,
    shape_pt_lon: -89.848637,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.799,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178995,
    shape_pt_lon: -89.848967,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.8362,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179047,
    shape_pt_lon: -89.849065,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.8471,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179372,
    shape_pt_lon: -89.84967,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.9128,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179497,
    shape_pt_lon: -89.849978,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.9432,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179684,
    shape_pt_lon: -89.85062,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.0049,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179791,
    shape_pt_lon: -89.85104,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.0447,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179853,
    shape_pt_lon: -89.851339,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.0726,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179893,
    shape_pt_lon: -89.851647,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.1009,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179898,
    shape_pt_lon: -89.8517,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.106,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179928,
    shape_pt_lon: -89.852353,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.1651,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179959,
    shape_pt_lon: -89.853203,
    shape_pt_sequence: 270,
    shape_dist_traveled: 14.2422,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179996,
    shape_pt_lon: -89.854181,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.3302,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180003,
    shape_pt_lon: -89.854593,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.3673,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180001,
    shape_pt_lon: -89.854646,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.3723,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179979,
    shape_pt_lon: -89.855108,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.4144,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179977,
    shape_pt_lon: -89.855598,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.4584,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179994,
    shape_pt_lon: -89.856178,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.5104,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180002,
    shape_pt_lon: -89.856331,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.5244,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179281,
    shape_pt_lon: -89.856351,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.6055,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178949,
    shape_pt_lon: -89.856351,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.6425,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17873,
    shape_pt_lon: -89.856323,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.6676,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178382,
    shape_pt_lon: -89.856226,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.7077,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.178075,
    shape_pt_lon: -89.856071,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.7444,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177972,
    shape_pt_lon: -89.856008,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.7565,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17775,
    shape_pt_lon: -89.855839,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.7862,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177069,
    shape_pt_lon: -89.855265,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.8783,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176856,
    shape_pt_lon: -89.855131,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.9051,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176769,
    shape_pt_lon: -89.855084,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.9159,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176533,
    shape_pt_lon: -89.854992,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.9434,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176295,
    shape_pt_lon: -89.854936,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.9709,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176093,
    shape_pt_lon: -89.85491,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.9939,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176036,
    shape_pt_lon: -89.854909,
    shape_pt_sequence: 291,
    shape_dist_traveled: 14.9999,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175982,
    shape_pt_lon: -89.854908,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.0059,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175695,
    shape_pt_lon: -89.854928,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.038,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175369,
    shape_pt_lon: -89.855011,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.0758,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175143,
    shape_pt_lon: -89.855103,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.1021,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174919,
    shape_pt_lon: -89.855207,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.1286,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174641,
    shape_pt_lon: -89.855321,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.1615,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174301,
    shape_pt_lon: -89.855393,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.2,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174011,
    shape_pt_lon: -89.855403,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.233,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173726,
    shape_pt_lon: -89.855371,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.2652,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17348,
    shape_pt_lon: -89.855309,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.2928,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173003,
    shape_pt_lon: -89.855131,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.3482,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172826,
    shape_pt_lon: -89.855081,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.3686,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17262,
    shape_pt_lon: -89.855057,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.3918,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17221,
    shape_pt_lon: -89.85506,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.4378,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171265,
    shape_pt_lon: -89.855089,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.5438,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17056,
    shape_pt_lon: -89.855112,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.6228,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170434,
    shape_pt_lon: -89.85512,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.6368,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170296,
    shape_pt_lon: -89.855141,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.652,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170121,
    shape_pt_lon: -89.8552,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.6729,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16991,
    shape_pt_lon: -89.855306,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.6985,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169742,
    shape_pt_lon: -89.855418,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.7191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169556,
    shape_pt_lon: -89.855591,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.7455,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169351,
    shape_pt_lon: -89.855846,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.778,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169249,
    shape_pt_lon: -89.856019,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.7972,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169159,
    shape_pt_lon: -89.85621,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.8178,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169086,
    shape_pt_lon: -89.856406,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.8366,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16902,
    shape_pt_lon: -89.856659,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.8606,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168927,
    shape_pt_lon: -89.857226,
    shape_pt_sequence: 319,
    shape_dist_traveled: 15.9128,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168863,
    shape_pt_lon: -89.8575,
    shape_pt_sequence: 320,
    shape_dist_traveled: 15.9388,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168773,
    shape_pt_lon: -89.857769,
    shape_pt_sequence: 321,
    shape_dist_traveled: 15.9648,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16863,
    shape_pt_lon: -89.858088,
    shape_pt_sequence: 322,
    shape_dist_traveled: 15.9979,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16826,
    shape_pt_lon: -89.858718,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.0687,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167999,
    shape_pt_lon: -89.85917,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.1189,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167908,
    shape_pt_lon: -89.85934,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.1369,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167877,
    shape_pt_lon: -89.859406,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.1441,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167828,
    shape_pt_lon: -89.859446,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.1506,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16779,
    shape_pt_lon: -89.859535,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.16,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167724,
    shape_pt_lon: -89.85975,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.1802,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167676,
    shape_pt_lon: -89.86001,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.2038,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167672,
    shape_pt_lon: -89.86026,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.2268,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167711,
    shape_pt_lon: -89.860727,
    shape_pt_sequence: 332,
    shape_dist_traveled: 16.269,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167766,
    shape_pt_lon: -89.861317,
    shape_pt_sequence: 333,
    shape_dist_traveled: 16.3223,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167852,
    shape_pt_lon: -89.862297,
    shape_pt_sequence: 334,
    shape_dist_traveled: 16.4119,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167888,
    shape_pt_lon: -89.862448,
    shape_pt_sequence: 335,
    shape_dist_traveled: 16.4264,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167939,
    shape_pt_lon: -89.862581,
    shape_pt_sequence: 336,
    shape_dist_traveled: 16.4394,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168007,
    shape_pt_lon: -89.862708,
    shape_pt_sequence: 337,
    shape_dist_traveled: 16.453,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168078,
    shape_pt_lon: -89.862806,
    shape_pt_sequence: 338,
    shape_dist_traveled: 16.4651,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168356,
    shape_pt_lon: -89.86307,
    shape_pt_sequence: 339,
    shape_dist_traveled: 16.5043,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168472,
    shape_pt_lon: -89.863198,
    shape_pt_sequence: 340,
    shape_dist_traveled: 16.522,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168537,
    shape_pt_lon: -89.863317,
    shape_pt_sequence: 341,
    shape_dist_traveled: 16.5342,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168613,
    shape_pt_lon: -89.863371,
    shape_pt_sequence: 342,
    shape_dist_traveled: 16.5445,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168671,
    shape_pt_lon: -89.863563,
    shape_pt_sequence: 343,
    shape_dist_traveled: 16.5635,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168689,
    shape_pt_lon: -89.863703,
    shape_pt_sequence: 344,
    shape_dist_traveled: 16.5756,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168689,
    shape_pt_lon: -89.863801,
    shape_pt_sequence: 345,
    shape_dist_traveled: 16.5846,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168677,
    shape_pt_lon: -89.863902,
    shape_pt_sequence: 346,
    shape_dist_traveled: 16.5937,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168669,
    shape_pt_lon: -89.86394,
    shape_pt_sequence: 347,
    shape_dist_traveled: 16.5978,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16863,
    shape_pt_lon: -89.86406,
    shape_pt_sequence: 348,
    shape_dist_traveled: 16.6086,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168558,
    shape_pt_lon: -89.864201,
    shape_pt_sequence: 349,
    shape_dist_traveled: 16.6244,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168343,
    shape_pt_lon: -89.864508,
    shape_pt_sequence: 350,
    shape_dist_traveled: 16.6613,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168301,
    shape_pt_lon: -89.864563,
    shape_pt_sequence: 351,
    shape_dist_traveled: 16.6677,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168259,
    shape_pt_lon: -89.864519,
    shape_pt_sequence: 352,
    shape_dist_traveled: 16.6741,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 353,
    shape_dist_traveled: 16.6805,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 354,
    shape_dist_traveled: 16.6989,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 355,
    shape_dist_traveled: 16.7302,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 356,
    shape_dist_traveled: 16.7678,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 357,
    shape_dist_traveled: 16.8423,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 358,
    shape_dist_traveled: 16.885,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 359,
    shape_dist_traveled: 16.9318,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 360,
    shape_dist_traveled: 17.0057,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 361,
    shape_dist_traveled: 17.0759,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 362,
    shape_dist_traveled: 17.0859,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 363,
    shape_dist_traveled: 17.0937,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 364,
    shape_dist_traveled: 17.1037,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 365,
    shape_dist_traveled: 17.1171,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 366,
    shape_dist_traveled: 17.1313,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 367,
    shape_dist_traveled: 17.1854,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 368,
    shape_dist_traveled: 17.2415,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 369,
    shape_dist_traveled: 17.2473,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 370,
    shape_dist_traveled: 17.2847,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 371,
    shape_dist_traveled: 17.3009,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 372,
    shape_dist_traveled: 17.317,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 373,
    shape_dist_traveled: 17.3514,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 374,
    shape_dist_traveled: 17.3771,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 375,
    shape_dist_traveled: 17.4188,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162915,
    shape_pt_lon: -89.860091,
    shape_pt_sequence: 376,
    shape_dist_traveled: 17.4569,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162871,
    shape_pt_lon: -89.861203,
    shape_pt_sequence: 377,
    shape_dist_traveled: 17.557,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162873,
    shape_pt_lon: -89.861851,
    shape_pt_sequence: 378,
    shape_dist_traveled: 17.616,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162883,
    shape_pt_lon: -89.862371,
    shape_pt_sequence: 379,
    shape_dist_traveled: 17.663,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162891,
    shape_pt_lon: -89.862829,
    shape_pt_sequence: 380,
    shape_dist_traveled: 17.704,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162898,
    shape_pt_lon: -89.863111,
    shape_pt_sequence: 381,
    shape_dist_traveled: 17.729,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162901,
    shape_pt_lon: -89.863341,
    shape_pt_sequence: 382,
    shape_dist_traveled: 17.75,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162909,
    shape_pt_lon: -89.86375,
    shape_pt_sequence: 383,
    shape_dist_traveled: 17.787,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162623,
    shape_pt_lon: -89.86376,
    shape_pt_sequence: 384,
    shape_dist_traveled: 17.8191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161748,
    shape_pt_lon: -89.863795,
    shape_pt_sequence: 385,
    shape_dist_traveled: 17.9171,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160743,
    shape_pt_lon: -89.863833,
    shape_pt_sequence: 386,
    shape_dist_traveled: 18.0291,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160279,
    shape_pt_lon: -89.86385,
    shape_pt_sequence: 387,
    shape_dist_traveled: 18.0812,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160127,
    shape_pt_lon: -89.863857,
    shape_pt_sequence: 388,
    shape_dist_traveled: 18.0982,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15918,
    shape_pt_lon: -89.863897,
    shape_pt_sequence: 389,
    shape_dist_traveled: 18.2042,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.863902,
    shape_pt_sequence: 390,
    shape_dist_traveled: 18.2213,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158893,
    shape_pt_lon: -89.863853,
    shape_pt_sequence: 391,
    shape_dist_traveled: 18.2371,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158766,
    shape_pt_lon: -89.863767,
    shape_pt_sequence: 392,
    shape_dist_traveled: 18.2527,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158669,
    shape_pt_lon: -89.863657,
    shape_pt_sequence: 393,
    shape_dist_traveled: 18.2676,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158593,
    shape_pt_lon: -89.863506,
    shape_pt_sequence: 394,
    shape_dist_traveled: 18.2842,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158561,
    shape_pt_lon: -89.863408,
    shape_pt_sequence: 395,
    shape_dist_traveled: 18.2937,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158542,
    shape_pt_lon: -89.86328,
    shape_pt_sequence: 396,
    shape_dist_traveled: 18.3049,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158533,
    shape_pt_lon: -89.863165,
    shape_pt_sequence: 397,
    shape_dist_traveled: 18.3159,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.861991,
    shape_pt_sequence: 398,
    shape_dist_traveled: 18.4219,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.860467,
    shape_pt_sequence: 399,
    shape_dist_traveled: 18.5599,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859968,
    shape_pt_sequence: 400,
    shape_dist_traveled: 18.6055,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158597,
    shape_pt_lon: -89.859639,
    shape_pt_sequence: 401,
    shape_dist_traveled: 18.6345,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859551,
    shape_pt_sequence: 402,
    shape_dist_traveled: 18.6425,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859462,
    shape_pt_sequence: 403,
    shape_dist_traveled: 18.6505,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161506,
    shape_pt_lon: -89.859413,
    shape_pt_sequence: 404,
    shape_dist_traveled: 18.9755,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161819,
    shape_pt_lon: -89.859401,
    shape_pt_sequence: 405,
    shape_dist_traveled: 19.0105,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162047,
    shape_pt_lon: -89.859408,
    shape_pt_sequence: 406,
    shape_dist_traveled: 19.0365,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162247,
    shape_pt_lon: -89.859422,
    shape_pt_sequence: 407,
    shape_dist_traveled: 19.0586,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162433,
    shape_pt_lon: -89.859447,
    shape_pt_sequence: 408,
    shape_dist_traveled: 19.0797,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162574,
    shape_pt_lon: -89.859473,
    shape_pt_sequence: 409,
    shape_dist_traveled: 19.0959,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162696,
    shape_pt_lon: -89.859506,
    shape_pt_sequence: 410,
    shape_dist_traveled: 19.1093,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162815,
    shape_pt_lon: -89.859615,
    shape_pt_sequence: 411,
    shape_dist_traveled: 19.1265,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162857,
    shape_pt_lon: -89.859629,
    shape_pt_sequence: 412,
    shape_dist_traveled: 19.1306,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 413,
    shape_dist_traveled: 19.1405,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 414,
    shape_dist_traveled: 19.1822,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 415,
    shape_dist_traveled: 19.2079,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 416,
    shape_dist_traveled: 19.2423,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 417,
    shape_dist_traveled: 19.2584,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 418,
    shape_dist_traveled: 19.2745,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 419,
    shape_dist_traveled: 19.312,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 420,
    shape_dist_traveled: 19.3178,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 421,
    shape_dist_traveled: 19.3739,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 422,
    shape_dist_traveled: 19.4279,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 423,
    shape_dist_traveled: 19.4421,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 424,
    shape_dist_traveled: 19.4556,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 425,
    shape_dist_traveled: 19.4656,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 426,
    shape_dist_traveled: 19.4734,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 427,
    shape_dist_traveled: 19.4834,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 428,
    shape_dist_traveled: 19.5536,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 429,
    shape_dist_traveled: 19.6275,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 430,
    shape_dist_traveled: 19.6743,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 431,
    shape_dist_traveled: 19.717,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 432,
    shape_dist_traveled: 19.7915,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 433,
    shape_dist_traveled: 19.8291,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 434,
    shape_dist_traveled: 19.8604,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 435,
    shape_dist_traveled: 19.8788,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168259,
    shape_pt_lon: -89.864519,
    shape_pt_sequence: 436,
    shape_dist_traveled: 19.8852,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168301,
    shape_pt_lon: -89.864563,
    shape_pt_sequence: 437,
    shape_dist_traveled: 19.8916,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168745,
    shape_pt_lon: -89.865017,
    shape_pt_sequence: 438,
    shape_dist_traveled: 19.9555,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168888,
    shape_pt_lon: -89.865159,
    shape_pt_sequence: 439,
    shape_dist_traveled: 19.9761,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169003,
    shape_pt_lon: -89.865278,
    shape_pt_sequence: 440,
    shape_dist_traveled: 19.9931,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169202,
    shape_pt_lon: -89.865487,
    shape_pt_sequence: 441,
    shape_dist_traveled: 20.0216,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169427,
    shape_pt_lon: -89.865717,
    shape_pt_sequence: 442,
    shape_dist_traveled: 20.0542,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169582,
    shape_pt_lon: -89.86587,
    shape_pt_sequence: 443,
    shape_dist_traveled: 20.077,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169776,
    shape_pt_lon: -89.866075,
    shape_pt_sequence: 444,
    shape_dist_traveled: 20.1053,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170017,
    shape_pt_lon: -89.866321,
    shape_pt_sequence: 445,
    shape_dist_traveled: 20.1402,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171021,
    shape_pt_lon: -89.867358,
    shape_pt_sequence: 446,
    shape_dist_traveled: 20.2864,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171307,
    shape_pt_lon: -89.867653,
    shape_pt_sequence: 447,
    shape_dist_traveled: 20.3283,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171398,
    shape_pt_lon: -89.867747,
    shape_pt_sequence: 448,
    shape_dist_traveled: 20.3419,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172045,
    shape_pt_lon: -89.868408,
    shape_pt_sequence: 449,
    shape_dist_traveled: 20.4356,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172218,
    shape_pt_lon: -89.868588,
    shape_pt_sequence: 450,
    shape_dist_traveled: 20.4604,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172601,
    shape_pt_lon: -89.868948,
    shape_pt_sequence: 451,
    shape_dist_traveled: 20.5146,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17275,
    shape_pt_lon: -89.869064,
    shape_pt_sequence: 452,
    shape_dist_traveled: 20.5344,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173097,
    shape_pt_lon: -89.869326,
    shape_pt_sequence: 453,
    shape_dist_traveled: 20.5793,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173226,
    shape_pt_lon: -89.86942,
    shape_pt_sequence: 454,
    shape_dist_traveled: 20.5968,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173323,
    shape_pt_lon: -89.869415,
    shape_pt_sequence: 455,
    shape_dist_traveled: 20.6078,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173389,
    shape_pt_lon: -89.869462,
    shape_pt_sequence: 456,
    shape_dist_traveled: 20.6159,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173429,
    shape_pt_lon: -89.869489,
    shape_pt_sequence: 457,
    shape_dist_traveled: 20.6209,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17511,
    shape_pt_lon: -89.870617,
    shape_pt_sequence: 458,
    shape_dist_traveled: 20.8348,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175229,
    shape_pt_lon: -89.870697,
    shape_pt_sequence: 459,
    shape_dist_traveled: 20.8504,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175793,
    shape_pt_lon: -89.871056,
    shape_pt_sequence: 460,
    shape_dist_traveled: 20.9216,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175831,
    shape_pt_lon: -89.871077,
    shape_pt_sequence: 461,
    shape_dist_traveled: 20.926,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176056,
    shape_pt_lon: -89.871191,
    shape_pt_sequence: 462,
    shape_dist_traveled: 20.953,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176446,
    shape_pt_lon: -89.871341,
    shape_pt_sequence: 463,
    shape_dist_traveled: 20.9991,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176683,
    shape_pt_lon: -89.871403,
    shape_pt_sequence: 464,
    shape_dist_traveled: 21.0258,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176889,
    shape_pt_lon: -89.871444,
    shape_pt_sequence: 465,
    shape_dist_traveled: 21.049,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177221,
    shape_pt_lon: -89.871481,
    shape_pt_sequence: 466,
    shape_dist_traveled: 21.0862,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177774,
    shape_pt_lon: -89.871473,
    shape_pt_sequence: 467,
    shape_dist_traveled: 21.1482,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179053,
    shape_pt_lon: -89.871423,
    shape_pt_sequence: 468,
    shape_dist_traveled: 21.2913,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.182556,
    shape_pt_lon: -89.871298,
    shape_pt_sequence: 469,
    shape_dist_traveled: 21.6834,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.182819,
    shape_pt_lon: -89.871266,
    shape_pt_sequence: 470,
    shape_dist_traveled: 21.7135,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.182831,
    shape_pt_lon: -89.871422,
    shape_pt_sequence: 471,
    shape_dist_traveled: 21.7275,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.182996,
    shape_pt_lon: -89.873143,
    shape_pt_sequence: 472,
    shape_dist_traveled: 21.8836,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183015,
    shape_pt_lon: -89.873409,
    shape_pt_sequence: 473,
    shape_dist_traveled: 21.9077,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183068,
    shape_pt_lon: -89.874022,
    shape_pt_sequence: 474,
    shape_dist_traveled: 21.964,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183085,
    shape_pt_lon: -89.874223,
    shape_pt_sequence: 475,
    shape_dist_traveled: 21.9821,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183127,
    shape_pt_lon: -89.874548,
    shape_pt_sequence: 476,
    shape_dist_traveled: 22.0115,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183208,
    shape_pt_lon: -89.875027,
    shape_pt_sequence: 477,
    shape_dist_traveled: 22.0555,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183267,
    shape_pt_lon: -89.875397,
    shape_pt_sequence: 478,
    shape_dist_traveled: 22.09,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183368,
    shape_pt_lon: -89.875972,
    shape_pt_sequence: 479,
    shape_dist_traveled: 22.1431,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183456,
    shape_pt_lon: -89.876344,
    shape_pt_sequence: 480,
    shape_dist_traveled: 22.1776,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183529,
    shape_pt_lon: -89.876618,
    shape_pt_sequence: 481,
    shape_dist_traveled: 22.2039,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183696,
    shape_pt_lon: -89.87711,
    shape_pt_sequence: 482,
    shape_dist_traveled: 22.2527,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184027,
    shape_pt_lon: -89.878028,
    shape_pt_sequence: 483,
    shape_dist_traveled: 22.3436,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184339,
    shape_pt_lon: -89.878889,
    shape_pt_sequence: 484,
    shape_dist_traveled: 22.4282,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184489,
    shape_pt_lon: -89.879334,
    shape_pt_sequence: 485,
    shape_dist_traveled: 22.4722,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184609,
    shape_pt_lon: -89.879848,
    shape_pt_sequence: 486,
    shape_dist_traveled: 22.5203,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184678,
    shape_pt_lon: -89.880186,
    shape_pt_sequence: 487,
    shape_dist_traveled: 22.552,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184706,
    shape_pt_lon: -89.880392,
    shape_pt_sequence: 488,
    shape_dist_traveled: 22.5703,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184732,
    shape_pt_lon: -89.880691,
    shape_pt_sequence: 489,
    shape_dist_traveled: 22.5975,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184747,
    shape_pt_lon: -89.88089,
    shape_pt_sequence: 490,
    shape_dist_traveled: 22.6156,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18481,
    shape_pt_lon: -89.881869,
    shape_pt_sequence: 491,
    shape_dist_traveled: 22.7048,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184888,
    shape_pt_lon: -89.882541,
    shape_pt_sequence: 492,
    shape_dist_traveled: 22.7654,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184962,
    shape_pt_lon: -89.882961,
    shape_pt_sequence: 493,
    shape_dist_traveled: 22.8044,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185073,
    shape_pt_lon: -89.883456,
    shape_pt_sequence: 494,
    shape_dist_traveled: 22.851,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185292,
    shape_pt_lon: -89.884163,
    shape_pt_sequence: 495,
    shape_dist_traveled: 22.9193,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185511,
    shape_pt_lon: -89.884699,
    shape_pt_sequence: 496,
    shape_dist_traveled: 22.9735,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185736,
    shape_pt_lon: -89.885167,
    shape_pt_sequence: 497,
    shape_dist_traveled: 23.0232,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.186019,
    shape_pt_lon: -89.885669,
    shape_pt_sequence: 498,
    shape_dist_traveled: 23.0779,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.186184,
    shape_pt_lon: -89.885921,
    shape_pt_sequence: 499,
    shape_dist_traveled: 23.1077,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.186371,
    shape_pt_lon: -89.886189,
    shape_pt_sequence: 500,
    shape_dist_traveled: 23.1396,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.186588,
    shape_pt_lon: -89.886474,
    shape_pt_sequence: 501,
    shape_dist_traveled: 23.175,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.186144,
    shape_pt_lon: -89.887171,
    shape_pt_sequence: 502,
    shape_dist_traveled: 23.2554,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185915,
    shape_pt_lon: -89.887558,
    shape_pt_sequence: 503,
    shape_dist_traveled: 23.299,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185689,
    shape_pt_lon: -89.887961,
    shape_pt_sequence: 504,
    shape_dist_traveled: 23.3428,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185657,
    shape_pt_lon: -89.88802,
    shape_pt_sequence: 505,
    shape_dist_traveled: 23.3492,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185529,
    shape_pt_lon: -89.8883,
    shape_pt_sequence: 506,
    shape_dist_traveled: 23.3779,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185383,
    shape_pt_lon: -89.888688,
    shape_pt_sequence: 507,
    shape_dist_traveled: 23.4164,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185227,
    shape_pt_lon: -89.889275,
    shape_pt_sequence: 508,
    shape_dist_traveled: 23.4723,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185155,
    shape_pt_lon: -89.889691,
    shape_pt_sequence: 509,
    shape_dist_traveled: 23.5112,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185112,
    shape_pt_lon: -89.890218,
    shape_pt_sequence: 510,
    shape_dist_traveled: 23.5594,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18511,
    shape_pt_lon: -89.890383,
    shape_pt_sequence: 511,
    shape_dist_traveled: 23.5734,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.18511,
    shape_pt_lon: -89.890624,
    shape_pt_sequence: 512,
    shape_dist_traveled: 23.5954,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185138,
    shape_pt_lon: -89.89098,
    shape_pt_sequence: 513,
    shape_dist_traveled: 23.6276,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185226,
    shape_pt_lon: -89.891793,
    shape_pt_sequence: 514,
    shape_dist_traveled: 23.7022,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185382,
    shape_pt_lon: -89.893203,
    shape_pt_sequence: 515,
    shape_dist_traveled: 23.8304,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185496,
    shape_pt_lon: -89.894252,
    shape_pt_sequence: 516,
    shape_dist_traveled: 23.9261,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185712,
    shape_pt_lon: -89.896038,
    shape_pt_sequence: 517,
    shape_dist_traveled: 24.0889,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185727,
    shape_pt_lon: -89.896209,
    shape_pt_sequence: 518,
    shape_dist_traveled: 24.105,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.185733,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 519,
    shape_dist_traveled: 24.1131,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.184549,
    shape_pt_lon: -89.896342,
    shape_pt_sequence: 520,
    shape_dist_traveled: 24.2461,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183762,
    shape_pt_lon: -89.896358,
    shape_pt_sequence: 521,
    shape_dist_traveled: 24.3341,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.183397,
    shape_pt_lon: -89.896371,
    shape_pt_sequence: 522,
    shape_dist_traveled: 24.3752,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.182612,
    shape_pt_lon: -89.896381,
    shape_pt_sequence: 523,
    shape_dist_traveled: 24.4632,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.180463,
    shape_pt_lon: -89.896418,
    shape_pt_sequence: 524,
    shape_dist_traveled: 24.7032,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179936,
    shape_pt_lon: -89.89643,
    shape_pt_sequence: 525,
    shape_dist_traveled: 24.7622,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.179154,
    shape_pt_lon: -89.896444,
    shape_pt_sequence: 526,
    shape_dist_traveled: 24.8502,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17853,
    shape_pt_lon: -89.89646,
    shape_pt_sequence: 527,
    shape_dist_traveled: 24.9192,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177907,
    shape_pt_lon: -89.896501,
    shape_pt_sequence: 528,
    shape_dist_traveled: 24.9893,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177261,
    shape_pt_lon: -89.896582,
    shape_pt_sequence: 529,
    shape_dist_traveled: 25.0627,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.177197,
    shape_pt_lon: -89.896594,
    shape_pt_sequence: 530,
    shape_dist_traveled: 25.0698,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176549,
    shape_pt_lon: -89.89673,
    shape_pt_sequence: 531,
    shape_dist_traveled: 25.1428,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.176067,
    shape_pt_lon: -89.896865,
    shape_pt_sequence: 532,
    shape_dist_traveled: 25.1981,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1758,
    shape_pt_lon: -89.896949,
    shape_pt_sequence: 533,
    shape_dist_traveled: 25.2289,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175739,
    shape_pt_lon: -89.89697,
    shape_pt_sequence: 534,
    shape_dist_traveled: 25.2362,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175687,
    shape_pt_lon: -89.896989,
    shape_pt_sequence: 535,
    shape_dist_traveled: 25.2425,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175667,
    shape_pt_lon: -89.896996,
    shape_pt_sequence: 536,
    shape_dist_traveled: 25.2447,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.897023,
    shape_pt_sequence: 537,
    shape_dist_traveled: 25.254,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17527,
    shape_pt_lon: -89.897142,
    shape_pt_sequence: 538,
    shape_dist_traveled: 25.2906,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.175056,
    shape_pt_lon: -89.897218,
    shape_pt_sequence: 539,
    shape_dist_traveled: 25.3156,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174453,
    shape_pt_lon: -89.8975,
    shape_pt_sequence: 540,
    shape_dist_traveled: 25.3881,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174127,
    shape_pt_lon: -89.897689,
    shape_pt_sequence: 541,
    shape_dist_traveled: 25.4279,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174043,
    shape_pt_lon: -89.897811,
    shape_pt_sequence: 542,
    shape_dist_traveled: 25.4428,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173732,
    shape_pt_lon: -89.897997,
    shape_pt_sequence: 543,
    shape_dist_traveled: 25.4817,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173269,
    shape_pt_lon: -89.898298,
    shape_pt_sequence: 544,
    shape_dist_traveled: 25.5403,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172948,
    shape_pt_lon: -89.898533,
    shape_pt_sequence: 545,
    shape_dist_traveled: 25.5811,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172748,
    shape_pt_lon: -89.898682,
    shape_pt_sequence: 546,
    shape_dist_traveled: 25.6075,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17232,
    shape_pt_lon: -89.899037,
    shape_pt_sequence: 547,
    shape_dist_traveled: 25.6652,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172273,
    shape_pt_lon: -89.899077,
    shape_pt_sequence: 548,
    shape_dist_traveled: 25.6716,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171769,
    shape_pt_lon: -89.899549,
    shape_pt_sequence: 549,
    shape_dist_traveled: 25.7424,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171402,
    shape_pt_lon: -89.89993,
    shape_pt_sequence: 550,
    shape_dist_traveled: 25.7963,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171152,
    shape_pt_lon: -89.900197,
    shape_pt_sequence: 551,
    shape_dist_traveled: 25.8332,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170458,
    shape_pt_lon: -89.900955,
    shape_pt_sequence: 552,
    shape_dist_traveled: 25.9359,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17025,
    shape_pt_lon: -89.90117,
    shape_pt_sequence: 553,
    shape_dist_traveled: 25.9672,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169701,
    shape_pt_lon: -89.901796,
    shape_pt_sequence: 554,
    shape_dist_traveled: 26.05,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169216,
    shape_pt_lon: -89.902352,
    shape_pt_sequence: 555,
    shape_dist_traveled: 26.1243,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169135,
    shape_pt_lon: -89.902446,
    shape_pt_sequence: 556,
    shape_dist_traveled: 26.137,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169061,
    shape_pt_lon: -89.902528,
    shape_pt_sequence: 557,
    shape_dist_traveled: 26.1477,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168977,
    shape_pt_lon: -89.902627,
    shape_pt_sequence: 558,
    shape_dist_traveled: 26.1604,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168641,
    shape_pt_lon: -89.902941,
    shape_pt_sequence: 559,
    shape_dist_traveled: 26.2076,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168489,
    shape_pt_lon: -89.903108,
    shape_pt_sequence: 560,
    shape_dist_traveled: 26.2303,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16808,
    shape_pt_lon: -89.903566,
    shape_pt_sequence: 561,
    shape_dist_traveled: 26.2926,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16788,
    shape_pt_lon: -89.903813,
    shape_pt_sequence: 562,
    shape_dist_traveled: 26.3237,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167509,
    shape_pt_lon: -89.904219,
    shape_pt_sequence: 563,
    shape_dist_traveled: 26.379,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167126,
    shape_pt_lon: -89.904634,
    shape_pt_sequence: 564,
    shape_dist_traveled: 26.4364,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16686,
    shape_pt_lon: -89.904896,
    shape_pt_sequence: 565,
    shape_dist_traveled: 26.4742,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166581,
    shape_pt_lon: -89.90513,
    shape_pt_sequence: 566,
    shape_dist_traveled: 26.5122,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166301,
    shape_pt_lon: -89.905322,
    shape_pt_sequence: 567,
    shape_dist_traveled: 26.5475,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166147,
    shape_pt_lon: -89.905416,
    shape_pt_sequence: 568,
    shape_dist_traveled: 26.5672,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165785,
    shape_pt_lon: -89.905588,
    shape_pt_sequence: 569,
    shape_dist_traveled: 26.6103,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165549,
    shape_pt_lon: -89.905672,
    shape_pt_sequence: 570,
    shape_dist_traveled: 26.6382,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165332,
    shape_pt_lon: -89.90573,
    shape_pt_sequence: 571,
    shape_dist_traveled: 26.6627,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165101,
    shape_pt_lon: -89.905777,
    shape_pt_sequence: 572,
    shape_dist_traveled: 26.6892,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164616,
    shape_pt_lon: -89.905821,
    shape_pt_sequence: 573,
    shape_dist_traveled: 26.7433,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163549,
    shape_pt_lon: -89.905849,
    shape_pt_sequence: 574,
    shape_dist_traveled: 26.8624,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16208,
    shape_pt_lon: -89.905877,
    shape_pt_sequence: 575,
    shape_dist_traveled: 27.0274,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161979,
    shape_pt_lon: -89.905817,
    shape_pt_sequence: 576,
    shape_dist_traveled: 27.0395,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161872,
    shape_pt_lon: -89.905812,
    shape_pt_sequence: 577,
    shape_dist_traveled: 27.0515,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161043,
    shape_pt_lon: -89.905835,
    shape_pt_sequence: 578,
    shape_dist_traveled: 27.1445,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 579,
    shape_dist_traveled: 27.1575,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 580,
    shape_dist_traveled: 27.1635,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 581,
    shape_dist_traveled: 27.3026,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 582,
    shape_dist_traveled: 27.3677,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 583,
    shape_dist_traveled: 27.3838,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 584,
    shape_dist_traveled: 27.4006,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 585,
    shape_dist_traveled: 27.4145,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 586,
    shape_dist_traveled: 27.4293,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 587,
    shape_dist_traveled: 27.4385,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 588,
    shape_dist_traveled: 27.4754,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 589,
    shape_dist_traveled: 27.4899,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 590,
    shape_dist_traveled: 27.4935,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 591,
    shape_dist_traveled: 27.5091,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 592,
    shape_dist_traveled: 27.5199,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 593,
    shape_dist_traveled: 27.5389,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 594,
    shape_dist_traveled: 27.5579,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 595,
    shape_dist_traveled: 27.6909,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 596,
    shape_dist_traveled: 27.8669,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 597,
    shape_dist_traveled: 27.974,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 598,
    shape_dist_traveled: 27.988,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 599,
    shape_dist_traveled: 28.078,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 600,
    shape_dist_traveled: 28.239,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162057,
    shape_pt_lon: -89.918828,
    shape_pt_sequence: 601,
    shape_dist_traveled: 28.3731,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162066,
    shape_pt_lon: -89.919163,
    shape_pt_sequence: 602,
    shape_dist_traveled: 28.4031,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1621,
    shape_pt_lon: -89.920656,
    shape_pt_sequence: 603,
    shape_dist_traveled: 28.5382,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162115,
    shape_pt_lon: -89.921213,
    shape_pt_sequence: 604,
    shape_dist_traveled: 28.5882,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162147,
    shape_pt_lon: -89.922561,
    shape_pt_sequence: 605,
    shape_dist_traveled: 28.7102,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162167,
    shape_pt_lon: -89.923343,
    shape_pt_sequence: 606,
    shape_dist_traveled: 28.7813,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162179,
    shape_pt_lon: -89.923849,
    shape_pt_sequence: 607,
    shape_dist_traveled: 28.8273,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162196,
    shape_pt_lon: -89.924549,
    shape_pt_sequence: 608,
    shape_dist_traveled: 28.8903,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.92518,
    shape_pt_sequence: 609,
    shape_dist_traveled: 28.9473,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162229,
    shape_pt_lon: -89.926081,
    shape_pt_sequence: 610,
    shape_dist_traveled: 29.0284,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162238,
    shape_pt_lon: -89.926421,
    shape_pt_sequence: 611,
    shape_dist_traveled: 29.0594,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162248,
    shape_pt_lon: -89.926816,
    shape_pt_sequence: 612,
    shape_dist_traveled: 29.0954,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162259,
    shape_pt_lon: -89.927259,
    shape_pt_sequence: 613,
    shape_dist_traveled: 29.1354,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162293,
    shape_pt_lon: -89.928736,
    shape_pt_sequence: 614,
    shape_dist_traveled: 29.2685,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162329,
    shape_pt_lon: -89.930287,
    shape_pt_sequence: 615,
    shape_dist_traveled: 29.4085,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162348,
    shape_pt_lon: -89.93129,
    shape_pt_sequence: 616,
    shape_dist_traveled: 29.4996,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16237,
    shape_pt_lon: -89.932579,
    shape_pt_sequence: 617,
    shape_dist_traveled: 29.6156,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162389,
    shape_pt_lon: -89.933641,
    shape_pt_sequence: 618,
    shape_dist_traveled: 29.7116,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.934857,
    shape_pt_sequence: 619,
    shape_dist_traveled: 29.8216,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162465,
    shape_pt_lon: -89.937429,
    shape_pt_sequence: 620,
    shape_dist_traveled: 30.0537,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.938798,
    shape_pt_sequence: 621,
    shape_dist_traveled: 30.1777,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162505,
    shape_pt_lon: -89.939352,
    shape_pt_sequence: 622,
    shape_dist_traveled: 30.2277,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.939483,
    shape_pt_sequence: 623,
    shape_dist_traveled: 30.2398,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161489,
    shape_pt_lon: -89.939568,
    shape_pt_sequence: 624,
    shape_dist_traveled: 30.353,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160938,
    shape_pt_lon: -89.939612,
    shape_pt_sequence: 625,
    shape_dist_traveled: 30.4141,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161072,
    shape_pt_lon: -89.940385,
    shape_pt_sequence: 626,
    shape_dist_traveled: 30.4857,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16113,
    shape_pt_lon: -89.94066,
    shape_pt_sequence: 627,
    shape_dist_traveled: 30.5114,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16125,
    shape_pt_lon: -89.941332,
    shape_pt_sequence: 628,
    shape_dist_traveled: 30.5738,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16134,
    shape_pt_lon: -89.941818,
    shape_pt_sequence: 629,
    shape_dist_traveled: 30.6189,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161448,
    shape_pt_lon: -89.942402,
    shape_pt_sequence: 630,
    shape_dist_traveled: 30.6723,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161538,
    shape_pt_lon: -89.942891,
    shape_pt_sequence: 631,
    shape_dist_traveled: 30.7174,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161756,
    shape_pt_lon: -89.944046,
    shape_pt_sequence: 632,
    shape_dist_traveled: 30.8253,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162017,
    shape_pt_lon: -89.945251,
    shape_pt_sequence: 633,
    shape_dist_traveled: 30.9381,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 634,
    shape_dist_traveled: 31.1006,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162576,
    shape_pt_lon: -89.948104,
    shape_pt_sequence: 635,
    shape_dist_traveled: 31.2025,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162861,
    shape_pt_lon: -89.949555,
    shape_pt_sequence: 636,
    shape_dist_traveled: 31.3383,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.950821,
    shape_pt_sequence: 637,
    shape_dist_traveled: 31.4557,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163179,
    shape_pt_lon: -89.950962,
    shape_pt_sequence: 638,
    shape_dist_traveled: 31.4705,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164459,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 639,
    shape_dist_traveled: 31.718,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164501,
    shape_pt_lon: -89.953272,
    shape_pt_sequence: 640,
    shape_dist_traveled: 31.7266,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164533,
    shape_pt_lon: -89.953329,
    shape_pt_sequence: 641,
    shape_dist_traveled: 31.7324,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164178,
    shape_pt_lon: -89.953885,
    shape_pt_sequence: 642,
    shape_dist_traveled: 31.7958,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.955541,
    shape_pt_sequence: 643,
    shape_dist_traveled: 31.9865,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162812,
    shape_pt_lon: -89.95602,
    shape_pt_sequence: 644,
    shape_dist_traveled: 32.0421,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162565,
    shape_pt_lon: -89.956425,
    shape_pt_sequence: 645,
    shape_dist_traveled: 32.0877,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16243,
    shape_pt_lon: -89.956659,
    shape_pt_sequence: 646,
    shape_dist_traveled: 32.1135,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162143,
    shape_pt_lon: -89.957234,
    shape_pt_sequence: 647,
    shape_dist_traveled: 32.1746,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161999,
    shape_pt_lon: -89.957566,
    shape_pt_sequence: 648,
    shape_dist_traveled: 32.2091,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161856,
    shape_pt_lon: -89.957922,
    shape_pt_sequence: 649,
    shape_dist_traveled: 32.2448,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161456,
    shape_pt_lon: -89.959032,
    shape_pt_sequence: 650,
    shape_dist_traveled: 32.3545,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161156,
    shape_pt_lon: -89.959868,
    shape_pt_sequence: 651,
    shape_dist_traveled: 32.4374,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160596,
    shape_pt_lon: -89.961431,
    shape_pt_sequence: 652,
    shape_dist_traveled: 32.5918,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160163,
    shape_pt_lon: -89.962633,
    shape_pt_sequence: 653,
    shape_dist_traveled: 32.7113,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160143,
    shape_pt_lon: -89.962691,
    shape_pt_sequence: 654,
    shape_dist_traveled: 32.7167,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160129,
    shape_pt_lon: -89.962738,
    shape_pt_sequence: 655,
    shape_dist_traveled: 32.7208,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160057,
    shape_pt_lon: -89.962956,
    shape_pt_sequence: 656,
    shape_dist_traveled: 32.7423,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.159977,
    shape_pt_lon: -89.963312,
    shape_pt_sequence: 657,
    shape_dist_traveled: 32.7756,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1599,
    shape_pt_lon: -89.963641,
    shape_pt_sequence: 658,
    shape_dist_traveled: 32.8069,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.159771,
    shape_pt_lon: -89.964131,
    shape_pt_sequence: 659,
    shape_dist_traveled: 32.8534,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.159197,
    shape_pt_lon: -89.966612,
    shape_pt_sequence: 660,
    shape_dist_traveled: 33.0864,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1591,
    shape_pt_lon: -89.967021,
    shape_pt_sequence: 661,
    shape_dist_traveled: 33.125,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158837,
    shape_pt_lon: -89.968161,
    shape_pt_sequence: 662,
    shape_dist_traveled: 33.2322,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15877,
    shape_pt_lon: -89.968448,
    shape_pt_sequence: 663,
    shape_dist_traveled: 33.2592,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158613,
    shape_pt_lon: -89.969125,
    shape_pt_sequence: 664,
    shape_dist_traveled: 33.3228,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158547,
    shape_pt_lon: -89.969421,
    shape_pt_sequence: 665,
    shape_dist_traveled: 33.3507,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158253,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 666,
    shape_dist_traveled: 33.4693,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158108,
    shape_pt_lon: -89.971347,
    shape_pt_sequence: 667,
    shape_dist_traveled: 33.5305,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158064,
    shape_pt_lon: -89.971557,
    shape_pt_sequence: 668,
    shape_dist_traveled: 33.5501,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158051,
    shape_pt_lon: -89.97162,
    shape_pt_sequence: 669,
    shape_dist_traveled: 33.5564,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158013,
    shape_pt_lon: -89.97204,
    shape_pt_sequence: 670,
    shape_dist_traveled: 33.5947,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158008,
    shape_pt_lon: -89.972365,
    shape_pt_sequence: 671,
    shape_dist_traveled: 33.6237,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158006,
    shape_pt_lon: -89.972661,
    shape_pt_sequence: 672,
    shape_dist_traveled: 33.6507,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158001,
    shape_pt_lon: -89.973726,
    shape_pt_sequence: 673,
    shape_dist_traveled: 33.7467,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158,
    shape_pt_lon: -89.973977,
    shape_pt_sequence: 674,
    shape_dist_traveled: 33.7697,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157995,
    shape_pt_lon: -89.974993,
    shape_pt_sequence: 675,
    shape_dist_traveled: 33.8617,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157989,
    shape_pt_lon: -89.975919,
    shape_pt_sequence: 676,
    shape_dist_traveled: 33.9447,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 677,
    shape_dist_traveled: 33.9507,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 678,
    shape_dist_traveled: 34.0435,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 679,
    shape_dist_traveled: 34.0675,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 680,
    shape_dist_traveled: 34.2316,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 681,
    shape_dist_traveled: 34.3256,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 682,
    shape_dist_traveled: 34.3766,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 683,
    shape_dist_traveled: 34.4866,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 684,
    shape_dist_traveled: 34.5436,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 685,
    shape_dist_traveled: 34.5776,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 686,
    shape_dist_traveled: 34.6657,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 687,
    shape_dist_traveled: 34.7257,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 688,
    shape_dist_traveled: 34.8057,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 689,
    shape_dist_traveled: 34.8177,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 690,
    shape_dist_traveled: 34.9127,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 691,
    shape_dist_traveled: 35.0018,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 692,
    shape_dist_traveled: 35.0808,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 693,
    shape_dist_traveled: 35.0958,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 694,
    shape_dist_traveled: 35.1608,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 695,
    shape_dist_traveled: 35.1868,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 696,
    shape_dist_traveled: 35.2418,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 697,
    shape_dist_traveled: 35.3288,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 698,
    shape_dist_traveled: 35.3928,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 699,
    shape_dist_traveled: 35.4719,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 700,
    shape_dist_traveled: 35.5289,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 701,
    shape_dist_traveled: 35.6179,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 702,
    shape_dist_traveled: 35.6339,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 703,
    shape_dist_traveled: 35.7069,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 704,
    shape_dist_traveled: 35.7549,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 705,
    shape_dist_traveled: 35.7969,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 706,
    shape_dist_traveled: 35.8059,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 707,
    shape_dist_traveled: 35.812,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 708,
    shape_dist_traveled: 35.822,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 709,
    shape_dist_traveled: 35.9191,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 710,
    shape_dist_traveled: 36.0242,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 711,
    shape_dist_traveled: 36.1313,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 712,
    shape_dist_traveled: 36.2064,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 713,
    shape_dist_traveled: 36.3064,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 714,
    shape_dist_traveled: 36.3604,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 715,
    shape_dist_traveled: 36.3766,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 716,
    shape_dist_traveled: 36.4435,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 717,
    shape_dist_traveled: 36.4607,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 718,
    shape_dist_traveled: 36.5331,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 719,
    shape_dist_traveled: 36.5557,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174456,
    shape_pt_lon: -89.983571,
    shape_pt_sequence: 720,
    shape_dist_traveled: 36.5621,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174402,
    shape_pt_lon: -89.983542,
    shape_pt_sequence: 721,
    shape_dist_traveled: 36.5684,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.174346,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 722,
    shape_dist_traveled: 36.5745,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.172029,
    shape_pt_lon: -89.983608,
    shape_pt_sequence: 723,
    shape_dist_traveled: 36.8345,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171889,
    shape_pt_lon: -89.983628,
    shape_pt_sequence: 724,
    shape_dist_traveled: 36.8497,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171679,
    shape_pt_lon: -89.983661,
    shape_pt_sequence: 725,
    shape_dist_traveled: 36.8739,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.171316,
    shape_pt_lon: -89.983677,
    shape_pt_sequence: 726,
    shape_dist_traveled: 36.9149,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170873,
    shape_pt_lon: -89.983695,
    shape_pt_sequence: 727,
    shape_dist_traveled: 36.9639,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170526,
    shape_pt_lon: -89.983711,
    shape_pt_sequence: 728,
    shape_dist_traveled: 37.003,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.170075,
    shape_pt_lon: -89.98373,
    shape_pt_sequence: 729,
    shape_dist_traveled: 37.053,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16977,
    shape_pt_lon: -89.983742,
    shape_pt_sequence: 730,
    shape_dist_traveled: 37.088,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.169197,
    shape_pt_lon: -89.983766,
    shape_pt_sequence: 731,
    shape_dist_traveled: 37.152,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.168679,
    shape_pt_lon: -89.983788,
    shape_pt_sequence: 732,
    shape_dist_traveled: 37.2101,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167993,
    shape_pt_lon: -89.983813,
    shape_pt_sequence: 733,
    shape_dist_traveled: 37.2861,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167912,
    shape_pt_lon: -89.98381,
    shape_pt_sequence: 734,
    shape_dist_traveled: 37.2952,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167267,
    shape_pt_lon: -89.983828,
    shape_pt_sequence: 735,
    shape_dist_traveled: 37.3682,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.167168,
    shape_pt_lon: -89.983832,
    shape_pt_sequence: 736,
    shape_dist_traveled: 37.3792,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.166502,
    shape_pt_lon: -89.98386,
    shape_pt_sequence: 737,
    shape_dist_traveled: 37.4533,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165718,
    shape_pt_lon: -89.983893,
    shape_pt_sequence: 738,
    shape_dist_traveled: 37.5413,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165227,
    shape_pt_lon: -89.983921,
    shape_pt_sequence: 739,
    shape_dist_traveled: 37.5964,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164834,
    shape_pt_lon: -89.983948,
    shape_pt_sequence: 740,
    shape_dist_traveled: 37.6404,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164515,
    shape_pt_lon: -89.983969,
    shape_pt_sequence: 741,
    shape_dist_traveled: 37.6765,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163981,
    shape_pt_lon: -89.983984,
    shape_pt_sequence: 742,
    shape_dist_traveled: 37.7355,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163662,
    shape_pt_lon: -89.984,
    shape_pt_sequence: 743,
    shape_dist_traveled: 37.7715,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163152,
    shape_pt_lon: -89.984023,
    shape_pt_sequence: 744,
    shape_dist_traveled: 37.8285,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162322,
    shape_pt_lon: -89.984057,
    shape_pt_sequence: 745,
    shape_dist_traveled: 37.9216,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162321,
    shape_pt_lon: -89.984197,
    shape_pt_sequence: 746,
    shape_dist_traveled: 37.9346,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162298,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 747,
    shape_dist_traveled: 37.9608,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162144,
    shape_pt_lon: -89.985288,
    shape_pt_sequence: 748,
    shape_dist_traveled: 38.0347,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162113,
    shape_pt_lon: -89.985513,
    shape_pt_sequence: 749,
    shape_dist_traveled: 38.056,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162112,
    shape_pt_lon: -89.985674,
    shape_pt_sequence: 750,
    shape_dist_traveled: 38.07,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162146,
    shape_pt_lon: -89.985911,
    shape_pt_sequence: 751,
    shape_dist_traveled: 38.0914,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162208,
    shape_pt_lon: -89.986163,
    shape_pt_sequence: 752,
    shape_dist_traveled: 38.1154,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162269,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 753,
    shape_dist_traveled: 38.1414,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162339,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 754,
    shape_dist_traveled: 38.1999,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16235,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 755,
    shape_dist_traveled: 38.211,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162589,
    shape_pt_lon: -89.989588,
    shape_pt_sequence: 756,
    shape_dist_traveled: 38.4285,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162598,
    shape_pt_lon: -89.989674,
    shape_pt_sequence: 757,
    shape_dist_traveled: 38.4356,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162723,
    shape_pt_lon: -89.990932,
    shape_pt_sequence: 758,
    shape_dist_traveled: 38.5505,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162842,
    shape_pt_lon: -89.992093,
    shape_pt_sequence: 759,
    shape_dist_traveled: 38.6564,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162851,
    shape_pt_lon: -89.992185,
    shape_pt_sequence: 760,
    shape_dist_traveled: 38.6644,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162957,
    shape_pt_lon: -89.993208,
    shape_pt_sequence: 761,
    shape_dist_traveled: 38.7581,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16298,
    shape_pt_lon: -89.993437,
    shape_pt_sequence: 762,
    shape_dist_traveled: 38.7783,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163111,
    shape_pt_lon: -89.994709,
    shape_pt_sequence: 763,
    shape_dist_traveled: 38.8943,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163189,
    shape_pt_lon: -89.99534,
    shape_pt_sequence: 764,
    shape_dist_traveled: 38.9518,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163252,
    shape_pt_lon: -89.995958,
    shape_pt_sequence: 765,
    shape_dist_traveled: 39.0083,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163296,
    shape_pt_lon: -89.996369,
    shape_pt_sequence: 766,
    shape_dist_traveled: 39.0456,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -89.996731,
    shape_pt_sequence: 767,
    shape_dist_traveled: 39.0788,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163361,
    shape_pt_lon: -89.997173,
    shape_pt_sequence: 768,
    shape_dist_traveled: 39.119,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163479,
    shape_pt_lon: -89.998337,
    shape_pt_sequence: 769,
    shape_dist_traveled: 39.2248,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.1636,
    shape_pt_lon: -89.999521,
    shape_pt_sequence: 770,
    shape_dist_traveled: 39.3327,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163656,
    shape_pt_lon: -89.999998,
    shape_pt_sequence: 771,
    shape_dist_traveled: 39.3761,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163731,
    shape_pt_lon: -90.000659,
    shape_pt_sequence: 772,
    shape_dist_traveled: 39.4366,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16382,
    shape_pt_lon: -90.001687,
    shape_pt_sequence: 773,
    shape_dist_traveled: 39.5302,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163936,
    shape_pt_lon: -90.002817,
    shape_pt_sequence: 774,
    shape_dist_traveled: 39.633,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164057,
    shape_pt_lon: -90.004031,
    shape_pt_sequence: 775,
    shape_dist_traveled: 39.7429,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16408,
    shape_pt_lon: -90.004269,
    shape_pt_sequence: 776,
    shape_dist_traveled: 39.765,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164173,
    shape_pt_lon: -90.005196,
    shape_pt_sequence: 777,
    shape_dist_traveled: 39.8497,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164233,
    shape_pt_lon: -90.005785,
    shape_pt_sequence: 778,
    shape_dist_traveled: 39.903,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164265,
    shape_pt_lon: -90.00608,
    shape_pt_sequence: 779,
    shape_dist_traveled: 39.9293,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164282,
    shape_pt_lon: -90.006258,
    shape_pt_sequence: 780,
    shape_dist_traveled: 39.9464,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164383,
    shape_pt_lon: -90.007242,
    shape_pt_sequence: 781,
    shape_dist_traveled: 40.0351,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164389,
    shape_pt_lon: -90.00731,
    shape_pt_sequence: 782,
    shape_dist_traveled: 40.0422,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164491,
    shape_pt_lon: -90.008313,
    shape_pt_sequence: 783,
    shape_dist_traveled: 40.1329,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164557,
    shape_pt_lon: -90.008928,
    shape_pt_sequence: 784,
    shape_dist_traveled: 40.1893,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164601,
    shape_pt_lon: -90.009349,
    shape_pt_sequence: 785,
    shape_dist_traveled: 40.2276,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164698,
    shape_pt_lon: -90.010199,
    shape_pt_sequence: 786,
    shape_dist_traveled: 40.3054,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 787,
    shape_dist_traveled: 40.3196,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164727,
    shape_pt_lon: -90.010507,
    shape_pt_sequence: 788,
    shape_dist_traveled: 40.3326,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164765,
    shape_pt_lon: -90.010895,
    shape_pt_sequence: 789,
    shape_dist_traveled: 40.368,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164836,
    shape_pt_lon: -90.011643,
    shape_pt_sequence: 790,
    shape_dist_traveled: 40.4363,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16484,
    shape_pt_lon: -90.011693,
    shape_pt_sequence: 791,
    shape_dist_traveled: 40.4414,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16491,
    shape_pt_lon: -90.012433,
    shape_pt_sequence: 792,
    shape_dist_traveled: 40.5079,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164918,
    shape_pt_lon: -90.012502,
    shape_pt_sequence: 793,
    shape_dist_traveled: 40.515,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164993,
    shape_pt_lon: -90.013267,
    shape_pt_sequence: 794,
    shape_dist_traveled: 40.5844,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.164997,
    shape_pt_lon: -90.013295,
    shape_pt_sequence: 795,
    shape_dist_traveled: 40.5864,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165072,
    shape_pt_lon: -90.014077,
    shape_pt_sequence: 796,
    shape_dist_traveled: 40.658,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165151,
    shape_pt_lon: -90.014877,
    shape_pt_sequence: 797,
    shape_dist_traveled: 40.7306,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16523,
    shape_pt_lon: -90.015676,
    shape_pt_sequence: 798,
    shape_dist_traveled: 40.803,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165308,
    shape_pt_lon: -90.016482,
    shape_pt_sequence: 799,
    shape_dist_traveled: 40.8765,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165359,
    shape_pt_lon: -90.017013,
    shape_pt_sequence: 800,
    shape_dist_traveled: 40.9249,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165438,
    shape_pt_lon: -90.017736,
    shape_pt_sequence: 801,
    shape_dist_traveled: 40.9905,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165469,
    shape_pt_lon: -90.01796,
    shape_pt_sequence: 802,
    shape_dist_traveled: 41.0118,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165503,
    shape_pt_lon: -90.018218,
    shape_pt_sequence: 803,
    shape_dist_traveled: 41.0351,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165565,
    shape_pt_lon: -90.018664,
    shape_pt_sequence: 804,
    shape_dist_traveled: 41.0757,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165574,
    shape_pt_lon: -90.018738,
    shape_pt_sequence: 805,
    shape_dist_traveled: 41.0828,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16559,
    shape_pt_lon: -90.01886,
    shape_pt_sequence: 806,
    shape_dist_traveled: 41.0938,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.16566,
    shape_pt_lon: -90.019526,
    shape_pt_sequence: 807,
    shape_dist_traveled: 41.1544,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165779,
    shape_pt_lon: -90.020646,
    shape_pt_sequence: 808,
    shape_dist_traveled: 41.2563,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165911,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 809,
    shape_dist_traveled: 41.3672,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.021859,
    shape_pt_sequence: 810,
    shape_dist_traveled: 41.3762,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163811,
    shape_pt_lon: -90.022188,
    shape_pt_sequence: 811,
    shape_dist_traveled: 41.6041,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.163354,
    shape_pt_lon: -90.022263,
    shape_pt_sequence: 812,
    shape_dist_traveled: 41.6555,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162448,
    shape_pt_lon: -90.022414,
    shape_pt_sequence: 813,
    shape_dist_traveled: 41.7575,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.162102,
    shape_pt_lon: -90.022471,
    shape_pt_sequence: 814,
    shape_dist_traveled: 41.7968,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161669,
    shape_pt_lon: -90.022542,
    shape_pt_sequence: 815,
    shape_dist_traveled: 41.8452,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161642,
    shape_pt_lon: -90.022548,
    shape_pt_sequence: 816,
    shape_dist_traveled: 41.8484,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161289,
    shape_pt_lon: -90.022607,
    shape_pt_sequence: 817,
    shape_dist_traveled: 41.8887,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.161008,
    shape_pt_lon: -90.022653,
    shape_pt_sequence: 818,
    shape_dist_traveled: 41.9199,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.160939,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 819,
    shape_dist_traveled: 41.928,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15925,
    shape_pt_lon: -90.022942,
    shape_pt_sequence: 820,
    shape_dist_traveled: 42.1186,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.158187,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 821,
    shape_dist_traveled: 42.2383,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157517,
    shape_pt_lon: -90.023161,
    shape_pt_sequence: 822,
    shape_dist_traveled: 42.3137,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 823,
    shape_dist_traveled: 42.3177,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 824,
    shape_dist_traveled: 42.4038,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 825,
    shape_dist_traveled: 42.4397,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 826,
    shape_dist_traveled: 42.4658,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 827,
    shape_dist_traveled: 42.5298,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 828,
    shape_dist_traveled: 42.5828,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 829,
    shape_dist_traveled: 42.6549,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 830,
    shape_dist_traveled: 42.675,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 831,
    shape_dist_traveled: 42.782,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 832,
    shape_dist_traveled: 42.7951,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 833,
    shape_dist_traveled: 42.8971,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 834,
    shape_dist_traveled: 42.9261,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 835,
    shape_dist_traveled: 43.0052,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 836,
    shape_dist_traveled: 43.0252,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 837,
    shape_dist_traveled: 43.0873,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 838,
    shape_dist_traveled: 43.1033,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 839,
    shape_dist_traveled: 43.1183,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 840,
    shape_dist_traveled: 43.1215,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 841,
    shape_dist_traveled: 43.1706,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 842,
    shape_dist_traveled: 43.1776,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 843,
    shape_dist_traveled: 43.2346,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 844,
    shape_dist_traveled: 43.2396,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 845,
    shape_dist_traveled: 43.2786,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 846,
    shape_dist_traveled: 43.3654,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 847,
    shape_dist_traveled: 43.4339,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 848,
    shape_dist_traveled: 43.4778,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 849,
    shape_dist_traveled: 43.524,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 850,
    shape_dist_traveled: 43.5702,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 851,
    shape_dist_traveled: 43.5955,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 852,
    shape_dist_traveled: 43.6338,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 853,
    shape_dist_traveled: 43.6383,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 854,
    shape_dist_traveled: 43.65,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 855,
    shape_dist_traveled: 43.692,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 856,
    shape_dist_traveled: 43.7267,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 857,
    shape_dist_traveled: 43.7558,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 858,
    shape_dist_traveled: 43.7768,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 859,
    shape_dist_traveled: 43.9098,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 860,
    shape_dist_traveled: 44.0069,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 861,
    shape_dist_traveled: 44.0639,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 862,
    shape_dist_traveled: 44.1289,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 863,
    shape_dist_traveled: 44.1739,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 864,
    shape_dist_traveled: 44.1934,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 865,
    shape_dist_traveled: 44.2531,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 866,
    shape_dist_traveled: 44.3711,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 867,
    shape_dist_traveled: 44.4306,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 868,
    shape_dist_traveled: 44.4894,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 869,
    shape_dist_traveled: 44.545,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 870,
    shape_dist_traveled: 44.6026,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 871,
    shape_dist_traveled: 44.6058,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 872,
    shape_dist_traveled: 44.6655,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 873,
    shape_dist_traveled: 44.6883,
  },
  {
    shape_id: 38199,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 874,
    shape_dist_traveled: 44.7547,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.148779,
    shape_pt_lon: -89.89656,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.148691,
    shape_pt_lon: -89.897102,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.05,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.148595,
    shape_pt_lon: -89.897887,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1217,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.148686,
    shape_pt_lon: -89.897883,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1318,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.149783,
    shape_pt_lon: -89.897851,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2538,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.150362,
    shape_pt_lon: -89.897829,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3188,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.151325,
    shape_pt_lon: -89.897797,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.4268,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.151828,
    shape_pt_lon: -89.897779,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4829,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.152868,
    shape_pt_lon: -89.897748,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.5999,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.153793,
    shape_pt_lon: -89.897716,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.7029,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.154124,
    shape_pt_lon: -89.897707,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.7399,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.154916,
    shape_pt_lon: -89.89768,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.829,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155711,
    shape_pt_lon: -89.897654,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.918,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156504,
    shape_pt_lon: -89.897628,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.007,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15694,
    shape_pt_lon: -89.897613,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.055,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157299,
    shape_pt_lon: -89.897602,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0961,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158064,
    shape_pt_lon: -89.897579,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.1811,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158121,
    shape_pt_lon: -89.897538,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.1891,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158474,
    shape_pt_lon: -89.89751,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.2282,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158622,
    shape_pt_lon: -89.897448,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.2462,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158836,
    shape_pt_lon: -89.897333,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.2722,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158896,
    shape_pt_lon: -89.897306,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.2789,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158971,
    shape_pt_lon: -89.897386,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.291,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159172,
    shape_pt_lon: -89.897533,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.3165,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159288,
    shape_pt_lon: -89.897616,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.3313,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159316,
    shape_pt_lon: -89.897637,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.3349,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159391,
    shape_pt_lon: -89.897726,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3469,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159433,
    shape_pt_lon: -89.897812,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3559,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159573,
    shape_pt_lon: -89.898267,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3999,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159889,
    shape_pt_lon: -89.89937,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5058,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160157,
    shape_pt_lon: -89.900316,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.596,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160479,
    shape_pt_lon: -89.901456,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.7051,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16074,
    shape_pt_lon: -89.902387,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.7949,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160971,
    shape_pt_lon: -89.903229,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8752,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161018,
    shape_pt_lon: -89.903547,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.9037,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16103,
    shape_pt_lon: -89.903879,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9337,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161016,
    shape_pt_lon: -89.904213,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9648,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160945,
    shape_pt_lon: -89.904863,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.0233,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160909,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0844,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.1115,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.1175,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.2566,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.3217,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.3378,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.3546,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3684,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.3833,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3925,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4294,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4438,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.4474,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4631,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.4739,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4929,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.5119,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.6449,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.8209,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.928,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.942,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.032,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.193,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162057,
    shape_pt_lon: -89.918828,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3271,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162066,
    shape_pt_lon: -89.919163,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.3571,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.1621,
    shape_pt_lon: -89.920656,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4921,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162115,
    shape_pt_lon: -89.921213,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5422,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162147,
    shape_pt_lon: -89.922561,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6642,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162167,
    shape_pt_lon: -89.923343,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7352,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162179,
    shape_pt_lon: -89.923849,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.7813,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162196,
    shape_pt_lon: -89.924549,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8443,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.92518,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.9013,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162229,
    shape_pt_lon: -89.926081,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.9824,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162238,
    shape_pt_lon: -89.926421,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.0134,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162248,
    shape_pt_lon: -89.926816,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.0494,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162259,
    shape_pt_lon: -89.927259,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0894,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162293,
    shape_pt_lon: -89.928736,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.2225,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162329,
    shape_pt_lon: -89.930287,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.3625,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162348,
    shape_pt_lon: -89.93129,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.4535,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16237,
    shape_pt_lon: -89.932579,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.5696,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162389,
    shape_pt_lon: -89.933641,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.6656,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.934857,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.7756,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162465,
    shape_pt_lon: -89.937429,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.0077,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.938798,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.1317,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162505,
    shape_pt_lon: -89.939352,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.1817,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162491,
    shape_pt_lon: -89.939483,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.1938,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161489,
    shape_pt_lon: -89.939568,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.307,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160938,
    shape_pt_lon: -89.939612,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.3681,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161072,
    shape_pt_lon: -89.940385,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.4397,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16113,
    shape_pt_lon: -89.94066,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.4654,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16125,
    shape_pt_lon: -89.941332,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.5278,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16134,
    shape_pt_lon: -89.941818,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.5729,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161448,
    shape_pt_lon: -89.942402,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.6263,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161538,
    shape_pt_lon: -89.942891,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.6714,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161756,
    shape_pt_lon: -89.944046,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.7793,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162017,
    shape_pt_lon: -89.945251,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.8921,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162361,
    shape_pt_lon: -89.947006,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.0546,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162576,
    shape_pt_lon: -89.948104,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.1565,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162861,
    shape_pt_lon: -89.949555,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.2923,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.950821,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.4097,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163179,
    shape_pt_lon: -89.950962,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.4245,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164459,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.672,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164501,
    shape_pt_lon: -89.953272,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.6806,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164533,
    shape_pt_lon: -89.953329,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.6864,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164178,
    shape_pt_lon: -89.953885,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.7498,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.955541,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.9405,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162812,
    shape_pt_lon: -89.95602,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.9961,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162565,
    shape_pt_lon: -89.956425,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.0417,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16243,
    shape_pt_lon: -89.956659,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.0675,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162143,
    shape_pt_lon: -89.957234,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.1286,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161999,
    shape_pt_lon: -89.957566,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.163,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161856,
    shape_pt_lon: -89.957922,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.1988,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161456,
    shape_pt_lon: -89.959032,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.3085,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161156,
    shape_pt_lon: -89.959868,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.3913,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160596,
    shape_pt_lon: -89.961431,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.5458,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160163,
    shape_pt_lon: -89.962633,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.6653,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160143,
    shape_pt_lon: -89.962691,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.6707,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160129,
    shape_pt_lon: -89.962738,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.6748,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160057,
    shape_pt_lon: -89.962956,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.6963,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159977,
    shape_pt_lon: -89.963312,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.7296,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.1599,
    shape_pt_lon: -89.963641,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.7609,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159771,
    shape_pt_lon: -89.964131,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.8074,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159197,
    shape_pt_lon: -89.966612,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.0403,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.1591,
    shape_pt_lon: -89.967021,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.0789,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158837,
    shape_pt_lon: -89.968161,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.1862,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15877,
    shape_pt_lon: -89.968448,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.2131,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158613,
    shape_pt_lon: -89.969125,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.2767,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158547,
    shape_pt_lon: -89.969421,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.3046,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158253,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4233,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158108,
    shape_pt_lon: -89.971347,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.4845,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158064,
    shape_pt_lon: -89.971557,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.5041,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158051,
    shape_pt_lon: -89.97162,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.5104,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158013,
    shape_pt_lon: -89.97204,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5486,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158008,
    shape_pt_lon: -89.972365,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5777,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158006,
    shape_pt_lon: -89.972661,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6047,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158001,
    shape_pt_lon: -89.973726,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.7007,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158,
    shape_pt_lon: -89.973977,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.7237,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157995,
    shape_pt_lon: -89.974993,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.8157,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157989,
    shape_pt_lon: -89.975919,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.8987,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.9047,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.9974,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.0215,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.1856,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.2796,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.3306,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.4406,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.4976,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.5316,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.6196,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.6797,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.7597,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.7717,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.8667,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.9557,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.0348,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.0498,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.1148,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.1408,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.1958,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.2828,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.3468,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.4259,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.4829,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.5719,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.5879,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.6609,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.7089,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.7509,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.7599,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.766,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174612,
    shape_pt_lon: -89.975537,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.776,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174648,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.8731,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174689,
    shape_pt_lon: -89.977773,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.9782,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174729,
    shape_pt_lon: -89.978955,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.0853,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174757,
    shape_pt_lon: -89.979788,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.1603,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174791,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.2604,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174807,
    shape_pt_lon: -89.981492,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.3144,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174789,
    shape_pt_lon: -89.981678,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.3305,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174687,
    shape_pt_lon: -89.982408,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.3975,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.17466,
    shape_pt_lon: -89.982588,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.4147,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174538,
    shape_pt_lon: -89.983374,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.4871,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174497,
    shape_pt_lon: -89.983624,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.5097,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174456,
    shape_pt_lon: -89.983571,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.5161,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174402,
    shape_pt_lon: -89.983542,
    shape_pt_sequence: 182,
    shape_dist_traveled: 11.5224,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.174346,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 183,
    shape_dist_traveled: 11.5285,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.172029,
    shape_pt_lon: -89.983608,
    shape_pt_sequence: 184,
    shape_dist_traveled: 11.7885,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.171889,
    shape_pt_lon: -89.983628,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.8037,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.171679,
    shape_pt_lon: -89.983661,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.8278,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.171316,
    shape_pt_lon: -89.983677,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.8689,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.170873,
    shape_pt_lon: -89.983695,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.9179,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.170526,
    shape_pt_lon: -89.983711,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.957,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.170075,
    shape_pt_lon: -89.98373,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.007,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16977,
    shape_pt_lon: -89.983742,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.042,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.169197,
    shape_pt_lon: -89.983766,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.106,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.168679,
    shape_pt_lon: -89.983788,
    shape_pt_sequence: 193,
    shape_dist_traveled: 12.164,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.167993,
    shape_pt_lon: -89.983813,
    shape_pt_sequence: 194,
    shape_dist_traveled: 12.2401,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.167912,
    shape_pt_lon: -89.98381,
    shape_pt_sequence: 195,
    shape_dist_traveled: 12.2492,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.167267,
    shape_pt_lon: -89.983828,
    shape_pt_sequence: 196,
    shape_dist_traveled: 12.3222,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.167168,
    shape_pt_lon: -89.983832,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.3332,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.166502,
    shape_pt_lon: -89.98386,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.4073,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165718,
    shape_pt_lon: -89.983893,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.4953,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165227,
    shape_pt_lon: -89.983921,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.5503,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164834,
    shape_pt_lon: -89.983948,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.5944,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164515,
    shape_pt_lon: -89.983969,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.6304,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163981,
    shape_pt_lon: -89.983984,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.6895,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163662,
    shape_pt_lon: -89.984,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.7255,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163152,
    shape_pt_lon: -89.984023,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.7825,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162322,
    shape_pt_lon: -89.984057,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.8756,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162321,
    shape_pt_lon: -89.984197,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.8886,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162298,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.9147,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162144,
    shape_pt_lon: -89.985288,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.9887,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162113,
    shape_pt_lon: -89.985513,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.0099,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162112,
    shape_pt_lon: -89.985674,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.024,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162146,
    shape_pt_lon: -89.985911,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.0454,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162208,
    shape_pt_lon: -89.986163,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.0694,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162269,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.0954,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162339,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.1539,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16235,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.1649,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162589,
    shape_pt_lon: -89.989588,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.3825,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162598,
    shape_pt_lon: -89.989674,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.3896,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162723,
    shape_pt_lon: -89.990932,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.5044,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162842,
    shape_pt_lon: -89.992093,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.6104,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162851,
    shape_pt_lon: -89.992185,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.6184,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162957,
    shape_pt_lon: -89.993208,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.7121,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16298,
    shape_pt_lon: -89.993437,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.7323,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163111,
    shape_pt_lon: -89.994709,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.8483,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163189,
    shape_pt_lon: -89.99534,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.9058,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163252,
    shape_pt_lon: -89.995958,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.9623,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163296,
    shape_pt_lon: -89.996369,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.9996,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163318,
    shape_pt_lon: -89.996731,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.0327,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163361,
    shape_pt_lon: -89.997173,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.0729,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163479,
    shape_pt_lon: -89.998337,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.1787,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.1636,
    shape_pt_lon: -89.999521,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.2867,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163656,
    shape_pt_lon: -89.999998,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.3301,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163731,
    shape_pt_lon: -90.000659,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.3906,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16382,
    shape_pt_lon: -90.001687,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.4841,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163936,
    shape_pt_lon: -90.002817,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.587,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164057,
    shape_pt_lon: -90.004031,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.6969,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16408,
    shape_pt_lon: -90.004269,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.7189,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164173,
    shape_pt_lon: -90.005196,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.8037,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164233,
    shape_pt_lon: -90.005785,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.857,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164265,
    shape_pt_lon: -90.00608,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.8833,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164282,
    shape_pt_lon: -90.006258,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.9004,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164383,
    shape_pt_lon: -90.007242,
    shape_pt_sequence: 242,
    shape_dist_traveled: 14.9891,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164389,
    shape_pt_lon: -90.00731,
    shape_pt_sequence: 243,
    shape_dist_traveled: 14.9962,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164491,
    shape_pt_lon: -90.008313,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.0869,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164557,
    shape_pt_lon: -90.008928,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.1433,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164601,
    shape_pt_lon: -90.009349,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.1816,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164698,
    shape_pt_lon: -90.010199,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.2594,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.2735,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164727,
    shape_pt_lon: -90.010507,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.2866,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164765,
    shape_pt_lon: -90.010895,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.3219,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164836,
    shape_pt_lon: -90.011643,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.3903,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16484,
    shape_pt_lon: -90.011693,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.3954,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16491,
    shape_pt_lon: -90.012433,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.4619,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164918,
    shape_pt_lon: -90.012502,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.4689,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164993,
    shape_pt_lon: -90.013267,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.5384,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.164997,
    shape_pt_lon: -90.013295,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.5404,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165072,
    shape_pt_lon: -90.014077,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.612,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165151,
    shape_pt_lon: -90.014877,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.6845,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16523,
    shape_pt_lon: -90.015676,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.757,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165308,
    shape_pt_lon: -90.016482,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.8305,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165359,
    shape_pt_lon: -90.017013,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.8789,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165438,
    shape_pt_lon: -90.017736,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.9445,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165469,
    shape_pt_lon: -90.01796,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.9657,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165503,
    shape_pt_lon: -90.018218,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.9891,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165565,
    shape_pt_lon: -90.018664,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.0297,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165574,
    shape_pt_lon: -90.018738,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.0368,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16559,
    shape_pt_lon: -90.01886,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.0478,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.16566,
    shape_pt_lon: -90.019526,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.1083,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165779,
    shape_pt_lon: -90.020646,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.2103,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165911,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.3212,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.165826,
    shape_pt_lon: -90.021859,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.3302,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163811,
    shape_pt_lon: -90.022188,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.558,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.163354,
    shape_pt_lon: -90.022263,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.6095,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162448,
    shape_pt_lon: -90.022414,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.7115,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.162102,
    shape_pt_lon: -90.022471,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.7508,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161669,
    shape_pt_lon: -90.022542,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.7992,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161642,
    shape_pt_lon: -90.022548,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.8023,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161289,
    shape_pt_lon: -90.022607,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.8427,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.161008,
    shape_pt_lon: -90.022653,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.8739,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.160939,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.882,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15925,
    shape_pt_lon: -90.022942,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.0726,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.158187,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.1923,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157517,
    shape_pt_lon: -90.023161,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.2677,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.2717,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.3578,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.3937,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.4198,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.4838,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.5368,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.6089,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.6289,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.736,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.7491,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.8511,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.8801,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.9592,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.9792,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.0413,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.0573,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.0723,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.0755,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.1245,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.1315,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.1885,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.1935,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.2326,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.3194,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.3878,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.4318,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.478,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.5242,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.5494,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.5878,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.5922,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.604,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.646,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.6807,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.7098,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.7308,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.8638,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.9608,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.0179,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.0829,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.1279,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.1473,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.2071,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.3251,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.3845,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.4434,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.499,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.5566,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.5597,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.6195,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.6423,
  },
  {
    shape_id: 38200,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.7087,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151659,
    shape_pt_lon: -90.048161,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6738,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7342,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8474,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8573,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152488,
    shape_pt_lon: -90.046991,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8662,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152283,
    shape_pt_lon: -90.046166,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9437,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.152039,
    shape_pt_lon: -90.04529,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0272,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15199,
    shape_pt_lon: -90.045069,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0478,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15194,
    shape_pt_lon: -90.044781,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0745,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151879,
    shape_pt_lon: -90.044553,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0966,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151786,
    shape_pt_lon: -90.044168,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.133,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15171,
    shape_pt_lon: -90.043738,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1721,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151649,
    shape_pt_lon: -90.043198,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2214,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151644,
    shape_pt_lon: -90.043137,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.2275,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151621,
    shape_pt_lon: -90.042832,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2547,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151557,
    shape_pt_lon: -90.041956,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.334,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151526,
    shape_pt_lon: -90.041669,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3602,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151447,
    shape_pt_lon: -90.041024,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4198,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151426,
    shape_pt_lon: -90.040848,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.436,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151421,
    shape_pt_lon: -90.040809,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.4391,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151391,
    shape_pt_lon: -90.040546,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.4633,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15117,
    shape_pt_lon: -90.038689,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6332,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.151069,
    shape_pt_lon: -90.037964,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.6991,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150801,
    shape_pt_lon: -90.036041,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8757,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150712,
    shape_pt_lon: -90.035343,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9394,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150549,
    shape_pt_lon: -90.034183,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.046,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.1504,
    shape_pt_lon: -90.033068,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1474,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150276,
    shape_pt_lon: -90.032141,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.2326,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150243,
    shape_pt_lon: -90.031923,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2528,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150209,
    shape_pt_lon: -90.031667,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.2761,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150089,
    shape_pt_lon: -90.03075,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.3603,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15007,
    shape_pt_lon: -90.030583,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3754,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150017,
    shape_pt_lon: -90.029869,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4397,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150016,
    shape_pt_lon: -90.029738,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4517,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150018,
    shape_pt_lon: -90.029482,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.4747,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.15002,
    shape_pt_lon: -90.029239,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4967,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150062,
    shape_pt_lon: -90.028061,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.6038,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150058,
    shape_pt_lon: -90.027697,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.6368,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.150037,
    shape_pt_lon: -90.027452,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.6589,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149806,
    shape_pt_lon: -90.025788,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.8111,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149663,
    shape_pt_lon: -90.025269,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.8608,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149633,
    shape_pt_lon: -90.025156,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8712,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149528,
    shape_pt_lon: -90.024774,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.9082,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149404,
    shape_pt_lon: -90.024408,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9441,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149368,
    shape_pt_lon: -90.024323,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.953,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149293,
    shape_pt_lon: -90.024187,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.9675,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.149086,
    shape_pt_lon: -90.023883,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.0036,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.148802,
    shape_pt_lon: -90.023576,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.0453,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.148521,
    shape_pt_lon: -90.023332,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0842,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.148278,
    shape_pt_lon: -90.023207,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.1133,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.148058,
    shape_pt_lon: -90.023128,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.1396,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.147763,
    shape_pt_lon: -90.02305,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.1733,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.146873,
    shape_pt_lon: -90.022813,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.2745,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.146458,
    shape_pt_lon: -90.022712,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.3224,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.146356,
    shape_pt_lon: -90.02269,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.3336,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.145907,
    shape_pt_lon: -90.022613,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.384,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.145599,
    shape_pt_lon: -90.022588,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.4191,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.144798,
    shape_pt_lon: -90.022572,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.5081,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.144583,
    shape_pt_lon: -90.022598,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.5322,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.14407,
    shape_pt_lon: -90.022657,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.5905,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.144012,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.5965,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.7967,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.141529,
    shape_pt_lon: -90.023041,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.8756,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.141245,
    shape_pt_lon: -90.023094,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.908,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.140751,
    shape_pt_lon: -90.02323,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.9643,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.14028,
    shape_pt_lon: -90.023398,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.0194,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.139605,
    shape_pt_lon: -90.023703,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.0995,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.139306,
    shape_pt_lon: -90.023841,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.1355,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.138819,
    shape_pt_lon: -90.024047,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.1928,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.138752,
    shape_pt_lon: -90.024067,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.201,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.138613,
    shape_pt_lon: -90.024108,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.2163,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.138335,
    shape_pt_lon: -90.024191,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.2493,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.024286,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.3031,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.137604,
    shape_pt_lon: -90.024308,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.3311,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.137372,
    shape_pt_lon: -90.02433,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.3571,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.136893,
    shape_pt_lon: -90.024342,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.4112,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.136311,
    shape_pt_lon: -90.024336,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.4762,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.136073,
    shape_pt_lon: -90.024329,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.5032,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.135953,
    shape_pt_lon: -90.024329,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.5162,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.135156,
    shape_pt_lon: -90.024303,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.6052,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.134731,
    shape_pt_lon: -90.024275,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.6533,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.13408,
    shape_pt_lon: -90.024241,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.7264,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.133017,
    shape_pt_lon: -90.024199,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.8455,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.132828,
    shape_pt_lon: -90.024193,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.8665,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.132642,
    shape_pt_lon: -90.024177,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.8875,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.132526,
    shape_pt_lon: -90.024167,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.9006,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.130891,
    shape_pt_lon: -90.024069,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.0838,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.130679,
    shape_pt_lon: -90.024067,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.1068,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.1368,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.130175,
    shape_pt_lon: -90.024041,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.1638,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.130083,
    shape_pt_lon: -90.024037,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.1738,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.129701,
    shape_pt_lon: -90.024001,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.217,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.128273,
    shape_pt_lon: -90.023921,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.3762,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.127775,
    shape_pt_lon: -90.023913,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.4322,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.127324,
    shape_pt_lon: -90.023943,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.4833,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.126897,
    shape_pt_lon: -90.024029,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.5309,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.12673,
    shape_pt_lon: -90.024076,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.5503,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.126512,
    shape_pt_lon: -90.024159,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.5763,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.126141,
    shape_pt_lon: -90.024318,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.62,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.125228,
    shape_pt_lon: -90.024777,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.7299,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.12508,
    shape_pt_lon: -90.024853,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.7483,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.12492,
    shape_pt_lon: -90.024937,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.768,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.124821,
    shape_pt_lon: -90.024987,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.7797,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.124042,
    shape_pt_lon: -90.02537,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.8735,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.12393,
    shape_pt_lon: -90.025421,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.8871,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.123631,
    shape_pt_lon: -90.025538,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.9218,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.123288,
    shape_pt_lon: -90.025643,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.9609,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.123099,
    shape_pt_lon: -90.025685,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.9833,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.122738,
    shape_pt_lon: -90.025741,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.0236,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.12165,
    shape_pt_lon: -90.025809,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.1457,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.121288,
    shape_pt_lon: -90.025828,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.1858,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.025893,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.3309,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.119553,
    shape_pt_lon: -90.025944,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.3801,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.11925,
    shape_pt_lon: -90.026006,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.4146,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.119128,
    shape_pt_lon: -90.026038,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.4289,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.11891,
    shape_pt_lon: -90.02611,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.4536,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.118792,
    shape_pt_lon: -90.026151,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.4672,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.118392,
    shape_pt_lon: -90.026325,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.515,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.116389,
    shape_pt_lon: -90.027429,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.7599,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.115599,
    shape_pt_lon: -90.027889,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.8583,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.8776,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.115087,
    shape_pt_lon: -90.028145,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.9198,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.114422,
    shape_pt_lon: -90.028451,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.9998,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.113888,
    shape_pt_lon: -90.028656,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.0615,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.113372,
    shape_pt_lon: -90.028816,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.1214,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.112813,
    shape_pt_lon: -90.02895,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.1856,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.11227,
    shape_pt_lon: -90.029048,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.2462,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.111749,
    shape_pt_lon: -90.029106,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.3054,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.111593,
    shape_pt_lon: -90.029112,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.3224,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.110665,
    shape_pt_lon: -90.029161,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.4266,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.110022,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.4987,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.109867,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.5157,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.109786,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.5247,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.109684,
    shape_pt_lon: -90.029215,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.5367,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.109511,
    shape_pt_lon: -90.029221,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.5557,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.10805,
    shape_pt_lon: -90.029268,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.7198,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.107246,
    shape_pt_lon: -90.0293,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.8098,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.107127,
    shape_pt_lon: -90.029303,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.8228,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.107018,
    shape_pt_lon: -90.02931,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.8349,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.106176,
    shape_pt_lon: -90.029343,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.9289,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.105893,
    shape_pt_lon: -90.029353,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.9609,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.104473,
    shape_pt_lon: -90.029411,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.12,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.102841,
    shape_pt_lon: -90.02948,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.3021,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.102165,
    shape_pt_lon: -90.029486,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.3781,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.101833,
    shape_pt_lon: -90.029463,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.4152,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.101438,
    shape_pt_lon: -90.029405,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.4596,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.101019,
    shape_pt_lon: -90.029302,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.5074,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.10083,
    shape_pt_lon: -90.029237,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.5293,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.100497,
    shape_pt_lon: -90.029109,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.5688,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.100251,
    shape_pt_lon: -90.028993,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.598,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.099275,
    shape_pt_lon: -90.028492,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.7159,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.098243,
    shape_pt_lon: -90.02797,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8414,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.097093,
    shape_pt_lon: -90.027392,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.9805,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.096366,
    shape_pt_lon: -90.027056,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.0669,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.095526,
    shape_pt_lon: -90.026721,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.1659,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.095316,
    shape_pt_lon: -90.026641,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.1899,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.094821,
    shape_pt_lon: -90.026467,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.2482,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.094123,
    shape_pt_lon: -90.026259,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.3282,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.09402,
    shape_pt_lon: -90.026229,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.3396,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.093127,
    shape_pt_lon: -90.025993,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.442,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.092767,
    shape_pt_lon: -90.025916,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.4836,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.091809,
    shape_pt_lon: -90.02575,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.5916,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.091067,
    shape_pt_lon: -90.025667,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.6749,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.090443,
    shape_pt_lon: -90.025614,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.7451,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.089918,
    shape_pt_lon: -90.025589,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.8032,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.089386,
    shape_pt_lon: -90.025573,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.8632,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.088676,
    shape_pt_lon: -90.025582,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.9422,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.086949,
    shape_pt_lon: -90.025614,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.1352,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.086578,
    shape_pt_lon: -90.025618,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.1772,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.086203,
    shape_pt_lon: -90.025628,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.2192,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.084173,
    shape_pt_lon: -90.025658,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.4463,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.5233,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.083443,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.5283,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.083411,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.5313,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.082426,
    shape_pt_lon: -90.025692,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.6423,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.081146,
    shape_pt_lon: -90.02571,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.7853,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.080069,
    shape_pt_lon: -90.025736,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.9054,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.079666,
    shape_pt_lon: -90.025759,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.9504,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.079364,
    shape_pt_lon: -90.025788,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.9845,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.079139,
    shape_pt_lon: -90.025824,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.0096,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.078848,
    shape_pt_lon: -90.02588,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.0432,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.078179,
    shape_pt_lon: -90.026026,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.1193,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.077706,
    shape_pt_lon: -90.026155,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.1734,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.077226,
    shape_pt_lon: -90.026316,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.2285,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.076924,
    shape_pt_lon: -90.026446,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.2646,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.076809,
    shape_pt_lon: -90.026508,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2785,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.075429,
    shape_pt_lon: -90.0272,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.4458,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.075227,
    shape_pt_lon: -90.027292,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.4692,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.075074,
    shape_pt_lon: -90.027361,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.4872,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.074891,
    shape_pt_lon: -90.027435,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.5094,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.074617,
    shape_pt_lon: -90.027547,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.541,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.074126,
    shape_pt_lon: -90.027709,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.598,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073391,
    shape_pt_lon: -90.027918,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.6832,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072952,
    shape_pt_lon: -90.027998,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.7327,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072829,
    shape_pt_lon: -90.028017,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.7457,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0727,
    shape_pt_lon: -90.028037,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.7608,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072481,
    shape_pt_lon: -90.028059,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.7849,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071836,
    shape_pt_lon: -90.028,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.8581,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071623,
    shape_pt_lon: -90.027953,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.8814,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071419,
    shape_pt_lon: -90.027882,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.9055,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071223,
    shape_pt_lon: -90.027785,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.9292,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071039,
    shape_pt_lon: -90.02766,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.9529,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070868,
    shape_pt_lon: -90.027512,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.976,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070711,
    shape_pt_lon: -90.027342,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.9993,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070579,
    shape_pt_lon: -90.027165,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.0212,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070452,
    shape_pt_lon: -90.026956,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.0448,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07035,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.0682,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070256,
    shape_pt_lon: -90.026457,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.0951,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070205,
    shape_pt_lon: -90.026237,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.116,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070159,
    shape_pt_lon: -90.025904,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.1464,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070154,
    shape_pt_lon: -90.025761,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.1594,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070154,
    shape_pt_lon: -90.025606,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.1734,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070183,
    shape_pt_lon: -90.025323,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.1987,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07023,
    shape_pt_lon: -90.025072,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.2223,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0703,
    shape_pt_lon: -90.024831,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.2457,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070389,
    shape_pt_lon: -90.024597,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.2689,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070719,
    shape_pt_lon: -90.023901,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.342,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070876,
    shape_pt_lon: -90.023593,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.3748,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071074,
    shape_pt_lon: -90.023176,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.4178,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071081,
    shape_pt_lon: -90.023153,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.421,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071179,
    shape_pt_lon: -90.022872,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.4483,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071218,
    shape_pt_lon: -90.022713,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.4628,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071265,
    shape_pt_lon: -90.022378,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.4944,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071271,
    shape_pt_lon: -90.022053,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.5234,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07121,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.5957,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071206,
    shape_pt_lon: -90.0212,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.6008,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0712,
    shape_pt_lon: -90.021142,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.6058,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071139,
    shape_pt_lon: -90.020266,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.6861,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071136,
    shape_pt_lon: -90.019869,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.7211,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071168,
    shape_pt_lon: -90.019307,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.7722,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07128,
    shape_pt_lon: -90.018501,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.8464,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071528,
    shape_pt_lon: -90.017126,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.9743,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071579,
    shape_pt_lon: -90.016902,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.9952,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07164,
    shape_pt_lon: -90.016691,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.0154,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071893,
    shape_pt_lon: -90.015279,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.1464,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072041,
    shape_pt_lon: -90.014471,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.2214,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072051,
    shape_pt_lon: -90.014113,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.2534,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07225,
    shape_pt_lon: -90.012481,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.403,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072401,
    shape_pt_lon: -90.011536,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.4897,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072569,
    shape_pt_lon: -90.010626,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.5739,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072618,
    shape_pt_lon: -90.010231,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.6104,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072611,
    shape_pt_lon: -90.010063,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.6254,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072605,
    shape_pt_lon: -90.00998,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.6335,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072561,
    shape_pt_lon: -90.009731,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.656,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072451,
    shape_pt_lon: -90.009502,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.6802,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072352,
    shape_pt_lon: -90.009324,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.6996,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072,
    shape_pt_lon: -90.009013,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.7477,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07178,
    shape_pt_lon: -90.008891,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.775,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071618,
    shape_pt_lon: -90.008832,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.7939,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071152,
    shape_pt_lon: -90.008761,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.8463,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071013,
    shape_pt_lon: -90.008731,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.8626,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070938,
    shape_pt_lon: -90.008694,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.8711,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070847,
    shape_pt_lon: -90.008613,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.8833,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070749,
    shape_pt_lon: -90.008477,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.9003,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070729,
    shape_pt_lon: -90.008417,
    shape_pt_sequence: 271,
    shape_dist_traveled: 13.9062,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0707,
    shape_pt_lon: -90.008301,
    shape_pt_sequence: 272,
    shape_dist_traveled: 13.9176,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070688,
    shape_pt_lon: -90.008182,
    shape_pt_sequence: 273,
    shape_dist_traveled: 13.9276,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0707,
    shape_pt_lon: -90.008062,
    shape_pt_sequence: 274,
    shape_dist_traveled: 13.9387,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070732,
    shape_pt_lon: -90.007959,
    shape_pt_sequence: 275,
    shape_dist_traveled: 13.9494,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070893,
    shape_pt_lon: -90.007635,
    shape_pt_sequence: 276,
    shape_dist_traveled: 13.9836,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070949,
    shape_pt_lon: -90.007543,
    shape_pt_sequence: 277,
    shape_dist_traveled: 13.9936,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071014,
    shape_pt_lon: -90.007433,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.0058,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071077,
    shape_pt_lon: -90.007329,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.018,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071086,
    shape_pt_lon: -90.007261,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.0243,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071098,
    shape_pt_lon: -90.007181,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.0314,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071125,
    shape_pt_lon: -90.00713,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.0372,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071187,
    shape_pt_lon: -90.007003,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.0502,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071391,
    shape_pt_lon: -90.006514,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.0999,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071492,
    shape_pt_lon: -90.006175,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.1328,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071563,
    shape_pt_lon: -90.005839,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.1638,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07162,
    shape_pt_lon: -90.005419,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.2023,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071633,
    shape_pt_lon: -90.004946,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.2454,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0716,
    shape_pt_lon: -90.00442,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.2935,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071459,
    shape_pt_lon: -90.002772,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.4433,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071453,
    shape_pt_lon: -90.002708,
    shape_pt_sequence: 291,
    shape_dist_traveled: 14.4484,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071431,
    shape_pt_lon: -90.002417,
    shape_pt_sequence: 292,
    shape_dist_traveled: 14.4755,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071431,
    shape_pt_lon: -90.002111,
    shape_pt_sequence: 293,
    shape_dist_traveled: 14.5025,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071457,
    shape_pt_lon: -90.001836,
    shape_pt_sequence: 294,
    shape_dist_traveled: 14.5277,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071517,
    shape_pt_lon: -90.00151,
    shape_pt_sequence: 295,
    shape_dist_traveled: 14.5585,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071849,
    shape_pt_lon: -90.000145,
    shape_pt_sequence: 296,
    shape_dist_traveled: 14.687,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071912,
    shape_pt_lon: -89.999851,
    shape_pt_sequence: 297,
    shape_dist_traveled: 14.7149,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071945,
    shape_pt_lon: -89.999602,
    shape_pt_sequence: 298,
    shape_dist_traveled: 14.7372,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071958,
    shape_pt_lon: -89.999321,
    shape_pt_sequence: 299,
    shape_dist_traveled: 14.7633,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071946,
    shape_pt_lon: -89.999044,
    shape_pt_sequence: 300,
    shape_dist_traveled: 14.7884,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0719,
    shape_pt_lon: -89.998732,
    shape_pt_sequence: 301,
    shape_dist_traveled: 14.8168,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071768,
    shape_pt_lon: -89.998108,
    shape_pt_sequence: 302,
    shape_dist_traveled: 14.8748,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071749,
    shape_pt_lon: -89.997962,
    shape_pt_sequence: 303,
    shape_dist_traveled: 14.8889,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07173,
    shape_pt_lon: -89.997682,
    shape_pt_sequence: 304,
    shape_dist_traveled: 14.914,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071739,
    shape_pt_lon: -89.997306,
    shape_pt_sequence: 305,
    shape_dist_traveled: 14.948,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071772,
    shape_pt_lon: -89.997027,
    shape_pt_sequence: 306,
    shape_dist_traveled: 14.9734,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071829,
    shape_pt_lon: -89.996758,
    shape_pt_sequence: 307,
    shape_dist_traveled: 14.9981,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07191,
    shape_pt_lon: -89.996491,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.0247,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072001,
    shape_pt_lon: -89.996268,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.0475,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072101,
    shape_pt_lon: -89.996065,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.0686,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0722,
    shape_pt_lon: -89.995901,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.0872,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072337,
    shape_pt_lon: -89.99571,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.1099,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072415,
    shape_pt_lon: -89.995611,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.1226,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072798,
    shape_pt_lon: -89.99521,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.1787,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073,
    shape_pt_lon: -89.994951,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.2112,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073139,
    shape_pt_lon: -89.994729,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.2368,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073255,
    shape_pt_lon: -89.994497,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.2615,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073354,
    shape_pt_lon: -89.994227,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.2879,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07342,
    shape_pt_lon: -89.99399,
    shape_pt_sequence: 319,
    shape_dist_traveled: 15.3113,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073468,
    shape_pt_lon: -89.993731,
    shape_pt_sequence: 320,
    shape_dist_traveled: 15.3349,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073493,
    shape_pt_lon: -89.993452,
    shape_pt_sequence: 321,
    shape_dist_traveled: 15.3601,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073499,
    shape_pt_lon: -89.993338,
    shape_pt_sequence: 322,
    shape_dist_traveled: 15.3711,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073489,
    shape_pt_lon: -89.993062,
    shape_pt_sequence: 323,
    shape_dist_traveled: 15.3961,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073462,
    shape_pt_lon: -89.992806,
    shape_pt_sequence: 324,
    shape_dist_traveled: 15.4193,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07341,
    shape_pt_lon: -89.99255,
    shape_pt_sequence: 325,
    shape_dist_traveled: 15.4428,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073332,
    shape_pt_lon: -89.992276,
    shape_pt_sequence: 326,
    shape_dist_traveled: 15.4694,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073242,
    shape_pt_lon: -89.992047,
    shape_pt_sequence: 327,
    shape_dist_traveled: 15.4917,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.073126,
    shape_pt_lon: -89.991811,
    shape_pt_sequence: 328,
    shape_dist_traveled: 15.5173,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072979,
    shape_pt_lon: -89.991573,
    shape_pt_sequence: 329,
    shape_dist_traveled: 15.5443,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072777,
    shape_pt_lon: -89.991313,
    shape_pt_sequence: 330,
    shape_dist_traveled: 15.5769,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072611,
    shape_pt_lon: -89.991151,
    shape_pt_sequence: 331,
    shape_dist_traveled: 15.6005,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072431,
    shape_pt_lon: -89.99101,
    shape_pt_sequence: 332,
    shape_dist_traveled: 15.6243,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.07226,
    shape_pt_lon: -89.990899,
    shape_pt_sequence: 333,
    shape_dist_traveled: 15.6458,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.072211,
    shape_pt_lon: -89.990871,
    shape_pt_sequence: 334,
    shape_dist_traveled: 15.6525,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.071936,
    shape_pt_lon: -89.990756,
    shape_pt_sequence: 335,
    shape_dist_traveled: 15.6841,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070719,
    shape_pt_lon: -89.990358,
    shape_pt_sequence: 336,
    shape_dist_traveled: 15.8248,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.070185,
    shape_pt_lon: -89.990188,
    shape_pt_sequence: 337,
    shape_dist_traveled: 15.8869,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.069859,
    shape_pt_lon: -89.990118,
    shape_pt_sequence: 338,
    shape_dist_traveled: 15.9244,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.069619,
    shape_pt_lon: -89.990092,
    shape_pt_sequence: 339,
    shape_dist_traveled: 15.9506,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.069031,
    shape_pt_lon: -89.99003,
    shape_pt_sequence: 340,
    shape_dist_traveled: 16.0168,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.99002,
    shape_pt_sequence: 341,
    shape_dist_traveled: 16.0388,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068745,
    shape_pt_lon: -89.99002,
    shape_pt_sequence: 342,
    shape_dist_traveled: 16.0488,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068743,
    shape_pt_lon: -89.989959,
    shape_pt_sequence: 343,
    shape_dist_traveled: 16.0548,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068742,
    shape_pt_lon: -89.989903,
    shape_pt_sequence: 344,
    shape_dist_traveled: 16.0599,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068721,
    shape_pt_lon: -89.988469,
    shape_pt_sequence: 345,
    shape_dist_traveled: 16.1889,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06867,
    shape_pt_lon: -89.98838,
    shape_pt_sequence: 346,
    shape_dist_traveled: 16.1989,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068668,
    shape_pt_lon: -89.988261,
    shape_pt_sequence: 347,
    shape_dist_traveled: 16.2099,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068661,
    shape_pt_lon: -89.988133,
    shape_pt_sequence: 348,
    shape_dist_traveled: 16.2219,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068586,
    shape_pt_lon: -89.987919,
    shape_pt_sequence: 349,
    shape_dist_traveled: 16.2425,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987863,
    shape_pt_sequence: 350,
    shape_dist_traveled: 16.249,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068491,
    shape_pt_lon: -89.987824,
    shape_pt_sequence: 351,
    shape_dist_traveled: 16.257,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 352,
    shape_dist_traveled: 16.2733,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 353,
    shape_dist_traveled: 16.3727,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 354,
    shape_dist_traveled: 16.6967,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 355,
    shape_dist_traveled: 16.7813,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 356,
    shape_dist_traveled: 16.8332,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 357,
    shape_dist_traveled: 16.8828,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 358,
    shape_dist_traveled: 16.8848,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 359,
    shape_dist_traveled: 16.9131,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 360,
    shape_dist_traveled: 16.9605,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 361,
    shape_dist_traveled: 17.0139,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 362,
    shape_dist_traveled: 17.0476,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 363,
    shape_dist_traveled: 17.091,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 364,
    shape_dist_traveled: 17.113,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 365,
    shape_dist_traveled: 17.1641,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 366,
    shape_dist_traveled: 17.1858,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 367,
    shape_dist_traveled: 17.2042,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 368,
    shape_dist_traveled: 17.2178,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 369,
    shape_dist_traveled: 17.2362,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 370,
    shape_dist_traveled: 17.2558,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 371,
    shape_dist_traveled: 17.2723,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 372,
    shape_dist_traveled: 17.2953,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 373,
    shape_dist_traveled: 17.3193,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 374,
    shape_dist_traveled: 17.454,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 375,
    shape_dist_traveled: 17.5711,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 376,
    shape_dist_traveled: 17.5931,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 377,
    shape_dist_traveled: 17.6442,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060602,
    shape_pt_lon: -89.991619,
    shape_pt_sequence: 378,
    shape_dist_traveled: 17.7002,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 379,
    shape_dist_traveled: 17.7032,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.060524,
    shape_pt_lon: -89.991622,
    shape_pt_sequence: 380,
    shape_dist_traveled: 17.7092,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.059692,
    shape_pt_lon: -89.991643,
    shape_pt_sequence: 381,
    shape_dist_traveled: 17.8022,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.058269,
    shape_pt_lon: -89.991672,
    shape_pt_sequence: 382,
    shape_dist_traveled: 17.9622,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.057629,
    shape_pt_lon: -89.99164,
    shape_pt_sequence: 383,
    shape_dist_traveled: 18.0333,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.056766,
    shape_pt_lon: -89.991596,
    shape_pt_sequence: 384,
    shape_dist_traveled: 18.1304,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.055766,
    shape_pt_lon: -89.991538,
    shape_pt_sequence: 385,
    shape_dist_traveled: 18.2425,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.054833,
    shape_pt_lon: -89.991486,
    shape_pt_sequence: 386,
    shape_dist_traveled: 18.3466,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053527,
    shape_pt_lon: -89.991368,
    shape_pt_sequence: 387,
    shape_dist_traveled: 18.493,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 388,
    shape_dist_traveled: 18.5042,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 389,
    shape_dist_traveled: 18.5192,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 390,
    shape_dist_traveled: 18.5363,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 391,
    shape_dist_traveled: 18.5763,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 392,
    shape_dist_traveled: 18.6383,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 393,
    shape_dist_traveled: 18.6453,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 394,
    shape_dist_traveled: 18.6493,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 395,
    shape_dist_traveled: 18.6593,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 396,
    shape_dist_traveled: 18.6634,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053219,
    shape_pt_lon: -89.989648,
    shape_pt_sequence: 397,
    shape_dist_traveled: 18.6698,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053181,
    shape_pt_lon: -89.989544,
    shape_pt_sequence: 398,
    shape_dist_traveled: 18.681,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053183,
    shape_pt_lon: -89.989342,
    shape_pt_sequence: 399,
    shape_dist_traveled: 18.6991,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05318,
    shape_pt_lon: -89.989049,
    shape_pt_sequence: 400,
    shape_dist_traveled: 18.7251,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053148,
    shape_pt_lon: -89.988771,
    shape_pt_sequence: 401,
    shape_dist_traveled: 18.7512,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053141,
    shape_pt_lon: -89.988321,
    shape_pt_sequence: 402,
    shape_dist_traveled: 18.7913,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053123,
    shape_pt_lon: -89.987852,
    shape_pt_sequence: 403,
    shape_dist_traveled: 18.8343,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053078,
    shape_pt_lon: -89.987433,
    shape_pt_sequence: 404,
    shape_dist_traveled: 18.8726,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.053038,
    shape_pt_lon: -89.987203,
    shape_pt_sequence: 405,
    shape_dist_traveled: 18.893,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052961,
    shape_pt_lon: -89.986893,
    shape_pt_sequence: 406,
    shape_dist_traveled: 18.9224,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052779,
    shape_pt_lon: -89.986228,
    shape_pt_sequence: 407,
    shape_dist_traveled: 18.9866,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052499,
    shape_pt_lon: -89.985401,
    shape_pt_sequence: 408,
    shape_dist_traveled: 19.0673,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052328,
    shape_pt_lon: -89.984898,
    shape_pt_sequence: 409,
    shape_dist_traveled: 19.117,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0519,
    shape_pt_lon: -89.983553,
    shape_pt_sequence: 410,
    shape_dist_traveled: 19.2481,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0518,
    shape_pt_lon: -89.983311,
    shape_pt_sequence: 411,
    shape_dist_traveled: 19.2727,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05165,
    shape_pt_lon: -89.983016,
    shape_pt_sequence: 412,
    shape_dist_traveled: 19.3033,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051509,
    shape_pt_lon: -89.982791,
    shape_pt_sequence: 413,
    shape_dist_traveled: 19.3297,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051356,
    shape_pt_lon: -89.982592,
    shape_pt_sequence: 414,
    shape_dist_traveled: 19.3544,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051087,
    shape_pt_lon: -89.982307,
    shape_pt_sequence: 415,
    shape_dist_traveled: 19.3941,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050927,
    shape_pt_lon: -89.982178,
    shape_pt_sequence: 416,
    shape_dist_traveled: 19.4152,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050713,
    shape_pt_lon: -89.982048,
    shape_pt_sequence: 417,
    shape_dist_traveled: 19.442,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050537,
    shape_pt_lon: -89.981973,
    shape_pt_sequence: 418,
    shape_dist_traveled: 19.4632,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05041,
    shape_pt_lon: -89.981952,
    shape_pt_sequence: 419,
    shape_dist_traveled: 19.4774,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050193,
    shape_pt_lon: -89.981913,
    shape_pt_sequence: 420,
    shape_dist_traveled: 19.5016,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050001,
    shape_pt_lon: -89.981918,
    shape_pt_sequence: 421,
    shape_dist_traveled: 19.5236,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049893,
    shape_pt_lon: -89.98192,
    shape_pt_sequence: 422,
    shape_dist_traveled: 19.5356,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049563,
    shape_pt_lon: -89.981939,
    shape_pt_sequence: 423,
    shape_dist_traveled: 19.5726,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049538,
    shape_pt_lon: -89.981938,
    shape_pt_sequence: 424,
    shape_dist_traveled: 19.5756,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048854,
    shape_pt_lon: -89.981921,
    shape_pt_sequence: 425,
    shape_dist_traveled: 19.6516,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048593,
    shape_pt_lon: -89.981916,
    shape_pt_sequence: 426,
    shape_dist_traveled: 19.6806,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047912,
    shape_pt_lon: -89.981902,
    shape_pt_sequence: 427,
    shape_dist_traveled: 19.7577,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047666,
    shape_pt_lon: -89.9819,
    shape_pt_sequence: 428,
    shape_dist_traveled: 19.7847,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047419,
    shape_pt_lon: -89.981914,
    shape_pt_sequence: 429,
    shape_dist_traveled: 19.8127,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047319,
    shape_pt_lon: -89.98193,
    shape_pt_sequence: 430,
    shape_dist_traveled: 19.8238,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047213,
    shape_pt_lon: -89.98198,
    shape_pt_sequence: 431,
    shape_dist_traveled: 19.8368,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04717,
    shape_pt_lon: -89.982018,
    shape_pt_sequence: 432,
    shape_dist_traveled: 19.8426,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047109,
    shape_pt_lon: -89.982088,
    shape_pt_sequence: 433,
    shape_dist_traveled: 19.8511,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047091,
    shape_pt_lon: -89.982116,
    shape_pt_sequence: 434,
    shape_dist_traveled: 19.8547,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047069,
    shape_pt_lon: -89.982155,
    shape_pt_sequence: 435,
    shape_dist_traveled: 19.8589,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047016,
    shape_pt_lon: -89.982309,
    shape_pt_sequence: 436,
    shape_dist_traveled: 19.8742,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047006,
    shape_pt_lon: -89.982522,
    shape_pt_sequence: 437,
    shape_dist_traveled: 19.8942,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046995,
    shape_pt_lon: -89.982811,
    shape_pt_sequence: 438,
    shape_dist_traveled: 19.9202,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046959,
    shape_pt_lon: -89.982935,
    shape_pt_sequence: 439,
    shape_dist_traveled: 19.9319,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046903,
    shape_pt_lon: -89.983046,
    shape_pt_sequence: 440,
    shape_dist_traveled: 19.9441,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046859,
    shape_pt_lon: -89.9831,
    shape_pt_sequence: 441,
    shape_dist_traveled: 19.9512,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046818,
    shape_pt_lon: -89.983141,
    shape_pt_sequence: 442,
    shape_dist_traveled: 19.9569,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046778,
    shape_pt_lon: -89.983172,
    shape_pt_sequence: 443,
    shape_dist_traveled: 19.9622,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046726,
    shape_pt_lon: -89.983222,
    shape_pt_sequence: 444,
    shape_dist_traveled: 19.9693,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046579,
    shape_pt_lon: -89.983278,
    shape_pt_sequence: 445,
    shape_dist_traveled: 19.987,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046389,
    shape_pt_lon: -89.98333,
    shape_pt_sequence: 446,
    shape_dist_traveled: 20.0086,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046035,
    shape_pt_lon: -89.983397,
    shape_pt_sequence: 447,
    shape_dist_traveled: 20.0491,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045709,
    shape_pt_lon: -89.983405,
    shape_pt_sequence: 448,
    shape_dist_traveled: 20.0851,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045189,
    shape_pt_lon: -89.983386,
    shape_pt_sequence: 449,
    shape_dist_traveled: 20.1431,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044967,
    shape_pt_lon: -89.983381,
    shape_pt_sequence: 450,
    shape_dist_traveled: 20.1681,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044853,
    shape_pt_lon: -89.983298,
    shape_pt_sequence: 451,
    shape_dist_traveled: 20.1829,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044822,
    shape_pt_lon: -89.983245,
    shape_pt_sequence: 452,
    shape_dist_traveled: 20.1887,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044789,
    shape_pt_lon: -89.983143,
    shape_pt_sequence: 453,
    shape_dist_traveled: 20.1995,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044793,
    shape_pt_lon: -89.982721,
    shape_pt_sequence: 454,
    shape_dist_traveled: 20.2375,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044797,
    shape_pt_lon: -89.98247,
    shape_pt_sequence: 455,
    shape_dist_traveled: 20.2595,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044813,
    shape_pt_lon: -89.981654,
    shape_pt_sequence: 456,
    shape_dist_traveled: 20.3335,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04482,
    shape_pt_lon: -89.981018,
    shape_pt_sequence: 457,
    shape_dist_traveled: 20.3915,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044823,
    shape_pt_lon: -89.980573,
    shape_pt_sequence: 458,
    shape_dist_traveled: 20.4315,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044829,
    shape_pt_lon: -89.980233,
    shape_pt_sequence: 459,
    shape_dist_traveled: 20.4625,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044833,
    shape_pt_lon: -89.980198,
    shape_pt_sequence: 460,
    shape_dist_traveled: 20.4655,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044858,
    shape_pt_lon: -89.980097,
    shape_pt_sequence: 461,
    shape_dist_traveled: 20.475,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044876,
    shape_pt_lon: -89.980061,
    shape_pt_sequence: 462,
    shape_dist_traveled: 20.4786,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.044919,
    shape_pt_lon: -89.980001,
    shape_pt_sequence: 463,
    shape_dist_traveled: 20.4864,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045009,
    shape_pt_lon: -89.97994,
    shape_pt_sequence: 464,
    shape_dist_traveled: 20.4976,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045047,
    shape_pt_lon: -89.979937,
    shape_pt_sequence: 465,
    shape_dist_traveled: 20.5018,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04526,
    shape_pt_lon: -89.979941,
    shape_pt_sequence: 466,
    shape_dist_traveled: 20.5258,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045898,
    shape_pt_lon: -89.979953,
    shape_pt_sequence: 467,
    shape_dist_traveled: 20.5968,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046273,
    shape_pt_lon: -89.980003,
    shape_pt_sequence: 468,
    shape_dist_traveled: 20.6391,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04642,
    shape_pt_lon: -89.980047,
    shape_pt_sequence: 469,
    shape_dist_traveled: 20.6565,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046514,
    shape_pt_lon: -89.98008,
    shape_pt_sequence: 470,
    shape_dist_traveled: 20.667,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04701,
    shape_pt_lon: -89.980179,
    shape_pt_sequence: 471,
    shape_dist_traveled: 20.7237,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047052,
    shape_pt_lon: -89.980188,
    shape_pt_sequence: 472,
    shape_dist_traveled: 20.7277,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047134,
    shape_pt_lon: -89.980201,
    shape_pt_sequence: 473,
    shape_dist_traveled: 20.7369,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04739,
    shape_pt_lon: -89.980248,
    shape_pt_sequence: 474,
    shape_dist_traveled: 20.7662,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.047637,
    shape_pt_lon: -89.980254,
    shape_pt_sequence: 475,
    shape_dist_traveled: 20.7942,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04779,
    shape_pt_lon: -89.980259,
    shape_pt_sequence: 476,
    shape_dist_traveled: 20.8112,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048188,
    shape_pt_lon: -89.980269,
    shape_pt_sequence: 477,
    shape_dist_traveled: 20.8552,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04854,
    shape_pt_lon: -89.980281,
    shape_pt_sequence: 478,
    shape_dist_traveled: 20.8952,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048696,
    shape_pt_lon: -89.980286,
    shape_pt_sequence: 479,
    shape_dist_traveled: 20.9122,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048733,
    shape_pt_lon: -89.980287,
    shape_pt_sequence: 480,
    shape_dist_traveled: 20.9164,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048841,
    shape_pt_lon: -89.980293,
    shape_pt_sequence: 481,
    shape_dist_traveled: 20.9284,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049109,
    shape_pt_lon: -89.980302,
    shape_pt_sequence: 482,
    shape_dist_traveled: 20.9584,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049536,
    shape_pt_lon: -89.980311,
    shape_pt_sequence: 483,
    shape_dist_traveled: 21.0064,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049986,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 484,
    shape_dist_traveled: 21.0574,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050207,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 485,
    shape_dist_traveled: 21.0814,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05033,
    shape_pt_lon: -89.980337,
    shape_pt_sequence: 486,
    shape_dist_traveled: 21.0954,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050434,
    shape_pt_lon: -89.980363,
    shape_pt_sequence: 487,
    shape_dist_traveled: 21.1078,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.980413,
    shape_pt_sequence: 488,
    shape_dist_traveled: 21.1195,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050711,
    shape_pt_lon: -89.980539,
    shape_pt_sequence: 489,
    shape_dist_traveled: 21.1428,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050799,
    shape_pt_lon: -89.980647,
    shape_pt_sequence: 490,
    shape_dist_traveled: 21.1563,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050864,
    shape_pt_lon: -89.98075,
    shape_pt_sequence: 491,
    shape_dist_traveled: 21.1685,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050981,
    shape_pt_lon: -89.980795,
    shape_pt_sequence: 492,
    shape_dist_traveled: 21.1821,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051017,
    shape_pt_lon: -89.980809,
    shape_pt_sequence: 493,
    shape_dist_traveled: 21.1862,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05108,
    shape_pt_lon: -89.980819,
    shape_pt_sequence: 494,
    shape_dist_traveled: 21.1933,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05115,
    shape_pt_lon: -89.98081,
    shape_pt_sequence: 495,
    shape_dist_traveled: 21.2014,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05128,
    shape_pt_lon: -89.980756,
    shape_pt_sequence: 496,
    shape_dist_traveled: 21.2162,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051385,
    shape_pt_lon: -89.980642,
    shape_pt_sequence: 497,
    shape_dist_traveled: 21.2318,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051477,
    shape_pt_lon: -89.980455,
    shape_pt_sequence: 498,
    shape_dist_traveled: 21.2516,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051509,
    shape_pt_lon: -89.980371,
    shape_pt_sequence: 499,
    shape_dist_traveled: 21.2605,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051746,
    shape_pt_lon: -89.980002,
    shape_pt_sequence: 500,
    shape_dist_traveled: 21.3031,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051791,
    shape_pt_lon: -89.97995,
    shape_pt_sequence: 501,
    shape_dist_traveled: 21.3102,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.051907,
    shape_pt_lon: -89.9798,
    shape_pt_sequence: 502,
    shape_dist_traveled: 21.3286,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052019,
    shape_pt_lon: -89.979611,
    shape_pt_sequence: 503,
    shape_dist_traveled: 21.3494,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052119,
    shape_pt_lon: -89.97938,
    shape_pt_sequence: 504,
    shape_dist_traveled: 21.3731,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.979097,
    shape_pt_sequence: 505,
    shape_dist_traveled: 21.4014,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052244,
    shape_pt_lon: -89.978727,
    shape_pt_sequence: 506,
    shape_dist_traveled: 21.4345,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0523,
    shape_pt_lon: -89.978025,
    shape_pt_sequence: 507,
    shape_dist_traveled: 21.4989,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052319,
    shape_pt_lon: -89.977789,
    shape_pt_sequence: 508,
    shape_dist_traveled: 21.52,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052351,
    shape_pt_lon: -89.977427,
    shape_pt_sequence: 509,
    shape_dist_traveled: 21.5531,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052415,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 510,
    shape_dist_traveled: 21.6445,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.975876,
    shape_pt_sequence: 511,
    shape_dist_traveled: 21.6937,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052529,
    shape_pt_lon: -89.974982,
    shape_pt_sequence: 512,
    shape_dist_traveled: 21.7751,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.97467,
    shape_pt_sequence: 513,
    shape_dist_traveled: 21.8032,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052572,
    shape_pt_lon: -89.974416,
    shape_pt_sequence: 514,
    shape_dist_traveled: 21.8263,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052701,
    shape_pt_lon: -89.972892,
    shape_pt_sequence: 515,
    shape_dist_traveled: 21.9651,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052722,
    shape_pt_lon: -89.972547,
    shape_pt_sequence: 516,
    shape_dist_traveled: 21.9961,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052734,
    shape_pt_lon: -89.971971,
    shape_pt_sequence: 517,
    shape_dist_traveled: 22.0482,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05266,
    shape_pt_lon: -89.969172,
    shape_pt_sequence: 518,
    shape_dist_traveled: 22.3013,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052654,
    shape_pt_lon: -89.968527,
    shape_pt_sequence: 519,
    shape_dist_traveled: 22.3603,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052637,
    shape_pt_lon: -89.967793,
    shape_pt_sequence: 520,
    shape_dist_traveled: 22.4263,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052608,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 521,
    shape_dist_traveled: 22.5294,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052573,
    shape_pt_lon: -89.965423,
    shape_pt_sequence: 522,
    shape_dist_traveled: 22.6415,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052555,
    shape_pt_lon: -89.964531,
    shape_pt_sequence: 523,
    shape_dist_traveled: 22.7215,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052536,
    shape_pt_lon: -89.963986,
    shape_pt_sequence: 524,
    shape_dist_traveled: 22.7715,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05253,
    shape_pt_lon: -89.963778,
    shape_pt_sequence: 525,
    shape_dist_traveled: 22.7895,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.963048,
    shape_pt_sequence: 526,
    shape_dist_traveled: 22.8555,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.05245,
    shape_pt_lon: -89.961156,
    shape_pt_sequence: 527,
    shape_dist_traveled: 23.0277,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052413,
    shape_pt_lon: -89.960378,
    shape_pt_sequence: 528,
    shape_dist_traveled: 23.0978,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052393,
    shape_pt_lon: -89.959713,
    shape_pt_sequence: 529,
    shape_dist_traveled: 23.1578,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052401,
    shape_pt_lon: -89.959699,
    shape_pt_sequence: 530,
    shape_dist_traveled: 23.1593,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 531,
    shape_dist_traveled: 23.1709,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 532,
    shape_dist_traveled: 23.1789,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 533,
    shape_dist_traveled: 23.336,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 534,
    shape_dist_traveled: 23.5471,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 535,
    shape_dist_traveled: 23.6042,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.052239,
    shape_pt_lon: -89.954808,
    shape_pt_sequence: 536,
    shape_dist_traveled: 23.6132,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0518,
    shape_pt_lon: -89.954808,
    shape_pt_sequence: 537,
    shape_dist_traveled: 23.6622,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050789,
    shape_pt_lon: -89.954808,
    shape_pt_sequence: 538,
    shape_dist_traveled: 23.7752,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.050254,
    shape_pt_lon: -89.954808,
    shape_pt_sequence: 539,
    shape_dist_traveled: 23.8352,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.049003,
    shape_pt_lon: -89.95481,
    shape_pt_sequence: 540,
    shape_dist_traveled: 23.9752,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048667,
    shape_pt_lon: -89.954823,
    shape_pt_sequence: 541,
    shape_dist_traveled: 24.0132,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.048352,
    shape_pt_lon: -89.954821,
    shape_pt_sequence: 542,
    shape_dist_traveled: 24.0482,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.046949,
    shape_pt_lon: -89.954806,
    shape_pt_sequence: 543,
    shape_dist_traveled: 24.2052,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.0468,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 544,
    shape_dist_traveled: 24.2212,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.045832,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 545,
    shape_dist_traveled: 24.3302,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04409,
    shape_pt_lon: -89.954799,
    shape_pt_sequence: 546,
    shape_dist_traveled: 24.5252,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043122,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 547,
    shape_dist_traveled: 24.6332,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043107,
    shape_pt_lon: -89.953143,
    shape_pt_sequence: 548,
    shape_dist_traveled: 24.7823,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043097,
    shape_pt_lon: -89.952144,
    shape_pt_sequence: 549,
    shape_dist_traveled: 24.8723,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04309,
    shape_pt_lon: -89.951115,
    shape_pt_sequence: 550,
    shape_dist_traveled: 24.9653,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043071,
    shape_pt_lon: -89.948242,
    shape_pt_sequence: 551,
    shape_dist_traveled: 25.2253,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043101,
    shape_pt_lon: -89.947932,
    shape_pt_sequence: 552,
    shape_dist_traveled: 25.2536,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04311,
    shape_pt_lon: -89.947878,
    shape_pt_sequence: 553,
    shape_dist_traveled: 25.2587,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043209,
    shape_pt_lon: -89.947391,
    shape_pt_sequence: 554,
    shape_dist_traveled: 25.304,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043254,
    shape_pt_lon: -89.947092,
    shape_pt_sequence: 555,
    shape_dist_traveled: 25.3315,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043267,
    shape_pt_lon: -89.946868,
    shape_pt_sequence: 556,
    shape_dist_traveled: 25.3515,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043271,
    shape_pt_lon: -89.946473,
    shape_pt_sequence: 557,
    shape_dist_traveled: 25.3875,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043258,
    shape_pt_lon: -89.945294,
    shape_pt_sequence: 558,
    shape_dist_traveled: 25.4945,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043243,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 559,
    shape_dist_traveled: 25.6545,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043193,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 560,
    shape_dist_traveled: 25.6605,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.043047,
    shape_pt_lon: -89.943522,
    shape_pt_sequence: 561,
    shape_dist_traveled: 25.6765,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.042767,
    shape_pt_lon: -89.943528,
    shape_pt_sequence: 562,
    shape_dist_traveled: 25.7075,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.041907,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 563,
    shape_dist_traveled: 25.8045,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.041359,
    shape_pt_lon: -89.943552,
    shape_pt_sequence: 564,
    shape_dist_traveled: 25.8655,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.04074,
    shape_pt_lon: -89.943536,
    shape_pt_sequence: 565,
    shape_dist_traveled: 25.9345,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.040042,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 566,
    shape_dist_traveled: 26.0125,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.03833,
    shape_pt_lon: -89.943557,
    shape_pt_sequence: 567,
    shape_dist_traveled: 26.2046,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.037132,
    shape_pt_lon: -89.943576,
    shape_pt_sequence: 568,
    shape_dist_traveled: 26.3386,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.036928,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 569,
    shape_dist_traveled: 26.3616,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.03688,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 570,
    shape_dist_traveled: 26.3666,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.036793,
    shape_pt_lon: -89.943572,
    shape_pt_sequence: 571,
    shape_dist_traveled: 26.3766,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.036286,
    shape_pt_lon: -89.943594,
    shape_pt_sequence: 572,
    shape_dist_traveled: 26.4326,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.033184,
    shape_pt_lon: -89.943628,
    shape_pt_sequence: 573,
    shape_dist_traveled: 26.7806,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.032065,
    shape_pt_lon: -89.943648,
    shape_pt_sequence: 574,
    shape_dist_traveled: 26.9057,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.032069,
    shape_pt_lon: -89.944691,
    shape_pt_sequence: 575,
    shape_dist_traveled: 26.9997,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.032079,
    shape_pt_lon: -89.945016,
    shape_pt_sequence: 576,
    shape_dist_traveled: 27.0287,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.032074,
    shape_pt_lon: -89.945287,
    shape_pt_sequence: 577,
    shape_dist_traveled: 27.0537,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.031258,
    shape_pt_lon: -89.9453,
    shape_pt_sequence: 578,
    shape_dist_traveled: 27.1447,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.030417,
    shape_pt_lon: -89.945314,
    shape_pt_sequence: 579,
    shape_dist_traveled: 27.2387,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.029614,
    shape_pt_lon: -89.94533,
    shape_pt_sequence: 580,
    shape_dist_traveled: 27.3287,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.028811,
    shape_pt_lon: -89.94534,
    shape_pt_sequence: 581,
    shape_dist_traveled: 27.4187,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027987,
    shape_pt_lon: -89.945346,
    shape_pt_sequence: 582,
    shape_dist_traveled: 27.5107,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027993,
    shape_pt_lon: -89.945854,
    shape_pt_sequence: 583,
    shape_dist_traveled: 27.5567,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.94633,
    shape_pt_sequence: 584,
    shape_dist_traveled: 27.5998,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.946853,
    shape_pt_sequence: 585,
    shape_dist_traveled: 27.6468,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027963,
    shape_pt_lon: -89.947081,
    shape_pt_sequence: 586,
    shape_dist_traveled: 27.6678,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027966,
    shape_pt_lon: -89.948133,
    shape_pt_sequence: 587,
    shape_dist_traveled: 27.7628,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027967,
    shape_pt_lon: -89.949072,
    shape_pt_sequence: 588,
    shape_dist_traveled: 27.8478,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94997,
    shape_pt_sequence: 589,
    shape_dist_traveled: 27.9288,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.950879,
    shape_pt_sequence: 590,
    shape_dist_traveled: 28.0108,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.953473,
    shape_pt_sequence: 591,
    shape_dist_traveled: 28.2458,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.954728,
    shape_pt_sequence: 592,
    shape_dist_traveled: 28.3598,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027836,
    shape_pt_lon: -89.954737,
    shape_pt_sequence: 593,
    shape_dist_traveled: 28.3748,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.027319,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 594,
    shape_dist_traveled: 28.4329,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.02707,
    shape_pt_lon: -89.954757,
    shape_pt_sequence: 595,
    shape_dist_traveled: 28.4609,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.02614,
    shape_pt_lon: -89.954652,
    shape_pt_sequence: 596,
    shape_dist_traveled: 28.5654,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.025685,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 597,
    shape_dist_traveled: 28.6164,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.024822,
    shape_pt_lon: -89.954638,
    shape_pt_sequence: 598,
    shape_dist_traveled: 28.7124,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.024258,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 599,
    shape_dist_traveled: 28.7764,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.022747,
    shape_pt_lon: -89.954625,
    shape_pt_sequence: 600,
    shape_dist_traveled: 28.9454,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.020812,
    shape_pt_lon: -89.954586,
    shape_pt_sequence: 601,
    shape_dist_traveled: 29.1615,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 602,
    shape_dist_traveled: 29.1675,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.954572,
    shape_pt_sequence: 603,
    shape_dist_traveled: 29.1776,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.020113,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 604,
    shape_dist_traveled: 29.2396,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.017091,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 605,
    shape_dist_traveled: 29.5776,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.016532,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 606,
    shape_dist_traveled: 29.6406,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.015599,
    shape_pt_lon: -89.954564,
    shape_pt_sequence: 607,
    shape_dist_traveled: 29.7446,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.014964,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 608,
    shape_dist_traveled: 29.8156,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.01411,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 609,
    shape_dist_traveled: 29.9116,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.013438,
    shape_pt_lon: -89.954563,
    shape_pt_sequence: 610,
    shape_dist_traveled: 29.9866,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.01126,
    shape_pt_lon: -89.954553,
    shape_pt_sequence: 611,
    shape_dist_traveled: 30.2306,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.009323,
    shape_pt_lon: -89.954539,
    shape_pt_sequence: 612,
    shape_dist_traveled: 30.4476,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006199,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 613,
    shape_dist_traveled: 30.7966,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006188,
    shape_pt_lon: -89.954324,
    shape_pt_sequence: 614,
    shape_dist_traveled: 30.8176,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006159,
    shape_pt_lon: -89.951597,
    shape_pt_sequence: 615,
    shape_dist_traveled: 31.0647,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006155,
    shape_pt_lon: -89.949916,
    shape_pt_sequence: 616,
    shape_dist_traveled: 31.2167,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006146,
    shape_pt_lon: -89.94867,
    shape_pt_sequence: 617,
    shape_dist_traveled: 31.3297,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006144,
    shape_pt_lon: -89.948532,
    shape_pt_sequence: 618,
    shape_dist_traveled: 31.3417,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006117,
    shape_pt_lon: -89.944809,
    shape_pt_sequence: 619,
    shape_dist_traveled: 31.6787,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006095,
    shape_pt_lon: -89.943923,
    shape_pt_sequence: 620,
    shape_dist_traveled: 31.7587,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006077,
    shape_pt_lon: -89.942096,
    shape_pt_sequence: 621,
    shape_dist_traveled: 31.9248,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.00607,
    shape_pt_lon: -89.941451,
    shape_pt_sequence: 622,
    shape_dist_traveled: 31.9828,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006069,
    shape_pt_lon: -89.941305,
    shape_pt_sequence: 623,
    shape_dist_traveled: 31.9958,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -89.940202,
    shape_pt_sequence: 624,
    shape_dist_traveled: 32.0958,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006052,
    shape_pt_lon: -89.939112,
    shape_pt_sequence: 625,
    shape_dist_traveled: 32.1948,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006062,
    shape_pt_lon: -89.938412,
    shape_pt_sequence: 626,
    shape_dist_traveled: 32.2578,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.00604,
    shape_pt_lon: -89.937121,
    shape_pt_sequence: 627,
    shape_dist_traveled: 32.3749,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 628,
    shape_dist_traveled: 32.4029,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 629,
    shape_dist_traveled: 32.6259,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 630,
    shape_dist_traveled: 32.6679,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 631,
    shape_dist_traveled: 32.6809,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 632,
    shape_dist_traveled: 32.7599,
  },
  {
    shape_id: 38205,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 633,
    shape_dist_traveled: 32.8609,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151659,
    shape_pt_lon: -90.048161,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6738,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7342,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8474,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8573,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152488,
    shape_pt_lon: -90.046991,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8662,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152283,
    shape_pt_lon: -90.046166,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9437,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.152039,
    shape_pt_lon: -90.04529,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0272,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15199,
    shape_pt_lon: -90.045069,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0478,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15194,
    shape_pt_lon: -90.044781,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0745,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151879,
    shape_pt_lon: -90.044553,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0966,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151786,
    shape_pt_lon: -90.044168,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.133,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15171,
    shape_pt_lon: -90.043738,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1721,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151649,
    shape_pt_lon: -90.043198,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2214,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151644,
    shape_pt_lon: -90.043137,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.2275,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151621,
    shape_pt_lon: -90.042832,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2547,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151557,
    shape_pt_lon: -90.041956,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.334,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151526,
    shape_pt_lon: -90.041669,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3602,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151447,
    shape_pt_lon: -90.041024,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4198,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151426,
    shape_pt_lon: -90.040848,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.436,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151421,
    shape_pt_lon: -90.040809,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.4391,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151391,
    shape_pt_lon: -90.040546,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.4633,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15117,
    shape_pt_lon: -90.038689,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6332,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.151069,
    shape_pt_lon: -90.037964,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.6991,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150801,
    shape_pt_lon: -90.036041,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8757,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150712,
    shape_pt_lon: -90.035343,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9394,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150549,
    shape_pt_lon: -90.034183,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.046,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.1504,
    shape_pt_lon: -90.033068,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1474,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150276,
    shape_pt_lon: -90.032141,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.2326,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150243,
    shape_pt_lon: -90.031923,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2528,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150209,
    shape_pt_lon: -90.031667,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.2761,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150089,
    shape_pt_lon: -90.03075,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.3603,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15007,
    shape_pt_lon: -90.030583,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3754,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150017,
    shape_pt_lon: -90.029869,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4397,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150016,
    shape_pt_lon: -90.029738,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4517,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150018,
    shape_pt_lon: -90.029482,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.4747,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.15002,
    shape_pt_lon: -90.029239,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4967,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150062,
    shape_pt_lon: -90.028061,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.6038,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150058,
    shape_pt_lon: -90.027697,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.6368,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.150037,
    shape_pt_lon: -90.027452,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.6589,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149806,
    shape_pt_lon: -90.025788,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.8111,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149663,
    shape_pt_lon: -90.025269,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.8608,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149633,
    shape_pt_lon: -90.025156,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8712,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149528,
    shape_pt_lon: -90.024774,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.9082,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149404,
    shape_pt_lon: -90.024408,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.9441,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149368,
    shape_pt_lon: -90.024323,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.953,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149293,
    shape_pt_lon: -90.024187,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.9675,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.149086,
    shape_pt_lon: -90.023883,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.0036,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.148802,
    shape_pt_lon: -90.023576,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.0453,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.148521,
    shape_pt_lon: -90.023332,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.0842,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.148278,
    shape_pt_lon: -90.023207,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.1133,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.148058,
    shape_pt_lon: -90.023128,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.1396,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.147763,
    shape_pt_lon: -90.02305,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.1733,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.146873,
    shape_pt_lon: -90.022813,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.2745,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.146458,
    shape_pt_lon: -90.022712,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.3224,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.146356,
    shape_pt_lon: -90.02269,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.3336,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.145907,
    shape_pt_lon: -90.022613,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.384,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.145599,
    shape_pt_lon: -90.022588,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.4191,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.144798,
    shape_pt_lon: -90.022572,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.5081,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.144583,
    shape_pt_lon: -90.022598,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.5322,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.14407,
    shape_pt_lon: -90.022657,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.5905,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.144012,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.5965,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.142236,
    shape_pt_lon: -90.02291,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.7967,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.141529,
    shape_pt_lon: -90.023041,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.8756,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.141245,
    shape_pt_lon: -90.023094,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.908,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.140751,
    shape_pt_lon: -90.02323,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.9643,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.14028,
    shape_pt_lon: -90.023398,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.0194,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.139605,
    shape_pt_lon: -90.023703,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.0995,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.139306,
    shape_pt_lon: -90.023841,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.1355,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.138819,
    shape_pt_lon: -90.024047,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.1928,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.138752,
    shape_pt_lon: -90.024067,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.201,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.138613,
    shape_pt_lon: -90.024108,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.2163,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.138335,
    shape_pt_lon: -90.024191,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.2493,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.024286,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.3031,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.137604,
    shape_pt_lon: -90.024308,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.3311,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.137372,
    shape_pt_lon: -90.02433,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.3571,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.136893,
    shape_pt_lon: -90.024342,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.4112,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.136311,
    shape_pt_lon: -90.024336,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.4762,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.136073,
    shape_pt_lon: -90.024329,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.5032,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.135953,
    shape_pt_lon: -90.024329,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.5162,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.135156,
    shape_pt_lon: -90.024303,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.6052,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.134731,
    shape_pt_lon: -90.024275,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.6533,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.13408,
    shape_pt_lon: -90.024241,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.7264,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.133017,
    shape_pt_lon: -90.024199,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.8455,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.132828,
    shape_pt_lon: -90.024193,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.8665,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.132642,
    shape_pt_lon: -90.024177,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.8875,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.132526,
    shape_pt_lon: -90.024167,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.9006,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.130891,
    shape_pt_lon: -90.024069,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.0838,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.130679,
    shape_pt_lon: -90.024067,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.1068,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.1368,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.130175,
    shape_pt_lon: -90.024041,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.1638,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.130083,
    shape_pt_lon: -90.024037,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.1738,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.129701,
    shape_pt_lon: -90.024001,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.217,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.128273,
    shape_pt_lon: -90.023921,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.3762,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.127775,
    shape_pt_lon: -90.023913,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.4322,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.127324,
    shape_pt_lon: -90.023943,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.4833,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.126897,
    shape_pt_lon: -90.024029,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.5309,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.12673,
    shape_pt_lon: -90.024076,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.5503,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.126512,
    shape_pt_lon: -90.024159,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.5763,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.126141,
    shape_pt_lon: -90.024318,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.62,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.125228,
    shape_pt_lon: -90.024777,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.7299,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.12508,
    shape_pt_lon: -90.024853,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.7483,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.12492,
    shape_pt_lon: -90.024937,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.768,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.124821,
    shape_pt_lon: -90.024987,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.7797,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.124042,
    shape_pt_lon: -90.02537,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.8735,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.12393,
    shape_pt_lon: -90.025421,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.8871,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.123631,
    shape_pt_lon: -90.025538,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.9218,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.123288,
    shape_pt_lon: -90.025643,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.9609,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.123099,
    shape_pt_lon: -90.025685,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.9833,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.122738,
    shape_pt_lon: -90.025741,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.0236,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.12165,
    shape_pt_lon: -90.025809,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.1457,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.121288,
    shape_pt_lon: -90.025828,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.1858,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.025893,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.3309,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.119553,
    shape_pt_lon: -90.025944,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.3801,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.11925,
    shape_pt_lon: -90.026006,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.4146,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.119128,
    shape_pt_lon: -90.026038,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.4289,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.11891,
    shape_pt_lon: -90.02611,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.4536,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.118792,
    shape_pt_lon: -90.026151,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.4672,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.118392,
    shape_pt_lon: -90.026325,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.515,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.116389,
    shape_pt_lon: -90.027429,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.7599,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.115599,
    shape_pt_lon: -90.027889,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.8583,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.8776,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.115087,
    shape_pt_lon: -90.028145,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.9198,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.114422,
    shape_pt_lon: -90.028451,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.9998,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.113888,
    shape_pt_lon: -90.028656,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.0615,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.113372,
    shape_pt_lon: -90.028816,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.1214,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.112813,
    shape_pt_lon: -90.02895,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.1856,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.11227,
    shape_pt_lon: -90.029048,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.2462,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.111749,
    shape_pt_lon: -90.029106,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.3054,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.111593,
    shape_pt_lon: -90.029112,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.3224,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.110665,
    shape_pt_lon: -90.029161,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.4266,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.110022,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.4987,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.109867,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.5157,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.109786,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.5247,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.109684,
    shape_pt_lon: -90.029215,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.5367,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.109511,
    shape_pt_lon: -90.029221,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.5557,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.10805,
    shape_pt_lon: -90.029268,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.7198,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.107246,
    shape_pt_lon: -90.0293,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.8098,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.107127,
    shape_pt_lon: -90.029303,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.8228,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.107018,
    shape_pt_lon: -90.02931,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.8349,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.106176,
    shape_pt_lon: -90.029343,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.9289,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.105893,
    shape_pt_lon: -90.029353,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.9609,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.104473,
    shape_pt_lon: -90.029411,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.12,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.102841,
    shape_pt_lon: -90.02948,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.3021,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.102165,
    shape_pt_lon: -90.029486,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.3781,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.101833,
    shape_pt_lon: -90.029463,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.4152,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.101438,
    shape_pt_lon: -90.029405,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.4596,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.101019,
    shape_pt_lon: -90.029302,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.5074,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.10083,
    shape_pt_lon: -90.029237,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.5293,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.100497,
    shape_pt_lon: -90.029109,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.5688,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.100251,
    shape_pt_lon: -90.028993,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.598,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.099275,
    shape_pt_lon: -90.028492,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.7159,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.098243,
    shape_pt_lon: -90.02797,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8414,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.097093,
    shape_pt_lon: -90.027392,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.9805,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.096366,
    shape_pt_lon: -90.027056,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.0669,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.095526,
    shape_pt_lon: -90.026721,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.1659,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.095316,
    shape_pt_lon: -90.026641,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.1899,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.094821,
    shape_pt_lon: -90.026467,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.2482,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.094123,
    shape_pt_lon: -90.026259,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.3282,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.09402,
    shape_pt_lon: -90.026229,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.3396,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.093127,
    shape_pt_lon: -90.025993,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.442,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.092767,
    shape_pt_lon: -90.025916,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.4836,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.091809,
    shape_pt_lon: -90.02575,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.5916,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.091067,
    shape_pt_lon: -90.025667,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.6749,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.090443,
    shape_pt_lon: -90.025614,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.7451,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.089918,
    shape_pt_lon: -90.025589,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.8032,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.089386,
    shape_pt_lon: -90.025573,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.8632,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.088676,
    shape_pt_lon: -90.025582,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.9422,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.086949,
    shape_pt_lon: -90.025614,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.1352,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.086578,
    shape_pt_lon: -90.025618,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.1772,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.086203,
    shape_pt_lon: -90.025628,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.2192,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.084173,
    shape_pt_lon: -90.025658,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.4463,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.5233,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.083443,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.5283,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.083411,
    shape_pt_lon: -90.025697,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.5313,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.082426,
    shape_pt_lon: -90.025692,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.6423,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.081146,
    shape_pt_lon: -90.02571,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.7853,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.080069,
    shape_pt_lon: -90.025736,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.9054,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.079666,
    shape_pt_lon: -90.025759,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.9504,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.079364,
    shape_pt_lon: -90.025788,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.9845,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.079139,
    shape_pt_lon: -90.025824,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.0096,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.078848,
    shape_pt_lon: -90.02588,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.0432,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.078179,
    shape_pt_lon: -90.026026,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.1193,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.077706,
    shape_pt_lon: -90.026155,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.1734,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.077226,
    shape_pt_lon: -90.026316,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.2285,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.076924,
    shape_pt_lon: -90.026446,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.2646,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.076809,
    shape_pt_lon: -90.026508,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2785,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.075429,
    shape_pt_lon: -90.0272,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.4458,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.075227,
    shape_pt_lon: -90.027292,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.4692,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.075074,
    shape_pt_lon: -90.027361,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.4872,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.074891,
    shape_pt_lon: -90.027435,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.5094,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.074617,
    shape_pt_lon: -90.027547,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.541,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.074126,
    shape_pt_lon: -90.027709,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.598,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073391,
    shape_pt_lon: -90.027918,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.6832,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072952,
    shape_pt_lon: -90.027998,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.7327,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072829,
    shape_pt_lon: -90.028017,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.7457,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0727,
    shape_pt_lon: -90.028037,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.7608,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072481,
    shape_pt_lon: -90.028059,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.7849,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071836,
    shape_pt_lon: -90.028,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.8581,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071623,
    shape_pt_lon: -90.027953,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.8814,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071419,
    shape_pt_lon: -90.027882,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.9055,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071223,
    shape_pt_lon: -90.027785,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.9292,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071039,
    shape_pt_lon: -90.02766,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.9529,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070868,
    shape_pt_lon: -90.027512,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.976,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070711,
    shape_pt_lon: -90.027342,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.9993,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070579,
    shape_pt_lon: -90.027165,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.0212,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070452,
    shape_pt_lon: -90.026956,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.0448,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07035,
    shape_pt_lon: -90.026731,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.0682,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070256,
    shape_pt_lon: -90.026457,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.0951,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070205,
    shape_pt_lon: -90.026237,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.116,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070159,
    shape_pt_lon: -90.025904,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.1464,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070154,
    shape_pt_lon: -90.025761,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.1594,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070154,
    shape_pt_lon: -90.025606,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.1734,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070183,
    shape_pt_lon: -90.025323,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.1987,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07023,
    shape_pt_lon: -90.025072,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.2223,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0703,
    shape_pt_lon: -90.024831,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.2457,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070389,
    shape_pt_lon: -90.024597,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.2689,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070719,
    shape_pt_lon: -90.023901,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.342,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070876,
    shape_pt_lon: -90.023593,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.3748,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071074,
    shape_pt_lon: -90.023176,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.4178,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071081,
    shape_pt_lon: -90.023153,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.421,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071179,
    shape_pt_lon: -90.022872,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.4483,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071218,
    shape_pt_lon: -90.022713,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.4628,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071265,
    shape_pt_lon: -90.022378,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.4944,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071271,
    shape_pt_lon: -90.022053,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.5234,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07121,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.5957,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071206,
    shape_pt_lon: -90.0212,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.6008,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0712,
    shape_pt_lon: -90.021142,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.6058,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071139,
    shape_pt_lon: -90.020266,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.6861,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071136,
    shape_pt_lon: -90.019869,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.7211,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071168,
    shape_pt_lon: -90.019307,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.7722,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07128,
    shape_pt_lon: -90.018501,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.8464,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071528,
    shape_pt_lon: -90.017126,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.9743,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071579,
    shape_pt_lon: -90.016902,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.9952,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07164,
    shape_pt_lon: -90.016691,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.0154,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071893,
    shape_pt_lon: -90.015279,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.1464,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072041,
    shape_pt_lon: -90.014471,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.2214,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072051,
    shape_pt_lon: -90.014113,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.2534,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07225,
    shape_pt_lon: -90.012481,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.403,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072401,
    shape_pt_lon: -90.011536,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.4897,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072569,
    shape_pt_lon: -90.010626,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.5739,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072618,
    shape_pt_lon: -90.010231,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.6104,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072611,
    shape_pt_lon: -90.010063,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.6254,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072605,
    shape_pt_lon: -90.00998,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.6335,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072561,
    shape_pt_lon: -90.009731,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.656,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072451,
    shape_pt_lon: -90.009502,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.6802,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072352,
    shape_pt_lon: -90.009324,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.6996,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072,
    shape_pt_lon: -90.009013,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.7477,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07178,
    shape_pt_lon: -90.008891,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.775,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071618,
    shape_pt_lon: -90.008832,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.7939,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071152,
    shape_pt_lon: -90.008761,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.8463,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071013,
    shape_pt_lon: -90.008731,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.8626,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070938,
    shape_pt_lon: -90.008694,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.8711,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070847,
    shape_pt_lon: -90.008613,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.8833,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070749,
    shape_pt_lon: -90.008477,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.9003,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070729,
    shape_pt_lon: -90.008417,
    shape_pt_sequence: 271,
    shape_dist_traveled: 13.9062,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0707,
    shape_pt_lon: -90.008301,
    shape_pt_sequence: 272,
    shape_dist_traveled: 13.9176,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070688,
    shape_pt_lon: -90.008182,
    shape_pt_sequence: 273,
    shape_dist_traveled: 13.9276,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0707,
    shape_pt_lon: -90.008062,
    shape_pt_sequence: 274,
    shape_dist_traveled: 13.9387,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070732,
    shape_pt_lon: -90.007959,
    shape_pt_sequence: 275,
    shape_dist_traveled: 13.9494,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070893,
    shape_pt_lon: -90.007635,
    shape_pt_sequence: 276,
    shape_dist_traveled: 13.9836,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070949,
    shape_pt_lon: -90.007543,
    shape_pt_sequence: 277,
    shape_dist_traveled: 13.9936,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071014,
    shape_pt_lon: -90.007433,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.0058,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071077,
    shape_pt_lon: -90.007329,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.018,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071086,
    shape_pt_lon: -90.007261,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.0243,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071098,
    shape_pt_lon: -90.007181,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.0314,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071125,
    shape_pt_lon: -90.00713,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.0372,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071187,
    shape_pt_lon: -90.007003,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.0502,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071391,
    shape_pt_lon: -90.006514,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.0999,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071492,
    shape_pt_lon: -90.006175,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.1328,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071563,
    shape_pt_lon: -90.005839,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.1638,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07162,
    shape_pt_lon: -90.005419,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.2023,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071633,
    shape_pt_lon: -90.004946,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.2454,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0716,
    shape_pt_lon: -90.00442,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.2935,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071459,
    shape_pt_lon: -90.002772,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.4433,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071453,
    shape_pt_lon: -90.002708,
    shape_pt_sequence: 291,
    shape_dist_traveled: 14.4484,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071431,
    shape_pt_lon: -90.002417,
    shape_pt_sequence: 292,
    shape_dist_traveled: 14.4755,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071431,
    shape_pt_lon: -90.002111,
    shape_pt_sequence: 293,
    shape_dist_traveled: 14.5025,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071457,
    shape_pt_lon: -90.001836,
    shape_pt_sequence: 294,
    shape_dist_traveled: 14.5277,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071517,
    shape_pt_lon: -90.00151,
    shape_pt_sequence: 295,
    shape_dist_traveled: 14.5585,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071849,
    shape_pt_lon: -90.000145,
    shape_pt_sequence: 296,
    shape_dist_traveled: 14.687,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071912,
    shape_pt_lon: -89.999851,
    shape_pt_sequence: 297,
    shape_dist_traveled: 14.7149,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071945,
    shape_pt_lon: -89.999602,
    shape_pt_sequence: 298,
    shape_dist_traveled: 14.7372,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071958,
    shape_pt_lon: -89.999321,
    shape_pt_sequence: 299,
    shape_dist_traveled: 14.7633,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071946,
    shape_pt_lon: -89.999044,
    shape_pt_sequence: 300,
    shape_dist_traveled: 14.7884,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0719,
    shape_pt_lon: -89.998732,
    shape_pt_sequence: 301,
    shape_dist_traveled: 14.8168,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071768,
    shape_pt_lon: -89.998108,
    shape_pt_sequence: 302,
    shape_dist_traveled: 14.8748,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071749,
    shape_pt_lon: -89.997962,
    shape_pt_sequence: 303,
    shape_dist_traveled: 14.8889,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07173,
    shape_pt_lon: -89.997682,
    shape_pt_sequence: 304,
    shape_dist_traveled: 14.914,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071739,
    shape_pt_lon: -89.997306,
    shape_pt_sequence: 305,
    shape_dist_traveled: 14.948,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071772,
    shape_pt_lon: -89.997027,
    shape_pt_sequence: 306,
    shape_dist_traveled: 14.9734,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071829,
    shape_pt_lon: -89.996758,
    shape_pt_sequence: 307,
    shape_dist_traveled: 14.9981,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07191,
    shape_pt_lon: -89.996491,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.0247,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072001,
    shape_pt_lon: -89.996268,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.0475,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072101,
    shape_pt_lon: -89.996065,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.0686,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0722,
    shape_pt_lon: -89.995901,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.0872,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072337,
    shape_pt_lon: -89.99571,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.1099,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072415,
    shape_pt_lon: -89.995611,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.1226,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072798,
    shape_pt_lon: -89.99521,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.1787,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073,
    shape_pt_lon: -89.994951,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.2112,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073139,
    shape_pt_lon: -89.994729,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.2368,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073255,
    shape_pt_lon: -89.994497,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.2615,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073354,
    shape_pt_lon: -89.994227,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.2879,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07342,
    shape_pt_lon: -89.99399,
    shape_pt_sequence: 319,
    shape_dist_traveled: 15.3113,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073468,
    shape_pt_lon: -89.993731,
    shape_pt_sequence: 320,
    shape_dist_traveled: 15.3349,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073493,
    shape_pt_lon: -89.993452,
    shape_pt_sequence: 321,
    shape_dist_traveled: 15.3601,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073499,
    shape_pt_lon: -89.993338,
    shape_pt_sequence: 322,
    shape_dist_traveled: 15.3711,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073489,
    shape_pt_lon: -89.993062,
    shape_pt_sequence: 323,
    shape_dist_traveled: 15.3961,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073462,
    shape_pt_lon: -89.992806,
    shape_pt_sequence: 324,
    shape_dist_traveled: 15.4193,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07341,
    shape_pt_lon: -89.99255,
    shape_pt_sequence: 325,
    shape_dist_traveled: 15.4428,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073332,
    shape_pt_lon: -89.992276,
    shape_pt_sequence: 326,
    shape_dist_traveled: 15.4694,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073242,
    shape_pt_lon: -89.992047,
    shape_pt_sequence: 327,
    shape_dist_traveled: 15.4917,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.073126,
    shape_pt_lon: -89.991811,
    shape_pt_sequence: 328,
    shape_dist_traveled: 15.5173,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072979,
    shape_pt_lon: -89.991573,
    shape_pt_sequence: 329,
    shape_dist_traveled: 15.5443,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072777,
    shape_pt_lon: -89.991313,
    shape_pt_sequence: 330,
    shape_dist_traveled: 15.5769,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072611,
    shape_pt_lon: -89.991151,
    shape_pt_sequence: 331,
    shape_dist_traveled: 15.6005,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072431,
    shape_pt_lon: -89.99101,
    shape_pt_sequence: 332,
    shape_dist_traveled: 15.6243,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.07226,
    shape_pt_lon: -89.990899,
    shape_pt_sequence: 333,
    shape_dist_traveled: 15.6458,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.072211,
    shape_pt_lon: -89.990871,
    shape_pt_sequence: 334,
    shape_dist_traveled: 15.6525,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.071936,
    shape_pt_lon: -89.990756,
    shape_pt_sequence: 335,
    shape_dist_traveled: 15.6841,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070719,
    shape_pt_lon: -89.990358,
    shape_pt_sequence: 336,
    shape_dist_traveled: 15.8248,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.070185,
    shape_pt_lon: -89.990188,
    shape_pt_sequence: 337,
    shape_dist_traveled: 15.8869,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.069859,
    shape_pt_lon: -89.990118,
    shape_pt_sequence: 338,
    shape_dist_traveled: 15.9244,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.069619,
    shape_pt_lon: -89.990092,
    shape_pt_sequence: 339,
    shape_dist_traveled: 15.9506,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.069031,
    shape_pt_lon: -89.99003,
    shape_pt_sequence: 340,
    shape_dist_traveled: 16.0168,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.99002,
    shape_pt_sequence: 341,
    shape_dist_traveled: 16.0388,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068745,
    shape_pt_lon: -89.99002,
    shape_pt_sequence: 342,
    shape_dist_traveled: 16.0488,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068743,
    shape_pt_lon: -89.989959,
    shape_pt_sequence: 343,
    shape_dist_traveled: 16.0548,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068742,
    shape_pt_lon: -89.989903,
    shape_pt_sequence: 344,
    shape_dist_traveled: 16.0599,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068721,
    shape_pt_lon: -89.988469,
    shape_pt_sequence: 345,
    shape_dist_traveled: 16.1889,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06867,
    shape_pt_lon: -89.98838,
    shape_pt_sequence: 346,
    shape_dist_traveled: 16.1989,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068668,
    shape_pt_lon: -89.988261,
    shape_pt_sequence: 347,
    shape_dist_traveled: 16.2099,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068661,
    shape_pt_lon: -89.988133,
    shape_pt_sequence: 348,
    shape_dist_traveled: 16.2219,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068586,
    shape_pt_lon: -89.987919,
    shape_pt_sequence: 349,
    shape_dist_traveled: 16.2425,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987863,
    shape_pt_sequence: 350,
    shape_dist_traveled: 16.249,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068491,
    shape_pt_lon: -89.987824,
    shape_pt_sequence: 351,
    shape_dist_traveled: 16.257,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 352,
    shape_dist_traveled: 16.2733,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 353,
    shape_dist_traveled: 16.3727,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 354,
    shape_dist_traveled: 16.6967,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 355,
    shape_dist_traveled: 16.7813,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 356,
    shape_dist_traveled: 16.8332,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 357,
    shape_dist_traveled: 16.8828,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 358,
    shape_dist_traveled: 16.8848,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 359,
    shape_dist_traveled: 16.9131,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 360,
    shape_dist_traveled: 16.9605,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 361,
    shape_dist_traveled: 17.0139,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 362,
    shape_dist_traveled: 17.0476,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 363,
    shape_dist_traveled: 17.091,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 364,
    shape_dist_traveled: 17.113,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 365,
    shape_dist_traveled: 17.1641,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 366,
    shape_dist_traveled: 17.1858,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 367,
    shape_dist_traveled: 17.2042,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 368,
    shape_dist_traveled: 17.2178,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 369,
    shape_dist_traveled: 17.2362,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 370,
    shape_dist_traveled: 17.2558,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 371,
    shape_dist_traveled: 17.2723,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 372,
    shape_dist_traveled: 17.2953,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 373,
    shape_dist_traveled: 17.3193,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 374,
    shape_dist_traveled: 17.454,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 375,
    shape_dist_traveled: 17.5711,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 376,
    shape_dist_traveled: 17.5931,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 377,
    shape_dist_traveled: 17.6442,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060602,
    shape_pt_lon: -89.991619,
    shape_pt_sequence: 378,
    shape_dist_traveled: 17.7002,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 379,
    shape_dist_traveled: 17.7032,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.060524,
    shape_pt_lon: -89.991622,
    shape_pt_sequence: 380,
    shape_dist_traveled: 17.7092,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.059692,
    shape_pt_lon: -89.991643,
    shape_pt_sequence: 381,
    shape_dist_traveled: 17.8022,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.058269,
    shape_pt_lon: -89.991672,
    shape_pt_sequence: 382,
    shape_dist_traveled: 17.9622,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.057629,
    shape_pt_lon: -89.99164,
    shape_pt_sequence: 383,
    shape_dist_traveled: 18.0333,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.056766,
    shape_pt_lon: -89.991596,
    shape_pt_sequence: 384,
    shape_dist_traveled: 18.1304,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.055766,
    shape_pt_lon: -89.991538,
    shape_pt_sequence: 385,
    shape_dist_traveled: 18.2425,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.054833,
    shape_pt_lon: -89.991486,
    shape_pt_sequence: 386,
    shape_dist_traveled: 18.3466,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053527,
    shape_pt_lon: -89.991368,
    shape_pt_sequence: 387,
    shape_dist_traveled: 18.493,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 388,
    shape_dist_traveled: 18.5042,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 389,
    shape_dist_traveled: 18.5192,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 390,
    shape_dist_traveled: 18.5363,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 391,
    shape_dist_traveled: 18.5763,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 392,
    shape_dist_traveled: 18.6383,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 393,
    shape_dist_traveled: 18.6453,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 394,
    shape_dist_traveled: 18.6493,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 395,
    shape_dist_traveled: 18.6593,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 396,
    shape_dist_traveled: 18.6634,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053219,
    shape_pt_lon: -89.989648,
    shape_pt_sequence: 397,
    shape_dist_traveled: 18.6698,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053181,
    shape_pt_lon: -89.989544,
    shape_pt_sequence: 398,
    shape_dist_traveled: 18.681,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053183,
    shape_pt_lon: -89.989342,
    shape_pt_sequence: 399,
    shape_dist_traveled: 18.6991,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.05318,
    shape_pt_lon: -89.989049,
    shape_pt_sequence: 400,
    shape_dist_traveled: 18.7251,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053148,
    shape_pt_lon: -89.988771,
    shape_pt_sequence: 401,
    shape_dist_traveled: 18.7512,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053141,
    shape_pt_lon: -89.988321,
    shape_pt_sequence: 402,
    shape_dist_traveled: 18.7913,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053123,
    shape_pt_lon: -89.987852,
    shape_pt_sequence: 403,
    shape_dist_traveled: 18.8343,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053078,
    shape_pt_lon: -89.987433,
    shape_pt_sequence: 404,
    shape_dist_traveled: 18.8726,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.053038,
    shape_pt_lon: -89.987203,
    shape_pt_sequence: 405,
    shape_dist_traveled: 18.893,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.052961,
    shape_pt_lon: -89.986893,
    shape_pt_sequence: 406,
    shape_dist_traveled: 18.9224,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.052779,
    shape_pt_lon: -89.986228,
    shape_pt_sequence: 407,
    shape_dist_traveled: 18.9866,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.052499,
    shape_pt_lon: -89.985401,
    shape_pt_sequence: 408,
    shape_dist_traveled: 19.0673,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.052328,
    shape_pt_lon: -89.984898,
    shape_pt_sequence: 409,
    shape_dist_traveled: 19.117,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0519,
    shape_pt_lon: -89.983553,
    shape_pt_sequence: 410,
    shape_dist_traveled: 19.2481,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.0518,
    shape_pt_lon: -89.983311,
    shape_pt_sequence: 411,
    shape_dist_traveled: 19.2727,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.05165,
    shape_pt_lon: -89.983016,
    shape_pt_sequence: 412,
    shape_dist_traveled: 19.3033,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.051509,
    shape_pt_lon: -89.982791,
    shape_pt_sequence: 413,
    shape_dist_traveled: 19.3297,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.051356,
    shape_pt_lon: -89.982592,
    shape_pt_sequence: 414,
    shape_dist_traveled: 19.3544,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.051087,
    shape_pt_lon: -89.982307,
    shape_pt_sequence: 415,
    shape_dist_traveled: 19.3941,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.050927,
    shape_pt_lon: -89.982178,
    shape_pt_sequence: 416,
    shape_dist_traveled: 19.4152,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.050713,
    shape_pt_lon: -89.982048,
    shape_pt_sequence: 417,
    shape_dist_traveled: 19.442,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.050537,
    shape_pt_lon: -89.981973,
    shape_pt_sequence: 418,
    shape_dist_traveled: 19.4632,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.05041,
    shape_pt_lon: -89.981952,
    shape_pt_sequence: 419,
    shape_dist_traveled: 19.4774,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.050193,
    shape_pt_lon: -89.981913,
    shape_pt_sequence: 420,
    shape_dist_traveled: 19.5016,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.050001,
    shape_pt_lon: -89.981918,
    shape_pt_sequence: 421,
    shape_dist_traveled: 19.5236,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.049893,
    shape_pt_lon: -89.98192,
    shape_pt_sequence: 422,
    shape_dist_traveled: 19.5356,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.049563,
    shape_pt_lon: -89.981939,
    shape_pt_sequence: 423,
    shape_dist_traveled: 19.5726,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.049538,
    shape_pt_lon: -89.981938,
    shape_pt_sequence: 424,
    shape_dist_traveled: 19.5756,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.048854,
    shape_pt_lon: -89.981921,
    shape_pt_sequence: 425,
    shape_dist_traveled: 19.6516,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.048593,
    shape_pt_lon: -89.981916,
    shape_pt_sequence: 426,
    shape_dist_traveled: 19.6806,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047912,
    shape_pt_lon: -89.981902,
    shape_pt_sequence: 427,
    shape_dist_traveled: 19.7577,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047666,
    shape_pt_lon: -89.9819,
    shape_pt_sequence: 428,
    shape_dist_traveled: 19.7847,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047419,
    shape_pt_lon: -89.981914,
    shape_pt_sequence: 429,
    shape_dist_traveled: 19.8127,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047319,
    shape_pt_lon: -89.98193,
    shape_pt_sequence: 430,
    shape_dist_traveled: 19.8238,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047213,
    shape_pt_lon: -89.98198,
    shape_pt_sequence: 431,
    shape_dist_traveled: 19.8368,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.04717,
    shape_pt_lon: -89.982018,
    shape_pt_sequence: 432,
    shape_dist_traveled: 19.8426,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047109,
    shape_pt_lon: -89.982088,
    shape_pt_sequence: 433,
    shape_dist_traveled: 19.8511,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047091,
    shape_pt_lon: -89.982116,
    shape_pt_sequence: 434,
    shape_dist_traveled: 19.8547,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047069,
    shape_pt_lon: -89.982155,
    shape_pt_sequence: 435,
    shape_dist_traveled: 19.8589,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047016,
    shape_pt_lon: -89.982309,
    shape_pt_sequence: 436,
    shape_dist_traveled: 19.8742,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.047006,
    shape_pt_lon: -89.982522,
    shape_pt_sequence: 437,
    shape_dist_traveled: 19.8942,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046995,
    shape_pt_lon: -89.982811,
    shape_pt_sequence: 438,
    shape_dist_traveled: 19.9202,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046959,
    shape_pt_lon: -89.982935,
    shape_pt_sequence: 439,
    shape_dist_traveled: 19.9319,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046903,
    shape_pt_lon: -89.983046,
    shape_pt_sequence: 440,
    shape_dist_traveled: 19.9441,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046859,
    shape_pt_lon: -89.9831,
    shape_pt_sequence: 441,
    shape_dist_traveled: 19.9512,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046818,
    shape_pt_lon: -89.983141,
    shape_pt_sequence: 442,
    shape_dist_traveled: 19.9569,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046778,
    shape_pt_lon: -89.983172,
    shape_pt_sequence: 443,
    shape_dist_traveled: 19.9622,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046726,
    shape_pt_lon: -89.983222,
    shape_pt_sequence: 444,
    shape_dist_traveled: 19.9693,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046579,
    shape_pt_lon: -89.983278,
    shape_pt_sequence: 445,
    shape_dist_traveled: 19.987,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046389,
    shape_pt_lon: -89.98333,
    shape_pt_sequence: 446,
    shape_dist_traveled: 20.0086,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.046035,
    shape_pt_lon: -89.983397,
    shape_pt_sequence: 447,
    shape_dist_traveled: 20.0491,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.045709,
    shape_pt_lon: -89.983405,
    shape_pt_sequence: 448,
    shape_dist_traveled: 20.0851,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.045189,
    shape_pt_lon: -89.983386,
    shape_pt_sequence: 449,
    shape_dist_traveled: 20.1431,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044967,
    shape_pt_lon: -89.983381,
    shape_pt_sequence: 450,
    shape_dist_traveled: 20.1681,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044853,
    shape_pt_lon: -89.983298,
    shape_pt_sequence: 451,
    shape_dist_traveled: 20.1829,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044822,
    shape_pt_lon: -89.983245,
    shape_pt_sequence: 452,
    shape_dist_traveled: 20.1887,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044789,
    shape_pt_lon: -89.983143,
    shape_pt_sequence: 453,
    shape_dist_traveled: 20.1995,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044793,
    shape_pt_lon: -89.982721,
    shape_pt_sequence: 454,
    shape_dist_traveled: 20.2375,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044797,
    shape_pt_lon: -89.98247,
    shape_pt_sequence: 455,
    shape_dist_traveled: 20.2595,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.044813,
    shape_pt_lon: -89.981654,
    shape_pt_sequence: 456,
    shape_dist_traveled: 20.3335,
  },
  {
    shape_id: 38206,
    shape_pt_lat: 35.04482,
    shape_pt_lon: -89.981018,
    shape_pt_sequence: 457,
    shape_dist_traveled: 20.3915,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.035,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.095,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.155,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.19,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.291,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.37,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.383,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4251,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6481,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.00604,
    shape_pt_lon: -89.937121,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6761,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006062,
    shape_pt_lon: -89.938412,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7931,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006052,
    shape_pt_lon: -89.939112,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8561,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -89.940202,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9552,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006069,
    shape_pt_lon: -89.941305,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0552,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.00607,
    shape_pt_lon: -89.941451,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0682,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006077,
    shape_pt_lon: -89.942096,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.1262,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006095,
    shape_pt_lon: -89.943923,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2922,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006117,
    shape_pt_lon: -89.944809,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.3722,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006144,
    shape_pt_lon: -89.948532,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.7092,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006146,
    shape_pt_lon: -89.94867,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.7213,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006155,
    shape_pt_lon: -89.949916,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.8343,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006159,
    shape_pt_lon: -89.951597,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.9863,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006188,
    shape_pt_lon: -89.954324,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.2333,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.006199,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.2543,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.009323,
    shape_pt_lon: -89.954539,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.6033,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.01126,
    shape_pt_lon: -89.954553,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.8203,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.013438,
    shape_pt_lon: -89.954563,
    shape_pt_sequence: 28,
    shape_dist_traveled: 3.0643,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.01411,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 29,
    shape_dist_traveled: 3.1393,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.014964,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 30,
    shape_dist_traveled: 3.2353,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.015599,
    shape_pt_lon: -89.954564,
    shape_pt_sequence: 31,
    shape_dist_traveled: 3.3063,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.016532,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.4103,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.017091,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.4733,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.020113,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.8113,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.954572,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.8733,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.8834,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.020812,
    shape_pt_lon: -89.954586,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.8895,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.022747,
    shape_pt_lon: -89.954625,
    shape_pt_sequence: 38,
    shape_dist_traveled: 4.1055,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.024258,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 39,
    shape_dist_traveled: 4.2745,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.024822,
    shape_pt_lon: -89.954638,
    shape_pt_sequence: 40,
    shape_dist_traveled: 4.3385,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.025685,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 41,
    shape_dist_traveled: 4.4345,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.02614,
    shape_pt_lon: -89.954652,
    shape_pt_sequence: 42,
    shape_dist_traveled: 4.4855,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.02707,
    shape_pt_lon: -89.954757,
    shape_pt_sequence: 43,
    shape_dist_traveled: 4.59,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027319,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 44,
    shape_dist_traveled: 4.618,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027836,
    shape_pt_lon: -89.954737,
    shape_pt_sequence: 45,
    shape_dist_traveled: 4.6761,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.954728,
    shape_pt_sequence: 46,
    shape_dist_traveled: 4.6911,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.953473,
    shape_pt_sequence: 47,
    shape_dist_traveled: 4.8051,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.950879,
    shape_pt_sequence: 48,
    shape_dist_traveled: 5.0401,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94997,
    shape_pt_sequence: 49,
    shape_dist_traveled: 5.1221,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027967,
    shape_pt_lon: -89.949072,
    shape_pt_sequence: 50,
    shape_dist_traveled: 5.2031,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027966,
    shape_pt_lon: -89.948133,
    shape_pt_sequence: 51,
    shape_dist_traveled: 5.2881,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027963,
    shape_pt_lon: -89.947081,
    shape_pt_sequence: 52,
    shape_dist_traveled: 5.3831,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.946853,
    shape_pt_sequence: 53,
    shape_dist_traveled: 5.4041,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.94633,
    shape_pt_sequence: 54,
    shape_dist_traveled: 5.4511,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027993,
    shape_pt_lon: -89.945854,
    shape_pt_sequence: 55,
    shape_dist_traveled: 5.4942,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.027987,
    shape_pt_lon: -89.945346,
    shape_pt_sequence: 56,
    shape_dist_traveled: 5.5402,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.028811,
    shape_pt_lon: -89.94534,
    shape_pt_sequence: 57,
    shape_dist_traveled: 5.6322,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.029614,
    shape_pt_lon: -89.94533,
    shape_pt_sequence: 58,
    shape_dist_traveled: 5.7222,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.030417,
    shape_pt_lon: -89.945314,
    shape_pt_sequence: 59,
    shape_dist_traveled: 5.8122,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.031258,
    shape_pt_lon: -89.9453,
    shape_pt_sequence: 60,
    shape_dist_traveled: 5.9062,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.032074,
    shape_pt_lon: -89.945287,
    shape_pt_sequence: 61,
    shape_dist_traveled: 5.9972,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.032079,
    shape_pt_lon: -89.945016,
    shape_pt_sequence: 62,
    shape_dist_traveled: 6.0223,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.032069,
    shape_pt_lon: -89.944691,
    shape_pt_sequence: 63,
    shape_dist_traveled: 6.0513,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.032065,
    shape_pt_lon: -89.943648,
    shape_pt_sequence: 64,
    shape_dist_traveled: 6.1453,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.033184,
    shape_pt_lon: -89.943628,
    shape_pt_sequence: 65,
    shape_dist_traveled: 6.2703,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.036286,
    shape_pt_lon: -89.943594,
    shape_pt_sequence: 66,
    shape_dist_traveled: 6.6183,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.036793,
    shape_pt_lon: -89.943572,
    shape_pt_sequence: 67,
    shape_dist_traveled: 6.6743,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.03688,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 68,
    shape_dist_traveled: 6.6843,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.036928,
    shape_pt_lon: -89.943571,
    shape_pt_sequence: 69,
    shape_dist_traveled: 6.6893,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.037132,
    shape_pt_lon: -89.943576,
    shape_pt_sequence: 70,
    shape_dist_traveled: 6.7124,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.03833,
    shape_pt_lon: -89.943557,
    shape_pt_sequence: 71,
    shape_dist_traveled: 6.8464,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.040042,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 72,
    shape_dist_traveled: 7.0384,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04074,
    shape_pt_lon: -89.943536,
    shape_pt_sequence: 73,
    shape_dist_traveled: 7.1164,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.041359,
    shape_pt_lon: -89.943552,
    shape_pt_sequence: 74,
    shape_dist_traveled: 7.1854,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.041907,
    shape_pt_lon: -89.94354,
    shape_pt_sequence: 75,
    shape_dist_traveled: 7.2464,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.042767,
    shape_pt_lon: -89.943528,
    shape_pt_sequence: 76,
    shape_dist_traveled: 7.3434,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043047,
    shape_pt_lon: -89.943522,
    shape_pt_sequence: 77,
    shape_dist_traveled: 7.3744,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043193,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 78,
    shape_dist_traveled: 7.3904,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043243,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 79,
    shape_dist_traveled: 7.3964,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043258,
    shape_pt_lon: -89.945294,
    shape_pt_sequence: 80,
    shape_dist_traveled: 7.5564,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043271,
    shape_pt_lon: -89.946473,
    shape_pt_sequence: 81,
    shape_dist_traveled: 7.6634,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043267,
    shape_pt_lon: -89.946868,
    shape_pt_sequence: 82,
    shape_dist_traveled: 7.6994,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043254,
    shape_pt_lon: -89.947092,
    shape_pt_sequence: 83,
    shape_dist_traveled: 7.7195,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043209,
    shape_pt_lon: -89.947391,
    shape_pt_sequence: 84,
    shape_dist_traveled: 7.7469,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04311,
    shape_pt_lon: -89.947878,
    shape_pt_sequence: 85,
    shape_dist_traveled: 7.7923,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043101,
    shape_pt_lon: -89.947932,
    shape_pt_sequence: 86,
    shape_dist_traveled: 7.7974,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043071,
    shape_pt_lon: -89.948242,
    shape_pt_sequence: 87,
    shape_dist_traveled: 7.8257,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04309,
    shape_pt_lon: -89.951115,
    shape_pt_sequence: 88,
    shape_dist_traveled: 8.0857,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043097,
    shape_pt_lon: -89.952144,
    shape_pt_sequence: 89,
    shape_dist_traveled: 8.1787,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043107,
    shape_pt_lon: -89.953143,
    shape_pt_sequence: 90,
    shape_dist_traveled: 8.2687,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.043122,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 91,
    shape_dist_traveled: 8.4177,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04409,
    shape_pt_lon: -89.954799,
    shape_pt_sequence: 92,
    shape_dist_traveled: 8.5257,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045832,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 93,
    shape_dist_traveled: 8.7207,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.0468,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 94,
    shape_dist_traveled: 8.8297,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046935,
    shape_pt_lon: -89.954781,
    shape_pt_sequence: 95,
    shape_dist_traveled: 8.8447,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047214,
    shape_pt_lon: -89.95474,
    shape_pt_sequence: 96,
    shape_dist_traveled: 8.876,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047463,
    shape_pt_lon: -89.954679,
    shape_pt_sequence: 97,
    shape_dist_traveled: 8.9044,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047992,
    shape_pt_lon: -89.954482,
    shape_pt_sequence: 98,
    shape_dist_traveled: 8.9661,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048362,
    shape_pt_lon: -89.954293,
    shape_pt_sequence: 99,
    shape_dist_traveled: 9.0105,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048717,
    shape_pt_lon: -89.954047,
    shape_pt_sequence: 100,
    shape_dist_traveled: 9.0561,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048848,
    shape_pt_lon: -89.953951,
    shape_pt_sequence: 101,
    shape_dist_traveled: 9.0736,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049027,
    shape_pt_lon: -89.953803,
    shape_pt_sequence: 102,
    shape_dist_traveled: 9.0975,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04983,
    shape_pt_lon: -89.953094,
    shape_pt_sequence: 103,
    shape_dist_traveled: 9.2079,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050488,
    shape_pt_lon: -89.9525,
    shape_pt_sequence: 104,
    shape_dist_traveled: 9.2987,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050726,
    shape_pt_lon: -89.952308,
    shape_pt_sequence: 105,
    shape_dist_traveled: 9.3306,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05076,
    shape_pt_lon: -89.952282,
    shape_pt_sequence: 106,
    shape_dist_traveled: 9.3356,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051054,
    shape_pt_lon: -89.952096,
    shape_pt_sequence: 107,
    shape_dist_traveled: 9.3723,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051436,
    shape_pt_lon: -89.9519,
    shape_pt_sequence: 108,
    shape_dist_traveled: 9.4189,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051544,
    shape_pt_lon: -89.951852,
    shape_pt_sequence: 109,
    shape_dist_traveled: 9.4316,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051896,
    shape_pt_lon: -89.951734,
    shape_pt_sequence: 110,
    shape_dist_traveled: 9.4721,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.95168,
    shape_pt_sequence: 111,
    shape_dist_traveled: 9.5055,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 112,
    shape_dist_traveled: 9.5116,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 113,
    shape_dist_traveled: 9.7096,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 114,
    shape_dist_traveled: 9.7957,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 115,
    shape_dist_traveled: 9.8527,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 116,
    shape_dist_traveled: 10.0638,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 117,
    shape_dist_traveled: 10.2209,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 118,
    shape_dist_traveled: 10.2289,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052518,
    shape_pt_lon: -89.959711,
    shape_pt_sequence: 119,
    shape_dist_traveled: 10.242,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052621,
    shape_pt_lon: -89.962222,
    shape_pt_sequence: 120,
    shape_dist_traveled: 10.4692,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05264,
    shape_pt_lon: -89.963049,
    shape_pt_sequence: 121,
    shape_dist_traveled: 10.5443,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052662,
    shape_pt_lon: -89.963789,
    shape_pt_sequence: 122,
    shape_dist_traveled: 10.6113,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052668,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 123,
    shape_dist_traveled: 10.6283,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05268,
    shape_pt_lon: -89.964479,
    shape_pt_sequence: 124,
    shape_dist_traveled: 10.6733,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052708,
    shape_pt_lon: -89.965441,
    shape_pt_sequence: 125,
    shape_dist_traveled: 10.7604,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052739,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 126,
    shape_dist_traveled: 10.8705,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052794,
    shape_pt_lon: -89.968479,
    shape_pt_sequence: 127,
    shape_dist_traveled: 11.0356,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052857,
    shape_pt_lon: -89.970677,
    shape_pt_sequence: 128,
    shape_dist_traveled: 11.2347,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052892,
    shape_pt_lon: -89.972186,
    shape_pt_sequence: 129,
    shape_dist_traveled: 11.3707,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052877,
    shape_pt_lon: -89.972562,
    shape_pt_sequence: 130,
    shape_dist_traveled: 11.4048,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052859,
    shape_pt_lon: -89.9729,
    shape_pt_sequence: 131,
    shape_dist_traveled: 11.4358,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052723,
    shape_pt_lon: -89.974436,
    shape_pt_sequence: 132,
    shape_dist_traveled: 11.5757,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052707,
    shape_pt_lon: -89.97468,
    shape_pt_sequence: 133,
    shape_dist_traveled: 11.5978,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052683,
    shape_pt_lon: -89.975008,
    shape_pt_sequence: 134,
    shape_dist_traveled: 11.6269,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052665,
    shape_pt_lon: -89.975273,
    shape_pt_sequence: 135,
    shape_dist_traveled: 11.651,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052606,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 136,
    shape_dist_traveled: 11.7064,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052601,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 137,
    shape_dist_traveled: 11.7064,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052553,
    shape_pt_lon: -89.976424,
    shape_pt_sequence: 138,
    shape_dist_traveled: 11.7558,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 139,
    shape_dist_traveled: 11.7558,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052521,
    shape_pt_lon: -89.976757,
    shape_pt_sequence: 140,
    shape_dist_traveled: 11.787,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052478,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 141,
    shape_dist_traveled: 11.8452,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052439,
    shape_pt_lon: -89.977803,
    shape_pt_sequence: 142,
    shape_dist_traveled: 11.8815,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05242,
    shape_pt_lon: -89.978039,
    shape_pt_sequence: 143,
    shape_dist_traveled: 11.9036,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052352,
    shape_pt_lon: -89.978785,
    shape_pt_sequence: 144,
    shape_dist_traveled: 11.9711,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052346,
    shape_pt_lon: -89.978868,
    shape_pt_sequence: 145,
    shape_dist_traveled: 11.9791,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052296,
    shape_pt_lon: -89.979454,
    shape_pt_sequence: 146,
    shape_dist_traveled: 12.0324,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052188,
    shape_pt_lon: -89.980672,
    shape_pt_sequence: 147,
    shape_dist_traveled: 12.1431,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05218,
    shape_pt_lon: -89.98076,
    shape_pt_sequence: 148,
    shape_dist_traveled: 12.1511,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052121,
    shape_pt_lon: -89.981575,
    shape_pt_sequence: 149,
    shape_dist_traveled: 12.2245,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 150,
    shape_dist_traveled: 12.2575,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052093,
    shape_pt_lon: -89.981988,
    shape_pt_sequence: 151,
    shape_dist_traveled: 12.2626,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 152,
    shape_dist_traveled: 12.2706,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.982481,
    shape_pt_sequence: 153,
    shape_dist_traveled: 12.3066,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052158,
    shape_pt_lon: -89.982821,
    shape_pt_sequence: 154,
    shape_dist_traveled: 12.3387,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052209,
    shape_pt_lon: -89.982964,
    shape_pt_sequence: 155,
    shape_dist_traveled: 12.353,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05225,
    shape_pt_lon: -89.983027,
    shape_pt_sequence: 156,
    shape_dist_traveled: 12.3608,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052315,
    shape_pt_lon: -89.983091,
    shape_pt_sequence: 157,
    shape_dist_traveled: 12.37,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052416,
    shape_pt_lon: -89.983147,
    shape_pt_sequence: 158,
    shape_dist_traveled: 12.3821,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052504,
    shape_pt_lon: -89.983166,
    shape_pt_sequence: 159,
    shape_dist_traveled: 12.3921,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052599,
    shape_pt_lon: -89.983159,
    shape_pt_sequence: 160,
    shape_dist_traveled: 12.4031,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052659,
    shape_pt_lon: -89.983133,
    shape_pt_sequence: 161,
    shape_dist_traveled: 12.4098,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052777,
    shape_pt_lon: -89.983046,
    shape_pt_sequence: 162,
    shape_dist_traveled: 12.4255,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052829,
    shape_pt_lon: -89.982981,
    shape_pt_sequence: 163,
    shape_dist_traveled: 12.4333,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052876,
    shape_pt_lon: -89.982886,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.4441,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052899,
    shape_pt_lon: -89.982789,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.4533,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.0529,
    shape_pt_lon: -89.982637,
    shape_pt_sequence: 166,
    shape_dist_traveled: 12.4663,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052874,
    shape_pt_lon: -89.982528,
    shape_pt_sequence: 167,
    shape_dist_traveled: 12.4765,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052829,
    shape_pt_lon: -89.982429,
    shape_pt_sequence: 168,
    shape_dist_traveled: 12.4868,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052769,
    shape_pt_lon: -89.982357,
    shape_pt_sequence: 169,
    shape_dist_traveled: 12.4967,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05263,
    shape_pt_lon: -89.982264,
    shape_pt_sequence: 170,
    shape_dist_traveled: 12.5146,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052322,
    shape_pt_lon: -89.98215,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.5504,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.577,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051956,
    shape_pt_lon: -89.982043,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.5923,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051752,
    shape_pt_lon: -89.981993,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.6159,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051246,
    shape_pt_lon: -89.981917,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.6733,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05052,
    shape_pt_lon: -89.981896,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.7543,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050285,
    shape_pt_lon: -89.981914,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.7804,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050193,
    shape_pt_lon: -89.981913,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.7904,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050001,
    shape_pt_lon: -89.981918,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.8124,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049893,
    shape_pt_lon: -89.98192,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.8244,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049563,
    shape_pt_lon: -89.981939,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.8615,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049538,
    shape_pt_lon: -89.981938,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.8645,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048854,
    shape_pt_lon: -89.981921,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.9405,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048593,
    shape_pt_lon: -89.981916,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.9695,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047912,
    shape_pt_lon: -89.981902,
    shape_pt_sequence: 185,
    shape_dist_traveled: 13.0465,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047666,
    shape_pt_lon: -89.9819,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.0735,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047419,
    shape_pt_lon: -89.981914,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.1016,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047319,
    shape_pt_lon: -89.98193,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.1126,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047213,
    shape_pt_lon: -89.98198,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.1256,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04717,
    shape_pt_lon: -89.982018,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.1315,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047109,
    shape_pt_lon: -89.982088,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.1399,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047091,
    shape_pt_lon: -89.982116,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.1436,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047069,
    shape_pt_lon: -89.982155,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.1478,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047016,
    shape_pt_lon: -89.982309,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.163,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047006,
    shape_pt_lon: -89.982522,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.1831,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046995,
    shape_pt_lon: -89.982811,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.2091,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046959,
    shape_pt_lon: -89.982935,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.2208,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046903,
    shape_pt_lon: -89.983046,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.233,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046859,
    shape_pt_lon: -89.9831,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.2401,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046818,
    shape_pt_lon: -89.983141,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.2457,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046778,
    shape_pt_lon: -89.983172,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.2511,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046726,
    shape_pt_lon: -89.983222,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.2582,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046579,
    shape_pt_lon: -89.983278,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.2759,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046389,
    shape_pt_lon: -89.98333,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.2975,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046035,
    shape_pt_lon: -89.983397,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.3379,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045709,
    shape_pt_lon: -89.983405,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.3739,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045189,
    shape_pt_lon: -89.983386,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.4319,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044967,
    shape_pt_lon: -89.983381,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.457,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044853,
    shape_pt_lon: -89.983298,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.4717,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044822,
    shape_pt_lon: -89.983245,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.4775,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044789,
    shape_pt_lon: -89.983143,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.4883,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044793,
    shape_pt_lon: -89.982721,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.5263,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044797,
    shape_pt_lon: -89.98247,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.5483,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044813,
    shape_pt_lon: -89.981654,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.6224,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04482,
    shape_pt_lon: -89.981018,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.6804,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044823,
    shape_pt_lon: -89.980573,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.7204,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044829,
    shape_pt_lon: -89.980233,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.7514,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044833,
    shape_pt_lon: -89.980198,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.7544,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044858,
    shape_pt_lon: -89.980097,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.7639,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044876,
    shape_pt_lon: -89.980061,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.7675,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.044919,
    shape_pt_lon: -89.980001,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.7753,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045009,
    shape_pt_lon: -89.97994,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.7865,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045047,
    shape_pt_lon: -89.979937,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.7906,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04526,
    shape_pt_lon: -89.979941,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.8146,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.045898,
    shape_pt_lon: -89.979953,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.8856,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046273,
    shape_pt_lon: -89.980003,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.9279,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04642,
    shape_pt_lon: -89.980047,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.9454,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.046514,
    shape_pt_lon: -89.98008,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.9558,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04701,
    shape_pt_lon: -89.980179,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.0125,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047052,
    shape_pt_lon: -89.980188,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.0165,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047134,
    shape_pt_lon: -89.980201,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.0258,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04739,
    shape_pt_lon: -89.980248,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.055,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.047637,
    shape_pt_lon: -89.980254,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.083,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04779,
    shape_pt_lon: -89.980259,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.1001,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048188,
    shape_pt_lon: -89.980269,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.1441,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.04854,
    shape_pt_lon: -89.980281,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.1841,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048696,
    shape_pt_lon: -89.980286,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.2011,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048733,
    shape_pt_lon: -89.980287,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.2052,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.048841,
    shape_pt_lon: -89.980293,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.2172,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049109,
    shape_pt_lon: -89.980302,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.2472,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049536,
    shape_pt_lon: -89.980311,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.2952,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.049986,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 242,
    shape_dist_traveled: 14.3463,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050207,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 243,
    shape_dist_traveled: 14.3703,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05033,
    shape_pt_lon: -89.980337,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.3843,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050434,
    shape_pt_lon: -89.980363,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.3967,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.980413,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.4084,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050711,
    shape_pt_lon: -89.980539,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.4317,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050799,
    shape_pt_lon: -89.980647,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.4451,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050864,
    shape_pt_lon: -89.98075,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.4573,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05092,
    shape_pt_lon: -89.980845,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.4673,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.050977,
    shape_pt_lon: -89.980961,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.4799,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051107,
    shape_pt_lon: -89.981309,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.5143,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051212,
    shape_pt_lon: -89.981513,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.5368,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05137,
    shape_pt_lon: -89.981681,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.5595,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051597,
    shape_pt_lon: -89.981834,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.589,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051848,
    shape_pt_lon: -89.981897,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.6174,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.051966,
    shape_pt_lon: -89.981917,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.6306,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.6457,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052342,
    shape_pt_lon: -89.98197,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.6729,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052543,
    shape_pt_lon: -89.981989,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.696,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052669,
    shape_pt_lon: -89.981979,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.71,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052772,
    shape_pt_lon: -89.98193,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.7217,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052877,
    shape_pt_lon: -89.981834,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.7367,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052929,
    shape_pt_lon: -89.981748,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.7467,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052962,
    shape_pt_lon: -89.981669,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.7543,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052987,
    shape_pt_lon: -89.981547,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.7657,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052989,
    shape_pt_lon: -89.981449,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.7747,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052967,
    shape_pt_lon: -89.981307,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.7879,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052907,
    shape_pt_lon: -89.98118,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.8009,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052823,
    shape_pt_lon: -89.981077,
    shape_pt_sequence: 270,
    shape_dist_traveled: 14.8144,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052769,
    shape_pt_lon: -89.98103,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.8216,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052643,
    shape_pt_lon: -89.980974,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.8365,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052548,
    shape_pt_lon: -89.980962,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.8475,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052438,
    shape_pt_lon: -89.980988,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.8597,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052322,
    shape_pt_lon: -89.981058,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.8744,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052257,
    shape_pt_lon: -89.981136,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.8843,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.0522,
    shape_pt_lon: -89.981242,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.8957,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052159,
    shape_pt_lon: -89.981396,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.9103,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052121,
    shape_pt_lon: -89.981575,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.9271,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.9601,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052093,
    shape_pt_lon: -89.981988,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.9652,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.9732,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.982481,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.0092,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052098,
    shape_pt_lon: -89.982754,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.0343,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052151,
    shape_pt_lon: -89.98318,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.0738,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052229,
    shape_pt_lon: -89.983566,
    shape_pt_sequence: 286,
    shape_dist_traveled: 15.1097,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052309,
    shape_pt_lon: -89.983848,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.1362,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05263,
    shape_pt_lon: -89.9848,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.2295,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052666,
    shape_pt_lon: -89.984901,
    shape_pt_sequence: 289,
    shape_dist_traveled: 15.2393,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.052852,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 290,
    shape_dist_traveled: 15.289,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053109,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.3476,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053158,
    shape_pt_lon: -89.986057,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.3579,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053313,
    shape_pt_lon: -89.98643,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.396,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053406,
    shape_pt_lon: -89.986702,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.4224,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053468,
    shape_pt_lon: -89.986996,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.4502,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05349,
    shape_pt_lon: -89.987205,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.4694,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053503,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.4895,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053497,
    shape_pt_lon: -89.98765,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.5095,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05345,
    shape_pt_lon: -89.987999,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.5419,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053319,
    shape_pt_lon: -89.988731,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.6095,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.989051,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.6387,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053299,
    shape_pt_lon: -89.989227,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.6547,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053303,
    shape_pt_lon: -89.989473,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.6767,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053284,
    shape_pt_lon: -89.989631,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.6909,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.6985,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.7026,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.7126,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.7166,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.7236,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.7856,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.8257,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.8427,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.8577,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05355,
    shape_pt_lon: -89.99123,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.8716,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.054161,
    shape_pt_lon: -89.991249,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.9406,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.054831,
    shape_pt_lon: -89.991301,
    shape_pt_sequence: 316,
    shape_dist_traveled: 16.0157,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.055837,
    shape_pt_lon: -89.991361,
    shape_pt_sequence: 317,
    shape_dist_traveled: 16.1279,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.05677,
    shape_pt_lon: -89.991416,
    shape_pt_sequence: 318,
    shape_dist_traveled: 16.233,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.057634,
    shape_pt_lon: -89.991457,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.3291,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.058032,
    shape_pt_lon: -89.991479,
    shape_pt_sequence: 320,
    shape_dist_traveled: 16.3742,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.058719,
    shape_pt_lon: -89.991487,
    shape_pt_sequence: 321,
    shape_dist_traveled: 16.4512,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.059566,
    shape_pt_lon: -89.991467,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.5462,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060574,
    shape_pt_lon: -89.99144,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.6582,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060577,
    shape_pt_lon: -89.991531,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.6663,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.6743,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.7603,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.7833,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.8073,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.942,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 330,
    shape_dist_traveled: 17.0591,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 331,
    shape_dist_traveled: 17.0811,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 332,
    shape_dist_traveled: 17.1322,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 333,
    shape_dist_traveled: 17.1538,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 334,
    shape_dist_traveled: 17.1723,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 335,
    shape_dist_traveled: 17.1859,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.2043,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 337,
    shape_dist_traveled: 17.2239,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 338,
    shape_dist_traveled: 17.2404,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.2634,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.2874,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.3635,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.4205,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 343,
    shape_dist_traveled: 17.4586,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 344,
    shape_dist_traveled: 17.4646,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 345,
    shape_dist_traveled: 17.6166,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 346,
    shape_dist_traveled: 17.7856,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 347,
    shape_dist_traveled: 17.8957,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 348,
    shape_dist_traveled: 17.9159,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 349,
    shape_dist_traveled: 17.9362,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 350,
    shape_dist_traveled: 17.9514,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 351,
    shape_dist_traveled: 17.9678,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 352,
    shape_dist_traveled: 17.9884,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.0099,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.0289,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.047,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 356,
    shape_dist_traveled: 18.062,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 357,
    shape_dist_traveled: 18.1026,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 358,
    shape_dist_traveled: 18.1298,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 359,
    shape_dist_traveled: 18.1984,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 360,
    shape_dist_traveled: 18.2267,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 361,
    shape_dist_traveled: 18.2562,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 362,
    shape_dist_traveled: 18.3691,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 363,
    shape_dist_traveled: 18.3767,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 364,
    shape_dist_traveled: 18.3861,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 365,
    shape_dist_traveled: 18.4694,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 366,
    shape_dist_traveled: 18.7943,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 367,
    shape_dist_traveled: 18.8547,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 368,
    shape_dist_traveled: 18.9025,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068538,
    shape_pt_lon: -89.987498,
    shape_pt_sequence: 369,
    shape_dist_traveled: 18.9258,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068651,
    shape_pt_lon: -89.987425,
    shape_pt_sequence: 370,
    shape_dist_traveled: 18.9397,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068708,
    shape_pt_lon: -89.987389,
    shape_pt_sequence: 371,
    shape_dist_traveled: 18.9473,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068769,
    shape_pt_lon: -89.987349,
    shape_pt_sequence: 372,
    shape_dist_traveled: 18.9554,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 373,
    shape_dist_traveled: 18.9714,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06876,
    shape_pt_lon: -89.987699,
    shape_pt_sequence: 374,
    shape_dist_traveled: 18.9874,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.987927,
    shape_pt_sequence: 375,
    shape_dist_traveled: 19.0084,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.988046,
    shape_pt_sequence: 376,
    shape_dist_traveled: 19.0184,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.988227,
    shape_pt_sequence: 377,
    shape_dist_traveled: 19.0354,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06877,
    shape_pt_lon: -89.988361,
    shape_pt_sequence: 378,
    shape_dist_traveled: 19.0474,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068721,
    shape_pt_lon: -89.988469,
    shape_pt_sequence: 379,
    shape_dist_traveled: 19.0591,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.068742,
    shape_pt_lon: -89.989903,
    shape_pt_sequence: 380,
    shape_dist_traveled: 19.1881,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.989907,
    shape_pt_sequence: 381,
    shape_dist_traveled: 19.1992,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.069637,
    shape_pt_lon: -89.989967,
    shape_pt_sequence: 382,
    shape_dist_traveled: 19.2893,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.06984,
    shape_pt_lon: -89.989989,
    shape_pt_sequence: 383,
    shape_dist_traveled: 19.3114,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.070143,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 384,
    shape_dist_traveled: 19.3459,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07074,
    shape_pt_lon: -89.990231,
    shape_pt_sequence: 385,
    shape_dist_traveled: 19.4148,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071437,
    shape_pt_lon: -89.990478,
    shape_pt_sequence: 386,
    shape_dist_traveled: 19.4961,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072104,
    shape_pt_lon: -89.990719,
    shape_pt_sequence: 387,
    shape_dist_traveled: 19.574,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072269,
    shape_pt_lon: -89.990801,
    shape_pt_sequence: 388,
    shape_dist_traveled: 19.5937,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072539,
    shape_pt_lon: -89.990972,
    shape_pt_sequence: 389,
    shape_dist_traveled: 19.6272,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072719,
    shape_pt_lon: -89.991129,
    shape_pt_sequence: 390,
    shape_dist_traveled: 19.6522,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072912,
    shape_pt_lon: -89.99132,
    shape_pt_sequence: 391,
    shape_dist_traveled: 19.68,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073021,
    shape_pt_lon: -89.991452,
    shape_pt_sequence: 392,
    shape_dist_traveled: 19.697,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073171,
    shape_pt_lon: -89.991669,
    shape_pt_sequence: 393,
    shape_dist_traveled: 19.7225,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073299,
    shape_pt_lon: -89.991908,
    shape_pt_sequence: 394,
    shape_dist_traveled: 19.7486,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073332,
    shape_pt_lon: -89.991992,
    shape_pt_sequence: 395,
    shape_dist_traveled: 19.7575,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073434,
    shape_pt_lon: -89.99225,
    shape_pt_sequence: 396,
    shape_dist_traveled: 19.783,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07351,
    shape_pt_lon: -89.992503,
    shape_pt_sequence: 397,
    shape_dist_traveled: 19.8077,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073582,
    shape_pt_lon: -89.992906,
    shape_pt_sequence: 398,
    shape_dist_traveled: 19.8446,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073601,
    shape_pt_lon: -89.993191,
    shape_pt_sequence: 399,
    shape_dist_traveled: 19.8707,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073597,
    shape_pt_lon: -89.993464,
    shape_pt_sequence: 400,
    shape_dist_traveled: 19.8957,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073577,
    shape_pt_lon: -89.993711,
    shape_pt_sequence: 401,
    shape_dist_traveled: 19.9178,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07353,
    shape_pt_lon: -89.993983,
    shape_pt_sequence: 402,
    shape_dist_traveled: 19.9433,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07342,
    shape_pt_lon: -89.994368,
    shape_pt_sequence: 403,
    shape_dist_traveled: 19.9803,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07332,
    shape_pt_lon: -89.994616,
    shape_pt_sequence: 404,
    shape_dist_traveled: 20.0053,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073199,
    shape_pt_lon: -89.994847,
    shape_pt_sequence: 405,
    shape_dist_traveled: 20.03,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073057,
    shape_pt_lon: -89.995068,
    shape_pt_sequence: 406,
    shape_dist_traveled: 20.0556,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072899,
    shape_pt_lon: -89.995269,
    shape_pt_sequence: 407,
    shape_dist_traveled: 20.0811,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072565,
    shape_pt_lon: -89.995617,
    shape_pt_sequence: 408,
    shape_dist_traveled: 20.1294,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072413,
    shape_pt_lon: -89.995796,
    shape_pt_sequence: 409,
    shape_dist_traveled: 20.1534,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072402,
    shape_pt_lon: -89.995809,
    shape_pt_sequence: 410,
    shape_dist_traveled: 20.1557,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072258,
    shape_pt_lon: -89.996018,
    shape_pt_sequence: 411,
    shape_dist_traveled: 20.1805,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072132,
    shape_pt_lon: -89.996246,
    shape_pt_sequence: 412,
    shape_dist_traveled: 20.2049,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07202,
    shape_pt_lon: -89.996504,
    shape_pt_sequence: 413,
    shape_dist_traveled: 20.2317,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071939,
    shape_pt_lon: -89.99675,
    shape_pt_sequence: 414,
    shape_dist_traveled: 20.2555,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071885,
    shape_pt_lon: -89.996992,
    shape_pt_sequence: 415,
    shape_dist_traveled: 20.2783,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071848,
    shape_pt_lon: -89.997249,
    shape_pt_sequence: 416,
    shape_dist_traveled: 20.3018,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07183,
    shape_pt_lon: -89.997541,
    shape_pt_sequence: 417,
    shape_dist_traveled: 20.3279,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071836,
    shape_pt_lon: -89.99776,
    shape_pt_sequence: 418,
    shape_dist_traveled: 20.348,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071866,
    shape_pt_lon: -89.998059,
    shape_pt_sequence: 419,
    shape_dist_traveled: 20.3751,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071983,
    shape_pt_lon: -89.998612,
    shape_pt_sequence: 420,
    shape_dist_traveled: 20.4268,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072029,
    shape_pt_lon: -89.998862,
    shape_pt_sequence: 421,
    shape_dist_traveled: 20.4505,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072057,
    shape_pt_lon: -89.99915,
    shape_pt_sequence: 422,
    shape_dist_traveled: 20.4767,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07206,
    shape_pt_lon: -89.999426,
    shape_pt_sequence: 423,
    shape_dist_traveled: 20.5017,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072042,
    shape_pt_lon: -89.999675,
    shape_pt_sequence: 424,
    shape_dist_traveled: 20.5238,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072002,
    shape_pt_lon: -89.999949,
    shape_pt_sequence: 425,
    shape_dist_traveled: 20.5493,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071954,
    shape_pt_lon: -90.000169,
    shape_pt_sequence: 426,
    shape_dist_traveled: 20.5699,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071843,
    shape_pt_lon: -90.000613,
    shape_pt_sequence: 427,
    shape_dist_traveled: 20.6117,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07162,
    shape_pt_lon: -90.00154,
    shape_pt_sequence: 428,
    shape_dist_traveled: 20.6993,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071568,
    shape_pt_lon: -90.001794,
    shape_pt_sequence: 429,
    shape_dist_traveled: 20.7231,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071537,
    shape_pt_lon: -90.002077,
    shape_pt_sequence: 430,
    shape_dist_traveled: 20.7494,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071539,
    shape_pt_lon: -90.002357,
    shape_pt_sequence: 431,
    shape_dist_traveled: 20.7744,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071564,
    shape_pt_lon: -90.002761,
    shape_pt_sequence: 432,
    shape_dist_traveled: 20.8105,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071706,
    shape_pt_lon: -90.004411,
    shape_pt_sequence: 433,
    shape_dist_traveled: 20.9614,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071736,
    shape_pt_lon: -90.004794,
    shape_pt_sequence: 434,
    shape_dist_traveled: 20.9955,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071736,
    shape_pt_lon: -90.00518,
    shape_pt_sequence: 435,
    shape_dist_traveled: 21.0305,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071711,
    shape_pt_lon: -90.005529,
    shape_pt_sequence: 436,
    shape_dist_traveled: 21.0626,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071663,
    shape_pt_lon: -90.005867,
    shape_pt_sequence: 437,
    shape_dist_traveled: 21.0932,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071589,
    shape_pt_lon: -90.006209,
    shape_pt_sequence: 438,
    shape_dist_traveled: 21.1252,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071511,
    shape_pt_lon: -90.006491,
    shape_pt_sequence: 439,
    shape_dist_traveled: 21.1527,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071322,
    shape_pt_lon: -90.006949,
    shape_pt_sequence: 440,
    shape_dist_traveled: 21.1988,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07119,
    shape_pt_lon: -90.007243,
    shape_pt_sequence: 441,
    shape_dist_traveled: 21.2296,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07111,
    shape_pt_lon: -90.007311,
    shape_pt_sequence: 442,
    shape_dist_traveled: 21.2405,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071077,
    shape_pt_lon: -90.007329,
    shape_pt_sequence: 443,
    shape_dist_traveled: 21.2446,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071014,
    shape_pt_lon: -90.007433,
    shape_pt_sequence: 444,
    shape_dist_traveled: 21.2568,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071307,
    shape_pt_lon: -90.007526,
    shape_pt_sequence: 445,
    shape_dist_traveled: 21.2907,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071642,
    shape_pt_lon: -90.007589,
    shape_pt_sequence: 446,
    shape_dist_traveled: 21.3292,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071951,
    shape_pt_lon: -90.007607,
    shape_pt_sequence: 447,
    shape_dist_traveled: 21.3633,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072171,
    shape_pt_lon: -90.007601,
    shape_pt_sequence: 448,
    shape_dist_traveled: 21.3883,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072241,
    shape_pt_lon: -90.007597,
    shape_pt_sequence: 449,
    shape_dist_traveled: 21.3963,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072387,
    shape_pt_lon: -90.007621,
    shape_pt_sequence: 450,
    shape_dist_traveled: 21.4124,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072754,
    shape_pt_lon: -90.007578,
    shape_pt_sequence: 451,
    shape_dist_traveled: 21.4536,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07288,
    shape_pt_lon: -90.007553,
    shape_pt_sequence: 452,
    shape_dist_traveled: 21.4678,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072952,
    shape_pt_lon: -90.007551,
    shape_pt_sequence: 453,
    shape_dist_traveled: 21.4758,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073126,
    shape_pt_lon: -90.007582,
    shape_pt_sequence: 454,
    shape_dist_traveled: 21.4959,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073273,
    shape_pt_lon: -90.007662,
    shape_pt_sequence: 455,
    shape_dist_traveled: 21.5137,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073391,
    shape_pt_lon: -90.007771,
    shape_pt_sequence: 456,
    shape_dist_traveled: 21.5301,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073396,
    shape_pt_lon: -90.007779,
    shape_pt_sequence: 457,
    shape_dist_traveled: 21.5311,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073462,
    shape_pt_lon: -90.007881,
    shape_pt_sequence: 458,
    shape_dist_traveled: 21.5434,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073489,
    shape_pt_lon: -90.007933,
    shape_pt_sequence: 459,
    shape_dist_traveled: 21.5484,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073549,
    shape_pt_lon: -90.008112,
    shape_pt_sequence: 460,
    shape_dist_traveled: 21.5658,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073567,
    shape_pt_lon: -90.008205,
    shape_pt_sequence: 461,
    shape_dist_traveled: 21.575,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073579,
    shape_pt_lon: -90.008323,
    shape_pt_sequence: 462,
    shape_dist_traveled: 21.5861,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073574,
    shape_pt_lon: -90.008527,
    shape_pt_sequence: 463,
    shape_dist_traveled: 21.6041,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07349,
    shape_pt_lon: -90.008992,
    shape_pt_sequence: 464,
    shape_dist_traveled: 21.6473,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073396,
    shape_pt_lon: -90.009494,
    shape_pt_sequence: 465,
    shape_dist_traveled: 21.6934,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073212,
    shape_pt_lon: -90.010441,
    shape_pt_sequence: 466,
    shape_dist_traveled: 21.7819,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072917,
    shape_pt_lon: -90.011838,
    shape_pt_sequence: 467,
    shape_dist_traveled: 21.9121,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07287,
    shape_pt_lon: -90.012014,
    shape_pt_sequence: 468,
    shape_dist_traveled: 21.9289,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072699,
    shape_pt_lon: -90.012847,
    shape_pt_sequence: 469,
    shape_dist_traveled: 22.0072,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072629,
    shape_pt_lon: -90.013138,
    shape_pt_sequence: 470,
    shape_dist_traveled: 22.0344,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07255,
    shape_pt_lon: -90.013349,
    shape_pt_sequence: 471,
    shape_dist_traveled: 22.0555,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072444,
    shape_pt_lon: -90.013829,
    shape_pt_sequence: 472,
    shape_dist_traveled: 22.1001,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07243,
    shape_pt_lon: -90.013902,
    shape_pt_sequence: 473,
    shape_dist_traveled: 22.1072,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072038,
    shape_pt_lon: -90.016092,
    shape_pt_sequence: 474,
    shape_dist_traveled: 22.31,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071738,
    shape_pt_lon: -90.017757,
    shape_pt_sequence: 475,
    shape_dist_traveled: 22.4648,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07163,
    shape_pt_lon: -90.018458,
    shape_pt_sequence: 476,
    shape_dist_traveled: 22.5289,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071568,
    shape_pt_lon: -90.019042,
    shape_pt_sequence: 477,
    shape_dist_traveled: 22.5824,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071535,
    shape_pt_lon: -90.019531,
    shape_pt_sequence: 478,
    shape_dist_traveled: 22.6266,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071523,
    shape_pt_lon: -90.020251,
    shape_pt_sequence: 479,
    shape_dist_traveled: 22.6916,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07153,
    shape_pt_lon: -90.020421,
    shape_pt_sequence: 480,
    shape_dist_traveled: 22.7066,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071567,
    shape_pt_lon: -90.020991,
    shape_pt_sequence: 481,
    shape_dist_traveled: 22.7588,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071576,
    shape_pt_lon: -90.02111,
    shape_pt_sequence: 482,
    shape_dist_traveled: 22.7699,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07158,
    shape_pt_lon: -90.021148,
    shape_pt_sequence: 483,
    shape_dist_traveled: 22.7729,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071607,
    shape_pt_lon: -90.021409,
    shape_pt_sequence: 484,
    shape_dist_traveled: 22.797,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071626,
    shape_pt_lon: -90.02158,
    shape_pt_sequence: 485,
    shape_dist_traveled: 22.8122,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071719,
    shape_pt_lon: -90.022146,
    shape_pt_sequence: 486,
    shape_dist_traveled: 22.8643,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.071839,
    shape_pt_lon: -90.02273,
    shape_pt_sequence: 487,
    shape_dist_traveled: 22.9189,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07194,
    shape_pt_lon: -90.023036,
    shape_pt_sequence: 488,
    shape_dist_traveled: 22.949,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.07209,
    shape_pt_lon: -90.023699,
    shape_pt_sequence: 489,
    shape_dist_traveled: 23.0114,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072209,
    shape_pt_lon: -90.02406,
    shape_pt_sequence: 490,
    shape_dist_traveled: 23.0468,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072319,
    shape_pt_lon: -90.02431,
    shape_pt_sequence: 491,
    shape_dist_traveled: 23.0724,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072444,
    shape_pt_lon: -90.024532,
    shape_pt_sequence: 492,
    shape_dist_traveled: 23.0968,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072665,
    shape_pt_lon: -90.024831,
    shape_pt_sequence: 493,
    shape_dist_traveled: 23.1329,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.072798,
    shape_pt_lon: -90.024961,
    shape_pt_sequence: 494,
    shape_dist_traveled: 23.1521,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073087,
    shape_pt_lon: -90.02518,
    shape_pt_sequence: 495,
    shape_dist_traveled: 23.1899,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073377,
    shape_pt_lon: -90.025336,
    shape_pt_sequence: 496,
    shape_dist_traveled: 23.2257,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073603,
    shape_pt_lon: -90.025417,
    shape_pt_sequence: 497,
    shape_dist_traveled: 23.2517,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.073937,
    shape_pt_lon: -90.025493,
    shape_pt_sequence: 498,
    shape_dist_traveled: 23.2903,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.074306,
    shape_pt_lon: -90.025537,
    shape_pt_sequence: 499,
    shape_dist_traveled: 23.3315,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.074909,
    shape_pt_lon: -90.025599,
    shape_pt_sequence: 500,
    shape_dist_traveled: 23.3988,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.075183,
    shape_pt_lon: -90.025606,
    shape_pt_sequence: 501,
    shape_dist_traveled: 23.4298,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.076789,
    shape_pt_lon: -90.02557,
    shape_pt_sequence: 502,
    shape_dist_traveled: 23.6098,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.077297,
    shape_pt_lon: -90.025562,
    shape_pt_sequence: 503,
    shape_dist_traveled: 23.6668,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.078162,
    shape_pt_lon: -90.025563,
    shape_pt_sequence: 504,
    shape_dist_traveled: 23.7628,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.078717,
    shape_pt_lon: -90.025573,
    shape_pt_sequence: 505,
    shape_dist_traveled: 23.8248,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.078869,
    shape_pt_lon: -90.025566,
    shape_pt_sequence: 506,
    shape_dist_traveled: 23.8419,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.079533,
    shape_pt_lon: -90.025531,
    shape_pt_sequence: 507,
    shape_dist_traveled: 23.9169,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.082461,
    shape_pt_lon: -90.025478,
    shape_pt_sequence: 508,
    shape_dist_traveled: 24.244,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.083375,
    shape_pt_lon: -90.025488,
    shape_pt_sequence: 509,
    shape_dist_traveled: 24.347,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.083409,
    shape_pt_lon: -90.025486,
    shape_pt_sequence: 510,
    shape_dist_traveled: 24.35,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025481,
    shape_pt_sequence: 511,
    shape_dist_traveled: 24.359,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.084341,
    shape_pt_lon: -90.025442,
    shape_pt_sequence: 512,
    shape_dist_traveled: 24.4551,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.085109,
    shape_pt_lon: -90.025431,
    shape_pt_sequence: 513,
    shape_dist_traveled: 24.5411,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.086704,
    shape_pt_lon: -90.025403,
    shape_pt_sequence: 514,
    shape_dist_traveled: 24.7191,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.089016,
    shape_pt_lon: -90.025363,
    shape_pt_sequence: 515,
    shape_dist_traveled: 24.9781,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.089959,
    shape_pt_lon: -90.025378,
    shape_pt_sequence: 516,
    shape_dist_traveled: 25.0831,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.090532,
    shape_pt_lon: -90.025408,
    shape_pt_sequence: 517,
    shape_dist_traveled: 25.1472,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.091087,
    shape_pt_lon: -90.025456,
    shape_pt_sequence: 518,
    shape_dist_traveled: 25.2104,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.091178,
    shape_pt_lon: -90.025465,
    shape_pt_sequence: 519,
    shape_dist_traveled: 25.2204,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.092253,
    shape_pt_lon: -90.025623,
    shape_pt_sequence: 520,
    shape_dist_traveled: 25.3412,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.09307,
    shape_pt_lon: -90.025769,
    shape_pt_sequence: 521,
    shape_dist_traveled: 25.4331,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.093595,
    shape_pt_lon: -90.025895,
    shape_pt_sequence: 522,
    shape_dist_traveled: 25.4934,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.094126,
    shape_pt_lon: -90.026036,
    shape_pt_sequence: 523,
    shape_dist_traveled: 25.5545,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.094918,
    shape_pt_lon: -90.026278,
    shape_pt_sequence: 524,
    shape_dist_traveled: 25.6453,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.095758,
    shape_pt_lon: -90.026579,
    shape_pt_sequence: 525,
    shape_dist_traveled: 25.7433,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.096329,
    shape_pt_lon: -90.026818,
    shape_pt_sequence: 526,
    shape_dist_traveled: 25.8107,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.097076,
    shape_pt_lon: -90.027153,
    shape_pt_sequence: 527,
    shape_dist_traveled: 25.9002,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.09827,
    shape_pt_lon: -90.027749,
    shape_pt_sequence: 528,
    shape_dist_traveled: 26.0434,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.100511,
    shape_pt_lon: -90.028888,
    shape_pt_sequence: 529,
    shape_dist_traveled: 26.3151,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.10082,
    shape_pt_lon: -90.029019,
    shape_pt_sequence: 530,
    shape_dist_traveled: 26.3508,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.100875,
    shape_pt_lon: -90.029041,
    shape_pt_sequence: 531,
    shape_dist_traveled: 26.3581,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.101301,
    shape_pt_lon: -90.029161,
    shape_pt_sequence: 532,
    shape_dist_traveled: 26.4064,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.101537,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 533,
    shape_dist_traveled: 26.4338,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.101959,
    shape_pt_lon: -90.029262,
    shape_pt_sequence: 534,
    shape_dist_traveled: 26.481,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.102202,
    shape_pt_lon: -90.029272,
    shape_pt_sequence: 535,
    shape_dist_traveled: 26.508,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.102571,
    shape_pt_lon: -90.029271,
    shape_pt_sequence: 536,
    shape_dist_traveled: 26.549,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.105902,
    shape_pt_lon: -90.029139,
    shape_pt_sequence: 537,
    shape_dist_traveled: 26.9222,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.106364,
    shape_pt_lon: -90.029122,
    shape_pt_sequence: 538,
    shape_dist_traveled: 26.9742,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.107012,
    shape_pt_lon: -90.029093,
    shape_pt_sequence: 539,
    shape_dist_traveled: 27.0463,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.107119,
    shape_pt_lon: -90.02909,
    shape_pt_sequence: 540,
    shape_dist_traveled: 27.0583,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.107702,
    shape_pt_lon: -90.029061,
    shape_pt_sequence: 541,
    shape_dist_traveled: 27.1233,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.108011,
    shape_pt_lon: -90.029052,
    shape_pt_sequence: 542,
    shape_dist_traveled: 27.1583,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.109511,
    shape_pt_lon: -90.029003,
    shape_pt_sequence: 543,
    shape_dist_traveled: 27.3264,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.109679,
    shape_pt_lon: -90.029,
    shape_pt_sequence: 544,
    shape_dist_traveled: 27.3454,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.109777,
    shape_pt_lon: -90.028997,
    shape_pt_sequence: 545,
    shape_dist_traveled: 27.3564,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.109861,
    shape_pt_lon: -90.028992,
    shape_pt_sequence: 546,
    shape_dist_traveled: 27.3655,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.110022,
    shape_pt_lon: -90.028983,
    shape_pt_sequence: 547,
    shape_dist_traveled: 27.3835,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.110673,
    shape_pt_lon: -90.028943,
    shape_pt_sequence: 548,
    shape_dist_traveled: 27.4566,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.11161,
    shape_pt_lon: -90.0289,
    shape_pt_sequence: 549,
    shape_dist_traveled: 27.5616,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.111741,
    shape_pt_lon: -90.028887,
    shape_pt_sequence: 550,
    shape_dist_traveled: 27.5757,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.112089,
    shape_pt_lon: -90.028852,
    shape_pt_sequence: 551,
    shape_dist_traveled: 27.6148,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.1124,
    shape_pt_lon: -90.028811,
    shape_pt_sequence: 552,
    shape_dist_traveled: 27.65,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.112876,
    shape_pt_lon: -90.028721,
    shape_pt_sequence: 553,
    shape_dist_traveled: 27.7036,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.113542,
    shape_pt_lon: -90.028546,
    shape_pt_sequence: 554,
    shape_dist_traveled: 27.7803,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.114053,
    shape_pt_lon: -90.028369,
    shape_pt_sequence: 555,
    shape_dist_traveled: 27.8395,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.114719,
    shape_pt_lon: -90.028092,
    shape_pt_sequence: 556,
    shape_dist_traveled: 27.9186,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 557,
    shape_dist_traveled: 28.0042,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.11563,
    shape_pt_lon: -90.027631,
    shape_pt_sequence: 558,
    shape_dist_traveled: 28.0285,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.116068,
    shape_pt_lon: -90.027368,
    shape_pt_sequence: 559,
    shape_dist_traveled: 28.0831,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.11794,
    shape_pt_lon: -90.026334,
    shape_pt_sequence: 560,
    shape_dist_traveled: 28.3119,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.118488,
    shape_pt_lon: -90.026051,
    shape_pt_sequence: 561,
    shape_dist_traveled: 28.3791,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.11884,
    shape_pt_lon: -90.025907,
    shape_pt_sequence: 562,
    shape_dist_traveled: 28.4202,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.119119,
    shape_pt_lon: -90.025819,
    shape_pt_sequence: 563,
    shape_dist_traveled: 28.4522,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.119259,
    shape_pt_lon: -90.025781,
    shape_pt_sequence: 564,
    shape_dist_traveled: 28.4685,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -90.025737,
    shape_pt_sequence: 565,
    shape_dist_traveled: 28.4958,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.119909,
    shape_pt_lon: -90.025688,
    shape_pt_sequence: 566,
    shape_dist_traveled: 28.5421,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.121643,
    shape_pt_lon: -90.025593,
    shape_pt_sequence: 567,
    shape_dist_traveled: 28.7362,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.122343,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 568,
    shape_dist_traveled: 28.8143,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -90.025517,
    shape_pt_sequence: 569,
    shape_dist_traveled: 28.8634,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.123137,
    shape_pt_lon: -90.025458,
    shape_pt_sequence: 570,
    shape_dist_traveled: 28.9038,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.123545,
    shape_pt_lon: -90.02535,
    shape_pt_sequence: 571,
    shape_dist_traveled: 28.9499,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.123869,
    shape_pt_lon: -90.025227,
    shape_pt_sequence: 572,
    shape_dist_traveled: 28.9885,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.123983,
    shape_pt_lon: -90.025175,
    shape_pt_sequence: 573,
    shape_dist_traveled: 29.0015,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.124779,
    shape_pt_lon: -90.024772,
    shape_pt_sequence: 574,
    shape_dist_traveled: 29.0975,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.124826,
    shape_pt_lon: -90.024748,
    shape_pt_sequence: 575,
    shape_dist_traveled: 29.1038,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.12495,
    shape_pt_lon: -90.024686,
    shape_pt_sequence: 576,
    shape_dist_traveled: 29.119,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.125887,
    shape_pt_lon: -90.024208,
    shape_pt_sequence: 577,
    shape_dist_traveled: 29.2325,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.126213,
    shape_pt_lon: -90.024053,
    shape_pt_sequence: 578,
    shape_dist_traveled: 29.2711,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.126501,
    shape_pt_lon: -90.023936,
    shape_pt_sequence: 579,
    shape_dist_traveled: 29.3049,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.126722,
    shape_pt_lon: -90.023858,
    shape_pt_sequence: 580,
    shape_dist_traveled: 29.3309,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.126882,
    shape_pt_lon: -90.023811,
    shape_pt_sequence: 581,
    shape_dist_traveled: 29.3493,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.126986,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 582,
    shape_dist_traveled: 29.3605,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.127379,
    shape_pt_lon: -90.02372,
    shape_pt_sequence: 583,
    shape_dist_traveled: 29.4049,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.12775,
    shape_pt_lon: -90.023696,
    shape_pt_sequence: 584,
    shape_dist_traveled: 29.447,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.128237,
    shape_pt_lon: -90.023704,
    shape_pt_sequence: 585,
    shape_dist_traveled: 29.501,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.128922,
    shape_pt_lon: -90.023741,
    shape_pt_sequence: 586,
    shape_dist_traveled: 29.5781,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.130168,
    shape_pt_lon: -90.02381,
    shape_pt_sequence: 587,
    shape_dist_traveled: 29.7182,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 588,
    shape_dist_traveled: 29.7292,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.130817,
    shape_pt_lon: -90.023853,
    shape_pt_sequence: 589,
    shape_dist_traveled: 29.7903,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.13155,
    shape_pt_lon: -90.023889,
    shape_pt_sequence: 590,
    shape_dist_traveled: 29.8724,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.132426,
    shape_pt_lon: -90.023948,
    shape_pt_sequence: 591,
    shape_dist_traveled: 29.9706,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.132822,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 592,
    shape_dist_traveled: 30.0156,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.133088,
    shape_pt_lon: -90.023978,
    shape_pt_sequence: 593,
    shape_dist_traveled: 30.0446,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.133638,
    shape_pt_lon: -90.024009,
    shape_pt_sequence: 594,
    shape_dist_traveled: 30.1067,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.134721,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 595,
    shape_dist_traveled: 30.2278,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.135522,
    shape_pt_lon: -90.024111,
    shape_pt_sequence: 596,
    shape_dist_traveled: 30.3179,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.136078,
    shape_pt_lon: -90.024118,
    shape_pt_sequence: 597,
    shape_dist_traveled: 30.3799,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.136243,
    shape_pt_lon: -90.024114,
    shape_pt_sequence: 598,
    shape_dist_traveled: 30.3979,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.136485,
    shape_pt_lon: -90.024128,
    shape_pt_sequence: 599,
    shape_dist_traveled: 30.425,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.137188,
    shape_pt_lon: -90.024132,
    shape_pt_sequence: 600,
    shape_dist_traveled: 30.504,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.13758,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 601,
    shape_dist_traveled: 30.5481,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.138131,
    shape_pt_lon: -90.024022,
    shape_pt_sequence: 602,
    shape_dist_traveled: 30.6105,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.138366,
    shape_pt_lon: -90.02397,
    shape_pt_sequence: 603,
    shape_dist_traveled: 30.6369,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.138586,
    shape_pt_lon: -90.023902,
    shape_pt_sequence: 604,
    shape_dist_traveled: 30.6617,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.138727,
    shape_pt_lon: -90.023851,
    shape_pt_sequence: 605,
    shape_dist_traveled: 30.6784,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.139069,
    shape_pt_lon: -90.023723,
    shape_pt_sequence: 606,
    shape_dist_traveled: 30.719,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.139585,
    shape_pt_lon: -90.023492,
    shape_pt_sequence: 607,
    shape_dist_traveled: 30.7797,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.139782,
    shape_pt_lon: -90.02339,
    shape_pt_sequence: 608,
    shape_dist_traveled: 30.8035,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.140268,
    shape_pt_lon: -90.02318,
    shape_pt_sequence: 609,
    shape_dist_traveled: 30.8617,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.140758,
    shape_pt_lon: -90.023003,
    shape_pt_sequence: 610,
    shape_dist_traveled: 30.9189,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.140999,
    shape_pt_lon: -90.022931,
    shape_pt_sequence: 611,
    shape_dist_traveled: 30.9468,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.141232,
    shape_pt_lon: -90.022881,
    shape_pt_sequence: 612,
    shape_dist_traveled: 30.9731,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.142032,
    shape_pt_lon: -90.022728,
    shape_pt_sequence: 613,
    shape_dist_traveled: 31.0623,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 614,
    shape_dist_traveled: 31.0835,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.142399,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 615,
    shape_dist_traveled: 31.1037,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.143278,
    shape_pt_lon: -90.022541,
    shape_pt_sequence: 616,
    shape_dist_traveled: 31.2033,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.143372,
    shape_pt_lon: -90.022528,
    shape_pt_sequence: 617,
    shape_dist_traveled: 31.2133,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.143792,
    shape_pt_lon: -90.02248,
    shape_pt_sequence: 618,
    shape_dist_traveled: 31.2605,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.144391,
    shape_pt_lon: -90.022412,
    shape_pt_sequence: 619,
    shape_dist_traveled: 31.3278,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.144479,
    shape_pt_lon: -90.022402,
    shape_pt_sequence: 620,
    shape_dist_traveled: 31.3378,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.144566,
    shape_pt_lon: -90.022392,
    shape_pt_sequence: 621,
    shape_dist_traveled: 31.3479,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.14488,
    shape_pt_lon: -90.022359,
    shape_pt_sequence: 622,
    shape_dist_traveled: 31.383,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.146014,
    shape_pt_lon: -90.022076,
    shape_pt_sequence: 623,
    shape_dist_traveled: 31.5126,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.1465,
    shape_pt_lon: -90.021935,
    shape_pt_sequence: 624,
    shape_dist_traveled: 31.568,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.146969,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 625,
    shape_dist_traveled: 31.6221,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.14713,
    shape_pt_lon: -90.021788,
    shape_pt_sequence: 626,
    shape_dist_traveled: 31.6403,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.14739,
    shape_pt_lon: -90.021777,
    shape_pt_sequence: 627,
    shape_dist_traveled: 31.6694,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.147775,
    shape_pt_lon: -90.021782,
    shape_pt_sequence: 628,
    shape_dist_traveled: 31.7124,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.14813,
    shape_pt_lon: -90.021836,
    shape_pt_sequence: 629,
    shape_dist_traveled: 31.7527,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148207,
    shape_pt_lon: -90.021862,
    shape_pt_sequence: 630,
    shape_dist_traveled: 31.7609,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148479,
    shape_pt_lon: -90.021963,
    shape_pt_sequence: 631,
    shape_dist_traveled: 31.7932,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148581,
    shape_pt_lon: -90.022021,
    shape_pt_sequence: 632,
    shape_dist_traveled: 31.8053,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148829,
    shape_pt_lon: -90.022147,
    shape_pt_sequence: 633,
    shape_dist_traveled: 31.8358,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148881,
    shape_pt_lon: -90.022179,
    shape_pt_sequence: 634,
    shape_dist_traveled: 31.8425,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.148931,
    shape_pt_lon: -90.022211,
    shape_pt_sequence: 635,
    shape_dist_traveled: 31.8483,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149037,
    shape_pt_lon: -90.02228,
    shape_pt_sequence: 636,
    shape_dist_traveled: 31.8617,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149163,
    shape_pt_lon: -90.022389,
    shape_pt_sequence: 637,
    shape_dist_traveled: 31.8789,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.1493,
    shape_pt_lon: -90.022525,
    shape_pt_sequence: 638,
    shape_dist_traveled: 31.8981,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149366,
    shape_pt_lon: -90.022602,
    shape_pt_sequence: 639,
    shape_dist_traveled: 31.9088,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.14949,
    shape_pt_lon: -90.022778,
    shape_pt_sequence: 640,
    shape_dist_traveled: 31.93,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149825,
    shape_pt_lon: -90.023314,
    shape_pt_sequence: 641,
    shape_dist_traveled: 31.9906,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149912,
    shape_pt_lon: -90.023484,
    shape_pt_sequence: 642,
    shape_dist_traveled: 32.0095,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149963,
    shape_pt_lon: -90.023582,
    shape_pt_sequence: 643,
    shape_dist_traveled: 32.0203,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.149999,
    shape_pt_lon: -90.023686,
    shape_pt_sequence: 644,
    shape_dist_traveled: 32.0302,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150036,
    shape_pt_lon: -90.023793,
    shape_pt_sequence: 645,
    shape_dist_traveled: 32.0409,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150081,
    shape_pt_lon: -90.023891,
    shape_pt_sequence: 646,
    shape_dist_traveled: 32.0504,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150175,
    shape_pt_lon: -90.024398,
    shape_pt_sequence: 647,
    shape_dist_traveled: 32.0974,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15022,
    shape_pt_lon: -90.024888,
    shape_pt_sequence: 648,
    shape_dist_traveled: 32.1427,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150243,
    shape_pt_lon: -90.025741,
    shape_pt_sequence: 649,
    shape_dist_traveled: 32.2198,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150244,
    shape_pt_lon: -90.026225,
    shape_pt_sequence: 650,
    shape_dist_traveled: 32.2628,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150256,
    shape_pt_lon: -90.027377,
    shape_pt_sequence: 651,
    shape_dist_traveled: 32.3668,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150256,
    shape_pt_lon: -90.027412,
    shape_pt_sequence: 652,
    shape_dist_traveled: 32.3708,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150247,
    shape_pt_lon: -90.029446,
    shape_pt_sequence: 653,
    shape_dist_traveled: 32.5538,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150238,
    shape_pt_lon: -90.030458,
    shape_pt_sequence: 654,
    shape_dist_traveled: 32.6458,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150322,
    shape_pt_lon: -90.031227,
    shape_pt_sequence: 655,
    shape_dist_traveled: 32.7154,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15038,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 656,
    shape_dist_traveled: 32.753,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150415,
    shape_pt_lon: -90.031885,
    shape_pt_sequence: 657,
    shape_dist_traveled: 32.7764,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150553,
    shape_pt_lon: -90.032885,
    shape_pt_sequence: 658,
    shape_dist_traveled: 32.8676,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150728,
    shape_pt_lon: -90.034156,
    shape_pt_sequence: 659,
    shape_dist_traveled: 32.9843,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150767,
    shape_pt_lon: -90.034403,
    shape_pt_sequence: 660,
    shape_dist_traveled: 33.0067,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150783,
    shape_pt_lon: -90.034593,
    shape_pt_sequence: 661,
    shape_dist_traveled: 33.0238,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.150902,
    shape_pt_lon: -90.035464,
    shape_pt_sequence: 662,
    shape_dist_traveled: 33.1039,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151161,
    shape_pt_lon: -90.037379,
    shape_pt_sequence: 663,
    shape_dist_traveled: 33.2793,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151394,
    shape_pt_lon: -90.039082,
    shape_pt_sequence: 664,
    shape_dist_traveled: 33.4355,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15148,
    shape_pt_lon: -90.039771,
    shape_pt_sequence: 665,
    shape_dist_traveled: 33.4983,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151522,
    shape_pt_lon: -90.040204,
    shape_pt_sequence: 666,
    shape_dist_traveled: 33.5375,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151568,
    shape_pt_lon: -90.040545,
    shape_pt_sequence: 667,
    shape_dist_traveled: 33.5691,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151576,
    shape_pt_lon: -90.040598,
    shape_pt_sequence: 668,
    shape_dist_traveled: 33.5741,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151587,
    shape_pt_lon: -90.040673,
    shape_pt_sequence: 669,
    shape_dist_traveled: 33.5813,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151594,
    shape_pt_lon: -90.040731,
    shape_pt_sequence: 670,
    shape_dist_traveled: 33.5863,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151625,
    shape_pt_lon: -90.040935,
    shape_pt_sequence: 671,
    shape_dist_traveled: 33.6048,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151751,
    shape_pt_lon: -90.04205,
    shape_pt_sequence: 672,
    shape_dist_traveled: 33.7067,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151784,
    shape_pt_lon: -90.042425,
    shape_pt_sequence: 673,
    shape_dist_traveled: 33.741,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151836,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 674,
    shape_dist_traveled: 33.8121,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151859,
    shape_pt_lon: -90.043478,
    shape_pt_sequence: 675,
    shape_dist_traveled: 33.8363,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151909,
    shape_pt_lon: -90.043906,
    shape_pt_sequence: 676,
    shape_dist_traveled: 33.8758,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151963,
    shape_pt_lon: -90.044238,
    shape_pt_sequence: 677,
    shape_dist_traveled: 33.9064,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152047,
    shape_pt_lon: -90.044626,
    shape_pt_sequence: 678,
    shape_dist_traveled: 33.9425,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152076,
    shape_pt_lon: -90.044736,
    shape_pt_sequence: 679,
    shape_dist_traveled: 33.953,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152094,
    shape_pt_lon: -90.044802,
    shape_pt_sequence: 680,
    shape_dist_traveled: 33.9593,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152176,
    shape_pt_lon: -90.044992,
    shape_pt_sequence: 681,
    shape_dist_traveled: 33.9785,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152489,
    shape_pt_lon: -90.045667,
    shape_pt_sequence: 682,
    shape_dist_traveled: 34.0489,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152724,
    shape_pt_lon: -90.04606,
    shape_pt_sequence: 683,
    shape_dist_traveled: 34.0931,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153054,
    shape_pt_lon: -90.046555,
    shape_pt_sequence: 684,
    shape_dist_traveled: 34.1513,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 685,
    shape_dist_traveled: 34.1813,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153298,
    shape_pt_lon: -90.04696,
    shape_pt_sequence: 686,
    shape_dist_traveled: 34.1971,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15337,
    shape_pt_lon: -90.047131,
    shape_pt_sequence: 687,
    shape_dist_traveled: 34.2141,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153408,
    shape_pt_lon: -90.047272,
    shape_pt_sequence: 688,
    shape_dist_traveled: 34.2277,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153432,
    shape_pt_lon: -90.04745,
    shape_pt_sequence: 689,
    shape_dist_traveled: 34.244,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153433,
    shape_pt_lon: -90.047673,
    shape_pt_sequence: 690,
    shape_dist_traveled: 34.264,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153417,
    shape_pt_lon: -90.04777,
    shape_pt_sequence: 691,
    shape_dist_traveled: 34.2732,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153359,
    shape_pt_lon: -90.04793,
    shape_pt_sequence: 692,
    shape_dist_traveled: 34.2885,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153271,
    shape_pt_lon: -90.048071,
    shape_pt_sequence: 693,
    shape_dist_traveled: 34.3049,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153174,
    shape_pt_lon: -90.04816,
    shape_pt_sequence: 694,
    shape_dist_traveled: 34.3185,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153113,
    shape_pt_lon: -90.048196,
    shape_pt_sequence: 695,
    shape_dist_traveled: 34.3261,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15306,
    shape_pt_lon: -90.048226,
    shape_pt_sequence: 696,
    shape_dist_traveled: 34.3328,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152988,
    shape_pt_lon: -90.048264,
    shape_pt_sequence: 697,
    shape_dist_traveled: 34.3413,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15292,
    shape_pt_lon: -90.048292,
    shape_pt_sequence: 698,
    shape_dist_traveled: 34.3489,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 699,
    shape_dist_traveled: 34.3624,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 700,
    shape_dist_traveled: 34.4781,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151659,
    shape_pt_lon: -90.048161,
    shape_pt_sequence: 701,
    shape_dist_traveled: 34.5353,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 702,
    shape_dist_traveled: 34.5958,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 703,
    shape_dist_traveled: 34.709,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 704,
    shape_dist_traveled: 34.7188,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 705,
    shape_dist_traveled: 34.7414,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 706,
    shape_dist_traveled: 34.7531,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 707,
    shape_dist_traveled: 34.8002,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 708,
    shape_dist_traveled: 34.8271,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 709,
    shape_dist_traveled: 34.8427,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 710,
    shape_dist_traveled: 34.947,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 711,
    shape_dist_traveled: 35.0586,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 712,
    shape_dist_traveled: 35.0659,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 713,
    shape_dist_traveled: 35.0713,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 714,
    shape_dist_traveled: 35.0713,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 715,
    shape_dist_traveled: 35.1279,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 716,
    shape_dist_traveled: 35.1279,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 717,
    shape_dist_traveled: 35.1804,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 718,
    shape_dist_traveled: 35.2399,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 719,
    shape_dist_traveled: 35.2987,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 720,
    shape_dist_traveled: 35.3158,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 721,
    shape_dist_traveled: 35.3784,
  },
  {
    shape_id: 38207,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 722,
    shape_dist_traveled: 35.4294,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04482,
    shape_pt_lon: -89.981018,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044823,
    shape_pt_lon: -89.980573,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.04,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044829,
    shape_pt_lon: -89.980233,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.071,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044833,
    shape_pt_lon: -89.980198,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.074,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044858,
    shape_pt_lon: -89.980097,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0835,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044876,
    shape_pt_lon: -89.980061,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0871,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.044919,
    shape_pt_lon: -89.980001,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.0949,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.045009,
    shape_pt_lon: -89.97994,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1061,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.045047,
    shape_pt_lon: -89.979937,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.1102,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04526,
    shape_pt_lon: -89.979941,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.1342,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.045898,
    shape_pt_lon: -89.979953,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.2053,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.046273,
    shape_pt_lon: -89.980003,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.2475,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04642,
    shape_pt_lon: -89.980047,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.265,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.046514,
    shape_pt_lon: -89.98008,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.2755,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04701,
    shape_pt_lon: -89.980179,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.3322,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.047052,
    shape_pt_lon: -89.980188,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.3362,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.047134,
    shape_pt_lon: -89.980201,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3454,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04739,
    shape_pt_lon: -89.980248,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.3747,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.047637,
    shape_pt_lon: -89.980254,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.4027,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04779,
    shape_pt_lon: -89.980259,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.4197,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.048188,
    shape_pt_lon: -89.980269,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.4637,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.04854,
    shape_pt_lon: -89.980281,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.5037,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.048696,
    shape_pt_lon: -89.980286,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.5207,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.048733,
    shape_pt_lon: -89.980287,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.5248,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.048841,
    shape_pt_lon: -89.980293,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.5368,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.049109,
    shape_pt_lon: -89.980302,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.5669,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.049536,
    shape_pt_lon: -89.980311,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.6149,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.049986,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 28,
    shape_dist_traveled: 0.6659,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050207,
    shape_pt_lon: -89.980329,
    shape_pt_sequence: 29,
    shape_dist_traveled: 0.6899,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05033,
    shape_pt_lon: -89.980337,
    shape_pt_sequence: 30,
    shape_dist_traveled: 0.7039,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050434,
    shape_pt_lon: -89.980363,
    shape_pt_sequence: 31,
    shape_dist_traveled: 0.7163,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.980413,
    shape_pt_sequence: 32,
    shape_dist_traveled: 0.728,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050711,
    shape_pt_lon: -89.980539,
    shape_pt_sequence: 33,
    shape_dist_traveled: 0.7513,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050799,
    shape_pt_lon: -89.980647,
    shape_pt_sequence: 34,
    shape_dist_traveled: 0.7648,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050864,
    shape_pt_lon: -89.98075,
    shape_pt_sequence: 35,
    shape_dist_traveled: 0.777,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05092,
    shape_pt_lon: -89.980845,
    shape_pt_sequence: 36,
    shape_dist_traveled: 0.787,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.050977,
    shape_pt_lon: -89.980961,
    shape_pt_sequence: 37,
    shape_dist_traveled: 0.7995,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.051107,
    shape_pt_lon: -89.981309,
    shape_pt_sequence: 38,
    shape_dist_traveled: 0.8339,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.051212,
    shape_pt_lon: -89.981513,
    shape_pt_sequence: 39,
    shape_dist_traveled: 0.8564,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05137,
    shape_pt_lon: -89.981681,
    shape_pt_sequence: 40,
    shape_dist_traveled: 0.8791,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.051597,
    shape_pt_lon: -89.981834,
    shape_pt_sequence: 41,
    shape_dist_traveled: 0.9086,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.051848,
    shape_pt_lon: -89.981897,
    shape_pt_sequence: 42,
    shape_dist_traveled: 0.9371,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.051966,
    shape_pt_lon: -89.981917,
    shape_pt_sequence: 43,
    shape_dist_traveled: 0.9502,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 44,
    shape_dist_traveled: 0.9653,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052342,
    shape_pt_lon: -89.98197,
    shape_pt_sequence: 45,
    shape_dist_traveled: 0.9925,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052543,
    shape_pt_lon: -89.981989,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.0156,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052669,
    shape_pt_lon: -89.981979,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.0296,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052772,
    shape_pt_lon: -89.98193,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.0413,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052877,
    shape_pt_lon: -89.981834,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.0563,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052929,
    shape_pt_lon: -89.981748,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.0663,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052962,
    shape_pt_lon: -89.981669,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.074,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052987,
    shape_pt_lon: -89.981547,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.0854,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052989,
    shape_pt_lon: -89.981449,
    shape_pt_sequence: 53,
    shape_dist_traveled: 1.0944,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052967,
    shape_pt_lon: -89.981307,
    shape_pt_sequence: 54,
    shape_dist_traveled: 1.1075,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052907,
    shape_pt_lon: -89.98118,
    shape_pt_sequence: 55,
    shape_dist_traveled: 1.1205,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052823,
    shape_pt_lon: -89.981077,
    shape_pt_sequence: 56,
    shape_dist_traveled: 1.134,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052769,
    shape_pt_lon: -89.98103,
    shape_pt_sequence: 57,
    shape_dist_traveled: 1.1412,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052643,
    shape_pt_lon: -89.980974,
    shape_pt_sequence: 58,
    shape_dist_traveled: 1.1561,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052548,
    shape_pt_lon: -89.980962,
    shape_pt_sequence: 59,
    shape_dist_traveled: 1.1671,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052438,
    shape_pt_lon: -89.980988,
    shape_pt_sequence: 60,
    shape_dist_traveled: 1.1793,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052322,
    shape_pt_lon: -89.981058,
    shape_pt_sequence: 61,
    shape_dist_traveled: 1.194,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052257,
    shape_pt_lon: -89.981136,
    shape_pt_sequence: 62,
    shape_dist_traveled: 1.2039,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.0522,
    shape_pt_lon: -89.981242,
    shape_pt_sequence: 63,
    shape_dist_traveled: 1.2154,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052159,
    shape_pt_lon: -89.981396,
    shape_pt_sequence: 64,
    shape_dist_traveled: 1.2299,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052121,
    shape_pt_lon: -89.981575,
    shape_pt_sequence: 65,
    shape_dist_traveled: 1.2467,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 66,
    shape_dist_traveled: 1.2797,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052093,
    shape_pt_lon: -89.981988,
    shape_pt_sequence: 67,
    shape_dist_traveled: 1.2848,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 68,
    shape_dist_traveled: 1.2928,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.982481,
    shape_pt_sequence: 69,
    shape_dist_traveled: 1.3288,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052098,
    shape_pt_lon: -89.982754,
    shape_pt_sequence: 70,
    shape_dist_traveled: 1.3539,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052151,
    shape_pt_lon: -89.98318,
    shape_pt_sequence: 71,
    shape_dist_traveled: 1.3934,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052229,
    shape_pt_lon: -89.983566,
    shape_pt_sequence: 72,
    shape_dist_traveled: 1.4293,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052309,
    shape_pt_lon: -89.983848,
    shape_pt_sequence: 73,
    shape_dist_traveled: 1.4559,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05263,
    shape_pt_lon: -89.9848,
    shape_pt_sequence: 74,
    shape_dist_traveled: 1.5491,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052666,
    shape_pt_lon: -89.984901,
    shape_pt_sequence: 75,
    shape_dist_traveled: 1.5589,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.052852,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 76,
    shape_dist_traveled: 1.6086,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053109,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 77,
    shape_dist_traveled: 1.6673,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053158,
    shape_pt_lon: -89.986057,
    shape_pt_sequence: 78,
    shape_dist_traveled: 1.6776,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053313,
    shape_pt_lon: -89.98643,
    shape_pt_sequence: 79,
    shape_dist_traveled: 1.7156,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053406,
    shape_pt_lon: -89.986702,
    shape_pt_sequence: 80,
    shape_dist_traveled: 1.742,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053468,
    shape_pt_lon: -89.986996,
    shape_pt_sequence: 81,
    shape_dist_traveled: 1.7699,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05349,
    shape_pt_lon: -89.987205,
    shape_pt_sequence: 82,
    shape_dist_traveled: 1.789,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053503,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 83,
    shape_dist_traveled: 1.8091,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053497,
    shape_pt_lon: -89.98765,
    shape_pt_sequence: 84,
    shape_dist_traveled: 1.8291,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05345,
    shape_pt_lon: -89.987999,
    shape_pt_sequence: 85,
    shape_dist_traveled: 1.8615,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053319,
    shape_pt_lon: -89.988731,
    shape_pt_sequence: 86,
    shape_dist_traveled: 1.9292,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.989051,
    shape_pt_sequence: 87,
    shape_dist_traveled: 1.9583,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053299,
    shape_pt_lon: -89.989227,
    shape_pt_sequence: 88,
    shape_dist_traveled: 1.9744,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053303,
    shape_pt_lon: -89.989473,
    shape_pt_sequence: 89,
    shape_dist_traveled: 1.9964,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053284,
    shape_pt_lon: -89.989631,
    shape_pt_sequence: 90,
    shape_dist_traveled: 2.0105,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 91,
    shape_dist_traveled: 2.0181,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 92,
    shape_dist_traveled: 2.0222,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 93,
    shape_dist_traveled: 2.0322,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 94,
    shape_dist_traveled: 2.0362,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 95,
    shape_dist_traveled: 2.0432,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 96,
    shape_dist_traveled: 2.1053,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 97,
    shape_dist_traveled: 2.1453,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 98,
    shape_dist_traveled: 2.1623,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.053438,
    shape_pt_lon: -89.991311,
    shape_pt_sequence: 99,
    shape_dist_traveled: 2.1773,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05355,
    shape_pt_lon: -89.99123,
    shape_pt_sequence: 100,
    shape_dist_traveled: 2.1912,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.054161,
    shape_pt_lon: -89.991249,
    shape_pt_sequence: 101,
    shape_dist_traveled: 2.2603,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.054831,
    shape_pt_lon: -89.991301,
    shape_pt_sequence: 102,
    shape_dist_traveled: 2.3354,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.055837,
    shape_pt_lon: -89.991361,
    shape_pt_sequence: 103,
    shape_dist_traveled: 2.4475,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.05677,
    shape_pt_lon: -89.991416,
    shape_pt_sequence: 104,
    shape_dist_traveled: 2.5527,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.057634,
    shape_pt_lon: -89.991457,
    shape_pt_sequence: 105,
    shape_dist_traveled: 2.6487,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.058032,
    shape_pt_lon: -89.991479,
    shape_pt_sequence: 106,
    shape_dist_traveled: 2.6938,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.058719,
    shape_pt_lon: -89.991487,
    shape_pt_sequence: 107,
    shape_dist_traveled: 2.7708,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.059566,
    shape_pt_lon: -89.991467,
    shape_pt_sequence: 108,
    shape_dist_traveled: 2.8658,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060574,
    shape_pt_lon: -89.99144,
    shape_pt_sequence: 109,
    shape_dist_traveled: 2.9778,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060577,
    shape_pt_lon: -89.991531,
    shape_pt_sequence: 110,
    shape_dist_traveled: 2.9859,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060579,
    shape_pt_lon: -89.99162,
    shape_pt_sequence: 111,
    shape_dist_traveled: 2.9939,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 112,
    shape_dist_traveled: 3.0799,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 113,
    shape_dist_traveled: 3.1029,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 114,
    shape_dist_traveled: 3.127,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 115,
    shape_dist_traveled: 3.2616,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 116,
    shape_dist_traveled: 3.3787,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 117,
    shape_dist_traveled: 3.4007,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 118,
    shape_dist_traveled: 3.4518,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 119,
    shape_dist_traveled: 3.4735,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 120,
    shape_dist_traveled: 3.4919,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 121,
    shape_dist_traveled: 3.5055,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 122,
    shape_dist_traveled: 3.5239,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 123,
    shape_dist_traveled: 3.5435,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 124,
    shape_dist_traveled: 3.56,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 125,
    shape_dist_traveled: 3.583,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 126,
    shape_dist_traveled: 3.607,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 127,
    shape_dist_traveled: 3.6831,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 128,
    shape_dist_traveled: 3.7401,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 129,
    shape_dist_traveled: 3.7782,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 130,
    shape_dist_traveled: 3.7842,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 131,
    shape_dist_traveled: 3.9362,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 132,
    shape_dist_traveled: 4.1053,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 133,
    shape_dist_traveled: 4.2153,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 134,
    shape_dist_traveled: 4.2355,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 135,
    shape_dist_traveled: 4.2558,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 136,
    shape_dist_traveled: 4.271,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 137,
    shape_dist_traveled: 4.2874,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 138,
    shape_dist_traveled: 4.3081,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 139,
    shape_dist_traveled: 4.3295,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 140,
    shape_dist_traveled: 4.3485,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 141,
    shape_dist_traveled: 4.3666,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 142,
    shape_dist_traveled: 4.3817,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 143,
    shape_dist_traveled: 4.4222,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 144,
    shape_dist_traveled: 4.4494,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 145,
    shape_dist_traveled: 4.5181,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 146,
    shape_dist_traveled: 4.5463,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 147,
    shape_dist_traveled: 4.5758,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 148,
    shape_dist_traveled: 4.6887,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 149,
    shape_dist_traveled: 4.6963,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 150,
    shape_dist_traveled: 4.7057,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 151,
    shape_dist_traveled: 4.789,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 152,
    shape_dist_traveled: 5.1139,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 153,
    shape_dist_traveled: 5.1743,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 154,
    shape_dist_traveled: 5.2222,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068538,
    shape_pt_lon: -89.987498,
    shape_pt_sequence: 155,
    shape_dist_traveled: 5.2454,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068651,
    shape_pt_lon: -89.987425,
    shape_pt_sequence: 156,
    shape_dist_traveled: 5.2593,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068708,
    shape_pt_lon: -89.987389,
    shape_pt_sequence: 157,
    shape_dist_traveled: 5.2669,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068769,
    shape_pt_lon: -89.987349,
    shape_pt_sequence: 158,
    shape_dist_traveled: 5.275,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 159,
    shape_dist_traveled: 5.291,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06876,
    shape_pt_lon: -89.987699,
    shape_pt_sequence: 160,
    shape_dist_traveled: 5.307,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.987927,
    shape_pt_sequence: 161,
    shape_dist_traveled: 5.328,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.988046,
    shape_pt_sequence: 162,
    shape_dist_traveled: 5.338,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068766,
    shape_pt_lon: -89.988227,
    shape_pt_sequence: 163,
    shape_dist_traveled: 5.355,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06877,
    shape_pt_lon: -89.988361,
    shape_pt_sequence: 164,
    shape_dist_traveled: 5.3671,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068721,
    shape_pt_lon: -89.988469,
    shape_pt_sequence: 165,
    shape_dist_traveled: 5.3787,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.068742,
    shape_pt_lon: -89.989903,
    shape_pt_sequence: 166,
    shape_dist_traveled: 5.5077,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.989907,
    shape_pt_sequence: 167,
    shape_dist_traveled: 5.5188,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.069637,
    shape_pt_lon: -89.989967,
    shape_pt_sequence: 168,
    shape_dist_traveled: 5.6089,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.06984,
    shape_pt_lon: -89.989989,
    shape_pt_sequence: 169,
    shape_dist_traveled: 5.631,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.070143,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 170,
    shape_dist_traveled: 5.6655,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07074,
    shape_pt_lon: -89.990231,
    shape_pt_sequence: 171,
    shape_dist_traveled: 5.7344,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071437,
    shape_pt_lon: -89.990478,
    shape_pt_sequence: 172,
    shape_dist_traveled: 5.8157,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072104,
    shape_pt_lon: -89.990719,
    shape_pt_sequence: 173,
    shape_dist_traveled: 5.8936,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072269,
    shape_pt_lon: -89.990801,
    shape_pt_sequence: 174,
    shape_dist_traveled: 5.9133,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072539,
    shape_pt_lon: -89.990972,
    shape_pt_sequence: 175,
    shape_dist_traveled: 5.9469,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072719,
    shape_pt_lon: -89.991129,
    shape_pt_sequence: 176,
    shape_dist_traveled: 5.9719,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072912,
    shape_pt_lon: -89.99132,
    shape_pt_sequence: 177,
    shape_dist_traveled: 5.9997,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073021,
    shape_pt_lon: -89.991452,
    shape_pt_sequence: 178,
    shape_dist_traveled: 6.0166,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073171,
    shape_pt_lon: -89.991669,
    shape_pt_sequence: 179,
    shape_dist_traveled: 6.0421,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073299,
    shape_pt_lon: -89.991908,
    shape_pt_sequence: 180,
    shape_dist_traveled: 6.0682,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073332,
    shape_pt_lon: -89.991992,
    shape_pt_sequence: 181,
    shape_dist_traveled: 6.0771,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073434,
    shape_pt_lon: -89.99225,
    shape_pt_sequence: 182,
    shape_dist_traveled: 6.1026,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07351,
    shape_pt_lon: -89.992503,
    shape_pt_sequence: 183,
    shape_dist_traveled: 6.1273,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073582,
    shape_pt_lon: -89.992906,
    shape_pt_sequence: 184,
    shape_dist_traveled: 6.1642,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073601,
    shape_pt_lon: -89.993191,
    shape_pt_sequence: 185,
    shape_dist_traveled: 6.1903,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073597,
    shape_pt_lon: -89.993464,
    shape_pt_sequence: 186,
    shape_dist_traveled: 6.2153,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073577,
    shape_pt_lon: -89.993711,
    shape_pt_sequence: 187,
    shape_dist_traveled: 6.2374,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07353,
    shape_pt_lon: -89.993983,
    shape_pt_sequence: 188,
    shape_dist_traveled: 6.2629,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07342,
    shape_pt_lon: -89.994368,
    shape_pt_sequence: 189,
    shape_dist_traveled: 6.2999,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07332,
    shape_pt_lon: -89.994616,
    shape_pt_sequence: 190,
    shape_dist_traveled: 6.325,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073199,
    shape_pt_lon: -89.994847,
    shape_pt_sequence: 191,
    shape_dist_traveled: 6.3497,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073057,
    shape_pt_lon: -89.995068,
    shape_pt_sequence: 192,
    shape_dist_traveled: 6.3753,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072899,
    shape_pt_lon: -89.995269,
    shape_pt_sequence: 193,
    shape_dist_traveled: 6.4007,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072565,
    shape_pt_lon: -89.995617,
    shape_pt_sequence: 194,
    shape_dist_traveled: 6.449,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072413,
    shape_pt_lon: -89.995796,
    shape_pt_sequence: 195,
    shape_dist_traveled: 6.473,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072402,
    shape_pt_lon: -89.995809,
    shape_pt_sequence: 196,
    shape_dist_traveled: 6.4753,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072258,
    shape_pt_lon: -89.996018,
    shape_pt_sequence: 197,
    shape_dist_traveled: 6.5001,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072132,
    shape_pt_lon: -89.996246,
    shape_pt_sequence: 198,
    shape_dist_traveled: 6.5245,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07202,
    shape_pt_lon: -89.996504,
    shape_pt_sequence: 199,
    shape_dist_traveled: 6.5514,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071939,
    shape_pt_lon: -89.99675,
    shape_pt_sequence: 200,
    shape_dist_traveled: 6.5751,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071885,
    shape_pt_lon: -89.996992,
    shape_pt_sequence: 201,
    shape_dist_traveled: 6.5979,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071848,
    shape_pt_lon: -89.997249,
    shape_pt_sequence: 202,
    shape_dist_traveled: 6.6215,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07183,
    shape_pt_lon: -89.997541,
    shape_pt_sequence: 203,
    shape_dist_traveled: 6.6475,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071836,
    shape_pt_lon: -89.99776,
    shape_pt_sequence: 204,
    shape_dist_traveled: 6.6676,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071866,
    shape_pt_lon: -89.998059,
    shape_pt_sequence: 205,
    shape_dist_traveled: 6.6947,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071983,
    shape_pt_lon: -89.998612,
    shape_pt_sequence: 206,
    shape_dist_traveled: 6.7464,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072029,
    shape_pt_lon: -89.998862,
    shape_pt_sequence: 207,
    shape_dist_traveled: 6.7702,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072057,
    shape_pt_lon: -89.99915,
    shape_pt_sequence: 208,
    shape_dist_traveled: 6.7963,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07206,
    shape_pt_lon: -89.999426,
    shape_pt_sequence: 209,
    shape_dist_traveled: 6.8213,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072042,
    shape_pt_lon: -89.999675,
    shape_pt_sequence: 210,
    shape_dist_traveled: 6.8434,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072002,
    shape_pt_lon: -89.999949,
    shape_pt_sequence: 211,
    shape_dist_traveled: 6.8689,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071954,
    shape_pt_lon: -90.000169,
    shape_pt_sequence: 212,
    shape_dist_traveled: 6.8895,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071843,
    shape_pt_lon: -90.000613,
    shape_pt_sequence: 213,
    shape_dist_traveled: 6.9313,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07162,
    shape_pt_lon: -90.00154,
    shape_pt_sequence: 214,
    shape_dist_traveled: 7.0189,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071568,
    shape_pt_lon: -90.001794,
    shape_pt_sequence: 215,
    shape_dist_traveled: 7.0427,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071537,
    shape_pt_lon: -90.002077,
    shape_pt_sequence: 216,
    shape_dist_traveled: 7.069,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071539,
    shape_pt_lon: -90.002357,
    shape_pt_sequence: 217,
    shape_dist_traveled: 7.094,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071564,
    shape_pt_lon: -90.002761,
    shape_pt_sequence: 218,
    shape_dist_traveled: 7.1301,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071706,
    shape_pt_lon: -90.004411,
    shape_pt_sequence: 219,
    shape_dist_traveled: 7.281,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071736,
    shape_pt_lon: -90.004794,
    shape_pt_sequence: 220,
    shape_dist_traveled: 7.3151,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071736,
    shape_pt_lon: -90.00518,
    shape_pt_sequence: 221,
    shape_dist_traveled: 7.3501,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071711,
    shape_pt_lon: -90.005529,
    shape_pt_sequence: 222,
    shape_dist_traveled: 7.3822,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071663,
    shape_pt_lon: -90.005867,
    shape_pt_sequence: 223,
    shape_dist_traveled: 7.4128,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071589,
    shape_pt_lon: -90.006209,
    shape_pt_sequence: 224,
    shape_dist_traveled: 7.4448,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071511,
    shape_pt_lon: -90.006491,
    shape_pt_sequence: 225,
    shape_dist_traveled: 7.4723,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071322,
    shape_pt_lon: -90.006949,
    shape_pt_sequence: 226,
    shape_dist_traveled: 7.5184,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07119,
    shape_pt_lon: -90.007243,
    shape_pt_sequence: 227,
    shape_dist_traveled: 7.5493,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07111,
    shape_pt_lon: -90.007311,
    shape_pt_sequence: 228,
    shape_dist_traveled: 7.5601,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071077,
    shape_pt_lon: -90.007329,
    shape_pt_sequence: 229,
    shape_dist_traveled: 7.5642,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071014,
    shape_pt_lon: -90.007433,
    shape_pt_sequence: 230,
    shape_dist_traveled: 7.5764,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071307,
    shape_pt_lon: -90.007526,
    shape_pt_sequence: 231,
    shape_dist_traveled: 7.6104,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071642,
    shape_pt_lon: -90.007589,
    shape_pt_sequence: 232,
    shape_dist_traveled: 7.6488,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071951,
    shape_pt_lon: -90.007607,
    shape_pt_sequence: 233,
    shape_dist_traveled: 7.6829,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072171,
    shape_pt_lon: -90.007601,
    shape_pt_sequence: 234,
    shape_dist_traveled: 7.7079,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072241,
    shape_pt_lon: -90.007597,
    shape_pt_sequence: 235,
    shape_dist_traveled: 7.7159,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072387,
    shape_pt_lon: -90.007621,
    shape_pt_sequence: 236,
    shape_dist_traveled: 7.732,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072754,
    shape_pt_lon: -90.007578,
    shape_pt_sequence: 237,
    shape_dist_traveled: 7.7732,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07288,
    shape_pt_lon: -90.007553,
    shape_pt_sequence: 238,
    shape_dist_traveled: 7.7874,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072952,
    shape_pt_lon: -90.007551,
    shape_pt_sequence: 239,
    shape_dist_traveled: 7.7954,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073126,
    shape_pt_lon: -90.007582,
    shape_pt_sequence: 240,
    shape_dist_traveled: 7.8155,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073273,
    shape_pt_lon: -90.007662,
    shape_pt_sequence: 241,
    shape_dist_traveled: 7.8334,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073391,
    shape_pt_lon: -90.007771,
    shape_pt_sequence: 242,
    shape_dist_traveled: 7.8498,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073396,
    shape_pt_lon: -90.007779,
    shape_pt_sequence: 243,
    shape_dist_traveled: 7.8508,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073462,
    shape_pt_lon: -90.007881,
    shape_pt_sequence: 244,
    shape_dist_traveled: 7.863,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073489,
    shape_pt_lon: -90.007933,
    shape_pt_sequence: 245,
    shape_dist_traveled: 7.868,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073549,
    shape_pt_lon: -90.008112,
    shape_pt_sequence: 246,
    shape_dist_traveled: 7.8854,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073567,
    shape_pt_lon: -90.008205,
    shape_pt_sequence: 247,
    shape_dist_traveled: 7.8947,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073579,
    shape_pt_lon: -90.008323,
    shape_pt_sequence: 248,
    shape_dist_traveled: 7.9057,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073574,
    shape_pt_lon: -90.008527,
    shape_pt_sequence: 249,
    shape_dist_traveled: 7.9237,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07349,
    shape_pt_lon: -90.008992,
    shape_pt_sequence: 250,
    shape_dist_traveled: 7.9669,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073396,
    shape_pt_lon: -90.009494,
    shape_pt_sequence: 251,
    shape_dist_traveled: 8.013,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073212,
    shape_pt_lon: -90.010441,
    shape_pt_sequence: 252,
    shape_dist_traveled: 8.1015,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072917,
    shape_pt_lon: -90.011838,
    shape_pt_sequence: 253,
    shape_dist_traveled: 8.2318,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07287,
    shape_pt_lon: -90.012014,
    shape_pt_sequence: 254,
    shape_dist_traveled: 8.2485,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072699,
    shape_pt_lon: -90.012847,
    shape_pt_sequence: 255,
    shape_dist_traveled: 8.3269,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072629,
    shape_pt_lon: -90.013138,
    shape_pt_sequence: 256,
    shape_dist_traveled: 8.3541,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07255,
    shape_pt_lon: -90.013349,
    shape_pt_sequence: 257,
    shape_dist_traveled: 8.3751,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072444,
    shape_pt_lon: -90.013829,
    shape_pt_sequence: 258,
    shape_dist_traveled: 8.4197,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07243,
    shape_pt_lon: -90.013902,
    shape_pt_sequence: 259,
    shape_dist_traveled: 8.4268,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072038,
    shape_pt_lon: -90.016092,
    shape_pt_sequence: 260,
    shape_dist_traveled: 8.6296,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071738,
    shape_pt_lon: -90.017757,
    shape_pt_sequence: 261,
    shape_dist_traveled: 8.7844,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07163,
    shape_pt_lon: -90.018458,
    shape_pt_sequence: 262,
    shape_dist_traveled: 8.8485,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071568,
    shape_pt_lon: -90.019042,
    shape_pt_sequence: 263,
    shape_dist_traveled: 8.902,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071535,
    shape_pt_lon: -90.019531,
    shape_pt_sequence: 264,
    shape_dist_traveled: 8.9462,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071523,
    shape_pt_lon: -90.020251,
    shape_pt_sequence: 265,
    shape_dist_traveled: 9.0112,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07153,
    shape_pt_lon: -90.020421,
    shape_pt_sequence: 266,
    shape_dist_traveled: 9.0262,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071567,
    shape_pt_lon: -90.020991,
    shape_pt_sequence: 267,
    shape_dist_traveled: 9.0784,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071576,
    shape_pt_lon: -90.02111,
    shape_pt_sequence: 268,
    shape_dist_traveled: 9.0895,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07158,
    shape_pt_lon: -90.021148,
    shape_pt_sequence: 269,
    shape_dist_traveled: 9.0925,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071607,
    shape_pt_lon: -90.021409,
    shape_pt_sequence: 270,
    shape_dist_traveled: 9.1167,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071626,
    shape_pt_lon: -90.02158,
    shape_pt_sequence: 271,
    shape_dist_traveled: 9.1318,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071719,
    shape_pt_lon: -90.022146,
    shape_pt_sequence: 272,
    shape_dist_traveled: 9.184,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.071839,
    shape_pt_lon: -90.02273,
    shape_pt_sequence: 273,
    shape_dist_traveled: 9.2385,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07194,
    shape_pt_lon: -90.023036,
    shape_pt_sequence: 274,
    shape_dist_traveled: 9.2686,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.07209,
    shape_pt_lon: -90.023699,
    shape_pt_sequence: 275,
    shape_dist_traveled: 9.331,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072209,
    shape_pt_lon: -90.02406,
    shape_pt_sequence: 276,
    shape_dist_traveled: 9.3665,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072319,
    shape_pt_lon: -90.02431,
    shape_pt_sequence: 277,
    shape_dist_traveled: 9.392,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072444,
    shape_pt_lon: -90.024532,
    shape_pt_sequence: 278,
    shape_dist_traveled: 9.4164,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072665,
    shape_pt_lon: -90.024831,
    shape_pt_sequence: 279,
    shape_dist_traveled: 9.4525,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.072798,
    shape_pt_lon: -90.024961,
    shape_pt_sequence: 280,
    shape_dist_traveled: 9.4718,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073087,
    shape_pt_lon: -90.02518,
    shape_pt_sequence: 281,
    shape_dist_traveled: 9.5095,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073377,
    shape_pt_lon: -90.025336,
    shape_pt_sequence: 282,
    shape_dist_traveled: 9.5453,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073603,
    shape_pt_lon: -90.025417,
    shape_pt_sequence: 283,
    shape_dist_traveled: 9.5713,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.073937,
    shape_pt_lon: -90.025493,
    shape_pt_sequence: 284,
    shape_dist_traveled: 9.6099,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.074306,
    shape_pt_lon: -90.025537,
    shape_pt_sequence: 285,
    shape_dist_traveled: 9.6511,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.074909,
    shape_pt_lon: -90.025599,
    shape_pt_sequence: 286,
    shape_dist_traveled: 9.7184,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.075183,
    shape_pt_lon: -90.025606,
    shape_pt_sequence: 287,
    shape_dist_traveled: 9.7494,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.076789,
    shape_pt_lon: -90.02557,
    shape_pt_sequence: 288,
    shape_dist_traveled: 9.9295,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.077297,
    shape_pt_lon: -90.025562,
    shape_pt_sequence: 289,
    shape_dist_traveled: 9.9865,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.078162,
    shape_pt_lon: -90.025563,
    shape_pt_sequence: 290,
    shape_dist_traveled: 10.0825,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.078717,
    shape_pt_lon: -90.025573,
    shape_pt_sequence: 291,
    shape_dist_traveled: 10.1445,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.078869,
    shape_pt_lon: -90.025566,
    shape_pt_sequence: 292,
    shape_dist_traveled: 10.1615,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.079533,
    shape_pt_lon: -90.025531,
    shape_pt_sequence: 293,
    shape_dist_traveled: 10.2366,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.082461,
    shape_pt_lon: -90.025478,
    shape_pt_sequence: 294,
    shape_dist_traveled: 10.5636,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.083375,
    shape_pt_lon: -90.025488,
    shape_pt_sequence: 295,
    shape_dist_traveled: 10.6666,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.083409,
    shape_pt_lon: -90.025486,
    shape_pt_sequence: 296,
    shape_dist_traveled: 10.6696,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.08349,
    shape_pt_lon: -90.025481,
    shape_pt_sequence: 297,
    shape_dist_traveled: 10.6786,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.084341,
    shape_pt_lon: -90.025442,
    shape_pt_sequence: 298,
    shape_dist_traveled: 10.7747,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.085109,
    shape_pt_lon: -90.025431,
    shape_pt_sequence: 299,
    shape_dist_traveled: 10.8607,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.086704,
    shape_pt_lon: -90.025403,
    shape_pt_sequence: 300,
    shape_dist_traveled: 11.0387,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.089016,
    shape_pt_lon: -90.025363,
    shape_pt_sequence: 301,
    shape_dist_traveled: 11.2977,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.089959,
    shape_pt_lon: -90.025378,
    shape_pt_sequence: 302,
    shape_dist_traveled: 11.4028,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.090532,
    shape_pt_lon: -90.025408,
    shape_pt_sequence: 303,
    shape_dist_traveled: 11.4668,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.091087,
    shape_pt_lon: -90.025456,
    shape_pt_sequence: 304,
    shape_dist_traveled: 11.53,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.091178,
    shape_pt_lon: -90.025465,
    shape_pt_sequence: 305,
    shape_dist_traveled: 11.54,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.092253,
    shape_pt_lon: -90.025623,
    shape_pt_sequence: 306,
    shape_dist_traveled: 11.6608,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.09307,
    shape_pt_lon: -90.025769,
    shape_pt_sequence: 307,
    shape_dist_traveled: 11.7528,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.093595,
    shape_pt_lon: -90.025895,
    shape_pt_sequence: 308,
    shape_dist_traveled: 11.813,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.094126,
    shape_pt_lon: -90.026036,
    shape_pt_sequence: 309,
    shape_dist_traveled: 11.8742,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.094918,
    shape_pt_lon: -90.026278,
    shape_pt_sequence: 310,
    shape_dist_traveled: 11.9649,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.095758,
    shape_pt_lon: -90.026579,
    shape_pt_sequence: 311,
    shape_dist_traveled: 12.063,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.096329,
    shape_pt_lon: -90.026818,
    shape_pt_sequence: 312,
    shape_dist_traveled: 12.1303,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.097076,
    shape_pt_lon: -90.027153,
    shape_pt_sequence: 313,
    shape_dist_traveled: 12.2199,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.09827,
    shape_pt_lon: -90.027749,
    shape_pt_sequence: 314,
    shape_dist_traveled: 12.363,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.100511,
    shape_pt_lon: -90.028888,
    shape_pt_sequence: 315,
    shape_dist_traveled: 12.6347,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.10082,
    shape_pt_lon: -90.029019,
    shape_pt_sequence: 316,
    shape_dist_traveled: 12.6704,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.100875,
    shape_pt_lon: -90.029041,
    shape_pt_sequence: 317,
    shape_dist_traveled: 12.6777,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.101301,
    shape_pt_lon: -90.029161,
    shape_pt_sequence: 318,
    shape_dist_traveled: 12.726,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.101537,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 319,
    shape_dist_traveled: 12.7535,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.101959,
    shape_pt_lon: -90.029262,
    shape_pt_sequence: 320,
    shape_dist_traveled: 12.8006,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.102202,
    shape_pt_lon: -90.029272,
    shape_pt_sequence: 321,
    shape_dist_traveled: 12.8276,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.102571,
    shape_pt_lon: -90.029271,
    shape_pt_sequence: 322,
    shape_dist_traveled: 12.8686,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.105902,
    shape_pt_lon: -90.029139,
    shape_pt_sequence: 323,
    shape_dist_traveled: 13.2418,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.106364,
    shape_pt_lon: -90.029122,
    shape_pt_sequence: 324,
    shape_dist_traveled: 13.2938,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.107012,
    shape_pt_lon: -90.029093,
    shape_pt_sequence: 325,
    shape_dist_traveled: 13.3659,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.107119,
    shape_pt_lon: -90.02909,
    shape_pt_sequence: 326,
    shape_dist_traveled: 13.3779,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.107702,
    shape_pt_lon: -90.029061,
    shape_pt_sequence: 327,
    shape_dist_traveled: 13.4429,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.108011,
    shape_pt_lon: -90.029052,
    shape_pt_sequence: 328,
    shape_dist_traveled: 13.478,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.109511,
    shape_pt_lon: -90.029003,
    shape_pt_sequence: 329,
    shape_dist_traveled: 13.646,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.109679,
    shape_pt_lon: -90.029,
    shape_pt_sequence: 330,
    shape_dist_traveled: 13.665,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.109777,
    shape_pt_lon: -90.028997,
    shape_pt_sequence: 331,
    shape_dist_traveled: 13.676,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.109861,
    shape_pt_lon: -90.028992,
    shape_pt_sequence: 332,
    shape_dist_traveled: 13.6851,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.110022,
    shape_pt_lon: -90.028983,
    shape_pt_sequence: 333,
    shape_dist_traveled: 13.7031,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.110673,
    shape_pt_lon: -90.028943,
    shape_pt_sequence: 334,
    shape_dist_traveled: 13.7762,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.11161,
    shape_pt_lon: -90.0289,
    shape_pt_sequence: 335,
    shape_dist_traveled: 13.8813,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.111741,
    shape_pt_lon: -90.028887,
    shape_pt_sequence: 336,
    shape_dist_traveled: 13.8953,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.112089,
    shape_pt_lon: -90.028852,
    shape_pt_sequence: 337,
    shape_dist_traveled: 13.9344,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.1124,
    shape_pt_lon: -90.028811,
    shape_pt_sequence: 338,
    shape_dist_traveled: 13.9696,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.112876,
    shape_pt_lon: -90.028721,
    shape_pt_sequence: 339,
    shape_dist_traveled: 14.0232,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.113542,
    shape_pt_lon: -90.028546,
    shape_pt_sequence: 340,
    shape_dist_traveled: 14.0999,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.114053,
    shape_pt_lon: -90.028369,
    shape_pt_sequence: 341,
    shape_dist_traveled: 14.1591,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.114719,
    shape_pt_lon: -90.028092,
    shape_pt_sequence: 342,
    shape_dist_traveled: 14.2382,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 343,
    shape_dist_traveled: 14.3238,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.11563,
    shape_pt_lon: -90.027631,
    shape_pt_sequence: 344,
    shape_dist_traveled: 14.3482,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.116068,
    shape_pt_lon: -90.027368,
    shape_pt_sequence: 345,
    shape_dist_traveled: 14.4027,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.11794,
    shape_pt_lon: -90.026334,
    shape_pt_sequence: 346,
    shape_dist_traveled: 14.6315,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.118488,
    shape_pt_lon: -90.026051,
    shape_pt_sequence: 347,
    shape_dist_traveled: 14.6987,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.11884,
    shape_pt_lon: -90.025907,
    shape_pt_sequence: 348,
    shape_dist_traveled: 14.7398,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.119119,
    shape_pt_lon: -90.025819,
    shape_pt_sequence: 349,
    shape_dist_traveled: 14.7718,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.119259,
    shape_pt_lon: -90.025781,
    shape_pt_sequence: 350,
    shape_dist_traveled: 14.7881,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.119503,
    shape_pt_lon: -90.025737,
    shape_pt_sequence: 351,
    shape_dist_traveled: 14.8154,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.119909,
    shape_pt_lon: -90.025688,
    shape_pt_sequence: 352,
    shape_dist_traveled: 14.8617,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.121643,
    shape_pt_lon: -90.025593,
    shape_pt_sequence: 353,
    shape_dist_traveled: 15.0558,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.122343,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 354,
    shape_dist_traveled: 15.1339,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -90.025517,
    shape_pt_sequence: 355,
    shape_dist_traveled: 15.1831,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.123137,
    shape_pt_lon: -90.025458,
    shape_pt_sequence: 356,
    shape_dist_traveled: 15.2234,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.123545,
    shape_pt_lon: -90.02535,
    shape_pt_sequence: 357,
    shape_dist_traveled: 15.2695,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.123869,
    shape_pt_lon: -90.025227,
    shape_pt_sequence: 358,
    shape_dist_traveled: 15.3081,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.123983,
    shape_pt_lon: -90.025175,
    shape_pt_sequence: 359,
    shape_dist_traveled: 15.3211,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.124779,
    shape_pt_lon: -90.024772,
    shape_pt_sequence: 360,
    shape_dist_traveled: 15.4171,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.124826,
    shape_pt_lon: -90.024748,
    shape_pt_sequence: 361,
    shape_dist_traveled: 15.4234,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.12495,
    shape_pt_lon: -90.024686,
    shape_pt_sequence: 362,
    shape_dist_traveled: 15.4386,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.125887,
    shape_pt_lon: -90.024208,
    shape_pt_sequence: 363,
    shape_dist_traveled: 15.5521,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.126213,
    shape_pt_lon: -90.024053,
    shape_pt_sequence: 364,
    shape_dist_traveled: 15.5907,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.126501,
    shape_pt_lon: -90.023936,
    shape_pt_sequence: 365,
    shape_dist_traveled: 15.6246,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.126722,
    shape_pt_lon: -90.023858,
    shape_pt_sequence: 366,
    shape_dist_traveled: 15.6505,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.126882,
    shape_pt_lon: -90.023811,
    shape_pt_sequence: 367,
    shape_dist_traveled: 15.669,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.126986,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 368,
    shape_dist_traveled: 15.6801,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.127379,
    shape_pt_lon: -90.02372,
    shape_pt_sequence: 369,
    shape_dist_traveled: 15.7246,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.12775,
    shape_pt_lon: -90.023696,
    shape_pt_sequence: 370,
    shape_dist_traveled: 15.7666,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.128237,
    shape_pt_lon: -90.023704,
    shape_pt_sequence: 371,
    shape_dist_traveled: 15.8206,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.128922,
    shape_pt_lon: -90.023741,
    shape_pt_sequence: 372,
    shape_dist_traveled: 15.8977,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.130168,
    shape_pt_lon: -90.02381,
    shape_pt_sequence: 373,
    shape_dist_traveled: 16.0378,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 374,
    shape_dist_traveled: 16.0488,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.130817,
    shape_pt_lon: -90.023853,
    shape_pt_sequence: 375,
    shape_dist_traveled: 16.11,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.13155,
    shape_pt_lon: -90.023889,
    shape_pt_sequence: 376,
    shape_dist_traveled: 16.192,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.132426,
    shape_pt_lon: -90.023948,
    shape_pt_sequence: 377,
    shape_dist_traveled: 16.2902,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.132822,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 378,
    shape_dist_traveled: 16.3353,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.133088,
    shape_pt_lon: -90.023978,
    shape_pt_sequence: 379,
    shape_dist_traveled: 16.3643,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.133638,
    shape_pt_lon: -90.024009,
    shape_pt_sequence: 380,
    shape_dist_traveled: 16.4263,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.134721,
    shape_pt_lon: -90.024062,
    shape_pt_sequence: 381,
    shape_dist_traveled: 16.5474,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.135522,
    shape_pt_lon: -90.024111,
    shape_pt_sequence: 382,
    shape_dist_traveled: 16.6376,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.136078,
    shape_pt_lon: -90.024118,
    shape_pt_sequence: 383,
    shape_dist_traveled: 16.6996,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.136243,
    shape_pt_lon: -90.024114,
    shape_pt_sequence: 384,
    shape_dist_traveled: 16.7176,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.136485,
    shape_pt_lon: -90.024128,
    shape_pt_sequence: 385,
    shape_dist_traveled: 16.7446,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.137188,
    shape_pt_lon: -90.024132,
    shape_pt_sequence: 386,
    shape_dist_traveled: 16.8236,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.13758,
    shape_pt_lon: -90.024099,
    shape_pt_sequence: 387,
    shape_dist_traveled: 16.8677,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.138131,
    shape_pt_lon: -90.024022,
    shape_pt_sequence: 388,
    shape_dist_traveled: 16.9301,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.138366,
    shape_pt_lon: -90.02397,
    shape_pt_sequence: 389,
    shape_dist_traveled: 16.9566,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.138586,
    shape_pt_lon: -90.023902,
    shape_pt_sequence: 390,
    shape_dist_traveled: 16.9813,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.138727,
    shape_pt_lon: -90.023851,
    shape_pt_sequence: 391,
    shape_dist_traveled: 16.9981,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.139069,
    shape_pt_lon: -90.023723,
    shape_pt_sequence: 392,
    shape_dist_traveled: 17.0386,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.139585,
    shape_pt_lon: -90.023492,
    shape_pt_sequence: 393,
    shape_dist_traveled: 17.0993,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.139782,
    shape_pt_lon: -90.02339,
    shape_pt_sequence: 394,
    shape_dist_traveled: 17.1231,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.140268,
    shape_pt_lon: -90.02318,
    shape_pt_sequence: 395,
    shape_dist_traveled: 17.1813,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.140758,
    shape_pt_lon: -90.023003,
    shape_pt_sequence: 396,
    shape_dist_traveled: 17.2386,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.140999,
    shape_pt_lon: -90.022931,
    shape_pt_sequence: 397,
    shape_dist_traveled: 17.2665,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.141232,
    shape_pt_lon: -90.022881,
    shape_pt_sequence: 398,
    shape_dist_traveled: 17.2928,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.142032,
    shape_pt_lon: -90.022728,
    shape_pt_sequence: 399,
    shape_dist_traveled: 17.3819,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.142219,
    shape_pt_lon: -90.022698,
    shape_pt_sequence: 400,
    shape_dist_traveled: 17.4031,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.142399,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 401,
    shape_dist_traveled: 17.4233,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.143278,
    shape_pt_lon: -90.022541,
    shape_pt_sequence: 402,
    shape_dist_traveled: 17.5229,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.143372,
    shape_pt_lon: -90.022528,
    shape_pt_sequence: 403,
    shape_dist_traveled: 17.533,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.143792,
    shape_pt_lon: -90.02248,
    shape_pt_sequence: 404,
    shape_dist_traveled: 17.5801,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.144391,
    shape_pt_lon: -90.022412,
    shape_pt_sequence: 405,
    shape_dist_traveled: 17.6474,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.144479,
    shape_pt_lon: -90.022402,
    shape_pt_sequence: 406,
    shape_dist_traveled: 17.6575,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.144566,
    shape_pt_lon: -90.022392,
    shape_pt_sequence: 407,
    shape_dist_traveled: 17.6675,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.14488,
    shape_pt_lon: -90.022359,
    shape_pt_sequence: 408,
    shape_dist_traveled: 17.7026,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.146014,
    shape_pt_lon: -90.022076,
    shape_pt_sequence: 409,
    shape_dist_traveled: 17.8323,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.1465,
    shape_pt_lon: -90.021935,
    shape_pt_sequence: 410,
    shape_dist_traveled: 17.8876,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.146969,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 411,
    shape_dist_traveled: 17.9417,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.14713,
    shape_pt_lon: -90.021788,
    shape_pt_sequence: 412,
    shape_dist_traveled: 17.96,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.14739,
    shape_pt_lon: -90.021777,
    shape_pt_sequence: 413,
    shape_dist_traveled: 17.989,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.147775,
    shape_pt_lon: -90.021782,
    shape_pt_sequence: 414,
    shape_dist_traveled: 18.032,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.14813,
    shape_pt_lon: -90.021836,
    shape_pt_sequence: 415,
    shape_dist_traveled: 18.0723,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148207,
    shape_pt_lon: -90.021862,
    shape_pt_sequence: 416,
    shape_dist_traveled: 18.0806,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148479,
    shape_pt_lon: -90.021963,
    shape_pt_sequence: 417,
    shape_dist_traveled: 18.1128,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148581,
    shape_pt_lon: -90.022021,
    shape_pt_sequence: 418,
    shape_dist_traveled: 18.1249,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148829,
    shape_pt_lon: -90.022147,
    shape_pt_sequence: 419,
    shape_dist_traveled: 18.1554,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148881,
    shape_pt_lon: -90.022179,
    shape_pt_sequence: 420,
    shape_dist_traveled: 18.1621,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.148931,
    shape_pt_lon: -90.022211,
    shape_pt_sequence: 421,
    shape_dist_traveled: 18.1679,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149037,
    shape_pt_lon: -90.02228,
    shape_pt_sequence: 422,
    shape_dist_traveled: 18.1813,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149163,
    shape_pt_lon: -90.022389,
    shape_pt_sequence: 423,
    shape_dist_traveled: 18.1985,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.1493,
    shape_pt_lon: -90.022525,
    shape_pt_sequence: 424,
    shape_dist_traveled: 18.2177,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149366,
    shape_pt_lon: -90.022602,
    shape_pt_sequence: 425,
    shape_dist_traveled: 18.2284,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.14949,
    shape_pt_lon: -90.022778,
    shape_pt_sequence: 426,
    shape_dist_traveled: 18.2496,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149825,
    shape_pt_lon: -90.023314,
    shape_pt_sequence: 427,
    shape_dist_traveled: 18.3102,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149912,
    shape_pt_lon: -90.023484,
    shape_pt_sequence: 428,
    shape_dist_traveled: 18.3291,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149963,
    shape_pt_lon: -90.023582,
    shape_pt_sequence: 429,
    shape_dist_traveled: 18.3399,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.149999,
    shape_pt_lon: -90.023686,
    shape_pt_sequence: 430,
    shape_dist_traveled: 18.3498,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150036,
    shape_pt_lon: -90.023793,
    shape_pt_sequence: 431,
    shape_dist_traveled: 18.3605,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150081,
    shape_pt_lon: -90.023891,
    shape_pt_sequence: 432,
    shape_dist_traveled: 18.37,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150175,
    shape_pt_lon: -90.024398,
    shape_pt_sequence: 433,
    shape_dist_traveled: 18.4171,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15022,
    shape_pt_lon: -90.024888,
    shape_pt_sequence: 434,
    shape_dist_traveled: 18.4623,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150243,
    shape_pt_lon: -90.025741,
    shape_pt_sequence: 435,
    shape_dist_traveled: 18.5394,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150244,
    shape_pt_lon: -90.026225,
    shape_pt_sequence: 436,
    shape_dist_traveled: 18.5824,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150256,
    shape_pt_lon: -90.027377,
    shape_pt_sequence: 437,
    shape_dist_traveled: 18.6864,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150256,
    shape_pt_lon: -90.027412,
    shape_pt_sequence: 438,
    shape_dist_traveled: 18.6904,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150247,
    shape_pt_lon: -90.029446,
    shape_pt_sequence: 439,
    shape_dist_traveled: 18.8734,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150238,
    shape_pt_lon: -90.030458,
    shape_pt_sequence: 440,
    shape_dist_traveled: 18.9654,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150322,
    shape_pt_lon: -90.031227,
    shape_pt_sequence: 441,
    shape_dist_traveled: 19.035,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15038,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 442,
    shape_dist_traveled: 19.0726,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150415,
    shape_pt_lon: -90.031885,
    shape_pt_sequence: 443,
    shape_dist_traveled: 19.096,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150553,
    shape_pt_lon: -90.032885,
    shape_pt_sequence: 444,
    shape_dist_traveled: 19.1872,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150728,
    shape_pt_lon: -90.034156,
    shape_pt_sequence: 445,
    shape_dist_traveled: 19.304,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150767,
    shape_pt_lon: -90.034403,
    shape_pt_sequence: 446,
    shape_dist_traveled: 19.3263,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150783,
    shape_pt_lon: -90.034593,
    shape_pt_sequence: 447,
    shape_dist_traveled: 19.3434,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.150902,
    shape_pt_lon: -90.035464,
    shape_pt_sequence: 448,
    shape_dist_traveled: 19.4235,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151161,
    shape_pt_lon: -90.037379,
    shape_pt_sequence: 449,
    shape_dist_traveled: 19.5989,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151394,
    shape_pt_lon: -90.039082,
    shape_pt_sequence: 450,
    shape_dist_traveled: 19.7551,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15148,
    shape_pt_lon: -90.039771,
    shape_pt_sequence: 451,
    shape_dist_traveled: 19.8179,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151522,
    shape_pt_lon: -90.040204,
    shape_pt_sequence: 452,
    shape_dist_traveled: 19.8571,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151568,
    shape_pt_lon: -90.040545,
    shape_pt_sequence: 453,
    shape_dist_traveled: 19.8887,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151576,
    shape_pt_lon: -90.040598,
    shape_pt_sequence: 454,
    shape_dist_traveled: 19.8937,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151587,
    shape_pt_lon: -90.040673,
    shape_pt_sequence: 455,
    shape_dist_traveled: 19.901,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151594,
    shape_pt_lon: -90.040731,
    shape_pt_sequence: 456,
    shape_dist_traveled: 19.906,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151625,
    shape_pt_lon: -90.040935,
    shape_pt_sequence: 457,
    shape_dist_traveled: 19.9244,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151751,
    shape_pt_lon: -90.04205,
    shape_pt_sequence: 458,
    shape_dist_traveled: 20.0264,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151784,
    shape_pt_lon: -90.042425,
    shape_pt_sequence: 459,
    shape_dist_traveled: 20.0606,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151836,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 460,
    shape_dist_traveled: 20.1318,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151859,
    shape_pt_lon: -90.043478,
    shape_pt_sequence: 461,
    shape_dist_traveled: 20.156,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151909,
    shape_pt_lon: -90.043906,
    shape_pt_sequence: 462,
    shape_dist_traveled: 20.1954,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151963,
    shape_pt_lon: -90.044238,
    shape_pt_sequence: 463,
    shape_dist_traveled: 20.226,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152047,
    shape_pt_lon: -90.044626,
    shape_pt_sequence: 464,
    shape_dist_traveled: 20.2621,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152076,
    shape_pt_lon: -90.044736,
    shape_pt_sequence: 465,
    shape_dist_traveled: 20.2726,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152094,
    shape_pt_lon: -90.044802,
    shape_pt_sequence: 466,
    shape_dist_traveled: 20.2789,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152176,
    shape_pt_lon: -90.044992,
    shape_pt_sequence: 467,
    shape_dist_traveled: 20.2981,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152489,
    shape_pt_lon: -90.045667,
    shape_pt_sequence: 468,
    shape_dist_traveled: 20.3685,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152724,
    shape_pt_lon: -90.04606,
    shape_pt_sequence: 469,
    shape_dist_traveled: 20.4127,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153054,
    shape_pt_lon: -90.046555,
    shape_pt_sequence: 470,
    shape_dist_traveled: 20.4709,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 471,
    shape_dist_traveled: 20.5009,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153298,
    shape_pt_lon: -90.04696,
    shape_pt_sequence: 472,
    shape_dist_traveled: 20.5167,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15337,
    shape_pt_lon: -90.047131,
    shape_pt_sequence: 473,
    shape_dist_traveled: 20.5337,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153408,
    shape_pt_lon: -90.047272,
    shape_pt_sequence: 474,
    shape_dist_traveled: 20.5474,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153432,
    shape_pt_lon: -90.04745,
    shape_pt_sequence: 475,
    shape_dist_traveled: 20.5636,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153433,
    shape_pt_lon: -90.047673,
    shape_pt_sequence: 476,
    shape_dist_traveled: 20.5836,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153417,
    shape_pt_lon: -90.04777,
    shape_pt_sequence: 477,
    shape_dist_traveled: 20.5928,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153359,
    shape_pt_lon: -90.04793,
    shape_pt_sequence: 478,
    shape_dist_traveled: 20.6081,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153271,
    shape_pt_lon: -90.048071,
    shape_pt_sequence: 479,
    shape_dist_traveled: 20.6245,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153174,
    shape_pt_lon: -90.04816,
    shape_pt_sequence: 480,
    shape_dist_traveled: 20.6381,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153113,
    shape_pt_lon: -90.048196,
    shape_pt_sequence: 481,
    shape_dist_traveled: 20.6457,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15306,
    shape_pt_lon: -90.048226,
    shape_pt_sequence: 482,
    shape_dist_traveled: 20.6524,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152988,
    shape_pt_lon: -90.048264,
    shape_pt_sequence: 483,
    shape_dist_traveled: 20.661,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15292,
    shape_pt_lon: -90.048292,
    shape_pt_sequence: 484,
    shape_dist_traveled: 20.6686,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 485,
    shape_dist_traveled: 20.682,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 486,
    shape_dist_traveled: 20.7977,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151659,
    shape_pt_lon: -90.048161,
    shape_pt_sequence: 487,
    shape_dist_traveled: 20.855,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 488,
    shape_dist_traveled: 20.9154,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 489,
    shape_dist_traveled: 21.0286,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 490,
    shape_dist_traveled: 21.0384,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 491,
    shape_dist_traveled: 21.061,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 492,
    shape_dist_traveled: 21.0727,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 493,
    shape_dist_traveled: 21.1198,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 494,
    shape_dist_traveled: 21.1468,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 495,
    shape_dist_traveled: 21.1623,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 496,
    shape_dist_traveled: 21.2666,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 497,
    shape_dist_traveled: 21.3783,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 498,
    shape_dist_traveled: 21.3855,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 499,
    shape_dist_traveled: 21.3909,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 500,
    shape_dist_traveled: 21.3909,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 501,
    shape_dist_traveled: 21.4475,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 502,
    shape_dist_traveled: 21.4475,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 503,
    shape_dist_traveled: 21.5,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 504,
    shape_dist_traveled: 21.5595,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 505,
    shape_dist_traveled: 21.6183,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 506,
    shape_dist_traveled: 21.6354,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 507,
    shape_dist_traveled: 21.6981,
  },
  {
    shape_id: 38208,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 508,
    shape_dist_traveled: 21.749,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064758,
    shape_pt_lon: -90.058849,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.016,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064738,
    shape_pt_lon: -90.057628,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.127,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064718,
    shape_pt_lon: -90.05662,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.2181,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.063045,
    shape_pt_lon: -90.056698,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.4052,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062966,
    shape_pt_lon: -90.053281,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.7143,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062931,
    shape_pt_lon: -90.051918,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.8374,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06293,
    shape_pt_lon: -90.051829,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.8454,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062929,
    shape_pt_lon: -90.051739,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.8534,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062904,
    shape_pt_lon: -90.050603,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.9564,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06289,
    shape_pt_lon: -90.049862,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.0234,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06288,
    shape_pt_lon: -90.049552,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.0515,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062858,
    shape_pt_lon: -90.049269,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.0776,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -90.049039,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.0988,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062775,
    shape_pt_lon: -90.048632,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.1363,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062623,
    shape_pt_lon: -90.047658,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.226,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062426,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.346,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062113,
    shape_pt_lon: -90.044343,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.5303,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062055,
    shape_pt_lon: -90.04382,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.5788,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062016,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.634,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061999,
    shape_pt_lon: -90.042573,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.692,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061956,
    shape_pt_lon: -90.040818,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.8501,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -90.038494,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.0602,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.2883,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061804,
    shape_pt_lon: -90.034866,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.3893,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061758,
    shape_pt_lon: -90.033019,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.5564,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061739,
    shape_pt_lon: -90.032218,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.6285,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061698,
    shape_pt_lon: -90.030753,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.7615,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -90.029399,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.8836,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061643,
    shape_pt_lon: -90.028518,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.9636,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061632,
    shape_pt_lon: -90.028151,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.9966,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061618,
    shape_pt_lon: -90.027535,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.0527,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061579,
    shape_pt_lon: -90.025923,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.1977,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061594,
    shape_pt_lon: -90.025482,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.2378,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061679,
    shape_pt_lon: -90.024789,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.3014,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061691,
    shape_pt_lon: -90.024712,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.3085,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061717,
    shape_pt_lon: -90.024418,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.3357,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061726,
    shape_pt_lon: -90.024207,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.3547,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061721,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.3757,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061705,
    shape_pt_lon: -90.023671,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.4028,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061699,
    shape_pt_lon: -90.0236,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.4098,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.4138,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061689,
    shape_pt_lon: -90.023451,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.4229,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06163,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.5021,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.6226,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061527,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.6297,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06148,
    shape_pt_lon: -90.020652,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.677,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061467,
    shape_pt_lon: -90.020286,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.71,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061451,
    shape_pt_lon: -90.019991,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.737,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061419,
    shape_pt_lon: -90.01949,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.7822,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061403,
    shape_pt_lon: -90.019237,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.8053,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061356,
    shape_pt_lon: -90.018053,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.9124,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061339,
    shape_pt_lon: -90.01756,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.9575,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061293,
    shape_pt_lon: -90.01599,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.0996,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061272,
    shape_pt_lon: -90.015226,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.1686,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061231,
    shape_pt_lon: -90.013952,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.2837,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06122,
    shape_pt_lon: -90.013628,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.3137,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0612,
    shape_pt_lon: -90.012873,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.3817,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061192,
    shape_pt_lon: -90.012536,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.4118,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061179,
    shape_pt_lon: -90.011738,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.4848,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061151,
    shape_pt_lon: -90.01104,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.5478,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061123,
    shape_pt_lon: -90.010033,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.6389,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061102,
    shape_pt_lon: -90.009289,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.706,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061051,
    shape_pt_lon: -90.007693,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.85,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061039,
    shape_pt_lon: -90.007298,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.8861,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061037,
    shape_pt_lon: -90.007223,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.8931,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061035,
    shape_pt_lon: -90.007162,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.8981,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -90.004151,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.1713,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06093,
    shape_pt_lon: -90.003676,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.2143,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06089,
    shape_pt_lon: -90.002047,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.3614,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060878,
    shape_pt_lon: -90.001628,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.3994,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060842,
    shape_pt_lon: -90.000408,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.5095,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06081,
    shape_pt_lon: -89.999536,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.5886,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060777,
    shape_pt_lon: -89.998354,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.6956,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.99807,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.7216,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060741,
    shape_pt_lon: -89.99722,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.7987,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 77,
    shape_dist_traveled: 6.0008,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 78,
    shape_dist_traveled: 6.0389,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.0959,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.172,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.3066,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.4237,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.4457,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.4968,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.5185,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.5369,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.5505,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.5689,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.5885,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.605,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.628,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.652,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.7281,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.7851,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.8232,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.8292,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.9812,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1503,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.2603,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.2805,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.3008,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.316,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.3324,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.3531,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.3745,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.3935,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.4116,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.4266,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.4672,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.4944,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.5631,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.5913,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.6208,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.7337,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.7413,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.7507,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.834,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.1589,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.2193,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.2671,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.2856,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.3036,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.3343,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3863,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.3923,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4068,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4178,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.4238,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.4378,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.4818,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068759,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5689,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068763,
    shape_pt_lon: -89.984272,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5809,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.983438,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6559,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068779,
    shape_pt_lon: -89.983286,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6699,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068811,
    shape_pt_lon: -89.982129,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.775,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068827,
    shape_pt_lon: -89.981701,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.813,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068833,
    shape_pt_lon: -89.981153,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.863,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980607,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.9121,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980318,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.9391,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068961,
    shape_pt_lon: -89.975041,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.4162,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.973159,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.5863,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069079,
    shape_pt_lon: -89.969853,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.8855,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069099,
    shape_pt_lon: -89.968477,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.0095,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069093,
    shape_pt_lon: -89.967908,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.0615,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06909,
    shape_pt_lon: -89.966763,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.1645,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069088,
    shape_pt_lon: -89.966092,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.2255,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069087,
    shape_pt_lon: -89.965871,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.2455,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.965049,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.3195,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069038,
    shape_pt_lon: -89.964462,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.3736,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069012,
    shape_pt_lon: -89.964132,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.4028,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069011,
    shape_pt_lon: -89.964075,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.4088,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068992,
    shape_pt_lon: -89.963804,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.4329,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06899,
    shape_pt_lon: -89.963668,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.4449,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068981,
    shape_pt_lon: -89.963304,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.4779,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068955,
    shape_pt_lon: -89.962088,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.5879,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068951,
    shape_pt_lon: -89.961978,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.598,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068929,
    shape_pt_lon: -89.961096,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.678,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068899,
    shape_pt_lon: -89.95991,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.7851,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068898,
    shape_pt_lon: -89.959817,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.7941,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.958461,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.9161,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068782,
    shape_pt_lon: -89.955203,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.2113,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068773,
    shape_pt_lon: -89.95478,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.2493,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068753,
    shape_pt_lon: -89.9543,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.2933,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068743,
    shape_pt_lon: -89.954207,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.3014,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.068859,
    shape_pt_lon: -89.954229,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.3145,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06918,
    shape_pt_lon: -89.954286,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.3509,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069328,
    shape_pt_lon: -89.954309,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.367,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072248,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.6967,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072429,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.7168,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072486,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.7239,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072802,
    shape_pt_lon: -89.954828,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.759,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072893,
    shape_pt_lon: -89.954822,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.769,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.073257,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.8103,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.073675,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.8589,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.8817,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.074048,
    shape_pt_lon: -89.954422,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.9041,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.074386,
    shape_pt_lon: -89.954148,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.9496,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.074464,
    shape_pt_lon: -89.954062,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.961,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.074843,
    shape_pt_lon: -89.95362,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.019,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075379,
    shape_pt_lon: -89.952992,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.1017,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075925,
    shape_pt_lon: -89.952326,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.188,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.951963,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.2313,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076279,
    shape_pt_lon: -89.951763,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.2524,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076427,
    shape_pt_lon: -89.951466,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.2843,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.317,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.3416,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.3669,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.3856,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.4147,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.4757,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.4938,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.5349,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.0399,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.1531,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.2002,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.8951,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.9451,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.9541,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.96,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.9685,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.9815,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.0426,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 203,
    shape_dist_traveled: 14.0484,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.0614,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.0754,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.0825,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.092,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.143,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.1462,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.1525,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.1585,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.1865,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.1995,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.2081,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.2139,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.2229,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.298,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.3561,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.3712,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.3783,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.5829,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.7233,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.7935,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.7935,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.9018,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.98,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.0691,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.0941,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.1922,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.2853,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.3283,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.3403,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.3564,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.3615,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067558,
    shape_pt_lon: -89.932289,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.3723,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067459,
    shape_pt_lon: -89.932293,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.3833,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.067092,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.4243,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.066671,
    shape_pt_lon: -89.932328,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.4713,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.065107,
    shape_pt_lon: -89.932411,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.6465,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064879,
    shape_pt_lon: -89.932423,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.6725,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064291,
    shape_pt_lon: -89.932453,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.7386,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.064029,
    shape_pt_lon: -89.932448,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.7676,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.063716,
    shape_pt_lon: -89.932403,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.8028,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.063507,
    shape_pt_lon: -89.932347,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.8263,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.063191,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.864,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062717,
    shape_pt_lon: -89.931944,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.9226,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.062459,
    shape_pt_lon: -89.931786,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.9552,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061961,
    shape_pt_lon: -89.931489,
    shape_pt_sequence: 248,
    shape_dist_traveled: 16.0165,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.061652,
    shape_pt_lon: -89.931341,
    shape_pt_sequence: 249,
    shape_dist_traveled: 16.0538,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.06123,
    shape_pt_lon: -89.9312,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.1026,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060961,
    shape_pt_lon: -89.931143,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.133,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060683,
    shape_pt_lon: -89.931106,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.1643,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.060302,
    shape_pt_lon: -89.931094,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.2073,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.059947,
    shape_pt_lon: -89.931126,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.2474,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.059509,
    shape_pt_lon: -89.931222,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.2972,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.059194,
    shape_pt_lon: -89.931322,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.3334,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.059069,
    shape_pt_lon: -89.931372,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.3479,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05867,
    shape_pt_lon: -89.931552,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.3947,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057947,
    shape_pt_lon: -89.931951,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.4834,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057775,
    shape_pt_lon: -89.932061,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.5057,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057193,
    shape_pt_lon: -89.9324,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.5778,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057159,
    shape_pt_lon: -89.932421,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.5822,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057099,
    shape_pt_lon: -89.932456,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.5889,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.057,
    shape_pt_lon: -89.932508,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.601,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.056851,
    shape_pt_lon: -89.932588,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.6194,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.056418,
    shape_pt_lon: -89.932841,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.6735,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.055928,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.733,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.055669,
    shape_pt_lon: -89.933286,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.7657,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.055378,
    shape_pt_lon: -89.933448,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.8015,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.055259,
    shape_pt_lon: -89.933511,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.8159,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.055036,
    shape_pt_lon: -89.933617,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.8428,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.054887,
    shape_pt_lon: -89.933682,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.8608,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.054358,
    shape_pt_lon: -89.933866,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.9219,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.054075,
    shape_pt_lon: -89.933937,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.9545,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05366,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 275,
    shape_dist_traveled: 17.001,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.053064,
    shape_pt_lon: -89.934154,
    shape_pt_sequence: 276,
    shape_dist_traveled: 17.0693,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05298,
    shape_pt_lon: -89.934179,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.0785,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052679,
    shape_pt_lon: -89.934248,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.113,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052349,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.1501,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.934276,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.2031,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051899,
    shape_pt_lon: -89.935629,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.3252,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051917,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.4462,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051877,
    shape_pt_lon: -89.937071,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.4569,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.937152,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.4639,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051961,
    shape_pt_lon: -89.940352,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.7531,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052043,
    shape_pt_lon: -89.943377,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.0272,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0521,
    shape_pt_lon: -89.944961,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.1704,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052153,
    shape_pt_lon: -89.946399,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.3005,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.948251,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.4685,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052203,
    shape_pt_lon: -89.94913,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.5475,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.949773,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.6065,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.7776,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.9756,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 294,
    shape_dist_traveled: 19.0617,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 295,
    shape_dist_traveled: 19.1187,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 296,
    shape_dist_traveled: 19.3298,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 297,
    shape_dist_traveled: 19.4869,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.4949,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052518,
    shape_pt_lon: -89.959711,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.508,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052621,
    shape_pt_lon: -89.962222,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.7353,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05264,
    shape_pt_lon: -89.963049,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.8103,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052662,
    shape_pt_lon: -89.963789,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.8773,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052668,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.8943,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052613,
    shape_pt_lon: -89.963983,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.9003,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052536,
    shape_pt_lon: -89.963986,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.9093,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051689,
    shape_pt_lon: -89.963983,
    shape_pt_sequence: 306,
    shape_dist_traveled: 20.0033,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051099,
    shape_pt_lon: -89.963989,
    shape_pt_sequence: 307,
    shape_dist_traveled: 20.0693,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.049991,
    shape_pt_lon: -89.963982,
    shape_pt_sequence: 308,
    shape_dist_traveled: 20.1933,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.049944,
    shape_pt_lon: -89.963982,
    shape_pt_sequence: 309,
    shape_dist_traveled: 20.1993,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.048089,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 310,
    shape_dist_traveled: 20.4063,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.044888,
    shape_pt_lon: -89.96397,
    shape_pt_sequence: 311,
    shape_dist_traveled: 20.7643,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.044304,
    shape_pt_lon: -89.963969,
    shape_pt_sequence: 312,
    shape_dist_traveled: 20.8303,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.043859,
    shape_pt_lon: -89.963967,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.8793,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.042169,
    shape_pt_lon: -89.963961,
    shape_pt_sequence: 314,
    shape_dist_traveled: 21.0694,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.041689,
    shape_pt_lon: -89.96396,
    shape_pt_sequence: 315,
    shape_dist_traveled: 21.1224,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.040567,
    shape_pt_lon: -89.963957,
    shape_pt_sequence: 316,
    shape_dist_traveled: 21.2484,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.04031,
    shape_pt_lon: -89.963956,
    shape_pt_sequence: 317,
    shape_dist_traveled: 21.2774,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.038955,
    shape_pt_lon: -89.963956,
    shape_pt_sequence: 318,
    shape_dist_traveled: 21.4284,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.037686,
    shape_pt_lon: -89.963948,
    shape_pt_sequence: 319,
    shape_dist_traveled: 21.5704,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.037218,
    shape_pt_lon: -89.963922,
    shape_pt_sequence: 320,
    shape_dist_traveled: 21.6224,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.036906,
    shape_pt_lon: -89.963904,
    shape_pt_sequence: 321,
    shape_dist_traveled: 21.6575,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.036019,
    shape_pt_lon: -89.963843,
    shape_pt_sequence: 322,
    shape_dist_traveled: 21.7576,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.034443,
    shape_pt_lon: -89.963736,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.9339,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.031978,
    shape_pt_lon: -89.963728,
    shape_pt_sequence: 324,
    shape_dist_traveled: 22.2099,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.030466,
    shape_pt_lon: -89.963717,
    shape_pt_sequence: 325,
    shape_dist_traveled: 22.3789,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.029357,
    shape_pt_lon: -89.96371,
    shape_pt_sequence: 326,
    shape_dist_traveled: 22.5029,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0282,
    shape_pt_lon: -89.963698,
    shape_pt_sequence: 327,
    shape_dist_traveled: 22.6319,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.028003,
    shape_pt_lon: -89.963693,
    shape_pt_sequence: 328,
    shape_dist_traveled: 22.6539,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.027169,
    shape_pt_lon: -89.963669,
    shape_pt_sequence: 329,
    shape_dist_traveled: 22.7479,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.026573,
    shape_pt_lon: -89.963659,
    shape_pt_sequence: 330,
    shape_dist_traveled: 22.8139,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.026329,
    shape_pt_lon: -89.963652,
    shape_pt_sequence: 331,
    shape_dist_traveled: 22.8419,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.025488,
    shape_pt_lon: -89.963699,
    shape_pt_sequence: 332,
    shape_dist_traveled: 22.936,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.024635,
    shape_pt_lon: -89.963756,
    shape_pt_sequence: 333,
    shape_dist_traveled: 23.0312,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.024553,
    shape_pt_lon: -89.963758,
    shape_pt_sequence: 334,
    shape_dist_traveled: 23.0402,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.023163,
    shape_pt_lon: -89.963719,
    shape_pt_sequence: 335,
    shape_dist_traveled: 23.1962,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.022849,
    shape_pt_lon: -89.96371,
    shape_pt_sequence: 336,
    shape_dist_traveled: 23.2312,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020936,
    shape_pt_lon: -89.96365,
    shape_pt_sequence: 337,
    shape_dist_traveled: 23.4453,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020845,
    shape_pt_lon: -89.963647,
    shape_pt_sequence: 338,
    shape_dist_traveled: 23.4553,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02084,
    shape_pt_lon: -89.962951,
    shape_pt_sequence: 339,
    shape_dist_traveled: 23.5183,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020803,
    shape_pt_lon: -89.959407,
    shape_pt_sequence: 340,
    shape_dist_traveled: 23.8393,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02079,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 341,
    shape_dist_traveled: 23.9873,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020786,
    shape_pt_lon: -89.957237,
    shape_pt_sequence: 342,
    shape_dist_traveled: 24.0353,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 343,
    shape_dist_traveled: 24.2763,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020749,
    shape_pt_lon: -89.954332,
    shape_pt_sequence: 344,
    shape_dist_traveled: 24.2984,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020682,
    shape_pt_lon: -89.954195,
    shape_pt_sequence: 345,
    shape_dist_traveled: 24.3136,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.953711,
    shape_pt_sequence: 346,
    shape_dist_traveled: 24.3566,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020659,
    shape_pt_lon: -89.950091,
    shape_pt_sequence: 347,
    shape_dist_traveled: 24.6846,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02065,
    shape_pt_lon: -89.949319,
    shape_pt_sequence: 348,
    shape_dist_traveled: 24.7546,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02064,
    shape_pt_lon: -89.948509,
    shape_pt_sequence: 349,
    shape_dist_traveled: 24.8276,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020643,
    shape_pt_lon: -89.947656,
    shape_pt_sequence: 350,
    shape_dist_traveled: 24.9047,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020637,
    shape_pt_lon: -89.946156,
    shape_pt_sequence: 351,
    shape_dist_traveled: 25.0407,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020629,
    shape_pt_lon: -89.944815,
    shape_pt_sequence: 352,
    shape_dist_traveled: 25.1617,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020617,
    shape_pt_lon: -89.942941,
    shape_pt_sequence: 353,
    shape_dist_traveled: 25.3317,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020613,
    shape_pt_lon: -89.942266,
    shape_pt_sequence: 354,
    shape_dist_traveled: 25.3927,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020599,
    shape_pt_lon: -89.940171,
    shape_pt_sequence: 355,
    shape_dist_traveled: 25.5827,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020598,
    shape_pt_lon: -89.939933,
    shape_pt_sequence: 356,
    shape_dist_traveled: 25.6037,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020589,
    shape_pt_lon: -89.939049,
    shape_pt_sequence: 357,
    shape_dist_traveled: 25.6837,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020592,
    shape_pt_lon: -89.938295,
    shape_pt_sequence: 358,
    shape_dist_traveled: 25.7527,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020576,
    shape_pt_lon: -89.93688,
    shape_pt_sequence: 359,
    shape_dist_traveled: 25.8807,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020556,
    shape_pt_lon: -89.934586,
    shape_pt_sequence: 360,
    shape_dist_traveled: 26.0877,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020548,
    shape_pt_lon: -89.931286,
    shape_pt_sequence: 361,
    shape_dist_traveled: 26.3867,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020544,
    shape_pt_lon: -89.93085,
    shape_pt_sequence: 362,
    shape_dist_traveled: 26.4257,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020542,
    shape_pt_lon: -89.930531,
    shape_pt_sequence: 363,
    shape_dist_traveled: 26.4547,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020539,
    shape_pt_lon: -89.929906,
    shape_pt_sequence: 364,
    shape_dist_traveled: 26.5117,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020537,
    shape_pt_lon: -89.927989,
    shape_pt_sequence: 365,
    shape_dist_traveled: 26.6847,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020538,
    shape_pt_lon: -89.926384,
    shape_pt_sequence: 366,
    shape_dist_traveled: 26.8297,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02054,
    shape_pt_lon: -89.92479,
    shape_pt_sequence: 367,
    shape_dist_traveled: 26.9747,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020544,
    shape_pt_lon: -89.923544,
    shape_pt_sequence: 368,
    shape_dist_traveled: 27.0867,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020532,
    shape_pt_lon: -89.919056,
    shape_pt_sequence: 369,
    shape_dist_traveled: 27.4937,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02052,
    shape_pt_lon: -89.916388,
    shape_pt_sequence: 370,
    shape_dist_traveled: 27.7347,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02052,
    shape_pt_lon: -89.916201,
    shape_pt_sequence: 371,
    shape_dist_traveled: 27.7517,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020499,
    shape_pt_lon: -89.911493,
    shape_pt_sequence: 372,
    shape_dist_traveled: 28.1777,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020499,
    shape_pt_lon: -89.911415,
    shape_pt_sequence: 373,
    shape_dist_traveled: 28.1847,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020498,
    shape_pt_lon: -89.911327,
    shape_pt_sequence: 374,
    shape_dist_traveled: 28.1928,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020487,
    shape_pt_lon: -89.909093,
    shape_pt_sequence: 375,
    shape_dist_traveled: 28.3948,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020486,
    shape_pt_lon: -89.906871,
    shape_pt_sequence: 376,
    shape_dist_traveled: 28.5958,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02047,
    shape_pt_lon: -89.905193,
    shape_pt_sequence: 377,
    shape_dist_traveled: 28.7478,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02047,
    shape_pt_lon: -89.904622,
    shape_pt_sequence: 378,
    shape_dist_traveled: 28.7998,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020463,
    shape_pt_lon: -89.903956,
    shape_pt_sequence: 379,
    shape_dist_traveled: 28.8598,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02047,
    shape_pt_lon: -89.903643,
    shape_pt_sequence: 380,
    shape_dist_traveled: 28.8878,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020463,
    shape_pt_lon: -89.901657,
    shape_pt_sequence: 381,
    shape_dist_traveled: 29.0678,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020462,
    shape_pt_lon: -89.901326,
    shape_pt_sequence: 382,
    shape_dist_traveled: 29.0978,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020464,
    shape_pt_lon: -89.90074,
    shape_pt_sequence: 383,
    shape_dist_traveled: 29.1508,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020469,
    shape_pt_lon: -89.900492,
    shape_pt_sequence: 384,
    shape_dist_traveled: 29.1738,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020479,
    shape_pt_lon: -89.899929,
    shape_pt_sequence: 385,
    shape_dist_traveled: 29.2238,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020483,
    shape_pt_lon: -89.898928,
    shape_pt_sequence: 386,
    shape_dist_traveled: 29.3148,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020483,
    shape_pt_lon: -89.898319,
    shape_pt_sequence: 387,
    shape_dist_traveled: 29.3698,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020482,
    shape_pt_lon: -89.898132,
    shape_pt_sequence: 388,
    shape_dist_traveled: 29.3868,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020481,
    shape_pt_lon: -89.898,
    shape_pt_sequence: 389,
    shape_dist_traveled: 29.3988,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02048,
    shape_pt_lon: -89.897461,
    shape_pt_sequence: 390,
    shape_dist_traveled: 29.4478,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020479,
    shape_pt_lon: -89.895648,
    shape_pt_sequence: 391,
    shape_dist_traveled: 29.6118,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020467,
    shape_pt_lon: -89.893247,
    shape_pt_sequence: 392,
    shape_dist_traveled: 29.8288,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020452,
    shape_pt_lon: -89.891584,
    shape_pt_sequence: 393,
    shape_dist_traveled: 29.9798,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020446,
    shape_pt_lon: -89.891079,
    shape_pt_sequence: 394,
    shape_dist_traveled: 30.0248,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020442,
    shape_pt_lon: -89.89071,
    shape_pt_sequence: 395,
    shape_dist_traveled: 30.0589,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02044,
    shape_pt_lon: -89.890449,
    shape_pt_sequence: 396,
    shape_dist_traveled: 30.0819,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02041,
    shape_pt_lon: -89.886222,
    shape_pt_sequence: 397,
    shape_dist_traveled: 30.4649,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020399,
    shape_pt_lon: -89.884332,
    shape_pt_sequence: 398,
    shape_dist_traveled: 30.6359,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020398,
    shape_pt_lon: -89.884191,
    shape_pt_sequence: 399,
    shape_dist_traveled: 30.6489,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.882964,
    shape_pt_sequence: 400,
    shape_dist_traveled: 30.7599,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020362,
    shape_pt_lon: -89.882212,
    shape_pt_sequence: 401,
    shape_dist_traveled: 30.8279,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020361,
    shape_pt_lon: -89.882022,
    shape_pt_sequence: 402,
    shape_dist_traveled: 30.8449,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020353,
    shape_pt_lon: -89.880173,
    shape_pt_sequence: 403,
    shape_dist_traveled: 31.0119,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020353,
    shape_pt_lon: -89.88013,
    shape_pt_sequence: 404,
    shape_dist_traveled: 31.0159,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020353,
    shape_pt_lon: -89.880103,
    shape_pt_sequence: 405,
    shape_dist_traveled: 31.0189,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020351,
    shape_pt_lon: -89.880042,
    shape_pt_sequence: 406,
    shape_dist_traveled: 31.0239,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02035,
    shape_pt_lon: -89.879974,
    shape_pt_sequence: 407,
    shape_dist_traveled: 31.0299,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020353,
    shape_pt_lon: -89.878463,
    shape_pt_sequence: 408,
    shape_dist_traveled: 31.1669,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020351,
    shape_pt_lon: -89.877441,
    shape_pt_sequence: 409,
    shape_dist_traveled: 31.2599,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020348,
    shape_pt_lon: -89.875017,
    shape_pt_sequence: 410,
    shape_dist_traveled: 31.4789,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020333,
    shape_pt_lon: -89.872993,
    shape_pt_sequence: 411,
    shape_dist_traveled: 31.6619,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02032,
    shape_pt_lon: -89.871279,
    shape_pt_sequence: 412,
    shape_dist_traveled: 31.8169,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02031,
    shape_pt_lon: -89.869938,
    shape_pt_sequence: 413,
    shape_dist_traveled: 31.9389,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020285,
    shape_pt_lon: -89.866352,
    shape_pt_sequence: 414,
    shape_dist_traveled: 32.2629,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020285,
    shape_pt_lon: -89.866236,
    shape_pt_sequence: 415,
    shape_dist_traveled: 32.2739,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020289,
    shape_pt_lon: -89.866162,
    shape_pt_sequence: 416,
    shape_dist_traveled: 32.28,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020357,
    shape_pt_lon: -89.866029,
    shape_pt_sequence: 417,
    shape_dist_traveled: 32.2939,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020348,
    shape_pt_lon: -89.865158,
    shape_pt_sequence: 418,
    shape_dist_traveled: 32.3729,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.863546,
    shape_pt_sequence: 419,
    shape_dist_traveled: 32.5189,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859638,
    shape_pt_sequence: 420,
    shape_dist_traveled: 32.8729,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859229,
    shape_pt_sequence: 421,
    shape_dist_traveled: 32.9099,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020358,
    shape_pt_lon: -89.857329,
    shape_pt_sequence: 422,
    shape_dist_traveled: 33.0819,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020369,
    shape_pt_lon: -89.855121,
    shape_pt_sequence: 423,
    shape_dist_traveled: 33.2809,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020379,
    shape_pt_lon: -89.853532,
    shape_pt_sequence: 424,
    shape_dist_traveled: 33.4249,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.852845,
    shape_pt_sequence: 425,
    shape_dist_traveled: 33.4869,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020383,
    shape_pt_lon: -89.852447,
    shape_pt_sequence: 426,
    shape_dist_traveled: 33.5229,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020399,
    shape_pt_lon: -89.84868,
    shape_pt_sequence: 427,
    shape_dist_traveled: 33.864,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0204,
    shape_pt_lon: -89.846984,
    shape_pt_sequence: 428,
    shape_dist_traveled: 34.018,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.845459,
    shape_pt_sequence: 429,
    shape_dist_traveled: 34.156,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.844767,
    shape_pt_sequence: 430,
    shape_dist_traveled: 34.218,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.844031,
    shape_pt_sequence: 431,
    shape_dist_traveled: 34.285,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.842893,
    shape_pt_sequence: 432,
    shape_dist_traveled: 34.388,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.84246,
    shape_pt_sequence: 433,
    shape_dist_traveled: 34.427,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.839877,
    shape_pt_sequence: 434,
    shape_dist_traveled: 34.661,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.838762,
    shape_pt_sequence: 435,
    shape_dist_traveled: 34.762,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.835831,
    shape_pt_sequence: 436,
    shape_dist_traveled: 35.027,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.834467,
    shape_pt_sequence: 437,
    shape_dist_traveled: 35.15,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.833046,
    shape_pt_sequence: 438,
    shape_dist_traveled: 35.279,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020383,
    shape_pt_lon: -89.83256,
    shape_pt_sequence: 439,
    shape_dist_traveled: 35.323,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020389,
    shape_pt_lon: -89.831326,
    shape_pt_sequence: 440,
    shape_dist_traveled: 35.435,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020389,
    shape_pt_lon: -89.831243,
    shape_pt_sequence: 441,
    shape_dist_traveled: 35.442,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.020389,
    shape_pt_lon: -89.831161,
    shape_pt_sequence: 442,
    shape_dist_traveled: 35.45,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02053,
    shape_pt_lon: -89.831161,
    shape_pt_sequence: 443,
    shape_dist_traveled: 35.466,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.022271,
    shape_pt_lon: -89.831177,
    shape_pt_sequence: 444,
    shape_dist_traveled: 35.661,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.022369,
    shape_pt_lon: -89.831177,
    shape_pt_sequence: 445,
    shape_dist_traveled: 35.672,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.023466,
    shape_pt_lon: -89.831186,
    shape_pt_sequence: 446,
    shape_dist_traveled: 35.795,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.023599,
    shape_pt_lon: -89.831187,
    shape_pt_sequence: 447,
    shape_dist_traveled: 35.81,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02391,
    shape_pt_lon: -89.831189,
    shape_pt_sequence: 448,
    shape_dist_traveled: 35.844,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.024341,
    shape_pt_lon: -89.831195,
    shape_pt_sequence: 449,
    shape_dist_traveled: 35.893,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.024633,
    shape_pt_lon: -89.831199,
    shape_pt_sequence: 450,
    shape_dist_traveled: 35.925,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.024887,
    shape_pt_lon: -89.831201,
    shape_pt_sequence: 451,
    shape_dist_traveled: 35.954,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02518,
    shape_pt_lon: -89.831202,
    shape_pt_sequence: 452,
    shape_dist_traveled: 35.986,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.027167,
    shape_pt_lon: -89.831219,
    shape_pt_sequence: 453,
    shape_dist_traveled: 36.2091,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.027679,
    shape_pt_lon: -89.831222,
    shape_pt_sequence: 454,
    shape_dist_traveled: 36.2661,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.028902,
    shape_pt_lon: -89.831229,
    shape_pt_sequence: 455,
    shape_dist_traveled: 36.4031,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.02931,
    shape_pt_lon: -89.83123,
    shape_pt_sequence: 456,
    shape_dist_traveled: 36.4491,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.030418,
    shape_pt_lon: -89.83124,
    shape_pt_sequence: 457,
    shape_dist_traveled: 36.5721,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.030959,
    shape_pt_lon: -89.831247,
    shape_pt_sequence: 458,
    shape_dist_traveled: 36.6331,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.031797,
    shape_pt_lon: -89.831247,
    shape_pt_sequence: 459,
    shape_dist_traveled: 36.7271,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.032623,
    shape_pt_lon: -89.831249,
    shape_pt_sequence: 460,
    shape_dist_traveled: 36.8191,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.034321,
    shape_pt_lon: -89.831251,
    shape_pt_sequence: 461,
    shape_dist_traveled: 37.0091,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.034976,
    shape_pt_lon: -89.831251,
    shape_pt_sequence: 462,
    shape_dist_traveled: 37.0821,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.037451,
    shape_pt_lon: -89.831251,
    shape_pt_sequence: 463,
    shape_dist_traveled: 37.3591,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.038507,
    shape_pt_lon: -89.831251,
    shape_pt_sequence: 464,
    shape_dist_traveled: 37.4781,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.039859,
    shape_pt_lon: -89.831248,
    shape_pt_sequence: 465,
    shape_dist_traveled: 37.6291,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.040413,
    shape_pt_lon: -89.831233,
    shape_pt_sequence: 466,
    shape_dist_traveled: 37.6911,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.041248,
    shape_pt_lon: -89.831158,
    shape_pt_sequence: 467,
    shape_dist_traveled: 37.7843,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.041398,
    shape_pt_lon: -89.83114,
    shape_pt_sequence: 468,
    shape_dist_traveled: 37.8014,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.044218,
    shape_pt_lon: -89.830698,
    shape_pt_sequence: 469,
    shape_dist_traveled: 38.1198,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.04567,
    shape_pt_lon: -89.830468,
    shape_pt_sequence: 470,
    shape_dist_traveled: 38.2831,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.045723,
    shape_pt_lon: -89.83046,
    shape_pt_sequence: 471,
    shape_dist_traveled: 38.2891,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.047198,
    shape_pt_lon: -89.830228,
    shape_pt_sequence: 472,
    shape_dist_traveled: 38.4555,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.049055,
    shape_pt_lon: -89.829933,
    shape_pt_sequence: 473,
    shape_dist_traveled: 38.6651,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 474,
    shape_dist_traveled: 38.6791,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.0493,
    shape_pt_lon: -89.829894,
    shape_pt_sequence: 475,
    shape_dist_traveled: 38.6923,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05084,
    shape_pt_lon: -89.829657,
    shape_pt_sequence: 476,
    shape_dist_traveled: 38.8665,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052212,
    shape_pt_lon: -89.829437,
    shape_pt_sequence: 477,
    shape_dist_traveled: 39.0209,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052081,
    shape_pt_lon: -89.827372,
    shape_pt_sequence: 478,
    shape_dist_traveled: 39.2074,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052028,
    shape_pt_lon: -89.826076,
    shape_pt_sequence: 479,
    shape_dist_traveled: 39.3255,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.052019,
    shape_pt_lon: -89.825732,
    shape_pt_sequence: 480,
    shape_dist_traveled: 39.3565,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051992,
    shape_pt_lon: -89.825131,
    shape_pt_sequence: 481,
    shape_dist_traveled: 39.4106,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05194,
    shape_pt_lon: -89.824839,
    shape_pt_sequence: 482,
    shape_dist_traveled: 39.4382,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.824589,
    shape_pt_sequence: 483,
    shape_dist_traveled: 39.4616,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051742,
    shape_pt_lon: -89.824274,
    shape_pt_sequence: 484,
    shape_dist_traveled: 39.4939,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051615,
    shape_pt_lon: -89.824058,
    shape_pt_sequence: 485,
    shape_dist_traveled: 39.5175,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051439,
    shape_pt_lon: -89.823819,
    shape_pt_sequence: 486,
    shape_dist_traveled: 39.5472,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051261,
    shape_pt_lon: -89.823632,
    shape_pt_sequence: 487,
    shape_dist_traveled: 39.5734,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.051016,
    shape_pt_lon: -89.823448,
    shape_pt_sequence: 488,
    shape_dist_traveled: 39.6053,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.05079,
    shape_pt_lon: -89.823331,
    shape_pt_sequence: 489,
    shape_dist_traveled: 39.6323,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.050497,
    shape_pt_lon: -89.823238,
    shape_pt_sequence: 490,
    shape_dist_traveled: 39.6665,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.050229,
    shape_pt_lon: -89.8232,
    shape_pt_sequence: 491,
    shape_dist_traveled: 39.6966,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.050006,
    shape_pt_lon: -89.823203,
    shape_pt_sequence: 492,
    shape_dist_traveled: 39.7216,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.04905,
    shape_pt_lon: -89.823357,
    shape_pt_sequence: 493,
    shape_dist_traveled: 39.8294,
  },
  {
    shape_id: 38209,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 494,
    shape_dist_traveled: 39.8445,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064758,
    shape_pt_lon: -90.058849,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.016,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064738,
    shape_pt_lon: -90.057628,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.127,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064718,
    shape_pt_lon: -90.05662,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.2181,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.063045,
    shape_pt_lon: -90.056698,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.4052,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062966,
    shape_pt_lon: -90.053281,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.7143,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062931,
    shape_pt_lon: -90.051918,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.8374,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06293,
    shape_pt_lon: -90.051829,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.8454,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062929,
    shape_pt_lon: -90.051739,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.8534,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062904,
    shape_pt_lon: -90.050603,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.9564,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06289,
    shape_pt_lon: -90.049862,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.0234,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06288,
    shape_pt_lon: -90.049552,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.0515,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062858,
    shape_pt_lon: -90.049269,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.0776,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -90.049039,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.0988,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062775,
    shape_pt_lon: -90.048632,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.1363,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062623,
    shape_pt_lon: -90.047658,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.226,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062426,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.346,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062113,
    shape_pt_lon: -90.044343,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.5303,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062055,
    shape_pt_lon: -90.04382,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.5788,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062016,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.634,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061999,
    shape_pt_lon: -90.042573,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.692,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061956,
    shape_pt_lon: -90.040818,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.8501,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -90.038494,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.0602,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.2883,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061877,
    shape_pt_lon: -90.035987,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.2934,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.2985,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061804,
    shape_pt_lon: -90.034866,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.3995,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061758,
    shape_pt_lon: -90.033019,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.5666,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061739,
    shape_pt_lon: -90.032218,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.6387,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061698,
    shape_pt_lon: -90.030753,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.7717,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -90.029399,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.8938,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061643,
    shape_pt_lon: -90.028518,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.9738,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061632,
    shape_pt_lon: -90.028151,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.0068,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061618,
    shape_pt_lon: -90.027535,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.0629,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061579,
    shape_pt_lon: -90.025923,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.2079,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061594,
    shape_pt_lon: -90.025482,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.248,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061679,
    shape_pt_lon: -90.024789,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.3116,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061691,
    shape_pt_lon: -90.024712,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.3187,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061717,
    shape_pt_lon: -90.024418,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.3459,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061726,
    shape_pt_lon: -90.024207,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.3649,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061721,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.3859,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061705,
    shape_pt_lon: -90.023671,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.413,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061699,
    shape_pt_lon: -90.0236,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.42,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.424,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061689,
    shape_pt_lon: -90.023451,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.4331,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06163,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.5123,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.6328,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061527,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.6399,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06148,
    shape_pt_lon: -90.020652,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.6872,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061467,
    shape_pt_lon: -90.020286,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.7202,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061451,
    shape_pt_lon: -90.019991,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.7472,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061419,
    shape_pt_lon: -90.01949,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.7924,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061403,
    shape_pt_lon: -90.019237,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.8155,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061356,
    shape_pt_lon: -90.018053,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.9226,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061339,
    shape_pt_lon: -90.01756,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.9677,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061293,
    shape_pt_lon: -90.01599,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.1098,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061272,
    shape_pt_lon: -90.015226,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.1788,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061231,
    shape_pt_lon: -90.013952,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.2939,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06122,
    shape_pt_lon: -90.013628,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.3239,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0612,
    shape_pt_lon: -90.012873,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.3919,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061192,
    shape_pt_lon: -90.012536,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.422,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061179,
    shape_pt_lon: -90.011738,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.495,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061151,
    shape_pt_lon: -90.01104,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.558,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061123,
    shape_pt_lon: -90.010033,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.6491,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061102,
    shape_pt_lon: -90.009289,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.7162,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061051,
    shape_pt_lon: -90.007693,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.8602,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061039,
    shape_pt_lon: -90.007298,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.8963,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061037,
    shape_pt_lon: -90.007223,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.9033,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061035,
    shape_pt_lon: -90.007162,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.9083,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -90.004151,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.1815,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06093,
    shape_pt_lon: -90.003676,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.2245,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06089,
    shape_pt_lon: -90.002047,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.3716,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060878,
    shape_pt_lon: -90.001628,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.4096,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060842,
    shape_pt_lon: -90.000408,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.5197,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06081,
    shape_pt_lon: -89.999536,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.5988,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060777,
    shape_pt_lon: -89.998354,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.7058,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.99807,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.7318,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060741,
    shape_pt_lon: -89.99722,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.8089,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.011,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.0491,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.1061,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.1822,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.3168,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.4339,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.4559,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.507,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.5287,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.5471,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.5607,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.5791,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.5987,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.6152,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.6382,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.6622,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.7383,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.7953,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.8334,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.8394,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.9914,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.1605,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.2705,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.2907,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.311,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.3262,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.3426,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.3633,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.3847,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.4037,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.4218,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.4368,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.4774,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.5046,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.5733,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.6015,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.631,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.7439,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.7515,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.7609,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.8442,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.1691,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.2295,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.2773,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.2958,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3138,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.3445,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.3965,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4025,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.4169,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.4279,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.434,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.448,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.492,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068759,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.5791,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068763,
    shape_pt_lon: -89.984272,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.5911,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.983438,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6661,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068779,
    shape_pt_lon: -89.983286,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.6801,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068811,
    shape_pt_lon: -89.982129,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.7852,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068827,
    shape_pt_lon: -89.981701,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.8232,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068833,
    shape_pt_lon: -89.981153,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.8732,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980607,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.9223,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980318,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.9493,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068961,
    shape_pt_lon: -89.975041,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.4264,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.973159,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.5965,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069079,
    shape_pt_lon: -89.969853,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.8956,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069099,
    shape_pt_lon: -89.968477,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.0197,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069093,
    shape_pt_lon: -89.967908,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.0717,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06909,
    shape_pt_lon: -89.966763,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.1747,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069088,
    shape_pt_lon: -89.966092,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.2357,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069087,
    shape_pt_lon: -89.965871,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.2557,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.965049,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.3297,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069038,
    shape_pt_lon: -89.964462,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.3838,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069012,
    shape_pt_lon: -89.964132,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.413,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069011,
    shape_pt_lon: -89.964075,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.419,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068992,
    shape_pt_lon: -89.963804,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.4431,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06899,
    shape_pt_lon: -89.963668,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.4551,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068981,
    shape_pt_lon: -89.963304,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.4881,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068955,
    shape_pt_lon: -89.962088,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.5981,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068951,
    shape_pt_lon: -89.961978,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.6082,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068929,
    shape_pt_lon: -89.961096,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.6882,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068899,
    shape_pt_lon: -89.95991,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.7953,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068898,
    shape_pt_lon: -89.959817,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.8043,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.958461,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.9263,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068782,
    shape_pt_lon: -89.955203,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.2215,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068773,
    shape_pt_lon: -89.95478,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.2595,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068753,
    shape_pt_lon: -89.9543,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.3035,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068743,
    shape_pt_lon: -89.954207,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.3116,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.068859,
    shape_pt_lon: -89.954229,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.3247,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06918,
    shape_pt_lon: -89.954286,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.3611,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069328,
    shape_pt_lon: -89.954309,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.3772,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072248,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.7069,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072429,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.727,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072486,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.7341,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072802,
    shape_pt_lon: -89.954828,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.7692,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072893,
    shape_pt_lon: -89.954822,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.7792,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.073257,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.8205,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.073675,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.8691,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.8919,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.074048,
    shape_pt_lon: -89.954422,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.9143,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.074386,
    shape_pt_lon: -89.954148,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.9597,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.074464,
    shape_pt_lon: -89.954062,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.9712,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.074843,
    shape_pt_lon: -89.95362,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.0292,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075379,
    shape_pt_lon: -89.952992,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.1119,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075925,
    shape_pt_lon: -89.952326,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.1982,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.951963,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.2415,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076279,
    shape_pt_lon: -89.951763,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.2626,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076427,
    shape_pt_lon: -89.951466,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.2945,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.3272,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.3518,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.3771,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.3958,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.4249,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.4859,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 193,
    shape_dist_traveled: 12.504,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 194,
    shape_dist_traveled: 12.5451,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.0501,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.1633,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.2104,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.9053,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.9553,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.9643,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.9702,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.9787,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.9917,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.0528,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.0586,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.0716,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.0856,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.0927,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.1022,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.1532,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.1564,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.1627,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.1687,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.1967,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.2097,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.2183,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.2241,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.2331,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.3082,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.3663,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.3814,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.3885,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.5931,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.7335,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.8037,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.8037,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.912,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.9902,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.0793,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.1043,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.2024,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.2955,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.3385,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.3505,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.3666,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.3717,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067558,
    shape_pt_lon: -89.932289,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.3825,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067459,
    shape_pt_lon: -89.932293,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.3935,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.067092,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.4345,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.066671,
    shape_pt_lon: -89.932328,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.4815,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.065107,
    shape_pt_lon: -89.932411,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.6567,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064879,
    shape_pt_lon: -89.932423,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.6827,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064291,
    shape_pt_lon: -89.932453,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.7488,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.064029,
    shape_pt_lon: -89.932448,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.7778,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.063716,
    shape_pt_lon: -89.932403,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.813,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.063507,
    shape_pt_lon: -89.932347,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.8365,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.063191,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.8742,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062717,
    shape_pt_lon: -89.931944,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.9328,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.062459,
    shape_pt_lon: -89.931786,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.9654,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061961,
    shape_pt_lon: -89.931489,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.0267,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.061652,
    shape_pt_lon: -89.931341,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.064,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.06123,
    shape_pt_lon: -89.9312,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.1128,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060961,
    shape_pt_lon: -89.931143,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.1432,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060683,
    shape_pt_lon: -89.931106,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.1745,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.060302,
    shape_pt_lon: -89.931094,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.2175,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.059947,
    shape_pt_lon: -89.931126,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.2576,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.059509,
    shape_pt_lon: -89.931222,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.3074,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.059194,
    shape_pt_lon: -89.931322,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.3436,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.059069,
    shape_pt_lon: -89.931372,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.3581,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05867,
    shape_pt_lon: -89.931552,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.4049,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057947,
    shape_pt_lon: -89.931951,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.4936,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057775,
    shape_pt_lon: -89.932061,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.5159,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057193,
    shape_pt_lon: -89.9324,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.588,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057159,
    shape_pt_lon: -89.932421,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.5924,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057099,
    shape_pt_lon: -89.932456,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.5991,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.057,
    shape_pt_lon: -89.932508,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.6112,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.056851,
    shape_pt_lon: -89.932588,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.6296,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.056418,
    shape_pt_lon: -89.932841,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.6837,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.055928,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.7432,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.055669,
    shape_pt_lon: -89.933286,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.7759,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.055378,
    shape_pt_lon: -89.933448,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.8117,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.055259,
    shape_pt_lon: -89.933511,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.8261,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.055036,
    shape_pt_lon: -89.933617,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.853,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.054887,
    shape_pt_lon: -89.933682,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.871,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.054358,
    shape_pt_lon: -89.933866,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.9321,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.054075,
    shape_pt_lon: -89.933937,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.9647,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05366,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.0112,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.053425,
    shape_pt_lon: -89.934032,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.0383,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.052017,
    shape_pt_lon: -89.934014,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.1953,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051872,
    shape_pt_lon: -89.934011,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.2113,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05176,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.2243,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051587,
    shape_pt_lon: -89.934008,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.2433,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05146,
    shape_pt_lon: -89.934002,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.2583,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051125,
    shape_pt_lon: -89.933969,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.2955,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.050258,
    shape_pt_lon: -89.933779,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.3939,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049812,
    shape_pt_lon: -89.933711,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.4444,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049505,
    shape_pt_lon: -89.93369,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.4785,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049324,
    shape_pt_lon: -89.933688,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.4985,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.048834,
    shape_pt_lon: -89.93368,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.5535,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.048836,
    shape_pt_lon: -89.933675,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.5535,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.047262,
    shape_pt_lon: -89.933651,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.7295,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.047268,
    shape_pt_lon: -89.933651,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.7295,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.046228,
    shape_pt_lon: -89.933636,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.8455,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045893,
    shape_pt_lon: -89.933648,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.8825,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045588,
    shape_pt_lon: -89.93369,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.9168,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045404,
    shape_pt_lon: -89.933729,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.938,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045038,
    shape_pt_lon: -89.933844,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.9804,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.044847,
    shape_pt_lon: -89.933923,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.0026,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.044716,
    shape_pt_lon: -89.933991,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.0187,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.044463,
    shape_pt_lon: -89.93413,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.0492,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.044273,
    shape_pt_lon: -89.934256,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.0742,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043993,
    shape_pt_lon: -89.934471,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.1106,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043719,
    shape_pt_lon: -89.934731,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.1498,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043373,
    shape_pt_lon: -89.935124,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.2015,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04294,
    shape_pt_lon: -89.935642,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.2694,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.042639,
    shape_pt_lon: -89.935982,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.3154,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04231,
    shape_pt_lon: -89.936287,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.3612,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.041898,
    shape_pt_lon: -89.936591,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.415,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.041548,
    shape_pt_lon: -89.936787,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.4576,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.041219,
    shape_pt_lon: -89.936931,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.4968,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040902,
    shape_pt_lon: -89.937033,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.5329,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040517,
    shape_pt_lon: -89.937118,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.5767,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040087,
    shape_pt_lon: -89.937159,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.6248,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.039772,
    shape_pt_lon: -89.93715,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.6609,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.039456,
    shape_pt_lon: -89.937118,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.696,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038971,
    shape_pt_lon: -89.93706,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.7502,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038648,
    shape_pt_lon: -89.937047,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.7863,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038419,
    shape_pt_lon: -89.937056,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.8123,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.03771,
    shape_pt_lon: -89.937138,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.8916,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.037277,
    shape_pt_lon: -89.937158,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.9406,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.036834,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.9897,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0368,
    shape_pt_lon: -89.937169,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.9937,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.036767,
    shape_pt_lon: -89.93717,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.9977,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.036123,
    shape_pt_lon: -89.937161,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.0697,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.035849,
    shape_pt_lon: -89.937137,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.1007,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.035163,
    shape_pt_lon: -89.937052,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.1771,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0348,
    shape_pt_lon: -89.937047,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.2181,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034462,
    shape_pt_lon: -89.937079,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.2563,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034377,
    shape_pt_lon: -89.937092,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.2653,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034298,
    shape_pt_lon: -89.937106,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.2744,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.033956,
    shape_pt_lon: -89.937151,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.3126,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.033633,
    shape_pt_lon: -89.937168,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.3496,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.032969,
    shape_pt_lon: -89.937172,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.4236,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.032404,
    shape_pt_lon: -89.937165,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.4866,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.030784,
    shape_pt_lon: -89.93712,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.6677,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.030663,
    shape_pt_lon: -89.937044,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.6833,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.030277,
    shape_pt_lon: -89.937031,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.7264,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.029582,
    shape_pt_lon: -89.937016,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.8044,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027972,
    shape_pt_lon: -89.936979,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.9844,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.93815,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.0904,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.939003,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.1674,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.94137,
    shape_pt_sequence: 342,
    shape_dist_traveled: 20.3814,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.943689,
    shape_pt_sequence: 343,
    shape_dist_traveled: 20.5914,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94404,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.6224,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027987,
    shape_pt_lon: -89.945346,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.7414,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027993,
    shape_pt_lon: -89.945854,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.7874,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.94633,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.8305,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.946853,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.8775,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027963,
    shape_pt_lon: -89.947081,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.8985,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027966,
    shape_pt_lon: -89.948133,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.9935,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027967,
    shape_pt_lon: -89.949072,
    shape_pt_sequence: 351,
    shape_dist_traveled: 21.0785,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94997,
    shape_pt_sequence: 352,
    shape_dist_traveled: 21.1595,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.950879,
    shape_pt_sequence: 353,
    shape_dist_traveled: 21.2415,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.953473,
    shape_pt_sequence: 354,
    shape_dist_traveled: 21.4765,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.954728,
    shape_pt_sequence: 355,
    shape_dist_traveled: 21.5905,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027836,
    shape_pt_lon: -89.954737,
    shape_pt_sequence: 356,
    shape_dist_traveled: 21.6055,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027319,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 357,
    shape_dist_traveled: 21.6636,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02707,
    shape_pt_lon: -89.954757,
    shape_pt_sequence: 358,
    shape_dist_traveled: 21.6916,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02614,
    shape_pt_lon: -89.954652,
    shape_pt_sequence: 359,
    shape_dist_traveled: 21.7961,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.025685,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 360,
    shape_dist_traveled: 21.8471,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.024822,
    shape_pt_lon: -89.954638,
    shape_pt_sequence: 361,
    shape_dist_traveled: 21.9431,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.024258,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.0071,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.022747,
    shape_pt_lon: -89.954625,
    shape_pt_sequence: 363,
    shape_dist_traveled: 22.1761,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020812,
    shape_pt_lon: -89.954586,
    shape_pt_sequence: 364,
    shape_dist_traveled: 22.3922,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 365,
    shape_dist_traveled: 22.3983,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.954572,
    shape_pt_sequence: 366,
    shape_dist_traveled: 22.4083,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020113,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 367,
    shape_dist_traveled: 22.4703,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.017091,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 368,
    shape_dist_traveled: 22.8083,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.016532,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 369,
    shape_dist_traveled: 22.8713,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.015599,
    shape_pt_lon: -89.954564,
    shape_pt_sequence: 370,
    shape_dist_traveled: 22.9753,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.014964,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.0463,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.01411,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.1423,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.013438,
    shape_pt_lon: -89.954563,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.2173,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.01126,
    shape_pt_lon: -89.954553,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.4613,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.009323,
    shape_pt_lon: -89.954539,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.6783,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006199,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 376,
    shape_dist_traveled: 24.0273,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006188,
    shape_pt_lon: -89.954324,
    shape_pt_sequence: 377,
    shape_dist_traveled: 24.0484,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006159,
    shape_pt_lon: -89.951597,
    shape_pt_sequence: 378,
    shape_dist_traveled: 24.2954,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006155,
    shape_pt_lon: -89.949916,
    shape_pt_sequence: 379,
    shape_dist_traveled: 24.4474,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006146,
    shape_pt_lon: -89.94867,
    shape_pt_sequence: 380,
    shape_dist_traveled: 24.5604,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006144,
    shape_pt_lon: -89.948532,
    shape_pt_sequence: 381,
    shape_dist_traveled: 24.5724,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006117,
    shape_pt_lon: -89.944809,
    shape_pt_sequence: 382,
    shape_dist_traveled: 24.9094,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006095,
    shape_pt_lon: -89.943923,
    shape_pt_sequence: 383,
    shape_dist_traveled: 24.9895,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006077,
    shape_pt_lon: -89.942096,
    shape_pt_sequence: 384,
    shape_dist_traveled: 25.1555,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00607,
    shape_pt_lon: -89.941451,
    shape_pt_sequence: 385,
    shape_dist_traveled: 25.2135,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006069,
    shape_pt_lon: -89.941305,
    shape_pt_sequence: 386,
    shape_dist_traveled: 25.2265,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -89.940202,
    shape_pt_sequence: 387,
    shape_dist_traveled: 25.3265,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006052,
    shape_pt_lon: -89.939112,
    shape_pt_sequence: 388,
    shape_dist_traveled: 25.4255,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006062,
    shape_pt_lon: -89.938412,
    shape_pt_sequence: 389,
    shape_dist_traveled: 25.4885,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00604,
    shape_pt_lon: -89.937121,
    shape_pt_sequence: 390,
    shape_dist_traveled: 25.6056,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 391,
    shape_dist_traveled: 25.6336,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 392,
    shape_dist_traveled: 25.8566,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 393,
    shape_dist_traveled: 25.8986,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 394,
    shape_dist_traveled: 25.9116,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 395,
    shape_dist_traveled: 25.9906,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 396,
    shape_dist_traveled: 26.0916,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 397,
    shape_dist_traveled: 26.1266,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 398,
    shape_dist_traveled: 26.1866,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001636,
    shape_pt_lon: -89.935264,
    shape_pt_sequence: 399,
    shape_dist_traveled: 26.2646,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001636,
    shape_pt_lon: -89.935264,
    shape_pt_sequence: 400,
    shape_dist_traveled: 26.3426,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 401,
    shape_dist_traveled: 26.4026,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 402,
    shape_dist_traveled: 26.4376,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 403,
    shape_dist_traveled: 26.5387,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 404,
    shape_dist_traveled: 26.6177,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 405,
    shape_dist_traveled: 26.6307,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 406,
    shape_dist_traveled: 26.6727,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 407,
    shape_dist_traveled: 26.8957,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006049,
    shape_pt_lon: -89.936093,
    shape_pt_sequence: 408,
    shape_dist_traveled: 26.9607,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.93365,
    shape_pt_sequence: 409,
    shape_dist_traveled: 27.1817,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.931137,
    shape_pt_sequence: 410,
    shape_dist_traveled: 27.4097,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.930648,
    shape_pt_sequence: 411,
    shape_dist_traveled: 27.4537,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006033,
    shape_pt_lon: -89.929494,
    shape_pt_sequence: 412,
    shape_dist_traveled: 27.5577,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.927998,
    shape_pt_sequence: 413,
    shape_dist_traveled: 27.6938,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.924019,
    shape_pt_sequence: 414,
    shape_dist_traveled: 28.0538,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00606,
    shape_pt_lon: -89.923647,
    shape_pt_sequence: 415,
    shape_dist_traveled: 28.0878,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00605,
    shape_pt_lon: -89.919066,
    shape_pt_sequence: 416,
    shape_dist_traveled: 28.5018,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006038,
    shape_pt_lon: -89.916056,
    shape_pt_sequence: 417,
    shape_dist_traveled: 28.7748,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.915058,
    shape_pt_sequence: 418,
    shape_dist_traveled: 28.8648,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006021,
    shape_pt_lon: -89.913257,
    shape_pt_sequence: 419,
    shape_dist_traveled: 29.0278,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006019,
    shape_pt_lon: -89.91084,
    shape_pt_sequence: 420,
    shape_dist_traveled: 29.2468,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.910567,
    shape_pt_sequence: 421,
    shape_dist_traveled: 29.2708,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00603,
    shape_pt_lon: -89.910074,
    shape_pt_sequence: 422,
    shape_dist_traveled: 29.3158,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006026,
    shape_pt_lon: -89.909113,
    shape_pt_sequence: 423,
    shape_dist_traveled: 29.4028,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00602,
    shape_pt_lon: -89.907796,
    shape_pt_sequence: 424,
    shape_dist_traveled: 29.5218,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006017,
    shape_pt_lon: -89.907199,
    shape_pt_sequence: 425,
    shape_dist_traveled: 29.5758,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.906361,
    shape_pt_sequence: 426,
    shape_dist_traveled: 29.6519,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005979,
    shape_pt_lon: -89.904696,
    shape_pt_sequence: 427,
    shape_dist_traveled: 29.8029,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005971,
    shape_pt_lon: -89.904277,
    shape_pt_sequence: 428,
    shape_dist_traveled: 29.8409,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005968,
    shape_pt_lon: -89.903436,
    shape_pt_sequence: 429,
    shape_dist_traveled: 29.9169,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.902176,
    shape_pt_sequence: 430,
    shape_dist_traveled: 30.0309,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.901498,
    shape_pt_sequence: 431,
    shape_dist_traveled: 30.0919,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005959,
    shape_pt_lon: -89.900487,
    shape_pt_sequence: 432,
    shape_dist_traveled: 30.1839,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005977,
    shape_pt_lon: -89.899086,
    shape_pt_sequence: 433,
    shape_dist_traveled: 30.3109,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.895486,
    shape_pt_sequence: 434,
    shape_dist_traveled: 30.6369,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005967,
    shape_pt_lon: -89.894096,
    shape_pt_sequence: 435,
    shape_dist_traveled: 30.762,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005961,
    shape_pt_lon: -89.893764,
    shape_pt_sequence: 436,
    shape_dist_traveled: 30.792,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.893538,
    shape_pt_sequence: 437,
    shape_dist_traveled: 30.813,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005969,
    shape_pt_lon: -89.892566,
    shape_pt_sequence: 438,
    shape_dist_traveled: 30.901,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005981,
    shape_pt_lon: -89.892098,
    shape_pt_sequence: 439,
    shape_dist_traveled: 30.943,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00599,
    shape_pt_lon: -89.891662,
    shape_pt_sequence: 440,
    shape_dist_traveled: 30.983,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005993,
    shape_pt_lon: -89.889476,
    shape_pt_sequence: 441,
    shape_dist_traveled: 31.18,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005978,
    shape_pt_lon: -89.887981,
    shape_pt_sequence: 442,
    shape_dist_traveled: 31.316,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.887272,
    shape_pt_sequence: 443,
    shape_dist_traveled: 31.38,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005956,
    shape_pt_lon: -89.88715,
    shape_pt_sequence: 444,
    shape_dist_traveled: 31.3911,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005949,
    shape_pt_lon: -89.887027,
    shape_pt_sequence: 445,
    shape_dist_traveled: 31.4021,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005937,
    shape_pt_lon: -89.886656,
    shape_pt_sequence: 446,
    shape_dist_traveled: 31.4361,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00594,
    shape_pt_lon: -89.886328,
    shape_pt_sequence: 447,
    shape_dist_traveled: 31.4651,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.884633,
    shape_pt_sequence: 448,
    shape_dist_traveled: 31.6191,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.88395,
    shape_pt_sequence: 449,
    shape_dist_traveled: 31.6811,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005929,
    shape_pt_lon: -89.883789,
    shape_pt_sequence: 450,
    shape_dist_traveled: 31.6951,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005927,
    shape_pt_lon: -89.882696,
    shape_pt_sequence: 451,
    shape_dist_traveled: 31.7941,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005916,
    shape_pt_lon: -89.88176,
    shape_pt_sequence: 452,
    shape_dist_traveled: 31.8792,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005906,
    shape_pt_lon: -89.880732,
    shape_pt_sequence: 453,
    shape_dist_traveled: 31.9722,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005905,
    shape_pt_lon: -89.880578,
    shape_pt_sequence: 454,
    shape_dist_traveled: 31.9862,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005902,
    shape_pt_lon: -89.880352,
    shape_pt_sequence: 455,
    shape_dist_traveled: 32.0062,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005899,
    shape_pt_lon: -89.879792,
    shape_pt_sequence: 456,
    shape_dist_traveled: 32.0572,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005891,
    shape_pt_lon: -89.878439,
    shape_pt_sequence: 457,
    shape_dist_traveled: 32.1792,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005886,
    shape_pt_lon: -89.8772,
    shape_pt_sequence: 458,
    shape_dist_traveled: 32.2922,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005879,
    shape_pt_lon: -89.876634,
    shape_pt_sequence: 459,
    shape_dist_traveled: 32.3432,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005878,
    shape_pt_lon: -89.876388,
    shape_pt_sequence: 460,
    shape_dist_traveled: 32.3652,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005877,
    shape_pt_lon: -89.875961,
    shape_pt_sequence: 461,
    shape_dist_traveled: 32.4042,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005874,
    shape_pt_lon: -89.875289,
    shape_pt_sequence: 462,
    shape_dist_traveled: 32.4652,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005854,
    shape_pt_lon: -89.871795,
    shape_pt_sequence: 463,
    shape_dist_traveled: 32.7812,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005849,
    shape_pt_lon: -89.870967,
    shape_pt_sequence: 464,
    shape_dist_traveled: 32.8562,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005837,
    shape_pt_lon: -89.869432,
    shape_pt_sequence: 465,
    shape_dist_traveled: 32.9952,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005831,
    shape_pt_lon: -89.86839,
    shape_pt_sequence: 466,
    shape_dist_traveled: 33.0892,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005811,
    shape_pt_lon: -89.866213,
    shape_pt_sequence: 467,
    shape_dist_traveled: 33.2862,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.005817,
    shape_pt_lon: -89.864401,
    shape_pt_sequence: 468,
    shape_dist_traveled: 33.4502,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.006796,
    shape_pt_lon: -89.864393,
    shape_pt_sequence: 469,
    shape_dist_traveled: 33.5602,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.00833,
    shape_pt_lon: -89.86438,
    shape_pt_sequence: 470,
    shape_dist_traveled: 33.7312,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.008838,
    shape_pt_lon: -89.864381,
    shape_pt_sequence: 471,
    shape_dist_traveled: 33.7882,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.010641,
    shape_pt_lon: -89.86438,
    shape_pt_sequence: 472,
    shape_dist_traveled: 33.9902,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.011098,
    shape_pt_lon: -89.864392,
    shape_pt_sequence: 473,
    shape_dist_traveled: 34.0412,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.011591,
    shape_pt_lon: -89.86444,
    shape_pt_sequence: 474,
    shape_dist_traveled: 34.0964,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.01214,
    shape_pt_lon: -89.864541,
    shape_pt_sequence: 475,
    shape_dist_traveled: 34.1581,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.012575,
    shape_pt_lon: -89.864657,
    shape_pt_sequence: 476,
    shape_dist_traveled: 34.2083,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.01266,
    shape_pt_lon: -89.864681,
    shape_pt_sequence: 477,
    shape_dist_traveled: 34.2185,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.013016,
    shape_pt_lon: -89.864802,
    shape_pt_sequence: 478,
    shape_dist_traveled: 34.259,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.013498,
    shape_pt_lon: -89.865003,
    shape_pt_sequence: 479,
    shape_dist_traveled: 34.316,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.014228,
    shape_pt_lon: -89.865375,
    shape_pt_sequence: 480,
    shape_dist_traveled: 34.4047,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.014612,
    shape_pt_lon: -89.865574,
    shape_pt_sequence: 481,
    shape_dist_traveled: 34.4514,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0151,
    shape_pt_lon: -89.865796,
    shape_pt_sequence: 482,
    shape_dist_traveled: 34.5089,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.015583,
    shape_pt_lon: -89.86596,
    shape_pt_sequence: 483,
    shape_dist_traveled: 34.565,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.016078,
    shape_pt_lon: -89.86609,
    shape_pt_sequence: 484,
    shape_dist_traveled: 34.6223,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.016624,
    shape_pt_lon: -89.86618,
    shape_pt_sequence: 485,
    shape_dist_traveled: 34.6838,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.017061,
    shape_pt_lon: -89.866212,
    shape_pt_sequence: 486,
    shape_dist_traveled: 34.7329,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.017602,
    shape_pt_lon: -89.866227,
    shape_pt_sequence: 487,
    shape_dist_traveled: 34.7929,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.018487,
    shape_pt_lon: -89.86623,
    shape_pt_sequence: 488,
    shape_dist_traveled: 34.8919,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020285,
    shape_pt_lon: -89.866236,
    shape_pt_sequence: 489,
    shape_dist_traveled: 35.0929,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020358,
    shape_pt_lon: -89.866237,
    shape_pt_sequence: 490,
    shape_dist_traveled: 35.102,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866238,
    shape_pt_sequence: 491,
    shape_dist_traveled: 35.108,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02052,
    shape_pt_lon: -89.866239,
    shape_pt_sequence: 492,
    shape_dist_traveled: 35.12,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.020933,
    shape_pt_lon: -89.866248,
    shape_pt_sequence: 493,
    shape_dist_traveled: 35.166,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.022206,
    shape_pt_lon: -89.866243,
    shape_pt_sequence: 494,
    shape_dist_traveled: 35.308,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.022789,
    shape_pt_lon: -89.866253,
    shape_pt_sequence: 495,
    shape_dist_traveled: 35.374,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.024325,
    shape_pt_lon: -89.866271,
    shape_pt_sequence: 496,
    shape_dist_traveled: 35.545,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.025487,
    shape_pt_lon: -89.866287,
    shape_pt_sequence: 497,
    shape_dist_traveled: 35.675,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.026009,
    shape_pt_lon: -89.866296,
    shape_pt_sequence: 498,
    shape_dist_traveled: 35.734,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.026391,
    shape_pt_lon: -89.866301,
    shape_pt_sequence: 499,
    shape_dist_traveled: 35.777,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.02681,
    shape_pt_lon: -89.86631,
    shape_pt_sequence: 500,
    shape_dist_traveled: 35.823,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.027249,
    shape_pt_lon: -89.866317,
    shape_pt_sequence: 501,
    shape_dist_traveled: 35.873,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.029049,
    shape_pt_lon: -89.866339,
    shape_pt_sequence: 502,
    shape_dist_traveled: 36.074,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.029975,
    shape_pt_lon: -89.866352,
    shape_pt_sequence: 503,
    shape_dist_traveled: 36.178,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.030658,
    shape_pt_lon: -89.86636,
    shape_pt_sequence: 504,
    shape_dist_traveled: 36.2541,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.031468,
    shape_pt_lon: -89.866373,
    shape_pt_sequence: 505,
    shape_dist_traveled: 36.3451,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.031863,
    shape_pt_lon: -89.866379,
    shape_pt_sequence: 506,
    shape_dist_traveled: 36.3891,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.033187,
    shape_pt_lon: -89.866396,
    shape_pt_sequence: 507,
    shape_dist_traveled: 36.5371,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.033741,
    shape_pt_lon: -89.866405,
    shape_pt_sequence: 508,
    shape_dist_traveled: 36.5991,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034873,
    shape_pt_lon: -89.866421,
    shape_pt_sequence: 509,
    shape_dist_traveled: 36.7261,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034862,
    shape_pt_lon: -89.865812,
    shape_pt_sequence: 510,
    shape_dist_traveled: 36.7812,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034876,
    shape_pt_lon: -89.864542,
    shape_pt_sequence: 511,
    shape_dist_traveled: 36.8962,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034897,
    shape_pt_lon: -89.863257,
    shape_pt_sequence: 512,
    shape_dist_traveled: 37.0132,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0349,
    shape_pt_lon: -89.862913,
    shape_pt_sequence: 513,
    shape_dist_traveled: 37.0442,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.03492,
    shape_pt_lon: -89.860734,
    shape_pt_sequence: 514,
    shape_dist_traveled: 37.2412,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034937,
    shape_pt_lon: -89.85975,
    shape_pt_sequence: 515,
    shape_dist_traveled: 37.3303,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034949,
    shape_pt_lon: -89.858748,
    shape_pt_sequence: 516,
    shape_dist_traveled: 37.4213,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034975,
    shape_pt_lon: -89.856373,
    shape_pt_sequence: 517,
    shape_dist_traveled: 37.6353,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034979,
    shape_pt_lon: -89.855973,
    shape_pt_sequence: 518,
    shape_dist_traveled: 37.6723,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034988,
    shape_pt_lon: -89.854241,
    shape_pt_sequence: 519,
    shape_dist_traveled: 37.8283,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034988,
    shape_pt_lon: -89.853793,
    shape_pt_sequence: 520,
    shape_dist_traveled: 37.8693,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034981,
    shape_pt_lon: -89.852555,
    shape_pt_sequence: 521,
    shape_dist_traveled: 37.9813,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.03498,
    shape_pt_lon: -89.851563,
    shape_pt_sequence: 522,
    shape_dist_traveled: 38.0713,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.03498,
    shape_pt_lon: -89.850703,
    shape_pt_sequence: 523,
    shape_dist_traveled: 38.1483,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034976,
    shape_pt_lon: -89.849685,
    shape_pt_sequence: 524,
    shape_dist_traveled: 38.2413,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034977,
    shape_pt_lon: -89.847749,
    shape_pt_sequence: 525,
    shape_dist_traveled: 38.4163,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034977,
    shape_pt_lon: -89.847675,
    shape_pt_sequence: 526,
    shape_dist_traveled: 38.4223,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.034977,
    shape_pt_lon: -89.847581,
    shape_pt_sequence: 527,
    shape_dist_traveled: 38.4313,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.036432,
    shape_pt_lon: -89.84742,
    shape_pt_sequence: 528,
    shape_dist_traveled: 38.5949,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.037452,
    shape_pt_lon: -89.847299,
    shape_pt_sequence: 529,
    shape_dist_traveled: 38.7095,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.03792,
    shape_pt_lon: -89.847217,
    shape_pt_sequence: 530,
    shape_dist_traveled: 38.7619,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038479,
    shape_pt_lon: -89.847061,
    shape_pt_sequence: 531,
    shape_dist_traveled: 38.8265,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038631,
    shape_pt_lon: -89.847016,
    shape_pt_sequence: 532,
    shape_dist_traveled: 38.8439,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.038821,
    shape_pt_lon: -89.846956,
    shape_pt_sequence: 533,
    shape_dist_traveled: 38.8655,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.039199,
    shape_pt_lon: -89.846808,
    shape_pt_sequence: 534,
    shape_dist_traveled: 38.9107,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0396,
    shape_pt_lon: -89.846623,
    shape_pt_sequence: 535,
    shape_dist_traveled: 38.9576,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.039726,
    shape_pt_lon: -89.84656,
    shape_pt_sequence: 536,
    shape_dist_traveled: 38.9737,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04007,
    shape_pt_lon: -89.846372,
    shape_pt_sequence: 537,
    shape_dist_traveled: 39.0153,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040504,
    shape_pt_lon: -89.846102,
    shape_pt_sequence: 538,
    shape_dist_traveled: 39.0699,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040544,
    shape_pt_lon: -89.846076,
    shape_pt_sequence: 539,
    shape_dist_traveled: 39.0749,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.040889,
    shape_pt_lon: -89.845832,
    shape_pt_sequence: 540,
    shape_dist_traveled: 39.1197,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.041359,
    shape_pt_lon: -89.845456,
    shape_pt_sequence: 541,
    shape_dist_traveled: 39.1813,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.041663,
    shape_pt_lon: -89.845176,
    shape_pt_sequence: 542,
    shape_dist_traveled: 39.2249,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.042098,
    shape_pt_lon: -89.844735,
    shape_pt_sequence: 543,
    shape_dist_traveled: 39.2873,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04228,
    shape_pt_lon: -89.84454,
    shape_pt_sequence: 544,
    shape_dist_traveled: 39.3144,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04236,
    shape_pt_lon: -89.844456,
    shape_pt_sequence: 545,
    shape_dist_traveled: 39.3264,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04261,
    shape_pt_lon: -89.844203,
    shape_pt_sequence: 546,
    shape_dist_traveled: 39.3626,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.042863,
    shape_pt_lon: -89.84399,
    shape_pt_sequence: 547,
    shape_dist_traveled: 39.3965,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043097,
    shape_pt_lon: -89.84382,
    shape_pt_sequence: 548,
    shape_dist_traveled: 39.4265,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043307,
    shape_pt_lon: -89.843682,
    shape_pt_sequence: 549,
    shape_dist_traveled: 39.4533,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043354,
    shape_pt_lon: -89.843651,
    shape_pt_sequence: 550,
    shape_dist_traveled: 39.4592,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043406,
    shape_pt_lon: -89.843621,
    shape_pt_sequence: 551,
    shape_dist_traveled: 39.4659,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.043827,
    shape_pt_lon: -89.843403,
    shape_pt_sequence: 552,
    shape_dist_traveled: 39.5169,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04426,
    shape_pt_lon: -89.84324,
    shape_pt_sequence: 553,
    shape_dist_traveled: 39.5679,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.044671,
    shape_pt_lon: -89.843132,
    shape_pt_sequence: 554,
    shape_dist_traveled: 39.615,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045019,
    shape_pt_lon: -89.843079,
    shape_pt_sequence: 555,
    shape_dist_traveled: 39.6533,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045277,
    shape_pt_lon: -89.843062,
    shape_pt_sequence: 556,
    shape_dist_traveled: 39.6823,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045512,
    shape_pt_lon: -89.843057,
    shape_pt_sequence: 557,
    shape_dist_traveled: 39.7093,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.045929,
    shape_pt_lon: -89.843082,
    shape_pt_sequence: 558,
    shape_dist_traveled: 39.7554,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.046232,
    shape_pt_lon: -89.843132,
    shape_pt_sequence: 559,
    shape_dist_traveled: 39.7897,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.046426,
    shape_pt_lon: -89.843181,
    shape_pt_sequence: 560,
    shape_dist_traveled: 39.8121,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.046541,
    shape_pt_lon: -89.84321,
    shape_pt_sequence: 561,
    shape_dist_traveled: 39.8254,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.047031,
    shape_pt_lon: -89.843383,
    shape_pt_sequence: 562,
    shape_dist_traveled: 39.8824,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04748,
    shape_pt_lon: -89.843567,
    shape_pt_sequence: 563,
    shape_dist_traveled: 39.9353,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.047793,
    shape_pt_lon: -89.843649,
    shape_pt_sequence: 564,
    shape_dist_traveled: 39.9712,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.048103,
    shape_pt_lon: -89.843702,
    shape_pt_sequence: 565,
    shape_dist_traveled: 40.0055,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.048546,
    shape_pt_lon: -89.843711,
    shape_pt_sequence: 566,
    shape_dist_traveled: 40.0555,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.843729,
    shape_pt_sequence: 567,
    shape_dist_traveled: 40.1305,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049318,
    shape_pt_lon: -89.843802,
    shape_pt_sequence: 568,
    shape_dist_traveled: 40.1436,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 569,
    shape_dist_traveled: 40.1656,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 570,
    shape_dist_traveled: 40.1766,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 571,
    shape_dist_traveled: 40.3156,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 572,
    shape_dist_traveled: 40.4327,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 573,
    shape_dist_traveled: 40.5227,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 574,
    shape_dist_traveled: 40.6257,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 575,
    shape_dist_traveled: 40.7688,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 576,
    shape_dist_traveled: 41.12,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 577,
    shape_dist_traveled: 41.21,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 578,
    shape_dist_traveled: 41.4061,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 579,
    shape_dist_traveled: 41.4141,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 580,
    shape_dist_traveled: 41.4221,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.0493,
    shape_pt_lon: -89.829894,
    shape_pt_sequence: 581,
    shape_dist_traveled: 41.4352,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05084,
    shape_pt_lon: -89.829657,
    shape_pt_sequence: 582,
    shape_dist_traveled: 41.6095,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.052212,
    shape_pt_lon: -89.829437,
    shape_pt_sequence: 583,
    shape_dist_traveled: 41.7638,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.052081,
    shape_pt_lon: -89.827372,
    shape_pt_sequence: 584,
    shape_dist_traveled: 41.9503,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.052028,
    shape_pt_lon: -89.826076,
    shape_pt_sequence: 585,
    shape_dist_traveled: 42.0684,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.052019,
    shape_pt_lon: -89.825732,
    shape_pt_sequence: 586,
    shape_dist_traveled: 42.0994,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051992,
    shape_pt_lon: -89.825131,
    shape_pt_sequence: 587,
    shape_dist_traveled: 42.1535,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05194,
    shape_pt_lon: -89.824839,
    shape_pt_sequence: 588,
    shape_dist_traveled: 42.1812,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.824589,
    shape_pt_sequence: 589,
    shape_dist_traveled: 42.2046,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051742,
    shape_pt_lon: -89.824274,
    shape_pt_sequence: 590,
    shape_dist_traveled: 42.2368,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051615,
    shape_pt_lon: -89.824058,
    shape_pt_sequence: 591,
    shape_dist_traveled: 42.2604,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051439,
    shape_pt_lon: -89.823819,
    shape_pt_sequence: 592,
    shape_dist_traveled: 42.2901,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051261,
    shape_pt_lon: -89.823632,
    shape_pt_sequence: 593,
    shape_dist_traveled: 42.3164,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.051016,
    shape_pt_lon: -89.823448,
    shape_pt_sequence: 594,
    shape_dist_traveled: 42.3483,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.05079,
    shape_pt_lon: -89.823331,
    shape_pt_sequence: 595,
    shape_dist_traveled: 42.3752,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.050497,
    shape_pt_lon: -89.823238,
    shape_pt_sequence: 596,
    shape_dist_traveled: 42.4094,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.050229,
    shape_pt_lon: -89.8232,
    shape_pt_sequence: 597,
    shape_dist_traveled: 42.4396,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.050006,
    shape_pt_lon: -89.823203,
    shape_pt_sequence: 598,
    shape_dist_traveled: 42.4646,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.04905,
    shape_pt_lon: -89.823357,
    shape_pt_sequence: 599,
    shape_dist_traveled: 42.5723,
  },
  {
    shape_id: 38210,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 600,
    shape_dist_traveled: 42.5875,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064758,
    shape_pt_lon: -90.058849,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064738,
    shape_pt_lon: -90.057628,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.111,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064718,
    shape_pt_lon: -90.05662,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2021,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.063045,
    shape_pt_lon: -90.056698,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.3892,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062966,
    shape_pt_lon: -90.053281,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.6983,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062931,
    shape_pt_lon: -90.051918,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.8214,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06293,
    shape_pt_lon: -90.051829,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.8294,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062929,
    shape_pt_lon: -90.051739,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.8374,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062904,
    shape_pt_lon: -90.050603,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.9404,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06289,
    shape_pt_lon: -90.049862,
    shape_pt_sequence: 10,
    shape_dist_traveled: 1.0074,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06288,
    shape_pt_lon: -90.049552,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.0355,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062858,
    shape_pt_lon: -90.049269,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.0616,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -90.049039,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.0828,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062775,
    shape_pt_lon: -90.048632,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.1203,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062623,
    shape_pt_lon: -90.047658,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.21,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062426,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.33,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062113,
    shape_pt_lon: -90.044343,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.5143,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062055,
    shape_pt_lon: -90.04382,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.5628,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.062016,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.618,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061999,
    shape_pt_lon: -90.042573,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.676,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061956,
    shape_pt_lon: -90.040818,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.8341,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -90.038494,
    shape_pt_sequence: 22,
    shape_dist_traveled: 2.0442,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.2723,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061804,
    shape_pt_lon: -90.034866,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.3733,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061758,
    shape_pt_lon: -90.033019,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.5404,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061739,
    shape_pt_lon: -90.032218,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.6125,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061698,
    shape_pt_lon: -90.030753,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.7455,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -90.029399,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.8676,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061643,
    shape_pt_lon: -90.028518,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.9476,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061632,
    shape_pt_lon: -90.028151,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.9806,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061618,
    shape_pt_lon: -90.027535,
    shape_pt_sequence: 31,
    shape_dist_traveled: 3.0367,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061579,
    shape_pt_lon: -90.025923,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.1817,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061594,
    shape_pt_lon: -90.025482,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.2218,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061679,
    shape_pt_lon: -90.024789,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.2854,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061691,
    shape_pt_lon: -90.024712,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.2925,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061717,
    shape_pt_lon: -90.024418,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.3197,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061726,
    shape_pt_lon: -90.024207,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.3387,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061721,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.3597,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061705,
    shape_pt_lon: -90.023671,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.3868,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061699,
    shape_pt_lon: -90.0236,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.3938,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.3978,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061689,
    shape_pt_lon: -90.023451,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.4069,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06163,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.4861,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.6066,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061527,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.6137,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06148,
    shape_pt_lon: -90.020652,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.661,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061467,
    shape_pt_lon: -90.020286,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.694,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061451,
    shape_pt_lon: -90.019991,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.721,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061419,
    shape_pt_lon: -90.01949,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.7662,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061403,
    shape_pt_lon: -90.019237,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.7893,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061356,
    shape_pt_lon: -90.018053,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.8964,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061339,
    shape_pt_lon: -90.01756,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.9415,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061293,
    shape_pt_lon: -90.01599,
    shape_pt_sequence: 53,
    shape_dist_traveled: 4.0836,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061272,
    shape_pt_lon: -90.015226,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.1526,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061231,
    shape_pt_lon: -90.013952,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.2677,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06122,
    shape_pt_lon: -90.013628,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.2977,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.0612,
    shape_pt_lon: -90.012873,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.3657,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061192,
    shape_pt_lon: -90.012536,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.3958,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061179,
    shape_pt_lon: -90.011738,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.4688,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061151,
    shape_pt_lon: -90.01104,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.5318,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061123,
    shape_pt_lon: -90.010033,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.6229,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061102,
    shape_pt_lon: -90.009289,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.69,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061051,
    shape_pt_lon: -90.007693,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.834,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061039,
    shape_pt_lon: -90.007298,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.8701,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061037,
    shape_pt_lon: -90.007223,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.8771,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061035,
    shape_pt_lon: -90.007162,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.8821,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -90.004151,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.1553,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06093,
    shape_pt_lon: -90.003676,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.1983,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06089,
    shape_pt_lon: -90.002047,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.3454,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060878,
    shape_pt_lon: -90.001628,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.3834,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060842,
    shape_pt_lon: -90.000408,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.4935,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06081,
    shape_pt_lon: -89.999536,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.5726,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060777,
    shape_pt_lon: -89.998354,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.6796,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.99807,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.7056,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060741,
    shape_pt_lon: -89.99722,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.7827,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.9848,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 77,
    shape_dist_traveled: 6.0229,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 78,
    shape_dist_traveled: 6.0799,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.156,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.2906,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.4077,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.4297,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.4808,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.5025,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.5209,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.5345,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.5529,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.5725,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.589,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.612,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.636,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.7121,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.7691,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.8072,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.8132,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.9652,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.1343,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.2443,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.2645,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.2848,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.3,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.3164,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.3371,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.3585,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.3775,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.3956,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.4106,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.4512,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.4784,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.5471,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.5753,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.6048,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.7177,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.7253,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.7347,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.818,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.1429,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.2033,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.2511,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.2696,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.2876,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.3183,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.3703,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3763,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.3908,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4018,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4078,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.4218,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.4658,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068759,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.5529,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068763,
    shape_pt_lon: -89.984272,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5649,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.983438,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.6399,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068779,
    shape_pt_lon: -89.983286,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.6539,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068811,
    shape_pt_lon: -89.982129,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.759,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068827,
    shape_pt_lon: -89.981701,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.797,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068833,
    shape_pt_lon: -89.981153,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.847,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980607,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.8961,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980318,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.9231,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068961,
    shape_pt_lon: -89.975041,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.4002,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.973159,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.5703,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069079,
    shape_pt_lon: -89.969853,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.8695,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069099,
    shape_pt_lon: -89.968477,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.9935,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069093,
    shape_pt_lon: -89.967908,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.0455,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06909,
    shape_pt_lon: -89.966763,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.1485,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069088,
    shape_pt_lon: -89.966092,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.2095,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069087,
    shape_pt_lon: -89.965871,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.2295,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.965049,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.3035,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069038,
    shape_pt_lon: -89.964462,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.3576,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069012,
    shape_pt_lon: -89.964132,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.3868,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069011,
    shape_pt_lon: -89.964075,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.3928,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068992,
    shape_pt_lon: -89.963804,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.4169,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06899,
    shape_pt_lon: -89.963668,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.4289,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068981,
    shape_pt_lon: -89.963304,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.4619,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068955,
    shape_pt_lon: -89.962088,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.5719,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068951,
    shape_pt_lon: -89.961978,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.582,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068929,
    shape_pt_lon: -89.961096,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.662,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068899,
    shape_pt_lon: -89.95991,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.7691,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068898,
    shape_pt_lon: -89.959817,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.7781,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.958461,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.9001,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068782,
    shape_pt_lon: -89.955203,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.1953,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068773,
    shape_pt_lon: -89.95478,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.2333,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068753,
    shape_pt_lon: -89.9543,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.2773,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068743,
    shape_pt_lon: -89.954207,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.2854,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.068859,
    shape_pt_lon: -89.954229,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.2985,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.06918,
    shape_pt_lon: -89.954286,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.3349,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.069328,
    shape_pt_lon: -89.954309,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.351,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.072248,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.6807,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.072429,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.7008,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.072486,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.7079,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.072802,
    shape_pt_lon: -89.954828,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.743,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.072893,
    shape_pt_lon: -89.954822,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.753,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.073257,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.7943,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.073675,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.8429,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.8657,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.074048,
    shape_pt_lon: -89.954422,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.8881,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.074386,
    shape_pt_lon: -89.954148,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.9336,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.074464,
    shape_pt_lon: -89.954062,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.945,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.074843,
    shape_pt_lon: -89.95362,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.003,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.075379,
    shape_pt_lon: -89.952992,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.0857,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.075925,
    shape_pt_lon: -89.952326,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.172,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.951963,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.2153,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076279,
    shape_pt_lon: -89.951763,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.2364,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076427,
    shape_pt_lon: -89.951466,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.2683,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.301,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.3256,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.3509,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.3696,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.3987,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.4597,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.4778,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.5189,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.0239,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.1371,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.1842,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.8791,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.9291,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.9381,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.944,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.9525,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.9655,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 201,
    shape_dist_traveled: 14.0266,
  },
  {
    shape_id: 38211,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.0324,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1061,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2242,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.3404,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.4766,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.5927,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.6007,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.6087,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049088,
    shape_pt_lon: -89.830107,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.6187,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.047242,
    shape_pt_lon: -89.830391,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.8272,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.045682,
    shape_pt_lon: -89.830641,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.0036,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.044234,
    shape_pt_lon: -89.830866,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.1668,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.042769,
    shape_pt_lon: -89.831093,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.3321,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.041251,
    shape_pt_lon: -89.831326,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.5024,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.040868,
    shape_pt_lon: -89.831366,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.5455,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0401,
    shape_pt_lon: -89.83141,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.6316,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.03851,
    shape_pt_lon: -89.831419,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.8096,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.037456,
    shape_pt_lon: -89.831417,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.9276,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.034973,
    shape_pt_lon: -89.831414,
    shape_pt_sequence: 19,
    shape_dist_traveled: 2.2056,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.034322,
    shape_pt_lon: -89.831413,
    shape_pt_sequence: 20,
    shape_dist_traveled: 2.2786,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.03261,
    shape_pt_lon: -89.831417,
    shape_pt_sequence: 21,
    shape_dist_traveled: 2.4696,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.032586,
    shape_pt_lon: -89.831417,
    shape_pt_sequence: 22,
    shape_dist_traveled: 2.4726,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.032106,
    shape_pt_lon: -89.831421,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.5266,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.03096,
    shape_pt_lon: -89.831405,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.6546,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.03041,
    shape_pt_lon: -89.831409,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.7156,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.029312,
    shape_pt_lon: -89.8314,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.8386,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.028896,
    shape_pt_lon: -89.831396,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.8856,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.027672,
    shape_pt_lon: -89.83139,
    shape_pt_sequence: 28,
    shape_dist_traveled: 3.0226,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.027173,
    shape_pt_lon: -89.831388,
    shape_pt_sequence: 29,
    shape_dist_traveled: 3.0786,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02518,
    shape_pt_lon: -89.831367,
    shape_pt_sequence: 30,
    shape_dist_traveled: 3.3017,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02492,
    shape_pt_lon: -89.831365,
    shape_pt_sequence: 31,
    shape_dist_traveled: 3.3307,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.024641,
    shape_pt_lon: -89.831362,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.3617,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.024339,
    shape_pt_lon: -89.83136,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.3957,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.023942,
    shape_pt_lon: -89.831357,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.4397,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.023599,
    shape_pt_lon: -89.831354,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.4787,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.023112,
    shape_pt_lon: -89.83135,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.5327,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02236,
    shape_pt_lon: -89.831346,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.6167,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.022239,
    shape_pt_lon: -89.831345,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.6307,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020541,
    shape_pt_lon: -89.831327,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.8207,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02042,
    shape_pt_lon: -89.831326,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.8337,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020389,
    shape_pt_lon: -89.831326,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.8377,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020383,
    shape_pt_lon: -89.83256,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.9497,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.833046,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.9937,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.834467,
    shape_pt_sequence: 44,
    shape_dist_traveled: 4.1227,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.835831,
    shape_pt_sequence: 45,
    shape_dist_traveled: 4.2457,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.838762,
    shape_pt_sequence: 46,
    shape_dist_traveled: 4.5107,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.839877,
    shape_pt_sequence: 47,
    shape_dist_traveled: 4.6117,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.84246,
    shape_pt_sequence: 48,
    shape_dist_traveled: 4.8457,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.842893,
    shape_pt_sequence: 49,
    shape_dist_traveled: 4.8847,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.844031,
    shape_pt_sequence: 50,
    shape_dist_traveled: 4.9877,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020382,
    shape_pt_lon: -89.844767,
    shape_pt_sequence: 51,
    shape_dist_traveled: 5.0547,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020384,
    shape_pt_lon: -89.845459,
    shape_pt_sequence: 52,
    shape_dist_traveled: 5.1167,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0204,
    shape_pt_lon: -89.846984,
    shape_pt_sequence: 53,
    shape_dist_traveled: 5.2547,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020399,
    shape_pt_lon: -89.84868,
    shape_pt_sequence: 54,
    shape_dist_traveled: 5.4087,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020383,
    shape_pt_lon: -89.852447,
    shape_pt_sequence: 55,
    shape_dist_traveled: 5.7497,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020381,
    shape_pt_lon: -89.852845,
    shape_pt_sequence: 56,
    shape_dist_traveled: 5.7857,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020379,
    shape_pt_lon: -89.853532,
    shape_pt_sequence: 57,
    shape_dist_traveled: 5.8478,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020369,
    shape_pt_lon: -89.855121,
    shape_pt_sequence: 58,
    shape_dist_traveled: 5.9918,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020358,
    shape_pt_lon: -89.857329,
    shape_pt_sequence: 59,
    shape_dist_traveled: 6.1908,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859229,
    shape_pt_sequence: 60,
    shape_dist_traveled: 6.3628,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.859638,
    shape_pt_sequence: 61,
    shape_dist_traveled: 6.3998,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020354,
    shape_pt_lon: -89.863546,
    shape_pt_sequence: 62,
    shape_dist_traveled: 6.7538,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020348,
    shape_pt_lon: -89.865158,
    shape_pt_sequence: 63,
    shape_dist_traveled: 6.8998,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020357,
    shape_pt_lon: -89.866029,
    shape_pt_sequence: 64,
    shape_dist_traveled: 6.9788,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020413,
    shape_pt_lon: -89.866158,
    shape_pt_sequence: 65,
    shape_dist_traveled: 6.9918,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866238,
    shape_pt_sequence: 66,
    shape_dist_traveled: 6.9998,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866357,
    shape_pt_sequence: 67,
    shape_dist_traveled: 7.0098,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020433,
    shape_pt_lon: -89.867187,
    shape_pt_sequence: 68,
    shape_dist_traveled: 7.0858,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020448,
    shape_pt_lon: -89.868539,
    shape_pt_sequence: 69,
    shape_dist_traveled: 7.2078,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020453,
    shape_pt_lon: -89.869939,
    shape_pt_sequence: 70,
    shape_dist_traveled: 7.3348,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020468,
    shape_pt_lon: -89.871278,
    shape_pt_sequence: 71,
    shape_dist_traveled: 7.4559,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020479,
    shape_pt_lon: -89.872996,
    shape_pt_sequence: 72,
    shape_dist_traveled: 7.6109,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020489,
    shape_pt_lon: -89.874195,
    shape_pt_sequence: 73,
    shape_dist_traveled: 7.7199,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020501,
    shape_pt_lon: -89.875019,
    shape_pt_sequence: 74,
    shape_dist_traveled: 7.7939,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020502,
    shape_pt_lon: -89.876066,
    shape_pt_sequence: 75,
    shape_dist_traveled: 7.8889,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020492,
    shape_pt_lon: -89.8769,
    shape_pt_sequence: 76,
    shape_dist_traveled: 7.9649,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020498,
    shape_pt_lon: -89.877438,
    shape_pt_sequence: 77,
    shape_dist_traveled: 8.0129,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.878476,
    shape_pt_sequence: 78,
    shape_dist_traveled: 8.1069,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.879969,
    shape_pt_sequence: 79,
    shape_dist_traveled: 8.2419,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.879998,
    shape_pt_sequence: 80,
    shape_dist_traveled: 8.2449,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880026,
    shape_pt_sequence: 81,
    shape_dist_traveled: 8.2469,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880047,
    shape_pt_sequence: 82,
    shape_dist_traveled: 8.2489,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880071,
    shape_pt_sequence: 83,
    shape_dist_traveled: 8.2509,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880099,
    shape_pt_sequence: 84,
    shape_dist_traveled: 8.2539,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0205,
    shape_pt_lon: -89.880174,
    shape_pt_sequence: 85,
    shape_dist_traveled: 8.2609,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020493,
    shape_pt_lon: -89.881984,
    shape_pt_sequence: 86,
    shape_dist_traveled: 8.4249,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020492,
    shape_pt_lon: -89.882181,
    shape_pt_sequence: 87,
    shape_dist_traveled: 8.4419,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020491,
    shape_pt_lon: -89.882972,
    shape_pt_sequence: 88,
    shape_dist_traveled: 8.5139,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.884308,
    shape_pt_sequence: 89,
    shape_dist_traveled: 8.6349,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020507,
    shape_pt_lon: -89.88445,
    shape_pt_sequence: 90,
    shape_dist_traveled: 8.6479,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020526,
    shape_pt_lon: -89.886751,
    shape_pt_sequence: 91,
    shape_dist_traveled: 8.8559,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020535,
    shape_pt_lon: -89.888839,
    shape_pt_sequence: 92,
    shape_dist_traveled: 9.0449,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020546,
    shape_pt_lon: -89.889701,
    shape_pt_sequence: 93,
    shape_dist_traveled: 9.1229,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020542,
    shape_pt_lon: -89.89057,
    shape_pt_sequence: 94,
    shape_dist_traveled: 9.2019,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020543,
    shape_pt_lon: -89.890641,
    shape_pt_sequence: 95,
    shape_dist_traveled: 9.2079,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020549,
    shape_pt_lon: -89.891089,
    shape_pt_sequence: 96,
    shape_dist_traveled: 9.2489,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020549,
    shape_pt_lon: -89.891118,
    shape_pt_sequence: 97,
    shape_dist_traveled: 9.2509,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020559,
    shape_pt_lon: -89.891753,
    shape_pt_sequence: 98,
    shape_dist_traveled: 9.3089,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020569,
    shape_pt_lon: -89.893117,
    shape_pt_sequence: 99,
    shape_dist_traveled: 9.4319,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020575,
    shape_pt_lon: -89.895639,
    shape_pt_sequence: 100,
    shape_dist_traveled: 9.6599,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020575,
    shape_pt_lon: -89.896052,
    shape_pt_sequence: 101,
    shape_dist_traveled: 9.698,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020593,
    shape_pt_lon: -89.897279,
    shape_pt_sequence: 102,
    shape_dist_traveled: 9.809,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.898114,
    shape_pt_sequence: 103,
    shape_dist_traveled: 9.884,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020611,
    shape_pt_lon: -89.898226,
    shape_pt_sequence: 104,
    shape_dist_traveled: 9.894,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02061,
    shape_pt_lon: -89.898429,
    shape_pt_sequence: 105,
    shape_dist_traveled: 9.913,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020606,
    shape_pt_lon: -89.898952,
    shape_pt_sequence: 106,
    shape_dist_traveled: 9.96,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020609,
    shape_pt_lon: -89.900039,
    shape_pt_sequence: 107,
    shape_dist_traveled: 10.059,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020602,
    shape_pt_lon: -89.900498,
    shape_pt_sequence: 108,
    shape_dist_traveled: 10.1,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020607,
    shape_pt_lon: -89.900768,
    shape_pt_sequence: 109,
    shape_dist_traveled: 10.124,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020611,
    shape_pt_lon: -89.901336,
    shape_pt_sequence: 110,
    shape_dist_traveled: 10.176,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.90159,
    shape_pt_sequence: 111,
    shape_dist_traveled: 10.199,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020612,
    shape_pt_lon: -89.901661,
    shape_pt_sequence: 112,
    shape_dist_traveled: 10.205,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020619,
    shape_pt_lon: -89.903125,
    shape_pt_sequence: 113,
    shape_dist_traveled: 10.338,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020613,
    shape_pt_lon: -89.904618,
    shape_pt_sequence: 114,
    shape_dist_traveled: 10.473,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020629,
    shape_pt_lon: -89.906868,
    shape_pt_sequence: 115,
    shape_dist_traveled: 10.6771,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020639,
    shape_pt_lon: -89.909091,
    shape_pt_sequence: 116,
    shape_dist_traveled: 10.8781,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020647,
    shape_pt_lon: -89.911331,
    shape_pt_sequence: 117,
    shape_dist_traveled: 11.0801,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020648,
    shape_pt_lon: -89.911413,
    shape_pt_sequence: 118,
    shape_dist_traveled: 11.0881,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020649,
    shape_pt_lon: -89.91149,
    shape_pt_sequence: 119,
    shape_dist_traveled: 11.0951,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020652,
    shape_pt_lon: -89.913666,
    shape_pt_sequence: 120,
    shape_dist_traveled: 11.2921,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020661,
    shape_pt_lon: -89.914472,
    shape_pt_sequence: 121,
    shape_dist_traveled: 11.3651,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02067,
    shape_pt_lon: -89.916207,
    shape_pt_sequence: 122,
    shape_dist_traveled: 11.5221,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.916393,
    shape_pt_sequence: 123,
    shape_dist_traveled: 11.5381,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020675,
    shape_pt_lon: -89.916827,
    shape_pt_sequence: 124,
    shape_dist_traveled: 11.5781,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020686,
    shape_pt_lon: -89.919056,
    shape_pt_sequence: 125,
    shape_dist_traveled: 11.7791,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.920732,
    shape_pt_sequence: 126,
    shape_dist_traveled: 11.9311,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.923524,
    shape_pt_sequence: 127,
    shape_dist_traveled: 12.1841,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020687,
    shape_pt_lon: -89.924789,
    shape_pt_sequence: 128,
    shape_dist_traveled: 12.2981,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020684,
    shape_pt_lon: -89.926384,
    shape_pt_sequence: 129,
    shape_dist_traveled: 12.4431,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020688,
    shape_pt_lon: -89.927984,
    shape_pt_sequence: 130,
    shape_dist_traveled: 12.5871,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020688,
    shape_pt_lon: -89.92991,
    shape_pt_sequence: 131,
    shape_dist_traveled: 12.7621,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.930379,
    shape_pt_sequence: 132,
    shape_dist_traveled: 12.8041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02069,
    shape_pt_lon: -89.930714,
    shape_pt_sequence: 133,
    shape_dist_traveled: 12.8341,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020692,
    shape_pt_lon: -89.93125,
    shape_pt_sequence: 134,
    shape_dist_traveled: 12.8831,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020707,
    shape_pt_lon: -89.935942,
    shape_pt_sequence: 135,
    shape_dist_traveled: 13.3081,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0207,
    shape_pt_lon: -89.93661,
    shape_pt_sequence: 136,
    shape_dist_traveled: 13.3681,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02071,
    shape_pt_lon: -89.936884,
    shape_pt_sequence: 137,
    shape_dist_traveled: 13.3931,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020728,
    shape_pt_lon: -89.939932,
    shape_pt_sequence: 138,
    shape_dist_traveled: 13.6691,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02074,
    shape_pt_lon: -89.94227,
    shape_pt_sequence: 139,
    shape_dist_traveled: 13.8801,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020755,
    shape_pt_lon: -89.944539,
    shape_pt_sequence: 140,
    shape_dist_traveled: 14.0861,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02076,
    shape_pt_lon: -89.944989,
    shape_pt_sequence: 141,
    shape_dist_traveled: 14.1261,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020766,
    shape_pt_lon: -89.946163,
    shape_pt_sequence: 142,
    shape_dist_traveled: 14.2331,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020776,
    shape_pt_lon: -89.947653,
    shape_pt_sequence: 143,
    shape_dist_traveled: 14.3671,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020781,
    shape_pt_lon: -89.949326,
    shape_pt_sequence: 144,
    shape_dist_traveled: 14.5191,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020787,
    shape_pt_lon: -89.950091,
    shape_pt_sequence: 145,
    shape_dist_traveled: 14.5881,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020788,
    shape_pt_lon: -89.951002,
    shape_pt_sequence: 146,
    shape_dist_traveled: 14.6701,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020803,
    shape_pt_lon: -89.952477,
    shape_pt_sequence: 147,
    shape_dist_traveled: 14.8041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020812,
    shape_pt_lon: -89.954174,
    shape_pt_sequence: 148,
    shape_dist_traveled: 14.9571,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020803,
    shape_pt_lon: -89.954218,
    shape_pt_sequence: 149,
    shape_dist_traveled: 14.9613,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020749,
    shape_pt_lon: -89.954332,
    shape_pt_sequence: 150,
    shape_dist_traveled: 14.9738,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 151,
    shape_dist_traveled: 14.9958,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020786,
    shape_pt_lon: -89.957237,
    shape_pt_sequence: 152,
    shape_dist_traveled: 15.2368,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02079,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 153,
    shape_dist_traveled: 15.2848,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020803,
    shape_pt_lon: -89.959407,
    shape_pt_sequence: 154,
    shape_dist_traveled: 15.4328,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.02084,
    shape_pt_lon: -89.962951,
    shape_pt_sequence: 155,
    shape_dist_traveled: 15.7539,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020845,
    shape_pt_lon: -89.963647,
    shape_pt_sequence: 156,
    shape_dist_traveled: 15.8169,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.020936,
    shape_pt_lon: -89.96365,
    shape_pt_sequence: 157,
    shape_dist_traveled: 15.8269,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.022849,
    shape_pt_lon: -89.96371,
    shape_pt_sequence: 158,
    shape_dist_traveled: 16.041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.023163,
    shape_pt_lon: -89.963719,
    shape_pt_sequence: 159,
    shape_dist_traveled: 16.076,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.024553,
    shape_pt_lon: -89.963758,
    shape_pt_sequence: 160,
    shape_dist_traveled: 16.232,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.024635,
    shape_pt_lon: -89.963756,
    shape_pt_sequence: 161,
    shape_dist_traveled: 16.241,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.025488,
    shape_pt_lon: -89.963699,
    shape_pt_sequence: 162,
    shape_dist_traveled: 16.3361,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.026329,
    shape_pt_lon: -89.963652,
    shape_pt_sequence: 163,
    shape_dist_traveled: 16.4302,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.026573,
    shape_pt_lon: -89.963659,
    shape_pt_sequence: 164,
    shape_dist_traveled: 16.4582,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.027169,
    shape_pt_lon: -89.963669,
    shape_pt_sequence: 165,
    shape_dist_traveled: 16.5242,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.028003,
    shape_pt_lon: -89.963693,
    shape_pt_sequence: 166,
    shape_dist_traveled: 16.6183,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0282,
    shape_pt_lon: -89.963698,
    shape_pt_sequence: 167,
    shape_dist_traveled: 16.6403,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.029357,
    shape_pt_lon: -89.96371,
    shape_pt_sequence: 168,
    shape_dist_traveled: 16.7693,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.030466,
    shape_pt_lon: -89.963717,
    shape_pt_sequence: 169,
    shape_dist_traveled: 16.8933,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.031978,
    shape_pt_lon: -89.963728,
    shape_pt_sequence: 170,
    shape_dist_traveled: 17.0623,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.034443,
    shape_pt_lon: -89.963736,
    shape_pt_sequence: 171,
    shape_dist_traveled: 17.3383,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.036019,
    shape_pt_lon: -89.963843,
    shape_pt_sequence: 172,
    shape_dist_traveled: 17.5145,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.036906,
    shape_pt_lon: -89.963904,
    shape_pt_sequence: 173,
    shape_dist_traveled: 17.6147,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.037218,
    shape_pt_lon: -89.963922,
    shape_pt_sequence: 174,
    shape_dist_traveled: 17.6497,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.037686,
    shape_pt_lon: -89.963948,
    shape_pt_sequence: 175,
    shape_dist_traveled: 17.7018,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.038955,
    shape_pt_lon: -89.963956,
    shape_pt_sequence: 176,
    shape_dist_traveled: 17.8438,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.04031,
    shape_pt_lon: -89.963956,
    shape_pt_sequence: 177,
    shape_dist_traveled: 17.9948,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.040567,
    shape_pt_lon: -89.963957,
    shape_pt_sequence: 178,
    shape_dist_traveled: 18.0238,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.041689,
    shape_pt_lon: -89.96396,
    shape_pt_sequence: 179,
    shape_dist_traveled: 18.1498,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.042169,
    shape_pt_lon: -89.963961,
    shape_pt_sequence: 180,
    shape_dist_traveled: 18.2028,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.043859,
    shape_pt_lon: -89.963967,
    shape_pt_sequence: 181,
    shape_dist_traveled: 18.3928,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.044304,
    shape_pt_lon: -89.963969,
    shape_pt_sequence: 182,
    shape_dist_traveled: 18.4418,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.044888,
    shape_pt_lon: -89.96397,
    shape_pt_sequence: 183,
    shape_dist_traveled: 18.5078,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.048089,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 184,
    shape_dist_traveled: 18.8658,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049944,
    shape_pt_lon: -89.963982,
    shape_pt_sequence: 185,
    shape_dist_traveled: 19.0728,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.049991,
    shape_pt_lon: -89.963982,
    shape_pt_sequence: 186,
    shape_dist_traveled: 19.0788,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051099,
    shape_pt_lon: -89.963989,
    shape_pt_sequence: 187,
    shape_dist_traveled: 19.2028,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051689,
    shape_pt_lon: -89.963983,
    shape_pt_sequence: 188,
    shape_dist_traveled: 19.2688,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052536,
    shape_pt_lon: -89.963986,
    shape_pt_sequence: 189,
    shape_dist_traveled: 19.3628,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05253,
    shape_pt_lon: -89.963778,
    shape_pt_sequence: 190,
    shape_dist_traveled: 19.3808,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.963048,
    shape_pt_sequence: 191,
    shape_dist_traveled: 19.4468,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05245,
    shape_pt_lon: -89.961156,
    shape_pt_sequence: 192,
    shape_dist_traveled: 19.619,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052413,
    shape_pt_lon: -89.960378,
    shape_pt_sequence: 193,
    shape_dist_traveled: 19.6891,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052393,
    shape_pt_lon: -89.959713,
    shape_pt_sequence: 194,
    shape_dist_traveled: 19.7491,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052401,
    shape_pt_lon: -89.959699,
    shape_pt_sequence: 195,
    shape_dist_traveled: 19.7505,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 196,
    shape_dist_traveled: 19.7622,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 197,
    shape_dist_traveled: 19.7702,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 198,
    shape_dist_traveled: 19.9273,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 199,
    shape_dist_traveled: 20.1384,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 200,
    shape_dist_traveled: 20.1955,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 201,
    shape_dist_traveled: 20.2815,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 202,
    shape_dist_traveled: 20.4796,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.949773,
    shape_pt_sequence: 203,
    shape_dist_traveled: 20.6506,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052203,
    shape_pt_lon: -89.94913,
    shape_pt_sequence: 204,
    shape_dist_traveled: 20.7096,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.948251,
    shape_pt_sequence: 205,
    shape_dist_traveled: 20.7886,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052153,
    shape_pt_lon: -89.946399,
    shape_pt_sequence: 206,
    shape_dist_traveled: 20.9567,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0521,
    shape_pt_lon: -89.944961,
    shape_pt_sequence: 207,
    shape_dist_traveled: 21.0868,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052043,
    shape_pt_lon: -89.943377,
    shape_pt_sequence: 208,
    shape_dist_traveled: 21.2299,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051961,
    shape_pt_lon: -89.940352,
    shape_pt_sequence: 209,
    shape_dist_traveled: 21.5041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.937152,
    shape_pt_sequence: 210,
    shape_dist_traveled: 21.7932,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051877,
    shape_pt_lon: -89.937071,
    shape_pt_sequence: 211,
    shape_dist_traveled: 21.8002,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051817,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 212,
    shape_dist_traveled: 21.8124,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051775,
    shape_pt_lon: -89.935403,
    shape_pt_sequence: 213,
    shape_dist_traveled: 21.9535,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051764,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 214,
    shape_dist_traveled: 22.0565,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05176,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 215,
    shape_dist_traveled: 22.0795,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051759,
    shape_pt_lon: -89.933918,
    shape_pt_sequence: 216,
    shape_dist_traveled: 22.0885,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 217,
    shape_dist_traveled: 22.1005,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.052017,
    shape_pt_lon: -89.933922,
    shape_pt_sequence: 218,
    shape_dist_traveled: 22.1175,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.053188,
    shape_pt_lon: -89.933938,
    shape_pt_sequence: 219,
    shape_dist_traveled: 22.2485,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05363,
    shape_pt_lon: -89.933901,
    shape_pt_sequence: 220,
    shape_dist_traveled: 22.2976,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.054053,
    shape_pt_lon: -89.933828,
    shape_pt_sequence: 221,
    shape_dist_traveled: 22.346,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.054357,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 222,
    shape_dist_traveled: 22.3807,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.054481,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 223,
    shape_dist_traveled: 22.3953,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05497,
    shape_pt_lon: -89.933527,
    shape_pt_sequence: 224,
    shape_dist_traveled: 22.4516,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.055188,
    shape_pt_lon: -89.933423,
    shape_pt_sequence: 225,
    shape_dist_traveled: 22.4785,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.055313,
    shape_pt_lon: -89.933358,
    shape_pt_sequence: 226,
    shape_dist_traveled: 22.4937,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0555,
    shape_pt_lon: -89.933261,
    shape_pt_sequence: 227,
    shape_dist_traveled: 22.5162,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.056072,
    shape_pt_lon: -89.932929,
    shape_pt_sequence: 228,
    shape_dist_traveled: 22.5869,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05679,
    shape_pt_lon: -89.932518,
    shape_pt_sequence: 229,
    shape_dist_traveled: 22.675,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.056932,
    shape_pt_lon: -89.932433,
    shape_pt_sequence: 230,
    shape_dist_traveled: 22.6929,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.057032,
    shape_pt_lon: -89.932373,
    shape_pt_sequence: 231,
    shape_dist_traveled: 22.7054,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.057089,
    shape_pt_lon: -89.93234,
    shape_pt_sequence: 232,
    shape_dist_traveled: 22.7131,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.057138,
    shape_pt_lon: -89.932313,
    shape_pt_sequence: 233,
    shape_dist_traveled: 22.7184,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05789,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 234,
    shape_dist_traveled: 22.8106,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.058198,
    shape_pt_lon: -89.931708,
    shape_pt_sequence: 235,
    shape_dist_traveled: 22.8491,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.058595,
    shape_pt_lon: -89.93147,
    shape_pt_sequence: 236,
    shape_dist_traveled: 22.8983,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05897,
    shape_pt_lon: -89.931251,
    shape_pt_sequence: 237,
    shape_dist_traveled: 22.9448,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.059103,
    shape_pt_lon: -89.931184,
    shape_pt_sequence: 238,
    shape_dist_traveled: 22.961,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.059289,
    shape_pt_lon: -89.931099,
    shape_pt_sequence: 239,
    shape_dist_traveled: 22.9831,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.05965,
    shape_pt_lon: -89.930978,
    shape_pt_sequence: 240,
    shape_dist_traveled: 23.0246,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06002,
    shape_pt_lon: -89.930914,
    shape_pt_sequence: 241,
    shape_dist_traveled: 23.067,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060297,
    shape_pt_lon: -89.930899,
    shape_pt_sequence: 242,
    shape_dist_traveled: 23.0981,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060377,
    shape_pt_lon: -89.930898,
    shape_pt_sequence: 243,
    shape_dist_traveled: 23.1071,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060845,
    shape_pt_lon: -89.930927,
    shape_pt_sequence: 244,
    shape_dist_traveled: 23.1591,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061082,
    shape_pt_lon: -89.930969,
    shape_pt_sequence: 245,
    shape_dist_traveled: 23.1854,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061463,
    shape_pt_lon: -89.93107,
    shape_pt_sequence: 246,
    shape_dist_traveled: 23.2293,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061775,
    shape_pt_lon: -89.931189,
    shape_pt_sequence: 247,
    shape_dist_traveled: 23.266,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06208,
    shape_pt_lon: -89.931346,
    shape_pt_sequence: 248,
    shape_dist_traveled: 23.3028,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063008,
    shape_pt_lon: -89.931907,
    shape_pt_sequence: 249,
    shape_dist_traveled: 23.4186,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063344,
    shape_pt_lon: -89.9321,
    shape_pt_sequence: 250,
    shape_dist_traveled: 23.4598,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063622,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 251,
    shape_dist_traveled: 23.4936,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063818,
    shape_pt_lon: -89.93228,
    shape_pt_sequence: 252,
    shape_dist_traveled: 23.5152,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064096,
    shape_pt_lon: -89.932338,
    shape_pt_sequence: 253,
    shape_dist_traveled: 23.5476,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064279,
    shape_pt_lon: -89.932352,
    shape_pt_sequence: 254,
    shape_dist_traveled: 23.5677,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064384,
    shape_pt_lon: -89.932352,
    shape_pt_sequence: 255,
    shape_dist_traveled: 23.5797,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064877,
    shape_pt_lon: -89.93233,
    shape_pt_sequence: 256,
    shape_dist_traveled: 23.6347,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 257,
    shape_dist_traveled: 23.6587,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.066376,
    shape_pt_lon: -89.932248,
    shape_pt_sequence: 258,
    shape_dist_traveled: 23.8029,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067282,
    shape_pt_lon: -89.932176,
    shape_pt_sequence: 259,
    shape_dist_traveled: 23.9041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067553,
    shape_pt_lon: -89.932161,
    shape_pt_sequence: 260,
    shape_dist_traveled: 23.9341,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06759,
    shape_pt_lon: -89.932188,
    shape_pt_sequence: 261,
    shape_dist_traveled: 23.9386,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 262,
    shape_dist_traveled: 23.9458,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 263,
    shape_dist_traveled: 23.9509,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 264,
    shape_dist_traveled: 23.9669,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 265,
    shape_dist_traveled: 23.9789,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 266,
    shape_dist_traveled: 24.022,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 267,
    shape_dist_traveled: 24.1151,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 268,
    shape_dist_traveled: 24.2132,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 269,
    shape_dist_traveled: 24.2382,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 270,
    shape_dist_traveled: 24.3273,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 271,
    shape_dist_traveled: 24.4055,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 272,
    shape_dist_traveled: 24.5137,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 273,
    shape_dist_traveled: 24.5137,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 274,
    shape_dist_traveled: 24.584,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 275,
    shape_dist_traveled: 24.7243,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 276,
    shape_dist_traveled: 24.929,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 277,
    shape_dist_traveled: 24.936,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 278,
    shape_dist_traveled: 24.9512,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 279,
    shape_dist_traveled: 25.0092,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 280,
    shape_dist_traveled: 25.0844,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 281,
    shape_dist_traveled: 25.0934,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 282,
    shape_dist_traveled: 25.0992,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 283,
    shape_dist_traveled: 25.1077,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 284,
    shape_dist_traveled: 25.1207,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 285,
    shape_dist_traveled: 25.1818,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 286,
    shape_dist_traveled: 25.1876,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 287,
    shape_dist_traveled: 25.2006,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 288,
    shape_dist_traveled: 25.2147,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 289,
    shape_dist_traveled: 25.2217,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 290,
    shape_dist_traveled: 25.2312,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 291,
    shape_dist_traveled: 25.2823,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 292,
    shape_dist_traveled: 25.2854,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 293,
    shape_dist_traveled: 25.2917,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 294,
    shape_dist_traveled: 25.2977,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 295,
    shape_dist_traveled: 25.3258,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 296,
    shape_dist_traveled: 25.3388,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 297,
    shape_dist_traveled: 25.3473,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 298,
    shape_dist_traveled: 25.3531,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 299,
    shape_dist_traveled: 25.3621,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 300,
    shape_dist_traveled: 25.4122,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 301,
    shape_dist_traveled: 26.1071,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 302,
    shape_dist_traveled: 26.1542,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 303,
    shape_dist_traveled: 26.2673,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 304,
    shape_dist_traveled: 26.7724,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 305,
    shape_dist_traveled: 26.8134,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 306,
    shape_dist_traveled: 26.8315,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 307,
    shape_dist_traveled: 26.8925,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 308,
    shape_dist_traveled: 26.9217,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 309,
    shape_dist_traveled: 26.9403,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 310,
    shape_dist_traveled: 26.9656,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 311,
    shape_dist_traveled: 26.9902,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076427,
    shape_pt_lon: -89.951466,
    shape_pt_sequence: 312,
    shape_dist_traveled: 27.023,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076279,
    shape_pt_lon: -89.951763,
    shape_pt_sequence: 313,
    shape_dist_traveled: 27.0549,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.951963,
    shape_pt_sequence: 314,
    shape_dist_traveled: 27.076,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075925,
    shape_pt_lon: -89.952326,
    shape_pt_sequence: 315,
    shape_dist_traveled: 27.1193,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.075379,
    shape_pt_lon: -89.952992,
    shape_pt_sequence: 316,
    shape_dist_traveled: 27.2056,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.074843,
    shape_pt_lon: -89.95362,
    shape_pt_sequence: 317,
    shape_dist_traveled: 27.2883,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.074464,
    shape_pt_lon: -89.954062,
    shape_pt_sequence: 318,
    shape_dist_traveled: 27.3463,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.074386,
    shape_pt_lon: -89.954148,
    shape_pt_sequence: 319,
    shape_dist_traveled: 27.3577,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.074048,
    shape_pt_lon: -89.954422,
    shape_pt_sequence: 320,
    shape_dist_traveled: 27.4032,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 321,
    shape_dist_traveled: 27.4256,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.073675,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 322,
    shape_dist_traveled: 27.4484,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.073257,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 323,
    shape_dist_traveled: 27.4969,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072893,
    shape_pt_lon: -89.954822,
    shape_pt_sequence: 324,
    shape_dist_traveled: 27.5382,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072802,
    shape_pt_lon: -89.954828,
    shape_pt_sequence: 325,
    shape_dist_traveled: 27.5483,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072486,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 326,
    shape_dist_traveled: 27.5834,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072429,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 327,
    shape_dist_traveled: 27.5905,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.072248,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 328,
    shape_dist_traveled: 27.6106,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069328,
    shape_pt_lon: -89.954309,
    shape_pt_sequence: 329,
    shape_dist_traveled: 27.9403,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06904,
    shape_pt_lon: -89.954436,
    shape_pt_sequence: 330,
    shape_dist_traveled: 27.9744,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068971,
    shape_pt_lon: -89.954489,
    shape_pt_sequence: 331,
    shape_dist_traveled: 27.9839,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068906,
    shape_pt_lon: -89.954568,
    shape_pt_sequence: 332,
    shape_dist_traveled: 27.9938,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 333,
    shape_dist_traveled: 28.0002,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068773,
    shape_pt_lon: -89.95478,
    shape_pt_sequence: 334,
    shape_dist_traveled: 28.018,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068782,
    shape_pt_lon: -89.955203,
    shape_pt_sequence: 335,
    shape_dist_traveled: 28.056,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.958461,
    shape_pt_sequence: 336,
    shape_dist_traveled: 28.3511,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068898,
    shape_pt_lon: -89.959817,
    shape_pt_sequence: 337,
    shape_dist_traveled: 28.4732,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068899,
    shape_pt_lon: -89.95991,
    shape_pt_sequence: 338,
    shape_dist_traveled: 28.4822,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068929,
    shape_pt_lon: -89.961096,
    shape_pt_sequence: 339,
    shape_dist_traveled: 28.5892,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068951,
    shape_pt_lon: -89.961978,
    shape_pt_sequence: 340,
    shape_dist_traveled: 28.6693,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068955,
    shape_pt_lon: -89.962088,
    shape_pt_sequence: 341,
    shape_dist_traveled: 28.6793,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068981,
    shape_pt_lon: -89.963304,
    shape_pt_sequence: 342,
    shape_dist_traveled: 28.7893,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06899,
    shape_pt_lon: -89.963668,
    shape_pt_sequence: 343,
    shape_dist_traveled: 28.8223,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068992,
    shape_pt_lon: -89.963804,
    shape_pt_sequence: 344,
    shape_dist_traveled: 28.8344,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069011,
    shape_pt_lon: -89.964075,
    shape_pt_sequence: 345,
    shape_dist_traveled: 28.8585,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069012,
    shape_pt_lon: -89.964132,
    shape_pt_sequence: 346,
    shape_dist_traveled: 28.8645,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069038,
    shape_pt_lon: -89.964462,
    shape_pt_sequence: 347,
    shape_dist_traveled: 28.8936,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.965049,
    shape_pt_sequence: 348,
    shape_dist_traveled: 28.9478,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069087,
    shape_pt_lon: -89.965871,
    shape_pt_sequence: 349,
    shape_dist_traveled: 29.0218,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069088,
    shape_pt_lon: -89.966092,
    shape_pt_sequence: 350,
    shape_dist_traveled: 29.0418,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06909,
    shape_pt_lon: -89.966763,
    shape_pt_sequence: 351,
    shape_dist_traveled: 29.1028,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069093,
    shape_pt_lon: -89.967908,
    shape_pt_sequence: 352,
    shape_dist_traveled: 29.2058,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069099,
    shape_pt_lon: -89.968477,
    shape_pt_sequence: 353,
    shape_dist_traveled: 29.2578,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069079,
    shape_pt_lon: -89.969853,
    shape_pt_sequence: 354,
    shape_dist_traveled: 29.3818,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.973159,
    shape_pt_sequence: 355,
    shape_dist_traveled: 29.6809,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068961,
    shape_pt_lon: -89.975041,
    shape_pt_sequence: 356,
    shape_dist_traveled: 29.851,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980318,
    shape_pt_sequence: 357,
    shape_dist_traveled: 30.3282,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980607,
    shape_pt_sequence: 358,
    shape_dist_traveled: 30.3552,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068833,
    shape_pt_lon: -89.981153,
    shape_pt_sequence: 359,
    shape_dist_traveled: 30.4042,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068827,
    shape_pt_lon: -89.981701,
    shape_pt_sequence: 360,
    shape_dist_traveled: 30.4542,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068811,
    shape_pt_lon: -89.982129,
    shape_pt_sequence: 361,
    shape_dist_traveled: 30.4923,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068779,
    shape_pt_lon: -89.983286,
    shape_pt_sequence: 362,
    shape_dist_traveled: 30.5974,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.983438,
    shape_pt_sequence: 363,
    shape_dist_traveled: 30.6114,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068763,
    shape_pt_lon: -89.984272,
    shape_pt_sequence: 364,
    shape_dist_traveled: 30.6864,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068759,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 365,
    shape_dist_traveled: 30.6984,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 366,
    shape_dist_traveled: 30.7854,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 367,
    shape_dist_traveled: 30.8294,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 368,
    shape_dist_traveled: 30.8434,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 369,
    shape_dist_traveled: 30.8495,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 370,
    shape_dist_traveled: 30.8605,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068771,
    shape_pt_lon: -89.986323,
    shape_pt_sequence: 371,
    shape_dist_traveled: 30.8735,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068771,
    shape_pt_lon: -89.98639,
    shape_pt_sequence: 372,
    shape_dist_traveled: 30.8795,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.986933,
    shape_pt_sequence: 373,
    shape_dist_traveled: 30.9285,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068769,
    shape_pt_lon: -89.987349,
    shape_pt_sequence: 374,
    shape_dist_traveled: 30.9655,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 375,
    shape_dist_traveled: 30.9816,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068713,
    shape_pt_lon: -89.987548,
    shape_pt_sequence: 376,
    shape_dist_traveled: 30.9879,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068657,
    shape_pt_lon: -89.987588,
    shape_pt_sequence: 377,
    shape_dist_traveled: 30.9951,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987664,
    shape_pt_sequence: 378,
    shape_dist_traveled: 31.009,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 379,
    shape_dist_traveled: 31.0345,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 380,
    shape_dist_traveled: 31.1338,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 381,
    shape_dist_traveled: 31.4579,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 382,
    shape_dist_traveled: 31.5425,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 383,
    shape_dist_traveled: 31.5944,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 384,
    shape_dist_traveled: 31.644,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 385,
    shape_dist_traveled: 31.646,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 386,
    shape_dist_traveled: 31.6742,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 387,
    shape_dist_traveled: 31.7217,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 388,
    shape_dist_traveled: 31.775,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 389,
    shape_dist_traveled: 31.8088,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 390,
    shape_dist_traveled: 31.8522,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 391,
    shape_dist_traveled: 31.8742,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 392,
    shape_dist_traveled: 31.9253,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 393,
    shape_dist_traveled: 31.9469,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 394,
    shape_dist_traveled: 31.9654,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 395,
    shape_dist_traveled: 31.979,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 396,
    shape_dist_traveled: 31.9974,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 397,
    shape_dist_traveled: 32.017,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 398,
    shape_dist_traveled: 32.0335,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 399,
    shape_dist_traveled: 32.0565,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 400,
    shape_dist_traveled: 32.0805,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 401,
    shape_dist_traveled: 32.2152,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 402,
    shape_dist_traveled: 32.3323,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 403,
    shape_dist_traveled: 32.3543,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 404,
    shape_dist_traveled: 32.4054,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 405,
    shape_dist_traveled: 32.427,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 406,
    shape_dist_traveled: 32.4455,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 407,
    shape_dist_traveled: 32.4591,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 408,
    shape_dist_traveled: 32.4774,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 409,
    shape_dist_traveled: 32.4971,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 410,
    shape_dist_traveled: 32.5136,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 411,
    shape_dist_traveled: 32.5366,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 412,
    shape_dist_traveled: 32.5606,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 413,
    shape_dist_traveled: 32.6367,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 414,
    shape_dist_traveled: 32.6937,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 415,
    shape_dist_traveled: 32.7318,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060741,
    shape_pt_lon: -89.99722,
    shape_pt_sequence: 416,
    shape_dist_traveled: 32.9339,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.99807,
    shape_pt_sequence: 417,
    shape_dist_traveled: 33.0109,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060777,
    shape_pt_lon: -89.998354,
    shape_pt_sequence: 418,
    shape_dist_traveled: 33.037,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06081,
    shape_pt_lon: -89.999536,
    shape_pt_sequence: 419,
    shape_dist_traveled: 33.144,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060842,
    shape_pt_lon: -90.000408,
    shape_pt_sequence: 420,
    shape_dist_traveled: 33.2231,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060878,
    shape_pt_lon: -90.001628,
    shape_pt_sequence: 421,
    shape_dist_traveled: 33.3332,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06089,
    shape_pt_lon: -90.002047,
    shape_pt_sequence: 422,
    shape_dist_traveled: 33.3712,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06093,
    shape_pt_lon: -90.003676,
    shape_pt_sequence: 423,
    shape_dist_traveled: 33.5182,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -90.004151,
    shape_pt_sequence: 424,
    shape_dist_traveled: 33.5613,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061035,
    shape_pt_lon: -90.007162,
    shape_pt_sequence: 425,
    shape_dist_traveled: 33.8345,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061037,
    shape_pt_lon: -90.007223,
    shape_pt_sequence: 426,
    shape_dist_traveled: 33.8395,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061039,
    shape_pt_lon: -90.007298,
    shape_pt_sequence: 427,
    shape_dist_traveled: 33.8465,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061051,
    shape_pt_lon: -90.007693,
    shape_pt_sequence: 428,
    shape_dist_traveled: 33.8825,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061102,
    shape_pt_lon: -90.009289,
    shape_pt_sequence: 429,
    shape_dist_traveled: 34.0266,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061123,
    shape_pt_lon: -90.010033,
    shape_pt_sequence: 430,
    shape_dist_traveled: 34.0937,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061151,
    shape_pt_lon: -90.01104,
    shape_pt_sequence: 431,
    shape_dist_traveled: 34.1847,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061179,
    shape_pt_lon: -90.011738,
    shape_pt_sequence: 432,
    shape_dist_traveled: 34.2478,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061192,
    shape_pt_lon: -90.012536,
    shape_pt_sequence: 433,
    shape_dist_traveled: 34.3208,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0612,
    shape_pt_lon: -90.012873,
    shape_pt_sequence: 434,
    shape_dist_traveled: 34.3508,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06122,
    shape_pt_lon: -90.013628,
    shape_pt_sequence: 435,
    shape_dist_traveled: 34.4188,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061231,
    shape_pt_lon: -90.013952,
    shape_pt_sequence: 436,
    shape_dist_traveled: 34.4489,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061272,
    shape_pt_lon: -90.015226,
    shape_pt_sequence: 437,
    shape_dist_traveled: 34.564,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061293,
    shape_pt_lon: -90.01599,
    shape_pt_sequence: 438,
    shape_dist_traveled: 34.633,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061339,
    shape_pt_lon: -90.01756,
    shape_pt_sequence: 439,
    shape_dist_traveled: 34.7751,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061356,
    shape_pt_lon: -90.018053,
    shape_pt_sequence: 440,
    shape_dist_traveled: 34.8201,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061403,
    shape_pt_lon: -90.019237,
    shape_pt_sequence: 441,
    shape_dist_traveled: 34.9273,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061419,
    shape_pt_lon: -90.01949,
    shape_pt_sequence: 442,
    shape_dist_traveled: 34.9503,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061451,
    shape_pt_lon: -90.019991,
    shape_pt_sequence: 443,
    shape_dist_traveled: 34.9955,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061467,
    shape_pt_lon: -90.020286,
    shape_pt_sequence: 444,
    shape_dist_traveled: 35.0226,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06148,
    shape_pt_lon: -90.020652,
    shape_pt_sequence: 445,
    shape_dist_traveled: 35.0556,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061527,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 446,
    shape_dist_traveled: 35.1029,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 447,
    shape_dist_traveled: 35.1099,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06163,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 448,
    shape_dist_traveled: 35.2304,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061689,
    shape_pt_lon: -90.023451,
    shape_pt_sequence: 449,
    shape_dist_traveled: 35.3097,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 450,
    shape_dist_traveled: 35.3187,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061699,
    shape_pt_lon: -90.0236,
    shape_pt_sequence: 451,
    shape_dist_traveled: 35.3227,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061705,
    shape_pt_lon: -90.023671,
    shape_pt_sequence: 452,
    shape_dist_traveled: 35.3298,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061721,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 453,
    shape_dist_traveled: 35.3569,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061726,
    shape_pt_lon: -90.024207,
    shape_pt_sequence: 454,
    shape_dist_traveled: 35.3779,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061717,
    shape_pt_lon: -90.024418,
    shape_pt_sequence: 455,
    shape_dist_traveled: 35.3969,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061691,
    shape_pt_lon: -90.024712,
    shape_pt_sequence: 456,
    shape_dist_traveled: 35.4241,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061679,
    shape_pt_lon: -90.024789,
    shape_pt_sequence: 457,
    shape_dist_traveled: 35.4311,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061594,
    shape_pt_lon: -90.025482,
    shape_pt_sequence: 458,
    shape_dist_traveled: 35.4948,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061579,
    shape_pt_lon: -90.025923,
    shape_pt_sequence: 459,
    shape_dist_traveled: 35.5348,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061618,
    shape_pt_lon: -90.027535,
    shape_pt_sequence: 460,
    shape_dist_traveled: 35.6799,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061632,
    shape_pt_lon: -90.028151,
    shape_pt_sequence: 461,
    shape_dist_traveled: 35.7359,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061643,
    shape_pt_lon: -90.028518,
    shape_pt_sequence: 462,
    shape_dist_traveled: 35.7689,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -90.029399,
    shape_pt_sequence: 463,
    shape_dist_traveled: 35.849,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061698,
    shape_pt_lon: -90.030753,
    shape_pt_sequence: 464,
    shape_dist_traveled: 35.971,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061739,
    shape_pt_lon: -90.032218,
    shape_pt_sequence: 465,
    shape_dist_traveled: 36.1041,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061758,
    shape_pt_lon: -90.033019,
    shape_pt_sequence: 466,
    shape_dist_traveled: 36.1761,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061804,
    shape_pt_lon: -90.034866,
    shape_pt_sequence: 467,
    shape_dist_traveled: 36.3432,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 468,
    shape_dist_traveled: 36.4443,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -90.038494,
    shape_pt_sequence: 469,
    shape_dist_traveled: 36.6724,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061956,
    shape_pt_lon: -90.040818,
    shape_pt_sequence: 470,
    shape_dist_traveled: 36.8825,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.061999,
    shape_pt_lon: -90.042573,
    shape_pt_sequence: 471,
    shape_dist_traveled: 37.0405,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062016,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 472,
    shape_dist_traveled: 37.0986,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062055,
    shape_pt_lon: -90.04382,
    shape_pt_sequence: 473,
    shape_dist_traveled: 37.1537,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062113,
    shape_pt_lon: -90.044343,
    shape_pt_sequence: 474,
    shape_dist_traveled: 37.2022,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062426,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 475,
    shape_dist_traveled: 37.3866,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062623,
    shape_pt_lon: -90.047658,
    shape_pt_sequence: 476,
    shape_dist_traveled: 37.5066,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062775,
    shape_pt_lon: -90.048632,
    shape_pt_sequence: 477,
    shape_dist_traveled: 37.5962,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -90.049039,
    shape_pt_sequence: 478,
    shape_dist_traveled: 37.6337,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062858,
    shape_pt_lon: -90.049269,
    shape_pt_sequence: 479,
    shape_dist_traveled: 37.6549,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06288,
    shape_pt_lon: -90.049552,
    shape_pt_sequence: 480,
    shape_dist_traveled: 37.6811,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06289,
    shape_pt_lon: -90.049862,
    shape_pt_sequence: 481,
    shape_dist_traveled: 37.7091,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062904,
    shape_pt_lon: -90.050603,
    shape_pt_sequence: 482,
    shape_dist_traveled: 37.7761,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062929,
    shape_pt_lon: -90.051739,
    shape_pt_sequence: 483,
    shape_dist_traveled: 37.8792,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06293,
    shape_pt_lon: -90.051829,
    shape_pt_sequence: 484,
    shape_dist_traveled: 37.8872,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062931,
    shape_pt_lon: -90.051918,
    shape_pt_sequence: 485,
    shape_dist_traveled: 37.8952,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.062966,
    shape_pt_lon: -90.053281,
    shape_pt_sequence: 486,
    shape_dist_traveled: 38.0182,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063045,
    shape_pt_lon: -90.056698,
    shape_pt_sequence: 487,
    shape_dist_traveled: 38.3274,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06309,
    shape_pt_lon: -90.05856,
    shape_pt_sequence: 488,
    shape_dist_traveled: 38.4954,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063202,
    shape_pt_lon: -90.058881,
    shape_pt_sequence: 489,
    shape_dist_traveled: 38.5272,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06323,
    shape_pt_lon: -90.058937,
    shape_pt_sequence: 490,
    shape_dist_traveled: 38.5339,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063285,
    shape_pt_lon: -90.059002,
    shape_pt_sequence: 491,
    shape_dist_traveled: 38.5417,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.063404,
    shape_pt_lon: -90.059076,
    shape_pt_sequence: 492,
    shape_dist_traveled: 38.5565,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 493,
    shape_dist_traveled: 38.574,
  },
  {
    shape_id: 38212,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 494,
    shape_dist_traveled: 38.7104,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1061,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2242,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.3404,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.4766,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.5927,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.6007,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.6087,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.8047,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.8947,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 11,
    shape_dist_traveled: 1.2459,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 12,
    shape_dist_traveled: 1.389,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 13,
    shape_dist_traveled: 1.492,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.5821,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.6991,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.8382,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.8492,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049318,
    shape_pt_lon: -89.843802,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.8712,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049213,
    shape_pt_lon: -89.843867,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.8837,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048766,
    shape_pt_lon: -89.843889,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.9337,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048179,
    shape_pt_lon: -89.843873,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.9998,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04796,
    shape_pt_lon: -89.843847,
    shape_pt_sequence: 22,
    shape_dist_traveled: 2.0248,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04749,
    shape_pt_lon: -89.843736,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.0778,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.046969,
    shape_pt_lon: -89.843532,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.1388,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.046586,
    shape_pt_lon: -89.843392,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.1838,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.046397,
    shape_pt_lon: -89.843337,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.2053,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04599,
    shape_pt_lon: -89.843251,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.252,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045793,
    shape_pt_lon: -89.843231,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.2741,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045399,
    shape_pt_lon: -89.843218,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.3181,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045087,
    shape_pt_lon: -89.843234,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.3531,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044679,
    shape_pt_lon: -89.843296,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.3995,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044331,
    shape_pt_lon: -89.843385,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.4394,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043942,
    shape_pt_lon: -89.843529,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.4843,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043699,
    shape_pt_lon: -89.843639,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.5131,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04348,
    shape_pt_lon: -89.843757,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.54,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043429,
    shape_pt_lon: -89.843787,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.5467,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043383,
    shape_pt_lon: -89.843813,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.5521,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043165,
    shape_pt_lon: -89.843956,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.5794,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04285,
    shape_pt_lon: -89.844194,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.6207,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042545,
    shape_pt_lon: -89.844472,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.6637,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042449,
    shape_pt_lon: -89.844567,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.6765,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042366,
    shape_pt_lon: -89.844655,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.6893,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04221,
    shape_pt_lon: -89.844829,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.7127,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041846,
    shape_pt_lon: -89.845201,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.766,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041758,
    shape_pt_lon: -89.845288,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.7782,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04147,
    shape_pt_lon: -89.845555,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.8188,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04102,
    shape_pt_lon: -89.845918,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.879,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.040959,
    shape_pt_lon: -89.845966,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.8868,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.040615,
    shape_pt_lon: -89.846209,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.9311,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.040202,
    shape_pt_lon: -89.84647,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.983,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.039784,
    shape_pt_lon: -89.846701,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.0344,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.039531,
    shape_pt_lon: -89.846828,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.0645,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.038962,
    shape_pt_lon: -89.847063,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.1319,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03885,
    shape_pt_lon: -89.847107,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.1455,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.038673,
    shape_pt_lon: -89.847164,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.1651,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.038522,
    shape_pt_lon: -89.847216,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1829,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.037908,
    shape_pt_lon: -89.847377,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2533,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.037733,
    shape_pt_lon: -89.847417,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2737,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036908,
    shape_pt_lon: -89.847529,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.3662,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036432,
    shape_pt_lon: -89.847578,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.4193,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034977,
    shape_pt_lon: -89.847749,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.583,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034976,
    shape_pt_lon: -89.849685,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.758,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03498,
    shape_pt_lon: -89.850703,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.851,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03498,
    shape_pt_lon: -89.851563,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.928,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034981,
    shape_pt_lon: -89.852555,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.018,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034988,
    shape_pt_lon: -89.853793,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.1301,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034988,
    shape_pt_lon: -89.854241,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.1711,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034979,
    shape_pt_lon: -89.855973,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.3271,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034975,
    shape_pt_lon: -89.856373,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.3641,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034949,
    shape_pt_lon: -89.858748,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.5781,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034937,
    shape_pt_lon: -89.85975,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.6691,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03492,
    shape_pt_lon: -89.860734,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.7581,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.0349,
    shape_pt_lon: -89.862913,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.9552,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034897,
    shape_pt_lon: -89.863257,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.9862,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034876,
    shape_pt_lon: -89.864542,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.1032,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034862,
    shape_pt_lon: -89.865812,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.2182,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034873,
    shape_pt_lon: -89.866421,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.2732,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.033741,
    shape_pt_lon: -89.866405,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.4003,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.033187,
    shape_pt_lon: -89.866396,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.4623,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.031863,
    shape_pt_lon: -89.866379,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.6103,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.031468,
    shape_pt_lon: -89.866373,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.6543,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.030658,
    shape_pt_lon: -89.86636,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.7453,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.029975,
    shape_pt_lon: -89.866352,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.8213,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.029049,
    shape_pt_lon: -89.866339,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.9253,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027249,
    shape_pt_lon: -89.866317,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.1263,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02681,
    shape_pt_lon: -89.86631,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.1763,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.026391,
    shape_pt_lon: -89.866301,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.2224,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.026009,
    shape_pt_lon: -89.866296,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.2654,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.025487,
    shape_pt_lon: -89.866287,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.3244,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.024325,
    shape_pt_lon: -89.866271,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.4544,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.022789,
    shape_pt_lon: -89.866253,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.6254,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.022206,
    shape_pt_lon: -89.866243,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.6914,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020933,
    shape_pt_lon: -89.866248,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.8334,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02052,
    shape_pt_lon: -89.866239,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.8794,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020415,
    shape_pt_lon: -89.866238,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.8914,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020358,
    shape_pt_lon: -89.866237,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.8974,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020285,
    shape_pt_lon: -89.866236,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.9065,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.018487,
    shape_pt_lon: -89.86623,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1075,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.017602,
    shape_pt_lon: -89.866227,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.2065,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.017061,
    shape_pt_lon: -89.866212,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.2665,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.016624,
    shape_pt_lon: -89.86618,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.3156,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.016078,
    shape_pt_lon: -89.86609,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.3771,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.015583,
    shape_pt_lon: -89.86596,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.4344,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.0151,
    shape_pt_lon: -89.865796,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.4904,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.014612,
    shape_pt_lon: -89.865574,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.548,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.014228,
    shape_pt_lon: -89.865375,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.5946,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.013498,
    shape_pt_lon: -89.865003,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.6834,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.013016,
    shape_pt_lon: -89.864802,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.7403,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.01266,
    shape_pt_lon: -89.864681,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.7808,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.012575,
    shape_pt_lon: -89.864657,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.791,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.01214,
    shape_pt_lon: -89.864541,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.8413,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.011591,
    shape_pt_lon: -89.86444,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.9029,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.011098,
    shape_pt_lon: -89.864392,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.9581,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.010641,
    shape_pt_lon: -89.86438,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.0092,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.008838,
    shape_pt_lon: -89.864381,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.2112,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00833,
    shape_pt_lon: -89.86438,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.2682,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006796,
    shape_pt_lon: -89.864393,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.4392,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005817,
    shape_pt_lon: -89.864401,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.5492,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005811,
    shape_pt_lon: -89.866213,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.7132,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005831,
    shape_pt_lon: -89.86839,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.9102,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005837,
    shape_pt_lon: -89.869432,
    shape_pt_sequence: 121,
    shape_dist_traveled: 9.0042,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005849,
    shape_pt_lon: -89.870967,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.1432,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005854,
    shape_pt_lon: -89.871795,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.2182,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005874,
    shape_pt_lon: -89.875289,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.5342,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005877,
    shape_pt_lon: -89.875961,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.5952,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005878,
    shape_pt_lon: -89.876388,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.6342,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005879,
    shape_pt_lon: -89.876634,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.6562,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005886,
    shape_pt_lon: -89.8772,
    shape_pt_sequence: 128,
    shape_dist_traveled: 9.7072,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005891,
    shape_pt_lon: -89.878439,
    shape_pt_sequence: 129,
    shape_dist_traveled: 9.8202,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005899,
    shape_pt_lon: -89.879792,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.9422,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005902,
    shape_pt_lon: -89.880352,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.9932,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005905,
    shape_pt_lon: -89.880578,
    shape_pt_sequence: 132,
    shape_dist_traveled: 10.0132,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005906,
    shape_pt_lon: -89.880732,
    shape_pt_sequence: 133,
    shape_dist_traveled: 10.0272,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005916,
    shape_pt_lon: -89.88176,
    shape_pt_sequence: 134,
    shape_dist_traveled: 10.1202,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005927,
    shape_pt_lon: -89.882696,
    shape_pt_sequence: 135,
    shape_dist_traveled: 10.2052,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005929,
    shape_pt_lon: -89.883789,
    shape_pt_sequence: 136,
    shape_dist_traveled: 10.3042,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.88395,
    shape_pt_sequence: 137,
    shape_dist_traveled: 10.3182,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00593,
    shape_pt_lon: -89.884633,
    shape_pt_sequence: 138,
    shape_dist_traveled: 10.3802,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00594,
    shape_pt_lon: -89.886328,
    shape_pt_sequence: 139,
    shape_dist_traveled: 10.5342,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005937,
    shape_pt_lon: -89.886656,
    shape_pt_sequence: 140,
    shape_dist_traveled: 10.5632,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005949,
    shape_pt_lon: -89.887027,
    shape_pt_sequence: 141,
    shape_dist_traveled: 10.5973,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005956,
    shape_pt_lon: -89.88715,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.6083,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.887272,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.6193,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005978,
    shape_pt_lon: -89.887981,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.6834,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005993,
    shape_pt_lon: -89.889476,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.8194,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00599,
    shape_pt_lon: -89.891662,
    shape_pt_sequence: 146,
    shape_dist_traveled: 11.0164,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005981,
    shape_pt_lon: -89.892098,
    shape_pt_sequence: 147,
    shape_dist_traveled: 11.0564,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005969,
    shape_pt_lon: -89.892566,
    shape_pt_sequence: 148,
    shape_dist_traveled: 11.0984,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005962,
    shape_pt_lon: -89.893538,
    shape_pt_sequence: 149,
    shape_dist_traveled: 11.1864,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005961,
    shape_pt_lon: -89.893764,
    shape_pt_sequence: 150,
    shape_dist_traveled: 11.2074,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005967,
    shape_pt_lon: -89.894096,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.2374,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.895486,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.3624,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005977,
    shape_pt_lon: -89.899086,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.6885,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005959,
    shape_pt_lon: -89.900487,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.8155,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.901498,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.9075,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005954,
    shape_pt_lon: -89.902176,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.9685,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005968,
    shape_pt_lon: -89.903436,
    shape_pt_sequence: 157,
    shape_dist_traveled: 12.0825,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005971,
    shape_pt_lon: -89.904277,
    shape_pt_sequence: 158,
    shape_dist_traveled: 12.1585,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005979,
    shape_pt_lon: -89.904696,
    shape_pt_sequence: 159,
    shape_dist_traveled: 12.1965,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.005989,
    shape_pt_lon: -89.906361,
    shape_pt_sequence: 160,
    shape_dist_traveled: 12.3475,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006017,
    shape_pt_lon: -89.907199,
    shape_pt_sequence: 161,
    shape_dist_traveled: 12.4236,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00602,
    shape_pt_lon: -89.907796,
    shape_pt_sequence: 162,
    shape_dist_traveled: 12.4776,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006026,
    shape_pt_lon: -89.909113,
    shape_pt_sequence: 163,
    shape_dist_traveled: 12.5966,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00603,
    shape_pt_lon: -89.910074,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.6836,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.910567,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.7286,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006019,
    shape_pt_lon: -89.91084,
    shape_pt_sequence: 166,
    shape_dist_traveled: 12.7526,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006021,
    shape_pt_lon: -89.913257,
    shape_pt_sequence: 167,
    shape_dist_traveled: 12.9716,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.915058,
    shape_pt_sequence: 168,
    shape_dist_traveled: 13.1346,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006038,
    shape_pt_lon: -89.916056,
    shape_pt_sequence: 169,
    shape_dist_traveled: 13.2246,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00605,
    shape_pt_lon: -89.919066,
    shape_pt_sequence: 170,
    shape_dist_traveled: 13.4976,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00606,
    shape_pt_lon: -89.923647,
    shape_pt_sequence: 171,
    shape_dist_traveled: 13.9116,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.924019,
    shape_pt_sequence: 172,
    shape_dist_traveled: 13.9456,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006059,
    shape_pt_lon: -89.927998,
    shape_pt_sequence: 173,
    shape_dist_traveled: 14.3056,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006033,
    shape_pt_lon: -89.929494,
    shape_pt_sequence: 174,
    shape_dist_traveled: 14.4417,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.930648,
    shape_pt_sequence: 175,
    shape_dist_traveled: 14.5457,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006029,
    shape_pt_lon: -89.931137,
    shape_pt_sequence: 176,
    shape_dist_traveled: 14.5897,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006031,
    shape_pt_lon: -89.93365,
    shape_pt_sequence: 177,
    shape_dist_traveled: 14.8177,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006049,
    shape_pt_lon: -89.936093,
    shape_pt_sequence: 178,
    shape_dist_traveled: 15.0387,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 179,
    shape_dist_traveled: 15.1037,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 180,
    shape_dist_traveled: 15.3267,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 181,
    shape_dist_traveled: 15.3687,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 182,
    shape_dist_traveled: 15.3817,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 183,
    shape_dist_traveled: 15.4607,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 184,
    shape_dist_traveled: 15.5617,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 185,
    shape_dist_traveled: 15.5967,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001643,
    shape_pt_lon: -89.93612,
    shape_pt_sequence: 186,
    shape_dist_traveled: 15.6567,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001636,
    shape_pt_lon: -89.935264,
    shape_pt_sequence: 187,
    shape_dist_traveled: 15.7347,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001636,
    shape_pt_lon: -89.935264,
    shape_pt_sequence: 188,
    shape_dist_traveled: 15.8127,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001639,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 189,
    shape_dist_traveled: 15.8727,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.001952,
    shape_pt_lon: -89.936786,
    shape_pt_sequence: 190,
    shape_dist_traveled: 15.9077,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.002857,
    shape_pt_lon: -89.936815,
    shape_pt_sequence: 191,
    shape_dist_traveled: 16.0088,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.003566,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 192,
    shape_dist_traveled: 16.0878,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00368,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 193,
    shape_dist_traveled: 16.1008,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.004051,
    shape_pt_lon: -89.936821,
    shape_pt_sequence: 194,
    shape_dist_traveled: 16.1428,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006042,
    shape_pt_lon: -89.936813,
    shape_pt_sequence: 195,
    shape_dist_traveled: 16.3658,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00604,
    shape_pt_lon: -89.937121,
    shape_pt_sequence: 196,
    shape_dist_traveled: 16.3938,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006062,
    shape_pt_lon: -89.938412,
    shape_pt_sequence: 197,
    shape_dist_traveled: 16.5108,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006052,
    shape_pt_lon: -89.939112,
    shape_pt_sequence: 198,
    shape_dist_traveled: 16.5739,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -89.940202,
    shape_pt_sequence: 199,
    shape_dist_traveled: 16.6729,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006069,
    shape_pt_lon: -89.941305,
    shape_pt_sequence: 200,
    shape_dist_traveled: 16.7729,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.00607,
    shape_pt_lon: -89.941451,
    shape_pt_sequence: 201,
    shape_dist_traveled: 16.7859,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006077,
    shape_pt_lon: -89.942096,
    shape_pt_sequence: 202,
    shape_dist_traveled: 16.8439,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006095,
    shape_pt_lon: -89.943923,
    shape_pt_sequence: 203,
    shape_dist_traveled: 17.0099,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006117,
    shape_pt_lon: -89.944809,
    shape_pt_sequence: 204,
    shape_dist_traveled: 17.0899,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006144,
    shape_pt_lon: -89.948532,
    shape_pt_sequence: 205,
    shape_dist_traveled: 17.4269,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006146,
    shape_pt_lon: -89.94867,
    shape_pt_sequence: 206,
    shape_dist_traveled: 17.439,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006155,
    shape_pt_lon: -89.949916,
    shape_pt_sequence: 207,
    shape_dist_traveled: 17.552,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006159,
    shape_pt_lon: -89.951597,
    shape_pt_sequence: 208,
    shape_dist_traveled: 17.704,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006188,
    shape_pt_lon: -89.954324,
    shape_pt_sequence: 209,
    shape_dist_traveled: 17.951,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.006199,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 210,
    shape_dist_traveled: 17.972,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.009323,
    shape_pt_lon: -89.954539,
    shape_pt_sequence: 211,
    shape_dist_traveled: 18.321,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.01126,
    shape_pt_lon: -89.954553,
    shape_pt_sequence: 212,
    shape_dist_traveled: 18.538,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.013438,
    shape_pt_lon: -89.954563,
    shape_pt_sequence: 213,
    shape_dist_traveled: 18.782,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.01411,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 214,
    shape_dist_traveled: 18.857,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.014964,
    shape_pt_lon: -89.954562,
    shape_pt_sequence: 215,
    shape_dist_traveled: 18.953,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.015599,
    shape_pt_lon: -89.954564,
    shape_pt_sequence: 216,
    shape_dist_traveled: 19.024,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.016532,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 217,
    shape_dist_traveled: 19.128,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.017091,
    shape_pt_lon: -89.954571,
    shape_pt_sequence: 218,
    shape_dist_traveled: 19.191,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020113,
    shape_pt_lon: -89.954552,
    shape_pt_sequence: 219,
    shape_dist_traveled: 19.529,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020673,
    shape_pt_lon: -89.954572,
    shape_pt_sequence: 220,
    shape_dist_traveled: 19.5911,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020756,
    shape_pt_lon: -89.954575,
    shape_pt_sequence: 221,
    shape_dist_traveled: 19.6011,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.020812,
    shape_pt_lon: -89.954586,
    shape_pt_sequence: 222,
    shape_dist_traveled: 19.6072,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.022747,
    shape_pt_lon: -89.954625,
    shape_pt_sequence: 223,
    shape_dist_traveled: 19.8232,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.024258,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 224,
    shape_dist_traveled: 19.9922,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.024822,
    shape_pt_lon: -89.954638,
    shape_pt_sequence: 225,
    shape_dist_traveled: 20.0562,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.025685,
    shape_pt_lon: -89.954634,
    shape_pt_sequence: 226,
    shape_dist_traveled: 20.1522,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02614,
    shape_pt_lon: -89.954652,
    shape_pt_sequence: 227,
    shape_dist_traveled: 20.2032,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02707,
    shape_pt_lon: -89.954757,
    shape_pt_sequence: 228,
    shape_dist_traveled: 20.3077,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027319,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 229,
    shape_dist_traveled: 20.3357,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027836,
    shape_pt_lon: -89.954737,
    shape_pt_sequence: 230,
    shape_dist_traveled: 20.3938,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.954728,
    shape_pt_sequence: 231,
    shape_dist_traveled: 20.4088,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.953473,
    shape_pt_sequence: 232,
    shape_dist_traveled: 20.5228,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.950879,
    shape_pt_sequence: 233,
    shape_dist_traveled: 20.7578,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94997,
    shape_pt_sequence: 234,
    shape_dist_traveled: 20.8398,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027967,
    shape_pt_lon: -89.949072,
    shape_pt_sequence: 235,
    shape_dist_traveled: 20.9208,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027966,
    shape_pt_lon: -89.948133,
    shape_pt_sequence: 236,
    shape_dist_traveled: 21.0058,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027963,
    shape_pt_lon: -89.947081,
    shape_pt_sequence: 237,
    shape_dist_traveled: 21.1008,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.946853,
    shape_pt_sequence: 238,
    shape_dist_traveled: 21.1218,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.94633,
    shape_pt_sequence: 239,
    shape_dist_traveled: 21.1688,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027993,
    shape_pt_lon: -89.945854,
    shape_pt_sequence: 240,
    shape_dist_traveled: 21.2119,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027987,
    shape_pt_lon: -89.945346,
    shape_pt_sequence: 241,
    shape_dist_traveled: 21.2579,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.94404,
    shape_pt_sequence: 242,
    shape_dist_traveled: 21.3769,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027973,
    shape_pt_lon: -89.943689,
    shape_pt_sequence: 243,
    shape_dist_traveled: 21.4079,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.02798,
    shape_pt_lon: -89.94137,
    shape_pt_sequence: 244,
    shape_dist_traveled: 21.6179,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027971,
    shape_pt_lon: -89.939003,
    shape_pt_sequence: 245,
    shape_dist_traveled: 21.8319,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027961,
    shape_pt_lon: -89.93815,
    shape_pt_sequence: 246,
    shape_dist_traveled: 21.909,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.027972,
    shape_pt_lon: -89.936979,
    shape_pt_sequence: 247,
    shape_dist_traveled: 22.015,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.029582,
    shape_pt_lon: -89.937016,
    shape_pt_sequence: 248,
    shape_dist_traveled: 22.195,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.030277,
    shape_pt_lon: -89.937031,
    shape_pt_sequence: 249,
    shape_dist_traveled: 22.273,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.030663,
    shape_pt_lon: -89.937044,
    shape_pt_sequence: 250,
    shape_dist_traveled: 22.316,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.030782,
    shape_pt_lon: -89.93696,
    shape_pt_sequence: 251,
    shape_dist_traveled: 22.3321,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.031272,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 252,
    shape_dist_traveled: 22.3861,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.032675,
    shape_pt_lon: -89.936986,
    shape_pt_sequence: 253,
    shape_dist_traveled: 22.5432,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.032752,
    shape_pt_lon: -89.936987,
    shape_pt_sequence: 254,
    shape_dist_traveled: 22.5522,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.033071,
    shape_pt_lon: -89.936995,
    shape_pt_sequence: 255,
    shape_dist_traveled: 22.5882,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034049,
    shape_pt_lon: -89.936998,
    shape_pt_sequence: 256,
    shape_dist_traveled: 22.6972,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034286,
    shape_pt_lon: -89.936977,
    shape_pt_sequence: 257,
    shape_dist_traveled: 22.7243,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034377,
    shape_pt_lon: -89.936964,
    shape_pt_sequence: 258,
    shape_dist_traveled: 22.7343,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.034619,
    shape_pt_lon: -89.936922,
    shape_pt_sequence: 259,
    shape_dist_traveled: 22.7616,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03582,
    shape_pt_lon: -89.936605,
    shape_pt_sequence: 260,
    shape_dist_traveled: 22.8985,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036047,
    shape_pt_lon: -89.936561,
    shape_pt_sequence: 261,
    shape_dist_traveled: 22.9248,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036453,
    shape_pt_lon: -89.936534,
    shape_pt_sequence: 262,
    shape_dist_traveled: 22.9699,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036762,
    shape_pt_lon: -89.93654,
    shape_pt_sequence: 263,
    shape_dist_traveled: 23.0049,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036762,
    shape_pt_lon: -89.936534,
    shape_pt_sequence: 264,
    shape_dist_traveled: 23.0049,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036792,
    shape_pt_lon: -89.936535,
    shape_pt_sequence: 265,
    shape_dist_traveled: 23.0079,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036849,
    shape_pt_lon: -89.936536,
    shape_pt_sequence: 266,
    shape_dist_traveled: 23.0139,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.036842,
    shape_pt_lon: -89.936543,
    shape_pt_sequence: 267,
    shape_dist_traveled: 23.0139,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.037411,
    shape_pt_lon: -89.936583,
    shape_pt_sequence: 268,
    shape_dist_traveled: 23.077,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.038197,
    shape_pt_lon: -89.93675,
    shape_pt_sequence: 269,
    shape_dist_traveled: 23.1662,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.038498,
    shape_pt_lon: -89.936798,
    shape_pt_sequence: 270,
    shape_dist_traveled: 23.2006,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.039469,
    shape_pt_lon: -89.936818,
    shape_pt_sequence: 271,
    shape_dist_traveled: 23.3086,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.03991,
    shape_pt_lon: -89.936817,
    shape_pt_sequence: 272,
    shape_dist_traveled: 23.3586,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.040407,
    shape_pt_lon: -89.936776,
    shape_pt_sequence: 273,
    shape_dist_traveled: 23.4138,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.040763,
    shape_pt_lon: -89.936709,
    shape_pt_sequence: 274,
    shape_dist_traveled: 23.4542,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041038,
    shape_pt_lon: -89.936625,
    shape_pt_sequence: 275,
    shape_dist_traveled: 23.486,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041316,
    shape_pt_lon: -89.936513,
    shape_pt_sequence: 276,
    shape_dist_traveled: 23.5189,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041545,
    shape_pt_lon: -89.9364,
    shape_pt_sequence: 277,
    shape_dist_traveled: 23.5468,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.041842,
    shape_pt_lon: -89.936217,
    shape_pt_sequence: 278,
    shape_dist_traveled: 23.5834,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042121,
    shape_pt_lon: -89.936008,
    shape_pt_sequence: 279,
    shape_dist_traveled: 23.6198,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042397,
    shape_pt_lon: -89.935757,
    shape_pt_sequence: 280,
    shape_dist_traveled: 23.6584,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042708,
    shape_pt_lon: -89.935411,
    shape_pt_sequence: 281,
    shape_dist_traveled: 23.7051,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.042884,
    shape_pt_lon: -89.935198,
    shape_pt_sequence: 282,
    shape_dist_traveled: 23.7327,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.04347,
    shape_pt_lon: -89.934513,
    shape_pt_sequence: 283,
    shape_dist_traveled: 23.8226,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.043751,
    shape_pt_lon: -89.934238,
    shape_pt_sequence: 284,
    shape_dist_traveled: 23.8632,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044019,
    shape_pt_lon: -89.934019,
    shape_pt_sequence: 285,
    shape_dist_traveled: 23.8992,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044307,
    shape_pt_lon: -89.933823,
    shape_pt_sequence: 286,
    shape_dist_traveled: 23.9355,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044619,
    shape_pt_lon: -89.93365,
    shape_pt_sequence: 287,
    shape_dist_traveled: 23.9739,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.044791,
    shape_pt_lon: -89.933569,
    shape_pt_sequence: 288,
    shape_dist_traveled: 23.9942,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045139,
    shape_pt_lon: -89.933433,
    shape_pt_sequence: 289,
    shape_dist_traveled: 24.0353,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045586,
    shape_pt_lon: -89.933327,
    shape_pt_sequence: 290,
    shape_dist_traveled: 24.0861,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.045808,
    shape_pt_lon: -89.933297,
    shape_pt_sequence: 291,
    shape_dist_traveled: 24.1113,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.046083,
    shape_pt_lon: -89.933279,
    shape_pt_sequence: 292,
    shape_dist_traveled: 24.1423,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.047246,
    shape_pt_lon: -89.933292,
    shape_pt_sequence: 293,
    shape_dist_traveled: 24.2723,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048716,
    shape_pt_lon: -89.933322,
    shape_pt_sequence: 294,
    shape_dist_traveled: 24.4373,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.048923,
    shape_pt_lon: -89.93334,
    shape_pt_sequence: 295,
    shape_dist_traveled: 24.4604,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049357,
    shape_pt_lon: -89.933403,
    shape_pt_sequence: 296,
    shape_dist_traveled: 24.5087,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.049567,
    shape_pt_lon: -89.933447,
    shape_pt_sequence: 297,
    shape_dist_traveled: 24.533,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.050553,
    shape_pt_lon: -89.933733,
    shape_pt_sequence: 298,
    shape_dist_traveled: 24.646,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.933858,
    shape_pt_sequence: 299,
    shape_dist_traveled: 24.7004,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.051359,
    shape_pt_lon: -89.933906,
    shape_pt_sequence: 300,
    shape_dist_traveled: 24.7376,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.051603,
    shape_pt_lon: -89.933914,
    shape_pt_sequence: 301,
    shape_dist_traveled: 24.7646,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.051759,
    shape_pt_lon: -89.933918,
    shape_pt_sequence: 302,
    shape_dist_traveled: 24.7826,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 303,
    shape_dist_traveled: 24.7946,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.052017,
    shape_pt_lon: -89.933922,
    shape_pt_sequence: 304,
    shape_dist_traveled: 24.8116,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.053188,
    shape_pt_lon: -89.933938,
    shape_pt_sequence: 305,
    shape_dist_traveled: 24.9426,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05363,
    shape_pt_lon: -89.933901,
    shape_pt_sequence: 306,
    shape_dist_traveled: 24.9917,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.054053,
    shape_pt_lon: -89.933828,
    shape_pt_sequence: 307,
    shape_dist_traveled: 25.0401,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.054357,
    shape_pt_lon: -89.933749,
    shape_pt_sequence: 308,
    shape_dist_traveled: 25.0748,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.054481,
    shape_pt_lon: -89.933712,
    shape_pt_sequence: 309,
    shape_dist_traveled: 25.0894,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05497,
    shape_pt_lon: -89.933527,
    shape_pt_sequence: 310,
    shape_dist_traveled: 25.1457,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.055188,
    shape_pt_lon: -89.933423,
    shape_pt_sequence: 311,
    shape_dist_traveled: 25.1726,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.055313,
    shape_pt_lon: -89.933358,
    shape_pt_sequence: 312,
    shape_dist_traveled: 25.1879,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.0555,
    shape_pt_lon: -89.933261,
    shape_pt_sequence: 313,
    shape_dist_traveled: 25.2103,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.056072,
    shape_pt_lon: -89.932929,
    shape_pt_sequence: 314,
    shape_dist_traveled: 25.281,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05679,
    shape_pt_lon: -89.932518,
    shape_pt_sequence: 315,
    shape_dist_traveled: 25.3692,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.056932,
    shape_pt_lon: -89.932433,
    shape_pt_sequence: 316,
    shape_dist_traveled: 25.387,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.057032,
    shape_pt_lon: -89.932373,
    shape_pt_sequence: 317,
    shape_dist_traveled: 25.3996,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.057089,
    shape_pt_lon: -89.93234,
    shape_pt_sequence: 318,
    shape_dist_traveled: 25.4072,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.057138,
    shape_pt_lon: -89.932313,
    shape_pt_sequence: 319,
    shape_dist_traveled: 25.4126,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05789,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 320,
    shape_dist_traveled: 25.5048,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.058198,
    shape_pt_lon: -89.931708,
    shape_pt_sequence: 321,
    shape_dist_traveled: 25.5433,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.058595,
    shape_pt_lon: -89.93147,
    shape_pt_sequence: 322,
    shape_dist_traveled: 25.5925,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05897,
    shape_pt_lon: -89.931251,
    shape_pt_sequence: 323,
    shape_dist_traveled: 25.639,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.059103,
    shape_pt_lon: -89.931184,
    shape_pt_sequence: 324,
    shape_dist_traveled: 25.6551,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.059289,
    shape_pt_lon: -89.931099,
    shape_pt_sequence: 325,
    shape_dist_traveled: 25.6773,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.05965,
    shape_pt_lon: -89.930978,
    shape_pt_sequence: 326,
    shape_dist_traveled: 25.7187,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06002,
    shape_pt_lon: -89.930914,
    shape_pt_sequence: 327,
    shape_dist_traveled: 25.7612,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060297,
    shape_pt_lon: -89.930899,
    shape_pt_sequence: 328,
    shape_dist_traveled: 25.7922,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060377,
    shape_pt_lon: -89.930898,
    shape_pt_sequence: 329,
    shape_dist_traveled: 25.8012,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060845,
    shape_pt_lon: -89.930927,
    shape_pt_sequence: 330,
    shape_dist_traveled: 25.8532,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061082,
    shape_pt_lon: -89.930969,
    shape_pt_sequence: 331,
    shape_dist_traveled: 25.8795,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061463,
    shape_pt_lon: -89.93107,
    shape_pt_sequence: 332,
    shape_dist_traveled: 25.9235,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061775,
    shape_pt_lon: -89.931189,
    shape_pt_sequence: 333,
    shape_dist_traveled: 25.9602,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06208,
    shape_pt_lon: -89.931346,
    shape_pt_sequence: 334,
    shape_dist_traveled: 25.9969,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063008,
    shape_pt_lon: -89.931907,
    shape_pt_sequence: 335,
    shape_dist_traveled: 26.1128,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063344,
    shape_pt_lon: -89.9321,
    shape_pt_sequence: 336,
    shape_dist_traveled: 26.1539,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063622,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 337,
    shape_dist_traveled: 26.1877,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063818,
    shape_pt_lon: -89.93228,
    shape_pt_sequence: 338,
    shape_dist_traveled: 26.2093,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064096,
    shape_pt_lon: -89.932338,
    shape_pt_sequence: 339,
    shape_dist_traveled: 26.2417,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064279,
    shape_pt_lon: -89.932352,
    shape_pt_sequence: 340,
    shape_dist_traveled: 26.2618,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064384,
    shape_pt_lon: -89.932352,
    shape_pt_sequence: 341,
    shape_dist_traveled: 26.2738,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064877,
    shape_pt_lon: -89.93233,
    shape_pt_sequence: 342,
    shape_dist_traveled: 26.3288,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 343,
    shape_dist_traveled: 26.3529,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.066376,
    shape_pt_lon: -89.932248,
    shape_pt_sequence: 344,
    shape_dist_traveled: 26.497,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067282,
    shape_pt_lon: -89.932176,
    shape_pt_sequence: 345,
    shape_dist_traveled: 26.5982,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067553,
    shape_pt_lon: -89.932161,
    shape_pt_sequence: 346,
    shape_dist_traveled: 26.6283,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06759,
    shape_pt_lon: -89.932188,
    shape_pt_sequence: 347,
    shape_dist_traveled: 26.6327,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 348,
    shape_dist_traveled: 26.6399,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 349,
    shape_dist_traveled: 26.645,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 350,
    shape_dist_traveled: 26.6611,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 351,
    shape_dist_traveled: 26.6731,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 352,
    shape_dist_traveled: 26.7161,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 353,
    shape_dist_traveled: 26.8092,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 354,
    shape_dist_traveled: 26.9073,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 355,
    shape_dist_traveled: 26.9323,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 356,
    shape_dist_traveled: 27.0214,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 357,
    shape_dist_traveled: 27.0996,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 358,
    shape_dist_traveled: 27.2079,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 359,
    shape_dist_traveled: 27.2079,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 360,
    shape_dist_traveled: 27.2781,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 361,
    shape_dist_traveled: 27.4185,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 362,
    shape_dist_traveled: 27.6231,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 363,
    shape_dist_traveled: 27.6302,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 364,
    shape_dist_traveled: 27.6453,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 365,
    shape_dist_traveled: 27.7034,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 366,
    shape_dist_traveled: 27.7785,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 367,
    shape_dist_traveled: 27.7875,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 368,
    shape_dist_traveled: 27.7933,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 369,
    shape_dist_traveled: 27.8019,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 370,
    shape_dist_traveled: 27.8149,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 371,
    shape_dist_traveled: 27.8759,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 372,
    shape_dist_traveled: 27.8818,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 373,
    shape_dist_traveled: 27.8948,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 374,
    shape_dist_traveled: 27.9088,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 375,
    shape_dist_traveled: 27.9159,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 376,
    shape_dist_traveled: 27.9254,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 377,
    shape_dist_traveled: 27.9764,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 378,
    shape_dist_traveled: 27.9796,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 379,
    shape_dist_traveled: 27.9859,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 380,
    shape_dist_traveled: 27.9919,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 381,
    shape_dist_traveled: 28.0199,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 382,
    shape_dist_traveled: 28.0329,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 383,
    shape_dist_traveled: 28.0414,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 384,
    shape_dist_traveled: 28.0473,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 385,
    shape_dist_traveled: 28.0563,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076519,
    shape_pt_lon: -89.933788,
    shape_pt_sequence: 386,
    shape_dist_traveled: 28.1063,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076845,
    shape_pt_lon: -89.941467,
    shape_pt_sequence: 387,
    shape_dist_traveled: 28.8012,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07687,
    shape_pt_lon: -89.941986,
    shape_pt_sequence: 388,
    shape_dist_traveled: 28.8483,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.07692,
    shape_pt_lon: -89.943239,
    shape_pt_sequence: 389,
    shape_dist_traveled: 28.9615,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076998,
    shape_pt_lon: -89.948818,
    shape_pt_sequence: 390,
    shape_dist_traveled: 29.4665,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076976,
    shape_pt_lon: -89.949271,
    shape_pt_sequence: 391,
    shape_dist_traveled: 29.5076,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076959,
    shape_pt_lon: -89.94947,
    shape_pt_sequence: 392,
    shape_dist_traveled: 29.5257,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076864,
    shape_pt_lon: -89.950137,
    shape_pt_sequence: 393,
    shape_dist_traveled: 29.5867,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076793,
    shape_pt_lon: -89.950446,
    shape_pt_sequence: 394,
    shape_dist_traveled: 29.6158,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076746,
    shape_pt_lon: -89.950645,
    shape_pt_sequence: 395,
    shape_dist_traveled: 29.6345,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076677,
    shape_pt_lon: -89.95091,
    shape_pt_sequence: 396,
    shape_dist_traveled: 29.6598,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 397,
    shape_dist_traveled: 29.6844,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076427,
    shape_pt_lon: -89.951466,
    shape_pt_sequence: 398,
    shape_dist_traveled: 29.7171,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076279,
    shape_pt_lon: -89.951763,
    shape_pt_sequence: 399,
    shape_dist_traveled: 29.749,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.951963,
    shape_pt_sequence: 400,
    shape_dist_traveled: 29.7701,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075925,
    shape_pt_lon: -89.952326,
    shape_pt_sequence: 401,
    shape_dist_traveled: 29.8134,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.075379,
    shape_pt_lon: -89.952992,
    shape_pt_sequence: 402,
    shape_dist_traveled: 29.8997,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.074843,
    shape_pt_lon: -89.95362,
    shape_pt_sequence: 403,
    shape_dist_traveled: 29.9824,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.074464,
    shape_pt_lon: -89.954062,
    shape_pt_sequence: 404,
    shape_dist_traveled: 30.0404,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.074386,
    shape_pt_lon: -89.954148,
    shape_pt_sequence: 405,
    shape_dist_traveled: 30.0518,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.074048,
    shape_pt_lon: -89.954422,
    shape_pt_sequence: 406,
    shape_dist_traveled: 30.0973,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 407,
    shape_dist_traveled: 30.1197,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.073675,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 408,
    shape_dist_traveled: 30.1425,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.073257,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 409,
    shape_dist_traveled: 30.1911,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072893,
    shape_pt_lon: -89.954822,
    shape_pt_sequence: 410,
    shape_dist_traveled: 30.2324,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072802,
    shape_pt_lon: -89.954828,
    shape_pt_sequence: 411,
    shape_dist_traveled: 30.2424,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072486,
    shape_pt_lon: -89.9548,
    shape_pt_sequence: 412,
    shape_dist_traveled: 30.2775,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072429,
    shape_pt_lon: -89.954792,
    shape_pt_sequence: 413,
    shape_dist_traveled: 30.2846,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.072248,
    shape_pt_lon: -89.954769,
    shape_pt_sequence: 414,
    shape_dist_traveled: 30.3047,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069328,
    shape_pt_lon: -89.954309,
    shape_pt_sequence: 415,
    shape_dist_traveled: 30.6344,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06904,
    shape_pt_lon: -89.954436,
    shape_pt_sequence: 416,
    shape_dist_traveled: 30.6686,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068971,
    shape_pt_lon: -89.954489,
    shape_pt_sequence: 417,
    shape_dist_traveled: 30.678,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068906,
    shape_pt_lon: -89.954568,
    shape_pt_sequence: 418,
    shape_dist_traveled: 30.6879,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.954629,
    shape_pt_sequence: 419,
    shape_dist_traveled: 30.6943,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068773,
    shape_pt_lon: -89.95478,
    shape_pt_sequence: 420,
    shape_dist_traveled: 30.7121,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068782,
    shape_pt_lon: -89.955203,
    shape_pt_sequence: 421,
    shape_dist_traveled: 30.7501,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068868,
    shape_pt_lon: -89.958461,
    shape_pt_sequence: 422,
    shape_dist_traveled: 31.0453,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068898,
    shape_pt_lon: -89.959817,
    shape_pt_sequence: 423,
    shape_dist_traveled: 31.1673,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068899,
    shape_pt_lon: -89.95991,
    shape_pt_sequence: 424,
    shape_dist_traveled: 31.1763,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068929,
    shape_pt_lon: -89.961096,
    shape_pt_sequence: 425,
    shape_dist_traveled: 31.2834,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068951,
    shape_pt_lon: -89.961978,
    shape_pt_sequence: 426,
    shape_dist_traveled: 31.3634,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068955,
    shape_pt_lon: -89.962088,
    shape_pt_sequence: 427,
    shape_dist_traveled: 31.3734,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068981,
    shape_pt_lon: -89.963304,
    shape_pt_sequence: 428,
    shape_dist_traveled: 31.4835,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06899,
    shape_pt_lon: -89.963668,
    shape_pt_sequence: 429,
    shape_dist_traveled: 31.5165,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068992,
    shape_pt_lon: -89.963804,
    shape_pt_sequence: 430,
    shape_dist_traveled: 31.5285,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069011,
    shape_pt_lon: -89.964075,
    shape_pt_sequence: 431,
    shape_dist_traveled: 31.5526,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069012,
    shape_pt_lon: -89.964132,
    shape_pt_sequence: 432,
    shape_dist_traveled: 31.5586,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069038,
    shape_pt_lon: -89.964462,
    shape_pt_sequence: 433,
    shape_dist_traveled: 31.5878,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.965049,
    shape_pt_sequence: 434,
    shape_dist_traveled: 31.6419,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069087,
    shape_pt_lon: -89.965871,
    shape_pt_sequence: 435,
    shape_dist_traveled: 31.7159,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069088,
    shape_pt_lon: -89.966092,
    shape_pt_sequence: 436,
    shape_dist_traveled: 31.7359,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06909,
    shape_pt_lon: -89.966763,
    shape_pt_sequence: 437,
    shape_dist_traveled: 31.7969,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069093,
    shape_pt_lon: -89.967908,
    shape_pt_sequence: 438,
    shape_dist_traveled: 31.8999,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069099,
    shape_pt_lon: -89.968477,
    shape_pt_sequence: 439,
    shape_dist_traveled: 31.9519,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069079,
    shape_pt_lon: -89.969853,
    shape_pt_sequence: 440,
    shape_dist_traveled: 32.0759,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.069004,
    shape_pt_lon: -89.973159,
    shape_pt_sequence: 441,
    shape_dist_traveled: 32.3751,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068961,
    shape_pt_lon: -89.975041,
    shape_pt_sequence: 442,
    shape_dist_traveled: 32.5451,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980318,
    shape_pt_sequence: 443,
    shape_dist_traveled: 33.0223,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06885,
    shape_pt_lon: -89.980607,
    shape_pt_sequence: 444,
    shape_dist_traveled: 33.0493,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068833,
    shape_pt_lon: -89.981153,
    shape_pt_sequence: 445,
    shape_dist_traveled: 33.0984,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068827,
    shape_pt_lon: -89.981701,
    shape_pt_sequence: 446,
    shape_dist_traveled: 33.1484,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068811,
    shape_pt_lon: -89.982129,
    shape_pt_sequence: 447,
    shape_dist_traveled: 33.1864,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068779,
    shape_pt_lon: -89.983286,
    shape_pt_sequence: 448,
    shape_dist_traveled: 33.2915,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.983438,
    shape_pt_sequence: 449,
    shape_dist_traveled: 33.3055,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068763,
    shape_pt_lon: -89.984272,
    shape_pt_sequence: 450,
    shape_dist_traveled: 33.3805,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068759,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 451,
    shape_dist_traveled: 33.3925,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 452,
    shape_dist_traveled: 33.4795,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 453,
    shape_dist_traveled: 33.5236,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 454,
    shape_dist_traveled: 33.5376,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 455,
    shape_dist_traveled: 33.5436,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 456,
    shape_dist_traveled: 33.5546,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068771,
    shape_pt_lon: -89.986323,
    shape_pt_sequence: 457,
    shape_dist_traveled: 33.5676,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068771,
    shape_pt_lon: -89.98639,
    shape_pt_sequence: 458,
    shape_dist_traveled: 33.5736,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068777,
    shape_pt_lon: -89.986933,
    shape_pt_sequence: 459,
    shape_dist_traveled: 33.6226,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068769,
    shape_pt_lon: -89.987349,
    shape_pt_sequence: 460,
    shape_dist_traveled: 33.6597,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 461,
    shape_dist_traveled: 33.6757,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068713,
    shape_pt_lon: -89.987548,
    shape_pt_sequence: 462,
    shape_dist_traveled: 33.682,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068657,
    shape_pt_lon: -89.987588,
    shape_pt_sequence: 463,
    shape_dist_traveled: 33.6892,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987664,
    shape_pt_sequence: 464,
    shape_dist_traveled: 33.7031,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 465,
    shape_dist_traveled: 33.7286,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 466,
    shape_dist_traveled: 33.828,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 467,
    shape_dist_traveled: 34.152,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 468,
    shape_dist_traveled: 34.2366,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 469,
    shape_dist_traveled: 34.2885,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 470,
    shape_dist_traveled: 34.3381,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 471,
    shape_dist_traveled: 34.3401,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 472,
    shape_dist_traveled: 34.3684,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 473,
    shape_dist_traveled: 34.4158,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 474,
    shape_dist_traveled: 34.4692,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 475,
    shape_dist_traveled: 34.5029,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 476,
    shape_dist_traveled: 34.5463,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 477,
    shape_dist_traveled: 34.5683,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 478,
    shape_dist_traveled: 34.6194,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 479,
    shape_dist_traveled: 34.6411,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 480,
    shape_dist_traveled: 34.6595,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 481,
    shape_dist_traveled: 34.6731,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 482,
    shape_dist_traveled: 34.6915,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 483,
    shape_dist_traveled: 34.7111,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 484,
    shape_dist_traveled: 34.7276,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 485,
    shape_dist_traveled: 34.7506,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 486,
    shape_dist_traveled: 34.7747,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 487,
    shape_dist_traveled: 34.9093,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 488,
    shape_dist_traveled: 35.0264,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 489,
    shape_dist_traveled: 35.0484,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 490,
    shape_dist_traveled: 35.0995,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 491,
    shape_dist_traveled: 35.1212,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 492,
    shape_dist_traveled: 35.1396,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 493,
    shape_dist_traveled: 35.1532,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 494,
    shape_dist_traveled: 35.1716,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 495,
    shape_dist_traveled: 35.1912,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 496,
    shape_dist_traveled: 35.2077,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 497,
    shape_dist_traveled: 35.2307,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 498,
    shape_dist_traveled: 35.2547,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 499,
    shape_dist_traveled: 35.3308,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 500,
    shape_dist_traveled: 35.3878,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 501,
    shape_dist_traveled: 35.4259,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060741,
    shape_pt_lon: -89.99722,
    shape_pt_sequence: 502,
    shape_dist_traveled: 35.628,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060767,
    shape_pt_lon: -89.99807,
    shape_pt_sequence: 503,
    shape_dist_traveled: 35.7051,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060777,
    shape_pt_lon: -89.998354,
    shape_pt_sequence: 504,
    shape_dist_traveled: 35.7311,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06081,
    shape_pt_lon: -89.999536,
    shape_pt_sequence: 505,
    shape_dist_traveled: 35.8381,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060842,
    shape_pt_lon: -90.000408,
    shape_pt_sequence: 506,
    shape_dist_traveled: 35.9172,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060878,
    shape_pt_lon: -90.001628,
    shape_pt_sequence: 507,
    shape_dist_traveled: 36.0273,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06089,
    shape_pt_lon: -90.002047,
    shape_pt_sequence: 508,
    shape_dist_traveled: 36.0653,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06093,
    shape_pt_lon: -90.003676,
    shape_pt_sequence: 509,
    shape_dist_traveled: 36.2124,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -90.004151,
    shape_pt_sequence: 510,
    shape_dist_traveled: 36.2554,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061035,
    shape_pt_lon: -90.007162,
    shape_pt_sequence: 511,
    shape_dist_traveled: 36.5286,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061037,
    shape_pt_lon: -90.007223,
    shape_pt_sequence: 512,
    shape_dist_traveled: 36.5336,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061039,
    shape_pt_lon: -90.007298,
    shape_pt_sequence: 513,
    shape_dist_traveled: 36.5406,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061051,
    shape_pt_lon: -90.007693,
    shape_pt_sequence: 514,
    shape_dist_traveled: 36.5767,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061102,
    shape_pt_lon: -90.009289,
    shape_pt_sequence: 515,
    shape_dist_traveled: 36.7207,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061123,
    shape_pt_lon: -90.010033,
    shape_pt_sequence: 516,
    shape_dist_traveled: 36.7878,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061151,
    shape_pt_lon: -90.01104,
    shape_pt_sequence: 517,
    shape_dist_traveled: 36.8789,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061179,
    shape_pt_lon: -90.011738,
    shape_pt_sequence: 518,
    shape_dist_traveled: 36.9419,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061192,
    shape_pt_lon: -90.012536,
    shape_pt_sequence: 519,
    shape_dist_traveled: 37.0149,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.0612,
    shape_pt_lon: -90.012873,
    shape_pt_sequence: 520,
    shape_dist_traveled: 37.045,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06122,
    shape_pt_lon: -90.013628,
    shape_pt_sequence: 521,
    shape_dist_traveled: 37.113,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061231,
    shape_pt_lon: -90.013952,
    shape_pt_sequence: 522,
    shape_dist_traveled: 37.143,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061272,
    shape_pt_lon: -90.015226,
    shape_pt_sequence: 523,
    shape_dist_traveled: 37.2581,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061293,
    shape_pt_lon: -90.01599,
    shape_pt_sequence: 524,
    shape_dist_traveled: 37.3271,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061339,
    shape_pt_lon: -90.01756,
    shape_pt_sequence: 525,
    shape_dist_traveled: 37.4692,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061356,
    shape_pt_lon: -90.018053,
    shape_pt_sequence: 526,
    shape_dist_traveled: 37.5143,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061403,
    shape_pt_lon: -90.019237,
    shape_pt_sequence: 527,
    shape_dist_traveled: 37.6214,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061419,
    shape_pt_lon: -90.01949,
    shape_pt_sequence: 528,
    shape_dist_traveled: 37.6445,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061451,
    shape_pt_lon: -90.019991,
    shape_pt_sequence: 529,
    shape_dist_traveled: 37.6897,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061467,
    shape_pt_lon: -90.020286,
    shape_pt_sequence: 530,
    shape_dist_traveled: 37.7167,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06148,
    shape_pt_lon: -90.020652,
    shape_pt_sequence: 531,
    shape_dist_traveled: 37.7497,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061527,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 532,
    shape_dist_traveled: 37.797,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -90.021257,
    shape_pt_sequence: 533,
    shape_dist_traveled: 37.8041,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06163,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 534,
    shape_dist_traveled: 37.9246,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061689,
    shape_pt_lon: -90.023451,
    shape_pt_sequence: 535,
    shape_dist_traveled: 38.0038,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 536,
    shape_dist_traveled: 38.0129,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061699,
    shape_pt_lon: -90.0236,
    shape_pt_sequence: 537,
    shape_dist_traveled: 38.0169,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061705,
    shape_pt_lon: -90.023671,
    shape_pt_sequence: 538,
    shape_dist_traveled: 38.0239,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061721,
    shape_pt_lon: -90.023972,
    shape_pt_sequence: 539,
    shape_dist_traveled: 38.051,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061726,
    shape_pt_lon: -90.024207,
    shape_pt_sequence: 540,
    shape_dist_traveled: 38.072,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061717,
    shape_pt_lon: -90.024418,
    shape_pt_sequence: 541,
    shape_dist_traveled: 38.091,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061691,
    shape_pt_lon: -90.024712,
    shape_pt_sequence: 542,
    shape_dist_traveled: 38.1182,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061679,
    shape_pt_lon: -90.024789,
    shape_pt_sequence: 543,
    shape_dist_traveled: 38.1253,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061594,
    shape_pt_lon: -90.025482,
    shape_pt_sequence: 544,
    shape_dist_traveled: 38.1889,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061579,
    shape_pt_lon: -90.025923,
    shape_pt_sequence: 545,
    shape_dist_traveled: 38.229,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061618,
    shape_pt_lon: -90.027535,
    shape_pt_sequence: 546,
    shape_dist_traveled: 38.374,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061632,
    shape_pt_lon: -90.028151,
    shape_pt_sequence: 547,
    shape_dist_traveled: 38.4301,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061643,
    shape_pt_lon: -90.028518,
    shape_pt_sequence: 548,
    shape_dist_traveled: 38.4631,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -90.029399,
    shape_pt_sequence: 549,
    shape_dist_traveled: 38.5431,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061698,
    shape_pt_lon: -90.030753,
    shape_pt_sequence: 550,
    shape_dist_traveled: 38.6652,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061739,
    shape_pt_lon: -90.032218,
    shape_pt_sequence: 551,
    shape_dist_traveled: 38.7982,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061758,
    shape_pt_lon: -90.033019,
    shape_pt_sequence: 552,
    shape_dist_traveled: 38.8703,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061804,
    shape_pt_lon: -90.034866,
    shape_pt_sequence: 553,
    shape_dist_traveled: 39.0374,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061835,
    shape_pt_lon: -90.035983,
    shape_pt_sequence: 554,
    shape_dist_traveled: 39.1384,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -90.038494,
    shape_pt_sequence: 555,
    shape_dist_traveled: 39.3665,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061956,
    shape_pt_lon: -90.040818,
    shape_pt_sequence: 556,
    shape_dist_traveled: 39.5766,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.061999,
    shape_pt_lon: -90.042573,
    shape_pt_sequence: 557,
    shape_dist_traveled: 39.7347,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062016,
    shape_pt_lon: -90.043213,
    shape_pt_sequence: 558,
    shape_dist_traveled: 39.7927,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062055,
    shape_pt_lon: -90.04382,
    shape_pt_sequence: 559,
    shape_dist_traveled: 39.8479,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062113,
    shape_pt_lon: -90.044343,
    shape_pt_sequence: 560,
    shape_dist_traveled: 39.8964,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062426,
    shape_pt_lon: -90.046347,
    shape_pt_sequence: 561,
    shape_dist_traveled: 40.0807,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062623,
    shape_pt_lon: -90.047658,
    shape_pt_sequence: 562,
    shape_dist_traveled: 40.2007,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062775,
    shape_pt_lon: -90.048632,
    shape_pt_sequence: 563,
    shape_dist_traveled: 40.2904,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062829,
    shape_pt_lon: -90.049039,
    shape_pt_sequence: 564,
    shape_dist_traveled: 40.3279,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062858,
    shape_pt_lon: -90.049269,
    shape_pt_sequence: 565,
    shape_dist_traveled: 40.3491,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06288,
    shape_pt_lon: -90.049552,
    shape_pt_sequence: 566,
    shape_dist_traveled: 40.3752,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06289,
    shape_pt_lon: -90.049862,
    shape_pt_sequence: 567,
    shape_dist_traveled: 40.4033,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062904,
    shape_pt_lon: -90.050603,
    shape_pt_sequence: 568,
    shape_dist_traveled: 40.4703,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062929,
    shape_pt_lon: -90.051739,
    shape_pt_sequence: 569,
    shape_dist_traveled: 40.5733,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06293,
    shape_pt_lon: -90.051829,
    shape_pt_sequence: 570,
    shape_dist_traveled: 40.5813,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062931,
    shape_pt_lon: -90.051918,
    shape_pt_sequence: 571,
    shape_dist_traveled: 40.5893,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.062966,
    shape_pt_lon: -90.053281,
    shape_pt_sequence: 572,
    shape_dist_traveled: 40.7124,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063045,
    shape_pt_lon: -90.056698,
    shape_pt_sequence: 573,
    shape_dist_traveled: 41.0215,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06309,
    shape_pt_lon: -90.05856,
    shape_pt_sequence: 574,
    shape_dist_traveled: 41.1896,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063202,
    shape_pt_lon: -90.058881,
    shape_pt_sequence: 575,
    shape_dist_traveled: 41.2214,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06323,
    shape_pt_lon: -90.058937,
    shape_pt_sequence: 576,
    shape_dist_traveled: 41.2281,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063285,
    shape_pt_lon: -90.059002,
    shape_pt_sequence: 577,
    shape_dist_traveled: 41.2359,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.063404,
    shape_pt_lon: -90.059076,
    shape_pt_sequence: 578,
    shape_dist_traveled: 41.2506,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 579,
    shape_dist_traveled: 41.2681,
  },
  {
    shape_id: 38213,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 580,
    shape_dist_traveled: 41.4046,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1171,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1391,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1902,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2119,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2303,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2623,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2819,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2984,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3214,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.3454,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.993928,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4215,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060663,
    shape_pt_lon: -89.994563,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4785,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.060677,
    shape_pt_lon: -89.994984,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5166,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06073,
    shape_pt_lon: -89.994983,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.5226,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06209,
    shape_pt_lon: -89.994944,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6746,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.0636,
    shape_pt_lon: -89.9949,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8437,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064586,
    shape_pt_lon: -89.994867,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9537,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064763,
    shape_pt_lon: -89.994832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9739,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064936,
    shape_pt_lon: -89.994755,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9942,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065049,
    shape_pt_lon: -89.994673,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0094,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065167,
    shape_pt_lon: -89.994554,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0258,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065282,
    shape_pt_lon: -89.994386,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0465,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065374,
    shape_pt_lon: -89.99417,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0679,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065422,
    shape_pt_lon: -89.993977,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0869,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065439,
    shape_pt_lon: -89.993768,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.105,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06543,
    shape_pt_lon: -89.993608,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.12,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065337,
    shape_pt_lon: -89.99318,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1606,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065266,
    shape_pt_lon: -89.992885,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1878,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.065093,
    shape_pt_lon: -89.992161,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.2564,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064998,
    shape_pt_lon: -89.991867,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.2847,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064872,
    shape_pt_lon: -89.991583,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3142,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.4271,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064338,
    shape_pt_lon: -89.990428,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.4347,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064297,
    shape_pt_lon: -89.990341,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.4441,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.064946,
    shape_pt_lon: -89.98989,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.5274,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067492,
    shape_pt_lon: -89.988165,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8523,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067972,
    shape_pt_lon: -89.987839,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9127,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068353,
    shape_pt_lon: -89.98761,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9605,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068463,
    shape_pt_lon: -89.987452,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.979,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068553,
    shape_pt_lon: -89.98729,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.997,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06864,
    shape_pt_lon: -89.986973,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0277,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.986391,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0797,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068652,
    shape_pt_lon: -89.98633,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.0857,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06873,
    shape_pt_lon: -89.986199,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1001,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068731,
    shape_pt_lon: -89.986077,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1111,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068737,
    shape_pt_lon: -89.98601,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.1172,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.1312,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068747,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.1752,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06865,
    shape_pt_lon: -89.985071,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.2044,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068625,
    shape_pt_lon: -89.984978,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.2126,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068562,
    shape_pt_lon: -89.984818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.2292,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068495,
    shape_pt_lon: -89.984687,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.2428,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068416,
    shape_pt_lon: -89.984587,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.2562,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068328,
    shape_pt_lon: -89.984503,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.2676,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068199,
    shape_pt_lon: -89.984432,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2842,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068038,
    shape_pt_lon: -89.984391,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3025,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067921,
    shape_pt_lon: -89.984398,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.3155,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067789,
    shape_pt_lon: -89.984438,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.331,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067707,
    shape_pt_lon: -89.984488,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.3408,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067603,
    shape_pt_lon: -89.984597,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.3564,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06755,
    shape_pt_lon: -89.984689,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.3673,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067501,
    shape_pt_lon: -89.984828,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.3803,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06749,
    shape_pt_lon: -89.98499,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.3953,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067507,
    shape_pt_lon: -89.985162,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.4104,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067575,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.4307,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067659,
    shape_pt_lon: -89.985504,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.4463,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067739,
    shape_pt_lon: -89.985591,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.4583,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.067882,
    shape_pt_lon: -89.985692,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.4767,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06811,
    shape_pt_lon: -89.985803,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.5036,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06868,
    shape_pt_lon: -89.985849,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.5678,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.068739,
    shape_pt_lon: -89.985854,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.5738,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.06903,
    shape_pt_lon: -89.985883,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.607,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.070991,
    shape_pt_lon: -89.986079,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.8267,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.072129,
    shape_pt_lon: -89.986183,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.955,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.072364,
    shape_pt_lon: -89.986204,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.9811,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.072728,
    shape_pt_lon: -89.986197,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.0221,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.073,
    shape_pt_lon: -89.986175,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.0522,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.073206,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.0753,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.073437,
    shape_pt_lon: -89.986107,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1017,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.074099,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.1768,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.074912,
    shape_pt_lon: -89.985771,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.2694,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.075888,
    shape_pt_lon: -89.985547,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.3802,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.07617,
    shape_pt_lon: -89.985479,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.4127,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.076291,
    shape_pt_lon: -89.985453,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.4259,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.076441,
    shape_pt_lon: -89.985422,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.4432,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.076887,
    shape_pt_lon: -89.985323,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.494,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.077022,
    shape_pt_lon: -89.985293,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.5093,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.077076,
    shape_pt_lon: -89.985282,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.5153,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.077372,
    shape_pt_lon: -89.985227,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.5487,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.078192,
    shape_pt_lon: -89.985049,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.6421,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.078692,
    shape_pt_lon: -89.984928,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.6992,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.07896,
    shape_pt_lon: -89.984866,
    shape_pt_sequence: 94,
    shape_dist_traveled: 3.7296,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.079172,
    shape_pt_lon: -89.984841,
    shape_pt_sequence: 95,
    shape_dist_traveled: 3.7537,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.079338,
    shape_pt_lon: -89.984819,
    shape_pt_sequence: 96,
    shape_dist_traveled: 3.7718,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.079397,
    shape_pt_lon: -89.984814,
    shape_pt_sequence: 97,
    shape_dist_traveled: 3.7788,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.079463,
    shape_pt_lon: -89.984867,
    shape_pt_sequence: 98,
    shape_dist_traveled: 3.7874,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.079661,
    shape_pt_lon: -89.984876,
    shape_pt_sequence: 99,
    shape_dist_traveled: 3.8105,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.07991,
    shape_pt_lon: -89.98491,
    shape_pt_sequence: 100,
    shape_dist_traveled: 3.8376,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.080171,
    shape_pt_lon: -89.984982,
    shape_pt_sequence: 101,
    shape_dist_traveled: 3.8682,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.081268,
    shape_pt_lon: -89.985313,
    shape_pt_sequence: 102,
    shape_dist_traveled: 3.9939,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.081571,
    shape_pt_lon: -89.985408,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.029,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.082231,
    shape_pt_lon: -89.98561,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.1052,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.08285,
    shape_pt_lon: -89.985797,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.1763,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.083306,
    shape_pt_lon: -89.985961,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.2294,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.083669,
    shape_pt_lon: -89.98611,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.2727,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.084267,
    shape_pt_lon: -89.98637,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.3436,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.084769,
    shape_pt_lon: -89.986699,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.4071,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.084891,
    shape_pt_lon: -89.986783,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.4232,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086123,
    shape_pt_lon: -89.987628,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.5799,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086229,
    shape_pt_lon: -89.98769,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.5933,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.0864,
    shape_pt_lon: -89.987761,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.6132,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086443,
    shape_pt_lon: -89.987776,
    shape_pt_sequence: 114,
    shape_dist_traveled: 4.6186,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.08648,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 115,
    shape_dist_traveled: 4.6227,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -89.987797,
    shape_pt_sequence: 116,
    shape_dist_traveled: 4.6288,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086709,
    shape_pt_lon: -89.987817,
    shape_pt_sequence: 117,
    shape_dist_traveled: 4.6489,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.086793,
    shape_pt_lon: -89.987819,
    shape_pt_sequence: 118,
    shape_dist_traveled: 4.6579,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.087974,
    shape_pt_lon: -89.98775,
    shape_pt_sequence: 119,
    shape_dist_traveled: 4.7901,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.089618,
    shape_pt_lon: -89.987673,
    shape_pt_sequence: 120,
    shape_dist_traveled: 4.9742,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.091535,
    shape_pt_lon: -89.987591,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.1893,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.09192,
    shape_pt_lon: -89.987578,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.2324,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.092549,
    shape_pt_lon: -89.987559,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.3024,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.092839,
    shape_pt_lon: -89.98755,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.3354,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.095038,
    shape_pt_lon: -89.987478,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.5814,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.095598,
    shape_pt_lon: -89.987454,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.6445,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.09605,
    shape_pt_lon: -89.987437,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.6945,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.09618,
    shape_pt_lon: -89.987432,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.7096,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.096287,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.7216,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.09647,
    shape_pt_lon: -89.987423,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.7416,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.09737,
    shape_pt_lon: -89.987396,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.8426,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.098383,
    shape_pt_lon: -89.987354,
    shape_pt_sequence: 132,
    shape_dist_traveled: 5.9556,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.099316,
    shape_pt_lon: -89.987305,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.0608,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.099659,
    shape_pt_lon: -89.987268,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.0989,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.100146,
    shape_pt_lon: -89.987178,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.1535,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.100501,
    shape_pt_lon: -89.987104,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.1941,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.101112,
    shape_pt_lon: -89.986943,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.2645,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.101187,
    shape_pt_lon: -89.986923,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.2727,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.102119,
    shape_pt_lon: -89.986662,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.3795,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.103101,
    shape_pt_lon: -89.98637,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.4925,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.10398,
    shape_pt_lon: -89.986102,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.5944,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.104042,
    shape_pt_lon: -89.986082,
    shape_pt_sequence: 142,
    shape_dist_traveled: 6.6007,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.104471,
    shape_pt_lon: -89.985951,
    shape_pt_sequence: 143,
    shape_dist_traveled: 6.6511,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.104962,
    shape_pt_lon: -89.985801,
    shape_pt_sequence: 144,
    shape_dist_traveled: 6.7067,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.105191,
    shape_pt_lon: -89.985722,
    shape_pt_sequence: 145,
    shape_dist_traveled: 6.7336,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 146,
    shape_dist_traveled: 6.7507,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.105461,
    shape_pt_lon: -89.985572,
    shape_pt_sequence: 147,
    shape_dist_traveled: 6.7668,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.105766,
    shape_pt_lon: -89.985349,
    shape_pt_sequence: 148,
    shape_dist_traveled: 6.8063,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.105855,
    shape_pt_lon: -89.985281,
    shape_pt_sequence: 149,
    shape_dist_traveled: 6.8179,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.106109,
    shape_pt_lon: -89.985139,
    shape_pt_sequence: 150,
    shape_dist_traveled: 6.8497,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.106288,
    shape_pt_lon: -89.985076,
    shape_pt_sequence: 151,
    shape_dist_traveled: 6.8706,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.106439,
    shape_pt_lon: -89.985046,
    shape_pt_sequence: 152,
    shape_dist_traveled: 6.8877,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.106473,
    shape_pt_lon: -89.985039,
    shape_pt_sequence: 153,
    shape_dist_traveled: 6.8918,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.107082,
    shape_pt_lon: -89.98499,
    shape_pt_sequence: 154,
    shape_dist_traveled: 6.9599,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.107562,
    shape_pt_lon: -89.984954,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.013,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.107752,
    shape_pt_lon: -89.984941,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.0351,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.108534,
    shape_pt_lon: -89.98489,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.1222,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.108639,
    shape_pt_lon: -89.984885,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.1343,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.10921,
    shape_pt_lon: -89.984863,
    shape_pt_sequence: 159,
    shape_dist_traveled: 7.1983,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.10989,
    shape_pt_lon: -89.984834,
    shape_pt_sequence: 160,
    shape_dist_traveled: 7.2743,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.110006,
    shape_pt_lon: -89.984829,
    shape_pt_sequence: 161,
    shape_dist_traveled: 7.2873,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.110149,
    shape_pt_lon: -89.984823,
    shape_pt_sequence: 162,
    shape_dist_traveled: 7.3034,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.110567,
    shape_pt_lon: -89.984807,
    shape_pt_sequence: 163,
    shape_dist_traveled: 7.3504,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.111243,
    shape_pt_lon: -89.984777,
    shape_pt_sequence: 164,
    shape_dist_traveled: 7.4254,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.111846,
    shape_pt_lon: -89.984751,
    shape_pt_sequence: 165,
    shape_dist_traveled: 7.4935,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.111911,
    shape_pt_lon: -89.984748,
    shape_pt_sequence: 166,
    shape_dist_traveled: 7.5005,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.112357,
    shape_pt_lon: -89.984728,
    shape_pt_sequence: 167,
    shape_dist_traveled: 7.5505,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.112899,
    shape_pt_lon: -89.984701,
    shape_pt_sequence: 168,
    shape_dist_traveled: 7.6116,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.113566,
    shape_pt_lon: -89.984668,
    shape_pt_sequence: 169,
    shape_dist_traveled: 7.6857,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.114051,
    shape_pt_lon: -89.984624,
    shape_pt_sequence: 170,
    shape_dist_traveled: 7.7408,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.114395,
    shape_pt_lon: -89.984581,
    shape_pt_sequence: 171,
    shape_dist_traveled: 7.7789,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.114489,
    shape_pt_lon: -89.984566,
    shape_pt_sequence: 172,
    shape_dist_traveled: 7.7901,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.114669,
    shape_pt_lon: -89.984538,
    shape_pt_sequence: 173,
    shape_dist_traveled: 7.8102,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.115039,
    shape_pt_lon: -89.984509,
    shape_pt_sequence: 174,
    shape_dist_traveled: 7.8513,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.115676,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 175,
    shape_dist_traveled: 7.9224,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.115821,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 176,
    shape_dist_traveled: 7.9394,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.115969,
    shape_pt_lon: -89.984473,
    shape_pt_sequence: 177,
    shape_dist_traveled: 7.9554,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.11752,
    shape_pt_lon: -89.984426,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.1294,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.117588,
    shape_pt_lon: -89.984376,
    shape_pt_sequence: 179,
    shape_dist_traveled: 8.138,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.117736,
    shape_pt_lon: -89.984366,
    shape_pt_sequence: 180,
    shape_dist_traveled: 8.1551,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.118021,
    shape_pt_lon: -89.984351,
    shape_pt_sequence: 181,
    shape_dist_traveled: 8.1871,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.11869,
    shape_pt_lon: -89.984292,
    shape_pt_sequence: 182,
    shape_dist_traveled: 8.2622,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.118836,
    shape_pt_lon: -89.984293,
    shape_pt_sequence: 183,
    shape_dist_traveled: 8.2782,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.119485,
    shape_pt_lon: -89.98426,
    shape_pt_sequence: 184,
    shape_dist_traveled: 8.3513,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.120497,
    shape_pt_lon: -89.984232,
    shape_pt_sequence: 185,
    shape_dist_traveled: 8.4643,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12098,
    shape_pt_lon: -89.984216,
    shape_pt_sequence: 186,
    shape_dist_traveled: 8.5184,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12175,
    shape_pt_lon: -89.98419,
    shape_pt_sequence: 187,
    shape_dist_traveled: 8.6044,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.122608,
    shape_pt_lon: -89.984168,
    shape_pt_sequence: 188,
    shape_dist_traveled: 8.7004,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.123337,
    shape_pt_lon: -89.984141,
    shape_pt_sequence: 189,
    shape_dist_traveled: 8.7824,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.123873,
    shape_pt_lon: -89.984126,
    shape_pt_sequence: 190,
    shape_dist_traveled: 8.8425,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12464,
    shape_pt_lon: -89.9841,
    shape_pt_sequence: 191,
    shape_dist_traveled: 8.9275,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125052,
    shape_pt_lon: -89.984099,
    shape_pt_sequence: 192,
    shape_dist_traveled: 8.9745,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125106,
    shape_pt_lon: -89.984098,
    shape_pt_sequence: 193,
    shape_dist_traveled: 8.9805,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12553,
    shape_pt_lon: -89.98409,
    shape_pt_sequence: 194,
    shape_dist_traveled: 9.0275,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125647,
    shape_pt_lon: -89.98403,
    shape_pt_sequence: 195,
    shape_dist_traveled: 9.0414,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125701,
    shape_pt_lon: -89.983977,
    shape_pt_sequence: 196,
    shape_dist_traveled: 9.0492,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125828,
    shape_pt_lon: -89.983733,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.0753,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125898,
    shape_pt_lon: -89.983502,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.0978,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125865,
    shape_pt_lon: -89.982469,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.1908,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125819,
    shape_pt_lon: -89.980482,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.3709,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125786,
    shape_pt_lon: -89.979109,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.495,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125769,
    shape_pt_lon: -89.978458,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.554,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125749,
    shape_pt_lon: -89.977673,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.625,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12574,
    shape_pt_lon: -89.977202,
    shape_pt_sequence: 204,
    shape_dist_traveled: 9.667,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.125701,
    shape_pt_lon: -89.975875,
    shape_pt_sequence: 205,
    shape_dist_traveled: 9.7871,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.126501,
    shape_pt_lon: -89.975847,
    shape_pt_sequence: 206,
    shape_dist_traveled: 9.8762,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.126739,
    shape_pt_lon: -89.975851,
    shape_pt_sequence: 207,
    shape_dist_traveled: 9.9032,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.126858,
    shape_pt_lon: -89.975864,
    shape_pt_sequence: 208,
    shape_dist_traveled: 9.9162,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.127219,
    shape_pt_lon: -89.975939,
    shape_pt_sequence: 209,
    shape_dist_traveled: 9.9578,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.127601,
    shape_pt_lon: -89.976077,
    shape_pt_sequence: 210,
    shape_dist_traveled: 10.0015,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.128512,
    shape_pt_lon: -89.976425,
    shape_pt_sequence: 211,
    shape_dist_traveled: 10.1084,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.129029,
    shape_pt_lon: -89.97662,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.1691,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.976666,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.1837,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.129395,
    shape_pt_lon: -89.976756,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.2118,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.129637,
    shape_pt_lon: -89.976815,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.2393,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.129954,
    shape_pt_lon: -89.976842,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.2754,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.976815,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.3655,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.13094,
    shape_pt_lon: -89.976832,
    shape_pt_sequence: 218,
    shape_dist_traveled: 10.3856,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.130956,
    shape_pt_lon: -89.976835,
    shape_pt_sequence: 219,
    shape_dist_traveled: 10.3876,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.131806,
    shape_pt_lon: -89.977139,
    shape_pt_sequence: 220,
    shape_dist_traveled: 10.4866,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.131983,
    shape_pt_lon: -89.977206,
    shape_pt_sequence: 221,
    shape_dist_traveled: 10.5075,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.132153,
    shape_pt_lon: -89.977269,
    shape_pt_sequence: 222,
    shape_dist_traveled: 10.5274,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.13229,
    shape_pt_lon: -89.977317,
    shape_pt_sequence: 223,
    shape_dist_traveled: 10.5429,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.132519,
    shape_pt_lon: -89.97737,
    shape_pt_sequence: 224,
    shape_dist_traveled: 10.5694,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.132779,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 225,
    shape_dist_traveled: 10.5986,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.133218,
    shape_pt_lon: -89.977412,
    shape_pt_sequence: 226,
    shape_dist_traveled: 10.6476,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 227,
    shape_dist_traveled: 10.6536,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.133331,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 228,
    shape_dist_traveled: 10.6606,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.133376,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 229,
    shape_dist_traveled: 10.6657,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.133404,
    shape_pt_lon: -89.977407,
    shape_pt_sequence: 230,
    shape_dist_traveled: 10.6687,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.133457,
    shape_pt_lon: -89.977359,
    shape_pt_sequence: 231,
    shape_dist_traveled: 10.6759,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.13418,
    shape_pt_lon: -89.977316,
    shape_pt_sequence: 232,
    shape_dist_traveled: 10.757,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.134416,
    shape_pt_lon: -89.977304,
    shape_pt_sequence: 233,
    shape_dist_traveled: 10.783,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.135645,
    shape_pt_lon: -89.977255,
    shape_pt_sequence: 234,
    shape_dist_traveled: 10.9201,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.136406,
    shape_pt_lon: -89.977227,
    shape_pt_sequence: 235,
    shape_dist_traveled: 11.0061,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.136608,
    shape_pt_lon: -89.977242,
    shape_pt_sequence: 236,
    shape_dist_traveled: 11.0282,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.136819,
    shape_pt_lon: -89.977297,
    shape_pt_sequence: 237,
    shape_dist_traveled: 11.0527,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137001,
    shape_pt_lon: -89.977386,
    shape_pt_sequence: 238,
    shape_dist_traveled: 11.0743,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137152,
    shape_pt_lon: -89.977502,
    shape_pt_sequence: 239,
    shape_dist_traveled: 11.094,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137327,
    shape_pt_lon: -89.977721,
    shape_pt_sequence: 240,
    shape_dist_traveled: 11.1223,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137387,
    shape_pt_lon: -89.977822,
    shape_pt_sequence: 241,
    shape_dist_traveled: 11.1331,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137457,
    shape_pt_lon: -89.977987,
    shape_pt_sequence: 242,
    shape_dist_traveled: 11.1501,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137706,
    shape_pt_lon: -89.978643,
    shape_pt_sequence: 243,
    shape_dist_traveled: 11.2154,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137716,
    shape_pt_lon: -89.978753,
    shape_pt_sequence: 244,
    shape_dist_traveled: 11.2254,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137763,
    shape_pt_lon: -89.978813,
    shape_pt_sequence: 245,
    shape_dist_traveled: 11.2333,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137806,
    shape_pt_lon: -89.978849,
    shape_pt_sequence: 246,
    shape_dist_traveled: 11.2391,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.137946,
    shape_pt_lon: -89.978957,
    shape_pt_sequence: 247,
    shape_dist_traveled: 11.258,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.138067,
    shape_pt_lon: -89.979004,
    shape_pt_sequence: 248,
    shape_dist_traveled: 11.2716,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.138197,
    shape_pt_lon: -89.979029,
    shape_pt_sequence: 249,
    shape_dist_traveled: 11.2867,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.138379,
    shape_pt_lon: -89.979009,
    shape_pt_sequence: 250,
    shape_dist_traveled: 11.3068,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.139028,
    shape_pt_lon: -89.978909,
    shape_pt_sequence: 251,
    shape_dist_traveled: 11.3802,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.142267,
    shape_pt_lon: -89.978446,
    shape_pt_sequence: 252,
    shape_dist_traveled: 11.7437,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.143416,
    shape_pt_lon: -89.97828,
    shape_pt_sequence: 253,
    shape_dist_traveled: 11.8735,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.144406,
    shape_pt_lon: -89.978138,
    shape_pt_sequence: 254,
    shape_dist_traveled: 11.9853,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.145418,
    shape_pt_lon: -89.977991,
    shape_pt_sequence: 255,
    shape_dist_traveled: 12.099,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.146443,
    shape_pt_lon: -89.977842,
    shape_pt_sequence: 256,
    shape_dist_traveled: 12.2148,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.146627,
    shape_pt_lon: -89.977818,
    shape_pt_sequence: 257,
    shape_dist_traveled: 12.2349,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.146843,
    shape_pt_lon: -89.977787,
    shape_pt_sequence: 258,
    shape_dist_traveled: 12.2591,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.147227,
    shape_pt_lon: -89.977721,
    shape_pt_sequence: 259,
    shape_dist_traveled: 12.3025,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.147314,
    shape_pt_lon: -89.977688,
    shape_pt_sequence: 260,
    shape_dist_traveled: 12.3129,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.147506,
    shape_pt_lon: -89.977591,
    shape_pt_sequence: 261,
    shape_dist_traveled: 12.3367,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.147619,
    shape_pt_lon: -89.977527,
    shape_pt_sequence: 262,
    shape_dist_traveled: 12.3497,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.148159,
    shape_pt_lon: -89.977206,
    shape_pt_sequence: 263,
    shape_dist_traveled: 12.4172,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.148203,
    shape_pt_lon: -89.977187,
    shape_pt_sequence: 264,
    shape_dist_traveled: 12.4226,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.148376,
    shape_pt_lon: -89.977108,
    shape_pt_sequence: 265,
    shape_dist_traveled: 12.4429,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.14857,
    shape_pt_lon: -89.977052,
    shape_pt_sequence: 266,
    shape_dist_traveled: 12.4654,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.148737,
    shape_pt_lon: -89.977028,
    shape_pt_sequence: 267,
    shape_dist_traveled: 12.4835,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.148962,
    shape_pt_lon: -89.977006,
    shape_pt_sequence: 268,
    shape_dist_traveled: 12.5096,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.15,
    shape_pt_lon: -89.976871,
    shape_pt_sequence: 269,
    shape_dist_traveled: 12.6262,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.150371,
    shape_pt_lon: -89.97689,
    shape_pt_sequence: 270,
    shape_dist_traveled: 12.6672,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.150541,
    shape_pt_lon: -89.976905,
    shape_pt_sequence: 271,
    shape_dist_traveled: 12.6863,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.151098,
    shape_pt_lon: -89.976958,
    shape_pt_sequence: 272,
    shape_dist_traveled: 12.7495,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.151269,
    shape_pt_lon: -89.976959,
    shape_pt_sequence: 273,
    shape_dist_traveled: 12.7685,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.151619,
    shape_pt_lon: -89.976911,
    shape_pt_sequence: 274,
    shape_dist_traveled: 12.8079,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.154661,
    shape_pt_lon: -89.976477,
    shape_pt_sequence: 275,
    shape_dist_traveled: 13.1501,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.156959,
    shape_pt_lon: -89.976146,
    shape_pt_sequence: 276,
    shape_dist_traveled: 13.4097,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.157397,
    shape_pt_lon: -89.976068,
    shape_pt_sequence: 277,
    shape_dist_traveled: 13.4592,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.975997,
    shape_pt_sequence: 278,
    shape_dist_traveled: 13.5156,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.157942,
    shape_pt_lon: -89.975988,
    shape_pt_sequence: 279,
    shape_dist_traveled: 13.5206,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 280,
    shape_dist_traveled: 13.5257,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 281,
    shape_dist_traveled: 13.6185,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 282,
    shape_dist_traveled: 13.6426,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 283,
    shape_dist_traveled: 13.8066,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 284,
    shape_dist_traveled: 13.9007,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 285,
    shape_dist_traveled: 13.9517,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.0617,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.1187,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.1527,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.2407,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.3007,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 291,
    shape_dist_traveled: 14.3808,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 292,
    shape_dist_traveled: 14.3928,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 293,
    shape_dist_traveled: 14.4878,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 294,
    shape_dist_traveled: 14.5768,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 295,
    shape_dist_traveled: 14.6558,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 296,
    shape_dist_traveled: 14.6708,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 297,
    shape_dist_traveled: 14.7359,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 298,
    shape_dist_traveled: 14.7619,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 299,
    shape_dist_traveled: 14.8169,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 300,
    shape_dist_traveled: 14.9039,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 301,
    shape_dist_traveled: 14.9679,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.0469,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.104,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.193,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.209,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.282,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.33,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.372,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.381,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.3871,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.174661,
    shape_pt_lon: -89.975432,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.3931,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.175221,
    shape_pt_lon: -89.975417,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.4551,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.175985,
    shape_pt_lon: -89.975407,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.5411,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.176991,
    shape_pt_lon: -89.97531,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.6534,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.177785,
    shape_pt_lon: -89.975278,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.7424,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.178151,
    shape_pt_lon: -89.975269,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.7834,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.178826,
    shape_pt_lon: -89.975247,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.8595,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.17998,
    shape_pt_lon: -89.975208,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.9885,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.180943,
    shape_pt_lon: -89.975177,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.0966,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.181935,
    shape_pt_lon: -89.975149,
    shape_pt_sequence: 320,
    shape_dist_traveled: 16.2076,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.182339,
    shape_pt_lon: -89.975135,
    shape_pt_sequence: 321,
    shape_dist_traveled: 16.2526,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.183698,
    shape_pt_lon: -89.975095,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.4046,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.184057,
    shape_pt_lon: -89.975127,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.4448,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.184342,
    shape_pt_lon: -89.975184,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.4771,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.184656,
    shape_pt_lon: -89.975288,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.5133,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.184992,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.5538,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.185458,
    shape_pt_lon: -89.975652,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.6091,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.18571,
    shape_pt_lon: -89.975729,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.638,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.186015,
    shape_pt_lon: -89.975787,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.6725,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.186078,
    shape_pt_lon: -89.975793,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.6795,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.186562,
    shape_pt_lon: -89.975801,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.7335,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.187312,
    shape_pt_lon: -89.975801,
    shape_pt_sequence: 332,
    shape_dist_traveled: 16.8175,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.187399,
    shape_pt_lon: -89.975741,
    shape_pt_sequence: 333,
    shape_dist_traveled: 16.8287,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.188119,
    shape_pt_lon: -89.975739,
    shape_pt_sequence: 334,
    shape_dist_traveled: 16.9087,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.189106,
    shape_pt_lon: -89.97572,
    shape_pt_sequence: 335,
    shape_dist_traveled: 17.0197,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.189369,
    shape_pt_lon: -89.975722,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.0487,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.190212,
    shape_pt_lon: -89.975733,
    shape_pt_sequence: 337,
    shape_dist_traveled: 17.1437,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.191299,
    shape_pt_lon: -89.975738,
    shape_pt_sequence: 338,
    shape_dist_traveled: 17.2647,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.191532,
    shape_pt_lon: -89.975719,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.2908,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.191598,
    shape_pt_lon: -89.975724,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.2989,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.191707,
    shape_pt_lon: -89.975736,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.3109,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.191837,
    shape_pt_lon: -89.975736,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.3259,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.193292,
    shape_pt_lon: -89.975726,
    shape_pt_sequence: 343,
    shape_dist_traveled: 17.4879,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.19501,
    shape_pt_lon: -89.975719,
    shape_pt_sequence: 344,
    shape_dist_traveled: 17.6809,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.195219,
    shape_pt_lon: -89.975724,
    shape_pt_sequence: 345,
    shape_dist_traveled: 17.704,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.195309,
    shape_pt_lon: -89.975728,
    shape_pt_sequence: 346,
    shape_dist_traveled: 17.714,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.196413,
    shape_pt_lon: -89.975722,
    shape_pt_sequence: 347,
    shape_dist_traveled: 17.838,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.196499,
    shape_pt_lon: -89.975783,
    shape_pt_sequence: 348,
    shape_dist_traveled: 17.8482,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.196803,
    shape_pt_lon: -89.975782,
    shape_pt_sequence: 349,
    shape_dist_traveled: 17.8822,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.197632,
    shape_pt_lon: -89.975787,
    shape_pt_sequence: 350,
    shape_dist_traveled: 17.9753,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.198123,
    shape_pt_lon: -89.975811,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.0303,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.198573,
    shape_pt_lon: -89.975818,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.0803,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.199177,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.1483,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.199239,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.1553,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.200682,
    shape_pt_lon: -89.975807,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.3163,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.202095,
    shape_pt_lon: -89.975798,
    shape_pt_sequence: 356,
    shape_dist_traveled: 18.4743,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.203678,
    shape_pt_lon: -89.975779,
    shape_pt_sequence: 357,
    shape_dist_traveled: 18.6523,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.204457,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 358,
    shape_dist_traveled: 18.7393,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.204742,
    shape_pt_lon: -89.975737,
    shape_pt_sequence: 359,
    shape_dist_traveled: 18.7715,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.205026,
    shape_pt_lon: -89.975723,
    shape_pt_sequence: 360,
    shape_dist_traveled: 18.8025,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206234,
    shape_pt_lon: -89.97572,
    shape_pt_sequence: 361,
    shape_dist_traveled: 18.9385,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207188,
    shape_pt_lon: -89.975712,
    shape_pt_sequence: 362,
    shape_dist_traveled: 19.0445,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207675,
    shape_pt_lon: -89.975713,
    shape_pt_sequence: 363,
    shape_dist_traveled: 19.0995,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208009,
    shape_pt_lon: -89.975692,
    shape_pt_sequence: 364,
    shape_dist_traveled: 19.1365,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208092,
    shape_pt_lon: -89.975679,
    shape_pt_sequence: 365,
    shape_dist_traveled: 19.1466,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208479,
    shape_pt_lon: -89.975611,
    shape_pt_sequence: 366,
    shape_dist_traveled: 19.19,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.209084,
    shape_pt_lon: -89.975603,
    shape_pt_sequence: 367,
    shape_dist_traveled: 19.258,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.209342,
    shape_pt_lon: -89.9756,
    shape_pt_sequence: 368,
    shape_dist_traveled: 19.286,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.20952,
    shape_pt_lon: -89.975593,
    shape_pt_sequence: 369,
    shape_dist_traveled: 19.306,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.210829,
    shape_pt_lon: -89.97559,
    shape_pt_sequence: 370,
    shape_dist_traveled: 19.453,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21176,
    shape_pt_lon: -89.975581,
    shape_pt_sequence: 371,
    shape_dist_traveled: 19.557,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.213699,
    shape_pt_lon: -89.975567,
    shape_pt_sequence: 372,
    shape_dist_traveled: 19.774,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.213956,
    shape_pt_lon: -89.975567,
    shape_pt_sequence: 373,
    shape_dist_traveled: 19.803,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.214802,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 374,
    shape_dist_traveled: 19.897,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21536,
    shape_pt_lon: -89.975556,
    shape_pt_sequence: 375,
    shape_dist_traveled: 19.96,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.215816,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 376,
    shape_dist_traveled: 20.011,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.218181,
    shape_pt_lon: -89.975536,
    shape_pt_sequence: 377,
    shape_dist_traveled: 20.276,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21823,
    shape_pt_lon: -89.975536,
    shape_pt_sequence: 378,
    shape_dist_traveled: 20.281,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220189,
    shape_pt_lon: -89.975522,
    shape_pt_sequence: 379,
    shape_dist_traveled: 20.5,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22104,
    shape_pt_lon: -89.975549,
    shape_pt_sequence: 380,
    shape_dist_traveled: 20.5961,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221108,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 381,
    shape_dist_traveled: 20.6031,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221149,
    shape_pt_lon: -89.975314,
    shape_pt_sequence: 382,
    shape_dist_traveled: 20.6247,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221181,
    shape_pt_lon: -89.974799,
    shape_pt_sequence: 383,
    shape_dist_traveled: 20.6718,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221242,
    shape_pt_lon: -89.973495,
    shape_pt_sequence: 384,
    shape_dist_traveled: 20.789,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221274,
    shape_pt_lon: -89.972501,
    shape_pt_sequence: 385,
    shape_dist_traveled: 20.8791,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221285,
    shape_pt_lon: -89.972166,
    shape_pt_sequence: 386,
    shape_dist_traveled: 20.9091,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221331,
    shape_pt_lon: -89.970692,
    shape_pt_sequence: 387,
    shape_dist_traveled: 21.0422,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221312,
    shape_pt_lon: -89.970213,
    shape_pt_sequence: 388,
    shape_dist_traveled: 21.0863,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 389,
    shape_dist_traveled: 21.1217,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 390,
    shape_dist_traveled: 21.2008,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 391,
    shape_dist_traveled: 21.2279,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 392,
    shape_dist_traveled: 21.238,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 393,
    shape_dist_traveled: 21.258,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 394,
    shape_dist_traveled: 21.281,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 395,
    shape_dist_traveled: 21.297,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221076,
    shape_pt_lon: -89.967813,
    shape_pt_sequence: 396,
    shape_dist_traveled: 21.305,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221092,
    shape_pt_lon: -89.967357,
    shape_pt_sequence: 397,
    shape_dist_traveled: 21.3461,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221097,
    shape_pt_lon: -89.966533,
    shape_pt_sequence: 398,
    shape_dist_traveled: 21.4211,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221061,
    shape_pt_lon: -89.965725,
    shape_pt_sequence: 399,
    shape_dist_traveled: 21.4942,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221042,
    shape_pt_lon: -89.965379,
    shape_pt_sequence: 400,
    shape_dist_traveled: 21.5253,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221019,
    shape_pt_lon: -89.965061,
    shape_pt_sequence: 401,
    shape_dist_traveled: 21.5544,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220992,
    shape_pt_lon: -89.964681,
    shape_pt_sequence: 402,
    shape_dist_traveled: 21.5885,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220989,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 403,
    shape_dist_traveled: 21.6075,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220983,
    shape_pt_lon: -89.964225,
    shape_pt_sequence: 404,
    shape_dist_traveled: 21.6296,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221001,
    shape_pt_lon: -89.963776,
    shape_pt_sequence: 405,
    shape_dist_traveled: 21.6706,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221062,
    shape_pt_lon: -89.9633,
    shape_pt_sequence: 406,
    shape_dist_traveled: 21.7142,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221096,
    shape_pt_lon: -89.963137,
    shape_pt_sequence: 407,
    shape_dist_traveled: 21.7287,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22099,
    shape_pt_lon: -89.963135,
    shape_pt_sequence: 408,
    shape_dist_traveled: 21.7407,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220977,
    shape_pt_lon: -89.962671,
    shape_pt_sequence: 409,
    shape_dist_traveled: 21.7828,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220978,
    shape_pt_lon: -89.961838,
    shape_pt_sequence: 410,
    shape_dist_traveled: 21.8588,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221,
    shape_pt_lon: -89.961421,
    shape_pt_sequence: 411,
    shape_dist_traveled: 21.8958,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221055,
    shape_pt_lon: -89.960519,
    shape_pt_sequence: 412,
    shape_dist_traveled: 21.9781,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221438,
    shape_pt_lon: -89.960531,
    shape_pt_sequence: 413,
    shape_dist_traveled: 22.0202,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221633,
    shape_pt_lon: -89.960571,
    shape_pt_sequence: 414,
    shape_dist_traveled: 22.0424,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221804,
    shape_pt_lon: -89.960628,
    shape_pt_sequence: 415,
    shape_dist_traveled: 22.062,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221859,
    shape_pt_lon: -89.960648,
    shape_pt_sequence: 416,
    shape_dist_traveled: 22.0693,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221912,
    shape_pt_lon: -89.960668,
    shape_pt_sequence: 417,
    shape_dist_traveled: 22.0756,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221757,
    shape_pt_lon: -89.961202,
    shape_pt_sequence: 418,
    shape_dist_traveled: 22.1269,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221233,
    shape_pt_lon: -89.963032,
    shape_pt_sequence: 419,
    shape_dist_traveled: 22.3021,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221211,
    shape_pt_lon: -89.963139,
    shape_pt_sequence: 420,
    shape_dist_traveled: 22.3123,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.963392,
    shape_pt_sequence: 421,
    shape_dist_traveled: 22.3358,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221135,
    shape_pt_lon: -89.963619,
    shape_pt_sequence: 422,
    shape_dist_traveled: 22.3562,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221095,
    shape_pt_lon: -89.96411,
    shape_pt_sequence: 423,
    shape_dist_traveled: 22.4014,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.2211,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 424,
    shape_dist_traveled: 22.4334,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221109,
    shape_pt_lon: -89.96504,
    shape_pt_sequence: 425,
    shape_dist_traveled: 22.4854,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221116,
    shape_pt_lon: -89.965355,
    shape_pt_sequence: 426,
    shape_dist_traveled: 22.5134,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.965535,
    shape_pt_sequence: 427,
    shape_dist_traveled: 22.5294,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221127,
    shape_pt_lon: -89.965716,
    shape_pt_sequence: 428,
    shape_dist_traveled: 22.5465,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221144,
    shape_pt_lon: -89.966082,
    shape_pt_sequence: 429,
    shape_dist_traveled: 22.5795,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.966808,
    shape_pt_sequence: 430,
    shape_dist_traveled: 22.6446,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221155,
    shape_pt_lon: -89.967397,
    shape_pt_sequence: 431,
    shape_dist_traveled: 22.6976,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221151,
    shape_pt_lon: -89.967812,
    shape_pt_sequence: 432,
    shape_dist_traveled: 22.7356,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 433,
    shape_dist_traveled: 22.7442,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 434,
    shape_dist_traveled: 22.7602,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 435,
    shape_dist_traveled: 22.7832,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 436,
    shape_dist_traveled: 22.8032,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 437,
    shape_dist_traveled: 22.8132,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 438,
    shape_dist_traveled: 22.8404,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 439,
    shape_dist_traveled: 22.9195,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.221362,
    shape_pt_lon: -89.969819,
    shape_pt_sequence: 440,
    shape_dist_traveled: 22.9305,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.224482,
    shape_pt_lon: -89.969649,
    shape_pt_sequence: 441,
    shape_dist_traveled: 23.2798,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.224743,
    shape_pt_lon: -89.969611,
    shape_pt_sequence: 442,
    shape_dist_traveled: 23.3089,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.225119,
    shape_pt_lon: -89.969517,
    shape_pt_sequence: 443,
    shape_dist_traveled: 23.3529,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.226032,
    shape_pt_lon: -89.969279,
    shape_pt_sequence: 444,
    shape_dist_traveled: 23.457,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.226792,
    shape_pt_lon: -89.969078,
    shape_pt_sequence: 445,
    shape_dist_traveled: 23.5439,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22771,
    shape_pt_lon: -89.968848,
    shape_pt_sequence: 446,
    shape_dist_traveled: 23.649,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.228257,
    shape_pt_lon: -89.968702,
    shape_pt_sequence: 447,
    shape_dist_traveled: 23.7114,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.228626,
    shape_pt_lon: -89.968611,
    shape_pt_sequence: 448,
    shape_dist_traveled: 23.7532,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.229037,
    shape_pt_lon: -89.968539,
    shape_pt_sequence: 449,
    shape_dist_traveled: 23.7997,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.229861,
    shape_pt_lon: -89.968454,
    shape_pt_sequence: 450,
    shape_dist_traveled: 23.892,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.230698,
    shape_pt_lon: -89.968381,
    shape_pt_sequence: 451,
    shape_dist_traveled: 23.9862,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.23113,
    shape_pt_lon: -89.96839,
    shape_pt_sequence: 452,
    shape_dist_traveled: 24.0342,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231358,
    shape_pt_lon: -89.968417,
    shape_pt_sequence: 453,
    shape_dist_traveled: 24.0604,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231438,
    shape_pt_lon: -89.968428,
    shape_pt_sequence: 454,
    shape_dist_traveled: 24.0695,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231531,
    shape_pt_lon: -89.968439,
    shape_pt_sequence: 455,
    shape_dist_traveled: 24.0795,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233414,
    shape_pt_lon: -89.968651,
    shape_pt_sequence: 456,
    shape_dist_traveled: 24.2914,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233418,
    shape_pt_lon: -89.969258,
    shape_pt_sequence: 457,
    shape_dist_traveled: 24.3464,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233416,
    shape_pt_lon: -89.969579,
    shape_pt_sequence: 458,
    shape_dist_traveled: 24.3754,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233445,
    shape_pt_lon: -89.970235,
    shape_pt_sequence: 459,
    shape_dist_traveled: 24.4344,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233482,
    shape_pt_lon: -89.970606,
    shape_pt_sequence: 460,
    shape_dist_traveled: 24.4677,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233522,
    shape_pt_lon: -89.971138,
    shape_pt_sequence: 461,
    shape_dist_traveled: 24.5159,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233528,
    shape_pt_lon: -89.971477,
    shape_pt_sequence: 462,
    shape_dist_traveled: 24.5469,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.23346,
    shape_pt_lon: -89.972338,
    shape_pt_sequence: 463,
    shape_dist_traveled: 24.6253,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233438,
    shape_pt_lon: -89.972966,
    shape_pt_sequence: 464,
    shape_dist_traveled: 24.6813,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233437,
    shape_pt_lon: -89.9735,
    shape_pt_sequence: 465,
    shape_dist_traveled: 24.7293,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233406,
    shape_pt_lon: -89.97504,
    shape_pt_sequence: 466,
    shape_dist_traveled: 24.8684,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233377,
    shape_pt_lon: -89.975402,
    shape_pt_sequence: 467,
    shape_dist_traveled: 24.9015,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233929,
    shape_pt_lon: -89.975429,
    shape_pt_sequence: 468,
    shape_dist_traveled: 24.9636,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.234319,
    shape_pt_lon: -89.975462,
    shape_pt_sequence: 469,
    shape_dist_traveled: 25.0077,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.235798,
    shape_pt_lon: -89.975402,
    shape_pt_sequence: 470,
    shape_dist_traveled: 25.1728,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.236369,
    shape_pt_lon: -89.975369,
    shape_pt_sequence: 471,
    shape_dist_traveled: 25.2369,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.236991,
    shape_pt_lon: -89.975339,
    shape_pt_sequence: 472,
    shape_dist_traveled: 25.3069,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.239183,
    shape_pt_lon: -89.975268,
    shape_pt_sequence: 473,
    shape_dist_traveled: 25.552,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.239709,
    shape_pt_lon: -89.975262,
    shape_pt_sequence: 474,
    shape_dist_traveled: 25.611,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.241301,
    shape_pt_lon: -89.975229,
    shape_pt_sequence: 475,
    shape_dist_traveled: 25.789,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242059,
    shape_pt_lon: -89.975219,
    shape_pt_sequence: 476,
    shape_dist_traveled: 25.874,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242246,
    shape_pt_lon: -89.975228,
    shape_pt_sequence: 477,
    shape_dist_traveled: 25.895,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242247,
    shape_pt_lon: -89.975872,
    shape_pt_sequence: 478,
    shape_dist_traveled: 25.954,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242238,
    shape_pt_lon: -89.97657,
    shape_pt_sequence: 479,
    shape_dist_traveled: 26.017,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242242,
    shape_pt_lon: -89.976823,
    shape_pt_sequence: 480,
    shape_dist_traveled: 26.039,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242267,
    shape_pt_lon: -89.977049,
    shape_pt_sequence: 481,
    shape_dist_traveled: 26.0602,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242324,
    shape_pt_lon: -89.977253,
    shape_pt_sequence: 482,
    shape_dist_traveled: 26.0792,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242409,
    shape_pt_lon: -89.977473,
    shape_pt_sequence: 483,
    shape_dist_traveled: 26.1016,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242512,
    shape_pt_lon: -89.977657,
    shape_pt_sequence: 484,
    shape_dist_traveled: 26.1218,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242589,
    shape_pt_lon: -89.977777,
    shape_pt_sequence: 485,
    shape_dist_traveled: 26.1353,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242737,
    shape_pt_lon: -89.977933,
    shape_pt_sequence: 486,
    shape_dist_traveled: 26.1572,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242914,
    shape_pt_lon: -89.978079,
    shape_pt_sequence: 487,
    shape_dist_traveled: 26.1811,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.243255,
    shape_pt_lon: -89.97832,
    shape_pt_sequence: 488,
    shape_dist_traveled: 26.225,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.243939,
    shape_pt_lon: -89.97881,
    shape_pt_sequence: 489,
    shape_dist_traveled: 26.3137,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244142,
    shape_pt_lon: -89.978987,
    shape_pt_sequence: 490,
    shape_dist_traveled: 26.3409,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244256,
    shape_pt_lon: -89.979133,
    shape_pt_sequence: 491,
    shape_dist_traveled: 26.3592,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244323,
    shape_pt_lon: -89.979274,
    shape_pt_sequence: 492,
    shape_dist_traveled: 26.3745,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.24445,
    shape_pt_lon: -89.979658,
    shape_pt_sequence: 493,
    shape_dist_traveled: 26.4113,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244472,
    shape_pt_lon: -89.979729,
    shape_pt_sequence: 494,
    shape_dist_traveled: 26.4186,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244489,
    shape_pt_lon: -89.979789,
    shape_pt_sequence: 495,
    shape_dist_traveled: 26.4239,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244878,
    shape_pt_lon: -89.98112,
    shape_pt_sequence: 496,
    shape_dist_traveled: 26.5518,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244978,
    shape_pt_lon: -89.981499,
    shape_pt_sequence: 497,
    shape_dist_traveled: 26.5884,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.245001,
    shape_pt_lon: -89.981725,
    shape_pt_sequence: 498,
    shape_dist_traveled: 26.6085,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.245002,
    shape_pt_lon: -89.981891,
    shape_pt_sequence: 499,
    shape_dist_traveled: 26.6235,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.24499,
    shape_pt_lon: -89.9821,
    shape_pt_sequence: 500,
    shape_dist_traveled: 26.6426,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244956,
    shape_pt_lon: -89.982858,
    shape_pt_sequence: 501,
    shape_dist_traveled: 26.7107,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.24496,
    shape_pt_lon: -89.983236,
    shape_pt_sequence: 502,
    shape_dist_traveled: 26.7447,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244966,
    shape_pt_lon: -89.984571,
    shape_pt_sequence: 503,
    shape_dist_traveled: 26.8657,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244977,
    shape_pt_lon: -89.98598,
    shape_pt_sequence: 504,
    shape_dist_traveled: 26.9927,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244997,
    shape_pt_lon: -89.986318,
    shape_pt_sequence: 505,
    shape_dist_traveled: 27.0228,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.245001,
    shape_pt_lon: -89.986648,
    shape_pt_sequence: 506,
    shape_dist_traveled: 27.0528,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244971,
    shape_pt_lon: -89.98693,
    shape_pt_sequence: 507,
    shape_dist_traveled: 27.079,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244906,
    shape_pt_lon: -89.987141,
    shape_pt_sequence: 508,
    shape_dist_traveled: 27.0996,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244875,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 509,
    shape_dist_traveled: 27.1055,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.24479,
    shape_pt_lon: -89.987331,
    shape_pt_sequence: 510,
    shape_dist_traveled: 27.1211,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244386,
    shape_pt_lon: -89.987789,
    shape_pt_sequence: 511,
    shape_dist_traveled: 27.182,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244163,
    shape_pt_lon: -89.988041,
    shape_pt_sequence: 512,
    shape_dist_traveled: 27.2159,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.244021,
    shape_pt_lon: -89.988226,
    shape_pt_sequence: 513,
    shape_dist_traveled: 27.2386,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.243874,
    shape_pt_lon: -89.988463,
    shape_pt_sequence: 514,
    shape_dist_traveled: 27.2658,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.243556,
    shape_pt_lon: -89.98918,
    shape_pt_sequence: 515,
    shape_dist_traveled: 27.3392,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.243042,
    shape_pt_lon: -89.990096,
    shape_pt_sequence: 516,
    shape_dist_traveled: 27.4405,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242845,
    shape_pt_lon: -89.990556,
    shape_pt_sequence: 517,
    shape_dist_traveled: 27.4879,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242679,
    shape_pt_lon: -89.990979,
    shape_pt_sequence: 518,
    shape_dist_traveled: 27.5299,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242632,
    shape_pt_lon: -89.991143,
    shape_pt_sequence: 519,
    shape_dist_traveled: 27.5457,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242484,
    shape_pt_lon: -89.991802,
    shape_pt_sequence: 520,
    shape_dist_traveled: 27.6071,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242464,
    shape_pt_lon: -89.991912,
    shape_pt_sequence: 521,
    shape_dist_traveled: 27.6173,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.242352,
    shape_pt_lon: -89.992265,
    shape_pt_sequence: 522,
    shape_dist_traveled: 27.6519,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.241978,
    shape_pt_lon: -89.993243,
    shape_pt_sequence: 523,
    shape_dist_traveled: 27.7494,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.240027,
    shape_pt_lon: -89.993313,
    shape_pt_sequence: 524,
    shape_dist_traveled: 27.9675,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.238041,
    shape_pt_lon: -89.993386,
    shape_pt_sequence: 525,
    shape_dist_traveled: 28.1896,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.237807,
    shape_pt_lon: -89.993395,
    shape_pt_sequence: 526,
    shape_dist_traveled: 28.2166,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.235849,
    shape_pt_lon: -89.993422,
    shape_pt_sequence: 527,
    shape_dist_traveled: 28.4356,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.23542,
    shape_pt_lon: -89.993426,
    shape_pt_sequence: 528,
    shape_dist_traveled: 28.4836,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.235088,
    shape_pt_lon: -89.993431,
    shape_pt_sequence: 529,
    shape_dist_traveled: 28.5206,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.234502,
    shape_pt_lon: -89.993438,
    shape_pt_sequence: 530,
    shape_dist_traveled: 28.5856,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.234398,
    shape_pt_lon: -89.993438,
    shape_pt_sequence: 531,
    shape_dist_traveled: 28.5976,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.234186,
    shape_pt_lon: -89.993459,
    shape_pt_sequence: 532,
    shape_dist_traveled: 28.6217,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233377,
    shape_pt_lon: -89.993645,
    shape_pt_sequence: 533,
    shape_dist_traveled: 28.7131,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.233098,
    shape_pt_lon: -89.99367,
    shape_pt_sequence: 534,
    shape_dist_traveled: 28.7452,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.23246,
    shape_pt_lon: -89.993622,
    shape_pt_sequence: 535,
    shape_dist_traveled: 28.8164,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231579,
    shape_pt_lon: -89.993546,
    shape_pt_sequence: 536,
    shape_dist_traveled: 28.9157,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231228,
    shape_pt_lon: -89.993519,
    shape_pt_sequence: 537,
    shape_dist_traveled: 28.9547,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231249,
    shape_pt_lon: -89.995265,
    shape_pt_sequence: 538,
    shape_dist_traveled: 29.1117,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231289,
    shape_pt_lon: -89.998117,
    shape_pt_sequence: 539,
    shape_dist_traveled: 29.3698,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231308,
    shape_pt_lon: -89.999327,
    shape_pt_sequence: 540,
    shape_dist_traveled: 29.4788,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231312,
    shape_pt_lon: -89.999568,
    shape_pt_sequence: 541,
    shape_dist_traveled: 29.5008,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231322,
    shape_pt_lon: -90.000773,
    shape_pt_sequence: 542,
    shape_dist_traveled: 29.6098,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.23129,
    shape_pt_lon: -90.001159,
    shape_pt_sequence: 543,
    shape_dist_traveled: 29.6439,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231228,
    shape_pt_lon: -90.001467,
    shape_pt_sequence: 544,
    shape_dist_traveled: 29.6728,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.231125,
    shape_pt_lon: -90.001794,
    shape_pt_sequence: 545,
    shape_dist_traveled: 29.7051,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.230988,
    shape_pt_lon: -90.002106,
    shape_pt_sequence: 546,
    shape_dist_traveled: 29.7369,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.230301,
    shape_pt_lon: -90.003385,
    shape_pt_sequence: 547,
    shape_dist_traveled: 29.8753,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.230107,
    shape_pt_lon: -90.003729,
    shape_pt_sequence: 548,
    shape_dist_traveled: 29.9133,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.230069,
    shape_pt_lon: -90.003791,
    shape_pt_sequence: 549,
    shape_dist_traveled: 29.9205,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.229844,
    shape_pt_lon: -90.003627,
    shape_pt_sequence: 550,
    shape_dist_traveled: 29.9496,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.229597,
    shape_pt_lon: -90.003468,
    shape_pt_sequence: 551,
    shape_dist_traveled: 29.9809,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22922,
    shape_pt_lon: -90.003281,
    shape_pt_sequence: 552,
    shape_dist_traveled: 30.0262,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.228853,
    shape_pt_lon: -90.003151,
    shape_pt_sequence: 553,
    shape_dist_traveled: 30.069,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.228694,
    shape_pt_lon: -90.003108,
    shape_pt_sequence: 554,
    shape_dist_traveled: 30.0874,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.228383,
    shape_pt_lon: -90.003048,
    shape_pt_sequence: 555,
    shape_dist_traveled: 30.1228,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22801,
    shape_pt_lon: -90.003017,
    shape_pt_sequence: 556,
    shape_dist_traveled: 30.1649,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.22711,
    shape_pt_lon: -90.003049,
    shape_pt_sequence: 557,
    shape_dist_traveled: 30.2649,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.226487,
    shape_pt_lon: -90.00308,
    shape_pt_sequence: 558,
    shape_dist_traveled: 30.3349,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.225225,
    shape_pt_lon: -90.003133,
    shape_pt_sequence: 559,
    shape_dist_traveled: 30.476,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.224251,
    shape_pt_lon: -90.00317,
    shape_pt_sequence: 560,
    shape_dist_traveled: 30.5851,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.222207,
    shape_pt_lon: -90.00325,
    shape_pt_sequence: 561,
    shape_dist_traveled: 30.8142,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.220817,
    shape_pt_lon: -90.003297,
    shape_pt_sequence: 562,
    shape_dist_traveled: 30.9703,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.219606,
    shape_pt_lon: -90.003345,
    shape_pt_sequence: 563,
    shape_dist_traveled: 31.1053,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.217548,
    shape_pt_lon: -90.003425,
    shape_pt_sequence: 564,
    shape_dist_traveled: 31.3354,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.216526,
    shape_pt_lon: -90.003461,
    shape_pt_sequence: 565,
    shape_dist_traveled: 31.4505,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21488,
    shape_pt_lon: -90.003526,
    shape_pt_sequence: 566,
    shape_dist_traveled: 31.6346,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.214512,
    shape_pt_lon: -90.003538,
    shape_pt_sequence: 567,
    shape_dist_traveled: 31.6756,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21371,
    shape_pt_lon: -90.003571,
    shape_pt_sequence: 568,
    shape_dist_traveled: 31.7656,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.213126,
    shape_pt_lon: -90.003601,
    shape_pt_sequence: 569,
    shape_dist_traveled: 31.8307,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.212896,
    shape_pt_lon: -90.003612,
    shape_pt_sequence: 570,
    shape_dist_traveled: 31.8567,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.211682,
    shape_pt_lon: -90.003668,
    shape_pt_sequence: 571,
    shape_dist_traveled: 31.9928,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.211291,
    shape_pt_lon: -90.003683,
    shape_pt_sequence: 572,
    shape_dist_traveled: 32.0368,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.211203,
    shape_pt_lon: -90.003687,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.0458,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.210252,
    shape_pt_lon: -90.003729,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.1529,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.209426,
    shape_pt_lon: -90.00376,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.2449,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.209191,
    shape_pt_lon: -90.003771,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.2719,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208351,
    shape_pt_lon: -90.003806,
    shape_pt_sequence: 577,
    shape_dist_traveled: 32.366,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208248,
    shape_pt_lon: -90.003809,
    shape_pt_sequence: 578,
    shape_dist_traveled: 32.377,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208203,
    shape_pt_lon: -90.003587,
    shape_pt_sequence: 579,
    shape_dist_traveled: 32.3976,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208153,
    shape_pt_lon: -90.003332,
    shape_pt_sequence: 580,
    shape_dist_traveled: 32.4214,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208109,
    shape_pt_lon: -90.003091,
    shape_pt_sequence: 581,
    shape_dist_traveled: 32.444,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208073,
    shape_pt_lon: -90.002873,
    shape_pt_sequence: 582,
    shape_dist_traveled: 32.4644,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208053,
    shape_pt_lon: -90.002756,
    shape_pt_sequence: 583,
    shape_dist_traveled: 32.4746,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208005,
    shape_pt_lon: -90.002369,
    shape_pt_sequence: 584,
    shape_dist_traveled: 32.5099,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207983,
    shape_pt_lon: -90.001972,
    shape_pt_sequence: 585,
    shape_dist_traveled: 32.546,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207922,
    shape_pt_lon: -89.999718,
    shape_pt_sequence: 586,
    shape_dist_traveled: 32.7501,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207849,
    shape_pt_lon: -89.99714,
    shape_pt_sequence: 587,
    shape_dist_traveled: 32.9823,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207803,
    shape_pt_lon: -89.995578,
    shape_pt_sequence: 588,
    shape_dist_traveled: 33.1234,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207773,
    shape_pt_lon: -89.994281,
    shape_pt_sequence: 589,
    shape_dist_traveled: 33.2404,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207736,
    shape_pt_lon: -89.993614,
    shape_pt_sequence: 590,
    shape_dist_traveled: 33.3016,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207714,
    shape_pt_lon: -89.993421,
    shape_pt_sequence: 591,
    shape_dist_traveled: 33.3187,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207638,
    shape_pt_lon: -89.99315,
    shape_pt_sequence: 592,
    shape_dist_traveled: 33.3452,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207535,
    shape_pt_lon: -89.992912,
    shape_pt_sequence: 593,
    shape_dist_traveled: 33.369,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207441,
    shape_pt_lon: -89.992753,
    shape_pt_sequence: 594,
    shape_dist_traveled: 33.3868,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.207279,
    shape_pt_lon: -89.992543,
    shape_pt_sequence: 595,
    shape_dist_traveled: 33.4129,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.20695,
    shape_pt_lon: -89.992167,
    shape_pt_sequence: 596,
    shape_dist_traveled: 33.4632,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206813,
    shape_pt_lon: -89.991989,
    shape_pt_sequence: 597,
    shape_dist_traveled: 33.4851,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206728,
    shape_pt_lon: -89.991843,
    shape_pt_sequence: 598,
    shape_dist_traveled: 33.5018,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206638,
    shape_pt_lon: -89.991667,
    shape_pt_sequence: 599,
    shape_dist_traveled: 33.5198,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.20656,
    shape_pt_lon: -89.991454,
    shape_pt_sequence: 600,
    shape_dist_traveled: 33.5417,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206514,
    shape_pt_lon: -89.991276,
    shape_pt_sequence: 601,
    shape_dist_traveled: 33.5585,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206503,
    shape_pt_lon: -89.991205,
    shape_pt_sequence: 602,
    shape_dist_traveled: 33.5648,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206488,
    shape_pt_lon: -89.991069,
    shape_pt_sequence: 603,
    shape_dist_traveled: 33.5768,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.206465,
    shape_pt_lon: -89.990279,
    shape_pt_sequence: 604,
    shape_dist_traveled: 33.6489,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.20671,
    shape_pt_lon: -89.990278,
    shape_pt_sequence: 605,
    shape_dist_traveled: 33.6769,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208296,
    shape_pt_lon: -89.9902,
    shape_pt_sequence: 606,
    shape_dist_traveled: 33.854,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.208359,
    shape_pt_lon: -89.990197,
    shape_pt_sequence: 607,
    shape_dist_traveled: 33.861,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.209617,
    shape_pt_lon: -89.990141,
    shape_pt_sequence: 608,
    shape_dist_traveled: 34.0021,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.210092,
    shape_pt_lon: -89.990111,
    shape_pt_sequence: 609,
    shape_dist_traveled: 34.0552,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.211173,
    shape_pt_lon: -89.990057,
    shape_pt_sequence: 610,
    shape_dist_traveled: 34.1763,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.21317,
    shape_pt_lon: -89.989963,
    shape_pt_sequence: 611,
    shape_dist_traveled: 34.4005,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.216216,
    shape_pt_lon: -89.989825,
    shape_pt_sequence: 612,
    shape_dist_traveled: 34.7407,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.216958,
    shape_pt_lon: -89.989797,
    shape_pt_sequence: 613,
    shape_dist_traveled: 34.8247,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.217136,
    shape_pt_lon: -89.989798,
    shape_pt_sequence: 614,
    shape_dist_traveled: 34.8437,
  },
  {
    shape_id: 38223,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 615,
    shape_dist_traveled: 35.0678,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.993016,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.066,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217237,
    shape_pt_lon: -89.993565,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1161,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217253,
    shape_pt_lon: -89.994126,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1661,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21732,
    shape_pt_lon: -89.996025,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.3383,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21734,
    shape_pt_lon: -89.996802,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.4083,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217343,
    shape_pt_lon: -89.996938,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.4203,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217386,
    shape_pt_lon: -89.998142,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.5294,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217416,
    shape_pt_lon: -89.999249,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.6295,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21743,
    shape_pt_lon: -89.999622,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6635,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217451,
    shape_pt_lon: -90.000286,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.7226,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217469,
    shape_pt_lon: -90.000907,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7796,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217479,
    shape_pt_lon: -90.001315,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8156,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217511,
    shape_pt_lon: -90.002238,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8997,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217514,
    shape_pt_lon: -90.002336,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.9087,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217523,
    shape_pt_lon: -90.002643,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.9357,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.217548,
    shape_pt_lon: -90.003425,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.0068,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.219606,
    shape_pt_lon: -90.003345,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2369,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220817,
    shape_pt_lon: -90.003297,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.372,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.222207,
    shape_pt_lon: -90.00325,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.528,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.224251,
    shape_pt_lon: -90.00317,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.7572,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.225225,
    shape_pt_lon: -90.003133,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.8662,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.226487,
    shape_pt_lon: -90.00308,
    shape_pt_sequence: 23,
    shape_dist_traveled: 2.0073,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22711,
    shape_pt_lon: -90.003049,
    shape_pt_sequence: 24,
    shape_dist_traveled: 2.0773,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22801,
    shape_pt_lon: -90.003017,
    shape_pt_sequence: 25,
    shape_dist_traveled: 2.1774,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.228383,
    shape_pt_lon: -90.003048,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.2195,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.228694,
    shape_pt_lon: -90.003108,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.2548,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.228853,
    shape_pt_lon: -90.003151,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.2733,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22922,
    shape_pt_lon: -90.003281,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.316,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.229597,
    shape_pt_lon: -90.003468,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.3613,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.229844,
    shape_pt_lon: -90.003627,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.3926,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.230069,
    shape_pt_lon: -90.003791,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.4218,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.230107,
    shape_pt_lon: -90.003729,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.429,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.230301,
    shape_pt_lon: -90.003385,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.467,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.230988,
    shape_pt_lon: -90.002106,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.6054,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231125,
    shape_pt_lon: -90.001794,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.6371,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231228,
    shape_pt_lon: -90.001467,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.6694,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.23129,
    shape_pt_lon: -90.001159,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.6983,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231322,
    shape_pt_lon: -90.000773,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.7324,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231312,
    shape_pt_lon: -89.999568,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.8414,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231308,
    shape_pt_lon: -89.999327,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.8634,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231289,
    shape_pt_lon: -89.998117,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.9725,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231249,
    shape_pt_lon: -89.995265,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.2305,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231228,
    shape_pt_lon: -89.993519,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.3875,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231579,
    shape_pt_lon: -89.993546,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.4266,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.23246,
    shape_pt_lon: -89.993622,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.5258,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233098,
    shape_pt_lon: -89.99367,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.597,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233377,
    shape_pt_lon: -89.993645,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.6291,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.234186,
    shape_pt_lon: -89.993459,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.7206,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.234398,
    shape_pt_lon: -89.993438,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.7446,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.234502,
    shape_pt_lon: -89.993438,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.7566,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.235088,
    shape_pt_lon: -89.993431,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.8216,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.23542,
    shape_pt_lon: -89.993426,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.8586,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.235849,
    shape_pt_lon: -89.993422,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.9066,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.237807,
    shape_pt_lon: -89.993395,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.1257,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.238041,
    shape_pt_lon: -89.993386,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.1527,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.240027,
    shape_pt_lon: -89.993313,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.3748,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.241978,
    shape_pt_lon: -89.993243,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.5929,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242352,
    shape_pt_lon: -89.992265,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.6904,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242464,
    shape_pt_lon: -89.991912,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.7249,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242484,
    shape_pt_lon: -89.991802,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.7351,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242632,
    shape_pt_lon: -89.991143,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.7965,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242679,
    shape_pt_lon: -89.990979,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.8123,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242845,
    shape_pt_lon: -89.990556,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.8544,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.243042,
    shape_pt_lon: -89.990096,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.9018,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.243556,
    shape_pt_lon: -89.98918,
    shape_pt_sequence: 66,
    shape_dist_traveled: 5.003,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.243874,
    shape_pt_lon: -89.988463,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.0765,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244021,
    shape_pt_lon: -89.988226,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.1037,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244163,
    shape_pt_lon: -89.988041,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.1263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244386,
    shape_pt_lon: -89.987789,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.1603,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.24479,
    shape_pt_lon: -89.987331,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.2211,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244875,
    shape_pt_lon: -89.987206,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.2368,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244906,
    shape_pt_lon: -89.987141,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.2426,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244971,
    shape_pt_lon: -89.98693,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.2632,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.245001,
    shape_pt_lon: -89.986648,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.2894,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244997,
    shape_pt_lon: -89.986318,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.3194,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244977,
    shape_pt_lon: -89.98598,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.3495,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244966,
    shape_pt_lon: -89.984571,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.4765,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.24496,
    shape_pt_lon: -89.983236,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.5975,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244956,
    shape_pt_lon: -89.982858,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.6315,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.24499,
    shape_pt_lon: -89.9821,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.6997,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.245002,
    shape_pt_lon: -89.981891,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.7187,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.245001,
    shape_pt_lon: -89.981725,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.7337,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244978,
    shape_pt_lon: -89.981499,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.7538,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244878,
    shape_pt_lon: -89.98112,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.7905,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244489,
    shape_pt_lon: -89.979789,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.9183,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244472,
    shape_pt_lon: -89.979729,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.9237,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.24445,
    shape_pt_lon: -89.979658,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.931,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244323,
    shape_pt_lon: -89.979274,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.9677,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244256,
    shape_pt_lon: -89.979133,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.983,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.244142,
    shape_pt_lon: -89.978987,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.0014,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.243939,
    shape_pt_lon: -89.97881,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.0286,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.243255,
    shape_pt_lon: -89.97832,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.1173,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242914,
    shape_pt_lon: -89.978079,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.1612,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242737,
    shape_pt_lon: -89.977933,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.185,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242589,
    shape_pt_lon: -89.977777,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.207,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242512,
    shape_pt_lon: -89.977657,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.2204,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242409,
    shape_pt_lon: -89.977473,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.2407,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242324,
    shape_pt_lon: -89.977253,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242267,
    shape_pt_lon: -89.977049,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.282,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242242,
    shape_pt_lon: -89.976823,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.3032,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242238,
    shape_pt_lon: -89.97657,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.3252,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242247,
    shape_pt_lon: -89.975872,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.3882,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242246,
    shape_pt_lon: -89.975228,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.4472,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.242059,
    shape_pt_lon: -89.975219,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.4682,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.241301,
    shape_pt_lon: -89.975229,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.5532,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.239709,
    shape_pt_lon: -89.975262,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.7312,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.239183,
    shape_pt_lon: -89.975268,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.7902,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.236991,
    shape_pt_lon: -89.975339,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.0353,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.236369,
    shape_pt_lon: -89.975369,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.1054,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.235798,
    shape_pt_lon: -89.975402,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.1694,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.234319,
    shape_pt_lon: -89.975462,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.3345,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233929,
    shape_pt_lon: -89.975429,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.3786,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233377,
    shape_pt_lon: -89.975402,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.4407,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233406,
    shape_pt_lon: -89.97504,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.4738,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233437,
    shape_pt_lon: -89.9735,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.6129,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233438,
    shape_pt_lon: -89.972966,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.6609,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.23346,
    shape_pt_lon: -89.972338,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.717,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233528,
    shape_pt_lon: -89.971477,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.7953,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233522,
    shape_pt_lon: -89.971138,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.8263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233482,
    shape_pt_lon: -89.970606,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.8746,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233445,
    shape_pt_lon: -89.970235,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.9078,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233416,
    shape_pt_lon: -89.969579,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.9669,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233418,
    shape_pt_lon: -89.969258,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.9959,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.233414,
    shape_pt_lon: -89.968651,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.0509,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231531,
    shape_pt_lon: -89.968439,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.2627,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231438,
    shape_pt_lon: -89.968428,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.2728,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.231358,
    shape_pt_lon: -89.968417,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.2818,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.23113,
    shape_pt_lon: -89.96839,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.308,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.230698,
    shape_pt_lon: -89.968381,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.356,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.229861,
    shape_pt_lon: -89.968454,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.4503,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.229037,
    shape_pt_lon: -89.968539,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.5425,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.228626,
    shape_pt_lon: -89.968611,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.5891,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.228257,
    shape_pt_lon: -89.968702,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.6308,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22771,
    shape_pt_lon: -89.968848,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.6932,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.226792,
    shape_pt_lon: -89.969078,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.7983,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.226032,
    shape_pt_lon: -89.969279,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.8852,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.225119,
    shape_pt_lon: -89.969517,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.9894,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.224743,
    shape_pt_lon: -89.969611,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.0333,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.224482,
    shape_pt_lon: -89.969649,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.0624,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221362,
    shape_pt_lon: -89.969819,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.4118,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.4228,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.5018,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.529,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.5391,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.5591,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.5821,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.5981,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221076,
    shape_pt_lon: -89.967813,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.6061,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221092,
    shape_pt_lon: -89.967357,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.6472,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221097,
    shape_pt_lon: -89.966533,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.7222,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221061,
    shape_pt_lon: -89.965725,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.7953,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221042,
    shape_pt_lon: -89.965379,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.8263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221019,
    shape_pt_lon: -89.965061,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.8555,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220992,
    shape_pt_lon: -89.964681,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.8896,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220989,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.9086,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220983,
    shape_pt_lon: -89.964225,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.9307,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221001,
    shape_pt_lon: -89.963776,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.9717,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221062,
    shape_pt_lon: -89.9633,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.0153,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221096,
    shape_pt_lon: -89.963137,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.0298,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22099,
    shape_pt_lon: -89.963135,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.0418,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220977,
    shape_pt_lon: -89.962671,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.0838,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220978,
    shape_pt_lon: -89.961838,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.1598,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221,
    shape_pt_lon: -89.961421,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.1969,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221055,
    shape_pt_lon: -89.960519,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.2792,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221438,
    shape_pt_lon: -89.960531,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.3212,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221633,
    shape_pt_lon: -89.960571,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.3434,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221804,
    shape_pt_lon: -89.960628,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.3631,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221859,
    shape_pt_lon: -89.960648,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.3704,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221912,
    shape_pt_lon: -89.960668,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.3767,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221757,
    shape_pt_lon: -89.961202,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.428,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221233,
    shape_pt_lon: -89.963032,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.6032,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221211,
    shape_pt_lon: -89.963139,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.6134,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.963392,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.6369,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221135,
    shape_pt_lon: -89.963619,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.6573,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221095,
    shape_pt_lon: -89.96411,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.7025,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.2211,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.7345,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221109,
    shape_pt_lon: -89.96504,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.7865,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221116,
    shape_pt_lon: -89.965355,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.8145,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.965535,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.8305,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221127,
    shape_pt_lon: -89.965716,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.8476,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221144,
    shape_pt_lon: -89.966082,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.8806,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.966808,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.9457,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221155,
    shape_pt_lon: -89.967397,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.9987,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221151,
    shape_pt_lon: -89.967812,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.0367,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.0453,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.0613,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.0843,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.1043,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.1143,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.1415,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.2206,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221312,
    shape_pt_lon: -89.970213,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.2559,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221331,
    shape_pt_lon: -89.970692,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.3,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221285,
    shape_pt_lon: -89.972166,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.4331,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221274,
    shape_pt_lon: -89.972501,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.4632,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221242,
    shape_pt_lon: -89.973495,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.5533,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221181,
    shape_pt_lon: -89.974799,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.6705,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221149,
    shape_pt_lon: -89.975314,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.7176,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.221108,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.7391,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.22104,
    shape_pt_lon: -89.975549,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.7461,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.220189,
    shape_pt_lon: -89.975522,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.8422,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21823,
    shape_pt_lon: -89.975536,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.0612,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.218181,
    shape_pt_lon: -89.975536,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.0662,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.215816,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.3312,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21536,
    shape_pt_lon: -89.975556,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.3822,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.214802,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.4452,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.213956,
    shape_pt_lon: -89.975567,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.5392,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.213699,
    shape_pt_lon: -89.975567,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.5682,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.21176,
    shape_pt_lon: -89.975581,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.7852,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.210829,
    shape_pt_lon: -89.97559,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.8892,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.20952,
    shape_pt_lon: -89.975593,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.0362,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.209342,
    shape_pt_lon: -89.9756,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.0562,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.209084,
    shape_pt_lon: -89.975603,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.0842,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.208479,
    shape_pt_lon: -89.975611,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.1522,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.208092,
    shape_pt_lon: -89.975679,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.1956,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.208009,
    shape_pt_lon: -89.975692,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.2057,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.207675,
    shape_pt_lon: -89.975713,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.2428,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.207188,
    shape_pt_lon: -89.975712,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.2978,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.206234,
    shape_pt_lon: -89.97572,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.4038,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.205026,
    shape_pt_lon: -89.975723,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.5398,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.204742,
    shape_pt_lon: -89.975737,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.5708,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.204457,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.6029,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.203678,
    shape_pt_lon: -89.975779,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.6899,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.202095,
    shape_pt_lon: -89.975798,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.8679,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.200682,
    shape_pt_lon: -89.975807,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.0259,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.199239,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.1869,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.199177,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.1939,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.198573,
    shape_pt_lon: -89.975818,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.2619,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.198123,
    shape_pt_lon: -89.975811,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.3119,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.197632,
    shape_pt_lon: -89.975787,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.367,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196803,
    shape_pt_lon: -89.975782,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.46,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196499,
    shape_pt_lon: -89.975783,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.494,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196412,
    shape_pt_lon: -89.975844,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.5056,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195307,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.6286,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195188,
    shape_pt_lon: -89.975852,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.6417,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19491,
    shape_pt_lon: -89.97586,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.6737,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19329,
    shape_pt_lon: -89.975848,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.8547,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.192016,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.9967,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191845,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.0167,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19171,
    shape_pt_lon: -89.975858,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.0317,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191543,
    shape_pt_lon: -89.975857,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.0497,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191299,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.0777,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191146,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.0947,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.190188,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.2017,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.190161,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.2047,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189879,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.2367,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189814,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.2437,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189109,
    shape_pt_lon: -89.975858,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.3227,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.188139,
    shape_pt_lon: -89.975861,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.4307,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.187399,
    shape_pt_lon: -89.975862,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.5137,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.188139,
    shape_pt_lon: -89.975861,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.5967,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189109,
    shape_pt_lon: -89.975858,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.7047,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189814,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.7837,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189879,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.7907,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.190161,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.8227,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.190188,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.8257,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191146,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.9327,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191299,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.9497,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191543,
    shape_pt_lon: -89.975857,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.9777,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19171,
    shape_pt_lon: -89.975858,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.9957,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191845,
    shape_pt_lon: -89.975859,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.0107,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.192016,
    shape_pt_lon: -89.975856,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.0307,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19329,
    shape_pt_lon: -89.975848,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.1727,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19491,
    shape_pt_lon: -89.97586,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.3537,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195188,
    shape_pt_lon: -89.975852,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.3857,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195307,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.3988,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196412,
    shape_pt_lon: -89.975844,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.5218,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196499,
    shape_pt_lon: -89.975783,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.5334,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.196413,
    shape_pt_lon: -89.975722,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.5437,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195309,
    shape_pt_lon: -89.975728,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.6677,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.195219,
    shape_pt_lon: -89.975724,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.6777,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.19501,
    shape_pt_lon: -89.975719,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.7007,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.193292,
    shape_pt_lon: -89.975726,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.8937,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191837,
    shape_pt_lon: -89.975736,
    shape_pt_sequence: 275,
    shape_dist_traveled: 17.0557,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191707,
    shape_pt_lon: -89.975736,
    shape_pt_sequence: 276,
    shape_dist_traveled: 17.0707,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191598,
    shape_pt_lon: -89.975724,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.0828,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191532,
    shape_pt_lon: -89.975719,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.0908,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.191299,
    shape_pt_lon: -89.975738,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.1169,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.190212,
    shape_pt_lon: -89.975733,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.2379,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189369,
    shape_pt_lon: -89.975722,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.3329,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.189106,
    shape_pt_lon: -89.97572,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.3619,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.188119,
    shape_pt_lon: -89.975739,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.4729,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.187399,
    shape_pt_lon: -89.975741,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.5529,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.187312,
    shape_pt_lon: -89.975801,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.5641,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.186562,
    shape_pt_lon: -89.975801,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.6481,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.186078,
    shape_pt_lon: -89.975793,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.7021,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.186015,
    shape_pt_lon: -89.975787,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.7091,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.18571,
    shape_pt_lon: -89.975729,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.7437,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.185458,
    shape_pt_lon: -89.975652,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.7725,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.184992,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.8279,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.184656,
    shape_pt_lon: -89.975288,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.8684,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.184342,
    shape_pt_lon: -89.975184,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.9045,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.184057,
    shape_pt_lon: -89.975127,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.9369,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.183698,
    shape_pt_lon: -89.975095,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.977,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.182339,
    shape_pt_lon: -89.975135,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.129,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.181935,
    shape_pt_lon: -89.975149,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.1741,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.180943,
    shape_pt_lon: -89.975177,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.2851,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.17998,
    shape_pt_lon: -89.975208,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.3932,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.178826,
    shape_pt_lon: -89.975247,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.5222,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.178151,
    shape_pt_lon: -89.975269,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.5982,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.177785,
    shape_pt_lon: -89.975278,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.6392,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.176991,
    shape_pt_lon: -89.97531,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.7283,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.175985,
    shape_pt_lon: -89.975407,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.8406,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.175221,
    shape_pt_lon: -89.975417,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.9266,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.174661,
    shape_pt_lon: -89.975432,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.9886,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.174609,
    shape_pt_lon: -89.975434,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.9946,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.174557,
    shape_pt_lon: -89.975437,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.0007,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.174474,
    shape_pt_lon: -89.97544,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.0097,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.174099,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.0517,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.17367,
    shape_pt_lon: -89.975436,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.0997,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.173017,
    shape_pt_lon: -89.975451,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.1727,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.172874,
    shape_pt_lon: -89.975456,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.1887,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.172083,
    shape_pt_lon: -89.97548,
    shape_pt_sequence: 314,
    shape_dist_traveled: 19.2777,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.171577,
    shape_pt_lon: -89.975493,
    shape_pt_sequence: 315,
    shape_dist_traveled: 19.3347,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.170864,
    shape_pt_lon: -89.975512,
    shape_pt_sequence: 316,
    shape_dist_traveled: 19.4137,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.170293,
    shape_pt_lon: -89.975528,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.4778,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.169519,
    shape_pt_lon: -89.975548,
    shape_pt_sequence: 318,
    shape_dist_traveled: 19.5648,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.169031,
    shape_pt_lon: -89.975559,
    shape_pt_sequence: 319,
    shape_dist_traveled: 19.6198,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.168791,
    shape_pt_lon: -89.975566,
    shape_pt_sequence: 320,
    shape_dist_traveled: 19.6458,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.168209,
    shape_pt_lon: -89.97558,
    shape_pt_sequence: 321,
    shape_dist_traveled: 19.7108,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.168079,
    shape_pt_lon: -89.975583,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.7258,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.167372,
    shape_pt_lon: -89.975601,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.8048,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.166577,
    shape_pt_lon: -89.97562,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.8938,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.16573,
    shape_pt_lon: -89.975645,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.9889,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.165622,
    shape_pt_lon: -89.975649,
    shape_pt_sequence: 326,
    shape_dist_traveled: 20.0009,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.164903,
    shape_pt_lon: -89.97567,
    shape_pt_sequence: 327,
    shape_dist_traveled: 20.0809,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.164372,
    shape_pt_lon: -89.975686,
    shape_pt_sequence: 328,
    shape_dist_traveled: 20.1409,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.16358,
    shape_pt_lon: -89.975708,
    shape_pt_sequence: 329,
    shape_dist_traveled: 20.229,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.163283,
    shape_pt_lon: -89.975716,
    shape_pt_sequence: 330,
    shape_dist_traveled: 20.263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.16277,
    shape_pt_lon: -89.975731,
    shape_pt_sequence: 331,
    shape_dist_traveled: 20.32,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.161787,
    shape_pt_lon: -89.975756,
    shape_pt_sequence: 332,
    shape_dist_traveled: 20.43,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.161329,
    shape_pt_lon: -89.975769,
    shape_pt_sequence: 333,
    shape_dist_traveled: 20.481,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.160492,
    shape_pt_lon: -89.975788,
    shape_pt_sequence: 334,
    shape_dist_traveled: 20.575,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.975829,
    shape_pt_sequence: 335,
    shape_dist_traveled: 20.7391,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.158815,
    shape_pt_lon: -89.97585,
    shape_pt_sequence: 336,
    shape_dist_traveled: 20.7631,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 337,
    shape_dist_traveled: 20.8559,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.157942,
    shape_pt_lon: -89.975988,
    shape_pt_sequence: 338,
    shape_dist_traveled: 20.861,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.975997,
    shape_pt_sequence: 339,
    shape_dist_traveled: 20.866,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.157397,
    shape_pt_lon: -89.976068,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.9224,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.156959,
    shape_pt_lon: -89.976146,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.9719,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.154661,
    shape_pt_lon: -89.976477,
    shape_pt_sequence: 342,
    shape_dist_traveled: 21.2316,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.151619,
    shape_pt_lon: -89.976911,
    shape_pt_sequence: 343,
    shape_dist_traveled: 21.5738,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.151269,
    shape_pt_lon: -89.976959,
    shape_pt_sequence: 344,
    shape_dist_traveled: 21.6131,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.151098,
    shape_pt_lon: -89.976958,
    shape_pt_sequence: 345,
    shape_dist_traveled: 21.6321,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.150541,
    shape_pt_lon: -89.976905,
    shape_pt_sequence: 346,
    shape_dist_traveled: 21.6953,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.150371,
    shape_pt_lon: -89.97689,
    shape_pt_sequence: 347,
    shape_dist_traveled: 21.7144,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.15,
    shape_pt_lon: -89.976871,
    shape_pt_sequence: 348,
    shape_dist_traveled: 21.7554,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.148962,
    shape_pt_lon: -89.977006,
    shape_pt_sequence: 349,
    shape_dist_traveled: 21.8721,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.148737,
    shape_pt_lon: -89.977028,
    shape_pt_sequence: 350,
    shape_dist_traveled: 21.8981,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.14857,
    shape_pt_lon: -89.977052,
    shape_pt_sequence: 351,
    shape_dist_traveled: 21.9162,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.148376,
    shape_pt_lon: -89.977108,
    shape_pt_sequence: 352,
    shape_dist_traveled: 21.9388,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.148203,
    shape_pt_lon: -89.977187,
    shape_pt_sequence: 353,
    shape_dist_traveled: 21.9591,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.148159,
    shape_pt_lon: -89.977206,
    shape_pt_sequence: 354,
    shape_dist_traveled: 21.9644,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.147619,
    shape_pt_lon: -89.977527,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.032,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.147506,
    shape_pt_lon: -89.977591,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.045,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.147314,
    shape_pt_lon: -89.977688,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.0687,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.147227,
    shape_pt_lon: -89.977721,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.0792,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.146843,
    shape_pt_lon: -89.977787,
    shape_pt_sequence: 359,
    shape_dist_traveled: 22.1226,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.146627,
    shape_pt_lon: -89.977818,
    shape_pt_sequence: 360,
    shape_dist_traveled: 22.1468,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.146443,
    shape_pt_lon: -89.977842,
    shape_pt_sequence: 361,
    shape_dist_traveled: 22.1669,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.145418,
    shape_pt_lon: -89.977991,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.2826,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.144406,
    shape_pt_lon: -89.978138,
    shape_pt_sequence: 363,
    shape_dist_traveled: 22.3964,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.143416,
    shape_pt_lon: -89.97828,
    shape_pt_sequence: 364,
    shape_dist_traveled: 22.5081,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.142267,
    shape_pt_lon: -89.978446,
    shape_pt_sequence: 365,
    shape_dist_traveled: 22.638,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.139028,
    shape_pt_lon: -89.978909,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.0014,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.138379,
    shape_pt_lon: -89.979009,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.0749,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.138197,
    shape_pt_lon: -89.979029,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.095,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.138067,
    shape_pt_lon: -89.979004,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.1101,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137946,
    shape_pt_lon: -89.978957,
    shape_pt_sequence: 370,
    shape_dist_traveled: 23.1237,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137806,
    shape_pt_lon: -89.978849,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.1426,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137763,
    shape_pt_lon: -89.978813,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.1484,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137716,
    shape_pt_lon: -89.978753,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.1562,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137626,
    shape_pt_lon: -89.9787,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.1674,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137586,
    shape_pt_lon: -89.978611,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.1763,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137375,
    shape_pt_lon: -89.978036,
    shape_pt_sequence: 376,
    shape_dist_traveled: 23.2336,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137303,
    shape_pt_lon: -89.977879,
    shape_pt_sequence: 377,
    shape_dist_traveled: 23.2497,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137197,
    shape_pt_lon: -89.977721,
    shape_pt_sequence: 378,
    shape_dist_traveled: 23.2682,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.137082,
    shape_pt_lon: -89.977591,
    shape_pt_sequence: 379,
    shape_dist_traveled: 23.2859,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.136954,
    shape_pt_lon: -89.977495,
    shape_pt_sequence: 380,
    shape_dist_traveled: 23.302,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.136907,
    shape_pt_lon: -89.97746,
    shape_pt_sequence: 381,
    shape_dist_traveled: 23.3084,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.136752,
    shape_pt_lon: -89.977386,
    shape_pt_sequence: 382,
    shape_dist_traveled: 23.3274,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.13659,
    shape_pt_lon: -89.977349,
    shape_pt_sequence: 383,
    shape_dist_traveled: 23.3458,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.1364,
    shape_pt_lon: -89.977337,
    shape_pt_sequence: 384,
    shape_dist_traveled: 23.3668,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.135585,
    shape_pt_lon: -89.977366,
    shape_pt_sequence: 385,
    shape_dist_traveled: 23.4579,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.134422,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 386,
    shape_dist_traveled: 23.5879,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.134219,
    shape_pt_lon: -89.977418,
    shape_pt_sequence: 387,
    shape_dist_traveled: 23.6109,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133699,
    shape_pt_lon: -89.977424,
    shape_pt_sequence: 388,
    shape_dist_traveled: 23.6689,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133459,
    shape_pt_lon: -89.977441,
    shape_pt_sequence: 389,
    shape_dist_traveled: 23.696,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133404,
    shape_pt_lon: -89.977407,
    shape_pt_sequence: 390,
    shape_dist_traveled: 23.7027,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133376,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.7057,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133331,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.7108,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.13327,
    shape_pt_lon: -89.977411,
    shape_pt_sequence: 393,
    shape_dist_traveled: 23.7178,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.133218,
    shape_pt_lon: -89.977412,
    shape_pt_sequence: 394,
    shape_dist_traveled: 23.7238,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.132779,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.7728,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.132519,
    shape_pt_lon: -89.97737,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.8019,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.13229,
    shape_pt_lon: -89.977317,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.8284,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.132153,
    shape_pt_lon: -89.977269,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.8439,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.131983,
    shape_pt_lon: -89.977206,
    shape_pt_sequence: 399,
    shape_dist_traveled: 23.8639,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.131806,
    shape_pt_lon: -89.977139,
    shape_pt_sequence: 400,
    shape_dist_traveled: 23.8847,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.130956,
    shape_pt_lon: -89.976835,
    shape_pt_sequence: 401,
    shape_dist_traveled: 23.9838,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.13094,
    shape_pt_lon: -89.976832,
    shape_pt_sequence: 402,
    shape_dist_traveled: 23.9858,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.976815,
    shape_pt_sequence: 403,
    shape_dist_traveled: 24.0059,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.129954,
    shape_pt_lon: -89.976842,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.0959,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.129637,
    shape_pt_lon: -89.976815,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.1321,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.129395,
    shape_pt_lon: -89.976756,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.1595,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.976666,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.1877,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.129029,
    shape_pt_lon: -89.97662,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.2022,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.128512,
    shape_pt_lon: -89.976425,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.127601,
    shape_pt_lon: -89.976077,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.3699,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.127219,
    shape_pt_lon: -89.975939,
    shape_pt_sequence: 411,
    shape_dist_traveled: 24.4136,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.126858,
    shape_pt_lon: -89.975864,
    shape_pt_sequence: 412,
    shape_dist_traveled: 24.4551,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.126739,
    shape_pt_lon: -89.975851,
    shape_pt_sequence: 413,
    shape_dist_traveled: 24.4682,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.126501,
    shape_pt_lon: -89.975847,
    shape_pt_sequence: 414,
    shape_dist_traveled: 24.4952,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125701,
    shape_pt_lon: -89.975875,
    shape_pt_sequence: 415,
    shape_dist_traveled: 24.5843,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.12574,
    shape_pt_lon: -89.977202,
    shape_pt_sequence: 416,
    shape_dist_traveled: 24.7043,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125749,
    shape_pt_lon: -89.977673,
    shape_pt_sequence: 417,
    shape_dist_traveled: 24.7463,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125769,
    shape_pt_lon: -89.978458,
    shape_pt_sequence: 418,
    shape_dist_traveled: 24.8174,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125786,
    shape_pt_lon: -89.979109,
    shape_pt_sequence: 419,
    shape_dist_traveled: 24.8764,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125819,
    shape_pt_lon: -89.980482,
    shape_pt_sequence: 420,
    shape_dist_traveled: 25.0005,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125865,
    shape_pt_lon: -89.982469,
    shape_pt_sequence: 421,
    shape_dist_traveled: 25.1805,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125898,
    shape_pt_lon: -89.983502,
    shape_pt_sequence: 422,
    shape_dist_traveled: 25.2736,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125921,
    shape_pt_lon: -89.984052,
    shape_pt_sequence: 423,
    shape_dist_traveled: 25.3237,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125928,
    shape_pt_lon: -89.98419,
    shape_pt_sequence: 424,
    shape_dist_traveled: 25.3357,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.12593,
    shape_pt_lon: -89.984262,
    shape_pt_sequence: 425,
    shape_dist_traveled: 25.3427,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125718,
    shape_pt_lon: -89.984276,
    shape_pt_sequence: 426,
    shape_dist_traveled: 25.3667,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.125052,
    shape_pt_lon: -89.984308,
    shape_pt_sequence: 427,
    shape_dist_traveled: 25.4408,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.124646,
    shape_pt_lon: -89.984314,
    shape_pt_sequence: 428,
    shape_dist_traveled: 25.4868,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.123881,
    shape_pt_lon: -89.984338,
    shape_pt_sequence: 429,
    shape_dist_traveled: 25.5718,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.123338,
    shape_pt_lon: -89.984357,
    shape_pt_sequence: 430,
    shape_dist_traveled: 25.6328,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.122616,
    shape_pt_lon: -89.984379,
    shape_pt_sequence: 431,
    shape_dist_traveled: 25.7139,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.121758,
    shape_pt_lon: -89.984409,
    shape_pt_sequence: 432,
    shape_dist_traveled: 25.8099,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.120982,
    shape_pt_lon: -89.984432,
    shape_pt_sequence: 433,
    shape_dist_traveled: 25.8969,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.120501,
    shape_pt_lon: -89.984446,
    shape_pt_sequence: 434,
    shape_dist_traveled: 25.951,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.11949,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 435,
    shape_dist_traveled: 26.064,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.119018,
    shape_pt_lon: -89.984495,
    shape_pt_sequence: 436,
    shape_dist_traveled: 26.117,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.118696,
    shape_pt_lon: -89.984492,
    shape_pt_sequence: 437,
    shape_dist_traveled: 26.153,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.118069,
    shape_pt_lon: -89.984476,
    shape_pt_sequence: 438,
    shape_dist_traveled: 26.223,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.117683,
    shape_pt_lon: -89.984476,
    shape_pt_sequence: 439,
    shape_dist_traveled: 26.266,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.117593,
    shape_pt_lon: -89.984473,
    shape_pt_sequence: 440,
    shape_dist_traveled: 26.276,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.11752,
    shape_pt_lon: -89.984426,
    shape_pt_sequence: 441,
    shape_dist_traveled: 26.285,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.115969,
    shape_pt_lon: -89.984473,
    shape_pt_sequence: 442,
    shape_dist_traveled: 26.459,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.115821,
    shape_pt_lon: -89.98448,
    shape_pt_sequence: 443,
    shape_dist_traveled: 26.4751,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.115676,
    shape_pt_lon: -89.984487,
    shape_pt_sequence: 444,
    shape_dist_traveled: 26.4921,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.115039,
    shape_pt_lon: -89.984509,
    shape_pt_sequence: 445,
    shape_dist_traveled: 26.5631,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.114669,
    shape_pt_lon: -89.984538,
    shape_pt_sequence: 446,
    shape_dist_traveled: 26.6042,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.114489,
    shape_pt_lon: -89.984566,
    shape_pt_sequence: 447,
    shape_dist_traveled: 26.6243,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.114395,
    shape_pt_lon: -89.984581,
    shape_pt_sequence: 448,
    shape_dist_traveled: 26.6355,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.114051,
    shape_pt_lon: -89.984624,
    shape_pt_sequence: 449,
    shape_dist_traveled: 26.6736,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.113566,
    shape_pt_lon: -89.984668,
    shape_pt_sequence: 450,
    shape_dist_traveled: 26.7287,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.112899,
    shape_pt_lon: -89.984701,
    shape_pt_sequence: 451,
    shape_dist_traveled: 26.8028,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.112357,
    shape_pt_lon: -89.984728,
    shape_pt_sequence: 452,
    shape_dist_traveled: 26.8639,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.111911,
    shape_pt_lon: -89.984748,
    shape_pt_sequence: 453,
    shape_dist_traveled: 26.9139,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.111846,
    shape_pt_lon: -89.984751,
    shape_pt_sequence: 454,
    shape_dist_traveled: 26.9209,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.111243,
    shape_pt_lon: -89.984777,
    shape_pt_sequence: 455,
    shape_dist_traveled: 26.989,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.110567,
    shape_pt_lon: -89.984807,
    shape_pt_sequence: 456,
    shape_dist_traveled: 27.064,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.110149,
    shape_pt_lon: -89.984823,
    shape_pt_sequence: 457,
    shape_dist_traveled: 27.111,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.110006,
    shape_pt_lon: -89.984829,
    shape_pt_sequence: 458,
    shape_dist_traveled: 27.1271,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.10989,
    shape_pt_lon: -89.984834,
    shape_pt_sequence: 459,
    shape_dist_traveled: 27.1401,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.10921,
    shape_pt_lon: -89.984863,
    shape_pt_sequence: 460,
    shape_dist_traveled: 27.2161,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.108639,
    shape_pt_lon: -89.984885,
    shape_pt_sequence: 461,
    shape_dist_traveled: 27.2801,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.108534,
    shape_pt_lon: -89.98489,
    shape_pt_sequence: 462,
    shape_dist_traveled: 27.2922,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.107752,
    shape_pt_lon: -89.984941,
    shape_pt_sequence: 463,
    shape_dist_traveled: 27.3793,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.107562,
    shape_pt_lon: -89.984954,
    shape_pt_sequence: 464,
    shape_dist_traveled: 27.4014,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.107082,
    shape_pt_lon: -89.98499,
    shape_pt_sequence: 465,
    shape_dist_traveled: 27.4545,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.106473,
    shape_pt_lon: -89.985039,
    shape_pt_sequence: 466,
    shape_dist_traveled: 27.5226,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.106439,
    shape_pt_lon: -89.985046,
    shape_pt_sequence: 467,
    shape_dist_traveled: 27.5267,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.106288,
    shape_pt_lon: -89.985076,
    shape_pt_sequence: 468,
    shape_dist_traveled: 27.5438,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.106109,
    shape_pt_lon: -89.985139,
    shape_pt_sequence: 469,
    shape_dist_traveled: 27.5647,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.105855,
    shape_pt_lon: -89.985281,
    shape_pt_sequence: 470,
    shape_dist_traveled: 27.5965,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.105766,
    shape_pt_lon: -89.985349,
    shape_pt_sequence: 471,
    shape_dist_traveled: 27.6081,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.105461,
    shape_pt_lon: -89.985572,
    shape_pt_sequence: 472,
    shape_dist_traveled: 27.6476,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 473,
    shape_dist_traveled: 27.6637,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.105191,
    shape_pt_lon: -89.985722,
    shape_pt_sequence: 474,
    shape_dist_traveled: 27.6808,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.104962,
    shape_pt_lon: -89.985801,
    shape_pt_sequence: 475,
    shape_dist_traveled: 27.7077,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.104471,
    shape_pt_lon: -89.985951,
    shape_pt_sequence: 476,
    shape_dist_traveled: 27.7633,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.104042,
    shape_pt_lon: -89.986082,
    shape_pt_sequence: 477,
    shape_dist_traveled: 27.8137,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.10398,
    shape_pt_lon: -89.986102,
    shape_pt_sequence: 478,
    shape_dist_traveled: 27.82,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.103101,
    shape_pt_lon: -89.98637,
    shape_pt_sequence: 479,
    shape_dist_traveled: 27.9219,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.102119,
    shape_pt_lon: -89.986662,
    shape_pt_sequence: 480,
    shape_dist_traveled: 28.0349,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.101187,
    shape_pt_lon: -89.986923,
    shape_pt_sequence: 481,
    shape_dist_traveled: 28.1417,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.101112,
    shape_pt_lon: -89.986943,
    shape_pt_sequence: 482,
    shape_dist_traveled: 28.1499,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.100501,
    shape_pt_lon: -89.987104,
    shape_pt_sequence: 483,
    shape_dist_traveled: 28.2203,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.100146,
    shape_pt_lon: -89.987178,
    shape_pt_sequence: 484,
    shape_dist_traveled: 28.2609,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.099659,
    shape_pt_lon: -89.987268,
    shape_pt_sequence: 485,
    shape_dist_traveled: 28.3155,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.099316,
    shape_pt_lon: -89.987305,
    shape_pt_sequence: 486,
    shape_dist_traveled: 28.3536,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.098383,
    shape_pt_lon: -89.987354,
    shape_pt_sequence: 487,
    shape_dist_traveled: 28.4588,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.09737,
    shape_pt_lon: -89.987396,
    shape_pt_sequence: 488,
    shape_dist_traveled: 28.5718,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.09647,
    shape_pt_lon: -89.987423,
    shape_pt_sequence: 489,
    shape_dist_traveled: 28.6728,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.096287,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 490,
    shape_dist_traveled: 28.6928,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.09618,
    shape_pt_lon: -89.987432,
    shape_pt_sequence: 491,
    shape_dist_traveled: 28.7048,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.09605,
    shape_pt_lon: -89.987437,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.7199,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.095598,
    shape_pt_lon: -89.987454,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.7699,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.095038,
    shape_pt_lon: -89.987478,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.833,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.092839,
    shape_pt_lon: -89.98755,
    shape_pt_sequence: 495,
    shape_dist_traveled: 29.079,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.092549,
    shape_pt_lon: -89.987559,
    shape_pt_sequence: 496,
    shape_dist_traveled: 29.112,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.09192,
    shape_pt_lon: -89.987578,
    shape_pt_sequence: 497,
    shape_dist_traveled: 29.1821,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.091535,
    shape_pt_lon: -89.987591,
    shape_pt_sequence: 498,
    shape_dist_traveled: 29.2251,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.089618,
    shape_pt_lon: -89.987673,
    shape_pt_sequence: 499,
    shape_dist_traveled: 29.4402,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.087974,
    shape_pt_lon: -89.98775,
    shape_pt_sequence: 500,
    shape_dist_traveled: 29.6243,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086793,
    shape_pt_lon: -89.987819,
    shape_pt_sequence: 501,
    shape_dist_traveled: 29.7565,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086709,
    shape_pt_lon: -89.987817,
    shape_pt_sequence: 502,
    shape_dist_traveled: 29.7655,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -89.987797,
    shape_pt_sequence: 503,
    shape_dist_traveled: 29.7856,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.08648,
    shape_pt_lon: -89.987787,
    shape_pt_sequence: 504,
    shape_dist_traveled: 29.7917,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086443,
    shape_pt_lon: -89.987776,
    shape_pt_sequence: 505,
    shape_dist_traveled: 29.7958,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.0864,
    shape_pt_lon: -89.987761,
    shape_pt_sequence: 506,
    shape_dist_traveled: 29.8012,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086229,
    shape_pt_lon: -89.98769,
    shape_pt_sequence: 507,
    shape_dist_traveled: 29.8211,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.086123,
    shape_pt_lon: -89.987628,
    shape_pt_sequence: 508,
    shape_dist_traveled: 29.8345,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.084891,
    shape_pt_lon: -89.986783,
    shape_pt_sequence: 509,
    shape_dist_traveled: 29.9912,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.084769,
    shape_pt_lon: -89.986699,
    shape_pt_sequence: 510,
    shape_dist_traveled: 30.0073,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.084267,
    shape_pt_lon: -89.98637,
    shape_pt_sequence: 511,
    shape_dist_traveled: 30.0708,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.083669,
    shape_pt_lon: -89.98611,
    shape_pt_sequence: 512,
    shape_dist_traveled: 30.1417,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.083306,
    shape_pt_lon: -89.985961,
    shape_pt_sequence: 513,
    shape_dist_traveled: 30.185,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.08285,
    shape_pt_lon: -89.985797,
    shape_pt_sequence: 514,
    shape_dist_traveled: 30.2382,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.082231,
    shape_pt_lon: -89.98561,
    shape_pt_sequence: 515,
    shape_dist_traveled: 30.3092,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.081571,
    shape_pt_lon: -89.985408,
    shape_pt_sequence: 516,
    shape_dist_traveled: 30.3854,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.081268,
    shape_pt_lon: -89.985313,
    shape_pt_sequence: 517,
    shape_dist_traveled: 30.4205,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.080171,
    shape_pt_lon: -89.984982,
    shape_pt_sequence: 518,
    shape_dist_traveled: 30.5462,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.07991,
    shape_pt_lon: -89.98491,
    shape_pt_sequence: 519,
    shape_dist_traveled: 30.5768,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.079661,
    shape_pt_lon: -89.984876,
    shape_pt_sequence: 520,
    shape_dist_traveled: 30.6039,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.079463,
    shape_pt_lon: -89.984867,
    shape_pt_sequence: 521,
    shape_dist_traveled: 30.627,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.079396,
    shape_pt_lon: -89.984913,
    shape_pt_sequence: 522,
    shape_dist_traveled: 30.635,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.079322,
    shape_pt_lon: -89.984919,
    shape_pt_sequence: 523,
    shape_dist_traveled: 30.6431,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.079134,
    shape_pt_lon: -89.98494,
    shape_pt_sequence: 524,
    shape_dist_traveled: 30.6641,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.078938,
    shape_pt_lon: -89.984974,
    shape_pt_sequence: 525,
    shape_dist_traveled: 30.6865,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.078643,
    shape_pt_lon: -89.985034,
    shape_pt_sequence: 526,
    shape_dist_traveled: 30.7198,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.078213,
    shape_pt_lon: -89.985126,
    shape_pt_sequence: 527,
    shape_dist_traveled: 30.7685,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.07723,
    shape_pt_lon: -89.985337,
    shape_pt_sequence: 528,
    shape_dist_traveled: 30.8811,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.077081,
    shape_pt_lon: -89.985369,
    shape_pt_sequence: 529,
    shape_dist_traveled: 30.8974,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.077027,
    shape_pt_lon: -89.985382,
    shape_pt_sequence: 530,
    shape_dist_traveled: 30.9035,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.076889,
    shape_pt_lon: -89.985413,
    shape_pt_sequence: 531,
    shape_dist_traveled: 30.9198,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.076452,
    shape_pt_lon: -89.985517,
    shape_pt_sequence: 532,
    shape_dist_traveled: 30.9696,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.076308,
    shape_pt_lon: -89.985551,
    shape_pt_sequence: 533,
    shape_dist_traveled: 30.9859,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.076179,
    shape_pt_lon: -89.985582,
    shape_pt_sequence: 534,
    shape_dist_traveled: 31.0002,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.075837,
    shape_pt_lon: -89.985667,
    shape_pt_sequence: 535,
    shape_dist_traveled: 31.039,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.075751,
    shape_pt_lon: -89.985687,
    shape_pt_sequence: 536,
    shape_dist_traveled: 31.0492,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.075283,
    shape_pt_lon: -89.985796,
    shape_pt_sequence: 537,
    shape_dist_traveled: 31.1022,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.985825,
    shape_pt_sequence: 538,
    shape_dist_traveled: 31.1173,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.075,
    shape_pt_lon: -89.985859,
    shape_pt_sequence: 539,
    shape_dist_traveled: 31.1346,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.074102,
    shape_pt_lon: -89.986058,
    shape_pt_sequence: 540,
    shape_dist_traveled: 31.2371,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.073676,
    shape_pt_lon: -89.986155,
    shape_pt_sequence: 541,
    shape_dist_traveled: 31.285,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.072943,
    shape_pt_lon: -89.986292,
    shape_pt_sequence: 542,
    shape_dist_traveled: 31.3679,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.072719,
    shape_pt_lon: -89.986367,
    shape_pt_sequence: 543,
    shape_dist_traveled: 31.3938,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.072344,
    shape_pt_lon: -89.986465,
    shape_pt_sequence: 544,
    shape_dist_traveled: 31.4368,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.070852,
    shape_pt_lon: -89.986799,
    shape_pt_sequence: 545,
    shape_dist_traveled: 31.6065,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.070628,
    shape_pt_lon: -89.986851,
    shape_pt_sequence: 546,
    shape_dist_traveled: 31.6318,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.070134,
    shape_pt_lon: -89.986979,
    shape_pt_sequence: 547,
    shape_dist_traveled: 31.6891,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.069826,
    shape_pt_lon: -89.987079,
    shape_pt_sequence: 548,
    shape_dist_traveled: 31.7242,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.069379,
    shape_pt_lon: -89.987227,
    shape_pt_sequence: 549,
    shape_dist_traveled: 31.7759,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.069075,
    shape_pt_lon: -89.987366,
    shape_pt_sequence: 550,
    shape_dist_traveled: 31.8123,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.06884,
    shape_pt_lon: -89.987491,
    shape_pt_sequence: 551,
    shape_dist_traveled: 31.8405,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.987528,
    shape_pt_sequence: 552,
    shape_dist_traveled: 31.85,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.068713,
    shape_pt_lon: -89.987548,
    shape_pt_sequence: 553,
    shape_dist_traveled: 31.8563,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.068657,
    shape_pt_lon: -89.987588,
    shape_pt_sequence: 554,
    shape_dist_traveled: 31.8635,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.06855,
    shape_pt_lon: -89.987664,
    shape_pt_sequence: 555,
    shape_dist_traveled: 31.8774,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.068343,
    shape_pt_lon: -89.987793,
    shape_pt_sequence: 556,
    shape_dist_traveled: 31.9029,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.067568,
    shape_pt_lon: -89.988323,
    shape_pt_sequence: 557,
    shape_dist_traveled: 32.0023,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.065027,
    shape_pt_lon: -89.990049,
    shape_pt_sequence: 558,
    shape_dist_traveled: 32.3263,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.064369,
    shape_pt_lon: -89.990497,
    shape_pt_sequence: 559,
    shape_dist_traveled: 32.4109,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.063958,
    shape_pt_lon: -89.990771,
    shape_pt_sequence: 560,
    shape_dist_traveled: 32.4628,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.063559,
    shape_pt_lon: -89.990995,
    shape_pt_sequence: 561,
    shape_dist_traveled: 32.5125,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.063539,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 562,
    shape_dist_traveled: 32.5145,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.063301,
    shape_pt_lon: -89.991119,
    shape_pt_sequence: 563,
    shape_dist_traveled: 32.5427,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.062899,
    shape_pt_lon: -89.991287,
    shape_pt_sequence: 564,
    shape_dist_traveled: 32.5901,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.06244,
    shape_pt_lon: -89.99142,
    shape_pt_sequence: 565,
    shape_dist_traveled: 32.6435,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.062148,
    shape_pt_lon: -89.991496,
    shape_pt_sequence: 566,
    shape_dist_traveled: 32.6772,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.061762,
    shape_pt_lon: -89.991562,
    shape_pt_sequence: 567,
    shape_dist_traveled: 32.7206,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.061565,
    shape_pt_lon: -89.991582,
    shape_pt_sequence: 568,
    shape_dist_traveled: 32.7427,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.061109,
    shape_pt_lon: -89.991606,
    shape_pt_sequence: 569,
    shape_dist_traveled: 32.7938,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060946,
    shape_pt_lon: -89.991741,
    shape_pt_sequence: 570,
    shape_dist_traveled: 32.8154,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060819,
    shape_pt_lon: -89.991875,
    shape_pt_sequence: 571,
    shape_dist_traveled: 32.8338,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060747,
    shape_pt_lon: -89.991998,
    shape_pt_sequence: 572,
    shape_dist_traveled: 32.8474,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060685,
    shape_pt_lon: -89.992187,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.8658,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060641,
    shape_pt_lon: -89.992396,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.8855,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.060603,
    shape_pt_lon: -89.992575,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.902,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.06061,
    shape_pt_lon: -89.992822,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.925,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.06062,
    shape_pt_lon: -89.993097,
    shape_pt_sequence: 577,
    shape_dist_traveled: 32.949,
  },
  {
    shape_id: 38226,
    shape_pt_lat: 35.061808,
    shape_pt_lon: -89.992864,
    shape_pt_sequence: 578,
    shape_dist_traveled: 33.0836,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145766,
    shape_pt_lon: -90.046733,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5843,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14565,
    shape_pt_lon: -90.046271,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6283,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145621,
    shape_pt_lon: -90.046134,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6406,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145598,
    shape_pt_lon: -90.045926,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6599,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145528,
    shape_pt_lon: -90.045569,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6926,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145193,
    shape_pt_lon: -90.044298,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.8137,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145094,
    shape_pt_lon: -90.043941,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8476,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145062,
    shape_pt_lon: -90.043826,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.8593,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.145039,
    shape_pt_lon: -90.043736,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8675,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144974,
    shape_pt_lon: -90.043495,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.8906,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144626,
    shape_pt_lon: -90.042423,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9942,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144491,
    shape_pt_lon: -90.042019,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0342,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14445,
    shape_pt_lon: -90.041976,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0406,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.1444,
    shape_pt_lon: -90.041851,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0531,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14435,
    shape_pt_lon: -90.041695,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.068,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144312,
    shape_pt_lon: -90.041577,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0797,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144241,
    shape_pt_lon: -90.04136,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1012,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.144074,
    shape_pt_lon: -90.04076,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1585,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143987,
    shape_pt_lon: -90.040428,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1901,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143873,
    shape_pt_lon: -90.039966,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2328,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14383,
    shape_pt_lon: -90.03971,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2573,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143778,
    shape_pt_lon: -90.039188,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3047,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143719,
    shape_pt_lon: -90.038859,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3345,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143612,
    shape_pt_lon: -90.038478,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.3715,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143472,
    shape_pt_lon: -90.038113,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4078,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143334,
    shape_pt_lon: -90.037738,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.4453,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143256,
    shape_pt_lon: -90.037451,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4726,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.143157,
    shape_pt_lon: -90.037051,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.5105,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142743,
    shape_pt_lon: -90.035473,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.6598,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142722,
    shape_pt_lon: -90.035371,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.67,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142708,
    shape_pt_lon: -90.035292,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.6772,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142689,
    shape_pt_lon: -90.035165,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.6884,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142727,
    shape_pt_lon: -90.035042,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.7001,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142718,
    shape_pt_lon: -90.034973,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.7072,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142608,
    shape_pt_lon: -90.033807,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.8129,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142565,
    shape_pt_lon: -90.033456,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.8453,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142558,
    shape_pt_lon: -90.033182,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.8693,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142567,
    shape_pt_lon: -90.032941,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.8913,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142626,
    shape_pt_lon: -90.032316,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.9486,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142641,
    shape_pt_lon: -90.032026,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.9747,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14263,
    shape_pt_lon: -90.031433,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.0277,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142657,
    shape_pt_lon: -90.030926,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.0738,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142669,
    shape_pt_lon: -90.03084,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.0819,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142716,
    shape_pt_lon: -90.03056,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.1073,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142765,
    shape_pt_lon: -90.030331,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.1292,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142791,
    shape_pt_lon: -90.030198,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.1416,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142836,
    shape_pt_lon: -90.0299,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.169,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142854,
    shape_pt_lon: -90.029591,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.1971,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142848,
    shape_pt_lon: -90.029315,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.2221,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.2342,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.2794,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.3769,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.3929,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.5267,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14099,
    shape_pt_lon: -90.027803,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.5688,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.140801,
    shape_pt_lon: -90.027861,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.5906,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.140387,
    shape_pt_lon: -90.028065,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.64,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.028117,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.6558,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.6792,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.139023,
    shape_pt_lon: -90.028323,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.795,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.138878,
    shape_pt_lon: -90.028336,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.8112,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.138729,
    shape_pt_lon: -90.028321,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.8283,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.138453,
    shape_pt_lon: -90.028239,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.86,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.138299,
    shape_pt_lon: -90.028198,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.8775,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.13807,
    shape_pt_lon: -90.02819,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.9025,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.9116,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.13704,
    shape_pt_lon: -90.028232,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.0186,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.136129,
    shape_pt_lon: -90.028261,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.1207,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.134849,
    shape_pt_lon: -90.028301,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.2637,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.133589,
    shape_pt_lon: -90.028343,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.4048,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.13317,
    shape_pt_lon: -90.028358,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.4518,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.132928,
    shape_pt_lon: -90.028358,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.4788,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131761,
    shape_pt_lon: -90.028378,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.6088,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.027252,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.7108,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131741,
    shape_pt_lon: -90.02703,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.7308,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.13174,
    shape_pt_lon: -90.026917,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.7408,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131729,
    shape_pt_lon: -90.026568,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.7728,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131693,
    shape_pt_lon: -90.026349,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.7923,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.8201,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.8562,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.8762,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.9676,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.9882,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.0412,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.0457,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.0663,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.073,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.0758,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.0936,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.1208,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.1544,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.231,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.2951,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.347,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.3628,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.3764,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.4116,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.5348,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.6646,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.7628,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.81,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.828,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.016751,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.8516,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.125917,
    shape_pt_lon: -90.016143,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.9202,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.124924,
    shape_pt_lon: -90.014434,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.1109,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.124501,
    shape_pt_lon: -90.013705,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.1911,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.124171,
    shape_pt_lon: -90.013136,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.2549,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12348,
    shape_pt_lon: -90.011949,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.3867,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -90.011677,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.4175,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.123301,
    shape_pt_lon: -90.011644,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.4211,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.12279,
    shape_pt_lon: -90.01076,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.5194,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.122543,
    shape_pt_lon: -90.010346,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.5658,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.122363,
    shape_pt_lon: -90.010048,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.5994,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.121429,
    shape_pt_lon: -90.008595,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.7673,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.121217,
    shape_pt_lon: -90.008259,
    shape_pt_sequence: 142,
    shape_dist_traveled: 6.8059,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.120687,
    shape_pt_lon: -90.007442,
    shape_pt_sequence: 143,
    shape_dist_traveled: 6.9005,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.120106,
    shape_pt_lon: -90.006533,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.0051,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.0258,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.119937,
    shape_pt_lon: -90.006268,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.0358,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.119197,
    shape_pt_lon: -90.005107,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.1696,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.119091,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.188,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.118393,
    shape_pt_lon: -90.003858,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.3141,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.118248,
    shape_pt_lon: -90.003629,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.3405,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11782,
    shape_pt_lon: -90.00296,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.4173,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.117619,
    shape_pt_lon: -90.002647,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.4529,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11727,
    shape_pt_lon: -90.002104,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.5162,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.117101,
    shape_pt_lon: -90.001834,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.547,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11686,
    shape_pt_lon: -90.001403,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.5944,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.116827,
    shape_pt_lon: -90.001336,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.6016,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.116452,
    shape_pt_lon: -90.000693,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.6732,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.116358,
    shape_pt_lon: -90.000539,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.6904,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.116176,
    shape_pt_lon: -90.00028,
    shape_pt_sequence: 159,
    shape_dist_traveled: 7.7216,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.115773,
    shape_pt_lon: -89.999719,
    shape_pt_sequence: 160,
    shape_dist_traveled: 7.7896,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.115591,
    shape_pt_lon: -89.999479,
    shape_pt_sequence: 161,
    shape_dist_traveled: 7.8193,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11536,
    shape_pt_lon: -89.999139,
    shape_pt_sequence: 162,
    shape_dist_traveled: 7.8598,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.113746,
    shape_pt_lon: -89.996673,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.1464,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.113717,
    shape_pt_lon: -89.996628,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.152,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.113499,
    shape_pt_lon: -89.996299,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.1897,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.113236,
    shape_pt_lon: -89.995889,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.2367,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.113011,
    shape_pt_lon: -89.995553,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.2771,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.112618,
    shape_pt_lon: -89.994952,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.3468,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.112561,
    shape_pt_lon: -89.994868,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.3568,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.112193,
    shape_pt_lon: -89.994306,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.4222,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.111537,
    shape_pt_lon: -89.993302,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.5381,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11106,
    shape_pt_lon: -89.992569,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.6242,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 173,
    shape_dist_traveled: 8.6848,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.699,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 175,
    shape_dist_traveled: 8.7154,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 176,
    shape_dist_traveled: 8.7574,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 177,
    shape_dist_traveled: 8.9073,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.9398,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.0076,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.021,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.0517,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.0708,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108396,
    shape_pt_lon: -89.988522,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.0972,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.108348,
    shape_pt_lon: -89.988462,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.1042,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.107991,
    shape_pt_lon: -89.988071,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.1581,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.107749,
    shape_pt_lon: -89.987853,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.1911,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.107475,
    shape_pt_lon: -89.987644,
    shape_pt_sequence: 187,
    shape_dist_traveled: 9.2266,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -89.987492,
    shape_pt_sequence: 188,
    shape_dist_traveled: 9.2561,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.106839,
    shape_pt_lon: -89.987218,
    shape_pt_sequence: 189,
    shape_dist_traveled: 9.3085,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 190,
    shape_dist_traveled: 9.3507,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.10627,
    shape_pt_lon: -89.986761,
    shape_pt_sequence: 191,
    shape_dist_traveled: 9.3837,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105949,
    shape_pt_lon: -89.986453,
    shape_pt_sequence: 192,
    shape_dist_traveled: 9.4293,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105683,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 193,
    shape_dist_traveled: 9.4696,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105579,
    shape_pt_lon: -89.986008,
    shape_pt_sequence: 194,
    shape_dist_traveled: 9.4873,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -89.985738,
    shape_pt_sequence: 195,
    shape_dist_traveled: 9.52,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 196,
    shape_dist_traveled: 9.5292,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105203,
    shape_pt_lon: -89.985466,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.5519,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.105062,
    shape_pt_lon: -89.98525,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.5769,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.104927,
    shape_pt_lon: -89.985038,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.6011,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.10462,
    shape_pt_lon: -89.984552,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.6573,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.104387,
    shape_pt_lon: -89.984177,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.7001,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.104063,
    shape_pt_lon: -89.983699,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.7562,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.1036,
    shape_pt_lon: -89.982976,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.8402,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.102607,
    shape_pt_lon: -89.981424,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.0189,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.102272,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.0795,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.102028,
    shape_pt_lon: -89.980468,
    shape_pt_sequence: 206,
    shape_dist_traveled: 10.1269,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.101844,
    shape_pt_lon: -89.980151,
    shape_pt_sequence: 207,
    shape_dist_traveled: 10.1619,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.100876,
    shape_pt_lon: -89.978307,
    shape_pt_sequence: 208,
    shape_dist_traveled: 10.3608,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -89.978038,
    shape_pt_sequence: 209,
    shape_dist_traveled: 10.3896,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.977596,
    shape_pt_sequence: 210,
    shape_dist_traveled: 10.4373,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.099702,
    shape_pt_lon: -89.976056,
    shape_pt_sequence: 211,
    shape_dist_traveled: 10.6032,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.099554,
    shape_pt_lon: -89.975775,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.6335,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.099477,
    shape_pt_lon: -89.975625,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.6496,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.099079,
    shape_pt_lon: -89.974863,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.732,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.098876,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.7738,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.098556,
    shape_pt_lon: -89.973858,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.839,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.098287,
    shape_pt_lon: -89.973401,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.8907,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.098014,
    shape_pt_lon: -89.973008,
    shape_pt_sequence: 218,
    shape_dist_traveled: 10.9374,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09781,
    shape_pt_lon: -89.972738,
    shape_pt_sequence: 219,
    shape_dist_traveled: 10.9714,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.972644,
    shape_pt_sequence: 220,
    shape_dist_traveled: 10.9834,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.097492,
    shape_pt_lon: -89.972371,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.0195,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.097178,
    shape_pt_lon: -89.972056,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.0643,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.096506,
    shape_pt_lon: -89.971448,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.1579,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.096076,
    shape_pt_lon: -89.971066,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.2176,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095461,
    shape_pt_lon: -89.970517,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.302,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095147,
    shape_pt_lon: -89.97023,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.3456,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095028,
    shape_pt_lon: -89.970124,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.3622,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095031,
    shape_pt_lon: -89.970053,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.3693,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09504,
    shape_pt_lon: -89.969758,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.3953,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09504,
    shape_pt_lon: -89.969676,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.4033,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095038,
    shape_pt_lon: -89.969352,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.4323,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095017,
    shape_pt_lon: -89.968201,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.5363,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.095002,
    shape_pt_lon: -89.96763,
    shape_pt_sequence: 233,
    shape_dist_traveled: 11.5883,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094994,
    shape_pt_lon: -89.96727,
    shape_pt_sequence: 234,
    shape_dist_traveled: 11.6204,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094982,
    shape_pt_lon: -89.96664,
    shape_pt_sequence: 235,
    shape_dist_traveled: 11.6774,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094972,
    shape_pt_lon: -89.96627,
    shape_pt_sequence: 236,
    shape_dist_traveled: 11.7114,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094968,
    shape_pt_lon: -89.966042,
    shape_pt_sequence: 237,
    shape_dist_traveled: 11.7314,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094962,
    shape_pt_lon: -89.96585,
    shape_pt_sequence: 238,
    shape_dist_traveled: 11.7494,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094951,
    shape_pt_lon: -89.965308,
    shape_pt_sequence: 239,
    shape_dist_traveled: 11.7984,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094941,
    shape_pt_lon: -89.964718,
    shape_pt_sequence: 240,
    shape_dist_traveled: 11.8514,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09492,
    shape_pt_lon: -89.963541,
    shape_pt_sequence: 241,
    shape_dist_traveled: 11.9585,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094874,
    shape_pt_lon: -89.961241,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.1665,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094828,
    shape_pt_lon: -89.958819,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.3846,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094792,
    shape_pt_lon: -89.957043,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.5456,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094781,
    shape_pt_lon: -89.95647,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.5976,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094759,
    shape_pt_lon: -89.955307,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.7027,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094732,
    shape_pt_lon: -89.954159,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.8067,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094719,
    shape_pt_lon: -89.953276,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.8867,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094715,
    shape_pt_lon: -89.953071,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.9047,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09471,
    shape_pt_lon: -89.952893,
    shape_pt_sequence: 250,
    shape_dist_traveled: 12.9208,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094687,
    shape_pt_lon: -89.951978,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.0038,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094682,
    shape_pt_lon: -89.951852,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.0148,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094662,
    shape_pt_lon: -89.951118,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.0819,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094656,
    shape_pt_lon: -89.950749,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.1149,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094649,
    shape_pt_lon: -89.950597,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.1289,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094647,
    shape_pt_lon: -89.950393,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.1469,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094567,
    shape_pt_lon: -89.950098,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.1754,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.0945,
    shape_pt_lon: -89.949946,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.1915,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094379,
    shape_pt_lon: -89.94974,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.2137,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.094261,
    shape_pt_lon: -89.949608,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.2314,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093951,
    shape_pt_lon: -89.949353,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.2733,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09381,
    shape_pt_lon: -89.949358,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.2893,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09349,
    shape_pt_lon: -89.949372,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.3253,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093428,
    shape_pt_lon: -89.948812,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.3758,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09341,
    shape_pt_lon: -89.948087,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.4418,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093369,
    shape_pt_lon: -89.946441,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.5909,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093359,
    shape_pt_lon: -89.945709,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.6569,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093336,
    shape_pt_lon: -89.944909,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.7289,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093316,
    shape_pt_lon: -89.944157,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.7969,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093297,
    shape_pt_lon: -89.943403,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.865,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093256,
    shape_pt_lon: -89.941807,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.01,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093205,
    shape_pt_lon: -89.939622,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.2071,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093199,
    shape_pt_lon: -89.939317,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.2351,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.093135,
    shape_pt_lon: -89.939257,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.2437,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.09298,
    shape_pt_lon: -89.93921,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.2614,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.092793,
    shape_pt_lon: -89.939201,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.2824,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.091552,
    shape_pt_lon: -89.93924,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.4215,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.090559,
    shape_pt_lon: -89.939277,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.5325,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.090408,
    shape_pt_lon: -89.939282,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.5495,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.090337,
    shape_pt_lon: -89.939284,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.5575,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.090289,
    shape_pt_lon: -89.939287,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.5635,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.089093,
    shape_pt_lon: -89.939328,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.6966,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.088558,
    shape_pt_lon: -89.939344,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.7566,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.087993,
    shape_pt_lon: -89.939364,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.8206,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.087399,
    shape_pt_lon: -89.939386,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.8866,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.087114,
    shape_pt_lon: -89.9394,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.9187,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.087014,
    shape_pt_lon: -89.939404,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.9297,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086693,
    shape_pt_lon: -89.939413,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.9657,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086818,
    shape_pt_lon: -89.939183,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.991,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086845,
    shape_pt_lon: -89.939118,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.9977,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086873,
    shape_pt_lon: -89.939012,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.0072,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086891,
    shape_pt_lon: -89.938839,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.0233,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086848,
    shape_pt_lon: -89.936393,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.2443,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086837,
    shape_pt_lon: -89.935222,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.3503,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086813,
    shape_pt_lon: -89.934053,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.4564,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086799,
    shape_pt_lon: -89.933066,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.5454,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086787,
    shape_pt_lon: -89.932617,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.5864,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086777,
    shape_pt_lon: -89.932047,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.6374,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.7165,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.7305,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.7425,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.7575,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.8956,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.9916,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 305,
    shape_dist_traveled: 16.0666,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 306,
    shape_dist_traveled: 16.1146,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 307,
    shape_dist_traveled: 16.1816,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 308,
    shape_dist_traveled: 16.2036,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 309,
    shape_dist_traveled: 16.2346,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 310,
    shape_dist_traveled: 16.2456,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 311,
    shape_dist_traveled: 16.3076,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 312,
    shape_dist_traveled: 16.4216,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 313,
    shape_dist_traveled: 16.4436,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 314,
    shape_dist_traveled: 16.4607,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 315,
    shape_dist_traveled: 16.4707,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 316,
    shape_dist_traveled: 16.4837,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 317,
    shape_dist_traveled: 16.4887,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 318,
    shape_dist_traveled: 16.5007,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.5097,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 320,
    shape_dist_traveled: 16.6457,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 321,
    shape_dist_traveled: 16.7128,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.7198,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.758,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.7941,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076752,
    shape_pt_lon: -89.931603,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.8358,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076651,
    shape_pt_lon: -89.93165,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.8485,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076568,
    shape_pt_lon: -89.931743,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.8612,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076521,
    shape_pt_lon: -89.931842,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.8715,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07651,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.8756,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076491,
    shape_pt_lon: -89.931999,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.8861,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.9232,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 332,
    shape_dist_traveled: 16.9983,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 333,
    shape_dist_traveled: 17.0073,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 334,
    shape_dist_traveled: 17.0131,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 335,
    shape_dist_traveled: 17.0217,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.0347,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 337,
    shape_dist_traveled: 17.0958,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 338,
    shape_dist_traveled: 17.1016,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.1146,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.1286,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.1357,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.1452,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 343,
    shape_dist_traveled: 17.1962,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 344,
    shape_dist_traveled: 17.1994,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 345,
    shape_dist_traveled: 17.2057,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 346,
    shape_dist_traveled: 17.2117,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 347,
    shape_dist_traveled: 17.2397,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 348,
    shape_dist_traveled: 17.2527,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 349,
    shape_dist_traveled: 17.2613,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 350,
    shape_dist_traveled: 17.2671,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 351,
    shape_dist_traveled: 17.2761,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 352,
    shape_dist_traveled: 17.3512,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 353,
    shape_dist_traveled: 17.4093,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 354,
    shape_dist_traveled: 17.4244,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 355,
    shape_dist_traveled: 17.4315,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 356,
    shape_dist_traveled: 17.4375,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07639,
    shape_pt_lon: -89.930939,
    shape_pt_sequence: 357,
    shape_dist_traveled: 17.4845,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076296,
    shape_pt_lon: -89.928906,
    shape_pt_sequence: 358,
    shape_dist_traveled: 17.6688,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076211,
    shape_pt_lon: -89.927188,
    shape_pt_sequence: 359,
    shape_dist_traveled: 17.8241,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076204,
    shape_pt_lon: -89.926709,
    shape_pt_sequence: 360,
    shape_dist_traveled: 17.8681,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076155,
    shape_pt_lon: -89.926593,
    shape_pt_sequence: 361,
    shape_dist_traveled: 17.8793,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07613,
    shape_pt_lon: -89.925902,
    shape_pt_sequence: 362,
    shape_dist_traveled: 17.9423,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076108,
    shape_pt_lon: -89.925237,
    shape_pt_sequence: 363,
    shape_dist_traveled: 18.0024,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076096,
    shape_pt_lon: -89.924887,
    shape_pt_sequence: 364,
    shape_dist_traveled: 18.0334,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076107,
    shape_pt_lon: -89.924483,
    shape_pt_sequence: 365,
    shape_dist_traveled: 18.0704,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076143,
    shape_pt_lon: -89.923893,
    shape_pt_sequence: 366,
    shape_dist_traveled: 18.1237,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076151,
    shape_pt_lon: -89.923483,
    shape_pt_sequence: 367,
    shape_dist_traveled: 18.1607,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076144,
    shape_pt_lon: -89.923266,
    shape_pt_sequence: 368,
    shape_dist_traveled: 18.1807,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076129,
    shape_pt_lon: -89.923082,
    shape_pt_sequence: 369,
    shape_dist_traveled: 18.1978,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076099,
    shape_pt_lon: -89.922871,
    shape_pt_sequence: 370,
    shape_dist_traveled: 18.2171,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076053,
    shape_pt_lon: -89.922669,
    shape_pt_sequence: 371,
    shape_dist_traveled: 18.2357,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.076003,
    shape_pt_lon: -89.922444,
    shape_pt_sequence: 372,
    shape_dist_traveled: 18.2566,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07586,
    shape_pt_lon: -89.921972,
    shape_pt_sequence: 373,
    shape_dist_traveled: 18.3025,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075699,
    shape_pt_lon: -89.921578,
    shape_pt_sequence: 374,
    shape_dist_traveled: 18.3428,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075543,
    shape_pt_lon: -89.921267,
    shape_pt_sequence: 375,
    shape_dist_traveled: 18.3755,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.920621,
    shape_pt_sequence: 376,
    shape_dist_traveled: 18.4483,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07499,
    shape_pt_lon: -89.920392,
    shape_pt_sequence: 377,
    shape_dist_traveled: 18.476,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.074653,
    shape_pt_lon: -89.919998,
    shape_pt_sequence: 378,
    shape_dist_traveled: 18.5283,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073911,
    shape_pt_lon: -89.919244,
    shape_pt_sequence: 379,
    shape_dist_traveled: 18.6348,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072987,
    shape_pt_lon: -89.918272,
    shape_pt_sequence: 380,
    shape_dist_traveled: 18.7711,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072721,
    shape_pt_lon: -89.917926,
    shape_pt_sequence: 381,
    shape_dist_traveled: 18.8135,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072662,
    shape_pt_lon: -89.917833,
    shape_pt_sequence: 382,
    shape_dist_traveled: 18.8249,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072533,
    shape_pt_lon: -89.917632,
    shape_pt_sequence: 383,
    shape_dist_traveled: 18.8477,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072401,
    shape_pt_lon: -89.917398,
    shape_pt_sequence: 384,
    shape_dist_traveled: 18.8735,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072281,
    shape_pt_lon: -89.917162,
    shape_pt_sequence: 385,
    shape_dist_traveled: 18.8996,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072148,
    shape_pt_lon: -89.916839,
    shape_pt_sequence: 386,
    shape_dist_traveled: 18.9318,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072003,
    shape_pt_lon: -89.9164,
    shape_pt_sequence: 387,
    shape_dist_traveled: 18.9753,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071908,
    shape_pt_lon: -89.916024,
    shape_pt_sequence: 388,
    shape_dist_traveled: 19.0107,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071828,
    shape_pt_lon: -89.915611,
    shape_pt_sequence: 389,
    shape_dist_traveled: 19.0488,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071794,
    shape_pt_lon: -89.915329,
    shape_pt_sequence: 390,
    shape_dist_traveled: 19.0751,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071766,
    shape_pt_lon: -89.914899,
    shape_pt_sequence: 391,
    shape_dist_traveled: 19.1132,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071731,
    shape_pt_lon: -89.913309,
    shape_pt_sequence: 392,
    shape_dist_traveled: 19.2573,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071731,
    shape_pt_lon: -89.912849,
    shape_pt_sequence: 393,
    shape_dist_traveled: 19.2993,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071754,
    shape_pt_lon: -89.912449,
    shape_pt_sequence: 394,
    shape_dist_traveled: 19.3353,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071825,
    shape_pt_lon: -89.91197,
    shape_pt_sequence: 395,
    shape_dist_traveled: 19.3791,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07192,
    shape_pt_lon: -89.911537,
    shape_pt_sequence: 396,
    shape_dist_traveled: 19.4196,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07208,
    shape_pt_lon: -89.911031,
    shape_pt_sequence: 397,
    shape_dist_traveled: 19.469,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07221,
    shape_pt_lon: -89.910697,
    shape_pt_sequence: 398,
    shape_dist_traveled: 19.5021,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072832,
    shape_pt_lon: -89.909337,
    shape_pt_sequence: 399,
    shape_dist_traveled: 19.6436,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07295,
    shape_pt_lon: -89.909093,
    shape_pt_sequence: 400,
    shape_dist_traveled: 19.6692,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073071,
    shape_pt_lon: -89.908813,
    shape_pt_sequence: 401,
    shape_dist_traveled: 19.6987,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073454,
    shape_pt_lon: -89.907938,
    shape_pt_sequence: 402,
    shape_dist_traveled: 19.7887,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.07354,
    shape_pt_lon: -89.907681,
    shape_pt_sequence: 403,
    shape_dist_traveled: 19.8137,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073647,
    shape_pt_lon: -89.907316,
    shape_pt_sequence: 404,
    shape_dist_traveled: 19.8489,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073718,
    shape_pt_lon: -89.906985,
    shape_pt_sequence: 405,
    shape_dist_traveled: 19.8799,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.906127,
    shape_pt_sequence: 406,
    shape_dist_traveled: 19.9588,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.073909,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 407,
    shape_dist_traveled: 19.9831,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072612,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 408,
    shape_dist_traveled: 20.1312,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.905498,
    shape_pt_sequence: 409,
    shape_dist_traveled: 20.1583,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.072123,
    shape_pt_lon: -89.905469,
    shape_pt_sequence: 410,
    shape_dist_traveled: 20.1855,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.071761,
    shape_pt_lon: -89.905462,
    shape_pt_sequence: 411,
    shape_dist_traveled: 20.2265,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.070973,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 412,
    shape_dist_traveled: 20.3145,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.070547,
    shape_pt_lon: -89.905501,
    shape_pt_sequence: 413,
    shape_dist_traveled: 20.3625,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.06981,
    shape_pt_lon: -89.905526,
    shape_pt_sequence: 414,
    shape_dist_traveled: 20.4446,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.069572,
    shape_pt_lon: -89.905538,
    shape_pt_sequence: 415,
    shape_dist_traveled: 20.4716,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.069462,
    shape_pt_lon: -89.905631,
    shape_pt_sequence: 416,
    shape_dist_traveled: 20.486,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.06932,
    shape_pt_lon: -89.905638,
    shape_pt_sequence: 417,
    shape_dist_traveled: 20.5021,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.066051,
    shape_pt_lon: -89.905741,
    shape_pt_sequence: 418,
    shape_dist_traveled: 20.8682,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.065674,
    shape_pt_lon: -89.905739,
    shape_pt_sequence: 419,
    shape_dist_traveled: 20.9102,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905759,
    shape_pt_sequence: 420,
    shape_dist_traveled: 20.9722,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.063261,
    shape_pt_lon: -89.905815,
    shape_pt_sequence: 421,
    shape_dist_traveled: 21.1802,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.063039,
    shape_pt_lon: -89.905821,
    shape_pt_sequence: 422,
    shape_dist_traveled: 21.2052,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.062819,
    shape_pt_lon: -89.905827,
    shape_pt_sequence: 423,
    shape_dist_traveled: 21.2302,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.062388,
    shape_pt_lon: -89.90583,
    shape_pt_sequence: 424,
    shape_dist_traveled: 21.2783,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.062312,
    shape_pt_lon: -89.905832,
    shape_pt_sequence: 425,
    shape_dist_traveled: 21.2863,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.061822,
    shape_pt_lon: -89.905848,
    shape_pt_sequence: 426,
    shape_dist_traveled: 21.3413,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.061736,
    shape_pt_lon: -89.90585,
    shape_pt_sequence: 427,
    shape_dist_traveled: 21.3513,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -89.905852,
    shape_pt_sequence: 428,
    shape_dist_traveled: 21.3593,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.06154,
    shape_pt_lon: -89.905857,
    shape_pt_sequence: 429,
    shape_dist_traveled: 21.3733,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.060771,
    shape_pt_lon: -89.905882,
    shape_pt_sequence: 430,
    shape_dist_traveled: 21.4593,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.058755,
    shape_pt_lon: -89.905948,
    shape_pt_sequence: 431,
    shape_dist_traveled: 21.6844,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.058299,
    shape_pt_lon: -89.905963,
    shape_pt_sequence: 432,
    shape_dist_traveled: 21.7354,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.057829,
    shape_pt_lon: -89.90598,
    shape_pt_sequence: 433,
    shape_dist_traveled: 21.7884,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.057497,
    shape_pt_lon: -89.906026,
    shape_pt_sequence: 434,
    shape_dist_traveled: 21.8256,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.057289,
    shape_pt_lon: -89.906085,
    shape_pt_sequence: 435,
    shape_dist_traveled: 21.8494,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.056978,
    shape_pt_lon: -89.906209,
    shape_pt_sequence: 436,
    shape_dist_traveled: 21.8861,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.056743,
    shape_pt_lon: -89.906342,
    shape_pt_sequence: 437,
    shape_dist_traveled: 21.9147,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.056599,
    shape_pt_lon: -89.906442,
    shape_pt_sequence: 438,
    shape_dist_traveled: 21.934,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.056349,
    shape_pt_lon: -89.906648,
    shape_pt_sequence: 439,
    shape_dist_traveled: 21.9673,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.056018,
    shape_pt_lon: -89.906993,
    shape_pt_sequence: 440,
    shape_dist_traveled: 22.0162,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.055752,
    shape_pt_lon: -89.907229,
    shape_pt_sequence: 441,
    shape_dist_traveled: 22.052,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.055564,
    shape_pt_lon: -89.907361,
    shape_pt_sequence: 442,
    shape_dist_traveled: 22.077,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.055366,
    shape_pt_lon: -89.907479,
    shape_pt_sequence: 443,
    shape_dist_traveled: 22.1012,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.055047,
    shape_pt_lon: -89.90762,
    shape_pt_sequence: 444,
    shape_dist_traveled: 22.1385,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.054733,
    shape_pt_lon: -89.907711,
    shape_pt_sequence: 445,
    shape_dist_traveled: 22.1754,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.054409,
    shape_pt_lon: -89.907758,
    shape_pt_sequence: 446,
    shape_dist_traveled: 22.2116,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.053628,
    shape_pt_lon: -89.907774,
    shape_pt_sequence: 447,
    shape_dist_traveled: 22.2987,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.053343,
    shape_pt_lon: -89.907778,
    shape_pt_sequence: 448,
    shape_dist_traveled: 22.3307,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.053099,
    shape_pt_lon: -89.907786,
    shape_pt_sequence: 449,
    shape_dist_traveled: 22.3577,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.052868,
    shape_pt_lon: -89.907878,
    shape_pt_sequence: 450,
    shape_dist_traveled: 22.3849,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051852,
    shape_pt_lon: -89.908005,
    shape_pt_sequence: 451,
    shape_dist_traveled: 22.4994,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051229,
    shape_pt_lon: -89.908073,
    shape_pt_sequence: 452,
    shape_dist_traveled: 22.5697,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05122,
    shape_pt_lon: -89.908073,
    shape_pt_sequence: 453,
    shape_dist_traveled: 22.5707,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051128,
    shape_pt_lon: -89.908078,
    shape_pt_sequence: 454,
    shape_dist_traveled: 22.5807,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051123,
    shape_pt_lon: -89.907829,
    shape_pt_sequence: 455,
    shape_dist_traveled: 22.6037,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051122,
    shape_pt_lon: -89.907742,
    shape_pt_sequence: 456,
    shape_dist_traveled: 22.6117,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 457,
    shape_dist_traveled: 22.6337,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 458,
    shape_dist_traveled: 22.8638,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 459,
    shape_dist_traveled: 22.9268,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 460,
    shape_dist_traveled: 23.0018,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 461,
    shape_dist_traveled: 23.0438,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 462,
    shape_dist_traveled: 23.0579,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 463,
    shape_dist_traveled: 23.154,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050943,
    shape_pt_lon: -89.900731,
    shape_pt_sequence: 464,
    shape_dist_traveled: 23.2461,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050903,
    shape_pt_lon: -89.899641,
    shape_pt_sequence: 465,
    shape_dist_traveled: 23.3442,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050889,
    shape_pt_lon: -89.898999,
    shape_pt_sequence: 466,
    shape_dist_traveled: 23.4032,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05085,
    shape_pt_lon: -89.897408,
    shape_pt_sequence: 467,
    shape_dist_traveled: 23.5462,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050672,
    shape_pt_lon: -89.891706,
    shape_pt_sequence: 468,
    shape_dist_traveled: 24.0626,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.889403,
    shape_pt_sequence: 469,
    shape_dist_traveled: 24.2717,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05058,
    shape_pt_lon: -89.888583,
    shape_pt_sequence: 470,
    shape_dist_traveled: 24.3458,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050576,
    shape_pt_lon: -89.888333,
    shape_pt_sequence: 471,
    shape_dist_traveled: 24.3678,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -89.886713,
    shape_pt_sequence: 472,
    shape_dist_traveled: 24.5148,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 473,
    shape_dist_traveled: 24.5598,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050538,
    shape_pt_lon: -89.886157,
    shape_pt_sequence: 474,
    shape_dist_traveled: 24.5648,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050526,
    shape_pt_lon: -89.885636,
    shape_pt_sequence: 475,
    shape_dist_traveled: 24.6119,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050508,
    shape_pt_lon: -89.884838,
    shape_pt_sequence: 476,
    shape_dist_traveled: 24.6849,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050509,
    shape_pt_lon: -89.884407,
    shape_pt_sequence: 477,
    shape_dist_traveled: 24.7239,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050493,
    shape_pt_lon: -89.883511,
    shape_pt_sequence: 478,
    shape_dist_traveled: 24.8049,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.882708,
    shape_pt_sequence: 479,
    shape_dist_traveled: 24.8769,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050472,
    shape_pt_lon: -89.882373,
    shape_pt_sequence: 480,
    shape_dist_traveled: 24.9079,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050471,
    shape_pt_lon: -89.882305,
    shape_pt_sequence: 481,
    shape_dist_traveled: 24.9139,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050441,
    shape_pt_lon: -89.880866,
    shape_pt_sequence: 482,
    shape_dist_traveled: 25.044,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050439,
    shape_pt_lon: -89.880782,
    shape_pt_sequence: 483,
    shape_dist_traveled: 25.052,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050379,
    shape_pt_lon: -89.878393,
    shape_pt_sequence: 484,
    shape_dist_traveled: 25.2681,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050365,
    shape_pt_lon: -89.877847,
    shape_pt_sequence: 485,
    shape_dist_traveled: 25.3171,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050322,
    shape_pt_lon: -89.875876,
    shape_pt_sequence: 486,
    shape_dist_traveled: 25.4952,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050302,
    shape_pt_lon: -89.874998,
    shape_pt_sequence: 487,
    shape_dist_traveled: 25.5752,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050298,
    shape_pt_lon: -89.874722,
    shape_pt_sequence: 488,
    shape_dist_traveled: 25.6002,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050257,
    shape_pt_lon: -89.873187,
    shape_pt_sequence: 489,
    shape_dist_traveled: 25.7393,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050187,
    shape_pt_lon: -89.870761,
    shape_pt_sequence: 490,
    shape_dist_traveled: 25.9584,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05018,
    shape_pt_lon: -89.870577,
    shape_pt_sequence: 491,
    shape_dist_traveled: 25.9755,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050161,
    shape_pt_lon: -89.870017,
    shape_pt_sequence: 492,
    shape_dist_traveled: 26.0255,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050149,
    shape_pt_lon: -89.869652,
    shape_pt_sequence: 493,
    shape_dist_traveled: 26.0585,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050136,
    shape_pt_lon: -89.869212,
    shape_pt_sequence: 494,
    shape_dist_traveled: 26.0985,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05012,
    shape_pt_lon: -89.86874,
    shape_pt_sequence: 495,
    shape_dist_traveled: 26.1416,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05007,
    shape_pt_lon: -89.867199,
    shape_pt_sequence: 496,
    shape_dist_traveled: 26.2807,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050052,
    shape_pt_lon: -89.866387,
    shape_pt_sequence: 497,
    shape_dist_traveled: 26.3547,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049987,
    shape_pt_lon: -89.864778,
    shape_pt_sequence: 498,
    shape_dist_traveled: 26.4999,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049946,
    shape_pt_lon: -89.864058,
    shape_pt_sequence: 499,
    shape_dist_traveled: 26.5651,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049917,
    shape_pt_lon: -89.863214,
    shape_pt_sequence: 500,
    shape_dist_traveled: 26.6421,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049898,
    shape_pt_lon: -89.862159,
    shape_pt_sequence: 501,
    shape_dist_traveled: 26.7371,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049821,
    shape_pt_lon: -89.862016,
    shape_pt_sequence: 502,
    shape_dist_traveled: 26.7524,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04979,
    shape_pt_lon: -89.860852,
    shape_pt_sequence: 503,
    shape_dist_traveled: 26.8584,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049787,
    shape_pt_lon: -89.860712,
    shape_pt_sequence: 504,
    shape_dist_traveled: 26.8705,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04974,
    shape_pt_lon: -89.858787,
    shape_pt_sequence: 505,
    shape_dist_traveled: 27.0446,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049711,
    shape_pt_lon: -89.857728,
    shape_pt_sequence: 506,
    shape_dist_traveled: 27.1406,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857596,
    shape_pt_sequence: 507,
    shape_dist_traveled: 27.155,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857456,
    shape_pt_sequence: 508,
    shape_dist_traveled: 27.168,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049768,
    shape_pt_lon: -89.857169,
    shape_pt_sequence: 509,
    shape_dist_traveled: 27.194,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04976,
    shape_pt_lon: -89.856952,
    shape_pt_sequence: 510,
    shape_dist_traveled: 27.2131,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049693,
    shape_pt_lon: -89.854776,
    shape_pt_sequence: 511,
    shape_dist_traveled: 27.4102,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049661,
    shape_pt_lon: -89.853303,
    shape_pt_sequence: 512,
    shape_dist_traveled: 27.5432,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049639,
    shape_pt_lon: -89.852306,
    shape_pt_sequence: 513,
    shape_dist_traveled: 27.6343,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049623,
    shape_pt_lon: -89.850912,
    shape_pt_sequence: 514,
    shape_dist_traveled: 27.7603,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049619,
    shape_pt_lon: -89.849989,
    shape_pt_sequence: 515,
    shape_dist_traveled: 27.8433,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049566,
    shape_pt_lon: -89.849872,
    shape_pt_sequence: 516,
    shape_dist_traveled: 27.8558,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04956,
    shape_pt_lon: -89.849331,
    shape_pt_sequence: 517,
    shape_dist_traveled: 27.9048,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049609,
    shape_pt_lon: -89.849221,
    shape_pt_sequence: 518,
    shape_dist_traveled: 27.9165,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049601,
    shape_pt_lon: -89.848321,
    shape_pt_sequence: 519,
    shape_dist_traveled: 27.9975,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049547,
    shape_pt_lon: -89.84591,
    shape_pt_sequence: 520,
    shape_dist_traveled: 28.2155,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049512,
    shape_pt_lon: -89.843906,
    shape_pt_sequence: 521,
    shape_dist_traveled: 28.3976,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 522,
    shape_dist_traveled: 28.4066,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 523,
    shape_dist_traveled: 28.4176,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 524,
    shape_dist_traveled: 28.5567,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 525,
    shape_dist_traveled: 28.6737,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 526,
    shape_dist_traveled: 28.7637,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 527,
    shape_dist_traveled: 28.8668,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 528,
    shape_dist_traveled: 29.0098,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 529,
    shape_dist_traveled: 29.361,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 530,
    shape_dist_traveled: 29.451,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 531,
    shape_dist_traveled: 29.6471,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 532,
    shape_dist_traveled: 29.6551,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 533,
    shape_dist_traveled: 29.6631,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 534,
    shape_dist_traveled: 29.7791,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 535,
    shape_dist_traveled: 29.9154,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 536,
    shape_dist_traveled: 30.0315,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 537,
    shape_dist_traveled: 30.1496,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 538,
    shape_dist_traveled: 30.2558,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048899,
    shape_pt_lon: -89.82295,
    shape_pt_sequence: 539,
    shape_dist_traveled: 30.2938,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048909,
    shape_pt_lon: -89.822418,
    shape_pt_sequence: 540,
    shape_dist_traveled: 30.3428,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048943,
    shape_pt_lon: -89.822009,
    shape_pt_sequence: 541,
    shape_dist_traveled: 30.38,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049037,
    shape_pt_lon: -89.821308,
    shape_pt_sequence: 542,
    shape_dist_traveled: 30.444,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049116,
    shape_pt_lon: -89.820899,
    shape_pt_sequence: 543,
    shape_dist_traveled: 30.4821,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049236,
    shape_pt_lon: -89.820414,
    shape_pt_sequence: 544,
    shape_dist_traveled: 30.528,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049377,
    shape_pt_lon: -89.819938,
    shape_pt_sequence: 545,
    shape_dist_traveled: 30.5738,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04977,
    shape_pt_lon: -89.818834,
    shape_pt_sequence: 546,
    shape_dist_traveled: 30.6826,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050082,
    shape_pt_lon: -89.817972,
    shape_pt_sequence: 547,
    shape_dist_traveled: 30.7681,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050423,
    shape_pt_lon: -89.817018,
    shape_pt_sequence: 548,
    shape_dist_traveled: 30.863,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050513,
    shape_pt_lon: -89.816697,
    shape_pt_sequence: 549,
    shape_dist_traveled: 30.8937,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050598,
    shape_pt_lon: -89.816314,
    shape_pt_sequence: 550,
    shape_dist_traveled: 30.9291,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050647,
    shape_pt_lon: -89.815982,
    shape_pt_sequence: 551,
    shape_dist_traveled: 30.9595,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050671,
    shape_pt_lon: -89.815662,
    shape_pt_sequence: 552,
    shape_dist_traveled: 30.9887,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050679,
    shape_pt_lon: -89.815568,
    shape_pt_sequence: 553,
    shape_dist_traveled: 30.9977,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050683,
    shape_pt_lon: -89.815217,
    shape_pt_sequence: 554,
    shape_dist_traveled: 31.0288,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050673,
    shape_pt_lon: -89.814931,
    shape_pt_sequence: 555,
    shape_dist_traveled: 31.0548,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050608,
    shape_pt_lon: -89.814331,
    shape_pt_sequence: 556,
    shape_dist_traveled: 31.1104,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.814296,
    shape_pt_sequence: 557,
    shape_dist_traveled: 31.1134,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05059,
    shape_pt_lon: -89.81421,
    shape_pt_sequence: 558,
    shape_dist_traveled: 31.1216,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.813383,
    shape_pt_sequence: 559,
    shape_dist_traveled: 31.1966,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050428,
    shape_pt_lon: -89.812981,
    shape_pt_sequence: 560,
    shape_dist_traveled: 31.2339,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050269,
    shape_pt_lon: -89.81182,
    shape_pt_sequence: 561,
    shape_dist_traveled: 31.3404,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050239,
    shape_pt_lon: -89.811597,
    shape_pt_sequence: 562,
    shape_dist_traveled: 31.3607,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.050142,
    shape_pt_lon: -89.810873,
    shape_pt_sequence: 563,
    shape_dist_traveled: 31.4276,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049947,
    shape_pt_lon: -89.809581,
    shape_pt_sequence: 564,
    shape_dist_traveled: 31.5455,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.809472,
    shape_pt_sequence: 565,
    shape_dist_traveled: 31.5557,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049912,
    shape_pt_lon: -89.809331,
    shape_pt_sequence: 566,
    shape_dist_traveled: 31.5688,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049889,
    shape_pt_lon: -89.809165,
    shape_pt_sequence: 567,
    shape_dist_traveled: 31.5841,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049819,
    shape_pt_lon: -89.808478,
    shape_pt_sequence: 568,
    shape_dist_traveled: 31.6465,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049741,
    shape_pt_lon: -89.807879,
    shape_pt_sequence: 569,
    shape_dist_traveled: 31.7013,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -89.807056,
    shape_pt_sequence: 570,
    shape_dist_traveled: 31.7774,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.0495,
    shape_pt_lon: -89.806127,
    shape_pt_sequence: 571,
    shape_dist_traveled: 31.8625,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049248,
    shape_pt_lon: -89.803895,
    shape_pt_sequence: 572,
    shape_dist_traveled: 32.0663,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.049058,
    shape_pt_lon: -89.802452,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.199,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048936,
    shape_pt_lon: -89.801578,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.2791,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04864,
    shape_pt_lon: -89.799391,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.4798,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048528,
    shape_pt_lon: -89.798569,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.5548,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.0484,
    shape_pt_lon: -89.797619,
    shape_pt_sequence: 577,
    shape_dist_traveled: 32.6419,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048371,
    shape_pt_lon: -89.797254,
    shape_pt_sequence: 578,
    shape_dist_traveled: 32.6751,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048337,
    shape_pt_lon: -89.796104,
    shape_pt_sequence: 579,
    shape_dist_traveled: 32.7792,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.048326,
    shape_pt_lon: -89.795509,
    shape_pt_sequence: 580,
    shape_dist_traveled: 32.8332,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.047416,
    shape_pt_lon: -89.795732,
    shape_pt_sequence: 581,
    shape_dist_traveled: 32.9371,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.047185,
    shape_pt_lon: -89.795788,
    shape_pt_sequence: 582,
    shape_dist_traveled: 32.9636,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.046642,
    shape_pt_lon: -89.795904,
    shape_pt_sequence: 583,
    shape_dist_traveled: 33.0254,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.046219,
    shape_pt_lon: -89.795951,
    shape_pt_sequence: 584,
    shape_dist_traveled: 33.0726,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.045958,
    shape_pt_lon: -89.795957,
    shape_pt_sequence: 585,
    shape_dist_traveled: 33.1016,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.044949,
    shape_pt_lon: -89.79595,
    shape_pt_sequence: 586,
    shape_dist_traveled: 33.2146,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.04374,
    shape_pt_lon: -89.795943,
    shape_pt_sequence: 587,
    shape_dist_traveled: 33.3496,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.043752,
    shape_pt_lon: -89.796376,
    shape_pt_sequence: 588,
    shape_dist_traveled: 33.3886,
  },
  {
    shape_id: 38229,
    shape_pt_lat: 35.043813,
    shape_pt_lon: -89.797497,
    shape_pt_sequence: 589,
    shape_dist_traveled: 33.4898,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145766,
    shape_pt_lon: -90.046733,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5843,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14565,
    shape_pt_lon: -90.046271,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6283,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145621,
    shape_pt_lon: -90.046134,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6406,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145598,
    shape_pt_lon: -90.045926,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6599,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145528,
    shape_pt_lon: -90.045569,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6926,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145193,
    shape_pt_lon: -90.044298,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.8137,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145094,
    shape_pt_lon: -90.043941,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8476,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145062,
    shape_pt_lon: -90.043826,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.8593,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.145039,
    shape_pt_lon: -90.043736,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8675,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144974,
    shape_pt_lon: -90.043495,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.8906,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144626,
    shape_pt_lon: -90.042423,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9942,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144491,
    shape_pt_lon: -90.042019,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.0342,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14445,
    shape_pt_lon: -90.041976,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0406,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.1444,
    shape_pt_lon: -90.041851,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0531,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14435,
    shape_pt_lon: -90.041695,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.068,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144312,
    shape_pt_lon: -90.041577,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0797,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144241,
    shape_pt_lon: -90.04136,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1012,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.144074,
    shape_pt_lon: -90.04076,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1585,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143987,
    shape_pt_lon: -90.040428,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1901,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143873,
    shape_pt_lon: -90.039966,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2328,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14383,
    shape_pt_lon: -90.03971,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2573,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143778,
    shape_pt_lon: -90.039188,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3047,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143719,
    shape_pt_lon: -90.038859,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.3345,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143612,
    shape_pt_lon: -90.038478,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.3715,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143472,
    shape_pt_lon: -90.038113,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.4078,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143334,
    shape_pt_lon: -90.037738,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.4453,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143256,
    shape_pt_lon: -90.037451,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4726,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.143157,
    shape_pt_lon: -90.037051,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.5105,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142743,
    shape_pt_lon: -90.035473,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.6598,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142722,
    shape_pt_lon: -90.035371,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.67,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142708,
    shape_pt_lon: -90.035292,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.6772,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142689,
    shape_pt_lon: -90.035165,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.6884,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142727,
    shape_pt_lon: -90.035042,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.7001,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142718,
    shape_pt_lon: -90.034973,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.7072,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142608,
    shape_pt_lon: -90.033807,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.8129,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142565,
    shape_pt_lon: -90.033456,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.8453,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142558,
    shape_pt_lon: -90.033182,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.8693,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142567,
    shape_pt_lon: -90.032941,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.8913,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142626,
    shape_pt_lon: -90.032316,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.9486,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142641,
    shape_pt_lon: -90.032026,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.9747,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14263,
    shape_pt_lon: -90.031433,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.0277,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142657,
    shape_pt_lon: -90.030926,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.0738,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142669,
    shape_pt_lon: -90.03084,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.0819,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142716,
    shape_pt_lon: -90.03056,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.1073,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142765,
    shape_pt_lon: -90.030331,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.1292,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142791,
    shape_pt_lon: -90.030198,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.1416,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142836,
    shape_pt_lon: -90.0299,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.169,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142854,
    shape_pt_lon: -90.029591,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.1971,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142848,
    shape_pt_lon: -90.029315,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.2221,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.2342,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.2794,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.3769,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.3929,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.5267,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14099,
    shape_pt_lon: -90.027803,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.5688,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.140801,
    shape_pt_lon: -90.027861,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.5906,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.140387,
    shape_pt_lon: -90.028065,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.64,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.028117,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.6558,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.6792,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.139023,
    shape_pt_lon: -90.028323,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.795,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.138878,
    shape_pt_lon: -90.028336,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.8112,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.138729,
    shape_pt_lon: -90.028321,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.8283,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.138453,
    shape_pt_lon: -90.028239,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.86,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.138299,
    shape_pt_lon: -90.028198,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.8775,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.13807,
    shape_pt_lon: -90.02819,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.9025,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.9116,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.13704,
    shape_pt_lon: -90.028232,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.0186,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.136129,
    shape_pt_lon: -90.028261,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.1207,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.134849,
    shape_pt_lon: -90.028301,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.2637,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.133589,
    shape_pt_lon: -90.028343,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.4048,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.13317,
    shape_pt_lon: -90.028358,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.4518,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.132928,
    shape_pt_lon: -90.028358,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.4788,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131761,
    shape_pt_lon: -90.028378,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.6088,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.027252,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.7108,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131741,
    shape_pt_lon: -90.02703,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.7308,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.13174,
    shape_pt_lon: -90.026917,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.7408,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131729,
    shape_pt_lon: -90.026568,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.7728,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131693,
    shape_pt_lon: -90.026349,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.7923,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.8201,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.8562,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.8762,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.9676,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.9882,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.0412,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.0457,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.0663,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.073,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.0758,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.0936,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.1208,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.1544,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.231,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.2951,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.347,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.3628,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.3764,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.4116,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.5348,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.6646,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.7628,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.81,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.828,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.016751,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.8516,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.125917,
    shape_pt_lon: -90.016143,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.9202,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.124924,
    shape_pt_lon: -90.014434,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.1109,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.124501,
    shape_pt_lon: -90.013705,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.1911,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.124171,
    shape_pt_lon: -90.013136,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.2549,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12348,
    shape_pt_lon: -90.011949,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.3867,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -90.011677,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.4175,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.123301,
    shape_pt_lon: -90.011644,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.4211,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.12279,
    shape_pt_lon: -90.01076,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.5194,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.122543,
    shape_pt_lon: -90.010346,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.5658,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.122363,
    shape_pt_lon: -90.010048,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.5994,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.121429,
    shape_pt_lon: -90.008595,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.7673,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.121217,
    shape_pt_lon: -90.008259,
    shape_pt_sequence: 142,
    shape_dist_traveled: 6.8059,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.120687,
    shape_pt_lon: -90.007442,
    shape_pt_sequence: 143,
    shape_dist_traveled: 6.9005,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.120106,
    shape_pt_lon: -90.006533,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.0051,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.0258,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.119937,
    shape_pt_lon: -90.006268,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.0358,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.119197,
    shape_pt_lon: -90.005107,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.1696,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.119091,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.188,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.118393,
    shape_pt_lon: -90.003858,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.3141,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.118248,
    shape_pt_lon: -90.003629,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.3405,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11782,
    shape_pt_lon: -90.00296,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.4173,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.117619,
    shape_pt_lon: -90.002647,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.4529,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11727,
    shape_pt_lon: -90.002104,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.5162,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.117101,
    shape_pt_lon: -90.001834,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.547,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11686,
    shape_pt_lon: -90.001403,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.5944,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.116827,
    shape_pt_lon: -90.001336,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.6016,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.116452,
    shape_pt_lon: -90.000693,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.6732,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.116358,
    shape_pt_lon: -90.000539,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.6904,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.116176,
    shape_pt_lon: -90.00028,
    shape_pt_sequence: 159,
    shape_dist_traveled: 7.7216,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.115773,
    shape_pt_lon: -89.999719,
    shape_pt_sequence: 160,
    shape_dist_traveled: 7.7896,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.115591,
    shape_pt_lon: -89.999479,
    shape_pt_sequence: 161,
    shape_dist_traveled: 7.8193,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11536,
    shape_pt_lon: -89.999139,
    shape_pt_sequence: 162,
    shape_dist_traveled: 7.8598,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.113746,
    shape_pt_lon: -89.996673,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.1464,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.113717,
    shape_pt_lon: -89.996628,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.152,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.113499,
    shape_pt_lon: -89.996299,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.1897,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.113236,
    shape_pt_lon: -89.995889,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.2367,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.113011,
    shape_pt_lon: -89.995553,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.2771,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.112618,
    shape_pt_lon: -89.994952,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.3468,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.112561,
    shape_pt_lon: -89.994868,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.3568,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.112193,
    shape_pt_lon: -89.994306,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.4222,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.111537,
    shape_pt_lon: -89.993302,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.5381,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11106,
    shape_pt_lon: -89.992569,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.6242,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 173,
    shape_dist_traveled: 8.6848,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.699,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 175,
    shape_dist_traveled: 8.7154,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 176,
    shape_dist_traveled: 8.7574,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 177,
    shape_dist_traveled: 8.9073,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.9398,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.0076,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.021,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.0517,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.0708,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108396,
    shape_pt_lon: -89.988522,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.0972,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.108348,
    shape_pt_lon: -89.988462,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.1042,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.107991,
    shape_pt_lon: -89.988071,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.1581,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.107749,
    shape_pt_lon: -89.987853,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.1911,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.107475,
    shape_pt_lon: -89.987644,
    shape_pt_sequence: 187,
    shape_dist_traveled: 9.2266,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -89.987492,
    shape_pt_sequence: 188,
    shape_dist_traveled: 9.2561,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.106839,
    shape_pt_lon: -89.987218,
    shape_pt_sequence: 189,
    shape_dist_traveled: 9.3085,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 190,
    shape_dist_traveled: 9.3507,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.10627,
    shape_pt_lon: -89.986761,
    shape_pt_sequence: 191,
    shape_dist_traveled: 9.3837,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105949,
    shape_pt_lon: -89.986453,
    shape_pt_sequence: 192,
    shape_dist_traveled: 9.4293,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105683,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 193,
    shape_dist_traveled: 9.4696,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105579,
    shape_pt_lon: -89.986008,
    shape_pt_sequence: 194,
    shape_dist_traveled: 9.4873,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -89.985738,
    shape_pt_sequence: 195,
    shape_dist_traveled: 9.52,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 196,
    shape_dist_traveled: 9.5292,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105203,
    shape_pt_lon: -89.985466,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.5519,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.105062,
    shape_pt_lon: -89.98525,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.5769,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.104927,
    shape_pt_lon: -89.985038,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.6011,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.10462,
    shape_pt_lon: -89.984552,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.6573,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.104387,
    shape_pt_lon: -89.984177,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.7001,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.104063,
    shape_pt_lon: -89.983699,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.7562,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.1036,
    shape_pt_lon: -89.982976,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.8402,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.102607,
    shape_pt_lon: -89.981424,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.0189,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.102272,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.0795,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.102028,
    shape_pt_lon: -89.980468,
    shape_pt_sequence: 206,
    shape_dist_traveled: 10.1269,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.101844,
    shape_pt_lon: -89.980151,
    shape_pt_sequence: 207,
    shape_dist_traveled: 10.1619,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.100876,
    shape_pt_lon: -89.978307,
    shape_pt_sequence: 208,
    shape_dist_traveled: 10.3608,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -89.978038,
    shape_pt_sequence: 209,
    shape_dist_traveled: 10.3896,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.977596,
    shape_pt_sequence: 210,
    shape_dist_traveled: 10.4373,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.099702,
    shape_pt_lon: -89.976056,
    shape_pt_sequence: 211,
    shape_dist_traveled: 10.6032,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.099554,
    shape_pt_lon: -89.975775,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.6335,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.099477,
    shape_pt_lon: -89.975625,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.6496,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.099079,
    shape_pt_lon: -89.974863,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.732,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.098876,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.7738,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.098556,
    shape_pt_lon: -89.973858,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.839,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.098287,
    shape_pt_lon: -89.973401,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.8907,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.098014,
    shape_pt_lon: -89.973008,
    shape_pt_sequence: 218,
    shape_dist_traveled: 10.9374,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.09781,
    shape_pt_lon: -89.972738,
    shape_pt_sequence: 219,
    shape_dist_traveled: 10.9714,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.972644,
    shape_pt_sequence: 220,
    shape_dist_traveled: 10.9834,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.097492,
    shape_pt_lon: -89.972371,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.0195,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.097178,
    shape_pt_lon: -89.972056,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.0643,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.096506,
    shape_pt_lon: -89.971448,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.1579,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.096076,
    shape_pt_lon: -89.971066,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.2176,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.095461,
    shape_pt_lon: -89.970517,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.302,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.095147,
    shape_pt_lon: -89.97023,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.3456,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.095028,
    shape_pt_lon: -89.970124,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.3622,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.094655,
    shape_pt_lon: -89.969791,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.4136,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.092773,
    shape_pt_lon: -89.968117,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.6731,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.09271,
    shape_pt_lon: -89.968051,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.6823,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.092173,
    shape_pt_lon: -89.967574,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.7561,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.09159,
    shape_pt_lon: -89.967051,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.8369,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.091458,
    shape_pt_lon: -89.96694,
    shape_pt_sequence: 233,
    shape_dist_traveled: 11.8549,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.090861,
    shape_pt_lon: -89.966416,
    shape_pt_sequence: 234,
    shape_dist_traveled: 11.9368,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.090059,
    shape_pt_lon: -89.965768,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.0436,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08799,
    shape_pt_lon: -89.964163,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.3171,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08761,
    shape_pt_lon: -89.96387,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.3671,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.087159,
    shape_pt_lon: -89.96349,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.4284,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.086457,
    shape_pt_lon: -89.962842,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.5262,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08599,
    shape_pt_lon: -89.962362,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.5936,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.085793,
    shape_pt_lon: -89.962153,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.6227,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.085059,
    shape_pt_lon: -89.961303,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.7352,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.084872,
    shape_pt_lon: -89.961078,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.7649,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.084278,
    shape_pt_lon: -89.960361,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.8575,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.083742,
    shape_pt_lon: -89.959723,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.9403,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.083205,
    shape_pt_lon: -89.959084,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.0245,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.083178,
    shape_pt_lon: -89.959052,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.0287,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08305,
    shape_pt_lon: -89.958897,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.0485,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.082897,
    shape_pt_lon: -89.958712,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.0725,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08266,
    shape_pt_lon: -89.95843,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.1086,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.082189,
    shape_pt_lon: -89.957871,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.1822,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.082102,
    shape_pt_lon: -89.957772,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.1956,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.081846,
    shape_pt_lon: -89.957458,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.2352,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.081418,
    shape_pt_lon: -89.956946,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.3024,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08121,
    shape_pt_lon: -89.956703,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.3342,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.081141,
    shape_pt_lon: -89.956621,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.3448,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.081081,
    shape_pt_lon: -89.956547,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.3547,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.081057,
    shape_pt_lon: -89.956517,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.3584,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.080586,
    shape_pt_lon: -89.955955,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.4312,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.080212,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.4899,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.08011,
    shape_pt_lon: -89.955388,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.5054,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.080076,
    shape_pt_lon: -89.955347,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.5104,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.079874,
    shape_pt_lon: -89.955103,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.5423,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.079393,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.6172,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.079346,
    shape_pt_lon: -89.954475,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.6243,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.078746,
    shape_pt_lon: -89.953756,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.7177,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.078247,
    shape_pt_lon: -89.953157,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.7961,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.078091,
    shape_pt_lon: -89.952973,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.8195,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.077269,
    shape_pt_lon: -89.951971,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.9489,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07722,
    shape_pt_lon: -89.951911,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.956,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07679,
    shape_pt_lon: -89.95139,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.0238,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.0557,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076531,
    shape_pt_lon: -89.9511,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.0628,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07609,
    shape_pt_lon: -89.950581,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.1314,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07559,
    shape_pt_lon: -89.949994,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.2085,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075098,
    shape_pt_lon: -89.949446,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.2829,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.074929,
    shape_pt_lon: -89.94927,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.3069,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.074452,
    shape_pt_lon: -89.9488,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.3754,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.074181,
    shape_pt_lon: -89.948549,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.4132,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073991,
    shape_pt_lon: -89.948372,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.4396,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073742,
    shape_pt_lon: -89.948159,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.4734,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072812,
    shape_pt_lon: -89.94743,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.5966,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07271,
    shape_pt_lon: -89.947353,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.6105,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072568,
    shape_pt_lon: -89.947248,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.6285,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07151,
    shape_pt_lon: -89.946442,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.7681,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.070217,
    shape_pt_lon: -89.945456,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.9374,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.069648,
    shape_pt_lon: -89.94502,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.0129,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.068987,
    shape_pt_lon: -89.944521,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.0995,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.94435,
    shape_pt_sequence: 289,
    shape_dist_traveled: 15.1286,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.068487,
    shape_pt_lon: -89.94414,
    shape_pt_sequence: 290,
    shape_dist_traveled: 15.165,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.0682,
    shape_pt_lon: -89.943922,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.2027,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067507,
    shape_pt_lon: -89.943397,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.2934,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067351,
    shape_pt_lon: -89.943279,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.3145,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.066412,
    shape_pt_lon: -89.942563,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.4375,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065689,
    shape_pt_lon: -89.942,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.5324,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065277,
    shape_pt_lon: -89.941642,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.5898,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065199,
    shape_pt_lon: -89.941572,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.5998,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064957,
    shape_pt_lon: -89.941359,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.6334,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064777,
    shape_pt_lon: -89.941181,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.659,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064839,
    shape_pt_lon: -89.941101,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.6689,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064944,
    shape_pt_lon: -89.940951,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.686,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065016,
    shape_pt_lon: -89.940826,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.7004,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065076,
    shape_pt_lon: -89.940681,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.7151,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065112,
    shape_pt_lon: -89.940521,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.7297,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065121,
    shape_pt_lon: -89.940443,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.7368,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065127,
    shape_pt_lon: -89.940139,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.7648,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065097,
    shape_pt_lon: -89.938544,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.9088,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065058,
    shape_pt_lon: -89.937512,
    shape_pt_sequence: 308,
    shape_dist_traveled: 16.002,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064995,
    shape_pt_lon: -89.935988,
    shape_pt_sequence: 309,
    shape_dist_traveled: 16.1401,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064916,
    shape_pt_lon: -89.933707,
    shape_pt_sequence: 310,
    shape_dist_traveled: 16.3473,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064887,
    shape_pt_lon: -89.932719,
    shape_pt_sequence: 311,
    shape_dist_traveled: 16.4363,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064879,
    shape_pt_lon: -89.932423,
    shape_pt_sequence: 312,
    shape_dist_traveled: 16.4634,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.064877,
    shape_pt_lon: -89.93233,
    shape_pt_sequence: 313,
    shape_dist_traveled: 16.4714,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06487,
    shape_pt_lon: -89.932046,
    shape_pt_sequence: 314,
    shape_dist_traveled: 16.4974,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06632,
    shape_pt_lon: -89.93212,
    shape_pt_sequence: 315,
    shape_dist_traveled: 16.6595,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.066881,
    shape_pt_lon: -89.932117,
    shape_pt_sequence: 316,
    shape_dist_traveled: 16.7225,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067045,
    shape_pt_lon: -89.932129,
    shape_pt_sequence: 317,
    shape_dist_traveled: 16.7415,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067282,
    shape_pt_lon: -89.932176,
    shape_pt_sequence: 318,
    shape_dist_traveled: 16.7679,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067553,
    shape_pt_lon: -89.932161,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.7979,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06759,
    shape_pt_lon: -89.932188,
    shape_pt_sequence: 320,
    shape_dist_traveled: 16.8023,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 321,
    shape_dist_traveled: 16.8096,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.8147,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.8307,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.8427,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.8857,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.9788,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 327,
    shape_dist_traveled: 17.0769,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 328,
    shape_dist_traveled: 17.102,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 329,
    shape_dist_traveled: 17.191,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 330,
    shape_dist_traveled: 17.2692,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 331,
    shape_dist_traveled: 17.3775,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 332,
    shape_dist_traveled: 17.3775,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 333,
    shape_dist_traveled: 17.4477,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 334,
    shape_dist_traveled: 17.5881,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 335,
    shape_dist_traveled: 17.7927,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.7998,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 337,
    shape_dist_traveled: 17.8149,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 338,
    shape_dist_traveled: 17.873,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.9481,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.9571,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.9629,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.9715,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 343,
    shape_dist_traveled: 17.9845,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.0455,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.0514,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.0644,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.0784,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.0855,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.095,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.146,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.1492,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.1555,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.1615,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.1895,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.2025,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 356,
    shape_dist_traveled: 18.2111,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 357,
    shape_dist_traveled: 18.2169,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 358,
    shape_dist_traveled: 18.2259,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 359,
    shape_dist_traveled: 18.301,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 360,
    shape_dist_traveled: 18.3591,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 361,
    shape_dist_traveled: 18.3742,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 362,
    shape_dist_traveled: 18.3813,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 363,
    shape_dist_traveled: 18.3873,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07639,
    shape_pt_lon: -89.930939,
    shape_pt_sequence: 364,
    shape_dist_traveled: 18.4343,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076296,
    shape_pt_lon: -89.928906,
    shape_pt_sequence: 365,
    shape_dist_traveled: 18.6186,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076211,
    shape_pt_lon: -89.927188,
    shape_pt_sequence: 366,
    shape_dist_traveled: 18.7739,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076204,
    shape_pt_lon: -89.926709,
    shape_pt_sequence: 367,
    shape_dist_traveled: 18.8179,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076155,
    shape_pt_lon: -89.926593,
    shape_pt_sequence: 368,
    shape_dist_traveled: 18.829,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07613,
    shape_pt_lon: -89.925902,
    shape_pt_sequence: 369,
    shape_dist_traveled: 18.8921,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076108,
    shape_pt_lon: -89.925237,
    shape_pt_sequence: 370,
    shape_dist_traveled: 18.9522,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076096,
    shape_pt_lon: -89.924887,
    shape_pt_sequence: 371,
    shape_dist_traveled: 18.9832,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076107,
    shape_pt_lon: -89.924483,
    shape_pt_sequence: 372,
    shape_dist_traveled: 19.0202,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076143,
    shape_pt_lon: -89.923893,
    shape_pt_sequence: 373,
    shape_dist_traveled: 19.0735,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076151,
    shape_pt_lon: -89.923483,
    shape_pt_sequence: 374,
    shape_dist_traveled: 19.1105,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076144,
    shape_pt_lon: -89.923266,
    shape_pt_sequence: 375,
    shape_dist_traveled: 19.1305,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076129,
    shape_pt_lon: -89.923082,
    shape_pt_sequence: 376,
    shape_dist_traveled: 19.1476,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076099,
    shape_pt_lon: -89.922871,
    shape_pt_sequence: 377,
    shape_dist_traveled: 19.1669,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076053,
    shape_pt_lon: -89.922669,
    shape_pt_sequence: 378,
    shape_dist_traveled: 19.1855,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.076003,
    shape_pt_lon: -89.922444,
    shape_pt_sequence: 379,
    shape_dist_traveled: 19.2064,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07586,
    shape_pt_lon: -89.921972,
    shape_pt_sequence: 380,
    shape_dist_traveled: 19.2523,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075699,
    shape_pt_lon: -89.921578,
    shape_pt_sequence: 381,
    shape_dist_traveled: 19.2925,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075543,
    shape_pt_lon: -89.921267,
    shape_pt_sequence: 382,
    shape_dist_traveled: 19.3253,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.920621,
    shape_pt_sequence: 383,
    shape_dist_traveled: 19.3981,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07499,
    shape_pt_lon: -89.920392,
    shape_pt_sequence: 384,
    shape_dist_traveled: 19.4258,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.074653,
    shape_pt_lon: -89.919998,
    shape_pt_sequence: 385,
    shape_dist_traveled: 19.4781,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073911,
    shape_pt_lon: -89.919244,
    shape_pt_sequence: 386,
    shape_dist_traveled: 19.5846,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072987,
    shape_pt_lon: -89.918272,
    shape_pt_sequence: 387,
    shape_dist_traveled: 19.7209,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072721,
    shape_pt_lon: -89.917926,
    shape_pt_sequence: 388,
    shape_dist_traveled: 19.7633,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072662,
    shape_pt_lon: -89.917833,
    shape_pt_sequence: 389,
    shape_dist_traveled: 19.7747,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072533,
    shape_pt_lon: -89.917632,
    shape_pt_sequence: 390,
    shape_dist_traveled: 19.7975,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072401,
    shape_pt_lon: -89.917398,
    shape_pt_sequence: 391,
    shape_dist_traveled: 19.8233,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072281,
    shape_pt_lon: -89.917162,
    shape_pt_sequence: 392,
    shape_dist_traveled: 19.8494,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072148,
    shape_pt_lon: -89.916839,
    shape_pt_sequence: 393,
    shape_dist_traveled: 19.8816,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072003,
    shape_pt_lon: -89.9164,
    shape_pt_sequence: 394,
    shape_dist_traveled: 19.9251,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071908,
    shape_pt_lon: -89.916024,
    shape_pt_sequence: 395,
    shape_dist_traveled: 19.9605,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071828,
    shape_pt_lon: -89.915611,
    shape_pt_sequence: 396,
    shape_dist_traveled: 19.9986,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071794,
    shape_pt_lon: -89.915329,
    shape_pt_sequence: 397,
    shape_dist_traveled: 20.0249,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071766,
    shape_pt_lon: -89.914899,
    shape_pt_sequence: 398,
    shape_dist_traveled: 20.063,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071731,
    shape_pt_lon: -89.913309,
    shape_pt_sequence: 399,
    shape_dist_traveled: 20.2071,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071731,
    shape_pt_lon: -89.912849,
    shape_pt_sequence: 400,
    shape_dist_traveled: 20.2491,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071754,
    shape_pt_lon: -89.912449,
    shape_pt_sequence: 401,
    shape_dist_traveled: 20.2851,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071825,
    shape_pt_lon: -89.91197,
    shape_pt_sequence: 402,
    shape_dist_traveled: 20.3289,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07192,
    shape_pt_lon: -89.911537,
    shape_pt_sequence: 403,
    shape_dist_traveled: 20.3694,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07208,
    shape_pt_lon: -89.911031,
    shape_pt_sequence: 404,
    shape_dist_traveled: 20.4188,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07221,
    shape_pt_lon: -89.910697,
    shape_pt_sequence: 405,
    shape_dist_traveled: 20.4519,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072832,
    shape_pt_lon: -89.909337,
    shape_pt_sequence: 406,
    shape_dist_traveled: 20.5934,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07295,
    shape_pt_lon: -89.909093,
    shape_pt_sequence: 407,
    shape_dist_traveled: 20.619,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073071,
    shape_pt_lon: -89.908813,
    shape_pt_sequence: 408,
    shape_dist_traveled: 20.6485,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073454,
    shape_pt_lon: -89.907938,
    shape_pt_sequence: 409,
    shape_dist_traveled: 20.7385,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.07354,
    shape_pt_lon: -89.907681,
    shape_pt_sequence: 410,
    shape_dist_traveled: 20.7635,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073647,
    shape_pt_lon: -89.907316,
    shape_pt_sequence: 411,
    shape_dist_traveled: 20.7986,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073718,
    shape_pt_lon: -89.906985,
    shape_pt_sequence: 412,
    shape_dist_traveled: 20.8297,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073867,
    shape_pt_lon: -89.906127,
    shape_pt_sequence: 413,
    shape_dist_traveled: 20.9086,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.073909,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 414,
    shape_dist_traveled: 20.9329,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072612,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 415,
    shape_dist_traveled: 21.081,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.905498,
    shape_pt_sequence: 416,
    shape_dist_traveled: 21.1081,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.072123,
    shape_pt_lon: -89.905469,
    shape_pt_sequence: 417,
    shape_dist_traveled: 21.1353,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.071761,
    shape_pt_lon: -89.905462,
    shape_pt_sequence: 418,
    shape_dist_traveled: 21.1763,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.070973,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 419,
    shape_dist_traveled: 21.2643,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.070547,
    shape_pt_lon: -89.905501,
    shape_pt_sequence: 420,
    shape_dist_traveled: 21.3123,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06981,
    shape_pt_lon: -89.905526,
    shape_pt_sequence: 421,
    shape_dist_traveled: 21.3944,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.069572,
    shape_pt_lon: -89.905538,
    shape_pt_sequence: 422,
    shape_dist_traveled: 21.4214,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.069462,
    shape_pt_lon: -89.905631,
    shape_pt_sequence: 423,
    shape_dist_traveled: 21.4358,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06932,
    shape_pt_lon: -89.905638,
    shape_pt_sequence: 424,
    shape_dist_traveled: 21.4519,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.066051,
    shape_pt_lon: -89.905741,
    shape_pt_sequence: 425,
    shape_dist_traveled: 21.8179,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065674,
    shape_pt_lon: -89.905739,
    shape_pt_sequence: 426,
    shape_dist_traveled: 21.8599,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905759,
    shape_pt_sequence: 427,
    shape_dist_traveled: 21.922,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.063261,
    shape_pt_lon: -89.905815,
    shape_pt_sequence: 428,
    shape_dist_traveled: 22.13,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.063039,
    shape_pt_lon: -89.905821,
    shape_pt_sequence: 429,
    shape_dist_traveled: 22.155,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.062819,
    shape_pt_lon: -89.905827,
    shape_pt_sequence: 430,
    shape_dist_traveled: 22.18,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.062388,
    shape_pt_lon: -89.90583,
    shape_pt_sequence: 431,
    shape_dist_traveled: 22.228,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.062312,
    shape_pt_lon: -89.905832,
    shape_pt_sequence: 432,
    shape_dist_traveled: 22.236,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.061822,
    shape_pt_lon: -89.905848,
    shape_pt_sequence: 433,
    shape_dist_traveled: 22.2911,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.061736,
    shape_pt_lon: -89.90585,
    shape_pt_sequence: 434,
    shape_dist_traveled: 22.3011,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06166,
    shape_pt_lon: -89.905852,
    shape_pt_sequence: 435,
    shape_dist_traveled: 22.3091,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.06154,
    shape_pt_lon: -89.905857,
    shape_pt_sequence: 436,
    shape_dist_traveled: 22.3231,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.060771,
    shape_pt_lon: -89.905882,
    shape_pt_sequence: 437,
    shape_dist_traveled: 22.4091,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.058755,
    shape_pt_lon: -89.905948,
    shape_pt_sequence: 438,
    shape_dist_traveled: 22.6342,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.058299,
    shape_pt_lon: -89.905963,
    shape_pt_sequence: 439,
    shape_dist_traveled: 22.6852,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.057829,
    shape_pt_lon: -89.90598,
    shape_pt_sequence: 440,
    shape_dist_traveled: 22.7382,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.057497,
    shape_pt_lon: -89.906026,
    shape_pt_sequence: 441,
    shape_dist_traveled: 22.7754,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.057289,
    shape_pt_lon: -89.906085,
    shape_pt_sequence: 442,
    shape_dist_traveled: 22.7992,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.056978,
    shape_pt_lon: -89.906209,
    shape_pt_sequence: 443,
    shape_dist_traveled: 22.8359,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.056743,
    shape_pt_lon: -89.906342,
    shape_pt_sequence: 444,
    shape_dist_traveled: 22.8645,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.056599,
    shape_pt_lon: -89.906442,
    shape_pt_sequence: 445,
    shape_dist_traveled: 22.8838,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.056349,
    shape_pt_lon: -89.906648,
    shape_pt_sequence: 446,
    shape_dist_traveled: 22.917,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.056018,
    shape_pt_lon: -89.906993,
    shape_pt_sequence: 447,
    shape_dist_traveled: 22.966,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.055752,
    shape_pt_lon: -89.907229,
    shape_pt_sequence: 448,
    shape_dist_traveled: 23.0018,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.055564,
    shape_pt_lon: -89.907361,
    shape_pt_sequence: 449,
    shape_dist_traveled: 23.0268,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.055366,
    shape_pt_lon: -89.907479,
    shape_pt_sequence: 450,
    shape_dist_traveled: 23.051,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.055047,
    shape_pt_lon: -89.90762,
    shape_pt_sequence: 451,
    shape_dist_traveled: 23.0883,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.054733,
    shape_pt_lon: -89.907711,
    shape_pt_sequence: 452,
    shape_dist_traveled: 23.1252,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.054409,
    shape_pt_lon: -89.907758,
    shape_pt_sequence: 453,
    shape_dist_traveled: 23.1614,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.053628,
    shape_pt_lon: -89.907774,
    shape_pt_sequence: 454,
    shape_dist_traveled: 23.2485,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.053343,
    shape_pt_lon: -89.907778,
    shape_pt_sequence: 455,
    shape_dist_traveled: 23.2805,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.053099,
    shape_pt_lon: -89.907786,
    shape_pt_sequence: 456,
    shape_dist_traveled: 23.3075,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.052868,
    shape_pt_lon: -89.907878,
    shape_pt_sequence: 457,
    shape_dist_traveled: 23.3347,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051852,
    shape_pt_lon: -89.908005,
    shape_pt_sequence: 458,
    shape_dist_traveled: 23.4492,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051229,
    shape_pt_lon: -89.908073,
    shape_pt_sequence: 459,
    shape_dist_traveled: 23.5195,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05122,
    shape_pt_lon: -89.908073,
    shape_pt_sequence: 460,
    shape_dist_traveled: 23.5205,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051128,
    shape_pt_lon: -89.908078,
    shape_pt_sequence: 461,
    shape_dist_traveled: 23.5305,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051123,
    shape_pt_lon: -89.907829,
    shape_pt_sequence: 462,
    shape_dist_traveled: 23.5535,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051122,
    shape_pt_lon: -89.907742,
    shape_pt_sequence: 463,
    shape_dist_traveled: 23.5615,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 464,
    shape_dist_traveled: 23.5835,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 465,
    shape_dist_traveled: 23.8136,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 466,
    shape_dist_traveled: 23.8766,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 467,
    shape_dist_traveled: 23.9516,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 468,
    shape_dist_traveled: 23.9936,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 469,
    shape_dist_traveled: 24.0077,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 470,
    shape_dist_traveled: 24.1038,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050943,
    shape_pt_lon: -89.900731,
    shape_pt_sequence: 471,
    shape_dist_traveled: 24.1959,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050903,
    shape_pt_lon: -89.899641,
    shape_pt_sequence: 472,
    shape_dist_traveled: 24.294,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050889,
    shape_pt_lon: -89.898999,
    shape_pt_sequence: 473,
    shape_dist_traveled: 24.353,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05085,
    shape_pt_lon: -89.897408,
    shape_pt_sequence: 474,
    shape_dist_traveled: 24.496,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050672,
    shape_pt_lon: -89.891706,
    shape_pt_sequence: 475,
    shape_dist_traveled: 25.0124,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.889403,
    shape_pt_sequence: 476,
    shape_dist_traveled: 25.2215,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05058,
    shape_pt_lon: -89.888583,
    shape_pt_sequence: 477,
    shape_dist_traveled: 25.2956,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050576,
    shape_pt_lon: -89.888333,
    shape_pt_sequence: 478,
    shape_dist_traveled: 25.3176,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -89.886713,
    shape_pt_sequence: 479,
    shape_dist_traveled: 25.4646,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 480,
    shape_dist_traveled: 25.5096,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050538,
    shape_pt_lon: -89.886157,
    shape_pt_sequence: 481,
    shape_dist_traveled: 25.5146,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050526,
    shape_pt_lon: -89.885636,
    shape_pt_sequence: 482,
    shape_dist_traveled: 25.5617,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050508,
    shape_pt_lon: -89.884838,
    shape_pt_sequence: 483,
    shape_dist_traveled: 25.6347,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050509,
    shape_pt_lon: -89.884407,
    shape_pt_sequence: 484,
    shape_dist_traveled: 25.6737,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050493,
    shape_pt_lon: -89.883511,
    shape_pt_sequence: 485,
    shape_dist_traveled: 25.7547,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.882708,
    shape_pt_sequence: 486,
    shape_dist_traveled: 25.8267,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050472,
    shape_pt_lon: -89.882373,
    shape_pt_sequence: 487,
    shape_dist_traveled: 25.8577,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050471,
    shape_pt_lon: -89.882305,
    shape_pt_sequence: 488,
    shape_dist_traveled: 25.8637,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050441,
    shape_pt_lon: -89.880866,
    shape_pt_sequence: 489,
    shape_dist_traveled: 25.9938,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050439,
    shape_pt_lon: -89.880782,
    shape_pt_sequence: 490,
    shape_dist_traveled: 26.0018,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050379,
    shape_pt_lon: -89.878393,
    shape_pt_sequence: 491,
    shape_dist_traveled: 26.2179,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050365,
    shape_pt_lon: -89.877847,
    shape_pt_sequence: 492,
    shape_dist_traveled: 26.2669,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050322,
    shape_pt_lon: -89.875876,
    shape_pt_sequence: 493,
    shape_dist_traveled: 26.445,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050302,
    shape_pt_lon: -89.874998,
    shape_pt_sequence: 494,
    shape_dist_traveled: 26.525,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050298,
    shape_pt_lon: -89.874722,
    shape_pt_sequence: 495,
    shape_dist_traveled: 26.55,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050257,
    shape_pt_lon: -89.873187,
    shape_pt_sequence: 496,
    shape_dist_traveled: 26.6891,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050187,
    shape_pt_lon: -89.870761,
    shape_pt_sequence: 497,
    shape_dist_traveled: 26.9082,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05018,
    shape_pt_lon: -89.870577,
    shape_pt_sequence: 498,
    shape_dist_traveled: 26.9253,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050161,
    shape_pt_lon: -89.870017,
    shape_pt_sequence: 499,
    shape_dist_traveled: 26.9753,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050149,
    shape_pt_lon: -89.869652,
    shape_pt_sequence: 500,
    shape_dist_traveled: 27.0083,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050136,
    shape_pt_lon: -89.869212,
    shape_pt_sequence: 501,
    shape_dist_traveled: 27.0483,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05012,
    shape_pt_lon: -89.86874,
    shape_pt_sequence: 502,
    shape_dist_traveled: 27.0914,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05007,
    shape_pt_lon: -89.867199,
    shape_pt_sequence: 503,
    shape_dist_traveled: 27.2305,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050052,
    shape_pt_lon: -89.866387,
    shape_pt_sequence: 504,
    shape_dist_traveled: 27.3045,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049987,
    shape_pt_lon: -89.864778,
    shape_pt_sequence: 505,
    shape_dist_traveled: 27.4497,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049946,
    shape_pt_lon: -89.864058,
    shape_pt_sequence: 506,
    shape_dist_traveled: 27.5149,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049917,
    shape_pt_lon: -89.863214,
    shape_pt_sequence: 507,
    shape_dist_traveled: 27.5919,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049898,
    shape_pt_lon: -89.862159,
    shape_pt_sequence: 508,
    shape_dist_traveled: 27.6869,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049821,
    shape_pt_lon: -89.862016,
    shape_pt_sequence: 509,
    shape_dist_traveled: 27.7022,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04979,
    shape_pt_lon: -89.860852,
    shape_pt_sequence: 510,
    shape_dist_traveled: 27.8082,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049787,
    shape_pt_lon: -89.860712,
    shape_pt_sequence: 511,
    shape_dist_traveled: 27.8203,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04974,
    shape_pt_lon: -89.858787,
    shape_pt_sequence: 512,
    shape_dist_traveled: 27.9944,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049711,
    shape_pt_lon: -89.857728,
    shape_pt_sequence: 513,
    shape_dist_traveled: 28.0904,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857596,
    shape_pt_sequence: 514,
    shape_dist_traveled: 28.1048,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857456,
    shape_pt_sequence: 515,
    shape_dist_traveled: 28.1178,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049768,
    shape_pt_lon: -89.857169,
    shape_pt_sequence: 516,
    shape_dist_traveled: 28.1438,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04976,
    shape_pt_lon: -89.856952,
    shape_pt_sequence: 517,
    shape_dist_traveled: 28.1629,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049693,
    shape_pt_lon: -89.854776,
    shape_pt_sequence: 518,
    shape_dist_traveled: 28.36,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049661,
    shape_pt_lon: -89.853303,
    shape_pt_sequence: 519,
    shape_dist_traveled: 28.493,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049639,
    shape_pt_lon: -89.852306,
    shape_pt_sequence: 520,
    shape_dist_traveled: 28.5841,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049623,
    shape_pt_lon: -89.850912,
    shape_pt_sequence: 521,
    shape_dist_traveled: 28.7101,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049619,
    shape_pt_lon: -89.849989,
    shape_pt_sequence: 522,
    shape_dist_traveled: 28.7931,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049566,
    shape_pt_lon: -89.849872,
    shape_pt_sequence: 523,
    shape_dist_traveled: 28.8056,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04956,
    shape_pt_lon: -89.849331,
    shape_pt_sequence: 524,
    shape_dist_traveled: 28.8546,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049609,
    shape_pt_lon: -89.849221,
    shape_pt_sequence: 525,
    shape_dist_traveled: 28.8663,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049601,
    shape_pt_lon: -89.848321,
    shape_pt_sequence: 526,
    shape_dist_traveled: 28.9473,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049547,
    shape_pt_lon: -89.84591,
    shape_pt_sequence: 527,
    shape_dist_traveled: 29.1653,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049512,
    shape_pt_lon: -89.843906,
    shape_pt_sequence: 528,
    shape_dist_traveled: 29.3474,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 529,
    shape_dist_traveled: 29.3564,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 530,
    shape_dist_traveled: 29.3674,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 531,
    shape_dist_traveled: 29.5064,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 532,
    shape_dist_traveled: 29.6235,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 533,
    shape_dist_traveled: 29.7135,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 534,
    shape_dist_traveled: 29.8165,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 535,
    shape_dist_traveled: 29.9596,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 536,
    shape_dist_traveled: 30.3108,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 537,
    shape_dist_traveled: 30.4008,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 538,
    shape_dist_traveled: 30.5969,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 539,
    shape_dist_traveled: 30.6049,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 540,
    shape_dist_traveled: 30.6129,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 541,
    shape_dist_traveled: 30.7289,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 542,
    shape_dist_traveled: 30.8652,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 543,
    shape_dist_traveled: 30.9813,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 544,
    shape_dist_traveled: 31.0994,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 545,
    shape_dist_traveled: 31.2056,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048899,
    shape_pt_lon: -89.82295,
    shape_pt_sequence: 546,
    shape_dist_traveled: 31.2436,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048909,
    shape_pt_lon: -89.822418,
    shape_pt_sequence: 547,
    shape_dist_traveled: 31.2926,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048943,
    shape_pt_lon: -89.822009,
    shape_pt_sequence: 548,
    shape_dist_traveled: 31.3298,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049037,
    shape_pt_lon: -89.821308,
    shape_pt_sequence: 549,
    shape_dist_traveled: 31.3938,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049116,
    shape_pt_lon: -89.820899,
    shape_pt_sequence: 550,
    shape_dist_traveled: 31.4319,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049236,
    shape_pt_lon: -89.820414,
    shape_pt_sequence: 551,
    shape_dist_traveled: 31.4777,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049377,
    shape_pt_lon: -89.819938,
    shape_pt_sequence: 552,
    shape_dist_traveled: 31.5236,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04977,
    shape_pt_lon: -89.818834,
    shape_pt_sequence: 553,
    shape_dist_traveled: 31.6324,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050082,
    shape_pt_lon: -89.817972,
    shape_pt_sequence: 554,
    shape_dist_traveled: 31.7179,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050423,
    shape_pt_lon: -89.817018,
    shape_pt_sequence: 555,
    shape_dist_traveled: 31.8128,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050513,
    shape_pt_lon: -89.816697,
    shape_pt_sequence: 556,
    shape_dist_traveled: 31.8435,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050598,
    shape_pt_lon: -89.816314,
    shape_pt_sequence: 557,
    shape_dist_traveled: 31.8789,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050647,
    shape_pt_lon: -89.815982,
    shape_pt_sequence: 558,
    shape_dist_traveled: 31.9093,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050671,
    shape_pt_lon: -89.815662,
    shape_pt_sequence: 559,
    shape_dist_traveled: 31.9385,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050679,
    shape_pt_lon: -89.815568,
    shape_pt_sequence: 560,
    shape_dist_traveled: 31.9475,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050683,
    shape_pt_lon: -89.815217,
    shape_pt_sequence: 561,
    shape_dist_traveled: 31.9786,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050673,
    shape_pt_lon: -89.814931,
    shape_pt_sequence: 562,
    shape_dist_traveled: 32.0046,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050608,
    shape_pt_lon: -89.814331,
    shape_pt_sequence: 563,
    shape_dist_traveled: 32.0602,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.814296,
    shape_pt_sequence: 564,
    shape_dist_traveled: 32.0632,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05059,
    shape_pt_lon: -89.81421,
    shape_pt_sequence: 565,
    shape_dist_traveled: 32.0714,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.813383,
    shape_pt_sequence: 566,
    shape_dist_traveled: 32.1464,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050428,
    shape_pt_lon: -89.812981,
    shape_pt_sequence: 567,
    shape_dist_traveled: 32.1837,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050269,
    shape_pt_lon: -89.81182,
    shape_pt_sequence: 568,
    shape_dist_traveled: 32.2902,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050239,
    shape_pt_lon: -89.811597,
    shape_pt_sequence: 569,
    shape_dist_traveled: 32.3105,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.050142,
    shape_pt_lon: -89.810873,
    shape_pt_sequence: 570,
    shape_dist_traveled: 32.3774,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049947,
    shape_pt_lon: -89.809581,
    shape_pt_sequence: 571,
    shape_dist_traveled: 32.4953,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.809472,
    shape_pt_sequence: 572,
    shape_dist_traveled: 32.5055,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049912,
    shape_pt_lon: -89.809331,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.5186,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049889,
    shape_pt_lon: -89.809165,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.5339,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049819,
    shape_pt_lon: -89.808478,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.5963,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049741,
    shape_pt_lon: -89.807879,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.651,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -89.807056,
    shape_pt_sequence: 577,
    shape_dist_traveled: 32.7272,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.0495,
    shape_pt_lon: -89.806127,
    shape_pt_sequence: 578,
    shape_dist_traveled: 32.8123,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049248,
    shape_pt_lon: -89.803895,
    shape_pt_sequence: 579,
    shape_dist_traveled: 33.0161,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.049058,
    shape_pt_lon: -89.802452,
    shape_pt_sequence: 580,
    shape_dist_traveled: 33.1488,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048936,
    shape_pt_lon: -89.801578,
    shape_pt_sequence: 581,
    shape_dist_traveled: 33.2289,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04864,
    shape_pt_lon: -89.799391,
    shape_pt_sequence: 582,
    shape_dist_traveled: 33.4296,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048528,
    shape_pt_lon: -89.798569,
    shape_pt_sequence: 583,
    shape_dist_traveled: 33.5045,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.0484,
    shape_pt_lon: -89.797619,
    shape_pt_sequence: 584,
    shape_dist_traveled: 33.5917,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048371,
    shape_pt_lon: -89.797254,
    shape_pt_sequence: 585,
    shape_dist_traveled: 33.6249,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048337,
    shape_pt_lon: -89.796104,
    shape_pt_sequence: 586,
    shape_dist_traveled: 33.729,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.048326,
    shape_pt_lon: -89.795509,
    shape_pt_sequence: 587,
    shape_dist_traveled: 33.783,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.047416,
    shape_pt_lon: -89.795732,
    shape_pt_sequence: 588,
    shape_dist_traveled: 33.8869,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.047185,
    shape_pt_lon: -89.795788,
    shape_pt_sequence: 589,
    shape_dist_traveled: 33.9134,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.046642,
    shape_pt_lon: -89.795904,
    shape_pt_sequence: 590,
    shape_dist_traveled: 33.9752,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.046219,
    shape_pt_lon: -89.795951,
    shape_pt_sequence: 591,
    shape_dist_traveled: 34.0224,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.045958,
    shape_pt_lon: -89.795957,
    shape_pt_sequence: 592,
    shape_dist_traveled: 34.0514,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.044949,
    shape_pt_lon: -89.79595,
    shape_pt_sequence: 593,
    shape_dist_traveled: 34.1644,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.04374,
    shape_pt_lon: -89.795943,
    shape_pt_sequence: 594,
    shape_dist_traveled: 34.2994,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.043752,
    shape_pt_lon: -89.796376,
    shape_pt_sequence: 595,
    shape_dist_traveled: 34.3384,
  },
  {
    shape_id: 38230,
    shape_pt_lat: 35.043813,
    shape_pt_lon: -89.797497,
    shape_pt_sequence: 596,
    shape_dist_traveled: 34.4396,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.043813,
    shape_pt_lon: -89.797497,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.043865,
    shape_pt_lon: -89.798443,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0862,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.043879,
    shape_pt_lon: -89.798639,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1042,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.043937,
    shape_pt_lon: -89.799009,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.138,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.044,
    shape_pt_lon: -89.799311,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1668,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.044129,
    shape_pt_lon: -89.799745,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2083,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.044207,
    shape_pt_lon: -89.799984,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.232,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.044303,
    shape_pt_lon: -89.800224,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2557,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04461,
    shape_pt_lon: -89.801143,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3464,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.044774,
    shape_pt_lon: -89.801595,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3902,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045149,
    shape_pt_lon: -89.802487,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4815,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045303,
    shape_pt_lon: -89.802785,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.5134,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045461,
    shape_pt_lon: -89.803026,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5412,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045592,
    shape_pt_lon: -89.803173,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.561,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045829,
    shape_pt_lon: -89.803372,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5932,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.045995,
    shape_pt_lon: -89.803474,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6143,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.046274,
    shape_pt_lon: -89.803589,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6468,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.047079,
    shape_pt_lon: -89.803798,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7388,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.047814,
    shape_pt_lon: -89.803909,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8215,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048332,
    shape_pt_lon: -89.803977,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8799,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048527,
    shape_pt_lon: -89.803966,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9019,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048887,
    shape_pt_lon: -89.803931,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.942,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049248,
    shape_pt_lon: -89.803895,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9831,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.0495,
    shape_pt_lon: -89.806127,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1869,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -89.807056,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.2721,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049741,
    shape_pt_lon: -89.807879,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.3482,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049819,
    shape_pt_lon: -89.808478,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4029,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049889,
    shape_pt_lon: -89.809165,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4653,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049912,
    shape_pt_lon: -89.809331,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4806,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.809472,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4938,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049947,
    shape_pt_lon: -89.809581,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.504,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050142,
    shape_pt_lon: -89.810873,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6218,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050239,
    shape_pt_lon: -89.811597,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6888,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050269,
    shape_pt_lon: -89.81182,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.709,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050428,
    shape_pt_lon: -89.812981,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.8155,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.813383,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8528,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05059,
    shape_pt_lon: -89.81421,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9278,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.814296,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9361,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050608,
    shape_pt_lon: -89.814331,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9391,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050673,
    shape_pt_lon: -89.814931,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9946,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050683,
    shape_pt_lon: -89.815217,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0207,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050679,
    shape_pt_lon: -89.815568,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0517,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050671,
    shape_pt_lon: -89.815662,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050647,
    shape_pt_lon: -89.815982,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0899,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050598,
    shape_pt_lon: -89.816314,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1203,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050513,
    shape_pt_lon: -89.816697,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1557,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050423,
    shape_pt_lon: -89.817018,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1864,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050082,
    shape_pt_lon: -89.817972,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2814,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04977,
    shape_pt_lon: -89.818834,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3668,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049377,
    shape_pt_lon: -89.819938,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4756,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049236,
    shape_pt_lon: -89.820414,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5215,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049116,
    shape_pt_lon: -89.820899,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.5674,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049037,
    shape_pt_lon: -89.821308,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.6054,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048943,
    shape_pt_lon: -89.822009,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.6694,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048909,
    shape_pt_lon: -89.822418,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.7066,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048899,
    shape_pt_lon: -89.82295,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7556,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7937,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8998,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.0179,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.134,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.2703,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3863,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.3943,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4023,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5984,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6884,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.0396,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.1827,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.2857,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.3757,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.4928,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.6318,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.6428,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049512,
    shape_pt_lon: -89.843906,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.6518,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049547,
    shape_pt_lon: -89.84591,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.8339,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049601,
    shape_pt_lon: -89.848321,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.0519,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049609,
    shape_pt_lon: -89.849221,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.1329,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049662,
    shape_pt_lon: -89.849326,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.1446,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04967,
    shape_pt_lon: -89.849464,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.1566,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049672,
    shape_pt_lon: -89.849718,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.1796,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04967,
    shape_pt_lon: -89.849872,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.1937,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049619,
    shape_pt_lon: -89.849989,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.2058,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049623,
    shape_pt_lon: -89.850912,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.2888,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049639,
    shape_pt_lon: -89.852306,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.4148,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049661,
    shape_pt_lon: -89.853303,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.5058,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049693,
    shape_pt_lon: -89.854776,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.6388,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.04976,
    shape_pt_lon: -89.856952,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.836,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049768,
    shape_pt_lon: -89.857169,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.855,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857456,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.881,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857596,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.894,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049841,
    shape_pt_lon: -89.857723,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.9071,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049886,
    shape_pt_lon: -89.858781,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.0032,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.860793,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.1852,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049943,
    shape_pt_lon: -89.861329,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.2343,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049967,
    shape_pt_lon: -89.862,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.2943,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049898,
    shape_pt_lon: -89.862159,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.3113,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049917,
    shape_pt_lon: -89.863214,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.4063,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049946,
    shape_pt_lon: -89.864058,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.4834,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.049987,
    shape_pt_lon: -89.864778,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.5486,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050052,
    shape_pt_lon: -89.866387,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.6937,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05007,
    shape_pt_lon: -89.867199,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.7678,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05012,
    shape_pt_lon: -89.86874,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.9069,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050136,
    shape_pt_lon: -89.869212,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.9499,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050149,
    shape_pt_lon: -89.869652,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.9899,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050161,
    shape_pt_lon: -89.870017,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.0229,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05018,
    shape_pt_lon: -89.870577,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.073,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050187,
    shape_pt_lon: -89.870761,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.09,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050257,
    shape_pt_lon: -89.873187,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.3091,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050298,
    shape_pt_lon: -89.874722,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.4482,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050302,
    shape_pt_lon: -89.874998,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.4732,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050322,
    shape_pt_lon: -89.875876,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.5532,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050365,
    shape_pt_lon: -89.877847,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.7313,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050379,
    shape_pt_lon: -89.878393,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.7803,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050439,
    shape_pt_lon: -89.880782,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.9964,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050441,
    shape_pt_lon: -89.880866,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.0045,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050471,
    shape_pt_lon: -89.882305,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.1345,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050472,
    shape_pt_lon: -89.882373,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.1405,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.882708,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.1715,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050493,
    shape_pt_lon: -89.883511,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.2435,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050509,
    shape_pt_lon: -89.884407,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.3245,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050508,
    shape_pt_lon: -89.884838,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.3636,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050526,
    shape_pt_lon: -89.885636,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.4366,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050538,
    shape_pt_lon: -89.886157,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.4836,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.4886,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -89.886713,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.5336,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050576,
    shape_pt_lon: -89.888333,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.6806,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05058,
    shape_pt_lon: -89.888583,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.7027,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.889403,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.7767,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050672,
    shape_pt_lon: -89.891706,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.9859,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05085,
    shape_pt_lon: -89.897408,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.5022,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050889,
    shape_pt_lon: -89.898999,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.6453,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050903,
    shape_pt_lon: -89.899641,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.7043,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050943,
    shape_pt_lon: -89.900731,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.8023,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.8945,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.9906,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 136,
    shape_dist_traveled: 10.0046,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 137,
    shape_dist_traveled: 10.0466,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 138,
    shape_dist_traveled: 10.1216,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 139,
    shape_dist_traveled: 10.1846,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 140,
    shape_dist_traveled: 10.4147,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.051201,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 141,
    shape_dist_traveled: 10.4237,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05158,
    shape_pt_lon: -89.907523,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.4668,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.052771,
    shape_pt_lon: -89.907592,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.5999,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.053049,
    shape_pt_lon: -89.907615,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.631,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.053309,
    shape_pt_lon: -89.907674,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.6606,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.053626,
    shape_pt_lon: -89.907659,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.6967,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.054491,
    shape_pt_lon: -89.907623,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.7927,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.054821,
    shape_pt_lon: -89.907559,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.8301,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.055121,
    shape_pt_lon: -89.907459,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.8652,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.055407,
    shape_pt_lon: -89.907316,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.8998,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.055593,
    shape_pt_lon: -89.907194,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.9235,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.055859,
    shape_pt_lon: -89.906981,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.9595,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.056109,
    shape_pt_lon: -89.906727,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.9951,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.056359,
    shape_pt_lon: -89.906479,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.0314,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.056549,
    shape_pt_lon: -89.906326,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.0566,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.056679,
    shape_pt_lon: -89.906238,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.0727,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.056734,
    shape_pt_lon: -89.906203,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.0804,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05704,
    shape_pt_lon: -89.906047,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.1171,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.05735,
    shape_pt_lon: -89.905935,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.1535,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.057696,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.192,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.058293,
    shape_pt_lon: -89.905837,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.2591,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.058823,
    shape_pt_lon: -89.90582,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.3181,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.060769,
    shape_pt_lon: -89.905756,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.5361,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -89.90573,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.6222,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.06181,
    shape_pt_lon: -89.905721,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.6532,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.062319,
    shape_pt_lon: -89.905688,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.7103,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.062379,
    shape_pt_lon: -89.905684,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.7163,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.905688,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.7304,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.062848,
    shape_pt_lon: -89.905666,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.7694,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.063029,
    shape_pt_lon: -89.905661,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.7895,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905592,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.0235,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.06567,
    shape_pt_lon: -89.905574,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.0845,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.065953,
    shape_pt_lon: -89.905576,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.1165,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.069051,
    shape_pt_lon: -89.905485,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.4636,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.069318,
    shape_pt_lon: -89.905471,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.4936,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.069447,
    shape_pt_lon: -89.905472,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.5076,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.069477,
    shape_pt_lon: -89.905477,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.5106,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.069572,
    shape_pt_lon: -89.905538,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.5232,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.06981,
    shape_pt_lon: -89.905526,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.5502,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.070547,
    shape_pt_lon: -89.905501,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.6322,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.070973,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.6802,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.071761,
    shape_pt_lon: -89.905462,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.7683,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072123,
    shape_pt_lon: -89.905469,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.8093,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.905498,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.8364,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072612,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.8636,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073909,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.0117,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07398,
    shape_pt_lon: -89.905884,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.0199,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.074041,
    shape_pt_lon: -89.905899,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.027,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.074,
    shape_pt_lon: -89.906149,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.0505,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07398,
    shape_pt_lon: -89.906276,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.0617,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073907,
    shape_pt_lon: -89.90669,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.1005,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07373,
    shape_pt_lon: -89.907503,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.1762,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073659,
    shape_pt_lon: -89.907767,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.2015,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073626,
    shape_pt_lon: -89.907885,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.2132,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07353,
    shape_pt_lon: -89.908151,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.2392,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073351,
    shape_pt_lon: -89.90855,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.2809,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073202,
    shape_pt_lon: -89.908888,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.3149,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073084,
    shape_pt_lon: -89.90915,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.3427,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07248,
    shape_pt_lon: -89.91047,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.4793,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072337,
    shape_pt_lon: -89.910789,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.5124,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072191,
    shape_pt_lon: -89.911154,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.5495,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07215,
    shape_pt_lon: -89.911268,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.5603,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072037,
    shape_pt_lon: -89.911662,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.5986,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.071952,
    shape_pt_lon: -89.912071,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.6369,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07189,
    shape_pt_lon: -89.912511,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.6775,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.071873,
    shape_pt_lon: -89.912928,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.7145,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.071907,
    shape_pt_lon: -89.914847,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.8886,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.071937,
    shape_pt_lon: -89.915316,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.9307,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072,
    shape_pt_lon: -89.915772,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.9733,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072081,
    shape_pt_lon: -89.91614,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.0075,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072202,
    shape_pt_lon: -89.916551,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.047,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.916988,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.0909,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072566,
    shape_pt_lon: -89.917378,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.1322,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.072777,
    shape_pt_lon: -89.917732,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.1722,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073006,
    shape_pt_lon: -89.918048,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.2105,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073426,
    shape_pt_lon: -89.918541,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.2749,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.073952,
    shape_pt_lon: -89.919082,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.3516,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07399,
    shape_pt_lon: -89.919125,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.3573,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.074024,
    shape_pt_lon: -89.919162,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.3629,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.074697,
    shape_pt_lon: -89.919839,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.4596,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.074899,
    shape_pt_lon: -89.920059,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.4901,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075061,
    shape_pt_lon: -89.920256,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.5155,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075161,
    shape_pt_lon: -89.920393,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.5318,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075399,
    shape_pt_lon: -89.92076,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.5745,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075711,
    shape_pt_lon: -89.921294,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.6347,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075882,
    shape_pt_lon: -89.921644,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.671,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076097,
    shape_pt_lon: -89.922139,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.722,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076257,
    shape_pt_lon: -89.922618,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.7686,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076311,
    shape_pt_lon: -89.922827,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.7886,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076359,
    shape_pt_lon: -89.923077,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.8121,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.0764,
    shape_pt_lon: -89.923435,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.8444,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.923889,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.8855,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076439,
    shape_pt_lon: -89.924393,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.9316,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076431,
    shape_pt_lon: -89.924657,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.9556,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076417,
    shape_pt_lon: -89.924811,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.9697,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07635,
    shape_pt_lon: -89.925249,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.0093,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076275,
    shape_pt_lon: -89.925789,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.0592,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07625,
    shape_pt_lon: -89.926138,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.0913,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07625,
    shape_pt_lon: -89.926603,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.1333,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076204,
    shape_pt_lon: -89.926709,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.1436,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076211,
    shape_pt_lon: -89.927188,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.1876,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076296,
    shape_pt_lon: -89.928906,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.3429,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07639,
    shape_pt_lon: -89.930939,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.5271,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.5742,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.5802,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.5873,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.6024,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.6605,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.7356,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.7446,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.7504,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.759,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.772,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.833,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.8389,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.8519,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.8659,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.873,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.8824,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.9335,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.9367,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.943,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.949,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.977,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.99,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.9985,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.0044,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.0134,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.0885,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.1466,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.1617,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.1688,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.1748,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.1808,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.076489,
    shape_pt_lon: -89.931516,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.1898,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.2599,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.296,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.3342,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.3412,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.4083,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.5443,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.5533,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.5653,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.5703,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.5833,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.5933,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.6104,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.6324,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.7464,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.8084,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.8194,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.8504,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.8724,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.9394,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.9874,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.0624,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.1584,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.2965,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.3115,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.3235,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.3375,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086777,
    shape_pt_lon: -89.932047,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.4166,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086787,
    shape_pt_lon: -89.932617,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.4676,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086799,
    shape_pt_lon: -89.933066,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.5086,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086813,
    shape_pt_lon: -89.934053,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.5976,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086837,
    shape_pt_lon: -89.935222,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.7037,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086848,
    shape_pt_lon: -89.936393,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.8097,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086891,
    shape_pt_lon: -89.938839,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.0307,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086873,
    shape_pt_lon: -89.939012,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.0468,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086845,
    shape_pt_lon: -89.939118,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.0563,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.086881,
    shape_pt_lon: -89.939273,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.0709,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.08692,
    shape_pt_lon: -89.939332,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.0781,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.087014,
    shape_pt_lon: -89.939404,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.0906,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.087114,
    shape_pt_lon: -89.9394,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.1016,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.087399,
    shape_pt_lon: -89.939386,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.1337,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.087993,
    shape_pt_lon: -89.939364,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.1997,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.088558,
    shape_pt_lon: -89.939344,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.2637,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.089093,
    shape_pt_lon: -89.939328,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.3238,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.090289,
    shape_pt_lon: -89.939287,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.4568,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.090337,
    shape_pt_lon: -89.939284,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.4628,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.090408,
    shape_pt_lon: -89.939282,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.4708,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.090559,
    shape_pt_lon: -89.939277,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.4878,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.091552,
    shape_pt_lon: -89.93924,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.5989,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.092793,
    shape_pt_lon: -89.939201,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.7379,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09298,
    shape_pt_lon: -89.93921,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.7589,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093135,
    shape_pt_lon: -89.939257,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.7766,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093199,
    shape_pt_lon: -89.939317,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.7852,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093205,
    shape_pt_lon: -89.939622,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.8132,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093256,
    shape_pt_lon: -89.941807,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.0103,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093297,
    shape_pt_lon: -89.943403,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.1554,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093316,
    shape_pt_lon: -89.944157,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.2234,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093336,
    shape_pt_lon: -89.944909,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.2915,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093359,
    shape_pt_lon: -89.945709,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.3635,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093369,
    shape_pt_lon: -89.946441,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.4295,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09341,
    shape_pt_lon: -89.948087,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.5786,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093428,
    shape_pt_lon: -89.948812,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.6446,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093461,
    shape_pt_lon: -89.948913,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.6544,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093529,
    shape_pt_lon: -89.949072,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.6706,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09363,
    shape_pt_lon: -89.949219,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.6876,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09381,
    shape_pt_lon: -89.949358,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.7114,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.093951,
    shape_pt_lon: -89.949353,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.7274,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094261,
    shape_pt_lon: -89.949608,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.7693,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094379,
    shape_pt_lon: -89.94974,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.787,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.0945,
    shape_pt_lon: -89.949946,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.8092,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094567,
    shape_pt_lon: -89.950098,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.8253,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094647,
    shape_pt_lon: -89.950393,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.8538,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094649,
    shape_pt_lon: -89.950597,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.8718,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094656,
    shape_pt_lon: -89.950749,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.8858,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094662,
    shape_pt_lon: -89.951118,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.9188,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094682,
    shape_pt_lon: -89.951852,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.9859,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094687,
    shape_pt_lon: -89.951978,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.9969,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09471,
    shape_pt_lon: -89.952893,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.0799,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094715,
    shape_pt_lon: -89.953071,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.096,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094719,
    shape_pt_lon: -89.953276,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.114,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094732,
    shape_pt_lon: -89.954159,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.194,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094759,
    shape_pt_lon: -89.955307,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.298,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094781,
    shape_pt_lon: -89.95647,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.4031,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094792,
    shape_pt_lon: -89.957043,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.4551,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094828,
    shape_pt_lon: -89.958819,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.6161,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094874,
    shape_pt_lon: -89.961241,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.8342,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09492,
    shape_pt_lon: -89.963541,
    shape_pt_sequence: 361,
    shape_dist_traveled: 21.0422,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094941,
    shape_pt_lon: -89.964718,
    shape_pt_sequence: 362,
    shape_dist_traveled: 21.1493,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094951,
    shape_pt_lon: -89.965308,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.2023,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094962,
    shape_pt_lon: -89.96585,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.2513,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094968,
    shape_pt_lon: -89.966042,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.2693,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094972,
    shape_pt_lon: -89.96627,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.2893,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094982,
    shape_pt_lon: -89.96664,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.3233,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.094994,
    shape_pt_lon: -89.96727,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.3804,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095002,
    shape_pt_lon: -89.96763,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.4124,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095017,
    shape_pt_lon: -89.968201,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.4644,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095038,
    shape_pt_lon: -89.969352,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.5684,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09504,
    shape_pt_lon: -89.969676,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.5974,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09504,
    shape_pt_lon: -89.969758,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.6054,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095031,
    shape_pt_lon: -89.970053,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.6314,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095028,
    shape_pt_lon: -89.970124,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.6385,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095147,
    shape_pt_lon: -89.97023,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.6551,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.095461,
    shape_pt_lon: -89.970517,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.6987,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.096076,
    shape_pt_lon: -89.971066,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.7832,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.096506,
    shape_pt_lon: -89.971448,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.8428,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.097178,
    shape_pt_lon: -89.972056,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.9364,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.097492,
    shape_pt_lon: -89.972371,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.9812,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.972644,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.0173,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.09781,
    shape_pt_lon: -89.972738,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.0293,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.098014,
    shape_pt_lon: -89.973008,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.0633,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.098287,
    shape_pt_lon: -89.973401,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.1101,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.098556,
    shape_pt_lon: -89.973858,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.1617,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.098876,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.2269,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.099079,
    shape_pt_lon: -89.974863,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.2687,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.099477,
    shape_pt_lon: -89.975625,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.3511,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.099554,
    shape_pt_lon: -89.975775,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.3672,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.099702,
    shape_pt_lon: -89.976056,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.3975,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.977596,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.5634,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -89.978038,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.6111,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.100876,
    shape_pt_lon: -89.978307,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.6399,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.101844,
    shape_pt_lon: -89.980151,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.8388,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.102028,
    shape_pt_lon: -89.980468,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.8738,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.102272,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.9212,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.102607,
    shape_pt_lon: -89.981424,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.9818,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.1036,
    shape_pt_lon: -89.982976,
    shape_pt_sequence: 399,
    shape_dist_traveled: 23.1605,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.104063,
    shape_pt_lon: -89.983699,
    shape_pt_sequence: 400,
    shape_dist_traveled: 23.2445,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.104387,
    shape_pt_lon: -89.984177,
    shape_pt_sequence: 401,
    shape_dist_traveled: 23.3006,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.10462,
    shape_pt_lon: -89.984552,
    shape_pt_sequence: 402,
    shape_dist_traveled: 23.3434,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.104927,
    shape_pt_lon: -89.985038,
    shape_pt_sequence: 403,
    shape_dist_traveled: 23.3996,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105062,
    shape_pt_lon: -89.98525,
    shape_pt_sequence: 404,
    shape_dist_traveled: 23.4238,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105203,
    shape_pt_lon: -89.985466,
    shape_pt_sequence: 405,
    shape_dist_traveled: 23.4488,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 406,
    shape_dist_traveled: 23.4715,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -89.985738,
    shape_pt_sequence: 407,
    shape_dist_traveled: 23.4807,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105579,
    shape_pt_lon: -89.986008,
    shape_pt_sequence: 408,
    shape_dist_traveled: 23.5134,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105683,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 409,
    shape_dist_traveled: 23.5311,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.105949,
    shape_pt_lon: -89.986453,
    shape_pt_sequence: 410,
    shape_dist_traveled: 23.5714,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.10627,
    shape_pt_lon: -89.986761,
    shape_pt_sequence: 411,
    shape_dist_traveled: 23.617,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 412,
    shape_dist_traveled: 23.6501,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.106839,
    shape_pt_lon: -89.987218,
    shape_pt_sequence: 413,
    shape_dist_traveled: 23.6922,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -89.987492,
    shape_pt_sequence: 414,
    shape_dist_traveled: 23.7446,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.107475,
    shape_pt_lon: -89.987644,
    shape_pt_sequence: 415,
    shape_dist_traveled: 23.7741,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.107749,
    shape_pt_lon: -89.987853,
    shape_pt_sequence: 416,
    shape_dist_traveled: 23.8096,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.107991,
    shape_pt_lon: -89.988071,
    shape_pt_sequence: 417,
    shape_dist_traveled: 23.8427,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108348,
    shape_pt_lon: -89.988462,
    shape_pt_sequence: 418,
    shape_dist_traveled: 23.8965,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108396,
    shape_pt_lon: -89.988522,
    shape_pt_sequence: 419,
    shape_dist_traveled: 23.9035,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 420,
    shape_dist_traveled: 23.9299,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 421,
    shape_dist_traveled: 23.949,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 422,
    shape_dist_traveled: 23.9797,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 423,
    shape_dist_traveled: 23.9931,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 424,
    shape_dist_traveled: 24.0609,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 425,
    shape_dist_traveled: 24.0934,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 426,
    shape_dist_traveled: 24.2433,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 427,
    shape_dist_traveled: 24.2853,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 428,
    shape_dist_traveled: 24.3017,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 429,
    shape_dist_traveled: 24.3159,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11106,
    shape_pt_lon: -89.992569,
    shape_pt_sequence: 430,
    shape_dist_traveled: 24.3765,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.111537,
    shape_pt_lon: -89.993302,
    shape_pt_sequence: 431,
    shape_dist_traveled: 24.4626,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.112193,
    shape_pt_lon: -89.994306,
    shape_pt_sequence: 432,
    shape_dist_traveled: 24.5785,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.112561,
    shape_pt_lon: -89.994868,
    shape_pt_sequence: 433,
    shape_dist_traveled: 24.6439,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.112618,
    shape_pt_lon: -89.994952,
    shape_pt_sequence: 434,
    shape_dist_traveled: 24.6539,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.113011,
    shape_pt_lon: -89.995553,
    shape_pt_sequence: 435,
    shape_dist_traveled: 24.7236,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.113236,
    shape_pt_lon: -89.995889,
    shape_pt_sequence: 436,
    shape_dist_traveled: 24.764,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.113499,
    shape_pt_lon: -89.996299,
    shape_pt_sequence: 437,
    shape_dist_traveled: 24.811,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.113717,
    shape_pt_lon: -89.996628,
    shape_pt_sequence: 438,
    shape_dist_traveled: 24.8487,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.113746,
    shape_pt_lon: -89.996673,
    shape_pt_sequence: 439,
    shape_dist_traveled: 24.8543,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11536,
    shape_pt_lon: -89.999139,
    shape_pt_sequence: 440,
    shape_dist_traveled: 25.1409,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.115591,
    shape_pt_lon: -89.999479,
    shape_pt_sequence: 441,
    shape_dist_traveled: 25.1814,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.115773,
    shape_pt_lon: -89.999719,
    shape_pt_sequence: 442,
    shape_dist_traveled: 25.2111,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.116176,
    shape_pt_lon: -90.00028,
    shape_pt_sequence: 443,
    shape_dist_traveled: 25.2791,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.116358,
    shape_pt_lon: -90.000539,
    shape_pt_sequence: 444,
    shape_dist_traveled: 25.3103,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.116452,
    shape_pt_lon: -90.000693,
    shape_pt_sequence: 445,
    shape_dist_traveled: 25.3275,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.116827,
    shape_pt_lon: -90.001336,
    shape_pt_sequence: 446,
    shape_dist_traveled: 25.3991,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11686,
    shape_pt_lon: -90.001403,
    shape_pt_sequence: 447,
    shape_dist_traveled: 25.4063,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.117101,
    shape_pt_lon: -90.001834,
    shape_pt_sequence: 448,
    shape_dist_traveled: 25.4537,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11727,
    shape_pt_lon: -90.002104,
    shape_pt_sequence: 449,
    shape_dist_traveled: 25.4845,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.117619,
    shape_pt_lon: -90.002647,
    shape_pt_sequence: 450,
    shape_dist_traveled: 25.5478,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11782,
    shape_pt_lon: -90.00296,
    shape_pt_sequence: 451,
    shape_dist_traveled: 25.5834,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.118248,
    shape_pt_lon: -90.003629,
    shape_pt_sequence: 452,
    shape_dist_traveled: 25.6602,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.118393,
    shape_pt_lon: -90.003858,
    shape_pt_sequence: 453,
    shape_dist_traveled: 25.6866,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.119091,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 454,
    shape_dist_traveled: 25.8127,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.119197,
    shape_pt_lon: -90.005107,
    shape_pt_sequence: 455,
    shape_dist_traveled: 25.8311,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.119937,
    shape_pt_lon: -90.006268,
    shape_pt_sequence: 456,
    shape_dist_traveled: 25.9649,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 457,
    shape_dist_traveled: 25.9749,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.120106,
    shape_pt_lon: -90.006533,
    shape_pt_sequence: 458,
    shape_dist_traveled: 25.9956,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.120687,
    shape_pt_lon: -90.007442,
    shape_pt_sequence: 459,
    shape_dist_traveled: 26.1002,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.121217,
    shape_pt_lon: -90.008259,
    shape_pt_sequence: 460,
    shape_dist_traveled: 26.1948,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.121429,
    shape_pt_lon: -90.008595,
    shape_pt_sequence: 461,
    shape_dist_traveled: 26.2334,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.122363,
    shape_pt_lon: -90.010048,
    shape_pt_sequence: 462,
    shape_dist_traveled: 26.4013,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.122543,
    shape_pt_lon: -90.010346,
    shape_pt_sequence: 463,
    shape_dist_traveled: 26.4349,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12279,
    shape_pt_lon: -90.01076,
    shape_pt_sequence: 464,
    shape_dist_traveled: 26.4813,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.123301,
    shape_pt_lon: -90.011644,
    shape_pt_sequence: 465,
    shape_dist_traveled: 26.5796,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -90.011677,
    shape_pt_sequence: 466,
    shape_dist_traveled: 26.5832,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12348,
    shape_pt_lon: -90.011949,
    shape_pt_sequence: 467,
    shape_dist_traveled: 26.614,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.124171,
    shape_pt_lon: -90.013136,
    shape_pt_sequence: 468,
    shape_dist_traveled: 26.7458,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.124501,
    shape_pt_lon: -90.013705,
    shape_pt_sequence: 469,
    shape_dist_traveled: 26.8096,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.124924,
    shape_pt_lon: -90.014434,
    shape_pt_sequence: 470,
    shape_dist_traveled: 26.8898,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.125917,
    shape_pt_lon: -90.016143,
    shape_pt_sequence: 471,
    shape_dist_traveled: 27.0805,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.016751,
    shape_pt_sequence: 472,
    shape_dist_traveled: 27.1491,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 473,
    shape_dist_traveled: 27.1727,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 474,
    shape_dist_traveled: 27.1907,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 475,
    shape_dist_traveled: 27.2379,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 476,
    shape_dist_traveled: 27.3361,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 477,
    shape_dist_traveled: 27.4659,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 478,
    shape_dist_traveled: 27.5891,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 479,
    shape_dist_traveled: 27.6243,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 480,
    shape_dist_traveled: 27.6379,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 481,
    shape_dist_traveled: 27.6537,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 482,
    shape_dist_traveled: 27.7056,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 483,
    shape_dist_traveled: 27.7697,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 484,
    shape_dist_traveled: 27.8463,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 485,
    shape_dist_traveled: 27.8799,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 486,
    shape_dist_traveled: 27.9071,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 487,
    shape_dist_traveled: 27.9249,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 488,
    shape_dist_traveled: 27.9277,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 489,
    shape_dist_traveled: 27.9344,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 490,
    shape_dist_traveled: 27.955,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 491,
    shape_dist_traveled: 27.9595,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.0125,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.0331,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.1245,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 495,
    shape_dist_traveled: 28.1445,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 496,
    shape_dist_traveled: 28.1806,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.131949,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 497,
    shape_dist_traveled: 28.2196,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.132856,
    shape_pt_lon: -90.02603,
    shape_pt_sequence: 498,
    shape_dist_traveled: 28.3206,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.133521,
    shape_pt_lon: -90.026023,
    shape_pt_sequence: 499,
    shape_dist_traveled: 28.3956,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.133678,
    shape_pt_lon: -90.026046,
    shape_pt_sequence: 500,
    shape_dist_traveled: 28.4127,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.133861,
    shape_pt_lon: -90.026101,
    shape_pt_sequence: 501,
    shape_dist_traveled: 28.4343,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.133952,
    shape_pt_lon: -90.026141,
    shape_pt_sequence: 502,
    shape_dist_traveled: 28.4451,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134112,
    shape_pt_lon: -90.026229,
    shape_pt_sequence: 503,
    shape_dist_traveled: 28.4648,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134253,
    shape_pt_lon: -90.026337,
    shape_pt_sequence: 504,
    shape_dist_traveled: 28.4837,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134353,
    shape_pt_lon: -90.026436,
    shape_pt_sequence: 505,
    shape_dist_traveled: 28.4979,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134457,
    shape_pt_lon: -90.026558,
    shape_pt_sequence: 506,
    shape_dist_traveled: 28.5134,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.13454,
    shape_pt_lon: -90.026679,
    shape_pt_sequence: 507,
    shape_dist_traveled: 28.5283,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134682,
    shape_pt_lon: -90.026957,
    shape_pt_sequence: 508,
    shape_dist_traveled: 28.558,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134746,
    shape_pt_lon: -90.02715,
    shape_pt_sequence: 509,
    shape_dist_traveled: 28.5764,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134797,
    shape_pt_lon: -90.027406,
    shape_pt_sequence: 510,
    shape_dist_traveled: 28.5999,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134807,
    shape_pt_lon: -90.0275,
    shape_pt_sequence: 511,
    shape_dist_traveled: 28.609,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.134849,
    shape_pt_lon: -90.028301,
    shape_pt_sequence: 512,
    shape_dist_traveled: 28.6811,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.136129,
    shape_pt_lon: -90.028261,
    shape_pt_sequence: 513,
    shape_dist_traveled: 28.8242,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.13704,
    shape_pt_lon: -90.028232,
    shape_pt_sequence: 514,
    shape_dist_traveled: 28.9262,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 515,
    shape_dist_traveled: 29.0332,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.13807,
    shape_pt_lon: -90.02819,
    shape_pt_sequence: 516,
    shape_dist_traveled: 29.0423,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.138299,
    shape_pt_lon: -90.028198,
    shape_pt_sequence: 517,
    shape_dist_traveled: 29.0673,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.138453,
    shape_pt_lon: -90.028239,
    shape_pt_sequence: 518,
    shape_dist_traveled: 29.0848,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.138729,
    shape_pt_lon: -90.028321,
    shape_pt_sequence: 519,
    shape_dist_traveled: 29.1166,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.138878,
    shape_pt_lon: -90.028336,
    shape_pt_sequence: 520,
    shape_dist_traveled: 29.1337,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.139023,
    shape_pt_lon: -90.028323,
    shape_pt_sequence: 521,
    shape_dist_traveled: 29.1498,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 522,
    shape_dist_traveled: 29.2657,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.028117,
    shape_pt_sequence: 523,
    shape_dist_traveled: 29.289,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.140387,
    shape_pt_lon: -90.028065,
    shape_pt_sequence: 524,
    shape_dist_traveled: 29.3048,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.140801,
    shape_pt_lon: -90.027861,
    shape_pt_sequence: 525,
    shape_dist_traveled: 29.3542,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14099,
    shape_pt_lon: -90.027803,
    shape_pt_sequence: 526,
    shape_dist_traveled: 29.3761,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 527,
    shape_dist_traveled: 29.4182,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 528,
    shape_dist_traveled: 29.5519,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 529,
    shape_dist_traveled: 29.5679,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 530,
    shape_dist_traveled: 29.6654,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 531,
    shape_dist_traveled: 29.7107,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142848,
    shape_pt_lon: -90.029315,
    shape_pt_sequence: 532,
    shape_dist_traveled: 29.7227,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142854,
    shape_pt_lon: -90.029591,
    shape_pt_sequence: 533,
    shape_dist_traveled: 29.7477,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142836,
    shape_pt_lon: -90.0299,
    shape_pt_sequence: 534,
    shape_dist_traveled: 29.7758,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142791,
    shape_pt_lon: -90.030198,
    shape_pt_sequence: 535,
    shape_dist_traveled: 29.8033,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142765,
    shape_pt_lon: -90.030331,
    shape_pt_sequence: 536,
    shape_dist_traveled: 29.8156,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142716,
    shape_pt_lon: -90.03056,
    shape_pt_sequence: 537,
    shape_dist_traveled: 29.8375,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142669,
    shape_pt_lon: -90.03084,
    shape_pt_sequence: 538,
    shape_dist_traveled: 29.863,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142657,
    shape_pt_lon: -90.030926,
    shape_pt_sequence: 539,
    shape_dist_traveled: 29.871,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14263,
    shape_pt_lon: -90.031433,
    shape_pt_sequence: 540,
    shape_dist_traveled: 29.9171,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142641,
    shape_pt_lon: -90.032026,
    shape_pt_sequence: 541,
    shape_dist_traveled: 29.9701,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142626,
    shape_pt_lon: -90.032316,
    shape_pt_sequence: 542,
    shape_dist_traveled: 29.9962,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142567,
    shape_pt_lon: -90.032941,
    shape_pt_sequence: 543,
    shape_dist_traveled: 30.0535,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142558,
    shape_pt_lon: -90.033182,
    shape_pt_sequence: 544,
    shape_dist_traveled: 30.0756,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142565,
    shape_pt_lon: -90.033456,
    shape_pt_sequence: 545,
    shape_dist_traveled: 30.0996,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142608,
    shape_pt_lon: -90.033807,
    shape_pt_sequence: 546,
    shape_dist_traveled: 30.132,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142718,
    shape_pt_lon: -90.034973,
    shape_pt_sequence: 547,
    shape_dist_traveled: 30.2376,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142727,
    shape_pt_lon: -90.035042,
    shape_pt_sequence: 548,
    shape_dist_traveled: 30.2447,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14279,
    shape_pt_lon: -90.035138,
    shape_pt_sequence: 549,
    shape_dist_traveled: 30.2553,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142812,
    shape_pt_lon: -90.035276,
    shape_pt_sequence: 550,
    shape_dist_traveled: 30.2687,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.142847,
    shape_pt_lon: -90.035446,
    shape_pt_sequence: 551,
    shape_dist_traveled: 30.2842,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14337,
    shape_pt_lon: -90.037434,
    shape_pt_sequence: 552,
    shape_dist_traveled: 30.4733,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.143397,
    shape_pt_lon: -90.037531,
    shape_pt_sequence: 553,
    shape_dist_traveled: 30.4828,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14358,
    shape_pt_lon: -90.038067,
    shape_pt_sequence: 554,
    shape_dist_traveled: 30.5352,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.143731,
    shape_pt_lon: -90.03848,
    shape_pt_sequence: 555,
    shape_dist_traveled: 30.5759,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.143811,
    shape_pt_lon: -90.038789,
    shape_pt_sequence: 556,
    shape_dist_traveled: 30.6053,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.143869,
    shape_pt_lon: -90.03912,
    shape_pt_sequence: 557,
    shape_dist_traveled: 30.6359,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.143909,
    shape_pt_lon: -90.039498,
    shape_pt_sequence: 558,
    shape_dist_traveled: 30.6703,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14399,
    shape_pt_lon: -90.039968,
    shape_pt_sequence: 559,
    shape_dist_traveled: 30.7142,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.1441,
    shape_pt_lon: -90.040389,
    shape_pt_sequence: 560,
    shape_dist_traveled: 30.7541,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14433,
    shape_pt_lon: -90.041272,
    shape_pt_sequence: 561,
    shape_dist_traveled: 30.8382,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144399,
    shape_pt_lon: -90.041547,
    shape_pt_sequence: 562,
    shape_dist_traveled: 30.8632,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144414,
    shape_pt_lon: -90.041606,
    shape_pt_sequence: 563,
    shape_dist_traveled: 30.8695,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144471,
    shape_pt_lon: -90.041829,
    shape_pt_sequence: 564,
    shape_dist_traveled: 30.8904,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144501,
    shape_pt_lon: -90.041958,
    shape_pt_sequence: 565,
    shape_dist_traveled: 30.903,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144491,
    shape_pt_lon: -90.042019,
    shape_pt_sequence: 566,
    shape_dist_traveled: 30.9081,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144626,
    shape_pt_lon: -90.042423,
    shape_pt_sequence: 567,
    shape_dist_traveled: 30.9481,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.144974,
    shape_pt_lon: -90.043495,
    shape_pt_sequence: 568,
    shape_dist_traveled: 31.0517,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145039,
    shape_pt_lon: -90.043736,
    shape_pt_sequence: 569,
    shape_dist_traveled: 31.0748,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145062,
    shape_pt_lon: -90.043826,
    shape_pt_sequence: 570,
    shape_dist_traveled: 31.083,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145094,
    shape_pt_lon: -90.043941,
    shape_pt_sequence: 571,
    shape_dist_traveled: 31.0947,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145193,
    shape_pt_lon: -90.044298,
    shape_pt_sequence: 572,
    shape_dist_traveled: 31.1286,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145528,
    shape_pt_lon: -90.045569,
    shape_pt_sequence: 573,
    shape_dist_traveled: 31.2497,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145598,
    shape_pt_lon: -90.045926,
    shape_pt_sequence: 574,
    shape_dist_traveled: 31.2824,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145621,
    shape_pt_lon: -90.046134,
    shape_pt_sequence: 575,
    shape_dist_traveled: 31.3017,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14565,
    shape_pt_lon: -90.046271,
    shape_pt_sequence: 576,
    shape_dist_traveled: 31.314,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.145766,
    shape_pt_lon: -90.046733,
    shape_pt_sequence: 577,
    shape_dist_traveled: 31.358,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 578,
    shape_dist_traveled: 31.6227,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 579,
    shape_dist_traveled: 31.7394,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 580,
    shape_dist_traveled: 31.857,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 581,
    shape_dist_traveled: 31.9127,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 582,
    shape_dist_traveled: 31.9728,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 583,
    shape_dist_traveled: 32.0904,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 584,
    shape_dist_traveled: 32.2068,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 585,
    shape_dist_traveled: 32.32,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 586,
    shape_dist_traveled: 32.3299,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 587,
    shape_dist_traveled: 32.3524,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 588,
    shape_dist_traveled: 32.3641,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 589,
    shape_dist_traveled: 32.4113,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 590,
    shape_dist_traveled: 32.4382,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 591,
    shape_dist_traveled: 32.4537,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 592,
    shape_dist_traveled: 32.5581,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 593,
    shape_dist_traveled: 32.6697,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 594,
    shape_dist_traveled: 32.677,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 595,
    shape_dist_traveled: 32.6824,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 596,
    shape_dist_traveled: 32.6824,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 597,
    shape_dist_traveled: 32.739,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 598,
    shape_dist_traveled: 32.739,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 599,
    shape_dist_traveled: 32.7915,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 600,
    shape_dist_traveled: 32.8509,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 601,
    shape_dist_traveled: 32.9097,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 602,
    shape_dist_traveled: 32.9268,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 603,
    shape_dist_traveled: 32.9895,
  },
  {
    shape_id: 38231,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 604,
    shape_dist_traveled: 33.0404,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.043813,
    shape_pt_lon: -89.797497,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.043865,
    shape_pt_lon: -89.798443,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0862,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.043879,
    shape_pt_lon: -89.798639,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1042,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.043937,
    shape_pt_lon: -89.799009,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.138,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.044,
    shape_pt_lon: -89.799311,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1668,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.044129,
    shape_pt_lon: -89.799745,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2083,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.044207,
    shape_pt_lon: -89.799984,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.232,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.044303,
    shape_pt_lon: -89.800224,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2557,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04461,
    shape_pt_lon: -89.801143,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3464,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.044774,
    shape_pt_lon: -89.801595,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3902,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045149,
    shape_pt_lon: -89.802487,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4815,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045303,
    shape_pt_lon: -89.802785,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.5134,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045461,
    shape_pt_lon: -89.803026,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5412,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045592,
    shape_pt_lon: -89.803173,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.561,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045829,
    shape_pt_lon: -89.803372,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5932,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.045995,
    shape_pt_lon: -89.803474,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6143,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.046274,
    shape_pt_lon: -89.803589,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6468,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.047079,
    shape_pt_lon: -89.803798,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7388,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.047814,
    shape_pt_lon: -89.803909,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8215,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048332,
    shape_pt_lon: -89.803977,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8799,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048527,
    shape_pt_lon: -89.803966,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9019,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048887,
    shape_pt_lon: -89.803931,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.942,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049248,
    shape_pt_lon: -89.803895,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9831,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.0495,
    shape_pt_lon: -89.806127,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1869,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -89.807056,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.2721,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049741,
    shape_pt_lon: -89.807879,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.3482,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049819,
    shape_pt_lon: -89.808478,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4029,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049889,
    shape_pt_lon: -89.809165,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4653,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049912,
    shape_pt_lon: -89.809331,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4806,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.809472,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4938,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049947,
    shape_pt_lon: -89.809581,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.504,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050142,
    shape_pt_lon: -89.810873,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6218,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050239,
    shape_pt_lon: -89.811597,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6888,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050269,
    shape_pt_lon: -89.81182,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.709,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050428,
    shape_pt_lon: -89.812981,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.8155,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.813383,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8528,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05059,
    shape_pt_lon: -89.81421,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9278,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.814296,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9361,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050608,
    shape_pt_lon: -89.814331,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9391,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050673,
    shape_pt_lon: -89.814931,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9946,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050683,
    shape_pt_lon: -89.815217,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0207,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050679,
    shape_pt_lon: -89.815568,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0517,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050671,
    shape_pt_lon: -89.815662,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050647,
    shape_pt_lon: -89.815982,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0899,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050598,
    shape_pt_lon: -89.816314,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1203,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050513,
    shape_pt_lon: -89.816697,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.1557,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050423,
    shape_pt_lon: -89.817018,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.1864,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050082,
    shape_pt_lon: -89.817972,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.2814,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04977,
    shape_pt_lon: -89.818834,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.3668,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049377,
    shape_pt_lon: -89.819938,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.4756,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049236,
    shape_pt_lon: -89.820414,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5215,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049116,
    shape_pt_lon: -89.820899,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.5674,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049037,
    shape_pt_lon: -89.821308,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.6054,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048943,
    shape_pt_lon: -89.822009,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.6694,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048909,
    shape_pt_lon: -89.822418,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.7066,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048899,
    shape_pt_lon: -89.82295,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.7556,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048912,
    shape_pt_lon: -89.823378,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.7937,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.048961,
    shape_pt_lon: -89.824541,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.8998,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049009,
    shape_pt_lon: -89.825845,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.0179,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049067,
    shape_pt_lon: -89.827138,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.134,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049139,
    shape_pt_lon: -89.828636,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.2703,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049177,
    shape_pt_lon: -89.829914,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3863,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049179,
    shape_pt_lon: -89.83001,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.3943,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049181,
    shape_pt_lon: -89.830091,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4023,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049219,
    shape_pt_lon: -89.832257,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5984,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049239,
    shape_pt_lon: -89.833252,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6884,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049348,
    shape_pt_lon: -89.837141,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.0396,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049392,
    shape_pt_lon: -89.838721,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.1827,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049425,
    shape_pt_lon: -89.839856,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.2857,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049447,
    shape_pt_lon: -89.840849,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.3757,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049473,
    shape_pt_lon: -89.842141,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.4928,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049508,
    shape_pt_lon: -89.843678,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.6318,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04951,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.6428,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049512,
    shape_pt_lon: -89.843906,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.6518,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049547,
    shape_pt_lon: -89.84591,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.8339,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049601,
    shape_pt_lon: -89.848321,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.0519,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049609,
    shape_pt_lon: -89.849221,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.1329,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049662,
    shape_pt_lon: -89.849326,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.1446,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04967,
    shape_pt_lon: -89.849464,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.1566,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049672,
    shape_pt_lon: -89.849718,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.1796,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04967,
    shape_pt_lon: -89.849872,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.1937,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049619,
    shape_pt_lon: -89.849989,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.2058,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049623,
    shape_pt_lon: -89.850912,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.2888,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049639,
    shape_pt_lon: -89.852306,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.4148,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049661,
    shape_pt_lon: -89.853303,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.5058,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049693,
    shape_pt_lon: -89.854776,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.6388,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.04976,
    shape_pt_lon: -89.856952,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.836,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049768,
    shape_pt_lon: -89.857169,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.855,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857456,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.881,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049781,
    shape_pt_lon: -89.857596,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.894,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049841,
    shape_pt_lon: -89.857723,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.9071,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049886,
    shape_pt_lon: -89.858781,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.0032,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049931,
    shape_pt_lon: -89.860793,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.1852,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049943,
    shape_pt_lon: -89.861329,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.2343,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049967,
    shape_pt_lon: -89.862,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.2943,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049898,
    shape_pt_lon: -89.862159,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.3113,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049917,
    shape_pt_lon: -89.863214,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.4063,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049946,
    shape_pt_lon: -89.864058,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.4834,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.049987,
    shape_pt_lon: -89.864778,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.5486,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050052,
    shape_pt_lon: -89.866387,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.6937,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05007,
    shape_pt_lon: -89.867199,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.7678,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05012,
    shape_pt_lon: -89.86874,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.9069,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050136,
    shape_pt_lon: -89.869212,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.9499,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050149,
    shape_pt_lon: -89.869652,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.9899,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050161,
    shape_pt_lon: -89.870017,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.0229,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05018,
    shape_pt_lon: -89.870577,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.073,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050187,
    shape_pt_lon: -89.870761,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.09,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050257,
    shape_pt_lon: -89.873187,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.3091,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050298,
    shape_pt_lon: -89.874722,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.4482,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050302,
    shape_pt_lon: -89.874998,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.4732,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050322,
    shape_pt_lon: -89.875876,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.5532,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050365,
    shape_pt_lon: -89.877847,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.7313,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050379,
    shape_pt_lon: -89.878393,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.7803,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050439,
    shape_pt_lon: -89.880782,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.9964,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050441,
    shape_pt_lon: -89.880866,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.0045,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050471,
    shape_pt_lon: -89.882305,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.1345,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050472,
    shape_pt_lon: -89.882373,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.1405,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05048,
    shape_pt_lon: -89.882708,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.1715,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050493,
    shape_pt_lon: -89.883511,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.2435,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050509,
    shape_pt_lon: -89.884407,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.3245,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050508,
    shape_pt_lon: -89.884838,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.3636,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050526,
    shape_pt_lon: -89.885636,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.4366,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050538,
    shape_pt_lon: -89.886157,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.4836,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050539,
    shape_pt_lon: -89.88621,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.4886,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -89.886713,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.5336,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050576,
    shape_pt_lon: -89.888333,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.6806,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05058,
    shape_pt_lon: -89.888583,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.7027,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050602,
    shape_pt_lon: -89.889403,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.7767,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050672,
    shape_pt_lon: -89.891706,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.9859,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05085,
    shape_pt_lon: -89.897408,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.5022,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050889,
    shape_pt_lon: -89.898999,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.6453,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050903,
    shape_pt_lon: -89.899641,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.7043,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050943,
    shape_pt_lon: -89.900731,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.8023,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.050984,
    shape_pt_lon: -89.901748,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.8945,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.051022,
    shape_pt_lon: -89.902803,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.9906,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.051029,
    shape_pt_lon: -89.902963,
    shape_pt_sequence: 136,
    shape_dist_traveled: 10.0046,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05104,
    shape_pt_lon: -89.903426,
    shape_pt_sequence: 137,
    shape_dist_traveled: 10.0466,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05106,
    shape_pt_lon: -89.90425,
    shape_pt_sequence: 138,
    shape_dist_traveled: 10.1216,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.051072,
    shape_pt_lon: -89.904953,
    shape_pt_sequence: 139,
    shape_dist_traveled: 10.1846,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.051118,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 140,
    shape_dist_traveled: 10.4147,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.051201,
    shape_pt_lon: -89.907496,
    shape_pt_sequence: 141,
    shape_dist_traveled: 10.4237,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05158,
    shape_pt_lon: -89.907523,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.4668,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.052771,
    shape_pt_lon: -89.907592,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.5999,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.053049,
    shape_pt_lon: -89.907615,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.631,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.053309,
    shape_pt_lon: -89.907674,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.6606,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.053626,
    shape_pt_lon: -89.907659,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.6967,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.054491,
    shape_pt_lon: -89.907623,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.7927,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.054821,
    shape_pt_lon: -89.907559,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.8301,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.055121,
    shape_pt_lon: -89.907459,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.8652,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.055407,
    shape_pt_lon: -89.907316,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.8998,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.055593,
    shape_pt_lon: -89.907194,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.9235,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.055859,
    shape_pt_lon: -89.906981,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.9595,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.056109,
    shape_pt_lon: -89.906727,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.9951,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.056359,
    shape_pt_lon: -89.906479,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.0314,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.056549,
    shape_pt_lon: -89.906326,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.0566,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.056679,
    shape_pt_lon: -89.906238,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.0727,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.056734,
    shape_pt_lon: -89.906203,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.0804,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05704,
    shape_pt_lon: -89.906047,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.1171,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.05735,
    shape_pt_lon: -89.905935,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.1535,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.057696,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.192,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.058293,
    shape_pt_lon: -89.905837,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.2591,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.058823,
    shape_pt_lon: -89.90582,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.3181,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.060769,
    shape_pt_lon: -89.905756,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.5361,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.061533,
    shape_pt_lon: -89.90573,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.6222,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.06181,
    shape_pt_lon: -89.905721,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.6532,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.062319,
    shape_pt_lon: -89.905688,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.7103,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.062379,
    shape_pt_lon: -89.905684,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.7163,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.905688,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.7304,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.062848,
    shape_pt_lon: -89.905666,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.7694,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.063029,
    shape_pt_lon: -89.905661,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.7895,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065119,
    shape_pt_lon: -89.905592,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.0235,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.06567,
    shape_pt_lon: -89.905574,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.0845,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065953,
    shape_pt_lon: -89.905576,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.1165,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069051,
    shape_pt_lon: -89.905485,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.4636,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069318,
    shape_pt_lon: -89.905471,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.4936,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069447,
    shape_pt_lon: -89.905472,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.5076,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069477,
    shape_pt_lon: -89.905477,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.5106,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069572,
    shape_pt_lon: -89.905538,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.5232,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.06981,
    shape_pt_lon: -89.905526,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.5502,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.070547,
    shape_pt_lon: -89.905501,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.6322,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.070973,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.6802,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071761,
    shape_pt_lon: -89.905462,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.7683,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072123,
    shape_pt_lon: -89.905469,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.8093,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.905498,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.8364,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072612,
    shape_pt_lon: -89.905539,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.8636,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073909,
    shape_pt_lon: -89.905866,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.0117,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07398,
    shape_pt_lon: -89.905884,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.0199,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074041,
    shape_pt_lon: -89.905899,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.027,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074,
    shape_pt_lon: -89.906149,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.0505,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07398,
    shape_pt_lon: -89.906276,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.0617,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073907,
    shape_pt_lon: -89.90669,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.1005,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07373,
    shape_pt_lon: -89.907503,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.1762,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073659,
    shape_pt_lon: -89.907767,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.2015,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073626,
    shape_pt_lon: -89.907885,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.2132,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07353,
    shape_pt_lon: -89.908151,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.2392,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073351,
    shape_pt_lon: -89.90855,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.2809,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073202,
    shape_pt_lon: -89.908888,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.3149,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073084,
    shape_pt_lon: -89.90915,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.3427,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07248,
    shape_pt_lon: -89.91047,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.4793,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072337,
    shape_pt_lon: -89.910789,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.5124,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072191,
    shape_pt_lon: -89.911154,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.5495,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07215,
    shape_pt_lon: -89.911268,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.5603,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072037,
    shape_pt_lon: -89.911662,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.5986,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071952,
    shape_pt_lon: -89.912071,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.6369,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07189,
    shape_pt_lon: -89.912511,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.6775,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071873,
    shape_pt_lon: -89.912928,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.7145,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071907,
    shape_pt_lon: -89.914847,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.8886,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071937,
    shape_pt_lon: -89.915316,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.9307,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072,
    shape_pt_lon: -89.915772,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.9733,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072081,
    shape_pt_lon: -89.91614,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.0075,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072202,
    shape_pt_lon: -89.916551,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.047,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072368,
    shape_pt_lon: -89.916988,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.0909,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072566,
    shape_pt_lon: -89.917378,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.1322,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072777,
    shape_pt_lon: -89.917732,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.1722,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073006,
    shape_pt_lon: -89.918048,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.2105,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073426,
    shape_pt_lon: -89.918541,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.2749,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073952,
    shape_pt_lon: -89.919082,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.3516,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07399,
    shape_pt_lon: -89.919125,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.3573,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074024,
    shape_pt_lon: -89.919162,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.3629,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074697,
    shape_pt_lon: -89.919839,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.4596,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074899,
    shape_pt_lon: -89.920059,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.4901,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075061,
    shape_pt_lon: -89.920256,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.5155,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075161,
    shape_pt_lon: -89.920393,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.5318,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075399,
    shape_pt_lon: -89.92076,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.5745,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075711,
    shape_pt_lon: -89.921294,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.6347,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075882,
    shape_pt_lon: -89.921644,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.671,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076097,
    shape_pt_lon: -89.922139,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.722,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076257,
    shape_pt_lon: -89.922618,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.7686,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076311,
    shape_pt_lon: -89.922827,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.7886,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076359,
    shape_pt_lon: -89.923077,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.8121,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.0764,
    shape_pt_lon: -89.923435,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.8444,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.923889,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.8855,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076439,
    shape_pt_lon: -89.924393,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.9316,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076431,
    shape_pt_lon: -89.924657,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.9556,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076417,
    shape_pt_lon: -89.924811,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.9697,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07635,
    shape_pt_lon: -89.925249,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.0093,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076275,
    shape_pt_lon: -89.925789,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.0592,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07625,
    shape_pt_lon: -89.926138,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.0913,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07625,
    shape_pt_lon: -89.926603,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.1333,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076204,
    shape_pt_lon: -89.926709,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.1436,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076211,
    shape_pt_lon: -89.927188,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.1876,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076296,
    shape_pt_lon: -89.928906,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.3429,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07639,
    shape_pt_lon: -89.930939,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.5271,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.5742,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.5802,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.5873,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.6024,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.6605,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.7356,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.7446,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.7504,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.759,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.772,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.833,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.8389,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.8519,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.8659,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.873,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.8824,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.9335,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.9367,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.943,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.949,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.977,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.99,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.9985,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.0044,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.0134,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.0885,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.1466,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.1617,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.1688,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.3734,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.5137,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.584,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.584,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.6922,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.7705,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.8595,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.8845,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.9827,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.06832,
    shape_pt_lon: -89.932181,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.0757,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.06793,
    shape_pt_lon: -89.932202,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.1188,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067831,
    shape_pt_lon: -89.932209,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.1308,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067681,
    shape_pt_lon: -89.93222,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.1468,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067639,
    shape_pt_lon: -89.932222,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.1519,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067558,
    shape_pt_lon: -89.932289,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.1627,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067459,
    shape_pt_lon: -89.932293,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.1737,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067092,
    shape_pt_lon: -89.932318,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.2148,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.066759,
    shape_pt_lon: -89.932392,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.2534,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065033,
    shape_pt_lon: -89.932701,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.4483,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.064887,
    shape_pt_lon: -89.932719,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.4644,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.064916,
    shape_pt_lon: -89.933707,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.5535,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.064995,
    shape_pt_lon: -89.935988,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.7607,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065058,
    shape_pt_lon: -89.937512,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.8988,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065097,
    shape_pt_lon: -89.938544,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.9919,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065127,
    shape_pt_lon: -89.940139,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.136,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065121,
    shape_pt_lon: -89.940443,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.164,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065112,
    shape_pt_lon: -89.940521,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.1711,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065122,
    shape_pt_lon: -89.940709,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.1881,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065092,
    shape_pt_lon: -89.940911,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.2063,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065072,
    shape_pt_lon: -89.941027,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.2165,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065076,
    shape_pt_lon: -89.941153,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.2285,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065149,
    shape_pt_lon: -89.941408,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.2529,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065199,
    shape_pt_lon: -89.941572,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.269,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065277,
    shape_pt_lon: -89.941642,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.279,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.065689,
    shape_pt_lon: -89.942,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.3365,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.066412,
    shape_pt_lon: -89.942563,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.4313,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067351,
    shape_pt_lon: -89.943279,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.5543,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.067507,
    shape_pt_lon: -89.943397,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.5754,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.0682,
    shape_pt_lon: -89.943922,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.6661,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.068487,
    shape_pt_lon: -89.94414,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.7039,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.068761,
    shape_pt_lon: -89.94435,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.7402,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.068987,
    shape_pt_lon: -89.944521,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.7694,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.069648,
    shape_pt_lon: -89.94502,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.856,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.070217,
    shape_pt_lon: -89.945456,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.9315,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07151,
    shape_pt_lon: -89.946442,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.1007,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072568,
    shape_pt_lon: -89.947248,
    shape_pt_sequence: 318,
    shape_dist_traveled: 19.2404,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07271,
    shape_pt_lon: -89.947353,
    shape_pt_sequence: 319,
    shape_dist_traveled: 19.2584,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.072812,
    shape_pt_lon: -89.94743,
    shape_pt_sequence: 320,
    shape_dist_traveled: 19.2723,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073742,
    shape_pt_lon: -89.948159,
    shape_pt_sequence: 321,
    shape_dist_traveled: 19.3955,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.073991,
    shape_pt_lon: -89.948372,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.4293,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074181,
    shape_pt_lon: -89.948549,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.4557,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074452,
    shape_pt_lon: -89.9488,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.4935,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.074929,
    shape_pt_lon: -89.94927,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.5619,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.075098,
    shape_pt_lon: -89.949446,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.586,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07559,
    shape_pt_lon: -89.949994,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.6603,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07609,
    shape_pt_lon: -89.950581,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.7374,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076531,
    shape_pt_lon: -89.9511,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.806,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.076578,
    shape_pt_lon: -89.951153,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.8131,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07679,
    shape_pt_lon: -89.95139,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.845,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.07722,
    shape_pt_lon: -89.951911,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.9129,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.077269,
    shape_pt_lon: -89.951971,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.92,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.078091,
    shape_pt_lon: -89.952973,
    shape_pt_sequence: 334,
    shape_dist_traveled: 20.0494,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.078247,
    shape_pt_lon: -89.953157,
    shape_pt_sequence: 335,
    shape_dist_traveled: 20.0727,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.078746,
    shape_pt_lon: -89.953756,
    shape_pt_sequence: 336,
    shape_dist_traveled: 20.1512,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.079346,
    shape_pt_lon: -89.954475,
    shape_pt_sequence: 337,
    shape_dist_traveled: 20.2445,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.079393,
    shape_pt_lon: -89.954531,
    shape_pt_sequence: 338,
    shape_dist_traveled: 20.2516,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.079874,
    shape_pt_lon: -89.955103,
    shape_pt_sequence: 339,
    shape_dist_traveled: 20.3266,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.080076,
    shape_pt_lon: -89.955347,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.3584,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08011,
    shape_pt_lon: -89.955388,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.3634,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.080212,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 342,
    shape_dist_traveled: 20.379,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.080586,
    shape_pt_lon: -89.955955,
    shape_pt_sequence: 343,
    shape_dist_traveled: 20.4377,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.081057,
    shape_pt_lon: -89.956517,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.5105,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.081081,
    shape_pt_lon: -89.956547,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.5141,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.081141,
    shape_pt_lon: -89.956621,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.524,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08121,
    shape_pt_lon: -89.956703,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.5346,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.081418,
    shape_pt_lon: -89.956946,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.5665,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.081846,
    shape_pt_lon: -89.957458,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.6336,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.082102,
    shape_pt_lon: -89.957772,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.6732,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.082189,
    shape_pt_lon: -89.957871,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.6867,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08266,
    shape_pt_lon: -89.95843,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.7602,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.082897,
    shape_pt_lon: -89.958712,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.7963,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08305,
    shape_pt_lon: -89.958897,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.8204,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.083178,
    shape_pt_lon: -89.959052,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.8402,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.083205,
    shape_pt_lon: -89.959084,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.8444,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.083742,
    shape_pt_lon: -89.959723,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.9286,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.084278,
    shape_pt_lon: -89.960361,
    shape_pt_sequence: 358,
    shape_dist_traveled: 21.0113,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.084872,
    shape_pt_lon: -89.961078,
    shape_pt_sequence: 359,
    shape_dist_traveled: 21.104,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.085059,
    shape_pt_lon: -89.961303,
    shape_pt_sequence: 360,
    shape_dist_traveled: 21.1337,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.085793,
    shape_pt_lon: -89.962153,
    shape_pt_sequence: 361,
    shape_dist_traveled: 21.2461,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08599,
    shape_pt_lon: -89.962362,
    shape_pt_sequence: 362,
    shape_dist_traveled: 21.2752,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.086457,
    shape_pt_lon: -89.962842,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.3427,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.087159,
    shape_pt_lon: -89.96349,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.4405,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08761,
    shape_pt_lon: -89.96387,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.5018,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.08799,
    shape_pt_lon: -89.964163,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.5517,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.090059,
    shape_pt_lon: -89.965768,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.8253,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.090861,
    shape_pt_lon: -89.966416,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.9321,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.091458,
    shape_pt_lon: -89.96694,
    shape_pt_sequence: 369,
    shape_dist_traveled: 22.0139,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.09159,
    shape_pt_lon: -89.967051,
    shape_pt_sequence: 370,
    shape_dist_traveled: 22.0319,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.092173,
    shape_pt_lon: -89.967574,
    shape_pt_sequence: 371,
    shape_dist_traveled: 22.1127,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.09271,
    shape_pt_lon: -89.968051,
    shape_pt_sequence: 372,
    shape_dist_traveled: 22.1866,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.092773,
    shape_pt_lon: -89.968117,
    shape_pt_sequence: 373,
    shape_dist_traveled: 22.1958,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.094655,
    shape_pt_lon: -89.969791,
    shape_pt_sequence: 374,
    shape_dist_traveled: 22.4553,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.095028,
    shape_pt_lon: -89.970124,
    shape_pt_sequence: 375,
    shape_dist_traveled: 22.5067,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.095147,
    shape_pt_lon: -89.97023,
    shape_pt_sequence: 376,
    shape_dist_traveled: 22.5233,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.095461,
    shape_pt_lon: -89.970517,
    shape_pt_sequence: 377,
    shape_dist_traveled: 22.5669,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.096076,
    shape_pt_lon: -89.971066,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.6513,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.096506,
    shape_pt_lon: -89.971448,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.7109,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.097178,
    shape_pt_lon: -89.972056,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.8045,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.097492,
    shape_pt_lon: -89.972371,
    shape_pt_sequence: 381,
    shape_dist_traveled: 22.8494,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.972644,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.8854,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.09781,
    shape_pt_lon: -89.972738,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.8975,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.098014,
    shape_pt_lon: -89.973008,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.9314,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.098287,
    shape_pt_lon: -89.973401,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.9782,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.098556,
    shape_pt_lon: -89.973858,
    shape_pt_sequence: 386,
    shape_dist_traveled: 23.0298,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.098876,
    shape_pt_lon: -89.974473,
    shape_pt_sequence: 387,
    shape_dist_traveled: 23.095,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.099079,
    shape_pt_lon: -89.974863,
    shape_pt_sequence: 388,
    shape_dist_traveled: 23.1369,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.099477,
    shape_pt_lon: -89.975625,
    shape_pt_sequence: 389,
    shape_dist_traveled: 23.2193,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.099554,
    shape_pt_lon: -89.975775,
    shape_pt_sequence: 390,
    shape_dist_traveled: 23.2354,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.099702,
    shape_pt_lon: -89.976056,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.2656,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.977596,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.4315,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -89.978038,
    shape_pt_sequence: 393,
    shape_dist_traveled: 23.4792,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.100876,
    shape_pt_lon: -89.978307,
    shape_pt_sequence: 394,
    shape_dist_traveled: 23.5081,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.101844,
    shape_pt_lon: -89.980151,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.7069,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.102028,
    shape_pt_lon: -89.980468,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.7419,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.102272,
    shape_pt_lon: -89.980893,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.7894,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.102607,
    shape_pt_lon: -89.981424,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.85,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.1036,
    shape_pt_lon: -89.982976,
    shape_pt_sequence: 399,
    shape_dist_traveled: 24.0286,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.104063,
    shape_pt_lon: -89.983699,
    shape_pt_sequence: 400,
    shape_dist_traveled: 24.1127,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.104387,
    shape_pt_lon: -89.984177,
    shape_pt_sequence: 401,
    shape_dist_traveled: 24.1688,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.10462,
    shape_pt_lon: -89.984552,
    shape_pt_sequence: 402,
    shape_dist_traveled: 24.2116,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.104927,
    shape_pt_lon: -89.985038,
    shape_pt_sequence: 403,
    shape_dist_traveled: 24.2678,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105062,
    shape_pt_lon: -89.98525,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.292,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105203,
    shape_pt_lon: -89.985466,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.317,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105329,
    shape_pt_lon: -89.985653,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.3397,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105385,
    shape_pt_lon: -89.985738,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.3489,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105579,
    shape_pt_lon: -89.986008,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.3815,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105683,
    shape_pt_lon: -89.98615,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.3992,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.105949,
    shape_pt_lon: -89.986453,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.4396,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.10627,
    shape_pt_lon: -89.986761,
    shape_pt_sequence: 411,
    shape_dist_traveled: 24.4852,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 412,
    shape_dist_traveled: 24.5182,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.106839,
    shape_pt_lon: -89.987218,
    shape_pt_sequence: 413,
    shape_dist_traveled: 24.5604,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.107251,
    shape_pt_lon: -89.987492,
    shape_pt_sequence: 414,
    shape_dist_traveled: 24.6127,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.107475,
    shape_pt_lon: -89.987644,
    shape_pt_sequence: 415,
    shape_dist_traveled: 24.6423,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.107749,
    shape_pt_lon: -89.987853,
    shape_pt_sequence: 416,
    shape_dist_traveled: 24.6778,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.107991,
    shape_pt_lon: -89.988071,
    shape_pt_sequence: 417,
    shape_dist_traveled: 24.7108,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108348,
    shape_pt_lon: -89.988462,
    shape_pt_sequence: 418,
    shape_dist_traveled: 24.7646,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108396,
    shape_pt_lon: -89.988522,
    shape_pt_sequence: 419,
    shape_dist_traveled: 24.7717,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 420,
    shape_dist_traveled: 24.7981,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 421,
    shape_dist_traveled: 24.8172,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 422,
    shape_dist_traveled: 24.8478,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 423,
    shape_dist_traveled: 24.8613,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 424,
    shape_dist_traveled: 24.9291,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 425,
    shape_dist_traveled: 24.9615,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 426,
    shape_dist_traveled: 25.1114,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 427,
    shape_dist_traveled: 25.1535,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 428,
    shape_dist_traveled: 25.1699,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 429,
    shape_dist_traveled: 25.1841,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11106,
    shape_pt_lon: -89.992569,
    shape_pt_sequence: 430,
    shape_dist_traveled: 25.2447,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.111537,
    shape_pt_lon: -89.993302,
    shape_pt_sequence: 431,
    shape_dist_traveled: 25.3307,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.112193,
    shape_pt_lon: -89.994306,
    shape_pt_sequence: 432,
    shape_dist_traveled: 25.4466,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.112561,
    shape_pt_lon: -89.994868,
    shape_pt_sequence: 433,
    shape_dist_traveled: 25.512,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.112618,
    shape_pt_lon: -89.994952,
    shape_pt_sequence: 434,
    shape_dist_traveled: 25.522,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.113011,
    shape_pt_lon: -89.995553,
    shape_pt_sequence: 435,
    shape_dist_traveled: 25.5917,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.113236,
    shape_pt_lon: -89.995889,
    shape_pt_sequence: 436,
    shape_dist_traveled: 25.6322,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.113499,
    shape_pt_lon: -89.996299,
    shape_pt_sequence: 437,
    shape_dist_traveled: 25.6792,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.113717,
    shape_pt_lon: -89.996628,
    shape_pt_sequence: 438,
    shape_dist_traveled: 25.7168,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.113746,
    shape_pt_lon: -89.996673,
    shape_pt_sequence: 439,
    shape_dist_traveled: 25.7225,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11536,
    shape_pt_lon: -89.999139,
    shape_pt_sequence: 440,
    shape_dist_traveled: 26.0091,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.115591,
    shape_pt_lon: -89.999479,
    shape_pt_sequence: 441,
    shape_dist_traveled: 26.0495,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.115773,
    shape_pt_lon: -89.999719,
    shape_pt_sequence: 442,
    shape_dist_traveled: 26.0792,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.116176,
    shape_pt_lon: -90.00028,
    shape_pt_sequence: 443,
    shape_dist_traveled: 26.1473,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.116358,
    shape_pt_lon: -90.000539,
    shape_pt_sequence: 444,
    shape_dist_traveled: 26.1784,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.116452,
    shape_pt_lon: -90.000693,
    shape_pt_sequence: 445,
    shape_dist_traveled: 26.1956,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.116827,
    shape_pt_lon: -90.001336,
    shape_pt_sequence: 446,
    shape_dist_traveled: 26.2672,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11686,
    shape_pt_lon: -90.001403,
    shape_pt_sequence: 447,
    shape_dist_traveled: 26.2744,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.117101,
    shape_pt_lon: -90.001834,
    shape_pt_sequence: 448,
    shape_dist_traveled: 26.3219,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11727,
    shape_pt_lon: -90.002104,
    shape_pt_sequence: 449,
    shape_dist_traveled: 26.3527,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.117619,
    shape_pt_lon: -90.002647,
    shape_pt_sequence: 450,
    shape_dist_traveled: 26.4159,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11782,
    shape_pt_lon: -90.00296,
    shape_pt_sequence: 451,
    shape_dist_traveled: 26.4515,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.118248,
    shape_pt_lon: -90.003629,
    shape_pt_sequence: 452,
    shape_dist_traveled: 26.5284,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.118393,
    shape_pt_lon: -90.003858,
    shape_pt_sequence: 453,
    shape_dist_traveled: 26.5548,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.119091,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 454,
    shape_dist_traveled: 26.6808,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.119197,
    shape_pt_lon: -90.005107,
    shape_pt_sequence: 455,
    shape_dist_traveled: 26.6992,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.119937,
    shape_pt_lon: -90.006268,
    shape_pt_sequence: 456,
    shape_dist_traveled: 26.8331,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.11999,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 457,
    shape_dist_traveled: 26.8431,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.120106,
    shape_pt_lon: -90.006533,
    shape_pt_sequence: 458,
    shape_dist_traveled: 26.8637,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.120687,
    shape_pt_lon: -90.007442,
    shape_pt_sequence: 459,
    shape_dist_traveled: 26.9683,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.121217,
    shape_pt_lon: -90.008259,
    shape_pt_sequence: 460,
    shape_dist_traveled: 27.063,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.121429,
    shape_pt_lon: -90.008595,
    shape_pt_sequence: 461,
    shape_dist_traveled: 27.1016,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.122363,
    shape_pt_lon: -90.010048,
    shape_pt_sequence: 462,
    shape_dist_traveled: 27.2695,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.122543,
    shape_pt_lon: -90.010346,
    shape_pt_sequence: 463,
    shape_dist_traveled: 27.3031,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12279,
    shape_pt_lon: -90.01076,
    shape_pt_sequence: 464,
    shape_dist_traveled: 27.3495,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.123301,
    shape_pt_lon: -90.011644,
    shape_pt_sequence: 465,
    shape_dist_traveled: 27.4477,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -90.011677,
    shape_pt_sequence: 466,
    shape_dist_traveled: 27.4513,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12348,
    shape_pt_lon: -90.011949,
    shape_pt_sequence: 467,
    shape_dist_traveled: 27.4821,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.124171,
    shape_pt_lon: -90.013136,
    shape_pt_sequence: 468,
    shape_dist_traveled: 27.6139,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.124501,
    shape_pt_lon: -90.013705,
    shape_pt_sequence: 469,
    shape_dist_traveled: 27.6778,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.124924,
    shape_pt_lon: -90.014434,
    shape_pt_sequence: 470,
    shape_dist_traveled: 27.758,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.125917,
    shape_pt_lon: -90.016143,
    shape_pt_sequence: 471,
    shape_dist_traveled: 27.9486,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.016751,
    shape_pt_sequence: 472,
    shape_dist_traveled: 28.0172,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 473,
    shape_dist_traveled: 28.0408,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 474,
    shape_dist_traveled: 28.0588,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 475,
    shape_dist_traveled: 28.106,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 476,
    shape_dist_traveled: 28.2042,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 477,
    shape_dist_traveled: 28.3341,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 478,
    shape_dist_traveled: 28.4572,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 479,
    shape_dist_traveled: 28.4925,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 480,
    shape_dist_traveled: 28.5061,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 481,
    shape_dist_traveled: 28.5219,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 482,
    shape_dist_traveled: 28.5737,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 483,
    shape_dist_traveled: 28.6378,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 484,
    shape_dist_traveled: 28.7144,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 485,
    shape_dist_traveled: 28.748,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 486,
    shape_dist_traveled: 28.7752,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 487,
    shape_dist_traveled: 28.793,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 488,
    shape_dist_traveled: 28.7959,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 489,
    shape_dist_traveled: 28.8026,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 490,
    shape_dist_traveled: 28.8232,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 491,
    shape_dist_traveled: 28.8276,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.8807,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.9013,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.9927,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 495,
    shape_dist_traveled: 29.0127,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 496,
    shape_dist_traveled: 29.0487,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.131949,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 497,
    shape_dist_traveled: 29.0877,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.132856,
    shape_pt_lon: -90.02603,
    shape_pt_sequence: 498,
    shape_dist_traveled: 29.1888,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.133521,
    shape_pt_lon: -90.026023,
    shape_pt_sequence: 499,
    shape_dist_traveled: 29.2638,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.133678,
    shape_pt_lon: -90.026046,
    shape_pt_sequence: 500,
    shape_dist_traveled: 29.2809,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.133861,
    shape_pt_lon: -90.026101,
    shape_pt_sequence: 501,
    shape_dist_traveled: 29.3025,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.133952,
    shape_pt_lon: -90.026141,
    shape_pt_sequence: 502,
    shape_dist_traveled: 29.3132,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134112,
    shape_pt_lon: -90.026229,
    shape_pt_sequence: 503,
    shape_dist_traveled: 29.3329,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134253,
    shape_pt_lon: -90.026337,
    shape_pt_sequence: 504,
    shape_dist_traveled: 29.3518,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134353,
    shape_pt_lon: -90.026436,
    shape_pt_sequence: 505,
    shape_dist_traveled: 29.366,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134457,
    shape_pt_lon: -90.026558,
    shape_pt_sequence: 506,
    shape_dist_traveled: 29.3816,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.13454,
    shape_pt_lon: -90.026679,
    shape_pt_sequence: 507,
    shape_dist_traveled: 29.3964,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134682,
    shape_pt_lon: -90.026957,
    shape_pt_sequence: 508,
    shape_dist_traveled: 29.4261,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134746,
    shape_pt_lon: -90.02715,
    shape_pt_sequence: 509,
    shape_dist_traveled: 29.4445,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134797,
    shape_pt_lon: -90.027406,
    shape_pt_sequence: 510,
    shape_dist_traveled: 29.468,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134807,
    shape_pt_lon: -90.0275,
    shape_pt_sequence: 511,
    shape_dist_traveled: 29.4771,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.134849,
    shape_pt_lon: -90.028301,
    shape_pt_sequence: 512,
    shape_dist_traveled: 29.5493,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.136129,
    shape_pt_lon: -90.028261,
    shape_pt_sequence: 513,
    shape_dist_traveled: 29.6923,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.13704,
    shape_pt_lon: -90.028232,
    shape_pt_sequence: 514,
    shape_dist_traveled: 29.7944,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.137989,
    shape_pt_lon: -90.028193,
    shape_pt_sequence: 515,
    shape_dist_traveled: 29.9014,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.13807,
    shape_pt_lon: -90.02819,
    shape_pt_sequence: 516,
    shape_dist_traveled: 29.9104,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.138299,
    shape_pt_lon: -90.028198,
    shape_pt_sequence: 517,
    shape_dist_traveled: 29.9355,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.138453,
    shape_pt_lon: -90.028239,
    shape_pt_sequence: 518,
    shape_dist_traveled: 29.9529,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.138729,
    shape_pt_lon: -90.028321,
    shape_pt_sequence: 519,
    shape_dist_traveled: 29.9847,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.138878,
    shape_pt_lon: -90.028336,
    shape_pt_sequence: 520,
    shape_dist_traveled: 30.0018,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.139023,
    shape_pt_lon: -90.028323,
    shape_pt_sequence: 521,
    shape_dist_traveled: 30.018,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.140049,
    shape_pt_lon: -90.028168,
    shape_pt_sequence: 522,
    shape_dist_traveled: 30.1338,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.028117,
    shape_pt_sequence: 523,
    shape_dist_traveled: 30.1571,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.140387,
    shape_pt_lon: -90.028065,
    shape_pt_sequence: 524,
    shape_dist_traveled: 30.173,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.140801,
    shape_pt_lon: -90.027861,
    shape_pt_sequence: 525,
    shape_dist_traveled: 30.2224,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14099,
    shape_pt_lon: -90.027803,
    shape_pt_sequence: 526,
    shape_dist_traveled: 30.2442,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14136,
    shape_pt_lon: -90.027759,
    shape_pt_sequence: 527,
    shape_dist_traveled: 30.2863,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142561,
    shape_pt_lon: -90.027612,
    shape_pt_sequence: 528,
    shape_dist_traveled: 30.42,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142701,
    shape_pt_lon: -90.027611,
    shape_pt_sequence: 529,
    shape_dist_traveled: 30.436,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142792,
    shape_pt_lon: -90.02868,
    shape_pt_sequence: 530,
    shape_dist_traveled: 30.5336,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142839,
    shape_pt_lon: -90.02918,
    shape_pt_sequence: 531,
    shape_dist_traveled: 30.5788,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142848,
    shape_pt_lon: -90.029315,
    shape_pt_sequence: 532,
    shape_dist_traveled: 30.5909,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142854,
    shape_pt_lon: -90.029591,
    shape_pt_sequence: 533,
    shape_dist_traveled: 30.6159,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142836,
    shape_pt_lon: -90.0299,
    shape_pt_sequence: 534,
    shape_dist_traveled: 30.644,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142791,
    shape_pt_lon: -90.030198,
    shape_pt_sequence: 535,
    shape_dist_traveled: 30.6714,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142765,
    shape_pt_lon: -90.030331,
    shape_pt_sequence: 536,
    shape_dist_traveled: 30.6838,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142716,
    shape_pt_lon: -90.03056,
    shape_pt_sequence: 537,
    shape_dist_traveled: 30.7056,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142669,
    shape_pt_lon: -90.03084,
    shape_pt_sequence: 538,
    shape_dist_traveled: 30.7311,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142657,
    shape_pt_lon: -90.030926,
    shape_pt_sequence: 539,
    shape_dist_traveled: 30.7392,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14263,
    shape_pt_lon: -90.031433,
    shape_pt_sequence: 540,
    shape_dist_traveled: 30.7853,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142641,
    shape_pt_lon: -90.032026,
    shape_pt_sequence: 541,
    shape_dist_traveled: 30.8383,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142626,
    shape_pt_lon: -90.032316,
    shape_pt_sequence: 542,
    shape_dist_traveled: 30.8644,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142567,
    shape_pt_lon: -90.032941,
    shape_pt_sequence: 543,
    shape_dist_traveled: 30.9217,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142558,
    shape_pt_lon: -90.033182,
    shape_pt_sequence: 544,
    shape_dist_traveled: 30.9437,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142565,
    shape_pt_lon: -90.033456,
    shape_pt_sequence: 545,
    shape_dist_traveled: 30.9677,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142608,
    shape_pt_lon: -90.033807,
    shape_pt_sequence: 546,
    shape_dist_traveled: 31.0001,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142718,
    shape_pt_lon: -90.034973,
    shape_pt_sequence: 547,
    shape_dist_traveled: 31.1058,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142727,
    shape_pt_lon: -90.035042,
    shape_pt_sequence: 548,
    shape_dist_traveled: 31.1129,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14279,
    shape_pt_lon: -90.035138,
    shape_pt_sequence: 549,
    shape_dist_traveled: 31.1235,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142812,
    shape_pt_lon: -90.035276,
    shape_pt_sequence: 550,
    shape_dist_traveled: 31.1368,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.142847,
    shape_pt_lon: -90.035446,
    shape_pt_sequence: 551,
    shape_dist_traveled: 31.1523,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14337,
    shape_pt_lon: -90.037434,
    shape_pt_sequence: 552,
    shape_dist_traveled: 31.3415,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.143397,
    shape_pt_lon: -90.037531,
    shape_pt_sequence: 553,
    shape_dist_traveled: 31.3509,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14358,
    shape_pt_lon: -90.038067,
    shape_pt_sequence: 554,
    shape_dist_traveled: 31.4033,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.143731,
    shape_pt_lon: -90.03848,
    shape_pt_sequence: 555,
    shape_dist_traveled: 31.4441,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.143811,
    shape_pt_lon: -90.038789,
    shape_pt_sequence: 556,
    shape_dist_traveled: 31.4735,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.143869,
    shape_pt_lon: -90.03912,
    shape_pt_sequence: 557,
    shape_dist_traveled: 31.5041,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.143909,
    shape_pt_lon: -90.039498,
    shape_pt_sequence: 558,
    shape_dist_traveled: 31.5384,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14399,
    shape_pt_lon: -90.039968,
    shape_pt_sequence: 559,
    shape_dist_traveled: 31.5824,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.1441,
    shape_pt_lon: -90.040389,
    shape_pt_sequence: 560,
    shape_dist_traveled: 31.6222,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14433,
    shape_pt_lon: -90.041272,
    shape_pt_sequence: 561,
    shape_dist_traveled: 31.7063,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144399,
    shape_pt_lon: -90.041547,
    shape_pt_sequence: 562,
    shape_dist_traveled: 31.7313,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144414,
    shape_pt_lon: -90.041606,
    shape_pt_sequence: 563,
    shape_dist_traveled: 31.7377,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144471,
    shape_pt_lon: -90.041829,
    shape_pt_sequence: 564,
    shape_dist_traveled: 31.7585,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144501,
    shape_pt_lon: -90.041958,
    shape_pt_sequence: 565,
    shape_dist_traveled: 31.7712,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144491,
    shape_pt_lon: -90.042019,
    shape_pt_sequence: 566,
    shape_dist_traveled: 31.7763,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144626,
    shape_pt_lon: -90.042423,
    shape_pt_sequence: 567,
    shape_dist_traveled: 31.8162,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.144974,
    shape_pt_lon: -90.043495,
    shape_pt_sequence: 568,
    shape_dist_traveled: 31.9198,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145039,
    shape_pt_lon: -90.043736,
    shape_pt_sequence: 569,
    shape_dist_traveled: 31.9429,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145062,
    shape_pt_lon: -90.043826,
    shape_pt_sequence: 570,
    shape_dist_traveled: 31.9512,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145094,
    shape_pt_lon: -90.043941,
    shape_pt_sequence: 571,
    shape_dist_traveled: 31.9629,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145193,
    shape_pt_lon: -90.044298,
    shape_pt_sequence: 572,
    shape_dist_traveled: 31.9967,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145528,
    shape_pt_lon: -90.045569,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.1178,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145598,
    shape_pt_lon: -90.045926,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.1506,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145621,
    shape_pt_lon: -90.046134,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.1698,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14565,
    shape_pt_lon: -90.046271,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.1822,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.145766,
    shape_pt_lon: -90.046733,
    shape_pt_sequence: 577,
    shape_dist_traveled: 32.2261,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 578,
    shape_dist_traveled: 32.4908,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 579,
    shape_dist_traveled: 32.6075,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 580,
    shape_dist_traveled: 32.7252,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 581,
    shape_dist_traveled: 32.7808,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 582,
    shape_dist_traveled: 32.8409,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 583,
    shape_dist_traveled: 32.9586,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 584,
    shape_dist_traveled: 33.0749,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 585,
    shape_dist_traveled: 33.1882,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 586,
    shape_dist_traveled: 33.198,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 587,
    shape_dist_traveled: 33.2206,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 588,
    shape_dist_traveled: 33.2323,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 589,
    shape_dist_traveled: 33.2794,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 590,
    shape_dist_traveled: 33.3063,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 591,
    shape_dist_traveled: 33.3218,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 592,
    shape_dist_traveled: 33.4262,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 593,
    shape_dist_traveled: 33.5378,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 594,
    shape_dist_traveled: 33.5451,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 595,
    shape_dist_traveled: 33.5505,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 596,
    shape_dist_traveled: 33.5505,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 597,
    shape_dist_traveled: 33.6071,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 598,
    shape_dist_traveled: 33.6071,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 599,
    shape_dist_traveled: 33.6596,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 600,
    shape_dist_traveled: 33.7191,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 601,
    shape_dist_traveled: 33.7779,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 602,
    shape_dist_traveled: 33.795,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 603,
    shape_dist_traveled: 33.8576,
  },
  {
    shape_id: 38232,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 604,
    shape_dist_traveled: 33.9085,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0058,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0188,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0329,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0399,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0494,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1005,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1036,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.11,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.116,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.144,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.157,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.1655,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.1713,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.1803,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.2554,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3135,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.3287,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.3357,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.076489,
    shape_pt_lon: -89.931516,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.3448,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.4149,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.451,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.4891,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.4962,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.5633,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.6993,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.7083,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 28,
    shape_dist_traveled: 0.7203,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 29,
    shape_dist_traveled: 0.7253,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 30,
    shape_dist_traveled: 0.7383,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 31,
    shape_dist_traveled: 0.7483,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 32,
    shape_dist_traveled: 0.7653,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 33,
    shape_dist_traveled: 0.7873,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 34,
    shape_dist_traveled: 0.9013,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 35,
    shape_dist_traveled: 0.9634,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 36,
    shape_dist_traveled: 0.9744,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.0054,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.0274,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.0944,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.1424,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.2174,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.3134,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.4515,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.4665,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.4785,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.4925,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08671,
    shape_pt_lon: -89.9306,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.5445,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086689,
    shape_pt_lon: -89.929684,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.6276,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086684,
    shape_pt_lon: -89.92945,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.6486,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086679,
    shape_pt_lon: -89.929174,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.6736,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08664,
    shape_pt_lon: -89.927339,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.8396,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086369,
    shape_pt_lon: -89.92369,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.171,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086192,
    shape_pt_lon: -89.92136,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.3829,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.086138,
    shape_pt_lon: -89.921077,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4087,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085995,
    shape_pt_lon: -89.920661,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.4499,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085959,
    shape_pt_lon: -89.920493,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.4654,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085942,
    shape_pt_lon: -89.920291,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.4834,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08593,
    shape_pt_lon: -89.919805,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.5275,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085904,
    shape_pt_lon: -89.918917,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.6085,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085846,
    shape_pt_lon: -89.916836,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.7966,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085797,
    shape_pt_lon: -89.913479,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.0997,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085786,
    shape_pt_lon: -89.911918,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.2407,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085772,
    shape_pt_lon: -89.910898,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.3337,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085737,
    shape_pt_lon: -89.909132,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4927,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085715,
    shape_pt_lon: -89.908168,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5798,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.085693,
    shape_pt_lon: -89.907154,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6718,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.08665,
    shape_pt_lon: -89.907123,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7788,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.087573,
    shape_pt_lon: -89.907089,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.8819,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.088489,
    shape_pt_lon: -89.907052,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.9839,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.0894,
    shape_pt_lon: -89.907021,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.0859,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.090327,
    shape_pt_lon: -89.906983,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.19,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.091417,
    shape_pt_lon: -89.906945,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.312,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.092645,
    shape_pt_lon: -89.906903,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.4501,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.093557,
    shape_pt_lon: -89.906873,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.5521,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.095612,
    shape_pt_lon: -89.906799,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.7822,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.096527,
    shape_pt_lon: -89.906767,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.8842,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.1006,
    shape_pt_lon: -89.906627,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.3404,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.10275,
    shape_pt_lon: -89.906556,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.5805,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.103309,
    shape_pt_lon: -89.906561,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.6435,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.103421,
    shape_pt_lon: -89.906596,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.6558,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.104147,
    shape_pt_lon: -89.907068,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.7484,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.104329,
    shape_pt_lon: -89.90722,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.7723,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.10456,
    shape_pt_lon: -89.907409,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.8039,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.10479,
    shape_pt_lon: -89.907556,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.8321,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.105088,
    shape_pt_lon: -89.907673,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.8675,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.105699,
    shape_pt_lon: -89.907887,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.9384,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.105822,
    shape_pt_lon: -89.907911,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.9526,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.105884,
    shape_pt_lon: -89.907917,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.9596,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.106853,
    shape_pt_lon: -89.907867,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.0677,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.10691,
    shape_pt_lon: -89.907861,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.0747,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.107013,
    shape_pt_lon: -89.907858,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.0857,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.108127,
    shape_pt_lon: -89.907789,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.2109,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.108422,
    shape_pt_lon: -89.907773,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.2439,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.108919,
    shape_pt_lon: -89.907749,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.299,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.109004,
    shape_pt_lon: -89.907745,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.309,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.109478,
    shape_pt_lon: -89.907721,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.362,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11001,
    shape_pt_lon: -89.907694,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.4211,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.110847,
    shape_pt_lon: -89.907651,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.5151,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11113,
    shape_pt_lon: -89.907638,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.5472,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.111261,
    shape_pt_lon: -89.90763,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.5612,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.112038,
    shape_pt_lon: -89.907591,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.6483,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.112308,
    shape_pt_lon: -89.907593,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.6793,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.112374,
    shape_pt_lon: -89.907604,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.6864,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.112558,
    shape_pt_lon: -89.907678,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.7082,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11272,
    shape_pt_lon: -89.907794,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.7293,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.112822,
    shape_pt_lon: -89.907901,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.7442,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.113294,
    shape_pt_lon: -89.908589,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.8258,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11343,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.8477,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.113583,
    shape_pt_lon: -89.908937,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.8704,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.113787,
    shape_pt_lon: -89.909118,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.899,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.114048,
    shape_pt_lon: -89.909298,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.9321,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11423,
    shape_pt_lon: -89.909394,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.954,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.114431,
    shape_pt_lon: -89.909472,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.9781,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.114612,
    shape_pt_lon: -89.909525,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.9987,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.114817,
    shape_pt_lon: -89.90956,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.0219,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.115101,
    shape_pt_lon: -89.909562,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.0539,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.115279,
    shape_pt_lon: -89.909546,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.0739,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.115523,
    shape_pt_lon: -89.909507,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.1012,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.115629,
    shape_pt_lon: -89.909488,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.1132,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.115937,
    shape_pt_lon: -89.909407,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.1482,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.116841,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.2545,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.117079,
    shape_pt_lon: -89.908961,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.2823,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.117748,
    shape_pt_lon: -89.908616,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.3635,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.117839,
    shape_pt_lon: -89.908588,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.3737,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.11807,
    shape_pt_lon: -89.908554,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.3999,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.119911,
    shape_pt_lon: -89.908506,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.6059,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.12014,
    shape_pt_lon: -89.90852,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.6319,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.120302,
    shape_pt_lon: -89.908559,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.6502,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.120519,
    shape_pt_lon: -89.908648,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.6758,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.120664,
    shape_pt_lon: -89.908746,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.6937,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.908992,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.7331,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.121063,
    shape_pt_lon: -89.90907,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.7479,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.121167,
    shape_pt_lon: -89.909138,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.7613,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.121389,
    shape_pt_lon: -89.909223,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.7866,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.121652,
    shape_pt_lon: -89.909266,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.8168,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.123656,
    shape_pt_lon: -89.909219,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.0409,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.124698,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.1579,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.125559,
    shape_pt_lon: -89.909168,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.2539,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.126146,
    shape_pt_lon: -89.90915,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.3199,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.126437,
    shape_pt_lon: -89.909123,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.352,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.126493,
    shape_pt_lon: -89.909115,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.3591,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.126831,
    shape_pt_lon: -89.909027,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.3969,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.127182,
    shape_pt_lon: -89.908888,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.439,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.127422,
    shape_pt_lon: -89.90881,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.4669,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.127593,
    shape_pt_lon: -89.908783,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.486,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.127804,
    shape_pt_lon: -89.908771,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.509,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.128486,
    shape_pt_lon: -89.90876,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.586,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.130102,
    shape_pt_lon: -89.908731,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.766,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.130312,
    shape_pt_lon: -89.90871,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.7901,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.130461,
    shape_pt_lon: -89.908681,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.8074,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13077,
    shape_pt_lon: -89.90859,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.8423,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131107,
    shape_pt_lon: -89.908492,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.8813,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131122,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.9454,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131157,
    shape_pt_lon: -89.910682,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.0794,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.911533,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.1564,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131187,
    shape_pt_lon: -89.912047,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.2034,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13119,
    shape_pt_lon: -89.912241,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.2205,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131197,
    shape_pt_lon: -89.912507,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.2445,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131207,
    shape_pt_lon: -89.912933,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2835,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131222,
    shape_pt_lon: -89.913562,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.3405,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131223,
    shape_pt_lon: -89.913616,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.3455,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131239,
    shape_pt_lon: -89.914353,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.4116,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131243,
    shape_pt_lon: -89.914567,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.4306,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131267,
    shape_pt_lon: -89.915488,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.5146,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13129,
    shape_pt_lon: -89.916459,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.6016,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.131739,
    shape_pt_lon: -89.91645,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.6526,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13244,
    shape_pt_lon: -89.916434,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.7307,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.132702,
    shape_pt_lon: -89.916427,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.7597,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13353,
    shape_pt_lon: -89.916398,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.8527,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.134039,
    shape_pt_lon: -89.916383,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.9097,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.1345,
    shape_pt_lon: -89.916369,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.9607,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.134538,
    shape_pt_lon: -89.916368,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.9657,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.134746,
    shape_pt_lon: -89.916359,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.9888,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13563,
    shape_pt_lon: -89.916329,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.0878,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13572,
    shape_pt_lon: -89.916326,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.0978,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.136281,
    shape_pt_lon: -89.916306,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.1608,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.13734,
    shape_pt_lon: -89.91627,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.2789,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -89.916264,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.2999,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.137817,
    shape_pt_lon: -89.916256,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.3329,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.137922,
    shape_pt_lon: -89.916251,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.3439,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.139473,
    shape_pt_lon: -89.9162,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.5179,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.139612,
    shape_pt_lon: -89.916197,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.534,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.140479,
    shape_pt_lon: -89.916164,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.631,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.140656,
    shape_pt_lon: -89.916159,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.65,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.141874,
    shape_pt_lon: -89.916121,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.7861,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.142228,
    shape_pt_lon: -89.916097,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.8251,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.14264,
    shape_pt_lon: -89.916009,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.8718,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.142951,
    shape_pt_lon: -89.91599,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.9069,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.143173,
    shape_pt_lon: -89.915996,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.9319,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.143405,
    shape_pt_lon: -89.916038,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.9582,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.143579,
    shape_pt_lon: -89.916081,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.9774,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.143807,
    shape_pt_lon: -89.916091,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.0034,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.143886,
    shape_pt_lon: -89.916083,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.0124,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.144033,
    shape_pt_lon: -89.916058,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.0287,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.144306,
    shape_pt_lon: -89.915987,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.0603,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.144512,
    shape_pt_lon: -89.915967,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.0834,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.144678,
    shape_pt_lon: -89.915977,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.1014,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.144957,
    shape_pt_lon: -89.916035,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.134,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.145249,
    shape_pt_lon: -89.916106,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.1665,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.145404,
    shape_pt_lon: -89.916118,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.1845,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.145572,
    shape_pt_lon: -89.916111,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.2025,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.14568,
    shape_pt_lon: -89.916107,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2156,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.14578,
    shape_pt_lon: -89.916101,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.2266,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.146003,
    shape_pt_lon: -89.916092,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.2516,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.147303,
    shape_pt_lon: -89.916037,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.3967,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.147827,
    shape_pt_lon: -89.916022,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.4557,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.14811,
    shape_pt_lon: -89.916046,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.4868,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.148593,
    shape_pt_lon: -89.916031,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.5418,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.14888,
    shape_pt_lon: -89.916018,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.5738,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.148959,
    shape_pt_lon: -89.916013,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.5818,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.151347,
    shape_pt_lon: -89.915819,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.8504,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.152373,
    shape_pt_lon: -89.915781,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.9654,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.152409,
    shape_pt_lon: -89.91578,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.9694,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.153209,
    shape_pt_lon: -89.915757,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.0584,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.154106,
    shape_pt_lon: -89.915728,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.1585,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.154213,
    shape_pt_lon: -89.915726,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.1705,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.154941,
    shape_pt_lon: -89.915711,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.2525,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.155527,
    shape_pt_lon: -89.915694,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.3175,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.155821,
    shape_pt_lon: -89.915683,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.3505,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.156739,
    shape_pt_lon: -89.915649,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.4536,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.157359,
    shape_pt_lon: -89.91563,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.5226,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.157676,
    shape_pt_lon: -89.915618,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.5586,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.158611,
    shape_pt_lon: -89.915588,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.6636,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.159107,
    shape_pt_lon: -89.915582,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.7187,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.15923,
    shape_pt_lon: -89.915601,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.7328,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.159345,
    shape_pt_lon: -89.915653,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.7467,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.159467,
    shape_pt_lon: -89.915754,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.7625,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.159522,
    shape_pt_lon: -89.915821,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.771,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16,
    shape_pt_lon: -89.91653,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.8548,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160325,
    shape_pt_lon: -89.916999,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.9101,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160456,
    shape_pt_lon: -89.917132,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.9293,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160612,
    shape_pt_lon: -89.917245,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.9495,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160801,
    shape_pt_lon: -89.917326,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.9717,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.917357,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.9909,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161129,
    shape_pt_lon: -89.917356,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.0089,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.1089,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.27,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.36,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.374,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.481,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.657,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.7901,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.8091,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.8281,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.8389,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.8545,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.8581,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.8725,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.9094,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.9186,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.9335,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.9474,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.9641,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.9803,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.0453,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.1844,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.1904,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161043,
    shape_pt_lon: -89.905835,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.2034,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161872,
    shape_pt_lon: -89.905812,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.2965,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.161979,
    shape_pt_lon: -89.905817,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.3085,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16207,
    shape_pt_lon: -89.905749,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.3202,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.162286,
    shape_pt_lon: -89.905737,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.3442,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.163323,
    shape_pt_lon: -89.90571,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.4602,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.163543,
    shape_pt_lon: -89.905704,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.4852,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16471,
    shape_pt_lon: -89.905676,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.6163,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.165041,
    shape_pt_lon: -89.905646,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.6533,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.165369,
    shape_pt_lon: -89.905579,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.6898,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.165661,
    shape_pt_lon: -89.905489,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.7238,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.165889,
    shape_pt_lon: -89.905392,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.7513,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166188,
    shape_pt_lon: -89.905237,
    shape_pt_sequence: 270,
    shape_dist_traveled: 14.7871,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166247,
    shape_pt_lon: -89.905198,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.7947,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166458,
    shape_pt_lon: -89.905056,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.8212,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166692,
    shape_pt_lon: -89.904871,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.8522,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166797,
    shape_pt_lon: -89.904774,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.8672,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.166989,
    shape_pt_lon: -89.904592,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.8944,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16711,
    shape_pt_lon: -89.904462,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.9121,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.167453,
    shape_pt_lon: -89.904043,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.9659,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16758,
    shape_pt_lon: -89.903906,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.985,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16775,
    shape_pt_lon: -89.903729,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.0098,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.167869,
    shape_pt_lon: -89.903612,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.0262,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.168423,
    shape_pt_lon: -89.902983,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.1104,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.168601,
    shape_pt_lon: -89.902798,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.1367,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.168899,
    shape_pt_lon: -89.902516,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.1789,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.168957,
    shape_pt_lon: -89.902448,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.1874,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.169028,
    shape_pt_lon: -89.902366,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.1987,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16911,
    shape_pt_lon: -89.902271,
    shape_pt_sequence: 286,
    shape_dist_traveled: 15.2107,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.169452,
    shape_pt_lon: -89.901851,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.2652,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.16962,
    shape_pt_lon: -89.901672,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.29,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.170064,
    shape_pt_lon: -89.901178,
    shape_pt_sequence: 289,
    shape_dist_traveled: 15.3565,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.170192,
    shape_pt_lon: -89.901011,
    shape_pt_sequence: 290,
    shape_dist_traveled: 15.3778,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.170347,
    shape_pt_lon: -89.900851,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.3998,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.171088,
    shape_pt_lon: -89.900083,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.5077,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.171719,
    shape_pt_lon: -89.899418,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.6007,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.172161,
    shape_pt_lon: -89.899001,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.6627,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.172343,
    shape_pt_lon: -89.898844,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.6879,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.172845,
    shape_pt_lon: -89.898445,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.7545,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.173303,
    shape_pt_lon: -89.898114,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.8137,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.173754,
    shape_pt_lon: -89.89782,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.8709,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.173998,
    shape_pt_lon: -89.897676,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.9009,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.174127,
    shape_pt_lon: -89.897689,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.9159,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.174453,
    shape_pt_lon: -89.8975,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.9557,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.175056,
    shape_pt_lon: -89.897218,
    shape_pt_sequence: 302,
    shape_dist_traveled: 16.0282,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.17527,
    shape_pt_lon: -89.897142,
    shape_pt_sequence: 303,
    shape_dist_traveled: 16.0532,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.897023,
    shape_pt_sequence: 304,
    shape_dist_traveled: 16.0899,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.175667,
    shape_pt_lon: -89.896996,
    shape_pt_sequence: 305,
    shape_dist_traveled: 16.0991,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.175687,
    shape_pt_lon: -89.896989,
    shape_pt_sequence: 306,
    shape_dist_traveled: 16.1013,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.175739,
    shape_pt_lon: -89.89697,
    shape_pt_sequence: 307,
    shape_dist_traveled: 16.1076,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.1758,
    shape_pt_lon: -89.896949,
    shape_pt_sequence: 308,
    shape_dist_traveled: 16.1149,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.176067,
    shape_pt_lon: -89.896865,
    shape_pt_sequence: 309,
    shape_dist_traveled: 16.1457,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.176549,
    shape_pt_lon: -89.89673,
    shape_pt_sequence: 310,
    shape_dist_traveled: 16.201,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.177197,
    shape_pt_lon: -89.896594,
    shape_pt_sequence: 311,
    shape_dist_traveled: 16.274,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.177261,
    shape_pt_lon: -89.896582,
    shape_pt_sequence: 312,
    shape_dist_traveled: 16.2811,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.177907,
    shape_pt_lon: -89.896501,
    shape_pt_sequence: 313,
    shape_dist_traveled: 16.3545,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.17853,
    shape_pt_lon: -89.89646,
    shape_pt_sequence: 314,
    shape_dist_traveled: 16.4246,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.179154,
    shape_pt_lon: -89.896444,
    shape_pt_sequence: 315,
    shape_dist_traveled: 16.4936,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.179936,
    shape_pt_lon: -89.89643,
    shape_pt_sequence: 316,
    shape_dist_traveled: 16.5816,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.180463,
    shape_pt_lon: -89.896418,
    shape_pt_sequence: 317,
    shape_dist_traveled: 16.6406,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.182612,
    shape_pt_lon: -89.896381,
    shape_pt_sequence: 318,
    shape_dist_traveled: 16.8807,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.183397,
    shape_pt_lon: -89.896371,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.9687,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.183762,
    shape_pt_lon: -89.896358,
    shape_pt_sequence: 320,
    shape_dist_traveled: 17.0097,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.184549,
    shape_pt_lon: -89.896342,
    shape_pt_sequence: 321,
    shape_dist_traveled: 17.0977,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.185733,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 322,
    shape_dist_traveled: 17.2307,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.185851,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 323,
    shape_dist_traveled: 17.2437,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.185924,
    shape_pt_lon: -89.896226,
    shape_pt_sequence: 324,
    shape_dist_traveled: 17.2544,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.186969,
    shape_pt_lon: -89.896131,
    shape_pt_sequence: 325,
    shape_dist_traveled: 17.3716,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.187962,
    shape_pt_lon: -89.896033,
    shape_pt_sequence: 326,
    shape_dist_traveled: 17.483,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.188329,
    shape_pt_lon: -89.895999,
    shape_pt_sequence: 327,
    shape_dist_traveled: 17.5241,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.188809,
    shape_pt_lon: -89.895954,
    shape_pt_sequence: 328,
    shape_dist_traveled: 17.5783,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.189189,
    shape_pt_lon: -89.895922,
    shape_pt_sequence: 329,
    shape_dist_traveled: 17.6204,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.191165,
    shape_pt_lon: -89.895742,
    shape_pt_sequence: 330,
    shape_dist_traveled: 17.8429,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.19153,
    shape_pt_lon: -89.895709,
    shape_pt_sequence: 331,
    shape_dist_traveled: 17.883,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.192906,
    shape_pt_lon: -89.895577,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.0374,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.193307,
    shape_pt_lon: -89.895546,
    shape_pt_sequence: 333,
    shape_dist_traveled: 18.0825,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.193349,
    shape_pt_lon: -89.895642,
    shape_pt_sequence: 334,
    shape_dist_traveled: 18.0928,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.193366,
    shape_pt_lon: -89.895679,
    shape_pt_sequence: 335,
    shape_dist_traveled: 18.0965,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.193529,
    shape_pt_lon: -89.89603,
    shape_pt_sequence: 336,
    shape_dist_traveled: 18.1332,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.194205,
    shape_pt_lon: -89.897451,
    shape_pt_sequence: 337,
    shape_dist_traveled: 18.2824,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.194418,
    shape_pt_lon: -89.897916,
    shape_pt_sequence: 338,
    shape_dist_traveled: 18.3308,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.194599,
    shape_pt_lon: -89.898341,
    shape_pt_sequence: 339,
    shape_dist_traveled: 18.3737,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.194915,
    shape_pt_lon: -89.899174,
    shape_pt_sequence: 340,
    shape_dist_traveled: 18.4569,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195077,
    shape_pt_lon: -89.899619,
    shape_pt_sequence: 341,
    shape_dist_traveled: 18.5008,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195298,
    shape_pt_lon: -89.900188,
    shape_pt_sequence: 342,
    shape_dist_traveled: 18.558,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195376,
    shape_pt_lon: -89.900398,
    shape_pt_sequence: 343,
    shape_dist_traveled: 18.579,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195479,
    shape_pt_lon: -89.900682,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.6073,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195649,
    shape_pt_lon: -89.90111,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.6498,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195829,
    shape_pt_lon: -89.901507,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.6909,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.195903,
    shape_pt_lon: -89.90164,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.7059,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.196159,
    shape_pt_lon: -89.902037,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.7516,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.196178,
    shape_pt_lon: -89.902064,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.7544,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.196453,
    shape_pt_lon: -89.902408,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.7989,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.196658,
    shape_pt_lon: -89.902618,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.8288,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197029,
    shape_pt_lon: -89.90299,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.8822,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197296,
    shape_pt_lon: -89.903298,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.9225,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197499,
    shape_pt_lon: -89.90358,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.9572,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197643,
    shape_pt_lon: -89.903812,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.9836,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197799,
    shape_pt_lon: -89.904096,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.0144,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.197947,
    shape_pt_lon: -89.904446,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.0502,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198069,
    shape_pt_lon: -89.904798,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.0851,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198181,
    shape_pt_lon: -89.905206,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.124,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198221,
    shape_pt_lon: -89.905417,
    shape_pt_sequence: 360,
    shape_dist_traveled: 19.1437,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198279,
    shape_pt_lon: -89.905853,
    shape_pt_sequence: 361,
    shape_dist_traveled: 19.1831,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198316,
    shape_pt_lon: -89.906857,
    shape_pt_sequence: 362,
    shape_dist_traveled: 19.2742,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198359,
    shape_pt_lon: -89.907381,
    shape_pt_sequence: 363,
    shape_dist_traveled: 19.3215,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.19843,
    shape_pt_lon: -89.907798,
    shape_pt_sequence: 364,
    shape_dist_traveled: 19.3603,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198487,
    shape_pt_lon: -89.90803,
    shape_pt_sequence: 365,
    shape_dist_traveled: 19.3821,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198532,
    shape_pt_lon: -89.908203,
    shape_pt_sequence: 366,
    shape_dist_traveled: 19.398,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.198638,
    shape_pt_lon: -89.90853,
    shape_pt_sequence: 367,
    shape_dist_traveled: 19.4303,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.19883,
    shape_pt_lon: -89.908989,
    shape_pt_sequence: 368,
    shape_dist_traveled: 19.4763,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.199567,
    shape_pt_lon: -89.910478,
    shape_pt_sequence: 369,
    shape_dist_traveled: 19.6343,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.199801,
    shape_pt_lon: -89.91095,
    shape_pt_sequence: 370,
    shape_dist_traveled: 19.6842,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.199999,
    shape_pt_lon: -89.911329,
    shape_pt_sequence: 371,
    shape_dist_traveled: 19.7256,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.200222,
    shape_pt_lon: -89.911685,
    shape_pt_sequence: 372,
    shape_dist_traveled: 19.7662,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.20041,
    shape_pt_lon: -89.911933,
    shape_pt_sequence: 373,
    shape_dist_traveled: 19.7966,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.200513,
    shape_pt_lon: -89.912068,
    shape_pt_sequence: 374,
    shape_dist_traveled: 19.8129,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.200776,
    shape_pt_lon: -89.912356,
    shape_pt_sequence: 375,
    shape_dist_traveled: 19.8525,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.200902,
    shape_pt_lon: -89.912471,
    shape_pt_sequence: 376,
    shape_dist_traveled: 19.8697,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.201331,
    shape_pt_lon: -89.912794,
    shape_pt_sequence: 377,
    shape_dist_traveled: 19.9258,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.201602,
    shape_pt_lon: -89.912959,
    shape_pt_sequence: 378,
    shape_dist_traveled: 19.9602,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.201739,
    shape_pt_lon: -89.913032,
    shape_pt_sequence: 379,
    shape_dist_traveled: 19.9768,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.202161,
    shape_pt_lon: -89.913212,
    shape_pt_sequence: 380,
    shape_dist_traveled: 20.0264,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.202531,
    shape_pt_lon: -89.913316,
    shape_pt_sequence: 381,
    shape_dist_traveled: 20.0696,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.202883,
    shape_pt_lon: -89.913371,
    shape_pt_sequence: 382,
    shape_dist_traveled: 20.1089,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.20293,
    shape_pt_lon: -89.913376,
    shape_pt_sequence: 383,
    shape_dist_traveled: 20.1139,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.203852,
    shape_pt_lon: -89.913448,
    shape_pt_sequence: 384,
    shape_dist_traveled: 20.2171,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.204631,
    shape_pt_lon: -89.913508,
    shape_pt_sequence: 385,
    shape_dist_traveled: 20.3053,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.205046,
    shape_pt_lon: -89.913597,
    shape_pt_sequence: 386,
    shape_dist_traveled: 20.352,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.205329,
    shape_pt_lon: -89.913692,
    shape_pt_sequence: 387,
    shape_dist_traveled: 20.3852,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.205775,
    shape_pt_lon: -89.913887,
    shape_pt_sequence: 388,
    shape_dist_traveled: 20.4384,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.205853,
    shape_pt_lon: -89.913923,
    shape_pt_sequence: 389,
    shape_dist_traveled: 20.4469,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.205913,
    shape_pt_lon: -89.913951,
    shape_pt_sequence: 390,
    shape_dist_traveled: 20.4542,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.207108,
    shape_pt_lon: -89.914489,
    shape_pt_sequence: 391,
    shape_dist_traveled: 20.5969,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.207223,
    shape_pt_lon: -89.914536,
    shape_pt_sequence: 392,
    shape_dist_traveled: 20.6108,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.20742,
    shape_pt_lon: -89.9146,
    shape_pt_sequence: 393,
    shape_dist_traveled: 20.6333,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.20763,
    shape_pt_lon: -89.914644,
    shape_pt_sequence: 394,
    shape_dist_traveled: 20.6567,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.207909,
    shape_pt_lon: -89.914675,
    shape_pt_sequence: 395,
    shape_dist_traveled: 20.6878,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.20853,
    shape_pt_lon: -89.914661,
    shape_pt_sequence: 396,
    shape_dist_traveled: 20.7578,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.209302,
    shape_pt_lon: -89.914632,
    shape_pt_sequence: 397,
    shape_dist_traveled: 20.8439,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.210099,
    shape_pt_lon: -89.914603,
    shape_pt_sequence: 398,
    shape_dist_traveled: 20.9329,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.211108,
    shape_pt_lon: -89.914564,
    shape_pt_sequence: 399,
    shape_dist_traveled: 21.046,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.211415,
    shape_pt_lon: -89.91457,
    shape_pt_sequence: 400,
    shape_dist_traveled: 21.081,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.211663,
    shape_pt_lon: -89.91461,
    shape_pt_sequence: 401,
    shape_dist_traveled: 21.1083,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.211851,
    shape_pt_lon: -89.914663,
    shape_pt_sequence: 402,
    shape_dist_traveled: 21.1298,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.212082,
    shape_pt_lon: -89.914759,
    shape_pt_sequence: 403,
    shape_dist_traveled: 21.1574,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.212316,
    shape_pt_lon: -89.914898,
    shape_pt_sequence: 404,
    shape_dist_traveled: 21.186,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.21253,
    shape_pt_lon: -89.915066,
    shape_pt_sequence: 405,
    shape_dist_traveled: 21.2143,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.212766,
    shape_pt_lon: -89.915302,
    shape_pt_sequence: 406,
    shape_dist_traveled: 21.2491,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.212889,
    shape_pt_lon: -89.915456,
    shape_pt_sequence: 407,
    shape_dist_traveled: 21.2682,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.213659,
    shape_pt_lon: -89.916607,
    shape_pt_sequence: 408,
    shape_dist_traveled: 21.4032,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.213717,
    shape_pt_lon: -89.916693,
    shape_pt_sequence: 409,
    shape_dist_traveled: 21.4131,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.214029,
    shape_pt_lon: -89.917117,
    shape_pt_sequence: 410,
    shape_dist_traveled: 21.4655,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.214102,
    shape_pt_lon: -89.917201,
    shape_pt_sequence: 411,
    shape_dist_traveled: 21.4761,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.214319,
    shape_pt_lon: -89.917432,
    shape_pt_sequence: 412,
    shape_dist_traveled: 21.508,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.214499,
    shape_pt_lon: -89.917579,
    shape_pt_sequence: 413,
    shape_dist_traveled: 21.5324,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.214749,
    shape_pt_lon: -89.917743,
    shape_pt_sequence: 414,
    shape_dist_traveled: 21.5637,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.21499,
    shape_pt_lon: -89.91786,
    shape_pt_sequence: 415,
    shape_dist_traveled: 21.5929,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.215228,
    shape_pt_lon: -89.917958,
    shape_pt_sequence: 416,
    shape_dist_traveled: 21.6213,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.215548,
    shape_pt_lon: -89.918038,
    shape_pt_sequence: 417,
    shape_dist_traveled: 21.658,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.21588,
    shape_pt_lon: -89.918071,
    shape_pt_sequence: 418,
    shape_dist_traveled: 21.6951,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.217341,
    shape_pt_lon: -89.918046,
    shape_pt_sequence: 419,
    shape_dist_traveled: 21.8581,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.217567,
    shape_pt_lon: -89.918014,
    shape_pt_sequence: 420,
    shape_dist_traveled: 21.8843,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.217846,
    shape_pt_lon: -89.917936,
    shape_pt_sequence: 421,
    shape_dist_traveled: 21.9161,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.218148,
    shape_pt_lon: -89.917797,
    shape_pt_sequence: 422,
    shape_dist_traveled: 21.9521,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.218325,
    shape_pt_lon: -89.917679,
    shape_pt_sequence: 423,
    shape_dist_traveled: 21.9741,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.218623,
    shape_pt_lon: -89.917472,
    shape_pt_sequence: 424,
    shape_dist_traveled: 22.0126,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.218807,
    shape_pt_lon: -89.917377,
    shape_pt_sequence: 425,
    shape_dist_traveled: 22.0345,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.219051,
    shape_pt_lon: -89.917291,
    shape_pt_sequence: 426,
    shape_dist_traveled: 22.0636,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.219306,
    shape_pt_lon: -89.917243,
    shape_pt_sequence: 427,
    shape_dist_traveled: 22.0919,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.219489,
    shape_pt_lon: -89.917234,
    shape_pt_sequence: 428,
    shape_dist_traveled: 22.1129,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221009,
    shape_pt_lon: -89.917198,
    shape_pt_sequence: 429,
    shape_dist_traveled: 22.283,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221831,
    shape_pt_lon: -89.917172,
    shape_pt_sequence: 430,
    shape_dist_traveled: 22.375,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221818,
    shape_pt_lon: -89.915564,
    shape_pt_sequence: 431,
    shape_dist_traveled: 22.52,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221808,
    shape_pt_lon: -89.914868,
    shape_pt_sequence: 432,
    shape_dist_traveled: 22.583,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.22179,
    shape_pt_lon: -89.913722,
    shape_pt_sequence: 433,
    shape_dist_traveled: 22.686,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221776,
    shape_pt_lon: -89.912561,
    shape_pt_sequence: 434,
    shape_dist_traveled: 22.791,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.22176,
    shape_pt_lon: -89.911377,
    shape_pt_sequence: 435,
    shape_dist_traveled: 22.898,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221751,
    shape_pt_lon: -89.910988,
    shape_pt_sequence: 436,
    shape_dist_traveled: 22.933,
  },
  {
    shape_id: 38233,
    shape_pt_lat: 35.221735,
    shape_pt_lon: -89.910006,
    shape_pt_sequence: 437,
    shape_dist_traveled: 23.0221,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221751,
    shape_pt_lon: -89.910988,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221735,
    shape_pt_lon: -89.910006,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.089,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221717,
    shape_pt_lon: -89.909378,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1451,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221677,
    shape_pt_lon: -89.909301,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1531,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.22158,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1717,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221492,
    shape_pt_lon: -89.909083,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1829,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.221249,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2099,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.220387,
    shape_pt_lon: -89.909927,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3338,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.219228,
    shape_pt_lon: -89.911078,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.5002,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.218346,
    shape_pt_lon: -89.911954,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6261,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.218097,
    shape_pt_lon: -89.912202,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6617,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.217299,
    shape_pt_lon: -89.91299,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7764,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.217119,
    shape_pt_lon: -89.91317,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.802,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.215377,
    shape_pt_lon: -89.914903,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.0517,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.214214,
    shape_pt_lon: -89.916057,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.2182,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.213659,
    shape_pt_lon: -89.916607,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.2986,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.212889,
    shape_pt_lon: -89.915456,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.4336,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.212766,
    shape_pt_lon: -89.915302,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.4527,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.21253,
    shape_pt_lon: -89.915066,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.4875,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.212316,
    shape_pt_lon: -89.914898,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.5158,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.212082,
    shape_pt_lon: -89.914759,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.5444,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.211851,
    shape_pt_lon: -89.914663,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.572,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.211663,
    shape_pt_lon: -89.91461,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.5935,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.211415,
    shape_pt_lon: -89.91457,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.6208,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.211108,
    shape_pt_lon: -89.914564,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.6558,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.210099,
    shape_pt_lon: -89.914603,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.7689,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.209302,
    shape_pt_lon: -89.914632,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.8579,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.20853,
    shape_pt_lon: -89.914661,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.9439,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.207909,
    shape_pt_lon: -89.914675,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.014,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.20763,
    shape_pt_lon: -89.914644,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.0451,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.20742,
    shape_pt_lon: -89.9146,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.0684,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.207223,
    shape_pt_lon: -89.914536,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.091,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.207108,
    shape_pt_lon: -89.914489,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.1049,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.205913,
    shape_pt_lon: -89.913951,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.2476,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.205853,
    shape_pt_lon: -89.913923,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.2549,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.205775,
    shape_pt_lon: -89.913887,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.2634,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.205329,
    shape_pt_lon: -89.913692,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.3166,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.205046,
    shape_pt_lon: -89.913597,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.3498,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.204631,
    shape_pt_lon: -89.913508,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.3965,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.203852,
    shape_pt_lon: -89.913448,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.4846,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.20293,
    shape_pt_lon: -89.913376,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.5879,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.202883,
    shape_pt_lon: -89.913371,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.5929,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.202531,
    shape_pt_lon: -89.913316,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.6322,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.202161,
    shape_pt_lon: -89.913212,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.6754,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.201739,
    shape_pt_lon: -89.913032,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.725,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.201602,
    shape_pt_lon: -89.912959,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.7416,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.201331,
    shape_pt_lon: -89.912794,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.776,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.200902,
    shape_pt_lon: -89.912471,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.8321,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.200776,
    shape_pt_lon: -89.912356,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.8493,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.200513,
    shape_pt_lon: -89.912068,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.8889,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.20041,
    shape_pt_lon: -89.911933,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.9052,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.200222,
    shape_pt_lon: -89.911685,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.9356,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.199999,
    shape_pt_lon: -89.911329,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9762,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.199801,
    shape_pt_lon: -89.91095,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.0176,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.199567,
    shape_pt_lon: -89.910478,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0675,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.19883,
    shape_pt_lon: -89.908989,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.2255,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198638,
    shape_pt_lon: -89.90853,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2715,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198532,
    shape_pt_lon: -89.908203,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.3038,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198487,
    shape_pt_lon: -89.90803,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.3196,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.19843,
    shape_pt_lon: -89.907798,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.3415,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198359,
    shape_pt_lon: -89.907381,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3803,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198316,
    shape_pt_lon: -89.906857,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.4276,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198279,
    shape_pt_lon: -89.905853,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.5187,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198221,
    shape_pt_lon: -89.905417,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.5581,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198181,
    shape_pt_lon: -89.905206,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5778,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.198069,
    shape_pt_lon: -89.904798,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6167,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197947,
    shape_pt_lon: -89.904446,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.6516,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197799,
    shape_pt_lon: -89.904096,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.6874,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197643,
    shape_pt_lon: -89.903812,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.7182,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197499,
    shape_pt_lon: -89.90358,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.7446,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197296,
    shape_pt_lon: -89.903298,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.7793,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.197029,
    shape_pt_lon: -89.90299,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.8196,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.196658,
    shape_pt_lon: -89.902618,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.873,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.196453,
    shape_pt_lon: -89.902408,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.9029,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.196178,
    shape_pt_lon: -89.902064,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.9474,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.196159,
    shape_pt_lon: -89.902037,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.9502,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195903,
    shape_pt_lon: -89.90164,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9958,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195829,
    shape_pt_lon: -89.901507,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0108,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195649,
    shape_pt_lon: -89.90111,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.052,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195479,
    shape_pt_lon: -89.900682,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.0945,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195376,
    shape_pt_lon: -89.900398,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.1227,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195298,
    shape_pt_lon: -89.900188,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.1438,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.195077,
    shape_pt_lon: -89.899619,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.201,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.194915,
    shape_pt_lon: -89.899174,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.2449,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.194599,
    shape_pt_lon: -89.898341,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.3281,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.194418,
    shape_pt_lon: -89.897916,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.371,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.194205,
    shape_pt_lon: -89.897451,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.4194,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.193529,
    shape_pt_lon: -89.89603,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.5686,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.193366,
    shape_pt_lon: -89.895679,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.6053,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.19153,
    shape_pt_lon: -89.895847,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.8119,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.191143,
    shape_pt_lon: -89.895882,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.855,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.18918,
    shape_pt_lon: -89.896068,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.0756,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.188763,
    shape_pt_lon: -89.896106,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.1217,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.188343,
    shape_pt_lon: -89.896143,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.1688,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.187961,
    shape_pt_lon: -89.896179,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.2119,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.187006,
    shape_pt_lon: -89.896269,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.3192,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.185949,
    shape_pt_lon: -89.896356,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.4375,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.185851,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.4496,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.185733,
    shape_pt_lon: -89.896302,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.4626,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.184549,
    shape_pt_lon: -89.896342,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.5956,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.183762,
    shape_pt_lon: -89.896358,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.6837,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.183397,
    shape_pt_lon: -89.896371,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.7247,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.182612,
    shape_pt_lon: -89.896381,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.8127,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.180463,
    shape_pt_lon: -89.896418,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.0527,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.179936,
    shape_pt_lon: -89.89643,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.1117,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.179154,
    shape_pt_lon: -89.896444,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.1997,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.17853,
    shape_pt_lon: -89.89646,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.2687,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.177907,
    shape_pt_lon: -89.896501,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.3388,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.177261,
    shape_pt_lon: -89.896582,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.4122,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.177197,
    shape_pt_lon: -89.896594,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.4193,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.176549,
    shape_pt_lon: -89.89673,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.4923,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.176067,
    shape_pt_lon: -89.896865,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.5476,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.1758,
    shape_pt_lon: -89.896949,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.5784,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.175739,
    shape_pt_lon: -89.89697,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.5857,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.175687,
    shape_pt_lon: -89.896989,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.592,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.175667,
    shape_pt_lon: -89.896996,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.5942,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.897023,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.6035,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.17527,
    shape_pt_lon: -89.897142,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.6402,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.175056,
    shape_pt_lon: -89.897218,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.6652,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.174453,
    shape_pt_lon: -89.8975,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.7376,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.174127,
    shape_pt_lon: -89.897689,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.7774,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.174043,
    shape_pt_lon: -89.897811,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.7923,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.173732,
    shape_pt_lon: -89.897997,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.8312,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.173269,
    shape_pt_lon: -89.898298,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.8898,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.172948,
    shape_pt_lon: -89.898533,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.9306,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.172748,
    shape_pt_lon: -89.898682,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.957,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.17232,
    shape_pt_lon: -89.899037,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.0147,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.172273,
    shape_pt_lon: -89.899077,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.0211,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.171769,
    shape_pt_lon: -89.899549,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.0919,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.171402,
    shape_pt_lon: -89.89993,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.1458,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.171152,
    shape_pt_lon: -89.900197,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.1827,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.170458,
    shape_pt_lon: -89.900955,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.2854,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.17025,
    shape_pt_lon: -89.90117,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.3167,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.169701,
    shape_pt_lon: -89.901796,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.3995,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.169216,
    shape_pt_lon: -89.902352,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.4738,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.169135,
    shape_pt_lon: -89.902446,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.4865,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.169061,
    shape_pt_lon: -89.902528,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.4972,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.168977,
    shape_pt_lon: -89.902627,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.5099,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.168641,
    shape_pt_lon: -89.902941,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.5571,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.168489,
    shape_pt_lon: -89.903108,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.5798,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16808,
    shape_pt_lon: -89.903566,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.6421,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16788,
    shape_pt_lon: -89.903813,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.6732,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.167509,
    shape_pt_lon: -89.904219,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.7285,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.167126,
    shape_pt_lon: -89.904634,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.7859,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16686,
    shape_pt_lon: -89.904896,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.8237,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.166581,
    shape_pt_lon: -89.90513,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.8617,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.166301,
    shape_pt_lon: -89.905322,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.897,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.166147,
    shape_pt_lon: -89.905416,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.9167,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.165785,
    shape_pt_lon: -89.905588,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.9598,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.165549,
    shape_pt_lon: -89.905672,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.9877,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.165332,
    shape_pt_lon: -89.90573,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.0122,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.165101,
    shape_pt_lon: -89.905777,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.0387,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.164616,
    shape_pt_lon: -89.905821,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.0929,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.163549,
    shape_pt_lon: -89.905849,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.2119,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16208,
    shape_pt_lon: -89.905877,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.3769,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161979,
    shape_pt_lon: -89.905817,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.389,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161872,
    shape_pt_lon: -89.905812,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.401,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161043,
    shape_pt_lon: -89.905835,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.494,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160922,
    shape_pt_lon: -89.905838,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.507,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -89.905897,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.513,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.907441,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.6521,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160997,
    shape_pt_lon: -89.908156,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.7172,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161014,
    shape_pt_lon: -89.90834,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.7333,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161062,
    shape_pt_lon: -89.908518,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.7501,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161125,
    shape_pt_lon: -89.908647,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.764,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161212,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.7788,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161278,
    shape_pt_lon: -89.908831,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.7881,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161559,
    shape_pt_lon: -89.909049,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.8249,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161659,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.8394,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161688,
    shape_pt_lon: -89.909169,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.843,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161777,
    shape_pt_lon: -89.9093,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.8586,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161829,
    shape_pt_lon: -89.909402,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.8694,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161885,
    shape_pt_lon: -89.909597,
    shape_pt_sequence: 173,
    shape_dist_traveled: 8.8884,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.1619,
    shape_pt_lon: -89.909809,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.9074,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161922,
    shape_pt_lon: -89.911276,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.0404,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161949,
    shape_pt_lon: -89.913225,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.2165,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161969,
    shape_pt_lon: -89.914411,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.3235,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16197,
    shape_pt_lon: -89.914561,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.3375,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161987,
    shape_pt_lon: -89.91556,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.4275,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16202,
    shape_pt_lon: -89.91734,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.5885,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.161129,
    shape_pt_lon: -89.917356,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.6886,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160969,
    shape_pt_lon: -89.917357,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.7066,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160801,
    shape_pt_lon: -89.917326,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.7258,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160612,
    shape_pt_lon: -89.917245,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.7479,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160456,
    shape_pt_lon: -89.917132,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.7682,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.160325,
    shape_pt_lon: -89.916999,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.7874,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.16,
    shape_pt_lon: -89.91653,
    shape_pt_sequence: 187,
    shape_dist_traveled: 9.8427,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.159522,
    shape_pt_lon: -89.915821,
    shape_pt_sequence: 188,
    shape_dist_traveled: 9.9264,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.159467,
    shape_pt_lon: -89.915754,
    shape_pt_sequence: 189,
    shape_dist_traveled: 9.9349,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.159345,
    shape_pt_lon: -89.915653,
    shape_pt_sequence: 190,
    shape_dist_traveled: 9.9507,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.15923,
    shape_pt_lon: -89.915601,
    shape_pt_sequence: 191,
    shape_dist_traveled: 9.9647,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.159107,
    shape_pt_lon: -89.915582,
    shape_pt_sequence: 192,
    shape_dist_traveled: 9.9788,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.158611,
    shape_pt_lon: -89.915588,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.0338,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.157676,
    shape_pt_lon: -89.915618,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.1388,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.157359,
    shape_pt_lon: -89.91563,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.1748,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.156739,
    shape_pt_lon: -89.915649,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.2439,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.155821,
    shape_pt_lon: -89.915683,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.3469,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.155527,
    shape_pt_lon: -89.915694,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.3799,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.154941,
    shape_pt_lon: -89.915711,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.4449,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.154213,
    shape_pt_lon: -89.915726,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.5269,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.154106,
    shape_pt_lon: -89.915728,
    shape_pt_sequence: 201,
    shape_dist_traveled: 10.539,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.153209,
    shape_pt_lon: -89.915757,
    shape_pt_sequence: 202,
    shape_dist_traveled: 10.639,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.152409,
    shape_pt_lon: -89.91578,
    shape_pt_sequence: 203,
    shape_dist_traveled: 10.728,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.152373,
    shape_pt_lon: -89.915781,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.732,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.151347,
    shape_pt_lon: -89.915819,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.8471,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.148959,
    shape_pt_lon: -89.916013,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.1156,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.14888,
    shape_pt_lon: -89.916018,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.1237,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.148593,
    shape_pt_lon: -89.916031,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.1557,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.14811,
    shape_pt_lon: -89.916046,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.2107,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.147827,
    shape_pt_lon: -89.916022,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.2418,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.147303,
    shape_pt_lon: -89.916037,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.3008,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.146003,
    shape_pt_lon: -89.916092,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.4459,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.14578,
    shape_pt_lon: -89.916101,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.4709,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.14568,
    shape_pt_lon: -89.916107,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.4819,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.145572,
    shape_pt_lon: -89.916111,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.4949,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.145404,
    shape_pt_lon: -89.916118,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.5129,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.145249,
    shape_pt_lon: -89.916106,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.5309,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.144957,
    shape_pt_lon: -89.916035,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.5635,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.144678,
    shape_pt_lon: -89.915977,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.5961,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.144512,
    shape_pt_lon: -89.915967,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.6141,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.144306,
    shape_pt_lon: -89.915987,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.6372,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.144033,
    shape_pt_lon: -89.916058,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.6687,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.143886,
    shape_pt_lon: -89.916083,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.685,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.143807,
    shape_pt_lon: -89.916091,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.694,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.143579,
    shape_pt_lon: -89.916081,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.72,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.143405,
    shape_pt_lon: -89.916038,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.7393,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.143173,
    shape_pt_lon: -89.915996,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.7656,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.142951,
    shape_pt_lon: -89.91599,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.7906,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.14264,
    shape_pt_lon: -89.916009,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.8257,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.142228,
    shape_pt_lon: -89.916097,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.8724,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.141874,
    shape_pt_lon: -89.916121,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.9114,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.140656,
    shape_pt_lon: -89.916159,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.0474,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.140479,
    shape_pt_lon: -89.916164,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.0664,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.139612,
    shape_pt_lon: -89.916197,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.1635,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.139473,
    shape_pt_lon: -89.9162,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.1795,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.137922,
    shape_pt_lon: -89.916251,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.3536,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.137817,
    shape_pt_lon: -89.916256,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.3646,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -89.916264,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.3976,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13734,
    shape_pt_lon: -89.91627,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.4186,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.136281,
    shape_pt_lon: -89.916306,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.5366,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13572,
    shape_pt_lon: -89.916326,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.5997,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13563,
    shape_pt_lon: -89.916329,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.6097,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.134746,
    shape_pt_lon: -89.916359,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.7087,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.134538,
    shape_pt_lon: -89.916368,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.7317,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.1345,
    shape_pt_lon: -89.916369,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.7367,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.134039,
    shape_pt_lon: -89.916383,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.7877,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13353,
    shape_pt_lon: -89.916398,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.8447,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.132702,
    shape_pt_lon: -89.916427,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.9378,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13244,
    shape_pt_lon: -89.916434,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.9668,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131739,
    shape_pt_lon: -89.91645,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.0448,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13129,
    shape_pt_lon: -89.916459,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.0958,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131267,
    shape_pt_lon: -89.915488,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.1828,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131243,
    shape_pt_lon: -89.914567,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.2669,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131239,
    shape_pt_lon: -89.914353,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.2859,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131223,
    shape_pt_lon: -89.913616,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.3519,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131222,
    shape_pt_lon: -89.913562,
    shape_pt_sequence: 256,
    shape_dist_traveled: 13.3569,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131207,
    shape_pt_lon: -89.912933,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.4139,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131197,
    shape_pt_lon: -89.912507,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.453,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.13119,
    shape_pt_lon: -89.912241,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.477,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131187,
    shape_pt_lon: -89.912047,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.494,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.911533,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.541,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131157,
    shape_pt_lon: -89.910682,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.618,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131122,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.7521,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.131047,
    shape_pt_lon: -89.908933,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.7777,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130999,
    shape_pt_lon: -89.908827,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.788,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130946,
    shape_pt_lon: -89.908776,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.7958,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130909,
    shape_pt_lon: -89.908752,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.8003,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130813,
    shape_pt_lon: -89.908739,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.8113,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130659,
    shape_pt_lon: -89.908752,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.8284,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130312,
    shape_pt_lon: -89.90871,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.8676,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.130102,
    shape_pt_lon: -89.908731,
    shape_pt_sequence: 271,
    shape_dist_traveled: 13.8917,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.128486,
    shape_pt_lon: -89.90876,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.0717,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.127804,
    shape_pt_lon: -89.908771,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.1487,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.127593,
    shape_pt_lon: -89.908783,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.1717,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.127422,
    shape_pt_lon: -89.90881,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.1908,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.127182,
    shape_pt_lon: -89.908888,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.2187,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.126831,
    shape_pt_lon: -89.909027,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.2607,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.126493,
    shape_pt_lon: -89.909115,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.2986,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.126437,
    shape_pt_lon: -89.909123,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.3057,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.126146,
    shape_pt_lon: -89.90915,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.3377,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.125559,
    shape_pt_lon: -89.909168,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.4037,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.124698,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.4998,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.123656,
    shape_pt_lon: -89.909219,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.6168,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.121652,
    shape_pt_lon: -89.909266,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.8408,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.121389,
    shape_pt_lon: -89.909223,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.8711,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.121167,
    shape_pt_lon: -89.909138,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.8964,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.121063,
    shape_pt_lon: -89.90907,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.9098,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.120947,
    shape_pt_lon: -89.908992,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.9246,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.120664,
    shape_pt_lon: -89.908746,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.964,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.120519,
    shape_pt_lon: -89.908648,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.9819,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.120302,
    shape_pt_lon: -89.908559,
    shape_pt_sequence: 291,
    shape_dist_traveled: 15.0075,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.12014,
    shape_pt_lon: -89.90852,
    shape_pt_sequence: 292,
    shape_dist_traveled: 15.0258,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.119911,
    shape_pt_lon: -89.908506,
    shape_pt_sequence: 293,
    shape_dist_traveled: 15.0518,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11807,
    shape_pt_lon: -89.908554,
    shape_pt_sequence: 294,
    shape_dist_traveled: 15.2578,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.117839,
    shape_pt_lon: -89.908588,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.284,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.117748,
    shape_pt_lon: -89.908616,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.2942,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.117079,
    shape_pt_lon: -89.908961,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.3753,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.116841,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.4032,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.115937,
    shape_pt_lon: -89.909407,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.5095,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.115629,
    shape_pt_lon: -89.909488,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.5445,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.115523,
    shape_pt_lon: -89.909507,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.5565,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.115279,
    shape_pt_lon: -89.909546,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.5838,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.115101,
    shape_pt_lon: -89.909562,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.6038,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.114817,
    shape_pt_lon: -89.90956,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.6358,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.114612,
    shape_pt_lon: -89.909525,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.659,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.114431,
    shape_pt_lon: -89.909472,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.6796,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11423,
    shape_pt_lon: -89.909394,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.7037,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.114048,
    shape_pt_lon: -89.909298,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.7256,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.113787,
    shape_pt_lon: -89.909118,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.7587,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.113583,
    shape_pt_lon: -89.908937,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.7873,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11343,
    shape_pt_lon: -89.908769,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.81,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.113294,
    shape_pt_lon: -89.908589,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.8319,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.112822,
    shape_pt_lon: -89.907901,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.9135,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11272,
    shape_pt_lon: -89.907794,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.9284,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.112558,
    shape_pt_lon: -89.907678,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.9494,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.112374,
    shape_pt_lon: -89.907604,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.9713,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.112308,
    shape_pt_lon: -89.907593,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.9784,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.112038,
    shape_pt_lon: -89.907591,
    shape_pt_sequence: 318,
    shape_dist_traveled: 16.0094,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.111261,
    shape_pt_lon: -89.90763,
    shape_pt_sequence: 319,
    shape_dist_traveled: 16.0965,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11113,
    shape_pt_lon: -89.907638,
    shape_pt_sequence: 320,
    shape_dist_traveled: 16.1105,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.110847,
    shape_pt_lon: -89.907651,
    shape_pt_sequence: 321,
    shape_dist_traveled: 16.1425,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.11001,
    shape_pt_lon: -89.907694,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.2366,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.109478,
    shape_pt_lon: -89.907721,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.2957,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.109004,
    shape_pt_lon: -89.907745,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.3487,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.108919,
    shape_pt_lon: -89.907749,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.3587,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.108422,
    shape_pt_lon: -89.907773,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.4137,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.108127,
    shape_pt_lon: -89.907789,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.4468,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.107013,
    shape_pt_lon: -89.907858,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.5719,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.10691,
    shape_pt_lon: -89.907861,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.5829,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.106853,
    shape_pt_lon: -89.907867,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.5899,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.105884,
    shape_pt_lon: -89.907917,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.698,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.105822,
    shape_pt_lon: -89.907911,
    shape_pt_sequence: 332,
    shape_dist_traveled: 16.7051,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.105699,
    shape_pt_lon: -89.907887,
    shape_pt_sequence: 333,
    shape_dist_traveled: 16.7193,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.105088,
    shape_pt_lon: -89.907673,
    shape_pt_sequence: 334,
    shape_dist_traveled: 16.7901,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.10479,
    shape_pt_lon: -89.907556,
    shape_pt_sequence: 335,
    shape_dist_traveled: 16.8256,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.10456,
    shape_pt_lon: -89.907409,
    shape_pt_sequence: 336,
    shape_dist_traveled: 16.8538,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.104329,
    shape_pt_lon: -89.90722,
    shape_pt_sequence: 337,
    shape_dist_traveled: 16.8854,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.104147,
    shape_pt_lon: -89.907068,
    shape_pt_sequence: 338,
    shape_dist_traveled: 16.9092,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.103421,
    shape_pt_lon: -89.906596,
    shape_pt_sequence: 339,
    shape_dist_traveled: 17.0018,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.103309,
    shape_pt_lon: -89.906561,
    shape_pt_sequence: 340,
    shape_dist_traveled: 17.0142,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.10275,
    shape_pt_lon: -89.906556,
    shape_pt_sequence: 341,
    shape_dist_traveled: 17.0772,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.1006,
    shape_pt_lon: -89.906627,
    shape_pt_sequence: 342,
    shape_dist_traveled: 17.3173,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.096527,
    shape_pt_lon: -89.906767,
    shape_pt_sequence: 343,
    shape_dist_traveled: 17.7734,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.095612,
    shape_pt_lon: -89.906799,
    shape_pt_sequence: 344,
    shape_dist_traveled: 17.8755,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.093557,
    shape_pt_lon: -89.906873,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.1056,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.092645,
    shape_pt_lon: -89.906903,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.2076,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.091417,
    shape_pt_lon: -89.906945,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.3456,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.090327,
    shape_pt_lon: -89.906983,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.4677,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.0894,
    shape_pt_lon: -89.907021,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.5718,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.088489,
    shape_pt_lon: -89.907052,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.6738,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.087573,
    shape_pt_lon: -89.907089,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.7758,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08665,
    shape_pt_lon: -89.907123,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.8789,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085693,
    shape_pt_lon: -89.907154,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.9859,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085715,
    shape_pt_lon: -89.908168,
    shape_pt_sequence: 354,
    shape_dist_traveled: 19.0779,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085737,
    shape_pt_lon: -89.909132,
    shape_pt_sequence: 355,
    shape_dist_traveled: 19.1649,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085772,
    shape_pt_lon: -89.910898,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.324,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085786,
    shape_pt_lon: -89.911918,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.417,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085797,
    shape_pt_lon: -89.913479,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.558,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085846,
    shape_pt_lon: -89.916836,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.861,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085904,
    shape_pt_lon: -89.918917,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.0491,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08593,
    shape_pt_lon: -89.919805,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.1302,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085942,
    shape_pt_lon: -89.920291,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.1742,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085959,
    shape_pt_lon: -89.920493,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.1923,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085995,
    shape_pt_lon: -89.920661,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.2078,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086138,
    shape_pt_lon: -89.921077,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.249,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086192,
    shape_pt_lon: -89.92136,
    shape_pt_sequence: 366,
    shape_dist_traveled: 20.2747,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086369,
    shape_pt_lon: -89.92369,
    shape_pt_sequence: 367,
    shape_dist_traveled: 20.4867,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08664,
    shape_pt_lon: -89.927339,
    shape_pt_sequence: 368,
    shape_dist_traveled: 20.818,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086679,
    shape_pt_lon: -89.929174,
    shape_pt_sequence: 369,
    shape_dist_traveled: 20.9841,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086684,
    shape_pt_lon: -89.92945,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.0091,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086689,
    shape_pt_lon: -89.929684,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.0301,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08671,
    shape_pt_lon: -89.9306,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.1131,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086738,
    shape_pt_lon: -89.931175,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.1652,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086616,
    shape_pt_lon: -89.931177,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.1792,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.086506,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.1912,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08637,
    shape_pt_lon: -89.931181,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.2062,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.085138,
    shape_pt_lon: -89.931213,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.3443,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.084279,
    shape_pt_lon: -89.931232,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.4403,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.083607,
    shape_pt_lon: -89.931248,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.5153,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.083181,
    shape_pt_lon: -89.93126,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.5633,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.082578,
    shape_pt_lon: -89.931271,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.6303,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.082388,
    shape_pt_lon: -89.931275,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.6523,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08211,
    shape_pt_lon: -89.931279,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.6833,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.082012,
    shape_pt_lon: -89.931281,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.6943,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.081456,
    shape_pt_lon: -89.931296,
    shape_pt_sequence: 385,
    shape_dist_traveled: 21.7563,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.080432,
    shape_pt_lon: -89.93131,
    shape_pt_sequence: 386,
    shape_dist_traveled: 21.8703,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.08024,
    shape_pt_lon: -89.931312,
    shape_pt_sequence: 387,
    shape_dist_traveled: 21.8923,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.080086,
    shape_pt_lon: -89.931315,
    shape_pt_sequence: 388,
    shape_dist_traveled: 21.9094,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.079997,
    shape_pt_lon: -89.931318,
    shape_pt_sequence: 389,
    shape_dist_traveled: 21.9194,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.079879,
    shape_pt_lon: -89.931323,
    shape_pt_sequence: 390,
    shape_dist_traveled: 21.9324,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.079841,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 391,
    shape_dist_traveled: 21.9374,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.079727,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 392,
    shape_dist_traveled: 21.9494,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.079646,
    shape_pt_lon: -89.931326,
    shape_pt_sequence: 393,
    shape_dist_traveled: 21.9584,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.078431,
    shape_pt_lon: -89.931361,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.0944,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.077837,
    shape_pt_lon: -89.931396,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.1615,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.077769,
    shape_pt_lon: -89.931401,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.1685,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.077433,
    shape_pt_lon: -89.931435,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.2066,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.07711,
    shape_pt_lon: -89.931468,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.2428,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076752,
    shape_pt_lon: -89.931603,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.2845,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076651,
    shape_pt_lon: -89.93165,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.2972,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076568,
    shape_pt_lon: -89.931743,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.3099,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076521,
    shape_pt_lon: -89.931842,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.3202,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.07651,
    shape_pt_lon: -89.931886,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.3243,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076491,
    shape_pt_lon: -89.931999,
    shape_pt_sequence: 404,
    shape_dist_traveled: 22.3348,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 405,
    shape_dist_traveled: 22.3719,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 406,
    shape_dist_traveled: 22.447,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 407,
    shape_dist_traveled: 22.456,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 408,
    shape_dist_traveled: 22.4618,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 409,
    shape_dist_traveled: 22.4704,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 410,
    shape_dist_traveled: 22.4834,
  },
  {
    shape_id: 38234,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 411,
    shape_dist_traveled: 22.5444,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2681,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3762,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4351,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4613,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4948,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.554,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6159,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6726,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7001,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7466,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8085,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8468,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9165,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0104,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1163,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1414,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2265,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3323,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.4443,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.135843,
    shape_pt_lon: -90.056147,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5321,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.135726,
    shape_pt_lon: -90.056195,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5461,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.134526,
    shape_pt_lon: -90.05639,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6821,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.134034,
    shape_pt_lon: -90.056461,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7376,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8786,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.13275,
    shape_pt_lon: -90.055818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9407,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.132744,
    shape_pt_lon: -90.055183,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9987,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0497,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1388,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.13154,
    shape_pt_lon: -90.054672,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1838,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.130348,
    shape_pt_lon: -90.054718,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.3168,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.129169,
    shape_pt_lon: -90.054757,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.4489,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.128066,
    shape_pt_lon: -90.054793,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.5729,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.126931,
    shape_pt_lon: -90.054838,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.7,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.126867,
    shape_pt_lon: -90.05484,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.707,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.126261,
    shape_pt_lon: -90.054858,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.775,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.125658,
    shape_pt_lon: -90.054886,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.8421,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.125593,
    shape_pt_lon: -90.054889,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.8491,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -90.054901,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.9081,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.124617,
    shape_pt_lon: -90.054916,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.9582,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.124573,
    shape_pt_lon: -90.054917,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.9632,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.123659,
    shape_pt_lon: -90.054948,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.0662,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.122803,
    shape_pt_lon: -90.054979,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.1612,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.122101,
    shape_pt_lon: -90.055003,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.2403,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.121681,
    shape_pt_lon: -90.055019,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.2873,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.119833,
    shape_pt_lon: -90.055083,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.4944,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.118579,
    shape_pt_lon: -90.055128,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.6344,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.117617,
    shape_pt_lon: -90.055161,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.7425,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.117259,
    shape_pt_lon: -90.055177,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.7825,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.116955,
    shape_pt_lon: -90.055187,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.8165,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.116041,
    shape_pt_lon: -90.055212,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.9185,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.115156,
    shape_pt_lon: -90.055223,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.0175,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.114328,
    shape_pt_lon: -90.055232,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.1105,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.113437,
    shape_pt_lon: -90.055242,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.2095,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.112452,
    shape_pt_lon: -90.055249,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.3206,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.112015,
    shape_pt_lon: -90.055256,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.3696,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.10959,
    shape_pt_lon: -90.055289,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.6406,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.108791,
    shape_pt_lon: -90.055316,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.7296,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.107876,
    shape_pt_lon: -90.055319,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.8326,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.107692,
    shape_pt_lon: -90.055327,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.8526,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -90.05533,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.8646,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.107507,
    shape_pt_lon: -90.055332,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.8736,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.10574,
    shape_pt_lon: -90.055395,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.0717,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.104183,
    shape_pt_lon: -90.055448,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.2458,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.10395,
    shape_pt_lon: -90.055456,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.2718,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.101561,
    shape_pt_lon: -90.055535,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.5389,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.101481,
    shape_pt_lon: -90.055538,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.548,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.101385,
    shape_pt_lon: -90.055617,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.561,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.100262,
    shape_pt_lon: -90.055674,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.6871,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.096869,
    shape_pt_lon: -90.05578,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.0662,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.09623,
    shape_pt_lon: -90.055837,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1384,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.096187,
    shape_pt_lon: -90.055841,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.1434,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.092937,
    shape_pt_lon: -90.056179,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.5077,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.09249,
    shape_pt_lon: -90.056224,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.5579,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.091541,
    shape_pt_lon: -90.056323,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.6653,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.09054,
    shape_pt_lon: -90.056426,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.7776,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.089539,
    shape_pt_lon: -90.05653,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.89,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.088531,
    shape_pt_lon: -90.056633,
    shape_pt_sequence: 105,
    shape_dist_traveled: 8.0024,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.087533,
    shape_pt_lon: -90.056741,
    shape_pt_sequence: 106,
    shape_dist_traveled: 8.1148,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.08714,
    shape_pt_lon: -90.056779,
    shape_pt_sequence: 107,
    shape_dist_traveled: 8.159,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.087059,
    shape_pt_lon: -90.056788,
    shape_pt_sequence: 108,
    shape_dist_traveled: 8.168,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.086531,
    shape_pt_lon: -90.056843,
    shape_pt_sequence: 109,
    shape_dist_traveled: 8.2272,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.08457,
    shape_pt_lon: -90.057046,
    shape_pt_sequence: 110,
    shape_dist_traveled: 8.448,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.084104,
    shape_pt_lon: -90.057099,
    shape_pt_sequence: 111,
    shape_dist_traveled: 8.5002,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.083999,
    shape_pt_lon: -90.05711,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.5113,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.082477,
    shape_pt_lon: -90.05726,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.6828,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.082371,
    shape_pt_lon: -90.057271,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.6948,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.082336,
    shape_pt_lon: -90.057276,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.6989,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.082206,
    shape_pt_lon: -90.05729,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.713,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.082136,
    shape_pt_lon: -90.057297,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.721,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.08112,
    shape_pt_lon: -90.057402,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.8354,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.080756,
    shape_pt_lon: -90.057426,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.8754,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.079797,
    shape_pt_lon: -90.057403,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.9835,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.079732,
    shape_pt_lon: -90.057399,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.9905,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.079479,
    shape_pt_lon: -90.057385,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.0185,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07939,
    shape_pt_lon: -90.057378,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.0285,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.079332,
    shape_pt_lon: -90.057376,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.0355,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07911,
    shape_pt_lon: -90.057361,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.0596,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.078697,
    shape_pt_lon: -90.057349,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.1066,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07845,
    shape_pt_lon: -90.057356,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.1336,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.078121,
    shape_pt_lon: -90.057407,
    shape_pt_sequence: 128,
    shape_dist_traveled: 9.1709,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.077812,
    shape_pt_lon: -90.05748,
    shape_pt_sequence: 129,
    shape_dist_traveled: 9.2066,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07719,
    shape_pt_lon: -90.057683,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.2779,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.076912,
    shape_pt_lon: -90.057753,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.3096,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.076606,
    shape_pt_lon: -90.057807,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.345,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.076247,
    shape_pt_lon: -90.057844,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.3851,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.075771,
    shape_pt_lon: -90.057893,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.4383,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.075223,
    shape_pt_lon: -90.057953,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.4996,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.074887,
    shape_pt_lon: -90.057987,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.5377,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.074542,
    shape_pt_lon: -90.05802,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.5768,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07431,
    shape_pt_lon: -90.058047,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.6029,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.07333,
    shape_pt_lon: -90.058181,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.7125,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.073204,
    shape_pt_lon: -90.05812,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.7274,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.071959,
    shape_pt_lon: -90.058321,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.8685,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.4894,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.6761,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.8125,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.8617,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.061676,
    shape_pt_lon: -90.059343,
    shape_pt_sequence: 146,
    shape_dist_traveled: 11.0222,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.060576,
    shape_pt_lon: -90.05946,
    shape_pt_sequence: 147,
    shape_dist_traveled: 11.1457,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.059309,
    shape_pt_lon: -90.05959,
    shape_pt_sequence: 148,
    shape_dist_traveled: 11.2882,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.05787,
    shape_pt_lon: -90.059736,
    shape_pt_sequence: 149,
    shape_dist_traveled: 11.4497,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.056792,
    shape_pt_lon: -90.05986,
    shape_pt_sequence: 150,
    shape_dist_traveled: 11.5712,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.056303,
    shape_pt_lon: -90.059931,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.6256,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.055334,
    shape_pt_lon: -90.060097,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.7356,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.055262,
    shape_pt_lon: -90.060111,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.7438,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.055212,
    shape_pt_lon: -90.060121,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.7489,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.054388,
    shape_pt_lon: -90.060303,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.8433,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.053431,
    shape_pt_lon: -90.06054,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.9524,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.05247,
    shape_pt_lon: -90.060823,
    shape_pt_sequence: 157,
    shape_dist_traveled: 12.0625,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.051507,
    shape_pt_lon: -90.061143,
    shape_pt_sequence: 158,
    shape_dist_traveled: 12.1743,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.050452,
    shape_pt_lon: -90.061533,
    shape_pt_sequence: 159,
    shape_dist_traveled: 12.2974,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.049379,
    shape_pt_lon: -90.06199,
    shape_pt_sequence: 160,
    shape_dist_traveled: 12.4245,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.048306,
    shape_pt_lon: -90.062499,
    shape_pt_sequence: 161,
    shape_dist_traveled: 12.553,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.047538,
    shape_pt_lon: -90.0629,
    shape_pt_sequence: 162,
    shape_dist_traveled: 12.6463,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.046798,
    shape_pt_lon: -90.063308,
    shape_pt_sequence: 163,
    shape_dist_traveled: 12.7371,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.045719,
    shape_pt_lon: -90.063962,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.8717,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.045268,
    shape_pt_lon: -90.064255,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.9281,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.044221,
    shape_pt_lon: -90.064973,
    shape_pt_sequence: 166,
    shape_dist_traveled: 13.0619,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.043433,
    shape_pt_lon: -90.065561,
    shape_pt_sequence: 167,
    shape_dist_traveled: 13.1652,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.042815,
    shape_pt_lon: -90.066047,
    shape_pt_sequence: 168,
    shape_dist_traveled: 13.2479,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.042568,
    shape_pt_lon: -90.066251,
    shape_pt_sequence: 169,
    shape_dist_traveled: 13.2803,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.042493,
    shape_pt_lon: -90.066313,
    shape_pt_sequence: 170,
    shape_dist_traveled: 13.2911,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.042101,
    shape_pt_lon: -90.066643,
    shape_pt_sequence: 171,
    shape_dist_traveled: 13.343,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.041198,
    shape_pt_lon: -90.067451,
    shape_pt_sequence: 172,
    shape_dist_traveled: 13.4682,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.040743,
    shape_pt_lon: -90.067878,
    shape_pt_sequence: 173,
    shape_dist_traveled: 13.5318,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.039943,
    shape_pt_lon: -90.068669,
    shape_pt_sequence: 174,
    shape_dist_traveled: 13.6471,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.039446,
    shape_pt_lon: -90.069161,
    shape_pt_sequence: 175,
    shape_dist_traveled: 13.7183,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.039352,
    shape_pt_lon: -90.069256,
    shape_pt_sequence: 176,
    shape_dist_traveled: 13.7317,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.038807,
    shape_pt_lon: -90.069807,
    shape_pt_sequence: 177,
    shape_dist_traveled: 13.81,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.037606,
    shape_pt_lon: -90.071005,
    shape_pt_sequence: 178,
    shape_dist_traveled: 13.9821,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.03597,
    shape_pt_lon: -90.07265,
    shape_pt_sequence: 179,
    shape_dist_traveled: 14.2181,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.035913,
    shape_pt_lon: -90.072706,
    shape_pt_sequence: 180,
    shape_dist_traveled: 14.2267,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.035397,
    shape_pt_lon: -90.07322,
    shape_pt_sequence: 181,
    shape_dist_traveled: 14.3007,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.03536,
    shape_pt_lon: -90.073256,
    shape_pt_sequence: 182,
    shape_dist_traveled: 14.3064,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.034896,
    shape_pt_lon: -90.073721,
    shape_pt_sequence: 183,
    shape_dist_traveled: 14.3732,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.03333,
    shape_pt_lon: -90.075288,
    shape_pt_sequence: 184,
    shape_dist_traveled: 14.5986,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.032038,
    shape_pt_lon: -90.076579,
    shape_pt_sequence: 185,
    shape_dist_traveled: 14.7835,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.031796,
    shape_pt_lon: -90.07683,
    shape_pt_sequence: 186,
    shape_dist_traveled: 14.819,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.031241,
    shape_pt_lon: -90.077382,
    shape_pt_sequence: 187,
    shape_dist_traveled: 14.8994,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.030851,
    shape_pt_lon: -90.07777,
    shape_pt_sequence: 188,
    shape_dist_traveled: 14.9548,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.030256,
    shape_pt_lon: -90.078369,
    shape_pt_sequence: 189,
    shape_dist_traveled: 15.0409,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.029967,
    shape_pt_lon: -90.078661,
    shape_pt_sequence: 190,
    shape_dist_traveled: 15.0827,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.029559,
    shape_pt_lon: -90.079063,
    shape_pt_sequence: 191,
    shape_dist_traveled: 15.1412,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.029319,
    shape_pt_lon: -90.079304,
    shape_pt_sequence: 192,
    shape_dist_traveled: 15.176,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.02909,
    shape_pt_lon: -90.07953,
    shape_pt_sequence: 193,
    shape_dist_traveled: 15.208,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.028418,
    shape_pt_lon: -90.08021,
    shape_pt_sequence: 194,
    shape_dist_traveled: 15.3053,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.027586,
    shape_pt_lon: -90.081041,
    shape_pt_sequence: 195,
    shape_dist_traveled: 15.4248,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.026959,
    shape_pt_lon: -90.081671,
    shape_pt_sequence: 196,
    shape_dist_traveled: 15.5158,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.026247,
    shape_pt_lon: -90.08238,
    shape_pt_sequence: 197,
    shape_dist_traveled: 15.6175,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.025708,
    shape_pt_lon: -90.082918,
    shape_pt_sequence: 198,
    shape_dist_traveled: 15.6957,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.024945,
    shape_pt_lon: -90.083688,
    shape_pt_sequence: 199,
    shape_dist_traveled: 15.8059,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.024729,
    shape_pt_lon: -90.083901,
    shape_pt_sequence: 200,
    shape_dist_traveled: 15.8365,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.023897,
    shape_pt_lon: -90.084738,
    shape_pt_sequence: 201,
    shape_dist_traveled: 15.9566,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.021363,
    shape_pt_lon: -90.087275,
    shape_pt_sequence: 202,
    shape_dist_traveled: 16.3214,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.020783,
    shape_pt_lon: -90.087852,
    shape_pt_sequence: 203,
    shape_dist_traveled: 16.4045,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.02031,
    shape_pt_lon: -90.088305,
    shape_pt_sequence: 204,
    shape_dist_traveled: 16.4715,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.019909,
    shape_pt_lon: -90.0887,
    shape_pt_sequence: 205,
    shape_dist_traveled: 16.5285,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.019237,
    shape_pt_lon: -90.089399,
    shape_pt_sequence: 206,
    shape_dist_traveled: 16.6271,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.018839,
    shape_pt_lon: -90.089795,
    shape_pt_sequence: 207,
    shape_dist_traveled: 16.6847,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.018299,
    shape_pt_lon: -90.090343,
    shape_pt_sequence: 208,
    shape_dist_traveled: 16.7622,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.018249,
    shape_pt_lon: -90.090393,
    shape_pt_sequence: 209,
    shape_dist_traveled: 16.77,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.017203,
    shape_pt_lon: -90.091439,
    shape_pt_sequence: 210,
    shape_dist_traveled: 16.9201,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.016831,
    shape_pt_lon: -90.091811,
    shape_pt_sequence: 211,
    shape_dist_traveled: 16.9741,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.016189,
    shape_pt_lon: -90.092452,
    shape_pt_sequence: 212,
    shape_dist_traveled: 17.0658,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.013499,
    shape_pt_lon: -90.095144,
    shape_pt_sequence: 213,
    shape_dist_traveled: 17.4533,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.012316,
    shape_pt_lon: -90.096326,
    shape_pt_sequence: 214,
    shape_dist_traveled: 17.624,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.012149,
    shape_pt_lon: -90.096491,
    shape_pt_sequence: 215,
    shape_dist_traveled: 17.6474,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.010842,
    shape_pt_lon: -90.096482,
    shape_pt_sequence: 216,
    shape_dist_traveled: 17.7944,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.010106,
    shape_pt_lon: -90.096474,
    shape_pt_sequence: 217,
    shape_dist_traveled: 17.8764,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.009942,
    shape_pt_lon: -90.096473,
    shape_pt_sequence: 218,
    shape_dist_traveled: 17.8944,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006133,
    shape_pt_lon: -90.096443,
    shape_pt_sequence: 219,
    shape_dist_traveled: 18.3204,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006096,
    shape_pt_lon: -90.098821,
    shape_pt_sequence: 220,
    shape_dist_traveled: 18.5355,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006079,
    shape_pt_lon: -90.099514,
    shape_pt_sequence: 221,
    shape_dist_traveled: 18.5985,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006068,
    shape_pt_lon: -90.100672,
    shape_pt_sequence: 222,
    shape_dist_traveled: 18.7025,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006066,
    shape_pt_lon: -90.101071,
    shape_pt_sequence: 223,
    shape_dist_traveled: 18.7385,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -90.101231,
    shape_pt_sequence: 224,
    shape_dist_traveled: 18.7535,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006062,
    shape_pt_lon: -90.101608,
    shape_pt_sequence: 225,
    shape_dist_traveled: 18.7875,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006063,
    shape_pt_lon: -90.10189,
    shape_pt_sequence: 226,
    shape_dist_traveled: 18.8135,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006064,
    shape_pt_lon: -90.102588,
    shape_pt_sequence: 227,
    shape_dist_traveled: 18.8765,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006069,
    shape_pt_lon: -90.103219,
    shape_pt_sequence: 228,
    shape_dist_traveled: 18.9335,
  },
  {
    shape_id: 38237,
    shape_pt_lat: 35.006088,
    shape_pt_lon: -90.104111,
    shape_pt_sequence: 229,
    shape_dist_traveled: 19.0146,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2681,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3762,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4351,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4613,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4948,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.554,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6159,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6726,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7001,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7466,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8085,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8468,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9165,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0104,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1163,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1414,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2265,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3323,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.4443,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.135843,
    shape_pt_lon: -90.056147,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5321,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.135726,
    shape_pt_lon: -90.056195,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5461,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.134526,
    shape_pt_lon: -90.05639,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6821,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.134034,
    shape_pt_lon: -90.056461,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7376,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8786,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.13275,
    shape_pt_lon: -90.055818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9407,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.132744,
    shape_pt_lon: -90.055183,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9987,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0497,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1388,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.13154,
    shape_pt_lon: -90.054672,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1838,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.130348,
    shape_pt_lon: -90.054718,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.3168,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.129169,
    shape_pt_lon: -90.054757,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.4489,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.128066,
    shape_pt_lon: -90.054793,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.5729,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.126931,
    shape_pt_lon: -90.054838,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.7,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.126867,
    shape_pt_lon: -90.05484,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.707,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.126261,
    shape_pt_lon: -90.054858,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.775,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.125658,
    shape_pt_lon: -90.054886,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.8421,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.125593,
    shape_pt_lon: -90.054889,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.8491,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -90.054901,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.9081,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.124617,
    shape_pt_lon: -90.054916,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.9582,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.124573,
    shape_pt_lon: -90.054917,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.9632,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.123659,
    shape_pt_lon: -90.054948,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.0662,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.122803,
    shape_pt_lon: -90.054979,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.1612,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.122101,
    shape_pt_lon: -90.055003,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.2403,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.121681,
    shape_pt_lon: -90.055019,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.2873,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.119833,
    shape_pt_lon: -90.055083,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.4944,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.118579,
    shape_pt_lon: -90.055128,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.6344,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.117617,
    shape_pt_lon: -90.055161,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.7425,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.117259,
    shape_pt_lon: -90.055177,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.7825,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.116955,
    shape_pt_lon: -90.055187,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.8165,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.116041,
    shape_pt_lon: -90.055212,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.9185,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.115156,
    shape_pt_lon: -90.055223,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.0175,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.114328,
    shape_pt_lon: -90.055232,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.1105,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.113437,
    shape_pt_lon: -90.055242,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.2095,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.112452,
    shape_pt_lon: -90.055249,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.3206,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.112015,
    shape_pt_lon: -90.055256,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.3696,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.10959,
    shape_pt_lon: -90.055289,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.6406,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.108791,
    shape_pt_lon: -90.055316,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.7296,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.107876,
    shape_pt_lon: -90.055319,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.8326,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.107692,
    shape_pt_lon: -90.055327,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.8526,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -90.05533,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.8646,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.107507,
    shape_pt_lon: -90.055332,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.8736,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.10574,
    shape_pt_lon: -90.055395,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.0717,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.104183,
    shape_pt_lon: -90.055448,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.2458,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.10395,
    shape_pt_lon: -90.055456,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.2718,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.101561,
    shape_pt_lon: -90.055535,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.5389,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.101481,
    shape_pt_lon: -90.055538,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.548,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.101385,
    shape_pt_lon: -90.055617,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.561,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.100262,
    shape_pt_lon: -90.055674,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.6871,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.096869,
    shape_pt_lon: -90.05578,
    shape_pt_sequence: 97,
    shape_dist_traveled: 7.0662,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.09623,
    shape_pt_lon: -90.055837,
    shape_pt_sequence: 98,
    shape_dist_traveled: 7.1384,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.096187,
    shape_pt_lon: -90.055841,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.1434,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.092937,
    shape_pt_lon: -90.056179,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.5077,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.09249,
    shape_pt_lon: -90.056224,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.5579,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.091541,
    shape_pt_lon: -90.056323,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.6653,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.09054,
    shape_pt_lon: -90.056426,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.7776,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.089539,
    shape_pt_lon: -90.05653,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.89,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.088531,
    shape_pt_lon: -90.056633,
    shape_pt_sequence: 105,
    shape_dist_traveled: 8.0024,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.087533,
    shape_pt_lon: -90.056741,
    shape_pt_sequence: 106,
    shape_dist_traveled: 8.1148,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.08714,
    shape_pt_lon: -90.056779,
    shape_pt_sequence: 107,
    shape_dist_traveled: 8.159,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.087059,
    shape_pt_lon: -90.056788,
    shape_pt_sequence: 108,
    shape_dist_traveled: 8.168,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.086531,
    shape_pt_lon: -90.056843,
    shape_pt_sequence: 109,
    shape_dist_traveled: 8.2272,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.08457,
    shape_pt_lon: -90.057046,
    shape_pt_sequence: 110,
    shape_dist_traveled: 8.448,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.084104,
    shape_pt_lon: -90.057099,
    shape_pt_sequence: 111,
    shape_dist_traveled: 8.5002,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.083999,
    shape_pt_lon: -90.05711,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.5113,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.082477,
    shape_pt_lon: -90.05726,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.6828,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.082371,
    shape_pt_lon: -90.057271,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.6948,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.082336,
    shape_pt_lon: -90.057276,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.6989,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.082206,
    shape_pt_lon: -90.05729,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.713,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.082136,
    shape_pt_lon: -90.057297,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.721,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.08112,
    shape_pt_lon: -90.057402,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.8354,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.080756,
    shape_pt_lon: -90.057426,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.8754,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.079797,
    shape_pt_lon: -90.057403,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.9835,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.079732,
    shape_pt_lon: -90.057399,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.9905,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.079479,
    shape_pt_lon: -90.057385,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.0185,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07939,
    shape_pt_lon: -90.057378,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.0285,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.079332,
    shape_pt_lon: -90.057376,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.0355,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07911,
    shape_pt_lon: -90.057361,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.0596,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.078697,
    shape_pt_lon: -90.057349,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.1066,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07845,
    shape_pt_lon: -90.057356,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.1336,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.078121,
    shape_pt_lon: -90.057407,
    shape_pt_sequence: 128,
    shape_dist_traveled: 9.1709,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.077812,
    shape_pt_lon: -90.05748,
    shape_pt_sequence: 129,
    shape_dist_traveled: 9.2066,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07719,
    shape_pt_lon: -90.057683,
    shape_pt_sequence: 130,
    shape_dist_traveled: 9.2779,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.076912,
    shape_pt_lon: -90.057753,
    shape_pt_sequence: 131,
    shape_dist_traveled: 9.3096,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.076606,
    shape_pt_lon: -90.057807,
    shape_pt_sequence: 132,
    shape_dist_traveled: 9.345,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.076247,
    shape_pt_lon: -90.057844,
    shape_pt_sequence: 133,
    shape_dist_traveled: 9.3851,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.075771,
    shape_pt_lon: -90.057893,
    shape_pt_sequence: 134,
    shape_dist_traveled: 9.4383,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.075223,
    shape_pt_lon: -90.057953,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.4996,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.074887,
    shape_pt_lon: -90.057987,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.5377,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.074542,
    shape_pt_lon: -90.05802,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.5768,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07431,
    shape_pt_lon: -90.058047,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.6029,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.07333,
    shape_pt_lon: -90.058181,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.7125,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.073204,
    shape_pt_lon: -90.05812,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.7274,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.071959,
    shape_pt_lon: -90.058321,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.8685,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.4894,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.6761,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.8125,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.8617,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063109,
    shape_pt_lon: -90.059249,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.8667,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063117,
    shape_pt_lon: -90.059562,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.8947,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06313,
    shape_pt_lon: -90.060296,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.9607,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.0632,
    shape_pt_lon: -90.063259,
    shape_pt_sequence: 149,
    shape_dist_traveled: 11.2288,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063209,
    shape_pt_lon: -90.063518,
    shape_pt_sequence: 150,
    shape_dist_traveled: 11.2529,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06325,
    shape_pt_lon: -90.065289,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.4129,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06329,
    shape_pt_lon: -90.067128,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.579,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063307,
    shape_pt_lon: -90.067722,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.633,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063311,
    shape_pt_lon: -90.06792,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.6511,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063332,
    shape_pt_lon: -90.068607,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.7131,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063339,
    shape_pt_lon: -90.068942,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.7431,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06334,
    shape_pt_lon: -90.069031,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.7511,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06334,
    shape_pt_lon: -90.069102,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.7581,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063366,
    shape_pt_lon: -90.070406,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.8751,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063282,
    shape_pt_lon: -90.072767,
    shape_pt_sequence: 160,
    shape_dist_traveled: 12.0884,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063281,
    shape_pt_lon: -90.072901,
    shape_pt_sequence: 161,
    shape_dist_traveled: 12.1014,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.063291,
    shape_pt_lon: -90.073427,
    shape_pt_sequence: 162,
    shape_dist_traveled: 12.1484,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.06331,
    shape_pt_lon: -90.074208,
    shape_pt_sequence: 163,
    shape_dist_traveled: 12.2194,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.062761,
    shape_pt_lon: -90.074292,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.2818,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -90.074369,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.3117,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.062329,
    shape_pt_lon: -90.07445,
    shape_pt_sequence: 166,
    shape_dist_traveled: 12.3323,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.062168,
    shape_pt_lon: -90.074571,
    shape_pt_sequence: 167,
    shape_dist_traveled: 12.3534,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.062132,
    shape_pt_lon: -90.074604,
    shape_pt_sequence: 168,
    shape_dist_traveled: 12.3584,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.075085,
    shape_pt_sequence: 169,
    shape_dist_traveled: 12.4236,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.061529,
    shape_pt_lon: -90.075248,
    shape_pt_sequence: 170,
    shape_dist_traveled: 12.4478,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.061307,
    shape_pt_lon: -90.075392,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.476,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.061147,
    shape_pt_lon: -90.075461,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.494,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.060969,
    shape_pt_lon: -90.075493,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.5142,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.0601,
    shape_pt_lon: -90.075526,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.6123,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.057669,
    shape_pt_lon: -90.075592,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.8843,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.055701,
    shape_pt_lon: -90.07567,
    shape_pt_sequence: 176,
    shape_dist_traveled: 13.1044,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.054698,
    shape_pt_lon: -90.075697,
    shape_pt_sequence: 177,
    shape_dist_traveled: 13.2165,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.054354,
    shape_pt_lon: -90.075708,
    shape_pt_sequence: 178,
    shape_dist_traveled: 13.2545,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.054146,
    shape_pt_lon: -90.075686,
    shape_pt_sequence: 179,
    shape_dist_traveled: 13.2786,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.0536,
    shape_pt_lon: -90.075551,
    shape_pt_sequence: 180,
    shape_dist_traveled: 13.3407,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.05274,
    shape_pt_lon: -90.075291,
    shape_pt_sequence: 181,
    shape_dist_traveled: 13.4394,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.052519,
    shape_pt_lon: -90.075252,
    shape_pt_sequence: 182,
    shape_dist_traveled: 13.4648,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.052227,
    shape_pt_lon: -90.07527,
    shape_pt_sequence: 183,
    shape_dist_traveled: 13.4968,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.052025,
    shape_pt_lon: -90.075328,
    shape_pt_sequence: 184,
    shape_dist_traveled: 13.5204,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050908,
    shape_pt_lon: -90.075926,
    shape_pt_sequence: 185,
    shape_dist_traveled: 13.6565,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050753,
    shape_pt_lon: -90.075987,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.6745,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050545,
    shape_pt_lon: -90.076039,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.6989,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050548,
    shape_pt_lon: -90.07469,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.8209,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050543,
    shape_pt_lon: -90.073509,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.9269,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050549,
    shape_pt_lon: -90.072786,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.9929,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050565,
    shape_pt_lon: -90.072596,
    shape_pt_sequence: 191,
    shape_dist_traveled: 14.01,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050624,
    shape_pt_lon: -90.07242,
    shape_pt_sequence: 192,
    shape_dist_traveled: 14.0271,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.05067,
    shape_pt_lon: -90.072338,
    shape_pt_sequence: 193,
    shape_dist_traveled: 14.0357,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050692,
    shape_pt_lon: -90.072297,
    shape_pt_sequence: 194,
    shape_dist_traveled: 14.0407,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.05076,
    shape_pt_lon: -90.07221,
    shape_pt_sequence: 195,
    shape_dist_traveled: 14.0513,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.051036,
    shape_pt_lon: -90.071958,
    shape_pt_sequence: 196,
    shape_dist_traveled: 14.0899,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.05095,
    shape_pt_lon: -90.071822,
    shape_pt_sequence: 197,
    shape_dist_traveled: 14.1049,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050682,
    shape_pt_lon: -90.071376,
    shape_pt_sequence: 198,
    shape_dist_traveled: 14.1549,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050615,
    shape_pt_lon: -90.071207,
    shape_pt_sequence: 199,
    shape_dist_traveled: 14.1728,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050574,
    shape_pt_lon: -90.071058,
    shape_pt_sequence: 200,
    shape_dist_traveled: 14.1864,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050551,
    shape_pt_lon: -90.070885,
    shape_pt_sequence: 201,
    shape_dist_traveled: 14.2027,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.05054,
    shape_pt_lon: -90.070511,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.2367,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -90.07038,
    shape_pt_sequence: 203,
    shape_dist_traveled: 14.2478,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050565,
    shape_pt_lon: -90.070294,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.256,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050616,
    shape_pt_lon: -90.070197,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.2663,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050681,
    shape_pt_lon: -90.07013,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.2763,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050809,
    shape_pt_lon: -90.070078,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.2912,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.053026,
    shape_pt_lon: -90.069917,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.5396,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.052956,
    shape_pt_lon: -90.068795,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.6409,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.052951,
    shape_pt_lon: -90.068736,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.6459,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.051308,
    shape_pt_lon: -90.06886,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.8302,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.051085,
    shape_pt_lon: -90.0689,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.8555,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.050643,
    shape_pt_lon: -90.06905,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.9072,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.049941,
    shape_pt_lon: -90.069249,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.9872,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.049603,
    shape_pt_lon: -90.069303,
    shape_pt_sequence: 215,
    shape_dist_traveled: 15.0256,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.049137,
    shape_pt_lon: -90.069332,
    shape_pt_sequence: 216,
    shape_dist_traveled: 15.0777,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.048742,
    shape_pt_lon: -90.06935,
    shape_pt_sequence: 217,
    shape_dist_traveled: 15.1217,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.048001,
    shape_pt_lon: -90.069388,
    shape_pt_sequence: 218,
    shape_dist_traveled: 15.2048,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.047749,
    shape_pt_lon: -90.069398,
    shape_pt_sequence: 219,
    shape_dist_traveled: 15.2338,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.047333,
    shape_pt_lon: -90.069418,
    shape_pt_sequence: 220,
    shape_dist_traveled: 15.2798,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.047214,
    shape_pt_lon: -90.069423,
    shape_pt_sequence: 221,
    shape_dist_traveled: 15.2928,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.047132,
    shape_pt_lon: -90.069429,
    shape_pt_sequence: 222,
    shape_dist_traveled: 15.3028,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.04651,
    shape_pt_lon: -90.069463,
    shape_pt_sequence: 223,
    shape_dist_traveled: 15.3719,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.044226,
    shape_pt_lon: -90.069496,
    shape_pt_sequence: 224,
    shape_dist_traveled: 15.628,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.043833,
    shape_pt_lon: -90.069508,
    shape_pt_sequence: 225,
    shape_dist_traveled: 15.672,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.043007,
    shape_pt_lon: -90.069533,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.764,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.042815,
    shape_pt_lon: -90.06953,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.786,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.041981,
    shape_pt_lon: -90.069535,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.879,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.041636,
    shape_pt_lon: -90.069507,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.917,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.040074,
    shape_pt_lon: -90.069243,
    shape_pt_sequence: 230,
    shape_dist_traveled: 16.0937,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.039676,
    shape_pt_lon: -90.069168,
    shape_pt_sequence: 231,
    shape_dist_traveled: 16.1392,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.039446,
    shape_pt_lon: -90.069161,
    shape_pt_sequence: 232,
    shape_dist_traveled: 16.1652,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.03916,
    shape_pt_lon: -90.069198,
    shape_pt_sequence: 233,
    shape_dist_traveled: 16.1975,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.038852,
    shape_pt_lon: -90.069228,
    shape_pt_sequence: 234,
    shape_dist_traveled: 16.2316,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.038556,
    shape_pt_lon: -90.069286,
    shape_pt_sequence: 235,
    shape_dist_traveled: 16.2651,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.0373,
    shape_pt_lon: -90.069595,
    shape_pt_sequence: 236,
    shape_dist_traveled: 16.4087,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.036581,
    shape_pt_lon: -90.069764,
    shape_pt_sequence: 237,
    shape_dist_traveled: 16.4902,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.035488,
    shape_pt_lon: -90.070036,
    shape_pt_sequence: 238,
    shape_dist_traveled: 16.6144,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.035,
    shape_pt_lon: -90.070139,
    shape_pt_sequence: 239,
    shape_dist_traveled: 16.6703,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.034625,
    shape_pt_lon: -90.070173,
    shape_pt_sequence: 240,
    shape_dist_traveled: 16.7124,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.034123,
    shape_pt_lon: -90.070166,
    shape_pt_sequence: 241,
    shape_dist_traveled: 16.7684,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.03362,
    shape_pt_lon: -90.070149,
    shape_pt_sequence: 242,
    shape_dist_traveled: 16.8244,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.03224,
    shape_pt_lon: -90.070111,
    shape_pt_sequence: 243,
    shape_dist_traveled: 16.9795,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.031169,
    shape_pt_lon: -90.070089,
    shape_pt_sequence: 244,
    shape_dist_traveled: 17.0995,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.030445,
    shape_pt_lon: -90.07009,
    shape_pt_sequence: 245,
    shape_dist_traveled: 17.1805,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.029479,
    shape_pt_lon: -90.070065,
    shape_pt_sequence: 246,
    shape_dist_traveled: 17.2885,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.029116,
    shape_pt_lon: -90.070043,
    shape_pt_sequence: 247,
    shape_dist_traveled: 17.3286,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.028517,
    shape_pt_lon: -90.069993,
    shape_pt_sequence: 248,
    shape_dist_traveled: 17.3957,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.028279,
    shape_pt_lon: -90.069969,
    shape_pt_sequence: 249,
    shape_dist_traveled: 17.4228,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.02816,
    shape_pt_lon: -90.06995,
    shape_pt_sequence: 250,
    shape_dist_traveled: 17.4359,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.027781,
    shape_pt_lon: -90.069846,
    shape_pt_sequence: 251,
    shape_dist_traveled: 17.48,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.027456,
    shape_pt_lon: -90.06972,
    shape_pt_sequence: 252,
    shape_dist_traveled: 17.5174,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.026683,
    shape_pt_lon: -90.069391,
    shape_pt_sequence: 253,
    shape_dist_traveled: 17.6094,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.025822,
    shape_pt_lon: -90.068948,
    shape_pt_sequence: 254,
    shape_dist_traveled: 17.7134,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.025162,
    shape_pt_lon: -90.068602,
    shape_pt_sequence: 255,
    shape_dist_traveled: 17.7936,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.024859,
    shape_pt_lon: -90.068458,
    shape_pt_sequence: 256,
    shape_dist_traveled: 17.83,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.024658,
    shape_pt_lon: -90.068383,
    shape_pt_sequence: 257,
    shape_dist_traveled: 17.8531,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.024513,
    shape_pt_lon: -90.068342,
    shape_pt_sequence: 258,
    shape_dist_traveled: 17.8696,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.024358,
    shape_pt_lon: -90.068306,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.8879,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.023916,
    shape_pt_lon: -90.068242,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.9372,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.023356,
    shape_pt_lon: -90.068227,
    shape_pt_sequence: 261,
    shape_dist_traveled: 18.0002,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.022902,
    shape_pt_lon: -90.068224,
    shape_pt_sequence: 262,
    shape_dist_traveled: 18.0513,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.022576,
    shape_pt_lon: -90.068222,
    shape_pt_sequence: 263,
    shape_dist_traveled: 18.0873,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.02168,
    shape_pt_lon: -90.068219,
    shape_pt_sequence: 264,
    shape_dist_traveled: 18.1873,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.021481,
    shape_pt_lon: -90.068222,
    shape_pt_sequence: 265,
    shape_dist_traveled: 18.2103,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.021,
    shape_pt_lon: -90.068228,
    shape_pt_sequence: 266,
    shape_dist_traveled: 18.2633,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.020893,
    shape_pt_lon: -90.068228,
    shape_pt_sequence: 267,
    shape_dist_traveled: 18.2753,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.020783,
    shape_pt_lon: -90.06823,
    shape_pt_sequence: 268,
    shape_dist_traveled: 18.2883,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.020509,
    shape_pt_lon: -90.068249,
    shape_pt_sequence: 269,
    shape_dist_traveled: 18.3183,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.019316,
    shape_pt_lon: -90.068233,
    shape_pt_sequence: 270,
    shape_dist_traveled: 18.4523,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.019041,
    shape_pt_lon: -90.068227,
    shape_pt_sequence: 271,
    shape_dist_traveled: 18.4833,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.018596,
    shape_pt_lon: -90.06823,
    shape_pt_sequence: 272,
    shape_dist_traveled: 18.5323,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.01762,
    shape_pt_lon: -90.068219,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.6424,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.017185,
    shape_pt_lon: -90.068218,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.6904,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.016731,
    shape_pt_lon: -90.068215,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.7414,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.015562,
    shape_pt_lon: -90.068207,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.8724,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.0149,
    shape_pt_lon: -90.068202,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.9464,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.014657,
    shape_pt_lon: -90.0682,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.9734,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.014526,
    shape_pt_lon: -90.068199,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.9884,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.01183,
    shape_pt_lon: -90.068181,
    shape_pt_sequence: 280,
    shape_dist_traveled: 19.2894,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.010882,
    shape_pt_lon: -90.068171,
    shape_pt_sequence: 281,
    shape_dist_traveled: 19.3954,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.008532,
    shape_pt_lon: -90.068152,
    shape_pt_sequence: 282,
    shape_dist_traveled: 19.6584,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.008461,
    shape_pt_lon: -90.068153,
    shape_pt_sequence: 283,
    shape_dist_traveled: 19.6664,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006449,
    shape_pt_lon: -90.068138,
    shape_pt_sequence: 284,
    shape_dist_traveled: 19.8914,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006453,
    shape_pt_lon: -90.068391,
    shape_pt_sequence: 285,
    shape_dist_traveled: 19.9144,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006429,
    shape_pt_lon: -90.069107,
    shape_pt_sequence: 286,
    shape_dist_traveled: 19.9784,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006426,
    shape_pt_lon: -90.069457,
    shape_pt_sequence: 287,
    shape_dist_traveled: 20.0104,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006375,
    shape_pt_lon: -90.071924,
    shape_pt_sequence: 288,
    shape_dist_traveled: 20.2325,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.00636,
    shape_pt_lon: -90.072844,
    shape_pt_sequence: 289,
    shape_dist_traveled: 20.3165,
  },
  {
    shape_id: 38238,
    shape_pt_lat: 35.006332,
    shape_pt_lon: -90.074658,
    shape_pt_sequence: 290,
    shape_dist_traveled: 20.4806,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.006088,
    shape_pt_lon: -90.104111,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.006102,
    shape_pt_lon: -90.104798,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.062,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.006249,
    shape_pt_lon: -90.104501,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0939,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.006397,
    shape_pt_lon: -90.104201,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1253,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.006609,
    shape_pt_lon: -90.103768,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1711,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.007172,
    shape_pt_lon: -90.102651,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.291,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.007448,
    shape_pt_lon: -90.102155,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3448,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.007731,
    shape_pt_lon: -90.101678,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3992,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.008288,
    shape_pt_lon: -90.100803,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4996,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.008468,
    shape_pt_lon: -90.10054,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5309,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.008767,
    shape_pt_lon: -90.100128,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5805,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.00923,
    shape_pt_lon: -90.099538,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6547,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.009388,
    shape_pt_lon: -90.099349,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.6802,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.009441,
    shape_pt_lon: -90.099284,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.688,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.009963,
    shape_pt_lon: -90.098698,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.7665,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.010187,
    shape_pt_lon: -90.098463,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.7998,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.01179,
    shape_pt_lon: -90.096852,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.0316,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.012149,
    shape_pt_lon: -90.096491,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.0828,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.012316,
    shape_pt_lon: -90.096326,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.1063,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.013499,
    shape_pt_lon: -90.095144,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.277,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.016189,
    shape_pt_lon: -90.092452,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.6644,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.016831,
    shape_pt_lon: -90.091811,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.7561,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.017203,
    shape_pt_lon: -90.091439,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.8102,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.018249,
    shape_pt_lon: -90.090393,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.9602,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.018299,
    shape_pt_lon: -90.090343,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.968,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.018839,
    shape_pt_lon: -90.089795,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.0455,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.019237,
    shape_pt_lon: -90.089399,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.1031,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.019909,
    shape_pt_lon: -90.0887,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.2017,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.02031,
    shape_pt_lon: -90.088305,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.2587,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.020783,
    shape_pt_lon: -90.087852,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.3257,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.021363,
    shape_pt_lon: -90.087275,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.4088,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.023897,
    shape_pt_lon: -90.084738,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.7737,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.024729,
    shape_pt_lon: -90.083901,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.8938,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.024945,
    shape_pt_lon: -90.083688,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.9244,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.025708,
    shape_pt_lon: -90.082918,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.0345,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.026247,
    shape_pt_lon: -90.08238,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.1127,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.026959,
    shape_pt_lon: -90.081671,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.2144,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.027586,
    shape_pt_lon: -90.081041,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.3055,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.028418,
    shape_pt_lon: -90.08021,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.4249,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.02909,
    shape_pt_lon: -90.07953,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.5222,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.029319,
    shape_pt_lon: -90.079304,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.5543,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.029559,
    shape_pt_lon: -90.079063,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.5891,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.029967,
    shape_pt_lon: -90.078661,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.6475,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.030256,
    shape_pt_lon: -90.078369,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.6894,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.030851,
    shape_pt_lon: -90.07777,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.7754,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.031241,
    shape_pt_lon: -90.077382,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.8309,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.031796,
    shape_pt_lon: -90.07683,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.9113,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.032038,
    shape_pt_lon: -90.076579,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.9468,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.03333,
    shape_pt_lon: -90.075288,
    shape_pt_sequence: 49,
    shape_dist_traveled: 4.1317,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.034896,
    shape_pt_lon: -90.073721,
    shape_pt_sequence: 50,
    shape_dist_traveled: 4.357,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.03536,
    shape_pt_lon: -90.073256,
    shape_pt_sequence: 51,
    shape_dist_traveled: 4.4239,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.035397,
    shape_pt_lon: -90.07322,
    shape_pt_sequence: 52,
    shape_dist_traveled: 4.4295,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.035913,
    shape_pt_lon: -90.072706,
    shape_pt_sequence: 53,
    shape_dist_traveled: 4.5036,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.03597,
    shape_pt_lon: -90.07265,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.5122,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.037606,
    shape_pt_lon: -90.071005,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.7482,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.038807,
    shape_pt_lon: -90.069807,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.9203,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.039352,
    shape_pt_lon: -90.069256,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.9985,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.039446,
    shape_pt_lon: -90.069161,
    shape_pt_sequence: 58,
    shape_dist_traveled: 5.012,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.039943,
    shape_pt_lon: -90.068669,
    shape_pt_sequence: 59,
    shape_dist_traveled: 5.0832,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.040743,
    shape_pt_lon: -90.067878,
    shape_pt_sequence: 60,
    shape_dist_traveled: 5.1984,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.041198,
    shape_pt_lon: -90.067451,
    shape_pt_sequence: 61,
    shape_dist_traveled: 5.262,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.042101,
    shape_pt_lon: -90.066643,
    shape_pt_sequence: 62,
    shape_dist_traveled: 5.3872,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.042493,
    shape_pt_lon: -90.066313,
    shape_pt_sequence: 63,
    shape_dist_traveled: 5.4391,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.042568,
    shape_pt_lon: -90.066251,
    shape_pt_sequence: 64,
    shape_dist_traveled: 5.4499,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.042815,
    shape_pt_lon: -90.066047,
    shape_pt_sequence: 65,
    shape_dist_traveled: 5.4824,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.043433,
    shape_pt_lon: -90.065561,
    shape_pt_sequence: 66,
    shape_dist_traveled: 5.5651,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.044221,
    shape_pt_lon: -90.064973,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.6683,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.045268,
    shape_pt_lon: -90.064255,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.8021,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.045719,
    shape_pt_lon: -90.063962,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.8585,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.046798,
    shape_pt_lon: -90.063308,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.9931,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.047538,
    shape_pt_lon: -90.0629,
    shape_pt_sequence: 71,
    shape_dist_traveled: 6.084,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.048306,
    shape_pt_lon: -90.062499,
    shape_pt_sequence: 72,
    shape_dist_traveled: 6.1772,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.049379,
    shape_pt_lon: -90.06199,
    shape_pt_sequence: 73,
    shape_dist_traveled: 6.3057,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.050452,
    shape_pt_lon: -90.061533,
    shape_pt_sequence: 74,
    shape_dist_traveled: 6.4329,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.051507,
    shape_pt_lon: -90.061143,
    shape_pt_sequence: 75,
    shape_dist_traveled: 6.556,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.05247,
    shape_pt_lon: -90.060823,
    shape_pt_sequence: 76,
    shape_dist_traveled: 6.6678,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.053431,
    shape_pt_lon: -90.06054,
    shape_pt_sequence: 77,
    shape_dist_traveled: 6.7779,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.054388,
    shape_pt_lon: -90.060303,
    shape_pt_sequence: 78,
    shape_dist_traveled: 6.8869,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.055212,
    shape_pt_lon: -90.060121,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.9813,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.055262,
    shape_pt_lon: -90.060111,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.9864,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.055334,
    shape_pt_lon: -90.060097,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.9946,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.056303,
    shape_pt_lon: -90.059931,
    shape_pt_sequence: 82,
    shape_dist_traveled: 7.1047,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.056792,
    shape_pt_lon: -90.05986,
    shape_pt_sequence: 83,
    shape_dist_traveled: 7.159,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.05787,
    shape_pt_lon: -90.059736,
    shape_pt_sequence: 84,
    shape_dist_traveled: 7.2805,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.059309,
    shape_pt_lon: -90.05959,
    shape_pt_sequence: 85,
    shape_dist_traveled: 7.442,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.060576,
    shape_pt_lon: -90.05946,
    shape_pt_sequence: 86,
    shape_dist_traveled: 7.5845,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.061676,
    shape_pt_lon: -90.059343,
    shape_pt_sequence: 87,
    shape_dist_traveled: 7.708,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 88,
    shape_dist_traveled: 7.8686,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 89,
    shape_dist_traveled: 7.9177,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 90,
    shape_dist_traveled: 8.0542,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 91,
    shape_dist_traveled: 8.2408,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.071959,
    shape_pt_lon: -90.058321,
    shape_pt_sequence: 92,
    shape_dist_traveled: 8.8617,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.073204,
    shape_pt_lon: -90.05812,
    shape_pt_sequence: 93,
    shape_dist_traveled: 9.0029,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.073341,
    shape_pt_lon: -90.057993,
    shape_pt_sequence: 94,
    shape_dist_traveled: 9.0221,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.074263,
    shape_pt_lon: -90.05785,
    shape_pt_sequence: 95,
    shape_dist_traveled: 9.1259,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.07461,
    shape_pt_lon: -90.057815,
    shape_pt_sequence: 96,
    shape_dist_traveled: 9.165,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.074963,
    shape_pt_lon: -90.057778,
    shape_pt_sequence: 97,
    shape_dist_traveled: 9.2051,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.075161,
    shape_pt_lon: -90.057757,
    shape_pt_sequence: 98,
    shape_dist_traveled: 9.2272,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.075189,
    shape_pt_lon: -90.057753,
    shape_pt_sequence: 99,
    shape_dist_traveled: 9.2302,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.07569,
    shape_pt_lon: -90.0577,
    shape_pt_sequence: 100,
    shape_dist_traveled: 9.2864,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.076198,
    shape_pt_lon: -90.057648,
    shape_pt_sequence: 101,
    shape_dist_traveled: 9.3436,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.076651,
    shape_pt_lon: -90.057596,
    shape_pt_sequence: 102,
    shape_dist_traveled: 9.3939,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.076805,
    shape_pt_lon: -90.057574,
    shape_pt_sequence: 103,
    shape_dist_traveled: 9.412,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.076953,
    shape_pt_lon: -90.057553,
    shape_pt_sequence: 104,
    shape_dist_traveled: 9.428,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.077449,
    shape_pt_lon: -90.057454,
    shape_pt_sequence: 105,
    shape_dist_traveled: 9.4848,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.077933,
    shape_pt_lon: -90.057331,
    shape_pt_sequence: 106,
    shape_dist_traveled: 9.5401,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.078152,
    shape_pt_lon: -90.057288,
    shape_pt_sequence: 107,
    shape_dist_traveled: 9.5643,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.078468,
    shape_pt_lon: -90.057247,
    shape_pt_sequence: 108,
    shape_dist_traveled: 9.6005,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.078795,
    shape_pt_lon: -90.057237,
    shape_pt_sequence: 109,
    shape_dist_traveled: 9.6365,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.079284,
    shape_pt_lon: -90.057253,
    shape_pt_sequence: 110,
    shape_dist_traveled: 9.6915,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.079341,
    shape_pt_lon: -90.057257,
    shape_pt_sequence: 111,
    shape_dist_traveled: 9.6976,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.079429,
    shape_pt_lon: -90.05726,
    shape_pt_sequence: 112,
    shape_dist_traveled: 9.7076,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.07963,
    shape_pt_lon: -90.057267,
    shape_pt_sequence: 113,
    shape_dist_traveled: 9.7306,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.080095,
    shape_pt_lon: -90.057307,
    shape_pt_sequence: 114,
    shape_dist_traveled: 9.7827,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.080786,
    shape_pt_lon: -90.0573,
    shape_pt_sequence: 115,
    shape_dist_traveled: 9.8597,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.081218,
    shape_pt_lon: -90.057267,
    shape_pt_sequence: 116,
    shape_dist_traveled: 9.9078,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.082119,
    shape_pt_lon: -90.05717,
    shape_pt_sequence: 117,
    shape_dist_traveled: 10.0092,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.082202,
    shape_pt_lon: -90.05716,
    shape_pt_sequence: 118,
    shape_dist_traveled: 10.0192,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.082333,
    shape_pt_lon: -90.057147,
    shape_pt_sequence: 119,
    shape_dist_traveled: 10.0333,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.082368,
    shape_pt_lon: -90.057142,
    shape_pt_sequence: 120,
    shape_dist_traveled: 10.0374,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.08246,
    shape_pt_lon: -90.057132,
    shape_pt_sequence: 121,
    shape_dist_traveled: 10.0474,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.084,
    shape_pt_lon: -90.056966,
    shape_pt_sequence: 122,
    shape_dist_traveled: 10.221,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.084073,
    shape_pt_lon: -90.056958,
    shape_pt_sequence: 123,
    shape_dist_traveled: 10.2291,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.084563,
    shape_pt_lon: -90.056906,
    shape_pt_sequence: 124,
    shape_dist_traveled: 10.2843,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.086016,
    shape_pt_lon: -90.056752,
    shape_pt_sequence: 125,
    shape_dist_traveled: 10.4469,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056711,
    shape_pt_sequence: 126,
    shape_dist_traveled: 10.505,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.087059,
    shape_pt_lon: -90.056649,
    shape_pt_sequence: 127,
    shape_dist_traveled: 10.5643,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.087131,
    shape_pt_lon: -90.056639,
    shape_pt_sequence: 128,
    shape_dist_traveled: 10.5724,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.087532,
    shape_pt_lon: -90.056597,
    shape_pt_sequence: 129,
    shape_dist_traveled: 10.6176,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.088528,
    shape_pt_lon: -90.056487,
    shape_pt_sequence: 130,
    shape_dist_traveled: 10.729,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.089534,
    shape_pt_lon: -90.056383,
    shape_pt_sequence: 131,
    shape_dist_traveled: 10.8424,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.090535,
    shape_pt_lon: -90.056279,
    shape_pt_sequence: 132,
    shape_dist_traveled: 10.9547,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.091538,
    shape_pt_lon: -90.056173,
    shape_pt_sequence: 133,
    shape_dist_traveled: 11.0672,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.092482,
    shape_pt_lon: -90.056078,
    shape_pt_sequence: 134,
    shape_dist_traveled: 11.1736,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.094853,
    shape_pt_lon: -90.055829,
    shape_pt_sequence: 135,
    shape_dist_traveled: 11.4395,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.096178,
    shape_pt_lon: -90.055697,
    shape_pt_sequence: 136,
    shape_dist_traveled: 11.588,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.096283,
    shape_pt_lon: -90.055686,
    shape_pt_sequence: 137,
    shape_dist_traveled: 11.6,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.096827,
    shape_pt_lon: -90.05563,
    shape_pt_sequence: 138,
    shape_dist_traveled: 11.6612,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.098503,
    shape_pt_lon: -90.055578,
    shape_pt_sequence: 139,
    shape_dist_traveled: 11.8483,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.099852,
    shape_pt_lon: -90.055537,
    shape_pt_sequence: 140,
    shape_dist_traveled: 11.9993,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.10041,
    shape_pt_lon: -90.055516,
    shape_pt_sequence: 141,
    shape_dist_traveled: 12.0623,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.101366,
    shape_pt_lon: -90.055467,
    shape_pt_sequence: 142,
    shape_dist_traveled: 12.1694,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.101481,
    shape_pt_lon: -90.055538,
    shape_pt_sequence: 143,
    shape_dist_traveled: 12.1842,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.101561,
    shape_pt_lon: -90.055535,
    shape_pt_sequence: 144,
    shape_dist_traveled: 12.1933,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.10395,
    shape_pt_lon: -90.055456,
    shape_pt_sequence: 145,
    shape_dist_traveled: 12.4604,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.104183,
    shape_pt_lon: -90.055448,
    shape_pt_sequence: 146,
    shape_dist_traveled: 12.4864,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.10574,
    shape_pt_lon: -90.055395,
    shape_pt_sequence: 147,
    shape_dist_traveled: 12.6604,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.107507,
    shape_pt_lon: -90.055332,
    shape_pt_sequence: 148,
    shape_dist_traveled: 12.8585,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -90.05533,
    shape_pt_sequence: 149,
    shape_dist_traveled: 12.8675,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.107692,
    shape_pt_lon: -90.055327,
    shape_pt_sequence: 150,
    shape_dist_traveled: 12.8795,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.107876,
    shape_pt_lon: -90.055319,
    shape_pt_sequence: 151,
    shape_dist_traveled: 12.8995,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.108791,
    shape_pt_lon: -90.055316,
    shape_pt_sequence: 152,
    shape_dist_traveled: 13.0025,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.10959,
    shape_pt_lon: -90.055289,
    shape_pt_sequence: 153,
    shape_dist_traveled: 13.0916,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.112015,
    shape_pt_lon: -90.055256,
    shape_pt_sequence: 154,
    shape_dist_traveled: 13.3626,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.112452,
    shape_pt_lon: -90.055249,
    shape_pt_sequence: 155,
    shape_dist_traveled: 13.4116,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.113437,
    shape_pt_lon: -90.055242,
    shape_pt_sequence: 156,
    shape_dist_traveled: 13.5226,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.114328,
    shape_pt_lon: -90.055232,
    shape_pt_sequence: 157,
    shape_dist_traveled: 13.6216,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.115156,
    shape_pt_lon: -90.055223,
    shape_pt_sequence: 158,
    shape_dist_traveled: 13.7146,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.116041,
    shape_pt_lon: -90.055212,
    shape_pt_sequence: 159,
    shape_dist_traveled: 13.8136,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.116955,
    shape_pt_lon: -90.055187,
    shape_pt_sequence: 160,
    shape_dist_traveled: 13.9157,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.117259,
    shape_pt_lon: -90.055177,
    shape_pt_sequence: 161,
    shape_dist_traveled: 13.9497,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.117617,
    shape_pt_lon: -90.055161,
    shape_pt_sequence: 162,
    shape_dist_traveled: 13.9897,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.118579,
    shape_pt_lon: -90.055128,
    shape_pt_sequence: 163,
    shape_dist_traveled: 14.0977,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.119833,
    shape_pt_lon: -90.055083,
    shape_pt_sequence: 164,
    shape_dist_traveled: 14.2378,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.121681,
    shape_pt_lon: -90.055019,
    shape_pt_sequence: 165,
    shape_dist_traveled: 14.4449,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.122101,
    shape_pt_lon: -90.055003,
    shape_pt_sequence: 166,
    shape_dist_traveled: 14.4919,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.122803,
    shape_pt_lon: -90.054979,
    shape_pt_sequence: 167,
    shape_dist_traveled: 14.5709,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.123659,
    shape_pt_lon: -90.054948,
    shape_pt_sequence: 168,
    shape_dist_traveled: 14.666,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.124573,
    shape_pt_lon: -90.054917,
    shape_pt_sequence: 169,
    shape_dist_traveled: 14.769,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.124617,
    shape_pt_lon: -90.054916,
    shape_pt_sequence: 170,
    shape_dist_traveled: 14.774,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -90.054901,
    shape_pt_sequence: 171,
    shape_dist_traveled: 14.824,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.125593,
    shape_pt_lon: -90.054889,
    shape_pt_sequence: 172,
    shape_dist_traveled: 14.883,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.125658,
    shape_pt_lon: -90.054886,
    shape_pt_sequence: 173,
    shape_dist_traveled: 14.8901,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.126261,
    shape_pt_lon: -90.054858,
    shape_pt_sequence: 174,
    shape_dist_traveled: 14.9571,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.126867,
    shape_pt_lon: -90.05484,
    shape_pt_sequence: 175,
    shape_dist_traveled: 15.0252,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.126931,
    shape_pt_lon: -90.054838,
    shape_pt_sequence: 176,
    shape_dist_traveled: 15.0322,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.128066,
    shape_pt_lon: -90.054793,
    shape_pt_sequence: 177,
    shape_dist_traveled: 15.1592,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.129169,
    shape_pt_lon: -90.054757,
    shape_pt_sequence: 178,
    shape_dist_traveled: 15.2833,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.130348,
    shape_pt_lon: -90.054718,
    shape_pt_sequence: 179,
    shape_dist_traveled: 15.4153,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.13154,
    shape_pt_lon: -90.054672,
    shape_pt_sequence: 180,
    shape_dist_traveled: 15.5484,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 181,
    shape_dist_traveled: 15.5934,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 182,
    shape_dist_traveled: 15.6825,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.132872,
    shape_pt_lon: -90.054626,
    shape_pt_sequence: 183,
    shape_dist_traveled: 15.6975,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.133043,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 184,
    shape_dist_traveled: 15.7167,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.133207,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 185,
    shape_dist_traveled: 15.7367,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.13334,
    shape_pt_lon: -90.054802,
    shape_pt_sequence: 186,
    shape_dist_traveled: 15.7532,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.133608,
    shape_pt_lon: -90.05498,
    shape_pt_sequence: 187,
    shape_dist_traveled: 15.7872,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.133698,
    shape_pt_lon: -90.055027,
    shape_pt_sequence: 188,
    shape_dist_traveled: 15.798,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.13387,
    shape_pt_lon: -90.055074,
    shape_pt_sequence: 189,
    shape_dist_traveled: 15.8174,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.134011,
    shape_pt_lon: -90.055085,
    shape_pt_sequence: 190,
    shape_dist_traveled: 15.8334,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.134228,
    shape_pt_lon: -90.055078,
    shape_pt_sequence: 191,
    shape_dist_traveled: 15.8574,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.134433,
    shape_pt_lon: -90.05506,
    shape_pt_sequence: 192,
    shape_dist_traveled: 15.8805,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.134673,
    shape_pt_lon: -90.054994,
    shape_pt_sequence: 193,
    shape_dist_traveled: 15.9082,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.134826,
    shape_pt_lon: -90.054925,
    shape_pt_sequence: 194,
    shape_dist_traveled: 15.9262,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.135772,
    shape_pt_lon: -90.054418,
    shape_pt_sequence: 195,
    shape_dist_traveled: 16.0418,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 196,
    shape_dist_traveled: 16.0565,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 197,
    shape_dist_traveled: 16.167,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 198,
    shape_dist_traveled: 16.2255,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 199,
    shape_dist_traveled: 16.2727,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 200,
    shape_dist_traveled: 16.3891,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 201,
    shape_dist_traveled: 16.4209,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 202,
    shape_dist_traveled: 16.4729,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 203,
    shape_dist_traveled: 16.4832,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 204,
    shape_dist_traveled: 16.5031,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 205,
    shape_dist_traveled: 16.5237,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 206,
    shape_dist_traveled: 16.539,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 207,
    shape_dist_traveled: 16.6157,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 208,
    shape_dist_traveled: 16.6761,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 209,
    shape_dist_traveled: 16.7236,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 210,
    shape_dist_traveled: 16.7462,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 211,
    shape_dist_traveled: 16.7889,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 212,
    shape_dist_traveled: 16.8654,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 213,
    shape_dist_traveled: 16.9179,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 214,
    shape_dist_traveled: 16.9802,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 215,
    shape_dist_traveled: 17.0308,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 216,
    shape_dist_traveled: 17.1001,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 217,
    shape_dist_traveled: 17.1599,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 218,
    shape_dist_traveled: 17.2177,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 219,
    shape_dist_traveled: 17.2744,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 220,
    shape_dist_traveled: 17.3344,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 221,
    shape_dist_traveled: 17.4511,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 222,
    shape_dist_traveled: 17.5688,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 223,
    shape_dist_traveled: 17.6244,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 224,
    shape_dist_traveled: 17.6845,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 225,
    shape_dist_traveled: 17.8022,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 226,
    shape_dist_traveled: 17.9185,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 227,
    shape_dist_traveled: 18.0318,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 228,
    shape_dist_traveled: 18.0416,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 229,
    shape_dist_traveled: 18.0642,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 230,
    shape_dist_traveled: 18.0759,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 231,
    shape_dist_traveled: 18.123,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 232,
    shape_dist_traveled: 18.1499,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 233,
    shape_dist_traveled: 18.1655,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 234,
    shape_dist_traveled: 18.2698,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 235,
    shape_dist_traveled: 18.3814,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 236,
    shape_dist_traveled: 18.3887,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 237,
    shape_dist_traveled: 18.3941,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 238,
    shape_dist_traveled: 18.3941,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 239,
    shape_dist_traveled: 18.4507,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 240,
    shape_dist_traveled: 18.4507,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 241,
    shape_dist_traveled: 18.5032,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 242,
    shape_dist_traveled: 18.5627,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 243,
    shape_dist_traveled: 18.6215,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 244,
    shape_dist_traveled: 18.6386,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 245,
    shape_dist_traveled: 18.7012,
  },
  {
    shape_id: 38239,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 246,
    shape_dist_traveled: 18.7522,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.006332,
    shape_pt_lon: -90.074658,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.006797,
    shape_pt_lon: -90.074658,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.052,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.007037,
    shape_pt_lon: -90.07468,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0781,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.00729,
    shape_pt_lon: -90.074742,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1075,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.00789,
    shape_pt_lon: -90.074968,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1777,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.008751,
    shape_pt_lon: -90.0753,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2783,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.009008,
    shape_pt_lon: -90.075396,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3087,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.009461,
    shape_pt_lon: -90.075565,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3618,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.010472,
    shape_pt_lon: -90.075967,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4804,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.010634,
    shape_pt_lon: -90.076083,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5015,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.010741,
    shape_pt_lon: -90.076208,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5178,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.010884,
    shape_pt_lon: -90.076381,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.5404,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.010956,
    shape_pt_lon: -90.076447,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5504,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.011081,
    shape_pt_lon: -90.076541,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5665,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.011279,
    shape_pt_lon: -90.076633,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.59,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.011412,
    shape_pt_lon: -90.076661,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6052,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.011572,
    shape_pt_lon: -90.076661,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6232,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.011702,
    shape_pt_lon: -90.076648,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6373,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01186,
    shape_pt_lon: -90.076592,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.656,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.012517,
    shape_pt_lon: -90.07619,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7387,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013174,
    shape_pt_lon: -90.075782,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8205,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013321,
    shape_pt_lon: -90.075752,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8367,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013347,
    shape_pt_lon: -90.07575,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8397,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014406,
    shape_pt_lon: -90.075767,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9587,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014397,
    shape_pt_lon: -90.07544,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9887,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014413,
    shape_pt_lon: -90.074207,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0997,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014411,
    shape_pt_lon: -90.073727,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.1437,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014393,
    shape_pt_lon: -90.073549,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.1598,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014342,
    shape_pt_lon: -90.073385,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1757,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014278,
    shape_pt_lon: -90.073267,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1879,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014166,
    shape_pt_lon: -90.073111,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.207,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014101,
    shape_pt_lon: -90.073052,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.2162,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01341,
    shape_pt_lon: -90.072448,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.3111,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013207,
    shape_pt_lon: -90.072246,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.3401,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013091,
    shape_pt_lon: -90.072073,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.36,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013062,
    shape_pt_lon: -90.072002,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.3676,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013029,
    shape_pt_lon: -90.07188,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.3793,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01301,
    shape_pt_lon: -90.071738,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.3924,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01301,
    shape_pt_lon: -90.071537,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.4104,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013021,
    shape_pt_lon: -90.071471,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.4165,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0131,
    shape_pt_lon: -90.071223,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.4403,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0132,
    shape_pt_lon: -90.070959,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.4667,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013259,
    shape_pt_lon: -90.070809,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.4824,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01337,
    shape_pt_lon: -90.070603,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.504,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013591,
    shape_pt_lon: -90.070324,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.5401,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.013903,
    shape_pt_lon: -90.069982,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.5862,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01446,
    shape_pt_lon: -90.069368,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.669,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01457,
    shape_pt_lon: -90.06923,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.6867,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014619,
    shape_pt_lon: -90.069132,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.6967,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014654,
    shape_pt_lon: -90.068995,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.7103,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014667,
    shape_pt_lon: -90.068868,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.7214,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014668,
    shape_pt_lon: -90.068587,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.7474,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.014657,
    shape_pt_lon: -90.0682,
    shape_pt_sequence: 53,
    shape_dist_traveled: 1.7824,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0149,
    shape_pt_lon: -90.068202,
    shape_pt_sequence: 54,
    shape_dist_traveled: 1.8094,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.015562,
    shape_pt_lon: -90.068207,
    shape_pt_sequence: 55,
    shape_dist_traveled: 1.8834,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.016731,
    shape_pt_lon: -90.068215,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.0144,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.017185,
    shape_pt_lon: -90.068218,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.0654,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.01762,
    shape_pt_lon: -90.068219,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.1134,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.018596,
    shape_pt_lon: -90.06823,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.2234,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.019041,
    shape_pt_lon: -90.068227,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.2724,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.019316,
    shape_pt_lon: -90.068233,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.3034,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.020509,
    shape_pt_lon: -90.068249,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.4374,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.020783,
    shape_pt_lon: -90.06823,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.4675,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.020893,
    shape_pt_lon: -90.068228,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.4805,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.021,
    shape_pt_lon: -90.068228,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.4925,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.021481,
    shape_pt_lon: -90.068222,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.5455,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.02168,
    shape_pt_lon: -90.068219,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.5685,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.022576,
    shape_pt_lon: -90.068222,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.6685,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.022902,
    shape_pt_lon: -90.068224,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.7045,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.023356,
    shape_pt_lon: -90.068227,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.7555,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.023916,
    shape_pt_lon: -90.068242,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.8185,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.024358,
    shape_pt_lon: -90.068306,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.8679,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.024513,
    shape_pt_lon: -90.068342,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.8861,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.024658,
    shape_pt_lon: -90.068383,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.9026,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.024859,
    shape_pt_lon: -90.068458,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.9257,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.025162,
    shape_pt_lon: -90.068602,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.9621,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.025822,
    shape_pt_lon: -90.068948,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.0423,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.026683,
    shape_pt_lon: -90.069391,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.1463,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.027456,
    shape_pt_lon: -90.06972,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.2384,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.027781,
    shape_pt_lon: -90.069846,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.2757,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.02816,
    shape_pt_lon: -90.06995,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.3199,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.028279,
    shape_pt_lon: -90.069969,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.3329,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.028517,
    shape_pt_lon: -90.069993,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.3601,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.029116,
    shape_pt_lon: -90.070043,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.4272,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.029479,
    shape_pt_lon: -90.070065,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.4672,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.030445,
    shape_pt_lon: -90.07009,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.5753,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.031169,
    shape_pt_lon: -90.070089,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.6563,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.03224,
    shape_pt_lon: -90.070111,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.7763,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.03362,
    shape_pt_lon: -90.070149,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.9313,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.034123,
    shape_pt_lon: -90.070166,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.9873,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.034625,
    shape_pt_lon: -90.070173,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.0433,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.035,
    shape_pt_lon: -90.070139,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.0855,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.035488,
    shape_pt_lon: -90.070036,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.1414,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.036581,
    shape_pt_lon: -90.069764,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.2655,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0373,
    shape_pt_lon: -90.069595,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.3471,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.038556,
    shape_pt_lon: -90.069286,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.4907,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.038852,
    shape_pt_lon: -90.069228,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.5242,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.03916,
    shape_pt_lon: -90.069198,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.5583,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.039446,
    shape_pt_lon: -90.069161,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.5905,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.039676,
    shape_pt_lon: -90.069168,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.6165,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.040074,
    shape_pt_lon: -90.069243,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.6621,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.041636,
    shape_pt_lon: -90.069507,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.8387,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.041981,
    shape_pt_lon: -90.069535,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.8768,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042815,
    shape_pt_lon: -90.06953,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.9698,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042816,
    shape_pt_lon: -90.069577,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.9739,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04282,
    shape_pt_lon: -90.070527,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.0589,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042819,
    shape_pt_lon: -90.070869,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.0899,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042813,
    shape_pt_lon: -90.072282,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.2179,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042797,
    shape_pt_lon: -90.074488,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.4169,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042784,
    shape_pt_lon: -90.076177,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.5699,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042799,
    shape_pt_lon: -90.077127,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.6559,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042796,
    shape_pt_lon: -90.078289,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.7609,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042795,
    shape_pt_lon: -90.079459,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.8669,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042789,
    shape_pt_lon: -90.081642,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.0639,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043617,
    shape_pt_lon: -90.081643,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.1559,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043699,
    shape_pt_lon: -90.081643,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.1649,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.044766,
    shape_pt_lon: -90.081664,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.2849,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.045066,
    shape_pt_lon: -90.081702,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.3182,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04582,
    shape_pt_lon: -90.08187,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.4045,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046345,
    shape_pt_lon: -90.08198,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.4633,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047176,
    shape_pt_lon: -90.082166,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.5579,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047019,
    shape_pt_lon: -90.083264,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.6583,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046689,
    shape_pt_lon: -90.085543,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.8676,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046687,
    shape_pt_lon: -90.085709,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.8826,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046712,
    shape_pt_lon: -90.085893,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.8999,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046772,
    shape_pt_lon: -90.086081,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.9179,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046858,
    shape_pt_lon: -90.086249,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.936,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046901,
    shape_pt_lon: -90.086312,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.9438,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047011,
    shape_pt_lon: -90.086439,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.96,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047159,
    shape_pt_lon: -90.086547,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.9798,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047305,
    shape_pt_lon: -90.086613,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.9969,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04743,
    shape_pt_lon: -90.086636,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.011,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04753,
    shape_pt_lon: -90.08664,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.022,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047516,
    shape_pt_lon: -90.085643,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.112,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047511,
    shape_pt_lon: -90.085548,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.1201,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047521,
    shape_pt_lon: -90.085058,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.1651,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047615,
    shape_pt_lon: -90.084341,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.231,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047751,
    shape_pt_lon: -90.083426,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.3144,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047019,
    shape_pt_lon: -90.083264,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.3977,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047176,
    shape_pt_lon: -90.082166,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.4982,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.046345,
    shape_pt_lon: -90.08198,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.5927,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04582,
    shape_pt_lon: -90.08187,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.6516,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.045066,
    shape_pt_lon: -90.081702,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.7379,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.044766,
    shape_pt_lon: -90.081664,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.7711,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043699,
    shape_pt_lon: -90.081643,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.8911,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043617,
    shape_pt_lon: -90.081643,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.9001,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042789,
    shape_pt_lon: -90.081642,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.9921,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042795,
    shape_pt_lon: -90.079459,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.1891,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042796,
    shape_pt_lon: -90.078289,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.2951,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042799,
    shape_pt_lon: -90.077127,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.4001,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042784,
    shape_pt_lon: -90.076177,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.4862,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042797,
    shape_pt_lon: -90.074488,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.6392,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042813,
    shape_pt_lon: -90.072282,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.8382,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042819,
    shape_pt_lon: -90.070869,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.9662,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04282,
    shape_pt_lon: -90.070527,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.9972,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042816,
    shape_pt_lon: -90.069577,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.0822,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.042815,
    shape_pt_lon: -90.06953,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.0863,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043007,
    shape_pt_lon: -90.069533,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.1083,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.043833,
    shape_pt_lon: -90.069508,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2003,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.044226,
    shape_pt_lon: -90.069496,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.2443,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.04651,
    shape_pt_lon: -90.069463,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.5004,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047132,
    shape_pt_lon: -90.069429,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.5695,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047214,
    shape_pt_lon: -90.069423,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.5795,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047333,
    shape_pt_lon: -90.069418,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.5925,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.047749,
    shape_pt_lon: -90.069398,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.6385,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.048001,
    shape_pt_lon: -90.069388,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.6675,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.048742,
    shape_pt_lon: -90.06935,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.7506,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.049137,
    shape_pt_lon: -90.069332,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.7946,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.049603,
    shape_pt_lon: -90.069303,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.8467,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.049941,
    shape_pt_lon: -90.069249,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.8851,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050643,
    shape_pt_lon: -90.06905,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.9651,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.051085,
    shape_pt_lon: -90.0689,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.0168,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.051308,
    shape_pt_lon: -90.06886,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.0421,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.052951,
    shape_pt_lon: -90.068736,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.2264,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.052956,
    shape_pt_lon: -90.068795,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.2314,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.053026,
    shape_pt_lon: -90.069917,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.3327,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050809,
    shape_pt_lon: -90.070078,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.5811,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050681,
    shape_pt_lon: -90.07013,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.596,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050616,
    shape_pt_lon: -90.070197,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.606,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050565,
    shape_pt_lon: -90.070294,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.6163,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050547,
    shape_pt_lon: -90.07038,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.6245,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.05054,
    shape_pt_lon: -90.070511,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.6356,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050551,
    shape_pt_lon: -90.070885,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.6696,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050574,
    shape_pt_lon: -90.071058,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.6859,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050615,
    shape_pt_lon: -90.071207,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.6995,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050682,
    shape_pt_lon: -90.071376,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.7174,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.05095,
    shape_pt_lon: -90.071822,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.7674,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.051036,
    shape_pt_lon: -90.071958,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.7824,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.05076,
    shape_pt_lon: -90.07221,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.821,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050692,
    shape_pt_lon: -90.072297,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.8316,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.05067,
    shape_pt_lon: -90.072338,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.8366,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050624,
    shape_pt_lon: -90.07242,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.8452,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050565,
    shape_pt_lon: -90.072596,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.8623,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050549,
    shape_pt_lon: -90.072786,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.8794,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050543,
    shape_pt_lon: -90.073509,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.9454,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050548,
    shape_pt_lon: -90.07469,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.0514,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050545,
    shape_pt_lon: -90.076039,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.1734,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050753,
    shape_pt_lon: -90.075987,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.1978,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.050908,
    shape_pt_lon: -90.075926,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.2158,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.052025,
    shape_pt_lon: -90.075328,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.3519,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.052227,
    shape_pt_lon: -90.07527,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.3755,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.052519,
    shape_pt_lon: -90.075252,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.4075,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.05274,
    shape_pt_lon: -90.075291,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.4329,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0536,
    shape_pt_lon: -90.075551,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.5316,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.054146,
    shape_pt_lon: -90.075686,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.5937,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.054354,
    shape_pt_lon: -90.075708,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.6178,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.054698,
    shape_pt_lon: -90.075697,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.6558,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.055701,
    shape_pt_lon: -90.07567,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.7679,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.057669,
    shape_pt_lon: -90.075592,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.988,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0601,
    shape_pt_lon: -90.075526,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.26,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.060969,
    shape_pt_lon: -90.075493,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.3581,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.061147,
    shape_pt_lon: -90.075461,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.3783,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.061307,
    shape_pt_lon: -90.075392,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.3963,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.061529,
    shape_pt_lon: -90.075248,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.4245,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.075085,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.4487,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.062132,
    shape_pt_lon: -90.074604,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.5139,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.062168,
    shape_pt_lon: -90.074571,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.5189,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.062329,
    shape_pt_lon: -90.07445,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.54,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -90.074369,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.5606,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.062761,
    shape_pt_lon: -90.074292,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.5905,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06331,
    shape_pt_lon: -90.074208,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.6529,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063291,
    shape_pt_lon: -90.073427,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.7239,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063281,
    shape_pt_lon: -90.072901,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.7709,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063282,
    shape_pt_lon: -90.072767,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.7839,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063366,
    shape_pt_lon: -90.070406,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.9972,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06334,
    shape_pt_lon: -90.069102,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.1142,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06334,
    shape_pt_lon: -90.069031,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.1212,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063339,
    shape_pt_lon: -90.068942,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.1292,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063332,
    shape_pt_lon: -90.068607,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.1592,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063311,
    shape_pt_lon: -90.06792,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.2212,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063307,
    shape_pt_lon: -90.067722,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.2393,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06329,
    shape_pt_lon: -90.067128,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.2933,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06325,
    shape_pt_lon: -90.065289,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.4594,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063209,
    shape_pt_lon: -90.063518,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.6194,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.0632,
    shape_pt_lon: -90.063259,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.6435,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06313,
    shape_pt_lon: -90.060296,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.9116,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063117,
    shape_pt_lon: -90.059562,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.9776,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063109,
    shape_pt_lon: -90.059249,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.0056,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.0106,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.0598,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.1962,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 242,
    shape_dist_traveled: 14.3829,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.071959,
    shape_pt_lon: -90.058321,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.0038,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.073204,
    shape_pt_lon: -90.05812,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.1449,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.073341,
    shape_pt_lon: -90.057993,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.1641,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.074263,
    shape_pt_lon: -90.05785,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.2679,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.07461,
    shape_pt_lon: -90.057815,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.3071,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.074963,
    shape_pt_lon: -90.057778,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.3472,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.075161,
    shape_pt_lon: -90.057757,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.3693,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.075189,
    shape_pt_lon: -90.057753,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.3723,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.07569,
    shape_pt_lon: -90.0577,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.4285,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.076198,
    shape_pt_lon: -90.057648,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.4857,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.076651,
    shape_pt_lon: -90.057596,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.5359,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.076805,
    shape_pt_lon: -90.057574,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.5541,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.076953,
    shape_pt_lon: -90.057553,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.5701,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.077449,
    shape_pt_lon: -90.057454,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.6268,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.077933,
    shape_pt_lon: -90.057331,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.6821,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.078152,
    shape_pt_lon: -90.057288,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.7063,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.078468,
    shape_pt_lon: -90.057247,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.7425,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.078795,
    shape_pt_lon: -90.057237,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.7785,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.079284,
    shape_pt_lon: -90.057253,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.8336,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.079341,
    shape_pt_lon: -90.057257,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.8396,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.079429,
    shape_pt_lon: -90.05726,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.8496,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.07963,
    shape_pt_lon: -90.057267,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.8727,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.080095,
    shape_pt_lon: -90.057307,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.9247,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.080786,
    shape_pt_lon: -90.0573,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.0017,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.081218,
    shape_pt_lon: -90.057267,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.0498,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.082119,
    shape_pt_lon: -90.05717,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.1512,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.082202,
    shape_pt_lon: -90.05716,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.1613,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.082333,
    shape_pt_lon: -90.057147,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.1753,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.082368,
    shape_pt_lon: -90.057142,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.1794,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.08246,
    shape_pt_lon: -90.057132,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.1894,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.084,
    shape_pt_lon: -90.056966,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.3631,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.084073,
    shape_pt_lon: -90.056958,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.3712,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.084563,
    shape_pt_lon: -90.056906,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.4264,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.086016,
    shape_pt_lon: -90.056752,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.589,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.086529,
    shape_pt_lon: -90.056711,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.6471,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.087059,
    shape_pt_lon: -90.056649,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.7064,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.087131,
    shape_pt_lon: -90.056639,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.7144,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.087532,
    shape_pt_lon: -90.056597,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.7596,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.088528,
    shape_pt_lon: -90.056487,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.8711,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.089534,
    shape_pt_lon: -90.056383,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.9844,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.090535,
    shape_pt_lon: -90.056279,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.0968,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.091538,
    shape_pt_lon: -90.056173,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.2092,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.092482,
    shape_pt_lon: -90.056078,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.3156,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.094853,
    shape_pt_lon: -90.055829,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.5815,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.096178,
    shape_pt_lon: -90.055697,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.73,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.096283,
    shape_pt_lon: -90.055686,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.742,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.096827,
    shape_pt_lon: -90.05563,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.8033,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.098503,
    shape_pt_lon: -90.055578,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.9903,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.099852,
    shape_pt_lon: -90.055537,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.1413,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.10041,
    shape_pt_lon: -90.055516,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.2044,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.101366,
    shape_pt_lon: -90.055467,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.3115,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.101481,
    shape_pt_lon: -90.055538,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.3263,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.101561,
    shape_pt_lon: -90.055535,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.3353,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.10395,
    shape_pt_lon: -90.055456,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.6024,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.104183,
    shape_pt_lon: -90.055448,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.6284,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.10574,
    shape_pt_lon: -90.055395,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.8025,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.107507,
    shape_pt_lon: -90.055332,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.0006,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -90.05533,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.0096,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.107692,
    shape_pt_lon: -90.055327,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.0216,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.107876,
    shape_pt_lon: -90.055319,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.0416,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.108791,
    shape_pt_lon: -90.055316,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.1446,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.10959,
    shape_pt_lon: -90.055289,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.2336,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.112015,
    shape_pt_lon: -90.055256,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.5047,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.112452,
    shape_pt_lon: -90.055249,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.5537,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.113437,
    shape_pt_lon: -90.055242,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.6647,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.114328,
    shape_pt_lon: -90.055232,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.7637,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.115156,
    shape_pt_lon: -90.055223,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.8567,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.116041,
    shape_pt_lon: -90.055212,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.9557,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.116955,
    shape_pt_lon: -90.055187,
    shape_pt_sequence: 311,
    shape_dist_traveled: 20.0577,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.117259,
    shape_pt_lon: -90.055177,
    shape_pt_sequence: 312,
    shape_dist_traveled: 20.0917,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.117617,
    shape_pt_lon: -90.055161,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.1317,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.118579,
    shape_pt_lon: -90.055128,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.2398,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.119833,
    shape_pt_lon: -90.055083,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.3798,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.121681,
    shape_pt_lon: -90.055019,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.5869,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.122101,
    shape_pt_lon: -90.055003,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.6339,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.122803,
    shape_pt_lon: -90.054979,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.713,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.123659,
    shape_pt_lon: -90.054948,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.808,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.124573,
    shape_pt_lon: -90.054917,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.9111,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.124617,
    shape_pt_lon: -90.054916,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.9161,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.125072,
    shape_pt_lon: -90.054901,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.9661,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.125593,
    shape_pt_lon: -90.054889,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.0251,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.125658,
    shape_pt_lon: -90.054886,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.0322,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.126261,
    shape_pt_lon: -90.054858,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.0992,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.126867,
    shape_pt_lon: -90.05484,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.1672,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.126931,
    shape_pt_lon: -90.054838,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.1742,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.128066,
    shape_pt_lon: -90.054793,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.3013,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.129169,
    shape_pt_lon: -90.054757,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.4253,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.130348,
    shape_pt_lon: -90.054718,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.5574,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.13154,
    shape_pt_lon: -90.054672,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.6904,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.7354,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.8245,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.132872,
    shape_pt_lon: -90.054626,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.8396,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.133043,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.8588,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.133207,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.8787,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.13334,
    shape_pt_lon: -90.054802,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.8953,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.133608,
    shape_pt_lon: -90.05498,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.9293,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.133698,
    shape_pt_lon: -90.055027,
    shape_pt_sequence: 339,
    shape_dist_traveled: 21.94,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.13387,
    shape_pt_lon: -90.055074,
    shape_pt_sequence: 340,
    shape_dist_traveled: 21.9595,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.134011,
    shape_pt_lon: -90.055085,
    shape_pt_sequence: 341,
    shape_dist_traveled: 21.9755,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.134228,
    shape_pt_lon: -90.055078,
    shape_pt_sequence: 342,
    shape_dist_traveled: 21.9995,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.134433,
    shape_pt_lon: -90.05506,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.0226,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.134673,
    shape_pt_lon: -90.054994,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.0502,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.134826,
    shape_pt_lon: -90.054925,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.0683,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.135772,
    shape_pt_lon: -90.054418,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.1838,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.1986,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.309,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.3676,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.4147,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.5312,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.563,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.615,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.6253,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.6452,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.6658,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.6811,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.7578,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 359,
    shape_dist_traveled: 22.8182,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 360,
    shape_dist_traveled: 22.8657,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 361,
    shape_dist_traveled: 22.8882,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.931,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.0075,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 364,
    shape_dist_traveled: 23.06,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 365,
    shape_dist_traveled: 23.1223,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.1729,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.2421,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.3019,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.3598,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 370,
    shape_dist_traveled: 23.4164,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.4765,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.5932,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.7108,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.7665,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.8266,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 376,
    shape_dist_traveled: 23.9442,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 377,
    shape_dist_traveled: 24.0606,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 378,
    shape_dist_traveled: 24.1738,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 379,
    shape_dist_traveled: 24.1837,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 380,
    shape_dist_traveled: 24.2062,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 381,
    shape_dist_traveled: 24.2179,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 382,
    shape_dist_traveled: 24.2651,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 383,
    shape_dist_traveled: 24.292,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 384,
    shape_dist_traveled: 24.3075,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 385,
    shape_dist_traveled: 24.4119,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 386,
    shape_dist_traveled: 24.5235,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 387,
    shape_dist_traveled: 24.5308,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 388,
    shape_dist_traveled: 24.5362,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 389,
    shape_dist_traveled: 24.5362,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 390,
    shape_dist_traveled: 24.5928,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 391,
    shape_dist_traveled: 24.5928,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 392,
    shape_dist_traveled: 24.6453,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 393,
    shape_dist_traveled: 24.7047,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 394,
    shape_dist_traveled: 24.7636,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 395,
    shape_dist_traveled: 24.7806,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 396,
    shape_dist_traveled: 24.8433,
  },
  {
    shape_id: 38240,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 397,
    shape_dist_traveled: 24.8942,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3625,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4213,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4833,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5453,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6009,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7176,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8362,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9529,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0756,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.163531,
    shape_pt_lon: -90.043125,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.112,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.163583,
    shape_pt_lon: -90.043386,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.1357,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.163728,
    shape_pt_lon: -90.043988,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.193,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.165118,
    shape_pt_lon: -90.043443,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.3568,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.16539,
    shape_pt_lon: -90.043373,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.3874,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.16591,
    shape_pt_lon: -90.043281,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.446,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.166425,
    shape_pt_lon: -90.04319,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.5045,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.16765,
    shape_pt_lon: -90.042981,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.6428,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.167976,
    shape_pt_lon: -90.042931,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.6802,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.168186,
    shape_pt_lon: -90.042925,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.7032,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.168379,
    shape_pt_lon: -90.042933,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.7252,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.169,
    shape_pt_lon: -90.042977,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.7943,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.169577,
    shape_pt_lon: -90.043028,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.8595,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.169807,
    shape_pt_lon: -90.043067,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.8848,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.170641,
    shape_pt_lon: -90.043217,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.9797,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.170967,
    shape_pt_lon: -90.043277,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.0162,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.172203,
    shape_pt_lon: -90.043498,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.1566,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.17313,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.2607,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.173379,
    shape_pt_lon: -90.043711,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.289,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.17394,
    shape_pt_lon: -90.043823,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.3528,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.17493,
    shape_pt_lon: -90.044022,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.4652,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.175737,
    shape_pt_lon: -90.044159,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.556,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.176511,
    shape_pt_lon: -90.044284,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.6438,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.177255,
    shape_pt_lon: -90.044397,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.7274,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.177516,
    shape_pt_lon: -90.044428,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.7566,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.17934,
    shape_pt_lon: -90.044641,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.9615,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.179859,
    shape_pt_lon: -90.044688,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.0196,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.180981,
    shape_pt_lon: -90.044781,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.1459,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.182267,
    shape_pt_lon: -90.044879,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.2902,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.182855,
    shape_pt_lon: -90.044957,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.3556,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.183198,
    shape_pt_lon: -90.045033,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.395,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.18365,
    shape_pt_lon: -90.04517,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.4467,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.184641,
    shape_pt_lon: -90.045489,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.5614,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.184729,
    shape_pt_lon: -90.045514,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.5716,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.184789,
    shape_pt_lon: -90.045527,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.5787,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.18495,
    shape_pt_lon: -90.045532,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.5967,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.185061,
    shape_pt_lon: -90.045521,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.6088,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.18525,
    shape_pt_lon: -90.045488,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.63,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.185313,
    shape_pt_lon: -90.045474,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.6382,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.185818,
    shape_pt_lon: -90.045373,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.695,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.18604,
    shape_pt_lon: -90.045362,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.72,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.186298,
    shape_pt_lon: -90.045392,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.7491,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.186919,
    shape_pt_lon: -90.045563,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.8197,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.188061,
    shape_pt_lon: -90.045901,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.9514,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.188275,
    shape_pt_lon: -90.045963,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.9762,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.188615,
    shape_pt_lon: -90.046063,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.0152,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.189337,
    shape_pt_lon: -90.046276,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.0984,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.18952,
    shape_pt_lon: -90.046339,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.1193,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.189754,
    shape_pt_lon: -90.046461,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.1475,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.189897,
    shape_pt_lon: -90.046558,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.1654,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190069,
    shape_pt_lon: -90.046699,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.1893,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190274,
    shape_pt_lon: -90.046925,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.2204,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190451,
    shape_pt_lon: -90.047178,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.2495,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19056,
    shape_pt_lon: -90.047367,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.2709,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190592,
    shape_pt_lon: -90.047382,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.2745,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190672,
    shape_pt_lon: -90.047535,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.2911,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19073,
    shape_pt_lon: -90.047647,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.3034,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190773,
    shape_pt_lon: -90.04772,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.3106,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190807,
    shape_pt_lon: -90.047779,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.3178,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190886,
    shape_pt_lon: -90.047908,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.332,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190949,
    shape_pt_lon: -90.048007,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.3434,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.190988,
    shape_pt_lon: -90.048061,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.3505,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19105,
    shape_pt_lon: -90.048141,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.3597,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191131,
    shape_pt_lon: -90.048236,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3731,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191259,
    shape_pt_lon: -90.04836,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3909,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191379,
    shape_pt_lon: -90.048462,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.4068,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191496,
    shape_pt_lon: -90.048549,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.422,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191513,
    shape_pt_lon: -90.048591,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.4265,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.191961,
    shape_pt_lon: -90.048813,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4803,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.192642,
    shape_pt_lon: -90.049127,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.5623,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.192734,
    shape_pt_lon: -90.049171,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.573,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.193333,
    shape_pt_lon: -90.049443,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.6446,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.193534,
    shape_pt_lon: -90.049533,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.668,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.195193,
    shape_pt_lon: -90.050518,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.8742,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.195256,
    shape_pt_lon: -90.050556,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.8822,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.196616,
    shape_pt_lon: -90.051378,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.0513,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197169,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.1206,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197373,
    shape_pt_lon: -90.0518,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.1446,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197573,
    shape_pt_lon: -90.051834,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.1668,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197778,
    shape_pt_lon: -90.051822,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.1899,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197876,
    shape_pt_lon: -90.051803,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.201,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198052,
    shape_pt_lon: -90.051748,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.2217,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198459,
    shape_pt_lon: -90.051552,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.2707,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199097,
    shape_pt_lon: -90.051222,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.3478,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199498,
    shape_pt_lon: -90.051032,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.3959,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199905,
    shape_pt_lon: -90.050877,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.443,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201359,
    shape_pt_lon: -90.050391,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.6118,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201632,
    shape_pt_lon: -90.050291,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.6441,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20178,
    shape_pt_lon: -90.050218,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.6616,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20192,
    shape_pt_lon: -90.050113,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.6799,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.202096,
    shape_pt_lon: -90.04991,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.7075,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20232,
    shape_pt_lon: -90.049569,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.7466,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203052,
    shape_pt_lon: -90.04843,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.8782,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203186,
    shape_pt_lon: -90.048179,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.9057,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203252,
    shape_pt_lon: -90.048005,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.9232,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203497,
    shape_pt_lon: -90.047097,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.0095,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20365,
    shape_pt_lon: -90.046532,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.0636,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203748,
    shape_pt_lon: -90.046291,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.0868,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203859,
    shape_pt_lon: -90.046114,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.1075,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2042,
    shape_pt_lon: -90.045703,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.1605,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204489,
    shape_pt_lon: -90.045349,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.2057,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204629,
    shape_pt_lon: -90.045148,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.2306,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204759,
    shape_pt_lon: -90.044911,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.2564,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205341,
    shape_pt_lon: -90.043719,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.3816,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205682,
    shape_pt_lon: -90.042989,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.4577,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205699,
    shape_pt_lon: -90.042954,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.4622,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205919,
    shape_pt_lon: -90.042508,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.5094,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2061,
    shape_pt_lon: -90.04208,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5523,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206258,
    shape_pt_lon: -90.04169,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.5921,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206478,
    shape_pt_lon: -90.041092,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.6516,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206872,
    shape_pt_lon: -90.039979,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.7609,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207276,
    shape_pt_lon: -90.038844,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.8733,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207761,
    shape_pt_lon: -90.037481,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.008,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.208038,
    shape_pt_lon: -90.036709,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.0837,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20808,
    shape_pt_lon: -90.03659,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.0958,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.208649,
    shape_pt_lon: -90.035001,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.252,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.208984,
    shape_pt_lon: -90.034048,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.346,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.209029,
    shape_pt_lon: -90.033927,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.3581,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.209401,
    shape_pt_lon: -90.032919,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.4579,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20945,
    shape_pt_lon: -90.032765,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.4732,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207273,
    shape_pt_lon: -90.033193,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.7201,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206342,
    shape_pt_lon: -90.033361,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.8252,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20608,
    shape_pt_lon: -90.033414,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.8546,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205872,
    shape_pt_lon: -90.033457,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.878,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205616,
    shape_pt_lon: -90.033503,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.9072,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204353,
    shape_pt_lon: -90.033756,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.0501,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203881,
    shape_pt_lon: -90.03387,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.104,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203444,
    shape_pt_lon: -90.033949,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.1535,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203446,
    shape_pt_lon: -90.033888,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.1585,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203448,
    shape_pt_lon: -90.033824,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.1645,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203468,
    shape_pt_lon: -90.033153,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.2246,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20348,
    shape_pt_lon: -90.032477,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.2856,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20349,
    shape_pt_lon: -90.032073,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.3226,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203509,
    shape_pt_lon: -90.031567,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.3687,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203513,
    shape_pt_lon: -90.030982,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.4207,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203486,
    shape_pt_lon: -90.030527,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.4628,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203442,
    shape_pt_lon: -90.030152,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.4962,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203403,
    shape_pt_lon: -90.029908,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.5187,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203376,
    shape_pt_lon: -90.029734,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.535,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20331,
    shape_pt_lon: -90.02944,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.5629,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203278,
    shape_pt_lon: -90.029292,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.5765,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20314,
    shape_pt_lon: -90.028802,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.623,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203029,
    shape_pt_lon: -90.028402,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.6609,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.202788,
    shape_pt_lon: -90.027593,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.7388,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20248,
    shape_pt_lon: -90.026576,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.8372,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.202393,
    shape_pt_lon: -90.026261,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.8679,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201975,
    shape_pt_lon: -90.024691,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.0162,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201929,
    shape_pt_lon: -90.024546,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.0311,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201819,
    shape_pt_lon: -90.024208,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.0637,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201736,
    shape_pt_lon: -90.024005,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.0839,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201657,
    shape_pt_lon: -90.023847,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.1014,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201558,
    shape_pt_lon: -90.023666,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.1208,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201388,
    shape_pt_lon: -90.023418,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.1499,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201308,
    shape_pt_lon: -90.023317,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.1633,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201239,
    shape_pt_lon: -90.02323,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.1739,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200657,
    shape_pt_lon: -90.022631,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.2591,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200389,
    shape_pt_lon: -90.02235,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.2981,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200212,
    shape_pt_lon: -90.022127,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.3257,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200036,
    shape_pt_lon: -90.021857,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.357,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199861,
    shape_pt_lon: -90.021516,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.3939,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199743,
    shape_pt_lon: -90.021219,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.4238,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199655,
    shape_pt_lon: -90.020923,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.4526,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.1996,
    shape_pt_lon: -90.020671,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.4764,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199542,
    shape_pt_lon: -90.020288,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.5109,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199535,
    shape_pt_lon: -90.020161,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.5229,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199489,
    shape_pt_lon: -90.01815,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.704,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199466,
    shape_pt_lon: -90.016651,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.8391,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199497,
    shape_pt_lon: -90.016272,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.8743,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199599,
    shape_pt_lon: -90.015598,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.9363,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199626,
    shape_pt_lon: -90.015212,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.9704,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199629,
    shape_pt_lon: -90.015069,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.9834,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199589,
    shape_pt_lon: -90.014043,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.0766,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199458,
    shape_pt_lon: -90.010878,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.3629,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199372,
    shape_pt_lon: -90.007917,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.6301,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199369,
    shape_pt_lon: -90.007784,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.6421,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199345,
    shape_pt_lon: -90.006988,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.7142,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199301,
    shape_pt_lon: -90.006677,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.7426,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199278,
    shape_pt_lon: -90.006568,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.7528,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199219,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.774,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199102,
    shape_pt_lon: -90.006043,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.804,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199008,
    shape_pt_lon: -90.005858,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.8237,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198876,
    shape_pt_lon: -90.005642,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.8479,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198413,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.9296,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19811,
    shape_pt_lon: -90.00446,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.9852,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19808,
    shape_pt_lon: -90.004406,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.991,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197929,
    shape_pt_lon: -90.004129,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.0212,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19786,
    shape_pt_lon: -90.003951,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.0391,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197801,
    shape_pt_lon: -90.003758,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.0581,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197749,
    shape_pt_lon: -90.003501,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.0819,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19772,
    shape_pt_lon: -90.003167,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.112,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197638,
    shape_pt_lon: -90.00051,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.3522,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197597,
    shape_pt_lon: -89.999062,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.4833,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19755,
    shape_pt_lon: -89.997559,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.6184,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197542,
    shape_pt_lon: -89.997134,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.6574,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19749,
    shape_pt_lon: -89.995601,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.7955,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197462,
    shape_pt_lon: -89.994693,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.8776,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197401,
    shape_pt_lon: -89.992738,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.0547,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19737,
    shape_pt_lon: -89.991821,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.1368,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19737,
    shape_pt_lon: -89.991756,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.1428,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19734,
    shape_pt_lon: -89.990797,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.2298,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197279,
    shape_pt_lon: -89.988708,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.419,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197262,
    shape_pt_lon: -89.988212,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.463,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197255,
    shape_pt_lon: -89.987682,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.511,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197292,
    shape_pt_lon: -89.987288,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.5472,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197322,
    shape_pt_lon: -89.987118,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.5625,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.1974,
    shape_pt_lon: -89.986785,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.5938,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197551,
    shape_pt_lon: -89.986352,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.6364,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.197682,
    shape_pt_lon: -89.986076,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.6655,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19828,
    shape_pt_lon: -89.985052,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.7801,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198489,
    shape_pt_lon: -89.984702,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.8187,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198819,
    shape_pt_lon: -89.984139,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.8818,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.198962,
    shape_pt_lon: -89.983803,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.9158,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19907,
    shape_pt_lon: -89.98347,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.9481,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199151,
    shape_pt_lon: -89.983123,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.9813,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199194,
    shape_pt_lon: -89.982808,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.0098,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199216,
    shape_pt_lon: -89.982458,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.0419,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199207,
    shape_pt_lon: -89.982061,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.0769,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.1992,
    shape_pt_lon: -89.98143,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.1339,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199182,
    shape_pt_lon: -89.980914,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.181,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199157,
    shape_pt_lon: -89.97989,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.273,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199148,
    shape_pt_lon: -89.979453,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.313,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199132,
    shape_pt_lon: -89.978818,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.37,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199109,
    shape_pt_lon: -89.977979,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.4461,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199087,
    shape_pt_lon: -89.976659,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.5651,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199177,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.6418,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199273,
    shape_pt_lon: -89.974938,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.7225,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199463,
    shape_pt_lon: -89.972724,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.9226,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19956,
    shape_pt_lon: -89.971866,
    shape_pt_sequence: 243,
    shape_dist_traveled: 14.0014,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199643,
    shape_pt_lon: -89.971157,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.066,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199839,
    shape_pt_lon: -89.969113,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.2514,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199852,
    shape_pt_lon: -89.968971,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.2645,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19989,
    shape_pt_lon: -89.968601,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.2987,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199863,
    shape_pt_lon: -89.968512,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.3073,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19994,
    shape_pt_lon: -89.96731,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.4156,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200118,
    shape_pt_lon: -89.965319,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.5967,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200126,
    shape_pt_lon: -89.965166,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.6108,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20014,
    shape_pt_lon: -89.965013,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.6249,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200167,
    shape_pt_lon: -89.964412,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.679,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200178,
    shape_pt_lon: -89.963941,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.721,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200175,
    shape_pt_lon: -89.963501,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.761,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200107,
    shape_pt_lon: -89.962089,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.8893,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200134,
    shape_pt_lon: -89.961968,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.8997,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200105,
    shape_pt_lon: -89.961591,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.9338,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199999,
    shape_pt_lon: -89.960178,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.0624,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19995,
    shape_pt_lon: -89.959553,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.1186,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199909,
    shape_pt_lon: -89.958923,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.1758,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199905,
    shape_pt_lon: -89.958843,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.1838,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199893,
    shape_pt_lon: -89.95874,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.1929,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199746,
    shape_pt_lon: -89.95677,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.3717,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199711,
    shape_pt_lon: -89.956287,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.4148,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199655,
    shape_pt_lon: -89.955507,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.4862,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199602,
    shape_pt_lon: -89.954854,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.5454,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199591,
    shape_pt_lon: -89.954697,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.5595,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19937,
    shape_pt_lon: -89.951692,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.8316,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199172,
    shape_pt_lon: -89.949016,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.0746,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199127,
    shape_pt_lon: -89.947776,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.1867,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199089,
    shape_pt_lon: -89.94665,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.2888,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199089,
    shape_pt_lon: -89.9465,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.3018,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199096,
    shape_pt_lon: -89.945861,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.3598,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.19913,
    shape_pt_lon: -89.944614,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.4718,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199161,
    shape_pt_lon: -89.942194,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.6909,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199188,
    shape_pt_lon: -89.941817,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.725,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199266,
    shape_pt_lon: -89.941314,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.7707,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199347,
    shape_pt_lon: -89.940982,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.8023,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199457,
    shape_pt_lon: -89.940618,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.8375,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199601,
    shape_pt_lon: -89.940243,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.875,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199663,
    shape_pt_lon: -89.940107,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.8889,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199811,
    shape_pt_lon: -89.939825,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.92,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.199981,
    shape_pt_lon: -89.939536,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.9522,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200183,
    shape_pt_lon: -89.93926,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.9855,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.200766,
    shape_pt_lon: -89.938524,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.0788,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20133,
    shape_pt_lon: -89.937835,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.1672,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201335,
    shape_pt_lon: -89.937831,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.1672,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201552,
    shape_pt_lon: -89.937562,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.2026,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.201551,
    shape_pt_lon: -89.937561,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.2026,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20205,
    shape_pt_lon: -89.93694,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.2818,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20235,
    shape_pt_lon: -89.936569,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.3284,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.202967,
    shape_pt_lon: -89.935811,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.426,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203178,
    shape_pt_lon: -89.93553,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.4607,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203344,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.4897,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203433,
    shape_pt_lon: -89.935135,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.5061,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203607,
    shape_pt_lon: -89.934831,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.54,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20376,
    shape_pt_lon: -89.934533,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.5719,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203909,
    shape_pt_lon: -89.934189,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.6072,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204208,
    shape_pt_lon: -89.93346,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.6815,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204396,
    shape_pt_lon: -89.932998,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.7276,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204533,
    shape_pt_lon: -89.932563,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.7703,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204627,
    shape_pt_lon: -89.932151,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.8089,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204694,
    shape_pt_lon: -89.931725,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.8475,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204726,
    shape_pt_lon: -89.931291,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.8867,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20473,
    shape_pt_lon: -89.931042,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.9097,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204712,
    shape_pt_lon: -89.930608,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.9488,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204607,
    shape_pt_lon: -89.92963,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.0375,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204599,
    shape_pt_lon: -89.929564,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.0435,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204591,
    shape_pt_lon: -89.929493,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.0506,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204552,
    shape_pt_lon: -89.92913,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.0829,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204537,
    shape_pt_lon: -89.928709,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.1209,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204554,
    shape_pt_lon: -89.928264,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.162,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204579,
    shape_pt_lon: -89.928023,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.1832,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204608,
    shape_pt_lon: -89.927821,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.2024,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204693,
    shape_pt_lon: -89.927393,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.2417,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204808,
    shape_pt_lon: -89.926997,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.28,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205088,
    shape_pt_lon: -89.926151,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.3621,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205187,
    shape_pt_lon: -89.92573,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.4016,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205262,
    shape_pt_lon: -89.925267,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.4446,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205298,
    shape_pt_lon: -89.92487,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.4808,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205307,
    shape_pt_lon: -89.924639,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.5018,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.924559,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.5088,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205306,
    shape_pt_lon: -89.924193,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.5418,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923879,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.5698,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923742,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.5828,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923586,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.5968,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205299,
    shape_pt_lon: -89.923478,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.6068,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205297,
    shape_pt_lon: -89.923384,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.6148,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205284,
    shape_pt_lon: -89.922931,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.6559,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205276,
    shape_pt_lon: -89.922495,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.6949,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205251,
    shape_pt_lon: -89.921838,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.755,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205187,
    shape_pt_lon: -89.921712,
    shape_pt_sequence: 333,
    shape_dist_traveled: 18.768,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205186,
    shape_pt_lon: -89.921631,
    shape_pt_sequence: 334,
    shape_dist_traveled: 18.775,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20518,
    shape_pt_lon: -89.921547,
    shape_pt_sequence: 335,
    shape_dist_traveled: 18.7831,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205175,
    shape_pt_lon: -89.921432,
    shape_pt_sequence: 336,
    shape_dist_traveled: 18.7931,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205159,
    shape_pt_lon: -89.921165,
    shape_pt_sequence: 337,
    shape_dist_traveled: 18.8171,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20514,
    shape_pt_lon: -89.920008,
    shape_pt_sequence: 338,
    shape_dist_traveled: 18.9221,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205137,
    shape_pt_lon: -89.919538,
    shape_pt_sequence: 339,
    shape_dist_traveled: 18.9642,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205161,
    shape_pt_lon: -89.918967,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.0162,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205193,
    shape_pt_lon: -89.918636,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.0465,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20532,
    shape_pt_lon: -89.917566,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.1435,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205521,
    shape_pt_lon: -89.915894,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.2963,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205599,
    shape_pt_lon: -89.91525,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.355,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205629,
    shape_pt_lon: -89.915016,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.3762,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205775,
    shape_pt_lon: -89.913887,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.4796,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205962,
    shape_pt_lon: -89.912442,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.6123,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206019,
    shape_pt_lon: -89.912032,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.6497,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206153,
    shape_pt_lon: -89.911088,
    shape_pt_sequence: 349,
    shape_dist_traveled: 19.7361,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206262,
    shape_pt_lon: -89.910296,
    shape_pt_sequence: 350,
    shape_dist_traveled: 19.8082,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206556,
    shape_pt_lon: -89.908229,
    shape_pt_sequence: 351,
    shape_dist_traveled: 19.9981,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206735,
    shape_pt_lon: -89.906913,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.1188,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20698,
    shape_pt_lon: -89.90516,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.2793,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206998,
    shape_pt_lon: -89.904968,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.2964,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207047,
    shape_pt_lon: -89.904448,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.3436,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207072,
    shape_pt_lon: -89.903455,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.4337,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207066,
    shape_pt_lon: -89.903221,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.4547,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207059,
    shape_pt_lon: -89.902954,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.4787,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207033,
    shape_pt_lon: -89.901731,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.5887,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.207007,
    shape_pt_lon: -89.90084,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.6698,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206967,
    shape_pt_lon: -89.898907,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.8438,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206942,
    shape_pt_lon: -89.897824,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.9419,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206915,
    shape_pt_lon: -89.896698,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.0439,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206915,
    shape_pt_lon: -89.89664,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.0489,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206917,
    shape_pt_lon: -89.896351,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.0749,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206908,
    shape_pt_lon: -89.896006,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.1059,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206901,
    shape_pt_lon: -89.895814,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.124,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206859,
    shape_pt_lon: -89.894608,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.233,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206855,
    shape_pt_lon: -89.89449,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.2431,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206791,
    shape_pt_lon: -89.893581,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.3254,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20671,
    shape_pt_lon: -89.892631,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.4117,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206499,
    shape_pt_lon: -89.889942,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.6559,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20641,
    shape_pt_lon: -89.88908,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.7344,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20636,
    shape_pt_lon: -89.888739,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.765,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206279,
    shape_pt_lon: -89.888328,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.8041,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.206191,
    shape_pt_lon: -89.887941,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.8405,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205882,
    shape_pt_lon: -89.886692,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.9575,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205607,
    shape_pt_lon: -89.885501,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.0699,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205553,
    shape_pt_lon: -89.885253,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.0927,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20545,
    shape_pt_lon: -89.88469,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.1449,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20538,
    shape_pt_lon: -89.88427,
    shape_pt_sequence: 381,
    shape_dist_traveled: 22.1837,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205345,
    shape_pt_lon: -89.884056,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.2031,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20527,
    shape_pt_lon: -89.883482,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.2557,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205064,
    shape_pt_lon: -89.881548,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.4322,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204979,
    shape_pt_lon: -89.880689,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.5108,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20493,
    shape_pt_lon: -89.880127,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.561,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204874,
    shape_pt_lon: -89.87932,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.6343,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204869,
    shape_pt_lon: -89.879172,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.6473,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204846,
    shape_pt_lon: -89.878423,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.7154,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204846,
    shape_pt_lon: -89.878256,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.7304,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204901,
    shape_pt_lon: -89.878128,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.7439,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204869,
    shape_pt_lon: -89.87734,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.815,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20486,
    shape_pt_lon: -89.877088,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.838,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20483,
    shape_pt_lon: -89.876253,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.9131,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20479,
    shape_pt_lon: -89.875326,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.9972,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204746,
    shape_pt_lon: -89.874968,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.0295,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204706,
    shape_pt_lon: -89.874771,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.0482,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204588,
    shape_pt_lon: -89.874302,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.0922,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204483,
    shape_pt_lon: -89.87389,
    shape_pt_sequence: 399,
    shape_dist_traveled: 23.1308,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204356,
    shape_pt_lon: -89.873421,
    shape_pt_sequence: 400,
    shape_dist_traveled: 23.176,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204271,
    shape_pt_lon: -89.873011,
    shape_pt_sequence: 401,
    shape_dist_traveled: 23.2143,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204237,
    shape_pt_lon: -89.872665,
    shape_pt_sequence: 402,
    shape_dist_traveled: 23.2456,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204211,
    shape_pt_lon: -89.871753,
    shape_pt_sequence: 403,
    shape_dist_traveled: 23.3276,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.2042,
    shape_pt_lon: -89.871225,
    shape_pt_sequence: 404,
    shape_dist_traveled: 23.3756,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204191,
    shape_pt_lon: -89.870786,
    shape_pt_sequence: 405,
    shape_dist_traveled: 23.4156,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204139,
    shape_pt_lon: -89.869651,
    shape_pt_sequence: 406,
    shape_dist_traveled: 23.5178,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204139,
    shape_pt_lon: -89.868962,
    shape_pt_sequence: 407,
    shape_dist_traveled: 23.5798,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204149,
    shape_pt_lon: -89.868657,
    shape_pt_sequence: 408,
    shape_dist_traveled: 23.6078,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204158,
    shape_pt_lon: -89.868544,
    shape_pt_sequence: 409,
    shape_dist_traveled: 23.6179,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204198,
    shape_pt_lon: -89.868068,
    shape_pt_sequence: 410,
    shape_dist_traveled: 23.6612,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204246,
    shape_pt_lon: -89.867671,
    shape_pt_sequence: 411,
    shape_dist_traveled: 23.6975,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204503,
    shape_pt_lon: -89.865992,
    shape_pt_sequence: 412,
    shape_dist_traveled: 23.8513,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20453,
    shape_pt_lon: -89.865808,
    shape_pt_sequence: 413,
    shape_dist_traveled: 23.8685,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204579,
    shape_pt_lon: -89.865487,
    shape_pt_sequence: 414,
    shape_dist_traveled: 23.8982,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204628,
    shape_pt_lon: -89.865175,
    shape_pt_sequence: 415,
    shape_dist_traveled: 23.9268,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204634,
    shape_pt_lon: -89.865116,
    shape_pt_sequence: 416,
    shape_dist_traveled: 23.9318,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204709,
    shape_pt_lon: -89.864499,
    shape_pt_sequence: 417,
    shape_dist_traveled: 23.9885,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204723,
    shape_pt_lon: -89.86433,
    shape_pt_sequence: 418,
    shape_dist_traveled: 24.0035,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204737,
    shape_pt_lon: -89.864169,
    shape_pt_sequence: 419,
    shape_dist_traveled: 24.0187,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204747,
    shape_pt_lon: -89.864039,
    shape_pt_sequence: 420,
    shape_dist_traveled: 24.0307,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.863518,
    shape_pt_sequence: 421,
    shape_dist_traveled: 24.0777,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.863066,
    shape_pt_sequence: 422,
    shape_dist_traveled: 24.1187,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.862667,
    shape_pt_sequence: 423,
    shape_dist_traveled: 24.1547,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.862068,
    shape_pt_sequence: 424,
    shape_dist_traveled: 24.2088,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.861506,
    shape_pt_sequence: 425,
    shape_dist_traveled: 24.2588,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204753,
    shape_pt_lon: -89.861062,
    shape_pt_sequence: 426,
    shape_dist_traveled: 24.2988,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204751,
    shape_pt_lon: -89.860572,
    shape_pt_sequence: 427,
    shape_dist_traveled: 24.3438,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204747,
    shape_pt_lon: -89.860231,
    shape_pt_sequence: 428,
    shape_dist_traveled: 24.3738,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204719,
    shape_pt_lon: -89.859727,
    shape_pt_sequence: 429,
    shape_dist_traveled: 24.4199,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204708,
    shape_pt_lon: -89.859538,
    shape_pt_sequence: 430,
    shape_dist_traveled: 24.4369,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204703,
    shape_pt_lon: -89.859466,
    shape_pt_sequence: 431,
    shape_dist_traveled: 24.444,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204672,
    shape_pt_lon: -89.859051,
    shape_pt_sequence: 432,
    shape_dist_traveled: 24.4811,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20466,
    shape_pt_lon: -89.858883,
    shape_pt_sequence: 433,
    shape_dist_traveled: 24.4962,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204611,
    shape_pt_lon: -89.858191,
    shape_pt_sequence: 434,
    shape_dist_traveled: 24.5594,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204606,
    shape_pt_lon: -89.858129,
    shape_pt_sequence: 435,
    shape_dist_traveled: 24.5644,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204587,
    shape_pt_lon: -89.85764,
    shape_pt_sequence: 436,
    shape_dist_traveled: 24.6085,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204583,
    shape_pt_lon: -89.85737,
    shape_pt_sequence: 437,
    shape_dist_traveled: 24.6335,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204596,
    shape_pt_lon: -89.856923,
    shape_pt_sequence: 438,
    shape_dist_traveled: 24.6736,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204606,
    shape_pt_lon: -89.856721,
    shape_pt_sequence: 439,
    shape_dist_traveled: 24.6916,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204679,
    shape_pt_lon: -89.855238,
    shape_pt_sequence: 440,
    shape_dist_traveled: 24.8258,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204676,
    shape_pt_lon: -89.855239,
    shape_pt_sequence: 441,
    shape_dist_traveled: 24.8258,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204731,
    shape_pt_lon: -89.854258,
    shape_pt_sequence: 442,
    shape_dist_traveled: 24.915,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204732,
    shape_pt_lon: -89.85426,
    shape_pt_sequence: 443,
    shape_dist_traveled: 24.915,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.853701,
    shape_pt_sequence: 444,
    shape_dist_traveled: 24.9651,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.853387,
    shape_pt_sequence: 445,
    shape_dist_traveled: 24.9931,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204767,
    shape_pt_lon: -89.852329,
    shape_pt_sequence: 446,
    shape_dist_traveled: 25.0892,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204767,
    shape_pt_lon: -89.851276,
    shape_pt_sequence: 447,
    shape_dist_traveled: 25.1842,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20476,
    shape_pt_lon: -89.850822,
    shape_pt_sequence: 448,
    shape_dist_traveled: 25.2252,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204759,
    shape_pt_lon: -89.849987,
    shape_pt_sequence: 449,
    shape_dist_traveled: 25.3002,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204737,
    shape_pt_lon: -89.84802,
    shape_pt_sequence: 450,
    shape_dist_traveled: 25.4782,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204736,
    shape_pt_lon: -89.847663,
    shape_pt_sequence: 451,
    shape_dist_traveled: 25.5102,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204723,
    shape_pt_lon: -89.845065,
    shape_pt_sequence: 452,
    shape_dist_traveled: 25.7452,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204722,
    shape_pt_lon: -89.844508,
    shape_pt_sequence: 453,
    shape_dist_traveled: 25.7952,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204732,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 454,
    shape_dist_traveled: 25.8592,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204745,
    shape_pt_lon: -89.842888,
    shape_pt_sequence: 455,
    shape_dist_traveled: 25.9412,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204746,
    shape_pt_lon: -89.842389,
    shape_pt_sequence: 456,
    shape_dist_traveled: 25.9862,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204756,
    shape_pt_lon: -89.841991,
    shape_pt_sequence: 457,
    shape_dist_traveled: 26.0223,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204763,
    shape_pt_lon: -89.841871,
    shape_pt_sequence: 458,
    shape_dist_traveled: 26.0333,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204717,
    shape_pt_lon: -89.841729,
    shape_pt_sequence: 459,
    shape_dist_traveled: 26.0472,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204728,
    shape_pt_lon: -89.841413,
    shape_pt_sequence: 460,
    shape_dist_traveled: 26.0753,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204794,
    shape_pt_lon: -89.840762,
    shape_pt_sequence: 461,
    shape_dist_traveled: 26.1347,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204839,
    shape_pt_lon: -89.840487,
    shape_pt_sequence: 462,
    shape_dist_traveled: 26.1602,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204953,
    shape_pt_lon: -89.839827,
    shape_pt_sequence: 463,
    shape_dist_traveled: 26.2206,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205027,
    shape_pt_lon: -89.839448,
    shape_pt_sequence: 464,
    shape_dist_traveled: 26.2567,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205176,
    shape_pt_lon: -89.838634,
    shape_pt_sequence: 465,
    shape_dist_traveled: 26.3315,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205291,
    shape_pt_lon: -89.838002,
    shape_pt_sequence: 466,
    shape_dist_traveled: 26.3899,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205381,
    shape_pt_lon: -89.837359,
    shape_pt_sequence: 467,
    shape_dist_traveled: 26.449,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20542,
    shape_pt_lon: -89.836892,
    shape_pt_sequence: 468,
    shape_dist_traveled: 26.4912,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205449,
    shape_pt_lon: -89.836349,
    shape_pt_sequence: 469,
    shape_dist_traveled: 26.5402,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205449,
    shape_pt_lon: -89.835738,
    shape_pt_sequence: 470,
    shape_dist_traveled: 26.5953,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205419,
    shape_pt_lon: -89.835186,
    shape_pt_sequence: 471,
    shape_dist_traveled: 26.6454,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205352,
    shape_pt_lon: -89.834534,
    shape_pt_sequence: 472,
    shape_dist_traveled: 26.7048,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205209,
    shape_pt_lon: -89.833177,
    shape_pt_sequence: 473,
    shape_dist_traveled: 26.8289,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205166,
    shape_pt_lon: -89.832782,
    shape_pt_sequence: 474,
    shape_dist_traveled: 26.8641,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.205023,
    shape_pt_lon: -89.831732,
    shape_pt_sequence: 475,
    shape_dist_traveled: 26.9604,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204859,
    shape_pt_lon: -89.830563,
    shape_pt_sequence: 476,
    shape_dist_traveled: 27.068,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204661,
    shape_pt_lon: -89.829159,
    shape_pt_sequence: 477,
    shape_dist_traveled: 27.1968,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204435,
    shape_pt_lon: -89.827481,
    shape_pt_sequence: 478,
    shape_dist_traveled: 27.3499,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204359,
    shape_pt_lon: -89.826941,
    shape_pt_sequence: 479,
    shape_dist_traveled: 27.3995,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204218,
    shape_pt_lon: -89.825929,
    shape_pt_sequence: 480,
    shape_dist_traveled: 27.4919,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204016,
    shape_pt_lon: -89.824479,
    shape_pt_sequence: 481,
    shape_dist_traveled: 27.6248,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203842,
    shape_pt_lon: -89.823184,
    shape_pt_sequence: 482,
    shape_dist_traveled: 27.7433,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203786,
    shape_pt_lon: -89.822504,
    shape_pt_sequence: 483,
    shape_dist_traveled: 27.8056,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203767,
    shape_pt_lon: -89.821837,
    shape_pt_sequence: 484,
    shape_dist_traveled: 27.8656,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203773,
    shape_pt_lon: -89.821403,
    shape_pt_sequence: 485,
    shape_dist_traveled: 27.9046,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203799,
    shape_pt_lon: -89.820969,
    shape_pt_sequence: 486,
    shape_dist_traveled: 27.9437,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203816,
    shape_pt_lon: -89.82077,
    shape_pt_sequence: 487,
    shape_dist_traveled: 27.9619,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20387,
    shape_pt_lon: -89.820242,
    shape_pt_sequence: 488,
    shape_dist_traveled: 28.0102,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204047,
    shape_pt_lon: -89.819071,
    shape_pt_sequence: 489,
    shape_dist_traveled: 28.1183,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204531,
    shape_pt_lon: -89.815911,
    shape_pt_sequence: 490,
    shape_dist_traveled: 28.4084,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204573,
    shape_pt_lon: -89.815494,
    shape_pt_sequence: 491,
    shape_dist_traveled: 28.4457,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204605,
    shape_pt_lon: -89.815062,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.4849,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204612,
    shape_pt_lon: -89.814805,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.5079,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204616,
    shape_pt_lon: -89.814698,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.5179,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204618,
    shape_pt_lon: -89.81461,
    shape_pt_sequence: 495,
    shape_dist_traveled: 28.5259,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204621,
    shape_pt_lon: -89.814296,
    shape_pt_sequence: 496,
    shape_dist_traveled: 28.5539,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204614,
    shape_pt_lon: -89.813809,
    shape_pt_sequence: 497,
    shape_dist_traveled: 28.598,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204599,
    shape_pt_lon: -89.812558,
    shape_pt_sequence: 498,
    shape_dist_traveled: 28.711,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204589,
    shape_pt_lon: -89.8125,
    shape_pt_sequence: 499,
    shape_dist_traveled: 28.717,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204571,
    shape_pt_lon: -89.811818,
    shape_pt_sequence: 500,
    shape_dist_traveled: 28.7781,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204556,
    shape_pt_lon: -89.810588,
    shape_pt_sequence: 501,
    shape_dist_traveled: 28.8891,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204542,
    shape_pt_lon: -89.808528,
    shape_pt_sequence: 502,
    shape_dist_traveled: 29.0751,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204526,
    shape_pt_lon: -89.805828,
    shape_pt_sequence: 503,
    shape_dist_traveled: 29.3191,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204519,
    shape_pt_lon: -89.803754,
    shape_pt_sequence: 504,
    shape_dist_traveled: 29.5061,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204538,
    shape_pt_lon: -89.802351,
    shape_pt_sequence: 505,
    shape_dist_traveled: 29.6331,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204549,
    shape_pt_lon: -89.800257,
    shape_pt_sequence: 506,
    shape_dist_traveled: 29.8221,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204559,
    shape_pt_lon: -89.799067,
    shape_pt_sequence: 507,
    shape_dist_traveled: 29.9291,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204566,
    shape_pt_lon: -89.798221,
    shape_pt_sequence: 508,
    shape_dist_traveled: 30.0062,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204565,
    shape_pt_lon: -89.798047,
    shape_pt_sequence: 509,
    shape_dist_traveled: 30.0212,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204572,
    shape_pt_lon: -89.796836,
    shape_pt_sequence: 510,
    shape_dist_traveled: 30.1312,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204569,
    shape_pt_lon: -89.796509,
    shape_pt_sequence: 511,
    shape_dist_traveled: 30.1602,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204581,
    shape_pt_lon: -89.79584,
    shape_pt_sequence: 512,
    shape_dist_traveled: 30.2212,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20458,
    shape_pt_lon: -89.795448,
    shape_pt_sequence: 513,
    shape_dist_traveled: 30.2562,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204569,
    shape_pt_lon: -89.794867,
    shape_pt_sequence: 514,
    shape_dist_traveled: 30.3082,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204566,
    shape_pt_lon: -89.794792,
    shape_pt_sequence: 515,
    shape_dist_traveled: 30.3152,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204526,
    shape_pt_lon: -89.793998,
    shape_pt_sequence: 516,
    shape_dist_traveled: 30.3874,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204497,
    shape_pt_lon: -89.793486,
    shape_pt_sequence: 517,
    shape_dist_traveled: 30.4334,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204447,
    shape_pt_lon: -89.792646,
    shape_pt_sequence: 518,
    shape_dist_traveled: 30.5096,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204423,
    shape_pt_lon: -89.792177,
    shape_pt_sequence: 519,
    shape_dist_traveled: 30.5517,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204416,
    shape_pt_lon: -89.792032,
    shape_pt_sequence: 520,
    shape_dist_traveled: 30.5647,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20441,
    shape_pt_lon: -89.791955,
    shape_pt_sequence: 521,
    shape_dist_traveled: 30.5718,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204371,
    shape_pt_lon: -89.791276,
    shape_pt_sequence: 522,
    shape_dist_traveled: 30.6339,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204344,
    shape_pt_lon: -89.790127,
    shape_pt_sequence: 523,
    shape_dist_traveled: 30.737,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204324,
    shape_pt_lon: -89.78874,
    shape_pt_sequence: 524,
    shape_dist_traveled: 30.863,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204323,
    shape_pt_lon: -89.788632,
    shape_pt_sequence: 525,
    shape_dist_traveled: 30.872,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204323,
    shape_pt_lon: -89.788465,
    shape_pt_sequence: 526,
    shape_dist_traveled: 30.887,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204288,
    shape_pt_lon: -89.785391,
    shape_pt_sequence: 527,
    shape_dist_traveled: 31.165,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204288,
    shape_pt_lon: -89.784891,
    shape_pt_sequence: 528,
    shape_dist_traveled: 31.21,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204287,
    shape_pt_lon: -89.784822,
    shape_pt_sequence: 529,
    shape_dist_traveled: 31.216,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204286,
    shape_pt_lon: -89.784744,
    shape_pt_sequence: 530,
    shape_dist_traveled: 31.223,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.782178,
    shape_pt_sequence: 531,
    shape_dist_traveled: 31.455,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204247,
    shape_pt_lon: -89.78141,
    shape_pt_sequence: 532,
    shape_dist_traveled: 31.524,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20425,
    shape_pt_lon: -89.779726,
    shape_pt_sequence: 533,
    shape_dist_traveled: 31.676,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204252,
    shape_pt_lon: -89.778769,
    shape_pt_sequence: 534,
    shape_dist_traveled: 31.763,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204264,
    shape_pt_lon: -89.777927,
    shape_pt_sequence: 535,
    shape_dist_traveled: 31.839,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.77771,
    shape_pt_sequence: 536,
    shape_dist_traveled: 31.858,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20425,
    shape_pt_lon: -89.777516,
    shape_pt_sequence: 537,
    shape_dist_traveled: 31.8761,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204264,
    shape_pt_lon: -89.775733,
    shape_pt_sequence: 538,
    shape_dist_traveled: 32.0371,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204269,
    shape_pt_lon: -89.774762,
    shape_pt_sequence: 539,
    shape_dist_traveled: 32.1251,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204259,
    shape_pt_lon: -89.773626,
    shape_pt_sequence: 540,
    shape_dist_traveled: 32.2271,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204257,
    shape_pt_lon: -89.773503,
    shape_pt_sequence: 541,
    shape_dist_traveled: 32.2381,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204242,
    shape_pt_lon: -89.772793,
    shape_pt_sequence: 542,
    shape_dist_traveled: 32.3021,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20424,
    shape_pt_lon: -89.772509,
    shape_pt_sequence: 543,
    shape_dist_traveled: 32.3281,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204242,
    shape_pt_lon: -89.772402,
    shape_pt_sequence: 544,
    shape_dist_traveled: 32.3381,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204256,
    shape_pt_lon: -89.771595,
    shape_pt_sequence: 545,
    shape_dist_traveled: 32.4111,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204267,
    shape_pt_lon: -89.770782,
    shape_pt_sequence: 546,
    shape_dist_traveled: 32.4842,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204268,
    shape_pt_lon: -89.770612,
    shape_pt_sequence: 547,
    shape_dist_traveled: 32.4992,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204268,
    shape_pt_lon: -89.770559,
    shape_pt_sequence: 548,
    shape_dist_traveled: 32.5042,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204262,
    shape_pt_lon: -89.768984,
    shape_pt_sequence: 549,
    shape_dist_traveled: 32.6462,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204258,
    shape_pt_lon: -89.768001,
    shape_pt_sequence: 550,
    shape_dist_traveled: 32.7352,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204273,
    shape_pt_lon: -89.767017,
    shape_pt_sequence: 551,
    shape_dist_traveled: 32.8242,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204342,
    shape_pt_lon: -89.765623,
    shape_pt_sequence: 552,
    shape_dist_traveled: 32.9504,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204409,
    shape_pt_lon: -89.765499,
    shape_pt_sequence: 553,
    shape_dist_traveled: 32.9635,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204411,
    shape_pt_lon: -89.765423,
    shape_pt_sequence: 554,
    shape_dist_traveled: 32.9705,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204475,
    shape_pt_lon: -89.764172,
    shape_pt_sequence: 555,
    shape_dist_traveled: 33.0838,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204479,
    shape_pt_lon: -89.764082,
    shape_pt_sequence: 556,
    shape_dist_traveled: 33.0918,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204482,
    shape_pt_lon: -89.764001,
    shape_pt_sequence: 557,
    shape_dist_traveled: 33.0988,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.763337,
    shape_pt_sequence: 558,
    shape_dist_traveled: 33.1589,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204496,
    shape_pt_lon: -89.762447,
    shape_pt_sequence: 559,
    shape_dist_traveled: 33.2389,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204503,
    shape_pt_lon: -89.760605,
    shape_pt_sequence: 560,
    shape_dist_traveled: 33.4059,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.20448,
    shape_pt_lon: -89.760606,
    shape_pt_sequence: 561,
    shape_dist_traveled: 33.4081,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204398,
    shape_pt_lon: -89.760617,
    shape_pt_sequence: 562,
    shape_dist_traveled: 33.4181,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.204059,
    shape_pt_lon: -89.760607,
    shape_pt_sequence: 563,
    shape_dist_traveled: 33.4561,
  },
  {
    shape_id: 38241,
    shape_pt_lat: 35.203782,
    shape_pt_lon: -89.760608,
    shape_pt_sequence: 564,
    shape_dist_traveled: 33.4871,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203782,
    shape_pt_lon: -89.760608,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203576,
    shape_pt_lon: -89.760598,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.023,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203388,
    shape_pt_lon: -89.760599,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.044,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203309,
    shape_pt_lon: -89.760589,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.053,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20316,
    shape_pt_lon: -89.760577,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0691,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203093,
    shape_pt_lon: -89.760526,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0781,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202806,
    shape_pt_lon: -89.760473,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1105,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20256,
    shape_pt_lon: -89.760389,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1386,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202339,
    shape_pt_lon: -89.760279,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.1656,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202146,
    shape_pt_lon: -89.760158,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.1902,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201916,
    shape_pt_lon: -89.759957,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.221,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201713,
    shape_pt_lon: -89.759739,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.2514,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201546,
    shape_pt_lon: -89.759512,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.2784,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201383,
    shape_pt_lon: -89.759241,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.3098,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201232,
    shape_pt_lon: -89.758903,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.3438,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201179,
    shape_pt_lon: -89.758744,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.3599,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201088,
    shape_pt_lon: -89.758358,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3963,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201048,
    shape_pt_lon: -89.758073,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.4218,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201035,
    shape_pt_lon: -89.757867,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.4408,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201046,
    shape_pt_lon: -89.757548,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.4698,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201031,
    shape_pt_lon: -89.757494,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.4752,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201079,
    shape_pt_lon: -89.757118,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.5098,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201176,
    shape_pt_lon: -89.756778,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.5417,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201222,
    shape_pt_lon: -89.756646,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.5547,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20126,
    shape_pt_lon: -89.756551,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.5646,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201432,
    shape_pt_lon: -89.756148,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.6057,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20155,
    shape_pt_lon: -89.755796,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.6403,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201596,
    shape_pt_lon: -89.755608,
    shape_pt_sequence: 28,
    shape_dist_traveled: 0.658,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201634,
    shape_pt_lon: -89.755276,
    shape_pt_sequence: 29,
    shape_dist_traveled: 0.6884,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201649,
    shape_pt_lon: -89.755042,
    shape_pt_sequence: 30,
    shape_dist_traveled: 0.7094,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201645,
    shape_pt_lon: -89.754775,
    shape_pt_sequence: 31,
    shape_dist_traveled: 0.7334,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20165,
    shape_pt_lon: -89.754562,
    shape_pt_sequence: 32,
    shape_dist_traveled: 0.7525,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202269,
    shape_pt_lon: -89.754549,
    shape_pt_sequence: 33,
    shape_dist_traveled: 0.8215,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20302,
    shape_pt_lon: -89.75453,
    shape_pt_sequence: 34,
    shape_dist_traveled: 0.9055,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203799,
    shape_pt_lon: -89.754494,
    shape_pt_sequence: 35,
    shape_dist_traveled: 0.9926,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204066,
    shape_pt_lon: -89.754467,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.0226,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204484,
    shape_pt_lon: -89.754477,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.0696,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204546,
    shape_pt_lon: -89.754478,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.0766,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204523,
    shape_pt_lon: -89.75495,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.1197,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20451,
    shape_pt_lon: -89.755506,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.1698,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204509,
    shape_pt_lon: -89.755558,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.1748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204509,
    shape_pt_lon: -89.755607,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.1788,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204504,
    shape_pt_lon: -89.755828,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.1988,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.756955,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.3008,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.758225,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.4158,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.758552,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.4448,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.75935,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.5168,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204502,
    shape_pt_lon: -89.760457,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.6168,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204503,
    shape_pt_lon: -89.76053,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.6238,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204503,
    shape_pt_lon: -89.760605,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.6298,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204496,
    shape_pt_lon: -89.762447,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.7968,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204499,
    shape_pt_lon: -89.763337,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.8768,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204482,
    shape_pt_lon: -89.764001,
    shape_pt_sequence: 53,
    shape_dist_traveled: 1.9368,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204479,
    shape_pt_lon: -89.764082,
    shape_pt_sequence: 54,
    shape_dist_traveled: 1.9439,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204475,
    shape_pt_lon: -89.764172,
    shape_pt_sequence: 55,
    shape_dist_traveled: 1.9519,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204411,
    shape_pt_lon: -89.765423,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.0652,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204409,
    shape_pt_lon: -89.765499,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.0722,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204462,
    shape_pt_lon: -89.765629,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.0856,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204439,
    shape_pt_lon: -89.766169,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.1347,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204417,
    shape_pt_lon: -89.767996,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.2997,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204417,
    shape_pt_lon: -89.76907,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.3967,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204412,
    shape_pt_lon: -89.769512,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.4357,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204427,
    shape_pt_lon: -89.7703,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.5077,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204424,
    shape_pt_lon: -89.771062,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.5758,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20441,
    shape_pt_lon: -89.771412,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.6078,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204409,
    shape_pt_lon: -89.771653,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.6298,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204402,
    shape_pt_lon: -89.772083,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.6688,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204399,
    shape_pt_lon: -89.772298,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.6878,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204396,
    shape_pt_lon: -89.772488,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.7048,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204392,
    shape_pt_lon: -89.772823,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.7348,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204398,
    shape_pt_lon: -89.773501,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.7968,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204402,
    shape_pt_lon: -89.773622,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.8078,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204409,
    shape_pt_lon: -89.773786,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.8219,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204411,
    shape_pt_lon: -89.774507,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.8869,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204412,
    shape_pt_lon: -89.775151,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.9459,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204426,
    shape_pt_lon: -89.775561,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.9829,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204428,
    shape_pt_lon: -89.775638,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.9899,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204404,
    shape_pt_lon: -89.776777,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.0919,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2044,
    shape_pt_lon: -89.777409,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.1489,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204411,
    shape_pt_lon: -89.777621,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.1689,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204415,
    shape_pt_lon: -89.777708,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1759,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20442,
    shape_pt_lon: -89.77793,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.196,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204419,
    shape_pt_lon: -89.778731,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.269,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204422,
    shape_pt_lon: -89.779721,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.358,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204419,
    shape_pt_lon: -89.781027,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.476,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20443,
    shape_pt_lon: -89.782169,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.579,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204441,
    shape_pt_lon: -89.78327,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.679,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204459,
    shape_pt_lon: -89.784748,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.812,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20446,
    shape_pt_lon: -89.78482,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.818,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20446,
    shape_pt_lon: -89.784889,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.825,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204461,
    shape_pt_lon: -89.785303,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.862,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204497,
    shape_pt_lon: -89.788467,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.148,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204497,
    shape_pt_lon: -89.78861,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.161,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204498,
    shape_pt_lon: -89.788736,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.172,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204523,
    shape_pt_lon: -89.790641,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.344,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204597,
    shape_pt_lon: -89.791948,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.4623,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2046,
    shape_pt_lon: -89.792011,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.4683,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20461,
    shape_pt_lon: -89.792156,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.4813,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204694,
    shape_pt_lon: -89.793858,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.6346,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204741,
    shape_pt_lon: -89.794837,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.7237,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.795845,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.8148,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204739,
    shape_pt_lon: -89.798023,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.0118,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204738,
    shape_pt_lon: -89.798219,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.0288,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204736,
    shape_pt_lon: -89.79863,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.0658,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204721,
    shape_pt_lon: -89.800253,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.2128,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204709,
    shape_pt_lon: -89.802361,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.4028,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204692,
    shape_pt_lon: -89.804273,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.5758,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204718,
    shape_pt_lon: -89.80853,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.9598,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204718,
    shape_pt_lon: -89.80895,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.9978,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20473,
    shape_pt_lon: -89.810579,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.1448,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204762,
    shape_pt_lon: -89.812149,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.2869,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204791,
    shape_pt_lon: -89.814597,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.5079,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204786,
    shape_pt_lon: -89.8148,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.5259,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204785,
    shape_pt_lon: -89.814968,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.5409,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204733,
    shape_pt_lon: -89.81565,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.6032,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204671,
    shape_pt_lon: -89.816157,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.6497,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20422,
    shape_pt_lon: -89.819108,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.9206,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204068,
    shape_pt_lon: -89.820104,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.0122,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203992,
    shape_pt_lon: -89.820742,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.0699,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203976,
    shape_pt_lon: -89.820939,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.088,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20396,
    shape_pt_lon: -89.821143,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.1071,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203952,
    shape_pt_lon: -89.821277,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.1191,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203939,
    shape_pt_lon: -89.821833,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.1691,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203946,
    shape_pt_lon: -89.822181,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.2001,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203972,
    shape_pt_lon: -89.822701,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.2472,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204022,
    shape_pt_lon: -89.823217,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.2946,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20419,
    shape_pt_lon: -89.824466,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.409,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204603,
    shape_pt_lon: -89.827447,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.6819,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204838,
    shape_pt_lon: -89.829129,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.8361,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205033,
    shape_pt_lon: -89.830538,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.9649,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205186,
    shape_pt_lon: -89.83164,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.0663,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205319,
    shape_pt_lon: -89.832752,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.1674,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205358,
    shape_pt_lon: -89.833126,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.2017,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205365,
    shape_pt_lon: -89.833199,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.2077,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205429,
    shape_pt_lon: -89.833892,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.2711,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205481,
    shape_pt_lon: -89.834406,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.3174,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205539,
    shape_pt_lon: -89.834969,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.3689,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205582,
    shape_pt_lon: -89.835598,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.426,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205589,
    shape_pt_lon: -89.836269,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.487,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20556,
    shape_pt_lon: -89.836908,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.5441,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205502,
    shape_pt_lon: -89.837518,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.5995,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205423,
    shape_pt_lon: -89.838037,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.6474,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.838671,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.7059,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205161,
    shape_pt_lon: -89.839474,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.7806,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204973,
    shape_pt_lon: -89.840496,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.8752,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204942,
    shape_pt_lon: -89.84073,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.8964,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204866,
    shape_pt_lon: -89.841421,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.9591,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204833,
    shape_pt_lon: -89.84174,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.9883,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204763,
    shape_pt_lon: -89.841871,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.0028,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204756,
    shape_pt_lon: -89.841991,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.0138,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204746,
    shape_pt_lon: -89.842389,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.0498,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204745,
    shape_pt_lon: -89.842888,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.0948,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204732,
    shape_pt_lon: -89.843799,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.1768,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204722,
    shape_pt_lon: -89.844508,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.2408,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204723,
    shape_pt_lon: -89.845065,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.2908,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204736,
    shape_pt_lon: -89.847663,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.5258,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204737,
    shape_pt_lon: -89.84802,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.5578,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204759,
    shape_pt_lon: -89.849987,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.7359,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20476,
    shape_pt_lon: -89.850822,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.8109,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204767,
    shape_pt_lon: -89.851276,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.8519,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204767,
    shape_pt_lon: -89.852329,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.9469,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.853387,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.0429,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.853701,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.0709,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204732,
    shape_pt_lon: -89.85426,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.121,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204731,
    shape_pt_lon: -89.854258,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.121,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204676,
    shape_pt_lon: -89.855239,
    shape_pt_sequence: 166,
    shape_dist_traveled: 10.2102,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204679,
    shape_pt_lon: -89.855238,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.2102,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204606,
    shape_pt_lon: -89.856721,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.3444,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204596,
    shape_pt_lon: -89.856923,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.3624,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204583,
    shape_pt_lon: -89.85737,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.4025,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204587,
    shape_pt_lon: -89.85764,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.4275,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204606,
    shape_pt_lon: -89.858129,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.4716,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204611,
    shape_pt_lon: -89.858191,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.4766,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20466,
    shape_pt_lon: -89.858883,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.5398,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204672,
    shape_pt_lon: -89.859051,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.5549,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204703,
    shape_pt_lon: -89.859466,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.5921,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204708,
    shape_pt_lon: -89.859538,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.5991,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204719,
    shape_pt_lon: -89.859727,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.6162,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204747,
    shape_pt_lon: -89.860231,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.6623,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204751,
    shape_pt_lon: -89.860572,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.6923,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204753,
    shape_pt_lon: -89.861062,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.7373,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.861506,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.7773,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204754,
    shape_pt_lon: -89.862068,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.8273,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.862667,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.8813,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.863066,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.9173,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204758,
    shape_pt_lon: -89.863518,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.9583,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204747,
    shape_pt_lon: -89.864039,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.0053,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204737,
    shape_pt_lon: -89.864169,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.0173,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204723,
    shape_pt_lon: -89.86433,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.0325,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204709,
    shape_pt_lon: -89.864499,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.0475,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204634,
    shape_pt_lon: -89.865116,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.1042,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204628,
    shape_pt_lon: -89.865175,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.1092,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204579,
    shape_pt_lon: -89.865487,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.1379,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20453,
    shape_pt_lon: -89.865808,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.1675,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204503,
    shape_pt_lon: -89.865992,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.1847,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204246,
    shape_pt_lon: -89.867671,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.3385,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204198,
    shape_pt_lon: -89.868068,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.3748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204158,
    shape_pt_lon: -89.868544,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.4181,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204149,
    shape_pt_lon: -89.868657,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.4282,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204139,
    shape_pt_lon: -89.868962,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.4562,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204139,
    shape_pt_lon: -89.869651,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.5182,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204191,
    shape_pt_lon: -89.870786,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.6204,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2042,
    shape_pt_lon: -89.871225,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.6604,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204211,
    shape_pt_lon: -89.871753,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.7084,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204237,
    shape_pt_lon: -89.872665,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.7904,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204271,
    shape_pt_lon: -89.873011,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.8217,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204356,
    shape_pt_lon: -89.873421,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.86,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204483,
    shape_pt_lon: -89.87389,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.9052,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204588,
    shape_pt_lon: -89.874302,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.9438,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204706,
    shape_pt_lon: -89.874771,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.9878,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204746,
    shape_pt_lon: -89.874968,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.0065,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20479,
    shape_pt_lon: -89.875326,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.0389,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20483,
    shape_pt_lon: -89.876253,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.123,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20486,
    shape_pt_lon: -89.877088,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.198,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204869,
    shape_pt_lon: -89.87734,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.221,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204901,
    shape_pt_lon: -89.878128,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.2922,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204964,
    shape_pt_lon: -89.87824,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.3044,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204972,
    shape_pt_lon: -89.87842,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.3214,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205059,
    shape_pt_lon: -89.879803,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.4467,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205081,
    shape_pt_lon: -89.880145,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.4768,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205127,
    shape_pt_lon: -89.880679,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.5262,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205209,
    shape_pt_lon: -89.881517,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.6017,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205421,
    shape_pt_lon: -89.883462,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.7792,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205441,
    shape_pt_lon: -89.883661,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.7973,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205529,
    shape_pt_lon: -89.884261,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.8522,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205699,
    shape_pt_lon: -89.885229,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.9422,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205726,
    shape_pt_lon: -89.885367,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.9546,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205928,
    shape_pt_lon: -89.886229,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.0357,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206331,
    shape_pt_lon: -89.887889,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.1923,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206387,
    shape_pt_lon: -89.888099,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.2122,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20643,
    shape_pt_lon: -89.888317,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.2318,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206523,
    shape_pt_lon: -89.888852,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.2818,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206599,
    shape_pt_lon: -89.889438,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.3356,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206629,
    shape_pt_lon: -89.88971,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.3598,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206781,
    shape_pt_lon: -89.891589,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.5306,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206868,
    shape_pt_lon: -89.892645,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.6261,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206912,
    shape_pt_lon: -89.893291,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.6853,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20701,
    shape_pt_lon: -89.894521,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.7968,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207016,
    shape_pt_lon: -89.894604,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.8038,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207017,
    shape_pt_lon: -89.894673,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.8099,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207062,
    shape_pt_lon: -89.896633,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.9869,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207112,
    shape_pt_lon: -89.8989,
    shape_pt_sequence: 242,
    shape_dist_traveled: 14.192,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20716,
    shape_pt_lon: -89.90083,
    shape_pt_sequence: 243,
    shape_dist_traveled: 14.3661,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207205,
    shape_pt_lon: -89.902942,
    shape_pt_sequence: 244,
    shape_dist_traveled: 14.5571,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207218,
    shape_pt_lon: -89.903636,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.6202,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207201,
    shape_pt_lon: -89.90426,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.6762,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207168,
    shape_pt_lon: -89.904738,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.7194,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207143,
    shape_pt_lon: -89.904959,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.7396,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207126,
    shape_pt_lon: -89.905119,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.7538,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206878,
    shape_pt_lon: -89.906941,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.9211,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206706,
    shape_pt_lon: -89.90823,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.0387,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206408,
    shape_pt_lon: -89.910329,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.2317,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206342,
    shape_pt_lon: -89.910786,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.2735,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206115,
    shape_pt_lon: -89.912428,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.4236,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205913,
    shape_pt_lon: -89.913951,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.5625,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205849,
    shape_pt_lon: -89.914443,
    shape_pt_sequence: 256,
    shape_dist_traveled: 15.608,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205746,
    shape_pt_lon: -89.915251,
    shape_pt_sequence: 257,
    shape_dist_traveled: 15.682,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205669,
    shape_pt_lon: -89.915888,
    shape_pt_sequence: 258,
    shape_dist_traveled: 15.7397,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205623,
    shape_pt_lon: -89.916301,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.778,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205556,
    shape_pt_lon: -89.91685,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.8275,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205361,
    shape_pt_lon: -89.918471,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.9752,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20534,
    shape_pt_lon: -89.918641,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.9915,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205288,
    shape_pt_lon: -89.919355,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.0557,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205287,
    shape_pt_lon: -89.920008,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.1147,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205303,
    shape_pt_lon: -89.921139,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.2168,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921465,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.2468,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921546,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.2538,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921608,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.2598,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205315,
    shape_pt_lon: -89.921707,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.2688,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205251,
    shape_pt_lon: -89.921838,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.2818,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205276,
    shape_pt_lon: -89.922495,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.3419,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205284,
    shape_pt_lon: -89.922931,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.3809,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205297,
    shape_pt_lon: -89.923384,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.422,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205299,
    shape_pt_lon: -89.923478,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.43,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923586,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.44,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923742,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.454,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2053,
    shape_pt_lon: -89.923879,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.467,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205306,
    shape_pt_lon: -89.924193,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.495,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.924559,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.528,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205307,
    shape_pt_lon: -89.924639,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.535,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205298,
    shape_pt_lon: -89.92487,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.556,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205262,
    shape_pt_lon: -89.925267,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.5922,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205187,
    shape_pt_lon: -89.92573,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.6352,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205088,
    shape_pt_lon: -89.926151,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.6748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204808,
    shape_pt_lon: -89.926997,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.7568,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204693,
    shape_pt_lon: -89.927393,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.7951,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204608,
    shape_pt_lon: -89.927821,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.8344,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204579,
    shape_pt_lon: -89.928023,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.8536,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204554,
    shape_pt_lon: -89.928264,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.8748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204537,
    shape_pt_lon: -89.928709,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.9159,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204552,
    shape_pt_lon: -89.92913,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.9539,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204591,
    shape_pt_lon: -89.929493,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.9862,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204599,
    shape_pt_lon: -89.929564,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.9933,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204607,
    shape_pt_lon: -89.92963,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.9994,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204712,
    shape_pt_lon: -89.930608,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.088,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20473,
    shape_pt_lon: -89.931042,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.1271,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204726,
    shape_pt_lon: -89.931291,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.1501,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204694,
    shape_pt_lon: -89.931725,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.1893,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204627,
    shape_pt_lon: -89.932151,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.2279,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204533,
    shape_pt_lon: -89.932563,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.2665,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204396,
    shape_pt_lon: -89.932998,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.3093,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204208,
    shape_pt_lon: -89.93346,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.3553,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203909,
    shape_pt_lon: -89.934189,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.4296,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20376,
    shape_pt_lon: -89.934533,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.4649,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203607,
    shape_pt_lon: -89.934831,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.4968,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203433,
    shape_pt_lon: -89.935135,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.5307,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203344,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.5471,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203178,
    shape_pt_lon: -89.93553,
    shape_pt_sequence: 308,
    shape_dist_traveled: 17.5761,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202967,
    shape_pt_lon: -89.935811,
    shape_pt_sequence: 309,
    shape_dist_traveled: 17.6108,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20235,
    shape_pt_lon: -89.936569,
    shape_pt_sequence: 310,
    shape_dist_traveled: 17.7084,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20205,
    shape_pt_lon: -89.93694,
    shape_pt_sequence: 311,
    shape_dist_traveled: 17.755,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201551,
    shape_pt_lon: -89.937561,
    shape_pt_sequence: 312,
    shape_dist_traveled: 17.8342,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201552,
    shape_pt_lon: -89.937562,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.8342,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201335,
    shape_pt_lon: -89.937831,
    shape_pt_sequence: 314,
    shape_dist_traveled: 17.8696,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20133,
    shape_pt_lon: -89.937835,
    shape_pt_sequence: 315,
    shape_dist_traveled: 17.8696,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200766,
    shape_pt_lon: -89.938524,
    shape_pt_sequence: 316,
    shape_dist_traveled: 17.958,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200183,
    shape_pt_lon: -89.93926,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.0513,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199981,
    shape_pt_lon: -89.939536,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.0846,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199811,
    shape_pt_lon: -89.939825,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.1168,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199663,
    shape_pt_lon: -89.940107,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.1479,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199601,
    shape_pt_lon: -89.940243,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.1618,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199457,
    shape_pt_lon: -89.940618,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.1994,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199347,
    shape_pt_lon: -89.940982,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.2345,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199266,
    shape_pt_lon: -89.941314,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.2661,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199188,
    shape_pt_lon: -89.941817,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.3118,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199161,
    shape_pt_lon: -89.942194,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.3459,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19913,
    shape_pt_lon: -89.944614,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.565,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199096,
    shape_pt_lon: -89.945861,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.677,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199089,
    shape_pt_lon: -89.9465,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.735,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199089,
    shape_pt_lon: -89.94665,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199127,
    shape_pt_lon: -89.947776,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.8501,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199172,
    shape_pt_lon: -89.949016,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.9622,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19937,
    shape_pt_lon: -89.951692,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.2052,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199591,
    shape_pt_lon: -89.954697,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.4773,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199602,
    shape_pt_lon: -89.954854,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.4914,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199655,
    shape_pt_lon: -89.955507,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.5506,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199711,
    shape_pt_lon: -89.956287,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.622,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199746,
    shape_pt_lon: -89.95677,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.6651,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199893,
    shape_pt_lon: -89.95874,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.8439,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199905,
    shape_pt_lon: -89.958843,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.853,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199909,
    shape_pt_lon: -89.958923,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.861,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19995,
    shape_pt_lon: -89.959553,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.9182,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199999,
    shape_pt_lon: -89.960178,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.9744,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200105,
    shape_pt_lon: -89.961591,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.103,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200134,
    shape_pt_lon: -89.961968,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.1371,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20018,
    shape_pt_lon: -89.962019,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.1435,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200191,
    shape_pt_lon: -89.962149,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.1557,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200252,
    shape_pt_lon: -89.962881,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.2219,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200283,
    shape_pt_lon: -89.963618,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.2891,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200277,
    shape_pt_lon: -89.964385,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.3581,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20025,
    shape_pt_lon: -89.965018,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.4151,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200235,
    shape_pt_lon: -89.96518,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.4303,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200222,
    shape_pt_lon: -89.96532,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.4423,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200131,
    shape_pt_lon: -89.966588,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.5577,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200073,
    shape_pt_lon: -89.967075,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.6023,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199979,
    shape_pt_lon: -89.968153,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.6999,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199962,
    shape_pt_lon: -89.968436,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.726,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199929,
    shape_pt_lon: -89.968547,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.7364,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19989,
    shape_pt_lon: -89.968601,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.7428,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199852,
    shape_pt_lon: -89.968971,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.7771,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199839,
    shape_pt_lon: -89.969113,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.7901,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199643,
    shape_pt_lon: -89.971157,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.9755,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19956,
    shape_pt_lon: -89.971866,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.0402,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199463,
    shape_pt_lon: -89.972724,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.119,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199273,
    shape_pt_lon: -89.974938,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.3191,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199177,
    shape_pt_lon: -89.975817,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.3998,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199087,
    shape_pt_lon: -89.976659,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.4765,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199109,
    shape_pt_lon: -89.977979,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.5955,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199132,
    shape_pt_lon: -89.978818,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.6715,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199148,
    shape_pt_lon: -89.979453,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.7285,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199157,
    shape_pt_lon: -89.97989,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.7686,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199182,
    shape_pt_lon: -89.980914,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.8606,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.1992,
    shape_pt_lon: -89.98143,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.9077,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199207,
    shape_pt_lon: -89.982061,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.9647,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199216,
    shape_pt_lon: -89.982458,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.9997,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199194,
    shape_pt_lon: -89.982808,
    shape_pt_sequence: 376,
    shape_dist_traveled: 22.0318,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199151,
    shape_pt_lon: -89.983123,
    shape_pt_sequence: 377,
    shape_dist_traveled: 22.0603,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19907,
    shape_pt_lon: -89.98347,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.0935,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198962,
    shape_pt_lon: -89.983803,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.1258,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198819,
    shape_pt_lon: -89.984139,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.1598,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198489,
    shape_pt_lon: -89.984702,
    shape_pt_sequence: 381,
    shape_dist_traveled: 22.2228,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19828,
    shape_pt_lon: -89.985052,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.2614,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197682,
    shape_pt_lon: -89.986076,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.376,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197551,
    shape_pt_lon: -89.986352,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.4052,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.1974,
    shape_pt_lon: -89.986785,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.4477,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197322,
    shape_pt_lon: -89.987118,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.4791,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197292,
    shape_pt_lon: -89.987288,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.4944,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197255,
    shape_pt_lon: -89.987682,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.5306,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197262,
    shape_pt_lon: -89.988212,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.5786,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197279,
    shape_pt_lon: -89.988708,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.6226,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19734,
    shape_pt_lon: -89.990797,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.8117,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19737,
    shape_pt_lon: -89.991756,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.8988,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19737,
    shape_pt_lon: -89.991821,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.9048,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197401,
    shape_pt_lon: -89.992738,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.9869,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197462,
    shape_pt_lon: -89.994693,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.164,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19749,
    shape_pt_lon: -89.995601,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.2461,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197542,
    shape_pt_lon: -89.997134,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.3842,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19755,
    shape_pt_lon: -89.997559,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.4232,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197597,
    shape_pt_lon: -89.999062,
    shape_pt_sequence: 399,
    shape_dist_traveled: 23.5583,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197638,
    shape_pt_lon: -90.00051,
    shape_pt_sequence: 400,
    shape_dist_traveled: 23.6894,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19772,
    shape_pt_lon: -90.003167,
    shape_pt_sequence: 401,
    shape_dist_traveled: 23.9295,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197749,
    shape_pt_lon: -90.003501,
    shape_pt_sequence: 402,
    shape_dist_traveled: 23.9597,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197801,
    shape_pt_lon: -90.003758,
    shape_pt_sequence: 403,
    shape_dist_traveled: 23.9835,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19786,
    shape_pt_lon: -90.003951,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.0024,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197929,
    shape_pt_lon: -90.004129,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.0203,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19808,
    shape_pt_lon: -90.004406,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.0506,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19811,
    shape_pt_lon: -90.00446,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.0564,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198413,
    shape_pt_lon: -90.004948,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.112,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198876,
    shape_pt_lon: -90.005642,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.1937,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199008,
    shape_pt_lon: -90.005858,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.2179,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199102,
    shape_pt_lon: -90.006043,
    shape_pt_sequence: 411,
    shape_dist_traveled: 24.2376,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199219,
    shape_pt_lon: -90.00635,
    shape_pt_sequence: 412,
    shape_dist_traveled: 24.2676,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199278,
    shape_pt_lon: -90.006568,
    shape_pt_sequence: 413,
    shape_dist_traveled: 24.2888,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199301,
    shape_pt_lon: -90.006677,
    shape_pt_sequence: 414,
    shape_dist_traveled: 24.299,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199345,
    shape_pt_lon: -90.006988,
    shape_pt_sequence: 415,
    shape_dist_traveled: 24.3274,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199369,
    shape_pt_lon: -90.007784,
    shape_pt_sequence: 416,
    shape_dist_traveled: 24.3995,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199372,
    shape_pt_lon: -90.007917,
    shape_pt_sequence: 417,
    shape_dist_traveled: 24.4115,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199458,
    shape_pt_lon: -90.010878,
    shape_pt_sequence: 418,
    shape_dist_traveled: 24.6787,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199589,
    shape_pt_lon: -90.014043,
    shape_pt_sequence: 419,
    shape_dist_traveled: 24.965,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199629,
    shape_pt_lon: -90.015069,
    shape_pt_sequence: 420,
    shape_dist_traveled: 25.0581,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199626,
    shape_pt_lon: -90.015212,
    shape_pt_sequence: 421,
    shape_dist_traveled: 25.0712,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199599,
    shape_pt_lon: -90.015598,
    shape_pt_sequence: 422,
    shape_dist_traveled: 25.1053,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199497,
    shape_pt_lon: -90.016272,
    shape_pt_sequence: 423,
    shape_dist_traveled: 25.1673,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199466,
    shape_pt_lon: -90.016651,
    shape_pt_sequence: 424,
    shape_dist_traveled: 25.2025,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199489,
    shape_pt_lon: -90.01815,
    shape_pt_sequence: 425,
    shape_dist_traveled: 25.3375,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199535,
    shape_pt_lon: -90.020161,
    shape_pt_sequence: 426,
    shape_dist_traveled: 25.5186,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199542,
    shape_pt_lon: -90.020288,
    shape_pt_sequence: 427,
    shape_dist_traveled: 25.5307,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.1996,
    shape_pt_lon: -90.020671,
    shape_pt_sequence: 428,
    shape_dist_traveled: 25.5652,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199655,
    shape_pt_lon: -90.020923,
    shape_pt_sequence: 429,
    shape_dist_traveled: 25.589,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199743,
    shape_pt_lon: -90.021219,
    shape_pt_sequence: 430,
    shape_dist_traveled: 25.6177,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199861,
    shape_pt_lon: -90.021516,
    shape_pt_sequence: 431,
    shape_dist_traveled: 25.6477,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200036,
    shape_pt_lon: -90.021857,
    shape_pt_sequence: 432,
    shape_dist_traveled: 25.6846,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200212,
    shape_pt_lon: -90.022127,
    shape_pt_sequence: 433,
    shape_dist_traveled: 25.7158,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200389,
    shape_pt_lon: -90.02235,
    shape_pt_sequence: 434,
    shape_dist_traveled: 25.7434,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.200657,
    shape_pt_lon: -90.022631,
    shape_pt_sequence: 435,
    shape_dist_traveled: 25.7825,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201239,
    shape_pt_lon: -90.02323,
    shape_pt_sequence: 436,
    shape_dist_traveled: 25.8676,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201308,
    shape_pt_lon: -90.023317,
    shape_pt_sequence: 437,
    shape_dist_traveled: 25.8783,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201388,
    shape_pt_lon: -90.023418,
    shape_pt_sequence: 438,
    shape_dist_traveled: 25.8917,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201558,
    shape_pt_lon: -90.023666,
    shape_pt_sequence: 439,
    shape_dist_traveled: 25.9208,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201657,
    shape_pt_lon: -90.023847,
    shape_pt_sequence: 440,
    shape_dist_traveled: 25.9402,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201736,
    shape_pt_lon: -90.024005,
    shape_pt_sequence: 441,
    shape_dist_traveled: 25.9577,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201819,
    shape_pt_lon: -90.024208,
    shape_pt_sequence: 442,
    shape_dist_traveled: 25.9778,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201929,
    shape_pt_lon: -90.024546,
    shape_pt_sequence: 443,
    shape_dist_traveled: 26.0105,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201975,
    shape_pt_lon: -90.024691,
    shape_pt_sequence: 444,
    shape_dist_traveled: 26.0254,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202393,
    shape_pt_lon: -90.026261,
    shape_pt_sequence: 445,
    shape_dist_traveled: 26.1737,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20248,
    shape_pt_lon: -90.026576,
    shape_pt_sequence: 446,
    shape_dist_traveled: 26.2044,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202788,
    shape_pt_lon: -90.027593,
    shape_pt_sequence: 447,
    shape_dist_traveled: 26.3028,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203029,
    shape_pt_lon: -90.028402,
    shape_pt_sequence: 448,
    shape_dist_traveled: 26.3806,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20314,
    shape_pt_lon: -90.028802,
    shape_pt_sequence: 449,
    shape_dist_traveled: 26.4186,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203278,
    shape_pt_lon: -90.029292,
    shape_pt_sequence: 450,
    shape_dist_traveled: 26.4651,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20331,
    shape_pt_lon: -90.02944,
    shape_pt_sequence: 451,
    shape_dist_traveled: 26.4787,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203376,
    shape_pt_lon: -90.029734,
    shape_pt_sequence: 452,
    shape_dist_traveled: 26.5066,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203403,
    shape_pt_lon: -90.029908,
    shape_pt_sequence: 453,
    shape_dist_traveled: 26.5228,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203442,
    shape_pt_lon: -90.030152,
    shape_pt_sequence: 454,
    shape_dist_traveled: 26.5454,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203486,
    shape_pt_lon: -90.030527,
    shape_pt_sequence: 455,
    shape_dist_traveled: 26.5788,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203513,
    shape_pt_lon: -90.030982,
    shape_pt_sequence: 456,
    shape_dist_traveled: 26.6209,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203509,
    shape_pt_lon: -90.031567,
    shape_pt_sequence: 457,
    shape_dist_traveled: 26.6729,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20349,
    shape_pt_lon: -90.032073,
    shape_pt_sequence: 458,
    shape_dist_traveled: 26.7189,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20348,
    shape_pt_lon: -90.032477,
    shape_pt_sequence: 459,
    shape_dist_traveled: 26.756,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203468,
    shape_pt_lon: -90.033153,
    shape_pt_sequence: 460,
    shape_dist_traveled: 26.817,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203448,
    shape_pt_lon: -90.033824,
    shape_pt_sequence: 461,
    shape_dist_traveled: 26.877,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204339,
    shape_pt_lon: -90.033677,
    shape_pt_sequence: 462,
    shape_dist_traveled: 26.9779,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205609,
    shape_pt_lon: -90.033429,
    shape_pt_sequence: 463,
    shape_dist_traveled: 27.1216,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205751,
    shape_pt_lon: -90.033406,
    shape_pt_sequence: 464,
    shape_dist_traveled: 27.1378,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205952,
    shape_pt_lon: -90.033363,
    shape_pt_sequence: 465,
    shape_dist_traveled: 27.1601,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206247,
    shape_pt_lon: -90.033303,
    shape_pt_sequence: 466,
    shape_dist_traveled: 27.1936,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206328,
    shape_pt_lon: -90.033288,
    shape_pt_sequence: 467,
    shape_dist_traveled: 27.2036,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207233,
    shape_pt_lon: -90.033084,
    shape_pt_sequence: 468,
    shape_dist_traveled: 27.3064,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2086,
    shape_pt_lon: -90.032816,
    shape_pt_sequence: 469,
    shape_dist_traveled: 27.4613,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20949,
    shape_pt_lon: -90.032639,
    shape_pt_sequence: 470,
    shape_dist_traveled: 27.5616,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.209467,
    shape_pt_lon: -90.032713,
    shape_pt_sequence: 471,
    shape_dist_traveled: 27.5689,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20945,
    shape_pt_lon: -90.032765,
    shape_pt_sequence: 472,
    shape_dist_traveled: 27.5742,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.209401,
    shape_pt_lon: -90.032919,
    shape_pt_sequence: 473,
    shape_dist_traveled: 27.5895,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.209029,
    shape_pt_lon: -90.033927,
    shape_pt_sequence: 474,
    shape_dist_traveled: 27.6893,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.208984,
    shape_pt_lon: -90.034048,
    shape_pt_sequence: 475,
    shape_dist_traveled: 27.7014,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.208649,
    shape_pt_lon: -90.035001,
    shape_pt_sequence: 476,
    shape_dist_traveled: 27.7954,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20808,
    shape_pt_lon: -90.03659,
    shape_pt_sequence: 477,
    shape_dist_traveled: 27.9516,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.208038,
    shape_pt_lon: -90.036709,
    shape_pt_sequence: 478,
    shape_dist_traveled: 27.9637,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207761,
    shape_pt_lon: -90.037481,
    shape_pt_sequence: 479,
    shape_dist_traveled: 28.0394,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.207276,
    shape_pt_lon: -90.038844,
    shape_pt_sequence: 480,
    shape_dist_traveled: 28.1741,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206872,
    shape_pt_lon: -90.039979,
    shape_pt_sequence: 481,
    shape_dist_traveled: 28.2865,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206478,
    shape_pt_lon: -90.041092,
    shape_pt_sequence: 482,
    shape_dist_traveled: 28.3958,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.206258,
    shape_pt_lon: -90.04169,
    shape_pt_sequence: 483,
    shape_dist_traveled: 28.4553,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2061,
    shape_pt_lon: -90.04208,
    shape_pt_sequence: 484,
    shape_dist_traveled: 28.4951,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205919,
    shape_pt_lon: -90.042508,
    shape_pt_sequence: 485,
    shape_dist_traveled: 28.538,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205699,
    shape_pt_lon: -90.042954,
    shape_pt_sequence: 486,
    shape_dist_traveled: 28.5852,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205682,
    shape_pt_lon: -90.042989,
    shape_pt_sequence: 487,
    shape_dist_traveled: 28.5897,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.205341,
    shape_pt_lon: -90.043719,
    shape_pt_sequence: 488,
    shape_dist_traveled: 28.6658,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204759,
    shape_pt_lon: -90.044911,
    shape_pt_sequence: 489,
    shape_dist_traveled: 28.791,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204629,
    shape_pt_lon: -90.045148,
    shape_pt_sequence: 490,
    shape_dist_traveled: 28.8168,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.204489,
    shape_pt_lon: -90.045349,
    shape_pt_sequence: 491,
    shape_dist_traveled: 28.8417,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.2042,
    shape_pt_lon: -90.045703,
    shape_pt_sequence: 492,
    shape_dist_traveled: 28.8869,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203859,
    shape_pt_lon: -90.046114,
    shape_pt_sequence: 493,
    shape_dist_traveled: 28.94,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203748,
    shape_pt_lon: -90.046291,
    shape_pt_sequence: 494,
    shape_dist_traveled: 28.9606,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20365,
    shape_pt_lon: -90.046532,
    shape_pt_sequence: 495,
    shape_dist_traveled: 28.9838,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203497,
    shape_pt_lon: -90.047097,
    shape_pt_sequence: 496,
    shape_dist_traveled: 29.0379,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203252,
    shape_pt_lon: -90.048005,
    shape_pt_sequence: 497,
    shape_dist_traveled: 29.1242,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203186,
    shape_pt_lon: -90.048179,
    shape_pt_sequence: 498,
    shape_dist_traveled: 29.1417,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.203052,
    shape_pt_lon: -90.04843,
    shape_pt_sequence: 499,
    shape_dist_traveled: 29.1692,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20232,
    shape_pt_lon: -90.049569,
    shape_pt_sequence: 500,
    shape_dist_traveled: 29.3008,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.202096,
    shape_pt_lon: -90.04991,
    shape_pt_sequence: 501,
    shape_dist_traveled: 29.3399,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20192,
    shape_pt_lon: -90.050113,
    shape_pt_sequence: 502,
    shape_dist_traveled: 29.3675,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.20178,
    shape_pt_lon: -90.050218,
    shape_pt_sequence: 503,
    shape_dist_traveled: 29.3858,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201632,
    shape_pt_lon: -90.050291,
    shape_pt_sequence: 504,
    shape_dist_traveled: 29.4033,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.201359,
    shape_pt_lon: -90.050391,
    shape_pt_sequence: 505,
    shape_dist_traveled: 29.4356,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199905,
    shape_pt_lon: -90.050877,
    shape_pt_sequence: 506,
    shape_dist_traveled: 29.6044,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199498,
    shape_pt_lon: -90.051032,
    shape_pt_sequence: 507,
    shape_dist_traveled: 29.6515,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.199097,
    shape_pt_lon: -90.051222,
    shape_pt_sequence: 508,
    shape_dist_traveled: 29.6996,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198459,
    shape_pt_lon: -90.051552,
    shape_pt_sequence: 509,
    shape_dist_traveled: 29.7767,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.198052,
    shape_pt_lon: -90.051748,
    shape_pt_sequence: 510,
    shape_dist_traveled: 29.8257,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197876,
    shape_pt_lon: -90.051803,
    shape_pt_sequence: 511,
    shape_dist_traveled: 29.8464,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197778,
    shape_pt_lon: -90.051822,
    shape_pt_sequence: 512,
    shape_dist_traveled: 29.8575,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197573,
    shape_pt_lon: -90.051834,
    shape_pt_sequence: 513,
    shape_dist_traveled: 29.8806,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197373,
    shape_pt_lon: -90.0518,
    shape_pt_sequence: 514,
    shape_dist_traveled: 29.9028,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.197169,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 515,
    shape_dist_traveled: 29.9268,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.196616,
    shape_pt_lon: -90.051378,
    shape_pt_sequence: 516,
    shape_dist_traveled: 29.9961,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.195256,
    shape_pt_lon: -90.050556,
    shape_pt_sequence: 517,
    shape_dist_traveled: 30.1652,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.195193,
    shape_pt_lon: -90.050518,
    shape_pt_sequence: 518,
    shape_dist_traveled: 30.1732,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.193534,
    shape_pt_lon: -90.049533,
    shape_pt_sequence: 519,
    shape_dist_traveled: 30.3794,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.193333,
    shape_pt_lon: -90.049443,
    shape_pt_sequence: 520,
    shape_dist_traveled: 30.4029,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.192734,
    shape_pt_lon: -90.049171,
    shape_pt_sequence: 521,
    shape_dist_traveled: 30.4744,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.192642,
    shape_pt_lon: -90.049127,
    shape_pt_sequence: 522,
    shape_dist_traveled: 30.4851,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191961,
    shape_pt_lon: -90.048813,
    shape_pt_sequence: 523,
    shape_dist_traveled: 30.5671,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191513,
    shape_pt_lon: -90.048591,
    shape_pt_sequence: 524,
    shape_dist_traveled: 30.6209,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19149,
    shape_pt_lon: -90.048591,
    shape_pt_sequence: 525,
    shape_dist_traveled: 30.6229,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191475,
    shape_pt_lon: -90.048592,
    shape_pt_sequence: 526,
    shape_dist_traveled: 30.6249,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191397,
    shape_pt_lon: -90.048536,
    shape_pt_sequence: 527,
    shape_dist_traveled: 30.6352,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191328,
    shape_pt_lon: -90.048486,
    shape_pt_sequence: 528,
    shape_dist_traveled: 30.6433,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191251,
    shape_pt_lon: -90.048424,
    shape_pt_sequence: 529,
    shape_dist_traveled: 30.6541,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191136,
    shape_pt_lon: -90.048319,
    shape_pt_sequence: 530,
    shape_dist_traveled: 30.6705,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191094,
    shape_pt_lon: -90.048279,
    shape_pt_sequence: 531,
    shape_dist_traveled: 30.6763,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.191029,
    shape_pt_lon: -90.048209,
    shape_pt_sequence: 532,
    shape_dist_traveled: 30.6862,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190948,
    shape_pt_lon: -90.048106,
    shape_pt_sequence: 533,
    shape_dist_traveled: 30.699,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190899,
    shape_pt_lon: -90.048039,
    shape_pt_sequence: 534,
    shape_dist_traveled: 30.7068,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190848,
    shape_pt_lon: -90.047962,
    shape_pt_sequence: 535,
    shape_dist_traveled: 30.716,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190812,
    shape_pt_lon: -90.047909,
    shape_pt_sequence: 536,
    shape_dist_traveled: 30.7224,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190772,
    shape_pt_lon: -90.047829,
    shape_pt_sequence: 537,
    shape_dist_traveled: 30.731,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190565,
    shape_pt_lon: -90.047412,
    shape_pt_sequence: 538,
    shape_dist_traveled: 30.7746,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.19056,
    shape_pt_lon: -90.047367,
    shape_pt_sequence: 539,
    shape_dist_traveled: 30.7796,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190451,
    shape_pt_lon: -90.047178,
    shape_pt_sequence: 540,
    shape_dist_traveled: 30.801,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190274,
    shape_pt_lon: -90.046925,
    shape_pt_sequence: 541,
    shape_dist_traveled: 30.83,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.190069,
    shape_pt_lon: -90.046699,
    shape_pt_sequence: 542,
    shape_dist_traveled: 30.8612,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.189897,
    shape_pt_lon: -90.046558,
    shape_pt_sequence: 543,
    shape_dist_traveled: 30.885,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.189754,
    shape_pt_lon: -90.046461,
    shape_pt_sequence: 544,
    shape_dist_traveled: 30.9029,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.18952,
    shape_pt_lon: -90.046339,
    shape_pt_sequence: 545,
    shape_dist_traveled: 30.9311,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.189337,
    shape_pt_lon: -90.046276,
    shape_pt_sequence: 546,
    shape_dist_traveled: 30.952,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.188615,
    shape_pt_lon: -90.046063,
    shape_pt_sequence: 547,
    shape_dist_traveled: 31.0352,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.188275,
    shape_pt_lon: -90.045963,
    shape_pt_sequence: 548,
    shape_dist_traveled: 31.0743,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.188061,
    shape_pt_lon: -90.045901,
    shape_pt_sequence: 549,
    shape_dist_traveled: 31.099,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.186919,
    shape_pt_lon: -90.045563,
    shape_pt_sequence: 550,
    shape_dist_traveled: 31.2307,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.186298,
    shape_pt_lon: -90.045392,
    shape_pt_sequence: 551,
    shape_dist_traveled: 31.3013,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.18604,
    shape_pt_lon: -90.045362,
    shape_pt_sequence: 552,
    shape_dist_traveled: 31.3305,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.185818,
    shape_pt_lon: -90.045373,
    shape_pt_sequence: 553,
    shape_dist_traveled: 31.3555,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.185313,
    shape_pt_lon: -90.045474,
    shape_pt_sequence: 554,
    shape_dist_traveled: 31.4122,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.18525,
    shape_pt_lon: -90.045488,
    shape_pt_sequence: 555,
    shape_dist_traveled: 31.4205,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.185061,
    shape_pt_lon: -90.045521,
    shape_pt_sequence: 556,
    shape_dist_traveled: 31.4417,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.18495,
    shape_pt_lon: -90.045532,
    shape_pt_sequence: 557,
    shape_dist_traveled: 31.4537,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.184789,
    shape_pt_lon: -90.045527,
    shape_pt_sequence: 558,
    shape_dist_traveled: 31.4717,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.184729,
    shape_pt_lon: -90.045514,
    shape_pt_sequence: 559,
    shape_dist_traveled: 31.4788,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.184641,
    shape_pt_lon: -90.045489,
    shape_pt_sequence: 560,
    shape_dist_traveled: 31.489,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.18365,
    shape_pt_lon: -90.04517,
    shape_pt_sequence: 561,
    shape_dist_traveled: 31.6037,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.183198,
    shape_pt_lon: -90.045033,
    shape_pt_sequence: 562,
    shape_dist_traveled: 31.6554,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.182855,
    shape_pt_lon: -90.044957,
    shape_pt_sequence: 563,
    shape_dist_traveled: 31.6949,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.182267,
    shape_pt_lon: -90.044879,
    shape_pt_sequence: 564,
    shape_dist_traveled: 31.7602,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.180981,
    shape_pt_lon: -90.044781,
    shape_pt_sequence: 565,
    shape_dist_traveled: 31.9045,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.179859,
    shape_pt_lon: -90.044688,
    shape_pt_sequence: 566,
    shape_dist_traveled: 32.0308,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.17934,
    shape_pt_lon: -90.044641,
    shape_pt_sequence: 567,
    shape_dist_traveled: 32.089,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.177516,
    shape_pt_lon: -90.044428,
    shape_pt_sequence: 568,
    shape_dist_traveled: 32.2939,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.177255,
    shape_pt_lon: -90.044397,
    shape_pt_sequence: 569,
    shape_dist_traveled: 32.323,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.176511,
    shape_pt_lon: -90.044284,
    shape_pt_sequence: 570,
    shape_dist_traveled: 32.4066,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.175737,
    shape_pt_lon: -90.044159,
    shape_pt_sequence: 571,
    shape_dist_traveled: 32.4944,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.17493,
    shape_pt_lon: -90.044022,
    shape_pt_sequence: 572,
    shape_dist_traveled: 32.5852,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.17394,
    shape_pt_lon: -90.043823,
    shape_pt_sequence: 573,
    shape_dist_traveled: 32.6977,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.173379,
    shape_pt_lon: -90.043711,
    shape_pt_sequence: 574,
    shape_dist_traveled: 32.7615,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.17313,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 575,
    shape_dist_traveled: 32.7898,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.172203,
    shape_pt_lon: -90.043498,
    shape_pt_sequence: 576,
    shape_dist_traveled: 32.8938,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.170967,
    shape_pt_lon: -90.043277,
    shape_pt_sequence: 577,
    shape_dist_traveled: 33.0343,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.170641,
    shape_pt_lon: -90.043217,
    shape_pt_sequence: 578,
    shape_dist_traveled: 33.0708,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.169807,
    shape_pt_lon: -90.043067,
    shape_pt_sequence: 579,
    shape_dist_traveled: 33.1657,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.169577,
    shape_pt_lon: -90.043028,
    shape_pt_sequence: 580,
    shape_dist_traveled: 33.191,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.169,
    shape_pt_lon: -90.042977,
    shape_pt_sequence: 581,
    shape_dist_traveled: 33.2562,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.168379,
    shape_pt_lon: -90.042933,
    shape_pt_sequence: 582,
    shape_dist_traveled: 33.3253,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.168186,
    shape_pt_lon: -90.042925,
    shape_pt_sequence: 583,
    shape_dist_traveled: 33.3473,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.167976,
    shape_pt_lon: -90.042931,
    shape_pt_sequence: 584,
    shape_dist_traveled: 33.3703,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.16765,
    shape_pt_lon: -90.042981,
    shape_pt_sequence: 585,
    shape_dist_traveled: 33.4076,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.166425,
    shape_pt_lon: -90.04319,
    shape_pt_sequence: 586,
    shape_dist_traveled: 33.5459,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.16591,
    shape_pt_lon: -90.043281,
    shape_pt_sequence: 587,
    shape_dist_traveled: 33.6045,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.16539,
    shape_pt_lon: -90.043373,
    shape_pt_sequence: 588,
    shape_dist_traveled: 33.663,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.165118,
    shape_pt_lon: -90.043443,
    shape_pt_sequence: 589,
    shape_dist_traveled: 33.6936,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.163728,
    shape_pt_lon: -90.043988,
    shape_pt_sequence: 590,
    shape_dist_traveled: 33.8575,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.163583,
    shape_pt_lon: -90.043386,
    shape_pt_sequence: 591,
    shape_dist_traveled: 33.9147,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.163531,
    shape_pt_lon: -90.043125,
    shape_pt_sequence: 592,
    shape_dist_traveled: 33.9385,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 593,
    shape_dist_traveled: 33.9748,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 594,
    shape_dist_traveled: 34.0976,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 595,
    shape_dist_traveled: 34.2142,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 596,
    shape_dist_traveled: 34.3328,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 597,
    shape_dist_traveled: 34.4495,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 598,
    shape_dist_traveled: 34.5052,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 599,
    shape_dist_traveled: 34.5672,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 600,
    shape_dist_traveled: 34.6292,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 601,
    shape_dist_traveled: 34.688,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 602,
    shape_dist_traveled: 34.8015,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 603,
    shape_dist_traveled: 34.854,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 604,
    shape_dist_traveled: 34.854,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 605,
    shape_dist_traveled: 34.9106,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 606,
    shape_dist_traveled: 34.9106,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 607,
    shape_dist_traveled: 34.916,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 608,
    shape_dist_traveled: 34.9865,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 609,
    shape_dist_traveled: 35.0337,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 610,
    shape_dist_traveled: 35.0934,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 611,
    shape_dist_traveled: 35.1162,
  },
  {
    shape_id: 38242,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 612,
    shape_dist_traveled: 35.1826,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.993016,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.066,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217237,
    shape_pt_lon: -89.993565,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1161,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217253,
    shape_pt_lon: -89.994126,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1661,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.21732,
    shape_pt_lon: -89.996025,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.3383,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.21734,
    shape_pt_lon: -89.996802,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.4083,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217343,
    shape_pt_lon: -89.996938,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.4203,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217386,
    shape_pt_lon: -89.998142,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.5294,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217416,
    shape_pt_lon: -89.999249,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.6295,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.21743,
    shape_pt_lon: -89.999622,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.6635,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217451,
    shape_pt_lon: -90.000286,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.7226,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217469,
    shape_pt_lon: -90.000907,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7796,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217479,
    shape_pt_lon: -90.001315,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8156,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217511,
    shape_pt_lon: -90.002238,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8997,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217514,
    shape_pt_lon: -90.002336,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.9087,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217523,
    shape_pt_lon: -90.002643,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.9357,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.217548,
    shape_pt_lon: -90.003425,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.0068,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.216526,
    shape_pt_lon: -90.003461,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.1218,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.21488,
    shape_pt_lon: -90.003526,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.3059,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.214512,
    shape_pt_lon: -90.003538,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.347,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.21371,
    shape_pt_lon: -90.003571,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.437,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.213126,
    shape_pt_lon: -90.003601,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.502,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.212896,
    shape_pt_lon: -90.003612,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.5281,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.211682,
    shape_pt_lon: -90.003668,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.6641,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.211291,
    shape_pt_lon: -90.003683,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.7082,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.211203,
    shape_pt_lon: -90.003687,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.7172,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.210252,
    shape_pt_lon: -90.003729,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.8243,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.209426,
    shape_pt_lon: -90.00376,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.9163,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.209191,
    shape_pt_lon: -90.003771,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.9433,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.208351,
    shape_pt_lon: -90.003806,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.0374,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.208248,
    shape_pt_lon: -90.003809,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.0484,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.20781,
    shape_pt_lon: -90.003833,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.0974,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.206858,
    shape_pt_lon: -90.003907,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.2046,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.205717,
    shape_pt_lon: -90.003947,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.3317,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.205412,
    shape_pt_lon: -90.003961,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.3667,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.205123,
    shape_pt_lon: -90.004005,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.3989,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.204929,
    shape_pt_lon: -90.004052,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.4213,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.20466,
    shape_pt_lon: -90.00415,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.4526,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.204593,
    shape_pt_lon: -90.004178,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.4602,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.204344,
    shape_pt_lon: -90.004315,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.4907,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.2041,
    shape_pt_lon: -90.004483,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.5216,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.203945,
    shape_pt_lon: -90.004612,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.5432,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.203785,
    shape_pt_lon: -90.004768,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.566,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.203628,
    shape_pt_lon: -90.004954,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.5901,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.203457,
    shape_pt_lon: -90.00519,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.6184,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.203265,
    shape_pt_lon: -90.005514,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.6548,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.202697,
    shape_pt_lon: -90.006758,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.7846,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.202583,
    shape_pt_lon: -90.006958,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.8063,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.202445,
    shape_pt_lon: -90.007142,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.8289,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.20229,
    shape_pt_lon: -90.007307,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.8516,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.202228,
    shape_pt_lon: -90.007368,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.8608,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.201996,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8903,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.201828,
    shape_pt_lon: -90.00762,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9109,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.201649,
    shape_pt_lon: -90.007688,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9318,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.201459,
    shape_pt_lon: -90.007729,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9532,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.201422,
    shape_pt_lon: -90.007732,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.9572,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.200607,
    shape_pt_lon: -90.007759,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.0482,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.199442,
    shape_pt_lon: -90.007783,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.1793,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.199369,
    shape_pt_lon: -90.007784,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.1873,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.199328,
    shape_pt_lon: -90.007786,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.1923,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.198055,
    shape_pt_lon: -90.007812,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3343,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.197538,
    shape_pt_lon: -90.007827,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3923,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.197393,
    shape_pt_lon: -90.007828,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4083,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.196498,
    shape_pt_lon: -90.007847,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.5083,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.196056,
    shape_pt_lon: -90.007857,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5583,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.195356,
    shape_pt_lon: -90.007899,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.6364,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.194176,
    shape_pt_lon: -90.007928,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7685,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.194004,
    shape_pt_lon: -90.00793,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.7875,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.19336,
    shape_pt_lon: -90.007938,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8595,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.192889,
    shape_pt_lon: -90.007942,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.9125,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.192774,
    shape_pt_lon: -90.007946,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.9255,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.19246,
    shape_pt_lon: -90.007963,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9605,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.192168,
    shape_pt_lon: -90.00798,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.9935,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.191997,
    shape_pt_lon: -90.007983,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0125,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.191647,
    shape_pt_lon: -90.007992,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.0516,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.191328,
    shape_pt_lon: -90.007993,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.0876,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.190996,
    shape_pt_lon: -90.007989,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.1246,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.19011,
    shape_pt_lon: -90.008017,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.2236,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.188559,
    shape_pt_lon: -90.008071,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3977,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.188032,
    shape_pt_lon: -90.008078,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.4567,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.187644,
    shape_pt_lon: -90.008093,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.4997,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.18727,
    shape_pt_lon: -90.008103,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.5417,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.186212,
    shape_pt_lon: -90.008131,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.6597,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.182365,
    shape_pt_lon: -90.008203,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.0908,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.181918,
    shape_pt_lon: -90.00821,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.1408,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.178113,
    shape_pt_lon: -90.008308,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.5669,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.17676,
    shape_pt_lon: -90.008348,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.7179,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.175712,
    shape_pt_lon: -90.008378,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.8349,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.174337,
    shape_pt_lon: -90.008404,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.9889,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.173082,
    shape_pt_lon: -90.008452,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.13,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.1332,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.172973,
    shape_pt_lon: -90.008458,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.1422,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.172662,
    shape_pt_lon: -90.008473,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.1772,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.172051,
    shape_pt_lon: -90.008501,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.2452,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.171766,
    shape_pt_lon: -90.008558,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.2776,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.171383,
    shape_pt_lon: -90.008676,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.3218,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.171111,
    shape_pt_lon: -90.00879,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.3537,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.170649,
    shape_pt_lon: -90.009023,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.4098,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.17018,
    shape_pt_lon: -90.009259,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.4659,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.170029,
    shape_pt_lon: -90.009337,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.4843,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.168936,
    shape_pt_lon: -90.009896,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.617,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.168629,
    shape_pt_lon: -90.010038,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.6534,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.168373,
    shape_pt_lon: -90.010133,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.6838,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.168159,
    shape_pt_lon: -90.010195,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.7083,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.168034,
    shape_pt_lon: -90.010221,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.7226,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.16773,
    shape_pt_lon: -90.010272,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.7569,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.167341,
    shape_pt_lon: -90.010296,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.7999,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.165539,
    shape_pt_lon: -90.010343,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.002,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.165162,
    shape_pt_lon: -90.010337,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.044,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.094,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.16445,
    shape_pt_lon: -90.010391,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.1242,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.164108,
    shape_pt_lon: -90.010454,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.1627,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.162486,
    shape_pt_lon: -90.010693,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.3449,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.160606,
    shape_pt_lon: -90.01096,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.5572,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.15902,
    shape_pt_lon: -90.011197,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.7355,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158881,
    shape_pt_lon: -90.011238,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.752,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158726,
    shape_pt_lon: -90.011302,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.77,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158557,
    shape_pt_lon: -90.011418,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.7915,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158208,
    shape_pt_lon: -90.011708,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.8383,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158155,
    shape_pt_lon: -90.011747,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.8455,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158109,
    shape_pt_lon: -90.011776,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.8514,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.158035,
    shape_pt_lon: -90.0118,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.8606,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.157633,
    shape_pt_lon: -90.011859,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.9059,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.157128,
    shape_pt_lon: -90.011931,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.9622,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.15611,
    shape_pt_lon: -90.012078,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.077,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.012092,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.0891,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.15495,
    shape_pt_lon: -90.01225,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.2079,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153933,
    shape_pt_lon: -90.012462,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.3235,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153787,
    shape_pt_lon: -90.012493,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.3398,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.1536,
    shape_pt_lon: -90.012531,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.361,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153553,
    shape_pt_lon: -90.01254,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.3661,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153521,
    shape_pt_lon: -90.012548,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.3702,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153471,
    shape_pt_lon: -90.012558,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.3753,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153433,
    shape_pt_lon: -90.012566,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.3804,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.15326,
    shape_pt_lon: -90.012592,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.3995,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.153087,
    shape_pt_lon: -90.01262,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.4186,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.152977,
    shape_pt_lon: -90.012638,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.4318,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.15278,
    shape_pt_lon: -90.012669,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.454,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151908,
    shape_pt_lon: -90.012814,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.5518,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151783,
    shape_pt_lon: -90.012837,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.566,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151617,
    shape_pt_lon: -90.012892,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.5856,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151501,
    shape_pt_lon: -90.012952,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.5996,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151374,
    shape_pt_lon: -90.013042,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.6157,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151238,
    shape_pt_lon: -90.013175,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.6349,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.151115,
    shape_pt_lon: -90.013341,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.6554,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150891,
    shape_pt_lon: -90.013742,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.7001,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150838,
    shape_pt_lon: -90.013825,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.7093,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150698,
    shape_pt_lon: -90.013979,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.7305,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150545,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.7508,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150399,
    shape_pt_lon: -90.014178,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.7683,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.150237,
    shape_pt_lon: -90.014233,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.7869,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.149378,
    shape_pt_lon: -90.014367,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.8837,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.147921,
    shape_pt_lon: -90.014581,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.0478,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.146667,
    shape_pt_lon: -90.014761,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.1897,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.146408,
    shape_pt_lon: -90.01481,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.2191,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.146299,
    shape_pt_lon: -90.014852,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.2318,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.1462,
    shape_pt_lon: -90.014904,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.2435,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.146046,
    shape_pt_lon: -90.015016,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.2632,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.145953,
    shape_pt_lon: -90.015108,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2774,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.145873,
    shape_pt_lon: -90.015187,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.2888,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.145716,
    shape_pt_lon: -90.015301,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.3085,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.145597,
    shape_pt_lon: -90.015362,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.3238,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.145417,
    shape_pt_lon: -90.015419,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.3444,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.144987,
    shape_pt_lon: -90.015481,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.3926,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.144581,
    shape_pt_lon: -90.01554,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.438,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.143956,
    shape_pt_lon: -90.015646,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.5086,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.143648,
    shape_pt_lon: -90.015706,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.5441,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.142749,
    shape_pt_lon: -90.015838,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.6448,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.142189,
    shape_pt_lon: -90.015913,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.7081,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.14157,
    shape_pt_lon: -90.015999,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.7766,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.140917,
    shape_pt_lon: -90.016094,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.8501,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.016192,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.9256,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.139586,
    shape_pt_lon: -90.016287,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.0001,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.0765,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.13837,
    shape_pt_lon: -90.016457,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.1369,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.01653,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.1954,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.137333,
    shape_pt_lon: -90.016604,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.2537,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.136839,
    shape_pt_lon: -90.016669,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.31,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.135617,
    shape_pt_lon: -90.016726,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.4461,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.134511,
    shape_pt_lon: -90.016772,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.5702,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.133528,
    shape_pt_lon: -90.016833,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.6803,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.133162,
    shape_pt_lon: -90.016845,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.7213,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132599,
    shape_pt_lon: -90.016857,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.7843,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132615,
    shape_pt_lon: -90.017536,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.8454,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132712,
    shape_pt_lon: -90.020268,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.0926,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132732,
    shape_pt_lon: -90.020757,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.1367,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132737,
    shape_pt_lon: -90.020829,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.1437,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.132279,
    shape_pt_lon: -90.020818,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.1947,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.020846,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.2547,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.131609,
    shape_pt_lon: -90.020856,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.2698,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.130807,
    shape_pt_lon: -90.020916,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.36,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.129803,
    shape_pt_lon: -90.021,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.4722,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.5964,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.127654,
    shape_pt_lon: -90.021121,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.7134,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.126632,
    shape_pt_lon: -90.02116,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.8275,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.125636,
    shape_pt_lon: -90.021203,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.9385,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.124627,
    shape_pt_lon: -90.021241,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.0516,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.123991,
    shape_pt_lon: -90.021266,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.1236,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.122942,
    shape_pt_lon: -90.021309,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.2407,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.122769,
    shape_pt_lon: -90.021316,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.2597,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.122748,
    shape_pt_lon: -90.021317,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.2627,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.122524,
    shape_pt_lon: -90.021327,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.2877,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.122289,
    shape_pt_lon: -90.021337,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.3138,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.121789,
    shape_pt_lon: -90.021356,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.3698,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.121548,
    shape_pt_lon: -90.021366,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.3968,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.120011,
    shape_pt_lon: -90.021426,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.5689,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.118996,
    shape_pt_lon: -90.021466,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.6819,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.118391,
    shape_pt_lon: -90.021489,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.75,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.118025,
    shape_pt_lon: -90.021507,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.791,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.117086,
    shape_pt_lon: -90.021551,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.8961,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.115578,
    shape_pt_lon: -90.02165,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.0653,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.115312,
    shape_pt_lon: -90.021665,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.0953,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.11382,
    shape_pt_lon: -90.021719,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.2624,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.113101,
    shape_pt_lon: -90.021698,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.3424,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.112628,
    shape_pt_lon: -90.021685,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.3954,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.11247,
    shape_pt_lon: -90.021688,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.4134,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.111539,
    shape_pt_lon: -90.021727,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.5175,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.10987,
    shape_pt_lon: -90.021796,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.7046,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.109655,
    shape_pt_lon: -90.021754,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.7289,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.109589,
    shape_pt_lon: -90.021741,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.736,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.109479,
    shape_pt_lon: -90.02172,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.7482,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.109147,
    shape_pt_lon: -90.021652,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.7866,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.108543,
    shape_pt_lon: -90.021528,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.8545,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.107743,
    shape_pt_lon: -90.021361,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.9458,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.106891,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.0423,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.106142,
    shape_pt_lon: -90.021022,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.1275,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.105208,
    shape_pt_lon: -90.0208,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.2334,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.104534,
    shape_pt_lon: -90.020591,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.3117,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.103955,
    shape_pt_lon: -90.020376,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.3787,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.10341,
    shape_pt_lon: -90.020148,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.4429,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.101882,
    shape_pt_lon: -90.019427,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.6262,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.10153,
    shape_pt_lon: -90.019249,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.6693,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -90.018858,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.765,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.100182,
    shape_pt_lon: -90.018618,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.8298,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.099587,
    shape_pt_lon: -90.018368,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.9006,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09898,
    shape_pt_lon: -90.018181,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.9705,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.098644,
    shape_pt_lon: -90.018106,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.0091,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.098341,
    shape_pt_lon: -90.018052,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.0425,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.098029,
    shape_pt_lon: -90.01802,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.0776,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09749,
    shape_pt_lon: -90.017999,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.1387,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09727,
    shape_pt_lon: -90.017998,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.1627,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09681,
    shape_pt_lon: -90.018002,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.2147,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.096155,
    shape_pt_lon: -90.018032,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.2877,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09549,
    shape_pt_lon: -90.018052,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.3618,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.095307,
    shape_pt_lon: -90.01806,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.3828,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09483,
    shape_pt_lon: -90.018086,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.4358,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.094176,
    shape_pt_lon: -90.018109,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.5099,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.09375,
    shape_pt_lon: -90.018128,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.5569,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.092803,
    shape_pt_lon: -90.018144,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.6629,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.091315,
    shape_pt_lon: -90.018206,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.83,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.090999,
    shape_pt_lon: -90.018219,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.865,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.090686,
    shape_pt_lon: -90.01823,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.9,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.087535,
    shape_pt_lon: -90.018358,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.2532,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.08686,
    shape_pt_lon: -90.018388,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.3283,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.086487,
    shape_pt_lon: -90.018402,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.3703,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.086333,
    shape_pt_lon: -90.01841,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.3873,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.086203,
    shape_pt_lon: -90.018414,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.4023,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.08549,
    shape_pt_lon: -90.018436,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.4813,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.084388,
    shape_pt_lon: -90.018487,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.6054,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.083643,
    shape_pt_lon: -90.018532,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.6885,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.083304,
    shape_pt_lon: -90.018566,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.7266,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.082303,
    shape_pt_lon: -90.018657,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.839,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.081148,
    shape_pt_lon: -90.018829,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.9688,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.080494,
    shape_pt_lon: -90.018947,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.0427,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.079849,
    shape_pt_lon: -90.019083,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.1167,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.078139,
    shape_pt_lon: -90.019483,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.311,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.078085,
    shape_pt_lon: -90.019498,
    shape_pt_sequence: 267,
    shape_dist_traveled: 17.3171,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.07676,
    shape_pt_lon: -90.019829,
    shape_pt_sequence: 268,
    shape_dist_traveled: 17.4681,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.074469,
    shape_pt_lon: -90.02039,
    shape_pt_sequence: 269,
    shape_dist_traveled: 17.7301,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.074337,
    shape_pt_lon: -90.020422,
    shape_pt_sequence: 270,
    shape_dist_traveled: 17.7444,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.072887,
    shape_pt_lon: -90.020782,
    shape_pt_sequence: 271,
    shape_dist_traveled: 17.9105,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.072667,
    shape_pt_lon: -90.020841,
    shape_pt_sequence: 272,
    shape_dist_traveled: 17.9351,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.071576,
    shape_pt_lon: -90.02111,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.0596,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.071441,
    shape_pt_lon: -90.021143,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.0759,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.071326,
    shape_pt_lon: -90.021171,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.088,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.071206,
    shape_pt_lon: -90.0212,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.1024,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.070713,
    shape_pt_lon: -90.021318,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.1585,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.068132,
    shape_pt_lon: -90.021949,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.4528,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.06559,
    shape_pt_lon: -90.022576,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.7425,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.065029,
    shape_pt_lon: -90.022717,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.8068,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.064563,
    shape_pt_lon: -90.022848,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.8602,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.063729,
    shape_pt_lon: -90.023047,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.9547,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.063678,
    shape_pt_lon: -90.023058,
    shape_pt_sequence: 283,
    shape_dist_traveled: 18.9608,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.063579,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.9722,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.063496,
    shape_pt_lon: -90.023105,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.9824,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.063247,
    shape_pt_lon: -90.023169,
    shape_pt_sequence: 286,
    shape_dist_traveled: 19.0101,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.062481,
    shape_pt_lon: -90.023362,
    shape_pt_sequence: 287,
    shape_dist_traveled: 19.0977,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.062212,
    shape_pt_lon: -90.023421,
    shape_pt_sequence: 288,
    shape_dist_traveled: 19.1281,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 289,
    shape_dist_traveled: 19.1874,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.061209,
    shape_pt_lon: -90.023668,
    shape_pt_sequence: 290,
    shape_dist_traveled: 19.2425,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.060552,
    shape_pt_lon: -90.023816,
    shape_pt_sequence: 291,
    shape_dist_traveled: 19.3176,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.060483,
    shape_pt_lon: -90.023832,
    shape_pt_sequence: 292,
    shape_dist_traveled: 19.3257,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.060416,
    shape_pt_lon: -90.023848,
    shape_pt_sequence: 293,
    shape_dist_traveled: 19.333,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.059688,
    shape_pt_lon: -90.024017,
    shape_pt_sequence: 294,
    shape_dist_traveled: 19.4163,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.058089,
    shape_pt_lon: -90.024402,
    shape_pt_sequence: 295,
    shape_dist_traveled: 19.5987,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.057628,
    shape_pt_lon: -90.02451,
    shape_pt_sequence: 296,
    shape_dist_traveled: 19.6505,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.05654,
    shape_pt_lon: -90.024714,
    shape_pt_sequence: 297,
    shape_dist_traveled: 19.774,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.056318,
    shape_pt_lon: -90.024747,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.7991,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.056011,
    shape_pt_lon: -90.024798,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.8334,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.054999,
    shape_pt_lon: -90.024925,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.947,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.054256,
    shape_pt_lon: -90.025002,
    shape_pt_sequence: 301,
    shape_dist_traveled: 20.0303,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.053422,
    shape_pt_lon: -90.025036,
    shape_pt_sequence: 302,
    shape_dist_traveled: 20.1244,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.051575,
    shape_pt_lon: -90.025072,
    shape_pt_sequence: 303,
    shape_dist_traveled: 20.3304,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.051532,
    shape_pt_lon: -90.025071,
    shape_pt_sequence: 304,
    shape_dist_traveled: 20.3354,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.051286,
    shape_pt_lon: -90.02507,
    shape_pt_sequence: 305,
    shape_dist_traveled: 20.3634,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.049707,
    shape_pt_lon: -90.025077,
    shape_pt_sequence: 306,
    shape_dist_traveled: 20.5394,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.049101,
    shape_pt_lon: -90.025083,
    shape_pt_sequence: 307,
    shape_dist_traveled: 20.6074,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.047836,
    shape_pt_lon: -90.0251,
    shape_pt_sequence: 308,
    shape_dist_traveled: 20.7494,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.046416,
    shape_pt_lon: -90.025106,
    shape_pt_sequence: 309,
    shape_dist_traveled: 20.9084,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.045019,
    shape_pt_lon: -90.025108,
    shape_pt_sequence: 310,
    shape_dist_traveled: 21.0644,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.044586,
    shape_pt_lon: -90.025109,
    shape_pt_sequence: 311,
    shape_dist_traveled: 21.1124,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.044216,
    shape_pt_lon: -90.025109,
    shape_pt_sequence: 312,
    shape_dist_traveled: 21.1544,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.043934,
    shape_pt_lon: -90.025108,
    shape_pt_sequence: 313,
    shape_dist_traveled: 21.1854,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.04251,
    shape_pt_lon: -90.025111,
    shape_pt_sequence: 314,
    shape_dist_traveled: 21.3454,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.041884,
    shape_pt_lon: -90.025112,
    shape_pt_sequence: 315,
    shape_dist_traveled: 21.4154,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.041013,
    shape_pt_lon: -90.02512,
    shape_pt_sequence: 316,
    shape_dist_traveled: 21.5124,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.040631,
    shape_pt_lon: -90.02512,
    shape_pt_sequence: 317,
    shape_dist_traveled: 21.5554,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.039992,
    shape_pt_lon: -90.02513,
    shape_pt_sequence: 318,
    shape_dist_traveled: 21.6264,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.03557,
    shape_pt_lon: -90.025148,
    shape_pt_sequence: 319,
    shape_dist_traveled: 22.1214,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035503,
    shape_pt_lon: -90.025149,
    shape_pt_sequence: 320,
    shape_dist_traveled: 22.1294,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035512,
    shape_pt_lon: -90.023979,
    shape_pt_sequence: 321,
    shape_dist_traveled: 22.2354,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035515,
    shape_pt_lon: -90.023245,
    shape_pt_sequence: 322,
    shape_dist_traveled: 22.3014,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035517,
    shape_pt_lon: -90.022219,
    shape_pt_sequence: 323,
    shape_dist_traveled: 22.3944,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035522,
    shape_pt_lon: -90.021135,
    shape_pt_sequence: 324,
    shape_dist_traveled: 22.4924,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.035528,
    shape_pt_lon: -90.019797,
    shape_pt_sequence: 325,
    shape_dist_traveled: 22.6134,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.034453,
    shape_pt_lon: -90.019783,
    shape_pt_sequence: 326,
    shape_dist_traveled: 22.7334,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.034219,
    shape_pt_lon: -90.019783,
    shape_pt_sequence: 327,
    shape_dist_traveled: 22.7594,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.033871,
    shape_pt_lon: -90.019784,
    shape_pt_sequence: 328,
    shape_dist_traveled: 22.7984,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.033546,
    shape_pt_lon: -90.019782,
    shape_pt_sequence: 329,
    shape_dist_traveled: 22.8344,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.03237,
    shape_pt_lon: -90.019787,
    shape_pt_sequence: 330,
    shape_dist_traveled: 22.9664,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.031828,
    shape_pt_lon: -90.019791,
    shape_pt_sequence: 331,
    shape_dist_traveled: 23.0274,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.031293,
    shape_pt_lon: -90.019791,
    shape_pt_sequence: 332,
    shape_dist_traveled: 23.0874,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.030694,
    shape_pt_lon: -90.01981,
    shape_pt_sequence: 333,
    shape_dist_traveled: 23.1545,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.030308,
    shape_pt_lon: -90.019842,
    shape_pt_sequence: 334,
    shape_dist_traveled: 23.1976,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.029892,
    shape_pt_lon: -90.019857,
    shape_pt_sequence: 335,
    shape_dist_traveled: 23.2436,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02978,
    shape_pt_lon: -90.019857,
    shape_pt_sequence: 336,
    shape_dist_traveled: 23.2566,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.028947,
    shape_pt_lon: -90.019856,
    shape_pt_sequence: 337,
    shape_dist_traveled: 23.3496,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.028357,
    shape_pt_lon: -90.019859,
    shape_pt_sequence: 338,
    shape_dist_traveled: 23.4156,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.028228,
    shape_pt_lon: -90.019859,
    shape_pt_sequence: 339,
    shape_dist_traveled: 23.4296,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.026966,
    shape_pt_lon: -90.019863,
    shape_pt_sequence: 340,
    shape_dist_traveled: 23.5716,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.026135,
    shape_pt_lon: -90.019862,
    shape_pt_sequence: 341,
    shape_dist_traveled: 23.6646,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.025443,
    shape_pt_lon: -90.019865,
    shape_pt_sequence: 342,
    shape_dist_traveled: 23.7416,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.023252,
    shape_pt_lon: -90.019874,
    shape_pt_sequence: 343,
    shape_dist_traveled: 23.9866,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021592,
    shape_pt_lon: -90.019878,
    shape_pt_sequence: 344,
    shape_dist_traveled: 24.1726,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021021,
    shape_pt_lon: -90.019878,
    shape_pt_sequence: 345,
    shape_dist_traveled: 24.2366,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021028,
    shape_pt_lon: -90.01798,
    shape_pt_sequence: 346,
    shape_dist_traveled: 24.4076,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.014056,
    shape_pt_sequence: 347,
    shape_dist_traveled: 24.7626,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.014007,
    shape_pt_sequence: 348,
    shape_dist_traveled: 24.7676,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.013957,
    shape_pt_sequence: 349,
    shape_dist_traveled: 24.7716,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021059,
    shape_pt_lon: -90.012471,
    shape_pt_sequence: 350,
    shape_dist_traveled: 24.9066,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021079,
    shape_pt_lon: -90.010369,
    shape_pt_sequence: 351,
    shape_dist_traveled: 25.0966,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021089,
    shape_pt_lon: -90.009228,
    shape_pt_sequence: 352,
    shape_dist_traveled: 25.1996,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.007592,
    shape_pt_sequence: 353,
    shape_dist_traveled: 25.3476,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.007527,
    shape_pt_sequence: 354,
    shape_dist_traveled: 25.3536,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021098,
    shape_pt_lon: -90.007086,
    shape_pt_sequence: 355,
    shape_dist_traveled: 25.3936,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.006189,
    shape_pt_sequence: 356,
    shape_dist_traveled: 25.4746,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021091,
    shape_pt_lon: -90.005253,
    shape_pt_sequence: 357,
    shape_dist_traveled: 25.5596,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021074,
    shape_pt_lon: -90.004769,
    shape_pt_sequence: 358,
    shape_dist_traveled: 25.6037,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021074,
    shape_pt_lon: -90.004429,
    shape_pt_sequence: 359,
    shape_dist_traveled: 25.6347,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021072,
    shape_pt_lon: -90.003697,
    shape_pt_sequence: 360,
    shape_dist_traveled: 25.7007,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021076,
    shape_pt_lon: -90.002867,
    shape_pt_sequence: 361,
    shape_dist_traveled: 25.7757,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021079,
    shape_pt_lon: -90.002724,
    shape_pt_sequence: 362,
    shape_dist_traveled: 25.7887,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021081,
    shape_pt_lon: -90.00239,
    shape_pt_sequence: 363,
    shape_dist_traveled: 25.8187,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021088,
    shape_pt_lon: -90.001955,
    shape_pt_sequence: 364,
    shape_dist_traveled: 25.8587,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02108,
    shape_pt_lon: -90.001237,
    shape_pt_sequence: 365,
    shape_dist_traveled: 25.9237,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021091,
    shape_pt_lon: -90.000777,
    shape_pt_sequence: 366,
    shape_dist_traveled: 25.9647,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -89.999997,
    shape_pt_sequence: 367,
    shape_dist_traveled: 26.0358,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021099,
    shape_pt_lon: -89.999863,
    shape_pt_sequence: 368,
    shape_dist_traveled: 26.0478,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021098,
    shape_pt_lon: -89.998869,
    shape_pt_sequence: 369,
    shape_dist_traveled: 26.1378,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021081,
    shape_pt_lon: -89.998581,
    shape_pt_sequence: 370,
    shape_dist_traveled: 26.1638,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021039,
    shape_pt_lon: -89.998491,
    shape_pt_sequence: 371,
    shape_dist_traveled: 26.1733,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021038,
    shape_pt_lon: -89.998268,
    shape_pt_sequence: 372,
    shape_dist_traveled: 26.1933,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021036,
    shape_pt_lon: -89.99659,
    shape_pt_sequence: 373,
    shape_dist_traveled: 26.3453,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021033,
    shape_pt_lon: -89.996025,
    shape_pt_sequence: 374,
    shape_dist_traveled: 26.3963,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02104,
    shape_pt_lon: -89.994149,
    shape_pt_sequence: 375,
    shape_dist_traveled: 26.5663,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.02104,
    shape_pt_lon: -89.99282,
    shape_pt_sequence: 376,
    shape_dist_traveled: 26.6863,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021039,
    shape_pt_lon: -89.992444,
    shape_pt_sequence: 377,
    shape_dist_traveled: 26.7203,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.021048,
    shape_pt_lon: -89.989819,
    shape_pt_sequence: 378,
    shape_dist_traveled: 26.9583,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.018804,
    shape_pt_lon: -89.989842,
    shape_pt_sequence: 379,
    shape_dist_traveled: 27.2093,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.015761,
    shape_pt_lon: -89.989846,
    shape_pt_sequence: 380,
    shape_dist_traveled: 27.5493,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.014031,
    shape_pt_lon: -89.98985,
    shape_pt_sequence: 381,
    shape_dist_traveled: 27.7433,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.01368,
    shape_pt_lon: -89.989859,
    shape_pt_sequence: 382,
    shape_dist_traveled: 27.7823,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.013607,
    shape_pt_lon: -89.98986,
    shape_pt_sequence: 383,
    shape_dist_traveled: 27.7903,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.013191,
    shape_pt_lon: -89.989867,
    shape_pt_sequence: 384,
    shape_dist_traveled: 27.8373,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.012103,
    shape_pt_lon: -89.989869,
    shape_pt_sequence: 385,
    shape_dist_traveled: 27.9593,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.011359,
    shape_pt_lon: -89.989869,
    shape_pt_sequence: 386,
    shape_dist_traveled: 28.0423,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.011311,
    shape_pt_lon: -89.989869,
    shape_pt_sequence: 387,
    shape_dist_traveled: 28.0473,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.011265,
    shape_pt_lon: -89.989869,
    shape_pt_sequence: 388,
    shape_dist_traveled: 28.0533,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.008349,
    shape_pt_lon: -89.989881,
    shape_pt_sequence: 389,
    shape_dist_traveled: 28.3793,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.007431,
    shape_pt_lon: -89.989878,
    shape_pt_sequence: 390,
    shape_dist_traveled: 28.4813,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006821,
    shape_pt_lon: -89.989871,
    shape_pt_sequence: 391,
    shape_dist_traveled: 28.5503,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.00648,
    shape_pt_lon: -89.989877,
    shape_pt_sequence: 392,
    shape_dist_traveled: 28.5883,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006479,
    shape_pt_lon: -89.989922,
    shape_pt_sequence: 393,
    shape_dist_traveled: 28.5923,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006478,
    shape_pt_lon: -89.989987,
    shape_pt_sequence: 394,
    shape_dist_traveled: 28.5983,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006468,
    shape_pt_lon: -89.990539,
    shape_pt_sequence: 395,
    shape_dist_traveled: 28.6483,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006459,
    shape_pt_lon: -89.992606,
    shape_pt_sequence: 396,
    shape_dist_traveled: 28.8353,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006446,
    shape_pt_lon: -89.993731,
    shape_pt_sequence: 397,
    shape_dist_traveled: 28.9373,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006436,
    shape_pt_lon: -89.99633,
    shape_pt_sequence: 398,
    shape_dist_traveled: 29.1723,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006439,
    shape_pt_lon: -89.998232,
    shape_pt_sequence: 399,
    shape_dist_traveled: 29.3443,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006438,
    shape_pt_lon: -89.998602,
    shape_pt_sequence: 400,
    shape_dist_traveled: 29.3783,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.006409,
    shape_pt_lon: -89.999106,
    shape_pt_sequence: 401,
    shape_dist_traveled: 29.4234,
  },
  {
    shape_id: 38243,
    shape_pt_lat: 35.0064,
    shape_pt_lon: -89.999581,
    shape_pt_sequence: 402,
    shape_dist_traveled: 29.4665,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.0064,
    shape_pt_lon: -89.999581,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006393,
    shape_pt_lon: -89.999822,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.022,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006403,
    shape_pt_lon: -90,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.038,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006396,
    shape_pt_lon: -90.001462,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.171,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006396,
    shape_pt_lon: -90.001826,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.204,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006395,
    shape_pt_lon: -90.002499,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.265,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006393,
    shape_pt_lon: -90.002807,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2921,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006393,
    shape_pt_lon: -90.003021,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3121,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006392,
    shape_pt_lon: -90.003524,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3571,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006411,
    shape_pt_lon: -90.004676,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4621,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006429,
    shape_pt_lon: -90.004958,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4872,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006431,
    shape_pt_lon: -90.005456,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.5322,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006432,
    shape_pt_lon: -90.005973,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5792,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006432,
    shape_pt_lon: -90.006352,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.6132,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006427,
    shape_pt_lon: -90.007571,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.7242,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006426,
    shape_pt_lon: -90.007661,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.7322,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006428,
    shape_pt_lon: -90.009005,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.8532,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006427,
    shape_pt_lon: -90.01032,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.9722,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006421,
    shape_pt_lon: -90.011457,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.0752,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006419,
    shape_pt_lon: -90.012538,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.1732,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006421,
    shape_pt_lon: -90.01342,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.2532,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006409,
    shape_pt_lon: -90.01567,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.4572,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006413,
    shape_pt_lon: -90.016871,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.5652,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006399,
    shape_pt_lon: -90.019323,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.7872,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006396,
    shape_pt_lon: -90.021235,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.9602,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.00639,
    shape_pt_lon: -90.02352,
    shape_pt_sequence: 26,
    shape_dist_traveled: 2.1672,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.006409,
    shape_pt_lon: -90.025272,
    shape_pt_sequence: 27,
    shape_dist_traveled: 2.3262,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.007929,
    shape_pt_lon: -90.025261,
    shape_pt_sequence: 28,
    shape_dist_traveled: 2.4962,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.010407,
    shape_pt_lon: -90.025248,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.7732,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.010832,
    shape_pt_lon: -90.025247,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.8212,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.011511,
    shape_pt_lon: -90.025243,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.8972,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.012471,
    shape_pt_lon: -90.025242,
    shape_pt_sequence: 32,
    shape_dist_traveled: 3.0042,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.012942,
    shape_pt_lon: -90.025239,
    shape_pt_sequence: 33,
    shape_dist_traveled: 3.0572,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.013766,
    shape_pt_lon: -90.025236,
    shape_pt_sequence: 34,
    shape_dist_traveled: 3.1492,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.01458,
    shape_pt_lon: -90.025229,
    shape_pt_sequence: 35,
    shape_dist_traveled: 3.2402,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.015418,
    shape_pt_lon: -90.025223,
    shape_pt_sequence: 36,
    shape_dist_traveled: 3.3342,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.016632,
    shape_pt_lon: -90.025218,
    shape_pt_sequence: 37,
    shape_dist_traveled: 3.4702,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.017219,
    shape_pt_lon: -90.025218,
    shape_pt_sequence: 38,
    shape_dist_traveled: 3.5362,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.018167,
    shape_pt_lon: -90.025218,
    shape_pt_sequence: 39,
    shape_dist_traveled: 3.6422,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.018701,
    shape_pt_lon: -90.025215,
    shape_pt_sequence: 40,
    shape_dist_traveled: 3.7012,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.018793,
    shape_pt_lon: -90.025215,
    shape_pt_sequence: 41,
    shape_dist_traveled: 3.7122,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.01915,
    shape_pt_lon: -90.025212,
    shape_pt_sequence: 42,
    shape_dist_traveled: 3.7522,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.019469,
    shape_pt_lon: -90.02521,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.7872,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.019732,
    shape_pt_lon: -90.02521,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.8172,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.019915,
    shape_pt_lon: -90.02521,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.8372,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020356,
    shape_pt_lon: -90.025208,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.8872,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020497,
    shape_pt_lon: -90.025208,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.9022,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020598,
    shape_pt_lon: -90.025139,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.9161,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020763,
    shape_pt_lon: -90.025028,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.9367,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020871,
    shape_pt_lon: -90.024902,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.953,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020878,
    shape_pt_lon: -90.024331,
    shape_pt_sequence: 51,
    shape_dist_traveled: 4.005,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020879,
    shape_pt_lon: -90.023421,
    shape_pt_sequence: 52,
    shape_dist_traveled: 4.087,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020879,
    shape_pt_lon: -90.023311,
    shape_pt_sequence: 53,
    shape_dist_traveled: 4.097,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020876,
    shape_pt_lon: -90.021951,
    shape_pt_sequence: 54,
    shape_dist_traveled: 4.22,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020876,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 55,
    shape_dist_traveled: 4.228,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020873,
    shape_pt_lon: -90.02067,
    shape_pt_sequence: 56,
    shape_dist_traveled: 4.336,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020872,
    shape_pt_lon: -90.019925,
    shape_pt_sequence: 57,
    shape_dist_traveled: 4.403,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.020936,
    shape_pt_lon: -90.019923,
    shape_pt_sequence: 58,
    shape_dist_traveled: 4.4101,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.021021,
    shape_pt_lon: -90.019878,
    shape_pt_sequence: 59,
    shape_dist_traveled: 4.4199,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.021592,
    shape_pt_lon: -90.019878,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.4839,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.023252,
    shape_pt_lon: -90.019874,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.6699,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.025443,
    shape_pt_lon: -90.019865,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.9149,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.026135,
    shape_pt_lon: -90.019862,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.9919,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.026966,
    shape_pt_lon: -90.019863,
    shape_pt_sequence: 64,
    shape_dist_traveled: 5.0849,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.028228,
    shape_pt_lon: -90.019859,
    shape_pt_sequence: 65,
    shape_dist_traveled: 5.2269,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.028357,
    shape_pt_lon: -90.019859,
    shape_pt_sequence: 66,
    shape_dist_traveled: 5.2409,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.028947,
    shape_pt_lon: -90.019856,
    shape_pt_sequence: 67,
    shape_dist_traveled: 5.3069,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.02978,
    shape_pt_lon: -90.019857,
    shape_pt_sequence: 68,
    shape_dist_traveled: 5.3999,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.029892,
    shape_pt_lon: -90.019857,
    shape_pt_sequence: 69,
    shape_dist_traveled: 5.4129,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.030308,
    shape_pt_lon: -90.019842,
    shape_pt_sequence: 70,
    shape_dist_traveled: 5.4589,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.030694,
    shape_pt_lon: -90.01981,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.502,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.031293,
    shape_pt_lon: -90.019791,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.5691,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.031828,
    shape_pt_lon: -90.019791,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.6291,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.03237,
    shape_pt_lon: -90.019787,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.6901,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.033546,
    shape_pt_lon: -90.019782,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.8221,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.033871,
    shape_pt_lon: -90.019784,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.8581,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.034219,
    shape_pt_lon: -90.019783,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.8971,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.034453,
    shape_pt_lon: -90.019783,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.9231,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035528,
    shape_pt_lon: -90.019797,
    shape_pt_sequence: 79,
    shape_dist_traveled: 6.0431,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035522,
    shape_pt_lon: -90.021135,
    shape_pt_sequence: 80,
    shape_dist_traveled: 6.1641,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035517,
    shape_pt_lon: -90.022219,
    shape_pt_sequence: 81,
    shape_dist_traveled: 6.2621,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035515,
    shape_pt_lon: -90.023245,
    shape_pt_sequence: 82,
    shape_dist_traveled: 6.3551,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035512,
    shape_pt_lon: -90.023979,
    shape_pt_sequence: 83,
    shape_dist_traveled: 6.4211,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.035503,
    shape_pt_lon: -90.025149,
    shape_pt_sequence: 84,
    shape_dist_traveled: 6.5271,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.03557,
    shape_pt_lon: -90.025148,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.5351,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.039992,
    shape_pt_lon: -90.02513,
    shape_pt_sequence: 86,
    shape_dist_traveled: 7.0301,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.040631,
    shape_pt_lon: -90.02512,
    shape_pt_sequence: 87,
    shape_dist_traveled: 7.1011,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.041013,
    shape_pt_lon: -90.02512,
    shape_pt_sequence: 88,
    shape_dist_traveled: 7.1441,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.041884,
    shape_pt_lon: -90.025112,
    shape_pt_sequence: 89,
    shape_dist_traveled: 7.2411,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.04251,
    shape_pt_lon: -90.025111,
    shape_pt_sequence: 90,
    shape_dist_traveled: 7.3111,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.043934,
    shape_pt_lon: -90.025108,
    shape_pt_sequence: 91,
    shape_dist_traveled: 7.4711,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.044216,
    shape_pt_lon: -90.025109,
    shape_pt_sequence: 92,
    shape_dist_traveled: 7.5021,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.044586,
    shape_pt_lon: -90.025109,
    shape_pt_sequence: 93,
    shape_dist_traveled: 7.5441,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.045019,
    shape_pt_lon: -90.025108,
    shape_pt_sequence: 94,
    shape_dist_traveled: 7.5921,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.046416,
    shape_pt_lon: -90.025106,
    shape_pt_sequence: 95,
    shape_dist_traveled: 7.7481,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.047836,
    shape_pt_lon: -90.0251,
    shape_pt_sequence: 96,
    shape_dist_traveled: 7.9071,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.049101,
    shape_pt_lon: -90.025083,
    shape_pt_sequence: 97,
    shape_dist_traveled: 8.0491,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.049707,
    shape_pt_lon: -90.025077,
    shape_pt_sequence: 98,
    shape_dist_traveled: 8.1171,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.051286,
    shape_pt_lon: -90.02507,
    shape_pt_sequence: 99,
    shape_dist_traveled: 8.2931,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.051532,
    shape_pt_lon: -90.025071,
    shape_pt_sequence: 100,
    shape_dist_traveled: 8.3211,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.051575,
    shape_pt_lon: -90.025072,
    shape_pt_sequence: 101,
    shape_dist_traveled: 8.3261,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.053422,
    shape_pt_lon: -90.025036,
    shape_pt_sequence: 102,
    shape_dist_traveled: 8.5321,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.054256,
    shape_pt_lon: -90.025002,
    shape_pt_sequence: 103,
    shape_dist_traveled: 8.6262,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.054999,
    shape_pt_lon: -90.024925,
    shape_pt_sequence: 104,
    shape_dist_traveled: 8.7095,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.056011,
    shape_pt_lon: -90.024798,
    shape_pt_sequence: 105,
    shape_dist_traveled: 8.8231,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.056318,
    shape_pt_lon: -90.024747,
    shape_pt_sequence: 106,
    shape_dist_traveled: 8.8574,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.05654,
    shape_pt_lon: -90.024714,
    shape_pt_sequence: 107,
    shape_dist_traveled: 8.8825,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.057628,
    shape_pt_lon: -90.02451,
    shape_pt_sequence: 108,
    shape_dist_traveled: 9.006,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.058089,
    shape_pt_lon: -90.024402,
    shape_pt_sequence: 109,
    shape_dist_traveled: 9.0578,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.059688,
    shape_pt_lon: -90.024017,
    shape_pt_sequence: 110,
    shape_dist_traveled: 9.2402,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.060416,
    shape_pt_lon: -90.023848,
    shape_pt_sequence: 111,
    shape_dist_traveled: 9.3235,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.060483,
    shape_pt_lon: -90.023832,
    shape_pt_sequence: 112,
    shape_dist_traveled: 9.3308,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.060552,
    shape_pt_lon: -90.023816,
    shape_pt_sequence: 113,
    shape_dist_traveled: 9.3389,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.061209,
    shape_pt_lon: -90.023668,
    shape_pt_sequence: 114,
    shape_dist_traveled: 9.414,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.061697,
    shape_pt_lon: -90.02355,
    shape_pt_sequence: 115,
    shape_dist_traveled: 9.4691,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.062212,
    shape_pt_lon: -90.023421,
    shape_pt_sequence: 116,
    shape_dist_traveled: 9.5284,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.062481,
    shape_pt_lon: -90.023362,
    shape_pt_sequence: 117,
    shape_dist_traveled: 9.5588,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.063247,
    shape_pt_lon: -90.023169,
    shape_pt_sequence: 118,
    shape_dist_traveled: 9.6464,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.063496,
    shape_pt_lon: -90.023105,
    shape_pt_sequence: 119,
    shape_dist_traveled: 9.6741,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.063579,
    shape_pt_lon: -90.023081,
    shape_pt_sequence: 120,
    shape_dist_traveled: 9.6843,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.063678,
    shape_pt_lon: -90.023058,
    shape_pt_sequence: 121,
    shape_dist_traveled: 9.6957,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.063729,
    shape_pt_lon: -90.023047,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.7018,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.064563,
    shape_pt_lon: -90.022848,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.7963,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.065029,
    shape_pt_lon: -90.022717,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.8497,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.06559,
    shape_pt_lon: -90.022576,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.914,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.068132,
    shape_pt_lon: -90.021949,
    shape_pt_sequence: 126,
    shape_dist_traveled: 10.2037,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.070713,
    shape_pt_lon: -90.021318,
    shape_pt_sequence: 127,
    shape_dist_traveled: 10.4981,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.071206,
    shape_pt_lon: -90.0212,
    shape_pt_sequence: 128,
    shape_dist_traveled: 10.5541,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.071326,
    shape_pt_lon: -90.021171,
    shape_pt_sequence: 129,
    shape_dist_traveled: 10.5685,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.071441,
    shape_pt_lon: -90.021143,
    shape_pt_sequence: 130,
    shape_dist_traveled: 10.5806,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.071576,
    shape_pt_lon: -90.02111,
    shape_pt_sequence: 131,
    shape_dist_traveled: 10.5969,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.072667,
    shape_pt_lon: -90.020841,
    shape_pt_sequence: 132,
    shape_dist_traveled: 10.7214,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.072887,
    shape_pt_lon: -90.020782,
    shape_pt_sequence: 133,
    shape_dist_traveled: 10.746,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.074337,
    shape_pt_lon: -90.020422,
    shape_pt_sequence: 134,
    shape_dist_traveled: 10.9121,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.074469,
    shape_pt_lon: -90.02039,
    shape_pt_sequence: 135,
    shape_dist_traveled: 10.9264,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.07676,
    shape_pt_lon: -90.019829,
    shape_pt_sequence: 136,
    shape_dist_traveled: 11.1884,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.078085,
    shape_pt_lon: -90.019498,
    shape_pt_sequence: 137,
    shape_dist_traveled: 11.3394,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.078139,
    shape_pt_lon: -90.019483,
    shape_pt_sequence: 138,
    shape_dist_traveled: 11.3455,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.079849,
    shape_pt_lon: -90.019083,
    shape_pt_sequence: 139,
    shape_dist_traveled: 11.5399,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.080494,
    shape_pt_lon: -90.018947,
    shape_pt_sequence: 140,
    shape_dist_traveled: 11.6138,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.081148,
    shape_pt_lon: -90.018829,
    shape_pt_sequence: 141,
    shape_dist_traveled: 11.6877,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.082303,
    shape_pt_lon: -90.018657,
    shape_pt_sequence: 142,
    shape_dist_traveled: 11.8175,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.083304,
    shape_pt_lon: -90.018566,
    shape_pt_sequence: 143,
    shape_dist_traveled: 11.9299,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.083643,
    shape_pt_lon: -90.018532,
    shape_pt_sequence: 144,
    shape_dist_traveled: 11.968,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.084388,
    shape_pt_lon: -90.018487,
    shape_pt_sequence: 145,
    shape_dist_traveled: 12.0511,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.08549,
    shape_pt_lon: -90.018436,
    shape_pt_sequence: 146,
    shape_dist_traveled: 12.1752,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.086203,
    shape_pt_lon: -90.018414,
    shape_pt_sequence: 147,
    shape_dist_traveled: 12.2542,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.086333,
    shape_pt_lon: -90.01841,
    shape_pt_sequence: 148,
    shape_dist_traveled: 12.2692,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.086487,
    shape_pt_lon: -90.018402,
    shape_pt_sequence: 149,
    shape_dist_traveled: 12.2862,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.08686,
    shape_pt_lon: -90.018388,
    shape_pt_sequence: 150,
    shape_dist_traveled: 12.3282,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.087535,
    shape_pt_lon: -90.018358,
    shape_pt_sequence: 151,
    shape_dist_traveled: 12.4033,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.090686,
    shape_pt_lon: -90.01823,
    shape_pt_sequence: 152,
    shape_dist_traveled: 12.7565,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.090999,
    shape_pt_lon: -90.018219,
    shape_pt_sequence: 153,
    shape_dist_traveled: 12.7915,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.091315,
    shape_pt_lon: -90.018206,
    shape_pt_sequence: 154,
    shape_dist_traveled: 12.8265,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.092803,
    shape_pt_lon: -90.018144,
    shape_pt_sequence: 155,
    shape_dist_traveled: 12.9936,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09375,
    shape_pt_lon: -90.018128,
    shape_pt_sequence: 156,
    shape_dist_traveled: 13.0996,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.094176,
    shape_pt_lon: -90.018109,
    shape_pt_sequence: 157,
    shape_dist_traveled: 13.1466,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09483,
    shape_pt_lon: -90.018086,
    shape_pt_sequence: 158,
    shape_dist_traveled: 13.2207,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.095307,
    shape_pt_lon: -90.01806,
    shape_pt_sequence: 159,
    shape_dist_traveled: 13.2737,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09549,
    shape_pt_lon: -90.018052,
    shape_pt_sequence: 160,
    shape_dist_traveled: 13.2947,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.096155,
    shape_pt_lon: -90.018032,
    shape_pt_sequence: 161,
    shape_dist_traveled: 13.3688,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09681,
    shape_pt_lon: -90.018002,
    shape_pt_sequence: 162,
    shape_dist_traveled: 13.4418,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09727,
    shape_pt_lon: -90.017998,
    shape_pt_sequence: 163,
    shape_dist_traveled: 13.4938,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09749,
    shape_pt_lon: -90.017999,
    shape_pt_sequence: 164,
    shape_dist_traveled: 13.5178,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.098029,
    shape_pt_lon: -90.01802,
    shape_pt_sequence: 165,
    shape_dist_traveled: 13.5789,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.098341,
    shape_pt_lon: -90.018052,
    shape_pt_sequence: 166,
    shape_dist_traveled: 13.614,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.098644,
    shape_pt_lon: -90.018106,
    shape_pt_sequence: 167,
    shape_dist_traveled: 13.6474,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.09898,
    shape_pt_lon: -90.018181,
    shape_pt_sequence: 168,
    shape_dist_traveled: 13.686,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.099587,
    shape_pt_lon: -90.018368,
    shape_pt_sequence: 169,
    shape_dist_traveled: 13.7559,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.100182,
    shape_pt_lon: -90.018618,
    shape_pt_sequence: 170,
    shape_dist_traveled: 13.8267,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.100735,
    shape_pt_lon: -90.018858,
    shape_pt_sequence: 171,
    shape_dist_traveled: 13.8916,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.10153,
    shape_pt_lon: -90.019249,
    shape_pt_sequence: 172,
    shape_dist_traveled: 13.9872,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.101882,
    shape_pt_lon: -90.019427,
    shape_pt_sequence: 173,
    shape_dist_traveled: 14.0303,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.10341,
    shape_pt_lon: -90.020148,
    shape_pt_sequence: 174,
    shape_dist_traveled: 14.2136,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.103955,
    shape_pt_lon: -90.020376,
    shape_pt_sequence: 175,
    shape_dist_traveled: 14.2778,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.104534,
    shape_pt_lon: -90.020591,
    shape_pt_sequence: 176,
    shape_dist_traveled: 14.3448,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.105208,
    shape_pt_lon: -90.0208,
    shape_pt_sequence: 177,
    shape_dist_traveled: 14.4231,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.106142,
    shape_pt_lon: -90.021022,
    shape_pt_sequence: 178,
    shape_dist_traveled: 14.5291,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.106891,
    shape_pt_lon: -90.021179,
    shape_pt_sequence: 179,
    shape_dist_traveled: 14.6142,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.107743,
    shape_pt_lon: -90.021361,
    shape_pt_sequence: 180,
    shape_dist_traveled: 14.7107,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.108543,
    shape_pt_lon: -90.021528,
    shape_pt_sequence: 181,
    shape_dist_traveled: 14.802,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.109147,
    shape_pt_lon: -90.021652,
    shape_pt_sequence: 182,
    shape_dist_traveled: 14.8699,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.109479,
    shape_pt_lon: -90.02172,
    shape_pt_sequence: 183,
    shape_dist_traveled: 14.9083,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.109589,
    shape_pt_lon: -90.021741,
    shape_pt_sequence: 184,
    shape_dist_traveled: 14.9205,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.109655,
    shape_pt_lon: -90.021754,
    shape_pt_sequence: 185,
    shape_dist_traveled: 14.9276,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.10987,
    shape_pt_lon: -90.021796,
    shape_pt_sequence: 186,
    shape_dist_traveled: 14.9519,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.111539,
    shape_pt_lon: -90.021727,
    shape_pt_sequence: 187,
    shape_dist_traveled: 15.139,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.11247,
    shape_pt_lon: -90.021688,
    shape_pt_sequence: 188,
    shape_dist_traveled: 15.2431,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.112628,
    shape_pt_lon: -90.021685,
    shape_pt_sequence: 189,
    shape_dist_traveled: 15.2611,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.113101,
    shape_pt_lon: -90.021698,
    shape_pt_sequence: 190,
    shape_dist_traveled: 15.3141,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.11382,
    shape_pt_lon: -90.021719,
    shape_pt_sequence: 191,
    shape_dist_traveled: 15.3941,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.115312,
    shape_pt_lon: -90.021665,
    shape_pt_sequence: 192,
    shape_dist_traveled: 15.5612,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.115578,
    shape_pt_lon: -90.02165,
    shape_pt_sequence: 193,
    shape_dist_traveled: 15.5912,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.117086,
    shape_pt_lon: -90.021551,
    shape_pt_sequence: 194,
    shape_dist_traveled: 15.7604,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.118025,
    shape_pt_lon: -90.021507,
    shape_pt_sequence: 195,
    shape_dist_traveled: 15.8655,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.118391,
    shape_pt_lon: -90.021489,
    shape_pt_sequence: 196,
    shape_dist_traveled: 15.9065,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.118996,
    shape_pt_lon: -90.021466,
    shape_pt_sequence: 197,
    shape_dist_traveled: 15.9746,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.120011,
    shape_pt_lon: -90.021426,
    shape_pt_sequence: 198,
    shape_dist_traveled: 16.0876,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.121548,
    shape_pt_lon: -90.021366,
    shape_pt_sequence: 199,
    shape_dist_traveled: 16.2597,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.121789,
    shape_pt_lon: -90.021356,
    shape_pt_sequence: 200,
    shape_dist_traveled: 16.2867,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.122289,
    shape_pt_lon: -90.021337,
    shape_pt_sequence: 201,
    shape_dist_traveled: 16.3428,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.122524,
    shape_pt_lon: -90.021327,
    shape_pt_sequence: 202,
    shape_dist_traveled: 16.3688,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.122748,
    shape_pt_lon: -90.021317,
    shape_pt_sequence: 203,
    shape_dist_traveled: 16.3938,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.122769,
    shape_pt_lon: -90.021316,
    shape_pt_sequence: 204,
    shape_dist_traveled: 16.3968,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.122942,
    shape_pt_lon: -90.021309,
    shape_pt_sequence: 205,
    shape_dist_traveled: 16.4158,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.123991,
    shape_pt_lon: -90.021266,
    shape_pt_sequence: 206,
    shape_dist_traveled: 16.5329,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.124627,
    shape_pt_lon: -90.021241,
    shape_pt_sequence: 207,
    shape_dist_traveled: 16.6049,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.125636,
    shape_pt_lon: -90.021203,
    shape_pt_sequence: 208,
    shape_dist_traveled: 16.718,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.126632,
    shape_pt_lon: -90.02116,
    shape_pt_sequence: 209,
    shape_dist_traveled: 16.829,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.127654,
    shape_pt_lon: -90.021121,
    shape_pt_sequence: 210,
    shape_dist_traveled: 16.9431,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 211,
    shape_dist_traveled: 17.0601,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.129803,
    shape_pt_lon: -90.021,
    shape_pt_sequence: 212,
    shape_dist_traveled: 17.1843,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.130807,
    shape_pt_lon: -90.020916,
    shape_pt_sequence: 213,
    shape_dist_traveled: 17.2966,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.131609,
    shape_pt_lon: -90.020856,
    shape_pt_sequence: 214,
    shape_dist_traveled: 17.3868,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.020846,
    shape_pt_sequence: 215,
    shape_dist_traveled: 17.4018,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132279,
    shape_pt_lon: -90.020818,
    shape_pt_sequence: 216,
    shape_dist_traveled: 17.4618,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132737,
    shape_pt_lon: -90.020829,
    shape_pt_sequence: 217,
    shape_dist_traveled: 17.5128,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132732,
    shape_pt_lon: -90.020757,
    shape_pt_sequence: 218,
    shape_dist_traveled: 17.5198,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132712,
    shape_pt_lon: -90.020268,
    shape_pt_sequence: 219,
    shape_dist_traveled: 17.5639,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132615,
    shape_pt_lon: -90.017536,
    shape_pt_sequence: 220,
    shape_dist_traveled: 17.8111,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.132599,
    shape_pt_lon: -90.016857,
    shape_pt_sequence: 221,
    shape_dist_traveled: 17.8722,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.133162,
    shape_pt_lon: -90.016845,
    shape_pt_sequence: 222,
    shape_dist_traveled: 17.9352,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.133528,
    shape_pt_lon: -90.016833,
    shape_pt_sequence: 223,
    shape_dist_traveled: 17.9762,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.134511,
    shape_pt_lon: -90.016772,
    shape_pt_sequence: 224,
    shape_dist_traveled: 18.0864,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.135617,
    shape_pt_lon: -90.016726,
    shape_pt_sequence: 225,
    shape_dist_traveled: 18.2104,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.136839,
    shape_pt_lon: -90.016669,
    shape_pt_sequence: 226,
    shape_dist_traveled: 18.3465,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.137333,
    shape_pt_lon: -90.016604,
    shape_pt_sequence: 227,
    shape_dist_traveled: 18.4028,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.137854,
    shape_pt_lon: -90.01653,
    shape_pt_sequence: 228,
    shape_dist_traveled: 18.4611,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.13837,
    shape_pt_lon: -90.016457,
    shape_pt_sequence: 229,
    shape_dist_traveled: 18.5196,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.138913,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 230,
    shape_dist_traveled: 18.58,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.139586,
    shape_pt_lon: -90.016287,
    shape_pt_sequence: 231,
    shape_dist_traveled: 18.6564,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.14025,
    shape_pt_lon: -90.016192,
    shape_pt_sequence: 232,
    shape_dist_traveled: 18.7309,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.140917,
    shape_pt_lon: -90.016094,
    shape_pt_sequence: 233,
    shape_dist_traveled: 18.8064,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.14157,
    shape_pt_lon: -90.015999,
    shape_pt_sequence: 234,
    shape_dist_traveled: 18.8799,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.142189,
    shape_pt_lon: -90.015913,
    shape_pt_sequence: 235,
    shape_dist_traveled: 18.9484,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.142749,
    shape_pt_lon: -90.015838,
    shape_pt_sequence: 236,
    shape_dist_traveled: 19.0117,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.143648,
    shape_pt_lon: -90.015706,
    shape_pt_sequence: 237,
    shape_dist_traveled: 19.1124,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.143956,
    shape_pt_lon: -90.015646,
    shape_pt_sequence: 238,
    shape_dist_traveled: 19.1479,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.144581,
    shape_pt_lon: -90.01554,
    shape_pt_sequence: 239,
    shape_dist_traveled: 19.2185,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.144987,
    shape_pt_lon: -90.015481,
    shape_pt_sequence: 240,
    shape_dist_traveled: 19.2639,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.145417,
    shape_pt_lon: -90.015419,
    shape_pt_sequence: 241,
    shape_dist_traveled: 19.3121,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.145597,
    shape_pt_lon: -90.015362,
    shape_pt_sequence: 242,
    shape_dist_traveled: 19.3327,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.145716,
    shape_pt_lon: -90.015301,
    shape_pt_sequence: 243,
    shape_dist_traveled: 19.348,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.145873,
    shape_pt_lon: -90.015187,
    shape_pt_sequence: 244,
    shape_dist_traveled: 19.3677,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.145953,
    shape_pt_lon: -90.015108,
    shape_pt_sequence: 245,
    shape_dist_traveled: 19.3791,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.146046,
    shape_pt_lon: -90.015016,
    shape_pt_sequence: 246,
    shape_dist_traveled: 19.3933,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.1462,
    shape_pt_lon: -90.014904,
    shape_pt_sequence: 247,
    shape_dist_traveled: 19.413,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.146299,
    shape_pt_lon: -90.014852,
    shape_pt_sequence: 248,
    shape_dist_traveled: 19.4247,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.146408,
    shape_pt_lon: -90.01481,
    shape_pt_sequence: 249,
    shape_dist_traveled: 19.4374,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.146667,
    shape_pt_lon: -90.014761,
    shape_pt_sequence: 250,
    shape_dist_traveled: 19.4668,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.147921,
    shape_pt_lon: -90.014581,
    shape_pt_sequence: 251,
    shape_dist_traveled: 19.6087,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.149378,
    shape_pt_lon: -90.014367,
    shape_pt_sequence: 252,
    shape_dist_traveled: 19.7728,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150237,
    shape_pt_lon: -90.014233,
    shape_pt_sequence: 253,
    shape_dist_traveled: 19.8696,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150399,
    shape_pt_lon: -90.014178,
    shape_pt_sequence: 254,
    shape_dist_traveled: 19.8883,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150545,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 255,
    shape_dist_traveled: 19.9057,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150698,
    shape_pt_lon: -90.013979,
    shape_pt_sequence: 256,
    shape_dist_traveled: 19.926,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150838,
    shape_pt_lon: -90.013825,
    shape_pt_sequence: 257,
    shape_dist_traveled: 19.9472,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.150891,
    shape_pt_lon: -90.013742,
    shape_pt_sequence: 258,
    shape_dist_traveled: 19.9564,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151115,
    shape_pt_lon: -90.013341,
    shape_pt_sequence: 259,
    shape_dist_traveled: 20.0011,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151238,
    shape_pt_lon: -90.013175,
    shape_pt_sequence: 260,
    shape_dist_traveled: 20.0216,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151374,
    shape_pt_lon: -90.013042,
    shape_pt_sequence: 261,
    shape_dist_traveled: 20.0408,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151501,
    shape_pt_lon: -90.012952,
    shape_pt_sequence: 262,
    shape_dist_traveled: 20.057,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151617,
    shape_pt_lon: -90.012892,
    shape_pt_sequence: 263,
    shape_dist_traveled: 20.0709,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151783,
    shape_pt_lon: -90.012837,
    shape_pt_sequence: 264,
    shape_dist_traveled: 20.0905,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.151908,
    shape_pt_lon: -90.012814,
    shape_pt_sequence: 265,
    shape_dist_traveled: 20.1047,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.15278,
    shape_pt_lon: -90.012669,
    shape_pt_sequence: 266,
    shape_dist_traveled: 20.2025,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.152977,
    shape_pt_lon: -90.012638,
    shape_pt_sequence: 267,
    shape_dist_traveled: 20.2247,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153087,
    shape_pt_lon: -90.01262,
    shape_pt_sequence: 268,
    shape_dist_traveled: 20.2379,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.15326,
    shape_pt_lon: -90.012592,
    shape_pt_sequence: 269,
    shape_dist_traveled: 20.257,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153433,
    shape_pt_lon: -90.012566,
    shape_pt_sequence: 270,
    shape_dist_traveled: 20.2761,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153471,
    shape_pt_lon: -90.012558,
    shape_pt_sequence: 271,
    shape_dist_traveled: 20.2812,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153521,
    shape_pt_lon: -90.012548,
    shape_pt_sequence: 272,
    shape_dist_traveled: 20.2863,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153553,
    shape_pt_lon: -90.01254,
    shape_pt_sequence: 273,
    shape_dist_traveled: 20.2904,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.1536,
    shape_pt_lon: -90.012531,
    shape_pt_sequence: 274,
    shape_dist_traveled: 20.2955,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153787,
    shape_pt_lon: -90.012493,
    shape_pt_sequence: 275,
    shape_dist_traveled: 20.3167,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.153933,
    shape_pt_lon: -90.012462,
    shape_pt_sequence: 276,
    shape_dist_traveled: 20.333,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.15495,
    shape_pt_lon: -90.01225,
    shape_pt_sequence: 277,
    shape_dist_traveled: 20.4486,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.012092,
    shape_pt_sequence: 278,
    shape_dist_traveled: 20.5674,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.15611,
    shape_pt_lon: -90.012078,
    shape_pt_sequence: 279,
    shape_dist_traveled: 20.5795,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.157128,
    shape_pt_lon: -90.011931,
    shape_pt_sequence: 280,
    shape_dist_traveled: 20.6943,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.157633,
    shape_pt_lon: -90.011859,
    shape_pt_sequence: 281,
    shape_dist_traveled: 20.7506,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158035,
    shape_pt_lon: -90.0118,
    shape_pt_sequence: 282,
    shape_dist_traveled: 20.7959,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158109,
    shape_pt_lon: -90.011776,
    shape_pt_sequence: 283,
    shape_dist_traveled: 20.8051,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158155,
    shape_pt_lon: -90.011747,
    shape_pt_sequence: 284,
    shape_dist_traveled: 20.811,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158208,
    shape_pt_lon: -90.011708,
    shape_pt_sequence: 285,
    shape_dist_traveled: 20.8182,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158557,
    shape_pt_lon: -90.011418,
    shape_pt_sequence: 286,
    shape_dist_traveled: 20.865,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158726,
    shape_pt_lon: -90.011302,
    shape_pt_sequence: 287,
    shape_dist_traveled: 20.8865,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.158881,
    shape_pt_lon: -90.011238,
    shape_pt_sequence: 288,
    shape_dist_traveled: 20.9045,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.15902,
    shape_pt_lon: -90.011197,
    shape_pt_sequence: 289,
    shape_dist_traveled: 20.921,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.160606,
    shape_pt_lon: -90.01096,
    shape_pt_sequence: 290,
    shape_dist_traveled: 21.0993,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.162486,
    shape_pt_lon: -90.010693,
    shape_pt_sequence: 291,
    shape_dist_traveled: 21.3116,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.164108,
    shape_pt_lon: -90.010454,
    shape_pt_sequence: 292,
    shape_dist_traveled: 21.4939,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.16445,
    shape_pt_lon: -90.010391,
    shape_pt_sequence: 293,
    shape_dist_traveled: 21.5323,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.164712,
    shape_pt_lon: -90.010358,
    shape_pt_sequence: 294,
    shape_dist_traveled: 21.5625,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.165162,
    shape_pt_lon: -90.010337,
    shape_pt_sequence: 295,
    shape_dist_traveled: 21.6125,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.165539,
    shape_pt_lon: -90.010343,
    shape_pt_sequence: 296,
    shape_dist_traveled: 21.6545,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.167341,
    shape_pt_lon: -90.010296,
    shape_pt_sequence: 297,
    shape_dist_traveled: 21.8566,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.16773,
    shape_pt_lon: -90.010272,
    shape_pt_sequence: 298,
    shape_dist_traveled: 21.8996,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.168034,
    shape_pt_lon: -90.010221,
    shape_pt_sequence: 299,
    shape_dist_traveled: 21.9339,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.168159,
    shape_pt_lon: -90.010195,
    shape_pt_sequence: 300,
    shape_dist_traveled: 21.9482,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.168373,
    shape_pt_lon: -90.010133,
    shape_pt_sequence: 301,
    shape_dist_traveled: 21.9727,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.168629,
    shape_pt_lon: -90.010038,
    shape_pt_sequence: 302,
    shape_dist_traveled: 22.0031,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.168936,
    shape_pt_lon: -90.009896,
    shape_pt_sequence: 303,
    shape_dist_traveled: 22.0395,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.170029,
    shape_pt_lon: -90.009337,
    shape_pt_sequence: 304,
    shape_dist_traveled: 22.1722,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.17018,
    shape_pt_lon: -90.009259,
    shape_pt_sequence: 305,
    shape_dist_traveled: 22.1906,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.170649,
    shape_pt_lon: -90.009023,
    shape_pt_sequence: 306,
    shape_dist_traveled: 22.2467,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.171111,
    shape_pt_lon: -90.00879,
    shape_pt_sequence: 307,
    shape_dist_traveled: 22.3028,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.171383,
    shape_pt_lon: -90.008676,
    shape_pt_sequence: 308,
    shape_dist_traveled: 22.3347,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.171766,
    shape_pt_lon: -90.008558,
    shape_pt_sequence: 309,
    shape_dist_traveled: 22.3789,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.172051,
    shape_pt_lon: -90.008501,
    shape_pt_sequence: 310,
    shape_dist_traveled: 22.4113,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.172662,
    shape_pt_lon: -90.008473,
    shape_pt_sequence: 311,
    shape_dist_traveled: 22.4793,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.172973,
    shape_pt_lon: -90.008458,
    shape_pt_sequence: 312,
    shape_dist_traveled: 22.5144,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.173048,
    shape_pt_lon: -90.008455,
    shape_pt_sequence: 313,
    shape_dist_traveled: 22.5234,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.173082,
    shape_pt_lon: -90.008452,
    shape_pt_sequence: 314,
    shape_dist_traveled: 22.5265,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.174337,
    shape_pt_lon: -90.008404,
    shape_pt_sequence: 315,
    shape_dist_traveled: 22.6676,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.175712,
    shape_pt_lon: -90.008378,
    shape_pt_sequence: 316,
    shape_dist_traveled: 22.8216,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.17676,
    shape_pt_lon: -90.008348,
    shape_pt_sequence: 317,
    shape_dist_traveled: 22.9386,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.178113,
    shape_pt_lon: -90.008308,
    shape_pt_sequence: 318,
    shape_dist_traveled: 23.0897,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.181918,
    shape_pt_lon: -90.00821,
    shape_pt_sequence: 319,
    shape_dist_traveled: 23.5157,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.182365,
    shape_pt_lon: -90.008203,
    shape_pt_sequence: 320,
    shape_dist_traveled: 23.5657,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.186212,
    shape_pt_lon: -90.008131,
    shape_pt_sequence: 321,
    shape_dist_traveled: 23.9968,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.18727,
    shape_pt_lon: -90.008103,
    shape_pt_sequence: 322,
    shape_dist_traveled: 24.1148,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.187644,
    shape_pt_lon: -90.008093,
    shape_pt_sequence: 323,
    shape_dist_traveled: 24.1568,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.188032,
    shape_pt_lon: -90.008078,
    shape_pt_sequence: 324,
    shape_dist_traveled: 24.1998,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.188559,
    shape_pt_lon: -90.008071,
    shape_pt_sequence: 325,
    shape_dist_traveled: 24.2589,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.19011,
    shape_pt_lon: -90.008017,
    shape_pt_sequence: 326,
    shape_dist_traveled: 24.4329,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.190996,
    shape_pt_lon: -90.007989,
    shape_pt_sequence: 327,
    shape_dist_traveled: 24.5319,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.191328,
    shape_pt_lon: -90.007993,
    shape_pt_sequence: 328,
    shape_dist_traveled: 24.5689,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.191647,
    shape_pt_lon: -90.007992,
    shape_pt_sequence: 329,
    shape_dist_traveled: 24.6049,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.191997,
    shape_pt_lon: -90.007983,
    shape_pt_sequence: 330,
    shape_dist_traveled: 24.644,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.192168,
    shape_pt_lon: -90.00798,
    shape_pt_sequence: 331,
    shape_dist_traveled: 24.663,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.19246,
    shape_pt_lon: -90.007963,
    shape_pt_sequence: 332,
    shape_dist_traveled: 24.696,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.192774,
    shape_pt_lon: -90.007946,
    shape_pt_sequence: 333,
    shape_dist_traveled: 24.731,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.192889,
    shape_pt_lon: -90.007942,
    shape_pt_sequence: 334,
    shape_dist_traveled: 24.744,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.19336,
    shape_pt_lon: -90.007938,
    shape_pt_sequence: 335,
    shape_dist_traveled: 24.797,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.194004,
    shape_pt_lon: -90.00793,
    shape_pt_sequence: 336,
    shape_dist_traveled: 24.869,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.194176,
    shape_pt_lon: -90.007928,
    shape_pt_sequence: 337,
    shape_dist_traveled: 24.888,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.195356,
    shape_pt_lon: -90.007899,
    shape_pt_sequence: 338,
    shape_dist_traveled: 25.0201,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.196056,
    shape_pt_lon: -90.007857,
    shape_pt_sequence: 339,
    shape_dist_traveled: 25.0982,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.196498,
    shape_pt_lon: -90.007847,
    shape_pt_sequence: 340,
    shape_dist_traveled: 25.1482,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.197393,
    shape_pt_lon: -90.007828,
    shape_pt_sequence: 341,
    shape_dist_traveled: 25.2482,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.197538,
    shape_pt_lon: -90.007827,
    shape_pt_sequence: 342,
    shape_dist_traveled: 25.2642,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.198055,
    shape_pt_lon: -90.007812,
    shape_pt_sequence: 343,
    shape_dist_traveled: 25.3222,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.199328,
    shape_pt_lon: -90.007786,
    shape_pt_sequence: 344,
    shape_dist_traveled: 25.4642,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.199369,
    shape_pt_lon: -90.007784,
    shape_pt_sequence: 345,
    shape_dist_traveled: 25.4692,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.199442,
    shape_pt_lon: -90.007783,
    shape_pt_sequence: 346,
    shape_dist_traveled: 25.4772,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.200607,
    shape_pt_lon: -90.007759,
    shape_pt_sequence: 347,
    shape_dist_traveled: 25.6083,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.201422,
    shape_pt_lon: -90.007732,
    shape_pt_sequence: 348,
    shape_dist_traveled: 25.6993,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.201459,
    shape_pt_lon: -90.007729,
    shape_pt_sequence: 349,
    shape_dist_traveled: 25.7033,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.201649,
    shape_pt_lon: -90.007688,
    shape_pt_sequence: 350,
    shape_dist_traveled: 25.7247,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.201828,
    shape_pt_lon: -90.00762,
    shape_pt_sequence: 351,
    shape_dist_traveled: 25.7456,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.201996,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 352,
    shape_dist_traveled: 25.7662,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.202228,
    shape_pt_lon: -90.007368,
    shape_pt_sequence: 353,
    shape_dist_traveled: 25.7957,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20229,
    shape_pt_lon: -90.007307,
    shape_pt_sequence: 354,
    shape_dist_traveled: 25.8049,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.202445,
    shape_pt_lon: -90.007142,
    shape_pt_sequence: 355,
    shape_dist_traveled: 25.8276,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.202583,
    shape_pt_lon: -90.006958,
    shape_pt_sequence: 356,
    shape_dist_traveled: 25.8502,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.202697,
    shape_pt_lon: -90.006758,
    shape_pt_sequence: 357,
    shape_dist_traveled: 25.8719,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.203265,
    shape_pt_lon: -90.005514,
    shape_pt_sequence: 358,
    shape_dist_traveled: 26.0017,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.203457,
    shape_pt_lon: -90.00519,
    shape_pt_sequence: 359,
    shape_dist_traveled: 26.0381,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.203628,
    shape_pt_lon: -90.004954,
    shape_pt_sequence: 360,
    shape_dist_traveled: 26.0664,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.203785,
    shape_pt_lon: -90.004768,
    shape_pt_sequence: 361,
    shape_dist_traveled: 26.0905,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.203945,
    shape_pt_lon: -90.004612,
    shape_pt_sequence: 362,
    shape_dist_traveled: 26.1133,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.2041,
    shape_pt_lon: -90.004483,
    shape_pt_sequence: 363,
    shape_dist_traveled: 26.1349,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.204344,
    shape_pt_lon: -90.004315,
    shape_pt_sequence: 364,
    shape_dist_traveled: 26.1658,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.204593,
    shape_pt_lon: -90.004178,
    shape_pt_sequence: 365,
    shape_dist_traveled: 26.1963,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20466,
    shape_pt_lon: -90.00415,
    shape_pt_sequence: 366,
    shape_dist_traveled: 26.2039,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.204929,
    shape_pt_lon: -90.004052,
    shape_pt_sequence: 367,
    shape_dist_traveled: 26.2352,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.205123,
    shape_pt_lon: -90.004005,
    shape_pt_sequence: 368,
    shape_dist_traveled: 26.2576,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.205412,
    shape_pt_lon: -90.003961,
    shape_pt_sequence: 369,
    shape_dist_traveled: 26.2898,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.205717,
    shape_pt_lon: -90.003947,
    shape_pt_sequence: 370,
    shape_dist_traveled: 26.3248,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206858,
    shape_pt_lon: -90.003907,
    shape_pt_sequence: 371,
    shape_dist_traveled: 26.4519,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20781,
    shape_pt_lon: -90.003833,
    shape_pt_sequence: 372,
    shape_dist_traveled: 26.5591,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207891,
    shape_pt_lon: -90.003769,
    shape_pt_sequence: 373,
    shape_dist_traveled: 26.5699,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20802,
    shape_pt_lon: -90.003663,
    shape_pt_sequence: 374,
    shape_dist_traveled: 26.5865,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20807,
    shape_pt_lon: -90.003595,
    shape_pt_sequence: 375,
    shape_dist_traveled: 26.5957,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208108,
    shape_pt_lon: -90.003501,
    shape_pt_sequence: 376,
    shape_dist_traveled: 26.6047,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208123,
    shape_pt_lon: -90.00338,
    shape_pt_sequence: 377,
    shape_dist_traveled: 26.6159,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208109,
    shape_pt_lon: -90.003091,
    shape_pt_sequence: 378,
    shape_dist_traveled: 26.6419,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208073,
    shape_pt_lon: -90.002873,
    shape_pt_sequence: 379,
    shape_dist_traveled: 26.6623,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208053,
    shape_pt_lon: -90.002756,
    shape_pt_sequence: 380,
    shape_dist_traveled: 26.6725,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208005,
    shape_pt_lon: -90.002369,
    shape_pt_sequence: 381,
    shape_dist_traveled: 26.7079,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207983,
    shape_pt_lon: -90.001972,
    shape_pt_sequence: 382,
    shape_dist_traveled: 26.744,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207922,
    shape_pt_lon: -89.999718,
    shape_pt_sequence: 383,
    shape_dist_traveled: 26.9481,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207849,
    shape_pt_lon: -89.99714,
    shape_pt_sequence: 384,
    shape_dist_traveled: 27.1802,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207803,
    shape_pt_lon: -89.995578,
    shape_pt_sequence: 385,
    shape_dist_traveled: 27.3213,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207773,
    shape_pt_lon: -89.994281,
    shape_pt_sequence: 386,
    shape_dist_traveled: 27.4384,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207736,
    shape_pt_lon: -89.993614,
    shape_pt_sequence: 387,
    shape_dist_traveled: 27.4995,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207714,
    shape_pt_lon: -89.993421,
    shape_pt_sequence: 388,
    shape_dist_traveled: 27.5167,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207638,
    shape_pt_lon: -89.99315,
    shape_pt_sequence: 389,
    shape_dist_traveled: 27.5432,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207535,
    shape_pt_lon: -89.992912,
    shape_pt_sequence: 390,
    shape_dist_traveled: 27.5669,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207441,
    shape_pt_lon: -89.992753,
    shape_pt_sequence: 391,
    shape_dist_traveled: 27.5847,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.207279,
    shape_pt_lon: -89.992543,
    shape_pt_sequence: 392,
    shape_dist_traveled: 27.6109,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20695,
    shape_pt_lon: -89.992167,
    shape_pt_sequence: 393,
    shape_dist_traveled: 27.6612,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206813,
    shape_pt_lon: -89.991989,
    shape_pt_sequence: 394,
    shape_dist_traveled: 27.6831,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206728,
    shape_pt_lon: -89.991843,
    shape_pt_sequence: 395,
    shape_dist_traveled: 27.6997,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206638,
    shape_pt_lon: -89.991667,
    shape_pt_sequence: 396,
    shape_dist_traveled: 27.7178,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20656,
    shape_pt_lon: -89.991454,
    shape_pt_sequence: 397,
    shape_dist_traveled: 27.7397,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206514,
    shape_pt_lon: -89.991276,
    shape_pt_sequence: 398,
    shape_dist_traveled: 27.7565,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206503,
    shape_pt_lon: -89.991205,
    shape_pt_sequence: 399,
    shape_dist_traveled: 27.7628,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206488,
    shape_pt_lon: -89.991069,
    shape_pt_sequence: 400,
    shape_dist_traveled: 27.7748,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.206465,
    shape_pt_lon: -89.990279,
    shape_pt_sequence: 401,
    shape_dist_traveled: 27.8469,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.20671,
    shape_pt_lon: -89.990278,
    shape_pt_sequence: 402,
    shape_dist_traveled: 27.8749,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208296,
    shape_pt_lon: -89.9902,
    shape_pt_sequence: 403,
    shape_dist_traveled: 28.052,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.208359,
    shape_pt_lon: -89.990197,
    shape_pt_sequence: 404,
    shape_dist_traveled: 28.059,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.209617,
    shape_pt_lon: -89.990141,
    shape_pt_sequence: 405,
    shape_dist_traveled: 28.2001,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.210092,
    shape_pt_lon: -89.990111,
    shape_pt_sequence: 406,
    shape_dist_traveled: 28.2532,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.211173,
    shape_pt_lon: -89.990057,
    shape_pt_sequence: 407,
    shape_dist_traveled: 28.3743,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.21317,
    shape_pt_lon: -89.989963,
    shape_pt_sequence: 408,
    shape_dist_traveled: 28.5984,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.216216,
    shape_pt_lon: -89.989825,
    shape_pt_sequence: 409,
    shape_dist_traveled: 28.9387,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.216958,
    shape_pt_lon: -89.989797,
    shape_pt_sequence: 410,
    shape_dist_traveled: 29.0227,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.217136,
    shape_pt_lon: -89.989798,
    shape_pt_sequence: 411,
    shape_dist_traveled: 29.0417,
  },
  {
    shape_id: 38244,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 412,
    shape_dist_traveled: 29.2658,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9701,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.149142,
    shape_pt_lon: -90.047058,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0906,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.148739,
    shape_pt_lon: -90.045543,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.2339,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.148462,
    shape_pt_lon: -90.044421,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.3405,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.148367,
    shape_pt_lon: -90.044008,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.3791,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.148265,
    shape_pt_lon: -90.043631,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.4148,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.148239,
    shape_pt_lon: -90.043539,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4243,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14822,
    shape_pt_lon: -90.043459,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4316,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.147963,
    shape_pt_lon: -90.042497,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5233,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.147786,
    shape_pt_lon: -90.041902,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.5799,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14769,
    shape_pt_lon: -90.041566,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6125,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.147557,
    shape_pt_lon: -90.040993,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6666,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.146969,
    shape_pt_lon: -90.038778,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8772,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.146469,
    shape_pt_lon: -90.036975,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.0496,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.146249,
    shape_pt_lon: -90.036106,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.1312,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.146068,
    shape_pt_lon: -90.03544,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.1948,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145899,
    shape_pt_lon: -90.03484,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.253,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145726,
    shape_pt_lon: -90.034214,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.3121,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145639,
    shape_pt_lon: -90.033818,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.3495,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.1456,
    shape_pt_lon: -90.033522,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.3768,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145559,
    shape_pt_lon: -90.033142,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.4111,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145529,
    shape_pt_lon: -90.032736,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4482,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145497,
    shape_pt_lon: -90.032431,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4755,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145421,
    shape_pt_lon: -90.031328,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.5759,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145401,
    shape_pt_lon: -90.031126,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.594,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145299,
    shape_pt_lon: -90.030039,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.6927,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145272,
    shape_pt_lon: -90.029697,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.7238,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145214,
    shape_pt_lon: -90.028922,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.7941,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145156,
    shape_pt_lon: -90.028304,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.8505,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.145012,
    shape_pt_lon: -90.02681,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.9865,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14498,
    shape_pt_lon: -90.026499,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.0146,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144881,
    shape_pt_lon: -90.025651,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.0924,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14482,
    shape_pt_lon: -90.025057,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.1459,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14481,
    shape_pt_lon: -90.024959,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.1549,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144767,
    shape_pt_lon: -90.024519,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.1953,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144681,
    shape_pt_lon: -90.023636,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.2758,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144619,
    shape_pt_lon: -90.02297,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.3362,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144601,
    shape_pt_lon: -90.022789,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.3523,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144583,
    shape_pt_lon: -90.022598,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.3704,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144572,
    shape_pt_lon: -90.022477,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3816,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144566,
    shape_pt_lon: -90.022392,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.3886,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14455,
    shape_pt_lon: -90.022231,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4037,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144528,
    shape_pt_lon: -90.021958,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4279,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144437,
    shape_pt_lon: -90.020973,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.5175,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144341,
    shape_pt_lon: -90.019942,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.612,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144299,
    shape_pt_lon: -90.019479,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.6533,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144286,
    shape_pt_lon: -90.019334,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.6673,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144125,
    shape_pt_lon: -90.017532,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8303,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144093,
    shape_pt_lon: -90.017184,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.8626,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144051,
    shape_pt_lon: -90.016679,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.9079,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144047,
    shape_pt_lon: -90.01662,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9139,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.144024,
    shape_pt_lon: -90.016366,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.937,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143956,
    shape_pt_lon: -90.015646,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0024,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143847,
    shape_pt_lon: -90.01455,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.1022,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143798,
    shape_pt_lon: -90.014,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.1515,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143761,
    shape_pt_lon: -90.013583,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.1897,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143707,
    shape_pt_lon: -90.012959,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.247,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143605,
    shape_pt_lon: -90.011832,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3486,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14356,
    shape_pt_lon: -90.011334,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3939,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143552,
    shape_pt_lon: -90.011262,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.401,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143493,
    shape_pt_lon: -90.01063,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.4584,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143489,
    shape_pt_lon: -90.010572,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.4634,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143485,
    shape_pt_lon: -90.010531,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4675,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143479,
    shape_pt_lon: -90.010474,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.4725,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143418,
    shape_pt_lon: -90.009802,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.5339,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143403,
    shape_pt_lon: -90.009648,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.5481,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143349,
    shape_pt_lon: -90.009037,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.6034,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143292,
    shape_pt_lon: -90.008413,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.6597,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14324,
    shape_pt_lon: -90.007817,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.7141,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143184,
    shape_pt_lon: -90.007149,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.7754,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143178,
    shape_pt_lon: -90.00707,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.7824,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14309,
    shape_pt_lon: -90.005944,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.8839,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143064,
    shape_pt_lon: -90.005599,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.916,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.143032,
    shape_pt_lon: -90.005209,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.9512,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14296,
    shape_pt_lon: -90.004299,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.0336,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142896,
    shape_pt_lon: -90.003461,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.1099,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142849,
    shape_pt_lon: -90.002882,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.1622,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142794,
    shape_pt_lon: -90.002224,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.2225,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142756,
    shape_pt_lon: -90.001732,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.2667,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142696,
    shape_pt_lon: -90.000959,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.337,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14265,
    shape_pt_lon: -90.000372,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.3902,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142643,
    shape_pt_lon: -90.000271,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3993,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142531,
    shape_pt_lon: -89.998943,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.52,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14244,
    shape_pt_lon: -89.997659,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.6364,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142432,
    shape_pt_lon: -89.997568,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.6445,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142303,
    shape_pt_lon: -89.996165,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.7723,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142206,
    shape_pt_lon: -89.99507,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.8719,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.142092,
    shape_pt_lon: -89.993831,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.9845,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141952,
    shape_pt_lon: -89.992313,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.1224,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141887,
    shape_pt_lon: -89.991588,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.1888,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141865,
    shape_pt_lon: -89.991342,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.211,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141729,
    shape_pt_lon: -89.989814,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.3498,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141699,
    shape_pt_lon: -89.989464,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.382,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141696,
    shape_pt_lon: -89.989431,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.385,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141601,
    shape_pt_lon: -89.988337,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.4835,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141524,
    shape_pt_lon: -89.98759,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.5521,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141461,
    shape_pt_lon: -89.986855,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.6184,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141452,
    shape_pt_lon: -89.986759,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.6275,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141375,
    shape_pt_lon: -89.986083,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.689,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141251,
    shape_pt_lon: -89.985111,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.7781,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141208,
    shape_pt_lon: -89.984852,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.8017,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.141177,
    shape_pt_lon: -89.984712,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.815,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.140911,
    shape_pt_lon: -89.983978,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.8875,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.140791,
    shape_pt_lon: -89.983681,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.9175,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.14054,
    shape_pt_lon: -89.983059,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.9801,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.140403,
    shape_pt_lon: -89.98271,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.0158,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.140212,
    shape_pt_lon: -89.98221,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.0655,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.139867,
    shape_pt_lon: -89.981243,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.1604,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13948,
    shape_pt_lon: -89.98016,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.2679,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.139224,
    shape_pt_lon: -89.979425,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.3405,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.139117,
    shape_pt_lon: -89.979122,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.37,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.139028,
    shape_pt_lon: -89.978909,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.3915,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.138705,
    shape_pt_lon: -89.978041,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.4783,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.138218,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.6186,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13802,
    shape_pt_lon: -89.97603,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.6759,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.137703,
    shape_pt_lon: -89.975132,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.7642,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.137615,
    shape_pt_lon: -89.974897,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.7874,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.137128,
    shape_pt_lon: -89.973693,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.9091,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.137032,
    shape_pt_lon: -89.97343,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.9355,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.136349,
    shape_pt_lon: -89.971509,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.1244,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.136238,
    shape_pt_lon: -89.971202,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.1553,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.1358,
    shape_pt_lon: -89.97,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.2748,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.135768,
    shape_pt_lon: -89.969908,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.2834,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.135546,
    shape_pt_lon: -89.969299,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.3438,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.135391,
    shape_pt_lon: -89.968866,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.3863,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.135351,
    shape_pt_lon: -89.968753,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.3984,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.135331,
    shape_pt_lon: -89.9687,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.4029,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.1352,
    shape_pt_lon: -89.968338,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.4387,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.134976,
    shape_pt_lon: -89.967715,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.5005,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.134901,
    shape_pt_lon: -89.967499,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.522,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.134449,
    shape_pt_lon: -89.966189,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.6502,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.134331,
    shape_pt_lon: -89.96587,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.6819,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.133896,
    shape_pt_lon: -89.964732,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.796,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.133737,
    shape_pt_lon: -89.964318,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.838,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.133373,
    shape_pt_lon: -89.963363,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.9329,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13293,
    shape_pt_lon: -89.962168,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.0519,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.132832,
    shape_pt_lon: -89.961906,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.0783,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.132124,
    shape_pt_lon: -89.960021,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2658,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.131872,
    shape_pt_lon: -89.959297,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.3365,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13179,
    shape_pt_lon: -89.959038,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.3622,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.131681,
    shape_pt_lon: -89.958693,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.3954,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.131607,
    shape_pt_lon: -89.958475,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.4173,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13133,
    shape_pt_lon: -89.957583,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.5028,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.955797,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.677,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.130669,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.7048,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.130242,
    shape_pt_lon: -89.954173,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.835,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.130206,
    shape_pt_lon: -89.954057,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.8458,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.129878,
    shape_pt_lon: -89.953019,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.9464,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.129507,
    shape_pt_lon: -89.951758,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.0679,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.129234,
    shape_pt_lon: -89.950736,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.1647,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.950421,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.1954,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.129134,
    shape_pt_lon: -89.950363,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.2005,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.129118,
    shape_pt_lon: -89.950301,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.2068,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.128926,
    shape_pt_lon: -89.949601,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.2735,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.128712,
    shape_pt_lon: -89.948817,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.3482,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.128229,
    shape_pt_lon: -89.947026,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.5189,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12798,
    shape_pt_lon: -89.946059,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.6103,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.127812,
    shape_pt_lon: -89.945321,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.6799,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.6892,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.8452,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.9033,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.9473,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.9863,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 185,
    shape_dist_traveled: 11.0264,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 186,
    shape_dist_traveled: 11.0654,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.1324,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.2034,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123129,
    shape_pt_lon: -89.943432,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.3816,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123109,
    shape_pt_lon: -89.942734,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.4446,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123098,
    shape_pt_lon: -89.942328,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.4817,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123046,
    shape_pt_lon: -89.940492,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.6477,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123011,
    shape_pt_lon: -89.939286,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.7568,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122951,
    shape_pt_lon: -89.937246,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.9409,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122925,
    shape_pt_lon: -89.936112,
    shape_pt_sequence: 195,
    shape_dist_traveled: 12.044,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122886,
    shape_pt_lon: -89.934812,
    shape_pt_sequence: 196,
    shape_dist_traveled: 12.1611,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122851,
    shape_pt_lon: -89.933669,
    shape_pt_sequence: 197,
    shape_dist_traveled: 12.2651,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12285,
    shape_pt_lon: -89.933601,
    shape_pt_sequence: 198,
    shape_dist_traveled: 12.2711,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -89.931146,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.4933,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122751,
    shape_pt_lon: -89.930122,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.5853,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122745,
    shape_pt_lon: -89.929746,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.6193,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122721,
    shape_pt_lon: -89.928587,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.7243,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122662,
    shape_pt_lon: -89.926531,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.9105,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12266,
    shape_pt_lon: -89.926458,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.9165,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122643,
    shape_pt_lon: -89.925813,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.9755,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123183,
    shape_pt_lon: -89.925795,
    shape_pt_sequence: 206,
    shape_dist_traveled: 13.0365,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123259,
    shape_pt_lon: -89.925789,
    shape_pt_sequence: 207,
    shape_dist_traveled: 13.0446,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123694,
    shape_pt_lon: -89.925768,
    shape_pt_sequence: 208,
    shape_dist_traveled: 13.0936,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123563,
    shape_pt_lon: -89.92513,
    shape_pt_sequence: 209,
    shape_dist_traveled: 13.1526,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123527,
    shape_pt_lon: -89.924957,
    shape_pt_sequence: 210,
    shape_dist_traveled: 13.169,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -89.924055,
    shape_pt_sequence: 211,
    shape_dist_traveled: 13.2542,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123259,
    shape_pt_lon: -89.923823,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.276,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.123112,
    shape_pt_lon: -89.923318,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.3242,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122938,
    shape_pt_lon: -89.922793,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.3758,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122819,
    shape_pt_lon: -89.922472,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.4076,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122785,
    shape_pt_lon: -89.922381,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.4165,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122645,
    shape_pt_lon: -89.922034,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.4514,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.122337,
    shape_pt_lon: -89.921312,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.5256,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121908,
    shape_pt_lon: -89.920308,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.6276,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12188,
    shape_pt_lon: -89.920246,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.6343,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121713,
    shape_pt_lon: -89.919852,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.675,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121664,
    shape_pt_lon: -89.919738,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.6862,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12159,
    shape_pt_lon: -89.919567,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.7037,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121496,
    shape_pt_lon: -89.919346,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.7261,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121279,
    shape_pt_lon: -89.918834,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.7788,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.121156,
    shape_pt_lon: -89.918548,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.8084,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.1211,
    shape_pt_lon: -89.918418,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.8209,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120931,
    shape_pt_lon: -89.918023,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.8616,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.12071,
    shape_pt_lon: -89.917512,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.914,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120649,
    shape_pt_lon: -89.917367,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.9287,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120633,
    shape_pt_lon: -89.917331,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.9329,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120289,
    shape_pt_lon: -89.916528,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.0147,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120259,
    shape_pt_lon: -89.916458,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.0224,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.120058,
    shape_pt_lon: -89.915991,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.0698,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.11933,
    shape_pt_lon: -89.914291,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.2442,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.119304,
    shape_pt_lon: -89.914232,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.2501,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.119275,
    shape_pt_lon: -89.914165,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.2568,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.118347,
    shape_pt_lon: -89.911957,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.4817,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.11778,
    shape_pt_lon: -89.910617,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.6186,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.117079,
    shape_pt_lon: -89.908961,
    shape_pt_sequence: 240,
    shape_dist_traveled: 14.7877,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.116813,
    shape_pt_lon: -89.908332,
    shape_pt_sequence: 241,
    shape_dist_traveled: 14.8516,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.116115,
    shape_pt_lon: -89.906686,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.0198,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.115703,
    shape_pt_lon: -89.905707,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.1191,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.115526,
    shape_pt_lon: -89.905268,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.1639,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.115239,
    shape_pt_lon: -89.904474,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.2426,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.114993,
    shape_pt_lon: -89.903686,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.3186,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.114778,
    shape_pt_lon: -89.902911,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.3926,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.11474,
    shape_pt_lon: -89.902755,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.4075,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.11455,
    shape_pt_lon: -89.901955,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.4834,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.114468,
    shape_pt_lon: -89.901606,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.5157,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.114237,
    shape_pt_lon: -89.900637,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.6072,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.113897,
    shape_pt_lon: -89.899206,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.7417,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.113461,
    shape_pt_lon: -89.897379,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.9138,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.11319,
    shape_pt_lon: -89.896259,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.0201,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.113154,
    shape_pt_lon: -89.896146,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.0309,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.113018,
    shape_pt_lon: -89.895773,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.068,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.112859,
    shape_pt_lon: -89.895422,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.1039,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.111466,
    shape_pt_lon: -89.892778,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.3896,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.110528,
    shape_pt_lon: -89.890996,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.5818,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.109958,
    shape_pt_lon: -89.889916,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.6983,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.109365,
    shape_pt_lon: -89.888789,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.8203,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.109148,
    shape_pt_lon: -89.888377,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.8644,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.108973,
    shape_pt_lon: -89.888045,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.9,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.108576,
    shape_pt_lon: -89.887287,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.9823,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.108373,
    shape_pt_lon: -89.886862,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.0262,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.108299,
    shape_pt_lon: -89.886688,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.0446,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.108069,
    shape_pt_lon: -89.886128,
    shape_pt_sequence: 267,
    shape_dist_traveled: 17.1005,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.107928,
    shape_pt_lon: -89.885751,
    shape_pt_sequence: 268,
    shape_dist_traveled: 17.139,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.107691,
    shape_pt_lon: -89.885027,
    shape_pt_sequence: 269,
    shape_dist_traveled: 17.209,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -89.884686,
    shape_pt_sequence: 270,
    shape_dist_traveled: 17.2419,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.107473,
    shape_pt_lon: -89.884213,
    shape_pt_sequence: 271,
    shape_dist_traveled: 17.2871,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.107376,
    shape_pt_lon: -89.883823,
    shape_pt_sequence: 272,
    shape_dist_traveled: 17.3235,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10732,
    shape_pt_lon: -89.883603,
    shape_pt_sequence: 273,
    shape_dist_traveled: 17.3447,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.106874,
    shape_pt_lon: -89.881819,
    shape_pt_sequence: 274,
    shape_dist_traveled: 17.513,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.106422,
    shape_pt_lon: -89.880014,
    shape_pt_sequence: 275,
    shape_dist_traveled: 17.6835,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.106393,
    shape_pt_lon: -89.879896,
    shape_pt_sequence: 276,
    shape_dist_traveled: 17.6952,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.106078,
    shape_pt_lon: -89.878627,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.8154,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105724,
    shape_pt_lon: -89.877227,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.9483,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10546,
    shape_pt_lon: -89.876156,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.0485,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105408,
    shape_pt_lon: -89.87595,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.0685,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105346,
    shape_pt_lon: -89.875704,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.0916,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105149,
    shape_pt_lon: -89.874941,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.164,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105125,
    shape_pt_lon: -89.874861,
    shape_pt_sequence: 283,
    shape_dist_traveled: 18.1725,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.105061,
    shape_pt_lon: -89.87479,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.1817,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104984,
    shape_pt_lon: -89.874608,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.1996,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104907,
    shape_pt_lon: -89.874436,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.218,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104803,
    shape_pt_lon: -89.87424,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.2391,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10466,
    shape_pt_lon: -89.873993,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.2663,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104463,
    shape_pt_lon: -89.873708,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.3003,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104352,
    shape_pt_lon: -89.873566,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.3187,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.104121,
    shape_pt_lon: -89.8733,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.3541,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.103719,
    shape_pt_lon: -89.872894,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.4124,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.103489,
    shape_pt_lon: -89.872586,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.4499,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102907,
    shape_pt_lon: -89.871921,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.5384,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102675,
    shape_pt_lon: -89.871653,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.5737,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102535,
    shape_pt_lon: -89.871486,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.5957,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102337,
    shape_pt_lon: -89.871223,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.6282,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102187,
    shape_pt_lon: -89.870979,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.656,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102092,
    shape_pt_lon: -89.870799,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.6749,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102047,
    shape_pt_lon: -89.870684,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.687,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101999,
    shape_pt_lon: -89.870521,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.7031,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101901,
    shape_pt_lon: -89.870276,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.7273,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101869,
    shape_pt_lon: -89.870188,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.7363,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101832,
    shape_pt_lon: -89.870077,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.747,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101812,
    shape_pt_lon: -89.870017,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.7524,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101781,
    shape_pt_lon: -89.869918,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.7623,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101729,
    shape_pt_lon: -89.869742,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.7793,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101689,
    shape_pt_lon: -89.869458,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.8057,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10166,
    shape_pt_lon: -89.869188,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.8298,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101646,
    shape_pt_lon: -89.868792,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.8659,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101641,
    shape_pt_lon: -89.868549,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.8879,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101655,
    shape_pt_lon: -89.86834,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.9069,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101681,
    shape_pt_lon: -89.868102,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.9281,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101729,
    shape_pt_lon: -89.867819,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.9548,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101893,
    shape_pt_lon: -89.867174,
    shape_pt_sequence: 315,
    shape_dist_traveled: 19.0155,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101942,
    shape_pt_lon: -89.866992,
    shape_pt_sequence: 316,
    shape_dist_traveled: 19.0336,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10232,
    shape_pt_lon: -89.865901,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.1402,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102456,
    shape_pt_lon: -89.865417,
    shape_pt_sequence: 318,
    shape_dist_traveled: 19.187,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102528,
    shape_pt_lon: -89.865051,
    shape_pt_sequence: 319,
    shape_dist_traveled: 19.221,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102578,
    shape_pt_lon: -89.864647,
    shape_pt_sequence: 320,
    shape_dist_traveled: 19.2573,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102583,
    shape_pt_lon: -89.864442,
    shape_pt_sequence: 321,
    shape_dist_traveled: 19.2763,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102577,
    shape_pt_lon: -89.86406,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.3114,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102558,
    shape_pt_lon: -89.863847,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.3305,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102543,
    shape_pt_lon: -89.863732,
    shape_pt_sequence: 324,
    shape_dist_traveled: 19.3405,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102499,
    shape_pt_lon: -89.863461,
    shape_pt_sequence: 325,
    shape_dist_traveled: 19.366,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102366,
    shape_pt_lon: -89.862743,
    shape_pt_sequence: 326,
    shape_dist_traveled: 19.4327,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102265,
    shape_pt_lon: -89.862137,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.4878,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10219,
    shape_pt_lon: -89.861596,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.5375,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102239,
    shape_pt_lon: -89.861437,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.5533,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.102111,
    shape_pt_lon: -89.860691,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.6217,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101996,
    shape_pt_lon: -89.86007,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.6792,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101985,
    shape_pt_lon: -89.860012,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.6853,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101891,
    shape_pt_lon: -89.859462,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.7353,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101861,
    shape_pt_lon: -89.85931,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.7499,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10173,
    shape_pt_lon: -89.858597,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.8164,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101362,
    shape_pt_lon: -89.856575,
    shape_pt_sequence: 336,
    shape_dist_traveled: 20.0029,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.101197,
    shape_pt_lon: -89.85567,
    shape_pt_sequence: 337,
    shape_dist_traveled: 20.0869,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.100542,
    shape_pt_lon: -89.852087,
    shape_pt_sequence: 338,
    shape_dist_traveled: 20.419,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.851897,
    shape_pt_sequence: 339,
    shape_dist_traveled: 20.4374,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.10035,
    shape_pt_lon: -89.8511,
    shape_pt_sequence: 340,
    shape_dist_traveled: 20.5114,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.100273,
    shape_pt_lon: -89.85068,
    shape_pt_sequence: 341,
    shape_dist_traveled: 20.5505,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.100233,
    shape_pt_lon: -89.850461,
    shape_pt_sequence: 342,
    shape_dist_traveled: 20.5699,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.099887,
    shape_pt_lon: -89.848559,
    shape_pt_sequence: 343,
    shape_dist_traveled: 20.747,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.099623,
    shape_pt_lon: -89.84711,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.8814,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.099498,
    shape_pt_lon: -89.846411,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.9459,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.099353,
    shape_pt_lon: -89.845613,
    shape_pt_sequence: 346,
    shape_dist_traveled: 21.0197,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.099324,
    shape_pt_lon: -89.845463,
    shape_pt_sequence: 347,
    shape_dist_traveled: 21.034,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09919,
    shape_pt_lon: -89.844737,
    shape_pt_sequence: 348,
    shape_dist_traveled: 21.1007,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09909,
    shape_pt_lon: -89.844199,
    shape_pt_sequence: 349,
    shape_dist_traveled: 21.1509,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098809,
    shape_pt_lon: -89.842678,
    shape_pt_sequence: 350,
    shape_dist_traveled: 21.2914,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098724,
    shape_pt_lon: -89.842223,
    shape_pt_sequence: 351,
    shape_dist_traveled: 21.3344,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098669,
    shape_pt_lon: -89.841922,
    shape_pt_sequence: 352,
    shape_dist_traveled: 21.362,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098622,
    shape_pt_lon: -89.841667,
    shape_pt_sequence: 353,
    shape_dist_traveled: 21.3858,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098579,
    shape_pt_lon: -89.841427,
    shape_pt_sequence: 354,
    shape_dist_traveled: 21.4081,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098516,
    shape_pt_lon: -89.841073,
    shape_pt_sequence: 355,
    shape_dist_traveled: 21.4409,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098477,
    shape_pt_lon: -89.840847,
    shape_pt_sequence: 356,
    shape_dist_traveled: 21.4615,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098432,
    shape_pt_lon: -89.840584,
    shape_pt_sequence: 357,
    shape_dist_traveled: 21.486,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098316,
    shape_pt_lon: -89.839892,
    shape_pt_sequence: 358,
    shape_dist_traveled: 21.5492,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09826,
    shape_pt_lon: -89.839472,
    shape_pt_sequence: 359,
    shape_dist_traveled: 21.5878,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098248,
    shape_pt_lon: -89.839388,
    shape_pt_sequence: 360,
    shape_dist_traveled: 21.5959,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098236,
    shape_pt_lon: -89.839293,
    shape_pt_sequence: 361,
    shape_dist_traveled: 21.6049,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09817,
    shape_pt_lon: -89.83878,
    shape_pt_sequence: 362,
    shape_dist_traveled: 21.6515,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098077,
    shape_pt_lon: -89.837891,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.7322,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.098019,
    shape_pt_lon: -89.837409,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.7766,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.097981,
    shape_pt_lon: -89.837076,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.8069,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.097938,
    shape_pt_lon: -89.836684,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.8432,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09786,
    shape_pt_lon: -89.836021,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.9039,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.097719,
    shape_pt_lon: -89.834802,
    shape_pt_sequence: 368,
    shape_dist_traveled: 22.0149,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.097351,
    shape_pt_lon: -89.831623,
    shape_pt_sequence: 369,
    shape_dist_traveled: 22.3048,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09707,
    shape_pt_lon: -89.829258,
    shape_pt_sequence: 370,
    shape_dist_traveled: 22.5209,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.097022,
    shape_pt_lon: -89.828857,
    shape_pt_sequence: 371,
    shape_dist_traveled: 22.5574,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09689,
    shape_pt_lon: -89.827715,
    shape_pt_sequence: 372,
    shape_dist_traveled: 22.6624,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096773,
    shape_pt_lon: -89.826779,
    shape_pt_sequence: 373,
    shape_dist_traveled: 22.7474,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096761,
    shape_pt_lon: -89.826689,
    shape_pt_sequence: 374,
    shape_dist_traveled: 22.7554,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096749,
    shape_pt_lon: -89.82659,
    shape_pt_sequence: 375,
    shape_dist_traveled: 22.7647,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096539,
    shape_pt_lon: -89.824755,
    shape_pt_sequence: 376,
    shape_dist_traveled: 22.9322,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096497,
    shape_pt_lon: -89.824401,
    shape_pt_sequence: 377,
    shape_dist_traveled: 22.9645,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096489,
    shape_pt_lon: -89.824337,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.9706,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096483,
    shape_pt_lon: -89.824286,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.9757,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096477,
    shape_pt_lon: -89.824217,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.9818,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09627,
    shape_pt_lon: -89.822276,
    shape_pt_sequence: 381,
    shape_dist_traveled: 23.1591,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096197,
    shape_pt_lon: -89.821649,
    shape_pt_sequence: 382,
    shape_dist_traveled: 23.2157,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.096061,
    shape_pt_lon: -89.8205,
    shape_pt_sequence: 383,
    shape_dist_traveled: 23.3208,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095789,
    shape_pt_lon: -89.818089,
    shape_pt_sequence: 384,
    shape_dist_traveled: 23.5408,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095701,
    shape_pt_lon: -89.817386,
    shape_pt_sequence: 385,
    shape_dist_traveled: 23.6056,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095531,
    shape_pt_lon: -89.816289,
    shape_pt_sequence: 386,
    shape_dist_traveled: 23.7064,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095453,
    shape_pt_lon: -89.81588,
    shape_pt_sequence: 387,
    shape_dist_traveled: 23.7443,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095326,
    shape_pt_lon: -89.815295,
    shape_pt_sequence: 388,
    shape_dist_traveled: 23.7991,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095166,
    shape_pt_lon: -89.814637,
    shape_pt_sequence: 389,
    shape_dist_traveled: 23.8617,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095039,
    shape_pt_lon: -89.814178,
    shape_pt_sequence: 390,
    shape_dist_traveled: 23.905,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094932,
    shape_pt_lon: -89.81381,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.9402,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094707,
    shape_pt_lon: -89.813097,
    shape_pt_sequence: 392,
    shape_dist_traveled: 24.0098,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094631,
    shape_pt_lon: -89.812898,
    shape_pt_sequence: 393,
    shape_dist_traveled: 24.0295,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09442,
    shape_pt_lon: -89.812339,
    shape_pt_sequence: 394,
    shape_dist_traveled: 24.0859,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09437,
    shape_pt_lon: -89.812206,
    shape_pt_sequence: 395,
    shape_dist_traveled: 24.0993,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094002,
    shape_pt_lon: -89.811357,
    shape_pt_sequence: 396,
    shape_dist_traveled: 24.1852,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094123,
    shape_pt_lon: -89.811281,
    shape_pt_sequence: 397,
    shape_dist_traveled: 24.1999,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094194,
    shape_pt_lon: -89.811169,
    shape_pt_sequence: 398,
    shape_dist_traveled: 24.2127,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094563,
    shape_pt_lon: -89.810968,
    shape_pt_sequence: 399,
    shape_dist_traveled: 24.2575,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094646,
    shape_pt_lon: -89.810922,
    shape_pt_sequence: 400,
    shape_dist_traveled: 24.2687,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094767,
    shape_pt_lon: -89.810843,
    shape_pt_sequence: 401,
    shape_dist_traveled: 24.2835,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094983,
    shape_pt_lon: -89.810676,
    shape_pt_sequence: 402,
    shape_dist_traveled: 24.3118,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095091,
    shape_pt_lon: -89.810577,
    shape_pt_sequence: 403,
    shape_dist_traveled: 24.3276,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095202,
    shape_pt_lon: -89.810446,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.3438,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095349,
    shape_pt_lon: -89.810232,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.3701,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095464,
    shape_pt_lon: -89.810017,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.3926,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095586,
    shape_pt_lon: -89.809689,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.4257,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095608,
    shape_pt_lon: -89.809607,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.4333,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095657,
    shape_pt_lon: -89.809352,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.4568,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095681,
    shape_pt_lon: -89.809099,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.48,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095678,
    shape_pt_lon: -89.80877,
    shape_pt_sequence: 411,
    shape_dist_traveled: 24.51,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095676,
    shape_pt_lon: -89.808689,
    shape_pt_sequence: 412,
    shape_dist_traveled: 24.5171,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095589,
    shape_pt_lon: -89.807831,
    shape_pt_sequence: 413,
    shape_dist_traveled: 24.5956,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095543,
    shape_pt_lon: -89.807548,
    shape_pt_sequence: 414,
    shape_dist_traveled: 24.6211,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095419,
    shape_pt_lon: -89.806993,
    shape_pt_sequence: 415,
    shape_dist_traveled: 24.674,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095383,
    shape_pt_lon: -89.806705,
    shape_pt_sequence: 416,
    shape_dist_traveled: 24.7003,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.09536,
    shape_pt_lon: -89.806389,
    shape_pt_sequence: 417,
    shape_dist_traveled: 24.7284,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095364,
    shape_pt_lon: -89.806197,
    shape_pt_sequence: 418,
    shape_dist_traveled: 24.7454,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095395,
    shape_pt_lon: -89.805766,
    shape_pt_sequence: 419,
    shape_dist_traveled: 24.7846,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095504,
    shape_pt_lon: -89.805015,
    shape_pt_sequence: 420,
    shape_dist_traveled: 24.8536,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095518,
    shape_pt_lon: -89.804779,
    shape_pt_sequence: 421,
    shape_dist_traveled: 24.8757,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095523,
    shape_pt_lon: -89.804549,
    shape_pt_sequence: 422,
    shape_dist_traveled: 24.8957,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095511,
    shape_pt_lon: -89.803632,
    shape_pt_sequence: 423,
    shape_dist_traveled: 24.9787,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.095496,
    shape_pt_lon: -89.802187,
    shape_pt_sequence: 424,
    shape_dist_traveled: 25.1097,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.094351,
    shape_pt_lon: -89.80229,
    shape_pt_sequence: 425,
    shape_dist_traveled: 25.238,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.093198,
    shape_pt_lon: -89.802386,
    shape_pt_sequence: 426,
    shape_dist_traveled: 25.3673,
  },
  {
    shape_id: 38245,
    shape_pt_lat: 35.0919,
    shape_pt_lon: -89.802498,
    shape_pt_sequence: 427,
    shape_dist_traveled: 25.5126,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.0919,
    shape_pt_lon: -89.802498,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09182,
    shape_pt_lon: -89.80251,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0091,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.091599,
    shape_pt_lon: -89.802562,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0346,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.091401,
    shape_pt_lon: -89.802628,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0574,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.091288,
    shape_pt_lon: -89.802678,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.071,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.090993,
    shape_pt_lon: -89.802838,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1068,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09026,
    shape_pt_lon: -89.803338,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2003,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.090112,
    shape_pt_lon: -89.803438,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2196,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.090771,
    shape_pt_lon: -89.804773,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3614,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09123,
    shape_pt_lon: -89.805688,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4588,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.0919,
    shape_pt_lon: -89.80705,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6032,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.092753,
    shape_pt_lon: -89.808771,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.785,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.093092,
    shape_pt_lon: -89.809447,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8577,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.093681,
    shape_pt_lon: -89.810657,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9852,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.094002,
    shape_pt_lon: -89.811357,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0586,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09437,
    shape_pt_lon: -89.812206,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.1445,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09442,
    shape_pt_lon: -89.812339,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.1579,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.094631,
    shape_pt_lon: -89.812898,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2143,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.094707,
    shape_pt_lon: -89.813097,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.234,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.094932,
    shape_pt_lon: -89.81381,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.3036,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095039,
    shape_pt_lon: -89.814178,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.3387,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095166,
    shape_pt_lon: -89.814637,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.382,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095326,
    shape_pt_lon: -89.815295,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.4447,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095453,
    shape_pt_lon: -89.81588,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.4995,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095531,
    shape_pt_lon: -89.816289,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.5374,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095701,
    shape_pt_lon: -89.817386,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.6382,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.095789,
    shape_pt_lon: -89.818089,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.7029,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096061,
    shape_pt_lon: -89.8205,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.923,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096197,
    shape_pt_lon: -89.821649,
    shape_pt_sequence: 29,
    shape_dist_traveled: 2.0281,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09627,
    shape_pt_lon: -89.822276,
    shape_pt_sequence: 30,
    shape_dist_traveled: 2.0846,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096477,
    shape_pt_lon: -89.824217,
    shape_pt_sequence: 31,
    shape_dist_traveled: 2.262,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096483,
    shape_pt_lon: -89.824286,
    shape_pt_sequence: 32,
    shape_dist_traveled: 2.2681,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096489,
    shape_pt_lon: -89.824337,
    shape_pt_sequence: 33,
    shape_dist_traveled: 2.2732,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096497,
    shape_pt_lon: -89.824401,
    shape_pt_sequence: 34,
    shape_dist_traveled: 2.2793,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096539,
    shape_pt_lon: -89.824755,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.3115,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096749,
    shape_pt_lon: -89.82659,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.4791,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096761,
    shape_pt_lon: -89.826689,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.4883,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.096773,
    shape_pt_lon: -89.826779,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.4964,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09689,
    shape_pt_lon: -89.827715,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.5814,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.097022,
    shape_pt_lon: -89.828857,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.6863,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09707,
    shape_pt_lon: -89.829258,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.7228,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.097351,
    shape_pt_lon: -89.831623,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.9389,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.097719,
    shape_pt_lon: -89.834802,
    shape_pt_sequence: 43,
    shape_dist_traveled: 3.2288,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09786,
    shape_pt_lon: -89.836021,
    shape_pt_sequence: 44,
    shape_dist_traveled: 3.3398,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.097938,
    shape_pt_lon: -89.836684,
    shape_pt_sequence: 45,
    shape_dist_traveled: 3.4005,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.097981,
    shape_pt_lon: -89.837076,
    shape_pt_sequence: 46,
    shape_dist_traveled: 3.4369,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098019,
    shape_pt_lon: -89.837409,
    shape_pt_sequence: 47,
    shape_dist_traveled: 3.4671,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098077,
    shape_pt_lon: -89.837891,
    shape_pt_sequence: 48,
    shape_dist_traveled: 3.5115,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09817,
    shape_pt_lon: -89.83878,
    shape_pt_sequence: 49,
    shape_dist_traveled: 3.5923,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098236,
    shape_pt_lon: -89.839293,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.6388,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098248,
    shape_pt_lon: -89.839388,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.6479,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09826,
    shape_pt_lon: -89.839472,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.6559,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098316,
    shape_pt_lon: -89.839892,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.6946,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098432,
    shape_pt_lon: -89.840584,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.7577,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098477,
    shape_pt_lon: -89.840847,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.7822,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098516,
    shape_pt_lon: -89.841073,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.8029,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098579,
    shape_pt_lon: -89.841427,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.8356,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098622,
    shape_pt_lon: -89.841667,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.858,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098669,
    shape_pt_lon: -89.841922,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.8817,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098724,
    shape_pt_lon: -89.842223,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.9094,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.098809,
    shape_pt_lon: -89.842678,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.9524,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09909,
    shape_pt_lon: -89.844199,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.0928,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.09919,
    shape_pt_lon: -89.844737,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.143,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.099324,
    shape_pt_lon: -89.845463,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.2097,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.099353,
    shape_pt_lon: -89.845613,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.2241,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.099498,
    shape_pt_lon: -89.846411,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.2978,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.099623,
    shape_pt_lon: -89.84711,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.3624,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.099887,
    shape_pt_lon: -89.848559,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.4968,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.100233,
    shape_pt_lon: -89.850461,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.6739,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.100273,
    shape_pt_lon: -89.85068,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.6933,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10035,
    shape_pt_lon: -89.8511,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.7323,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.100505,
    shape_pt_lon: -89.851897,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.8063,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.100542,
    shape_pt_lon: -89.852087,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.8248,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101197,
    shape_pt_lon: -89.85567,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.1569,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101362,
    shape_pt_lon: -89.856575,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.2408,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10173,
    shape_pt_lon: -89.858597,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.4274,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101861,
    shape_pt_lon: -89.85931,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.4939,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101891,
    shape_pt_lon: -89.859462,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.5084,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101985,
    shape_pt_lon: -89.860012,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.5585,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.101996,
    shape_pt_lon: -89.86007,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.5645,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102111,
    shape_pt_lon: -89.860691,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.622,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102239,
    shape_pt_lon: -89.861437,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.6905,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102323,
    shape_pt_lon: -89.861572,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.7055,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10243,
    shape_pt_lon: -89.862186,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.7627,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102571,
    shape_pt_lon: -89.86294,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.8326,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102698,
    shape_pt_lon: -89.863513,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.8865,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10273,
    shape_pt_lon: -89.863686,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.9029,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102806,
    shape_pt_lon: -89.864105,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.9418,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.102957,
    shape_pt_lon: -89.864889,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.0138,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103187,
    shape_pt_lon: -89.865924,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.1111,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103359,
    shape_pt_lon: -89.86669,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.1827,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103456,
    shape_pt_lon: -89.86712,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.2232,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103605,
    shape_pt_lon: -89.867791,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.2865,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103828,
    shape_pt_lon: -89.868787,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.3796,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.103929,
    shape_pt_lon: -89.869346,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.432,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104047,
    shape_pt_lon: -89.869899,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.4837,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104069,
    shape_pt_lon: -89.870018,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.4939,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104101,
    shape_pt_lon: -89.870148,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.5065,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104115,
    shape_pt_lon: -89.870221,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.5136,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104178,
    shape_pt_lon: -89.870485,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.5386,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.1042,
    shape_pt_lon: -89.870585,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.5481,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104229,
    shape_pt_lon: -89.870709,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.5595,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10449,
    shape_pt_lon: -89.871879,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.6694,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104649,
    shape_pt_lon: -89.872577,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.7349,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104733,
    shape_pt_lon: -89.872876,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.7634,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104827,
    shape_pt_lon: -89.8733,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.8027,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.104897,
    shape_pt_lon: -89.873672,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.8376,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105143,
    shape_pt_lon: -89.874759,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.9393,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105125,
    shape_pt_lon: -89.874861,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.9485,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105149,
    shape_pt_lon: -89.874941,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.957,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105346,
    shape_pt_lon: -89.875704,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.0294,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105408,
    shape_pt_lon: -89.87595,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.0525,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10546,
    shape_pt_lon: -89.876156,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.0725,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.105724,
    shape_pt_lon: -89.877227,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.1727,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.106078,
    shape_pt_lon: -89.878627,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.3056,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.106393,
    shape_pt_lon: -89.879896,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.4258,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.106422,
    shape_pt_lon: -89.880014,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.4375,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.106874,
    shape_pt_lon: -89.881819,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.608,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.10732,
    shape_pt_lon: -89.883603,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.7763,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.107376,
    shape_pt_lon: -89.883823,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.7975,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.107473,
    shape_pt_lon: -89.884213,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.8339,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.107592,
    shape_pt_lon: -89.884686,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.8791,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.107691,
    shape_pt_lon: -89.885027,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.912,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.107928,
    shape_pt_lon: -89.885751,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.982,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.108069,
    shape_pt_lon: -89.886128,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.0205,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.108299,
    shape_pt_lon: -89.886688,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.0764,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.108373,
    shape_pt_lon: -89.886862,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.0947,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.108576,
    shape_pt_lon: -89.887287,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.1387,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.108973,
    shape_pt_lon: -89.888045,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.221,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.109148,
    shape_pt_lon: -89.888377,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.2565,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.109365,
    shape_pt_lon: -89.888789,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.3006,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.109958,
    shape_pt_lon: -89.889916,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.4227,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.110528,
    shape_pt_lon: -89.890996,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.5392,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.111466,
    shape_pt_lon: -89.892778,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.7314,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.112859,
    shape_pt_lon: -89.895422,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.0171,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.113018,
    shape_pt_lon: -89.895773,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.0529,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.113154,
    shape_pt_lon: -89.896146,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.0901,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.11319,
    shape_pt_lon: -89.896259,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.1009,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.113461,
    shape_pt_lon: -89.897379,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.2072,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.113897,
    shape_pt_lon: -89.899206,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.3793,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.114237,
    shape_pt_lon: -89.900637,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.5138,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.114468,
    shape_pt_lon: -89.901606,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.6053,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.11455,
    shape_pt_lon: -89.901955,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.6376,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.11474,
    shape_pt_lon: -89.902755,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.7135,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.114778,
    shape_pt_lon: -89.902911,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.7284,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.114993,
    shape_pt_lon: -89.903686,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.8024,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.115239,
    shape_pt_lon: -89.904474,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.8784,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.115526,
    shape_pt_lon: -89.905268,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.9571,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.115703,
    shape_pt_lon: -89.905707,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.0019,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.116115,
    shape_pt_lon: -89.906686,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.1012,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.116813,
    shape_pt_lon: -89.908332,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.2693,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.117079,
    shape_pt_lon: -89.908961,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.3333,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.11778,
    shape_pt_lon: -89.910617,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.5024,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.118347,
    shape_pt_lon: -89.911957,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.6392,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.119275,
    shape_pt_lon: -89.914165,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.8642,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.119304,
    shape_pt_lon: -89.914232,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.8709,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.11933,
    shape_pt_lon: -89.914291,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.8768,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120058,
    shape_pt_lon: -89.915991,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.0512,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120259,
    shape_pt_lon: -89.916458,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.0986,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120289,
    shape_pt_lon: -89.916528,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.1063,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120633,
    shape_pt_lon: -89.917331,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.1881,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120649,
    shape_pt_lon: -89.917367,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.1923,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12071,
    shape_pt_lon: -89.917512,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.207,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.120931,
    shape_pt_lon: -89.918023,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.2594,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.1211,
    shape_pt_lon: -89.918418,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.3001,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121156,
    shape_pt_lon: -89.918548,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.3126,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121279,
    shape_pt_lon: -89.918834,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.3421,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121496,
    shape_pt_lon: -89.919346,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.3949,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12159,
    shape_pt_lon: -89.919567,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.4173,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121664,
    shape_pt_lon: -89.919738,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.4348,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121713,
    shape_pt_lon: -89.919852,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.4459,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12188,
    shape_pt_lon: -89.920246,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.4867,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.121908,
    shape_pt_lon: -89.920308,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.4934,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122337,
    shape_pt_lon: -89.921312,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.5954,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122645,
    shape_pt_lon: -89.922034,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.6696,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122785,
    shape_pt_lon: -89.922381,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.7045,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122819,
    shape_pt_lon: -89.922472,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.7134,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122938,
    shape_pt_lon: -89.922793,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.7452,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123112,
    shape_pt_lon: -89.923318,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.7968,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123259,
    shape_pt_lon: -89.923823,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.8449,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12332,
    shape_pt_lon: -89.924055,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.8668,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123527,
    shape_pt_lon: -89.924957,
    shape_pt_sequence: 182,
    shape_dist_traveled: 11.952,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123563,
    shape_pt_lon: -89.92513,
    shape_pt_sequence: 183,
    shape_dist_traveled: 11.9684,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123694,
    shape_pt_lon: -89.925768,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.0274,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123259,
    shape_pt_lon: -89.925789,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.0764,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123183,
    shape_pt_lon: -89.925795,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.0845,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122643,
    shape_pt_lon: -89.925813,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.1455,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12266,
    shape_pt_lon: -89.926458,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.2045,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122662,
    shape_pt_lon: -89.926531,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.2105,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122721,
    shape_pt_lon: -89.928587,
    shape_pt_sequence: 190,
    shape_dist_traveled: 12.3967,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122745,
    shape_pt_lon: -89.929746,
    shape_pt_sequence: 191,
    shape_dist_traveled: 12.5017,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122751,
    shape_pt_lon: -89.930122,
    shape_pt_sequence: 192,
    shape_dist_traveled: 12.5357,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122783,
    shape_pt_lon: -89.931146,
    shape_pt_sequence: 193,
    shape_dist_traveled: 12.6277,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12285,
    shape_pt_lon: -89.933601,
    shape_pt_sequence: 194,
    shape_dist_traveled: 12.8499,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122851,
    shape_pt_lon: -89.933669,
    shape_pt_sequence: 195,
    shape_dist_traveled: 12.8559,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122886,
    shape_pt_lon: -89.934812,
    shape_pt_sequence: 196,
    shape_dist_traveled: 12.9599,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122925,
    shape_pt_lon: -89.936112,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.077,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.122951,
    shape_pt_lon: -89.937246,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.1801,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123011,
    shape_pt_lon: -89.939286,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.3642,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123046,
    shape_pt_lon: -89.940492,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.4733,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123098,
    shape_pt_lon: -89.942328,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.6393,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123109,
    shape_pt_lon: -89.942734,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.6764,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123129,
    shape_pt_lon: -89.943432,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.7394,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.9176,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 205,
    shape_dist_traveled: 13.9886,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.0556,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.0946,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.1346,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.1737,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.2177,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.2757,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.4318,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.127812,
    shape_pt_lon: -89.945321,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.441,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12798,
    shape_pt_lon: -89.946059,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.5107,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.128229,
    shape_pt_lon: -89.947026,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.6021,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.128712,
    shape_pt_lon: -89.948817,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.7728,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.128926,
    shape_pt_lon: -89.949601,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.8475,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.129118,
    shape_pt_lon: -89.950301,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.9142,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.129134,
    shape_pt_lon: -89.950363,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.9205,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.12915,
    shape_pt_lon: -89.950421,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.9256,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.129234,
    shape_pt_lon: -89.950736,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.9563,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.129507,
    shape_pt_lon: -89.951758,
    shape_pt_sequence: 222,
    shape_dist_traveled: 15.0531,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.129878,
    shape_pt_lon: -89.953019,
    shape_pt_sequence: 223,
    shape_dist_traveled: 15.1746,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.130206,
    shape_pt_lon: -89.954057,
    shape_pt_sequence: 224,
    shape_dist_traveled: 15.2752,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.130242,
    shape_pt_lon: -89.954173,
    shape_pt_sequence: 225,
    shape_dist_traveled: 15.286,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.130669,
    shape_pt_lon: -89.955511,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.4162,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13076,
    shape_pt_lon: -89.955797,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.444,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13133,
    shape_pt_lon: -89.957583,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.6182,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.131607,
    shape_pt_lon: -89.958475,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.7036,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.131681,
    shape_pt_lon: -89.958693,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.7256,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13179,
    shape_pt_lon: -89.959038,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.7588,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.131872,
    shape_pt_lon: -89.959297,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.7845,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.132124,
    shape_pt_lon: -89.960021,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.8552,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.132832,
    shape_pt_lon: -89.961906,
    shape_pt_sequence: 234,
    shape_dist_traveled: 16.0427,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13293,
    shape_pt_lon: -89.962168,
    shape_pt_sequence: 235,
    shape_dist_traveled: 16.0691,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.133373,
    shape_pt_lon: -89.963363,
    shape_pt_sequence: 236,
    shape_dist_traveled: 16.1881,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.133737,
    shape_pt_lon: -89.964318,
    shape_pt_sequence: 237,
    shape_dist_traveled: 16.2829,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.133896,
    shape_pt_lon: -89.964732,
    shape_pt_sequence: 238,
    shape_dist_traveled: 16.325,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.134331,
    shape_pt_lon: -89.96587,
    shape_pt_sequence: 239,
    shape_dist_traveled: 16.4391,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.134449,
    shape_pt_lon: -89.966189,
    shape_pt_sequence: 240,
    shape_dist_traveled: 16.4708,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.134901,
    shape_pt_lon: -89.967499,
    shape_pt_sequence: 241,
    shape_dist_traveled: 16.599,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.134976,
    shape_pt_lon: -89.967715,
    shape_pt_sequence: 242,
    shape_dist_traveled: 16.6205,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.1352,
    shape_pt_lon: -89.968338,
    shape_pt_sequence: 243,
    shape_dist_traveled: 16.6823,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.135331,
    shape_pt_lon: -89.9687,
    shape_pt_sequence: 244,
    shape_dist_traveled: 16.7181,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.135351,
    shape_pt_lon: -89.968753,
    shape_pt_sequence: 245,
    shape_dist_traveled: 16.7226,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.135391,
    shape_pt_lon: -89.968866,
    shape_pt_sequence: 246,
    shape_dist_traveled: 16.7347,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.135546,
    shape_pt_lon: -89.969299,
    shape_pt_sequence: 247,
    shape_dist_traveled: 16.7772,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.135768,
    shape_pt_lon: -89.969908,
    shape_pt_sequence: 248,
    shape_dist_traveled: 16.8376,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.1358,
    shape_pt_lon: -89.97,
    shape_pt_sequence: 249,
    shape_dist_traveled: 16.8462,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.136238,
    shape_pt_lon: -89.971202,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.9657,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.136349,
    shape_pt_lon: -89.971509,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.9966,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.137032,
    shape_pt_lon: -89.97343,
    shape_pt_sequence: 252,
    shape_dist_traveled: 17.1855,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.137128,
    shape_pt_lon: -89.973693,
    shape_pt_sequence: 253,
    shape_dist_traveled: 17.2119,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.137615,
    shape_pt_lon: -89.974897,
    shape_pt_sequence: 254,
    shape_dist_traveled: 17.3336,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.137703,
    shape_pt_lon: -89.975132,
    shape_pt_sequence: 255,
    shape_dist_traveled: 17.3568,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13802,
    shape_pt_lon: -89.97603,
    shape_pt_sequence: 256,
    shape_dist_traveled: 17.4451,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.138218,
    shape_pt_lon: -89.976608,
    shape_pt_sequence: 257,
    shape_dist_traveled: 17.5024,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.138705,
    shape_pt_lon: -89.978041,
    shape_pt_sequence: 258,
    shape_dist_traveled: 17.6427,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.139028,
    shape_pt_lon: -89.978909,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.7295,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.139117,
    shape_pt_lon: -89.979122,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.751,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.139224,
    shape_pt_lon: -89.979425,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.7805,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.13948,
    shape_pt_lon: -89.98016,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.8531,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.139867,
    shape_pt_lon: -89.981243,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.9605,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.140212,
    shape_pt_lon: -89.98221,
    shape_pt_sequence: 264,
    shape_dist_traveled: 18.0555,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.140403,
    shape_pt_lon: -89.98271,
    shape_pt_sequence: 265,
    shape_dist_traveled: 18.1051,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14054,
    shape_pt_lon: -89.983059,
    shape_pt_sequence: 266,
    shape_dist_traveled: 18.1409,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.140791,
    shape_pt_lon: -89.983681,
    shape_pt_sequence: 267,
    shape_dist_traveled: 18.2035,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.140911,
    shape_pt_lon: -89.983978,
    shape_pt_sequence: 268,
    shape_dist_traveled: 18.2335,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141177,
    shape_pt_lon: -89.984712,
    shape_pt_sequence: 269,
    shape_dist_traveled: 18.306,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141208,
    shape_pt_lon: -89.984852,
    shape_pt_sequence: 270,
    shape_dist_traveled: 18.3193,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141251,
    shape_pt_lon: -89.985111,
    shape_pt_sequence: 271,
    shape_dist_traveled: 18.3429,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141375,
    shape_pt_lon: -89.986083,
    shape_pt_sequence: 272,
    shape_dist_traveled: 18.432,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141452,
    shape_pt_lon: -89.986759,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.4935,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141461,
    shape_pt_lon: -89.986855,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.5026,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141524,
    shape_pt_lon: -89.98759,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.5689,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141601,
    shape_pt_lon: -89.988337,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.6375,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141696,
    shape_pt_lon: -89.989431,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.736,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141699,
    shape_pt_lon: -89.989464,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.739,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141729,
    shape_pt_lon: -89.989814,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.7712,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141865,
    shape_pt_lon: -89.991342,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.91,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141887,
    shape_pt_lon: -89.991588,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.9322,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.141952,
    shape_pt_lon: -89.992313,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.9986,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142092,
    shape_pt_lon: -89.993831,
    shape_pt_sequence: 283,
    shape_dist_traveled: 19.1365,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142206,
    shape_pt_lon: -89.99507,
    shape_pt_sequence: 284,
    shape_dist_traveled: 19.2491,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142303,
    shape_pt_lon: -89.996165,
    shape_pt_sequence: 285,
    shape_dist_traveled: 19.3487,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142432,
    shape_pt_lon: -89.997568,
    shape_pt_sequence: 286,
    shape_dist_traveled: 19.4765,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14244,
    shape_pt_lon: -89.997659,
    shape_pt_sequence: 287,
    shape_dist_traveled: 19.4846,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142531,
    shape_pt_lon: -89.998943,
    shape_pt_sequence: 288,
    shape_dist_traveled: 19.601,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142643,
    shape_pt_lon: -90.000271,
    shape_pt_sequence: 289,
    shape_dist_traveled: 19.7217,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14265,
    shape_pt_lon: -90.000372,
    shape_pt_sequence: 290,
    shape_dist_traveled: 19.7308,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142696,
    shape_pt_lon: -90.000959,
    shape_pt_sequence: 291,
    shape_dist_traveled: 19.784,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142756,
    shape_pt_lon: -90.001732,
    shape_pt_sequence: 292,
    shape_dist_traveled: 19.8543,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142794,
    shape_pt_lon: -90.002224,
    shape_pt_sequence: 293,
    shape_dist_traveled: 19.8985,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142849,
    shape_pt_lon: -90.002882,
    shape_pt_sequence: 294,
    shape_dist_traveled: 19.9588,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.142896,
    shape_pt_lon: -90.003461,
    shape_pt_sequence: 295,
    shape_dist_traveled: 20.0111,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14296,
    shape_pt_lon: -90.004299,
    shape_pt_sequence: 296,
    shape_dist_traveled: 20.0874,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143032,
    shape_pt_lon: -90.005209,
    shape_pt_sequence: 297,
    shape_dist_traveled: 20.1698,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143064,
    shape_pt_lon: -90.005599,
    shape_pt_sequence: 298,
    shape_dist_traveled: 20.205,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14309,
    shape_pt_lon: -90.005944,
    shape_pt_sequence: 299,
    shape_dist_traveled: 20.2371,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143178,
    shape_pt_lon: -90.00707,
    shape_pt_sequence: 300,
    shape_dist_traveled: 20.3386,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143184,
    shape_pt_lon: -90.007149,
    shape_pt_sequence: 301,
    shape_dist_traveled: 20.3456,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14324,
    shape_pt_lon: -90.007817,
    shape_pt_sequence: 302,
    shape_dist_traveled: 20.4069,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143292,
    shape_pt_lon: -90.008413,
    shape_pt_sequence: 303,
    shape_dist_traveled: 20.4613,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143349,
    shape_pt_lon: -90.009037,
    shape_pt_sequence: 304,
    shape_dist_traveled: 20.5176,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143403,
    shape_pt_lon: -90.009648,
    shape_pt_sequence: 305,
    shape_dist_traveled: 20.5729,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143418,
    shape_pt_lon: -90.009802,
    shape_pt_sequence: 306,
    shape_dist_traveled: 20.5871,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143479,
    shape_pt_lon: -90.010474,
    shape_pt_sequence: 307,
    shape_dist_traveled: 20.6485,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143485,
    shape_pt_lon: -90.010531,
    shape_pt_sequence: 308,
    shape_dist_traveled: 20.6535,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143489,
    shape_pt_lon: -90.010572,
    shape_pt_sequence: 309,
    shape_dist_traveled: 20.6576,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143493,
    shape_pt_lon: -90.01063,
    shape_pt_sequence: 310,
    shape_dist_traveled: 20.6626,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143552,
    shape_pt_lon: -90.011262,
    shape_pt_sequence: 311,
    shape_dist_traveled: 20.72,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14356,
    shape_pt_lon: -90.011334,
    shape_pt_sequence: 312,
    shape_dist_traveled: 20.7271,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143605,
    shape_pt_lon: -90.011832,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.7724,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143707,
    shape_pt_lon: -90.012959,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.874,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143761,
    shape_pt_lon: -90.013583,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.9313,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143798,
    shape_pt_lon: -90.014,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.9695,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143847,
    shape_pt_lon: -90.01455,
    shape_pt_sequence: 317,
    shape_dist_traveled: 21.0188,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.143956,
    shape_pt_lon: -90.015646,
    shape_pt_sequence: 318,
    shape_dist_traveled: 21.1186,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144024,
    shape_pt_lon: -90.016366,
    shape_pt_sequence: 319,
    shape_dist_traveled: 21.1839,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144047,
    shape_pt_lon: -90.01662,
    shape_pt_sequence: 320,
    shape_dist_traveled: 21.2071,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144051,
    shape_pt_lon: -90.016679,
    shape_pt_sequence: 321,
    shape_dist_traveled: 21.2131,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144093,
    shape_pt_lon: -90.017184,
    shape_pt_sequence: 322,
    shape_dist_traveled: 21.2584,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144125,
    shape_pt_lon: -90.017532,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.2907,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144286,
    shape_pt_lon: -90.019334,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.4537,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144299,
    shape_pt_lon: -90.019479,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.4677,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144341,
    shape_pt_lon: -90.019942,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.509,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144437,
    shape_pt_lon: -90.020973,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.6035,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144528,
    shape_pt_lon: -90.021958,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.6931,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14455,
    shape_pt_lon: -90.022231,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.7173,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144566,
    shape_pt_lon: -90.022392,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.7324,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144572,
    shape_pt_lon: -90.022477,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.7394,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144583,
    shape_pt_lon: -90.022598,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.7506,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144601,
    shape_pt_lon: -90.022789,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.7687,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144619,
    shape_pt_lon: -90.02297,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.7848,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144681,
    shape_pt_lon: -90.023636,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.8452,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144767,
    shape_pt_lon: -90.024519,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.9257,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14481,
    shape_pt_lon: -90.024959,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.966,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14482,
    shape_pt_lon: -90.025057,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.9751,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.144881,
    shape_pt_lon: -90.025651,
    shape_pt_sequence: 339,
    shape_dist_traveled: 22.0286,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14498,
    shape_pt_lon: -90.026499,
    shape_pt_sequence: 340,
    shape_dist_traveled: 22.1063,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145012,
    shape_pt_lon: -90.02681,
    shape_pt_sequence: 341,
    shape_dist_traveled: 22.1345,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145156,
    shape_pt_lon: -90.028304,
    shape_pt_sequence: 342,
    shape_dist_traveled: 22.2705,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145214,
    shape_pt_lon: -90.028922,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.3269,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145272,
    shape_pt_lon: -90.029697,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.3971,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145299,
    shape_pt_lon: -90.030039,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.4283,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145401,
    shape_pt_lon: -90.031126,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.527,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145421,
    shape_pt_lon: -90.031328,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.5451,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145497,
    shape_pt_lon: -90.032431,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.6455,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145529,
    shape_pt_lon: -90.032736,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.6727,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145559,
    shape_pt_lon: -90.033142,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.7099,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.1456,
    shape_pt_lon: -90.033522,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.7442,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145639,
    shape_pt_lon: -90.033818,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.7715,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145726,
    shape_pt_lon: -90.034214,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.8089,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.145899,
    shape_pt_lon: -90.03484,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.868,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.146068,
    shape_pt_lon: -90.03544,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.9262,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.146249,
    shape_pt_lon: -90.036106,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.9898,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.146469,
    shape_pt_lon: -90.036975,
    shape_pt_sequence: 357,
    shape_dist_traveled: 23.0714,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.146969,
    shape_pt_lon: -90.038778,
    shape_pt_sequence: 358,
    shape_dist_traveled: 23.2437,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.147557,
    shape_pt_lon: -90.040993,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.4544,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14769,
    shape_pt_lon: -90.041566,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.5085,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.147786,
    shape_pt_lon: -90.041902,
    shape_pt_sequence: 361,
    shape_dist_traveled: 23.541,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.147963,
    shape_pt_lon: -90.042497,
    shape_pt_sequence: 362,
    shape_dist_traveled: 23.5977,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.14822,
    shape_pt_lon: -90.043459,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.6894,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.148239,
    shape_pt_lon: -90.043539,
    shape_pt_sequence: 364,
    shape_dist_traveled: 23.6967,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.148265,
    shape_pt_lon: -90.043631,
    shape_pt_sequence: 365,
    shape_dist_traveled: 23.7062,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.148367,
    shape_pt_lon: -90.044008,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.7419,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.148462,
    shape_pt_lon: -90.044421,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.7805,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.148739,
    shape_pt_lon: -90.045543,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.8871,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.149142,
    shape_pt_lon: -90.047058,
    shape_pt_sequence: 369,
    shape_dist_traveled: 24.0304,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 370,
    shape_dist_traveled: 24.1508,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 371,
    shape_dist_traveled: 24.2685,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 372,
    shape_dist_traveled: 24.3849,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 373,
    shape_dist_traveled: 24.4981,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 374,
    shape_dist_traveled: 24.5079,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 375,
    shape_dist_traveled: 24.5305,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 376,
    shape_dist_traveled: 24.5422,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 377,
    shape_dist_traveled: 24.5893,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 378,
    shape_dist_traveled: 24.6163,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 379,
    shape_dist_traveled: 24.6318,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 380,
    shape_dist_traveled: 24.7361,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 381,
    shape_dist_traveled: 24.8478,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 382,
    shape_dist_traveled: 24.8551,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 383,
    shape_dist_traveled: 24.8604,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 384,
    shape_dist_traveled: 24.8604,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 385,
    shape_dist_traveled: 24.917,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 386,
    shape_dist_traveled: 24.917,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 387,
    shape_dist_traveled: 24.9695,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 388,
    shape_dist_traveled: 25.029,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 389,
    shape_dist_traveled: 25.0878,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 390,
    shape_dist_traveled: 25.1049,
  },
  {
    shape_id: 38246,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 391,
    shape_dist_traveled: 25.1676,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1961,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.272,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.272,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3286,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3286,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3811,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4991,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5589,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5783,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.6233,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6883,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.7453,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8424,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.9754,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9964,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0255,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0602,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.1022,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.114,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1184,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.1568,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.182,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2282,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.2744,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3184,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.3868,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4736,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5127,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5177,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5747,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.5817,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6307,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.6339,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.6489,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.6649,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.727,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.747,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.8261,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8551,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.9571,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.9702,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.0773,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.0973,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.1694,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.2224,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.2864,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.3125,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.3484,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4345,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157539,
    shape_pt_lon: -90.022893,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.4593,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157583,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.4809,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157706,
    shape_pt_lon: -90.022044,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.5386,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157734,
    shape_pt_lon: -90.021912,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.551,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157841,
    shape_pt_lon: -90.021432,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.5956,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157851,
    shape_pt_lon: -90.021387,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.5997,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157925,
    shape_pt_lon: -90.021083,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.6291,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15795,
    shape_pt_lon: -90.020967,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.6393,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157978,
    shape_pt_lon: -90.020836,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.652,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157989,
    shape_pt_lon: -90.020788,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.6561,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158021,
    shape_pt_lon: -90.020649,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.6695,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158073,
    shape_pt_lon: -90.020402,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.6923,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.1581,
    shape_pt_lon: -90.020277,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.7046,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158108,
    shape_pt_lon: -90.020239,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.7078,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158156,
    shape_pt_lon: -90.020019,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.7287,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158181,
    shape_pt_lon: -90.019902,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.7389,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158249,
    shape_pt_lon: -90.019595,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.768,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158288,
    shape_pt_lon: -90.019419,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.7845,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158311,
    shape_pt_lon: -90.019311,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.7949,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158373,
    shape_pt_lon: -90.019036,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.8209,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -90.018203,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.8982,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158577,
    shape_pt_lon: -90.017921,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.9236,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158578,
    shape_pt_lon: -90.017855,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.9296,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158557,
    shape_pt_lon: -90.017437,
    shape_pt_sequence: 78,
    shape_dist_traveled: 2.9676,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158497,
    shape_pt_lon: -90.016646,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.04,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158434,
    shape_pt_lon: -90.015859,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.1113,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158371,
    shape_pt_lon: -90.015061,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.1836,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15831,
    shape_pt_lon: -90.014262,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.256,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15826,
    shape_pt_lon: -90.013457,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.3292,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158206,
    shape_pt_lon: -90.012651,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.4014,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158167,
    shape_pt_lon: -90.012032,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.4576,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158159,
    shape_pt_lon: -90.011854,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.4737,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158156,
    shape_pt_lon: -90.011779,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.4807,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158155,
    shape_pt_lon: -90.011747,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.4837,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158149,
    shape_pt_lon: -90.011428,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.5127,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158169,
    shape_pt_lon: -90.011021,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.5497,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158195,
    shape_pt_lon: -90.010823,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.568,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158279,
    shape_pt_lon: -90.01047,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.6005,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15834,
    shape_pt_lon: -90.010229,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.6236,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158401,
    shape_pt_lon: -90.01004,
    shape_pt_sequence: 94,
    shape_dist_traveled: 3.6417,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158393,
    shape_pt_lon: -90.009921,
    shape_pt_sequence: 95,
    shape_dist_traveled: 3.6527,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158597,
    shape_pt_lon: -90.00915,
    shape_pt_sequence: 96,
    shape_dist_traveled: 3.726,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158879,
    shape_pt_lon: -90.008142,
    shape_pt_sequence: 97,
    shape_dist_traveled: 3.8225,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158916,
    shape_pt_lon: -90.008013,
    shape_pt_sequence: 98,
    shape_dist_traveled: 3.8342,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159163,
    shape_pt_lon: -90.007127,
    shape_pt_sequence: 99,
    shape_dist_traveled: 3.919,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159203,
    shape_pt_lon: -90.00698,
    shape_pt_sequence: 100,
    shape_dist_traveled: 3.9335,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159516,
    shape_pt_lon: -90.005863,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.0395,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159856,
    shape_pt_lon: -90.00464,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.1571,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160219,
    shape_pt_lon: -90.003341,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.2808,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160552,
    shape_pt_lon: -90.002138,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.3962,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160857,
    shape_pt_lon: -90.001043,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.5009,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160876,
    shape_pt_lon: -90.000929,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.5111,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160879,
    shape_pt_lon: -90.000836,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.5191,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160868,
    shape_pt_lon: -90.00065,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.5361,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160791,
    shape_pt_lon: -89.999911,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.6036,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160675,
    shape_pt_lon: -89.998767,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.7074,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160636,
    shape_pt_lon: -89.998397,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.7418,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160551,
    shape_pt_lon: -89.997599,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.8143,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160393,
    shape_pt_lon: -89.996372,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.9268,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160236,
    shape_pt_lon: -89.995127,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.0401,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160079,
    shape_pt_lon: -89.993921,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.1505,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159917,
    shape_pt_lon: -89.99263,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.2689,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159789,
    shape_pt_lon: -89.991611,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.362,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159761,
    shape_pt_lon: -89.991323,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.3881,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159739,
    shape_pt_lon: -89.991096,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.4084,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15973,
    shape_pt_lon: -89.991003,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.4174,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15972,
    shape_pt_lon: -89.990909,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.4255,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159708,
    shape_pt_lon: -89.9908,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.4355,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159649,
    shape_pt_lon: -89.990186,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.492,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159471,
    shape_pt_lon: -89.988348,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.6591,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159292,
    shape_pt_lon: -89.986449,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.8312,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159229,
    shape_pt_lon: -89.985848,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.8867,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159209,
    shape_pt_lon: -89.985731,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.8969,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159181,
    shape_pt_lon: -89.985576,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.9114,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159079,
    shape_pt_lon: -89.985121,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.9539,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158875,
    shape_pt_lon: -89.984216,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.039,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158789,
    shape_pt_lon: -89.983841,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.0742,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158789,
    shape_pt_lon: -89.983843,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.0742,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158767,
    shape_pt_lon: -89.983741,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.0837,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158767,
    shape_pt_lon: -89.98374,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.0837,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158711,
    shape_pt_lon: -89.983501,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.1065,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158687,
    shape_pt_lon: -89.983388,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.1169,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158629,
    shape_pt_lon: -89.983139,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.1397,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158593,
    shape_pt_lon: -89.982983,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.1543,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158322,
    shape_pt_lon: -89.981782,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.2674,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158279,
    shape_pt_lon: -89.981556,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.288,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15818,
    shape_pt_lon: -89.981068,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.3333,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158061,
    shape_pt_lon: -89.980582,
    shape_pt_sequence: 142,
    shape_dist_traveled: 6.3792,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157979,
    shape_pt_lon: -89.980222,
    shape_pt_sequence: 143,
    shape_dist_traveled: 6.4137,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157945,
    shape_pt_lon: -89.980006,
    shape_pt_sequence: 144,
    shape_dist_traveled: 6.4329,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157909,
    shape_pt_lon: -89.979732,
    shape_pt_sequence: 145,
    shape_dist_traveled: 6.4582,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.979582,
    shape_pt_sequence: 146,
    shape_dist_traveled: 6.4724,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15789,
    shape_pt_lon: -89.97945,
    shape_pt_sequence: 147,
    shape_dist_traveled: 6.4844,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157888,
    shape_pt_lon: -89.979082,
    shape_pt_sequence: 148,
    shape_dist_traveled: 6.5174,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157889,
    shape_pt_lon: -89.978479,
    shape_pt_sequence: 149,
    shape_dist_traveled: 6.5714,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157891,
    shape_pt_lon: -89.97805,
    shape_pt_sequence: 150,
    shape_dist_traveled: 6.6104,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157893,
    shape_pt_lon: -89.977449,
    shape_pt_sequence: 151,
    shape_dist_traveled: 6.6644,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157896,
    shape_pt_lon: -89.977046,
    shape_pt_sequence: 152,
    shape_dist_traveled: 6.7014,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.976428,
    shape_pt_sequence: 153,
    shape_dist_traveled: 6.7575,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157899,
    shape_pt_lon: -89.975997,
    shape_pt_sequence: 154,
    shape_dist_traveled: 6.7965,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157906,
    shape_pt_lon: -89.975006,
    shape_pt_sequence: 155,
    shape_dist_traveled: 6.8855,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157909,
    shape_pt_lon: -89.973988,
    shape_pt_sequence: 156,
    shape_dist_traveled: 6.9775,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15791,
    shape_pt_lon: -89.973728,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.0005,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157919,
    shape_pt_lon: -89.972663,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.0975,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157922,
    shape_pt_lon: -89.972376,
    shape_pt_sequence: 159,
    shape_dist_traveled: 7.1235,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157932,
    shape_pt_lon: -89.971913,
    shape_pt_sequence: 160,
    shape_dist_traveled: 7.1645,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157961,
    shape_pt_lon: -89.971649,
    shape_pt_sequence: 161,
    shape_dist_traveled: 7.1887,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.157979,
    shape_pt_lon: -89.971554,
    shape_pt_sequence: 162,
    shape_dist_traveled: 7.1979,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158026,
    shape_pt_lon: -89.971354,
    shape_pt_sequence: 163,
    shape_dist_traveled: 7.2166,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158163,
    shape_pt_lon: -89.970781,
    shape_pt_sequence: 164,
    shape_dist_traveled: 7.271,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158486,
    shape_pt_lon: -89.969401,
    shape_pt_sequence: 165,
    shape_dist_traveled: 7.4001,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158709,
    shape_pt_lon: -89.968425,
    shape_pt_sequence: 166,
    shape_dist_traveled: 7.4916,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.158767,
    shape_pt_lon: -89.968172,
    shape_pt_sequence: 167,
    shape_dist_traveled: 7.5156,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159033,
    shape_pt_lon: -89.967029,
    shape_pt_sequence: 168,
    shape_dist_traveled: 7.6229,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159128,
    shape_pt_lon: -89.966613,
    shape_pt_sequence: 169,
    shape_dist_traveled: 7.6622,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159305,
    shape_pt_lon: -89.965851,
    shape_pt_sequence: 170,
    shape_dist_traveled: 7.734,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.15972,
    shape_pt_lon: -89.964035,
    shape_pt_sequence: 171,
    shape_dist_traveled: 7.9046,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159738,
    shape_pt_lon: -89.963937,
    shape_pt_sequence: 172,
    shape_dist_traveled: 7.9139,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159766,
    shape_pt_lon: -89.96378,
    shape_pt_sequence: 173,
    shape_dist_traveled: 7.9282,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.159962,
    shape_pt_lon: -89.962932,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.0073,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.16002,
    shape_pt_lon: -89.962741,
    shape_pt_sequence: 175,
    shape_dist_traveled: 8.0263,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160031,
    shape_pt_lon: -89.962697,
    shape_pt_sequence: 176,
    shape_dist_traveled: 8.0307,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160131,
    shape_pt_lon: -89.962361,
    shape_pt_sequence: 177,
    shape_dist_traveled: 8.0627,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.160482,
    shape_pt_lon: -89.961376,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.1599,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.161037,
    shape_pt_lon: -89.959828,
    shape_pt_sequence: 179,
    shape_dist_traveled: 8.313,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.161351,
    shape_pt_lon: -89.958953,
    shape_pt_sequence: 180,
    shape_dist_traveled: 8.3998,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.161696,
    shape_pt_lon: -89.957985,
    shape_pt_sequence: 181,
    shape_dist_traveled: 8.4947,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.161883,
    shape_pt_lon: -89.957507,
    shape_pt_sequence: 182,
    shape_dist_traveled: 8.5426,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.162038,
    shape_pt_lon: -89.957158,
    shape_pt_sequence: 183,
    shape_dist_traveled: 8.5793,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.162209,
    shape_pt_lon: -89.956806,
    shape_pt_sequence: 184,
    shape_dist_traveled: 8.6165,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.162461,
    shape_pt_lon: -89.956345,
    shape_pt_sequence: 185,
    shape_dist_traveled: 8.6662,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.162681,
    shape_pt_lon: -89.955979,
    shape_pt_sequence: 186,
    shape_dist_traveled: 8.7076,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.162713,
    shape_pt_lon: -89.955929,
    shape_pt_sequence: 187,
    shape_dist_traveled: 8.7134,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.163019,
    shape_pt_lon: -89.955451,
    shape_pt_sequence: 188,
    shape_dist_traveled: 8.7688,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.164066,
    shape_pt_lon: -89.953818,
    shape_pt_sequence: 189,
    shape_dist_traveled: 8.9567,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.164105,
    shape_pt_lon: -89.953755,
    shape_pt_sequence: 190,
    shape_dist_traveled: 8.9639,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.164459,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 191,
    shape_dist_traveled: 9.028,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.164858,
    shape_pt_lon: -89.952598,
    shape_pt_sequence: 192,
    shape_dist_traveled: 9.0983,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.165027,
    shape_pt_lon: -89.952365,
    shape_pt_sequence: 193,
    shape_dist_traveled: 9.1266,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.165247,
    shape_pt_lon: -89.95206,
    shape_pt_sequence: 194,
    shape_dist_traveled: 9.1635,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.165809,
    shape_pt_lon: -89.951351,
    shape_pt_sequence: 195,
    shape_dist_traveled: 9.2533,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.166434,
    shape_pt_lon: -89.950609,
    shape_pt_sequence: 196,
    shape_dist_traveled: 9.3502,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.166959,
    shape_pt_lon: -89.949982,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.4315,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.167593,
    shape_pt_lon: -89.949219,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.5305,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.16789,
    shape_pt_lon: -89.948847,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.5786,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.16829,
    shape_pt_lon: -89.94831,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.6437,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.168973,
    shape_pt_lon: -89.94733,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.7606,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.16966,
    shape_pt_lon: -89.946356,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.8776,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.170341,
    shape_pt_lon: -89.945378,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.9946,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.171219,
    shape_pt_lon: -89.944133,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.1441,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.171469,
    shape_pt_lon: -89.943789,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.1859,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.171697,
    shape_pt_lon: -89.943494,
    shape_pt_sequence: 206,
    shape_dist_traveled: 10.2227,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.171771,
    shape_pt_lon: -89.943398,
    shape_pt_sequence: 207,
    shape_dist_traveled: 10.234,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.172466,
    shape_pt_lon: -89.942579,
    shape_pt_sequence: 208,
    shape_dist_traveled: 10.3415,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.172603,
    shape_pt_lon: -89.94242,
    shape_pt_sequence: 209,
    shape_dist_traveled: 10.3627,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.17296,
    shape_pt_lon: -89.942066,
    shape_pt_sequence: 210,
    shape_dist_traveled: 10.414,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.173341,
    shape_pt_lon: -89.941742,
    shape_pt_sequence: 211,
    shape_dist_traveled: 10.4656,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.173462,
    shape_pt_lon: -89.941724,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.4796,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.173512,
    shape_pt_lon: -89.941679,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.4868,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.173883,
    shape_pt_lon: -89.94134,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.5382,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.173966,
    shape_pt_lon: -89.941262,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.5496,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.175354,
    shape_pt_lon: -89.93994,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.7458,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.939717,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.7786,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.17562,
    shape_pt_lon: -89.939606,
    shape_pt_sequence: 218,
    shape_dist_traveled: 10.7894,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.175713,
    shape_pt_lon: -89.939286,
    shape_pt_sequence: 219,
    shape_dist_traveled: 10.8201,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.175823,
    shape_pt_lon: -89.939076,
    shape_pt_sequence: 220,
    shape_dist_traveled: 10.8426,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.175932,
    shape_pt_lon: -89.938931,
    shape_pt_sequence: 221,
    shape_dist_traveled: 10.8609,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.17604,
    shape_pt_lon: -89.938825,
    shape_pt_sequence: 222,
    shape_dist_traveled: 10.8766,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.177066,
    shape_pt_lon: -89.938003,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.0133,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.177122,
    shape_pt_lon: -89.937983,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.0194,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.177161,
    shape_pt_lon: -89.937985,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.0234,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.177319,
    shape_pt_lon: -89.938022,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.0417,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.177689,
    shape_pt_lon: -89.937635,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.0956,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.178006,
    shape_pt_lon: -89.937328,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.1406,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.179173,
    shape_pt_lon: -89.936206,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.3066,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.179517,
    shape_pt_lon: -89.935878,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.3544,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.179773,
    shape_pt_lon: -89.935639,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.3908,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.180456,
    shape_pt_lon: -89.935,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.4858,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.181109,
    shape_pt_lon: -89.934444,
    shape_pt_sequence: 233,
    shape_dist_traveled: 11.5751,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.183909,
    shape_pt_lon: -89.932151,
    shape_pt_sequence: 234,
    shape_dist_traveled: 11.9504,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.1848,
    shape_pt_lon: -89.931424,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.0702,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.185407,
    shape_pt_lon: -89.930928,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.1512,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.185866,
    shape_pt_lon: -89.930555,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.2125,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.18618,
    shape_pt_lon: -89.930298,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.2552,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.186619,
    shape_pt_lon: -89.929938,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.3137,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.187723,
    shape_pt_lon: -89.929029,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.4615,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.18861,
    shape_pt_lon: -89.928302,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.5813,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.188707,
    shape_pt_lon: -89.928226,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.5944,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.189068,
    shape_pt_lon: -89.92793,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.6421,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.189108,
    shape_pt_lon: -89.927814,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.6542,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.189253,
    shape_pt_lon: -89.927681,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.6742,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.189693,
    shape_pt_lon: -89.927321,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.7327,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.189858,
    shape_pt_lon: -89.927191,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.7552,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.190148,
    shape_pt_lon: -89.927002,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.7914,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.190289,
    shape_pt_lon: -89.926916,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.8093,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.190406,
    shape_pt_lon: -89.926851,
    shape_pt_sequence: 250,
    shape_dist_traveled: 12.8236,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.190509,
    shape_pt_lon: -89.926787,
    shape_pt_sequence: 251,
    shape_dist_traveled: 12.8357,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.19061,
    shape_pt_lon: -89.926733,
    shape_pt_sequence: 252,
    shape_dist_traveled: 12.8487,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.190867,
    shape_pt_lon: -89.926612,
    shape_pt_sequence: 253,
    shape_dist_traveled: 12.8797,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.191001,
    shape_pt_lon: -89.926558,
    shape_pt_sequence: 254,
    shape_dist_traveled: 12.8955,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.19115,
    shape_pt_lon: -89.926506,
    shape_pt_sequence: 255,
    shape_dist_traveled: 12.9123,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.191377,
    shape_pt_lon: -89.926435,
    shape_pt_sequence: 256,
    shape_dist_traveled: 12.939,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.192486,
    shape_pt_lon: -89.926213,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.0646,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.192584,
    shape_pt_lon: -89.926198,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.0756,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.192702,
    shape_pt_lon: -89.926179,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.0888,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.192808,
    shape_pt_lon: -89.92616,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.1009,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.193032,
    shape_pt_lon: -89.926109,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.1263,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.193725,
    shape_pt_lon: -89.925935,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.2049,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.194869,
    shape_pt_lon: -89.925677,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.3349,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.195559,
    shape_pt_lon: -89.925518,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.4142,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.196005,
    shape_pt_lon: -89.92541,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.4652,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.196344,
    shape_pt_lon: -89.925298,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.5045,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.196989,
    shape_pt_lon: -89.925152,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.5776,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.197737,
    shape_pt_lon: -89.92499,
    shape_pt_sequence: 268,
    shape_dist_traveled: 13.6628,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.198091,
    shape_pt_lon: -89.924931,
    shape_pt_sequence: 269,
    shape_dist_traveled: 13.7023,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.198459,
    shape_pt_lon: -89.924848,
    shape_pt_sequence: 270,
    shape_dist_traveled: 13.7439,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.198871,
    shape_pt_lon: -89.924755,
    shape_pt_sequence: 271,
    shape_dist_traveled: 13.7905,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.20064,
    shape_pt_lon: -89.924362,
    shape_pt_sequence: 272,
    shape_dist_traveled: 13.9918,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.202353,
    shape_pt_lon: -89.92402,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.1861,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.202742,
    shape_pt_lon: -89.923939,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.2307,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.202919,
    shape_pt_lon: -89.923832,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.253,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.203163,
    shape_pt_lon: -89.923723,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.2818,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.204504,
    shape_pt_lon: -89.923311,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.4363,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.204751,
    shape_pt_lon: -89.923242,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.465,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.204967,
    shape_pt_lon: -89.923156,
    shape_pt_sequence: 279,
    shape_dist_traveled: 14.4903,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.20503,
    shape_pt_lon: -89.923123,
    shape_pt_sequence: 280,
    shape_dist_traveled: 14.4979,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205083,
    shape_pt_lon: -89.923077,
    shape_pt_sequence: 281,
    shape_dist_traveled: 14.5051,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205133,
    shape_pt_lon: -89.923001,
    shape_pt_sequence: 282,
    shape_dist_traveled: 14.5137,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.2052,
    shape_pt_lon: -89.92284,
    shape_pt_sequence: 283,
    shape_dist_traveled: 14.5298,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205276,
    shape_pt_lon: -89.922495,
    shape_pt_sequence: 284,
    shape_dist_traveled: 14.5621,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205251,
    shape_pt_lon: -89.921838,
    shape_pt_sequence: 285,
    shape_dist_traveled: 14.6222,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205315,
    shape_pt_lon: -89.921707,
    shape_pt_sequence: 286,
    shape_dist_traveled: 14.6352,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921608,
    shape_pt_sequence: 287,
    shape_dist_traveled: 14.6443,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921546,
    shape_pt_sequence: 288,
    shape_dist_traveled: 14.6503,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.205641,
    shape_pt_lon: -89.921541,
    shape_pt_sequence: 289,
    shape_dist_traveled: 14.6873,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.207347,
    shape_pt_lon: -89.921516,
    shape_pt_sequence: 290,
    shape_dist_traveled: 14.8783,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.20754,
    shape_pt_lon: -89.921506,
    shape_pt_sequence: 291,
    shape_dist_traveled: 14.9003,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.207679,
    shape_pt_lon: -89.921459,
    shape_pt_sequence: 292,
    shape_dist_traveled: 14.9168,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.207827,
    shape_pt_lon: -89.921425,
    shape_pt_sequence: 293,
    shape_dist_traveled: 14.9331,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.208243,
    shape_pt_lon: -89.921374,
    shape_pt_sequence: 294,
    shape_dist_traveled: 14.9803,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.20852,
    shape_pt_lon: -89.921292,
    shape_pt_sequence: 295,
    shape_dist_traveled: 15.0121,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.208567,
    shape_pt_lon: -89.921272,
    shape_pt_sequence: 296,
    shape_dist_traveled: 15.0175,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.208818,
    shape_pt_lon: -89.92115,
    shape_pt_sequence: 297,
    shape_dist_traveled: 15.0476,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.209062,
    shape_pt_lon: -89.920998,
    shape_pt_sequence: 298,
    shape_dist_traveled: 15.078,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.209309,
    shape_pt_lon: -89.920791,
    shape_pt_sequence: 299,
    shape_dist_traveled: 15.1113,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.209502,
    shape_pt_lon: -89.920627,
    shape_pt_sequence: 300,
    shape_dist_traveled: 15.1379,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.209781,
    shape_pt_lon: -89.920422,
    shape_pt_sequence: 301,
    shape_dist_traveled: 15.1743,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.209986,
    shape_pt_lon: -89.920192,
    shape_pt_sequence: 302,
    shape_dist_traveled: 15.2047,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210098,
    shape_pt_lon: -89.92007,
    shape_pt_sequence: 303,
    shape_dist_traveled: 15.221,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210329,
    shape_pt_lon: -89.919823,
    shape_pt_sequence: 304,
    shape_dist_traveled: 15.2557,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210359,
    shape_pt_lon: -89.919802,
    shape_pt_sequence: 305,
    shape_dist_traveled: 15.2602,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210476,
    shape_pt_lon: -89.919721,
    shape_pt_sequence: 306,
    shape_dist_traveled: 15.275,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210559,
    shape_pt_lon: -89.919648,
    shape_pt_sequence: 307,
    shape_dist_traveled: 15.2858,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210637,
    shape_pt_lon: -89.919626,
    shape_pt_sequence: 308,
    shape_dist_traveled: 15.295,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210707,
    shape_pt_lon: -89.91955,
    shape_pt_sequence: 309,
    shape_dist_traveled: 15.3056,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.210828,
    shape_pt_lon: -89.919429,
    shape_pt_sequence: 310,
    shape_dist_traveled: 15.3227,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.211208,
    shape_pt_lon: -89.919042,
    shape_pt_sequence: 311,
    shape_dist_traveled: 15.3781,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.211343,
    shape_pt_lon: -89.918908,
    shape_pt_sequence: 312,
    shape_dist_traveled: 15.3973,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.211702,
    shape_pt_lon: -89.918551,
    shape_pt_sequence: 313,
    shape_dist_traveled: 15.4485,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.212021,
    shape_pt_lon: -89.918236,
    shape_pt_sequence: 314,
    shape_dist_traveled: 15.4948,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.212167,
    shape_pt_lon: -89.918091,
    shape_pt_sequence: 315,
    shape_dist_traveled: 15.5154,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.212217,
    shape_pt_lon: -89.918042,
    shape_pt_sequence: 316,
    shape_dist_traveled: 15.5226,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.212335,
    shape_pt_lon: -89.917926,
    shape_pt_sequence: 317,
    shape_dist_traveled: 15.5396,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.212809,
    shape_pt_lon: -89.917454,
    shape_pt_sequence: 318,
    shape_dist_traveled: 15.6073,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.213659,
    shape_pt_lon: -89.916607,
    shape_pt_sequence: 319,
    shape_dist_traveled: 15.7289,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.214214,
    shape_pt_lon: -89.916057,
    shape_pt_sequence: 320,
    shape_dist_traveled: 15.8093,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.215377,
    shape_pt_lon: -89.914903,
    shape_pt_sequence: 321,
    shape_dist_traveled: 15.9758,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.217119,
    shape_pt_lon: -89.91317,
    shape_pt_sequence: 322,
    shape_dist_traveled: 16.2255,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.217299,
    shape_pt_lon: -89.91299,
    shape_pt_sequence: 323,
    shape_dist_traveled: 16.2512,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.218097,
    shape_pt_lon: -89.912202,
    shape_pt_sequence: 324,
    shape_dist_traveled: 16.3658,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.218346,
    shape_pt_lon: -89.911954,
    shape_pt_sequence: 325,
    shape_dist_traveled: 16.4014,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.219228,
    shape_pt_lon: -89.911078,
    shape_pt_sequence: 326,
    shape_dist_traveled: 16.5273,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.220387,
    shape_pt_lon: -89.909927,
    shape_pt_sequence: 327,
    shape_dist_traveled: 16.6938,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.221249,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 328,
    shape_dist_traveled: 16.8176,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.221727,
    shape_pt_lon: -89.908594,
    shape_pt_sequence: 329,
    shape_dist_traveled: 16.8866,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.221821,
    shape_pt_lon: -89.908497,
    shape_pt_sequence: 330,
    shape_dist_traveled: 16.9001,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.222213,
    shape_pt_lon: -89.908116,
    shape_pt_sequence: 331,
    shape_dist_traveled: 16.9557,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.22352,
    shape_pt_lon: -89.906812,
    shape_pt_sequence: 332,
    shape_dist_traveled: 17.1434,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.227323,
    shape_pt_lon: -89.90303,
    shape_pt_sequence: 333,
    shape_dist_traveled: 17.6892,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.227613,
    shape_pt_lon: -89.902741,
    shape_pt_sequence: 334,
    shape_dist_traveled: 17.7305,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.227721,
    shape_pt_lon: -89.902633,
    shape_pt_sequence: 335,
    shape_dist_traveled: 17.7461,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.228013,
    shape_pt_lon: -89.902338,
    shape_pt_sequence: 336,
    shape_dist_traveled: 17.7887,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.229549,
    shape_pt_lon: -89.900813,
    shape_pt_sequence: 337,
    shape_dist_traveled: 18.0086,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.230077,
    shape_pt_lon: -89.90029,
    shape_pt_sequence: 338,
    shape_dist_traveled: 18.0841,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.230979,
    shape_pt_lon: -89.89939,
    shape_pt_sequence: 339,
    shape_dist_traveled: 18.2135,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.231605,
    shape_pt_lon: -89.89877,
    shape_pt_sequence: 340,
    shape_dist_traveled: 18.3032,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.231761,
    shape_pt_lon: -89.898612,
    shape_pt_sequence: 341,
    shape_dist_traveled: 18.326,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.232678,
    shape_pt_lon: -89.897699,
    shape_pt_sequence: 342,
    shape_dist_traveled: 18.4576,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233334,
    shape_pt_lon: -89.897041,
    shape_pt_sequence: 343,
    shape_dist_traveled: 18.5521,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234265,
    shape_pt_lon: -89.89612,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.6854,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234349,
    shape_pt_lon: -89.896037,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.6974,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234451,
    shape_pt_lon: -89.895936,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.7116,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234501,
    shape_pt_lon: -89.895771,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.7278,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234588,
    shape_pt_lon: -89.895499,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.7538,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234615,
    shape_pt_lon: -89.895309,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.772,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.23459,
    shape_pt_lon: -89.894884,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.8101,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.234461,
    shape_pt_lon: -89.894715,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.8306,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233997,
    shape_pt_lon: -89.894149,
    shape_pt_sequence: 352,
    shape_dist_traveled: 18.9035,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233971,
    shape_pt_lon: -89.894021,
    shape_pt_sequence: 353,
    shape_dist_traveled: 18.9159,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233809,
    shape_pt_lon: -89.893808,
    shape_pt_sequence: 354,
    shape_dist_traveled: 18.942,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233503,
    shape_pt_lon: -89.893445,
    shape_pt_sequence: 355,
    shape_dist_traveled: 18.9894,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233376,
    shape_pt_lon: -89.893308,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.0078,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.233144,
    shape_pt_lon: -89.89309,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.0406,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.23285,
    shape_pt_lon: -89.892862,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.0798,
  },
  {
    shape_id: 38247,
    shape_pt_lat: 35.232656,
    shape_pt_lon: -89.892739,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.1044,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.233144,
    shape_pt_lon: -89.89309,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.23285,
    shape_pt_lon: -89.892862,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0391,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.232656,
    shape_pt_lon: -89.892739,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0637,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.232373,
    shape_pt_lon: -89.892588,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0986,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.232057,
    shape_pt_lon: -89.892437,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.136,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.231786,
    shape_pt_lon: -89.892344,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1673,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.231435,
    shape_pt_lon: -89.892258,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2071,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.231102,
    shape_pt_lon: -89.892219,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2452,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.230716,
    shape_pt_lon: -89.892213,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2882,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.230446,
    shape_pt_lon: -89.892237,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.3183,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.230157,
    shape_pt_lon: -89.89229,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3517,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.229943,
    shape_pt_lon: -89.892339,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.375,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.229507,
    shape_pt_lon: -89.892442,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.425,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.229429,
    shape_pt_lon: -89.892463,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4343,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.229367,
    shape_pt_lon: -89.892481,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.4413,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.229226,
    shape_pt_lon: -89.892508,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.4576,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.228739,
    shape_pt_lon: -89.892628,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5125,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22857,
    shape_pt_lon: -89.892666,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5319,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.228361,
    shape_pt_lon: -89.892721,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.5565,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.228251,
    shape_pt_lon: -89.892748,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.5686,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.227972,
    shape_pt_lon: -89.892817,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.6002,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.227324,
    shape_pt_lon: -89.892976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.6745,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.226768,
    shape_pt_lon: -89.893113,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.7379,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.226521,
    shape_pt_lon: -89.893195,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.7667,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22632,
    shape_pt_lon: -89.893279,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.7901,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22545,
    shape_pt_lon: -89.893714,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.8947,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22517,
    shape_pt_lon: -89.893833,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.9282,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.224772,
    shape_pt_lon: -89.893969,
    shape_pt_sequence: 28,
    shape_dist_traveled: 0.9738,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.224452,
    shape_pt_lon: -89.894043,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.0105,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.224146,
    shape_pt_lon: -89.894083,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.0447,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.224059,
    shape_pt_lon: -89.894092,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.0547,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.223148,
    shape_pt_lon: -89.894128,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.1568,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.222319,
    shape_pt_lon: -89.894154,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.2498,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.222189,
    shape_pt_lon: -89.894159,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.2638,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221539,
    shape_pt_lon: -89.894181,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.3369,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221403,
    shape_pt_lon: -89.894186,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.3519,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221339,
    shape_pt_lon: -89.894187,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.3599,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221352,
    shape_pt_lon: -89.894357,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.376,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221399,
    shape_pt_lon: -89.894456,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.3863,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.2214,
    shape_pt_lon: -89.894531,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.3933,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221434,
    shape_pt_lon: -89.895658,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.4944,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221476,
    shape_pt_lon: -89.897164,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.6304,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221488,
    shape_pt_lon: -89.897661,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.6754,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221533,
    shape_pt_lon: -89.899518,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.8435,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22157,
    shape_pt_lon: -89.900993,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.9766,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221572,
    shape_pt_lon: -89.9012,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.9946,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221517,
    shape_pt_lon: -89.90132,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.0071,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221519,
    shape_pt_lon: -89.901361,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.0111,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.22152,
    shape_pt_lon: -89.901411,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.0161,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221577,
    shape_pt_lon: -89.902996,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.1593,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221587,
    shape_pt_lon: -89.903337,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.1893,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221613,
    shape_pt_lon: -89.904041,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.2533,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221666,
    shape_pt_lon: -89.904956,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.3365,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221691,
    shape_pt_lon: -89.906249,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.4526,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221689,
    shape_pt_lon: -89.906635,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.4876,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221687,
    shape_pt_lon: -89.906789,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.5016,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221711,
    shape_pt_lon: -89.907922,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.6036,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221727,
    shape_pt_lon: -89.908594,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.6647,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.221249,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.7337,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.220387,
    shape_pt_lon: -89.909927,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.8576,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.219228,
    shape_pt_lon: -89.911078,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.024,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.218346,
    shape_pt_lon: -89.911954,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.1499,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.218097,
    shape_pt_lon: -89.912202,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.1855,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.217299,
    shape_pt_lon: -89.91299,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.3002,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.217119,
    shape_pt_lon: -89.91317,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.3258,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.215377,
    shape_pt_lon: -89.914903,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5755,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.214214,
    shape_pt_lon: -89.916057,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.742,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.213659,
    shape_pt_lon: -89.916607,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.8224,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.212809,
    shape_pt_lon: -89.917454,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.9441,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.212335,
    shape_pt_lon: -89.917926,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.0117,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.212217,
    shape_pt_lon: -89.918042,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.0287,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.212167,
    shape_pt_lon: -89.918091,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.0359,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.212021,
    shape_pt_lon: -89.918236,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.0565,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.211702,
    shape_pt_lon: -89.918551,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.1028,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.211343,
    shape_pt_lon: -89.918908,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.154,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.211208,
    shape_pt_lon: -89.919042,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.1732,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.210908,
    shape_pt_lon: -89.919574,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.232,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.210807,
    shape_pt_lon: -89.91973,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.2498,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.210182,
    shape_pt_lon: -89.920708,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.3623,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.210026,
    shape_pt_lon: -89.920938,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.3899,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209982,
    shape_pt_lon: -89.921002,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.3977,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209833,
    shape_pt_lon: -89.921167,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.4197,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209691,
    shape_pt_lon: -89.921271,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.438,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209562,
    shape_pt_lon: -89.921329,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4538,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209376,
    shape_pt_lon: -89.921373,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.4752,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.209007,
    shape_pt_lon: -89.921419,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.5164,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.208849,
    shape_pt_lon: -89.921438,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.5345,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.208693,
    shape_pt_lon: -89.921454,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.5516,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.20826,
    shape_pt_lon: -89.92149,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.5996,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.207966,
    shape_pt_lon: -89.921528,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.6329,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.207754,
    shape_pt_lon: -89.921527,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.6569,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.20754,
    shape_pt_lon: -89.921506,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.681,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.207347,
    shape_pt_lon: -89.921516,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.703,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205641,
    shape_pt_lon: -89.921541,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.894,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921546,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.931,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205309,
    shape_pt_lon: -89.921608,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.937,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205315,
    shape_pt_lon: -89.921707,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.9461,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205251,
    shape_pt_lon: -89.921838,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.9591,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205276,
    shape_pt_lon: -89.922495,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.0192,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205284,
    shape_pt_lon: -89.922931,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.0582,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205297,
    shape_pt_lon: -89.923384,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.0992,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205299,
    shape_pt_lon: -89.923478,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.1072,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205263,
    shape_pt_lon: -89.923538,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.1144,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.205221,
    shape_pt_lon: -89.92361,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.1222,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.204789,
    shape_pt_lon: -89.923728,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.1715,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.204639,
    shape_pt_lon: -89.923762,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.1888,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.203522,
    shape_pt_lon: -89.923983,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.3153,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.202468,
    shape_pt_lon: -89.924219,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.4352,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.202397,
    shape_pt_lon: -89.924235,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.4433,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.200592,
    shape_pt_lon: -89.92459,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.6478,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.19861,
    shape_pt_lon: -89.925031,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.8734,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.198393,
    shape_pt_lon: -89.92508,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.8977,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.197741,
    shape_pt_lon: -89.925249,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.9722,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.197277,
    shape_pt_lon: -89.925348,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.025,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.19637,
    shape_pt_lon: -89.925549,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.1286,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.196249,
    shape_pt_lon: -89.925576,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.1417,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.195678,
    shape_pt_lon: -89.925661,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.2062,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.194837,
    shape_pt_lon: -89.925846,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.3016,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.193685,
    shape_pt_lon: -89.926105,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.4328,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.192868,
    shape_pt_lon: -89.926323,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.5267,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.19281,
    shape_pt_lon: -89.926338,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.533,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.192717,
    shape_pt_lon: -89.926359,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.5431,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.19259,
    shape_pt_lon: -89.926384,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5584,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.192371,
    shape_pt_lon: -89.926427,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.5826,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.191435,
    shape_pt_lon: -89.926603,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.6888,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.191133,
    shape_pt_lon: -89.926688,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.7237,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190948,
    shape_pt_lon: -89.92675,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.7443,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.19084,
    shape_pt_lon: -89.92679,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.7579,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190642,
    shape_pt_lon: -89.926878,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.7813,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190539,
    shape_pt_lon: -89.926928,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.793,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190447,
    shape_pt_lon: -89.926968,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.8048,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190352,
    shape_pt_lon: -89.927006,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.8152,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190203,
    shape_pt_lon: -89.927105,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.8344,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.190135,
    shape_pt_lon: -89.927129,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.842,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.189992,
    shape_pt_lon: -89.927235,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.8604,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.189872,
    shape_pt_lon: -89.927339,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.877,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.189739,
    shape_pt_lon: -89.927443,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.8951,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.189167,
    shape_pt_lon: -89.92792,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.9722,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.189068,
    shape_pt_lon: -89.92793,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.9832,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.188707,
    shape_pt_lon: -89.928226,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.0309,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.18861,
    shape_pt_lon: -89.928302,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.044,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.187723,
    shape_pt_lon: -89.929029,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.1638,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.186619,
    shape_pt_lon: -89.929938,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.3116,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.18618,
    shape_pt_lon: -89.930298,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.3701,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.185866,
    shape_pt_lon: -89.930555,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.4129,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.185407,
    shape_pt_lon: -89.930928,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.4741,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.1848,
    shape_pt_lon: -89.931424,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.5551,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.183909,
    shape_pt_lon: -89.932151,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.675,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.181109,
    shape_pt_lon: -89.934444,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.0502,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.180456,
    shape_pt_lon: -89.935,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.1395,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.179773,
    shape_pt_lon: -89.935639,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.2345,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.179517,
    shape_pt_lon: -89.935878,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.2709,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.179173,
    shape_pt_lon: -89.936206,
    shape_pt_sequence: 153,
    shape_dist_traveled: 8.3187,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.178006,
    shape_pt_lon: -89.937328,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.4848,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.177689,
    shape_pt_lon: -89.937635,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.5298,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.177319,
    shape_pt_lon: -89.938022,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.5837,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.177009,
    shape_pt_lon: -89.938348,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.6291,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.17622,
    shape_pt_lon: -89.939111,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.7409,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.175589,
    shape_pt_lon: -89.939717,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.8308,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.175354,
    shape_pt_lon: -89.93994,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.8636,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.173966,
    shape_pt_lon: -89.941262,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.0598,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.173883,
    shape_pt_lon: -89.94134,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.0712,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.173512,
    shape_pt_lon: -89.941679,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.1226,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.173462,
    shape_pt_lon: -89.941724,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.1298,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.173422,
    shape_pt_lon: -89.941837,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.141,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.172894,
    shape_pt_lon: -89.942318,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.214,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.17255,
    shape_pt_lon: -89.942691,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.2649,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.172443,
    shape_pt_lon: -89.942811,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.2812,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.172256,
    shape_pt_lon: -89.943025,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.3095,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.171793,
    shape_pt_lon: -89.94359,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.3824,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.171302,
    shape_pt_lon: -89.944243,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.463,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.170436,
    shape_pt_lon: -89.945481,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.6112,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.169748,
    shape_pt_lon: -89.946461,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.7281,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.169059,
    shape_pt_lon: -89.947445,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.8458,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.1689,
    shape_pt_lon: -89.947672,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.8727,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.168395,
    shape_pt_lon: -89.94839,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.9592,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.168213,
    shape_pt_lon: -89.948647,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.9897,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.167679,
    shape_pt_lon: -89.949334,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.0759,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.16704,
    shape_pt_lon: -89.950094,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.1757,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.16658,
    shape_pt_lon: -89.950642,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.2464,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.16591,
    shape_pt_lon: -89.951442,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.3504,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.165561,
    shape_pt_lon: -89.951875,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.4062,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.165132,
    shape_pt_lon: -89.952443,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.4763,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.164882,
    shape_pt_lon: -89.952798,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.5188,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.164533,
    shape_pt_lon: -89.953329,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.5813,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.164178,
    shape_pt_lon: -89.953885,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.6447,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.163119,
    shape_pt_lon: -89.955541,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.8354,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.162812,
    shape_pt_lon: -89.95602,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.891,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.162565,
    shape_pt_lon: -89.956425,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.9366,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.16243,
    shape_pt_lon: -89.956659,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.9624,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.162143,
    shape_pt_lon: -89.957234,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.0234,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.161999,
    shape_pt_lon: -89.957566,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.0579,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.161856,
    shape_pt_lon: -89.957922,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.0937,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.161456,
    shape_pt_lon: -89.959032,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.2034,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.161156,
    shape_pt_lon: -89.959868,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.2862,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160596,
    shape_pt_lon: -89.961431,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.4406,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160163,
    shape_pt_lon: -89.962633,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.5602,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160143,
    shape_pt_lon: -89.962691,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.5655,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160129,
    shape_pt_lon: -89.962738,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.5697,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160057,
    shape_pt_lon: -89.962956,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.5912,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159977,
    shape_pt_lon: -89.963312,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.6244,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.1599,
    shape_pt_lon: -89.963641,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.6558,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159771,
    shape_pt_lon: -89.964131,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.7023,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159197,
    shape_pt_lon: -89.966612,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.9352,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.1591,
    shape_pt_lon: -89.967021,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.9738,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158837,
    shape_pt_lon: -89.968161,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.0811,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15877,
    shape_pt_lon: -89.968448,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.108,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158613,
    shape_pt_lon: -89.969125,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.1716,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158547,
    shape_pt_lon: -89.969421,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.1995,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158253,
    shape_pt_lon: -89.970694,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.3182,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158108,
    shape_pt_lon: -89.971347,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.3793,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158064,
    shape_pt_lon: -89.971557,
    shape_pt_sequence: 212,
    shape_dist_traveled: 12.399,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158051,
    shape_pt_lon: -89.97162,
    shape_pt_sequence: 213,
    shape_dist_traveled: 12.4053,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158013,
    shape_pt_lon: -89.97204,
    shape_pt_sequence: 214,
    shape_dist_traveled: 12.4435,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158008,
    shape_pt_lon: -89.972365,
    shape_pt_sequence: 215,
    shape_dist_traveled: 12.4725,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158006,
    shape_pt_lon: -89.972661,
    shape_pt_sequence: 216,
    shape_dist_traveled: 12.4995,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158001,
    shape_pt_lon: -89.973726,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.5955,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158,
    shape_pt_lon: -89.973977,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.6185,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157995,
    shape_pt_lon: -89.974993,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.7105,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157989,
    shape_pt_lon: -89.975919,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.7935,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157988,
    shape_pt_lon: -89.97598,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.7995,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157987,
    shape_pt_lon: -89.976082,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.8085,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157987,
    shape_pt_lon: -89.976426,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.8395,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157985,
    shape_pt_lon: -89.977034,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.8945,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157982,
    shape_pt_lon: -89.977448,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.9325,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15798,
    shape_pt_lon: -89.978039,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.9855,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157979,
    shape_pt_lon: -89.978478,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.0255,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157976,
    shape_pt_lon: -89.979069,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.0786,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157973,
    shape_pt_lon: -89.979301,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.0996,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157995,
    shape_pt_lon: -89.979578,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.1246,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158026,
    shape_pt_lon: -89.979879,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.1519,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158089,
    shape_pt_lon: -89.980203,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.1818,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15817,
    shape_pt_lon: -89.980577,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.2169,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158388,
    shape_pt_lon: -89.981551,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.3081,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15874,
    shape_pt_lon: -89.983097,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.4535,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158796,
    shape_pt_lon: -89.983347,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.4775,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158911,
    shape_pt_lon: -89.983858,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.5251,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158989,
    shape_pt_lon: -89.984202,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.5573,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159196,
    shape_pt_lon: -89.985108,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.6425,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15927,
    shape_pt_lon: -89.985433,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.6726,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159331,
    shape_pt_lon: -89.985713,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.6985,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15935,
    shape_pt_lon: -89.985842,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.7107,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159411,
    shape_pt_lon: -89.986479,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.7691,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159588,
    shape_pt_lon: -89.988316,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.9353,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159788,
    shape_pt_lon: -89.990423,
    shape_pt_sequence: 245,
    shape_dist_traveled: 14.1276,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159798,
    shape_pt_lon: -89.990531,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.1377,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159824,
    shape_pt_lon: -89.990836,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.1648,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159844,
    shape_pt_lon: -89.99106,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.1849,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159852,
    shape_pt_lon: -89.991146,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.193,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15989,
    shape_pt_lon: -89.991568,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.2312,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160014,
    shape_pt_lon: -89.992616,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.3272,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.16018,
    shape_pt_lon: -89.993892,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.4438,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160337,
    shape_pt_lon: -89.995112,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.5551,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160495,
    shape_pt_lon: -89.996358,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.6695,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160651,
    shape_pt_lon: -89.997588,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.7818,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160736,
    shape_pt_lon: -89.998388,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.8544,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160773,
    shape_pt_lon: -89.998747,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.8877,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160892,
    shape_pt_lon: -89.999899,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.9926,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160985,
    shape_pt_lon: -90.000877,
    shape_pt_sequence: 259,
    shape_dist_traveled: 15.0811,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160973,
    shape_pt_lon: -90.001006,
    shape_pt_sequence: 260,
    shape_dist_traveled: 15.0932,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160926,
    shape_pt_lon: -90.001181,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.1099,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160663,
    shape_pt_lon: -90.002126,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.2001,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.160328,
    shape_pt_lon: -90.003327,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.3142,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159964,
    shape_pt_lon: -90.004629,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.4391,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159627,
    shape_pt_lon: -90.005846,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.5555,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159313,
    shape_pt_lon: -90.006967,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.6624,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15927,
    shape_pt_lon: -90.007127,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.6782,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.159018,
    shape_pt_lon: -90.008032,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.7639,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158987,
    shape_pt_lon: -90.008145,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.7756,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158702,
    shape_pt_lon: -90.00915,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.8712,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158481,
    shape_pt_lon: -90.00996,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.9493,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158401,
    shape_pt_lon: -90.01004,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.9607,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15834,
    shape_pt_lon: -90.010229,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.9787,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158279,
    shape_pt_lon: -90.01047,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.0018,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158195,
    shape_pt_lon: -90.010823,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.0344,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158169,
    shape_pt_lon: -90.011021,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.0526,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158149,
    shape_pt_lon: -90.011428,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.0897,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158155,
    shape_pt_lon: -90.011747,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.1187,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158156,
    shape_pt_lon: -90.011779,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.1217,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158159,
    shape_pt_lon: -90.011854,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.1287,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158167,
    shape_pt_lon: -90.012032,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.1447,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158206,
    shape_pt_lon: -90.012651,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.2009,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15826,
    shape_pt_lon: -90.013457,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.2732,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15831,
    shape_pt_lon: -90.014262,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.3463,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158371,
    shape_pt_lon: -90.015061,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.4187,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158434,
    shape_pt_lon: -90.015859,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.491,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158497,
    shape_pt_lon: -90.016646,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.5624,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158557,
    shape_pt_lon: -90.017437,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.6347,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158578,
    shape_pt_lon: -90.017855,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.6728,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158577,
    shape_pt_lon: -90.017921,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.6788,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -90.018203,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.7041,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158373,
    shape_pt_lon: -90.019036,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.7814,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158311,
    shape_pt_lon: -90.019311,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.8074,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158288,
    shape_pt_lon: -90.019419,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.8178,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158249,
    shape_pt_lon: -90.019595,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.8343,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158181,
    shape_pt_lon: -90.019902,
    shape_pt_sequence: 296,
    shape_dist_traveled: 16.8635,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158156,
    shape_pt_lon: -90.020019,
    shape_pt_sequence: 297,
    shape_dist_traveled: 16.8737,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158108,
    shape_pt_lon: -90.020239,
    shape_pt_sequence: 298,
    shape_dist_traveled: 16.8945,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.1581,
    shape_pt_lon: -90.020277,
    shape_pt_sequence: 299,
    shape_dist_traveled: 16.8977,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158073,
    shape_pt_lon: -90.020402,
    shape_pt_sequence: 300,
    shape_dist_traveled: 16.9101,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.158021,
    shape_pt_lon: -90.020649,
    shape_pt_sequence: 301,
    shape_dist_traveled: 16.9329,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157989,
    shape_pt_lon: -90.020788,
    shape_pt_sequence: 302,
    shape_dist_traveled: 16.9462,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157978,
    shape_pt_lon: -90.020836,
    shape_pt_sequence: 303,
    shape_dist_traveled: 16.9503,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15795,
    shape_pt_lon: -90.020967,
    shape_pt_sequence: 304,
    shape_dist_traveled: 16.963,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157925,
    shape_pt_lon: -90.021083,
    shape_pt_sequence: 305,
    shape_dist_traveled: 16.9732,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157851,
    shape_pt_lon: -90.021387,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.0026,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157841,
    shape_pt_lon: -90.021432,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.0067,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157734,
    shape_pt_lon: -90.021912,
    shape_pt_sequence: 308,
    shape_dist_traveled: 17.0514,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157706,
    shape_pt_lon: -90.022044,
    shape_pt_sequence: 309,
    shape_dist_traveled: 17.0637,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157583,
    shape_pt_lon: -90.022664,
    shape_pt_sequence: 310,
    shape_dist_traveled: 17.1215,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157539,
    shape_pt_lon: -90.022893,
    shape_pt_sequence: 311,
    shape_dist_traveled: 17.143,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15748,
    shape_pt_lon: -90.023166,
    shape_pt_sequence: 312,
    shape_dist_traveled: 17.1678,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157279,
    shape_pt_lon: -90.024077,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.2539,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15721,
    shape_pt_lon: -90.024463,
    shape_pt_sequence: 314,
    shape_dist_traveled: 17.2898,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157188,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 315,
    shape_dist_traveled: 17.3159,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157177,
    shape_pt_lon: -90.025469,
    shape_pt_sequence: 316,
    shape_dist_traveled: 17.3799,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157166,
    shape_pt_lon: -90.026053,
    shape_pt_sequence: 317,
    shape_dist_traveled: 17.4329,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15713,
    shape_pt_lon: -90.026844,
    shape_pt_sequence: 318,
    shape_dist_traveled: 17.505,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157117,
    shape_pt_lon: -90.02707,
    shape_pt_sequence: 319,
    shape_dist_traveled: 17.5251,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157078,
    shape_pt_lon: -90.028259,
    shape_pt_sequence: 320,
    shape_dist_traveled: 17.6321,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157072,
    shape_pt_lon: -90.028398,
    shape_pt_sequence: 321,
    shape_dist_traveled: 17.6452,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157049,
    shape_pt_lon: -90.029525,
    shape_pt_sequence: 322,
    shape_dist_traveled: 17.7472,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157048,
    shape_pt_lon: -90.029847,
    shape_pt_sequence: 323,
    shape_dist_traveled: 17.7762,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157014,
    shape_pt_lon: -90.030721,
    shape_pt_sequence: 324,
    shape_dist_traveled: 17.8553,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.157006,
    shape_pt_lon: -90.030943,
    shape_pt_sequence: 325,
    shape_dist_traveled: 17.8754,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156977,
    shape_pt_lon: -90.031629,
    shape_pt_sequence: 326,
    shape_dist_traveled: 17.9374,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156969,
    shape_pt_lon: -90.031809,
    shape_pt_sequence: 327,
    shape_dist_traveled: 17.9535,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156963,
    shape_pt_lon: -90.031974,
    shape_pt_sequence: 328,
    shape_dist_traveled: 17.9685,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156962,
    shape_pt_lon: -90.032003,
    shape_pt_sequence: 329,
    shape_dist_traveled: 17.9716,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156974,
    shape_pt_lon: -90.032545,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.0207,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156978,
    shape_pt_lon: -90.032628,
    shape_pt_sequence: 331,
    shape_dist_traveled: 18.0277,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033256,
    shape_pt_sequence: 332,
    shape_dist_traveled: 18.0847,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156987,
    shape_pt_lon: -90.033312,
    shape_pt_sequence: 333,
    shape_dist_traveled: 18.0897,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15697,
    shape_pt_lon: -90.033744,
    shape_pt_sequence: 334,
    shape_dist_traveled: 18.1287,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156773,
    shape_pt_lon: -90.034672,
    shape_pt_sequence: 335,
    shape_dist_traveled: 18.2156,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15661,
    shape_pt_lon: -90.035409,
    shape_pt_sequence: 336,
    shape_dist_traveled: 18.284,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156493,
    shape_pt_lon: -90.03587,
    shape_pt_sequence: 337,
    shape_dist_traveled: 18.3279,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156368,
    shape_pt_lon: -90.036357,
    shape_pt_sequence: 338,
    shape_dist_traveled: 18.3741,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156241,
    shape_pt_lon: -90.036847,
    shape_pt_sequence: 339,
    shape_dist_traveled: 18.4203,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156173,
    shape_pt_lon: -90.037107,
    shape_pt_sequence: 340,
    shape_dist_traveled: 18.4456,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156083,
    shape_pt_lon: -90.03752,
    shape_pt_sequence: 341,
    shape_dist_traveled: 18.4839,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156069,
    shape_pt_lon: -90.037569,
    shape_pt_sequence: 342,
    shape_dist_traveled: 18.4884,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156034,
    shape_pt_lon: -90.037685,
    shape_pt_sequence: 343,
    shape_dist_traveled: 18.5001,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155913,
    shape_pt_lon: -90.038131,
    shape_pt_sequence: 344,
    shape_dist_traveled: 18.5421,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155856,
    shape_pt_lon: -90.038509,
    shape_pt_sequence: 345,
    shape_dist_traveled: 18.5769,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15583,
    shape_pt_lon: -90.038827,
    shape_pt_sequence: 346,
    shape_dist_traveled: 18.6059,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 347,
    shape_dist_traveled: 18.6269,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 348,
    shape_dist_traveled: 18.76,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 349,
    shape_dist_traveled: 18.857,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 350,
    shape_dist_traveled: 18.914,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 351,
    shape_dist_traveled: 18.979,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 352,
    shape_dist_traveled: 19.0241,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 353,
    shape_dist_traveled: 19.0435,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 354,
    shape_dist_traveled: 19.1032,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 355,
    shape_dist_traveled: 19.2212,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 356,
    shape_dist_traveled: 19.2807,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 357,
    shape_dist_traveled: 19.3395,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 358,
    shape_dist_traveled: 19.3566,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 359,
    shape_dist_traveled: 19.4192,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 360,
    shape_dist_traveled: 19.4701,
  },
  {
    shape_id: 38248,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 361,
    shape_dist_traveled: 19.5365,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3669,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4267,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4461,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4911,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5561,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6132,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.7102,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8432,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155583,
    shape_pt_lon: -90.039057,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.8712,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155473,
    shape_pt_lon: -90.039066,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.8833,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15556,
    shape_pt_lon: -90.038782,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.9108,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155621,
    shape_pt_lon: -90.03848,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.9387,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155658,
    shape_pt_lon: -90.038242,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.961,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155666,
    shape_pt_lon: -90.038121,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.9721,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155673,
    shape_pt_lon: -90.03803,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9801,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155677,
    shape_pt_lon: -90.037869,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9941,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155677,
    shape_pt_lon: -90.037661,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0131,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15567,
    shape_pt_lon: -90.037578,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0211,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155651,
    shape_pt_lon: -90.037308,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0452,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155588,
    shape_pt_lon: -90.036716,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0997,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15547,
    shape_pt_lon: -90.035344,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2245,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155502,
    shape_pt_lon: -90.033488,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3915,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155492,
    shape_pt_lon: -90.03317,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4205,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155472,
    shape_pt_lon: -90.032902,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4446,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155449,
    shape_pt_lon: -90.032702,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4628,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15543,
    shape_pt_lon: -90.032538,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.478,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15538,
    shape_pt_lon: -90.032006,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5264,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155372,
    shape_pt_lon: -90.031882,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5374,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155268,
    shape_pt_lon: -90.031732,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5558,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15517,
    shape_pt_lon: -90.031151,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.6089,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.155126,
    shape_pt_lon: -90.030757,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.6453,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154939,
    shape_pt_lon: -90.028674,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8345,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15462,
    shape_pt_lon: -90.025099,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.1594,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154528,
    shape_pt_lon: -90.024028,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.257,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154409,
    shape_pt_lon: -90.02266,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.3807,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154382,
    shape_pt_lon: -90.022177,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.4248,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154313,
    shape_pt_lon: -90.021569,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4803,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154309,
    shape_pt_lon: -90.021366,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4983,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15429,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.5145,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154266,
    shape_pt_lon: -90.020966,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.5357,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154252,
    shape_pt_lon: -90.020865,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.5447,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154249,
    shape_pt_lon: -90.020829,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5479,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154244,
    shape_pt_lon: -90.020774,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5529,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15423,
    shape_pt_lon: -90.020575,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.571,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15416,
    shape_pt_lon: -90.019869,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.6355,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154144,
    shape_pt_lon: -90.019639,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.6555,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15335,
    shape_pt_lon: -90.019728,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.7449,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152919,
    shape_pt_lon: -90.019807,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7934,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151924,
    shape_pt_lon: -90.019951,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9061,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151476,
    shape_pt_lon: -90.020013,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.9564,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149979,
    shape_pt_lon: -90.020233,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1246,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149798,
    shape_pt_lon: -90.018533,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.28,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149701,
    shape_pt_lon: -90.017441,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.3785,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149643,
    shape_pt_lon: -90.016648,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.4509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149611,
    shape_pt_lon: -90.01636,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.477,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149511,
    shape_pt_lon: -90.015519,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.554,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14938,
    shape_pt_lon: -90.01441,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.655,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149378,
    shape_pt_lon: -90.014367,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.659,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150237,
    shape_pt_lon: -90.014233,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.7557,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150399,
    shape_pt_lon: -90.014178,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.7744,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150545,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7918,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150698,
    shape_pt_lon: -90.013979,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.8121,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150838,
    shape_pt_lon: -90.013825,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.8334,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150891,
    shape_pt_lon: -90.013742,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.8426,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151115,
    shape_pt_lon: -90.013341,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.8872,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151238,
    shape_pt_lon: -90.013175,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9077,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151374,
    shape_pt_lon: -90.013042,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.927,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151501,
    shape_pt_lon: -90.012952,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.9431,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151617,
    shape_pt_lon: -90.012892,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.957,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151783,
    shape_pt_lon: -90.012837,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.9767,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151908,
    shape_pt_lon: -90.012814,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.9908,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15278,
    shape_pt_lon: -90.012669,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.0887,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152801,
    shape_pt_lon: -90.012415,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.1119,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15289,
    shape_pt_lon: -90.011653,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.1816,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152913,
    shape_pt_lon: -90.011491,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.1967,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15314,
    shape_pt_lon: -90.010417,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.2971,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153309,
    shape_pt_lon: -90.0095,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.3813,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15336,
    shape_pt_lon: -90.009307,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.4,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153431,
    shape_pt_lon: -90.008957,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.432,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153497,
    shape_pt_lon: -90.008603,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.465,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153531,
    shape_pt_lon: -90.00835,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.4883,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.1535,
    shape_pt_lon: -90.007916,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.5275,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153266,
    shape_pt_lon: -90.005593,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.7391,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15309,
    shape_pt_lon: -90.00383,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.9003,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15293,
    shape_pt_lon: -90.002236,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.0454,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152909,
    shape_pt_lon: -90.002002,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.0666,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152661,
    shape_pt_lon: -89.99954,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.2902,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152461,
    shape_pt_lon: -89.997515,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.4746,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15219,
    shape_pt_lon: -89.994731,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.7285,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.152021,
    shape_pt_lon: -89.993062,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.8795,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151949,
    shape_pt_lon: -89.992231,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.956,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151932,
    shape_pt_lon: -89.992076,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.9701,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151516,
    shape_pt_lon: -89.987748,
    shape_pt_sequence: 99,
    shape_dist_traveled: 6.3638,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151341,
    shape_pt_lon: -89.985993,
    shape_pt_sequence: 100,
    shape_dist_traveled: 6.5231,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15117,
    shape_pt_lon: -89.984205,
    shape_pt_sequence: 101,
    shape_dist_traveled: 6.6862,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151095,
    shape_pt_lon: -89.983476,
    shape_pt_sequence: 102,
    shape_dist_traveled: 6.7526,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151027,
    shape_pt_lon: -89.98269,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.8241,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150961,
    shape_pt_lon: -89.981951,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.8905,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15093,
    shape_pt_lon: -89.981606,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.9227,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150906,
    shape_pt_lon: -89.98135,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.9458,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150897,
    shape_pt_lon: -89.981259,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.9539,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150893,
    shape_pt_lon: -89.981209,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.958,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150721,
    shape_pt_lon: -89.979327,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.129,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -89.97923,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.1393,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150738,
    shape_pt_lon: -89.979073,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.1537,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150603,
    shape_pt_lon: -89.977622,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.2865,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150541,
    shape_pt_lon: -89.976905,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.3509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150463,
    shape_pt_lon: -89.976042,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.4293,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150372,
    shape_pt_lon: -89.975043,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.5208,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150296,
    shape_pt_lon: -89.974224,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.5954,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150135,
    shape_pt_lon: -89.97256,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.7465,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14988,
    shape_pt_lon: -89.969932,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.9851,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149832,
    shape_pt_lon: -89.969401,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.0334,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14976,
    shape_pt_lon: -89.968682,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.0989,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149698,
    shape_pt_lon: -89.968032,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.1583,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149637,
    shape_pt_lon: -89.967075,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.2446,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149611,
    shape_pt_lon: -89.966611,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.2867,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149588,
    shape_pt_lon: -89.966127,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.3307,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14957,
    shape_pt_lon: -89.965187,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.4157,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14955,
    shape_pt_lon: -89.964257,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4998,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149535,
    shape_pt_lon: -89.963297,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.5868,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149521,
    shape_pt_lon: -89.962392,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.6688,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149509,
    shape_pt_lon: -89.961489,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.7498,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149506,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.7688,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149493,
    shape_pt_lon: -89.96059,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.8308,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149478,
    shape_pt_lon: -89.959947,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.8889,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149472,
    shape_pt_lon: -89.95966,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.9149,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149468,
    shape_pt_lon: -89.959344,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.9439,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149463,
    shape_pt_lon: -89.95915,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.9609,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149433,
    shape_pt_lon: -89.957881,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.0759,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.956682,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.184,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149393,
    shape_pt_lon: -89.955984,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.247,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149364,
    shape_pt_lon: -89.954827,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.352,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149347,
    shape_pt_lon: -89.954047,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.4221,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149339,
    shape_pt_lon: -89.953673,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.4561,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149332,
    shape_pt_lon: -89.953297,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.4901,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14934,
    shape_pt_lon: -89.952538,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.5591,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149359,
    shape_pt_lon: -89.951389,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.6631,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149391,
    shape_pt_lon: -89.949151,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.8652,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149395,
    shape_pt_lon: -89.948462,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.9272,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947991,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.9702,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947853,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.9822,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149432,
    shape_pt_lon: -89.946088,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.1422,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149467,
    shape_pt_lon: -89.944512,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.2843,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149477,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 151,
    shape_dist_traveled: 10.3743,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149404,
    shape_pt_lon: -89.940993,
    shape_pt_sequence: 152,
    shape_dist_traveled: 10.6025,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149353,
    shape_pt_lon: -89.939251,
    shape_pt_sequence: 153,
    shape_dist_traveled: 10.7596,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14934,
    shape_pt_lon: -89.938528,
    shape_pt_sequence: 154,
    shape_dist_traveled: 10.8256,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14933,
    shape_pt_lon: -89.93782,
    shape_pt_sequence: 155,
    shape_dist_traveled: 10.8886,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149313,
    shape_pt_lon: -89.937325,
    shape_pt_sequence: 156,
    shape_dist_traveled: 10.9336,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149277,
    shape_pt_lon: -89.935585,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.0907,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149253,
    shape_pt_lon: -89.934517,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.1877,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149252,
    shape_pt_lon: -89.934456,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.1927,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14923,
    shape_pt_lon: -89.933441,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.2848,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149217,
    shape_pt_lon: -89.932803,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.3428,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149181,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.4888,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149159,
    shape_pt_lon: -89.930021,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.5938,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149149,
    shape_pt_lon: -89.929609,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.6309,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149143,
    shape_pt_lon: -89.929394,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.6509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149134,
    shape_pt_lon: -89.929024,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.6839,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14913,
    shape_pt_lon: -89.928721,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.7119,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149117,
    shape_pt_lon: -89.928032,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.7739,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149097,
    shape_pt_lon: -89.927041,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.863,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149087,
    shape_pt_lon: -89.926592,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.904,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149072,
    shape_pt_lon: -89.925927,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.964,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14907,
    shape_pt_lon: -89.925823,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.973,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149065,
    shape_pt_lon: -89.925456,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.007,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149053,
    shape_pt_lon: -89.924889,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.058,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149049,
    shape_pt_lon: -89.924628,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.0811,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149027,
    shape_pt_lon: -89.923569,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.1771,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149013,
    shape_pt_lon: -89.92284,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.2431,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148988,
    shape_pt_lon: -89.921514,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.3631,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148986,
    shape_pt_lon: -89.921369,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.3761,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148961,
    shape_pt_lon: -89.92004,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.4962,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148943,
    shape_pt_lon: -89.91923,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.5692,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148932,
    shape_pt_lon: -89.918656,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.6212,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14888,
    shape_pt_lon: -89.916018,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.8593,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148852,
    shape_pt_lon: -89.914573,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.9903,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148817,
    shape_pt_lon: -89.912867,
    shape_pt_sequence: 185,
    shape_dist_traveled: 13.1443,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148792,
    shape_pt_lon: -89.911747,
    shape_pt_sequence: 186,
    shape_dist_traveled: 13.2454,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14879,
    shape_pt_lon: -89.911601,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.2584,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14875,
    shape_pt_lon: -89.909723,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.4284,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148712,
    shape_pt_lon: -89.907833,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.5995,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148677,
    shape_pt_lon: -89.906149,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.7515,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148638,
    shape_pt_lon: -89.904421,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.9076,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148607,
    shape_pt_lon: -89.902898,
    shape_pt_sequence: 192,
    shape_dist_traveled: 14.0456,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148588,
    shape_pt_lon: -89.902052,
    shape_pt_sequence: 193,
    shape_dist_traveled: 14.1216,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148536,
    shape_pt_lon: -89.89966,
    shape_pt_sequence: 194,
    shape_dist_traveled: 14.3377,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148521,
    shape_pt_lon: -89.899049,
    shape_pt_sequence: 195,
    shape_dist_traveled: 14.3927,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148532,
    shape_pt_lon: -89.898662,
    shape_pt_sequence: 196,
    shape_dist_traveled: 14.4278,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148568,
    shape_pt_lon: -89.8981,
    shape_pt_sequence: 197,
    shape_dist_traveled: 14.4789,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148595,
    shape_pt_lon: -89.897887,
    shape_pt_sequence: 198,
    shape_dist_traveled: 14.4983,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148691,
    shape_pt_lon: -89.897102,
    shape_pt_sequence: 199,
    shape_dist_traveled: 14.57,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.148779,
    shape_pt_lon: -89.89656,
    shape_pt_sequence: 200,
    shape_dist_traveled: 14.62,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14891,
    shape_pt_lon: -89.895916,
    shape_pt_sequence: 201,
    shape_dist_traveled: 14.6799,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149065,
    shape_pt_lon: -89.8953,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.7388,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.149275,
    shape_pt_lon: -89.894597,
    shape_pt_sequence: 203,
    shape_dist_traveled: 14.8058,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.1494,
    shape_pt_lon: -89.894225,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.8426,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.14967,
    shape_pt_lon: -89.893519,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.9137,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150031,
    shape_pt_lon: -89.892731,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.9952,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150129,
    shape_pt_lon: -89.892542,
    shape_pt_sequence: 207,
    shape_dist_traveled: 15.0155,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150329,
    shape_pt_lon: -89.892155,
    shape_pt_sequence: 208,
    shape_dist_traveled: 15.0573,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.150734,
    shape_pt_lon: -89.891479,
    shape_pt_sequence: 209,
    shape_dist_traveled: 15.1331,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151129,
    shape_pt_lon: -89.890898,
    shape_pt_sequence: 210,
    shape_dist_traveled: 15.2013,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151321,
    shape_pt_lon: -89.890635,
    shape_pt_sequence: 211,
    shape_dist_traveled: 15.2338,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.151715,
    shape_pt_lon: -89.890148,
    shape_pt_sequence: 212,
    shape_dist_traveled: 15.296,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153068,
    shape_pt_lon: -89.888605,
    shape_pt_sequence: 213,
    shape_dist_traveled: 15.502,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153456,
    shape_pt_lon: -89.888198,
    shape_pt_sequence: 214,
    shape_dist_traveled: 15.5587,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.153682,
    shape_pt_lon: -89.887943,
    shape_pt_sequence: 215,
    shape_dist_traveled: 15.5927,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15399,
    shape_pt_lon: -89.887578,
    shape_pt_sequence: 216,
    shape_dist_traveled: 15.6408,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154019,
    shape_pt_lon: -89.887542,
    shape_pt_sequence: 217,
    shape_dist_traveled: 15.6451,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15416,
    shape_pt_lon: -89.88737,
    shape_pt_sequence: 218,
    shape_dist_traveled: 15.667,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154197,
    shape_pt_lon: -89.887326,
    shape_pt_sequence: 219,
    shape_dist_traveled: 15.6726,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154258,
    shape_pt_lon: -89.887248,
    shape_pt_sequence: 220,
    shape_dist_traveled: 15.6825,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154499,
    shape_pt_lon: -89.886987,
    shape_pt_sequence: 221,
    shape_dist_traveled: 15.7187,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154739,
    shape_pt_lon: -89.886735,
    shape_pt_sequence: 222,
    shape_dist_traveled: 15.7541,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.154783,
    shape_pt_lon: -89.886693,
    shape_pt_sequence: 223,
    shape_dist_traveled: 15.76,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15507,
    shape_pt_lon: -89.886389,
    shape_pt_sequence: 224,
    shape_dist_traveled: 15.8025,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.156541,
    shape_pt_lon: -89.884793,
    shape_pt_sequence: 225,
    shape_dist_traveled: 16.0215,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.157342,
    shape_pt_lon: -89.88391,
    shape_pt_sequence: 226,
    shape_dist_traveled: 16.1412,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15749,
    shape_pt_lon: -89.883751,
    shape_pt_sequence: 227,
    shape_dist_traveled: 16.1625,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.15794,
    shape_pt_lon: -89.883249,
    shape_pt_sequence: 228,
    shape_dist_traveled: 16.2312,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.158868,
    shape_pt_lon: -89.882219,
    shape_pt_sequence: 229,
    shape_dist_traveled: 16.3707,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.159798,
    shape_pt_lon: -89.881188,
    shape_pt_sequence: 230,
    shape_dist_traveled: 16.5096,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162521,
    shape_pt_lon: -89.878169,
    shape_pt_sequence: 231,
    shape_dist_traveled: 16.9189,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162763,
    shape_pt_lon: -89.877911,
    shape_pt_sequence: 232,
    shape_dist_traveled: 16.9544,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162783,
    shape_pt_lon: -89.877778,
    shape_pt_sequence: 233,
    shape_dist_traveled: 16.9667,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162799,
    shape_pt_lon: -89.877709,
    shape_pt_sequence: 234,
    shape_dist_traveled: 16.9728,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162812,
    shape_pt_lon: -89.877618,
    shape_pt_sequence: 235,
    shape_dist_traveled: 16.9811,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16281,
    shape_pt_lon: -89.877551,
    shape_pt_sequence: 236,
    shape_dist_traveled: 16.9871,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16279,
    shape_pt_lon: -89.87745,
    shape_pt_sequence: 237,
    shape_dist_traveled: 16.9965,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162693,
    shape_pt_lon: -89.877331,
    shape_pt_sequence: 238,
    shape_dist_traveled: 17.0114,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162566,
    shape_pt_lon: -89.877089,
    shape_pt_sequence: 239,
    shape_dist_traveled: 17.038,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162485,
    shape_pt_lon: -89.876814,
    shape_pt_sequence: 240,
    shape_dist_traveled: 17.0646,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162461,
    shape_pt_lon: -89.876612,
    shape_pt_sequence: 241,
    shape_dist_traveled: 17.0827,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162452,
    shape_pt_lon: -89.876424,
    shape_pt_sequence: 242,
    shape_dist_traveled: 17.0997,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162469,
    shape_pt_lon: -89.87637,
    shape_pt_sequence: 243,
    shape_dist_traveled: 17.1051,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162463,
    shape_pt_lon: -89.876271,
    shape_pt_sequence: 244,
    shape_dist_traveled: 17.1142,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162439,
    shape_pt_lon: -89.874761,
    shape_pt_sequence: 245,
    shape_dist_traveled: 17.2502,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162418,
    shape_pt_lon: -89.873519,
    shape_pt_sequence: 246,
    shape_dist_traveled: 17.3632,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162406,
    shape_pt_lon: -89.87284,
    shape_pt_sequence: 247,
    shape_dist_traveled: 17.4243,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162403,
    shape_pt_lon: -89.872093,
    shape_pt_sequence: 248,
    shape_dist_traveled: 17.4913,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.87199,
    shape_pt_sequence: 249,
    shape_dist_traveled: 17.5013,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162438,
    shape_pt_lon: -89.871753,
    shape_pt_sequence: 250,
    shape_dist_traveled: 17.5225,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162478,
    shape_pt_lon: -89.871541,
    shape_pt_sequence: 251,
    shape_dist_traveled: 17.5422,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162551,
    shape_pt_lon: -89.871271,
    shape_pt_sequence: 252,
    shape_dist_traveled: 17.5684,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162753,
    shape_pt_lon: -89.870732,
    shape_pt_sequence: 253,
    shape_dist_traveled: 17.6216,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162843,
    shape_pt_lon: -89.870501,
    shape_pt_sequence: 254,
    shape_dist_traveled: 17.6449,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162955,
    shape_pt_lon: -89.870209,
    shape_pt_sequence: 255,
    shape_dist_traveled: 17.6735,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163039,
    shape_pt_lon: -89.869993,
    shape_pt_sequence: 256,
    shape_dist_traveled: 17.6959,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163121,
    shape_pt_lon: -89.869778,
    shape_pt_sequence: 257,
    shape_dist_traveled: 17.7169,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163505,
    shape_pt_lon: -89.868771,
    shape_pt_sequence: 258,
    shape_dist_traveled: 17.8176,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16378,
    shape_pt_lon: -89.868071,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.8878,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163851,
    shape_pt_lon: -89.867914,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.9048,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163856,
    shape_pt_lon: -89.867823,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.9128,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163864,
    shape_pt_lon: -89.867767,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.9178,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163989,
    shape_pt_lon: -89.86754,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.9428,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16409,
    shape_pt_lon: -89.867381,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.9614,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164182,
    shape_pt_lon: -89.867247,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.9771,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164328,
    shape_pt_lon: -89.867068,
    shape_pt_sequence: 266,
    shape_dist_traveled: 18.0004,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164606,
    shape_pt_lon: -89.866799,
    shape_pt_sequence: 267,
    shape_dist_traveled: 18.0396,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16473,
    shape_pt_lon: -89.866708,
    shape_pt_sequence: 268,
    shape_dist_traveled: 18.0557,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164837,
    shape_pt_lon: -89.866631,
    shape_pt_sequence: 269,
    shape_dist_traveled: 18.0696,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165089,
    shape_pt_lon: -89.8665,
    shape_pt_sequence: 270,
    shape_dist_traveled: 18.1001,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165246,
    shape_pt_lon: -89.866438,
    shape_pt_sequence: 271,
    shape_dist_traveled: 18.1181,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165347,
    shape_pt_lon: -89.86641,
    shape_pt_sequence: 272,
    shape_dist_traveled: 18.1303,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165463,
    shape_pt_lon: -89.866379,
    shape_pt_sequence: 273,
    shape_dist_traveled: 18.1436,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165683,
    shape_pt_lon: -89.866343,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.1678,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.166206,
    shape_pt_lon: -89.866305,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.2269,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.166385,
    shape_pt_lon: -89.866268,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.2473,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.166563,
    shape_pt_lon: -89.866211,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.2679,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16675,
    shape_pt_lon: -89.866131,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.29,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167038,
    shape_pt_lon: -89.865953,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.3258,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167121,
    shape_pt_lon: -89.865889,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.3366,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167256,
    shape_pt_lon: -89.865776,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.3547,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167437,
    shape_pt_lon: -89.865588,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.3817,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167727,
    shape_pt_lon: -89.86519,
    shape_pt_sequence: 283,
    shape_dist_traveled: 18.4298,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.5142,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.5327,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.5639,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.6016,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.6761,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.7187,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.7656,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.8394,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.9096,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.9196,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.9274,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.9374,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.9509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.9651,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.0191,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.0752,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.0811,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.1185,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.1346,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.1507,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.1852,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.2108,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.2526,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162915,
    shape_pt_lon: -89.860091,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.2906,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162871,
    shape_pt_lon: -89.861203,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.3908,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162873,
    shape_pt_lon: -89.861851,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.4498,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162883,
    shape_pt_lon: -89.862371,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.4968,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162891,
    shape_pt_lon: -89.862829,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.5378,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162898,
    shape_pt_lon: -89.863111,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.5628,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162901,
    shape_pt_lon: -89.863341,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.5838,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162909,
    shape_pt_lon: -89.86375,
    shape_pt_sequence: 314,
    shape_dist_traveled: 19.6208,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.162623,
    shape_pt_lon: -89.86376,
    shape_pt_sequence: 315,
    shape_dist_traveled: 19.6528,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.161748,
    shape_pt_lon: -89.863795,
    shape_pt_sequence: 316,
    shape_dist_traveled: 19.7509,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.160743,
    shape_pt_lon: -89.863833,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.8629,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.160279,
    shape_pt_lon: -89.86385,
    shape_pt_sequence: 318,
    shape_dist_traveled: 19.9149,
  },
  {
    shape_id: 38249,
    shape_pt_lat: 35.160127,
    shape_pt_lon: -89.863857,
    shape_pt_sequence: 319,
    shape_dist_traveled: 19.9319,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.160127,
    shape_pt_lon: -89.863857,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15918,
    shape_pt_lon: -89.863897,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.106,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.159031,
    shape_pt_lon: -89.863902,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1231,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158893,
    shape_pt_lon: -89.863853,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1389,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158766,
    shape_pt_lon: -89.863767,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1545,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158669,
    shape_pt_lon: -89.863657,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1694,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158593,
    shape_pt_lon: -89.863506,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.186,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158561,
    shape_pt_lon: -89.863408,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1955,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158542,
    shape_pt_lon: -89.86328,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.2067,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158533,
    shape_pt_lon: -89.863165,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.2178,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.861991,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.3238,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15854,
    shape_pt_lon: -89.860467,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4618,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158599,
    shape_pt_lon: -89.859968,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5073,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158597,
    shape_pt_lon: -89.859639,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5363,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.157487,
    shape_pt_lon: -89.859662,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.6613,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156972,
    shape_pt_lon: -89.859669,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.7183,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156472,
    shape_pt_lon: -89.85965,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.7744,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156289,
    shape_pt_lon: -89.859622,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.7954,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156056,
    shape_pt_lon: -89.85958,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8218,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155599,
    shape_pt_lon: -89.859465,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8739,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155214,
    shape_pt_lon: -89.859317,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.9188,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154674,
    shape_pt_lon: -89.859058,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9835,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15447,
    shape_pt_lon: -89.858933,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.009,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154073,
    shape_pt_lon: -89.858649,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.0601,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154091,
    shape_pt_lon: -89.858585,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0664,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154098,
    shape_pt_lon: -89.8585,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0734,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154097,
    shape_pt_lon: -89.858438,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0794,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154066,
    shape_pt_lon: -89.858248,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.0967,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154009,
    shape_pt_lon: -89.85791,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.1282,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153979,
    shape_pt_lon: -89.857627,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1536,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153968,
    shape_pt_lon: -89.857152,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.1966,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153987,
    shape_pt_lon: -89.856923,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.2177,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154003,
    shape_pt_lon: -89.856748,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.2338,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154124,
    shape_pt_lon: -89.855781,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.3219,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15414,
    shape_pt_lon: -89.855518,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.346,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15414,
    shape_pt_lon: -89.854968,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.395,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154148,
    shape_pt_lon: -89.854599,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.429,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154171,
    shape_pt_lon: -89.854393,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.4471,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154228,
    shape_pt_lon: -89.85405,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.4789,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154429,
    shape_pt_lon: -89.853233,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.5564,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154481,
    shape_pt_lon: -89.852883,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.5888,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154507,
    shape_pt_lon: -89.852453,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.6279,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.1545,
    shape_pt_lon: -89.851846,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.6819,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154493,
    shape_pt_lon: -89.851296,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.7319,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154632,
    shape_pt_lon: -89.85124,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.7487,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154873,
    shape_pt_lon: -89.851179,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.7763,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155474,
    shape_pt_lon: -89.8511,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.8436,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155901,
    shape_pt_lon: -89.851058,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.8918,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15625,
    shape_pt_lon: -89.851007,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.9311,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156497,
    shape_pt_lon: -89.85093,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.959,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15674,
    shape_pt_lon: -89.850819,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.9887,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156868,
    shape_pt_lon: -89.850784,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.003,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.157159,
    shape_pt_lon: -89.850738,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.0363,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.157946,
    shape_pt_lon: -89.850701,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.1243,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158789,
    shape_pt_lon: -89.85067,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.2183,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.159636,
    shape_pt_lon: -89.850627,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.3134,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15982,
    shape_pt_lon: -89.850616,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.3334,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.160429,
    shape_pt_lon: -89.850576,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.4026,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.161126,
    shape_pt_lon: -89.850527,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.4807,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.161936,
    shape_pt_lon: -89.850471,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.5708,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16315,
    shape_pt_lon: -89.850392,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.7069,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163316,
    shape_pt_lon: -89.850409,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.726,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16342,
    shape_pt_lon: -89.850451,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.7377,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163367,
    shape_pt_lon: -89.850611,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.7529,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16329,
    shape_pt_lon: -89.850962,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.7859,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16325,
    shape_pt_lon: -89.851292,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.8163,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16313,
    shape_pt_lon: -89.853729,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.0367,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16309,
    shape_pt_lon: -89.854758,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.1299,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16305,
    shape_pt_lon: -89.855818,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.226,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16303,
    shape_pt_lon: -89.856337,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.273,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162979,
    shape_pt_lon: -89.857723,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.3982,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16296,
    shape_pt_lon: -89.85836,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.4552,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162958,
    shape_pt_lon: -89.858447,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.4632,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162939,
    shape_pt_lon: -89.858926,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.5063,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162922,
    shape_pt_lon: -89.859436,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.5523,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16293,
    shape_pt_lon: -89.859671,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.5743,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163289,
    shape_pt_lon: -89.859804,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.6161,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163506,
    shape_pt_lon: -89.859906,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.6417,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163782,
    shape_pt_lon: -89.860069,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.6762,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163907,
    shape_pt_lon: -89.860158,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.6923,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164032,
    shape_pt_lon: -89.86025,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.7084,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164311,
    shape_pt_lon: -89.86048,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.7459,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164353,
    shape_pt_lon: -89.860518,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.7517,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164741,
    shape_pt_lon: -89.860905,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.8078,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16512,
    shape_pt_lon: -89.861289,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.8618,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165216,
    shape_pt_lon: -89.861388,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.876,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165309,
    shape_pt_lon: -89.861482,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.8895,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16538,
    shape_pt_lon: -89.861556,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.8995,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165435,
    shape_pt_lon: -89.86161,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.9073,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165499,
    shape_pt_lon: -89.861677,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.9173,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165979,
    shape_pt_lon: -89.862183,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.9875,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166483,
    shape_pt_lon: -89.862699,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.0613,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166812,
    shape_pt_lon: -89.863035,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.1082,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167106,
    shape_pt_lon: -89.863328,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.1508,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167616,
    shape_pt_lon: -89.86386,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.2254,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167871,
    shape_pt_lon: -89.864119,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.263,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.168091,
    shape_pt_lon: -89.864342,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.2942,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.168218,
    shape_pt_lon: -89.864476,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.3127,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.168259,
    shape_pt_lon: -89.864519,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.3191,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.168301,
    shape_pt_lon: -89.864563,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.3255,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167853,
    shape_pt_lon: -89.865203,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.4027,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167719,
    shape_pt_lon: -89.865401,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.4254,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16758,
    shape_pt_lon: -89.86559,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.4481,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.167354,
    shape_pt_lon: -89.865849,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.4835,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16719,
    shape_pt_lon: -89.865988,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.5051,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166998,
    shape_pt_lon: -89.866139,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.5312,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166896,
    shape_pt_lon: -89.866198,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.5432,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16662,
    shape_pt_lon: -89.866329,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.5765,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166459,
    shape_pt_lon: -89.866383,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.5952,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166226,
    shape_pt_lon: -89.866432,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.6215,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.166159,
    shape_pt_lon: -89.866443,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.6285,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165719,
    shape_pt_lon: -89.866474,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.6786,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165484,
    shape_pt_lon: -89.866514,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.7048,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165367,
    shape_pt_lon: -89.866545,
    shape_pt_sequence: 114,
    shape_dist_traveled: 4.7181,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.165193,
    shape_pt_lon: -89.866599,
    shape_pt_sequence: 115,
    shape_dist_traveled: 4.7388,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164958,
    shape_pt_lon: -89.866711,
    shape_pt_sequence: 116,
    shape_dist_traveled: 4.7666,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164794,
    shape_pt_lon: -89.86681,
    shape_pt_sequence: 117,
    shape_dist_traveled: 4.7867,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164588,
    shape_pt_lon: -89.866982,
    shape_pt_sequence: 118,
    shape_dist_traveled: 4.8142,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164449,
    shape_pt_lon: -89.867112,
    shape_pt_sequence: 119,
    shape_dist_traveled: 4.8342,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164269,
    shape_pt_lon: -89.867311,
    shape_pt_sequence: 120,
    shape_dist_traveled: 4.8611,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164159,
    shape_pt_lon: -89.867466,
    shape_pt_sequence: 121,
    shape_dist_traveled: 4.8796,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164138,
    shape_pt_lon: -89.867496,
    shape_pt_sequence: 122,
    shape_dist_traveled: 4.8838,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.164053,
    shape_pt_lon: -89.86763,
    shape_pt_sequence: 123,
    shape_dist_traveled: 4.8988,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16395,
    shape_pt_lon: -89.867831,
    shape_pt_sequence: 124,
    shape_dist_traveled: 4.9204,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163896,
    shape_pt_lon: -89.867878,
    shape_pt_sequence: 125,
    shape_dist_traveled: 4.9276,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163877,
    shape_pt_lon: -89.867896,
    shape_pt_sequence: 126,
    shape_dist_traveled: 4.9305,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163851,
    shape_pt_lon: -89.867914,
    shape_pt_sequence: 127,
    shape_dist_traveled: 4.9336,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.16378,
    shape_pt_lon: -89.868071,
    shape_pt_sequence: 128,
    shape_dist_traveled: 4.9506,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163505,
    shape_pt_lon: -89.868771,
    shape_pt_sequence: 129,
    shape_dist_traveled: 5.0208,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163121,
    shape_pt_lon: -89.869778,
    shape_pt_sequence: 130,
    shape_dist_traveled: 5.1215,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163039,
    shape_pt_lon: -89.869993,
    shape_pt_sequence: 131,
    shape_dist_traveled: 5.1425,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162955,
    shape_pt_lon: -89.870209,
    shape_pt_sequence: 132,
    shape_dist_traveled: 5.1649,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162843,
    shape_pt_lon: -89.870501,
    shape_pt_sequence: 133,
    shape_dist_traveled: 5.1935,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162753,
    shape_pt_lon: -89.870732,
    shape_pt_sequence: 134,
    shape_dist_traveled: 5.2168,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162551,
    shape_pt_lon: -89.871271,
    shape_pt_sequence: 135,
    shape_dist_traveled: 5.27,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162478,
    shape_pt_lon: -89.871541,
    shape_pt_sequence: 136,
    shape_dist_traveled: 5.2962,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162438,
    shape_pt_lon: -89.871753,
    shape_pt_sequence: 137,
    shape_dist_traveled: 5.3159,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162409,
    shape_pt_lon: -89.87199,
    shape_pt_sequence: 138,
    shape_dist_traveled: 5.3371,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162403,
    shape_pt_lon: -89.872093,
    shape_pt_sequence: 139,
    shape_dist_traveled: 5.3472,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162406,
    shape_pt_lon: -89.87284,
    shape_pt_sequence: 140,
    shape_dist_traveled: 5.4142,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162418,
    shape_pt_lon: -89.873519,
    shape_pt_sequence: 141,
    shape_dist_traveled: 5.4752,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162439,
    shape_pt_lon: -89.874761,
    shape_pt_sequence: 142,
    shape_dist_traveled: 5.5882,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162463,
    shape_pt_lon: -89.876271,
    shape_pt_sequence: 143,
    shape_dist_traveled: 5.7242,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162469,
    shape_pt_lon: -89.87637,
    shape_pt_sequence: 144,
    shape_dist_traveled: 5.7333,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162501,
    shape_pt_lon: -89.876428,
    shape_pt_sequence: 145,
    shape_dist_traveled: 5.7391,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162522,
    shape_pt_lon: -89.876737,
    shape_pt_sequence: 146,
    shape_dist_traveled: 5.7673,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162559,
    shape_pt_lon: -89.87689,
    shape_pt_sequence: 147,
    shape_dist_traveled: 5.7818,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162609,
    shape_pt_lon: -89.877021,
    shape_pt_sequence: 148,
    shape_dist_traveled: 5.7948,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162636,
    shape_pt_lon: -89.877078,
    shape_pt_sequence: 149,
    shape_dist_traveled: 5.8007,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162737,
    shape_pt_lon: -89.877252,
    shape_pt_sequence: 150,
    shape_dist_traveled: 5.8207,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162804,
    shape_pt_lon: -89.877349,
    shape_pt_sequence: 151,
    shape_dist_traveled: 5.8313,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162981,
    shape_pt_lon: -89.877542,
    shape_pt_sequence: 152,
    shape_dist_traveled: 5.8582,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.163057,
    shape_pt_lon: -89.877598,
    shape_pt_sequence: 153,
    shape_dist_traveled: 5.8676,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162992,
    shape_pt_lon: -89.877667,
    shape_pt_sequence: 154,
    shape_dist_traveled: 5.8768,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162763,
    shape_pt_lon: -89.877911,
    shape_pt_sequence: 155,
    shape_dist_traveled: 5.9109,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.162521,
    shape_pt_lon: -89.878169,
    shape_pt_sequence: 156,
    shape_dist_traveled: 5.9464,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.159798,
    shape_pt_lon: -89.881188,
    shape_pt_sequence: 157,
    shape_dist_traveled: 6.3557,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.158868,
    shape_pt_lon: -89.882219,
    shape_pt_sequence: 158,
    shape_dist_traveled: 6.4946,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15794,
    shape_pt_lon: -89.883249,
    shape_pt_sequence: 159,
    shape_dist_traveled: 6.6341,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15749,
    shape_pt_lon: -89.883751,
    shape_pt_sequence: 160,
    shape_dist_traveled: 6.7028,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.157342,
    shape_pt_lon: -89.88391,
    shape_pt_sequence: 161,
    shape_dist_traveled: 6.724,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156541,
    shape_pt_lon: -89.884793,
    shape_pt_sequence: 162,
    shape_dist_traveled: 6.8438,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15507,
    shape_pt_lon: -89.886389,
    shape_pt_sequence: 163,
    shape_dist_traveled: 7.0628,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154783,
    shape_pt_lon: -89.886693,
    shape_pt_sequence: 164,
    shape_dist_traveled: 7.1053,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154739,
    shape_pt_lon: -89.886735,
    shape_pt_sequence: 165,
    shape_dist_traveled: 7.1111,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154499,
    shape_pt_lon: -89.886987,
    shape_pt_sequence: 166,
    shape_dist_traveled: 7.1466,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154258,
    shape_pt_lon: -89.887248,
    shape_pt_sequence: 167,
    shape_dist_traveled: 7.1827,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154197,
    shape_pt_lon: -89.887326,
    shape_pt_sequence: 168,
    shape_dist_traveled: 7.1926,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15416,
    shape_pt_lon: -89.88737,
    shape_pt_sequence: 169,
    shape_dist_traveled: 7.1983,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154019,
    shape_pt_lon: -89.887542,
    shape_pt_sequence: 170,
    shape_dist_traveled: 7.2202,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15399,
    shape_pt_lon: -89.887578,
    shape_pt_sequence: 171,
    shape_dist_traveled: 7.2245,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153682,
    shape_pt_lon: -89.887943,
    shape_pt_sequence: 172,
    shape_dist_traveled: 7.2726,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153456,
    shape_pt_lon: -89.888198,
    shape_pt_sequence: 173,
    shape_dist_traveled: 7.3065,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153068,
    shape_pt_lon: -89.888605,
    shape_pt_sequence: 174,
    shape_dist_traveled: 7.3633,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151715,
    shape_pt_lon: -89.890148,
    shape_pt_sequence: 175,
    shape_dist_traveled: 7.5692,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151321,
    shape_pt_lon: -89.890635,
    shape_pt_sequence: 176,
    shape_dist_traveled: 7.6315,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151129,
    shape_pt_lon: -89.890898,
    shape_pt_sequence: 177,
    shape_dist_traveled: 7.664,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150734,
    shape_pt_lon: -89.891479,
    shape_pt_sequence: 178,
    shape_dist_traveled: 7.7321,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150329,
    shape_pt_lon: -89.892155,
    shape_pt_sequence: 179,
    shape_dist_traveled: 7.8079,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150129,
    shape_pt_lon: -89.892542,
    shape_pt_sequence: 180,
    shape_dist_traveled: 7.8498,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150031,
    shape_pt_lon: -89.892731,
    shape_pt_sequence: 181,
    shape_dist_traveled: 7.8701,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14967,
    shape_pt_lon: -89.893519,
    shape_pt_sequence: 182,
    shape_dist_traveled: 7.9515,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.1494,
    shape_pt_lon: -89.894225,
    shape_pt_sequence: 183,
    shape_dist_traveled: 8.0227,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149275,
    shape_pt_lon: -89.894597,
    shape_pt_sequence: 184,
    shape_dist_traveled: 8.0594,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149065,
    shape_pt_lon: -89.8953,
    shape_pt_sequence: 185,
    shape_dist_traveled: 8.1265,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14891,
    shape_pt_lon: -89.895916,
    shape_pt_sequence: 186,
    shape_dist_traveled: 8.1853,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148779,
    shape_pt_lon: -89.89656,
    shape_pt_sequence: 187,
    shape_dist_traveled: 8.2452,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148691,
    shape_pt_lon: -89.897102,
    shape_pt_sequence: 188,
    shape_dist_traveled: 8.2952,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148595,
    shape_pt_lon: -89.897887,
    shape_pt_sequence: 189,
    shape_dist_traveled: 8.3669,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148568,
    shape_pt_lon: -89.8981,
    shape_pt_sequence: 190,
    shape_dist_traveled: 8.3864,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148532,
    shape_pt_lon: -89.898662,
    shape_pt_sequence: 191,
    shape_dist_traveled: 8.4375,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148521,
    shape_pt_lon: -89.899049,
    shape_pt_sequence: 192,
    shape_dist_traveled: 8.4725,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148536,
    shape_pt_lon: -89.89966,
    shape_pt_sequence: 193,
    shape_dist_traveled: 8.5276,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148588,
    shape_pt_lon: -89.902052,
    shape_pt_sequence: 194,
    shape_dist_traveled: 8.7436,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148607,
    shape_pt_lon: -89.902898,
    shape_pt_sequence: 195,
    shape_dist_traveled: 8.8196,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148638,
    shape_pt_lon: -89.904421,
    shape_pt_sequence: 196,
    shape_dist_traveled: 8.9577,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148677,
    shape_pt_lon: -89.906149,
    shape_pt_sequence: 197,
    shape_dist_traveled: 9.1137,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148712,
    shape_pt_lon: -89.907833,
    shape_pt_sequence: 198,
    shape_dist_traveled: 9.2658,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14875,
    shape_pt_lon: -89.909723,
    shape_pt_sequence: 199,
    shape_dist_traveled: 9.4368,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14879,
    shape_pt_lon: -89.911601,
    shape_pt_sequence: 200,
    shape_dist_traveled: 9.6069,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148792,
    shape_pt_lon: -89.911747,
    shape_pt_sequence: 201,
    shape_dist_traveled: 9.6199,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148817,
    shape_pt_lon: -89.912867,
    shape_pt_sequence: 202,
    shape_dist_traveled: 9.7209,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148852,
    shape_pt_lon: -89.914573,
    shape_pt_sequence: 203,
    shape_dist_traveled: 9.875,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14888,
    shape_pt_lon: -89.916018,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.006,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148932,
    shape_pt_lon: -89.918656,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.2441,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148943,
    shape_pt_lon: -89.91923,
    shape_pt_sequence: 206,
    shape_dist_traveled: 10.2961,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148961,
    shape_pt_lon: -89.92004,
    shape_pt_sequence: 207,
    shape_dist_traveled: 10.3691,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148986,
    shape_pt_lon: -89.921369,
    shape_pt_sequence: 208,
    shape_dist_traveled: 10.4891,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.148988,
    shape_pt_lon: -89.921514,
    shape_pt_sequence: 209,
    shape_dist_traveled: 10.5021,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149013,
    shape_pt_lon: -89.92284,
    shape_pt_sequence: 210,
    shape_dist_traveled: 10.6222,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149027,
    shape_pt_lon: -89.923569,
    shape_pt_sequence: 211,
    shape_dist_traveled: 10.6882,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149049,
    shape_pt_lon: -89.924628,
    shape_pt_sequence: 212,
    shape_dist_traveled: 10.7842,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149053,
    shape_pt_lon: -89.924889,
    shape_pt_sequence: 213,
    shape_dist_traveled: 10.8072,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149065,
    shape_pt_lon: -89.925456,
    shape_pt_sequence: 214,
    shape_dist_traveled: 10.8582,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14907,
    shape_pt_lon: -89.925823,
    shape_pt_sequence: 215,
    shape_dist_traveled: 10.8922,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149072,
    shape_pt_lon: -89.925927,
    shape_pt_sequence: 216,
    shape_dist_traveled: 10.9013,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149087,
    shape_pt_lon: -89.926592,
    shape_pt_sequence: 217,
    shape_dist_traveled: 10.9613,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149097,
    shape_pt_lon: -89.927041,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.0023,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149117,
    shape_pt_lon: -89.928032,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.0913,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14913,
    shape_pt_lon: -89.928721,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.1534,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149134,
    shape_pt_lon: -89.929024,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.1814,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149143,
    shape_pt_lon: -89.929394,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.2144,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149149,
    shape_pt_lon: -89.929609,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.2344,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149159,
    shape_pt_lon: -89.930021,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.2714,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149181,
    shape_pt_lon: -89.931179,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.3764,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149217,
    shape_pt_lon: -89.932803,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.5225,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14923,
    shape_pt_lon: -89.933441,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.5805,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149252,
    shape_pt_lon: -89.934456,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.6725,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149253,
    shape_pt_lon: -89.934517,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.6775,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149277,
    shape_pt_lon: -89.935585,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.7746,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149313,
    shape_pt_lon: -89.937325,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.9316,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14933,
    shape_pt_lon: -89.93782,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.9767,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14934,
    shape_pt_lon: -89.938528,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.0397,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149353,
    shape_pt_lon: -89.939251,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.1057,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149404,
    shape_pt_lon: -89.940993,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.2628,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149477,
    shape_pt_lon: -89.943519,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.4909,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149467,
    shape_pt_lon: -89.944512,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.5809,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149432,
    shape_pt_lon: -89.946088,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.723,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947853,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.883,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.947991,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.895,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149395,
    shape_pt_lon: -89.948462,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.9381,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149391,
    shape_pt_lon: -89.949151,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.0001,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149359,
    shape_pt_lon: -89.951389,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.2021,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14934,
    shape_pt_lon: -89.952538,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.3062,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149332,
    shape_pt_lon: -89.953297,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.3752,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149339,
    shape_pt_lon: -89.953673,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.4092,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149347,
    shape_pt_lon: -89.954047,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.4432,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149364,
    shape_pt_lon: -89.954827,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.5132,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149393,
    shape_pt_lon: -89.955984,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.6183,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149408,
    shape_pt_lon: -89.956682,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.6813,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149433,
    shape_pt_lon: -89.957881,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.7893,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149463,
    shape_pt_lon: -89.95915,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.9044,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149468,
    shape_pt_lon: -89.959344,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.9214,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149472,
    shape_pt_lon: -89.95966,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.9504,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149478,
    shape_pt_lon: -89.959947,
    shape_pt_sequence: 255,
    shape_dist_traveled: 13.9764,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149493,
    shape_pt_lon: -89.96059,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.0344,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149506,
    shape_pt_lon: -89.961282,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.0965,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149509,
    shape_pt_lon: -89.961489,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.1155,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149521,
    shape_pt_lon: -89.962392,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.1965,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149535,
    shape_pt_lon: -89.963297,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.2785,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14955,
    shape_pt_lon: -89.964257,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.3655,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14957,
    shape_pt_lon: -89.965187,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.4495,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149588,
    shape_pt_lon: -89.966127,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.5346,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149611,
    shape_pt_lon: -89.966611,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.5786,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149637,
    shape_pt_lon: -89.967075,
    shape_pt_sequence: 265,
    shape_dist_traveled: 14.6207,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149698,
    shape_pt_lon: -89.968032,
    shape_pt_sequence: 266,
    shape_dist_traveled: 14.707,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14976,
    shape_pt_lon: -89.968682,
    shape_pt_sequence: 267,
    shape_dist_traveled: 14.7664,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149832,
    shape_pt_lon: -89.969401,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.8319,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14988,
    shape_pt_lon: -89.969932,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.8802,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150135,
    shape_pt_lon: -89.97256,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.1188,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150296,
    shape_pt_lon: -89.974224,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.2699,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150372,
    shape_pt_lon: -89.975043,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.3444,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150463,
    shape_pt_lon: -89.976042,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.436,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150541,
    shape_pt_lon: -89.976905,
    shape_pt_sequence: 274,
    shape_dist_traveled: 15.5144,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150603,
    shape_pt_lon: -89.977622,
    shape_pt_sequence: 275,
    shape_dist_traveled: 15.5788,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150738,
    shape_pt_lon: -89.979073,
    shape_pt_sequence: 276,
    shape_dist_traveled: 15.7116,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -89.97923,
    shape_pt_sequence: 277,
    shape_dist_traveled: 15.7259,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150808,
    shape_pt_lon: -89.97932,
    shape_pt_sequence: 278,
    shape_dist_traveled: 15.7354,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151052,
    shape_pt_lon: -89.981186,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.9065,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151058,
    shape_pt_lon: -89.981238,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.9106,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151063,
    shape_pt_lon: -89.981306,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.9166,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151071,
    shape_pt_lon: -89.981399,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.9257,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151195,
    shape_pt_lon: -89.982698,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.0435,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15145,
    shape_pt_lon: -89.985353,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.2851,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151511,
    shape_pt_lon: -89.985977,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.3416,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151609,
    shape_pt_lon: -89.987013,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.4362,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15168,
    shape_pt_lon: -89.987739,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.5027,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151706,
    shape_pt_lon: -89.988013,
    shape_pt_sequence: 288,
    shape_dist_traveled: 16.5269,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151802,
    shape_pt_lon: -89.988956,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.6135,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151935,
    shape_pt_lon: -89.990346,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.7394,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152102,
    shape_pt_lon: -89.992051,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.8945,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152119,
    shape_pt_lon: -89.992206,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.9087,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152131,
    shape_pt_lon: -89.992328,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.9197,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152438,
    shape_pt_lon: -89.995539,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.2117,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152629,
    shape_pt_lon: -89.997488,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.389,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152679,
    shape_pt_lon: -89.998049,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.4403,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152761,
    shape_pt_lon: -89.998781,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.5069,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152831,
    shape_pt_lon: -89.999514,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.5734,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153073,
    shape_pt_lon: -90.001972,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.798,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153255,
    shape_pt_lon: -90.003807,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.9642,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153437,
    shape_pt_lon: -90.00557,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.1255,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153623,
    shape_pt_lon: -90.007459,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.2968,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15366,
    shape_pt_lon: -90.007949,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.342,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153682,
    shape_pt_lon: -90.008126,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.3582,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15369,
    shape_pt_lon: -90.008381,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.3812,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153652,
    shape_pt_lon: -90.008926,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.4304,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153637,
    shape_pt_lon: -90.009424,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.4754,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153619,
    shape_pt_lon: -90.009979,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.5255,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153557,
    shape_pt_lon: -90.010421,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.5661,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15351,
    shape_pt_lon: -90.010654,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.5877,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153433,
    shape_pt_lon: -90.010889,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.6101,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153377,
    shape_pt_lon: -90.011011,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.6232,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153309,
    shape_pt_lon: -90.011139,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.6371,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153213,
    shape_pt_lon: -90.011279,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.6534,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.153179,
    shape_pt_lon: -90.011331,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.6598,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15303,
    shape_pt_lon: -90.011566,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.6868,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152993,
    shape_pt_lon: -90.011663,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.6966,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152959,
    shape_pt_lon: -90.01181,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.7102,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152936,
    shape_pt_lon: -90.011927,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.7214,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152918,
    shape_pt_lon: -90.01214,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.7405,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15295,
    shape_pt_lon: -90.012428,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.7667,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152977,
    shape_pt_lon: -90.012638,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.7859,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15278,
    shape_pt_lon: -90.012669,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.8081,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151908,
    shape_pt_lon: -90.012814,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.906,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151783,
    shape_pt_lon: -90.012837,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.9201,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151617,
    shape_pt_lon: -90.012892,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.9398,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151501,
    shape_pt_lon: -90.012952,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.9537,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151374,
    shape_pt_lon: -90.013042,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.9698,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151238,
    shape_pt_lon: -90.013175,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.989,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151115,
    shape_pt_lon: -90.013341,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.0096,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150891,
    shape_pt_lon: -90.013742,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.0542,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150838,
    shape_pt_lon: -90.013825,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.0634,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150698,
    shape_pt_lon: -90.013979,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.0847,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150545,
    shape_pt_lon: -90.0141,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.1049,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150399,
    shape_pt_lon: -90.014178,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.1224,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.150237,
    shape_pt_lon: -90.014233,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.1411,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149378,
    shape_pt_lon: -90.014367,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.2378,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.14938,
    shape_pt_lon: -90.01441,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.2418,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149511,
    shape_pt_lon: -90.015519,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.3428,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149611,
    shape_pt_lon: -90.01636,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.4198,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149643,
    shape_pt_lon: -90.016648,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.4459,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149701,
    shape_pt_lon: -90.017441,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.5183,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149798,
    shape_pt_lon: -90.018533,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.6168,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.149979,
    shape_pt_lon: -90.020233,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.7722,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151476,
    shape_pt_lon: -90.020013,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.9404,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.151924,
    shape_pt_lon: -90.019951,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.9906,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.152919,
    shape_pt_lon: -90.019807,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.1034,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15335,
    shape_pt_lon: -90.019728,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.1519,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154144,
    shape_pt_lon: -90.019639,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.2413,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15425,
    shape_pt_lon: -90.019621,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.2524,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154361,
    shape_pt_lon: -90.0196,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.2656,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154425,
    shape_pt_lon: -90.020523,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.3489,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154436,
    shape_pt_lon: -90.020731,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.3679,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154439,
    shape_pt_lon: -90.020783,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.373,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154441,
    shape_pt_lon: -90.020831,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.377,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154447,
    shape_pt_lon: -90.020905,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.384,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154449,
    shape_pt_lon: -90.020939,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.3872,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154481,
    shape_pt_lon: -90.021157,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.4074,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154508,
    shape_pt_lon: -90.021326,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.4227,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154526,
    shape_pt_lon: -90.02153,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.4408,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154574,
    shape_pt_lon: -90.022157,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.4981,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154639,
    shape_pt_lon: -90.022649,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.5437,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154749,
    shape_pt_lon: -90.023857,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.6533,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154766,
    shape_pt_lon: -90.024049,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.6704,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154786,
    shape_pt_lon: -90.024364,
    shape_pt_sequence: 365,
    shape_dist_traveled: 20.6985,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.154851,
    shape_pt_lon: -90.025068,
    shape_pt_sequence: 366,
    shape_dist_traveled: 20.7629,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155171,
    shape_pt_lon: -90.028633,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.0869,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155228,
    shape_pt_lon: -90.029263,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.1442,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155388,
    shape_pt_lon: -90.031129,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.3142,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155422,
    shape_pt_lon: -90.031743,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.3693,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155372,
    shape_pt_lon: -90.031882,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.3832,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15538,
    shape_pt_lon: -90.032006,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.3942,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15543,
    shape_pt_lon: -90.032538,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.4426,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155449,
    shape_pt_lon: -90.032702,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.4578,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155472,
    shape_pt_lon: -90.032902,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.476,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155492,
    shape_pt_lon: -90.03317,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.5001,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155502,
    shape_pt_lon: -90.033488,
    shape_pt_sequence: 377,
    shape_dist_traveled: 21.5291,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15547,
    shape_pt_lon: -90.035344,
    shape_pt_sequence: 378,
    shape_dist_traveled: 21.6961,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155588,
    shape_pt_lon: -90.036716,
    shape_pt_sequence: 379,
    shape_dist_traveled: 21.8209,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155651,
    shape_pt_lon: -90.037308,
    shape_pt_sequence: 380,
    shape_dist_traveled: 21.8754,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15567,
    shape_pt_lon: -90.037578,
    shape_pt_sequence: 381,
    shape_dist_traveled: 21.8995,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155677,
    shape_pt_lon: -90.037661,
    shape_pt_sequence: 382,
    shape_dist_traveled: 21.9075,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155677,
    shape_pt_lon: -90.037869,
    shape_pt_sequence: 383,
    shape_dist_traveled: 21.9265,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155673,
    shape_pt_lon: -90.03803,
    shape_pt_sequence: 384,
    shape_dist_traveled: 21.9405,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155666,
    shape_pt_lon: -90.038121,
    shape_pt_sequence: 385,
    shape_dist_traveled: 21.9485,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155658,
    shape_pt_lon: -90.038242,
    shape_pt_sequence: 386,
    shape_dist_traveled: 21.9596,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155621,
    shape_pt_lon: -90.03848,
    shape_pt_sequence: 387,
    shape_dist_traveled: 21.9819,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15556,
    shape_pt_lon: -90.038782,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.0098,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155473,
    shape_pt_lon: -90.039066,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.0373,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155583,
    shape_pt_lon: -90.039057,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.0494,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155833,
    shape_pt_lon: -90.039054,
    shape_pt_sequence: 391,
    shape_dist_traveled: 22.0774,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15586,
    shape_pt_lon: -90.040531,
    shape_pt_sequence: 392,
    shape_dist_traveled: 22.2104,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155884,
    shape_pt_lon: -90.04161,
    shape_pt_sequence: 393,
    shape_dist_traveled: 22.3074,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155899,
    shape_pt_lon: -90.042236,
    shape_pt_sequence: 394,
    shape_dist_traveled: 22.3645,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155915,
    shape_pt_lon: -90.042959,
    shape_pt_sequence: 395,
    shape_dist_traveled: 22.4295,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155926,
    shape_pt_lon: -90.043449,
    shape_pt_sequence: 396,
    shape_dist_traveled: 22.4745,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155956,
    shape_pt_lon: -90.043664,
    shape_pt_sequence: 397,
    shape_dist_traveled: 22.4939,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156121,
    shape_pt_lon: -90.04429,
    shape_pt_sequence: 398,
    shape_dist_traveled: 22.5537,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 399,
    shape_dist_traveled: 22.6717,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 400,
    shape_dist_traveled: 22.7242,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 401,
    shape_dist_traveled: 22.7242,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 402,
    shape_dist_traveled: 22.7808,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 403,
    shape_dist_traveled: 22.7808,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 404,
    shape_dist_traveled: 22.7862,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 405,
    shape_dist_traveled: 22.8567,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 406,
    shape_dist_traveled: 22.9038,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 407,
    shape_dist_traveled: 22.9636,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 408,
    shape_dist_traveled: 22.9864,
  },
  {
    shape_id: 38250,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 409,
    shape_dist_traveled: 23.0528,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0664,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0892,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.149,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1575,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2666,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3707,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3837,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3891,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4441,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4655,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4718,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4799,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.4881,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5008,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6165,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6396,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6709,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7298,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7428,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8519,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.911,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.9695,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1397,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2026,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2681,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.3196,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3762,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.4351,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4613,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4948,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.554,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.6159,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.6726,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.7001,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7466,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.8085,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.8468,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.9165,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.0104,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.0607,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1163,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1414,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.2265,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.3323,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.4443,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.135843,
    shape_pt_lon: -90.056147,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5321,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.135726,
    shape_pt_lon: -90.056195,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5461,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.134526,
    shape_pt_lon: -90.05639,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6821,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.134034,
    shape_pt_lon: -90.056461,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7376,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13277,
    shape_pt_lon: -90.056506,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.8786,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13275,
    shape_pt_lon: -90.055818,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9407,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132744,
    shape_pt_lon: -90.055183,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9987,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.0497,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132721,
    shape_pt_lon: -90.054193,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0877,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132699,
    shape_pt_lon: -90.053449,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1548,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132667,
    shape_pt_lon: -90.051972,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.2888,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132662,
    shape_pt_lon: -90.051453,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.3358,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132636,
    shape_pt_lon: -90.050397,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.4309,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132618,
    shape_pt_lon: -90.049503,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.5119,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132615,
    shape_pt_lon: -90.049365,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.5249,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132604,
    shape_pt_lon: -90.048888,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.5679,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13259,
    shape_pt_lon: -90.048217,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.628,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132399,
    shape_pt_lon: -90.047989,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.6584,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.132059,
    shape_pt_lon: -90.047665,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.7062,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13183,
    shape_pt_lon: -90.047443,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.7382,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.131521,
    shape_pt_lon: -90.047519,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.7739,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.131393,
    shape_pt_lon: -90.047537,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.7879,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.13111,
    shape_pt_lon: -90.047533,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.8199,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.130189,
    shape_pt_lon: -90.04745,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.9232,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.129926,
    shape_pt_lon: -90.047423,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.9523,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.129591,
    shape_pt_lon: -90.047408,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.9903,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.129101,
    shape_pt_lon: -90.047364,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0454,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.129016,
    shape_pt_lon: -90.04735,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.0547,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.128892,
    shape_pt_lon: -90.04732,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.0688,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.1286,
    shape_pt_lon: -90.04722,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.103,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.128179,
    shape_pt_lon: -90.047005,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.1541,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.127938,
    shape_pt_lon: -90.046932,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.182,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.127709,
    shape_pt_lon: -90.0469,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.2071,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.127651,
    shape_pt_lon: -90.046898,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.2141,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.126751,
    shape_pt_lon: -90.046937,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.3152,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.126663,
    shape_pt_lon: -90.046941,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.3242,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.126235,
    shape_pt_lon: -90.046969,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.3723,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.125059,
    shape_pt_lon: -90.047006,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.5043,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.12477,
    shape_pt_lon: -90.047016,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.5364,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.124416,
    shape_pt_lon: -90.047028,
    shape_pt_sequence: 87,
    shape_dist_traveled: 4.5764,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.124376,
    shape_pt_lon: -90.04703,
    shape_pt_sequence: 88,
    shape_dist_traveled: 4.5804,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.124299,
    shape_pt_lon: -90.047033,
    shape_pt_sequence: 89,
    shape_dist_traveled: 4.5894,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.123606,
    shape_pt_lon: -90.047063,
    shape_pt_sequence: 90,
    shape_dist_traveled: 4.6665,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.123218,
    shape_pt_lon: -90.04708,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.7105,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.122383,
    shape_pt_lon: -90.047117,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.8035,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.121989,
    shape_pt_lon: -90.04713,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.8476,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.121307,
    shape_pt_lon: -90.047151,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.9246,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.119948,
    shape_pt_lon: -90.047201,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.0767,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.119656,
    shape_pt_lon: -90.047201,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.1087,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11919,
    shape_pt_lon: -90.047223,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.1617,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11911,
    shape_pt_lon: -90.04728,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.172,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.118853,
    shape_pt_lon: -90.04731,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.2001,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.1187,
    shape_pt_lon: -90.047315,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.2171,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.118449,
    shape_pt_lon: -90.047324,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.2461,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.117771,
    shape_pt_lon: -90.04735,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.3211,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.117137,
    shape_pt_lon: -90.047371,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.3922,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.117015,
    shape_pt_lon: -90.047375,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.4062,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11682,
    shape_pt_lon: -90.047379,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.4282,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.116579,
    shape_pt_lon: -90.047386,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.4552,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11639,
    shape_pt_lon: -90.047361,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.4763,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.116265,
    shape_pt_lon: -90.04732,
    shape_pt_sequence: 108,
    shape_dist_traveled: 5.4909,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.116209,
    shape_pt_lon: -90.047287,
    shape_pt_sequence: 109,
    shape_dist_traveled: 5.4976,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.116115,
    shape_pt_lon: -90.047231,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.5096,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.116077,
    shape_pt_lon: -90.047151,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.5177,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115979,
    shape_pt_lon: -90.047105,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.5294,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115869,
    shape_pt_lon: -90.047071,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.5418,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115787,
    shape_pt_lon: -90.043428,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.8719,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115782,
    shape_pt_lon: -90.043292,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.8839,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115783,
    shape_pt_lon: -90.043292,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.8839,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115729,
    shape_pt_lon: -90.040988,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.092,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115731,
    shape_pt_lon: -90.040991,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.092,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115719,
    shape_pt_lon: -90.040404,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.145,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115682,
    shape_pt_lon: -90.038879,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.2831,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115659,
    shape_pt_lon: -90.037797,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.3801,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115643,
    shape_pt_lon: -90.037169,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.4372,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115617,
    shape_pt_lon: -90.036039,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.5392,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115587,
    shape_pt_lon: -90.034629,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.6672,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115571,
    shape_pt_lon: -90.033973,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.7263,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115567,
    shape_pt_lon: -90.033757,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.7463,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115551,
    shape_pt_lon: -90.033067,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.8083,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11555,
    shape_pt_lon: -90.032981,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.8164,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115516,
    shape_pt_lon: -90.031497,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.9504,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.1155,
    shape_pt_lon: -90.030813,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.0124,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115489,
    shape_pt_lon: -90.030319,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.0564,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11547,
    shape_pt_lon: -90.029561,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.1255,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115463,
    shape_pt_lon: -90.029251,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.1535,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115454,
    shape_pt_lon: -90.028823,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.1925,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -90.028441,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.2265,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115439,
    shape_pt_lon: -90.02805,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.2615,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.2695,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115433,
    shape_pt_lon: -90.027829,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.2815,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.2916,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115429,
    shape_pt_lon: -90.02769,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.2946,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115426,
    shape_pt_lon: -90.027519,
    shape_pt_sequence: 141,
    shape_dist_traveled: 7.3096,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -90.026878,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.3676,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115399,
    shape_pt_lon: -90.026361,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.4146,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115399,
    shape_pt_lon: -90.026309,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.4196,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115373,
    shape_pt_lon: -90.025257,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.5147,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11535,
    shape_pt_lon: -90.02428,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.6027,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115347,
    shape_pt_lon: -90.024109,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.6187,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115342,
    shape_pt_lon: -90.023918,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.6357,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -90.023221,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.6988,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115323,
    shape_pt_lon: -90.023036,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.7158,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115322,
    shape_pt_lon: -90.022983,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.7198,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115309,
    shape_pt_lon: -90.021872,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.8208,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.115312,
    shape_pt_lon: -90.021665,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.8398,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11382,
    shape_pt_lon: -90.021719,
    shape_pt_sequence: 154,
    shape_dist_traveled: 8.0069,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.113101,
    shape_pt_lon: -90.021698,
    shape_pt_sequence: 155,
    shape_dist_traveled: 8.0869,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.112628,
    shape_pt_lon: -90.021685,
    shape_pt_sequence: 156,
    shape_dist_traveled: 8.1399,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11247,
    shape_pt_lon: -90.021688,
    shape_pt_sequence: 157,
    shape_dist_traveled: 8.1579,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.111539,
    shape_pt_lon: -90.021727,
    shape_pt_sequence: 158,
    shape_dist_traveled: 8.262,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10987,
    shape_pt_lon: -90.021796,
    shape_pt_sequence: 159,
    shape_dist_traveled: 8.4491,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109655,
    shape_pt_lon: -90.021754,
    shape_pt_sequence: 160,
    shape_dist_traveled: 8.4734,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109589,
    shape_pt_lon: -90.021741,
    shape_pt_sequence: 161,
    shape_dist_traveled: 8.4805,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109479,
    shape_pt_lon: -90.02172,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.4926,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109376,
    shape_pt_lon: -90.018399,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.7929,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109272,
    shape_pt_lon: -90.015197,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.0831,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10925,
    shape_pt_lon: -90.014871,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.1122,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109231,
    shape_pt_lon: -90.014597,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.1373,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109219,
    shape_pt_lon: -90.014198,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.1733,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109207,
    shape_pt_lon: -90.013719,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.2163,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109181,
    shape_pt_lon: -90.012399,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.3354,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109158,
    shape_pt_lon: -90.011469,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.4204,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109123,
    shape_pt_lon: -90.010688,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.4905,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109117,
    shape_pt_lon: -90.01055,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.5035,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10911,
    shape_pt_lon: -90.01033,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.5236,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109109,
    shape_pt_lon: -90.00998,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.5546,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109108,
    shape_pt_lon: -90.00984,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.5676,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109073,
    shape_pt_lon: -90.008797,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.6617,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108983,
    shape_pt_lon: -90.006042,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.9109,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108955,
    shape_pt_lon: -90.00509,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.9969,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108943,
    shape_pt_lon: -90.004812,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.0219,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108917,
    shape_pt_lon: -90.003623,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.13,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108916,
    shape_pt_lon: -90.003556,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.136,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10888,
    shape_pt_lon: -90.002148,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.263,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108837,
    shape_pt_lon: -90.000299,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.4301,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108781,
    shape_pt_lon: -89.999366,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.5153,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108743,
    shape_pt_lon: -89.998831,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.5635,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108696,
    shape_pt_lon: -89.998158,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.6248,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108684,
    shape_pt_lon: -89.99767,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.6688,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108681,
    shape_pt_lon: -89.997594,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.6758,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108679,
    shape_pt_lon: -89.997539,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.6808,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108667,
    shape_pt_lon: -89.9969,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.7378,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108686,
    shape_pt_lon: -89.996515,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.7729,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108744,
    shape_pt_lon: -89.996251,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.7979,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108877,
    shape_pt_lon: -89.995773,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.8434,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109247,
    shape_pt_lon: -89.994441,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.9712,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109671,
    shape_pt_lon: -89.992923,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.1164,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10978,
    shape_pt_lon: -89.992607,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.1468,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109882,
    shape_pt_lon: -89.99241,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.1679,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110001,
    shape_pt_lon: -89.992234,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.1892,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110219,
    shape_pt_lon: -89.991993,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.2217,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110282,
    shape_pt_lon: -89.991941,
    shape_pt_sequence: 200,
    shape_dist_traveled: 11.2303,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110333,
    shape_pt_lon: -89.991833,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.2411,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110357,
    shape_pt_lon: -89.991756,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.2488,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110359,
    shape_pt_lon: -89.991652,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.2588,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.11034,
    shape_pt_lon: -89.991489,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.2729,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.2812,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.4311,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.4635,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.5313,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.5448,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108697,
    shape_pt_lon: -89.988851,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.5754,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108579,
    shape_pt_lon: -89.988692,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.5945,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108565,
    shape_pt_lon: -89.988601,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.6037,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108561,
    shape_pt_lon: -89.988516,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.6107,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108583,
    shape_pt_lon: -89.988189,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.6408,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108572,
    shape_pt_lon: -89.987394,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.7128,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10856,
    shape_pt_lon: -89.986498,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.7938,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108559,
    shape_pt_lon: -89.986436,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.7988,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108549,
    shape_pt_lon: -89.985706,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.8648,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108538,
    shape_pt_lon: -89.984999,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.9288,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108534,
    shape_pt_lon: -89.98489,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.9389,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108533,
    shape_pt_lon: -89.984831,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.9439,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108528,
    shape_pt_lon: -89.984537,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.9709,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108458,
    shape_pt_lon: -89.983278,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.0852,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108414,
    shape_pt_lon: -89.982429,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.1623,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108403,
    shape_pt_lon: -89.982197,
    shape_pt_sequence: 225,
    shape_dist_traveled: 12.1833,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108379,
    shape_pt_lon: -89.981625,
    shape_pt_sequence: 226,
    shape_dist_traveled: 12.2344,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108371,
    shape_pt_lon: -89.98143,
    shape_pt_sequence: 227,
    shape_dist_traveled: 12.2525,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108369,
    shape_pt_lon: -89.980821,
    shape_pt_sequence: 228,
    shape_dist_traveled: 12.3075,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108369,
    shape_pt_lon: -89.980049,
    shape_pt_sequence: 229,
    shape_dist_traveled: 12.3775,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108361,
    shape_pt_lon: -89.979523,
    shape_pt_sequence: 230,
    shape_dist_traveled: 12.4245,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108356,
    shape_pt_lon: -89.979261,
    shape_pt_sequence: 231,
    shape_dist_traveled: 12.4485,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10834,
    shape_pt_lon: -89.978706,
    shape_pt_sequence: 232,
    shape_dist_traveled: 12.4985,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108333,
    shape_pt_lon: -89.978508,
    shape_pt_sequence: 233,
    shape_dist_traveled: 12.5165,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108314,
    shape_pt_lon: -89.977862,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.5746,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10831,
    shape_pt_lon: -89.97769,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.5906,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108291,
    shape_pt_lon: -89.97703,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.6506,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108238,
    shape_pt_lon: -89.97531,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.8057,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108209,
    shape_pt_lon: -89.974516,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.8778,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108207,
    shape_pt_lon: -89.974458,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.8828,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108183,
    shape_pt_lon: -89.97373,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.9488,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108175,
    shape_pt_lon: -89.973442,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.9749,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10816,
    shape_pt_lon: -89.972943,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.0199,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108139,
    shape_pt_lon: -89.97221,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.0859,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108136,
    shape_pt_lon: -89.972131,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.0929,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108111,
    shape_pt_lon: -89.971342,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.165,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108088,
    shape_pt_lon: -89.970547,
    shape_pt_sequence: 246,
    shape_dist_traveled: 13.237,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10806,
    shape_pt_lon: -89.969711,
    shape_pt_sequence: 247,
    shape_dist_traveled: 13.3121,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10803,
    shape_pt_lon: -89.968977,
    shape_pt_sequence: 248,
    shape_dist_traveled: 13.3792,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.108019,
    shape_pt_lon: -89.968091,
    shape_pt_sequence: 249,
    shape_dist_traveled: 13.4592,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107981,
    shape_pt_lon: -89.966828,
    shape_pt_sequence: 250,
    shape_dist_traveled: 13.5733,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107949,
    shape_pt_lon: -89.965754,
    shape_pt_sequence: 251,
    shape_dist_traveled: 13.6704,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10793,
    shape_pt_lon: -89.965142,
    shape_pt_sequence: 252,
    shape_dist_traveled: 13.7254,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107896,
    shape_pt_lon: -89.963879,
    shape_pt_sequence: 253,
    shape_dist_traveled: 13.8394,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10787,
    shape_pt_lon: -89.962728,
    shape_pt_sequence: 254,
    shape_dist_traveled: 13.9435,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10783,
    shape_pt_lon: -89.961463,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.0586,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107806,
    shape_pt_lon: -89.960875,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.1116,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107774,
    shape_pt_lon: -89.959789,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.2097,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107736,
    shape_pt_lon: -89.958523,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.3248,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107703,
    shape_pt_lon: -89.957417,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.4248,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107688,
    shape_pt_lon: -89.95687,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.4739,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107693,
    shape_pt_lon: -89.956381,
    shape_pt_sequence: 261,
    shape_dist_traveled: 14.5179,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107737,
    shape_pt_lon: -89.953992,
    shape_pt_sequence: 262,
    shape_dist_traveled: 14.734,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107765,
    shape_pt_lon: -89.952472,
    shape_pt_sequence: 263,
    shape_dist_traveled: 14.872,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107789,
    shape_pt_lon: -89.951302,
    shape_pt_sequence: 264,
    shape_dist_traveled: 14.977,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107838,
    shape_pt_lon: -89.948892,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.1951,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.94614,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.4442,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.946043,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.4532,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107882,
    shape_pt_lon: -89.945972,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.4592,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107883,
    shape_pt_lon: -89.945535,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.4992,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107794,
    shape_pt_lon: -89.942447,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.7783,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107769,
    shape_pt_lon: -89.941557,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.8584,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10774,
    shape_pt_lon: -89.940522,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.9524,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10769,
    shape_pt_lon: -89.938887,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.1005,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107685,
    shape_pt_lon: -89.938542,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.1315,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107617,
    shape_pt_lon: -89.935723,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.3866,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107608,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.4256,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.1076,
    shape_pt_lon: -89.934959,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.4556,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107585,
    shape_pt_lon: -89.934331,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.5117,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107522,
    shape_pt_lon: -89.931714,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.7487,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107499,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.8428,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10749,
    shape_pt_lon: -89.930335,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.8738,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107489,
    shape_pt_lon: -89.930207,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.8848,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107459,
    shape_pt_lon: -89.92892,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.0009,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107427,
    shape_pt_lon: -89.927641,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.1169,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107392,
    shape_pt_lon: -89.926229,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.245,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107352,
    shape_pt_lon: -89.924405,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.41,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10735,
    shape_pt_lon: -89.924306,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.4191,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107343,
    shape_pt_lon: -89.924121,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.4351,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107328,
    shape_pt_lon: -89.923551,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.4871,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.1073,
    shape_pt_lon: -89.922517,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.5801,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107271,
    shape_pt_lon: -89.921471,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.6752,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107256,
    shape_pt_lon: -89.92088,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.7282,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107235,
    shape_pt_lon: -89.920151,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.7943,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107229,
    shape_pt_lon: -89.91993,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.8143,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107212,
    shape_pt_lon: -89.919373,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.8653,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107184,
    shape_pt_lon: -89.918583,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.9364,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107113,
    shape_pt_lon: -89.915669,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.1995,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107082,
    shape_pt_lon: -89.914544,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.3015,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10708,
    shape_pt_lon: -89.914447,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.3105,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107056,
    shape_pt_lon: -89.91352,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.3946,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107029,
    shape_pt_lon: -89.912493,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.4866,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107016,
    shape_pt_lon: -89.911906,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.5406,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107013,
    shape_pt_lon: -89.911833,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.5467,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107003,
    shape_pt_lon: -89.911463,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.5807,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.107,
    shape_pt_lon: -89.911292,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.5957,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106976,
    shape_pt_lon: -89.910393,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.6768,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10697,
    shape_pt_lon: -89.91019,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.6958,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10692,
    shape_pt_lon: -89.908132,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.8818,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10691,
    shape_pt_lon: -89.907861,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.9059,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106823,
    shape_pt_lon: -89.904539,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.206,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106782,
    shape_pt_lon: -89.90295,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.3501,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10677,
    shape_pt_lon: -89.902536,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.3881,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106762,
    shape_pt_lon: -89.902262,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.4121,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10674,
    shape_pt_lon: -89.901399,
    shape_pt_sequence: 314,
    shape_dist_traveled: 19.4902,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106737,
    shape_pt_lon: -89.901288,
    shape_pt_sequence: 315,
    shape_dist_traveled: 19.5002,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10669,
    shape_pt_lon: -89.899388,
    shape_pt_sequence: 316,
    shape_dist_traveled: 19.6723,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106676,
    shape_pt_lon: -89.898782,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.7273,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106587,
    shape_pt_lon: -89.895397,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.0334,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.892862,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.2626,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10649,
    shape_pt_lon: -89.891854,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.3536,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106467,
    shape_pt_lon: -89.891598,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.3767,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106401,
    shape_pt_lon: -89.891231,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.4116,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106348,
    shape_pt_lon: -89.891019,
    shape_pt_sequence: 323,
    shape_dist_traveled: 20.4315,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.106093,
    shape_pt_lon: -89.890303,
    shape_pt_sequence: 324,
    shape_dist_traveled: 20.5014,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.105426,
    shape_pt_lon: -89.888526,
    shape_pt_sequence: 325,
    shape_dist_traveled: 20.6786,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.105028,
    shape_pt_lon: -89.887443,
    shape_pt_sequence: 326,
    shape_dist_traveled: 20.7864,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.104947,
    shape_pt_lon: -89.887249,
    shape_pt_sequence: 327,
    shape_dist_traveled: 20.8066,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.104709,
    shape_pt_lon: -89.886608,
    shape_pt_sequence: 328,
    shape_dist_traveled: 20.8701,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10386,
    shape_pt_lon: -89.884333,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.097,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.103102,
    shape_pt_lon: -89.882291,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.2992,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.102746,
    shape_pt_lon: -89.881326,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.3959,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.102488,
    shape_pt_lon: -89.880641,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.4643,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.102341,
    shape_pt_lon: -89.880251,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.5028,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.10196,
    shape_pt_lon: -89.879218,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.6053,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.101371,
    shape_pt_lon: -89.877648,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.7615,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.101271,
    shape_pt_lon: -89.877378,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.7888,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.100766,
    shape_pt_lon: -89.876027,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.9234,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.100637,
    shape_pt_lon: -89.875697,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.9565,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.100334,
    shape_pt_lon: -89.874956,
    shape_pt_sequence: 339,
    shape_dist_traveled: 22.0317,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.100073,
    shape_pt_lon: -89.874256,
    shape_pt_sequence: 340,
    shape_dist_traveled: 22.101,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099966,
    shape_pt_lon: -89.873973,
    shape_pt_sequence: 341,
    shape_dist_traveled: 22.1297,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099849,
    shape_pt_lon: -89.873602,
    shape_pt_sequence: 342,
    shape_dist_traveled: 22.1661,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099777,
    shape_pt_lon: -89.873277,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.1961,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099697,
    shape_pt_lon: -89.872745,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.245,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099672,
    shape_pt_lon: -89.872419,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.275,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099668,
    shape_pt_lon: -89.872062,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.3071,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.09971,
    shape_pt_lon: -89.870609,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.4382,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099717,
    shape_pt_lon: -89.870391,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.4582,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.09972,
    shape_pt_lon: -89.870318,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.4652,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099721,
    shape_pt_lon: -89.87024,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.4723,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099726,
    shape_pt_lon: -89.870115,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.4833,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099733,
    shape_pt_lon: -89.869863,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.5063,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.09974,
    shape_pt_lon: -89.869638,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.5263,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099778,
    shape_pt_lon: -89.868666,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.6144,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099812,
    shape_pt_lon: -89.867252,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.7425,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099827,
    shape_pt_lon: -89.866732,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.7895,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099867,
    shape_pt_lon: -89.865811,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.8726,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099881,
    shape_pt_lon: -89.865343,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.9147,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099911,
    shape_pt_lon: -89.864052,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.0317,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099929,
    shape_pt_lon: -89.86352,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.0798,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099977,
    shape_pt_lon: -89.862802,
    shape_pt_sequence: 361,
    shape_dist_traveled: 23.145,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099982,
    shape_pt_lon: -89.862622,
    shape_pt_sequence: 362,
    shape_dist_traveled: 23.161,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.099509,
    shape_pt_lon: -89.862632,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.2141,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.098972,
    shape_pt_lon: -89.862643,
    shape_pt_sequence: 364,
    shape_dist_traveled: 23.2741,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.098746,
    shape_pt_lon: -89.862648,
    shape_pt_sequence: 365,
    shape_dist_traveled: 23.2991,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.098389,
    shape_pt_lon: -89.862656,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.3391,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.0982,
    shape_pt_lon: -89.862659,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.3601,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.09773,
    shape_pt_lon: -89.86267,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.4131,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.09704,
    shape_pt_lon: -89.862688,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.4901,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.0969,
    shape_pt_lon: -89.862691,
    shape_pt_sequence: 370,
    shape_dist_traveled: 23.5061,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.096251,
    shape_pt_lon: -89.862704,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.5781,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.096128,
    shape_pt_lon: -89.862701,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.5921,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095986,
    shape_pt_lon: -89.862681,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.6082,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095845,
    shape_pt_lon: -89.86264,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.6247,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095687,
    shape_pt_lon: -89.86256,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.6431,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095539,
    shape_pt_lon: -89.862452,
    shape_pt_sequence: 376,
    shape_dist_traveled: 23.6628,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.0954,
    shape_pt_lon: -89.86231,
    shape_pt_sequence: 377,
    shape_dist_traveled: 23.682,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095329,
    shape_pt_lon: -89.862217,
    shape_pt_sequence: 378,
    shape_dist_traveled: 23.6941,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095208,
    shape_pt_lon: -89.861993,
    shape_pt_sequence: 379,
    shape_dist_traveled: 23.7185,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095142,
    shape_pt_lon: -89.861811,
    shape_pt_sequence: 380,
    shape_dist_traveled: 23.7369,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095093,
    shape_pt_lon: -89.861616,
    shape_pt_sequence: 381,
    shape_dist_traveled: 23.7549,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095079,
    shape_pt_lon: -89.861377,
    shape_pt_sequence: 382,
    shape_dist_traveled: 23.7769,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095083,
    shape_pt_lon: -89.861119,
    shape_pt_sequence: 383,
    shape_dist_traveled: 23.7999,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095085,
    shape_pt_lon: -89.861062,
    shape_pt_sequence: 384,
    shape_dist_traveled: 23.805,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095057,
    shape_pt_lon: -89.859611,
    shape_pt_sequence: 385,
    shape_dist_traveled: 23.9371,
  },
  {
    shape_id: 38251,
    shape_pt_lat: 35.095037,
    shape_pt_lon: -89.858582,
    shape_pt_sequence: 386,
    shape_dist_traveled: 24.0301,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.095037,
    shape_pt_lon: -89.858582,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.095113,
    shape_pt_lon: -89.858571,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0081,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.095938,
    shape_pt_lon: -89.85854,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1011,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.096419,
    shape_pt_lon: -89.85854,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1541,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.096936,
    shape_pt_lon: -89.858538,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.2121,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.097448,
    shape_pt_lon: -89.858541,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2701,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.097953,
    shape_pt_lon: -89.858599,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3264,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.098123,
    shape_pt_lon: -89.858543,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3461,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.098639,
    shape_pt_lon: -89.858657,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4049,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09958,
    shape_pt_lon: -89.858896,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5122,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099912,
    shape_pt_lon: -89.858977,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5498,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099926,
    shape_pt_lon: -89.859173,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.568,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099941,
    shape_pt_lon: -89.859591,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.606,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09995,
    shape_pt_lon: -89.85997,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.64,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099949,
    shape_pt_lon: -89.860166,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.658,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09997,
    shape_pt_lon: -89.860791,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.714,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099975,
    shape_pt_lon: -89.860962,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.73,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099978,
    shape_pt_lon: -89.861103,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.743,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099991,
    shape_pt_lon: -89.861678,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7941,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099982,
    shape_pt_lon: -89.862622,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8801,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099977,
    shape_pt_lon: -89.862802,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.8961,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099929,
    shape_pt_lon: -89.86352,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.9613,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099911,
    shape_pt_lon: -89.864052,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.0094,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099881,
    shape_pt_lon: -89.865343,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.1265,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099867,
    shape_pt_lon: -89.865811,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.1685,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099827,
    shape_pt_lon: -89.866732,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.2516,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099812,
    shape_pt_lon: -89.867252,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.2987,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099778,
    shape_pt_lon: -89.868666,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4267,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09974,
    shape_pt_lon: -89.869638,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.5148,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099733,
    shape_pt_lon: -89.869863,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.5348,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099726,
    shape_pt_lon: -89.870115,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.5579,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099721,
    shape_pt_lon: -89.87024,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.5689,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09972,
    shape_pt_lon: -89.870318,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5759,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099717,
    shape_pt_lon: -89.870391,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5829,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.09971,
    shape_pt_lon: -89.870609,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.603,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099668,
    shape_pt_lon: -89.872062,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.734,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099672,
    shape_pt_lon: -89.872419,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.7661,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099697,
    shape_pt_lon: -89.872745,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7961,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099777,
    shape_pt_lon: -89.873277,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.845,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099849,
    shape_pt_lon: -89.873602,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.875,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.099966,
    shape_pt_lon: -89.873973,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9114,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.100073,
    shape_pt_lon: -89.874256,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9401,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.100334,
    shape_pt_lon: -89.874956,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0094,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.100637,
    shape_pt_lon: -89.875697,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.0846,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.100766,
    shape_pt_lon: -89.876027,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1177,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.101271,
    shape_pt_lon: -89.877378,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.2523,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.101371,
    shape_pt_lon: -89.877648,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2797,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10196,
    shape_pt_lon: -89.879218,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.4358,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.102341,
    shape_pt_lon: -89.880251,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5383,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.102488,
    shape_pt_lon: -89.880641,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5768,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.102746,
    shape_pt_lon: -89.881326,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.6452,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.103102,
    shape_pt_lon: -89.882291,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7419,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10386,
    shape_pt_lon: -89.884333,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.9441,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.104709,
    shape_pt_lon: -89.886608,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.171,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.104947,
    shape_pt_lon: -89.887249,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.2346,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.105028,
    shape_pt_lon: -89.887443,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.2547,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.105426,
    shape_pt_lon: -89.888526,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.3625,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106093,
    shape_pt_lon: -89.890303,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.5397,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106348,
    shape_pt_lon: -89.891019,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.6096,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106401,
    shape_pt_lon: -89.891231,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.6295,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106467,
    shape_pt_lon: -89.891598,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.6644,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10649,
    shape_pt_lon: -89.891854,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.6875,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106513,
    shape_pt_lon: -89.892862,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.7786,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106587,
    shape_pt_lon: -89.895397,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.0077,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106676,
    shape_pt_lon: -89.898782,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.3138,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10669,
    shape_pt_lon: -89.899388,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.3688,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106737,
    shape_pt_lon: -89.901288,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.5409,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10674,
    shape_pt_lon: -89.901399,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.551,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106762,
    shape_pt_lon: -89.902262,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.629,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10677,
    shape_pt_lon: -89.902536,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.653,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106782,
    shape_pt_lon: -89.90295,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.691,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106823,
    shape_pt_lon: -89.904539,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.8351,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10691,
    shape_pt_lon: -89.907861,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.1352,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10692,
    shape_pt_lon: -89.908132,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.1593,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10697,
    shape_pt_lon: -89.91019,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.3453,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.106976,
    shape_pt_lon: -89.910393,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.3644,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107,
    shape_pt_lon: -89.911292,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.4454,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107003,
    shape_pt_lon: -89.911463,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.4604,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107013,
    shape_pt_lon: -89.911833,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.4944,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107016,
    shape_pt_lon: -89.911906,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.5005,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107029,
    shape_pt_lon: -89.912493,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.5545,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107056,
    shape_pt_lon: -89.91352,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.6465,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10708,
    shape_pt_lon: -89.914447,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.7306,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107082,
    shape_pt_lon: -89.914544,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.7396,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107113,
    shape_pt_lon: -89.915669,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.8416,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107184,
    shape_pt_lon: -89.918583,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.1048,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107212,
    shape_pt_lon: -89.919373,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.1758,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107229,
    shape_pt_lon: -89.91993,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.2268,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107235,
    shape_pt_lon: -89.920151,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.2469,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107256,
    shape_pt_lon: -89.92088,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.3129,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107271,
    shape_pt_lon: -89.921471,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.3659,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.1073,
    shape_pt_lon: -89.922517,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.461,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107328,
    shape_pt_lon: -89.923551,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.554,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107343,
    shape_pt_lon: -89.924121,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.6061,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10735,
    shape_pt_lon: -89.924306,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.6221,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107352,
    shape_pt_lon: -89.924405,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.6311,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107392,
    shape_pt_lon: -89.926229,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.7962,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107427,
    shape_pt_lon: -89.927641,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.9242,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107459,
    shape_pt_lon: -89.92892,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.0403,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107489,
    shape_pt_lon: -89.930207,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.1563,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10749,
    shape_pt_lon: -89.930335,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.1673,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107499,
    shape_pt_lon: -89.930668,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.1983,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107522,
    shape_pt_lon: -89.931714,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.2924,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107585,
    shape_pt_lon: -89.934331,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.5294,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.1076,
    shape_pt_lon: -89.934959,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.5855,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107608,
    shape_pt_lon: -89.935283,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.6155,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107617,
    shape_pt_lon: -89.935723,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.6545,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107685,
    shape_pt_lon: -89.938542,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.9096,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10769,
    shape_pt_lon: -89.938887,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.9406,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10774,
    shape_pt_lon: -89.940522,
    shape_pt_sequence: 110,
    shape_dist_traveled: 8.0887,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107769,
    shape_pt_lon: -89.941557,
    shape_pt_sequence: 111,
    shape_dist_traveled: 8.1827,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107794,
    shape_pt_lon: -89.942447,
    shape_pt_sequence: 112,
    shape_dist_traveled: 8.2628,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107883,
    shape_pt_lon: -89.945535,
    shape_pt_sequence: 113,
    shape_dist_traveled: 8.5419,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107882,
    shape_pt_lon: -89.945972,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.5819,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.946043,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.5879,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107881,
    shape_pt_lon: -89.94614,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.5969,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107838,
    shape_pt_lon: -89.948892,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.846,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107789,
    shape_pt_lon: -89.951302,
    shape_pt_sequence: 118,
    shape_dist_traveled: 9.0641,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107765,
    shape_pt_lon: -89.952472,
    shape_pt_sequence: 119,
    shape_dist_traveled: 9.1691,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107737,
    shape_pt_lon: -89.953992,
    shape_pt_sequence: 120,
    shape_dist_traveled: 9.3072,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107693,
    shape_pt_lon: -89.956381,
    shape_pt_sequence: 121,
    shape_dist_traveled: 9.5232,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107688,
    shape_pt_lon: -89.95687,
    shape_pt_sequence: 122,
    shape_dist_traveled: 9.5672,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107703,
    shape_pt_lon: -89.957417,
    shape_pt_sequence: 123,
    shape_dist_traveled: 9.6163,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107736,
    shape_pt_lon: -89.958523,
    shape_pt_sequence: 124,
    shape_dist_traveled: 9.7163,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107774,
    shape_pt_lon: -89.959789,
    shape_pt_sequence: 125,
    shape_dist_traveled: 9.8314,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107806,
    shape_pt_lon: -89.960875,
    shape_pt_sequence: 126,
    shape_dist_traveled: 9.9295,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10783,
    shape_pt_lon: -89.961463,
    shape_pt_sequence: 127,
    shape_dist_traveled: 9.9825,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10787,
    shape_pt_lon: -89.962728,
    shape_pt_sequence: 128,
    shape_dist_traveled: 10.0976,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107896,
    shape_pt_lon: -89.963879,
    shape_pt_sequence: 129,
    shape_dist_traveled: 10.2017,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10793,
    shape_pt_lon: -89.965142,
    shape_pt_sequence: 130,
    shape_dist_traveled: 10.3157,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107949,
    shape_pt_lon: -89.965754,
    shape_pt_sequence: 131,
    shape_dist_traveled: 10.3707,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.107981,
    shape_pt_lon: -89.966828,
    shape_pt_sequence: 132,
    shape_dist_traveled: 10.4678,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108019,
    shape_pt_lon: -89.968091,
    shape_pt_sequence: 133,
    shape_dist_traveled: 10.5819,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10803,
    shape_pt_lon: -89.968977,
    shape_pt_sequence: 134,
    shape_dist_traveled: 10.6619,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10806,
    shape_pt_lon: -89.969711,
    shape_pt_sequence: 135,
    shape_dist_traveled: 10.729,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108088,
    shape_pt_lon: -89.970547,
    shape_pt_sequence: 136,
    shape_dist_traveled: 10.8041,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108111,
    shape_pt_lon: -89.971342,
    shape_pt_sequence: 137,
    shape_dist_traveled: 10.8761,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108136,
    shape_pt_lon: -89.972131,
    shape_pt_sequence: 138,
    shape_dist_traveled: 10.9482,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108139,
    shape_pt_lon: -89.97221,
    shape_pt_sequence: 139,
    shape_dist_traveled: 10.9552,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10816,
    shape_pt_lon: -89.972943,
    shape_pt_sequence: 140,
    shape_dist_traveled: 11.0212,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108175,
    shape_pt_lon: -89.973442,
    shape_pt_sequence: 141,
    shape_dist_traveled: 11.0662,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108183,
    shape_pt_lon: -89.97373,
    shape_pt_sequence: 142,
    shape_dist_traveled: 11.0923,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108207,
    shape_pt_lon: -89.974458,
    shape_pt_sequence: 143,
    shape_dist_traveled: 11.1583,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108209,
    shape_pt_lon: -89.974516,
    shape_pt_sequence: 144,
    shape_dist_traveled: 11.1633,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108238,
    shape_pt_lon: -89.97531,
    shape_pt_sequence: 145,
    shape_dist_traveled: 11.2354,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108291,
    shape_pt_lon: -89.97703,
    shape_pt_sequence: 146,
    shape_dist_traveled: 11.3905,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10831,
    shape_pt_lon: -89.97769,
    shape_pt_sequence: 147,
    shape_dist_traveled: 11.4505,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108314,
    shape_pt_lon: -89.977862,
    shape_pt_sequence: 148,
    shape_dist_traveled: 11.4665,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108333,
    shape_pt_lon: -89.978508,
    shape_pt_sequence: 149,
    shape_dist_traveled: 11.5246,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10834,
    shape_pt_lon: -89.978706,
    shape_pt_sequence: 150,
    shape_dist_traveled: 11.5426,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108356,
    shape_pt_lon: -89.979261,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.5926,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108361,
    shape_pt_lon: -89.979523,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.6166,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108369,
    shape_pt_lon: -89.980049,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.6637,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108369,
    shape_pt_lon: -89.980821,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.7337,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108371,
    shape_pt_lon: -89.98143,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.7887,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108379,
    shape_pt_lon: -89.981625,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.8067,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108403,
    shape_pt_lon: -89.982197,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.8578,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108414,
    shape_pt_lon: -89.982429,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.8788,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108458,
    shape_pt_lon: -89.983278,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.956,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108528,
    shape_pt_lon: -89.984537,
    shape_pt_sequence: 160,
    shape_dist_traveled: 12.0702,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108533,
    shape_pt_lon: -89.984831,
    shape_pt_sequence: 161,
    shape_dist_traveled: 12.0972,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108534,
    shape_pt_lon: -89.98489,
    shape_pt_sequence: 162,
    shape_dist_traveled: 12.1022,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108538,
    shape_pt_lon: -89.984999,
    shape_pt_sequence: 163,
    shape_dist_traveled: 12.1123,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108549,
    shape_pt_lon: -89.985706,
    shape_pt_sequence: 164,
    shape_dist_traveled: 12.1763,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108559,
    shape_pt_lon: -89.986436,
    shape_pt_sequence: 165,
    shape_dist_traveled: 12.2423,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10856,
    shape_pt_lon: -89.986498,
    shape_pt_sequence: 166,
    shape_dist_traveled: 12.2473,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108572,
    shape_pt_lon: -89.987394,
    shape_pt_sequence: 167,
    shape_dist_traveled: 12.3283,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108583,
    shape_pt_lon: -89.988189,
    shape_pt_sequence: 168,
    shape_dist_traveled: 12.4003,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108653,
    shape_pt_lon: -89.988509,
    shape_pt_sequence: 169,
    shape_dist_traveled: 12.4304,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10869,
    shape_pt_lon: -89.988611,
    shape_pt_sequence: 170,
    shape_dist_traveled: 12.4402,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10876,
    shape_pt_lon: -89.988751,
    shape_pt_sequence: 171,
    shape_dist_traveled: 12.4555,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108818,
    shape_pt_lon: -89.98895,
    shape_pt_sequence: 172,
    shape_dist_traveled: 12.4748,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108867,
    shape_pt_lon: -89.989116,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.4906,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.108944,
    shape_pt_lon: -89.989226,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.5041,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109312,
    shape_pt_lon: -89.989828,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.5719,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109473,
    shape_pt_lon: -89.990119,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.6043,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110321,
    shape_pt_lon: -89.991401,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.7543,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11056,
    shape_pt_lon: -89.991772,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.7963,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11065,
    shape_pt_lon: -89.991911,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.8127,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110729,
    shape_pt_lon: -89.992034,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.8269,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110539,
    shape_pt_lon: -89.992156,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.8506,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110519,
    shape_pt_lon: -89.992169,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.8528,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110329,
    shape_pt_lon: -89.99236,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.8806,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110232,
    shape_pt_lon: -89.992486,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.8963,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110157,
    shape_pt_lon: -89.992621,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.9113,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.110049,
    shape_pt_lon: -89.992891,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.9381,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109953,
    shape_pt_lon: -89.993204,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.9691,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109852,
    shape_pt_lon: -89.993593,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.0058,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109618,
    shape_pt_lon: -89.994428,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.0852,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109233,
    shape_pt_lon: -89.995797,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.2164,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109055,
    shape_pt_lon: -89.99647,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.2806,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109027,
    shape_pt_lon: -89.996622,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.294,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109009,
    shape_pt_lon: -89.996908,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.3201,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109036,
    shape_pt_lon: -89.997625,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.3852,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109042,
    shape_pt_lon: -89.998156,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.4332,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109047,
    shape_pt_lon: -89.998827,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.4942,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109013,
    shape_pt_lon: -89.999248,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.5324,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109011,
    shape_pt_lon: -89.999369,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.5434,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109013,
    shape_pt_lon: -90.000336,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.6304,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109056,
    shape_pt_lon: -90.002141,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.7935,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109089,
    shape_pt_lon: -90.00362,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.9275,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109119,
    shape_pt_lon: -90.004785,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.0326,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109128,
    shape_pt_lon: -90.005081,
    shape_pt_sequence: 203,
    shape_dist_traveled: 14.0596,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109157,
    shape_pt_lon: -90.006036,
    shape_pt_sequence: 204,
    shape_dist_traveled: 14.1457,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109249,
    shape_pt_lon: -90.008801,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.3959,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109289,
    shape_pt_lon: -90.010027,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.507,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109296,
    shape_pt_lon: -90.010192,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.522,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109309,
    shape_pt_lon: -90.010576,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.557,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109311,
    shape_pt_lon: -90.010682,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.566,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109339,
    shape_pt_lon: -90.011461,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.6371,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10935,
    shape_pt_lon: -90.011701,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.6582,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109353,
    shape_pt_lon: -90.012048,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.6902,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109323,
    shape_pt_lon: -90.012399,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.7223,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109307,
    shape_pt_lon: -90.012607,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.7404,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109305,
    shape_pt_lon: -90.012642,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.7434,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109266,
    shape_pt_lon: -90.01337,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.8096,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109265,
    shape_pt_lon: -90.013719,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.8416,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109278,
    shape_pt_lon: -90.014183,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.8837,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109298,
    shape_pt_lon: -90.014619,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.9227,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109352,
    shape_pt_lon: -90.014869,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.9465,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109376,
    shape_pt_lon: -90.014957,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.9547,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109451,
    shape_pt_lon: -90.015345,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.9909,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109472,
    shape_pt_lon: -90.015758,
    shape_pt_sequence: 223,
    shape_dist_traveled: 15.0279,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109518,
    shape_pt_lon: -90.017288,
    shape_pt_sequence: 224,
    shape_dist_traveled: 15.166,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109533,
    shape_pt_lon: -90.017901,
    shape_pt_sequence: 225,
    shape_dist_traveled: 15.2221,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109557,
    shape_pt_lon: -90.018411,
    shape_pt_sequence: 226,
    shape_dist_traveled: 15.2681,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109579,
    shape_pt_lon: -90.019054,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.3262,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109643,
    shape_pt_lon: -90.020999,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.5023,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109652,
    shape_pt_lon: -90.021647,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.5603,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.109655,
    shape_pt_lon: -90.021754,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.5703,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.10987,
    shape_pt_lon: -90.021796,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.5947,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.111539,
    shape_pt_lon: -90.021727,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.7818,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11247,
    shape_pt_lon: -90.021688,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.8858,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.112628,
    shape_pt_lon: -90.021685,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.9038,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.113101,
    shape_pt_lon: -90.021698,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.9568,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11382,
    shape_pt_lon: -90.021719,
    shape_pt_sequence: 236,
    shape_dist_traveled: 16.0369,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115312,
    shape_pt_lon: -90.021665,
    shape_pt_sequence: 237,
    shape_dist_traveled: 16.2039,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115309,
    shape_pt_lon: -90.021872,
    shape_pt_sequence: 238,
    shape_dist_traveled: 16.2229,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115322,
    shape_pt_lon: -90.022983,
    shape_pt_sequence: 239,
    shape_dist_traveled: 16.3239,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115323,
    shape_pt_lon: -90.023036,
    shape_pt_sequence: 240,
    shape_dist_traveled: 16.3279,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115328,
    shape_pt_lon: -90.023221,
    shape_pt_sequence: 241,
    shape_dist_traveled: 16.345,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115342,
    shape_pt_lon: -90.023918,
    shape_pt_sequence: 242,
    shape_dist_traveled: 16.408,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115347,
    shape_pt_lon: -90.024109,
    shape_pt_sequence: 243,
    shape_dist_traveled: 16.425,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11535,
    shape_pt_lon: -90.02428,
    shape_pt_sequence: 244,
    shape_dist_traveled: 16.441,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115373,
    shape_pt_lon: -90.025257,
    shape_pt_sequence: 245,
    shape_dist_traveled: 16.5291,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115399,
    shape_pt_lon: -90.026309,
    shape_pt_sequence: 246,
    shape_dist_traveled: 16.6241,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115399,
    shape_pt_lon: -90.026361,
    shape_pt_sequence: 247,
    shape_dist_traveled: 16.6291,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115411,
    shape_pt_lon: -90.026878,
    shape_pt_sequence: 248,
    shape_dist_traveled: 16.6761,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115426,
    shape_pt_lon: -90.027519,
    shape_pt_sequence: 249,
    shape_dist_traveled: 16.7342,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115429,
    shape_pt_lon: -90.02769,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.7492,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11543,
    shape_pt_lon: -90.027728,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.7522,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115433,
    shape_pt_lon: -90.027829,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.7622,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115437,
    shape_pt_lon: -90.027968,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.7742,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115439,
    shape_pt_lon: -90.02805,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.7822,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115447,
    shape_pt_lon: -90.028441,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.8172,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115454,
    shape_pt_lon: -90.028823,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.8512,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115463,
    shape_pt_lon: -90.029251,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.8902,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11547,
    shape_pt_lon: -90.029561,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.9183,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115489,
    shape_pt_lon: -90.030319,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.9873,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.1155,
    shape_pt_lon: -90.030813,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.0313,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115516,
    shape_pt_lon: -90.031497,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.0933,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11555,
    shape_pt_lon: -90.032981,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.2274,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115551,
    shape_pt_lon: -90.033067,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.2354,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115567,
    shape_pt_lon: -90.033757,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.2974,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115571,
    shape_pt_lon: -90.033973,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.3175,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115587,
    shape_pt_lon: -90.034629,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.3765,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115617,
    shape_pt_lon: -90.036039,
    shape_pt_sequence: 267,
    shape_dist_traveled: 17.5045,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115643,
    shape_pt_lon: -90.037169,
    shape_pt_sequence: 268,
    shape_dist_traveled: 17.6066,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115659,
    shape_pt_lon: -90.037797,
    shape_pt_sequence: 269,
    shape_dist_traveled: 17.6636,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115682,
    shape_pt_lon: -90.038879,
    shape_pt_sequence: 270,
    shape_dist_traveled: 17.7606,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115719,
    shape_pt_lon: -90.040404,
    shape_pt_sequence: 271,
    shape_dist_traveled: 17.8987,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115731,
    shape_pt_lon: -90.040991,
    shape_pt_sequence: 272,
    shape_dist_traveled: 17.9517,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115729,
    shape_pt_lon: -90.040988,
    shape_pt_sequence: 273,
    shape_dist_traveled: 17.9517,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115783,
    shape_pt_lon: -90.043292,
    shape_pt_sequence: 274,
    shape_dist_traveled: 18.1598,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115782,
    shape_pt_lon: -90.043292,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.1598,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115787,
    shape_pt_lon: -90.043428,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.1718,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115869,
    shape_pt_lon: -90.047071,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.502,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.115979,
    shape_pt_lon: -90.047105,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.5143,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.116077,
    shape_pt_lon: -90.047151,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.526,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.116151,
    shape_pt_lon: -90.047146,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.534,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.116219,
    shape_pt_lon: -90.047177,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.5423,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.116338,
    shape_pt_lon: -90.047214,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.5559,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.116528,
    shape_pt_lon: -90.047225,
    shape_pt_sequence: 283,
    shape_dist_traveled: 18.5779,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11681,
    shape_pt_lon: -90.047211,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.609,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11701,
    shape_pt_lon: -90.047205,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.631,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.117134,
    shape_pt_lon: -90.047201,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.645,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11778,
    shape_pt_lon: -90.04718,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.718,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.118447,
    shape_pt_lon: -90.047158,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.792,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.118686,
    shape_pt_lon: -90.047154,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.819,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.119109,
    shape_pt_lon: -90.047168,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.8661,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.11919,
    shape_pt_lon: -90.047223,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.8764,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.119656,
    shape_pt_lon: -90.047201,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.9294,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.119948,
    shape_pt_lon: -90.047201,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.9614,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.121307,
    shape_pt_lon: -90.047151,
    shape_pt_sequence: 294,
    shape_dist_traveled: 19.1135,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.121989,
    shape_pt_lon: -90.04713,
    shape_pt_sequence: 295,
    shape_dist_traveled: 19.1905,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.122383,
    shape_pt_lon: -90.047117,
    shape_pt_sequence: 296,
    shape_dist_traveled: 19.2345,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.123218,
    shape_pt_lon: -90.04708,
    shape_pt_sequence: 297,
    shape_dist_traveled: 19.3276,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.123606,
    shape_pt_lon: -90.047063,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.3716,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.124299,
    shape_pt_lon: -90.047033,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.4486,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.124376,
    shape_pt_lon: -90.04703,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.4577,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.124374,
    shape_pt_lon: -90.04699,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.4609,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.12437,
    shape_pt_lon: -90.04686,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.4729,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.124413,
    shape_pt_lon: -90.04686,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.4779,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.12505,
    shape_pt_lon: -90.046848,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.5489,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.126663,
    shape_pt_lon: -90.046803,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.7299,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.126749,
    shape_pt_lon: -90.046801,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.7389,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.127652,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.84,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.12772,
    shape_pt_lon: -90.046771,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.848,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.128,
    shape_pt_lon: -90.046811,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.8792,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.128212,
    shape_pt_lon: -90.046883,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.904,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.128669,
    shape_pt_lon: -90.047111,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.9591,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.128903,
    shape_pt_lon: -90.047189,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.986,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.129018,
    shape_pt_lon: -90.047208,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.9991,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.129596,
    shape_pt_lon: -90.047262,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.0643,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.130189,
    shape_pt_lon: -90.047319,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.1305,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131169,
    shape_pt_lon: -90.047409,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.2408,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131391,
    shape_pt_lon: -90.047403,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.2659,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131494,
    shape_pt_lon: -90.047392,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.2769,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13173,
    shape_pt_lon: -90.047336,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.3044,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131871,
    shape_pt_lon: -90.047295,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.3199,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131911,
    shape_pt_lon: -90.04733,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.3263,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.131979,
    shape_pt_lon: -90.047393,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.3349,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132302,
    shape_pt_lon: -90.047692,
    shape_pt_sequence: 323,
    shape_dist_traveled: 20.3807,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132492,
    shape_pt_lon: -90.047878,
    shape_pt_sequence: 324,
    shape_dist_traveled: 20.4077,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132607,
    shape_pt_lon: -90.048014,
    shape_pt_sequence: 325,
    shape_dist_traveled: 20.4254,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13259,
    shape_pt_lon: -90.048217,
    shape_pt_sequence: 326,
    shape_dist_traveled: 20.4445,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132604,
    shape_pt_lon: -90.048888,
    shape_pt_sequence: 327,
    shape_dist_traveled: 20.5045,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132615,
    shape_pt_lon: -90.049365,
    shape_pt_sequence: 328,
    shape_dist_traveled: 20.5476,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132618,
    shape_pt_lon: -90.049503,
    shape_pt_sequence: 329,
    shape_dist_traveled: 20.5606,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132636,
    shape_pt_lon: -90.050397,
    shape_pt_sequence: 330,
    shape_dist_traveled: 20.6416,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132662,
    shape_pt_lon: -90.051453,
    shape_pt_sequence: 331,
    shape_dist_traveled: 20.7366,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132667,
    shape_pt_lon: -90.051972,
    shape_pt_sequence: 332,
    shape_dist_traveled: 20.7836,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132699,
    shape_pt_lon: -90.053449,
    shape_pt_sequence: 333,
    shape_dist_traveled: 20.9177,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132721,
    shape_pt_lon: -90.054193,
    shape_pt_sequence: 334,
    shape_dist_traveled: 20.9847,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132739,
    shape_pt_lon: -90.054619,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.0228,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.132872,
    shape_pt_lon: -90.054626,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.0378,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.133043,
    shape_pt_lon: -90.054659,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.057,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.133207,
    shape_pt_lon: -90.054723,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.077,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13334,
    shape_pt_lon: -90.054802,
    shape_pt_sequence: 339,
    shape_dist_traveled: 21.0935,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.133608,
    shape_pt_lon: -90.05498,
    shape_pt_sequence: 340,
    shape_dist_traveled: 21.1275,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.133698,
    shape_pt_lon: -90.055027,
    shape_pt_sequence: 341,
    shape_dist_traveled: 21.1383,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13387,
    shape_pt_lon: -90.055074,
    shape_pt_sequence: 342,
    shape_dist_traveled: 21.1577,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.134011,
    shape_pt_lon: -90.055085,
    shape_pt_sequence: 343,
    shape_dist_traveled: 21.1737,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.134228,
    shape_pt_lon: -90.055078,
    shape_pt_sequence: 344,
    shape_dist_traveled: 21.1977,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.134433,
    shape_pt_lon: -90.05506,
    shape_pt_sequence: 345,
    shape_dist_traveled: 21.2208,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.134673,
    shape_pt_lon: -90.054994,
    shape_pt_sequence: 346,
    shape_dist_traveled: 21.2485,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.134826,
    shape_pt_lon: -90.054925,
    shape_pt_sequence: 347,
    shape_dist_traveled: 21.2665,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.135772,
    shape_pt_lon: -90.054418,
    shape_pt_sequence: 348,
    shape_dist_traveled: 21.3821,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 349,
    shape_dist_traveled: 21.3968,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 350,
    shape_dist_traveled: 21.5073,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 351,
    shape_dist_traveled: 21.5658,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 352,
    shape_dist_traveled: 21.613,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 353,
    shape_dist_traveled: 21.7294,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 354,
    shape_dist_traveled: 21.7612,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 355,
    shape_dist_traveled: 21.8132,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 356,
    shape_dist_traveled: 21.8235,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 357,
    shape_dist_traveled: 21.8434,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 358,
    shape_dist_traveled: 21.864,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 359,
    shape_dist_traveled: 21.8793,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 360,
    shape_dist_traveled: 21.956,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 361,
    shape_dist_traveled: 22.0164,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.0639,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 363,
    shape_dist_traveled: 22.0865,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 364,
    shape_dist_traveled: 22.1292,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 365,
    shape_dist_traveled: 22.2057,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 366,
    shape_dist_traveled: 22.2582,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 367,
    shape_dist_traveled: 22.3205,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 368,
    shape_dist_traveled: 22.3711,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 369,
    shape_dist_traveled: 22.4404,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 370,
    shape_dist_traveled: 22.5002,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 371,
    shape_dist_traveled: 22.558,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 372,
    shape_dist_traveled: 22.6147,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 373,
    shape_dist_traveled: 22.6747,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 374,
    shape_dist_traveled: 22.7914,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 375,
    shape_dist_traveled: 22.9091,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 376,
    shape_dist_traveled: 22.9647,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 377,
    shape_dist_traveled: 23.0248,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 378,
    shape_dist_traveled: 23.1425,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 379,
    shape_dist_traveled: 23.2588,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 380,
    shape_dist_traveled: 23.3721,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 381,
    shape_dist_traveled: 23.3819,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 382,
    shape_dist_traveled: 23.4045,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 383,
    shape_dist_traveled: 23.4162,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 384,
    shape_dist_traveled: 23.4633,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 385,
    shape_dist_traveled: 23.4902,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 386,
    shape_dist_traveled: 23.5058,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 387,
    shape_dist_traveled: 23.6101,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 388,
    shape_dist_traveled: 23.7217,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 389,
    shape_dist_traveled: 23.729,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 390,
    shape_dist_traveled: 23.7344,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.7344,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.791,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 393,
    shape_dist_traveled: 23.791,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 394,
    shape_dist_traveled: 23.8435,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.903,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.9618,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.9789,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 398,
    shape_dist_traveled: 24.0415,
  },
  {
    shape_id: 38252,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 399,
    shape_dist_traveled: 24.0924,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076249,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0058,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076133,
    shape_pt_lon: -89.932261,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0188,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076008,
    shape_pt_lon: -89.932272,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0329,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075945,
    shape_pt_lon: -89.932284,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0399,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075919,
    shape_pt_lon: -89.932383,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.0494,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075937,
    shape_pt_lon: -89.932947,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.1005,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075946,
    shape_pt_lon: -89.93298,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.1036,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.11,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076054,
    shape_pt_lon: -89.933002,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.116,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.144,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.157,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.1655,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.1713,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.1803,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.2554,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.3135,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.3287,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.3357,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076403,
    shape_pt_lon: -89.931457,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.3417,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07639,
    shape_pt_lon: -89.930939,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.3888,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076296,
    shape_pt_lon: -89.928906,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.573,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076211,
    shape_pt_lon: -89.927188,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.7283,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076204,
    shape_pt_lon: -89.926709,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.7723,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076155,
    shape_pt_lon: -89.926593,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.7835,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07613,
    shape_pt_lon: -89.925902,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.8466,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076108,
    shape_pt_lon: -89.925237,
    shape_pt_sequence: 27,
    shape_dist_traveled: 0.9066,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076096,
    shape_pt_lon: -89.924887,
    shape_pt_sequence: 28,
    shape_dist_traveled: 0.9377,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076107,
    shape_pt_lon: -89.924483,
    shape_pt_sequence: 29,
    shape_dist_traveled: 0.9747,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076143,
    shape_pt_lon: -89.923893,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.0279,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076151,
    shape_pt_lon: -89.923483,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.0649,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076144,
    shape_pt_lon: -89.923266,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.085,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076129,
    shape_pt_lon: -89.923082,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.1021,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076099,
    shape_pt_lon: -89.922871,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.1213,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076053,
    shape_pt_lon: -89.922669,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.14,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.076003,
    shape_pt_lon: -89.922444,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.1609,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07586,
    shape_pt_lon: -89.921972,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.2067,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075699,
    shape_pt_lon: -89.921578,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.247,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075543,
    shape_pt_lon: -89.921267,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.2798,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.075151,
    shape_pt_lon: -89.920621,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.3526,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07499,
    shape_pt_lon: -89.920392,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.3802,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.074653,
    shape_pt_lon: -89.919998,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.4326,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.073911,
    shape_pt_lon: -89.919244,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.5391,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.073241,
    shape_pt_lon: -89.920204,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.6546,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.073146,
    shape_pt_lon: -89.920317,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.6688,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.072968,
    shape_pt_lon: -89.920519,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.6957,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.072679,
    shape_pt_lon: -89.920758,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.7353,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07243,
    shape_pt_lon: -89.920909,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.7653,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.072323,
    shape_pt_lon: -89.920958,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.7783,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.072148,
    shape_pt_lon: -89.921031,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.7992,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.07188,
    shape_pt_lon: -89.921098,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.8298,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.071572,
    shape_pt_lon: -89.921126,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.8639,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.071455,
    shape_pt_lon: -89.921135,
    shape_pt_sequence: 53,
    shape_dist_traveled: 1.8779,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.070784,
    shape_pt_lon: -89.921166,
    shape_pt_sequence: 54,
    shape_dist_traveled: 1.953,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.069574,
    shape_pt_lon: -89.921222,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.088,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.068709,
    shape_pt_lon: -89.921261,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.1851,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.067742,
    shape_pt_lon: -89.921305,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.2932,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.067493,
    shape_pt_lon: -89.921316,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.3212,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066656,
    shape_pt_lon: -89.921353,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.4152,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066089,
    shape_pt_lon: -89.921381,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.4783,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.065611,
    shape_pt_lon: -89.921399,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.5313,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.064689,
    shape_pt_lon: -89.921436,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.6354,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.063943,
    shape_pt_lon: -89.921462,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.7184,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06316,
    shape_pt_lon: -89.921485,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.8064,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062988,
    shape_pt_lon: -89.921513,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.8255,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062857,
    shape_pt_lon: -89.921559,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.8411,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062787,
    shape_pt_lon: -89.921593,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.8487,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.921797,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.8868,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062227,
    shape_pt_lon: -89.921996,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.9217,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062191,
    shape_pt_lon: -89.922012,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.9259,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.062041,
    shape_pt_lon: -89.922078,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.9439,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -89.922108,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.9602,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06138,
    shape_pt_lon: -89.922139,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.0183,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.059375,
    shape_pt_lon: -89.922231,
    shape_pt_sequence: 74,
    shape_dist_traveled: 3.2424,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.057751,
    shape_pt_lon: -89.922281,
    shape_pt_sequence: 75,
    shape_dist_traveled: 3.4244,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.057548,
    shape_pt_lon: -89.922287,
    shape_pt_sequence: 76,
    shape_dist_traveled: 3.4474,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.057182,
    shape_pt_lon: -89.922297,
    shape_pt_sequence: 77,
    shape_dist_traveled: 3.4885,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056719,
    shape_pt_lon: -89.922308,
    shape_pt_sequence: 78,
    shape_dist_traveled: 3.5405,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056665,
    shape_pt_lon: -89.922303,
    shape_pt_sequence: 79,
    shape_dist_traveled: 3.5465,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05643,
    shape_pt_lon: -89.922269,
    shape_pt_sequence: 80,
    shape_dist_traveled: 3.5728,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056186,
    shape_pt_lon: -89.922201,
    shape_pt_sequence: 81,
    shape_dist_traveled: 3.6004,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055926,
    shape_pt_lon: -89.922089,
    shape_pt_sequence: 82,
    shape_dist_traveled: 3.6311,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05555,
    shape_pt_lon: -89.921927,
    shape_pt_sequence: 83,
    shape_dist_traveled: 3.6757,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055455,
    shape_pt_lon: -89.921897,
    shape_pt_sequence: 84,
    shape_dist_traveled: 3.6869,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055198,
    shape_pt_lon: -89.921844,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.7163,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054871,
    shape_pt_lon: -89.921831,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.7523,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054492,
    shape_pt_lon: -89.921844,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.7953,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054293,
    shape_pt_lon: -89.921853,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.8173,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05338,
    shape_pt_lon: -89.92189,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.9194,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052945,
    shape_pt_lon: -89.921907,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.9684,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052659,
    shape_pt_lon: -89.921919,
    shape_pt_sequence: 91,
    shape_dist_traveled: 4.0004,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052268,
    shape_pt_lon: -89.92193,
    shape_pt_sequence: 92,
    shape_dist_traveled: 4.0434,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051622,
    shape_pt_lon: -89.921956,
    shape_pt_sequence: 93,
    shape_dist_traveled: 4.1165,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051529,
    shape_pt_lon: -89.921959,
    shape_pt_sequence: 94,
    shape_dist_traveled: 4.1265,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05153,
    shape_pt_lon: -89.922086,
    shape_pt_sequence: 95,
    shape_dist_traveled: 4.1375,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051577,
    shape_pt_lon: -89.922189,
    shape_pt_sequence: 96,
    shape_dist_traveled: 4.1478,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05163,
    shape_pt_lon: -89.923754,
    shape_pt_sequence: 97,
    shape_dist_traveled: 4.2899,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05165,
    shape_pt_lon: -89.92461,
    shape_pt_sequence: 98,
    shape_dist_traveled: 4.367,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051667,
    shape_pt_lon: -89.925367,
    shape_pt_sequence: 99,
    shape_dist_traveled: 4.436,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051677,
    shape_pt_lon: -89.926049,
    shape_pt_sequence: 100,
    shape_dist_traveled: 4.498,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051678,
    shape_pt_lon: -89.926141,
    shape_pt_sequence: 101,
    shape_dist_traveled: 4.506,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051679,
    shape_pt_lon: -89.926254,
    shape_pt_sequence: 102,
    shape_dist_traveled: 4.516,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051687,
    shape_pt_lon: -89.926926,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.577,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05171,
    shape_pt_lon: -89.927821,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.658,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051769,
    shape_pt_lon: -89.92853,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.7224,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051803,
    shape_pt_lon: -89.92925,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.7875,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051811,
    shape_pt_lon: -89.929514,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.8115,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051806,
    shape_pt_lon: -89.93039,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.8905,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051818,
    shape_pt_lon: -89.931308,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.9735,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051859,
    shape_pt_lon: -89.933182,
    shape_pt_sequence: 110,
    shape_dist_traveled: 5.1436,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051859,
    shape_pt_lon: -89.933387,
    shape_pt_sequence: 111,
    shape_dist_traveled: 5.1616,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051862,
    shape_pt_lon: -89.933493,
    shape_pt_sequence: 112,
    shape_dist_traveled: 5.1716,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051863,
    shape_pt_lon: -89.933593,
    shape_pt_sequence: 113,
    shape_dist_traveled: 5.1806,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051869,
    shape_pt_lon: -89.933919,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.2096,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051872,
    shape_pt_lon: -89.934011,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.2187,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.934276,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.2427,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051899,
    shape_pt_lon: -89.935629,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.3647,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051917,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.4857,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051877,
    shape_pt_lon: -89.937071,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.4965,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.937152,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.5035,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051961,
    shape_pt_lon: -89.940352,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.7926,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052043,
    shape_pt_lon: -89.943377,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.0668,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.0521,
    shape_pt_lon: -89.944961,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.2099,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052153,
    shape_pt_lon: -89.946399,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.34,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.948251,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.508,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052203,
    shape_pt_lon: -89.94913,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.5871,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.949773,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.6461,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 128,
    shape_dist_traveled: 6.8171,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.0152,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.1012,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.1582,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.3694,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.5265,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.5345,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052518,
    shape_pt_lon: -89.959711,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.5475,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052621,
    shape_pt_lon: -89.962222,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.7748,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05264,
    shape_pt_lon: -89.963049,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.8498,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052662,
    shape_pt_lon: -89.963789,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.9169,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052668,
    shape_pt_lon: -89.96398,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.9339,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05268,
    shape_pt_lon: -89.964479,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.9789,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052708,
    shape_pt_lon: -89.965441,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.066,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052739,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.176,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052794,
    shape_pt_lon: -89.968479,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.3411,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052857,
    shape_pt_lon: -89.970677,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.5403,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052892,
    shape_pt_lon: -89.972186,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.6763,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052877,
    shape_pt_lon: -89.972562,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.7103,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052859,
    shape_pt_lon: -89.9729,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.7414,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052723,
    shape_pt_lon: -89.974436,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.8813,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052707,
    shape_pt_lon: -89.97468,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.9033,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052683,
    shape_pt_lon: -89.975008,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.9325,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052665,
    shape_pt_lon: -89.975273,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.9565,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052606,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.012,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052601,
    shape_pt_lon: -89.975881,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.012,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052553,
    shape_pt_lon: -89.976424,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.0614,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.0614,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052521,
    shape_pt_lon: -89.976757,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.0925,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052478,
    shape_pt_lon: -89.977408,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.1507,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052439,
    shape_pt_lon: -89.977803,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.1871,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05242,
    shape_pt_lon: -89.978039,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.2091,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052352,
    shape_pt_lon: -89.978785,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.2766,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052346,
    shape_pt_lon: -89.978868,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.2846,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052296,
    shape_pt_lon: -89.979454,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.338,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052188,
    shape_pt_lon: -89.980672,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.4486,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05218,
    shape_pt_lon: -89.98076,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.4567,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052121,
    shape_pt_lon: -89.981575,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.53,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052097,
    shape_pt_lon: -89.981937,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.5631,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052093,
    shape_pt_lon: -89.981988,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.5682,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052088,
    shape_pt_lon: -89.982079,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.5762,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.982481,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.6122,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052098,
    shape_pt_lon: -89.982754,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.6373,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052151,
    shape_pt_lon: -89.98318,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.6767,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052229,
    shape_pt_lon: -89.983566,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.7126,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052309,
    shape_pt_lon: -89.983848,
    shape_pt_sequence: 173,
    shape_dist_traveled: 9.7392,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05263,
    shape_pt_lon: -89.9848,
    shape_pt_sequence: 174,
    shape_dist_traveled: 9.8324,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052666,
    shape_pt_lon: -89.984901,
    shape_pt_sequence: 175,
    shape_dist_traveled: 9.8423,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052852,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 176,
    shape_dist_traveled: 9.8919,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053109,
    shape_pt_lon: -89.985958,
    shape_pt_sequence: 177,
    shape_dist_traveled: 9.9506,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053158,
    shape_pt_lon: -89.986057,
    shape_pt_sequence: 178,
    shape_dist_traveled: 9.9609,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053313,
    shape_pt_lon: -89.98643,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.9989,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053406,
    shape_pt_lon: -89.986702,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.0253,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053468,
    shape_pt_lon: -89.986996,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.0532,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05349,
    shape_pt_lon: -89.987205,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.0723,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053503,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.0924,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053497,
    shape_pt_lon: -89.98765,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.1124,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05345,
    shape_pt_lon: -89.987999,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.1448,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053319,
    shape_pt_lon: -89.988731,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.2125,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.989051,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.2417,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053299,
    shape_pt_lon: -89.989227,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.2577,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053303,
    shape_pt_lon: -89.989473,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.2797,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053284,
    shape_pt_lon: -89.989631,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.2938,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.3015,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.3056,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.3156,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.3196,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.3266,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.3886,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.4286,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.4456,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053306,
    shape_pt_lon: -89.991485,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.4626,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053309,
    shape_pt_lon: -89.991777,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.4886,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053371,
    shape_pt_lon: -89.99416,
    shape_pt_sequence: 201,
    shape_dist_traveled: 10.7048,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053392,
    shape_pt_lon: -89.994918,
    shape_pt_sequence: 202,
    shape_dist_traveled: 10.7728,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053396,
    shape_pt_lon: -89.995022,
    shape_pt_sequence: 203,
    shape_dist_traveled: 10.7828,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053418,
    shape_pt_lon: -89.995841,
    shape_pt_sequence: 204,
    shape_dist_traveled: 10.8569,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053445,
    shape_pt_lon: -89.996838,
    shape_pt_sequence: 205,
    shape_dist_traveled: 10.9469,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053479,
    shape_pt_lon: -89.997909,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.044,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053489,
    shape_pt_lon: -89.998186,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.069,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053506,
    shape_pt_lon: -89.998869,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.131,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053509,
    shape_pt_lon: -89.998946,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.138,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05351,
    shape_pt_lon: -89.999017,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.144,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053577,
    shape_pt_lon: -90.001128,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.3352,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053603,
    shape_pt_lon: -90.001916,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.4073,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053622,
    shape_pt_lon: -90.002532,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.4623,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053631,
    shape_pt_lon: -90.002807,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.4873,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05364,
    shape_pt_lon: -90.003193,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.5223,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053645,
    shape_pt_lon: -90.003392,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.5403,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053648,
    shape_pt_lon: -90.003511,
    shape_pt_sequence: 217,
    shape_dist_traveled: 11.5514,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053656,
    shape_pt_lon: -90.003766,
    shape_pt_sequence: 218,
    shape_dist_traveled: 11.5744,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053672,
    shape_pt_lon: -90.004259,
    shape_pt_sequence: 219,
    shape_dist_traveled: 11.6184,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053683,
    shape_pt_lon: -90.004726,
    shape_pt_sequence: 220,
    shape_dist_traveled: 11.6614,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053688,
    shape_pt_lon: -90.004857,
    shape_pt_sequence: 221,
    shape_dist_traveled: 11.6735,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053709,
    shape_pt_lon: -90.005523,
    shape_pt_sequence: 222,
    shape_dist_traveled: 11.7335,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053714,
    shape_pt_lon: -90.00578,
    shape_pt_sequence: 223,
    shape_dist_traveled: 11.7565,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053716,
    shape_pt_lon: -90.005816,
    shape_pt_sequence: 224,
    shape_dist_traveled: 11.7595,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053719,
    shape_pt_lon: -90.005967,
    shape_pt_sequence: 225,
    shape_dist_traveled: 11.7735,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053731,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 226,
    shape_dist_traveled: 11.8076,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053738,
    shape_pt_lon: -90.006541,
    shape_pt_sequence: 227,
    shape_dist_traveled: 11.8256,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05375,
    shape_pt_lon: -90.007011,
    shape_pt_sequence: 228,
    shape_dist_traveled: 11.8676,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053765,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 229,
    shape_dist_traveled: 11.9077,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053728,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 230,
    shape_dist_traveled: 11.9117,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053689,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 231,
    shape_dist_traveled: 11.9157,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053425,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 232,
    shape_dist_traveled: 11.9457,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053152,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 233,
    shape_dist_traveled: 11.9757,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052913,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 234,
    shape_dist_traveled: 12.0027,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052834,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 235,
    shape_dist_traveled: 12.0117,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05241,
    shape_pt_lon: -90.007453,
    shape_pt_sequence: 236,
    shape_dist_traveled: 12.0587,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052306,
    shape_pt_lon: -90.007453,
    shape_pt_sequence: 237,
    shape_dist_traveled: 12.0707,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052224,
    shape_pt_lon: -90.007454,
    shape_pt_sequence: 238,
    shape_dist_traveled: 12.0797,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051152,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 239,
    shape_dist_traveled: 12.1997,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051123,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 240,
    shape_dist_traveled: 12.2027,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051094,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 241,
    shape_dist_traveled: 12.2057,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.050069,
    shape_pt_lon: -90.007472,
    shape_pt_sequence: 242,
    shape_dist_traveled: 12.3207,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049676,
    shape_pt_lon: -90.007473,
    shape_pt_sequence: 243,
    shape_dist_traveled: 12.3647,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049353,
    shape_pt_lon: -90.007474,
    shape_pt_sequence: 244,
    shape_dist_traveled: 12.4007,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04841,
    shape_pt_lon: -90.007481,
    shape_pt_sequence: 245,
    shape_dist_traveled: 12.5067,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047709,
    shape_pt_lon: -90.007487,
    shape_pt_sequence: 246,
    shape_dist_traveled: 12.5847,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047629,
    shape_pt_lon: -90.00749,
    shape_pt_sequence: 247,
    shape_dist_traveled: 12.5937,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047422,
    shape_pt_lon: -90.00749,
    shape_pt_sequence: 248,
    shape_dist_traveled: 12.6167,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04695,
    shape_pt_lon: -90.007508,
    shape_pt_sequence: 249,
    shape_dist_traveled: 12.6697,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046269,
    shape_pt_lon: -90.00755,
    shape_pt_sequence: 250,
    shape_dist_traveled: 12.7458,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045801,
    shape_pt_lon: -90.007548,
    shape_pt_sequence: 251,
    shape_dist_traveled: 12.7988,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045576,
    shape_pt_lon: -90.007546,
    shape_pt_sequence: 252,
    shape_dist_traveled: 12.8238,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045279,
    shape_pt_lon: -90.007542,
    shape_pt_sequence: 253,
    shape_dist_traveled: 12.8568,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04507,
    shape_pt_lon: -90.007539,
    shape_pt_sequence: 254,
    shape_dist_traveled: 12.8798,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.044967,
    shape_pt_lon: -90.007535,
    shape_pt_sequence: 255,
    shape_dist_traveled: 12.8919,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.044343,
    shape_pt_lon: -90.007509,
    shape_pt_sequence: 256,
    shape_dist_traveled: 12.9619,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043316,
    shape_pt_lon: -90.007499,
    shape_pt_sequence: 257,
    shape_dist_traveled: 13.0769,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043219,
    shape_pt_lon: -90.007499,
    shape_pt_sequence: 258,
    shape_dist_traveled: 13.0879,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041171,
    shape_pt_lon: -90.007508,
    shape_pt_sequence: 259,
    shape_dist_traveled: 13.3169,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.040359,
    shape_pt_lon: -90.007507,
    shape_pt_sequence: 260,
    shape_dist_traveled: 13.4079,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039731,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 261,
    shape_dist_traveled: 13.4779,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039553,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 262,
    shape_dist_traveled: 13.4979,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.038955,
    shape_pt_lon: -90.007515,
    shape_pt_sequence: 263,
    shape_dist_traveled: 13.5649,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.038476,
    shape_pt_lon: -90.007512,
    shape_pt_sequence: 264,
    shape_dist_traveled: 13.6179,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.038098,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 265,
    shape_dist_traveled: 13.6599,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03662,
    shape_pt_lon: -90.007516,
    shape_pt_sequence: 266,
    shape_dist_traveled: 13.8259,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035578,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 267,
    shape_dist_traveled: 13.9419,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03458,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 268,
    shape_dist_traveled: 14.0539,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034466,
    shape_pt_lon: -90.007516,
    shape_pt_sequence: 269,
    shape_dist_traveled: 14.0669,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.033794,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 270,
    shape_dist_traveled: 14.1419,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.032332,
    shape_pt_lon: -90.00752,
    shape_pt_sequence: 271,
    shape_dist_traveled: 14.3059,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.031516,
    shape_pt_lon: -90.007518,
    shape_pt_sequence: 272,
    shape_dist_traveled: 14.3969,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.029801,
    shape_pt_lon: -90.007518,
    shape_pt_sequence: 273,
    shape_dist_traveled: 14.5889,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02883,
    shape_pt_lon: -90.00752,
    shape_pt_sequence: 274,
    shape_dist_traveled: 14.6969,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028148,
    shape_pt_lon: -90.007523,
    shape_pt_sequence: 275,
    shape_dist_traveled: 14.7739,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027741,
    shape_pt_lon: -90.007528,
    shape_pt_sequence: 276,
    shape_dist_traveled: 14.8189,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027133,
    shape_pt_lon: -90.007529,
    shape_pt_sequence: 277,
    shape_dist_traveled: 14.8869,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026963,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 278,
    shape_dist_traveled: 14.9059,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026092,
    shape_pt_lon: -90.007532,
    shape_pt_sequence: 279,
    shape_dist_traveled: 15.0039,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.025296,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 280,
    shape_dist_traveled: 15.0929,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.024731,
    shape_pt_lon: -90.007529,
    shape_pt_sequence: 281,
    shape_dist_traveled: 15.1559,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.022977,
    shape_pt_lon: -90.007531,
    shape_pt_sequence: 282,
    shape_dist_traveled: 15.3519,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.022322,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 283,
    shape_dist_traveled: 15.4259,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021486,
    shape_pt_lon: -90.007524,
    shape_pt_sequence: 284,
    shape_dist_traveled: 15.5189,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.007527,
    shape_pt_sequence: 285,
    shape_dist_traveled: 15.5629,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.007592,
    shape_pt_sequence: 286,
    shape_dist_traveled: 15.5689,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021089,
    shape_pt_lon: -90.009228,
    shape_pt_sequence: 287,
    shape_dist_traveled: 15.7169,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021079,
    shape_pt_lon: -90.010369,
    shape_pt_sequence: 288,
    shape_dist_traveled: 15.8199,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021059,
    shape_pt_lon: -90.012471,
    shape_pt_sequence: 289,
    shape_dist_traveled: 16.01,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.013957,
    shape_pt_sequence: 290,
    shape_dist_traveled: 16.145,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.014007,
    shape_pt_sequence: 291,
    shape_dist_traveled: 16.149,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02105,
    shape_pt_lon: -90.014056,
    shape_pt_sequence: 292,
    shape_dist_traveled: 16.154,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021028,
    shape_pt_lon: -90.01798,
    shape_pt_sequence: 293,
    shape_dist_traveled: 16.509,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021021,
    shape_pt_lon: -90.019878,
    shape_pt_sequence: 294,
    shape_dist_traveled: 16.68,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021025,
    shape_pt_lon: -90.020481,
    shape_pt_sequence: 295,
    shape_dist_traveled: 16.735,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021019,
    shape_pt_lon: -90.02186,
    shape_pt_sequence: 296,
    shape_dist_traveled: 16.86,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021019,
    shape_pt_lon: -90.021953,
    shape_pt_sequence: 297,
    shape_dist_traveled: 16.868,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021022,
    shape_pt_lon: -90.023306,
    shape_pt_sequence: 298,
    shape_dist_traveled: 16.991,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021022,
    shape_pt_lon: -90.023426,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.001,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02102,
    shape_pt_lon: -90.02486,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.131,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02102,
    shape_pt_lon: -90.025006,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.144,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021021,
    shape_pt_lon: -90.025109,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.154,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021021,
    shape_pt_lon: -90.025207,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.163,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021026,
    shape_pt_lon: -90.02584,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.22,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020954,
    shape_pt_lon: -90.025991,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.2361,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020948,
    shape_pt_lon: -90.026724,
    shape_pt_sequence: 306,
    shape_dist_traveled: 17.3021,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020943,
    shape_pt_lon: -90.027153,
    shape_pt_sequence: 307,
    shape_dist_traveled: 17.3411,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020938,
    shape_pt_lon: -90.02742,
    shape_pt_sequence: 308,
    shape_dist_traveled: 17.3651,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020918,
    shape_pt_lon: -90.029067,
    shape_pt_sequence: 309,
    shape_dist_traveled: 17.5142,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.029769,
    shape_pt_sequence: 310,
    shape_dist_traveled: 17.5772,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.029856,
    shape_pt_sequence: 311,
    shape_dist_traveled: 17.5852,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020912,
    shape_pt_lon: -90.032211,
    shape_pt_sequence: 312,
    shape_dist_traveled: 17.7982,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020909,
    shape_pt_lon: -90.032851,
    shape_pt_sequence: 313,
    shape_dist_traveled: 17.8562,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020898,
    shape_pt_lon: -90.035051,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.0552,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020901,
    shape_pt_lon: -90.036342,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.1722,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020908,
    shape_pt_lon: -90.037041,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.2362,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020941,
    shape_pt_lon: -90.037618,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.2883,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020957,
    shape_pt_lon: -90.037824,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.3063,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020982,
    shape_pt_lon: -90.038615,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.3784,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020979,
    shape_pt_lon: -90.038974,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.4104,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02096,
    shape_pt_lon: -90.040659,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.5634,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020939,
    shape_pt_lon: -90.041436,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.6335,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.041826,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.6695,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020897,
    shape_pt_lon: -90.042449,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.7256,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020889,
    shape_pt_lon: -90.04294,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.7696,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020874,
    shape_pt_lon: -90.044716,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.9306,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020863,
    shape_pt_lon: -90.045506,
    shape_pt_sequence: 327,
    shape_dist_traveled: 19.0026,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020845,
    shape_pt_lon: -90.04757,
    shape_pt_sequence: 328,
    shape_dist_traveled: 19.1887,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020822,
    shape_pt_lon: -90.049562,
    shape_pt_sequence: 329,
    shape_dist_traveled: 19.3697,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.020908,
    shape_pt_lon: -90.049561,
    shape_pt_sequence: 330,
    shape_dist_traveled: 19.3797,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.021337,
    shape_pt_lon: -90.049568,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.4277,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02158,
    shape_pt_lon: -90.049537,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.4548,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02172,
    shape_pt_lon: -90.049489,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.4704,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.022146,
    shape_pt_lon: -90.049293,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.5216,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.022242,
    shape_pt_lon: -90.049248,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.5333,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.022988,
    shape_pt_lon: -90.048899,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.6219,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.023219,
    shape_pt_lon: -90.048811,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.6491,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.023465,
    shape_pt_lon: -90.048772,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.6774,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.023613,
    shape_pt_lon: -90.048771,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.6934,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.024536,
    shape_pt_lon: -90.048762,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.7964,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.025085,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.8584,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.025909,
    shape_pt_lon: -90.048749,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.9504,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026263,
    shape_pt_lon: -90.048748,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.9904,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026499,
    shape_pt_lon: -90.048751,
    shape_pt_sequence: 344,
    shape_dist_traveled: 20.0164,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026719,
    shape_pt_lon: -90.048797,
    shape_pt_sequence: 345,
    shape_dist_traveled: 20.0419,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026856,
    shape_pt_lon: -90.048853,
    shape_pt_sequence: 346,
    shape_dist_traveled: 20.0577,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026961,
    shape_pt_lon: -90.048902,
    shape_pt_sequence: 347,
    shape_dist_traveled: 20.0704,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027112,
    shape_pt_lon: -90.049008,
    shape_pt_sequence: 348,
    shape_dist_traveled: 20.0901,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.0273,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.1165,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02748,
    shape_pt_lon: -90.049343,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.1409,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02764,
    shape_pt_lon: -90.04944,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.1611,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027841,
    shape_pt_lon: -90.049525,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.1841,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028051,
    shape_pt_lon: -90.049568,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.2085,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02862,
    shape_pt_lon: -90.049574,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.2715,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03084,
    shape_pt_lon: -90.049573,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.5205,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03145,
    shape_pt_lon: -90.049571,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.5885,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.031591,
    shape_pt_lon: -90.049585,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.6045,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.031909,
    shape_pt_lon: -90.049648,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.64,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.032005,
    shape_pt_lon: -90.04967,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.6512,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.032236,
    shape_pt_lon: -90.049709,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.6774,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.032822,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 361,
    shape_dist_traveled: 20.7434,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03374,
    shape_pt_lon: -90.049735,
    shape_pt_sequence: 362,
    shape_dist_traveled: 20.8454,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034312,
    shape_pt_lon: -90.049753,
    shape_pt_sequence: 363,
    shape_dist_traveled: 20.9094,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034698,
    shape_pt_lon: -90.049753,
    shape_pt_sequence: 364,
    shape_dist_traveled: 20.9524,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034677,
    shape_pt_lon: -90.051866,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.1434,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034673,
    shape_pt_lon: -90.052031,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.1584,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034666,
    shape_pt_lon: -90.056244,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.5404,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034702,
    shape_pt_lon: -90.056244,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.5444,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035147,
    shape_pt_lon: -90.056251,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.5944,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035538,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.6375,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035811,
    shape_pt_lon: -90.056275,
    shape_pt_sequence: 371,
    shape_dist_traveled: 21.6685,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03617,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 372,
    shape_dist_traveled: 21.7085,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.0363,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 373,
    shape_dist_traveled: 21.7235,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.036911,
    shape_pt_lon: -90.056267,
    shape_pt_sequence: 374,
    shape_dist_traveled: 21.7915,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.037339,
    shape_pt_lon: -90.056231,
    shape_pt_sequence: 375,
    shape_dist_traveled: 21.8396,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.038009,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 376,
    shape_dist_traveled: 21.9146,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039102,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 377,
    shape_dist_traveled: 22.0367,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039635,
    shape_pt_lon: -90.056241,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.0967,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.040229,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.1627,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04153,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.3087,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041578,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 381,
    shape_dist_traveled: 22.3137,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041623,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.3187,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.042746,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.4447,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043079,
    shape_pt_lon: -90.056232,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.4817,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.044218,
    shape_pt_lon: -90.056237,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.6097,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04467,
    shape_pt_lon: -90.056243,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.6597,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045089,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.7067,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045329,
    shape_pt_lon: -90.056246,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.7337,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046676,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.8847,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047606,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.9887,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.050398,
    shape_pt_lon: -90.056261,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.3007,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.3677,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052028,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 393,
    shape_dist_traveled: 23.4827,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054788,
    shape_pt_lon: -90.05626,
    shape_pt_sequence: 394,
    shape_dist_traveled: 23.7917,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055047,
    shape_pt_lon: -90.056257,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.8207,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05514,
    shape_pt_lon: -90.056255,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.8317,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055159,
    shape_pt_lon: -90.056836,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.8837,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055166,
    shape_pt_lon: -90.05703,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.9017,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05522,
    shape_pt_lon: -90.058573,
    shape_pt_sequence: 399,
    shape_dist_traveled: 24.0419,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055262,
    shape_pt_lon: -90.060111,
    shape_pt_sequence: 400,
    shape_dist_traveled: 24.181,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055334,
    shape_pt_lon: -90.060097,
    shape_pt_sequence: 401,
    shape_dist_traveled: 24.1892,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056303,
    shape_pt_lon: -90.059931,
    shape_pt_sequence: 402,
    shape_dist_traveled: 24.2992,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056792,
    shape_pt_lon: -90.05986,
    shape_pt_sequence: 403,
    shape_dist_traveled: 24.3536,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05787,
    shape_pt_lon: -90.059736,
    shape_pt_sequence: 404,
    shape_dist_traveled: 24.4751,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.059309,
    shape_pt_lon: -90.05959,
    shape_pt_sequence: 405,
    shape_dist_traveled: 24.6366,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.060576,
    shape_pt_lon: -90.05946,
    shape_pt_sequence: 406,
    shape_dist_traveled: 24.7791,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.061676,
    shape_pt_lon: -90.059343,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.9026,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 408,
    shape_dist_traveled: 25.0631,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 409,
    shape_dist_traveled: 25.1123,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 410,
    shape_dist_traveled: 25.2487,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 411,
    shape_dist_traveled: 25.4354,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066428,
    shape_pt_lon: -90.05891,
    shape_pt_sequence: 412,
    shape_dist_traveled: 25.4405,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066429,
    shape_pt_lon: -90.059237,
    shape_pt_sequence: 413,
    shape_dist_traveled: 25.4705,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066446,
    shape_pt_lon: -90.059707,
    shape_pt_sequence: 414,
    shape_dist_traveled: 25.5126,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066449,
    shape_pt_lon: -90.060102,
    shape_pt_sequence: 415,
    shape_dist_traveled: 25.5486,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06647,
    shape_pt_lon: -90.060843,
    shape_pt_sequence: 416,
    shape_dist_traveled: 25.6156,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066526,
    shape_pt_lon: -90.062818,
    shape_pt_sequence: 417,
    shape_dist_traveled: 25.7947,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066533,
    shape_pt_lon: -90.063212,
    shape_pt_sequence: 418,
    shape_dist_traveled: 25.8297,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066542,
    shape_pt_lon: -90.06369,
    shape_pt_sequence: 419,
    shape_dist_traveled: 25.8727,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066578,
    shape_pt_lon: -90.065172,
    shape_pt_sequence: 420,
    shape_dist_traveled: 26.0068,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066627,
    shape_pt_lon: -90.067008,
    shape_pt_sequence: 421,
    shape_dist_traveled: 26.1739,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066637,
    shape_pt_lon: -90.06742,
    shape_pt_sequence: 422,
    shape_dist_traveled: 26.2109,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066652,
    shape_pt_lon: -90.068069,
    shape_pt_sequence: 423,
    shape_dist_traveled: 26.2689,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066655,
    shape_pt_lon: -90.068144,
    shape_pt_sequence: 424,
    shape_dist_traveled: 26.2759,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066673,
    shape_pt_lon: -90.068768,
    shape_pt_sequence: 425,
    shape_dist_traveled: 26.333,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066712,
    shape_pt_lon: -90.07032,
    shape_pt_sequence: 426,
    shape_dist_traveled: 26.472,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066708,
    shape_pt_lon: -90.070483,
    shape_pt_sequence: 427,
    shape_dist_traveled: 26.487,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066726,
    shape_pt_lon: -90.071375,
    shape_pt_sequence: 428,
    shape_dist_traveled: 26.567,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066742,
    shape_pt_lon: -90.072068,
    shape_pt_sequence: 429,
    shape_dist_traveled: 26.6301,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066775,
    shape_pt_lon: -90.073247,
    shape_pt_sequence: 430,
    shape_dist_traveled: 26.7371,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066815,
    shape_pt_lon: -90.074867,
    shape_pt_sequence: 431,
    shape_dist_traveled: 26.8832,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066895,
    shape_pt_lon: -90.077985,
    shape_pt_sequence: 432,
    shape_dist_traveled: 27.1653,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06696,
    shape_pt_lon: -90.08058,
    shape_pt_sequence: 433,
    shape_dist_traveled: 27.4004,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.066982,
    shape_pt_lon: -90.082203,
    shape_pt_sequence: 434,
    shape_dist_traveled: 27.5475,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.067002,
    shape_pt_lon: -90.083698,
    shape_pt_sequence: 435,
    shape_dist_traveled: 27.6825,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06703,
    shape_pt_lon: -90.085181,
    shape_pt_sequence: 436,
    shape_dist_traveled: 27.8165,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.067037,
    shape_pt_lon: -90.085823,
    shape_pt_sequence: 437,
    shape_dist_traveled: 27.8745,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06705,
    shape_pt_lon: -90.086652,
    shape_pt_sequence: 438,
    shape_dist_traveled: 27.9495,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.065291,
    shape_pt_lon: -90.086709,
    shape_pt_sequence: 439,
    shape_dist_traveled: 28.1466,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.063767,
    shape_pt_lon: -90.086766,
    shape_pt_sequence: 440,
    shape_dist_traveled: 28.3167,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.06274,
    shape_pt_lon: -90.086805,
    shape_pt_sequence: 441,
    shape_dist_traveled: 28.4318,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.061738,
    shape_pt_lon: -90.086839,
    shape_pt_sequence: 442,
    shape_dist_traveled: 28.5438,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.060732,
    shape_pt_lon: -90.086848,
    shape_pt_sequence: 443,
    shape_dist_traveled: 28.6568,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.059727,
    shape_pt_lon: -90.08687,
    shape_pt_sequence: 444,
    shape_dist_traveled: 28.7688,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.059017,
    shape_pt_lon: -90.086889,
    shape_pt_sequence: 445,
    shape_dist_traveled: 28.8488,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.058135,
    shape_pt_lon: -90.086916,
    shape_pt_sequence: 446,
    shape_dist_traveled: 28.9469,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.057189,
    shape_pt_lon: -90.086942,
    shape_pt_sequence: 447,
    shape_dist_traveled: 29.0529,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.057053,
    shape_pt_lon: -90.086945,
    shape_pt_sequence: 448,
    shape_dist_traveled: 29.0679,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05596,
    shape_pt_lon: -90.086976,
    shape_pt_sequence: 449,
    shape_dist_traveled: 29.1909,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055989,
    shape_pt_lon: -90.08817,
    shape_pt_sequence: 450,
    shape_dist_traveled: 29.299,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056029,
    shape_pt_lon: -90.089532,
    shape_pt_sequence: 451,
    shape_dist_traveled: 29.4221,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05612,
    shape_pt_lon: -90.092463,
    shape_pt_sequence: 452,
    shape_dist_traveled: 29.6873,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.056202,
    shape_pt_lon: -90.095312,
    shape_pt_sequence: 453,
    shape_dist_traveled: 29.9455,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055479,
    shape_pt_lon: -90.095942,
    shape_pt_sequence: 454,
    shape_dist_traveled: 30.0445,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.055309,
    shape_pt_lon: -90.096088,
    shape_pt_sequence: 455,
    shape_dist_traveled: 30.0675,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054949,
    shape_pt_lon: -90.09633,
    shape_pt_sequence: 456,
    shape_dist_traveled: 30.1141,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054704,
    shape_pt_lon: -90.096456,
    shape_pt_sequence: 457,
    shape_dist_traveled: 30.1432,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054406,
    shape_pt_lon: -90.096569,
    shape_pt_sequence: 458,
    shape_dist_traveled: 30.1777,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.054102,
    shape_pt_lon: -90.096644,
    shape_pt_sequence: 459,
    shape_dist_traveled: 30.2124,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.05373,
    shape_pt_lon: -90.096689,
    shape_pt_sequence: 460,
    shape_dist_traveled: 30.2546,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.053351,
    shape_pt_lon: -90.096687,
    shape_pt_sequence: 461,
    shape_dist_traveled: 30.2966,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052854,
    shape_pt_lon: -90.096672,
    shape_pt_sequence: 462,
    shape_dist_traveled: 30.3526,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.052112,
    shape_pt_lon: -90.096656,
    shape_pt_sequence: 463,
    shape_dist_traveled: 30.4356,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.051419,
    shape_pt_lon: -90.096652,
    shape_pt_sequence: 464,
    shape_dist_traveled: 30.5136,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.050397,
    shape_pt_lon: -90.096651,
    shape_pt_sequence: 465,
    shape_dist_traveled: 30.6276,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049649,
    shape_pt_lon: -90.09665,
    shape_pt_sequence: 466,
    shape_dist_traveled: 30.7116,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.048342,
    shape_pt_lon: -90.096616,
    shape_pt_sequence: 467,
    shape_dist_traveled: 30.8577,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046936,
    shape_pt_lon: -90.096577,
    shape_pt_sequence: 468,
    shape_dist_traveled: 31.0147,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04693,
    shape_pt_lon: -90.097885,
    shape_pt_sequence: 469,
    shape_dist_traveled: 31.1337,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046924,
    shape_pt_lon: -90.099187,
    shape_pt_sequence: 470,
    shape_dist_traveled: 31.2517,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046923,
    shape_pt_lon: -90.099696,
    shape_pt_sequence: 471,
    shape_dist_traveled: 31.2977,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046908,
    shape_pt_lon: -90.099917,
    shape_pt_sequence: 472,
    shape_dist_traveled: 31.3178,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046856,
    shape_pt_lon: -90.100168,
    shape_pt_sequence: 473,
    shape_dist_traveled: 31.3406,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04677,
    shape_pt_lon: -90.100412,
    shape_pt_sequence: 474,
    shape_dist_traveled: 31.3647,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046513,
    shape_pt_lon: -90.100901,
    shape_pt_sequence: 475,
    shape_dist_traveled: 31.4177,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046182,
    shape_pt_lon: -90.101508,
    shape_pt_sequence: 476,
    shape_dist_traveled: 31.4832,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046126,
    shape_pt_lon: -90.101651,
    shape_pt_sequence: 477,
    shape_dist_traveled: 31.4979,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046075,
    shape_pt_lon: -90.101836,
    shape_pt_sequence: 478,
    shape_dist_traveled: 31.5157,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04605,
    shape_pt_lon: -90.101968,
    shape_pt_sequence: 479,
    shape_dist_traveled: 31.528,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046041,
    shape_pt_lon: -90.10223,
    shape_pt_sequence: 480,
    shape_dist_traveled: 31.5521,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046497,
    shape_pt_lon: -90.102238,
    shape_pt_sequence: 481,
    shape_dist_traveled: 31.6031,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046616,
    shape_pt_lon: -90.102267,
    shape_pt_sequence: 482,
    shape_dist_traveled: 31.6162,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046786,
    shape_pt_lon: -90.102349,
    shape_pt_sequence: 483,
    shape_dist_traveled: 31.6368,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046929,
    shape_pt_lon: -90.102451,
    shape_pt_sequence: 484,
    shape_dist_traveled: 31.6552,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047067,
    shape_pt_lon: -90.102601,
    shape_pt_sequence: 485,
    shape_dist_traveled: 31.6758,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047163,
    shape_pt_lon: -90.102769,
    shape_pt_sequence: 486,
    shape_dist_traveled: 31.6947,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047233,
    shape_pt_lon: -90.102937,
    shape_pt_sequence: 487,
    shape_dist_traveled: 31.7117,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047597,
    shape_pt_lon: -90.10384,
    shape_pt_sequence: 488,
    shape_dist_traveled: 31.8025,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049003,
    shape_pt_lon: -90.103012,
    shape_pt_sequence: 489,
    shape_dist_traveled: 31.9765,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049124,
    shape_pt_lon: -90.102952,
    shape_pt_sequence: 490,
    shape_dist_traveled: 31.9913,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049341,
    shape_pt_lon: -90.102917,
    shape_pt_sequence: 491,
    shape_dist_traveled: 32.0155,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049629,
    shape_pt_lon: -90.102909,
    shape_pt_sequence: 492,
    shape_dist_traveled: 32.0475,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -90.103551,
    shape_pt_sequence: 493,
    shape_dist_traveled: 32.1055,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049626,
    shape_pt_lon: -90.103895,
    shape_pt_sequence: 494,
    shape_dist_traveled: 32.1365,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049613,
    shape_pt_lon: -90.105509,
    shape_pt_sequence: 495,
    shape_dist_traveled: 32.2825,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049611,
    shape_pt_lon: -90.106318,
    shape_pt_sequence: 496,
    shape_dist_traveled: 32.3555,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049603,
    shape_pt_lon: -90.107773,
    shape_pt_sequence: 497,
    shape_dist_traveled: 32.4875,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04959,
    shape_pt_lon: -90.110074,
    shape_pt_sequence: 498,
    shape_dist_traveled: 32.6955,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04957,
    shape_pt_lon: -90.1145,
    shape_pt_sequence: 499,
    shape_dist_traveled: 33.0965,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04956,
    shape_pt_lon: -90.115882,
    shape_pt_sequence: 500,
    shape_dist_traveled: 33.2216,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049556,
    shape_pt_lon: -90.117066,
    shape_pt_sequence: 501,
    shape_dist_traveled: 33.3286,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049554,
    shape_pt_lon: -90.117238,
    shape_pt_sequence: 502,
    shape_dist_traveled: 33.3436,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049548,
    shape_pt_lon: -90.118757,
    shape_pt_sequence: 503,
    shape_dist_traveled: 33.4816,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049533,
    shape_pt_lon: -90.121899,
    shape_pt_sequence: 504,
    shape_dist_traveled: 33.7656,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049577,
    shape_pt_lon: -90.122509,
    shape_pt_sequence: 505,
    shape_dist_traveled: 33.8208,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049579,
    shape_pt_lon: -90.122935,
    shape_pt_sequence: 506,
    shape_dist_traveled: 33.8598,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04957,
    shape_pt_lon: -90.124206,
    shape_pt_sequence: 507,
    shape_dist_traveled: 33.9748,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049563,
    shape_pt_lon: -90.125435,
    shape_pt_sequence: 508,
    shape_dist_traveled: 34.0858,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049539,
    shape_pt_lon: -90.125542,
    shape_pt_sequence: 509,
    shape_dist_traveled: 34.0963,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.049364,
    shape_pt_lon: -90.125467,
    shape_pt_sequence: 510,
    shape_dist_traveled: 34.1165,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04905,
    shape_pt_lon: -90.125261,
    shape_pt_sequence: 511,
    shape_dist_traveled: 34.1563,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.048762,
    shape_pt_lon: -90.12507,
    shape_pt_sequence: 512,
    shape_dist_traveled: 34.1934,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.048622,
    shape_pt_lon: -90.124991,
    shape_pt_sequence: 513,
    shape_dist_traveled: 34.21,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.048326,
    shape_pt_lon: -90.124853,
    shape_pt_sequence: 514,
    shape_dist_traveled: 34.2464,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.048109,
    shape_pt_lon: -90.124779,
    shape_pt_sequence: 515,
    shape_dist_traveled: 34.2711,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047799,
    shape_pt_lon: -90.124697,
    shape_pt_sequence: 516,
    shape_dist_traveled: 34.3061,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.047518,
    shape_pt_lon: -90.124657,
    shape_pt_sequence: 517,
    shape_dist_traveled: 34.3382,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.046396,
    shape_pt_lon: -90.124603,
    shape_pt_sequence: 518,
    shape_dist_traveled: 34.4633,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.045186,
    shape_pt_lon: -90.124551,
    shape_pt_sequence: 519,
    shape_dist_traveled: 34.5994,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04455,
    shape_pt_lon: -90.12453,
    shape_pt_sequence: 520,
    shape_dist_traveled: 34.6704,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04434,
    shape_pt_lon: -90.124481,
    shape_pt_sequence: 521,
    shape_dist_traveled: 34.6948,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.044132,
    shape_pt_lon: -90.124391,
    shape_pt_sequence: 522,
    shape_dist_traveled: 34.7191,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043977,
    shape_pt_lon: -90.124291,
    shape_pt_sequence: 523,
    shape_dist_traveled: 34.7383,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043862,
    shape_pt_lon: -90.124198,
    shape_pt_sequence: 524,
    shape_dist_traveled: 34.7542,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043686,
    shape_pt_lon: -90.124003,
    shape_pt_sequence: 525,
    shape_dist_traveled: 34.7804,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043411,
    shape_pt_lon: -90.123621,
    shape_pt_sequence: 526,
    shape_dist_traveled: 34.8265,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.043212,
    shape_pt_lon: -90.123808,
    shape_pt_sequence: 527,
    shape_dist_traveled: 34.8551,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04296,
    shape_pt_lon: -90.123991,
    shape_pt_sequence: 528,
    shape_dist_traveled: 34.8879,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.042751,
    shape_pt_lon: -90.124102,
    shape_pt_sequence: 529,
    shape_dist_traveled: 34.9129,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.042677,
    shape_pt_lon: -90.124137,
    shape_pt_sequence: 530,
    shape_dist_traveled: 34.9224,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.042399,
    shape_pt_lon: -90.124242,
    shape_pt_sequence: 531,
    shape_dist_traveled: 34.9547,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.042305,
    shape_pt_lon: -90.124268,
    shape_pt_sequence: 532,
    shape_dist_traveled: 34.9651,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04201,
    shape_pt_lon: -90.124323,
    shape_pt_sequence: 533,
    shape_dist_traveled: 34.9985,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041895,
    shape_pt_lon: -90.124331,
    shape_pt_sequence: 534,
    shape_dist_traveled: 35.0115,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.04167,
    shape_pt_lon: -90.124334,
    shape_pt_sequence: 535,
    shape_dist_traveled: 35.0365,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041367,
    shape_pt_lon: -90.124318,
    shape_pt_sequence: 536,
    shape_dist_traveled: 35.0706,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.041268,
    shape_pt_lon: -90.12431,
    shape_pt_sequence: 537,
    shape_dist_traveled: 35.0816,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039623,
    shape_pt_lon: -90.124199,
    shape_pt_sequence: 538,
    shape_dist_traveled: 35.2659,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.039218,
    shape_pt_lon: -90.12417,
    shape_pt_sequence: 539,
    shape_dist_traveled: 35.312,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035716,
    shape_pt_lon: -90.123932,
    shape_pt_sequence: 540,
    shape_dist_traveled: 35.7035,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035739,
    shape_pt_lon: -90.121589,
    shape_pt_sequence: 541,
    shape_dist_traveled: 35.9155,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03576,
    shape_pt_lon: -90.120103,
    shape_pt_sequence: 542,
    shape_dist_traveled: 36.0506,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035791,
    shape_pt_lon: -90.117143,
    shape_pt_sequence: 543,
    shape_dist_traveled: 36.3186,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035819,
    shape_pt_lon: -90.115044,
    shape_pt_sequence: 544,
    shape_dist_traveled: 36.5076,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035831,
    shape_pt_lon: -90.112977,
    shape_pt_sequence: 545,
    shape_dist_traveled: 36.6946,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035842,
    shape_pt_lon: -90.111669,
    shape_pt_sequence: 546,
    shape_dist_traveled: 36.8136,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035861,
    shape_pt_lon: -90.110231,
    shape_pt_sequence: 547,
    shape_dist_traveled: 36.9436,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035867,
    shape_pt_lon: -90.109721,
    shape_pt_sequence: 548,
    shape_dist_traveled: 36.9896,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035871,
    shape_pt_lon: -90.108672,
    shape_pt_sequence: 549,
    shape_dist_traveled: 37.0846,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035878,
    shape_pt_lon: -90.107971,
    shape_pt_sequence: 550,
    shape_dist_traveled: 37.1477,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.035909,
    shape_pt_lon: -90.104041,
    shape_pt_sequence: 551,
    shape_dist_traveled: 37.5037,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.03499,
    shape_pt_lon: -90.104156,
    shape_pt_sequence: 552,
    shape_dist_traveled: 37.6073,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034497,
    shape_pt_lon: -90.104181,
    shape_pt_sequence: 553,
    shape_dist_traveled: 37.6623,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034299,
    shape_pt_lon: -90.104166,
    shape_pt_sequence: 554,
    shape_dist_traveled: 37.6844,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.034108,
    shape_pt_lon: -90.10414,
    shape_pt_sequence: 555,
    shape_dist_traveled: 37.7055,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.033622,
    shape_pt_lon: -90.103986,
    shape_pt_sequence: 556,
    shape_dist_traveled: 37.7622,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.033512,
    shape_pt_lon: -90.103958,
    shape_pt_sequence: 557,
    shape_dist_traveled: 37.7744,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.033391,
    shape_pt_lon: -90.103943,
    shape_pt_sequence: 558,
    shape_dist_traveled: 37.7875,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.033249,
    shape_pt_lon: -90.103931,
    shape_pt_sequence: 559,
    shape_dist_traveled: 37.8036,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.032561,
    shape_pt_lon: -90.10394,
    shape_pt_sequence: 560,
    shape_dist_traveled: 37.8806,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.031095,
    shape_pt_lon: -90.103951,
    shape_pt_sequence: 561,
    shape_dist_traveled: 38.0446,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.030177,
    shape_pt_lon: -90.103902,
    shape_pt_sequence: 562,
    shape_dist_traveled: 38.1477,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.030017,
    shape_pt_lon: -90.103891,
    shape_pt_sequence: 563,
    shape_dist_traveled: 38.1657,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.029272,
    shape_pt_lon: -90.103878,
    shape_pt_sequence: 564,
    shape_dist_traveled: 38.2487,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028977,
    shape_pt_lon: -90.103872,
    shape_pt_sequence: 565,
    shape_dist_traveled: 38.2817,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028765,
    shape_pt_lon: -90.103852,
    shape_pt_sequence: 566,
    shape_dist_traveled: 38.3058,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028493,
    shape_pt_lon: -90.103791,
    shape_pt_sequence: 567,
    shape_dist_traveled: 38.3362,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.028383,
    shape_pt_lon: -90.103758,
    shape_pt_sequence: 568,
    shape_dist_traveled: 38.3496,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02796,
    shape_pt_lon: -90.103592,
    shape_pt_sequence: 569,
    shape_dist_traveled: 38.3989,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027758,
    shape_pt_lon: -90.103548,
    shape_pt_sequence: 570,
    shape_dist_traveled: 38.4222,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027564,
    shape_pt_lon: -90.103539,
    shape_pt_sequence: 571,
    shape_dist_traveled: 38.4433,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02743,
    shape_pt_lon: -90.103551,
    shape_pt_sequence: 572,
    shape_dist_traveled: 38.4583,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.027118,
    shape_pt_lon: -90.103627,
    shape_pt_sequence: 573,
    shape_dist_traveled: 38.494,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026946,
    shape_pt_lon: -90.103668,
    shape_pt_sequence: 574,
    shape_dist_traveled: 38.5144,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026647,
    shape_pt_lon: -90.103703,
    shape_pt_sequence: 575,
    shape_dist_traveled: 38.5475,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026488,
    shape_pt_lon: -90.103698,
    shape_pt_sequence: 576,
    shape_dist_traveled: 38.5655,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.026389,
    shape_pt_lon: -90.103676,
    shape_pt_sequence: 577,
    shape_dist_traveled: 38.5767,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.025686,
    shape_pt_lon: -90.103417,
    shape_pt_sequence: 578,
    shape_dist_traveled: 38.659,
  },
  {
    shape_id: 38253,
    shape_pt_lat: 35.02543,
    shape_pt_lon: -90.103354,
    shape_pt_sequence: 579,
    shape_dist_traveled: 38.6876,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025238,
    shape_pt_lon: -90.103338,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025227,
    shape_pt_lon: -90.102233,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025203,
    shape_pt_lon: -90.101981,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1232,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025111,
    shape_pt_lon: -90.101667,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1529,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025022,
    shape_pt_lon: -90.101469,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1735,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02493,
    shape_pt_lon: -90.101323,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1899,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024724,
    shape_pt_lon: -90.10103,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2254,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023869,
    shape_pt_lon: -90.099842,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.3691,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023651,
    shape_pt_lon: -90.099543,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4053,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023556,
    shape_pt_lon: -90.099399,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4231,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02344,
    shape_pt_lon: -90.099153,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4486,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023406,
    shape_pt_lon: -90.099042,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4594,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023356,
    shape_pt_lon: -90.098747,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.4859,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023342,
    shape_pt_lon: -90.098606,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.499,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02334,
    shape_pt_lon: -90.098315,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.526,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023348,
    shape_pt_lon: -90.097756,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.576,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023381,
    shape_pt_lon: -90.096514,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6891,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024409,
    shape_pt_lon: -90.096562,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.8042,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024696,
    shape_pt_lon: -90.096613,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.8374,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024906,
    shape_pt_lon: -90.096687,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8615,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02582,
    shape_pt_lon: -90.097128,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.971,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.028933,
    shape_pt_lon: -90.098626,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.3452,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.029099,
    shape_pt_lon: -90.0987,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3645,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.029377,
    shape_pt_lon: -90.098809,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.3971,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.029587,
    shape_pt_lon: -90.098889,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4221,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.030238,
    shape_pt_lon: -90.099107,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4978,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.030801,
    shape_pt_lon: -90.099284,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.5628,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032402,
    shape_pt_lon: -90.099804,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.7479,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032728,
    shape_pt_lon: -90.099867,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.7844,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032973,
    shape_pt_lon: -90.099873,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.8124,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.033147,
    shape_pt_lon: -90.099848,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.8315,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.033479,
    shape_pt_lon: -90.099746,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.8696,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034012,
    shape_pt_lon: -90.09957,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.9317,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03428,
    shape_pt_lon: -90.099483,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.9627,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03472,
    shape_pt_lon: -90.099336,
    shape_pt_sequence: 35,
    shape_dist_traveled: 2.0137,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035782,
    shape_pt_lon: -90.098951,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.1374,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03592,
    shape_pt_lon: -90.098886,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.1536,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036097,
    shape_pt_lon: -90.098798,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.1751,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036403,
    shape_pt_lon: -90.098552,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.2162,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.037222,
    shape_pt_lon: -90.097736,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.3336,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.037606,
    shape_pt_lon: -90.097359,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.3891,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.037839,
    shape_pt_lon: -90.097178,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.4196,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.037928,
    shape_pt_lon: -90.09712,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4308,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038164,
    shape_pt_lon: -90.096976,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4598,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038378,
    shape_pt_lon: -90.096872,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.4858,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038735,
    shape_pt_lon: -90.096748,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.5273,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038895,
    shape_pt_lon: -90.09671,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.5456,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039278,
    shape_pt_lon: -90.096655,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5889,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040396,
    shape_pt_lon: -90.096638,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.7139,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042382,
    shape_pt_lon: -90.096611,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.9359,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042405,
    shape_pt_lon: -90.09244,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.3139,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042405,
    shape_pt_lon: -90.091559,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.3929,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04216,
    shape_pt_lon: -90.091574,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.4209,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041707,
    shape_pt_lon: -90.09158,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.4709,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041347,
    shape_pt_lon: -90.091637,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.5124,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041079,
    shape_pt_lon: -90.091637,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.5424,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040708,
    shape_pt_lon: -90.091583,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.5837,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040173,
    shape_pt_lon: -90.09156,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.6437,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040176,
    shape_pt_lon: -90.092509,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.7297,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039249,
    shape_pt_lon: -90.092511,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.8327,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038749,
    shape_pt_lon: -90.092502,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.8887,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038289,
    shape_pt_lon: -90.092506,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.9407,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03781,
    shape_pt_lon: -90.092493,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.9937,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036943,
    shape_pt_lon: -90.092509,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.0907,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036711,
    shape_pt_lon: -90.092519,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.1167,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036567,
    shape_pt_lon: -90.092562,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.133,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036399,
    shape_pt_lon: -90.092657,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.154,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036181,
    shape_pt_lon: -90.092838,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.1837,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035791,
    shape_pt_lon: -90.093181,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.2367,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035692,
    shape_pt_lon: -90.093254,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.2498,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035586,
    shape_pt_lon: -90.093288,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.2621,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035199,
    shape_pt_lon: -90.093318,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.3053,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035102,
    shape_pt_lon: -90.093319,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.3163,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03511,
    shape_pt_lon: -90.093506,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.3333,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035149,
    shape_pt_lon: -90.093867,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.3665,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035228,
    shape_pt_lon: -90.094219,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.3988,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035327,
    shape_pt_lon: -90.094496,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.4261,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035447,
    shape_pt_lon: -90.094762,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.4543,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035617,
    shape_pt_lon: -90.095078,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.4881,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035717,
    shape_pt_lon: -90.095312,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.5123,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035766,
    shape_pt_lon: -90.095443,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.5253,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035831,
    shape_pt_lon: -90.095694,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.5494,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035888,
    shape_pt_lon: -90.095979,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.5763,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03591,
    shape_pt_lon: -90.096223,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.5984,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035928,
    shape_pt_lon: -90.097305,
    shape_pt_sequence: 85,
    shape_dist_traveled: 4.6964,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03592,
    shape_pt_lon: -90.098886,
    shape_pt_sequence: 86,
    shape_dist_traveled: 4.8394,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03592,
    shape_pt_lon: -90.102069,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.1274,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035909,
    shape_pt_lon: -90.104041,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.3054,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035878,
    shape_pt_lon: -90.107971,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.6614,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035871,
    shape_pt_lon: -90.108672,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.7244,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035867,
    shape_pt_lon: -90.109721,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.8194,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035861,
    shape_pt_lon: -90.110231,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.8654,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035842,
    shape_pt_lon: -90.111669,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.9954,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035831,
    shape_pt_lon: -90.112977,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.1145,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035819,
    shape_pt_lon: -90.115044,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.3015,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035791,
    shape_pt_lon: -90.117143,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.4905,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03576,
    shape_pt_lon: -90.120103,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.7585,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035739,
    shape_pt_lon: -90.121589,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.8935,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035716,
    shape_pt_lon: -90.123932,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.1055,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039218,
    shape_pt_lon: -90.12417,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.4971,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039623,
    shape_pt_lon: -90.124199,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.5432,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041268,
    shape_pt_lon: -90.12431,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.7275,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041367,
    shape_pt_lon: -90.124318,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.7385,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04167,
    shape_pt_lon: -90.124334,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.7725,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041895,
    shape_pt_lon: -90.124331,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.7975,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04201,
    shape_pt_lon: -90.124323,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.8105,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042305,
    shape_pt_lon: -90.124268,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.8439,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042399,
    shape_pt_lon: -90.124242,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.8544,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042677,
    shape_pt_lon: -90.124137,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.8866,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042751,
    shape_pt_lon: -90.124102,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.8961,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04296,
    shape_pt_lon: -90.123991,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.9212,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043212,
    shape_pt_lon: -90.123808,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.954,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043411,
    shape_pt_lon: -90.123621,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.9826,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043686,
    shape_pt_lon: -90.124003,
    shape_pt_sequence: 114,
    shape_dist_traveled: 8.0287,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043862,
    shape_pt_lon: -90.124198,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.0549,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043977,
    shape_pt_lon: -90.124291,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.0707,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.044132,
    shape_pt_lon: -90.124391,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.09,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04434,
    shape_pt_lon: -90.124481,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.1143,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04455,
    shape_pt_lon: -90.12453,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.1386,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045186,
    shape_pt_lon: -90.124551,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.2097,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046396,
    shape_pt_lon: -90.124603,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.3458,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047518,
    shape_pt_lon: -90.124657,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.4709,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047799,
    shape_pt_lon: -90.124697,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.503,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.048109,
    shape_pt_lon: -90.124779,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.5379,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.048326,
    shape_pt_lon: -90.124853,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.5627,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.048622,
    shape_pt_lon: -90.124991,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.5991,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.048762,
    shape_pt_lon: -90.12507,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.6156,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04905,
    shape_pt_lon: -90.125261,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.6527,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049364,
    shape_pt_lon: -90.125467,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.6926,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049539,
    shape_pt_lon: -90.125542,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.7128,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049563,
    shape_pt_lon: -90.125435,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.7233,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04957,
    shape_pt_lon: -90.124206,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.8343,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049579,
    shape_pt_lon: -90.122935,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.9493,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049577,
    shape_pt_lon: -90.122509,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.9883,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049533,
    shape_pt_lon: -90.121899,
    shape_pt_sequence: 135,
    shape_dist_traveled: 9.0435,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049548,
    shape_pt_lon: -90.118757,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.3275,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049554,
    shape_pt_lon: -90.117238,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.4655,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049556,
    shape_pt_lon: -90.117066,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.4805,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04956,
    shape_pt_lon: -90.115882,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.5875,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04957,
    shape_pt_lon: -90.1145,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.7125,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04959,
    shape_pt_lon: -90.110074,
    shape_pt_sequence: 141,
    shape_dist_traveled: 10.1135,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049603,
    shape_pt_lon: -90.107773,
    shape_pt_sequence: 142,
    shape_dist_traveled: 10.3215,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049611,
    shape_pt_lon: -90.106318,
    shape_pt_sequence: 143,
    shape_dist_traveled: 10.4535,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049613,
    shape_pt_lon: -90.105509,
    shape_pt_sequence: 144,
    shape_dist_traveled: 10.5265,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049626,
    shape_pt_lon: -90.103895,
    shape_pt_sequence: 145,
    shape_dist_traveled: 10.6725,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049627,
    shape_pt_lon: -90.103551,
    shape_pt_sequence: 146,
    shape_dist_traveled: 10.7035,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049629,
    shape_pt_lon: -90.102909,
    shape_pt_sequence: 147,
    shape_dist_traveled: 10.7615,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049341,
    shape_pt_lon: -90.102917,
    shape_pt_sequence: 148,
    shape_dist_traveled: 10.7936,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049124,
    shape_pt_lon: -90.102952,
    shape_pt_sequence: 149,
    shape_dist_traveled: 10.8177,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049003,
    shape_pt_lon: -90.103012,
    shape_pt_sequence: 150,
    shape_dist_traveled: 10.8326,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047597,
    shape_pt_lon: -90.10384,
    shape_pt_sequence: 151,
    shape_dist_traveled: 11.0066,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047233,
    shape_pt_lon: -90.102937,
    shape_pt_sequence: 152,
    shape_dist_traveled: 11.0974,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047163,
    shape_pt_lon: -90.102769,
    shape_pt_sequence: 153,
    shape_dist_traveled: 11.1144,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047067,
    shape_pt_lon: -90.102601,
    shape_pt_sequence: 154,
    shape_dist_traveled: 11.1333,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046929,
    shape_pt_lon: -90.102451,
    shape_pt_sequence: 155,
    shape_dist_traveled: 11.1539,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046786,
    shape_pt_lon: -90.102349,
    shape_pt_sequence: 156,
    shape_dist_traveled: 11.1722,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046616,
    shape_pt_lon: -90.102267,
    shape_pt_sequence: 157,
    shape_dist_traveled: 11.1929,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046497,
    shape_pt_lon: -90.102238,
    shape_pt_sequence: 158,
    shape_dist_traveled: 11.206,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046041,
    shape_pt_lon: -90.10223,
    shape_pt_sequence: 159,
    shape_dist_traveled: 11.257,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04605,
    shape_pt_lon: -90.101968,
    shape_pt_sequence: 160,
    shape_dist_traveled: 11.281,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046075,
    shape_pt_lon: -90.101836,
    shape_pt_sequence: 161,
    shape_dist_traveled: 11.2934,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046126,
    shape_pt_lon: -90.101651,
    shape_pt_sequence: 162,
    shape_dist_traveled: 11.3111,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046182,
    shape_pt_lon: -90.101508,
    shape_pt_sequence: 163,
    shape_dist_traveled: 11.3259,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046513,
    shape_pt_lon: -90.100901,
    shape_pt_sequence: 164,
    shape_dist_traveled: 11.3913,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04677,
    shape_pt_lon: -90.100412,
    shape_pt_sequence: 165,
    shape_dist_traveled: 11.4443,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046856,
    shape_pt_lon: -90.100168,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.4685,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046908,
    shape_pt_lon: -90.099917,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.4913,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046923,
    shape_pt_lon: -90.099696,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.5113,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046924,
    shape_pt_lon: -90.099187,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.5574,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04693,
    shape_pt_lon: -90.097885,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.6754,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046936,
    shape_pt_lon: -90.096577,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.7944,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.048342,
    shape_pt_lon: -90.096616,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.9514,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049649,
    shape_pt_lon: -90.09665,
    shape_pt_sequence: 173,
    shape_dist_traveled: 12.0974,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.050397,
    shape_pt_lon: -90.096651,
    shape_pt_sequence: 174,
    shape_dist_traveled: 12.1814,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051419,
    shape_pt_lon: -90.096652,
    shape_pt_sequence: 175,
    shape_dist_traveled: 12.2954,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052112,
    shape_pt_lon: -90.096656,
    shape_pt_sequence: 176,
    shape_dist_traveled: 12.3734,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052854,
    shape_pt_lon: -90.096672,
    shape_pt_sequence: 177,
    shape_dist_traveled: 12.4565,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053351,
    shape_pt_lon: -90.096687,
    shape_pt_sequence: 178,
    shape_dist_traveled: 12.5125,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05373,
    shape_pt_lon: -90.096689,
    shape_pt_sequence: 179,
    shape_dist_traveled: 12.5545,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054102,
    shape_pt_lon: -90.096644,
    shape_pt_sequence: 180,
    shape_dist_traveled: 12.5967,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054406,
    shape_pt_lon: -90.096569,
    shape_pt_sequence: 181,
    shape_dist_traveled: 12.6314,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054704,
    shape_pt_lon: -90.096456,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.6659,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054949,
    shape_pt_lon: -90.09633,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.695,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055309,
    shape_pt_lon: -90.096088,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.7415,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055479,
    shape_pt_lon: -90.095942,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.7646,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056202,
    shape_pt_lon: -90.095312,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.8636,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05612,
    shape_pt_lon: -90.092463,
    shape_pt_sequence: 187,
    shape_dist_traveled: 13.1218,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056029,
    shape_pt_lon: -90.089532,
    shape_pt_sequence: 188,
    shape_dist_traveled: 13.387,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055989,
    shape_pt_lon: -90.08817,
    shape_pt_sequence: 189,
    shape_dist_traveled: 13.5101,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05596,
    shape_pt_lon: -90.086976,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.6181,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.057053,
    shape_pt_lon: -90.086945,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.7412,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.057189,
    shape_pt_lon: -90.086942,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.7562,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.058135,
    shape_pt_lon: -90.086916,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.8622,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.059017,
    shape_pt_lon: -90.086889,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.9602,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.059727,
    shape_pt_lon: -90.08687,
    shape_pt_sequence: 195,
    shape_dist_traveled: 14.0403,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.060732,
    shape_pt_lon: -90.086848,
    shape_pt_sequence: 196,
    shape_dist_traveled: 14.1523,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.061738,
    shape_pt_lon: -90.086839,
    shape_pt_sequence: 197,
    shape_dist_traveled: 14.2653,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06274,
    shape_pt_lon: -90.086805,
    shape_pt_sequence: 198,
    shape_dist_traveled: 14.3773,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.063767,
    shape_pt_lon: -90.086766,
    shape_pt_sequence: 199,
    shape_dist_traveled: 14.4924,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.065291,
    shape_pt_lon: -90.086709,
    shape_pt_sequence: 200,
    shape_dist_traveled: 14.6625,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06705,
    shape_pt_lon: -90.086652,
    shape_pt_sequence: 201,
    shape_dist_traveled: 14.8595,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.067037,
    shape_pt_lon: -90.085823,
    shape_pt_sequence: 202,
    shape_dist_traveled: 14.9345,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06703,
    shape_pt_lon: -90.085181,
    shape_pt_sequence: 203,
    shape_dist_traveled: 14.9925,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.067002,
    shape_pt_lon: -90.083698,
    shape_pt_sequence: 204,
    shape_dist_traveled: 15.1266,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066982,
    shape_pt_lon: -90.082203,
    shape_pt_sequence: 205,
    shape_dist_traveled: 15.2616,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06696,
    shape_pt_lon: -90.08058,
    shape_pt_sequence: 206,
    shape_dist_traveled: 15.4086,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066895,
    shape_pt_lon: -90.077985,
    shape_pt_sequence: 207,
    shape_dist_traveled: 15.6437,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066815,
    shape_pt_lon: -90.074867,
    shape_pt_sequence: 208,
    shape_dist_traveled: 15.9259,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066775,
    shape_pt_lon: -90.073247,
    shape_pt_sequence: 209,
    shape_dist_traveled: 16.072,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066742,
    shape_pt_lon: -90.072068,
    shape_pt_sequence: 210,
    shape_dist_traveled: 16.179,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066726,
    shape_pt_lon: -90.071375,
    shape_pt_sequence: 211,
    shape_dist_traveled: 16.242,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066708,
    shape_pt_lon: -90.070483,
    shape_pt_sequence: 212,
    shape_dist_traveled: 16.3221,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066712,
    shape_pt_lon: -90.07032,
    shape_pt_sequence: 213,
    shape_dist_traveled: 16.3371,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066673,
    shape_pt_lon: -90.068768,
    shape_pt_sequence: 214,
    shape_dist_traveled: 16.4761,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066655,
    shape_pt_lon: -90.068144,
    shape_pt_sequence: 215,
    shape_dist_traveled: 16.5331,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066652,
    shape_pt_lon: -90.068069,
    shape_pt_sequence: 216,
    shape_dist_traveled: 16.5401,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066637,
    shape_pt_lon: -90.06742,
    shape_pt_sequence: 217,
    shape_dist_traveled: 16.5982,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066627,
    shape_pt_lon: -90.067008,
    shape_pt_sequence: 218,
    shape_dist_traveled: 16.6352,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066578,
    shape_pt_lon: -90.065172,
    shape_pt_sequence: 219,
    shape_dist_traveled: 16.8023,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066542,
    shape_pt_lon: -90.06369,
    shape_pt_sequence: 220,
    shape_dist_traveled: 16.9364,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066533,
    shape_pt_lon: -90.063212,
    shape_pt_sequence: 221,
    shape_dist_traveled: 16.9794,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066526,
    shape_pt_lon: -90.062818,
    shape_pt_sequence: 222,
    shape_dist_traveled: 17.0144,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06647,
    shape_pt_lon: -90.060843,
    shape_pt_sequence: 223,
    shape_dist_traveled: 17.1935,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066449,
    shape_pt_lon: -90.060102,
    shape_pt_sequence: 224,
    shape_dist_traveled: 17.2605,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066446,
    shape_pt_lon: -90.059707,
    shape_pt_sequence: 225,
    shape_dist_traveled: 17.2965,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066429,
    shape_pt_lon: -90.059237,
    shape_pt_sequence: 226,
    shape_dist_traveled: 17.3386,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066428,
    shape_pt_lon: -90.05891,
    shape_pt_sequence: 227,
    shape_dist_traveled: 17.3686,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066426,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 228,
    shape_dist_traveled: 17.3737,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.064759,
    shape_pt_lon: -90.059032,
    shape_pt_sequence: 229,
    shape_dist_traveled: 17.5604,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06355,
    shape_pt_lon: -90.059153,
    shape_pt_sequence: 230,
    shape_dist_traveled: 17.6968,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.063108,
    shape_pt_lon: -90.059199,
    shape_pt_sequence: 231,
    shape_dist_traveled: 17.746,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.061676,
    shape_pt_lon: -90.059343,
    shape_pt_sequence: 232,
    shape_dist_traveled: 17.9065,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.060576,
    shape_pt_lon: -90.05946,
    shape_pt_sequence: 233,
    shape_dist_traveled: 18.03,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.059309,
    shape_pt_lon: -90.05959,
    shape_pt_sequence: 234,
    shape_dist_traveled: 18.1725,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05787,
    shape_pt_lon: -90.059736,
    shape_pt_sequence: 235,
    shape_dist_traveled: 18.334,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056792,
    shape_pt_lon: -90.05986,
    shape_pt_sequence: 236,
    shape_dist_traveled: 18.4555,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056303,
    shape_pt_lon: -90.059931,
    shape_pt_sequence: 237,
    shape_dist_traveled: 18.5098,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055334,
    shape_pt_lon: -90.060097,
    shape_pt_sequence: 238,
    shape_dist_traveled: 18.6199,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055262,
    shape_pt_lon: -90.060111,
    shape_pt_sequence: 239,
    shape_dist_traveled: 18.6281,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05522,
    shape_pt_lon: -90.058573,
    shape_pt_sequence: 240,
    shape_dist_traveled: 18.7672,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055166,
    shape_pt_lon: -90.05703,
    shape_pt_sequence: 241,
    shape_dist_traveled: 18.9073,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055159,
    shape_pt_lon: -90.056836,
    shape_pt_sequence: 242,
    shape_dist_traveled: 18.9253,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055113,
    shape_pt_lon: -90.056686,
    shape_pt_sequence: 243,
    shape_dist_traveled: 18.9393,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055034,
    shape_pt_lon: -90.056429,
    shape_pt_sequence: 244,
    shape_dist_traveled: 18.964,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054982,
    shape_pt_lon: -90.056371,
    shape_pt_sequence: 245,
    shape_dist_traveled: 18.9724,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054788,
    shape_pt_lon: -90.05626,
    shape_pt_sequence: 246,
    shape_dist_traveled: 18.9966,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052028,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 247,
    shape_dist_traveled: 19.3056,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 248,
    shape_dist_traveled: 19.4206,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.050398,
    shape_pt_lon: -90.056261,
    shape_pt_sequence: 249,
    shape_dist_traveled: 19.4876,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047606,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 250,
    shape_dist_traveled: 19.7996,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046676,
    shape_pt_lon: -90.056248,
    shape_pt_sequence: 251,
    shape_dist_traveled: 19.9036,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045329,
    shape_pt_lon: -90.056246,
    shape_pt_sequence: 252,
    shape_dist_traveled: 20.0546,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045089,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 253,
    shape_dist_traveled: 20.0816,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04467,
    shape_pt_lon: -90.056243,
    shape_pt_sequence: 254,
    shape_dist_traveled: 20.1286,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.044218,
    shape_pt_lon: -90.056237,
    shape_pt_sequence: 255,
    shape_dist_traveled: 20.1786,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043079,
    shape_pt_lon: -90.056232,
    shape_pt_sequence: 256,
    shape_dist_traveled: 20.3066,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.042746,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 257,
    shape_dist_traveled: 20.3436,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041623,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 258,
    shape_dist_traveled: 20.4696,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041578,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 259,
    shape_dist_traveled: 20.4746,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04153,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 260,
    shape_dist_traveled: 20.4796,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040229,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 261,
    shape_dist_traveled: 20.6256,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039635,
    shape_pt_lon: -90.056241,
    shape_pt_sequence: 262,
    shape_dist_traveled: 20.6916,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039102,
    shape_pt_lon: -90.056252,
    shape_pt_sequence: 263,
    shape_dist_traveled: 20.7517,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038009,
    shape_pt_lon: -90.056229,
    shape_pt_sequence: 264,
    shape_dist_traveled: 20.8737,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.037339,
    shape_pt_lon: -90.056231,
    shape_pt_sequence: 265,
    shape_dist_traveled: 20.9487,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.036911,
    shape_pt_lon: -90.056267,
    shape_pt_sequence: 266,
    shape_dist_traveled: 20.9968,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0363,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 267,
    shape_dist_traveled: 21.0648,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03617,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 268,
    shape_dist_traveled: 21.0798,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035811,
    shape_pt_lon: -90.056275,
    shape_pt_sequence: 269,
    shape_dist_traveled: 21.1198,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035538,
    shape_pt_lon: -90.056273,
    shape_pt_sequence: 270,
    shape_dist_traveled: 21.1508,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035147,
    shape_pt_lon: -90.056251,
    shape_pt_sequence: 271,
    shape_dist_traveled: 21.1939,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034702,
    shape_pt_lon: -90.056244,
    shape_pt_sequence: 272,
    shape_dist_traveled: 21.2439,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034666,
    shape_pt_lon: -90.056244,
    shape_pt_sequence: 273,
    shape_dist_traveled: 21.2479,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034673,
    shape_pt_lon: -90.052031,
    shape_pt_sequence: 274,
    shape_dist_traveled: 21.6299,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034677,
    shape_pt_lon: -90.051866,
    shape_pt_sequence: 275,
    shape_dist_traveled: 21.6449,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034698,
    shape_pt_lon: -90.049753,
    shape_pt_sequence: 276,
    shape_dist_traveled: 21.8359,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034312,
    shape_pt_lon: -90.049753,
    shape_pt_sequence: 277,
    shape_dist_traveled: 21.8789,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03374,
    shape_pt_lon: -90.049735,
    shape_pt_sequence: 278,
    shape_dist_traveled: 21.9429,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032822,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 279,
    shape_dist_traveled: 22.0449,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032236,
    shape_pt_lon: -90.049709,
    shape_pt_sequence: 280,
    shape_dist_traveled: 22.1109,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032005,
    shape_pt_lon: -90.04967,
    shape_pt_sequence: 281,
    shape_dist_traveled: 22.1371,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.031909,
    shape_pt_lon: -90.049648,
    shape_pt_sequence: 282,
    shape_dist_traveled: 22.1483,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.031591,
    shape_pt_lon: -90.049585,
    shape_pt_sequence: 283,
    shape_dist_traveled: 22.1838,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03145,
    shape_pt_lon: -90.049571,
    shape_pt_sequence: 284,
    shape_dist_traveled: 22.1998,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03084,
    shape_pt_lon: -90.049573,
    shape_pt_sequence: 285,
    shape_dist_traveled: 22.2678,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02862,
    shape_pt_lon: -90.049574,
    shape_pt_sequence: 286,
    shape_dist_traveled: 22.5168,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.028051,
    shape_pt_lon: -90.049568,
    shape_pt_sequence: 287,
    shape_dist_traveled: 22.5798,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.027841,
    shape_pt_lon: -90.049525,
    shape_pt_sequence: 288,
    shape_dist_traveled: 22.6042,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02764,
    shape_pt_lon: -90.04944,
    shape_pt_sequence: 289,
    shape_dist_traveled: 22.6273,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02748,
    shape_pt_lon: -90.049343,
    shape_pt_sequence: 290,
    shape_dist_traveled: 22.6474,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0273,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 291,
    shape_dist_traveled: 22.6718,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.027112,
    shape_pt_lon: -90.049008,
    shape_pt_sequence: 292,
    shape_dist_traveled: 22.6982,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026961,
    shape_pt_lon: -90.048902,
    shape_pt_sequence: 293,
    shape_dist_traveled: 22.7179,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026856,
    shape_pt_lon: -90.048853,
    shape_pt_sequence: 294,
    shape_dist_traveled: 22.7306,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026719,
    shape_pt_lon: -90.048797,
    shape_pt_sequence: 295,
    shape_dist_traveled: 22.7464,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026499,
    shape_pt_lon: -90.048751,
    shape_pt_sequence: 296,
    shape_dist_traveled: 22.7719,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026263,
    shape_pt_lon: -90.048748,
    shape_pt_sequence: 297,
    shape_dist_traveled: 22.7979,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025909,
    shape_pt_lon: -90.048749,
    shape_pt_sequence: 298,
    shape_dist_traveled: 22.8379,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025085,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 299,
    shape_dist_traveled: 22.9299,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024536,
    shape_pt_lon: -90.048762,
    shape_pt_sequence: 300,
    shape_dist_traveled: 22.9919,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023613,
    shape_pt_lon: -90.048771,
    shape_pt_sequence: 301,
    shape_dist_traveled: 23.0949,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023465,
    shape_pt_lon: -90.048772,
    shape_pt_sequence: 302,
    shape_dist_traveled: 23.1109,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.023219,
    shape_pt_lon: -90.048811,
    shape_pt_sequence: 303,
    shape_dist_traveled: 23.1392,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.022988,
    shape_pt_lon: -90.048899,
    shape_pt_sequence: 304,
    shape_dist_traveled: 23.1664,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.022242,
    shape_pt_lon: -90.049248,
    shape_pt_sequence: 305,
    shape_dist_traveled: 23.255,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.022146,
    shape_pt_lon: -90.049293,
    shape_pt_sequence: 306,
    shape_dist_traveled: 23.2667,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02172,
    shape_pt_lon: -90.049489,
    shape_pt_sequence: 307,
    shape_dist_traveled: 23.3179,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02158,
    shape_pt_lon: -90.049537,
    shape_pt_sequence: 308,
    shape_dist_traveled: 23.3335,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.021337,
    shape_pt_lon: -90.049568,
    shape_pt_sequence: 309,
    shape_dist_traveled: 23.3606,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020908,
    shape_pt_lon: -90.049561,
    shape_pt_sequence: 310,
    shape_dist_traveled: 23.4086,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020822,
    shape_pt_lon: -90.049562,
    shape_pt_sequence: 311,
    shape_dist_traveled: 23.4186,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020845,
    shape_pt_lon: -90.04757,
    shape_pt_sequence: 312,
    shape_dist_traveled: 23.5997,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020863,
    shape_pt_lon: -90.045506,
    shape_pt_sequence: 313,
    shape_dist_traveled: 23.7857,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020874,
    shape_pt_lon: -90.044716,
    shape_pt_sequence: 314,
    shape_dist_traveled: 23.8577,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020889,
    shape_pt_lon: -90.04294,
    shape_pt_sequence: 315,
    shape_dist_traveled: 24.0187,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020897,
    shape_pt_lon: -90.042449,
    shape_pt_sequence: 316,
    shape_dist_traveled: 24.0627,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.041826,
    shape_pt_sequence: 317,
    shape_dist_traveled: 24.1188,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020939,
    shape_pt_lon: -90.041436,
    shape_pt_sequence: 318,
    shape_dist_traveled: 24.1548,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02096,
    shape_pt_lon: -90.040659,
    shape_pt_sequence: 319,
    shape_dist_traveled: 24.2249,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020979,
    shape_pt_lon: -90.038974,
    shape_pt_sequence: 320,
    shape_dist_traveled: 24.3779,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020982,
    shape_pt_lon: -90.038615,
    shape_pt_sequence: 321,
    shape_dist_traveled: 24.4099,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020957,
    shape_pt_lon: -90.037824,
    shape_pt_sequence: 322,
    shape_dist_traveled: 24.482,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020941,
    shape_pt_lon: -90.037618,
    shape_pt_sequence: 323,
    shape_dist_traveled: 24.5,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020908,
    shape_pt_lon: -90.037041,
    shape_pt_sequence: 324,
    shape_dist_traveled: 24.5521,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020901,
    shape_pt_lon: -90.036342,
    shape_pt_sequence: 325,
    shape_dist_traveled: 24.6161,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020898,
    shape_pt_lon: -90.035051,
    shape_pt_sequence: 326,
    shape_dist_traveled: 24.7331,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020909,
    shape_pt_lon: -90.032851,
    shape_pt_sequence: 327,
    shape_dist_traveled: 24.9322,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020912,
    shape_pt_lon: -90.032211,
    shape_pt_sequence: 328,
    shape_dist_traveled: 24.9902,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.029856,
    shape_pt_sequence: 329,
    shape_dist_traveled: 25.2032,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020916,
    shape_pt_lon: -90.029769,
    shape_pt_sequence: 330,
    shape_dist_traveled: 25.2112,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020918,
    shape_pt_lon: -90.029067,
    shape_pt_sequence: 331,
    shape_dist_traveled: 25.2742,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020938,
    shape_pt_lon: -90.02742,
    shape_pt_sequence: 332,
    shape_dist_traveled: 25.4232,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020943,
    shape_pt_lon: -90.027153,
    shape_pt_sequence: 333,
    shape_dist_traveled: 25.4472,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020948,
    shape_pt_lon: -90.026724,
    shape_pt_sequence: 334,
    shape_dist_traveled: 25.4862,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020954,
    shape_pt_lon: -90.025991,
    shape_pt_sequence: 335,
    shape_dist_traveled: 25.5522,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020887,
    shape_pt_lon: -90.02584,
    shape_pt_sequence: 336,
    shape_dist_traveled: 25.5678,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020878,
    shape_pt_lon: -90.025207,
    shape_pt_sequence: 337,
    shape_dist_traveled: 25.6249,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020874,
    shape_pt_lon: -90.025078,
    shape_pt_sequence: 338,
    shape_dist_traveled: 25.6369,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020871,
    shape_pt_lon: -90.024902,
    shape_pt_sequence: 339,
    shape_dist_traveled: 25.6529,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020878,
    shape_pt_lon: -90.024331,
    shape_pt_sequence: 340,
    shape_dist_traveled: 25.7049,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020879,
    shape_pt_lon: -90.023421,
    shape_pt_sequence: 341,
    shape_dist_traveled: 25.7869,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020879,
    shape_pt_lon: -90.023311,
    shape_pt_sequence: 342,
    shape_dist_traveled: 25.7969,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020876,
    shape_pt_lon: -90.021951,
    shape_pt_sequence: 343,
    shape_dist_traveled: 25.9199,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020876,
    shape_pt_lon: -90.021858,
    shape_pt_sequence: 344,
    shape_dist_traveled: 25.9279,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020873,
    shape_pt_lon: -90.02067,
    shape_pt_sequence: 345,
    shape_dist_traveled: 26.0359,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020872,
    shape_pt_lon: -90.019925,
    shape_pt_sequence: 346,
    shape_dist_traveled: 26.1029,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020873,
    shape_pt_lon: -90.019829,
    shape_pt_sequence: 347,
    shape_dist_traveled: 26.1119,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02088,
    shape_pt_lon: -90.018737,
    shape_pt_sequence: 348,
    shape_dist_traveled: 26.2109,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020883,
    shape_pt_lon: -90.01798,
    shape_pt_sequence: 349,
    shape_dist_traveled: 26.2789,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020887,
    shape_pt_lon: -90.016694,
    shape_pt_sequence: 350,
    shape_dist_traveled: 26.3959,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020889,
    shape_pt_lon: -90.015892,
    shape_pt_sequence: 351,
    shape_dist_traveled: 26.4679,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020894,
    shape_pt_lon: -90.014853,
    shape_pt_sequence: 352,
    shape_dist_traveled: 26.5619,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020899,
    shape_pt_lon: -90.014055,
    shape_pt_sequence: 353,
    shape_dist_traveled: 26.6349,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0209,
    shape_pt_lon: -90.013953,
    shape_pt_sequence: 354,
    shape_dist_traveled: 26.6439,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020911,
    shape_pt_lon: -90.01247,
    shape_pt_sequence: 355,
    shape_dist_traveled: 26.7779,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020927,
    shape_pt_lon: -90.010379,
    shape_pt_sequence: 356,
    shape_dist_traveled: 26.9669,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020936,
    shape_pt_lon: -90.009226,
    shape_pt_sequence: 357,
    shape_dist_traveled: 27.0719,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.020946,
    shape_pt_lon: -90.0083,
    shape_pt_sequence: 358,
    shape_dist_traveled: 27.155,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02095,
    shape_pt_lon: -90.007584,
    shape_pt_sequence: 359,
    shape_dist_traveled: 27.22,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02095,
    shape_pt_lon: -90.007528,
    shape_pt_sequence: 360,
    shape_dist_traveled: 27.225,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02102,
    shape_pt_lon: -90.007527,
    shape_pt_sequence: 361,
    shape_dist_traveled: 27.233,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.021097,
    shape_pt_lon: -90.007527,
    shape_pt_sequence: 362,
    shape_dist_traveled: 27.241,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.021486,
    shape_pt_lon: -90.007524,
    shape_pt_sequence: 363,
    shape_dist_traveled: 27.285,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.022322,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 364,
    shape_dist_traveled: 27.378,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.022977,
    shape_pt_lon: -90.007531,
    shape_pt_sequence: 365,
    shape_dist_traveled: 27.452,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.024731,
    shape_pt_lon: -90.007529,
    shape_pt_sequence: 366,
    shape_dist_traveled: 27.648,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.025296,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 367,
    shape_dist_traveled: 27.711,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026092,
    shape_pt_lon: -90.007532,
    shape_pt_sequence: 368,
    shape_dist_traveled: 27.8,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.026963,
    shape_pt_lon: -90.00753,
    shape_pt_sequence: 369,
    shape_dist_traveled: 27.898,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.027133,
    shape_pt_lon: -90.007529,
    shape_pt_sequence: 370,
    shape_dist_traveled: 27.917,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.027741,
    shape_pt_lon: -90.007528,
    shape_pt_sequence: 371,
    shape_dist_traveled: 27.985,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.028148,
    shape_pt_lon: -90.007523,
    shape_pt_sequence: 372,
    shape_dist_traveled: 28.03,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.02883,
    shape_pt_lon: -90.00752,
    shape_pt_sequence: 373,
    shape_dist_traveled: 28.107,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.029801,
    shape_pt_lon: -90.007518,
    shape_pt_sequence: 374,
    shape_dist_traveled: 28.215,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.031516,
    shape_pt_lon: -90.007518,
    shape_pt_sequence: 375,
    shape_dist_traveled: 28.407,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.032332,
    shape_pt_lon: -90.00752,
    shape_pt_sequence: 376,
    shape_dist_traveled: 28.498,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.033794,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 377,
    shape_dist_traveled: 28.662,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.034466,
    shape_pt_lon: -90.007516,
    shape_pt_sequence: 378,
    shape_dist_traveled: 28.737,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03458,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 379,
    shape_dist_traveled: 28.75,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.035578,
    shape_pt_lon: -90.007517,
    shape_pt_sequence: 380,
    shape_dist_traveled: 28.862,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.03662,
    shape_pt_lon: -90.007516,
    shape_pt_sequence: 381,
    shape_dist_traveled: 28.978,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038098,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 382,
    shape_dist_traveled: 29.144,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038476,
    shape_pt_lon: -90.007512,
    shape_pt_sequence: 383,
    shape_dist_traveled: 29.186,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.038955,
    shape_pt_lon: -90.007515,
    shape_pt_sequence: 384,
    shape_dist_traveled: 29.239,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039553,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 385,
    shape_dist_traveled: 29.306,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.039731,
    shape_pt_lon: -90.007511,
    shape_pt_sequence: 386,
    shape_dist_traveled: 29.326,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.040359,
    shape_pt_lon: -90.007507,
    shape_pt_sequence: 387,
    shape_dist_traveled: 29.396,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.041171,
    shape_pt_lon: -90.007508,
    shape_pt_sequence: 388,
    shape_dist_traveled: 29.487,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043219,
    shape_pt_lon: -90.007499,
    shape_pt_sequence: 389,
    shape_dist_traveled: 29.716,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.043316,
    shape_pt_lon: -90.007499,
    shape_pt_sequence: 390,
    shape_dist_traveled: 29.727,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.044343,
    shape_pt_lon: -90.007509,
    shape_pt_sequence: 391,
    shape_dist_traveled: 29.842,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.044967,
    shape_pt_lon: -90.007535,
    shape_pt_sequence: 392,
    shape_dist_traveled: 29.912,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04507,
    shape_pt_lon: -90.007539,
    shape_pt_sequence: 393,
    shape_dist_traveled: 29.9241,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045279,
    shape_pt_lon: -90.007542,
    shape_pt_sequence: 394,
    shape_dist_traveled: 29.9471,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045576,
    shape_pt_lon: -90.007546,
    shape_pt_sequence: 395,
    shape_dist_traveled: 29.9801,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.045801,
    shape_pt_lon: -90.007548,
    shape_pt_sequence: 396,
    shape_dist_traveled: 30.0051,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.046269,
    shape_pt_lon: -90.00755,
    shape_pt_sequence: 397,
    shape_dist_traveled: 30.0581,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04695,
    shape_pt_lon: -90.007508,
    shape_pt_sequence: 398,
    shape_dist_traveled: 30.1342,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047422,
    shape_pt_lon: -90.00749,
    shape_pt_sequence: 399,
    shape_dist_traveled: 30.1872,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047629,
    shape_pt_lon: -90.00749,
    shape_pt_sequence: 400,
    shape_dist_traveled: 30.2102,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.047709,
    shape_pt_lon: -90.007487,
    shape_pt_sequence: 401,
    shape_dist_traveled: 30.2192,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.04841,
    shape_pt_lon: -90.007481,
    shape_pt_sequence: 402,
    shape_dist_traveled: 30.2972,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049353,
    shape_pt_lon: -90.007474,
    shape_pt_sequence: 403,
    shape_dist_traveled: 30.4032,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.049676,
    shape_pt_lon: -90.007473,
    shape_pt_sequence: 404,
    shape_dist_traveled: 30.4392,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.050069,
    shape_pt_lon: -90.007472,
    shape_pt_sequence: 405,
    shape_dist_traveled: 30.4832,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051094,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 406,
    shape_dist_traveled: 30.5982,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051123,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 407,
    shape_dist_traveled: 30.6012,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051152,
    shape_pt_lon: -90.00746,
    shape_pt_sequence: 408,
    shape_dist_traveled: 30.6042,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052224,
    shape_pt_lon: -90.007454,
    shape_pt_sequence: 409,
    shape_dist_traveled: 30.7242,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052306,
    shape_pt_lon: -90.007453,
    shape_pt_sequence: 410,
    shape_dist_traveled: 30.7332,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05241,
    shape_pt_lon: -90.007453,
    shape_pt_sequence: 411,
    shape_dist_traveled: 30.7452,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052834,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 412,
    shape_dist_traveled: 30.7922,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052913,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 413,
    shape_dist_traveled: 30.8012,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053152,
    shape_pt_lon: -90.007449,
    shape_pt_sequence: 414,
    shape_dist_traveled: 30.8282,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053425,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 415,
    shape_dist_traveled: 30.8582,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053689,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 416,
    shape_dist_traveled: 30.8882,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053728,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 417,
    shape_dist_traveled: 30.8922,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053765,
    shape_pt_lon: -90.00745,
    shape_pt_sequence: 418,
    shape_dist_traveled: 30.8962,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05375,
    shape_pt_lon: -90.007011,
    shape_pt_sequence: 419,
    shape_dist_traveled: 30.9363,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053738,
    shape_pt_lon: -90.006541,
    shape_pt_sequence: 420,
    shape_dist_traveled: 30.9783,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053731,
    shape_pt_lon: -90.006348,
    shape_pt_sequence: 421,
    shape_dist_traveled: 30.9963,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053719,
    shape_pt_lon: -90.005967,
    shape_pt_sequence: 422,
    shape_dist_traveled: 31.0304,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053716,
    shape_pt_lon: -90.005816,
    shape_pt_sequence: 423,
    shape_dist_traveled: 31.0444,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053714,
    shape_pt_lon: -90.00578,
    shape_pt_sequence: 424,
    shape_dist_traveled: 31.0474,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053709,
    shape_pt_lon: -90.005523,
    shape_pt_sequence: 425,
    shape_dist_traveled: 31.0704,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053688,
    shape_pt_lon: -90.004857,
    shape_pt_sequence: 426,
    shape_dist_traveled: 31.1304,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053683,
    shape_pt_lon: -90.004726,
    shape_pt_sequence: 427,
    shape_dist_traveled: 31.1425,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053672,
    shape_pt_lon: -90.004259,
    shape_pt_sequence: 428,
    shape_dist_traveled: 31.1855,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053656,
    shape_pt_lon: -90.003766,
    shape_pt_sequence: 429,
    shape_dist_traveled: 31.2295,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053648,
    shape_pt_lon: -90.003511,
    shape_pt_sequence: 430,
    shape_dist_traveled: 31.2525,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053645,
    shape_pt_lon: -90.003392,
    shape_pt_sequence: 431,
    shape_dist_traveled: 31.2636,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05364,
    shape_pt_lon: -90.003193,
    shape_pt_sequence: 432,
    shape_dist_traveled: 31.2816,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053631,
    shape_pt_lon: -90.002807,
    shape_pt_sequence: 433,
    shape_dist_traveled: 31.3166,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053622,
    shape_pt_lon: -90.002532,
    shape_pt_sequence: 434,
    shape_dist_traveled: 31.3416,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053603,
    shape_pt_lon: -90.001916,
    shape_pt_sequence: 435,
    shape_dist_traveled: 31.3966,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053577,
    shape_pt_lon: -90.001128,
    shape_pt_sequence: 436,
    shape_dist_traveled: 31.4687,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05351,
    shape_pt_lon: -89.999017,
    shape_pt_sequence: 437,
    shape_dist_traveled: 31.6599,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053509,
    shape_pt_lon: -89.998946,
    shape_pt_sequence: 438,
    shape_dist_traveled: 31.6659,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053506,
    shape_pt_lon: -89.998869,
    shape_pt_sequence: 439,
    shape_dist_traveled: 31.6729,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053489,
    shape_pt_lon: -89.998186,
    shape_pt_sequence: 440,
    shape_dist_traveled: 31.7349,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053479,
    shape_pt_lon: -89.997909,
    shape_pt_sequence: 441,
    shape_dist_traveled: 31.7599,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053445,
    shape_pt_lon: -89.996838,
    shape_pt_sequence: 442,
    shape_dist_traveled: 31.857,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053418,
    shape_pt_lon: -89.995841,
    shape_pt_sequence: 443,
    shape_dist_traveled: 31.947,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053396,
    shape_pt_lon: -89.995022,
    shape_pt_sequence: 444,
    shape_dist_traveled: 32.0211,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053392,
    shape_pt_lon: -89.994918,
    shape_pt_sequence: 445,
    shape_dist_traveled: 32.0311,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053371,
    shape_pt_lon: -89.99416,
    shape_pt_sequence: 446,
    shape_dist_traveled: 32.0991,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053309,
    shape_pt_lon: -89.991777,
    shape_pt_sequence: 447,
    shape_dist_traveled: 32.3153,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053306,
    shape_pt_lon: -89.991485,
    shape_pt_sequence: 448,
    shape_dist_traveled: 32.3413,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053302,
    shape_pt_lon: -89.991299,
    shape_pt_sequence: 449,
    shape_dist_traveled: 32.3583,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053298,
    shape_pt_lon: -89.99111,
    shape_pt_sequence: 450,
    shape_dist_traveled: 32.3753,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053286,
    shape_pt_lon: -89.990665,
    shape_pt_sequence: 451,
    shape_dist_traveled: 32.4153,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053272,
    shape_pt_lon: -89.989986,
    shape_pt_sequence: 452,
    shape_dist_traveled: 32.4773,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053271,
    shape_pt_lon: -89.989901,
    shape_pt_sequence: 453,
    shape_dist_traveled: 32.4843,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989858,
    shape_pt_sequence: 454,
    shape_dist_traveled: 32.4883,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05327,
    shape_pt_lon: -89.989748,
    shape_pt_sequence: 455,
    shape_dist_traveled: 32.4983,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053257,
    shape_pt_lon: -89.989703,
    shape_pt_sequence: 456,
    shape_dist_traveled: 32.5024,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053219,
    shape_pt_lon: -89.989648,
    shape_pt_sequence: 457,
    shape_dist_traveled: 32.5089,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053181,
    shape_pt_lon: -89.989544,
    shape_pt_sequence: 458,
    shape_dist_traveled: 32.52,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053183,
    shape_pt_lon: -89.989342,
    shape_pt_sequence: 459,
    shape_dist_traveled: 32.5381,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05318,
    shape_pt_lon: -89.989049,
    shape_pt_sequence: 460,
    shape_dist_traveled: 32.5641,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053186,
    shape_pt_lon: -89.988826,
    shape_pt_sequence: 461,
    shape_dist_traveled: 32.5851,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053228,
    shape_pt_lon: -89.988528,
    shape_pt_sequence: 462,
    shape_dist_traveled: 32.6126,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053316,
    shape_pt_lon: -89.988099,
    shape_pt_sequence: 463,
    shape_dist_traveled: 32.6519,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05335,
    shape_pt_lon: -89.987783,
    shape_pt_sequence: 464,
    shape_dist_traveled: 32.681,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053383,
    shape_pt_lon: -89.987429,
    shape_pt_sequence: 465,
    shape_dist_traveled: 32.7133,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053356,
    shape_pt_lon: -89.987097,
    shape_pt_sequence: 466,
    shape_dist_traveled: 32.7434,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05334,
    shape_pt_lon: -89.98697,
    shape_pt_sequence: 467,
    shape_dist_traveled: 32.7546,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053296,
    shape_pt_lon: -89.986777,
    shape_pt_sequence: 468,
    shape_dist_traveled: 32.7733,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053216,
    shape_pt_lon: -89.986536,
    shape_pt_sequence: 469,
    shape_dist_traveled: 32.797,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.053179,
    shape_pt_lon: -89.986439,
    shape_pt_sequence: 470,
    shape_dist_traveled: 32.8069,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052983,
    shape_pt_lon: -89.986032,
    shape_pt_sequence: 471,
    shape_dist_traveled: 32.8491,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052821,
    shape_pt_lon: -89.985703,
    shape_pt_sequence: 472,
    shape_dist_traveled: 32.8841,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052692,
    shape_pt_lon: -89.985398,
    shape_pt_sequence: 473,
    shape_dist_traveled: 32.9154,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.984897,
    shape_pt_sequence: 474,
    shape_dist_traveled: 32.9646,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052141,
    shape_pt_lon: -89.983801,
    shape_pt_sequence: 475,
    shape_dist_traveled: 33.0722,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052073,
    shape_pt_lon: -89.983532,
    shape_pt_sequence: 476,
    shape_dist_traveled: 33.0981,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051989,
    shape_pt_lon: -89.9831,
    shape_pt_sequence: 477,
    shape_dist_traveled: 33.1384,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051956,
    shape_pt_lon: -89.982767,
    shape_pt_sequence: 478,
    shape_dist_traveled: 33.1685,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051951,
    shape_pt_lon: -89.982618,
    shape_pt_sequence: 479,
    shape_dist_traveled: 33.1816,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051948,
    shape_pt_lon: -89.982474,
    shape_pt_sequence: 480,
    shape_dist_traveled: 33.1946,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05194,
    shape_pt_lon: -89.982211,
    shape_pt_sequence: 481,
    shape_dist_traveled: 33.2186,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051956,
    shape_pt_lon: -89.982043,
    shape_pt_sequence: 482,
    shape_dist_traveled: 33.2337,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051963,
    shape_pt_lon: -89.981939,
    shape_pt_sequence: 483,
    shape_dist_traveled: 33.2438,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051966,
    shape_pt_lon: -89.981917,
    shape_pt_sequence: 484,
    shape_dist_traveled: 33.2458,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052007,
    shape_pt_lon: -89.981483,
    shape_pt_sequence: 485,
    shape_dist_traveled: 33.2851,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052061,
    shape_pt_lon: -89.980869,
    shape_pt_sequence: 486,
    shape_dist_traveled: 33.3404,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052083,
    shape_pt_lon: -89.980628,
    shape_pt_sequence: 487,
    shape_dist_traveled: 33.3625,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052143,
    shape_pt_lon: -89.979973,
    shape_pt_sequence: 488,
    shape_dist_traveled: 33.4229,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.979097,
    shape_pt_sequence: 489,
    shape_dist_traveled: 33.5023,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052244,
    shape_pt_lon: -89.978727,
    shape_pt_sequence: 490,
    shape_dist_traveled: 33.5355,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0523,
    shape_pt_lon: -89.978025,
    shape_pt_sequence: 491,
    shape_dist_traveled: 33.5998,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052319,
    shape_pt_lon: -89.977789,
    shape_pt_sequence: 492,
    shape_dist_traveled: 33.6209,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052351,
    shape_pt_lon: -89.977427,
    shape_pt_sequence: 493,
    shape_dist_traveled: 33.6541,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052415,
    shape_pt_lon: -89.976422,
    shape_pt_sequence: 494,
    shape_dist_traveled: 33.7454,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.975876,
    shape_pt_sequence: 495,
    shape_dist_traveled: 33.7947,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052529,
    shape_pt_lon: -89.974982,
    shape_pt_sequence: 496,
    shape_dist_traveled: 33.8761,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052552,
    shape_pt_lon: -89.97467,
    shape_pt_sequence: 497,
    shape_dist_traveled: 33.9041,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052572,
    shape_pt_lon: -89.974416,
    shape_pt_sequence: 498,
    shape_dist_traveled: 33.9272,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052701,
    shape_pt_lon: -89.972892,
    shape_pt_sequence: 499,
    shape_dist_traveled: 34.066,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052722,
    shape_pt_lon: -89.972547,
    shape_pt_sequence: 500,
    shape_dist_traveled: 34.0971,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052734,
    shape_pt_lon: -89.971971,
    shape_pt_sequence: 501,
    shape_dist_traveled: 34.1491,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05266,
    shape_pt_lon: -89.969172,
    shape_pt_sequence: 502,
    shape_dist_traveled: 34.4023,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052654,
    shape_pt_lon: -89.968527,
    shape_pt_sequence: 503,
    shape_dist_traveled: 34.4613,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052637,
    shape_pt_lon: -89.967793,
    shape_pt_sequence: 504,
    shape_dist_traveled: 34.5273,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052608,
    shape_pt_lon: -89.966653,
    shape_pt_sequence: 505,
    shape_dist_traveled: 34.6303,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052573,
    shape_pt_lon: -89.965423,
    shape_pt_sequence: 506,
    shape_dist_traveled: 34.7424,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052555,
    shape_pt_lon: -89.964531,
    shape_pt_sequence: 507,
    shape_dist_traveled: 34.8224,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052536,
    shape_pt_lon: -89.963986,
    shape_pt_sequence: 508,
    shape_dist_traveled: 34.8725,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05253,
    shape_pt_lon: -89.963778,
    shape_pt_sequence: 509,
    shape_dist_traveled: 34.8905,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052513,
    shape_pt_lon: -89.963048,
    shape_pt_sequence: 510,
    shape_dist_traveled: 34.9565,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05245,
    shape_pt_lon: -89.961156,
    shape_pt_sequence: 511,
    shape_dist_traveled: 35.1287,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052413,
    shape_pt_lon: -89.960378,
    shape_pt_sequence: 512,
    shape_dist_traveled: 35.1988,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052393,
    shape_pt_lon: -89.959713,
    shape_pt_sequence: 513,
    shape_dist_traveled: 35.2588,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052401,
    shape_pt_lon: -89.959699,
    shape_pt_sequence: 514,
    shape_dist_traveled: 35.2602,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052459,
    shape_pt_lon: -89.959593,
    shape_pt_sequence: 515,
    shape_dist_traveled: 35.2719,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052455,
    shape_pt_lon: -89.959501,
    shape_pt_sequence: 516,
    shape_dist_traveled: 35.2799,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052399,
    shape_pt_lon: -89.957771,
    shape_pt_sequence: 517,
    shape_dist_traveled: 35.437,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052333,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 518,
    shape_dist_traveled: 35.6481,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052316,
    shape_pt_lon: -89.954807,
    shape_pt_sequence: 519,
    shape_dist_traveled: 35.7051,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052286,
    shape_pt_lon: -89.953853,
    shape_pt_sequence: 520,
    shape_dist_traveled: 35.7912,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052243,
    shape_pt_lon: -89.951672,
    shape_pt_sequence: 521,
    shape_dist_traveled: 35.9893,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052216,
    shape_pt_lon: -89.949773,
    shape_pt_sequence: 522,
    shape_dist_traveled: 36.1603,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052203,
    shape_pt_lon: -89.94913,
    shape_pt_sequence: 523,
    shape_dist_traveled: 36.2193,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052189,
    shape_pt_lon: -89.948251,
    shape_pt_sequence: 524,
    shape_dist_traveled: 36.2983,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052153,
    shape_pt_lon: -89.946399,
    shape_pt_sequence: 525,
    shape_dist_traveled: 36.4664,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0521,
    shape_pt_lon: -89.944961,
    shape_pt_sequence: 526,
    shape_dist_traveled: 36.5965,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052043,
    shape_pt_lon: -89.943377,
    shape_pt_sequence: 527,
    shape_dist_traveled: 36.7396,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051961,
    shape_pt_lon: -89.940352,
    shape_pt_sequence: 528,
    shape_dist_traveled: 37.0137,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05188,
    shape_pt_lon: -89.937152,
    shape_pt_sequence: 529,
    shape_dist_traveled: 37.3029,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051877,
    shape_pt_lon: -89.937071,
    shape_pt_sequence: 530,
    shape_dist_traveled: 37.3099,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051817,
    shape_pt_lon: -89.936961,
    shape_pt_sequence: 531,
    shape_dist_traveled: 37.3221,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051775,
    shape_pt_lon: -89.935403,
    shape_pt_sequence: 532,
    shape_dist_traveled: 37.4631,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051764,
    shape_pt_lon: -89.934273,
    shape_pt_sequence: 533,
    shape_dist_traveled: 37.5662,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05176,
    shape_pt_lon: -89.934009,
    shape_pt_sequence: 534,
    shape_dist_traveled: 37.5892,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051759,
    shape_pt_lon: -89.933918,
    shape_pt_sequence: 535,
    shape_dist_traveled: 37.5982,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051755,
    shape_pt_lon: -89.933766,
    shape_pt_sequence: 536,
    shape_dist_traveled: 37.6112,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051748,
    shape_pt_lon: -89.933588,
    shape_pt_sequence: 537,
    shape_dist_traveled: 37.6272,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051739,
    shape_pt_lon: -89.933392,
    shape_pt_sequence: 538,
    shape_dist_traveled: 37.6452,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051731,
    shape_pt_lon: -89.93318,
    shape_pt_sequence: 539,
    shape_dist_traveled: 37.6642,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051719,
    shape_pt_lon: -89.932847,
    shape_pt_sequence: 540,
    shape_dist_traveled: 37.6953,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051673,
    shape_pt_lon: -89.931306,
    shape_pt_sequence: 541,
    shape_dist_traveled: 37.8344,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051659,
    shape_pt_lon: -89.930718,
    shape_pt_sequence: 542,
    shape_dist_traveled: 37.8874,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051663,
    shape_pt_lon: -89.929544,
    shape_pt_sequence: 543,
    shape_dist_traveled: 37.9934,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051646,
    shape_pt_lon: -89.929058,
    shape_pt_sequence: 544,
    shape_dist_traveled: 38.0374,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051621,
    shape_pt_lon: -89.928551,
    shape_pt_sequence: 545,
    shape_dist_traveled: 38.0835,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051564,
    shape_pt_lon: -89.927932,
    shape_pt_sequence: 546,
    shape_dist_traveled: 38.1399,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05155,
    shape_pt_lon: -89.927513,
    shape_pt_sequence: 547,
    shape_dist_traveled: 38.1779,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051537,
    shape_pt_lon: -89.926691,
    shape_pt_sequence: 548,
    shape_dist_traveled: 38.2519,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051529,
    shape_pt_lon: -89.926312,
    shape_pt_sequence: 549,
    shape_dist_traveled: 38.2869,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051526,
    shape_pt_lon: -89.926058,
    shape_pt_sequence: 550,
    shape_dist_traveled: 38.3099,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051524,
    shape_pt_lon: -89.92595,
    shape_pt_sequence: 551,
    shape_dist_traveled: 38.3189,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051522,
    shape_pt_lon: -89.92585,
    shape_pt_sequence: 552,
    shape_dist_traveled: 38.3279,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051513,
    shape_pt_lon: -89.925285,
    shape_pt_sequence: 553,
    shape_dist_traveled: 38.3799,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051507,
    shape_pt_lon: -89.924678,
    shape_pt_sequence: 554,
    shape_dist_traveled: 38.434,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051495,
    shape_pt_lon: -89.923463,
    shape_pt_sequence: 555,
    shape_dist_traveled: 38.544,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051469,
    shape_pt_lon: -89.922197,
    shape_pt_sequence: 556,
    shape_dist_traveled: 38.659,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05153,
    shape_pt_lon: -89.922086,
    shape_pt_sequence: 557,
    shape_dist_traveled: 38.6712,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051529,
    shape_pt_lon: -89.921959,
    shape_pt_sequence: 558,
    shape_dist_traveled: 38.6822,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.051622,
    shape_pt_lon: -89.921956,
    shape_pt_sequence: 559,
    shape_dist_traveled: 38.6923,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052268,
    shape_pt_lon: -89.92193,
    shape_pt_sequence: 560,
    shape_dist_traveled: 38.7653,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052659,
    shape_pt_lon: -89.921919,
    shape_pt_sequence: 561,
    shape_dist_traveled: 38.8083,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.052945,
    shape_pt_lon: -89.921907,
    shape_pt_sequence: 562,
    shape_dist_traveled: 38.8403,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05338,
    shape_pt_lon: -89.92189,
    shape_pt_sequence: 563,
    shape_dist_traveled: 38.8893,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054293,
    shape_pt_lon: -89.921853,
    shape_pt_sequence: 564,
    shape_dist_traveled: 38.9914,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054492,
    shape_pt_lon: -89.921844,
    shape_pt_sequence: 565,
    shape_dist_traveled: 39.0134,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.054871,
    shape_pt_lon: -89.921831,
    shape_pt_sequence: 566,
    shape_dist_traveled: 39.0564,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055198,
    shape_pt_lon: -89.921844,
    shape_pt_sequence: 567,
    shape_dist_traveled: 39.0924,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055455,
    shape_pt_lon: -89.921897,
    shape_pt_sequence: 568,
    shape_dist_traveled: 39.1218,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05555,
    shape_pt_lon: -89.921927,
    shape_pt_sequence: 569,
    shape_dist_traveled: 39.133,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.055926,
    shape_pt_lon: -89.922089,
    shape_pt_sequence: 570,
    shape_dist_traveled: 39.1776,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056186,
    shape_pt_lon: -89.922201,
    shape_pt_sequence: 571,
    shape_dist_traveled: 39.2083,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.05643,
    shape_pt_lon: -89.922269,
    shape_pt_sequence: 572,
    shape_dist_traveled: 39.236,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056665,
    shape_pt_lon: -89.922303,
    shape_pt_sequence: 573,
    shape_dist_traveled: 39.2623,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.056719,
    shape_pt_lon: -89.922308,
    shape_pt_sequence: 574,
    shape_dist_traveled: 39.2683,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.057182,
    shape_pt_lon: -89.922297,
    shape_pt_sequence: 575,
    shape_dist_traveled: 39.3203,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.057548,
    shape_pt_lon: -89.922287,
    shape_pt_sequence: 576,
    shape_dist_traveled: 39.3613,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.057751,
    shape_pt_lon: -89.922281,
    shape_pt_sequence: 577,
    shape_dist_traveled: 39.3843,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.059375,
    shape_pt_lon: -89.922231,
    shape_pt_sequence: 578,
    shape_dist_traveled: 39.5663,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06138,
    shape_pt_lon: -89.922139,
    shape_pt_sequence: 579,
    shape_dist_traveled: 39.7905,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.0619,
    shape_pt_lon: -89.922108,
    shape_pt_sequence: 580,
    shape_dist_traveled: 39.8486,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062041,
    shape_pt_lon: -89.922078,
    shape_pt_sequence: 581,
    shape_dist_traveled: 39.8648,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062191,
    shape_pt_lon: -89.922012,
    shape_pt_sequence: 582,
    shape_dist_traveled: 39.8829,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062227,
    shape_pt_lon: -89.921996,
    shape_pt_sequence: 583,
    shape_dist_traveled: 39.887,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062499,
    shape_pt_lon: -89.921797,
    shape_pt_sequence: 584,
    shape_dist_traveled: 39.922,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062787,
    shape_pt_lon: -89.921593,
    shape_pt_sequence: 585,
    shape_dist_traveled: 39.96,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062857,
    shape_pt_lon: -89.921559,
    shape_pt_sequence: 586,
    shape_dist_traveled: 39.9677,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.062988,
    shape_pt_lon: -89.921513,
    shape_pt_sequence: 587,
    shape_dist_traveled: 39.9832,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.06316,
    shape_pt_lon: -89.921485,
    shape_pt_sequence: 588,
    shape_dist_traveled: 40.0023,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.063943,
    shape_pt_lon: -89.921462,
    shape_pt_sequence: 589,
    shape_dist_traveled: 40.0903,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.064689,
    shape_pt_lon: -89.921436,
    shape_pt_sequence: 590,
    shape_dist_traveled: 40.1733,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.065611,
    shape_pt_lon: -89.921399,
    shape_pt_sequence: 591,
    shape_dist_traveled: 40.2774,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066089,
    shape_pt_lon: -89.921381,
    shape_pt_sequence: 592,
    shape_dist_traveled: 40.3304,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.066656,
    shape_pt_lon: -89.921353,
    shape_pt_sequence: 593,
    shape_dist_traveled: 40.3935,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.067493,
    shape_pt_lon: -89.921316,
    shape_pt_sequence: 594,
    shape_dist_traveled: 40.4875,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.067742,
    shape_pt_lon: -89.921305,
    shape_pt_sequence: 595,
    shape_dist_traveled: 40.5156,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.068709,
    shape_pt_lon: -89.921261,
    shape_pt_sequence: 596,
    shape_dist_traveled: 40.6236,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069574,
    shape_pt_lon: -89.921222,
    shape_pt_sequence: 597,
    shape_dist_traveled: 40.7207,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069561,
    shape_pt_lon: -89.921469,
    shape_pt_sequence: 598,
    shape_dist_traveled: 40.7428,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069525,
    shape_pt_lon: -89.921729,
    shape_pt_sequence: 599,
    shape_dist_traveled: 40.7671,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069462,
    shape_pt_lon: -89.921974,
    shape_pt_sequence: 600,
    shape_dist_traveled: 40.7902,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069383,
    shape_pt_lon: -89.922194,
    shape_pt_sequence: 601,
    shape_dist_traveled: 40.8121,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069241,
    shape_pt_lon: -89.922499,
    shape_pt_sequence: 602,
    shape_dist_traveled: 40.843,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069169,
    shape_pt_lon: -89.922689,
    shape_pt_sequence: 603,
    shape_dist_traveled: 40.8631,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069084,
    shape_pt_lon: -89.922981,
    shape_pt_sequence: 604,
    shape_dist_traveled: 40.8906,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069049,
    shape_pt_lon: -89.92316,
    shape_pt_sequence: 605,
    shape_dist_traveled: 40.9071,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069023,
    shape_pt_lon: -89.923439,
    shape_pt_sequence: 606,
    shape_dist_traveled: 40.9323,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069,
    shape_pt_lon: -89.925336,
    shape_pt_sequence: 607,
    shape_dist_traveled: 41.1043,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.068983,
    shape_pt_lon: -89.926756,
    shape_pt_sequence: 608,
    shape_dist_traveled: 41.2324,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069024,
    shape_pt_lon: -89.92823,
    shape_pt_sequence: 609,
    shape_dist_traveled: 41.3664,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069026,
    shape_pt_lon: -89.928346,
    shape_pt_sequence: 610,
    shape_dist_traveled: 41.3765,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069058,
    shape_pt_lon: -89.929252,
    shape_pt_sequence: 611,
    shape_dist_traveled: 41.4585,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069059,
    shape_pt_lon: -89.929456,
    shape_pt_sequence: 612,
    shape_dist_traveled: 41.4775,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069096,
    shape_pt_lon: -89.930447,
    shape_pt_sequence: 613,
    shape_dist_traveled: 41.5666,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069147,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 614,
    shape_dist_traveled: 41.7137,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.069148,
    shape_pt_lon: -89.932138,
    shape_pt_sequence: 615,
    shape_dist_traveled: 41.7197,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.070029,
    shape_pt_lon: -89.932083,
    shape_pt_sequence: 616,
    shape_dist_traveled: 41.8179,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.070248,
    shape_pt_lon: -89.932072,
    shape_pt_sequence: 617,
    shape_dist_traveled: 41.8429,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.071041,
    shape_pt_lon: -89.93203,
    shape_pt_sequence: 618,
    shape_dist_traveled: 41.9319,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.071745,
    shape_pt_lon: -89.931968,
    shape_pt_sequence: 619,
    shape_dist_traveled: 42.0102,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931882,
    shape_pt_sequence: 620,
    shape_dist_traveled: 42.1184,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.072708,
    shape_pt_lon: -89.931881,
    shape_pt_sequence: 621,
    shape_dist_traveled: 42.1184,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.073329,
    shape_pt_lon: -89.931821,
    shape_pt_sequence: 622,
    shape_dist_traveled: 42.1887,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.07458,
    shape_pt_lon: -89.931702,
    shape_pt_sequence: 623,
    shape_dist_traveled: 42.329,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.07641,
    shape_pt_lon: -89.931522,
    shape_pt_sequence: 624,
    shape_dist_traveled: 42.5336,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076416,
    shape_pt_lon: -89.931598,
    shape_pt_sequence: 625,
    shape_dist_traveled: 42.5407,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076429,
    shape_pt_lon: -89.931768,
    shape_pt_sequence: 626,
    shape_dist_traveled: 42.5558,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076458,
    shape_pt_lon: -89.932408,
    shape_pt_sequence: 627,
    shape_dist_traveled: 42.6139,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076501,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 628,
    shape_dist_traveled: 42.689,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.07642,
    shape_pt_lon: -89.933245,
    shape_pt_sequence: 629,
    shape_dist_traveled: 42.698,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076376,
    shape_pt_lon: -89.933212,
    shape_pt_sequence: 630,
    shape_dist_traveled: 42.7039,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076349,
    shape_pt_lon: -89.933123,
    shape_pt_sequence: 631,
    shape_dist_traveled: 42.7124,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076304,
    shape_pt_lon: -89.932991,
    shape_pt_sequence: 632,
    shape_dist_traveled: 42.7254,
  },
  {
    shape_id: 38254,
    shape_pt_lat: 35.076276,
    shape_pt_lon: -89.932316,
    shape_pt_sequence: 633,
    shape_dist_traveled: 42.7865,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.156322,
    shape_pt_lon: -90.046957,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1863,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.155831,
    shape_pt_lon: -90.047149,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2439,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.247,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.155728,
    shape_pt_lon: -90.047191,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.2556,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.154803,
    shape_pt_lon: -90.047564,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3647,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.153916,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4687,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.153813,
    shape_pt_lon: -90.04799,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4818,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.15377,
    shape_pt_lon: -90.04802,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4872,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.15331,
    shape_pt_lon: -90.04821,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5422,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.153123,
    shape_pt_lon: -90.048263,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5636,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.153065,
    shape_pt_lon: -90.048276,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.5699,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.152991,
    shape_pt_lon: -90.048293,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.578,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.152923,
    shape_pt_lon: -90.048315,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.5862,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.152818,
    shape_pt_lon: -90.048355,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.5988,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.151832,
    shape_pt_lon: -90.048754,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7146,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.151632,
    shape_pt_lon: -90.048832,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7377,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.15137,
    shape_pt_lon: -90.048929,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.769,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.150867,
    shape_pt_lon: -90.049132,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8278,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.150759,
    shape_pt_lon: -90.049188,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8408,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.149833,
    shape_pt_lon: -90.04956,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9499,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.149329,
    shape_pt_lon: -90.04976,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.0091,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.148832,
    shape_pt_lon: -90.049952,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.0676,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.147847,
    shape_pt_lon: -90.05034,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.183,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.147387,
    shape_pt_lon: -90.050527,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.2377,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.146845,
    shape_pt_lon: -90.050742,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.3007,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.146292,
    shape_pt_lon: -90.050976,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.3661,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.145854,
    shape_pt_lon: -90.05115,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.4177,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.145376,
    shape_pt_lon: -90.051338,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.4743,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.14487,
    shape_pt_lon: -90.05154,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.5331,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.144651,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.5594,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.144363,
    shape_pt_lon: -90.051741,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.5929,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.143861,
    shape_pt_lon: -90.051943,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.652,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.143336,
    shape_pt_lon: -90.052157,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.714,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.142851,
    shape_pt_lon: -90.05235,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.7706,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.142621,
    shape_pt_lon: -90.05244,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.7981,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.142227,
    shape_pt_lon: -90.052614,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.8446,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.141702,
    shape_pt_lon: -90.052826,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.9066,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.14137,
    shape_pt_lon: -90.052961,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.9449,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.140771,
    shape_pt_lon: -90.05318,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.0145,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.139973,
    shape_pt_lon: -90.053503,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.1084,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.139545,
    shape_pt_lon: -90.053672,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.1587,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13907,
    shape_pt_lon: -90.053861,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.2144,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.138867,
    shape_pt_lon: -90.05397,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.2395,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.138208,
    shape_pt_lon: -90.054435,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.3246,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.137388,
    shape_pt_lon: -90.055031,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.4304,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.136522,
    shape_pt_lon: -90.055654,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5424,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.6809,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.135667,
    shape_pt_lon: -90.0539,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7281,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.135632,
    shape_pt_lon: -90.053788,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.7389,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.135536,
    shape_pt_lon: -90.052817,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.8276,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.135413,
    shape_pt_lon: -90.051589,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9393,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.135266,
    shape_pt_lon: -90.050031,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0804,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134923,
    shape_pt_lon: -90.046476,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.4046,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134916,
    shape_pt_lon: -90.046389,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.4117,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134911,
    shape_pt_lon: -90.046351,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.4157,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134779,
    shape_pt_lon: -90.044939,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.5435,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13473,
    shape_pt_lon: -90.044399,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.5929,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134648,
    shape_pt_lon: -90.043655,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.6605,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134541,
    shape_pt_lon: -90.042447,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.7712,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13445,
    shape_pt_lon: -90.041519,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.8548,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134419,
    shape_pt_lon: -90.041217,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.8829,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134308,
    shape_pt_lon: -90.040018,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.9917,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.134108,
    shape_pt_lon: -90.03791,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.184,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133991,
    shape_pt_lon: -90.03661,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.3017,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133941,
    shape_pt_lon: -90.036041,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.353,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133879,
    shape_pt_lon: -90.03529,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.4214,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133854,
    shape_pt_lon: -90.035001,
    shape_pt_sequence: 71,
    shape_dist_traveled: 4.4475,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133701,
    shape_pt_lon: -90.033345,
    shape_pt_sequence: 72,
    shape_dist_traveled: 4.5984,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133647,
    shape_pt_lon: -90.032713,
    shape_pt_sequence: 73,
    shape_dist_traveled: 4.6559,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133597,
    shape_pt_lon: -90.032144,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.7081,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133591,
    shape_pt_lon: -90.032078,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.7142,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133515,
    shape_pt_lon: -90.031269,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.7876,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.133481,
    shape_pt_lon: -90.031028,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.809,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.132985,
    shape_pt_lon: -90.031042,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.865,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.132953,
    shape_pt_lon: -90.031045,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.868,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.132401,
    shape_pt_lon: -90.031066,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.93,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131833,
    shape_pt_lon: -90.03107,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.993,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131798,
    shape_pt_lon: -90.029742,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.1131,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131782,
    shape_pt_lon: -90.029179,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.1641,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131762,
    shape_pt_lon: -90.028411,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.2332,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131761,
    shape_pt_lon: -90.028378,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.2362,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.027252,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.3382,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131741,
    shape_pt_lon: -90.02703,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.3582,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13174,
    shape_pt_lon: -90.026917,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.3682,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131729,
    shape_pt_lon: -90.026568,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.4002,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131693,
    shape_pt_lon: -90.026349,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.4197,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.4475,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.4836,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.5036,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.595,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.6156,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.6686,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.6731,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.6937,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.7004,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.7032,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.721,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.7482,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 103,
    shape_dist_traveled: 5.7818,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 104,
    shape_dist_traveled: 5.8584,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 105,
    shape_dist_traveled: 5.9225,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 106,
    shape_dist_traveled: 5.9744,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 107,
    shape_dist_traveled: 5.9902,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.0038,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.039,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.1622,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.292,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.3902,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126499,
    shape_pt_lon: -90.017122,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.4374,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126406,
    shape_pt_lon: -90.016962,
    shape_pt_sequence: 114,
    shape_dist_traveled: 6.4554,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126493,
    shape_pt_lon: -90.016873,
    shape_pt_sequence: 115,
    shape_dist_traveled: 6.4682,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126542,
    shape_pt_lon: -90.016788,
    shape_pt_sequence: 116,
    shape_dist_traveled: 6.4777,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126582,
    shape_pt_lon: -90.016628,
    shape_pt_sequence: 117,
    shape_dist_traveled: 6.4925,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126592,
    shape_pt_lon: -90.016381,
    shape_pt_sequence: 118,
    shape_dist_traveled: 6.5145,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126607,
    shape_pt_lon: -90.016338,
    shape_pt_sequence: 119,
    shape_dist_traveled: 6.5187,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126577,
    shape_pt_lon: -90.0149,
    shape_pt_sequence: 120,
    shape_dist_traveled: 6.6487,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126569,
    shape_pt_lon: -90.014751,
    shape_pt_sequence: 121,
    shape_dist_traveled: 6.6627,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126533,
    shape_pt_lon: -90.014683,
    shape_pt_sequence: 122,
    shape_dist_traveled: 6.67,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126507,
    shape_pt_lon: -90.013643,
    shape_pt_sequence: 123,
    shape_dist_traveled: 6.764,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126469,
    shape_pt_lon: -90.012241,
    shape_pt_sequence: 124,
    shape_dist_traveled: 6.8911,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126458,
    shape_pt_lon: -90.011841,
    shape_pt_sequence: 125,
    shape_dist_traveled: 6.9271,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126453,
    shape_pt_lon: -90.011679,
    shape_pt_sequence: 126,
    shape_dist_traveled: 6.9411,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12644,
    shape_pt_lon: -90.011242,
    shape_pt_sequence: 127,
    shape_dist_traveled: 6.9811,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126415,
    shape_pt_lon: -90.010289,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.0672,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126381,
    shape_pt_lon: -90.009101,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.1742,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126364,
    shape_pt_lon: -90.008368,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.2412,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126356,
    shape_pt_lon: -90.007965,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.2773,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126328,
    shape_pt_lon: -90.00691,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.3723,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12631,
    shape_pt_lon: -90.006288,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.4293,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126286,
    shape_pt_lon: -90.005318,
    shape_pt_sequence: 134,
    shape_dist_traveled: 7.5164,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126279,
    shape_pt_lon: -90.005193,
    shape_pt_sequence: 135,
    shape_dist_traveled: 7.5284,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126243,
    shape_pt_lon: -90.003748,
    shape_pt_sequence: 136,
    shape_dist_traveled: 7.6585,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126219,
    shape_pt_lon: -90.003035,
    shape_pt_sequence: 137,
    shape_dist_traveled: 7.7235,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126202,
    shape_pt_lon: -90.002131,
    shape_pt_sequence: 138,
    shape_dist_traveled: 7.8046,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126242,
    shape_pt_lon: -90.002046,
    shape_pt_sequence: 139,
    shape_dist_traveled: 7.8135,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126228,
    shape_pt_lon: -90.001391,
    shape_pt_sequence: 140,
    shape_dist_traveled: 7.8725,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12617,
    shape_pt_lon: -89.998723,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.1136,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126167,
    shape_pt_lon: -89.998304,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.1516,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126148,
    shape_pt_lon: -89.997139,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.2566,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126128,
    shape_pt_lon: -89.996034,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.3566,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126121,
    shape_pt_lon: -89.995693,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.3877,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126094,
    shape_pt_lon: -89.994227,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.5207,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126077,
    shape_pt_lon: -89.993203,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.6127,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126061,
    shape_pt_lon: -89.992382,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.6867,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126034,
    shape_pt_lon: -89.990948,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.8168,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.8368,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126012,
    shape_pt_lon: -89.990186,
    shape_pt_sequence: 151,
    shape_dist_traveled: 8.8858,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126,
    shape_pt_lon: -89.989586,
    shape_pt_sequence: 152,
    shape_dist_traveled: 8.9398,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12599,
    shape_pt_lon: -89.988843,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.0068,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125982,
    shape_pt_lon: -89.988246,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.0608,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125978,
    shape_pt_lon: -89.987748,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.1058,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125971,
    shape_pt_lon: -89.987115,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.1629,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125961,
    shape_pt_lon: -89.986039,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.2609,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125957,
    shape_pt_lon: -89.985508,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.3089,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12593,
    shape_pt_lon: -89.984262,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.4209,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125928,
    shape_pt_lon: -89.98419,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.4279,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125921,
    shape_pt_lon: -89.984052,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.4399,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125898,
    shape_pt_lon: -89.983502,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.49,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125865,
    shape_pt_lon: -89.982469,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.5831,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125819,
    shape_pt_lon: -89.980482,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.7631,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125786,
    shape_pt_lon: -89.979109,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.8872,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125769,
    shape_pt_lon: -89.978458,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.9462,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125749,
    shape_pt_lon: -89.977673,
    shape_pt_sequence: 167,
    shape_dist_traveled: 10.0173,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12574,
    shape_pt_lon: -89.977202,
    shape_pt_sequence: 168,
    shape_dist_traveled: 10.0593,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125701,
    shape_pt_lon: -89.975875,
    shape_pt_sequence: 169,
    shape_dist_traveled: 10.1793,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125684,
    shape_pt_lon: -89.975276,
    shape_pt_sequence: 170,
    shape_dist_traveled: 10.2334,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125678,
    shape_pt_lon: -89.974509,
    shape_pt_sequence: 171,
    shape_dist_traveled: 10.3034,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125659,
    shape_pt_lon: -89.974211,
    shape_pt_sequence: 172,
    shape_dist_traveled: 10.3295,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125617,
    shape_pt_lon: -89.973969,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.3518,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125507,
    shape_pt_lon: -89.973629,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.3854,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.1254,
    shape_pt_lon: -89.973423,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.4079,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125307,
    shape_pt_lon: -89.973281,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.4243,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125091,
    shape_pt_lon: -89.973022,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.4576,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.124916,
    shape_pt_lon: -89.972821,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.4845,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.124465,
    shape_pt_lon: -89.972277,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.5545,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.124157,
    shape_pt_lon: -89.971891,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.604,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.124033,
    shape_pt_lon: -89.971708,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.6254,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123977,
    shape_pt_lon: -89.971605,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.6368,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123939,
    shape_pt_lon: -89.971513,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.6457,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123889,
    shape_pt_lon: -89.971376,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.6596,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123846,
    shape_pt_lon: -89.971158,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.6803,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123822,
    shape_pt_lon: -89.970851,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.7074,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123802,
    shape_pt_lon: -89.970386,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.7495,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123779,
    shape_pt_lon: -89.969375,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.8415,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123757,
    shape_pt_lon: -89.968374,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.9315,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123729,
    shape_pt_lon: -89.967158,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.0416,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12372,
    shape_pt_lon: -89.966865,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.0686,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123714,
    shape_pt_lon: -89.966491,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.1016,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123712,
    shape_pt_lon: -89.966356,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.1146,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123665,
    shape_pt_lon: -89.964053,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.3227,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123627,
    shape_pt_lon: -89.9623,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.4807,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123588,
    shape_pt_lon: -89.961003,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.5978,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123561,
    shape_pt_lon: -89.96011,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.6789,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123521,
    shape_pt_lon: -89.958803,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.7969,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123499,
    shape_pt_lon: -89.958224,
    shape_pt_sequence: 199,
    shape_dist_traveled: 11.85,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123436,
    shape_pt_lon: -89.954418,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.1931,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123434,
    shape_pt_lon: -89.954311,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.2031,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123409,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.3041,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123376,
    shape_pt_lon: -89.952075,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.4052,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12336,
    shape_pt_lon: -89.951458,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.4612,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123347,
    shape_pt_lon: -89.950979,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.5043,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123317,
    shape_pt_lon: -89.949976,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.5953,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123313,
    shape_pt_lon: -89.949873,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.6044,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123279,
    shape_pt_lon: -89.948487,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.7304,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123238,
    shape_pt_lon: -89.94701,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.8635,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123227,
    shape_pt_lon: -89.946571,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.9035,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.1232,
    shape_pt_lon: -89.945526,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.9976,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.0086,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.0796,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.1466,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.1856,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.2256,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.2647,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.3087,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.3667,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.5228,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.5789,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.6359,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 223,
    shape_dist_traveled: 13.6889,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 224,
    shape_dist_traveled: 13.8199,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.946,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.987,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131935,
    shape_pt_lon: -89.944649,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.025,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131918,
    shape_pt_lon: -89.943996,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.084,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131908,
    shape_pt_lon: -89.943433,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.135,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131899,
    shape_pt_lon: -89.9431,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.1651,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131889,
    shape_pt_lon: -89.942685,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.2031,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131862,
    shape_pt_lon: -89.941426,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.3161,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131847,
    shape_pt_lon: -89.940625,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.3891,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131819,
    shape_pt_lon: -89.93945,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.4952,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131755,
    shape_pt_lon: -89.936781,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.7363,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131738,
    shape_pt_lon: -89.935998,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.8073,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.935521,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.8503,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131686,
    shape_pt_lon: -89.933782,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.0074,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131669,
    shape_pt_lon: -89.932956,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.0824,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.1316,
    shape_pt_lon: -89.929973,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.3515,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13158,
    shape_pt_lon: -89.929001,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.4395,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.928619,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.4745,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131561,
    shape_pt_lon: -89.928168,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.5146,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131552,
    shape_pt_lon: -89.927839,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.5446,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131534,
    shape_pt_lon: -89.927011,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.6196,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131511,
    shape_pt_lon: -89.926138,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.6986,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131505,
    shape_pt_lon: -89.925852,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.7246,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131497,
    shape_pt_lon: -89.925537,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.7527,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131474,
    shape_pt_lon: -89.924576,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.8397,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131473,
    shape_pt_lon: -89.924461,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.8497,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131432,
    shape_pt_lon: -89.922526,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.0248,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131426,
    shape_pt_lon: -89.922239,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.0508,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.1314,
    shape_pt_lon: -89.921123,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.1518,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131396,
    shape_pt_lon: -89.920919,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.1708,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131379,
    shape_pt_lon: -89.920088,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.2458,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131374,
    shape_pt_lon: -89.919979,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.2549,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131358,
    shape_pt_lon: -89.91924,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.3219,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131345,
    shape_pt_lon: -89.918669,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.3739,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131337,
    shape_pt_lon: -89.91842,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.3959,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131315,
    shape_pt_lon: -89.917374,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.491,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131312,
    shape_pt_lon: -89.917311,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.497,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131311,
    shape_pt_lon: -89.91725,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.502,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13129,
    shape_pt_lon: -89.916459,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.574,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131267,
    shape_pt_lon: -89.915488,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.6611,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131243,
    shape_pt_lon: -89.914567,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.7451,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131239,
    shape_pt_lon: -89.914353,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.7641,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131223,
    shape_pt_lon: -89.913616,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.8301,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131222,
    shape_pt_lon: -89.913562,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.8351,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131207,
    shape_pt_lon: -89.912933,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.8921,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131197,
    shape_pt_lon: -89.912507,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.9312,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13119,
    shape_pt_lon: -89.912241,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.9552,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131187,
    shape_pt_lon: -89.912047,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.9722,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.911533,
    shape_pt_sequence: 273,
    shape_dist_traveled: 17.0192,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131157,
    shape_pt_lon: -89.910682,
    shape_pt_sequence: 274,
    shape_dist_traveled: 17.0963,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131122,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 275,
    shape_dist_traveled: 17.2303,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131107,
    shape_pt_lon: -89.908492,
    shape_pt_sequence: 276,
    shape_dist_traveled: 17.2943,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131096,
    shape_pt_lon: -89.907833,
    shape_pt_sequence: 277,
    shape_dist_traveled: 17.3533,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131088,
    shape_pt_lon: -89.90741,
    shape_pt_sequence: 278,
    shape_dist_traveled: 17.3913,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131068,
    shape_pt_lon: -89.906423,
    shape_pt_sequence: 279,
    shape_dist_traveled: 17.4814,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13105,
    shape_pt_lon: -89.905627,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.5524,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131035,
    shape_pt_lon: -89.904876,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.6204,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131024,
    shape_pt_lon: -89.904353,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.6684,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130986,
    shape_pt_lon: -89.902663,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.8205,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13093,
    shape_pt_lon: -89.900298,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.0346,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130898,
    shape_pt_lon: -89.899139,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.1396,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130858,
    shape_pt_lon: -89.898767,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.1729,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130811,
    shape_pt_lon: -89.898454,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.2025,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130691,
    shape_pt_lon: -89.897761,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.2658,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130674,
    shape_pt_lon: -89.897626,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.279,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130652,
    shape_pt_lon: -89.897438,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.2961,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130612,
    shape_pt_lon: -89.895473,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.4731,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130591,
    shape_pt_lon: -89.894423,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.5682,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130561,
    shape_pt_lon: -89.892939,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.7022,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130556,
    shape_pt_lon: -89.892519,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.7402,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13054,
    shape_pt_lon: -89.891703,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.8142,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130535,
    shape_pt_lon: -89.89138,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.8432,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13052,
    shape_pt_lon: -89.890809,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.8953,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13051,
    shape_pt_lon: -89.890293,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.9413,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130479,
    shape_pt_lon: -89.88828,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.1233,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13046,
    shape_pt_lon: -89.887361,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.2063,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130456,
    shape_pt_lon: -89.886837,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.2543,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130487,
    shape_pt_lon: -89.886595,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.2756,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130566,
    shape_pt_lon: -89.886312,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.3031,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130673,
    shape_pt_lon: -89.886076,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.3273,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130808,
    shape_pt_lon: -89.885881,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.3507,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130917,
    shape_pt_lon: -89.885746,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.3677,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131021,
    shape_pt_lon: -89.885576,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.3869,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131107,
    shape_pt_lon: -89.885355,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.4092,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.88504,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.4393,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131195,
    shape_pt_lon: -89.88482,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.4594,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131198,
    shape_pt_lon: -89.884667,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.4724,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131113,
    shape_pt_lon: -89.882518,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.6676,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131158,
    shape_pt_lon: -89.879293,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.9587,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131161,
    shape_pt_lon: -89.87815,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.0617,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131137,
    shape_pt_lon: -89.876469,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.2137,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131123,
    shape_pt_lon: -89.875102,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.3377,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13108,
    shape_pt_lon: -89.873167,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.5128,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131055,
    shape_pt_lon: -89.871916,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.6258,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13104,
    shape_pt_lon: -89.871449,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.6678,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130969,
    shape_pt_lon: -89.868479,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.9359,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130963,
    shape_pt_lon: -89.868238,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.9579,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130958,
    shape_pt_lon: -89.86794,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.9849,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130953,
    shape_pt_lon: -89.867773,
    shape_pt_sequence: 323,
    shape_dist_traveled: 20.9999,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130951,
    shape_pt_lon: -89.867667,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.0099,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130949,
    shape_pt_lon: -89.867535,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.022,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130942,
    shape_pt_lon: -89.867267,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.046,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130931,
    shape_pt_lon: -89.866627,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.104,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130886,
    shape_pt_lon: -89.864736,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.2751,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13085,
    shape_pt_lon: -89.863018,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.4301,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130837,
    shape_pt_lon: -89.862345,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.4911,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130796,
    shape_pt_lon: -89.860547,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.6532,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130786,
    shape_pt_lon: -89.85965,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.7342,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130811,
    shape_pt_lon: -89.859232,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.7723,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -89.859118,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.7824,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130809,
    shape_pt_lon: -89.858828,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.8084,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13082,
    shape_pt_lon: -89.85857,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.8325,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13084,
    shape_pt_lon: -89.858387,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.8486,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130874,
    shape_pt_lon: -89.858135,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.872,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130949,
    shape_pt_lon: -89.857847,
    shape_pt_sequence: 339,
    shape_dist_traveled: 21.8992,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131059,
    shape_pt_lon: -89.857547,
    shape_pt_sequence: 340,
    shape_dist_traveled: 21.9292,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131248,
    shape_pt_lon: -89.857114,
    shape_pt_sequence: 341,
    shape_dist_traveled: 21.9734,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131272,
    shape_pt_lon: -89.857026,
    shape_pt_sequence: 342,
    shape_dist_traveled: 21.982,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13129,
    shape_pt_lon: -89.856858,
    shape_pt_sequence: 343,
    shape_dist_traveled: 21.9971,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.13125,
    shape_pt_lon: -89.856675,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.0148,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.131197,
    shape_pt_lon: -89.856568,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.0265,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130977,
    shape_pt_lon: -89.8563,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.0604,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130841,
    shape_pt_lon: -89.856159,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.0803,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130721,
    shape_pt_lon: -89.855998,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.1001,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130481,
    shape_pt_lon: -89.855798,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.1325,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130398,
    shape_pt_lon: -89.855731,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.1434,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.130223,
    shape_pt_lon: -89.855598,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.1664,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.129746,
    shape_pt_lon: -89.855282,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.2272,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12935,
    shape_pt_lon: -89.855039,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.2764,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.128911,
    shape_pt_lon: -89.854737,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.3328,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.12835,
    shape_pt_lon: -89.854324,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.4059,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127809,
    shape_pt_lon: -89.853876,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.4786,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127315,
    shape_pt_lon: -89.853431,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.5466,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127181,
    shape_pt_lon: -89.853295,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.5658,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.127035,
    shape_pt_lon: -89.853146,
    shape_pt_sequence: 359,
    shape_dist_traveled: 22.5878,
  },
  {
    shape_id: 38257,
    shape_pt_lat: 35.126457,
    shape_pt_lon: -89.852529,
    shape_pt_sequence: 360,
    shape_dist_traveled: 22.6729,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126457,
    shape_pt_lon: -89.852529,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125909,
    shape_pt_lon: -89.851875,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0849,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125586,
    shape_pt_lon: -89.851451,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1372,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125558,
    shape_pt_lon: -89.851413,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1429,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12553,
    shape_pt_lon: -89.851379,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1471,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124937,
    shape_pt_lon: -89.850548,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.247,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124849,
    shape_pt_lon: -89.850401,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2642,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12444,
    shape_pt_lon: -89.849868,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.33,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124008,
    shape_pt_lon: -89.849356,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.3972,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123769,
    shape_pt_lon: -89.849112,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4313,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123446,
    shape_pt_lon: -89.848807,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4769,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123318,
    shape_pt_lon: -89.848698,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.4949,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122847,
    shape_pt_lon: -89.849507,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5851,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122811,
    shape_pt_lon: -89.84957,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5923,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122694,
    shape_pt_lon: -89.849817,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.6179,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122608,
    shape_pt_lon: -89.850042,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6398,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122515,
    shape_pt_lon: -89.850383,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6727,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122471,
    shape_pt_lon: -89.850597,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6933,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122437,
    shape_pt_lon: -89.850866,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7177,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122424,
    shape_pt_lon: -89.851061,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.7347,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122426,
    shape_pt_lon: -89.85129,
    shape_pt_sequence: 21,
    shape_dist_traveled: 0.7557,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12244,
    shape_pt_lon: -89.851886,
    shape_pt_sequence: 22,
    shape_dist_traveled: 0.8097,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122448,
    shape_pt_lon: -89.852404,
    shape_pt_sequence: 23,
    shape_dist_traveled: 0.8567,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12243,
    shape_pt_lon: -89.852887,
    shape_pt_sequence: 24,
    shape_dist_traveled: 0.9008,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1224,
    shape_pt_lon: -89.853176,
    shape_pt_sequence: 25,
    shape_dist_traveled: 0.9269,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12231,
    shape_pt_lon: -89.853805,
    shape_pt_sequence: 26,
    shape_dist_traveled: 0.984,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122229,
    shape_pt_lon: -89.854122,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.0144,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122134,
    shape_pt_lon: -89.854385,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.0404,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12188,
    shape_pt_lon: -89.854918,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.0964,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12176,
    shape_pt_lon: -89.855216,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.1264,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121672,
    shape_pt_lon: -89.855516,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.1552,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121616,
    shape_pt_lon: -89.855818,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.1831,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121598,
    shape_pt_lon: -89.855999,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.2002,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121589,
    shape_pt_lon: -89.856233,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.2212,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121596,
    shape_pt_lon: -89.856418,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.2373,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121601,
    shape_pt_lon: -89.85652,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.2473,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121657,
    shape_pt_lon: -89.856897,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.2818,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12171,
    shape_pt_lon: -89.857242,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.3134,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121737,
    shape_pt_lon: -89.857623,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.3476,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121761,
    shape_pt_lon: -89.857866,
    shape_pt_sequence: 40,
    shape_dist_traveled: 1.3696,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121837,
    shape_pt_lon: -89.858136,
    shape_pt_sequence: 41,
    shape_dist_traveled: 1.3962,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.121878,
    shape_pt_lon: -89.858337,
    shape_pt_sequence: 42,
    shape_dist_traveled: 1.4147,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122182,
    shape_pt_lon: -89.861041,
    shape_pt_sequence: 43,
    shape_dist_traveled: 1.661,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122244,
    shape_pt_lon: -89.861534,
    shape_pt_sequence: 44,
    shape_dist_traveled: 1.7064,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122316,
    shape_pt_lon: -89.861929,
    shape_pt_sequence: 45,
    shape_dist_traveled: 1.7433,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122389,
    shape_pt_lon: -89.862249,
    shape_pt_sequence: 46,
    shape_dist_traveled: 1.7737,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122441,
    shape_pt_lon: -89.862422,
    shape_pt_sequence: 47,
    shape_dist_traveled: 1.7895,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122468,
    shape_pt_lon: -89.86249,
    shape_pt_sequence: 48,
    shape_dist_traveled: 1.7962,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122522,
    shape_pt_lon: -89.862614,
    shape_pt_sequence: 49,
    shape_dist_traveled: 1.8096,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122698,
    shape_pt_lon: -89.862863,
    shape_pt_sequence: 50,
    shape_dist_traveled: 1.8393,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122969,
    shape_pt_lon: -89.863193,
    shape_pt_sequence: 51,
    shape_dist_traveled: 1.8817,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1231,
    shape_pt_lon: -89.863386,
    shape_pt_sequence: 52,
    shape_dist_traveled: 1.9052,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123157,
    shape_pt_lon: -89.863502,
    shape_pt_sequence: 53,
    shape_dist_traveled: 1.9168,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123231,
    shape_pt_lon: -89.863719,
    shape_pt_sequence: 54,
    shape_dist_traveled: 1.9388,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123282,
    shape_pt_lon: -89.86397,
    shape_pt_sequence: 55,
    shape_dist_traveled: 1.9613,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1233,
    shape_pt_lon: -89.864272,
    shape_pt_sequence: 56,
    shape_dist_traveled: 1.9894,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123316,
    shape_pt_lon: -89.864837,
    shape_pt_sequence: 57,
    shape_dist_traveled: 2.0404,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123329,
    shape_pt_lon: -89.865705,
    shape_pt_sequence: 58,
    shape_dist_traveled: 2.1184,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123333,
    shape_pt_lon: -89.865979,
    shape_pt_sequence: 59,
    shape_dist_traveled: 2.1434,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123339,
    shape_pt_lon: -89.866373,
    shape_pt_sequence: 60,
    shape_dist_traveled: 2.1795,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123357,
    shape_pt_lon: -89.867386,
    shape_pt_sequence: 61,
    shape_dist_traveled: 2.2705,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123363,
    shape_pt_lon: -89.868213,
    shape_pt_sequence: 62,
    shape_dist_traveled: 2.3455,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123368,
    shape_pt_lon: -89.868447,
    shape_pt_sequence: 63,
    shape_dist_traveled: 2.3665,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12337,
    shape_pt_lon: -89.868549,
    shape_pt_sequence: 64,
    shape_dist_traveled: 2.3755,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123371,
    shape_pt_lon: -89.86865,
    shape_pt_sequence: 65,
    shape_dist_traveled: 2.3845,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123383,
    shape_pt_lon: -89.869092,
    shape_pt_sequence: 66,
    shape_dist_traveled: 2.4245,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123391,
    shape_pt_lon: -89.869436,
    shape_pt_sequence: 67,
    shape_dist_traveled: 2.4555,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1234,
    shape_pt_lon: -89.869938,
    shape_pt_sequence: 68,
    shape_dist_traveled: 2.5015,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123402,
    shape_pt_lon: -89.87002,
    shape_pt_sequence: 69,
    shape_dist_traveled: 2.5085,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123416,
    shape_pt_lon: -89.870652,
    shape_pt_sequence: 70,
    shape_dist_traveled: 2.5656,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123419,
    shape_pt_lon: -89.870858,
    shape_pt_sequence: 71,
    shape_dist_traveled: 2.5846,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123413,
    shape_pt_lon: -89.870987,
    shape_pt_sequence: 72,
    shape_dist_traveled: 2.5966,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1234,
    shape_pt_lon: -89.871073,
    shape_pt_sequence: 73,
    shape_dist_traveled: 2.6037,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123253,
    shape_pt_lon: -89.871541,
    shape_pt_sequence: 74,
    shape_dist_traveled: 2.6499,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123073,
    shape_pt_lon: -89.872068,
    shape_pt_sequence: 75,
    shape_dist_traveled: 2.701,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123046,
    shape_pt_lon: -89.872166,
    shape_pt_sequence: 76,
    shape_dist_traveled: 2.7105,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123007,
    shape_pt_lon: -89.87235,
    shape_pt_sequence: 77,
    shape_dist_traveled: 2.728,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.122973,
    shape_pt_lon: -89.872649,
    shape_pt_sequence: 78,
    shape_dist_traveled: 2.7553,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123028,
    shape_pt_lon: -89.873937,
    shape_pt_sequence: 79,
    shape_dist_traveled: 2.8714,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123035,
    shape_pt_lon: -89.874087,
    shape_pt_sequence: 80,
    shape_dist_traveled: 2.8854,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123041,
    shape_pt_lon: -89.874228,
    shape_pt_sequence: 81,
    shape_dist_traveled: 2.8974,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123052,
    shape_pt_lon: -89.87452,
    shape_pt_sequence: 82,
    shape_dist_traveled: 2.9245,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123052,
    shape_pt_lon: -89.87509,
    shape_pt_sequence: 83,
    shape_dist_traveled: 2.9755,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123052,
    shape_pt_lon: -89.875352,
    shape_pt_sequence: 84,
    shape_dist_traveled: 2.9995,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123049,
    shape_pt_lon: -89.876441,
    shape_pt_sequence: 85,
    shape_dist_traveled: 3.0975,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123202,
    shape_pt_lon: -89.876386,
    shape_pt_sequence: 86,
    shape_dist_traveled: 3.1152,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123368,
    shape_pt_lon: -89.876287,
    shape_pt_sequence: 87,
    shape_dist_traveled: 3.1353,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123651,
    shape_pt_lon: -89.876082,
    shape_pt_sequence: 88,
    shape_dist_traveled: 3.172,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123732,
    shape_pt_lon: -89.876025,
    shape_pt_sequence: 89,
    shape_dist_traveled: 3.1823,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123921,
    shape_pt_lon: -89.875927,
    shape_pt_sequence: 90,
    shape_dist_traveled: 3.2052,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124109,
    shape_pt_lon: -89.87586,
    shape_pt_sequence: 91,
    shape_dist_traveled: 3.227,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124442,
    shape_pt_lon: -89.875771,
    shape_pt_sequence: 92,
    shape_dist_traveled: 3.2658,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125301,
    shape_pt_lon: -89.875542,
    shape_pt_sequence: 93,
    shape_dist_traveled: 3.3639,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.127557,
    shape_pt_lon: -89.874942,
    shape_pt_sequence: 94,
    shape_dist_traveled: 3.6216,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128301,
    shape_pt_lon: -89.874741,
    shape_pt_sequence: 95,
    shape_dist_traveled: 3.7075,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128467,
    shape_pt_lon: -89.874708,
    shape_pt_sequence: 96,
    shape_dist_traveled: 3.7258,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128677,
    shape_pt_lon: -89.874689,
    shape_pt_sequence: 97,
    shape_dist_traveled: 3.7499,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12876,
    shape_pt_lon: -89.874686,
    shape_pt_sequence: 98,
    shape_dist_traveled: 3.7589,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128889,
    shape_pt_lon: -89.874699,
    shape_pt_sequence: 99,
    shape_dist_traveled: 3.7739,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.129728,
    shape_pt_lon: -89.874882,
    shape_pt_sequence: 100,
    shape_dist_traveled: 3.8684,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130634,
    shape_pt_lon: -89.875093,
    shape_pt_sequence: 101,
    shape_dist_traveled: 3.9722,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130792,
    shape_pt_lon: -89.875111,
    shape_pt_sequence: 102,
    shape_dist_traveled: 3.9893,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131123,
    shape_pt_lon: -89.875102,
    shape_pt_sequence: 103,
    shape_dist_traveled: 4.0263,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131137,
    shape_pt_lon: -89.876469,
    shape_pt_sequence: 104,
    shape_dist_traveled: 4.1503,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131161,
    shape_pt_lon: -89.87815,
    shape_pt_sequence: 105,
    shape_dist_traveled: 4.3024,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131158,
    shape_pt_lon: -89.879293,
    shape_pt_sequence: 106,
    shape_dist_traveled: 4.4054,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131113,
    shape_pt_lon: -89.882518,
    shape_pt_sequence: 107,
    shape_dist_traveled: 4.6964,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131198,
    shape_pt_lon: -89.884667,
    shape_pt_sequence: 108,
    shape_dist_traveled: 4.8916,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131195,
    shape_pt_lon: -89.88482,
    shape_pt_sequence: 109,
    shape_dist_traveled: 4.9046,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.88504,
    shape_pt_sequence: 110,
    shape_dist_traveled: 4.9247,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131107,
    shape_pt_lon: -89.885355,
    shape_pt_sequence: 111,
    shape_dist_traveled: 4.9548,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131021,
    shape_pt_lon: -89.885576,
    shape_pt_sequence: 112,
    shape_dist_traveled: 4.9772,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130917,
    shape_pt_lon: -89.885746,
    shape_pt_sequence: 113,
    shape_dist_traveled: 4.9964,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130808,
    shape_pt_lon: -89.885881,
    shape_pt_sequence: 114,
    shape_dist_traveled: 5.0133,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130673,
    shape_pt_lon: -89.886076,
    shape_pt_sequence: 115,
    shape_dist_traveled: 5.0368,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130566,
    shape_pt_lon: -89.886312,
    shape_pt_sequence: 116,
    shape_dist_traveled: 5.061,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130487,
    shape_pt_lon: -89.886595,
    shape_pt_sequence: 117,
    shape_dist_traveled: 5.0885,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130456,
    shape_pt_lon: -89.886837,
    shape_pt_sequence: 118,
    shape_dist_traveled: 5.1097,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13046,
    shape_pt_lon: -89.887361,
    shape_pt_sequence: 119,
    shape_dist_traveled: 5.1577,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130479,
    shape_pt_lon: -89.88828,
    shape_pt_sequence: 120,
    shape_dist_traveled: 5.2407,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13051,
    shape_pt_lon: -89.890293,
    shape_pt_sequence: 121,
    shape_dist_traveled: 5.4227,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13052,
    shape_pt_lon: -89.890809,
    shape_pt_sequence: 122,
    shape_dist_traveled: 5.4687,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130535,
    shape_pt_lon: -89.89138,
    shape_pt_sequence: 123,
    shape_dist_traveled: 5.5208,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13054,
    shape_pt_lon: -89.891703,
    shape_pt_sequence: 124,
    shape_dist_traveled: 5.5498,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130556,
    shape_pt_lon: -89.892519,
    shape_pt_sequence: 125,
    shape_dist_traveled: 5.6238,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130561,
    shape_pt_lon: -89.892939,
    shape_pt_sequence: 126,
    shape_dist_traveled: 5.6618,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130591,
    shape_pt_lon: -89.894423,
    shape_pt_sequence: 127,
    shape_dist_traveled: 5.7958,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130612,
    shape_pt_lon: -89.895473,
    shape_pt_sequence: 128,
    shape_dist_traveled: 5.8909,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130652,
    shape_pt_lon: -89.897438,
    shape_pt_sequence: 129,
    shape_dist_traveled: 6.0679,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130674,
    shape_pt_lon: -89.897626,
    shape_pt_sequence: 130,
    shape_dist_traveled: 6.0851,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130691,
    shape_pt_lon: -89.897761,
    shape_pt_sequence: 131,
    shape_dist_traveled: 6.0982,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130811,
    shape_pt_lon: -89.898454,
    shape_pt_sequence: 132,
    shape_dist_traveled: 6.1616,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130858,
    shape_pt_lon: -89.898767,
    shape_pt_sequence: 133,
    shape_dist_traveled: 6.1912,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130898,
    shape_pt_lon: -89.899139,
    shape_pt_sequence: 134,
    shape_dist_traveled: 6.2244,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13093,
    shape_pt_lon: -89.900298,
    shape_pt_sequence: 135,
    shape_dist_traveled: 6.3295,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130986,
    shape_pt_lon: -89.902663,
    shape_pt_sequence: 136,
    shape_dist_traveled: 6.5435,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131024,
    shape_pt_lon: -89.904353,
    shape_pt_sequence: 137,
    shape_dist_traveled: 6.6956,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131035,
    shape_pt_lon: -89.904876,
    shape_pt_sequence: 138,
    shape_dist_traveled: 6.7436,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13105,
    shape_pt_lon: -89.905627,
    shape_pt_sequence: 139,
    shape_dist_traveled: 6.8116,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131068,
    shape_pt_lon: -89.906423,
    shape_pt_sequence: 140,
    shape_dist_traveled: 6.8827,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131088,
    shape_pt_lon: -89.90741,
    shape_pt_sequence: 141,
    shape_dist_traveled: 6.9727,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131096,
    shape_pt_lon: -89.907833,
    shape_pt_sequence: 142,
    shape_dist_traveled: 7.0107,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131107,
    shape_pt_lon: -89.908492,
    shape_pt_sequence: 143,
    shape_dist_traveled: 7.0697,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131122,
    shape_pt_lon: -89.909191,
    shape_pt_sequence: 144,
    shape_dist_traveled: 7.1337,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131157,
    shape_pt_lon: -89.910682,
    shape_pt_sequence: 145,
    shape_dist_traveled: 7.2678,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131177,
    shape_pt_lon: -89.911533,
    shape_pt_sequence: 146,
    shape_dist_traveled: 7.3448,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131187,
    shape_pt_lon: -89.912047,
    shape_pt_sequence: 147,
    shape_dist_traveled: 7.3918,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13119,
    shape_pt_lon: -89.912241,
    shape_pt_sequence: 148,
    shape_dist_traveled: 7.4088,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131197,
    shape_pt_lon: -89.912507,
    shape_pt_sequence: 149,
    shape_dist_traveled: 7.4328,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131207,
    shape_pt_lon: -89.912933,
    shape_pt_sequence: 150,
    shape_dist_traveled: 7.4719,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131222,
    shape_pt_lon: -89.913562,
    shape_pt_sequence: 151,
    shape_dist_traveled: 7.5289,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131223,
    shape_pt_lon: -89.913616,
    shape_pt_sequence: 152,
    shape_dist_traveled: 7.5339,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131239,
    shape_pt_lon: -89.914353,
    shape_pt_sequence: 153,
    shape_dist_traveled: 7.5999,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131243,
    shape_pt_lon: -89.914567,
    shape_pt_sequence: 154,
    shape_dist_traveled: 7.6189,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131267,
    shape_pt_lon: -89.915488,
    shape_pt_sequence: 155,
    shape_dist_traveled: 7.703,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13129,
    shape_pt_lon: -89.916459,
    shape_pt_sequence: 156,
    shape_dist_traveled: 7.79,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131311,
    shape_pt_lon: -89.91725,
    shape_pt_sequence: 157,
    shape_dist_traveled: 7.8621,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131312,
    shape_pt_lon: -89.917311,
    shape_pt_sequence: 158,
    shape_dist_traveled: 7.8671,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131315,
    shape_pt_lon: -89.917374,
    shape_pt_sequence: 159,
    shape_dist_traveled: 7.8731,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131337,
    shape_pt_lon: -89.91842,
    shape_pt_sequence: 160,
    shape_dist_traveled: 7.9681,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131345,
    shape_pt_lon: -89.918669,
    shape_pt_sequence: 161,
    shape_dist_traveled: 7.9901,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131358,
    shape_pt_lon: -89.91924,
    shape_pt_sequence: 162,
    shape_dist_traveled: 8.0421,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131374,
    shape_pt_lon: -89.919979,
    shape_pt_sequence: 163,
    shape_dist_traveled: 8.1091,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131379,
    shape_pt_lon: -89.920088,
    shape_pt_sequence: 164,
    shape_dist_traveled: 8.1182,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131396,
    shape_pt_lon: -89.920919,
    shape_pt_sequence: 165,
    shape_dist_traveled: 8.1932,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1314,
    shape_pt_lon: -89.921123,
    shape_pt_sequence: 166,
    shape_dist_traveled: 8.2122,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131426,
    shape_pt_lon: -89.922239,
    shape_pt_sequence: 167,
    shape_dist_traveled: 8.3133,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131432,
    shape_pt_lon: -89.922526,
    shape_pt_sequence: 168,
    shape_dist_traveled: 8.3393,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131473,
    shape_pt_lon: -89.924461,
    shape_pt_sequence: 169,
    shape_dist_traveled: 8.5144,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131474,
    shape_pt_lon: -89.924576,
    shape_pt_sequence: 170,
    shape_dist_traveled: 8.5244,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131497,
    shape_pt_lon: -89.925537,
    shape_pt_sequence: 171,
    shape_dist_traveled: 8.6114,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131505,
    shape_pt_lon: -89.925852,
    shape_pt_sequence: 172,
    shape_dist_traveled: 8.6394,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131511,
    shape_pt_lon: -89.926138,
    shape_pt_sequence: 173,
    shape_dist_traveled: 8.6654,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131534,
    shape_pt_lon: -89.927011,
    shape_pt_sequence: 174,
    shape_dist_traveled: 8.7444,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131552,
    shape_pt_lon: -89.927839,
    shape_pt_sequence: 175,
    shape_dist_traveled: 8.8195,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131561,
    shape_pt_lon: -89.928168,
    shape_pt_sequence: 176,
    shape_dist_traveled: 8.8495,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.928619,
    shape_pt_sequence: 177,
    shape_dist_traveled: 8.8895,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13158,
    shape_pt_lon: -89.929001,
    shape_pt_sequence: 178,
    shape_dist_traveled: 8.9245,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1316,
    shape_pt_lon: -89.929973,
    shape_pt_sequence: 179,
    shape_dist_traveled: 9.0125,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131669,
    shape_pt_lon: -89.932956,
    shape_pt_sequence: 180,
    shape_dist_traveled: 9.2816,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131686,
    shape_pt_lon: -89.933782,
    shape_pt_sequence: 181,
    shape_dist_traveled: 9.3567,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131727,
    shape_pt_lon: -89.935521,
    shape_pt_sequence: 182,
    shape_dist_traveled: 9.5137,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131738,
    shape_pt_lon: -89.935998,
    shape_pt_sequence: 183,
    shape_dist_traveled: 9.5567,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131755,
    shape_pt_lon: -89.936781,
    shape_pt_sequence: 184,
    shape_dist_traveled: 9.6278,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131819,
    shape_pt_lon: -89.93945,
    shape_pt_sequence: 185,
    shape_dist_traveled: 9.8688,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131847,
    shape_pt_lon: -89.940625,
    shape_pt_sequence: 186,
    shape_dist_traveled: 9.9749,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131862,
    shape_pt_lon: -89.941426,
    shape_pt_sequence: 187,
    shape_dist_traveled: 10.0479,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131889,
    shape_pt_lon: -89.942685,
    shape_pt_sequence: 188,
    shape_dist_traveled: 10.161,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131899,
    shape_pt_lon: -89.9431,
    shape_pt_sequence: 189,
    shape_dist_traveled: 10.199,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131908,
    shape_pt_lon: -89.943433,
    shape_pt_sequence: 190,
    shape_dist_traveled: 10.229,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131918,
    shape_pt_lon: -89.943996,
    shape_pt_sequence: 191,
    shape_dist_traveled: 10.28,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131935,
    shape_pt_lon: -89.944649,
    shape_pt_sequence: 192,
    shape_dist_traveled: 10.339,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131937,
    shape_pt_lon: -89.945073,
    shape_pt_sequence: 193,
    shape_dist_traveled: 10.377,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131572,
    shape_pt_lon: -89.945083,
    shape_pt_sequence: 194,
    shape_dist_traveled: 10.418,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130446,
    shape_pt_lon: -89.945128,
    shape_pt_sequence: 195,
    shape_dist_traveled: 10.5441,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.129279,
    shape_pt_lon: -89.945172,
    shape_pt_sequence: 196,
    shape_dist_traveled: 10.6752,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128798,
    shape_pt_lon: -89.945183,
    shape_pt_sequence: 197,
    shape_dist_traveled: 10.7282,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128289,
    shape_pt_lon: -89.945194,
    shape_pt_sequence: 198,
    shape_dist_traveled: 10.7852,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12779,
    shape_pt_lon: -89.945219,
    shape_pt_sequence: 199,
    shape_dist_traveled: 10.8412,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126399,
    shape_pt_lon: -89.945281,
    shape_pt_sequence: 200,
    shape_dist_traveled: 10.9973,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12588,
    shape_pt_lon: -89.945302,
    shape_pt_sequence: 201,
    shape_dist_traveled: 11.0553,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125484,
    shape_pt_lon: -89.945319,
    shape_pt_sequence: 202,
    shape_dist_traveled: 11.0993,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125141,
    shape_pt_lon: -89.945333,
    shape_pt_sequence: 203,
    shape_dist_traveled: 11.1384,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124782,
    shape_pt_lon: -89.945347,
    shape_pt_sequence: 204,
    shape_dist_traveled: 11.1784,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12443,
    shape_pt_lon: -89.945361,
    shape_pt_sequence: 205,
    shape_dist_traveled: 11.2174,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12383,
    shape_pt_lon: -89.945387,
    shape_pt_sequence: 206,
    shape_dist_traveled: 11.2845,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123197,
    shape_pt_lon: -89.945411,
    shape_pt_sequence: 207,
    shape_dist_traveled: 11.3555,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1232,
    shape_pt_lon: -89.945526,
    shape_pt_sequence: 208,
    shape_dist_traveled: 11.3665,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123227,
    shape_pt_lon: -89.946571,
    shape_pt_sequence: 209,
    shape_dist_traveled: 11.4605,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123238,
    shape_pt_lon: -89.94701,
    shape_pt_sequence: 210,
    shape_dist_traveled: 11.5005,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123279,
    shape_pt_lon: -89.948487,
    shape_pt_sequence: 211,
    shape_dist_traveled: 11.6336,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123313,
    shape_pt_lon: -89.949873,
    shape_pt_sequence: 212,
    shape_dist_traveled: 11.7597,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123317,
    shape_pt_lon: -89.949976,
    shape_pt_sequence: 213,
    shape_dist_traveled: 11.7687,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123347,
    shape_pt_lon: -89.950979,
    shape_pt_sequence: 214,
    shape_dist_traveled: 11.8598,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12336,
    shape_pt_lon: -89.951458,
    shape_pt_sequence: 215,
    shape_dist_traveled: 11.9028,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123376,
    shape_pt_lon: -89.952075,
    shape_pt_sequence: 216,
    shape_dist_traveled: 11.9588,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123409,
    shape_pt_lon: -89.953198,
    shape_pt_sequence: 217,
    shape_dist_traveled: 12.0599,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123434,
    shape_pt_lon: -89.954311,
    shape_pt_sequence: 218,
    shape_dist_traveled: 12.1609,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123436,
    shape_pt_lon: -89.954418,
    shape_pt_sequence: 219,
    shape_dist_traveled: 12.171,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123499,
    shape_pt_lon: -89.958224,
    shape_pt_sequence: 220,
    shape_dist_traveled: 12.514,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123521,
    shape_pt_lon: -89.958803,
    shape_pt_sequence: 221,
    shape_dist_traveled: 12.5671,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123561,
    shape_pt_lon: -89.96011,
    shape_pt_sequence: 222,
    shape_dist_traveled: 12.6852,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123588,
    shape_pt_lon: -89.961003,
    shape_pt_sequence: 223,
    shape_dist_traveled: 12.7662,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123627,
    shape_pt_lon: -89.9623,
    shape_pt_sequence: 224,
    shape_dist_traveled: 12.8833,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123665,
    shape_pt_lon: -89.964053,
    shape_pt_sequence: 225,
    shape_dist_traveled: 13.0413,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123712,
    shape_pt_lon: -89.966356,
    shape_pt_sequence: 226,
    shape_dist_traveled: 13.2494,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123714,
    shape_pt_lon: -89.966491,
    shape_pt_sequence: 227,
    shape_dist_traveled: 13.2624,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12372,
    shape_pt_lon: -89.966865,
    shape_pt_sequence: 228,
    shape_dist_traveled: 13.2954,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123729,
    shape_pt_lon: -89.967158,
    shape_pt_sequence: 229,
    shape_dist_traveled: 13.3224,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123757,
    shape_pt_lon: -89.968374,
    shape_pt_sequence: 230,
    shape_dist_traveled: 13.4325,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123779,
    shape_pt_lon: -89.969375,
    shape_pt_sequence: 231,
    shape_dist_traveled: 13.5225,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123802,
    shape_pt_lon: -89.970386,
    shape_pt_sequence: 232,
    shape_dist_traveled: 13.6146,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123822,
    shape_pt_lon: -89.970851,
    shape_pt_sequence: 233,
    shape_dist_traveled: 13.6566,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123846,
    shape_pt_lon: -89.971158,
    shape_pt_sequence: 234,
    shape_dist_traveled: 13.6838,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123889,
    shape_pt_lon: -89.971376,
    shape_pt_sequence: 235,
    shape_dist_traveled: 13.7044,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123939,
    shape_pt_lon: -89.971513,
    shape_pt_sequence: 236,
    shape_dist_traveled: 13.7183,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.123977,
    shape_pt_lon: -89.971605,
    shape_pt_sequence: 237,
    shape_dist_traveled: 13.7273,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124033,
    shape_pt_lon: -89.971708,
    shape_pt_sequence: 238,
    shape_dist_traveled: 13.7387,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124157,
    shape_pt_lon: -89.971891,
    shape_pt_sequence: 239,
    shape_dist_traveled: 13.7601,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124465,
    shape_pt_lon: -89.972277,
    shape_pt_sequence: 240,
    shape_dist_traveled: 13.8096,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.124916,
    shape_pt_lon: -89.972821,
    shape_pt_sequence: 241,
    shape_dist_traveled: 13.8796,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125091,
    shape_pt_lon: -89.973022,
    shape_pt_sequence: 242,
    shape_dist_traveled: 13.9065,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125307,
    shape_pt_lon: -89.973281,
    shape_pt_sequence: 243,
    shape_dist_traveled: 13.9397,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1254,
    shape_pt_lon: -89.973423,
    shape_pt_sequence: 244,
    shape_dist_traveled: 13.9561,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125507,
    shape_pt_lon: -89.973629,
    shape_pt_sequence: 245,
    shape_dist_traveled: 13.9786,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125617,
    shape_pt_lon: -89.973969,
    shape_pt_sequence: 246,
    shape_dist_traveled: 14.0122,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125659,
    shape_pt_lon: -89.974211,
    shape_pt_sequence: 247,
    shape_dist_traveled: 14.0346,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125678,
    shape_pt_lon: -89.974509,
    shape_pt_sequence: 248,
    shape_dist_traveled: 14.0606,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125684,
    shape_pt_lon: -89.975276,
    shape_pt_sequence: 249,
    shape_dist_traveled: 14.1306,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125701,
    shape_pt_lon: -89.975875,
    shape_pt_sequence: 250,
    shape_dist_traveled: 14.1847,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12574,
    shape_pt_lon: -89.977202,
    shape_pt_sequence: 251,
    shape_dist_traveled: 14.3048,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125749,
    shape_pt_lon: -89.977673,
    shape_pt_sequence: 252,
    shape_dist_traveled: 14.3468,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125769,
    shape_pt_lon: -89.978458,
    shape_pt_sequence: 253,
    shape_dist_traveled: 14.4178,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125786,
    shape_pt_lon: -89.979109,
    shape_pt_sequence: 254,
    shape_dist_traveled: 14.4768,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125819,
    shape_pt_lon: -89.980482,
    shape_pt_sequence: 255,
    shape_dist_traveled: 14.6009,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125865,
    shape_pt_lon: -89.982469,
    shape_pt_sequence: 256,
    shape_dist_traveled: 14.781,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125898,
    shape_pt_lon: -89.983502,
    shape_pt_sequence: 257,
    shape_dist_traveled: 14.874,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125921,
    shape_pt_lon: -89.984052,
    shape_pt_sequence: 258,
    shape_dist_traveled: 14.9241,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125928,
    shape_pt_lon: -89.98419,
    shape_pt_sequence: 259,
    shape_dist_traveled: 14.9361,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12593,
    shape_pt_lon: -89.984262,
    shape_pt_sequence: 260,
    shape_dist_traveled: 14.9431,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125957,
    shape_pt_lon: -89.985508,
    shape_pt_sequence: 261,
    shape_dist_traveled: 15.0552,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125961,
    shape_pt_lon: -89.986039,
    shape_pt_sequence: 262,
    shape_dist_traveled: 15.1032,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125971,
    shape_pt_lon: -89.987115,
    shape_pt_sequence: 263,
    shape_dist_traveled: 15.2012,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125978,
    shape_pt_lon: -89.987748,
    shape_pt_sequence: 264,
    shape_dist_traveled: 15.2582,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.125982,
    shape_pt_lon: -89.988246,
    shape_pt_sequence: 265,
    shape_dist_traveled: 15.3032,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12599,
    shape_pt_lon: -89.988843,
    shape_pt_sequence: 266,
    shape_dist_traveled: 15.3572,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126,
    shape_pt_lon: -89.989586,
    shape_pt_sequence: 267,
    shape_dist_traveled: 15.4242,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126012,
    shape_pt_lon: -89.990186,
    shape_pt_sequence: 268,
    shape_dist_traveled: 15.4782,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126025,
    shape_pt_lon: -89.990726,
    shape_pt_sequence: 269,
    shape_dist_traveled: 15.5273,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126034,
    shape_pt_lon: -89.990948,
    shape_pt_sequence: 270,
    shape_dist_traveled: 15.5473,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126061,
    shape_pt_lon: -89.992382,
    shape_pt_sequence: 271,
    shape_dist_traveled: 15.6773,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126077,
    shape_pt_lon: -89.993203,
    shape_pt_sequence: 272,
    shape_dist_traveled: 15.7513,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126094,
    shape_pt_lon: -89.994227,
    shape_pt_sequence: 273,
    shape_dist_traveled: 15.8433,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126121,
    shape_pt_lon: -89.995693,
    shape_pt_sequence: 274,
    shape_dist_traveled: 15.9764,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126128,
    shape_pt_lon: -89.996034,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.0074,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126148,
    shape_pt_lon: -89.997139,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.1074,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126167,
    shape_pt_lon: -89.998304,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.2124,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12617,
    shape_pt_lon: -89.998723,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.2505,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126228,
    shape_pt_lon: -90.001391,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.4915,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126242,
    shape_pt_lon: -90.002046,
    shape_pt_sequence: 280,
    shape_dist_traveled: 16.5505,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126281,
    shape_pt_lon: -90.002129,
    shape_pt_sequence: 281,
    shape_dist_traveled: 16.56,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126289,
    shape_pt_lon: -90.003032,
    shape_pt_sequence: 282,
    shape_dist_traveled: 16.641,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126312,
    shape_pt_lon: -90.003759,
    shape_pt_sequence: 283,
    shape_dist_traveled: 16.707,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126353,
    shape_pt_lon: -90.005191,
    shape_pt_sequence: 284,
    shape_dist_traveled: 16.8361,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126358,
    shape_pt_lon: -90.005317,
    shape_pt_sequence: 285,
    shape_dist_traveled: 16.8481,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126379,
    shape_pt_lon: -90.006286,
    shape_pt_sequence: 286,
    shape_dist_traveled: 16.9351,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126396,
    shape_pt_lon: -90.006909,
    shape_pt_sequence: 287,
    shape_dist_traveled: 16.9922,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126424,
    shape_pt_lon: -90.007961,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.0872,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126433,
    shape_pt_lon: -90.008368,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.1232,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126455,
    shape_pt_lon: -90.009093,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.1893,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126484,
    shape_pt_lon: -90.010211,
    shape_pt_sequence: 291,
    shape_dist_traveled: 17.2903,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126487,
    shape_pt_lon: -90.010289,
    shape_pt_sequence: 292,
    shape_dist_traveled: 17.2973,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126501,
    shape_pt_lon: -90.010932,
    shape_pt_sequence: 293,
    shape_dist_traveled: 17.3554,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12651,
    shape_pt_lon: -90.01124,
    shape_pt_sequence: 294,
    shape_dist_traveled: 17.3834,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12654,
    shape_pt_lon: -90.012237,
    shape_pt_sequence: 295,
    shape_dist_traveled: 17.4734,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126573,
    shape_pt_lon: -90.013643,
    shape_pt_sequence: 296,
    shape_dist_traveled: 17.6005,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126599,
    shape_pt_lon: -90.014686,
    shape_pt_sequence: 297,
    shape_dist_traveled: 17.6945,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126569,
    shape_pt_lon: -90.014751,
    shape_pt_sequence: 298,
    shape_dist_traveled: 17.7018,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126577,
    shape_pt_lon: -90.0149,
    shape_pt_sequence: 299,
    shape_dist_traveled: 17.7158,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126607,
    shape_pt_lon: -90.016338,
    shape_pt_sequence: 300,
    shape_dist_traveled: 17.8458,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12663,
    shape_pt_lon: -90.016373,
    shape_pt_sequence: 301,
    shape_dist_traveled: 17.8501,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12665,
    shape_pt_lon: -90.016515,
    shape_pt_sequence: 302,
    shape_dist_traveled: 17.8632,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126697,
    shape_pt_lon: -90.017097,
    shape_pt_sequence: 303,
    shape_dist_traveled: 17.9155,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126742,
    shape_pt_lon: -90.017448,
    shape_pt_sequence: 304,
    shape_dist_traveled: 17.9479,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.126747,
    shape_pt_lon: -90.017553,
    shape_pt_sequence: 305,
    shape_dist_traveled: 17.9569,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.127231,
    shape_pt_lon: -90.018451,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.0551,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.127902,
    shape_pt_lon: -90.019632,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.1849,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128518,
    shape_pt_lon: -90.02076,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.3081,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128698,
    shape_pt_lon: -90.021071,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.3433,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.128769,
    shape_pt_lon: -90.021198,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.3569,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12885,
    shape_pt_lon: -90.021342,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.3727,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.129111,
    shape_pt_lon: -90.021816,
    shape_pt_sequence: 312,
    shape_dist_traveled: 18.4246,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12943,
    shape_pt_lon: -90.022399,
    shape_pt_sequence: 313,
    shape_dist_traveled: 18.4887,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.12983,
    shape_pt_lon: -90.023091,
    shape_pt_sequence: 314,
    shape_dist_traveled: 18.5653,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13001,
    shape_pt_lon: -90.023389,
    shape_pt_sequence: 315,
    shape_dist_traveled: 18.5989,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130155,
    shape_pt_lon: -90.02363,
    shape_pt_sequence: 316,
    shape_dist_traveled: 18.6261,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130251,
    shape_pt_lon: -90.023789,
    shape_pt_sequence: 317,
    shape_dist_traveled: 18.6439,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130266,
    shape_pt_lon: -90.023813,
    shape_pt_sequence: 318,
    shape_dist_traveled: 18.6467,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1303,
    shape_pt_lon: -90.023872,
    shape_pt_sequence: 319,
    shape_dist_traveled: 18.6534,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130412,
    shape_pt_lon: -90.024056,
    shape_pt_sequence: 320,
    shape_dist_traveled: 18.674,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130433,
    shape_pt_lon: -90.024093,
    shape_pt_sequence: 321,
    shape_dist_traveled: 18.6785,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130709,
    shape_pt_lon: -90.024567,
    shape_pt_sequence: 322,
    shape_dist_traveled: 18.7315,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.130826,
    shape_pt_lon: -90.024751,
    shape_pt_sequence: 323,
    shape_dist_traveled: 18.7521,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131317,
    shape_pt_lon: -90.025558,
    shape_pt_sequence: 324,
    shape_dist_traveled: 18.8435,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131422,
    shape_pt_lon: -90.025729,
    shape_pt_sequence: 325,
    shape_dist_traveled: 18.8635,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131601,
    shape_pt_lon: -90.026066,
    shape_pt_sequence: 326,
    shape_dist_traveled: 18.8996,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131693,
    shape_pt_lon: -90.026349,
    shape_pt_sequence: 327,
    shape_dist_traveled: 18.9274,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131729,
    shape_pt_lon: -90.026568,
    shape_pt_sequence: 328,
    shape_dist_traveled: 18.9469,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13174,
    shape_pt_lon: -90.026917,
    shape_pt_sequence: 329,
    shape_dist_traveled: 18.9789,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131741,
    shape_pt_lon: -90.02703,
    shape_pt_sequence: 330,
    shape_dist_traveled: 18.9889,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131744,
    shape_pt_lon: -90.027252,
    shape_pt_sequence: 331,
    shape_dist_traveled: 19.0089,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131761,
    shape_pt_lon: -90.028378,
    shape_pt_sequence: 332,
    shape_dist_traveled: 19.1109,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131762,
    shape_pt_lon: -90.028411,
    shape_pt_sequence: 333,
    shape_dist_traveled: 19.1139,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131782,
    shape_pt_lon: -90.029179,
    shape_pt_sequence: 334,
    shape_dist_traveled: 19.1829,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131798,
    shape_pt_lon: -90.029742,
    shape_pt_sequence: 335,
    shape_dist_traveled: 19.234,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.131833,
    shape_pt_lon: -90.03107,
    shape_pt_sequence: 336,
    shape_dist_traveled: 19.3541,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.132401,
    shape_pt_lon: -90.031066,
    shape_pt_sequence: 337,
    shape_dist_traveled: 19.4171,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.132953,
    shape_pt_lon: -90.031045,
    shape_pt_sequence: 338,
    shape_dist_traveled: 19.4791,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.132985,
    shape_pt_lon: -90.031042,
    shape_pt_sequence: 339,
    shape_dist_traveled: 19.4821,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133481,
    shape_pt_lon: -90.031028,
    shape_pt_sequence: 340,
    shape_dist_traveled: 19.5381,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133515,
    shape_pt_lon: -90.031269,
    shape_pt_sequence: 341,
    shape_dist_traveled: 19.5595,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133591,
    shape_pt_lon: -90.032078,
    shape_pt_sequence: 342,
    shape_dist_traveled: 19.6329,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133597,
    shape_pt_lon: -90.032144,
    shape_pt_sequence: 343,
    shape_dist_traveled: 19.639,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133647,
    shape_pt_lon: -90.032713,
    shape_pt_sequence: 344,
    shape_dist_traveled: 19.6912,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133701,
    shape_pt_lon: -90.033345,
    shape_pt_sequence: 345,
    shape_dist_traveled: 19.7487,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133854,
    shape_pt_lon: -90.035001,
    shape_pt_sequence: 346,
    shape_dist_traveled: 19.8996,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133879,
    shape_pt_lon: -90.03529,
    shape_pt_sequence: 347,
    shape_dist_traveled: 19.9257,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133941,
    shape_pt_lon: -90.036041,
    shape_pt_sequence: 348,
    shape_dist_traveled: 19.9941,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.133991,
    shape_pt_lon: -90.03661,
    shape_pt_sequence: 349,
    shape_dist_traveled: 20.0454,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134108,
    shape_pt_lon: -90.03791,
    shape_pt_sequence: 350,
    shape_dist_traveled: 20.1631,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134308,
    shape_pt_lon: -90.040018,
    shape_pt_sequence: 351,
    shape_dist_traveled: 20.3554,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134419,
    shape_pt_lon: -90.041217,
    shape_pt_sequence: 352,
    shape_dist_traveled: 20.4642,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13445,
    shape_pt_lon: -90.041519,
    shape_pt_sequence: 353,
    shape_dist_traveled: 20.4923,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134541,
    shape_pt_lon: -90.042447,
    shape_pt_sequence: 354,
    shape_dist_traveled: 20.5759,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134648,
    shape_pt_lon: -90.043655,
    shape_pt_sequence: 355,
    shape_dist_traveled: 20.6866,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13473,
    shape_pt_lon: -90.044399,
    shape_pt_sequence: 356,
    shape_dist_traveled: 20.7542,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134779,
    shape_pt_lon: -90.044939,
    shape_pt_sequence: 357,
    shape_dist_traveled: 20.8036,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134911,
    shape_pt_lon: -90.046351,
    shape_pt_sequence: 358,
    shape_dist_traveled: 20.9314,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134916,
    shape_pt_lon: -90.046389,
    shape_pt_sequence: 359,
    shape_dist_traveled: 20.9354,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.134923,
    shape_pt_lon: -90.046476,
    shape_pt_sequence: 360,
    shape_dist_traveled: 20.9425,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.135266,
    shape_pt_lon: -90.050031,
    shape_pt_sequence: 361,
    shape_dist_traveled: 21.2667,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.135413,
    shape_pt_lon: -90.051589,
    shape_pt_sequence: 362,
    shape_dist_traveled: 21.4078,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.135536,
    shape_pt_lon: -90.052817,
    shape_pt_sequence: 363,
    shape_dist_traveled: 21.5195,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.135632,
    shape_pt_lon: -90.053788,
    shape_pt_sequence: 364,
    shape_dist_traveled: 21.6082,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.135667,
    shape_pt_lon: -90.0539,
    shape_pt_sequence: 365,
    shape_dist_traveled: 21.619,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13589,
    shape_pt_lon: -90.054339,
    shape_pt_sequence: 366,
    shape_dist_traveled: 21.6662,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.136729,
    shape_pt_lon: -90.053698,
    shape_pt_sequence: 367,
    shape_dist_traveled: 21.7766,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.137168,
    shape_pt_lon: -90.053338,
    shape_pt_sequence: 368,
    shape_dist_traveled: 21.8351,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.137529,
    shape_pt_lon: -90.053063,
    shape_pt_sequence: 369,
    shape_dist_traveled: 21.8823,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.138424,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 370,
    shape_dist_traveled: 21.9988,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.138682,
    shape_pt_lon: -90.052261,
    shape_pt_sequence: 371,
    shape_dist_traveled: 22.0305,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.139106,
    shape_pt_lon: -90.052032,
    shape_pt_sequence: 372,
    shape_dist_traveled: 22.0825,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.139189,
    shape_pt_lon: -90.051986,
    shape_pt_sequence: 373,
    shape_dist_traveled: 22.0928,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.139359,
    shape_pt_lon: -90.05191,
    shape_pt_sequence: 374,
    shape_dist_traveled: 22.1128,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.139539,
    shape_pt_lon: -90.051861,
    shape_pt_sequence: 375,
    shape_dist_traveled: 22.1334,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.13967,
    shape_pt_lon: -90.051826,
    shape_pt_sequence: 376,
    shape_dist_traveled: 22.1487,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.140353,
    shape_pt_lon: -90.051719,
    shape_pt_sequence: 377,
    shape_dist_traveled: 22.2253,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.140882,
    shape_pt_lon: -90.051639,
    shape_pt_sequence: 378,
    shape_dist_traveled: 22.2857,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.141304,
    shape_pt_lon: -90.051561,
    shape_pt_sequence: 379,
    shape_dist_traveled: 22.3332,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.1415,
    shape_pt_lon: -90.051508,
    shape_pt_sequence: 380,
    shape_dist_traveled: 22.3558,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.141878,
    shape_pt_lon: -90.05137,
    shape_pt_sequence: 381,
    shape_dist_traveled: 22.3985,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.051117,
    shape_pt_sequence: 382,
    shape_dist_traveled: 22.4751,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.142973,
    shape_pt_lon: -90.050936,
    shape_pt_sequence: 383,
    shape_dist_traveled: 22.5276,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.143505,
    shape_pt_lon: -90.05072,
    shape_pt_sequence: 384,
    shape_dist_traveled: 22.5899,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.143937,
    shape_pt_lon: -90.050543,
    shape_pt_sequence: 385,
    shape_dist_traveled: 22.6405,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.14452,
    shape_pt_lon: -90.050311,
    shape_pt_sequence: 386,
    shape_dist_traveled: 22.7097,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.145034,
    shape_pt_lon: -90.050108,
    shape_pt_sequence: 387,
    shape_dist_traveled: 22.7695,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.145522,
    shape_pt_lon: -90.049912,
    shape_pt_sequence: 388,
    shape_dist_traveled: 22.8274,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.146007,
    shape_pt_lon: -90.049721,
    shape_pt_sequence: 389,
    shape_dist_traveled: 22.884,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.146516,
    shape_pt_lon: -90.049512,
    shape_pt_sequence: 390,
    shape_dist_traveled: 22.9441,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.14751,
    shape_pt_lon: -90.049116,
    shape_pt_sequence: 391,
    shape_dist_traveled: 23.0607,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.148509,
    shape_pt_lon: -90.048717,
    shape_pt_sequence: 392,
    shape_dist_traveled: 23.1784,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.148985,
    shape_pt_lon: -90.048522,
    shape_pt_sequence: 393,
    shape_dist_traveled: 23.2341,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.149495,
    shape_pt_lon: -90.04832,
    shape_pt_sequence: 394,
    shape_dist_traveled: 23.2941,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.150489,
    shape_pt_lon: -90.047919,
    shape_pt_sequence: 395,
    shape_dist_traveled: 23.4118,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.151488,
    shape_pt_lon: -90.047523,
    shape_pt_sequence: 396,
    shape_dist_traveled: 23.5282,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.152436,
    shape_pt_lon: -90.047118,
    shape_pt_sequence: 397,
    shape_dist_traveled: 23.6414,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.152524,
    shape_pt_lon: -90.047078,
    shape_pt_sequence: 398,
    shape_dist_traveled: 23.6512,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.152715,
    shape_pt_lon: -90.047018,
    shape_pt_sequence: 399,
    shape_dist_traveled: 23.6738,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.152813,
    shape_pt_lon: -90.046978,
    shape_pt_sequence: 400,
    shape_dist_traveled: 23.6855,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.153217,
    shape_pt_lon: -90.04682,
    shape_pt_sequence: 401,
    shape_dist_traveled: 23.7326,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.153446,
    shape_pt_lon: -90.046737,
    shape_pt_sequence: 402,
    shape_dist_traveled: 23.7596,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.15358,
    shape_pt_lon: -90.046695,
    shape_pt_sequence: 403,
    shape_dist_traveled: 23.7751,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.154467,
    shape_pt_lon: -90.046331,
    shape_pt_sequence: 404,
    shape_dist_traveled: 23.8794,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.155417,
    shape_pt_lon: -90.045946,
    shape_pt_sequence: 405,
    shape_dist_traveled: 23.9911,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 406,
    shape_dist_traveled: 23.9983,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 407,
    shape_dist_traveled: 24.0689,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 408,
    shape_dist_traveled: 24.116,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 409,
    shape_dist_traveled: 24.1758,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 410,
    shape_dist_traveled: 24.1986,
  },
  {
    shape_id: 38258,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 411,
    shape_dist_traveled: 24.265,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.157122,
    shape_pt_lon: -90.048039,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.156166,
    shape_pt_lon: -90.048424,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.1126,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.15613,
    shape_pt_lon: -90.048439,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1167,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.15515,
    shape_pt_lon: -90.048837,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.2324,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.15415,
    shape_pt_lon: -90.04924,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.3504,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.153966,
    shape_pt_lon: -90.04931,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3713,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.153653,
    shape_pt_lon: -90.049428,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.408,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.153281,
    shape_pt_lon: -90.049567,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4516,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.153133,
    shape_pt_lon: -90.04962,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4694,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.153049,
    shape_pt_lon: -90.049651,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.4789,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.152941,
    shape_pt_lon: -90.04969,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.4912,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.152823,
    shape_pt_lon: -90.049739,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.5052,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.152218,
    shape_pt_lon: -90.050002,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.5769,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.152043,
    shape_pt_lon: -90.050073,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.5972,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.151751,
    shape_pt_lon: -90.05019,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.6317,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.151289,
    shape_pt_lon: -90.050351,
    shape_pt_sequence: 16,
    shape_dist_traveled: 0.6858,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.151237,
    shape_pt_lon: -90.050352,
    shape_pt_sequence: 17,
    shape_dist_traveled: 0.6918,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.151224,
    shape_pt_lon: -90.050379,
    shape_pt_sequence: 18,
    shape_dist_traveled: 0.6949,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.151181,
    shape_pt_lon: -90.050432,
    shape_pt_sequence: 19,
    shape_dist_traveled: 0.7014,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.150172,
    shape_pt_lon: -90.050838,
    shape_pt_sequence: 20,
    shape_dist_traveled: 0.8203,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.148192,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.0524,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.147947,
    shape_pt_lon: -90.051729,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.0818,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.147209,
    shape_pt_lon: -90.052009,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.1678,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.1466,
    shape_pt_lon: -90.052253,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.2402,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.146175,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.2896,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.145982,
    shape_pt_lon: -90.052506,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.313,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.14567,
    shape_pt_lon: -90.052631,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.3497,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.14523,
    shape_pt_lon: -90.052809,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.4012,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.144712,
    shape_pt_lon: -90.053017,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.4622,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.144199,
    shape_pt_lon: -90.053222,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.522,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.143688,
    shape_pt_lon: -90.053436,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.5824,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.143645,
    shape_pt_lon: -90.053453,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.5875,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.143212,
    shape_pt_lon: -90.053621,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6388,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.053878,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.7182,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.142023,
    shape_pt_lon: -90.054088,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.7783,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.141498,
    shape_pt_lon: -90.054322,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.8403,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.140932,
    shape_pt_lon: -90.054531,
    shape_pt_sequence: 37,
    shape_dist_traveled: 1.9058,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.140671,
    shape_pt_lon: -90.054651,
    shape_pt_sequence: 38,
    shape_dist_traveled: 1.9368,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.140313,
    shape_pt_lon: -90.054799,
    shape_pt_sequence: 39,
    shape_dist_traveled: 1.9792,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.140023,
    shape_pt_lon: -90.054969,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.0155,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.139789,
    shape_pt_lon: -90.055148,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.046,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.139102,
    shape_pt_lon: -90.05563,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.1347,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.138956,
    shape_pt_lon: -90.055742,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.1535,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.138059,
    shape_pt_lon: -90.056393,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.2696,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.137194,
    shape_pt_lon: -90.057018,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.3817,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.136349,
    shape_pt_lon: -90.057626,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.4914,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.136122,
    shape_pt_lon: -90.0578,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.5211,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.1356,
    shape_pt_lon: -90.058168,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.5887,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.135482,
    shape_pt_lon: -90.058266,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.6045,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.134772,
    shape_pt_lon: -90.058771,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6954,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.134614,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.7151,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.134476,
    shape_pt_lon: -90.058908,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.7309,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.134343,
    shape_pt_lon: -90.058941,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.7462,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.134092,
    shape_pt_lon: -90.058958,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7743,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.132823,
    shape_pt_lon: -90.059,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9163,
  },
  {
    shape_id: 38263,
    shape_pt_lat: 35.131633,
    shape_pt_lon: -90.059031,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0493,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.131633,
    shape_pt_lon: -90.059031,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.132823,
    shape_pt_lon: -90.059,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.133,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.134092,
    shape_pt_lon: -90.058958,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.2751,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.134343,
    shape_pt_lon: -90.058941,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.3031,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.134476,
    shape_pt_lon: -90.058908,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.3184,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.134614,
    shape_pt_lon: -90.058858,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.3342,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.134772,
    shape_pt_lon: -90.058771,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3539,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.135482,
    shape_pt_lon: -90.058266,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4448,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.1356,
    shape_pt_lon: -90.058168,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4606,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.136122,
    shape_pt_lon: -90.0578,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5282,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.136349,
    shape_pt_lon: -90.057626,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.5579,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.137194,
    shape_pt_lon: -90.057018,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.6677,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.138059,
    shape_pt_lon: -90.056393,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.7797,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.138956,
    shape_pt_lon: -90.055742,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.8958,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.139102,
    shape_pt_lon: -90.05563,
    shape_pt_sequence: 15,
    shape_dist_traveled: 0.9147,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.139789,
    shape_pt_lon: -90.055148,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.0034,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.140023,
    shape_pt_lon: -90.054969,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.0339,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.140313,
    shape_pt_lon: -90.054799,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.0701,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.140671,
    shape_pt_lon: -90.054651,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.1125,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.140932,
    shape_pt_lon: -90.054531,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.1435,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.141498,
    shape_pt_lon: -90.054322,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.2091,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.142023,
    shape_pt_lon: -90.054088,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.2711,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.142534,
    shape_pt_lon: -90.053878,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3312,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.143212,
    shape_pt_lon: -90.053621,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.4106,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.143645,
    shape_pt_lon: -90.053453,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4618,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.143688,
    shape_pt_lon: -90.053436,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4669,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.144199,
    shape_pt_lon: -90.053222,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.5273,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.144712,
    shape_pt_lon: -90.053017,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.5871,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.14523,
    shape_pt_lon: -90.052809,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6481,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.14567,
    shape_pt_lon: -90.052631,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6997,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.145982,
    shape_pt_lon: -90.052506,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.7364,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.146175,
    shape_pt_lon: -90.052427,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.7598,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.1466,
    shape_pt_lon: -90.052253,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.8091,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.147209,
    shape_pt_lon: -90.052009,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8815,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.147947,
    shape_pt_lon: -90.051729,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.9676,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.148192,
    shape_pt_lon: -90.051628,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.997,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.150172,
    shape_pt_lon: -90.050838,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.2291,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.151181,
    shape_pt_lon: -90.050432,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.348,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.151224,
    shape_pt_lon: -90.050379,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.3544,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.151237,
    shape_pt_lon: -90.050352,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.3576,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.151289,
    shape_pt_lon: -90.050351,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.3636,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.151751,
    shape_pt_lon: -90.05019,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.4177,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.152043,
    shape_pt_lon: -90.050073,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.4522,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.152218,
    shape_pt_lon: -90.050002,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.4724,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.152823,
    shape_pt_lon: -90.049739,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.5442,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.152941,
    shape_pt_lon: -90.04969,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.5581,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.153049,
    shape_pt_lon: -90.049651,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.5705,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.153133,
    shape_pt_lon: -90.04962,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.58,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.153281,
    shape_pt_lon: -90.049567,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5977,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.153653,
    shape_pt_lon: -90.049428,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.6414,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.153966,
    shape_pt_lon: -90.04931,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.6781,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.15415,
    shape_pt_lon: -90.04924,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.699,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.15515,
    shape_pt_lon: -90.048837,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.8169,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.15613,
    shape_pt_lon: -90.048439,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.9326,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.156166,
    shape_pt_lon: -90.048424,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.9368,
  },
  {
    shape_id: 38264,
    shape_pt_lat: 35.157122,
    shape_pt_lon: -90.048039,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.0493,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221735,
    shape_pt_lon: -89.910006,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221717,
    shape_pt_lon: -89.909378,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.056,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221677,
    shape_pt_lon: -89.909301,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.0641,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22158,
    shape_pt_lon: -89.909139,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.0827,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221492,
    shape_pt_lon: -89.909083,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.0939,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221249,
    shape_pt_lon: -89.909068,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.1209,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220387,
    shape_pt_lon: -89.909927,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.2447,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219228,
    shape_pt_lon: -89.911078,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4112,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218346,
    shape_pt_lon: -89.911954,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.5371,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218097,
    shape_pt_lon: -89.912202,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5727,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217299,
    shape_pt_lon: -89.91299,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6873,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217119,
    shape_pt_lon: -89.91317,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.713,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215377,
    shape_pt_lon: -89.914903,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.9627,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214214,
    shape_pt_lon: -89.916057,
    shape_pt_sequence: 14,
    shape_dist_traveled: 1.1292,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213659,
    shape_pt_lon: -89.916607,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.2096,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213717,
    shape_pt_lon: -89.916693,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.2195,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214029,
    shape_pt_lon: -89.917117,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.2719,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214102,
    shape_pt_lon: -89.917201,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.2825,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214319,
    shape_pt_lon: -89.917432,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.3144,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214499,
    shape_pt_lon: -89.917579,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.3388,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214749,
    shape_pt_lon: -89.917743,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.3701,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21499,
    shape_pt_lon: -89.91786,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.3993,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215228,
    shape_pt_lon: -89.917958,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.4277,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215548,
    shape_pt_lon: -89.918038,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.4644,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21588,
    shape_pt_lon: -89.918071,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.5015,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217341,
    shape_pt_lon: -89.918046,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.6646,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217567,
    shape_pt_lon: -89.918014,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.6907,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217846,
    shape_pt_lon: -89.917936,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.7225,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218148,
    shape_pt_lon: -89.917797,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.7586,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218325,
    shape_pt_lon: -89.917679,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.7805,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218623,
    shape_pt_lon: -89.917472,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.819,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218807,
    shape_pt_lon: -89.917377,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.8409,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219051,
    shape_pt_lon: -89.917291,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.87,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219306,
    shape_pt_lon: -89.917243,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.8983,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219489,
    shape_pt_lon: -89.917234,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.9193,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221009,
    shape_pt_lon: -89.917198,
    shape_pt_sequence: 36,
    shape_dist_traveled: 2.0894,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221831,
    shape_pt_lon: -89.917172,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.1814,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221838,
    shape_pt_lon: -89.917636,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.2234,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221828,
    shape_pt_lon: -89.918008,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.2575,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221786,
    shape_pt_lon: -89.918317,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.2848,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221727,
    shape_pt_lon: -89.918561,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.3078,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221716,
    shape_pt_lon: -89.918603,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.312,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221623,
    shape_pt_lon: -89.918843,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.3366,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221507,
    shape_pt_lon: -89.919076,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.3613,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221316,
    shape_pt_lon: -89.919367,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.3947,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221067,
    shape_pt_lon: -89.919716,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.4372,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220863,
    shape_pt_lon: -89.920017,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.4727,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220759,
    shape_pt_lon: -89.920209,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.4935,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220641,
    shape_pt_lon: -89.920478,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.5208,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220551,
    shape_pt_lon: -89.920756,
    shape_pt_sequence: 50,
    shape_dist_traveled: 2.5477,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22049,
    shape_pt_lon: -89.921022,
    shape_pt_sequence: 51,
    shape_dist_traveled: 2.5727,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220449,
    shape_pt_lon: -89.921348,
    shape_pt_sequence: 52,
    shape_dist_traveled: 2.603,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220439,
    shape_pt_lon: -89.921687,
    shape_pt_sequence: 53,
    shape_dist_traveled: 2.633,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220459,
    shape_pt_lon: -89.922708,
    shape_pt_sequence: 54,
    shape_dist_traveled: 2.7261,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220474,
    shape_pt_lon: -89.923727,
    shape_pt_sequence: 55,
    shape_dist_traveled: 2.8181,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220493,
    shape_pt_lon: -89.924727,
    shape_pt_sequence: 56,
    shape_dist_traveled: 2.9081,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220537,
    shape_pt_lon: -89.927197,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.1312,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22055,
    shape_pt_lon: -89.927511,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.1592,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220587,
    shape_pt_lon: -89.92784,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.1895,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22075,
    shape_pt_lon: -89.928697,
    shape_pt_sequence: 60,
    shape_dist_traveled: 3.2685,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22095,
    shape_pt_lon: -89.929938,
    shape_pt_sequence: 61,
    shape_dist_traveled: 3.3827,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22102,
    shape_pt_lon: -89.93031,
    shape_pt_sequence: 62,
    shape_dist_traveled: 3.4176,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22109,
    shape_pt_lon: -89.93055,
    shape_pt_sequence: 63,
    shape_dist_traveled: 3.4401,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221169,
    shape_pt_lon: -89.93075,
    shape_pt_sequence: 64,
    shape_dist_traveled: 3.4611,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221272,
    shape_pt_lon: -89.930961,
    shape_pt_sequence: 65,
    shape_dist_traveled: 3.483,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221427,
    shape_pt_lon: -89.931195,
    shape_pt_sequence: 66,
    shape_dist_traveled: 3.5107,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221957,
    shape_pt_lon: -89.931877,
    shape_pt_sequence: 67,
    shape_dist_traveled: 3.5956,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222495,
    shape_pt_lon: -89.932685,
    shape_pt_sequence: 68,
    shape_dist_traveled: 3.6901,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222751,
    shape_pt_lon: -89.933102,
    shape_pt_sequence: 69,
    shape_dist_traveled: 3.7379,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222888,
    shape_pt_lon: -89.933387,
    shape_pt_sequence: 70,
    shape_dist_traveled: 3.7679,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222919,
    shape_pt_lon: -89.9335,
    shape_pt_sequence: 71,
    shape_dist_traveled: 3.7783,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222958,
    shape_pt_lon: -89.933815,
    shape_pt_sequence: 72,
    shape_dist_traveled: 3.8068,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222998,
    shape_pt_lon: -89.93501,
    shape_pt_sequence: 73,
    shape_dist_traveled: 3.9148,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223017,
    shape_pt_lon: -89.936447,
    shape_pt_sequence: 74,
    shape_dist_traveled: 4.0449,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223032,
    shape_pt_lon: -89.937811,
    shape_pt_sequence: 75,
    shape_dist_traveled: 4.1679,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223043,
    shape_pt_lon: -89.938234,
    shape_pt_sequence: 76,
    shape_dist_traveled: 4.2059,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223081,
    shape_pt_lon: -89.940179,
    shape_pt_sequence: 77,
    shape_dist_traveled: 4.382,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.2231,
    shape_pt_lon: -89.941153,
    shape_pt_sequence: 78,
    shape_dist_traveled: 4.47,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223182,
    shape_pt_lon: -89.945031,
    shape_pt_sequence: 79,
    shape_dist_traveled: 4.8201,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223225,
    shape_pt_lon: -89.945517,
    shape_pt_sequence: 80,
    shape_dist_traveled: 4.8644,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223267,
    shape_pt_lon: -89.945759,
    shape_pt_sequence: 81,
    shape_dist_traveled: 4.8869,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223339,
    shape_pt_lon: -89.946096,
    shape_pt_sequence: 82,
    shape_dist_traveled: 4.918,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223476,
    shape_pt_lon: -89.946676,
    shape_pt_sequence: 83,
    shape_dist_traveled: 4.9721,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22352,
    shape_pt_lon: -89.946899,
    shape_pt_sequence: 84,
    shape_dist_traveled: 4.9927,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223558,
    shape_pt_lon: -89.94719,
    shape_pt_sequence: 85,
    shape_dist_traveled: 5.02,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223564,
    shape_pt_lon: -89.947272,
    shape_pt_sequence: 86,
    shape_dist_traveled: 5.0271,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223576,
    shape_pt_lon: -89.947396,
    shape_pt_sequence: 87,
    shape_dist_traveled: 5.0381,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223582,
    shape_pt_lon: -89.947711,
    shape_pt_sequence: 88,
    shape_dist_traveled: 5.0671,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223531,
    shape_pt_lon: -89.94958,
    shape_pt_sequence: 89,
    shape_dist_traveled: 5.2352,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223524,
    shape_pt_lon: -89.949693,
    shape_pt_sequence: 90,
    shape_dist_traveled: 5.2463,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223563,
    shape_pt_lon: -89.949787,
    shape_pt_sequence: 91,
    shape_dist_traveled: 5.2552,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223556,
    shape_pt_lon: -89.949923,
    shape_pt_sequence: 92,
    shape_dist_traveled: 5.2672,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223543,
    shape_pt_lon: -89.950098,
    shape_pt_sequence: 93,
    shape_dist_traveled: 5.2834,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223524,
    shape_pt_lon: -89.950271,
    shape_pt_sequence: 94,
    shape_dist_traveled: 5.2995,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22345,
    shape_pt_lon: -89.95067,
    shape_pt_sequence: 95,
    shape_dist_traveled: 5.3364,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223209,
    shape_pt_lon: -89.951614,
    shape_pt_sequence: 96,
    shape_dist_traveled: 5.4255,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223156,
    shape_pt_lon: -89.95195,
    shape_pt_sequence: 97,
    shape_dist_traveled: 5.4561,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223039,
    shape_pt_lon: -89.953535,
    shape_pt_sequence: 98,
    shape_dist_traveled: 5.5998,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223005,
    shape_pt_lon: -89.953889,
    shape_pt_sequence: 99,
    shape_dist_traveled: 5.632,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222909,
    shape_pt_lon: -89.955196,
    shape_pt_sequence: 100,
    shape_dist_traveled: 5.7505,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222842,
    shape_pt_lon: -89.956096,
    shape_pt_sequence: 101,
    shape_dist_traveled: 5.8319,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22358,
    shape_pt_lon: -89.95591,
    shape_pt_sequence: 102,
    shape_dist_traveled: 5.9164,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.224417,
    shape_pt_lon: -89.955717,
    shape_pt_sequence: 103,
    shape_dist_traveled: 6.0111,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.225346,
    shape_pt_lon: -89.955505,
    shape_pt_sequence: 104,
    shape_dist_traveled: 6.1168,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.226409,
    shape_pt_lon: -89.955249,
    shape_pt_sequence: 105,
    shape_dist_traveled: 6.238,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22741,
    shape_pt_lon: -89.955025,
    shape_pt_sequence: 106,
    shape_dist_traveled: 6.3518,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.228384,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 107,
    shape_dist_traveled: 6.4628,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22942,
    shape_pt_lon: -89.954546,
    shape_pt_sequence: 108,
    shape_dist_traveled: 6.5809,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230295,
    shape_pt_lon: -89.954345,
    shape_pt_sequence: 109,
    shape_dist_traveled: 6.6805,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230742,
    shape_pt_lon: -89.954223,
    shape_pt_sequence: 110,
    shape_dist_traveled: 6.7317,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.231108,
    shape_pt_lon: -89.954067,
    shape_pt_sequence: 111,
    shape_dist_traveled: 6.775,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.231948,
    shape_pt_lon: -89.9537,
    shape_pt_sequence: 112,
    shape_dist_traveled: 6.8747,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232118,
    shape_pt_lon: -89.953622,
    shape_pt_sequence: 113,
    shape_dist_traveled: 6.8949,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232218,
    shape_pt_lon: -89.955461,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.0613,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232158,
    shape_pt_lon: -89.958199,
    shape_pt_sequence: 115,
    shape_dist_traveled: 7.3084,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232122,
    shape_pt_lon: -89.958199,
    shape_pt_sequence: 116,
    shape_dist_traveled: 7.3124,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232131,
    shape_pt_lon: -89.957944,
    shape_pt_sequence: 117,
    shape_dist_traveled: 7.3354,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232148,
    shape_pt_lon: -89.956991,
    shape_pt_sequence: 118,
    shape_dist_traveled: 7.4214,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232165,
    shape_pt_lon: -89.956226,
    shape_pt_sequence: 119,
    shape_dist_traveled: 7.4905,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232182,
    shape_pt_lon: -89.955661,
    shape_pt_sequence: 120,
    shape_dist_traveled: 7.5415,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232191,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 121,
    shape_dist_traveled: 7.5615,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232164,
    shape_pt_lon: -89.954963,
    shape_pt_sequence: 122,
    shape_dist_traveled: 7.6046,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.232136,
    shape_pt_lon: -89.954542,
    shape_pt_sequence: 123,
    shape_dist_traveled: 7.6427,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.2321,
    shape_pt_lon: -89.953921,
    shape_pt_sequence: 124,
    shape_dist_traveled: 7.6989,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.231779,
    shape_pt_lon: -89.954066,
    shape_pt_sequence: 125,
    shape_dist_traveled: 7.7372,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.231082,
    shape_pt_lon: -89.954366,
    shape_pt_sequence: 126,
    shape_dist_traveled: 7.8197,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.231073,
    shape_pt_lon: -89.954366,
    shape_pt_sequence: 127,
    shape_dist_traveled: 7.8197,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230859,
    shape_pt_lon: -89.954444,
    shape_pt_sequence: 128,
    shape_dist_traveled: 7.8447,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230635,
    shape_pt_lon: -89.954533,
    shape_pt_sequence: 129,
    shape_dist_traveled: 7.8709,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230503,
    shape_pt_lon: -89.954569,
    shape_pt_sequence: 130,
    shape_dist_traveled: 7.8855,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.230099,
    shape_pt_lon: -89.954667,
    shape_pt_sequence: 131,
    shape_dist_traveled: 7.9322,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.229867,
    shape_pt_lon: -89.954723,
    shape_pt_sequence: 132,
    shape_dist_traveled: 7.9587,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.229527,
    shape_pt_lon: -89.954801,
    shape_pt_sequence: 133,
    shape_dist_traveled: 7.9973,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.229242,
    shape_pt_lon: -89.954856,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.0297,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.228661,
    shape_pt_lon: -89.95499,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.0958,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.228053,
    shape_pt_lon: -89.955124,
    shape_pt_sequence: 136,
    shape_dist_traveled: 8.1649,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.227571,
    shape_pt_lon: -89.955236,
    shape_pt_sequence: 137,
    shape_dist_traveled: 8.2198,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.226874,
    shape_pt_lon: -89.955392,
    shape_pt_sequence: 138,
    shape_dist_traveled: 8.299,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.226124,
    shape_pt_lon: -89.955581,
    shape_pt_sequence: 139,
    shape_dist_traveled: 8.3847,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22565,
    shape_pt_lon: -89.955693,
    shape_pt_sequence: 140,
    shape_dist_traveled: 8.4387,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22523,
    shape_pt_lon: -89.955782,
    shape_pt_sequence: 141,
    shape_dist_traveled: 8.4863,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22481,
    shape_pt_lon: -89.955882,
    shape_pt_sequence: 142,
    shape_dist_traveled: 8.5342,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.224256,
    shape_pt_lon: -89.956016,
    shape_pt_sequence: 143,
    shape_dist_traveled: 8.5973,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.223595,
    shape_pt_lon: -89.956161,
    shape_pt_sequence: 144,
    shape_dist_traveled: 8.6725,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222824,
    shape_pt_lon: -89.956337,
    shape_pt_sequence: 145,
    shape_dist_traveled: 8.7599,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222795,
    shape_pt_lon: -89.956802,
    shape_pt_sequence: 146,
    shape_dist_traveled: 8.8021,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222741,
    shape_pt_lon: -89.957538,
    shape_pt_sequence: 147,
    shape_dist_traveled: 8.8693,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222702,
    shape_pt_lon: -89.957786,
    shape_pt_sequence: 148,
    shape_dist_traveled: 8.8919,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222689,
    shape_pt_lon: -89.95787,
    shape_pt_sequence: 149,
    shape_dist_traveled: 8.8999,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.222582,
    shape_pt_lon: -89.958357,
    shape_pt_sequence: 150,
    shape_dist_traveled: 8.9456,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221912,
    shape_pt_lon: -89.960668,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.1667,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221757,
    shape_pt_lon: -89.961202,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.2179,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221233,
    shape_pt_lon: -89.963032,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.3932,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221211,
    shape_pt_lon: -89.963139,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.4034,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.963392,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.4269,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221135,
    shape_pt_lon: -89.963619,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.4473,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221095,
    shape_pt_lon: -89.96411,
    shape_pt_sequence: 157,
    shape_dist_traveled: 9.4925,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.2211,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 158,
    shape_dist_traveled: 9.5245,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221109,
    shape_pt_lon: -89.96504,
    shape_pt_sequence: 159,
    shape_dist_traveled: 9.5765,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221116,
    shape_pt_lon: -89.965355,
    shape_pt_sequence: 160,
    shape_dist_traveled: 9.6045,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.965535,
    shape_pt_sequence: 161,
    shape_dist_traveled: 9.6205,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221127,
    shape_pt_lon: -89.965716,
    shape_pt_sequence: 162,
    shape_dist_traveled: 9.6375,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221144,
    shape_pt_lon: -89.966082,
    shape_pt_sequence: 163,
    shape_dist_traveled: 9.6706,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221164,
    shape_pt_lon: -89.966808,
    shape_pt_sequence: 164,
    shape_dist_traveled: 9.7356,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221155,
    shape_pt_lon: -89.967397,
    shape_pt_sequence: 165,
    shape_dist_traveled: 9.7886,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221151,
    shape_pt_lon: -89.967812,
    shape_pt_sequence: 166,
    shape_dist_traveled: 9.8266,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 167,
    shape_dist_traveled: 9.8352,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 168,
    shape_dist_traveled: 9.8512,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 169,
    shape_dist_traveled: 9.8742,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 170,
    shape_dist_traveled: 9.8942,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 171,
    shape_dist_traveled: 9.9043,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 172,
    shape_dist_traveled: 9.9314,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 173,
    shape_dist_traveled: 10.0105,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221312,
    shape_pt_lon: -89.970213,
    shape_pt_sequence: 174,
    shape_dist_traveled: 10.0459,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221331,
    shape_pt_lon: -89.970692,
    shape_pt_sequence: 175,
    shape_dist_traveled: 10.09,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221285,
    shape_pt_lon: -89.972166,
    shape_pt_sequence: 176,
    shape_dist_traveled: 10.2231,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221274,
    shape_pt_lon: -89.972501,
    shape_pt_sequence: 177,
    shape_dist_traveled: 10.2531,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221242,
    shape_pt_lon: -89.973495,
    shape_pt_sequence: 178,
    shape_dist_traveled: 10.3432,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221181,
    shape_pt_lon: -89.974799,
    shape_pt_sequence: 179,
    shape_dist_traveled: 10.4604,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221149,
    shape_pt_lon: -89.975314,
    shape_pt_sequence: 180,
    shape_dist_traveled: 10.5075,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221108,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 181,
    shape_dist_traveled: 10.5291,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.221082,
    shape_pt_lon: -89.975647,
    shape_pt_sequence: 182,
    shape_dist_traveled: 10.5386,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.220987,
    shape_pt_lon: -89.97594,
    shape_pt_sequence: 183,
    shape_dist_traveled: 10.5664,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219907,
    shape_pt_lon: -89.978705,
    shape_pt_sequence: 184,
    shape_dist_traveled: 10.8446,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21949,
    shape_pt_lon: -89.97976,
    shape_pt_sequence: 185,
    shape_dist_traveled: 10.9502,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.219319,
    shape_pt_lon: -89.980199,
    shape_pt_sequence: 186,
    shape_dist_traveled: 10.9945,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218761,
    shape_pt_lon: -89.981611,
    shape_pt_sequence: 187,
    shape_dist_traveled: 11.1362,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218325,
    shape_pt_lon: -89.982717,
    shape_pt_sequence: 188,
    shape_dist_traveled: 11.2476,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.218199,
    shape_pt_lon: -89.982951,
    shape_pt_sequence: 189,
    shape_dist_traveled: 11.2728,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217345,
    shape_pt_lon: -89.984081,
    shape_pt_sequence: 190,
    shape_dist_traveled: 11.4129,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.984318,
    shape_pt_sequence: 191,
    shape_dist_traveled: 11.4381,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21716,
    shape_pt_lon: -89.984482,
    shape_pt_sequence: 192,
    shape_dist_traveled: 11.4543,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217103,
    shape_pt_lon: -89.984657,
    shape_pt_sequence: 193,
    shape_dist_traveled: 11.4718,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217067,
    shape_pt_lon: -89.984879,
    shape_pt_sequence: 194,
    shape_dist_traveled: 11.4922,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217049,
    shape_pt_lon: -89.985043,
    shape_pt_sequence: 195,
    shape_dist_traveled: 11.5073,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217068,
    shape_pt_lon: -89.986281,
    shape_pt_sequence: 196,
    shape_dist_traveled: 11.6183,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217076,
    shape_pt_lon: -89.986569,
    shape_pt_sequence: 197,
    shape_dist_traveled: 11.6443,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217136,
    shape_pt_lon: -89.989798,
    shape_pt_sequence: 198,
    shape_dist_traveled: 11.9364,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 199,
    shape_dist_traveled: 12.1605,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.993016,
    shape_pt_sequence: 200,
    shape_dist_traveled: 12.2265,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217237,
    shape_pt_lon: -89.993565,
    shape_pt_sequence: 201,
    shape_dist_traveled: 12.2766,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217253,
    shape_pt_lon: -89.994126,
    shape_pt_sequence: 202,
    shape_dist_traveled: 12.3266,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21732,
    shape_pt_lon: -89.996025,
    shape_pt_sequence: 203,
    shape_dist_traveled: 12.4988,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21734,
    shape_pt_lon: -89.996802,
    shape_pt_sequence: 204,
    shape_dist_traveled: 12.5688,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217343,
    shape_pt_lon: -89.996938,
    shape_pt_sequence: 205,
    shape_dist_traveled: 12.5808,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217386,
    shape_pt_lon: -89.998142,
    shape_pt_sequence: 206,
    shape_dist_traveled: 12.6899,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217416,
    shape_pt_lon: -89.999249,
    shape_pt_sequence: 207,
    shape_dist_traveled: 12.79,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21743,
    shape_pt_lon: -89.999622,
    shape_pt_sequence: 208,
    shape_dist_traveled: 12.824,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217451,
    shape_pt_lon: -90.000286,
    shape_pt_sequence: 209,
    shape_dist_traveled: 12.8831,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217469,
    shape_pt_lon: -90.000907,
    shape_pt_sequence: 210,
    shape_dist_traveled: 12.9401,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217479,
    shape_pt_lon: -90.001315,
    shape_pt_sequence: 211,
    shape_dist_traveled: 12.9761,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217511,
    shape_pt_lon: -90.002238,
    shape_pt_sequence: 212,
    shape_dist_traveled: 13.0602,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217514,
    shape_pt_lon: -90.002336,
    shape_pt_sequence: 213,
    shape_dist_traveled: 13.0692,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217523,
    shape_pt_lon: -90.002643,
    shape_pt_sequence: 214,
    shape_dist_traveled: 13.0962,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217548,
    shape_pt_lon: -90.003425,
    shape_pt_sequence: 215,
    shape_dist_traveled: 13.1673,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217549,
    shape_pt_lon: -90.003476,
    shape_pt_sequence: 216,
    shape_dist_traveled: 13.1713,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217605,
    shape_pt_lon: -90.004857,
    shape_pt_sequence: 217,
    shape_dist_traveled: 13.2964,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217628,
    shape_pt_lon: -90.005477,
    shape_pt_sequence: 218,
    shape_dist_traveled: 13.3525,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217646,
    shape_pt_lon: -90.005716,
    shape_pt_sequence: 219,
    shape_dist_traveled: 13.3746,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217783,
    shape_pt_lon: -90.007426,
    shape_pt_sequence: 220,
    shape_dist_traveled: 13.5294,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217829,
    shape_pt_lon: -90.008326,
    shape_pt_sequence: 221,
    shape_dist_traveled: 13.6106,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217839,
    shape_pt_lon: -90.009018,
    shape_pt_sequence: 222,
    shape_dist_traveled: 13.6736,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217879,
    shape_pt_lon: -90.012782,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.0126,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.217902,
    shape_pt_lon: -90.014979,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.2116,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216998,
    shape_pt_lon: -90.015006,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.3136,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216812,
    shape_pt_lon: -90.01501,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.3336,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216092,
    shape_pt_lon: -90.015011,
    shape_pt_sequence: 227,
    shape_dist_traveled: 14.4146,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215601,
    shape_pt_lon: -90.015024,
    shape_pt_sequence: 228,
    shape_dist_traveled: 14.4696,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214773,
    shape_pt_lon: -90.015051,
    shape_pt_sequence: 229,
    shape_dist_traveled: 14.5627,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214239,
    shape_pt_lon: -90.015062,
    shape_pt_sequence: 230,
    shape_dist_traveled: 14.6217,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213204,
    shape_pt_lon: -90.015099,
    shape_pt_sequence: 231,
    shape_dist_traveled: 14.7377,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213161,
    shape_pt_lon: -90.015101,
    shape_pt_sequence: 232,
    shape_dist_traveled: 14.7427,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213163,
    shape_pt_lon: -90.015279,
    shape_pt_sequence: 233,
    shape_dist_traveled: 14.7597,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213146,
    shape_pt_lon: -90.015448,
    shape_pt_sequence: 234,
    shape_dist_traveled: 14.7748,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.213049,
    shape_pt_lon: -90.015897,
    shape_pt_sequence: 235,
    shape_dist_traveled: 14.8163,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21296,
    shape_pt_lon: -90.01621,
    shape_pt_sequence: 236,
    shape_dist_traveled: 14.847,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.212856,
    shape_pt_lon: -90.0165,
    shape_pt_sequence: 237,
    shape_dist_traveled: 14.8752,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.212666,
    shape_pt_lon: -90.016907,
    shape_pt_sequence: 238,
    shape_dist_traveled: 14.9169,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.212486,
    shape_pt_lon: -90.017217,
    shape_pt_sequence: 239,
    shape_dist_traveled: 14.9519,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.211707,
    shape_pt_lon: -90.018317,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.0845,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.211094,
    shape_pt_lon: -90.019169,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.1879,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21093,
    shape_pt_lon: -90.019441,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.2179,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210811,
    shape_pt_lon: -90.019688,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.2434,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210706,
    shape_pt_lon: -90.019977,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.272,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210644,
    shape_pt_lon: -90.020199,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.2932,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.2105,
    shape_pt_lon: -90.020999,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.368,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21046,
    shape_pt_lon: -90.021224,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.3886,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210279,
    shape_pt_lon: -90.022058,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.4662,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210177,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 249,
    shape_dist_traveled: 15.5145,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210165,
    shape_pt_lon: -90.022853,
    shape_pt_sequence: 250,
    shape_dist_traveled: 15.5396,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210168,
    shape_pt_lon: -90.022988,
    shape_pt_sequence: 251,
    shape_dist_traveled: 15.5516,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210299,
    shape_pt_lon: -90.024011,
    shape_pt_sequence: 252,
    shape_dist_traveled: 15.6456,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210388,
    shape_pt_lon: -90.02562,
    shape_pt_sequence: 253,
    shape_dist_traveled: 15.791,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210471,
    shape_pt_lon: -90.02728,
    shape_pt_sequence: 254,
    shape_dist_traveled: 15.9413,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210469,
    shape_pt_lon: -90.027451,
    shape_pt_sequence: 255,
    shape_dist_traveled: 15.9563,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21039,
    shape_pt_lon: -90.027929,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.0002,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210372,
    shape_pt_lon: -90.028014,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.0084,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210538,
    shape_pt_lon: -90.028304,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.0406,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210589,
    shape_pt_lon: -90.028388,
    shape_pt_sequence: 259,
    shape_dist_traveled: 16.0506,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210627,
    shape_pt_lon: -90.02845,
    shape_pt_sequence: 260,
    shape_dist_traveled: 16.057,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210699,
    shape_pt_lon: -90.028569,
    shape_pt_sequence: 261,
    shape_dist_traveled: 16.0706,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210803,
    shape_pt_lon: -90.028673,
    shape_pt_sequence: 262,
    shape_dist_traveled: 16.0856,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210851,
    shape_pt_lon: -90.028723,
    shape_pt_sequence: 263,
    shape_dist_traveled: 16.0927,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21095,
    shape_pt_lon: -90.028792,
    shape_pt_sequence: 264,
    shape_dist_traveled: 16.1052,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210991,
    shape_pt_lon: -90.028801,
    shape_pt_sequence: 265,
    shape_dist_traveled: 16.1103,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.211237,
    shape_pt_lon: -90.028797,
    shape_pt_sequence: 266,
    shape_dist_traveled: 16.1373,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.211411,
    shape_pt_lon: -90.028747,
    shape_pt_sequence: 267,
    shape_dist_traveled: 16.158,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.211688,
    shape_pt_lon: -90.028733,
    shape_pt_sequence: 268,
    shape_dist_traveled: 16.189,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21211,
    shape_pt_lon: -90.02873,
    shape_pt_sequence: 269,
    shape_dist_traveled: 16.236,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214265,
    shape_pt_lon: -90.028699,
    shape_pt_sequence: 270,
    shape_dist_traveled: 16.477,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215395,
    shape_pt_lon: -90.028658,
    shape_pt_sequence: 271,
    shape_dist_traveled: 16.6031,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216596,
    shape_pt_lon: -90.028608,
    shape_pt_sequence: 272,
    shape_dist_traveled: 16.7381,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216611,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 273,
    shape_dist_traveled: 16.7921,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21664,
    shape_pt_lon: -90.029488,
    shape_pt_sequence: 274,
    shape_dist_traveled: 16.8174,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216696,
    shape_pt_lon: -90.029728,
    shape_pt_sequence: 275,
    shape_dist_traveled: 16.8402,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216849,
    shape_pt_lon: -90.030192,
    shape_pt_sequence: 276,
    shape_dist_traveled: 16.8856,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216869,
    shape_pt_lon: -90.030248,
    shape_pt_sequence: 277,
    shape_dist_traveled: 16.8909,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21689,
    shape_pt_lon: -90.030311,
    shape_pt_sequence: 278,
    shape_dist_traveled: 16.8976,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.216456,
    shape_pt_lon: -90.030587,
    shape_pt_sequence: 279,
    shape_dist_traveled: 16.9522,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215738,
    shape_pt_lon: -90.030967,
    shape_pt_sequence: 280,
    shape_dist_traveled: 17.0395,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.215248,
    shape_pt_lon: -90.031206,
    shape_pt_sequence: 281,
    shape_dist_traveled: 17.0984,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214917,
    shape_pt_lon: -90.031351,
    shape_pt_sequence: 282,
    shape_dist_traveled: 17.1376,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214744,
    shape_pt_lon: -90.031424,
    shape_pt_sequence: 283,
    shape_dist_traveled: 17.1588,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214347,
    shape_pt_lon: -90.031579,
    shape_pt_sequence: 284,
    shape_dist_traveled: 17.205,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.214192,
    shape_pt_lon: -90.031637,
    shape_pt_sequence: 285,
    shape_dist_traveled: 17.2227,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21313,
    shape_pt_lon: -90.031973,
    shape_pt_sequence: 286,
    shape_dist_traveled: 17.3454,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.212834,
    shape_pt_lon: -90.032054,
    shape_pt_sequence: 287,
    shape_dist_traveled: 17.3794,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.21186,
    shape_pt_lon: -90.032275,
    shape_pt_sequence: 288,
    shape_dist_traveled: 17.4902,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.210702,
    shape_pt_lon: -90.032511,
    shape_pt_sequence: 289,
    shape_dist_traveled: 17.6219,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.20945,
    shape_pt_lon: -90.032765,
    shape_pt_sequence: 290,
    shape_dist_traveled: 17.7638,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.207273,
    shape_pt_lon: -90.033193,
    shape_pt_sequence: 291,
    shape_dist_traveled: 18.0107,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.206342,
    shape_pt_lon: -90.033361,
    shape_pt_sequence: 292,
    shape_dist_traveled: 18.1158,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.20608,
    shape_pt_lon: -90.033414,
    shape_pt_sequence: 293,
    shape_dist_traveled: 18.1452,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.205872,
    shape_pt_lon: -90.033457,
    shape_pt_sequence: 294,
    shape_dist_traveled: 18.1686,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.205616,
    shape_pt_lon: -90.033503,
    shape_pt_sequence: 295,
    shape_dist_traveled: 18.1978,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.204353,
    shape_pt_lon: -90.033756,
    shape_pt_sequence: 296,
    shape_dist_traveled: 18.3407,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.203881,
    shape_pt_lon: -90.03387,
    shape_pt_sequence: 297,
    shape_dist_traveled: 18.3946,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.203444,
    shape_pt_lon: -90.033949,
    shape_pt_sequence: 298,
    shape_dist_traveled: 18.4441,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.203331,
    shape_pt_lon: -90.033969,
    shape_pt_sequence: 299,
    shape_dist_traveled: 18.4573,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.202588,
    shape_pt_lon: -90.034081,
    shape_pt_sequence: 300,
    shape_dist_traveled: 18.5409,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.202277,
    shape_pt_lon: -90.034129,
    shape_pt_sequence: 301,
    shape_dist_traveled: 18.5762,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.202018,
    shape_pt_lon: -90.034177,
    shape_pt_sequence: 302,
    shape_dist_traveled: 18.6055,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.200916,
    shape_pt_lon: -90.034392,
    shape_pt_sequence: 303,
    shape_dist_traveled: 18.73,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199786,
    shape_pt_lon: -90.03459,
    shape_pt_sequence: 304,
    shape_dist_traveled: 18.8582,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199646,
    shape_pt_lon: -90.034613,
    shape_pt_sequence: 305,
    shape_dist_traveled: 18.8734,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199506,
    shape_pt_lon: -90.034635,
    shape_pt_sequence: 306,
    shape_dist_traveled: 18.8895,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199417,
    shape_pt_lon: -90.034648,
    shape_pt_sequence: 307,
    shape_dist_traveled: 18.8995,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199417,
    shape_pt_lon: -90.034644,
    shape_pt_sequence: 308,
    shape_dist_traveled: 18.8995,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199387,
    shape_pt_lon: -90.034647,
    shape_pt_sequence: 309,
    shape_dist_traveled: 18.9025,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199299,
    shape_pt_lon: -90.034656,
    shape_pt_sequence: 310,
    shape_dist_traveled: 18.9126,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.199297,
    shape_pt_lon: -90.034658,
    shape_pt_sequence: 311,
    shape_dist_traveled: 18.9126,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.198499,
    shape_pt_lon: -90.034738,
    shape_pt_sequence: 312,
    shape_dist_traveled: 19.0029,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.197958,
    shape_pt_lon: -90.034784,
    shape_pt_sequence: 313,
    shape_dist_traveled: 19.0631,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.197251,
    shape_pt_lon: -90.034818,
    shape_pt_sequence: 314,
    shape_dist_traveled: 19.1421,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.197128,
    shape_pt_lon: -90.03482,
    shape_pt_sequence: 315,
    shape_dist_traveled: 19.1561,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.196569,
    shape_pt_lon: -90.034869,
    shape_pt_sequence: 316,
    shape_dist_traveled: 19.2193,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.195619,
    shape_pt_lon: -90.034933,
    shape_pt_sequence: 317,
    shape_dist_traveled: 19.3254,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.195562,
    shape_pt_lon: -90.034902,
    shape_pt_sequence: 318,
    shape_dist_traveled: 19.3321,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.195351,
    shape_pt_lon: -90.03491,
    shape_pt_sequence: 319,
    shape_dist_traveled: 19.3562,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.190558,
    shape_pt_lon: -90.035128,
    shape_pt_sequence: 320,
    shape_dist_traveled: 19.8925,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.19033,
    shape_pt_lon: -90.035139,
    shape_pt_sequence: 321,
    shape_dist_traveled: 19.9185,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.190004,
    shape_pt_lon: -90.035157,
    shape_pt_sequence: 322,
    shape_dist_traveled: 19.9546,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.189712,
    shape_pt_lon: -90.035171,
    shape_pt_sequence: 323,
    shape_dist_traveled: 19.9876,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.188725,
    shape_pt_lon: -90.035212,
    shape_pt_sequence: 324,
    shape_dist_traveled: 20.0977,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.188451,
    shape_pt_lon: -90.035225,
    shape_pt_sequence: 325,
    shape_dist_traveled: 20.1287,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.18771,
    shape_pt_lon: -90.035268,
    shape_pt_sequence: 326,
    shape_dist_traveled: 20.2118,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.18765,
    shape_pt_lon: -90.035313,
    shape_pt_sequence: 327,
    shape_dist_traveled: 20.2198,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.186931,
    shape_pt_lon: -90.035332,
    shape_pt_sequence: 328,
    shape_dist_traveled: 20.2999,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.186173,
    shape_pt_lon: -90.035376,
    shape_pt_sequence: 329,
    shape_dist_traveled: 20.3849,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.186116,
    shape_pt_lon: -90.035335,
    shape_pt_sequence: 330,
    shape_dist_traveled: 20.3922,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.182844,
    shape_pt_lon: -90.035507,
    shape_pt_sequence: 331,
    shape_dist_traveled: 20.7585,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.182233,
    shape_pt_lon: -90.035535,
    shape_pt_sequence: 332,
    shape_dist_traveled: 20.8275,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.181619,
    shape_pt_lon: -90.03556,
    shape_pt_sequence: 333,
    shape_dist_traveled: 20.8966,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.180736,
    shape_pt_lon: -90.035611,
    shape_pt_sequence: 334,
    shape_dist_traveled: 20.9947,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.179786,
    shape_pt_lon: -90.035655,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.1017,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.17969,
    shape_pt_lon: -90.03566,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.1118,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.178846,
    shape_pt_lon: -90.035699,
    shape_pt_sequence: 337,
    shape_dist_traveled: 21.2068,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.177904,
    shape_pt_lon: -90.035746,
    shape_pt_sequence: 338,
    shape_dist_traveled: 21.3119,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.17761,
    shape_pt_lon: -90.035758,
    shape_pt_sequence: 339,
    shape_dist_traveled: 21.345,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.177,
    shape_pt_lon: -90.035788,
    shape_pt_sequence: 340,
    shape_dist_traveled: 21.413,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.17546,
    shape_pt_lon: -90.035859,
    shape_pt_sequence: 341,
    shape_dist_traveled: 21.5861,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.174889,
    shape_pt_lon: -90.035888,
    shape_pt_sequence: 342,
    shape_dist_traveled: 21.6502,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.174512,
    shape_pt_lon: -90.035918,
    shape_pt_sequence: 343,
    shape_dist_traveled: 21.6923,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.174473,
    shape_pt_lon: -90.03592,
    shape_pt_sequence: 344,
    shape_dist_traveled: 21.6963,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.173563,
    shape_pt_lon: -90.035966,
    shape_pt_sequence: 345,
    shape_dist_traveled: 21.7983,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.035999,
    shape_pt_sequence: 346,
    shape_dist_traveled: 21.8764,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.172293,
    shape_pt_lon: -90.036027,
    shape_pt_sequence: 347,
    shape_dist_traveled: 21.9405,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.171762,
    shape_pt_lon: -90.036051,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.0005,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.171053,
    shape_pt_lon: -90.036082,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.0796,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.170409,
    shape_pt_lon: -90.036105,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.1516,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.168056,
    shape_pt_lon: -90.036183,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.4147,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.167539,
    shape_pt_lon: -90.036217,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.4728,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.167506,
    shape_pt_lon: -90.036221,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.4768,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.166975,
    shape_pt_lon: -90.036299,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.5362,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.166323,
    shape_pt_lon: -90.036396,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.6097,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.164296,
    shape_pt_lon: -90.036697,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.8383,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.16405,
    shape_pt_lon: -90.036732,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.8665,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.16388,
    shape_pt_lon: -90.036745,
    shape_pt_sequence: 358,
    shape_dist_traveled: 22.8855,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 359,
    shape_dist_traveled: 22.9018,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 360,
    shape_dist_traveled: 22.905,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 361,
    shape_dist_traveled: 22.9117,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 362,
    shape_dist_traveled: 22.9301,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 363,
    shape_dist_traveled: 23.033,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038231,
    shape_pt_sequence: 364,
    shape_dist_traveled: 23.0505,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163219,
    shape_pt_lon: -90.038463,
    shape_pt_sequence: 365,
    shape_dist_traveled: 23.0715,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038662,
    shape_pt_sequence: 366,
    shape_dist_traveled: 23.0895,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163264,
    shape_pt_lon: -90.039227,
    shape_pt_sequence: 367,
    shape_dist_traveled: 23.1407,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163309,
    shape_pt_lon: -90.03998,
    shape_pt_sequence: 368,
    shape_dist_traveled: 23.2089,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163309,
    shape_pt_lon: -90.040046,
    shape_pt_sequence: 369,
    shape_dist_traveled: 23.2149,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163336,
    shape_pt_lon: -90.040334,
    shape_pt_sequence: 370,
    shape_dist_traveled: 23.241,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163389,
    shape_pt_lon: -90.041186,
    shape_pt_sequence: 371,
    shape_dist_traveled: 23.3183,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163398,
    shape_pt_lon: -90.041341,
    shape_pt_sequence: 372,
    shape_dist_traveled: 23.3323,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 373,
    shape_dist_traveled: 23.3404,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163474,
    shape_pt_lon: -90.042521,
    shape_pt_sequence: 374,
    shape_dist_traveled: 23.4397,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 375,
    shape_dist_traveled: 23.4587,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 376,
    shape_dist_traveled: 23.5814,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 377,
    shape_dist_traveled: 23.6981,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 378,
    shape_dist_traveled: 23.8167,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 379,
    shape_dist_traveled: 23.9334,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 380,
    shape_dist_traveled: 23.9891,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 381,
    shape_dist_traveled: 24.0511,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 382,
    shape_dist_traveled: 24.113,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 383,
    shape_dist_traveled: 24.1719,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 384,
    shape_dist_traveled: 24.2854,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.156003,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 385,
    shape_dist_traveled: 24.3379,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.156004,
    shape_pt_lon: -90.045712,
    shape_pt_sequence: 386,
    shape_dist_traveled: 24.3379,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.155521,
    shape_pt_lon: -90.045905,
    shape_pt_sequence: 387,
    shape_dist_traveled: 24.3945,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.15552,
    shape_pt_lon: -90.045902,
    shape_pt_sequence: 388,
    shape_dist_traveled: 24.3945,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.155476,
    shape_pt_lon: -90.04592,
    shape_pt_sequence: 389,
    shape_dist_traveled: 24.3999,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.155678,
    shape_pt_lon: -90.046659,
    shape_pt_sequence: 390,
    shape_dist_traveled: 24.4704,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.155797,
    shape_pt_lon: -90.047162,
    shape_pt_sequence: 391,
    shape_dist_traveled: 24.5175,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.15596,
    shape_pt_lon: -90.047788,
    shape_pt_sequence: 392,
    shape_dist_traveled: 24.5773,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.156018,
    shape_pt_lon: -90.048041,
    shape_pt_sequence: 393,
    shape_dist_traveled: 24.6001,
  },
  {
    shape_id: 38342,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 394,
    shape_dist_traveled: 24.6665,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.156581,
    shape_pt_lon: -90.047809,
    shape_pt_sequence: 1,
    shape_dist_traveled: 0,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.15701,
    shape_pt_lon: -90.04762,
    shape_pt_sequence: 2,
    shape_dist_traveled: 0.0509,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.156843,
    shape_pt_lon: -90.04695,
    shape_pt_sequence: 3,
    shape_dist_traveled: 0.1136,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.156792,
    shape_pt_lon: -90.04677,
    shape_pt_sequence: 4,
    shape_dist_traveled: 0.1307,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.156629,
    shape_pt_lon: -90.046156,
    shape_pt_sequence: 5,
    shape_dist_traveled: 0.1895,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.156452,
    shape_pt_lon: -90.045529,
    shape_pt_sequence: 6,
    shape_dist_traveled: 0.2489,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.157419,
    shape_pt_lon: -90.04515,
    shape_pt_sequence: 7,
    shape_dist_traveled: 0.3625,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.157914,
    shape_pt_lon: -90.044951,
    shape_pt_sequence: 8,
    shape_dist_traveled: 0.4213,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.158448,
    shape_pt_lon: -90.044737,
    shape_pt_sequence: 9,
    shape_dist_traveled: 0.4833,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.158969,
    shape_pt_lon: -90.044528,
    shape_pt_sequence: 10,
    shape_dist_traveled: 0.5453,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.159445,
    shape_pt_lon: -90.044338,
    shape_pt_sequence: 11,
    shape_dist_traveled: 0.6009,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.16044,
    shape_pt_lon: -90.043938,
    shape_pt_sequence: 12,
    shape_dist_traveled: 0.7176,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.161443,
    shape_pt_lon: -90.043536,
    shape_pt_sequence: 13,
    shape_dist_traveled: 0.8362,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.162437,
    shape_pt_lon: -90.043139,
    shape_pt_sequence: 14,
    shape_dist_traveled: 0.9529,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163487,
    shape_pt_lon: -90.042729,
    shape_pt_sequence: 15,
    shape_dist_traveled: 1.0756,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163452,
    shape_pt_lon: -90.042305,
    shape_pt_sequence: 16,
    shape_dist_traveled: 1.1148,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163406,
    shape_pt_lon: -90.04143,
    shape_pt_sequence: 17,
    shape_dist_traveled: 1.194,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163398,
    shape_pt_lon: -90.041341,
    shape_pt_sequence: 18,
    shape_dist_traveled: 1.202,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163389,
    shape_pt_lon: -90.041186,
    shape_pt_sequence: 19,
    shape_dist_traveled: 1.2161,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163336,
    shape_pt_lon: -90.040334,
    shape_pt_sequence: 20,
    shape_dist_traveled: 1.2933,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163309,
    shape_pt_lon: -90.040046,
    shape_pt_sequence: 21,
    shape_dist_traveled: 1.3195,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163309,
    shape_pt_lon: -90.03998,
    shape_pt_sequence: 22,
    shape_dist_traveled: 1.3255,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163264,
    shape_pt_lon: -90.039227,
    shape_pt_sequence: 23,
    shape_dist_traveled: 1.3937,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038662,
    shape_pt_sequence: 24,
    shape_dist_traveled: 1.4448,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163219,
    shape_pt_lon: -90.038463,
    shape_pt_sequence: 25,
    shape_dist_traveled: 1.4628,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163228,
    shape_pt_lon: -90.038231,
    shape_pt_sequence: 26,
    shape_dist_traveled: 1.4839,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.16326,
    shape_pt_lon: -90.038042,
    shape_pt_sequence: 27,
    shape_dist_traveled: 1.5013,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163638,
    shape_pt_lon: -90.036991,
    shape_pt_sequence: 28,
    shape_dist_traveled: 1.6043,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163702,
    shape_pt_lon: -90.036811,
    shape_pt_sequence: 29,
    shape_dist_traveled: 1.6227,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.163722,
    shape_pt_lon: -90.03674,
    shape_pt_sequence: 30,
    shape_dist_traveled: 1.6294,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.16373,
    shape_pt_lon: -90.036712,
    shape_pt_sequence: 31,
    shape_dist_traveled: 1.6325,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.16388,
    shape_pt_lon: -90.036745,
    shape_pt_sequence: 32,
    shape_dist_traveled: 1.6488,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.16405,
    shape_pt_lon: -90.036732,
    shape_pt_sequence: 33,
    shape_dist_traveled: 1.6678,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.164296,
    shape_pt_lon: -90.036697,
    shape_pt_sequence: 34,
    shape_dist_traveled: 1.696,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.166323,
    shape_pt_lon: -90.036396,
    shape_pt_sequence: 35,
    shape_dist_traveled: 1.9246,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.166975,
    shape_pt_lon: -90.036299,
    shape_pt_sequence: 36,
    shape_dist_traveled: 1.9982,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.167506,
    shape_pt_lon: -90.036221,
    shape_pt_sequence: 37,
    shape_dist_traveled: 2.0576,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.167539,
    shape_pt_lon: -90.036217,
    shape_pt_sequence: 38,
    shape_dist_traveled: 2.0616,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.168056,
    shape_pt_lon: -90.036183,
    shape_pt_sequence: 39,
    shape_dist_traveled: 2.1197,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.170409,
    shape_pt_lon: -90.036105,
    shape_pt_sequence: 40,
    shape_dist_traveled: 2.3827,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.171053,
    shape_pt_lon: -90.036082,
    shape_pt_sequence: 41,
    shape_dist_traveled: 2.4548,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.171762,
    shape_pt_lon: -90.036051,
    shape_pt_sequence: 42,
    shape_dist_traveled: 2.5338,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.172293,
    shape_pt_lon: -90.036027,
    shape_pt_sequence: 43,
    shape_dist_traveled: 2.5939,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.17287,
    shape_pt_lon: -90.035999,
    shape_pt_sequence: 44,
    shape_dist_traveled: 2.6579,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.173563,
    shape_pt_lon: -90.035966,
    shape_pt_sequence: 45,
    shape_dist_traveled: 2.736,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.174473,
    shape_pt_lon: -90.03592,
    shape_pt_sequence: 46,
    shape_dist_traveled: 2.8381,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.174512,
    shape_pt_lon: -90.035918,
    shape_pt_sequence: 47,
    shape_dist_traveled: 2.8421,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.174889,
    shape_pt_lon: -90.035888,
    shape_pt_sequence: 48,
    shape_dist_traveled: 2.8842,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.17546,
    shape_pt_lon: -90.035859,
    shape_pt_sequence: 49,
    shape_dist_traveled: 2.9482,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.177,
    shape_pt_lon: -90.035788,
    shape_pt_sequence: 50,
    shape_dist_traveled: 3.1213,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.17761,
    shape_pt_lon: -90.035758,
    shape_pt_sequence: 51,
    shape_dist_traveled: 3.1894,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.177904,
    shape_pt_lon: -90.035746,
    shape_pt_sequence: 52,
    shape_dist_traveled: 3.2224,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.178846,
    shape_pt_lon: -90.035699,
    shape_pt_sequence: 53,
    shape_dist_traveled: 3.3275,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.17969,
    shape_pt_lon: -90.03566,
    shape_pt_sequence: 54,
    shape_dist_traveled: 3.4226,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.179786,
    shape_pt_lon: -90.035655,
    shape_pt_sequence: 55,
    shape_dist_traveled: 3.4326,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.180736,
    shape_pt_lon: -90.035611,
    shape_pt_sequence: 56,
    shape_dist_traveled: 3.5397,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.181619,
    shape_pt_lon: -90.03556,
    shape_pt_sequence: 57,
    shape_dist_traveled: 3.6378,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.182233,
    shape_pt_lon: -90.035535,
    shape_pt_sequence: 58,
    shape_dist_traveled: 3.7068,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.182844,
    shape_pt_lon: -90.035507,
    shape_pt_sequence: 59,
    shape_dist_traveled: 3.7759,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.186116,
    shape_pt_lon: -90.035335,
    shape_pt_sequence: 60,
    shape_dist_traveled: 4.1422,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.18618,
    shape_pt_lon: -90.035282,
    shape_pt_sequence: 61,
    shape_dist_traveled: 4.1508,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.186928,
    shape_pt_lon: -90.035259,
    shape_pt_sequence: 62,
    shape_dist_traveled: 4.2348,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.18765,
    shape_pt_lon: -90.035229,
    shape_pt_sequence: 63,
    shape_dist_traveled: 4.3149,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.18771,
    shape_pt_lon: -90.035268,
    shape_pt_sequence: 64,
    shape_dist_traveled: 4.3229,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.188451,
    shape_pt_lon: -90.035225,
    shape_pt_sequence: 65,
    shape_dist_traveled: 4.406,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.188725,
    shape_pt_lon: -90.035212,
    shape_pt_sequence: 66,
    shape_dist_traveled: 4.437,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.189712,
    shape_pt_lon: -90.035171,
    shape_pt_sequence: 67,
    shape_dist_traveled: 4.5471,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.190004,
    shape_pt_lon: -90.035157,
    shape_pt_sequence: 68,
    shape_dist_traveled: 4.5801,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.19033,
    shape_pt_lon: -90.035139,
    shape_pt_sequence: 69,
    shape_dist_traveled: 4.6162,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.190558,
    shape_pt_lon: -90.035128,
    shape_pt_sequence: 70,
    shape_dist_traveled: 4.6422,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.195351,
    shape_pt_lon: -90.03491,
    shape_pt_sequence: 71,
    shape_dist_traveled: 5.1785,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.195562,
    shape_pt_lon: -90.034902,
    shape_pt_sequence: 72,
    shape_dist_traveled: 5.2026,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.195617,
    shape_pt_lon: -90.034862,
    shape_pt_sequence: 73,
    shape_dist_traveled: 5.2093,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.196201,
    shape_pt_lon: -90.034828,
    shape_pt_sequence: 74,
    shape_dist_traveled: 5.2744,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.196831,
    shape_pt_lon: -90.034723,
    shape_pt_sequence: 75,
    shape_dist_traveled: 5.346,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.197062,
    shape_pt_lon: -90.034692,
    shape_pt_sequence: 76,
    shape_dist_traveled: 5.3721,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.197188,
    shape_pt_lon: -90.034691,
    shape_pt_sequence: 77,
    shape_dist_traveled: 5.3861,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.197842,
    shape_pt_lon: -90.034658,
    shape_pt_sequence: 78,
    shape_dist_traveled: 5.4592,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.199356,
    shape_pt_lon: -90.034522,
    shape_pt_sequence: 79,
    shape_dist_traveled: 5.6286,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.199499,
    shape_pt_lon: -90.034502,
    shape_pt_sequence: 80,
    shape_dist_traveled: 5.6447,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.199809,
    shape_pt_lon: -90.034459,
    shape_pt_sequence: 81,
    shape_dist_traveled: 5.68,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.201403,
    shape_pt_lon: -90.034168,
    shape_pt_sequence: 82,
    shape_dist_traveled: 5.8599,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.2024,
    shape_pt_lon: -90.033967,
    shape_pt_sequence: 83,
    shape_dist_traveled: 5.9733,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.20257,
    shape_pt_lon: -90.033947,
    shape_pt_sequence: 84,
    shape_dist_traveled: 5.9924,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.203031,
    shape_pt_lon: -90.033892,
    shape_pt_sequence: 85,
    shape_dist_traveled: 6.0446,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.203089,
    shape_pt_lon: -90.033882,
    shape_pt_sequence: 86,
    shape_dist_traveled: 6.0507,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.203448,
    shape_pt_lon: -90.033824,
    shape_pt_sequence: 87,
    shape_dist_traveled: 6.091,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.204339,
    shape_pt_lon: -90.033677,
    shape_pt_sequence: 88,
    shape_dist_traveled: 6.1919,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.205609,
    shape_pt_lon: -90.033429,
    shape_pt_sequence: 89,
    shape_dist_traveled: 6.3356,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.205751,
    shape_pt_lon: -90.033406,
    shape_pt_sequence: 90,
    shape_dist_traveled: 6.3518,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.205952,
    shape_pt_lon: -90.033363,
    shape_pt_sequence: 91,
    shape_dist_traveled: 6.3741,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.206247,
    shape_pt_lon: -90.033303,
    shape_pt_sequence: 92,
    shape_dist_traveled: 6.4076,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.206328,
    shape_pt_lon: -90.033288,
    shape_pt_sequence: 93,
    shape_dist_traveled: 6.4176,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.207233,
    shape_pt_lon: -90.033084,
    shape_pt_sequence: 94,
    shape_dist_traveled: 6.5204,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.2086,
    shape_pt_lon: -90.032816,
    shape_pt_sequence: 95,
    shape_dist_traveled: 6.6753,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.20949,
    shape_pt_lon: -90.032639,
    shape_pt_sequence: 96,
    shape_dist_traveled: 6.7756,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.209857,
    shape_pt_lon: -90.032566,
    shape_pt_sequence: 97,
    shape_dist_traveled: 6.817,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210687,
    shape_pt_lon: -90.032398,
    shape_pt_sequence: 98,
    shape_dist_traveled: 6.9112,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211859,
    shape_pt_lon: -90.032155,
    shape_pt_sequence: 99,
    shape_dist_traveled: 7.045,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.212386,
    shape_pt_lon: -90.032047,
    shape_pt_sequence: 100,
    shape_dist_traveled: 7.1049,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213117,
    shape_pt_lon: -90.031866,
    shape_pt_sequence: 101,
    shape_dist_traveled: 7.1874,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214085,
    shape_pt_lon: -90.031591,
    shape_pt_sequence: 102,
    shape_dist_traveled: 7.2993,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214345,
    shape_pt_lon: -90.031496,
    shape_pt_sequence: 103,
    shape_dist_traveled: 7.3296,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214894,
    shape_pt_lon: -90.031278,
    shape_pt_sequence: 104,
    shape_dist_traveled: 7.3935,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21552,
    shape_pt_lon: -90.030987,
    shape_pt_sequence: 105,
    shape_dist_traveled: 7.4682,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.215709,
    shape_pt_lon: -90.030889,
    shape_pt_sequence: 106,
    shape_dist_traveled: 7.491,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216218,
    shape_pt_lon: -90.030601,
    shape_pt_sequence: 107,
    shape_dist_traveled: 7.5537,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216548,
    shape_pt_lon: -90.03038,
    shape_pt_sequence: 108,
    shape_dist_traveled: 7.5957,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216849,
    shape_pt_lon: -90.030192,
    shape_pt_sequence: 109,
    shape_dist_traveled: 7.6338,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216696,
    shape_pt_lon: -90.029728,
    shape_pt_sequence: 110,
    shape_dist_traveled: 7.6791,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21664,
    shape_pt_lon: -90.029488,
    shape_pt_sequence: 111,
    shape_dist_traveled: 7.7019,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216611,
    shape_pt_lon: -90.02921,
    shape_pt_sequence: 112,
    shape_dist_traveled: 7.7272,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216596,
    shape_pt_lon: -90.028608,
    shape_pt_sequence: 113,
    shape_dist_traveled: 7.7812,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.215395,
    shape_pt_lon: -90.028658,
    shape_pt_sequence: 114,
    shape_dist_traveled: 7.9163,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214265,
    shape_pt_lon: -90.028699,
    shape_pt_sequence: 115,
    shape_dist_traveled: 8.0423,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21211,
    shape_pt_lon: -90.02873,
    shape_pt_sequence: 116,
    shape_dist_traveled: 8.2833,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211688,
    shape_pt_lon: -90.028733,
    shape_pt_sequence: 117,
    shape_dist_traveled: 8.3303,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211411,
    shape_pt_lon: -90.028747,
    shape_pt_sequence: 118,
    shape_dist_traveled: 8.3614,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211237,
    shape_pt_lon: -90.028797,
    shape_pt_sequence: 119,
    shape_dist_traveled: 8.382,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210991,
    shape_pt_lon: -90.028801,
    shape_pt_sequence: 120,
    shape_dist_traveled: 8.409,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21095,
    shape_pt_lon: -90.028792,
    shape_pt_sequence: 121,
    shape_dist_traveled: 8.4141,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210851,
    shape_pt_lon: -90.028723,
    shape_pt_sequence: 122,
    shape_dist_traveled: 8.4266,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210803,
    shape_pt_lon: -90.028673,
    shape_pt_sequence: 123,
    shape_dist_traveled: 8.4337,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210699,
    shape_pt_lon: -90.028569,
    shape_pt_sequence: 124,
    shape_dist_traveled: 8.4487,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210627,
    shape_pt_lon: -90.02845,
    shape_pt_sequence: 125,
    shape_dist_traveled: 8.4623,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210589,
    shape_pt_lon: -90.028388,
    shape_pt_sequence: 126,
    shape_dist_traveled: 8.4687,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210538,
    shape_pt_lon: -90.028304,
    shape_pt_sequence: 127,
    shape_dist_traveled: 8.4787,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210372,
    shape_pt_lon: -90.028014,
    shape_pt_sequence: 128,
    shape_dist_traveled: 8.5109,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21039,
    shape_pt_lon: -90.027929,
    shape_pt_sequence: 129,
    shape_dist_traveled: 8.5191,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210469,
    shape_pt_lon: -90.027451,
    shape_pt_sequence: 130,
    shape_dist_traveled: 8.5631,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210471,
    shape_pt_lon: -90.02728,
    shape_pt_sequence: 131,
    shape_dist_traveled: 8.5781,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210388,
    shape_pt_lon: -90.02562,
    shape_pt_sequence: 132,
    shape_dist_traveled: 8.7283,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210299,
    shape_pt_lon: -90.024011,
    shape_pt_sequence: 133,
    shape_dist_traveled: 8.8737,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210168,
    shape_pt_lon: -90.022988,
    shape_pt_sequence: 134,
    shape_dist_traveled: 8.9677,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210165,
    shape_pt_lon: -90.022853,
    shape_pt_sequence: 135,
    shape_dist_traveled: 8.9798,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210177,
    shape_pt_lon: -90.022581,
    shape_pt_sequence: 136,
    shape_dist_traveled: 9.0048,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210279,
    shape_pt_lon: -90.022058,
    shape_pt_sequence: 137,
    shape_dist_traveled: 9.0531,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21046,
    shape_pt_lon: -90.021224,
    shape_pt_sequence: 138,
    shape_dist_traveled: 9.1307,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.2105,
    shape_pt_lon: -90.020999,
    shape_pt_sequence: 139,
    shape_dist_traveled: 9.1514,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210644,
    shape_pt_lon: -90.020199,
    shape_pt_sequence: 140,
    shape_dist_traveled: 9.2261,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210706,
    shape_pt_lon: -90.019977,
    shape_pt_sequence: 141,
    shape_dist_traveled: 9.2473,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.210811,
    shape_pt_lon: -90.019688,
    shape_pt_sequence: 142,
    shape_dist_traveled: 9.2759,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21093,
    shape_pt_lon: -90.019441,
    shape_pt_sequence: 143,
    shape_dist_traveled: 9.3015,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211094,
    shape_pt_lon: -90.019169,
    shape_pt_sequence: 144,
    shape_dist_traveled: 9.3315,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.211707,
    shape_pt_lon: -90.018317,
    shape_pt_sequence: 145,
    shape_dist_traveled: 9.4349,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.212486,
    shape_pt_lon: -90.017217,
    shape_pt_sequence: 146,
    shape_dist_traveled: 9.5674,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.212666,
    shape_pt_lon: -90.016907,
    shape_pt_sequence: 147,
    shape_dist_traveled: 9.6024,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.212856,
    shape_pt_lon: -90.0165,
    shape_pt_sequence: 148,
    shape_dist_traveled: 9.6441,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21296,
    shape_pt_lon: -90.01621,
    shape_pt_sequence: 149,
    shape_dist_traveled: 9.6723,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213049,
    shape_pt_lon: -90.015897,
    shape_pt_sequence: 150,
    shape_dist_traveled: 9.703,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213146,
    shape_pt_lon: -90.015448,
    shape_pt_sequence: 151,
    shape_dist_traveled: 9.7445,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213163,
    shape_pt_lon: -90.015279,
    shape_pt_sequence: 152,
    shape_dist_traveled: 9.7596,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213161,
    shape_pt_lon: -90.015101,
    shape_pt_sequence: 153,
    shape_dist_traveled: 9.7766,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.213204,
    shape_pt_lon: -90.015099,
    shape_pt_sequence: 154,
    shape_dist_traveled: 9.7816,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214239,
    shape_pt_lon: -90.015062,
    shape_pt_sequence: 155,
    shape_dist_traveled: 9.8976,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.214773,
    shape_pt_lon: -90.015051,
    shape_pt_sequence: 156,
    shape_dist_traveled: 9.9567,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.215601,
    shape_pt_lon: -90.015024,
    shape_pt_sequence: 157,
    shape_dist_traveled: 10.0497,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216092,
    shape_pt_lon: -90.015011,
    shape_pt_sequence: 158,
    shape_dist_traveled: 10.1047,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216812,
    shape_pt_lon: -90.01501,
    shape_pt_sequence: 159,
    shape_dist_traveled: 10.1857,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.216998,
    shape_pt_lon: -90.015006,
    shape_pt_sequence: 160,
    shape_dist_traveled: 10.2057,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217902,
    shape_pt_lon: -90.014979,
    shape_pt_sequence: 161,
    shape_dist_traveled: 10.3077,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217879,
    shape_pt_lon: -90.012782,
    shape_pt_sequence: 162,
    shape_dist_traveled: 10.5067,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217839,
    shape_pt_lon: -90.009018,
    shape_pt_sequence: 163,
    shape_dist_traveled: 10.8458,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217829,
    shape_pt_lon: -90.008326,
    shape_pt_sequence: 164,
    shape_dist_traveled: 10.9088,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217783,
    shape_pt_lon: -90.007426,
    shape_pt_sequence: 165,
    shape_dist_traveled: 10.9899,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217646,
    shape_pt_lon: -90.005716,
    shape_pt_sequence: 166,
    shape_dist_traveled: 11.1447,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217628,
    shape_pt_lon: -90.005477,
    shape_pt_sequence: 167,
    shape_dist_traveled: 11.1668,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217605,
    shape_pt_lon: -90.004857,
    shape_pt_sequence: 168,
    shape_dist_traveled: 11.2229,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217549,
    shape_pt_lon: -90.003476,
    shape_pt_sequence: 169,
    shape_dist_traveled: 11.348,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217548,
    shape_pt_lon: -90.003425,
    shape_pt_sequence: 170,
    shape_dist_traveled: 11.352,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217523,
    shape_pt_lon: -90.002643,
    shape_pt_sequence: 171,
    shape_dist_traveled: 11.4231,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217514,
    shape_pt_lon: -90.002336,
    shape_pt_sequence: 172,
    shape_dist_traveled: 11.4501,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217511,
    shape_pt_lon: -90.002238,
    shape_pt_sequence: 173,
    shape_dist_traveled: 11.4591,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217479,
    shape_pt_lon: -90.001315,
    shape_pt_sequence: 174,
    shape_dist_traveled: 11.5432,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217469,
    shape_pt_lon: -90.000907,
    shape_pt_sequence: 175,
    shape_dist_traveled: 11.5792,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217451,
    shape_pt_lon: -90.000286,
    shape_pt_sequence: 176,
    shape_dist_traveled: 11.6362,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21743,
    shape_pt_lon: -89.999622,
    shape_pt_sequence: 177,
    shape_dist_traveled: 11.6953,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217416,
    shape_pt_lon: -89.999249,
    shape_pt_sequence: 178,
    shape_dist_traveled: 11.7293,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217386,
    shape_pt_lon: -89.998142,
    shape_pt_sequence: 179,
    shape_dist_traveled: 11.8294,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217343,
    shape_pt_lon: -89.996938,
    shape_pt_sequence: 180,
    shape_dist_traveled: 11.9385,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21734,
    shape_pt_lon: -89.996802,
    shape_pt_sequence: 181,
    shape_dist_traveled: 11.9505,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21732,
    shape_pt_lon: -89.996025,
    shape_pt_sequence: 182,
    shape_dist_traveled: 12.0205,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217253,
    shape_pt_lon: -89.994126,
    shape_pt_sequence: 183,
    shape_dist_traveled: 12.1927,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217237,
    shape_pt_lon: -89.993565,
    shape_pt_sequence: 184,
    shape_dist_traveled: 12.2428,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.993016,
    shape_pt_sequence: 185,
    shape_dist_traveled: 12.2928,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217199,
    shape_pt_lon: -89.992281,
    shape_pt_sequence: 186,
    shape_dist_traveled: 12.3588,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217136,
    shape_pt_lon: -89.989798,
    shape_pt_sequence: 187,
    shape_dist_traveled: 12.5829,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217076,
    shape_pt_lon: -89.986569,
    shape_pt_sequence: 188,
    shape_dist_traveled: 12.875,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217068,
    shape_pt_lon: -89.986281,
    shape_pt_sequence: 189,
    shape_dist_traveled: 12.901,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217049,
    shape_pt_lon: -89.985043,
    shape_pt_sequence: 190,
    shape_dist_traveled: 13.012,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217067,
    shape_pt_lon: -89.984879,
    shape_pt_sequence: 191,
    shape_dist_traveled: 13.0272,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217103,
    shape_pt_lon: -89.984657,
    shape_pt_sequence: 192,
    shape_dist_traveled: 13.0476,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21716,
    shape_pt_lon: -89.984482,
    shape_pt_sequence: 193,
    shape_dist_traveled: 13.065,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217219,
    shape_pt_lon: -89.984318,
    shape_pt_sequence: 194,
    shape_dist_traveled: 13.0812,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.217345,
    shape_pt_lon: -89.984081,
    shape_pt_sequence: 195,
    shape_dist_traveled: 13.1064,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.218199,
    shape_pt_lon: -89.982951,
    shape_pt_sequence: 196,
    shape_dist_traveled: 13.2465,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.218325,
    shape_pt_lon: -89.982717,
    shape_pt_sequence: 197,
    shape_dist_traveled: 13.2717,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.218761,
    shape_pt_lon: -89.981611,
    shape_pt_sequence: 198,
    shape_dist_traveled: 13.3831,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.219319,
    shape_pt_lon: -89.980199,
    shape_pt_sequence: 199,
    shape_dist_traveled: 13.5249,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.21949,
    shape_pt_lon: -89.97976,
    shape_pt_sequence: 200,
    shape_dist_traveled: 13.5691,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.219907,
    shape_pt_lon: -89.978705,
    shape_pt_sequence: 201,
    shape_dist_traveled: 13.6747,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220987,
    shape_pt_lon: -89.97594,
    shape_pt_sequence: 202,
    shape_dist_traveled: 13.9529,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221082,
    shape_pt_lon: -89.975647,
    shape_pt_sequence: 203,
    shape_dist_traveled: 13.9807,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221108,
    shape_pt_lon: -89.975552,
    shape_pt_sequence: 204,
    shape_dist_traveled: 13.9902,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221149,
    shape_pt_lon: -89.975314,
    shape_pt_sequence: 205,
    shape_dist_traveled: 14.0118,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221181,
    shape_pt_lon: -89.974799,
    shape_pt_sequence: 206,
    shape_dist_traveled: 14.0589,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221242,
    shape_pt_lon: -89.973495,
    shape_pt_sequence: 207,
    shape_dist_traveled: 14.1761,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221274,
    shape_pt_lon: -89.972501,
    shape_pt_sequence: 208,
    shape_dist_traveled: 14.2662,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221285,
    shape_pt_lon: -89.972166,
    shape_pt_sequence: 209,
    shape_dist_traveled: 14.2962,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221331,
    shape_pt_lon: -89.970692,
    shape_pt_sequence: 210,
    shape_dist_traveled: 14.4293,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221312,
    shape_pt_lon: -89.970213,
    shape_pt_sequence: 211,
    shape_dist_traveled: 14.4734,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221261,
    shape_pt_lon: -89.969825,
    shape_pt_sequence: 212,
    shape_dist_traveled: 14.5088,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221148,
    shape_pt_lon: -89.968961,
    shape_pt_sequence: 213,
    shape_dist_traveled: 14.5879,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22112,
    shape_pt_lon: -89.968659,
    shape_pt_sequence: 214,
    shape_dist_traveled: 14.615,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.968541,
    shape_pt_sequence: 215,
    shape_dist_traveled: 14.6251,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221106,
    shape_pt_lon: -89.968321,
    shape_pt_sequence: 216,
    shape_dist_traveled: 14.6451,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22111,
    shape_pt_lon: -89.968066,
    shape_pt_sequence: 217,
    shape_dist_traveled: 14.6681,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221113,
    shape_pt_lon: -89.967888,
    shape_pt_sequence: 218,
    shape_dist_traveled: 14.6841,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221076,
    shape_pt_lon: -89.967813,
    shape_pt_sequence: 219,
    shape_dist_traveled: 14.6922,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221092,
    shape_pt_lon: -89.967357,
    shape_pt_sequence: 220,
    shape_dist_traveled: 14.7332,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221097,
    shape_pt_lon: -89.966533,
    shape_pt_sequence: 221,
    shape_dist_traveled: 14.8082,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221061,
    shape_pt_lon: -89.965725,
    shape_pt_sequence: 222,
    shape_dist_traveled: 14.8813,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221042,
    shape_pt_lon: -89.965379,
    shape_pt_sequence: 223,
    shape_dist_traveled: 14.9124,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221019,
    shape_pt_lon: -89.965061,
    shape_pt_sequence: 224,
    shape_dist_traveled: 14.9415,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220992,
    shape_pt_lon: -89.964681,
    shape_pt_sequence: 225,
    shape_dist_traveled: 14.9757,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220989,
    shape_pt_lon: -89.96447,
    shape_pt_sequence: 226,
    shape_dist_traveled: 14.9947,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220983,
    shape_pt_lon: -89.964225,
    shape_pt_sequence: 227,
    shape_dist_traveled: 15.0167,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221001,
    shape_pt_lon: -89.963776,
    shape_pt_sequence: 228,
    shape_dist_traveled: 15.0577,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221062,
    shape_pt_lon: -89.9633,
    shape_pt_sequence: 229,
    shape_dist_traveled: 15.1013,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221096,
    shape_pt_lon: -89.963137,
    shape_pt_sequence: 230,
    shape_dist_traveled: 15.1159,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221119,
    shape_pt_lon: -89.963036,
    shape_pt_sequence: 231,
    shape_dist_traveled: 15.1254,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221201,
    shape_pt_lon: -89.962697,
    shape_pt_sequence: 232,
    shape_dist_traveled: 15.1576,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221482,
    shape_pt_lon: -89.961733,
    shape_pt_sequence: 233,
    shape_dist_traveled: 15.25,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221542,
    shape_pt_lon: -89.961491,
    shape_pt_sequence: 234,
    shape_dist_traveled: 15.2731,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221626,
    shape_pt_lon: -89.961249,
    shape_pt_sequence: 235,
    shape_dist_traveled: 15.2969,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221648,
    shape_pt_lon: -89.961159,
    shape_pt_sequence: 236,
    shape_dist_traveled: 15.3054,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221804,
    shape_pt_lon: -89.960628,
    shape_pt_sequence: 237,
    shape_dist_traveled: 15.3563,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222402,
    shape_pt_lon: -89.958553,
    shape_pt_sequence: 238,
    shape_dist_traveled: 15.5553,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222527,
    shape_pt_lon: -89.958079,
    shape_pt_sequence: 239,
    shape_dist_traveled: 15.6005,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222606,
    shape_pt_lon: -89.957658,
    shape_pt_sequence: 240,
    shape_dist_traveled: 15.6394,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22265,
    shape_pt_lon: -89.957183,
    shape_pt_sequence: 241,
    shape_dist_traveled: 15.6816,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222677,
    shape_pt_lon: -89.956711,
    shape_pt_sequence: 242,
    shape_dist_traveled: 15.7248,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222709,
    shape_pt_lon: -89.956362,
    shape_pt_sequence: 243,
    shape_dist_traveled: 15.757,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222717,
    shape_pt_lon: -89.956258,
    shape_pt_sequence: 244,
    shape_dist_traveled: 15.766,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222723,
    shape_pt_lon: -89.956131,
    shape_pt_sequence: 245,
    shape_dist_traveled: 15.7771,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222842,
    shape_pt_lon: -89.956096,
    shape_pt_sequence: 246,
    shape_dist_traveled: 15.7907,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22358,
    shape_pt_lon: -89.95591,
    shape_pt_sequence: 247,
    shape_dist_traveled: 15.8752,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.224417,
    shape_pt_lon: -89.955717,
    shape_pt_sequence: 248,
    shape_dist_traveled: 15.9699,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.225346,
    shape_pt_lon: -89.955505,
    shape_pt_sequence: 249,
    shape_dist_traveled: 16.0757,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.226409,
    shape_pt_lon: -89.955249,
    shape_pt_sequence: 250,
    shape_dist_traveled: 16.1969,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22741,
    shape_pt_lon: -89.955025,
    shape_pt_sequence: 251,
    shape_dist_traveled: 16.3106,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.228384,
    shape_pt_lon: -89.954791,
    shape_pt_sequence: 252,
    shape_dist_traveled: 16.4216,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22942,
    shape_pt_lon: -89.954546,
    shape_pt_sequence: 253,
    shape_dist_traveled: 16.5397,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.230295,
    shape_pt_lon: -89.954345,
    shape_pt_sequence: 254,
    shape_dist_traveled: 16.6393,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.230742,
    shape_pt_lon: -89.954223,
    shape_pt_sequence: 255,
    shape_dist_traveled: 16.6905,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.231108,
    shape_pt_lon: -89.954067,
    shape_pt_sequence: 256,
    shape_dist_traveled: 16.7339,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.231948,
    shape_pt_lon: -89.9537,
    shape_pt_sequence: 257,
    shape_dist_traveled: 16.8335,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232118,
    shape_pt_lon: -89.953622,
    shape_pt_sequence: 258,
    shape_dist_traveled: 16.8537,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232218,
    shape_pt_lon: -89.955461,
    shape_pt_sequence: 259,
    shape_dist_traveled: 17.0201,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232158,
    shape_pt_lon: -89.958199,
    shape_pt_sequence: 260,
    shape_dist_traveled: 17.2672,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232122,
    shape_pt_lon: -89.958199,
    shape_pt_sequence: 261,
    shape_dist_traveled: 17.2712,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232131,
    shape_pt_lon: -89.957944,
    shape_pt_sequence: 262,
    shape_dist_traveled: 17.2942,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232148,
    shape_pt_lon: -89.956991,
    shape_pt_sequence: 263,
    shape_dist_traveled: 17.3802,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232165,
    shape_pt_lon: -89.956226,
    shape_pt_sequence: 264,
    shape_dist_traveled: 17.4493,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232182,
    shape_pt_lon: -89.955661,
    shape_pt_sequence: 265,
    shape_dist_traveled: 17.5003,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232191,
    shape_pt_lon: -89.955439,
    shape_pt_sequence: 266,
    shape_dist_traveled: 17.5203,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232164,
    shape_pt_lon: -89.954963,
    shape_pt_sequence: 267,
    shape_dist_traveled: 17.5634,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.232136,
    shape_pt_lon: -89.954542,
    shape_pt_sequence: 268,
    shape_dist_traveled: 17.6016,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.2321,
    shape_pt_lon: -89.953921,
    shape_pt_sequence: 269,
    shape_dist_traveled: 17.6577,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.231779,
    shape_pt_lon: -89.954066,
    shape_pt_sequence: 270,
    shape_dist_traveled: 17.696,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.231082,
    shape_pt_lon: -89.954366,
    shape_pt_sequence: 271,
    shape_dist_traveled: 17.7785,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.230725,
    shape_pt_lon: -89.9545,
    shape_pt_sequence: 272,
    shape_dist_traveled: 17.8203,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.230503,
    shape_pt_lon: -89.954569,
    shape_pt_sequence: 273,
    shape_dist_traveled: 17.8453,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.229858,
    shape_pt_lon: -89.954734,
    shape_pt_sequence: 274,
    shape_dist_traveled: 17.9196,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.229108,
    shape_pt_lon: -89.95489,
    shape_pt_sequence: 275,
    shape_dist_traveled: 18.0048,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.228384,
    shape_pt_lon: -89.955057,
    shape_pt_sequence: 276,
    shape_dist_traveled: 18.0871,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22791,
    shape_pt_lon: -89.955158,
    shape_pt_sequence: 277,
    shape_dist_traveled: 18.1409,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.227214,
    shape_pt_lon: -89.955325,
    shape_pt_sequence: 278,
    shape_dist_traveled: 18.2203,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.226347,
    shape_pt_lon: -89.955526,
    shape_pt_sequence: 279,
    shape_dist_traveled: 18.319,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.225364,
    shape_pt_lon: -89.95576,
    shape_pt_sequence: 280,
    shape_dist_traveled: 18.431,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.224122,
    shape_pt_lon: -89.95605,
    shape_pt_sequence: 281,
    shape_dist_traveled: 18.5724,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222709,
    shape_pt_lon: -89.956362,
    shape_pt_sequence: 282,
    shape_dist_traveled: 18.7328,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222737,
    shape_pt_lon: -89.955919,
    shape_pt_sequence: 283,
    shape_dist_traveled: 18.7328,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222711,
    shape_pt_lon: -89.956362,
    shape_pt_sequence: 284,
    shape_dist_traveled: 18.773,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222737,
    shape_pt_lon: -89.955919,
    shape_pt_sequence: 285,
    shape_dist_traveled: 18.8131,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222709,
    shape_pt_lon: -89.956362,
    shape_pt_sequence: 286,
    shape_dist_traveled: 18.8131,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222717,
    shape_pt_lon: -89.956258,
    shape_pt_sequence: 287,
    shape_dist_traveled: 18.8221,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222723,
    shape_pt_lon: -89.956131,
    shape_pt_sequence: 288,
    shape_dist_traveled: 18.8332,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222752,
    shape_pt_lon: -89.955676,
    shape_pt_sequence: 289,
    shape_dist_traveled: 18.8743,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222852,
    shape_pt_lon: -89.95443,
    shape_pt_sequence: 290,
    shape_dist_traveled: 18.9878,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22292,
    shape_pt_lon: -89.953436,
    shape_pt_sequence: 291,
    shape_dist_traveled: 19.0782,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223026,
    shape_pt_lon: -89.952138,
    shape_pt_sequence: 292,
    shape_dist_traveled: 19.1958,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223111,
    shape_pt_lon: -89.951611,
    shape_pt_sequence: 293,
    shape_dist_traveled: 19.2438,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223331,
    shape_pt_lon: -89.950743,
    shape_pt_sequence: 294,
    shape_dist_traveled: 19.3264,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22339,
    shape_pt_lon: -89.95047,
    shape_pt_sequence: 295,
    shape_dist_traveled: 19.3514,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223447,
    shape_pt_lon: -89.950094,
    shape_pt_sequence: 296,
    shape_dist_traveled: 19.3859,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22346,
    shape_pt_lon: -89.949993,
    shape_pt_sequence: 297,
    shape_dist_traveled: 19.3951,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223466,
    shape_pt_lon: -89.949934,
    shape_pt_sequence: 298,
    shape_dist_traveled: 19.4012,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223482,
    shape_pt_lon: -89.94976,
    shape_pt_sequence: 299,
    shape_dist_traveled: 19.4163,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223524,
    shape_pt_lon: -89.949693,
    shape_pt_sequence: 300,
    shape_dist_traveled: 19.4241,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223531,
    shape_pt_lon: -89.94958,
    shape_pt_sequence: 301,
    shape_dist_traveled: 19.4351,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223582,
    shape_pt_lon: -89.947711,
    shape_pt_sequence: 302,
    shape_dist_traveled: 19.6032,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223576,
    shape_pt_lon: -89.947396,
    shape_pt_sequence: 303,
    shape_dist_traveled: 19.6322,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223564,
    shape_pt_lon: -89.947272,
    shape_pt_sequence: 304,
    shape_dist_traveled: 19.6433,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223558,
    shape_pt_lon: -89.94719,
    shape_pt_sequence: 305,
    shape_dist_traveled: 19.6504,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22352,
    shape_pt_lon: -89.946899,
    shape_pt_sequence: 306,
    shape_dist_traveled: 19.6776,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223476,
    shape_pt_lon: -89.946676,
    shape_pt_sequence: 307,
    shape_dist_traveled: 19.6983,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223339,
    shape_pt_lon: -89.946096,
    shape_pt_sequence: 308,
    shape_dist_traveled: 19.7524,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223267,
    shape_pt_lon: -89.945759,
    shape_pt_sequence: 309,
    shape_dist_traveled: 19.7834,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223225,
    shape_pt_lon: -89.945517,
    shape_pt_sequence: 310,
    shape_dist_traveled: 19.806,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223182,
    shape_pt_lon: -89.945031,
    shape_pt_sequence: 311,
    shape_dist_traveled: 19.8503,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.2231,
    shape_pt_lon: -89.941153,
    shape_pt_sequence: 312,
    shape_dist_traveled: 20.2004,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223081,
    shape_pt_lon: -89.940179,
    shape_pt_sequence: 313,
    shape_dist_traveled: 20.2884,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223043,
    shape_pt_lon: -89.938234,
    shape_pt_sequence: 314,
    shape_dist_traveled: 20.4644,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223032,
    shape_pt_lon: -89.937811,
    shape_pt_sequence: 315,
    shape_dist_traveled: 20.5025,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.223017,
    shape_pt_lon: -89.936447,
    shape_pt_sequence: 316,
    shape_dist_traveled: 20.6255,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222998,
    shape_pt_lon: -89.93501,
    shape_pt_sequence: 317,
    shape_dist_traveled: 20.7555,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222958,
    shape_pt_lon: -89.933815,
    shape_pt_sequence: 318,
    shape_dist_traveled: 20.8636,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222919,
    shape_pt_lon: -89.9335,
    shape_pt_sequence: 319,
    shape_dist_traveled: 20.892,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222888,
    shape_pt_lon: -89.933387,
    shape_pt_sequence: 320,
    shape_dist_traveled: 20.9025,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222751,
    shape_pt_lon: -89.933102,
    shape_pt_sequence: 321,
    shape_dist_traveled: 20.9325,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.222495,
    shape_pt_lon: -89.932685,
    shape_pt_sequence: 322,
    shape_dist_traveled: 20.9803,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221957,
    shape_pt_lon: -89.931877,
    shape_pt_sequence: 323,
    shape_dist_traveled: 21.0748,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221427,
    shape_pt_lon: -89.931195,
    shape_pt_sequence: 324,
    shape_dist_traveled: 21.1596,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221272,
    shape_pt_lon: -89.930961,
    shape_pt_sequence: 325,
    shape_dist_traveled: 21.1873,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221169,
    shape_pt_lon: -89.93075,
    shape_pt_sequence: 326,
    shape_dist_traveled: 21.2093,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22109,
    shape_pt_lon: -89.93055,
    shape_pt_sequence: 327,
    shape_dist_traveled: 21.2303,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22102,
    shape_pt_lon: -89.93031,
    shape_pt_sequence: 328,
    shape_dist_traveled: 21.2528,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22095,
    shape_pt_lon: -89.929938,
    shape_pt_sequence: 329,
    shape_dist_traveled: 21.2877,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22075,
    shape_pt_lon: -89.928697,
    shape_pt_sequence: 330,
    shape_dist_traveled: 21.4018,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220587,
    shape_pt_lon: -89.92784,
    shape_pt_sequence: 331,
    shape_dist_traveled: 21.4809,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22055,
    shape_pt_lon: -89.927511,
    shape_pt_sequence: 332,
    shape_dist_traveled: 21.5112,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220537,
    shape_pt_lon: -89.927197,
    shape_pt_sequence: 333,
    shape_dist_traveled: 21.5392,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220493,
    shape_pt_lon: -89.924727,
    shape_pt_sequence: 334,
    shape_dist_traveled: 21.7622,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220474,
    shape_pt_lon: -89.923727,
    shape_pt_sequence: 335,
    shape_dist_traveled: 21.8523,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220459,
    shape_pt_lon: -89.922708,
    shape_pt_sequence: 336,
    shape_dist_traveled: 21.9443,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220439,
    shape_pt_lon: -89.921687,
    shape_pt_sequence: 337,
    shape_dist_traveled: 22.0373,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220449,
    shape_pt_lon: -89.921348,
    shape_pt_sequence: 338,
    shape_dist_traveled: 22.0674,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22049,
    shape_pt_lon: -89.921022,
    shape_pt_sequence: 339,
    shape_dist_traveled: 22.0976,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220551,
    shape_pt_lon: -89.920756,
    shape_pt_sequence: 340,
    shape_dist_traveled: 22.1226,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220641,
    shape_pt_lon: -89.920478,
    shape_pt_sequence: 341,
    shape_dist_traveled: 22.1496,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220759,
    shape_pt_lon: -89.920209,
    shape_pt_sequence: 342,
    shape_dist_traveled: 22.1769,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.220863,
    shape_pt_lon: -89.920017,
    shape_pt_sequence: 343,
    shape_dist_traveled: 22.1977,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221067,
    shape_pt_lon: -89.919716,
    shape_pt_sequence: 344,
    shape_dist_traveled: 22.2331,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221316,
    shape_pt_lon: -89.919367,
    shape_pt_sequence: 345,
    shape_dist_traveled: 22.2757,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221507,
    shape_pt_lon: -89.919076,
    shape_pt_sequence: 346,
    shape_dist_traveled: 22.3091,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221623,
    shape_pt_lon: -89.918843,
    shape_pt_sequence: 347,
    shape_dist_traveled: 22.3338,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221716,
    shape_pt_lon: -89.918603,
    shape_pt_sequence: 348,
    shape_dist_traveled: 22.3584,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221727,
    shape_pt_lon: -89.918561,
    shape_pt_sequence: 349,
    shape_dist_traveled: 22.3625,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221786,
    shape_pt_lon: -89.918317,
    shape_pt_sequence: 350,
    shape_dist_traveled: 22.3856,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221828,
    shape_pt_lon: -89.918008,
    shape_pt_sequence: 351,
    shape_dist_traveled: 22.4129,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221838,
    shape_pt_lon: -89.917636,
    shape_pt_sequence: 352,
    shape_dist_traveled: 22.4469,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221831,
    shape_pt_lon: -89.917172,
    shape_pt_sequence: 353,
    shape_dist_traveled: 22.489,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221818,
    shape_pt_lon: -89.915564,
    shape_pt_sequence: 354,
    shape_dist_traveled: 22.634,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221808,
    shape_pt_lon: -89.914868,
    shape_pt_sequence: 355,
    shape_dist_traveled: 22.697,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22179,
    shape_pt_lon: -89.913722,
    shape_pt_sequence: 356,
    shape_dist_traveled: 22.8,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221776,
    shape_pt_lon: -89.912561,
    shape_pt_sequence: 357,
    shape_dist_traveled: 22.905,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.22176,
    shape_pt_lon: -89.911377,
    shape_pt_sequence: 358,
    shape_dist_traveled: 23.012,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221751,
    shape_pt_lon: -89.910988,
    shape_pt_sequence: 359,
    shape_dist_traveled: 23.047,
  },
  {
    shape_id: 38343,
    shape_pt_lat: 35.221735,
    shape_pt_lon: -89.910006,
    shape_pt_sequence: 360,
    shape_dist_traveled: 23.136,
  },
];
