import { Button, Skeleton, Spin, Table } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import { toFixed2Offset } from "utils/helper";
import { exportCSVFile } from "utils/renderExportFile";
import DetectorResultChart from "./DetectorResultChart";
// import PrintPreview from "./PrintPreview";

@inject("rootStore")
@observer
class ReportResultPane extends Component {
  timeoutResize = null;
  constructor(props) {
    super(props);
    this.moduleName = "result_table";
    this.printRef = React.createRef();
    this.state = {
      tableHeight: 700,
      resizing: false,
      printPreview: false,
      selectedInterection: null,
    };
  }

  getTableColumn = () => {
    const { t } = this.props;

    return [
      {
        title: t(this.moduleName + ".intersection_name"),
        dataIndex: "int_name",
        key: "int_name",
      },
      {
        title: t("Detector Type"),
        dataIndex: "detector_type",
        key: "detector_type",
      },
      {
        title: t("Call Phase"),
        dataIndex: "call_phases",
        key: "call_phases",
      },
      {
        title: t("Total Activation (Raw count)"),
        dataIndex: "total_activation",
        key: "total_activation",

        render: (value, d) => {
          return (
            <div
              style={
                d.is_total_act_satisfy_condition
                  ? {
                      color: CHART_COLOR_SCHEMA.YELLOW,
                    }
                  : null
              }>
              {value ? toFixed2Offset(value) : ""}
            </div>
          );
        },
      },
      {
        title: t("Occupancy (%)"),
        dataIndex: "occupancy",
        key: "occupancy",
        render: (value, d) => {
          return (
            <div
              style={
                d.is_occupancy_satisfy_condition
                  ? {
                      color: CHART_COLOR_SCHEMA.YELLOW,
                    }
                  : null
              }>
              {value ? toFixed2Offset(value) : ""}
            </div>
          );
        },
      },
      {
        title: t("Avg Volume(VPH)"),
        dataIndex: "avg_volume",
        key: "avg_volume",

        render: (value, d) => {
          return (
            <div
              style={
                d.is_avg_volume_satisfy_condition
                  ? {
                      color: CHART_COLOR_SCHEMA.YELLOW,
                    }
                  : null
              }>
              {value ? toFixed2Offset(value) : ""}
            </div>
          );
        },
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        render: (_, record) =>
          record.detail_chart ? (
            <Button
              type="link"
              onClick={() => this.setState({ selectedInterection: record })}>
              {t("View chart")}
            </Button>
          ) : null,
      },
    ];
  };
  convertCoverPageToExportJsonFormat = (reportDetail) => {
    const { t } = this.props;
    let coverTranform = [];

    // console.log(coverData);
    Object.keys(reportDetail).forEach((e) => {
      switch (e) {
        case "owner": {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e].name,
          });
          break;
        }
        case "week_days": {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e].join(","),
          });
          break;
        }
        case "owner_id": {
          break;
        }
        case "id": {
          break;
        }
        case "chart_data_aggregate": {
          break;
        }
        case "num_of_int": {
          break;
        }

        case "agency_id": {
          break;
        }
        case "timezone": {
          coverTranform.push({
            title: t("report_result." + "time_zone"),
            data: reportDetail[e],
          });
          break;
        }
        default: {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e],
          });
        }
      }
    });

    return coverTranform;
  };
  convertDataToExportJsonFormat = (resultData) => {
    const { t } = this.props;
    let titleRow = {
      int_name: t(this.moduleName + ".intersection_name"),
      detector_type: t("Detector Type"),
      call_phases: t("Call Phase"),
      total_activation: t("Total Activation (Raw count)"),
      occupancy: t("Occupancy (%)"),
      avg_volume: t("Avg Volume(VPH)"),
    };
    let dataTranform = [];

    dataTranform.push(titleRow);

    // render data
    resultData.forEach((int) => {
      dataTranform.push({
        int_name: int.int_name,
        detector_type: "-",
        call_phases: int.call_phases,
        total_activation: int.avg_volume,
        occupancy: int.occupancy,
        avg_volume: int.avg_volume,
      });
      int.children.forEach((det) => {
        dataTranform.push({
          int_name: det.int_name + "-" + int.int_name,
          detector_type: det.detector_type || " ",
          call_phases: det.call_phases,
          total_activation: det.avg_volume,
          occupancy: det.occupancy,
          avg_volume: det.avg_volume,
        });
        // if (e !== "key") {
        //   if (e === "intersection_name") tranformRow[e] = row[e];
        //   else
        //     Object.keys(row[e]).forEach((c) => {
        //       tranformRow[e + "_" + c] = row[e][c].value;
        //       tranformRow[e + "_" + c + "trending"] =
        //         row[e][c].trending === SELECT_TREND.none
        //           ? t(row[e][c].trending)
        //           : t(row[e][c].trending) +
        //             "(" +
        //             (row[e][c].fluctuation >= 0
        //               ? "+" + row[e][c].fluctuation
        //               : row[e][c].fluctuation) +
        //             ")";
        //     });
        // }
        // tranformRow[e] = "1243y7";
      });
      // dataTranform.push(tranformRow);
    });
    return dataTranform;
  };

  componentWillUnmount() {
    const {
      rootStore: { detectorResultStore },
    } = this.props;
    detectorResultStore.resetData();
  }
  render() {
    const {
      t,
      history,
      rootStore: {
        detectorResultStore: {
          resultData,
          reportDetail,
          prev_result_time,
          loading,
        },
      },
    } = this.props;

    return (
      <div className="report-result-pane overflow-auto">
        {/* <Modal
          className="alarm-records-summary-modal"
          width={"60vw"}
          title={t("print.modal-title")}
          visible={this.state.printPreview}
          onCancel={() => this.setState({ printPreview: false })}
          footer={null}
          destroyOnClose={true}>
       
        </Modal> */}
        <DetectorResultChart
          onClose={() => this.setState({ selectedInterection: false })}
          selectedInterection={this.state.selectedInterection}
        />
        <div
          className="result-loading-pane"
          style={{ display: loading ? "" : "none" }}>
          <Skeleton active />
          <Skeleton active />
        </div>
        {/* {!resultData.length && (
          <div className="result-detector-nodata">
            <Empty />
          </div>
        )} */}
        {
          <div className="result-detector-table-pane h-full flex flex-col ">
            <div
              className="report-result-function flex-0-0-auto"
              ref={this.printRef}>
              <div>
                <Button
                  icon="arrow-left"
                  onClick={() =>
                    history.push("/report/result/detector-report")
                  }>
                  {t("detail.back")}
                </Button>
              </div>
              <div>
                <Button
                  icon="file-excel"
                  onClick={() => {
                    const fileTitle = reportDetail?.name;
                    exportCSVFile(
                      this.convertCoverPageToExportJsonFormat(
                        reportDetail,
                        resultData.length,
                        prev_result_time
                      ),
                      [
                        {
                          title: "Detail Report",
                          data: this.convertDataToExportJsonFormat(resultData),
                        },
                      ],
                      t("detector-report"),
                      fileTitle
                    );
                  }}>
                  {t("report_result.export_csv")}
                </Button>
              </div>
            </div>
            <Table
              className="spm-table flex-1-1-auto overflow-auto"
              rowKey="key"
              loading={
                this.state.resizing && {
                  indicator: <Spin spinning size="large" />,
                }
              }
              rowClassName={(record, index) => {
                if (index % 2 !== 0) return "odd-row";
                else return "even-row";
              }}
              // scroll={{ y: this.state.tableHeight }}
              pagination={false}
              dataSource={resultData}
              columns={this.getTableColumn()}
            />
          </div>
        }
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReportResultPane));
