import { Col, DatePicker, Icon, Input, Select, Tooltip } from "antd";
import BaseManagementComponents from "components/BaseManagementComponents";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  ACTIVE,
  AGGREGATION_UNIT,
  DATE_FORMAT,
  INACTIVE,
  REPORT_TEMPLATE_TYPE,
  RESOURCE_LIST,
  STATUS_LIST,
  WEEK_DAY_OPTION,
} from "utils/constants";
import ReportTemplateDetailPopup from "./ReportTemplateDetailPopup";
import "./style.scss";
import React from "react";
import SwitchTypeReport from "../../../components/SwitchTypeReport";

const { Option } = Select;
const { RangePicker } = DatePicker;

@inject("rootStore")
@observer
class ReportTemplate extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore.reportTemplateStore, props);
    const { t } = props;
    this.rowKey = "id";
    this.moduleName = "summary-report";
    this.resourceName = RESOURCE_LIST.REPORT_TEMPLATE;
    this.pageTitle = t("report_template.report_template");
    this.columns = this.getTableColumns();
    this.enableRedirectPage = true;
    this.state = {
      expand: true,
    };
    this.buttonActions = [
      {
        key: "active_all",
        title: t("report_template.active_all"),
        action: (ids) => this.currentStore?.setStatus(ids, ACTIVE),
        resource: RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        type: "check",
        confirm: true,
        confirmTitle: t("report_template.active_confirm_title"),
        confirmContent: t("report_template.active_confirm_content"),
      },
      {
        key: "deactive_all",
        title: t("report_template.deactive_all"),
        resource: RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
        action: (ids) => this.currentStore?.setStatus(ids, INACTIVE),
        permission: ACTIONS_LIST.EDIT,
        type: "stop",
        confirm: true,
        confirmTitle: t("report_template.inactive_confirm_title"),
        confirmContent: t("report_template.inactive_confirm_content"),
      },
      {
        key: "delete_all",
        title: t("report_template.delete_all"),
        type: "delete",
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.DELETE,
        action: this.currentStore?.delete,
        confirm: true,
        confirmTitle: t("report_template.delete_confirm_title"),
        confirmContent: t("report_template.delete_confirm_content"),
      },
    ];

    this.tableInlineActions = [
      {
        key: "duplicate",
        tooltip: t("report_template.duplicate"),
        action: this.handleDuplicate,
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.CREATE,
        icon: "copy",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "run_template",
        tooltip: t("report_template.run_template"),
        action: this.handleRunTemplate,
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.CREATE,
        child: (
          <Icon
            style={{ fontSize: "16px" }}
            type="play-square"
            theme="filled"
          />
        ),
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "deactive_all",
        tooltip: t("report_template.deactive"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("report_template.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status !== ACTIVE;
        },
      },
      {
        key: "edit",
        tooltip: t("report_template.edit"),
        resource: RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
        action: this.goToDetailPage,
        permission: ACTIONS_LIST.EDIT,
        icon: "edit",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "delete_all",
        tooltip: t("report_template.delete"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.DELETE,
        child: <Icon style={{ fontSize: "16px" }} type="delete" />,
        passingData: (item) => {
          return ["delete_all", [item?.id]];
        },
      },
    ];
  }

  componentDidMount = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    document.title = t("report_template.page-title");
    if (this.props.location?.search?.length > 0) {
      let filterObject = queryString?.parse(this.props.location.search);
      this.currentStore?.setFilterData(filterObject);
    }
    this.fetchReportTemplateByAgency(commonStore.isSuperAdmin());
    this.currentStore.clearSelectedItems();
  };
  handleRunTemplate = (templateId) => {
    const { t } = this.props;
    this.showConfirmBox(
      t("report_template.run_confirm_title"),
      t("report_template.run_confirm_content"),
      this.currentStore.runTemplate,
      templateId
    );
  };

  fetchReportTemplateByAgency = (isSuperAdmin) => {
    this.currentStore?.setDetaultAgencyFilter(isSuperAdmin, () => {
      this.currentStore?.getData(() => {});
    });
    this.currentStore?.getAllUsers();
    // this.currentStore?.getAllIntersections();
  };

  getTableColumns = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    return [
      {
        title: t("report_template.name"),
        key: "name",
        ellipsis: true,
        render: (item) =>
          myStore.hasPermission(
            ACTIONS_LIST.VIEW,
            RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
            false,
            item.owner_id
          ) ? (
            <div>
              <Tooltip title={item.name} placement={"topRight"}>
                <a
                  onClick={() =>
                    this.currentStore?.showCreateOrEditForm(
                      t("report_template.show_form"),
                      item.id,
                      false
                    )
                  }
                >
                  {item.name}
                </a>
              </Tooltip>
            </div>
          ) : (
            item.name
          ),
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("report_template.description"),
        key: "description",
        ellipsis: true,
        render: (item) => t(item?.description),
      },
      {
        title: t("report_template.aggregation_unit"),
        key: "aggregation",
        render: (item) => {
          return t(
            AGGREGATION_UNIT.find((e) => e.value === item.aggregation).text
          );
        },
      },
      {
        title: t("report_template.day_of_week"),
        key: "week_days",
        render: (item) =>
          item?.week_days
            .map((e) => {
              return WEEK_DAY_OPTION.find((i) => i.value == e).label;
            })
            .join(", "),
      },
      {
        title: t("report_template.created_by"),
        key: "owner",
        ellipsis: true,
        render: (item) => item?.owner?.name,
      },
      {
        title: t("report_template.created_at"),
        key: "created_at",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
        render: (item) =>
          moment(item.created_at).format(DATE_FORMAT.full_with_tz),
      },
      {
        title: t("report_template.status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status) => <StatusTag status={status} />,
      },
    ];
  };
  /**
   * renderDetailData
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <ReportTemplateDetailPopup
        childRef={(ref) => (this.defailFromRef = ref)}
      ></ReportTemplateDetailPopup>
    );
  };

  goToDetailPage = (id) => {
    const { history } = this.props;
    history.push("/report/template/summary-report/" + id);
  };

  handleDuplicate = (id) => {
    const { history } = this.props;
    history.push("/report/template/summary-report/create" + "?cloneid=" + id);
  };
  handleChangeActiveTab = (value) => {
    const { history } = this.props;
    switch (value) {
      case REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE:
        history.push("/report/template/performance-metric");
        break;
      case REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE:
        history.push("/report/template/summary-report");
        break;

      case REPORT_TEMPLATE_TYPE.DETECTOR_DATA:
        history.push("/report/template/detector-report");
        break;

      default:
        history.push("/report/template/summary-report");
        break;
    }
  };
  renderFilterComponent = () => {
    const {
      t,
      activeTab,

      rootStore: { commonStore },
    } = this.props;
    const allAgencies = this.currentStore?.allAgencies;
    const allUsers = this.currentStore?.allUsers;

    return (
      <div className="wrap-filter">
        <Col lg={8} xl={4}>
          <SwitchTypeReport
            value={activeTab}
            onChange={(value) => this.handleChangeActiveTab(value)}
          />
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip title={t("report_template.filter_text")}>
            <Input
              value={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e?.target.value
                )
              }
              placeholder={t("report_template.search_placholer")}
            />
          </Tooltip>
        </Col>
        <Col lg={16} xl={5}>
          <Tooltip title={t("report_template.filter_date")}>
            <RangePicker
              defaultValue={[
                this.currentStore?.filterData?.from_date &&
                  moment.unix(this.currentStore?.filterData?.from_date / 1000),
                this.currentStore?.filterData?.to_date &&
                  moment.unix(this.currentStore?.filterData?.to_date / 1000),
              ]}
              format={DATE_FORMAT.day_only}
              onChange={(e) => {
                this.currentStore.handleFilterDataChange(
                  "from_date",
                  e?.[0]?.startOf("day").utc().format("x"),
                  false
                );
                this.currentStore.handleFilterDataChange(
                  "to_date",
                  e?.[1]?.endOf("day").utc().format("x")
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip title={t("report_template.filter_aggregration_unit")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("aggregation", value)
              }
              defaultValue={this.currentStore?.filterData?.aggregation}
            >
              <Option value={null}>
                {t("report_template.all_aggregration_unit")}
              </Option>
              {AGGREGATION_UNIT.map((e) => (
                <Option value={e?.value} key={e?.key}>
                  {t("report_template" + "." + e.key)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("report_template.filter_owner")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("owner_id", value)
              }
              defaultValue={this.currentStore?.filterData?.owner_id}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                return (
                  option?.props?.filter
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              <Option value={null}>{t("report_template.all_owner")}</Option>
              {allUsers.map((e) => (
                <Option value={e?.id} key={e?.id} filter={e?.name}>
                  <b>{e?.name}</b> ({e?.email})
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col
          lg={8}
          xl={2}
          style={{ display: this.state.expand ? "block" : "none" }}
        >
          <Tooltip placement="top" title={t("report_template.filter_status")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}
            >
              <Option value={null}>{t("report_template.all_status")}</Option>
              {STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() && (
          <Col lg={8} xl={3}>
            <Tooltip
              title={t("report_template.filter_agency")}
              key={allAgencies.length && allAgencies?.[0]?.id}
            >
              {this.currentStore?.filterData.agency_id && (
                <Select
                  showSearch
                  onChange={(value) =>
                    this.currentStore.handleFilterDataChange("agency_id", value)
                  }
                  defaultValue={this.currentStore?.filterData.agency_id}
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {allAgencies?.map((e) => (
                    <Option key={e?.id} value={e?.id} filter={e?.name}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </Col>
        )}
      </div>
    );
  };

  render() {
    return <div className="wrap-alarm-rule">{super.render()}</div>;
  }
}

ReportTemplate.propTypes = {
  rootStore: PropTypes.any,
  history: PropTypes.any,
};

export default withRouter(withTranslation()(ReportTemplate));
