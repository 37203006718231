function objectToCSVLine(object) {
  let line = "";
  for (let index in object) {
    if (line != "") line += ",";
    if (object[index] === "") {
      line += '""';
    } else {
      line += isNaN(object[index]) ? '"' + object[index] + '"' : object[index];
    }
  }
  return line + "\r\n";
}

function convertToCSV(objArray) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    if (Array.isArray(array[i])) {
      str += array[i].map((el) => objectToCSVLine(el)).join("");
      continue;
    }
    str += objectToCSVLine(array[i]);
  }
  return str;
}

export function exportCSVFile(coverPage, items, headerTitle, fileTitle) {
  let csvCoverPage = "";
  if (coverPage) {
    csvCoverPage = convertToCSV(JSON.stringify(coverPage));
  }

  // Convert Object to JSON
  let arrayCSV = items?.map(
    (item) =>
      (item?.title ? item.title + "\n \n" : "") +
      convertToCSV(JSON.stringify(item.data)) +
      "\n \n"
  );

  let exportedFilenmae = fileTitle + ".csv" || "export.csv";

  const BOM = "\uFEFF";

  let blob = new Blob(
    [
      BOM +
        headerTitle +
        "\n \n" +
        csvCoverPage +
        "\n \n" +
        arrayCSV.join("\n"),
    ],
    {
      type: "text/csv;charset=utf-8;",
    }
  );
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
