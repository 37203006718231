export const mockStops = [
  {
    stop_id: 5,
    stop_code: "YALCOVWF",
    stop_name: "YALE@COVINGTON PIKE",
    stop_desc: "YALE RD & RAMP",
    stop_lat: 35.221519,
    stop_lon: -89.895439,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6,
    stop_code: "YALCOVWM",
    stop_name: "YALE@COVINGTON PIKE",
    stop_desc: "YALE RD & CONNECTING RD",
    stop_lat: 35.221558,
    stop_lon: -89.897067,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7,
    stop_code: "AIRKETSN",
    stop_name: "AIRWAYS BLVD@KETCHUM",
    stop_desc: "AIRWAYS BLVD & KETCHUM RD",
    stop_lat: 35.079543,
    stop_lon: -89.984917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 8,
    stop_code: "EPAYOUSF",
    stop_name: "E PKWY@YOUNG (Tiger Lane)",
    stop_desc: "E PARKWAY S & YOUNG AVE",
    stop_lat: 35.11942,
    stop_lon: -89.984563,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 10,
    stop_code: "CLEMA1SN",
    stop_name: "CLEVELAND@MADISON",
    stop_desc: "N CLEVELAND ST & COURT AVE",
    stop_lat: 35.13902,
    stop_lon: -90.016438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 15,
    stop_code: "FOUGEOSF",
    stop_name: "4TH ST@ GEORGIA AVE",
    stop_desc: "S 4TH ST & E GEORGIA AVE",
    stop_lat: 35.128896,
    stop_lon: -90.050605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 24,
    stop_code: "FROGEPNN",
    stop_name: "CENTRAL STATION",
    stop_desc: "S FRONT ST & E CALHOUN AVE",
    stop_lat: 35.132731,
    stop_lon: -90.060719,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 27,
    stop_code: "PAUJEFSF",
    stop_name: "PAULINE@JEFFERSON",
    stop_desc: "",
    stop_lat: 35.142688,
    stop_lon: -90.027691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 28,
    stop_code: "CLEMA1NN",
    stop_name: "CLEVELAND@MADISON",
    stop_desc: "S CLEVELAND ST & MADISON AVE",
    stop_lat: 35.138769,
    stop_lon: -90.016261,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 30,
    stop_code: "EPAYOUNF",
    stop_name: "E PARKWAY S@YOUNG AVE",
    stop_desc: "E PARKWAY S & OLIVER AVE",
    stop_lat: 35.119858,
    stop_lon: -89.984198,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 32,
    stop_code: "MEMINT",
    stop_name: "MEMPHIS INTERNATIONAL AIRPORT",
    stop_desc: "",
    stop_lat: 35.044694,
    stop_lon: -89.981054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 33,
    stop_code: "COOMADNN",
    stop_name: "COOPER ST@MADISON AVE",
    stop_desc: "",
    stop_lat: 35.136181,
    stop_lon: -89.990255,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 34,
    stop_code: "CENHOLEF",
    stop_name: "CENTRAL AVE@HOLLYWOOD ST",
    stop_desc: "CENTRAL AVE & S HOLLYWOOD ST",
    stop_lat: 35.125678,
    stop_lon: -89.975804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 41,
    stop_code: "ELVNORNF",
    stop_name: "ELVIS P. @ NORRIS",
    stop_desc: "S BELLEVUE BLVD & E DEMPSTER AVE",
    stop_lat: 35.083495,
    stop_lon: -90.018401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 45,
    stop_code: "KETPENSN",
    stop_name: "KETCHUM RD@PENDLETON ST",
    stop_desc: "KETCHUM RD & DRIVEWAY",
    stop_lat: 35.080552,
    stop_lon: -89.972287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 46,
    stop_code: "LAPDU1SN",
    stop_name: "LAPALOMA@DUNN",
    stop_desc: "LAPALOMA ST & MURLEY AVE",
    stop_lat: 35.092163,
    stop_lon: -89.998751,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 47,
    stop_code: "PILETHNN",
    stop_name: "PILLOW@ETHLYN",
    stop_desc: "PILLOW ST & ETHLYN AVE",
    stop_lat: 35.104549,
    stop_lon: -90.014913,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 48,
    stop_code: "MCLCOLWN",
    stop_name: "MCLEMORE@COLLEGE",
    stop_desc: "E MCLEMORE AVE & COLLEGE ST",
    stop_lat: 35.115566,
    stop_lon: -90.030704,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 49,
    stop_code: "LAUVANNN",
    stop_name: "LAUDERDALE@VANCE",
    stop_desc: "S LAUDERDALE ST & GRACE ALY",
    stop_lat: 35.134534,
    stop_lon: -90.043632,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 53,
    stop_code: "FROJEFSN",
    stop_name: "FRONT ST AT JEFFERSON AVE",
    stop_desc: "",
    stop_lat: 35.147422,
    stop_lon: -90.053357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 54,
    stop_code: "LAUVANSF",
    stop_name: "LAUDERDALE ST@VANCE AVE",
    stop_desc: "S LAUDERDALE ST & VANCE AVE",
    stop_lat: 35.134507,
    stop_lon: -90.043776,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 55,
    stop_code: "MCLCOLEN",
    stop_name: "MCLEMORE AVE@COLLEGE ST",
    stop_desc: "E MCLEMORE AVE & COLLEGE ST",
    stop_lat: 35.115459,
    stop_lon: -90.030638,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 56,
    stop_code: "PILET1SN",
    stop_name: "PILLOW ST@ETHLYN AVE",
    stop_desc: "",
    stop_lat: 35.104826,
    stop_lon: -90.015101,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 57,
    stop_code: "LAPDUNNF",
    stop_name: "LAPALOMA ST@DUNN AVE",
    stop_desc: "LAPALOMA ST & MURLEY AVE",
    stop_lat: 35.092136,
    stop_lon: -89.998585,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 58,
    stop_code: "PENKE1SN",
    stop_name: "PENDLETON ST @KETCHUM RD",
    stop_desc: "PENDLETON ST & KNIGHT ARNOLD RD",
    stop_lat: 35.080615,
    stop_lon: -89.972099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 62,
    stop_code: "ELVNORSF",
    stop_name: "ELVIS PRESLEY BLVD@ NORRIS RD",
    stop_desc: "",
    stop_lat: 35.083138,
    stop_lon: -90.018633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 68,
    stop_code: "LAMEPAWN",
    stop_name: "LAMAR AVE@EAST PKWY S",
    stop_desc: "LAMAR AVE & E PARKWAY S",
    stop_lat: 35.110587,
    stop_lon: -89.991687,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 72,
    stop_code: "IRS",
    stop_name: "IRS",
    stop_desc: "",
    stop_lat: 35.001947,
    stop_lon: -89.936824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 79,
    stop_code: "AIPWINSF",
    stop_name: "AIR PARK@WINCHESTER",
    stop_desc: "",
    stop_lat: 35.051536,
    stop_lon: -89.931321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 80,
    stop_code: 3,
    stop_name: "AMERICAN WAY TRANSIT CENTER",
    stop_desc: "",
    stop_lat: 35.076267,
    stop_lon: -89.932316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 81,
    stop_code: "LAMSPKEF",
    stop_name: "LAMAR AVE@EAST PKWY S",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.110166,
    stop_lon: -89.991355,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 91,
    stop_code: "KANINGNN",
    stop_name: "KANSAS@INGLE",
    stop_desc: "KANSAS ST & INGLE AVE",
    stop_lat: 35.100192,
    stop_lon: -90.06507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 99,
    stop_code: "WATJACNN",
    stop_name: "WATKINS@JACKSON",
    stop_desc: "N WATKINS ST & ONTARIO AVE",
    stop_lat: 35.157936,
    stop_lon: -90.011709,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 100,
    stop_code: "WATLOUN",
    stop_name: "WATKINS@LOUISVILLE",
    stop_desc: "",
    stop_lat: 35.175745,
    stop_lon: -90.008362,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 101,
    stop_code: "WATDELNF",
    stop_name: "WATKINS @ DELANO",
    stop_desc: "N WATKINS ST & WINDCREST RD",
    stop_lat: 35.199515,
    stop_lon: -90.00769,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 107,
    stop_code: "RANWHISF",
    stop_name: "RANGE LINE@WHITNEY",
    stop_desc: "RANGE LINE RD & LAKECREST CIR",
    stop_lat: 35.207733,
    stop_lon: -89.975847,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 109,
    stop_code: "WATBURNN",
    stop_name: "WATKINS@BURNHAM",
    stop_desc: "N WATKINS ST & BURNHAM AVE",
    stop_lat: 35.226383,
    stop_lon: -90.003003,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 110,
    stop_code: "WATDELSF",
    stop_name: "WATKINS @ DELANO",
    stop_desc: "N WATKINS ST & ONTARIO AVE",
    stop_lat: 35.199149,
    stop_lon: -90.007912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 111,
    stop_code: 1006,
    stop_name: "WATKINS ST@LEVEE RD",
    stop_desc: "? N WATKINS ST & LEVEE RD",
    stop_lat: 35.175968,
    stop_lon: -90.008462,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 112,
    stop_code: "WATJA2SN",
    stop_name: "WATKINS @ JACKSON",
    stop_desc: "N WATKINS ST & RALEIGH SPRINGS MALL",
    stop_lat: 35.158285,
    stop_lon: -90.011708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 115,
    stop_code: "LAMLOWEF",
    stop_name: "LAMAR@LOWELL",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.095905,
    stop_lon: -89.971006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 122,
    stop_code: "NEERAINF",
    stop_name: "NEELY RD @ RAINES RD",
    stop_desc: "NEELY RD & SULLIVAN DR",
    stop_lat: 35.034859,
    stop_lon: -90.056166,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 123,
    stop_code: "THIPEENF",
    stop_name: "THIRD ST AT PEEBLES RD",
    stop_desc: "US HWY 61 & STATE HWY 14",
    stop_lat: 35.066596,
    stop_lon: -90.058748,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 138,
    stop_code: "YALSCHWN",
    stop_name: "YALE@SCHEIBLER",
    stop_desc: "YALE RD & CONNECTING RD",
    stop_lat: 35.221624,
    stop_lon: -89.899427,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 141,
    stop_code: "COVBANSF",
    stop_name: "COVINGTON PIKE@BANBURY AVE",
    stop_desc: "",
    stop_lat: 35.226386,
    stop_lon: -89.8934,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 142,
    stop_code: "THISPASN",
    stop_name: "THIRD ST AT S PARKWAY",
    stop_desc: "US HWY 61 & S THIRD ST",
    stop_lat: 35.107743,
    stop_lon: -90.055423,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 143,
    stop_code: "THIPEBSF",
    stop_name: "3RD ST@PEEBLES RD",
    stop_desc: "US HWY 61 & E PEEBLES RD",
    stop_lat: 35.06623,
    stop_lon: -90.058925,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 151,
    stop_code: "FROGEPSF",
    stop_name: "FRONT ST @G E PATTERSON AVE",
    stop_desc: "S FRONT ST & E CALHOUN AVE",
    stop_lat: 35.13265,
    stop_lon: -90.060874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 165,
    stop_code: "SPKCOOWF",
    stop_name: "S PARKWAY@COOPER",
    stop_desc: "S PARKWAY E & S COOPER ST",
    stop_lat: 35.11014,
    stop_lon: -89.992838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 176,
    stop_code: "THIRAINF",
    stop_name: "3RD ST@RAINES RD",
    stop_desc: "US HWY 61 & S 3RD ST",
    stop_lat: 35.03553,
    stop_lon: -90.072999,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 179,
    stop_code: "VOLWA2EN",
    stop_name: "VOLLINTINE AVE@WATKINS ST",
    stop_desc: "VOLLINTINE AVE & N MANSFIELD ST",
    stop_lat: 35.16462,
    stop_lon: -90.010519,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 180,
    stop_code: "HOLCHENN",
    stop_name: "N HOLLYWOOD@CHELSEA",
    stop_desc: "N HOLLYWOOD ST & HUBBARD AVE",
    stop_lat: 35.174338,
    stop_lon: -89.975379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 184,
    stop_code: "MACWELWF",
    stop_name: "MACON@WELLS STATION",
    stop_desc: "MACON RD & WELLS STATION RD",
    stop_lat: 35.162133,
    stop_lon: -89.917715,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 195,
    stop_code: "HOLCHESF",
    stop_name: "HOLLYWOOD@CHELSEA",
    stop_desc: "N HOLLYWOOD ST & HUBBARD AVE",
    stop_lat: 35.174374,
    stop_lon: -89.975578,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 196,
    stop_code: "WATVOLNF",
    stop_name: "WATKINS@VOLLINTINE",
    stop_desc: "? N WATKINS ST & VOLLINTINE AVE",
    stop_lat: 35.164817,
    stop_lon: -90.010264,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 198,
    stop_code: "THIFORSF",
    stop_name: "THIRD@FORD",
    stop_desc: "S THIRD ST & FORD RD",
    stop_lat: 35.026154,
    stop_lon: -90.082547,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 200,
    stop_code: "GETMCENF",
    stop_name: "GETWELL@MCEVERS",
    stop_desc: "GETWELL RD & MCEVERS RD",
    stop_lat: 35.101468,
    stop_lon: -89.930734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 202,
    stop_code: "TCHSHESF",
    stop_name: "TCHULAHOMA@SHELBY DR",
    stop_desc: "TCHULAHOMA RD & ARNOLD RD",
    stop_lat: 35.020199,
    stop_lon: -89.954694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 204,
    stop_code: "LAMBELNF",
    stop_name: "LAMAR@BELLEVUE",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.128878,
    stop_lon: -90.021258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 205,
    stop_code: "SECCOUSQ",
    stop_name: "SECOND@COURT SQUARE",
    stop_desc: "N 2ND ST & COURT AVE",
    stop_lat: 35.145929,
    stop_lon: -90.051265,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 206,
    stop_code: "GETDUNSF",
    stop_name: "GETWELL@DUNN",
    stop_desc: "GETWELL RD & DUNN RD",
    stop_lat: 35.089716,
    stop_lon: -89.931208,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 208,
    stop_code: "TCHSH1SN",
    stop_name: "TCHULAHOMA RD @ SHELBY DR",
    stop_desc: "TCHULAHOMA RD & KERR AVE",
    stop_lat: 35.021343,
    stop_lon: -89.954703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 227,
    stop_code: "KANSPANN",
    stop_name: "KANSAS@S PARKWAY",
    stop_desc: "KANSAS ST & S PARKWAY W",
    stop_lat: 35.10769,
    stop_lon: -90.064671,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 235,
    stop_code: "HOLPOPNF",
    stop_name: "HOLLYWOOD ST@POPLAR AVE",
    stop_desc: "N HOLLYWOOD ST & UNNAMED ST",
    stop_lat: 35.140212,
    stop_lon: -89.978697,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 236,
    stop_code: "HOLJA1NF",
    stop_name: "N HOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & JACKSON AVE",
    stop_lat: 35.158324,
    stop_lon: -89.975885,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 237,
    stop_code: "WATCHENN",
    stop_name: "WATKINS@CHELSEA",
    stop_desc: "N WATKINS ST & WALDRUP ST",
    stop_lat: 35.172233,
    stop_lon: -90.008376,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 238,
    stop_code: "WATCHESF",
    stop_name: "WATKINS@CHELSEA",
    stop_desc: "N WATKINS ST & WALDRUP ST",
    stop_lat: 35.172322,
    stop_lon: -90.008575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 239,
    stop_code: "HOLJACSN",
    stop_name: "HOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & JACKSON AVE",
    stop_lat: 35.158146,
    stop_lon: -89.976085,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 240,
    stop_code: "GETDUNNF",
    stop_name: "GETWELL@DUNN",
    stop_desc: "GETWELL RD & DUNN RD",
    stop_lat: 35.090556,
    stop_lon: -89.930997,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 251,
    stop_code: "UNICLEWF",
    stop_name: "UNION@CLEVELAND",
    stop_desc: "US HWY 79 & S CLEVELAND ST",
    stop_lat: 35.136946,
    stop_lon: -90.016882,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 253,
    stop_code: "3402IB",
    stop_name: "WALNUT GROVE@PERKINS",
    stop_desc: "STATE HWY 23 & N PERKINS RD",
    stop_lat: 35.131147,
    stop_lon: -89.908857,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 254,
    stop_code: "HUMWALSF",
    stop_name: "HUMPHREYS BL@WALNUT GROVE",
    stop_desc: "? RAMP & HUMPHREYS CTR",
    stop_lat: 35.131145,
    stop_lon: -89.857111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 277,
    stop_code: "SPKBELEF",
    stop_name: "S PARKWAY@BELLEVUE",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109405,
    stop_lon: -90.02147,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 278,
    stop_code: "SPALAMEN",
    stop_name: "S PARKWAY@LAMAR",
    stop_desc: "S PARKWAY E & RAMP",
    stop_lat: 35.11006,
    stop_lon: -89.992086,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 285,
    stop_code: "STANBRWF",
    stop_name: "STAGE@NEW BRUNSWICK",
    stop_desc: "US HWY 64 & STATE HWY 15",
    stop_lat: 35.204479,
    stop_lon: -89.778162,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 286,
    stop_code: "KANPRENM",
    stop_name: "KANSAS@PRETORIA",
    stop_desc: "KANSAS ST & PRETORIA AVE",
    stop_lat: 35.095786,
    stop_lon: -90.066785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 287,
    stop_code: "STAALTWF",
    stop_name: "STAGE@ALTRURIA",
    stop_desc: "STATE HWY 15 & ALTRURIA RD",
    stop_lat: 35.204819,
    stop_lon: -89.848344,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 290,
    stop_code: "JACEVAWF",
    stop_name: "JACKSON@EVA",
    stop_desc: "JACKSON AVE & EVA ST",
    stop_lat: 35.1581,
    stop_lon: -89.975244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 291,
    stop_code: "WATJACNF",
    stop_name: "WATKINS @ JACKSON",
    stop_desc: "N WATKINS ST & RALEIGH SPRINGS MALL",
    stop_lat: 35.15832,
    stop_lon: -90.011498,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 294,
    stop_code: "JACWATEN",
    stop_name: "JACKSON AVE@WATKINS ST",
    stop_desc: "? STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.158106,
    stop_lon: -90.012041,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 295,
    stop_code: "JACHOLEF",
    stop_name: "JACKSON AVE@HOLLYWOOD ST",
    stop_desc: "? STATE HWY 14 & N HOLLYWOOD ST",
    stop_lat: 35.157859,
    stop_lon: -89.975864,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 297,
    stop_code: "STAJACEF",
    stop_name: "STAGE@JACKSON",
    stop_desc: "STATE HWY 15 & JAMES RD",
    stop_lat: 35.205203,
    stop_lon: -89.922193,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 298,
    stop_code: "STAAL2EF",
    stop_name: "STAGE@ALTRURIA",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204567,
    stop_lon: -89.847226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 299,
    stop_code: "STASUMEN",
    stop_name: "STAGE@SUMMER",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.205312,
    stop_lon: -89.835184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 301,
    stop_code: "BERSTANN",
    stop_name: "BERRYHILL@STAGE",
    stop_desc: "BERRYHILL RD & MORNING PARK DR",
    stop_lat: 35.204301,
    stop_lon: -89.754447,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 306,
    stop_code: "EXEPO1SN",
    stop_name: "EXETER@POPLAR",
    stop_desc: "EXETER RD & CONNECTING RD",
    stop_lat: 35.090711,
    stop_lon: -89.803237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 310,
    stop_code: "ELVLAUSN",
    stop_name: "ELVIS PRESLEY BLVD@ LAUDEEN DR",
    stop_desc: "0 ELVIS PRESLEY BLVD & LAUDEEN DR",
    stop_lat: 35.040089,
    stop_lon: -90.025227,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 315,
    stop_code: "AIRHO1SN",
    stop_name: "AIRWAYS BLVD@HOLMES RD",
    stop_desc: "AIRWAYS BLVD & E HOLMES RD",
    stop_lat: 35.006643,
    stop_lon: -89.990014,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 320,
    stop_code: "MILSHENF",
    stop_name: "MILLBRANCH@SHELBY",
    stop_desc: "0 MILLBRANCH RD",
    stop_lat: 35.022903,
    stop_lon: -90.007435,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 323,
    stop_code: "ELVLAUNF",
    stop_name: "ELVIS P. @ LAUDEEN",
    stop_desc: "US HWY 51 & WHITAKER DR",
    stop_lat: 35.04075,
    stop_lon: -90.024995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 330,
    stop_code: "POPHOLWF",
    stop_name: "POPLAR AVE@HOLLYWOOD ST",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.139184,
    stop_lon: -89.97914,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 331,
    stop_code: "POPCLEN",
    stop_name: "POPLAR@CLEVELAND",
    stop_desc: "POPLAR AVE & N CLEVELAND ST",
    stop_lat: 35.143872,
    stop_lon: -90.015693,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 332,
    stop_code: "POPCLEEF",
    stop_name: "POPLAR AVE@CLEVELAND ST",
    stop_desc: "POPLAR AVE & N CLEVELAND ST",
    stop_lat: 35.143827,
    stop_lon: -90.015206,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 334,
    stop_code: "POPHI2EN",
    stop_name: "POPLAR AVE@HIGHLAND ST",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.127741,
    stop_lon: -89.945448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 341,
    stop_code: "QUIPERWN",
    stop_name: "QUINCE@PERKINS",
    stop_desc: "QUINCE RD & S PERKINS RD",
    stop_lat: 35.091508,
    stop_lon: -89.906728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 342,
    stop_code: "PRIRIDEN",
    stop_name: "PRIMACY PKWY@RIDGEWAY RD",
    stop_desc: "",
    stop_lat: 35.094944,
    stop_lon: -89.858837,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 343,
    stop_code: "PARPERWF",
    stop_name: "PARK AVE@PERKINS RD",
    stop_desc: "PARK AVE & S PERKINS RD",
    stop_lat: 35.107025,
    stop_lon: -89.9081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 347,
    stop_code: "AUSYALSF",
    stop_name: "AUSTIN PEAY@YALE",
    stop_desc: "STATE HWY 14 & RALEIGH SPRINGS MALL",
    stop_lat: 35.220254,
    stop_lon: -89.910199,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 351,
    stop_code: "MACHOMWF",
    stop_name: "MACON@HOMER",
    stop_desc: "",
    stop_lat: 35.160989,
    stop_lon: -89.939814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 353,
    stop_code: "SUMWHIEN",
    stop_name: "SUMMER AVE@WHITE STATION RD",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.152825,
    stop_lon: -89.888754,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 354,
    stop_code: "MACHOMEN",
    stop_name: "MACON RD@HOMER ST",
    stop_desc: "",
    stop_lat: 35.160899,
    stop_lon: -89.939892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 356,
    stop_code: "YALSCHWF",
    stop_name: "YALE@SCHEIBLER",
    stop_desc: "RAMP & YALE RD",
    stop_lat: 35.221756,
    stop_lon: -89.904601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 357,
    stop_code: "METNOREF",
    stop_name: "METHODIST HOSPITAL NORTH",
    stop_desc: "? W K SINGLETON PKWY & NEW COVINGTON PIKE",
    stop_lat: 35.232775,
    stop_lon: -89.892975,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 358,
    stop_code: "RALSPGML",
    stop_name: "AUSTIN PEAY@YALE",
    stop_desc: "3368 STATE HWY 14 & RALEIGH SPRINGS MALL",
    stop_lat: 35.220137,
    stop_lon: -89.910144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 359,
    stop_code: "JACCEMNM",
    stop_name: "NATIONAL CEMETERY",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.175493,
    stop_lon: -89.939767,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 362,
    stop_code: "PARPE1EN",
    stop_name: "PARK AVE@PERKINS RD",
    stop_desc: "PARK AVE & S PERKINS RD",
    stop_lat: 35.106864,
    stop_lon: -89.9081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 364,
    stop_code: "QUIPEREN",
    stop_name: "QUINCE@PERKINS",
    stop_desc: "QUINCE RD & S PERKINS RD",
    stop_lat: 35.091348,
    stop_lon: -89.90727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 367,
    stop_code: "SWTCCSF",
    stop_name: "SOUTHWEST TECH COMM COLLEGE",
    stop_desc: "",
    stop_lat: 35.160058,
    stop_lon: -89.863972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 370,
    stop_code: "SUMPOPWF",
    stop_name: "SUMMER@POPE",
    stop_desc: "US HWY 64 & VANDALIA ST",
    stop_lat: 35.149495,
    stop_lon: -89.956282,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 373,
    stop_code: "GETKIMSF",
    stop_name: "GETWELL@KIMBALL",
    stop_desc: "GETWELL RD & OAK CLIFF RD",
    stop_lat: 35.092487,
    stop_lon: -89.931159,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 374,
    stop_code: "FLOMCLSN",
    stop_name: "FLORIDA@MCLEMORE",
    stop_desc: "FLORIDA ST & W MCLEMORE AVE",
    stop_lat: 35.116403,
    stop_lon: -90.062336,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 376,
    stop_code: "FLOMALSN",
    stop_name: "FLORIDA ST@MALLORY AVE",
    stop_desc: "FLORIDA ST & E MALLORY AVE",
    stop_lat: 35.086831,
    stop_lon: -90.063102,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 377,
    stop_code: "DEEMOCNN",
    stop_name: "DEERSKIN DR@MOCCASIN DR",
    stop_desc: "",
    stop_lat: 35.047479,
    stop_lon: -90.086596,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 378,
    stop_code: "GETRHOSF",
    stop_name: "GETWELL@RHODES",
    stop_desc: "GETWELL RD & RHODES AVE",
    stop_lat: 35.09902,
    stop_lon: -89.930992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 387,
    stop_code: "GETRHONF",
    stop_name: "GETWELL@RHODES",
    stop_desc: "GETWELL RD & RHODES AVE",
    stop_lat: 35.099904,
    stop_lon: -89.930747,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 389,
    stop_code: "FLOMALNF",
    stop_name: "FLORIDA@MALLORY",
    stop_desc: "FLORIDA ST & E MALLORY AVE",
    stop_lat: 35.08684,
    stop_lon: -90.062903,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 390,
    stop_code: "KANFIRNF",
    stop_name: "KANSAS@FIRST",
    stop_desc: "KANSAS ST & KANSAS ST",
    stop_lat: 35.093418,
    stop_lon: -90.067415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 391,
    stop_code: "FLOMCLNF",
    stop_name: "FLORIDA@MCLEMORE",
    stop_desc: "FLORIDA ST & E MCLEMORE AVE",
    stop_lat: 35.116403,
    stop_lon: -90.062137,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 392,
    stop_code: "GETKIMNF",
    stop_name: "GETWELL@KIMBALL",
    stop_desc: "GETWELL RD & KIMBALL AVE",
    stop_lat: 35.093166,
    stop_lon: -89.930937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 395,
    stop_code: "SUMPOPEF",
    stop_name: "SUMMER AVE@POPE ST",
    stop_desc: "US HWY 64 & VANDALIA ST",
    stop_lat: 35.149334,
    stop_lon: -89.956161,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 397,
    stop_code: "SHEJAIEM",
    stop_name: "SHELBY COUNTY JAIL",
    stop_desc: "STATE RD",
    stop_lat: 35.154143,
    stop_lon: -89.85449,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 399,
    stop_code: "WINRIDWF",
    stop_name: "HICKORY RIDGE MALL",
    stop_desc: "WINCHESTER RD & S HICKORY RIDGE MALL",
    stop_lat: 35.049968,
    stop_lon: -89.861548,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 404,
    stop_code: "WINHICOB",
    stop_name: "WINCHESTER@HICKORY HILL MALL",
    stop_desc: "WINCHESTER RD & S HICKORY RIDGE MALL",
    stop_lat: 35.049805,
    stop_lon: -89.860785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 406,
    stop_code: "SHEROSEF",
    stop_name: "SHELBY@ROSS",
    stop_desc: "STATE HWY 175 & ROSS RD",
    stop_lat: 35.020316,
    stop_lon: -89.839439,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 430,
    stop_code: "FRAFRAWN",
    stop_name: "FRAYSER@FRAYSER SCHOOL",
    stop_desc: "FRAYSER BLVD & FRAYSER SCHOOL DR",
    stop_lat: 35.217906,
    stop_lon: -90.008916,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 433,
    stop_code: "KANMAJNF",
    stop_name: "KANSAS@MAJUBA",
    stop_desc: "KANSAS ST & MAJUBA AVE",
    stop_lat: 35.097019,
    stop_lon: -90.065845,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 435,
    stop_code: "KANBLANF",
    stop_name: "KANSAS@BLAIR HUNT N",
    stop_desc: "KANSAS ST & BLAIR HUNT DR",
    stop_lat: 35.098387,
    stop_lon: -90.065247,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 436,
    stop_code: "MILCINSN",
    stop_name: "MILLINGTON@CINDY LN",
    stop_desc: "MILLINGTON RD & CINDY LN",
    stop_lat: 35.214483,
    stop_lon: -90.028815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 437,
    stop_code: "WATCHESN",
    stop_name: "WATKINS@CHELSEA",
    stop_desc: "N WATKINS ST & PARNELL AVE",
    stop_lat: 35.173287,
    stop_lon: -90.008541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 438,
    stop_code: "WATCHENF",
    stop_name: "WATKINS@CHELSEA",
    stop_desc: "N WATKINS ST & PARNELL AVE",
    stop_lat: 35.173261,
    stop_lon: -90.008342,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 440,
    stop_code: "RAIBERWN",
    stop_name: "RAINES@BERRY BROOK",
    stop_desc: "E RAINES RD & BERRYBROOK RD",
    stop_lat: 35.035009,
    stop_lon: -89.863169,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 442,
    stop_code: "WINNGTWF",
    stop_name: "WINCHESTER RD@GETWELL RD",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.051969,
    stop_lon: -89.934936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 443,
    stop_code: "KANJOUNF",
    stop_name: "KANSAS@JOUBERT",
    stop_desc: "KANSAS ST & JOUBERT AVE",
    stop_lat: 35.094472,
    stop_lon: -90.067305,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 444,
    stop_code: "SECPEANN",
    stop_name: "SECOND@PEAR",
    stop_desc: "N 2ND ST",
    stop_lat: 35.182175,
    stop_lon: -90.044823,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 468,
    stop_code: "GEPMAIEN",
    stop_name: "GE PATTERSON@MAIN (Central Station)",
    stop_desc: "E GE PATTERSON & E GE PATTERSON AVE",
    stop_lat: 35.132749,
    stop_lon: -90.059424,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 469,
    stop_code: 2,
    stop_name: "WILLIAM HUDSON TRANSIT CENTER",
    stop_desc: "",
    stop_lat: 35.156554,
    stop_lon: -90.047698,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 476,
    stop_code: "MILFRASF",
    stop_name: "MILLINGTON@FRAYSER",
    stop_desc: "MILLINGTON RD & FRAYSER BLVD",
    stop_lat: 35.216128,
    stop_lon: -90.028748,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 477,
    stop_code: "SHECRUEF",
    stop_name: "SHELBY DR @ KIRBY PKWY",
    stop_desc: "STATE HWY 175 & CRUMPLER RD",
    stop_lat: 35.020351,
    stop_lon: -89.848147,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 482,
    stop_code: "SHEKIRWF",
    stop_name: "SHELBY@KIRBY PKWY",
    stop_desc: "STATE HWY 175 & KIRBY PKWY",
    stop_lat: 35.020513,
    stop_lon: -89.849107,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 499,
    stop_code: "STAELMWN",
    stop_name: "STAGE@ELMORE PARK",
    stop_desc: "STAGE RD & STATE HWY 15",
    stop_lat: 35.204656,
    stop_lon: -89.857073,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 500,
    stop_code: "GEPMAIWF",
    stop_name: "GE PATTERSON@MAIN (Central Station)",
    stop_desc: "E CALHOUN AVE & E GE PATTERSON AVE",
    stop_lat: 35.132883,
    stop_lon: -90.059424,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 502,
    stop_code: "MADDUNWN",
    stop_name: "MADISON AVE@DUNLAP ST WB",
    stop_desc: "MADISON AVE & N DUNLAP ST",
    stop_lat: 35.140545,
    stop_lon: -90.03244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 505,
    stop_code: "WHTCTRLY",
    stop_name: "WHTC@MAIN",
    stop_desc: "",
    stop_lat: 35.1571,
    stop_lon: -90.048019,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 510,
    stop_code: "MADDUNEM",
    stop_name: "MADISON AVE@DUNLAP ST EB",
    stop_desc: "",
    stop_lat: 35.140429,
    stop_lon: -90.032396,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 603,
    stop_code: "FROPOPSN",
    stop_name: "FRONT@POPLAR",
    stop_desc: "N FRONT ST & POPLAR AVE",
    stop_lat: 35.150621,
    stop_lon: -90.052183,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 604,
    stop_code: "FROADASM",
    stop_name: "FRONT@ADAMS",
    stop_desc: "",
    stop_lat: 35.148503,
    stop_lon: -90.052925,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 613,
    stop_code: "SECJACSM",
    stop_name: "SECOND@JACKSON",
    stop_desc: "N 2ND ST & JACKSON AVE",
    stop_lat: 35.153909,
    stop_lon: -90.048064,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 614,
    stop_code: "SECWINSM",
    stop_name: "SECOND@WINCHESTER",
    stop_desc: "N 2ND ST & N SECOND ST",
    stop_lat: 35.153319,
    stop_lon: -90.048286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 615,
    stop_code: "SECMARSN",
    stop_name: "SECOND@MARKET",
    stop_desc: "245 N 2ND ST & MARKET AVE",
    stop_lat: 35.151961,
    stop_lon: -90.048817,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 616,
    stop_code: "SECEXCSN",
    stop_name: "SECOND@EXCHANGE",
    stop_desc: "N SECOND ST & EXCHANGE AVE",
    stop_lat: 35.150996,
    stop_lon: -90.049205,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 617,
    stop_code: "SECADASM",
    stop_name: "SECOND@ADAMS",
    stop_desc: "N 2ND ST & ADAMS AVE",
    stop_lat: 35.147967,
    stop_lon: -90.050423,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 618,
    stop_code: "SECJEFSN",
    stop_name: "SECOND@JEFFERSON",
    stop_desc: "75 N 2ND ST & JEFFERSON AVE",
    stop_lat: 35.146984,
    stop_lon: -90.050833,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 619,
    stop_code: "SECSCOSN",
    stop_name: "SECOND@S COURT",
    stop_desc: "N 2ND ST & S COURT AVE",
    stop_lat: 35.145491,
    stop_lon: -90.051442,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 620,
    stop_code: "SECBEASN",
    stop_name: "SECOND@BEALE",
    stop_desc: "S SECOND ST & BEALE ST",
    stop_lat: 35.140139,
    stop_lon: -90.053535,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 621,
    stop_code: "SECLEESN",
    stop_name: "SECOND@LEE",
    stop_desc: "STATE HWY 14 & S 2ND ST",
    stop_lat: 35.139281,
    stop_lon: -90.053856,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 622,
    stop_code: "SECPONSM",
    stop_name: "SECOND@PONTOTOC",
    stop_desc: "STATE HWY 14 & PONTOTOC AVE",
    stop_lat: 35.137797,
    stop_lon: -90.054842,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 623,
    stop_code: "SECVANSN",
    stop_name: "SECOND@VANCE",
    stop_desc: "S SECOND ST & E PONTOTOC AVE",
    stop_lat: 35.136689,
    stop_lon: -90.055628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 624,
    stop_code: "SECBULSN",
    stop_name: "SECOND@BULTER",
    stop_desc: "STATE HWY 14 & HULING AVE",
    stop_lat: 35.134187,
    stop_lon: -90.056536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 625,
    stop_code: "THIKEESM",
    stop_name: "THIRD@KEEL",
    stop_desc: "",
    stop_lat: 35.163008,
    stop_lon: -90.042988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 626,
    stop_code: "THISAFSN",
    stop_name: "THIRD@SAFFARANS",
    stop_desc: "",
    stop_lat: 35.160622,
    stop_lon: -90.043951,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 627,
    stop_code: "THIMILSM",
    stop_name: "THIRD@MILL",
    stop_desc: "",
    stop_lat: 35.15909,
    stop_lon: -90.044559,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 628,
    stop_code: "THIAUCSM",
    stop_name: "THIRD@AUCTION",
    stop_desc: "",
    stop_lat: 35.15802,
    stop_lon: -90.044991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 629,
    stop_code: "THIAUCSN",
    stop_name: "THIRD@AUCTION",
    stop_desc: "",
    stop_lat: 35.156867,
    stop_lon: -90.045453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 630,
    stop_code: "THINPASN",
    stop_name: "THIRD@NORTH PARKWAY",
    stop_desc: "N 3RD ST & N THIRD ST",
    stop_lat: 35.155625,
    stop_lon: -90.045971,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 631,
    stop_code: "THIGEPNF",
    stop_name: "THIRD@GEPATTERSON",
    stop_desc: "STATE HWY 14 & S 3RD ST",
    stop_lat: 35.13307,
    stop_lon: -90.054577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 632,
    stop_code: "THIVANNM",
    stop_name: "THIRD@VANCE",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.135036,
    stop_lon: -90.054698,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 633,
    stop_code: "THIPEANN",
    stop_name: "THIRD@PEABODYPL",
    stop_desc: "",
    stop_lat: 35.140153,
    stop_lon: -90.051686,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 634,
    stop_code: "THIGAYNM",
    stop_name: "THIRD@GAYOSO",
    stop_desc: "",
    stop_lat: 35.141049,
    stop_lon: -90.051577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 635,
    stop_code: "THIUNINN",
    stop_name: "THIRD@UNION",
    stop_desc: "",
    stop_lat: 35.142507,
    stop_lon: -90.051111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 636,
    stop_code: "THIMADNM",
    stop_name: "THIRD@MADISION",
    stop_desc: "S 3RD ST & MADISON AVE",
    stop_lat: 35.14391,
    stop_lon: -90.050458,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 637,
    stop_code: "THICOUNM",
    stop_name: "THIRD@COURT",
    stop_desc: "N 3RD ST & MADISON AVE",
    stop_lat: 35.144776,
    stop_lon: -90.050103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 638,
    stop_code: "THICOUNN",
    stop_name: "THIRD@COURT",
    stop_desc: "N 3RD ST & COURT AVE",
    stop_lat: 35.145348,
    stop_lon: -90.049904,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 639,
    stop_code: "THIJEFNN",
    stop_name: "THIRD@JEFFERSON",
    stop_desc: "N 3RD ST & JEFFERSON AVE",
    stop_lat: 35.146322,
    stop_lon: -90.049483,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 640,
    stop_code: "THIADANN",
    stop_name: "THIRD@ADAMS",
    stop_desc: "N 3RD ST & N THIRD ST",
    stop_lat: 35.147314,
    stop_lon: -90.049073,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 641,
    stop_code: "THIWASNF",
    stop_name: "THIRD@WASHINGTON",
    stop_desc: "0 N 3RD ST & DRIVEWAY",
    stop_lat: 35.148655,
    stop_lon: -90.048553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 642,
    stop_code: "THIEXCNM",
    stop_name: "THIRD@EXCHANGE",
    stop_desc: "N 3RD ST & EXCHANGE AVE",
    stop_lat: 35.149825,
    stop_lon: -90.048076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 643,
    stop_code: "THIMARNM",
    stop_name: "THIRD@MARKET",
    stop_desc: "N 3RD ST & EXCHANGE AVE",
    stop_lat: 35.150763,
    stop_lon: -90.047689,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 644,
    stop_code: "THIJACNM",
    stop_name: "THIRD@JACKSON",
    stop_desc: "N 3RD ST & N THIRD ST",
    stop_lat: 35.152944,
    stop_lon: -90.046814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 645,
    stop_code: "THIOVENN",
    stop_name: "THIRD@OVERTON",
    stop_desc: "N 3RD ST & OVERTON AVE",
    stop_lat: 35.154311,
    stop_lon: -90.046271,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 646,
    stop_code: "THINPANN",
    stop_name: "THIRD@NORTH PARKWAY",
    stop_desc: "N 3RD ST & N THIRD ST",
    stop_lat: 35.15533,
    stop_lon: -90.045872,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 647,
    stop_code: "THIMILNM",
    stop_name: "THIRD@MILL",
    stop_desc: "",
    stop_lat: 35.157196,
    stop_lon: -90.045145,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 648,
    stop_code: "THIGRENM",
    stop_name: "THIRD@GREENLAW",
    stop_desc: "",
    stop_lat: 35.158769,
    stop_lon: -90.044515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 649,
    stop_code: "THILOONM",
    stop_name: "THIRD@LOONEY",
    stop_desc: "",
    stop_lat: 35.160698,
    stop_lon: -90.043742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 650,
    stop_code: "THIKEENM",
    stop_name: "THIRD@KEEL",
    stop_desc: "",
    stop_lat: 35.162012,
    stop_lon: -90.043219,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 651,
    stop_code: "THICHENN",
    stop_name: "THIRD@CHELSEA",
    stop_desc: "",
    stop_lat: 35.163191,
    stop_lon: -90.042746,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 656,
    stop_code: "DANCHENF",
    stop_name: "DANNY THOMAS@CHELSEA",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.164335,
    stop_lon: -90.03658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 657,
    stop_code: "DANGUTNM",
    stop_name: "DANNY THOMAS@GUTHRIE",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.165613,
    stop_lon: -90.036403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 658,
    stop_code: "DANHENNF",
    stop_name: "DANNY THOMAS@HENDERSON",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.168133,
    stop_lon: -90.036081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 659,
    stop_code: "DANWELNN",
    stop_name: "WELLS@DANNY THOMAS",
    stop_desc: "US HWY 51 & WELLS AVE",
    stop_lat: 35.17093,
    stop_lon: -90.03598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 661,
    stop_code: "DANFIRNN",
    stop_name: "DANNY THOMAS@FIRESTONE",
    stop_desc: "US HWY 51 & FIRESTONE AVE",
    stop_lat: 35.174621,
    stop_lon: -90.035812,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 662,
    stop_code: "DANFIRNF",
    stop_name: "DANNY THOMAS@FIRESTONE",
    stop_desc: "US HWY 51 & FIRESTONE AVE",
    stop_lat: 35.175193,
    stop_lon: -90.035779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 663,
    stop_code: "DANWEANN",
    stop_name: "DANNY THOMAS@WEAKLEY",
    stop_desc: "US HWY 51 & WEAKLEY AVE",
    stop_lat: 35.176641,
    stop_lon: -90.0357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 664,
    stop_code: "DANCORNN",
    stop_name: "DANNY THOMAS@CORRINE",
    stop_desc: "US HWY 51 & CORRINE AVE",
    stop_lat: 35.179465,
    stop_lon: -90.035555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 665,
    stop_code: "DANPLUNN",
    stop_name: "DANNY THOMAS@PLUM",
    stop_desc: "US HWY 51 & PLUM AVE",
    stop_lat: 35.180644,
    stop_lon: -90.03551,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 666,
    stop_code: "DANROYNN",
    stop_name: "DANNY THOMAS@ROYAL",
    stop_desc: "",
    stop_lat: 35.182119,
    stop_lon: -90.035521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 669,
    stop_code: "DANWHINN",
    stop_name: "DANNY THOMAS@WHITNEY",
    stop_desc: "",
    stop_lat: 35.208837,
    stop_lon: -90.032762,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 673,
    stop_code: "DANROYSM",
    stop_name: "DANNYTHOMAS@ROYAL",
    stop_desc: "",
    stop_lat: 35.187874,
    stop_lon: -90.035275,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 674,
    stop_code: "DANRO1SM",
    stop_name: "DANNYTHOMAS@ROYAL",
    stop_desc: "THOMAS ST",
    stop_lat: 35.186194,
    stop_lon: -90.035397,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 675,
    stop_code: "DANRO2SM",
    stop_name: "DANNY THOMAS@ROYAL",
    stop_desc: "",
    stop_lat: 35.183566,
    stop_lon: -90.035487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 676,
    stop_code: "DANPLUSN",
    stop_name: "DANNY THOMAS@PLUM",
    stop_desc: "STATE HWY 3 & PLUM AVE",
    stop_lat: 35.180841,
    stop_lon: -90.035699,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 677,
    stop_code: "DANCORSF",
    stop_name: "DANNY THOMAS@CORRINE",
    stop_desc: "US HWY 51 & CORRINE AVE",
    stop_lat: 35.17934,
    stop_lon: -90.035766,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 678,
    stop_code: "DANHIRSN",
    stop_name: "DANNY THOMAS@HURON",
    stop_desc: "US HWY 51 & HURON AVE",
    stop_lat: 35.177999,
    stop_lon: -90.035833,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 679,
    stop_code: "DANWEASN",
    stop_name: "DANNY THOMAS@WEAKLEY",
    stop_desc: "US HWY 51 & WEAKLEY AVE",
    stop_lat: 35.17707,
    stop_lon: -90.035877,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 680,
    stop_code: "DANFIRSF",
    stop_name: "DANNY THOMAS@FIRESTONE",
    stop_desc: "0 THOMAS ST & FIRESTONE AVE",
    stop_lat: 35.174737,
    stop_lon: -90.036011,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 681,
    stop_code: "DANHENSM",
    stop_name: "DANNY THOMAS@HENDERSON",
    stop_desc: "0 THOMAS ST & FLEMING AVE",
    stop_lat: 35.168589,
    stop_lon: -90.03628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 682,
    stop_code: "DANWELSN",
    stop_name: "DANNY THOMAS@WELLS",
    stop_desc: "US HWY 51 & WELLS AVE",
    stop_lat: 35.171127,
    stop_lon: -90.036168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 683,
    stop_code: "DANHENSN",
    stop_name: "DANNY THOMAS@HENDERSON",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.167696,
    stop_lon: -90.036313,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 684,
    stop_code: "DANCHESM",
    stop_name: "DANNYTHOMAS@CHELSEA",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.164979,
    stop_lon: -90.036691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 685,
    stop_code: "DANCHESN",
    stop_name: "DANNY THOMAS@CHELSEA",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.163907,
    stop_lon: -90.036824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 688,
    stop_code: "DANJACSN",
    stop_name: "DANNY THOMAS@JACKSON",
    stop_desc: "US HWY 51 & JACKSON AVE",
    stop_lat: 35.156239,
    stop_lon: -90.03758,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 694,
    stop_code: "OVETROSN",
    stop_name: "OVERTON-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.15516,
    stop_lon: -90.048861,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 695,
    stop_code: "OVETRONN",
    stop_name: "OVERTON-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.155125,
    stop_lon: -90.048839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 696,
    stop_code: "JEFTROSN",
    stop_name: "JEFFERSON-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.147243,
    stop_lon: -90.052017,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 697,
    stop_code: "MAINCORT",
    stop_name: "COURT-MAIN/TRO NB",
    stop_desc: "N MAIN ST",
    stop_lat: 35.146895,
    stop_lon: -90.052128,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 700,
    stop_code: "UNITRONN",
    stop_name: "UNION-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.143177,
    stop_lon: -90.053623,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 701,
    stop_code: "UNITROSN",
    stop_name: "UNION-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.14324,
    stop_lon: -90.053623,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 702,
    stop_code: "BEATRONF",
    stop_name: "BEALE-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.140604,
    stop_lon: -90.054675,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 703,
    stop_code: "BEATROSN",
    stop_name: "BEALE-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.140434,
    stop_lon: -90.054764,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 704,
    stop_code: "MLKTROSN",
    stop_name: "MLK JR-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.139049,
    stop_lon: -90.055705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 706,
    stop_code: "HULTROSN",
    stop_name: "HULING-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.135546,
    stop_lon: -90.05824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 707,
    stop_code: "BUTMAIN",
    stop_name: "BUTLER-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.134107,
    stop_lon: -90.058948,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 708,
    stop_code: "BUTTRONB",
    stop_name: "BULTER-MAIN/TRO",
    stop_desc: "",
    stop_lat: 35.134062,
    stop_lon: -90.058948,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 709,
    stop_code: "HULTRONN",
    stop_name: "HULING-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.13543,
    stop_lon: -90.058295,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 711,
    stop_code: "MLKTRONF",
    stop_name: "MLK JR-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.138888,
    stop_lon: -90.055782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 714,
    stop_code: "GEPTHIEM",
    stop_name: "GEPATTERSON@THIRD",
    stop_desc: "E CALHOUN AVE & S THIRD ST",
    stop_lat: 35.132641,
    stop_lon: -90.054754,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 715,
    stop_code: "GEPHEREN",
    stop_name: "GEPATTERSON@HERNANDO",
    stop_desc: "",
    stop_lat: 35.132619,
    stop_lon: -90.052132,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 716,
    stop_code: "GEPFOUWN",
    stop_name: "GEPATTERSON@FOURTH",
    stop_desc: "",
    stop_lat: 35.132686,
    stop_lon: -90.050026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 717,
    stop_code: "GEPTHIWM",
    stop_name: "GEPATTERSON@THIRD",
    stop_desc: "E CALHOUN AVE & ABEL ST",
    stop_lat: 35.132784,
    stop_lon: -90.053957,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 720,
    stop_code: "THITROEB",
    stop_name: "MADISON@THIRD/TRO",
    stop_desc: "MADISON AVE & N 3RD ST",
    stop_lat: 35.144124,
    stop_lon: -90.048897,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 726,
    stop_code: "MADCLAEF",
    stop_name: "MADISON@CLAYBROOK",
    stop_desc: "MADISON AVE & N CLAYBROOK ST",
    stop_lat: 35.138931,
    stop_lon: -90.017478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 727,
    stop_code: "MADCLEEN",
    stop_name: "MADISON@CLEVELAND",
    stop_desc: "",
    stop_lat: 35.138862,
    stop_lon: -90.016593,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 728,
    stop_code: "MADWATEN",
    stop_name: "MADISON@WATKINS",
    stop_desc: "MADISON AVE & N WATKINS ST",
    stop_lat: 35.138652,
    stop_lon: -90.014545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 729,
    stop_code: "MADRAIEN",
    stop_name: "MADISON@RAINE PL",
    stop_desc: "MADISON AVE & RAINE PL",
    stop_lat: 35.138527,
    stop_lon: -90.013328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 730,
    stop_code: "MADMCNEN",
    stop_name: "MADISON@MCNEIL",
    stop_desc: "MADISON AVE & RAINE PL",
    stop_lat: 35.138419,
    stop_lon: -90.012111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 731,
    stop_code: "MADWILEM",
    stop_name: "MADISON@WILLET",
    stop_desc: "MADISON AVE & N WILLETT ST",
    stop_lat: 35.138212,
    stop_lon: -90.009953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 732,
    stop_code: "MADAVAEN",
    stop_name: "MADISON@AVALON",
    stop_desc: "MADISON AVE & S AVALON ST",
    stop_lat: 35.138032,
    stop_lon: -90.008138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 733,
    stop_code: "MADANGEM",
    stop_name: "MADISON@ANGELUS",
    stop_desc: "MADISON AVE & ANGELUS ST",
    stop_lat: 35.137943,
    stop_lon: -90.007208,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 735,
    stop_code: "MADEVEEN",
    stop_name: "MADISON@EVERGREEN",
    stop_desc: "MADISON AVE & S EVERGREEN ST",
    stop_lat: 35.138057,
    stop_lon: -90.0037,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 736,
    stop_code: "MADAUBEN",
    stop_name: "MADISON@AUBURNDALE",
    stop_desc: "MADISON AVE & S AUBURNDALE ST",
    stop_lat: 35.137931,
    stop_lon: -90.002549,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 737,
    stop_code: "MADIDLEN",
    stop_name: "MADISON@IDLEWILD",
    stop_desc: "MADISON AVE & N IDLEWILD ST",
    stop_lat: 35.137787,
    stop_lon: -90.001277,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 738,
    stop_code: "MADMCLEM",
    stop_name: "MADISON@MCLEAN",
    stop_desc: "MADISON AVE & S IDLEWILD ST",
    stop_lat: 35.137706,
    stop_lon: -90.00048,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 739,
    stop_code: "MADBAREM",
    stop_name: "MADISON@BARKSDALE/TRO",
    stop_desc: "0 MADISON AVE & S MCLEAN BLVD",
    stop_lat: 35.137527,
    stop_lon: -89.998886,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 740,
    stop_code: "MADTUCEN",
    stop_name: "MADISON@TUCKER",
    stop_desc: "MADISON AVE & N TUCKER ST",
    stop_lat: 35.137311,
    stop_lon: -89.997094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 741,
    stop_code: "MADREMEN",
    stop_name: "MADISON@REMBERT",
    stop_desc: "MADISON AVE & N TUCKER ST",
    stop_lat: 35.137149,
    stop_lon: -89.995622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 742,
    stop_code: "MADMOREN",
    stop_name: "MADISON@MORRISON",
    stop_desc: "MADISON AVE & S MORRISON ST",
    stop_lat: 35.137015,
    stop_lon: -89.994305,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 743,
    stop_code: "MADDIAEN",
    stop_name: "MADISON@DIANA",
    stop_desc: "MADISON AVE & OVERTON SQ",
    stop_lat: 35.136871,
    stop_lon: -89.993032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 747,
    stop_code: "MADCOOWF",
    stop_name: "MADISON AVE@COOPER ST",
    stop_desc: "MADISON AVE & OVERTON SQ",
    stop_lat: 35.136798,
    stop_lon: -89.990963,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 748,
    stop_code: "MADMORWN",
    stop_name: "MADISON@MORRISON",
    stop_desc: "? MADISON AVE & N MORRISON ST",
    stop_lat: 35.137121,
    stop_lon: -89.993785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 749,
    stop_code: "MADREMWN",
    stop_name: "MADISON@REMBERT",
    stop_desc: "OVERTON SQ & N REMBERT ST",
    stop_lat: 35.137283,
    stop_lon: -89.995312,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 750,
    stop_code: "MADTUCWN",
    stop_name: "MADISON@TUCKER",
    stop_desc: "MADISON AVE & N TUCKER ST",
    stop_lat: 35.137436,
    stop_lon: -89.996739,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 751,
    stop_code: "MADMCLWM",
    stop_name: "MADISON@MCLEAN",
    stop_desc: "0 MADISON AVE & N MCLEAN BLVD",
    stop_lat: 35.137733,
    stop_lon: -89.999218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 752,
    stop_code: "MADIDLWN",
    stop_name: "MADISON@IDLEWILD",
    stop_desc: "MADISON AVE & N IDLEWILD ST",
    stop_lat: 35.137921,
    stop_lon: -90.000978,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 753,
    stop_code: "MADAUBWF",
    stop_name: "MADISON@AUBURNDALE",
    stop_desc: "0 MADISON AVE & S EVERGREEN ST",
    stop_lat: 35.13811,
    stop_lon: -90.002604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 754,
    stop_code: "MADEVEWN",
    stop_name: "MADISON@EVERGREEN",
    stop_desc: "MADISON AVE & N EVERGREEN ST",
    stop_lat: 35.138191,
    stop_lon: -90.003401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 755,
    stop_code: "MADBELWN",
    stop_name: "MADISON@BELVEDERE",
    stop_desc: "MADISON AVE & N BELVEDERE BLVD",
    stop_lat: 35.138102,
    stop_lon: -90.004785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 756,
    stop_code: "MADANGWN",
    stop_name: "MADISON@ANGELUS",
    stop_desc: "MADISON AVE & ANGELUS ST",
    stop_lat: 35.138005,
    stop_lon: -90.006223,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 757,
    stop_code: "MADAVAWM",
    stop_name: "MADISON@AVALON",
    stop_desc: "? MADISON AVE & S AVALON ST",
    stop_lat: 35.138094,
    stop_lon: -90.007098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 758,
    stop_code: "MADWILWN",
    stop_name: "MADISON@WILLETT",
    stop_desc: "MADISON AVE & N AVALON ST",
    stop_lat: 35.138247,
    stop_lon: -90.008658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 759,
    stop_code: "MADMCNWN",
    stop_name: "MADISON@MCNEIL",
    stop_desc: "MADISON AVE & N MCNEIL ST",
    stop_lat: 35.138553,
    stop_lon: -90.011878,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 760,
    stop_code: "MADWATWN",
    stop_name: "MADISON@WATKINS",
    stop_desc: "MADISON AVE & N WATKINS ST",
    stop_lat: 35.138759,
    stop_lon: -90.013992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 761,
    stop_code: "MADCLEWN",
    stop_name: "MADISON@CLEVELAND",
    stop_desc: "? MADISON AVE & N CLEVELAND ST",
    stop_lat: 35.138939,
    stop_lon: -90.015895,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 763,
    stop_code: "MADMONWF",
    stop_name: "MADISON@MONTGOMERY",
    stop_desc: "",
    stop_lat: 35.139271,
    stop_lon: -90.019846,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 767,
    stop_code: "ORLTROWB",
    stop_name: "MADISON@ORLEANS/TRO WB",
    stop_desc: "MADISON AVE",
    stop_lat: 35.141127,
    stop_lon: -90.037465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 768,
    stop_code: "DTHTROWB",
    stop_name: "MADISON@DANNYTHOMAS/TRO WB",
    stop_desc: "MADISON AVE",
    stop_lat: 35.142603,
    stop_lon: -90.042389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 772,
    stop_code: "VANTHIEN",
    stop_name: "VANCE@THIRD",
    stop_desc: "VANCE AVE & S THIRD ST",
    stop_lat: 35.135876,
    stop_lon: -90.054554,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 773,
    stop_code: "VANDANEM",
    stop_name: "VANCE@DANNYTHOMAS",
    stop_desc: "VANCE AVE & S FOURTH ST",
    stop_lat: 35.13499,
    stop_lon: -90.048069,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 774,
    stop_code: "VANDANEN",
    stop_name: "VANCE@DANNY THOMAS",
    stop_desc: "VANCE AVE & S DANNY THOMAS BLVD",
    stop_lat: 35.134874,
    stop_lon: -90.046764,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 775,
    stop_code: "VANLAU",
    stop_name: "VANCE@LAUDERDALE",
    stop_desc: "VANCE AVE & S LAUDERDALE ST",
    stop_lat: 35.134632,
    stop_lon: -90.044218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 776,
    stop_code: "VANLAUEF",
    stop_name: "VANCE@LAUDERDALE",
    stop_desc: "VANCE AVE & S LAUDERDALE ST",
    stop_lat: 35.134533,
    stop_lon: -90.043211,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 777,
    stop_code: "VANORLEN",
    stop_name: "VANCE@ORLEANS",
    stop_desc: "VANCE AVE & S ORLEANS ST",
    stop_lat: 35.134238,
    stop_lon: -90.040157,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 778,
    stop_code: "VANBOYEN",
    stop_name: "VANCE@BOYD",
    stop_desc: "VANCE AVE & BOYD ST",
    stop_lat: 35.133915,
    stop_lon: -90.036715,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 779,
    stop_code: "VANWALEF",
    stop_name: "VANCE@WALNUT",
    stop_desc: "VANCE AVE & WALNUT ST",
    stop_lat: 35.133709,
    stop_lon: -90.03458,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 780,
    stop_code: "VANEASEN",
    stop_name: "VANCE@EAST",
    stop_desc: "VANCE AVE & WALNUT ST",
    stop_lat: 35.133458,
    stop_lon: -90.031592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 781,
    stop_code: "VANEASWF",
    stop_name: "VANCE@EAST",
    stop_desc: "VANCE AVE & STATE HWY 3",
    stop_lat: 35.133619,
    stop_lon: -90.031592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 782,
    stop_code: "VANDUNWN",
    stop_name: "VANCE@DUNLAP",
    stop_desc: "VANCE AVE & S DUNLAP ST",
    stop_lat: 35.133753,
    stop_lon: -90.033196,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 783,
    stop_code: "VANWALWN",
    stop_name: "VANCE@WALNUT",
    stop_desc: "VANCE AVE & WALNUT ST",
    stop_lat: 35.133897,
    stop_lon: -90.034878,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 784,
    stop_code: "VANBOYWN",
    stop_name: "VANCE@BOYD",
    stop_desc: "VANCE AVE & BOYD ST",
    stop_lat: 35.134049,
    stop_lon: -90.036461,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 785,
    stop_code: "VANBOYWF",
    stop_name: "VANCE@BOYD",
    stop_desc: "VANCE AVE & BOYD ST",
    stop_lat: 35.134175,
    stop_lon: -90.037811,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 786,
    stop_code: "VANORLWN",
    stop_name: "ORLEANS@VANCE",
    stop_desc: "VANCE AVE & S ORLEANS ST",
    stop_lat: 35.134372,
    stop_lon: -90.03988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 787,
    stop_code: "VANORLWF",
    stop_name: "VANCE AVE@ORLEANS ST",
    stop_desc: "VANCE AVE & S ORLEANS ST",
    stop_lat: 35.134435,
    stop_lon: -90.0406,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 788,
    stop_code: "VANCYNWN",
    stop_name: "VANCE@CYNTHIA",
    stop_desc: "VANCE AVE & CYNTHIA PL",
    stop_lat: 35.134506,
    stop_lon: -90.041385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 789,
    stop_code: "VANLAUWN",
    stop_name: "VANCE@LAUDERDALE",
    stop_desc: "VANCE AVE & S LAUDERDALE ST",
    stop_lat: 35.13473,
    stop_lon: -90.043576,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 790,
    stop_code: "VANDANWN",
    stop_name: "VANCE@DANNYTHOMAS",
    stop_desc: "VANCE AVE & S DANNY THOMAS BLVD",
    stop_lat: 35.134972,
    stop_lon: -90.046133,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 791,
    stop_code: "VANFOUWM",
    stop_name: "VANCE@FOURTH",
    stop_desc: "VANCE AVE & S 4TH ST",
    stop_lat: 35.135187,
    stop_lon: -90.048357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 792,
    stop_code: "VANFOUWN",
    stop_name: "VANCE@FOURTH",
    stop_desc: "VANCE AVE & S 4TH ST",
    stop_lat: 35.13533,
    stop_lon: -90.049895,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 793,
    stop_code: "VANHERWN",
    stop_name: "VANCE@HERNANDO",
    stop_desc: "VANCE AVE & HERNANDO ST",
    stop_lat: 35.1355,
    stop_lon: -90.051478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 798,
    stop_code: "FOUWEBSN",
    stop_name: "FOURTH@WEBSTER",
    stop_desc: "",
    stop_lat: 35.130363,
    stop_lon: -90.050485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 800,
    stop_code: "FOUCRUSM",
    stop_name: "FOURTH@CRUMP",
    stop_desc: "S FOURTH ST & S 4TH ST",
    stop_lat: 35.12794,
    stop_lon: -90.050827,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 804,
    stop_code: "FOUGEONN",
    stop_name: "4TH ST@GEORGIA AVE",
    stop_desc: "FOURTH & GEORGIA",
    stop_lat: 35.128977,
    stop_lon: -90.05046,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 805,
    stop_code: "FOUWEBNF",
    stop_name: "FOURTH@WEBSTER",
    stop_desc: "",
    stop_lat: 35.130308,
    stop_lon: -90.050386,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 809,
    stop_code: "AUCFIFEF",
    stop_name: "AUCTION@FIFTH",
    stop_desc: "AUCTION AVE & N SIXTH ST",
    stop_lat: 35.155802,
    stop_lon: -90.041798,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 810,
    stop_code: "AUCDANEM",
    stop_name: "AUCTION@DANNYTHOMAS",
    stop_desc: "AUCTION AVE & N SEVENTH ST",
    stop_lat: 35.155757,
    stop_lon: -90.039473,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 811,
    stop_code: "AUCDANEN",
    stop_name: "AUCTION@DANNY THOMAS",
    stop_desc: "AUCTION AVE & STATE HWY 14",
    stop_lat: 35.155971,
    stop_lon: -90.037735,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 812,
    stop_code: "AUCDANWF",
    stop_name: "AUCTION@DANNY THOMAS",
    stop_desc: "AUCTION AVE & N 7TH ST",
    stop_lat: 35.155909,
    stop_lon: -90.039341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 813,
    stop_code: "AUCSEVWN",
    stop_name: "AUCTION@SEVENTH",
    stop_desc: "AUCTION AVE & N 7TH ST",
    stop_lat: 35.155927,
    stop_lon: -90.040326,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 814,
    stop_code: "AUCFIFWN",
    stop_name: "AUCTION@FIFTH",
    stop_desc: "AUCTION AVE & N 5TH ST",
    stop_lat: 35.155981,
    stop_lon: -90.042794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 815,
    stop_code: "AUCTHIWN",
    stop_name: "AUCTION@THIRD",
    stop_desc: "AUCTION AVE & N 3RD ST",
    stop_lat: 35.156491,
    stop_lon: -90.045407,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 822,
    stop_code: "JACTHIWM",
    stop_name: "JACKSON@THIRD",
    stop_desc: "JACKSON AVE & N 3RD ST",
    stop_lat: 35.153507,
    stop_lon: -90.046592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 828,
    stop_code: "ADAHIGEM",
    stop_name: "ADAMS@HIGH",
    stop_desc: "ADAMS AVE",
    stop_lat: 35.145847,
    stop_lon: -90.043141,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 829,
    stop_code: "ADAHIGEN",
    stop_name: "ADAMS@HIGH",
    stop_desc: "ADAMS AVE & HIGH ST",
    stop_lat: 35.145516,
    stop_lon: -90.042056,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 830,
    stop_code: "ADANEEEN",
    stop_name: "ADAMS@NEELY",
    stop_desc: "ADAMS AVE & NEELY ST",
    stop_lat: 35.145033,
    stop_lon: -90.040286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 831,
    stop_code: "ADAORLEN",
    stop_name: "ADAMS@ORLEANS",
    stop_desc: "ADAMS AVE & N ORLEANS ST",
    stop_lat: 35.1443,
    stop_lon: -90.03753,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 832,
    stop_code: "ADAMANEN",
    stop_name: "ADAMS@MANASSAS",
    stop_desc: "? ADAMS AVE & N ORLEANS ST",
    stop_lat: 35.143745,
    stop_lon: -90.035416,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 833,
    stop_code: "ADADUNEN",
    stop_name: "ADAMS@DUNLAP",
    stop_desc: "ADAMS AVE & N DUNLAP ST",
    stop_lat: 35.143503,
    stop_lon: -90.033214,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 834,
    stop_code: "ADADUNEF",
    stop_name: "ADAMS@DUNLAP",
    stop_desc: "ADAMS AVE & N DUNLAP ST",
    stop_lat: 35.143432,
    stop_lon: -90.032417,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 835,
    stop_code: "ADAJEFEN",
    stop_name: "ADAMS@JEFFERSON",
    stop_desc: "RAMP & ADAMS AVE",
    stop_lat: 35.143056,
    stop_lon: -90.030536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 836,
    stop_code: "ADAJEFWF",
    stop_name: "ADAMS@JEFFERSON",
    stop_desc: "ADAMS AVE & RAMP",
    stop_lat: 35.143288,
    stop_lon: -90.030679,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 837,
    stop_code: "ADADUNWN",
    stop_name: "ADAMS@DUNLAP",
    stop_desc: "ADAMS AVE & N DUNLAP ST",
    stop_lat: 35.143637,
    stop_lon: -90.032893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 838,
    stop_code: "ADADUNWF",
    stop_name: "ADAMS@DUNLAP",
    stop_desc: "ADAMS AVE & N DUNLAP ST",
    stop_lat: 35.143682,
    stop_lon: -90.033402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 839,
    stop_code: "ADAMANWF",
    stop_name: "ADAMS@MANASSAS",
    stop_desc: "ADAMS AVE & N DUNLAP ST",
    stop_lat: 35.143996,
    stop_lon: -90.035671,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 840,
    stop_code: "ADAORLWN",
    stop_name: "ADAMS@ORLEANS",
    stop_desc: "ADAMS AVE & N ORLEANS ST",
    stop_lat: 35.144389,
    stop_lon: -90.037165,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 841,
    stop_code: "ADANEEWF",
    stop_name: "ADAMS@NEELY",
    stop_desc: "ADAMS AVE & NEELY ST",
    stop_lat: 35.145185,
    stop_lon: -90.040175,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 842,
    stop_code: "ADADANWM",
    stop_name: "ADAMS@DANNYTHOMAS",
    stop_desc: "ADAMS AVE & NEELY ST",
    stop_lat: 35.145981,
    stop_lon: -90.043119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 843,
    stop_code: "ADAFOUWN",
    stop_name: "ADAMS@FOURTH",
    stop_desc: "ADAMS AVE & N FOURTH ST",
    stop_lat: 35.146777,
    stop_lon: -90.046196,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 844,
    stop_code: "ADATHIWN",
    stop_name: "ADAMS@THIRD",
    stop_desc: "ADAMS AVE & N THIRD ST",
    stop_lat: 35.14752,
    stop_lon: -90.048918,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 846,
    stop_code: "JEFFOUEN",
    stop_name: "JEFFERSON@FOURTH",
    stop_desc: "JEFFERSON AVE & N FOURTH ST",
    stop_lat: 35.145723,
    stop_lon: -90.046948,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 848,
    stop_code: "JEFHAMEM",
    stop_name: "JEFFERSON@HAMLIN PL",
    stop_desc: "JEFFERSON AVE & NEELY ST",
    stop_lat: 35.143791,
    stop_lon: -90.039854,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 850,
    stop_code: "JEFORLEF",
    stop_name: "JEFFERSON@ORLEANS",
    stop_desc: "JEFFERSON AVE & N ORLEANS ST",
    stop_lat: 35.143111,
    stop_lon: -90.037066,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 852,
    stop_code: "JEFDUNEN",
    stop_name: "JEFFERSON@DUNLAP",
    stop_desc: "",
    stop_lat: 35.142547,
    stop_lon: -90.033402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 854,
    stop_code: "JEFPAUEN",
    stop_name: "JEFFERSON@PAULINE",
    stop_desc: "JEFFERSON AVE & N NEW PAULINE ST",
    stop_lat: 35.142671,
    stop_lon: -90.028389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 856,
    stop_code: "JEFBELEN",
    stop_name: "JEFFERSON@BELLEVUE",
    stop_desc: "0 JEFFERSON AVE & N BELLEVUE BLVD",
    stop_lat: 35.141998,
    stop_lon: -90.02135,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 857,
    stop_code: "JEFMONEN",
    stop_name: "JEFFERSON@MONTGOMERY",
    stop_desc: "",
    stop_lat: 35.141827,
    stop_lon: -90.019324,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 861,
    stop_code: "JEFBELWF",
    stop_name: "JEFFERSON@BELLEVUE",
    stop_desc: "JEFFERSON AVE & N BELLEVUE BLVD",
    stop_lat: 35.142203,
    stop_lon: -90.021936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 863,
    stop_code: "JEFPAUWF",
    stop_name: "JEFFERSON@PAULINE",
    stop_desc: "JEFFERSON AVE & N NEW PAULINE ST",
    stop_lat: 35.142804,
    stop_lon: -90.028079,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 864,
    stop_code: "JEFADAWN",
    stop_name: "JEFFERSON@ADAMS",
    stop_desc: "JEFFERSON AVE & RAMP",
    stop_lat: 35.142867,
    stop_lon: -90.02923,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 867,
    stop_code: "JEFDUNWF",
    stop_name: "JEFFERSON@DUNLAP",
    stop_desc: "JEFFERSON AVE & N 3RD ST",
    stop_lat: 35.142699,
    stop_lon: -90.033956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 868,
    stop_code: "JEFMANWF",
    stop_name: "JEFFERSON@MANASSAS",
    stop_desc: "JEFFERSON AVE & N 3RD ST",
    stop_lat: 35.142923,
    stop_lon: -90.035738,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 870,
    stop_code: "JEFNEEWM",
    stop_name: "JEFFERSON@NEELY",
    stop_desc: "JEFFERSON AVE & N MONTGOMERY ST",
    stop_lat: 35.143907,
    stop_lon: -90.039323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 871,
    stop_code: "JEFFOUWF",
    stop_name: "JEFFERSON@FOURTH",
    stop_desc: "JEFFERSON AVE & N FOURTH ST",
    stop_lat: 35.145929,
    stop_lon: -90.047048,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 872,
    stop_code: "JEFTHIWN",
    stop_name: "JEFFERSON@THIRD",
    stop_desc: "JEFFERSON AVE & N THIRD ST",
    stop_lat: 35.146528,
    stop_lon: -90.049273,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 877,
    stop_code: "MLKTHIWN",
    stop_name: "MLK BLVD@THIRD",
    stop_desc: "LINDEN AVE & S 3RD ST",
    stop_lat: 35.137583,
    stop_lon: -90.052938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 899,
    stop_code: "GERWOLSN",
    stop_name: "GERMANTOWN PKY@WOLFCREEK PKY",
    stop_desc: "N GERMANTOWN PKY & N GERMANTOWN PKWY",
    stop_lat: 35.202288,
    stop_lon: -89.792338,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 900,
    stop_code: "GERGIASN",
    stop_name: "GERMANTOWN PKY@GIACOSA",
    stop_desc: "N GERMANTOWN PKY & STATE HWY 177",
    stop_lat: 35.199813,
    stop_lon: -89.792457,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 901,
    stop_code: "GERROCSF",
    stop_name: "GERMANTOWN@ROCK CREEK",
    stop_desc: "GERMANTOWN PKWY & N GERMANTOWN PKWY",
    stop_lat: 35.191458,
    stop_lon: -89.79272,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 902,
    stop_code: "GERMARSN",
    stop_name: "GERMANTOWN PKY@IKEA WAY",
    stop_desc: "N GERMANTOWN PKY & N GERMANTOWN PKWY",
    stop_lat: 35.188822,
    stop_lon: -89.792796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 903,
    stop_code: "GERWOOSF",
    stop_name: "GERMANTOWN PKY@WOODCHASE",
    stop_desc: "N GERMANTOWN PKY & BURWELL DR",
    stop_lat: 35.177536,
    stop_lon: -89.793158,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 904,
    stop_code: "GERDEXSN",
    stop_name: "GERMANTOWN PKWY@DEXTER RD",
    stop_desc: "N GERMANTOWN PKY & DEXTER RD",
    stop_lat: 35.174971,
    stop_lon: -89.793167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 950,
    stop_code: "GERDEXNF",
    stop_name: "GERMANTOWN PKWY @ DEXTER RD",
    stop_desc: "N GERMANTOWN PKY & N GERMANTOWN PKWY",
    stop_lat: 35.174372,
    stop_lon: -89.792782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 951,
    stop_code: "GERCHINF",
    stop_name: "GERMANTOWN PKY@CHIMNEY ROCK",
    stop_desc: "N GERMANTOWN PKY & AMBERGATE LN",
    stop_lat: 35.182486,
    stop_lon: -89.792609,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 952,
    stop_code: "GERBELNN",
    stop_name: "GERMANTOWN PKWY@BELLEVUE PKWY",
    stop_desc: "BELLEVUE PKY & N GERMANTOWN PKY",
    stop_lat: 35.185149,
    stop_lon: -89.79261,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 953,
    stop_code: "GERVARNF",
    stop_name: "GERMANTOWN PKY@VARNAVAS",
    stop_desc: "N GERMANTOWN PKY & GERMANTOWN PKWY",
    stop_lat: 35.188723,
    stop_lon: -89.792431,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 955,
    stop_code: "GERGIANF",
    stop_name: "GERMANTOWN@GIACOSA",
    stop_desc: "STATE HWY 177 & GIACOSA PL",
    stop_lat: 35.199427,
    stop_lon: -89.792027,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 956,
    stop_code: "GERWOLNF",
    stop_name: "GERMANTOWN@WOLFCREEK PKWY",
    stop_desc: "STATE HWY 177 & N GERMANTOWN PKWY",
    stop_lat: 35.202546,
    stop_lon: -89.791927,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 957,
    stop_code: "FLESTASF",
    stop_name: "FLETCHER TRACE PKWY@STAGE RD",
    stop_desc: "FLETCHER TRACE PKWY & HIGHWAY 64",
    stop_lat: 35.203791,
    stop_lon: -89.760653,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 958,
    stop_code: "FLEFLESN",
    stop_name: "FLETCHER TRACE@FLETCHER VIEW",
    stop_desc: "FLETCHER TRACE PKWY & HIGHWAY 64",
    stop_lat: 35.200987,
    stop_lon: -89.758294,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 959,
    stop_code: "FLEBEREN",
    stop_name: "FLETCHER TRACE@BERRY HILL",
    stop_desc: "FLETCHER TRACE PKWY & MORNING TRACE DR",
    stop_lat: 35.201552,
    stop_lon: -89.755445,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 960,
    stop_code: "BERMORNF",
    stop_name: "BERRYHILL@MORNING PARK",
    stop_desc: "BERRYHILL RD & MORNING PARK DR",
    stop_lat: 35.202514,
    stop_lon: -89.754466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 961,
    stop_code: "STAFLEWM",
    stop_name: "STAGE@FLETCHER TRACE",
    stop_desc: "US HWY 64 & FLETCHER TRACE PKWY",
    stop_lat: 35.204581,
    stop_lon: -89.758689,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 962,
    stop_code: "STAROCWF",
    stop_name: "STAGE@ROCK CREEK PKWY",
    stop_desc: "STATE HWY 15 & ROCKCREEK PKWY",
    stop_lat: 35.204553,
    stop_lon: -89.765113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 963,
    stop_code: "STAWALWM",
    stop_name: "STAGE@WALMART DR",
    stop_desc: "? US HWY 64 & CONNECTING RD",
    stop_lat: 35.204484,
    stop_lon: -89.7802,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 964,
    stop_code: "STAWALWN",
    stop_name: "STAGE@WALMART DR",
    stop_desc: "US HWY 64 & CONNECTING RD",
    stop_lat: 35.204488,
    stop_lon: -89.781818,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 965,
    stop_code: "STAKATWN",
    stop_name: "STAGE@KATE HYDE BLVD",
    stop_desc: "US HWY 64 & UNNAMED ST",
    stop_lat: 35.204538,
    stop_lon: -89.78431,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 966,
    stop_code: "STAGERWM",
    stop_name: "STAGE@GERMANTOWN",
    stop_desc: "0 64 & CONNECTING RD",
    stop_lat: 35.204575,
    stop_lon: -89.788375,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 967,
    stop_code: "STAGERWF",
    stop_name: "STAGE@GERMANTOWN",
    stop_desc: "STAGE RD & CONNECTING RD",
    stop_lat: 35.204756,
    stop_lon: -89.793082,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 968,
    stop_code: "STAWOLWN",
    stop_name: "STAGE@WOLFCREEK PKWY",
    stop_desc: "? US HWY 64 & WOLFCREEK PKWY",
    stop_lat: 35.204806,
    stop_lon: -89.795574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 969,
    stop_code: "STAKATWM",
    stop_name: "STAGE@KATE BOND",
    stop_desc: "STAGE RD & KATE BOND RD",
    stop_lat: 35.204808,
    stop_lon: -89.799949,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 971,
    stop_code: "STAKATWF",
    stop_name: "STAGE@KATE BOND",
    stop_desc: "US HWY 64 & KATE BOND RD",
    stop_lat: 35.204797,
    stop_lon: -89.803084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 972,
    stop_code: "STAAPPWF",
    stop_name: "STAGE@APPLING",
    stop_desc: "US HWY 64 & APPLING RD",
    stop_lat: 35.204837,
    stop_lon: -89.809099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 973,
    stop_code: "STABROWN",
    stop_name: "STAGE@BROTHER",
    stop_desc: "STAGE RD & BROTHER BLVD",
    stop_lat: 35.204867,
    stop_lon: -89.814504,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 974,
    stop_code: "STACHRWF",
    stop_name: "STAGE@CHRISTOVAL",
    stop_desc: "STATE HWY 15 & CHRISTOVAL ST",
    stop_lat: 35.204743,
    stop_lon: -89.828041,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 975,
    stop_code: "STASUMWN",
    stop_name: "STAGE@SUMMER",
    stop_desc: "",
    stop_lat: 35.205372,
    stop_lon: -89.832995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 976,
    stop_code: "STASUMWF",
    stop_name: "STAGE RD@SUMMER AVE",
    stop_desc: "STAGE RD & STATE HWY 15",
    stop_lat: 35.205643,
    stop_lon: -89.835404,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 977,
    stop_code: "STAKIRWM",
    stop_name: "STAGE@KIRBY WHITTEN",
    stop_desc: "STAGE RD & KIRBY WHITTEN RD",
    stop_lat: 35.20516,
    stop_lon: -89.839903,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 978,
    stop_code: "STAKI1WM",
    stop_name: "STAGE@KIRBY WHITTEN",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204905,
    stop_lon: -89.841997,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 979,
    stop_code: "STACHAWN",
    stop_name: "STAGE@CHARLES BRYAN",
    stop_desc: "STATE HWY 15",
    stop_lat: 35.204777,
    stop_lon: -89.844966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 980,
    stop_code: "STASTAWM",
    stop_name: "STAGE@STAGE VILLAGE",
    stop_desc: "STAGE RD & STAGE VILLAGE CV",
    stop_lat: 35.204769,
    stop_lon: -89.854825,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 981,
    stop_code: "STABARWM",
    stop_name: "STAGE@BARTLETT BLVD",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204816,
    stop_lon: -89.861592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 982,
    stop_code: "STABARWF",
    stop_name: "STAGE@BARTLETT BLVD",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204583,
    stop_lon: -89.866013,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 983,
    stop_code: "STACOUWM",
    stop_name: "STAGE@COURT",
    stop_desc: "STATE HWY 15 & COURT ST",
    stop_lat: 35.204241,
    stop_lon: -89.869891,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 984,
    stop_code: "STASHEWF",
    stop_name: "STAGE@SHELBY",
    stop_desc: "STATE HWY 15 & SHELBY ST",
    stop_lat: 35.204758,
    stop_lon: -89.874608,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 985,
    stop_code: "STAOLDWF",
    stop_name: "STAGE@OLD BROWNSVILLE",
    stop_desc: "STATE HWY 15 & OLD BROWNSVILLE RD",
    stop_lat: 35.205306,
    stop_lon: -89.881408,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 987,
    stop_code: "STASPRWF",
    stop_name: "STAGE@SPRINGLAND",
    stop_desc: "STATE HWY 15 & SPRINGLAND ST",
    stop_lat: 35.205812,
    stop_lon: -89.885317,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 988,
    stop_code: "STALYNWN",
    stop_name: "STAGE@LYNCHBURG",
    stop_desc: "",
    stop_lat: 35.206388,
    stop_lon: -89.888051,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 989,
    stop_code: "STACLEWN",
    stop_name: "STAGE@CLEARWOOD",
    stop_desc: "RALEIGH BARTLETT RD & CLEARWOOD RD",
    stop_lat: 35.206967,
    stop_lon: -89.892547,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 990,
    stop_code: "STACOVWN",
    stop_name: "STAGE@COVINGTON PIKE",
    stop_desc: "STATE HWY 15 & RALEIGH BARTLETT RD",
    stop_lat: 35.207086,
    stop_lon: -89.894275,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 991,
    stop_code: "STACOVWF",
    stop_name: "STAGE@COVINGTON PIKE",
    stop_desc: "5046 STAGE ROAD & STAGE RD",
    stop_lat: 35.207141,
    stop_lon: -89.895316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 992,
    stop_code: "STACREWM",
    stop_name: "STAGE@CREEK VIEW DR",
    stop_desc: "RALEIGH BARTLETT RD & STAGE RD",
    stop_lat: 35.207228,
    stop_lon: -89.899525,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 993,
    stop_code: "STAKENWF",
    stop_name: "STAGE@KENNETH",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.207147,
    stop_lon: -89.905806,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 994,
    stop_code: "STAROBWN",
    stop_name: "STAGE@ROBBIEDON",
    stop_desc: "STATE HWY 15 & ROBBIEDON ST",
    stop_lat: 35.206864,
    stop_lon: -89.908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 995,
    stop_code: "STAFARWN",
    stop_name: "STAGE@FARGO",
    stop_desc: "STATE HWY 15 & FARGO RD",
    stop_lat: 35.206519,
    stop_lon: -89.910239,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 996,
    stop_code: "STASCHWN",
    stop_name: "STAGE@SCHEIBLER",
    stop_desc: "STATE HWY 15 & RALEIGH BARTLETT RD",
    stop_lat: 35.206262,
    stop_lon: -89.912167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 997,
    stop_code: "STACOLWN",
    stop_name: "STAGE@COLEMAN",
    stop_desc: "RALEIGH BARTLETT RD & COLEMAN RD",
    stop_lat: 35.206041,
    stop_lon: -89.913795,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 998,
    stop_code: "STATENWF",
    stop_name: "STAGE@TENA",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.205811,
    stop_lon: -89.915668,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 999,
    stop_code: "STAAUSWM",
    stop_name: "STAGE@AUSTIN PEAY",
    stop_desc: "STATE HWY 15 & EAST RD",
    stop_lat: 35.20538,
    stop_lon: -89.920709,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1000,
    stop_code: "STAEASEN",
    stop_name: "STAGE@EAST",
    stop_desc: "STATE HWY 15 & EAST ST",
    stop_lat: 35.205066,
    stop_lon: -89.920255,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1001,
    stop_code: "STAHAMEF",
    stop_name: "STAGE@HAMMOND",
    stop_desc: "RALEIGH BARTLETT RD & BEVERLY HILLS RD",
    stop_lat: 35.205268,
    stop_lon: -89.917463,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1002,
    stop_code: "STABEVEN",
    stop_name: "STAGE@BEVERLY HILLS",
    stop_desc: "STATE HWY 15 & BEVERLY HILLS RD",
    stop_lat: 35.20541,
    stop_lon: -89.916267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1003,
    stop_code: "STACOLEN",
    stop_name: "STAGE@COLEMAN",
    stop_desc: "STATE HWY 15 & COLEMAN RD",
    stop_lat: 35.205648,
    stop_lon: -89.914106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1004,
    stop_code: "STAFAREN",
    stop_name: "STAGE@FARGO",
    stop_desc: "STATE HWY 15 & FARGO RD",
    stop_lat: 35.206091,
    stop_lon: -89.910838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1005,
    stop_code: "STAKENEN",
    stop_name: "STAGE@KENNETH",
    stop_desc: "RALEIGH BARTLETT RD & KENNETH ST",
    stop_lat: 35.206887,
    stop_lon: -89.905231,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1006,
    stop_code: "STACREEM",
    stop_name: "STAGE@CREEK VIEW",
    stop_desc: "4829 STATE HWY 15 & CREEK VIEW DR",
    stop_lat: 35.206954,
    stop_lon: -89.902074,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1007,
    stop_code: "STACREEF",
    stop_name: "STAGE@CREEK VIEW CIR",
    stop_desc: "STATE HWY 15 & CONNECTING RD",
    stop_lat: 35.206915,
    stop_lon: -89.899482,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1008,
    stop_code: "STACOVEM",
    stop_name: "STAGE@COVINGTON PIKE",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.20684,
    stop_lon: -89.897078,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1009,
    stop_code: "STACOVEN",
    stop_name: "STAGE RD@COVINGTON PIKE",
    stop_desc: "5065 STAGE & COVINGTON PIKE",
    stop_lat: 35.206834,
    stop_lon: -89.895133,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1010,
    stop_code: "STACLEEN",
    stop_name: "STAGE@CLEARWOOD",
    stop_desc: "STATE HWY 15 & CLEARWOOD RD",
    stop_lat: 35.206664,
    stop_lon: -89.892847,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1011,
    stop_code: "STALYNEN",
    stop_name: "STAGE@LYNCHBURG",
    stop_desc: "STATE HWY 15 & LYNCHBURG ST",
    stop_lat: 35.206255,
    stop_lon: -89.888572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1012,
    stop_code: "STASPREN",
    stop_name: "STAGE@SPRINGLAND",
    stop_desc: "RALEIGH BARTLETT RD & SPRINGLAND ST",
    stop_lat: 35.205509,
    stop_lon: -89.885484,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1013,
    stop_code: "STAOLDEN",
    stop_name: "STAGE@OLD BROWNSVILLE",
    stop_desc: "STATE HWY 15 & OLD BROWNSVILLE RD",
    stop_lat: 35.204966,
    stop_lon: -89.881287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1014,
    stop_code: "STABAREF",
    stop_name: "STAGE@BARTLETT",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204655,
    stop_lon: -89.86136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1015,
    stop_code: "STASHEEM",
    stop_name: "STAGE@SHELBY",
    stop_desc: "STATE HWY 15 & SHELBY ST",
    stop_lat: 35.204571,
    stop_lon: -89.874553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1016,
    stop_code: "STASHEEF",
    stop_name: "STAGE@SHELBY",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204166,
    stop_lon: -89.872915,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1017,
    stop_code: "STACOUEN",
    stop_name: "STAGE@COURT",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.204119,
    stop_lon: -89.871464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1018,
    stop_code: "STACOUEF",
    stop_name: "STAGE@COURT",
    stop_desc: "STATE HWY 15 & COURT ST",
    stop_lat: 35.20408,
    stop_lon: -89.869935,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1019,
    stop_code: "STABAREN",
    stop_name: "STAGE@BARTLETT BLVD",
    stop_desc: "STAGE RD & STATE HWY 15",
    stop_lat: 35.204616,
    stop_lon: -89.864584,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1020,
    stop_code: "STAELMEN",
    stop_name: "STAGE@ELMORE PARK",
    stop_desc: "STAGE RD & BARTLETT CREST DR",
    stop_lat: 35.204506,
    stop_lon: -89.857639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1021,
    stop_code: "STASTAEF",
    stop_name: "STAGE@STAGE VILLAGE",
    stop_desc: "STATE HWY 15 & STAGE VILLAGE CV",
    stop_lat: 35.204617,
    stop_lon: -89.854747,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1024,
    stop_code: "STAKIREN",
    stop_name: "STAGE@KIRBY WHITTEN",
    stop_desc: "STAGE RD & KIRBY WHITTEN RD",
    stop_lat: 35.204697,
    stop_lon: -89.840779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1025,
    stop_code: "STAWHIEM",
    stop_name: "STAGE@WHITTEN",
    stop_desc: "STAGE RD & STATE HWY 15",
    stop_lat: 35.205237,
    stop_lon: -89.837798,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1026,
    stop_code: "STASUMEM",
    stop_name: "STAGE@SUMMER",
    stop_desc: "",
    stop_lat: 35.205384,
    stop_lon: -89.835876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1027,
    stop_code: "STASUMEF",
    stop_name: "STAGE RD@SUMMER AVE",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.205184,
    stop_lon: -89.83381,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1028,
    stop_code: "STAVANEN",
    stop_name: "STAGE@VAN DER VEER",
    stop_desc: "STAGE RD & VAN DER VEER DR",
    stop_lat: 35.204603,
    stop_lon: -89.829315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1029,
    stop_code: "STADROEM",
    stop_name: "STAGE@DROMEDARY",
    stop_desc: "STATE HWY 15 & STAGE RD",
    stop_lat: 35.203741,
    stop_lon: -89.823104,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1031,
    stop_code: "STAAPPEN",
    stop_name: "STAGE@APPLING",
    stop_desc: "US HWY 64 & STAGE RD",
    stop_lat: 35.204511,
    stop_lon: -89.815081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1032,
    stop_code: "STAAPPEM",
    stop_name: "STAGE@APPLING WAY",
    stop_desc: "US HWY 64",
    stop_lat: 35.204569,
    stop_lon: -89.813054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1033,
    stop_code: "STAAPPEF",
    stop_name: "STAGE@APPLING WAY",
    stop_desc: "US HWY 64 & APPLING RD",
    stop_lat: 35.2045,
    stop_lon: -89.810053,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1034,
    stop_code: "STAKATEF",
    stop_name: "STAGE@KATE BOND",
    stop_desc: "US HWY 64 & KATE BOND RD",
    stop_lat: 35.204463,
    stop_lon: -89.801479,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1035,
    stop_code: "STAWOLEM",
    stop_name: "STAGE@WOLFCREEK PKWY",
    stop_desc: "STAGE RD & STATE HWY 15",
    stop_lat: 35.204459,
    stop_lon: -89.799729,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1036,
    stop_code: "STAWOLEF",
    stop_name: "STAGE RD@WOLFCREEK PKY",
    stop_desc: "US HWY 64 & WOLFCREEK PKWY",
    stop_lat: 35.204501,
    stop_lon: -89.795043,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1037,
    stop_code: "STAGEREF",
    stop_name: "STAGE@GERMANTOWN PKWY",
    stop_desc: "? US HWY 64 & N GERMANTOWN PKWY",
    stop_lat: 35.204301,
    stop_lon: -89.789616,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1039,
    stop_code: "STAMALEN",
    stop_name: "STAGE@MALL DR",
    stop_desc: "US HWY 64 & UNNAMED ST",
    stop_lat: 35.204219,
    stop_lon: -89.785164,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1040,
    stop_code: "STAWALEN",
    stop_name: "STAGE@WALMART DR",
    stop_desc: "US HWY 64 & STAGE RD",
    stop_lat: 35.204132,
    stop_lon: -89.782605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1041,
    stop_code: "STANEWEM",
    stop_name: "STAGE@NEW BRUNSWICK",
    stop_desc: "STAGE RD & CONNECTING RD",
    stop_lat: 35.204134,
    stop_lon: -89.77977,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1042,
    stop_code: "STAROCEF",
    stop_name: "STAGE@ROCKCREEK PKWY",
    stop_desc: "STATE HWY 15 & ROCKCREEK PKWY",
    stop_lat: 35.204378,
    stop_lon: -89.762999,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1070,
    stop_code: "COLSTASF",
    stop_name: "COLEMAN@STAGE",
    stop_desc: "COLEMAN RD & STAGE RD",
    stop_lat: 35.204852,
    stop_lon: -89.913698,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1071,
    stop_code: "COLSTOSF",
    stop_name: "COLEMAN@STONE GATE DR",
    stop_desc: "COLEMAN RD & STAGE RD",
    stop_lat: 35.201295,
    stop_lon: -89.912919,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1072,
    stop_code: "RALPARSM",
    stop_name: "RALEIGH LAGRANGE@PARKVIEW",
    stop_desc: "RALEIGH LAGRANGE RD & STAGE RD",
    stop_lat: 35.199504,
    stop_lon: -89.910541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1073,
    stop_code: "RALPARSN",
    stop_name: "RALEIGH LAGRANGE@PARKVIEW",
    stop_desc: "RALEIGH LAGRANGE RD & PARKVIEW DR",
    stop_lat: 35.198519,
    stop_lon: -89.908505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1074,
    stop_code: "RALHOOSF",
    stop_name: "RALEIGH LAGRANGE@HOOVER",
    stop_desc: "RALEIGH LAGRANGE RD & PARKVIEW DR",
    stop_lat: 35.197771,
    stop_lon: -89.904276,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1075,
    stop_code: "RALDEVSF",
    stop_name: "RALEIGH LAGRANGE@DEVEREUX",
    stop_desc: "RALEIGH LAGRANGE RD & PARKVIEW DR",
    stop_lat: 35.195945,
    stop_lon: -89.901876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1076,
    stop_code: "RALBATSN",
    stop_name: "RALEIGH LAGRANGE@BATTLE CREEK",
    stop_desc: "RALEIGH LAGRANGE RD & BATTLE CREEK DR",
    stop_lat: 35.195138,
    stop_lon: -89.900006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1077,
    stop_code: "RALCOVSN",
    stop_name: "RALEIGH LAGRANG@COVINGTON PIKE",
    stop_desc: "",
    stop_lat: 35.193433,
    stop_lon: -89.896065,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1078,
    stop_code: "COVCHISM",
    stop_name: "COVINGTON PIKE@CHISWOOD",
    stop_desc: "COVINGTON PIKE & CONNECTING RD",
    stop_lat: 35.191816,
    stop_lon: -89.895982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1079,
    stop_code: "COVELMSF",
    stop_name: "COVINGTON PIKE@ELMORE",
    stop_desc: "COVINGTON PIKE & STATE HWY 204",
    stop_lat: 35.185285,
    stop_lon: -89.896462,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1080,
    stop_code: "COVCOVSF",
    stop_name: "COVINGTON PIKE@COVINGTON WAY",
    stop_desc: "STATE HWY 204 & COVINGTON WAY",
    stop_lat: 35.182237,
    stop_lon: -89.896514,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1081,
    stop_code: "COVPLESM",
    stop_name: "COVINGTON PIKE@PLEASANT VIEW RD",
    stop_desc: "STATE HWY 204 & COVINGTON PIKE",
    stop_lat: 35.180084,
    stop_lon: -89.896574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1082,
    stop_code: "COVPL1SM",
    stop_name: "SHELBY OAKS@SYCAMORE VIEW",
    stop_desc: "STATE HWY 204 & COVINGTON PIKE",
    stop_lat: 35.176867,
    stop_lon: -89.896802,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1083,
    stop_code: "COVMCCSN",
    stop_name: "COVINGTON PIKE@MCCRORY",
    stop_desc: "COVINGTON PIKE & GERMANTOWN RD",
    stop_lat: 35.161224,
    stop_lon: -89.906004,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1084,
    stop_code: "MACBERWN",
    stop_name: "MACON@BERCLAIR",
    stop_desc: "MACON RD & BERCLAIR RD",
    stop_lat: 35.161999,
    stop_lon: -89.910874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1085,
    stop_code: "MACPALWN",
    stop_name: "MACON@PALLWOOD",
    stop_desc: "MACON RD & PALLWOOD RD",
    stop_lat: 35.162001,
    stop_lon: -89.912623,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1086,
    stop_code: "MACWEIWN",
    stop_name: "MACON@WEINER",
    stop_desc: "MACON RD & WEINER RD",
    stop_lat: 35.162058,
    stop_lon: -89.915136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1087,
    stop_code: "WARMACSF",
    stop_name: "WARING RD@MACON RD",
    stop_desc: "WARING RD & EMILY AVE",
    stop_lat: 35.161471,
    stop_lon: -89.917484,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1088,
    stop_code: "WARFERSN",
    stop_name: "WARING@FERNDALE",
    stop_desc: "WARING RD & FERNDALE RD",
    stop_lat: 35.158734,
    stop_lon: -89.915707,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1089,
    stop_code: "WAROWESN",
    stop_name: "WARING@OWEN",
    stop_desc: "WARING RD & OWEN RD",
    stop_lat: 35.155955,
    stop_lon: -89.915779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1090,
    stop_code: "WARPOWSM",
    stop_name: "WARING@POWELL",
    stop_desc: "WARING RD & POWELL AVE",
    stop_lat: 35.154588,
    stop_lon: -89.915837,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1091,
    stop_code: "WARSUMSN",
    stop_name: "WARING RD@SUMMER AVE",
    stop_desc: "? WARING RD & POWELL AVE",
    stop_lat: 35.149253,
    stop_lon: -89.916125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1092,
    stop_code: "WARSUMSF",
    stop_name: "WARING@SUMMER",
    stop_desc: "WARING RD & STANLEY DR",
    stop_lat: 35.148118,
    stop_lon: -89.916171,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1093,
    stop_code: "WARESTSN",
    stop_name: "WARING@ESTRIDGE",
    stop_desc: "WARING RD & SAM COOPER BLVD",
    stop_lat: 35.145437,
    stop_lon: -89.916232,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1094,
    stop_code: "WARSEQSF",
    stop_name: "WARING@SEQUOIA",
    stop_desc: "WARING RD & SEQUOIA RD",
    stop_lat: 35.141353,
    stop_lon: -89.916251,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1095,
    stop_code: "WARCHISN",
    stop_name: "WARING@CHICKASAW",
    stop_desc: "WARING RD & CHICKASAW RD",
    stop_lat: 35.139754,
    stop_lon: -89.916321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1096,
    stop_code: "WARWALSM",
    stop_name: "WARING@WALNUT GROVE",
    stop_desc: "WARING RD & STATE HWY 23",
    stop_lat: 35.131702,
    stop_lon: -89.916624,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1097,
    stop_code: "WARWALNF",
    stop_name: "WARING@WALNUT GROVE",
    stop_desc: "WARING RD & STATE HWY 23",
    stop_lat: 35.131568,
    stop_lon: -89.916437,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1098,
    stop_code: "WARNORNN",
    stop_name: "WARING@NORMANDY",
    stop_desc: "WARING RD & NORMANDY AVE",
    stop_lat: 35.134231,
    stop_lon: -89.916299,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1099,
    stop_code: "WARBARNN",
    stop_name: "WARING@BARFIELD",
    stop_desc: "WARING RD & BARFIELD RD",
    stop_lat: 35.135553,
    stop_lon: -89.916263,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1100,
    stop_code: "WARCHINN",
    stop_name: "WARING@CHICKASAW",
    stop_desc: "WARING RD & CHICKASAW RD",
    stop_lat: 35.139503,
    stop_lon: -89.916133,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1101,
    stop_code: "WARSEQNN",
    stop_name: "WARING@SEQUOIA",
    stop_desc: "WARING RD & SEQUOIA RD",
    stop_lat: 35.141746,
    stop_lon: -89.91604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1102,
    stop_code: "WARPRINM",
    stop_name: "WARING@PRINCETON",
    stop_desc: "WARING RD & SEQUOIA RD",
    stop_lat: 35.143435,
    stop_lon: -89.915981,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1103,
    stop_code: "WARSTANM",
    stop_name: "WARING@STANLEY",
    stop_desc: "WARING RD & W FOY CIR",
    stop_lat: 35.146635,
    stop_lon: -89.915997,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1104,
    stop_code: "WARSUMNN",
    stop_name: "WARING RD@SUMMER AVE",
    stop_desc: "",
    stop_lat: 35.148344,
    stop_lon: -89.915982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1105,
    stop_code: "WARTUTNM",
    stop_name: "WARING@TUTWILER",
    stop_desc: "WARING RD & W FOY CIR",
    stop_lat: 35.149878,
    stop_lon: -89.915869,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1106,
    stop_code: "WARCASNN",
    stop_name: "WARING@CASTLE",
    stop_desc: "WARING RD & CASTLE AVE",
    stop_lat: 35.152264,
    stop_lon: -89.91572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1107,
    stop_code: "WARPOWNN",
    stop_name: "WARING@POWELL",
    stop_desc: "WARING RD & POWELL AVE",
    stop_lat: 35.154043,
    stop_lon: -89.915661,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1108,
    stop_code: "WAROWENN",
    stop_name: "WARING@OWEN",
    stop_desc: "WARING RD & OWEN RD",
    stop_lat: 35.155428,
    stop_lon: -89.915603,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1109,
    stop_code: "WARGIVNN",
    stop_name: "WARING@GIVEN",
    stop_desc: "WARING RD & GIVEN AVE",
    stop_lat: 35.156813,
    stop_lon: -89.915567,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1110,
    stop_code: "WARFERNN",
    stop_name: "WARING@FERNDALE",
    stop_desc: "WARING RD & FERNDALE RD",
    stop_lat: 35.158484,
    stop_lon: -89.915508,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1111,
    stop_code: "WARMACNN",
    stop_name: "WARING RD@MACON RD",
    stop_desc: "",
    stop_lat: 35.161909,
    stop_lon: -89.917284,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1112,
    stop_code: "MACWEIEN",
    stop_name: "MACON@WEINER",
    stop_desc: "MACON RD & WARING RD",
    stop_lat: 35.161925,
    stop_lon: -89.916044,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1113,
    stop_code: "MACLOREN",
    stop_name: "MACON@LORRAINE",
    stop_desc: "MACON RD & LORRAINE ST",
    stop_lat: 35.161888,
    stop_lon: -89.914616,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1114,
    stop_code: "MACPALEF",
    stop_name: "MACON@PALLWOOD",
    stop_desc: "MACON RD & PALLWOOD RD",
    stop_lat: 35.161841,
    stop_lon: -89.913055,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1115,
    stop_code: "MACBEREN",
    stop_name: "MACON@BERCLAIR",
    stop_desc: "MACON RD & PALLWOOD RD",
    stop_lat: 35.161839,
    stop_lon: -89.911439,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1116,
    stop_code: "MACBEREF",
    stop_name: "MACON@BERCLAIR",
    stop_desc: "MACON RD & BERCLAIR RD",
    stop_lat: 35.161819,
    stop_lon: -89.909822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1117,
    stop_code: "MCCSTREN",
    stop_name: "MCCRORY@STRATFORD",
    stop_desc: "MCCRORY AVE & MACON RD",
    stop_lat: 35.160839,
    stop_lon: -89.906071,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1118,
    stop_code: "STRMACNN",
    stop_name: "STRATFORD@MACON",
    stop_desc: "",
    stop_lat: 35.161813,
    stop_lon: -89.905732,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1119,
    stop_code: "COVPLENF",
    stop_name: "COVINGTON PIKE@PLEASANT VIEW RD",
    stop_desc: "COVINGTON PIKE & STATE HWY 204",
    stop_lat: 35.176724,
    stop_lon: -89.896626,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1120,
    stop_code: "COVCOVNF",
    stop_name: "COVINGTON PIKE@COVINGTON WAY",
    stop_desc: "STATE HWY 204 & COVINGTON PIKE",
    stop_lat: 35.183283,
    stop_lon: -89.896301,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1121,
    stop_code: "COVELMNF",
    stop_name: "COVINGTON PIKE@ELMORE",
    stop_desc: "STATE HWY 204 & COVINGTON PIKE",
    stop_lat: 35.186106,
    stop_lon: -89.89615,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1122,
    stop_code: "COVCHINN",
    stop_name: "COVINGTON PIKE@CHISWOOD",
    stop_desc: "COVINGTON PIKE & CHISWOOD ST",
    stop_lat: 35.190511,
    stop_lon: -89.895731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1123,
    stop_code: "RALCOVNF",
    stop_name: "RALEIGH LAGRANGE RD@COVINGTON PIKE",
    stop_desc: "RALEIGH LAGRANGE RD & RAMP",
    stop_lat: 35.193998,
    stop_lon: -89.896808,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1124,
    stop_code: "RALBATNF",
    stop_name: "RALEIGH LAGRANG@BATTLE CREEK",
    stop_desc: "RALEIGH LAGRANGE RD & BATTLE CREEK DR",
    stop_lat: 35.195343,
    stop_lon: -89.900039,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1125,
    stop_code: "RALBARNN",
    stop_name: "RALEIGH LAGRANG@BARBARA CIRCLE",
    stop_desc: "RALEIGH LAGRANGE RD & W BARBARA CIR",
    stop_lat: 35.196678,
    stop_lon: -89.902539,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1126,
    stop_code: "RALHOONF",
    stop_name: "RALEIGH LAGRANG@HOOVER",
    stop_desc: "RALEIGH LAGRANGE RD & W BARBARA CIR",
    stop_lat: 35.198192,
    stop_lon: -89.904851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1127,
    stop_code: "RALKENWF",
    stop_name: "RALEIGH LAGRANGE@KENNEDY PARK",
    stop_desc: "RALEIGH LAGRANGE RD & KENNEDY PARK",
    stop_lat: 35.198957,
    stop_lon: -89.909091,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1128,
    stop_code: "COLSTONN",
    stop_name: "COLEMAN@STONE GATE DR",
    stop_desc: "COLEMAN RD & KENNEDY PARK",
    stop_lat: 35.201366,
    stop_lon: -89.912742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1129,
    stop_code: "COLSTANM",
    stop_name: "COLEMAN@STAGE",
    stop_desc: "",
    stop_lat: 35.205675,
    stop_lon: -89.913763,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1130,
    stop_code: "COLMAGNN",
    stop_name: "COLEMAN@MAGEVNEY",
    stop_desc: "COLEMAN RD & MAGEVNEY ST",
    stop_lat: 35.206891,
    stop_lon: -89.914303,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1131,
    stop_code: "COLPRINN",
    stop_name: "COLEMAN@PRISCILLA",
    stop_desc: "COLEMAN RD & TANBARK AVE",
    stop_lat: 35.20967,
    stop_lon: -89.914574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1133,
    stop_code: "COLAUSSF",
    stop_name: "COLEMAN@AUSTIN PEAY",
    stop_desc: "COLEMAN RD & TANBARK AVE",
    stop_lat: 35.21313,
    stop_lon: -89.916008,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1134,
    stop_code: "COLPRISN",
    stop_name: "COLEMAN@PRISCILLA",
    stop_desc: "COLEMAN RD & LOCKWOOD CV",
    stop_lat: 35.210153,
    stop_lon: -89.914751,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1135,
    stop_code: "COLSTASN",
    stop_name: "COLEMAN@STAGE",
    stop_desc: "COLEMAN RD & STAGE RD",
    stop_lat: 35.206319,
    stop_lon: -89.91426,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1144,
    stop_code: "COVAUSNM",
    stop_name: "COVINGTON PIKE@AUSTIN PEAY",
    stop_desc: "W K SINGLETON PKWY & NEW COVINGTON PIKE",
    stop_lat: 35.231309,
    stop_lon: -89.892369,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1145,
    stop_code: "COVAU1NM",
    stop_name: "COVINGTON PIKE@AUSTIN PEAY",
    stop_desc: "3964 COVINGTON PIKE & NEW COVINGTON PIKE",
    stop_lat: 35.233491,
    stop_lon: -89.893605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1151,
    stop_code: "AUSCOLSM",
    stop_name: "AUSTIN PEAY@COLEMAN",
    stop_desc: "STATE HWY 14 & AUSTIN PEAY HWY",
    stop_lat: 35.215684,
    stop_lon: -89.914717,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1152,
    stop_code: "AUSCOLSN",
    stop_name: "AUSTIN PEAY@COLEMAN",
    stop_desc: "STATE HWY 14 & COLEMAN RD",
    stop_lat: 35.213846,
    stop_lon: -89.916593,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1154,
    stop_code: "AUSCOLSF",
    stop_name: "AUSTIN PEAY@COLEMAN RD",
    stop_desc: "STATE HWY 14 & OVERBROOK RD",
    stop_lat: 35.21356,
    stop_lon: -89.916849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1155,
    stop_code: "AUSJONSN",
    stop_name: "AUSTIN PEAY@JONES",
    stop_desc: "3175 AUSTIN PEAY HWY & OVERBROOK DR",
    stop_lat: 35.211909,
    stop_lon: -89.918503,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1156,
    stop_code: "AUSSTASN",
    stop_name: "AUSTIN PEAY@STAGE",
    stop_desc: "STATE HWY 14 BUS & AUSTIN PEAY HWY",
    stop_lat: 35.205703,
    stop_lon: -89.921672,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1157,
    stop_code: "AUSSTANF",
    stop_name: "AUSTIN PEAY@STAGE",
    stop_desc: "STATE HWY 14 BUS & AUSTIN PEAY HWY",
    stop_lat: 35.20572,
    stop_lon: -89.921472,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1158,
    stop_code: "AUSJONNN",
    stop_name: "AUSTIN PEAY@JONES",
    stop_desc: "STATE HWY 14 & STATE HWY 14 BUS",
    stop_lat: 35.211204,
    stop_lon: -89.918958,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1159,
    stop_code: "AUSOVENF",
    stop_name: "AUSTIN PEAY@OVERBROOK",
    stop_desc: "STATE HWY 14 & AUSTIN PEAY HWY",
    stop_lat: 35.213087,
    stop_lon: -89.917104,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1160,
    stop_code: "AUSCOLNF",
    stop_name: "AUSTIN PEAY@COLEMAN",
    stop_desc: "3254 AUSTIN PEAY HWY & COLEMAN RD",
    stop_lat: 35.214604,
    stop_lon: -89.915584,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1161,
    stop_code: "AUSYA1NM",
    stop_name: "AUSTIN PEAY@YALE",
    stop_desc: "STATE HWY 14 & AUSTIN PEAY HWY",
    stop_lat: 35.216362,
    stop_lon: -89.91383,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1162,
    stop_code: "AUSYA2NM",
    stop_name: "AUSTIN PEAY@YALE",
    stop_desc: "STATE HWY 14 & AUSTIN PEAY HWY",
    stop_lat: 35.218183,
    stop_lon: -89.912042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1163,
    stop_code: "AUSYA3NM",
    stop_name: "AUSTIN PEAY@YALE RD",
    stop_desc: "AUSTIN PEAY HWY & STATE HWY 14",
    stop_lat: 35.219486,
    stop_lon: -89.910743,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1167,
    stop_code: "YALGLEWF",
    stop_name: "YALE@GLENSHAW",
    stop_desc: "YALE RD & COLEMAN CV",
    stop_lat: 35.220696,
    stop_lon: -89.920624,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1168,
    stop_code: "YALLEHWM",
    stop_name: "YALE@LEHI",
    stop_desc: "YALE RD & LEHI DR",
    stop_lat: 35.220558,
    stop_lon: -89.92428,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1182,
    stop_code: "YALRAMEF",
    stop_name: "YALE@RAMILL",
    stop_desc: "YALE RD & RAMILL RD",
    stop_lat: 35.220444,
    stop_lon: -89.926485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1183,
    stop_code: "YALCOLEF",
    stop_name: "YALE@COLEMAN",
    stop_desc: "YALE RD & COLEMAN RD",
    stop_lat: 35.221754,
    stop_lon: -89.916123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1184,
    stop_code: "YALAUSEM",
    stop_name: "YALE RD@AUSTIN PEAY HWY",
    stop_desc: "",
    stop_lat: 35.221692,
    stop_lon: -89.910403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1197,
    stop_code: "JAMHOMWM",
    stop_name: "JAMES@HOMEWOOD",
    stop_desc: "JAMES RD & WATER RD",
    stop_lat: 35.205305,
    stop_lon: -89.92566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1198,
    stop_code: "JAMRG1WM",
    stop_name: "JAMES@RIVER GROVE",
    stop_desc: "JAMES RD & RIVER GROVE CIR",
    stop_lat: 35.204712,
    stop_lon: -89.929638,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1199,
    stop_code: "JAMSCEWN",
    stop_name: "JAMES@SCENIC HWY",
    stop_desc: "? JAMES RD & SCENIC HWY",
    stop_lat: 35.204064,
    stop_lon: -89.933992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1200,
    stop_code: "JAMADVWM",
    stop_name: "JAMES@ADVANTAGE WAY",
    stop_desc: "JAMES RD & S ADVANTAGE WAY DR",
    stop_lat: 35.202673,
    stop_lon: -89.936321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1201,
    stop_code: "JAMEPPWN",
    stop_name: "JAMES@EPPINGWAY",
    stop_desc: "JAMES RD & EPPING WAY DR",
    stop_lat: 35.200344,
    stop_lon: -89.939194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1202,
    stop_code: "JAMDURWM",
    stop_name: "JAMES@DURY WAY",
    stop_desc: "JAMES RD & DRURY WAY LN",
    stop_lat: 35.199666,
    stop_lon: -89.940347,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1203,
    stop_code: "JAMHIGWN",
    stop_name: "JAMES@HIGHLAND",
    stop_desc: "JAMES RD & HIGHLAND RD",
    stop_lat: 35.199205,
    stop_lon: -89.944169,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1204,
    stop_code: "JAMNRAWF",
    stop_name: "JAMES@NEW RALEIGH",
    stop_desc: "JAMES RD & NEW RALEIGH RD",
    stop_lat: 35.199482,
    stop_lon: -89.951977,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1205,
    stop_code: "JAMALLWM",
    stop_name: "JAMES@ALLEN",
    stop_desc: "JAMES RD & ROPER RD",
    stop_lat: 35.199735,
    stop_lon: -89.955455,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1206,
    stop_code: "JAMWARWM",
    stop_name: "JAMES@WARFORD",
    stop_desc: "JAMES RD & OLD ALLEN RD",
    stop_lat: 35.19988,
    stop_lon: -89.957359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1207,
    stop_code: "JAMSCOWF",
    stop_name: "JAMES@SCOTLAND",
    stop_desc: "JAMES RD & SCOTLAND RD",
    stop_lat: 35.20007,
    stop_lon: -89.960117,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1227,
    stop_code: "JAMSCOEM",
    stop_name: "JAMES@SCOTLAND",
    stop_desc: "JAMES RD & BIRCHFIELD DR",
    stop_lat: 35.199991,
    stop_lon: -89.961546,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1228,
    stop_code: "JAMWAREF",
    stop_name: "JAMES@WARFORD",
    stop_desc: "JAMES RD & NEW ALLEN RD",
    stop_lat: 35.199755,
    stop_lon: -89.957869,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1230,
    stop_code: "JAMNRAEF",
    stop_name: "JAMES@NEW RALEIGH",
    stop_desc: "JAMES RD & NEW RALEIGH RD",
    stop_lat: 35.199266,
    stop_lon: -89.951147,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1231,
    stop_code: "JAMWALEF",
    stop_name: "JAMES@WALNUT",
    stop_desc: "",
    stop_lat: 35.199156,
    stop_lon: -89.948466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1232,
    stop_code: "JAMHIGEF",
    stop_name: "JAMES@HIGHLAND",
    stop_desc: "JAMES RD & HIGHLAND RD",
    stop_lat: 35.199044,
    stop_lon: -89.943804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1233,
    stop_code: "JAMEPPEM",
    stop_name: "JAMES@DRURY WAY",
    stop_desc: "JAMES RD & DRURY WAY LN",
    stop_lat: 35.19955,
    stop_lon: -89.940181,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1234,
    stop_code: "JAMHICEN",
    stop_name: "JAMES@HICKORY BLUFF",
    stop_desc: "JAMES RD & W HICKORY BLF",
    stop_lat: 35.200674,
    stop_lon: -89.938473,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1235,
    stop_code: "JAMNOREM",
    stop_name: "JAMES@NORTHCLIFFE",
    stop_desc: "JAMES RD & S ADVANTAGE WAY DR",
    stop_lat: 35.202735,
    stop_lon: -89.935933,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1236,
    stop_code: "JAMJAMEF",
    stop_name: "JAMES@JAMES",
    stop_desc: "JAMES RD & SCENIC HWY",
    stop_lat: 35.204037,
    stop_lon: -89.933605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1237,
    stop_code: "JAMRIVEF",
    stop_name: "JAMES@RIVER GROVE CR",
    stop_desc: "JAMES RD & RIVER GROVE CIR",
    stop_lat: 35.204505,
    stop_lon: -89.929085,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1238,
    stop_code: "JAMWATEN",
    stop_name: "JAMES@WATER RD",
    stop_desc: "JAMES RD & RIVER GROVE CIR",
    stop_lat: 35.205127,
    stop_lon: -89.925705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1239,
    stop_code: "JAMAUSEN",
    stop_name: "JAMES RD@AUSTIN PEAY HWY",
    stop_desc: "STATE HWY 15 & JAMES RD",
    stop_lat: 35.205193,
    stop_lon: -89.921728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1240,
    stop_code: "YALRAMWF",
    stop_name: "YALE@RAMILL",
    stop_desc: "YALE RD & LEHI DR",
    stop_lat: 35.220642,
    stop_lon: -89.927659,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1241,
    stop_code: "YALRALWF",
    stop_name: "YALE RD@RALEIGH MILLINGTON RD",
    stop_desc: "HAWKINS MILL RD & RALEIGH MILLINGTON RD",
    stop_lat: 35.220948,
    stop_lon: -89.929409,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1242,
    stop_code: "FRAHAWWF",
    stop_name: "FRAYSER@HAWKINS MILL",
    stop_desc: "FRAYSER RALEIGH RD & HAWKINS MILL RD",
    stop_lat: 35.223091,
    stop_lon: -89.935853,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1243,
    stop_code: "FRAEDGWN",
    stop_name: "FRAYSER@EDGEFIELD",
    stop_desc: "FRAYSER BLVD & EDGEFIELD DR",
    stop_lat: 35.223176,
    stop_lon: -89.939997,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1244,
    stop_code: "FRAWINWN",
    stop_name: "FRAYSER@WINDERMERE",
    stop_desc: "",
    stop_lat: 35.223175,
    stop_lon: -89.940917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1245,
    stop_code: "FRAWESWN",
    stop_name: "FRAYSER@WESTLINE DR",
    stop_desc: "FRAYSER & CALVARY CREEK",
    stop_lat: 35.223582,
    stop_lon: -89.946792,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1246,
    stop_code: "FRAALLWF",
    stop_name: "FRAYSER@ALLEN",
    stop_desc: "",
    stop_lat: 35.223567,
    stop_lon: -89.95022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1247,
    stop_code: "FRANEWWN",
    stop_name: "FRAYSER@NEW ALLEN",
    stop_desc: "FRAYSER BLVD & NEW ALLEN RD",
    stop_lat: 35.222907,
    stop_lon: -89.955808,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1248,
    stop_code: "FRAREDWN",
    stop_name: "FRAYSER RD @ REDCOAT",
    stop_desc: "NEW FRAYSER RALEIGH RD & REDCOAT RD",
    stop_lat: 35.222214,
    stop_lon: -89.960096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1249,
    stop_code: "FRAWARWN",
    stop_name: "FRAYSER@WARFORD",
    stop_desc: "NEW FRAYSER RALEIGH RD & FRAYSER BLVD",
    stop_lat: 35.221484,
    stop_lon: -89.962757,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1250,
    stop_code: "FRASCHWF",
    stop_name: "FRAYSER@SCHOOLFIELD",
    stop_desc: "FRAYSER BLVD & LADUE ST",
    stop_lat: 35.221401,
    stop_lon: -89.970102,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1251,
    stop_code: "FRALADWN",
    stop_name: "FRAYSER@LADUE",
    stop_desc: "FRAYSER BLVD & LADUE ST",
    stop_lat: 35.221367,
    stop_lon: -89.972362,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1252,
    stop_code: "FRARANWM",
    stop_name: "FRAYSER@RANGE LINE RD",
    stop_desc: "FRAYSER BLVD & NEW ALLEN RD",
    stop_lat: 35.22079,
    stop_lon: -89.976784,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1253,
    stop_code: "FRATREWF",
    stop_name: "FRAYSER@TREZEVANT",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.2198,
    stop_lon: -89.979267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1254,
    stop_code: "FRAMOUWM",
    stop_name: "FRAYSER@MOUNTAINTERRACE",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.218212,
    stop_lon: -89.983135,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1255,
    stop_code: "FRAMOUWF",
    stop_name: "FRAYSER@MT TERRACE",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.217141,
    stop_lon: -89.985164,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1256,
    stop_code: "FRAARDWF",
    stop_name: "FRAYSER@ARDMORE",
    stop_desc: "FRAYSER BLVD & ARDMORE ST",
    stop_lat: 35.217169,
    stop_lon: -89.987014,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1257,
    stop_code: "FRAUNIWF",
    stop_name: "FRAYSER@UNIVERSITY",
    stop_desc: "FRAYSER BLVD & UNIVERSITY ST",
    stop_lat: 35.217234,
    stop_lon: -89.990415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1258,
    stop_code: "FRAWOOWN",
    stop_name: "FRAYSER BLVD @ WOODROW ST",
    stop_desc: "",
    stop_lat: 35.217244,
    stop_lon: -89.992187,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1259,
    stop_code: "FRAOVEWF",
    stop_name: "FRAYSER@OVERTON CROSSING",
    stop_desc: "FRAYSER BLVD & RAMP",
    stop_lat: 35.217353,
    stop_lon: -89.994314,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1260,
    stop_code: "FRADENWF",
    stop_name: "FRAYSER@DENVER",
    stop_desc: "FRAYSER BLVD & DENVER ST",
    stop_lat: 35.217417,
    stop_lon: -89.996331,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1261,
    stop_code: "FRAADEWN",
    stop_name: "FRAYSER@ADEN",
    stop_desc: "FRAYSER BLVD & ADEN ST",
    stop_lat: 35.217471,
    stop_lon: -89.998026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1262,
    stop_code: "FRAWATWN",
    stop_name: "FRAYSER@WATKINS",
    stop_desc: "FRAYSER BLVD & N WATKINS ST",
    stop_lat: 35.217608,
    stop_lon: -90.002878,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1263,
    stop_code: "FRAWATWF",
    stop_name: "FRAYSER BLVD@WATKINS ST",
    stop_desc: "? FRAYSER BLVD & ADEN ST",
    stop_lat: 35.217653,
    stop_lon: -90.004041,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1265,
    stop_code: "FRASTEWM",
    stop_name: "FRAYSER@STEELE",
    stop_desc: "FRAYSER BLVD & STUDENTS ST",
    stop_lat: 35.217962,
    stop_lon: -90.013857,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1266,
    stop_code: "STEFRASF",
    stop_name: "STEELE@FRAYSER",
    stop_desc: "STEELE ST & FRAYSER BLVD",
    stop_lat: 35.217382,
    stop_lon: -90.015109,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1267,
    stop_code: "STEPAUSN",
    stop_name: "STEELE@PAULLUS",
    stop_desc: "STEELE ST & PAULLUS AVE",
    stop_lat: 35.216194,
    stop_lon: -90.015132,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1268,
    stop_code: "STEGRESN",
    stop_name: "STEELE@GREENDALE",
    stop_desc: "STEELE ST & GREENDALE AVE",
    stop_lat: 35.21488,
    stop_lon: -90.015144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1269,
    stop_code: "STEDELSN",
    stop_name: "STEELE@DELLWOOD",
    stop_desc: "STEELE ST & GREENDALE AVE",
    stop_lat: 35.213289,
    stop_lon: -90.015201,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1270,
    stop_code: "DELRAYWN",
    stop_name: "DELLWOOD@RAINIER",
    stop_desc: "DELLWOOD AVE & RAINIER DR",
    stop_lat: 35.211807,
    stop_lon: -90.018292,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1271,
    stop_code: "DELRIDWM",
    stop_name: "DELLWOOD@RIDGECREST",
    stop_desc: "DELLWOOD AVE & RAINIER DR",
    stop_lat: 35.210691,
    stop_lon: -90.020254,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1272,
    stop_code: "DELBASWN",
    stop_name: "DELLWOOD@BASKIN",
    stop_desc: "DELLWOOD AVE & RAINIER DR",
    stop_lat: 35.210272,
    stop_lon: -90.022248,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1273,
    stop_code: "DELMADWF",
    stop_name: "DELLWOOD@MADEWELL",
    stop_desc: "DELLWOOD AVE & MADEWELL DR",
    stop_lat: 35.21047,
    stop_lon: -90.025981,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1275,
    stop_code: "WHIHILWN",
    stop_name: "WHITINEY AVE@THOMAS ST",
    stop_desc: "WHITNEY AVE & HILLIS ST",
    stop_lat: 35.209123,
    stop_lon: -90.033858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1276,
    stop_code: "WHISUNWN",
    stop_name: "WHITINEY@SUNRISE",
    stop_desc: "WHITNEY AVE & SUNRISE ST",
    stop_lat: 35.206989,
    stop_lon: -90.039808,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1277,
    stop_code: "WHIBENWM",
    stop_name: "WHITINEY@BENJESTOWN",
    stop_desc: "WHITNEY AVE & BENJESTOWN RD",
    stop_lat: 35.206704,
    stop_lon: -90.040627,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1285,
    stop_code: "THOCINNM",
    stop_name: "THOMAS@CINDY",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.213207,
    stop_lon: -90.031729,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1304,
    stop_code: "WATSTENM",
    stop_name: "WATKINS@STELMO",
    stop_desc: "N WATKINS ST & N WATKINS RD",
    stop_lat: 35.227786,
    stop_lon: -90.002947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1305,
    stop_code: "WATSTENN",
    stop_name: "WATKINS ST@SAINT ELMO AVE",
    stop_desc: "N WATKINS ST & SAINT ELMO AVE",
    stop_lat: 35.229931,
    stop_lon: -90.00361,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1336,
    stop_code: "WATBURSN",
    stop_name: "WATKINS@BURNHAM",
    stop_desc: "N WATKINS RD & BURNHAM AVE",
    stop_lat: 35.226544,
    stop_lon: -90.003192,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1337,
    stop_code: "WATCORSN",
    stop_name: "WATKINS@CORNING",
    stop_desc: "N WATKINS RD & BURNHAM AVE",
    stop_lat: 35.225392,
    stop_lon: -90.003237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1351,
    stop_code: "THOFRASF",
    stop_name: "THOMAS@FRAYSER CR",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.214994,
    stop_lon: -90.031418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1352,
    stop_code: "THOMARSM",
    stop_name: "THOMAS@MARSH",
    stop_desc: "STATE HWY 3 & THOMAS ST",
    stop_lat: 35.213117,
    stop_lon: -90.032084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1359,
    stop_code: "WHISINEN",
    stop_name: "WHITNEY@SINCLAIR",
    stop_desc: "WHITNEY AVE & SINCLAIR LN",
    stop_lat: 35.205802,
    stop_lon: -90.042422,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1360,
    stop_code: "WHIBENEN",
    stop_name: "WHITNEY@BENJESTOWN",
    stop_desc: "WHITNEY AVE & BENJESTOWN RD",
    stop_lat: 35.206284,
    stop_lon: -90.041237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1361,
    stop_code: "WHIMOREN",
    stop_name: "WHITNEY@MORNINGSIDE",
    stop_desc: "WHITNEY AVE & MORNINGSIDE ST",
    stop_lat: 35.207096,
    stop_lon: -90.038966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1362,
    stop_code: "WHIKINEN",
    stop_name: "WHITNEY@KINGSTON",
    stop_desc: "WHITNEY AVE & KINGSTON ST",
    stop_lat: 35.207864,
    stop_lon: -90.036816,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1363,
    stop_code: "WHITHOEN",
    stop_name: "WHITNEY AVE@THOMAS ST",
    stop_desc: "",
    stop_lat: 35.209177,
    stop_lon: -90.033138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1366,
    stop_code: "THOGILNF",
    stop_name: "THOMAS@GILLILAND",
    stop_desc: "US HWY 51 & GILLILAND AVE",
    stop_lat: 35.210848,
    stop_lon: -90.032262,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1367,
    stop_code: "DELMADEN",
    stop_name: "DELLWOOD@MADEWELL",
    stop_desc: "DELLWOOD AVE & MADEWELL DR",
    stop_lat: 35.210309,
    stop_lon: -90.026004,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1368,
    stop_code: "DELBASEN",
    stop_name: "DELLWOOD@BASKIN",
    stop_desc: "DELLWOOD AVE & BASKIN ST",
    stop_lat: 35.210067,
    stop_lon: -90.022758,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1369,
    stop_code: "DELBRIEN",
    stop_name: "DELLWOOD@RIDGECREST",
    stop_desc: "DELLWOOD AVE & RIDGECREST ST",
    stop_lat: 35.210352,
    stop_lon: -90.02114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1370,
    stop_code: "DELRAIEN",
    stop_name: "DELLWOOD@RAINIER",
    stop_desc: "DELLWOOD AVE & RAINIER DR",
    stop_lat: 35.211388,
    stop_lon: -90.018559,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1371,
    stop_code: "DELCANEF",
    stop_name: "DELLWOOD@CANFIELD",
    stop_desc: "DELLWOOD AVE & CANFIELD AVE",
    stop_lat: 35.212923,
    stop_lon: -90.015954,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1372,
    stop_code: "STEDELNF",
    stop_name: "STEELE@DELLWOOD",
    stop_desc: "STEELE ST & CANFIELD AVE",
    stop_lat: 35.213611,
    stop_lon: -90.014979,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1373,
    stop_code: "STEGRENN",
    stop_name: "STEELE@GREENDALE",
    stop_desc: "STEELE ST & GREENDALE AVE",
    stop_lat: 35.214701,
    stop_lon: -90.014956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1374,
    stop_code: "STEROLNM",
    stop_name: "STEELE@ROLLING HILLS",
    stop_desc: "STEELE ST & ROLLING HILLS DR",
    stop_lat: 35.216542,
    stop_lon: -90.014944,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1375,
    stop_code: "STEFRANN",
    stop_name: "STEELE@FRAYSER BLVD",
    stop_desc: "STEELE ST & FRAYSER BLVD",
    stop_lat: 35.217436,
    stop_lon: -90.014899,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1376,
    stop_code: "FRAFRAEM",
    stop_name: "FRAYSER@FRAYSER SCHOOL",
    stop_desc: "FRAYSER BLVD & GILLIE ST",
    stop_lat: 35.217746,
    stop_lon: -90.010688,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1377,
    stop_code: "FRAFRAEN",
    stop_name: "FRAYSER@FRAYSER SCHOOL",
    stop_desc: "FRAYSER BLVD & FRAYSER SCHOOL DR",
    stop_lat: 35.217755,
    stop_lon: -90.009182,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1378,
    stop_code: "FRAWATEN",
    stop_name: "FRAYSER@WATKINS",
    stop_desc: "FRAYSER BLVD & RAMP",
    stop_lat: 35.21751,
    stop_lon: -90.003798,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1379,
    stop_code: "FRAWATEF",
    stop_name: "FRAYSER@WATKINS",
    stop_desc: "FRAYSER BLVD & ASHLAND ST",
    stop_lat: 35.217438,
    stop_lon: -90.002911,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1380,
    stop_code: "FRADENEN",
    stop_name: "FRAYSER@DENVER",
    stop_desc: "FRAYSER BLVD & DENVER ST",
    stop_lat: 35.217256,
    stop_lon: -89.996397,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1381,
    stop_code: "FRAOVEEM",
    stop_name: "FRAYSER@OVERTONCROSSING",
    stop_desc: "FRAYSER BLVD & RAMP",
    stop_lat: 35.217201,
    stop_lon: -89.994536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1382,
    stop_code: "FRAOVEEF",
    stop_name: "FRAYSER@OVERTON CROSSING",
    stop_desc: "FRAYSER BLVD & RAMP",
    stop_lat: 35.217146,
    stop_lon: -89.992952,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1383,
    stop_code: "FRAARDEN",
    stop_name: "FRAYSER@ARDMORE",
    stop_desc: "FRAYSER BLVD & ARDMORE ST",
    stop_lat: 35.216999,
    stop_lon: -89.986482,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1384,
    stop_code: "FRAMOUEN",
    stop_name: "FRAYSER@MOUNTAIN TERRACE",
    stop_desc: "0 FRAYSER BLVD",
    stop_lat: 35.217051,
    stop_lon: -89.984521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1385,
    stop_code: "FRABROEM",
    stop_name: "FRAYSER@BROOKMEADE",
    stop_desc: "FRAYSER BLVD & BROOKMEADE ST",
    stop_lat: 35.21905,
    stop_lon: -89.980741,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1386,
    stop_code: "FRATREEN",
    stop_name: "FRAYSER@TREZERANT",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.219772,
    stop_lon: -89.97889,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1387,
    stop_code: "FRARANEM",
    stop_name: "FRAYSER BLVD@RANGE LINE RD",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.220754,
    stop_lon: -89.976429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1388,
    stop_code: "FRALADEN",
    stop_name: "FRAYSER@LADUE",
    stop_desc: "FRAYSER BLVD & LADUE ST",
    stop_lat: 35.221215,
    stop_lon: -89.972717,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1389,
    stop_code: "FRARANEN",
    stop_name: "FRAYSER@RANGE HILLS",
    stop_desc: "FRAYSER BLVD & N TREZEVANT ST",
    stop_lat: 35.221123,
    stop_lon: -89.969205,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1390,
    stop_code: "FRAREDEN",
    stop_name: "FRAYSER@REDCOAT",
    stop_desc: "NEW FRAYSER RALEIGH RD & WARFORD ST",
    stop_lat: 35.221616,
    stop_lon: -89.961216,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1391,
    stop_code: "FRANALEM",
    stop_name: "FRAYSER@NEW ALLAN",
    stop_desc: "NEW FRAYSER RALEIGH RD & WARFORD ST",
    stop_lat: 35.222035,
    stop_lon: -89.959676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1392,
    stop_code: "FRANALEF",
    stop_name: "FRAYSER@NEW ALLEN",
    stop_desc: "FRAYSER BLVD & RAMP",
    stop_lat: 35.222808,
    stop_lon: -89.955121,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1393,
    stop_code: "FRAOLDEF",
    stop_name: "FRAYSER@OLD ALLEN RD",
    stop_desc: "FRAYSER RALEIGH RD & FRAYSER BLVD",
    stop_lat: 35.223455,
    stop_lon: -89.949525,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1394,
    stop_code: "FRAWESEF",
    stop_name: "FRAYSER@WESTLINE",
    stop_desc: "FRAYSER RALEIGH RD & FRAYSER BLVD",
    stop_lat: 35.223434,
    stop_lon: -89.946877,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1395,
    stop_code: "FRAWINEN",
    stop_name: "FRAYSER@WINDERMERE",
    stop_desc: "FRAYSER RALEIGH RD & WINDERMERE DR",
    stop_lat: 35.223044,
    stop_lon: -89.941493,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1396,
    stop_code: "FRAEDGEF",
    stop_name: "FRAYSER@EDGEFIELD",
    stop_desc: "FRAYSER RALEIGH RD & EDGEFIELD DR",
    stop_lat: 35.223016,
    stop_lon: -89.940163,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1397,
    stop_code: "FRAHAWEM",
    stop_name: "FRAYSER@HAWKINS MILL",
    stop_desc: "FRAYSER RALEIGH RD & FRAYSER BLVD",
    stop_lat: 35.22295,
    stop_lon: -89.936851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1398,
    stop_code: "FRAHAWEN",
    stop_name: "FRAYSER@HAWKINS MILL",
    stop_desc: "FRAYSER BLVD & HAWKINS MILL RD",
    stop_lat: 35.222921,
    stop_lon: -89.935577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1399,
    stop_code: "FRARALEN",
    stop_name: "FRAYSER@RALEIGH MILLINGTON",
    stop_desc: "RALEIGH FRAYSER RD & RALEIGH MILLINGTON RD",
    stop_lat: 35.220697,
    stop_lon: -89.928911,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1400,
    stop_code: "YALRAMEN",
    stop_name: "YALE@RAMILL",
    stop_desc: "YALE RD & RAMILL RD",
    stop_lat: 35.220454,
    stop_lon: -89.927404,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1450,
    stop_code: "WATDUPNF",
    stop_name: "WATKINS@DUPONT",
    stop_desc: "N WATKINS ST & DUPONT AVE",
    stop_lat: 35.197031,
    stop_lon: -90.007736,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1451,
    stop_code: "WATDELNN",
    stop_name: "WATKINS@DELANO",
    stop_desc: "",
    stop_lat: 35.19919,
    stop_lon: -90.00769,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1452,
    stop_code: "WATOBENN",
    stop_name: "WATKINS@OBERLE",
    stop_desc: "N WATKINS ST & OBERLE AVE",
    stop_lat: 35.201276,
    stop_lon: -90.007633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1453,
    stop_code: "WATCEDNM",
    stop_name: "WATKINS@CEDARHURST",
    stop_desc: "N WATKINS ST & OBERLE AVE",
    stop_lat: 35.202517,
    stop_lon: -90.006834,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1454,
    stop_code: "WATCE1NM",
    stop_name: "WATKINS@CEDARHURST",
    stop_desc: "N WATKINS ST & OBERLE AVE",
    stop_lat: 35.203651,
    stop_lon: -90.004718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1455,
    stop_code: "WATRUGNM",
    stop_name: "WATKINS@RUGBY",
    stop_desc: "N WATKINS ST & RUGBY PL",
    stop_lat: 35.206287,
    stop_lon: -90.003829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1456,
    stop_code: "WATWHINN",
    stop_name: "WATKINS@WHITNEY",
    stop_desc: "N WATKINS ST & RUGBY PL",
    stop_lat: 35.2076,
    stop_lon: -90.003762,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1463,
    stop_code: "WATSUTNN",
    stop_name: "WATKINS@SUTTON",
    stop_desc: "N WATKINS ST & SUTTON DR",
    stop_lat: 35.22171,
    stop_lon: -90.003163,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1464,
    stop_code: "WATCLANN",
    stop_name: "WATKINS@CLAIRE",
    stop_desc: "N WATKINS ST & S SUTTON DR",
    stop_lat: 35.224141,
    stop_lon: -90.003083,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1465,
    stop_code: "STELEWEF",
    stop_name: "ST ELMO@LEWEIR",
    stop_desc: "SAINT ELMO AVE & LEWEIR ST",
    stop_lat: 35.231233,
    stop_lon: -89.997902,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1466,
    stop_code: "STEDENEN",
    stop_name: "ST ELMO@DENVER",
    stop_desc: "SAINT ELMO AVE & LEWEIR ST",
    stop_lat: 35.231204,
    stop_lon: -89.995575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1467,
    stop_code: "OVELEYNM",
    stop_name: "OVERTON CROSSING@LEYTON",
    stop_desc: "OVERTON CROSSING ST & DURHAM AVE",
    stop_lat: 35.233214,
    stop_lon: -89.993634,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1468,
    stop_code: "OVEHILNN",
    stop_name: "OVERTON CROSSING@HILLSIDE",
    stop_desc: "OVERTON CROSSING ST & LEWEIR ST",
    stop_lat: 35.234152,
    stop_lon: -89.993423,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1469,
    stop_code: "OVEWELNF",
    stop_name: "OVERTONCROSSING@WELLONS",
    stop_desc: "OVERTON CROSSING ST & WELLONS AVE",
    stop_lat: 35.236207,
    stop_lon: -89.993354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1470,
    stop_code: "OVESHANN",
    stop_name: "OVERTON CROSSING@SHARON",
    stop_desc: "0 OVERTON CROSSING ST",
    stop_lat: 35.237878,
    stop_lon: -89.993297,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1471,
    stop_code: "OVEHAWNN",
    stop_name: "OVERTON CROSSING@HAWKINS MILL",
    stop_desc: "0 OVERTON CROSSING ST & DURHAM AVE",
    stop_lat: 35.241363,
    stop_lon: -89.993172,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1472,
    stop_code: "HAWOVEEF",
    stop_name: "HAWKINS MILLS@OVERTONCROSSING",
    stop_desc: "HAWKINS MILL RD & OVERTON CROSSING ST",
    stop_lat: 35.242113,
    stop_lon: -89.99275,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1473,
    stop_code: "HAWCHAEF",
    stop_name: "HAWKINS MILLS@CHATTERING",
    stop_desc: "HAWKINS MILL RD & CHATTERING LN",
    stop_lat: 35.24263,
    stop_lon: -89.991054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1474,
    stop_code: "HAWUNIEN",
    stop_name: "HAWKINS MILLS@UNIVERSITY",
    stop_desc: "HAWKINS MILL RD & CHATTERING LN",
    stop_lat: 35.243746,
    stop_lon: -89.988703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1484,
    stop_code: "HAWLISWN",
    stop_name: "HAWKINS MILLS@LISA",
    stop_desc: "",
    stop_lat: 35.242465,
    stop_lon: -89.977406,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1485,
    stop_code: "HAWSUNWM",
    stop_name: "HAWKINS MILLS@SUNCREST",
    stop_desc: "",
    stop_lat: 35.244163,
    stop_lon: -89.978917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1486,
    stop_code: "HAWALIWN",
    stop_name: "HAWKINS MILLS RD@ALINE RD",
    stop_desc: "",
    stop_lat: 35.245019,
    stop_lon: -89.984407,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1487,
    stop_code: "HAWCHAWM",
    stop_name: "HAWKINS MILLS@CHATTERING LN",
    stop_desc: "",
    stop_lat: 35.242729,
    stop_lon: -89.991064,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1488,
    stop_code: "OVEHAWSF",
    stop_name: "OVERTON CROSSING@HAWKINS MILL",
    stop_desc: "OVERTONCROSSING & DURHAM DR",
    stop_lat: 35.240883,
    stop_lon: -89.993369,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1490,
    stop_code: "OVESHASF",
    stop_name: "OVERTON CROSSING@SHARON LN",
    stop_desc: "OVERTON CROSSING ST & SHARON LN",
    stop_lat: 35.237637,
    stop_lon: -89.993563,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1491,
    stop_code: "OVEWELSN",
    stop_name: "OVERTONCROSSING@WELLONS",
    stop_desc: "OVERTON CROSSING ST & WELLONS AVE",
    stop_lat: 35.236305,
    stop_lon: -89.993553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1492,
    stop_code: "OVEHILSF",
    stop_name: "OVERTON CROSSING@HILLSIDE",
    stop_desc: "OVERTON CROSSING ST & HILLSIDE AVE",
    stop_lat: 35.234286,
    stop_lon: -89.9936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1493,
    stop_code: "OVESTESN",
    stop_name: "OVERTON CROSSING@ST ELMO",
    stop_desc: "OVERTON CROSSING ST & SHARON LN",
    stop_lat: 35.231453,
    stop_lon: -89.99368,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1495,
    stop_code: "STELEWWN",
    stop_name: "ST ELMO@LEWEIR",
    stop_desc: "ST ELMO AVE & LEWEIR ST",
    stop_lat: 35.231393,
    stop_lon: -89.99788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1497,
    stop_code: "STEWATWN",
    stop_name: "SAINT ELMO AVE@WATKINS ST",
    stop_desc: "",
    stop_lat: 35.23036,
    stop_lon: -90.003255,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1498,
    stop_code: "WATSUTSM",
    stop_name: "WATKINS@SUTTON",
    stop_desc: "N WATKINS RD & S SUTTON DR",
    stop_lat: 35.223292,
    stop_lon: -90.003305,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1499,
    stop_code: "WATFRASM",
    stop_name: "WATKINS@FRAYSER",
    stop_desc: "N WATKINS RD & FRAYSER BLVD",
    stop_lat: 35.218636,
    stop_lon: -90.003487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1500,
    stop_code: "WATFRASF",
    stop_name: "WATKINS@FRAYSER",
    stop_desc: "N WATKINS ST & N WATKINS RD",
    stop_lat: 35.216742,
    stop_lon: -90.003555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1501,
    stop_code: "WATDELSN",
    stop_name: "WATKINS@DELLWOOD",
    stop_desc: "N WATKINS ST & N WATKINS RD",
    stop_lat: 35.213382,
    stop_lon: -90.00369,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1502,
    stop_code: "WATDRISN",
    stop_name: "WATKINS@DRIFTWOOD",
    stop_desc: "N WATKINS RD & DRIFTWOOD AVE",
    stop_lat: 35.210388,
    stop_lon: -90.003826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1503,
    stop_code: "WATWHISN",
    stop_name: "WATKINS@WHITNEY",
    stop_desc: "N WATKINS ST & N WATKINS RD",
    stop_lat: 35.208485,
    stop_lon: -90.003905,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1504,
    stop_code: "WATWHISF",
    stop_name: "WATKINS@WHITNEY",
    stop_desc: "N WATKINS ST & N WATKINS RD",
    stop_lat: 35.2076,
    stop_lon: -90.003961,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1505,
    stop_code: "WATPINSM",
    stop_name: "WATKINS@PINEDALE",
    stop_desc: "N WATKINS ST & PINEDALE AVE",
    stop_lat: 35.206323,
    stop_lon: -90.004029,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1506,
    stop_code: "WATSIGSM",
    stop_name: "WATKINS@SIGNAL",
    stop_desc: "N WATKINS ST & PINEDALE AVE",
    stop_lat: 35.203973,
    stop_lon: -90.00464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1507,
    stop_code: "WATOBESN",
    stop_name: "WATKINS@OBERLE",
    stop_desc: "N WATKINS ST & OBERLE AVE",
    stop_lat: 35.201624,
    stop_lon: -90.007777,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1509,
    stop_code: "WATDUPSM",
    stop_name: "WATKINS@DUPONT",
    stop_desc: "N WATKINS ST & DUPONT AVE",
    stop_lat: 35.197201,
    stop_lon: -90.007935,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1512,
    stop_code: "WATEVANF",
    stop_name: "WATKINS@EVADNE",
    stop_desc: "N WATKINS ST & EVADNE AVE",
    stop_lat: 35.1773,
    stop_lon: -90.008228,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1513,
    stop_code: "WATOVENF",
    stop_name: "WATKINS@OVERTON CROSSING",
    stop_desc: "N WATKINS ST & EVADNE AVE",
    stop_lat: 35.194323,
    stop_lon: -90.007827,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1516,
    stop_code: "CHESTOEN",
    stop_name: "CHELSEA@STONEWALL",
    stop_desc: "",
    stop_lat: 35.172877,
    stop_lon: -90.006785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1517,
    stop_code: "CHEWILEF",
    stop_name: "CHELSEA@WILLETT",
    stop_desc: "CHELSEA & EVERGREEN",
    stop_lat: 35.172769,
    stop_lon: -90.004267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1519,
    stop_code: "CHEEVEEN",
    stop_name: "CHELSEA@EVERGREEN",
    stop_desc: "",
    stop_lat: 35.172623,
    stop_lon: -90.000341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1520,
    stop_code: "CHEMCLEF",
    stop_name: "CHELSEA@MCLEAN",
    stop_desc: "",
    stop_lat: 35.172694,
    stop_lon: -89.995072,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1521,
    stop_code: "CHEUNIEN",
    stop_name: "CHELSEA@UNIVERSITY",
    stop_desc: "",
    stop_lat: 35.17333,
    stop_lon: -89.990858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1522,
    stop_code: "CHEBRIEM",
    stop_name: "CHELSEA@BRITTON",
    stop_desc: "",
    stop_lat: 35.173708,
    stop_lon: -89.988381,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1523,
    stop_code: "CHETUNEN",
    stop_name: "CHELSEA@TUNICA",
    stop_desc: "",
    stop_lat: 35.173934,
    stop_lon: -89.98689,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1524,
    stop_code: "CHEAUSEN",
    stop_name: "CHELSEA@AUSTIN",
    stop_desc: "",
    stop_lat: 35.174073,
    stop_lon: -89.985799,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1525,
    stop_code: "CHESPREN",
    stop_name: "CHELSEA@SPRINGDALE",
    stop_desc: "",
    stop_lat: 35.174385,
    stop_lon: -89.983753,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1526,
    stop_code: "CHEPEREN",
    stop_name: "CHELSEA@PERES",
    stop_desc: "CHELSEA AVE & PERES AVE",
    stop_lat: 35.174736,
    stop_lon: -89.981125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1527,
    stop_code: "CHETREEN",
    stop_name: "CHELSEA@TREZEVANT",
    stop_desc: "CHELSEA AVE & N TREZEVANT ST",
    stop_lat: 35.174708,
    stop_lon: -89.979907,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1528,
    stop_code: "CHEHAREN",
    stop_name: "CHELSEA@HARRISION",
    stop_desc: "CHELSEA AVE & HARRISON ST",
    stop_lat: 35.174644,
    stop_lon: -89.978036,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1529,
    stop_code: "CHEHOLEN",
    stop_name: "CHELSEA AVE@HOLLYWOOD ST",
    stop_desc: "CHELSEA AVE & OAKWOOD ST",
    stop_lat: 35.174553,
    stop_lon: -89.9756,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1530,
    stop_code: "HOLCHENF",
    stop_name: "HOLLYWOOD@CHELSEA",
    stop_desc: "N HOLLYWOOD ST & HUBBARD AVE",
    stop_lat: 35.175115,
    stop_lon: -89.975367,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1531,
    stop_code: "HOLPERNF",
    stop_name: "HOLLYWOOD@PERES",
    stop_desc: "N HOLLYWOOD ST & SANDERS AVE",
    stop_lat: 35.17751,
    stop_lon: -89.975242,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1532,
    stop_code: "HOLCALNN",
    stop_name: "HOLLYWOOD@CALVERT",
    stop_desc: "N HOLLYWOOD ST & CALVERT AVE",
    stop_lat: 35.179717,
    stop_lon: -89.975162,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1533,
    stop_code: "HOLHEANF",
    stop_name: "HOLLYWOOD@HEARD",
    stop_desc: "N HOLLYWOOD ST & HEARD AVE",
    stop_lat: 35.181299,
    stop_lon: -89.975115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1534,
    stop_code: "HOLMIDNF",
    stop_name: "HOLLYWOOD@MIDWAY",
    stop_desc: "N HOLLYWOOD ST & HEARD AVE",
    stop_lat: 35.183944,
    stop_lon: -89.975057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1535,
    stop_code: "HOLBLUNF",
    stop_name: "HOLLYWOOD@BLUE RD",
    stop_desc: "N HOLLYWOOD ST & HEARD AVE",
    stop_lat: 35.185687,
    stop_lon: -89.975641,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1537,
    stop_code: "HOLJAMNN",
    stop_name: "HOLLYWOOD ST@JAMES RD",
    stop_desc: "HOLLYWOOD ST & N HOLLYWOOD ST",
    stop_lat: 35.198225,
    stop_lon: -89.975737,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1539,
    stop_code: "RANBETNN",
    stop_name: "RANGE LINE@BETHLEHEM",
    stop_desc: "",
    stop_lat: 35.202125,
    stop_lon: -89.975702,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1540,
    stop_code: "RANTRENN",
    stop_name: "RANGE LINE@TREEMONT",
    stop_desc: "RANGE LINE & LAKECREST",
    stop_lat: 35.204807,
    stop_lon: -89.975639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1541,
    stop_code: "RANLAKNM",
    stop_name: "RANGE LINE@LAKECREST CIRCLE",
    stop_desc: "RANGE LINE & LAKECRESTCIRCLE",
    stop_lat: 35.206695,
    stop_lon: -89.975631,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1542,
    stop_code: "RANWHINF",
    stop_name: "RANGE LINE@WHITNEY",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.208484,
    stop_lon: -89.975558,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1543,
    stop_code: "RANWHINN",
    stop_name: "RANGE LINE RD@WHITNEY AVE",
    stop_desc: "",
    stop_lat: 35.210512,
    stop_lon: -89.975544,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1544,
    stop_code: "RANMONNF",
    stop_name: "RANGE LINE@MONETTE",
    stop_desc: "RANGE LINE RD & MONETTE AVE",
    stop_lat: 35.21187,
    stop_lon: -89.975532,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1546,
    stop_code: "RANSANNF",
    stop_name: "RANGELINE@SANDPIPER",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.216383,
    stop_lon: -89.975493,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1547,
    stop_code: "RANORMNF",
    stop_name: "RANGE LINE AVE@ORMAN",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.218778,
    stop_lon: -89.975479,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1548,
    stop_code: "RANFRANN",
    stop_name: "RANGE LINE@FRAYSER",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.220136,
    stop_lon: -89.975466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1552,
    stop_code: "RANSTENF",
    stop_name: "RANGE LINE@STELMO",
    stop_desc: "",
    stop_lat: 35.233963,
    stop_lon: -89.975342,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1553,
    stop_code: "RANWELNF",
    stop_name: "RANGE LINE@WELLONS",
    stop_desc: "",
    stop_lat: 35.237172,
    stop_lon: -89.975312,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1554,
    stop_code: "RANDURNF",
    stop_name: "RANGE LINE@DURHAM",
    stop_desc: "",
    stop_lat: 35.239864,
    stop_lon: -89.975224,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1555,
    stop_code: "RANDURSN",
    stop_name: "RANGE LINE@DURHAM",
    stop_desc: "RANGE LINE RD",
    stop_lat: 35.239884,
    stop_lon: -89.975265,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1556,
    stop_code: "RANWELSN",
    stop_name: "RANGE LINE@WELLONS",
    stop_desc: "RANGE LINE RD",
    stop_lat: 35.237097,
    stop_lon: -89.97539,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1573,
    stop_code: "RANFRASF",
    stop_name: "RANGE LINE@FRAYSER",
    stop_desc: "N HOLLYWOOD ST & RANGE LINE RD",
    stop_lat: 35.219931,
    stop_lon: -89.975666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1574,
    stop_code: "RANORMSN",
    stop_name: "RANGE LINE@ORMAN",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.218367,
    stop_lon: -89.975679,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1575,
    stop_code: "RANSANSM",
    stop_name: "RANGE LINE@SANDPIPER",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.216347,
    stop_lon: -89.975692,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1576,
    stop_code: "RANSANSN",
    stop_name: "RANGE LINE@SANDPIPER",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.213944,
    stop_lon: -89.975706,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1577,
    stop_code: "RANMONSM",
    stop_name: "RANGE LINE@MONETTE",
    stop_desc: "N HOLLYWOOD ST & MONETTE AVE",
    stop_lat: 35.212326,
    stop_lon: -89.975731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1578,
    stop_code: "RANHORSM",
    stop_name: "RANGE LINE@HORNBEAM",
    stop_desc: "N HOLLYWOOD ST & WHITNEY AVE",
    stop_lat: 35.210369,
    stop_lon: -89.975744,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1579,
    stop_code: "RANWHISN",
    stop_name: "RANGE LINE@WHITNEY",
    stop_desc: "N HOLLYWOOD ST & CLIFTON AVE",
    stop_lat: 35.208421,
    stop_lon: -89.975758,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1580,
    stop_code: "RANLAKSM",
    stop_name: "RANGE LINE@LAKECREST CIRCLE",
    stop_desc: "RANGE LINE & LAKECRESTCIRCLE",
    stop_lat: 35.206728,
    stop_lon: -89.975799,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1581,
    stop_code: "RANWOOSN",
    stop_name: "RANGE LINE@WOODCLIFF",
    stop_desc: "RANGE LINE & BETHLEHEM",
    stop_lat: 35.204081,
    stop_lon: -89.975876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1582,
    stop_code: "RANBETSN",
    stop_name: "RANGE LINE@BETHLEHEM",
    stop_desc: "",
    stop_lat: 35.202284,
    stop_lon: -89.975893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1583,
    stop_code: "HOLJAMSF",
    stop_name: "HOLLYWOOD ST@JAMES RD",
    stop_desc: "N HOLLYWOOD ST & RANGE LINE RD",
    stop_lat: 35.198216,
    stop_lon: -89.975936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1584,
    stop_code: "HOLMIDSF",
    stop_name: "HOLLYWOOD@MIDWAY",
    stop_desc: "N HOLLYWOOD ST & RANGE LINE RD",
    stop_lat: 35.183712,
    stop_lon: -89.975234,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1585,
    stop_code: "HOLHEASN",
    stop_name: "HOLLYWOOD@HEARD",
    stop_desc: "N HOLLYWOOD ST & HEARD AVE",
    stop_lat: 35.181192,
    stop_lon: -89.975326,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1586,
    stop_code: "HOLSANSM",
    stop_name: "HOLLYWOOD@SANDERS",
    stop_desc: "N HOLLYWOOD ST & SANDERS AVE",
    stop_lat: 35.1797,
    stop_lon: -89.975361,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1587,
    stop_code: "HOLPERSN",
    stop_name: "HOLLYWOOD@PERES",
    stop_desc: "N HOLLYWOOD ST & PERES AVE",
    stop_lat: 35.17709,
    stop_lon: -89.975453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1588,
    stop_code: "HOLCHESN",
    stop_name: "HOLLYWOOD@CHELSEA",
    stop_desc: "? N HOLLYWOOD ST & PERES AVE",
    stop_lat: 35.174731,
    stop_lon: -89.975566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1589,
    stop_code: "CHEHARWN",
    stop_name: "CHELSEA@HARRISON",
    stop_desc: "CHELSEA AVE & HARRISON ST",
    stop_lat: 35.174796,
    stop_lon: -89.977714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1590,
    stop_code: "CHELAMWN",
    stop_name: "CHELSEA@LAMBERT",
    stop_desc: "CHELSEA AVE & LAMBERT ST",
    stop_lat: 35.17479,
    stop_lon: -89.982387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1591,
    stop_code: "CHEHYDWM",
    stop_name: "CHELSEA@HYDE PARK",
    stop_desc: "",
    stop_lat: 35.17452,
    stop_lon: -89.984039,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1592,
    stop_code: "CHEAUSWN",
    stop_name: "CHELSEA@AUSTIN",
    stop_desc: "",
    stop_lat: 35.17431,
    stop_lon: -89.985419,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1593,
    stop_code: "CHETUNWN",
    stop_name: "CHELSEA@TUNICA",
    stop_desc: "",
    stop_lat: 35.174134,
    stop_lon: -89.986555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1594,
    stop_code: "CHEBRIWN",
    stop_name: "CHELSEA@BRITTON",
    stop_desc: "",
    stop_lat: 35.173967,
    stop_lon: -89.987673,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1595,
    stop_code: "CHEMAYWN",
    stop_name: "CHELSEA@MAY",
    stop_desc: "",
    stop_lat: 35.173614,
    stop_lon: -89.989988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1596,
    stop_code: "CHEUNIWF",
    stop_name: "CHELSEA@UNIVERSITY",
    stop_desc: "CHELSEA & MCLEAN",
    stop_lat: 35.173272,
    stop_lon: -89.992264,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1597,
    stop_code: "CHEMCLWN",
    stop_name: "CHELSEA@MCLEAN",
    stop_desc: "",
    stop_lat: 35.172856,
    stop_lon: -89.995001,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1598,
    stop_code: "CHEMCLWF",
    stop_name: "CHELSEA@MCLEAN",
    stop_desc: "CHELSEA & EVERGREEN",
    stop_lat: 35.172674,
    stop_lon: -89.996496,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1599,
    stop_code: "CHEEVEWM",
    stop_name: "CHELSEA@EVERGREEN",
    stop_desc: "",
    stop_lat: 35.172706,
    stop_lon: -89.997973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1600,
    stop_code: "CHEWILWM",
    stop_name: "CHELSEA@WILLETT",
    stop_desc: "",
    stop_lat: 35.172803,
    stop_lon: -90.00089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1601,
    stop_code: "CHEWI1WM",
    stop_name: "CHELSEA@WILLETT",
    stop_desc: "",
    stop_lat: 35.172938,
    stop_lon: -90.004496,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1602,
    stop_code: "CHESTOWN",
    stop_name: "CHELSEA@STONEWALL",
    stop_desc: "",
    stop_lat: 35.173049,
    stop_lon: -90.006614,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1603,
    stop_code: "CHEWATWN",
    stop_name: "CHELSEA@WATKINS",
    stop_desc: "",
    stop_lat: 35.173131,
    stop_lon: -90.008281,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1604,
    stop_code: "CHECLAWM",
    stop_name: "CHELSEA@CLAYBROOK",
    stop_desc: "",
    stop_lat: 35.173148,
    stop_lon: -90.009524,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1605,
    stop_code: "CHECLAWN",
    stop_name: "CHELSEA@CLAYBROOK",
    stop_desc: "",
    stop_lat: 35.172387,
    stop_lon: -90.013631,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1606,
    stop_code: "CHECOLWN",
    stop_name: "CHELSEA@COLBY",
    stop_desc: "",
    stop_lat: 35.171886,
    stop_lon: -90.014992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1607,
    stop_code: "CHESYLWN",
    stop_name: "CHELSEA@SYLVAN",
    stop_desc: "",
    stop_lat: 35.171402,
    stop_lon: -90.016277,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1608,
    stop_code: "CHEBELWN",
    stop_name: "CHELSEA@BELLEVUE",
    stop_desc: "CHELSEA AVE & RAMP",
    stop_lat: 35.171049,
    stop_lon: -90.017135,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1609,
    stop_code: "CHELEWWN",
    stop_name: "CHELSEA@LEWIS",
    stop_desc: "",
    stop_lat: 35.170132,
    stop_lon: -90.019693,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1610,
    stop_code: "CHEBREWN",
    stop_name: "CHELSEA@BREEDLOVE",
    stop_desc: "? CHELSEA & BREEDLOVE",
    stop_lat: 35.16951,
    stop_lon: -90.021403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1611,
    stop_code: "CHEDECWN",
    stop_name: "CHELSEA@DECATUR",
    stop_desc: "",
    stop_lat: 35.168617,
    stop_lon: -90.023722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1612,
    stop_code: "CHEMORWN",
    stop_name: "CHELSEA@MOREHEAD",
    stop_desc: "",
    stop_lat: 35.168176,
    stop_lon: -90.024892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1613,
    stop_code: "CHEPEAWN",
    stop_name: "CHELSEA@PEARCE",
    stop_desc: "",
    stop_lat: 35.167086,
    stop_lon: -90.027828,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1614,
    stop_code: "CHEDUNWN",
    stop_name: "CHELSEA@DUNLAP",
    stop_desc: "",
    stop_lat: 35.166511,
    stop_lon: -90.02938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1615,
    stop_code: "CHELEAWN",
    stop_name: "CHELSEA@LEATH",
    stop_desc: "",
    stop_lat: 35.165978,
    stop_lon: -90.030816,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1616,
    stop_code: "CHEMANWF",
    stop_name: "CHELSEA@MANASSAS",
    stop_desc: "",
    stop_lat: 35.165409,
    stop_lon: -90.032366,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1617,
    stop_code: "CHEWOOWN",
    stop_name: "CHELSEA@WOODLAWN",
    stop_desc: "",
    stop_lat: 35.165065,
    stop_lon: -90.033306,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1618,
    stop_code: "CHETULWN",
    stop_name: "CHELSEA@TULLY",
    stop_desc: "",
    stop_lat: 35.164456,
    stop_lon: -90.034936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1619,
    stop_code: "CHETHOWN",
    stop_name: "CHELSEA@THOMAS",
    stop_desc: "CHELSEA AVE & STATE HWY 3",
    stop_lat: 35.163951,
    stop_lon: -90.036315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1620,
    stop_code: "CHESEVWN",
    stop_name: "CHELSEA@SEVENTH",
    stop_desc: "CHELSEA AVE & LAKE GROVE ST",
    stop_lat: 35.163299,
    stop_lon: -90.03812,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1621,
    stop_code: "CHEFIFWF",
    stop_name: "CHELSEA@FIFTH",
    stop_desc: "",
    stop_lat: 35.163399,
    stop_lon: -90.040286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1622,
    stop_code: "CHEFOUEF",
    stop_name: "CHELSEA@FOURTH",
    stop_desc: "",
    stop_lat: 35.163312,
    stop_lon: -90.041004,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1623,
    stop_code: "CHEFIFEN",
    stop_name: "CHELSEA@FIFTH",
    stop_desc: "",
    stop_lat: 35.163261,
    stop_lon: -90.040188,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1624,
    stop_code: "CHESIXEN",
    stop_name: "CHELSEA@SIXTH",
    stop_desc: "",
    stop_lat: 35.163176,
    stop_lon: -90.038865,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1625,
    stop_code: "CHETHOEN",
    stop_name: "CHELSEA@THOMAS",
    stop_desc: "",
    stop_lat: 35.163573,
    stop_lon: -90.036942,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1626,
    stop_code: "CHETULEM",
    stop_name: "CHELSEA@TULLY",
    stop_desc: "",
    stop_lat: 35.164012,
    stop_lon: -90.035668,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1627,
    stop_code: "CHETULEF",
    stop_name: "CHELSEA@TULLY",
    stop_desc: "",
    stop_lat: 35.164247,
    stop_lon: -90.035036,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1628,
    stop_code: "CHEWOOEN",
    stop_name: "CHELSEA@WOODLAWN",
    stop_desc: "",
    stop_lat: 35.16469,
    stop_lon: -90.033828,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1629,
    stop_code: "CHEMANEF",
    stop_name: "CHELSEA@MANASSAS",
    stop_desc: "",
    stop_lat: 35.165553,
    stop_lon: -90.031482,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1630,
    stop_code: "CHEDUNEN",
    stop_name: "CHELSEA@DUNLAP",
    stop_desc: "",
    stop_lat: 35.166145,
    stop_lon: -90.029868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1631,
    stop_code: "CHEPEAEN",
    stop_name: "CHELSEA@PEARCE",
    stop_desc: "",
    stop_lat: 35.166608,
    stop_lon: -90.028635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1632,
    stop_code: "CHEAYEEF",
    stop_name: "CHELSEA@AYERS",
    stop_desc: "CHELSEA AVE & AYERS ST",
    stop_lat: 35.167326,
    stop_lon: -90.026659,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1633,
    stop_code: "CHEMOREN",
    stop_name: "CHELSEA@MOREHEAD",
    stop_desc: "",
    stop_lat: 35.16783,
    stop_lon: -90.02535,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1634,
    stop_code: "CHEDECEN",
    stop_name: "CHELSEA@DECATUR",
    stop_desc: "",
    stop_lat: 35.168238,
    stop_lon: -90.024257,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1635,
    stop_code: "CHEKNEEN",
    stop_name: "CHELSEA@KNEY",
    stop_desc: "",
    stop_lat: 35.168675,
    stop_lon: -90.023084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1636,
    stop_code: "CHEBREEN",
    stop_name: "CHELSEA AVE@BREEDLOVE ST",
    stop_desc: "",
    stop_lat: 35.169155,
    stop_lon: -90.021864,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1637,
    stop_code: "CHELEWEN",
    stop_name: "CHELSEA@LEWIS",
    stop_desc: "",
    stop_lat: 35.169892,
    stop_lon: -90.019824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1638,
    stop_code: "CHESYLEM",
    stop_name: "CHELSEA@SYLVAN",
    stop_desc: "",
    stop_lat: 35.170916,
    stop_lon: -90.017057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1639,
    stop_code: "CHECOLEF",
    stop_name: "CHELSEA@COLBY",
    stop_desc: "",
    stop_lat: 35.171607,
    stop_lon: -90.015207,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1640,
    stop_code: "CHECLAEN",
    stop_name: "CHELSEA@CLAYBROOK",
    stop_desc: "",
    stop_lat: 35.172081,
    stop_lon: -90.013939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1641,
    stop_code: "CHEWATEM",
    stop_name: "CHELSEA@WATKINS",
    stop_desc: "",
    stop_lat: 35.172685,
    stop_lon: -90.011891,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1642,
    stop_code: "CHEWATEN",
    stop_name: "CHELSEA@WATKINS",
    stop_desc: "",
    stop_lat: 35.172968,
    stop_lon: -90.008669,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1643,
    stop_code: "WHIEDEEM",
    stop_name: "WHITNEY@EDEN",
    stop_desc: "WHITNEY AVE & EDEN ST",
    stop_lat: 35.207876,
    stop_lon: -90.001214,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1644,
    stop_code: "WHIEDEEF",
    stop_name: "WHITNEY@EDEN",
    stop_desc: "WHITNEY AVE & EDEN ST",
    stop_lat: 35.20783,
    stop_lon: -89.998998,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1645,
    stop_code: "WHIWOOEF",
    stop_name: "WHITNEY@WOODLAWN",
    stop_desc: "WHITNEY AVE & WOODLAWN TER",
    stop_lat: 35.207766,
    stop_lon: -89.996728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1646,
    stop_code: "WHIOVEEN",
    stop_name: "WHITNEY@OVERTON CROSSING",
    stop_desc: "WHITNEY AVE & OVERTON CROSSING ST",
    stop_lat: 35.207702,
    stop_lon: -89.994512,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1647,
    stop_code: "WHIUNIEM",
    stop_name: "WHITNEY@UNIVERSITY",
    stop_desc: "0 WHITNEY AVE & UNNAMED ST",
    stop_lat: 35.206959,
    stop_lon: -89.992331,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1656,
    stop_code: "SCHALPNN",
    stop_name: "SCHOOLFIELD@ALPENA",
    stop_desc: "0 SCHOOLFIELD RD & ALPENA AVE",
    stop_lat: 35.22501,
    stop_lon: -89.969444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1659,
    stop_code: "SCHLAKNN",
    stop_name: "SCHOOLFIELD@LAKE PARK",
    stop_desc: "SCHOOLFIELD RD & FIELDBROOK DR",
    stop_lat: 35.229594,
    stop_lon: -89.968418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1661,
    stop_code: "STESCHWF",
    stop_name: "STELMO@SCHOOLFIELD",
    stop_desc: "0 SAINT ELMO AVE & ALPENA AVE",
    stop_lat: 35.23349,
    stop_lon: -89.969167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1662,
    stop_code: "STESCHEN",
    stop_name: "ST ELMO@SCHOOLFIELD",
    stop_desc: "ST ELMO AVE & SCHOOLFIELD CV",
    stop_lat: 35.233374,
    stop_lon: -89.96879,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1663,
    stop_code: "SCHGRUSM",
    stop_name: "SCHOOLFIELD@GRUBER",
    stop_desc: "SCHOOLFIELD RD & GRUBER DR",
    stop_lat: 35.232668,
    stop_lon: -89.968714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1665,
    stop_code: "SCHFIESN",
    stop_name: "SCHOOLFIELD@FIELDBROOK",
    stop_desc: "SCHOOLFIELD RD & FIELDBROOK DR",
    stop_lat: 35.227968,
    stop_lon: -89.968964,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1666,
    stop_code: "SCHBROSN",
    stop_name: "SCHOOLFIELD@BROOKVIEW",
    stop_desc: "SCHOOLFIELD RD & BROOKVIEW CV",
    stop_lat: 35.227003,
    stop_lon: -89.969209,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1667,
    stop_code: "SCHALPSN",
    stop_name: "SCHOOLFIELD@ALPENA",
    stop_desc: "SCHOOLFIELD RD & ALPENA AVE",
    stop_lat: 35.225287,
    stop_lon: -89.969665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1668,
    stop_code: "SCHFRASM",
    stop_name: "SCHOOLFIELD@FRAYSER",
    stop_desc: "SCHOOLFIELD RD & FIELDBROOK DR",
    stop_lat: 35.221928,
    stop_lon: -89.969958,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1682,
    stop_code: "JAMBIREN",
    stop_name: "JAMES@BIRCHFIELD",
    stop_desc: "JAMES RD & BIRCHFIELD DR",
    stop_lat: 35.199774,
    stop_lon: -89.969134,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1683,
    stop_code: "JAMBIRWN",
    stop_name: "JAMES@BIRCHFIELD",
    stop_desc: "0 JAMES RD",
    stop_lat: 35.1999,
    stop_lon: -89.969244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1684,
    stop_code: "JAMRANWF",
    stop_name: "JAMES RD@RANGE LINE RD",
    stop_desc: "0 JAMES RD & N HOLLYWOOD ST",
    stop_lat: 35.1992,
    stop_lon: -89.976345,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1685,
    stop_code: "WINWINWF",
    stop_name: "WINCHESTER@WINPLACE",
    stop_desc: "",
    stop_lat: 35.050702,
    stop_lon: -89.88912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1686,
    stop_code: "WINGERWN",
    stop_name: "WINCHESTER@GERONIMO",
    stop_desc: "WINCHESTER RD & GERONIMO CT",
    stop_lat: 35.051769,
    stop_lon: -89.924544,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1687,
    stop_code: "WINLAMWF",
    stop_name: "WINCHESTER@LAMAR",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.051809,
    stop_lon: -89.927286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1688,
    stop_code: "WINAIRWN",
    stop_name: "WINCHESTER@AIR PARK",
    stop_desc: "WINCHESTER RD & AIR PARK ST",
    stop_lat: 35.051912,
    stop_lon: -89.931221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1689,
    stop_code: "WINGETWN",
    stop_name: "WINCHESTER RD @ AIR PARK ST",
    stop_desc: "WINCHESTER RD & REGAL BLVD",
    stop_lat: 35.051958,
    stop_lon: -89.932935,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1690,
    stop_code: "WINOGTWN",
    stop_name: "WINCHESTER@OLD GETWELL",
    stop_desc: "WINCHESTER RD & AIR PARK ST",
    stop_lat: 35.052052,
    stop_lon: -89.936726,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1691,
    stop_code: "WINMETWN",
    stop_name: "WINCHESTER@METROPOLITAN",
    stop_desc: "WINCHESTER RD & METROPOLITAN LN",
    stop_lat: 35.052074,
    stop_lon: -89.940109,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1692,
    stop_code: "WINPREWN",
    stop_name: "WINCHESTER@PRESCOTT",
    stop_desc: "WINCHESTER RD & PRESCOTT RD",
    stop_lat: 35.05214,
    stop_lon: -89.943182,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1693,
    stop_code: "WINLAKWF",
    stop_name: "WINCHESTER@PARK LAKE",
    stop_desc: "WINCHESTER RD & PARK LAKE DR",
    stop_lat: 35.052288,
    stop_lon: -89.94861,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1694,
    stop_code: "WINWIHWF",
    stop_name: "WINCHESTER@WINHOMA",
    stop_desc: "WINCHESTER RD & WINHOMA DR",
    stop_lat: 35.052317,
    stop_lon: -89.950445,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1695,
    stop_code: "WINTUCWF",
    stop_name: "WINCHESTER RD @ TCHULAHOMA RD",
    stop_desc: "? WINCHESTER RD & MERCER DR",
    stop_lat: 35.052364,
    stop_lon: -89.952711,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1696,
    stop_code: "WINBISWF",
    stop_name: "WINCHESTER@BISHOPS BRIDGE",
    stop_desc: "WINCHESTER RD & MERCER DR",
    stop_lat: 35.052666,
    stop_lon: -89.959985,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1697,
    stop_code: "WINSWIWF",
    stop_name: "WINCHESTER@SWINNEA",
    stop_desc: "WINCHESTER RD & RACE ST",
    stop_lat: 35.052804,
    stop_lon: -89.964771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1698,
    stop_code: "WINCARWM",
    stop_name: "WINCHESTER@CARGO",
    stop_desc: "WINCHESTER RD & MERCER DR",
    stop_lat: 35.052851,
    stop_lon: -89.967502,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1699,
    stop_code: "WINPLOWM",
    stop_name: "WINCHESTER@PLOUGH",
    stop_desc: "2698 WINCHESTER RD & RAMP",
    stop_lat: 35.052184,
    stop_lon: -89.982327,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1700,
    stop_code: "WINAIRWF",
    stop_name: "WINCHESTER RD@AIRWAYS BLVD",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.053433,
    stop_lon: -89.991855,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1701,
    stop_code: "WINBOEWF",
    stop_name: "WINCHESTER@BOEINGSHIRE",
    stop_desc: "WINCHESTER RD & BOEINGSHIRE DR",
    stop_lat: 35.053525,
    stop_lon: -89.995415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1702,
    stop_code: "WINWBRWF",
    stop_name: "WINCHESTER@WINBROOK",
    stop_desc: "WINCHESTER RD & WINBROOK DR",
    stop_lat: 35.053599,
    stop_lon: -89.999771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1703,
    stop_code: "WINCAZWN",
    stop_name: "WINCHESTER@CAZASSA",
    stop_desc: "? WINCHESTER RD & CAZASSA RD",
    stop_lat: 35.053645,
    stop_lon: -90.001683,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1704,
    stop_code: "WINWINWN",
    stop_name: "WINCHESTER@WINDCREST",
    stop_desc: "WINCHESTER RD & WINDCREST RD",
    stop_lat: 35.053736,
    stop_lon: -90.004568,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1713,
    stop_code: "WINGILWF",
    stop_name: "WINCHESTER@GILL",
    stop_desc: "E MITCHELL RD & GILL RD",
    stop_lat: 35.055246,
    stop_lon: -90.057412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1714,
    stop_code: "WINTHIWN",
    stop_name: "WINCHESTER RD@3RD ST",
    stop_desc: "E MITCHELL RD & GILL RD",
    stop_lat: 35.055326,
    stop_lon: -90.059645,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1737,
    stop_code: "WINTHIEF",
    stop_name: "WINCHESTER RD@3RD ST",
    stop_desc: "",
    stop_lat: 35.05521,
    stop_lon: -90.059501,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1738,
    stop_code: "MITGILEN",
    stop_name: "MITCHELL@GILL",
    stop_desc: "E MITCHELL RD & GILL RD",
    stop_lat: 35.055085,
    stop_lon: -90.057401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1745,
    stop_code: "WINMILEF",
    stop_name: "WINCHESTER RD@MILLBRANCH RD",
    stop_desc: "WINCHESTER RD & WINDCREST RD",
    stop_lat: 35.05363,
    stop_lon: -90.006647,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1746,
    stop_code: "WINWINEF",
    stop_name: "WINCHESTER@WINDCREST",
    stop_desc: "WINCHESTER RD & WINDCREST RD",
    stop_lat: 35.053575,
    stop_lon: -90.004392,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1747,
    stop_code: "WINCAZEN",
    stop_name: "WINCHESTER@CAZASSA",
    stop_desc: "WINCHESTER RD & CAZASSA RD",
    stop_lat: 35.053493,
    stop_lon: -90.00207,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1748,
    stop_code: "WINCAZEM",
    stop_name: "WINCHESTER@CAZASSA",
    stop_desc: "WINCHESTER RD & CAZASSA RD",
    stop_lat: 35.053447,
    stop_lon: -90.000114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1749,
    stop_code: "WINWINEN",
    stop_name: "WINCHESTER@WINBROOK",
    stop_desc: "WINCHESTER RD & WINBROOK DR",
    stop_lat: 35.053438,
    stop_lon: -89.999229,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1750,
    stop_code: "WINBOEEN",
    stop_name: "WINCHESTER@BOEINGSHIRE",
    stop_desc: "WINCHESTER RD & BOEINGSHIRE DR",
    stop_lat: 35.053355,
    stop_lon: -89.995194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1751,
    stop_code: "WINAIREN",
    stop_name: "WINCHESTER RD@AIRWAYS BLVD",
    stop_desc: "WINCHESTER RD & BOEINGSHIRE DR",
    stop_lat: 35.053263,
    stop_lon: -89.991524,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1752,
    stop_code: "WINHOLEN",
    stop_name: "WINCHESTER@HOLLY",
    stop_desc: "WINCHESTER RD & BOEINGSHIRE DR",
    stop_lat: 35.053209,
    stop_lon: -89.990098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1753,
    stop_code: "WINCAREF",
    stop_name: "WINCHESTER@CARGO",
    stop_desc: "? WINCHESTER RD & RAMP",
    stop_lat: 35.052066,
    stop_lon: -89.980094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1754,
    stop_code: "WINSWIEM",
    stop_name: "WINCHESTER@SWINNEA",
    stop_desc: "WINCHESTER RD & RACE ST",
    stop_lat: 35.052583,
    stop_lon: -89.966728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1755,
    stop_code: "WINNANEF",
    stop_name: "WINCHESTER@NANCY",
    stop_desc: "WINCHESTER RD & NANCY RD",
    stop_lat: 35.052464,
    stop_lon: -89.963777,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1756,
    stop_code: "WINBISEN",
    stop_name: "WINCHESTER@BISHOPS BRIDGE",
    stop_desc: "WINCHESTER RD & UNION AVE",
    stop_lat: 35.052344,
    stop_lon: -89.95972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1757,
    stop_code: "WINTCHEN",
    stop_name: "WINCHESTER RD@TCHULAHOMA RD",
    stop_desc: "",
    stop_lat: 35.05225,
    stop_lon: -89.954978,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1758,
    stop_code: "WINNTCEN",
    stop_name: "WINCHESTER@NEW TCHULAHOMA",
    stop_desc: "WINCHESTER RD & NEW TCHULAHOMA RD",
    stop_lat: 35.052194,
    stop_lon: -89.95217,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1759,
    stop_code: "WINPAREN",
    stop_name: "WINCHESTER@PARK LAKE",
    stop_desc: "WINCHESTER RD & PARK LAKE DR",
    stop_lat: 35.052127,
    stop_lon: -89.948577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1760,
    stop_code: "WINPREEF",
    stop_name: "WINCHESTER@PRESCOTT",
    stop_desc: "WINCHESTER RD & PRESCOTT RD",
    stop_lat: 35.051969,
    stop_lon: -89.942939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1761,
    stop_code: "WINMETEF",
    stop_name: "WINCHESTER@ METROPOLITAN LN",
    stop_desc: "WINCHESTER RD & METROPOLITAN LN",
    stop_lat: 35.051913,
    stop_lon: -89.940142,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1762,
    stop_code: "WINGETEN",
    stop_name: "WINCHESTER@OLD GETWELL",
    stop_desc: "WINCHESTER RD & OLD GETWELL RD",
    stop_lat: 35.051838,
    stop_lon: -89.937545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1763,
    stop_code: "WINGETEF",
    stop_name: "WINCHESTER@OLD GETWELL",
    stop_desc: "WINCHESTER RD & OLD GETWELL RD",
    stop_lat: 35.051747,
    stop_lon: -89.936141,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1764,
    stop_code: "WINGE1EN",
    stop_name: "WINCHESTER RD@GETWELL RD",
    stop_desc: "WINCHESTER & NEW GETWELL",
    stop_lat: 35.051715,
    stop_lon: -89.934528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1765,
    stop_code: "WINAIREF",
    stop_name: "WINCHESTER@AIR PARK",
    stop_desc: "0 WINCHESTER RD & AIR PARK ST",
    stop_lat: 35.051589,
    stop_lon: -89.930846,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1766,
    stop_code: "WINGEREF",
    stop_name: "WINCHESTER@GERONIMO CT",
    stop_desc: "WINCHESTER RD & OLD GETWELL RD",
    stop_lat: 35.051456,
    stop_lon: -89.924081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1767,
    stop_code: "WINTALEN",
    stop_name: "WINCHESTER@TALL OAKS CR",
    stop_desc: "WINCHESTER RD & TALL OAKS CIR",
    stop_lat: 35.050842,
    stop_lon: -89.899142,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1768,
    stop_code: "WINCLEEF",
    stop_name: "WINCHESTER@CLEARBROOK",
    stop_desc: "WINCHESTER RD & CLEARBROOK ST",
    stop_lat: 35.050794,
    stop_lon: -89.896976,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1769,
    stop_code: "WINCASEF",
    stop_name: "WINCHESTER RD@CASTLEMAN ST",
    stop_desc: "",
    stop_lat: 35.050597,
    stop_lon: -89.891184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1770,
    stop_code: "WINWI1EN",
    stop_name: "WINCHESTER@WINPLACE",
    stop_desc: "WINCHESTER RD & WINPLACE RD",
    stop_lat: 35.050531,
    stop_lon: -89.88894,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1771,
    stop_code: "WINMENEF",
    stop_name: "WINCHESTER RD @ MENDENHALL RD",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.050462,
    stop_lon: -89.884772,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1772,
    stop_code: "WINFALEF",
    stop_name: "WINCHESTER@FALL RIVER",
    stop_desc: "WINCHESTER RD & FALL RIVER DR",
    stop_lat: 35.050433,
    stop_lon: -89.883114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1773,
    stop_code: "WINEMEEF",
    stop_name: "WINCHESTER@EMERALD",
    stop_desc: "WINCHESTER RD & EMERALD ST",
    stop_lat: 35.050357,
    stop_lon: -89.880295,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1774,
    stop_code: "WINHEAEN",
    stop_name: "WINCHESTER@HEATHER RIDGE",
    stop_desc: "WINCHESTER RD & HEATHER RIDGE DR",
    stop_lat: 35.050309,
    stop_lon: -89.87814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1775,
    stop_code: "WINCLAEN",
    stop_name: "WINCHESTER@CLARKE",
    stop_desc: "WINCHESTER RD & CLARKE RD",
    stop_lat: 35.05026,
    stop_lon: -89.875443,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1776,
    stop_code: "WINFOXEM",
    stop_name: "WINCHESTER@FOX MEADOWS",
    stop_desc: "WINCHESTER RD & FOX MEADOWS RD",
    stop_lat: 35.050221,
    stop_lon: -89.87374,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1777,
    stop_code: "WINFANEN",
    stop_name: "WINCHESTER@FANN",
    stop_desc: "WINCHESTER RD & FANN RD",
    stop_lat: 35.050107,
    stop_lon: -89.869849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1778,
    stop_code: "WINHICEN",
    stop_name: "WINCHESTER@HICKORY HILL",
    stop_desc: "WINCHESTER RD & HICKORY HILL RD",
    stop_lat: 35.050004,
    stop_lon: -89.86692,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1779,
    stop_code: "WINLICEN",
    stop_name: "WINCHESTER@LICHTERMAN",
    stop_desc: "WINCHESTER RD & LITCHERMAN ST",
    stop_lat: 35.049919,
    stop_lon: -89.864466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1780,
    stop_code: "WINRIDEF",
    stop_name: "WINCHESTER@RIDGEWAY",
    stop_desc: "WINCHESTER RD & S HICKORY RIDGE MALL",
    stop_lat: 35.049709,
    stop_lon: -89.856773,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1781,
    stop_code: "WINWOOEN",
    stop_name: "WINCHESTER@WOODY LN",
    stop_desc: "WINCHESTER RD & WINCHEST CV",
    stop_lat: 35.049604,
    stop_lon: -89.853103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1782,
    stop_code: "WINKIREF",
    stop_name: "WINCHESTER@KIRBY RD",
    stop_desc: "WINCHESTER RD & KIRBY RD",
    stop_lat: 35.049522,
    stop_lon: -89.847488,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1783,
    stop_code: "WINKIREN",
    stop_name: "WINCHESTER@KIRBY PKWY",
    stop_desc: "WINCHESTER RD & KIRBY RD",
    stop_lat: 35.049472,
    stop_lon: -89.844669,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1784,
    stop_code: "WINROSEM",
    stop_name: "WINCHESTER@ROSS",
    stop_desc: "WINCHESTER RD & KIRBY RD",
    stop_lat: 35.049394,
    stop_lon: -89.841342,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1785,
    stop_code: "WINROSEF",
    stop_name: "WINCHESTER@ROSS RD",
    stop_desc: "WINCHESTER RD & ROSS RD",
    stop_lat: 35.049325,
    stop_lon: -89.838258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1786,
    stop_code: "WINKIREM",
    stop_name: "WINCHESTER@KIRBY TERRACE",
    stop_desc: "WINCHESTER RD & KIRBY TERRACE DR",
    stop_lat: 35.049257,
    stop_lon: -89.835494,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1787,
    stop_code: "WINRIVEN",
    stop_name: "WINCHESTER@RIVERDALE",
    stop_desc: "? WINCHESTER RD & KIRBY TERRACE DR",
    stop_lat: 35.04916,
    stop_lon: -89.831404,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1788,
    stop_code: "WINRIVEF",
    stop_name: "WINCHESTER@RIVERDALE BEND",
    stop_desc: "WINCHESTER RD & KIRBY TERRACE DR",
    stop_lat: 35.04889,
    stop_lon: -89.822153,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1789,
    stop_code: "WINGEREN",
    stop_name: "WINCHESTER@GERMANTOWN",
    stop_desc: "WINCHESTER RD & KIRBY TERRACE DR",
    stop_lat: 35.050517,
    stop_lon: -89.81389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1790,
    stop_code: "WINCENEM",
    stop_name: "WINCHESTER@CENTENNIAL DR",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.0496,
    stop_lon: -89.807238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1791,
    stop_code: "WINCENEN",
    stop_name: "WINCHESTER@CENTENNIAL DR",
    stop_desc: "? WINCHESTER RD & RAMP",
    stop_lat: 35.049227,
    stop_lon: -89.804409,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1792,
    stop_code: "WINCOUEM",
    stop_name: "WINCHESTER@COUNTY GATE",
    stop_desc: "WINCHESTER RD & WINCROSS DR",
    stop_lat: 35.048826,
    stop_lon: -89.801083,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1793,
    stop_code: "WINHACEN",
    stop_name: "WINCHESTER@HACKS CROSS",
    stop_desc: "? WINCHESTER RD & HACKS CROSS RD",
    stop_lat: 35.048261,
    stop_lon: -89.796133,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1794,
    stop_code: "HACCENSM",
    stop_name: "HACKS CROSS@CENTENNIAL",
    stop_desc: "HACKS CROSS RD & HACKS CROSS RD",
    stop_lat: 35.046151,
    stop_lon: -89.796096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1795,
    stop_code: "CENHACWF",
    stop_name: "CENTENNIAL DR@HACKS CROSS RD",
    stop_desc: "CAPILANO DR & N THIRD ST",
    stop_lat: 35.043848,
    stop_lon: -89.797066,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1796,
    stop_code: "CENELRWM",
    stop_name: "CENTENNIAL DR@EL RINCON",
    stop_desc: "CAPILANO DR & EL RINCON DR",
    stop_lat: 35.043967,
    stop_lon: -89.798668,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1799,
    stop_code: "WINCEMWF",
    stop_name: "WINCHESTER@CENTENNIAL",
    stop_desc: "WINCHESTER RD & EL RINCON DR",
    stop_lat: 35.049388,
    stop_lon: -89.804398,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1800,
    stop_code: "WINGERWF",
    stop_name: "WINCHESTER@GERMANTOWN",
    stop_desc: "WINCHESTER RD & GERMANTOWN RD",
    stop_lat: 35.05066,
    stop_lon: -89.813734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1801,
    stop_code: "WINRIVWM",
    stop_name: "WINCHESTER@RIVERDALE BEND",
    stop_desc: "WINCHESTER RD & GERMANTOWN RD",
    stop_lat: 35.049095,
    stop_lon: -89.821666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1802,
    stop_code: "WINMALWM",
    stop_name: "WINCHESTER RD@ MALCO WAY",
    stop_desc: "? WINCHESTER RD & MALCO WAY",
    stop_lat: 35.049215,
    stop_lon: -89.827966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1803,
    stop_code: "WINKIRWM",
    stop_name: "WINCHESTER@KIRBY TERRACE",
    stop_desc: "6940 WINCHESTER RD & MALCO WAY",
    stop_lat: 35.04933,
    stop_lon: -89.831713,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1804,
    stop_code: "WINBROWM",
    stop_name: "WINCHESTER@BROWN BARK",
    stop_desc: "WINCHESTER RD & BROWNBARK DR",
    stop_lat: 35.049437,
    stop_lon: -89.836168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1805,
    stop_code: "WINROSWF",
    stop_name: "WINCHESTER@ROSS",
    stop_desc: "? WINCHESTER RD & ROSS RD",
    stop_lat: 35.049506,
    stop_lon: -89.839119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1806,
    stop_code: "WINKIRWF",
    stop_name: "WINCHESTER@KIRBY",
    stop_desc: "WINCHESTER RD & ROSS RD",
    stop_lat: 35.049623,
    stop_lon: -89.844403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1807,
    stop_code: "WINFIRWF",
    stop_name: "WINCHESTER@FIR LN",
    stop_desc: "WINCHESTER RD & FIR LN",
    stop_lat: 35.049744,
    stop_lon: -89.851566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1808,
    stop_code: "WINRIDWM",
    stop_name: "WINCHESTER@RIDGEWAY",
    stop_desc: "WINCHESTER RD & WINCHEST CV",
    stop_lat: 35.049812,
    stop_lon: -89.854672,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1810,
    stop_code: "WINLICWM",
    stop_name: "WINCHESTER@LICHTERMAN",
    stop_desc: "6016 WINCHESTER RD & LITCHERMAN ST",
    stop_lat: 35.049996,
    stop_lon: -89.862454,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1811,
    stop_code: "WINLICWN",
    stop_name: "WINCHESTER@LICHTERMAN",
    stop_desc: "WINCHESTER RD & LITCHERMAN ST",
    stop_lat: 35.050061,
    stop_lon: -89.863957,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1812,
    stop_code: "WINHICWN",
    stop_name: "WINCHESTER@HICKORY HILL",
    stop_desc: "WINCHESTER RD & HICKORY HILL RD",
    stop_lat: 35.050146,
    stop_lon: -89.86629,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1813,
    stop_code: "WINHICWF",
    stop_name: "WINCHESTER@HICKORY HILL",
    stop_desc: "WINCHESTER RD & HICKORY HILL RD",
    stop_lat: 35.050183,
    stop_lon: -89.867384,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1814,
    stop_code: "WINFOXWM",
    stop_name: "WINCHESTER@FOX MEADOWS",
    stop_desc: "5764 WINCHESTER RD & WINCHEST CV",
    stop_lat: 35.050315,
    stop_lon: -89.871341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1815,
    stop_code: "WINCLAWN",
    stop_name: "WINCHESTER@CLARKE",
    stop_desc: "WINCHESTER RD & CLARKE RD",
    stop_lat: 35.050401,
    stop_lon: -89.874779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1816,
    stop_code: "WINCLAWF",
    stop_name: "WINCHESTER@CLARKE",
    stop_desc: "5626 WINCHESTER RD & CLARKE RD",
    stop_lat: 35.050421,
    stop_lon: -89.875685,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1817,
    stop_code: "WINFREWN",
    stop_name: "WINCHESTER@FRESHWATER",
    stop_desc: "WINCHESTER RD & FRESHWATER DR",
    stop_lat: 35.050539,
    stop_lon: -89.882207,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1819,
    stop_code: "WINMENWN",
    stop_name: "WINCHESTER@MENDENHALL",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.050624,
    stop_lon: -89.885468,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1820,
    stop_code: "MENFLOSM",
    stop_name: "MENDENHALL@FLOWERING PEACH",
    stop_desc: "0 S MENDENHALL RD & DURBY CIR",
    stop_lat: 35.049213,
    stop_lon: -89.886334,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1821,
    stop_code: "MENFLOS2",
    stop_name: "MENDENHALL@FLOWERING PEACH",
    stop_desc: "0 S MENDENHALL RD",
    stop_lat: 35.048409,
    stop_lon: -89.886258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1822,
    stop_code: "MENMENSN",
    stop_name: "MENDENHALL@MENDENHALL PARK",
    stop_desc: "0 S MENDENHALL RD & DURBY CIR",
    stop_lat: 35.043779,
    stop_lon: -89.886037,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1823,
    stop_code: "MENSHASN",
    stop_name: "MENDENHALL@SHARP",
    stop_desc: "S MENDENHALL RD & SHARP BLVD",
    stop_lat: 35.041142,
    stop_lon: -89.8859,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1825,
    stop_code: "MENTRUSF",
    stop_name: "MENDENHALL@TRUST DR",
    stop_desc: "",
    stop_lat: 35.038021,
    stop_lon: -89.884813,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1826,
    stop_code: "MENRAISN",
    stop_name: "MENDENHALL@RAINES",
    stop_desc: "4145 S MENDENHALL RD & E RAINES RD",
    stop_lat: 35.035233,
    stop_lon: -89.884842,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1828,
    stop_code: "RAICHRWM",
    stop_name: "RAINES@CHRYSLER",
    stop_desc: "E RAINES RD & CHRYSLER DR",
    stop_lat: 35.03531,
    stop_lon: -89.894259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1830,
    stop_code: "RAIOUTWN",
    stop_name: "E RAINES RD@OUTLAND RD",
    stop_desc: "E RAINES RD & RAMP",
    stop_lat: 35.035355,
    stop_lon: -89.900592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1831,
    stop_code: "OUWARNF",
    stop_name: "OUTLAND@WARRINGTON",
    stop_desc: "0 OUTLAND RD & WARRINGTON RD",
    stop_lat: 35.04281,
    stop_lon: -89.901592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1832,
    stop_code: "OUTCRONF",
    stop_name: "OUTLAND@CROMWELL",
    stop_desc: "OUTLAND RD & CROMWELL RD",
    stop_lat: 35.046644,
    stop_lon: -89.901639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1833,
    stop_code: "OUTFOXNF",
    stop_name: "OUTLAND@FOXHALL",
    stop_desc: "OUTLAND RD & FOXHALL DR",
    stop_lat: 35.048521,
    stop_lon: -89.901646,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1834,
    stop_code: "OUTWINNN",
    stop_name: "OUTLAND@WINCHESTER",
    stop_desc: "OUTLAND RD & CHESTERWOOD CT",
    stop_lat: 35.050586,
    stop_lon: -89.901674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1842,
    stop_code: "COTBRAEN",
    stop_name: "COTTONWOOD@BRANDALE",
    stop_desc: "COTTONWOOD RD & BRANDALE ST",
    stop_lat: 35.069233,
    stop_lon: -89.904475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1843,
    stop_code: "COTCLEEM",
    stop_name: "COTTONWOOD@CLEARBROOK",
    stop_desc: "0 COTTONWOOD RD & WESTMEADE ST",
    stop_lat: 35.069148,
    stop_lon: -89.901125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1844,
    stop_code: "COTPRIEF",
    stop_name: "COTTONWOOD@PRIDE COVE",
    stop_desc: "COTTONWOOD RD & CURTIS ST",
    stop_lat: 35.06907,
    stop_lon: -89.896437,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1846,
    stop_code: "COTCHUEF",
    stop_name: "COTTONWOOD@CHURCHILL",
    stop_desc: "COTTONWOOD RD & CHURCHILL ST",
    stop_lat: 35.068944,
    stop_lon: -89.890146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1847,
    stop_code: "MENDARSF",
    stop_name: "MENDENHALL@DARGEN",
    stop_desc: "S MENDENHALL RD & DARGEN AVE",
    stop_lat: 35.066927,
    stop_lon: -89.885706,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1848,
    stop_code: "MENHORSN",
    stop_name: "MENDENHALL@HORSESHOE",
    stop_desc: "S MENDENHALL RD & HORSESHOE TRL",
    stop_lat: 35.063647,
    stop_lon: -89.885835,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1850,
    stop_code: "MENSCOSN",
    stop_name: "MENDENHALL@SCOTTSDALE",
    stop_desc: "S MENDENHALL RD & SCOTTSDALE AVE",
    stop_lat: 35.057525,
    stop_lon: -89.886115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1851,
    stop_code: "MENMEASM",
    stop_name: "MENDENHALL@MEADOWICK",
    stop_desc: "S MENDENHALL RD & MEADOWICK AVE",
    stop_lat: 35.055291,
    stop_lon: -89.886165,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1852,
    stop_code: "MENWINSM",
    stop_name: "MENDENHALL@WINCHESTER",
    stop_desc: "S MENDENHALL RD & MENDENHALL MALL",
    stop_lat: 35.052976,
    stop_lon: -89.886259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1854,
    stop_code: "MENNEWNN",
    stop_name: "MENDENHALL@NEWBERRY",
    stop_desc: "0 S MENDENHALL RD & EVERETT LN",
    stop_lat: 35.053825,
    stop_lon: -89.886013,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1856,
    stop_code: "MENSCONN",
    stop_name: "MENDENHALL@SCOTTSDALE",
    stop_desc: "S MENDENHALL RD & SCOTTSDALE AVE",
    stop_lat: 35.057131,
    stop_lon: -89.885928,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1857,
    stop_code: "MENKNINM",
    stop_name: "MENDENHALL@KNIGHT ARNOLD",
    stop_desc: "S MENDENHALL RD & CORTINA CT",
    stop_lat: 35.060179,
    stop_lon: -89.885843,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1858,
    stop_code: "MENKNINF",
    stop_name: "MENDENHALL@KNIGHT ARNOLD",
    stop_desc: "S MENDENHALL RD & KNIGHT ARNOLD RD",
    stop_lat: 35.062422,
    stop_lon: -89.885761,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1859,
    stop_code: "MENDERNN",
    stop_name: "MENDENHALL@DERRON",
    stop_desc: "S MENDENHALL RD & DERRON AVE",
    stop_lat: 35.064379,
    stop_lon: -89.885656,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1860,
    stop_code: "MENCOTNN",
    stop_name: "MENDENHALL RD @ COTTONWOOD RD",
    stop_desc: "S MENDENHALL RD & COTTONWOOD RD",
    stop_lat: 35.068687,
    stop_lon: -89.885414,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1861,
    stop_code: "COTCHUWN",
    stop_name: "COTTONWOOD@CHURCHILL",
    stop_desc: "COTTONWOOD RD & CHURCHILL ST",
    stop_lat: 35.069105,
    stop_lon: -89.890156,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1866,
    stop_code: "COTBRAWM",
    stop_name: "COTTONWOOD@BRANDALE",
    stop_desc: "",
    stop_lat: 35.06932,
    stop_lon: -89.90373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1868,
    stop_code: "PERSCOSM",
    stop_name: "PERKINS@SCOTTSDALE",
    stop_desc: "0 S PERKINS RD & SCOTTSDALE AVE",
    stop_lat: 35.058573,
    stop_lon: -89.906068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1875,
    stop_code: "POPLAUEM",
    stop_name: "POPLAR@LAUDERDALE",
    stop_desc: "0 POPLAR AVE & N LAUDERDALE ST",
    stop_lat: 35.14843,
    stop_lon: -90.044645,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1876,
    stop_code: "POPHIGEN",
    stop_name: "POPLAR@HIGH",
    stop_desc: "POPLAR AVE & HIGH ST",
    stop_lat: 35.14767,
    stop_lon: -90.041867,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1877,
    stop_code: "POPHAMEN",
    stop_name: "POPLAR@HAMLIN PLACE",
    stop_desc: "POPLAR AVE & HAMLIN PL",
    stop_lat: 35.146946,
    stop_lon: -90.039112,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1878,
    stop_code: "POPORLEN",
    stop_name: "POPLAR@ORLEANS",
    stop_desc: "POPLAR AVE & N ORLEANS ST",
    stop_lat: 35.146418,
    stop_lon: -90.037153,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1879,
    stop_code: "POPMANEN",
    stop_name: "POPLAR@MANASSSAS",
    stop_desc: "761 POPLAR AVE & N MANASSAS ST",
    stop_lat: 35.145863,
    stop_lon: -90.035028,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1880,
    stop_code: "POPDUNEF",
    stop_name: "POPLAR@DUNLAP",
    stop_desc: "POPLAR AVE & N DUNLAP ST",
    stop_lat: 35.14538,
    stop_lon: -90.032183,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1881,
    stop_code: "POPPAUEN",
    stop_name: "POPLAR@PAULINE",
    stop_desc: "POPLAR AVE & AYERS ST",
    stop_lat: 35.145227,
    stop_lon: -90.030224,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1883,
    stop_code: "POPDECEN",
    stop_name: "POPLAR@DECATUR",
    stop_desc: "POPLAR AVE & DECATUR ST",
    stop_lat: 35.144931,
    stop_lon: -90.026993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1884,
    stop_code: "POPCOCEF",
    stop_name: "POPLAR@COCHRAN",
    stop_desc: "POPLAR AVE & COCHRAN PL",
    stop_lat: 35.14477,
    stop_lon: -90.025476,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1885,
    stop_code: "POPWALEN",
    stop_name: "POPLAR@WALDRAN",
    stop_desc: "POPLAR AVE & N WALDRAN BLVD",
    stop_lat: 35.144599,
    stop_lon: -90.023794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1886,
    stop_code: "POPBELEN",
    stop_name: "POPLAR@BELLEVUE",
    stop_desc: "POPLAR AVE & N BELLEVUE BLVD",
    stop_lat: 35.144375,
    stop_lon: -90.02116,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1887,
    stop_code: "POPMONEN",
    stop_name: "POPLAR@MONTGOMERY",
    stop_desc: "POPLAR AVE & N MONTGOMERY ST",
    stop_lat: 35.14424,
    stop_lon: -90.019799,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1888,
    stop_code: "POPCLAEN",
    stop_name: "POPLAR@CLAYBROOK",
    stop_desc: "POPLAR AVE & N CLAYBROOK ST",
    stop_lat: 35.144069,
    stop_lon: -90.017818,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1889,
    stop_code: "MILCINSF",
    stop_name: "MILLINGTON@CINDY LN",
    stop_desc: "MILLINGTON RD & CINDY LN",
    stop_lat: 35.213527,
    stop_lon: -90.028838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1890,
    stop_code: "POPWATEN",
    stop_name: "POPLAR@WATKINS",
    stop_desc: "POPLAR AVE & N WATKINS ST",
    stop_lat: 35.143701,
    stop_lon: -90.013778,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1891,
    stop_code: "POPMCNEN",
    stop_name: "POPLAR@MCNEIL",
    stop_desc: "POPLAR AVE & N MCNEIL ST",
    stop_lat: 35.143539,
    stop_lon: -90.01203,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1892,
    stop_code: "POPSTOEN",
    stop_name: "POPLAR@STONEWALL",
    stop_desc: "POPLAR AVE & STONEWALL ST",
    stop_lat: 35.143423,
    stop_lon: -90.010845,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1893,
    stop_code: "POPCL1EN",
    stop_name: "POPLAR@CLARK PL",
    stop_desc: "POPLAR AVE & CLARK PL",
    stop_lat: 35.143297,
    stop_lon: -90.009285,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1894,
    stop_code: "POPWILEN",
    stop_name: "POPLAR@WILLET",
    stop_desc: "POPLAR AVE & N WILLETT ST",
    stop_lat: 35.14318,
    stop_lon: -90.008012,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1895,
    stop_code: "POPANGEN",
    stop_name: "POPLAR@ANGELUS",
    stop_desc: "POPLAR AVE & ANGELUS ST",
    stop_lat: 35.143009,
    stop_lon: -90.006042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1896,
    stop_code: "POPEVEEN",
    stop_name: "POPLAR@EVERGREEN",
    stop_desc: "POPLAR AVE & N EVERGREEN ST",
    stop_lat: 35.142784,
    stop_lon: -90.003264,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1897,
    stop_code: "POPAUBEN",
    stop_name: "POPLAR@AUBURNDALE",
    stop_desc: "POPLAR AVE & N AUBURNDALE ST",
    stop_lat: 35.142685,
    stop_lon: -90.001914,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1898,
    stop_code: "POPHAWEF",
    stop_name: "POPLAR@HAWTHORNE",
    stop_desc: "POPLAR AVE & HAWTHORNE ST",
    stop_lat: 35.142577,
    stop_lon: -90.000442,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1899,
    stop_code: "POPMCLEN",
    stop_name: "POPLAR@MCLEAN",
    stop_desc: "1847 POPLAR AVE & N MCLEAN BLVD",
    stop_lat: 35.14246,
    stop_lon: -89.999269,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1900,
    stop_code: "POPBAREF",
    stop_name: "POPLAR@BARKSDALE",
    stop_desc: "0 POPLAR AVE & KENILWORTH PL",
    stop_lat: 35.142289,
    stop_lon: -89.996923,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1902,
    stop_code: "POPREMEF",
    stop_name: "POPLAR@REMBERT",
    stop_desc: "POPLAR AVE & N REMBERT ST",
    stop_lat: 35.142091,
    stop_lon: -89.994577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1903,
    stop_code: "POPBELEF",
    stop_name: "POPLAR@BELLEAIR",
    stop_desc: "POPLAR AVE & N REMBERT ST",
    stop_lat: 35.141767,
    stop_lon: -89.991367,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1904,
    stop_code: "POPCOOEN",
    stop_name: "POPLAR@COOPER",
    stop_desc: "0 POPLAR AVE & BELLEAIR DR",
    stop_lat: 35.141641,
    stop_lon: -89.989718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1905,
    stop_code: "POPCOXEF",
    stop_name: "POPLAR@COX",
    stop_desc: "POPLAR AVE & N REMBERT ST",
    stop_lat: 35.141335,
    stop_lon: -89.986432,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1908,
    stop_code: "POPALIEN",
    stop_name: "POPLAR AVE@ALICIA DR",
    stop_desc: "2449 POPLAR & ALICIA",
    stop_lat: 35.139006,
    stop_lon: -89.979118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1909,
    stop_code: "POPALIEF",
    stop_name: "POPLAR@ALICIA",
    stop_desc: "STATE HWY 57 & N ALICIA DR",
    stop_lat: 35.138683,
    stop_lon: -89.978245,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1910,
    stop_code: "POPMEREN",
    stop_name: "POPLAR@MERTON",
    stop_desc: "US HWY 72 & N MERTON ST",
    stop_lat: 35.13802,
    stop_lon: -89.976298,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1911,
    stop_code: "POPBINEN",
    stop_name: "POPLAR@BINGHAM",
    stop_desc: "STATE HWY 57 & N BINGHAM ST",
    stop_lat: 35.137644,
    stop_lon: -89.975247,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1912,
    stop_code: "POPUNIEN",
    stop_name: "POPLAR AVE@UNION AVE EXT",
    stop_desc: "",
    stop_lat: 35.137017,
    stop_lon: -89.973466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1913,
    stop_code: "POPSCOEN",
    stop_name: "POPLAR@SCOTT",
    stop_desc: "US HWY 72 & SCOTT ST",
    stop_lat: 35.135387,
    stop_lon: -89.969218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1914,
    stop_code: "POPHUMEM",
    stop_name: "POPLAR@HUMES",
    stop_desc: "2785 US HWY 72 & N HUMES ST",
    stop_lat: 35.134796,
    stop_lon: -89.967514,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1915,
    stop_code: "POPTILEN",
    stop_name: "POPLAR@TILLMAN",
    stop_desc: "? STATE HWY 57 & TILLMAN ST",
    stop_lat: 35.133363,
    stop_lon: -89.963643,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1916,
    stop_code: "POPSIOEF",
    stop_name: "POPLAR@SIOUX",
    stop_desc: "US HWY 72 & SIOUX ST",
    stop_lat: 35.132556,
    stop_lon: -89.961475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1917,
    stop_code: "POPLAFEF",
    stop_name: "POPLAR@LAFAYETTE",
    stop_desc: "STATE HWY 57 & LAFAYETTE ST",
    stop_lat: 35.131355,
    stop_lon: -89.957947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1918,
    stop_code: "POPMAREN",
    stop_name: "POPLAR@MARNE",
    stop_desc: "US HWY 72 & MARNE ST",
    stop_lat: 35.13062,
    stop_lon: -89.955668,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1919,
    stop_code: "POPHOLEN",
    stop_name: "POPLAR@HOLMES",
    stop_desc: "3275 US HWY 72 & S HOLMES ST",
    stop_lat: 35.129885,
    stop_lon: -89.95329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1920,
    stop_code: "POPREEEN",
    stop_name: "POPLAR@REESE",
    stop_desc: "US HWY 72 & S REESE ST",
    stop_lat: 35.128908,
    stop_lon: -89.949839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1929,
    stop_code: "POPWILEM",
    stop_name: "POPLAR@WILLIAMSBURG",
    stop_desc: "STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.123356,
    stop_lon: -89.924486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1932,
    stop_code: "POPGREEF",
    stop_name: "POPLAR@GREENFIELD",
    stop_desc: "US HWY 72 & GREENFIELD RD",
    stop_lat: 35.121321,
    stop_lon: -89.919068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1933,
    stop_code: "POPCHEEF",
    stop_name: "POPLAR@CHERRY",
    stop_desc: "US HWY 72 & CHERRY RD",
    stop_lat: 35.120433,
    stop_lon: -89.917001,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1934,
    stop_code: "POPOAKEN",
    stop_name: "POPLAR@OAK COURT",
    stop_desc: "STATE HWY 57 & OAK COURT DR",
    stop_lat: 35.119358,
    stop_lon: -89.914469,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1935,
    stop_code: "POPGROEM",
    stop_name: "POPLAR@GROVE PARK",
    stop_desc: "4473 US HWY 72 & S GROVE PARK RD",
    stop_lat: 35.118739,
    stop_lon: -89.913021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1936,
    stop_code: "POPPEREM",
    stop_name: "POPLAR@PERKINS",
    stop_desc: "4547 STATE HWY 57 & PARKING LOT",
    stop_lat: 35.117806,
    stop_lon: -89.9107,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1937,
    stop_code: "POPPEREN",
    stop_name: "POPLAR@PERKINS EXTD",
    stop_desc: "",
    stop_lat: 35.11716,
    stop_lon: -89.90942,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1938,
    stop_code: "POPPE1EN",
    stop_name: "POPLAR@PERKINS",
    stop_desc: "US HWY 72 & STATE HWY 57",
    stop_lat: 35.11613,
    stop_lon: -89.906919,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1939,
    stop_code: "POPPEREF",
    stop_name: "POPLAR AVE@S PERKINS RD",
    stop_desc: "4667 US HWY 72 & S PERKINS RD",
    stop_lat: 35.11578,
    stop_lon: -89.906068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1940,
    stop_code: "POPCOLEM",
    stop_name: "POPLAR@COLONIAL",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.115152,
    stop_lon: -89.904399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1941,
    stop_code: "POPCOLEN",
    stop_name: "POPLAR@COLONIAL",
    stop_desc: "US HWY 72 & COLONIAL RD",
    stop_lat: 35.114577,
    stop_lon: -89.902287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1942,
    stop_code: "POPERIEN",
    stop_name: "POPLAR@ERIN",
    stop_desc: "4881 STATE HWY 57 & ERIN DR",
    stop_lat: 35.113858,
    stop_lon: -89.89929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1943,
    stop_code: "POPMOSEN",
    stop_name: "POPLAR@MOSS",
    stop_desc: "STATE HWY 57 & MOSS RD",
    stop_lat: 35.113499,
    stop_lon: -89.897786,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1944,
    stop_code: "POPMENEF",
    stop_name: "POPLAR@MENDENHALL",
    stop_desc: "4989 STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.112789,
    stop_lon: -89.895486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1945,
    stop_code: "POPTRUEN",
    stop_name: "POPLAR@TRUSE PKWY",
    stop_desc: "5055 STATE HWY 57 & TRUSE PKWY",
    stop_lat: 35.111543,
    stop_lon: -89.893055,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1946,
    stop_code: "POPWHIEF",
    stop_name: "POPLAR@WHITE STATION",
    stop_desc: "0 POPLAR AVE & S WHITE STATION RD",
    stop_lat: 35.108953,
    stop_lon: -89.888194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1947,
    stop_code: "POPPELEN",
    stop_name: "POPLAR@PELHAM DR",
    stop_desc: "STATE HWY 57 & PELHAM CIR",
    stop_lat: 35.108334,
    stop_lon: -89.886901,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1948,
    stop_code: "POPESTEN",
    stop_name: "POPLAR@ESTATE DR",
    stop_desc: "US HWY 72 & ESTATE DR",
    stop_lat: 35.1075,
    stop_lon: -89.884602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1950,
    stop_code: "POPREDEN",
    stop_name: "POPLAR@REDDOCH",
    stop_desc: "US HWY 72 & REDDOCH ST",
    stop_lat: 35.10651,
    stop_lon: -89.880621,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1951,
    stop_code: "POPJUNEF",
    stop_name: "POPLAR@JUNE",
    stop_desc: "US HWY 72 & JUNE RD",
    stop_lat: 35.105961,
    stop_lon: -89.878244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1952,
    stop_code: "POPBROEN",
    stop_name: "POPLAR@BROOKFIELD",
    stop_desc: "RAMP & JUNE RD",
    stop_lat: 35.104515,
    stop_lon: -89.873934,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1953,
    stop_code: "POPRIDEN",
    stop_name: "POPLAR@RIDGEWAY LOOP",
    stop_desc: "RAMP & PALO ALTO DR W",
    stop_lat: 35.102451,
    stop_lon: -89.863474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1955,
    stop_code: "POPSHAEF",
    stop_name: "POPLAR@SHADY GROVE",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.101592,
    stop_lon: -89.858167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1956,
    stop_code: "POPBRIEN",
    stop_name: "POPLAR@BRIARCREST",
    stop_desc: "US HWY 72 & STATE HWY 57",
    stop_lat: 35.101177,
    stop_lon: -89.855845,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1957,
    stop_code: "POPMASEM",
    stop_name: "POPLAR@MASSEY",
    stop_desc: "US HWY 72 & BRIARCREST AVE",
    stop_lat: 35.100933,
    stop_lon: -89.854562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1958,
    stop_code: "POPMASEN",
    stop_name: "POPLAR@MASSEY",
    stop_desc: "STATE HWY 57 & US HWY 72",
    stop_lat: 35.100581,
    stop_lon: -89.852616,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1959,
    stop_code: "POPMASEF",
    stop_name: "POPLAR@MASSEY",
    stop_desc: "6351 US HWY 72 & MASSEY RD",
    stop_lat: 35.100401,
    stop_lon: -89.851632,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1960,
    stop_code: "POPPOPEN",
    stop_name: "POPLAR@POPLAR CREST",
    stop_desc: "US HWY 72 & POPLAR CREST CV",
    stop_lat: 35.100301,
    stop_lon: -89.851113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1961,
    stop_code: "POPINTEF",
    stop_name: "POPLAR@INTERNATIONAL PL",
    stop_desc: "US HWY 72 & INTERNATIONAL DR",
    stop_lat: 35.099715,
    stop_lon: -89.84794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1962,
    stop_code: "POPAAREN",
    stop_name: "POPLAR@AARON BRENNER",
    stop_desc: "6517 US HWY 72 & POPLAR PINES DR",
    stop_lat: 35.099344,
    stop_lon: -89.84595,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1963,
    stop_code: "POPPOPEM",
    stop_name: "POPLAR@POPLAR WOODS",
    stop_desc: "US HWY 72 & POPLAR WOODS CIR W",
    stop_lat: 35.098866,
    stop_lon: -89.843374,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1964,
    stop_code: "POPKIREM",
    stop_name: "POPLAR@KIRBY",
    stop_desc: "STATE HWY 57 & KIRBY PKWY",
    stop_lat: 35.098522,
    stop_lon: -89.841384,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1965,
    stop_code: "POPKIREF",
    stop_name: "POPLAR AVE@KIRBY PKWY",
    stop_desc: "STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.098142,
    stop_lon: -89.838907,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1966,
    stop_code: "POPOAKEM",
    stop_name: "POPLAR@OAK HILL",
    stop_desc: "US HWY 72 & OAK HILL RD",
    stop_lat: 35.09776,
    stop_lon: -89.835579,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1967,
    stop_code: "POPVA1EM",
    stop_name: "POPLAR@VAL VERDE",
    stop_desc: "US HWY 72 & POPLAR ESTATES PKWY",
    stop_lat: 35.097169,
    stop_lon: -89.830503,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1968,
    stop_code: "POPRIVEF",
    stop_name: "POPLAR@RIVERDALE",
    stop_desc: "US HWY 72 & RIVERDALE RD",
    stop_lat: 35.096031,
    stop_lon: -89.820585,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1996,
    stop_code: "POPEXEWF",
    stop_name: "POPLAR@EXETER",
    stop_desc: "STATE HWY 57 & EXETER RD",
    stop_lat: 35.090633,
    stop_lon: -89.8042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1997,
    stop_code: "POPGERWN",
    stop_name: "POPLAR@GERMANTOWN",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.092752,
    stop_lon: -89.808506,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1998,
    stop_code: "POPGERWM",
    stop_name: "POPLAR@GERMANTOWN",
    stop_desc: "STATE HWY 57 & S GERMANTOWN RD",
    stop_lat: 35.093264,
    stop_lon: -89.809555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 1999,
    stop_code: "POPINNWN",
    stop_name: "POPLAR@INNSBRUCK DR",
    stop_desc: "POPLAR AVE & DRIVEWAY",
    stop_lat: 35.094359,
    stop_lon: -89.811974,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2001,
    stop_code: "POPMILFA",
    stop_name: "POPLAR@MILLER",
    stop_desc: "US HWY 72 & MILLER FARMS RD",
    stop_lat: 35.09552,
    stop_lon: -89.815686,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2002,
    stop_code: "POPRIVWF",
    stop_name: "POPLAR@RIVERDAL",
    stop_desc: "STATE HWY 57 & RIVERDALE RD",
    stop_lat: 35.096374,
    stop_lon: -89.822199,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2003,
    stop_code: "POPPOPWN",
    stop_name: "POPLAR@POPLAR ESTATES",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.097375,
    stop_lon: -89.83089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2004,
    stop_code: "POPOA1WN",
    stop_name: "POPLAR@OAK HILL",
    stop_desc: "US HWY 72 & OAK HILL RD",
    stop_lat: 35.097803,
    stop_lon: -89.834539,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2006,
    stop_code: "POPKIRWF",
    stop_name: "POPLAR AVE@KIRBY PKWY",
    stop_desc: "STATE HWY 57 & US HWY 72",
    stop_lat: 35.098457,
    stop_lon: -89.83989,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2007,
    stop_code: "POPRANWN",
    stop_name: "POPLAR@RANDOLPH",
    stop_desc: "? US HWY 72 & RANDOLPH PL",
    stop_lat: 35.098746,
    stop_lon: -89.841726,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2008,
    stop_code: "POPAARWF",
    stop_name: "POPLAR@AARON BRENNER",
    stop_desc: "6512 US HWY 72 & POPLAR PINES DR",
    stop_lat: 35.09955,
    stop_lon: -89.846126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2009,
    stop_code: "POPINTWN",
    stop_name: "POPLAR@INTERNATIONALPL",
    stop_desc: "US HWY 72 & INTERNATIONAL DR",
    stop_lat: 35.099812,
    stop_lon: -89.847541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2010,
    stop_code: "POPMASWM",
    stop_name: "POPLAR@MASSEY",
    stop_desc: "6412 US HWY 72 & INTERNATIONAL DR",
    stop_lat: 35.100218,
    stop_lon: -89.849752,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2011,
    stop_code: "POPMASWN",
    stop_name: "POPLAR@MASSEY",
    stop_desc: "STATE HWY 57 & MASSEY RD",
    stop_lat: 35.100544,
    stop_lon: -89.85151,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2012,
    stop_code: "POPBRIWM",
    stop_name: "POPLAR@BRIARCREST",
    stop_desc: "",
    stop_lat: 35.101095,
    stop_lon: -89.854927,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2013,
    stop_code: "POPBRIWF",
    stop_name: "POPLAR@BRIARCREST",
    stop_desc: "STATE HWY 57 & US HWY 72",
    stop_lat: 35.101437,
    stop_lon: -89.856397,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2014,
    stop_code: "POPSHAWM",
    stop_name: "POPLAR@SHADY GROVE",
    stop_desc: "6152 US HWY 72 & POPLAR AVE",
    stop_lat: 35.101699,
    stop_lon: -89.857779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2015,
    stop_code: "POPRIDWN",
    stop_name: "POPLAR@RIDGEWAY LOOP",
    stop_desc: "5976 US HWY 72 & POPLAR AVE",
    stop_lat: 35.102655,
    stop_lon: -89.862876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2016,
    stop_code: "POPOAKWM",
    stop_name: "POPLAR@OAKHAVEN",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.104574,
    stop_lon: -89.871765,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2017,
    stop_code: "POPJUNWN",
    stop_name: "POPLAR@JUNE",
    stop_desc: "STATE HWY 57 & JUNE RD",
    stop_lat: 35.106194,
    stop_lon: -89.878542,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2018,
    stop_code: "POPVALWM",
    stop_name: "POPLAR@VALLEYBROOK",
    stop_desc: "5444 US HWY 72 & VALLEYBROOK DR",
    stop_lat: 35.10677,
    stop_lon: -89.880975,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2019,
    stop_code: "POPESTWN",
    stop_name: "POPLAR@ESTATE",
    stop_desc: "5320 STATE HWY 57 & ESTATE DR",
    stop_lat: 35.107409,
    stop_lon: -89.883507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2020,
    stop_code: "POPESTWF",
    stop_name: "POPLAR@ESTATE",
    stop_desc: "5326 US HWY 72 & ESTATE DR",
    stop_lat: 35.107678,
    stop_lon: -89.884601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2021,
    stop_code: "POPWHIWM",
    stop_name: "POPLAR@WHITE STATION",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.108872,
    stop_lon: -89.887596,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2022,
    stop_code: "POPWHIWF",
    stop_name: "POPLAR@WHITE STATION",
    stop_desc: "US HWY 72 & S WHITE STATION RD",
    stop_lat: 35.109589,
    stop_lon: -89.889011,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2023,
    stop_code: "POPBROWF",
    stop_name: "POPLAR@BROOKHAVEN",
    stop_desc: "STATE HWY 57 & E BROOKHAVEN CIR",
    stop_lat: 35.110261,
    stop_lon: -89.890215,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2024,
    stop_code: "POPTRUWN",
    stop_name: "POPLAR@TRUSE PKWY",
    stop_desc: "5098 STATE HWY 57 & TRUSE PKWY",
    stop_lat: 35.111328,
    stop_lon: -89.892259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2025,
    stop_code: "POPMENWM",
    stop_name: "POPLAR@MENDENHALL",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.112744,
    stop_lon: -89.894967,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2026,
    stop_code: "POPERIWN",
    stop_name: "POPLAR@ERIN",
    stop_desc: "US HWY 72 & ERIN DR",
    stop_lat: 35.113938,
    stop_lon: -89.898892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2028,
    stop_code: "POPCOLWN",
    stop_name: "POPLAR@COLONIAL",
    stop_desc: "4786 US HWY 72 & COLONIAL RD",
    stop_lat: 35.11481,
    stop_lon: -89.90253,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2029,
    stop_code: "POPPERWM",
    stop_name: "POPLAR@PERKINS",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.115529,
    stop_lon: -89.904962,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2030,
    stop_code: "POPPERWN",
    stop_name: "POPLAR AVE@S PERKINS RD",
    stop_desc: "US HWY 72 & POPLAR AVE",
    stop_lat: 35.11604,
    stop_lon: -89.906267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2031,
    stop_code: "POPGROWM",
    stop_name: "POPLAR@GROVE PARK",
    stop_desc: "? US HWY 72 & PARKING LOT",
    stop_lat: 35.117627,
    stop_lon: -89.90987,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2032,
    stop_code: "POPGROWN",
    stop_name: "POPLAR@ GROVE PARK",
    stop_desc: "4506 US HWY 72 & S GROVE PARK RD",
    stop_lat: 35.11829,
    stop_lon: -89.911451,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2033,
    stop_code: "POPOA2WN",
    stop_name: "POPLAR@OAK COURT",
    stop_desc: "4454 US HWY 72 & OAK COURT DR",
    stop_lat: 35.119303,
    stop_lon: -89.913927,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2034,
    stop_code: "POPCHEWM",
    stop_name: "POPLAR@CHERRY",
    stop_desc: "STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.119985,
    stop_lon: -89.915575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2035,
    stop_code: "POPCHEWF",
    stop_name: "POPLAR@ CHERRY",
    stop_desc: "US HWY 72 & CHERRY RD",
    stop_lat: 35.120998,
    stop_lon: -89.917929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2036,
    stop_code: "POPGREWF",
    stop_name: "POPLAR@GREENFIELD",
    stop_desc: "US HWY 72 & GREENFIELD RD",
    stop_lat: 35.121661,
    stop_lon: -89.919422,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2038,
    stop_code: "POPGOOWN",
    stop_name: "POPLAR@GOODLETT",
    stop_desc: "POPLAR AVE & RAMP",
    stop_lat: 35.123778,
    stop_lon: -89.92557,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2045,
    stop_code: "POPHIGWF",
    stop_name: "POPLAR AVE@HIGHLAND ST",
    stop_desc: "3488 STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.127947,
    stop_lon: -89.945558,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2046,
    stop_code: "POPPREWM",
    stop_name: "POPLAR@PRESCOTT",
    stop_desc: "3438 US HWY 72 & S PRESCOTT ST",
    stop_lat: 35.128575,
    stop_lon: -89.947914,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2047,
    stop_code: "POPREEWN",
    stop_name: "POPLAR@REESE",
    stop_desc: "US HWY 72 & S REESE ST",
    stop_lat: 35.129185,
    stop_lon: -89.950193,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2049,
    stop_code: "POPHOLWN",
    stop_name: "POPLAR@HOLMES",
    stop_desc: "US HWY 72 & S HOLMES ST",
    stop_lat: 35.129921,
    stop_lon: -89.952836,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2050,
    stop_code: "POPMARWM",
    stop_name: "POPLAR@MARNE",
    stop_desc: "3228 US HWY 72 & POPLAR AVE",
    stop_lat: 35.130485,
    stop_lon: -89.954661,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2052,
    stop_code: "POPTILWM",
    stop_name: "POPLAR@TILLMAN",
    stop_desc: "3042 STATE HWY 57 & US HWY 72",
    stop_lat: 35.132618,
    stop_lon: -89.961088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2053,
    stop_code: "POPTILWN",
    stop_name: "POPLAR@TILLMAN",
    stop_desc: "US HWY 72 & TILLMAN ST",
    stop_lat: 35.133398,
    stop_lon: -89.9632,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2054,
    stop_code: "POPFENWF",
    stop_name: "POPLAR@FENWICK",
    stop_desc: "STATE HWY 57 & POPLAR AVE",
    stop_lat: 35.13407,
    stop_lon: -89.964959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2055,
    stop_code: "POPSCOWN",
    stop_name: "POPLAR@SCOTT",
    stop_desc: "2766 POPLAR AVE & STATE HWY 57",
    stop_lat: 35.135369,
    stop_lon: -89.96862,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2056,
    stop_code: "POPCO1WN",
    stop_name: "POPLAR@COLLINS",
    stop_desc: "US HWY 72 & COLLINS ST",
    stop_lat: 35.137071,
    stop_lon: -89.973266,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2057,
    stop_code: "POPBINWN",
    stop_name: "POPLAR@BINGHAM",
    stop_desc: "US HWY 72 & N BINGHAM ST",
    stop_lat: 35.137724,
    stop_lon: -89.974959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2058,
    stop_code: "POPMERWN",
    stop_name: "POPLAR@MERTON",
    stop_desc: "STATE HWY 57 & N MERTON ST",
    stop_lat: 35.138172,
    stop_lon: -89.97622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2060,
    stop_code: "POPWI1WN",
    stop_name: "POPLAR@WILLIFORD",
    stop_desc: "US HWY 72 & WILLIFORD ST",
    stop_lat: 35.139525,
    stop_lon: -89.98007,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2061,
    stop_code: "POPCREWN",
    stop_name: "POPLAR@CRESTMERE",
    stop_desc: "STATE HWY 57 & CRESTMERE PL",
    stop_lat: 35.139919,
    stop_lon: -89.981154,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2062,
    stop_code: "POPEPAWN",
    stop_name: "POPLAR@EAST PARKWAY",
    stop_desc: "US HWY 72 & CRESTMERE PL",
    stop_lat: 35.140304,
    stop_lon: -89.982172,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2064,
    stop_code: "POPCOXWN",
    stop_name: "POPLAR@COX",
    stop_desc: "POPLAR AVE & RAMP",
    stop_lat: 35.141531,
    stop_lon: -89.986752,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2065,
    stop_code: "POPCO2WN",
    stop_name: "POPLAR@COOPER",
    stop_desc: "2144 POPLAR AVE & N COOPER ST",
    stop_lat: 35.141766,
    stop_lon: -89.989275,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2066,
    stop_code: "POPBELWF",
    stop_name: "POPLAR@BELLEAIR",
    stop_desc: "POPLAR AVE & BELLEAIR DR",
    stop_lat: 35.142036,
    stop_lon: -89.992452,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2068,
    stop_code: "POPKENWN",
    stop_name: "POPLAR@KENILWORTH",
    stop_desc: "POPLAR AVE & KENILWORTH PL",
    stop_lat: 35.142387,
    stop_lon: -89.995904,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2069,
    stop_code: "POPMCLWN",
    stop_name: "POPLAR@MCLEAN",
    stop_desc: "POPLAR AVE & N MCLEAN BLVD",
    stop_lat: 35.142594,
    stop_lon: -89.998926,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2070,
    stop_code: "POPHAWWN",
    stop_name: "POPLAR@HAWTHORNE",
    stop_desc: "POPLAR AVE & HAWTHORNE ST",
    stop_lat: 35.142765,
    stop_lon: -90.000885,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2071,
    stop_code: "POPEVEWN",
    stop_name: "POPLAR@EVERGREEN",
    stop_desc: "POPLAR AVE & N EVERGREEN ST",
    stop_lat: 35.142936,
    stop_lon: -90.003176,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2072,
    stop_code: "POPBE1WF",
    stop_name: "POPLAR@BELVEDERE",
    stop_desc: "POPLAR AVE & N BELVEDERE BLVD",
    stop_lat: 35.143089,
    stop_lon: -90.00498,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2073,
    stop_code: "POPAVAWN",
    stop_name: "POPLAR@AVALON",
    stop_desc: "POPLAR AVE & N AVALON ST",
    stop_lat: 35.143242,
    stop_lon: -90.00695,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2074,
    stop_code: "POPWILWN",
    stop_name: "POPLAR@WILLET",
    stop_desc: "POPLAR AVE & N WILLETT ST",
    stop_lat: 35.143359,
    stop_lon: -90.008289,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2075,
    stop_code: "POPSTOWN",
    stop_name: "POPLAR@STONEWALL",
    stop_desc: "POPLAR AVE & STONEWALL ST",
    stop_lat: 35.143548,
    stop_lon: -90.010414,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2076,
    stop_code: "POPMCNWF",
    stop_name: "POPLAR@MCNEIL",
    stop_desc: "POPLAR AVE & N MCNEIL ST",
    stop_lat: 35.143718,
    stop_lon: -90.012251,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2077,
    stop_code: "POPCLEWN",
    stop_name: "POPLAR AVE@CLEVELAND ST",
    stop_desc: "? POPLAR & CLEVELAND",
    stop_lat: 35.143984,
    stop_lon: -90.01511,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2078,
    stop_code: "MILSHISN",
    stop_name: "MILLINGTON@WHITNEY",
    stop_desc: "MILLINGTON RD & CINDY LN",
    stop_lat: 35.211463,
    stop_lon: -90.028839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2079,
    stop_code: "POPCLAWN",
    stop_name: "POPLAR@CLAYBROOK",
    stop_desc: "POPLAR AVE & N CLAYBROOK ST",
    stop_lat: 35.14414,
    stop_lon: -90.016755,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2080,
    stop_code: "POPCLAWF",
    stop_name: "POPLAR@CLAYBROOK",
    stop_desc: "1264 POPLAR AVE & N CLAYBROOK ST",
    stop_lat: 35.144257,
    stop_lon: -90.018227,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2081,
    stop_code: "POPMONWN",
    stop_name: "POPLAR@MONTGOMERY",
    stop_desc: "POPLAR AVE & N MONTGOMERY ST",
    stop_lat: 35.144338,
    stop_lon: -90.019057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2082,
    stop_code: "POPBE1WN",
    stop_name: "POPLAR@BELLEVUE",
    stop_desc: "POPLAR AVE & N BELLEVUE BLVD",
    stop_lat: 35.144491,
    stop_lon: -90.020717,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2083,
    stop_code: "POPWALWF",
    stop_name: "POPLAR@WALDRON",
    stop_desc: "POPLAR AVE & N WALDRAN BLVD",
    stop_lat: 35.144787,
    stop_lon: -90.024049,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2084,
    stop_code: "POPCOCWN",
    stop_name: "POPLAR@COCHRAN",
    stop_desc: "POPLAR AVE & COCHRAN PL",
    stop_lat: 35.144939,
    stop_lon: -90.025487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2085,
    stop_code: "POPDECWF",
    stop_name: "POPLAR@DECATUR",
    stop_desc: "POPLAR AVE & DECATUR ST",
    stop_lat: 35.145092,
    stop_lon: -90.026982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2086,
    stop_code: "POPPAUWN",
    stop_name: "POPLAR@PAULINE",
    stop_desc: "POPLAR AVE & N PAULINE ST",
    stop_lat: 35.145208,
    stop_lon: -90.028122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2087,
    stop_code: "POPAYEWN",
    stop_name: "POPLAR@AYERS",
    stop_desc: "POPLAR AVE & AYERS ST",
    stop_lat: 35.145352,
    stop_lon: -90.029793,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2088,
    stop_code: "POPDUNWN",
    stop_name: "POPLAR@DUNLAP",
    stop_desc: "POPLAR AVE & N DUNLAP ST",
    stop_lat: 35.145576,
    stop_lon: -90.032482,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2089,
    stop_code: "POPMANWN",
    stop_name: "POPLAR@MANASSAS",
    stop_desc: "POPLAR AVE & N MANASSAS ST",
    stop_lat: 35.145952,
    stop_lon: -90.034718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2090,
    stop_code: "POPORLWN",
    stop_name: "POPLAR@ORLEANS",
    stop_desc: "POPLAR AVE & N ORLEANS ST",
    stop_lat: 35.146453,
    stop_lon: -90.03661,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2091,
    stop_code: "POPHAMWN",
    stop_name: "POPLAR@HAMLIN PL",
    stop_desc: "POPLAR AVE & HAMLIN PL",
    stop_lat: 35.146999,
    stop_lon: -90.038636,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2092,
    stop_code: "POPHIGWM",
    stop_name: "POPLAR@HIGH",
    stop_desc: "POPLAR AVE & HAMLIN PL",
    stop_lat: 35.147437,
    stop_lon: -90.040329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2093,
    stop_code: "POPTHOWM",
    stop_name: "POPLAR@THOMAS",
    stop_desc: "POPLAR AVE & HAMLIN PL",
    stop_lat: 35.14801,
    stop_lon: -90.042388,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2094,
    stop_code: "POPLAUWF",
    stop_name: "POPLAR@LAUDERDALE",
    stop_desc: "POPLAR AVE & N LAUDERDALE ST",
    stop_lat: 35.148654,
    stop_lon: -90.044889,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2095,
    stop_code: "POPTHIWN",
    stop_name: "POPLAR@THIRD",
    stop_desc: "POPLAR AVE & N 3RD ST",
    stop_lat: 35.149494,
    stop_lon: -90.048054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2106,
    stop_code: "KNIMENEF",
    stop_name: "KNIGHT ARNOLD@MENDENHALL",
    stop_desc: "KNIGHT ARNOLD RD & S MENDENHALL RD",
    stop_lat: 35.061822,
    stop_lon: -89.884723,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2107,
    stop_code: "KNIEMEEF",
    stop_name: "KNIGHT ARNOLD@EMERALD",
    stop_desc: "KNIGHT ARNOLD RD & SPENCER DR",
    stop_lat: 35.061127,
    stop_lon: -89.880667,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2108,
    stop_code: "KNICLAEN",
    stop_name: "KNIGHT ARNOLD@CLARKE",
    stop_desc: "KNIGHT ARNOLD RD & KINGS ARMS ST",
    stop_lat: 35.060959,
    stop_lon: -89.876245,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2109,
    stop_code: "KNIFOXEN",
    stop_name: "KNIGHT ARNOLD@FOX GATE DR",
    stop_desc: "KNIGHT ARNOLD RD & FOXGATE DR",
    stop_lat: 35.060883,
    stop_lon: -89.87337,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2110,
    stop_code: "KNIFO1EN",
    stop_name: "KNIGHT ARNOLD@FOXBRIAR DR",
    stop_desc: "KNIGHT ARNOLD RD & FOXBRIAR DR",
    stop_lat: 35.060769,
    stop_lon: -89.869446,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2111,
    stop_code: "KNIRIDEF",
    stop_name: "KNIGHT ARNOLD RD@RIDGEWAY RD",
    stop_desc: "",
    stop_lat: 35.060066,
    stop_lon: -89.856125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2122,
    stop_code: "RIDPARNM",
    stop_name: "RIDGEWAY@PARK",
    stop_desc: "RIDGEWAY RD & ESTATE DR",
    stop_lat: 35.097366,
    stop_lon: -89.858377,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2135,
    stop_code: "VANFOUEN",
    stop_name: "VANCE@FOURTH",
    stop_desc: "VANCE AVE & S 4TH ST",
    stop_lat: 35.135205,
    stop_lon: -90.050238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2136,
    stop_code: "PARRIDWF",
    stop_name: "PARK@RIDGEWAY",
    stop_desc: "RAMP & PARK AVE",
    stop_lat: 35.100076,
    stop_lon: -89.859786,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2137,
    stop_code: "PARPRIWF",
    stop_name: "PARK@PRIMACY PKWY",
    stop_desc: "5978 PARK AVE & PARK AVE",
    stop_lat: 35.100038,
    stop_lon: -89.863857,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2138,
    stop_code: "PARBRIWN",
    stop_name: "PARK@BRISTOL DR",
    stop_desc: "PARK AVE & BRISTOL DR",
    stop_lat: 35.099928,
    stop_lon: -89.86691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2139,
    stop_code: "PARLYNWF",
    stop_name: "PARK@LYNNFIELD",
    stop_desc: "5820 PARK AVE & LYNNFIELD RD",
    stop_lat: 35.09986,
    stop_lon: -89.869255,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2140,
    stop_code: "PARCREWF",
    stop_name: "PARK@CRESTWOOD DR",
    stop_desc: "PARK AVE & W CRESTWOOD DR",
    stop_lat: 35.101519,
    stop_lon: -89.877713,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2141,
    stop_code: "PARHAYWN",
    stop_name: "PARK@HAYNE RD",
    stop_desc: "PARK AVE & HAYNE RD",
    stop_lat: 35.102704,
    stop_lon: -89.88094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2142,
    stop_code: "PARESTWN",
    stop_name: "PARK@ESTATE DR",
    stop_desc: "PARK AVE & ESTATE DR",
    stop_lat: 35.103897,
    stop_lon: -89.88409,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2143,
    stop_code: "PARESTWF",
    stop_name: "PARK@ESTATE",
    stop_desc: "PARK AVE & ESTATE DR",
    stop_lat: 35.104193,
    stop_lon: -89.884897,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2144,
    stop_code: "PARPARWN",
    stop_name: "PARK@PARK VALLEY",
    stop_desc: "PARK AVE & ESTATE DR",
    stop_lat: 35.105073,
    stop_lon: -89.887262,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2145,
    stop_code: "PARWHIWF",
    stop_name: "PARK@WHITE STATION",
    stop_desc: "PARK AVE & S WHITE STATION RD",
    stop_lat: 35.106374,
    stop_lon: -89.890733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2146,
    stop_code: "PARMTMWN",
    stop_name: "PARK@MT MORIAH",
    stop_desc: "5038 PARK AVE & MOUNT MORIAH RD",
    stop_lat: 35.106656,
    stop_lon: -89.894118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2147,
    stop_code: "PARMTMWF",
    stop_name: "PARK@MT MORIAH",
    stop_desc: "PARK AVE & MOUNT MORIAH RD",
    stop_lat: 35.106694,
    stop_lon: -89.895589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2149,
    stop_code: "PARIVYWF",
    stop_name: "PARK@IVY",
    stop_desc: "PARK AVE & IVY RD",
    stop_lat: 35.106817,
    stop_lon: -89.900091,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2150,
    stop_code: "PARCROWN",
    stop_name: "PARK@CROSSOVER LN",
    stop_desc: "PARK AVE & CROSSOVER LN",
    stop_lat: 35.10693,
    stop_lon: -89.904461,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2151,
    stop_code: "PARPERWN",
    stop_name: "PARK@PERKINS",
    stop_desc: "PARK AVE & S PERKINS RD",
    stop_lat: 35.107006,
    stop_lon: -89.907326,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2152,
    stop_code: "PARFAIWF",
    stop_name: "PARK@FAIR MEADOW",
    stop_desc: "PARK AVE & FAIRMEADOW RD",
    stop_lat: 35.107128,
    stop_lon: -89.91143,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2153,
    stop_code: "PARMEAWF",
    stop_name: "PARK@MEANDER DR",
    stop_desc: "PARK AVE & MEANDER DR",
    stop_lat: 35.107166,
    stop_lon: -89.913642,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2154,
    stop_code: "PARCHEWN",
    stop_name: "PARK AVE@CHERRY",
    stop_desc: "PARK AVE & CHERRY RD",
    stop_lat: 35.107204,
    stop_lon: -89.915003,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2155,
    stop_code: "PARCHEWF",
    stop_name: "PARK AVE@CHERRY RD",
    stop_desc: "",
    stop_lat: 35.107308,
    stop_lon: -89.919096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2156,
    stop_code: "PARHIAWM",
    stop_name: "PARK@HIAWATHA",
    stop_desc: "PARK AVE & HIAWATHA ST",
    stop_lat: 35.107411,
    stop_lon: -89.92289,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2157,
    stop_code: "PARGOOWM",
    stop_name: "PARK AVE@GOODLETT ST",
    stop_desc: "PARK AVE & S GRAHAM ST",
    stop_lat: 35.107448,
    stop_lon: -89.924306,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2158,
    stop_code: "PARGRAWF",
    stop_name: "PARK@GRAHAM",
    stop_desc: "4000 PARK AVE & S GRAHAM ST",
    stop_lat: 35.107562,
    stop_lon: -89.929395,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2159,
    stop_code: "PARSHOWN",
    stop_name: "PARK@SHOTWELL",
    stop_desc: "PARK AVE & GETWELL RD",
    stop_lat: 35.107627,
    stop_lon: -89.931674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2160,
    stop_code: "PARKELWN",
    stop_name: "PARK@KELLEY",
    stop_desc: "PARK AVE & KELLEY RD",
    stop_lat: 35.107692,
    stop_lon: -89.934052,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2161,
    stop_code: "PARGOOWF",
    stop_name: "PARK@GOODMAN",
    stop_desc: "PARK AVE & GOODMAN ST",
    stop_lat: 35.10773,
    stop_lon: -89.936243,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2162,
    stop_code: "PARECHWN",
    stop_name: "PARK@ECHLES",
    stop_desc: "PARK AVE & ECHLES ST",
    stop_lat: 35.107787,
    stop_lon: -89.938477,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2163,
    stop_code: "PARPATWN",
    stop_name: "PARK@PATTERSON",
    stop_desc: "PARK AVE & PATTERSON ST",
    stop_lat: 35.10787,
    stop_lon: -89.941508,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2164,
    stop_code: "PARHIGWM",
    stop_name: "PARK@HIGHLAND",
    stop_desc: "PARK AVE & PARKHAVEN LN",
    stop_lat: 35.107935,
    stop_lon: -89.943522,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2165,
    stop_code: "PARHI1WM",
    stop_name: "PARK AVE @ HIGHLAND ST",
    stop_desc: "PARK AVE & CHERRY RD",
    stop_lat: 35.107973,
    stop_lon: -89.94517,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2166,
    stop_code: "PARHIGWF",
    stop_name: "PARK AVE@HIGHLAND ST",
    stop_desc: "PARK AVE & S PRESCOTT ST",
    stop_lat: 35.107974,
    stop_lon: -89.946564,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2167,
    stop_code: "PARPREWF",
    stop_name: "PARK@PRESCOTT",
    stop_desc: "PARK AVE & S PRESCOTT ST",
    stop_lat: 35.107932,
    stop_lon: -89.948998,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2168,
    stop_code: "PARBROWN",
    stop_name: "PARK AVE@BROWER ST",
    stop_desc: "",
    stop_lat: 35.107873,
    stop_lon: -89.952361,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2169,
    stop_code: "PARGREWN",
    stop_name: "PARK@GREER",
    stop_desc: "PARK AVE & S GREER ST",
    stop_lat: 35.107847,
    stop_lon: -89.953545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2170,
    stop_code: "PARMAXWF",
    stop_name: "PARK@MAXEY",
    stop_desc: "PARK AVE & MAXEY RD",
    stop_lat: 35.107806,
    stop_lon: -89.956687,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2171,
    stop_code: "PARAUBWF",
    stop_name: "PARK@AUBRA",
    stop_desc: "PARK AVE & AUBRA RD",
    stop_lat: 35.10787,
    stop_lon: -89.959032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2172,
    stop_code: "PARCARWM",
    stop_name: "PARK@CARSON",
    stop_desc: "PARK AVE & CARSON ST",
    stop_lat: 35.107963,
    stop_lon: -89.962185,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2173,
    stop_code: "PARGO2WF",
    stop_name: "PARK@GOODWYN",
    stop_desc: "0 PARK AVE & INEZ ST",
    stop_lat: 35.107991,
    stop_lon: -89.964099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2175,
    stop_code: "PARMAYWN",
    stop_name: "PARK@MAYWOOD",
    stop_desc: "PARK AVE & HAYNES ST",
    stop_lat: 35.108092,
    stop_lon: -89.966731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2176,
    stop_code: "PARLAUWN",
    stop_name: "PARK@LAUREL",
    stop_desc: "PARK AVE & LAUREL ST",
    stop_lat: 35.108138,
    stop_lon: -89.968037,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2177,
    stop_code: "PARJOSWN",
    stop_name: "PARK@JOSEPHINE",
    stop_desc: "PARK AVE & JOSEPHINE ST",
    stop_lat: 35.108165,
    stop_lon: -89.968922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2178,
    stop_code: "PARPENWF",
    stop_name: "PARK@PENDLETON",
    stop_desc: "PARK AVE & PENDLETON ST",
    stop_lat: 35.108202,
    stop_lon: -89.970061,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2179,
    stop_code: "PARMARWF",
    stop_name: "PARK@MARIANNA",
    stop_desc: "PARK AVE & MARIANNA ST",
    stop_lat: 35.108239,
    stop_lon: -89.971499,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2180,
    stop_code: "PARBALWN",
    stop_name: "PARK@BALTIMORE",
    stop_desc: "PARK AVE & BALTIMORE ST",
    stop_lat: 35.108276,
    stop_lon: -89.972893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2181,
    stop_code: "PARDAVWN",
    stop_name: "PARK@DAVID",
    stop_desc: "PARK AVE & DAVID ST",
    stop_lat: 35.108322,
    stop_lon: -89.974431,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2182,
    stop_code: "PARHANWM",
    stop_name: "PARK@HANLEY",
    stop_desc: "PARK AVE & HANLEY ST",
    stop_lat: 35.108413,
    stop_lon: -89.976953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2183,
    stop_code: "PARHAMWF",
    stop_name: "PARK@HAMILTON",
    stop_desc: "PARK AVE & HAMILTON ST",
    stop_lat: 35.10845,
    stop_lon: -89.97817,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2184,
    stop_code: "PARKEAWF",
    stop_name: "PARK@KEATING",
    stop_desc: "PARK AVE & RAYMOND ST",
    stop_lat: 35.108488,
    stop_lon: -89.981035,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2185,
    stop_code: "PARGRAWN",
    stop_name: "PARK@GRAND",
    stop_desc: "PARK AVE & GRAND ST",
    stop_lat: 35.108506,
    stop_lon: -89.982119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2186,
    stop_code: "PARAIRWN",
    stop_name: "PARK@AIRWAYS",
    stop_desc: "PARK AVE & ETHEL ST",
    stop_lat: 35.108615,
    stop_lon: -89.984111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2187,
    stop_code: "PARSAMWN",
    stop_name: "PARK@SAMPLE ST",
    stop_desc: "PARK AVE & SAMPLE ST",
    stop_lat: 35.108661,
    stop_lon: -89.985659,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2190,
    stop_code: "LAMSPAWM",
    stop_name: "LAMAR@SOUTH PARKWAY",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.109772,
    stop_lon: -89.990415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2192,
    stop_code: "SPACASWN",
    stop_name: "SOUTH PARKWAY@CASTALIA",
    stop_desc: "",
    stop_lat: 35.109688,
    stop_lon: -89.994306,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2193,
    stop_code: "SPAWORWF",
    stop_name: "SOUTH PARKWAY@WORTHINGTON",
    stop_desc: "S PKWY & WORTHINGTON ST",
    stop_lat: 35.10932,
    stop_lon: -89.995936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2194,
    stop_code: "SPALAPWN",
    stop_name: "SOUTH PARKWAY@LAPALOMA",
    stop_desc: "S PKWY & LAPALOMA ST",
    stop_lat: 35.109143,
    stop_lon: -89.998094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2195,
    stop_code: "SPABARWF",
    stop_name: "SOUTH PARKWAY@BARKSDALE",
    stop_desc: "S PKWY & S BARKSDALE ST",
    stop_lat: 35.109153,
    stop_lon: -89.999543,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2196,
    stop_code: "SPAOAKWN",
    stop_name: "SOUTH PARKWAY@OAKLAWN",
    stop_desc: "S PARKWAY E & OAKLAWN ST",
    stop_lat: 35.109218,
    stop_lon: -90.003481,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2197,
    stop_code: "SPAKYLWN",
    stop_name: "SOUTH PARKWAY@KYLE",
    stop_desc: "S PKWY & KYLE ST",
    stop_lat: 35.109245,
    stop_lon: -90.004953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2198,
    stop_code: "SPAWILWM",
    stop_name: "SOUTH PARKWAY@WILLETT",
    stop_desc: "0 S E & CONNECTING RD",
    stop_lat: 35.109364,
    stop_lon: -90.009699,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2199,
    stop_code: "SPARAYWF",
    stop_name: "S PARKWAY@RAYNER",
    stop_desc: "0 S E",
    stop_lat: 35.109436,
    stop_lon: -90.011602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2200,
    stop_code: "SPADUNWN",
    stop_name: "S PARKWAY@DUNNAVANT",
    stop_desc: "S PKWY & DUNNAVANT ST",
    stop_lat: 35.109537,
    stop_lon: -90.015606,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2201,
    stop_code: "SPAWILWN",
    stop_name: "S PARKWAY@WILSON",
    stop_desc: "S PKWY & WILSON ST",
    stop_lat: 35.109645,
    stop_lon: -90.018295,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2202,
    stop_code: "SPABELWN",
    stop_name: "S PARKWAY@BELLEVUE",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109754,
    stop_lon: -90.021514,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2203,
    stop_code: "SPABELWF",
    stop_name: "S PARKWAY@BELLEVUE",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109763,
    stop_lon: -90.021945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2204,
    stop_code: "SPAGR1WN",
    stop_name: "S PARKWAY@GREENWOOD",
    stop_desc: "S PKWY & GREENWOOD ST",
    stop_lat: 35.109808,
    stop_lon: -90.024224,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2205,
    stop_code: "SPAWOOWN",
    stop_name: "S PARKWAY@WOODWARD",
    stop_desc: "S PKWY & GREENWOOD ST",
    stop_lat: 35.109835,
    stop_lon: -90.025054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2206,
    stop_code: "SPANIEWN",
    stop_name: "S PARKWAY@NIESE",
    stop_desc: "S PKWY & NIESE ST",
    stop_lat: 35.109881,
    stop_lon: -90.026758,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2219,
    stop_code: "SPAKANWM",
    stop_name: "SOUTH PARKWAY@KANSAS",
    stop_desc: "S PARKWAY W & KANSAS ST",
    stop_lat: 35.107851,
    stop_lon: -90.06497,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2222,
    stop_code: "SPALOUWF",
    stop_name: "SOUTH PARKWAY@LOUSIANA",
    stop_desc: "S PARKWAY W & S PKWY",
    stop_lat: 35.107949,
    stop_lon: -90.069395,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2224,
    stop_code: "SWISPASF",
    stop_name: "SWIFT@SOUTH PARKWAY",
    stop_desc: "SWIFT ST & S PARKWAY W",
    stop_lat: 35.107484,
    stop_lon: -90.07309,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2225,
    stop_code: "SWIBURSN",
    stop_name: "SWIFT@BURDOCK",
    stop_desc: "SWIFT ST & W BURDOCK AVE",
    stop_lat: 35.10618,
    stop_lon: -90.073146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2226,
    stop_code: "SWIWALSN",
    stop_name: "SWIFT@WALDORF",
    stop_desc: "SWIFT ST & W WALDORF AVE",
    stop_lat: 35.105357,
    stop_lon: -90.073168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2227,
    stop_code: "SWIFIESN",
    stop_name: "SWIFT@FIELDS",
    stop_desc: "SWIFT ST & FIELDS AVE",
    stop_lat: 35.103785,
    stop_lon: -90.073223,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2228,
    stop_code: "SWIPERSN",
    stop_name: "SWIFT@PERSON",
    stop_desc: "SWIFT ST & W PERSON AVE",
    stop_lat: 35.102203,
    stop_lon: -90.0733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2249,
    stop_code: "PERCASEN",
    stop_name: "PERSON@CASTEX",
    stop_desc: "W PERSON AVE & CASTEX ST",
    stop_lat: 35.10181,
    stop_lon: -90.06976,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2250,
    stop_code: "PERPENEM",
    stop_name: "PERSON@PENNSYLVANIA",
    stop_desc: "W PERSON AVE & PENNSYLVANIA ST",
    stop_lat: 35.101774,
    stop_lon: -90.068765,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2253,
    stop_code: "KANDISSN",
    stop_name: "KANSAS@DISON",
    stop_desc: "KANSAS ST & W DISON AVE",
    stop_lat: 35.10113,
    stop_lon: -90.065159,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2254,
    stop_code: "KANKIRSN",
    stop_name: "KANSAS@KIRK",
    stop_desc: "KANSAS ST & KIRK AVE",
    stop_lat: 35.099727,
    stop_lon: -90.065314,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2255,
    stop_code: "KANVAASF",
    stop_name: "KANSAS@VAAL AVE",
    stop_desc: "KANSAS ST & VAAL AVE",
    stop_lat: 35.097922,
    stop_lon: -90.065502,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2256,
    stop_code: "KANPRESN",
    stop_name: "KANSAS@PRETORIA",
    stop_desc: "KANSAS ST & PRETORIA AVE",
    stop_lat: 35.09651,
    stop_lon: -90.066475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2257,
    stop_code: "KANBLASN",
    stop_name: "KANSAS@BLAIR HUNT S",
    stop_desc: "KANSAS ST & S PKWY",
    stop_lat: 35.095482,
    stop_lon: -90.067216,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2258,
    stop_code: "KANFIRSN",
    stop_name: "KANSAS@FIRST",
    stop_desc: "KANSAS ST & 1ST AVE",
    stop_lat: 35.093498,
    stop_lon: -90.067615,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2268,
    stop_code: "SWIFIENF",
    stop_name: "SWIFT@FIELDS",
    stop_desc: "",
    stop_lat: 35.103865,
    stop_lon: -90.073115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2270,
    stop_code: "SWIEDSNN",
    stop_name: "SWIFT@EDSEL",
    stop_desc: "",
    stop_lat: 35.106841,
    stop_lon: -90.073024,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2275,
    stop_code: "SPAKENEN",
    stop_name: "SOUTH PARKWAY@KENTUCKY",
    stop_desc: "S PKWY & KENTUCKY ST",
    stop_lat: 35.107663,
    stop_lon: -90.063731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2283,
    stop_code: "SPAMILEN",
    stop_name: "SOUTH PARKWAY@MILLER",
    stop_desc: "S PKWY & MILLER ST",
    stop_lat: 35.107159,
    stop_lon: -90.041606,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2284,
    stop_code: "SPAWEBEM",
    stop_name: "SOUTH PARKWAY@WEBB",
    stop_desc: "S PKWY & TAYLOR ST",
    stop_lat: 35.107114,
    stop_lon: -90.038951,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2291,
    stop_code: "SPAENGEF",
    stop_name: "S PARKWAY@ENGLEWOOD",
    stop_desc: "S PKWY & ENGLEWOOD ST",
    stop_lat: 35.109523,
    stop_lon: -90.025939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2292,
    stop_code: "SPAGREEF",
    stop_name: "S PARKWAY@GREENWOOD",
    stop_desc: "S PKWY & GREENWOOD ST",
    stop_lat: 35.10946,
    stop_lon: -90.024037,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2293,
    stop_code: "SPAHEMEN",
    stop_name: "S PARKWAY@HEMLOCK",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109432,
    stop_lon: -90.022842,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2294,
    stop_code: "SPAELVEF",
    stop_name: "S PARKWAY@ELVIS PRESLEY",
    stop_desc: "S E & 51",
    stop_lat: 35.109405,
    stop_lon: -90.021625,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2295,
    stop_code: "SPAWILEN",
    stop_name: "S PARKWAY@WILSON",
    stop_desc: "S PARKWAY E & WILSON ST",
    stop_lat: 35.109305,
    stop_lon: -90.018571,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2296,
    stop_code: "SPADUNEN",
    stop_name: "S PARKWAY@DUNNAVANT",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109215,
    stop_lon: -90.015706,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2297,
    stop_code: "SPAPILEF",
    stop_name: "S PARKWAY@PILLOW",
    stop_desc: "0 S E & PILLOW ST",
    stop_lat: 35.109161,
    stop_lon: -90.014611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2298,
    stop_code: "SPASILEN",
    stop_name: "S PARKWAY@SILVER",
    stop_desc: "S PKWY & SILVER ST",
    stop_lat: 35.109106,
    stop_lon: -90.012531,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2299,
    stop_code: "SPAWILEF",
    stop_name: "S PARKWAY@WILLETT",
    stop_desc: "S PARKWAY E & S WILLETT ST",
    stop_lat: 35.109051,
    stop_lon: -90.010429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2300,
    stop_code: "SPAROZEM",
    stop_name: "SOUTH PARKWAY@ROZELLE",
    stop_desc: "0 S E & CONNECTING RD",
    stop_lat: 35.108961,
    stop_lon: -90.007464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2301,
    stop_code: "SPAKYLEF",
    stop_name: "SOUTH PARKWAY@KYLE",
    stop_desc: "S PKWY & KYLE ST",
    stop_lat: 35.108852,
    stop_lon: -90.004555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2302,
    stop_code: "SPAOAKEF",
    stop_name: "SOUTH PARKWAY@OAKLAWN",
    stop_desc: "S PKWY & OAKLAWN ST",
    stop_lat: 35.108833,
    stop_lon: -90.003404,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2303,
    stop_code: "SPABAREF",
    stop_name: "SOUTH PARKWAY@BARKSDALE",
    stop_desc: "S PKWY & S BARKSDALE ST",
    stop_lat: 35.108759,
    stop_lon: -89.999333,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2304,
    stop_code: "SPALAPEN",
    stop_name: "SOUTH PARKWAY@LAPALOMA",
    stop_desc: "S PKWY & LAPALOMA CIR",
    stop_lat: 35.108714,
    stop_lon: -89.997851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2305,
    stop_code: "SPACASEN",
    stop_name: "SOUTH PARKWAY@CASTALIA",
    stop_desc: "S PKWY & S PARKWAY E",
    stop_lat: 35.109203,
    stop_lon: -89.99462,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2306,
    stop_code: "SPACOOEN",
    stop_name: "SOUTH PARKWAY@COOPER",
    stop_desc: "S PARKWAY E & S PKWY",
    stop_lat: 35.109702,
    stop_lon: -89.992661,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2307,
    stop_code: "PARLAMEF",
    stop_name: "PARK@LAMAR",
    stop_desc: "PARK AVE & PHILADELPHIA ST",
    stop_lat: 35.108493,
    stop_lon: -89.987961,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2308,
    stop_code: "PARBRUEN",
    stop_name: "PARK@BRUCE",
    stop_desc: "PARK AVE & BRUCE ST",
    stop_lat: 35.108519,
    stop_lon: -89.986633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2309,
    stop_code: "PARAIREN",
    stop_name: "PARK@AIRWAYS",
    stop_desc: "? PARK AVE & SAMPLE ST",
    stop_lat: 35.108491,
    stop_lon: -89.985372,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2310,
    stop_code: "PARRAYEN",
    stop_name: "PARK@RAYMOND",
    stop_desc: "PARK AVE & RAYMOND ST",
    stop_lat: 35.108345,
    stop_lon: -89.981854,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2311,
    stop_code: "PARTUNEM",
    stop_name: "PARK@TUNSTALL",
    stop_desc: "PARK AVE & TUNSTALL ST",
    stop_lat: 35.108317,
    stop_lon: -89.980416,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2312,
    stop_code: "PARHAMEN",
    stop_name: "PARK@HAMILTON",
    stop_desc: "PARK AVE & HAMILTON ST",
    stop_lat: 35.108271,
    stop_lon: -89.977838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2313,
    stop_code: "PARDAVEN",
    stop_name: "PARK@DAVID",
    stop_desc: "PARK AVE & DAVID ST",
    stop_lat: 35.108179,
    stop_lon: -89.974885,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2314,
    stop_code: "PARBOSEN",
    stop_name: "PARK@BOSTON",
    stop_desc: "PARK AVE & BOSTON ST",
    stop_lat: 35.108133,
    stop_lon: -89.973624,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2315,
    stop_code: "PARBUNEN",
    stop_name: "PARK@BUNTYN",
    stop_desc: "PARK AVE & BUNTYN ST",
    stop_lat: 35.108106,
    stop_lon: -89.972495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2316,
    stop_code: "PARHILEN",
    stop_name: "PARK@HILTON",
    stop_desc: "PARK AVE & HILTON ST",
    stop_lat: 35.10806,
    stop_lon: -89.97088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2317,
    stop_code: "PARPENEN",
    stop_name: "PARK@PENDLETON",
    stop_desc: "PARK AVE & PENDLETON ST",
    stop_lat: 35.108032,
    stop_lon: -89.969829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2318,
    stop_code: "PARLAUEF",
    stop_name: "PARK@LAUREL",
    stop_desc: "PARK AVE & LAUREL ST",
    stop_lat: 35.107968,
    stop_lon: -89.967805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2319,
    stop_code: "PARHAYEN",
    stop_name: "PARK@HAYNES",
    stop_desc: "PARK AVE & HAYNES ST",
    stop_lat: 35.107912,
    stop_lon: -89.965913,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2320,
    stop_code: "PARGOOEF",
    stop_name: "PARK@GOODWYN",
    stop_desc: "PARK AVE & GOODWYN ST",
    stop_lat: 35.107839,
    stop_lon: -89.963723,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2321,
    stop_code: "PARSEMEN",
    stop_name: "PARK@SEMMES",
    stop_desc: "PARK AVE & SEMMES ST",
    stop_lat: 35.107792,
    stop_lon: -89.961632,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2322,
    stop_code: "PARNEWEN",
    stop_name: "PARK@NEWELL",
    stop_desc: "PARK AVE & NEWELL ST",
    stop_lat: 35.107737,
    stop_lon: -89.959939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2323,
    stop_code: "PARINMEN",
    stop_name: "PARK@INMAN",
    stop_desc: "PARK AVE & INMAN RD",
    stop_lat: 35.107672,
    stop_lon: -89.957572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2324,
    stop_code: "PARMAXEN",
    stop_name: "PARK@MAXEY",
    stop_desc: "PARK AVE & MAXEY RD",
    stop_lat: 35.107645,
    stop_lon: -89.956565,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2325,
    stop_code: "PARGREEN",
    stop_name: "PARK@GREER",
    stop_desc: "PARK AVE & S GREER ST",
    stop_lat: 35.107678,
    stop_lon: -89.954142,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2326,
    stop_code: "PARBROEF",
    stop_name: "PARK AVE@BROWER ST",
    stop_desc: "",
    stop_lat: 35.107712,
    stop_lon: -89.952217,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2327,
    stop_code: "PARPREEN",
    stop_name: "PARK@PRESCOTT",
    stop_desc: "PARK AVE & S PRESCOTT ST",
    stop_lat: 35.107771,
    stop_lon: -89.949075,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2329,
    stop_code: "PARHIGEF",
    stop_name: "PARK AVE @ HIGHLAND ST",
    stop_desc: "PARK AVE & WINDOVER RD",
    stop_lat: 35.107812,
    stop_lon: -89.94517,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2330,
    stop_code: "PARPAREN",
    stop_name: "PARK@PARKHAVEN LN",
    stop_desc: "PARK AVE & PARKHAVEN LN",
    stop_lat: 35.107746,
    stop_lon: -89.942626,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2331,
    stop_code: "PARROBEN",
    stop_name: "PARK@ROBIN HOOD",
    stop_desc: "PARK AVE & ROBIN HOOD LN",
    stop_lat: 35.1077,
    stop_lon: -89.940712,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2332,
    stop_code: "PARECHEN",
    stop_name: "PARK@ECHLES",
    stop_desc: "PARK AVE & ROBIN HOOD LN",
    stop_lat: 35.107635,
    stop_lon: -89.938677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2333,
    stop_code: "PARGOOEN",
    stop_name: "PARK@GOODMAN",
    stop_desc: "PARK AVE & GOODMAN ST",
    stop_lat: 35.107569,
    stop_lon: -89.9359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2334,
    stop_code: "PARKELEF",
    stop_name: "PARK@KELLEY RD",
    stop_desc: "PARK AVE & SHOTWELL ST",
    stop_lat: 35.107522,
    stop_lon: -89.933676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2335,
    stop_code: "PARSHOEN",
    stop_name: "PARK@SHOTWELL",
    stop_desc: "PARK AVE & SHOTWELL ST",
    stop_lat: 35.107475,
    stop_lon: -89.932061,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2336,
    stop_code: "PARLOEEF",
    stop_name: "PARK@LOEB",
    stop_desc: "PARK AVE & LOEB ST",
    stop_lat: 35.10741,
    stop_lon: -89.929805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2337,
    stop_code: "PARGO1EN",
    stop_name: "PARK@GOODLETT",
    stop_desc: "4067 PARK AVE & S GOODLETT ST",
    stop_lat: 35.107335,
    stop_lon: -89.926818,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2338,
    stop_code: "PARHIAEM",
    stop_name: "PARK AVE@HIAWATHA ST",
    stop_desc: "PARK AVE & BUFORD ELLINGTON DR",
    stop_lat: 35.107297,
    stop_lon: -89.924782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2339,
    stop_code: "PARMANEM",
    stop_name: "PARK@MANITO",
    stop_desc: "PARK AVE & MANITO ST",
    stop_lat: 35.107231,
    stop_lon: -89.921995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2340,
    stop_code: "PARAUDEN",
    stop_name: "PARK@AUDUBON",
    stop_desc: "PARK AVE & MINNEHAHA ST",
    stop_lat: 35.107184,
    stop_lon: -89.920357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2341,
    stop_code: "PARCHEEN",
    stop_name: "PARK@CHERRY",
    stop_desc: "PARK AVE & CHERRY RD",
    stop_lat: 35.107147,
    stop_lon: -89.919041,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2342,
    stop_code: "PARCHEEF",
    stop_name: "PARK@CHERRY",
    stop_desc: "PARK AVE & CHERRY RD",
    stop_lat: 35.107043,
    stop_lon: -89.915236,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2343,
    stop_code: "PARMEAEN",
    stop_name: "PARK@MEANDER",
    stop_desc: "PARK AVE & MEANDER DR",
    stop_lat: 35.106996,
    stop_lon: -89.913499,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2344,
    stop_code: "PARFAIEN",
    stop_name: "PARK@FAIRMEADOW",
    stop_desc: "PARK AVE & FAIRMEADOW RD",
    stop_lat: 35.106967,
    stop_lon: -89.911441,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2345,
    stop_code: "PARPEREN",
    stop_name: "PARK@PERKINS",
    stop_desc: "PARK AVE & FAIRMEADOW RD",
    stop_lat: 35.106911,
    stop_lon: -89.90966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2346,
    stop_code: "PARCROEM",
    stop_name: "PARK@CROSSOVER",
    stop_desc: "PARK AVE & CROSSOVER LN",
    stop_lat: 35.106798,
    stop_lon: -89.905534,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2347,
    stop_code: "PARCOLEN",
    stop_name: "PARK@COLONIAL",
    stop_desc: "PARK AVE & COLONIAL RD",
    stop_lat: 35.106741,
    stop_lon: -89.903399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2348,
    stop_code: "PARIVYEN",
    stop_name: "PARK@IVY",
    stop_desc: "PARK AVE & IVY RD",
    stop_lat: 35.106637,
    stop_lon: -89.89956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2349,
    stop_code: "PARMTMEM",
    stop_name: "PARK@MTMORIAH",
    stop_desc: "4965 PARK AVE & MOUNT MORIAH RD",
    stop_lat: 35.106562,
    stop_lon: -89.896839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2350,
    stop_code: "PARWILEM",
    stop_name: "PARK@WILBEC",
    stop_desc: "5059 PARK AVE & MOUNT MORIAH RD",
    stop_lat: 35.106467,
    stop_lon: -89.892912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2351,
    stop_code: "PARWHIEN",
    stop_name: "PARK@WHITE STATION",
    stop_desc: "PARK AVE & S WHITE STATION RD",
    stop_lat: 35.106213,
    stop_lon: -89.890822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2352,
    stop_code: "PARWHIEF",
    stop_name: "PARK@WHITE STATION",
    stop_desc: "PARK AVE & S WHITE STATION RD",
    stop_lat: 35.105783,
    stop_lon: -89.889683,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2353,
    stop_code: "PARESTEN",
    stop_name: "PARK@ESTATE",
    stop_desc: "PARK AVE & ESTATE DR",
    stop_lat: 35.103853,
    stop_lon: -89.884511,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2354,
    stop_code: "PARHAYEF",
    stop_name: "PARK@HAYNE",
    stop_desc: "PARK AVE & HAYNE RD",
    stop_lat: 35.102525,
    stop_lon: -89.880985,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2355,
    stop_code: "PARCREEN",
    stop_name: "PARK@WEST CRESTWOOD",
    stop_desc: "PARK AVE & W CRESTWOOD DR",
    stop_lat: 35.101376,
    stop_lon: -89.877869,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2356,
    stop_code: "PARECREN",
    stop_name: "PARK@EAST CRESTWOOD",
    stop_desc: "PARK AVE & E CRESTWOOD DR",
    stop_lat: 35.100748,
    stop_lon: -89.8762,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2357,
    stop_code: "PARBROEN",
    stop_name: "PARK@BROOKFIELD",
    stop_desc: "PARK AVE & BROOKFIELD RD",
    stop_lat: 35.100066,
    stop_lon: -89.874465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2358,
    stop_code: "PARLYNEM",
    stop_name: "PARK@LYNNFIELD",
    stop_desc: "5811 PARK AVE & LYNNFIELD RD",
    stop_lat: 35.099691,
    stop_lon: -89.869565,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2360,
    stop_code: "PARBRIEF",
    stop_name: "PARK@BRISTOL DR",
    stop_desc: "PARK AVE & BRISTOL DR",
    stop_lat: 35.099767,
    stop_lon: -89.866877,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2361,
    stop_code: "PARPRIEM",
    stop_name: "PARK@PRIMACY PKWY",
    stop_desc: "5969 PARK AVE & BRISTOL DR",
    stop_lat: 35.099835,
    stop_lon: -89.865118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2362,
    stop_code: "PARPRIEN",
    stop_name: "PARK@PRIMACY PKWY",
    stop_desc: "PARK AVE & BRISTOL DR",
    stop_lat: 35.099878,
    stop_lon: -89.863835,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2379,
    stop_code: "KNIKIREM",
    stop_name: "KNIGHT ARNOLD@KIRBY",
    stop_desc: "KNIGHT ARNOLD RD & KNIGHT TRAILS CIR",
    stop_lat: 35.060115,
    stop_lon: -89.848596,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2380,
    stop_code: "KNIKN1EM",
    stop_name: "KNIGHT ARNOLD@KNIGHT TRAILS",
    stop_desc: "KNIGHT ARNOLD RD & KNIGHT TRAILS CIR",
    stop_lat: 35.060112,
    stop_lon: -89.847048,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2381,
    stop_code: "KNIKIREN",
    stop_name: "KNIGHT ARNOLD RD@KIRBY RD",
    stop_desc: "KNIGHT ARNOLD RD & KIRBY PKWY",
    stop_lat: 35.060115,
    stop_lon: -89.844151,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2414,
    stop_code: "SHEAMAWF",
    stop_name: "SHELBY@AMANDA OAKS",
    stop_desc: "",
    stop_lat: 35.020502,
    stop_lon: -89.853069,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2415,
    stop_code: "SHECRUWM",
    stop_name: "SHELBY@CRUMP",
    stop_desc: "",
    stop_lat: 35.020487,
    stop_lon: -89.855574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2416,
    stop_code: "SHECR1WM",
    stop_name: "SHELBY@CRUMP",
    stop_desc: "",
    stop_lat: 35.020475,
    stop_lon: -89.857678,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2417,
    stop_code: "SHEHICWM",
    stop_name: "SHELBY@HICKORY HILL",
    stop_desc: "",
    stop_lat: 35.020472,
    stop_lon: -89.862265,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2419,
    stop_code: "SHEHICWF",
    stop_name: "SHELBY@HICKORY HILL",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020487,
    stop_lon: -89.869639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2420,
    stop_code: "SHEMENWN",
    stop_name: "SHELBY@MENDENHALL",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & S MENDENHALL RD",
    stop_lat: 35.020549,
    stop_lon: -89.879695,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2421,
    stop_code: "SHETUGWM",
    stop_name: "SHELBY@TUGGLE",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020572,
    stop_lon: -89.888789,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2422,
    stop_code: "SHETUGWF",
    stop_name: "SHELBY@TUGGLE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & STATE HWY 175",
    stop_lat: 35.020622,
    stop_lon: -89.891949,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2423,
    stop_code: "SHELAMWN",
    stop_name: "SHELBY@LAMAR",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020647,
    stop_lon: -89.897088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2424,
    stop_code: "SHELAMWF",
    stop_name: "SHELBY DR @ LAMAR AVE",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020723,
    stop_lon: -89.89985,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2425,
    stop_code: "SHEQUAWM",
    stop_name: "SHELBY@QUALITY",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020724,
    stop_lon: -89.907375,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2426,
    stop_code: "SHEQUAWN",
    stop_name: "SHELBY@QUALITY",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020747,
    stop_lon: -89.910945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2427,
    stop_code: "SHEMALWM",
    stop_name: "SHELBY@MALONE",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020753,
    stop_lon: -89.915144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2428,
    stop_code: "SHEMA1WM",
    stop_name: "SHELBY@MALONE",
    stop_desc: "",
    stop_lat: 35.020721,
    stop_lon: -89.91722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2429,
    stop_code: "SHEDELWM",
    stop_name: "SHELBY@DELP",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020788,
    stop_lon: -89.928592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2430,
    stop_code: "SHEDELWN",
    stop_name: "SHELBY@DELP",
    stop_desc: "STATE HWY 175 & DELP ST",
    stop_lat: 35.02079,
    stop_lon: -89.931012,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2431,
    stop_code: "SHEGETWN",
    stop_name: "SHELBY@GETWELL",
    stop_desc: "STATE HWY 175 & GETWELL RD",
    stop_lat: 35.020814,
    stop_lon: -89.935929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2481,
    stop_code: "SHECRUEM",
    stop_name: "SHELBY@CRUMP",
    stop_desc: "",
    stop_lat: 35.020232,
    stop_lon: -89.861325,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2482,
    stop_code: "SHESANEM",
    stop_name: "SHELBY@SANDY PARK",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & SANDY PARK DR",
    stop_lat: 35.020308,
    stop_lon: -89.85418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2484,
    stop_code: "SHEVESEF",
    stop_name: "SHELBY@VESCOVI",
    stop_desc: "",
    stop_lat: 35.020266,
    stop_lon: -89.843747,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2519,
    stop_code: "GETSHESF",
    stop_name: "GETWELL@SHELBY",
    stop_desc: "NEW GETWELL RD & FAULKNER RD",
    stop_lat: 35.019501,
    stop_lon: -89.936915,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2520,
    stop_code: "GETHOLSM",
    stop_name: "GETWELL@HOLMES",
    stop_desc: "STATE HWY 176 & GETWELL RD",
    stop_lat: 35.015309,
    stop_lon: -89.9369,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2522,
    stop_code: "HOLMEMEM",
    stop_name: "HOLMES@MEMPHIS OAKS",
    stop_desc: "E HOLMES RD & GETWELL RD",
    stop_lat: 35.006019,
    stop_lon: -89.933768,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2523,
    stop_code: "HOLMALEM",
    stop_name: "HOLMES@MALONE",
    stop_desc: "",
    stop_lat: 35.006063,
    stop_lon: -89.925106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2524,
    stop_code: "HOLMALEN",
    stop_name: "HOLMES@MALONE",
    stop_desc: "E HOLMES RD & MALONE RD",
    stop_lat: 35.006002,
    stop_lon: -89.919372,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2525,
    stop_code: "HOLCITEM",
    stop_name: "HOLMES@CITATION",
    stop_desc: "E HOLMES RD & PLEASANT HILL RD",
    stop_lat: 35.005962,
    stop_lon: -89.909826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2526,
    stop_code: "HOLPLEEN",
    stop_name: "HOLMES@PLEASANT HILL",
    stop_desc: "E HOLMES RD & PLEASANT HILL RD",
    stop_lat: 35.005907,
    stop_lon: -89.901805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2528,
    stop_code: "HOLLAMEF",
    stop_name: "HOLMES RD @ LAMAR AVE",
    stop_desc: "",
    stop_lat: 35.005901,
    stop_lon: -89.886018,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2530,
    stop_code: "HOLHICEF",
    stop_name: "HOLMES@HICKORY HILL",
    stop_desc: "",
    stop_lat: 35.005703,
    stop_lon: -89.863276,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2531,
    stop_code: "HOLDISEN",
    stop_name: "HOLMES@DISTRIPLEX FARM",
    stop_desc: "",
    stop_lat: 35.005698,
    stop_lon: -89.857993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2532,
    stop_code: "CRUHOLNF",
    stop_name: "CRUMPLER@HOLMES",
    stop_desc: "",
    stop_lat: 35.006514,
    stop_lon: -89.84852,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2533,
    stop_code: "CRUPINNF",
    stop_name: "CRUMPLER@PINE MEADOW",
    stop_desc: "",
    stop_lat: 35.014552,
    stop_lon: -89.848628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2534,
    stop_code: "CRUSHENN",
    stop_name: "CRUMPLER RD @ SHELBY DR",
    stop_desc: "",
    stop_lat: 35.020127,
    stop_lon: -89.848562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2539,
    stop_code: "RAIFRIWN",
    stop_name: "RAINES@FRIENDLY WAY",
    stop_desc: "E RAINES RD & FRIENDLY WAY",
    stop_lat: 35.035073,
    stop_lon: -89.849486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2540,
    stop_code: "RAIPARWN",
    stop_name: "RAINES@PARK FOREST",
    stop_desc: "E RAINES RD & PARK FOREST DR",
    stop_lat: 35.035097,
    stop_lon: -89.852403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2543,
    stop_code: "RAIHICWN",
    stop_name: "E RAINES RD @ HICKORY HILL RD",
    stop_desc: "5904 RAINES & HICKORY HILL",
    stop_lat: 35.034985,
    stop_lon: -89.866032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2549,
    stop_code: "TCHEARSM",
    stop_name: "TCHULAHOMA@EARLS COURT",
    stop_desc: "TCHULAHOMA RD & EARLS COURT RD",
    stop_lat: 35.046351,
    stop_lon: -89.95492,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2550,
    stop_code: "TCHKENSN",
    stop_name: "TCHULAHOMA@KENLAND",
    stop_desc: "TCHULAHOMA RD & KENLAND DR",
    stop_lat: 35.044313,
    stop_lon: -89.954934,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2551,
    stop_code: "CHRCHREN",
    stop_name: "CHRISTINE@CHRISTIANGARDEN",
    stop_desc: "CHRISTINE AVE & TCHULAHOMA RD",
    stop_lat: 35.04307,
    stop_lon: -89.953355,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2552,
    stop_code: "CHRPREEM",
    stop_name: "CHRISTINE RD@PRESCOTT RD",
    stop_desc: "SKY HARBOR CV & TCHULAHOMA RD",
    stop_lat: 35.043214,
    stop_lon: -89.945938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2553,
    stop_code: "CHRPREEN",
    stop_name: "CHRISTINE RD@PRESCOTT RD",
    stop_desc: "SKY HARBOR CV & PRESCOTT RD",
    stop_lat: 35.04323,
    stop_lon: -89.943849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2554,
    stop_code: "PRERAISN",
    stop_name: "PRESCOTT@RAINES",
    stop_desc: "PRESCOTT RD & E RAINES RD",
    stop_lat: 35.037197,
    stop_lon: -89.943726,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2555,
    stop_code: "PRESHESM",
    stop_name: "PRESCOTT@SHEMWELL",
    stop_desc: "PRESCOTT RD & E RAINES RD",
    stop_lat: 35.033872,
    stop_lon: -89.943776,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2556,
    stop_code: "PRESHESN",
    stop_name: "PRESCOTT@SHEMWELL",
    stop_desc: "PRESCOTT RD & SHEMWELL AVE",
    stop_lat: 35.032308,
    stop_lon: -89.943801,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2557,
    stop_code: "ELMWILSM",
    stop_name: "ELMRIDGE@WILLARD",
    stop_desc: "ELMRIDGE ST & UNNAMED ST",
    stop_lat: 35.030638,
    stop_lon: -89.945439,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2558,
    stop_code: "ELMARNSN",
    stop_name: "ELMRIDGE@ARNOLD",
    stop_desc: "ELMRIDGE ST & ELMRIDGE CV",
    stop_lat: 35.028234,
    stop_lon: -89.945498,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2569,
    stop_code: "TCHARNSF",
    stop_name: "TCHULAHOMA@ARNOLD",
    stop_desc: "",
    stop_lat: 35.027336,
    stop_lon: -89.954868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2570,
    stop_code: "TCHSHESN",
    stop_name: "TCHULAHOMA@SHELBY",
    stop_desc: "TCHULAHOMA RD",
    stop_lat: 35.021826,
    stop_lon: -89.954769,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2573,
    stop_code: "GETSHENF",
    stop_name: "GETWELL@SHELBY",
    stop_desc: "STATE HWY 176 & E SHELBY DR",
    stop_lat: 35.022111,
    stop_lon: -89.936778,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2574,
    stop_code: "GETDISNN",
    stop_name: "GETWELL@DISTRIPLEX",
    stop_desc: "STATE HWY 176 & DISTRIPLEX DR N",
    stop_lat: 35.023854,
    stop_lon: -89.936819,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2575,
    stop_code: "GETARNNN",
    stop_name: "GETWELL@ARNOLD",
    stop_desc: "STATE HWY 176 & ARNOLD RD",
    stop_lat: 35.02759,
    stop_lon: -89.936879,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2576,
    stop_code: "GETCRONN",
    stop_name: "GETWELL@CROWFARN",
    stop_desc: "GETWELL RD & ARNOLD RD",
    stop_lat: 35.029404,
    stop_lon: -89.936909,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2577,
    stop_code: "GETCRONF",
    stop_name: "GETWELL@CROWFARN",
    stop_desc: "STATE HWY 176 & CROWFARN AVE",
    stop_lat: 35.031103,
    stop_lon: -89.936939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2578,
    stop_code: "GETRA1NM",
    stop_name: "GETWELL RD @ RAINES RD",
    stop_desc: "STATE HWY 176 & GETWELL RD",
    stop_lat: 35.032997,
    stop_lon: -89.937002,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2604,
    stop_code: "AIRRAINF",
    stop_name: "AIR PARK ST @ RAINES RD",
    stop_desc: "AIR PARK ST & E RAINES RD",
    stop_lat: 35.037316,
    stop_lon: -89.931126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2605,
    stop_code: "AIRAIRNN",
    stop_name: "AIR PARK@AIR PARK CV",
    stop_desc: "AIR PARK ST & AIR PARK CV",
    stop_lat: 35.040185,
    stop_lon: -89.93111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2606,
    stop_code: "AIRPRENM",
    stop_name: "AIR PARK@PREMIER",
    stop_desc: "AIR PARK ST & PREMIER AVE",
    stop_lat: 35.041919,
    stop_lon: -89.931129,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2607,
    stop_code: "AIRPRENN",
    stop_name: "AIR PARK@PREMIER",
    stop_desc: "0 AIR PARK ST & AIR PARK COVE",
    stop_lat: 35.043984,
    stop_lon: -89.931158,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2608,
    stop_code: "AIRREGNN",
    stop_name: "AIR PARK@REGAL",
    stop_desc: "AIR PARK ST & REGAL BLVD",
    stop_lat: 35.050178,
    stop_lon: -89.931213,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2614,
    stop_code: "GETRAISF",
    stop_name: "GETWELL RD @ RAINES RD",
    stop_desc: "",
    stop_lat: 35.036274,
    stop_lon: -89.937209,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2615,
    stop_code: "GETCROSM",
    stop_name: "GETWELL@CROWFARN",
    stop_desc: "",
    stop_lat: 35.032966,
    stop_lon: -89.937213,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2616,
    stop_code: "GETCR1SM",
    stop_name: "GETWELL@CROWFARN",
    stop_desc: "",
    stop_lat: 35.031723,
    stop_lon: -89.937194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2617,
    stop_code: "GETCR2SM",
    stop_name: "GETWELL@CROWFARN",
    stop_desc: "",
    stop_lat: 35.030551,
    stop_lon: -89.937179,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2618,
    stop_code: "GETARNSM",
    stop_name: "GETWELL@ARNOLD",
    stop_desc: "",
    stop_lat: 35.028766,
    stop_lon: -89.937131,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2619,
    stop_code: "GETDISSM",
    stop_name: "GETWELL@DISTRIPLEX",
    stop_desc: "",
    stop_lat: 35.026271,
    stop_lon: -89.937098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2620,
    stop_code: "GETDISSF",
    stop_name: "GETWELL@DISTRIPLEX",
    stop_desc: "",
    stop_lat: 35.023806,
    stop_lon: -89.937069,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2621,
    stop_code: "GETSHESN",
    stop_name: "GETWELL@SHELBY",
    stop_desc: "",
    stop_lat: 35.021617,
    stop_lon: -89.937033,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2622,
    stop_code: "RAIGETEF",
    stop_name: "RAINES RD@GETWELL RD",
    stop_desc: "E RAINES RD & RAMP",
    stop_lat: 35.036687,
    stop_lon: -89.93536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2625,
    stop_code: "MTMKIRWF",
    stop_name: "MTMORIAH RD EX@KIRBY PARKWAY",
    stop_desc: "MOUNT MORIAH ROAD EXT & CHARLESTOWNE PL",
    stop_lat: 35.064371,
    stop_lon: -89.844703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2626,
    stop_code: "MTMKIRWM",
    stop_name: "MTMORIAH EXT@KIRBY DOWNS DR.",
    stop_desc: "MOUNT MORIAH ROAD EXT & KIRBY DOWNS DR",
    stop_lat: 35.067376,
    stop_lon: -89.850477,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2627,
    stop_code: "MTMCHAWN",
    stop_name: "MTMORIAH@CHARLESTOWN PL",
    stop_desc: "MOUNT MORIAH ROAD EXT & CHARLESTOWNE PL",
    stop_lat: 35.068821,
    stop_lon: -89.854088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2628,
    stop_code: "MTMRI1WN",
    stop_name: "MTMORIAH@RIDGEWAY",
    stop_desc: "MOUNT MORIAH ROAD EXT & RIDGEWAY RD",
    stop_lat: 35.069593,
    stop_lon: -89.856054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2629,
    stop_code: "MTMRI2WN",
    stop_name: "MTMORIAH EXT@RIDGEWAY RD",
    stop_desc: "MOUNT MORIAH ROAD EXT & RIDGEWAY RD",
    stop_lat: 35.071865,
    stop_lon: -89.861986,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2630,
    stop_code: "MTMMTMWF",
    stop_name: "MTMORIAH RD@MTMORIAH TERR",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.073005,
    stop_lon: -89.864814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2631,
    stop_code: "MTMSONWM",
    stop_name: "MTMORIAH RD@SONORA DR",
    stop_desc: "MT MORIAH RD & SONORA DR",
    stop_lat: 35.074448,
    stop_lon: -89.872274,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2632,
    stop_code: "MTMSONWF",
    stop_name: "MTMORIAH RD@SONORA DR",
    stop_desc: "MT MORIAH RD & SONORA DR",
    stop_lat: 35.074458,
    stop_lon: -89.872926,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2633,
    stop_code: "MTMCLAWF",
    stop_name: "MTMORIAH RD@CLARKE",
    stop_desc: "5622 MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.074517,
    stop_lon: -89.876089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2634,
    stop_code: "MTMEMEWF",
    stop_name: "MTMORIAH@EMERALD",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.074798,
    stop_lon: -89.878521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2635,
    stop_code: "MTMMENWM",
    stop_name: "MTMORIAH RD@MENDENHALL",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.076035,
    stop_lon: -89.881039,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2636,
    stop_code: "MTMMENNM",
    stop_name: "MTMORIAH@MENDENHALL",
    stop_desc: "MT MORIAH RD & MOUNT MORIAH RD",
    stop_lat: 35.077397,
    stop_lon: -89.883181,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2641,
    stop_code: "AMEPERWF",
    stop_name: "AMERICAN WAY@PERKINS RD",
    stop_desc: "4710 AMERICAN WAY & CLEARBROOK ST",
    stop_lat: 35.073974,
    stop_lon: -89.906887,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2642,
    stop_code: "AMWMALWF",
    stop_name: "AMERICAN WAY@MALL RD",
    stop_desc: "AMERICAN WAY & MALL OF MEMPHIS",
    stop_lat: 35.072959,
    stop_lon: -89.909665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2643,
    stop_code: "AMWCONWM",
    stop_name: "AMERICAN WAY@CONNECTING RD",
    stop_desc: "AMERICAN WAY & MALL OF MEMPHIS",
    stop_lat: 35.071953,
    stop_lon: -89.913139,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2644,
    stop_code: "AMECHEWN",
    stop_name: "AMERICAN WAY@CHERRY RD",
    stop_desc: "",
    stop_lat: 35.073632,
    stop_lon: -89.918587,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2645,
    stop_code: "AMECHEWF",
    stop_name: "AMERICAN WAY@CHERRY RD",
    stop_desc: "AMERICAN WAY & CHERRY CENTER DR",
    stop_lat: 35.074571,
    stop_lon: -89.919558,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2646,
    stop_code: "AMECOLWM",
    stop_name: "AMERICAN WAY@COLONY PARK DR",
    stop_desc: "AMERICAN WAY & CHERRY CENTER DR",
    stop_lat: 35.075511,
    stop_lon: -89.920751,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2647,
    stop_code: "AMECLAWN",
    stop_name: "AMERICAN WAY@CLAUDETTE",
    stop_desc: "AMERICAN WAY & CLAUDETTE RD",
    stop_lat: 35.076491,
    stop_lon: -89.925062,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2648,
    stop_code: "AMEGETWM",
    stop_name: "AMERICAN WAY@GETWELL RD",
    stop_desc: "AMERICAN WAY & CLAUDETTE RD",
    stop_lat: 35.076432,
    stop_lon: -89.928368,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2649,
    stop_code: "AMEGETWN",
    stop_name: "AMERICAN WAY@GETWELL RD",
    stop_desc: "AMERICAN WAY & AMERICAN WAY DR",
    stop_lat: 35.07648,
    stop_lon: -89.93069,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2651,
    stop_code: "AMELA1WM",
    stop_name: "AMERICAN WAY@LAMAR",
    stop_desc: "AMERICAN WAY & COSSITT PL",
    stop_lat: 35.076957,
    stop_lon: -89.941383,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2652,
    stop_code: "AMELAMWM",
    stop_name: "AMERICAN WAY@LAMAR AVE 78",
    stop_desc: "AMERICAN WAY & COSSITT PL",
    stop_lat: 35.077042,
    stop_lon: -89.946226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2654,
    stop_code: "AMETR1EM",
    stop_name: "AMERICAN WAY@TRANSIT CENTER",
    stop_desc: "AMERICAN WAY & COSSITT PL",
    stop_lat: 35.076881,
    stop_lon: -89.946006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2655,
    stop_code: "AMETRAEM",
    stop_name: "AMERICAN WAY@TRANSIT CENTER",
    stop_desc: "AMERICAN WAY & COSSITT PL",
    stop_lat: 35.076768,
    stop_lon: -89.940886,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2656,
    stop_code: "GETALLSN",
    stop_name: "GETWELL RD@ALLENBROOKE CV",
    stop_desc: "",
    stop_lat: 35.07506,
    stop_lon: -89.931743,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2657,
    stop_code: "GETARRSF",
    stop_name: "GETWELL RD@ARROWHEAD AVE",
    stop_desc: "0 GETWELL RD & ARROWHEAD RD",
    stop_lat: 35.071664,
    stop_lon: -89.93207,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2658,
    stop_code: "GETCOMSN",
    stop_name: "GETWELL RD@COMANCHE",
    stop_desc: "",
    stop_lat: 35.069269,
    stop_lon: -89.932163,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2659,
    stop_code: "GETKNISM",
    stop_name: "GETWELL RD@KNIGHT ARNOLD",
    stop_desc: "RAMP & UNNAMED ST",
    stop_lat: 35.066329,
    stop_lon: -89.932588,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2660,
    stop_code: "GETKNISN",
    stop_name: "GETWELL RD@KNIGHT ARNOLD",
    stop_desc: "RAMP & KNIGHT ARNOLD RD",
    stop_lat: 35.065069,
    stop_lon: -89.93279,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2661,
    stop_code: "GETWHISM",
    stop_name: "GETWELL RD@WHITEBROOK",
    stop_desc: "",
    stop_lat: 35.06171,
    stop_lon: -89.931405,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2662,
    stop_code: "GETWHISF",
    stop_name: "GETWELL RD@WHITEBROOK",
    stop_desc: "",
    stop_lat: 35.059494,
    stop_lon: -89.93127,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2663,
    stop_code: "GETWHINM",
    stop_name: "GETWELL RD@WHITEBROOK",
    stop_desc: "",
    stop_lat: 35.059624,
    stop_lon: -89.930927,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2664,
    stop_code: "GETKENNM",
    stop_name: "GETWELL@KENOSHA",
    stop_desc: "RAMP & LAMAR AVE",
    stop_lat: 35.066829,
    stop_lon: -89.932024,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2666,
    stop_code: "GETARRNF",
    stop_name: "GETWELL@ARROWHEAD",
    stop_desc: "STATE HWY 176 & ARROWHEAD AVE",
    stop_lat: 35.072182,
    stop_lon: -89.931837,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2667,
    stop_code: "GETALLNM",
    stop_name: "GETWELL RD@ALLENBROOKE",
    stop_desc: "GETWELL RD & ALLENBROOKE CV",
    stop_lat: 35.073514,
    stop_lon: -89.931702,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2668,
    stop_code: "AMEGETEF",
    stop_name: "AMERICAN WAY@GETWELL",
    stop_desc: "AMERICAN WAY & AMERICAN WAY DR",
    stop_lat: 35.076319,
    stop_lon: -89.930691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2669,
    stop_code: "AMEAMWEF",
    stop_name: "AMERICAN WAY@AMERICAN WAY DR",
    stop_desc: "",
    stop_lat: 35.076119,
    stop_lon: -89.927561,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2670,
    stop_code: "AMECLAEN",
    stop_name: "AMERICAN WAY@CLAUDETTE",
    stop_desc: "AMERICAN WAY & AMERICAN WAY DR",
    stop_lat: 35.076072,
    stop_lon: -89.926113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2671,
    stop_code: "AMECOLEN",
    stop_name: "AMERICAN WAY@COLONY PARK",
    stop_desc: "AMERICAN WAY & COLONY PARK DR",
    stop_lat: 35.076006,
    stop_lon: -89.922851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2672,
    stop_code: "AMECHEEN",
    stop_name: "AMERICAN WAY@CHERRY",
    stop_desc: "AMERICAN WAY & CHERRY RD",
    stop_lat: 35.07399,
    stop_lon: -89.919471,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2673,
    stop_code: "AMECHEEF",
    stop_name: "AMERICAN WAY@CHERRY",
    stop_desc: "0 OF MEMPHIS",
    stop_lat: 35.071904,
    stop_lon: -89.916324,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2674,
    stop_code: "AMEMALEF",
    stop_name: "AMERICAN WAY@MALL RD",
    stop_desc: "AMERICAN WAY & CLAUDETTE RD",
    stop_lat: 35.073341,
    stop_lon: -89.908016,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2675,
    stop_code: "AMEPEREN",
    stop_name: "AMERICAN WAY@S PERKINS",
    stop_desc: "4701 AMERICAN WAY & S PERKINS RD",
    stop_lat: 35.07375,
    stop_lon: -89.906412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2681,
    stop_code: "MTMSCOSM",
    stop_name: "MT MORIAH RD@SCOTTSWAY AVE",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.079826,
    stop_lon: -89.8874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2693,
    stop_code: "MENFOXSN",
    stop_name: "MENDENHALL@FOX PLAZA",
    stop_desc: "S MENDENHALL RD & FOX PLAZA DR",
    stop_lat: 35.074799,
    stop_lon: -89.884868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2694,
    stop_code: "MENCOTSM",
    stop_name: "MENDENHALL@COTTONWOOD",
    stop_desc: "S MENDENHALL RD & MEADOWLAKE DR W",
    stop_lat: 35.072262,
    stop_lon: -89.885438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2695,
    stop_code: "MENCO1SM",
    stop_name: "MENDENHALL@COOTONWOOD",
    stop_desc: "S MENDENHALL RD & MEADOWLAKE DR W",
    stop_lat: 35.070519,
    stop_lon: -89.88552,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2696,
    stop_code: "MENCOTSN",
    stop_name: "MENDENHALL RD @ COTTONWOOD RD",
    stop_desc: "S MENDENHALL RD & COTTONWOOD RD",
    stop_lat: 35.06917,
    stop_lon: -89.885568,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2701,
    stop_code: "GOOARRSM",
    stop_name: "GOODLETT ST@ARROWOOD",
    stop_desc: "S GOODLETT ST & ARROWOOD AVE",
    stop_lat: 35.066092,
    stop_lon: -89.921477,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2702,
    stop_code: "GOOKNISN",
    stop_name: "GOODLETT ST@KNIGHT ARNOLD R",
    stop_desc: "GOODLETT RD & KNIGHT ARNOLD RD",
    stop_lat: 35.064859,
    stop_lon: -89.921545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2703,
    stop_code: "GOOSHESN",
    stop_name: "GOODLETT@SHEFFIELD",
    stop_desc: "S GOODLETT ST & GOODLETT RD",
    stop_lat: 35.063313,
    stop_lon: -89.92157,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2704,
    stop_code: "GOOCAMSF",
    stop_name: "GOODLETT@CAMELOT",
    stop_desc: "GOODLETT RD & S GOODLETT ST",
    stop_lat: 35.061902,
    stop_lon: -89.922214,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2705,
    stop_code: "GOOFORSN",
    stop_name: "GOODLETT@FOREST VIEW",
    stop_desc: "GOODLETT RD & FOREST VIEW DR",
    stop_lat: 35.059569,
    stop_lon: -89.922329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2706,
    stop_code: "GOOCRESN",
    stop_name: "GOODLETT@CREIGHTON",
    stop_desc: "S GOODLETT ST & CREIGHTON AVE",
    stop_lat: 35.057719,
    stop_lon: -89.922388,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2707,
    stop_code: "GOODEVSN",
    stop_name: "GOODLETT@DEVEL",
    stop_desc: "S GOODLETT ST & GOODLETT RD",
    stop_lat: 35.056575,
    stop_lon: -89.922402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2708,
    stop_code: "GOOWINSF",
    stop_name: "GOODLETT ST@WINGOOD",
    stop_desc: "GOODLETT RD & WINGOOD CIR",
    stop_lat: 35.051927,
    stop_lon: -89.922068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2709,
    stop_code: "GOOWINNF",
    stop_name: "GOODLETT ST@WINCHESTER",
    stop_desc: "GOODLETT RD & WINGOOD CIR",
    stop_lat: 35.052177,
    stop_lon: -89.921857,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2710,
    stop_code: "GOOWINNN",
    stop_name: "GOODLETT ST@WINDY HOLLOW",
    stop_desc: "GOODLETT RD & WINDY HOLLOW CIR",
    stop_lat: 35.053982,
    stop_lon: -89.921787,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2711,
    stop_code: "GOOCHUNN",
    stop_name: "GOODLETT ST@CHUCK AVE",
    stop_desc: "GOODLETT RD & CHUCK AVE",
    stop_lat: 35.056986,
    stop_lon: -89.922213,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2712,
    stop_code: "GOOFORNN",
    stop_name: "GOODLETT ST@FOREST VIEW DR",
    stop_desc: "GOODLETT RD & FOREST VIEW DR",
    stop_lat: 35.059229,
    stop_lon: -89.922142,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2713,
    stop_code: "GOOKNINN",
    stop_name: "GOODLETT@KNIGHT ARNOLD R",
    stop_desc: "GOODLETT RD & KNIGHT ARNOLD RD",
    stop_lat: 35.064483,
    stop_lon: -89.921358,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2714,
    stop_code: "GOOKNINF",
    stop_name: "GOODLETT ST@KNIGHT ARNOLD",
    stop_desc: "GOODLETT RD & KNIGHT ARNOLD RD",
    stop_lat: 35.065082,
    stop_lon: -89.921335,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2715,
    stop_code: "GOOALMNM",
    stop_name: "GOODLETT ST@ALMO",
    stop_desc: "GOODLETT RD & S GOODLETT ST",
    stop_lat: 35.067004,
    stop_lon: -89.921221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2716,
    stop_code: "GOOCOTNN",
    stop_name: "GOODLETT ST@COTTONWOOD",
    stop_desc: "GOODLETT RD & COTTONWOOD RD",
    stop_lat: 35.069425,
    stop_lon: -89.921127,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2719,
    stop_code: "CHECHESN",
    stop_name: "CHERRY ST@CHERRY CENTER",
    stop_desc: "CHERRY RD & CHERRY CENTER DR",
    stop_lat: 35.073384,
    stop_lon: -89.920202,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2720,
    stop_code: "GOOCOTSN",
    stop_name: "GOODLETT ST@COTTONWOOD",
    stop_desc: "GOODLETT RD & COTTONWOOD RD",
    stop_lat: 35.069864,
    stop_lon: -89.921315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2721,
    stop_code: "COTCHIWN",
    stop_name: "COTTONWOOD RD@CHIPPEWA RD",
    stop_desc: "COTTONWOOD RD & GOODLETT RD",
    stop_lat: 35.069605,
    stop_lon: -89.921846,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2722,
    stop_code: "COTCLAWN",
    stop_name: "COTTONWOOD RD@CLAUDETTE",
    stop_desc: "COTTONWOOD RD & CLAUDETTE RD",
    stop_lat: 35.069091,
    stop_lon: -89.925197,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2723,
    stop_code: "COMGETWN",
    stop_name: "COMANCHE RD@GETWELL RD",
    stop_desc: "COMANCHE RD & CLAUDETTE RD",
    stop_lat: 35.069233,
    stop_lon: -89.931975,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2724,
    stop_code: "COMWATEM",
    stop_name: "COMANCHE@WATSON",
    stop_desc: "",
    stop_lat: 35.069081,
    stop_lon: -89.931096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2725,
    stop_code: "COTCLAEN",
    stop_name: "COTTONWOOD RD@CLAUDETTE",
    stop_desc: "COTTONWOOD RD & CLAUDETTE RD",
    stop_lat: 35.068939,
    stop_lon: -89.925507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2726,
    stop_code: "COTGOOEN",
    stop_name: "COTTONWOOD@GOODLETT ST",
    stop_desc: "COTTONWOOD RD & GOODLETT RD",
    stop_lat: 35.069462,
    stop_lon: -89.921702,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2732,
    stop_code: "KNIARRWM",
    stop_name: "KNIGHT ARNOLD R@ARRENDALE ST",
    stop_desc: "KNIGHT ARNOLD RD & S PERKINS RD",
    stop_lat: 35.062544,
    stop_lon: -89.907851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2733,
    stop_code: "KNIKNIWF",
    stop_name: "KNIGHT ARNOLD@KNIGHT",
    stop_desc: "KNIGHT ARNOLD RD & KNIGHT RD",
    stop_lat: 35.062683,
    stop_lon: -89.911389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2735,
    stop_code: "KNIDANWN",
    stop_name: "KNIGHT ARNOLD@DANVILLE RD",
    stop_desc: "KNIGHT ARNOLD RD & DANVILLE CIR E",
    stop_lat: 35.062716,
    stop_lon: -89.915833,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2736,
    stop_code: "KNIRANWF",
    stop_name: "KNIGHT ARNOLD@RANDY LN",
    stop_desc: "KNIGHT ARNOLD RD & DANVILLE CIR E",
    stop_lat: 35.064589,
    stop_lon: -89.919655,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2739,
    stop_code: "KNIOLDWN",
    stop_name: "KNIGHT ARNOLD@OLD GETWELL RD",
    stop_desc: "KNIGHT ARNOLD RD & OLD GETWELL RD",
    stop_lat: 35.065052,
    stop_lon: -89.933453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2740,
    stop_code: "KNIJULWF",
    stop_name: "KNIGHT ARNOLD R@JULIUS LEWIS",
    stop_desc: "KNIGHT ARNOLD RD & JULIUS LEWIS DR",
    stop_lat: 35.065219,
    stop_lon: -89.938992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2746,
    stop_code: "DEMTCHWF",
    stop_name: "DEMOCRAT RD@TCHULAHOMA RD",
    stop_desc: "DEMOCRAT RD & UNNAMED ST",
    stop_lat: 35.06891,
    stop_lon: -89.955893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2747,
    stop_code: "DEMLAMWM",
    stop_name: "DEMOCRAT@LAMB PLACE",
    stop_desc: "DEMOCRAT RD & UNNAMED ST",
    stop_lat: 35.069013,
    stop_lon: -89.960514,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2748,
    stop_code: "DEMREPWN",
    stop_name: "FED EX",
    stop_desc: "DEMOCRAT RD & UNNAMED ST",
    stop_lat: 35.06917,
    stop_lon: -89.966673,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2749,
    stop_code: "DEMREPWF",
    stop_name: "DEMOCRAT@REPUBLICAN",
    stop_desc: "DEMOCRAT RD & REPUBLICAN DR",
    stop_lat: 35.06916,
    stop_lon: -89.964937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2750,
    stop_code: "DEMSOUWN",
    stop_name: "DEMOCRAT@SOUTHWIDE",
    stop_desc: "DEMOCRAT RD & UNNAMED ST",
    stop_lat: 35.069197,
    stop_lon: -89.966231,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2751,
    stop_code: "DEMPORWN",
    stop_name: "DEMOCRAT@PORTIA",
    stop_desc: "DEMOCRAT RD & PORTIA ST",
    stop_lat: 35.069182,
    stop_lon: -89.969791,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2752,
    stop_code: "DEMRENEN",
    stop_name: "DEMOCRAT@RENTAL RD",
    stop_desc: "DEMOCRAT RD & RENTAL RD",
    stop_lat: 35.068935,
    stop_lon: -89.973485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2753,
    stop_code: "DEMAIRWM",
    stop_name: "DEMOCRAT@AIRWAYS",
    stop_desc: "DEMOCRAT RD & RENTAL RD",
    stop_lat: 35.068992,
    stop_lon: -89.977719,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2754,
    stop_code: "DEMAI1WM",
    stop_name: "DEMOCRAT@AIRWAYS",
    stop_desc: "DEMOCRAT RD & RENTAL RD",
    stop_lat: 35.068905,
    stop_lon: -89.981556,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2758,
    stop_code: "NONPARWM",
    stop_name: "NONCONNAH@PARAGON",
    stop_desc: "",
    stop_lat: 35.072544,
    stop_lon: -89.995806,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2760,
    stop_code: "NONDIVWM",
    stop_name: "NONCONNAH@DIVIDEND",
    stop_desc: "NONCONNAH BLVD & LOCHEARN RD",
    stop_lat: 35.071735,
    stop_lon: -90.004045,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2766,
    stop_code: "CORNONSN",
    stop_name: "CORPORATE@NONCONNAH",
    stop_desc: "E CORPORATE AVE & NONCONNAH BLVD",
    stop_lat: 35.073498,
    stop_lon: -89.992056,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2768,
    stop_code: "NONDE1SM",
    stop_name: "NONCONNAH@DEMOCRAT RD",
    stop_desc: "NONCONNAH BLVD & LOCHEARN RD",
    stop_lat: 35.069761,
    stop_lon: -89.990213,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2770,
    stop_code: "DEMAIREF",
    stop_name: "DEMOCRAT RD@AIRWAYS BLVD",
    stop_desc: "",
    stop_lat: 35.068641,
    stop_lon: -89.986743,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2771,
    stop_code: "DEMRENEM",
    stop_name: "DEMOCRAT RD@RENTAL RD",
    stop_desc: "",
    stop_lat: 35.068762,
    stop_lon: -89.980716,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2772,
    stop_code: "DEMRENEF",
    stop_name: "DEMOCRAT RD@RENTAL RD",
    stop_desc: "",
    stop_lat: 35.068952,
    stop_lon: -89.972611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2773,
    stop_code: "DEMREPEM",
    stop_name: "DEMOCRAT RD@REPUBLICAN",
    stop_desc: "DEMOCRAT RD & PORTIA ST",
    stop_lat: 35.069021,
    stop_lon: -89.969415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2774,
    stop_code: "DEMREPEF",
    stop_name: "FED EX",
    stop_desc: "UNNAMED ST & DEMOCRAT RD",
    stop_lat: 35.06901,
    stop_lon: -89.966673,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2775,
    stop_code: "DEMTCHEM",
    stop_name: "DEMOCRAT RD@TCHULAHOMA RD",
    stop_desc: "DEMOCRAT RD & UNNAMED ST",
    stop_lat: 35.068852,
    stop_lon: -89.960437,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2776,
    stop_code: "DEMTC1EM",
    stop_name: "DEMOCRAT RD@TCHULAHOMA RD",
    stop_desc: "DEMOCRAT RD & RAMP",
    stop_lat: 35.068777,
    stop_lon: -89.956943,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2783,
    stop_code: "KNIJULEM",
    stop_name: "KNIGHT ARNOLD@JULIUS LEWIS",
    stop_desc: "? KNIGHT ARNOLD RD & JULIUS LEWIS DR",
    stop_lat: 35.065086,
    stop_lon: -89.939523,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2784,
    stop_code: "KNIOLDEM",
    stop_name: "KNIGHT ARNOLD@OLD GETWELL RD",
    stop_desc: "KNIGHT ARNOLD RD & OLD GETWELL RD",
    stop_lat: 35.064994,
    stop_lon: -89.937522,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2785,
    stop_code: "KNIOLDEN",
    stop_name: "KNIGHT ARNOLD@OLD GETWELL RD",
    stop_desc: "KNIGHT ARNOLD RD & OLD GETWELL RD",
    stop_lat: 35.06491,
    stop_lon: -89.933973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2786,
    stop_code: "KNIGOOEF",
    stop_name: "KNIGHT ARNOLD RD@GOODLETT ST",
    stop_desc: "KNIGHT ARNOLD RD & GOODLETT RD",
    stop_lat: 35.064635,
    stop_lon: -89.921115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2789,
    stop_code: "KNIPEREM",
    stop_name: "KNIGHT ARNOLD@PERKINS RD",
    stop_desc: "KNIGHT ARNOLD RD & RAMP",
    stop_lat: 35.062365,
    stop_lon: -89.907486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2790,
    stop_code: "MTMAMENF",
    stop_name: "MT MORIAH@AMERICAN WAY",
    stop_desc: "MT MORIAH RD & MOUNT MORIAH RD",
    stop_lat: 35.0809,
    stop_lon: -89.888835,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2791,
    stop_code: "MTMWHINF",
    stop_name: "MT MORIAH@WHITE STATION",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.086274,
    stop_lon: -89.896078,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2792,
    stop_code: "MTMHELNN",
    stop_name: "MT MORIAH@HELENE",
    stop_desc: "MOUNT MORIAH RD & HELENE RD",
    stop_lat: 35.087873,
    stop_lon: -89.896019,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2793,
    stop_code: "MTMQUINN",
    stop_name: "MT MORIAH@QUINCE RD",
    stop_desc: "MT MORIAH RD & HELENE RD",
    stop_lat: 35.091001,
    stop_lon: -89.895912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2794,
    stop_code: "QUIMTMWF",
    stop_name: "QUINCE RD@MT MORIAH",
    stop_desc: "QUINCE RD & MOUNT MORIAH RD",
    stop_lat: 35.091297,
    stop_lon: -89.896431,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2795,
    stop_code: "QUICOLWF",
    stop_name: "QUINCE RD@COLONIAL",
    stop_desc: "QUINCE RD & COLONIAL RD",
    stop_lat: 35.09146,
    stop_lon: -89.903852,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2796,
    stop_code: "PERDUNSN",
    stop_name: "PERKINS@DUNN AVE",
    stop_desc: "S PERKINS RD & DUNN AVE",
    stop_lat: 35.090535,
    stop_lon: -89.907095,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2797,
    stop_code: "PERHELSN",
    stop_name: "PERKINS@HELENE",
    stop_desc: "S PERKINS RD & HELENE RD",
    stop_lat: 35.088667,
    stop_lon: -89.907176,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2798,
    stop_code: "PERLEASF",
    stop_name: "PERKINS@LEATHERWOOD",
    stop_desc: "S PERKINS RD & LEATHERWOOD AVE",
    stop_lat: 35.086299,
    stop_lon: -89.907226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2799,
    stop_code: "WILCORWN",
    stop_name: "WILLOW RD@CORNELIA LN",
    stop_desc: "",
    stop_lat: 35.085826,
    stop_lon: -89.908023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2800,
    stop_code: "WILSEAWF",
    stop_name: "WILLOW RD@SEA ISLE",
    stop_desc: "WILLOW RD & S SEA ISLE RD",
    stop_lat: 35.085884,
    stop_lon: -89.911341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2801,
    stop_code: "WILDEAWF",
    stop_name: "WILLOW RD@DEARING RD",
    stop_desc: "",
    stop_lat: 35.085923,
    stop_lon: -89.913896,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2802,
    stop_code: "WILCHEWN",
    stop_name: "WILLOW RD@CHERRY",
    stop_desc: "WILLOW RD & CHERRY RD",
    stop_lat: 35.085963,
    stop_lon: -89.916627,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2803,
    stop_code: "WILMAXWN",
    stop_name: "WILLOW RD@MAXINE",
    stop_desc: "WILLOW RD & MAXINE ST",
    stop_lat: 35.086001,
    stop_lon: -89.918684,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2804,
    stop_code: "WILSGOWF",
    stop_name: "WILLOW RD@S GOODLETT RD",
    stop_desc: "NEW WILLOW RD & MAXINE ST",
    stop_lat: 35.086272,
    stop_lon: -89.921261,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2805,
    stop_code: "WILWATWF",
    stop_name: "WILLOW RD@WATSON",
    stop_desc: "NEW WILLOW RD & WATSON RD",
    stop_lat: 35.086518,
    stop_lon: -89.924313,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2806,
    stop_code: "WILGETWM",
    stop_name: "WILLOW RD@GETWELL RD",
    stop_desc: "NEW WILLOW RD & WATSON RD",
    stop_lat: 35.086754,
    stop_lon: -89.927652,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2807,
    stop_code: "NEWGETWN",
    stop_name: "NEW WILLOW RD@GETWELL RD",
    stop_desc: "NEW WILLOW RD & ELLISTON RD",
    stop_lat: 35.08682,
    stop_lon: -89.930727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2808,
    stop_code: "GETELLSM",
    stop_name: "GETWELL RD@ELLISTON",
    stop_desc: "GETWELL RD & ELLISTON RD",
    stop_lat: 35.085489,
    stop_lon: -89.931304,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2809,
    stop_code: "GETMALSM",
    stop_name: "GETWELL RD@MALLORY",
    stop_desc: "GETWELL RD & ELLISTON RD",
    stop_lat: 35.083988,
    stop_lon: -89.931329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2810,
    stop_code: "GETMALNF",
    stop_name: "GETWELL RD@MALLORY",
    stop_desc: "GETWELL RD & ELLISTON RD",
    stop_lat: 35.083836,
    stop_lon: -89.93113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2811,
    stop_code: "GETELLNN",
    stop_name: "GETWELL RD@ELLISTON RD",
    stop_desc: "",
    stop_lat: 35.084819,
    stop_lon: -89.931118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2812,
    stop_code: "WILWATEM",
    stop_name: "NEW WILLOW RD@WATSON RD",
    stop_desc: "",
    stop_lat: 35.086639,
    stop_lon: -89.930082,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2813,
    stop_code: "NEWWILEF",
    stop_name: "NEW WILLOW@WILLLOW CV",
    stop_desc: "NEW WILLOW RD & WILLOW CV",
    stop_lat: 35.086612,
    stop_lon: -89.928792,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2814,
    stop_code: "NEWGOOEN",
    stop_name: "NEW WILLOW@GOODLETT RD",
    stop_desc: "NEW WILLOW RD & GOODLETT RD",
    stop_lat: 35.085968,
    stop_lon: -89.920852,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2815,
    stop_code: "WILMAXEF",
    stop_name: "WILLOW RD@MAXINE",
    stop_desc: "WILLOW RD & MAXINE ST",
    stop_lat: 35.085831,
    stop_lon: -89.918253,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2816,
    stop_code: "WILCHEEF",
    stop_name: "WILLOW RD@CHERRY",
    stop_desc: "WILLOW RD & CHERRY RD",
    stop_lat: 35.085801,
    stop_lon: -89.916229,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2817,
    stop_code: "WILDEAEN",
    stop_name: "WILLOW RD@DEARING",
    stop_desc: "WILLOW RD & DEARING RD",
    stop_lat: 35.085762,
    stop_lon: -89.91373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2818,
    stop_code: "WILSEAEM",
    stop_name: "WILLOW RD@SEA ISLE RD",
    stop_desc: "WILLOW RD & S SEA ISLE RD",
    stop_lat: 35.085723,
    stop_lon: -89.91143,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2819,
    stop_code: "WILCOREN",
    stop_name: "WILLOW RD@CORNELIA LN",
    stop_desc: "WILLOW RD & DORRIE LN",
    stop_lat: 35.085675,
    stop_lon: -89.908366,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2820,
    stop_code: "PERDEANN",
    stop_name: "PERKINS@DEARING RD E",
    stop_desc: "S PERKINS RD & DEARING RD E",
    stop_lat: 35.087192,
    stop_lon: -89.907014,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2821,
    stop_code: "PERDEANF",
    stop_name: "PERKINS@DEARING RD",
    stop_desc: "S PERKINS RD & DEARING RD E",
    stop_lat: 35.087844,
    stop_lon: -89.907001,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2822,
    stop_code: "PERBOYNN",
    stop_name: "PERKINS@BOYCE RD",
    stop_desc: "S PERKINS RD & BOYCE AVE",
    stop_lat: 35.089292,
    stop_lon: -89.906943,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2823,
    stop_code: "PERQUINN",
    stop_name: "PERKINS@QUINCE RD",
    stop_desc: "S PERKINS RD & DUNN AVE",
    stop_lat: 35.091079,
    stop_lon: -89.906873,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2825,
    stop_code: "QUICOLEN",
    stop_name: "QUINCE RD@COLONIAL RD",
    stop_desc: "",
    stop_lat: 35.091298,
    stop_lon: -89.903665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2826,
    stop_code: "QUIHOPEN",
    stop_name: "QUINCE RD@HOPEWELL RD",
    stop_desc: "",
    stop_lat: 35.091251,
    stop_lon: -89.901796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2827,
    stop_code: "QUIIVYEN",
    stop_name: "QUINCE RD@IVY RD",
    stop_desc: "",
    stop_lat: 35.091185,
    stop_lon: -89.899362,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2828,
    stop_code: "QUIDELEN",
    stop_name: "QUINCE RD@DELMONT RD",
    stop_desc: "",
    stop_lat: 35.091155,
    stop_lon: -89.897239,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2829,
    stop_code: "MTMHELSN",
    stop_name: "MT MORIAH@HELENE RD",
    stop_desc: "MT MORIAH RD & HELENE RD",
    stop_lat: 35.088151,
    stop_lon: -89.896206,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2830,
    stop_code: "MTMWILSN",
    stop_name: "MT MORIAH RD@WILLOW RD",
    stop_desc: "MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.086229,
    stop_lon: -89.896277,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2831,
    stop_code: "MTMAMESN",
    stop_name: "MT MORIAH@AMERICAN WAY",
    stop_desc: "2497 MOUNT MORIAH RD & MT MORIAH RD",
    stop_lat: 35.080847,
    stop_lon: -89.889089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2889,
    stop_code: "WALMENEF",
    stop_name: "WALNUT GROVE@MENDENHALL",
    stop_desc: "WALNUT GROVE RD & STATE HWY 23",
    stop_lat: 35.130612,
    stop_lon: -89.89767,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2891,
    stop_code: "WALLAVEN",
    stop_name: "WALNUT GROVE@LAVERNE",
    stop_desc: "STATE HWY 23 & LAVERNE LN",
    stop_lat: 35.13045,
    stop_lon: -89.890633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2892,
    stop_code: "WALWHIEN",
    stop_name: "WALNUT GROVE@WHITE STATION",
    stop_desc: "STATE HWY 23 & S WHITE STATION RD",
    stop_lat: 35.130383,
    stop_lon: -89.887601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2893,
    stop_code: "WALWHIEF",
    stop_name: "WALNUT GROVE@WHITE STATION",
    stop_desc: "",
    stop_lat: 35.130388,
    stop_lon: -89.886643,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2894,
    stop_code: "WALMCEEN",
    stop_name: "WALNUT GROVE@MCELROY",
    stop_desc: "",
    stop_lat: 35.131058,
    stop_lon: -89.88286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2895,
    stop_code: "WALOAKEN",
    stop_name: "WALNUT GROVE@OAK GROVE RD",
    stop_desc: "",
    stop_lat: 35.131077,
    stop_lon: -89.879656,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2897,
    stop_code: "WALEYAEF",
    stop_name: "WALNUT GROVE@E YATES",
    stop_desc: "5731 STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.130974,
    stop_lon: -89.871631,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2898,
    stop_code: "WALBAPEN",
    stop_name: "WALNUT GROVE@BAPTIST HOSPITA",
    stop_desc: "? WALNUT GROVE RD & WALNUT GROVE RD",
    stop_lat: 35.130723,
    stop_lon: -89.861185,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2899,
    stop_code: "HUMHUMSM",
    stop_name: "HUMPHREYS@HUMPHREYS CENTER",
    stop_desc: "HUMPHREYS BLVD & HUMPHREYS CTR",
    stop_lat: 35.127376,
    stop_lon: -89.85357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2900,
    stop_code: "HUMESHSN",
    stop_name: "HUMPHREYS@E SHADY GROVE",
    stop_desc: "HUMPHREYS BLVD & E SHADY GROVE RD",
    stop_lat: 35.123444,
    stop_lon: -89.848944,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2932,
    stop_code: "WALOAKWN",
    stop_name: "WALNUT GROVE@OAK GROVE ST",
    stop_desc: "",
    stop_lat: 35.131228,
    stop_lon: -89.878922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2933,
    stop_code: "WALMCEWN",
    stop_name: "WALNUT GROVE@MCELROY",
    stop_desc: "",
    stop_lat: 35.13118,
    stop_lon: -89.882343,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2934,
    stop_code: "WALWHIWF",
    stop_name: "WALNUT GROVE@WHITE STATION",
    stop_desc: "STATE HWY 23 & N WHITE STATION RD",
    stop_lat: 35.130545,
    stop_lon: -89.888154,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2935,
    stop_code: "WALSHEWN",
    stop_name: "WALNUT GROVE@SHEPHERD LN",
    stop_desc: "STATE HWY 23 & GROVE CREEK PL",
    stop_lat: 35.13063,
    stop_lon: -89.891562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2936,
    stop_code: "WALNFEWN",
    stop_name: "WALNUT GROVE@N FERNWAY",
    stop_desc: "WALNUT GROVE RD & N FERNWAY DR",
    stop_lat: 35.130698,
    stop_lon: -89.895224,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2963,
    stop_code: "YATSYCNN",
    stop_name: "YATES RD@SYCAMORE GROVE",
    stop_desc: "S YATES RD & SYCAMORE GROVE LN",
    stop_lat: 35.125118,
    stop_lon: -89.875541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2965,
    stop_code: "ESHRIVWF",
    stop_name: "ESHADY GROVE RD@RIVER VIEW",
    stop_desc: "",
    stop_lat: 35.122476,
    stop_lon: -89.852156,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2966,
    stop_code: "ESHRIPWN",
    stop_name: "ESHADYGROVE@RIPPLEBROOK",
    stop_desc: "",
    stop_lat: 35.121963,
    stop_lon: -89.854848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2968,
    stop_code: "ESHSWEWN",
    stop_name: "ESHADY GROVE RD@SWEETBRIER RD",
    stop_desc: "",
    stop_lat: 35.122441,
    stop_lon: -89.862336,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2969,
    stop_code: "ESHBRIWF",
    stop_name: "ESHADY GROVE RD@BRIERVIEW",
    stop_desc: "",
    stop_lat: 35.123348,
    stop_lon: -89.864901,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2970,
    stop_code: "SHARANWM",
    stop_name: "SHADY GROVE RD@RANSOM LN",
    stop_desc: "",
    stop_lat: 35.123456,
    stop_lon: -89.870505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2971,
    stop_code: "WALLAKWN",
    stop_name: "WALNUT GROVE RD@LAKE DR",
    stop_desc: "STATE HWY 23 & COLONIAL RD",
    stop_lat: 35.131027,
    stop_lon: -89.900192,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2972,
    stop_code: "WALNORWN",
    stop_name: "WALNUT GROVE RD@NORWAL",
    stop_desc: "STATE HWY 23 & AVON RD",
    stop_lat: 35.131096,
    stop_lon: -89.904353,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2974,
    stop_code: "WALGROWN",
    stop_name: "WALNUT GROVE RD@GROVE PARK RD",
    stop_desc: "WALNUT GROVE RD & N GROVE PARK RD",
    stop_lat: 35.131247,
    stop_lon: -89.910605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2975,
    stop_code: "WALCHEWN",
    stop_name: "WALNUT GROVE RD@CHERRY LN",
    stop_desc: "",
    stop_lat: 35.131313,
    stop_lon: -89.912752,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2976,
    stop_code: "WALCHEWF",
    stop_name: "WALNUT GROVE RD@CHERRY RD",
    stop_desc: "WALNUT GROVE RD & WARING RD",
    stop_lat: 35.131388,
    stop_lon: -89.915696,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2977,
    stop_code: "WALPIDWF",
    stop_name: "WALNUT GROVE RD@PIDGEON RD",
    stop_desc: "",
    stop_lat: 35.131455,
    stop_lon: -89.918971,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2978,
    stop_code: "WALWOOWF",
    stop_name: "WALNUT GROVE RD@WOOD GROVE RD",
    stop_desc: "",
    stop_lat: 35.131512,
    stop_lon: -89.921693,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2979,
    stop_code: "WALWALWN",
    stop_name: "WALNUT GROVE RD@WALLACE RD",
    stop_desc: "WALNUT GROVE RD & STATE HWY 23",
    stop_lat: 35.13156,
    stop_lon: -89.924415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2980,
    stop_code: "WALROSWF",
    stop_name: "WALNUT GROVE RD@ROSE",
    stop_desc: "WALNUT GROVE RD & STATE HWY 23",
    stop_lat: 35.131617,
    stop_lon: -89.927093,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2981,
    stop_code: "WALSTAWN",
    stop_name: "WALNUT GROVE RD@ST ANDREWS FAIRW",
    stop_desc: "STATE HWY 23 & E GALLOWAY DR",
    stop_lat: 35.131683,
    stop_lon: -89.929716,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2982,
    stop_code: "WALEASWF",
    stop_name: "WALNUT GROVE RD@EASTLAND ST",
    stop_desc: "WALNUT GROVE RD & EASTLAND DR",
    stop_lat: 35.131804,
    stop_lon: -89.934231,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2983,
    stop_code: "WALSEVWN",
    stop_name: "WALNUT GROVE RD@SEVIER ST",
    stop_desc: "",
    stop_lat: 35.131917,
    stop_lon: -89.939288,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2984,
    stop_code: "WALNORWF",
    stop_name: "WALNUT GROVE RD@NORMANDY CIR",
    stop_desc: "",
    stop_lat: 35.131946,
    stop_lon: -89.94118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2985,
    stop_code: "WALHIGWF",
    stop_name: "WALNUT GROVE RD@HIGHLAND ST",
    stop_desc: "WALNUT GROVE RD & STATE HWY 23",
    stop_lat: 35.132049,
    stop_lon: -89.945529,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2986,
    stop_code: "WALREEWM",
    stop_name: "WALNUT GROVE RD@REESE",
    stop_desc: "WALNUT GROVE RD & N REESE ST",
    stop_lat: 35.132893,
    stop_lon: -89.949611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2992,
    stop_code: "WALRACWM",
    stop_name: "WALNUT GROVE RD@RACINE ST",
    stop_desc: "",
    stop_lat: 35.135918,
    stop_lon: -89.963097,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2993,
    stop_code: "WALPOPWM",
    stop_name: "WALNUT GROVE RD@POPLAR AVE",
    stop_desc: "2880 STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.136189,
    stop_lon: -89.965908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2994,
    stop_code: "UNIALIWF",
    stop_name: "UNION AVE@ALICIA DR",
    stop_desc: "UNION AVE & S ALICIA DR",
    stop_lat: 35.133455,
    stop_lon: -89.977919,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2995,
    stop_code: "UNIPATWN",
    stop_name: "UNION AVE@PATRICIA",
    stop_desc: "",
    stop_lat: 35.133475,
    stop_lon: -89.98042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2996,
    stop_code: "UNIEPAWN",
    stop_name: "UNION AVE@E PARKWAY",
    stop_desc: "2362 UNION AVE & RAMP",
    stop_lat: 35.133637,
    stop_lon: -89.982677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2997,
    stop_code: "UNIEDGWN",
    stop_name: "UNION AVE@EDGEWOOD",
    stop_desc: "US HWY 70 & S EDGEWOOD ST",
    stop_lat: 35.133801,
    stop_lon: -89.98582,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2998,
    stop_code: "UNICOXWN",
    stop_name: "UNION AVE@COX",
    stop_desc: "US HWY 70 & S COX ST",
    stop_lat: 35.133972,
    stop_lon: -89.987579,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 2999,
    stop_code: "UNICOOWN",
    stop_name: "UNION AVE@COOPER ST",
    stop_desc: "US HWY 79 & S COOPER ST",
    stop_lat: 35.134224,
    stop_lon: -89.990191,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3000,
    stop_code: "UNIDIAWN",
    stop_name: "UNION AVE@DIANA ST",
    stop_desc: "US HWY 79 & DIANA ST",
    stop_lat: 35.134529,
    stop_lon: -89.993112,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3001,
    stop_code: "UNITUCWN",
    stop_name: "UNION AVE@TUCKER ST",
    stop_desc: "US HWY 79 & S TUCKER ST",
    stop_lat: 35.134943,
    stop_lon: -89.997085,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3002,
    stop_code: "UNIBARWN",
    stop_name: "UNION AVE@BARKSDALE ST",
    stop_desc: "US HWY 70 & S BARKSDALE ST",
    stop_lat: 35.135087,
    stop_lon: -89.998545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3003,
    stop_code: "UNIMCLWF",
    stop_name: "UNION AVE@MCLEAN BLVD",
    stop_desc: "US HWY 79 & UNION AVE",
    stop_lat: 35.135329,
    stop_lon: -90.000681,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3004,
    stop_code: "UNIAUBWF",
    stop_name: "UNION AVE@AUBURNDALE",
    stop_desc: "US HWY 79 & S AUBURNDALE ST",
    stop_lat: 35.135599,
    stop_lon: -90.003226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3005,
    stop_code: "UNIROSWF",
    stop_name: "UNION AVE@ROZELLE",
    stop_desc: "US HWY 70 & US HWY 79",
    stop_lat: 35.135931,
    stop_lon: -90.006424,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3006,
    stop_code: "UNIWILWN",
    stop_name: "UNION AVE@WILLETT ST",
    stop_desc: "US HWY 70 & S WILLETT ST",
    stop_lat: 35.136147,
    stop_lon: -90.008759,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3007,
    stop_code: "UNIWILWF",
    stop_name: "UNION AVE@WILLETT",
    stop_desc: "US HWY 79 & S MCNEIL ST",
    stop_lat: 35.136363,
    stop_lon: -90.011039,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3008,
    stop_code: "UNIMCNWN",
    stop_name: "UNION AVE@MCNEIL ST",
    stop_desc: "US HWY 79 & S MCNEIL ST",
    stop_lat: 35.136471,
    stop_lon: -90.012123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3009,
    stop_code: "UNIWATWN",
    stop_name: "UNION AVE@WATKINS ST",
    stop_desc: "US HWY 79 & S WATKINS ST",
    stop_lat: 35.136704,
    stop_lon: -90.014403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3010,
    stop_code: "UNICLEWN",
    stop_name: "UNION AVE@CLEVELAND ST",
    stop_desc: "1352 UNION AVENUE & CLEVELAND ST",
    stop_lat: 35.136895,
    stop_lon: -90.016418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3011,
    stop_code: "UNICLAWN",
    stop_name: "UNION AVE@CLAYBROOK ST",
    stop_desc: "US HWY 79 & S CLAYBROOK ST",
    stop_lat: 35.137063,
    stop_lon: -90.018077,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3012,
    stop_code: "UNIBELWN",
    stop_name: "UNION AVE@BELLEVUE",
    stop_desc: "STATE HWY 3 & S BELLEVUE BLVD",
    stop_lat: 35.137341,
    stop_lon: -90.020844,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3013,
    stop_code: "UNIWALWM",
    stop_name: "UNION AVE@WALDRAN BLVD",
    stop_desc: "STATE HWY 3 & WALDRAN RD",
    stop_lat: 35.137503,
    stop_lon: -90.022459,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3014,
    stop_code: "UNICAMWM",
    stop_name: "UNION AVE@CAMILLA",
    stop_desc: "US HWY 51 & UNION AVE",
    stop_lat: 35.137835,
    stop_lon: -90.025934,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3015,
    stop_code: "UNIPAUWF",
    stop_name: "UNION AVE@PAULINE ST",
    stop_desc: "976 US HWY 51 & DUDLEY ST",
    stop_lat: 35.138149,
    stop_lon: -90.028856,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3016,
    stop_code: "UNIDUDWF",
    stop_name: "UNION AVE@DUDLEY ST",
    stop_desc: "928 US HWY 51 & DUDLEY ST",
    stop_lat: 35.138247,
    stop_lon: -90.030162,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3017,
    stop_code: "UNIDUNWN",
    stop_name: "UNION AVE@DUNLAP ST",
    stop_desc: "US HWY 51 & S DUNLAP ST",
    stop_lat: 35.138633,
    stop_lon: -90.03367,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3018,
    stop_code: "UNIMARWM",
    stop_name: "UNION AVE@MARSHALL AVE",
    stop_desc: "? US HWY 51 & STATE HWY 3",
    stop_lat: 35.138911,
    stop_lon: -90.036757,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3020,
    stop_code: "UNILA1WM",
    stop_name: "UNION AVE@LAUDERDALE",
    stop_desc: "US HWY 51 & UNION AVE",
    stop_lat: 35.13985,
    stop_lon: -90.040686,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3021,
    stop_code: "UNILAUWN",
    stop_name: "UNION AVE@LAUDERDALE ST",
    stop_desc: "US HWY 51 & S LAUDERDALE ST",
    stop_lat: 35.14036,
    stop_lon: -90.042678,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3022,
    stop_code: "UNIDANWN",
    stop_name: "UNION AVE@DANNY THOMAS",
    stop_desc: "? STATE HWY 3 & S DANNY THOMAS BLVD",
    stop_lat: 35.140977,
    stop_lon: -90.044991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3023,
    stop_code: "UNIFOUWM",
    stop_name: "UNION AVE@FOURTH ST",
    stop_desc: "STATE HWY 14 & S DANNY THOMAS BLVD",
    stop_lat: 35.141514,
    stop_lon: -90.046928,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3024,
    stop_code: "UNITHIWN",
    stop_name: "UNION AVE@THIRD",
    stop_desc: "UNION AVE & S 3RD ST",
    stop_lat: 35.14256,
    stop_lon: -90.050923,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3026,
    stop_code: "UNIDANEN",
    stop_name: "UNION AVE@DANNY THOMAS BLVD",
    stop_desc: "STATE HWY 14 & S DANNY THOMAS BLVD",
    stop_lat: 35.141031,
    stop_lon: -90.045821,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3027,
    stop_code: "UNILAUEN",
    stop_name: "UNION AVE@LAUDERDALE ST",
    stop_desc: "STATE HWY 3 & S LAUDERDALE ST",
    stop_lat: 35.140271,
    stop_lon: -90.04301,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3028,
    stop_code: "UNIMYREM",
    stop_name: "UNION AVE@MYRTLE",
    stop_desc: "STATE HWY 3 & UNION AVE",
    stop_lat: 35.139725,
    stop_lon: -90.040863,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3030,
    stop_code: "UNIMANEN",
    stop_name: "UNION AVE@MANASSAS ST",
    stop_desc: "US HWY 51 & UNION AVE",
    stop_lat: 35.138696,
    stop_lon: -90.036315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3031,
    stop_code: "UNIDUNEM",
    stop_name: "UNION AVE@DUNLAP",
    stop_desc: "815 US HWY 51 & UNION AVE",
    stop_lat: 35.138535,
    stop_lon: -90.034334,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3032,
    stop_code: "UNIDUDEN",
    stop_name: "UNION AVE@DUDLEY ST",
    stop_desc: "0 UNION AVE & S DUDLEY ST",
    stop_lat: 35.13806,
    stop_lon: -90.029653,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3033,
    stop_code: "UNIPAUEN",
    stop_name: "UNION AVE@PAULINE ST",
    stop_desc: "US HWY 51 & UNION AVE",
    stop_lat: 35.137943,
    stop_lon: -90.028446,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3034,
    stop_code: "UNIPASEN",
    stop_name: "UNION AVE@PASADENA PL",
    stop_desc: "STATE HWY 3 & PASADENA PL",
    stop_lat: 35.137306,
    stop_lon: -90.022172,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3035,
    stop_code: "UNIBELEF",
    stop_name: "UNION AVE@BELLEVUE",
    stop_desc: "? US HWY 79 & RAMP",
    stop_lat: 35.137055,
    stop_lon: -90.019549,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3036,
    stop_code: "UNICLAEN",
    stop_name: "UNION AVE@CLAYBROOK ST",
    stop_desc: "US HWY 70 & S CLAYBROOK ST",
    stop_lat: 35.136929,
    stop_lon: -90.018287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3037,
    stop_code: "UNICLEEN",
    stop_name: "UNION AVE@CLEVELAND ST",
    stop_desc: "US HWY 70 & UNION AVE",
    stop_lat: 35.136786,
    stop_lon: -90.016893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3038,
    stop_code: "UNIKIMEN",
    stop_name: "UNION AVE@KIMBROUGH ST",
    stop_desc: "US HWY 70 & UNION AVE",
    stop_lat: 35.136346,
    stop_lon: -90.012433,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3039,
    stop_code: "UNIWILEM",
    stop_name: "UNION AVE@WILLETT ST",
    stop_desc: "US HWY 70 & S WILLETT ST",
    stop_lat: 35.136148,
    stop_lon: -90.010541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3040,
    stop_code: "UNIAVAEN",
    stop_name: "UNION AVE@AVALON ST",
    stop_desc: "US HWY 70 & S AVALON ST",
    stop_lat: 35.135941,
    stop_lon: -90.008361,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3041,
    stop_code: "UNIBELSN",
    stop_name: "UNION AVE@BELVEDERE BLVD",
    stop_desc: "US HWY 70 & S BELVEDERE BLVD",
    stop_lat: 35.135681,
    stop_lon: -90.005484,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3042,
    stop_code: "UNILEMEN",
    stop_name: "UNION AVE@LEMASTER ST",
    stop_desc: "US HWY 70 & LEMASTER ST",
    stop_lat: 35.135528,
    stop_lon: -90.004244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3043,
    stop_code: "UNIAUBEF",
    stop_name: "UNION AVE@AUBURNDALE",
    stop_desc: "US HWY 70 & S AUBURNDALE ST",
    stop_lat: 35.135366,
    stop_lon: -90.002562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3044,
    stop_code: "UNIMCLEN",
    stop_name: "UNION AVE@MCLEAN BLVD",
    stop_desc: "US HWY 70 & S MCLEAN BLVD",
    stop_lat: 35.135133,
    stop_lon: -90.000427,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3045,
    stop_code: "UNIBAREN",
    stop_name: "UNION AVE@BARKSDALE",
    stop_desc: "0 UNION AVE & S MCLEAN BLVD",
    stop_lat: 35.134998,
    stop_lon: -89.999165,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3046,
    stop_code: "UNIREMEN",
    stop_name: "UNION AVE@REMBERT ST",
    stop_desc: "US HWY 70 & S REMBERT ST",
    stop_lat: 35.134629,
    stop_lon: -89.995547,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3047,
    stop_code: "UNIFLOEN",
    stop_name: "UNION AVE@FLORENCE ST",
    stop_desc: "US HWY 70 & FLORENCE ST",
    stop_lat: 35.134315,
    stop_lon: -89.992648,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3048,
    stop_code: "UNICOOEN",
    stop_name: "UNION AVE@COOPER ST",
    stop_desc: "US HWY 70 & US HWY 79",
    stop_lat: 35.134099,
    stop_lon: -89.990578,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3049,
    stop_code: "UNISUMEM",
    stop_name: "UNION AVE@SUMMIT ST",
    stop_desc: "US HWY 70 & US HWY 79",
    stop_lat: 35.134,
    stop_lon: -89.989649,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3050,
    stop_code: "UNISCOEN",
    stop_name: "UNION AVE@S COX ST",
    stop_desc: "US HWY 70 & S COX ST",
    stop_lat: 35.133865,
    stop_lon: -89.988155,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3051,
    stop_code: "UNIEDGEN",
    stop_name: "UNION AVE@EDGEWOOD ST",
    stop_desc: "US HWY 70 & S EDGEWOOD ST",
    stop_lat: 35.133721,
    stop_lon: -89.986584,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3053,
    stop_code: "UNISTAEF",
    stop_name: "UNION AVE@ST AGNES",
    stop_desc: "UNION AVE & STAGNES",
    stop_lat: 35.133285,
    stop_lon: -89.980053,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3054,
    stop_code: "UNIHOLEN",
    stop_name: "UNION AVE@HOLLYWOOD ST",
    stop_desc: "2511 UNION AVE & HOLLYWOOD",
    stop_lat: 35.133185,
    stop_lon: -89.977657,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3064,
    stop_code: "WALHIGEF",
    stop_name: "WALNUT GROVE RD@HIGHLAND ST",
    stop_desc: "",
    stop_lat: 35.131888,
    stop_lon: -89.944954,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3065,
    stop_code: "WALWGAEN",
    stop_name: "WALNUT GROVE RD@WGALLOWAY DR",
    stop_desc: "WALNUT GROVE RD & W GALLOWAY DR",
    stop_lat: 35.131804,
    stop_lon: -89.9419,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3066,
    stop_code: "WALSEVEF",
    stop_name: "WALNUT GROVE RD@SEVIER",
    stop_desc: "STATE HWY 23 & SEVIER ST",
    stop_lat: 35.131756,
    stop_lon: -89.939288,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3067,
    stop_code: "WALHIGEN",
    stop_name: "WALNUT GROVE RD@HIGH POINT TERR",
    stop_desc: "STATE HWY 23 & HIGH POINT TER",
    stop_lat: 35.131709,
    stop_lon: -89.937031,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3069,
    stop_code: "WALSTAEN",
    stop_name: "WALNUT GROVE RD@ST ANDREWS",
    stop_desc: "STATE HWY 23 & SAINT ANDREWS FAIRWAY",
    stop_lat: 35.13155,
    stop_lon: -89.930956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3070,
    stop_code: "WALROSEN",
    stop_name: "WALNUT GROVE RD@ROSE",
    stop_desc: "STATE HWY 23 & S ROSE RD",
    stop_lat: 35.131466,
    stop_lon: -89.927592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3071,
    stop_code: "WALGOOEF",
    stop_name: "WALNUT GROVE RD@GOODLET",
    stop_desc: "STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.131409,
    stop_lon: -89.924858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3072,
    stop_code: "WALWOOEF",
    stop_name: "WALNUT GROVE RD@WOOD GROVE",
    stop_desc: "STATE HWY 23 & PIDGEON RD",
    stop_lat: 35.131323,
    stop_lon: -89.920565,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3073,
    stop_code: "WALWYCEN",
    stop_name: "WALNUT GROVE@WYCHEWOOD DR",
    stop_desc: "STATE HWY 23 & WYCHEWOOD DR",
    stop_lat: 35.131265,
    stop_lon: -89.917544,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3074,
    stop_code: "WALCHEEF",
    stop_name: "WALNUT GROVE RD@CHERRY RD",
    stop_desc: "STATE HWY 23 & CHERRY RD",
    stop_lat: 35.131209,
    stop_lon: -89.915143,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3075,
    stop_code: "WALECHEF",
    stop_name: "WALNUT GROVE RD@ECHERRY DR",
    stop_desc: "WALNUT GROVE RD & E CHERRY DR",
    stop_lat: 35.131162,
    stop_lon: -89.913261,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3076,
    stop_code: "WALGROEN",
    stop_name: "WALNUT GROVE RD@GROVE PARK RD",
    stop_desc: "STATE HWY 23 & S GROVE PARK RD",
    stop_lat: 35.131096,
    stop_lon: -89.910949,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3077,
    stop_code: "WALPEREN",
    stop_name: "WALNUT GROVE RD@PERKINS EXT",
    stop_desc: "STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.131049,
    stop_lon: -89.909167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3078,
    stop_code: "WALPEREF",
    stop_name: "WALNUT GROVE RD@PERKINS EXT",
    stop_desc: "STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.131002,
    stop_lon: -89.907917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3079,
    stop_code: "WALNOREN",
    stop_name: "WALNUT GROVE RD@NORWAL",
    stop_desc: "STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.130935,
    stop_lon: -89.904641,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3080,
    stop_code: "WALAVOEN",
    stop_name: "WALNUT GROVE RD@AVON",
    stop_desc: "STATE HWY 23 & WALNUT GROVE RD",
    stop_lat: 35.130906,
    stop_lon: -89.902826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3081,
    stop_code: "WALLAKEN",
    stop_name: "WALNUT GROVE RD@LAKE",
    stop_desc: "STATE HWY 23 & LAKE RD",
    stop_lat: 35.130876,
    stop_lon: -89.900813,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3107,
    stop_code: "MENTUTNN",
    stop_name: "MENDENHALL@TUTWILER AVE",
    stop_desc: "N MENDENHALL RD & TUTWILER AVE",
    stop_lat: 35.150193,
    stop_lon: -89.897759,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3109,
    stop_code: "MENDURNM",
    stop_name: "MENDENHALL@DURBIN AVE",
    stop_desc: "N MENDENHALL RD & DURBIN AVE",
    stop_lat: 35.155233,
    stop_lon: -89.897604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3110,
    stop_code: "MENMACNM",
    stop_name: "MENDENHALL@MACON",
    stop_desc: "N MENDENHALL RD & VIOLET AVE",
    stop_lat: 35.157798,
    stop_lon: -89.89751,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3111,
    stop_code: "MACWHIEN",
    stop_name: "MACON@WHITE STATION",
    stop_desc: "MACON RD & N WHITE STATION RD",
    stop_lat: 35.158573,
    stop_lon: -89.896191,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3112,
    stop_code: "WHIBAREF",
    stop_name: "WHITE STATION@BARTLETT RD",
    stop_desc: "N WHITE STATION RD & RAMP",
    stop_lat: 35.155108,
    stop_lon: -89.891438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3113,
    stop_code: "WHISUMEM",
    stop_name: "WHITESTATION@SUMMER AVE",
    stop_desc: "851 N WHITE STATION RD & RAMP",
    stop_lat: 35.154248,
    stop_lon: -89.890322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3121,
    stop_code: "PERGRESM",
    stop_name: "PERKINS EXT@GREENBRIAR",
    stop_desc: "PERKINS RD & PERKINS EXT",
    stop_lat: 35.129252,
    stop_lon: -89.908872,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3123,
    stop_code: "PERSHASN",
    stop_name: "PERKINS EXT@SHADY GROVE RD",
    stop_desc: "PERKINS EXT & PERKINS RD",
    stop_lat: 35.124963,
    stop_lon: -89.909302,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3125,
    stop_code: "PERALDSN",
    stop_name: "PERKINS EXT@ALDERSGATE",
    stop_desc: "PERKINS EXT & PERKINS RD",
    stop_lat: 35.122094,
    stop_lon: -89.909385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3126,
    stop_code: "PERPOPSM",
    stop_name: "PERKINS EXT@POPLAR",
    stop_desc: "0 PERKINS EXT & RAMP",
    stop_lat: 35.117313,
    stop_lon: -89.908952,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3127,
    stop_code: "PERPOPSN",
    stop_name: "PERKINS EXT@POPLAR AVE",
    stop_desc: "451 PERKINS EXT & RAMP",
    stop_lat: 35.116607,
    stop_lon: -89.909264,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3128,
    stop_code: "PERWINSN",
    stop_name: "PERKINS@WINDERMERE",
    stop_desc: "PERKINS & PARK AVE",
    stop_lat: 35.110588,
    stop_lon: -89.907752,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3129,
    stop_code: "PERPERSM",
    stop_name: "PERKINS@PERKINS COVE",
    stop_desc: "S PERKINS RD & OAK COURT DR",
    stop_lat: 35.106229,
    stop_lon: -89.908013,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3130,
    stop_code: "PERDEESN",
    stop_name: "PERKINS@DEE RD",
    stop_desc: "S PERKINS RD & DEE RD",
    stop_lat: 35.100901,
    stop_lon: -89.90673,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3131,
    stop_code: "PERDEESF",
    stop_name: "PERKINS@DEE",
    stop_desc: "S PERKINS RD & DEE RD",
    stop_lat: 35.098774,
    stop_lon: -89.906801,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3132,
    stop_code: "PERFLASN",
    stop_name: "PERKINS@FLAMINGO",
    stop_desc: "S PERKINS RD & FLAMINGO RD",
    stop_lat: 35.096674,
    stop_lon: -89.906894,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3133,
    stop_code: "PERSEASN",
    stop_name: "PERKINS@SEA ISLE RD",
    stop_desc: "S PERKINS RD & SEA ISLE RD",
    stop_lat: 35.09385,
    stop_lon: -89.906977,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3134,
    stop_code: "PERQUISN",
    stop_name: "PERKINS@QUINCE RD",
    stop_desc: "S PERKINS RD & VERNE RD",
    stop_lat: 35.091598,
    stop_lon: -89.907049,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3136,
    stop_code: "PERAMBNN",
    stop_name: "PERKINS@AMBOY",
    stop_desc: "S PERKINS RD & AMBOY RD",
    stop_lat: 35.095548,
    stop_lon: -89.906731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3137,
    stop_code: "PERFLANN",
    stop_name: "PERKINS@FLAMINGO RD",
    stop_desc: "S PERKINS RD & AMBOY RD",
    stop_lat: 35.096388,
    stop_lon: -89.906707,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3138,
    stop_code: "PERDEENM",
    stop_name: "PERKINS@DEE RD",
    stop_desc: "S PERKINS RD & AMBOY RD",
    stop_lat: 35.098354,
    stop_lon: -89.906614,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3139,
    stop_code: "PERDEENN",
    stop_name: "PERKINS@DEE RD",
    stop_desc: "S PERKINS RD & DEE RD",
    stop_lat: 35.10049,
    stop_lon: -89.906543,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3140,
    stop_code: "PERPARNM",
    stop_name: "PERKINS@PARK",
    stop_desc: "S PERKINS RD & DEE RD",
    stop_lat: 35.106774,
    stop_lon: -89.907791,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3141,
    stop_code: "PERPARNF",
    stop_name: "PERKINS@PARK",
    stop_desc: "",
    stop_lat: 35.10747,
    stop_lon: -89.907749,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3142,
    stop_code: "PERWINNF",
    stop_name: "PERKINS@WINDERMERE",
    stop_desc: "S PERKINS RD & WINDERMERE RD",
    stop_lat: 35.11017,
    stop_lon: -89.907618,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3143,
    stop_code: "PERSOUNM",
    stop_name: "PERKINS@SOUTHERN AVE",
    stop_desc: "PERKINS EXT & PERKINS RD",
    stop_lat: 35.113263,
    stop_lon: -89.908408,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3144,
    stop_code: "PERPOPNN",
    stop_name: "PERKINS EXT@POPLAR AVE",
    stop_desc: "504 PERKINS EXTD & POPLAR",
    stop_lat: 35.116616,
    stop_lon: -89.90906,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3145,
    stop_code: "PERALDNM",
    stop_name: "PERKINS@ALDERSGATE",
    stop_desc: "434 PERKINS EXT & PERKINS RD",
    stop_lat: 35.120046,
    stop_lon: -89.908438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3147,
    stop_code: "PERSHANN",
    stop_name: "PERKINS@SHADY GROVE",
    stop_desc: "PERKINS RD & LAURELWOOD DR",
    stop_lat: 35.124525,
    stop_lon: -89.909103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3149,
    stop_code: "PERWALNM",
    stop_name: "PERKINS@WALNUT GROVE RD",
    stop_desc: "PERKINS EXT & PERKINS RD",
    stop_lat: 35.130056,
    stop_lon: -89.90866,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3155,
    stop_code: "QUIFLAWN",
    stop_name: "QUINCE RD@FLAMINGO RD",
    stop_desc: "",
    stop_lat: 35.093957,
    stop_lon: -89.913017,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3162,
    stop_code: "GETPARNM",
    stop_name: "GETWELL RD@PARK AVE",
    stop_desc: "",
    stop_lat: 35.106634,
    stop_lon: -89.930658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3163,
    stop_code: "GETPARSF",
    stop_name: "GETWELL RD@PARK AVE",
    stop_desc: "915 GETWELL RD & RAMP",
    stop_lat: 35.106938,
    stop_lon: -89.930779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3171,
    stop_code: "RHOCH1EN",
    stop_name: "RHODES@CHERRY",
    stop_desc: "RHODES AVE & CHERRY RD",
    stop_lat: 35.095185,
    stop_lon: -89.916166,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3172,
    stop_code: "QUISEAEN",
    stop_name: "QUINCE RD@SEA ISLE",
    stop_desc: "QUINCE RD & CHERRY RD",
    stop_lat: 35.092406,
    stop_lon: -89.91,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3173,
    stop_code: "HIGNORNN",
    stop_name: "HIGHLAND@NORTHWOOD DR",
    stop_desc: "N HIGHLAND ST & NORTHWOOD DR",
    stop_lat: 35.134095,
    stop_lon: -89.94495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3174,
    stop_code: "HIGOAKNN",
    stop_name: "HIGHLAND@OAKLEY AVE",
    stop_desc: "N HIGHLAND ST & OAKLEY AVE",
    stop_lat: 35.136409,
    stop_lon: -89.94488,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3175,
    stop_code: "HIGWAYNN",
    stop_name: "HIGHLAND ST@WAYNOKA",
    stop_desc: "N HIGHLAND ST & WAYNOKA AVE",
    stop_lat: 35.137741,
    stop_lon: -89.944834,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3176,
    stop_code: "HIGCHANF",
    stop_name: "HIGHLAND@CHARLESWOOD AVE",
    stop_desc: "N HIGHLAND ST & CHARLESWOOD AVE",
    stop_lat: 35.142996,
    stop_lon: -89.944659,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3177,
    stop_code: "HIGSHINN",
    stop_name: "HIGHLAND@SHIRLWOOD",
    stop_desc: "N HIGHLAND ST & SHIRLWOOD AVE",
    stop_lat: 35.144408,
    stop_lon: -89.944613,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3178,
    stop_code: "HIGPHINF",
    stop_name: "HIGHLAND@PHILWOOD AVE",
    stop_desc: "N HIGHLAND ST & PHILWOOD AVE",
    stop_lat: 35.145569,
    stop_lon: -89.944578,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3185,
    stop_code: "MACGOREN",
    stop_name: "MACON RD@GORDON ST",
    stop_desc: "",
    stop_lat: 35.161511,
    stop_lon: -89.943068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3186,
    stop_code: "MACLANEF",
    stop_name: "MACON@LANGSTON",
    stop_desc: "MACON RD & LANGSTON ST",
    stop_lat: 35.160989,
    stop_lon: -89.940312,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3187,
    stop_code: "HOMMACNF",
    stop_name: "HOMER ST@MACON",
    stop_desc: "HOMER ST & MACON RD",
    stop_lat: 35.161239,
    stop_lon: -89.939526,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3191,
    stop_code: "NATMAYSN",
    stop_name: "NATIONAL ST@MAYFAIR AVE",
    stop_desc: "",
    stop_lat: 35.170718,
    stop_lon: -89.942817,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3193,
    stop_code: "JACMAYNF",
    stop_name: "JACKSON@MAYFAIR AVE",
    stop_desc: "STATE HWY 14 & MAYFAIR AVE",
    stop_lat: 35.171288,
    stop_lon: -89.943871,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3194,
    stop_code: "JACBAYNN",
    stop_name: "JACKSON AVE@BAYLISS AVE",
    stop_desc: "STATE HWY 14 & BAYLISS AVE",
    stop_lat: 35.172163,
    stop_lon: -89.942796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3201,
    stop_code: "HOMMACSN",
    stop_name: "HOMER ST@MACON RD",
    stop_desc: "",
    stop_lat: 35.160997,
    stop_lon: -89.939626,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3203,
    stop_code: "MACBILWN",
    stop_name: "MACON RD@BILTMORE",
    stop_desc: "",
    stop_lat: 35.16124,
    stop_lon: -89.941164,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3204,
    stop_code: "MACGORWN",
    stop_name: "MACON RD@GORDON ST",
    stop_desc: "",
    stop_lat: 35.161537,
    stop_lon: -89.942293,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3213,
    stop_code: "HIGPHISN",
    stop_name: "HIGHLAND ST@PHILWOOD AVE",
    stop_desc: "N HIGHLAND ST & PHILWOOD AVE",
    stop_lat: 35.145409,
    stop_lon: -89.944777,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3214,
    stop_code: "HIGSHISN",
    stop_name: "HIGHLAND ST@SHIRLWOOD AVE",
    stop_desc: "N HIGHLAND ST & SHIRLWOOD AVE",
    stop_lat: 35.144613,
    stop_lon: -89.9448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3215,
    stop_code: "HIGCHASF",
    stop_name: "HIGHLAND ST@CHARLESWOOD",
    stop_desc: "N HIGHLAND ST & CHARLESWOOD AVE",
    stop_lat: 35.142719,
    stop_lon: -89.94487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3216,
    stop_code: "HIGBARSF",
    stop_name: "HIGHLAND ST@BARWOOD CR",
    stop_desc: "317 N HIGHLAND ST & BARWOOD CIR",
    stop_lat: 35.140735,
    stop_lon: -89.944929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3217,
    stop_code: "HIGMIMSF",
    stop_name: "HIGHLAND ST@MIMOSA AVE",
    stop_desc: "N HIGHLAND ST & MIMOSA AVE",
    stop_lat: 35.138974,
    stop_lon: -89.944998,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3218,
    stop_code: "HIGWAYSN",
    stop_name: "HIGHLAND ST@WAYNOKA AVE",
    stop_desc: "N HIGHLAND ST & WAYNOKA AVE",
    stop_lat: 35.137509,
    stop_lon: -89.945033,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3219,
    stop_code: "HIGOAKSF",
    stop_name: "HIGHLAND ST@OAKLEY",
    stop_desc: "N HIGHLAND ST & OAKLEY AVE",
    stop_lat: 35.136401,
    stop_lon: -89.945079,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3220,
    stop_code: "HIGHIGSN",
    stop_name: "HIGHLAND ST@HIGHLAND PARK P",
    stop_desc: "N HIGHLAND ST & HIGHLAND PARK PL",
    stop_lat: 35.135462,
    stop_lon: -89.945103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3221,
    stop_code: "HIGHI1SN",
    stop_name: "HIGHLAND ST@HIGHLAND CV",
    stop_desc: "N HIGHLAND ST & HIGHLAND CV",
    stop_lat: 35.134265,
    stop_lon: -89.945138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3222,
    stop_code: "HIGLILSF",
    stop_name: "HIGHLAND ST@LILLY LN",
    stop_desc: "",
    stop_lat: 35.130512,
    stop_lon: -89.945244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3223,
    stop_code: "HIGPOPSN",
    stop_name: "HIGHLAND ST @ POPLAR AVE",
    stop_desc: "133 HIGHLAND ST & POPLAR AVE",
    stop_lat: 35.128167,
    stop_lon: -89.945315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3224,
    stop_code: "HIGWINSN",
    stop_name: "HIGHLAND ST@WINDOVER",
    stop_desc: "S HIGHLAND ST & WINDOVER RD",
    stop_lat: 35.12658,
    stop_lon: -89.945405,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3225,
    stop_code: "HIGCENSF",
    stop_name: "HIGHLAND ST@CENTRAL AVE",
    stop_desc: "",
    stop_lat: 35.122777,
    stop_lon: -89.945547,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3226,
    stop_code: "HIGWATSM",
    stop_name: "HIGHLAND ST@WATUGA AVE",
    stop_desc: "409 S HIGHLAND ST & WATAUGA AVE",
    stop_lat: 35.120842,
    stop_lon: -89.945669,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3227,
    stop_code: "HIGMIDSN",
    stop_name: "HIGHLAND ST@MIDLAND",
    stop_desc: "S HIGHLAND ST & WATAUGA AVE",
    stop_lat: 35.119395,
    stop_lon: -89.945727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3228,
    stop_code: "HIGSOUSN",
    stop_name: "HIGHLAND ST@SOUTHERN AVE",
    stop_desc: "",
    stop_lat: 35.115812,
    stop_lon: -89.945841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3229,
    stop_code: "HIGSPOSM",
    stop_name: "HIGHLAND ST@SPOTTSWOOD AVE",
    stop_desc: "",
    stop_lat: 35.114481,
    stop_lon: -89.945868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3279,
    stop_code: "HIGSPONF",
    stop_name: "HIGHLAND@SPOTTSWOOD",
    stop_desc: "",
    stop_lat: 35.113758,
    stop_lon: -89.945739,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3280,
    stop_code: "HIGWA2NN",
    stop_name: "HIGHLAND ST@WALKER",
    stop_desc: "HIGHLAND & WALKER AVE",
    stop_lat: 35.116098,
    stop_lon: -89.945636,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3281,
    stop_code: "HIGMIDNN",
    stop_name: "HIGHLAND@MIDLAND AVE",
    stop_desc: "",
    stop_lat: 35.119063,
    stop_lon: -89.945522,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3282,
    stop_code: "HIGNO1NN",
    stop_name: "HIGHLAND@NORRISWOOD",
    stop_desc: "",
    stop_lat: 35.120941,
    stop_lon: -89.945448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3283,
    stop_code: "HIGCENNF",
    stop_name: "HIGHLAND ST@CENTRAL AVE",
    stop_desc: "S HIGHLAND ST & CENTRAL AVE",
    stop_lat: 35.123559,
    stop_lon: -89.945355,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3284,
    stop_code: "HIGWINNM",
    stop_name: "HIGHLAND ST@WINDOVER",
    stop_desc: "0 S HIGHLAND ST & UNNAMED ST",
    stop_lat: 35.125695,
    stop_lon: -89.945208,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3285,
    stop_code: "HIGPOPNN",
    stop_name: "HIGHLAND ST @ POPLAR AVE",
    stop_desc: "",
    stop_lat: 35.127393,
    stop_lon: -89.945167,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3286,
    stop_code: "HIGGALNN",
    stop_name: "HIGHLAND@GALLOWAY",
    stop_desc: "S HIGHLAND ST & S GALLOWAY DR",
    stop_lat: 35.128608,
    stop_lon: -89.945092,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3287,
    stop_code: "HIGLILNN",
    stop_name: "HIGHLAND@LILLY",
    stop_desc: "S HIGHLAND ST & LILLY LN",
    stop_lat: 35.130342,
    stop_lon: -89.945045,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3288,
    stop_code: "HIGWA1NN",
    stop_name: "HIGHLAND@WALNUT GROVE",
    stop_desc: "S HIGHLAND ST & N HIGHLAND ST",
    stop_lat: 35.131861,
    stop_lon: -89.944987,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3314,
    stop_code: "ALIPOPSF",
    stop_name: "ALICIA DR@POPLAR AVE",
    stop_desc: "N ALICIA DR & MISSISSIPPI BLVD",
    stop_lat: 35.138461,
    stop_lon: -89.979152,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3315,
    stop_code: "ALIMADSM",
    stop_name: "ALICIA DR@MADISON AVE",
    stop_desc: "N ALICIA DR & KETCHUM RD",
    stop_lat: 35.136869,
    stop_lon: -89.977561,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3316,
    stop_code: "ALIMADSN",
    stop_name: "ALICIA DR@MADISON AVE",
    stop_desc: "N ALICIA DR & MADISON AVE",
    stop_lat: 35.135823,
    stop_lon: -89.977462,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3317,
    stop_code: "ALIUNISM",
    stop_name: "ALICIA DR@UNION AVE",
    stop_desc: "S ALICIA DR & MADISON AVE",
    stop_lat: 35.134929,
    stop_lon: -89.977497,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3318,
    stop_code: "ALIUNISN",
    stop_name: "ALICIA DR@UNION AVE",
    stop_desc: "S ALICIA DR & UNION AVE",
    stop_lat: 35.133535,
    stop_lon: -89.977554,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3319,
    stop_code: "HOLAVESF",
    stop_name: "HOLLYWOOD@AVERY",
    stop_desc: "S HOLLYWOOD ST & AVERY AVE",
    stop_lat: 35.130487,
    stop_lon: -89.976982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3321,
    stop_code: "CENHOLWF",
    stop_name: "CENTRAL AVE@HOLLYWOOD",
    stop_desc: "",
    stop_lat: 35.12575,
    stop_lon: -89.97629,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3322,
    stop_code: "CENEDWWN",
    stop_name: "CENTRAL AVE@EDWIN CIR",
    stop_desc: "CENTRAL AVE & E EDWIN CIR",
    stop_lat: 35.125903,
    stop_lon: -89.977186,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3323,
    stop_code: "CENEDWM",
    stop_name: "CENTRAL AVE@WEDWIN",
    stop_desc: "CENTRAL AVE & EDWIN CIR W",
    stop_lat: 35.125913,
    stop_lon: -89.978348,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3324,
    stop_code: "CENEARWN",
    stop_name: "CENTRAL AVE@EARLY MAXWELL",
    stop_desc: "CENTRAL AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.125896,
    stop_lon: -89.980329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3325,
    stop_code: "CENEPAWM",
    stop_name: "CENTRAL AVE@E PARKWAY",
    stop_desc: "2388 CENTRAL AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.125951,
    stop_lon: -89.982254,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3326,
    stop_code: "EPAYORSN",
    stop_name: "E PARKWAY@YORK AVE",
    stop_desc: "E PARKWAY S & YORK AVE",
    stop_lat: 35.125318,
    stop_lon: -89.984435,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3327,
    stop_code: "EPAEVESN",
    stop_name: "E PARKWAY@EVELYN AVE",
    stop_desc: "E PARKWAY S & EVELYN AVE",
    stop_lat: 35.122727,
    stop_lon: -89.984515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3331,
    stop_code: "AIRCARSM",
    stop_name: "AIRWAYS BLVD@CARNES",
    stop_desc: "STATE HWY 277 & AIRWAYS BLVD",
    stop_lat: 35.113862,
    stop_lon: -89.98478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3332,
    stop_code: "AIRSARSN",
    stop_name: "AIRWAYS BLVD@SARATOGA",
    stop_desc: "AIRWAYS BLVD & BROOKLYN AVE",
    stop_lat: 35.109581,
    stop_lon: -89.984984,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3333,
    stop_code: "AIRPARSN",
    stop_name: "AIRWAYS BLVD@PARK AVE",
    stop_desc: "? AIRWAYS BLVD & STATE HWY 277",
    stop_lat: 35.10883,
    stop_lon: -89.985018,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3334,
    stop_code: "AIRPROSF",
    stop_name: "AIRWAYS@PROGRESS",
    stop_desc: "",
    stop_lat: 35.106885,
    stop_lon: -89.985097,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3335,
    stop_code: "AIRLAMSN",
    stop_name: "AIRWAYS BLVD@LAMAR AVE",
    stop_desc: "STATE HWY 277 & AIRWAYS BLVD",
    stop_lat: 35.105721,
    stop_lon: -89.985574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3336,
    stop_code: "AIRLAMNF",
    stop_name: "AIRWAYS@LAMAR",
    stop_desc: "AIRWAYS BLVD & STATE HWY 277",
    stop_lat: 35.105649,
    stop_lon: -89.985397,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3337,
    stop_code: "AIRPRONN",
    stop_name: "AIRWAYS@PROGRESS",
    stop_desc: "AIRWAYS BLVD & PROGRESS AVE",
    stop_lat: 35.106855,
    stop_lon: -89.984964,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3338,
    stop_code: "AIRPARNN",
    stop_name: "AIRWAYS@PARK AVE",
    stop_desc: "STATE HWY 277 & AIRWAYS BLVD",
    stop_lat: 35.108357,
    stop_lon: -89.984841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3339,
    stop_code: "AIRBRONN",
    stop_name: "AIRWAYS@BROOKLYN",
    stop_desc: "AIRWAYS BLVD & BROOKLYN AVE",
    stop_lat: 35.109804,
    stop_lon: -89.984773,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3340,
    stop_code: "AIRSPONN",
    stop_name: "AIRWAYS@SPOTTSWOOD AVE",
    stop_desc: "AIRWAYS BLVD & STATE HWY 277",
    stop_lat: 35.114121,
    stop_lon: -89.984558,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3346,
    stop_code: "CENEPAEF",
    stop_name: "CENTRAL AVE@E PARKWAY",
    stop_desc: "2363 CENTRAL AVE & RAMP",
    stop_lat: 35.125836,
    stop_lon: -89.983372,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3347,
    stop_code: "CENEAREM",
    stop_name: "CENTRAL AVE@EARLY MAXWELL",
    stop_desc: "CENTRAL AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.125781,
    stop_lon: -89.981723,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3348,
    stop_code: "CENEAREN",
    stop_name: "CENTRAL AVE@EARLY MAXWELL",
    stop_desc: "CENTRAL AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.125745,
    stop_lon: -89.980705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3349,
    stop_code: "CENEDWEN",
    stop_name: "CENTRAL AVE@EDWIN",
    stop_desc: "CENTRAL AVE & W EDWIN CIR",
    stop_lat: 35.125743,
    stop_lon: -89.978725,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3350,
    stop_code: "CENHOLEM",
    stop_name: "CENTRAL AVE@HOLLYWOOD",
    stop_desc: "CENTRAL AVE & E EDWIN CIR",
    stop_lat: 35.125661,
    stop_lon: -89.976965,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3351,
    stop_code: "CENHOLEN",
    stop_name: "CENTRAL AVE@HOLLYWOOD",
    stop_desc: "CENTRAL AVE & S HOLLYWOOD ST",
    stop_lat: 35.125634,
    stop_lon: -89.976113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3352,
    stop_code: "HOLCENNF",
    stop_name: "HOLLYWOOD@CENTRAL AVE",
    stop_desc: "? S HOLLYWOOD ST & CENTRAL AVE",
    stop_lat: 35.126366,
    stop_lon: -89.975792,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3353,
    stop_code: "HOLAVENM",
    stop_name: "HOLLYWOOD@AVERY AVE",
    stop_desc: "S HOLLYWOOD ST & CENTRAL AVE",
    stop_lat: 35.129611,
    stop_lon: -89.97675,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3354,
    stop_code: "ALIUNINF",
    stop_name: "ALICIA@UNION AVE",
    stop_desc: "S ALICIA DR & UNION AVE",
    stop_lat: 35.133642,
    stop_lon: -89.977354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3355,
    stop_code: "ALIMADNF",
    stop_name: "ALICIA@MADISON AVE",
    stop_desc: "N ALICIA DR & MADISON AVE",
    stop_lat: 35.136073,
    stop_lon: -89.977252,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3356,
    stop_code: "ALIASHNN",
    stop_name: "ALICIA@ASHLAWN",
    stop_desc: "N ALICIA DR & ALTON AVE",
    stop_lat: 35.137808,
    stop_lon: -89.978722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3357,
    stop_code: "ALIPOPNN",
    stop_name: "ALICIA@POPLAR",
    stop_desc: "0 N ALICIA DR & N HOLLYWOOD ST",
    stop_lat: 35.138612,
    stop_lon: -89.978876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3359,
    stop_code: "COOMONSN",
    stop_name: "COOPER@MONROE",
    stop_desc: "S COOPER ST & MONROE",
    stop_lat: 35.135215,
    stop_lon: -89.990399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3360,
    stop_code: "COOUNISN",
    stop_name: "COOPER@UNION AVE",
    stop_desc: "S COOPER ST & UNION AVE",
    stop_lat: 35.134367,
    stop_lon: -89.990501,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3361,
    stop_code: "COOLINSN",
    stop_name: "COOPER@LINDEN",
    stop_desc: "S COOPER ST & LINDEN AVE",
    stop_lat: 35.133259,
    stop_lon: -89.990557,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3362,
    stop_code: "COOPEASN",
    stop_name: "COOPER@PEABODY AVE",
    stop_desc: "S COOPER ST & PEABODY AVE",
    stop_lat: 35.131873,
    stop_lon: -89.990603,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3363,
    stop_code: "COOVINSN",
    stop_name: "COOPER@VINTON AVE",
    stop_desc: "S COOPER ST & VINTON AVE",
    stop_lat: 35.130345,
    stop_lon: -89.990671,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3364,
    stop_code: "COOHARSN",
    stop_name: "COOPER@HARBERT AVE",
    stop_desc: "S COOPER ST & HARBERT AVE",
    stop_lat: 35.129353,
    stop_lon: -89.990727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3365,
    stop_code: "COOCOWSF",
    stop_name: "COOPER@COWDEN",
    stop_desc: "S COOPER ST & COWDEN AVE",
    stop_lat: 35.127852,
    stop_lon: -89.990806,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3366,
    stop_code: "COOHIGSN",
    stop_name: "COOPER@HIGBEE",
    stop_desc: "S COOPER ST & HIGBEE AVE",
    stop_lat: 35.127262,
    stop_lon: -89.990774,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3367,
    stop_code: "COOCENSN",
    stop_name: "COOPER@CENTRAL AVE",
    stop_desc: "S COOPER ST & CENTRAL AVE",
    stop_lat: 35.126252,
    stop_lon: -89.990874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3368,
    stop_code: "COOELZSN",
    stop_name: "COOPER@ELZEY AVE",
    stop_desc: "S COOPER ST & ELZEY AVE",
    stop_lat: 35.123822,
    stop_lon: -89.990976,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3369,
    stop_code: "COONELSN",
    stop_name: "COOPER@NELSON AVE",
    stop_desc: "S COOPER ST & NELSON AVE",
    stop_lat: 35.121947,
    stop_lon: -89.991023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3370,
    stop_code: "COOOLISF",
    stop_name: "COOPER@OLIVER AVE",
    stop_desc: "S COOPER ST & OLIVER AVE",
    stop_lat: 35.12056,
    stop_lon: -89.991146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3375,
    stop_code: "YOUBLYEM",
    stop_name: "YOUNG@BLYTHE",
    stop_desc: "YOUNG AVE & BLYTHE ST",
    stop_lat: 35.119576,
    stop_lon: -89.990527,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3376,
    stop_code: "YOUMEDEF",
    stop_name: "YOUNG@MEDA",
    stop_desc: "YOUNG AVE & MEDA ST",
    stop_lat: 35.119539,
    stop_lon: -89.988602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3377,
    stop_code: "YOUNEWEN",
    stop_name: "YOUNG@NEW YORK ST",
    stop_desc: "YOUNG AVE & NEW YORK ST",
    stop_lat: 35.119503,
    stop_lon: -89.987307,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3379,
    stop_code: "YOUEPAWF",
    stop_name: "YOUNG@E PARKWAY",
    stop_desc: "",
    stop_lat: 35.119519,
    stop_lon: -89.984928,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3380,
    stop_code: "YOUNEWWN",
    stop_name: "YOUNG@NEW YORK ST",
    stop_desc: "YOUNG AVE & NEW YORK ST",
    stop_lat: 35.119586,
    stop_lon: -89.987088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3381,
    stop_code: "YOUBLYWM",
    stop_name: "YOUNG@BLYTHE ST",
    stop_desc: "YOUNG AVE & BLYTHE ST",
    stop_lat: 35.119719,
    stop_lon: -89.989454,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3382,
    stop_code: "COONELNM",
    stop_name: "COOPER@NELSON AVE",
    stop_desc: "S COOPER ST & OLIVER AVE",
    stop_lat: 35.121167,
    stop_lon: -89.990913,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3383,
    stop_code: "COOEVENN",
    stop_name: "COOPER@EVELYN",
    stop_desc: "S COOPER ST & EVELYN AVE",
    stop_lat: 35.122544,
    stop_lon: -89.990845,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3384,
    stop_code: "COOELZNN",
    stop_name: "COOPER@ELZEY",
    stop_desc: "S COOPER ST & ELZEY AVE",
    stop_lat: 35.123553,
    stop_lon: -89.990788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3385,
    stop_code: "COOCENNN",
    stop_name: "COOPER@CENTRAL",
    stop_desc: "S COOPER ST & CENTRAL AVE",
    stop_lat: 35.125716,
    stop_lon: -89.990687,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3386,
    stop_code: "COOHIGNF",
    stop_name: "COOPER@HIGBEE",
    stop_desc: "S COOPER ST & HIGBEE AVE",
    stop_lat: 35.127351,
    stop_lon: -89.99063,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3387,
    stop_code: "COOCOWNN",
    stop_name: "COOPER@COWDEN",
    stop_desc: "S COOPER ST & COWDEN AVE",
    stop_lat: 35.128317,
    stop_lon: -89.990573,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3388,
    stop_code: "COOHARNN",
    stop_name: "COOPER@HARBERT AVE",
    stop_desc: "S COOPER ST & HARBERT AVE",
    stop_lat: 35.129148,
    stop_lon: -89.990539,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3389,
    stop_code: "COOPEANN",
    stop_name: "COOPER@PEABODY",
    stop_desc: "S COOPER ST & PEABODY AVE",
    stop_lat: 35.131534,
    stop_lon: -89.990415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3390,
    stop_code: "COOUNINN",
    stop_name: "COOPER@UNION",
    stop_desc: "160 S COOPER ST & UNION AVE",
    stop_lat: 35.13392,
    stop_lon: -89.990324,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3391,
    stop_code: "COOMONNF",
    stop_name: "COOPER@MONROE",
    stop_desc: "S COOPER ST & MONROE AVE",
    stop_lat: 35.135296,
    stop_lon: -89.990256,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3432,
    stop_code: "SOUEPAEF",
    stop_name: "SOUTHERN@E PARKWAY",
    stop_desc: "SOUTHERN AVE & E PARKWAY S",
    stop_lat: 35.115872,
    stop_lon: -89.983859,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3433,
    stop_code: "SOUEAREN",
    stop_name: "SOUTHERN@EARLY MAXWELL",
    stop_desc: "SOUTHERN AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.11587,
    stop_lon: -89.98096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3434,
    stop_code: "SOUHOLEM",
    stop_name: "SOUTHERN@HOLLYWOOD",
    stop_desc: "SOUTHERN AVE & S HOLMES ST",
    stop_lat: 35.115778,
    stop_lon: -89.978504,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3435,
    stop_code: "SOUHOLEN",
    stop_name: "SOUTHERN@HOLLYWOOD",
    stop_desc: "SOUTHERN AVE & S HOLLYWOOD ST",
    stop_lat: 35.115714,
    stop_lon: -89.976358,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3436,
    stop_code: "SOUBOSEF",
    stop_name: "SOUTHERN@BOSTON",
    stop_desc: "SOUTHERN AVE & BALTIMORE ST",
    stop_lat: 35.115631,
    stop_lon: -89.973094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3437,
    stop_code: "SOUBUNEN",
    stop_name: "SOUTHERN@BUNTYN",
    stop_desc: "SOUTHERN AVE & BUNTYN ST",
    stop_lat: 35.115612,
    stop_lon: -89.971899,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3438,
    stop_code: "SOUMAREF",
    stop_name: "SOUTHERN@MARIANNA",
    stop_desc: "SOUTHERN AVE & JOSEPHINE ST",
    stop_lat: 35.115566,
    stop_lon: -89.970583,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3439,
    stop_code: "SOUJOSEF",
    stop_name: "SOUTHERN@JOSEPHINE",
    stop_desc: "SOUTHERN AVE & JOSEPHINE ST",
    stop_lat: 35.115539,
    stop_lon: -89.969521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3440,
    stop_code: "SOULUNEN",
    stop_name: "SOUTHERN@LUNDEE",
    stop_desc: "SOUTHERN AVE & LUNDEE ST",
    stop_lat: 35.115511,
    stop_lon: -89.968392,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3441,
    stop_code: "SOUHAYEN",
    stop_name: "SOUTHERN@HAYNES",
    stop_desc: "SOUTHERN AVE & HAYNES ST",
    stop_lat: 35.115492,
    stop_lon: -89.966732,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3442,
    stop_code: "SOUHAYEF",
    stop_name: "SOUTHERN@HAYNES",
    stop_desc: "SOUTHERN AVE & GOODWYN ST",
    stop_lat: 35.115472,
    stop_lon: -89.965283,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3443,
    stop_code: "SOUGOOEM",
    stop_name: "SOUTHERN@GOODWYN",
    stop_desc: "SOUTHERN AVE & GOODWYN ST",
    stop_lat: 35.115453,
    stop_lon: -89.963657,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3444,
    stop_code: "SOUGOOEN",
    stop_name: "SOUTHERN AVE@GOODWYN ST",
    stop_desc: "",
    stop_lat: 35.115452,
    stop_lon: -89.962705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3445,
    stop_code: "SOUSEMEF",
    stop_name: "SOUTHERN@SEMMES",
    stop_desc: "SOUTHERN AVE & SEMMES ST",
    stop_lat: 35.115433,
    stop_lon: -89.96088,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3446,
    stop_code: "SOUTEREF",
    stop_name: "SOUTHERN@TERRELL",
    stop_desc: "SOUTHERN AVE & TERRELL PL",
    stop_lat: 35.115359,
    stop_lon: -89.958401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3447,
    stop_code: "SOUCLUEN",
    stop_name: "SOUTHERN AVE@CLUB WALK",
    stop_desc: "SOUTHERN AVE & CLUB WALK ST",
    stop_lat: 35.115322,
    stop_lon: -89.956731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3448,
    stop_code: "SOUGREEN",
    stop_name: "SOUTHERN@GREER",
    stop_desc: "SOUTHERN AVE & S GREER ST",
    stop_lat: 35.115266,
    stop_lon: -89.954408,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3449,
    stop_code: "SOUHO1EN",
    stop_name: "SOUTHERN@HOLMES",
    stop_desc: "SOUTHERN AVE & S HOLMES ST",
    stop_lat: 35.115238,
    stop_lon: -89.95339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3450,
    stop_code: "SOUALEEF",
    stop_name: "SOUTHERN@ALEXANDER",
    stop_desc: "SOUTHERN AVE & ALEXANDER ST",
    stop_lat: 35.1152,
    stop_lon: -89.951509,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3451,
    stop_code: "SOUREEEN",
    stop_name: "SOUTHERN@REESE",
    stop_desc: "SOUTHERN AVE & S REESE ST",
    stop_lat: 35.115181,
    stop_lon: -89.950436,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3452,
    stop_code: "SOUPREEN",
    stop_name: "SOUTHERN@PRESCOTT",
    stop_desc: "SOUTHERN AVE & S REESE ST",
    stop_lat: 35.115144,
    stop_lon: -89.948975,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3453,
    stop_code: "SOUELLEF",
    stop_name: "SOUTHERN@ELLSWORTH",
    stop_desc: "SOUTHERN AVE & EARLY MAXWELL BLVD",
    stop_lat: 35.11516,
    stop_lon: -89.946851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3456,
    stop_code: "WALPATEF",
    stop_name: "WALKER AVE@PATTERSON",
    stop_desc: "WALKER AVE & PATTERSON ST",
    stop_lat: 35.116422,
    stop_lon: -89.940211,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3459,
    stop_code: "ZACDESNF",
    stop_name: "ZACH H CURLIN@DESOTO",
    stop_desc: "ZACK H CURLIN ST & DESOTO AVE",
    stop_lat: 35.120043,
    stop_lon: -89.933676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3460,
    stop_code: "ZACCENSF",
    stop_name: "ZACH H CURLIN@CENTRAL AVE",
    stop_desc: "ZACK H CURLIN ST & CENTRAL AVE",
    stop_lat: 35.122161,
    stop_lon: -89.933816,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3461,
    stop_code: "ZACCENNN",
    stop_name: "ZACH H CURLIN@CENTRAL AVE",
    stop_desc: "ZACK H CURLIN ST & CENTRAL AVE",
    stop_lat: 35.122518,
    stop_lon: -89.933605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3462,
    stop_code: "CENGRAEN",
    stop_name: "CENTRAL@GRANDVIEW ST",
    stop_desc: "",
    stop_lat: 35.122792,
    stop_lon: -89.931292,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3463,
    stop_code: "CENROSEM",
    stop_name: "CENTRAL@ROSELAND",
    stop_desc: "",
    stop_lat: 35.122746,
    stop_lon: -89.929832,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3465,
    stop_code: "CENROSWN",
    stop_name: "CENTRAL AVE@ROSELAND",
    stop_desc: "",
    stop_lat: 35.122735,
    stop_lon: -89.928371,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3466,
    stop_code: "CENGRAWN",
    stop_name: "CENTRAL AVE@GRANDVIEW",
    stop_desc: "",
    stop_lat: 35.12281,
    stop_lon: -89.93106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3467,
    stop_code: "ZACDESSN",
    stop_name: "ZACH H CURLIN@DESOTO",
    stop_desc: "ZACK H CURLIN ST & NORRISWOOD AVE",
    stop_lat: 35.120132,
    stop_lon: -89.933875,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3468,
    stop_code: "ZACDESSF",
    stop_name: "ZACH H CURLIN@DESOTO",
    stop_desc: "ZACK H CURLIN ST & DESOTO AVE",
    stop_lat: 35.11956,
    stop_lon: -89.933887,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3469,
    stop_code: "ZACWALSM",
    stop_name: "ZACH H CURLIN@WALKER",
    stop_desc: "ZACK H CURLIN ST & DESOTO AVE",
    stop_lat: 35.118032,
    stop_lon: -89.933901,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3473,
    stop_code: "WALPATWF",
    stop_name: "WALKER@PATTERSON",
    stop_desc: "? WALKER AVE & PATTERSON ST",
    stop_lat: 35.116602,
    stop_lon: -89.941339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3476,
    stop_code: "SOUELLWN",
    stop_name: "SOUTHERN AVE@ELLSWORTH ST",
    stop_desc: "SOUTHERN AVE & S PRESCOTT ST",
    stop_lat: 35.11525,
    stop_lon: -89.94809,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3477,
    stop_code: "SOUREEWN",
    stop_name: "SOUTHERN AVE@REESE",
    stop_desc: "SOUTHERN AVE & S PRESCOTT ST",
    stop_lat: 35.115333,
    stop_lon: -89.950092,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3478,
    stop_code: "SOUALEWN",
    stop_name: "SOUTHERN@ALEXANDER ST",
    stop_desc: "SOUTHERN AVE & ALEXANDER ST",
    stop_lat: 35.115361,
    stop_lon: -89.951487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3479,
    stop_code: "SOUCLUWN",
    stop_name: "SOUTHERN AVE@CLUB WALK",
    stop_desc: "",
    stop_lat: 35.115473,
    stop_lon: -89.956509,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3480,
    stop_code: "SOUTERWN",
    stop_name: "SOUTHERN AVE@TERRELL PL",
    stop_desc: "SOUTHERN AVE & COUNTRY CLUB LN",
    stop_lat: 35.11552,
    stop_lon: -89.95828,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3481,
    stop_code: "SOUGOOWF",
    stop_name: "SOUTHERN AVE@GOODWYN",
    stop_desc: "SOUTHERN AVE & GOODWYN ST",
    stop_lat: 35.115604,
    stop_lon: -89.962296,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3482,
    stop_code: "SOUHAYWN",
    stop_name: "SOUTHERN AVE@HAYNES",
    stop_desc: "SOUTHERN AVE & HAYNES ST",
    stop_lat: 35.115652,
    stop_lon: -89.966434,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3483,
    stop_code: "SOULUNWN",
    stop_name: "SOUTHERN@LUNDEE",
    stop_desc: "SOUTHERN AVE & LUNDEE ST",
    stop_lat: 35.115663,
    stop_lon: -89.968082,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3484,
    stop_code: "SOUJOSWN",
    stop_name: "SOUTHERN AVE@JOSEPHINE",
    stop_desc: "SOUTHERN AVE & JOSEPHINE ST",
    stop_lat: 35.1157,
    stop_lon: -89.969542,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3485,
    stop_code: "SOUMARWN",
    stop_name: "SOUTHERN AVE@MARIANNA ST",
    stop_desc: "THE HOLLY LN & JOSEPHINE ST",
    stop_lat: 35.115727,
    stop_lon: -89.970649,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3486,
    stop_code: "SOUBUNWM",
    stop_name: "SOUTHERN@BUNTYN ST",
    stop_desc: "SOUTHERN AVE & BUNTYN ST",
    stop_lat: 35.115755,
    stop_lon: -89.971379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3487,
    stop_code: "SOUBOSWN",
    stop_name: "SOUTHERN@BOSTON ST",
    stop_desc: "SOUTHERN AVE & BALTIMORE ST",
    stop_lat: 35.115792,
    stop_lon: -89.973315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3488,
    stop_code: "SOUHOLWN",
    stop_name: "SOUTHERN@HOLLYWOOD",
    stop_desc: "SOUTHERN AVE & BELT LINE ST",
    stop_lat: 35.115875,
    stop_lon: -89.975993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3489,
    stop_code: "CENCOOEF",
    stop_name: "CENTRAL AVE@COOPER",
    stop_desc: "CENTRAL AVE & S COOPER ST",
    stop_lat: 35.125939,
    stop_lon: -89.990266,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3490,
    stop_code: "CENMEDEF",
    stop_name: "CENTRAL AVE@MEDA",
    stop_desc: "CENTRAL AVE & MEDA ST",
    stop_lat: 35.125893,
    stop_lon: -89.988562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3491,
    stop_code: "CENNEWEF",
    stop_name: "CENTRAL AVE@NEW YORK",
    stop_desc: "CENTRAL AVE & NEW YORK ST",
    stop_lat: 35.125865,
    stop_lon: -89.986968,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3492,
    stop_code: "CENLANEF",
    stop_name: "CENTRAL AVE@LANDIS",
    stop_desc: "CENTRAL AVE & E S",
    stop_lat: 35.125882,
    stop_lon: -89.985408,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3493,
    stop_code: "CENFLIEM",
    stop_name: "CENTRAL AVE@FLICKER ST",
    stop_desc: "",
    stop_lat: 35.125722,
    stop_lon: -89.974708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3494,
    stop_code: "CENMILEN",
    stop_name: "CENTRAL AVE@MILTON",
    stop_desc: "CENTRAL AVE & S HOLLYWOOD ST",
    stop_lat: 35.124022,
    stop_lon: -89.971877,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3495,
    stop_code: "CENJOSEN",
    stop_name: "CENTRAL AVE@JOSEPHINE ST",
    stop_desc: "",
    stop_lat: 35.123752,
    stop_lon: -89.969576,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3496,
    stop_code: "CENFENEN",
    stop_name: "CENTRAL AVE@FENWICK RD",
    stop_desc: "CENTRAL AVE & S FENWICK RD",
    stop_lat: 35.123687,
    stop_lon: -89.967374,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3497,
    stop_code: "CENTAREN",
    stop_name: "CENTRAL AVE@TARA LN",
    stop_desc: "",
    stop_lat: 35.123622,
    stop_lon: -89.964265,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3498,
    stop_code: "CENGOOEN",
    stop_name: "CENTRAL AVE@GOODWYN",
    stop_desc: "CENTRAL AVE & GOODWYN ST",
    stop_lat: 35.123585,
    stop_lon: -89.962495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3499,
    stop_code: "CENTILEF",
    stop_name: "CENTRAL AVE@TILTON",
    stop_desc: "CENTRAL AVE & TILTON ST",
    stop_lat: 35.123511,
    stop_lon: -89.960448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3500,
    stop_code: "CENLAFEF",
    stop_name: "LAFAYETTE ST@ CENTRAL",
    stop_desc: "",
    stop_lat: 35.123527,
    stop_lon: -89.958567,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3501,
    stop_code: "CENGREEF",
    stop_name: "CENTRAL AVE@GREER",
    stop_desc: "CENTRAL AVE & S HOLMES ST",
    stop_lat: 35.12338,
    stop_lon: -89.953765,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3502,
    stop_code: "CENALEEN",
    stop_name: "CENTRAL AVE@ALEXANDER ST",
    stop_desc: "",
    stop_lat: 35.123315,
    stop_lon: -89.951718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3503,
    stop_code: "CENPREEN",
    stop_name: "CENTRAL AVE@PRESCOTT ST",
    stop_desc: "CENTRAL AVE & S PRESCOTT ST",
    stop_lat: 35.123241,
    stop_lon: -89.948697,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3504,
    stop_code: "CENHIGEF",
    stop_name: "CENTRAL AVE@HIGHLAND",
    stop_desc: "CENTRAL AVE & CENTRAL CV",
    stop_lat: 35.123129,
    stop_lon: -89.944957,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3505,
    stop_code: "CENPATEM",
    stop_name: "CENTRAL AVE@PATTERSON",
    stop_desc: "CENTRAL AVE & PATTERSON ST",
    stop_lat: 35.123055,
    stop_lon: -89.942612,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3506,
    stop_code: "CENPATEN",
    stop_name: "CENTRAL AVE@PATTERSON",
    stop_desc: "CENTRAL AVE & PATTERSON ST",
    stop_lat: 35.123018,
    stop_lon: -89.940974,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3514,
    stop_code: "CENPATWF",
    stop_name: "CENTRAL AVE@PATTERSON",
    stop_desc: "CENTRAL AVE & PATTERSON ST",
    stop_lat: 35.123188,
    stop_lon: -89.941483,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3515,
    stop_code: "CENHIGWN",
    stop_name: "CENTRAL AVE@HIGHLAND",
    stop_desc: "CENTRAL AVE & CENTRAL CV",
    stop_lat: 35.1233,
    stop_lon: -89.945256,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3516,
    stop_code: "CENWINWN",
    stop_name: "CENTRAL AVE@WINDOVER RD",
    stop_desc: "",
    stop_lat: 35.123392,
    stop_lon: -89.948354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3517,
    stop_code: "CENALEWF",
    stop_name: "CENTRAL AVE@ALEXANDER",
    stop_desc: "CENTRAL AVE & ALEXANDER ST",
    stop_lat: 35.123467,
    stop_lon: -89.951463,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3519,
    stop_code: "CENLAFWN",
    stop_name: "CENTRAL AVE@LAFAYETTE ST",
    stop_desc: "",
    stop_lat: 35.123626,
    stop_lon: -89.9586,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3520,
    stop_code: "CENGOOWN",
    stop_name: "CENTRAL AVE@GOODWYN",
    stop_desc: "CENTRAL AVE & GOODWYN ST",
    stop_lat: 35.123718,
    stop_lon: -89.962196,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3521,
    stop_code: "CENTARWF",
    stop_name: "CENTRAL AVE@TARA",
    stop_desc: "CENTRAL AVE & TARA LN",
    stop_lat: 35.123765,
    stop_lon: -89.964298,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3522,
    stop_code: "CENFENWN",
    stop_name: "CENTRAL AVE@FENWICK RD",
    stop_desc: "CENTRAL AVE & S FENWICK RD",
    stop_lat: 35.123839,
    stop_lon: -89.967031,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3523,
    stop_code: "CENJOSWF",
    stop_name: "CENTRAL AVE@JOSEPHINE",
    stop_desc: "CENTRAL AVE & JOSEPHINE ST",
    stop_lat: 35.123913,
    stop_lon: -89.969576,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3524,
    stop_code: "CENBUNWN",
    stop_name: "CENTRAL AVE@BUNTYN",
    stop_desc: "CENTRAL AVE & JOSEPHINE ST",
    stop_lat: 35.123941,
    stop_lon: -89.971037,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3525,
    stop_code: "CENHOLWN",
    stop_name: "CENTRAL AVE@HOLLYWOOD",
    stop_desc: "",
    stop_lat: 35.125732,
    stop_lon: -89.975715,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3526,
    stop_code: "CENEPAWF",
    stop_name: "CENTRAL AVE@PARKWAY",
    stop_desc: "CENTRAL AVE & E PARKWAY S",
    stop_lat: 35.126016,
    stop_lon: -89.984733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3527,
    stop_code: "CENPHIWN",
    stop_name: "CENTRAL AVE@PHILADELPHIA",
    stop_desc: "CENTRAL AVE & PHILADELPHIA ST",
    stop_lat: 35.126017,
    stop_lon: -89.98584,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3528,
    stop_code: "CENNEWWN",
    stop_name: "CENTRAL AVE@NEW YORK",
    stop_desc: "CENTRAL AVE & NEW YORK ST",
    stop_lat: 35.126026,
    stop_lon: -89.987035,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3529,
    stop_code: "CENBLYWM",
    stop_name: "CENTRAL AVE@BLYTHE",
    stop_desc: "CENTRAL AVE & BLYTHE ST",
    stop_lat: 35.126072,
    stop_lon: -89.989026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3530,
    stop_code: "CENCOOWN",
    stop_name: "CENTRAL AVE@COOPER",
    stop_desc: "CENTRAL AVE & S COOPER ST",
    stop_lat: 35.126091,
    stop_lon: -89.990177,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3531,
    stop_code: "HOLHOLNM",
    stop_name: "HOLLYWOOD@HOLLINS",
    stop_desc: "N HOLLYWOOD ST & CENTRAL AVE",
    stop_lat: 35.1409,
    stop_lon: -89.978607,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3532,
    stop_code: "HOLHOLNN",
    stop_name: "HOLLYWOOD@HOLLINS",
    stop_desc: "N HOLLYWOOD ST & HOLLINS AVE",
    stop_lat: 35.14216,
    stop_lon: -89.978418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3533,
    stop_code: "HOLYALNN",
    stop_name: "HOLLYWOOD@YALE AVE",
    stop_desc: "N HOLLYWOOD ST & YALE AVE",
    stop_lat: 35.144278,
    stop_lon: -89.978105,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3534,
    stop_code: "HOLAUTNN",
    stop_name: "HOLLYWOOD@AUTUMN",
    stop_desc: "N HOLLYWOOD ST & PRINCETON AVE",
    stop_lat: 35.146896,
    stop_lon: -89.977726,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3535,
    stop_code: "HOLFORNN",
    stop_name: "HOLLYWOOD@FORREST",
    stop_desc: "N HOLLYWOOD ST & FORREST AVE",
    stop_lat: 35.149916,
    stop_lon: -89.976848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3536,
    stop_code: "SUMTREWN",
    stop_name: "SUMMER@TREZEVANT",
    stop_desc: "STATE HWY 1 & E PARKWAY N",
    stop_lat: 35.151018,
    stop_lon: -89.98082,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3538,
    stop_code: "NPAWESWN",
    stop_name: "N PARKWAY@WEST DR",
    stop_desc: "US HWY 64 & N PARKWAY",
    stop_lat: 35.151593,
    stop_lon: -89.985734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3539,
    stop_code: "NPAUNIWM",
    stop_name: "N PARKWAY@UNIVERSITY",
    stop_desc: "N PKWY & US HWY 64",
    stop_lat: 35.1518,
    stop_lon: -89.987881,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3540,
    stop_code: "NPAUNIWN",
    stop_name: "N PARKWAY@UNIVERSITY",
    stop_desc: "N PKWY & UNIVERSITY ST",
    stop_lat: 35.15217,
    stop_lon: -89.991777,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3541,
    stop_code: "NPAUNIWF",
    stop_name: "N PARKWAY@UNIVERSITY",
    stop_desc: "N PKWY & N PARKWAY",
    stop_lat: 35.152341,
    stop_lon: -89.993648,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3542,
    stop_code: "NPAMCLWN",
    stop_name: "N PARKWAY@MC LEAN",
    stop_desc: "1860 US HWY 64 & N MCLEAN BLVD",
    stop_lat: 35.152638,
    stop_lon: -89.996935,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3543,
    stop_code: "NPAHAWWN",
    stop_name: "N PARKWAY@HAWTHORNE ST",
    stop_desc: "N PKWY & HAWTHORNE ST",
    stop_lat: 35.152889,
    stop_lon: -89.999426,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3544,
    stop_code: "NPAEVEWN",
    stop_name: "N PARKWAY@EVERGREEN",
    stop_desc: "US HWY 64 & N EVERGREEN ST",
    stop_lat: 35.153123,
    stop_lon: -90.001805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3557,
    stop_code: "NPAWALWF",
    stop_name: "N PARKWAY@WALDRAN",
    stop_desc: "N PKWY & N WALDRAN BLVD",
    stop_lat: 35.154715,
    stop_lon: -90.022703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3558,
    stop_code: "NPADECWF",
    stop_name: "N PARKWAY@DECATUR",
    stop_desc: "N PKWY & DECATUR ST",
    stop_lat: 35.154975,
    stop_lon: -90.025692,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3559,
    stop_code: "NPAAYEWN",
    stop_name: "N PARKWAY@AYERS",
    stop_desc: "N PKWY & AYERS ST",
    stop_lat: 35.1552,
    stop_lon: -90.028194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3560,
    stop_code: "NPALEAWM",
    stop_name: "N PARKWAY@LEATH",
    stop_desc: "N PKWY & N DUNLAP ST",
    stop_lat: 35.155487,
    stop_lon: -90.031625,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3561,
    stop_code: "NPANMAWF",
    stop_name: "N PARKWAY@MANASSAS",
    stop_desc: "US HWY 64 & N PARKWAY",
    stop_lat: 35.15555,
    stop_lon: -90.033983,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3562,
    stop_code: "NPAPEYWM",
    stop_name: "N PARKWAY@PEYTON",
    stop_desc: "N PKWY & PEYTON ST",
    stop_lat: 35.155613,
    stop_lon: -90.03633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3566,
    stop_code: "NPATHIWN",
    stop_name: "N PARKWAY@THIRD ST",
    stop_desc: "N PARKWAY & N 3RD ST",
    stop_lat: 35.155517,
    stop_lon: -90.045794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3570,
    stop_code: "NPAPEYEF",
    stop_name: "N PARKWAY@PEYTON",
    stop_desc: "US HWY 64 & DUNSCOMB PL",
    stop_lat: 35.15547,
    stop_lon: -90.036518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3571,
    stop_code: "NPANMAEN",
    stop_name: "N PARKWAY@MANASSAS ST",
    stop_desc: "US HWY 64 & N MANASSAS ST",
    stop_lat: 35.155398,
    stop_lon: -90.033695,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3572,
    stop_code: "NPAAYEEN",
    stop_name: "N PARKWAY@AYERS ST",
    stop_desc: "US HWY 64 & AYERS ST",
    stop_lat: 35.154896,
    stop_lon: -90.028858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3574,
    stop_code: "NPADECEN",
    stop_name: "N PARKWAY@DECATUR",
    stop_desc: "US HWY 64 & DECATUR ST",
    stop_lat: 35.154564,
    stop_lon: -90.025283,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3575,
    stop_code: "NPANWAEN",
    stop_name: "N PARKWAY@WALDRAN BLVD",
    stop_desc: "US HWY 64 & N PARKWAY",
    stop_lat: 35.15434,
    stop_lon: -90.022781,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3598,
    stop_code: "WATFAXNN",
    stop_name: "WATKINS@FAXON",
    stop_desc: "N WATKINS ST & FAXON AVE",
    stop_lat: 35.154755,
    stop_lon: -90.012187,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3606,
    stop_code: "NPAHAWEN",
    stop_name: "N PARKWAY@HAWTHORNE",
    stop_desc: "US HWY 64 & HAWTHORNE ST",
    stop_lat: 35.152612,
    stop_lon: -89.999736,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3607,
    stop_code: "NPAMCLEN",
    stop_name: "N PARKWAY@MC LEAN",
    stop_desc: "N PKWY & N MCLEAN BLVD",
    stop_lat: 35.152388,
    stop_lon: -89.99771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3608,
    stop_code: "NPAUNIEM",
    stop_name: "N PARKWAY@UNIVERSITY",
    stop_desc: "N PKWY & N PARKWAY",
    stop_lat: 35.152046,
    stop_lon: -89.994423,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3610,
    stop_code: "NPAWESEM",
    stop_name: "N PARKWAY@WEST DR",
    stop_desc: "US HWY 64 & CONNECTING RD",
    stop_lat: 35.151416,
    stop_lon: -89.987849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3612,
    stop_code: "SUMEPAEF",
    stop_name: "SUMMER@E PARKWAY",
    stop_desc: "STATE HWY 1 & E PARKWAY N",
    stop_lat: 35.150812,
    stop_lon: -89.980466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3613,
    stop_code: "SUMMCCEF",
    stop_name: "SUMMER@McCONNELL ST",
    stop_desc: "STATE HWY 1 & MCCONNELL ST",
    stop_lat: 35.150632,
    stop_lon: -89.978883,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3614,
    stop_code: "NHOSUMNF",
    stop_name: "N HOLLYWOOD@SUMMER",
    stop_desc: "N HOLLYWOOD ST & SUMMER AVE",
    stop_lat: 35.15097,
    stop_lon: -89.976902,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3615,
    stop_code: "NHOPERNN",
    stop_name: "N HOLLYWOOD@PERSHING AVE",
    stop_desc: "N HOLLYWOOD ST & PERSHING AVE",
    stop_lat: 35.154518,
    stop_lon: -89.976443,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3616,
    stop_code: "NHOJACNN",
    stop_name: "NHOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & JACKSON AVE",
    stop_lat: 35.157681,
    stop_lon: -89.975986,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3617,
    stop_code: "NHOJACNF",
    stop_name: "NHOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & DANA DR",
    stop_lat: 35.160612,
    stop_lon: -89.975728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3618,
    stop_code: "NHODANNF",
    stop_name: "N HOLLYWOOD@DANA",
    stop_desc: "N HOLLYWOOD ST & DANA DR",
    stop_lat: 35.162077,
    stop_lon: -89.975681,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3619,
    stop_code: "NHOSTANN",
    stop_name: "N HOLLYWOOD@STATEN",
    stop_desc: "N HOLLYWOOD ST & STATEN AVE",
    stop_lat: 35.165616,
    stop_lon: -89.975589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3620,
    stop_code: "NHOVANNN",
    stop_name: "N HOLLYWOOD@VANDALE AVE",
    stop_desc: "N HOLLYWOOD ST & WINNONA AVE",
    stop_lat: 35.167251,
    stop_lon: -89.975553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3621,
    stop_code: "NHOSHANN",
    stop_name: "N HOLLYWOOD@SHASTA",
    stop_desc: "N HOLLYWOOD ST & SHASTA AVE",
    stop_lat: 35.168663,
    stop_lon: -89.975529,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3622,
    stop_code: "NHOELDNN",
    stop_name: "N HOLLYWOOD@ELDRIDGE",
    stop_desc: "N HOLLYWOOD ST & ELDRIDGE AVE",
    stop_lat: 35.170209,
    stop_lon: -89.975483,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3623,
    stop_code: "NHOGOLNN",
    stop_name: "N HOLLYWOOD@GOLDEN",
    stop_desc: "N HOLLYWOOD ST & GOLDEN AVE",
    stop_lat: 35.172899,
    stop_lon: -89.975391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3624,
    stop_code: "NHOHUBNN",
    stop_name: "N HOLLYWOOD@HUBBARD",
    stop_desc: "N HOLLYWOOD ST & HUBBARD AVE",
    stop_lat: 35.173587,
    stop_lon: -89.975391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3656,
    stop_code: "HOLHUBSN",
    stop_name: "HOLLYWOOD@HUBBARD",
    stop_desc: "N HOLLYWOOD ST & HUBBARD AVE",
    stop_lat: 35.173775,
    stop_lon: -89.97559,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3657,
    stop_code: "HOLGOLSN",
    stop_name: "HOLLYWOOD@GOLDEN",
    stop_desc: "N HOLLYWOOD ST & GOLDEN AVE",
    stop_lat: 35.173016,
    stop_lon: -89.975591,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3658,
    stop_code: "HOLSHASN",
    stop_name: "HOLLYWOOD@SHASTA",
    stop_desc: "N HOLLYWOOD ST & SHASTA AVE",
    stop_lat: 35.169128,
    stop_lon: -89.975717,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3659,
    stop_code: "HOLVANSF",
    stop_name: "HOLLYWOOD@VANDALE AVE",
    stop_desc: "0 N HOLLYWOOD ST & WINNONA AVE",
    stop_lat: 35.167252,
    stop_lon: -89.975719,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3660,
    stop_code: "HOLSTASN",
    stop_name: "HOLLYWOOD@STATEN",
    stop_desc: "N HOLLYWOOD ST & GENTRY AVE",
    stop_lat: 35.165884,
    stop_lon: -89.975776,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3661,
    stop_code: "HOLDEVSN",
    stop_name: "HOLLYWOOD@DEVOY",
    stop_desc: "N HOLLYWOOD ST & DEVOY AVE",
    stop_lat: 35.165018,
    stop_lon: -89.975811,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3662,
    stop_code: "HOLVOLSN",
    stop_name: "HOLLYWOOD@VOLLINTINE AVE",
    stop_desc: "N HOLLYWOOD ST & VOLLINTINE AVE",
    stop_lat: 35.16332,
    stop_lon: -89.975846,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3663,
    stop_code: "HOLDANSF",
    stop_name: "HOLLYWOOD@DANA",
    stop_desc: "N HOLLYWOOD ST & DANA DR",
    stop_lat: 35.161756,
    stop_lon: -89.975903,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3665,
    stop_code: "HOLJACSF",
    stop_name: "HOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & JACKSON AVE",
    stop_lat: 35.157511,
    stop_lon: -89.976218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3666,
    stop_code: "HOLPE1SN",
    stop_name: "HOLLYWOOD@PERSHING",
    stop_desc: "N HOLLYWOOD ST & PERSHING AVE",
    stop_lat: 35.154866,
    stop_lon: -89.976598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3667,
    stop_code: "HOLFAXSF",
    stop_name: "HOLLYWOOD@FAXON",
    stop_desc: "N HOLLYWOOD ST & FAXON AVE",
    stop_lat: 35.151292,
    stop_lon: -89.9771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3712,
    stop_code: "NHOSUMSF",
    stop_name: "N HOLLYWOOD@SUMMER",
    stop_desc: "N HOLLYWOOD ST & SUMMER AVE",
    stop_lat: 35.150193,
    stop_lon: -89.977046,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3713,
    stop_code: "NHOBROSN",
    stop_name: "N HOLLYWOOD@BROAD",
    stop_desc: "N HOLLYWOOD ST & BROAD AVE",
    stop_lat: 35.148379,
    stop_lon: -89.977281,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3714,
    stop_code: "NHOPRISN",
    stop_name: "N HOLLYWOOD@PRINCETON",
    stop_desc: "N HOLLYWOOD ST & PRINCETON AVE",
    stop_lat: 35.146762,
    stop_lon: -89.977947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3715,
    stop_code: "NHOYALSF",
    stop_name: "N HOLLYWOOD@YALE",
    stop_desc: "N HOLLYWOOD ST & YALE AVE",
    stop_lat: 35.144251,
    stop_lon: -89.978315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3716,
    stop_code: "NHOHOLSN",
    stop_name: "N HOLLYWOOD@HOLLINS",
    stop_desc: "N HOLLYWOOD ST & HALE AVE",
    stop_lat: 35.142428,
    stop_lon: -89.978583,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3717,
    stop_code: "NHOPOPSM",
    stop_name: "N HOLLYWOOD@POPLAR",
    stop_desc: "N HOLLYWOOD ST & N ALICIA DR",
    stop_lat: 35.13989,
    stop_lon: -89.978929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3719,
    stop_code: "JACHEMSN",
    stop_name: "JACKSON AVE@HEMINGWAY AVE",
    stop_desc: "STATE HWY 14 & HEMINGWAY AVE",
    stop_lat: 35.187946,
    stop_lon: -89.929015,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3720,
    stop_code: "JACBRISN",
    stop_name: "JACKSON AVE@BRIGHTON RD",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.186357,
    stop_lon: -89.930281,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3721,
    stop_code: "JACWALSN",
    stop_name: "JACKSON AVE@WALES",
    stop_desc: "4044 STATE HWY 14 & CAPE HENRY DR",
    stop_lat: 35.185634,
    stop_lon: -89.930858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3722,
    stop_code: "JACGRASM",
    stop_name: "JACKSON AVE@GRAGG AVE",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.184143,
    stop_lon: -89.932068,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3723,
    stop_code: "JACGR1SM",
    stop_name: "JACKSON @GRAGG AVE",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.182858,
    stop_lon: -89.933122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3724,
    stop_code: "JACGR2SM",
    stop_name: "JACKSON @GRAGG",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.181242,
    stop_lon: -89.934487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3725,
    stop_code: "JACGRASN",
    stop_name: "JACKSON@GRAGG",
    stop_desc: "STATE HWY 14 & GRAGG AVE",
    stop_lat: 35.179903,
    stop_lon: -89.935674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3726,
    stop_code: "JACORCSN",
    stop_name: "JACKSON AVE@ORCHI",
    stop_desc: "",
    stop_lat: 35.178132,
    stop_lon: -89.937363,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3727,
    stop_code: "JACBAYSN",
    stop_name: "JACKSON AVE@BAYLISS AVE",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.172734,
    stop_lon: -89.942662,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3728,
    stop_code: "JACMAYSN",
    stop_name: "JACKSON AVE@MAYFAIR AVE",
    stop_desc: "",
    stop_lat: 35.171449,
    stop_lon: -89.944081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3729,
    stop_code: "JACKALSN",
    stop_name: "JACKSON AVE@KALLAHER",
    stop_desc: "0 JACKSON AVE & KALLAHER AVE",
    stop_lat: 35.170548,
    stop_lon: -89.945478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3730,
    stop_code: "JACMA1SN",
    stop_name: "JACKSON AVE@MAYFLOWER",
    stop_desc: "",
    stop_lat: 35.169189,
    stop_lon: -89.947337,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3733,
    stop_code: "JACFARSN",
    stop_name: "JACKSON AVE@FARMVILLE",
    stop_desc: "",
    stop_lat: 35.166781,
    stop_lon: -89.950476,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3734,
    stop_code: "JACROSSM",
    stop_name: "JACKSON AVE@ROSEAMOND",
    stop_desc: "",
    stop_lat: 35.16555,
    stop_lon: -89.951985,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3735,
    stop_code: "JACMACSF",
    stop_name: "JACKSON AVE@MACON RD",
    stop_desc: "? JACKSON AVE & MACON RD",
    stop_lat: 35.164325,
    stop_lon: -89.95373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3736,
    stop_code: "JACHUDSN",
    stop_name: "JACKSON AVE@HUDSON",
    stop_desc: "",
    stop_lat: 35.162936,
    stop_lon: -89.955906,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3737,
    stop_code: "JACPOPSN",
    stop_name: "JACKSON AVE@POPE",
    stop_desc: "",
    stop_lat: 35.161681,
    stop_lon: -89.958524,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3738,
    stop_code: "JACPOPSF",
    stop_name: "JACKSON AVE@POPE",
    stop_desc: "3040 JACKSON AVE & POPE",
    stop_lat: 35.161392,
    stop_lon: -89.959323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3739,
    stop_code: "JACWARSM",
    stop_name: "JACKSON AVE@WARFORD",
    stop_desc: "",
    stop_lat: 35.160785,
    stop_lon: -89.961019,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3740,
    stop_code: "JACWARSN",
    stop_name: "JACKSON@WARFORD",
    stop_desc: "JACKSON AVE & WARFORD",
    stop_lat: 35.160326,
    stop_lon: -89.962312,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3741,
    stop_code: "JACBINWN",
    stop_name: "JACKSON AVE@BINGHAM",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.15824,
    stop_lon: -89.971258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3742,
    stop_code: "JACMERWM",
    stop_name: "JACKSON AVE@MERTON",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.15809,
    stop_lon: -89.973528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3743,
    stop_code: "JACMAPM",
    stop_name: "JACKSON AVE@MAPLE",
    stop_desc: "JACKSON AVE & BIRCH ST",
    stop_lat: 35.158057,
    stop_lon: -89.976937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3744,
    stop_code: "JACBARWN",
    stop_name: "JACKSON AVE@BARBARA",
    stop_desc: "JACKSON AVE & BARBARA DR",
    stop_lat: 35.158085,
    stop_lon: -89.978177,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3745,
    stop_code: "JACTREWN",
    stop_name: "JACKSON AVE@TREZEVANT",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.158131,
    stop_lon: -89.979937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3747,
    stop_code: "JACSPRWN",
    stop_name: "JACKSON AVE@SPRINGDALE",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.159045,
    stop_lon: -89.984032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3748,
    stop_code: "JACCHAWF",
    stop_name: "JACKSON AVE@CHARLES PL",
    stop_desc: "STATE HWY 14 & UNIVERSITY CIR",
    stop_lat: 35.159485,
    stop_lon: -89.986146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3749,
    stop_code: "JACUNIWM",
    stop_name: "JACKSON AVE@UNIVERSITY CIR",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.159665,
    stop_lon: -89.987973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3750,
    stop_code: "JACUNIWN",
    stop_name: "JACKSON AVE@UNIVERSITY ST",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.159917,
    stop_lon: -89.990762,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3751,
    stop_code: "JACSHEWN",
    stop_name: "JACKSON AVE@SHERIDAN",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.160088,
    stop_lon: -89.992467,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3752,
    stop_code: "JACKENWN",
    stop_name: "JACKSON AVE@KENSINGTON ST",
    stop_desc: "STATE HWY 14 & KENSINGTON PL",
    stop_lat: 35.160429,
    stop_lon: -89.995046,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3754,
    stop_code: "JACIDLWF",
    stop_name: "JACKSON AVE@IDLEWILD",
    stop_desc: "JACKSON AVE & N IDLEWILD ST",
    stop_lat: 35.160779,
    stop_lon: -89.997835,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3755,
    stop_code: "JACEVEWN",
    stop_name: "JACKSON AVE@EVERGREEN",
    stop_desc: "JACKSON AVE & PARKING LOT",
    stop_lat: 35.161049,
    stop_lon: -90.000769,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3756,
    stop_code: "JACBELWN",
    stop_name: "JACKSON AVE@BELVEDERE",
    stop_desc: "STATE HWY 14 & N BELVEDERE BLVD",
    stop_lat: 35.160782,
    stop_lon: -90.001964,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3758,
    stop_code: "JACMAUWN",
    stop_name: "JACKSON AVE@MAURY",
    stop_desc: "JACKSON AVE & MAURY ST",
    stop_lat: 35.159747,
    stop_lon: -90.005685,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3759,
    stop_code: "JACNWIWN",
    stop_name: "JACKSON AVE@NWILLETT",
    stop_desc: "STATE HWY 14 & N WILLETT ST",
    stop_lat: 35.159435,
    stop_lon: -90.006803,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3760,
    stop_code: "JACMCNWN",
    stop_name: "JACKSON AVE@MCNEIL",
    stop_desc: "STATE HWY 14 & N MCNEIL ST",
    stop_lat: 35.158828,
    stop_lon: -90.008996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3762,
    stop_code: "JACSPEWN",
    stop_name: "JACKSON AVE@SPEED ST",
    stop_desc: "STATE HWY 14 & SPEED ST",
    stop_lat: 35.158312,
    stop_lon: -90.013258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3763,
    stop_code: "JACCLAWN",
    stop_name: "JACKSON AVE@CLAYBROOK",
    stop_desc: "JACKSON AVE & N CLAYBROOK ST",
    stop_lat: 35.158429,
    stop_lon: -90.014941,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3765,
    stop_code: "JACNBEWN",
    stop_name: "JACKSON AVE@N BELLEVUE BLVD",
    stop_desc: "STATE HWY 14 & N BELLEVUE BLVD",
    stop_lat: 35.158502,
    stop_lon: -90.018705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3766,
    stop_code: "JACBREWF",
    stop_name: "JACKSON AVE@BREEDLOVE",
    stop_desc: "STATE HWY 14 & BREEDLOVE ST",
    stop_lat: 35.157414,
    stop_lon: -90.023809,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3767,
    stop_code: "JACDECWN",
    stop_name: "JACKSON AVE@DECATUR",
    stop_desc: "STATE HWY 14 & DECATUR ST",
    stop_lat: 35.157236,
    stop_lon: -90.02527,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3768,
    stop_code: "JACHASWN",
    stop_name: "JACKSON AVE@HASTINGS ST",
    stop_desc: "STATE HWY 14 & HASTINGS ST",
    stop_lat: 35.157192,
    stop_lon: -90.026632,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3769,
    stop_code: "JACAYEWN",
    stop_name: "JACKSON AVE@AYERS",
    stop_desc: "STATE HWY 14 & AYERS ST",
    stop_lat: 35.157148,
    stop_lon: -90.028049,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3770,
    stop_code: "JACPEAWN",
    stop_name: "JACKSON AVE@PEARCE ST",
    stop_desc: "STATE HWY 14 & AYERS ST",
    stop_lat: 35.157112,
    stop_lon: -90.029355,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3771,
    stop_code: "JACDUNWF",
    stop_name: "JACKSON AVE@DUNLAP",
    stop_desc: "STATE HWY 14 & N DUNLAP ST",
    stop_lat: 35.157068,
    stop_lon: -90.031093,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3772,
    stop_code: "JACMANWN",
    stop_name: "JACKSON AVE@MANASSAS",
    stop_desc: "JACKSON AVE & N MANASSAS ST",
    stop_lat: 35.157069,
    stop_lon: -90.033074,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3773,
    stop_code: "JACMCDWN",
    stop_name: "JACKSON AVE@MCDAVITT",
    stop_desc: "AUCTION AVE & MCDAVITT PL",
    stop_lat: 35.156614,
    stop_lon: -90.035665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3774,
    stop_code: "JACTHOWN",
    stop_name: "JACKSON AVE@THOMAS",
    stop_desc: "STATE HWY 14 & PEYTON ST",
    stop_lat: 35.156221,
    stop_lon: -90.037226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3775,
    stop_code: "JACDUNEN",
    stop_name: "JACKSON AVE@DUNSCOMB PL",
    stop_desc: "STATE HWY 14 & DUNSCOMB PL",
    stop_lat: 35.156435,
    stop_lon: -90.035709,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3776,
    stop_code: "JACMANEN",
    stop_name: "JACKSON AVE@MANASSAS",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.156899,
    stop_lon: -90.03365,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3777,
    stop_code: "JACLEAEN",
    stop_name: "JACKSON AVE@LEATH",
    stop_desc: "JACKSON AVE & LEATH ST",
    stop_lat: 35.156881,
    stop_lon: -90.032045,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3778,
    stop_code: "JACPEAEM",
    stop_name: "JACKSON AVE@PEARCE",
    stop_desc: "JACKSON AVE & PEARCE ST",
    stop_lat: 35.156934,
    stop_lon: -90.030185,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3779,
    stop_code: "JACAYEEN",
    stop_name: "JACKSON AVE@AYERS ST",
    stop_desc: "STATE HWY 14 & AYERS ST",
    stop_lat: 35.156969,
    stop_lon: -90.028658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3780,
    stop_code: "JACHASEN",
    stop_name: "JACKSON AVE@HASTINGS ST",
    stop_desc: "STATE HWY 14 & HASTINGS ST",
    stop_lat: 35.157022,
    stop_lon: -90.02703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3781,
    stop_code: "JACDECEN",
    stop_name: "JACKSON AVE@DECATUR",
    stop_desc: "STATE HWY 14 & DECATUR ST",
    stop_lat: 35.157084,
    stop_lon: -90.024916,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3782,
    stop_code: "JACBREEN",
    stop_name: "JACKSON AVE@BREEDLOVE",
    stop_desc: "STATE HWY 14 & BREEDLOVE ST",
    stop_lat: 35.157325,
    stop_lon: -90.023421,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3783,
    stop_code: "BRELAGNN",
    stop_name: "BREEDLOVE@LAGRANGE",
    stop_desc: "BREEDLOVE ST & LAGRANGE AVE",
    stop_lat: 35.158031,
    stop_lon: -90.023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3784,
    stop_code: "BRECOWNN",
    stop_name: "BREEDLOVE@COWETA",
    stop_desc: "BREEDLOVE ST & COWETA PL",
    stop_lat: 35.159014,
    stop_lon: -90.022867,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3785,
    stop_code: "BREPALNN",
    stop_name: "BREEDLOVE@PALMETTO",
    stop_desc: "BREEDLOVE ST & PALMETTO AVE",
    stop_lat: 35.160828,
    stop_lon: -90.022567,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3786,
    stop_code: "BREJOSNF",
    stop_name: "BREEDLOVE@JOSEPH PL",
    stop_desc: "BREEDLOVE ST & JOSEPH PL",
    stop_lat: 35.162722,
    stop_lon: -90.022244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3787,
    stop_code: "BREVOLNM",
    stop_name: "BREEDLOVE@VOLLINTINE",
    stop_desc: "BREEDLOVE ST & JOSEPH PL",
    stop_lat: 35.164545,
    stop_lon: -90.021956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3824,
    stop_code: "BREVOLSF",
    stop_name: "BREEDLOVE@VOLLINTINE",
    stop_desc: "BREEDLOVE ST & VOLLINTINE AVE",
    stop_lat: 35.165305,
    stop_lon: -90.022021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3825,
    stop_code: "BREJEHSN",
    stop_name: "BREEDLOVE@JEHL PL",
    stop_desc: "BREEDLOVE ST & JEHL PL",
    stop_lat: 35.164268,
    stop_lon: -90.02221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3826,
    stop_code: "BREJOSSN",
    stop_name: "BREEDLOVE@JOSEPH",
    stop_desc: "BREEDLOVE ST & JOSEPH PL",
    stop_lat: 35.162606,
    stop_lon: -90.022466,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3827,
    stop_code: "BREPALSF",
    stop_name: "BREEDLOVE@PALMETTO",
    stop_desc: "BREEDLOVE ST & PALMETTO AVE",
    stop_lat: 35.160711,
    stop_lon: -90.022788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3828,
    stop_code: "BRECOWSN",
    stop_name: "BREEDLOVE@COWETA",
    stop_desc: "BREEDLOVE ST & COWETA PL",
    stop_lat: 35.159389,
    stop_lon: -90.02301,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3835,
    stop_code: "JACALMEN",
    stop_name: "JACKSON AVE@ALMA ST",
    stop_desc: "",
    stop_lat: 35.158367,
    stop_lon: -90.016258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3836,
    stop_code: "JACCLAEN",
    stop_name: "JACKSON AVE@CLAYBROOK",
    stop_desc: "STATE HWY 14 & N CLAYBROOK ST",
    stop_lat: 35.158295,
    stop_lon: -90.015284,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3839,
    stop_code: "WATHENNM",
    stop_name: "WATKINS@HENRY",
    stop_desc: "N WATKINS ST & HENRY AVE",
    stop_lat: 35.160545,
    stop_lon: -90.010865,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3840,
    stop_code: "WATHENNN",
    stop_name: "WATKINS@HENRY",
    stop_desc: "N WATKINS ST & HENRY AVE",
    stop_lat: 35.162386,
    stop_lon: -90.010609,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3842,
    stop_code: "WATBRONN",
    stop_name: "WATKINS@BROWN",
    stop_desc: "N WATKINS ST & BROWN AVE",
    stop_lat: 35.167203,
    stop_lon: -90.010207,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3843,
    stop_code: "COOLINNN",
    stop_name: "COOPER@LINDEN",
    stop_desc: "S COOPER ST & LINDEN AVE",
    stop_lat: 35.133098,
    stop_lon: -89.990369,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3845,
    stop_code: "WATLEXSF",
    stop_name: "WATKINS@LEXINGTON CIR",
    stop_desc: "",
    stop_lat: 35.169809,
    stop_lon: -90.00955,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3846,
    stop_code: "WATLEXSN",
    stop_name: "WATKINS@LEXINGTON CIR",
    stop_desc: "N WATKINS ST & LEXINGTON CIR S",
    stop_lat: 35.168257,
    stop_lon: -90.010251,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3847,
    stop_code: "WATBROSN",
    stop_name: "WATKINS@BROWN",
    stop_desc: "N WATKINS ST & BROWN AVE",
    stop_lat: 35.167489,
    stop_lon: -90.010395,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3848,
    stop_code: "WATVOLSN",
    stop_name: "WATKINS@VOLLINTINE",
    stop_desc: "N WATKINS ST & VOLLINTINE AVE",
    stop_lat: 35.164861,
    stop_lon: -90.010463,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3849,
    stop_code: "VOLWATWF",
    stop_name: "VOLLINTINE@WATKINS",
    stop_desc: "VOLLINTINE AVE & N WATKINS ST",
    stop_lat: 35.164736,
    stop_lon: -90.010464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3850,
    stop_code: "VOLSPEWN",
    stop_name: "VOLLINTINE@SPEED ST",
    stop_desc: "VOLLINTINE AVE & SPEED ST",
    stop_lat: 35.16497,
    stop_lon: -90.012323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3851,
    stop_code: "VOLCLAWN",
    stop_name: "VOLLINTINE@CLAYBROOK",
    stop_desc: "VOLLINTINE AVE & N CLAYBROOK ST",
    stop_lat: 35.165131,
    stop_lon: -90.013962,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3852,
    stop_code: "VOLMONWN",
    stop_name: "VOLLINTINE@MONTGOMERY",
    stop_desc: "VOLLINTINE AVE & N MONTGOMERY ST",
    stop_lat: 35.165365,
    stop_lon: -90.016364,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3853,
    stop_code: "VOLBELWN",
    stop_name: "VOLLINTINE@BELLEVUE",
    stop_desc: "VOLLINTINE AVE & N BELLEVUE BLVD",
    stop_lat: 35.165526,
    stop_lon: -90.017836,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3854,
    stop_code: "VOLLEWWN",
    stop_name: "VOLLINTINE@LEWIS",
    stop_desc: "VOLLINTINE AVE & LEWIS ST",
    stop_lat: 35.165706,
    stop_lon: -90.019397,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3855,
    stop_code: "VOLBREWN",
    stop_name: "VOLLINTINE AVE @BREEDLOVE ST",
    stop_desc: "VOLLINTINE AVE & CROCKETT PL",
    stop_lat: 35.165921,
    stop_lon: -90.021335,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3856,
    stop_code: "VOLCROEN",
    stop_name: "VOLLINTINE@CROCKETT",
    stop_desc: "VOLLINTINE AVE & CROCKETT PL",
    stop_lat: 35.165697,
    stop_lon: -90.02077,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3857,
    stop_code: "VOLBELEN",
    stop_name: "VOLLINTINE@BELLEVUE",
    stop_desc: "VOLLINTINE AVE & N BELLEVUE BLVD",
    stop_lat: 35.165392,
    stop_lon: -90.018124,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3858,
    stop_code: "VOLANNEN",
    stop_name: "VOLLINTINE@ANNIE",
    stop_desc: "VOLLINTINE AVE & ANNIE ST",
    stop_lat: 35.165294,
    stop_lon: -90.017194,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3859,
    stop_code: "VOLALMEM",
    stop_name: "VOLLINTINE@ALMA",
    stop_desc: "VOLLINTINE AVE & OLYMPIC ST",
    stop_lat: 35.165096,
    stop_lon: -90.015257,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3863,
    stop_code: "WATHENSN",
    stop_name: "WATKINS@HENRY",
    stop_desc: "N WATKINS ST & HENRY AVE",
    stop_lat: 35.162672,
    stop_lon: -90.010764,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3864,
    stop_code: "WATHENSF",
    stop_name: "WATKINS@HENRY",
    stop_desc: "N WATKINS ST & HENRY AVE",
    stop_lat: 35.16051,
    stop_lon: -90.011076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3866,
    stop_code: "WATJACSF",
    stop_name: "WATKINS@JACKSON",
    stop_desc: "N WATKINS ST & HENRY AVE",
    stop_lat: 35.157945,
    stop_lon: -90.011908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3867,
    stop_code: "WATTUTSN",
    stop_name: "WATKINS@TUTWILER",
    stop_desc: "N WATKINS ST & TUTWILER AVE",
    stop_lat: 35.156185,
    stop_lon: -90.012164,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3868,
    stop_code: "WATFAXSN",
    stop_name: "WATKINS@FAXON",
    stop_desc: "N WATKINS ST & FAXON AVE",
    stop_lat: 35.155104,
    stop_lon: -90.01232,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3869,
    stop_code: "WATGALSN",
    stop_name: "WATKINS@GALLOWAY",
    stop_desc: "? N WATKINS ST & GALLOWAY AVE",
    stop_lat: 35.152316,
    stop_lon: -90.012831,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3870,
    stop_code: "CLEOVESN",
    stop_name: "CLEVELAND@OVERTON PARK AV",
    stop_desc: "N CLEVELAND ST & OVERTON PARK AVE",
    stop_lat: 35.149555,
    stop_lon: -90.014438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3871,
    stop_code: "RIVSHENF",
    stop_name: "RIVERDALE@SHELBY DR",
    stop_desc: "RIVERDALE RD & GERMANTOWN RD",
    stop_lat: 35.020827,
    stop_lon: -89.831083,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3872,
    stop_code: "CLELARNN",
    stop_name: "CLEVELAND@LARKIN",
    stop_desc: "N CLEVELAND ST & LARKIN AVE",
    stop_lat: 35.145704,
    stop_lon: -90.01516,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3873,
    stop_code: "CLEPO1SN",
    stop_name: "CLEVELAND @ POPLAR",
    stop_desc: "N CLEVELAND ST & POPLAR AVE",
    stop_lat: 35.144265,
    stop_lon: -90.015681,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3876,
    stop_code: "CLEJEFSF",
    stop_name: "CLEVELAND@JEFFERSON",
    stop_desc: "N CLEVELAND ST & JEFFERSON AVE",
    stop_lat: 35.141227,
    stop_lon: -90.016126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3878,
    stop_code: "CLEMADSF",
    stop_name: "CLEVELAND@MADISON",
    stop_desc: "S CLEVELAND ST & MADISON AVE",
    stop_lat: 35.138591,
    stop_lon: -90.016493,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3879,
    stop_code: "CLEUNISN",
    stop_name: "CLEVELAND@UNION",
    stop_desc: "S CLEVELAND ST & UNION AVE",
    stop_lat: 35.137071,
    stop_lon: -90.016716,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3880,
    stop_code: "CLEMONNN",
    stop_name: "CLEVELAND@MONROE",
    stop_desc: "S CLEVELAND ST & MONROE AVE",
    stop_lat: 35.137759,
    stop_lon: -90.016416,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3881,
    stop_code: "RIVBORNN",
    stop_name: "RIVERDALE@BORDEAUX",
    stop_desc: "RIVERDALE RD & BORDEAUX LN",
    stop_lat: 35.024053,
    stop_lon: -89.831095,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3882,
    stop_code: "CLEMADNF",
    stop_name: "CLEVELAND@MADISON",
    stop_desc: "N CLEVELAND ST & MADISON AVE",
    stop_lat: 35.139216,
    stop_lon: -90.016205,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3883,
    stop_code: "CLEJEFNN",
    stop_name: "CLEVELAND@JEFFERSON",
    stop_desc: "N CLEVELAND ST & JEFFERSON AVE",
    stop_lat: 35.141414,
    stop_lon: -90.015894,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3884,
    stop_code: "CLACOUSM",
    stop_name: "CLAYBROOK@COURT AVE",
    stop_desc: "N CLAYBROOK ST & COURT AVE",
    stop_lat: 35.140799,
    stop_lon: -90.018097,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3885,
    stop_code: "CLAMADSN",
    stop_name: "CLAYBROOK@MADISON",
    stop_desc: "N CLAYBROOK ST & MADISON AVE",
    stop_lat: 35.139503,
    stop_lon: -90.018275,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3886,
    stop_code: "BELMADNF",
    stop_name: "BELLEVUE@MADISON WB",
    stop_desc: "",
    stop_lat: 35.139755,
    stop_lon: -90.021528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3887,
    stop_code: "BELCOUNF",
    stop_name: "BELLEVUE@COURT",
    stop_desc: "",
    stop_lat: 35.141283,
    stop_lon: -90.021284,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3888,
    stop_code: "CLEWASNN",
    stop_name: "CLEVELAND@WASHINGTON",
    stop_desc: "N CLEVELAND ST & WASHINGTON AVE",
    stop_lat: 35.142612,
    stop_lon: -90.015727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3889,
    stop_code: "CLEPOPNN",
    stop_name: "CLEVELAND @ POPLAR",
    stop_desc: "? N CLEVELAND ST & POPLAR AVE",
    stop_lat: 35.143702,
    stop_lon: -90.01556,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3891,
    stop_code: "RIVHUNNF",
    stop_name: "RIVERDALE@HUNT CLUB",
    stop_desc: "RIVERDALE RD & HUNT CLUB RD",
    stop_lat: 35.029702,
    stop_lon: -89.8311,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3892,
    stop_code: "CLEPEANN",
    stop_name: "CLEVELAND@PEACH",
    stop_desc: "N CLEVELAND ST & PEACH AVE",
    stop_lat: 35.147777,
    stop_lon: -90.014495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3893,
    stop_code: "CLEOVENN",
    stop_name: "CLEVELAND@OVERTON PARK",
    stop_desc: "N CLEVELAND ST & OVERTON PARK AVE",
    stop_lat: 35.149162,
    stop_lon: -90.014294,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3894,
    stop_code: "CLEAUTNN",
    stop_name: "CLEVELAND@AUTUMN",
    stop_desc: "N CLEVELAND ST & WASHINGTON AVE",
    stop_lat: 35.150699,
    stop_lon: -90.013817,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3895,
    stop_code: "WATNPANN",
    stop_name: "WATKINS ST@GALLOWAY AVE",
    stop_desc: "? N WATKINS ST & GALLOWAY AVE",
    stop_lat: 35.152432,
    stop_lon: -90.012609,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3896,
    stop_code: "WATTUTNN",
    stop_name: "WATKINS@TUTWILER",
    stop_desc: "N WATKINS ST & TUTWILER AVE",
    stop_lat: 35.155684,
    stop_lon: -90.012031,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3898,
    stop_code: "JACGAREN",
    stop_name: "JACKSON@GARLAND",
    stop_desc: "JACKSON AVE & GARLAND ST",
    stop_lat: 35.158204,
    stop_lon: -90.010391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3899,
    stop_code: "JACSTOEN",
    stop_name: "JACKSON@STONEWALL",
    stop_desc: "STATE HWY 14 & STONEWALL ST",
    stop_lat: 35.158685,
    stop_lon: -90.008409,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3900,
    stop_code: "JACMAUEN",
    stop_name: "JACKSON@MAURY ST",
    stop_desc: "STATE HWY 14 & N WILLETT ST",
    stop_lat: 35.159399,
    stop_lon: -90.006062,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3901,
    stop_code: "JACNAVEN",
    stop_name: "JACKSON@NAVALON",
    stop_desc: "STATE HWY 14 & N AVALON ST",
    stop_lat: 35.159675,
    stop_lon: -90.004888,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3902,
    stop_code: "JACDICEN",
    stop_name: "JACKSON@DICKINSON",
    stop_desc: "STATE HWY 14 & N AVALON ST",
    stop_lat: 35.160041,
    stop_lon: -90.003559,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3903,
    stop_code: "JACBELEN",
    stop_name: "JACKSON@BELVEDERE",
    stop_desc: "JACKSON AVE & N BELVEDERE BLVD",
    stop_lat: 35.160371,
    stop_lon: -90.002341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3904,
    stop_code: "JACEVEEN",
    stop_name: "JACKSON@EVERGREEN",
    stop_desc: "JACKSON AVE & N EVERGREEN ST",
    stop_lat: 35.160728,
    stop_lon: -90.001046,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3905,
    stop_code: "JACHAWEN",
    stop_name: "JACKSON@HAWTHORNE ST",
    stop_desc: "STATE HWY 14 & HAWTHORNE ST",
    stop_lat: 35.160574,
    stop_lon: -89.998566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3906,
    stop_code: "JACMCLEF",
    stop_name: "JACKSON@MCLEAN",
    stop_desc: "0 JACKSON AVE & KENSINGTON PL",
    stop_lat: 35.160296,
    stop_lon: -89.996286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3909,
    stop_code: "JACUNIEN",
    stop_name: "JACKSON@UNIVERSITY",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.159703,
    stop_lon: -89.99146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3910,
    stop_code: "JACUNIEM",
    stop_name: "JACKSON@UNIVERSITY CIR",
    stop_desc: "STATE HWY 14 & UNIVERSITY CIR",
    stop_lat: 35.159415,
    stop_lon: -89.988548,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3911,
    stop_code: "JACCHAEN",
    stop_name: "JACKSON@CHARLES PL",
    stop_desc: "STATE HWY 14 & CHARLES PL",
    stop_lat: 35.159163,
    stop_lon: -89.985991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3912,
    stop_code: "JACSPREN",
    stop_name: "JACKSON@SPRINGDALE",
    stop_desc: "STATE HWY 14 & WEST DR",
    stop_lat: 35.158867,
    stop_lon: -89.984553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3913,
    stop_code: "JACEASEM",
    stop_name: "JACKSON@EAST",
    stop_desc: "0 JACKSON AVE & WARRINGTON RD",
    stop_lat: 35.158383,
    stop_lon: -89.982428,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3914,
    stop_code: "JACTREEF",
    stop_name: "JACKSON@TREZEVANT",
    stop_desc: "STATE HWY 14 & BIGGS ST",
    stop_lat: 35.157845,
    stop_lon: -89.979749,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3915,
    stop_code: "JACMCCEF",
    stop_name: "JACKSON@MCCONNELL ST",
    stop_desc: "0 JACKSON AVE & MAPLE DR",
    stop_lat: 35.157807,
    stop_lon: -89.977956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3917,
    stop_code: "JACMEREN",
    stop_name: "JACKSON@MERTON ST",
    stop_desc: "STATE HWY 14 & N MERTON ST",
    stop_lat: 35.157849,
    stop_lon: -89.974214,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3918,
    stop_code: "JACCOLEN",
    stop_name: "JACKSON@COLUMBIA",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.157848,
    stop_lon: -89.972897,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3919,
    stop_code: "JACBINEN",
    stop_name: "JACKSON@BINGHAM",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.157865,
    stop_lon: -89.971724,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3920,
    stop_code: "JACWARNN",
    stop_name: "JACKSON@WARFORD",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.159904,
    stop_lon: -89.962942,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3921,
    stop_code: "JACTILEN",
    stop_name: "JACKSON@TILLMAN",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.160795,
    stop_lon: -89.960284,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3922,
    stop_code: "JACPOPEF",
    stop_name: "JACKSON@POPE",
    stop_desc: "STATE HWY 14 & POPE ST",
    stop_lat: 35.161437,
    stop_lon: -89.958534,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3923,
    stop_code: "JACHUDEN",
    stop_name: "JACKSON@HUDSON",
    stop_desc: "STATE HWY 14 & HUDSON ST",
    stop_lat: 35.16256,
    stop_lon: -89.956086,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3924,
    stop_code: "JACMACEN",
    stop_name: "JACKSON@MACON RD",
    stop_desc: "STATE HWY 14 & MACON RD",
    stop_lat: 35.164113,
    stop_lon: -89.953648,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3925,
    stop_code: "MACHOLEF",
    stop_name: "MACON@HOLMES",
    stop_desc: "MACON RD & N HOLMES ST",
    stop_lat: 35.163065,
    stop_lon: -89.950782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3926,
    stop_code: "MACPANEN",
    stop_name: "MACON@PANAMA",
    stop_desc: "MACON RD & PANAMA ST",
    stop_lat: 35.162625,
    stop_lon: -89.948635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3927,
    stop_code: "MACNATEN",
    stop_name: "MACON@NATIONAL ST",
    stop_desc: "MACON RD & NATIONAL ST",
    stop_lat: 35.162337,
    stop_lon: -89.947218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3928,
    stop_code: "MACPEAEN",
    stop_name: "MACON@PEACHTREE",
    stop_desc: "MACON RD & PEACHTREE ST",
    stop_lat: 35.162023,
    stop_lon: -89.945647,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3929,
    stop_code: "MACHIGEN",
    stop_name: "MACON@NHIGHLAND",
    stop_desc: "MACON RD & N HIGHLAND ST",
    stop_lat: 35.161762,
    stop_lon: -89.944396,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3940,
    stop_code: "MACVICEF",
    stop_name: "MACON@VICTOR",
    stop_desc: "MACON RD & VICTOR DR",
    stop_lat: 35.162218,
    stop_lon: -89.928708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3941,
    stop_code: "MACNGREN",
    stop_name: "MACON@NGRAHAM",
    stop_desc: "MACON RD & N GRAHAM ST",
    stop_lat: 35.16219,
    stop_lon: -89.927446,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3942,
    stop_code: "MACWINEN",
    stop_name: "MACON@WINGFIELD",
    stop_desc: "",
    stop_lat: 35.16211,
    stop_lon: -89.923999,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3943,
    stop_code: "MACTATEN",
    stop_name: "MACON@TATUM",
    stop_desc: "",
    stop_lat: 35.162078,
    stop_lon: -89.922698,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3944,
    stop_code: "MACVAUEN",
    stop_name: "MACON@VAUGHN",
    stop_desc: "",
    stop_lat: 35.162037,
    stop_lon: -89.920737,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3945,
    stop_code: "MACWAREN",
    stop_name: "MACON@WARING",
    stop_desc: "MACON RD & WARING RD",
    stop_lat: 35.161963,
    stop_lon: -89.917516,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3946,
    stop_code: "MCCDOREN",
    stop_name: "MCCRORY@DORA",
    stop_desc: "MCCRORY AVE & DANITA CV",
    stop_lat: 35.160962,
    stop_lon: -89.904067,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3947,
    stop_code: "MCCGAREN",
    stop_name: "MCCRORY@GARY ST",
    stop_desc: "MCCRORY AVE & GARY ST",
    stop_lat: 35.160143,
    stop_lon: -89.900549,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3948,
    stop_code: "MCCNMEEM",
    stop_name: "MCCRORY@NMENDENHALL",
    stop_desc: "MCCRORY AVE & GARY ST",
    stop_lat: 35.15947,
    stop_lon: -89.898192,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3949,
    stop_code: "MCCGARWN",
    stop_name: "MCCRORY@GARY",
    stop_desc: "MCCRORY AVE & GARY ST",
    stop_lat: 35.160196,
    stop_lon: -89.900095,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3950,
    stop_code: "MCCBUCWN",
    stop_name: "MCCRORY@BUCKNER",
    stop_desc: "MCCRORY AVE & BUCKNER RD",
    stop_lat: 35.160807,
    stop_lon: -89.902308,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3951,
    stop_code: "MCCSTRWN",
    stop_name: "MCCRORY@STRATFORD",
    stop_desc: "MCCRORY AVE & BUCKNER RD",
    stop_lat: 35.160991,
    stop_lon: -89.905529,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3979,
    stop_code: "MACJAMWN",
    stop_name: "MACON@JAMERSON",
    stop_desc: "MACON RD & JAMERSON RD",
    stop_lat: 35.162161,
    stop_lon: -89.918634,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3980,
    stop_code: "MACWINWF",
    stop_name: "MACON@WINGFIELD RD",
    stop_desc: "MACON & SANDRA RD",
    stop_lat: 35.16226,
    stop_lon: -89.924267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3981,
    stop_code: "MACVICWN",
    stop_name: "MACON@VICTOR",
    stop_desc: "MACON RD & VICTOR DR",
    stop_lat: 35.162379,
    stop_lon: -89.928431,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3982,
    stop_code: "MACCHAWN",
    stop_name: "MACON@CHATWOOD",
    stop_desc: "MACON RD & CHATWOOD ST",
    stop_lat: 35.162425,
    stop_lon: -89.930158,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3993,
    stop_code: "MACHIGWF",
    stop_name: "MACON@HIGHLAND",
    stop_desc: "MACON RD & N HIGHLAND ST",
    stop_lat: 35.161932,
    stop_lon: -89.944407,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3994,
    stop_code: "MACNATWN",
    stop_name: "MACON@NATIONAL",
    stop_desc: "MACON RD & NATIONAL ST",
    stop_lat: 35.162444,
    stop_lon: -89.946908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3995,
    stop_code: "MACPANWF",
    stop_name: "MACON@PANAMA",
    stop_desc: "MACON RD & PANAMA ST",
    stop_lat: 35.162758,
    stop_lon: -89.948435,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3996,
    stop_code: "MACHOLWN",
    stop_name: "MACON@HOLMES",
    stop_desc: "MACON RD & N HOLMES ST",
    stop_lat: 35.163234,
    stop_lon: -89.950793,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3997,
    stop_code: "MACJACWM",
    stop_name: "MACON@JACKSON",
    stop_desc: "MACON RD & JACKSON AVE",
    stop_lat: 35.164219,
    stop_lon: -89.952596,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3998,
    stop_code: "JACCICNN",
    stop_name: "JACKSON@CICALLA AVE",
    stop_desc: "JACKSON AVE & CICALLA AVE",
    stop_lat: 35.165612,
    stop_lon: -89.95152,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 3999,
    stop_code: "JACFARNN",
    stop_name: "JACKSON@FARMVILLE",
    stop_desc: "JACKSON AVE & FARMVILLE RD",
    stop_lat: 35.166219,
    stop_lon: -89.950799,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4000,
    stop_code: "JACROCNN",
    stop_name: "JACKSON@ROCKWOOD",
    stop_desc: "STATE HWY 14 & BUCHANAN AVE",
    stop_lat: 35.16746,
    stop_lon: -89.949325,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4002,
    stop_code: "JACMAYNN",
    stop_name: "JACKSON@MAYFLOWER",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.16879,
    stop_lon: -89.947518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4003,
    stop_code: "JACFAINN",
    stop_name: "JACKSON@FAIROAKS",
    stop_desc: "STATE HWY 14 & FAIROAKS AVE",
    stop_lat: 35.169477,
    stop_lon: -89.946531,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4004,
    stop_code: "JACKALNN",
    stop_name: "JACKSON@KALLAHER",
    stop_desc: "JACKSON AVE & KALLAHER AVE",
    stop_lat: 35.170164,
    stop_lon: -89.945456,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4006,
    stop_code: "JACGRANF",
    stop_name: "JACKSON@GRAGG",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.180804,
    stop_lon: -89.934598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4007,
    stop_code: "JACWA1NM",
    stop_name: "JACKSON@WALES",
    stop_desc: "STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.181893,
    stop_lon: -89.933677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4008,
    stop_code: "JACWA2NM",
    stop_name: "JACKSON@WALES",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.182911,
    stop_lon: -89.932823,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4009,
    stop_code: "JACWA3NM",
    stop_name: "JACKSON@WALES ST",
    stop_desc: "",
    stop_lat: 35.184081,
    stop_lon: -89.932001,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4010,
    stop_code: "JACWALNN",
    stop_name: "JACKSON AVE@WALES ST",
    stop_desc: "STATE HWY 14 & WALES AVE",
    stop_lat: 35.185348,
    stop_lon: -89.930847,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4011,
    stop_code: "JACMAMNN",
    stop_name: "JACKSON@MAMIE",
    stop_desc: "JACKSON AVE & STATE HWY 14",
    stop_lat: 35.186455,
    stop_lon: -89.929959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4012,
    stop_code: "JACHEMNN",
    stop_name: "JACKSON@HEMINGWAY",
    stop_desc: "STATE HWY 14 & MAMIE RD",
    stop_lat: 35.187527,
    stop_lon: -89.929105,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4015,
    stop_code: "FARPOPNF",
    stop_name: "FARMINGTON RD@POPLAR",
    stop_desc: "W FARMINGTON AVE & FARMINGTON AVE",
    stop_lat: 35.094804,
    stop_lon: -89.810733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4016,
    stop_code: "FARGEREN",
    stop_name: "FARMINGTON RD@GERMANTOWN RD",
    stop_desc: "FARMINGTON BLVD & FARMINGTON AVE",
    stop_lat: 35.09553,
    stop_lon: -89.807988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4017,
    stop_code: "FAREXEEM",
    stop_name: "FARMINGTON RD@EXETER RD",
    stop_desc: "FARMINGTON AVE & FARMINGTON BLVD",
    stop_lat: 35.095364,
    stop_lon: -89.80571,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4018,
    stop_code: "FAREXEEN",
    stop_name: "FARMINGTON@EXETER RD",
    stop_desc: "FARMINGTON BLVD & EXETER RD",
    stop_lat: 35.095448,
    stop_lon: -89.803221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4019,
    stop_code: "EXEFARSF",
    stop_name: "EXETER RD@FARMINGTON",
    stop_desc: "EXETER RD & FARMINGTON AVE",
    stop_lat: 35.094767,
    stop_lon: -89.802382,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4020,
    stop_code: "EXEPO1SM",
    stop_name: "EXETER RD@POPLAR AVE",
    stop_desc: "",
    stop_lat: 35.091907,
    stop_lon: -89.802536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4024,
    stop_code: "NEWSTANM",
    stop_name: "NEW BRUNSWICK R@STAGE RD",
    stop_desc: "0 NEW BRUNSWICK RD & DAROLYN ST",
    stop_lat: 35.202469,
    stop_lon: -89.778447,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4027,
    stop_code: "STAMULEM",
    stop_name: "STATE RD@MULLINS STATION",
    stop_desc: "STATE RD & SYCAMORE VIEW RD",
    stop_lat: 35.154039,
    stop_lon: -89.855897,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4028,
    stop_code: "STAMULEN",
    stop_name: "MULLIN STATION RD@STATE RD",
    stop_desc: "6315 STATE RD & MULLINS STATION",
    stop_lat: 35.154462,
    stop_lon: -89.851747,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4029,
    stop_code: "MULTHRNN",
    stop_name: "MULLINS STATION RD@THRUSHCROSS DR",
    stop_desc: "",
    stop_lat: 35.157848,
    stop_lon: -89.850677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4030,
    stop_code: "MULOAKNN",
    stop_name: "MULLINS STATION RD@OAK PARK DR",
    stop_desc: "MULLINS STATION RD & THORNFIELD DR",
    stop_lat: 35.159569,
    stop_lon: -89.850556,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4031,
    stop_code: "MULMACNM",
    stop_name: "MULLINS STATION RD@MACON RD",
    stop_desc: "MULLINS STATION RD & SAGINAW RD",
    stop_lat: 35.162759,
    stop_lon: -89.850337,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4032,
    stop_code: "MACHEAWM",
    stop_name: "MACON RD@HEATHCLIFF",
    stop_desc: "MACON RD & GORDON ST",
    stop_lat: 35.163308,
    stop_lon: -89.851996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4033,
    stop_code: "MACGILWN",
    stop_name: "MACON RD@GILLHAM",
    stop_desc: "MACON RD & GARDEN RD",
    stop_lat: 35.16311,
    stop_lon: -89.856159,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4034,
    stop_code: "MACDOVWN",
    stop_name: "MACON RD@DOVECREST",
    stop_desc: "MACON RD & DOVECREST RD",
    stop_lat: 35.16306,
    stop_lon: -89.858373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4035,
    stop_code: "MACSYCWF",
    stop_name: "MACON@SYCAMORE VIEW",
    stop_desc: "MACON RD & MACON COVE RD",
    stop_lat: 35.162832,
    stop_lon: -89.860644,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4037,
    stop_code: "RESSYCEM",
    stop_name: "RESOURCES DR@SYCAMORE VIEW",
    stop_desc: "RESOURCES DR & STATE HWY 14",
    stop_lat: 35.158483,
    stop_lon: -89.862393,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4038,
    stop_code: "RESSY1EM",
    stop_name: "SYCAMORE VIEW@RESOURCES DR",
    stop_desc: "RESOURCES DR & STATE HWY 14",
    stop_lat: 35.158461,
    stop_lon: -89.860301,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4039,
    stop_code: "SYCMACNM",
    stop_name: "SYCAMORE VIEW@MACON",
    stop_desc: "1340 SYCAMORE VIEW & MACON",
    stop_lat: 35.161699,
    stop_lon: -89.859353,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4040,
    stop_code: "SYCSHENN",
    stop_name: "SYCAMORE VIEW@SHELBY OAKS DR",
    stop_desc: "1548 SYCAMORE VIEW RD & SAGINAW RD",
    stop_lat: 35.168084,
    stop_lon: -89.864239,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4041,
    stop_code: "SYCSUMNM",
    stop_name: "SYCAMORE VIEW@SUMMER AVE",
    stop_desc: "",
    stop_lat: 35.169388,
    stop_lon: -89.865502,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4045,
    stop_code: "SUMVIRWM",
    stop_name: "SUMMER AVE@VIRGINIA  RUN",
    stop_desc: "STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.162041,
    stop_lon: -89.878879,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4048,
    stop_code: "SUMI24WM",
    stop_name: "SUMMER AVE@I-240",
    stop_desc: "STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.155939,
    stop_lon: -89.885603,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4049,
    stop_code: "SUMWHISF",
    stop_name: "SUMMER AVE@WHITE STATION RD",
    stop_desc: "",
    stop_lat: 35.152986,
    stop_lon: -89.888831,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4050,
    stop_code: "SUMOL1WM",
    stop_name: "OLD SUMMER@SUMMER",
    stop_desc: "5112 US HWY 64 & STATE HWY 1",
    stop_lat: 35.152111,
    stop_lon: -89.889829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4051,
    stop_code: "SUMOLSSF",
    stop_name: "SUMMER AVE@OLD SUMMER RD",
    stop_desc: "US HWY 64 & SUMMER AVE",
    stop_lat: 35.151487,
    stop_lon: -89.890572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4052,
    stop_code: "SUMBARWF",
    stop_name: "SUMMER AVE@BARTLETT RD",
    stop_desc: "US HWY 64 & STATE HWY 1",
    stop_lat: 35.149624,
    stop_lon: -89.893964,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4053,
    stop_code: "SUMMENWN",
    stop_name: "SUMMER AVE@MENDENHALL RD",
    stop_desc: "US HWY 64 & STATE HWY 1",
    stop_lat: 35.14886,
    stop_lon: -89.896666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4054,
    stop_code: "SUMMENWF",
    stop_name: "SUMMER AVE@MENDENHALL RD",
    stop_desc: "? STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.14863,
    stop_lon: -89.898604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4055,
    stop_code: "SUMAVOWN",
    stop_name: "SUMMER AVE@AVON ST",
    stop_desc: "STATE HWY 1 & AVON RD",
    stop_lat: 35.148662,
    stop_lon: -89.90177,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4056,
    stop_code: "SUMCADWM",
    stop_name: "SUMMER AVE@CADRACA DR",
    stop_desc: "STATE HWY 1 & CADRACA DR",
    stop_lat: 35.148718,
    stop_lon: -89.90364,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4057,
    stop_code: "SUMSTRWN",
    stop_name: "SUMMER AVE@STRATFORD RD",
    stop_desc: "STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.148766,
    stop_lon: -89.905987,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4058,
    stop_code: "SUMNOVWM",
    stop_name: "SUMMER AVE@NOVARESE ST",
    stop_desc: "US HWY 64 & NOVARESE RD",
    stop_lat: 35.148815,
    stop_lon: -89.908853,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4059,
    stop_code: "SUMBERWN",
    stop_name: "SUMMER AVE@BERCLAIR",
    stop_desc: "4490 US HWY 64 & BERCLAIR RD",
    stop_lat: 35.148862,
    stop_lon: -89.910967,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4060,
    stop_code: "SUMSANWN",
    stop_name: "SUMMER AVE@SANDRIDGE ST",
    stop_desc: "STATE HWY 1 & BERCLAIR RD",
    stop_lat: 35.1489,
    stop_lon: -89.912517,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4061,
    stop_code: "SUMSANWF",
    stop_name: "SUMMER AVE@SANDRIDGE",
    stop_desc: "US HWY 64 & SANDRIDGE ST",
    stop_lat: 35.148938,
    stop_lon: -89.914266,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4062,
    stop_code: "SUMWARWF",
    stop_name: "SUMMER AVE@WARNING RD",
    stop_desc: "",
    stop_lat: 35.148905,
    stop_lon: -89.916535,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4063,
    stop_code: "SUMESTWM",
    stop_name: "SUMMER AVE@ESTRIDGE DR",
    stop_desc: "US HWY 64 & ESTRIDGE DR",
    stop_lat: 35.148996,
    stop_lon: -89.917896,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4065,
    stop_code: "SUMVAUWN",
    stop_name: "SUMMER AVE@VAUGHN RD",
    stop_desc: "US HWY 64 & VAUGHN RD",
    stop_lat: 35.149081,
    stop_lon: -89.921316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4069,
    stop_code: "SUMVAUWF",
    stop_name: "SUMMER AVE@VAUGHN",
    stop_desc: "US HWY 64 & VAUGHN RD",
    stop_lat: 35.149101,
    stop_lon: -89.92291,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4070,
    stop_code: "SUMNGRWM",
    stop_name: "SUMMER AVE@NGRAHAM ST",
    stop_desc: "US HWY 64 & N GRAHAM ST",
    stop_lat: 35.149149,
    stop_lon: -89.925412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4071,
    stop_code: "SUMNGRWF",
    stop_name: "SUMMER AVE@N GRAHAM ST",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.149224,
    stop_lon: -89.928489,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4072,
    stop_code: "SUMLYNWN",
    stop_name: "SUMMER AVE@LYNNCREST",
    stop_desc: "STATE HWY 1 & LYNNCREST ST",
    stop_lat: 35.149319,
    stop_lon: -89.933425,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4073,
    stop_code: "SUMISAWF",
    stop_name: "SUMMER AVE@ISABELLE",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.149357,
    stop_lon: -89.934964,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4074,
    stop_code: "SUMHIGWN",
    stop_name: "SUMMER AVE@HIGH POINT TERR",
    stop_desc: "STATE HWY 1 & HIGH POINT TER",
    stop_lat: 35.149395,
    stop_lon: -89.937166,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4076,
    stop_code: "SUMFREWF",
    stop_name: "FREEMAN ST@SUMMER AVE",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.149451,
    stop_lon: -89.939336,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4077,
    stop_code: "SUMSEVWN",
    stop_name: "SUMMER AVE@SEVIER ST",
    stop_desc: "STATE HWY 1 & FREEMAN ST",
    stop_lat: 35.149497,
    stop_lon: -89.940852,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4078,
    stop_code: "SUMNHIWN",
    stop_name: "SUMMER AVE@NHIGHLAND ST",
    stop_desc: "STATE HWY 1 & N HIGHLAND ST",
    stop_lat: 35.149563,
    stop_lon: -89.943785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4079,
    stop_code: "SUMNATWN",
    stop_name: "SUMMER AVE@NATIONAL ST",
    stop_desc: "STATE HWY 1 & N HIGHLAND ST",
    stop_lat: 35.149514,
    stop_lon: -89.947659,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4080,
    stop_code: "SUMATLWN",
    stop_name: "SUMMER AVE@ATLANTIC ST",
    stop_desc: "US HWY 64 & ATLANTIC ST",
    stop_lat: 35.149429,
    stop_lon: -89.952474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4081,
    stop_code: "SUMMALWF",
    stop_name: "SUMMER AVE@MALCOMB",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.149458,
    stop_lon: -89.954212,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4083,
    stop_code: "SUMFRAWM",
    stop_name: "SUMMER AVE@FRANKLIN ST",
    stop_desc: "STATE HWY 1 & FRANKLIN ST",
    stop_lat: 35.149542,
    stop_lon: -89.958806,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4084,
    stop_code: "SUMFRAWN",
    stop_name: "SUMMER AVE@FRANKLIN ST",
    stop_desc: "STATE HWY 1 & FRANKLIN ST",
    stop_lat: 35.14958,
    stop_lon: -89.96051,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4085,
    stop_code: "SUMGLAWN",
    stop_name: "SUMMER AVE@GLANKLER ST",
    stop_desc: "US HWY 64 & GLANKLER ST",
    stop_lat: 35.149608,
    stop_lon: -89.962226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4086,
    stop_code: "SUMCARWF",
    stop_name: "SUMMER AVE@CARPENTER",
    stop_desc: "STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.149646,
    stop_lon: -89.964672,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4088,
    stop_code: "SUMMERWN",
    stop_name: "SUMMER AVE@MERTON ST",
    stop_desc: "STATE HWY 1 & N BINGHAM ST",
    stop_lat: 35.15045,
    stop_lon: -89.974932,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4090,
    stop_code: "SUMMEREN",
    stop_name: "SUMMER AVE@MERTON",
    stop_desc: "STATE HWY 1 & N BINGHAM ST",
    stop_lat: 35.150298,
    stop_lon: -89.975021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4093,
    stop_code: "SUMLIPEN",
    stop_name: "SUMMER AVE@LIPFORD ST",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.149475,
    stop_lon: -89.963444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4094,
    stop_code: "SUMTILEN",
    stop_name: "SUMMER AVE@TILLMAN ST",
    stop_desc: "US HWY 64 & STATE HWY 1",
    stop_lat: 35.149429,
    stop_lon: -89.961518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4096,
    stop_code: "SUMBA1EM",
    stop_name: "SUMMER AVE@BALTIC ST",
    stop_desc: "US HWY 64 & BALTIC ST",
    stop_lat: 35.149372,
    stop_lon: -89.958474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4098,
    stop_code: "SUMMALEN",
    stop_name: "SUMMER AVE@MALCOMB ST",
    stop_desc: "US HWY 64 & MALCOMB ST",
    stop_lat: 35.149297,
    stop_lon: -89.954212,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4099,
    stop_code: "SUMHOLEN",
    stop_name: "SUMMER AVE@HOLMES ST",
    stop_desc: "STATE HWY 1 & N HOLMES ST",
    stop_lat: 35.149268,
    stop_lon: -89.9518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4100,
    stop_code: "SUMNATEN",
    stop_name: "SUMMER AVE@NATIONAL ST",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.149344,
    stop_lon: -89.948091,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4101,
    stop_code: "SUMHIGEN",
    stop_name: "SUMMER AVE@HIGHLAND ST",
    stop_desc: "STATE HWY 1 & N HIGHLAND ST",
    stop_lat: 35.149412,
    stop_lon: -89.944671,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4102,
    stop_code: "SUMHIGEF",
    stop_name: "SUMMER AVE@HIGHLAND",
    stop_desc: "STATE HWY 1 & N HIGHLAND ST",
    stop_lat: 35.149402,
    stop_lon: -89.943619,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4103,
    stop_code: "SUMSEVEN",
    stop_name: "SUMMER AVE@SEVIER ST",
    stop_desc: "US HWY 64 & SUMMER AVE",
    stop_lat: 35.149364,
    stop_lon: -89.941771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4104,
    stop_code: "SUMFREEN",
    stop_name: "SUMMER AVE@FREEMAN ST",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.14929,
    stop_lon: -89.939391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4105,
    stop_code: "SUMHI1EN",
    stop_name: "SUMMER AVE@HIGH POINT",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.149244,
    stop_lon: -89.937554,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4106,
    stop_code: "SUMISAEN",
    stop_name: "SUMMER AVE@ISABELLE ST",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.149196,
    stop_lon: -89.934743,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4107,
    stop_code: "SUMGRAEM",
    stop_name: "SUMMER AVE@GRAHAM",
    stop_desc: "3889 US HWY 64 & STATE HWY 1",
    stop_lat: 35.14912,
    stop_lon: -89.931289,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4108,
    stop_code: "SUMGRAEF",
    stop_name: "SUMMER AVE@GRAHAM",
    stop_desc: "US HWY 64 & N GRAHAM ST",
    stop_lat: 35.149044,
    stop_lon: -89.927448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4109,
    stop_code: "SUMVAUEM",
    stop_name: "SUMMER AVE@VAUGHN",
    stop_desc: "US HWY 64 & STATE HWY 1",
    stop_lat: 35.149006,
    stop_lon: -89.925965,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4110,
    stop_code: "SUMVA1EM",
    stop_name: "SUMMER AVE@VAUGHN",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.14895,
    stop_lon: -89.923929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4111,
    stop_code: "SUMEASEN",
    stop_name: "SUMMER AVE@EASTERN",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.148892,
    stop_lon: -89.920188,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4112,
    stop_code: "SUMESTEN",
    stop_name: "SUMMER AVE@ESTRIDGE",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.148855,
    stop_lon: -89.918826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4113,
    stop_code: "SUMWAREN",
    stop_name: "SUMMER AVE@WARING RD",
    stop_desc: "US HWY 64 & WARING RD",
    stop_lat: 35.148816,
    stop_lon: -89.916435,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4114,
    stop_code: "SUMSANEN",
    stop_name: "SUMMER AVE@SANDRIDGE",
    stop_desc: "US HWY 64 & WARING RD",
    stop_lat: 35.148749,
    stop_lon: -89.912982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4115,
    stop_code: "SUMBEREN",
    stop_name: "SUMMER AVE@BERCLAIR",
    stop_desc: "STATE HWY 1 & BERCLAIR RD",
    stop_lat: 35.14872,
    stop_lon: -89.911875,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4116,
    stop_code: "SUMNOVEN",
    stop_name: "SUMMER AVE@NOVARESE",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.148691,
    stop_lon: -89.910204,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4117,
    stop_code: "SUMPEREN",
    stop_name: "SUMMER AVE@PERKINS RD",
    stop_desc: "STATE HWY 1 & NOVARESE RD",
    stop_lat: 35.148635,
    stop_lon: -89.908311,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4118,
    stop_code: "SUMSTREM",
    stop_name: "SUMMER AVE@STRATFORD",
    stop_desc: "US HWY 64 & N PERKINS RD",
    stop_lat: 35.148624,
    stop_lon: -89.906828,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4120,
    stop_code: "SUMAVOEN",
    stop_name: "SUMMER AVE@AVON",
    stop_desc: "US HWY 64 & US HWY 79",
    stop_lat: 35.14851,
    stop_lon: -89.902235,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4121,
    stop_code: "SUMMENEN",
    stop_name: "SUMMER AVE@MENDENHALL",
    stop_desc: "US HWY 64 & N MENDENHALL RD",
    stop_lat: 35.148513,
    stop_lon: -89.89815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4123,
    stop_code: "SUMBAREM",
    stop_name: "SUMMER AVE@BARTLETT RD",
    stop_desc: "",
    stop_lat: 35.149372,
    stop_lon: -89.894036,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4124,
    stop_code: "SUMOLDEN",
    stop_name: "SUMMER AVE@OLD SUMMER RD",
    stop_desc: "",
    stop_lat: 35.150167,
    stop_lon: -89.892269,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4126,
    stop_code: "SUMI24EF",
    stop_name: "SUMMER AVE@I-40",
    stop_desc: "5219 STATE HWY 1 & US HWY 79",
    stop_lat: 35.154921,
    stop_lon: -89.886424,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4127,
    stop_code: "SUMVIREM",
    stop_name: "SUMMER AVE@VIRGINIA RUN",
    stop_desc: "STATE HWY 1 & SUMMER AVE",
    stop_lat: 35.156527,
    stop_lon: -89.884694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4132,
    stop_code: "SYCSUMSF",
    stop_name: "SYCAMORE VIEW@SUMMER",
    stop_desc: "",
    stop_lat: 35.171083,
    stop_lon: -89.867475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4133,
    stop_code: "SYCSHESM",
    stop_name: "SYCAMORE VIEW@SHELBY OAKS DR",
    stop_desc: "1657 SYCAMORE VIEW",
    stop_lat: 35.170456,
    stop_lon: -89.866823,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4136,
    stop_code: "SYCI-4SM",
    stop_name: "SYCAMORE VIEW@I-40",
    stop_desc: "",
    stop_lat: 35.167234,
    stop_lon: -89.86351,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4137,
    stop_code: "SHESYCWF",
    stop_name: "SHELBY OAKS@SYCAMORE VIEW",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.167934,
    stop_lon: -89.865202,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4138,
    stop_code: "SHESU4WM",
    stop_name: "SHELBY OAKS@SUMMER TREES",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.16697,
    stop_lon: -89.866234,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4139,
    stop_code: "SHESUMWF",
    stop_name: "SHELBY OAKS@SUMMER TREES",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.164969,
    stop_lon: -89.866782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4140,
    stop_code: "SHESUMWM",
    stop_name: "SHELBY OAKS@SUMMER AVE",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.163321,
    stop_lon: -89.869455,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4141,
    stop_code: "SHESU1WM",
    stop_name: "SHELBY OAKS@SUMMER AVE",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.162511,
    stop_lon: -89.871793,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4142,
    stop_code: "SHESU2WM",
    stop_name: "SHELBY OAKS@SUMMER",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.162527,
    stop_lon: -89.875512,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4143,
    stop_code: "SHESU3WM",
    stop_name: "SHELBY OAKS@SUMMER",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.162564,
    stop_lon: -89.876476,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4145,
    stop_code: "SHESUMEM",
    stop_name: "SHELBY OAKS@SUMMER TREES",
    stop_desc: "",
    stop_lat: 35.162406,
    stop_lon: -89.874573,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4148,
    stop_code: "SHESYCNN",
    stop_name: "SHELBY OAKS@SYCAMORE VIEW",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.166988,
    stop_lon: -89.86598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4149,
    stop_code: "SHESYCSN",
    stop_name: "SHELBY OAKS@SYCAMORE VIEW",
    stop_desc: "SHELBY OAKS DR & SYCAMORE VIEW RD",
    stop_lat: 35.168022,
    stop_lon: -89.864759,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4150,
    stop_code: "ELLSTRWN",
    stop_name: "ELLISTON@STRIBLING",
    stop_desc: "",
    stop_lat: 35.086818,
    stop_lon: -89.932915,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4151,
    stop_code: "ELLHARWN",
    stop_name: "ELLISTON@HARVILLE ST",
    stop_desc: "",
    stop_lat: 35.086836,
    stop_lon: -89.933936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4156,
    stop_code: "ROBKIMNM",
    stop_name: "ROBIN HOOD@KIMBALL",
    stop_desc: "ROBIN HOOD LN & FREEMILE AVE",
    stop_lat: 35.09255,
    stop_lon: -89.9391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4157,
    stop_code: "KIMROBWF",
    stop_name: "KIMBALL AVE@ROBIN HOOD",
    stop_desc: "",
    stop_lat: 35.093274,
    stop_lon: -89.939936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4159,
    stop_code: "KIMSHIWN",
    stop_name: "KIMBALL AVE@S HIGHLAND ST",
    stop_desc: "",
    stop_lat: 35.093351,
    stop_lon: -89.944751,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4160,
    stop_code: "KIMHOPWN",
    stop_name: "KIMBALL AVE@HOPE",
    stop_desc: "",
    stop_lat: 35.093474,
    stop_lon: -89.947934,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4161,
    stop_code: "KIMRAIWN",
    stop_name: "KIMBALL AVE@RAILTON",
    stop_desc: "KIMBALL AVE & RAILTON RD",
    stop_lat: 35.094805,
    stop_lon: -89.950611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4162,
    stop_code: "KIMBROWN",
    stop_name: "KIMBALL AVE@BROWER",
    stop_desc: "KIMBALL AVE & BROWER ST",
    stop_lat: 35.094834,
    stop_lon: -89.952879,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4163,
    stop_code: "KIMGREWN",
    stop_name: "KIMBALL AVE@GREER",
    stop_desc: "KIMBALL AVE & PARKLAND RD",
    stop_lat: 35.094881,
    stop_lon: -89.955168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4164,
    stop_code: "KIMCATWN",
    stop_name: "KIMBALL AVE@CATHERINE",
    stop_desc: "KIMBALL AVE & CATHERINE ST",
    stop_lat: 35.094909,
    stop_lon: -89.95623,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4165,
    stop_code: "KIMALAWN",
    stop_name: "KIMBALL AVE@ALAMO",
    stop_desc: "KIMBALL AVE & ALAMO ST",
    stop_lat: 35.094956,
    stop_lon: -89.958542,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4166,
    stop_code: "KIMSEMWN",
    stop_name: "KIMBALL AVE@SEMMES ST",
    stop_desc: "",
    stop_lat: 35.095003,
    stop_lon: -89.961119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4171,
    stop_code: "KIMLAMEF",
    stop_name: "KIMBALL AVE@LAMAR AVE",
    stop_desc: "KIMBALL AVE & S PKWY",
    stop_lat: 35.094965,
    stop_lon: -89.96895,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4172,
    stop_code: "KIMHAREN",
    stop_name: "KIMBALL AVE@HARROW",
    stop_desc: "KIMBALL AVE & HARROW LN",
    stop_lat: 35.094964,
    stop_lon: -89.967335,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4173,
    stop_code: "KIMKIMEN",
    stop_name: "KIMBALL AVE@KIMBALL CV",
    stop_desc: "",
    stop_lat: 35.094884,
    stop_lon: -89.965412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4174,
    stop_code: "KIMRUTEF",
    stop_name: "KIMBALL AVE@RUTLAND",
    stop_desc: "KIMBALL AVE & RUTLAND RD",
    stop_lat: 35.094907,
    stop_lon: -89.963453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4175,
    stop_code: "KIMSEMEN",
    stop_name: "KIMBALL AVE@SEMMES",
    stop_desc: "KIMBALL AVE & SEMMES ST",
    stop_lat: 35.094851,
    stop_lon: -89.961429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4176,
    stop_code: "KIMALAEN",
    stop_name: "KIMBALL AVE@ALAMO",
    stop_desc: "KIMBALL AVE & ALAMO ST",
    stop_lat: 35.094804,
    stop_lon: -89.958918,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4177,
    stop_code: "KIMCATEN",
    stop_name: "KIMBALL AVE@CATHERINE",
    stop_desc: "KIMBALL AVE & CATHERINE ST",
    stop_lat: 35.094757,
    stop_lon: -89.956706,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4178,
    stop_code: "KIMGREEF",
    stop_name: "KIMBALL AVE@GREER",
    stop_desc: "KIMBALL AVE & S GREER ST",
    stop_lat: 35.09472,
    stop_lon: -89.955268,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4179,
    stop_code: "KIMCRIEN",
    stop_name: "KIMBALL AVE@CRIDER",
    stop_desc: "KIMBALL AVE & CRIDER ST",
    stop_lat: 35.094682,
    stop_lon: -89.953111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4180,
    stop_code: "KIMFOXEN",
    stop_name: "KIMBALL AVE@FOX ST",
    stop_desc: "",
    stop_lat: 35.094672,
    stop_lon: -89.952071,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4181,
    stop_code: "KIMRAIEN",
    stop_name: "KIMBALL AVE@RAILTON",
    stop_desc: "KIMBALL AVE & RAILTON RD",
    stop_lat: 35.094653,
    stop_lon: -89.950822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4182,
    stop_code: "KIMHOPEN",
    stop_name: "KIMBALL AVE@HOPE",
    stop_desc: "",
    stop_lat: 35.093453,
    stop_lon: -89.9486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4183,
    stop_code: "KIMSHIEN",
    stop_name: "KIMBALL AVE@SHIGHLAND",
    stop_desc: "",
    stop_lat: 35.093334,
    stop_lon: -89.945006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4184,
    stop_code: "KIMPATEM",
    stop_name: "KIMBALL AVE@PATTERSON",
    stop_desc: "",
    stop_lat: 35.093323,
    stop_lon: -89.943258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4185,
    stop_code: "KIMPATEN",
    stop_name: "PATTERSON@KIMBALL AVE",
    stop_desc: "",
    stop_lat: 35.093303,
    stop_lon: -89.941953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4186,
    stop_code: "KIMROBEN",
    stop_name: "KIMBALL AVE@ROBIN HOOD",
    stop_desc: "",
    stop_lat: 35.093135,
    stop_lon: -89.939403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4187,
    stop_code: "ROBKIMSF",
    stop_name: "ROBIN HOOD@KIMBALL",
    stop_desc: "ROBIN HOOD LN & FREEMILE AVE",
    stop_lat: 35.092425,
    stop_lon: -89.9393,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4188,
    stop_code: "ROBDUNSN",
    stop_name: "ROBIN HOOD@DUNN",
    stop_desc: "ROBIN HOOD LN & DUNN RD",
    stop_lat: 35.090745,
    stop_lon: -89.93938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4189,
    stop_code: "ROBSTUSM",
    stop_name: "ROBIN HOOD@STURGEON",
    stop_desc: "ROBIN HOOD LN & STURGEON AVE",
    stop_lat: 35.087965,
    stop_lon: -89.939506,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4190,
    stop_code: "ROBELLSN",
    stop_name: "ROBIN HOOD@ELLISTON",
    stop_desc: "ROBIN HOOD LN & STURGEON AVE",
    stop_lat: 35.086911,
    stop_lon: -89.939552,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4191,
    stop_code: "ELLROBEF",
    stop_name: "ELLISTON@ROBIN HOOD",
    stop_desc: "ELLISTON RD & DUNN RD",
    stop_lat: 35.086821,
    stop_lon: -89.938922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4192,
    stop_code: "ELLGREEM",
    stop_name: "ELLISTON@GREEN DOLPHIN",
    stop_desc: "ELLISTON RD & GREEN DOLPHIN ST",
    stop_lat: 35.086819,
    stop_lon: -89.937131,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4193,
    stop_code: "ELLECHEN",
    stop_name: "ELLISTON@ECHLES",
    stop_desc: "ELLISTON RD & GREEN DOLPHIN ST",
    stop_lat: 35.086781,
    stop_lon: -89.935604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4194,
    stop_code: "ELLECHEF",
    stop_name: "ELLISTON@ECHLES",
    stop_desc: "ELLISTON RD & ECHLES ST",
    stop_lat: 35.086762,
    stop_lon: -89.934631,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4195,
    stop_code: "ELLGETEN",
    stop_name: "ELLISTON@GETWELL",
    stop_desc: "ELLISTON RD & GETWELL RD",
    stop_lat: 35.086706,
    stop_lon: -89.932032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4255,
    stop_code: "BELCARSM",
    stop_name: "BELLEVUE@CARR AVE",
    stop_desc: "STATE HWY 3 & CARR AVE",
    stop_lat: 35.132167,
    stop_lon: -90.020913,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4256,
    stop_code: "BELCARSF",
    stop_name: "BELLEVUE@CARR AVE",
    stop_desc: "US HWY 51 & CARR AVE",
    stop_lat: 35.131247,
    stop_lon: -90.02098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4257,
    stop_code: "BELLAMSN",
    stop_name: "BELLEVUE BLVD@LAMAR AVE",
    stop_desc: "? BELLEVUE & LAMAR AVE",
    stop_lat: 35.129046,
    stop_lon: -90.021141,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4258,
    stop_code: "BELCENSN",
    stop_name: "BELLEVUE@CENTRAL AVE",
    stop_desc: "US HWY 51 & CENTRAL AVE",
    stop_lat: 35.126805,
    stop_lon: -90.021238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4259,
    stop_code: "BELSLESN",
    stop_name: "BELLEVUE@SLEDGE ST",
    stop_desc: "STATE HWY 3 & SLEDGE AVE",
    stop_lat: 35.125786,
    stop_lon: -90.021283,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4260,
    stop_code: "BELHEISN",
    stop_name: "BELLEVUE@HEISTAN PL",
    stop_desc: "US HWY 51 & HEISTAN PL",
    stop_lat: 35.124204,
    stop_lon: -90.021339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4261,
    stop_code: "BELWILSF",
    stop_name: "BELLEVUE@WILLIAMS AVE",
    stop_desc: "US HWY 51 & S BELLEVUE BLVD",
    stop_lat: 35.122185,
    stop_lon: -90.021429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4262,
    stop_code: "BELWALSN",
    stop_name: "BELLEVUE@WALKER AVE",
    stop_desc: "STATE HWY 3 & WALKER AVE",
    stop_lat: 35.119254,
    stop_lon: -90.02153,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4263,
    stop_code: "BELBEESN",
    stop_name: "BELLEVUE@BEECHWOOD",
    stop_desc: "US HWY 51 & WALKER AVE",
    stop_lat: 35.118163,
    stop_lon: -90.021575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4264,
    stop_code: "BELSAXSN",
    stop_name: "BELLEVUE@SAXON AVE",
    stop_desc: "US HWY 51 & SAXON AVE",
    stop_lat: 35.117261,
    stop_lon: -90.02162,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4265,
    stop_code: "BELMCLSN",
    stop_name: "BELLEVUE@MCLEMORE AVE",
    stop_desc: "? US HWY 51 & E MCLEMORE AVE",
    stop_lat: 35.115464,
    stop_lon: -90.021721,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4266,
    stop_code: "MCLJAMWF",
    stop_name: "MCLEMORE AVE@JAMES ST",
    stop_desc: "E MCLEMORE AVE & JAMES ST",
    stop_lat: 35.11542,
    stop_lon: -90.023126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4267,
    stop_code: "MCLGREWN",
    stop_name: "MCLEMORE AVE@GREENWOOD ST",
    stop_desc: "E MCLEMORE AVE & GREENWOOD ST",
    stop_lat: 35.115439,
    stop_lon: -90.02411,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4268,
    stop_code: "MCLENGWN",
    stop_name: "MCLEMORE AVE@ENGLEWOOD",
    stop_desc: "E MCLEMORE AVE & ENGLEWOOD ST",
    stop_lat: 35.115439,
    stop_lon: -90.025173,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4269,
    stop_code: "MCLSOMWF",
    stop_name: "MCLEMORE AVE@SOMERVILLE",
    stop_desc: "E MCLEMORE AVE & S SOMERVILLE ST",
    stop_lat: 35.115475,
    stop_lon: -90.026478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4270,
    stop_code: "MCLCUMWF",
    stop_name: "MCLEMORE AVE@CUMMINGS",
    stop_desc: "E MCLEMORE AVE & CUMMINGS ST",
    stop_lat: 35.115502,
    stop_lon: -90.027551,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4271,
    stop_code: "MCLAZAWN",
    stop_name: "MCLEMORE AVE@AZALIA",
    stop_desc: "E MCLEMORE AVE & AZALIA ST",
    stop_lat: 35.115521,
    stop_lon: -90.028359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4272,
    stop_code: "MCLCOLWF",
    stop_name: "MCLEMORE AVE@COLLEGE",
    stop_desc: "E MCLEMORE AVE & COLLEGE ST",
    stop_lat: 35.115566,
    stop_lon: -90.030406,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4273,
    stop_code: "MCLNEPWM",
    stop_name: "MCLEMORE AVE@NEPTUNE ST",
    stop_desc: "E MCLEMORE AVE & NEPTUNE ST",
    stop_lat: 35.115585,
    stop_lon: -90.032696,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4274,
    stop_code: "MCLFOUWN",
    stop_name: "MCLEMORE AVE@FOUNTAIN CT",
    stop_desc: "E MCLEMORE AVE & FOUNTAIN CT",
    stop_lat: 35.115629,
    stop_lon: -90.033625,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4275,
    stop_code: "MCLKRAWM",
    stop_name: "MCLEMORE AVE@KRAYER",
    stop_desc: "E MCLEMORE AVE & RACE ST",
    stop_lat: 35.115684,
    stop_lon: -90.036181,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4276,
    stop_code: "MCLMISWN",
    stop_name: "MCLEMORE AVE@MISSISSIPPI",
    stop_desc: "E MCLEMORE AVE & MISSISSIPPI BLVD",
    stop_lat: 35.115702,
    stop_lon: -90.037099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4277,
    stop_code: "MCLPONWN",
    stop_name: "MCLEMORE AVE@POND ST",
    stop_desc: "E MCLEMORE AVE & MISSISSIPPI BLVD",
    stop_lat: 35.115747,
    stop_lon: -90.038737,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4278,
    stop_code: "MCLORLWN",
    stop_name: "MCLEMORE AVE@ORLEANS",
    stop_desc: "E MCLEMORE AVE & POND ST",
    stop_lat: 35.115783,
    stop_lon: -90.040861,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4279,
    stop_code: "MCLLAUWN",
    stop_name: "MCLEMORE AVE@LAUDERDALE ST",
    stop_desc: "? E MCLEMORE AVE & S LAUDERDALE ST",
    stop_lat: 35.115838,
    stop_lon: -90.043162,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4280,
    stop_code: "MCLLAUWF",
    stop_name: "MCLEMORE AVE@LAUDERDALE",
    stop_desc: "E MCLEMORE AVE & S LAUDERDALE ST",
    stop_lat: 35.115882,
    stop_lon: -90.04366,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4281,
    stop_code: "MCLWELWM",
    stop_name: "MCLEMORE AVE@WELLINGTON ST",
    stop_desc: "E MCLEMORE AVE & S WELLINGTON ST",
    stop_lat: 35.115918,
    stop_lon: -90.045054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4282,
    stop_code: "MCLWELWN",
    stop_name: "MCLEMORE AVE@WELLINGTON ST",
    stop_desc: "",
    stop_lat: 35.115914,
    stop_lon: -90.046875,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4285,
    stop_code: "MCLLATWM",
    stop_name: "MCLEMORE AVE@LATHAM",
    stop_desc: "E MCLEMORE AVE & LATHAM ST",
    stop_lat: 35.116036,
    stop_lon: -90.051947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4288,
    stop_code: "THIELMNM",
    stop_name: "THIRD@ELM AVE",
    stop_desc: "US HWY 61 & ELM AVE",
    stop_lat: 35.117913,
    stop_lon: -90.055067,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4289,
    stop_code: "THIWALNN",
    stop_name: "THIRD@WALKER AVE",
    stop_desc: "S THIRD ST & ELM AVE",
    stop_lat: 35.119683,
    stop_lon: -90.055,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4290,
    stop_code: "THILUCNN",
    stop_name: "THIRD@LUCERNE PL",
    stop_desc: "S THIRD ST & LUCERNE PL",
    stop_lat: 35.121515,
    stop_lon: -90.054922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4291,
    stop_code: "THIMARNN",
    stop_name: "THIRD@MARYLAND AVE",
    stop_desc: "S THIRD ST & MARYLAND AVE",
    stop_lat: 35.122676,
    stop_lon: -90.054889,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4292,
    stop_code: "THICRUNN",
    stop_name: "THIRD@CRUMP BLVD",
    stop_desc: "S THIRD ST & ALSTON AVE",
    stop_lat: 35.124428,
    stop_lon: -90.054811,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4293,
    stop_code: "THIVIRNN",
    stop_name: "THIRD@VIRGINIA",
    stop_desc: "S THIRD ST & E VIRGINIA AVE",
    stop_lat: 35.125518,
    stop_lon: -90.054756,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4294,
    stop_code: "THIECANN",
    stop_name: "THIRD@ECAROLINA",
    stop_desc: "S THIRD ST & E CAROLINA AVE",
    stop_lat: 35.127922,
    stop_lon: -90.054678,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4295,
    stop_code: "THIEGENN",
    stop_name: "THIRD@EGEORGIA",
    stop_desc: "S THIRD ST & E CAROLINA AVE",
    stop_lat: 35.129004,
    stop_lon: -90.054633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4296,
    stop_code: "THISTPNN",
    stop_name: "THIRD@STPAUL",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.131399,
    stop_lon: -90.054555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4297,
    stop_code: "THISTPSN",
    stop_name: "THIRD@ST PAUL",
    stop_desc: "? STATE HWY 14 & SAINT PAUL AVE",
    stop_lat: 35.131622,
    stop_lon: -90.054743,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4298,
    stop_code: "THIEGESN",
    stop_name: "THIRD@EGEORGIA",
    stop_desc: "S THIRD ST & E GEORGIA AVE",
    stop_lat: 35.129272,
    stop_lon: -90.054821,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4299,
    stop_code: "THIECASN",
    stop_name: "THIRD@ECAROLINA",
    stop_desc: "STATE HWY 14 & E CAROLINA AVE",
    stop_lat: 35.128226,
    stop_lon: -90.054866,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4300,
    stop_code: "THIVIRSN",
    stop_name: "THIRD@VIRGINIA",
    stop_desc: "S THIRD ST & STATE HWY 14",
    stop_lat: 35.125786,
    stop_lon: -90.054944,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4301,
    stop_code: "THICRUSN",
    stop_name: "THIRD@CRUMP",
    stop_desc: "STATE HWY 14 & E VIRGINIA AVE",
    stop_lat: 35.124723,
    stop_lon: -90.054999,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4302,
    stop_code: "THIMARSM",
    stop_name: "THIRD@MARYLAND",
    stop_desc: "S THIRD ST & MARYLAND AVE",
    stop_lat: 35.122864,
    stop_lon: -90.055077,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4303,
    stop_code: "THILUCSN",
    stop_name: "THIRD@LUCERNE",
    stop_desc: "S THIRD ST & LUCERNE PL",
    stop_lat: 35.12172,
    stop_lon: -90.05511,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4304,
    stop_code: "THIWALSM",
    stop_name: "THIRD@WALKER",
    stop_desc: "US HWY 61 & WALKER AVE",
    stop_lat: 35.11996,
    stop_lon: -90.055188,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4305,
    stop_code: "THIELMSM",
    stop_name: "THIRD@ELM AVE",
    stop_desc: "S THIRD ST & ELM AVE",
    stop_lat: 35.118708,
    stop_lon: -90.055233,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4306,
    stop_code: "THIGASSN",
    stop_name: "THIRD@GASTON",
    stop_desc: "S THIRD ST & GASTON AVE",
    stop_lat: 35.117743,
    stop_lon: -90.055266,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4310,
    stop_code: "MCLSFOEN",
    stop_name: "MCLEMORE@S FOURTH",
    stop_desc: "E MCLEMORE AVE & S 4TH ST",
    stop_lat: 35.115893,
    stop_lon: -90.051317,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4313,
    stop_code: "WELEDINN",
    stop_name: "WELLINGTON@EDITH",
    stop_desc: "S WELLINGTON ST & EDITH AVE",
    stop_lat: 35.116777,
    stop_lon: -90.047112,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4314,
    stop_code: "WELLACNN",
    stop_name: "WELLINGTON@LACLEDE",
    stop_desc: "S WELLINGTON ST & LACLEDE AVE",
    stop_lat: 35.118341,
    stop_lon: -90.047056,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4315,
    stop_code: "WELWALNN",
    stop_name: "WELLINGTON@WALKER",
    stop_desc: "S WELLINGTON ST & LACLEDE AVE",
    stop_lat: 35.119476,
    stop_lon: -90.047056,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4316,
    stop_code: "WELWICNN",
    stop_name: "WELLINGTON@WICKS",
    stop_desc: "WELLINGTON ST & S WELLINGTON ST",
    stop_lat: 35.121245,
    stop_lon: -90.047055,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4317,
    stop_code: "WELMCENM",
    stop_name: "WELLINGTON@MCEWEN PL",
    stop_desc: "WELLINGTON ST & MCEWEN PL",
    stop_lat: 35.121924,
    stop_lon: -90.047022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4318,
    stop_code: "WELALSNN",
    stop_name: "WELLINGTON@ALSTON",
    stop_desc: "WELLINGTON ST & ALSTON AVE",
    stop_lat: 35.123426,
    stop_lon: -90.046955,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4319,
    stop_code: "DANCARNM",
    stop_name: "DANNY THOMAS@CAROLINA",
    stop_desc: "S DANNY THOMAS BLVD & US HWY 79",
    stop_lat: 35.125678,
    stop_lon: -90.0467,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4320,
    stop_code: "DANCARNF",
    stop_name: "DANNY THOMAS@CAROLINA",
    stop_desc: "US HWY 79 & E CAROLINA AVE",
    stop_lat: 35.127564,
    stop_lon: -90.046666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4321,
    stop_code: "DANGEONF",
    stop_name: "DANNY THOMAS@GEORGIA",
    stop_desc: "S DANNY THOMAS BLVD & US HWY 79",
    stop_lat: 35.129315,
    stop_lon: -90.047075,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4323,
    stop_code: "DANSTPNN",
    stop_name: "DANNY THOMAS@ST PAUL",
    stop_desc: "US HWY 79 & SAINT PAUL AVE",
    stop_lat: 35.131308,
    stop_lon: -90.047241,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4339,
    stop_code: "DANPOPSF",
    stop_name: "DANNY THOMAS@POPLAR",
    stop_desc: "US HWY 51 & POPLAR AVE",
    stop_lat: 35.148108,
    stop_lon: -90.043694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4344,
    stop_code: "DANSAISF",
    stop_name: "DANNY THOMAS@SAINT PAUL",
    stop_desc: "US HWY 79 & SAINT PAUL AVE",
    stop_lat: 35.131281,
    stop_lon: -90.047628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4345,
    stop_code: "DANEGESF",
    stop_name: "DANNY THOMAS@EGEORGIA",
    stop_desc: "0 S DANNY THOMAS BLVD",
    stop_lat: 35.128859,
    stop_lon: -90.047429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4347,
    stop_code: "DANCRUSM",
    stop_name: "DANNY THOMAS@CRUMP",
    stop_desc: "US HWY 79 & RAMP",
    stop_lat: 35.124999,
    stop_lon: -90.047076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4348,
    stop_code: "WELWILSN",
    stop_name: "WELLINGTON@WILLIAMS",
    stop_desc: "WELLINGTON ST & WILLIAMS AVE",
    stop_lat: 35.122836,
    stop_lon: -90.047188,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4349,
    stop_code: "WELMCESN",
    stop_name: "WELLINGTON@MCEWEN",
    stop_desc: "S WELLINGTON ST & MCEWEN PL",
    stop_lat: 35.122076,
    stop_lon: -90.04721,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4350,
    stop_code: "WELWALSF",
    stop_name: "WELLINGTON@WALKER",
    stop_desc: "S WELLINGTON ST & WELLINGTON ST",
    stop_lat: 35.119422,
    stop_lon: -90.047333,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4351,
    stop_code: "WELWALSN",
    stop_name: "WELLINGTON@WALKER",
    stop_desc: "S WELLINGTON ST & LACLEDE AVE",
    stop_lat: 35.118591,
    stop_lon: -90.047399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4352,
    stop_code: "WELLACSF",
    stop_name: "WELLINGTON@LACLEDE",
    stop_desc: "WELLINGTON ST & LACLEDE AVE",
    stop_lat: 35.118394,
    stop_lon: -90.047388,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4354,
    stop_code: "MCLWELEF",
    stop_name: "MCLEMORE AVE@WELLINGTON ST",
    stop_desc: "E MCLEMORE AVE & S WELLINGTON ST",
    stop_lat: 35.115794,
    stop_lon: -90.046803,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4355,
    stop_code: "MCLSLAEM",
    stop_name: "MCLEMORE@SLAUDERDALE",
    stop_desc: "E MCLEMORE AVE & LACLEDE AVE",
    stop_lat: 35.115775,
    stop_lon: -90.044822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4356,
    stop_code: "MCLSLAEN",
    stop_name: "MCLEMORE@SLAUDERDALE",
    stop_desc: "E MCLEMORE AVE & S LAUDERDALE ST",
    stop_lat: 35.115748,
    stop_lon: -90.04355,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4357,
    stop_code: "MCLSOREN",
    stop_name: "MCLEMORE@SORLEANS",
    stop_desc: "E MCLEMORE AVE & S ORLEANS ST",
    stop_lat: 35.115685,
    stop_lon: -90.041105,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4358,
    stop_code: "MCLPONEN",
    stop_name: "MCLEMORE@POND",
    stop_desc: "E MCLEMORE AVE & POND ST",
    stop_lat: 35.115649,
    stop_lon: -90.038969,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4359,
    stop_code: "MCLMISEN",
    stop_name: "MCLEMORE@MISSISSIPPI BLV",
    stop_desc: "E MCLEMORE AVE & MISSISSIPPI BLVD",
    stop_lat: 35.115604,
    stop_lon: -90.037276,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4360,
    stop_code: "MCLKRAEF",
    stop_name: "MCLEMORE@KRAYER",
    stop_desc: "E MCLEMORE AVE & KRAYER ST",
    stop_lat: 35.115568,
    stop_lon: -90.035893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4361,
    stop_code: "MCLFOUEN",
    stop_name: "MCLEMORE@FOUNTAIN",
    stop_desc: "E MCLEMORE AVE & FOUNTAIN CT",
    stop_lat: 35.11554,
    stop_lon: -90.034046,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4362,
    stop_code: "MCLCOLEM",
    stop_name: "MCLEMORE@COLLEGE",
    stop_desc: "E MCLEMORE AVE & BEACH ST",
    stop_lat: 35.115468,
    stop_lon: -90.031379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4363,
    stop_code: "MCLCOLEF",
    stop_name: "MCLEMORE@COLLEGE",
    stop_desc: "E MCLEMORE AVE & S LAUDERDALE ST",
    stop_lat: 35.115432,
    stop_lon: -90.029775,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4364,
    stop_code: "MCLAZAEN",
    stop_name: "MCLEMORE@AZALIA",
    stop_desc: "E MCLEMORE AVE & AZALIA ST",
    stop_lat: 35.115413,
    stop_lon: -90.028768,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4365,
    stop_code: "MCLCUMEF",
    stop_name: "MCLEMORE@CUMMINS",
    stop_desc: "E MCLEMORE AVE & CUMMINGS ST",
    stop_lat: 35.115395,
    stop_lon: -90.02754,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4366,
    stop_code: "MCLENGEN",
    stop_name: "MCLEMORE@ENGLEWOOD",
    stop_desc: "E MCLEMORE AVE & ENGLEWOOD ST",
    stop_lat: 35.115341,
    stop_lon: -90.025316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4367,
    stop_code: "MCLGREEN",
    stop_name: "MCLEMORE@GREENWOOD",
    stop_desc: "E MCLEMORE AVE & GREENWOOD ST",
    stop_lat: 35.115313,
    stop_lon: -90.024199,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4368,
    stop_code: "MCLJAMEN",
    stop_name: "MCLEMORE@JAMES",
    stop_desc: "E MCLEMORE AVE & JAMES ST",
    stop_lat: 35.115286,
    stop_lon: -90.023059,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4369,
    stop_code: "BELMCLNF",
    stop_name: "BELLEVUE@MCLEMORE",
    stop_desc: "STATE HWY 3 & E MCLEMORE AVE",
    stop_lat: 35.115491,
    stop_lon: -90.021521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4370,
    stop_code: "BELSAXNN",
    stop_name: "BELLEVUE@SAXON",
    stop_desc: "STATE HWY 3 & SAXON AVE",
    stop_lat: 35.117028,
    stop_lon: -90.021432,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4371,
    stop_code: "BELBEENN",
    stop_name: "BELLEVUE@BEECHWOOD",
    stop_desc: "US HWY 51 & BEECHWOOD AVE",
    stop_lat: 35.117967,
    stop_lon: -90.021387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4372,
    stop_code: "BELSEVNM",
    stop_name: "BELLEVUE@SEVERSON",
    stop_desc: "STATE HWY 3 & SEVERSON AVE",
    stop_lat: 35.119504,
    stop_lon: -90.02132,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4373,
    stop_code: "BELWILNN",
    stop_name: "BELLEVUE@WILLIAMS",
    stop_desc: "STATE HWY 3 & WILLIAMS AVE",
    stop_lat: 35.122086,
    stop_lon: -90.02123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4374,
    stop_code: "BELAGNNN",
    stop_name: "BELLEVUE@AGNES",
    stop_desc: "STATE HWY 3 & AGNES PL",
    stop_lat: 35.124464,
    stop_lon: -90.021128,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4375,
    stop_code: "BELCENNM",
    stop_name: "BELLEVUE@CENTRAL",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.126054,
    stop_lon: -90.021072,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4376,
    stop_code: "BELLAMNN",
    stop_name: "BELLEVUE BLVD@ LAMAR AVE",
    stop_desc: "US HWY 51 & S BELLEVUE BLVD",
    stop_lat: 35.128181,
    stop_lon: -90.020982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4378,
    stop_code: "BELCARNM",
    stop_name: "BELLEVUE@CARR",
    stop_desc: "STATE HWY 3 & CARR AVE",
    stop_lat: 35.131246,
    stop_lon: -90.020781,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4379,
    stop_code: "BELPEANN",
    stop_name: "BELLEVUE@PEABODY",
    stop_desc: "STATE HWY 3 & CARR AVE",
    stop_lat: 35.132587,
    stop_lon: -90.020703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4383,
    stop_code: "COUSOMEN",
    stop_name: "COURT@SOMERVILLE ST",
    stop_desc: "COURT AVE & N SOMERVILLE ST",
    stop_lat: 35.141043,
    stop_lon: -90.025578,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4385,
    stop_code: "SOMCOUSF",
    stop_name: "SOMERVILLE@COURT",
    stop_desc: "N SOMERVILLE ST & COURT AVE",
    stop_lat: 35.14124,
    stop_lon: -90.025467,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4393,
    stop_code: "PAUEASSF",
    stop_name: "PAULINE@EAST MORELAND",
    stop_desc: "S PAULINE ST & EASTMORELAND AVE",
    stop_lat: 35.135905,
    stop_lon: -90.028359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4395,
    stop_code: "MLKCAMWN",
    stop_name: "MLK BLVD@CAMILLA",
    stop_desc: "? STATE HWY 278 & S ORLEANS ST",
    stop_lat: 35.134618,
    stop_lon: -90.026666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4396,
    stop_code: "PAUEASNN",
    stop_name: "PAULINE@EASTMORLAND",
    stop_desc: "S PAULINE ST & EASTMORELAND AVE",
    stop_lat: 35.135888,
    stop_lon: -90.02816,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4397,
    stop_code: "PAUUNINM",
    stop_name: "PAULINE@UNION",
    stop_desc: "S PAULINE ST & EASTMORELAND AVE",
    stop_lat: 35.136915,
    stop_lon: -90.028126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4398,
    stop_code: "LAUGRASF",
    stop_name: "LAUDERDALE@GRACE",
    stop_desc: "S LAUDERDALE ST & GRACE ALY",
    stop_lat: 35.133837,
    stop_lon: -90.043876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4399,
    stop_code: "LAUSTPSN",
    stop_name: "LAUDERDALE@ST PAUL",
    stop_desc: "S LAUDERDALE ST & SAINT PAUL AVE",
    stop_lat: 35.132353,
    stop_lon: -90.044075,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4400,
    stop_code: "LAUGEOSM",
    stop_name: "LAUDERDALE@GEORGIA",
    stop_desc: "S LAUDERDALE ST & S LAUDERDALE ST",
    stop_lat: 35.130593,
    stop_lon: -90.044364,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4402,
    stop_code: "MISPHESN",
    stop_name: "MISSISSIPPI BLVD@PHELAN",
    stop_desc: "MISSISSIPPI BLVD & PHELAN AVE",
    stop_lat: 35.127438,
    stop_lon: -90.043988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4403,
    stop_code: "MISGROSF",
    stop_name: "MISSISSIPPI BLVD@GOYER",
    stop_desc: "MISSISSIPPI BLVD & GOYER PL",
    stop_lat: 35.125418,
    stop_lon: -90.042484,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4404,
    stop_code: "MISCRUSN",
    stop_name: "MISSISSIPPI BLVD@PEARL",
    stop_desc: "MISSISSIPPI BLVD & PEARL PL",
    stop_lat: 35.124515,
    stop_lon: -90.041765,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4405,
    stop_code: "MISALSSN",
    stop_name: "MISSISSIPPI BLVD@ALSTON",
    stop_desc: "MISSISSIPPI BLVD & ALSTON AVE",
    stop_lat: 35.123335,
    stop_lon: -90.041035,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4406,
    stop_code: "MISWILSN",
    stop_name: "MISSISSIPPI BLVD@WILLIAMS",
    stop_desc: "MISSISSIPPI BLVD & WILLIAMS AVE",
    stop_lat: 35.122432,
    stop_lon: -90.040538,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4407,
    stop_code: "MISWICSN",
    stop_name: "MISSISSIPPI BLVD@WICKS",
    stop_desc: "MISSISSIPPI BLVD & WICKS AVE",
    stop_lat: 35.121485,
    stop_lon: -90.040007,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4408,
    stop_code: "MISWALSM",
    stop_name: "MISSISSIPPI BLVD@WALKER",
    stop_desc: "MISSISSIPPI BLVD & STEPHENS PL",
    stop_lat: 35.11993,
    stop_lon: -90.039122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4409,
    stop_code: "WALFOREN",
    stop_name: "WALKER@FORD",
    stop_desc: "",
    stop_lat: 35.119411,
    stop_lon: -90.03795,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4410,
    stop_code: "WALDRHEN",
    stop_name: "WALKER@DR HOLLIS F PRICE",
    stop_desc: "WALKER AVE & DR HOLLIS F PRICE ST",
    stop_lat: 35.119339,
    stop_lon: -90.036014,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4411,
    stop_code: "WALLEMEF",
    stop_name: "WALKER@LEMOYNE MALL",
    stop_desc: "WALKER AVE & LEMOYNE MALL",
    stop_lat: 35.119321,
    stop_lon: -90.034841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4412,
    stop_code: "WALNEPEM",
    stop_name: "WALKER@NEPTUNE",
    stop_desc: "WALKER AVE & NEPTUNE ST",
    stop_lat: 35.119258,
    stop_lon: -90.033081,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4413,
    stop_code: "COLWALSF",
    stop_name: "COLLEGE@WALKER",
    stop_desc: "0 COLLEGE ST & FERRY CT",
    stop_lat: 35.119194,
    stop_lon: -90.030791,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4414,
    stop_code: "COLSAXSN",
    stop_name: "COLLEGE@SAXON",
    stop_desc: "COLLEGE ST & SAXON AVE",
    stop_lat: 35.117568,
    stop_lon: -90.030825,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4416,
    stop_code: "CUMMCLSF",
    stop_name: "CUMMINGS@MCLEMORE",
    stop_desc: "CUMMINGS ST & E MCLEMORE AVE",
    stop_lat: 35.115082,
    stop_lon: -90.027607,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4418,
    stop_code: "CUMTALSF",
    stop_name: "CUMMINGS@TALLEY PL",
    stop_desc: "CUMMINGS ST & TALLEY PL",
    stop_lat: 35.113018,
    stop_lon: -90.027674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4420,
    stop_code: "CUMSTANN",
    stop_name: "CUMMINGS@STAFFORD",
    stop_desc: "CUMMINGS ST & STAFFORD AVE",
    stop_lat: 35.114099,
    stop_lon: -90.027441,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4421,
    stop_code: "CUMMCLNN",
    stop_name: "CUMMINGS@MCLEMORE",
    stop_desc: "",
    stop_lat: 35.115306,
    stop_lon: -90.027507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4438,
    stop_code: "COLMCLNF",
    stop_name: "COLLEGE@MCLEMORE",
    stop_desc: "COLLEGE ST & E MCLEMORE AVE",
    stop_lat: 35.115923,
    stop_lon: -90.030682,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4440,
    stop_code: "COLWALNN",
    stop_name: "COLLEGE@WALKER",
    stop_desc: "COLLEGE ST & WALKER AVE",
    stop_lat: 35.119203,
    stop_lon: -90.03057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4441,
    stop_code: "WALNEPWF",
    stop_name: "WALKER@NEPTUNE",
    stop_desc: "WALKER AVE & NEPTUNE ST",
    stop_lat: 35.119418,
    stop_lon: -90.032749,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4442,
    stop_code: "WALLEMWN",
    stop_name: "WALKER@LEMOYNE MALL",
    stop_desc: "WALKER AVE & LEMOYNE MALL",
    stop_lat: 35.119473,
    stop_lon: -90.034664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4443,
    stop_code: "WALPORWN",
    stop_name: "WALKER@PORTER",
    stop_desc: "WALKER AVE & PORTER ST",
    stop_lat: 35.119518,
    stop_lon: -90.036611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4444,
    stop_code: "WALMISWN",
    stop_name: "WALKER@MISSISSIPPI",
    stop_desc: "WALKER AVE & MISSISSIPPI BLVD",
    stop_lat: 35.119554,
    stop_lon: -90.038658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4445,
    stop_code: "MISWICNN",
    stop_name: "MISSISSIPPI@WICKS ALLEY",
    stop_desc: "MISSISSIPPI BLVD & WICKS AVE",
    stop_lat: 35.120904,
    stop_lon: -90.039421,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4446,
    stop_code: "MISWICNF",
    stop_name: "MISSISSIPPI@WICKS",
    stop_desc: "MISSISSIPPI BLVD & WICKS AVE",
    stop_lat: 35.121556,
    stop_lon: -90.039808,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4447,
    stop_code: "MISWILNN",
    stop_name: "MISSISSIPPI@WILLIAMS",
    stop_desc: "MISSISSIPPI BLVD & WICKS AVE",
    stop_lat: 35.122084,
    stop_lon: -90.040106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4448,
    stop_code: "MISALSNF",
    stop_name: "MISSISSIPPI@ALSTON",
    stop_desc: "MISSISSIPPI BLVD & ALSTON AVE",
    stop_lat: 35.123478,
    stop_lon: -90.040869,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4449,
    stop_code: "MISPEANN",
    stop_name: "MISSISSIPPI@PEARL",
    stop_desc: "MISSISSIPPI BLVD & PEARL PL",
    stop_lat: 35.124551,
    stop_lon: -90.041555,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4450,
    stop_code: "MISGOYNF",
    stop_name: "MISSISSIPPI@GOYER",
    stop_desc: "MISSISSIPPI BLVD & GOYER PL",
    stop_lat: 35.126017,
    stop_lon: -90.042694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4451,
    stop_code: "MISSLANN",
    stop_name: "MISSISSIPPI@SLAUDERDALE",
    stop_desc: "MISSISSIPPI BLVD & GOYER PL",
    stop_lat: 35.127768,
    stop_lon: -90.043988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4452,
    stop_code: "LAUEGENN",
    stop_name: "LAUDERDALE@EGEORGIA",
    stop_desc: "S LAUDERDALE ST & MISSISSIPPI BLVD",
    stop_lat: 35.1291,
    stop_lon: -90.044331,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4453,
    stop_code: "LAUSTPNM",
    stop_name: "LAUDERDALE@STPAUL",
    stop_desc: "0 S LAUDERDALE ST & UNNAMED ST",
    stop_lat: 35.130995,
    stop_lon: -90.044131,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4454,
    stop_code: "LAUGRANF",
    stop_name: "LAUDERDALE@GRACE",
    stop_desc: "S LAUDERDALE ST & GRACE ALY",
    stop_lat: 35.134078,
    stop_lon: -90.043676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4465,
    stop_code: "FLOWCASM",
    stop_name: "FLORIDA@WCAROLINA",
    stop_desc: "FLORIDA ST & W CAROLINA AVE",
    stop_lat: 35.12879,
    stop_lon: -90.061904,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4466,
    stop_code: "FLOVIRSF",
    stop_name: "FLORIDA@VIRGINIA",
    stop_desc: "FLORIDA ST & E VIRGINIA AVE",
    stop_lat: 35.125447,
    stop_lon: -90.061992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4467,
    stop_code: "FLOCRUSF",
    stop_name: "FLORIDA@CRUMP",
    stop_desc: "FLORIDA ST & E BEAN ALY",
    stop_lat: 35.123982,
    stop_lon: -90.062059,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4468,
    stop_code: "FLODESSN",
    stop_name: "FLORIDA@DESOTO",
    stop_desc: "FLORIDA ST & E DESOTO AVE",
    stop_lat: 35.122427,
    stop_lon: -90.062114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4469,
    stop_code: "FLOCOLSN",
    stop_name: "FLORIDA@COLORADO",
    stop_desc: "FLORIDA ST & W COLORADO AVE",
    stop_lat: 35.120908,
    stop_lon: -90.062159,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4470,
    stop_code: "FLOHAPSM",
    stop_name: "FLORIDA@HAPPILAND ALLEY",
    stop_desc: "FLORIDA ST & DAKOTA PL",
    stop_lat: 35.118709,
    stop_lon: -90.062259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4471,
    stop_code: "FLOHAPSN",
    stop_name: "FLORIDA@HAPPILAND",
    stop_desc: "FLORIDA ST & HAPPILAND PL",
    stop_lat: 35.116841,
    stop_lon: -90.062314,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4474,
    stop_code: "MCLTHIEN",
    stop_name: "MCLEMORE@THIRD ST",
    stop_desc: "E MCLEMORE AVE & TEXAS ST",
    stop_lat: 35.116001,
    stop_lon: -90.05541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4478,
    stop_code: "FLOCOLNM",
    stop_name: "FLORIDA@COLORADO",
    stop_desc: "FLORIDA ST & E BELZ BLVD",
    stop_lat: 35.118441,
    stop_lon: -90.062071,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4479,
    stop_code: "FLOCOLNN",
    stop_name: "FLORIDA@COLORADO",
    stop_desc: "FLORIDA ST & E COLORADO AVE",
    stop_lat: 35.120613,
    stop_lon: -90.061971,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4480,
    stop_code: "FLODESNN",
    stop_name: "FLORIDA@DESOTO",
    stop_desc: "FLORIDA ST & E COLORADO AVE",
    stop_lat: 35.122043,
    stop_lon: -90.061926,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4481,
    stop_code: "FLOCRUNF",
    stop_name: "FLORIDA@CRUMP",
    stop_desc: "FLORIDA ST & W EH CRUMP BLVD",
    stop_lat: 35.124938,
    stop_lon: -90.061815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4482,
    stop_code: "FLOVIRNN",
    stop_name: "FLORIDA@VIRGINIA",
    stop_desc: "FLORIDA ST & E EH CRUMP BLVD",
    stop_lat: 35.125653,
    stop_lon: -90.061782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4483,
    stop_code: "FLOGEONN",
    stop_name: "FLORIDA@GEORGIA",
    stop_desc: "FLORIDA ST & E GEORGIA AVE",
    stop_lat: 35.129022,
    stop_lon: -90.061693,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4515,
    stop_code: "CHENHIWF",
    stop_name: "CHELSEA AVE@NHIGHLAND",
    stop_desc: "CHELSEA AVE & PANAMA ST",
    stop_lat: 35.174086,
    stop_lon: -89.945007,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4516,
    stop_code: "CHEPANWF",
    stop_name: "CHELSEA AVE@PANAMA",
    stop_desc: "CHELSEA AVE & PANAMA ST",
    stop_lat: 35.173507,
    stop_lon: -89.946901,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4517,
    stop_code: "CHEHOLWM",
    stop_name: "CHELSEA AVE@HOLMES ST",
    stop_desc: "CHELSEA AVE & N HOLMES ST",
    stop_lat: 35.173358,
    stop_lon: -89.949083,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4518,
    stop_code: "CHEELLWN",
    stop_name: "CHELSEA AVE@ELLINGTON",
    stop_desc: "CHELSEA AVE & ELLINGTON ST",
    stop_lat: 35.173386,
    stop_lon: -89.950766,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4519,
    stop_code: "CHEASHWN",
    stop_name: "CHELSEA AVE@ASH",
    stop_desc: "CHELSEA AVE & ASH ST",
    stop_lat: 35.17347,
    stop_lon: -89.9536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4520,
    stop_code: "CHEBROWN",
    stop_name: "CHELSEA AVE@BROOKINS",
    stop_desc: "CHELSEA AVE & BROOKINS ST",
    stop_lat: 35.173525,
    stop_lon: -89.955516,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4521,
    stop_code: "CHELOCWN",
    stop_name: "CHELSEA AVE@LOCUST",
    stop_desc: "CHELSEA AVE & LOCUST ST",
    stop_lat: 35.173714,
    stop_lon: -89.957099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4522,
    stop_code: "CHESUNWF",
    stop_name: "CHELSEA AVE@SUNSET",
    stop_desc: "CHELSEA AVE & SUNSET ST",
    stop_lat: 35.173994,
    stop_lon: -89.959745,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4523,
    stop_code: "CHELAKWN",
    stop_name: "CHELSEA AVE@LAKE GROVE",
    stop_desc: "",
    stop_lat: 35.17412,
    stop_lon: -89.961228,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4524,
    stop_code: "CHECARWN",
    stop_name: "CHELSEA AVE@CARPENTER",
    stop_desc: "CHELSEA AVE & CARPENTER ST",
    stop_lat: 35.174301,
    stop_lon: -89.963287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4525,
    stop_code: "CHECARWF",
    stop_name: "CHELSEA AVE@CARPENTER",
    stop_desc: "CHELSEA AVE & CARPENTER ST",
    stop_lat: 35.174365,
    stop_lon: -89.965491,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4526,
    stop_code: "CHEFAIWM",
    stop_name: "CHELSEA AVE@FAIRFAX",
    stop_desc: "CHELSEA AVE & FAIRFAX ST",
    stop_lat: 35.174466,
    stop_lon: -89.967849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4527,
    stop_code: "CHEFAIWN",
    stop_name: "CHELSEA AVE@FAIRFAX ST",
    stop_desc: "? CHELSEA AVE & FAIRFAX ST",
    stop_lat: 35.174548,
    stop_lon: -89.970307,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4528,
    stop_code: "CHESTAWN",
    stop_name: "CHELSEA AVE@STANDRIDGE",
    stop_desc: "CHELSEA AVE & STANDRIDGE ST",
    stop_lat: 35.174603,
    stop_lon: -89.971824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4529,
    stop_code: "CHEMERWF",
    stop_name: "CHELSEA AVE@MERTON",
    stop_desc: "CHELSEA AVE & N MERTON ST",
    stop_lat: 35.174649,
    stop_lon: -89.973252,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4530,
    stop_code: "CHEHO1WF",
    stop_name: "CHELSEA AVE@HOLLYWOOD",
    stop_desc: "0 CHELSEA AVE & OAKWOOD ST",
    stop_lat: 35.174696,
    stop_lon: -89.975788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4531,
    stop_code: "SPRCHESF",
    stop_name: "SPRINGDALE@CHELSEA",
    stop_desc: "SPRINGDALE ST & JACKSON AVE",
    stop_lat: 35.174335,
    stop_lon: -89.983672,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4532,
    stop_code: "SPRELDSN",
    stop_name: "SPRINGDALE@ELDRIDGE",
    stop_desc: "SPRINGDALE ST & ELDRIDGE AVE",
    stop_lat: 35.171011,
    stop_lon: -89.983853,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4533,
    stop_code: "SPRHUNSN",
    stop_name: "SPRINGDALE@HUNTER",
    stop_desc: "SPRINGDALE ST & HUNTER AVE",
    stop_lat: 35.169885,
    stop_lon: -89.983887,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4534,
    stop_code: "SPRHOWSN",
    stop_name: "SPRINGDALE@HOWELL",
    stop_desc: "SPRINGDALE ST & HOWELL AVE",
    stop_lat: 35.167356,
    stop_lon: -89.983979,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4536,
    stop_code: "SPRBERSN",
    stop_name: "SPRINGDALE@BERKELEY",
    stop_desc: "SPRINGDALE ST & BERKELEY AVE",
    stop_lat: 35.16497,
    stop_lon: -89.984103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4537,
    stop_code: "SPRALBSN",
    stop_name: "SPRINGDALE@ALBANY",
    stop_desc: "SPRINGDALE ST & ALBANY AVE",
    stop_lat: 35.163317,
    stop_lon: -89.984171,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4538,
    stop_code: "SPRVOLSN",
    stop_name: "SPRINGDALE@VOLLINTINE",
    stop_desc: "SPRINGDALE ST & VOLLINTINE AVE",
    stop_lat: 35.162531,
    stop_lon: -89.984205,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4539,
    stop_code: "VOLHALWM",
    stop_name: "VOLLINTINE@HALLWOOD",
    stop_desc: "0 VOLLINTINE AVE & HALLWOOD DR",
    stop_lat: 35.16221,
    stop_lon: -89.9858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4540,
    stop_code: "VOLHALWN",
    stop_name: "VOLLINTINE@HALLWOOD",
    stop_desc: "0 VOLLINTINE AVE & HALLWOOD DR",
    stop_lat: 35.162408,
    stop_lon: -89.986973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4542,
    stop_code: "VOLUNIWF",
    stop_name: "VOLLINTINE@UNIVERSITY",
    stop_desc: "0 VOLLINTINE AVE & UNIVERSITY ST",
    stop_lat: 35.162857,
    stop_lon: -89.991434,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4545,
    stop_code: "VOLMCLWN",
    stop_name: "VOLLINTINE@MCLEAN",
    stop_desc: "0 VOLLINTINE AVE & N MCLEAN BLVD",
    stop_lat: 35.16337,
    stop_lon: -89.99636,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4547,
    stop_code: "VOLAUBWF",
    stop_name: "VOLLINTINE@AUBURNDALE",
    stop_desc: "0 VOLLINTINE AVE",
    stop_lat: 35.163721,
    stop_lon: -89.999892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4548,
    stop_code: "VOLEVEWF",
    stop_name: "VOLLINTINE@EVERGREEN",
    stop_desc: "0 VOLLINTINE AVE & N BELVEDERE BLVD",
    stop_lat: 35.163856,
    stop_lon: -90.001253,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4550,
    stop_code: "VOLAVAWF",
    stop_name: "VOLLINTINE@AVALON",
    stop_desc: "0 VOLLINTINE AVE & MAURY ST",
    stop_lat: 35.164215,
    stop_lon: -90.004796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4552,
    stop_code: "VOLMCNWN",
    stop_name: "VOLLINTINE@MCNEIL",
    stop_desc: "VOLLINTINE AVE & N MCNEIL ST",
    stop_lat: 35.164547,
    stop_lon: -90.008183,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4553,
    stop_code: "VOLMCNEN",
    stop_name: "VOLLINTINE@MCNEIL",
    stop_desc: "VOLLINTINE AVE & N MCNEIL ST",
    stop_lat: 35.164414,
    stop_lon: -90.008471,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4555,
    stop_code: "VOLAVAEN",
    stop_name: "VOLLINTINE@AVALON",
    stop_desc: "VOLLINTINE AVE & N AVALON ST",
    stop_lat: 35.163973,
    stop_lon: -90.004176,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4557,
    stop_code: "VOLNEVEF",
    stop_name: "VOLLINTINE@NEVERGREEN",
    stop_desc: "0 VOLLINTINE AVE & N EVERGREEN ST",
    stop_lat: 35.163614,
    stop_lon: -90.000379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4558,
    stop_code: "VOLNAUEN",
    stop_name: "VOLLINTINE@NAUBURNDALE",
    stop_desc: "VOLLINTINE AVE & N AUBURNDALE ST",
    stop_lat: 35.163551,
    stop_lon: -89.999704,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4560,
    stop_code: "VOLIDLEN",
    stop_name: "VOLLINTINE@IDLEWILD",
    stop_desc: "VOLLINTINE AVE & N IDLEWILD ST",
    stop_lat: 35.163299,
    stop_lon: -89.997368,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4561,
    stop_code: "VOLMCLEF",
    stop_name: "VOLLINTINE@N MCLEAN",
    stop_desc: "0 VOLLINTINE AVE & N MCLEAN BLVD",
    stop_lat: 35.163137,
    stop_lon: -89.995707,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4564,
    stop_code: "VOLUNIEN",
    stop_name: "VOLLINTINE@UNIVERSITY",
    stop_desc: "0 VOLLINTINE AVE & OAKMONT PL",
    stop_lat: 35.16258,
    stop_lon: -89.990383,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4566,
    stop_code: "VOLHALEN",
    stop_name: "VOLLINTINE@HALLWOOD",
    stop_desc: "VOLLINTINE AVE & E CABANA CIR",
    stop_lat: 35.162292,
    stop_lon: -89.987305,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4567,
    stop_code: "VOLSPREM",
    stop_name: "VOLLINTINE@SPRINGDALE",
    stop_desc: "VOLLINTINE AVE & E CABANA CIR",
    stop_lat: 35.162174,
    stop_lon: -89.984837,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4568,
    stop_code: "SPRALANN",
    stop_name: "SPRINGDALE@ALAMEDA",
    stop_desc: "SPRINGDALE ST & ALAMEDA AVE",
    stop_lat: 35.163362,
    stop_lon: -89.983972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4569,
    stop_code: "SPRDEVNN",
    stop_name: "SPRINGDALE@DEVOY",
    stop_desc: "SPRINGDALE ST & DEVOY AVE",
    stop_lat: 35.165042,
    stop_lon: -89.983893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4570,
    stop_code: "SPRBRONN",
    stop_name: "SPRINGDALE@BROWN",
    stop_desc: "SPRINGDALE ST & PIEDMONT AVE",
    stop_lat: 35.166391,
    stop_lon: -89.983825,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4571,
    stop_code: "SPRHUBNF",
    stop_name: "SPRINGDALE@HUBERT CIR",
    stop_desc: "SPRINGDALE ST & N HUBERT CIR",
    stop_lat: 35.168786,
    stop_lon: -89.983733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4572,
    stop_code: "SPRHUNNF",
    stop_name: "SPRINGDALE@HUNTER",
    stop_desc: "SPRINGDALE ST & HUNTER AVE",
    stop_lat: 35.170099,
    stop_lon: -89.983677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4573,
    stop_code: "SPRMARNF",
    stop_name: "SPRINGDALE@MARBLE",
    stop_desc: "SPRINGDALE ST & MARBLE AVE",
    stop_lat: 35.171601,
    stop_lon: -89.983608,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4574,
    stop_code: "SPRCHENN",
    stop_name: "SPRINGDALE@CHELSEA",
    stop_desc: "SPRINGDALE ST & MARBLE AVE",
    stop_lat: 35.174228,
    stop_lon: -89.983484,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4575,
    stop_code: "CHEMEREN",
    stop_name: "CHELSEA AVE@MERTON",
    stop_desc: "CHELSEA AVE & N MERTON ST",
    stop_lat: 35.174488,
    stop_lon: -89.973241,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4576,
    stop_code: "CHESTAEN",
    stop_name: "CHELSEA AVE@STANDRIDGE",
    stop_desc: "CHELSEA AVE & STANDRIDGE ST",
    stop_lat: 35.174451,
    stop_lon: -89.972023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4577,
    stop_code: "CHESTAEF",
    stop_name: "CHELSEA AVE@STANDRIDGE",
    stop_desc: "CHELSEA AVE & STANDRIDGE ST",
    stop_lat: 35.174433,
    stop_lon: -89.97147,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4578,
    stop_code: "CHEFAIEF",
    stop_name: "CHELSEA AVE@FAIRFAX",
    stop_desc: "CHELSEA AVE & FAIRFAX ST",
    stop_lat: 35.174387,
    stop_lon: -89.970075,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4579,
    stop_code: "CHECAREM",
    stop_name: "CHELSEA AVE@CARPENTER",
    stop_desc: "CHELSEA AVE & FAIRFAX ST",
    stop_lat: 35.174296,
    stop_lon: -89.967672,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4580,
    stop_code: "CHECA1EM",
    stop_name: "CHELSEA AVE@CARPENTER",
    stop_desc: "CHELSEA AVE & CARPENTER ST",
    stop_lat: 35.174177,
    stop_lon: -89.964417,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4581,
    stop_code: "CHECAREF",
    stop_name: "CHELSEA AVE@CARPENTER ST",
    stop_desc: "",
    stop_lat: 35.17414,
    stop_lon: -89.963343,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4582,
    stop_code: "CHEWAREF",
    stop_name: "CHELSEA AVE@WARFORD",
    stop_desc: "",
    stop_lat: 35.174076,
    stop_lon: -89.961912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4583,
    stop_code: "CHESUNEN",
    stop_name: "CHELSEA AVE@SUNSET",
    stop_desc: "CHELSEA AVE & SUNSET ST",
    stop_lat: 35.173806,
    stop_lon: -89.959568,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4584,
    stop_code: "CHEORIEF",
    stop_name: "CHELSEA AVE@ORIOLE",
    stop_desc: "CHELSEA AVE & LOCUST ST",
    stop_lat: 35.173662,
    stop_lon: -89.958173,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4585,
    stop_code: "CHEBROEN",
    stop_name: "CHELSEA AVE@BROOKINS",
    stop_desc: "CHELSEA AVE & BROOKINS ST",
    stop_lat: 35.1734,
    stop_lon: -89.955848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4586,
    stop_code: "CHEORREF",
    stop_name: "CHELSEA AVE@ORR",
    stop_desc: "CHELSEA AVE & ASH ST",
    stop_lat: 35.173327,
    stop_lon: -89.954387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4587,
    stop_code: "CHEASHEF",
    stop_name: "CHELSEA AVE@ASH",
    stop_desc: "CHELSEA AVE & ASH ST",
    stop_lat: 35.173308,
    stop_lon: -89.953434,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4588,
    stop_code: "CHEELLEN",
    stop_name: "CHELSEA AVE@ELLINGTON",
    stop_desc: "CHELSEA AVE & ELLINGTON ST",
    stop_lat: 35.173235,
    stop_lon: -89.951032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4589,
    stop_code: "CHEHOLEF",
    stop_name: "CHELSEA AVE@HOLMES",
    stop_desc: "CHELSEA AVE & N HOLMES ST",
    stop_lat: 35.173197,
    stop_lon: -89.949139,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4590,
    stop_code: "CHEPANEF",
    stop_name: "CHELSEA AVE@PANAMA",
    stop_desc: "CHELSEA AVE & PANAMA ST",
    stop_lat: 35.173462,
    stop_lon: -89.946448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4591,
    stop_code: "CHENHIEN",
    stop_name: "CHELSEA AVE@NHIGHLAND",
    stop_desc: "CHELSEA AVE & HIGHLAND RD",
    stop_lat: 35.174121,
    stop_lon: -89.944321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4592,
    stop_code: "CHECROEN",
    stop_name: "CHELSEA AVE@CROW",
    stop_desc: "CHELSEA AVE & STANTON RD",
    stop_lat: 35.174976,
    stop_lon: -89.941617,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4593,
    stop_code: "NATKRUNM",
    stop_name: "NATIONAL ST@KRUGER RD",
    stop_desc: "",
    stop_lat: 35.176217,
    stop_lon: -89.940121,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4594,
    stop_code: "ORCJACSN",
    stop_name: "ORCHI@JACKSON",
    stop_desc: "ORCHI RD & JACKSON AVE",
    stop_lat: 35.177859,
    stop_lon: -89.938036,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4595,
    stop_code: "PILQUISN",
    stop_name: "PILLOW@QUINN",
    stop_desc: "PILLOW ST & QUINN AVE",
    stop_lat: 35.108589,
    stop_lon: -90.014965,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4599,
    stop_code: "PILGOLSM",
    stop_name: "PILLOW@GOLD",
    stop_desc: "PILLOW ST & GOLD AVE",
    stop_lat: 35.104156,
    stop_lon: -90.015123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4601,
    stop_code: "PERCINEN",
    stop_name: "PERSON@CINCINATTI",
    stop_desc: "E PERSON AVE & SOUTH AVE",
    stop_lat: 35.100652,
    stop_lon: -90.013301,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4602,
    stop_code: "CINMENSM",
    stop_name: "CINCINATTI@MENAGER",
    stop_desc: "0 CINCINNATI RD & UNNAMED ST",
    stop_lat: 35.099249,
    stop_lon: -90.013523,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4603,
    stop_code: "CINMENSN",
    stop_name: "CINCINATTI@MENAGER",
    stop_desc: "",
    stop_lat: 35.097581,
    stop_lon: -90.01395,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4605,
    stop_code: "CINVALSF",
    stop_name: "CINCINATTI@VALSE",
    stop_desc: "CINCINNATI RD & VALSE RD",
    stop_lat: 35.094388,
    stop_lon: -90.014113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4606,
    stop_code: "CINDUNSN",
    stop_name: "CINCINATTI@DUNN",
    stop_desc: "CINCINNATI RD & DUNN AVE",
    stop_lat: 35.092993,
    stop_lon: -90.014169,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4607,
    stop_code: "WARCORSN",
    stop_name: "WARREN@CORRY",
    stop_desc: "WARREN ST & CORRY RD",
    stop_lat: 35.090795,
    stop_lon: -90.014724,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4608,
    stop_code: "WARMERSN",
    stop_name: "WARREN@MERLIN",
    stop_desc: "WARREN ST & WARREN RD",
    stop_lat: 35.089705,
    stop_lon: -90.014691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4609,
    stop_code: "STAWARWF",
    stop_name: "STARR@WARREN",
    stop_desc: "STARR AVE & CORRY RD",
    stop_lat: 35.088454,
    stop_lon: -90.014681,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4611,
    stop_code: "CORELLSN",
    stop_name: "CORRY@ELLISTON",
    stop_desc: "CORRY RD & ELLISTON RD",
    stop_lat: 35.087659,
    stop_lon: -90.016993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4612,
    stop_code: "ELLRAYEM",
    stop_name: "ELLISTON@RAYNER",
    stop_desc: "ELLISTON RD & ELLISTON RD",
    stop_lat: 35.087444,
    stop_lon: -90.015113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4613,
    stop_code: "ELLRAYEF",
    stop_name: "ELLISTON@RAYNER",
    stop_desc: "ELLISTON RD & RAYNER ST",
    stop_lat: 35.087425,
    stop_lon: -90.012614,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4614,
    stop_code: "ELLPEREN",
    stop_name: "ELLISTON@PERRY",
    stop_desc: "ELLISTON RD & PERRY RD",
    stop_lat: 35.087415,
    stop_lon: -90.010733,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4615,
    stop_code: "PERMERNN",
    stop_name: "PERRY@MERLIN",
    stop_desc: "PERRY RD & MERLIN AVE",
    stop_lat: 35.089622,
    stop_lon: -90.010179,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4616,
    stop_code: "PERDUNNM",
    stop_name: "PERRY@DUNN",
    stop_desc: "PERRY RD & DUNN AVE",
    stop_lat: 35.091106,
    stop_lon: -90.010122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4617,
    stop_code: "DUNPEREF",
    stop_name: "DUNN@PERRY",
    stop_desc: "DUNN AVE & PERRY RD",
    stop_lat: 35.092044,
    stop_lon: -90.009491,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4619,
    stop_code: "DUNHAYEF",
    stop_name: "DUNN@HAYS",
    stop_desc: "DUNN AVE & HAYS RD",
    stop_lat: 35.091843,
    stop_lon: -90.001959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4621,
    stop_code: "LAPFRENN",
    stop_name: "LAPALOMA@FREEMONT AVE",
    stop_desc: "LAPALOMA ST & FREEMONT RD",
    stop_lat: 35.093262,
    stop_lon: -89.998518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4622,
    stop_code: "LAPFERNN",
    stop_name: "LAPALOMA@FERBER",
    stop_desc: "LAPALOMA ST & FERBER AVE",
    stop_lat: 35.094594,
    stop_lon: -89.998428,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4623,
    stop_code: "LAPHEANN",
    stop_name: "LAPALOMA@HEARST",
    stop_desc: "LAPALOMA ST",
    stop_lat: 35.096041,
    stop_lon: -89.998438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4624,
    stop_code: "LAPCARNN",
    stop_name: "LAPALOMA@CARVER",
    stop_desc: "LAPALOMA ST & CARVER AVE",
    stop_lat: 35.097043,
    stop_lon: -89.998813,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4625,
    stop_code: "LAPFRINN",
    stop_name: "LAPALOMA@FRISCO",
    stop_desc: "LAPALOMA ST & FRISCO AVE",
    stop_lat: 35.097963,
    stop_lon: -89.99879,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4626,
    stop_code: "LAPEPENN",
    stop_name: "LAPALOMA@E PERSON",
    stop_desc: "LAPALOMA ST & E PERSON AVE",
    stop_lat: 35.099831,
    stop_lon: -89.998445,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4627,
    stop_code: "PERGLOEM",
    stop_name: "PERSON@GLORY",
    stop_desc: "E PERSON AVE & GLORY CIR",
    stop_lat: 35.099875,
    stop_lon: -89.997748,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4628,
    stop_code: "PERCA1EN",
    stop_name: "PERSON@CASTALIA",
    stop_desc: "E PERSON AVE & GLORY CIR",
    stop_lat: 35.099784,
    stop_lon: -89.995492,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4630,
    stop_code: "CASKELSM",
    stop_name: "CASTALIA@KELTNER",
    stop_desc: "CASTALIA ST & CARVER AVE",
    stop_lat: 35.096343,
    stop_lon: -89.995009,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4632,
    stop_code: "CASDUNSM",
    stop_name: "CASTALIA@DUNN",
    stop_desc: "CASTALIA ST & DUNN AVE",
    stop_lat: 35.093519,
    stop_lon: -89.995111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4634,
    stop_code: "DUNCUSEM",
    stop_name: "DUNN@CUSTER",
    stop_desc: "DUNN AVE & CUSTER ST",
    stop_lat: 35.091632,
    stop_lon: -89.993044,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4635,
    stop_code: "DUNFRIEN",
    stop_name: "DUNN@FRISCO",
    stop_desc: "DUNN AVE & CUSTER ST",
    stop_lat: 35.091504,
    stop_lon: -89.988742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4636,
    stop_code: "DUNAIREF",
    stop_name: "DUNN@AIRWAYS",
    stop_desc: "DUNN AVE & KELLOGG AVE",
    stop_lat: 35.091467,
    stop_lon: -89.986851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4639,
    stop_code: "FRIIMOEN",
    stop_name: "FRISCO@IMOGENE",
    stop_desc: "FRISCO AVE & IMOGENE ST",
    stop_lat: 35.088673,
    stop_lon: -89.979963,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4643,
    stop_code: "PENDWIEN",
    stop_name: "PENDLETON@DWIGHT",
    stop_desc: "PENDLETON ST & PENDLETON ST",
    stop_lat: 35.084601,
    stop_lon: -89.971928,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4644,
    stop_code: "PENLOLSF",
    stop_name: "PENDLETON@LOLA",
    stop_desc: "PENDLETON ST & LOLA AVE",
    stop_lat: 35.083135,
    stop_lon: -89.971996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4646,
    stop_code: "KETLABWN",
    stop_name: "KETCHUM@LABELLE",
    stop_desc: "KETCHUM RD & LABELLE ST",
    stop_lat: 35.080661,
    stop_lon: -89.974465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4647,
    stop_code: "KETCROWN",
    stop_name: "KETCHUM@CROSBY",
    stop_desc: "KETCHUM RD & CROSBY ST",
    stop_lat: 35.080225,
    stop_lon: -89.976578,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4648,
    stop_code: "KETLONWN",
    stop_name: "KETCHUM@LONG",
    stop_desc: "KETCHUM RD & LABELLE ST",
    stop_lat: 35.079673,
    stop_lon: -89.97868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4653,
    stop_code: "FRIIMOWF",
    stop_name: "FRISCO@IMOGENE",
    stop_desc: "",
    stop_lat: 35.088859,
    stop_lon: -89.980096,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4654,
    stop_code: "FRICHAWM",
    stop_name: "FRISCO@CHARJEAN",
    stop_desc: "",
    stop_lat: 35.089377,
    stop_lon: -89.981057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4655,
    stop_code: "FRICHAWN",
    stop_name: "FRISCO@CHARJEAN",
    stop_desc: "",
    stop_lat: 35.090309,
    stop_lon: -89.98279,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4657,
    stop_code: "DUNAIRWN",
    stop_name: "DUNN@AIRWAYS",
    stop_desc: "",
    stop_lat: 35.09156,
    stop_lon: -89.98657,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4658,
    stop_code: "DUNCUSWM",
    stop_name: "DUNN@CUSTER",
    stop_desc: "",
    stop_lat: 35.091657,
    stop_lon: -89.989872,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4659,
    stop_code: "DUNCUSWF",
    stop_name: "DUNN@CUSTER",
    stop_desc: "DUNN AVE & CUSTER ST",
    stop_lat: 35.091775,
    stop_lon: -89.992535,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4660,
    stop_code: "CASDUNNF",
    stop_name: "CASTALIA@DUNN",
    stop_desc: "CASTALIA ST & DUNN AVE",
    stop_lat: 35.092295,
    stop_lon: -89.994968,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4661,
    stop_code: "CASKELNM",
    stop_name: "CASTALIA@KELTNER",
    stop_desc: "CASTALIA ST & KELTNER CIR",
    stop_lat: 35.093546,
    stop_lon: -89.994912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4662,
    stop_code: "CASKELNF",
    stop_name: "CASTALIA@KELTNER",
    stop_desc: "CASTALIA ST & KELTNER CIR",
    stop_lat: 35.09494,
    stop_lon: -89.994888,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4663,
    stop_code: "CASFRINF",
    stop_name: "CASTALIA@FRISCO",
    stop_desc: "CASTALIA ST & FRISCO AVE",
    stop_lat: 35.097907,
    stop_lon: -89.994764,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4665,
    stop_code: "PERCASWF",
    stop_name: "PERSON@CASTAILA",
    stop_desc: "E PERSON AVE & GLORY CIR",
    stop_lat: 35.099963,
    stop_lon: -89.995824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4666,
    stop_code: "PERGLOWF",
    stop_name: "PERSON@GLORY",
    stop_desc: "E PERSON AVE & GLORY CIR",
    stop_lat: 35.100018,
    stop_lon: -89.997129,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4667,
    stop_code: "LAPPERSF",
    stop_name: "LAPALOMA@PERSON",
    stop_desc: "LAPALOMA ST & E PERSON AVE",
    stop_lat: 35.099661,
    stop_lon: -89.998645,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4668,
    stop_code: "LAPFRISN",
    stop_name: "LAPALOMA@FRISCO",
    stop_desc: "LAPALOMA ST & FRISCO AVE",
    stop_lat: 35.098222,
    stop_lon: -89.998967,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4669,
    stop_code: "LAPCARSN",
    stop_name: "LAPALOMA@CARVER",
    stop_desc: "",
    stop_lat: 35.097251,
    stop_lon: -89.998915,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4671,
    stop_code: "LAPFERSN",
    stop_name: "LAPALOMA@FERBER",
    stop_desc: "LAPALOMA ST & FERBER AVE",
    stop_lat: 35.094898,
    stop_lon: -89.998671,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4674,
    stop_code: "DUNSTAWM",
    stop_name: "DUNN@STATE",
    stop_desc: "DUNN AVE & STATE ST",
    stop_lat: 35.091967,
    stop_lon: -90.000576,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4677,
    stop_code: "PERDUNSF",
    stop_name: "PERRY@DUNN",
    stop_desc: "PERRY RD & DUNN AVE",
    stop_lat: 35.091284,
    stop_lon: -90.010321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4678,
    stop_code: "PERMERSN",
    stop_name: "PERRY@MERLIN",
    stop_desc: "PERRY RD & MERLIN AVE",
    stop_lat: 35.089828,
    stop_lon: -90.010367,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4679,
    stop_code: "PERELLSM",
    stop_name: "PERRY@ELLISTON",
    stop_desc: "PERRY RD & ELLISTON RD",
    stop_lat: 35.088425,
    stop_lon: -90.010434,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4686,
    stop_code: "SPAWHISM",
    stop_name: "SPARKS@WHITMORE",
    stop_desc: "SPARKS ST & WHITMORE RD",
    stop_lat: 35.079989,
    stop_lon: -90.012509,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4687,
    stop_code: "SPAALCSN",
    stop_name: "SPARKS@ALCY",
    stop_desc: "",
    stop_lat: 35.078103,
    stop_lon: -90.012444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4688,
    stop_code: "ALCPEREN",
    stop_name: "ALCY@PERRY",
    stop_desc: "E ALCY RD & PERRY RD",
    stop_lat: 35.077825,
    stop_lon: -90.011006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4689,
    stop_code: "ALCKEEEN",
    stop_name: "ALCY@KEEN",
    stop_desc: "E ALCY RD & KEEN RD",
    stop_lat: 35.077789,
    stop_lon: -90.009967,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4690,
    stop_code: "ALCROZEN",
    stop_name: "ALCY@ROZELLE",
    stop_desc: "E ALCY RD & ROZELLE ST",
    stop_lat: 35.077698,
    stop_lon: -90.007501,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4691,
    stop_code: "ALCMANEN",
    stop_name: "ALCY@MANCHESTER",
    stop_desc: "E ALCY RD & MANCHESTER RD",
    stop_lat: 35.077644,
    stop_lon: -90.006052,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4692,
    stop_code: "ALCJUDEN",
    stop_name: "ALCY@JUDSON",
    stop_desc: "E ALCY RD & JUDSON ST",
    stop_lat: 35.077607,
    stop_lon: -90.004438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4693,
    stop_code: "ALCSHEEF",
    stop_name: "ALCY@SHERRIE",
    stop_desc: "E ALCY RD & SHERRIE ST",
    stop_lat: 35.077526,
    stop_lon: -90.002027,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4694,
    stop_code: "ALCJOHEN",
    stop_name: "ALCY@JOHANNA",
    stop_desc: "ALCY RD & JOHANNA DR",
    stop_lat: 35.077766,
    stop_lon: -90.000279,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4695,
    stop_code: "ALCLAPEN",
    stop_name: "ALCY RD@LAPALOMA ST",
    stop_desc: "ALCY RD & E ALCY RD",
    stop_lat: 35.078292,
    stop_lon: -89.998908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4698,
    stop_code: "ALCKE1EM",
    stop_name: "ALCY@KETCHUM",
    stop_desc: "E ALCY RD & E CHEZITA DR",
    stop_lat: 35.078316,
    stop_lon: -89.993389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4699,
    stop_code: "ALCKE2EM",
    stop_name: "ALCY@KETCHUM",
    stop_desc: "RAMP & KETCHUM RD",
    stop_lat: 35.07899,
    stop_lon: -89.985714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4702,
    stop_code: "KETALCWM",
    stop_name: "ALCY RD@KETCHUM RD",
    stop_desc: "0 E ALCY RD & E ESSEX AVE",
    stop_lat: 35.078624,
    stop_lon: -89.986057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4717,
    stop_code: "ELLRAYWN",
    stop_name: "ELLISTON@RAYNER",
    stop_desc: "ELLISTON RD & RAYNER ST",
    stop_lat: 35.087586,
    stop_lon: -90.012481,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4718,
    stop_code: "ELLCORWM",
    stop_name: "ELLISTON@CORRY",
    stop_desc: "ELLISTON RD & RAYNER ST",
    stop_lat: 35.087604,
    stop_lon: -90.014184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4721,
    stop_code: "STAWAREN",
    stop_name: "STARR@WARREN",
    stop_desc: "STARR AVE & CORRY RD",
    stop_lat: 35.088293,
    stop_lon: -90.014902,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4722,
    stop_code: "WARMERNN",
    stop_name: "WARREN@MERLIN",
    stop_desc: "WARREN ST & MERLIN AVE",
    stop_lat: 35.089499,
    stop_lon: -90.014481,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4723,
    stop_code: "WARRAVNF",
    stop_name: "WARREN@RAVEN",
    stop_desc: "WARREN ST & CORRY RD",
    stop_lat: 35.090831,
    stop_lon: -90.014525,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4724,
    stop_code: "CINDUNNF",
    stop_name: "CINCINNATTI@DUNN",
    stop_desc: "CINCINNATI RD & DUNN AVE",
    stop_lat: 35.093154,
    stop_lon: -90.013959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4725,
    stop_code: "CINVALNN",
    stop_name: "CINCINNATTI@VALSE",
    stop_desc: "CINCINNATI RD & VALSE RD",
    stop_lat: 35.094477,
    stop_lon: -90.013914,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4726,
    stop_code: "CINROBNF",
    stop_name: "CINCINNATTI@ROBERTS",
    stop_desc: "CINCINNATI RD & ROBERTS RD",
    stop_lat: 35.095487,
    stop_lon: -90.013858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4727,
    stop_code: "CINMENNN",
    stop_name: "CINCINNATTI@MENAGER",
    stop_desc: "CINCINNATI RD & MENAGER RD",
    stop_lat: 35.097533,
    stop_lon: -90.01379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4728,
    stop_code: "PERPILWM",
    stop_name: "PERSON@PILLOW",
    stop_desc: "E PERSON AVE & MENAGER RD",
    stop_lat: 35.100822,
    stop_lon: -90.013566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4729,
    stop_code: "PERPILWN",
    stop_name: "PERSON@PILLOW",
    stop_desc: "E PERSON AVE & PILLOW ST",
    stop_lat: 35.100849,
    stop_lon: -90.014705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4730,
    stop_code: "PILDORNN",
    stop_name: "PILLOW@DORIS",
    stop_desc: "PILLOW ST & DORIS AVE",
    stop_lat: 35.101457,
    stop_lon: -90.015026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4731,
    stop_code: "PILSOUNN",
    stop_name: "PILLOW@SOUTH",
    stop_desc: "PILLOW ST & SOUTH AVE",
    stop_lat: 35.10295,
    stop_lon: -90.014969,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4732,
    stop_code: "PILKERNM",
    stop_name: "PILLOW@KERR",
    stop_desc: "PILLOW ST & KERR AVE",
    stop_lat: 35.106095,
    stop_lon: -90.014868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4737,
    stop_code: "MARWALSM",
    stop_name: "MARJORIE@WALDORF AVE",
    stop_desc: "MARJORIE ST & LELAND ST",
    stop_lat: 35.106667,
    stop_lon: -90.036959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4738,
    stop_code: "MARWALSN",
    stop_name: "MARJORIE@WALDORF",
    stop_desc: "MARJORIE ST & E MALLORY AVE",
    stop_lat: 35.105684,
    stop_lon: -90.03748,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4740,
    stop_code: "MARLELSM",
    stop_name: "MARJORIE@LELAND",
    stop_desc: "MARJORIE ST & LELAND ST",
    stop_lat: 35.103959,
    stop_lon: -90.036197,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4741,
    stop_code: "MARHAVSN",
    stop_name: "MARJORIE@HAVANA",
    stop_desc: "MARJORIE ST & LELAND ST",
    stop_lat: 35.102823,
    stop_lon: -90.035291,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4742,
    stop_code: "MARPERSM",
    stop_name: "MARJORIE@PERSON",
    stop_desc: "MARJORIE ST & E PERSON AVE",
    stop_lat: 35.101474,
    stop_lon: -90.035612,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4743,
    stop_code: "MARTAMSN",
    stop_name: "MARJORIE@TAMPA",
    stop_desc: "MARJORIE ST & E PERSON AVE",
    stop_lat: 35.099427,
    stop_lon: -90.03569,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4744,
    stop_code: "MARALISF",
    stop_name: "MARJORIE@ALIDA",
    stop_desc: "MARJORIE ST & ALIDA AVE",
    stop_lat: 35.098301,
    stop_lon: -90.034319,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4745,
    stop_code: "MARWOOSN",
    stop_name: "MARJORIE@WOODLAND",
    stop_desc: "MARJORIE ST & WOODLAND AVE",
    stop_lat: 35.097407,
    stop_lon: -90.033003,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4746,
    stop_code: "MARNORSN",
    stop_name: "MARJORIE@NORFOLK",
    stop_desc: "MARJORIE ST & NORFOLK ST",
    stop_lat: 35.095949,
    stop_lon: -90.030891,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4747,
    stop_code: "MARALISN",
    stop_name: "MARJORIE@ALICE",
    stop_desc: "MARJORIE ST & ROSEWOOD AVE",
    stop_lat: 35.094295,
    stop_lon: -90.029499,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4775,
    stop_code: "LAUESSNN",
    stop_name: "LAUDERDALE@ESSEX",
    stop_desc: "S LAUDERDALE ST & E ESSEX AVE",
    stop_lat: 35.103219,
    stop_lon: -90.043963,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4786,
    stop_code: "MALLAUEF",
    stop_name: "MALLORY@LAUDERDALE",
    stop_desc: "E MALLORY AVE & S LAUDERDALE ST",
    stop_lat: 35.08622,
    stop_lon: -90.0444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4787,
    stop_code: "MALMCMEF",
    stop_name: "MALLORY@MCMILLAN",
    stop_desc: "E MALLORY AVE & MCMILLAN ST",
    stop_lat: 35.086184,
    stop_lon: -90.042122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4788,
    stop_code: "MALORLEN",
    stop_name: "MALLORY@ORLEANS",
    stop_desc: "E MALLORY AVE & S ORLEANS ST",
    stop_lat: 35.086139,
    stop_lon: -90.040142,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4798,
    stop_code: "LAUALTNN",
    stop_name: "LAUDERDALE@ALTON",
    stop_desc: "S LAUDERDALE ST & ALTON AVE",
    stop_lat: 35.092262,
    stop_lon: -90.044266,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4799,
    stop_code: "LAUROSNN",
    stop_name: "LAUDERDALE@ROSEWOOD",
    stop_desc: "S LAUDERDALE ST & ROSEWOOD AVE",
    stop_lat: 35.094836,
    stop_lon: -90.044232,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4800,
    stop_code: "LAUSHANN",
    stop_name: "LAUDERDALE@SHAWNEE",
    stop_desc: "S LAUDERDALE ST & SHAWNEE AVE",
    stop_lat: 35.097812,
    stop_lon: -90.044164,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4801,
    stop_code: "LAUDISNN",
    stop_name: "LAUDERDALE@DISON",
    stop_desc: "S LAUDERDALE ST & E DISON AVE",
    stop_lat: 35.100573,
    stop_lon: -90.044086,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4802,
    stop_code: "LAUWALNN",
    stop_name: "LAUDERDALE ST@WALDORF AVE",
    stop_desc: "S LAUDERDALE ST & E WALDORF AVE",
    stop_lat: 35.105221,
    stop_lon: -90.043929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4803,
    stop_code: "LAUSPANN",
    stop_name: "LAUDERDALE@SPARKWAY",
    stop_desc: "S LAUDERDALE ST & KNIGHT ARNOLD RD",
    stop_lat: 35.106686,
    stop_lon: -90.043874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4806,
    stop_code: "NORPRAWN",
    stop_name: "NORRIS@PRATT",
    stop_desc: "NORRIS RD & PRATT ST",
    stop_lat: 35.083434,
    stop_lon: -90.021309,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4819,
    stop_code: "LATBONNN",
    stop_name: "LATHAM@BOND AVE",
    stop_desc: "LATHAM ST & BOND AVE",
    stop_lat: 35.108484,
    stop_lon: -90.051794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4820,
    stop_code: "LATBULNF",
    stop_name: "LATHAM@BULLINGTON",
    stop_desc: "LATHAM ST & BOND AVE",
    stop_lat: 35.109324,
    stop_lon: -90.051905,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4821,
    stop_code: "LATEOLNN",
    stop_name: "LATHAM@EOLIVE",
    stop_desc: "",
    stop_lat: 35.11028,
    stop_lon: -90.052082,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4822,
    stop_code: "LATTRINN",
    stop_name: "LATHAM@TRIGG",
    stop_desc: "LATHAM ST & E TRIGG AVE",
    stop_lat: 35.112256,
    stop_lon: -90.052258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4823,
    stop_code: "LATLUCNN",
    stop_name: "LATHAM@LUCY",
    stop_desc: "LATHAM ST & CAMBRIDGE AVE",
    stop_lat: 35.114025,
    stop_lon: -90.052379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4824,
    stop_code: "LATLUCNF",
    stop_name: "LATHAM@LUCY",
    stop_desc: "LATHAM ST & SIMPSON AVE",
    stop_lat: 35.114517,
    stop_lon: -90.052412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4825,
    stop_code: "LATMCLNN",
    stop_name: "LATHAM@MCLEMORE",
    stop_desc: "LATHAM ST & SIMPSON AVE",
    stop_lat: 35.115795,
    stop_lon: -90.052445,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4826,
    stop_code: "FOUMCLNF",
    stop_name: "FOURTH@MCLEMORE",
    stop_desc: "S FOURTH ST & S 4TH ST",
    stop_lat: 35.116313,
    stop_lon: -90.051117,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4827,
    stop_code: "FOUEDINN",
    stop_name: "FOURTH@EDITH",
    stop_desc: "S FOURTH ST & EDITH AVE",
    stop_lat: 35.116795,
    stop_lon: -90.051084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4828,
    stop_code: "FOUGASNN",
    stop_name: "FOURTH@GASTON",
    stop_desc: "S FOURTH ST & EDITH AVE",
    stop_lat: 35.117769,
    stop_lon: -90.051051,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4829,
    stop_code: "FOULACNN",
    stop_name: "FOURTH@LACLEDE",
    stop_desc: "S FOURTH ST & GASTON AVE",
    stop_lat: 35.118699,
    stop_lon: -90.051017,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4830,
    stop_code: "FOUWALNN",
    stop_name: "FOURTH@WALKER",
    stop_desc: "S FOURTH ST & LACLEDE AVE",
    stop_lat: 35.119575,
    stop_lon: -90.050995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4831,
    stop_code: "FOUTAPNN",
    stop_name: "FOURTH@TAPTON",
    stop_desc: "S 4TH ST & TAPTON PL",
    stop_lat: 35.121344,
    stop_lon: -90.050939,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4832,
    stop_code: "FOUTAPSF",
    stop_name: "FOURTH@TAPTON",
    stop_desc: "S FOURTH ST & S 4TH ST",
    stop_lat: 35.121371,
    stop_lon: -90.051138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4833,
    stop_code: "FOUWALSN",
    stop_name: "FOURTH@WALKER",
    stop_desc: "S 4TH ST & WALKER AVE",
    stop_lat: 35.119896,
    stop_lon: -90.051205,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4834,
    stop_code: "FOULACSN",
    stop_name: "FOURTH@LACLEDE",
    stop_desc: "",
    stop_lat: 35.118833,
    stop_lon: -90.051171,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4835,
    stop_code: "FOUGASSN",
    stop_name: "FOURTH@GASTON",
    stop_desc: "",
    stop_lat: 35.118083,
    stop_lon: -90.051188,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4836,
    stop_code: "FOUEDISF",
    stop_name: "FOURTH@EDITH",
    stop_desc: "",
    stop_lat: 35.116933,
    stop_lon: -90.051243,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4837,
    stop_code: "FOUMCLSN",
    stop_name: "FOURTH@MCLEMORE",
    stop_desc: "",
    stop_lat: 35.116064,
    stop_lon: -90.051276,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4838,
    stop_code: "LATMCLSF",
    stop_name: "LATHAM@MCLEMORE",
    stop_desc: "",
    stop_lat: 35.115629,
    stop_lon: -90.052622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4839,
    stop_code: "LATLUCSN",
    stop_name: "LATHAM@LUCY",
    stop_desc: "",
    stop_lat: 35.11448,
    stop_lon: -90.05255,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4840,
    stop_code: "LATTRISF",
    stop_name: "LATHAM@TRIGG",
    stop_desc: "",
    stop_lat: 35.112047,
    stop_lon: -90.052373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4841,
    stop_code: "LATEOLSN",
    stop_name: "LATHAM@EOLIVE",
    stop_desc: "",
    stop_lat: 35.110385,
    stop_lon: -90.052174,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4868,
    stop_code: "FLOMCLSF",
    stop_name: "FLORIDA@MCLEMORE",
    stop_desc: "FLORIDA ST & E MCLEMORE AVE",
    stop_lat: 35.115706,
    stop_lon: -90.062359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4869,
    stop_code: "FLOFARSN",
    stop_name: "FLORIDA@FARROW",
    stop_desc: "FLORIDA ST & FARROW AVE",
    stop_lat: 35.114116,
    stop_lon: -90.06237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4870,
    stop_code: "FLOWTRSN",
    stop_name: "FLORIDA@WTRIGG",
    stop_desc: "FLORIDA ST & FARROW AVE",
    stop_lat: 35.112936,
    stop_lon: -90.06237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4871,
    stop_code: "FLOEOLSN",
    stop_name: "FLORIDA@EOLIVE",
    stop_desc: "FLORIDA ST & E OLIVE AVE",
    stop_lat: 35.110398,
    stop_lon: -90.062425,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4872,
    stop_code: "FLOSPASN",
    stop_name: "FLORIDA ST@S PARKWAY W",
    stop_desc: "FLORIDA ST & SHERWOOD AVE",
    stop_lat: 35.107985,
    stop_lon: -90.062459,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4879,
    stop_code: "BELFLOWF",
    stop_name: "BELZ@FLORIDA",
    stop_desc: "W BELZ BLVD & PRETORIA AVE",
    stop_lat: 35.094928,
    stop_lon: -90.063433,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4880,
    stop_code: "BELKANWN",
    stop_name: "BELZ@KANSAS",
    stop_desc: "W BELZ BLVD & KANSAS ST",
    stop_lat: 35.092613,
    stop_lon: -90.066829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4881,
    stop_code: "BELKANEF",
    stop_name: "BELZ@KANSAS",
    stop_desc: "W BELZ BLVD & KANSAS ST",
    stop_lat: 35.092747,
    stop_lon: -90.065845,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4882,
    stop_code: "FLOROSSN",
    stop_name: "FLORIDA@ROSIE DR",
    stop_desc: "FLORIDA ST & ROSIE DR",
    stop_lat: 35.093882,
    stop_lon: -90.062936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4883,
    stop_code: "FLOFERSN",
    stop_name: "FLORIDA@FERNWOOD",
    stop_desc: "FLORIDA ST & FERNWOOD AVE",
    stop_lat: 35.09272,
    stop_lon: -90.062947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4884,
    stop_code: "FLONORSN",
    stop_name: "FLORIDA@NORWOOD",
    stop_desc: "FLORIDA ST & W NORWOOD AVE",
    stop_lat: 35.091782,
    stop_lon: -90.062969,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4885,
    stop_code: "FLOFRASN",
    stop_name: "FLORIDA@FRANK",
    stop_desc: "FLORIDA ST & W FRANK AVE",
    stop_lat: 35.090808,
    stop_lon: -90.063002,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4886,
    stop_code: "FLOMCKSN",
    stop_name: "FLORIDA@MCKELLAR",
    stop_desc: "FLORIDA ST & W FRANK AVE",
    stop_lat: 35.089825,
    stop_lon: -90.063036,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4887,
    stop_code: "FLODAVSN",
    stop_name: "FLORIDA@DAVANT",
    stop_desc: "FLORIDA ST & W DAVANT AVE",
    stop_lat: 35.088815,
    stop_lon: -90.063058,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4888,
    stop_code: "FLOGAGSN",
    stop_name: "FLORIDA@GAGE",
    stop_desc: "FLORIDA ST & W DAVANT AVE",
    stop_lat: 35.087805,
    stop_lon: -90.06308,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4897,
    stop_code: "HORCROSM",
    stop_name: "HORN LAKE@CROSS VILLAGE",
    stop_desc: "HORN LAKE RD & BRANTLEY RD",
    stop_lat: 35.051037,
    stop_lon: -90.06902,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4898,
    stop_code: "HORFAISN",
    stop_name: "HORN LAKE RD@FAIRWAY AVE",
    stop_desc: "HORN LAKE RD & W FAIRWAY AVE",
    stop_lat: 35.048222,
    stop_lon: -90.069473,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4899,
    stop_code: "HORLEVSN",
    stop_name: "HORN LAKE@LEVI",
    stop_desc: "HORN LAKE RD & W LEVI RD",
    stop_lat: 35.043047,
    stop_lon: -90.069617,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4900,
    stop_code: "HORTHISN",
    stop_name: "HORN LAKE@THIRD",
    stop_desc: "HORN LAKE RD & RAMP",
    stop_lat: 35.039686,
    stop_lon: -90.069274,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4901,
    stop_code: "HORTHISF",
    stop_name: "HORN LAKE@THIRD",
    stop_desc: "HORN LAKE RD & S 3RD ST",
    stop_lat: 35.03839,
    stop_lon: -90.069396,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4902,
    stop_code: "HORRAISM",
    stop_name: "HORN LAKE@RAINES",
    stop_desc: "HORN LAKE RD & S 3RD ST",
    stop_lat: 35.036737,
    stop_lon: -90.069794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4903,
    stop_code: "HORRAISN",
    stop_name: "HORN LAKE@RAINES",
    stop_desc: "HORN LAKE RD & W RAINES RD",
    stop_lat: 35.035664,
    stop_lon: -90.070059,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4904,
    stop_code: "HORSHESM",
    stop_name: "HORN LAKE@SHELBY DR",
    stop_desc: "HORN LAKE RD & S 3RD ST",
    stop_lat: 35.024242,
    stop_lon: -90.06839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4905,
    stop_code: "HORSHESN",
    stop_name: "HORN LAKE@SHELBY DR",
    stop_desc: "HORN LAKE RD & S 3RD ST",
    stop_lat: 35.021695,
    stop_lon: -90.068313,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4921,
    stop_code: "JONHOLNF",
    stop_name: "JONETTA ST@HOLMES RD",
    stop_desc: "",
    stop_lat: 35.00642,
    stop_lon: -90.074622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4922,
    stop_code: "JONWASNM",
    stop_name: "JONETTA@WASHBURN",
    stop_desc: "",
    stop_lat: 35.007787,
    stop_lon: -90.074876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4923,
    stop_code: "JONWASNN",
    stop_name: "JONETTA@WASHBURN",
    stop_desc: "JONETTA ST & WASHBURN DR",
    stop_lat: 35.009155,
    stop_lon: -90.075318,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4924,
    stop_code: "JONMCFNM",
    stop_name: "JONETTA@MCFARLAND",
    stop_desc: "JONETTA ST & WASHBURN DR",
    stop_lat: 35.010335,
    stop_lon: -90.075793,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4925,
    stop_code: "JONFITNN",
    stop_name: "JONETTA@FITZGERALD",
    stop_desc: "JONETTA ST & FITZGERALD DR",
    stop_lat: 35.012381,
    stop_lon: -90.076125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4926,
    stop_code: "JONHONNN",
    stop_name: "JONETTA@HONDURAS",
    stop_desc: "JONETTA ST & HONDURAS DR",
    stop_lat: 35.014053,
    stop_lon: -90.075639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4927,
    stop_code: "HONHONEN",
    stop_name: "HONDURAS@HONDURAS CV",
    stop_desc: "HONDURAS DR & HONDURAS CV",
    stop_lat: 35.014321,
    stop_lon: -90.074401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4928,
    stop_code: "HONJEFEN",
    stop_name: "HONDURAS@JEFF",
    stop_desc: "HONDURAS DR & HONDURAS RD",
    stop_lat: 35.013508,
    stop_lon: -90.072633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4929,
    stop_code: "HONPEAEF",
    stop_name: "HONDURAS@PEACE",
    stop_desc: "HONDURAS RD & PEACE ST",
    stop_lat: 35.01382,
    stop_lon: -90.069937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4930,
    stop_code: "HONHOREN",
    stop_name: "HONDURAS@HORN LAKE",
    stop_desc: "HONDURAS DR & HONDURAS RD",
    stop_lat: 35.014634,
    stop_lon: -90.068423,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4931,
    stop_code: "HORBYFNN",
    stop_name: "HORN LAKE@BYFIELD",
    stop_desc: "HORN LAKE RD & W BYFIELD DR",
    stop_lat: 35.016582,
    stop_lon: -90.068103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4932,
    stop_code: "HORGEENN",
    stop_name: "HORN LAKE@GEETER",
    stop_desc: "HORN LAKE RD & W GEETER RD",
    stop_lat: 35.018486,
    stop_lon: -90.068103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4933,
    stop_code: "HORFALNN",
    stop_name: "HORN LAKER@FALLS RD",
    stop_desc: "HORN LAKE RD & W FALLS RD",
    stop_lat: 35.018897,
    stop_lon: -90.068114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4935,
    stop_code: "HORHONSN",
    stop_name: "HORN LAKE@HONDURAS",
    stop_desc: "",
    stop_lat: 35.014829,
    stop_lon: -90.068286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4936,
    stop_code: "HORVANSN",
    stop_name: "HORN LAKE@VAN HUESEN",
    stop_desc: "",
    stop_lat: 35.011131,
    stop_lon: -90.068258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4937,
    stop_code: "HORHOLSN",
    stop_name: "HORN LAKE@HOLMES",
    stop_desc: "",
    stop_lat: 35.006721,
    stop_lon: -90.068218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4941,
    stop_code: "HORSHENF",
    stop_name: "HORN LAKE@SHELBY DR",
    stop_desc: "HORN LAKE RD & W FALLS RD",
    stop_lat: 35.021614,
    stop_lon: -90.068114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4942,
    stop_code: "HORWYANF",
    stop_name: "HORN LAKE@WYATT DR",
    stop_desc: "HORN LAKE RD & WYATT DR",
    stop_lat: 35.029426,
    stop_lon: -90.069937,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4943,
    stop_code: "HORRAINN",
    stop_name: "HORN LAKE@RAINES",
    stop_desc: "",
    stop_lat: 35.035201,
    stop_lon: -90.070006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4944,
    stop_code: "HORTHINN",
    stop_name: "HORN LAKE@THIRD",
    stop_desc: "HORN LAKE RD & HORN LAKE RD",
    stop_lat: 35.038408,
    stop_lon: -90.069186,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4945,
    stop_code: "HORTHINF",
    stop_name: "HORN LAKE@THIRD",
    stop_desc: "HORN LAKE RD & RAMP",
    stop_lat: 35.040294,
    stop_lon: -90.069186,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4946,
    stop_code: "HORLEVNF",
    stop_name: "HORN LAKE@LEVI RD",
    stop_desc: "HORN LAKE RD & W LEVI RD",
    stop_lat: 35.043949,
    stop_lon: -90.069385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4947,
    stop_code: "HORFAINN",
    stop_name: "HORN LAKE RD@FAIRWAY AVE",
    stop_desc: "HORN LAKE RD & W FAIRWAY AVE",
    stop_lat: 35.047757,
    stop_lon: -90.069296,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4948,
    stop_code: "HORBRANM",
    stop_name: "HORN LAKE@BRANTLEY",
    stop_desc: "0 HORN LAKE RD",
    stop_lat: 35.051225,
    stop_lon: -90.068777,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4950,
    stop_code: "HORBRANN",
    stop_name: "HORN LAKE@BRANTLEY",
    stop_desc: "HORN LAKE RD & BRANTLEY RD",
    stop_lat: 35.05244,
    stop_lon: -90.068677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4962,
    stop_code: "FLOGAGNN",
    stop_name: "FLORIDA@GAGE",
    stop_desc: "FLORIDA ST & E GAGE AVE",
    stop_lat: 35.087421,
    stop_lon: -90.062892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4963,
    stop_code: "FLODAVNN",
    stop_name: "FLORIDA@DAVANT",
    stop_desc: "FLORIDA ST & E DAVANT AVE",
    stop_lat: 35.088538,
    stop_lon: -90.06287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4964,
    stop_code: "FLOMCKNN",
    stop_name: "FLORIDA@MCKELLAR",
    stop_desc: "FLORIDA ST & E MCKELLAR AVE",
    stop_lat: 35.089539,
    stop_lon: -90.062848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4965,
    stop_code: "FLOFRANN",
    stop_name: "FLORIDA@FRANK",
    stop_desc: "FLORIDA ST & E FRANK AVE",
    stop_lat: 35.090522,
    stop_lon: -90.062814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4966,
    stop_code: "FLONORNN",
    stop_name: "FLORIDA@NORWOOD",
    stop_desc: "FLORIDA ST & E FRANK AVE",
    stop_lat: 35.091478,
    stop_lon: -90.062781,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4967,
    stop_code: "FLOFERNN",
    stop_name: "FLORIDA@FERNWOOD",
    stop_desc: "FLORIDA ST & E FERNWOOD AVE",
    stop_lat: 35.092443,
    stop_lon: -90.062759,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4968,
    stop_code: "FLOBELNM",
    stop_name: "FLORIDA@BELZ",
    stop_desc: "FLORIDA ST & ROSIE DR",
    stop_lat: 35.094025,
    stop_lon: -90.062726,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4969,
    stop_code: "FLOBELNN",
    stop_name: "BELZ@FLORIDA",
    stop_desc: "? FLORIDA ST & E BELZ BLVD",
    stop_lat: 35.094964,
    stop_lon: -90.062715,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4981,
    stop_code: "FLOSPANF",
    stop_name: "FLORIDA ST@S PARKWAY E",
    stop_desc: "",
    stop_lat: 35.108253,
    stop_lon: -90.06226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4982,
    stop_code: "FLOEOLNN",
    stop_name: "FLORIDA@EOLIVE",
    stop_desc: "FLORIDA ST & E OLIVE AVE",
    stop_lat: 35.110049,
    stop_lon: -90.062237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4983,
    stop_code: "FLOTRINN",
    stop_name: "FLORIDA@TRIGG",
    stop_desc: "FLORIDA ST & E TRIGG AVE",
    stop_lat: 35.112364,
    stop_lon: -90.062182,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4984,
    stop_code: "FLOFARNF",
    stop_name: "FLORIDA@FARROW",
    stop_desc: "FLORIDA ST & FARROW AVE",
    stop_lat: 35.114276,
    stop_lon: -90.062171,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4985,
    stop_code: "FLOWMCNN",
    stop_name: "FLORIDA@WMCLEMORE",
    stop_desc: "FLORIDA ST & E MCLEMORE AVE",
    stop_lat: 35.116001,
    stop_lon: -90.062204,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4986,
    stop_code: "THIMCLSF",
    stop_name: "3RD ST@MCLEMORE AVE",
    stop_desc: "THIRD & MCLEMORE",
    stop_lat: 35.115394,
    stop_lon: -90.055303,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4987,
    stop_code: "THISPASF",
    stop_name: "THIRD@SPARKWAY",
    stop_desc: "US HWY 61 & S PARKWAY E",
    stop_lat: 35.106939,
    stop_lon: -90.055445,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4988,
    stop_code: "THIWALSN",
    stop_name: "THIRD@WALDORF",
    stop_desc: "S THIRD ST & US HWY 61",
    stop_lat: 35.105848,
    stop_lon: -90.055479,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4989,
    stop_code: "THIESSSN",
    stop_name: "THIRD@ESSEX",
    stop_desc: "S THIRD ST & E ESSEX AVE",
    stop_lat: 35.104302,
    stop_lon: -90.055523,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4990,
    stop_code: "THIEPESN",
    stop_name: "THIRD@E PERSON",
    stop_desc: "US HWY 61 & E PERSON AVE",
    stop_lat: 35.10197,
    stop_lon: -90.055601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4991,
    stop_code: "THIPERSF",
    stop_name: "THIRD@PERSON",
    stop_desc: "US HWY 61 & S 3RD ST",
    stop_lat: 35.100173,
    stop_lon: -90.05569,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4992,
    stop_code: "THIBELSM",
    stop_name: "THIRD ST@BELZ BLVD",
    stop_desc: "US HWY 61 & S 3RD ST",
    stop_lat: 35.098216,
    stop_lon: -90.055723,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4993,
    stop_code: "THIBELSN",
    stop_name: "THIRD@BELZ",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.096884,
    stop_lon: -90.055779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4994,
    stop_code: "THIBELSF",
    stop_name: "THIRD@BELZ BLVD",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.095392,
    stop_lon: -90.055912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4995,
    stop_code: "THIFERSM",
    stop_name: "THIRD@FERNWOOD",
    stop_desc: "US HWY 61 & FERNWOOD AVE",
    stop_lat: 35.093962,
    stop_lon: -90.056067,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4996,
    stop_code: "THIFERSN",
    stop_name: "THIRD@FERNWOOD",
    stop_desc: "S THIRD ST & FERNWOOD AVE",
    stop_lat: 35.092639,
    stop_lon: -90.0562,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4997,
    stop_code: "THIFRASN",
    stop_name: "THIRD@FRANK",
    stop_desc: "S THIRD ST & E FRANK AVE",
    stop_lat: 35.090673,
    stop_lon: -90.056399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4998,
    stop_code: "THIDEVSN",
    stop_name: "THIRD@DEVANT",
    stop_desc: "US HWY 61 & STATE HWY 14",
    stop_lat: 35.089002,
    stop_lon: -90.056588,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 4999,
    stop_code: "THIEMASN",
    stop_name: "THIRD@E MALLORY",
    stop_desc: "US HWY 61 & E MALLORY AVE",
    stop_lat: 35.086687,
    stop_lon: -90.056909,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5000,
    stop_code: "MALSTHWF",
    stop_name: "MALLORY@STHIRD",
    stop_desc: "E MALLORY AVE & S THIRD ST",
    stop_lat: 35.086625,
    stop_lon: -90.057495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5001,
    stop_code: "MALSHEWN",
    stop_name: "MALLORY@SHELBY ST",
    stop_desc: "E MALLORY AVE & S MAIN ST",
    stop_lat: 35.08667,
    stop_lon: -90.06047,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5003,
    stop_code: "MALFLOEF",
    stop_name: "MALLORY@FLORIDA",
    stop_desc: "E MALLORY AVE & FLORIDA ST",
    stop_lat: 35.086536,
    stop_lon: -90.06214,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5004,
    stop_code: "MALSHEEF",
    stop_name: "MALLORY@SHELBY ST",
    stop_desc: "E MALLORY AVE & SHELBY ST",
    stop_lat: 35.086509,
    stop_lon: -90.060315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5005,
    stop_code: "MALTHIEN",
    stop_name: "MALLORY@THIRD",
    stop_desc: "E MALLORY AVE & S THIRD ST",
    stop_lat: 35.086464,
    stop_lon: -90.057042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5006,
    stop_code: "THIBODSN",
    stop_name: "THIRD@BODLEY",
    stop_desc: "US HWY 61 & E BODLEY AVE",
    stop_lat: 35.084453,
    stop_lon: -90.05713,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5007,
    stop_code: "THIPEESN",
    stop_name: "THIRD@PEEBLES",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.068813,
    stop_lon: -90.058681,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5011,
    stop_code: "THIBROSF",
    stop_name: "THIRD@BROOKS",
    stop_desc: "S THIRD ST & E BROOKS RD",
    stop_lat: 35.062324,
    stop_lon: -90.059334,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5012,
    stop_code: "THIROLSN",
    stop_name: "THIRD@ROLLINS",
    stop_desc: "S THIRD ST & E ROLLINS RD",
    stop_lat: 35.06102,
    stop_lon: -90.059467,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5013,
    stop_code: "THIBE1SN",
    stop_name: "THIRD@BELLE HAVEN",
    stop_desc: "S THIRD ST & E BELLE HAVEN RD",
    stop_lat: 35.059768,
    stop_lon: -90.059589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5014,
    stop_code: "THIMITSM",
    stop_name: "THIRD@MITCHELL",
    stop_desc: "US HWY 61 & E BELLE HAVEN RD",
    stop_lat: 35.05748,
    stop_lon: -90.059832,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5015,
    stop_code: "THIMITSF",
    stop_name: "3RD ST@MITCHELL RD",
    stop_desc: "S 3RD ST & N GERMANTOWN PKY",
    stop_lat: 35.056238,
    stop_lon: -90.060021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5016,
    stop_code: "THIFAISM",
    stop_name: "THIRD@FAIRWAY AVE",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.053485,
    stop_lon: -90.060629,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5017,
    stop_code: "THIFA1SM",
    stop_name: "THIRD@FAIRWAY AVE",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.051332,
    stop_lon: -90.061292,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5019,
    stop_code: "FAITHISN",
    stop_name: "FAIRWAY@THIRD",
    stop_desc: "US HWY 61 & STATE HWY 14",
    stop_lat: 35.047667,
    stop_lon: -90.062929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5020,
    stop_code: "THILEVSF",
    stop_name: "THIRD@LEVI",
    stop_desc: "S THIRD ST & RAMP",
    stop_lat: 35.042305,
    stop_lon: -90.066588,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5021,
    stop_code: "THIHORSF",
    stop_name: "THIRD@HORN LAKE",
    stop_desc: "S THIRD ST & US HWY 61",
    stop_lat: 35.038989,
    stop_lon: -90.06976,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5022,
    stop_code: "THIRAISM",
    stop_name: "THIRD@RAINES",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.037631,
    stop_lon: -90.071098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5023,
    stop_code: "THIRAISN",
    stop_name: "3RD ST@RAINES RD",
    stop_desc: "US HWY 61 & STATE HWY 14",
    stop_lat: 35.036102,
    stop_lon: -90.072601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5024,
    stop_code: "THIRAISF",
    stop_name: "THIRD@RAINES",
    stop_desc: "0 S 3RD ST & DRIVEWAY",
    stop_lat: 35.034681,
    stop_lon: -90.07406,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5025,
    stop_code: "THIWESSF",
    stop_name: "THIRD@WESTVIEW",
    stop_desc: "S THIRD ST & WESTVIEW RD",
    stop_lat: 35.024366,
    stop_lon: -90.084381,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5026,
    stop_code: "THIDELSF",
    stop_name: "THIRD@DELTA",
    stop_desc: "S THIRD ST & DELTA RD",
    stop_lat: 35.019861,
    stop_lon: -90.088877,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5028,
    stop_code: "THIWEASN",
    stop_name: "THIRD@WEAVER",
    stop_desc: "US HWY 61 & LAKE MIGUEL RD",
    stop_lat: 35.012665,
    stop_lon: -90.096024,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5030,
    stop_code: "HOLTHIWN",
    stop_name: "HOLMES RD @THIRD ST",
    stop_desc: "W HOLMES RD & RAMP",
    stop_lat: 35.006138,
    stop_lon: -90.103756,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5031,
    stop_code: "HOLTU2WF",
    stop_name: "HOLMES RD@TULIP RD",
    stop_desc: "",
    stop_lat: 35.006103,
    stop_lon: -90.099513,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5049,
    stop_code: "THIHORNM",
    stop_name: "THIRD@HORN LAKE",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.037023,
    stop_lon: -90.07144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5050,
    stop_code: "THIHORNN",
    stop_name: "THIRD@HORN LAKE",
    stop_desc: "S THIRD ST & RAMP",
    stop_lat: 35.038721,
    stop_lon: -90.069771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5051,
    stop_code: "THILEVNN",
    stop_name: "THIRD@LEVI",
    stop_desc: "3880 US HWY 61 & W LEVI RD",
    stop_lat: 35.04268,
    stop_lon: -90.066024,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5052,
    stop_code: "THIBUINN",
    stop_name: "THIRD@BUILDERS",
    stop_desc: "US HWY 61 & BUILDERS WAY",
    stop_lat: 35.045156,
    stop_lon: -90.064222,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5053,
    stop_code: "THIFAINN",
    stop_name: "THIRD@FAIRWAY",
    stop_desc: "S THIRD ST & E FAIRWAY AVE",
    stop_lat: 35.047346,
    stop_lon: -90.062874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5054,
    stop_code: "THIFAINF",
    stop_name: "THIRD@FAIRWAY",
    stop_desc: "? US HWY 61 & E FAIRWAY AVE",
    stop_lat: 35.048123,
    stop_lon: -90.062465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5056,
    stop_code: "THIMI1NM",
    stop_name: "THIRD ST@MITCHELL",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.053691,
    stop_lon: -90.060375,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5057,
    stop_code: "THIMITNN",
    stop_name: "3RD ST@MITCHELL RD",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.05488,
    stop_lon: -90.060076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5058,
    stop_code: "THIBELNM",
    stop_name: "THIRD@BELLE HAVEN",
    stop_desc: "? S THIRD ST & E BELLE HAVEN RD",
    stop_lat: 35.057489,
    stop_lon: -90.059633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5059,
    stop_code: "THIBELNN",
    stop_name: "THIRD@BELLE HAVEN",
    stop_desc: "",
    stop_lat: 35.059196,
    stop_lon: -90.059545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5060,
    stop_code: "THIBRONN",
    stop_name: "THIRD@BROOKS",
    stop_desc: "S THIRD ST & E BROOKS RD",
    stop_lat: 35.062986,
    stop_lon: -90.05908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5061,
    stop_code: "THIEASNN",
    stop_name: "3RD ST@EASTMAN RD",
    stop_desc: "S THIRD ST & EASTMAN RD",
    stop_lat: 35.064469,
    stop_lon: -90.058936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5062,
    stop_code: "THIPE1NN",
    stop_name: "3RD ST@PEEBLES RD",
    stop_desc: "THIRD & PEEBLES RD",
    stop_lat: 35.066244,
    stop_lon: -90.058742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5063,
    stop_code: "THIPEEFM",
    stop_name: "THIRD@PEEBLES",
    stop_desc: "S THIRD ST & E PEEBLES RD",
    stop_lat: 35.067946,
    stop_lon: -90.058571,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5064,
    stop_code: "THIBODNF",
    stop_name: "THIRD@BODLEY",
    stop_desc: "S THIRD ST & E BODLEY AVE",
    stop_lat: 35.08448,
    stop_lon: -90.056788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5065,
    stop_code: "THIMALNF",
    stop_name: "THIRD@MALLORY",
    stop_desc: "US HWY 61 & E MALLORY AVE",
    stop_lat: 35.087,
    stop_lon: -90.056533,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5066,
    stop_code: "THIMCKNN",
    stop_name: "THIRD@MCKELLAR",
    stop_desc: "S THIRD ST & E MCKELLAR AVE",
    stop_lat: 35.089073,
    stop_lon: -90.056378,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5067,
    stop_code: "THIFRANF",
    stop_name: "THIRD@FRANK",
    stop_desc: "S THIRD ST & E FRANK AVE",
    stop_lat: 35.090468,
    stop_lon: -90.056222,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5068,
    stop_code: "THIFERNN",
    stop_name: "THIRD@FERNWOOD",
    stop_desc: "US HWY 61 & E FERNWOOD AVE",
    stop_lat: 35.092434,
    stop_lon: -90.056023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5069,
    stop_code: "THIFERNF",
    stop_name: "THIRD@FERNWOOD",
    stop_desc: "US HWY 61 & S THIRD ST",
    stop_lat: 35.094025,
    stop_lon: -90.055857,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5070,
    stop_code: "THIBE1NN",
    stop_name: "THIRD@BELZ",
    stop_desc: "S THIRD ST & E FERNWOOD AVE",
    stop_lat: 35.09541,
    stop_lon: -90.055713,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5071,
    stop_code: "THIBELNF",
    stop_name: "THIRD@BELZ",
    stop_desc: "S THIRD ST & S 3RD ST",
    stop_lat: 35.09751,
    stop_lon: -90.055558,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5072,
    stop_code: "THIEPENF",
    stop_name: "THIRD@E PERSON",
    stop_desc: "0 S 3RD ST & E PERSON AVE",
    stop_lat: 35.10239,
    stop_lon: -90.055402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5073,
    stop_code: "THIESSNN",
    stop_name: "THIRD@ESSEX",
    stop_desc: "S THIRD ST & E ESSEX AVE",
    stop_lat: 35.103793,
    stop_lon: -90.055346,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5074,
    stop_code: "THIWALNF",
    stop_name: "THIRD@WALDORF",
    stop_desc: "S THIRD ST & E WALDORF AVE",
    stop_lat: 35.106009,
    stop_lon: -90.055279,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5075,
    stop_code: "THISPANM",
    stop_name: "THIRD ST AT S PARKWAY",
    stop_desc: "0 S 3RD ST & E WALDORF AVE",
    stop_lat: 35.106697,
    stop_lon: -90.055257,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5076,
    stop_code: "THIMCLNN",
    stop_name: "3RD ST@MCLEMORE AVE",
    stop_desc: "S THIRD ST & E MCLEMORE AVE",
    stop_lat: 35.115777,
    stop_lon: -90.055112,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5077,
    stop_code: "LATSPASF",
    stop_name: "LATHAM@SPARKWAY",
    stop_desc: "",
    stop_lat: 35.106966,
    stop_lon: -90.051667,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5079,
    stop_code: "WALLATEF",
    stop_name: "WALDORF@LATHAM",
    stop_desc: "E WALDORF AVE & LATHAM ST",
    stop_lat: 35.105401,
    stop_lon: -90.051319,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5080,
    stop_code: "WALCAMEN",
    stop_name: "WALDORF@CAMERON",
    stop_desc: "E WALDORF AVE & PRESTON ST",
    stop_lat: 35.105365,
    stop_lon: -90.049405,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5081,
    stop_code: "WALSHAEN",
    stop_name: "WALDORF@SHADOWLAWN",
    stop_desc: "E WALDORF AVE & SHADOWLAWN BLVD",
    stop_lat: 35.105338,
    stop_lon: -90.048222,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5082,
    stop_code: "WALWELEN",
    stop_name: "WALDORF@WELLINGTON",
    stop_desc: "E WALDORF AVE & SHADOWLAWN BLVD",
    stop_lat: 35.105311,
    stop_lon: -90.047038,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5090,
    stop_code: "LAUJACNN",
    stop_name: "LAUDERDALE@JACKLYN",
    stop_desc: "S LAUDERDALE ST & JACKLYN AVE",
    stop_lat: 35.094112,
    stop_lon: -90.044221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5100,
    stop_code: "WALSHAWN",
    stop_name: "WALDORF@SHADOWLAWN",
    stop_desc: "E WALDORF AVE & S WELLINGTON ST",
    stop_lat: 35.10549,
    stop_lon: -90.047956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5101,
    stop_code: "WALCAMWN",
    stop_name: "WALDORF@CAMERON",
    stop_desc: "E WALDORF AVE & CAMERON ST",
    stop_lat: 35.105517,
    stop_lon: -90.049173,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5102,
    stop_code: "WALPREWN",
    stop_name: "WALDORF@PRESTON",
    stop_desc: "E WALDORF AVE & CAMERON ST",
    stop_lat: 35.105535,
    stop_lon: -90.050357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5103,
    stop_code: "WALLATWN",
    stop_name: "WALDORF@LATHAM",
    stop_desc: "E WALDORF AVE & LATHAM ST",
    stop_lat: 35.105562,
    stop_lon: -90.051507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5104,
    stop_code: "LATSPANN",
    stop_name: "LATHAM@SPARKWAY",
    stop_desc: "LATHAM ST & E WALDORF AVE",
    stop_lat: 35.107179,
    stop_lon: -90.051485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5105,
    stop_code: "BELMCLSF",
    stop_name: "BELLEVUE@MCLEMORE",
    stop_desc: "STATE HWY 3 & E MCLEMORE AVE",
    stop_lat: 35.114472,
    stop_lon: -90.021765,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5106,
    stop_code: "BELORGSM",
    stop_name: "BELLEVUE@ORGILL",
    stop_desc: "STATE HWY 3 & ORGILL AVE",
    stop_lat: 35.112328,
    stop_lon: -90.021767,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5107,
    stop_code: "BELTRISF",
    stop_name: "BELLEVUE@TRIGG",
    stop_desc: "US HWY 51 & E TRIGG AVE",
    stop_lat: 35.111496,
    stop_lon: -90.0218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5108,
    stop_code: "BELSPASN",
    stop_name: "BELLEVUE@S PARKWAY E",
    stop_desc: "STATE HWY 3 & S BELLEVUE BLVD",
    stop_lat: 35.109941,
    stop_lon: -90.021868,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5109,
    stop_code: "ELVSPASF",
    stop_name: "ELVIS PRESLEY@SPARKWAY E",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.108931,
    stop_lon: -90.021691,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5110,
    stop_code: "ELVKERSF",
    stop_name: "ELVIS PRESLEY@KERR",
    stop_desc: "BELLEVUE BLVD & ELVIS PRESLEY BLVD",
    stop_lat: 35.106527,
    stop_lon: -90.021184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5111,
    stop_code: "ELVMONSF",
    stop_name: "ELVIS PRESLEY@MONTGOMERY",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.103792,
    stop_lon: -90.020389,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5112,
    stop_code: "ELVDORSM",
    stop_name: "ELVIS PRESLEY@DORIS",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.102916,
    stop_lon: -90.020003,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5114,
    stop_code: "ELVPERSN",
    stop_name: "ELVIS PRESLEY@PERSON AVE",
    stop_desc: "S BELLEVUE BLVD & US HWY 51",
    stop_lat: 35.100798,
    stop_lon: -90.018997,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5115,
    stop_code: "ELVPERSF",
    stop_name: "ELVIS PRESLEY@PERSON",
    stop_desc: "S BELLEVUE BLVD & ELVIS PRESLEY BLVD",
    stop_lat: 35.099358,
    stop_lon: -90.018379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5116,
    stop_code: "ELVMENSN",
    stop_name: "ELVIS PRESLEY@MENAGER",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.097616,
    stop_lon: -90.01807,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5117,
    stop_code: "ELVROBSF",
    stop_name: "ELVIS PRESLEY@ROBERTS",
    stop_desc: "? US HWY 51 & VALSE RD",
    stop_lat: 35.094979,
    stop_lon: -90.018161,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5118,
    stop_code: "ELVLEDSF",
    stop_name: "ELVIS PRESLEY@LEDGER",
    stop_desc: "S BELLEVUE BLVD & VALSE RD",
    stop_lat: 35.094264,
    stop_lon: -90.018172,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5121,
    stop_code: "ELVEFFSM",
    stop_name: "ELVIS PRESLEY@EFFIE",
    stop_desc: "US HWY 51 & ELLISTON RD",
    stop_lat: 35.086998,
    stop_lon: -90.018454,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5122,
    stop_code: "ELVMALSN",
    stop_name: "ELVIS PRESLEY@MALLORY",
    stop_desc: "S BELLEVUE BLVD & E MALLORY AVE",
    stop_lat: 35.085667,
    stop_lon: -90.01851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5124,
    stop_code: "ELVGAUSN",
    stop_name: "ELVIS PRESLEY@GAUSCO",
    stop_desc: "US HWY 51 & GAUSCO AVE",
    stop_lat: 35.081288,
    stop_lon: -90.018878,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5125,
    stop_code: "ELVWHISN",
    stop_name: "ELVIS PRESLEY@WHITMORE",
    stop_desc: "STATE HWY 3 & WHITMORE RD",
    stop_lat: 35.079974,
    stop_lon: -90.019133,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5126,
    stop_code: "ELVALCSN",
    stop_name: "ELVIS PRESLEY@ALCY",
    stop_desc: "US HWY 51 & E ALCY RD",
    stop_lat: 35.078267,
    stop_lon: -90.019521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5128,
    stop_code: "ELVFERSM",
    stop_name: "ELVIS PRESLEY@FERGUSON",
    stop_desc: "STATE HWY 3 & FERGUSON RD",
    stop_lat: 35.075175,
    stop_lon: -90.020297,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5129,
    stop_code: "ELVCLESN",
    stop_name: "ELVIS PRESLEY@CLEMENTINE",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.07312,
    stop_lon: -90.020796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5130,
    stop_code: "ELVCORSN",
    stop_name: "ELVIS PRESLEY@CORPORATE",
    stop_desc: "S BELLEVUE BLVD & US HWY 51",
    stop_lat: 35.070698,
    stop_lon: -90.021384,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5131,
    stop_code: "ELVSPRSM",
    stop_name: "ELVIS PRESLEY@SPRING BROOK",
    stop_desc: "S BELLEVUE BLVD & US HWY 51",
    stop_lat: 35.068294,
    stop_lon: -90.021982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5132,
    stop_code: "ELVSPRSF",
    stop_name: "ELVIS PRESLEY@SPRING BROOK",
    stop_desc: "STATE HWY 3 & SPRINGBROOK AVE",
    stop_lat: 35.0648,
    stop_lon: -90.022858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5133,
    stop_code: "ELVBROSF",
    stop_name: "ELVIS P. @ BROOKS",
    stop_desc: "STATE HWY 3 & COMMERCIAL PKWY",
    stop_lat: 35.060868,
    stop_lon: -90.023822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5134,
    stop_code: "ELVGATSN",
    stop_name: "ELVIS PRESLEY@GATEWAY",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.058893,
    stop_lon: -90.024277,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5135,
    stop_code: "ELVOLDSN",
    stop_name: "ELVIS PRESLEY@OLD HERNANDO",
    stop_desc: "STATE HWY 3 & OLD HERNANDO RD",
    stop_lat: 35.056534,
    stop_lon: -90.024787,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5136,
    stop_code: "ELVWINSF",
    stop_name: "ELVIS PRESLEY@WINCHESTER",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.053915,
    stop_lon: -90.025076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5137,
    stop_code: "ELVBLUSN",
    stop_name: "ELVIS PRESLEY@BLUEBIRD",
    stop_desc: "",
    stop_lat: 35.051386,
    stop_lon: -90.025066,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5138,
    stop_code: "ELVCOLSN",
    stop_name: "ELVIS PRESLEY@COLONIAL OAK LN",
    stop_desc: "US HWY 51 & COLONIAL OAK LN",
    stop_lat: 35.049795,
    stop_lon: -90.025144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5139,
    stop_code: "ELVCOLSF",
    stop_name: "ELVIS PRESLEY@COLONIAL OAK LN",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.04656,
    stop_lon: -90.025168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5140,
    stop_code: "ELVDOLSN",
    stop_name: "ELVIS PRESLEY BLVD@DOLAN DR",
    stop_desc: "3769 STATE HWY 3 & DOLAN DR",
    stop_lat: 35.045201,
    stop_lon: -90.02518,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5141,
    stop_code: "ELVCRASF",
    stop_name: "ELVIS PRESLEY@CRAFT RD",
    stop_desc: "",
    stop_lat: 35.04378,
    stop_lon: -90.025104,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5142,
    stop_code: "ELVWH1SN",
    stop_name: "ELVIS PRESLEY@WHITAKER",
    stop_desc: "US HWY 51 & WHITAKER DR",
    stop_lat: 35.042019,
    stop_lon: -90.025193,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5143,
    stop_code: "ELVLAUSF",
    stop_name: "ELVIS PRESLEY@LAUDEEN",
    stop_desc: "0 ELVIS PRESLEY BLVD & DRIVEWAY",
    stop_lat: 35.038587,
    stop_lon: -90.025239,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5144,
    stop_code: "ELVRAISM",
    stop_name: "ELVIS PRESLEY@RAINES",
    stop_desc: "0 ELVIS PRESLEY BLVD & DRIVEWAY",
    stop_lat: 35.037417,
    stop_lon: -90.02524,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5177,
    stop_code: "ELVRICNN",
    stop_name: "ELVIS PRESLEY@RICHLAND",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.007833,
    stop_lon: -90.025136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5178,
    stop_code: "ELVHESNN",
    stop_name: "ELVIS PRESLEY@HESTER",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.010282,
    stop_lon: -90.025123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5179,
    stop_code: "ELVPALNF",
    stop_name: "ELVIS PRESLEY@PALMER",
    stop_desc: "US HWY 51 & W LIONS GATE DR",
    stop_lat: 35.012784,
    stop_lon: -90.025111,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5180,
    stop_code: "ELVMCCNF",
    stop_name: "ELVIS PRESLEY@MCCLURE",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.015108,
    stop_lon: -90.025109,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5181,
    stop_code: "ELVWHINN",
    stop_name: "ELVIS PRESLEY@WHITEHAVEN",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.017119,
    stop_lon: -90.025108,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5182,
    stop_code: "ELVSHENN",
    stop_name: "ELVIS PRESLEY BLVD@SHELBY DR",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.020185,
    stop_lon: -90.025084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5184,
    stop_code: "ELVLAUNM",
    stop_name: "ELVIS PRESLEY@LAUDEEN",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.036907,
    stop_lon: -90.025019,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5185,
    stop_code: "ELVLA1NM",
    stop_name: "ELVIS PRESLEY@LAUDEEN",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.038301,
    stop_lon: -90.025007,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5186,
    stop_code: "ELVLAUNN",
    stop_name: "ELVIS PRESLEY@LAUDEEN",
    stop_desc: "",
    stop_lat: 35.040481,
    stop_lon: -90.025028,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5187,
    stop_code: "ELVTIMNN",
    stop_name: "ELVIS PRESLEY@TIMOTHY",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.04243,
    stop_lon: -90.024994,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5188,
    stop_code: "ELVCRANN",
    stop_name: "ELVIS PRESLEY@CRAFT",
    stop_desc: "US HWY 51 & CRAFT RD",
    stop_lat: 35.043869,
    stop_lon: -90.024993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5189,
    stop_code: "ELVOLDNM",
    stop_name: "ELVIS PRESLEY@OLD HICKORY",
    stop_desc: "3690 STATE HWY 3 & DOLAN DR",
    stop_lat: 35.048133,
    stop_lon: -90.024957,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5190,
    stop_code: "ELVOL1NM",
    stop_name: "ELVIS PRESLEY@OLD HICKORY",
    stop_desc: "STATE HWY 3 & US HWY 51",
    stop_lat: 35.050403,
    stop_lon: -90.024945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5191,
    stop_code: "ELVOLDNN",
    stop_name: "ELVIS PRESLEY BLVD@OLD HICKORY",
    stop_desc: "STATE HWY 3 & BLUEBIRD RD",
    stop_lat: 35.051145,
    stop_lon: -90.024945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5192,
    stop_code: "ELVOHINF",
    stop_name: "ELVIS PRESLEY@OLD HICKORY RD",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.05228,
    stop_lon: -90.024922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5193,
    stop_code: "ELVWINNN",
    stop_name: "ELVIS PRESLEY@WINCHESTER",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.054085,
    stop_lon: -90.024876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5194,
    stop_code: "ELVWI1NF",
    stop_name: "ELVIS PRESLEY BLVD @ WINCHESTER RD",
    stop_desc: "? US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.05455,
    stop_lon: -90.024865,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5195,
    stop_code: "ELVGATNM",
    stop_name: "ELVIS PRESLEY@GATEWAY",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.057007,
    stop_lon: -90.024499,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5196,
    stop_code: "ELVGATNF",
    stop_name: "ELVIS PRESLEY@GATEWAY",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.058893,
    stop_lon: -90.024067,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5197,
    stop_code: "ELVCOMNN",
    stop_name: "ELVIS P. @ BROOKS",
    stop_desc: "STATE HWY 3 & COMMERCIAL PKWY",
    stop_lat: 35.060269,
    stop_lon: -90.023756,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5198,
    stop_code: "ELVSPRNN",
    stop_name: "ELVIS PRESLEY@SPRING BROOK",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.064889,
    stop_lon: -90.022615,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5199,
    stop_code: "ELVCORNM",
    stop_name: "ELVIS PRESLEY@CORPORATE",
    stop_desc: "US HWY 51 & STATE HWY 3",
    stop_lat: 35.0685,
    stop_lon: -90.021728,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5200,
    stop_code: "ELVCORNN",
    stop_name: "ELVIS PRESLEY@CORPORATE",
    stop_desc: "S BELLEVUE BLVD & CORPORATE AVE",
    stop_lat: 35.070573,
    stop_lon: -90.021218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5201,
    stop_code: "ELVCLENF",
    stop_name: "ELVIS PRESLEY@CLEMENTINE",
    stop_desc: "? US HWY 51 & STATE HWY 3",
    stop_lat: 35.073013,
    stop_lon: -90.020619,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5202,
    stop_code: "ELVFERNN",
    stop_name: "ELVIS PRESLEY@FERGUSON AVE",
    stop_desc: "US HWY 51 & FERGUSON RD",
    stop_lat: 35.074371,
    stop_lon: -90.020287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5203,
    stop_code: "ELVCARNN",
    stop_name: "ELVIS PRESLEY@CARLTON",
    stop_desc: "STATE HWY 3 & CARLTON RD",
    stop_lat: 35.076721,
    stop_lon: -90.01971,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5204,
    stop_code: "ELVALCNF",
    stop_name: "ELVIS PRESLEY@ALCY",
    stop_desc: "US HWY 51 & E ALCY RD",
    stop_lat: 35.07833,
    stop_lon: -90.019311,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5205,
    stop_code: "ELVWHINF",
    stop_name: "ELVIS PRESLEY@WHITMORE",
    stop_desc: "STATE HWY 3 & WHITMORE RD",
    stop_lat: 35.080001,
    stop_lon: -90.018923,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5206,
    stop_code: "ELVGAUNF",
    stop_name: "ELVIS PRESLEY@GAUSCO",
    stop_desc: "US HWY 51 & GAUSCO AVE",
    stop_lat: 35.081243,
    stop_lon: -90.018679,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5208,
    stop_code: "ELVMALNN",
    stop_name: "ELVIS PRESLEY@MALLORY",
    stop_desc: "S BELLEVUE BLVD & E MALLORY AVE",
    stop_lat: 35.085345,
    stop_lon: -90.018322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5209,
    stop_code: "ELVROSNM",
    stop_name: "ELVIS PRESLEY@ROSE HILL",
    stop_desc: "STATE HWY 3 & ELLISTON RD",
    stop_lat: 35.08825,
    stop_lon: -90.018198,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5210,
    stop_code: "ELVROSNN",
    stop_name: "ELVIS PRESLEY@ROSE HILL",
    stop_desc: "STATE HWY 3 & ELVIS PRESLEY BLVD",
    stop_lat: 35.090671,
    stop_lon: -90.018108,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5211,
    stop_code: "ELVDUNNN",
    stop_name: "ELVIS PRESLEY@DUNN",
    stop_desc: "STATE HWY 3 & DUNN AVE",
    stop_lat: 35.092432,
    stop_lon: -90.018041,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5212,
    stop_code: "ELVLEDNN",
    stop_name: "ELVIS PRESLEY@LEDGER",
    stop_desc: "US HWY 51 & DUNN AVE",
    stop_lat: 35.093799,
    stop_lon: -90.017996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5213,
    stop_code: "ELVROBNN",
    stop_name: "ELVIS PRESLEY@ROBERTS",
    stop_desc: "S BELLEVUE BLVD & VALSE RD",
    stop_lat: 35.095104,
    stop_lon: -90.017961,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5214,
    stop_code: "ELVMENNN",
    stop_name: "ELVIS PRESLEY@MENAGER RD",
    stop_desc: "S BELLEVUE BLVD & ELVIS PRESLEY BLVD",
    stop_lat: 35.097204,
    stop_lon: -90.017872,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5215,
    stop_code: "ELVPERNM",
    stop_name: "ELVIS PRESLEY@PERSON",
    stop_desc: "US HWY 51 & ELVIS PRESLEY BLVD",
    stop_lat: 35.099975,
    stop_lon: -90.01839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5216,
    stop_code: "ELVDORNN",
    stop_name: "ELVIS PRESLEY@DORIS",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.10179,
    stop_lon: -90.019251,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5217,
    stop_code: "ELVMONNF",
    stop_name: "ELVIS PRESLEY@MONTGOMERY",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.104266,
    stop_lon: -90.020367,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5219,
    stop_code: "ELVSPANN",
    stop_name: "ELVIS PRESLEY@S PARKWAY E",
    stop_desc: "US HWY 51 & S PARKWAY E",
    stop_lat: 35.109396,
    stop_lon: -90.021592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5220,
    stop_code: "ELVSPANF",
    stop_name: "ELVIS PRESLEY@S PARKWAY E",
    stop_desc: "US HWY 51 & BELLEVUE BLVD",
    stop_lat: 35.109861,
    stop_lon: -90.021669,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5221,
    stop_code: "BELTRINN",
    stop_name: "BELLEVUE@TRIGG",
    stop_desc: "US HWY 51 & E TRIGG AVE",
    stop_lat: 35.111496,
    stop_lon: -90.021601,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5222,
    stop_code: "BELMCLNM",
    stop_name: "BELLEVUE@MCLEMORE",
    stop_desc: "STATE HWY 3 & E MCLEMORE AVE",
    stop_lat: 35.115134,
    stop_lon: -90.021588,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5224,
    stop_code: "AIRDEASN",
    stop_name: "AIRWAYS BLVD@DEADRICK",
    stop_desc: "AIRWAYS BLVD & DEADRICK AVE",
    stop_lat: 35.10464,
    stop_lon: -89.986051,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5225,
    stop_code: "AIRMALSN",
    stop_name: "AIRWAYS BLVD@MALONE AVE",
    stop_desc: "",
    stop_lat: 35.10329,
    stop_lon: -89.986462,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5226,
    stop_code: "AIRARLSN",
    stop_name: "AIRWAYS BLVD@ARLINGTON AVE",
    stop_desc: "",
    stop_lat: 35.102316,
    stop_lon: -89.98675,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5227,
    stop_code: "AIRLARSN",
    stop_name: "AIRWAYS BLVD@LAROSE",
    stop_desc: "AIRWAYS BLVD & LAROSE AVE",
    stop_lat: 35.101369,
    stop_lon: -89.987039,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5228,
    stop_code: "AIRBOYSN",
    stop_name: "AIRWAYS BLVD@BOYLE",
    stop_desc: "1717 AIRWAYS BLVD & BOYLE AVE",
    stop_lat: 35.098617,
    stop_lon: -89.987485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5229,
    stop_code: "AIRDUNSF",
    stop_name: "AIRWAYS BLVD@DUNN RD",
    stop_desc: "AIRWAYS BLVD & DUNN AVE",
    stop_lat: 35.090735,
    stop_lon: -89.987758,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5230,
    stop_code: "AIRMEMSM",
    stop_name: "AIRWAYS BLVD@MEMPHIS DEPOT P",
    stop_desc: "AIRWAYS BLVD & BOYLE AVE",
    stop_lat: 35.087267,
    stop_lon: -89.987917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5231,
    stop_code: "AIRDWISF",
    stop_name: "AIRWAYS BLVD@DWIGHT",
    stop_desc: "AIRWAYS BLVD & DWIGHT RD",
    stop_lat: 35.084549,
    stop_lon: -89.986692,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5233,
    stop_code: "AIRBALSN",
    stop_name: "AIRWAYS BLVD@BALL RD",
    stop_desc: "AIRWAYS BLVD & CANDLEWYCK CIR",
    stop_lat: 35.08159,
    stop_lon: -89.985556,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5234,
    stop_code: "AIRJOYSM",
    stop_name: "AIRWAYS BLVD@JOY",
    stop_desc: "AIRWAYS BLVD & JOY LN",
    stop_lat: 35.080527,
    stop_lon: -89.985237,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5235,
    stop_code: "AIRDEMSN",
    stop_name: "AIRWAYS BLVD@DEMOCRAT",
    stop_desc: "",
    stop_lat: 35.068356,
    stop_lon: -89.987838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5236,
    stop_code: "AIRDIRSM",
    stop_name: "AIRWAYS BLVD@DIRECTORS ROW",
    stop_desc: "AIRWAYS BLVD & RAMP",
    stop_lat: 35.067391,
    stop_lon: -89.988635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5237,
    stop_code: "AIRDI1SM",
    stop_name: "AIRWAYS BLVD@DIRECTORS ROW",
    stop_desc: "AIRWAYS BLVD & AIRPORT INTERCHANGE AVE",
    stop_lat: 35.066668,
    stop_lon: -89.989122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5238,
    stop_code: "AIRDIRSN",
    stop_name: "AIRWAYS BLVD@DIRECTORS ROW",
    stop_desc: "",
    stop_lat: 35.064684,
    stop_lon: -89.99044,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5239,
    stop_code: "AIRBROSM",
    stop_name: "AIRWAYS BLVD@BROOKS",
    stop_desc: "AIRWAYS BLVD & JOY LN",
    stop_lat: 35.063184,
    stop_lon: -89.991337,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5240,
    stop_code: "AIRBROSN",
    stop_name: "AIRWAYS BLVD@BROOKS",
    stop_desc: "",
    stop_lat: 35.061432,
    stop_lon: -89.991604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5241,
    stop_code: "BROAIRWF",
    stop_name: "BROOKS@AIRWAYS",
    stop_desc: "E BROOKS RD & RAMP",
    stop_lat: 35.0607,
    stop_lon: -89.993175,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5242,
    stop_code: "BROAI2WF",
    stop_name: "BROOKS@AIRWAYS",
    stop_desc: "",
    stop_lat: 35.060646,
    stop_lon: -89.99302,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5243,
    stop_code: "BROWINWF",
    stop_name: "BROOKS@WINBROOK",
    stop_desc: "E BROOKS RD & WINBROOK DR",
    stop_lat: 35.060819,
    stop_lon: -89.997365,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5244,
    stop_code: "BROCAZWN",
    stop_name: "BROOKS@CAZASSA",
    stop_desc: "E BROOKS RD & CAZASSA RD",
    stop_lat: 35.060938,
    stop_lon: -90.0015,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5245,
    stop_code: "BROMILWN",
    stop_name: "BROOKS@MILLBRANCH RD",
    stop_desc: "E BROOKS RD & CARRIER AVE",
    stop_lat: 35.061084,
    stop_lon: -90.00695,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5246,
    stop_code: "BROFONWM",
    stop_name: "BROOKS@FONTAINE",
    stop_desc: "E BROOKS RD & TCHULAHOMA RD",
    stop_lat: 35.061129,
    stop_lon: -90.008553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5247,
    stop_code: "BROFONWF",
    stop_name: "BROOKS@FONTAINE",
    stop_desc: "E BROOKS RD & FONTAINE RD",
    stop_lat: 35.061157,
    stop_lon: -90.009648,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5248,
    stop_code: "BROONEWN",
    stop_name: "BROOKS@ONE PLACE",
    stop_desc: "E BROOKS RD & BRENMARDA LN",
    stop_lat: 35.061284,
    stop_lon: -90.01333,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5249,
    stop_code: "BROCOUWN",
    stop_name: "BROOKS@COUGHLIN",
    stop_desc: "E BROOKS RD & ONE PL",
    stop_lat: 35.061329,
    stop_lon: -90.01553,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5250,
    stop_code: "BROLUCWM",
    stop_name: "BROOKS@LUCIBILL",
    stop_desc: "E BROOKS RD & BELLBROOK DR",
    stop_lat: 35.061664,
    stop_lon: -90.026475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5251,
    stop_code: "BROLUCWN",
    stop_name: "BROOKS RD@LUCIBILL RD",
    stop_desc: "E BROOKS RD & LUCIBILL RD",
    stop_lat: 35.061701,
    stop_lon: -90.028023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5252,
    stop_code: "BROROSWF",
    stop_name: "BROOKS@ROSITA",
    stop_desc: "E BROOKS RD & E ROSITA CIR E",
    stop_lat: 35.0618,
    stop_lon: -90.031727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5253,
    stop_code: "BRODOGWF",
    stop_name: "BROOKS@DOGWOOD LN",
    stop_desc: "E BROOKS RD & CONNAHBROOK DR",
    stop_lat: 35.061837,
    stop_lon: -90.033474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5254,
    stop_code: "BROLAKWN",
    stop_name: "BROOKS@LAKEVIEW RD",
    stop_desc: "E BROOKS RD & LAKEVIEW RD",
    stop_lat: 35.061891,
    stop_lon: -90.035641,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5255,
    stop_code: "BROGRAWM",
    stop_name: "BROOKS@GRAVES RD",
    stop_desc: "E BROOKS RD & GRAVES RD",
    stop_lat: 35.061964,
    stop_lon: -90.039599,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5256,
    stop_code: "BROTULWF",
    stop_name: "BROOKS@TULANE",
    stop_desc: "E BROOKS RD & GRAVES RD",
    stop_lat: 35.062591,
    stop_lon: -90.046874,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5257,
    stop_code: "BROTRAWN",
    stop_name: "BROOKS@TRANQUILITY DR",
    stop_desc: "E BROOKS RD & TRANQUILITY DR",
    stop_lat: 35.062788,
    stop_lon: -90.048057,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5258,
    stop_code: "BROGE1WM",
    stop_name: "BROOKS@GEMINI",
    stop_desc: "E BROOKS RD & GEMINI DR",
    stop_lat: 35.062985,
    stop_lon: -90.050622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5259,
    stop_code: "BROSOUWN",
    stop_name: "BROOKS@SOUTH CENTER",
    stop_desc: "E BROOKS RD & S CENTER RD",
    stop_lat: 35.063048,
    stop_lon: -90.052623,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5260,
    stop_code: "BROGILWN",
    stop_name: "BROOKS@GILL",
    stop_desc: "E BROOKS RD & GILL RD",
    stop_lat: 35.063129,
    stop_lon: -90.056448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5261,
    stop_code: "BROGILWF",
    stop_name: "BROOKS@GILL",
    stop_desc: "E BROOKS RD & GILL RD",
    stop_lat: 35.063155,
    stop_lon: -90.057775,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5262,
    stop_code: "BROTHIWF",
    stop_name: "BROOKS@THIRD",
    stop_desc: "E BROOKS RD & GILL RD",
    stop_lat: 35.063227,
    stop_lon: -90.059865,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5263,
    stop_code: "BROMEAWM",
    stop_name: "BROOKS@MEADOWBROOK",
    stop_desc: "E BROOKS RD & GILL RD",
    stop_lat: 35.063272,
    stop_lon: -90.062474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5264,
    stop_code: "BROLOTWN",
    stop_name: "BROOKS@LOTUS",
    stop_desc: "W BROOKS RD & MEADOWBROOK RD",
    stop_lat: 35.06329,
    stop_lon: -90.064907,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5265,
    stop_code: "BROREDWN",
    stop_name: "BROOKS@REDBUD",
    stop_desc: "W BROOKS RD & REDBUD RD",
    stop_lat: 35.063335,
    stop_lon: -90.066952,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5266,
    stop_code: "BRONEWWN",
    stop_name: "BROOKS@NEW HORN LAKE R",
    stop_desc: "W BROOKS RD & REDBUD RD",
    stop_lat: 35.063388,
    stop_lon: -90.068843,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5267,
    stop_code: "BROOLDWN",
    stop_name: "BROOKS@OLD HORN LAKE R",
    stop_desc: "W BROOKS RD & HORN LAKE RD",
    stop_lat: 35.063442,
    stop_lon: -90.070125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5268,
    stop_code: "BROOLDWF",
    stop_name: "BROOKS@OLD HORN LAKE",
    stop_desc: "W BROOKS RD & HORN LAKE RD",
    stop_lat: 35.063433,
    stop_lon: -90.07091,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5269,
    stop_code: "BROROCWN",
    stop_name: "BROOKS@ROCHESTER",
    stop_desc: "W BROOKS RD & ROCHESTER RD",
    stop_lat: 35.06337,
    stop_lon: -90.073984,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5279,
    stop_code: "BROREDEF",
    stop_name: "BROOKS RD@REDBUD",
    stop_desc: "W BROOKS RD & REDBUD RD",
    stop_lat: 35.063192,
    stop_lon: -90.067527,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5280,
    stop_code: "BROLOTEF",
    stop_name: "BROOKS RD@LOTUS",
    stop_desc: "W BROOKS RD & LOTUS RD",
    stop_lat: 35.063129,
    stop_lon: -90.065084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5281,
    stop_code: "BROMEAEF",
    stop_name: "BROOKS RD@MEADOWBROOK",
    stop_desc: "E BROOKS RD & LOTUS RD",
    stop_lat: 35.063111,
    stop_lon: -90.062695,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5282,
    stop_code: "BROTHIEN",
    stop_name: "BROOKS RD@THIRD",
    stop_desc: "E BROOKS RD & LOTUS RD",
    stop_lat: 35.063057,
    stop_lon: -90.059434,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5285,
    stop_code: "BROSOUEF",
    stop_name: "BROOKS RD@SOUTH CENTER",
    stop_desc: "E BROOKS RD & S CENTER RD",
    stop_lat: 35.062887,
    stop_lon: -90.0528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5286,
    stop_code: "BROTULEM",
    stop_name: "BROOKS RD@TULANE",
    stop_desc: "E BROOKS RD & S CENTER RD",
    stop_lat: 35.062815,
    stop_lon: -90.05008,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5287,
    stop_code: "BROTRAEN",
    stop_name: "BROOKS RD@TRANQUILITY",
    stop_desc: "E BROOKS RD & TRANQUILITY DR",
    stop_lat: 35.062707,
    stop_lon: -90.048665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5289,
    stop_code: "BROGRAEM",
    stop_name: "BROOKS RD@GRAVES",
    stop_desc: "E BROOKS RD & PARKING LOT",
    stop_lat: 35.061902,
    stop_lon: -90.042617,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5290,
    stop_code: "BROLAKEN",
    stop_name: "BROOKS RD@LAKEVIEW RD",
    stop_desc: "E BROOKS RD & MCCORKLE RD",
    stop_lat: 35.061757,
    stop_lon: -90.036636,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5291,
    stop_code: "BRODOGEN",
    stop_name: "BROOKS RD@DOGWOOD LN",
    stop_desc: "E BROOKS RD & DOGWOOD LN",
    stop_lat: 35.061676,
    stop_lon: -90.03322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5292,
    stop_code: "BROFLEEN",
    stop_name: "BROOKS RD@FLEETBROOK DR",
    stop_desc: "E BROOKS RD & FLEETBROOK DR",
    stop_lat: 35.061648,
    stop_lon: -90.031926,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5293,
    stop_code: "BROLUCEN",
    stop_name: "BROOKS RD@LUCIBILL",
    stop_desc: "E BROOKS RD & BELLBROOK CENTER DR",
    stop_lat: 35.061549,
    stop_lon: -90.028366,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5294,
    stop_code: "BROHEREF",
    stop_name: "BROOKS RD@HERNANDO RD",
    stop_desc: "0 E BROOKS RD & DURBY CIR",
    stop_lat: 35.061628,
    stop_lon: -90.024507,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5295,
    stop_code: "BROELVEF",
    stop_name: "BROOKS RD @ ELVIS PRESLEY BLVD",
    stop_desc: "? BROOKS RD & ELVIS PRESLEY",
    stop_lat: 35.061538,
    stop_lon: -90.022843,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5296,
    stop_code: "BROONEEN",
    stop_name: "BROOKS RD@ONE PLACE",
    stop_desc: "E BROOKS RD & ONE PL",
    stop_lat: 35.061132,
    stop_lon: -90.014347,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5297,
    stop_code: "BROFONEN",
    stop_name: "BROOKS RD@FONTAINE",
    stop_desc: "E BROOKS RD & FONTAINE RD",
    stop_lat: 35.061014,
    stop_lon: -90.010267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5298,
    stop_code: "BROMILEN",
    stop_name: "BROOKS RD@MILLBRANCH",
    stop_desc: "E BROOKS RD & FONTAINE RD",
    stop_lat: 35.060941,
    stop_lon: -90.007415,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5299,
    stop_code: "BROMILEF",
    stop_name: "BROOKS RD@MILLBRANCH RD",
    stop_desc: "E BROOKS RD & CARRIER AVE",
    stop_lat: 35.060905,
    stop_lon: -90.006365,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5300,
    stop_code: "BROCAREN",
    stop_name: "BROOKS RD@CARRIER ST",
    stop_desc: "E BROOKS RD & CARRIER AVE",
    stop_lat: 35.06085,
    stop_lon: -90.003943,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5301,
    stop_code: "BROSTAEN",
    stop_name: "BROOKS RD@STARNES CV",
    stop_desc: "E BROOKS RD & UNNAMED ST",
    stop_lat: 35.06074,
    stop_lon: -90.000549,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5302,
    stop_code: "BROWI1EN",
    stop_name: "BROOKS RD@WINBROOK",
    stop_desc: "E BROOKS RD & WINBROOK DR",
    stop_lat: 35.060658,
    stop_lon: -89.997454,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5303,
    stop_code: "BRODIREN",
    stop_name: "BROOKS RD@DIRECTORS ROW",
    stop_desc: "E BROOKS RD & DIRECTORS ROW",
    stop_lat: 35.060594,
    stop_lon: -89.995165,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5305,
    stop_code: "AIRWINSM",
    stop_name: "AIRWAYS BLVD@WINCHESTER",
    stop_desc: "",
    stop_lat: 35.056767,
    stop_lon: -89.991708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5313,
    stop_code: "AIRWINNF",
    stop_name: "AIRWAYS BLVD@WINCHESTER",
    stop_desc: "AIRWAYS BLVD & CONNECTING RD",
    stop_lat: 35.053978,
    stop_lon: -89.991181,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5314,
    stop_code: "AIRBRONM",
    stop_name: "AIRWAYS BLVD@BROOKS",
    stop_desc: "AIRWAYS BLVD & CONNECTING RD",
    stop_lat: 35.055837,
    stop_lon: -89.991267,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5318,
    stop_code: "AIRDEMNM",
    stop_name: "AIRWAYS BLVD@DEMOCRAT",
    stop_desc: "AIRWAYS BLVD & E WALDORF AVE",
    stop_lat: 35.066676,
    stop_lon: -89.988647,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5319,
    stop_code: "AIRDEMNN",
    stop_name: "AIRWAYS BLVD@DEMOCRAT",
    stop_desc: "AIRWAYS BLVD & RAMP",
    stop_lat: 35.067757,
    stop_lon: -89.987938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5320,
    stop_code: "AIRKETNF",
    stop_name: "AIRWAYS BLVD@KETCHUM RD",
    stop_desc: "",
    stop_lat: 35.079499,
    stop_lon: -89.984773,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5321,
    stop_code: "AIRPECNN",
    stop_name: "AIRWAYS BLVD@PECAN CL",
    stop_desc: "AIRWAYS BLVD & PECAN CIR",
    stop_lat: 35.082198,
    stop_lon: -89.985545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5322,
    stop_code: "AIRDWINN",
    stop_name: "AIRWAYS BLVD@DWIGHT",
    stop_desc: "AIRWAYS BLVD & DWIGHT RD",
    stop_lat: 35.084746,
    stop_lon: -89.986592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5323,
    stop_code: "AIRMEMNN",
    stop_name: "AIRWAYS BLVD@MEMPHIS DEPOT",
    stop_desc: "AIRWAYS BLVD & PECAN CIR",
    stop_lat: 35.086373,
    stop_lon: -89.987652,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5324,
    stop_code: "AIRDUNNM",
    stop_name: "AIRWAYS BLVD@DUNN",
    stop_desc: "AIRWAYS BLVD & DUNN AVE",
    stop_lat: 35.090225,
    stop_lon: -89.987582,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5325,
    stop_code: "AIRDUNNN",
    stop_name: "AIRWAYS BLVD@DUNN",
    stop_desc: "AIRWAYS BLVD & DUNN AVE",
    stop_lat: 35.091476,
    stop_lon: -89.987536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5326,
    stop_code: "AIRDUNNF",
    stop_name: "AIRWAYS BLVD@DUNN",
    stop_desc: "1954 AIRWAYS BLVD & DUNN AVE",
    stop_lat: 35.091923,
    stop_lon: -89.987525,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5327,
    stop_code: "AIRBOYNN",
    stop_name: "AIRWAYS BLVD@BOYLE",
    stop_desc: "AIRWAYS BLVD & BOYLE AVE",
    stop_lat: 35.098215,
    stop_lon: -89.987308,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5328,
    stop_code: "AIRLOWNN",
    stop_name: "AIRWAYS BLVD@LOWELL",
    stop_desc: "AIRWAYS BLVD & LOWELL AVE",
    stop_lat: 35.100029,
    stop_lon: -89.987151,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5329,
    stop_code: "AIRARLNN",
    stop_name: "AIRWAYS BLVD@ARLINGTON",
    stop_desc: "AIRWAYS BLVD & ARLINGTON AVE",
    stop_lat: 35.101941,
    stop_lon: -89.986662,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5330,
    stop_code: "AIRMALNN",
    stop_name: "AIRWAYS BLVD@MALONE",
    stop_desc: "AIRWAYS BLVD & ARLINGTON AVE",
    stop_lat: 35.102906,
    stop_lon: -89.986363,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5331,
    stop_code: "AIRRILNN",
    stop_name: "AIRWAYS BLVD@RILEY AVE",
    stop_desc: "",
    stop_lat: 35.103934,
    stop_lon: -89.986063,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5333,
    stop_code: "MILWINSF",
    stop_name: "MILLBRANCH@WINCHESTER",
    stop_desc: "MILLBRANCH RD & BRANCHWAY DR",
    stop_lat: 35.053094,
    stop_lon: -90.007521,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5334,
    stop_code: "MILSINSN",
    stop_name: "MILLBRANCH@SINGING TREES",
    stop_desc: "MILLBRANCH RD & SINGING TREES DR",
    stop_lat: 35.050654,
    stop_lon: -90.007534,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5335,
    stop_code: "MILVICSN",
    stop_name: "MILLBRANCH@VICTORIA RD",
    stop_desc: "MILLBRANCH RD & VICTORIA RD",
    stop_lat: 35.048491,
    stop_lon: -90.007536,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5336,
    stop_code: "MILOLDSF",
    stop_name: "MILLBRANCH@OLD HICKORY",
    stop_desc: "MILLBRANCH RD & MILL BRANCH RD",
    stop_lat: 35.044657,
    stop_lon: -90.007605,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5337,
    stop_code: "MILTIMSF",
    stop_name: "MILLBRANCH@TIMOTHY",
    stop_desc: "MILLBRANCH RD & TIMOTHY DR",
    stop_lat: 35.042789,
    stop_lon: -90.007573,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5338,
    stop_code: "MILDAVSF",
    stop_name: "MILLBRANCH@DAVID",
    stop_desc: "MILLBRANCH RD & DAVID DR",
    stop_lat: 35.040286,
    stop_lon: -90.007575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5339,
    stop_code: "MILRAISN",
    stop_name: "MILLBRANCH@RAINES",
    stop_desc: "MILLBRANCH RD & E RAINES RD",
    stop_lat: 35.035755,
    stop_lon: -90.00759,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5340,
    stop_code: "MILRAISF",
    stop_name: "MILLBRANCH@RAINES",
    stop_desc: "MILLBRANCH RD & E RAINES RD",
    stop_lat: 35.034843,
    stop_lon: -90.007591,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5341,
    stop_code: "MILWHISN",
    stop_name: "MILLBRANCH@WHITMAN",
    stop_desc: "MILLBRANCH RD & WHITMAN RD",
    stop_lat: 35.031831,
    stop_lon: -90.007571,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5342,
    stop_code: "MILFINSN",
    stop_name: "MILLBRANCH@FINLEY",
    stop_desc: "MILLBRANCH RD & FINLEY RD",
    stop_lat: 35.029946,
    stop_lon: -90.007584,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5343,
    stop_code: "MILOAKSN",
    stop_name: "MILLBRANCH@OAKWOOD",
    stop_desc: "MILLBRANCH RD & OAKWOOD DR",
    stop_lat: 35.028015,
    stop_lon: -90.007585,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5344,
    stop_code: "MILRIVSM",
    stop_name: "MILLBRANCH@RIVERBROOK",
    stop_desc: "",
    stop_lat: 35.024984,
    stop_lon: -90.007618,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5345,
    stop_code: "MILTURSN",
    stop_name: "MILLBRANCH@TURTLE CREEK",
    stop_desc: "MILLBRANCH RD & TURTLE CREEK CT",
    stop_lat: 35.023108,
    stop_lon: -90.0076,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5346,
    stop_code: "MILSHESN",
    stop_name: "MILLBRANCH@SHELBY DR",
    stop_desc: "MILLBRANCH RD & E SHELBY DR",
    stop_lat: 35.021365,
    stop_lon: -90.007602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5347,
    stop_code: "SHEBOEEN",
    stop_name: "SHELBY DRIVE@BOEINGSHIRE",
    stop_desc: "STATE HWY 175 & BOEINGSHIRE DR",
    stop_lat: 35.020948,
    stop_lon: -89.997248,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5348,
    stop_code: "SHEAIREN",
    stop_name: "SHELBY DRIVE@AIRWAYS",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & E SHELBY DR",
    stop_lat: 35.020971,
    stop_lon: -89.990993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5349,
    stop_code: "AIRSHESF",
    stop_name: "AIRWAYS@SHELBY DR",
    stop_desc: "AIRWAYS BLVD & VICTORIA RD",
    stop_lat: 35.020372,
    stop_lon: -89.989944,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5350,
    stop_code: "AIRJAMSN",
    stop_name: "AIRWAYS@JAMIE",
    stop_desc: "AIRWAYS BLVD & JAMIE DR",
    stop_lat: 35.015885,
    stop_lon: -89.989982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5351,
    stop_code: "AIRWILSN",
    stop_name: "AIRWAYS@WILSON",
    stop_desc: "AIRWAYS BLVD & WILSON RD",
    stop_lat: 35.013874,
    stop_lon: -89.989973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5352,
    stop_code: "AIRMCKSF",
    stop_name: "AIRWAYS@MCKELLAR WOODS",
    stop_desc: "AIRWAYS BLVD & MCKELLAR WOODS CT S",
    stop_lat: 35.010477,
    stop_lon: -89.989999,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5354,
    stop_code: "HOLHORWN",
    stop_name: "HOLMES RD@HORNSBY",
    stop_desc: "E HOLMES RD & HORNSBY DR",
    stop_lat: 35.006538,
    stop_lon: -89.993649,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5364,
    stop_code: "HOLHUDWN",
    stop_name: "HOLMES RD@HUDGINS RD",
    stop_desc: "E HOLMES RD & QUEEN ELIZABETH FWY",
    stop_lat: 35.006488,
    stop_lon: -89.999416,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5365,
    stop_code: "HOLSANWN",
    stop_name: "HOLMES@SANTA MONICA",
    stop_desc: "E HOLMES RD & SANTA MONICA ST",
    stop_lat: 35.006474,
    stop_lon: -90.005327,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5366,
    stop_code: "HOLMILWM",
    stop_name: "HOLMES@MILL BRANCH",
    stop_desc: "",
    stop_lat: 35.006502,
    stop_lon: -90.006022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5367,
    stop_code: "MILRIVNF",
    stop_name: "MILLBRANCH@RIVERBROOK",
    stop_desc: "MILLBRANCH RD & RIVERBROOK DR",
    stop_lat: 35.025092,
    stop_lon: -90.0074,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5368,
    stop_code: "MILJANNN",
    stop_name: "MILLBRANCH@JANIS",
    stop_desc: "MILL BRANCH RD & JANIS DR",
    stop_lat: 35.028551,
    stop_lon: -90.007375,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5369,
    stop_code: "MILFINNF",
    stop_name: "MILLBRANCH@FINLEY RD",
    stop_desc: "MILLBRANCH RD & MILL BRANCH RD",
    stop_lat: 35.030339,
    stop_lon: -90.007384,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5370,
    stop_code: "MILWHINN",
    stop_name: "MILLBRANCH@WHITMAN",
    stop_desc: "MILLBRANCH RD & WHITMAN RD",
    stop_lat: 35.031349,
    stop_lon: -90.007384,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5371,
    stop_code: "MILPIPNM",
    stop_name: "MILLBRANCH@PIPING ROCK",
    stop_desc: "MILLBRANCH RD & MILL BRANCH RD",
    stop_lat: 35.033306,
    stop_lon: -90.007382,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5372,
    stop_code: "MILRAINN",
    stop_name: "MILLBRANCH@RAINES",
    stop_desc: "MILLBRANCH RD & E RAINES RD",
    stop_lat: 35.034825,
    stop_lon: -90.007392,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5373,
    stop_code: "MILRAINF",
    stop_name: "MILLBRANCH@RAINES RD",
    stop_desc: "MILLBRANCH RD & E RAINES RD",
    stop_lat: 35.035952,
    stop_lon: -90.007391,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5374,
    stop_code: "MILGRANF",
    stop_name: "MILLBRANCH@GRACELAND",
    stop_desc: "MILLBRANCH RD & GRACELAND CV",
    stop_lat: 35.041761,
    stop_lon: -90.007375,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5375,
    stop_code: "MILTIMNF",
    stop_name: "MILLBRANCH@TIMOTHY",
    stop_desc: "MILLBRANCH RD & TIMOTHY DR",
    stop_lat: 35.043307,
    stop_lon: -90.007385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5376,
    stop_code: "MILVICNN",
    stop_name: "MILLBRANCH@VICTORIA",
    stop_desc: "MILL BRANCH RD & MILLBRANCH RD",
    stop_lat: 35.048134,
    stop_lon: -90.007348,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5377,
    stop_code: "MILMILNN",
    stop_name: "MILLBRANCH@MILLWALK DR",
    stop_desc: "MILL BRANCH RD & MILLBRANCH RD",
    stop_lat: 35.049429,
    stop_lon: -90.007336,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5378,
    stop_code: "MILBRANN",
    stop_name: "MILLBRANCH@BRANCHWAY",
    stop_desc: "MILL BRANCH RD & BRANCHWAY DR",
    stop_lat: 35.051789,
    stop_lon: -90.007323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5379,
    stop_code: "MILWINNN",
    stop_name: "MILLBRANCH RD@WINCHESTER RD",
    stop_desc: "MILLBRANCH RD & MILL BRANCH RD",
    stop_lat: 35.053004,
    stop_lon: -90.007322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5388,
    stop_code: "SHEMILWF",
    stop_name: "SHELBY DR@MILL BRANCH",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.021134,
    stop_lon: -90.008574,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5389,
    stop_code: "SHEMICWM",
    stop_name: "SHELBY DR@MICKEY",
    stop_desc: "STATE HWY 175 & MICKEY DR",
    stop_lat: 35.021126,
    stop_lon: -90.011304,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5390,
    stop_code: "SHEMICWF",
    stop_name: "SHELBY DR@MICKEY",
    stop_desc: "STATE HWY 175 & MICKEY DR",
    stop_lat: 35.021118,
    stop_lon: -90.013006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5391,
    stop_code: "SHESOUWF",
    stop_name: "SHELBY DR@SOUTHMALL MANOR DR",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & SOUTHMALL MANOR DR",
    stop_lat: 35.02111,
    stop_lon: -90.014763,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5392,
    stop_code: "SHEWOOWN",
    stop_name: "SHELBY DR@WOODRIDGE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & WOODRIDGE DR",
    stop_lat: 35.021093,
    stop_lon: -90.016829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5393,
    stop_code: "SHEFARWN",
    stop_name: "SHELBY DR@FARONIA",
    stop_desc: "STATE HWY 175 & FARONIA RD",
    stop_lat: 35.021076,
    stop_lon: -90.019702,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5394,
    stop_code: "FARSHENF",
    stop_name: "FARONIA@SHELBY DR",
    stop_desc: "? FARONIA RD & E WHITEHAVEN CAPLEVILLE RD",
    stop_lat: 35.021407,
    stop_lon: -90.019757,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5395,
    stop_code: "FARFARNN",
    stop_name: "FARONIA@FARROW",
    stop_desc: "FARONIA RD & E SHELBY DR",
    stop_lat: 35.023087,
    stop_lon: -90.019745,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5396,
    stop_code: "FARMAYNN",
    stop_name: "FARONIA@MAYHILL",
    stop_desc: "FARONIA RD & MAYHILL DR",
    stop_lat: 35.025322,
    stop_lon: -90.019744,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5397,
    stop_code: "FAROAKNN",
    stop_name: "FARONIA@OAKWOOD DR",
    stop_desc: "FARONIA RD & MAYHILL DR",
    stop_lat: 35.026832,
    stop_lon: -90.019732,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5398,
    stop_code: "FARDAVNN",
    stop_name: "FARONIA@DAVIDSON",
    stop_desc: "FARONIA RD & DAVIDSON DR",
    stop_lat: 35.028289,
    stop_lon: -90.01972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5399,
    stop_code: "FARFINNF",
    stop_name: "FARONIA@FINLEY",
    stop_desc: "FARONIA RD & S FARONIA SQ",
    stop_lat: 35.030532,
    stop_lon: -90.019718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5400,
    stop_code: "FARWESNF",
    stop_name: "FARONIA@WESLEY",
    stop_desc: "FARONIA RD & WESLEY DR",
    stop_lat: 35.032534,
    stop_lon: -90.019661,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5401,
    stop_code: "FARRAINN",
    stop_name: "FARONIA@RAINES",
    stop_desc: "FARONIA RD & MARLIN RD",
    stop_lat: 35.034965,
    stop_lon: -90.01966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5402,
    stop_code: "RAICHAWN",
    stop_name: "RAINES@CHARLES",
    stop_desc: "E RAINES RD & CHARLES DR",
    stop_lat: 35.035574,
    stop_lon: -90.021008,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5403,
    stop_code: "RAIBARWF",
    stop_name: "RAINES@BARTON",
    stop_desc: "E RAINES RD & BARTON DR",
    stop_lat: 35.035566,
    stop_lon: -90.023108,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5404,
    stop_code: "RAIPACEN",
    stop_name: "RAINES@PACE",
    stop_desc: "",
    stop_lat: 35.035476,
    stop_lon: -90.023561,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5405,
    stop_code: "RAIBAREF",
    stop_name: "RAINES@BARTON",
    stop_desc: "E RAINES RD & BARTON DR",
    stop_lat: 35.035404,
    stop_lon: -90.021737,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5407,
    stop_code: "FARMARSM",
    stop_name: "FARONIA@MARLIN",
    stop_desc: "",
    stop_lat: 35.034662,
    stop_lon: -90.01987,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5408,
    stop_code: "FARSOUSF",
    stop_name: "FARONIA@SOUTH WIND",
    stop_desc: "",
    stop_lat: 35.033348,
    stop_lon: -90.019849,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5410,
    stop_code: "FARFINSN",
    stop_name: "FARONIA@FINLEY",
    stop_desc: "",
    stop_lat: 35.029782,
    stop_lon: -90.019951,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5411,
    stop_code: "FARDAVSF",
    stop_name: "FARONIA@DAVIDSON",
    stop_desc: "",
    stop_lat: 35.027994,
    stop_lon: -90.019908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5412,
    stop_code: "FAROAKSN",
    stop_name: "FARONIA@OAKWOOD",
    stop_desc: "",
    stop_lat: 35.026966,
    stop_lon: -90.019919,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5413,
    stop_code: "FARMAYSN",
    stop_name: "FARONIA@MAYHILL",
    stop_desc: "",
    stop_lat: 35.025447,
    stop_lon: -90.019953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5414,
    stop_code: "FARFARSN",
    stop_name: "FARONIA@FARROW",
    stop_desc: "",
    stop_lat: 35.023257,
    stop_lon: -90.019955,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5415,
    stop_code: "FARSHESN",
    stop_name: "FARONIA@SHELBY DR",
    stop_desc: "",
    stop_lat: 35.022113,
    stop_lon: -90.019933,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5416,
    stop_code: "SHEELVWM",
    stop_name: "SHELBY DR@ELVIS PRESLEY",
    stop_desc: "STATE HWY 175 & CONNECTING RD",
    stop_lat: 35.021068,
    stop_lon: -90.02168,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5417,
    stop_code: "SHEELVWN",
    stop_name: "SHELBY DR@ELVIS PRESLEY",
    stop_desc: "STATE HWY 175 & CONNECTING RD",
    stop_lat: 35.021069,
    stop_lon: -90.024288,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5418,
    stop_code: "SHEELVWF",
    stop_name: "SHELBY DR@ELVIS PRESLEY BLVD",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020998,
    stop_lon: -90.026377,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5419,
    stop_code: "SHELINWN",
    stop_name: "SHELBY DR@LINWOOD",
    stop_desc: "STATE HWY 175 & GLORIA RD",
    stop_lat: 35.020973,
    stop_lon: -90.029515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5420,
    stop_code: "SHEFAIWF",
    stop_name: "SHELBY DR@FAIRFIELD",
    stop_desc: "STATE HWY 175 & FAIRFIELD RD",
    stop_lat: 35.020974,
    stop_lon: -90.032952,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5421,
    stop_code: "SHEMCCWN",
    stop_name: "SHELBY DR@MCCORKLE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & E SHELBY DR",
    stop_lat: 35.021065,
    stop_lon: -90.0383,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5422,
    stop_code: "SHEMCCWF",
    stop_name: "SHELBY DR@MCCORKLE",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.021074,
    stop_lon: -90.039527,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5423,
    stop_code: "SHETULWN",
    stop_name: "SHELBY DR@TULANE",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020976,
    stop_lon: -90.042134,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5424,
    stop_code: "SHETULWF",
    stop_name: "SHELBY DR@TUILANE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & TULANE RD",
    stop_lat: 35.020941,
    stop_lon: -90.043704,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5425,
    stop_code: "SHEBERWN",
    stop_name: "SHELBY DR@BERTA",
    stop_desc: "STATE HWY 175 & BERTA RD",
    stop_lat: 35.020932,
    stop_lon: -90.045328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5426,
    stop_code: "SHEHODWN",
    stop_name: "SHELBY DR@HODGE",
    stop_desc: "STATE HWY 175 & HODGE RD",
    stop_lat: 35.020879,
    stop_lon: -90.048831,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5436,
    stop_code: "RAINEEEF",
    stop_name: "RAINES@NEELY",
    stop_desc: "E RAINES RD & NEELY RD",
    stop_lat: 35.034573,
    stop_lon: -90.055492,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5437,
    stop_code: "RAIWINEN",
    stop_name: "RAINES@WINDWARD",
    stop_desc: "E RAINES RD & WINDWARD DR",
    stop_lat: 35.034564,
    stop_lon: -90.051955,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5438,
    stop_code: "RAIHODEN",
    stop_name: "RAINES@HODGE",
    stop_desc: "",
    stop_lat: 35.034615,
    stop_lon: -90.050136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5439,
    stop_code: "HODDRESN",
    stop_name: "HODGE@DREGER",
    stop_desc: "HODGE RD & DREGER RD",
    stop_lat: 35.032972,
    stop_lon: -90.049822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5440,
    stop_code: "HODSHOSN",
    stop_name: "HODGE@SHOFNER",
    stop_desc: "",
    stop_lat: 35.032093,
    stop_lon: -90.04974,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5441,
    stop_code: "HODPARSN",
    stop_name: "HODGE@PARKDALE",
    stop_desc: "HODGE RD & PARKDALE DR",
    stop_lat: 35.030952,
    stop_lon: -90.049635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5442,
    stop_code: "HODBYRSN",
    stop_name: "HODGE@BYRON",
    stop_desc: "HODGE RD & BYRON DR",
    stop_lat: 35.028736,
    stop_lon: -90.049636,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5443,
    stop_code: "HODWHISN",
    stop_name: "HODGE@WHITEVILLE",
    stop_desc: "HODGE RD & BYRON DR",
    stop_lat: 35.02693,
    stop_lon: -90.048995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5444,
    stop_code: "HODSTOSN",
    stop_name: "HODGE@STONEHAM",
    stop_desc: "HODGE RD & STONEHAM RD",
    stop_lat: 35.024812,
    stop_lon: -90.048841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5445,
    stop_code: "HODBERSF",
    stop_name: "HODGE@BERTA RD",
    stop_desc: "HODGE RD & BERTA RD",
    stop_lat: 35.023471,
    stop_lon: -90.048841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5446,
    stop_code: "HODSHESN",
    stop_name: "HODGE@SHELBY DR",
    stop_desc: "HODGE RD & BERTA RD",
    stop_lat: 35.021344,
    stop_lon: -90.049627,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5447,
    stop_code: "HODWINNN",
    stop_name: "HODGE@WINDWARD",
    stop_desc: "HODGE RD & WINDWARD CV",
    stop_lat: 35.02197,
    stop_lon: -90.049229,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5448,
    stop_code: "HODBERNN",
    stop_name: "HODGE@BERTA",
    stop_desc: "HODGE RD & WINDWARD CV",
    stop_lat: 35.023239,
    stop_lon: -90.048676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5449,
    stop_code: "HODSTONF",
    stop_name: "HODGE@STONEHAM",
    stop_desc: "",
    stop_lat: 35.02484,
    stop_lon: -90.0487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5450,
    stop_code: "HODWHINF",
    stop_name: "HODGE@WHITESBORO",
    stop_desc: "HODGE RD & WHITESBORO AVE",
    stop_lat: 35.026269,
    stop_lon: -90.048609,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5451,
    stop_code: "HODBYRNN",
    stop_name: "HODGE@BYRON",
    stop_desc: "HODGE RD & BYRON DR",
    stop_lat: 35.028503,
    stop_lon: -90.049448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5452,
    stop_code: "HODPARNN",
    stop_name: "HODGE@PARKDALE",
    stop_desc: "HODGE RD & BYRON DR",
    stop_lat: 35.030577,
    stop_lon: -90.049414,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5453,
    stop_code: "HODSHONN",
    stop_name: "HODGE@SHOFNER",
    stop_desc: "HODGE RD & PARKDALE DR",
    stop_lat: 35.031739,
    stop_lon: -90.049469,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5454,
    stop_code: "HODDRENN",
    stop_name: "HODGE@DREGER",
    stop_desc: "HODGE RD & DREGER RD",
    stop_lat: 35.03274,
    stop_lon: -90.049624,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5455,
    stop_code: "HODCLONN",
    stop_name: "HODGE@CLOWER",
    stop_desc: "",
    stop_lat: 35.033609,
    stop_lon: -90.049669,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5465,
    stop_code: "RAIHODWF",
    stop_name: "RAINES@HODGE",
    stop_desc: "E RAINES RD & HODGE RD",
    stop_lat: 35.034733,
    stop_lon: -90.050452,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5466,
    stop_code: "RAINEEWM",
    stop_name: "RAINES@NEELY",
    stop_desc: "",
    stop_lat: 35.034753,
    stop_lon: -90.053804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5479,
    stop_code: "NEEJEANN",
    stop_name: "NEELY@JEANNE",
    stop_desc: "NEELY RD & JEANNE DR",
    stop_lat: 35.037871,
    stop_lon: -90.05611,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5480,
    stop_code: "NEEIVANN",
    stop_name: "NEELY@IVAN",
    stop_desc: "NEELY RD & IVAN RD",
    stop_lat: 35.039006,
    stop_lon: -90.056099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5481,
    stop_code: "NEEWESNF",
    stop_name: "NEELY@WESLEY OAKS",
    stop_desc: "NEELY RD & WESLEY OAKS DR",
    stop_lat: 35.04158,
    stop_lon: -90.056098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5482,
    stop_code: "NEELEVNF",
    stop_name: "NEELY@LEVI",
    stop_desc: "NEELY RD & E LEVI RD",
    stop_lat: 35.043001,
    stop_lon: -90.056098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5483,
    stop_code: "NEEFAINN",
    stop_name: "NEELY@FAIRWAY",
    stop_desc: "NEELY RD & E FAIRWAY AVE",
    stop_lat: 35.044494,
    stop_lon: -90.056109,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5484,
    stop_code: "NEENEENN",
    stop_name: "NEELY@NEELY CV",
    stop_desc: "NEELY RD & NEELY CV",
    stop_lat: 35.046335,
    stop_lon: -90.056109,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5485,
    stop_code: "NEEHILNN",
    stop_name: "NEELY@HILLVIEW",
    stop_desc: "NEELY RD & HILLVIEW AVE",
    stop_lat: 35.04747,
    stop_lon: -90.056108,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5486,
    stop_code: "NEESOUNF",
    stop_name: "NEELY@SOUTHHILL",
    stop_desc: "NEELY RD & SOUTHVIEW AVE",
    stop_lat: 35.050795,
    stop_lon: -90.056119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5487,
    stop_code: "NEESOUNN",
    stop_name: "NEELY@SOUTHVIEW",
    stop_desc: "NEELY RD & SOUTHHILL AVE",
    stop_lat: 35.051706,
    stop_lon: -90.056119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5488,
    stop_code: "NEEMITNM",
    stop_name: "NEELY@MITCHELL RD",
    stop_desc: "NEELY RD & SOUTHVIEW AVE",
    stop_lat: 35.053154,
    stop_lon: -90.056119,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5489,
    stop_code: "NEEMITNN",
    stop_name: "NEELY@MITCHELL RD",
    stop_desc: "NEELY RD & RAMP",
    stop_lat: 35.05445,
    stop_lon: -90.056129,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5499,
    stop_code: "NEEMITSF",
    stop_name: "NEELY RD@MITCHELL RD",
    stop_desc: "NEELY RD & RAMP",
    stop_lat: 35.054477,
    stop_lon: -90.056328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5500,
    stop_code: "NEESOUSF",
    stop_name: "NEELY RD@SOUTHVIEW",
    stop_desc: "NEELY RD & SOUTHHILL AVE",
    stop_lat: 35.051555,
    stop_lon: -90.056318,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5501,
    stop_code: "NEESOUSN",
    stop_name: "NEELY RD@SOUTHHILL",
    stop_desc: "NEELY RD & SOUTHVIEW AVE",
    stop_lat: 35.050911,
    stop_lon: -90.056318,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5502,
    stop_code: "NEEHILSN",
    stop_name: "NEELY RD@HILLVIEW",
    stop_desc: "NEELY RD & HILLVIEW AVE",
    stop_lat: 35.04781,
    stop_lon: -90.056318,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5503,
    stop_code: "NEEFAISN",
    stop_name: "NEELY RD@FAIRWAY",
    stop_desc: "NEELY RD & E FAIRWAY AVE",
    stop_lat: 35.044816,
    stop_lon: -90.056319,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5504,
    stop_code: "NEEWESSN",
    stop_name: "NEELY RD@WESLEY OAKS CR",
    stop_desc: "NEELY RD & WESLEY OAKS DR",
    stop_lat: 35.041839,
    stop_lon: -90.056297,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5505,
    stop_code: "NEEIVASN",
    stop_name: "NEELY RD@IVAN",
    stop_desc: "NEELY RD & IVAN RD",
    stop_lat: 35.039265,
    stop_lon: -90.056298,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5518,
    stop_code: "SHEHODEF",
    stop_name: "SHELBY DR@HODGE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & HODGE RD",
    stop_lat: 35.020719,
    stop_lon: -90.048732,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5519,
    stop_code: "SHEBEREN",
    stop_name: "SHELBY DR@BERTA",
    stop_desc: "STATE HWY 175 & BERTA RD",
    stop_lat: 35.020772,
    stop_lon: -90.045637,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5520,
    stop_code: "SHETULEN",
    stop_name: "SHELBY DR@TULANE",
    stop_desc: "STATE HWY 175 & TULANE RD",
    stop_lat: 35.02078,
    stop_lon: -90.043085,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5558,
    stop_code: "SHEGILEN",
    stop_name: "SHELBY DR@GILBERT",
    stop_desc: "STATE HWY 175 & GILBERT RD",
    stop_lat: 35.020859,
    stop_lon: -90.037725,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5559,
    stop_code: "SHEAUBEN",
    stop_name: "SHELBY DR@AUBURN RD",
    stop_desc: "STATE HWY 175 & AUBURN RD",
    stop_lat: 35.020814,
    stop_lon: -90.035228,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5560,
    stop_code: "SHEFA1EN",
    stop_name: "SHELBY DR@FAIRFIELD",
    stop_desc: "STATE HWY 175 & FAIRFIELD RD",
    stop_lat: 35.020813,
    stop_lon: -90.032996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5561,
    stop_code: "SHELINEM",
    stop_name: "SHELBY DR@LINWOOD",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & LINWOOD RD",
    stop_lat: 35.020803,
    stop_lon: -90.030753,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5562,
    stop_code: "SHEGLOEF",
    stop_name: "SHELBY DR@GLORIA",
    stop_desc: "STATE HWY 175 & GLORIA RD",
    stop_lat: 35.020812,
    stop_lon: -90.028664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5563,
    stop_code: "SHEELVEN",
    stop_name: "SHELBY DR@ELVIS PRESLEY",
    stop_desc: "",
    stop_lat: 35.020836,
    stop_lon: -90.025508,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5564,
    stop_code: "SHEELVEF",
    stop_name: "SHELBY DR@ELVIS PRESLEY BLVD",
    stop_desc: "1185 STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020792,
    stop_lon: -90.024575,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5565,
    stop_code: "SHEFAREM",
    stop_name: "SHELBY DR@FARONIA",
    stop_desc: "? STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020764,
    stop_lon: -90.022178,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5566,
    stop_code: "SHEFAREF",
    stop_name: "SHELBY DR @ FARONIA",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & FARONIA RD",
    stop_lat: 35.020763,
    stop_lon: -90.018973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5567,
    stop_code: "SHEWOOEF",
    stop_name: "SHELBY DR@WOODRIDGE",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & WOODRIDGE DR",
    stop_lat: 35.02078,
    stop_lon: -90.017293,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5568,
    stop_code: "SHEMILEM",
    stop_name: "SHELBY DR@MILLBRANCH",
    stop_desc: "STATE HWY 175 & E SHELBY DR",
    stop_lat: 35.020797,
    stop_lon: -90.014962,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5569,
    stop_code: "SHEMICEF",
    stop_name: "SHELBY DR@MICKEY DR",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & MICKEY DR",
    stop_lat: 35.020813,
    stop_lon: -90.010486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5570,
    stop_code: "SHEMI2EM",
    stop_name: "SHELBY @MILLBRANCH RD",
    stop_desc: "E WHITEHAVEN CAPLEVILLE RD & E SHELBY DR",
    stop_lat: 35.020839,
    stop_lon: -90.008685,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5585,
    stop_code: "FIELIGEN",
    stop_name: "FIELDS@LIGHTSFOOT",
    stop_desc: "FIELDS RD & LIGHTSFOOT LN",
    stop_lat: 35.049509,
    stop_lon: -90.114774,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5586,
    stop_code: "FIECOOEN",
    stop_name: "FIELDS@COOK",
    stop_desc: "FIELDS RD & COOK RD",
    stop_lat: 35.049537,
    stop_lon: -90.110485,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5587,
    stop_code: "FIEOUTEF",
    stop_name: "FIELDS@OUTLET RD",
    stop_desc: "FIELDS RD & Y M V RD",
    stop_lat: 35.04953,
    stop_lon: -90.105334,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5588,
    stop_code: "FIEOUTWN",
    stop_name: "FIELDS@OUTLET RD",
    stop_desc: "FIELDS RD & OUTLET RD",
    stop_lat: 35.049691,
    stop_lon: -90.10529,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5589,
    stop_code: "FIEOSCWN",
    stop_name: "FIELDS@OSCEOLA",
    stop_desc: "FIELDS RD & OSCEOLA RD",
    stop_lat: 35.04969,
    stop_lon: -90.107191,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5590,
    stop_code: "FIECOOWN",
    stop_name: "FIELDS@COOK",
    stop_desc: "FIELDS RD & OSCEOLA RD",
    stop_lat: 35.049698,
    stop_lon: -90.109568,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5591,
    stop_code: "SEWBOXWF",
    stop_name: "SEWANEE@BOXTOWN",
    stop_desc: "SEWANEE RD & BEACON RD",
    stop_lat: 35.04282,
    stop_lon: -90.124177,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5593,
    stop_code: "SEWRAISM",
    stop_name: "SEWANEE RD@RAINES RD",
    stop_desc: "",
    stop_lat: 35.038011,
    stop_lon: -90.124107,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5609,
    stop_code: "WEAPARNF",
    stop_name: "WEAVER RD@PARKROSE",
    stop_desc: "WEAVER RD & PARKROSE AVE",
    stop_lat: 35.024534,
    stop_lon: -90.09647,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5610,
    stop_code: "WEAWEANN",
    stop_name: "WEAVER@WEAVER CV",
    stop_desc: "WEAVER RD & WESTERN PARK DR",
    stop_lat: 35.028475,
    stop_lon: -90.098262,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5611,
    stop_code: "WEAWESNN",
    stop_name: "WEAVER@WESTERN PARK",
    stop_desc: "WEAVER RD & WESTERN PARK DR",
    stop_lat: 35.030155,
    stop_lon: -90.098959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5612,
    stop_code: "WEALEANF",
    stop_name: "WEAVER@LEACREST",
    stop_desc: "WEAVER RD & LEACREST AVE",
    stop_lat: 35.034213,
    stop_lon: -90.099381,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5613,
    stop_code: "WEARAINN",
    stop_name: "WEAVER RD@RAINES",
    stop_desc: "WEAVER RD & W RAINES RD",
    stop_lat: 35.035375,
    stop_lon: -90.098972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5614,
    stop_code: "DOURAISF",
    stop_name: "DOUBLE TREE RD@RAINES RD",
    stop_desc: "0 DOUBLE TREE RD & W RAINES RD",
    stop_lat: 35.035364,
    stop_lon: -90.1042,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5615,
    stop_code: "DOUENCSN",
    stop_name: "DOUBLE TREE@ENCANTO",
    stop_desc: "0 DOUBLE TREE RD & ENCANTO RD",
    stop_lat: 35.032737,
    stop_lon: -90.104022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5616,
    stop_code: "DOUWESSM",
    stop_name: "DOUBLE TREE@WESTERN PARK",
    stop_desc: "0 DOUBLE TREE RD & WESTERN PARK DR",
    stop_lat: 35.030592,
    stop_lon: -90.104021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5617,
    stop_code: "DOUSHASF",
    stop_name: "DOUBLE TREE@SHADOWLINE",
    stop_desc: "0 DOUBLE TREE RD & SHADOWLINE DR",
    stop_lat: 35.029001,
    stop_lon: -90.103976,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5618,
    stop_code: "DOUDOUSN",
    stop_name: "DOUBLE TREE@DOUBLE TREE CV",
    stop_desc: "0 DOUBLE TREE RD & SHADOWLINE DR",
    stop_lat: 35.026498,
    stop_lon: -90.103798,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5656,
    stop_code: "RAIWEAEN",
    stop_name: "RAINES@WEAVER",
    stop_desc: "",
    stop_lat: 35.035947,
    stop_lon: -90.099271,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5670,
    stop_code: "PERJUDNM",
    stop_name: "PERKINS @ S JUDY LYNN",
    stop_desc: "",
    stop_lat: 35.070559,
    stop_lon: -89.905373,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5671,
    stop_code: "PERJUDNF",
    stop_name: "PERKINS@ N JUDY LYNN",
    stop_desc: "",
    stop_lat: 35.072233,
    stop_lon: -89.905354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5676,
    stop_code: "LAMDEMWF",
    stop_name: "LAMAR AVE@DEMOCRAT",
    stop_desc: "US HWY 78 & EASTWAY BLVD",
    stop_lat: 35.069587,
    stop_lon: -89.944912,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5677,
    stop_code: "LAMPEAWN",
    stop_name: "LAMAR AVE@PEARSON RD",
    stop_desc: "US HWY 78 & PEARSON RD",
    stop_lat: 35.072297,
    stop_lon: -89.946942,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5678,
    stop_code: "LAMAMEWM",
    stop_name: "LAMAR AVE@AMERICAN WAY",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.07414,
    stop_lon: -89.948376,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5680,
    stop_code: "LAMJOHWN",
    stop_name: "LAMAR AVE@JOHN PAUL",
    stop_desc: "",
    stop_lat: 35.082864,
    stop_lon: -89.95867,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5682,
    stop_code: "LAMSHUWM",
    stop_name: "LAMAR AVE@SHULTS",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.089233,
    stop_lon: -89.96502,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5683,
    stop_code: "LAMFILWN",
    stop_name: "LAMAR AVE@FILMORE",
    stop_desc: "US HWY 78 & FILMORE AVE",
    stop_lat: 35.092176,
    stop_lon: -89.967461,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5684,
    stop_code: "LAMKIMWM",
    stop_name: "LAMAR AVE@KIMBALL",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.093607,
    stop_lon: -89.968764,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5685,
    stop_code: "LAMKIMWN",
    stop_name: "LAMAR AVE@KIMBALL",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.094465,
    stop_lon: -89.969526,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5686,
    stop_code: "LAMLOWWF",
    stop_name: "LAMAR AVE@LOWELL",
    stop_desc: "US HWY 78 & LOWELL AVE",
    stop_lat: 35.096379,
    stop_lon: -89.971227,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5687,
    stop_code: "LAMSHEWN",
    stop_name: "LAMAR AVE@SHELLEY",
    stop_desc: "US HWY 78 & SHELLEY ST",
    stop_lat: 35.097596,
    stop_lon: -89.972376,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5688,
    stop_code: "LAMFIZWN",
    stop_name: "LAMAR AVE@FIZER",
    stop_desc: "US HWY 78 & FIZER AVE",
    stop_lat: 35.099439,
    stop_lon: -89.975338,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5689,
    stop_code: "LAMHUGWN",
    stop_name: "LAMAR AVE@HUGENOT",
    stop_desc: "US HWY 78 & HUGENOT ST",
    stop_lat: 35.100325,
    stop_lon: -89.977029,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5690,
    stop_code: "LAMKEAWN",
    stop_name: "LAMAR AVE@KEATING",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.101847,
    stop_lon: -89.979915,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5691,
    stop_code: "LAMLAMWN",
    stop_name: "LAMAR AVE@LAMAR CIR",
    stop_desc: "STATE HWY 4 & LAMAR CIR",
    stop_lat: 35.103538,
    stop_lon: -89.982711,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5692,
    stop_code: "LAMAIRWN",
    stop_name: "LAMAR AVE@AIRWAYS",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.105309,
    stop_lon: -89.985464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5693,
    stop_code: "LAMKERNF",
    stop_name: "LAMAR@KERR AVE",
    stop_desc: "? STATE HWY 4 & KERR AVE",
    stop_lat: 35.107188,
    stop_lon: -89.987354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5694,
    stop_code: "LAMPARWN",
    stop_name: "LAMAR AVE@PARK",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.108475,
    stop_lon: -89.98847,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5696,
    stop_code: "LAMTANWN",
    stop_name: "LAMAR AVE@TANGLEWOOD",
    stop_desc: "US HWY 78 & TANGLEWOOD ST",
    stop_lat: 35.111454,
    stop_lon: -89.992991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5697,
    stop_code: "LAMCASWF",
    stop_name: "LAMAR AVE@CASTALIA",
    stop_desc: "STATE HWY 4 & WORTHINGTON ST",
    stop_lat: 35.112841,
    stop_lon: -89.995103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5698,
    stop_code: "LAMREMWN",
    stop_name: "LAMAR AVE@REMBERT ST",
    stop_desc: "US HWY 78 & S REMBERT ST",
    stop_lat: 35.113557,
    stop_lon: -89.996187,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5699,
    stop_code: "LAMBARWN",
    stop_name: "LAMAR AVE@BARKSDALE",
    stop_desc: "STATE HWY 4 & S BARKSDALE ST",
    stop_lat: 35.115194,
    stop_lon: -89.998697,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5700,
    stop_code: "LAMSMCWF",
    stop_name: "LAMAR AVE@SMCLEAN",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.117457,
    stop_lon: -90.002191,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5701,
    stop_code: "LAMSEAWF",
    stop_name: "LAMAR AVE@SEATTLE",
    stop_desc: "US HWY 78 & STATE HWY 4",
    stop_lat: 35.118029,
    stop_lon: -90.003098,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5702,
    stop_code: "LAMFELWN",
    stop_name: "LAMAR AVE@FELIX",
    stop_desc: "US HWY 78 & FELIX AVE",
    stop_lat: 35.119031,
    stop_lon: -90.004657,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5703,
    stop_code: "LAMVESWN",
    stop_name: "LAMAR AVE@VESEY",
    stop_desc: "STATE HWY 4 & ROZELLE ST",
    stop_lat: 35.120042,
    stop_lon: -90.006238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5704,
    stop_code: "LAMEUCWN",
    stop_name: "LAMAR AVE@EUCLID",
    stop_desc: "US HWY 78 & EUCLID AVE",
    stop_lat: 35.121115,
    stop_lon: -90.00793,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5705,
    stop_code: "LAMWILWN",
    stop_name: "LAMAR AVE@WILLETT",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.122358,
    stop_lon: -90.009866,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5706,
    stop_code: "LAMHEYWN",
    stop_name: "LAMAR AVE@HEYWOOD",
    stop_desc: "STATE HWY 4 & HEYWOOD ST",
    stop_lat: 35.124845,
    stop_lon: -90.014113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5707,
    stop_code: "LAMCENWN",
    stop_name: "LAMAR AVE@CENTRAL",
    stop_desc: "1372 US HWY 78 & LAMAR AVE",
    stop_lat: 35.12632,
    stop_lon: -90.016635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5708,
    stop_code: "LAMCLEWN",
    stop_name: "LAMAR AVE@CLEVELAND",
    stop_desc: "US HWY 78 & CENTRAL AVE",
    stop_lat: 35.127233,
    stop_lon: -90.018227,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5710,
    stop_code: "LAMBELWN",
    stop_name: "LAMAR@BELLEVUE",
    stop_desc: "LAMAR AVE & BELLEVUE",
    stop_lat: 35.12873,
    stop_lon: -90.020929,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5716,
    stop_code: "EASESTSN",
    stop_name: "EAST ST@ESTIVAL",
    stop_desc: "EAST ST & ESTIVAL PL",
    stop_lat: 35.132555,
    stop_lon: -90.031161,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5727,
    stop_code: "CRUEASEF",
    stop_name: "CRUMP@EAST",
    stop_desc: "US HWY 78 & E EH CRUMP BLVD",
    stop_lat: 35.131751,
    stop_lon: -90.030785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5728,
    stop_code: "CRUDUDEN",
    stop_name: "CRUMP@DUDLEY",
    stop_desc: "STATE HWY 4 & DUDLEY ST",
    stop_lat: 35.131714,
    stop_lon: -90.029302,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5729,
    stop_code: "CRUCAMEN",
    stop_name: "CRUMP@CAMILLA",
    stop_desc: "US HWY 78 & STATE HWY 4",
    stop_lat: 35.131678,
    stop_lon: -90.027387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5730,
    stop_code: "CRUSOMEF",
    stop_name: "CRUMP@SOMERVILLE",
    stop_desc: "0 78",
    stop_lat: 35.13132,
    stop_lon: -90.025717,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5731,
    stop_code: "LAMMINEN",
    stop_name: "LAMAR@MINNA PL",
    stop_desc: "? US HWY 78 & STATE HWY 4",
    stop_lat: 35.128896,
    stop_lon: -90.021624,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5732,
    stop_code: "LAMBELEF",
    stop_name: "LAMAR AVE@BELLEVUE BLVD",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.128288,
    stop_lon: -90.020528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5733,
    stop_code: "LAMNOEN",
    stop_name: "LAMAR@SNOWDEN",
    stop_desc: "US HWY 78 & STATE HWY 4",
    stop_lat: 35.127242,
    stop_lon: -90.018637,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5734,
    stop_code: "LAMSNOEF",
    stop_name: "LAMAR@SNOWDEN CIR",
    stop_desc: "US HWY 78 & E SNOWDEN CIR",
    stop_lat: 35.125999,
    stop_lon: -90.016436,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5735,
    stop_code: "LAMHEYEM",
    stop_name: "LAMAR@HEYWOOD",
    stop_desc: "STATE HWY 4 & E SNOWDEN CIR",
    stop_lat: 35.125355,
    stop_lon: -90.01533,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5736,
    stop_code: "LAMWILEN",
    stop_name: "LAMAR@WILLETT",
    stop_desc: "US HWY 78 & S WILLETT ST",
    stop_lat: 35.122537,
    stop_lon: -90.010474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5737,
    stop_code: "LAMROLEN",
    stop_name: "LAMAR@ROLAND",
    stop_desc: "US HWY 78 & ROLAND ST",
    stop_lat: 35.121401,
    stop_lon: -90.008727,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5738,
    stop_code: "LAMOAKEF",
    stop_name: "LAMAR@OAKVIEW",
    stop_desc: "US HWY 78 & OAKVIEW ST",
    stop_lat: 35.1204,
    stop_lon: -90.007134,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5739,
    stop_code: "LAMROZEN",
    stop_name: "LAMAR@ROZELLE",
    stop_desc: "US HWY 78 & ROZELLE ST",
    stop_lat: 35.120024,
    stop_lon: -90.006537,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5740,
    stop_code: "LAMFELEN",
    stop_name: "LAMAR@FELIX",
    stop_desc: "STATE HWY 4 & FELIX AVE",
    stop_lat: 35.11913,
    stop_lon: -90.005144,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5741,
    stop_code: "LAMSEAEN",
    stop_name: "LAMAR@SEATTLE",
    stop_desc: "STATE HWY 4 & SEATTLE ST",
    stop_lat: 35.117886,
    stop_lon: -90.003197,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5742,
    stop_code: "LAMMANEN",
    stop_name: "LAMAR@MANILLA",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.11726,
    stop_lon: -90.002202,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5743,
    stop_code: "LAMBAREF",
    stop_name: "LAMAR@BARKSDALE",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.115086,
    stop_lon: -89.998841,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5744,
    stop_code: "LAMLAPEN",
    stop_name: "LAMAR@LAPALOMA",
    stop_desc: "US HWY 78 & LAPALOMA ST",
    stop_lat: 35.113852,
    stop_lon: -89.996983,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5745,
    stop_code: "LAMCASEN",
    stop_name: "LAMAR@CASTALIA",
    stop_desc: "US HWY 78 & CASTALIA ST",
    stop_lat: 35.112206,
    stop_lon: -89.994495,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5746,
    stop_code: "LAMSPAEN",
    stop_name: "LAMAR AVE@S PARKWAY",
    stop_desc: "? US HWY 78 & S PKWY",
    stop_lat: 35.110801,
    stop_lon: -89.992328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5747,
    stop_code: "LAMPAREF",
    stop_name: "LAMAR@PARK",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.108136,
    stop_lon: -89.988359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5748,
    stop_code: "LAMKEREN",
    stop_name: "LAMAR@KERR",
    stop_desc: "US HWY 78 & UNNAMED ST",
    stop_lat: 35.10675,
    stop_lon: -89.987277,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5749,
    stop_code: "LAMAIREM",
    stop_name: "LAMAR@AIRWAYS",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.105945,
    stop_lon: -89.986592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5750,
    stop_code: "LAMTREEN",
    stop_name: "LAMAR@TREZEVANT",
    stop_desc: "US HWY 78 & S TREZEVANT ST",
    stop_lat: 35.104639,
    stop_lon: -89.984746,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5751,
    stop_code: "LAMLAMEF",
    stop_name: "LAMAR@LAMAR CIR",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.103386,
    stop_lon: -89.982811,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5752,
    stop_code: "LAMKEAEN",
    stop_name: "LAMAR@KEATING",
    stop_desc: "US HWY 78 & KEATING ST",
    stop_lat: 35.102089,
    stop_lon: -89.980722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5753,
    stop_code: "LAMHUGEF",
    stop_name: "LAMAR@HUGENOT",
    stop_desc: "US HWY 78 & HUGENOT ST",
    stop_lat: 35.100209,
    stop_lon: -89.977195,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5754,
    stop_code: "LAMLABEN",
    stop_name: "LAMAR@LABELLE",
    stop_desc: "STATE HWY 4 & LABELLE ST",
    stop_lat: 35.098884,
    stop_lon: -89.974664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5755,
    stop_code: "LAMSHEEN",
    stop_name: "LAMAR@SHELLEY",
    stop_desc: "STATE HWY 4 & SHELLEY ST",
    stop_lat: 35.097766,
    stop_lon: -89.97284,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5756,
    stop_code: "LAMLOWEN",
    stop_name: "LAMAR@LOWELL",
    stop_desc: "STATE HWY 4 & LOWELL AVE",
    stop_lat: 35.096192,
    stop_lon: -89.971316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5757,
    stop_code: "LAMPENEF",
    stop_name: "LAMAR@PENDLETON",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.094161,
    stop_lon: -89.969504,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5758,
    stop_code: "LAMFILEF",
    stop_name: "LAMAR@FILMORE",
    stop_desc: "STATE HWY 4 & FILMORE AVE",
    stop_lat: 35.092167,
    stop_lon: -89.967704,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5759,
    stop_code: "LAMSHUEN",
    stop_name: "LAMAR@SHULTS",
    stop_desc: "US HWY 78 & SHULTS RD",
    stop_lat: 35.090137,
    stop_lon: -89.965992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5760,
    stop_code: "LAMSOUEM",
    stop_name: "LAMAR@SOUTHWALL",
    stop_desc: "STATE HWY 4 & SOUTHWALL ST",
    stop_lat: 35.08901,
    stop_lon: -89.965087,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5761,
    stop_code: "LAMSOUEN",
    stop_name: "LAMAR@SOUTHWALL",
    stop_desc: "STATE HWY 4 & SOUTHWALL ST",
    stop_lat: 35.08808,
    stop_lon: -89.96438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5762,
    stop_code: "LAMSEMEM",
    stop_name: "LAMAR@SEMMES AVE",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.087069,
    stop_lon: -89.963552,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5763,
    stop_code: "LAMSEMEF",
    stop_name: "LAMAR@SEMMES AVE",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.085468,
    stop_lon: -89.961962,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5764,
    stop_code: "LAMRKSEN",
    stop_name: "LAMAR@RKS COMMERCIAL",
    stop_desc: "STATE HWY 4 & RKS COMMERCIAL CV",
    stop_lat: 35.08443,
    stop_lon: -89.960714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5765,
    stop_code: "LAMJOHEN",
    stop_name: "LAMAR@JOHN PAUL",
    stop_desc: "STATE HWY 4 & JOHN PAUL ST",
    stop_lat: 35.083499,
    stop_lon: -89.959609,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5766,
    stop_code: "LAMPEAEM",
    stop_name: "LAMAR@PEARSON",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.074838,
    stop_lon: -89.949315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5768,
    stop_code: "LAMPEANN",
    stop_name: "LAMAR@PEARSON RD",
    stop_desc: "US HWY 78 & LAMAR AVE",
    stop_lat: 35.073255,
    stop_lon: -89.947891,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5770,
    stop_code: "LAMDEMEM",
    stop_name: "LAMAR@DEMOCRAT",
    stop_desc: "STATE HWY 4 & LAMAR AVE",
    stop_lat: 35.069498,
    stop_lon: -89.945089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5772,
    stop_code: "LAMOAKEN",
    stop_name: "LAMAR@OAKWAY",
    stop_desc: "STATE HWY 4 & OAKWAY DR",
    stop_lat: 35.067781,
    stop_lon: -89.943776,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5778,
    stop_code: "BRAHORWF",
    stop_name: "BRANTLY@HORN LAKE RD",
    stop_desc: "BRANTLEY RD & W HORN LAKE RD",
    stop_lat: 35.053048,
    stop_lon: -90.069429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5779,
    stop_code: "WHOBRASF",
    stop_name: "W HORN LAKE RD@BRANTLEY",
    stop_desc: "W HORN LAKE RD & BRANTLEY RD",
    stop_lat: 35.05118,
    stop_lon: -90.070136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5780,
    stop_code: "VERWHOWF",
    stop_name: "VERNELLE@WHORN LAKE RD",
    stop_desc: "",
    stop_lat: 35.050577,
    stop_lon: -90.070464,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5782,
    stop_code: "GLEDAGWN",
    stop_name: "GLENCO@DAGGETT",
    stop_desc: "GLENCOE RD & DAGGETT RD",
    stop_lat: 35.050617,
    stop_lon: -90.073022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5783,
    stop_code: "GLEROCWN",
    stop_name: "GLENCO@ROCHESTER",
    stop_desc: "GLENCOE RD & ROCHESTER RD",
    stop_lat: 35.050626,
    stop_lon: -90.075796,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5784,
    stop_code: "ROCCARSN",
    stop_name: "ROCHESTER@CARBON RD",
    stop_desc: "ROCHESTER RD & CARBON RD",
    stop_lat: 35.053664,
    stop_lon: -90.075631,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5785,
    stop_code: "ROCCARNN",
    stop_name: "ROCHESTER@CARBON RD",
    stop_desc: "ROCHESTER RD & CARBON RD",
    stop_lat: 35.053244,
    stop_lon: -90.075321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5786,
    stop_code: "ROCMITNN",
    stop_name: "ROCHESTER@MITCHELL RD",
    stop_desc: "ROCHESTER RD & W MITCHELL RD",
    stop_lat: 35.055389,
    stop_lon: -90.075564,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5787,
    stop_code: "ROCJENNN",
    stop_name: "ROCHESTER@JENSON",
    stop_desc: "ROCHESTER RD & JENSON RD",
    stop_lat: 35.05723,
    stop_lon: -90.075509,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5788,
    stop_code: "ROCJENNF",
    stop_name: "ROCHESTER@JENSEN RD",
    stop_desc: "ROCHESTER RD & JENSON RD",
    stop_lat: 35.05858,
    stop_lon: -90.075465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5789,
    stop_code: "ROCKINNN",
    stop_name: "ROCHESTER@KING",
    stop_desc: "ROCHESTER RD & KING RD",
    stop_lat: 35.060019,
    stop_lon: -90.07541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5790,
    stop_code: "ROCHILNN",
    stop_name: "ROCHESTER@HILDA",
    stop_desc: "ROCHESTER RD & HILDA ST",
    stop_lat: 35.061574,
    stop_lon: -90.075078,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5791,
    stop_code: "ROCBRONN",
    stop_name: "ROCHESTER@BROOKS",
    stop_desc: "ROCHESTER RD & W BROOKS RD",
    stop_lat: 35.063165,
    stop_lon: -90.074128,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5794,
    stop_code: "PEEHOREN",
    stop_name: "PEEBLES@HORN LAKE RD",
    stop_desc: "W PEEBLES RD & HORN LAKE RD",
    stop_lat: 35.066597,
    stop_lon: -90.0707,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5795,
    stop_code: "PEENHOEM",
    stop_name: "PEEBLES@NEWHORNLAKE R",
    stop_desc: "W PEEBLES RD & HORN LAKE RD",
    stop_lat: 35.066579,
    stop_lon: -90.069617,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5796,
    stop_code: "PEENHOEN",
    stop_name: "PEEBLES@NEW HORN LAKE",
    stop_desc: "W PEEBLES RD & HORN LAKE RD",
    stop_lat: 35.066561,
    stop_lon: -90.068411,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5797,
    stop_code: "PEEREDEN",
    stop_name: "PEEBLES@REDBUD",
    stop_desc: "W PEEBLES RD & REDBUD RD",
    stop_lat: 35.066552,
    stop_lon: -90.067184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5798,
    stop_code: "PEELOTEN",
    stop_name: "PEEBLES@LOTUS",
    stop_desc: "W PEEBLES RD & LOTUS RD",
    stop_lat: 35.066508,
    stop_lon: -90.06568,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5799,
    stop_code: "PEEMEAEN",
    stop_name: "PEEBLES@MEADOWBROOK",
    stop_desc: "E PEEBLES RD & MEADOWBROOK RD",
    stop_lat: 35.066436,
    stop_lon: -90.062994,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5800,
    stop_code: "PEEBEVEN",
    stop_name: "PEEBLES@BEVEL",
    stop_desc: "E PEEBLES RD & BEVEL RD",
    stop_lat: 35.066409,
    stop_lon: -90.061003,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5801,
    stop_code: "PEEBEVEF",
    stop_name: "PEEBLES@BEVEL RD",
    stop_desc: "E PEEBLES RD & BEVEL RD",
    stop_lat: 35.066382,
    stop_lon: -90.060063,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5802,
    stop_code: "PEEBEVWF",
    stop_name: "PEEBLES@BEVEL",
    stop_desc: "E PEEBLES RD & BEVEL RD",
    stop_lat: 35.06657,
    stop_lon: -90.061291,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5803,
    stop_code: "PEEMEAWF",
    stop_name: "PEEBLES@MEADOWBROOK",
    stop_desc: "E PEEBLES RD & MEADOWBROOK RD",
    stop_lat: 35.066597,
    stop_lon: -90.063071,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5804,
    stop_code: "PEELOTWN",
    stop_name: "PEEBLES@LOTUS",
    stop_desc: "E PEEBLES RD & LOTUS RD",
    stop_lat: 35.066642,
    stop_lon: -90.064818,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5805,
    stop_code: "PEENHOWN",
    stop_name: "PEEBLES@NEW HORN LAKE R",
    stop_desc: "W PEEBLES RD & REDBUD RD",
    stop_lat: 35.066704,
    stop_lon: -90.066852,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5806,
    stop_code: "PEEOLDWN",
    stop_name: "PEEBLES@HORN LAKE RD",
    stop_desc: "W PEEBLES RD & HORN LAKE RD",
    stop_lat: 35.066749,
    stop_lon: -90.070192,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5807,
    stop_code: "PEEROCWN",
    stop_name: "PEEBLES@ROCHESTER",
    stop_desc: "W PEEBLES RD & ROCHESTER RD",
    stop_lat: 35.066829,
    stop_lon: -90.073221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5808,
    stop_code: "PEEROCWF",
    stop_name: "PEEBLES@ROCHESTER",
    stop_desc: "W PEEBLES RD & ROCHESTER RD",
    stop_lat: 35.066856,
    stop_lon: -90.074714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5809,
    stop_code: "PEEALTWM",
    stop_name: "PEEBLES@ALTA RD",
    stop_desc: "W PEEBLES RD & ALTA RD",
    stop_lat: 35.06691,
    stop_lon: -90.076472,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5810,
    stop_code: "PEEALTWN",
    stop_name: "PEEBLES ST@ALTA",
    stop_desc: "W PEEBLES RD & ALTA RD",
    stop_lat: 35.066945,
    stop_lon: -90.077832,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5811,
    stop_code: "PEEFORWF",
    stop_name: "PEEBLES@FORD",
    stop_desc: "W PEEBLES RD & FORD RD",
    stop_lat: 35.067034,
    stop_lon: -90.081005,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5812,
    stop_code: "PEEPARWF",
    stop_name: "PEEBLES@PARKER",
    stop_desc: "W PEEBLES RD & PARKER RD",
    stop_lat: 35.06707,
    stop_lon: -90.082465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5813,
    stop_code: "PEEGREWM",
    stop_name: "PEEBLES@GREEN",
    stop_desc: "W PEEBLES RD & GREEN RD",
    stop_lat: 35.067088,
    stop_lon: -90.084455,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5814,
    stop_code: "PEEGREWF",
    stop_name: "PEEBLES@GREEN",
    stop_desc: "W PEEBLES RD & GREEN RD",
    stop_lat: 35.067087,
    stop_lon: -90.08566,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5815,
    stop_code: "SAXPEESF",
    stop_name: "SAX@PEEBLES",
    stop_desc: "SAX RD & W PEEBLES RD",
    stop_lat: 35.06639,
    stop_lon: -90.086755,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5816,
    stop_code: "SAXFLYSN",
    stop_name: "SAX@FLYNN",
    stop_desc: "SAX RD & FLYNN RD",
    stop_lat: 35.065604,
    stop_lon: -90.086788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5817,
    stop_code: "SAXNONSN",
    stop_name: "SAX@NONCONNAH",
    stop_desc: "SAX RD & FLYNN RD",
    stop_lat: 35.063915,
    stop_lon: -90.086854,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5818,
    stop_code: "SAXHARSN",
    stop_name: "SAX@HARAHAN",
    stop_desc: "SAX RD & HARAHAN RD",
    stop_lat: 35.063039,
    stop_lon: -90.086887,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5819,
    stop_code: "SAXGILSN",
    stop_name: "SAX@GILLEAS",
    stop_desc: "SAX RD & GILLIS RD",
    stop_lat: 35.061904,
    stop_lon: -90.086909,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5820,
    stop_code: "SAXKINSN",
    stop_name: "SAX@KING",
    stop_desc: "SAX RD & GILLIS RD",
    stop_lat: 35.061037,
    stop_lon: -90.086942,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5821,
    stop_code: "SAXHAZSF",
    stop_name: "SAX@HAZELWOOD",
    stop_desc: "SAX RD & HAZELWOOD RD",
    stop_lat: 35.059294,
    stop_lon: -90.086952,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5822,
    stop_code: "SAXMITSN",
    stop_name: "SAX RD@MITCHELL RD",
    stop_desc: "SAX RD & W MITCHELL RD",
    stop_lat: 35.056175,
    stop_lon: -90.087029,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5823,
    stop_code: "WEAMITSF",
    stop_name: "WEAVER@MITCHELL",
    stop_desc: "WEAVER RD & W MITCHELL RD",
    stop_lat: 35.055878,
    stop_lon: -90.095674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5824,
    stop_code: "WEANORSN",
    stop_name: "WEAVER@NORA",
    stop_desc: "WEAVER RD & NORA RD",
    stop_lat: 35.05367,
    stop_lon: -90.096768,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5825,
    stop_code: "WEAEYESN",
    stop_name: "WEAVER@EYERS",
    stop_desc: "WEAVER RD & EYERS RD",
    stop_lat: 35.051597,
    stop_lon: -90.096734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5826,
    stop_code: "WEAFIESN",
    stop_name: "WEAVER RD@FIELDS RD",
    stop_desc: "",
    stop_lat: 35.0498,
    stop_lon: -90.096722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5827,
    stop_code: "WEACARSN",
    stop_name: "WEAVER@CAREY",
    stop_desc: "WEAVER RD & CAREY RD",
    stop_lat: 35.048496,
    stop_lon: -90.096722,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5828,
    stop_code: "WEACANSN",
    stop_name: "WEAVER@CANARY",
    stop_desc: "WEAVER RD & CANARY LN",
    stop_lat: 35.04711,
    stop_lon: -90.096688,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5833,
    stop_code: "WEARAINF",
    stop_name: "WEAVER@RAINES",
    stop_desc: "WEAVER RD & W RAINES RD",
    stop_lat: 35.036421,
    stop_lon: -90.098365,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5834,
    stop_code: "WEANORNN",
    stop_name: "WEAVER@NORFLEET",
    stop_desc: "",
    stop_lat: 35.037904,
    stop_lon: -90.097006,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5835,
    stop_code: "WEALEVNM",
    stop_name: "WEAVER@LEVI",
    stop_desc: "",
    stop_lat: 35.039924,
    stop_lon: -90.096531,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5836,
    stop_code: "WEALEVNN",
    stop_name: "WEAVER RD@LEVI RD",
    stop_desc: "WEAVER RD & W LEVI RD",
    stop_lat: 35.04223,
    stop_lon: -90.096443,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5837,
    stop_code: "LEVWEAEF",
    stop_name: "LEVI@WEAVER",
    stop_desc: "W LEVI RD & W LEVI RD",
    stop_lat: 35.04232,
    stop_lon: -90.09378,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5838,
    stop_code: "LEVOAKEN",
    stop_name: "LEVI RD@OAKSHIRE ST",
    stop_desc: "W LEVI RD & OAKSHIRE ST",
    stop_lat: 35.042321,
    stop_lon: -90.091945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5839,
    stop_code: "LEVFOREF",
    stop_name: "LEVI@FORD",
    stop_desc: "W LEVI RD & HAMMETT DR",
    stop_lat: 35.042698,
    stop_lon: -90.081333,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5840,
    stop_code: "LEVHAMEN",
    stop_name: "LEVI@HAMMETT",
    stop_desc: "W LEVI RD & HAMMETT DR",
    stop_lat: 35.042698,
    stop_lon: -90.079664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5841,
    stop_code: "LEVMCCEF",
    stop_name: "LEVI@MCCAIN",
    stop_desc: "W LEVI RD & WEST VALLEY CIR",
    stop_lat: 35.042689,
    stop_lon: -90.07794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5842,
    stop_code: "LEVDECEN",
    stop_name: "LEVI@DECEMBER",
    stop_desc: "W LEVI RD & DECEMBER ST",
    stop_lat: 35.04268,
    stop_lon: -90.074668,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5843,
    stop_code: "LEVDEAEN",
    stop_name: "LEVI@DEARBORN",
    stop_desc: "W LEVI RD & DEARBORN ST",
    stop_lat: 35.042707,
    stop_lon: -90.072457,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5844,
    stop_code: "LEVDEAWF",
    stop_name: "LEVI@DEARBORN",
    stop_desc: "W LEVI RD & DEARBORN ST",
    stop_lat: 35.042868,
    stop_lon: -90.072258,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5845,
    stop_code: "LEVDECWF",
    stop_name: "LEVI@DECEMBER",
    stop_desc: "W LEVI RD & DECEMBER ST",
    stop_lat: 35.042841,
    stop_lon: -90.074701,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5846,
    stop_code: "LEVWESWN",
    stop_name: "LEVI@WEST VALLEY",
    stop_desc: "W LEVI RD & W VALLEY CIR",
    stop_lat: 35.04285,
    stop_lon: -90.076945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5847,
    stop_code: "LEVMCCWN",
    stop_name: "LEVI@MCCAIN",
    stop_desc: "W LEVI RD & WEST VALLEY CIR",
    stop_lat: 35.04285,
    stop_lon: -90.078062,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5848,
    stop_code: "LEVHAMWF",
    stop_name: "LEVI@HAMMETT",
    stop_desc: "W LEVI RD & HAMMETT DR",
    stop_lat: 35.042859,
    stop_lon: -90.079742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5849,
    stop_code: "LEVFORWN",
    stop_name: "LEVI@FORD",
    stop_desc: "W LEVI RD & FORD RD",
    stop_lat: 35.042859,
    stop_lon: -90.081112,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5852,
    stop_code: "CANBENWN",
    stop_name: "CANARY@BENSON",
    stop_desc: "CANARY LN & BENSON RD",
    stop_lat: 35.047021,
    stop_lon: -90.097628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5853,
    stop_code: "CANPARWN",
    stop_name: "CANARY@PARAKEET",
    stop_desc: "CANARY LN & CANARY CV W",
    stop_lat: 35.046805,
    stop_lon: -90.100513,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5855,
    stop_code: "PELSKYNN",
    stop_name: "PELICAN@SKYLARK",
    stop_desc: "FIELDLARK DR & SKYLARK DR",
    stop_lat: 35.0476,
    stop_lon: -90.103619,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5856,
    stop_code: "SKYPELEF",
    stop_name: "SKYLARK@PELICAN",
    stop_desc: "SKYLARK DR & PELICAN LN",
    stop_lat: 35.047984,
    stop_lon: -90.103465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5857,
    stop_code: "SKYFIENN",
    stop_name: "SKYLARK@FIELDS",
    stop_desc: "SKYLARK DR & FIELDS RD",
    stop_lat: 35.049531,
    stop_lon: -90.10278,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5858,
    stop_code: "PELSKYSF",
    stop_name: "PELICAN@SKYLARK",
    stop_desc: "PELICAN LN & SKYLARK DR",
    stop_lat: 35.04735,
    stop_lon: -90.103465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5859,
    stop_code: "PELCANSN",
    stop_name: "PELICAN@CANARY",
    stop_desc: "FIELDLARK DR & CANARY LN",
    stop_lat: 35.046251,
    stop_lon: -90.102314,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5860,
    stop_code: "CANPAREN",
    stop_name: "CANARY@PARAKEET",
    stop_desc: "CANARY LN & PARAKEET RD",
    stop_lat: 35.046358,
    stop_lon: -90.100966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5861,
    stop_code: "CANCANEN",
    stop_name: "CANARY@CANARY CV WEST",
    stop_desc: "CANARY LN & CANARY CV W",
    stop_lat: 35.046761,
    stop_lon: -90.100203,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5862,
    stop_code: "CANWEAEN",
    stop_name: "CANARY@WEAVER",
    stop_desc: "CANARY LN & WEAVER RD",
    stop_lat: 35.046887,
    stop_lon: -90.096799,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5863,
    stop_code: "WEACARNN",
    stop_name: "WEAVER@CAREY",
    stop_desc: "WEAVER RD & CANARY LN",
    stop_lat: 35.048245,
    stop_lon: -90.096512,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5864,
    stop_code: "WEAFIENF",
    stop_name: "WEAVER RD@FIELDS RD",
    stop_desc: "",
    stop_lat: 35.049809,
    stop_lon: -90.096523,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5865,
    stop_code: "WEAWALNN",
    stop_name: "WEAVER@WALK RD",
    stop_desc: "WEAVER RD & WALK RD",
    stop_lat: 35.051847,
    stop_lon: -90.096535,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5866,
    stop_code: "WEAMITNN",
    stop_name: "WEAVER@MITCHELL",
    stop_desc: "WEAVER RD & W MITCHELL RD",
    stop_lat: 35.056021,
    stop_lon: -90.095309,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5867,
    stop_code: "SAXMITNF",
    stop_name: "SAX RD@MITCHELL RD",
    stop_desc: "SAX RD & W MITCHELL RD",
    stop_lat: 35.056586,
    stop_lon: -90.086819,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5868,
    stop_code: "SAXHAZNN",
    stop_name: "SAX@HAZELWOOD RD",
    stop_desc: "SAX RD & HAZELWOOD RD",
    stop_lat: 35.059562,
    stop_lon: -90.086742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5869,
    stop_code: "SAXHARNN",
    stop_name: "SAX@HARAHAN",
    stop_desc: "SAX RD & HARAHAN RD",
    stop_lat: 35.062574,
    stop_lon: -90.086699,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5870,
    stop_code: "SAXNONNN",
    stop_name: "SAX@NONCONNAH",
    stop_desc: "SAX RD & HARAHAN RD",
    stop_lat: 35.063593,
    stop_lon: -90.086666,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5871,
    stop_code: "SAXFLYNF",
    stop_name: "SAX@FLYNN",
    stop_desc: "SAX RD & FLYNN RD",
    stop_lat: 35.065443,
    stop_lon: -90.086589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5872,
    stop_code: "SAXPEENN",
    stop_name: "SAX@PEEBLES",
    stop_desc: "SAX RD & W PEEBLES RD",
    stop_lat: 35.066891,
    stop_lon: -90.086545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5874,
    stop_code: "PEEPAREN",
    stop_name: "PEEBLES@PARKER",
    stop_desc: "W PEEBLES RD & PARKER RD",
    stop_lat: 35.066909,
    stop_lon: -90.082498,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5875,
    stop_code: "PEEFOREN",
    stop_name: "PEEBLES@FORD",
    stop_desc: "W PEEBLES RD & FORD RD",
    stop_lat: 35.066865,
    stop_lon: -90.080795,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5884,
    stop_code: "PEEALTEN",
    stop_name: "PEEBLES@ALTA",
    stop_desc: "W PEEBLES RD & ALTA RD",
    stop_lat: 35.066802,
    stop_lon: -90.078418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5885,
    stop_code: "PEEALTEF",
    stop_name: "PEEBLES@ALTA",
    stop_desc: "W PEEBLES RD & ALTA RD",
    stop_lat: 35.06674,
    stop_lon: -90.076306,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5886,
    stop_code: "PEEROCEM",
    stop_name: "PEEBLES@ROCHESTER",
    stop_desc: "W PEEBLES RD & ROCHESTER RD",
    stop_lat: 35.066695,
    stop_lon: -90.074703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5887,
    stop_code: "PEEROCEN",
    stop_name: "PEEBLES@ROCHESTER",
    stop_desc: "W PEEBLES RD & ROCHESTER RD",
    stop_lat: 35.066677,
    stop_lon: -90.073564,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5892,
    stop_code: "ROCBROSF",
    stop_name: "ROCHESTER@BROOKS",
    stop_desc: "ROCHESTER RD & W BROOKS RD",
    stop_lat: 35.062825,
    stop_lon: -90.074393,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5893,
    stop_code: "ROCHILSN",
    stop_name: "ROCHESTER@HILDA",
    stop_desc: "ROCHESTER RD & RADAR RD",
    stop_lat: 35.061949,
    stop_lon: -90.074957,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5894,
    stop_code: "ROCKINSN",
    stop_name: "ROCHESTER@KING",
    stop_desc: "ROCHESTER RD & KING RD",
    stop_lat: 35.060233,
    stop_lon: -90.075598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5895,
    stop_code: "ROCJENSM",
    stop_name: "ROCHESTER@JENSON",
    stop_desc: "ROCHESTER RD & KING RD",
    stop_lat: 35.058973,
    stop_lon: -90.075642,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5896,
    stop_code: "ROCJENSN",
    stop_name: "ROCHESTER@JENSON",
    stop_desc: "ROCHESTER RD & JENSON RD",
    stop_lat: 35.057785,
    stop_lon: -90.075708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5897,
    stop_code: "ROCMITSF",
    stop_name: "ROCHESTER@MITCHELL",
    stop_desc: "ROCHESTER RD & W MITCHELL RD",
    stop_lat: 35.055202,
    stop_lon: -90.075763,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5899,
    stop_code: "ROCGLESM",
    stop_name: "ROCHESTER@GLENCO",
    stop_desc: "ROCHESTER RD & W MITCHELL RD",
    stop_lat: 35.051904,
    stop_lon: -90.075487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5900,
    stop_code: "ROCGLESN",
    stop_name: "ROCHESTER@GLENCO",
    stop_desc: "ROCHESTER RD & GLENCOE RD",
    stop_lat: 35.050903,
    stop_lon: -90.075995,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5901,
    stop_code: "GLELANEF",
    stop_name: "GLENCO@LANETTE",
    stop_desc: "GLENCOE RD & LANETTE RD",
    stop_lat: 35.050447,
    stop_lon: -90.074448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5902,
    stop_code: "GLEDAGEF",
    stop_name: "GLENCO@DAGGETT RD",
    stop_desc: "GLENCOE RD & DAGGETT RD",
    stop_lat: 35.050456,
    stop_lon: -90.072988,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5903,
    stop_code: "MILVERNN",
    stop_name: "MILLARD@VERNELLE",
    stop_desc: "GLENCOE RD & MILLARD RD",
    stop_lat: 35.05084,
    stop_lon: -90.071993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5919,
    stop_code: "FORLEVNF",
    stop_name: "FORD@LEVI",
    stop_desc: "FORD RD & W LEVI RD",
    stop_lat: 35.043234,
    stop_lon: -90.08151,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5920,
    stop_code: "FORMARNF",
    stop_name: "FORD@MARLOWE",
    stop_desc: "FORD RD & W LEVI RD",
    stop_lat: 35.04419,
    stop_lon: -90.08151,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5921,
    stop_code: "OAKLEVSF",
    stop_name: "OAKSHIRE@LEVI",
    stop_desc: "",
    stop_lat: 35.041756,
    stop_lon: -90.091633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5922,
    stop_code: "OAKCASSN",
    stop_name: "OAKSHIRE@CASTLEWOOD",
    stop_desc: "OAKSHIRE ST & CASTLEWOOD AVE",
    stop_lat: 35.040265,
    stop_lon: -90.091646,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5923,
    stop_code: "WESNORSM",
    stop_name: "WESTMONT@NORFLEET",
    stop_desc: "",
    stop_lat: 35.039014,
    stop_lon: -90.092567,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5924,
    stop_code: "WESNORSN",
    stop_name: "WESTMONT@NORFLEET",
    stop_desc: "WESTMONT RD & NORFLEET AVE",
    stop_lat: 35.037995,
    stop_lon: -90.092573,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 5925,
    stop_code: "WESCAVSN",
    stop_name: "WESTMONT@CAVALIER",
    stop_desc: "WESTMONT RD & WESTMONT ST",
    stop_lat: 35.036752,
    stop_lon: -90.092573,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6080,
    stop_code: "FIESEWWF",
    stop_name: "FIELDS@SEWANEE",
    stop_desc: "FIELDS RD & SEWANEE RD",
    stop_lat: 35.049669,
    stop_lon: -90.117604,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6081,
    stop_code: "FIEPINWF",
    stop_name: "FIELDS@PINKE",
    stop_desc: "FIELDS RD & PINKIE RD",
    stop_lat: 35.049632,
    stop_lon: -90.119716,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6082,
    stop_code: "FIEBEVWM",
    stop_name: "FIELDS@BEVERLY TERR",
    stop_desc: "FIELDS RD & BEVERLY TERRACE DR",
    stop_lat: 35.049623,
    stop_lon: -90.12091,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6083,
    stop_code: "FIEBOXWN",
    stop_name: "FIELDS@BOXTOWN",
    stop_desc: "FIELDS RD & BOXTOWN RD",
    stop_lat: 35.049656,
    stop_lon: -90.125309,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6085,
    stop_code: "BOXBOXSM",
    stop_name: "BOXTOWN@BOXTOWN E",
    stop_desc: "BOXTOWN RD & E BOXTOWN RD",
    stop_lat: 35.046806,
    stop_lon: -90.124721,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6086,
    stop_code: "BOXSEWSM",
    stop_name: "BOXTOWN@SEWANEE",
    stop_desc: "BOXTOWN RD & HICKS RD",
    stop_lat: 35.044929,
    stop_lon: -90.124654,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6087,
    stop_code: "BOXSEWSN",
    stop_name: "BOXTOWN RD@SEWANEE RD",
    stop_desc: "",
    stop_lat: 35.043428,
    stop_lon: -90.123846,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6089,
    stop_code: "BOXBOXNM",
    stop_name: "BOXTOWN@BOXTOWN E",
    stop_desc: "",
    stop_lat: 35.044984,
    stop_lon: -90.124505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6090,
    stop_code: "BOXBOXNF",
    stop_name: "BOXTOWN@BOXTOWN E",
    stop_desc: "BOXTOWN RD & SKYLARK DR",
    stop_lat: 35.046806,
    stop_lon: -90.124567,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6091,
    stop_code: "BOXFIENN",
    stop_name: "BOXTOWN@FIELDS",
    stop_desc: "BOXTOWN RD & FIELDS RD",
    stop_lat: 35.049183,
    stop_lon: -90.125143,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6092,
    stop_code: "FIEPINEM",
    stop_name: "FIELDS@PINKE",
    stop_desc: "FIELDS RD & BEVERLY TERRACE DR",
    stop_lat: 35.049462,
    stop_lon: -90.121385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6093,
    stop_code: "FIEPI1EM",
    stop_name: "FIELDS@PINKE",
    stop_desc: "FIELDS RD & PINKIE RD",
    stop_lat: 35.049471,
    stop_lon: -90.119716,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6094,
    stop_code: "FIESEWEN",
    stop_name: "FIELDS@SEWANEE",
    stop_desc: "FIELDS RD & PINKIE RD",
    stop_lat: 35.049508,
    stop_lon: -90.117405,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6095,
    stop_code: "KETKETEF",
    stop_name: "KETCHUM@KETCHUM CV",
    stop_desc: "",
    stop_lat: 35.079291,
    stop_lon: -89.983494,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6097,
    stop_code: "STPFOUWF",
    stop_name: "STPAUL@FOURTH ST",
    stop_desc: "? SAINT PAUL AVE & S FOURTH ST",
    stop_lat: 35.13155,
    stop_lon: -90.050804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6098,
    stop_code: "STPHERWN",
    stop_name: "STPAUL@HERNADO ST",
    stop_desc: "ST PAUL AVE & HERNANDO ST",
    stop_lat: 35.131568,
    stop_lon: -90.051778,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6099,
    stop_code: "HERGEPNN",
    stop_name: "HERNANDO@GE PATTERSON",
    stop_desc: "HERNANDO ST & E CALHOUN AVE",
    stop_lat: 35.132355,
    stop_lon: -90.051866,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6100,
    stop_code: "HERSTPSN",
    stop_name: "HERNANDO@STPAUL",
    stop_desc: "",
    stop_lat: 35.131592,
    stop_lon: -90.052052,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6101,
    stop_code: "STPFOUEN",
    stop_name: "STPAUL@FOURTHST",
    stop_desc: "",
    stop_lat: 35.131415,
    stop_lon: -90.050655,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6102,
    stop_code: "POPTHIEN",
    stop_name: "POPLAR@THIRD",
    stop_desc: "? POPLAR AVE & N 3RD ST",
    stop_lat: 35.149504,
    stop_lon: -90.048752,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6110,
    stop_code: "NORROLWN",
    stop_name: "NORRIS@ROLLINS",
    stop_desc: "NORRIS RD & ROLLINS ST",
    stop_lat: 35.083479,
    stop_lon: -90.023156,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6112,
    stop_code: "MALORLWN",
    stop_name: "MALLORY@ORLEANS",
    stop_desc: "E MALLORY AVE & S ORLEANS ST",
    stop_lat: 35.086291,
    stop_lon: -90.039822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6114,
    stop_code: "MALBENWF",
    stop_name: "MALLORY AVE@BENTON ST",
    stop_desc: "E MALLORY AVE & BENTON ST",
    stop_lat: 35.086191,
    stop_lon: -90.034314,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6115,
    stop_code: "MALPROWN",
    stop_name: "MALLORY@PROSPECT",
    stop_desc: "0 E MALLORY AVE & BENTON ST",
    stop_lat: 35.086254,
    stop_lon: -90.036824,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6116,
    stop_code: "MALMCMWN",
    stop_name: "MALLORY@MCMILLAN",
    stop_desc: "E MALLORY AVE & MCMILLAN ST",
    stop_lat: 35.086345,
    stop_lon: -90.042166,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6117,
    stop_code: "MALLAUWN",
    stop_name: "MALLORY@LAUDERDALE",
    stop_desc: "E MALLORY AVE & S LAUDERDALE ST",
    stop_lat: 35.086381,
    stop_lon: -90.0444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6118,
    stop_code: "LAUFRANN",
    stop_name: "LAUDERDALE@FRANK",
    stop_desc: "S LAUDERDALE ST & E FRANK AVE",
    stop_lat: 35.090251,
    stop_lon: -90.044322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6119,
    stop_code: "ALCTHAWN",
    stop_name: "ALCY@THAMES",
    stop_desc: "E ALCY RD & THAMES RD",
    stop_lat: 35.078569,
    stop_lon: -89.997448,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6120,
    stop_code: "ALCJOHWM",
    stop_name: "ALCY@JOHANNA",
    stop_desc: "ALCY RD & E ALCY RD",
    stop_lat: 35.078087,
    stop_lon: -89.999958,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6123,
    stop_code: "ALCSPAWN",
    stop_name: "ALCY@SPARKS",
    stop_desc: "E ALCY RD & SPARKS ST",
    stop_lat: 35.078032,
    stop_lon: -90.012422,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6126,
    stop_code: "MALPROEN",
    stop_name: "MALLORY@PROSPECT",
    stop_desc: "E MALLORY AVE & PROSPECT ST",
    stop_lat: 35.086085,
    stop_lon: -90.037024,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6127,
    stop_code: "MALBENEN",
    stop_name: "MALLORY AVE@BENTON ST",
    stop_desc: "E MALLORY AVE & BENTON ST",
    stop_lat: 35.086039,
    stop_lon: -90.034635,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6128,
    stop_code: "NORNCREN",
    stop_name: "NORRIS@NORRIS CIR",
    stop_desc: "NORRIS RD & NORRIS CIR",
    stop_lat: 35.083373,
    stop_lon: -90.026739,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6129,
    stop_code: "NORROLEN",
    stop_name: "NORRIS@ROLLINS",
    stop_desc: "NORRIS RD & ROLLINS ST",
    stop_lat: 35.083327,
    stop_lon: -90.0234,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6130,
    stop_code: "NORDRAEN",
    stop_name: "NORRIS@DRAKE",
    stop_desc: "NORRIS RD & DRAKE ST",
    stop_lat: 35.083291,
    stop_lon: -90.021962,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6131,
    stop_code: "NORELVEN",
    stop_name: "NORRIS@ELVIS PRESLEY",
    stop_desc: "NORRIS RD & ALCY RD",
    stop_lat: 35.083254,
    stop_lon: -90.01881,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6135,
    stop_code: "BUFDEESN",
    stop_name: "BUFFALO@DEERSKIN",
    stop_desc: "BUFFALO RD & DEERSKIN DR",
    stop_lat: 35.047166,
    stop_lon: -90.08339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6136,
    stop_code: "MOCMCVEN",
    stop_name: "MOCCASIN@MOCCASIN COVE",
    stop_desc: "MOCCASIN DR & MOCCASIN CV",
    stop_lat: 35.047434,
    stop_lon: -90.085734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6137,
    stop_code: "MOCBLAEF",
    stop_name: "MOCCASIN@BLACKHAWK",
    stop_desc: "0 MOCCASIN DR & BUFFALO RD",
    stop_lat: 35.047559,
    stop_lon: -90.084208,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6138,
    stop_code: "FORGAISN",
    stop_name: "FORD@GAINESVILLE",
    stop_desc: "FORD RD & GAINSVILLE AVE",
    stop_lat: 35.04596,
    stop_lon: -90.081986,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6139,
    stop_code: "FORMARSN",
    stop_name: "FORD@MARLOWE",
    stop_desc: "FORD RD & GAINSVILLE AVE",
    stop_lat: 35.04377,
    stop_lon: -90.081709,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6141,
    stop_code: "KIMKCVWN",
    stop_name: "KIMBALL@KIMBALL COVVE",
    stop_desc: "KIMBALL AVE & GULF CV",
    stop_lat: 35.095078,
    stop_lon: -89.965245,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6142,
    stop_code: "KIMLAUWF",
    stop_name: "KIMBALL@LAUREL",
    stop_desc: "KIMBALL AVE & GULF CV",
    stop_lat: 35.095126,
    stop_lon: -89.96895,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6144,
    stop_code: "COLPOWNN",
    stop_name: "COLEMAN@POWERS",
    stop_desc: "COLEMAN RD & POWERS RD",
    stop_lat: 35.214848,
    stop_lon: -89.91771,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6145,
    stop_code: "COLYALNN",
    stop_name: "COLEMAN@YALE",
    stop_desc: "COLEMAN RD & COLEMAN CV",
    stop_lat: 35.221692,
    stop_lon: -89.917121,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6146,
    stop_code: "SOUSEMWF",
    stop_name: "SOUTHERN@SEMMES",
    stop_desc: "SOUTHERN AVE & SEMMES ST",
    stop_lat: 35.115603,
    stop_lon: -89.961322,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6148,
    stop_code: "AUSYALNN",
    stop_name: "AUSTIN PEAY@YALE RD",
    stop_desc: "AUSTIN PEAY HWY & RAMP",
    stop_lat: 35.22103,
    stop_lon: -89.909211,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6150,
    stop_code: "PRIPARSF",
    stop_name: "PRIMACY PKWY@PARK AVE",
    stop_desc: "PRIMACY PKWY & ESTATE DR",
    stop_lat: 35.097874,
    stop_lon: -89.862778,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6152,
    stop_code: "PRIRIDEM",
    stop_name: "PRIMACY PKWY@RIDGEWAY",
    stop_desc: "PRIMACY PKWY & RIDGEWAY RD",
    stop_lat: 35.094948,
    stop_lon: -89.860972,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6155,
    stop_code: "GETSTUNN",
    stop_name: "GETWELL@STUART",
    stop_desc: "0 GETWELL RD & STUART RD",
    stop_lat: 35.104748,
    stop_lon: -89.930628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6156,
    stop_code: "GETSTUSF",
    stop_name: "GETWELL@STUART",
    stop_desc: "0 GETWELL RD & DRIVEWAY",
    stop_lat: 35.104114,
    stop_lon: -89.93085,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6157,
    stop_code: "CLEEASSN",
    stop_name: "CLEVELAND@EASTMORELAND",
    stop_desc: "S CLEVELAND ST & EASTMORELAND AVE",
    stop_lat: 35.135767,
    stop_lon: -90.016827,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6158,
    stop_code: "CLEPEASN",
    stop_name: "CLEVELAND@PEABODY",
    stop_desc: "S CLEVELAND ST & PEABODY AVE",
    stop_lat: 35.132746,
    stop_lon: -90.01694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6159,
    stop_code: "CLEVANNN",
    stop_name: "CLEVELAND@VANCE",
    stop_desc: "S CLEVELAND ST & VANCE AVE",
    stop_lat: 35.133336,
    stop_lon: -90.016718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6160,
    stop_code: "CLEUNINN",
    stop_name: "CLEVELAND@UNION",
    stop_desc: "S CLEVELAND ST & UNION AVE",
    stop_lat: 35.136696,
    stop_lon: -90.016572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6167,
    stop_code: "STABRUEN",
    stop_name: "STAGE@NEW BRUNSWICK",
    stop_desc: "US HWY 64 & STATE HWY 15",
    stop_lat: 35.204175,
    stop_lon: -89.778141,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6168,
    stop_code: "FOUCRUNM",
    stop_name: "FOURTH@CRUMP",
    stop_desc: "",
    stop_lat: 35.122989,
    stop_lon: -90.050917,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6175,
    stop_code: "CIVTRONN",
    stop_name: "CIVIC CTR-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.149182,
    stop_lon: -90.05122,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6176,
    stop_code: "CONTRONB",
    stop_name: "CONVEN CTR-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.151729,
    stop_lon: -90.050146,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6177,
    stop_code: "CONTROSB",
    stop_name: "CONVEN CTR-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.151747,
    stop_lon: -90.050201,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6178,
    stop_code: "CIVTROSM",
    stop_name: "CIVIC CTR-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.149182,
    stop_lon: -90.051253,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6179,
    stop_code: "DTHTROEB",
    stop_name: "MADISON@DANNYTHOMAS/TRO",
    stop_desc: "",
    stop_lat: 35.14271,
    stop_lon: -90.042821,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6180,
    stop_code: "ORLTROEB",
    stop_name: "MADISON@ORLEANS/TRO EB",
    stop_desc: "MADISON AVE",
    stop_lat: 35.141029,
    stop_lon: -90.037465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6182,
    stop_code: "PAUTROEB",
    stop_name: "MADISON@PAULINE/TRO EB",
    stop_desc: "MADISON AVE & N PAULINE ST",
    stop_lat: 35.140088,
    stop_lon: -90.028766,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6184,
    stop_code: "PEATRONB",
    stop_name: "PEABODY-MAIN/TRO NB",
    stop_desc: "",
    stop_lat: 35.141551,
    stop_lon: -90.054288,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6185,
    stop_code: "PEATROSB",
    stop_name: "PEABODY-MAIN/TRO SB",
    stop_desc: "",
    stop_lat: 35.14156,
    stop_lon: -90.054321,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6191,
    stop_code: "JAMHOLEF",
    stop_name: "JAMES RD@HOLLYWOOD ST",
    stop_desc: "0 JAMES RD & N HOLLYWOOD ST",
    stop_lat: 35.199145,
    stop_lon: -89.975315,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6192,
    stop_code: "JAMSKYWN",
    stop_name: "JAMES@W SKYLINE CIR",
    stop_desc: "JAMES RD",
    stop_lat: 35.19924,
    stop_lon: -89.98196,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6194,
    stop_code: "JAMMCLWN",
    stop_name: "JAMES@MCLEAN",
    stop_desc: "JAMES RD & N MCLEAN BLVD",
    stop_lat: 35.197505,
    stop_lon: -89.99347,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6199,
    stop_code: "JAMCHAWF",
    stop_name: "JAMES@CHATWORTH",
    stop_desc: "JAMES RD & CHATWORTH ST",
    stop_lat: 35.197697,
    stop_lon: -90.00099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6200,
    stop_code: "DELOVEWN",
    stop_name: "DELANO@OVERTON CROSSING",
    stop_desc: "0 JAMES RD & DELANO AVE",
    stop_lat: 35.197905,
    stop_lon: -90.003826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6201,
    stop_code: "DELWATWN",
    stop_name: "DELANO@WATKINS",
    stop_desc: "DELANO AVE & N WATKINS ST",
    stop_lat: 35.19939,
    stop_lon: -90.007723,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6207,
    stop_code: "DELWATEF",
    stop_name: "DELANO@WATKINS",
    stop_desc: "0 DELANO AVE",
    stop_lat: 35.199247,
    stop_lon: -90.006904,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6208,
    stop_code: "DELOVEEN",
    stop_name: "DELANO@OVERTON CROSSING",
    stop_desc: "DELANO AVE",
    stop_lat: 35.197896,
    stop_lon: -90.00428,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6209,
    stop_code: "JAMCHAEN",
    stop_name: "JAMES@CHATWORTH",
    stop_desc: "JAMES RD",
    stop_lat: 35.197572,
    stop_lon: -90.000592,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6210,
    stop_code: "JAMBEVEF",
    stop_name: "JAMES@BEAVERWOOD",
    stop_desc: "JAMES RD & BEAVERBROOK RD",
    stop_lat: 35.197498,
    stop_lon: -89.996848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6214,
    stop_code: "JAMMCLEF",
    stop_name: "JAMES@MCLEAN",
    stop_desc: "JAMES RD & N MCLEAN BLVD",
    stop_lat: 35.197362,
    stop_lon: -89.993969,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6215,
    stop_code: "JAMMTTEF",
    stop_name: "JAMES@MT TERRACE",
    stop_desc: "JAMES RD & RAMP",
    stop_lat: 35.198437,
    stop_lon: -89.984664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6216,
    stop_code: "JAMSKYEF",
    stop_name: "JAMES@W SKYLINE CIR",
    stop_desc: "JAMES RD & W SKYLINE CIR",
    stop_lat: 35.199195,
    stop_lon: -89.981628,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6243,
    stop_code: "KNIHICEF",
    stop_name: "KNIGHT ARNOLD@HICKORY HILL",
    stop_desc: "KNIGHT ARNOLD RD & HICKORY HILL RD",
    stop_lat: 35.060672,
    stop_lon: -89.865344,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6244,
    stop_code: "KNIRIDEM",
    stop_name: "KNIGHT ARNOLD@RIDGEWYCK DR.",
    stop_desc: "KNIGHT ARNOLD RD & KNIGHTSBRIDGE CIR",
    stop_lat: 35.060476,
    stop_lon: -89.86048,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6245,
    stop_code: "KNIKNIEM",
    stop_name: "KNIGHT ARNOLD@KNIGHT TRAILS CIR",
    stop_desc: "KNIGHT ARNOLD RD & KNIGHT TRAILS CIR",
    stop_lat: 35.060107,
    stop_lon: -89.849115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6246,
    stop_code: "KIRLENNN",
    stop_name: "KIRBY@LENOX",
    stop_desc: "KIRBY RD & KIRBY PKWY",
    stop_lat: 35.061276,
    stop_lon: -89.843694,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6295,
    stop_code: "COTMENEN",
    stop_name: "COTTONWOOD RD @ MENDENHALL RD",
    stop_desc: "COTTONWOOD RD & S MENDENHALL RD",
    stop_lat: 35.068849,
    stop_lon: -89.886486,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6327,
    stop_code: "WINMENNF",
    stop_name: "WINCHESTER RD @ MENDENHALL RD",
    stop_desc: "S MENDENHALL RD & RAMP",
    stop_lat: 35.051215,
    stop_lon: -89.886108,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6333,
    stop_code: "HOMMACNN",
    stop_name: "HOMER@MACON",
    stop_desc: "HOMER ST & MACON RD",
    stop_lat: 35.162159,
    stop_lon: -89.939469,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6334,
    stop_code: "MACLEAFE",
    stop_name: "MACON@LEAWOOD",
    stop_desc: "MACON RD & LEAWOOD ST",
    stop_lat: 35.162371,
    stop_lon: -89.937022,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6335,
    stop_code: "MACSALEN",
    stop_name: "MACON@SALEM",
    stop_desc: "MACON RD & SALEM ST",
    stop_lat: 35.162323,
    stop_lon: -89.9339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6336,
    stop_code: "MACMAREN",
    stop_name: "MACON@MARIA",
    stop_desc: "MACON RD & MARIA ST",
    stop_lat: 35.162293,
    stop_lon: -89.93152,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6343,
    stop_code: "MACMARNW",
    stop_name: "MACON@MARIA",
    stop_desc: "MACON RD & MARIA ST",
    stop_lat: 35.162445,
    stop_lon: -89.931265,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6344,
    stop_code: "MACSALWN",
    stop_name: "MACON@SALEM",
    stop_desc: "MACON RD & SALEM ST",
    stop_lat: 35.162474,
    stop_lon: -89.93359,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6345,
    stop_code: "MACLEAWN",
    stop_name: "MACON@LEAWOOD",
    stop_desc: "MACON RD & LEAWOOD ST",
    stop_lat: 35.162532,
    stop_lon: -89.937354,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6356,
    stop_code: "HICPLUSF",
    stop_name: "HICKORY HILL@ PLUM VALLEY DR",
    stop_desc: "0 HICKORY HILL RD & RIDGEHILL DR",
    stop_lat: 35.031395,
    stop_lon: -89.866483,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6358,
    stop_code: "HICSHESN",
    stop_name: "HICKORY HILL@SHELBY DR",
    stop_desc: "HICKORY HILL RD & ASHRIDGE PL",
    stop_lat: 35.020661,
    stop_lon: -89.866335,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6359,
    stop_code: "HICRAISF",
    stop_name: "E RAINES RD @ HICKORY HILL RD",
    stop_desc: "",
    stop_lat: 35.034604,
    stop_lon: -89.866541,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6369,
    stop_code: "BELFLOEN",
    stop_name: "BELZ@FLORIDA",
    stop_desc: "W BELZ BLVD & 1ST AVE",
    stop_lat: 35.09499,
    stop_lon: -90.063002,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6376,
    stop_code: "HOLMILWF",
    stop_name: "HOLMES@MILLBRANCH",
    stop_desc: "E HOLMES RD & LOCHINVAR DR",
    stop_lat: 35.006457,
    stop_lon: -90.007989,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6377,
    stop_code: "HOLLOCWF",
    stop_name: "HOLMES@LOCH LOMOND",
    stop_desc: "E HOLMES RD & LOCH LOMOND DR",
    stop_lat: 35.006458,
    stop_lon: -90.009426,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6378,
    stop_code: "HOLLEXWN",
    stop_name: "HOLMES@LEXIE",
    stop_desc: "E HOLMES RD & LEXIE DR",
    stop_lat: 35.00646,
    stop_lon: -90.01232,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6379,
    stop_code: "HOLCREWN",
    stop_name: "HOLMES@CRESSER",
    stop_desc: "E HOLMES RD & CRESSER ST",
    stop_lat: 35.006462,
    stop_lon: -90.01674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6381,
    stop_code: "HOLWALWM",
    stop_name: "HOLMES@WALDRUP",
    stop_desc: "E HOLMES RD & WALDRUP ST",
    stop_lat: 35.006445,
    stop_lon: -90.020595,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6382,
    stop_code: "HOLELVWN",
    stop_name: "HOLMES@ELVIS PRESLEY BLVD",
    stop_desc: "E HOLMES RD & WALDRUP ST",
    stop_lat: 35.006447,
    stop_lon: -90.024794,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6393,
    stop_code: "HUMHUMSF",
    stop_name: "HUMPHREYS CENTER",
    stop_desc: "",
    stop_lat: 35.12657,
    stop_lon: -89.852543,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6398,
    stop_code: "DOUPARSN",
    stop_name: "DOUBLE TREE RD@PARKROSE RD",
    stop_desc: "0 DOUBLE TREE RD & PARKROSE RD",
    stop_lat: 35.025435,
    stop_lon: -90.103444,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6422,
    stop_code: "WINFALWN",
    stop_name: "WINCHESTER@FALL RIVER",
    stop_desc: "0 WINCHESTER RD & FALL RIVER DR",
    stop_lat: 35.050576,
    stop_lon: -89.883412,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6427,
    stop_code: "WOLGALML",
    stop_name: "WOLFCHASE GALLERIA MALL",
    stop_desc: "",
    stop_lat: 35.200202,
    stop_lon: -89.783196,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6431,
    stop_code: "LAPHEASF",
    stop_name: "LAPALOMA@HEARST",
    stop_desc: "LAPALOMA ST & FRISCO AVE",
    stop_lat: 35.096104,
    stop_lon: -89.998637,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6434,
    stop_code: 3206,
    stop_name: "AIRWAYS TRANSIT CENTER",
    stop_desc: "",
    stop_lat: 35.061781,
    stop_lon: -89.992831,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6435,
    stop_code: "RIVROCNN",
    stop_name: "RIVERDALE@ROCKINGHAM",
    stop_desc: "RIVERDALE RD & ROCKINGHAM RD",
    stop_lat: 35.027593,
    stop_lon: -89.831129,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6473,
    stop_code: "STAVANWN",
    stop_name: "STAGE@VAN LEER DR",
    stop_desc: "STATE HWY 15 & VAN LEER DR",
    stop_lat: 35.204226,
    stop_lon: -89.824365,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6479,
    stop_code: "FRYREDEN",
    stop_name: "FRAYSER RALEIGH BLVD@REDCOAT RD",
    stop_desc: "FRAYSER RALEIGH RD & REDCOAT RD",
    stop_lat: 35.221053,
    stop_lon: -89.960519,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6494,
    stop_code: "WINCASWF",
    stop_name: "WINCHESTER@CASTLEMAN",
    stop_desc: "WINCHESTER RD & CASTLEMAN ST",
    stop_lat: 35.050742,
    stop_lon: -89.892156,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6495,
    stop_code: "WINMENWF",
    stop_name: "WINCHESTER@MENDENHALL",
    stop_desc: "WINCHESTER RD & GROCERY RD",
    stop_lat: 35.050609,
    stop_lon: -89.887325,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6502,
    stop_code: "MADIMAIN",
    stop_name: "MADISON-MAIN/TRO NB",
    stop_desc: "N MAIN ST",
    stop_lat: 35.145179,
    stop_lon: -90.052815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6503,
    stop_code: "PAUTROWB",
    stop_name: "MADISON@PAULINE/TRO WB",
    stop_desc: "MADISON AVE & N PAULINE ST",
    stop_lat: 35.140061,
    stop_lon: -90.028401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6504,
    stop_code: "THITROWB",
    stop_name: "MADISON@THIRD/TRO",
    stop_desc: "MADISON AVE",
    stop_lat: 35.144231,
    stop_lon: -90.049063,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6505,
    stop_code: "JEFFERNN",
    stop_name: "JEFFERSON@MAIN/TRO WB",
    stop_desc: "",
    stop_lat: 35.147252,
    stop_lon: -90.052062,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6510,
    stop_code: "COUMAIN",
    stop_name: "COURT-MAIN/TRO SB",
    stop_desc: "N MAIN ST",
    stop_lat: 35.146627,
    stop_lon: -90.052261,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6513,
    stop_code: "MADMAIN",
    stop_name: "MADISON@MAIN/TRO",
    stop_desc: "MADISON AVE",
    stop_lat: 35.145233,
    stop_lon: -90.052881,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6517,
    stop_code: "NORELVWN",
    stop_name: "NORRIS@ELVIS PRESLEY",
    stop_desc: "NORRIS RD",
    stop_lat: 35.083379,
    stop_lon: -90.018379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6520,
    stop_code: "VANCOSEN",
    stop_name: "VANCE@COSSITT",
    stop_desc: "VANCE AVE & COSSITT PL",
    stop_lat: 35.133557,
    stop_lon: -90.03282,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6521,
    stop_code: "CHEHOLWF",
    stop_name: "CHELSEA AVE@HOLLYWOOD",
    stop_desc: "CHELSEA AVE & OAKWOOD ST",
    stop_lat: 35.174714,
    stop_lon: -89.975677,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6522,
    stop_code: "HOLJACNF",
    stop_name: "HOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & JACKSON AVE",
    stop_lat: 35.158154,
    stop_lon: -89.975908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6530,
    stop_code: "DIRVANNN",
    stop_name: "DIRECTORS@VANGUARD",
    stop_desc: "DIRECTORS ROW & VICTORIA RD",
    stop_lat: 35.064884,
    stop_lon: -89.994674,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6533,
    stop_code: "SUMHOLWF",
    stop_name: "SUMMER@HOLLYWOOD",
    stop_desc: "STATE HWY 1 & US HWY 79",
    stop_lat: 35.150622,
    stop_lon: -89.977345,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6534,
    stop_code: "SUMHOLEF",
    stop_name: "SUMMER AVE@HOLLYWOOD ST",
    stop_desc: "STATE HWY 1 & N HOLLYWOOD ST",
    stop_lat: 35.150487,
    stop_lon: -89.976648,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6536,
    stop_code: "BASSPRO",
    stop_name: "BASS PRO SHOPS",
    stop_desc: "N PKY",
    stop_lat: 35.156707,
    stop_lon: -90.050499,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6538,
    stop_code: "NORELVWF",
    stop_name: "NORRIS@ELVIS PRESLEY",
    stop_desc: "NORRIS RD & ALCY RD",
    stop_lat: 35.083361,
    stop_lon: -90.018821,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6542,
    stop_code: "AUSRALNM",
    stop_name: "AUSTIN PEAY@RALEIGH",
    stop_desc: "AUSTIN PEAY HWY & STATE HWY 14",
    stop_lat: 35.232818,
    stop_lon: -89.897585,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6544,
    stop_code: "JACWATWN",
    stop_name: "JACKSON AVE@WATKINS",
    stop_desc: "? STATE HWY 14 & JACKSON AVE",
    stop_lat: 35.158213,
    stop_lon: -90.011476,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6546,
    stop_code: "THOWHINF",
    stop_name: "THOMAS ST @WHITNEY AVE",
    stop_desc: "US HWY 51 & THOMAS ST",
    stop_lat: 35.210428,
    stop_lon: -90.032351,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6547,
    stop_code: "THOWHISN",
    stop_name: "THOMAS@WHITNEY",
    stop_desc: "US HWY 51 & THOMAS ST",
    stop_lat: 35.210017,
    stop_lon: -90.032772,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6549,
    stop_code: "HOLJAMNF",
    stop_name: "HOLLYWOOD@JAMES RD",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.199628,
    stop_lon: -89.975757,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6550,
    stop_code: "HOLJAMSN",
    stop_name: "HOLLYWOOD@JAMES",
    stop_desc: "RANGE LINE RD & N HOLLYWOOD ST",
    stop_lat: 35.200084,
    stop_lon: -89.975956,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6551,
    stop_code: "TILPRISN",
    stop_name: "TILLMAN@PRINCETON",
    stop_desc: "0 TILLMAN ST & HEARD AVE",
    stop_lat: 35.145613,
    stop_lon: -89.961379,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6553,
    stop_code: "TILJOHNN",
    stop_name: "TILLMAN@JOHNSON",
    stop_desc: "0 TILLMAN ST & SNYDER RD",
    stop_lat: 35.141654,
    stop_lon: -89.961341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6555,
    stop_code: "CLEAUTSF",
    stop_name: "CLEVELAND@AUTUMN",
    stop_desc: "? N CLEVELAND ST & YALE AVE",
    stop_lat: 35.150413,
    stop_lon: -90.01426,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6556,
    stop_code: "OVECLAWN",
    stop_name: "OVERTON PARK@CLAYBROOK",
    stop_desc: "OVERTON PARK AVE & N CLAYBROOK ST",
    stop_lat: 35.149645,
    stop_lon: -90.015954,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6557,
    stop_code: "OVEMONWN",
    stop_name: "OVERTON PARK@MONTGOMERY",
    stop_desc: "OVERTON PARK AVE & N MONTGOMERY ST",
    stop_lat: 35.149852,
    stop_lon: -90.018257,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6558,
    stop_code: "OVEBELWN",
    stop_name: "OVERTON PARK@BELLEVUE",
    stop_desc: "OVERTON PARK AVE & N BELLEVUE BLVD",
    stop_lat: 35.150049,
    stop_lon: -90.020116,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6559,
    stop_code: "BELAUTNN",
    stop_name: "BELLEVUE@AUTUMN",
    stop_desc: "N BELLEVUE BLVD & AUTUMN AVE",
    stop_lat: 35.151291,
    stop_lon: -90.019938,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6560,
    stop_code: "BELFORNN",
    stop_name: "BELLEVUE@FORREST",
    stop_desc: "N BELLEVUE BLVD & FORREST AVE",
    stop_lat: 35.152783,
    stop_lon: -90.019705,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6561,
    stop_code: "NPADUNEN",
    stop_name: "N PARKWAY@DUNLAP",
    stop_desc: "US HWY 64 & STATE HWY 1",
    stop_lat: 35.155093,
    stop_lon: -90.03136,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6562,
    stop_code: "NPABELEN",
    stop_name: "N PARKWAY@BELLEVUE",
    stop_desc: "N PKWY & N BELLEVUE BLVD",
    stop_lat: 35.154097,
    stop_lon: -90.019792,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6563,
    stop_code: "BELFORSF",
    stop_name: "BELLEVUE@FORREST",
    stop_desc: "N BELLEVUE BLVD & FORREST AVE",
    stop_lat: 35.152676,
    stop_lon: -90.019882,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6564,
    stop_code: "BELAUTSN",
    stop_name: "BELLEVUE@AUTUMN",
    stop_desc: "N BELLEVUE BLVD & AUTUMN AVE",
    stop_lat: 35.151649,
    stop_lon: -90.020049,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6565,
    stop_code: "OVEMONEF",
    stop_name: "OVERTON PARK@MONTGOMERY",
    stop_desc: "OVERTON PARK AVE & MALVERN ST",
    stop_lat: 35.1497,
    stop_lon: -90.018323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6566,
    stop_code: "OVECLAEF",
    stop_name: "OVERTON PARK@CLAYBROOK",
    stop_desc: "OVERTON PARK AVE & N CLAYBROOK ST",
    stop_lat: 35.149502,
    stop_lon: -90.015888,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6567,
    stop_code: "NPAEVEEN",
    stop_name: "N PARKWAY@EVERGREEN",
    stop_desc: "US HWY 64 & N EVERGREEN ST",
    stop_lat: 35.152891,
    stop_lon: -90.002138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6569,
    stop_code: "DEMNONWN",
    stop_name: "DEMOCRAT@NONCONNAH",
    stop_desc: "DEMOCRAT RD & NONCONNAH BLVD",
    stop_lat: 35.068822,
    stop_lon: -89.989385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6570,
    stop_code: "MTMAUTWN",
    stop_name: "MT MORIAH@AUTUMNWOOD",
    stop_desc: "MOUNT MORIAH ROAD EXT & NONCONNAH BLVD",
    stop_lat: 35.064686,
    stop_lon: -89.846316,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6571,
    stop_code: "MTMWHIWF",
    stop_name: "MT MORIAH@WHISPERING LANE",
    stop_desc: "MOUNT MORIAH ROAD EXT & WHISPERING LN E",
    stop_lat: 35.065395,
    stop_lon: -89.847641,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6572,
    stop_code: "MTMMORWF",
    stop_name: "MT MORIAH@MORIAH TRAIL",
    stop_desc: "MOUNT MORIAH ROAD EXT & MORIAH TRL",
    stop_lat: 35.06655,
    stop_lon: -89.848931,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6573,
    stop_code: "MTMR13WM",
    stop_name: "MT MORIAH@RIDGEWAY",
    stop_desc: "MOUNT MORIAH ROAD EXT & MORIAH TRL",
    stop_lat: 35.068256,
    stop_lon: -89.852785,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6575,
    stop_code: "MTMPICWN",
    stop_name: "MT MORIAH@PICKERING",
    stop_desc: "MOUNT MORIAH RD & PICKERING DR",
    stop_lat: 35.074399,
    stop_lon: -89.869731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6577,
    stop_code: "MTMMT2WF",
    stop_name: "MT MORIAH@MT MORIAH VIEW",
    stop_desc: "MT MORIAH RD & MOUNT MORIAH VW",
    stop_lat: 35.079987,
    stop_lon: -89.887399,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6585,
    stop_code: "MISWALNN",
    stop_name: "MISSISSIPPI BLVD@WALKER",
    stop_desc: "MISSISSIPPI BLVD & WALKER AVE",
    stop_lat: 35.119367,
    stop_lon: -90.03858,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6592,
    stop_code: "ALIBENWN",
    stop_name: "ALICE AVE@BENTON ST",
    stop_desc: "",
    stop_lat: 35.094244,
    stop_lon: -90.035947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6593,
    stop_code: "ALILAUWN",
    stop_name: "ALICE@LAUREN ROBERTS",
    stop_desc: "ALICE AVE & GAITHER CV",
    stop_lat: 35.09418,
    stop_lon: -90.031589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6594,
    stop_code: "MARGAISF",
    stop_name: "MARJORIE@GAITHER COVE",
    stop_desc: "MARJORIE ST & GAITHER CV",
    stop_lat: 35.096477,
    stop_lon: -90.031665,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6598,
    stop_code: "PROALISF",
    stop_name: "PROSPECT@ALICE",
    stop_desc: "PROSPECT ST & ALICE AVE",
    stop_lat: 35.09369,
    stop_lon: -90.038071,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6599,
    stop_code: "PRODUNSN",
    stop_name: "PROSPECT@DUNN",
    stop_desc: "PROSPECT ST & DUNN AVE",
    stop_lat: 35.091322,
    stop_lon: -90.037231,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6600,
    stop_code: "PROMCKSN",
    stop_name: "PROSPECT@MCKELLER",
    stop_desc: "PROSPECT ST & DUNN AVE",
    stop_lat: 35.090133,
    stop_lon: -90.036922,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6601,
    stop_code: "PRODAVSF",
    stop_name: "PROSPECT@DAVANT",
    stop_desc: "PROSPECT ST & E DAVANT AVE",
    stop_lat: 35.087854,
    stop_lon: -90.036967,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6636,
    stop_code: "WINCLEWF",
    stop_name: "WINCHESTER@CLEARBROOK",
    stop_desc: "WINCHESTER RD & CLEARBROOK ST",
    stop_lat: 35.050965,
    stop_lon: -89.897782,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6637,
    stop_code: "WINBOXWF",
    stop_name: "WINCHESTER@BOXDALE",
    stop_desc: "WINCHESTER RD & BOXDALE ST",
    stop_lat: 35.051087,
    stop_lon: -89.901961,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6638,
    stop_code: "WINPERWN",
    stop_name: "WINCHESTER@PERKINS",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.051228,
    stop_lon: -89.907123,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6639,
    stop_code: "PERWOONF",
    stop_name: "PERKINS@WOODDALE",
    stop_desc: "S PERKINS RD & WOODDALE AVE",
    stop_lat: 35.054017,
    stop_lon: -89.907548,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6640,
    stop_code: "PERSCONN",
    stop_name: "PERKINS@SCOTTSDALE",
    stop_desc: "0 S PERKINS RD & SCOTTSDALE AVE",
    stop_lat: 35.058063,
    stop_lon: -89.905749,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6641,
    stop_code: "PERKNINM",
    stop_name: "PERKINS@KNIGHT ARNOLD",
    stop_desc: "S PERKINS RD & SCOTTSDALE AVE",
    stop_lat: 35.060128,
    stop_lon: -89.905634,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6642,
    stop_code: "PERKNINF",
    stop_name: "PERKINS@KNIGHT ARNOLD",
    stop_desc: "0 S PERKINS RD & CONNECTING RD",
    stop_lat: 35.063444,
    stop_lon: -89.905549,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6643,
    stop_code: "PERALONN",
    stop_name: "PERKINS@ALOHA",
    stop_desc: "S PERKINS RD & ALOHA AVE",
    stop_lat: 35.065472,
    stop_lon: -89.905589,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6644,
    stop_code: "PERCOTNN",
    stop_name: "PERKINS@COTTONWOOD",
    stop_desc: "S PERKINS RD & COTTONWOOD RD",
    stop_lat: 35.069163,
    stop_lon: -89.905482,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6645,
    stop_code: "PERCOTSF",
    stop_name: "PERKINS@COTTONWOOD",
    stop_desc: "S PERKINS RD & COTTONWOOD RD",
    stop_lat: 35.069012,
    stop_lon: -89.905692,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6646,
    stop_code: "PERALOSF",
    stop_name: "PERKINS@ALOHA",
    stop_desc: "S PERKINS RD & ALOHA AVE",
    stop_lat: 35.065401,
    stop_lon: -89.905788,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6647,
    stop_code: "PERKNISN",
    stop_name: "PERKINS@KNIGHT ARNOLD",
    stop_desc: "S PERKINS RD & RAMP",
    stop_lat: 35.063346,
    stop_lon: -89.905848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6648,
    stop_code: "PERKNISF",
    stop_name: "PERKINS@KNIGHT ARNOLD",
    stop_desc: "S PERKINS RD & RAMP",
    stop_lat: 35.061371,
    stop_lon: -89.905996,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6649,
    stop_code: "PERWOOSN",
    stop_name: "PERKINS@WOODDALE",
    stop_desc: "S PERKINS RD & WOODDALE AVE",
    stop_lat: 35.05408,
    stop_lon: -89.907924,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6650,
    stop_code: "WINPEREF",
    stop_name: "WINCHESTER@PERKINS",
    stop_desc: "WINCHESTER RD & RAMP",
    stop_lat: 35.051058,
    stop_lon: -89.906991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6652,
    stop_code: "CHEPARSF",
    stop_name: "CHERRY@PARK",
    stop_desc: "CHERRY RD & BOXDALE ST",
    stop_lat: 35.106848,
    stop_lon: -89.915811,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6653,
    stop_code: "PARCHENN",
    stop_name: "PARK@CHERRY",
    stop_desc: "CHERRY RD & BOXDALE ST",
    stop_lat: 35.106937,
    stop_lon: -89.915612,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6654,
    stop_code: "CHEHAVSN",
    stop_name: "CHERRY@HAVERHILL",
    stop_desc: "CHERRY RD & HAVERHILL RD",
    stop_lat: 35.102397,
    stop_lon: -89.915953,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6655,
    stop_code: "CHEHAVNF",
    stop_name: "CHERRY@HAVERHILL",
    stop_desc: "CHERRY RD & HAVERHILL RD",
    stop_lat: 35.102307,
    stop_lon: -89.915754,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6656,
    stop_code: "CHEWOOSF",
    stop_name: "CHERRY@WOODCREST",
    stop_desc: "CHERRY RD & WOODCREST DR",
    stop_lat: 35.09901,
    stop_lon: -89.916015,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6657,
    stop_code: "CHEWOONN",
    stop_name: "CHERRY@WOODCREST",
    stop_desc: "CHERRY RD & WOODCREST DR",
    stop_lat: 35.099448,
    stop_lon: -89.915815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6658,
    stop_code: "CHERHOSN",
    stop_name: "CHERRY@RHODES",
    stop_desc: "CHERRY RD & RHODES AVE",
    stop_lat: 35.095632,
    stop_lon: -89.916143,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6659,
    stop_code: "CHEQUINF",
    stop_name: "CHERRY@QUINCE",
    stop_desc: "CHERRY RD & QUINCE RD",
    stop_lat: 35.09556,
    stop_lon: -89.915944,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6663,
    stop_code: "UNIWILNF",
    stop_name: "UNIVERSITY@WILLOW WOOD",
    stop_desc: "UNIVERSITY ST & PUEBLO ST",
    stop_lat: 35.211649,
    stop_lon: -89.989989,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6672,
    stop_code: "AIRPRONF",
    stop_name: "AIRWAYS@PROFESSIONAL",
    stop_desc: "AIRWAYS BLVD & UNNAMED ST",
    stop_lat: 35.057553,
    stop_lon: -89.991387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6673,
    stop_code: "NPKSTOEF",
    stop_name: "N PARKWAY@STONEWALL",
    stop_desc: "US HWY 64 & N PARKWAY",
    stop_lat: 35.153394,
    stop_lon: -90.007805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6674,
    stop_code: "NPKSTOWN",
    stop_name: "N PARKWAY@STONEWALL",
    stop_desc: "N PKWY & N PARKWAY",
    stop_lat: 35.153671,
    stop_lon: -90.007417,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6688,
    stop_code: "MFIKSF",
    stop_name: "MARKET FARMS DR@IKEA WAY SB",
    stop_desc: "",
    stop_lat: 35.188302,
    stop_lon: -89.795888,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6689,
    stop_code: "SECPEASN",
    stop_name: "SECOND@PEAR",
    stop_desc: "N 2ND",
    stop_lat: 35.182318,
    stop_lon: -90.044989,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6690,
    stop_code: "SAXKINNN",
    stop_name: "SAX@KING",
    stop_desc: "",
    stop_lat: 35.06067,
    stop_lon: -90.086731,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6691,
    stop_code: "FRAREDWF",
    stop_name: "FRAYSER@REDCOAT",
    stop_desc: "NEW FRAYSER RALEIGH RD",
    stop_lat: 35.22192,
    stop_lon: -89.960895,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6701,
    stop_code: "DEXAPPEF",
    stop_name: "DEXTER RD@APPLING RD",
    stop_desc: "",
    stop_lat: 35.171981,
    stop_lon: -89.814403,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6702,
    stop_code: "APPDEXNF",
    stop_name: "APPLING RD@DEXTER RD",
    stop_desc: "",
    stop_lat: 35.172115,
    stop_lon: -89.814602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6703,
    stop_code: "DEXCHINF",
    stop_name: "DEXTER RD@CHICKERING LANE",
    stop_desc: "",
    stop_lat: 35.172753,
    stop_lon: -89.796187,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6705,
    stop_code: "APPMAYSN",
    stop_name: "APPLING RD@MAY APPLE DR",
    stop_desc: "",
    stop_lat: 35.174117,
    stop_lon: -89.814352,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6706,
    stop_code: "GOODCBEN",
    stop_name: "GOODLETT FARMS PKY@CHARLES BRYAN RD",
    stop_desc: "",
    stop_lat: 35.173925,
    stop_lon: -89.8293,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6707,
    stop_code: "GOODCBEF",
    stop_name: "GOODLETT FARMS PKY@CHARLES BRYAN RD",
    stop_desc: "",
    stop_lat: 35.173889,
    stop_lon: -89.829467,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6709,
    stop_code: "RIVCOUSN",
    stop_name: "RIVERSIDE DR @ COURT AVE",
    stop_desc: "",
    stop_lat: 35.146547,
    stop_lon: -90.05515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6710,
    stop_code: "RIVBEASN",
    stop_name: "RIVERSIDE DR @ BEALE ST",
    stop_desc: "",
    stop_lat: 35.141283,
    stop_lon: -90.057984,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6711,
    stop_code: "RIVTOMSM",
    stop_name: "RIVERSIDE DR @ TOM LEE PARK",
    stop_desc: "",
    stop_lat: 35.135948,
    stop_lon: -90.062943,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6717,
    stop_code: "DELSTEWF",
    stop_name: "DELANO@STEELE",
    stop_desc: "",
    stop_lat: 35.199688,
    stop_lon: -90.014202,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6718,
    stop_code: "STASTAWN",
    stop_name: "STAGE DELANO@STAGE",
    stop_desc: "STAGE DELANO RD",
    stop_lat: 35.201274,
    stop_lon: -90.023229,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6719,
    stop_code: "STAWATWM",
    stop_name: "STAGE@WATER LILY TRAIL",
    stop_desc: "STAGE AVE",
    stop_lat: 35.203439,
    stop_lon: -90.029774,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6720,
    stop_code: "STATHOWN",
    stop_name: "STAGE@THOMAS",
    stop_desc: "STAGE AVE",
    stop_lat: 35.203493,
    stop_lon: -90.032642,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6721,
    stop_code: "STATHOEF",
    stop_name: "STAGE@THOMAS",
    stop_desc: "STAGE AVE",
    stop_lat: 35.20344,
    stop_lon: -90.032742,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6722,
    stop_code: "STAWATEF",
    stop_name: "STAGE@WATER LILY TRAIL",
    stop_desc: "STAGE AVE",
    stop_lat: 35.20326,
    stop_lon: -90.029552,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6723,
    stop_code: "STASTAEN",
    stop_name: "STAGE DELANO @STAGE",
    stop_desc: "STAGE AVE",
    stop_lat: 35.201328,
    stop_lon: -90.023516,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6724,
    stop_code: "STASTEEN",
    stop_name: "DELANO@STEELE",
    stop_desc: "DELANO RD",
    stop_lat: 35.199536,
    stop_lon: -90.014203,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6725,
    stop_code: "JAMSKYWM",
    stop_name: "JAMES@E SKYLINE CIR",
    stop_desc: "JAMES RD",
    stop_lat: 35.199157,
    stop_lon: -89.978737,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6726,
    stop_code: "JAMMTTWN",
    stop_name: "JAMES@MT TERRACE",
    stop_desc: "JAMES RD",
    stop_lat: 35.198616,
    stop_lon: -89.984708,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6727,
    stop_code: "DEL156WF",
    stop_name: "DELANO@1560",
    stop_desc: "DELANO AVE",
    stop_lat: 35.199544,
    stop_lon: -90.01099,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6728,
    stop_code: "STALERWF",
    stop_name: "STAGE@LAREDO",
    stop_desc: "STAGE AVE",
    stop_lat: 35.202508,
    stop_lon: -90.026396,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6729,
    stop_code: "STALAREF",
    stop_name: "STAGE@LAREDO",
    stop_desc: "STAGE AVE",
    stop_lat: 35.202285,
    stop_lon: -90.026285,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6730,
    stop_code: "DEL156EM",
    stop_name: "DELANO@1561",
    stop_desc: "DELANO AVE",
    stop_lat: 35.199401,
    stop_lon: -90.010991,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6731,
    stop_code: "JAMSKYEM",
    stop_name: "JAMES@E SKYLINE CIR",
    stop_desc: "JAMES RD",
    stop_lat: 35.199112,
    stop_lon: -89.978649,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6757,
    stop_code: "SHEOAKWM",
    stop_name: "SHELBYOAKS@6081",
    stop_desc: "SHELBY OAKS DR & SHELBY OAKS DR",
    stop_lat: 35.167702,
    stop_lon: -89.86073,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6758,
    stop_code: "SHESHENN",
    stop_name: "SHELBYOAKS@SHELBYOAKS",
    stop_desc: "SHELBY OAKS COVE S & SHELBY OAKS DR",
    stop_lat: 35.170427,
    stop_lon: -89.855054,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6768,
    stop_code: "RALWHIWF",
    stop_name: "RALEIGH LAGRANGE RD@WHITTEN RD",
    stop_desc: "",
    stop_lat: 35.178516,
    stop_lon: -89.837259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6769,
    stop_code: "SHERALSF",
    stop_name: "SHELBYVIEW@RALEIGH LAGRANGE",
    stop_desc: "SHELBY VIEW DR & GOLDBRIER LN",
    stop_lat: 35.179039,
    stop_lon: -89.849072,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6770,
    stop_code: "SHESHVSF",
    stop_name: "SHELBYOAKS@SHELBYVIEW",
    stop_desc: "N SHELBY OAKS DR & SHELBY VIEW DR",
    stop_lat: 35.175976,
    stop_lon: -89.854916,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6800,
    stop_code: "RALWHIEM",
    stop_name: "RALEIGH LAGRANGE RD@WHITTEN RD",
    stop_desc: "",
    stop_lat: 35.178187,
    stop_lon: -89.838212,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6806,
    stop_code: "RALWALWN",
    stop_name: "RALEIGH LAGRANGE@WAL MART WAY",
    stop_desc: "RALEIGH LAGRANGE RD",
    stop_lat: 35.177612,
    stop_lon: -89.841137,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6810,
    stop_code: "UNITHIEN",
    stop_name: "UNION AVE @ S 3RD ST",
    stop_desc: "",
    stop_lat: 35.14248,
    stop_lon: -90.051299,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6813,
    stop_code: "ALCHILWN",
    stop_name: "ALCY@HILLVIEW",
    stop_desc: "E ALCY RD & E HILLVIEW DR",
    stop_lat: 35.078378,
    stop_lon: -89.992593,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6814,
    stop_code: "ALCSAMWN",
    stop_name: "ALCY@SAMUELS",
    stop_desc: "E ALCY RD & SAMUELS ST",
    stop_lat: 35.078523,
    stop_lon: -89.996176,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6815,
    stop_code: "ALCVERWM",
    stop_name: "ALCY@VERDUN",
    stop_desc: "E ALCY RD & SAMUELS ST",
    stop_lat: 35.077811,
    stop_lon: -90.000821,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6816,
    stop_code: "CINPERNM",
    stop_name: "CINCINNATI@PERSON",
    stop_desc: "CINCINNATI RD & UNNAMED ST",
    stop_lat: 35.099276,
    stop_lon: -90.01339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6817,
    stop_code: "CUMTALNN",
    stop_name: "CUMMINGS@TALLEY",
    stop_desc: "CUMMINGS ST & E TRIGG AVE",
    stop_lat: 35.113268,
    stop_lon: -90.027564,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6818,
    stop_code: "COLFERNM",
    stop_name: "COLLEGE@FERRY",
    stop_desc: "COLLEGE ST & DRIVEWAY",
    stop_lat: 35.1178,
    stop_lon: -90.030703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6819,
    stop_code: "LAUSTPNF",
    stop_name: "LAUDERDALE@ST PAUL",
    stop_desc: "S LAUDERDALE ST & SAINT PAUL AVE",
    stop_lat: 35.132255,
    stop_lon: -90.044009,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6820,
    stop_code: "VANTHIWN",
    stop_name: "VANCE@THIRD",
    stop_desc: "VANCE AVE & ELDERS ROW DR",
    stop_lat: 35.135921,
    stop_lon: -90.0542,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6826,
    stop_code: "CHELOCEN",
    stop_name: "CHELSEA@LOCUST",
    stop_desc: "CHELSEA AVE & LOCUST ST",
    stop_lat: 35.173599,
    stop_lon: -89.957774,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6828,
    stop_code: "CHETREWN",
    stop_name: "CHELSEA@TREZEVANT",
    stop_desc: "CHELSEA AVE & MAPLEWOOD ST",
    stop_lat: 35.174833,
    stop_lon: -89.979696,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6834,
    stop_code: "JACDANEF",
    stop_name: "JACKSON@DANNY THOMAS",
    stop_desc: "JACKSON AVE & DRIVEWAY",
    stop_lat: 35.156114,
    stop_lon: -90.036983,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6835,
    stop_code: "AUSLAKNF",
    stop_name: "AUSTIN PEAY@LAKEHURST",
    stop_desc: "AUSTIN PEAY HWY & DRIVEWAY",
    stop_lat: 35.22409,
    stop_lon: -89.906114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6836,
    stop_code: "AUSJOSNN",
    stop_name: "AUSTIN PEAY @ JOSLYN",
    stop_desc: "AUSTIN PEAY HWY & LAKEHURST DR",
    stop_lat: 35.227178,
    stop_lon: -89.903049,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6837,
    stop_code: "AUSLAKNN",
    stop_name: "AUSTIN PEAY@LAKEHURST",
    stop_desc: "AUSTIN PEAY HWY & PARKING LOT",
    stop_lat: 35.229908,
    stop_lon: -89.900328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6838,
    stop_code: "COVYALSM",
    stop_name: "COVINGTON PIKE @ YALE",
    stop_desc: "COVINGTON PIKE & PARKING LOT",
    stop_lat: 35.222268,
    stop_lon: -89.894263,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6839,
    stop_code: "JACWOOWN",
    stop_name: "JACKSON@WOODLAWN",
    stop_desc: "JACKSON AVE & WOODLAWN ST",
    stop_lat: 35.156864,
    stop_lon: -90.034602,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6840,
    stop_code: "HOLJACSM",
    stop_name: "HOLLYWOOD@JACKSON",
    stop_desc: "N HOLLYWOOD ST & DRIVEWAY",
    stop_lat: 35.160103,
    stop_lon: -89.975905,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6841,
    stop_code: "EPACENNN",
    stop_name: "E PARKWAY@CENTRAL",
    stop_desc: "E S & RAMP",
    stop_lat: 35.125452,
    stop_lon: -89.983992,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6842,
    stop_code: "AIRDUNSN",
    stop_name: "AIRWAYS@DUNN",
    stop_desc: "AIRWAYS BLVD & RAMP",
    stop_lat: 35.091843,
    stop_lon: -89.98768,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6843,
    stop_code: "BREJACNN",
    stop_name: "BREEDLOVE@JACKSON",
    stop_desc: "BREEDLOVE ST & DRIVEWAY",
    stop_lat: 35.157378,
    stop_lon: -90.023089,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6850,
    stop_code: "MADIJEFF",
    stop_name: "MADISON@NEELY WB",
    stop_desc: "",
    stop_lat: 35.142076,
    stop_lon: -90.041128,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6851,
    stop_code: "CLAYMADI",
    stop_name: "CLAY BROOK@MADISON",
    stop_desc: "",
    stop_lat: 35.139208,
    stop_lon: -90.018219,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6852,
    stop_code: "MADIMARS",
    stop_name: "MADISON@MARSHALL EB",
    stop_desc: "",
    stop_lat: 35.142147,
    stop_lon: -90.041416,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6853,
    stop_code: "MADIBELL",
    stop_name: "MADISON@BELLEVUE EB",
    stop_desc: "",
    stop_lat: 35.139397,
    stop_lon: -90.021706,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6855,
    stop_code: "MILDAVNM",
    stop_name: "MILLBRANCH@DAVID",
    stop_desc: "0 MILLBRANCH RD & DRIVEWAY",
    stop_lat: 35.039241,
    stop_lon: -90.007421,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6856,
    stop_code: "CHAGETEF",
    stop_name: "CHAD LN @ GETWELL RD",
    stop_desc: "",
    stop_lat: 35.001607,
    stop_lon: -89.936107,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6857,
    stop_code: "WATKLOUI",
    stop_name: "WATKINS ST@LOUISVILLE AVE",
    stop_desc: "",
    stop_lat: 35.175557,
    stop_lon: -90.008296,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6858,
    stop_code: "CENUMCEN",
    stop_name: "CENTRAL AVE@U-M CROSSWALK",
    stop_desc: "0 CENTRAL AVE & PARKING LOT",
    stop_lat: 35.122861,
    stop_lon: -89.936238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6860,
    stop_code: "CENZHCWF",
    stop_name: "CENTRAL@ZACH H CURLIN",
    stop_desc: "0 CENTRAL AVE & GRANDVIEW ST",
    stop_lat: 35.122938,
    stop_lon: -89.933505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6861,
    stop_code: "CENUMCWF",
    stop_name: "CENTRAL@U-M CROSSWALK",
    stop_desc: "0 CENTRAL AVE & PARKING LOT",
    stop_lat: 35.123022,
    stop_lon: -89.936238,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6862,
    stop_code: "CENDELWF",
    stop_name: "CENTRAL@DELOACH",
    stop_desc: "0 CENTRAL AVE & PATTERSON ST",
    stop_lat: 35.123106,
    stop_lon: -89.939358,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6863,
    stop_code: "TCHMORSN",
    stop_name: "TCHULAHOMA RD@MORNINGVIEW DR",
    stop_desc: "0 TCHULAHOMA RD & MORNINGVIEW DR",
    stop_lat: 35.011378,
    stop_lon: -89.954663,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6864,
    stop_code: "HOLMELEM",
    stop_name: "HOLMES@TCHULAHOMA",
    stop_desc: "0 E HOLMES RD & PATTERSON ST",
    stop_lat: 35.006083,
    stop_lon: -89.9515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6865,
    stop_code: "HOLMELEF",
    stop_name: "HOLMES@MELTECH",
    stop_desc: "0 E HOLMES RD & MELTECH BLVD",
    stop_lat: 35.006051,
    stop_lon: -89.946528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6866,
    stop_code: "HOLTCHWM",
    stop_name: "HOLMES RD @TCHULAHOMA RD",
    stop_desc: "0 E HOLMES RD & MELTECH BLVD",
    stop_lat: 35.006244,
    stop_lon: -89.9515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6867,
    stop_code: "TCHMORNN",
    stop_name: "TCHULAHOMA RD@MORNINGVIEW RD",
    stop_desc: "0 TCHULAHOMA RD & MORNINGVIEW DR",
    stop_lat: 35.011181,
    stop_lon: -89.954453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6868,
    stop_code: "FRARINEF",
    stop_name: "FRAYSER@RINEY",
    stop_desc: "0 FRAYSER BLVD & RINEY ST",
    stop_lat: 35.217556,
    stop_lon: -90.005626,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6869,
    stop_code: "PERCHUSF",
    stop_name: "PERKINS@CHUCK",
    stop_desc: "0 S PERKINS RD & N WATKINS ST",
    stop_lat: 35.056135,
    stop_lon: -89.907013,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6870,
    stop_code: "FRATHOEF",
    stop_name: "FRAYSER@THOMAS",
    stop_desc: "0 FRAYSER BLVD & THOMAS ST",
    stop_lat: 35.2167,
    stop_lon: -90.030032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6871,
    stop_code: "FRAMILEN",
    stop_name: "FRAYSER@MILLINGTON",
    stop_desc: "0 FRAYSER BLVD & MILLINGTON RD",
    stop_lat: 35.216521,
    stop_lon: -90.02888,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6872,
    stop_code: "MILWHISM",
    stop_name: "MILLINGTON@WHITNEY",
    stop_desc: "0 MILLINGTON RD & CINDY LN",
    stop_lat: 35.212437,
    stop_lon: -90.028838,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6873,
    stop_code: "MILWH2SN",
    stop_name: "MILLINGTON@WHITNEY",
    stop_desc: "0 MILLINGTON RD & CINDY LN",
    stop_lat: 35.211767,
    stop_lon: -90.028839,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6874,
    stop_code: "FRAUNIEN",
    stop_name: "FRAYSER@UNIVERSITY",
    stop_desc: "0 FRAYSER BLVD & WOODROW ST",
    stop_lat: 35.217055,
    stop_lon: -89.989928,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6875,
    stop_code: "FRADEBEN",
    stop_name: "FRAYSER@DEBBY",
    stop_desc: "0 FRAYSER BLVD & DEBBY ST",
    stop_lat: 35.218524,
    stop_lon: -89.981983,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6876,
    stop_code: "AUSRSMNS",
    stop_name: "AUSTIN PEAY@RALEIGH SPRINGS MKTPL",
    stop_desc: "0 AUSTIN PEAY HWY & PARKING LOT",
    stop_lat: 35.219093,
    stop_lon: -89.911342,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6878,
    stop_code: "YALBELWN",
    stop_name: "YALE@BELLWOOD",
    stop_desc: "0 YALE RD & BELLWOOD DR",
    stop_lat: 35.221828,
    stop_lon: -89.918494,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6879,
    stop_code: "YALRAMWN",
    stop_name: "YALE@RAMIL",
    stop_desc: "0 YALE RD & LEHI DR",
    stop_lat: 35.220615,
    stop_lon: -89.927105,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6880,
    stop_code: "DELCANWF",
    stop_name: "DELLWOOD@CANFIELD",
    stop_desc: "0 DELLWOOD AVE & LEHI DR",
    stop_lat: 35.213058,
    stop_lon: -90.016154,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6881,
    stop_code: "PAUVANSN",
    stop_name: "PAULINE@VANCE",
    stop_desc: "0 S PAULINE ST & VANCE AVE",
    stop_lat: 35.133725,
    stop_lon: -90.028438,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6882,
    stop_code: "MILWHINF",
    stop_name: "MILLINGTON@WHITNEY",
    stop_desc: "0 MILLINGTON RD",
    stop_lat: 35.21149,
    stop_lon: -90.02865,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6883,
    stop_code: "MILCINNN",
    stop_name: "MILLINGTON@CINDY",
    stop_desc: "0 MILLINGTON RD & DRIVEWAY",
    stop_lat: 35.21401,
    stop_lon: -90.028616,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6884,
    stop_code: "FRAMILWF",
    stop_name: "FRAYSER@MILLINGTON",
    stop_desc: "0 FRAYSER BLVD & DRIVEWAY",
    stop_lat: 35.216682,
    stop_lon: -90.029113,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6887,
    stop_code: "THILOOSN",
    stop_name: "THIRD@LOONEY",
    stop_desc: "0 N 3RD ST & KEEL AVE",
    stop_lat: 35.161549,
    stop_lon: -90.043612,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6888,
    stop_code: "HAWMOUEN",
    stop_name: "HAWKINS MILL@MOUNTAIN TERRACE",
    stop_desc: "0 HAWKINS MILL RD & MOUNTAIN TERRACE ST",
    stop_lat: 35.244877,
    stop_lon: -89.983138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6890,
    stop_code: "STECOREN",
    stop_name: "ST ELMO@CORAL",
    stop_desc: "0 SAINT ELMO AVE & SAINT ELMO AVE",
    stop_lat: 35.233351,
    stop_lon: -89.9734,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6892,
    stop_code: "THIMARNF",
    stop_name: "THIRD@MARKET",
    stop_desc: "0 N 3RD ST & MARKET AVE",
    stop_lat: 35.151532,
    stop_lon: -90.047401,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6895,
    stop_code: "UNIFOUEN",
    stop_name: "UNION@FOURTH",
    stop_desc: "0 UNION AVE & HERNANDO ST",
    stop_lat: 35.141952,
    stop_lon: -90.049274,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6899,
    stop_code: "COTLANWN",
    stop_name: "COTTONWOOD@LANDVIEW",
    stop_desc: "0 COTTONWOOD RD & GINGER CIR",
    stop_lat: 35.069146,
    stop_lon: -89.893474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6902,
    stop_code: "KNIDANEN",
    stop_name: "KNIGHT ARNOLD@DANVILLE",
    stop_desc: "0 KNIGHT ARNOLD RD & DANVILLE RD",
    stop_lat: 35.062564,
    stop_lon: -89.916287,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6910,
    stop_code: "AUSRALNF",
    stop_name: "AUSTIN PEAY@RALEIGH COMMON",
    stop_desc: "0 AUSTIN PEAY HWY & DRIVEWAY",
    stop_lat: 35.231764,
    stop_lon: -89.898474,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6912,
    stop_code: "NCOSCHSF",
    stop_name: "NEW COVINGTON PIKE@SCHEIBLER",
    stop_desc: "0 COVINGTON PIKE & DRIVEWAY",
    stop_lat: 35.223546,
    stop_lon: -89.894215,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6913,
    stop_code: "COVYALSN",
    stop_name: "COVINGTON PIKE@YALE",
    stop_desc: "0 COVINGTON PIKE & GLOUCESTER AVE",
    stop_lat: 35.221616,
    stop_lon: -89.894286,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6914,
    stop_code: "REDFRANM",
    stop_name: "REDCOAT@FRAYSER RALEIGH",
    stop_desc: "0 REDCOAT RD",
    stop_lat: 35.221347,
    stop_lon: -89.960441,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6917,
    stop_code: "HOLPERNN",
    stop_name: "HOLLYWOOD@PERES",
    stop_desc: "0 N HOLLYWOOD ST & PERES AVE",
    stop_lat: 35.176795,
    stop_lon: -89.975243,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6918,
    stop_code: "HOLHEANN",
    stop_name: "HOLLYWOOD@HEARD",
    stop_desc: "0 N HOLLYWOOD ST & HEARD AVE",
    stop_lat: 35.180843,
    stop_lon: -89.975094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6919,
    stop_code: "BELOVESM",
    stop_name: "BELLEVUE@OVERTON PARK",
    stop_desc: "0 N BELLEVUE BLVD & AUTUMN AVE",
    stop_lat: 35.150585,
    stop_lon: -90.020249,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6920,
    stop_code: "SUMCAREN",
    stop_name: "SUMMER@CARPENTER",
    stop_desc: "0 SUMMER AVE & LESTER ST",
    stop_lat: 35.149476,
    stop_lon: -89.964385,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6922,
    stop_code: "SUMVI2EM",
    stop_name: "SUMMER@VIRGINIA RUN",
    stop_desc: "0 SUMMER AVE & BEARFIELD PL",
    stop_lat: 35.155073,
    stop_lon: -89.886247,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6929,
    stop_code: "CASKELSN",
    stop_name: "CASTALIA@KELTNER",
    stop_desc: "0 CASTALIA ST & KELTNER CIR",
    stop_lat: 35.094904,
    stop_lon: -89.995043,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6935,
    stop_code: "LAUW1LSF",
    stop_name: "LAUDERDALE ST@WALDORF AVE",
    stop_desc: "0 S LAUDERDALE ST & E ESSEX AVE",
    stop_lat: 35.105212,
    stop_lon: -90.044106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6936,
    stop_code: "GETHOLSN",
    stop_name: "GETWELL@HOLMES",
    stop_desc: "0 GETWELL RD & E HOLMES RD",
    stop_lat: 35.006166,
    stop_lon: -89.936927,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6940,
    stop_code: "MACGRAEF",
    stop_name: "MACON@GRAHAM",
    stop_desc: "0 MACON RD & GRAHAM OAKS CT",
    stop_lat: 35.162153,
    stop_lon: -89.926328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6941,
    stop_code: "MACVAUWN",
    stop_name: "MACON@VAUGHN",
    stop_desc: "0 MACON RD & VAUGHN RD",
    stop_lat: 35.162172,
    stop_lon: -89.92056,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6942,
    stop_code: "MACGRAWN",
    stop_name: "MACON@GRAHAM",
    stop_desc: "0 MACON RD & GRAHAM OAKS CT",
    stop_lat: 35.162314,
    stop_lon: -89.926328,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6943,
    stop_code: "WALHI2NE",
    stop_name: "WALNUT GROVE RD@HIGHLAND ST",
    stop_desc: "",
    stop_lat: 35.131816,
    stop_lon: -89.94522,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6944,
    stop_code: "WALAVOWN",
    stop_name: "WALNUT GROVE@AVON",
    stop_desc: "0 WALNUT GROVE RD & AVON RD",
    stop_lat: 35.131066,
    stop_lon: -89.902572,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6946,
    stop_code: "GETHOLNN",
    stop_name: "GETWELL@HOLMES",
    stop_desc: "0 GETWELL RD & E HOLMES RD",
    stop_lat: 35.005513,
    stop_lon: -89.936718,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6951,
    stop_code: "HOLCRUEM",
    stop_name: "HOLMES@CRUMPLER",
    stop_desc: "0 E HOLMES RD & LUCCA AVE",
    stop_lat: 35.005724,
    stop_lon: -89.850807,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6952,
    stop_code: "CRUAMBNN",
    stop_name: "CRUMPLER@AMBERWOOD",
    stop_desc: "0 CRUMPLER RD & AMBERWOOD DR",
    stop_lat: 35.012441,
    stop_lon: -89.848545,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6953,
    stop_code: "DEERCOVE",
    stop_name: "DEERSKIN DR@DEERSKIN COVE",
    stop_desc: "",
    stop_lat: 35.046746,
    stop_lon: -90.08538,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6955,
    stop_code: "MLKCRUNF",
    stop_name: "MLK BLVD@CRUMP",
    stop_desc: "0 S SOMERVILLE ST & DRIVEWAY",
    stop_lat: 35.13174,
    stop_lon: -90.02596,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6956,
    stop_code: "MENKNISF",
    stop_name: "MENDENHALL@KNIGHT ARNOLD",
    stop_desc: "0 S MENDENHALL RD & TYROL CT",
    stop_lat: 35.060876,
    stop_lon: -89.885963,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6972,
    stop_code: "NATROCNM",
    stop_name: "NATIONAL@ROCKWOOD",
    stop_desc: "NATIONAL ST & BEALE ST",
    stop_lat: 35.165911,
    stop_lon: -89.946216,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6973,
    stop_code: "NATFAINF",
    stop_name: "NATIONAL@FAIROAKS",
    stop_desc: "0 NATIONAL ST & FAIROAKS AVE",
    stop_lat: 35.168759,
    stop_lon: -89.944008,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6974,
    stop_code: "NATHAZNM",
    stop_name: "NATIONAL@HAZELWOOD",
    stop_desc: "0 NATIONAL ST & HAZELWOOD AVE",
    stop_lat: 35.170804,
    stop_lon: -89.942577,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6975,
    stop_code: "NATVERSN",
    stop_name: "NATIONAL@VERNON",
    stop_desc: "0 NATIONAL ST & MAYFLOWER AVE",
    stop_lat: 35.167232,
    stop_lon: -89.945439,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6976,
    stop_code: "NATFARNN",
    stop_name: "NATIONAL@FARMVILLE",
    stop_desc: "0 NATIONAL ST & ROSAMOND AVE",
    stop_lat: 35.164714,
    stop_lon: -89.946639,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6977,
    stop_code: "NATFARSN",
    stop_name: "NATIONAL@FARMVILLE",
    stop_desc: "0 NATIONAL ST & FARMVILLE RD",
    stop_lat: 35.164893,
    stop_lon: -89.946816,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6978,
    stop_code: "ORCNATNM",
    stop_name: "ORCHI@NATIONAL",
    stop_desc: "0 ORCHI RD & NATIONAL ST",
    stop_lat: 35.1781,
    stop_lon: -89.93808,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6984,
    stop_code: "POPCR2WF",
    stop_name: "POPLAR@CRESTHAVEN",
    stop_desc: "0 POPLAR AVE & CREST HAVEN RD",
    stop_lat: 35.105259,
    stop_lon: -89.875027,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6985,
    stop_code: "WINQUIWN",
    stop_name: "WINCHESTER@QUINCE",
    stop_desc: "0 WINCHESTER RD & QUINCE RD",
    stop_lat: 35.050204,
    stop_lon: -89.817926,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6986,
    stop_code: "AUCTHIEF",
    stop_name: "AW WILLIS@THIRD",
    stop_desc: "0 AUCTION AVE & N 3RD ST",
    stop_lat: 35.156339,
    stop_lon: -90.045473,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6987,
    stop_code: "CASBOYSF",
    stop_name: "CASTALIA@BOYLE",
    stop_desc: "0 CASTALIA ST & KELLOGG AVE",
    stop_lat: 35.098711,
    stop_lon: -89.994896,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 6996,
    stop_code: "TRIAZAWF",
    stop_name: "TRIGG@AZALIA",
    stop_desc: "",
    stop_lat: 35.111839,
    stop_lon: -90.030065,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7006,
    stop_code: "TILMIMSF",
    stop_name: "TILLMAN@MIMOSA",
    stop_desc: "0 TILLMAN ST & MIMOSA AVE",
    stop_lat: 35.138902,
    stop_lon: -89.961776,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7008,
    stop_code: "NATMACNF",
    stop_name: "NATIONAL@MACON",
    stop_desc: "NATIONAL ST",
    stop_lat: 35.162453,
    stop_lon: -89.946908,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7009,
    stop_code: "NATBAYNF",
    stop_name: "NATIONAL@BAYLISS AVE",
    stop_desc: "",
    stop_lat: 35.172028,
    stop_lon: -89.942043,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7010,
    stop_code: "SHESYCSF",
    stop_name: "SHELBY OAKS@SYCAMORE VIEW",
    stop_desc: "",
    stop_lat: 35.168718,
    stop_lon: -89.863827,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7011,
    stop_code: "NATTUTNF",
    stop_name: "NATIONAL@TUTWILER",
    stop_desc: "0 NATIONAL ST & BOWEN AVE",
    stop_lat: 35.152275,
    stop_lon: -89.947644,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7012,
    stop_code: "NATCOLNN",
    stop_name: "NATIONAL@COLEMAN",
    stop_desc: "0 NATIONAL ST & POWELL AVE",
    stop_lat: 35.155707,
    stop_lon: -89.947528,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7013,
    stop_code: "NATGUENN",
    stop_name: "NATIONAL@GUERNSEY",
    stop_desc: "0 NATIONAL ST & GUERNSEY AVE",
    stop_lat: 35.158459,
    stop_lon: -89.947446,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7014,
    stop_code: "NATGUESN",
    stop_name: "NATIONAL@GUERNSEY",
    stop_desc: "0 NATIONAL ST & GUERNSEY AVE",
    stop_lat: 35.158227,
    stop_lon: -89.947767,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7015,
    stop_code: "NATCOLSF",
    stop_name: "NATIONAL@COLEMAN",
    stop_desc: "0 NATIONAL ST & POWELL AVE",
    stop_lat: 35.155609,
    stop_lon: -89.94786,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7016,
    stop_code: "NATTUTSF",
    stop_name: "NATIONAL@TUTWILER",
    stop_desc: "0 NATIONAL ST & TUTWILER AVE",
    stop_lat: 35.151891,
    stop_lon: -89.947977,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7018,
    stop_code: "HOLATKWN",
    stop_name: "W HOLMES RD@ATKINS DR",
    stop_desc: "",
    stop_lat: 35.00642,
    stop_lon: -90.071826,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7020,
    stop_code: "POPNRIEF",
    stop_name: "POPLAR@NEW RIVERDALE",
    stop_desc: "0 POPLAR AVE & 72",
    stop_lat: 35.096378,
    stop_lon: -89.824002,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7021,
    stop_code: "POPMILEF",
    stop_name: "POPLAR@MILLER FARMS",
    stop_desc: "0 POPLAR AVE & MILLER FARMS RD",
    stop_lat: 35.095315,
    stop_lon: -89.815587,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7022,
    stop_code: "TURN",
    stop_name: "SWOOSHWAY",
    stop_desc: "",
    stop_lat: 35.232139,
    stop_lon: -89.95818,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7024,
    stop_code: "VRCNALEN",
    stop_name: "VICTORY RIDGE COVE@NEW ALLEN RD",
    stop_desc: "",
    stop_lat: 35.23222,
    stop_lon: -89.955454,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7025,
    stop_code: "JAMAUSWN",
    stop_name: "JAMES RD@AUSTIN PEAY HWY",
    stop_desc: "",
    stop_lat: 35.205355,
    stop_lon: -89.922226,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7026,
    stop_code: "CENLAMWM",
    stop_name: "CENTRAL@LAMAR",
    stop_desc: "0 CENTRAL AVE & S MANSFIELD ST",
    stop_lat: 35.126677,
    stop_lon: -90.015506,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7027,
    stop_code: "CENMANEN",
    stop_name: "CENTRAL@MANSFIELD",
    stop_desc: "0 CENTRAL AVE & S MANSFIELD ST",
    stop_lat: 35.126508,
    stop_lon: -90.015251,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7028,
    stop_code: "CENMELEF",
    stop_name: "CENTRAL@MELROSE",
    stop_desc: "0 CENTRAL AVE & ROSEMARY LN",
    stop_lat: 35.126417,
    stop_lon: -90.013304,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7029,
    stop_code: "CENROLEM",
    stop_name: "CENTRAL@ROLAND",
    stop_desc: "0 CENTRAL AVE & S WILLETT ST",
    stop_lat: 35.126308,
    stop_lon: -90.009442,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7030,
    stop_code: "CENBELEF",
    stop_name: "CENTRAL@BELVEDERE",
    stop_desc: "0 CENTRAL AVE & S BELVEDERE BLVD",
    stop_lat: 35.126162,
    stop_lon: -90.004064,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7031,
    stop_code: "CENMCLEF",
    stop_name: "CENTRAL@MCLEAN",
    stop_desc: "0 CENTRAL AVE & S MCLEAN BLVD",
    stop_lat: 35.126152,
    stop_lon: -90.001032,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7032,
    stop_code: "CENMETEN",
    stop_name: "CENTRAL@METCALF",
    stop_desc: "0 CENTRAL AVE & S BARKSDALE ST",
    stop_lat: 35.126078,
    stop_lon: -89.997458,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7033,
    stop_code: "CENLINEN",
    stop_name: "CENTRAL@LINDSEY",
    stop_desc: "0 CENTRAL AVE & S REMBERT ST",
    stop_lat: 35.126032,
    stop_lon: -89.994869,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7034,
    stop_code: "CENCOOWF",
    stop_name: "CENTRAL@COOPER",
    stop_desc: "0 CENTRAL AVE & S COOPER ST",
    stop_lat: 35.126118,
    stop_lon: -89.990919,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7035,
    stop_code: "CENREMWF",
    stop_name: "CENTRAL@REMBERT",
    stop_desc: "0 CENTRAL AVE & S REMBERT ST",
    stop_lat: 35.126211,
    stop_lon: -89.995876,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7036,
    stop_code: "CENMCLWF",
    stop_name: "CENTRAL@MCLEAN",
    stop_desc: "0 CENTRAL AVE & S MCLEAN BLVD",
    stop_lat: 35.126322,
    stop_lon: -90.001906,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7037,
    stop_code: "CENROZWN",
    stop_name: "CENTRAL@ROZELLE",
    stop_desc: "0 CENTRAL AVE & ROZELLE ST",
    stop_lat: 35.126458,
    stop_lon: -90.006189,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7038,
    stop_code: "CENWILWF",
    stop_name: "CENTRAL@WILLETT",
    stop_desc: "0 CENTRAL AVE & DRIVEWAY",
    stop_lat: 35.126586,
    stop_lon: -90.010703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7039,
    stop_code: "THIMLKNF",
    stop_name: "THIRD@MLK",
    stop_desc: "0 S 3RD ST & LINDEN AVE",
    stop_lat: 35.137735,
    stop_lon: -90.052805,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7042,
    stop_code: "GOOGRASF",
    stop_name: "GOODLETT@GRANDVIEW",
    stop_desc: "0 S GOODLETT ST & GRANDVIEW AVE",
    stop_lat: 35.118765,
    stop_lon: -89.926033,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7043,
    stop_code: "GOOSPOSF",
    stop_name: "GOODLETT@SPOTTSWOOD",
    stop_desc: "0 S GOODLETT ST & DOUGLASS AVE",
    stop_lat: 35.112786,
    stop_lon: -89.926166,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7044,
    stop_code: "GOODOUSF",
    stop_name: "GOODLETT@DOUGLASS",
    stop_desc: "0 S GOODLETT ST & DOUGLASS AVE",
    stop_lat: 35.109533,
    stop_lon: -89.926305,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7045,
    stop_code: "GOODOUNF",
    stop_name: "GOODLETT@DOUGLASS",
    stop_desc: "0 S GOODLETT ST & SPOTTSWOOD AVE",
    stop_lat: 35.109917,
    stop_lon: -89.926094,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7046,
    stop_code: "GOOSPONF",
    stop_name: "GOODLETT@SPOTTSWOOD",
    stop_desc: "0 S GOODLETT ST & SPOTTSWOOD AVE",
    stop_lat: 35.11317,
    stop_lon: -89.925955,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7047,
    stop_code: "GOOGRANF",
    stop_name: "GOODLETT@GRANDVIEW",
    stop_desc: "0 S GOODLETT ST & GRANDVIEW AVE",
    stop_lat: 35.119149,
    stop_lon: -89.925822,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7048,
    stop_code: "WALUMCEF",
    stop_name: "WALKER@UNIVERSITY CENTER",
    stop_desc: "0 WALKER AVE & UNIVERSITY CENTER",
    stop_lat: 35.116301,
    stop_lon: -89.935973,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7050,
    stop_code: "KETIMOEN",
    stop_name: "KETCHUM@IMOGENE",
    stop_desc: "0 KETCHUM RD & PECAN CIR",
    stop_lat: 35.079388,
    stop_lon: -89.980184,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7051,
    stop_code: "KETLABEN",
    stop_name: "KETCHUM@LABELLE",
    stop_desc: "0 KETCHUM RD & PECAN CIR",
    stop_lat: 35.080429,
    stop_lon: -89.97493,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7052,
    stop_code: "PENJOLNF",
    stop_name: "PENDLETON@JOLSON",
    stop_desc: "0 PENDLETON ST & LOLA AVE",
    stop_lat: 35.08242,
    stop_lon: -89.971798,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7053,
    stop_code: "FRIPENWF",
    stop_name: "FRISCO@PENDLETON",
    stop_desc: "0 FRISCO AVE & LOLA AVE",
    stop_lat: 35.085538,
    stop_lon: -89.971141,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7054,
    stop_code: "FRILABWF",
    stop_name: "FRISCO@LABELLE",
    stop_desc: "0 FRISCO AVE & LOLA AVE",
    stop_lat: 35.087132,
    stop_lon: -89.974512,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7055,
    stop_code: "FRILABEF",
    stop_name: "FRISCO@LABELLE",
    stop_desc: "0 FRISCO AVE & LOLA AVE",
    stop_lat: 35.086765,
    stop_lon: -89.974137,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7056,
    stop_code: "KETJOYWM",
    stop_name: "KETCHUM@JOY",
    stop_desc: "0 KETCHUM RD & UNNAMED ST",
    stop_lat: 35.079496,
    stop_lon: -89.981699,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7057,
    stop_code: "KETKETWN",
    stop_name: "KETCHUM@KETCHUM CV",
    stop_desc: "0 KETCHUM RD & KETCHUM COVE",
    stop_lat: 35.079435,
    stop_lon: -89.983889,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7058,
    stop_code: "PERMONWN",
    stop_name: "PERSON@MONTSARRAT",
    stop_desc: "0 W PERSON AVE & MONSARRAT ST",
    stop_lat: 35.101845,
    stop_lon: -90.066221,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7059,
    stop_code: "PERHANWN",
    stop_name: "PERSON@HANAUER",
    stop_desc: "0 W PERSON AVE & HANAUER ST",
    stop_lat: 35.102006,
    stop_lon: -90.069982,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7060,
    stop_code: "PERSWIWN",
    stop_name: "PERSON@SWIFT",
    stop_desc: "0 W PERSON AVE & HANAUER ST",
    stop_lat: 35.102131,
    stop_lon: -90.073101,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7061,
    stop_code: "MALLATEF",
    stop_name: "MALLORY@LATHAM",
    stop_desc: "0 E MALLORY AVE & HANAUER ST",
    stop_lat: 35.086374,
    stop_lon: -90.051755,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7062,
    stop_code: "MALWALEF",
    stop_name: "MALLORY@WELLINGTON",
    stop_desc: "0 E MALLORY AVE & S LAUDERDALE ST",
    stop_lat: 35.086284,
    stop_lon: -90.047021,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7063,
    stop_code: "MALWELWF",
    stop_name: "MALLORY@WELLINGTON",
    stop_desc: "0 E MALLORY AVE & S WELLINGTON ST",
    stop_lat: 35.086453,
    stop_lon: -90.047497,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7064,
    stop_code: "MALLATWF",
    stop_name: "MALLORY@LATHAM",
    stop_desc: "0 E MALLORY AVE & LATHAM ST",
    stop_lat: 35.086544,
    stop_lon: -90.052463,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7065,
    stop_code: "ELMSPIEN",
    stop_name: "ELMORE@SPICER CV",
    stop_desc: "0 ELMORE RD & LATHAM ST",
    stop_lat: 35.185053,
    stop_lon: -89.890882,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7066,
    stop_code: "RALROCEF",
    stop_name: "RALEIGH LAGRANGE@ROCK RIDGE",
    stop_desc: "0 RALEIGH LAGRANGE RD & ROCK RIDGE RD",
    stop_lat: 35.185141,
    stop_lon: -89.883993,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7067,
    stop_code: "RALBAREF",
    stop_name: "RALEIGH LAGRANGE@BARTLETT",
    stop_desc: "0 RALEIGH LAGRANGE RD & BARTLETT RD",
    stop_lat: 35.184472,
    stop_lon: -89.879654,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7068,
    stop_code: "RALPENEF",
    stop_name: "RALEIGH LAGRANGE@PENNINGTON GAP",
    stop_desc: "0 RALEIGH LAGRANGE RD & E RIVER TRACE DR",
    stop_lat: 35.183133,
    stop_lon: -89.875084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7069,
    stop_code: "SYCRALSF",
    stop_name: "SYCAMORE VIEW@RALEIGH LAGRANGE",
    stop_desc: "0 SYCAMORE VIEW RD & E RIVER TRACE DR",
    stop_lat: 35.182314,
    stop_lon: -89.871564,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7070,
    stop_code: "SYCSYCSM",
    stop_name: "SYCAMORE VIEW@SYCAMORE RIDGE",
    stop_desc: "0 SYCAMORE VIEW RD & E RIVER TRACE DR",
    stop_lat: 35.17773,
    stop_lon: -89.87172,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7071,
    stop_code: "SYCPLESF",
    stop_name: "SYCAMORE VIEW@PLEASANT VIEW",
    stop_desc: "0 SYCAMORE VIEW RD & RAMP",
    stop_lat: 35.172927,
    stop_lon: -89.869319,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7072,
    stop_code: "SYCSUMNF",
    stop_name: "SYCAMORE VIEW@SUMMER",
    stop_desc: "0 SYCAMORE VIEW RD & SUMMER AVE",
    stop_lat: 35.172256,
    stop_lon: -89.868501,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7073,
    stop_code: "SYCSYCNF",
    stop_name: "SYCAMORE VIEW@SYCAMORE HEIGHTS",
    stop_desc: "0 SYCAMORE VIEW RD & SYCAMORE HEIGHTS LN",
    stop_lat: 35.17689,
    stop_lon: -89.871346,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7074,
    stop_code: "SYCSYWNF",
    stop_name: "SYCAMORE VIEW@SYCAMORE WOODS",
    stop_desc: "0 SYCAMORE VIEW RD & DRIVEWAY",
    stop_lat: 35.180669,
    stop_lon: -89.871259,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7075,
    stop_code: "RALSYCWF",
    stop_name: "RALEIGH LAGRANGE@SYCAMORE VIEW",
    stop_desc: "0 RALEIGH LAGRANGE RD & SYCAMORE VIEW RD",
    stop_lat: 35.18294,
    stop_lon: -89.871762,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7076,
    stop_code: "RALRIVWF",
    stop_name: "RALEIGH LAGRANGE@RIVER TRACE",
    stop_desc: "0 RALEIGH LAGRANGE RD & E RIVER TRACE DR",
    stop_lat: 35.183402,
    stop_lon: -89.875714,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7077,
    stop_code: "RALBARWF",
    stop_name: "RALEIGH LAGRANGE@BARTLETT",
    stop_desc: "0 RALEIGH LAGRANGE RD & E RIVER TRACE DR",
    stop_lat: 35.184732,
    stop_lon: -89.880074,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7078,
    stop_code: "RALROCWF",
    stop_name: "RALEIGH LAGRANGE@ROCK RIDGE",
    stop_desc: "0 RALEIGH LAGRANGE RD & ROCK RIDGE RD",
    stop_lat: 35.18549,
    stop_lon: -89.884435,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7079,
    stop_code: "ELMRALWF",
    stop_name: "ELMORE@RALEIGH LAGRANGE",
    stop_desc: "0 ELMORE RD & RALEIGH LAGRANGE RD",
    stop_lat: 35.186476,
    stop_lon: -89.886814,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7080,
    stop_code: "ELMCOVWN",
    stop_name: "ELMORE@COVINGTON WAY",
    stop_desc: "0 ELMORE RD & BUSINESS CENTER DR",
    stop_lat: 35.185449,
    stop_lon: -89.893095,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7081,
    stop_code: "TCHBESNF",
    stop_name: "TCHULAHOMA@BESTWAY",
    stop_desc: "0 TCHULAHOMA RD & BESTWAY DR",
    stop_lat: 35.016812,
    stop_lon: -89.954478,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7082,
    stop_code: "TCHSHENF",
    stop_name: "TCHULAHOMA@SHELBY",
    stop_desc: "0 TCHULAHOMA RD & DRIVEWAY",
    stop_lat: 35.021093,
    stop_lon: -89.954505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7083,
    stop_code: "ARNSHEEF",
    stop_name: "ARNOLD@SHELBY",
    stop_desc: "0 ARNOLD RD & TCHULAHOMA RD",
    stop_lat: 35.027894,
    stop_lon: -89.954406,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7084,
    stop_code: "ARNSUMEF",
    stop_name: "ARNOLD@SUMNERS WELLS",
    stop_desc: "0 ARNOLD RD & ARNOLD COVE",
    stop_lat: 35.0279,
    stop_lon: -89.950649,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7085,
    stop_code: "ARNOLDEF",
    stop_name: "ARNOLD@OLD QUARRY",
    stop_desc: "0 ARNOLD RD & OLD QUARRY RD",
    stop_lat: 35.027895,
    stop_lon: -89.946106,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7086,
    stop_code: "ELMWILNF",
    stop_name: "ELMRIDGE@WILLARD",
    stop_desc: "0 ELMRIDGE ST & WILLARD DR",
    stop_lat: 35.029896,
    stop_lon: -89.945219,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7087,
    stop_code: "CHRPREWF",
    stop_name: "CHRISTINE@PRESCOTT",
    stop_desc: "0 CHRISTINE RD & PRESCOTT RD",
    stop_lat: 35.043328,
    stop_lon: -89.944114,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7088,
    stop_code: "TCHCHRNF",
    stop_name: "TCHULAHOMA@CHRISTINE",
    stop_desc: "0 TCHULAHOMA RD & KENLAND DR",
    stop_lat: 35.043402,
    stop_lon: -89.954703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7094,
    stop_code: "HIGSPOSF",
    stop_name: "HIGHLAND@SPOTSWOOD",
    stop_desc: "0 S HIGHLAND ST & KEARNEY AVE",
    stop_lat: 35.112898,
    stop_lon: -89.945947,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7095,
    stop_code: "THICLINF",
    stop_name: "THIRD@CLINTON",
    stop_desc: "0 S 3RD ST & CLINTON RD",
    stop_lat: 35.007846,
    stop_lon: -90.101337,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7096,
    stop_code: "THIWEANF",
    stop_name: "THIRD@WEAVER",
    stop_desc: "0 S 3RD ST & DRIVEWAY",
    stop_lat: 35.012262,
    stop_lon: -90.096245,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7097,
    stop_code: "THIDELNN",
    stop_name: "THIRD@DELTA",
    stop_desc: "0 S 3RD ST & DELTA RD",
    stop_lat: 35.019798,
    stop_lon: -90.088678,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7098,
    stop_code: "THISHENF",
    stop_name: "THIRD@SHELBY",
    stop_desc: "0 S 3RD ST & RAMP",
    stop_lat: 35.021479,
    stop_lon: -90.08701,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7099,
    stop_code: "THIFORNM",
    stop_name: "THIRD@FORD",
    stop_desc: "0 S 3RD ST & WESTVIEW RD",
    stop_lat: 35.025341,
    stop_lon: -90.083154,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7100,
    stop_code: "THIWESNF",
    stop_name: "THIRD@WESTERN PARK",
    stop_desc: "0 S 3RD ST & DRIVEWAY",
    stop_lat: 35.032098,
    stop_lon: -90.076381,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7101,
    stop_code: "COLRICNM",
    stop_name: "COLEMAN@RICHE",
    stop_desc: "0 COLEMAN RD",
    stop_lat: 35.217896,
    stop_lon: -89.917804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7102,
    stop_code: "NONDIVEF",
    stop_name: "NONCONNAH@DIVIDEND",
    stop_desc: "0 NONCONNAH BLVD",
    stop_lat: 35.071531,
    stop_lon: -90.005515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7103,
    stop_code: "NONPA2EF",
    stop_name: "NONCONNAH@PARAGON",
    stop_desc: "0 NONCONNAH BLVD",
    stop_lat: 35.071858,
    stop_lon: -89.999699,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7104,
    stop_code: "NONCOREN",
    stop_name: "NONCONNAH@CORPORATE",
    stop_desc: "0 NONCONNAH BLVD",
    stop_lat: 35.073355,
    stop_lon: -89.992676,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7107,
    stop_code: "SECMONSF",
    stop_name: "SECOND@MONROE",
    stop_desc: "0 S 2ND ST & ESCAPE ALY",
    stop_lat: 35.143695,
    stop_lon: -90.052129,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7108,
    stop_code: "HAWSUNEN",
    stop_name: "HAWKINS MILL@SUNCREST",
    stop_desc: "0 HAWKINS MILL RD & SUNCREST DR",
    stop_lat: 35.244436,
    stop_lon: -89.979936,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7109,
    stop_code: "RANHAWSF",
    stop_name: "RANGE LINE@HAWKINS MILL",
    stop_desc: "0 RANGE LINE RD & DRIVEWAY",
    stop_lat: 35.241377,
    stop_lon: -89.975329,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7111,
    stop_code: "NORWAREF",
    stop_name: "NORRIS@WARREN",
    stop_desc: "0 NORRIS RD & WARREN ST",
    stop_lat: 35.083217,
    stop_lon: -90.015924,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7112,
    stop_code: "BALSILEN",
    stop_name: "BALL@SILVER",
    stop_desc: "0 BALL RD & WARREN ST",
    stop_lat: 35.082,
    stop_lon: -90.013425,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7113,
    stop_code: "SPAGAUNN",
    stop_name: "SPARKS@GAUSCO",
    stop_desc: "0 SPARKS ST & GAUSCO RD",
    stop_lat: 35.080302,
    stop_lon: -90.012342,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7114,
    stop_code: "BALSILWN",
    stop_name: "BALL@SILVER",
    stop_desc: "0 BALL RD & SILVER ST",
    stop_lat: 35.082125,
    stop_lon: -90.013126,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7115,
    stop_code: "NORWARWN",
    stop_name: "NORRIS@WARREN",
    stop_desc: "0 NORRIS RD & WARREN ST",
    stop_lat: 35.083378,
    stop_lon: -90.016299,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7116,
    stop_code: "ALCMANWN",
    stop_name: "ALCY@MANCHESTER",
    stop_desc: "0 E ALCY RD & MANCHESTER RD",
    stop_lat: 35.077823,
    stop_lon: -90.005842,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7117,
    stop_code: "ALCPERWM",
    stop_name: "ALCY@PERRY",
    stop_desc: "0 E ALCY RD & KEEN RD",
    stop_lat: 35.077968,
    stop_lon: -90.010398,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7118,
    stop_code: "CENTRSTA",
    stop_name: "CENTRAL STATION",
    stop_desc: "",
    stop_lat: 35.131935,
    stop_lon: -90.059026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7122,
    stop_code: "TCHMELNF",
    stop_name: "TCHULAHOMA@MELODYWOOD",
    stop_desc: "0 TCHULAHOMA RD & MELODYWOOD DR",
    stop_lat: 35.014309,
    stop_lon: -89.954471,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7130,
    stop_code: "FROGEPNM",
    stop_name: "FRONT@FARMERS MARKET",
    stop_desc: "0 S FRONT ST & E GE PATTERSON AVE",
    stop_lat: 35.131757,
    stop_lon: -90.06073,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7133,
    stop_code: "DIRBRONF",
    stop_name: "DIRECTORS ROW@BROOKS",
    stop_desc: "0 DIRECTORS ROW & EXECUTIVE COURT DR",
    stop_lat: 35.061827,
    stop_lon: -89.994854,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7134,
    stop_code: "DIRAIREN",
    stop_name: "DIRECTORS ROW@AIRWAYS",
    stop_desc: "0 DIRECTORS ROW & AIRWAYS BLVD",
    stop_lat: 35.064461,
    stop_lon: -89.990893,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7135,
    stop_code: "JAMWOOWN",
    stop_name: "JAMES@WOODLAWN TER",
    stop_desc: "0 JAMES RD & BEAVERBROOK RD",
    stop_lat: 35.197624,
    stop_lon: -89.997402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7136,
    stop_code: "JAMHIGWF",
    stop_name: "JAMES@HIGHLAND",
    stop_desc: "0 STAGE JAMES RD & HIGHLAND RD",
    stop_lat: 35.199188,
    stop_lon: -89.944966,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7137,
    stop_code: "JAMWALWN",
    stop_name: "JAMES@WALNUT",
    stop_desc: "0 STAGE JAMES RD & DRIVEWAY",
    stop_lat: 35.199246,
    stop_lon: -89.94892,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7138,
    stop_code: "POPEBREN",
    stop_name: "POPLAR@E BROOKHAVEN",
    stop_desc: "0 POPLAR AVE & W BROOKHAVEN CIR",
    stop_lat: 35.109939,
    stop_lon: -89.890061,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7141,
    stop_code: "THIWPASF",
    stop_name: "THIRD@WESTERN PARK",
    stop_desc: "0 S 3RD ST & RAMP",
    stop_lat: 35.030721,
    stop_lon: -90.078027,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7142,
    stop_code: "THIBLANN",
    stop_name: "THIRD@BLANCHE",
    stop_desc: "0 S 3RD ST & BLANCHE RD",
    stop_lat: 35.028308,
    stop_lon: -90.080193,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7143,
    stop_code: "THIBLASN",
    stop_name: "THIRD@BLANCHE",
    stop_desc: "0 S 3RD ST & DRIVEWAY",
    stop_lat: 35.028549,
    stop_lon: -90.080215,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7145,
    stop_code: "MENRAINF",
    stop_name: "MENDENHALL@RAINES",
    stop_desc: "0 S MENDENHALL RD",
    stop_lat: 35.0355,
    stop_lon: -89.884576,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7146,
    stop_code: "HICSHENF",
    stop_name: "HICKORY HILL@SHELBY",
    stop_desc: "0 HICKORY HILL RD & PARKING LOT",
    stop_lat: 35.020642,
    stop_lon: -89.866158,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7147,
    stop_code: "RAIHICEF",
    stop_name: "RAINES RD@HICKORY HILL RD",
    stop_desc: "0 E RAINES RD & HICKORY HILL RD",
    stop_lat: 35.0348,
    stop_lon: -89.866131,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7148,
    stop_code: "KIRRAINF",
    stop_name: "KIRBY@RAINES",
    stop_desc: "0 KIRBY PKY & FREELARK LN",
    stop_lat: 35.035257,
    stop_lon: -89.847451,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7149,
    stop_code: "RIVRAISF",
    stop_name: "RIVERDALE@RAINES",
    stop_desc: "0 RIVERDALE RD & E RAINES RD",
    stop_lat: 35.034699,
    stop_lon: -89.831516,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7151,
    stop_code: "HOLHICWF",
    stop_name: "HOLMES@HICKORY HILL",
    stop_desc: "0 E HOLMES RD & HICKORY HILL RD",
    stop_lat: 35.005892,
    stop_lon: -89.86475,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7152,
    stop_code: "HOLLAMWF",
    stop_name: "HOLMES@LAMAR",
    stop_desc: "0 E HOLMES RD & RAMP",
    stop_lat: 35.006075,
    stop_lon: -89.888658,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7153,
    stop_code: "SWICHRSF",
    stop_name: "SWINNEA@CHRISTINE",
    stop_desc: "0 QUEENSGATE AVE & CHRISTINE RD",
    stop_lat: 35.044626,
    stop_lon: -89.964296,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7156,
    stop_code: "WINBIGWF",
    stop_name: "WINCHESTER@BISHOPS GATE",
    stop_desc: "0 WINCHESTER RD & EMERALD ST",
    stop_lat: 35.05047,
    stop_lon: -89.878637,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7157,
    stop_code: "AIRPRESM",
    stop_name: "AIR PARK@PREMIER",
    stop_desc: "0 AIR PARK ST & REGAL BLVD",
    stop_lat: 35.046728,
    stop_lon: -89.931374,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7158,
    stop_code: "AIRPRESF",
    stop_name: "AIRPARK@PREMIER",
    stop_desc: "0 AIR PARK ST & AIR PARK COVE",
    stop_lat: 35.043877,
    stop_lon: -89.931357,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7159,
    stop_code: "AIRAIRSM",
    stop_name: "AIR PARK@AIR PARK CV",
    stop_desc: "0 AIR PARK ST & PREMIER AVE",
    stop_lat: 35.041616,
    stop_lon: -89.931339,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7160,
    stop_code: "AIRRAISM",
    stop_name: "AIR PARK@RAINES",
    stop_desc: "0 AIR PARK ST & AIR PARK COVE",
    stop_lat: 35.038711,
    stop_lon: -89.931333,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7161,
    stop_code: "AIPREGNM",
    stop_name: "AIR PARK@REGAL",
    stop_desc: "0 AIR PARK ST & PREMIER AVE",
    stop_lat: 35.047335,
    stop_lon: -89.931185,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7162,
    stop_code: "RIVWINSN",
    stop_name: "RIVERDALE BEND@WINCHESTER",
    stop_desc: "",
    stop_lat: 35.048982,
    stop_lon: -89.823402,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7163,
    stop_code: "PELCANNF",
    stop_name: "PELICAN LN@CANARY LN",
    stop_desc: "",
    stop_lat: 35.046582,
    stop_lon: -90.102138,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7164,
    stop_code: "STADAREN",
    stop_name: "STAGE@DAROLYN",
    stop_desc: "0 STAGE RD & DAROLYN ST",
    stop_lat: 35.204681,
    stop_lon: -89.85118,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7166,
    stop_code: "LAMLYNNN",
    stop_name: "LAMAR@LYNHURST",
    stop_desc: "915 LAMAR AVE & LYNHURST AVE",
    stop_lat: 35.066385,
    stop_lon: -89.942429,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7167,
    stop_code: "PAUUNINF",
    stop_name: "PAULINE@UNION",
    stop_desc: "915 S PAULINE ST & LYNHURST AVE",
    stop_lat: 35.13822,
    stop_lon: -90.028103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7168,
    stop_code: "PAUMADNM",
    stop_name: "PAULINE@MADISON",
    stop_desc: "0 S PAULINE ST & MADISON AVE",
    stop_lat: 35.139641,
    stop_lon: -90.028125,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7169,
    stop_code: "PAUMADSF",
    stop_name: "PAULINE@MADISON",
    stop_desc: "0 S PAULINE ST & MADISON AVE",
    stop_lat: 35.139873,
    stop_lon: -90.02829,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7170,
    stop_code: "PAUUNISF",
    stop_name: "PAULINE@UNION",
    stop_desc: "0 S PAULINE ST & DRIVEWAY",
    stop_lat: 35.137729,
    stop_lon: -90.028303,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7171,
    stop_code: "TILBRONF",
    stop_name: "TILLMAN@BROAD",
    stop_desc: "0 TILLMAN ST & ALCORN AVE",
    stop_lat: 35.147838,
    stop_lon: -89.961199,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7172,
    stop_code: "VANBRONM",
    stop_name: "VANDALIA@BROAD",
    stop_desc: "0 VANDALIA ST & SUMMER AVE",
    stop_lat: 35.148164,
    stop_lon: -89.956815,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7173,
    stop_code: "BROVANWF",
    stop_name: "BROAD@VANDALIA",
    stop_desc: "0 BROAD AVE & SUMMER AVE",
    stop_lat: 35.147387,
    stop_lon: -89.957104,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7174,
    stop_code: "BROTILWM",
    stop_name: "BROAD@TILLMAN",
    stop_desc: "0 BROAD AVE & RAMP",
    stop_lat: 35.147372,
    stop_lon: -89.960115,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7175,
    stop_code: "TILJOHSF",
    stop_name: "TILLMAN@JOHNSON",
    stop_desc: "0 TILLMAN ST & HALE AVE",
    stop_lat: 35.141591,
    stop_lon: -89.961551,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7178,
    stop_code: "PERCROWN",
    stop_name: "PERKINS@CROMWELL",
    stop_desc: "0 S PERKINS RD & CONNECTING RD",
    stop_lat: 35.046457,
    stop_lon: -89.90804,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7179,
    stop_code: "PERKN2SN",
    stop_name: "PERKINS@KNIGHT",
    stop_desc: "0 S PERKINS RD & KNIGHT RD",
    stop_lat: 35.042019,
    stop_lon: -89.910746,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7180,
    stop_code: "RAIDELWN",
    stop_name: "RAINES@DELP",
    stop_desc: "0 E RAINES RD & DELP ST",
    stop_lat: 35.037243,
    stop_lon: -89.922074,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7181,
    stop_code: "RAIHOMWN",
    stop_name: "RAINES@HOMEWOOD",
    stop_desc: "0 E RAINES RD & CONNECTING RD",
    stop_lat: 35.037167,
    stop_lon: -89.92571,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7182,
    stop_code: "RAISENWN",
    stop_name: "RAINES@SENATOR",
    stop_desc: "0 E RAINES RD & RUSKIN RD",
    stop_lat: 35.036962,
    stop_lon: -89.933989,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7183,
    stop_code: "RAIGETWF",
    stop_name: "RAINES@GETWELL",
    stop_desc: "0 E RAINES RD & HATCHER CIR",
    stop_lat: 35.036931,
    stop_lon: -89.937703,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7184,
    stop_code: "TILWAYNF",
    stop_name: "TILLMAN@WAYNOKA",
    stop_desc: "0 TILLMAN ST & WAYNOKA AVE",
    stop_lat: 35.137329,
    stop_lon: -89.96159,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7185,
    stop_code: "TILJAYSN",
    stop_name: "TILLMAN@JAY",
    stop_desc: "0 TILLMAN ST & JAY AVE",
    stop_lat: 35.136319,
    stop_lon: -89.962189,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7186,
    stop_code: "PRERAINF",
    stop_name: "PRESCOTT@RAINES",
    stop_desc: "0 PRESCOTT RD & E RAINES RD",
    stop_lat: 35.037134,
    stop_lon: -89.943483,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7187,
    stop_code: "RAIHOMEM",
    stop_name: "RAINES@HOMEWOOD",
    stop_desc: "0 E RAINES RD & CONNECTING RD",
    stop_lat: 35.036803,
    stop_lon: -89.927977,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7188,
    stop_code: "RAIDELEF",
    stop_name: "RAINES@DELP",
    stop_desc: "0 E RAINES RD & DELP ST",
    stop_lat: 35.036922,
    stop_lon: -89.922704,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7189,
    stop_code: "RAIHUNEN",
    stop_name: "RAINES@HUNGERFORD",
    stop_desc: "0 E RAINES RD & DELP ST",
    stop_lat: 35.040628,
    stop_lon: -89.913346,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7190,
    stop_code: "PERKN1NF",
    stop_name: "PERKINS@KNIGHT",
    stop_desc: "0 S PERKINS RD & DELP ST",
    stop_lat: 35.041705,
    stop_lon: -89.910227,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7191,
    stop_code: "PERCRONN",
    stop_name: "PERKINS@CROMWELL",
    stop_desc: "0 S PERKINS RD & CROMWELL AVE",
    stop_lat: 35.046287,
    stop_lon: -89.907664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7192,
    stop_code: "OUTWINSF",
    stop_name: "OUTLAND@WINCHESTER",
    stop_desc: "0 OUTLAND RD & BOXDALE ST",
    stop_lat: 35.050667,
    stop_lon: -89.901851,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7193,
    stop_code: "OUTNEWSN",
    stop_name: "OUTLAND@NEWGATE",
    stop_desc: "0 OUTLAND RD & FOXHALL DR",
    stop_lat: 35.048155,
    stop_lon: -89.901835,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7194,
    stop_code: "OUTCROSF",
    stop_name: "OUTLAND@CROMWELL",
    stop_desc: "0 OUTLAND RD & CROMWELL AVE",
    stop_lat: 35.046064,
    stop_lon: -89.901817,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7195,
    stop_code: "OUTWARSF",
    stop_name: "OUTLAND@WARRINGTON",
    stop_desc: "0 OUTLAND RD & WARRINGTON RD",
    stop_lat: 35.042471,
    stop_lon: -89.901781,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7196,
    stop_code: "OUTRAISM",
    stop_name: "OUTLAND@RAINES",
    stop_desc: "0 OUTLAND RD & UNNAMED ST",
    stop_lat: 35.037984,
    stop_lon: -89.901813,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7197,
    stop_code: "RAIOUTEF",
    stop_name: "RAINES@OUTLAND",
    stop_desc: "0 E RAINES RD & CONNECTING RD",
    stop_lat: 35.035087,
    stop_lon: -89.90078,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7198,
    stop_code: "RAICHREF",
    stop_name: "RAINES@CHRYSLER",
    stop_desc: "0 E RAINES RD & CHRYSLER DR",
    stop_lat: 35.035063,
    stop_lon: -89.896945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7199,
    stop_code: "RAIWILEN",
    stop_name: "RAINES@WILLOW LAKE",
    stop_desc: "0 E RAINES RD & WILLOW LAKE BLVD",
    stop_lat: 35.035007,
    stop_lon: -89.889341,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7200,
    stop_code: "MENSHANM",
    stop_name: "MENDENHALL@SHARP PLAZA",
    stop_desc: "0 S MENDENHALL RD & WILLOW LAKE BLVD",
    stop_lat: 35.040167,
    stop_lon: -89.885272,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7201,
    stop_code: "MENFLONN",
    stop_name: "MENDENHALL@FLOWERING PEACH",
    stop_desc: "0 S MENDENHALL RD & FLOWERING PEACH DR",
    stop_lat: 35.044288,
    stop_lon: -89.885848,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7202,
    stop_code: "MENWINNM",
    stop_name: "MENDENHALL@WINCHESTER",
    stop_desc: "0 S MENDENHALL RD & FLOWERING PEACH DR",
    stop_lat: 35.047702,
    stop_lon: -89.885951,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7203,
    stop_code: "HICFRENF",
    stop_name: "HICKORY HILL@FREEPORT",
    stop_desc: "0 HICKORY HILL RD & FREEPORT AVE",
    stop_lat: 35.010843,
    stop_lon: -89.864294,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7204,
    stop_code: "HICDISNM",
    stop_name: "HICKORY HILL@DISTRIBUTION",
    stop_desc: "0 HICKORY HILL RD & FREEPORT AVE",
    stop_lat: 35.015046,
    stop_lon: -89.865664,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7206,
    stop_code: "HICHICNN",
    stop_name: "HICKORY HILL@HICKORY CMMS",
    stop_desc: "0 HICKORY HILL RD & HICKORY CMNS",
    stop_lat: 35.024244,
    stop_lon: -89.866181,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7207,
    stop_code: "HICASHNN",
    stop_name: "HICKORY HILL@ASHRIDGE",
    stop_desc: "0 HICKORY HILL RD & ASHRIDGE PL",
    stop_lat: 35.027176,
    stop_lon: -89.866218,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7208,
    stop_code: "HICRIDNN",
    stop_name: "HICKORY HILL@RIDGEHILL",
    stop_desc: "0 HICKORY HILL RD & BLACKROCK LN",
    stop_lat: 35.030581,
    stop_lon: -89.866264,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7209,
    stop_code: "HICRAINM",
    stop_name: "HICKORY HILL@RAINES",
    stop_desc: "0 HICKORY HILL RD & E RAINES RD",
    stop_lat: 35.033745,
    stop_lon: -89.866311,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7210,
    stop_code: "RAIHICEN",
    stop_name: "RAINES@HICKORY GROVE",
    stop_desc: "0 E RAINES RD & HICKORY GROVE DR",
    stop_lat: 35.034821,
    stop_lon: -89.863026,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7211,
    stop_code: "RAICRUEF",
    stop_name: "RAINES@CRUMP",
    stop_desc: "0 E RAINES RD & CRUMP RD",
    stop_lat: 35.034859,
    stop_lon: -89.859522,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7212,
    stop_code: "RAIROSEN",
    stop_name: "RAINES@ROSEWIND",
    stop_desc: "0 E RAINES RD & ROSEWIND CIR",
    stop_lat: 35.034898,
    stop_lon: -89.856084,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7213,
    stop_code: "RAIPAREN",
    stop_name: "RAINES@PARK FOREST",
    stop_desc: "0 E RAINES RD & PARK FOREST DR",
    stop_lat: 35.03491,
    stop_lon: -89.85268,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7214,
    stop_code: "RAIFRIEF",
    stop_name: "RAINES@FRIENDLY WAY",
    stop_desc: "0 E RAINES RD & FRIENDLY WAY",
    stop_lat: 35.034895,
    stop_lon: -89.849453,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7215,
    stop_code: "KIRTIMNN",
    stop_name: "KIRBY@TIMBER TRAIL",
    stop_desc: "0 KIRBY PKY & DRIVEWAY",
    stop_lat: 35.038537,
    stop_lon: -89.846945,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7216,
    stop_code: "KIRBIRNN",
    stop_name: "KIRBY@BIRCH RUN",
    stop_desc: "0 KIRBY PKY & DRIVEWAY",
    stop_lat: 35.040394,
    stop_lon: -89.846077,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7217,
    stop_code: "KIRDEENF",
    stop_name: "KIRBY@DEER FOREST",
    stop_desc: "0 KIRBY PKY & DEER FOREST DR",
    stop_lat: 35.044428,
    stop_lon: -89.843103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7218,
    stop_code: "KIRWINNM",
    stop_name: "KIRBY@WINCHESTER",
    stop_desc: "0 KIRBY PKY & DEER FOREST DR",
    stop_lat: 35.048469,
    stop_lon: -89.843622,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7219,
    stop_code: "RIVRAINF",
    stop_name: "RIVERDALE@RAINES",
    stop_desc: "0 RIVERDALE RD & E RAINES RD",
    stop_lat: 35.035252,
    stop_lon: -89.83116,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7220,
    stop_code: "RIVCHENN",
    stop_name: "RIVERDALE@CHESAPEAKE",
    stop_desc: "0 RIVERDALE RD & CHESAPEAKE WAY",
    stop_lat: 35.038434,
    stop_lon: -89.831161,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7221,
    stop_code: "RIVBIRNF",
    stop_name: "RIVERDALE@BIRCH RUN",
    stop_desc: "0 RIVERDALE RD & CHESAPEAKE WAY",
    stop_lat: 35.041455,
    stop_lon: -89.83103,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7222,
    stop_code: "RIVSTANN",
    stop_name: "RIVERDALE@STARKENBURG",
    stop_desc: "0 RIVERDALE RD & STARKENBURG LN",
    stop_lat: 35.045583,
    stop_lon: -89.830387,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7223,
    stop_code: "RIVWINNM",
    stop_name: "RIVERDALE@WINCHESTER",
    stop_desc: "0 RIVERDALE RD & MALCO XING",
    stop_lat: 35.048272,
    stop_lon: -89.829959,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7224,
    stop_code: "RIVRIVNN",
    stop_name: "RIVERDALE@RIVERDALE BEND",
    stop_desc: "0 RIVERDALE RD & CONNECTING RD",
    stop_lat: 35.052069,
    stop_lon: -89.82935,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7225,
    stop_code: "KIRWINSF",
    stop_name: "KIRBY@WINCHESTER",
    stop_desc: "0 KIRBY PKY & WINCHESTER RD",
    stop_lat: 35.049318,
    stop_lon: -89.843907,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7226,
    stop_code: "KIRDEESN",
    stop_name: "KIRBY@DEER CREEK",
    stop_desc: "0 KIRBY PKY & WINCHESTER RD",
    stop_lat: 35.046493,
    stop_lon: -89.843473,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7227,
    stop_code: "KIRCOUSN",
    stop_name: "KIRBY@COUNTRY OAKS",
    stop_desc: "0 KIRBY PKY & WINCHESTER RD",
    stop_lat: 35.043652,
    stop_lon: -89.843779,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7228,
    stop_code: "KIRWILSN",
    stop_name: "KIRBY@WILDWIND",
    stop_desc: "0 KIRBY PKY & WILDWIND DR",
    stop_lat: 35.041073,
    stop_lon: -89.845998,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7229,
    stop_code: "KIRTIMSF",
    stop_name: "KIRBY@TIMBER TRAIL",
    stop_desc: "0 KIRBY PKY & WILDWIND DR",
    stop_lat: 35.038457,
    stop_lon: -89.847332,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7230,
    stop_code: "HICDISSF",
    stop_name: "HICKORY HILL@DISTRIBUTION",
    stop_desc: "0 HICKORY HILL RD & DISTRIBUTION DR",
    stop_lat: 35.01822,
    stop_lon: -89.86633,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7231,
    stop_code: "HICGROSN",
    stop_name: "HICKORY HILL@GROCERY",
    stop_desc: "0 HICKORY HILL RD & GROCERY RD",
    stop_lat: 35.008931,
    stop_lon: -89.864487,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7232,
    stop_code: "HOLSIMWN",
    stop_name: "HOLMES@SIMS RD",
    stop_desc: "0 E HOLMES RD & GROCERY RD",
    stop_lat: 35.005929,
    stop_lon: -89.870307,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7233,
    stop_code: "RIVWINSF",
    stop_name: "RIVERDALE@WINCHESTER",
    stop_desc: "0 RIVERDALE RD & GROCERY RD",
    stop_lat: 35.048907,
    stop_lon: -89.830244,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7234,
    stop_code: "RIVSTASN",
    stop_name: "RIVERDALE@STARKENBURG",
    stop_desc: "0 RIVERDALE RD & STARKENBURG LN",
    stop_lat: 35.045771,
    stop_lon: -89.83073,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7235,
    stop_code: "RIVBIRSN",
    stop_name: "RIVERDALE@BIRCH RUN",
    stop_desc: "0 RIVERDALE RD & BIRCH RUN LN",
    stop_lat: 35.041348,
    stop_lon: -89.831418,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7236,
    stop_code: "RIVCHESF",
    stop_name: "RIVERDALE@CHESAPEAKE",
    stop_desc: "0 RIVERDALE RD & CLUB RIDGE CIR",
    stop_lat: 35.038426,
    stop_lon: -89.831515,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7237,
    stop_code: "RIVFOXSN",
    stop_name: "RIVERDALE@FOX HEDGE",
    stop_desc: "0 RIVERDALE RD & FOX HEDGE",
    stop_lat: 35.031043,
    stop_lon: -89.831505,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7238,
    stop_code: "RIVROCSN",
    stop_name: "RIVERDALE@ROCKINGHAM",
    stop_desc: "0 RIVERDALE RD & ROCKINGHAM RD",
    stop_lat: 35.027754,
    stop_lon: -89.831493,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7239,
    stop_code: "RIVBORSN",
    stop_name: "RIVERDALE@BORDEAUX",
    stop_desc: "0 RIVERDALE RD & BORDEAUX LN",
    stop_lat: 35.02442,
    stop_lon: -89.831459,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7240,
    stop_code: "SHETCHWF",
    stop_name: "SHELBY@TCHULAHOMA",
    stop_desc: "0 E SHELBY DR & TCHULAHOMA RD",
    stop_lat: 35.020852,
    stop_lon: -89.954925,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7241,
    stop_code: "SHEMACWN",
    stop_name: "SHELBY@MARTHA COLE",
    stop_desc: "0 E SHELBY DR & DRIVEWAY",
    stop_lat: 35.020883,
    stop_lon: -89.959312,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7242,
    stop_code: "ARNPREWN",
    stop_name: "ARNOLD@PRESCOTT",
    stop_desc: "0 ARNOLD RD & PRESCOTT RD",
    stop_lat: 35.028053,
    stop_lon: -89.943598,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7243,
    stop_code: "ARNPREEF",
    stop_name: "ARNOLD@PRESCOTT",
    stop_desc: "0 ARNOLD RD & PRESCOTT RD",
    stop_lat: 35.027892,
    stop_lon: -89.943465,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7244,
    stop_code: "RAIOPPEN",
    stop_name: "RAINES@OPPORTUNITY",
    stop_desc: "0 W RAINES RD & DRIVEWAY",
    stop_lat: 35.035717,
    stop_lon: -90.117231,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7245,
    stop_code: "RAIRI2EN",
    stop_name: "RAINES@RIDGE",
    stop_desc: "0 W RAINES RD & DRIVEWAY",
    stop_lat: 35.035782,
    stop_lon: -90.110323,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7246,
    stop_code: "RAIDOUWN",
    stop_name: "RAINES@DOUBLE TREE",
    stop_desc: "0 W RAINES RD & DOUBLE TREE RD",
    stop_lat: 35.035999,
    stop_lon: -90.103924,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7247,
    stop_code: "SWIWINSF",
    stop_name: "SWINNEA@WINCHESTER",
    stop_desc: "0 NANCY RD & WINCHESTER RD",
    stop_lat: 35.052133,
    stop_lon: -89.964087,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7248,
    stop_code: "SWIMEDSM",
    stop_name: "SWINNEA@MEDTRONIC",
    stop_desc: "0 SWINNEA RD & DRIVEWAY",
    stop_lat: 35.030039,
    stop_lon: -89.963819,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
  {
    stop_id: 7249,
    stop_code: "CHRWALWN",
    stop_name: "CHRISTINE@WALNUT CRK CV",
    stop_desc: "0 CHRISTINE RD & WALNUT CREEK COVE",
    stop_lat: 35.043174,
    stop_lon: -89.951023,
    zone_id: "",
    stop_url: "",
    location_type: "",
    parent_station: "",
    stop_timezone: "",
    wheelchair_boarding: 0,
  },
];
