import { action, computed, observable, runInAction } from "mobx";
import moment from "moment";
import OptimizeService from "services/OptimizeService";
import { DATE_FORMAT } from "utils/constants";
import { OPTIMIZATION_TYPE } from "utils/constants/optimizerConstant";

// Store for analysis
class HistoricTimeSpaceStore {
  constructor(parentStore) {
    this.parentStore = parentStore;
    this.moduleService = new OptimizeService(parentStore);
  }

  @observable selectedItem = null;
  @observable loading = false;
  @observable loadingActual = false;
  @observable dataPrint = [];

  @action setSelectedItem = (value) => {
    // this.selectedItem = value;
    this.selectedItem = value;
  };

  @action getDataActual = (filterObject) => {
    const shouldLoadMore =
      moment(
        this.selectedItem.actual.from_time,
        DATE_FORMAT.time_only_full
      ).valueOf() >
        moment(filterObject.from_time, DATE_FORMAT.time_only_full).valueOf() ||
      moment(
        this.selectedItem.actual.to_time,
        DATE_FORMAT.time_only_full
      ).valueOf() <
        moment(filterObject.to_time, DATE_FORMAT.time_only_full).valueOf();

    if (this.selectedItem.general_data && shouldLoadMore) {
      const from_time =
        moment(filterObject.from_time, DATE_FORMAT.time_only_full).valueOf() <
        moment(
          this.selectedItem.general_data.start_time,
          DATE_FORMAT.time_only
        ).valueOf()
          ? this.selectedItem.general_data.start_time
          : filterObject.from_time;

      const to_time =
        moment(filterObject.to_time, DATE_FORMAT.time_only_full).valueOf() >
        moment(
          this.selectedItem.general_data.end_time,
          DATE_FORMAT.time_only
        ).valueOf()
          ? this.selectedItem.general_data.end_time
          : filterObject.to_time;

      this.loadingActual = true;
      this.moduleService
        .getOneItem(this.selectedItem.general_data.id, {
          type: OPTIMIZATION_TYPE.HISTORIC_TIME_SPACE,
          from_time: from_time,
          to_time: to_time,
        })
        .then((data) => {
          runInAction(() => {
            this.selectedItem = data;
          });

          //    this.selectedItem = {}
        })
        .catch((e) => {
          console.log(e);
        })

        .finally(() => {
          runInAction(() => (this.loadingActual = false));
        });
    }
  };
  @computed get tranformDataHistoricTimeSpace() {
    if (this.selectedItem) {
      const tranformObject = {
        general_data: { ...this.selectedItem.general_data },
        actual: {
          ...this.selectedItem.actual,
          from_time: moment(
            this.selectedItem.actual.from_time,
            DATE_FORMAT.time_only_full
          ).toDate(),
          to_time: moment(
            this.selectedItem.actual.to_time,
            DATE_FORMAT.time_only_full
          ).toDate(),
          from_distance: 0,
          min_distance: 0,
          to_distance: 0,
          intersections: [],
        },
        programmed: this.selectedItem.programmed
          ? {
              ...this.selectedItem.programmed,
              from_time: moment(
                this.selectedItem.programmed.from_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
              to_time: moment(
                this.selectedItem.programmed.to_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
              from_distance: 0,
              min_distance: 0,
              to_distance: 0,
              intersections: [],
            }
          : null,
      };

      //tranform actual
      let len = this.selectedItem.actual.intersections.length;
      let currentYCoord = 0;
      let min_distance = Number.MAX_SAFE_INTEGER;
      for (let i = len - 1; i >= 0; i--) {
        const actualIntersectionItem =
          this.selectedItem.actual.intersections[i];
        min_distance =
          actualIntersectionItem.distance &&
          actualIntersectionItem.distance < min_distance
            ? actualIntersectionItem.distance
            : min_distance;
        currentYCoord += actualIntersectionItem.distance;
        const tranformActualIntersectionItem = {
          ...actualIntersectionItem,
          ring1: {
            ...actualIntersectionItem.ring1,
            vehicles: actualIntersectionItem.ring1.vehicles
              ? actualIntersectionItem.ring1.vehicles.map((el) => ({
                  time: moment(el, DATE_FORMAT.time_only_full).toDate(),
                }))
              : actualIntersectionItem.ring1.vehicle_points.map((el) => ({
                  ...el,
                  time: moment(el.time, DATE_FORMAT.time_only_full).toDate(),
                })),
            lights: actualIntersectionItem.ring1.lights.map((el) => {
              const startTime = moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate();
              const endTime = moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate();

              // tranform greenwave line
              let green_wave_lines = null;

              if (
                actualIntersectionItem.ring1.coord_phase ===
                  actualIntersectionItem.upstream_phase &&
                i === 0
              ) {
                // console.log(i, actualIntersectionItem.upstream_phase);
                green_wave_lines = el.forward_green_wave_lines?.map(
                  (greenwave_line) => {
                    const greenwave_time = moment(
                      greenwave_line,
                      DATE_FORMAT.time_only_full
                    ).valueOf();

                    let totalEstTime = 0;
                    let totalDistance = 0;
                    this.selectedItem.actual.intersections.forEach(
                      (intItem) => {
                        totalEstTime += intItem.estimated_time;
                        totalDistance += intItem.distance;
                      }
                    );

                    const arrayPointOfGWLine = [
                      {
                        x: new Date(greenwave_time),
                        y: totalDistance,
                      },
                      {
                        x: new Date(greenwave_time + totalEstTime * 1000),
                        y: 0,
                      },
                    ];

                    return arrayPointOfGWLine;
                  }
                );
              }
              if (
                actualIntersectionItem.ring1.coord_phase ===
                  actualIntersectionItem.downstream_phase &&
                i === len - 1
              ) {
                green_wave_lines = el.backward_green_wave_lines?.map(
                  (greenwave_line) => {
                    const greenwave_time = moment(
                      greenwave_line,
                      DATE_FORMAT.time_only_full
                    ).valueOf();

                    let totalEstTime = 0;
                    let totalDistance = 0;
                    this.selectedItem.actual.intersections.forEach(
                      (intItem) => {
                        totalEstTime += intItem.estimated_time;
                        totalDistance += intItem.distance;
                      }
                    );

                    const arrayPointOfGWLine = [
                      {
                        x: new Date(greenwave_time),
                        y: 0,
                      },
                      {
                        x: new Date(greenwave_time + totalEstTime * 1000),
                        y: totalDistance,
                      },
                    ];

                    return arrayPointOfGWLine;
                  }
                );
              }

              return {
                ...el,
                green_wave_lines: green_wave_lines,
                direction_green_wave_percent:
                  el.phase === actualIntersectionItem.upstream_phase
                    ? i === 0
                      ? el.forward_green_wave_percent
                      : null
                    : i === len - 1
                    ? el.backward_green_wave_percent
                    : null,
                greenwave_percent:
                  el.phase === actualIntersectionItem.upstream_phase
                    ? el.forward_green_percent
                    : el.backward_green_percent,
                time_space:
                  actualIntersectionItem.ring1.coord_phase ===
                  actualIntersectionItem.downstream_phase
                    ? i > 0 &&
                      el.phase === actualIntersectionItem.ring1.coord_phase &&
                      el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                this.selectedItem.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                this.selectedItem.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),

                            y:
                              currentYCoord +
                              this.selectedItem.actual.intersections[i - 1]
                                .distance,
                          },
                        ]
                      : null
                    : i < len - 1 &&
                      el.phase === actualIntersectionItem.ring1.coord_phase &&
                      el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              actualIntersectionItem.estimated_time * 1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              actualIntersectionItem.estimated_time * 1000
                          ),
                          y:
                            currentYCoord -
                            this.selectedItem.actual.intersections[i].distance,
                        },
                      ]
                    : null,
                start_time: moment(
                  el.start_time,
                  DATE_FORMAT.time_only_full
                ).toDate(),
                end_time: moment(
                  el.end_time,
                  DATE_FORMAT.time_only_full
                ).toDate(),
              };
            }),
          },

          ring2: {
            ...actualIntersectionItem.ring2,
            vehicles: actualIntersectionItem.ring2.vehicles
              ? actualIntersectionItem.ring2.vehicles.map((el) => ({
                  time: moment(el, DATE_FORMAT.time_only_full).toDate(),
                }))
              : actualIntersectionItem.ring2.vehicle_points.map((el) => ({
                  ...el,
                  time: moment(el.time, DATE_FORMAT.time_only_full).toDate(),
                })),
            lights: actualIntersectionItem.ring2.lights.map((el) => {
              const startTime = moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate();
              const endTime = moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate();

              // tranform greenwave line
              let green_wave_lines = null;

              if (
                actualIntersectionItem.ring2.coord_phase ===
                  actualIntersectionItem.upstream_phase &&
                i === 0
              ) {
                green_wave_lines = el.forward_green_wave_lines?.map(
                  (greenwave_line) => {
                    const greenwave_time = moment(
                      greenwave_line,
                      DATE_FORMAT.time_only_full
                    ).valueOf();

                    let totalEstTime = 0;
                    let totalDistance = 0;
                    this.selectedItem.actual.intersections.forEach(
                      (intItem) => {
                        totalEstTime += intItem.estimated_time;
                        totalDistance += intItem.distance;
                      }
                    );

                    const arrayPointOfGWLine = [
                      {
                        x: new Date(greenwave_time),
                        y: totalDistance,
                      },
                      {
                        x: new Date(greenwave_time + totalEstTime * 1000),
                        y: 0,
                      },
                    ];

                    return arrayPointOfGWLine;
                  }
                );
              }
              if (
                actualIntersectionItem.ring2.coord_phase ===
                  actualIntersectionItem.downstream_phase &&
                i === len - 1
              )
                green_wave_lines = el.backward_green_wave_lines?.map(
                  (greenwave_line) => {
                    const greenwave_time = moment(
                      greenwave_line,
                      DATE_FORMAT.time_only_full
                    ).valueOf();

                    let totalEstTime = 0;
                    let totalDistance = 0;
                    this.selectedItem.actual.intersections.forEach(
                      (intItem) => {
                        totalEstTime += intItem.estimated_time;
                        totalDistance += intItem.distance;
                      }
                    );

                    const arrayPointOfGWLine = [
                      {
                        x: new Date(greenwave_time),
                        y: 0,
                      },
                      {
                        x: new Date(greenwave_time + totalEstTime * 1000),
                        y: totalDistance,
                      },
                    ];

                    return arrayPointOfGWLine;
                  }
                );

              return {
                ...el,

                green_wave_lines: green_wave_lines,

                direction_green_wave_percent:
                  el.phase === actualIntersectionItem.upstream_phase
                    ? i === 0
                      ? el.forward_green_wave_percent
                      : null
                    : i === len - 1
                    ? el.backward_green_wave_percent
                    : null,
                greenwave_percent:
                  el.phase === actualIntersectionItem.upstream_phase
                    ? el.forward_green_percent
                    : el.backward_green_percent,

                time_space:
                  actualIntersectionItem.ring2.coord_phase ===
                  actualIntersectionItem.upstream_phase
                    ? i < len - 1 &&
                      el.phase === actualIntersectionItem.ring2.coord_phase &&
                      el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                actualIntersectionItem.estimated_time * 1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                actualIntersectionItem.estimated_time * 1000
                            ),
                            y:
                              currentYCoord -
                              this.selectedItem.actual.intersections[i]
                                .distance,
                          },
                        ]
                      : null
                    : i > 0 &&
                      el.phase === actualIntersectionItem.ring2.coord_phase &&
                      el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              this.selectedItem.actual?.intersections[i - 1]
                                .estimated_time *
                                1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              this.selectedItem.actual?.intersections[i - 1]
                                .estimated_time *
                                1000
                          ),

                          y:
                            currentYCoord +
                            this.selectedItem.actual.intersections[i - 1]
                              .distance,
                        },
                      ]
                    : null,
                start_time: moment(
                  el.start_time,
                  DATE_FORMAT.time_only_full
                ).toDate(),
                end_time: moment(
                  el.end_time,
                  DATE_FORMAT.time_only_full
                ).toDate(),
              };
            }),
          },
          plans: actualIntersectionItem.plans.map((el) => {
            return {
              ...el,
              start_point: moment(
                el.start_point,
                DATE_FORMAT.time_only_full
              ).toDate(),
              end_point: moment(
                el.end_point,
                DATE_FORMAT.time_only_full
              ).toDate(),
            };
          }),
          coordY: currentYCoord,
        };

        tranformObject.actual.intersections.push(
          tranformActualIntersectionItem
        );
      }
      tranformObject.actual.min_distance = min_distance;
      tranformObject.actual.to_distance = currentYCoord + min_distance;

      //tranform programmed
      currentYCoord = 0;
      min_distance = Number.MAX_SAFE_INTEGER;
      if (this.selectedItem.programmed) {
        len = this.selectedItem.programmed.intersections.length;
        for (let i = len - 1; i >= 0; i--) {
          const programmedIntersectionItem =
            this.selectedItem.programmed?.intersections[i];
          min_distance =
            programmedIntersectionItem.distance &&
            programmedIntersectionItem.distance < min_distance
              ? programmedIntersectionItem.distance
              : min_distance;
          currentYCoord += programmedIntersectionItem.distance;
          const tranformprogrammedIntersectionItem = {
            ...programmedIntersectionItem,
            ring1: {
              ...programmedIntersectionItem.ring1,
              lights: programmedIntersectionItem.ring1.lights.map((el) => {
                const startTime = moment(
                  el.start_time,
                  DATE_FORMAT.time_only_full
                ).toDate();
                const endTime = moment(
                  el.end_time,
                  DATE_FORMAT.time_only_full
                ).toDate();

                return {
                  ...el,
                  start_time: startTime,
                  end_time: endTime,
                  time_space:
                    programmedIntersectionItem.ring1.coord_phase ===
                    programmedIntersectionItem.downstream_phase
                      ? i > 0 &&
                        el.phase ===
                          programmedIntersectionItem.ring1.coord_phase &&
                        el.color === "GREEN"
                        ? [
                            {
                              x_start: startTime,
                              x_end: endTime,
                              y: currentYCoord,
                            },
                            {
                              x_start: new Date(
                                startTime.getTime() +
                                  this.selectedItem.actual?.intersections[i - 1]
                                    .estimated_time *
                                    1000
                              ),
                              x_end: new Date(
                                endTime.getTime() +
                                  this.selectedItem.actual?.intersections[i - 1]
                                    .estimated_time *
                                    1000
                              ),

                              y:
                                currentYCoord +
                                this.selectedItem.actual.intersections[i - 1]
                                  .distance,
                            },
                          ]
                        : null
                      : i < len - 1 &&
                        el.phase ===
                          programmedIntersectionItem.ring1.coord_phase &&
                        el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                programmedIntersectionItem.estimated_time * 1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                programmedIntersectionItem.estimated_time * 1000
                            ),
                            y:
                              currentYCoord -
                              this.selectedItem.actual.intersections[i]
                                .distance,
                          },
                        ]
                      : null,
                };
              }),
            },

            ring2: {
              ...programmedIntersectionItem.ring2,
              lights: programmedIntersectionItem.ring2.lights.map((el) => {
                const startTime = moment(
                  el.start_time,
                  DATE_FORMAT.time_only_full
                ).toDate();
                const endTime = moment(
                  el.end_time,
                  DATE_FORMAT.time_only_full
                ).toDate();

                return {
                  ...el,
                  start_time: startTime,
                  end_time: endTime,

                  time_space:
                    programmedIntersectionItem.ring2.coord_phase ===
                    programmedIntersectionItem.upstream_phase
                      ? i < len - 1 &&
                        el.phase ===
                          programmedIntersectionItem.ring2.coord_phase &&
                        el.color === "GREEN"
                        ? [
                            {
                              x_start: startTime,
                              x_end: endTime,
                              y: currentYCoord,
                            },
                            {
                              x_start: new Date(
                                startTime.getTime() +
                                  programmedIntersectionItem.estimated_time *
                                    1000
                              ),
                              x_end: new Date(
                                endTime.getTime() +
                                  programmedIntersectionItem.estimated_time *
                                    1000
                              ),
                              y:
                                currentYCoord -
                                this.selectedItem.actual.intersections[i]
                                  .distance,
                            },
                          ]
                        : null
                      : i > 0 &&
                        el.phase ===
                          programmedIntersectionItem.ring2.coord_phase &&
                        el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                this.selectedItem.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                this.selectedItem.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),

                            y:
                              currentYCoord +
                              this.selectedItem.actual.intersections[i - 1]
                                .distance,
                          },
                        ]
                      : null,
                };
              }),
            },
            coordY: currentYCoord,
          };

          tranformObject.programmed.intersections.push(
            tranformprogrammedIntersectionItem
          );
        }

        tranformObject.programmed.min_distance = min_distance;
        tranformObject.programmed.to_distance = currentYCoord + min_distance;
      }
      return tranformObject;
    }
    return null;
  }

  transformData(data, index) {
    this.dataPrint.push({
      ...data,
      no: index,
    });
  }

  @action processDataToPrint = (id, filterObject, index) => {
    this.loading = true;
    this.moduleService
      .getOneItem(id, {
        type: OPTIMIZATION_TYPE.HISTORIC_TIME_SPACE,
        from_time: filterObject.from_time,
        to_time: filterObject.to_time,
      })
      .then((data) => {
        runInAction(() => {
          return this.transformData(data, index);
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        runInAction(() => (this.loading = false));
      });
  };
}

export default HistoricTimeSpaceStore;
