import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, TimePicker, } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Map, Marker, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import { withRouter } from "react-router";
import { ANALYSIS_TYPE, DEFAULT_PIN, DYNAMIC_FIELD_TYPE, SELECTED_PIN, TIME_PICKER_FORMAT, } from "utils/constants";
import FullCalendarPopup from "./FullCalendarPopup";
import MapPopup from "./MapPopup";
import "./style.scss";

const Option = Select.Option;
const MAP = "map";
const COMBOBOX = "combobox";

@inject("rootStore")
@observer
class AnalysisFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowFullMap: false,
      isShowFullCalendar: false,
    };
  }

  componentDidMount() {
    const {
      rootStore: {
        analysisStore: { getAllFilterData, setFilterData },
      },
    } = this.props;
    if (this.props.location.search.length > 0) {
      let filterObject = queryString.parse(this.props.location.search);
      if (Object.keys(filterObject).length > 0) {
        //convert time to date and time
        if (filterObject.from_time) {
          filterObject.date = moment(filterObject.from_time);
          filterObject.from_time = moment(
            filterObject.date.format(TIME_PICKER_FORMAT),
            TIME_PICKER_FORMAT
          );
        }
        if (filterObject.to_time) {
          filterObject.to_time = moment(
            moment(filterObject.to_time).format(TIME_PICKER_FORMAT),
            TIME_PICKER_FORMAT
          );
        }
        //convert analysis to front-end format
        filterObject.analysis_type = filterObject.analysis_id;
        //confim data change
        setFilterData(filterObject);
      }
    }
    getAllFilterData();
  }

  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  checkVaildTime = (time, value, callback) => {
    const {
      t,
      rootStore: { analysisStore },
    } = this.props;
    if (
      analysisStore.filterData.from_time.isBefore(
        analysisStore.filterData.to_time
      )
    ) {
      return callback();
    } else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  };

  checkToTime = (time, value, callback) => {
    const {
      t,
      rootStore: { analysisStore },
    } = this.props;

    switch (analysisStore.filterData?.analysis_type) {
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        if (
          moment
            .duration(
              analysisStore.filterData.to_time -
              analysisStore.filterData.from_time
            )
            .as("hours") <= 3
        ) {
          return callback();
        } else {
          callback(t("analysis.max_time_range"));
          break;
        }
      default:
        return callback();
    }
  };

  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  handleIntersectionChange = (intersectionId, type) => {
    const {
      form,
      rootStore: { analysisStore },
    } = this.props;
    if (!intersectionId) {
      form.setFieldsValue({ intersection_id: undefined });
    } else {
      analysisStore?.handleFilterDataChange("intersection_id", intersectionId);
      if (type === MAP) {
        form.setFieldsValue({
          intersection_id: intersectionId,
        });
      }
    }
  };
  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  renderDisableDateInCalendar = (currentDate) => {
    if (currentDate.valueOf() > new Date().setDate(new Date().getDate() + 1)) {
      return true;
    }

    let date = currentDate?.date();
    if (currentDate?.month() === moment().month()) {
      const {
        rootStore: { analysisStore },
      } = this.props;
      let dateInfo = analysisStore?.listAvailableDate?.find(
        (item) => item.day === date
      );

      return dateInfo ? !dateInfo?.has_analysis : false;
    }

    return false;
  };
  /**
   * initHandlerActionOnCalender
   * cuz antd calendar don't have call back for next/privous month/year
   * we have to manually handle it by hook-up onClick on calendar
   * @return  {Null}
   */
  initHandlerActionOnCalender = (isOpen, defaultDate = null) => {
    const {
      rootStore: { analysisStore },
    } = this.props;
    if (defaultDate === null) {
      defaultDate = moment();
    }
    if (isOpen) {
      //set current month and year of calendar
      analysisStore
        .setCurrentMonthYear(defaultDate.month(), defaultDate.year())
        .then(() => {
          //start manually listening on  next/privous month/year button
          let nextMonth = document.getElementsByClassName(
            "ant-calendar-next-month-btn"
          )[0];
          let prevMonth = document.getElementsByClassName(
            "ant-calendar-prev-month-btn"
          )[0];
          let nextYear = document.getElementsByClassName(
            "ant-calendar-next-year-btn"
          )[0];
          let prevYear = document.getElementsByClassName(
            "ant-calendar-prev-year-btn"
          )[0];
          if (nextMonth) {
            nextMonth.onclick = () => {
              analysisStore.setCurrentMonthYear(
                analysisStore.currentCalendarData.month() + 1,
                analysisStore.currentCalendarData.year()
              );
            };
          }
          if (prevMonth) {
            prevMonth.onclick = () => {
              analysisStore.setCurrentMonthYear(
                analysisStore.currentCalendarData.month() - 1,
                analysisStore.currentCalendarData.year()
              );
            };
          }
          if (nextYear) {
            nextYear.onclick = () => {
              analysisStore.setCurrentMonthYear(
                analysisStore.currentCalendarData.month(),
                analysisStore.currentCalendarData.year() + 1
              );
            };
          }
          if (prevYear) {
            prevYear.onclick = () => {
              analysisStore.setCurrentMonthYear(
                analysisStore.currentCalendarData.month(),
                analysisStore.currentCalendarData.year() - 1
              );
            };
          }
        });
    } else {
      //clean current month/year
      analysisStore.setCurrentMonthYear(null, null);
    }
  };
  /**
   * handleMapChangeView
   * this function trigger when the map is change position or zoom level
   *
   * @return  {null}
   */
  handleMapChangeView = (viewport) => {
    const {
      rootStore: { analysisStore },
    } = this.props;
    if (typeof viewport?.zoom == "number") {
      analysisStore.updateCurrentZoomLevel(viewport?.zoom);
    }
  };
  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  renderDateColorInCalendar = (currentDate) => {
    let date = currentDate?.date();
    const {
      rootStore: { analysisStore },
    } = this.props;
    let dateColor = analysisStore?.findTheColorByDate(currentDate);
    return (
      <div
        className="ant-calendar-date"
        aria-selected="false"
        title={analysisStore.getDisplayDayTitle(currentDate)}
        aria-disabled="false"
        {...{ style: { background: dateColor } }}>
        {date}
      </div>
    );
  };
  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  renderExtraFieldItems = (extraField, typeId) => {
    const {
      t,
      form: { getFieldDecorator },
      rootStore: { analysisStore },
    } = this.props;
    return (
      extraField && (
        <Form.Item label={extraField.label} key={typeId + "_" + extraField.id}>
          {extraField.type === DYNAMIC_FIELD_TYPE.LIST ? (
            getFieldDecorator(typeId + "_" + extraField.id, {
              rules: [
                {
                  required: true,
                  message: t("analysis." + extraField.id + "_required"),
                },
              ],
              initialValue: { key: extraField.default_value },
            })(
              <Select
                labelInValue
                onChange={(value) =>
                  analysisStore?.handleFilterDataChange(
                    extraField.id,
                    value.key
                  )
                }>
                {extraField.values?.map((e) => (
                  <Option value={e?.value} key={e?.value}>
                    {e?.display}
                  </Option>
                ))}
              </Select>
            )
          ) : (
            <Input
              maxLength={255}
              onChange={(value) =>
                analysisStore?.handleFilterDataChange(extraField.key, value)
              }
            />
          )}
        </Form.Item>
      )
    );
  };

  renderCompare = () => {
    const {
      t,
      rootStore: { analysisStore },
      form: { getFieldDecorator },
    } = this.props;

    switch (analysisStore.filterData?.analysis_type) {
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        analysisStore?.handleFilterDataChange("is_compare", false);
        return null;
      case ANALYSIS_TYPE.TURNING_MOVEMENT:
        analysisStore?.handleFilterDataChange("is_compare", false);
        return <></>;
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY:
        analysisStore?.handleFilterDataChange("is_compare", false);
        return <></>;
      case ANALYSIS_TYPE.MOE_ANALYSIS:
        analysisStore?.handleFilterDataChange("is_compare", false);
        return <></>;
      default:
        return (
          <>
            <Form.Item>
              <Checkbox
                checked={analysisStore.filterData.is_compare}
                disabled={!analysisStore.loadingDate}
                onChange={(e) =>
                  analysisStore?.handleFilterDataChange(
                    "is_compare",
                    e?.target?.checked
                  )
                }>
                {t("analysis.compare")}
              </Checkbox>
            </Form.Item>

            <Form.Item>
              {analysisStore.filterData?.is_compare &&
                getFieldDecorator("compare_date", {
                  initialValue: analysisStore.filterData.compare_date,
                })(
                  <DatePicker
                    allowClear={false}
                    onChange={(value) =>
                      analysisStore?.handleFilterDataChange(
                        "compare_date",
                        value
                      )
                    }
                    onOpenChange={(open) =>
                      this.initHandlerActionOnCalender(
                        open,
                        analysisStore.filterData.compare_date
                      )
                    }
                    disabledDate={this.renderDisableDateInCalendar}
                    dateRender={this.renderDateColorInCalendar}
                  />
                )}
            </Form.Item>
          </>
        );
    }
  };

  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: { analysisStore, commonStore },
      form: { getFieldDecorator },
    } = this.props;
    return (
      <div className="wrap-analysis-filter">
        <Form className="styled-scroll">
          {commonStore.isSuperAdmin() &&
            !commonStore.isFromStatic &&
            analysisStore?.listAgencies.length > 0 && (
              <Form.Item label={t("analysis.agencies")}>
                <Select
                  labelInValue
                  onChange={(value) => {
                    this.handleIntersectionChange(null);
                    analysisStore?.handleFilterDataChange(
                      "agency_id",
                      value.key
                    );
                  }}
                  defaultValue={{ key: analysisStore?.filterData.agency_id }}>
                  {analysisStore?.listAgencies?.map((e) => (
                    <Option value={e?.id} key={e?.id}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          {analysisStore?.listAnalysisTypes.length > 0 && (
            <Form.Item label={t("analysis.analysis")}>
              {getFieldDecorator("analysis_type", {
                rules: [
                  { required: true, message: t("analysis.analysis_required") },
                ],
                initialValue: analysisStore?.currentAnalysisType?.name,
              })(
                <Select
                  onChange={(value) => {
                    analysisStore?.handleFilterDataChange(
                      "analysis_type",
                      value
                    );

                    this.props.form.resetFields(["to_time"]);
                    this.props.form.validateFields(["to_time"]);
                  }}>
                  {analysisStore?.listAnalysisTypes?.map((e) => (
                    <Option value={e?.id} key={e?.id}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
          <Form.Item label={t("analysis.intersection")}>
            {analysisStore?.listIntersections &&
              getFieldDecorator("intersection_id", {
                rules: [
                  {
                    required: true,
                    message: t("analysis.intersection_required"),
                  },
                ],
                initialValue: analysisStore?.filterData.intersection_id,
              })(
                <Select
                  showSearch
                  onChange={(value) =>
                    this.handleIntersectionChange(value, COMBOBOX)
                  }
                  placeholder={t("analysis.intersection_placholder")}
                  optionFilterProp="value"
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }>
                  {analysisStore?.listIntersections?.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              )}
          </Form.Item>
          <Form.Item>
            <Map
              style={{ height: "200px" }}
              className="intersection-map"
              zoom={analysisStore?.currentMapZoom}
              center={analysisStore?.currentMapCenter}
              onViewportChanged={this.handleMapChangeView}>
              <TileLayer
                url={process.env.REACT_APP_OPEN_STREET_MAP}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Control position="bottomright">
                <Button
                  icon="fullscreen"
                  className="map-fullscreen"
                  onClick={() => {
                    this.setState({ isShowFullMap: true });
                  }}
                />
              </Control>
              {analysisStore?.listIntersections?.map((intersection) => {
                let marker = null;
                if (intersection) {
                  marker = (
                    <Marker
                      icon={
                        analysisStore?.filterData?.intersection_id ===
                        intersection?.id
                          ? SELECTED_PIN
                          : DEFAULT_PIN
                      }
                      position={[intersection.latitude, intersection.longitude]}
                      key={intersection.id}
                      onClick={(e) =>
                        this.handleIntersectionChange(intersection.id, MAP, e)
                      }></Marker>
                  );
                  intersection.marker = marker;
                  analysisStore.listAllIntersectionsMarker.push(marker);
                }
                return marker;
              })}
            </Map>
          </Form.Item>
          {/* Dynamic fields */}
          {analysisStore.currentAnalysisType &&
            analysisStore.currentAnalysisType?.extra_fields?.map((item) =>
              this.renderExtraFieldItems(
                item,
                analysisStore.currentAnalysisType?.id
              )
            )}
          <Form.Item label={t("analysis.date")}>
            <DatePicker
              mode={"date"}
              allowClear={false}
              disabled={!analysisStore.loadingDate}
              onChange={(value) =>
                analysisStore?.handleFilterDataChange("date", value)
              }
              value={analysisStore.filterData.date}
              disabledDate={(currentDate) =>
                currentDate.valueOf() >
                new Date().setDate(new Date().getDate() + 1)
              }
              onOpenChange={(open) =>
                this.initHandlerActionOnCalender(
                  open,
                  analysisStore.filterData.date
                )
              }
              dateRender={this.renderDateColorInCalendar}
              renderExtraFooter={() => (
                <Button
                  icon="fullscreen"
                  size="small"
                  onClick={() => {
                    analysisStore
                      .getAllDateByIntersection(
                        analysisStore?.filterData?.date
                          ?.clone()
                          ?.startOf("year"),
                        analysisStore?.filterData?.date?.clone()?.endOf("year")
                      )
                      .finally(() => {
                        this.setState({ isShowFullCalendar: true });
                      });
                  }}
                />
              )}
              showToday={false}
            />
          </Form.Item>

          {this.renderCompare()}
          <Input.Group>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("analysis.time")}>
                  {getFieldDecorator("from_time", {
                    rules: [
                      {
                        required: true,
                        message: t("analysis.from_time_required"),
                      },
                      { validator: this.checkVaildTime },
                    ],

                    initialValue: analysisStore.filterData.from_time,
                  })(
                    <TimePicker
                      placeholder={t("analysis.from_time_placeholder")}
                      disabled={!analysisStore.loadingDate}
                      onChange={(time, timeString) => {
                        analysisStore?.handleFilterDataChange(
                          "from_time",
                          moment(timeString, TIME_PICKER_FORMAT)
                        );

                        this.props.form.resetFields(["to_time"]);
                        this.props.form.validateFields(["to_time"]);
                      }}
                      format={TIME_PICKER_FORMAT}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={" "} colon={false}>
                  {getFieldDecorator("to_time", {
                    initialValue: analysisStore.filterData.to_time,
                    rules: [{ validator: this.checkToTime }],
                    validateTrigger: ["onChange", "onLoad"],
                  })(
                    <TimePicker
                      placeholder={t("analysis.to_time_placeholder")}
                      disabled={!analysisStore.loadingDate}
                      onChange={(time, timeString) => {
                        analysisStore?.handleFilterDataChange(
                          "to_time",
                          moment(timeString, TIME_PICKER_FORMAT)
                        );
                        //trigger onchange of from_time

                        this.props.form.resetFields(["from_time"]);
                        this.props.form.validateFields(["from_time"]);
                      }}
                      format={TIME_PICKER_FORMAT}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form>
        <div className="filter-footer">
          <Button
            type="link"
            onClick={() => {
              this.props.form.setFieldsValue({
                from_time: moment("00:00", TIME_PICKER_FORMAT),
                to_time: moment("23:59", TIME_PICKER_FORMAT),
              });
              analysisStore?.resetFilter();
            }}>
            {t("analysis.reset-filter")}
          </Button>
          <Button
            loading={analysisStore.loading}
            disabled={!analysisStore.filterDataChanged}
            onClick={() => {
              this.props.form?.validateFields((err) => {
                if (!err) {
                  analysisStore?.generateReport();
                }
              });
            }}
            type="primary">
            {t("analysis.create-analysis")}
          </Button>
        </div>

        {/* Modal box */}
        {this.state.isShowFullMap && (
          <MapPopup
            visible={this.state.isShowFullMap}
            onMapSelect={(intersectioId) =>
              this.handleIntersectionChange(intersectioId, MAP)
            }
            onClose={() => {
              this.setState({ isShowFullMap: false });
            }}
          />
        )}
        {this.state.isShowFullCalendar && (
          <FullCalendarPopup
            selectedColorMatrix={analysisStore.selectedColorMatrix}
            visible={this.state.isShowFullCalendar}
            onCalendarSelect={(value) =>
              analysisStore?.handleFilterDataChange("date", value)
            }
            customCSSclasses={analysisStore.customCSSclasses}
            renderDisableDateInCalendar={this.renderDisableDateInCalendar}
            renderDateColorInCalendar={this.renderDateColorInCalendar}
            defaultValue={analysisStore.filterData.date}
            onClose={() => {
              this.setState({ isShowFullCalendar: false });
            }}
          />
        )}
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "analysis_filter" })(AnalysisFilter);

export default withRouter(withTranslation()(WrapForm));
