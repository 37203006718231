import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  List,
  Radio,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  AGGREGATION_UNIT,
  DATE_FORMAT,
  DATE_RAGNGE_SELECT,
  MANUAL_SCOPE,
  RELATIVE_DATE_UNIT,
  TIME_TO_RUN_REPORT,
  WEEK_DAY_OPTION,
} from "utils/constants";
import { TIMEZONES } from "../../../../../utils/timezone";
import "./style.scss";
import CheckboxGroup from "antd/lib/checkbox/Group";
const END_HOUR_DAY = 0;

const { Option } = Select;
const HORIZONTAL_LAYOUT = "horizontal";
const { TextArea } = Input;

@inject("rootStore")
@observer
class DetectorTemplateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: HORIZONTAL_LAYOUT,
    };
    this.moduleName = "report_template";
    this.formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    this.formItemLayoutNoLabel = {
      wrapperCol: { span: 24 },
    };
  }

  operatos = ["==", ">=", ">", "<=", "<", "!="];

  componentDidMount() {
    const {
      rootStore: {
        detectorDataStore: { setFormField, getAllAgencies },
        commonStore,
        myStore,
      },

      childRef,
      t,
    } = this.props;
    document.title = t("report_template.page-title");
    const currentAgency = myStore.currentAgency;
    const superAdmin = commonStore.isSuperAdmin();
    if (!superAdmin) {
      setFormField("agency_id", currentAgency.agency_id);
    } else {
      getAllAgencies();
    }
    childRef && childRef(this);
  }
  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      //specific for time_range case
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field == e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  handleUpdate = () => {
    const {
      rootStore: {
        detectorDataStore: {
          getTemplateDetail,
          submitUpdateTemplate,
          formData,
        },
      },
    } = this.props;
    this.props.form.validateFields((err) => {
      if (!err) {
        submitUpdateTemplate(
          () => {
            getTemplateDetail(formData.id);
          },
          (error) => {
            this.setFormError(error);
          }
        );
      }
    });
  };
  /**
   * handleNext
   *
   * @return  {Object}
   */
  handleNext = () => {
    const {
      rootStore: {
        detectorDataStore: { changeReportTemplateTab, formData },
      },
    } = this.props;
    this.props.form.validateFields((err) => {
      if (!err) {
        if (
          formData.date_range?.scope === DATE_RAGNGE_SELECT.DATE_RANGE.value &&
          [
            TIME_TO_RUN_REPORT.EVERY_DAY,
            TIME_TO_RUN_REPORT.DAY_OF_WEEK,
            TIME_TO_RUN_REPORT.DAY_OF_MONTH,
          ].includes(formData.schedule?.scope)
        ) {
          this.onChangeField("schedule.scope", MANUAL_SCOPE);
        }
        changeReportTemplateTab("2");
      }
    });
  };
  checkEndTime = (rule, value, callback) => {
    const {
      t,
      rootStore: {
        detectorDataStore: { formData },
      },
    } = this.props;
    const getHourStartTime = Number(formData?.start_time?.slice(0, 2));
    const getHourEndTime = moment(value?._d).hour();
    let startTime = moment(formData?.start_time, DATE_FORMAT.hour).valueOf();
    let endTime = value?.valueOf();

    if (getHourStartTime === getHourEndTime) {
      callback(t("report_template.end_time_must"));
    }
    if (value?.hour() !== END_HOUR_DAY) {
      if (formData?.start_time && endTime >= startTime) return callback();
      callback(t("report_template.end_time_must"));
    } else {
      return callback();
    }
  };
  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      //specific for time_range case
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field === e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  onChangeDateScope = (field, value) => {
    this.onChangeField(field, value);
    this.onChangeField("aggregation", AGGREGATION_UNIT[0].value);
    return value;
  };
  onChangeField = (field, value) => {
    const {
      rootStore: {
        detectorDataStore: { setFormField },
      },
      selectedIntersectionStore,
    } = this.props;
    setFormField(field, value);
    if (field === "agency_id") {
      selectedIntersectionStore.resetListIntersection();
    }
    return value;
  };
  onChangeTimeRange = (field, value) => {
    let hourOnly = value?.format("HH");
    this.onChangeField(field, hourOnly + ":00");
    return value;
  };
  onChangeDateRange = (field, value) => {
    this.onChangeField(
      "date_range.start_date",
      value[0].format(DATE_FORMAT.day_only_backend)
    );
    this.onChangeField(
      "date_range.end_date",
      value[1].format(DATE_FORMAT.day_only_backend)
    );
    return value;
  };
  renderAggregationUnit = () => {
    const {
      t,
      rootStore: {
        detectorDataStore: { formData },
      },
    } = this.props;
    const moduleName = "report_template";
    if (formData?.date_range?.scope === DATE_RAGNGE_SELECT.RELATIVE.value) {
      if (formData?.date_range?.unit === RELATIVE_DATE_UNIT[0].value) {
        return [
          <Option
            value={AGGREGATION_UNIT[0].value}
            key={AGGREGATION_UNIT[0].key}
          >
            {t(moduleName + "." + AGGREGATION_UNIT[0].key)}
          </Option>,
          <Option
            value={AGGREGATION_UNIT[1].value}
            key={AGGREGATION_UNIT[1].key}
          >
            {t(moduleName + "." + AGGREGATION_UNIT[1].key)}
          </Option>,
        ];
      } else
        return AGGREGATION_UNIT.map((e) => (
          <Option value={e.value} key={e.key}>
            {t(moduleName + "." + e.key)}
          </Option>
        ));
    } else {
      let startDate = moment(
        formData?.date_range?.start_date,
        DATE_FORMAT.day_only_backend
      );
      let endDate = moment(
        formData?.date_range?.end_date,
        DATE_FORMAT.day_only_backend
      );
      let dateRange = endDate.valueOf() - startDate.valueOf();
      if (dateRange >= 604800000)
        return AGGREGATION_UNIT.map((e) => (
          <Option value={e.value} key={e.key}>
            {t(moduleName + "." + e.key)}
          </Option>
        ));
      else
        return [
          <Option
            value={AGGREGATION_UNIT[0].value}
            key={AGGREGATION_UNIT[0].key}
          >
            {t(moduleName + "." + AGGREGATION_UNIT[0].key)}
          </Option>,
          <Option
            value={AGGREGATION_UNIT[1].value}
            key={AGGREGATION_UNIT[1].key}
          >
            {t(moduleName + "." + AGGREGATION_UNIT[1].key)}
          </Option>,
        ];
    }
  };

  generateDefaultPhase = (length) =>
    Array.from({ length }, (_, i) => {
      //  if index < 9 then add 0 before label
      let label = i < 9 ? `0${i + 1}` : i + 1;

      return {
        label: label,
        value: i + 1,
      };
    });

  selectPhaseRenderer = () => {
    const { t } = this.props;

    const onSelectAll = () => {
      this.onChangeField(
        "phases",
        this.generateDefaultPhase(16).map((e) => e.value)
      );
    };

    const onDeselectAll = () => {
      this.onChangeField("phases", []);
    };

    return (
      <span>
        {t("detector-report.select_phases")}

        <Button type="link" className="ml-8 text-primary" onClick={onSelectAll}>
          {t("detector-report.select_all_phase")}
        </Button>
        <Button
          type="link"
          className="ml-8 text-primary"
          onClick={onDeselectAll}
        >
          {t("detector-report.deselect_all_phase")}
        </Button>
      </span>
    );
  };

  handleMetricChange = (item, value) => {
    const {
      rootStore: {
        detectorDataStore: { setFormField, formData },
      },
    } = this.props;
    const newValue = formData.metrics.map((el) => {
      if (el.key_name === item.key_name) {
        return {
          ...el,
          ...value,
        };
      }
      return el;
    });
    setFormField("metrics", newValue);
  };

  renderIndicatorItem = (item) => {
    const { t } = this.props;
    return (
      <List.Item>
        <Col span={2}>
          <Checkbox
            className="w-full"
            style={{ marginLeft: "8px" }}
            checked={item.enabled}
            onChange={(event) =>
              this.handleMetricChange(item, {
                ...item,
                enabled: event.target.checked,
              })
            }
          />
        </Col>
        <Col span={4}>{item.key_name}</Col>
        <Col span={4}>{t("detector-report." + item.unit)}</Col>
        <Col span={4}>
          <Select
            value={item.statistic}
            style={{ width: "75%" }}
            onChange={(value) => {
              this.handleMetricChange(item, {
                ...item,
                statistic: value,
              });
            }}
          >
            <Select.Option value="TOTAL">
              {t("detector-report.total")}
            </Select.Option>
            <Select.Option value="AVERAGE">
              {t("detector-report.avg")}
            </Select.Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            value={item.condition?.operator}
            style={{ width: "50%" }}
            onChange={(value) => {
              this.handleMetricChange(item, {
                ...item,
                condition: {
                  ...item.condition,
                  operator: value,
                  value: value === null ? 0 : item.condition?.value,
                },
              });
            }}
          >
            <Select.Option value={null}>None</Select.Option>
            {this.operatos.map((e) => (
              <Select.Option value={e} key={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <InputNumber
            min={0}
            disabled={item.condition?.operator === null}
            value={item.condition?.value}
            onChange={(value) => {
              this.handleMetricChange(item, {
                ...item,
                condition: {
                  ...item.condition,
                  value: value || 0,
                },
              });
            }}
          />
        </Col>
      </List.Item>
    );
  };

  renderHeader = () => {
    const { t } = this.props;

    return (
      <List.Item>
        <Col span={2}></Col>
        <Col span={4}>{t("detector-report.key_name")}</Col>
        <Col span={4}>{t("detector-report.unit")}</Col>
        <Col span={4}>{t("detector-report.statistics")}</Col>
        <Col span={6}>{t("detector-report.condition")}</Col>
        <Col span={4}></Col>
      </List.Item>
    );
  };

  render() {
    const {
      id,
      t,
      form: { getFieldDecorator },
      rootStore: {
        detectorDataStore: { formData, allAgencies, indicators },
        commonStore: { isSuperAdmin },
      },
    } = this.props;
    const superAdmin = isSuperAdmin();
    const moduleName = "report_template";

    const defaultPhase = this.generateDefaultPhase(16);
    return (
      <div className="wrap-alarm-rule-detail-page">
        <Form layout={this.state.formLayout} onSubmit={this.handleSubmit}>
          <div>
            <Row gutter={32}>
              <Col lg={12} xs={24}>
                <Row gutter={16}>
                  <Col lg={16} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".name")}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: t(moduleName + ".name_required"),
                          },
                        ],
                        initialValue: formData?.name,
                        getValueFromEvent: (e) => {
                          return this.onChangeField("name", e.target.value);
                        },
                      })(
                        <Input
                          placeholder={t(moduleName + ".name_placeholder")}
                          maxLength={50}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".description")}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("description", {
                        rules: [
                          {
                            required: false,
                            message: t(moduleName + ".description_required"),
                          },
                        ],
                        initialValue: formData?.description,
                        getValueFromEvent: (e) => {
                          return this.onChangeField(
                            "description",
                            e.target.value
                          );
                        },
                      })(
                        <TextArea
                          maxLength={500}
                          style={{ resize: "none" }}
                          rows={3}
                          placeholder={t(moduleName + ".description")}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".timezone")}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("time_zone", {
                        rules: [
                          {
                            required: true,
                            message: t(moduleName + ".timezone_required"),
                          },
                        ],
                        initialValue: formData?.time_zone,
                        getValueFromEvent: (e) => {
                          return this.onChangeField("time_zone", e);
                        },
                      })(
                        <Select className="timezone">
                          {TIMEZONES.map((e) => (
                            <Option value={e?.value} key={e?.value}>
                              {e?.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".select_date_range")}
                      {...this.formItemLayout}
                      style={{ marginBottom: 0 }}
                    >
                      {getFieldDecorator("date_range.scope", {
                        rules: [],
                        initialValue: formData?.date_range?.scope,
                        getValueFromEvent: (e) => {
                          return this.onChangeDateScope(
                            "date_range.scope",
                            e.target.value
                          );
                        },
                      })(
                        <Radio.Group name="radiogroup">
                          <Radio value={DATE_RAGNGE_SELECT.RELATIVE.value}>
                            {DATE_RAGNGE_SELECT.RELATIVE.label}
                          </Radio>
                          <Radio value={DATE_RAGNGE_SELECT.DATE_RANGE.value}>
                            {DATE_RAGNGE_SELECT.DATE_RANGE.label}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24}>
                    {formData?.date_range?.scope ===
                    DATE_RAGNGE_SELECT.DATE_RANGE.value ? (
                      <Form.Item className="label" {...this.formItemLayout}>
                        {getFieldDecorator("date_range.value", {
                          rules: [
                            {
                              required: true,
                              message: t(
                                moduleName + ".start_end_date_required"
                              ),
                            },
                          ],
                          initialValue: [
                            formData?.date_range?.start_date
                              ? moment(
                                  formData?.date_range?.start_date,
                                  DATE_FORMAT.day_only_backend
                                )
                              : null,
                            formData?.date_range?.end_date
                              ? moment(
                                  formData?.date_range?.end_date,
                                  DATE_FORMAT.day_only_backend
                                )
                              : null,
                          ],
                          getValueFromEvent: (e) => {
                            return this.onChangeDateRange("date_range", e);
                          },
                        })(
                          <DatePicker.RangePicker
                            className="mr-8"
                            style={{ width: "53.33333333%" }}
                            format={DATE_FORMAT.day_only}
                          />
                        )}
                        {[
                          TIME_TO_RUN_REPORT.EVERY_DAY,
                          TIME_TO_RUN_REPORT.DAY_OF_WEEK,
                          TIME_TO_RUN_REPORT.DAY_OF_MONTH,
                        ].includes(formData.schedule?.scope) && (
                          <Typography.Text type="warning">
                            <Icon type="warning" className="mr-4" />
                            {t(moduleName + ".change_date_range_warning")}
                          </Typography.Text>
                        )}
                      </Form.Item>
                    ) : (
                      <Row>
                        <Col span={16}>
                          <Form.Item
                            className="label"
                            // label={t(moduleName + ".category")}
                            {...this.formItemLayout}
                          >
                            {getFieldDecorator("date_range.unit", {
                              rules: [
                                {
                                  required: true,
                                  message: t(
                                    moduleName + ".date_range_unit_required"
                                  ),
                                },
                              ],
                              initialValue: formData?.date_range?.unit,
                              getValueFromEvent: (e) => {
                                return this.onChangeField("date_range.unit", e);
                              },
                            })(
                              <Select style={{ width: "80%" }} placeholder="cc">
                                {RELATIVE_DATE_UNIT.map((e) => (
                                  <Option value={e.value} key={e.key}>
                                    {t(moduleName + "." + e.key)}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            className="label"
                            // label={t(moduleName + ".category")}
                            {...this.formItemLayout}
                          >
                            {getFieldDecorator("date_range.offset", {
                              rules: [
                                {
                                  required: true,
                                  message: t(
                                    moduleName + ".date_range_offset_required"
                                  ),
                                },
                              ],
                              initialValue: formData?.date_range?.offset,
                              getValueFromEvent: (e) => {
                                return this.onChangeField(
                                  "date_range.offset",
                                  e
                                );
                              },
                            })(
                              <InputNumber
                                placeholder="Offset"
                                min={1}
                                max={
                                  RELATIVE_DATE_UNIT.find(
                                    (e) =>
                                      e.value === formData?.date_range?.unit
                                  ).max
                                }
                              />
                            )}
                            &nbsp;
                            {
                              RELATIVE_DATE_UNIT.find(
                                (e) => e.value === formData?.date_range?.unit
                              ).unit
                            }
                            &nbsp;
                            <Tooltip
                              placement="rightBottom"
                              title={
                                <div>
                                  <p>{t(moduleName + ".tooltip.limit_day")}</p>
                                  <p>{t(moduleName + ".tooltip.limit_week")}</p>
                                  <p>
                                    {t(moduleName + ".tooltip.limit_month")}
                                  </p>
                                </div>
                              }
                            >
                              <Icon
                                className="info-tooltip-icon"
                                type="info-circle"
                              />
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      label={t(moduleName + ".select_time_range")}
                      {...this.formItemLayout}
                      style={{ margin: 0 }}
                    >
                      <Col lg={8} xs={16}>
                        <div>
                          <Form.Item
                            className="label"
                            label={t(moduleName + ".start")}
                            {...this.formItemLayout}
                          >
                            <div>
                              {getFieldDecorator("start_time", {
                                rules: [
                                  {
                                    required: true,
                                    message: t(
                                      moduleName + ".start_time_required"
                                    ),
                                  },
                                ],
                                initialValue: formData?.start_time
                                  ? moment(formData?.start_time, "HH:mm")
                                  : null,
                                getValueFromEvent: (e) => {
                                  return this.onChangeTimeRange(
                                    "start_time",
                                    e
                                  );
                                },
                              })(<TimePicker format={"HH"} />)}
                            </div>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={8} xs={8}>
                        <div>
                          <Form.Item
                            className="label"
                            label={t(moduleName + ".end")}
                            {...this.formItemLayout}
                          >
                            <div>
                              {getFieldDecorator("end_time", {
                                rules: [
                                  { validator: this.checkEndTime },
                                  {
                                    required: true,
                                    message: t(
                                      moduleName + ".end_time_required"
                                    ),
                                  },
                                ],
                                initialValue: formData?.end_time
                                  ? moment(formData?.end_time, "HH:mm")
                                  : null,
                                getValueFromEvent: (e) => {
                                  return this.onChangeTimeRange("end_time", e);
                                },
                              })(<TimePicker format="HH" />)}
                              {moment(
                                formData.end_time,
                                DATE_FORMAT.hour
                              ).hour() === END_HOUR_DAY && (
                                <span>&nbsp; (24:00)</span>
                              )}
                            </div>
                          </Form.Item>
                        </div>
                      </Col>
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".select_day")}
                    >
                      {getFieldDecorator("week_days", {
                        rules: [
                          {
                            required: true,
                            message: t(moduleName + ".week_days_required"),
                          },
                        ],
                        initialValue: formData?.week_days,
                        getValueFromEvent: (e) => {
                          return this.onChangeField("week_days", e);
                        },
                      })(<Checkbox.Group options={WEEK_DAY_OPTION} />)}
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={
                        <span>{t(moduleName + ".aggregation")}&nbsp;</span>
                      }
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("aggregation", {
                        rules: [
                          {
                            required: true,
                            message: t(
                              moduleName + ".aggregation_unit_required"
                            ),
                          },
                        ],
                        initialValue: formData?.aggregation,
                        getValueFromEvent: (e) => {
                          return this.onChangeField("aggregation", e);
                        },
                      })(
                        <Select style={{ width: "33.33333333%" }}>
                          {this.renderAggregationUnit()}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}></Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  {superAdmin && (
                    <Col lg={12} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".agency_name")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("agency_id", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".agency_required"),
                            },
                          ],
                          initialValue: formData?.agency_id,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("agency_id", e);
                          },
                        })(
                          <Select
                            disabled={id != null}
                            placeholder={t(moduleName + ".agency_placeholder")}
                          >
                            {allAgencies.map((e) => (
                              <Option key={e.id} value={e.id}>
                                {e.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="label"
                      label={t("detector-report.select_indicators")}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("metrics", {
                        initialValue: formData?.report_type,
                        getValueFromEvent: (e) => {
                          return this.onChangeField("indicator", e);
                        },
                      })(
                        <List
                          rowKey={""}
                          dataSource={formData?.metrics || indicators}
                          renderItem={this.renderIndicatorItem}
                          header={this.renderHeader()}
                          itemLayout="horizontal"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      className="label"
                      label={this.selectPhaseRenderer()}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("phases", {
                        initialValue: formData?.phases || [],

                        getValueFromEvent: (value) => {
                          return this.onChangeField("phases", value);
                        },
                      })(<CheckboxGroup options={defaultPhase} />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      className="label"
                      label={t("detector-report.select_detectors")}
                      {...this.formItemLayout}
                    >
                      {getFieldDecorator("detector_scope", {
                        initialValue: formData?.detector_scope,

                        getValueFromEvent: (value) => {
                          return this.onChangeField("detector_scope", value);
                        },
                      })(
                        <Radio.Group>
                          <Radio value={"ALL_DETECTORS"}>
                            {t("detector-report.select_all_detectors")}
                          </Radio>
                          <Radio disabled>
                            {t("detector-report.select_specific_detectors")}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "report_template" })(
  DetectorTemplateDetail
);

export default withRouter(withTranslation()(WrapForm));
