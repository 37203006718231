import { Col, DatePicker, Input, Select, Tooltip } from "antd";
import BaseManagementComponents from "components/BaseManagementComponents";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  AGGREGATION_UNIT,
  DATE_FORMAT,
  FULL_PERMISSION,
  REPORT_TEMPLATE_TYPE,
  RESOURCE_LIST,
  WEEK_DAY_OPTION,
} from "utils/constants";
import "./style.scss";
import SwitchTypeReport from "components/SwitchTypeReport";

const { RangePicker } = DatePicker;
const { Option } = Select;

@inject("rootStore")
@observer
class SummaryReportResult extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.reportResultStore, props);
    const { t } = props;
    this.resourceName = RESOURCE_LIST.REPORT_RESULT;
    this.enableCreateButton = false;
    this.enableRowSelection = true;
    this.moduleName = "report_result";
    this.columns = this.getTableColumns();
    this.pageTitle = t("report_result.page_title");
    this.headerButtonActions = [];
    this.buttonActions = [
      {
        key: "delete_all",
        title: t("report_template.delete_all"),
        type: "delete",
        resource: RESOURCE_LIST.REPORT_RESULT,
        permission: ACTIONS_LIST.DELETE,
        action: this.currentStore?.delete,
        confirm: true,
        confirmTitle: t(this.moduleName + ".run_confirm_title"),
        confirmContent: t(this.moduleName + ".run_confirm_content"),
      },
    ];

    this.tableInlineActions = [
      {
        key: "redirect",
        tooltip: t(this.moduleName + ".redirect"),
        action: this.goToResultPage,
        resource: FULL_PERMISSION,
        permission: ACTIONS_LIST.VIEW,
        icon: "double-right",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "delete_all",
        tooltip: t(this.moduleName + ".delete"),
        resource: RESOURCE_LIST.REPORT_RESULT,
        action: this.handleTableAction,
        permission: ACTIONS_LIST.DELETE,
        icon: "delete",
        passingData: (item) => {
          return ["delete_all", [item?.id]];
        },
      },
    ];
  }

  goToResultPage = (id) => {
    this.props.history.push("/report/result/summary-report/" + id);
  };
  fetchReportResultByAgency = (isSuperAdmin) => {
    this.currentStore?.getAllUsers();
    this.currentStore?.setDetaultAgencyFilter(isSuperAdmin, () => {
      this.currentStore?.getData(() => {});
    });
  };
  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    document.title = t(this.moduleName + ".page-title");
    if (this.props.location.search.length > 0) {
      let filterObject = queryString.parse(this.props.location.search);
      this.currentStore.setFilterData(filterObject);
    }
    this.fetchReportResultByAgency(commonStore.isSuperAdmin());
    this.currentStore.clearSelectedItems();
  };

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t(this.moduleName + ".name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t(this.moduleName + ".aggregation"),
        dataIndex: "aggregation",
        key: "aggregation",
        ellipsis: true,
        render: (item) => {
          return t(AGGREGATION_UNIT.find((e) => e.value === item).text);
        },
      },
      {
        title: t("report_result.owner"),
        key: "owner",
        ellipsis: true,
        render: (item) => t(item?.owner?.name),
      },
      {
        title: t("report_result.from_time"),
        key: "from_time",
        ellipsis: true,
        render: (item) => {
          return moment(item?.from_time, DATE_FORMAT.time_only_full).format(
            DATE_FORMAT.hour
          );
        },
      },
      {
        title: t("report_result.to_time"),
        key: "to_time",
        ellipsis: true,
        render: (item) =>
          moment(item?.to_time, DATE_FORMAT.time_only_full).format(
            DATE_FORMAT.hour
          ),
      },
      {
        title: t("report_template.day_of_week"),
        key: "week_days",
        render: (item) =>
          item?.week_days
            .map((e) => {
              return WEEK_DAY_OPTION.find((i) => i.value == e).label;
            })
            .join(", "),
      },
      {
        title: t("report_result.from_date"),
        key: "from_date",
        ellipsis: true,
        render: (item) => {
          return moment(item?.from_date, DATE_FORMAT.day_only_backend).format(
            DATE_FORMAT.day_only
          );
        },
      },
      {
        title: t("report_result.to_date"),
        key: "to_date",
        ellipsis: true,
        render: (item) =>
          moment(item?.to_date, DATE_FORMAT.day_only_backend).format(
            DATE_FORMAT.day_only
          ),
      },
      {
        title: t(this.moduleName + ".created_at"),
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => {
          return moment(created_at).format(DATE_FORMAT.full_with_tz);
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
        width: "13%",
      },
    ];
  };
  handleChangeActiveTab = (value) => {
    const { history } = this.props;
    switch (value) {
      case REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE:
        history.push("/report/result/performance-metric");
        break;
      case REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE:
        history.push("/report/result/summary-report");
        break;

      case REPORT_TEMPLATE_TYPE.DETECTOR_DATA:
        history.push("/report/result/detector-report");
        break;

      default:
        history.push("/report/result/summary-report");
        break;
    }
  };
  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const {
      t,
      activeTab,
      rootStore: { commonStore },
    } = this.props;
    const allUsers = this.currentStore?.allUsers;
    return (
      <div>
        <Col lg={8} xl={4}>
          <SwitchTypeReport
            value={activeTab}
            onChange={(value) => this.handleChangeActiveTab(value)}
          />
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip
            placement="top"
            title={t(this.moduleName + ".search_tooltip")}
          >
            <Input
              maxLength={255}
              value={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t(this.moduleName + ".search_plachoder")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        <Col lg={16} xl={5}>
          <Tooltip placement="top" title={t(this.moduleName + ".time_tooltip")}>
            <RangePicker
              value={[
                this.currentStore?.filterData?.from_date &&
                  moment.unix(this.currentStore?.filterData?.from_date / 1000),
                this.currentStore?.filterData?.to_date &&
                  moment.unix(this.currentStore?.filterData?.to_date / 1000),
              ]}
              format={DATE_FORMAT.day_only}
              onChange={(e) => {
                this.currentStore.handleFilterDataChange(
                  "from_date",
                  e?.[0]?.startOf("day").utc().format("x"),
                  false
                );
                this.currentStore.handleFilterDataChange(
                  "to_date",
                  e?.[1]?.endOf("day").utc().format("x")
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("report_result.filter_aggregration_unit")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("aggregation", value)
              }
              value={this.currentStore?.filterData?.aggregation}
            >
              <Option value={null}>
                {t("report_result.all_aggregration_unit")}
              </Option>
              {AGGREGATION_UNIT.map((e) => (
                <Option value={e?.value} key={e?.key}>
                  {t("report_template" + "." + e.key)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("report_result.filter_owner")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("owner_id", value)
              }
              value={this.currentStore?.filterData?.owner_id}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                return (
                  option?.props?.filter
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              <Option value={null}>{t("report_result.all_owner")}</Option>
              {allUsers.map((e) => (
                <Option value={e?.id} key={e?.id} filter={e?.name}>
                  <b>{e?.name}</b> ({e?.email})
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() &&
          this.currentStore?.allAgencies.length > 0 && (
            <Col lg={8} xl={4}>
              <Tooltip
                placement="top"
                title={t(this.moduleName + ".agency_tooltip")}
              >
                <Select
                  labelInValue={true}
                  onChange={(value) =>
                    this.currentStore?.handleFilterDataChange(
                      "agency_id",
                      value.key
                    )
                  }
                  value={{ key: this.currentStore?.filterData.agency_id }}
                >
                  {this.currentStore?.allAgencies?.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Col>
          )}
      </div>
    );
  };
  /**
   * renderDetailData
   * this function return the user detail box
   * @return  {Component}
   */
  renderDetailData = () => {
    return <></>;
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default withRouter(withTranslation()(SummaryReportResult));
