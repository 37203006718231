import { action, observable, runInAction } from "mobx";
import moment from "moment";
import AgencyService from "services/AgencyService";
import DetectorDataService from "services/TemplateGroup/DetectorDataService";
import UserService from "services/UserService";
import BaseStore from "stores/BaseStore";
import {
  DATE_FORMAT,
  INTERSECTION_SCOPE,
  MANUAL_SCOPE,
  RESOURCE_LIST,
  SYSTEM_ROUTES_PATH,
} from "utils/constants";

const DEFAULT_TEMPLATE_FORM = {
  id: null,
  name: null,
  description: null,
  week_days: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  agency_id: null,
  time_zone: "Z",
  start_time: null,
  end_time: null,
  aggregation: "HOURLY",
  date_range: {
    scope: "RELATIVE",
    start_date: null,
    end_date: null,
    unit: "DAY",
    offset: null,
  },
  phases: [],
  metrics: [],
  detectors: [],
  detector_scope: "ALL_DETECTORS",
  intersection: {
    scope: "SPECIFIC_INTERSECTION",
    uuids: [],
  },
  schedule: {
    scope: "MANUAL",
    value: {
      date: moment().format(DATE_FORMAT.day_only_backend),
      offset: 1,
      day_of_week: [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ],
      day_of_month: ["1"],
    },
    time: null,
    mail_receive: true,
  },
};

class DetectorDataStore extends BaseStore {
  constructor(parentStore) {
    super(new DetectorDataService(parentStore), parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        aggregation: null,
        agency_id: "",
        from_date: null,
        to_date: null,
        owner_id: null,
        status: null,
        sort: "",
      },
    };
    this.currentModuleRoute = SYSTEM_ROUTES_PATH.ReportTemplate;
    this.resourceName = RESOURCE_LIST.DETECTOR_DATA_REPORT_RESULT;
  }

  DEFAULT_DETECTOR_SCOPE = "ALL_DETECTORS";

  isEditFormDirty = false; // make sure formData is not be edited
  @observable context_id = null;
  @observable allIntersections = [];
  @observable allAgencies = [];
  @observable allUsers = [];
  @observable currentTab = "1";
  @observable formData = {
    ...DEFAULT_TEMPLATE_FORM,
  };
  @observable selectedRecordRowSchedule = [];
  @observable isSelectedReccuring = false;
  @observable timeValueSelectedOccuring = MANUAL_SCOPE;
  @observable indicators = [];

  DEFAULT_METRIC_SETTING = [];

  @action setTemplateId = (id) => {
    this.context_id = id;
  };

  @action resetForm() {
    this.context_id = null;
    this.formData = {
      ...DEFAULT_TEMPLATE_FORM,
    };
  }

  @action setFormField = (field, value) => {
    this.isEditFormDirty = true;
    const fieldTree = field.split(".");
    switch (fieldTree.length) {
      case 1:
        {
          this.formData[field] = value;
        }
        break;
      case 2:
        {
          this.formData[fieldTree[0]][fieldTree[1]] = value;
        }
        break;
      case 3:
        {
          this.formData[fieldTree[0]][fieldTree[1]][fieldTree[2]] = value;
        }
        break;
    }
  };
  @action setOccuringTime = () => {
    this.isSelectedReccuring = true;
  };
  @action setTimeValueSelectedOccuring = (value) => {
    this.timeValueSelectedOccuring = value;
  };
  @action getAllUsers = (agency_id) => {
    const userService = new UserService(this.parentStore);
    if (this.allUsers?.length === 0) {
      this.loading = true;
      userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = response?.data;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };
  @action getAllAgencies = (cb) => {
    const agencyService = new AgencyService(this.parentStore);
    agencyService.getAll((data) => {
      runInAction(() => {
        this.allAgencies = data;
        if (!this.formData?.agency_id) this.formData.agency_id = data[0]?.id;
        cb && cb();
      });
    });
  };

  @action runTemplate = (id, cb) => {
    this.moduleService.runTemplate(
      id,
      () => {
        runInAction(() => {
          cb && cb();
        });
      },
      () => {}
    );
  };
  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    const agencyService = new AgencyService(this.parentStore);
    if (isSuperAdmin)
      agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };

  @action getTemplateMetrics = (cb) => {
    this.moduleService?.getTemplateMetrics((data) => {
      runInAction(() => {
        this.indicators = data?.metrics;
        if (!this.context_id) {
          this.formData.metrics = data?.metrics?.map((item) => ({
            ...item,
            enabled: false,
            value: 0,
            condition: {
              value: 0,
              operator: null,
            },
            statistic: "TOTAL",
          }));
        }
      });
      cb && cb();
    });
  };

  @action getTemplateDetail = (id, cb) => {
    this.isEditFormDirty = false;
    this.moduleService?.getDetail(id, (response) => {
      runInAction(() => {
        const metrics = response?.general_data?.metrics.map((item) => {
          return {
            ...item,
            condition: item?.condition || {
              value: 0,
              operator: null,
            },
          };
        });

        this.formData = {
          ...DEFAULT_TEMPLATE_FORM,
          ...response?.general_data,
          metrics,
          detectors: response?.general_data?.detectors,
          detector_scope:
            response?.general_data?.detector_scope ||
            this.DEFAULT_DETECTOR_SCOPE,
          date_range: {
            ...DEFAULT_TEMPLATE_FORM.date_range,
            ...response?.general_data?.date_range,
          },
          id: response?.id,
          status: response?.status,
        };
      });
      this.moduleService.getScheduleDetail(id, (response) => {
        runInAction(() => {
          this.formData.schedule = {
            ...DEFAULT_TEMPLATE_FORM.schedule,
            ...response,
            value: {
              ...DEFAULT_TEMPLATE_FORM.schedule.value,
              ...response?.value,
            },
          };

          cb && cb();
        });
      });
    });
  };

  @action changeReportTemplateTab = (tab) => {
    this.currentTab = tab;
  };
  @action setIntersectionList = (scope, ids) => {
    if (scope === INTERSECTION_SCOPE.SPECIFIC) {
      this.formData.intersection = {
        scope: scope,
        uuids: ids.map((e) => e?.id),
      };
    } else
      this.formData.intersection = {
        scope: scope,
        uuids: "*",
      };
  };
  @action submitUpdateSchedule = (cb, fb) => {
    this.moduleService?.updateSchedule(
      {
        id: this.formData.id,
        data: this.formData.schedule,
      },
      (id) => {
        // helper.showNotification(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("update_success"))
        cb && cb(id);
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action submitUpdateTemplate = (cb, fb) => {
    const metrics = this.formData.metrics.map((item) => {
      return {
        ...item,
        condition: item?.condition?.operator !== null ? item.condition : null,
      };
    });

    const general_data = {
      name: this.formData.name,
      description: this.formData.description,
      week_days: this.formData.week_days,
      agency_id: this.formData.agency_id,
      detector_scope: this.formData.detector_scope,
      detectors: this.formData.detectors,
      time_zone: this.formData.time_zone,
      start_time: this.formData.start_time,
      end_time: this.formData.end_time,
      aggregation: this.formData.aggregation,
      date_range: this.formData.date_range,
      metrics: metrics,
      phases: this.formData.phases,
    };

    this.moduleService?.update(
      { ...general_data, id: this.formData.id },
      () => {
        // helper.showNotification(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("update_success"))
        cb && cb();
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action submit = (cb, fb) => {
    this.loading = true;

    const metrics = this.formData.metrics.map((item) => {
      return {
        ...item,
        condition: item?.condition?.operator !== null ? item.condition : null,
      };
    });

    const data = {
      general_data: {
        name: this.formData.name,
        description: this.formData.description,
        week_days: this.formData.week_days,
        agency_id: this.formData.agency_id,
        detector_scope: this.formData.detector_scope,
        detectors: this.formData.detectors,
        time_zone: this.formData.time_zone,
        start_time: this.formData.start_time,
        end_time: this.formData.end_time,
        aggregation: this.formData.aggregation,
        date_range: this.formData.date_range,
        metrics: metrics,
        phases: this.formData.phases,
      },
      intersection: this.formData.intersection,
      schedule: this.formData.schedule,
    };

    this.moduleService?.create(
      data,
      () => {
        cb && cb();
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action setStatus = (data = null, status) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateStatus(
      "/activate",
      { template_ids: data, status },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action delete = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.delete(
      data,
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default DetectorDataStore;
