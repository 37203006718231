import * as d3 from "d3";
import moment from "moment";
import React from "react";
import { CHART_COLOR_SCHEMA, DATE_FORMAT } from "utils/constants";
import BaseRightContent from "../BaseHistoricTimeSpaceChart/BaseRightContent";
const COLOR_LIGHT = {
  YELLOW: CHART_COLOR_SCHEMA.YELLOW,
  RED: CHART_COLOR_SCHEMA.RED,
  GREEN: CHART_COLOR_SCHEMA.LOWER_GREEN,
  COORD_PHASE: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
};
class RightContent extends BaseRightContent {
  constructor(props) {
    super(props);
  }
  drawPlanDataBackground = (dataIntersection, distanceDraw) => {
    const areaCtx = d3
      .area()
      .x0((d) => {
        return this.xScale(d.x1);
      })
      .x1((d) => {
        return this.xScale(d.x2);
      })
      .y((d) => {
        return this.yScale(d.y) + 32;
      })
      .context(this.ctx);
    dataIntersection.plans?.forEach((plan) => {
      const areaCoord = [
        {
          x1: plan.start_point,
          x2: plan.end_point,
          y: dataIntersection.coordY,
        },
        {
          x1: plan.start_point,
          x2: plan.end_point,
          y: dataIntersection.coordY + distanceDraw,
        },
      ];
      this.ctx.beginPath();
      areaCtx(areaCoord);
      this.ctx.fillStyle = "#fff";
      this.ctx.fill();

      this.ctx.closePath();
    });
  };

  drawPlanDataText = (dataIntersection) => {
    dataIntersection.plans?.forEach((plan) => {
      this.ctx.beginPath();
      this.ctx.beginPath();
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillStyle = "#111111";
      this.ctx.fillText(
        `Plan ${plan.plan_num} (${moment(plan.start_point).format(
          DATE_FORMAT.hour
        )} - ${moment(plan.end_point).format(DATE_FORMAT.hour)})`,
        this.xScale(
          new Date((plan.start_point.getTime() + plan.end_point.getTime()) / 2)
        ),
        this.yScale(dataIntersection.coordY) + 96
      );
      this.ctx.closePath();
    });
  };
  drawTimeSpaceLine = (time_space_line, color, space, currentLine) => {
    var area = d3
      .area()
      .x0((d) => {
        return this.xScale(d.x_start);
      })
      .x1((d) => {
        return this.xScale(d.x_end);
      })
      .y((d) => {
        return this.yScale(d.y) + space;
      }) //<-- y1
      .context(this.ctx);
    if (time_space_line) {
      this.ctx.beginPath();
      area(time_space_line);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.lineWidth = 3;

      if (currentLine.max_aog) {
        this.ctx.strokeStyle = CHART_COLOR_SCHEMA.GREEN_SIEMENS;
        this.ctx.stroke();
      }
      if (currentLine.min_aog) {
        this.ctx.strokeStyle = CHART_COLOR_SCHEMA.RED;
        this.ctx.stroke();
      }

      this.ctx.closePath();
    }
  };

  drawGreenWaveLine = (currentLine, space) => {
    var line = d3
      .line()
      .x((d) => {
        return this.xScale(d.x);
      })
      .y((d) => {
        return this.yScale(d.y) + space;
      }) //<-- y1
      .context(this.ctx);
    if (currentLine) {
      currentLine.forEach((gw_line) => {
        this.ctx.beginPath();

        line(gw_line);

        this.ctx.strokeStyle = CHART_COLOR_SCHEMA.GREEN;
        this.ctx.lineWidth = 1;
        this.ctx.stroke();

        this.ctx.closePath();
      });
    }
  };

  drawGreenWavePercent = (dataGWLine, coordY, yOffset, value) => {
    const areaGWPercent = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + yOffset)
      .context(this.ctx);
    if (dataGWLine?.length) {
      const lineCoord = [
        {
          x: dataGWLine[0][0].x,
          y: coordY,
        },
        {
          x: dataGWLine[dataGWLine.length - 1][0].x,
          y: coordY,
        },
      ];

      this.ctx.beginPath();
      areaGWPercent(lineCoord);
      this.ctx.lineWidth = 20;
      this.ctx.strokeStyle = CHART_COLOR_SCHEMA.GREEN;
      this.ctx.stroke();
      this.ctx.closePath();

      this.ctx.beginPath();
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.fillStyle = "#111111";
      this.ctx.fillText(
        value + "%",
        this.xScale(
          new Date(
            (dataGWLine[0][0].x.getTime() +
              dataGWLine[dataGWLine.length - 1][0].x.getTime()) /
              2
          )
        ),
        this.yScale(coordY) + yOffset
      );
      this.ctx.closePath();
    }
  };
  drawLineData = () => {
    const { dataSource, showGreenwave, showUpstreamBand, showDownstreamBand } =
      this.props;
    this.ctx.fillStyle = "black";
    dataSource.intersections.forEach((dataSourceLineIntersection) => {
      // this.drawPlanDataBackground(
      //   dataSourceLineIntersection,
      //   dataSource.intersections[id + 1]
      //     ? dataSource.intersections[id + 1].distance
      //     : dataSourceLineIntersection.distance
      // );

      const dataSourceLineRing1 = dataSourceLineIntersection.ring1.lights;
      const dataSourceLineRing2 = dataSourceLineIntersection.ring2.lights;

      if (
        (dataSourceLineIntersection.upstream_phase ===
          dataSourceLineIntersection.ring2.coord_phase &&
          showUpstreamBand) ||
        (dataSourceLineIntersection.downstream_phase ===
          dataSourceLineIntersection.ring2.coord_phase &&
          showDownstreamBand)
      )
        dataSourceLineRing2.forEach((line) => {
          this.drawTimeSpaceLine(
            line.time_space,

            dataSourceLineIntersection.upstream_phase ===
              dataSourceLineIntersection.ring2.coord_phase
              ? "#c0c0c080"
              : "#acd6ff80",
            56,
            line
          );
        });

      if (
        (dataSourceLineIntersection.upstream_phase ===
          dataSourceLineIntersection.ring1.coord_phase &&
          showUpstreamBand) ||
        (dataSourceLineIntersection.downstream_phase ===
          dataSourceLineIntersection.ring1.coord_phase &&
          showDownstreamBand)
      )
        dataSourceLineRing1.forEach((line) => {
          this.drawTimeSpaceLine(
            line.time_space,
            dataSourceLineIntersection.downstream_phase ===
              dataSourceLineIntersection.ring1.coord_phase
              ? "#acd6ff80"
              : "#c0c0c080",
            36,
            line
          );
        });
    });

    dataSource.intersections.forEach((dataSourceLineIntersection) => {
      const dataSourceLineRing1 = dataSourceLineIntersection.ring1.lights;
      const dataSourceLineRing2 = dataSourceLineIntersection.ring2.lights;

      dataSourceLineRing1.forEach((line) => {
        if (showGreenwave) {
          this.drawGreenWaveLine(line.green_wave_lines, 32);
        }
      });
      dataSourceLineRing2.forEach((line) => {
        // console.log(line, "ring 2");

        if (showGreenwave) {
          this.drawGreenWaveLine(line.green_wave_lines, 56);
        }
      });
    });

    dataSource.intersections.forEach((dataSourceLineIntersection) => {
      const { showTimePhase, showAogPhase } = this.props;
      this.drawPlanDataText(dataSourceLineIntersection);
      const dataSourceLineRing1 = dataSourceLineIntersection.ring1.lights;
      const lineRing1Ctx = d3
        .line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y) + 32)
        .context(this.ctx);
      const lineRing2Ctx = d3
        .line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y) + 56)
        .context(this.ctx);
      dataSourceLineRing1.forEach((line) => {
        //draw all line
        const lineCoord = [
          {
            x: line.start_time,
            y: dataSourceLineIntersection.coordY,
          },
          {
            x: line.end_time,
            y: dataSourceLineIntersection.coordY,
          },
        ];
        this.ctx.beginPath();
        lineRing1Ctx(lineCoord);
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle =
          line.phase === dataSourceLineIntersection.ring1.coord_phase &&
          line.color === "GREEN"
            ? COLOR_LIGHT.COORD_PHASE
            : COLOR_LIGHT[line.color];
        this.ctx.stroke();
        this.ctx.closePath();
        const spaceXscale =
          this.xScale(line.end_time) - this.xScale(line.start_time);
        const space =
          (line.end_time.getTime() - line.start_time.getTime()) / 1000;

        if (line.greenwave_percent && showGreenwave) {
          // draw green text
          //green wave
          const endTimeGreen = new Date(
            line.start_time.getTime() +
              ((line.end_time.getTime() - line.start_time.getTime()) *
                line.greenwave_percent) /
                100
          );
          const greenCoord = [
            {
              x: line.start_time,
              y: dataSourceLineIntersection.coordY,
            },
            {
              x: endTimeGreen,
              y: dataSourceLineIntersection.coordY,
            },
          ];
          this.ctx.beginPath();
          lineRing1Ctx(greenCoord);
          this.ctx.lineWidth = 10;
          this.ctx.strokeStyle = "#ccffff80";
          this.ctx.stroke();
          this.ctx.closePath();

          this.ctx.beginPath();
          this.ctx.textAlign = "center";
          this.ctx.textBaseline = "middle";
          this.ctx.fillStyle = "#111111";

          if (line.greenwave_percent)
            this.ctx.fillText(
              `${line.greenwave_percent?.toFixed(2)}%`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + endTimeGreen.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 32
            );
          this.ctx.closePath();
        }

        this.ctx.beginPath();

        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = "#111111";
        if (spaceXscale >= 60) {
          if (showTimePhase)
            this.ctx.fillText(
              `P${line.phase} (${space}s)`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + line.end_time.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 32
            );

          if (showAogPhase && line.aog_percent !== undefined)
            this.ctx.fillText(
              `P${line.phase}: ${line.aog_percent?.toFixed(2)}%`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + line.end_time.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 32
            );
        }

        this.ctx.closePath();
        if (line.direction_green_wave_percent && showGreenwave) {
          this.drawGreenWavePercent(
            line.green_wave_lines,
            dataSourceLineIntersection.coordY,
            32,
            line.direction_green_wave_percent?.toFixed(2)
          );
        }
      });
      const dataSourceLineRing2 = dataSourceLineIntersection.ring2.lights;
      dataSourceLineRing2.forEach((line) => {
        //draw all line
        const lineCoord = [
          {
            x: line.start_time,
            y: dataSourceLineIntersection.coordY,
          },
          {
            x: line.end_time,
            y: dataSourceLineIntersection.coordY,
          },
        ];
        this.ctx.beginPath();
        lineRing2Ctx(lineCoord);
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle =
          line.phase === dataSourceLineIntersection.ring2.coord_phase &&
          line.color === "GREEN"
            ? COLOR_LIGHT.COORD_PHASE
            : COLOR_LIGHT[line.color];
        this.ctx.stroke();
        this.ctx.closePath();

        const spaceXscale =
          this.xScale(line.end_time) - this.xScale(line.start_time);
        const space =
          (line.end_time.getTime() - line.start_time.getTime()) / 1000;

        this.ctx.beginPath();
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = "#111111";

        if (spaceXscale >= 60) {
          if (showTimePhase)
            this.ctx.fillText(
              `P${line.phase} (${space}s)`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + line.end_time.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 56
            );

          if (showAogPhase && line.aog_percent !== undefined)
            this.ctx.fillText(
              `P${line.phase}: ${line.aog_percent?.toFixed(2)}%`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + line.end_time.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 56
            );
        }
        if (line.greenwave_percent && showGreenwave) {
          // draw green text
          //green wave
          const endTimeGreen = new Date(
            line.start_time.getTime() +
              ((line.end_time.getTime() - line.start_time.getTime()) *
                line.greenwave_percent) /
                100
          );
          const greenCoord = [
            {
              x: line.start_time,
              y: dataSourceLineIntersection.coordY,
            },
            {
              x: endTimeGreen,
              y: dataSourceLineIntersection.coordY,
            },
          ];
          this.ctx.beginPath();
          lineRing2Ctx(greenCoord);
          this.ctx.lineWidth = 10;
          this.ctx.strokeStyle = "#ccffff80";
          this.ctx.stroke();
          this.ctx.closePath();

          this.ctx.beginPath();
          this.ctx.textAlign = "center";
          this.ctx.textBaseline = "middle";
          this.ctx.fillStyle = "#111111";
          if (line.greenwave_percent)
            this.ctx.fillText(
              `${line.greenwave_percent.toFixed(2)}%`,
              this.xScale(
                new Date(
                  (line.start_time.getTime() + endTimeGreen.getTime()) / 2
                )
              ),
              this.yScale(lineCoord[0].y) + 56
            );
          this.ctx.closePath();
        }

        this.ctx.closePath();
        if (line.direction_green_wave_percent && showGreenwave) {
          this.drawGreenWavePercent(
            line.green_wave_lines,
            dataSourceLineIntersection.coordY,
            56,
            line.direction_green_wave_percent?.toFixed(2)
          );
        }
      });
      this.drawScatterLine(dataSourceLineIntersection.coordY);
      this.drawScatter(
        dataSourceLineIntersection.ring1.vehicles,
        dataSourceLineIntersection.coordY,
        14
      );
      this.drawScatter(
        dataSourceLineIntersection.ring2.vehicles,
        dataSourceLineIntersection.coordY,
        74
      );
    });
  };
  drawScatterLine = (y) => {
    const rangeX = this.xScale.domain();
    const upperLineCtx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 14)
      .context(this.ctx);
    const downlineCtx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 74)
      .context(this.ctx);
    this.ctx.beginPath();
    upperLineCtx([
      { x: rangeX[0], y },
      { x: rangeX[1], y },
    ]);
    downlineCtx([
      { x: rangeX[0], y },
      { x: rangeX[1], y },
    ]);
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = "black";
    this.ctx.stroke();
    this.ctx.closePath();
  };
  drawScatter = (dataX, y, space) => {
    dataX?.forEach((point) => {
      this.ctx.fillStyle = CHART_COLOR_SCHEMA.DURATION;

      // start a new path for drawing
      this.ctx.beginPath();
      // paint an arc based on information from the DOM node
      this.ctx.arc(
        this.xScale(point.time),
        this.yScale(y) + space,
        3,
        0,
        2 * Math.PI
      );
      // fill the point
      this.ctx.fill();
      this.ctx.closePath();
    });
  };
  seekTo = (value) => {
    const { rangeX } = this.props;
    const currentBrushRange = [
      Number(this.brush.select(".selection").attr("x")),
      Number(this.brush.select(".selection").attr("x")) +
        Number(this.brush.select(".selection").attr("width")),
    ];

    const change =
      ((this.xScale(new Date(rangeX[0].getTime() + value)) -
        this.xScale(rangeX[0])) *
        this.axisScroll.current.offsetWidth) /
      this.rightConfig.width;
    if (currentBrushRange[1] + change >= this.axisScroll.current.offsetWidth) {
      this.brush
        .transition()
        .call(this.brushObject.move, [
          this.axisScroll.current.offsetWidth -
            currentBrushRange[1] +
            currentBrushRange[0],
          this.axisScroll.current.offsetWidth,
        ]);
      return;
    }
    if (currentBrushRange[1] + change <= 0) {
      this.brush
        .transition()
        .call(this.brushObject.move, [
          0,
          currentBrushRange[1] - currentBrushRange[0],
        ]);
      return;
    }
    this.brush
      .transition()
      .call(this.brushObject.move, [
        currentBrushRange[0] + change,
        currentBrushRange[1] + change,
      ]);
  };
  drawContent = () => {
    this.drawXaxis();
    this.drawLineData();
  };

  reDrawContent({ xScale, yScale }) {
    this.xScale = xScale;
    this.yScale = yScale;

    this.axisCtx.clearRect(0, 0, this.axisCtx.canvas.width, 16);
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    this.axisCtx.canvas.width = this.chartRef.current.offsetWidth;
    this.ctx.canvas.width = this.chartRef.current.offsetWidth;
    this.ctx.canvas.height = this.chartRef.current.offsetHeight;

    this.drawXaxis();
    this.drawLineData();
  }

  render() {
    const { height, openRightPanel } = this.props;
    return (
      <>
        <div
          style={{
            width: openRightPanel ? "calc(100% - 600px)" : "calc(100% - 320px)",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              left: 300,
              zIndex: 25,
              background: "white",
            }}
            ref={this.axisScroll}
          >
            <svg width="100%" height="16" ref={this.brushRef}></svg>
            <div style={{ overflowX: "hidden" }} ref={this.axisAreaRef}></div>
          </div>
          <div
            style={{
              height: height,
              overflowX: "hidden",
              overflowY: "hidden",
            }}
            ref={this.chartRef}
          ></div>
        </div>
      </>
    );
  }
}

export default RightContent;
