import * as d3 from "d3";
import moment from "moment";
import React, { createRef } from "react";
import {
  CHART_COLOR_SCHEMA,
  DATE_FORMAT,
  FONT_STRING_CAVAS,
} from "utils/constants";
import BaseHistoricTimeSpaceChart from "../BaseHistoricTimeSpaceChart";
import LeftContent from "../ActualTimespaceChart/LeftContent";
import RightContent from "../ActualTimespaceChart/RightContent";
import RightExtendAogContent from "../ActualTimespaceChart/RightExtendAogContent";
import { Icon } from "antd";

class ActualChart extends BaseHistoricTimeSpaceChart {
  constructor(props) {
    super(props);
    this.aogRef = createRef();
    this.state = {
      openRightPanel: true,
    };
    this.rightPanelWidth = 300;
  }

  handleMouseMove = () => {
    const width = this.areaRef.current.offsetWidth;
    var mouseX = d3.event.layerX || d3.event.offsetX;
    var mouseY = d3.event.layerY || d3.event.offsetY;
    const yOffset = mouseY > this.areaRef.current.offsetHeight / 2 ? 0 : 120;

    this.hoverCtx.clearRect(0, 0, width, this.listHeight);
    const lineCtx = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .context(this.hoverCtx);
    this.hoverCtx.beginPath();
    lineCtx([
      {
        x: mouseX,
        y: 0,
      },
      {
        x: mouseX,
        y: this.listHeight,
      },
    ]);
    lineCtx([
      {
        x: 0,
        y: mouseY,
      },
      {
        x: width,
        y: mouseY,
      },
    ]);
    this.hoverCtx.lineWidth = 0.5;
    this.hoverCtx.strokeStyle = "black";
    this.hoverCtx.stroke();
    this.hoverCtx.closePath();

    //hightlight point
    // console.log(mouseX);
    const realXCoord = mouseX;
    // console.log(mouseX);
    const realYCoord =
      mouseY - this.rightRef.current.chartRef.current?.offsetTop;
    // const realYCoord = mouseY - this.leftContentWidth;
    const listHightLightRing1 = [];
    const listHightLightRing2 = [];
    this.dataSource.intersections.forEach((int) => {
      int.ring1.vehicles.forEach((point) => {
        // console.log(this.yScale(int.coordY), realYCoord);
        const xTime = point.time;
        if (
          xTime.getTime() >= this.xScale.domain()[0].getTime() &&
          xTime.getTime() <= this.xScale.domain()[1].getTime() &&
          this.xScale(xTime) <= realXCoord + 4 &&
          this.xScale(xTime) >= realXCoord - 4 &&
          this.yScale(int.coordY) <= realYCoord &&
          this.yScale(int.coordY) >= realYCoord - 60
        ) {
          listHightLightRing1.push({
            ...point,
            x: xTime,
            rootY: int.coordY,
            y: int.coordY,
          });
        }
      });
      int.ring2.vehicles.forEach((point) => {
        const xTime = point.time;
        if (
          xTime.getTime() >= this.xScale.domain()[0].getTime() &&
          xTime.getTime() <= this.xScale.domain()[1].getTime() &&
          this.xScale(xTime) <= realXCoord + 4 &&
          this.xScale(xTime) >= realXCoord - 4 &&
          this.yScale(int.coordY) <= realYCoord &&
          this.yScale(int.coordY) >= realYCoord - 60
        ) {
          listHightLightRing2.push({
            ...point,
            x: xTime,
            rootY: int.coordY,
            y: int.coordY,
          });
        }
      });
    });
    this.hoverCtx.beginPath();
    this.hoverCtx.fillStyle = "white";
    this.hoverCtx.fillRect(
      mouseX > window.innerWidth / 2 ? mouseX - 198 : mouseX + 10,
      mouseY - 100 + yOffset,
      188,
      listHightLightRing1.length + listHightLightRing2.length
        ? (listHightLightRing1.length + listHightLightRing2.length) * 24 + 8
        : 0
    );
    this.hoverCtx.closePath();

    //draw x y axis of mouse
    // this.hoverCtx.beginPath();
    // this.hoverCtx.font = `16px ${FONT_STRING_CAVAS}`;
    // this.hoverCtx.fillStyle = "black";
    // const time = this.xScale.invert(realXCoord);
    // const startFillText =
    //   mouseX > window.innerWidth / 2 ? mouseX - 165 : mouseX + 15;
    // this.hoverCtx.fillText(
    //   `Time: ${moment(time).format(DATE_FORMAT.time_only_full)}`,
    //   startFillText,
    //   mouseY - 80
    // );
    // this.hoverCtx.fillText(
    //   `CoordY: ${this.yScale.invert(mouseY).toFixed(2)}`,
    //   startFillText,
    //   mouseY - 56
    // );
    // this.hoverCtx.closePath();

    let h = 4;
    listHightLightRing1?.forEach((point) => {
      this.hoverCtx.font = `16px ${FONT_STRING_CAVAS}`;
      this.hoverCtx.fillStyle = "black";
      this.hoverCtx.beginPath();
      this.hoverCtx.fillText(
        point.phase !== undefined
          ? `Time: ${moment(point.x).format(DATE_FORMAT.time_only_full)}, P ${
              point.phase
            } - D ${point.det_num}`
          : `Time: ${moment(point.x).format(DATE_FORMAT.time_only_full)}`,
        mouseX > window.innerWidth / 2 ? mouseX - 190 : mouseX + 15,
        mouseY - 80 + h + yOffset
      );
      h += 24;
      this.hoverCtx.closePath();

      this.hoverCtx.fillStyle = CHART_COLOR_SCHEMA.DURATION;
      this.hoverCtx.beginPath();
      this.hoverCtx.arc(
        this.xScale(point.x) -
          this.rightRef.current.chartRef.current?.scrollLeft,
        this.yScale(point.y) + 56,
        8,
        0,
        2 * Math.PI
      );

      // fill the point
      this.hoverCtx.fill();
      this.hoverCtx.closePath();
    });
    listHightLightRing2?.forEach((point) => {
      this.hoverCtx.font = `16px ${FONT_STRING_CAVAS}`;
      this.hoverCtx.fillStyle = "black";
      // start a new path for drawing
      this.hoverCtx.beginPath();
      // paint an arc based on information from the DOM node
      this.hoverCtx.fillText(
        point.phase !== undefined
          ? `Time: ${moment(point.x).format(DATE_FORMAT.time_only_full)}, P ${
              point.phase
            } - D ${point.det_num}`
          : `Time: ${moment(point.x).format(DATE_FORMAT.time_only_full)}`,
        mouseX > window.innerWidth / 2 ? mouseX - 190 : mouseX + 15,

        mouseY - 80 + h + yOffset
      );
      h += 24;
      this.hoverCtx.closePath();

      this.hoverCtx.fillStyle = CHART_COLOR_SCHEMA.DURATION;
      this.hoverCtx.beginPath();
      this.hoverCtx.arc(
        this.xScale(point.x) -
          this.rightRef.current.chartRef.current?.scrollLeft,
        this.yScale(point.y) + 116,
        6,
        0,
        2 * Math.PI
      );

      // fill the point
      this.hoverCtx.fill();
      this.hoverCtx.closePath();
    });
    // this.rightRef.current.handleMouseMove(mouseX, mouseY);
  };

  drawHiddenCanvas = () => {
    const { start_time, end_time } = this.props;
    const width = this.areaRef.current.offsetWidth;
    this.hoverCtx?.clearRect(0, 0, width, this.listHeight);
    const base = d3.select(this.areaRef.current);
    base.selectAll(".hiddenCanvas").remove();
    this.hoverCtx?.clearRect(0, 0, width, this.listHeight);

    this.hiddenCanvas = base
      .append("canvas")
      .classed("hiddenCanvas", true)
      .attr("width", width)
      .attr("height", this.listHeight);
    this.hoverCtx = this.hiddenCanvas.node().getContext("2d");
    d3.select(this.hiddenCanvas.node()).on("mousemove", this.handleMouseMove);
    d3.select(this.hiddenCanvas.node()).on("mouseleave", this.handleMouseOut);

    d3.select(this.hoverCtx.canvas).call(
      d3
        .zoom()
        .scaleExtent([0.25, 5])
        .extent([
          [0, 0],
          [
            this.areaRef.current.offsetWidth * 10,
            this.areaRef.current.offsetHeight * 10,
          ],
        ])
        .on("zoom", () => {
          const tranformXScale = d3.event.transform.rescaleX(this.rootXScale);
          const startTime =
            start_time.getTime() > tranformXScale.domain()[0].getTime()
              ? start_time
              : tranformXScale.domain()[0];
          const endTime =
            end_time.getTime() < tranformXScale.domain()[1].getTime()
              ? end_time
              : tranformXScale.domain()[1];
          // console.log(startTime, endTime);
          this.rightRef.current.handleBrushZoom(startTime, endTime);
          this.xScale = d3
            .scaleTime()
            .range([0, this.areaRef.current.offsetWidth - 20])
            .domain(d3.extent([startTime, endTime]));
        })
    );
  };

  handleBrushZoom = (xStart, xEnd) => {
    this.xScale = d3
      .scaleTime()
      .range([0, this.areaRef.current.offsetWidth - 20])
      .domain(d3.extent([xStart, xEnd]));
    this.reDrawContent();
  };

  reDrawContent() {
    // this.drawHiddenCanvas();
    this.leftRef.current?.drawContent(this.yScale);
    this.aogRef.current?.drawContent(this.yScale);
    this.rightRef.current?.reDrawContent({
      xScale: this.xScale,
      yScale: this.yScale,
    });
  }

  renderRightContent = () => {
    const { data, start_time, end_time } = this.props;
    return (
      <RightContent
        {...this.props}
        handleBrushZoom={this.handleBrushZoom}
        start_time={start_time}
        end_time={end_time}
        openRightPanel={this.state.openRightPanel}
        dataSource={data}
        ref={this.rightRef}
        yScale={this.yScale}
        height={this.listHeight}
        xScale={this.xScale}
        rangeX={this.rangeX}
      />
    );
  };
  renderLeftContent = () => {
    return (
      <LeftContent
        dataSourceIntersection={this.dataSource?.intersections}
        ref={this.leftRef}
        height={this.listHeight}
        width={this.leftContentWidth}
        yScale={this.yScale}
        rangeX={this.rangeX}
        seekTo={this.seekTo}
      />
    );
  };

  render() {
    return (
      <div>
        <div
          ref={this.parentRef}
          className="chart-containter"
          style={{ height: this.props.height, overflow: "hidden" }}
        >
          {this.renderLeftContent()}
          {this.renderRightContent()}
          <div
            style={{
              width: this.state.openRightPanel ? this.rightPanelWidth : 20,
              height: this.listHeight + 37,
            }}
            className={"aog-statistic"}
          >
            {this.state.openRightPanel ? (
              <RightExtendAogContent
                onCollapsePanel={() => {
                  this.setState({ openRightPanel: false });
                  this.rightPanelWidth = 20;
                }}
                ref={this.aogRef}
                xScale={this.xScale}
                yScale={this.yScale}
                dataSource={this.dataSource}
                width={300}
                height={this.listHeight}
              />
            ) : (
              <div
                className="clickable"
                style={{
                  position: "sticky",
                  zIndex: 25,
                }}
                onClick={() => {
                  this.setState({ openRightPanel: true });
                  this.rightPanelWidth = 300;
                }}
              >
                <Icon type="caret-left" />
              </div>
            )}
          </div>
        </div>

        <div
          ref={this.areaRef}
          style={{
            width: "100%",
            height: 0,
          }}
          className="hiddenContent"
        ></div>
      </div>
    );
  }
}

export default ActualChart;
