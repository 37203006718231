import queryString from "query-string";
import Api from "services/Api";
import BaseApi from "services/BaseApi";

class DetectorResultService extends BaseApi {
  constructor(parentStore) {
    super("detector-report/results", parentStore);
    this.dataKey = "results";
  }
  /**
   * getRuleDetail
   * this function return detail of rule
   * @param   {int} id  rule id
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getDetail = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
    // cb(mockDetectorDetail);
  };

  getDetailIntersectionChartDetail = ({ report_id, int_id }, cb, fb) => {
    const api = new Api(
      this.moduleName + "/" + report_id + "/intersections/" + int_id,
      this.parentStore
    );
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      (err) => {
        console.log(err);
        fb && fb();
      }
    );
    // cb(mockDetectorDetail);
  };
  delete = (params, cb, fb) => {
    const api = new Api(
      this.moduleName +
        "?" +
        queryString.stringify(params, { arrayFormat: "brackets" }),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
}

export default DetectorResultService;
