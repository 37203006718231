import {
  Button,
  Icon,
  Input,
  InputNumber,
  Popover,
  Radio,
  Select,
  Tooltip,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import {
  DATE_FORMAT,
  DELAY_SEARCH_STRING,
  RESOURCE_LIST,
} from "utils/constants";
import BaseManagementComponents from "../../../components/BaseManagementComponents";
import EventHistoryDetailPopup from "../EventHistoryDetailPopup";
import QueryTimePopup from "../QueryTimePopup";
/**
 * Component for Application layout
 *
 * @component
 */

@inject("rootStore")
@observer
class TSPEventHistory extends BaseManagementComponents {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore.TSPEventHistoryStore, props);
    this.resourceName = RESOURCE_LIST.REPORT_TEMPLATE;
    this.pageTitle = props.t("tsp_dashboard.tsp_event_history");
    this.enableCreateButton = false;
    this.timeoutRouteChange = null;
    this.state = {
      visible: false,
    };
    this.columns = this.getTableColumns();
  }

  renderFilterComponent = () => {
    const {
      t,
      rootStore: { TSPStore },
    } = this.props;
    return (
      <div className="flex justify-space-beetwen int-event-history-filter-pane">
        <div>
          <div className="flex int-event-history-filter">
            <div>
              <Tooltip title={t("tsp_dashboard.search_by_int_id")}>
                <Input
                  disabled={this.currentStore.loading}
                  size="large"
                  style={{ width: 300 }}
                  placeholder={t("tsp_dashboard.search_by_int_id")}
                  value={this.currentStore?.filterData?.text}
                  onChange={(e) => {
                    this.currentStore?.handleFilterDataChange(
                      "text",
                      e?.target.value
                    );
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title={t("tsp_dashboard.filter_by_tsp_event")}>
                <Select
                  disabled={this.currentStore.loading}
                  value={this.currentStore.filterData?.event_type}
                  onChange={(e) =>
                    this.currentStore.handleFilterDataChange("event_type", e)
                  }
                >
                  <Select.Option value={null}>
                    {t("tsp_dashboard.all_tsp_event")}
                  </Select.Option>
                  {TSPStore.listAllEventTypes?.map((e, id) => (
                    <Select.Option key={id} value={e}>
                      {e}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </div>
            <div>
              <Tooltip title={t("tsp_dashboard.filter_by_route_number")}>
                <InputNumber
                  disabled={this.currentStore.loading}
                  size="large"
                  placeholder={t(
                    "tsp_dashboard.filter_by_route_number_placeholder"
                  )}
                  style={{ minWidth: 100, fontSize: 14 }}
                  max={100}
                  min={1}
                  onChange={(value) => {
                    if (this.timeoutRouteChange)
                      clearTimeout(this.timeoutRouteChange);
                    this.timeoutRouteChange = setTimeout(() => {
                      if (Number.isInteger(value) || !value)
                        this.currentStore?.handleFilterDataChange(
                          "route_number",
                          value
                        );
                    }, DELAY_SEARCH_STRING);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className="flex">
            <div lg={12}>
              <Radio.Group
                disabled={this.currentStore.loading}
                size="large"
                value={this.currentStore.filterData?.range}
                onChange={(e) => this.handleQuerytimeChange(e.target.value)}
              >
                <Radio.Button value={3600000}>
                  1 {t("tsp_dashboard.hour")}
                </Radio.Button>
                <Radio.Button value={43200000}>
                  12 {t("tsp_dashboard.hours")}
                </Radio.Button>
                <Radio.Button value={86400000}>
                  24 {t("tsp_dashboard.hours")}
                </Radio.Button>
                <Popover
                  content={
                    <QueryTimePopup
                      closeModal={() => this.setState({ visible: false })}
                      currentStore={this.currentStore}
                    />
                  }
                  title={t("tsp_dashboard.custom_query")}
                  trigger="click"
                  placement="bottomLeft"
                  visible={this.state.visible}
                  onVisibleChange={(visible) => {
                    this.setState({ visible });
                    this.currentStore.handleFilterDataChange(
                      "range",
                      undefined,
                      false
                    );
                  }}
                >
                  <Radio.Button
                    value={undefined}
                    onClick={() => this.setState({ visible: true })}
                  >
                    {t("tsp_dashboard.custom")} <Icon type="calendar" />
                  </Radio.Button>
                </Popover>
              </Radio.Group>
            </div>
            {this.currentStore.filterData.range && (
              <div>
                <Tooltip placement="top" title={t("dashboard.refresh_tooltip")}>
                  <Button
                    loading={this.currentStore.loading}
                    type="primary"
                    icon="reload"
                    size="large"
                    className="auto-reload-btn"
                    onClick={() => {
                      this.handleQuerytimeChange(
                        this.currentStore.filterData.range
                      );
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className="description">
            <p>
              <b>
                {t("tsp_dashboard.query_time")} {": "}
              </b>
              <span>
                {moment(this.currentStore.filterData?.from_time).format(
                  DATE_FORMAT.tsp_full
                )}
                {" - "}
                {moment(this.currentStore.filterData?.to_time).format(
                  DATE_FORMAT.tsp_full
                )}
                {", "}
                {moment().local().format(DATE_FORMAT.zone_only)}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  handleQuerytimeChange = (value) => {
    const now = moment().valueOf();
    if (typeof value === "number") {
      this.currentStore.handleFilterDataChange("to_time", now, false);
      this.currentStore.handleFilterDataChange("from_time", now - value, false);
      this.currentStore.handleFilterDataChange("range", value);
    }
  };
  getTableColumns() {
    const { t } = this.props;
    return [
      {
        title: t("tsp_dashboard.time_stamp"),
        key: "date_time_stamp",
        render: (item) => {
          return (
            // <a
            //   onClick={() =>
            //     this.currentStore?.showCreateOrEditForm(
            //       t("tsp_dashboard.tsp_event_show_form"),
            //       item.id,
            //       false
            //     )
            //   }
            // >
            //   {moment(item.date_time_stamp)
            //     .local()
            //     .format(DATE_FORMAT.time_only_full)}
            // </a>
            item.date_time_stamp
          );
        },
        sorter: true,
      },
      {
        title: t("tsp_dashboard.int_uuid"),
        dataIndex: "intersection_uuid",
        key: "intersection_uuid",
        sorter: true,
      },
      {
        title: t("tsp_dashboard.int_name"),
        dataIndex: "intersection_name",
        key: "intersection_name",
        sorter: true,
      },

      {
        title: t("tsp_dashboard.event_type"),
        dataIndex: "event_type",
        key: "event_type",
        sorter: true,
      },
      {
        title: t("tsp_dashboard.route_number"),
        dataIndex: "route_number",
        key: "route_number",
        sorter: true,
      },
      // {
      //   title: t("tsp_dashboard.vehicle_info"),
      //   render: (item) => {
      //     return (
      //       <div>
      //         {item?.vehicle_id} | {t("tsp_dashboard.lat")}:{" "}
      //         {item?.vehicle_location.latitude}, {t("tsp_dashboard.lon")}:{" "}
      //         {item?.vehicle_location.longitude}
      //       </div>
      //     );
      //   },
      // },
      {
        title: t("tsp_dashboard.vehicle_info"),
        dataIndex: "vehicle_info",
        key: "vehicle_info",
        sorter: true,
      },
      {
        title: t("tsp_dashboard.request_id"),
        dataIndex: "request_id",
        key: "request_id",
      },
    ];
  }
  renderDetailData = () => {
    return (
      <EventHistoryDetailPopup
        currentStore={this.currentStore}
        childRef={(ref) => (this.defailFromRef = ref)}
      ></EventHistoryDetailPopup>
    );
  };
  componentDidMount = () => {};

  render() {
    return <div className="wrap-tsp_table-page ">{super.render()}</div>;
  }
}

export default TSPEventHistory;
