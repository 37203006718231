import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AdjustmentResult from "../AdjustmentResult";

@inject("rootStore")
@observer
class AogChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
    };
    this.timeoutResize = null;
  }

  tranformDataChart = (data) => {
    return data?.offset_results?.map((item) => {
      const object = {
        offset: item.offset,
      };
      item.predicted_list.forEach((predict) => {
        object["predicted_phase_" + predict.phases] = predict.predicted_aog;
      });
      object.total_aog = item.total_aog;
      return object;
    });
  };
  render() {
    const { aogData, t, isPrintMode } = this.props;
    const data = aogData;
    const offsetTranformData = this.tranformDataChart(aogData);

    return (
      <AdjustmentResult
        t={t}
        height={"70vh"}
        chartType={"aog"}
        gapdata={[]}
        isPrintMode={isPrintMode}
        onChartZoom={() => {}}
        rootData={data}
        data={{
          offset_results: offsetTranformData,
          plan_statistics: [],
          current_offset: data?.current_offset,
        }}
      />
    );
  }
}

export default withTranslation()(AogChart);
