import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ReportInfo from "./ReportInfo";
import ReportResultPane from "./ReportResultPane";

@inject("rootStore")
@observer
class DetectorReportResultDetail extends Component {
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const {
      t,
      match: { params },
      rootStore: { detectorResultStore },
    } = this.props;
    document.title = t("report_result.page-title");
    detectorResultStore.getResultData(params.id);
  }

  render() {
    return (
      <div className="wrap-report-detector-result">
        <div className="wrap-report-detector-detail">
          <ReportInfo />
        </div>
        <div className="wrap-report-detector-content">
          <ReportResultPane />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(DetectorReportResultDetail));
