import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { CORRIDOR_OFFSET_OPTIMIZATION_MODE } from "utils/constants";
import CorridorBarChartView from "./CorridorBarChartView";
import CorridorOffsetTableView from "./CorridorOffsetTableView";
import HeaderCorridorOffsetOptimiztion from "./HeaderCorridorOffsetOptimiztion";
import "./index.scss";
import PrintPreview from "./PrintPreview";
@inject("rootStore")
@observer
class CorridorOffsetOptimization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
    };
    this.state = {
      printMode: false,
      visibleTableChart: false,
      visibleTableInfo: false,
      chartType: CORRIDOR_OFFSET_OPTIMIZATION_MODE.PIE,
    };

    this.timeoutResize = null;
  }
  handleChangeMode = (mode) => {
    this.setState({
      chartType: mode,
    });
  };
  render() {
    const {
      t,
      rootStore: {
        optimizerStore: {
          corridorOffsetStore: { tranformCorridorOffsetData },
        },
        myStore: { currentProfile },
      },
    } = this.props;

    return (
      <div className="h-full flex flex-col wrap-corridor-offset-result">
        <Modal
          title={t("print.modal-title")}
          visible={this.state.printMode}
          width="80vw"
          centered
          destroyOnClose={true}
          footer={[]}
          onCancel={() => this.setState({ printMode: false })}
        >
          <PrintPreview
            data={tranformCorridorOffsetData}
            reporter={currentProfile.name}
          />
        </Modal>
        <HeaderCorridorOffsetOptimiztion
          print={() => this.setState({ printMode: true })}
          chartType={this.state.chartType}
          handleChangeMode={this.handleChangeMode}
        />
        {this.state.chartType === CORRIDOR_OFFSET_OPTIMIZATION_MODE.TABLE ? (
          <CorridorOffsetTableView />
        ) : (
          <CorridorBarChartView />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation()(CorridorOffsetOptimization));
