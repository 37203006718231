import { Col, Form, Icon, Input, Row, Select, Typography } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ANALYSIS_TYPE,
  DATE_FORMAT,
  DATE_RAGNGE_SELECT,
  DYNAMIC_FIELD_TYPE,
  MANUAL_SCOPE,
  REPORT_TAB,
  TIME_TO_RUN_REPORT,
  WEEK_DAY_OPTION,
} from "utils/constants";
import { TIMEZONES } from "utils/timezone";
import PerflogFilterSubForm from "./PerformanceMetricSubForms/PerflogFilterSubForm";
import AbnormalSubForm from "./PerformanceMetricSubForms/AbnormalSubForm";
import DefaultSubForm from "./PerformanceMetricSubForms/DefaultSubForm";

const MAX_DAY_RANGE = 7;
const { Option } = Select;
const HORIZONTAL_LAYOUT = "horizontal";
const { TextArea } = Input;

@inject("rootStore")
@observer
class GeneralInfoTab extends Component {
  constructor(props) {
    super(props);
    this.moduleName = "analysis_schedule";
    this.formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    props.childRef && props.childRef(this);
  }

  componentDidMount() {
    const {
      rootStore: {
        analysisScheduleStore: {
          setFormField,
          getAllAgencies,
          getAllPerflogEvent,
          setIsEditToFalse,
        },
        commonStore,
        myStore,
      },
    } = this.props;

    getAllPerflogEvent();

    const currentAgency = myStore.currentAgency;
    const superAdmin = commonStore.isSuperAdmin();
    if (!superAdmin) {
      setFormField("general_data.agency_id", currentAgency.agency_id);
    } else {
      getAllAgencies();
    }
    setIsEditToFalse();
  }

  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      //specific for time_range case
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field == e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  handleUpdate = () => {
    const {
      rootStore: {
        analysisScheduleStore: {
          getTemplateDetail,
          submitUpdateTemplate,
          formData: { general_data },
        },
      },
    } = this.props;
    this.props.form.validateFields((err) => {
      if (!err) {
        submitUpdateTemplate(
          () => {
            getTemplateDetail(general_data.id);
          },
          (error) => {
            this.setFormError(error);
          }
        );
      }
    });
  };
  /**
   * handleNext
   *
   * @return  {Object}
   */
  handleNext = () => {
    const {
      rootStore: {
        analysisScheduleStore: { changeReportTemplateTab, formData },
      },
    } = this.props;
    this.props.form.validateFields((err) => {
      if (!err) {
        if (
          formData.date_range?.scope === DATE_RAGNGE_SELECT.DATE_RANGE.value &&
          [
            TIME_TO_RUN_REPORT.EVERY_DAY,
            TIME_TO_RUN_REPORT.DAY_OF_WEEK,
            TIME_TO_RUN_REPORT.DAY_OF_MONTH,
          ].includes(formData.schedule?.scope)
        ) {
          setFormField("schedule.scope", MANUAL_SCOPE);
        }
        changeReportTemplateTab(REPORT_TAB.INTERSECTION_TAB);
      }
    });
  };
  checkVaildTime = (time, value, callback) => {
    const {
      t,
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props;

    if (
      value?.isBefore(moment(general_data.metadata.to_time, DATE_FORMAT.hour))
    ) {
      callback();
    } else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  };

  checkToTime = (time, value, callback) => {
    const {
      t,
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props;

    switch (general_data.metric_type) {
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        if (
          moment
            .duration(
              moment(general_data.metadata.to_time, DATE_FORMAT.hour) -
              moment(general_data.metadata.from_time, DATE_FORMAT.hour)
            )
            .as("hours") <= 3
        ) {
          return callback();
        } else {
          callback(t("analysis.max_time_range"));
          break;
        }
      default:
        return callback();
    }
  };

  renderDayOfWeekWarning = () => {
    const {
      t,
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props;
    const startWeekdayMoment = moment(
      general_data.date_range.start_date,
      DATE_FORMAT.day_only_backend
    );
    const endWeekdayMoment = moment(
      general_data.date_range.end_date,
      DATE_FORMAT.day_only_backend
    );

    if (
      general_data.date_range?.scope === DATE_RAGNGE_SELECT.DATE_RANGE.value &&
      general_data.week_days.length !== 7
    ) {
      let checkDayofWeek = true;
      if (endWeekdayMoment.diff(startWeekdayMoment, "days") >= 7) {
        checkDayofWeek = false;
      } else {
        const startWeekday = startWeekdayMoment.day();
        const endWeekday = endWeekdayMoment.day();
        for (let i = startWeekday; i <= endWeekday; i++) {
          if (!general_data.week_days.includes(WEEK_DAY_OPTION[i]?.value))
            checkDayofWeek = false;
        }
      }
      return checkDayofWeek ? (
        <></>
      ) : (
        <Typography.Text type="warning" className="ml-8">
          <Icon type="warning" className="mr-4"/>
          {t("report_template.day_of_week_out_range_warning")}
        </Typography.Text>
      );
    } else return <></>;
  };
  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      //specific for time_range case
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field === e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  onChangeDateScope = (field, value) => {
    this.onChangeField(field, value);
    return value;
  };
  onChangeField = (field, value) => {
    const {
      rootStore: {
        analysisScheduleStore: { setFormField },
      },
    } = this.props;
    setFormField(field, value);
    return value;
  };

  onChangeDateRange = (field, value) => {
    this.onChangeField(
      "general_data.date_range.start_date",
      value[0].format(DATE_FORMAT.day_only_backend)
    );
    this.onChangeField(
      "general_data.date_range.end_date",
      value[1].format(DATE_FORMAT.day_only_backend)
    );
    return value;
  };

  renderExtraFields = (typeId) => {
    const {
      t,
      form: { getFieldDecorator },
      rootStore: {
        analysisScheduleStore: { allAnalysisTypes, formData, setFormField },
      },
    } = this.props;
    const selectedType = allAnalysisTypes?.find(
      (type) => type.id === formData.general_data.metric_type
    );

    return selectedType?.extra_fields.map((extraField) => {
      return (
        <Form.Item label={extraField.label} key={typeId + "_" + extraField.id}>
          {extraField.type === DYNAMIC_FIELD_TYPE.LIST ? (
            getFieldDecorator(typeId + "_" + extraField.id, {
              rules: [
                {
                  required: true,
                  message: t("analysis." + extraField.id + "_required"),
                },
              ],
              initialValue: formData?.general_data.metadata[extraField.id]
                ? formData?.general_data.metadata[extraField.id]
                : extraField.default_value,
              getValueFromEvent(e) {
                return setFormField("general_data.metadata.bin_size", e);
              },
            })(
              <Select>
                {extraField.values?.map((e) => (
                  <Option key={e?.value} value={e?.value}>
                    {e?.display}
                  </Option>
                ))}
              </Select>
            )
          ) : (
            <Input
              maxLength={255}
              onChange={(value) =>
                analysisStore?.handleFilterDataChange(extraField.key, value)
              }
            />
          )}
        </Form.Item>
      );
    });
  };
  checkOutRange = (rule, data, callback) => {
    const { t } = this.props;
    if (Array.isArray(data) && data.length === 2) {
      if (data[1].diff(data[0], "days") > MAX_DAY_RANGE) {
        callback(t("analysis_schedule.time_range_over"));
      } else {
        callback();
      }
    }
  };

  renderSubForm = () => {
    const {
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props;

    switch (general_data.metric_type) {
      case ANALYSIS_TYPE.ABNORMAL:
        return (
          <AbnormalSubForm
            checkOutRange={this.checkOutRange}
            setFormError={this.setFormError}
            renderExtraFields={this.renderExtraFields}
            renderDayOfWeekWarning={this.renderDayOfWeekWarning}
            onChangeDateRange={this.onChangeDateRange}
            onChangeDateScope={this.onChangeDateScope}
            checkVaildTime={this.checkVaildTime}
            checkToTime={this.checkToTime}
            handleUpdate={this.handleUpdate}
            form={this.props.form}
            formItemLayout={this.formItemLayout}
            onChangeField={this.onChangeField}
          />
        );

      default:
        return (
          <DefaultSubForm
            checkOutRange={this.checkOutRange}
            setFormError={this.setFormError}
            renderExtraFields={this.renderExtraFields}
            renderDayOfWeekWarning={this.renderDayOfWeekWarning}
            onChangeDateRange={this.onChangeDateRange}
            onChangeDateScope={this.onChangeDateScope}
            checkVaildTime={this.checkVaildTime}
            checkToTime={this.checkToTime}
            handleUpdate={this.handleUpdate}
            form={this.props.form}
            formItemLayout={this.formItemLayout}
            onChangeField={this.onChangeField}
          />
        );
    }
  };

  renderExtraFilters = () => {
    const {
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props;

    switch (general_data.metric_type) {
      case ANALYSIS_TYPE.ABNORMAL:
        return (
          <PerflogFilterSubForm
            onChangeField={this.onChangeField}
            form={this.props.form}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const {
      id,
      t,
      form: { getFieldDecorator, validateFields, resetFields, setFieldsValue },
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
          handleChangeAnalysisType,
          allAnalysisTypes,
          allAgencies,
        },
        commonStore: { isSuperAdmin },
      },
      resetListIntersection,
    } = this.props;
    const superAdmin = isSuperAdmin();
    const moduleName = "report_template";

    return (
      <div className="wrap-alarm-rule-detail-page">
        <Form layout={HORIZONTAL_LAYOUT} onSubmit={this.handleSubmit}>
          <div>
            <Row gutter={32}>
              <Col lg={12} xs={24}>
                <Row gutter={16}>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={t("alarm_records.analysis_name")}
                      {...this.formItemLayout}>
                      {getFieldDecorator("metric_type", {
                        rules: [
                          {
                            required: true,
                            message: t(moduleName + ".name_required"),
                          },
                        ],
                        initialValue: general_data?.metric_type,
                        // getValueFromEvent: (value) => {
                        //   return handleChangeAnalysisType(value);
                        // },
                      })(
                        <Select
                          onChange={(value) => {
                            handleChangeAnalysisType(value);
                            setFieldsValue({
                              "general_data.date_range.scope":
                              DATE_RAGNGE_SELECT.RELATIVE.value,
                            });
                            resetFields(["general_data.metadata.to_time"]);
                            validateFields(["general_data.metadata.to_time"]);
                          }}>
                          {allAnalysisTypes?.map((e) => (
                            <Option value={e.id} key={e.id}>
                              {e.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".description")}
                      {...this.formItemLayout}>
                      {getFieldDecorator("general_data.description", {
                        rules: [
                          {
                            required: false,
                            message: t(moduleName + ".description_required"),
                          },
                        ],
                        initialValue: general_data?.description,
                        getValueFromEvent: (e) => {
                          return this.onChangeField(
                            "general_data.description",
                            e.target.value
                          );
                        },
                      })(
                        <TextArea
                          maxLength={500}
                          style={{ resize: "none" }}
                          rows={3}
                          placeholder={t(moduleName + ".description")}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      className="label"
                      label={t(moduleName + ".timezone")}
                      {...this.formItemLayout}>
                      {getFieldDecorator("general_data.time_zone", {
                        rules: [
                          {
                            required: true,
                            message: t(moduleName + ".timezone_required"),
                          },
                        ],
                        initialValue: general_data?.time_zone,
                        getValueFromEvent: (e) => {
                          return this.onChangeField(
                            "general_data.time_zone",
                            e
                          );
                        },
                      })(
                        <Select className="timezone">
                          {TIMEZONES.map((e) => (
                            <Option value={e?.value} key={e?.value}>
                              {e?.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  {this.renderSubForm()}
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  {superAdmin && (
                    <Col lg={12} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".agency_name")}
                        {...this.formItemLayout}>
                        {getFieldDecorator("agency_id", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".agency_required"),
                            },
                          ],
                          initialValue: general_data?.agency_id,
                          getValueFromEvent: (e) => {
                            resetListIntersection();
                            return this.onChangeField(
                              "general_data.agency_id",
                              e
                            );
                          },
                        })(
                          <Select
                            disabled={id != null}
                            placeholder={t(moduleName + ".agency_placeholder")}>
                            {allAgencies.map((e) => (
                              <Option key={e.id} value={e.id}>
                                {e.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </Row>

                {this.renderExtraFilters()}
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "report_template" })(GeneralInfoTab);

export default withRouter(withTranslation()(WrapForm));
