import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View, } from "@react-pdf/renderer";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT, WEEK_DAY_OPTION } from "utils/constants";
// import "./style.scss";
import SiemenBold from "fonts/Siemens_Sans_Bold.ttf";
import SiemenItalicBold from "fonts/Siemens_Sans_Bold_Italic.ttf";
import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
// import AdjustmentResult from "../AdjustmentResult";
import { Spin } from "antd";
import { chunk, uniqueId } from "lodash";
import AogVolumeBarChart from "../CorridorBarChartView/AogVolumeBarChart";
import CorridorOffsetTableView from "../CorridorOffsetTableView";
import AogChart from "./AogChart";
// import TableChart from "../TableChart";
// import BarChart from "../BarChart";

Font.register({
  family: "siemenfont",
  src: SiemenFont,
  fonts: [
    { src: SiemenFont }, // font-style: normal, font-weight: normal
    { src: SiemenBold, fontWeight: "bold", fontWeight: 700 },
    { src: SiemenItalicBold, fontStyle: "italic", fontWeight: 900 },
  ],
});
const styles = StyleSheet.create({
  cover: {
    fontFamily: "siemenfont",
    backgroundColor: "#becdd7",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "150px",
    margin: "30px",
    marginLeft: "70%",
  },
  title: {
    backgroundColor: "#009999",
    width: "350px",
    height: "256px",
    padding: "16px",
    marginTop: "60%",
    marginLeft: "30px",
    color: "#fff",
  },
  line: {
    width: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    height: "2px",
    backgroundColor: "#fff",
  },
  site: {
    backgroundColor: "#fff",
    width: "350px",
    height: "30x",
    marginTop: "20px",
    padding: "0 16px",
    marginLeft: "30px",
  },
  siteText: {
    color: "black",
    fontSize: "10px",
    textAlign: "justify",
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  companyText: {
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
  },
  titleText: {
    backgroundColor: "#fff",
    marginTop: "10px",
    width: "160px",
    color: "#000",
    marginLeft: "8px",
    padding: "4px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleTypeText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "900",
    fontSize: "12px",
  },
  desText: {
    marginLeft: "10px",
    marginTop: "6px",
    fontSize: "12px",
  },
  contentDetail: {
    fontFamily: "siemenfont",
    height: "100%",
    padding: "36px 16px 32px 16px",
  },
  contentDetailCenter: {
    fontFamily: "siemenfont",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
  headerPane: {
    position: "absolute",
    top: "0",
    right: "16px",
  },
  chart: {
    position: "relative",
    padding: "16px",
    top: "0",
  },
  pageTitle: {
    position: "absolute",
    top: "32px",
    display: "flex",
    justifyContent: "center",
    fontFamily: "siemenfont",
    fontSize: "12px",
    fontWeight: "bold",
  },
  header: {
    fontFamily: "siemenfont",
    fontSize: "8px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  headerInfo: {
    fontFamily: "siemenfont",
    color: "#becdd7",
    marginTop: "10px",
    fontSize: "8px",
  },
  footerPane: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    left: "0px",
    padding: "0 16px",
    bottom: "16px",
  },
  footer: {
    fontFamily: "siemenfont",
    textAlign: "left",
    fontSize: "8px",
    fontWeight: "bold",
  },
  footerPage: {
    fontFamily: "siemenfont",
    fontSize: "8px",
  },
  row: {
    fontFamily: "siemenfont",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
  },
  lineTable: {
    width: "100%",
    marginLeft: "20px",
    marginTop: "10px",
    height: "0.5px",
    backgroundColor: "#000",
  },
  resultTable: {
    width: "100%",
    fontFamily: "siemenfont",
    padding: "0px 16px",
    marginTop: "5px",
    marginBottom: "24px",
  },
  tableHeadRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#E4F0F6",
    borderBottom: "1px solid black",
  },
  tableBodyRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
  },
  headColumn: {
    width: "12%",
    padding: "4px 0 4px 4px",
    flexDirection: "column",
    fontSize: "9px",
    textAlign: "left",
  },
  valueColumn: {
    width: "8.5%",
    padding: "4px 0 4px 4px",
    flexDirection: "column",
    fontSize: "9px",
    textAlign: "left",
  },

  cellText: {
    padding: "4px 0",
  },
});

class PrintPreview extends Component {
  page = 0;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageSize: "A4",
      adjustmentChart: [],
      barChart: null,
      tableUrl: null,
    };
    this.page = 1;
    this.tableChartRef = React.createRef();
    this.adjustmentChartRef = React.createRef();
    this.barChartRef = React.createRef();
  }

  renderCoverPage = (coverData) => {
    const { t } = this.props;

    return (
      <View style={styles.cover}>
        <Image
          style={styles.logo}
          src={process.env.PUBLIC_URL + "/img/logo.png"}
        />
        <View style={styles.title}>
          <Text style={styles.companyText}>YUTRAFFIC Insights</Text>
          <Text style={styles.titleText}>
            {" "}
            {t("optimization.offset_optimization")}
          </Text>
          <View style={styles.line}></View>
          <Text style={styles.titleTypeText}>
            {t("optimization.plan_number")}: &nbsp;{coverData?.plan_number} |{" "}
            {t("analysis.phase_name")}: &nbsp;{coverData?.plan_number}
          </Text>

          <Text style={styles.desText}>
            {t("report_result.intersection")}:&nbsp;{" "}
            {coverData?.intersection_name}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.time_range")}: &nbsp;
            {coverData?.start_time}
            {" - "}
            {coverData?.end_time}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.date_range")}: &nbsp;
            {moment(coverData?.start_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
            {" - "}
            {moment(coverData?.end_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
          </Text>

          <Text style={styles.desText}>
            {t("report_result.day_of_week")}:&nbsp;
            {coverData?.week_days
              ?.map((e) => {
                return WEEK_DAY_OPTION.find((i) => i.value == e).label;
              })
              .join(", ")}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.created_at")}: &nbsp;
            {moment(coverData?.created_at).format(DATE_FORMAT.full_with_tz)}
          </Text>
        </View>
        <View style={styles.site}>
          <Text style={styles.siteText}>{t("print.site")}</Text>
        </View>
      </View>
    );
  };

  async componentDidMount() {
    const {
      data: {
        result: { intersections },
      },
    } = this.props;
    const childInt = this.adjustmentChartRef.current?.childNodes;
    let dataInt = [];
    for (let i = 0; i < childInt?.length; i++) {
      const dataUrl = await htmlToImage.toPng(childInt[i]);
      dataInt.push({
        int_name: intersections[i].int_name,
        dataUrl: dataUrl,
      });
    }

    const childBarChart = this.barChartRef.current?.childNodes;

    let dataBar = [];

    for (let i = 0; i < childBarChart?.length; i++) {
      const dataUrl = await htmlToImage.toPng(childBarChart[i]);
      dataBar.push({
        int_name: "By AOG Volume - Raw Count",
        dataUrl: dataUrl,
      });
    }

    // const barChartUrl = await htmlToImage.toPng(this.barChartRef.current);
    const tableUrl = await htmlToImage.toPng(this.tableChartRef.current);

    this.setState({
      adjustmentChart: dataInt,
      barChart: dataBar,
      tableUrl: tableUrl,
    });
  }

  tranfromDataTableToPrint = () => {
    // const { offsetTranformData, t } = this.props;
    // const listKey = Object.keys(offsetTranformData[0]);
    // if (offsetTranformData) {
    //   //split array for page
    //   let array = [];
    //   let i, j, temporary;
    //   for (
    //     i = 0, j = offsetTranformData?.length;
    //     i < j;
    //     i += COLUMN_PER_TABLE
    //   ) {
    //     temporary = offsetTranformData.slice(i, i + COLUMN_PER_TABLE);
    //     array.push(temporary);
    //   }
    //   //tranform data per page
    //   let tranformArray = array.map((element) => {
    //     return listKey.map((key) => {
    //       if (key.indexOf("predicted_phase_") !== -1)
    //         return {
    //           key: key,
    //           label:
    //             t("analysis.phase_name") + key.replace("predicted_phase_", " "),
    //           data: element.map((e) => e[key]),
    //         };
    //       else
    //         return {
    //           key: key,
    //           label: t("optimization." + key),
    //           data: element.map((e) => e[key]),
    //         };
    //     });
    //   });
    //   //split tranformArray
    //   let tablePerPage = Math.floor(MAX_ROW_PER_PAGE / (listKey.length + 1));
    //   let splitArrayByPage = [];
    //   for (i = 0, j = tranformArray.length; i < j; i += tablePerPage) {
    //     temporary = tranformArray.slice(i, i + tablePerPage);
    //     splitArrayByPage.push(temporary);
    //   }
    //   return splitArrayByPage;
    // } else return [];
  };
  renderAllDataPage = () => {
    // const { reporter, t } = this.props;
    // const data = this.tranfromDataTableToPrint();
    // let componentArray = [];
    // data.forEach((page, i) => {
    //   componentArray.push(
    //     <Page key={i} size="A4" orientation="landscape">
    //       <View
    //         style={{
    //           ...styles.row,
    //           ...styles.headerPane,
    //         }}>
    //         <Text style={styles.header}>{t("print.header")} | </Text>
    //         <Text style={styles.headerInfo}>
    //           {t("print.header-reporter")} : {reporter} |{" "}
    //           {moment().format(DATE_FORMAT.full)}
    //         </Text>
    //       </View>
    //       <View style={styles.contentDetail}>
    //         <View>
    //           {page.map((table, id) => (
    //             <View key={i + "_" + id} style={styles.resultTable}>
    //               {table.map((row, idRow) => (
    //                 <View
    //                   key={i + "_" + id + row.key}
    //                   style={idRow ? styles.tableBodyRow : styles.tableHeadRow}>
    //                   <View style={styles.headColumn}>
    //                     <Text style={styles.cellText}> {row.label}</Text>
    //                   </View>
    //                   {row.data.map((e, idcell) => (
    //                     <View
    //                       key={i + "_" + id + row.key + idcell}
    //                       style={styles.valueColumn}>
    //                       <Text style={styles.cellText}>{e}</Text>
    //                     </View>
    //                   ))}
    //                 </View>
    //               ))}
    //             </View>
    //           ))}
    //         </View>
    //       </View>
    //       <View
    //         style={{
    //           ...styles.row,
    //           ...styles.footerPane,
    //         }}>
    //         <Text style={styles.footer}>
    //           {"© " + moment().year() + "  " + t("print.footer")}
    //         </Text>
    //         <Text style={styles.footerPage}>
    //           {t("print.footer-page")} {++this.page}
    //         </Text>
    //       </View>
    //     </Page>
    // );
    // });
    // return componentArray;
  };
  renderPDFFile = () => {
    const { data, t, reporter } = this.props;
    const printInfo = data.general_data;

    return (
      <PDFViewer className="chart-print-preview">
        <Document>
          <Page size="A4" orientation="portrait">
            {this.renderCoverPage(printInfo)}
          </Page>
          <Page
            style={{ position: "relative", height: "100%" }}
            size="A4"
            orientation="landscape"
          >
            <View
              style={{
                ...styles.row,
                ...styles.headerPane,
              }}
            >
              <Text style={styles.header}>{t("print.header")} | </Text>
              <Text style={styles.headerInfo}>
                {t("print.header-reporter")} : {reporter} |{" "}
                {moment().format(DATE_FORMAT.full)}
              </Text>
            </View>
            <View style={styles.contentDetailCenter}>
              <Image src={this.state.tableUrl}/>
            </View>
            <View
              style={{
                ...styles.row,
                ...styles.footerPane,
              }}
            >
              <Text style={styles.footer}>
                {"© " + moment().year() + "  " + t("print.footer")}
              </Text>
              <Text style={styles.footerPage}>
                {t("print.footer-page")} {++this.page}
              </Text>
            </View>
          </Page>
          {/* render table pie chart */}
          {this.state.adjustmentChart?.map((page, id) => (
            <Page
              key={id}
              style={{ position: "relative", height: "100%" }}
              size="A4"
              orientation="landscape"
            >
              <View
                style={{
                  ...styles.row,
                  ...styles.headerPane,
                }}
              >
                <Text style={styles.header}>{t("print.header")} | </Text>
                <Text style={styles.headerInfo}>
                  {t("print.header-reporter")} : {reporter} |{" "}
                  {moment().format(DATE_FORMAT.full)}
                </Text>
              </View>
              <View style={styles.contentDetailCenter}>
                {<Text style={styles.pageTitle}>{page.int_name}</Text>}
                <Image src={page.dataUrl}/>
              </View>
              <View
                style={{
                  ...styles.row,
                  ...styles.footerPane,
                }}
              >
                <Text style={styles.footer}>
                  {"© " + moment().year() + "  " + t("print.footer")}
                </Text>
                <Text style={styles.footerPage}>
                  {t("print.footer-page")} {++this.page}
                </Text>
              </View>
            </Page>
          ))}
          {this.state.barChart?.map((page, id) => (
            <Page
              style={{ position: "relative", height: "100%" }}
              size="A4"
              orientation="landscape"
              key={id}
            >
              <View
                style={{
                  ...styles.row,
                  ...styles.headerPane,
                }}
              >
                <Text style={styles.header}>{t("print.header")} | </Text>
                <Text style={styles.headerInfo}>
                  {t("print.header-reporter")} : {reporter} |{" "}
                  {moment().format(DATE_FORMAT.full)}
                </Text>
              </View>
              <View style={styles.contentDetailCenter}>
                {/* {<Text style={styles.pageTitle}>{page.int_name}</Text>} */}
                <Image src={page.dataUrl}/>
              </View>
              <View
                style={{
                  ...styles.row,
                  ...styles.footerPane,
                }}
              >
                <Text style={styles.footer}>
                  {"© " + moment().year() + "  " + t("print.footer")}
                </Text>
                <Text style={styles.footerPage}>
                  {t("print.footer-page")} {++this.page}
                </Text>
              </View>
            </Page>
          ))}
          {this.state.barChart?.map((page, id) => (
            <Page
              style={{ position: "relative", height: "100%" }}
              size="A4"
              orientation="landscape"
              key={id}>
              <View
                style={{
                  ...styles.row,
                  ...styles.headerPane,
                }}>
                <Text style={styles.header}>{t("print.header")} | </Text>
                <Text style={styles.headerInfo}>
                  {t("print.header-reporter")} : {reporter} |{" "}
                  {moment().format(DATE_FORMAT.full)}
                </Text>
              </View>
              <View style={styles.contentDetailCenter}>
                {/* {<Text style={styles.pageTitle}>{page.int_name}</Text>} */}
                <Image src={page.dataUrl}/>
              </View>
              <View
                style={{
                  ...styles.row,
                  ...styles.footerPane,
                }}>
                <Text style={styles.footer}>
                  {"© " + moment().year() + "  " + t("print.footer")}
                </Text>
                <Text style={styles.footerPage}>
                  {t("print.footer-page")} {++this.page}
                </Text>
              </View>
            </Page>
          ))}
          {/* {this.renderAllDataPage()} */}
        </Document>
      </PDFViewer>
    );
  };

  render() {
    const {
      data: {
        result: { intersections },
      },
    } = this.props;

    return this.state.adjustmentChart.length ? (
      this.renderPDFFile()
    ) : (
      <div className="loading-screen" style={{ width: "100%" }}>
        <Spin
          size="large"
          className={"centralize"}
          style={{
            background: "transparent",
          }}>
          <div ref={this.tableChartRef} className="absolute">
            <CorridorOffsetTableView/>
          </div>
          <div ref={this.adjustmentChartRef} className="chart-data">
            {intersections?.map((int) => (
              <div className="chart-item chart-item-print" key={uniqueId()}>
                <AogChart aogData={int} isPrintMode={true}/>
              </div>
            ))}
          </div>

          <div
            ref={this.barChartRef}
            className="chart-data"
            style={{ width: "100%" }}>
            {chunk(intersections, 9).map((item, index) => (
              <div className="chart-item chart-item-print" key={index}>
                <div className="wrap-corridor-offset-result  h-screen">
                  <div className="chart-title mt-16">
                    By AOG Volume - Raw Count
                  </div>
                  <div className="bar-chart-detail mb-8">
                    <AogVolumeBarChart data={item}/>
                  </div>
                  {/* <div className="chart-title">By % AOG</div>
                <AOGPieChart data={intersections} /> */}
                </div>
              </div>
            ))}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withTranslation()(PrintPreview);
