import { Card } from "antd";
import HeatmapLayer from "components/HeatMapLayer";
import L from "leaflet";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Helper from "utils/helper";
import MapFilter from "./MapFilter";
import MapHeader from "./MapHeader";
import "./style.scss";

import AgencySettingStore from "stores/AgencySettingStore";
import {
  DATE_FORMAT,
  MAP_DELAY_LOAD_DATA,
  MAP_SETTING,
  UNKOWN_DATA_TEXT,
} from "utils/constants";

@inject("rootStore")
@observer
class HeatMap extends Component {
  currentTopLeft = {}; //current toplef position of map => use for get data by area(rectangle)
  currentBottomRight = {}; //current rightbutton position of map => use for get data by area(rectangle)
  //interval timeout to delay load data
  //=> we not get data every time map position change
  //=> we just get the lastest position then delay 1.5 sec to get data
  timeoutCheckInterval = null;

  constructor(props) {
    super(props);
    const {
      rootStore: { dashboardStore },
    } = this.props;
    this.map = createRef();
    this.getWidthMap = createRef();

    this.findAgency = dashboardStore?.listAllAgency.find(
      (agency) => agency.name === dashboardStore?.currentAgency
    );
    this.agencySettingStore = new AgencySettingStore(
      this.findAgency?.id ?? dashboardStore.filterData.agency_id,
      this.props.rootStore
    );
  }

  componentDidMount = () => {
    const { childRef } = this.props;
    childRef && childRef(this);

    this.agencySettingStore.getAgencySettings();
  };
  /**
   * getintensityExtractor
   * get intensity of intersection for heatmap color
   * auto change value to percentage =>(required by heatmap)
   *
   * @return {Float} intensity
   */
  getintensityExtractor = (value) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    value = value
      ? parseFloat(value / dashboardStore.maxColorGradient) * 100
      : 0;
    return value;
  };

  /**
   * handlerViewPortChange
   * this function triggered whenever map location change(position/zoom level)
   *
   * we will get new current top/left buttom/right position to get new intersection belong to that area
   *
   * we not execute get intersection immediately cuz maybe current change is not what customer want
   * we push current position to delay function ( settimeout) and execute in next 1.5s
   * if any new position is comming we clean the old delay function.
   *
   * @return {null}
   */
  handlerViewPortChange = () => {
    const {
      rootStore: { dashboardStore },
      skeleton,
    } = this.props;
    // skip handle resizing on editing-layout mode
    if (skeleton) return null;

    let mapInst = this.map.current?.leafletElement;

    if (mapInst) {
      let currentTopLeft = mapInst.getBounds()?.getNorthWest();
      this.currentBottomRight = mapInst.getBounds()?.getSouthEast();
      this.currentTopLeft = currentTopLeft;
      let context = this;
      const centerPoint = mapInst.getCenter();

      dashboardStore.handleMapSettingChange(
        MAP_SETTING.ZOOM_LEVEL,
        mapInst.getZoom(),
        true
      );
      dashboardStore.handleMapSettingChange(
        MAP_SETTING.CENTER_POINT,
        {
          lat: centerPoint.lat,
          lon: centerPoint.lng,
        },
        true
      );

      if (this.timeoutCheckInterval) {
        clearTimeout(this.timeoutCheckInterval);
      }
      this.timeoutCheckInterval = setTimeout(function () {
        context.loadIntersectionData(currentTopLeft);
      }, MAP_DELAY_LOAD_DATA);
    }
  };

  /**
   * findCurrentSuitableColor
   * this function find the color of intersection on map base on current color setting matrix
   *
   * @return {String} target color
   */
  findCurrentSuitableColor = (value) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    let currentMatrixColor = dashboardStore?.selectedColorGradient;
    //sort the matrix key - make sure we get correct direction
    let colorMapper = Object.keys(currentMatrixColor)?.sort();

    if (Array.isArray(colorMapper)) {
      //get color setting by current matrix
      let color = currentMatrixColor[colorMapper[0]];
      colorMapper.map((item) => {
        if (this.getintensityExtractor(value) / 100 >= item) {
          color = currentMatrixColor[item];
        }
      });
      return color;
    }
  };

  /**
   * loadIntersectionData
   * load intersection list by target postion
   *
   * cuz this function runby settimeout function
   * => may be target position is not lastest => have to check that
   * @params {Object} currentTopLeft : target topleft position
   *
   * @return {null} call store to get intersection
   */
  loadIntersectionData = (currentTopLeft) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    //check target topleft is the lastest one or not
    //if don't ignore that
    if (
      currentTopLeft.lat === this.currentTopLeft?.lat &&
      currentTopLeft.lng === this.currentTopLeft?.lng
    ) {
      dashboardStore?.setMapBounder(
        this.currentTopLeft,
        this.currentBottomRight
      );
    }
    //after call don't need other delay request running
    this.timeoutCheckInterval = null;
  };

  render() {
    const {
      t,
      rootStore: { dashboardStore, history },
      skeleton,
    } = this.props;
    let addressPoint = toJS(dashboardStore?.listFilterIntersections);
    let gradient = toJS(dashboardStore?.selectedColorGradient);

    if (skeleton) {
      return (
        <div className="wrap-hit-map">
          <div className="widget-header-title">{t("heatmap.map_title")}</div>
        </div>
      );
    }

    const markerSize = dashboardStore?.mapSetting?.layer_hidden
      ? dashboardStore?.mapWidgetSetting.dotSize
      : dashboardStore?.mapWidgetSetting.radius;
    return (
      <div ref={this.getWidthMap} className="wrap-hit-map">
        <div className="button-wrap">
          <MapHeader />
        </div>
        <Map
          ref={this.map}
          center={[
            dashboardStore?.mapWidgetSetting?.centerPointer?.lat,
            dashboardStore?.mapWidgetSetting?.centerPointer?.lon,
          ]}
          zoom={dashboardStore?.mapWidgetSetting.zoomLevel}
          style={{ height: "calc(100% - 64px)", borderRadius: "4px" }}
          onViewportChanged={this.handlerViewPortChange}
          preferCanvas={true}
          minZoom={1}
          maxZoom={18}
        >
          {!dashboardStore?.mapSetting?.layer_hidden && (
            <HeatmapLayer
              fitBoundsOnLoad={false}
              fitBoundsOnUpdate={false}
              points={addressPoint}
              longitudeExtractor={(m) => m.longitude}
              latitudeExtractor={(m) => m.latitude}
              gradient={gradient}
              intensityExtractor={(m) => {
                return this.getintensityExtractor(
                  m[dashboardStore.selectedColorMatrix]
                );
              }}
              radius={Number(
                dashboardStore?.mapSetting?.layer_hidden
                  ? dashboardStore?.mapWidgetSetting.dotSize
                  : dashboardStore?.mapWidgetSetting.radius
              )}
              blur={Number(dashboardStore?.mapWidgetSetting.blur)}
              max={1}
            />
          )}
          <TileLayer
            url={process.env.REACT_APP_OPEN_STREET_MAP}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapFilter
            widthMap={{
              width: this.getWidthMap.current?.offsetWidth,
              height: this.getWidthMap.current?.offsetHeight,
            }}
          />
          {addressPoint?.map((intersection) => {
            return intersection?.[dashboardStore?.selectedColorMatrix] ? (
              <Marker
                key={intersection?.id}
                position={[intersection?.latitude, intersection?.longitude]}
                // radius={markerSize}
                // fill={true}
                // color={this.findCurrentSuitableColor(
                //   intersection[dashboardStore?.selectedColorMatrix]
                // )}
                // weight={0}
                // fillOpacity={1}
                icon={L.divIcon({
                  iconSize: [markerSize * 2.5, markerSize * 2.5],
                  iconAnchor: [(markerSize * 2.5) / 2, (markerSize * 2.5) / 2],
                  // iconUrl: process.env.PUBLIC_URL + "/svg/circle.svg",
                  html: L.Util.template(
                    `
                    <svg width="${markerSize * 2.5}" height="${
                      markerSize * 2.5
                    }" viewBox="0 0 ${markerSize * 2.5} ${
                      markerSize * 2.5
                    }" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="${markerSize * 1.25}" cy="${
                      markerSize * 1.25
                    }" r="${markerSize}" fill="${this.findCurrentSuitableColor(
                      intersection[dashboardStore?.selectedColorMatrix]
                    )}"/>
                    </svg>
                    `
                  ),
                  className: "marker-icon-ext",
                })}
                zIndexOffset={1000}
                pane="markerPane"
              >
                <Popup className="map-popup" autoPan={false}>
                  <Card
                    className="intersect-detail-card"
                    title={
                      <div>
                        <div className="card-header">{intersection?.name}</div>
                        <div style={{ marginBottom: "5px" }}>
                          {dashboardStore?.selectedColorList?.label} {": "}
                          {intersection?.[
                            dashboardStore?.selectedColorMatrix
                          ] ? (
                            <strong className="card-value">
                              {Math.round(
                                intersection?.[
                                  dashboardStore?.selectedColorMatrix
                                ] * 100
                              ) / 100}
                            </strong>
                          ) : (
                            <strong className="card-value">
                              {UNKOWN_DATA_TEXT}
                            </strong>
                          )}
                        </div>
                        <div>
                          {t("heatmap.last-update")}
                          {intersection?.updated_time
                            ? moment
                                .utc(intersection?.updated_time)
                                .format(DATE_FORMAT.withTimeZone)
                            : UNKOWN_DATA_TEXT}
                        </div>
                      </div>
                    }
                    bordered={false}
                  >
                    <div>
                      {t("intersection.goto")}:{" "}
                      <a
                        onClick={() =>
                          intersection?.action_map &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ] &&
                          Helper.handleLinkAction(
                            intersection?.action_map[
                              dashboardStore?.selectedColorMatrix
                            ],
                            history
                          )
                        }
                      >
                        {intersection?.action_map &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ] &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ]?.label}
                        <br />
                      </a>
                    </div>
                  </Card>
                </Popup>
              </Marker>
            ) : this.agencySettingStore.currentAgencySetting
                .show_unavailable_map_data ? (
              <Marker
                // icon={L.icon({
                //   iconSize: [markerSize * 2.5, markerSize * 2.5],
                //   iconAnchor: [(markerSize * 2.5) / 2, (markerSize * 2.5) / 2],
                //   iconUrl:
                //     process.env.PUBLIC_URL + "/svg/marker-icon-unavailable.svg",
                // })}
                icon={L.divIcon({
                  iconSize: [markerSize * 2.5, markerSize * 2.5],
                  iconAnchor: [(markerSize * 2.5) / 2, (markerSize * 2.5) / 2],
                  // iconUrl: process.env.PUBLIC_URL + "/svg/circle.svg",

                  html: L.Util.template(
                    `
                    <svg width="${markerSize * 2.5}" height="${
                      markerSize * 2.5
                    }" viewBox="0 0 ${markerSize * 2.5} ${
                      markerSize * 2.5
                    }" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.93 4.93L19.07 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#3f444d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    `
                  ),
                  className: "marker-icon-ext",
                })}
                key={intersection?.id}
                position={[intersection?.latitude, intersection?.longitude]}
                zIndexOffset={500}
                pane="markerPane"
              >
                <Popup className="map-popup" autoPan={false}>
                  <Card
                    className="intersect-detail-card"
                    title={
                      <div>
                        <div className="card-header">{intersection?.name}</div>
                        <div style={{ marginBottom: "5px" }}>
                          {dashboardStore?.selectedColorList?.label} {": "}
                          {intersection?.[
                            dashboardStore?.selectedColorMatrix
                          ] ? (
                            <span className="card-value">
                              {Math.round(
                                intersection?.[
                                  dashboardStore?.selectedColorMatrix
                                ] * 100
                              ) / 100}
                            </span>
                          ) : (
                            <span className="card-value">
                              {UNKOWN_DATA_TEXT}
                            </span>
                          )}
                        </div>
                        <div>
                          {t("heatmap.last-update")}
                          {intersection?.updated_time
                            ? moment
                                .utc(intersection?.updated_time)
                                .format(DATE_FORMAT.withTimeZone)
                            : UNKOWN_DATA_TEXT}
                        </div>
                      </div>
                    }
                    bordered={false}
                  >
                    <div>
                      {t("intersection.goto")}:{" "}
                      <a
                        onClick={() =>
                          intersection?.action_map &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ] &&
                          Helper.handleLinkAction(
                            intersection?.action_map[
                              dashboardStore?.selectedColorMatrix
                            ],
                            history
                          )
                        }
                      >
                        {intersection?.action_map &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ] &&
                          intersection?.action_map[
                            dashboardStore?.selectedColorMatrix
                          ]?.label}
                        <br />
                      </a>
                    </div>
                  </Card>
                </Popup>
              </Marker>
            ) : (
              <div key={intersection?.id}></div>
            );
          })}
        </Map>
      </div>
    ); //
  }
}

export default withTranslation()(HeatMap);
