import { Button, Icon, Input, Popover, Radio, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { DATE_FORMAT, RESOURCE_LIST } from "utils/constants";
import BaseManagementComponents from "../../../components/BaseManagementComponents";
import QueryTimePopup from "../QueryTimePopup";
/**
 * Component for Application layout
 *
 * @component
 */

@inject("rootStore")
@observer
class SummaryTable extends BaseManagementComponents {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore.TSPSummaryTableStore, props);
    this.moduleName = "templates";
    this.resourceName = RESOURCE_LIST.REPORT_TEMPLATE;
    this.pageTitle = props.t("tsp_dashboard.summary_table");
    this.enableCreateButton = false;
    this.state = {
      visible: false,
    };
    this.rowKey = "intersection_uuid";
    this.columns = this.getTableColumns();
  }

  renderFilterComponent = () => {
    const {
      t,
      rootStore: { TSPSummaryTableStore },
    } = this.props;
    return (
      <div className="flex justify-space-beetwen int-event-history-filter-pane">
        <div>
          <div className="flex int-event-history-filter">
            <div>
              <Tooltip title={t("tsp_dashboard.search_by_int_name")}>
                <Input
                  onChange={(e) => {
                    this.currentStore?.handleFilterDataChange(
                      "text",
                      e?.target.value
                    );
                  }}
                  disabled={this.currentStore.loading}
                  size="large"
                  style={{ width: 400 }}
                  placeholder={t("tsp_dashboard.search_by_int_name")}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className="flex">
            <div>
              <Radio.Group
                disabled={this.currentStore.loading}
                size="large"
                value={TSPSummaryTableStore.filterData?.range}
                onChange={(e) => this.handleQuerytimeChange(e.target.value)}
              >
                <Radio.Button value={3600000}>
                  1 {t("tsp_dashboard.hour")}
                </Radio.Button>
                <Radio.Button value={43200000}>
                  12 {t("tsp_dashboard.hours")}
                </Radio.Button>
                <Radio.Button value={86400000}>
                  24 {t("tsp_dashboard.hours")}
                </Radio.Button>
                <Popover
                  content={
                    <QueryTimePopup
                      hourOnly={true}
                      closeModal={() => this.setState({ visible: false })}
                      currentStore={this.currentStore}
                    />
                  }
                  title={t("tsp_dashboard.custom_query")}
                  trigger="click"
                  placement="bottomLeft"
                  visible={this.state.visible}
                  onVisibleChange={(visible) => {
                    this.setState({ visible });
                    TSPSummaryTableStore.handleFilterDataChange(
                      "range",
                      undefined,
                      false
                    );
                  }}
                >
                  <Radio.Button
                    value={undefined}
                    onClick={() => this.setState({ visible: true })}
                  >
                    {t("tsp_dashboard.custom")} <Icon type="calendar" />
                  </Radio.Button>
                </Popover>
              </Radio.Group>
            </div>
            {this.currentStore.filterData.range && (
              <div>
                <Tooltip placement="top" title={t("dashboard.refresh_tooltip")}>
                  <Button
                    loading={this.currentStore.loading}
                    type="primary"
                    icon="reload"
                    size="large"
                    className="auto-reload-btn"
                    onClick={() => {
                      this.handleQuerytimeChange(
                        this.currentStore.filterData.range
                      );
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className="description">
            <p>
              <b>
                {t("tsp_dashboard.query_time")} {": "}
              </b>
              <span>
                {moment(TSPSummaryTableStore.filterData?.from_time).format(
                  DATE_FORMAT.tsp_full
                )}
                {" - "}
                {moment(TSPSummaryTableStore.filterData?.to_time).format(
                  DATE_FORMAT.tsp_full
                )}
                {", "}
                {moment().local().format(DATE_FORMAT.zone_only)}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  handleQuerytimeChange = (value) => {
    const now = Math.floor(moment().valueOf() / 3600000) * 3600000;
    if (value && typeof value === "number") {
      this.currentStore.handleFilterDataChange("to_time", now, false);
      this.currentStore.handleFilterDataChange("from_time", now - value, false);
      this.currentStore.handleFilterDataChange("range", value);
    }
  };
  getTableColumns() {
    const {
      t,
      // rootStore: { TSPStore, intersectionEventHistoryStore },
    } = this.props;
    return [
      {
        title: t("tsp_dashboard.int_name"),
        key: "name",
        render: (item) => {
          return (
            // <a
            //   onClick={() => {
            //     intersectionEventHistoryStore.handleFilterDataChange(
            //       "to_time",
            //       this.currentStore.filterData.to_time,
            //       false
            //     );
            //     intersectionEventHistoryStore.handleFilterDataChange(
            //       "from_time",
            //       this.currentStore.filterData.from_time,
            //       false
            //     );
            //     intersectionEventHistoryStore.handleFilterDataChange(
            //       "range",
            //       undefined,
            //       false
            //     );
            //     intersectionEventHistoryStore.handleFilterDataChange(
            //       "intersection_uuid",
            //       item.intersection_uuid
            //     );
            //     TSPStore.setActiveTab(TSP_KEY_TAB.INT_EVENT_HISTORY);
            //   }}
            // >
            //   {item?.intersection_name}
            // </a>
            item?.intersection_name
          );
        },
        sorter: true,
      },
      {
        title: t("tsp_dashboard.checkin"),
        dataIndex: "check_in",
        key: "check_in",
      },
      {
        title: t("tsp_dashboard.checkout"),
        dataIndex: "check_out",
        key: "check_out",
      },
      {
        title: t("tsp_dashboard.adjust_early"),
        dataIndex: "adjust_early",
        key: "adjust_early",
      },
      {
        title: t("tsp_dashboard.adjust_late"),
        dataIndex: "adjust_late",
        key: "adjust_late",
      },
      {
        title: t("tsp_dashboard.priority_req_enable"),
        dataIndex: "priority_request_enable",
        key: "priority_request_enable",
      },
      {
        title: t("tsp_dashboard.priority_req_cancel"),
        dataIndex: "priority_request_cancel",
        key: "priority_request_cancel",
      },
      {
        title: t("tsp_dashboard.last_checkin"),
        key: "last_check_in",
        render: (item) => {
          return (
            <div>
              {/* {item.last_check_in
                ? moment(item.last_check_in)
                    .local()
                    .format(DATE_FORMAT.tsp_full)
                : "-"} */}
              {item.last_check_in}
            </div>
          );
        },
        sorter: true,
      },
      {
        title: t("tsp_dashboard.last_checkout"),
        key: "last_check_out",
        render: (item) => {
          return (
            <div>
              {/* {item.last_check_in
                ? moment(item.last_check_out)
                    .local()
                    .format(DATE_FORMAT.tsp_full)
                : "-"} */}
              {item.last_check_out}
            </div>
          );
        },
        sorter: true,
      },
    ];
  }
  renderDetailData = () => {
    return <></>;
  };
  componentDidMount = () => {};

  render() {
    return <div className="wrap-tsp_table-page ">{super.render()}</div>;
  }
}

export default SummaryTable;
