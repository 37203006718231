import { Icon } from "antd";
import React from "react";
import ForceChangePasswordForm from "./containers/ForceChangePassword";
import ForgotPasswordForm from "./containers/ForgotPassword";
import Home from "./containers/Home";
import LoginForm from "./containers/Login";
import OtpVerification from "./containers/OtpVerification";
//static pages
import CorridorDetailPage from "containers/Corridor/CorridorDetailPage/index";
import Corridor from "containers/Corridor/index";
import CustomAnalysis from "containers/CustomAnalysis";
import AnalysisSchedule from "containers/Report/AnalysisSchedule";
import AnalysisScheduleDetailPage from "containers/Report/AnalysisSchedule/AnalysisScheduleDetailPage";
import DetectorData from "containers/Report/DetectorData";
import DetectorTemplateDetailPage from "containers/Report/DetectorData/DetectorTemplateDetailPage";
import ReportTemplate from "containers/Report/ReportTemplate";
import AnalysisScheduleResult from "containers/ReportResultPage/AnalysisScheduleResult/index";
import DetectorReportResult from "containers/ReportResultPage/DetectorReportResult";
import DetectorReportResultDetail from "containers/ReportResultPage/DetectorReportResult/DetectorReportResultDetail";
import { Redirect } from "react-router";
import {
  ACTIONS_LIST,
  FULL_PERMISSION,
  LICENSE_LIST,
  REPORT_TEMPLATE_TYPE,
  RESOURCE_LIST,
} from "utils/constants";
import Agencies from "./containers/Agencies";
import EditAgencyForm from "./containers/Agencies/EditAgency";
import AlarmRecords from "./containers/Alarm/AlarmRecords";
import AlarmRule from "./containers/Alarm/AlarmRule";
import AlarmRuleCreatePage from "./containers/Alarm/AlarmRule/AlarmRuleCreatePage";
import AlarmRuleDetailPage from "./containers/Alarm/AlarmRule/AlarmRuleDetailPage";
import Analysis from "./containers/Analysis";
import ExportDownload from "./containers/ExportDownload";
import Intersection from "./containers/Intersection";
import IntersectionConfig from "./containers/Intersection/IntersectionConfig";
import Notifications from "./containers/Notifications";
import Optimizer from "./containers/Optimizer";
import OptimizerDetail from "./containers/Optimizer/OptimizerDetail";
import ReportTemplateDetailPage from "./containers/Report/ReportTemplate/ReportTemplateDetailPage";
import AnalysisScheduleResultDetail from "./containers/ReportResultPage/AnalysisScheduleResult/AnalysisScheduleResultDetail";
import SummaryReportResult from "./containers/ReportResultPage/SummaryReportResult";
import SummaryReportResultDetail from "./containers/ReportResultPage/SummaryReportResult/SummaryReportResultDetail";
import ResetPasswordForm from "./containers/ResetPassword";
import Unauthorized from "./containers/StaticPages/403";
import ServerError from "./containers/StaticPages/500";
import Users from "./containers/Users";
import TSPLayout from "./tsp-container/TSPLayout";

export const generalRoutes = [
  {
    name: "export_download",
    path: "/export-download/:id",
    container: <ExportDownload />,
    permission: null,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },
];
export const publicRoutes = [
  {
    name: "login",
    path: "/login",
    container: <LoginForm permission={FULL_PERMISSION} />,
    permission: FULL_PERMISSION,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },
  {
    name: "forgotpassword",
    path: "/forgot-password",
    container: <ForgotPasswordForm permission={FULL_PERMISSION} />,
    permission: FULL_PERMISSION,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },
  {
    name: "resetpassword",
    path: "/reset-password",
    container: <ResetPasswordForm permission={FULL_PERMISSION} />,
    permission: FULL_PERMISSION,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },
  {
    name: "opt",
    path: "/otp",
    container: <OtpVerification permission={RESOURCE_LIST.OTP} />,
    permission: RESOURCE_LIST.OTP,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: false,
  },
  {
    name: "changepassword",
    path: "/change-password",
    container: (
      <ForceChangePasswordForm permission={RESOURCE_LIST.USER_CHANGE_PASS} />
    ),
    permission: RESOURCE_LIST.USER_CHANGE_PASS,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: false,
  },
];

export const privateRoutes = [
  {
    name: "500",
    path: "/500",
    container: <ServerError permission={FULL_PERMISSION} />,
    permission: FULL_PERMISSION,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },

  {
    name: "403",
    path: "/403",
    container: <Unauthorized permission={FULL_PERMISSION} />,
    permission: FULL_PERMISSION,
    exact: true,
    hiddenFromMenu: true,
    fullscreen: true,
  },
  {
    name: "dashboard",
    path: "/",
    icon: <Icon type="home" />,
    text: "Dashboard",
    // container: <Redirect to="/home/dashboard" />,
    container: <Home permission={RESOURCE_LIST.DASHBOARD} />,
    permission: RESOURCE_LIST.DASHBOARD,
    action: ACTIONS_LIST.VIEW,
    exact: true,
    hiddenFromMenu: false,
  },
  {
    name: "tsp_dashboard",
    icon: <Icon type="line-chart" />,
    path: "/tsp-dashboard",
    text: "TSP Dashboard",
    container: <TSPLayout permission={RESOURCE_LIST.DASHBOARD} />,
    permission: RESOURCE_LIST.AGENCY_DETAIL,
    action: ACTIONS_LIST.VIEW,
    exact: false,
    license: LICENSE_LIST.TSP_DASHBOARD,
  },
  // {
  //   name: "dashboard",
  //   path: "/home",
  //   icon: <Icon type="home" />,
  //   text: "Dashboard",
  //   permission: RESOURCE_LIST.DASHBOARD,
  //   action: ACTIONS_LIST.VIEW,
  //   exact: true,
  //   container: <Home permission={RESOURCE_LIST.DASHBOARD} />,
  // childs: [
  //   {
  //     name: "main_dashboard",
  //     icon: <Icon type="desktop" />,
  //     path: "/dashboard",
  //     text: "Main Dashboard",
  //     container: <Home permission={RESOURCE_LIST.DASHBOARD} />,
  //     permission: RESOURCE_LIST.AGENCY_DETAIL,
  //     action: ACTIONS_LIST.VIEW,
  //     exact: false,
  //   },
  //   {
  //     name: "tsp_dashboard",
  //     icon: <Icon type="line-chart" />,
  //     path: "/tsp-dashboard",
  //     text: "TSP Dashboard",
  //     container: <TSPLayout permission={RESOURCE_LIST.DASHBOARD} />,
  //     permission: RESOURCE_LIST.AGENCY_DETAIL,
  //     action: ACTIONS_LIST.VIEW,
  //     exact: false,
  //   },
  // ],
  // },

  {
    name: "users",
    path: "/users",
    icon: <Icon type="user" />,
    text: "Users",
    container: <Users permission={RESOURCE_LIST.USER} />,
    permission: RESOURCE_LIST.USER,
    action: ACTIONS_LIST.VIEW,
    exact: true,
  },

  {
    name: "notifications",
    path: "/notifications",
    container: <Notifications permission={FULL_PERMISSION} />,
    exact: true,
    hiddenFromMenu: true,
    permission: FULL_PERMISSION,
  },
  {
    name: "agencies",
    path: "/agencies",
    icon: <Icon type="gold" />,
    text: "Agency",
    permission: RESOURCE_LIST.AGENCY,
    action: ACTIONS_LIST.VIEW,
    exact: true,
    container: <Agencies permission={RESOURCE_LIST.AGENCY} />,
    render_route: true,
    childs: [
      {
        name: "agency_detail",
        path: "/:id",
        icon: <Icon type="user" />,
        text: "Agency Detail",
        container: <EditAgencyForm permission={RESOURCE_LIST.AGENCY_DETAIL} />,
        permission: RESOURCE_LIST.AGENCY_DETAIL,
        action: ACTIONS_LIST.VIEW,
        exact: false,
        hiddenFromMenu: true,
      },
    ],
  },
  {
    name: "agency_detail",
    path: "/intersection-config",
    icon: <Icon type="user" />,
    text: "Intersection config",
    container: <IntersectionConfig permission={RESOURCE_LIST.INTERSECTION} />,
    permission: RESOURCE_LIST.INTERSECTION,
    action: ACTIONS_LIST.VIEW,
    exact: true,
    hiddenFromMenu: true,
  },
  {
    name: "intersections",
    path: "/intersection",
    icon: <Icon type="fullscreen-exit" />,
    text: "Intersections",
    container: <Intersection permission={RESOURCE_LIST.INTERSECTION} />,
    permission: RESOURCE_LIST.INTERSECTION,
    action: ACTIONS_LIST.VIEW,
    render_route: true,
    exact: true,
    license: LICENSE_LIST.INTERSECTION,
  },
  {
    name: "corridor",
    path: "/corridor",
    icon: <Icon type="pic-center" />,
    text: "Corridors",
    permission: RESOURCE_LIST.CORRIDOR,
    action: ACTIONS_LIST.VIEW,
    container: <Corridor permission={RESOURCE_LIST.CORRIDOR} />,
    render_route: true,
    license: LICENSE_LIST.CORRIDOR,
    childs: [
      {
        name: "corridor_create",
        path: "/create",
        container: <CorridorDetailPage permission={RESOURCE_LIST.CORRIDOR} />,
        permission: RESOURCE_LIST.CORRIDOR,
        action: ACTIONS_LIST.CREATE,
        exact: false,
        hiddenFromMenu: true,
      },
      {
        name: "corridor_detail",
        path: "/:id",
        container: (
          <CorridorDetailPage permission={RESOURCE_LIST.CORRIDOR_DETAIL} />
        ),
        permission: RESOURCE_LIST.CORRIDOR_DETAIL,
        action: ACTIONS_LIST.EDIT,
        exact: false,
        hiddenFromMenu: true,
      },
    ],
  },
  {
    name: "alarms",
    path: "/alarms",
    icon: <Icon type="bell" />,
    text: "Alarms",
    permission: RESOURCE_LIST.ALARM,
    action: ACTIONS_LIST.VIEW,
    license: LICENSE_LIST.ALARM,
    childs: [
      {
        name: "rule",
        path: "/rules",
        icon: <Icon type="calculator" />,
        text: "Alarm rules",
        container: <AlarmRule permission={RESOURCE_LIST.ALARM_RULE} />,
        permission: RESOURCE_LIST.ALARM_RULE,
        action: ACTIONS_LIST.VIEW,
        exact: true,
        render_route: true,
        childs: [
          {
            name: "alarm_rule_create",
            path: "/create",
            container: (
              <AlarmRuleCreatePage permission={RESOURCE_LIST.ALARM_RULE} />
            ),
            permission: RESOURCE_LIST.ALARM_RULE,
            action: ACTIONS_LIST.CREATE,
            exact: false,
            hiddenFromMenu: true,
          },
          {
            name: "alarm_rule_detail",
            path: "/:id",
            container: (
              <AlarmRuleDetailPage permission={RESOURCE_LIST.RESOURCE_LIST} />
            ),
            permission: RESOURCE_LIST.ALARM_RULE,
            action: ACTIONS_LIST.VIEW,
            exact: false,
            hiddenFromMenu: true,
          },
        ],
      },
      {
        name: "records",
        path: "/records",
        icon: <Icon type="mail" />,
        text: "Alarm records",
        container: <AlarmRecords permission={RESOURCE_LIST.ALARM_RECORD} />,
        permission: RESOURCE_LIST.ALARM_RECORD,
        action: ACTIONS_LIST.VIEW,
        exact: true,
      },
    ],
  },
  {
    name: "analysis",
    path: "/analysis",
    icon: <Icon type="bar-chart" />,
    text: "Analysis",
    permission: RESOURCE_LIST.ANALYSIS,
    action: ACTIONS_LIST.VIEW,
    license_child: [LICENSE_LIST.PERFORMANCE_METRICS, LICENSE_LIST.OPTIMIZER],
    childs: [
      {
        name: "perfomance_metric",
        icon: <Icon type="bar-chart" />,
        path: "/perfomance-metric",
        text: "Performance Metrics",
        container: <Analysis permission={RESOURCE_LIST.ANALYSIS} />,
        permission: RESOURCE_LIST.ANALYSIS,
        action: ACTIONS_LIST.VIEW,
        exact: false,
        license: LICENSE_LIST.PERFORMANCE_METRICS,
      },
      {
        name: "optimizer",
        icon: <Icon type="pie-chart" />,
        path: "/optimizer",
        text: "Advanced Analytics",
        container: <Optimizer permission={RESOURCE_LIST.OPTIMIZATION} />,
        permission: RESOURCE_LIST.OPTIMIZATION,
        action: ACTIONS_LIST.CREATE,
        exact: false,
        render_route: true,
        license: LICENSE_LIST.OPTIMIZER,
        childs: [
          {
            name: "optimizer_detail",
            path: "/:id",
            container: (
              <OptimizerDetail permission={RESOURCE_LIST.OPTIMIZATION_DETAIL} />
            ),
            permission: RESOURCE_LIST.OPTIMIZATION_DETAIL,
            action: ACTIONS_LIST.VIEW,
            exact: false,
            hiddenFromMenu: true,
          },
        ],
      },
      {
        name: "custom-analysis",
        icon: <Icon type="dot-chart" />,
        path: "/custom-analysis",
        text: "Custom Analysis Template",
        container: <CustomAnalysis permission={RESOURCE_LIST.ANALYSIS} />,
        permission: RESOURCE_LIST.ANALYSIS,
        action: ACTIONS_LIST.VIEW,
        exact: false,
        license: LICENSE_LIST.PERFORMANCE_METRICS,
      },
    ],
  },

  {
    name: "report",
    path: "/report",
    icon: <Icon type="file-text" />,
    text: "Reports",
    permission: RESOURCE_LIST.REPORT,
    action: ACTIONS_LIST.VIEW,
    license: LICENSE_LIST.REPORT,
    childs: [
      {
        name: "report",
        path: "/template",
        icon: <Icon type="file-text" />,
        text: "Templates",
        permission: RESOURCE_LIST.REPORT,
        action: ACTIONS_LIST.VIEW,
        container: <Redirect to={"/report/template/summary-report"} />,
        exact: true,
        render_route: true,
        childs: [
          {
            name: "summary-report",
            path: "/summary-report",
            icon: <Icon type="container" />,
            text: "Templates",
            container: (
              <ReportTemplate
                permission={RESOURCE_LIST.REPORT_TEMPLATE}
                activeTab={REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            render_route: true,
            childs: [
              {
                name: "report_template_create",
                path: "/create",
                container: (
                  <ReportTemplateDetailPage
                    permission={RESOURCE_LIST.REPORT_TEMPLATE}
                  />
                ),
                permission: RESOURCE_LIST.REPORT_TEMPLATE,
                action: ACTIONS_LIST.CREATE,
                exact: false,
                hiddenFromMenu: true,
              },
              {
                name: "report_template_detail",
                path: "/:id",
                container: (
                  <ReportTemplateDetailPage
                    permission={RESOURCE_LIST.RESOURCE_LIST}
                  />
                ),
                permission: RESOURCE_LIST.RESOURCE_LIST,
                action: ACTIONS_LIST.VIEW,
                exact: false,
                hiddenFromMenu: true,
              },
            ],
          },
          {
            name: "performance-metri",
            path: "/performance-metric",
            icon: <Icon type="container" />,
            text: "Templates",

            container: (
              <AnalysisSchedule
                permission={RESOURCE_LIST.REPORT_TEMPLATE}
                activeTab={REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            hiddenFromMenu: true,
            render_route: true,
            childs: [
              {
                name: "report_template_create",
                path: "/create",
                container: (
                  <AnalysisScheduleDetailPage
                    permission={RESOURCE_LIST.REPORT_TEMPLATE}
                  />
                ),
                permission: RESOURCE_LIST.REPORT_TEMPLATE,
                action: ACTIONS_LIST.CREATE,
                exact: false,
                hiddenFromMenu: true,
              },
              {
                name: "report_template_detail",
                path: "/:id",
                container: (
                  <AnalysisScheduleDetailPage
                    permission={RESOURCE_LIST.RESOURCE_LIST}
                  />
                ),
                permission: RESOURCE_LIST.RESOURCE_LIST,
                action: ACTIONS_LIST.VIEW,
                exact: false,
                hiddenFromMenu: true,
              },
            ],
          },

          {
            name: "detector-report",
            path: "/detector-report",
            icon: <Icon type="container" />,
            text: "Templates",

            container: (
              <DetectorData
                permission={RESOURCE_LIST.REPORT_TEMPLATE}
                activeTab={REPORT_TEMPLATE_TYPE.DETECTOR_DATA}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            hiddenFromMenu: true,
            render_route: true,
            childs: [
              {
                name: "detector_create",
                path: "/create",
                container: (
                  <DetectorTemplateDetailPage
                    permission={RESOURCE_LIST.REPORT_TEMPLATE}
                  />
                ),
                permission: RESOURCE_LIST.REPORT_TEMPLATE,
                action: ACTIONS_LIST.CREATE,
                exact: false,
                hiddenFromMenu: true,
              },
              {
                name: "detector_detail",
                path: "/:id",
                container: (
                  <DetectorTemplateDetailPage
                    permission={RESOURCE_LIST.RESOURCE_LIST}
                  />
                  // <></>
                ),
                permission: RESOURCE_LIST.RESOURCE_LIST,
                action: ACTIONS_LIST.VIEW,
                exact: false,
                hiddenFromMenu: true,
              },
            ],
          },
        ],
      },

      {
        name: "report-result",
        path: "/result",
        icon: <Icon type="export" />,
        text: "Results",
        container: <Redirect to={"/report/result/summary-report"} />,
        permission: RESOURCE_LIST.REPORT_TEMPLATE,
        action: ACTIONS_LIST.VIEW,
        exact: true,
        render_route: true,
        childs: [
          {
            name: "summary-report",
            path: "/summary-report",
            icon: <Icon type="export" />,
            text: "Results",
            container: (
              <SummaryReportResult
                activeTab={REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE}
                permission={RESOURCE_LIST.ALARM_RECORD}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            render_route: true,
            childs: [
              {
                name: "result",
                path: "/:id",
                text: "Result",
                hiddenFromMenu: true,
                container: (
                  <SummaryReportResultDetail
                    permission={RESOURCE_LIST.ANALYSIS}
                  />
                ),
                permission: RESOURCE_LIST.ANALYSIS,
                action: ACTIONS_LIST.VIEW,
                exact: false,
              },
            ],
          },
          {
            name: "summary-report",
            path: "/performance-metric",
            icon: <Icon type="export" />,
            text: "Results",
            container: (
              <AnalysisScheduleResult
                activeTab={REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE}
                permission={RESOURCE_LIST.ALARM_RECORD}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            render_route: true,
            childs: [
              {
                name: "result",
                path: "/:id",
                text: "Result",
                hiddenFromMenu: true,
                container: (
                  <AnalysisScheduleResultDetail
                    permission={RESOURCE_LIST.ANALYSIS}
                  />
                ),
                permission: RESOURCE_LIST.ANALYSIS,
                action: ACTIONS_LIST.VIEW,
                exact: false,
              },
            ],
          },
          {
            name: "detector-report",
            path: "/detector-report",
            icon: <Icon type="export" />,
            text: "Results",
            container: (
              <DetectorReportResult
                activeTab={REPORT_TEMPLATE_TYPE.DETECTOR_DATA}
                permission={RESOURCE_LIST.ALARM_RECORD}
              />
            ),
            permission: RESOURCE_LIST.REPORT_TEMPLATE,
            action: ACTIONS_LIST.VIEW,
            exact: true,
            render_route: true,
            childs: [
              {
                name: "result",
                path: "/:id",
                text: "Result",
                hiddenFromMenu: true,
                container: (
                  <DetectorReportResultDetail
                    permission={RESOURCE_LIST.ANALYSIS}
                  />
                ),
                permission: RESOURCE_LIST.ANALYSIS,
                action: ACTIONS_LIST.VIEW,
                exact: false,
              },
            ],
          },
        ],
      },
    ],
  },
];
