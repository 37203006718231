import { action, observable, runInAction } from "mobx";
import {
  AGENCY_FILTER_FIELD,
  AVAILABLE,
  DEFAULT_CENTER_POINT_DASHBOARD,
  DEFAULT_LOAD_INTERSECTION_ON_MAP,
  DEFAULT_ZOOM_LEVEL,
  DELAY_PULSING_ICON,
  QUERY_DELAY_LOAD_DATA,
  RESOURCE_LIST,
  SUPPER_ADMIN_GROUP_AGENCY_ID,
  TSP_KEY_TAB,
} from "utils/constants";
import AgencyService from "../services/AgencyService";
import DashboardService from "../services/DashboardService";
import IntersectionService from "../services/IntersectionService";
import TSPDashboardService from "../services/TSPSerrvice";
import BaseStore from "./BaseStore";
/**
 * Store for Dashboard
 *
 * @class
 */
class TSPStore extends BaseStore {
  //header data
  @observable filterData = {};
  @observable lastUpldatedTime = 0; //last time update intersection - display on header
  @observable listAllAgency = []; //list all agency of current user - list agenies for simemens admin
  @observable currentAgency = {};
  //map data
  @observable listAllIntersections = []; //list all intersection
  @observable listFilterIntersections = []; //list filtered intersection display on map
  @observable tspMapSetting = {
    center_pointer: DEFAULT_CENTER_POINT_DASHBOARD,
    zoom_level: DEFAULT_ZOOM_LEVEL,
    radius: 9, //default radius of interstion marker
  };
  //ThreadId loading loop for lazy loading intersection on map
  continuousLoadingThreadId = null;
  //refernce of map object - to get topleft-buttonright position when change agency
  heatMapRef = null;
  @observable searchString = ""; //all open data display on chart
  @observable activeTab = TSP_KEY_TAB.MAP_VIEW;
  @observable listAllEventTypes = [];
  @observable isSave = false;
  @observable TimeoutSucces = null;
  @observable clientSideFilterData = {
    minValue: 0,
    maxValue: 100,
  };
  constructor(parentStore) {
    super(new TSPDashboardService(parentStore), parentStore);
    this.dashboardService = new DashboardService(parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    this.intersectionService = new IntersectionService(parentStore);
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
      ...{
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
      },
    };
    this.resourceName = RESOURCE_LIST.DASHBOARD;
    //current agency name is wokring on -  for binding on UI
    this.currentAgency = parentStore?.myStore?.currentAgency?.agency_name;
    this.mapFilterData = {
      top_left: [0, 0],
      bottom_right: [0, 0],
      size: DEFAULT_LOAD_INTERSECTION_ON_MAP,
    };
  }
  /**
   * handleMapSettingChange
   * this function triggered whenever map setting change
   * this function will update radius and blur of intersection on map
   * @params {Enum} type only 2 type support now : radius/ blur
   * @params {int} value new value
   *
   * @return {null} intensity
   */
  @action resetIsSave = () => {
    this.isSave = false;
  };
  @action setTimeoutSucces = () => {
    this.TimeoutSucces = setTimeout(() => {
      runInAction(() => {
        this.isSave = false;
      });
    }, 5000);
  };
  @action clearTimeoutSucces = () => {
    clearTimeout(this.TimeoutSucces);
  };
  @action handleMapSettingChange = (type, value) => {
    if (value !== null) {
      this.tspMapSetting[type] = value;
    }
  };
  @action setActiveTab(tab_key) {
    this.activeTab = tab_key;
  }

  @action updateSerachString = (searchStr = "") => {
    this.searchString = searchStr;
    this.updateListAllIntersections();
  };
  /**
   * setHeatMapRef
   *
   * @return {null}
   */
  setHeatMapRef = (heatMap) => {
    this.heatMapRef = heatMap;
  };

  @action getAllIntersectionsForFilter = () => {
    return new Promise((resolve, reject) => {
      this.intersectionService.getAllIntersections(
        {
          agency_id: this.filterData.agency_id,
          status: AVAILABLE,
        },
        (intersections) => {
          runInAction(() => {
            this.parentStore.intersectionEventHistoryStore.setListAllIntersections(
              intersections?.data
            );
            this.parentStore.intersectionEventHistoryStore.handleFilterDataChange(
              "intersection_uuid",
              intersections?.data[0]?.id,
              this.activeTab === TSP_KEY_TAB.INT_EVENT_HISTORY
            );
            resolve(intersections);
          });
        },
        (errors) => reject(errors)
      );
    });
  };

  /**
   * getAllAgencies
   * get all agencies belong to current users
   * if current user is not simemen admin using current agency of user
   *
   * @return {Promise}
   */
  @action getAllAgencies = () => {
    return new Promise((resolve, reject) => {
      let agency_id = this.parentStore?.myStore?.currentAgency?.agency_id;
      if (agency_id === SUPPER_ADMIN_GROUP_AGENCY_ID) {
        this.loading = true;
        this.agencyService?.fetchDataWithoutParams(
          (items) => {
            runInAction(() => {
              this.listAllAgency = items;
              //auto binding seleted agency - or set default a gency
              if (Array.isArray(this.listAllAgency) && this.listAllAgency[0]) {
                //binding selected agency
                let defaultAgencies = this.listAllAgency.find(
                  (item) => item.id === this.filterData.agency_id
                );
                //if no selected agency =>  get default of the frist one
                if (!defaultAgencies) {
                  defaultAgencies = this.listAllAgency[0];
                }
                //set default agency data
                this.handleFilterDataChange("agency_id", defaultAgencies.id);
              }
            });
            resolve(items);
          },
          () => {
            runInAction(() => {
              this.loading = false;
            });
            reject(false);
          }
        );
      } else {
        this.handleFilterDataChange("agency_id", agency_id);
        resolve(true);
      }
    });
  };
  @action resetItemNewEvent = (item) => {
    if (item) item.hasNewEvent = false;
  };
  @action setCurrentNewEvent = (tsp_event) => {
    let int_new_event = this.listFilterIntersections?.find(
      (e) => e.id === tsp_event.id
    );
    if (int_new_event) {
      int_new_event.last_event_time = tsp_event.last_event_time;
      int_new_event.hasNewEvent = true;
      setTimeout(
        () => this.resetItemNewEvent(int_new_event),
        DELAY_PULSING_ICON
      );
    }
    // this.heatMapRef.current.updateBusLocation(tsp_event);
  };
  @action handleFilterDataChange = (target, value) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === AGENCY_FILTER_FIELD) {
      let selectedAgency = this.listAllAgency?.find(
        (item) => item.id === value
      );
      this.listAllIntersections = [];
      this.getAllIntersectionsForFilter();
      this.currentAgency = selectedAgency;
      this.parentStore.TSPEventHistoryStore.handleFilterDataChange(
        "agency_id",
        value,
        this.activeTab === TSP_KEY_TAB.TSP_EVENT_HISTORY
      );
      this.parentStore.intersectionEventHistoryStore.handleFilterDataChange(
        "agency_id",
        value,
        false
      );
      this.parentStore.TSPSummaryTableStore.handleFilterDataChange(
        "agency_id",
        value,
        this.activeTab === TSP_KEY_TAB.SUMMARY_TABLE
      );
      //refresh all data execpt agenies
      this.getMapSettingByAgency();
      this.updateSerachString(); //clear searchString
    }
  };

  @action updateClientSideFilterData = (key, newValue) => {
    this.clientSideFilterData[key] = newValue;
    this.updateListAllIntersections();
  };
  @action async getAllEventTypes() {
    let response = await this.moduleService.getAllEventTypes().catch(() => {});
    runInAction(() => {
      this.listAllEventTypes = response?.types;
    });
  }
  /**
   * setMapBounder
   * this function will trigger when heat map viewport change
   * to update mapfilter data (topleft / buttonright)
   * then we make a request to get intersection base on that data filter
   * @return  {null}
   */
  setMapBounder = (topLeft, bottomRight, isReload = true) => {
    this.mapFilterData.top_left = topLeft?.lat + "," + topLeft?.lng;
    this.mapFilterData.bottom_right = bottomRight?.lat + "," + bottomRight?.lng;
    if (isReload) {
      this.getAllIntersectionsLocation();
    }
  };
  /**
   * updateListAllIntersections
   * update List All Intersections with filter data
   * @param {Array} newIntersection
   */
  @action updateListAllIntersections = (newIntersection = null) => {
    if (Array.isArray(newIntersection)) {
      this.listAllIntersections = newIntersection;
    }
    let result = this.listAllIntersections;
    this.listFilterIntersections = result.filter((intersection) => {
      return (
        intersection.name
          ?.toLowerCase()
          .indexOf(this.searchString?.toLowerCase()) >= 0
      );
    });
  };
  /**
   * getAllIntersectionsLocation
   * get all intersection for map
   *
   * @return {null}
   */
  @action getAllIntersectionsLocation = (
    page = 0,
    continuousLoadingThreadId = null
  ) => {
    if (page === 0) {
      //random the loading threadId
      this.continuousLoadingThreadId = Math.floor(Math.random() * 100);
    }
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.getAllIntersectionsWithBounds({
          ...this.mapFilterData,
          ...{
            agency_id: this.filterData.agency_id,
            page: page,
            status: AVAILABLE,
          },
        })
        .then((response) => {
          //check if return data belong to old thread just ignore that
          if (
            continuousLoadingThreadId &&
            this.continuousLoadingThreadId !== continuousLoadingThreadId
          ) {
            resolve();
          } else {
            runInAction(() => {
              this.lastUpldatedTime = response.updated_time;
              if (page === 0) {
                this.updateListAllIntersections(response?.intersections);
              } else {
                this.updateListAllIntersections(
                  this.listAllIntersections.concat(response?.intersections)
                );
              }
              resolve();
              if (response.total_count > this.listAllIntersections.length) {
                continuousLoadingThreadId = this.continuousLoadingThreadId;
                let context = this;
                setTimeout(function () {
                  context.getAllIntersectionsLocation(
                    page + 1,
                    continuousLoadingThreadId
                  );
                }, QUERY_DELAY_LOAD_DATA);
              }
            });
          }
        })
        .catch((response) => {
          runInAction(() => {
            this.setErrorList(response);
          });
          reject(response);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  /**
   * getMapseting
   * get setting of map:
   * location of map, radius
   * get all intersection for map
   *
   * @return {null}
   */
  @action getMapSettingByAgency = () => {
    this.dashboardService
      .getMapSettingByAgency({
        agency_id: this.filterData.agency_id,
      })
      .then((data) => {
        runInAction(() => {
          this.tspMapSetting = {
            ...this.tspMapSetting,
            center_pointer:
              data.dashboard_setting.map_view_widget.center_pointer,
          };
        });
        //IMPORTANT: after set centerpoint we load all intersection on this area
        this.heatMapRef.current?.handlerViewPortChange();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  @action saveMapSetting = () => {
    this.dashboardService
      .saveMapSettingByAgency({
        target: "default",
        agency_id: this.filterData.agency_id,
        default_map: this.tspMapSetting,
      })
      .then(() => {
        runInAction(() => {
          this.isSave = true;
          this.setTimeoutSucces();
        });
      })
      .catch(() => {});
  };
}

export default TSPStore;
