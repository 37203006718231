import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Spin } from "antd";
import SiemenBold from "fonts/Siemens_Sans_Bold.ttf";
import SiemenItalicBold from "fonts/Siemens_Sans_Bold_Italic.ttf";
import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
import * as htmlToImage from "html-to-image";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants";
import TimeSpaceChart from "./TimeSpaceChart";

import "./style.scss";

Font.register({
  family: "siemenfont",
  src: SiemenFont,
  fonts: [
    { src: SiemenFont }, // font-style: normal, font-weight: normal
    { src: SiemenBold, fontWeight: "bold", fontWeight: 700 },
    { src: SiemenItalicBold, fontStyle: "italic", fontWeight: 900 },
  ],
});
const styles = StyleSheet.create({
  cover: {
    fontFamily: "siemenfont",
    backgroundColor: "#becdd7",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "150px",
    margin: "30px",
    marginLeft: "70%",
  },
  title: {
    backgroundColor: "#009999",
    width: "350px",
    height: "256px",
    padding: "16px",
    marginTop: "60%",
    marginLeft: "30px",
    color: "#fff",
  },
  line: {
    width: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    height: "2px",
    backgroundColor: "#fff",
  },
  site: {
    backgroundColor: "#fff",
    width: "350px",
    height: "30x",
    marginTop: "20px",
    padding: "0 16px",
    marginLeft: "30px",
  },
  siteText: {
    color: "black",
    fontWeight: "900",
    fontSize: "10px",
    textAlign: "justify",
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  companyText: {
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
  },
  titleText: {
    backgroundColor: "#fff",
    marginTop: "10px",
    width: "auto",
    width: "160px",
    color: "#000",
    marginLeft: "8px",
    padding: "4px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleTypeText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "900",
    fontSize: "12px",
  },
  desText: {
    marginLeft: "10px",
    marginTop: "6px",
    fontSize: "12px",
  },
  contentDetail: {
    fontFamily: "siemenfont",
    height: "100%",
    padding: "36px 16px 32px 16px",
  },
  contentDetailCenter: {
    fontFamily: "siemenfont",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
  headerPane: {
    position: "absolute",
    top: "0",
    right: "16px",
  },
  chart: {
    position: "relative",
    padding: "16px",
    top: "0",
  },
  pageTitle: {
    position: "absolute",
    top: "32px",
    display: "flex",
    justifyContent: "center",
    fontFamily: "siemenfont",
    fontSize: "12px",
    fontWeight: "bold",
  },
  header: {
    fontFamily: "siemenfont",
    fontSize: "8px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  headerInfo: {
    fontFamily: "siemenfont",
    color: "#becdd7",
    marginTop: "10px",
    fontSize: "8px",
  },
  footerPane: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    left: "0px",
    padding: "0 16px",
    bottom: "16px",
  },
  footer: {
    fontFamily: "siemenfont",
    textAlign: "left",
    fontSize: "8px",
    fontWeight: "bold",
  },
  footerPage: {
    fontFamily: "siemenfont",
    fontSize: "8px",
  },
  row: {
    fontFamily: "siemenfont",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
  },
  lineTable: {
    width: "100%",
    marginLeft: "20px",
    marginTop: "10px",
    height: "0.5px",
    backgroundColor: "#000",
  },
  resultTable: {
    width: "100%",
    fontFamily: "siemenfont",
    padding: "0px 16px",
    marginTop: "5px",
    marginBottom: "24px",
  },
  tableHeadRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#E4F0F6",
    borderBottom: "1px solid black",
  },
  tableBodyRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
  },
  headColumn: {
    width: "12%",
    padding: "4px 0 4px 4px",
    flexDirection: "column",
    fontSize: "9px",
    textAlign: "left",
  },
  valueColumn: {
    width: "8.5%",
    padding: "4px 0 4px 4px",
    flexDirection: "column",
    fontSize: "9px",
    textAlign: "left",
  },

  cellText: {
    padding: "4px 0",
  },
});

@inject("rootStore")
@observer
class PrintPreview extends Component {
  page = 0;
  constructor(props) {
    super(props);

    this.state = {
      timeSpaceChart: [],
      splitDuration: [],
    };
    this.page = 1;

    this.timeSpaceChartRef = React.createRef();
  }

  renderCoverPage = (coverData) => {
    const { t } = this.props;

    return (
      <View style={styles.cover}>
        <Image
          style={styles.logo}
          src={process.env.PUBLIC_URL + "/img/logo.png"}
        />
        <View style={styles.title}>
          <Text style={styles.companyText}>YUTRAFFIC Insights</Text>
          <Text style={styles.titleText}>
            {" "}
            {t("optimization.historic_time_space")}
          </Text>
          <View style={styles.line}></View>
          <Text style={styles.titleTypeText}>
            {t("optimization.plan_number")}: &nbsp;{coverData?.plan_number} |{" "}
            {t("analysis.phase_name")}: &nbsp;{coverData?.plan_number}
          </Text>

          <Text style={styles.desText}>
            {t("report_result.intersection")}:&nbsp;{" "}
            {coverData?.intersection_name}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.time_range")}: &nbsp;
            {coverData?.start_time}
            {" - "}
            {coverData?.end_time}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.date_range")}: &nbsp;
            {moment(coverData?.start_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
            {" - "}
            {moment(coverData?.end_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
          </Text>

          <Text style={styles.desText}>
            {t("report_result.day_of_week")}:&nbsp;
            {coverData?.week_days
              ?.map((e) => {
                return WEEK_DAY_OPTION.find((i) => i.value == e).label;
              })
              .join(", ")}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.created_at")}: &nbsp;
            {moment(coverData?.created_at).format(DATE_FORMAT.full_with_tz)}
          </Text>
        </View>
        <View style={styles.site}>
          <Text style={styles.siteText}>{t("print.site")}</Text>
        </View>
      </View>
    );
  };

  splitDuration = (startTime, endTime) => {
    const defaultDuration = 30 * 60 * 1000;

    const listDuration = [];

    const diff = moment(endTime, DATE_FORMAT.time_minute).diff(
      moment(startTime, DATE_FORMAT.time_minute)
    );

    if (diff < defaultDuration) {
      return [{ start: startTime, end: endTime }];
    }

    let start = moment(startTime, DATE_FORMAT.time_minute);
    let end = start.clone().add(defaultDuration, "ms");

    while (end < moment(endTime, DATE_FORMAT.time_minute)) {
      listDuration.push({
        start: start.format(DATE_FORMAT.time_minute),
        end: end.format(DATE_FORMAT.time_minute),
      });
      start = end.clone();
      end = start.clone().add(defaultDuration, "ms");
    }

    listDuration.push({
      start: start.format(DATE_FORMAT.time_minute),
      end: moment(endTime, DATE_FORMAT.time_minute).format(
        DATE_FORMAT.time_minute
      ),
    });

    return listDuration;
  };

  transformDataPrint = (data) => {
    let tranformObject = {
      general_data: { ...data.general_data },
      actual: {
        ...data.actual,
        from_time: moment(
          data.actual.from_time,
          DATE_FORMAT.time_only_full
        ).toDate(),
        to_time: moment(
          data.actual.to_time,
          DATE_FORMAT.time_only_full
        ).toDate(),
        from_distance: 0,
        min_distance: 0,
        to_distance: 0,
        intersections: [],
      },
      programmed: data.programmed
        ? {
            ...data.programmed,
            from_time: moment(
              data.programmed.from_time,
              DATE_FORMAT.time_only_full
            ).toDate(),
            to_time: moment(
              data.programmed.to_time,
              DATE_FORMAT.time_only_full
            ).toDate(),
            from_distance: 0,
            min_distance: 0,
            to_distance: 0,
            intersections: [],
          }
        : null,
    };

    //tranform actual
    let len = data.actual.intersections.length;
    let currentYCoord = 0;
    let min_distance = Number.MAX_SAFE_INTEGER;
    for (let i = len - 1; i >= 0; i--) {
      const actualIntersectionItem = data.actual.intersections[i];
      min_distance =
        actualIntersectionItem.distance &&
        actualIntersectionItem.distance < min_distance
          ? actualIntersectionItem.distance
          : min_distance;
      currentYCoord += actualIntersectionItem.distance;
      const tranformActualIntersectionItem = {
        ...actualIntersectionItem,
        ring1: {
          ...actualIntersectionItem.ring1,
          vehicles: actualIntersectionItem.ring1.vehicles
            ? actualIntersectionItem.ring1.vehicles.map((el) => ({
                time: moment(el, DATE_FORMAT.time_only_full).toDate(),
              }))
            : actualIntersectionItem.ring1.vehicle_points.map((el) => ({
                ...el,
                time: moment(el.time, DATE_FORMAT.time_only_full).toDate(),
              })),
          lights: actualIntersectionItem.ring1.lights.map((el) => {
            const startTime = moment(
              el.start_time,
              DATE_FORMAT.time_only_full
            ).toDate();
            const endTime = moment(
              el.end_time,
              DATE_FORMAT.time_only_full
            ).toDate();

            // tranform greenwave line
            let green_wave_lines = null;

            if (
              actualIntersectionItem.ring1.coord_phase ===
                actualIntersectionItem.upstream_phase &&
              i === 0
            ) {
              // console.log(i, actualIntersectionItem.upstream_phase);
              green_wave_lines = el.forward_green_wave_lines?.map(
                (greenwave_line) => {
                  const greenwave_time = moment(
                    greenwave_line,
                    DATE_FORMAT.time_only_full
                  ).valueOf();

                  let totalEstTime = 0;
                  let totalDistance = 0;
                  data.actual.intersections.forEach((intItem) => {
                    totalEstTime += intItem.estimated_time;
                    totalDistance += intItem.distance;
                  });

                  const arrayPointOfGWLine = [
                    {
                      x: new Date(greenwave_time),
                      y: totalDistance,
                    },
                    {
                      x: new Date(greenwave_time + totalEstTime * 1000),
                      y: 0,
                    },
                  ];

                  return arrayPointOfGWLine;
                }
              );
            }
            if (
              actualIntersectionItem.ring1.coord_phase ===
                actualIntersectionItem.downstream_phase &&
              i === len - 1
            ) {
              green_wave_lines = el.backward_green_wave_lines?.map(
                (greenwave_line) => {
                  const greenwave_time = moment(
                    greenwave_line,
                    DATE_FORMAT.time_only_full
                  ).valueOf();

                  let totalEstTime = 0;
                  let totalDistance = 0;
                  data.actual.intersections.forEach((intItem) => {
                    totalEstTime += intItem.estimated_time;
                    totalDistance += intItem.distance;
                  });

                  const arrayPointOfGWLine = [
                    {
                      x: new Date(greenwave_time),
                      y: 0,
                    },
                    {
                      x: new Date(greenwave_time + totalEstTime * 1000),
                      y: totalDistance,
                    },
                  ];

                  return arrayPointOfGWLine;
                }
              );
            }

            return {
              ...el,
              green_wave_lines: green_wave_lines,
              direction_green_wave_percent:
                el.phase === actualIntersectionItem.upstream_phase
                  ? i === 0
                    ? el.forward_green_wave_percent
                    : null
                  : i === len - 1
                  ? el.backward_green_wave_percent
                  : null,
              greenwave_percent:
                el.phase === actualIntersectionItem.upstream_phase
                  ? el.forward_green_percent
                  : el.backward_green_percent,
              time_space:
                actualIntersectionItem.ring1.coord_phase ===
                actualIntersectionItem.downstream_phase
                  ? i > 0 &&
                    el.phase === actualIntersectionItem.ring1.coord_phase &&
                    el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              data.actual?.intersections[i - 1].estimated_time *
                                1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              data.actual?.intersections[i - 1].estimated_time *
                                1000
                          ),

                          y:
                            currentYCoord +
                            data.actual.intersections[i - 1].distance,
                        },
                      ]
                    : null
                  : i < len - 1 &&
                    el.phase === actualIntersectionItem.ring1.coord_phase &&
                    el.color === "GREEN"
                  ? [
                      {
                        x_start: startTime,
                        x_end: endTime,
                        y: currentYCoord,
                      },
                      {
                        x_start: new Date(
                          startTime.getTime() +
                            actualIntersectionItem.estimated_time * 1000
                        ),
                        x_end: new Date(
                          endTime.getTime() +
                            actualIntersectionItem.estimated_time * 1000
                        ),
                        y:
                          currentYCoord - data.actual.intersections[i].distance,
                      },
                    ]
                  : null,
              start_time: moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
              end_time: moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
            };
          }),
        },

        ring2: {
          ...actualIntersectionItem.ring2,
          vehicles: actualIntersectionItem.ring2.vehicles
            ? actualIntersectionItem.ring2.vehicles.map((el) => ({
                time: moment(el, DATE_FORMAT.time_only_full).toDate(),
              }))
            : actualIntersectionItem.ring2.vehicle_points.map((el) => ({
                ...el,
                time: moment(el.time, DATE_FORMAT.time_only_full).toDate(),
              })),
          lights: actualIntersectionItem.ring2.lights.map((el) => {
            const startTime = moment(
              el.start_time,
              DATE_FORMAT.time_only_full
            ).toDate();
            const endTime = moment(
              el.end_time,
              DATE_FORMAT.time_only_full
            ).toDate();

            // tranform greenwave line
            let green_wave_lines = null;

            if (
              actualIntersectionItem.ring2.coord_phase ===
                actualIntersectionItem.upstream_phase &&
              i === 0
            ) {
              green_wave_lines = el.forward_green_wave_lines?.map(
                (greenwave_line) => {
                  const greenwave_time = moment(
                    greenwave_line,
                    DATE_FORMAT.time_only_full
                  ).valueOf();

                  let totalEstTime = 0;
                  let totalDistance = 0;
                  data.actual.intersections.forEach((intItem) => {
                    totalEstTime += intItem.estimated_time;
                    totalDistance += intItem.distance;
                  });

                  const arrayPointOfGWLine = [
                    {
                      x: new Date(greenwave_time),
                      y: totalDistance,
                    },
                    {
                      x: new Date(greenwave_time + totalEstTime * 1000),
                      y: 0,
                    },
                  ];

                  return arrayPointOfGWLine;
                }
              );
            }
            if (
              actualIntersectionItem.ring2.coord_phase ===
                actualIntersectionItem.downstream_phase &&
              i === len - 1
            )
              green_wave_lines = el.backward_green_wave_lines?.map(
                (greenwave_line) => {
                  const greenwave_time = moment(
                    greenwave_line,
                    DATE_FORMAT.time_only_full
                  ).valueOf();

                  let totalEstTime = 0;
                  let totalDistance = 0;
                  data.actual.intersections.forEach((intItem) => {
                    totalEstTime += intItem.estimated_time;
                    totalDistance += intItem.distance;
                  });

                  const arrayPointOfGWLine = [
                    {
                      x: new Date(greenwave_time),
                      y: 0,
                    },
                    {
                      x: new Date(greenwave_time + totalEstTime * 1000),
                      y: totalDistance,
                    },
                  ];

                  return arrayPointOfGWLine;
                }
              );

            return {
              ...el,

              green_wave_lines: green_wave_lines,

              direction_green_wave_percent:
                el.phase === actualIntersectionItem.upstream_phase
                  ? i === 0
                    ? el.forward_green_wave_percent
                    : null
                  : i === len - 1
                  ? el.backward_green_wave_percent
                  : null,
              greenwave_percent:
                el.phase === actualIntersectionItem.upstream_phase
                  ? el.forward_green_percent
                  : el.backward_green_percent,

              time_space:
                actualIntersectionItem.ring2.coord_phase ===
                actualIntersectionItem.upstream_phase
                  ? i < len - 1 &&
                    el.phase === actualIntersectionItem.ring2.coord_phase &&
                    el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              actualIntersectionItem.estimated_time * 1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              actualIntersectionItem.estimated_time * 1000
                          ),
                          y:
                            currentYCoord -
                            data.actual.intersections[i].distance,
                        },
                      ]
                    : null
                  : i > 0 &&
                    el.phase === actualIntersectionItem.ring2.coord_phase &&
                    el.color === "GREEN"
                  ? [
                      {
                        x_start: startTime,
                        x_end: endTime,
                        y: currentYCoord,
                      },
                      {
                        x_start: new Date(
                          startTime.getTime() +
                            data.actual?.intersections[i - 1].estimated_time *
                              1000
                        ),
                        x_end: new Date(
                          endTime.getTime() +
                            data.actual?.intersections[i - 1].estimated_time *
                              1000
                        ),

                        y:
                          currentYCoord +
                          data.actual.intersections[i - 1].distance,
                      },
                    ]
                  : null,
              start_time: moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
              end_time: moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate(),
            };
          }),
        },
        plans: actualIntersectionItem.plans.map((el) => {
          return {
            ...el,
            start_point: moment(
              el.start_point,
              DATE_FORMAT.time_only_full
            ).toDate(),
            end_point: moment(
              el.end_point,
              DATE_FORMAT.time_only_full
            ).toDate(),
          };
        }),
        coordY: currentYCoord,
      };

      tranformObject.actual.intersections.push(tranformActualIntersectionItem);
    }
    tranformObject.actual.min_distance = min_distance;
    tranformObject.actual.to_distance = currentYCoord + min_distance;

    //tranform programmed
    currentYCoord = 0;
    min_distance = Number.MAX_SAFE_INTEGER;
    if (data.programmed) {
      len = data.programmed.intersections.length;
      for (let i = len - 1; i >= 0; i--) {
        const programmedIntersectionItem = data.programmed?.intersections[i];
        min_distance =
          programmedIntersectionItem.distance &&
          programmedIntersectionItem.distance < min_distance
            ? programmedIntersectionItem.distance
            : min_distance;
        currentYCoord += programmedIntersectionItem.distance;
        const tranformprogrammedIntersectionItem = {
          ...programmedIntersectionItem,
          ring1: {
            ...programmedIntersectionItem.ring1,
            lights: programmedIntersectionItem.ring1.lights.map((el) => {
              const startTime = moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate();
              const endTime = moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate();

              return {
                ...el,
                start_time: startTime,
                end_time: endTime,
                time_space:
                  programmedIntersectionItem.ring1.coord_phase ===
                  programmedIntersectionItem.downstream_phase
                    ? i > 0 &&
                      el.phase ===
                        programmedIntersectionItem.ring1.coord_phase &&
                      el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                data.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                data.actual?.intersections[i - 1]
                                  .estimated_time *
                                  1000
                            ),

                            y:
                              currentYCoord +
                              data.actual.intersections[i - 1].distance,
                          },
                        ]
                      : null
                    : i < len - 1 &&
                      el.phase ===
                        programmedIntersectionItem.ring1.coord_phase &&
                      el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              programmedIntersectionItem.estimated_time * 1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              programmedIntersectionItem.estimated_time * 1000
                          ),
                          y:
                            currentYCoord -
                            data.actual.intersections[i].distance,
                        },
                      ]
                    : null,
              };
            }),
          },

          ring2: {
            ...programmedIntersectionItem.ring2,
            lights: programmedIntersectionItem.ring2.lights.map((el) => {
              const startTime = moment(
                el.start_time,
                DATE_FORMAT.time_only_full
              ).toDate();
              const endTime = moment(
                el.end_time,
                DATE_FORMAT.time_only_full
              ).toDate();

              return {
                ...el,
                start_time: startTime,
                end_time: endTime,

                time_space:
                  programmedIntersectionItem.ring2.coord_phase ===
                  programmedIntersectionItem.upstream_phase
                    ? i < len - 1 &&
                      el.phase ===
                        programmedIntersectionItem.ring2.coord_phase &&
                      el.color === "GREEN"
                      ? [
                          {
                            x_start: startTime,
                            x_end: endTime,
                            y: currentYCoord,
                          },
                          {
                            x_start: new Date(
                              startTime.getTime() +
                                programmedIntersectionItem.estimated_time * 1000
                            ),
                            x_end: new Date(
                              endTime.getTime() +
                                programmedIntersectionItem.estimated_time * 1000
                            ),
                            y:
                              currentYCoord -
                              data.actual.intersections[i].distance,
                          },
                        ]
                      : null
                    : i > 0 &&
                      el.phase ===
                        programmedIntersectionItem.ring2.coord_phase &&
                      el.color === "GREEN"
                    ? [
                        {
                          x_start: startTime,
                          x_end: endTime,
                          y: currentYCoord,
                        },
                        {
                          x_start: new Date(
                            startTime.getTime() +
                              data.actual?.intersections[i - 1].estimated_time *
                                1000
                          ),
                          x_end: new Date(
                            endTime.getTime() +
                              data.actual?.intersections[i - 1].estimated_time *
                                1000
                          ),

                          y:
                            currentYCoord +
                            data.actual.intersections[i - 1].distance,
                        },
                      ]
                    : null,
              };
            }),
          },
          coordY: currentYCoord,
        };

        tranformObject.programmed.intersections.push(
          tranformprogrammedIntersectionItem
        );
      }

      tranformObject.programmed.min_distance = min_distance;
      tranformObject.programmed.to_distance = currentYCoord + min_distance;
    }
    return tranformObject;
  };

  renderPDFFile = () => {
    const { data, t } = this.props;

    const printInfo = data?.general_data;

    return (
      <PDFViewer className="chart-print-preview">
        <Document>
          <Page size={"A4"} orientation="portrait">
            {this.renderCoverPage(printInfo)}
          </Page>

          {this.state.timeSpaceChart?.map((page, id) => (
            <Page
              key={id}
              style={{ position: "relative", height: "100%" }}
              size="A4"
              orientation="landscape">
              <View
                style={{
                  ...styles.row,
                  ...styles.headerPane,
                }}>
                <Text style={styles.header}>{t("print.header")} | </Text>
                <Text style={styles.headerInfo}>
                  {t("print.header-reporter")} : {} |{" "}
                  {moment().format(DATE_FORMAT.full)}
                </Text>
              </View>
              <View style={styles.contentDetailCenter}>
                {<Text style={styles.pageTitle}>{page.int_name}</Text>}
                <Image src={page.dataUrl} />
              </View>
              <View
                style={{
                  ...styles.row,
                  ...styles.footerPane,
                }}>
                <Text style={styles.footer}>
                  {"© " + moment().year() + "  " + t("print.footer")}
                </Text>
                <Text style={styles.footerPage}>
                  {t("print.footer-page")} {++this.page}
                </Text>
              </View>
            </Page>
          ))}
        </Document>
      </PDFViewer>
    );
  };

  async componentDidMount() {
    const {
      data: {
        general_data: {
          start_time,
          end_time,
          id,
          optimization_type: { key },
        },
      },
      currentStore: {
        historicTimespaceStore: { processDataToPrint },
      },
    } = this.props;

    const splitTime = this.splitDuration(start_time, end_time);

    this.setState({
      splitDuration: splitTime,
    });

    splitTime.forEach(async (e, index) => {
      const data = {
        from_time: e.start,
        to_time: e.end,
        type: key,
      };

      processDataToPrint(id, data, index);
    });
  }

  async componentDidUpdate() {
    const {
      currentStore: {
        historicTimespaceStore: { dataPrint },
      },
    } = this.props;

    if (dataPrint.length === this.state.timeSpaceChart.length) {
      return;
    }

    if (dataPrint.length === this.state.splitDuration.length) {
      const childInt = this.timeSpaceChartRef.current?.childNodes;

      let dataInt = [];

      for (let i = 0; i < childInt?.length; i++) {
        const dataUrl = await htmlToImage.toPng(childInt[i]);

        dataInt.push({
          dataUrl,
          index: i,
        });
      }

      this.setState({
        timeSpaceChart: dataInt,
      });
    }
  }

  render() {
    const {
      currentStore: {
        historicTimespaceStore: { dataPrint },
      },
    } = this.props;

    const transformedDataPrint = dataPrint?.map((item) => {
      return this.transformDataPrint(item);
    });

    // return (
    //   <div
    //     className="chart-data w-screen h-screen absolute"
    //     ref={this.timeSpaceChartRef}
    //   >
    //     {transformedDataPrint?.map((item, index) => {
    //       return (
    //         <TimeSpaceChart key={index} data={item} hiddenContent={true} />
    //       );
    //     })}
    //   </div>
    // );

    return this.state?.timeSpaceChart?.length ? (
      this.renderPDFFile()
    ) : (
      <div className="loading-screen" style={{ width: "100%" }}>
        <Spin size="large" className={"centralize bg-background"}>
          <div
            className="chart-data w-screen h-screen absolute"
            ref={this.timeSpaceChartRef}>
            {transformedDataPrint
              ?.sort((a, b) => b.index - a.index)
              .map((item, index) => {
                return <TimeSpaceChart key={index} data={item} />;
              })}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withTranslation()(PrintPreview);
