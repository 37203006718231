import i18n from "i18n";
import { action, observable, runInAction } from "mobx";
import moment from "moment";
import AgencyService from "services/AgencyService";
import IntersectionService from "services/IntersectionService";
import DetectorResultService from "services/TemplateGroup/DetectorResultService";
import UserService from "services/UserService";
import BaseStore from "stores/BaseStore";
import { DATE_FORMAT, RESOURCE_LIST } from "utils/constants";
import { mockDetailChart } from "./mock";

class DetectorResultStore extends BaseStore {
  @observable allAgencies = [];
  @observable allUsers = [];
  constructor(parentStore) {
    super(new DetectorResultService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.userService = new UserService(parentStore);
    this.intersectionService = new IntersectionService(parentStore);
    this.resourceName = RESOURCE_LIST.ALARM_RECORD;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        owner_id: null,
        aggregation: null,
        agency_id: "",
        from_time: null,
        to_time: null,
        from_date: null,
        to_date: null,
        sort: "",
      },
    };
    this.resourceName = RESOURCE_LIST.REPORT_RESULT;
  }
  @observable reportDetail = {};
  @observable resultData = [];
  @observable prev_result_time = null;
  /**
   * getCategoryName
   *
   * @return  {string} -
   */
  @action resetData() {
    this.reportDetail = {};
    this.resultData = [];
  }
  @action getAllUsers = (agency_id) => {
    if (this.allUsers?.length == 0) {
      this.loading = true;
      this.userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = [
              ...response?.data,
              {
                id: -1,
                name: "System",
                email: "SYSTEM",
              },
            ];
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    if (isSuperAdmin)
      this.agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };

  @action delete = (id = null) => {
    this.loading = true;
    if (!id) {
      id = this.selectedItems;
    }
    this.moduleService?.delete(
      { result_ids: id },
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  tranformDataResult = (jsonData) => {
    let data = jsonData ? jsonData?.map((e) => JSON.parse(e)) : [];

    if (data?.length) {
      data = data.map((int) => {
        return {
          key: int.int_id,
          int_id: int.int_id,
          int_name: int.int_name,
          detector_type: "-",
          detail_chart: {
            total_activation: mockDetailChart.detail_chart.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: time.call_phases,
              };

              Object.keys(time.detector_data).forEach((det) => {
                objectReturn["phase_" + det] =
                  time.detector_data[det].phase_data[det].total_activation;
              });
              return objectReturn;
            }),
            occupancy: mockDetailChart.detail_chart.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: time.call_phases,
              };

              Object.keys(time.detector_data).forEach((det) => {
                objectReturn["phase_" + det] =
                  time.detector_data[det].phase_data[det].occupancy;
              });
              return objectReturn;
            }),
            avg_volume: mockDetailChart.detail_chart.map((time) => {
              const objectReturn = {
                from_time: new Date(time.from_time),
                to_time: new Date(time.to_time),
                time: new Date(
                  (new Date(time.from_time).getTime() +
                    new Date(time.to_time).getTime()) /
                    2
                ),
                call_phases: time.call_phases,
              };

              Object.keys(time.detector_data).forEach((det) => {
                objectReturn["phase_" + det] =
                  time.detector_data[det].phase_data[det].avg_volume;
              });
              return objectReturn;
            }),
          },

          total_activation: Object.keys(int.detector_data).reduce(
            (total, detector) => {
              const currentPhase = Object.keys(
                int.detector_data[detector].phase_data
              )?.[0];

              return (
                total +
                  int.detector_data[detector].phase_data[currentPhase]
                    ?.total_activation ?? 0
              );
            },
            0
          ),
          occupancy: Object.keys(int.detector_data).reduce(
            (total, detector) => {
              const currentPhase = Object.keys(
                int.detector_data[detector].phase_data
              )?.[0];

              return (
                total +
                  int.detector_data[detector].phase_data[currentPhase]
                    ?.occupancy ?? 0
              );
            },
            0
          ),
          avg_volume: Object.keys(int.detector_data).reduce(
            (total, detector) => {
              const currentPhase = Object.keys(
                int.detector_data[detector].phase_data
              )?.[0];

              return (
                total +
                  int.detector_data[detector].phase_data[currentPhase]
                    ?.avg_volume ?? 0
              );
            },
            0
          ),

          call_phases: int.call_phases?.join(", "),
          children: Object.keys(int.detector_data)?.map((detector) => {
            const currentPhase = Object.keys(
              int.detector_data[detector].phase_data
            )?.[0];

            return {
              key: int.int_id + detector,
              int_name: `${i18n.t(
                "intersection_config.detector"
              )}: ${detector}`,

              detector_type: int.detector_data[detector].det_type,
              call_phases: Object.keys(int.detector_data[detector].phase_data)
                .map((p) => `${i18n.t("intersection_config.Phase")} ${p}`)
                .join(", "),
              ...int.detector_data[detector].phase_data[currentPhase],
            };
          }),
        };
      });
    }
    return data;
  };
  @action getResultData = (id) => {
    this.loading = true;
    this.moduleService?.getDetail(
      id,
      (response) => {
        runInAction(() => {
          this.reportDetail = {
            ...response,
            from_time: moment(
              response?.from_time,
              DATE_FORMAT.time_only_full
            ).format(DATE_FORMAT.hour),
            to_time: moment(
              response?.to_time,
              DATE_FORMAT.time_only_full
            ).format(DATE_FORMAT.hour),
            from_date: moment(
              response?.from_date,
              DATE_FORMAT.day_only_backend
            ).format(DATE_FORMAT.day_only),
            to_date: moment(
              response?.to_date,
              DATE_FORMAT.day_only_backend
            ).format(DATE_FORMAT.day_only),
            created_at: moment(response?.created_at).format(
              DATE_FORMAT.full_with_tz
            ),
            // selected_intersection: 45
          };
          this.resultData = this.tranformDataResult(
            response.chart_data_aggregate
          );

          this.loading = false;
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default DetectorResultStore;
